"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var organization_1 = require("./organization");
var api_service_1 = require("../shared/api.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var OrganizationService = /** @class */ (function () {
    function OrganizationService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.count = 0;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.token = currentUser.token;
            this.organizationId = currentUser.organization && currentUser.organization.id;
        }
        this.params = new http_1.HttpParams();
    }
    OrganizationService.prototype.getOrganization = function (organizationId) {
        var organizationUrl = this.baseUrl + 'organizations/' + organizationId + '/';
        return this.http.get(organizationUrl, {
            headers: api_service_1.requestHeaders(),
        }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    OrganizationService.prototype.get = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var organizationsUrl = this.baseUrl + 'organizations/';
        var params = new http_1.HttpParams();
        if (query) {
            if (typeof query === 'string') {
                organizationsUrl = this.baseUrl + 'organizations/' + query + '/';
            }
            else {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params = params.set(key, query[key].toString());
                    }
                });
            }
        }
        return this.http.get(organizationsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    OrganizationService.prototype.getOrganizations = function (search) {
        var organizationsUrl = this.baseUrl + 'organizations/';
        this.buildSearchQuery(search);
        this.buildExclusionList(this.organizationId, true);
        return this.http.get(organizationsUrl, {
            headers: api_service_1.requestHeaders(),
            params: this.params
        }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    OrganizationService.prototype.list = function (search) {
        var organizationsUrl = this.baseUrl + 'organizations/';
        this.buildSearchQuery(search);
        this.buildExclusionList(this.organizationId, true);
        return this.http.get(organizationsUrl, {
            headers: api_service_1.requestHeaders(),
            params: this.params
        }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    OrganizationService.prototype.save = function (organization) {
        var _this = this;
        var organizationUrl = this.baseUrl + 'organizations/';
        var _organization = decamelizeKeysDeep(lodash_1.clone(organization));
        delete _organization.image;
        delete _organization.attachments;
        if (typeof _organization.billing_contact === 'object') {
            _organization.billing_contact = _organization.billing_contact.id;
        }
        if (_organization.default_round_trip_minutes && parseFloat(_organization.default_round_trip_minutes) > 0) {
            _organization.default_round_trip_time = parseFloat(_organization.default_round_trip_minutes) * 60;
        }
        if (_organization.default_yard_prep_minutes && parseFloat(_organization.default_yard_prep_minutes) > 0) {
            _organization.default_yard_prep_time = parseFloat(_organization.default_yard_prep_minutes) * 60;
        }
        if (_organization.default_yard_buffer_minutes && parseFloat(_organization.default_yard_buffer_minutes) > 0) {
            _organization.default_yard_buffer_time = parseFloat(_organization.default_yard_buffer_minutes) * 60;
        }
        if (!organization.id) {
            return this.http.post(organizationUrl, _organization, { headers: api_service_1.requestHeaders() });
        }
        else {
            var updateUrl = organizationUrl + organization.id + '/';
            return this.http.put(updateUrl, _organization, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
    };
    OrganizationService.prototype.patch = function (model, id) {
        return this.http.patch(this.baseUrl + "organizations/" + id + "/", model, { headers: api_service_1.requestHeaders() });
    };
    OrganizationService.prototype.saveWithImage = function (organization, logoImage) {
        var _this = this;
        var _organization = decamelizeKeysDeep(lodash_1.clone(organization));
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            delete _organization.image;
            delete _organization.tags;
            delete _organization.billing_contact;
            delete _organization.primary_contact;
            delete _organization.email;
            delete _organization.feature;
            delete _organization.enabled_features;
            delete _organization.carrier;
            delete _organization.broker;
            if (_organization.default_round_trip_minutes && parseFloat(_organization.default_round_trip_minutes) > 0) {
                _organization.default_round_trip_time = parseFloat(_organization.default_round_trip_minutes) * 60;
            }
            else {
                _organization.default_round_trip_time = 0;
            }
            if (_organization.default_yard_prep_minutes && parseFloat(_organization.default_yard_prep_minutes) > 0) {
                _organization.default_yard_prep_time = parseFloat(_organization.default_yard_prep_minutes) * 60;
            }
            else {
                _organization.default_yard_prep_time = 0;
            }
            if (_organization.default_yard_buffer_minutes && parseFloat(_organization.default_yard_buffer_minutes) > 0) {
                _organization.default_yard_buffer_time = parseFloat(_organization.default_yard_buffer_minutes) * 60;
            }
            else {
                _organization.default_yard_buffer_time = 0;
            }
            Object.keys(_organization).map(function (key, index) {
                var value = _organization[key];
                formData.append(key, value);
            });
            if (logoImage) {
                formData.append('image', logoImage, logoImage.name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            if (_organization.id) {
                var organizationUrl = _this.baseUrl + 'organizations/' + _organization.id + '/';
                xhr.open('PUT', organizationUrl, true);
            }
            else {
                var organizationUrl = _this.baseUrl + 'organizations/';
                xhr.open('POST', organizationUrl, true);
            }
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    OrganizationService.prototype.saveOnlyImage = function (organizationId, logoImage) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            formData.append('image', logoImage, logoImage.name);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200 || xhr.status === 201) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            if (organizationId) {
                var organizationUrl = _this.baseUrl + 'organizations/' + organizationId + '/';
                xhr.open('PUT', organizationUrl, true);
            }
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    OrganizationService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(function (res) { return api_service_1.handleError(res); }));
        }
        else {
            return null;
        }
    };
    OrganizationService.prototype.listNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(function (res) { return api_service_1.handleError(res); }));
        }
        else {
            return null;
        }
    };
    OrganizationService.prototype.extractData = function (res) {
        var resObj = res;
        var body = resObj['results'];
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (org) {
                return new organization_1.Organization(org);
            });
        }
        else if (res) {
            return new organization_1.Organization(res);
        }
        else {
            return [];
        }
    };
    OrganizationService.prototype.buildSearchQuery = function (search) {
        if (search && search.length > 0) {
            this.params = this.params.set('name__icontains', search);
        }
        else {
            this.params = this.params.delete('name__icontains');
        }
    };
    OrganizationService.prototype.buildExclusionList = function (organizationId, excludeConnected) {
        if (excludeConnected === void 0) { excludeConnected = true; }
        if (organizationId && organizationId.length > 0) {
            this.params = this.params.set('exclude', organizationId);
        }
        else {
            this.params = this.params.delete('exclude');
        }
        this.params = this.params.set('exclude_connected', excludeConnected ? 'True' : 'False');
    };
    return OrganizationService;
}());
exports.OrganizationService = OrganizationService;
