"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".truck-photo[_ngcontent-%COMP%]{min-width:110px;width:100%;height:65px;padding-bottom:3px;border-radius:4px;overflow:hidden}.truck-photo[_ngcontent-%COMP%]   .image[_ngcontent-%COMP%]{height:100%}.truck-photo[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;width:100%;height:100%}.truck-photo[_ngcontent-%COMP%]   .icon-fleet[_ngcontent-%COMP%]{display:block;font-size:46px;color:#e2e3e9;width:100%;height:100%;display:flex;justify-content:center;align-items:center;background:#f5f8fa}.details[_ngcontent-%COMP%]{cursor:pointer}.name[_ngcontent-%COMP%]{font-size:14px;font-weight:600;text-align:center;color:#354052}.carrier[_ngcontent-%COMP%], .created-at[_ngcontent-%COMP%], .phone[_ngcontent-%COMP%], .type[_ngcontent-%COMP%]{font-size:12px;text-align:center;color:#7f8fa4}a[_ngcontent-%COMP%]{display:block;text-align:center;color:#58b4f9;font-weight:600;text-decoration:none}"];
exports.styles = styles;
