"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pay_sheet_1 = require("./pay-sheet");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PaySheetSerializer = /** @class */ (function () {
    function PaySheetSerializer() {
    }
    PaySheetSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var payRecord = new pay_sheet_1.PaySheet();
        if (!json) {
            return payRecord;
        }
        payRecord.id = json.id;
        payRecord.createdAt = json.createdAt;
        payRecord.createdBy = json.createdBy;
        payRecord.lastModified = json.lastModified;
        payRecord.lastModifiedBy = json.lastModifiedBy;
        payRecord.payerOrganization = json.payerOrganization;
        payRecord.payeeOrganizations = json.payeeOrganizations;
        payRecord.reportDate = json.reportDate;
        payRecord.startTime = json.startTime;
        payRecord.endTime = json.endTime;
        payRecord.isApproved = json.isApproved;
        payRecord.processStatus = json.processStatus;
        return payRecord;
    };
    PaySheetSerializer.prototype.toJson = function (payRecord) {
        var json = {
            id: payRecord.id,
            createdAt: payRecord.createdAt,
            createdBy: payRecord.createdBy,
            lastModified: payRecord.lastModified,
            lastModifiedBy: payRecord.lastModifiedBy,
            payerOrganization: payRecord.payerOrganization,
            payeeOrganizations: payRecord.payeeOrganizations,
            reportDate: payRecord.reportDate,
            startTime: payRecord.startTime,
            endTime: payRecord.endTime,
            isApproved: payRecord.isApproved
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return PaySheetSerializer;
}());
exports.PaySheetSerializer = PaySheetSerializer;
