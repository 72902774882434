"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
// angular material
var material_1 = require("@angular/material");
// libraries
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
// components
var index_1 = require("../../shared/dialogs/index");
var index_2 = require("../../shared/index");
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
// services
var core_2 = require("@ngx-translate/core");
var driver_service_1 = require("./../driver.service");
var api_service_1 = require("../../shared/api.service");
var index_3 = require("../../shared/index");
var connection_service_1 = require("../../connections/connection.service");
var api_service_2 = require("../../shared/api.service");
var custom_field_service_1 = require("../../custom-fields/custom-field.service");
var truck_service_1 = require("../../trucks/truck.service");
// models
var custom_field_1 = require("../../custom-fields/custom-field");
var driver_1 = require("../../drivers/driver");
var EditDriverComponent = /** @class */ (function () {
    function EditDriverComponent(fb, driverService, apiService, authenticationService, deviceDetectorService, _eref, customFieldService, translate, dialog) {
        this.fb = fb;
        this.driverService = driverService;
        this.apiService = apiService;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this._eref = _eref;
        this.customFieldService = customFieldService;
        this.translate = translate;
        this.dialog = dialog;
        this.driver = { user: {}, profile: {} };
        this.completed = new core_1.EventEmitter();
        this.open = false;
        this.states = [];
        this.stateOptions = [];
        this.countryOptions = [];
        this.loading = false;
        this.errors = [];
        // carriers dropdown config
        this.carriersConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'organization.carrier.id',
            selectText: this.translate.instant('Select Fleet'),
            loadingText: this.translate.instant('Loading Fleets...'),
            noResultsText: this.translate.instant('No Fleets'),
            service: connection_service_1.ConnectionService,
            serviceFunction: 'list',
            query: {
                ordering: 'organization__name',
                is_carrier: 'True',
                allow_dispatch: 'True',
                status: 'active',
            },
            customOptionKeys: ['organization'],
        };
        // trucks dropdown config
        this.trucksConfig = {
            searchable: true,
            nameProperty: 'displayName',
            selectText: 'Assign Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            service: truck_service_1.TruckService,
            serviceFunction: 'list',
            groupBy: 'carrierOrganizationName',
            query: {
                ordering: 'name',
                carrier: '',
            },
            prefilledOptions: [
                {
                    displayName: this.translate.instant('No Truck'),
                    id: null,
                },
            ],
        };
        this.truckFirstLoad = true;
        this.dutyStatusConfig = { nameProperty: 'name' };
        this.dutyStatusOptions = driver_1.DRIVER_DUTY_OPTIONS.slice();
        this.customFields = {};
    }
    EditDriverComponent.prototype.documentClick = function (event) {
        var row = event && event.target && event.target.closest('.row');
        if (!lodash_1.includes(event.target.classList, 'row') &&
            !(row && row.contains(event.target))) {
            var confirmDialog = document.querySelector('ruckit-confirm-dialog');
            if (!lodash_1.includes(event.target.classList, 'icon-more') &&
                !lodash_1.includes(event.target.classList, 'mat-chip-remove') &&
                !lodash_1.includes(event.target.classList, 'mat-option-text') &&
                !this._eref.nativeElement.contains(event.target) &&
                !(confirmDialog && confirmDialog.contains(event.target))) {
                this.close();
            }
        }
    };
    EditDriverComponent.prototype.ngOnInit = function () {
        this.onInit();
    };
    EditDriverComponent.prototype.onInit = function () {
        this.user = this.authenticationService.user();
        this.checkDevice();
        this.getCountries();
        this.getCustomFields();
        this.states = this.apiService.getStates();
        this.setCountry();
    };
    EditDriverComponent.prototype.checkDevice = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop(),
        };
    };
    EditDriverComponent.prototype.createEditDriverForm = function (driver) {
        var _this = this;
        var phonePattern = '(^$)|([- +()0-9]+)';
        var form = this.fb.group({
            profile: this.fb.group({
                firstName: [
                    driver && driver.profile ? driver.profile.firstName : '',
                    [forms_1.Validators.required, forms_1.Validators.minLength(1)],
                ],
                lastName: [
                    driver && driver.profile ? driver.profile.lastName : '',
                    [forms_1.Validators.required, forms_1.Validators.minLength(1)],
                ],
                email: [driver.profile ? driver.profile.email : '', [forms_1.Validators.email]],
                phoneNumber: [
                    driver.profile ? driver.profile.phoneNumber : '',
                    [forms_1.Validators.pattern(phonePattern)],
                ],
            }),
            cdl: [driver.cdl, [forms_1.Validators.minLength(1)]],
            uniqueBillingId: [driver.uniqueBillingId, [forms_1.Validators.minLength(1)]],
            user: this.fb.group({
                username: [
                    driver && driver.user ? driver.user.username : '',
                    [forms_1.Validators.required, forms_1.Validators.minLength(1)],
                ],
                password: [
                    {
                        value: driver && driver.user ? driver.user.password : '',
                        disabled: true,
                    },
                    [forms_1.Validators.required, forms_1.Validators.minLength(1)],
                ],
            }),
            carrier: [driver.carrier || null],
            truck: [driver.truck || null],
            dutyStatus: [driver.dutyStatus || null],
            street: [driver.street || null],
            city: [driver.city || null],
            country: [driver.country || null],
            state: [driver.state || null],
            zipcode: [driver.zipcode || null],
            tags: [driver.tags || null],
            customFieldData: this.fb.group({}),
        });
        var customFields = form.get('customFieldData');
        Object.keys(this.customFields).forEach(function (field) {
            customFields.addControl(_this.customFields[field].key, new forms_1.FormControl(driver.customFieldData[_this.customFields[field].key]));
        });
        return form;
    };
    EditDriverComponent.prototype.setInitialValues = function () {
        this.editDriverForm = this.createEditDriverForm(__assign({}, this.driver));
        this.truckFirstLoad = this.driver.truck ? true : false;
        this.errors = [];
        if (this.user.organization.carrier.id === this.driver.carrier.id) {
            this.trucksConfig.query = {
                ordering: 'name',
                carrier: null,
            };
        }
        else {
            this.trucksConfig.query = {
                ordering: 'name',
                carrier: this.driver.carrier.id,
            };
        }
        this.setCountry();
    };
    EditDriverComponent.prototype.setCountry = function () {
        if (this.driver && this.driver.country) {
            this.optionSelected(this.driver.country, 'country', false);
        }
        else {
            this.optionSelected('US', 'country', false);
        }
    };
    EditDriverComponent.prototype.setOpen = function () {
        var _this = this;
        this.editDriverForm = null;
        setTimeout(function () {
            _this.setInitialValues();
            _this.open = true;
            _this.addUserCarrierToCarriersDropdown();
        }, 300);
    };
    EditDriverComponent.prototype.addUserCarrierToCarriersDropdown = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.carriersDropdown) {
                var userCarrierOption = __assign({}, _this.user.organization.carrier, { organization: _this.user.organization });
                _this.carriersDropdown.addAnOptionToStart(userCarrierOption);
            }
        }, 500);
    };
    EditDriverComponent.prototype.close = function () {
        this.open = false;
    };
    EditDriverComponent.prototype.onPasswordChange = function () {
        var passwordControl = this.getFormControlWithArgs(['user', 'password']);
        if (passwordControl.enabled) {
            passwordControl.disable();
        }
        else {
            passwordControl.enable();
        }
    };
    EditDriverComponent.prototype.onSubmit = function () {
        var _this = this;
        this.loading = true;
        // get all form values that were changed
        var changedValues = this.getDirtyValues(this.editDriverForm);
        var modifiedDriver = __assign({}, this.driver);
        // profile and user are nested objects, that's why it's more complicted
        // truck only needs id
        Object.keys(changedValues).forEach(function (key) {
            switch (key) {
                case 'profile':
                    modifiedDriver = __assign({}, modifiedDriver, { profile: __assign({}, modifiedDriver.profile, changedValues['profile']) });
                    // combine first and lastname so the change will be seen on emitting
                    modifiedDriver.name = modifiedDriver.profile.firstName + " " + modifiedDriver.profile.lastName;
                    modifiedDriver.profile.name = modifiedDriver.profile.firstName + " " + modifiedDriver.profile.lastName;
                    break;
                case 'user':
                    modifiedDriver = __assign({}, modifiedDriver, { user: __assign({}, modifiedDriver.user, changedValues['user']) });
                    break;
                case 'truck':
                    modifiedDriver.truck = _this.driver.truck.id;
                    break;
                default:
                    modifiedDriver[key] = changedValues[key];
                    break;
            }
        });
        this.saveChanges(modifiedDriver);
    };
    EditDriverComponent.prototype.getDirtyValues = function (form) {
        var _this = this;
        var dirtyValues = {};
        Object.keys(form.controls).forEach(function (key) {
            var currentControl = form.controls[key];
            if (currentControl.dirty) {
                if (currentControl.controls) {
                    dirtyValues[key] = _this.getDirtyValues(currentControl);
                }
                else {
                    dirtyValues[key] = currentControl.value;
                }
            }
        });
        return dirtyValues;
    };
    EditDriverComponent.prototype.getCountries = function () {
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name,
            };
        });
    };
    EditDriverComponent.prototype.getCustomFields = function () {
        var _this = this;
        this.customFieldService
            .getIndexedFieldsForKind(custom_field_1.CustomFieldKind.Driver)
            .subscribe(function (fields) {
            _this.customFields = fields;
        });
    };
    EditDriverComponent.prototype.saveChanges = function (modifiedDriver) {
        var _this = this;
        this.errors = [];
        this.driverService.saveDriver(modifiedDriver).subscribe(function () {
            modifiedDriver['filterOptions'] = _this.driver.filterOptions;
            _this.completed.emit(modifiedDriver);
            _this.open = false;
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.loading = false;
        });
    };
    EditDriverComponent.prototype.remove = function () {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translate.instant('Delete Driver?'),
            body: this.translate.instant('You will no longer be able to dispatch this driver. A deleted driver will be placed in the archived section where you can reactivate at a later time.'),
            close: this.translate.instant('Cancel'),
            accept: this.translate.instant('Delete'),
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.driverService.remove(_this.driver).subscribe(function () {
                    _this.driver.status = 'deleted';
                    _this.open = false;
                    _this.loading = false;
                    _this.completed.emit(_this.driver);
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    /**
     * Updates a specified field on the driver object, with specified functionality for particular fields
     *
     * @param {any} value The updated value to be applied to the driver object
     * @param {string} field The field name which the value will be set on
     * @param {boolean} makeFormControlDirty make form control dirty or not
     */
    EditDriverComponent.prototype.optionSelected = function (value, field, makeFormControlDirty) {
        var _this = this;
        if (makeFormControlDirty === void 0) { makeFormControlDirty = true; }
        switch (field) {
            case 'country':
                this.stateOptions = lodash_1.filter(this.states, { country: value }).map(function (_state) {
                    return {
                        id: _state.abbreviation,
                        name: _state.name,
                    };
                });
                this.driver.country = value;
                break;
            case 'carrier':
                var carrier = value.organization && value.organization.carrier
                    ? value.organization.carrier
                    : null;
                if (carrier === null) {
                    return;
                }
                if (this.driver.carrier.id === carrier.id) {
                    makeFormControlDirty = false;
                }
                else {
                    this.driver.carrier = carrier;
                    this.driver.carrierName = carrier.name;
                    if (this.user.organization.carrier.id === carrier.id) {
                        this.getTrucksWithCarrierId(null);
                    }
                    else {
                        this.getTrucksWithCarrierId(carrier.id);
                    }
                    this.driver.truck = '';
                }
                break;
            case 'dutyStatus':
                this.driver[field] = value;
                this.dutyStatusOptions.forEach(function (option) {
                    if (value === option.id) {
                        _this.driver.displayDutyStatus = option.name;
                    }
                });
                break;
            case 'tags':
                if (value.length > 0) {
                    this.driver['tags'] = value;
                }
                else {
                    this.driver['tags'] = [];
                }
                break;
            default:
                this.driver[field] = value;
                break;
        }
        if (this.editDriverForm && makeFormControlDirty) {
            if (field === 'truck' && this.truckFirstLoad) {
                this.truckFirstLoad = false;
            }
            else {
                this.setFormControlValueAndMarkDirty(field, value);
            }
        }
    };
    EditDriverComponent.prototype.getTrucksWithCarrierId = function (carrierId) {
        this.trucksConfig.query.carrier = carrierId;
        this.trucksDropdown.getRecords({
            carrier: carrierId,
        });
    };
    EditDriverComponent.prototype.setFormControlValueAndMarkDirty = function (args, value) {
        var formControl = this.editDriverForm.get(args);
        formControl.setValue(value);
        formControl.markAsDirty();
    };
    EditDriverComponent.prototype.getFormControlWithArgs = function (args) {
        return this.editDriverForm.get(args);
    };
    return EditDriverComponent;
}());
exports.EditDriverComponent = EditDriverComponent;
