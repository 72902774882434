"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var plant_1 = require("./plant");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PlantSerializer = /** @class */ (function () {
    function PlantSerializer() {
    }
    PlantSerializer.prototype.fromJson = function (plantInfo) {
        plantInfo = camelcaseKeysDeep(plantInfo);
        var plant = new plant_1.Plant();
        if (!plantInfo) {
            return plant;
        }
        plant.id = plantInfo.id;
        return plant;
    };
    PlantSerializer.prototype.toJson = function (plant) {
        var json = {
            id: plant.id,
            selected: plant.selected
        };
        return decamelizeKeysDeep(json);
    };
    return PlantSerializer;
}());
exports.PlantSerializer = PlantSerializer;
