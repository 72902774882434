"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./void-ticket-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("./void-ticket-dialog.component");
var i4 = require("@angular/material/dialog");
var styles_VoidTicketConfirmDialogComponent = [i0.styles];
var RenderType_VoidTicketConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VoidTicketConfirmDialogComponent, data: {} });
exports.RenderType_VoidTicketConfirmDialogComponent = RenderType_VoidTicketConfirmDialogComponent;
function View_VoidTicketConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "dialog-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What is the reason for voiding this ticket?"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "textarea", [["class", "reason"]], [[8, "maxLength", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.createCopy = ($event.target.checked ? true : false)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["for", "voided_ticket"]], [[1, "data-label", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-cancel close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDialog.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-warning accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onVoidButtonClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attributes.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.attributes.body; _ck(_v, 4, 0, currVal_1); var currVal_2 = 255; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.createCopy; _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("Create a copy of voided ticket")), ""); _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.attributes.close; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.attributes.accept; _ck(_v, 17, 0, currVal_6); }); }
exports.View_VoidTicketConfirmDialogComponent_0 = View_VoidTicketConfirmDialogComponent_0;
function View_VoidTicketConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "void-ticket-dialog", [], null, null, null, View_VoidTicketConfirmDialogComponent_0, RenderType_VoidTicketConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.VoidTicketConfirmDialogComponent, [i4.MatDialogRef], null, null)], null, null); }
exports.View_VoidTicketConfirmDialogComponent_Host_0 = View_VoidTicketConfirmDialogComponent_Host_0;
var VoidTicketConfirmDialogComponentNgFactory = i1.ɵccf("void-ticket-dialog", i3.VoidTicketConfirmDialogComponent, View_VoidTicketConfirmDialogComponent_Host_0, {}, {}, []);
exports.VoidTicketConfirmDialogComponentNgFactory = VoidTicketConfirmDialogComponentNgFactory;
