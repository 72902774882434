"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var user_service_1 = require("../users/user.service");
var add_employee_dialog_component_1 = require("./add-employee-dialog.component");
var shared_1 = require("../shared");
var TeamComponent = /** @class */ (function () {
    function TeamComponent(userService, authenticationService, translationService, dialog) {
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'last_name' },
            { key: 'title', title: this.translationService.instant('Title'), sortable: true },
            { key: 'email', title: this.translationService.instant('Email'), sortable: true },
            { key: 'phone', title: this.translationService.instant('Phone Number') },
            { key: 'markets', title: this.translationService.instant('Markets') }
        ];
        this.displayedColumns = [
            'select', 'name', 'title', 'email', 'phone', 'markets'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            service: user_service_1.UserService,
            preferenceKey: 'TeamComponent-UserService',
            query: {},
            collectionTitle: this.translationService.instant('Users'),
            noResultsText: this.translationService.instant('a user'),
            newRecordRoute: ['/users/new'],
            sortBy: 'last_name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
            ]
        };
    }
    TeamComponent.prototype.ngOnInit = function () { };
    TeamComponent.prototype.openDialog = function () {
        var organization = this.authenticationService.getOrganization();
        this.dialog.open(add_employee_dialog_component_1.RuckitAddEmployeeDialogComponent, {
            width: '444px',
            data: { type: 'employee', organizationId: organization && organization.id }
        });
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    TeamComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return TeamComponent;
}());
exports.TeamComponent = TeamComponent;
