"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./not-found.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./not-found.component");
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
exports.RenderType_NotFoundComponent = RenderType_NotFoundComponent;
function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "not-found"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/img/icon-404-barricade.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops! We got lost."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The page you're looking for doesn't exist or couldn't be found."])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Take me back to the home page"]))], null, null); }
exports.View_NotFoundComponent_0 = View_NotFoundComponent_0;
function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i2.NotFoundComponent, [], null, null)], null, null); }
exports.View_NotFoundComponent_Host_0 = View_NotFoundComponent_Host_0;
var NotFoundComponentNgFactory = i1.ɵccf("not-found", i2.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
exports.NotFoundComponentNgFactory = NotFoundComponentNgFactory;
