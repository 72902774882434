import { Component, Input } from '@angular/core';
import { JobEvent } from '../job-events/job-event';

@Component({
  selector: 'mobile-job-overview',
  templateUrl: './mobile-job-overview.component.html',
  styleUrls: ['./mobile-job-overview.component.scss'],
})
export class MobileJobOverviewComponent {
  @Input() jobEvent: JobEvent;
  notesOpen = false;
}
