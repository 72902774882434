"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var invoice_service_1 = require("./invoice.service");
var organization_service_1 = require("../organizations/organization.service");
var api_service_1 = require("../shared/api.service");
var index_1 = require("../shared/dialogs/index");
var EditInvoiceComponent = /** @class */ (function () {
    function EditInvoiceComponent(route, router, invoiceService, organizationService, dialog) {
        this.route = route;
        this.router = router;
        this.invoiceService = invoiceService;
        this.organizationService = organizationService;
        this.dialog = dialog;
        this.loading = true;
        this.editing = false;
        this.errors = [];
    }
    EditInvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.route.params
            .subscribe(function (params) { return _this.getInvoice(params['id']); });
    };
    EditInvoiceComponent.prototype.getInvoice = function (id) {
        var _this = this;
        if (id) {
            this.invoiceService.getInvoice(id).subscribe(function (res) {
                _this.invoice = res;
                var _invoiceDate = _this.invoice.invoiceDate && new Date(_this.invoice.invoiceDate);
                if (_invoiceDate) {
                    _this.invoiceDate = _invoiceDate;
                }
                _this.loading = false;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    EditInvoiceComponent.prototype.updateInvoice = function (update) {
        if (update === void 0) { update = false; }
        if (update) {
            this.getInvoice(this.invoice.id);
        }
    };
    EditInvoiceComponent.prototype.fileChange = function (e) {
        var _this = this;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (_e) {
            _this.invoice.ownerOrganization.image = _e.target['result'];
        };
        reader.readAsDataURL(file);
        this.organizationService.saveOnlyImage(this.invoice.ownerOrganization.id, file).subscribe(function (organization) {
            _this.save();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditInvoiceComponent.prototype.save = function () {
        var _this = this;
        this.loading = true;
        this.invoiceService.save(this.invoice).subscribe(function () {
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditInvoiceComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Send Invoice?',
            body: 'Are you sure you want to send this invoice? You will no longer be able to edit the invoice once it has been sent.',
            close: 'Cancel',
            accept: 'Send'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.invoiceService.save(_this.invoice).subscribe(function () {
                    _this.invoiceService.send(_this.invoice).subscribe(function () {
                        _this.loading = false;
                        _this.router.navigate(['/invoices/filter/sent']);
                    }, function (err) {
                        _this.errors = api_service_1.parseErrors(err);
                        _this.loading = false;
                    });
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            else {
                _this.loading = false;
            }
            _this.confirmDialog = null;
        });
    };
    EditInvoiceComponent.prototype.onDateChanged = function (values) {
        if (values && values.length) {
            this.invoiceDate = values[0];
            this.invoice.invoiceDate = values[0].toISOString();
        }
    };
    EditInvoiceComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
    };
    return EditInvoiceComponent;
}());
exports.EditInvoiceComponent = EditInvoiceComponent;
