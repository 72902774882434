"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Profile = /** @class */ (function () {
    function Profile(profileInfo) {
        profileInfo = camelcaseKeysDeep(profileInfo);
        this.id = profileInfo.id;
        this.firstName = profileInfo.firstName;
        this.lastName = profileInfo.lastName;
        this.name = this.firstName + " " + this.lastName;
        this.email = profileInfo.email;
        this.phoneNumber = profileInfo.phoneNumber;
        this.title = profileInfo.title;
        this.organization = profileInfo.organization;
        this.lastModified = profileInfo.lastModified;
        this.createdAt = profileInfo.created_at;
        this.driverNotificationEmail = profileInfo.driverNotificationEmail;
        this.jobNotificationEmail = profileInfo.jobNotificationEmail;
    }
    return Profile;
}());
exports.Profile = Profile;
