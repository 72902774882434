import * as moment from 'moment';

import { Assignment } from './assignment';
import { DriverSerializer } from './../drivers/driver.serializer';
import { Organization } from '../organizations/organization';
import { Trip } from '../trips/trip';
import { TripStats } from '../trips/trip-stats';
import { PunchCard } from '../punch-cards/punch-card';
import { TruckSerializer } from '../trucks/truck.serializer';
import { JobEvent } from '../job-events/job-event';
import { JobSerializer } from '../jobs/job.serializer';
import { Location } from '../locations/location';
import { LocationSerializer } from '../locations/location.serializer';
import { JobEventShareSerializer } from '../job-event-shares/job-event-share.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class AssignmentSerializer {
  /**
  * @param  {any} json
  * @returns Assignment
  */
  fromJson(json: any): Assignment {
    json = camelcaseKeysDeep(json);
    const assignment = new Assignment();
    if (!json) { return assignment; }

    assignment.id = json.id;
    assignment.dispatched = json.dispatched;
    assignment.dispatchedTime = json.dispatchedTime;

    if (json.truck && typeof (json.truck) === 'object') {
      assignment.truck = (new TruckSerializer().fromJson(json.truck));
    } else {
      assignment.truck = new TruckSerializer().fromJson({ id: json.truck });
    }
    if (json.driver && typeof (json.driver) === 'object') {
      assignment.driver = new DriverSerializer().fromJson(json.driver);
    } else {
      assignment.driver = new DriverSerializer().fromJson({ id: json.driver });
    }
    if (json.jobevent && typeof (json.jobevent) === 'object') {
      assignment.jobevent = new JobEvent(json.jobevent);
    } else {
      assignment.jobevent = new JobEvent({ id: json.jobevent });
    }
    if (json.job && typeof (json.job) === 'object') {
      assignment.job = new JobSerializer().fromJson(json.job);
    } else {
      assignment.job = new JobSerializer().fromJson({ id: json.job });
    }
    if (json.jobeventshare && typeof (json.jobeventshare) === 'object') {
      assignment.jobeventshare = new JobEventShareSerializer().fromJson(json.jobeventshare);
    } else {
      assignment.jobeventshare = new JobEventShareSerializer().fromJson({ id: json.jobeventshare });
    }

    assignment.shift = json.shift;
    assignment.shifts = json.shifts;
    assignment.estimatedNumberOfLoads = json.estimatedNumberOfLoads;
    assignment.estimatedNumberOfTons = json.estimatedNumberOfTons;
    assignment.completed = json.completed;

    if (json.contactOrganization && typeof (json.contactOrganization) === 'object') {
      assignment.contactOrganization = new Organization(json.contactOrganization);
    } else {
      assignment.contactOrganization = new Organization({ id: json.contactOrganization });
    }

    if (json.trips) {
      assignment.trips = json.trips.map(trip => new Trip(trip));
    } else if (json.completedTrips) {
      assignment.trips = json.completedTrips.map(trip => new Trip(trip));
    } else {
      assignment.trips = [];
    }

    if (json.predictedTrips) {
      assignment.predictedTrips = json.predictedTrips.map(trip => new Trip(trip));
    } else {
      assignment.predictedTrips = [];
    }

    if (json.punchcards) {
      assignment.punchCards = json.punchcards.map(punchCard => new PunchCard(punchCard));
    }

    if (json.tripStats) {
      assignment.tripStats = new TripStats(json.tripStats);
    }

    assignment.driverConfirmed = json.driverConfirmed;
    assignment.driverRejectionNote = json.driverRejectionNote;
    if (json.uniqueStart) {
      assignment.uniqueStart = json.uniqueStart;
      assignment.uniqueStartDate = moment(assignment.uniqueStart).toDate();
      assignment.uniqueStartTime = moment(assignment.uniqueStart).format('h:mm A');
    } else if (assignment.jobevent) {
      assignment.uniqueStart = assignment.jobevent.shift1StartTimestamp;
      assignment.uniqueStartDate = moment(assignment.uniqueStart).toDate();
      assignment.uniqueStartTime = moment(assignment.uniqueStart).format('h:mm A');
    }
    if (json.kind === 'job') {
      assignment.numberOfLoadsType = 'allDay';
    } else if (json.kind === 'load') {
      if (json.maxNumberOfLoads === 0 || json.maxNumberOfLoads === null) {
        assignment.numberOfLoadsType = 'allDay';
      } else {
        assignment.numberOfLoadsType = 'numbered';
      }
    } else {
      assignment.numberOfLoadsType = json.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
    }
    assignment.maxNumberOfLoads = json.maxNumberOfLoads === null ? 0 : Number(json.maxNumberOfLoads);
    assignment.numberOfLoadsLeft = json.numberOfLoadsLeft;
    assignment.driverStatus = json.driverStatus;

    let values = ['Estimated:'];
    let estimates = [];
    if (json.estimatedNumberOfLoads !== undefined) { estimates.push(json.estimatedNumberOfLoads + ' loads'); }
    if (json.estimatedNumberOfTons !== undefined) { estimates.push(json.estimatedNumberOfTons + ' tons'); }
    if (estimates.length === 0) { estimates.push('0'); }
    values.push(estimates.filter(function (v) {
      return (v !== '' && v !== null);
    }).join(' | '));

    assignment.assignedEstimate = values.filter(function (v) {
      return (v !== '' && v !== null);
    }).join(' ');

    assignment.confirmed = json.requiresConfirmation && json.driverConfirmed;
    assignment.unconfirmed = json.requiresConfirmation && !json.driverConfirmed;
    assignment.requiresConfirmation = json.driver && json.driver.requireAssignmentConfirmation;
    if (!json.driver || !json.driver.trip) {
      assignment.waiting = true;
    } else if (json.driver && json.driver.trip) {
      assignment.waiting = !json.driver.isActive(json.driver.trip, 'any') ? true : false;
    } else {
      assignment.waiting = true;
    }

    if (assignment.waiting) {
      assignment.tripStatus = 'Waiting';
    } else if (json.driver.isActive(json.driver.trip, 'en-route-one')) {
      assignment.tripStatus = 'Enroute to Loading';
    } else if (json.driver.isActive(json.driver.trip, 'loading')) {
      assignment.tripStatus = 'Loading';
    } else if (json.driver.isActive(json.driver.trip, 'en-route-two')) {
      assignment.tripStatus = 'Enroute to Unloading';
    } else if (json.driver.isActive(json.driver.trip, 'unloading')) {
      assignment.tripStatus = 'Unloading';
    } else {
      assignment.tripStatus = '';
    }

    if (assignment.waiting) {
      assignment.tripStatusTime = null;
    } else if (json.driver.isActive(json.driver.trip, 'en-route-one')) {
      assignment.tripStatusTime = json.driver.trip.loadingEnRouteTime;
    } else if (json.driver.isActive(json.driver.trip, 'loading')) {
      assignment.tripStatusTime = json.driver.trip.loadingTime;
    } else if (json.driver.isActive(json.driver.trip, 'en-route-two')) {
      assignment.tripStatusTime = json.driver.trip.unloadingEnRouteTime;
    } else if (json.driver.isActive(json.driver.trip, 'unloading')) {
      assignment.tripStatusTime = json.driver.trip.unloadingTime;
    }

    assignment.expandableTrips = assignment.trips.length > 0 || false;
    assignment.expandablePredictedTrips = assignment.predictedTrips.length > 0 || false;
    assignment.details = json.details;
    assignment.notes = json.notes;

    if (assignment.jobevent) {
      assignment.jobDisplayName = [
        assignment.jobevent.externalIdentifier,
        assignment.job.name
      ].filter(Boolean).join(': ');
    } else if (assignment.job) {
      assignment.jobDisplayName = assignment.job.name;
    }

    if (json.yardLocation && typeof (json.yardLocation) === 'object') {
      assignment.yardLocation = new LocationSerializer().fromJson(json.yardLocation);
    } else {
      assignment.yardLocation = new LocationSerializer().fromJson({ id: json.yardLocation });
    }
    assignment.yardStartTime = moment(assignment.yardStartTime).toDate();
    assignment.yardLeaveTime = moment(assignment.yardLeaveTime).toDate();
    assignment.yardPrepTime = json.yardPrepTime;
    assignment.yardBufferTime = json.yardBufferTime;
    if (json.yardBufferTime && parseFloat(json.yardBufferTime) > 0) {
      assignment.yardBufferMinutes = parseFloat(json.yardBufferTime) / 60;
    }
    assignment.carrierUniqueBillingId = json.carrierUniqueBillingId;
    assignment.pavers = json.pavers || [];
    assignment.connexCreatedAt = json.connexCreatedAt;
    assignment.connexLastSentAt = json.connexLastSentAt;

    return assignment;
  }

  /**
  * @param  {Assignment} assignment
  * @returns any
  */
  toJson(assignment: Assignment): any {
    let json = {
      id: assignment.id,
      truck: assignment.truck && assignment.truck.id,
      driver: assignment.driver && assignment.driver.id,
      contactOrganization: assignment.contactOrganization && assignment.contactOrganization.id,
      jobevent: assignment.jobevent && assignment.jobevent.id,
      shift: assignment.shift,
      shifts: assignment.shifts,
      dispatched: assignment.dispatched,
      dispatchedTime: assignment.dispatchedTime,
      estimatedNumberOfLoads: assignment.estimatedNumberOfLoads,
      estimatedNumberOfTons: assignment.estimatedNumberOfTons,
      durationTimer: assignment.durationTimer,
      driverConfirmed: assignment.driverConfirmed,
      driverRejectionNote: assignment.driverRejectionNote,
      maxNumberOfLoads: assignment.maxNumberOfLoads,
      numberOfLoadsLeft: assignment.numberOfLoadsLeft,
      completed: assignment.completed,
      driverStatus: assignment.driverStatus,
      notes: assignment.notes,
      uniqueStart: assignment.uniqueStart && moment(assignment.uniqueStart).toISOString(),
      yardBufferTime: (assignment.yardBufferMinutes || 0) * 60
    };
    return decamelizeKeysDeep(json);
  }
}
