<div class="grid-container">
  <div class="section">
    <div class="section-content">
      <div class="header">
        <ng-content select="[header-stats]"></ng-content>
        <span class="name-toggle" *ngIf="displayKeys && displayKeys.length > 1" (click)="toggleDisplayedNames()" translate>Toggle Displayed Names</span>
        <div class="groupby-dropdown" *ngIf="groupByOptions && groupByOptions.length > 1" translate>
          Group By:
          <dropdown #groupByDropdown [options]="groupByOptions" [selectedOption]="activeGroupBy" (onSelect)="selectGroupBy($event)"></dropdown>
        </div>
        <ng-content select="[header-dropdowns]"></ng-content>
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="searchTerm"></search>
      </div>
      <ng-container *ngIf="groupByOptions && groupByOptions.length > 1; else ungrouped">
        <ng-container *ngFor="let group of displayedItemGroups">
          <div class="group" *ngIf="group.items.length">
            <div class="name">{{ group.name || ('Ungrouped' | translate) }}<ng-container *ngIf="group.assigned || group.ordered"> - {{ group.assigned }}/{{ group.ordered }}</ng-container></div>
            <div class="items" *ngIf="!showDriverContextMenu">
              <ng-container *ngFor="let item of group.items">
                <div 
                  class="item-name {{ itemClassNames(item) }}" (click)="selectItem(item.id)"
                  [ngClass]="{ 'selected': isSelected(item.id) }">
                  {{ item[activeDisplayKey] }}
                  <ng-container *ngIf="item.dailyDeliveryTarget"> - {{ item.assignedLoads }}/{{ (item.dailyDeliveryTarget || 0) | number:'1.0-0' }}</ng-container>
                </div>
              </ng-container>
            </div>
            <div class="items" *ngIf="showDriverContextMenu">
              <ng-container *ngFor="let item of group.items">
                <div 
                  class="item-name {{ itemClassNames(item) }}" (click)="selectItem(item.id)"
                  (contextmenu)="openContextMenu($event, item.id)"
                  [ngClass]="{ 'selected': isSelected(item.id) }">
                  {{ item[activeDisplayKey] }}
                  <ng-container *ngIf="item.dailyDeliveryTarget"> - {{ item.assignedLoads }}/{{ (item.dailyDeliveryTarget || 0) | number:'1.0-0' }}</ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #ungrouped>
        <ng-container *ngFor="let group of displayedItemGroups">
          <div class="group ungrouped" *ngIf="group.items.length">
            <div class="items">
              <ng-container *ngFor="let item of group.items">
                <div 
                  class="item-name {{ itemClassNames(item) }}" (click)="selectItem(item.id)"
                  [ngClass]="{ 'selected': isSelected(item.id) }">
                  {{ item[activeDisplayKey] }}
                  <ng-container *ngIf="item.dailyDeliveryTarget"> - {{ item.assignedLoads }}/{{ (item.dailyDeliveryTarget || 0) | number:'1.0-0' }}</ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>

<!-- driver context menu -->
<ng-container *ngIf="showDriverContextMenu">
  <app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>
</ng-container>