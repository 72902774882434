import { Shift } from './shift';
import { SlotSerializer } from './slot.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ShiftSerializer {
  /**
  * @param  {any} json
  * @returns Shift
  */
  fromJson(json: any): Shift {
    json = camelcaseKeysDeep(json);
    const shift = new Shift();
    if (!json) { return shift; }

    shift.id = json.id;
    if (json.slots) {
      shift.slots = json.slots.map(slot => new SlotSerializer().fromJson(slot));
    }

    return shift;
  }

  /**
  * @param  {Shift} shift
  * @returns any
  */
  toJson(shift: Shift): any {
    let json = {
      id: shift.id,
      slots: shift.slots
    };
    return decamelizeKeysDeep(json);
  }
}
