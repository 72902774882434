"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var shared_1 = require("../../../shared");
var BulkEditDialogComponent = /** @class */ (function () {
    function BulkEditDialogComponent(dialog, dialogRef, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.errors = [];
        this.changes = {
            duration: 0,
            startTime: '',
            endTime: '',
            payStart: '',
            payEnd: ''
        };
        this.payDropdownConfig = { nameProperty: 'name' };
        this.payStartOptions = [
            { value: 'scheduled-start', name: 'Scheduled Start' },
            { value: 'shift-start', name: 'Shift Start' },
            { value: 'geofence-start', name: 'Geofence Start' },
            { value: 'first-load', name: 'First Load' }
        ];
        this.payEndOptions = [
            { value: 'shift-end', name: 'Shift End' },
            { value: 'geofence-end', name: 'Geofence End' },
            { value: 'last-load', name: 'Last Load' }
        ];
    }
    BulkEditDialogComponent.prototype.submit = function () {
        this.callback(this.changes);
        this.dialogRef.close();
    };
    return BulkEditDialogComponent;
}());
exports.BulkEditDialogComponent = BulkEditDialogComponent;
