"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Unit = /** @class */ (function () {
    function Unit(data) {
        data = camelcaseKeysDeep(data);
        this.id = data.id;
        this.name = data.name;
    }
    return Unit;
}());
exports.Unit = Unit;
