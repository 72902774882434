import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { ExpensesComponent } from './expenses.component';
import { EditExpenseComponent } from './edit-expense.component';

const routes: Routes = [
  { path: '', component: ExpensesComponent, canActivate: [AuthGuard] },
  {
    path: 'filter/:state',
    component: ExpensesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: ':id/edit',
    component: EditExpenseComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpensesRoutingModule {}
