import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({ name: 'propertyFilter', pure: false })

export class PropertyFilterPipe implements PipeTransform {
  transform(objects: any, property: string, value: string): any {
    if (!objects || !objects.length || !property || (typeof (value) !== 'boolean' && !value)) {
      return objects;
    }
    let query = value && value.toLowerCase();

    return objects.filter(obj => {
      let result = true;

      if (obj.hasOwnProperty(property)) {
        let prop = obj[property].toString().toLowerCase();
        if (prop) {
          result = prop.toString().toLowerCase().indexOf(query) > -1;
        }
      } else {
        let prop = get(obj, property, null);
        if (prop) {
          result = prop.toString().toLowerCase().indexOf(query) > -1;
        }
      }
      return result;
    });
  }
}
