import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

// services
import { DriverService } from '../driver.service';

// models
import { Driver } from '../driver';

@Component({
  selector: 'ruckit-driver-details-page',
  templateUrl: './driver-details-page.page.html',
  styleUrls: ['./driver-details-page.page.scss'],
})
export class DriverDetailsPage implements OnInit {
  driver$: Observable<Driver>;
  selectedTab: string;
  error$ = new Subject<boolean>();
  returnUrl: string;
  tabChange = false;

  constructor(
    private driverService: DriverService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.selectedTab = params.tab ? params.tab : 'details';
      if (this.tabChange) {
        const oldQueryParams = { ...this.activatedRoute.snapshot.queryParams };
        if (oldQueryParams.page) {
          const queryParams: Params = { returnTo: this.returnUrl };
          this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
          });
        }
      } else {
        this.tabChange = true;
      }
    });
    const driverId: string = this.activatedRoute.snapshot.params.id;
    this.getDriver(driverId);
    this.activatedRoute.queryParams.subscribe(
      (params) =>
        (this.returnUrl = this.returnUrl
          ? this.returnUrl
          : params.returnTo || '/')
    );
  }

  getDriver(driverId: string) {
    this.driver$ = this.driverService.get(driverId).pipe(
      catchError(() => {
        this.error$.next(true);
        return of(null);
      })
    );
  }

  updatedMarkets() {}
}
