<div class="address-context" *ngIf="addresses.length > 1" (click)="showAddresses()">
  <mat-icon matBadge="{{ addresses.length }}" matBadgeColor="accent">
    format_list_numbered_rtl
  </mat-icon>
</div>

<div class="address-list" *ngIf="showingAddresses">
  <ul>
    <li *ngFor='let address of addresses' (click)='addressSelected(address)'
        class="{{ selectedAddress === address ? 'selected' : '' }}">
      <i class='fa fa-circle-thin select-icon'></i>
      <h3 class='item-name'>{{ address.displayName }}</h3>
    </li>
  </ul>
</div>
