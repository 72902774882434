"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var shift_1 = require("./shift");
var driver_serializer_1 = require("../drivers/driver.serializer");
var truck_serializer_1 = require("../trucks/truck.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ShiftSerializer = /** @class */ (function () {
    function ShiftSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Shift
    */
    ShiftSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var shift = new shift_1.Shift();
        if (!json) {
            return shift;
        }
        shift.id = json.id;
        shift.driver = new driver_serializer_1.DriverSerializer().fromJson(json.driver);
        shift.truck = new truck_serializer_1.TruckSerializer().fromJson(json.truck);
        shift.startTime = json.startTime;
        shift.endTime = json.endTime;
        // json.adjustment is returned as `00.0`, we'll convert this to minutes
        shift.adjustment = Number(json.adjustment) / 60;
        shift.haulTotal = json.haulTotal;
        shift.jobeventsCount = json.jobeventsCount;
        shift.tripsCount = json.tripsCount;
        if (json.totalHours) {
            shift.totalHours = json.totalHours;
        }
        else {
            shift.totalHours = moment().diff(json.startTime, 'hours', true);
        }
        shift.totalTons = json.totalTons;
        shift.autoClosed = json.autoClosed;
        return shift;
    };
    /**
     * @param  {Shift} Shift
     * @returns any
     */
    ShiftSerializer.prototype.toJson = function (shift) {
        // need to format our seconds into the server's expected `00.0` format
        var adjustment = shift.adjustment ? (shift.adjustment * 60) + '.0' : undefined;
        var json = {
            id: shift.id,
            driver: shift.driver,
            truck: shift.truck,
            startTime: shift.startTime,
            endTime: shift.endTime,
            adjustment: adjustment,
            haulTotal: shift.haulTotal,
            jobeventsCount: shift.jobeventsCount,
            tripsCount: shift.tripsCount,
            totalHours: shift.totalHours,
            totalTons: shift.totalTons
        };
        return decamelizeKeysDeep(json);
    };
    return ShiftSerializer;
}());
exports.ShiftSerializer = ShiftSerializer;
