<div class="app-content" [ngClass]="{grid: displayAsGrid}">
  <my-toolbar class="desktop-visible" title="{{ 'Jobs' | translate }}" (updatedMarkets)="updatedMarkets()"></my-toolbar>
  <my-toolbar class="mobile-visible" title="{{ 'Active Jobs' | translate }} - {{ jobEventDate | date: 'MM/dd/yyyy' }}" (updatedMarkets)="dailyBoardGrid.getJobs()"></my-toolbar>

  <action-bar *ngIf="!device.mobile">
    <div [style.width.%]="100">
      <jobs-header></jobs-header>
    </div>
    <div class="left-actions">
      <div *ngIf="!carrierMode" class="segmented-buttons">
        <a (click)="switchView('grid')" class="btn btn-default" [ngClass]="{active: view === 'grid'}">
          <img src="assets/img/icon-grid.svg">
        </a>
        <a (click)="switchView('list')" class="btn btn-default" [ngClass]="{active: view === 'list'}">
          <i class="icon-rows"></i>
        </a>
      </div>
      <date-range-picker [selectedDates]="[jobEventDate]"
                         (dateChanged)="onDateChanged($event)"></date-range-picker>
      <button *ngIf="view === 'grid'" class="btn btn-default btn-filters"
              (click)="openFilters()"
              [ngClass]="{active: filters.length}">
        <i class="icon-filter"></i>
        <ng-container translate>Filters</ng-container>
      </button>
      <div *ngIf="!carrierMode" class="cancelled">
        <input type="checkbox" id="hide_cancelled" [(ngModel)]="hideCancelled" (change)="toggleCancelled()" />
        <label attr.data-label="{{ 'Hide Cancelled' | translate }}" for="hide_cancelled"></label>
      </div>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
     
      <button class="btn btn-default" (click)="exportAssignments()" translate>
        Export Assignments
      </button>

      <button [routerLink]="['/jobs/new']"
              [queryParams]="{returnTo: router.url}"
              id="daily-board-no-results-new-job-button"
              class="btn btn-primary new-job" *ngIf="canCreateJobs">
        <span class="icon-plus"></span>
        {{ 'New Job' | translate }}
      </button>
    </div>
  </action-bar>

  <div class="filters" *ngIf="view === 'grid' && filters && filters.length">
    <ng-container *ngIf="!loading">{{ dailyBoardGrid?.count }} {{ 'Results' | translate }}</ng-container>
    <ng-container *ngIf="loading">{{ 'Loading...' | translate }}</ng-container>
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters">
      {{ filter.key | titleCase }}: {{ (filter.value && (filter.value.name || filter.value.organizationName)) || filter.value  }}
      <i class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <ruckit-job-stats *ngIf="enabledFeatures.includes('hasCollaboratorStats') && (device.desktop || device.tablet)"
    [jobEventDate]="jobEventDate" [hideCancelled]="hideCancelled"></ruckit-job-stats>

  <ng-container *ngIf="(table === 'job-stats' || device.mobile)">
    <date-range-picker [selectedDates]="[jobEventDate]" *ngIf="device.mobile" [config]="{ mobile: true }"
                         (dateChanged)="onDateChanged($event)"></date-range-picker>
    <div class="search-container {{ loading ? 'loading' : '' }}" *ngIf="device.mobile">
      <search (searchTermChange)="changeSearch($event)" [searchTerm]="search"></search>
    </div>

    <!-- daily board grid view -->
    <div class="daily-board-grid" *ngIf="view === 'grid' && !loading">
      <ruckit-daily-board-grid 
        #dailyBoardGrid 
        [jobEventDate] ="jobEventDate"
        [hideCancelled]="hideCancelled" 
        [filters]="filters" 
        [sortBy]="sortBy"
        [search]="search">
      </ruckit-daily-board-grid>
    </div>

    <!-- daily board list view -->
    <div class="daily-board-list" *ngIf="view === 'list' && !loading">
      <ruckit-daily-board-list 
        #dailyBoardList
        [withJobStats]="enabledFeatures.includes('hasCollaboratorStats')"
        [jobEventDate]="jobEventDate" 
        [hideCancelled]="hideCancelled"
        [search]="search" 
        (searchReset)="changeSearch('')">
      </ruckit-daily-board-list>
    </div>
  </ng-container>

  <ng-container *ngIf="table === 'collaborator'">
    <div class="daily-board-collaborator-stats" *ngIf="view === 'list' && !loading">
      <ruckit-collaborator-stats #collaboratorStats
        [jobEventDate]="jobEventDate" [hideCancelled]="hideCancelled"
        [search]="search"></ruckit-collaborator-stats>
    </div>
  </ng-container>
</div>
