import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { WorkOrdersRoutingModule } from './work-orders-routing.module';
import { WorkOrderTemplatesComponent } from './work-order-templates/work-order-templates.component';
import { WorkOrdersComponent } from './work-orders.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WorkOrdersRoutingModule
  ],
  declarations: [
    WorkOrderTemplatesComponent,
    WorkOrdersComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class WorkOrdersModule { }
