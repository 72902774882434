import { Component, OnInit, Input } from '@angular/core';


import { JobEventStatService } from '../job-event-stats/job-event-stat.service';

@Component({
  selector: 'overall-job-production',
  templateUrl: './overall-job-production.component.html',
  styleUrls: ['./overall-job-production.component.scss'],
  providers: [JobEventStatService]
})
export class OverallJobProductionComponent implements OnInit {
  @Input() job: any;
  errors = [];

  constructor(
    private jobEventStatService: JobEventStatService
  ) { }

  ngOnInit() {
    this.getJobStats();
  }

  getJobStats() {
    if (this.job) {
      this.jobEventStatService.getJobStats(this.job.id).subscribe(
        stats => this.job.stats = stats,
        err => console.error(err)
      );
    }
  }
}
