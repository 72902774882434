"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var project_service_1 = require("../projects/project.service");
var api_service_1 = require("../shared/api.service");
var MarketplaceNewProjectDialogComponent = /** @class */ (function () {
    function MarketplaceNewProjectDialogComponent(dialogRef, projectService, dialog) {
        this.dialogRef = dialogRef;
        this.projectService = projectService;
        this.dialog = dialog;
        this.loading = false;
        this.model = {
            customerOrganization: '',
            name: ''
        };
        this.errors = [];
    }
    MarketplaceNewProjectDialogComponent.prototype.ngOnInit = function () { };
    MarketplaceNewProjectDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.projectService.save(this.model).subscribe(function (project) {
            _this.dialogRef.close();
            _this.callback(project);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return MarketplaceNewProjectDialogComponent;
}());
exports.MarketplaceNewProjectDialogComponent = MarketplaceNewProjectDialogComponent;
