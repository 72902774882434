"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var truck_type_serializer_1 = require("../trucks/truck-type.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Multiplier = /** @class */ (function () {
    function Multiplier(rateInfo) {
        this.remove = false;
        rateInfo = camelcaseKeysDeep(rateInfo);
        this.id = rateInfo.id;
        this.truckType = rateInfo.truckType;
        this.rateType = rateInfo.rateType;
        this.haulPercentage = rateInfo.haulPercentage;
        this.truckTypeName = rateInfo.truckTypeName;
        if (typeof rateInfo.truckType === 'object') {
            this.truckType = (new truck_type_serializer_1.TruckTypeSerializer().fromJson(rateInfo.truckType));
        }
        else {
            this.truckType = (new truck_type_serializer_1.TruckTypeSerializer().fromJson({ id: rateInfo.truckType }));
        }
        this.connection = rateInfo.connection;
        this.haulPercentage = rateInfo.haulPercentage;
        this.createdAt = rateInfo.createdAt;
        this.lastModified = rateInfo.lastModified;
        if (this.truckType && this.truckType.id) {
            this.selectedTruckType = { id: this.truckType.id, name: this.truckType.name };
        }
        else {
            this.selectedTruckType = null;
        }
    }
    return Multiplier;
}());
exports.Multiplier = Multiplier;
