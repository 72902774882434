<notification *ngFor="let error of errors" translate>{{error}}</notification>

<div class="app-content fleet-container">
  <my-toolbar title="{{ 'Fleet Trackables' | titlecase | translate }}"></my-toolbar>

  <ruckit-edit-trackable #editDrawer [trackable]='trackable' (completed)="onEditComplete($event)" (closed)="onClose()"></ruckit-edit-trackable>

  <div class="container trackables-container">
    <div class="actions-wrapper">
      <fancy-table #trackablesTable
                  [(availableColumns)]="availableColumns"
                  [(displayedColumns)]="displayedColumns"
                  [(filters)]="appliedFilters"
                  [(search)]="search"
                  [config]="tableConfig"
                  [(query)]="query"
                  [customClasses]="customClasses"
                  (onClickAction)="clickAction($event)"
                  (columnChange)="columnsChanged($event)"
                  (onDataLoaded)="onDataLoaded($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value trackable-type" *ngSwitchCase="'trackable-type'">
              <div class="data">
                {{ row.trackableType && row.trackableType.name }}
              </div>
            </div>
            <div class="value" *ngSwitchCase="'device-id'">
              <div class="data"> {{ row.deviceId || '&nbsp;' }}</div>
            </div>
            <div class="value truck" *ngSwitchCase="'truck'">
              <div class="data">
                {{ row.truck && row.truck.name }}
              </div>
            </div>
            <div class="value" *ngSwitchCase="'active'">
              <div class="data"> {{ (row.active ? 'Yes' : 'No' | translate) || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'device-state'">
              <div class="data"> {{ row.deviceState || '&nbsp;' }}</div>
            </div>
            <div class="value position-source" *ngSwitchCase="'position-source'">
              <div class="data"> {{ row.positionSource || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'hdop'">
              <div class="data"> {{ row.hdop || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'rssi'">
              <div class="data"> {{ row.rssi || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'voltage'">
              <div class="data"> {{ row.voltage || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'heading'">
              <div class="data"> {{ row.heading || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'speed'">
              <div class="data"> {{ row.speed || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'accuracy'">
              <div class="data"> {{ row.accuracy || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'temperature'">
              <div class="data"> {{ row.temperature || '&nbsp;' }}</div>
            </div>
            <div class="value last-status-event" *ngSwitchCase="'last-status-event'">
              <div class="data">
                {{ row.lastStatusEvent | date:"MM/dd/yyyy" || '&nbsp;' }}
                <br />
                {{ row.lastStatusEvent | date:"h:mm a" || '&nbsp;' }}
              </div>
            </div>
            <div class="value location" *ngSwitchCase="'location'">
              <div class="data" *ngIf="row.location && row.location['coordinates']">
                {{ row.location['coordinates'][0] }}
                <br/>
                {{ row.location['coordinates'][1] }}
              </div>
            </div>
            <div class="value" *ngSwitchCase="'action'">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of row.filterOptions(tableConfig.menuOptions)">
                  <a *ngIf="option.link" mat-menu-item [routerLink]="row.url(option.action)">
                    {{ option.name }}
                  </a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Trackables</div>
        </div>
        <div applied-filter-content>
          <applied-filters #appliedFiltersDisplay
                  [(filters)]="appliedFilters"
                  [(search)]="search"
                  (filtersChange)="appliedFilters=$event">
          </applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle #columnToggle
                      [(availableColumns)]="availableColumns"
                      [(displayedColumns)]="displayedColumns">
        </column-toggle>
        <filters-panel [(appliedFilters)]="appliedFilters"
                      [customFilter]="true"
                      (openCustomFilter)="openFilters()"
                      [search]="search" (searchChange)="search = $event"></filters-panel>
        <!-- add new trackable dialog-->
        <a mat-icon-button color="accent" class="add-button" (click)="addTrackable()">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
