"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".punch-card-form[_ngcontent-%COMP%]{overflow:hidden;height:100%}.punch-card-form[_ngcontent-%COMP%]   .end-date[_ngcontent-%COMP%], .punch-card-form[_ngcontent-%COMP%]   .start-date[_ngcontent-%COMP%]{margin-right:20px}.punch-card-form[_ngcontent-%COMP%]   .timepicker-wrapper[_ngcontent-%COMP%]{height:38px}.punch-card-form[_ngcontent-%COMP%]   .pickers[_ngcontent-%COMP%]{display:flex;flex:1 1 auto}.punch-card-form[_ngcontent-%COMP%]   .time[_ngcontent-%COMP%]{flex-flow:row wrap;display:flex}.punch-card-form[_ngcontent-%COMP%]   .time[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{width:100%}.punch-card-form[_ngcontent-%COMP%]   .time[_ngcontent-%COMP%]   .date-wrapper[_ngcontent-%COMP%]{flex:1 1 auto;margin-right:20px;width:1px}.punch-card-form[_ngcontent-%COMP%]   .time[_ngcontent-%COMP%]   .timepicker-wrapper[_ngcontent-%COMP%]{width:50%}.punch-card-container[_ngcontent-%COMP%]{margin-top:-20px;overflow-y:auto;height:calc(100% - 146px);padding:30px}.punch-card-data[_ngcontent-%COMP%]{display:flex;flex-direction:column;width:430px}.punch-card-data[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#7f8fa4;margin-bottom:10px}.punch-card-data[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354052}.upload-image-dropzone[_ngcontent-%COMP%]{margin-bottom:10px}.upload-image-dropzone[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{border-radius:4px}"];
exports.styles = styles;
