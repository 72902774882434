"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var purchase_order_serializer_1 = require("./purchase-order.serializer");
var purchase_order_service_1 = require("./purchase-order.service");
var location_service_1 = require("../locations/location.service");
var product_service_1 = require("../products/product.service");
var price_list_service_1 = require("../price-lists/price-list.service");
var organization_service_1 = require("../organizations/organization.service");
var authentication_service_1 = require("../shared/authentication.service");
var new_location_dialog_component_1 = require("../locations/new-location-dialog.component");
var api_service_1 = require("../shared/api.service");
var PurchaseOrderComponent = /** @class */ (function () {
    function PurchaseOrderComponent(route, purchaseOrderService, priceListService, locationService, organizationService, authenticationService, productService, router, dialog, formBuilder) {
        this.route = route;
        this.purchaseOrderService = purchaseOrderService;
        this.priceListService = priceListService;
        this.locationService = locationService;
        this.organizationService = organizationService;
        this.authenticationService = authenticationService;
        this.productService = productService;
        this.router = router;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.purchaseOrder = new purchase_order_serializer_1.PurchaseOrderSerializer().fromJson({});
        this.loading = false;
        this.errors = [];
        this.addLocation = {
            name: 'Add a New Address',
            id: 'new-location',
            button: true
        };
        this.locations = [this.addLocation];
        this.locationsDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.searchChanged = new rxjs_1.Subject();
        this.customers = [];
        this.customersDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.products = [];
        this.productsDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.priceLists = [];
        this.priceListsDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
    }
    PurchaseOrderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.getPurchaseOrder(params['id']);
        });
        this.getLocations();
        this.locationService.getLocationByIP().subscribe(function (res) {
            _this.defaultLocation = res;
        });
        this.getCustomers();
        this.getProducts();
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (data) {
            _this.dropdownSearch(data[0], data[1]);
        });
    };
    PurchaseOrderComponent.prototype.ngOnDestroy = function () { };
    PurchaseOrderComponent.prototype.getPurchaseOrder = function (purchaseOrderId) {
        var _this = this;
        if (purchaseOrderId) {
            this.purchaseOrderService.get(purchaseOrderId).subscribe(function (res) {
                _this.purchaseOrder = res;
                if (_this.purchaseOrder.customerOrganization) {
                    _this.selectedCustomer = _this.purchaseOrder.customerOrganization;
                    var customer = lodash_1.find(_this.customers, { id: _this.selectedCustomer.Id });
                    if (!customer) {
                        _this.customers.unshift(_this.selectedCustomer);
                    }
                }
                if (_this.purchaseOrder.product) {
                    _this.selectedProduct = _this.purchaseOrder.product;
                    var product = lodash_1.find(_this.products, { id: _this.selectedProduct.Id });
                    if (!product) {
                        _this.products.unshift(_this.selectedProduct);
                    }
                    if (_this.purchaseOrder.customPrice) {
                        _this.selectedPriceList = _this.purchaseOrder.customPrice.priceList;
                    }
                    _this.getPriceLists();
                }
                if (_this.purchaseOrder.deliveryLocation) {
                    _this.selectedLocation = _this.purchaseOrder.deliveryLocation;
                    var location_1 = lodash_1.find(_this.locations, { id: _this.selectedLocation.Id });
                    if (!location_1) {
                        if (_this.selectedLocation.name) {
                            _this.selectedLocation.name = _this.selectedLocation.name + ' - ' + _this.selectedLocation.street;
                            _this.locations.push(_this.selectedLocation);
                        }
                        else {
                            _this.selectedLocation = null;
                        }
                    }
                }
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    PurchaseOrderComponent.prototype.getLocations = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.locationsReq) {
            this.locationsReq.unsubscribe();
        }
        this.locationsReq = this.locationService.list(__assign({ archived: 'False' }, query)).subscribe(function (res) {
            res = res.map(function (loc) {
                loc['name'] = loc.name + ' - ' + loc.street;
                return loc;
            });
            if (append) {
                _this.locations = _this.locations.concat(res);
            }
            else {
                _this.locations = [_this.addLocation].concat(res);
            }
        });
    };
    PurchaseOrderComponent.prototype.changeLocation = function (location) {
        var _this = this;
        if (location['id'] === 'new-location') {
            location.selected = false;
            var dialog = this.dialog.open(new_location_dialog_component_1.NewLocationDialogComponent, {
                width: '100%',
                height: '100%',
                disableClose: true
            });
            dialog.componentInstance.callback = function (newLocation) {
                newLocation.name = newLocation.street ? (newLocation.name + ' - ' + newLocation.street) : newLocation.name;
                _this.locations.forEach(function (_location) {
                    _location['selected'] = false;
                });
                newLocation.selected = true;
                _this.locations.push(newLocation);
                var _newLocation = lodash_1.clone(newLocation);
                _newLocation.selected = false;
                _this.purchaseOrder.deliveryLocation = newLocation;
                _this.selectedLocation = newLocation;
            };
        }
        else {
            this.purchaseOrder.deliveryLocation = location;
            this.selectedLocation = location;
        }
    };
    PurchaseOrderComponent.prototype.getCoord = function (index) {
        var defaultCoords = [
            lodash_1.get(this.defaultLocation, 'longitude', 30.2178214),
            lodash_1.get(this.defaultLocation, 'latitude', -97.6023238)
        ];
        return lodash_1.get(this.purchaseOrder, 'deliveryLocation.location.coordinates[' + index + ']', defaultCoords[index]);
    };
    PurchaseOrderComponent.prototype.delaySearch = function (term, type) {
        this.searchChanged.next([term, type]);
    };
    PurchaseOrderComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'customer':
                this.getCustomers({ search: term });
                break;
            case 'product':
                this.getProducts({ search: term });
                break;
            case 'location':
                this.getLocations({ search: term, archived: 'False' });
                break;
            case 'priceList':
                this.getPriceLists({ search: term });
                break;
        }
    };
    PurchaseOrderComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'customer':
                config = this.customersDropdownConfig;
                service = this.organizationService;
                options = this.customers;
                break;
            case 'product':
                config = this.productsDropdownConfig;
                service = this.productService;
                options = this.products;
                break;
            case 'location':
                config = this.locationsDropdownConfig;
                service = this.locationService;
                options = this.locations;
                break;
            case 'priceList':
                config = this.priceListsDropdownConfig;
                service = this.priceListService;
                options = this.priceLists;
        }
        if (!config.loadingOptions) {
            var o = void 0;
            if (type === 'customer' || type === 'product' || type === 'location') {
                o = service && service.listNext();
            }
            else {
                o = service && service.getNext();
            }
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'customer':
                            _this.customers = _this.customers.concat(results);
                            break;
                        case 'product':
                            _this.products = _this.products.concat(results);
                            break;
                        case 'location':
                            _this.locations = _this.locations.concat(results);
                            break;
                        case 'priceList':
                            _this.priceLists = _this.priceLists.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    PurchaseOrderComponent.prototype.getCustomers = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.customersReq) {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.organizationService.get(__assign({}, query)).subscribe(function (customers) {
            if (append) {
                _this.customers = _this.customers.concat(customers);
            }
            else {
                _this.customers = customers;
            }
        });
    };
    PurchaseOrderComponent.prototype.customerSelected = function (customer) {
        this.purchaseOrder.customerOrganization = customer;
        this.selectedCustomer = customer;
    };
    PurchaseOrderComponent.prototype.getProducts = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.productsReq) {
            this.productsReq.unsubscribe();
        }
        this.productService.list(__assign({}, query)).subscribe(function (products) {
            if (append) {
                _this.products = _this.products.concat(products);
            }
            else {
                _this.products = products;
            }
        });
    };
    PurchaseOrderComponent.prototype.productSelected = function (product) {
        this.purchaseOrder.product = product;
        this.selectedPriceList = null;
        this.purchaseOrder.customPrice = null;
        this.selectedProduct = product;
        this.purchaseOrder.unitOfMeasurement = product.unitOfMeasurement;
        this.getPriceLists();
    };
    PurchaseOrderComponent.prototype.getPriceLists = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.priceListsReq) {
            this.priceListsReq.unsubscribe();
        }
        this.priceListsReq = this.priceListService.list(__assign({ prices__product: this.selectedProduct && this.selectedProduct.id }, query)).subscribe(function (priceLists) {
            _this.priceLists = [{ id: null, name: '-' }].concat(priceLists);
            if (append) {
                _this.priceLists = _this.priceLists.concat(priceLists);
            }
            else {
                _this.priceLists = [{ id: null, name: '-' }].concat(priceLists);
            }
        });
    };
    PurchaseOrderComponent.prototype.priceListSelected = function (priceList) {
        this.selectedPriceList = priceList;
        var price = lodash_1.find(priceList.prices, { product: { id: this.selectedProduct.id } });
        if (price) {
            this.purchaseOrder.customPrice = price;
        }
        else {
            this.purchaseOrder.customPrice = null;
        }
    };
    PurchaseOrderComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.purchaseOrder.sellerOrganization = this.authenticationService.getOrganization();
        this.purchaseOrderService.save(this.purchaseOrder).subscribe(function (res) {
            _this.loading = false;
            _this.router.navigate(['/purchase-orders']);
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    return PurchaseOrderComponent;
}());
exports.PurchaseOrderComponent = PurchaseOrderComponent;
