import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from './../../shared/shared.module';
import { TripHistoryRoutingModule } from './trip-history-routing.module';
import { TripHistoryComponent } from './trip-history.component';
import { TripHistoryTicketComponent } from './trip-history-ticket/trip-history-ticket.component';
import { TripHistoryWidgetComponent } from './trip-history-widget/trip-history-widget.component';
import { TripHistoryBillingComponent } from './trip-history-billing/trip-history-billing.component';
import { TripHistoryRouteComponent } from './trip-history-route/trip-history-route.component';


@NgModule({
  imports: [CommonModule, SharedModule, TripHistoryRoutingModule],
  declarations: [
    TripHistoryComponent,
    TripHistoryTicketComponent,
    TripHistoryWidgetComponent,
    TripHistoryBillingComponent,
    TripHistoryRouteComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class TripHistoryModule {}
