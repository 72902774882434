'use strict';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss'],
})
export class MenuLinkComponent {
  @Input() section: {
    url: string;
    icon: string;
    badge: string;
    name: string;
    type: string;
  };
  @Input() isSelected = false;
  @Input() external = false;
  @Output() menuClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  focusSection(event) {
    event.preventDefault();
    // set flag to be used later when
    // $locationChangeSuccess calls openPage()
    // controller.autoFocusContent = true;
  }

  onMenuClick() {
    this.menuClick.emit(this.section);
  }
}
