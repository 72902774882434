"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var resource_service_1 = require("../shared/resource.service");
var condensed_ticket_serializer_1 = require("./condensed-ticket.serializer");
var lodash_1 = require("lodash");
var operators_1 = require("rxjs/operators");
var CondensedTicketService = /** @class */ (function (_super) {
    __extends(CondensedTicketService, _super);
    function CondensedTicketService(http) {
        var _this = _super.call(this, http, 'scaletickets/condensed/', new condensed_ticket_serializer_1.CondensedTicketSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'scaletickets/condensed/';
        return _this;
    }
    CondensedTicketService.prototype.saveTicket = function (model) {
        var resourceUrl = environment_1.environment.serverUrl + 'scaletickets/';
        model = lodash_1.clone(model);
        model = new condensed_ticket_serializer_1.CondensedTicketSerializer().toJson(model);
        if (!model.id) {
            return this.http.post(resourceUrl, model, {
                headers: this.requestHeaders()
            }).pipe(operators_1.map(function (res) {
                try {
                    res = res;
                }
                catch (err) {
                }
                return new condensed_ticket_serializer_1.CondensedTicketSerializer().fromJson(res);
            }));
        }
        else {
            return this.http.put("" + resourceUrl + model.id + "/", model, {
                headers: this.requestHeaders()
            }).pipe(operators_1.map(function (res) {
                try {
                    res = res;
                }
                catch (err) { }
                return new condensed_ticket_serializer_1.CondensedTicketSerializer().fromJson(res);
            }));
        }
    };
    return CondensedTicketService;
}(resource_service_1.ResourceService));
exports.CondensedTicketService = CondensedTicketService;
