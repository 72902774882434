"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var purchase_order_filter_1 = require("./purchase-order.filter");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PurchaseOrderFilterSerializer = /** @class */ (function () {
    function PurchaseOrderFilterSerializer() {
    }
    PurchaseOrderFilterSerializer.prototype.fromJson = function (filterInfo) {
        filterInfo = camelcaseKeysDeep(filterInfo);
        var resource = new purchase_order_filter_1.PurchaseOrderFilter();
        if (!filterInfo) {
            return resource;
        }
        resource.id = filterInfo.id;
        resource.name = filterInfo.name;
        return resource;
    };
    PurchaseOrderFilterSerializer.prototype.toJson = function (filter) {
        var json = {
            id: filter.id,
            name: filter.name
        };
        return decamelizeKeysDeep(json);
    };
    return PurchaseOrderFilterSerializer;
}());
exports.PurchaseOrderFilterSerializer = PurchaseOrderFilterSerializer;
