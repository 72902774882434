<div class="driver-infobox">
  <div class="truck-photo">
    <div class="image">
      <img *ngIf="driver.truck && driver.truck.image" [src]="driver.truck.image" />
      <i *ngIf="!driver.truck || !driver.truck.image" class="icon-fleet"></i>
    </div>
  </div>
  <div class="details" (contextmenu)="openContextMenu($event, driver.id)">
    <div class="name">{{ driver.name }}</div>
    <div class="type" *ngIf="driver.truck">{{ driver.truck.name }} {{ driver.truck.truckType.name }}</div>
    <div class="phone" *ngIf="driver.profile">{{ driver.profile.phoneNumber | telephone }}</div>
    <div class="carrier" *ngIf="driver.carrier">{{ driver.carrier.name }}</div>
    <div class="created-at">{{ createdAt | date: 'M/d/yy h:mm a' }}</div>
  </div>
  <a *ngIf="date" class="replay-link" routerLinkActive="active" [routerLink]="['/replay']"
     [queryParams]="{ date: date.toISOString() | moment: 'YYYYMMDD', driver: driver.id }"
     translate>View Replay</a>
  <a class="replay-link" routerLinkActive="active" [routerLink]="['/fleet-health']"
     [queryParams]="{ search: driver.name }"
     translate>Fleet Health</a>
</div>

<app-driver-context-menu 
  [contextMenuEventSubject]="contextMenuEventSubject" 
  [useDefaultCoordinates]="true">
</app-driver-context-menu>
