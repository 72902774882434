import { clone } from 'lodash';

import { Trip } from '../trips/trip';
import { Assignment } from './assignment';
import { Truck } from '../trucks/truck';
import { Location } from '../locations/location';

export class DriverAssignment {
  id: string;
  name: string;
  organization: string;
  organizationId: string;
  activeJobs: any[];
  latestTruck: Truck;
  assignments: Assignment[];
  assignmentCount = 0;
  hasActiveShift: boolean;
  activeTrips: Trip[];
  latestTrip: Trip;
  trips: Trip[];
  activeGeoTrips: Trip[];
  latestGeoTrip: Trip;
  geoTrips: Trip[];
  invoiceTotal: number;
  expenseTotal: number;
  loadCount: number;
  assignmentAccepted: boolean;
  expanded = false;
  highlighted = false;
  selected = false;
  driverStatus: string;
  driverRejectionNote: string;
  maxNumberOfLoads: string;
  completed: boolean;
  kind: string;
  driverRequireConfirmation: boolean;
  totalTrips = 0;
  totalGeoTrips = 0;
  image: string;
  imageKey: string;
  numberOfLoadsType: string;
  numberOfLoadsLeft: number;
  listImage;
  status;
  tripStatus;
  statusClass;
  statusIcon;
  tripLoading: any;
  enRoute1;
  enRoute2;
  tripUnloading: any;
  geoTripStatus;
  geoTripLoading: any;
  geoEnRoute1;
  geoEnRoute2;
  geoTripUnloading: any;
  load;
  job;
  jobId;
  jobEvent;
  jobDisplayName: string;
  classes: string;
  loading: boolean;
  notes: string;
  allDecisionsApproved: boolean;
  allTripsApproved: string[];
  duration: {
    value: number,
    target?: number
  };
  geoDuration: {
    value: number,
    target?: number
  };
  activeLocation: Location;

  url(action): string {
    return '/scale/tickets/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
