"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var message_1 = require("./message");
var api_service_1 = require("../shared/api.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var MessageService = /** @class */ (function () {
    function MessageService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    MessageService.prototype.getMessage = function (jobEventId, messageId) {
        var messageUrl = this.baseUrl + 'jobevents/' + jobEventId + '/messaging/' + messageId + '/';
        return this.http.get(messageUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    MessageService.prototype.getMessages = function (jobEventId, query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var messagesUrl = this.baseUrl + 'jobevents/' + jobEventId + '/messaging/';
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        return this.http.get(messagesUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    MessageService.prototype.send = function (message) {
        var messageUrl = this.baseUrl + 'jobevents/' + message.jobEventId + '/messaging/';
        message = decamelizeKeysDeep(message);
        return this.http.post(messageUrl, message, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    MessageService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (message) {
                return new message_1.Message(message);
            });
        }
        else if (resObj) {
            return new message_1.Message(resObj);
        }
    };
    MessageService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    return MessageService;
}());
exports.MessageService = MessageService;
