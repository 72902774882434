"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var invoice_service_1 = require("./invoice.service");
var connection_service_1 = require("../connections/connection.service");
var job_service_1 = require("../jobs/job.service");
var api_service_1 = require("../shared/api.service");
var invoice_trips_dialog_component_1 = require("./invoice-trips-dialog.component");
var invoice_punch_cards_dialog_component_1 = require("./invoice-punch-cards-dialog.component");
var NewInvoiceDialogComponent = /** @class */ (function () {
    function NewInvoiceDialogComponent(invoiceService, connectionService, jobService, router, dialogRef, dialog) {
        this.invoiceService = invoiceService;
        this.connectionService = connectionService;
        this.jobService = jobService;
        this.router = router;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.loading = false;
        this.invoiceTypes = [
            'trips',
            'punchCards'
        ];
        this.model = {
            invoiceType: 'trips'
        };
        this.errors = [];
        this.jobSearch = '';
        this.jobOptions = [];
        this.jobLoading = false;
        this.jobDropdownConfig = {
            nameProperty: 'name'
        };
        this.customerSearch = '';
        this.customerOptions = [];
        this.customerLoading = false;
        this.customerDropdownConfig = {
            nameProperty: 'name'
        };
        this.invoiceCounts = {
            'trips': 0,
            'punchCards': 0
        };
    }
    NewInvoiceDialogComponent.prototype.ngOnInit = function () {
        this.jobDropdownConfig = { searchable: true, loadingOptions: false };
        this.customerDropdownConfig = { searchable: true, loadingOptions: false };
        this.getCustomers();
        this.getJobs();
    };
    NewInvoiceDialogComponent.prototype.submit = function () {
        var dialog;
        this.dialogRef.close();
        if (this.model.invoiceType === 'trips') {
            dialog = this.dialog.open(invoice_trips_dialog_component_1.InvoiceTripsDialogComponent, {
                width: '100%',
                height: '100%',
                disableClose: true
            });
        }
        else {
            dialog = this.dialog.open(invoice_punch_cards_dialog_component_1.InvoicePunchCardsDialogComponent, {
                width: '100%',
                height: '100%',
                disableClose: true
            });
        }
        dialog.componentInstance.jobId = this.model && this.model.job && this.model.job.id;
        dialog.componentInstance.customerId = this.model && this.model.customer && this.model.customer.id;
        dialog.componentInstance.routeToInvoice = true;
    };
    NewInvoiceDialogComponent.prototype.selectJob = function (job) {
        this.model.job = job;
        this.invoiceCounts['punchCards'] = job.invoiceablePunchcardCount;
        this.invoiceCounts['trips'] = job.invoiceableTripCount;
        this.model.invoiceType = job.invoiceType === 'hour' ? 'punchCards' : 'trips';
    };
    NewInvoiceDialogComponent.prototype.getJobs = function (search, setLoading) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (setLoading === void 0) { setLoading = false; }
        if (setLoading) {
            this.jobLoading = true;
        }
        if (this.jobsReq) {
            this.jobsReq.unsubscribe();
        }
        var debounceAmt = search ? 500 : 0;
        this.jobsReq = this.jobService.list({
            ordering: 'name',
            has_billable_items: 'True',
            search: search,
            customer_organization: this.model && this.model.customer && this.model.customer.id
        }).pipe(operators_1.debounceTime(debounceAmt)).subscribe(function (jobs) {
            _this.jobOptions = [];
            _this.jobOptions = jobs.map(function (job) {
                return {
                    name: job.name, id: job.id, invoiceType: job.invoiceType,
                    invoiceablePunchcardCount: job.invoiceablePunchcardCount,
                    invoiceableTripCount: job.invoiceableTripCount
                };
            });
            _this.jobLoading = false;
            _this.jobDropdownConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.jobLoading = false;
            _this.jobDropdownConfig.loadingOptions = false;
        });
    };
    NewInvoiceDialogComponent.prototype.jobDropdownSearch = function (term) {
        this.jobDropdownConfig.loadingOptions = true;
        this.getJobs(term);
        this.jobDropdownConfig.loadingOptions = false;
    };
    NewInvoiceDialogComponent.prototype.jobDropdownNextPage = function () {
        var _this = this;
        if (!this.jobDropdownConfig.loadingOptions) {
            var o = this.jobService.listNext();
            if (o) {
                this.jobDropdownConfig.loadingOptions = true;
                o.subscribe(function (jobs) {
                    _this.jobOptions = _this.jobOptions.concat(jobs.map(function (job) {
                        return {
                            name: job.name, id: job.id, invoiceType: job.invoiceType,
                            invoiceablePunchcardCount: job.invoiceablePunchcardCount,
                            invoiceableTripCount: job.invoiceableTripCount
                        };
                    }));
                    _this.jobDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.jobDropdownConfig.loadingOptions = false;
                });
            }
        }
    };
    NewInvoiceDialogComponent.prototype.selectCustomer = function (customer) {
        var _this = this;
        this.model.customer = customer;
        this.model.job = null;
        this.invoiceStatsReq = this.connectionService.getInvoiceStats(customer.customerId).subscribe(function (stats) {
            _this.invoiceCounts['punchCards'] = stats.invoiceablePunchcardCount;
            _this.invoiceCounts['trips'] = stats.invoiceableTripCount;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
        this.getJobs(null, true);
    };
    NewInvoiceDialogComponent.prototype.getCustomers = function (query, setLoading) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (setLoading === void 0) { setLoading = true; }
        if (setLoading) {
            this.customerLoading = true;
        }
        if (this.customersReq) {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.connectionService.list({
            ordering: 'name',
            has_billable_items: 'True',
            search: this.customerSearch
        }).subscribe(function (customers) {
            if (_this.customerSearch) {
                _this.customerOptions = [];
            }
            _this.customerOptions = _this.customerOptions.concat(customers.map(function (customer) {
                return {
                    name: customer.organization.name, id: customer.organization.id, customerId: customer.id,
                    invoiceablePunchcardCount: customer.invoiceablePunchcardCount,
                    invoiceableTripCount: customer.invoiceableTripCount
                };
            }));
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        });
    };
    NewInvoiceDialogComponent.prototype.customerDropdownSearch = function (term) {
        this.customerDropdownConfig.loadingOptions = true;
        this.customerSearch = term;
        this.getCustomers({}, false);
        this.customerDropdownConfig.loadingOptions = false;
    };
    NewInvoiceDialogComponent.prototype.customerDropdownNextPage = function () {
        var _this = this;
        if (!this.customerDropdownConfig.loadingOptions) {
            var o = this.connectionService.listNext();
            if (o) {
                this.customerDropdownConfig.loadingOptions = true;
                o.subscribe(function (customers) {
                    _this.customerOptions = _this.customerOptions.concat(customers.map(function (customer) {
                        return {
                            name: customer.organization.name, id: customer.organization.id, customerId: customer.id,
                            invoiceablePunchcardCount: customer.invoiceablePunchcardCount,
                            invoiceableTripCount: customer.invoiceableTripCount
                        };
                    }));
                    _this.customerDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.customerDropdownConfig.loadingOptions = false;
                });
            }
        }
    };
    NewInvoiceDialogComponent.prototype.selectInvoiceType = function (item) {
        this.model.invoiceType = item;
    };
    return NewInvoiceDialogComponent;
}());
exports.NewInvoiceDialogComponent = NewInvoiceDialogComponent;
