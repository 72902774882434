import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

import { UserService } from '../users/user.service';
import { OrganizationService } from '../organizations/organization.service';
import { OrganizationMarketDialogComponent } from '../organizations/organization-market-dialog.component';
import { AuthenticationService } from '../shared/authentication.service';
import { parseErrors } from '../shared/api.service';
import { User } from '../users/user';
import { Organization } from '../organizations/organization';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [UserService, OrganizationService]
})
export class SettingsComponent implements OnInit, OnDestroy {
  loading = true;
  errors = [];
  tab = 'edit';
  area = 'user';
  currentUrl = '';
  user: User;
  userReq: Subscription;
  organization: Organization;
  organizationReq: Subscription;

  @ViewChild('editOrganizationMarkets', { static: false }) editOrganizationMarkets;

  saveOrganizationCallback = (e) => {
    // Update organization data
  }

  newOrganizationMarketCallback = (e) => {
    this.editOrganizationMarkets.getOrganizationMarkets();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.currentUrl = this.route.snapshot.url.join('/');
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params['tab']) { this.tab = params['tab']; }
      this.area = params['area'];

      switch (this.area) {
        case 'user': {
          this.getUser();
          break;
        }
        case 'organization':
        default: {
          this.getOrganization();
          break;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }
    if (this.organizationReq && typeof this.organizationReq.unsubscribe === 'function') {
      this.organizationReq.unsubscribe();
    }
  }

  getUser(): void {
    this.loading = true;
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }

    let user = this.authenticationService.user();
    if (user) {
      this.userReq = this.userService.get(user.id).subscribe(_user => {
        this.user = _user;
        this.routeToTab('user', _user.id, this.tab);
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  getOrganization(): void {
    this.loading = true;
    if (this.organizationReq && typeof this.organizationReq.unsubscribe === 'function') {
      this.organizationReq.unsubscribe();
    }

    let org = new Organization(this.authenticationService.getOrganization());
    if (org) {
      this.organizationReq = this.organizationService.getOrganization(
        org.id
      ).subscribe(organization => {
        this.organization = organization;
        this.routeToTab('organization', organization.id, this.tab);
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  openAddOrganizationMarket(): void {
    const dialog = this.dialog.open(OrganizationMarketDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = this.newOrganizationMarketCallback;
  }

  private routeToTab(area: string, objectId: string, tab: string): void {
    this.tab = tab;
    if (objectId && this.currentUrl !== `settings/${area}/${objectId}/${tab}`) {
      this.router.navigate(['/settings', area, objectId, tab]);
    }
  }
}
