"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Conflict = /** @class */ (function () {
    function Conflict(conflictInfo) {
        conflictInfo = camelcaseKeysDeep(conflictInfo);
        this.jobevent = conflictInfo.jobevent || conflictInfo.jobeventId;
        this.jobeventName = conflictInfo.jobeventName;
        this.start = conflictInfo.start;
        this.end = conflictInfo.end;
        var start = moment(this.start).format('h:mm a');
        var end = moment(this.end).format('h:mm a');
        this._privateTime = start + ' - ' + end;
    }
    Object.defineProperty(Conflict.prototype, "time", {
        get: function () {
            return this._privateTime;
        },
        enumerable: true,
        configurable: true
    });
    return Conflict;
}());
exports.Conflict = Conflict;
