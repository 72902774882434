<form #ab5Questions="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Compliance Questions</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <notification *ngIf="error">{{ error }}</notification>
    <div class="body form-content">
      <div class="question" *ngFor="let question of questions; let i = index">
        <div class="text">
          <p class="label" translate>{{ i + 1 }} of {{ questions.length }}</p>
          <p>{{ question | translate }}</p>
        </div>
        <div class="options">
          <mat-checkbox [checked]="answers[i] === false" (change)="answers[i] = false"></mat-checkbox>
          No
          <mat-checkbox [checked]="answers[i]" (change)="answers[i] = true"></mat-checkbox>
          Yes
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="saveAnswers()" translate>
      Save Answers
    </button>
  </div>
</form>
