<div class="mobile-dispatch-shifts mobile-list">
  <div class="slot" *ngFor="let shift of shifts; let i = index" (click)="select(i)">
    <div class="info">
      <h3 translate>
        Shift {{ i + 1 }} {{ jobEvent['shift' + (i + 1) + 'StartTimestamp'] | date: 'h:mm a' }} - 
        {{ jobEvent['shift' + (i + 1) + 'EndTimestamp'] | date: 'h:mm a' }}
      </h3>
    </div>
    <div class="actions">
      <i class="icon-next-arrow"></i>
    </div>
  </div>
</div>
