"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment");
var resource_1 = require("../shared/resource");
var ShiftReport = /** @class */ (function (_super) {
    __extends(ShiftReport, _super);
    function ShiftReport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Calculates the shift report duration using the start and end times as well as the adjustment field
     *
     * @returns {string} The formatted (pretty) duration for display
     */
    ShiftReport.prototype.duration = function () {
        if (this.startTime && this.endTime) {
            var minuteDiff = moment(this.endTime).diff(moment(this.startTime), 'minutes') + (this.adjustment || 0);
            return Math.floor(minuteDiff / 60) + 'h ' + (minuteDiff % 60) + 'm';
        }
        else {
            return 'N/A';
        }
    };
    ShiftReport.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        return options;
    };
    return ShiftReport;
}(resource_1.Resource));
exports.ShiftReport = ShiftReport;
