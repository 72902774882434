"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".edit-drawer[_ngcontent-%COMP%]{position:fixed;margin-right:20px;margin-top:170px;max-width:415px}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}.tablet.edit-drawer[_ngcontent-%COMP%]{margin-top:calc(64px + 30px);height:calc(100vh - 70px - 62px)}.driver-image[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:370px;height:194px;border-radius:4px;border:1px solid #dfe3e9;margin:30px;overflow:hidden;-o-object-fit:cover;object-fit:cover}.form-row[_ngcontent-%COMP%]{display:flex;gap:20px;padding:0 30px;margin-bottom:10px}.form-row[_ngcontent-%COMP%]   .single-row[_ngcontent-%COMP%], .form-row[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{width:100%}.form-row[_ngcontent-%COMP%]   .password[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{cursor:pointer;color:#015bc5}.form-row[_ngcontent-%COMP%]   .password[_ngcontent-%COMP%]   input.password-disabled[_ngcontent-%COMP%]{background:#efefef;opacity:.6}.buttons-row[_ngcontent-%COMP%]{display:flex;gap:20px;width:100%;min-height:60px;background-color:#f0f3f8;margin-top:20px;padding:12px 30px;text-align:center}.buttons-row[_ngcontent-%COMP%]   .btn[_ngcontent-%COMP%]{width:100%}.notifications-container[_ngcontent-%COMP%]{padding:0 30px}"];
exports.styles = styles;
