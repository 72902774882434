import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Driver } from '../drivers/driver';
import { ConfirmDriversDialogComponent } from './confirm-drivers-dialog.component';
import { Job } from '../jobs/job';
import { Subscription } from 'rxjs';
import { JobEvent } from '../job-events/job-event';
import { DriverService } from '../drivers/driver.service';
import { Carrier } from '../carriers/carrier';

@Component({
  selector: 'mobile-dispatch-drivers',
  templateUrl: './mobile-dispatch-drivers.component.html',
  styleUrls: ['./mobile-dispatch-drivers.component.scss']
})
export class MobileDispatchDriversComponent implements OnInit, OnDestroy {
  @Input() job: Job;
  @Input() jobEvent: JobEvent;
  @Input() carrier: Carrier;
  @Input() selectedDriver: Driver;
  @Input() scrollEvent: any;
  @Output() selectDriver: EventEmitter<Driver> = new EventEmitter<Driver>();
  @Output() selectEditTruck: EventEmitter<Driver> = new EventEmitter<Driver>();
  drivers: Driver[] = [];
  loading = false;
  errors = [];
  driversReq: Subscription;

  constructor(
    private driverService: DriverService,
    public dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollEvent && !this.loading &&
      this.scrollEvent && this.scrollEvent.target &&
      this.scrollEvent.target.scrollTop >
      this.scrollEvent.target.scrollHeight -
      this.scrollEvent.target.clientHeight * 3) {
      this.getDrivers(true);
    }
  }

  ngOnInit() {
    this.getDrivers();
  }

  ngOnDestroy() {
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
  }

  select(driver: Driver) {
    this.selectedDriver = driver;
  }

  add(driver: Driver) {
    if (driver.conflicts && driver.conflicts.length > 0) {
      const dialog = this.dialog.open(ConfirmDriversDialogComponent, {
        width: '80vw'
      });
      if (dialog) {
        dialog.componentInstance.drivers = [driver];
        dialog.componentInstance.callback = this.confirmDriverCallback;
      }
    } else {
      this.selectDriver.emit(driver);
    }
  }

  confirmDriverCallback = () => {
    this.selectDriver.emit(this.selectedDriver);
  }

  editTruck(driver: Driver) {
    this.selectEditTruck.emit(driver);
  }

  getDrivers(next = false) {
    this.loading = true;
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
    let allCarriers, allLeased, carrierId;
    if (this.carrier && this.carrier.id === 'all_carriers') {
      allCarriers = true;
    } else if (this.carrier && this.carrier.id === 'all_leased') {
      allLeased = true;
    } else if (this.carrier && this.carrier.id === 'my_drivers') {
      // Nothing necessary
    } else if (this.carrier && this.carrier.id) {
      carrierId = this.carrier.id;
    }
    let jobEventId = this.jobEvent && this.jobEvent.id;
    let driverServiceReq = next ? this.driverService.listNext() :
    this.driverService.list({
      ordering: 'profile__last_name,name',
      // search: this.search,
      carrier: carrierId,
      all_carriers: allCarriers,
      all_leased: allLeased,
      jobevent_available: jobEventId
    });
    this.driversReq = driverServiceReq && driverServiceReq.subscribe(drivers => {
      this.drivers = next ? this.drivers.concat(drivers) : drivers;
    }, err => {
      this.errors = err;
    }, () => this.loading = false);
    if (!driverServiceReq) { this.loading = false; }
  }
}
