"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
// libraries
var material_1 = require("@angular/material");
var moment = require("moment");
// services
var api_service_1 = require("../../shared/api.service");
var driver_service_1 = require("../../drivers/driver.service");
var truck_service_1 = require("../../trucks/truck.service");
var shift_service_1 = require("../shift.service");
// components
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
var NewShiftComponent = /** @class */ (function () {
    function NewShiftComponent(route, shiftService, router, dialog) {
        this.route = route;
        this.shiftService = shiftService;
        this.router = router;
        this.dialog = dialog;
        this.model = {
            id: '',
            driver: null,
            truck: null,
            startTime: null,
            startDate: null,
            endTime: null,
            endDate: null,
            adjustment: null
        };
        this.loading = false;
        this.errors = [];
        this.driverDropdownConfig = {
            service: driver_service_1.DriverService,
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            sortBy: 'profile__first_name,profile__last_name',
            includeFullObject: true,
            query: {
                all_carriers: 'True'
            }
        };
        this.truckDropdownConfig = {
            service: truck_service_1.TruckService,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'displayName',
            query: {},
            sortBy: 'name'
        };
    }
    NewShiftComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.queryParamsSub = this.route.queryParams && this.route.queryParams.forEach(function (params) {
            if (params['returnTo'] && params['returnTo'].length) {
                _this.returnTo = params['returnTo'];
            }
        });
    };
    NewShiftComponent.prototype.ngOnDestroy = function () {
        if (this.queryParamsSub && typeof this.queryParamsSub.unsubscribe === 'function') {
            this.queryParamsSub.unsubscribe();
        }
    };
    NewShiftComponent.prototype.isValid = function () {
        var validTimes = false;
        if (this.model.endTime && this.model.startTime) {
            validTimes = !(moment(this.model.endTime).isBefore(moment(this.model.startTime)));
        }
        return !!this.model.driver && !!this.model.startTime && (validTimes || !this.model.endTime);
    };
    NewShiftComponent.prototype.submit = function () {
        var _this = this;
        if (this.loading || !this.isValid()) {
            return;
        }
        this.loading = true;
        var model = {
            driver: this.model.driver && this.model.driver.id,
            truck: this.model.truck && this.model.truck.id,
            startTime: this.model.startTime && this.model.startTime.toISOString(),
            endTime: this.model.endTime && this.model.endTime.toISOString(),
            adjustment: this.model.adjustment
        };
        this.shiftService.save(model).subscribe(function () {
            _this.loading = false;
            if (_this.returnTo && _this.returnTo.length) {
                if (_this.returnTo.includes('?')) {
                    var route = _this.returnTo.split('?')[0];
                    var params_1 = {};
                    _this.returnTo.split('?')[1].split('&').forEach(function (param) {
                        var paramData = param.split('=');
                        params_1[paramData[0]] = paramData[1];
                    });
                    _this.router.navigate([route], { queryParams: params_1 });
                }
                else {
                    _this.router.navigate([_this.returnTo]);
                }
            }
            else {
                _this.router.navigate(['/shifts']);
            }
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    NewShiftComponent.prototype.onDateChanged = function (field, dates) {
        this.model[field] = dates[0];
        this.editDateTime(field, dates[0]);
    };
    NewShiftComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'driver':
                this.model.truck = null;
                this.model.driver = e;
                if (e.carrier) {
                    this.truckDropdown.getRecords({
                        carrier: e.carrier.id
                    });
                }
                break;
            case 'truck':
                this.model.truck = e;
                break;
        }
        this.model[filterType] = e;
    };
    /**
     * Makes an edit to either the date or time for the startTime or endTime field
     * and pairs that value with the date to set the overall timestamp.
     *
     * @param {string} field The datetime field we will apply the edit to
     * @param {any} value The edit value for the datetime
     */
    NewShiftComponent.prototype.editDateTime = function (field, value) {
        if (['startTime', 'endTime'].includes(field) && value) {
            var dateField = 'startTime' === field ? 'startDate' : 'endDate';
            var date = this.model[dateField] || new Date();
            if (!!this.model[dateField]) {
                this.model[dateField] = date;
            }
            this.model[field] = moment(moment(date).format('YYYY-MM-DD') + " " + value, 'YYYY-MM-DD HH:mm').toDate();
        }
        else if (['startDate', 'endDate'].includes(field) && value) {
            var timeField = 'startDate' === field ? 'startTime' : 'endTime';
            var time = this.model[timeField];
            this.model[field] = moment(value, 'YYYY-MM-DD').toDate();
            if (time) {
                this.model[timeField] = moment(moment(this.model[field]).format('YYYY-MM-DD') + " " + moment(time).format('HH:mm'), 'YYYY-MM-DD HH:mm').toDate();
            }
        }
        else if (field && value) {
            this.model[field] = moment(value).toDate();
        }
    };
    return NewShiftComponent;
}());
exports.NewShiftComponent = NewShiftComponent;
