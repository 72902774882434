'use strict';
import {
  Component, Input, Output, ElementRef, ViewChild, EventEmitter, HostListener,
  OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { find, groupBy, get as _get } from 'lodash';

const findIndex = function(arr, el) {
  return Array.prototype.indexOf.call(arr, el);
};
const includes = function(arr, el) {
  return findIndex(arr, el) !== -1;
};

@Component({
  selector: 'truck-dropdown',
  templateUrl: './truck-dropdown.component.html',
  styleUrls: ['./truck-dropdown.component.scss']
})
export class TruckDropdownComponent implements OnChanges, OnInit {
  open = false;
  public selectedItems = [];
  menuHeight = '0px';
  menuWidth = '100%';
  searchTerm;
  keySearchTerm = '';
  keyTimeout;
  groups = {};
  groupKeys;
  @ViewChild('optionsEl', { static: false }) optionsElRef: ElementRef;
  @Input() selectedOption;
  @Input('title') title;
  @Input('icon') icon;
  @Input() setTitle = true;
  @Input() changeTitle = true;
  @Input() config;
  @Input() options = [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled;
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.open) {
      this.toggleMenu();
    }
  }

  constructor(private el: ElementRef, private _eref: ElementRef) {
    this.config = this.config || {};
  }

  ngOnInit() {
    this.config = this.config || {};
    this.config = {
      nameProperty: 'displayName',
      licenseProperty: 'licensePlate',
      truckIdProperty: 'name',
      imageProperty: 'image',
      image: false,
      multiselect: false,
      selectedProp: 'selected',
      style: '',
      pin: '',
      groupBy: 'group',
      group: false,
      selectable: true,
      ...this.config
    };

    if (!Array.isArray(this.options)) {
      throw { error: 'drowpdown options must be an array.', dropdown: this };
    }
    this.setSelectedOption();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.config && this.config.group) {
      this.groups = groupBy(this.options, this.config.groupBy);
    }
    this.groupKeys = Object.keys(this.groups);
    if (this.open) {
      setTimeout(() => {
        this.setSize();
      }, 0);
    }
  }

  public setSelectedOption() {
    this.selectedOption =
      (this.options &&
        this.options.find(option => {
          return option[this.config.selectedProp];
        })) ||
      this.selectedOption;
  }

  onKeyPress(e) {
    if (this.el.nativeElement.contains(document.activeElement)) {
      if (e.code === 'Escape' && this.open) {
        this.toggleMenu();
      }
      let options = this.optionsElRef.nativeElement.children[0].children;
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        if (!this.open) {
          this.toggleMenu();
        } else if (options.length) {
          let lastOption = options[options.length - 1];
          let index = findIndex(options, document.activeElement);
          if (e.code === 'ArrowDown') {
            if (!includes(options, document.activeElement)) {
              options[0].focus();
            } else if (document.activeElement !== lastOption) {
              options[index + 1].focus();
            }
          } else if (e.code === 'ArrowUp') {
            if (!includes(options, document.activeElement)) {
              lastOption.focus();
            } else if (index !== 0) {
              options[index - 1].focus();
            }
          }
        }
        e.preventDefault();
      } else if (
        e.key.match(/^\w$/) &&
        e.target.nodeName.toLowerCase() !== 'input'
      ) {
        if (this.keyTimeout) {
          clearTimeout(this.keyTimeout);
        }
        this.keyTimeout = setTimeout(() => {
          this.keySearchTerm = '';
        }, 1000);
        this.keySearchTerm += e.key.toLowerCase();
        let option: any = find(options, (o: HTMLElement) => {
          return o.innerText
            .toLowerCase()
            .match(RegExp('^' + this.keySearchTerm));
        });
        if (option) {
          option.focus();
        }
      }
    }
    setTimeout(() => {
      if (
        this.open &&
        !this.el.nativeElement.contains(document.activeElement)
      ) {
        this.toggleMenu();
      }
    }, 0);
  }
  buttonKeyPress(e) {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      this.toggleMenu();
    }
  }
  optionKeyPress(e, option) {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      this.toggleOption(option);
    }
  }
  toggleMenu() {
    if (!this.disabled) {
      this.open = !this.open;
    }
    this.setSize();
  }
  openMenu() {
    if (!this.disabled) {
      this.open = true;
    }
    this.setSize();
  }

  setSize() {
    const menu = this.optionsElRef.nativeElement;
    this.menuHeight = (this.open ? menu.offsetHeight : 0) + 'px';
    this.menuWidth = this.optionsElRef.nativeElement.offsetWidth + 'px';

    const rect = menu.getBoundingClientRect();
    if (
      rect.height + menu.parentElement.getBoundingClientRect().top >
      window.innerHeight
    ) {
      menu.parentElement.style.transform =
        'translateY(-' +
        (rect.height +
          menu.parentElement.getBoundingClientRect().top -
          window.innerHeight) +
        'px)';
    }
  }

  toggleOption(option) {
    if (!this.config.selectable) {
      return;
    }

    if (this.config.multiselect) {
      option[this.config.selectedProp] = !option[this.config.selectedProp];
      this.onSelect.emit(
        this.options.filter(_option => {
          return _option[this.config.selectedProp];
        })
      );
    } else {
      this.deselectAll();
      this.selectedOption = option;
      this.toggleMenu();
      this.onSelect.emit(this.selectedOption);
    }
  }
  isSelected(option) {
    return (
      !option.button &&
      (option[this.config.selectedProp] || option === this.selectedOption)
    );
  }
  public deselectAll() {
    this.options.forEach(option => {
      if (typeof option === 'object') {
        option[this.config.selectedProp] = undefined;
      }
    });
    this.selectedOption = undefined;
  }

  optionDisplayName(option) {
    if (typeof option === 'object') {
      let name = _get(option, this.config.nameProperty);
      if (!name) {
        name = '-';
      }
      return name;
    } else {
      return option;
    }
  }
  optionDisplayLicense(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.licenseProperty);
    } else {
      return option;
    }
  }
  optionDisplayTruckId(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.truckIdProperty);
    } else {
      return option;
    }
  }
  optionImageDisplay(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.imageProperty);
    } else {
      return option;
    }
  }

  onScroll(e) {
    if (
      !this.config.loadingOptions &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3
    ) {
      this.nextPage.emit();
    }

    let div = e.currentTarget;
    if (div.clientHeight + div.scrollTop + e.deltaY >= div.scrollHeight) {
      e.preventDefault();
      div.scrollTop = div.scrollHeight;
    } else if (div.scrollTop + e.deltaY <= 0) {
      e.preventDefault();
      div.scrollTop = 0;
    }
  }
  windowScroll(e) {
    if (this.open) {
      e.preventDefault();
    }
    return false;
  }
}
