import { Driver } from './driver';
import { PROFILE } from './mock-profile';
import { CARRIER } from '../carriers/mock-carrier';

export let DRIVER: Driver = <Driver> {
  id: '00000000-0000-0000-0000-000000000001',
  profile: PROFILE,
  image: '',
  city: 'Austin',
  state: 'TX',
  country: 'US',
  street: '123 Main Street',
  zipcode: '12345',
  name: 'Testy Tester',
  activeShift: true,
  cdl: '123456',
  requireAssignmentConfirmation: false,
  initials: 'JL',
  carrierName: 'Jeff Lee',
  uniqueBillingId: '',
  conflictIds: null,
  carrier: CARRIER,
  carrierOrganization: null,
  geoTrips: [
    {
      id: '0a1a0a1a-0a1a-0a1a-0a1a-0a1a0a1a0a1a',
      startTime: '2024-08-13T20:52:06.164000Z',
      enrouteLoadingTime: '2024-08-13T20:52:06.164000Z',
      loadingArrivalTime: '2024-08-13T20:52:06.164000Z',
      loadingCompleteTime: null,
      enrouteUnloadingTime: null,
      unloadingArrivalTime: null,
      unloadingCompleteTime: null,
      endTime: '2024-08-13T20:52:06.164000Z',
      completed: false,
      tripStatus: 'loading',
      distance: null,
      job: {
        id: 'a0a1a0a1-a0a1-a0a1-a0a1-a0a1a0a1a0a1',
        name: 'Mock Job',
        displayName: 'Mock Job Name',
        startLocation: {
          id: 'a0a1a0a1-a0a1-a0a1-a0a1-a0a1a0a1a001',
          name: 'Mock start location',
          averageLoadingTime: 30,
          averageUnloadingTime: 15
        },
        endLocation: {
          id: 'a0a1a0a1-a0a1-a0a1-a0a1-a0a1a0a1a010',
          name: 'Mock end location',
          averageLoadingTime: 15,
          averageUnloadingTime: 15
        }
      }
    }
  ],
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  }
};

export let DRIVER_TWO: Driver = <Driver> {
  id: '00000000-0000-0000-0000-000000000002',
  profile: PROFILE,
  image: '',
  city: 'Austin',
  state: 'TX',
  country: 'US',
  street: '456 Main Street',
  zipcode: '67890',
  name: 'Testy Tester Jr.',
  activeShift: false,
  cdl: '78910',
  requireAssignmentConfirmation: true,
  initials: 'AC',
  carrierName: 'Alton Chaney',
  carrierOrganization: null,
  uniqueBillingId: '',
  conflictIds: null,
  geoTrips: [],
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  }
};
