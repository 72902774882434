import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'expense-pay-type-dialog',
  templateUrl: './expense-pay-type-dialog.component.html',
  styleUrls: ['./expense-pay-type-dialog.component.scss'],
  providers: []
})
export class ExpensePayTypeDialogComponent implements OnInit {
  @Input() expense;
  loading = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<ExpensePayTypeDialogComponent>
  ) { }

  ngOnInit() {
    this.expense = cloneDeep(this.expense);
  }

  submit() {
    this.callback(this.expense);
    this.dialogRef.close();
  }
}
