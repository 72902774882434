"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_1 = require("@ngui/map");
var moment = require("moment");
var static_data_1 = require("../../shared/static-data");
var driver_1 = require("../../drivers/driver");
var shared_1 = require("../../shared");
var location_service_1 = require("../../locations/location.service");
var RuckitReplayMapComponent = /** @class */ (function () {
    function RuckitReplayMapComponent(authenticationService, locationService) {
        this.authenticationService = authenticationService;
        this.locationService = locationService;
        this.errors = [];
        this.jobEvents = [];
        this.locations = [];
        this.selectedTime = '';
        this.timeRange = {};
        this.activeIndex = 0;
        this.loaded = true;
        this.loading = false;
        this.mapOptions = static_data_1.mapOptions({
            zoom: 10,
            disableDefaultUI: true,
            fullscreenControl: true,
            streetViewControl: false,
            mapTypeControl: true
        });
        this.condensedLocationUpdates = [];
        this.customUpdateMarkers = [];
        this.jobEventRoutes = [];
        this.polylineOptions = {
            strokeColor: '#002649',
            strokeWeight: '4',
            strokeOpacity: 0.6
        };
        this.customLocationMarkers = [];
    }
    RuckitReplayMapComponent.prototype.ngOnInit = function () {
        this.organization = this.authenticationService.getOrganization();
    };
    RuckitReplayMapComponent.prototype.jobEventsEqual = function (prevJobEvents, currJobEvents) {
        if (prevJobEvents.length !== currJobEvents.length) {
            return false;
        }
        for (var i = 0; i < currJobEvents.length; i++) {
            if (prevJobEvents[i].id !== currJobEvents[i].id) {
                return false;
            }
        }
        return true;
    };
    RuckitReplayMapComponent.prototype.locationsEqual = function (prevLocations, currLocations) {
        if (prevLocations.length !== currLocations.length) {
            return false;
        }
        for (var i = 0; i < currLocations.length; i++) {
            if (prevLocations[i].id !== currLocations[i].id) {
                return false;
            }
        }
        return true;
    };
    RuckitReplayMapComponent.prototype.ngOnChanges = function (changes) {
        if (changes.jobEvents && changes.jobEvents.currentValue.length &&
            !this.jobEventsEqual(changes.jobEvents.previousValue, changes.jobEvents.currentValue)) {
            this.jobEventRoutes = this.createJobEventRoutes(changes.jobEvents.currentValue);
            this.geofences = this.setupGeofences(changes.jobEvents.currentValue);
            if (this.jobEventRoutes && this.jobEventRoutes.length) {
                this.centerMap();
            }
        }
        else if (changes.jobEvents && !changes.jobEvents.currentValue.length) {
            this.jobEventRoutes = [];
        }
        if (changes.locations && changes.locations.currentValue.length &&
            !this.locationsEqual(changes.locations.previousValue, changes.locations.currentValue)) {
            this.customLocationMarkers = [];
            this.allLocationGeofences = this.setupAllLocationGeofences(changes.locations.currentValue);
        }
        else if (changes.locations && !changes.locations.currentValue.length) {
            this.allLocationGeofences = [];
        }
        if (changes.selectedTime && this.condensedLocationUpdates.length) {
            this.activeIndex = this.activeUpdateIndex(this.selectedTime);
        }
        else if (changes.locationUpdates && this.timeRange && this.driver) {
            this.getMapData(this.driver);
        }
    };
    RuckitReplayMapComponent.prototype.initMap = function (e) {
        if (e) {
            this.map = e;
        }
        this.centerMap();
    };
    RuckitReplayMapComponent.prototype.onMapLoaded = function (e) {
        if (e === void 0) { e = null; }
        if (e) {
            this.map = e.target.map;
        }
    };
    RuckitReplayMapComponent.prototype.centerMap = function () {
        var _centerOnOrganization = true;
        if (this.map) {
            var bounds_1 = new google.maps.LatLngBounds();
            var latLngList_1 = [];
            var geofences_1 = [];
            this.condensedLocationUpdates.forEach(function (update) {
                if (update.location) {
                    _centerOnOrganization = false;
                    bounds_1.extend(new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]));
                }
            });
            this.jobEvents.forEach(function (jobEvent) {
                if (jobEvent.job) {
                    if (jobEvent.startCoordinates && jobEvent.endCoordinates) {
                        latLngList_1 = [
                            jobEvent.job.startLocationMappable ?
                                new google.maps.LatLng(jobEvent.startCoordinates.latitude, jobEvent.startCoordinates.longitude) : null,
                            jobEvent.job.endLocationMappable ?
                                new google.maps.LatLng(jobEvent.endCoordinates.latitude, jobEvent.endCoordinates.longitude) : null,
                        ];
                    }
                    for (var i = 0, LtLgLen = latLngList_1.length; i < LtLgLen; i++) {
                        _centerOnOrganization = false;
                        var list = latLngList_1[i];
                        bounds_1.extend(list);
                    }
                    if (jobEvent.job.startLocation && jobEvent.job.startLocation.geofence && jobEvent.job.startLocation.paths) {
                        geofences_1.push({ paths: jobEvent.job.startLocation.paths });
                        jobEvent.job.startLocation.paths.forEach(function (path) {
                            if (path.lat && path.lng) {
                                _centerOnOrganization = false;
                                bounds_1.extend(new google.maps.LatLng(path.lat, path.lng));
                            }
                        });
                    }
                    if (jobEvent.job.endLocation && jobEvent.job.endLocation.geofence && jobEvent.job.endLocation.paths) {
                        geofences_1.push({ paths: jobEvent.job.endLocation.paths });
                        jobEvent.job.endLocation.paths.forEach(function (path) {
                            if (path.lat && path.lng) {
                                _centerOnOrganization = false;
                                bounds_1.extend(new google.maps.LatLng(path.lat, path.lng));
                            }
                        });
                    }
                }
            });
            if (this.map.getZoom() > 17) {
                this.map.setZoom(17);
            }
            if (_centerOnOrganization) {
                this.centerOnOrganization();
            }
            this.map.fitBounds(bounds_1);
            return true;
        }
        else {
            return false;
        }
    };
    RuckitReplayMapComponent.prototype.centerOnOrganization = function () {
        var _this = this;
        if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
            this.organizationLocationReq.unsubscribe();
        }
        if (!this.organization || this.organization === undefined) {
            return;
        }
        this.locationService.getLocationByAddress(this.organization.street + ", " + this.organization.location).subscribe(function (coords) {
            if (coords && coords[0] && coords[0].geometry && coords[0].geometry.location) {
                var fallbackCenter = new google.maps.LatLng(coords[0].geometry.location.lat, coords[0].geometry.location.lng);
                _this.mapOptions = static_data_1.mapOptions({
                    zoom: 10,
                    center: fallbackCenter,
                    disableDefaultUI: true,
                    scrollwheel: false,
                    geoFallbackCenter: fallbackCenter
                }, {}, {
                    mapStyle: 'google-map-style'
                });
            }
        }, function (err) { return _this.errors = ['Cannot get your organizations location']; });
    };
    RuckitReplayMapComponent.prototype.getMapData = function (driver) {
        this.loading = true;
        this.condensedLocationUpdates = [];
        this.selectedDate = moment(this.timeRange.startDatetime).format('YYYYMMDD');
        this.timelineStart = moment(this.timeRange.startDatetime).subtract(2, 'hours').startOf('hour');
        this.timelineStartUnix = Number(this.timelineStart.format('x'));
        if (this.locationUpdates.length && (!this.condensedLocationUpdates.length || driver.id !== this.driver.id)) {
            this.customUpdateMarkers = [];
            this.condensedLocationUpdates = this.setupLocationUpdates(this.locationUpdates);
            this.centerMap();
        }
        this.loading = false;
    };
    RuckitReplayMapComponent.prototype.setupGeofences = function (jobEvents) {
        var geofences = [];
        jobEvents.forEach(function (jobEvent) {
            if (jobEvent.job) {
                if (jobEvent.job.startLocation && jobEvent.job.startLocation.paths) {
                    geofences.push({
                        id: jobEvent.job.startLocation.id,
                        name: jobEvent.job.startLocation.name,
                        paths: jobEvent.job.startLocation.paths
                    });
                }
                if (jobEvent.job.endLocation && jobEvent.job.endLocation.paths) {
                    geofences.push({
                        id: jobEvent.job.endLocation.id,
                        name: jobEvent.job.endLocation.name,
                        paths: jobEvent.job.endLocation.paths
                    });
                }
            }
        });
        return geofences;
    };
    RuckitReplayMapComponent.prototype.setupAllLocationGeofences = function (locations) {
        var geofences = [];
        locations.forEach(function (location) {
            if (location.geofence && location.location) {
                if (geofences.findIndex(function (geofence) { return (geofence.id === location.id); }) === -1) {
                    geofences.push({
                        id: location.id,
                        name: location.name,
                        paths: location.paths,
                        coordinates: location.location && location.location.coordinates
                    });
                }
            }
        });
        return geofences;
    };
    RuckitReplayMapComponent.prototype.openGeofenceInfoWindow = function (index, location) {
        this.geofenceInfoWindowModel = {
            name: location.name
        };
        this.nguiMapComponent.openInfoWindow('geofence-info-window', this.customLocationMarkers[index]);
    };
    RuckitReplayMapComponent.prototype.onCustomGeofenceMarkerInit = function (customMarker) {
        this.customLocationMarkers.push(customMarker);
    };
    RuckitReplayMapComponent.prototype.setupLocationUpdates = function (locationUpdates) {
        var newLocationUpdates = [];
        var currUpdateIndex = 0;
        var endTime = Number(moment(this.timeRange.endDatetime).add(2, 'hours').startOf('hour').format('x'));
        if (locationUpdates.length) {
            for (var minute = this.timelineStartUnix; minute < endTime; minute += 60000) {
                while (locationUpdates[currUpdateIndex] && Date.parse(locationUpdates[currUpdateIndex].date) < minute) {
                    currUpdateIndex++;
                }
                var updateData = locationUpdates[currUpdateIndex - 1];
                if (updateData) {
                    newLocationUpdates.push({
                        date: moment(minute, 'x'),
                        location: updateData.location,
                        orientation: updateData.orientation
                    });
                }
                else {
                    newLocationUpdates.push({ date: moment(minute, 'x') });
                }
            }
        }
        return newLocationUpdates;
    };
    RuckitReplayMapComponent.prototype.createJobEventRoutes = function (jobEvents) {
        var jobEventRoutes = [];
        jobEvents.forEach(function (jobEvent) {
            if (jobEvent.startCoordinates && jobEvent.endCoordinates) {
                jobEventRoutes.push({
                    origin: jobEvent.startCoordinates.latitude + ', ' + jobEvent.startCoordinates.longitude,
                    destination: jobEvent.endCoordinates.latitude + ', ' + jobEvent.endCoordinates.longitude,
                    travelMode: 'DRIVING'
                });
            }
        });
        return jobEventRoutes;
    };
    RuckitReplayMapComponent.prototype.activeUpdateIndex = function (selectedTime) {
        // get the difference in minutes from the range start and set that number as the index
        var index = Date.parse(selectedTime) - this.timelineStartUnix;
        return (index / 60000);
    };
    return RuckitReplayMapComponent;
}());
exports.RuckitReplayMapComponent = RuckitReplayMapComponent;
