<div class="app-content trip-history">
  <my-toolbar
    icon="icon-back"
    title="{{ title | translate }}"
    [iconLink]="returnToUrl"
  ></my-toolbar>
  <div class="trip-history-dashboard">
    <div class="trip-history-ticket">
      <trip-history-ticket [trip]="trip"></trip-history-ticket>
    </div>
    <div class="billing-route-container">
      <div class="trip-history-billing">
        <trip-history-billing [trip]="trip"></trip-history-billing>
      </div>
      <div class="trip-history-route">
        <trip-history-route [trip]="trip"></trip-history-route>
      </div>
    </div>
  </div>
</div>
