"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ControlOption = /** @class */ (function () {
    function ControlOption(data) {
        this.classes = '';
        this.id = data.id;
        this.name = data.name;
        this.label = data.label;
        this.classes = data.classes;
        this.disabled = data.disabled || false;
        this.selected = data.selected || false;
    }
    return ControlOption;
}());
exports.ControlOption = ControlOption;
