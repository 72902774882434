"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../../guards/auth.guard");
var end_of_day_component_1 = require("./end-of-day.component");
var routes = [
    { path: '', component: end_of_day_component_1.EndOfDayComponent, canActivate: [auth_guard_1.AuthGuard] },
];
var EndOfDayRoutingModule = /** @class */ (function () {
    function EndOfDayRoutingModule() {
    }
    return EndOfDayRoutingModule;
}());
exports.EndOfDayRoutingModule = EndOfDayRoutingModule;
