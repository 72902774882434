"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var JoinByPropertyPipe = /** @class */ (function () {
    function JoinByPropertyPipe() {
    }
    JoinByPropertyPipe.prototype.transform = function (arr, property, character) {
        if (character === void 0) { character = ', '; }
        if (!Array.isArray(arr)) {
            return '';
        }
        return arr.map(function (t) { return t[property]; }).join(character);
    };
    return JoinByPropertyPipe;
}());
exports.JoinByPropertyPipe = JoinByPropertyPipe;
