<form #saveSurchargeTemplate="ngForm">
  <div md-dialog-title class="modal-title">
    <h1 translate>Surcharge Template</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <div md-dialog-content class="md-dialog-content">
    <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="group title" fxFlex="50">
        <label class="required" translate>Item Name</label>
        <input required type="text" name="title" [(ngModel)]="model.title" #title="ngModel" />
      </div>
      <div class="group amount" fxFlex="50">
        <div class="modifiers">
          <div class="positive" [ngClass]="{ active: positiveAmount }" (click)="setAmountPositive(true)">+</div>
          <div class="negative" [ngClass]="{ active: !positiveAmount }" (click)="setAmountPositive(false)">&#x2013;</div>
        </div>
        <div>
          <label translate>Amount</label>
          <input currencyMask
                 type="text"
                 name="amount"
                 placeholder=""
                 [(ngModel)]="model.amount"
                 (ngModelChange)="amountChange()"
                 #amount="ngModel"
                 [options]="{ align: 'left', allowNegative: true, precision: 2 }" />
        </div>
      </div>
    </div>

    <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="hasPOS">
      <div class="field-group kind" fxFlex="50">
        <label class="required" translate>Surcharge Type</label>
        <input type="radio" name="kind" attr.data-label="{{ 'Billing' | translate }}"
               value="invoice" [checked]="model.kind === 'invoice'" [(ngModel)]="model.kind">
      </div>
      <div class="field-group kind" fxFlex="50">
        <label translate>&nbsp;</label>
        <input type="radio" name="kind" attr.data-label="{{ 'Scale Ticket' | translate }}"
               value="scaleticket" [checked]="model.kind === 'scaleticket'" [(ngModel)]="model.kind">
      </div>
    </div>

    <div class="form-content">
      <div class="field-group description">
        <label translate>Description</label>
        <textarea [(ngModel)]="model.description" name="description"></textarea>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="saveSurchargeTemplate.form.valid && submitSurchargeTemplate(); false"
            [disabled]="!saveSurchargeTemplate.form.valid || loading"
            [ngClass]="{loading: loading}"
            translate>
      Save Template
    </button>
  </div>
</form>
