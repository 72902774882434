import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { JobService } from '../jobs/job.service';
import { Job } from '../jobs/job';

@Injectable({
  providedIn: 'root'
})
export class DispatchGuard implements CanActivate {
  jobReq: Subscription;
  job: Job;
  errors = [];

  constructor (private router: Router, private jobService: JobService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.jobReq = this.jobService.get(route.params.jobId).subscribe(job => {
      this.job = job;
      if (currentUser && currentUser.organization && this.job && this.job.project && this.job.project.customerOrganization) {
        if (currentUser.organization.id === this.job.project.customerOrganization.id) {
          this.router.navigateByUrl('/jobs/daily');
          return false;
        }
      }
    });
    return true;
  }
}

