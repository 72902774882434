"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var assignment_1 = require("../assignments/assignment");
var job_event_1 = require("../job-events/job-event");
var MobileAssignmentDetailsComponent = /** @class */ (function () {
    function MobileAssignmentDetailsComponent() {
    }
    return MobileAssignmentDetailsComponent;
}());
exports.MobileAssignmentDetailsComponent = MobileAssignmentDetailsComponent;
