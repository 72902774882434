"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var marketplace_service_1 = require("../marketplace/marketplace.service");
var project_service_1 = require("../projects/project.service");
var api_service_1 = require("../shared/api.service");
var marketplace_new_project_dialog_component_1 = require("./marketplace-new-project-dialog.component");
var MarketplaceAcceptJobComponent = /** @class */ (function () {
    function MarketplaceAcceptJobComponent(route, router, elementRef, marketplaceService, projectService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.elementRef = elementRef;
        this.marketplaceService = marketplaceService;
        this.projectService = projectService;
        this.dialog = dialog;
        this.loading = true;
        this.errors = [];
        this.model = {
            shareId: null,
            haulRate: null,
            haulType: '',
            weightUnit: '',
            project: ''
        };
        this.projects = [];
        this.haulTypes = [
            { name: 'Load', id: 'load' },
            { name: 'Hour', id: 'hour' },
            { name: 'Weight', id: 'weight' }
        ];
        this.weightUnits = [
            { name: 'Tons', id: 'ton' },
            { name: 'Metric Tons', id: 'metric-tons' },
            { name: 'Yards', id: 'yard' },
        ];
        this.saveProjectCallback = function (project) {
            _this.getProjects({}, project);
        };
    }
    MarketplaceAcceptJobComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.disableSidebar();
        this.route.params
            .subscribe(function (params) { return _this.getMarketplace(params['id']); });
    };
    MarketplaceAcceptJobComponent.prototype.disableSidebar = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.sidebar = false;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    };
    MarketplaceAcceptJobComponent.prototype.getMarketplace = function (id) {
        var _this = this;
        if (this.marketplaceReq) {
            this.marketplaceReq.unsubscribe();
        }
        this.marketplaceReq = this.marketplaceService.getMarketplace(id)
            .subscribe(function (marketplace) {
            _this.marketplace = marketplace;
            _this.model.shareId = _this.marketplace.id;
            _this.getProjects();
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    MarketplaceAcceptJobComponent.prototype.getProjects = function (query, selectProject) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (selectProject === void 0) { selectProject = null; }
        if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
            this.projectsReq.unsubscribe();
        }
        this.projectsReq = this.projectService.list({
            ordering: 'name',
            customer_organization: this.marketplace.job.project.ownerOrganization
        }).subscribe(function (_projects) {
            _this.projects = _projects;
            if (selectProject) {
                _this.model.project = selectProject.id;
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    MarketplaceAcceptJobComponent.prototype.onProjectChange = function (event, value) {
        if (value === 'new-project') {
            this.model.project = '';
            this.openAddProject();
        }
    };
    MarketplaceAcceptJobComponent.prototype.openAddProject = function () {
        this.dialogRef = this.dialog.open(marketplace_new_project_dialog_component_1.MarketplaceNewProjectDialogComponent, {
            width: '90%'
        });
        this.dialogRef.componentInstance.model.customerOrganization = this.marketplace.job.project.ownerOrganization;
        this.dialogRef.componentInstance.callback = this.saveProjectCallback;
    };
    MarketplaceAcceptJobComponent.prototype.accept = function () {
        var _this = this;
        this.loading = true;
        this.marketplaceService.accept(this.model).subscribe(function (res) {
            _this.router.navigate(['/marketplace/']);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return MarketplaceAcceptJobComponent;
}());
exports.MarketplaceAcceptJobComponent = MarketplaceAcceptJobComponent;
