"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var GetDurationStringPipe = /** @class */ (function () {
    function GetDurationStringPipe() {
    }
    GetDurationStringPipe.prototype.transform = function (startDatetime, endDatetime, durationType) {
        if (durationType === void 0) { durationType = 'hours'; }
        endDatetime = endDatetime ? endDatetime : moment().toISOString();
        if (startDatetime && endDatetime) {
            var minuteDuration = moment(endDatetime).diff(startDatetime, 'minutes');
            return getDurationString(durationType, minuteDuration);
        }
        else if (startDatetime) {
            var minuteDuration = moment(new Date()).diff(startDatetime, 'minutes');
            return getDurationString(durationType, minuteDuration);
        }
        else {
            return '-h--m';
        }
    };
    return GetDurationStringPipe;
}());
exports.GetDurationStringPipe = GetDurationStringPipe;
var getDurationString = function (durationType, minuteDuration) {
    switch (durationType) {
        case 'minutes':
            return minuteDuration + 'm';
        default:
            return (Math.floor(minuteDuration / 60) + 'h ' + (minuteDuration % 60) + 'm');
    }
};
var ɵ0 = getDurationString;
exports.ɵ0 = ɵ0;
