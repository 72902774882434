"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var job_event_stat_service_1 = require("../job-event-stats/job-event-stat.service");
var OverallJobProductionComponent = /** @class */ (function () {
    function OverallJobProductionComponent(jobEventStatService) {
        this.jobEventStatService = jobEventStatService;
        this.errors = [];
    }
    OverallJobProductionComponent.prototype.ngOnInit = function () {
        this.getJobStats();
    };
    OverallJobProductionComponent.prototype.getJobStats = function () {
        var _this = this;
        if (this.job) {
            this.jobEventStatService.getJobStats(this.job.id).subscribe(function (stats) { return _this.job.stats = stats; }, function (err) { return console.error(err); });
        }
    };
    return OverallJobProductionComponent;
}());
exports.OverallJobProductionComponent = OverallJobProductionComponent;
