"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".dialog[_ngcontent-%COMP%]{padding:1px 0 0}.dialog[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{font-size:22px;font-weight:300;color:#333;text-align:center}.dialog[_ngcontent-%COMP%]   .content-body[_ngcontent-%COMP%]{text-align:left;font-size:14px;color:#72839b;padding:0 64px}.dialog[_ngcontent-%COMP%]   .dialog-body[_ngcontent-%COMP%]{padding:0 65px}.dialog[_ngcontent-%COMP%]   .dialog-body[_ngcontent-%COMP%]   .reason[_ngcontent-%COMP%]{height:120px;resize:none;font-size:14px;font-weight:400;line-height:1.36;color:#354052}.dialog[_ngcontent-%COMP%]   .dialog-body[_ngcontent-%COMP%]   .check[_ngcontent-%COMP%]{padding-top:10px}.dialog[_ngcontent-%COMP%]   .dialog-actions[_ngcontent-%COMP%]{margin:30px;text-align:center;padding-bottom:20px}.dialog[_ngcontent-%COMP%]   .dialog-actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:140px;height:46px}.dialog[_ngcontent-%COMP%]   .dialog-actions[_ngcontent-%COMP%]   button.btn[_ngcontent-%COMP%]{margin-bottom:0}.dialog[_ngcontent-%COMP%]   .dialog-actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]:first-child{margin-right:30px}"];
exports.styles = styles;
