import { clone } from 'lodash';

import { Resource } from '../shared/resource';
import { Invoice } from '../invoices/invoice';
import { Ticket } from '../tickets/ticket';
import { SurchargeTemplate } from '../surcharge-templates/surcharge-template';

export class Surcharge extends Resource {
  id: string;
  invoice: Invoice;
  scaleticket: Ticket;
  title: string;
  time: string;
  createdAt: string;
  description: string;
  amount: string;
  positive = false;
  loading = false;
  template: SurchargeTemplate;
  kind = 'invoice'; // invoice or scaleticket
  selectedSurchargeTemplate;

  url(action): string {
    return '/surcharges/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
