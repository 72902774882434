import { Injectable } from "@angular/core";
import { ResourceService } from "../shared/resource.service";
import { ShiftReport } from "./shift-report";
import { HttpClient } from "@angular/common/http";
import { ShiftReportSerializer } from "./shift-report.serializer";

@Injectable()
export class ShiftDashboardService extends ResourceService<ShiftReport> {
  constructor(http: HttpClient) {
    super(http, 'shifts/dashboard/', new ShiftReportSerializer());
  }
}
