"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var scale_service_1 = require("./scale.service");
var shared_1 = require("../shared");
var ScalesComponent = /** @class */ (function () {
    function ScalesComponent(scaleService, decimalPipe) {
        this.scaleService = scaleService;
        this.decimalPipe = decimalPipe;
        this.loading = false;
        this.errors = [];
        this.scales = [];
        this.loadScalesInterval = 10000;
        this.sortBy = 'name';
        this.sortAsc = true;
    }
    ScalesComponent.prototype.ngOnInit = function () {
        this.getScales();
        this.loadScalesEveryMiliseconds(this.loadScalesInterval);
    };
    ScalesComponent.prototype.ngOnDestroy = function () {
        if (this.scalesReq) {
            this.scalesReq.unsubscribe();
        }
    };
    ScalesComponent.prototype.loadScalesEveryMiliseconds = function (miliseconds) {
        var _this = this;
        this.scalesReq = rxjs_1.interval(miliseconds).subscribe(function (val) {
            _this.getScales();
        });
    };
    ScalesComponent.prototype.getScales = function () {
        var _this = this;
        this.loading = true;
        this.scales = [];
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.scalesReq) {
            this.scalesReq.unsubscribe();
        }
        this.scalesReq = this.scaleService.getScales({
            ordering: order
        }).subscribe(function (scales) {
            _this.scales = scales;
            _this.loading = false;
        }, function (error) {
            _this.errors = shared_1.parseErrors(error);
            _this.loading = false;
        });
    };
    ScalesComponent.prototype.getWeightUnit = function () {
        return this.scales.length > 0 ? this.scales[0].weightUnit : 'lbs';
    };
    ScalesComponent.prototype.getScaleWeight = function (scale) {
        if (scale.status === 'disconnected' || scale.status === 'unstable') {
            return '-';
        }
        return this.decimalPipe.transform(scale.weight);
    };
    ScalesComponent.prototype.truncateTextByElipses = function (value, stringLength) {
        if (stringLength === void 0) { stringLength = 16; }
        if (value === null || value === undefined) {
            return '—';
        }
        if (value.length > stringLength) {
            return value.substring(0, stringLength) + ' ...';
        }
        return value;
    };
    return ScalesComponent;
}());
exports.ScalesComponent = ScalesComponent;
