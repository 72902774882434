"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var PropertyFilterPipe = /** @class */ (function () {
    function PropertyFilterPipe() {
    }
    PropertyFilterPipe.prototype.transform = function (objects, property, value) {
        if (!objects || !objects.length || !property || (typeof (value) !== 'boolean' && !value)) {
            return objects;
        }
        var query = value && value.toLowerCase();
        return objects.filter(function (obj) {
            var result = true;
            if (obj.hasOwnProperty(property)) {
                var prop = obj[property].toString().toLowerCase();
                if (prop) {
                    result = prop.toString().toLowerCase().indexOf(query) > -1;
                }
            }
            else {
                var prop = lodash_1.get(obj, property, null);
                if (prop) {
                    result = prop.toString().toLowerCase().indexOf(query) > -1;
                }
            }
            return result;
        });
    };
    return PropertyFilterPipe;
}());
exports.PropertyFilterPipe = PropertyFilterPipe;
