import { Location } from '../locations/location';
import { BulkEditJob } from './bulk-edit-job';
import { keys } from 'lodash';
import * as moment from 'moment-timezone';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class BulkEditJobsSerialzier {
  /**
  * @param  {any} json
  * @returns BulkEditJob
  */
  fromJson(json: any): BulkEditJob {
    json = camelcaseKeysDeep(json);
    return json;
  }

  /**
  * @param  {BulkEditJob} job
  * @returns any
  */
  toJson(job: BulkEditJob): any {
    let editModelData = {} as BulkEditJob;
    if (job.include && job.include.length) {
      editModelData.include = job.include;
    }
    
    if (job.exclude && job.exclude.length) {
      editModelData.exclude = job.exclude;
    }

    if (job.rate) {
      editModelData.rate = job.rate;
    }
    if (job.invoiceType) {
      editModelData.invoiceType = job.invoiceType;
    }
    if (job.invoiceWeightUnit) {
      editModelData.invoiceWeightUnit = job.invoiceWeightUnit;
    }
    if (job.haulType) {
      editModelData.haulType = job.haulType;
    }
    if (job.haulRate) {
      editModelData.haulRate = job.haulRate;
    }
    if (job.haulWeightUnit) {
      editModelData.haulWeightUnit = job.haulWeightUnit;
    }
    if (job.dates && job.dates.length) {
      editModelData.dates = job.dates.map(x => moment(x).format('YYYY-MM-DD'));
    }
    if (job.material) {
      editModelData.material = job.material;
    }
    if (job.amountNeeded) {
      editModelData.amountNeeded = job.amountNeeded;
    }
    if (job.totalAmountType) {
      editModelData.totalAmountType = job.totalAmountType;
    }
    if (job.dailyDeliveryTarget) {
      editModelData.dailyDeliveryTarget = job.dailyDeliveryTarget;
    }
    if (job.dailyDeliveryTargetType) {
      editModelData.dailyDeliveryTargetType = job.dailyDeliveryTargetType;
    }
    if (job.deliveryInterval) {
      editModelData.deliveryInterval = job.deliveryInterval;
    }
    if (job.deliveryIntervalUnit) {
      editModelData.deliveryIntervalUnit = job.deliveryIntervalUnit;
    }
    if (job.startLocation) {
      editModelData.startLocation = job.startLocation.id;
    }
    if (keys(job.checkinOptions).length) {
      editModelData.checkinOptions = job.checkinOptions;
    }
    if (job.endLocation) {
      editModelData.endLocation = job.endLocation.id;
    }
    if (keys(job.checkoutOptions).length) {
      editModelData.checkoutOptions = job.checkoutOptions;
    }
    return decamelizeKeysDeep(editModelData);
  }
}
