"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
// services
var checkin_service_1 = require("../../../checkins/checkin.service");
var trip_service_1 = require("../../trip.service");
var attachment_service_1 = require("../../../attachments/attachment.service");
// components
var multiple_images_edit_component_1 = require("../../../shared/multiple-images-edit/multiple-images-edit.component");
// models
var condensed_trip_1 = require("../../condensed-trip");
// libs
var shared_1 = require("../../../shared");
// constants
var app_constants_1 = require("../../../app.constants");
var TripHistoryTicketComponent = /** @class */ (function () {
    function TripHistoryTicketComponent(checkinService, tripService, attachmentService) {
        this.checkinService = checkinService;
        this.tripService = tripService;
        this.attachmentService = attachmentService;
        this.loadingImages = [];
        this.unloadingImages = [];
        this.loadingSignatureImages = [];
        this.unloadingSignatureImages = [];
        this.imagesToDelete = [];
        this.errors = [];
    }
    TripHistoryTicketComponent.prototype.ngOnChanges = function () {
        if (this.trip) {
            this.parseAttachments(this.trip);
        }
    };
    TripHistoryTicketComponent.prototype.handleImagesDialogVisibility = function () {
        this.editImagesContainer.handleVisibility();
    };
    TripHistoryTicketComponent.prototype.parseAttachments = function (trip) {
        this.loadingImages = trip.loadingAttachments
            .filter(function (att) { return att.fileType === app_constants_1.TRIPTICKETIMAGETYPE; })
            .map(function (file) { return ({
            id: file.id,
            src: file.file,
        }); });
        this.loadingSignatureImages = trip.loadingAttachments
            .filter(function (att) { return att.fileType === app_constants_1.TRIPSIGNATUREIMAGETYPE; })
            .map(function (file) { return ({
            id: file.id,
            src: file.file,
        }); });
        this.unloadingImages = trip.unloadingAttachments
            .filter(function (att) { return att.fileType === app_constants_1.TRIPTICKETIMAGETYPE; })
            .map(function (file) { return ({
            id: file.id,
            src: file.file,
        }); });
        this.unloadingSignatureImages = trip.unloadingAttachments
            .filter(function (att) { return att.fileType === app_constants_1.TRIPSIGNATUREIMAGETYPE; })
            .map(function (file) { return ({
            id: file.id,
            src: file.file,
        }); });
    };
    TripHistoryTicketComponent.prototype.requestRetake = function (id) {
        var _this = this;
        this.checkinService.requestRetake(id).subscribe(function () {
            _this.trip.retakeStatus = 'requested';
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
        });
    };
    TripHistoryTicketComponent.prototype.undoRequestRetake = function () {
        var _this = this;
        if (this.trip.retakeRequested) {
            this.tripService.undoRetakeRequest(this.trip).subscribe(function () {
                _this.trip.retakeStatus = null;
            }, function (err) {
                _this.errors = shared_1.parseErrors(err);
            });
        }
    };
    TripHistoryTicketComponent.prototype.parseImageOnChange = function (image) {
        if (image && (image.isNew || image.isEdited)) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                image.file = reader_1.result;
                image.src = reader_1.result;
            };
            reader_1.readAsDataURL(image);
            return image;
        }
        if (image && image.isDeleted) {
            this.imagesToDelete.push(image);
        }
        return image;
    };
    TripHistoryTicketComponent.prototype.onLoadingImagesChange = function (images) {
        var _this = this;
        this.loadingImages = images.map(function (img) {
            return img.isNew || img.isEdited
                ? Object.assign(img, {
                    tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                })
                : _this.parseImageOnChange(img);
        });
    };
    TripHistoryTicketComponent.prototype.onLoadingSignaturesChange = function (images) {
        var _this = this;
        this.loadingSignatureImages = images.map(function (img) {
            return img.isNew || img.isEdited
                ? Object.assign(img, {
                    tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                })
                : _this.parseImageOnChange(img);
        });
    };
    TripHistoryTicketComponent.prototype.onUnloadingImagesChange = function (images) {
        var _this = this;
        this.unloadingImages = images.map(function (img) {
            return img.isNew || img.isEdited
                ? Object.assign(img, {
                    tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                })
                : _this.parseImageOnChange(img);
        });
    };
    TripHistoryTicketComponent.prototype.onUnloadingSignaturesChange = function (images) {
        var _this = this;
        this.unloadingSignatureImages = images.map(function (img) {
            return img.isNew || img.isEdited
                ? Object.assign(img, {
                    tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                })
                : _this.parseImageOnChange(img);
        });
    };
    TripHistoryTicketComponent.prototype.uploading = function (uploading) {
        console.log(uploading);
    };
    TripHistoryTicketComponent.prototype.saveImages = function () {
        var _this = this;
        var requests = this.attachmentService.combineTicketImagesAndAttachments(this.imagesToDelete, this.loadingImages, this.loadingSignatureImages, this.unloadingImages, this.unloadingSignatureImages, this.trip.loadingCheckin.id, this.trip.unloadingCheckin.id);
        rxjs_1.forkJoin(requests).subscribe(function (res) {
            _this.handleImagesDialogVisibility();
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
        });
    };
    return TripHistoryTicketComponent;
}());
exports.TripHistoryTicketComponent = TripHistoryTicketComponent;
