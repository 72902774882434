"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./copy-assignments-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../shared/dropdown/dropdown.component.ngfactory");
var i3 = require("../shared/dropdown/dropdown.component");
var i4 = require("../../../node_modules/@angular/material/list/typings/index.ngfactory");
var i5 = require("@angular/material/list");
var i6 = require("@angular/common");
var i7 = require("../../../node_modules/@angular/material/card/typings/index.ngfactory");
var i8 = require("@angular/material/card");
var i9 = require("@angular/forms");
var i10 = require("@angular/material/dialog");
var i11 = require("@ngx-translate/core");
var i12 = require("./copy-assignments-dialog.component");
var i13 = require("./assignment.service");
var i14 = require("../drivers/driver.service");
var styles_CopyAssignmentsDialogComponent = [i0.styles];
var RenderType_CopyAssignmentsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyAssignmentsDialogComponent, data: {} });
exports.RenderType_CopyAssignmentsDialogComponent = RenderType_CopyAssignmentsDialogComponent;
function View_CopyAssignmentsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dropdown", [["title", "Select drivers"]], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.setSelectedDriver($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DropdownComponent_0, i2.RenderType_DropdownComponent)), i1.ɵdid(1, 770048, null, 0, i3.DropdownComponent, [i1.ElementRef, i1.ElementRef], { selectedItems: [0, "selectedItems"], title: [1, "title"], config: [2, "config"], options: [3, "options"] }, { onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedDrivers; var currVal_1 = "Select drivers"; var currVal_2 = _co.driverDropdownConfig; var currVal_3 = _co.driversList; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_CopyAssignmentsDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-item", [["class", "assignment-name mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i4.View_MatListItem_0, i4.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i5.MatListItem, [i1.ElementRef, [2, i5.MatNavList], [2, i5.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 1, "div", [["class", "assignment-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, 2, 0, "i", [["class", "icon-error"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deselectAssignment(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_CopyAssignmentsDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-list-item", [["class", "assignment-name mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i4.View_MatListItem_0, i4.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i5.MatListItem, [i1.ElementRef, [2, i5.MatNavList], [2, i5.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 4, { _lines: 1 }), i1.ɵqud(335544320, 5, { _avatar: 0 }), i1.ɵqud(335544320, 6, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 1, "div", [["class", "assignment-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, 2, 0, "i", [["class", "icon-error"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deselectAssignment(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.job.name; _ck(_v, 6, 0, currVal_2); }); }
function View_CopyAssignmentsDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i4.View_MatList_0, i4.RenderType_MatList)), i1.ɵdid(1, 704512, null, 0, i5.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CopyAssignmentsDialogComponent_5)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assignmentList; _ck(_v, 3, 0, currVal_0); }, null); }
function View_CopyAssignmentsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-card", [["class", "assignments-list mat-card"]], null, null, null, i7.View_MatCard_0, i7.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i8.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Assignments"])), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i4.View_MatList_0, i4.RenderType_MatList)), i1.ɵdid(5, 704512, null, 0, i5.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CopyAssignmentsDialogComponent_3)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CopyAssignmentsDialogComponent_4)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedAssignment; _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.selectedAssignment.length == 0); _ck(_v, 9, 0, currVal_1); }, null); }
function View_CopyAssignmentsDialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-list-item", [["class", "driver-name mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i4.View_MatListItem_0, i4.RenderType_MatListItem)), i1.ɵdid(1, 1228800, null, 3, i5.MatListItem, [i1.ElementRef, [2, i5.MatNavList], [2, i5.MatList], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 7, { _lines: 1 }), i1.ɵqud(335544320, 8, { _avatar: 0 }), i1.ɵqud(335544320, 9, { _icon: 0 }), (_l()(), i1.ɵted(5, 2, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); }); }
function View_CopyAssignmentsDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-card", [["class", "drivers-list mat-card"]], null, null, null, i7.View_MatCard_0, i7.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i8.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drivers"])), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "mat-list", [["class", "mat-list mat-list-base"]], null, null, null, i4.View_MatList_0, i4.RenderType_MatList)), i1.ɵdid(5, 704512, null, 0, i5.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CopyAssignmentsDialogComponent_7)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedDrivers; _ck(_v, 7, 0, currVal_0); }, null); }
function View_CopyAssignmentsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i9.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, [["copyAssignment", 4]], 0, i9.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i9.ControlContainer, null, [i9.NgForm]), i1.ɵdid(4, 16384, null, 0, i9.NgControlStatusGroup, [[4, i9.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(6, 81920, null, 0, i10.MatDialogTitle, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i11.TranslateDirective, [i11.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Select Assignments and Drivers"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 16, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i10.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 14, "div", [["class", "body form-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "dropdowns-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.allSelectAssignments($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "label", [["for", "select_all"]], [[1, "data-label", 0]], null, null, null, null)), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(20, 0, null, null, 1, "dropdown", [["title", "Select assignments"]], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.setSelectedAssignment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DropdownComponent_0, i2.RenderType_DropdownComponent)), i1.ɵdid(21, 770048, null, 0, i3.DropdownComponent, [i1.ElementRef, i1.ElementRef], { selectedItems: [0, "selectedItems"], title: [1, "title"], config: [2, "config"], options: [3, "options"], disabled: [4, "disabled"] }, { onSelect: "onSelect" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyAssignmentsDialogComponent_1)), i1.ɵdid(23, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "selection-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyAssignmentsDialogComponent_2)), i1.ɵdid(26, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyAssignmentsDialogComponent_6)), i1.ɵdid(28, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openConfirmDialog($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Copy"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_8 = ""; _ck(_v, 8, 0, currVal_8); var currVal_11 = _co.selectedAssignment; var currVal_12 = "Select assignments"; var currVal_13 = _co.assignmentDropdownConfig; var currVal_14 = _co.displayAssignmentName; var currVal_15 = ((_co.showAllAssignments && !_co.deselectedAssignment) || _co.allAssignmentSelected); _ck(_v, 21, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = (((_co.assignmentList.length > 0) || (_co.selectedAssignment.length > 0)) || _co.allAssignmentSelected); _ck(_v, 23, 0, currVal_16); var currVal_17 = (_co.showAllAssignments || _co.allAssignmentSelected); _ck(_v, 26, 0, currVal_17); var currVal_18 = (_co.selectedDrivers.length > 0); _ck(_v, 28, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 6).id; _ck(_v, 5, 0, currVal_7); var currVal_9 = _co.allAssignmentSelected; _ck(_v, 17, 0, currVal_9); var currVal_10 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Select all assignments")), ""); _ck(_v, 18, 0, currVal_10); }); }
exports.View_CopyAssignmentsDialogComponent_0 = View_CopyAssignmentsDialogComponent_0;
function View_CopyAssignmentsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "copy-assignments-dialog", [], null, null, null, View_CopyAssignmentsDialogComponent_0, RenderType_CopyAssignmentsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i12.CopyAssignmentsDialogComponent, [i10.MatDialog, i10.MatDialogRef, i13.AssignmentService, i14.DriverService, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CopyAssignmentsDialogComponent_Host_0 = View_CopyAssignmentsDialogComponent_Host_0;
var CopyAssignmentsDialogComponentNgFactory = i1.ɵccf("copy-assignments-dialog", i12.CopyAssignmentsDialogComponent, View_CopyAssignmentsDialogComponent_Host_0, {}, {}, []);
exports.CopyAssignmentsDialogComponentNgFactory = CopyAssignmentsDialogComponentNgFactory;
