"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var locations_component_1 = require("./locations.component");
var new_location_component_1 = require("./new-location.component");
var edit_location_component_1 = require("./edit-location.component");
var location_manager_component_1 = require("./location-manager/location-manager.component");
var routes = [
    { path: '', component: locations_component_1.LocationsComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'new', component: new_location_component_1.NewLocationComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'manager', component: location_manager_component_1.LocationManagerComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'manager/:view', component: location_manager_component_1.LocationManagerComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'active', component: location_manager_component_1.LocationManagerComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'active/:view', component: location_manager_component_1.LocationManagerComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: ':id/edit', component: edit_location_component_1.EditLocationComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: ':view', component: locations_component_1.LocationsComponent, canActivate: [auth_guard_1.AuthGuard] },
];
var LocationsRoutingModule = /** @class */ (function () {
    function LocationsRoutingModule() {
    }
    return LocationsRoutingModule;
}());
exports.LocationsRoutingModule = LocationsRoutingModule;
