"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var JoinPipe = /** @class */ (function () {
    function JoinPipe() {
    }
    JoinPipe.prototype.transform = function (input, character) {
        if (character === void 0) { character = ''; }
        if (!Array.isArray(input)) {
            return input;
        }
        return input.join(character);
    };
    return JoinPipe;
}());
exports.JoinPipe = JoinPipe;
