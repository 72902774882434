import { EndOfDayOverallStats } from './end-of-day-overall-stats';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class EndOfDayOverallStatsSerializer {
  /**
  * @param  {any} json
  * @returns {EndOfDayOverallStats} EndOfDayOverallStats
  */
  fromJson(json: any): EndOfDayOverallStats {
    json = camelcaseKeysDeep(json);
    const endOfDayOverallStats = new EndOfDayOverallStats();
    if (!json) { return endOfDayOverallStats; }

    endOfDayOverallStats.driversCount = json.driversCount;
    endOfDayOverallStats.plannedTrips = json.plannedTrips;
    endOfDayOverallStats.completedTrips = json.completedTrips;
    endOfDayOverallStats.totalTrips = json.totalTrips;
    endOfDayOverallStats.invoiceTotal = json.invoiceTotal;
    endOfDayOverallStats.expenseTotal = json.expenseTotal;

    return endOfDayOverallStats;
  }

  /**
  * @param  {EndOfDayOverallStats} endOfDayOverallStats
  * @returns any
  */
  toJson(endOfDayOverallStats: EndOfDayOverallStats): any {
    let json = {
      driversCount: endOfDayOverallStats.driversCount,
      plannedTrips: endOfDayOverallStats.plannedTrips,
      completedTrips: endOfDayOverallStats.completedTrips,
      totalTrips: endOfDayOverallStats.totalTrips,
      invoiceTotal: endOfDayOverallStats.invoiceTotal,
      expenseTotal: endOfDayOverallStats.expenseTotal,
    };
    return decamelizeKeysDeep(json);
  }
}
