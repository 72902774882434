"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var job_event_1 = require("../job-events/job-event");
var connection_service_1 = require("../connections/connection.service");
var carrier_1 = require("../carriers/carrier");
var MobileDispatchCarriersComponent = /** @class */ (function () {
    function MobileDispatchCarriersComponent(connectionService) {
        this.connectionService = connectionService;
        this.selectCarrier = new core_1.EventEmitter();
        this.carriers = [];
        this.loading = false;
        this.errors = [];
    }
    MobileDispatchCarriersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.scrollEvent && !this.loading &&
            this.scrollEvent && this.scrollEvent.target &&
            this.scrollEvent.target.scrollTop >
                this.scrollEvent.target.scrollHeight -
                    this.scrollEvent.target.clientHeight * 3) {
            this.getCarriers(true);
        }
    };
    MobileDispatchCarriersComponent.prototype.ngOnInit = function () {
        this.getCarriers();
    };
    MobileDispatchCarriersComponent.prototype.ngOnDestroy = function () {
        if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
            this.carriersReq.unsubscribe();
        }
    };
    MobileDispatchCarriersComponent.prototype.select = function (carrier) {
        this.selectCarrier.emit(carrier);
    };
    MobileDispatchCarriersComponent.prototype.getCarriers = function (next) {
        var _this = this;
        if (next === void 0) { next = false; }
        this.loading = true;
        if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
            this.carriersReq.unsubscribe();
        }
        var connectionServiceRequest = this.connectionService.list({
            ordering: 'organization__name',
            allow_dispatch: 'True',
            is_carrier: 'True'
        });
        if (!next) {
            this.carriers = [
                new carrier_1.Carrier({ name: 'My Drivers', id: 'my_drivers' }),
                new carrier_1.Carrier({ name: 'All Carriers', id: 'all_carriers' }),
            ];
            if (this.jobEvent
                && this.jobEvent.job
                && this.jobEvent.job.project
                && this.jobEvent.job.project.customerOrganization
                && this.jobEvent.job.project.customerOrganization['hasLeasedOrgs']) {
                this.carriers.push({ name: 'Leased', id: 'all_leased' });
            }
        }
        else {
            connectionServiceRequest = this.connectionService.listNext();
        }
        this.carriersReq = connectionServiceRequest && connectionServiceRequest.subscribe(function (connections) {
            var _carriers = connections.map(function (connection) {
                return {
                    name: connection.organization.name,
                    id: connection.organization.carrier.id
                };
            });
            _this.carriers = _this.carriers.concat(_carriers);
        }, function (err) {
            _this.errors = err;
        }, function () { return _this.loading = false; });
        if (!connectionServiceRequest) {
            this.loading = false;
        }
    };
    return MobileDispatchCarriersComponent;
}());
exports.MobileDispatchCarriersComponent = MobileDispatchCarriersComponent;
