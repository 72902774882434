import { Component } from '@angular/core';

@Component({
    selector: 'ruckit-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss']
  })
  export class ResourcesComponent {
    constructor() {}
  }
