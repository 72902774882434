<form #inviteDriver="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Invite a Driver</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="driver-image" *ngIf='model.image'>
        <img src="{{ model.image }}" />
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group first-name" fxFlex="50">
          <label translate>First</label>
          <input type="text" name="first-name" placeholder="{{ 'First Name' | translate }}" [(ngModel)]="model.profile.firstName" #firstName="ngModel" />
          <span [ngClass]="{'ng-valid': firstName.valid, 'ng-invalid': firstName.invalid, 'ng-pristine': firstName.pristine}"></span>
        </div>

        <div class="field-group last-name" fxFlex="50">
          <label translate>Last</label>
          <input type="text" name="last-name" placeholder="{{ 'Last Name' | translate }}" [(ngModel)]="model.profile.lastName" #lastName="ngModel" />
          <span [ngClass]="{'ng-valid': lastName.valid, 'ng-invalid': lastName.invalid, 'ng-pristine': lastName.pristine}"></span>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group cdl" fxFlex="50">
          <label translate>CDL</label>
          <input type="text" name="cdl" placeholder="{{ 'CDL' | translate }}" [(ngModel)]="model.cdl" #cdl="ngModel" />
          <span [ngClass]="{'ng-valid': cdl.valid, 'ng-invalid': cdl.invalid, 'ng-pristine': cdl.pristine}"></span>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group email" fxFlex="100">
          <label class="required" translate>Email</label>
          <input type="text" name="email" placeholder="{{ 'Email' | translate }}" required [(ngModel)]="model.profile.email" #email="ngModel" />
          <span [ngClass]="{'ng-valid': email.valid, 'ng-invalid': email.invalid, 'ng-pristine': email.pristine}"></span>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group address" fxFlex="100">
          <label translate>Address</label>
          <input type="text" name="address" placeholder="{{ 'Address' | translate }}" [(ngModel)]="model.street" #street="ngModel" />
          <span [ngClass]="{'ng-valid': street.valid, 'ng-invalid': street.invalid, 'ng-pristine': street.pristine}"></span>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group city" fxFlex="65">
          <label translate>City</label>
          <input type="text" name="city" placeholder="{{ 'City' | translate }}" [(ngModel)]="model.city" #city="ngModel" />
          <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
        </div>

        <div class="field-group state" fxFlex="35">
          <label translate>State</label>
          <select name="state" required (change)="selectState($event.target.value)">
            <option value="" selected disabled hidden>{{ 'Select State' | translate }}</option>
            <option *ngFor="let stateOption of stateOptions" [value]="stateOption.name">{{ stateOption.name }}</option>
          </select>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group zip" fxFlex="35">
          <label>Zip</label>
          <input type="text" name="zipcode" placeholder="Zip" [(ngModel)]="model.zipcode" #zipcode="ngModel" />
          <span [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
        </div>

        <div class="field-group phone" fxFlex="65">
          <label>Phone</label>
          <input type="text" name="phone" placeholder="Phone" [(ngModel)]="model.profile.phoneNumber" #phoneNumber="ngModel" />
          <span [ngClass]="{'ng-valid': phoneNumber.valid, 'ng-invalid': phoneNumber.invalid, 'ng-pristine': phoneNumber.pristine}"></span>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="inviteDriver.form.valid && submit(); false"
            [disabled]="!inviteDriver.form.valid || loading"
            [ngClass]="{loading:loading}">
      Invite Driver
    </button>
  </div>
</form>
