"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var scale_1 = require("./scale");
var api_service_1 = require("../shared/api.service");
var ScaleService = /** @class */ (function () {
    function ScaleService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl + 'scales/';
    }
    ScaleService.prototype.getScale = function (scaleId) {
        var scaleUrl = this.baseUrl + scaleId + '/';
        return this.http.get(scaleUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    ScaleService.prototype.getScales = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var scalesUrl = this.baseUrl;
        return this.http.get(scalesUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    ScaleService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (scale) {
                return new scale_1.Scale(scale);
            });
        }
        else if (resObj) {
            return new scale_1.Scale(resObj);
        }
    };
    ScaleService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    return ScaleService;
}());
exports.ScaleService = ScaleService;
