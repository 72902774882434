"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["@media screen and (max-width:720px){input#search-field.search.condensed[_ngcontent-%COMP%]{overflow:hidden;width:36px;height:36px;transition:width .3s;padding:0}input#search-field.search.condensed.open[_ngcontent-%COMP%]{width:100%;padding:0 12px 0 36px}.icon-search.left[_ngcontent-%COMP%]{left:8px;right:auto;color:#8e8e93}}"];
exports.styles = styles;
