import { clone } from 'lodash';

import { Resource } from '../shared/resource';
import { Organization } from '../organizations/organization';
import { Driver } from '../drivers/driver';

type Notifications = {
  email: boolean,
  push: boolean,
  sms: boolean,
  web: boolean
};

export type DriverNotifications = {
  autoPilot: {
    driverNewTicketNotification: Notifications,
    driverShiftStartedNotification: Notifications
  },
  dispatch: {
    afCarrierDispatchNotification: Notifications,
    ticketNotification: Notifications,
    truckGpsDispatchNotification: Notifications
  },
  drivers: {
    driverNotification: Notifications
  },
  jobs: {
    jobNotification: Notifications,
    jobChangedNotification: Notifications,
    truckTicketedWithoutAssignmentNotification: Notifications,
    driverRejectionAssignmentNotification: Notifications
  }
};

export class User extends Resource {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  token: string;
  organization: Organization;
  organizationName: string;
  createdAt: string;
  driverNotificationEmail: boolean;
  jobNotificationEmail: boolean;
  notifications: DriverNotifications;
  afCarrierDispatchNotificationEmail: boolean;
  lastModified: string;
  phoneNumber: string;
  title: string;
  status: string;
  image: string;
  driver: Driver;
  remittanceInfo: string;
  favoriteTags: any[];
  favoriteLocation: string;
  readOnlyUser: boolean;
  tags: any[];
  isDriver: boolean;
  isCarrier: boolean;
  isRuckit: boolean;
  isCrh: boolean;
  isScaleit: boolean;
  passwordCurrent: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  workOrderSignatureImage: string;
  workOrderSignatureName: string;
  sidebar: boolean;
  features: any;
  enabledFeatures: string[];
  hasSignature: boolean;
  lafargeRegion: string;

  url(action): string {
    return '/team/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
