<div class="driver-details-container" *ngIf="driver">
  <div class="driver-info-container">
    <div class="header">
      <h3 translate>Driver Info</h3>
    </div>
    <div class="details">
      <div class="detail-row">
        <span class="detail-key" translate>Driver</span>
        <span class="detail-value">{{ driver.name || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Truck</span>
        <span class="detail-value">{{ driver.truckName || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Truck Type</span>
        <span class="detail-value">{{
          driver.truck?.truckTypeName || '-'
        }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Duty Status</span>
        <span class="detail-value">{{ driver.dutyStatus || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Phone Number</span>
        <span class="detail-value">{{
          driver?.profile.phoneNumber || '-'
        }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Email</span>
        <span class="detail-value">{{ driver.profile?.email || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Address</span>
        <span class="detail-value">{{ driver.street || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>City</span>
        <span class="detail-value">{{ driver.city || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>State</span>
        <span class="detail-value">{{
          (driver.state | state)?.name || '-'
        }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Country</span>
        <span class="detail-value">{{
          (driver.country | country)?.name || '-'
        }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Replay</span>
        <span class="detail-value">
          <a
            href="/replay?date={{ currentDate | date: 'yyyyMMdd' }}&driver={{
              driver.id
            }}"
            target="_blank"
            translate
            >View Replay</a
          >
        </span>
      </div>
    </div>
  </div>

  <!-- driver health -->
  <div class="driver-health-container">
    <div class="header">
      <h3 translate>Driver Health</h3>
    </div>
    <div class="details">
      <div class="detail-row">
        <span class="detail-key" translate>Shift Status</span>
        <span class="detail-value">{{ driver.displayShiftStatus || '-' }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Issues</span>
        <div class="detail-value">
          <span *ngIf="!driver.lastDeviceStatusEvent" translate
            >No Device Status</span
          >
          <ng-container *ngIf="driver.lastDeviceStatusEvent">
            <span translate *ngIf="driver.deviceErrors.length === 1">
              {{ driver.deviceErrors.length }} Issue
            </span>
            <span translate *ngIf="driver.deviceErrors.length > 1">
              {{ driver.deviceErrors.length }} Issues
            </span>
            <span translate *ngIf="!driver.deviceErrors.length">No Issues</span>
          </ng-container>
        </div>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Location Authorization</span>
        <div class="detail-value">
          <ng-container *ngIf="driver.lastDeviceStatusEvent">
            <ng-container
              *ngIf="
                driver.lastDeviceStatusEvent.locationAuthorization !== 'Always'
              "
            >
              <span translate>Not Always</span>
            </ng-container>
            <ng-container
              *ngIf="
                driver.lastDeviceStatusEvent.locationAuthorization === 'Always'
              "
            >
              <span translate>Always</span>
            </ng-container>
          </ng-container>
          <span *ngIf="!driver.lastDeviceStatusEvent">-</span>
        </div>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Low Power Mode</span>
        <div class="detail-value">
          <ng-container *ngIf="driver.lastDeviceStatusEvent">
            <span *ngIf="driver.lastDeviceStatusEvent.lowPowerMode" translate
              >Enabled</span
            >
            <span *ngIf="!driver.lastDeviceStatusEvent.lowPowerMode" translate
              >Disabled</span
            >
          </ng-container>
          <span *ngIf="!driver.lastDeviceStatusEvent">-</span>
        </div>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Background App Refresh</span>
        <div class="detail-value">
          <ng-container *ngIf="driver.lastDeviceStatusEvent">
            <span
              *ngIf="driver.lastDeviceStatusEvent.backgroundAppRefresh"
              translate
              >Enabled</span
            >
            <span
              *ngIf="!driver.lastDeviceStatusEvent.backgroundAppRefresh"
              translate
              >Disabled</span
            >
          </ng-container>
          <span *ngIf="!driver.lastDeviceStatusEvent">-</span>
        </div>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>App Version</span>
        <span class="detail-value">{{
          driver.lastDeviceStatusEvent?.data?.applicationVersion || '-'
        }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Device OS</span>
        <span class="detail-value">{{
          driver.lastDeviceStatusEvent?.data?.deviceOs || '-'
        }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-key" translate>Last Health Update</span>
        <span class="detail-value">{{
          (driver.lastDeviceStatusEvent?.createdAt | date: 'MM/dd/yyyy') || '-'
        }}</span>
      </div>
    </div>
  </div>
</div>
