"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
require("rxjs/add/operator/map");
var environment_1 = require("../../environments/environment");
var rate_type_1 = require("./rate-type");
var api_service_1 = require("../shared/api.service");
var RateTypeService = /** @class */ (function () {
    function RateTypeService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.params = new http_1.HttpParams();
        this.count = 0;
        this.params = new http_1.HttpParams();
    }
    RateTypeService.prototype.list = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    _this.params = _this.params.set(key, query[key].toString());
                }
            });
        }
        var rateTypesUrl = '/assets/files/rate-types.json';
        return this.http.get(rateTypesUrl, {
            headers: api_service_1.requestHeaders(),
            params: this.params
        }).map(function (res) {
            return _this.extractData(res);
        });
    };
    RateTypeService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (rateType) {
                return new rate_type_1.RateType(rateType);
            });
        }
        else if (resObj) {
            return new rate_type_1.RateType(resObj);
        }
        else {
            return {};
        }
    };
    return RateTypeService;
}());
exports.RateTypeService = RateTypeService;
