<div class="punch-cards" *ngIf="(!loading && punchCards.length !== 0) || loading || checkingDuplicates" [ngClass]="{loading: loading}">
  <div class="section-header">
    <div class="left-actions">
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Punch Cards Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Punch Card Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Punch Cards Selected' | translate
            }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='punch-card-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Punch Cards' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Punch Card' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Punch Cards' | translate }}</ng-template>
        </span>
        <span *ngIf="loading" class='punch-card-results' translate>
          Loading Punch Cards...
        </span>
      </div>
      <a (click)="openAddPunchCard()" class="new-punch-card">
        <i class="icon-plus-circle"></i>
      </a>
      <action-menu title="Actions">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a *ngIf="option.link" mat-menu-item [routerLink]="" [queryParams]="{ returnTo: '/invoices/' + this.invoice.id + '/edit' }">{{ option.name }}</a>
          <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action)">{{ option.name }}</a>
        </ng-container>
      </action-menu>
      <input type="checkbox" class="toggle duplicate-check" [(ngModel)]="checkingDuplicates" (change)="duplicateCheck()" />
      <label attr.data-label="{{ 'Show Duplicates' | translate }}" for="duplicate_check"></label>
    </div>
    <div class="right-actions">
      <div class="invoice-total">
        {{ invoice.punchcardsAmount || 0 | currency : 'USD' : 'symbol' }}
      </div>
    </div>
  </div>

  <div class="list-table condensed">
    <div class="table-body" [ngClass]="{'full-height': loading && punchCards.length === 0}">
      <div class="header">
        <div class="head selector">
          <label>
            <input type="checkbox" (change)="selector($event)" [(ngModel)]="allSelected" />
          </label>
        </div>
        <div class="head ticket-number sort" (click)="customSort('ticket_number_ordering', 'loading')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loading' && sortParameter === 'ticket_number_ordering'}"
          translate>
          Ticket #
        </div>
        <div class="head date sort" (click)="sort('jobevent__shift1_start')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__shift1_start'}"
          translate>
          Date
        </div>
        <div class="head driver sort" (click)="sort('driver__profile__first_name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__first_name'}"
          translate>
          Driver - Truck
        </div>
        <div class="head amount" translate>
          Quantity
        </div>
        <div class="head rate" translate>
          Rate
        </div>
        <div class="head total" translate>
          Total
        </div>
        <div class="head expense" translate>
          Expense
        </div>
        <div class="head actions" translate></div>
      </div>

      <div *ngFor="let punchCard of punchCards" class="row" [ngClass]="{selected: allSelected || punchCard.selected}">
        <div class="selector">
          <label>
            <input type="checkbox" (change)="selector($event, punchCard)" [checked]="allSelected || punchCard.selected" />
          </label>
        </div>
        <div class="ticket-number">
          <a [routerLink]="punchCard.url('edit')" [queryParams]="{ returnTo: '/invoices/' + this.invoice.id + '/edit' }">
            <div class="ticket-photo" *ngIf="!punchCard.hasTicketImage">
              <div class="missing-image">!</div>
            </div>
            <div class="ticket-photo" *ngIf="punchCard.hasTicketImage">
              <img [src]="punchCard.inlineTicket" />
            </div>
            <div class="value" [ngClass]="{'missing-required': !punchCard.ticketNumber}">
              {{ punchCard.ticketNumber }}
            </div>
          </a>
        </div>
        <div class="date">
          <div class="value">{{ punchCard.jobDate | date:'shortDate' || '&nbsp;' }}</div>
        </div>
        <div class="driver">
          <div class="value">{{ punchCard.driver || '&nbsp;' }} - {{ punchCard.truckName || '&nbsp;' }}</div>
        </div>
        <div class="amount">
          <div class="value" [ngClass]="{'missing-required': !punchCard.invoiceQuantity}">
            {{ punchCard.invoiceQuantity || '!' }} {{ punchCard.pluralizedInvoiceRateUnit(punchCard.invoiceQuantity !==
            1) || '&nbsp;' | titleCase }}
          </div>
        </div>
        <div class="rate">
          <div class="value" [ngClass]="{'missing-required': !punchCard.invoiceQuantity}">
            {{ (punchCard.invoiceRate | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }} per {{ punchCard.invoiceRateUnit ||
            '&nbsp;' | titleCase }}
          </div>
        </div>
        <div class="total">
          <div class="value" [ngClass]="{'missing-required': !punchCard.invoiceQuantity}">
            {{ (punchCard.invoiceTotal | currency : 'USD' : 'symbol') || '&nbsp;' }}
          </div>
        </div>
        <div class="expense">
          <div class="value" [ngClass]="{'missing-required': !punchCard.invoiceQuantity}" title="{{ (punchCard.haulTotal | currency : 'USD' : 'symbol') || '&nbsp;' }} per {{ punchCard.haulRateUnit || '&nbsp;' | titleCase }}">
            {{ (punchCard.haulTotal | currency : 'USD' : 'symbol') || '&nbsp;' }}
          </div>
        </div>
        <div class="actions">
          <action-menu icon="icon-more">
            <ng-container *ngFor="let option of punchCard.filterOptions(menuOptions)">
              <a *ngIf="option.link" mat-menu-item [routerLink]="punchCard.url(option.action)" [queryParams]="{ returnTo: '/invoices/' + this.invoice.id + '/edit' }">{{ option.name }}</a>
              <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, punchCard)">{{ option.name }}</a>
            </ng-container>
          </action-menu>
        </div>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading"></my-loader>
    </div>
  </div>

  <ruckit-pagination *ngIf="(!loading && count) || loading" [totalCount]="count" [currentPage]="page" (page)="pageChange($event)"></ruckit-pagination>
</div>

<div class="punch-cards no-results" *ngIf="!loading && punchCards.length === 0 && !checkingDuplicates">
  <div class="section-header">
    <div class="left-actions">
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Punch Cards Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Punch Card Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Punch Cards Selected' | translate
            }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='punch-card-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Punch Cards' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Punch Card' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Punch Cards' | translate }}</ng-template>
        </span>
      </div>
      <a (click)="openAddPunchCard()" class="new-punch-card">
        <i class="icon-plus-circle"></i>
      </a>
    </div>
    <div class="right-actions"></div>
  </div>
</div>
