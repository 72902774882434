import { Component, Output, EventEmitter, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';

import { Driver } from '../driver';
import { DriverContextEvent } from '../driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'driver-daily',
  templateUrl: './driver-daily.component.html',
  styleUrls: ['./driver-daily.component.scss'],
})
export class DriverDailyComponent {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false,
  };
  @Input() search = '';
  @Input() loading = true;
  @Input() driver: Driver;
  @Input() reportDate: Date;
  @Input() drivers: Driver[] = [];
  @Input() filtersApplied = false;
  @Output() selectedDriver: EventEmitter<Driver> = new EventEmitter<Driver>();
  @Output() changedSearch: EventEmitter<string> = new EventEmitter<string>();

  contextMenuOpened = false;
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor(private deviceDetectorService: DeviceDetectorService) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop(),
    };
  }

  changeSearch(term?: string) {
    this.search = term;
    this.changedSearch.emit(term);
  }

  selectDriver(driver: Driver) {
    this.driver = driver;
    this.selectedDriver.emit(driver);
  }

  openContextMenu(event: any, driverId) {
    this.contextMenuOpened = true;
    this.contextMenuEventSubject.next({
      event,
      driverId,
    });
  }
}
