<form #inviteEmployee="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>{{ data.title || ('Select Pay Time' | translate) }}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="field-group full-width">
      <label translate>Duration Options</label>
      <dropdown #durationSelect
                title="Select Interval"
                [config]="durationDropdownConfig"
                [options]="durationOptions"
                (onSelect)="selectedOption = $event"></dropdown>
    </div>
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="submit()"
            translate>
      Generate
    </button>
  </div>
</form>
