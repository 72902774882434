import { TranslateService } from '@ngx-translate/core';

export const DISPLAYEDCOLUMNS: string[] = [
  'select',
  'job-date',
  'job-title',
  'start-time',
  'end-time',
  'trip-duration',
  'loading-time',
  'transit-time',
  'unloading-time',
  'load-ticket-number',
  'load-ticket-weight',
  'unload-ticket-number',
  'unload-ticket-weight',
  'carrier',
  'driver',
  'truck',
  'duration',
  'payable',
  'customer',
  'invoice-rate',
  'haul-rate',
];

export const AVAILABLECOLUMNS = (
  translationService: TranslateService
): {
  key: string;
  title?: string;
  sortable?: boolean;
  sortBy?: string;
}[] => [
  { key: 'select' },
  {
    key: 'job-date',
    title: translationService.instant('Job Date'),
    sortable: true,
    sortBy: 'job_date',
  },
  {
    key: 'job-title',
    title: translationService.instant('Job'),
    sortable: true,
    sortBy: 'jobevent__job__name',
  },
  {
    key: 'start-time',
    title: translationService.instant('Start Time'),
    sortable: true,
    sortBy: 'jobevent__shift1_start',
  },
  {
    key: 'end-time',
    title: translationService.instant('End Time'),
    sortable: true,
    sortBy: 'jobevent__shift1_end',
  },
  {
    key: 'trip-duration',
    title: translationService.instant('Trip Duration'),
    sortable: true,
    sortBy: 'duration',
  },
  {
    key: 'loading-time',
    title: translationService.instant('Loading Time'),
    sortable: true,
    sortBy: 'duration',
  },
  {
    key: 'transit-time',
    title: translationService.instant('Transit Time'),
    sortable: true,
    sortBy: 'travel_time',
  },
  {
    key: 'unloading-time',
    title: translationService.instant('Unloading Time'),
    sortable: true,
    sortBy: 'travel_time',
  },
  {
    key: 'load-ticket-number',
    title: translationService.instant('Load Ticket #'),
    sortable: true,
    sortBy: 'ticket_number_ordering',
  },
  {
    key: 'load-ticket-weight',
    title: translationService.instant('Load Wt'),
    sortable: true,
    sortBy: 'weight_ordering',
  },
  {
    key: 'unload-ticket-number',
    title: translationService.instant('Unload Ticket #'),
    sortable: true,
    sortBy: 'ticket_number_ordering',
  },
  {
    key: 'unload-ticket-weight',
    title: translationService.instant('Unload Wt'),
    sortable: true,
    sortBy: 'weight_ordering',
  },
  {
    key: 'carrier',
    title: translationService.instant('Carrier'),
    sortable: true,
    sortBy: 'driver__carrier__name',
  },
  {
    key: 'driver',
    title: translationService.instant('Driver'),
    sortable: true,
    sortBy: 'driver__profile__first_name',
  },
  {
    key: 'truck',
    title: translationService.instant('Truck'),
    sortable: true,
    sortBy: 'truck__name',
  },
  { key: 'duration', title: translationService.instant('Duration') },
  {
    key: 'payable',
    title: translationService.instant('Payable To'),
    sortable: true,
    sortBy: 'jobevent__owner_organization__name',
  },
  {
    key: 'customer',
    title: translationService.instant('Customer'),
    sortable: true,
    sortBy: 'jobevent__customer_organization__name',
  },
  {
    key: 'invoice-rate',
    title: translationService.instant('Invoice Rate'),
  },
  { key: 'haul-rate', title: translationService.instant('Haul Rate') },
];
