import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/index';

export type S3PolicyFields = {
  AWSAccessKeyId: string;
  key: string;
  policy: string;
  signature: string;
};

export type S3PolicyData = {
  fields: S3PolicyFields,
  url: string
};

@Injectable()
export class UploadService {
  baseUrl = environment.serverUrl;
  public count: any;
  progress$: any;
  progress: any;

  constructor(private http: HttpClient) { }

  getS3Policy(path = 's3'): Observable<S3PolicyData> {
    let url = `${this.baseUrl}organizations/${path}/`;

    return this.http.get(url, { headers: requestHeaders() }).pipe(
      map(res => { return <S3PolicyData>res; }),
      catchError(handleError)
    );
  }

  uploadToS3(policy: S3PolicyData, fileData: string | File, fileType = 'text/csv') {
    let body = new FormData();
    const blob = typeof fileData === 'string' ? new Blob([fileData], { type: fileType }) : fileData;
    body.append('Content-Type', fileType);
    body.append('key', policy.fields.key);
    body.append('AWSAccessKeyId', policy.fields.AWSAccessKeyId);
    body.append('acl', 'public-read');
    body.append('Policy', policy.fields.policy);
    body.append('Signature', policy.fields.signature);
    body.append('file', blob);

    return this.http.post(policy.url, body);
  }

  generateUUID(): string {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  convertDataUriToFile(data: any, fileName: string): File {
    let arr = data.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }
}
