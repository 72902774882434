"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var connection_service_1 = require("../connections/connection.service");
var index_1 = require("../shared/dialogs/index");
var api_service_1 = require("../shared/api.service");
var add_employee_dialog_component_1 = require("../team/add-employee-dialog.component");
/**
 * Accounts Component: Displays the connections, ordered by organization name
 * available space.
 *
 * The Accounts table is rendered using the FancyTable component and includes
 * Name, Phone Number, Email, Unique Billing ID, and Actions columns
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <accounts></accounts>
 */
var AccountsComponent = /** @class */ (function () {
    function AccountsComponent(route, router, location, connectionService, translationService, dialog) {
        this.route = route;
        this.router = router;
        this.location = location;
        this.connectionService = connectionService;
        this.translationService = translationService;
        this.dialog = dialog;
        /**
         * Defines the columns available to the FancyTable component with the settings
         * and configuration for each one of the columns.
         */
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'organization__name' },
            { key: 'phone-number', title: this.translationService.instant('Phone Number'), sortable: true, sortBy: 'organization__phone_number' },
            { key: 'email', title: this.translationService.instant('Email'), sortable: true, sortBy: 'organization__email' },
            { key: 'billing-id', title: this.translationService.instant('Billing Id'), sortable: true, sortBy: 'unique_billing_id' },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false }
        ];
        /**
         * Defines the keys for the columns to be displyed in the FancyTable component
         * by default in the order they are to be displayed.
         */
        this.displayedColumns = [
            'name', 'phone-number', 'email', 'billing-id', 'action'
        ];
        /**
         * Defines the filters available to the FancyTable component with the settings
         * and configuration for each one.
         */
        this.availableFilters = [];
        /**
         * Defines the filters that are automatically applied to the FancyTable
         * component along with the settings for each.
         */
        this.appliedFilters = [];
        /**
         * Stores and tracks the search term between components.
         */
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        /**
         * FancyTable configuration settings
         */
        this.accountTableConfig = {
            hasHeader: true,
            service: connection_service_1.ConnectionService,
            preferenceKey: 'AccountsComponent-ConnectionService',
            query: {},
            collectionTitle: this.translationService.instant('Accounts'),
            noResultsText: this.translationService.instant('an account'),
            newRecordRoute: [],
            sortBy: 'organization__name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false },
                { name: this.translationService.instant('Invite'), action: 'invite', link: false, external: false },
            ]
        };
        this.loading = true;
        this.errors = [];
        this.accountType = 'leased-fleet';
    }
    /**
    * Examines the route and query parameters to determine the Account Type and
    * persisted search value.
    *
    * If the account type equals `leased-fleet` the `leased_org: True` filter is
    * added to the query and the `newRecordRoute` is set to `/leased-fleet/new`.
    */
    AccountsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route && this.route.queryParams) {
            this.route.queryParams.forEach(function (param) {
                _this.loading = true;
                _this.accountType = _this.router.url.includes('accounts') ? 'accounts' : 'leased-fleet';
                if (param['search']) {
                    _this.search = param['search'];
                }
            });
        }
        if (this.accountType === 'leased-fleet' && this.router.url.includes('leased-fleet')) {
            this.accountTableConfig.query = { leased_org: 'True' };
            this.accountTableConfig.newRecordRoute = ['/leased-fleet/new'];
        }
        else {
            this.accountTableConfig.query = { customer_only: 'True', leased_org: 'False' };
            this.accountTableConfig.newRecordRoute = ['/accounts/new'];
        }
    };
    /**
    * Maps a menu link to a function that cooresponds with the desired behavior
    * and calls the function with the provided `account` object.
    *
    * @param {} name The action name to call
    * @param {} account The account object to perform the action on
    */
    AccountsComponent.prototype.menuAction = function (name, account) {
        switch (name) {
            case 'edit':
                this.router.navigateByUrl(account.url('edit', this.location.path()));
                break;
            case 'remove':
                this.removeAccount(account);
                break;
            case 'invite':
                this.inviteCustomer(account);
                break;
        }
    };
    /**
    * Presents a modal dialog using {@link RuckitAddEmployeeDialogComponent} to
    * capture the email address an invitation will be sent to.
    *
    * @param {} account The account object to invite the email address to
    */
    AccountsComponent.prototype.inviteCustomer = function (account) {
        var _a = account.organization, id = _a.id, email = _a.email;
        this.dialog.open(add_employee_dialog_component_1.RuckitAddEmployeeDialogComponent, {
            width: '444px',
            data: {
                type: 'customer',
                organizationId: id,
                email: email
            },
        });
    };
    /**
    * Presents a modal dialog using {@link RuckitConfirmDialogComponent} to verify
    * the removal action. Upon a successful removal, the table data is reloaded
    * from the API.
    *
    * @param {} account The account object to remove
    */
    AccountsComponent.prototype.removeAccount = function (account) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Account?'),
            body: this.translationService.instant('This account will be deleted and cannot be recovered.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.connectionService.disconnectConnection(account.id).subscribe(function (res) {
                    _this.refreshTable();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    /**
    * Updates the URL with the given parameters and ensures that the `search`
    * value is persisted.
    *
    * @param {} params Query parameters to be included in the URL.
    */
    AccountsComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    /**
     * refresh/reload the account table on any action performed like
     * add, edit, remove
     */
    AccountsComponent.prototype.refreshTable = function () {
        this.accountTable.getRecords();
    };
    /**
     * Used for reseting the filters or when filter is changed
     *
     * @param {} appliedFilters
     */
    AccountsComponent.prototype.filtersModified = function (appliedFilters) {
        if (!appliedFilters || !appliedFilters.length) {
            // Unimplemented
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    AccountsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return AccountsComponent;
}());
exports.AccountsComponent = AccountsComponent;
