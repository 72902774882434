"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".driver-image[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:370px;height:194px;border-radius:4px;border:1px solid #dfe3e9;margin:30px;overflow:hidden;-o-object-fit:cover;object-fit:cover}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{position:relative;flex-grow:1}form[_ngcontent-%COMP%]   .field-group.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .field-group.half-width[_ngcontent-%COMP%]{padding-right:30px;min-width:25%;margin-left:20px}form[_ngcontent-%COMP%]   .field-group.first-name[_ngcontent-%COMP%]{margin-left:0;padding-right:10px}form[_ngcontent-%COMP%]   .field-group.last-name[_ngcontent-%COMP%]{margin-left:0;padding-left:10px}form[_ngcontent-%COMP%]   .field-group.city[_ngcontent-%COMP%]{margin-left:0;padding-right:10px;width:65%}form[_ngcontent-%COMP%]   .field-group.state[_ngcontent-%COMP%]{margin-left:0;padding-left:10px;width:35%}form[_ngcontent-%COMP%]   .field-group.state[_ngcontent-%COMP%]   .dropdown[_ngcontent-%COMP%]   .dropdown-button[_ngcontent-%COMP%]{margin-bottom:0}form[_ngcontent-%COMP%]   .field-group.zip[_ngcontent-%COMP%]{margin-left:0;padding-right:10px;width:35%}form[_ngcontent-%COMP%]   .field-group.phone[_ngcontent-%COMP%]{margin-left:0;padding-left:10px;width:65%}.form-content[_ngcontent-%COMP%]{flex:1 1 auto;padding-top:30px}"];
exports.styles = styles;
