import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { OrderTicketListComponent } from './order-ticket-list/order-ticket-list.component';
import { TicketDetailDialogComponent } from './ticket-detail-dialog/ticket-detail-dialog.component';
import { TicketReviewDialogComponent } from './ticket-review-dialog/ticket-review-dialog.component';
import { OrderService } from './order.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  providers: [
    OrderService
  ],
  declarations: [
    OrderTicketListComponent,
    TicketDetailDialogComponent,
    TicketReviewDialogComponent
  ],
  entryComponents: [
    TicketDetailDialogComponent,
    TicketReviewDialogComponent
  ],
  exports: [
    OrderTicketListComponent,
    TicketDetailDialogComponent,
    TicketReviewDialogComponent
  ]
})
export class OrdersModule {}
