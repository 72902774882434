"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mobile-dispatch-drivers.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("../directives/loader/loader.component.ngfactory");
var i6 = require("../directives/loader/loader.component");
var i7 = require("@ngx-translate/core");
var i8 = require("./mobile-dispatch-drivers.component");
var i9 = require("../drivers/driver.service");
var i10 = require("@angular/material/dialog");
var styles_MobileDispatchDriversComponent = [i0.styles];
var RenderType_MobileDispatchDriversComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDispatchDriversComponent, data: {} });
exports.RenderType_MobileDispatchDriversComponent = RenderType_MobileDispatchDriversComponent;
function View_MobileDispatchDriversComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name[0]; _ck(_v, 1, 0, currVal_0); }); }
function View_MobileDispatchDriversComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.dispatchImage; _ck(_v, 0, 0, currVal_0); }); }
function View_MobileDispatchDriversComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "slot"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchDriversComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchDriversComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h4", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editTruck(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "button", [["class", "radio-button"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(15, { "active": 0 }), i1.ɵdid(16, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(17, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_v.context.$implicit.image; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.image; _ck(_v, 5, 0, currVal_1); var currVal_4 = "radio-button"; var currVal_5 = _ck(_v, 15, 0, ((_co.selectedDriver && _co.selectedDriver.id) === _v.context.$implicit.id)); _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 17, 0, ((_co.selectedDriver && _co.selectedDriver.id) === _v.context.$implicit.id)); var currVal_7 = "radio-button"; _ck(_v, 16, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_2); var currVal_3 = (_v.context.$implicit.truck ? _v.context.$implicit.truck.displayName : "--"); _ck(_v, 10, 0, currVal_3); }); }
function View_MobileDispatchDriversComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-loader", [["class", "mobile-loader full-height"]], null, null, null, i5.View_LoaderComponent_0, i5.RenderType_LoaderComponent)), i1.ɵdid(1, 180224, null, 0, i6.LoaderComponent, [], null, null)], null, null); }
function View_MobileDispatchDriversComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bottom-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add(_co.selectedDriver) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["CREATE ASSIGNMENT"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MobileDispatchDriversComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "mobile-dispatch-drivers"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { readonly: 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { readonly: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchDriversComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchDriversComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchDriversComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mobile-dispatch-drivers"; var currVal_1 = _ck(_v, 3, 0, (!_co.job || !_co.job.canEdit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (!_co.job || !_co.job.canEdit)); var currVal_3 = "mobile-dispatch-drivers"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.drivers; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.selectedDriver; _ck(_v, 11, 0, currVal_6); }, null); }
exports.View_MobileDispatchDriversComponent_0 = View_MobileDispatchDriversComponent_0;
function View_MobileDispatchDriversComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-dispatch-drivers", [], null, null, null, View_MobileDispatchDriversComponent_0, RenderType_MobileDispatchDriversComponent)), i1.ɵdid(1, 770048, null, 0, i8.MobileDispatchDriversComponent, [i9.DriverService, i10.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileDispatchDriversComponent_Host_0 = View_MobileDispatchDriversComponent_Host_0;
var MobileDispatchDriversComponentNgFactory = i1.ɵccf("mobile-dispatch-drivers", i8.MobileDispatchDriversComponent, View_MobileDispatchDriversComponent_Host_0, { job: "job", jobEvent: "jobEvent", carrier: "carrier", selectedDriver: "selectedDriver", scrollEvent: "scrollEvent" }, { selectDriver: "selectDriver", selectEditTruck: "selectEditTruck" }, []);
exports.MobileDispatchDriversComponentNgFactory = MobileDispatchDriversComponentNgFactory;
