"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var punch_card_1 = require("../punch-cards/punch-card");
var punch_card_service_1 = require("../punch-cards/punch-card.service");
var lodash_1 = require("lodash");
var ViewPunchCardTicketDialogComponent = /** @class */ (function () {
    function ViewPunchCardTicketDialogComponent(dialogRef, punchCardService, elementRef) {
        this.dialogRef = dialogRef;
        this.punchCardService = punchCardService;
        this.elementRef = elementRef;
        this.punchCard = {};
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
    }
    ViewPunchCardTicketDialogComponent.prototype.ngOnInit = function () { };
    ViewPunchCardTicketDialogComponent.prototype.fileChange = function (e) {
        var _this = this;
        this.punchCardService.uploadImage('PUT', this.punchCard.id, 'ticket_image', e.srcElement.files).subscribe(function (res) {
            var _punchCard = new punch_card_1.PunchCard(res);
            _punchCard.ticketImage = _punchCard.ticketImage + '&cb=' + encodeURIComponent(new Date().toISOString());
            lodash_1.extend(_this.punchCard, _punchCard);
            _this.completed.emit(_this.punchCard);
        }, function (err) {
            console.error('err', err);
        });
    };
    return ViewPunchCardTicketDialogComponent;
}());
exports.ViewPunchCardTicketDialogComponent = ViewPunchCardTicketDialogComponent;
