"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var core_2 = require("@ngx-translate/core");
var forms_1 = require("@angular/forms");
// libraries
var moment = require("moment-timezone");
// angular material
var material_1 = require("@angular/material");
// services
var trip_service_1 = require("./../trip.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var api_service_1 = require("../../shared/api.service");
var shared_1 = require("../../shared");
var attachment_service_1 = require("../../attachments/attachment.service");
var checkin_service_1 = require("../../checkins/checkin.service");
var job_event_1 = require("../../job-events/job-event");
var carrier_1 = require("../../carriers/carrier");
// components
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
// constants
var app_constants_1 = require("../../app.constants");
var dropdownsConfig_1 = require("./dropdownsConfig");
// validators
var trip_time_validators_1 = require("../trip-time-validators");
var EditTripDialogComponent = /** @class */ (function () {
    function EditTripDialogComponent(dialogRef, tripService, attachmentsService, checkinService, jobEventService, authenticationService, translate, fb) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.tripService = tripService;
        this.attachmentsService = attachmentsService;
        this.checkinService = checkinService;
        this.jobEventService = jobEventService;
        this.authenticationService = authenticationService;
        this.translate = translate;
        this.fb = fb;
        this.lockedFields = true;
        this.jobEventDate = null;
        this.jobEventDatePickerOptions = null;
        this.highlightedDates = [];
        this.jobEvents = [];
        this.edittedTrip = null;
        this.tripLoading = true;
        this.loading = false;
        this.errors = [];
        this.jobEventError = false;
        this.activeTab = 'trip-data';
        this.jobDropdownConfig = dropdownsConfig_1.JOB_DROPDOWN_CONFIG(this.translate);
        this.jobEventsDropdownConfig = dropdownsConfig_1.JOB_EVENTS_DROPDOWN_CONFIG(this.translate);
        this.carrierDropdownConfig = dropdownsConfig_1.CARRIER_DROPDOWN_CONFIG(this.translate);
        this.driverDropdownConfig = dropdownsConfig_1.DRIVER_DROPDOWN_CONFIG(this.translate);
        this.truckDropdownConfig = dropdownsConfig_1.TRUCK_DROPDOWN_CONFIG(this.translate);
        this.firstLoad = true;
        this.loadingTicketImages = [];
        this.unloadingTicketImages = [];
        this.loadingSignatures = [];
        this.unloadingSignatures = [];
        this.imagesToDelete = [];
        this.uploading = false;
        this.fieldsToCheck = [
            'activeTracking',
            'ticketImage',
            'signatureImage',
            'ticketNumber',
            'weight',
        ];
        this.disabledFields = {
            carrier: false,
            driver: false,
            calendar: false,
        };
        this.requiredImages = {
            loadingImages: false,
            unloadingImages: false,
            loadingSignature: false,
            unloadingSignature: false,
        };
        this.checkinsReq = { loading: null, unloading: null };
        this.parseDateTime = function (date, time) {
            return !!date && !!time
                ? moment(moment(date).format('YYYY-MM-DD') + " " + time).toISOString()
                : null;
        };
        this.onImagesChangeParser = function (images) {
            return images.map(function (img) {
                return img.isNew || img.isEdited
                    ? Object.assign(img, {
                        tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                    })
                    : _this.parseImageOnChange(img);
            });
        };
    }
    EditTripDialogComponent.prototype.ngOnInit = function () {
        var user = this.authenticationService.user();
        this.carrierDropdownConfig.query = {
            exclude: user.organization.id,
        };
        this.carrierDropdownConfig.prefilledOptions.push(user.organization);
        this.getTrip(this.tripId);
    };
    EditTripDialogComponent.prototype.getTrip = function (id) {
        var _this = this;
        this.tripService.getCondensedTrip(id).subscribe(function (trip) {
            _this.edittedTrip = trip;
            _this.job = {
                id: trip.jobId,
                name: trip.job,
            };
            _this.jobEvent = new job_event_1.JobEvent({ id: trip.jobEventId });
            _this.carrier = new carrier_1.Carrier({
                id: trip.carrierId,
                name: trip.carrier,
            });
            _this.driver = {
                id: trip.driverId,
                name: trip.driver,
            };
            _this.truck = {
                id: trip.truckId,
                name: trip.truck,
            };
            _this.getCheckins(_this.edittedTrip.id, 'loading');
            _this.getCheckins(_this.edittedTrip.id, 'unloading');
            _this.initForm();
        }, function (err) {
            _this.errors = err;
        });
    };
    EditTripDialogComponent.prototype.getCheckins = function (tripId, kind) {
        var _this = this;
        if (kind === void 0) { kind = 'loading'; }
        if (this.checkinsReq[kind] &&
            typeof this.checkinsReq[kind].unsubscribe === 'function') {
            this.checkinsReq[kind].unsubscribe();
        }
        this.checkinsReq[kind] = this.checkinService
            .getCheckins({
            ordering: 'name',
            kind: kind,
            trip: tripId,
        })
            .subscribe(function (checkins) {
            if (checkins && checkins.length) {
                var checkin = checkins[0];
                _this.editTripFormPatchValueNested([kind + "Checkin", 'ticketNumber'], checkin.ticketNumber);
                _this.editTripFormPatchValueNested([kind + "Checkin", 'weight'], checkin.weight);
                _this.editTripFormPatchValueNested([kind + "Checkin", 'date'], checkin.date);
                _this.editTripFormPatchValueNested([kind + "Checkin", 'time'], moment(checkin.date).format('HH:mm'));
                switch (kind) {
                    case 'loading':
                        _this.edittedTrip.loadingCheckin = checkin;
                        var loadingTicketImages = _this.edittedTrip.loadingCheckin.attachments
                            .filter(function (img) { return img.fileType === app_constants_1.TRIPTICKETIMAGETYPE; })
                            .map(function (a) { return ({
                            id: a.id,
                            src: a.file,
                            file: a.file,
                            legacy: a.legacy,
                            imgType: app_constants_1.LOADINGTRIPTICKETIMAGETYPE,
                        }); });
                        var loadingSignatures = _this.edittedTrip.loadingCheckin.attachments
                            .filter(function (att) { return att.fileType === app_constants_1.TRIPSIGNATUREIMAGETYPE; })
                            .map(function (file) { return ({
                            id: file.id,
                            src: file.file,
                            file: file.file,
                            legacy: file.legacy,
                            imgType: app_constants_1.LOADINGTRIPSIGNATUREIMAGETYPE,
                        }); });
                        _this.loadingTicketImages = loadingTicketImages;
                        _this.loadingSignatures = loadingSignatures;
                        _this.editTripFormPatchValueNested(['loadingCheckin', 'images'], [loadingTicketImages]);
                        _this.editTripFormPatchValueNested(['loadingCheckin', 'signatures'], [loadingSignatures]);
                        break;
                    case 'unloading':
                        _this.edittedTrip.unloadingCheckin = checkin;
                        var unloadingTicketImages = _this.edittedTrip.unloadingCheckin.attachments
                            .filter(function (img) { return img.fileType === app_constants_1.TRIPTICKETIMAGETYPE; })
                            .map(function (a) { return ({
                            id: a.id,
                            src: a.file,
                            file: a.file,
                            legacy: a.legacy,
                            imgType: app_constants_1.UNLOADINGTRIPTICKETIMAGETYPE,
                        }); });
                        var unloadingSignatures = _this.edittedTrip.unloadingCheckin.attachments
                            .filter(function (att) { return att.fileType === app_constants_1.TRIPSIGNATUREIMAGETYPE; })
                            .map(function (file) { return ({
                            id: file.id,
                            src: file.file,
                            file: file.file,
                            legacy: file.legacy,
                            imgType: app_constants_1.UNLOADINGTRIPSIGNATUREIMAGETYPE,
                        }); });
                        _this.unloadingTicketImages = unloadingTicketImages;
                        _this.unloadingSignatures = unloadingSignatures;
                        _this.editTripFormPatchValueNested(['unloadingCheckin', 'images'], [unloadingTicketImages]);
                        _this.editTripFormPatchValueNested(['unloadingCheckin', 'signatures'], [unloadingSignatures]);
                        break;
                }
            }
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditTripDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        var requests = this.attachmentsService.combineTicketImagesAndAttachments(this.imagesToDelete, this.loadingTicketImages, this.loadingSignatures, this.unloadingTicketImages, this.unloadingSignatures, this.edittedTrip.loadingCheckin.id, this.edittedTrip.unloadingCheckin.id);
        var parsedTrip = this.parseTrip();
        delete parsedTrip.loadingCheckin.attachments;
        delete parsedTrip.unloadingCheckin.attachments;
        var updateTrip$ = this.tripService.saveWithCheckinsAndAttachments(parsedTrip);
        rxjs_1.forkJoin(requests.concat([updateTrip$])).subscribe(function (res) {
            _this.callback(res && res.length ? res[0] : null);
            _this.dialogRef.close();
        }, function (err) {
            console.log(err);
            _this.errors = api_service_1.parseErrors(Array.isArray(err) ? err : [err]);
            _this.loading = false;
        });
    };
    EditTripDialogComponent.prototype.parseTrip = function () {
        var formTrip = this.editTripForm.value;
        var trip = __assign({}, this.edittedTrip, this.editTripForm.value, { id: this.edittedTrip.id, job: formTrip.job.id, jobEvent: formTrip.jobevent, jobevent: formTrip.jobevent, carrier: this.editTripForm.value.carrier.id, driver: this.editTripForm.value.driver.id, truck: this.editTripForm.value.truck.id, startTime: this.parseDateTime(formTrip.start.date, formTrip.start.time), endTime: this.parseDateTime(formTrip.end.date, formTrip.end.time), loadingCheckin: __assign({}, formTrip.loadingCheckin, { id: this.edittedTrip.loadingCheckin.id, date: this.parseDateTime(formTrip.loadingCheckin.date, formTrip.loadingCheckin.time) }), unloadingCheckin: __assign({}, formTrip.unloadingCheckin, { id: this.edittedTrip.unloadingCheckin.id, date: this.parseDateTime(formTrip.unloadingCheckin.date, formTrip.unloadingCheckin.time) }) });
        return trip;
    };
    EditTripDialogComponent.prototype.onJobEventDateChanged = function (values) {
        this.jobEventError = false;
        if (!this.firstLoad && this.job && values && values.length) {
            if (!this.disabledFields.carrier && !this.disabledFields.driver) {
                this.edittedTrip.carrier = null;
                this.edittedTrip.driver = null;
            }
            this.edittedTrip.truck = null;
            var selectedDate_1 = moment(values[0]).format().split('T')[0];
            var jobEvent = this.jobEvents.find(function (j) {
                return j.shift1Start.includes(selectedDate_1);
            });
            this.jobEventDate = values[0];
            if (jobEvent) {
                this.jobEvent = jobEvent;
                this.editTripFormSetRequiredFields(jobEvent);
                if (jobEvent.job &&
                    jobEvent.job.checkinOptions &&
                    jobEvent.job.checkoutOptions) {
                    this.setRequiredImages(jobEvent.job.checkinOptions, jobEvent.job.checkoutOptions);
                }
                var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: jobEvent.id });
                this.carriersDropdown.config.query = query;
                this.carriersDropdown.getRecords(query);
                this.edittedTrip.jobEventId = jobEvent.id;
                this.editTripFormPatchValue('jobevent', jobEvent.id);
            }
            else {
                this.jobEventError = true;
                this.jobEvent = null;
            }
        }
    };
    EditTripDialogComponent.prototype.onDateChange = function (args, dates) {
        if (args && args.length > 0 && dates) {
            this.editTripFormPatchValueNested(args, dates[0]);
        }
    };
    EditTripDialogComponent.prototype.editDateTime = function (field, value) {
        if (value) {
            var checkinField = 'loadingTime' === field ? 'loadingCheckin' : 'unloadingCheckin';
            this.edittedTrip[checkinField].time = value;
            this.editTripFormPatchValueNested([checkinField, 'time'], value);
        }
    };
    EditTripDialogComponent.prototype.enableEditing = function () {
        this.lockedFields = false;
        this.firstLoad = true;
        this.disabledFields.carrier = true;
        this.disabledFields.calendar = true;
    };
    EditTripDialogComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                if (this.disabledFields.carrier || this.disabledFields.calendar) {
                    this.disabledFields.carrier = false;
                    this.disabledFields.calendar = false;
                }
                // if newly selected job is the same as existing selected job
                if (this.job && this.job.id === e.id) {
                    if (this.firstLoad) {
                        this.firstLoad = false;
                        this.getJobEvents(e.id);
                        this.editTripFormPatchValue(filterType, e);
                    }
                    break;
                }
                this.jobEventDatePickerOptions = null;
                this.job = e;
                this.jobId = e.id;
                if (this.firstLoad) {
                    this.firstLoad = false;
                }
                else {
                    this.resetForm();
                }
                this.getJobEvents(e.id);
                this.editTripFormPatchValue(filterType, e);
                break;
            case 'carrier':
                var carrier = e.carrier;
                if (this.jobEvent &&
                    (this.edittedTrip.carrier === null ||
                        this.edittedTrip.carrier !== carrier.id)) {
                    this.edittedTrip.carrier = carrier;
                    this.edittedTrip.driver = null;
                    this.edittedTrip.truck = null;
                    this.driversDropdown.getRecords({
                        carrier: carrier.id,
                        shared_jobevent: this.jobEvent.id,
                    });
                    this.truckDropdown.getRecords({
                        carrier: carrier.id,
                    });
                    this.editTripFormPatchValue('driver', null);
                    this.editTripFormPatchValue('truck', null);
                }
                this.editTripFormPatchValue(filterType, carrier);
                break;
            default:
                this.edittedTrip[filterType] = e;
                this.editTripFormPatchValue(filterType, e);
                break;
        }
    };
    EditTripDialogComponent.prototype.getJobEvents = function (jobId, query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        this.jobEventError = false;
        // shift1Start
        this.jobEventService
            .list(__assign({ ordering: 'shift1_start_timestamp', job: jobId, page_size: 100 }, query))
            .subscribe(function (jobEvents) {
            var newDates = jobEvents.map(function (j) { return j.shift1Start; });
            _this.jobEvents = jobEvents.slice();
            var jobEvent = _this.jobEvent
                ? _this.jobEvent
                : _this.getJobEventWithDateAndJobEvents(jobEvents, _this.jobEventDate || newDates[0]);
            if (newDates.length && jobEvent && jobEvent.id) {
                _this.highlightedDates = newDates;
                _this.jobEventDatePickerOptions = {
                    min: newDates[0],
                    max: newDates[newDates.length - 1],
                };
                var query_1 = __assign({}, _this.carrierDropdownConfig.query, (jobEvent &&
                    jobEvent.id && {
                    carriers_for_jobevent: jobEvent.id,
                }));
                _this.jobEvent = jobEvent;
                _this.edittedTrip.jobEventId = jobEvent.id;
                _this.editTripFormPatchValue('jobevent', jobEvent.id);
                _this.carriersDropdown.config.query = query_1;
                if (!_this.disabledFields.carrier && !_this.disabledFields.driver) {
                    _this.carriersDropdown.getRecords(query_1);
                }
                _this.editTripFormSetRequiredFields(jobEvent);
            }
            else {
                _this.jobEventError = true;
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
        });
    };
    EditTripDialogComponent.prototype.getJobEventWithDateAndJobEvents = function (jobEvents, jobEventDate) {
        var selectedDate = moment(jobEventDate).format().split('T')[0];
        return jobEvents.find(function (j) { return j.shift1Start.includes(selectedDate); });
    };
    EditTripDialogComponent.prototype.getJobEventWithId = function (jobEventId) {
        var _this = this;
        this.jobEventService.get(jobEventId).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
            _this.job = jobEvent.job;
            _this.editTripFormSetRequiredFields(jobEvent);
            var selectedDate = moment(jobEvent.shift1Start).format().split('T')[0];
            _this.jobEventDate = jobEvent.shift1Start;
            // if (
            //   jobEvent.job &&
            //   jobEvent.job.checkinOptions &&
            //   jobEvent.job.checkoutOptions
            // ) {
            //   this.setRequiredImages(
            //     jobEvent.job.checkinOptions,
            //     jobEvent.job.checkoutOptions
            //   );
            // }
        });
    };
    EditTripDialogComponent.prototype.editTripFormPatchValue = function (key, value) {
        var _a;
        this.editTripForm.patchValue((_a = {},
            _a[key] = value,
            _a));
    };
    EditTripDialogComponent.prototype.editTripFormPatchValueNested = function (keys, value) {
        if (keys && keys.length > 0 && value) {
            var objToPatch = {};
            keys.reduce(function (prev, curr, i) {
                return (prev[curr] = i + i === keys.length ? value : {});
            }, objToPatch);
            this.editTripForm.patchValue(objToPatch);
        }
    };
    EditTripDialogComponent.prototype.editTripFormSetRequiredFields = function (jobEvent) {
        var _this = this;
        this.fieldsToCheck.forEach(function (field) {
            _this.editTripFormValidatorsSwitch('loadingCheckin', field, jobEvent.job.checkinOptions[field + "Config"] === 'required');
        });
        this.fieldsToCheck.forEach(function (field) {
            _this.editTripFormValidatorsSwitch('unloadingCheckin', field, jobEvent.job.checkoutOptions[field + "Config"] === 'required');
        });
        this.updateFormValueAndValidity(this.editTripForm);
    };
    EditTripDialogComponent.prototype.updateFormValueAndValidity = function (group) {
        var _this = this;
        Object.keys(group.controls).forEach(function (key) {
            var abstractControl = group.controls[key];
            if (abstractControl instanceof forms_1.FormGroup ||
                abstractControl instanceof forms_1.FormArray) {
                _this.updateFormValueAndValidity(abstractControl);
            }
            else {
                abstractControl.updateValueAndValidity();
            }
        });
    };
    EditTripDialogComponent.prototype.editTripFormValidatorsSwitch = function (parent, key, required) {
        switch (key) {
            case 'activeTracking':
                if (required) {
                    this.editTripFormSetValidators([parent, 'date'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                    this.editTripFormSetValidators([parent, 'time'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'date']);
                    this.editTripFormClearValidators([parent, 'time']);
                }
                break;
            case 'ticketImage':
                if (required) {
                    this.editTripFormSetValidators([parent, 'images'], [forms_1.Validators.required]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'images']);
                }
                break;
            case 'signatureImage':
                if (required) {
                    this.editTripFormSetValidators([parent, 'signatures'], [forms_1.Validators.required]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'signatures']);
                }
                break;
            case 'ticketNumber':
                if (required) {
                    this.editTripFormSetValidators([parent, 'ticketNumber'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'ticketNumber']);
                }
                break;
            case 'weight':
                if (required) {
                    this.editTripFormSetValidators([parent, 'weight'], [forms_1.Validators.required, forms_1.Validators.min(0)]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'weight']);
                }
                break;
            default:
                break;
        }
    };
    EditTripDialogComponent.prototype.editTripFormSetValidators = function (field, validators) {
        this.editTripForm.get(field).setValidators(validators);
    };
    EditTripDialogComponent.prototype.editTripFormClearValidators = function (field) {
        this.editTripForm.get(field).clearValidators();
    };
    EditTripDialogComponent.prototype.getMomentDateFromDate = function (date, format) {
        if (format === void 0) { format = 'YYYY-MM-DD'; }
        return date ? moment(date).format(format) : null;
    };
    EditTripDialogComponent.prototype.initForm = function () {
        this.editTripForm = this.fb.group({
            job: [this.job, forms_1.Validators.required],
            jobevent: [this.edittedTrip.jobEventId, forms_1.Validators.required],
            driver: [this.edittedTrip.driver, forms_1.Validators.required],
            carrier: [this.edittedTrip.carrier, forms_1.Validators.required],
            truck: [this.edittedTrip.truck, forms_1.Validators.required],
            start: this.fb.group({
                date: [
                    this.getMomentDateFromDate(this.edittedTrip.startDate),
                    [forms_1.Validators.required],
                ],
                time: [
                    this.getMomentDateFromDate(this.edittedTrip.startDate, 'HH:mm'),
                    [forms_1.Validators.required],
                ],
            }),
            end: this.fb.group({
                date: [this.getMomentDateFromDate(this.edittedTrip.endDate)],
                time: [this.getMomentDateFromDate(this.edittedTrip.endDate, 'HH:mm')],
            }),
            loadingCheckin: this.fb.group({
                ticketNumber: [this.edittedTrip.loadingCheckin.ticketNumber],
                weight: [this.edittedTrip.loadingCheckin.weight],
                date: [
                    this.getMomentDateFromDate(this.edittedTrip.loadingCheckin.date),
                ],
                time: [
                    this.getMomentDateFromDate(this.edittedTrip.loadingCheckin.date, 'HH:mm'),
                ],
                images: [null],
                signatures: [null],
            }),
            unloadingCheckin: this.fb.group({
                ticketNumber: [this.edittedTrip.unloadingCheckin.ticketNumber],
                weight: [this.edittedTrip.unloadingCheckin.weight],
                date: [
                    this.getMomentDateFromDate(this.edittedTrip.unloadingCheckin.date),
                ],
                time: [
                    this.getMomentDateFromDate(this.edittedTrip.unloadingCheckin.date, 'HH:mm'),
                ],
                images: [null],
                signatures: [null],
            }),
        }, { validators: trip_time_validators_1.globalTripTimeValidator });
        if (this.edittedTrip.jobEventId) {
            this.getJobEventWithId(this.edittedTrip.jobEventId);
        }
        this.tripLoading = false;
    };
    EditTripDialogComponent.prototype.parseImageOnChange = function (image) {
        if (image && (image.isNew || image.isEdited)) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                image.file = reader_1.result;
                image.src = reader_1.result;
            };
            reader_1.readAsDataURL(image);
        }
        if (image && image.isDeleted) {
            this.imagesToDelete.push(image);
        }
        return image;
    };
    EditTripDialogComponent.prototype.onImagesChange = function (type, checkin, images) {
        var parsedImages = this.onImagesChangeParser(images);
        this[type] = parsedImages;
        this.editTripFormPatchValueNested([checkin, type.includes('Signature') ? 'signatures' : 'images'], parsedImages.slice());
    };
    EditTripDialogComponent.prototype.setRequiredImages = function (checkinOptions, checkoutOptions) {
        this.requiredImages = {
            loadingImages: checkinOptions.ticketImageConfig === 'required',
            unloadingImages: checkoutOptions.ticketImageConfig === 'required',
            loadingSignature: checkinOptions.signatureImageConfig === 'required',
            unloadingSignature: checkoutOptions.signatureImageConfig === 'required',
        };
    };
    EditTripDialogComponent.prototype.fileUploading = function (uploading) {
        this.uploading = uploading;
    };
    EditTripDialogComponent.prototype.resetForm = function () {
        this.edittedTrip.jobEventId = null;
        this.jobEventDate = null;
        this.edittedTrip.truck = null;
        if (!this.disabledFields.carrier) {
            this.edittedTrip.carrier = null;
        }
        if (!this.disabledFields.driver) {
            this.edittedTrip.driver = null;
        }
        this.editTripFormPatchValue('carrier', null);
        this.editTripFormPatchValue('jobevent', null);
        this.editTripFormPatchValue('driver', null);
        this.editTripFormPatchValue('truck', null);
        this.updateFormValueAndValidity(this.editTripForm);
    };
    Object.defineProperty(EditTripDialogComponent.prototype, "startDate", {
        get: function () {
            return this.editTripForm.get(['start', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripDialogComponent.prototype, "endDate", {
        get: function () {
            return this.editTripForm.get(['end', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripDialogComponent.prototype, "loadingCheckingDate", {
        get: function () {
            return this.editTripForm.get(['loadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripDialogComponent.prototype, "unloadingCheckingDate", {
        get: function () {
            return this.editTripForm.get(['unloadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripDialogComponent.prototype, "loadingCheckingTime", {
        get: function () {
            return this.editTripForm.get(['loadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripDialogComponent.prototype, "unloadingCheckingTime", {
        get: function () {
            return this.editTripForm.get(['unloadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    return EditTripDialogComponent;
}());
exports.EditTripDialogComponent = EditTripDialogComponent;
