
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ResourceService } from '../shared/resource.service';
import { Price } from './price';
import { PriceSerializer } from './price.serializer';

@Injectable()
export class PriceService extends ResourceService<Price> {
  baseUrl = environment.serverUrl + 'customprices/';
  public nextUri;
  public count;
  progress$;
  progress;

  constructor(http: HttpClient) {
    super(http, 'customprices/', new PriceSerializer());
  }
}
