"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var cyber_trips_component_1 = require("./cyber-trips.component");
var routes = [
    { path: '', component: cyber_trips_component_1.CyberTripsComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var CyberTripsRoutingModule = /** @class */ (function () {
    function CyberTripsRoutingModule() {
    }
    return CyberTripsRoutingModule;
}());
exports.CyberTripsRoutingModule = CyberTripsRoutingModule;
