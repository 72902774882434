"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".heading[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-items:center;height:100%}.heading[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 25px 0 0;font-size:15px;font-weight:600;color:#3f536e}.heading[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{margin-right:15px;font-size:13px;font-weight:600;cursor:pointer}.heading[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a.active[_ngcontent-%COMP%], .heading[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover{color:#0093ee}.heading[_ngcontent-%COMP%]   .export[_ngcontent-%COMP%]{flex:1 1 auto;align-items:flex-end;flex-direction:column;cursor:pointer;height:25px;width:25px;text-align:right}.heading[_ngcontent-%COMP%]   .export[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{line-height:25px;font-size:20px;color:#acb4b7;vertical-align:middle}.content[_ngcontent-%COMP%]{padding:20px}.content[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#58b4f9;font-weight:600;text-decoration:none}.content[_ngcontent-%COMP%]   .job[_ngcontent-%COMP%]{font-size:13px;text-align:left;color:#3f536e}.content[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .job[_ngcontent-%COMP%]{height:39px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;line-height:39px;padding-right:10px}.content[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .trucks[_ngcontent-%COMP%]{margin-right:15px}.content[_ngcontent-%COMP%]   .trucks[_ngcontent-%COMP%]{font-size:13px;text-align:left;color:#3f536e;margin-right:40px;display:flex;align-items:center}.content[_ngcontent-%COMP%]   .trucks[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{display:inline-block;font-size:24px;margin-top:4px}"];
exports.styles = styles;
