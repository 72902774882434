<div class="app-content">
  <my-toolbar title="{{ 'Expenses' | translate }}"></my-toolbar>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/expenses', 'filter', 'draft']" routerLinkActive="active" translate>Draft</a>
        <a [routerLink]="['/expenses', 'filter', 'paid']" routerLinkActive="active" translate>Paid</a>
      </div>
    </div>

    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filters.length}"><i class="icon-filter"></i>{{ 'Filters' | translate }}</button>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <dropdown title="Actions" [options]="dropdownOptions[state]" [changeTitle]="false" (onSelect)="setSelectedAction($event, state)"
        class="actions-dropdown" [disabled]="loading || (!allSelected[state] && !selectedExpenses[state].length)"></dropdown>
    </div>
  </action-bar>

  <div class="filters" *ngIf="filters.length">
    <ng-container *ngIf="!loading">{{ count }} {{ 'Results' | translate }}</ng-container>
    <ng-container *ngIf="loading">{{ 'Loading...' | translate }}</ng-container>
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters | propertyFilter: 'hidden': 'false'">
      {{ filter.key | titleCase }}: {{ filter.value && (filter.value.selectName || filter.value.name || filter.value) }}
      <i  class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <div class="expenses">
    <div class="list-table">
      <div class="table-body" (scroll)="onScroll($event)">
        <div class="header">
          <div class="head selector">
            <label>
              <input type="checkbox" (change)="selector($event, state)" [(ngModel)]="allSelected[state]" />
            </label>
          </div>
          <div class="head date sort" (click)="sort('start_time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'start_time'}" translate>
            Trip Dates
          </div>
          <div class="head expense-number sort" (click)="sort('number')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'number'}" translate>
            Number
          </div>
          <div class="head amount sort" (click)="sort('expense_amount')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'expense_amount'}" translate>
            Amount
          </div>
          <div class="head customer sort" (click)="sort('customer_organization__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'customer_organization__name'}" translate>
            Carrier
          </div>
          <div class="head payment-type sort" (click)="sort('payment_type')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'payment_type'}" translate>
            Payment Type
          </div>
          <div class="head created sort" (click)="sort('created_at')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'created_at'}" translate>
            Created
          </div>
          <div class="head marked-as-paid sort" (click)="sort('sent_time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'sent_time'}" translate>
            Marked as Paid
          </div>
          <div class="head actions" translate>
            Actions
          </div>
        </div>

        <div class="no-results" *ngIf="!loading && expenses.length === 0" fxLayout="column" fxLayoutAlign="start center">
          <div class="icon"><i class="icon-expense"></i></div>
          <h2 translate>No Expenses</h2>
          <p translate>When you create expenses you’ll see them here.</p>
        </div>

        <div *ngFor="let expense of expenses | propertyFilter: 'administrativeStatus': state; let i = index">
          <div class="row" [ngClass]="{selected: expense === selectedExpense || expense.selected}">
            <div class="selector">
              <label>
                <input type="checkbox" (change)="selector($event, state, expense)" [checked]="allSelected[state] || expense.selected" />
              </label>
            </div>
            <div class="date" [ngClass]="{'spacer': !expense.displayableStatus}">
              <div class="value">{{ expense.expensePeriod || '&nbsp;' }}</div>
              <div class="expense-status">
                <div class="label-{{expense.administrativeStatus}}" *ngIf="expense.displayableStatus">{{expense.administrativeStatus}}</div>
                <div class="spacer" *ngIf="!expense.displayableStatus">&nbsp;</div>
              </div>
            </div>
            <div class="expense-number">
              <div class="value">#{{ expense.number || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="amount">
              <div class="value currency">{{ (expense.expenseAmount || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="customer">
              <div class="value">{{ expense.customerOrganization?.name || '&nbsp;' }}</div>
              <div class="label" translate>Payee</div>
            </div>
            <div class="payment-type">
              <div class="value" *ngIf="expense.paymentType">{{ expense.paymentType | titleCase }}</div>
              <div class="value" *ngIf="!expense.paymentType" class="missing-required">&nbsp;</div>
              <div class="label" translate>Payment Type</div>
            </div>
            <div class="created">
              <div class="value">{{ expense.createdAt | date:'mediumDate' || '&nbsp;' }}</div>
              <div class="label" translate>Created</div>
            </div>
            <div class="marked-as-paid">
              <div class="value">{{ expense.sentTime | date:'mediumDate' || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="actions">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of expense.filterOptions(menuOptions)">
                  <a *ngIf="option.link && !option.external" mat-menu-item [routerLink]="expense.url(option.action)" [queryParams]="{ returnTo: '/expenses' }">{{ option.name }}</a>
                  <a *ngIf="option.link && option.external" mat-menu-item [href]="expense.url(option.action)" target="_blank">{{ option.name }}</a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, expense)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
          </div>
        </div>

        <notification *ngFor="let error of errors">{{error}}</notification>
        <my-loader *ngIf="loading" [ngClass]="{'full-height': expenses.length === 0}"></my-loader>
      </div>
    </div>
  </div>
</div>
