"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var shared_1 = require("../shared");
var job_event_stat_service_1 = require("../job-event-stats/job-event-stat.service");
var job_event_1 = require("../job-events/job-event");
var edit_share_dialog_component_1 = require("./edit-share-dialog.component");
var CollaboratorSummaryComponent = /** @class */ (function () {
    function CollaboratorSummaryComponent(jobEventStatService, authenticationService, dialog) {
        var _this = this;
        this.jobEventStatService = jobEventStatService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.totalHoursField = 'totalHours';
        this.statsLoading = true;
        this.errors = [];
        this.saveShareCallback = function (result) {
            var jobEventShare = result.jobEventShare;
            var updates = lodash_1.pick(jobEventShare, [
                'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
                'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
            ]);
            updates['rate'] = updates['invoiceRate'];
            Object.assign(_this.jobEvent, updates);
        };
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
            var totalHoursPreference = this.authenticationService.getFeature('calculator');
            this.totalHoursField = totalHoursPreference === 'trips' ?
                'totalTripHours' : totalHoursPreference === 'completed_trips' ?
                'totalCompletedTripHours' : 'totalHours';
        }
    }
    CollaboratorSummaryComponent.prototype.ngOnChanges = function (changes) {
        this.getStats();
    };
    CollaboratorSummaryComponent.prototype.getStats = function () {
        var _this = this;
        this.statsLoading = true;
        this.jobEventStatService.getStats(this.jobEvent && this.jobEvent.id).subscribe(function (stats) {
            _this.stats = stats;
            _this.statsLoading = false;
        }, function (err) {
            console.error(err);
        });
    };
    CollaboratorSummaryComponent.prototype.openEditShare = function (change) {
        var dialog = this.dialog.open(edit_share_dialog_component_1.EditShareDialogComponent, {
            width: '430px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.change = change;
            dialog.componentInstance.callback = this.saveShareCallback;
        }
    };
    return CollaboratorSummaryComponent;
}());
exports.CollaboratorSummaryComponent = CollaboratorSummaryComponent;
