import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { MarketplaceService } from '../marketplace/marketplace.service';
import { ProjectService } from '../projects/project.service';
import { parseErrors } from '../shared/api.service';
import { MarketplaceNewProjectDialogComponent } from './marketplace-new-project-dialog.component';

@Component({
  selector: 'ruckit-marketplace-accept-job',
  templateUrl: './marketplace-accept-job.component.html',
  styleUrls: ['./marketplace-accept-job.component.scss']
})
export class MarketplaceAcceptJobComponent implements OnInit {
  loading = true;
  errors = [];
  marketplaceId;
  marketplace;
  marketplaceReq;
  dialogRef: MatDialogRef<MarketplaceNewProjectDialogComponent>;
  model = {
    shareId: null,
    haulRate: null,
    haulType: '',
    weightUnit: '',
    project: ''
  };

  projects = [];

  haulTypes = [
    {name: 'Load', id: 'load'},
    {name: 'Hour', id: 'hour'},
    {name: 'Weight', id: 'weight'}
  ];
  weightUnits = [
    {name: 'Tons', id: 'ton'},
    {name: 'Metric Tons', id: 'metric-tons'},
    {name: 'Yards', id: 'yard'},
  ];
  projectsReq;

  saveProjectCallback = (project) => {
    this.getProjects({}, project);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private marketplaceService: MarketplaceService,
    private projectService: ProjectService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.errors = [];
    this.disableSidebar();
    this.route.params
      .subscribe((params: Params) => this.getMarketplace(params['id']));
  }

  disableSidebar() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.sidebar = false;

    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getMarketplace(id) {
    if (this.marketplaceReq) {
      this.marketplaceReq.unsubscribe();
    }

    this.marketplaceReq = this.marketplaceService.getMarketplace(id)
      .subscribe(
        marketplace => {
          this.marketplace = marketplace;
          this.model.shareId = this.marketplace.id;
          this.getProjects();
        },
        err => this.errors = err,
        () => {
          this.loading = false;
        }
      );
  }

  getProjects(query = {}, selectProject = null) {
    if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
      this.projectsReq.unsubscribe();
    }
    this.projectsReq = this.projectService.list({
      ordering: 'name',
      customer_organization: this.marketplace.job.project.ownerOrganization
    }).subscribe(
      _projects => {
        this.projects = _projects;
        if (selectProject) {
          this.model.project = selectProject.id;
        }
      }, (err) => {
        this.errors = parseErrors(err);
      }
    );
  }

  onProjectChange(event: Event, value): void {
    if (value === 'new-project') {
      this.model.project = '';
      this.openAddProject();
    }
  }

  openAddProject() {
    this.dialogRef = this.dialog.open(MarketplaceNewProjectDialogComponent, {
      width: '90%'
    });
    this.dialogRef.componentInstance.model.customerOrganization = this.marketplace.job.project.ownerOrganization;
    this.dialogRef.componentInstance.callback = this.saveProjectCallback;
  }

  accept() {
    this.loading = true;
    this.marketplaceService.accept(this.model).subscribe((res) => {
      this.router.navigate(['/marketplace/']);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }
}
