"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var trackable_1 = require("./trackable");
var trackable_type_serializer_1 = require("../trackable-types/trackable-type.serializer");
var truck_serializer_1 = require("../trucks/truck.serializer");
var organization_1 = require("../organizations/organization");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TrackableSerializer = /** @class */ (function () {
    function TrackableSerializer() {
    }
    TrackableSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var trackable = new trackable_1.Trackable();
        if (!json) {
            return trackable;
        }
        trackable.id = json.id;
        if (json.trackableType && typeof (json.trackableType) === 'object') {
            trackable.trackableType = (new trackable_type_serializer_1.TrackableTypeSerializer().fromJson(json.trackableType));
        }
        else if (json.trackableType) {
            trackable.trackableType = (new trackable_type_serializer_1.TrackableTypeSerializer().fromJson({ id: json.trackableType }));
        }
        if (typeof (json.truck) === 'object') {
            trackable.truck = new truck_serializer_1.TruckSerializer().fromJson(json.truck);
        }
        else {
            trackable.truck = new truck_serializer_1.TruckSerializer().fromJson({ name: json.truck });
        }
        if (typeof (json.organization) === 'object') {
            trackable.organization = new organization_1.Organization(json.organization);
        }
        else {
            trackable.organization = new organization_1.Organization({ id: json.organization });
        }
        trackable.deviceId = json.deviceId;
        trackable.active = json.active;
        trackable.deviceState = json.deviceState;
        trackable.positionSource = json.positionSource;
        trackable.hdop = json.hdop;
        trackable.rssi = json.rssi;
        trackable.voltage = json.voltage;
        trackable.heading = json.heading;
        trackable.speed = json.speed;
        trackable.accuracy = json.accuracy;
        trackable.temperature = json.temperature;
        trackable.location = json.location;
        trackable.lastStatusEvent = json.lastStatusEvent;
        trackable.createdAt = json.createdAt;
        trackable.lastModified = json.lastModified;
        trackable.removed = json.removed;
        return trackable;
    };
    TrackableSerializer.prototype.toJson = function (trackable) {
        var json = {
            id: trackable.id,
            truck: trackable.truck && trackable.truck.id,
            trackableType: trackable.trackableType && trackable.trackableType.id,
            active: trackable.active,
            deviceId: trackable.deviceId
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return TrackableSerializer;
}());
exports.TrackableSerializer = TrackableSerializer;
