"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var moment = require("moment");
var common_1 = require("@angular/common");
var punch_card_service_1 = require("./punch-card.service");
var punch_card_filters_dialog_component_1 = require("./punch-card-filters-dialog.component");
var index_1 = require("../shared/dialogs/index");
var authentication_service_1 = require("../shared/authentication.service");
var preference_service_1 = require("../preferences/preference.service");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
// utils
var app_utilities_1 = require("../shared/app-utilities");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PunchCardsComponent = /** @class */ (function () {
    function PunchCardsComponent(route, router, punchCardService, authenticationService, dialog, preferenceService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.punchCardService = punchCardService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.preferenceService = preferenceService;
        this.datePipe = new common_1.DatePipe('en-US');
        this.punchCards = [];
        this.expandedPunchCards = {};
        this.enabledFeatures = [];
        this.count = 0;
        this.selectedCount = 0;
        this.allSelected = false;
        this.selectedPunchCards = [];
        this.excludePunchCards = [];
        this.loading = true;
        this.errors = [];
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.sortBy = '-start_time';
        this.sortAsc = true;
        this.filters = [];
        this.checkingDuplicates = false;
        this.multipleActionDropdownOptions = [
            { name: 'Export', action: 'export', button: true, link: false },
            // !allSelected && !selectedPunchCards.length
            { name: 'Void', action: 'void', button: true, link: false, disabled: true },
        ];
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Unvoid', action: 'unvoid', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.preferenceKey = 'PunchCardsComponent-CondensedPunchCardService';
        this.lockedExportFields = null;
        this.contextMenuOpened = false;
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.viewTicketCallback = function (e) {
            // Update Ticket Icon
        };
        this.exportCallback = function () {
            _this.allSelected = false;
            _this.selectedPunchCards = [];
            _this.selectedCount = 0;
            _this.punchCards.forEach(function (_punchCard) { _punchCard.selected = false; });
        };
        this.allSubscriptionsToUnsubscribe = [];
        this.allSubscriptionsToUnsubscribe.push(this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.getPunchCards();
        }));
    }
    PunchCardsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            _this.sortBy = result.qparams['sortBy'] || _this.sortBy;
            _this.sortAsc = result.qparams['sortAsc'] || _this.sortAsc;
            _this.getPreferences();
        }));
        if (!this.loading) {
            this.getPreferences();
        }
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures.includes('punchcardExportFields')) {
            this.lockedExportFields = this.authenticationService.getFeature('punchcardExportFields');
            this.multipleActionDropdownOptions.push({ name: 'Spectrum Export', action: 'locked-export', button: true, link: false });
        }
    };
    PunchCardsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    PunchCardsComponent.prototype.getPreferences = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        if (this.preference && this.preference.id) {
            this.preferenceService.get(this.preference.id).subscribe(function (preference) {
                _this.preference = preference;
                _this.parsePreferences();
            });
        }
        else {
            this.preferenceService.list({
                name: this.preferenceKey,
                type: 'user',
                profile: currentUser.id
            }).subscribe(function (preferences) {
                if (preferences && preferences.length) {
                    _this.preference = preferences[0];
                    _this.parsePreferences();
                }
                else {
                    _this.getPunchCards();
                }
            });
        }
    };
    PunchCardsComponent.prototype.savePreferences = function (filters) {
        var _this = this;
        if (this.preferenceKey) {
            var currentUser = this.authenticationService.user();
            this.preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: currentUser.id, blob: { filters: filters } });
            this.preferenceService.save(this.preference).subscribe(function (preference) {
                _this.preference = preference;
            });
        }
    };
    /**
     * Examine the found preference to determine if it has filters and set those
     * filters.
     *
     * Once the preferences data is handled, trigger getPunchCards()
     */
    PunchCardsComponent.prototype.parsePreferences = function () {
        var _this = this;
        if (this.preference.blob && this.preference.blob['filters']) {
            this.filters = this.preference.blob['filters'].map(function (filter) {
                if (['startDate', 'endDate'].includes(filter.key)) {
                    filter.value = _this.prettyDate(filter.value);
                }
                return filter;
            });
        }
        this.getPunchCards();
    };
    PunchCardsComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.punchCardService.getNextCondensed();
            if (o) {
                this.loading = true;
                o.subscribe(function (punchCards) {
                    _this.punchCards = _this.punchCards.concat(punchCards);
                    _this.loading = false;
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                });
            }
        }
    };
    PunchCardsComponent.prototype.getPunchCards = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var formattedFilters = this.filters.map(function (filter) { return decamelizeKeysDeep(filter.query, '__'); });
        var filters = formattedFilters.reduce(function (key, value) {
            return Object.assign(key, value);
        }, {});
        if (this.punchCardsReq) {
            this.punchCardsReq.unsubscribe();
        }
        this.punchCardsReq = this.punchCardService.getCondensedPunchCards(__assign({ ordering: order, search: this.search, exclude_cf: 'True' }, filters, query)).subscribe(function (punchCards) {
            if (append) {
                _this.punchCards = _this.punchCards.concat(punchCards);
            }
            else {
                _this.punchCards = punchCards;
            }
            _this.count = _this.punchCardService.count;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    PunchCardsComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getPunchCards({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    PunchCardsComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.loading = true;
        this.getPunchCards((_a = {}, _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy, _a));
    };
    PunchCardsComponent.prototype.openAddPunchCard = function () {
        this.router.navigate(['/punchcards/new'], {
            queryParams: { returnTo: '/punchcards' }
        });
    };
    PunchCardsComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    PunchCardsComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    PunchCardsComponent.prototype.duplicateCheck = function () {
        this.checkingDuplicates = !this.checkingDuplicates;
        if (this.checkingDuplicates) {
            this.filters.push({
                key: 'duplicates', value: 'True', displayKey: 'Duplicates', query: {
                    only_dupes: 'True'
                }
            });
            this.getPunchCards();
        }
        else {
            var filter = lodash_1.find(this.filters, { key: 'duplicates' });
            if (filter) {
                this.removeFilter(filter);
            }
        }
    };
    PunchCardsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(punch_card_filters_dialog_component_1.PunchCardFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        var startDate = lodash_1.get(lodash_1.find(this.filters, { key: 'startDate' }), 'value');
        if (startDate && startDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.startDate = startDate;
        }
        var endDate = lodash_1.get(lodash_1.find(this.filters, { key: 'endDate' }), 'value');
        if (endDate && endDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.endDate = endDate;
        }
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.filters, { key: 'job' }), 'value');
        dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.filters, { key: 'project' }), 'value');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.filters, { key: 'customer' }), 'value');
        dialog.componentInstance.model.driver = lodash_1.get(lodash_1.find(this.filters, { key: 'driver' }), 'value');
        dialog.componentInstance.model.truck = lodash_1.get(lodash_1.find(this.filters, { key: 'truck' }), 'value');
        dialog.componentInstance.model.carrier = lodash_1.get(lodash_1.find(this.filters, { key: 'carrier' }), 'value');
        dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    PunchCardsComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            job: 'jobevent__job',
            driver: 'driver',
            truck: 'truck',
            startDate: 'jobevent__shift1_start__gte',
            endDate: 'jobevent__shift1_start__lte',
            carrier: 'driver__carrier',
            duplicates: 'only_dupes',
            uninvoiced: 'uninvoiced'
        };
        var displayKeys = {
            duplicates: 'Duplicates'
        };
        var falseyFilters = [];
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var value = filterRes[key];
            var displayValue;
            if (typeof (value) === 'boolean') {
                if (key === 'incomplete' && value) {
                    displayValue = value.toString();
                    displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
                    value = !value;
                }
                else if (key === 'retake' && value) {
                    value = 'requested';
                    query[queryKeys[key]] = value;
                }
                else if (value) {
                    value = value.toString();
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    query[queryKeys[key]] = value;
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var filter = {
                key: key,
                displayKey: displayKeys[key] || null,
                value: displayValue || value,
                query: query
            };
            if (filter.value === 'False' || !filter.value) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.filters = lodash_1.difference(this.filters, falseyFilters);
        this.savePreferences(this.filters);
        this.getPunchCards();
    };
    PunchCardsComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.filters, filter);
        var duplicateFilter = lodash_1.find(this.filters, { key: 'duplicates' });
        if (!duplicateFilter) {
            this.checkingDuplicates = false;
        }
        this.savePreferences(this.filters);
        this.getPunchCards();
    };
    PunchCardsComponent.prototype.menuAction = function (name, punchCard) {
        switch (name) {
            case 'void':
                this.voidPunchCard(punchCard);
                break;
            case 'unvoid':
                this.unvoidPunchCard(punchCard);
                break;
        }
    };
    PunchCardsComponent.prototype.setSelectedAction = function (option) {
        switch (option.name) {
            case 'Export':
                this.exportSelectedPunchCards();
                break;
            case 'Void':
                this.voidSelectedPunchCards();
                break;
        }
    };
    PunchCardsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedPunchCards();
                break;
            case 'locked-export':
                this.exportSelectedPunchCards(null, true);
                break;
            case 'void':
                this.voidSelectedPunchCards();
                break;
        }
    };
    PunchCardsComponent.prototype.exportSelectedPunchCards = function (punchCards, locked) {
        var _this = this;
        if (punchCards === void 0) { punchCards = null; }
        if (locked === void 0) { locked = false; }
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.filters, punchCards || this.selectedPunchCards, this.excludePunchCards, this.allSelected, this.search), params = _a.params, scope = _a.scope;
        if (this.enabledFeatures.includes('hasExportTracking')) {
            scope['markAsExported'] = true;
        }
        if (this.enabledFeatures.includes('hasExportProtection')) {
            params = params.set('unexported', 'True');
        }
        this.punchCardService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'punchcards',
                    buttonText: 'Export Data to CSV',
                    callback: _this.exportCallback,
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.punchCardService,
                    lockedFields: locked ? _this.lockedExportFields : null
                }
            });
        }, function (err) { _this.errors = err; });
    };
    PunchCardsComponent.prototype.voidSelectedPunchCards = function (punchCards) {
        var _this = this;
        if (punchCards === void 0) { punchCards = null; }
        punchCards = punchCards ? punchCards : this.selectedPunchCards;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Punch Cards?',
            body: 'These punch cards will be marked as \'Void\' and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                punchCards.forEach(function (punchCardId, index) {
                    var punchCard = lodash_1.find(_this.punchCards, { id: punchCardId });
                    _this.punchCardService.save({ id: punchCard.id, void: true })
                        .subscribe(function (result) {
                        punchCard['void'] = true;
                        punchCard['loading'] = false;
                        if (index === punchCards.length - 1) {
                            _this.allSelected = false;
                            _this.selectedPunchCards = [];
                            _this.selectedCount = 0;
                            setTimeout(function () {
                                _this.getPunchCards();
                            }, 2000);
                        }
                    }, function (err) {
                        _this.errors = err;
                    });
                });
            }
            _this.confirmDialog = null;
        });
    };
    PunchCardsComponent.prototype.voidPunchCard = function (punchCard) {
        var _this = this;
        punchCard.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Punch Card?',
            body: 'This Punch Card will be marked as \'Void\' and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.punchCardService.save({ id: punchCard.id, void: true })
                    .subscribe(function (result) {
                    punchCard.void = true;
                    punchCard.loading = false;
                }, function (err) {
                    _this.errors = err;
                    punchCard.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    PunchCardsComponent.prototype.unvoidPunchCard = function (punchCard) {
        var _this = this;
        punchCard.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unvoid Punch Card?',
            body: 'This Punch Card will be unmarked as \'Void\' and will be visible for the Job.',
            close: 'Cancel',
            accept: 'Unvoid'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.punchCardService.save({ id: punchCard.id, void: false })
                    .subscribe(function (result) {
                    punchCard.void = false;
                    punchCard.loading = false;
                }, function (err) {
                    _this.errors = err;
                    punchCard.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    PunchCardsComponent.prototype.formattedDuration = function (startTime) {
        var duration = moment.duration(moment().diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    PunchCardsComponent.prototype.expandPunchCard = function (punchCard) {
        if (this.expandedPunchCards[punchCard.id]) {
            delete this.expandedPunchCards[punchCard.id];
        }
        else {
            this.expandedPunchCards[punchCard.id] = true;
            this.expandedPunchCards = __assign({}, this.expandedPunchCards);
        }
    };
    // isPunchCardExpanded(punchCard): boolean {
    //   if (_find(this.expandedPunchCards, {id: punchCard.id})) {
    //     return true;
    //   }
    //   return false;
    // }
    PunchCardsComponent.prototype.selector = function (event, punchCard) {
        var _this = this;
        if (punchCard === void 0) { punchCard = null; }
        if (punchCard) {
            if (!event.target.checked) {
                punchCard.selected = false;
                lodash_1.pull(this.selectedPunchCards, punchCard.id);
                if (this.allSelected) {
                    this.excludePunchCards.push(punchCard.id);
                    this.selectedCount = (this.count - this.excludePunchCards.length);
                }
                else {
                    this.selectedCount = this.selectedPunchCards.length;
                }
            }
            else {
                punchCard.selected = true;
                if (this.allSelected) {
                    lodash_1.pull(this.excludePunchCards, punchCard.id);
                    this.selectedCount = (this.count - this.excludePunchCards.length);
                }
                else {
                    this.selectedPunchCards.push(punchCard.id);
                    this.selectedCount = this.selectedPunchCards.length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected = false;
                this.punchCards.forEach(function (_punchCard) { _punchCard.selected = false; });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                this.selectedCount = (this.count - this.excludePunchCards.length);
            }
            this.selectedPunchCards = [];
            this.excludePunchCards = [];
        }
        this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map(function (opt) { return (opt.action === 'void' ? __assign({}, opt, { disabled: _this.selectedCount === 0 }) : opt); });
    };
    PunchCardsComponent.prototype.prettyDate = function (dateString) {
        var date = new Date(dateString);
        return date instanceof Date && !isNaN(date.getTime()) ? this.datePipe.transform(date, 'MM/dd/yyyy') : '';
    };
    PunchCardsComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuOpened = true;
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId,
        });
    };
    return PunchCardsComponent;
}());
exports.PunchCardsComponent = PunchCardsComponent;
