"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./ruckit-dropdown.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../dropdown/dropdown.component.ngfactory");
var i3 = require("@angular/common");
var i4 = require("@angular/flex-layout/extended");
var i5 = require("@angular/flex-layout/core");
var i6 = require("../dropdown/dropdown.component");
var i7 = require("./ruckit-dropdown.component");
var i8 = require("@ngx-translate/core");
var styles_RuckitDropdownComponent = [i0.styles];
var RenderType_RuckitDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RuckitDropdownComponent, data: {} });
exports.RenderType_RuckitDropdownComponent = RenderType_RuckitDropdownComponent;
function View_RuckitDropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { optionsElRef: 0 }), i1.ɵqud(671088640, 2, { dropdownComponent: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "dropdown", [], null, [[null, "onSelect"], [null, "onSearch"], [null, "nextPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.toggleOption($event) !== false);
        ad = (pd_0 && ad);
    } if (("onSearch" === en)) {
        var pd_1 = (_co.changeSearch($event) !== false);
        ad = (pd_1 && ad);
    } if (("nextPage" === en)) {
        var pd_2 = (_co.onScroll() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_DropdownComponent_0, i2.RenderType_DropdownComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "small": 0 }), i1.ɵdid(6, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { "small": 0 }), i1.ɵdid(8, 770048, [[2, 4], ["dropdownComponent", 4]], 0, i6.DropdownComponent, [i1.ElementRef, i1.ElementRef], { open: [0, "open"], selectedOption: [1, "selectedOption"], selectedItems: [2, "selectedItems"], title: [3, "title"], icon: [4, "icon"], config: [5, "config"], loading: [6, "loading"], options: [7, "options"], disabled: [8, "disabled"], toggle: [9, "toggle"] }, { onSelect: "onSelect", nextPage: "nextPage", onSearch: "onSearch" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.config.small); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, _co.config.small); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.open; var currVal_3 = _co.selectedOption; var currVal_4 = _co.selectedItems; var currVal_5 = i1.ɵinlineInterpolate(1, "", (_co.loading ? _co.config.loadingText : _co.config.selectText), ""); var currVal_6 = _co.icon; var currVal_7 = _co.config; var currVal_8 = _co.loading; var currVal_9 = _co.options; var currVal_10 = _co.disabled; var currVal_11 = _co.toggle; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
exports.View_RuckitDropdownComponent_0 = View_RuckitDropdownComponent_0;
function View_RuckitDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ruckit-dropdown", [], null, null, null, View_RuckitDropdownComponent_0, RenderType_RuckitDropdownComponent)), i1.ɵdid(1, 770048, null, 0, i7.RuckitDropdownComponent, [i1.Injector, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RuckitDropdownComponent_Host_0 = View_RuckitDropdownComponent_Host_0;
var RuckitDropdownComponentNgFactory = i1.ɵccf("ruckit-dropdown", i7.RuckitDropdownComponent, View_RuckitDropdownComponent_Host_0, { open: "open", options: "options", selectedOption: "selectedOption", selectedItems: "selectedItems", icon: "icon", config: "config", backgroundColor: "backgroundColor", loading: "loading", disabled: "disabled", toggle: "toggle" }, { onSelect: "onSelect", onUserSelect: "onUserSelect", nextPage: "nextPage", onSearch: "onSearch" }, []);
exports.RuckitDropdownComponentNgFactory = RuckitDropdownComponentNgFactory;
