"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
// angular material
var material_1 = require("@angular/material");
var moment = require("moment");
// components
var index_1 = require("../../shared/dialogs/index");
var driver_note_dialog_component_1 = require("../driver-note/driver-note-dialog.component");
var edit_trip_dialog_component_1 = require("../../trips/edit-trip-dialog/edit-trip-dialog.component");
// models
var trip_1 = require("../../trips/trip");
var job_event_share_1 = require("../../job-event-shares/job-event-share");
// services
var trip_decision_service_1 = require("../../trips/trip-decision.service");
var trip_service_1 = require("../../trips/trip.service");
var shared_1 = require("../../shared");
var DriverTripsComponent = /** @class */ (function () {
    function DriverTripsComponent(tripDecisionService, tripService, authenticationService, deviceDetectorService, route, router, location, dialog) {
        this.tripDecisionService = tripDecisionService;
        this.tripService = tripService;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.route = route;
        this.router = router;
        this.location = location;
        this.dialog = dialog;
        this.trips = [];
        this.hideApproved = false;
        this.showVoided = false;
        this.shouldAuditDecisions = new core_1.EventEmitter();
        this.updateTripData = new core_1.EventEmitter();
        this.loading = true;
        this.errors = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.displayedColumns = [
            'total-time', 'start-time', 'end-time', 'loading-ticket-number',
            'unloading-ticket-number', 'amount', 'invoice', 'expense', 'exported', 'note', 'approved-by', 'actions'
        ];
        this.dataSource = new material_1.MatTableDataSource();
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    DriverTripsComponent.prototype.ngOnInit = function () {
        this.organization = this.authenticationService.getOrganization();
        this.user = this.authenticationService.user();
        this.returnTo = this.location.path();
    };
    DriverTripsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        this.loading = true;
        var trips = this.trips.filter(function (v) { return v.jobEventShare.id === _this.jobEventShare.id; });
        trips = trips.filter(function (v) {
            if (_this.hideApproved) {
                return !v.latestDecisionStatus || (v.latestDecisionStatus && v.latestDecisionStatus !== 'approved');
            }
            else {
                return true;
            }
        });
        trips = trips.filter(function (v) {
            return _this.showVoided ? true : !v.void;
        });
        this.dataSource.data = trips;
        if (this.tripsTable) {
            this.tripsTable.renderRows();
        }
        this.loading = false;
    };
    DriverTripsComponent.prototype.setSelectedBulkAction = function (action, trip) {
        switch (action) {
            case 'void':
                this.voidTrip(trip);
                break;
            case 'unvoid':
                this.unvoidTrip(trip);
                break;
        }
    };
    DriverTripsComponent.prototype.voidTrip = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Trip?',
            body: 'This trip will be marked as \'Void\' and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: true })
                    .subscribe(function (result) {
                    trip.void = true;
                    trip.loading = false;
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    DriverTripsComponent.prototype.unvoidTrip = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unvoid Trip?',
            body: 'This trip will be unmarked as \'Void\' and will be visible for the Job.',
            close: 'Cancel',
            accept: 'Unvoid'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: false })
                    .subscribe(function () {
                    trip.void = false;
                    trip.loading = false;
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    /**
     * Makes an edit to either the startTime or endTime field and saves that edit to the API trip record
     *
     * @param {Trip} trip The target trip
     * @param {string} field The time field we will apply the edit to
     * @param {any} value The edit value for the time adjustment
     */
    DriverTripsComponent.prototype.editTripTime = function (trip, field, value) {
        var _this = this;
        var saveData = { id: trip.id };
        var hasChanges = false;
        var newTime = moment(value);
        if (field === 'start' && !newTime.isSame(trip.startTimeTimestamp)) {
            hasChanges = true;
            saveData.startTime = value;
            if (newTime.isAfter(trip.endTimeTimestamp)) {
                saveData.endTime = value;
            }
        }
        else if (field === 'end' && !newTime.isSame(trip.endTimeTimestamp)) {
            hasChanges = true;
            saveData.endTime = value;
            if (newTime.isBefore(trip.startTimeTimestamp)) {
                saveData.startTime = value;
            }
        }
        if (!hasChanges) {
            return;
        }
        // Change detected, send to server
        trip.loading = true;
        this.tripService.save(saveData).subscribe(function (updates) {
            var tripUpdates = new trip_1.Trip(updates);
            _this.updateTrip(Object.assign(trip, {
                startTimeTimestamp: tripUpdates.startTimeTimestamp,
                endTimeTimestamp: tripUpdates.endTimeTimestamp,
                duration: tripUpdates.duration,
                completedTripDuration: tripUpdates.completedTripDuration
            }));
            trip.loading = false;
        }, function (err) {
            _this.errors = err;
            trip.loading = false;
        });
    };
    DriverTripsComponent.prototype.editTrip = function (trip) {
        var _this = this;
        var dialog = this.dialog.open(edit_trip_dialog_component_1.EditTripDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.tripId = trip.id;
        dialog.componentInstance.callback = function (edittedTrip) {
            var queryParamRefreshPage = _this.route.snapshot.queryParams['refreshPage'];
            if (edittedTrip && edittedTrip.driver) {
                _this.router.navigate([], {
                    relativeTo: _this.route,
                    queryParams: {
                        driver: edittedTrip.driver.id,
                        'refreshPage': queryParamRefreshPage ? null : true,
                    },
                    queryParamsHandling: 'merge'
                });
            }
            else {
                _this.router.navigate([], {
                    relativeTo: _this.route,
                    queryParams: {
                        'refreshPage': queryParamRefreshPage ? null : true,
                    },
                    queryParamsHandling: 'merge'
                });
            }
        };
    };
    DriverTripsComponent.prototype.unapproveTrip = function (trip) {
        this.approveTrip(trip, 'pending');
    };
    DriverTripsComponent.prototype.approveTrip = function (trip, decisionStatus) {
        var _this = this;
        if (decisionStatus === void 0) { decisionStatus = 'approved'; }
        trip.loading = true;
        var data = {
            trip: trip, decisionStatus: decisionStatus, decidedBy: this.user,
            organization: this.organization, decidedAt: new Date().toISOString()
        };
        this.tripDecisionService.save(data).subscribe(function (decision) {
            trip.latestDecision = decision.id;
            trip.latestDecisionStatus = decision.decisionStatus;
            trip.latestExportedAt = decision.exportedAt;
            trip.latestDecider = decision.decidedBy && decision.decidedBy.id;
            trip.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
            _this.shouldAuditDecisions.emit();
            _this.updateTrip(trip);
            trip.loading = false;
        }, function (err) {
            _this.errors = err;
            trip.loading = false;
        });
    };
    DriverTripsComponent.prototype.toggleExportedFlag = function (trip, value) {
        var _this = this;
        trip.loading = true;
        var data = {
            id: trip.latestDecision,
            trip: trip,
            exportedAt: value ? new Date().toISOString() : null,
            organization: this.organization,
            decisionStatus: trip.latestDecisionStatus
        };
        this.tripDecisionService.save(data).subscribe(function (decision) {
            trip.latestExportedAt = decision.exportedAt;
            _this.updateTrip(trip);
            trip.loading = false;
        }, function (err) {
            _this.errors = err;
            trip.loading = false;
        });
    };
    DriverTripsComponent.prototype.updateTrip = function (updates) {
        var updatedIndex = this.dataSource.data.findIndex(function (t) { return (t['id'] === updates['id']); });
        this.dataSource.data[updatedIndex] = Object.assign(this.dataSource.data[updatedIndex], {
            duration: updates.duration,
            startTimeTimestamp: updates.startTimeTimestamp,
            endTimeTimestamp: updates.endTimeTimestamp,
            completedTripDuration: updates.completedTripDuration,
            latestExportedAt: updates.latestExportedAt,
            exported: !!updates.latestExportedAt
        });
        if (this.tripsTable) {
            this.tripsTable.renderRows();
        }
        this.updateTripData.emit(this.dataSource.data);
    };
    DriverTripsComponent.prototype.onDriverNoteClick = function (tripId, note) {
        var _this = this;
        var dialog = this.dialog.open(driver_note_dialog_component_1.DriverNoteDialogComponent, {
            width: '430px',
        });
        dialog.componentInstance.tripId = tripId;
        dialog.componentInstance.note = note ? note : '';
        dialog
            .afterClosed()
            .subscribe(function (data) {
            if (data && data.note !== null && data.note !== undefined) {
                var updatedIndex = _this.dataSource.data.findIndex(function (t) { return t['id'] === data.tripId; });
                _this.dataSource.data[updatedIndex] = Object.assign(_this.dataSource.data[updatedIndex], {
                    reviewerNotes: data.note,
                });
                if (_this.tripsTable) {
                    _this.tripsTable.renderRows();
                }
                _this.updateTripData.emit(_this.dataSource.data);
            }
        });
    };
    return DriverTripsComponent;
}());
exports.DriverTripsComponent = DriverTripsComponent;
