'use strict';
import {
  Component, Input, Output, ElementRef, ViewChild, EventEmitter, HostListener,
  OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { find, groupBy, get as _get } from 'lodash';

import { Scale } from '../../../scales/scale';
import { KeyCodes } from '../../../shared/enums/key-codes.enum';

const findIndex = function (arr, el) {
  return Array.prototype.indexOf.call(arr, el);
};
const includes = function (arr, el) {
  return findIndex(arr, el) !== -1;
};

@Component({
  selector: 'scale-dropdown',
  templateUrl: './scale-dropdown.component.html',
  styleUrls: ['./scale-dropdown.component.scss'],
  providers: [DecimalPipe]
})
export class ScaleDropdownComponent implements OnChanges, OnInit {
  open = false;
  public selectedItems = [];
  menuHeight = '0px';
  menuWidth = '100%';
  searchTerm;
  keySearchTerm = '';
  keyTimeout;
  groups = {};
  groupKeys;
  @ViewChild('optionsEl', { static: false }) optionsElRef: ElementRef;
  @Input() selectedOption;
  @Input('title') title;
  @Input('icon') icon;
  @Input() setTitle = true;
  @Input() changeTitle = true;
  @Input() config;
  @Input() options = [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();
  private scaleRefreshInterval: any;
  private scaleRefreshIntervalTime = 10000;
  @Output() onScaleRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled;
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (!this._eref.nativeElement.contains(event.target) && this.open) {
      this.toggleMenu();
    }
    this.onClick.emit(this.open);
  }

  constructor(
    private el: ElementRef,
    private _eref: ElementRef,
    private decimalPipe: DecimalPipe
  ) {
    this.config = this.config || {};
  }

  ngOnInit() {
    this.setDefaultConfig();
    this.setSelectedOption();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDefaultConfig();
    this.setSelectedOption();

    if (this.config && this.config.group) {
      this.groups = groupBy(this.options, this.config.groupBy);
    }
    this.groupKeys = Object.keys(this.groups);
    if (this.open) {
      setTimeout(() => {
        this.setSize();
      }, 0);
    }
  }

  setDefaultConfig() {
    this.config = this.config || {};
    this.config = {
      scaleStatus: 'status',
      scaleName: 'name',
      scaleWeight: 'weight',
      scaleUnit: 'weightUnit',
      nameProperty: 'name',
      subtitleProperty: 'subtitle',
      subtitle: false,
      imageProperty: 'image',
      image: false,
      multiselect: false,
      selectedProp: 'selected',
      style: '',
      pin: '',
      groupBy: 'group',
      group: false,
      selectable: true,
      width: undefined,
      height: undefined,
      loadingScales: false,
      ...this.config
    };
  }

  public setSelectedOption() {
    this.selectedOption =
      (this.options &&
        this.options.find(option => {
          return option[this.config.selectedProp];
        })) ||
      this.selectedOption;
  }

  onKeyPress(e) {
    if (this.el.nativeElement.contains(document.activeElement)) {
      if (e.code === 'Escape' && this.open) {
        this.toggleMenu();
      }
      let options = this.optionsElRef.nativeElement.children[0].children;
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
        if (!this.open) {
          this.toggleMenu();
        } else if (options.length) {
          let lastOption = options[options.length - 1];
          let index = findIndex(options, document.activeElement);
          if (e.code === 'ArrowDown') {
            if (!includes(options, document.activeElement)) {
              options[0].focus();
            } else if (document.activeElement !== lastOption) {
              options[index + 1].focus();
            }
          } else if (e.code === 'ArrowUp') {
            if (!includes(options, document.activeElement)) {
              lastOption.focus();
            } else if (index !== 0) {
              options[index - 1].focus();
            }
          }
        }
        e.preventDefault();
      } else if (
        e.key.match(/^\w$/) &&
        e.target.nodeName.toLowerCase() !== 'input'
      ) {
        if (this.keyTimeout) {
          clearTimeout(this.keyTimeout);
        }
        this.keyTimeout = setTimeout(() => {
          this.keySearchTerm = '';
        }, 1000);
        this.keySearchTerm += e.key.toLowerCase();
        let option: any = find(options, (o: HTMLElement) => {
          return o.innerText
            .toLowerCase()
            .match(RegExp('^' + this.keySearchTerm));
        });
        if (option) {
          option.focus();
        }
      }
    }
    setTimeout(() => {
      if (
        this.open &&
        !this.el.nativeElement.contains(document.activeElement)
      ) {
        this.toggleMenu();
      }
    }, 0);
  }
  buttonKeyPress(e) {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      this.toggleMenu();
    }
  }
  optionKeyPress(e, option) {
    if (e.code === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      this.toggleOption(option);
    }
  }
  toggleMenu() {
    if (!this.disabled) { this.open = !this.open; }
    this.onToggle.emit(this.open);
    this.setSize();
    this.toggleScaleRefresh();
  }

  isTareWeight(scaleWeight: Scale) {
    if (scaleWeight.weightType === 'tare') {
      return true;
    }
  }

  toggleScaleRefresh() {
    if (this.open) {
      this.scaleRefreshInterval = setInterval(() => {
        this.onScaleRefresh.emit(this.open);
      }, this.scaleRefreshIntervalTime);
    } else {
      clearInterval(this.scaleRefreshInterval);
    }
  }

  setSize() {
    const menu = this.optionsElRef.nativeElement;

    if (this.config.height) {
      this.menuHeight = (this.open ? this.config.height : 0) + 'px';
    } else {
      this.menuHeight = (this.open ? menu.offsetHeight : 0) + 'px';
    }

    if (this.config.width) {
      this.menuWidth = this.config.width + 'px';
    } else {
      this.menuWidth = this.optionsElRef.nativeElement.offsetWidth + 'px';
    }

    const rect = menu.getBoundingClientRect();
    menu.parentElement.style.top =
      '-' + ((this.open ? rect.height : 0) + 14) + 'px';
  }

  toggleOption(option) {
    if (
      !this.config.selectable ||
      option.disabled ||
      this.isZeroWeight(option)
    ) {
      return;
    }

    if (this.config.multiselect) {
      option[this.config.selectedProp] = !option[this.config.selectedProp];
      this.onSelect.emit(
        this.options.filter(_option => {
          return _option[this.config.selectedProp];
        })
      );
    } else {
      this.deselectAll();
      this.selectedOption = option;
      this.toggleMenu();
      this.onSelect.emit(this.selectedOption);
    }
  }
  isSelected(option) {
    return (
      !option.button &&
      (option[this.config.selectedProp] ||
        (option && this.selectedOption && option.id === this.selectedOption.id))
    );
  }
  isZeroWeight(option) {
    return option.weight <= 0;
  }
  public deselectAll() {
    this.options.forEach(option => {
      if (typeof option === 'object') {
        option[this.config.selectedProp] = undefined;
      }
    });
    this.selectedOption = undefined;
  }

  getButtonStatus() {
    return this.optionScaleStatus(this.selectedOption);
  }

  getButtonText() {
    this.setSelectedOption();
    let buttonText = '';
    if (this.config.multiselect) {
      if (this.selectedItems.length === 1) {
        buttonText = this.optionDisplay(this.selectedItems[0]);
      }
    } else {
      // single select
      buttonText = this.selectedOption
        ? this.optionDisplay(this.selectedOption) +
        (this.config.subtitle
          ? ' ' + this.optionSubtitleDisplay(this.selectedOption)
          : '')
        : this.title;
    }
    if (!this.setTitle) {
      buttonText = null;
    }
    if (!this.changeTitle) {
      buttonText = this.title;
    }
    return buttonText;
  }
  optionDisplay(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.nameProperty);
    } else {
      return option;
    }
  }
  optionSubtitleDisplay(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.subtitleProperty);
    } else {
      return option;
    }
  }
  optionImageDisplay(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.imageProperty);
    } else {
      return option;
    }
  }
  optionScaleStatus(option) {
    if (option && typeof option === 'object') {
      let _option = _get(option, this.config.scaleStatus);
      return _option;
    } else {
      return option;
    }
  }
  optionScaleName(option) {
    if (typeof option === 'object') {
      return _get(option, this.config.scaleName);
    } else {
      return option;
    }
  }
  optionScaleWeight(option) {
    if (typeof option === 'object') {
      if (option.disabled) { return '-'; }
      let weight = _get(option, this.config.scaleWeight);
      let toReturnWeight = this.decimalPipe.transform(weight);
      let toReturnUnit = _get(option, this.config.scaleUnit);
      return toReturnWeight + ' ' + toReturnUnit;
    } else {
      return option;
    }
  }

  onSearchboxKeyPress(event: KeyboardEvent) {
    if (event.code === KeyCodes.Enter) {
      this.onSearch.emit(this.searchTerm);
      event.preventDefault();
    }
  }

  onBlur() {
    this.onSearch.emit(this.searchTerm);
  }

  onScroll(e) {
    if (
      !this.config.loadingOptions &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3
    ) {
      this.nextPage.emit();
    }

    let div = e.currentTarget;
    if (div.clientHeight + div.scrollTop + e.deltaY >= div.scrollHeight) {
      e.preventDefault();
      div.scrollTop = div.scrollHeight;
    } else if (div.scrollTop + e.deltaY <= 0) {
      e.preventDefault();
      div.scrollTop = 0;
    }
  }
  windowScroll(e) {
    if (this.open) {
      e.preventDefault();
    }
    return false;
  }
}
