"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var widget_1 = require("./widget");
var WidgetComponent = /** @class */ (function () {
    function WidgetComponent() {
        this.loading = true;
        this.errors = [];
        this.noPadding = false;
    }
    WidgetComponent.prototype.ngOnInit = function () {
        this.widget = new widget_1.Widget({ size: this.size, noPadding: this.noPadding });
    };
    return WidgetComponent;
}());
exports.WidgetComponent = WidgetComponent;
