import { combineLatest, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone } from 'lodash';
import { requestHeaders, handleError } from '../shared/api.service';

import { Collaborator } from './collaborator';
import { environment } from '../../environments/environment';
import { CollaborationTemplate } from './collaboration-template';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class CollaboratorService {
  baseUrl = environment.serverUrl;
  collaborator: Collaborator;
  token: string;
  organizationId: string;
  params: HttpParams;
  public nextUri;
  public count = 0;
  public listAllProgress = new Subject<number>();

  constructor(private http: HttpClient) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.token = currentUser.token;
      this.organizationId = currentUser.organization && currentUser.organization.id;
    }
    this.params = new HttpParams();
    this.listAllProgress.next(0);
  }

  getNext(): Observable<Collaborator[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()}).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  getCollaborators(jobEventId, query: any = null): Observable<Collaborator[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let collaboratorsUrl = this.baseUrl + 'shares/';
    params = params.set('jobevent', jobEventId);
    params = params.set('shared_by', this.organizationId);

    return this.http.get(collaboratorsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getShares(query: any = null): Observable<Collaborator[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let collaboratorsUrl = this.baseUrl + 'shares/';

    return this.http.get(collaboratorsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getAllShares(pageSize = 50, query?: any): Observable<Collaborator[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    params = params.set('page_size', '1');
    let collaboratorsUrl = this.baseUrl + 'shares/';

    let requestCount = 0;
    this.listAllProgress.next(0);

    return this.http.get(collaboratorsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => (this.captureMetaData(res))),
      mergeMap(() => {
        params = params.set('page_size', pageSize.toString());
        let nextReqs: Observable<any>[] = [];
        if (this.count < pageSize) {
          nextReqs.push(
            this.http.get(collaboratorsUrl, {
              headers: requestHeaders(),
              params: params
            }).map(res => {
              this.listAllProgress.next(1);
              return this.captureMetaData(res);
            })
          );
        } else {
          this.listAllProgress.next(1 / Math.ceil(this.count / pageSize));
          for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
            params = params.set('page', i.toString());
            nextReqs.push(
              this.http.get(collaboratorsUrl, {
                headers: requestHeaders(),
                params: params
              }).pipe(
                map(res => {
                  requestCount++;
                  this.listAllProgress.next(requestCount / Math.ceil(this.count / pageSize));
                  return this.captureMetaData(res);
                })
              )
            );
          }
        }
        return combineLatest(nextReqs);
      }),
      map(data => {
        let mergedList: any[] = [];
        data.forEach(list => {
          mergedList = mergedList.concat(list);
        });
        return mergedList.map(collaborator => {
          collaborator = camelcaseKeysDeep(collaborator);
          return new Collaborator(collaborator);
        });
      }),
      catchError(handleError)
    );
  }

  getPending(query: any = null): Observable<Collaborator[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let collaboratorsUrl = this.baseUrl + 'shares/';
    params = params.set('status', 'pending');
    params = params.set('organization', this.organizationId);
    let endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    params = params.set('end__gte', endDate.toISOString());
    // params = params.set('group_by_job', 'True');

    return this.http.get(collaboratorsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  save(jobEventId, collaborator) {
    let collaboratorUrl = this.baseUrl + 'jobevents/' + jobEventId + '/share/';
    let _collaborator = clone(collaborator);
    if (!_collaborator.customFieldData) { _collaborator.customFieldData = {}; }
    _collaborator.customFieldData[_collaborator.brokerRateCodeKey] = _collaborator.brokerRateCode;
    _collaborator = decamelizeKeysDeep(_collaborator);

    if (!collaborator.id) {
      return this.http.post(collaboratorUrl, _collaborator, {
        headers: requestHeaders()
      }).pipe(
        map(res => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      delete _collaborator.status;
      collaboratorUrl = this.baseUrl + 'shares/' + collaborator.id + '/';
      return this.http.put(collaboratorUrl, _collaborator, {
        headers: requestHeaders()
      }).pipe(
        map(res => { return this.extractData(res); }),
        catchError(handleError)
      );
    }
  }

  accept(collaborator) {
    let collaboratorUrl = this.baseUrl + 'shares/' + collaborator.id + '/';
    const _collaborator = decamelizeKeysDeep(clone(collaborator));
    _collaborator.status = 'accepted';

    return this.http.put(collaboratorUrl, _collaborator, {
      headers: requestHeaders()
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  reject(collaboratorId) {
    let collaboratorUrl = this.baseUrl + 'shares/' + collaboratorId + '/';

    return this.http.put(collaboratorUrl, {status: 'declined'}, {
      headers: requestHeaders()
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  updateDays(jobEventId, collaborator) {
    const collaboratorUrl = this.baseUrl + 'jobevents/' + jobEventId + '/share/';
    collaborator.removeMissing = true;
    const _collaborator = decamelizeKeysDeep(collaborator);
    return this.http.post(collaboratorUrl, _collaborator, {
      headers: requestHeaders(), responseType: 'text'
    });
  }

  multiShare(jobId, model) {
    const collaboratorUrl = this.baseUrl + 'jobs/' + jobId + '/share/';
    const _model = decamelizeKeysDeep(clone(model));
    return this.http.post(collaboratorUrl, _model, {
      headers: requestHeaders(), responseType: 'text'
    });
  }

  bulkSave(jobId: string, collaborations: CollaborationTemplate[] = []) {
    const collaboratorUrl = this.baseUrl + 'jobs/' + jobId + '/jobevents/share/';
    collaborations.forEach(collaboration => {
      delete collaboration.jobEventOptions;
      delete collaboration.jobEventsLoading;
      if (collaboration.invoiceWeightUnit === 'load' || collaboration.invoiceWeightUnit === 'hour') {
        collaboration.invoiceWeightUnit = 'ton';
      }
    });
    return this.http.post(collaboratorUrl, {
      collaborators: decamelizeKeysDeep(collaborations)
    }, {
      headers: requestHeaders(), responseType: 'text'
    });
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(collaborator => {
        collaborator = camelcaseKeysDeep(collaborator);
        return new Collaborator(collaborator);
      });
    } else if (resObj) {
      return new Collaborator(camelcaseKeysDeep(resObj));
    } else {
      return {};
    }
  }

  private captureMetaData(res: any, paginated = true): any {
    let json = res;
    if (paginated) {
      this.nextUri = json['next'];
    }
    this.count = json['count'] || json['results'] && json['results'].length;
    return json.results || json;
  }
}
