"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_1 = require("@ngui/map");
var lodash_1 = require("lodash");
var location_1 = require("../../locations/location");
var location_service_1 = require("../location.service");
var authentication_service_1 = require("../../shared/authentication.service");
var api_service_1 = require("../../shared/api.service");
var location_owner_service_1 = require("../location-owner.service");
var shared_1 = require("../../shared");
var address_1 = require("../address");
var location_type_service_1 = require("../location-type.service");
var location_owner_serializer_1 = require("../location-owner.serializer");
var EditLocationDetailsComponent = /** @class */ (function () {
    function EditLocationDetailsComponent(locationService, apiService, authenticationService, locationOwnerService) {
        this.locationService = locationService;
        this.apiService = apiService;
        this.authenticationService = authenticationService;
        this.locationOwnerService = locationOwnerService;
        this.includeInstructions = true;
        this.isGeoFenceAvailable = false;
        this.onPlaceChanged = new core_1.EventEmitter();
        this.onEditCompletion = new core_1.EventEmitter();
        this.clearGeoFence = new core_1.EventEmitter();
        this.loading = false;
        this.showLocationOwnerTextField = false;
        this.errors = [];
        this.states = [];
        this.stateOptions = [];
        this.countryOptions = [];
        this.ownerOptions = [];
        this.ownersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.zoom = 8;
        this.drawingMode = undefined;
        this.paths = [];
        this.address = new address_1.Address();
        this.selectedAddress = new address_1.Address();
        this.addresses = [];
        this.mapOptions = {};
        this.qrEnabled = false;
        this.laserFicheEnabled = false;
        this.locationTypeDropdownConfig = {
            service: location_type_service_1.LocationTypeService,
            selectText: 'Select Location Type',
            loadingText: 'Loading Location Types...',
            noResultsText: 'No Location Types'
        };
    }
    EditLocationDetailsComponent.prototype.ngOnInit = function () {
        this.states = this.apiService.getStates();
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name
            };
        });
        if (this.location && this.location.country) {
            this.selectCountry(this.location.country);
        }
        else {
            this.selectCountry('US');
        }
        var user = this.authenticationService.user();
        this.qrEnabled = user && user.organization && user.organization.qrEnabled;
        var enabledFeatures = this.authenticationService.enabledFeatures();
        if (enabledFeatures && enabledFeatures.includes('useLaserficheExport')) {
            this.laserFicheEnabled = true;
        }
        this.getLocationOwners();
    };
    EditLocationDetailsComponent.prototype.ngOnDestroy = function () {
        if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
            this.locationOwnersReq.unsubscribe();
        }
    };
    EditLocationDetailsComponent.prototype.getLocationOwners = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
            this.locationOwnersReq.unsubscribe();
        }
        this.locationOwnersReq = this.locationOwnerService.list(__assign({ ordering: 'name' }, query)).subscribe(function (locationOwners) {
            _this.ownerOptions = locationOwners.map(function (locationOwner) {
                return { name: locationOwner.name, id: locationOwner.id };
            });
            var ownOrganization = _this.authenticationService.getOrganization();
            if (ownOrganization) {
                var _locationOwner = new location_owner_serializer_1.LocationOwnerSerializer().fromJson({
                    organization: ownOrganization.id,
                    name: ownOrganization.name
                });
                _this.ownerOptions = lodash_1.reject(_this.ownerOptions, _locationOwner);
                _this.ownerOptions.unshift(_locationOwner);
            }
            if (_this.location.locationOwner && _this.location.locationOwner.id) {
                _this.ownerOptions = lodash_1.reject(_this.ownerOptions, _this.location.locationOwner);
                _this.ownerOptions.unshift(_this.location.locationOwner);
                _this.ownersDropdown.selectedOption = lodash_1.find(_this.ownerOptions, { id: _this.location.locationOwner.id });
            }
            _this.ownerOptions.unshift({
                name: 'Add a New Owner',
                id: 'new-location-owner',
                button: true
            });
            if (_this.ownersDropdown) {
                _this.ownersDropdown.config.loadingOptions = false;
            }
            _this.ownersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.ownersConfig.loadingOptions = false;
        });
    };
    EditLocationDetailsComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'owner':
                config = this.ownersConfig;
                service = this.locationOwnerService;
                options = this.ownerOptions;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'owner':
                            var _ownerOptions = results.map(function (result) {
                                return { name: result.name, id: result.id };
                            });
                            _this.ownerOptions = _this.ownerOptions.concat(_ownerOptions);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    EditLocationDetailsComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'owner':
                this.getLocationOwners({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    EditLocationDetailsComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var coordinates = null;
        if (this.polygon) {
            var polygonPath = this.polygon.getPath();
            if (polygonPath) {
                var pathArray = polygonPath.getArray();
                if (pathArray) {
                    coordinates = pathArray.map(function (path) { return [path.lng(), path.lat()]; });
                    coordinates = lodash_1.uniqWith(coordinates, lodash_1.isEqual);
                    if (coordinates && coordinates[0] !== coordinates.reverse[0]) {
                        coordinates.push(coordinates[0]);
                    }
                }
            }
        }
        if (this.selectedGeoFence) {
            this.location.geofence = this.selectedGeoFence;
        }
        if (this.location.geofence && this.location.geofence instanceof Array) {
            var geofence = this.location.geofence[0];
            if (geofence && geofence.geometry) {
                this.location.geofence = geofence.geometry;
            }
        }
        else if (coordinates) {
            this.location.geofence = {
                type: 'Polygon',
                coordinates: [coordinates]
            };
        }
        this.locationService.save(this.location).subscribe(function (location) {
            _this.loading = false;
            _this.onEditCompletion.emit(location);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditLocationDetailsComponent.prototype.cancel = function () {
        this.onEditCompletion.emit();
    };
    EditLocationDetailsComponent.prototype.placeChanged = function (place) {
        var _this = this;
        this.location.latitude = place.geometry.location.lat();
        this.location.longitude = place.geometry.location.lng();
        if (this.location.latitude && this.location.longitude) {
            this.marker = null;
            this.marker = {
                latLng: new google.maps.LatLng(parseFloat(this.location.latitude), parseFloat(this.location.longitude)),
                mappable: true
            };
            this.location.marker = this.marker;
        }
        this.zoom = 14;
        this.address.street = null;
        this.location.city = this.extractFromAddress(place.address_components, 'locality');
        this.countryOptions.map(function (country) {
            if (country.name === _this.extractFromAddress(place.address_components, 'country')) {
                _this.selectCountry(country.id);
            }
        });
        this.states.map(function (state) {
            if (state.name === _this.extractFromAddress(place.address_components, 'administrative_area_level_1')) {
                _this.location.state = state.abbreviation;
            }
        });
        this.location.zipcode = this.extractFromAddress(place.address_components, 'postal_code');
        this.location.name = place.name;
        this.location.street = this.extractFromAddress(place.address_components, 'street_number') + ' ' +
            this.extractFromAddress(place.address_components, 'route');
        if (this.location.street && this.location.street.trim().length === 0) {
            this.location.street = this.location.name;
        }
        this.onPlaceChanged.emit(this.location);
    };
    EditLocationDetailsComponent.prototype.selectState = function (state) {
        this.location.state = state;
    };
    EditLocationDetailsComponent.prototype.selectCountry = function (country) {
        this.location.country = country;
        this.stateOptions = lodash_1.filter(this.states, { country: country }).map(function (state) {
            return {
                id: state.abbreviation,
                name: state.name
            };
        });
    };
    EditLocationDetailsComponent.prototype.selectOwner = function (owner) {
        if (owner['id'] === 'new-location-owner') {
            this.showLocationOwnerTextField = true;
            this.location.locationOwner.id = null;
        }
        else {
            this.location.locationOwner = owner;
        }
    };
    EditLocationDetailsComponent.prototype.extractFromAddress = function (components, type) {
        for (var i = 0; i < components.length; i++) {
            for (var j = 0; j < components[i].types.length; j++) {
                if (components[i].types[j] === type) {
                    return components[i].long_name;
                }
            }
        }
        return '';
    };
    EditLocationDetailsComponent.prototype.geolocate = function () {
        var _this = this;
        var displayName = this.location.street + " " + this.location.city + ", " + this.location.state + " " + this.location.zipcode;
        if (this.location.street && this.location.street.trim().length > 0) {
            displayName = displayName.replace('undefined', '');
            displayName = displayName.replace(' ,', ',');
        }
        this.address = {
            street: this.location.street,
            street2: null,
            city: this.location.city,
            state: this.location.state,
            zipcode: this.location.zipcode,
            country: this.location.country,
            displayName: displayName
        };
        var status = Object.keys(this.address).every(function (x) {
            return _this.address[x] && _this.address[x].length;
        }) === true;
        var address = Object.keys(this.address).map(function (x) {
            return _this.address[x];
        }).join(', ');
        this.copyUpdatedAddress();
        if (status) {
            this.locationService.getLocationByAddress(address).subscribe(function (res) {
                var coordinates = res && res[0];
                _this.location.latitude = coordinates.geometry.location.lat;
                _this.location.longitude = coordinates.geometry.location.lng;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    EditLocationDetailsComponent.prototype.copyUpdatedAddress = function () {
        if (this.address && this.location && this.address.street !== this.location.street) {
            this.location.street = this.address.street;
            this.location.city = this.address.city;
            this.location.state = this.address.state;
            this.location.zipcode = this.address.zipcode;
            this.location.country = this.address.country;
        }
        this.address = new address_1.Address();
    };
    EditLocationDetailsComponent.prototype.reverseGeocode = function () {
        var _this = this;
        if (this.location.latitude && this.location.longitude) {
            var coordinates = [this.location.latitude, this.location.longitude];
            this.locationService.getAddressByCoordinates(coordinates).subscribe(function (res) {
                _this.addresses = _this.processAddresses(res);
                _this.selectedAddress = _this.addresses[0];
                _this.address = _this.addresses[0];
                if (_this.location && !_this.location.street) {
                    _this.copyUpdatedAddress();
                }
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    EditLocationDetailsComponent.prototype.processAddresses = function (results) {
        var _this = this;
        var addresses = [];
        results.forEach(function (result) {
            var address = new address_1.Address();
            address.street = _this.extractFromAddress(result.address_components, 'street_number')
                + ' '
                + _this.extractFromAddress(result.address_components, 'route');
            address.city = _this.extractFromAddress(result.address_components, 'locality');
            _this.apiService.getStates().map(function (state) {
                if (state.name === _this.extractFromAddress(result.address_components, 'administrative_area_level_1')) {
                    address.state = state.abbreviation;
                }
            });
            address.zipcode = _this.extractFromAddress(result.address_components, 'postal_code');
            address.displayName = address.street + " " + address.street2 + ", " + address.city + ", " + address.state + " " + address.zipcode;
            if (address.street && address.street.trim().length > 0) {
                address.displayName = address.displayName.replace('undefined', '');
                address.displayName = address.displayName.replace(' ,', ',');
                addresses.push(address);
            }
        });
        return addresses;
    };
    EditLocationDetailsComponent.prototype.updateAddress = function (address) {
        if (address && this.location) {
            this.location.street = address.street;
            this.location.city = address.city;
            this.location.state = address.state;
            this.location.zipcode = address.zipcode;
            this.location.country = address.country;
        }
    };
    EditLocationDetailsComponent.prototype.tagChange = function (tags) {
        this.location.tags = tags.map(function (t) { return (t.name); });
    };
    EditLocationDetailsComponent.prototype.onSelect = function (propertyName, e) {
        this.location[propertyName] = e;
    };
    EditLocationDetailsComponent.prototype.onClearGeoFence = function () {
        this.clearGeoFence.emit();
    };
    return EditLocationDetailsComponent;
}());
exports.EditLocationDetailsComponent = EditLocationDetailsComponent;
