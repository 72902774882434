import { Component, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { SurchargeTemplateService } from './surcharge-template.service';
import { parseErrors } from '../shared/api.service';
import { AuthenticationService } from '../shared/authentication.service';

@Component({
  selector: 'surcharge-template-dialog',
  templateUrl: './surcharge-template-dialog.component.html',
  styleUrls: ['./surcharge-template-dialog.component.scss'],
  providers: [SurchargeTemplateService, AuthenticationService]
})
export class SurchargeTemplateDialogComponent implements OnInit {
  surchargeTemplateDate;
  positiveAmount = true;
  @Input() model = {
    id: null,
    title: null,
    description: null,
    date: null,
    amount: null,
    positive: true,
    kind: 'invoice'
  };
  @Output() completed = new EventEmitter();
  loading = false;
  hasPOS = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<SurchargeTemplateDialogComponent>,
    private surchargeTemplateService: SurchargeTemplateService,
    private authenticationService: AuthenticationService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (this.model && this.model.amount >= 0) {
      this.positiveAmount = true;
    } else {
      this.positiveAmount = false;
    }

    let organization = this.authenticationService.getOrganization();
    this.hasPOS = organization && organization.posEnabled ? true : false;
  }

  submitSurchargeTemplate() {
    this.loading = true;

    this.surchargeTemplateService.save(this.model).subscribe((res) => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  setAmountPositive(positive = true): void {
    this.model.amount = positive ? Math.abs(this.model.amount) : -Math.abs(this.model.amount);
    this.positiveAmount = positive;
  }

  amountChange(): void {
    if (this.model.amount < 0) { this.positiveAmount = false; }
    if (!this.positiveAmount) { this.model.amount = -Math.abs(this.model.amount); }
  }
}
