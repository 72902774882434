"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resource_1 = require("../shared/resource");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var RuckitCondensedTrip = /** @class */ (function (_super) {
    __extends(RuckitCondensedTrip, _super);
    function RuckitCondensedTrip() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selected = false;
        _this.invoiceSent = false;
        _this.loading = false;
        _this.expenseSent = false;
        _this.paystubSent = false;
        return _this;
    }
    RuckitCondensedTrip.prototype.updateStatus = function () {
        this.edited = true;
        if (this.void) {
            this.administrativeStatus = 'void';
        }
        else if (this.retakeStatus === 'requested') {
            this.administrativeStatus = 'retake';
        }
        else if (this.edited) {
            this.administrativeStatus = 'edited';
        }
        else {
            this.administrativeStatus = '';
        }
        this.displayableStatus =
            this.administrativeStatus && this.administrativeStatus.length
                ? true
                : false;
    };
    RuckitCondensedTrip.prototype.url = function (action) {
        return '/trips/' + this.id + '/' + action;
    };
    RuckitCondensedTrip.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.void) {
            var _option = lodash_1.find(options, { action: 'unvoid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    RuckitCondensedTrip.prototype.pluralizedInvoiceRateUnit = function (plural) {
        if (plural === void 0) { plural = true; }
        if (this.invoiceType === 'weight') {
            switch (this.invoiceWeightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
            }
        }
        else {
            return this.invoiceType;
        }
    };
    RuckitCondensedTrip.prototype.pluralizedHaulRateUnit = function (plural) {
        if (plural === void 0) { plural = true; }
        if (this.haulType === 'weight') {
            switch (this.haulWeightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
            }
        }
        else {
            return this.haulType;
        }
    };
    RuckitCondensedTrip.prototype.pluralizedInvoiceWeightUnitDisplay = function (plural) {
        if (plural === void 0) { plural = true; }
        switch (this.invoiceWeightUnit) {
            case 'cuyds':
                return plural ? 'cu. yds.' : 'cu. yd.';
            default:
                return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
        }
    };
    RuckitCondensedTrip.prototype.pluralizedHaulWeightUnitDisplay = function (plural) {
        if (plural === void 0) { plural = true; }
        switch (this.haulWeightUnit) {
            case 'cuyds':
                return plural ? 'cu. yds.' : 'cu. yd.';
            default:
                return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
        }
    };
    return RuckitCondensedTrip;
}(resource_1.Resource));
exports.RuckitCondensedTrip = RuckitCondensedTrip;
