<div class="app-content">
  <my-toolbar [title]="title"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <button id="back-button" class="btn btn-default" (click)="openReturnTo(); false" translate>Back</button>
    </div>
    <div class="right-actions">
      <button id="show-images-button" class="btn btn-default" (click)="toggleTickets(); false" translate>Show and Print Ticket Images</button>
    </div>
  </action-bar>

  <div class="html-container">
    <div class="invoice-container">
      <div class="invoice" *ngIf="invoice">
        <header>
          <div class="left-segment">
            <h1>{{ invoice.kind }} {{ invoice.number }}</h1>
            <div class="invoice-date">
              {{ invoice.invoiceDate | date:'mediumDate' || '&nbsp;' }}
            </div>
          </div>
          <div class="right-segment">
            <div class="logo">
              <img *ngIf='invoice.logo || invoice.ownerOrganization.image' [src]="invoice.logo || invoice.ownerOrganization.image" />
            </div>
          </div>
        </header>

        <div class="sub-header">
          <div class="left-segment">
            <div class="bill-from">
              <h2 translate>Bill From</h2>
              <label>{{ invoice.ownerOrganization.name }}</label>
              <p>
                <span>{{ invoice.ownerOrganization.billingAddress1 }}</span>
                <span>{{ invoice.ownerOrganization.billingAddress2 }}</span>
                <br />
                <span>{{ invoice.ownerOrganization.billingCity }}</span>,
                <span>{{ invoice.ownerOrganization.billingState }}</span>
                <span>{{ invoice.ownerOrganization.billingZipcode }}</span>
                <br />
                <span>{{ invoice.ownerOrganization.billingPhoneNumber }}</span>
              </p>
            </div>

            <div class="bill-to">
              <h2 translate>Bill To</h2>
              <label>{{ invoice.customerOrganization.name }}</label>
              <p>
                <span>{{ invoice.customerOrganization.billingAddress1 }}</span>
                <span>{{ invoice.customerOrganization.billingAddress2 }}</span>
                <br />
                <span>{{ invoice.customerOrganization.billingCity }}</span>,
                <span>{{ invoice.customerOrganization.billingState }}</span>
                <span>{{ invoice.customerOrganization.billingZipcode }}</span>
                <br />
                <span>{{ invoice.customerOrganization.billingPhoneNumber }}</span>
              </p>
            </div>
          </div>

          <div class="right-segment">
            <div class="total-amount">
              <h2 translate>Total Amount</h2>
              {{ invoice.amount || 0 | currency : 'USD' : 'symbol' }}
            </div>
          </div>
        </div>

        <div class="content">
          <div class="section-divider" data-property="trips" *ngIf="!loadingTrips && tripCount > 0">
            <span [ngPlural]="tripCount || 0">
              <ng-template ngPluralCase="=0" translate>{{ tripCount | number }} Trips</ng-template>
              <ng-template ngPluralCase="=1" translate>{{ tripCount | number }} Trip</ng-template>
              <ng-template ngPluralCase="other" translate>{{ tripCount | number }} Trips</ng-template>
            </span>
            <div class="divider-line"></div>
          </div>

          <div class="section-divider" data-property="trips" *ngIf="loadingTrips">
            <span>Loading Trips...</span>
            <div class="divider-line"></div>
          </div>

          <table data-property="trips" *ngIf="!loadingTrips && tripCount > 0">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="sort" (click)="sort('trips', 'ticketNumber')" translate>Ticket</th>
                <th class="sort" (click)="sort('trips', 'date')" translate>Date</th>
                <th class="sort" (click)="sort('trips', 'job')" translate>Job</th>
                <th class="sort" (click)="sort('trips', 'driver')" translate>Driver / Truck</th>
                <ng-container *ngIf="invoice.kind === 'invoice'">
                  <th class="sort quantity numeric" (click)="sort('trips', 'invoiceQuantity')" translate>Quantity</th>
                  <th class="sort" (click)="sort('trips', 'invoiceRate')" translate>Rate</th>
                  <th class="currency numeric sort" (click)="sort('trips', 'invoiceTotal')" translate>Total</th>
                </ng-container>
                <ng-container *ngIf="invoice.kind !== 'invoice'">
                  <th class="sort quantity numeric" (click)="sort('trips', 'invoiceQuantity')" translate>Quantity</th>
                  <th class="sort" (click)="sort('trips', 'haulRate')" translate>Rate</th>
                  <th class="currency numeric sort" (click)="sort('trips', 'haulTotal')" translate>Total</th>
                </ng-container>
              </tr>
            </thead>
            <tbody class="items">
              <ng-container *ngFor="let trip of trips; let isLast = last">
                <tr>
                  <td class='index'>{{ trip.index }}</td>
                  <td class='ticket'>{{ trip.ticketNumber || '-' }}</td>
                  <td class='date'>{{ trip.jobDate | date:'shortDate' || '&nbsp;' }}</td>
                  <td class='job'>{{ trip.job }}</td>
                  <td class='driver'>{{ trip.driver }} / {{ trip.truck }} {{ trip.truckType }}</td>
                  <ng-container *ngIf="invoice.kind === 'invoice'">
                    <td class='quantity'>{{ trip.invoiceQuantity }} {{ trip.pluralizedInvoiceRateUnit(trip.invoiceQuantity !== 1) }}</td>
                    <td class='rate'>{{ trip.invoiceRate || 0 | currency : 'USD' : 'symbol' : '1.2-4' }} / {{ trip.invoiceRateUnit }}</td>
                    <td class='currency bold total'>{{ trip.invoiceTotal || 0 | currency : 'USD' : 'symbol' }}</td>
                  </ng-container>
                  <ng-container *ngIf="invoice.kind !== 'invoice'">
                    <td class='quantity'>{{ trip.invoiceQuantity }} {{ trip.pluralizedHaulRateUnit(trip.invoiceQuantity !== 1) }}</td>
                    <td class='rate'>{{ trip.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4'}} / {{ trip.haulRateUnit }}</td>
                    <td class='currency bold total'>{{ trip.haulTotal || 0 | currency : 'USD' : 'symbol' }}</td>
                  </ng-container>
                </tr>
                <tr *ngIf="!isLast"><td class='border' colspan='42'><div></div></td></tr>
              </ng-container>
            </tbody>
          </table>

          <div class="section-divider" data-property="cards" *ngIf="loadingCards">
            <span>Loading Punch Cards...</span>
            <div class="divider-line"></div>
          </div>

          <div class="section-divider" data-property="cards" *ngIf="!loadingCards && punchCardCount > 0">
            <span [ngPlural]="punchCardCount || 0">
              <ng-template ngPluralCase="=0" translate>{{ punchCardCount | number }} Punch Cards</ng-template>
              <ng-template ngPluralCase="=1" translate>{{ punchCardCount | number }} Punch Card</ng-template>
              <ng-template ngPluralCase="other" translate>{{ punchCardCount | number }} Punch Cards</ng-template>
            </span>
            <div class="divider-line"></div>
          </div>

          <table data-property="cards" *ngIf="!loadingCards && punchCardCount > 0">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="sort" (click)="sort('cards', 'ticketNumber')" translate>Ticket</th>
                <th class="sort" (click)="sort('cards', 'jobDate')" translate>Date</th>
                <th class="sort" (click)="sort('cards', 'job')" translate>Job</th>
                <th class="sort" (click)="sort('cards', 'driver')" translate>Driver / Truck</th>
                <ng-container *ngIf="invoice.kind === 'invoice'">
                  <th class="sort quantity numeric" (click)="sort('cards', 'invoiceQuantity')" translate>Quantity</th>
                  <th class="sort" (click)="sort('cards', 'invoiceRate')" translate>Rate</th>
                  <th class="currency numeric sort" (click)="sort('cards', 'invoiceTotal')" translate>Total</th>
                </ng-container>
                <ng-container *ngIf="invoice.kind !== 'invoice'">
                  <th class="sort quantity numeric" (click)="sort('cards', 'invoiceQuantity')" translate>Quantity</th>
                  <th class="sort" (click)="sort('cards', 'haulRate')" translate>Rate</th>
                  <th class="currency numeric sort" (click)="sort('cards', 'haulTotal')" translate>Total</th>
                </ng-container>
              </tr>
            </thead>
            <tbody class="items">
              <ng-container *ngFor="let punchCard of punchCards; let isLast = last">
                <tr>
                  <td class='index'>{{ punchCard.index }}</td>
                  <td class='ticket'>{{ punchCard.ticketNumber }}</td>
                  <td class='date'>{{ punchCard.jobDate | date:'shortDate' || '&nbsp;' }}</td>
                  <td class='job'>{{ punchCard.job }}</td>
                  <td class='driver'>{{ punchCard.driver }} / {{ punchCard.truck }} {{ punchCard.truckType }}</td>
                  <ng-container *ngIf="invoice.kind === 'invoice'">
                    <td class='quantity'>{{ punchCard.invoiceQuantity }} {{ punchCard.invoiceRateUnit }}</td>
                    <td class='rate'>{{ punchCard.invoiceRate || 0 | currency : 'USD' : 'symbol' : '1.2-4' }} / {{ punchCard.invoiceRateUnit }}</td>
                    <td class='currency bold total'>{{ punchCard.invoiceTotal || 0 | currency : 'USD' : 'symbol' }}</td>
                  </ng-container>
                  <ng-container *ngIf="invoice.kind !== 'invoice'">
                    <td class='quantity'>{{ punchCard.invoiceQuantity }} {{ punchCard.haulRateUnit }}</td>
                    <td class='rate'>{{ punchCard.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4'}} / {{ punchCard.haulRateUnit }}</td>
                    <td class='currency bold total'>{{ punchCard.haulTotal || 0 | currency : 'USD' : 'symbol' }}</td>
                  </ng-container>
                </tr>
                <tr *ngIf="!isLast"><td class='border' colspan='42'><div></div></td></tr>
              </ng-container>
            </tbody>
          </table>

          <div class="section-divider" data-property="surcharges" *ngIf="!loadingSurcharges && surchargeCount > 0">
            <span [ngPlural]="surchargeCount || 0">
              <ng-template ngPluralCase="=0" translate>{{ surchargeCount | number }} Surcharges</ng-template>
              <ng-template ngPluralCase="=1" translate>{{ surchargeCount | number }} Surcharge</ng-template>
              <ng-template ngPluralCase="other" translate>{{ surchargeCount | number }} Surcharges</ng-template>
            </span>
            <div class="divider-line"></div>
          </div>

          <div class="section-divider" data-property="surcharges" *ngIf="loadingSurcharges">
            <span>Loading Surcharges...</span>
            <div class="divider-line"></div>
          </div>

          <table data-property="surcharges" *ngIf="!loadingSurcharges && surchargeCount > 0">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="sort" (click)="sort('surcharges', 'title')" translate>Item</th>
                <th class="sort" (click)="sort('surcharges', 'time')" translate>Date</th>
                <th class="sort" (click)="sort('surcharges', 'description')" translate>Description</th>
                <th class="sort currency numeric" (click)="sort('surcharges', 'amount')" translate>Total</th>
              </tr>
            </thead>
            <tbody class="items">
              <ng-container *ngFor="let surcharge of surcharges; let isLast = last">
                <tr>
                  <td class='index'>{{ surcharge.index }}</td>
                  <td class='item'>{{ surcharge.title }}</td>
                  <td class='date'>{{ surcharge.time | date:'shortDate' || '&nbsp;' }}</td>
                  <td class='description'>{{ surcharge.description }}</td>
                  <td class='currency bold total'>{{ surcharge.amount || 0 | currency : 'USD' : 'symbol' }}</td>
                </tr>
                <tr *ngIf="!isLast"><td class='border' colspan='42'><div></div></td></tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <footer>
          <div class="remittance-info">
            <h3 translate>Remittance Info</h3>
            <p>{{ invoice.remittanceInfo }}</p>
          </div>

          <div class="summary">
            <div class="detail-row subtotal">
              <label translate>Tickets Subtotal</label>
              <div class="value">
                {{ invoice.subtotal || 0 | currency : 'USD' : 'symbol' }}
              </div>
            </div>
            <div class="detail-row surcharges">
              <label translate>Surcharges</label>
              <div class="value">
                {{ invoice.surchargeAmount || 0 | currency : 'USD' : 'symbol' }}
              </div>
            </div>
            <div class="detail-row total">
              <label translate>Total Amount</label>
              <div class="value">
                {{ invoice.amount || 0 | currency : 'USD' : 'symbol' }}
              </div>
            </div>
          </div>
        </footer>

        <div class="content tickets" *ngIf="showTickets && tickets.length > 0">
          <header>
            <div class="left-segment">
              <h1>{{ invoice.kind }} {{ invoice.number }}</h1>
            </div>
            <div class="right-segment">
              <span [ngPlural]="tickets.length || 0">
                <ng-template ngPluralCase="=0" translate>{{ tickets.length | number }} Tickets</ng-template>
                <ng-template ngPluralCase="=1" translate>{{ tickets.length | number }} Ticket</ng-template>
                <ng-template ngPluralCase="other" translate>{{ tickets.length | number }} Tickets</ng-template>
              </span>
            </div>
          </header>

          <ng-container>
            <table class="tickets" data-property="tickets">
              <tbody class="items">
                <ng-container *ngFor="let ticket of tickets; let isFirst = first; let isLast = last">
                  <tr>
                    <td class="index">{{ ticket.checkin1.index }}</td>
                    <td>
                      <table>
                        <tr *ngIf="ticket.checkin1">
                          <td class="details checkin1">
                            <div class="kind">{{ ticket.checkin1.kind }}</div>
                            <div class="ticket-number">
                              <a href="{{ ticket.checkin1.ticketImage }}" target="_blank">
                                {{ '#' + ticket.checkin1.ticketNumber || '-' }}
                              </a>
                            </div>
                            <div class="date">{{ ticket.checkin1.date | date:'shortDate' || '&nbsp;' }}</div>
                            <ng-container *ngIf="invoice.kind === 'invoice'">
                              <div class="bold weight">{{ ticket.checkin1.weight }} {{ ticket.checkin1.invoiceWeightUnit }}</div>
                            </ng-container>
                            <ng-container *ngIf="invoice.kind !== 'invoice'">
                              <div class="bold weight">{{ ticket.checkin1.weight }} {{ ticket.checkin1.haulWeightUnit }}</div>
                            </ng-container>
                          </td>
                          <td class="image checkin1">
                            <a href="{{ ticket.checkin1.ticketImage }}" target="_blank">
                              <img *ngIf="ticket.checkin1.ticketImage" [src]="ticket.checkin1.printableTicketImage" />
                            </a>
                          </td>
                        </tr>

                        <tr *ngIf="ticket.checkin2">
                          <td class="details checkin2">
                            <div class="kind">{{ ticket.checkin2.kind }}</div>
                            <div class="ticket-number">
                              <a href="{{ ticket.checkin2.ticketImage }}" target="_blank">
                                {{ '#' + ticket.checkin2.ticketNumber || '-' }}
                              </a>
                            </div>
                            <div class="date">{{ ticket.checkin2.date | date:'shortDate' || '&nbsp;' }}</div>
                            <ng-container *ngIf="invoice.kind === 'invoice'">
                              <div class="bold weight">{{ ticket.checkin2.weight }} {{ ticket.checkin2.invoiceWeightUnit }}</div>
                            </ng-container>
                            <ng-container *ngIf="invoice.kind !== 'invoice'">
                              <div class="bold weight">{{ ticket.checkin2.weight }} {{ ticket.checkin2.haulWeightUnit }}</div>
                            </ng-container>
                          </td>
                          <td class="image checkin2">
                            <a href="{{ ticket.checkin2.ticketImage }}" target="_blank">
                              <img *ngIf="ticket.checkin2.ticketImage" [src]="ticket.checkin2.printableTicketImage" />
                            </a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
