import { Trackable } from './trackable';
import { TrackableTypeSerializer } from '../trackable-types/trackable-type.serializer';
import { TruckSerializer } from '../trucks/truck.serializer';
import { Organization } from '../organizations/organization';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TrackableSerializer {
  fromJson(json: any): Trackable {
    json = camelcaseKeysDeep(json);
    const trackable = new Trackable();
    if (!json) { return trackable; }

    trackable.id = json.id;
    if (json.trackableType && typeof (json.trackableType) === 'object') {
      trackable.trackableType = (new TrackableTypeSerializer().fromJson(json.trackableType));
    } else if (json.trackableType) {
      trackable.trackableType = (new TrackableTypeSerializer().fromJson({ id: json.trackableType }));
    }
    if (typeof (json.truck) === 'object') {
      trackable.truck = new TruckSerializer().fromJson(json.truck);
    } else {
      trackable.truck = new TruckSerializer().fromJson({ name: json.truck });
    }
    if (typeof (json.organization) === 'object') {
      trackable.organization = new Organization(json.organization);
    } else {
      trackable.organization = new Organization({ id: json.organization });
    }

    trackable.deviceId = json.deviceId;
    trackable.active = json.active;
    trackable.deviceState = json.deviceState;
    trackable.positionSource = json.positionSource;
    trackable.hdop = json.hdop;
    trackable.rssi = json.rssi;
    trackable.voltage = json.voltage;
    trackable.heading = json.heading;
    trackable.speed = json.speed;
    trackable.accuracy = json.accuracy;
    trackable.temperature = json.temperature;
    trackable.location = json.location;
    trackable.lastStatusEvent = json.lastStatusEvent;
    trackable.createdAt = json.createdAt;
    trackable.lastModified = json.lastModified;
    trackable.removed = json.removed;
    
    return trackable;
  }

  toJson(trackable: Trackable): any {
    let json = {
      id: trackable.id,
      truck: trackable.truck && trackable.truck.id,
      trackableType: trackable.trackableType && trackable.trackableType.id,
      active: trackable.active,
      deviceId: trackable.deviceId
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
