"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".no-results[_ngcontent-%COMP%]{margin-top:0;width:100%;text-align:center;overflow-y:auto}.no-results.bordered[_ngcontent-%COMP%]{background:#fcfcfd;border:1px solid #e6e9ec;border-top:0;border-radius:0 0 4px 4px}.no-results.mat-table-no-results[_ngcontent-%COMP%]{position:absolute;display:flex;flex-direction:row;align-items:center;top:0;margin-top:56px;padding-top:20px;padding-bottom:20px;background-color:rgba(252,252,252,.5);width:100%;height:100%}.no-results.mat-table-no-results.has-header[_ngcontent-%COMP%]{margin-top:calc(56px + 56px)}.no-results[_ngcontent-%COMP%]   .inner-container[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:column;align-items:center}.no-results[_ngcontent-%COMP%]   .inner-container[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center}.no-results[_ngcontent-%COMP%]   .inner-container[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{position:relative}.no-results[_ngcontent-%COMP%]   .inner-container[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{margin-right:0}.no-results[_ngcontent-%COMP%]   .inner-container[_ngcontent-%COMP%]   .sub-title[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#7e8fa4}.no-results.mobile[_ngcontent-%COMP%]{display:flex;align-items:center}.no-results.mobile[_ngcontent-%COMP%]   .inner-container[_ngcontent-%COMP%]{flex-direction:row;justify-content:center}"];
exports.styles = styles;
