<form #filterTrips="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Driver Job Reports</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group company-name" fxFlex="100">
          <label translate>Company</label>
          <ruckit-dropdown #companyDropdown
            [config]="companyConfig"
            [selectedOption]="model.company && model.company.id"
            (onSelect)="onSelect('company', $event)">
          </ruckit-dropdown>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>
