import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { remove, clone } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { TruckService } from '../trucks/truck.service';
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { Selection, TableData } from '../shared/fancy-table/table.types';
import { Location } from '../locations/location';
import { LocationService } from '../locations/location.service';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { Driver } from '../drivers/driver';
import { DriverAssignmentsService } from '../assignments/driver-assignments.service';
import { DriverAssignment } from '../assignments/driver-assignment';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';
import { AssignmentService } from '../assignments/assignment.service';

@Component({
  selector: 'plants',
  templateUrl: './plants.component.html',
  styleUrls: ['./plants.component.scss']
})

export class PlantsComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'truck-carrier', title: this.translationService.instant('Truck / Carrier'), sortable: true, sortBy: 'truck__name' },
    { key: 'eta-customer', title: this.translationService.instant('ETA / Customer'), sortable: true, sortBy: 'customer__name' },
    { key: 'status', title: this.translationService.instant('Status'), sortable: true, sortBy: 'status' }
  ];
  @Input() displayedColumns = [
    'select', 'truck-carrier', 'eta-customer', 'status'
  ];
  @Input() availableFilters = [
    new FilterOption({
      key: 'truck', title: this.translationService.instant('Truck'), filterType: 'select',
      service: TruckService, slug: 'trucks'
    }),
  ];
  @Input() appliedFilters = [];
  @Input() search = '';

  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  errors = [];
  count = 0;
  loading = true;
  driverAssignmentsTableConfig = {
    service: DriverAssignmentsService,
    preferenceKey: 'PlantsComponent-DriverAssignmentsService',
    query: {},
    collectionTitle: this.translationService.instant('Assignments'),
    noResultsText: this.translationService.instant('an assignment'),
    newRecordModal: () => { },
    sortBy: '',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Export'), action: 'export', link: false, external: false }
    ]
  };
  driverAssignmentsTableConfigReady = false;
  selectedLocationId: string;
  activeRange: string;
  locationDropdownConfig = {
    service: LocationService,
    selectText: this.translationService.instant('Select Location'),
    loadingText: this.translationService.instant('Loading Locations...'),
    noResultsText: this.translationService.instant('No Locations'),
    groupProperty: 'locationTypeName',
    group: true
  };
  multipleActionDropdownOptions = [
    { name: 'Export', button: true }
  ];
  allSelected = false;
  selectedAssignments: DriverAssignment[] = [];
  excludedAssignments: DriverAssignment[] = [];

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('driverAssignmentsTable', { static: false }) driverAssignmentsTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private assignmentService: AssignmentService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    combinedParams.subscribe(result => {
      this.selectedLocationId = result.qparams['location'];
      let startDate = new Date(); // temp
      startDate.setDate(startDate.getDate() - 5);
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      this.activeRange = startDate.toISOString() + ',' + endDate.toISOString();
      this.setDriverAssignmentsQuery();
    });
  }

  onSelectLocation(location: Location): void {
    this.selectedLocationId = location.id;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        location: this.selectedLocationId
      },
      queryParamsHandling: 'merge'
    });
    this.driverAssignmentsTable.getRecords();
  }

  removeFilter(filter): void {
    remove(this.appliedFilters, filter);
    this.driverAssignmentsTable.getRecords();
  }

  zoomTo(content: string): void {
    //
  }

  multiselectDisabled(): boolean {
    return !this.count || this.count === 0 ||
      (!this.allSelected && !this.selectedAssignments.length) ||
      this.count === this.excludedAssignments.length;
  }

  setSelectedAction(option) {
    switch (option.action) {
      case 'export':
        this.createExport(this.selectedAssignments, this.excludedAssignments);
        break;
    }
  }

  createExport(selectedAssignments: DriverAssignment[], excludedAssignments: DriverAssignment[]): void {
    let scope = {
      assignments: selectedAssignments,
      exclude_assignments: excludedAssignments,
    };

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    let filters = {
      search: this.search,
      'jobevent__shift1_start__gte': startDate && startDate.toISOString(),
      'jobevent__shift1_start__lte': endDate && endDate.toISOString(),
      'exclude_assignments': excludedAssignments,
      'assignments': selectedAssignments
    };

    this.assignmentService.export(scope, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          scope: scope,
          params: params,
          service: this.assignmentService,
          buttonText: this.translationService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }
  /**
   * @param  {} driver
   * @returns void
   * This function is triggered when driver icon is clicked on the map,
   * the driver list will be scrolled to the clicked driver.
   */
  scrollToSelectedDriver(driver: Driver): void {
    // let drivers = this.rows;
    // if (_find(drivers, { id: driver.id })) {
    //   this.driverList.nativeElement.scrollBy(0, this.driverList.nativeElement.scrollHeight);
    // }
  }

  onDataLoaded(tableData: TableData): void {
    this.count = tableData.count;
  }

  onSelectionChanged(selection: Selection): void {
    this.allSelected = selection.allSelected;
    if (this.allSelected) {
      this.selectedAssignments = [];
      this.excludedAssignments = selection.exclusion.selected;
    } else {
      this.selectedAssignments = selection.selection.selected;
      this.excludedAssignments = [];
    }
  }

  setDriverAssignmentsQuery(): void {
    this.driverAssignmentsTableConfig.query = {
      ...this.driverAssignmentsTableConfig.query,
      active_range: this.activeRange,
      location: this.selectedLocationId
    };
    this.driverAssignmentsTableConfigReady = true;
  }
}
