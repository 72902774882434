import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { clone, get, find as _find } from 'lodash';

import { PurchaseOrderSerializer } from './purchase-order.serializer';
import { PurchaseOrderService } from './purchase-order.service';
import { LocationService } from '../locations/location.service';
import { ProductService } from '../products/product.service';
import { PriceListService } from '../price-lists/price-list.service';
import { OrganizationService } from '../organizations/organization.service';
import { AuthenticationService } from '../shared/authentication.service';
import { NewLocationDialogComponent } from '../locations/new-location-dialog.component';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss'],
  providers: [
    PurchaseOrderService, LocationService, OrganizationService, ProductService,
    PriceListService, AuthenticationService, MatDialog, FormBuilder
  ]
})

export class PurchaseOrderComponent implements OnInit, OnDestroy {
  purchaseOrder = new PurchaseOrderSerializer().fromJson({});
  loading = false;
  errors = [];
  defaultLocation;
  selectedLocation;
  addLocation = {
    name: 'Add a New Address',
    id: 'new-location',
    button: true
  };
  locationsReq;
  locations = [this.addLocation];
  locationsDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  searchChanged: Subject<string[]> = new Subject<string[]>();
  @ViewChild('locationDropdown', { static: false }) locationDropdown;
  customersReq;
  customers = [];
  customersDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  selectedCustomer;
  @ViewChild('customerDropdown', { static: false }) customerDropdown;
  productsReq;
  products = [];
  productsDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  selectedProduct;
  @ViewChild('productDropdown', { static: false }) productDropdown;
  priceListsReq;
  priceLists = [];
  priceListsDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  selectedPriceList;
  @ViewChild('priceListDropdown', { static: false }) priceListDropdown;

  constructor(
    private route: ActivatedRoute,
    private purchaseOrderService: PurchaseOrderService,
    private priceListService: PriceListService,
    private locationService: LocationService,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.getPurchaseOrder(params['id']);
    });

    this.getLocations();
    this.locationService.getLocationByIP().subscribe(res => {
      this.defaultLocation = res;
    });
    this.getCustomers();
    this.getProducts();
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(data => {
      this.dropdownSearch(data[0], data[1]);
    });
  }

  ngOnDestroy() { }

  getPurchaseOrder(purchaseOrderId) {
    if (purchaseOrderId) {
      this.purchaseOrderService.get(purchaseOrderId).subscribe((res) => {
        this.purchaseOrder = res;
        if (this.purchaseOrder.customerOrganization) {
          this.selectedCustomer = this.purchaseOrder.customerOrganization;
          let customer = _find(this.customers, { id: this.selectedCustomer.Id });
          if (!customer) { this.customers.unshift(this.selectedCustomer); }
        }
        if (this.purchaseOrder.product) {
          this.selectedProduct = this.purchaseOrder.product;
          let product = _find(this.products, { id: this.selectedProduct.Id });
          if (!product) { this.products.unshift(this.selectedProduct); }
          if (this.purchaseOrder.customPrice) {
            this.selectedPriceList = this.purchaseOrder.customPrice.priceList;
          }
          this.getPriceLists();
        }
        if (this.purchaseOrder.deliveryLocation) {
          this.selectedLocation = this.purchaseOrder.deliveryLocation;
          let location = _find(this.locations, { id: this.selectedLocation.Id });
          if (!location) {
            if (this.selectedLocation.name) {
              this.selectedLocation.name = this.selectedLocation.name + ' - ' + this.selectedLocation.street;
              this.locations.push(this.selectedLocation);
            } else {
              this.selectedLocation = null;
            }
          }
        }
      }, err => {
        this.errors = err;
      });
    }
  }

  getLocations(query = {}, append = false) {
    if (this.locationsReq) { this.locationsReq.unsubscribe(); }

    this.locationsReq = this.locationService.list({
      archived: 'False',
      ...query
    }).subscribe(res => {
      res = res.map((loc) => {
        loc['name'] = loc.name + ' - ' + loc.street;
        return loc;
      });
      if (append) {
        this.locations = this.locations.concat(res);
      } else {
        this.locations = [this.addLocation].concat(res);
      }
    });
  }

  changeLocation(location) {
    if (location['id'] === 'new-location') {
      location.selected = false;
      const dialog = this.dialog.open(NewLocationDialogComponent, {
        width: '100%',
        height: '100%',
        disableClose: true
      });
      dialog.componentInstance.callback = (newLocation) => {
        newLocation.name = newLocation.street ? (newLocation.name + ' - ' + newLocation.street) : newLocation.name;

        this.locations.forEach((_location) => {
          _location['selected'] = false;
        });
        newLocation.selected = true;
        this.locations.push(newLocation);
        let _newLocation = clone(newLocation);
        _newLocation.selected = false;

        this.purchaseOrder.deliveryLocation = newLocation;
        this.selectedLocation = newLocation;
      };
    } else {
      this.purchaseOrder.deliveryLocation = location;
      this.selectedLocation = location;
    }
  }

  getCoord(index) {
    let defaultCoords = [
      get(this.defaultLocation, 'longitude', 30.2178214),
      get(this.defaultLocation, 'latitude', -97.6023238)
    ];
    return get(this.purchaseOrder, 'deliveryLocation.location.coordinates[' + index + ']', defaultCoords[index]);
  }

  delaySearch(term: string, type: string) {
    this.searchChanged.next([term, type]);
  }

  dropdownSearch(term: string, type) {
    switch (type) {
      case 'customer':
        this.getCustomers({ search: term });
        break;
      case 'product':
        this.getProducts({ search: term });
        break;
      case 'location':
        this.getLocations({ search: term, archived: 'False' });
        break;
      case 'priceList':
        this.getPriceLists({ search: term });
        break;
    }
  }

  dropdownNextPage(e, type) {
    let config;
    let service;
    let options;

    switch (type) {
      case 'customer':
        config = this.customersDropdownConfig;
        service = this.organizationService;
        options = this.customers;
        break;
      case 'product':
        config = this.productsDropdownConfig;
        service = this.productService;
        options = this.products;
        break;
      case 'location':
        config = this.locationsDropdownConfig;
        service = this.locationService;
        options = this.locations;
        break;
      case 'priceList':
        config = this.priceListsDropdownConfig;
        service = this.priceListService;
        options = this.priceLists;
    }

    if (!config.loadingOptions) {
      let o;
      if (type === 'customer' || type === 'product' || type === 'location') {
        o = service && service.listNext();
      } else {
        o = service && service.getNext();
      }
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'customer':
              this.customers = this.customers.concat(results);
              break;
            case 'product':
              this.products = this.products.concat(results);
              break;
            case 'location':
              this.locations = this.locations.concat(results);
              break;
            case 'priceList':
              this.priceLists = this.priceLists.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  getCustomers(query = {}, append = false) {
    if (this.customersReq) { this.customersReq.unsubscribe(); }

    this.customersReq = this.organizationService.get({
      ...query
    }).subscribe(customers => {
      if (append) {
        this.customers = this.customers.concat(customers);
      } else {
        this.customers = customers;
      }
    });
  }

  customerSelected(customer) {
    this.purchaseOrder.customerOrganization = customer;
    this.selectedCustomer = customer;
  }

  getProducts(query = {}, append = false) {
    if (this.productsReq) { this.productsReq.unsubscribe(); }

    this.productService.list({
      ...query
    }).subscribe(products => {
      if (append) {
        this.products = this.products.concat(products);
      } else {
        this.products = products;
      }
    });
  }

  productSelected(product) {
    this.purchaseOrder.product = product;
    this.selectedPriceList = null;
    this.purchaseOrder.customPrice = null;
    this.selectedProduct = product;
    this.purchaseOrder.unitOfMeasurement = product.unitOfMeasurement;
    this.getPriceLists();
  }

  getPriceLists(query = {}, append = false) {
    if (this.priceListsReq) { this.priceListsReq.unsubscribe(); }

    this.priceListsReq = this.priceListService.list({
      prices__product: this.selectedProduct && this.selectedProduct.id,
      ...query
    }).subscribe(priceLists => {
      this.priceLists = [{ id: null, name: '-' }].concat(priceLists);
      if (append) {
        this.priceLists = this.priceLists.concat(priceLists);
      } else {
        this.priceLists = [{ id: null, name: '-' }].concat(priceLists);
      }
    });
  }

  priceListSelected(priceList) {
    this.selectedPriceList = priceList;
    let price = _find(priceList.prices, { product: {id: this.selectedProduct.id } });
    if (price) {
      this.purchaseOrder.customPrice = price;
    } else {
      this.purchaseOrder.customPrice = null;
    }
  }

  submit() {
    this.loading = true;
    this.purchaseOrder.sellerOrganization = this.authenticationService.getOrganization();
    this.purchaseOrderService.save(this.purchaseOrder).subscribe((res) => {
      this.loading = false;
      this.router.navigate(['/purchase-orders']);
    }, (err) => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }
}
