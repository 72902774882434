"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var api_service_1 = require("../shared/api.service");
var checkin_1 = require("./checkin");
var environment_1 = require("../../environments/environment");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CheckinService = /** @class */ (function () {
    function CheckinService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.count = 0;
    }
    CheckinService.prototype.getCheckin = function (checkinId) {
        var checkinUrl = this.baseUrl + 'checkins/' + checkinId + '/';
        return this.http.get(checkinUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    CheckinService.prototype.getCheckins = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var checkinsUrl = this.baseUrl + 'checkins/';
        return this.http.get(checkinsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    CheckinService.prototype.save = function (model) {
        var _this = this;
        var uri = this.baseUrl + 'checkins/';
        var _model = decamelizeKeysDeep(lodash_1.clone(model));
        delete _model.signature_image;
        delete _model.ticket_image;
        delete _model.load_image;
        if (!_model.id) {
            return this.http.post(uri, _model, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return this.http.put(uri + _model.id + '/', _model, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
    };
    CheckinService.prototype.requestRetake = function (checkinId) {
        var checkinUrl = this.baseUrl + 'checkins/' + checkinId + '/retake/';
        return this.http.get(checkinUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    CheckinService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (checkin) {
                return new checkin_1.Checkin(checkin);
            });
        }
        else if (resObj) {
            return new checkin_1.Checkin(resObj);
        }
    };
    CheckinService.prototype.getNext = function () {
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() });
        }
        else {
            return null;
        }
    };
    CheckinService.prototype.uploadImage = function (method, checkinId, propertyName, files) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            for (var i = 0; i < files.length; i++) {
                formData.append(propertyName, files[i], files[i].name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = function (event) {
                _this.progress = Math.round(event.loaded / event.total * 100);
            };
            var url = _this.baseUrl + 'checkins/' + checkinId + '/';
            xhr.open(method, url, true);
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    return CheckinService;
}());
exports.CheckinService = CheckinService;
