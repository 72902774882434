<div class="timeline-container" id="timeline" [ngClass]="{ 'external-scroll': !timelineConfig.scroll }">
  <div class="header" *ngIf="times" [ngStyle]="{'height': timelineConfig.headerHeight + 'px'}">
    <div class="header-label" *ngFor="let time of times">
      {{ time }}
    </div>
  </div>
  <div class="body" *ngIf="times">
    <div *ngFor="let row of rows; let i = index" id="row-{{i}}" class="row" (click)="selectRow(row)"
         [ngStyle]="{'height': timelineConfig.rowHeight + 'px'}" [ngClass]="{ 'selected': i === selectedRowIndex }">
      <div class="interval-container">
        <span class="intervals" *ngFor="let time of times"></span>
      </div>
      <div class="boundary-container" [ngStyle]="{'height': timelineConfig.rowHeight + 'px', 'margin-top': '-' + timelineConfig.rowHeight + 'px'}">
        <div class="shifts-and-pavers" *ngIf="row.shifts || row.pavers">
          <ng-container *ngFor="let shift of row.shifts">
            <span class="shift-line start"
                  [ngStyle]="barStyles(shift.startDatetime)"
                  [ngClass]="{ 'scheduled': shift.type === 'scheduled' }">
              <span class="label">{{ shift.type === 'scheduled' ? 'Scheduled Start' : 'Shift Start' | translate }}</span>
            </span>
            <span class="shift-line end" *ngIf="shift.endDatetime"
                  [ngStyle]="barStyles(shift.endDatetime)"
                  [ngClass]="{ 'scheduled': shift.type === 'scheduled' }">
              <span class="label">{{ 'Shift End' | translate }}</span>
            </span>
          </ng-container>
          <ng-container *ngFor="let paver of row.pavers">
            <span class="start paver-line"
                  matTooltip="{{ 'Paver Unloading Start' | translate }}: &#13; {{ paver.startDatetime | date:'short' }}"
                  matTooltipPosition="above"
                  matTooltipClass="ruckit-tooltip"
                  [ngStyle]="barStyles(paver.startDatetime, null, 20)">
              <span class="label"><i class="icomoon icon-paver"></i></span>
            </span>
            <span class="end paver-line"
                  matTooltip="{{ 'Paver Unloading End' | translate }}: &#13; {{ paver.endDatetime | date:'short' }}"
                  matTooltipPosition="above"
                  matTooltipClass="ruckit-tooltip"
                  [ngStyle]="barStyles(paver.endDatetime, null, 20)">
              <span class="label"><i class="icomoon icon-paver"></i></span>
            </span>
          </ng-container>
        </div>
        <div class="pay-periods" *ngIf="row.payLines">
          <div *ngFor="let payLine of row.payLines; let i = index"
               id="{{ row.referenceId + '-' + i }}" class="pay-period"
               [ngClass]="{'editing': payLineEditing}"
               [ngStyle]="barStyles(payLine.startDatetime, payLine.endDatetime)">
            <div class="edit-handle start">
              <span class="corner"></span>
              <span class="time">{{ payLine.startDatetime | date: 'h:mma' }}</span>
              <span class="corner"></span>
            </div>
            <div class="edit-duration">
              <span class="time">{{ payLine.startDatetime | getDurationString: payLine.endDatetime }}</span>
            </div>
            <div class="edit-handle end">
              <span class="corner"></span>
              <span class="time">{{ payLine.endDatetime | date: 'h:mma' }}</span>
              <span class="corner"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="bar-container" id="bars" [ngStyle]="{'height': timelineConfig.rowHeight + 'px', 'margin-top': '-' + timelineConfig.rowHeight + 'px'}">
        <ng-container *ngFor="let key of timelineConfig.visibleBars">
          <div class="bar-row" *ngIf="(row[key] && ['payLines', 'shifts'].indexOf(key) === -1) || key === 'truckStatuses'" [ngSwitch]="key">
            <ng-container *ngSwitchCase="'predictedTrips'">
              <div class="bar-row" *ngIf="row.predictedTrips && row.geofences">
                <div class="bar geofence {{ geofence.type.toLowerCase() }}"
                     *ngFor="let geofence of row.geofences.slice().reverse(); let i = index"
                     [ngStyle]="barStyles(geofence.range.startDatetime, geofence.range.endDatetime)"
                     matTooltip="{{ geofence.type | translate }} - {{ geofence.location && geofence.location.name }}
                                 {{ 'Duration' | translate }} - {{ geofence.range.startDatetime | getDurationString: geofence.range.endDatetime : 'minutes' }}"
                     matTooltipPosition="above" matTooltipClass="ruckit-tooltip"
                     (contextmenu)="openContextMenu($event, geofence)"
                >
                  <img class="status-icon" src="/assets/img/icons/{{ geofence.type === 'Loading' ? 'loading-timeline.svg' : 'unloading-timeline.svg' }}"/>
                  <span class="label"> {{ geofence.range.startDatetime | getDurationString: geofence.range.endDatetime : 'minutes' }}</span>
                </div>
                <ng-container *ngFor="let predictedTrip of row.predictedTrips">
                  <div class="bar predictedTrip" *ngIf="predictedTrip.unloadingArrivalTime && predictedTrip.unloadingCompleteTime"
                       [ngClass]="{'in-progress': !predictedTrip.endTimeTimestamp}"
                       [ngStyle]="barStyles(predictedTrip.startTimeTimestamp, predictedTrip.endTimeTimestamp)">
                  </div>
                </ng-container>
              </div>
            </ng-container>
            
            <ng-container *ngSwitchCase="'truckStatuses'">
              <ng-container *ngFor="let signalLoss of row.signalLosses">
                <div class="bar signalLosses"
                      *ngIf="signalLoss && signalLoss.startDatetime && signalLoss.endDatetime"
                      [ngStyle]="barStyles(signalLoss.startDatetime, signalLoss.endDatetime)"
                      matTooltip="{{ 'Signal Loss' | translate }}: {{ signalLoss.startDatetime | getDurationString: signalLoss.endDatetime : 'minutes' }}"
                      matTooltipPosition="above"
                      matTooltipClass="ruckit-tooltip">
                  <i class="icon-no-signal"></i>
                  <span class="label">{{ signalLoss.startDatetime | getDurationString: signalLoss.endDatetime }}</span>
                </div>
              </ng-container>

              <ng-container *ngFor="let waitTime of row.waitTimes">
                <div class="bar waitTimes"
                      *ngIf="waitTime && waitTime.startDatetime && waitTime.endDatetime"
                      [ngStyle]="barStyles(waitTime.startDatetime, waitTime.endDatetime)"
                      matTooltip="{{ 'Stopped Time' | translate }}: {{ waitTime.startDatetime | getDurationString: waitTime.endDatetime : 'minutes' }}"
                      matTooltipPosition="above"
                      matTooltipClass="ruckit-tooltip">
                  <i class="icon-stop-hand"></i>
                  <span class="label">{{ waitTime.startDatetime | getDurationString: waitTime.endDatetime : 'minutes' }}</span>
                </div>
              </ng-container>
            </ng-container>
            
            <ng-container *ngSwitchDefault>
              <ng-container *ngFor="let range of row[key].slice().reverse(); let i = index">
                <div class="bar {{ key }} {{ key === 'trips' && range.origin }}" *ngIf="range" (click)="switchBarDisplay()"
                    [ngClass]="{'in-progress': !range.endTimeTimestamp}"
                    [ngStyle]="barStyles(range.startTimeTimestamp, range.endTimeTimestamp)"
                    [ngSwitch]="barDisplay"
                    matTooltip="{{ key | titlecase | translate }} {{ i + 1 }}
                                {{ 'Job' | translate }} - {{ range.jobEvent && range.jobEvent.job.name }} | {{ 'Duration' | translate }} - {{ range.startTimeTimestamp | getDurationString: range.endTimeTimestamp }}
                                {{ 'Start Time' | translate }} - {{ range.startTimeTimestamp | date: 'h:mma' }} | {{ 'End Time' | translate }} - {{ range.endTimeTimestamp | date: 'h:mma' }}
                                {{ key === 'trips' && range.ticketNumber && (('Ticket Number' | translate) + ' - ' + range.ticketNumber) }}"
                    matTooltipPosition="above"
                    matTooltipClass="ruckit-tooltip"
                    (contextmenu)="openContextMenu($event, range, key)">
                  <ng-container *ngSwitchCase="'times'">
                    <span class="label start">{{ range.startTimeTimestamp | date: 'h:mma' }}</span>
                    <span class="label end">{{ range.endTimeTimestamp | date: 'h:mma' }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'duration'">
                    <span class="label start">{{ range.startTimeTimestamp | getDurationString: range.endTimeTimestamp }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'type'">
                    <span class="label start">{{ key | titlecase | translate }} {{ i + 1 }}</span>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="overlay-container" [ngStyle]="{'height': timelineConfig.rowHeight * rows.length + 'px', 'margin-top': '-' + timelineConfig.rowHeight * rows.length + 'px'}">
    <span class="current-line"
          *ngIf="timelineConfig.currentTime && timeWithinRange(currentTime)"
          [ngStyle]="barStyles(currentTime)">
      <p>{{ currentTime | date: 'h:mma' }}</p>
    </span>
    <span class="selected-line"
          *ngIf="timelineConfig.selectedTime"
          [ngStyle]="barStyles(selectedTime)">
      <p>{{ selectedTime | date: 'h:mma' }}</p>
    </span>
  </div>
</div>

<!-- edit trip context menu -->
<ng-container>
  <ng-template #title>
    <div class="context-menu-title">
      <i class="icomoon icon-clock"></i>
      <div class="title-text">
        <span class="trip-text" translate>{{ keyContextMenuSelected === 'trips' ? 'Trip' : 'Punch Card' }} Duration</span>
        <span class="time-text">{{ tripContextMenuSelected }}</span>
      </div>
    </div>
  </ng-template>
  <app-context-menu 
    #tripContextMenu 
    [menuOptions]="tripContextMenuItems"
    [titleRef]="title"
    (menuSelection)="onEditTripClick()"
  >
  </app-context-menu>
  <app-context-menu 
    #punchcardContextMenu 
    [menuOptions]="punchcardContextMenuItems"
    [titleRef]="title"
    (menuSelection)="onEditPunchcardClick()"
  >
  </app-context-menu>
</ng-container>