"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var TripStatsAverages = /** @class */ (function () {
    function TripStatsAverages(averages) {
        this.enrouteLoading = this.prettyTime(averages.enrouteLoading);
        this.loading = this.prettyTime(averages.loading);
        this.enrouteUnloading = this.prettyTime(averages.enrouteUnloading);
        this.unloading = this.prettyTime(averages.unloading);
    }
    TripStatsAverages.prototype.prettyTime = function (value) {
        if (value && value !== 0) {
            return moment.duration(parseInt(value, 10) | 0, 'seconds').format('m[ mins]');
        }
        else {
            return '--';
        }
    };
    return TripStatsAverages;
}());
var TripStatsLastValues = /** @class */ (function () {
    function TripStatsLastValues(lastValues) {
        this.enrouteLoading = this.prettyTime(lastValues.enrouteLoading);
        this.loading = this.prettyTime(lastValues.loading);
        this.enrouteUnloading = this.prettyTime(lastValues.enrouteUnloading);
        this.unloading = this.prettyTime(lastValues.unloading);
        this.status = lastValues.status;
    }
    TripStatsLastValues.prototype.prettyTime = function (value) {
        if (value && value !== 0) {
            return moment.duration(parseInt(value, 10) | 0, 'seconds').format('m[ mins]');
        }
        else {
            return '--';
        }
    };
    return TripStatsLastValues;
}());
var TripStats = /** @class */ (function () {
    function TripStats(statusInfo) {
        statusInfo = camelcaseKeysDeep(statusInfo);
        this.count = statusInfo.count;
        this.averages = new TripStatsAverages(statusInfo.averages);
        this.lastValues = new TripStatsLastValues(statusInfo.lastValues);
    }
    return TripStats;
}());
exports.TripStats = TripStats;
