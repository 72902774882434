<div class="mobile-dispatch">
  <div class="header-container">
    <my-toolbar *ngIf="jobEvent" class="mobile" color="#154b80"
                [title]="editAssignment ? selectedAssignment.driver.name : jobEvent && jobEvent.jobDisplayName">
      <div *ngIf="jobEvent && !loading" class="mobile-toolbar-action left-actions" left-first>
        <button *ngIf="editAssignment" (click)="editAssignment = false"><span class="icon-back"></span></button>
        <button *ngIf="!editAssignment" (click)="back()"><span class="icon-back"></span></button>
      </div>
      <div *ngIf="!editAssignment && !loading" class="mobile-toolbar-action right-actions" right-first>
        <button *ngIf="!editMode" (click)="editMode = true" translate>Edit</button>
      </div>
    </my-toolbar>

    <div class="dispatch-container" *ngIf="jobEvent">
      <header class="dispatch-header" *ngIf="!editAssignment">
        <div class="details">
          <div class="top">
            <div class="job-customer" *ngIf="jobEvent.job.project && jobEvent.job.project.customerOrganization">
              {{ jobEvent.job.project.customerOrganization.name }}</div>
          </div>
          <div class="left">
            <div class="shift-date">{{ jobEvent['shift' + (selectedShift + 1) + 'StartTimestamp'] | date: 'MMMM dd' }}</div>
            <div class="shift-time">Shift {{ selectedShift + 1 }}:
              {{ jobEvent['shift' + (selectedShift + 1) + 'StartTimestamp'] | date: 'h:mm a' }} -
              {{ jobEvent['shift' + (selectedShift + 1) + 'EndTimestamp'] | date: 'h:mm a' }}</div>
          </div>
          <div class="right">
            <div class="shift-slots">
              {{ dispatchedTotal }}
              <ng-container *ngIf="jobEvent.numTrucks"> / {{ jobEvent.numTrucks }}</ng-container>
            </div>
            <div class="shift-trucktypes">
              <ng-container *ngIf="jobEvent.allowAnyTruckType">
                <span translate>Any Truck Type</span>
              </ng-container>
              <ng-container *ngIf="!jobEvent.allowAnyTruckType">
                <span *ngFor="let truckType of jobEvent.truckTypes; let i = index; last as isLast">
                  <ng-container *ngIf="i <= 2">
                    <ng-container *ngIf="i !== 2">{{ truckType.name }}<span *ngIf="!isLast">, </span></ng-container>
                    <ng-container *ngIf="i === 2 && jobEvent.truckTypes.length > 2">+{{ jobEvent.truckTypes.length - 3 }}</ng-container>
                  </ng-container>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>

  <div class="content-container">
    <ng-container [ngSwitch]="activePanel">
      <div class="mobile-dispatch-assignments mobile-list" *ngIf="jobEvent && !loading" (scroll)="scrollEvent = $event">
        <mobile-dispatch-shifts *ngSwitchCase="panel.shift"
                                [jobEvent]="jobEvent"
                                (selectShift)="onShiftSelected($event)"></mobile-dispatch-shifts>
        <mobile-dispatch-assignments *ngSwitchCase="panel.assign"
                                     [jobEvent]="jobEvent"
                                     [shift]="shift"
                                     [editMode]="editMode"
                                     (selectSlot)="onSlotSelected($event)"
                                     (selectAssignment)="onAssignmentSelected($event)"
                                     (selectRemoveSlot)="onRemoveSlotSelected($event)"
                                     (dispatchAssignments)="onDispatchAssignments($event)"></mobile-dispatch-assignments>
        <mobile-dispatch-carriers *ngSwitchCase="panel.carrier"
                                  [jobEvent]="jobEvent"
                                  [scrollEvent]="scrollEvent"
                                  (selectCarrier)="onCarrierSelected($event)"></mobile-dispatch-carriers>
        <mobile-dispatch-drivers *ngSwitchCase="panel.driver"
                                 [jobEvent]="jobEvent"
                                 [carrier]="selectedCarrier"
                                 [scrollEvent]="scrollEvent"
                                 (selectDriver)="onDriverSelected($event)"
                                 (selectEditTruck)="onEditTruckSelected($event)"></mobile-dispatch-drivers>
        <ng-container *ngSwitchCase="panel.truck">
          <mobile-dispatch-trucks *ngIf="selectedDriver"
                                  [jobEvent]="jobEvent"
                                  [selectedCarrier]="selectedCarrier"
                                  [selectedDriver]="selectedDriver"
                                  [scrollEvent]="scrollEvent"
                                  (selectTruck)="onTruckSelected($event)"></mobile-dispatch-trucks>
        </ng-container>
      </div>
    </ng-container>

    <my-loader *ngIf="loading" class="mobile-loader full-height"></my-loader>
    <no-results class="mobile" itemName="{{ 'Results' | translate }}"
                  [styles]="{height: 'calc(100% - 130px)'}"
                  [results]="jobEvent ? 1 : 0"
                  customClasses="mobile"
                  customText="{{ 'We weren\'t able to find any available results.' | translate }}"
                  [hasAddText]="false" [loading]="loading" [hasAction]="false"></no-results>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>
</div>
