"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shift_1 = require("./shift");
var slot_serializer_1 = require("./slot.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ShiftSerializer = /** @class */ (function () {
    function ShiftSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Shift
    */
    ShiftSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var shift = new shift_1.Shift();
        if (!json) {
            return shift;
        }
        shift.id = json.id;
        if (json.slots) {
            shift.slots = json.slots.map(function (slot) { return new slot_serializer_1.SlotSerializer().fromJson(slot); });
        }
        return shift;
    };
    /**
    * @param  {Shift} shift
    * @returns any
    */
    ShiftSerializer.prototype.toJson = function (shift) {
        var json = {
            id: shift.id,
            slots: shift.slots
        };
        return decamelizeKeysDeep(json);
    };
    return ShiftSerializer;
}());
exports.ShiftSerializer = ShiftSerializer;
