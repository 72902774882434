import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';


import { MarketplaceService } from '../marketplace/marketplace.service';
import { AuthenticationService } from '../shared';
import { PreferenceService } from '../preferences/preference.service';
import { Preference } from '../preferences/preference';

@Component({
  selector: 'ruckit-marketplace-job-overview',
  templateUrl: './job-overview.component.html',
  styleUrls: ['./job-overview.component.scss'],
  providers: [MarketplaceService]
})
export class JobOverviewComponent implements OnInit {
  loading = true;
  errors = [];
  marketplaceId;
  marketplace;
  marketplaceReq;
  displayNotes = false;
  userPreferences$: BehaviorSubject<Preference>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private marketplaceService: MarketplaceService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.errors = [];
    this.disableSidebar();
    this.route.params
      .subscribe((params: Params) => this.getMarketplace(params['id']));
    this.userPreferences$ = this.preferenceService.userPreference$;
    this.getPreferences();
  }

  disableSidebar() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.sidebar = false;

    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getPreferences() {
    const currentUser = this.authenticationService.user();
    this.preferenceService.getUserPreferenceObservable(currentUser && currentUser.id);
  }

  getMarketplace(id) {
    if (this.marketplaceReq) {
      this.marketplaceReq.unsubscribe();
    }

    this.marketplaceReq = this.marketplaceService.getMarketplace(id)
      .subscribe(
        marketplace => this.marketplace = marketplace,
        err => this.errors = err,
        () => {
          this.loading = false;
        }
      );
  }

  acceptJob() {
    this.router.navigate(['/marketplace/' + this.marketplace.id + '/accept']);
  }
}
