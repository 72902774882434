<div class="all-collaborators-container">
  <div class="actions-wrapper">
    <fancy-table #allCollaboratorsTable
                [(availableColumns)]="availableColumns"
                [(displayedColumns)]="displayedColumns"
                [config]="allCollaboratorsTableConfig">

      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'name'">
            <div class="data">
              {{ row.collaboratorName ? row.collaboratorName : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'avg-trip-time'">
            <div class="data">
              {{ row.avgTripTime ? ((row.avgTripTime/60) |  number : '1.2-2') + ' mins' : '-'}}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'dispatched-drivers-count'">
            <div class="data">
              {{ row.dispatchedDriversCount ? (row.dispatchedDriversCount | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'confirmed-trucks'">
            <div class="data">
              {{ row.confirmedTrucks ? (row.confirmedTrucks | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'num-trucks'">
            <div class="data">
              {{ row.numTrucks ? (row.numTrucks | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'assigned-trucks'">
            <div class="data assigned-trucks-cell">
              <ng-container *ngIf="assignedTrucks | filterBy: 'organizationId':row.collaboratorId as orgAssignedTrucks">
                <span *ngIf="orgAssignedTrucks.length === 0">
                  0
                </span>
                <span *ngIf="orgAssignedTrucks.length === 1">
                  {{ orgAssignedTrucks[0].name }}
                </span>
                <span 
                  *ngIf="orgAssignedTrucks.length > 1" 
                  matTooltipClass="ruckit-tooltip"
                  [matTooltip]="orgAssignedTrucks | joinByProperty: 'name'"
                  matTooltipPosition="below"
                >
                  {{ orgAssignedTrucks[0].name }}, ...
                </span>
              </ng-container>
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'dispatched-estimated-number-of-loads'">
            <div class="data">
              {{ row.dispatchedEstimatedNumberOfLoads ? (row.dispatchedEstimatedNumberOfLoads | number) : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'dispatched-estimated-number-of-tons'">
            <div class="data">
              {{ row.dispatchedEstimatedNumberOfTons ? (row.dispatchedEstimatedNumberOfTons | number) : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'invoice-rate'">
            <div class="data">
              {{ row.invoiceRate ? (row.invoiceRate | currency:'USD':'symbol':'1.2-4') : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'invoice-type'">
            <div class="data">
              {{ row.invoiceType ? row.invoiceType : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'invoice-weight-unit'">
            <div class="data">
              {{ row.invoiceWeightUnit ? row.invoiceWeightUnit  : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'total-loads'">
            <div class="data">
              {{ (row.totalLoads ? row.totalLoads : 0) | number }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'total-tons'">
            <div class="data">
              {{ (row.totalTons ? row.totalTons : 0) | number }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'weight-in-transit'">
            <div class="data">
              {{ row.weightInTransit ? (row.weightInTransit | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'ticketed-weight'">
            <div class="data">
              {{ row.ticketedWeight ? (row.ticketedWeight | number) : 0 }}
            </div>
          </div>
        </ng-container>
      </ng-template>
    </fancy-table>
  </div>
</div>
