import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { Trip } from '../../trips/trip';
import { PunchCard } from '../../punch-cards/punch-card';

@Pipe({
  name: 'completeHour', pure: true
})
export class CompleteHourPipe implements PipeTransform {
  transform(record: Trip|PunchCard, hour: string): boolean {
    let complete = false;
    const currentTime = moment().toDate();
    if (!record || !record.startTimeTimestamp) { return complete; }
    const startTime = moment(record.startTimeTimestamp).toDate();

    let _date = moment(startTime).format('YYYY-MM-DD');
    let timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
    timeAtHour.add(3599, 'seconds');

    if (startTime < timeAtHour.toDate() && !record.endTimeTimestamp && timeAtHour.toDate() < currentTime) {
      complete = true;
    } else if (startTime < timeAtHour.toDate() && timeAtHour.toDate() < moment(record.endTimeTimestamp).toDate()) {
      complete = true;
    }

    return complete;
  }
}
