"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var shared_1 = require("../../shared");
var dispatch_service_1 = require("../dispatch.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var MobileDispatchJobsComponent = /** @class */ (function () {
    function MobileDispatchJobsComponent(jobEventService, dispatchService) {
        this.jobEventService = jobEventService;
        this.dispatchService = dispatchService;
        this.getJobEvents = new core_1.EventEmitter();
    }
    MobileDispatchJobsComponent.prototype.dispatchAll = function () {
        var _this = this;
        var dispatchReqs = [];
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        if (this.selectedDates && this.selectedDates.length) {
            startDate = this.selectedDates[0];
        }
        else {
            this.selectedDates = [startDate];
        }
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        this.jobEventService.listAll(10, {
            shift1_start__gte: startDate && startDate.toISOString(),
            shift1_start__lte: endDate && endDate.toISOString(),
            ordering: 'project__name,name',
            archived: 'False',
            cancelled: 'False',
            exclude_pending: 'True',
            dispatched: 'False'
        }).pipe(operators_1.first()).subscribe(function (jobEvents) {
            jobEvents.forEach(function (j) {
                if (j.lastDispatchedTime) {
                    dispatchReqs.push(_this.dispatchService.save({
                        jobEvent: j.id,
                        notifyNew: true
                    }));
                }
            });
            rxjs_1.forkJoin(dispatchReqs).subscribe(function () {
                _this.getJobEvents.emit(true);
            }, function (err) {
                _this.errors = shared_1.parseErrors(err);
            });
        });
    };
    return MobileDispatchJobsComponent;
}());
exports.MobileDispatchJobsComponent = MobileDispatchJobsComponent;
