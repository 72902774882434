"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var index_1 = require("../../shared/index");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var assignment_service_1 = require("../../assignments/assignment.service");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var http_1 = require("@angular/common/http");
var NeedsDispatchedComponent = /** @class */ (function () {
    function NeedsDispatchedComponent(collaboratorService, authenticationService, assignmentService, dialog) {
        this.collaboratorService = collaboratorService;
        this.authenticationService = authenticationService;
        this.assignmentService = assignmentService;
        this.dialog = dialog;
        this.collaborators = [];
        this.loading = true;
        this.errors = [];
        this.range = 'today';
        this.startDate = new Date();
        this.endDate = new Date();
        this.sortAsc = true;
    }
    NeedsDispatchedComponent.prototype.ngOnInit = function () {
        if (window.innerWidth > 900) {
            this.organization = this.authenticationService.getOrganization();
            this.startDate.setHours(0, 0, 0, 0);
            this.endDate.setHours(23, 59, 59, 999);
            this.getShares();
        }
    };
    NeedsDispatchedComponent.prototype.ngOnDestroy = function () {
        if (this.sharesReq && typeof this.sharesReq.unsubscribe === 'function') {
            this.sharesReq.unsubscribe();
        }
    };
    NeedsDispatchedComponent.prototype.onResize = function (event) {
        if (window.innerWidth <= 900) {
            this.ngOnInit();
        }
    };
    NeedsDispatchedComponent.prototype.setRange = function (range) {
        this.range = range;
        this.startDate = new Date();
        this.endDate = new Date();
        switch (this.range) {
            case 'today':
                this.startDate.setHours(0, 0, 0, 0);
                this.endDate.setHours(23, 59, 59, 999);
                break;
            case 'tomorrow':
                this.startDate.setDate(this.startDate.getDate() + 1);
                this.endDate.setDate(this.endDate.getDate() + 1);
                this.startDate.setHours(0, 0, 0, 0);
                this.endDate.setHours(23, 59, 59, 999);
                break;
        }
        this.getShares();
    };
    NeedsDispatchedComponent.prototype.exportAssignments = function () {
        var _this = this;
        var filters = {
            'jobevent__shift1_start__gte': this.startDate && this.startDate.toISOString(),
            'jobevent__shift1_start__lte': this.endDate && this.endDate.toISOString()
        };
        this.assignmentService.export({}, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    params: params,
                    service: _this.assignmentService,
                    buttonText: 'Try to Export Again'
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    NeedsDispatchedComponent.prototype.getShares = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.loading = true;
        this.collaborators = [];
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.sharesReq && typeof this.sharesReq.unsubscribe === 'function') {
            this.sharesReq.unsubscribe();
        }
        this.sharesReq = this.collaboratorService.getShares(__assign({ end__gte: this.startDate.toISOString(), end__lte: this.endDate.toISOString(), organization: this.organization && this.organization.id, dispatch_percent: 'True', ordering: order }, query)).subscribe(function (collaborators) {
            _this.collaborators = collaborators;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    NeedsDispatchedComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getShares({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    return NeedsDispatchedComponent;
}());
exports.NeedsDispatchedComponent = NeedsDispatchedComponent;
