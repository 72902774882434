<form #editPunchCard="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Punch Card</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content" *ngIf="punchCard">
    <div class="form-content">
      <div class="field-group job full-width">
        <label class="required" translate>Job</label>
        <dropdown [options]="jobDropdownOptions" (onSelect)="onSelect('job', $event)" [config]="jobDropdownConfig"
                  #jobDropdown title="&ndash;"
                  (onSearch)="dropdownSearch($event, 'job')" (nextPage)="dropdownNextPage($event, 'job')"></dropdown>
      </div>
    </div>

    <div class="form-content">
      <div class="field-group punch-card-date full-width">
        <label class="required" translate>Day</label>
        <input type="hidden" name="job_event_date" required [(ngModel)]="jobEventDate" />
        <date-range-picker [config]="jobEventDatePickerOptions"
          [disabled]="!job" [selectedDates]="[jobEventDate]"
          (dateChanged)="onJobEventDateChanged($event)"></date-range-picker>
      </div>
    </div>

    <notification *ngIf="jobEventError">{{jobEventError}}</notification>

    <div class="form-content">
      <div class="field-group driver full-width">
        <label class="required" translate>Driver</label>
        <dropdown [options]="driverDropdownOptions" (onSelect)="onSelect('driver', $event)" [config]="driverDropdownConfig"
                  #driverDropdown title="&ndash;"
                  (onSearch)="dropdownSearch($event, 'driver')" (nextPage)="dropdownNextPage($event, 'driver')"></dropdown>
      </div>
    </div>

    <div class="form-content">
      <div class="field-group truck full-width">
        <label class="required" translate>Truck</label>
        <dropdown [options]="truckDropdownOptions" (onSelect)="onSelect('truck', $event)" [config]="truckDropdownConfig"
                  #truckDropdown title="&ndash;"
                  (onSearch)="dropdownSearch($event, 'truck')" (nextPage)="dropdownNextPage($event, 'truck')"></dropdown>
      </div>
    </div>

    <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="start-date group" fxFlex="50">
        <label class="required" translate>Start Time</label>
        <date-range-picker [selectedDates]="[startDate]" (dateChanged)="onStartDateChanged($event)"></date-range-picker>
      </div>

      <div class="start-time group" fxFlex="50">
        <label>&nbsp;</label>
        <div class="timepicker-wrapper">
          <input 
          type="time" 
          name="start_time" 
          class="timepicker"
          (focusout)="onStartTimeChange($event)"
        />
        </div>
      </div>
    </div>

    <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="end-date group" fxFlex="50">
        <label class="required" translate>End Time</label>
        <date-range-picker [selectedDates]="[endDate]" (dateChanged)="onEndDateChanged($event)"></date-range-picker>
      </div>

      <div class="end-time group" fxFlex="50">
        <label>&nbsp;</label>
        <div class="timepicker-wrapper">
          <input 
          type="time" 
          name="end_time" 
          class="timepicker"
          (focusout)="onEndTimeChange($event)"
        />
        </div>
      </div>
    </div>

    <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="field-group ticket-number" fxFlex="100">
        <label class="required" translate>Ticket Number</label>
        <input type="text" name="ticket-number" placeholder="" [(ngModel)]="punchCard.ticketNumber" #ticketNumber="ngModel" />
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="editPunchCard.form.valid && submitPunchCard(); false"
            [disabled]="!editPunchCard.form.valid || loading"
            [ngClass]="{loading:loading}"
            translate>
      Save Punch Card Data
    </button>
  </div>
</form>
