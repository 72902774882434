<div class="container">
  <div class="actions-wrapper">
    <fancy-table
      #fancyTable
      [availableColumns]="availableColumns"
      [displayedColumns]="displayedColumns"
      [(filters)]="appliedFilters"
      [(search)]="search"
      [config]="tableConfig"
      (columnChange)="columnsChanged($event)"
      (selectionChanged)="selector($event)"
      (onDataLoaded)="onDataLoaded($event)"
    >
      <ng-template
        #columnTemplates
        let-row="row"
        let-column="column"
        let-value="value"
      >
        <ng-container [ngSwitch]="column">
          <div class="head selector" *ngSwitchCase="'select'">
            <label>
              <input type="checkbox" [checked]="allSelected || row.selected" />
            </label>
          </div>
          <div class="value job-date" *ngSwitchCase="'job-date'">
            <div class="data">
              {{ row.localizedJobDate | date : 'mediumDate' || '&nbsp;' }}
            </div>
            <div
              class="sub-title {{ row.administrativeStatus }}"
              *ngIf="row.displayableStatus"
            >
              {{ row.administrativeStatus }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'job-title'">
            <div class="data">{{ row.job || '&nbsp;' }}</div>
            <div class="sub-title">{{ row.project || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'start-time'">
            <div class="data">
              {{ row.localizedStartDatetime | moment : 'h:mm a' || '&nbsp;' }}
            </div>
            <div class="sub-title">
              {{ row.loadingLocationName || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'end-time'">
            <div class="data">
              {{ row.localizedEndDatetime | moment : 'h:mm a' || '&nbsp;' }}
            </div>
            <div class="sub-title">
              {{ row.unloadingLocationName || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'trip-duration'">
            <div class="data">
              {{
                getDuration(
                  row.localizedStartDatetime,
                  row.localizedEndDatetime
                ) || '&nbsp;'
              }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'loading-time'">
            <div class="data">
              {{
                getDuration(
                  row.localizedLoadingArrivalDatetime,
                  row.localizedLoadingCompleteDatetime
                ) || '&nbsp;'
              }}
            </div>
            <div class="sub-title">
              {{ row.loadingLocationName || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'travel-time'">
            <div class="data">
              {{
                getDuration(
                  row.localizedLoadingCompleteDatetime,
                  row.localizedUnloadingArrivalDatetime
                ) || '&nbsp;'
              }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'unloading-time'">
            <div class="data">
              {{
                getDuration(
                  row.localizedUnloadingArrivalDatetime,
                  row.localizedUnloadingCompleteDatetime
                ) || '&nbsp;'
              }}
            </div>
            <div class="sub-title">
              {{ row.unloadingLocationName || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'load-ticket-number'">
            <div
              class="data"
              [ngClass]="{
                'missing-required':
                  row.checkinOptions.ticketNumber && !row.loadingTicket
              }"
            >
              {{ row.loadingTicket || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'load-ticket-weight'">
            <div
              class="data"
              [ngClass]="{
                'missing-required':
                  row.checkinOptions.weight && !row.loadingWeight
              }"
            >
              {{ row.loadingWeight || '&nbsp;' }}
            </div>
            <div class="sub-title">
              {{ row.invoiceWeightUnit || '&nbsp;' | titleCase }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'unload-ticket-number'">
            <div
              class="data"
              [ngClass]="{
                'missing-required':
                  row.checkoutOptions.ticketNumber && !row.unloadingTicket
              }"
            >
              {{ row.unloadingTicket || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'unload-ticket-weight'">
            <div
              class="data"
              [ngClass]="{
                'missing-required':
                  row.checkoutOptions.weight && !row.unloadingWeight
              }"
            >
              {{ row.unloadingWeight || '&nbsp;' }}
            </div>
            <div class="sub-title">
              {{ row.invoiceWeightUnit || '&nbsp;' | titleCase }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'carrier'">
            <div class="data">{{ row.carrier || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'driver'">
            <div class="data">{{ row.driver || '&nbsp;' }}</div>
            <div class="sub-title">
              <a
                class="replay-link"
                target="_blank"
                href="/replay?date={{
                  row.jobDate | moment : 'YYYYMMDD'
                }}&driver={{ row.driverId }}"
                >View Replay</a
              >
            </div>
          </div>
          <div class="value" *ngSwitchCase="'truck'">
            <div class="data">{{ row.truck || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'duration'">
            <div class="data">{{ row.completedTripTime || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'payable'">
            <div class="data">{{ row.payableTo || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'customer'">
            <div class="data">{{ row.customer || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'invoice-rate'">
            <div class="data">
              {{
                (row.invoiceRate || 0
                  | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;'
              }}
            </div>
            <div class="sub-title">{{ row.invoiceType || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'haul-rate'">
            <div class="data">
              {{
                (row.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') ||
                  '&nbsp;'
              }}
            </div>
            <div class="sub-title">{{ row.haulType || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchDefault>{{ row.value }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>GeoTrips</div>
      </div>
      <div applied-filter-content>
        <applied-filters
          [(filters)]="appliedFilters"
          [(search)]="search"
        ></applied-filters>
      </div>
    </fancy-table>

    <div class="actions">
      <column-toggle
        #columnToggle
        [(availableColumns)]="availableColumns"
        [(displayedColumns)]="displayedColumns"
      >
      </column-toggle>
      <filters-panel
        [(appliedFilters)]="appliedFilters"
        [customFilter]="true"
        (openCustomFilter)="openFilters()"
        [search]="search"
        (searchChange)="search = $event"
      ></filters-panel>
      <action-menu icon="icon-more">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a *ngIf="option.link" mat-menu-item [routerLink]="option.url">{{
            option.name
          }}</a>
          <a
            *ngIf="!option.link"
            mat-menu-item
            (click)="setSelectedBulkAction(option)"
            >{{ option.name }}</a
          >
        </ng-container>
      </action-menu>
    </div>
  </div>
</div>
