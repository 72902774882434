import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { Trip } from '../../trips/trip';
import { PunchCard } from '../../punch-cards/punch-card';

@Pipe({
  name: 'lastHour', pure: true
})
export class LastHourPipe implements PipeTransform {
  transform(record: Trip|PunchCard, hour: string, complete = false, active = false): boolean {
    let ended = false;
    let endTime, timeAtHour, timeAtEndOfHour;
    if (complete || active || !record || !record.endTimeTimestamp) { return false; }
    if (record.endTimeTimestamp) {
      endTime = new Date(record.endTimeTimestamp);
      let _date = moment(endTime).format('YYYY-MM-DD');
      timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
      timeAtEndOfHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
      timeAtEndOfHour.add(3599, 'seconds');

      let _endTime = moment(endTime);
      if (timeAtHour < _endTime && _endTime <= timeAtEndOfHour) {
        ended = true;
      }
    }

    return ended;
  }
}
