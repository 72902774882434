"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ɵ0 = {}, ɵ1 = [];
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
var DriverPayModule = /** @class */ (function () {
    function DriverPayModule() {
    }
    return DriverPayModule;
}());
exports.DriverPayModule = DriverPayModule;
