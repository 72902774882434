import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Output() inviteConnection: EventEmitter<any> = new EventEmitter<any>();
  @Output() findConnection: EventEmitter<any> = new EventEmitter<any>();

  invite() { this.inviteConnection.emit(); }
  connect() { this.findConnection.emit(); }
}
