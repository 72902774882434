const camelcaseKeysDeep = require('camelcase-keys-deep');

export class ShiftFilter {
  id: string;
  name: string;

  constructor(shiftFilterInfo: any) {
    shiftFilterInfo = camelcaseKeysDeep(shiftFilterInfo);

    this.id = shiftFilterInfo.id;
    this.name = shiftFilterInfo.name;
  }
}
