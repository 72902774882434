import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PaystubsRoutingModule } from './paystubs-routing.module';

import { PaystubsComponent } from './paystubs.component';
import { PaystubTripsComponent } from './paystub-trips.component';
import { PaystubPunchCardsComponent } from './paystub-punch-cards.component';
import { PaystubSurchargesComponent } from './paystub-surcharges.component';
import { EditPaystubComponent } from './edit-paystub.component';

@NgModule({
  imports: [CommonModule, SharedModule, PaystubsRoutingModule],
  declarations: [
    PaystubsComponent,
    PaystubTripsComponent,
    PaystubPunchCardsComponent,
    PaystubSurchargesComponent,
    EditPaystubComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class PaystubsModule { }
