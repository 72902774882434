"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var http_1 = require("@angular/common/http");
var material_1 = require("@angular/material");
var moment = require("moment");
var core_2 = require("@ngx-translate/core");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var driver_job_report_service_1 = require("./driver-job-report.service");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var driver_job_report_filters_dialog_component_1 = require("./driver-job-report-filters-dialog.component");
var new_job_report_dialog_component_1 = require("./new-job-report-dialog.component");
var DriverJobReportsComponent = /** @class */ (function () {
    function DriverJobReportsComponent(driverJobReportService, route, router, translationService, dialog) {
        var _this = this;
        this.driverJobReportService = driverJobReportService;
        this.route = route;
        this.router = router;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'driver', title: this.translationService.instant('Driver / Truck') },
            { key: 'company', title: this.translationService.instant('Company') },
            { key: 'job', title: this.translationService.instant('Job') },
            { key: 'job-number', title: this.translationService.instant('Job #') },
            { key: 'customer', title: this.translationService.instant('Customer') },
            { key: 'customer-address', title: this.translationService.instant('customer-address') },
            { key: 'clock-in-time', title: this.translationService.instant('Clock In') },
            { key: 'first-load-time', title: this.translationService.instant('First Load') },
            { key: 'loading-wait-time', title: this.translationService.instant('Loading Wait Time') },
            { key: 'unloading-wait-time', title: this.translationService.instant('Unloading Wait Time') },
            { key: 'average-round-trip-time', title: this.translationService.instant('AVG Round Trip') },
            { key: 'trip-count', title: this.translationService.instant('Trip Count') },
            // { key: 'shift-end', title: this.translationService.instant('Shift End') },
            { key: 'last-load-time', title: this.translationService.instant('Last Load') },
            // { key: 'at-yard', title: this.translationService.instant('At Yard') },
            { key: 'freight-bill-time', title: this.translationService.instant('Freight Bill Time') },
            { key: 'shift-time', title: this.translationService.instant('Shift Time') },
            { key: 'deficient-time', title: this.translationService.instant('Deficient Time') },
        ];
        this.displayedColumns = [
            'select', 'driver', 'company', 'job', 'job-number', 'customer',
            'customer-address', 'clock-in-time', 'first-load-time', 'loading-wait-time',
            'unloading-wait-time', 'average-round-trip-time', 'trip-count',
            'last-load-time', 'freight-bill-time', 'shift-time', 'deficient-time'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.searchChanged = new rxjs_1.Subject();
        // config for fancy table
        this.tableConfig = {
            hasHeader: true,
            service: driver_job_report_service_1.DriverJobReportService,
            preferenceKey: 'DriverJobReportsComponent-DriverJobReportService',
            query: {},
            collectionTitle: this.translationService.instant('Driver Job Reports'),
            noResultsText: this.translationService.instant('a driver job report'),
            sortBy: 'date',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false }
            ]
        };
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Export'), action: 'export', link: false }
        ];
        this.query = {};
        this.loading = true;
        this.errors = [];
        this.allSelected = false;
        this.selectedDriverJobReports = [];
        this.excludeDriverJobReports = [];
        this.filters = [];
        this.currentDate = moment().format('YYYY-MM-DD');
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.exportCallback = function () { };
        this.saveJobReportCallback = function () {
            if (_this.driverJobReportsTable) {
                _this.driverJobReportsTable.getRecords();
            }
        };
        this.allSubscriptionsToUnsubscribe = [];
    }
    DriverJobReportsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = {};
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            if (result.qparams['date']) {
                var date = moment(result.qparams['date'], 'YYYYMMDD').toDate();
                _this.reportDate = date || new Date();
            }
            else {
                _this.setDefaultDate();
            }
            var startOfRange = moment(_this.reportDate).startOf('day');
            var endOfRange = moment(_this.reportDate).endOf('day');
            query['active_range'] = [startOfRange.toISOString(), endOfRange.toISOString()];
            _this.tableConfig['query'] = __assign({}, _this.tableConfig['query'], query);
            if (_this.driverJobReportsTable) {
                _this.driverJobReportsTable.config = __assign({}, _this.tableConfig);
                _this.driverJobReportsTable.getRecords();
            }
        }));
    };
    DriverJobReportsComponent.prototype.ngOnChanges = function (changes) {
        if (changes['reportDate']) {
            var reportDate = changes['reportDate'].currentValue;
            if (reportDate) {
                this.reportDate = new Date(reportDate);
            }
        }
        this.tableConfig['query'] = {
            active_range: this.reportDate.toISOString()
        };
        if (this.driverJobReportsTable) {
            this.driverJobReportsTable.config = __assign({}, this.tableConfig);
            this.driverJobReportsTable.getRecords();
        }
    };
    DriverJobReportsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DriverJobReportsComponent.prototype.openReport = function (_a) {
        var e = _a[0], row = _a[1];
        this.router.navigate(['driver-job-report', row.id]);
    };
    DriverJobReportsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        if (!this.allSelected) {
            this.selectedDriverJobReports = event.selection.selected;
            this.excludeDriverJobReports = [];
        }
        else {
            this.selectedDriverJobReports = [];
            this.excludeDriverJobReports = event.exclusion.selected;
        }
    };
    DriverJobReportsComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
    };
    DriverJobReportsComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    DriverJobReportsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(driver_job_report_filters_dialog_component_1.DriverJobReportFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        // dialog.componentInstance.model.carrier = null;
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    DriverJobReportsComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            reportDate: 'active_range',
            company: 'company',
            customer: 'customer'
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (typeof (values) === 'boolean') {
                if (values) {
                    values = values.toString();
                    values = values.charAt(0).toUpperCase() + values.slice(1);
                    query[queryKeys[key]] = values;
                }
                displayValues = values;
            }
            else if (filterRes[key]) {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
            }
            var filter = new filter_option_1.FilterOption({
                filterType: ['active_range'].indexOf(key) === -1 ? 'text' : 'date',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (filter.values === 'False' || !filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    DriverJobReportsComponent.prototype.setSelectedAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedReports();
                break;
        }
    };
    DriverJobReportsComponent.prototype.exportSelectedReports = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        var scope = {};
        var params = new http_1.HttpParams();
        this.driverJobReportService.getExportFields().subscribe(function (fields) {
            if (trips || _this.selectedDriverJobReports.length) {
                Object.assign(scope, { include: trips || _this.selectedDriverJobReports });
            }
            else if (_this.allSelected) {
                Object.assign(scope, { exclude: _this.excludeDriverJobReports });
                Object.keys(_this.filters).map(function (key) {
                    if (_this.filters[key]) {
                        var query_1 = _this.filters[key]['query'];
                        Object.keys(query_1).map(function (queryKey) {
                            params = params.set(queryKey, query_1[queryKey]);
                        });
                    }
                });
                params = params.set('search', _this.search);
            }
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'trips',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: _this.exportCallback,
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.driverJobReportService
                }
            });
        }, function (err) { _this.errors = err; });
    };
    DriverJobReportsComponent.prototype.onDateChanged = function (dates) {
        this.reportDate = dates[0];
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        var date = moment(this.reportDate).format('YYYYMMDD');
        queryParams['date'] = date;
        queryParams['search'] = this.search;
        this.router.navigate([], { queryParams: queryParams });
    };
    DriverJobReportsComponent.prototype.setDefaultDate = function () {
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        var date = moment();
        queryParams['date'] = date.format('YYYYMMDD');
        this.reportDate = date.toDate();
        this.router.navigate(['driver-reports'], { queryParams: queryParams });
    };
    DriverJobReportsComponent.prototype.openAddJobReport = function () {
        var dialog = this.dialog.open(new_job_report_dialog_component_1.NewJobReportDialogComponent, {
            width: '444px'
        });
        dialog.componentInstance.reportDate = this.reportDate;
        dialog.componentInstance.callback = this.saveJobReportCallback;
    };
    DriverJobReportsComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId,
        });
    };
    return DriverJobReportsComponent;
}());
exports.DriverJobReportsComponent = DriverJobReportsComponent;
