"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./driver-note-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/dialog");
var i3 = require("@ngx-translate/core");
var i4 = require("@angular/forms");
var i5 = require("./driver-note-dialog.component");
var i6 = require("../../trips/trip.service");
var styles_DriverNoteDialogComponent = [i0.styles];
var RenderType_DriverNoteDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DriverNoteDialogComponent, data: {} });
exports.RenderType_DriverNoteDialogComponent = RenderType_DriverNoteDialogComponent;
function View_DriverNoteDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Ticket Note"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 19, "div", [["class", "dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "content-title"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(10, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Leave a note on this ticket which can be seen by other team members. "])), (_l()(), i1.ɵeld(12, 0, null, null, 8, "div", [["class", "driver-note"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 7, "textarea", [["name", "note"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 14)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newNote = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(15, 540672, null, 0, i4.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.MaxLengthValidator]), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(18, 671744, null, 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(20, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 5, "div", [["class", "character-limit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", "/", " "])), (_l()(), i1.ɵeld(24, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(25, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["character limit"])), (_l()(), i1.ɵeld(27, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["translate", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Save Note "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = ""; _ck(_v, 3, 0, currVal_1); var currVal_2 = ""; _ck(_v, 10, 0, currVal_2); var currVal_11 = _co.maxlength; _ck(_v, 15, 0, currVal_11); var currVal_12 = "note"; var currVal_13 = _co.disabled; var currVal_14 = _co.newNote; _ck(_v, 18, 0, currVal_12, currVal_13, currVal_14); var currVal_17 = ""; _ck(_v, 25, 0, currVal_17); var currVal_19 = ""; _ck(_v, 29, 0, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 15).maxlength ? i1.ɵnov(_v, 15).maxlength : null); var currVal_4 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 20).ngClassValid; var currVal_9 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_15 = (_co.maxlength - _co.newNote.length); var currVal_16 = _co.maxlength; _ck(_v, 23, 0, currVal_15, currVal_16); var currVal_18 = (_co.disabled || (_co.newNote === _co.note)); _ck(_v, 28, 0, currVal_18); }); }
exports.View_DriverNoteDialogComponent_0 = View_DriverNoteDialogComponent_0;
function View_DriverNoteDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "driver-note-dialog", [], null, null, null, View_DriverNoteDialogComponent_0, RenderType_DriverNoteDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.DriverNoteDialogComponent, [i2.MatDialogRef, i6.TripService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DriverNoteDialogComponent_Host_0 = View_DriverNoteDialogComponent_Host_0;
var DriverNoteDialogComponentNgFactory = i1.ɵccf("driver-note-dialog", i5.DriverNoteDialogComponent, View_DriverNoteDialogComponent_Host_0, { tripId: "tripId", note: "note", noteProperty: "noteProperty", disabled: "disabled" }, {}, []);
exports.DriverNoteDialogComponentNgFactory = DriverNoteDialogComponentNgFactory;
