import {
  Component, Input, Output, EventEmitter, OnInit, HostListener,
  ElementRef, ViewChild, OnDestroy, SimpleChanges, OnChanges
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// libraries
import { includes, clone } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

// angular material
import { MatDialog, MatDialogRef } from '@angular/material';

// services
import { TruckService } from './truck.service';
import { parseErrors } from '../shared/api.service';
import { TagService } from '../tags/tag.service';
import { CustomFieldService } from '../custom-fields/custom-field.service';
import { TruckTypeService } from './truck-type.service';
import { LocationService } from '../locations/location.service';
import { ConnectionService } from '../connections/connection.service';

// components
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { DropdownComponent } from '../shared';

// types
import { CustomField, CustomFieldKind } from '../custom-fields/custom-field';
import { Truck } from './truck';
import { Tag } from '../tags/tag';

@Component({
  selector: 'ruckit-edit-truck',
  templateUrl: './edit-truck.component.html',
  styleUrls: ['./edit-truck.component.scss']
})
export class EditTruckComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modifiedTruck: Truck;
  @Input() truck: Truck;
  @Input() afterEdit;
  @Input() tags = [];
  @Output() completed = new EventEmitter();
  @Output() closed = new EventEmitter();

  open = false;
  customFields: { [key: string]: CustomField } = {};
  customFieldsReq: Subscription;
  loading = false;
  errors = [];
  confirmDialog: MatDialogRef<any>;
  tag = [];
  device;

  @ViewChild('serviceStatusDropdown', { static: false }) serviceStatusDropdown: DropdownComponent;
  serviceStatusConfig = { nameProperty: 'name' };
  serviceStatusOptions = [
    { name: 'In Service', id: 'in-service' },
    { name: 'Out Of Service', id: 'out-of-service' }
  ];

  // carriers dropdown
  @ViewChild('carriersDropdown', { static: false }) carriersDropdown: RuckitDropdownComponent;
  carriersConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'organization.carrier.id',
    selectText: this.translateService.instant('Select Fleet'),
    loadingText: this.translateService.instant('Loading Fleets...'),
    noResultsText: this.translateService.instant('No Fleets'),
    service: ConnectionService,
    serviceFunction: 'list',
    query: {
      ordering: 'organization__name',
      is_carrier: 'True',
      allow_dispatch: 'True',
    },
    prefilledOptions: [],
  };

  // markets dropdown
  @ViewChild('marketsDropdown', { static: false }) marketsDropdown: RuckitDropdownComponent;
  marketsConfig = {
    multiselect: true,
    selectText: this.translateService.instant('Select Markets'),
    loadingText: this.translateService.instant('Loading Markets...'),
    noResultsText: this.translateService.instant('No Markets'),
    service: TagService,
    query: {}
  };

  // locations dropdown
  @ViewChild('locationsDropdown', { static: false }) locationsDropdown: RuckitDropdownComponent;
  locationsConfig = {
    selectText: this.translateService.instant('Select Location'),
    loadingText: this.translateService.instant('Loading Locations...'),
    noResultsText: this.translateService.instant('No Locations'),
    service: LocationService,
    query: {},
    noneOption: true
  };

  // truck types dropdown
  @ViewChild('truckTypesDropdown', { static: false }) truckTypesDropdown: RuckitDropdownComponent;
  truckTypesConfig = {
    searchable: true,
    selectText: this.translateService.instant('Select Truck Type'),
    loadingText: this.translateService.instant('Loading Truck Types...'),
    noResultsText: this.translateService.instant('No Truck Types'),
    nameProperty: 'name',
    serviceFunction: 'list',
    service: TruckTypeService,
    query: { ordering: 'name' }
  };
  @ViewChild('truckForm', { static: false }) truckForm;

  @HostListener('document:click', ['$event', 'truck']) documentClick(event) {
    let row = event && event.target && event.target.closest('.primary-row');
    if (!includes(event.target.classList, 'row') && !(row && row.contains(event.target)) && this.open) {
      let confirmDialog = document.querySelector('ruckit-confirm-dialog');
      if (!includes(event.target.classList, 'icon-more') &&
        !includes(event.target.classList, 'mat-chip-remove') &&
        !includes(event.target.classList, 'mat-option-text') &&
        !this._eref.nativeElement.contains(event.target) &&
        !(confirmDialog && confirmDialog.contains(event.target))) {
        if (this.truckForm && !this.truckForm.form.pristine) {
          this.confirmBeforeClosing().subscribe((dialogResult) => {
            if (dialogResult) {
              this.submit();
            } else {
              this.close();
            }
          });
        } else {
          this.close();
        }
      }
    }
  }

  constructor(
    private _eref: ElementRef,
    public dialog: MatDialog,
    private truckService: TruckService,
    private customFieldService: CustomFieldService,
    private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.getCustomFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.truck && changes.truck.currentValue && changes.truck.currentValue.id) {
      this.modifiedTruck = clone(this.truck);
    }
  }

  ngOnDestroy() {
    if (this.customFieldsReq && typeof this.customFieldsReq.unsubscribe === 'function') {
      this.customFieldsReq.unsubscribe();
    }
  }

  submit(): void {
    this.loading = true;
    delete this.modifiedTruck.carrierOrganizationId;
    this.truckService.save(this.modifiedTruck).subscribe((truck) => {
      this.open = false;
      this.truck.selected = false;
      this.completed.emit(
        Object.assign(truck, {
          truckType: this.modifiedTruck.truckType,
          tags: truck.tags.map(t => (<Tag>{name: t}))
        })
      );
      if (this.truckForm) { this.truckForm.form.markAsPristine(); }
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  remove(): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });

    if (this.confirmDialog && this.confirmDialog.componentInstance) {
      this.confirmDialog.componentInstance.attributes = {
        title: this.translateService.instant('Delete Truck?'),
        body: this.translateService.instant('You will no longer be able to dispatch this truck. Deleting a truck cannot be undone.'),
        close: this.translateService.instant('Cancel'),
        accept: this.translateService.instant('Delete')
      };
      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.truckService.remove(this.modifiedTruck).subscribe(() => {
            this.modifiedTruck.status = 'removed';
            this.open = false;
            this.truck.selected = false;
            if (this.truckForm) {
              this.truckForm.form.markAsPristine();
            }
            this.loading = false;
            this.completed.emit(this.modifiedTruck);
          }, (err) => {
            this.errors = parseErrors(err);
            this.loading = false;
          });
        }
        this.confirmDialog = null;
      });
    }
  }

  /**
   * Updates a specified field on the truck object, with specified functionality for particular fields
   *
   * @param {any} value The updated value to be applied to the truck object
   * @param {string} field The field name which the value will be set on
   */
  optionSelected(e: any, field: string) {
    let editsMade = false;
    switch (field) {
      case 'carrier':
        if (
          e.organization && e.organization.carrier && e.organization.carrier.id &&
          this.modifiedTruck.carrierOrganizationId !== e.organization.id
        ) {
          this.modifiedTruck.carrier = e.organization.carrier.id;
          editsMade = true;
        }
        break;
      case 'tags':
        let tagSelected: Tag[] = [];
        e.forEach((tag) => {
          let obj = this.tags.find(o => o.name.toUpperCase() === tag.name.toUpperCase());
          if (obj) {
            tagSelected.push(obj);
          } else {
            tagSelected.push(<Tag>{name: tag.name});
          }
        });
        if (tagSelected.length > 0) {
          this.modifiedTruck['tags'] = tagSelected;
          editsMade = true;
        }
        break;
      case 'location':
        if (!this.modifiedTruck.yardLocation || this.modifiedTruck.yardLocation.id !== e.id) {
          this.modifiedTruck.yardLocation = e;
          editsMade = true;
        }
        break;
      default:
        this.modifiedTruck[field] = e;
        editsMade = true;
        break;
    }
    if (this.truckForm && this.truckForm.form && editsMade) { this.truckForm.form.markAsDirty(); }
  }

  close(): void {
    if (this.truckForm && this.truckForm.form) {
      this.truckForm.form.markAsPristine();
      this.open = false;
      this.truck.selected = false;
      if (this.truck && this.modifiedTruck && (this.truck.id === this.modifiedTruck.id)) {
        this.modifiedTruck = undefined;
      }
      this.closed.emit();
    }
  }

  public markAsPristine(): void {
    if (this.truckForm && this.truckForm.form) {
      this.truckForm.form.markAsPristine();
    }
  }

  public setOpen(): void {
    this.open = true;
    setTimeout(() => {
      if (this.carriersDropdown) {
        this.carriersDropdown.deselectOptions();
        this.carriersConfig = {
          ...this.carriersConfig,
          selectText:
            this.truck && this.truck.carrierOrganizationName
              ? this.truck.carrierOrganizationName
              : this.translateService.instant('Select Fleet'),
        };
      }
    }, 500);
  }

  getCustomFields(): void {
    if (this.customFieldsReq && typeof this.customFieldsReq.unsubscribe === 'function') {
      this.customFieldsReq.unsubscribe();
    }

    this.customFieldsReq = this.customFieldService.getIndexedFieldsForKind(
      CustomFieldKind.Truck
    ).subscribe(fields => {
      this.customFields = fields;
    });
  }

  /**
   * To display confirm dialog
   * if user closes edit panel without saving data
   */
  confirmBeforeClosing(): Observable<any> {
    let observable: Observable<any>;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    if (this.confirmDialog && this.confirmDialog.componentInstance) {
      this.confirmDialog.componentInstance.attributes = {
        title: this.translateService.instant('You have unsaved Changes'),
        body: this.translateService.instant('Are you sure you want to leave? Your changes will not be saved.'),
        close: this.translateService.instant('Discard'),
        accept: this.translateService.instant('Save')
      };
      observable = this.confirmDialog.afterClosed();
      this.confirmDialog = null;
    }
    return observable;
  }
}
