"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var material_1 = require("@angular/material");
var job_invitation_filters_dialog_component_1 = require("./job-invitation-filters-dialog.component");
var accept_job_dialog_component_1 = require("../jobs/accept-job-dialog.component");
var project_service_1 = require("../projects/project.service");
var collaborator_service_1 = require("../collaborators/collaborator.service");
var connection_service_1 = require("../connections/connection.service");
var invitation_service_1 = require("../invitation/invitation.service");
var location_service_1 = require("../locations/location.service");
var index_1 = require("../shared/index");
var ngx_device_detector_1 = require("ngx-device-detector");
var device_1 = require("../shared/device");
var JobInvitationsComponent = /** @class */ (function () {
    function JobInvitationsComponent(route, router, deviceDetectorService, projectService, connectionService, locationService, collaboratorService, invitationService, authenticationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.deviceDetectorService = deviceDetectorService;
        this.projectService = projectService;
        this.connectionService = connectionService;
        this.locationService = locationService;
        this.collaboratorService = collaboratorService;
        this.invitationService = invitationService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.device = new device_1.Device();
        this.invitations = [];
        this.invitation = {};
        this.loading = true;
        this.canCreateJobs = true;
        this.hasAllDriversEnabled = false;
        this.errors = [];
        this.search = '';
        this.sortAsc = true;
        this.stringify = JSON.stringify;
        this.locations = [];
        this.customers = [];
        this.projects = [];
        this.filters = [];
        this.acceptJobCallback = function () {
            _this.getInvitations();
        };
        this.dropdownNextPage = this.dropdownNextPage.bind(this);
        this.dropdownSearch = this.dropdownSearch.bind(this);
    }
    JobInvitationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.canCreateJobs = this.authenticationService.canCreateJobs();
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
        var invitationId;
        this.route.params.subscribe(function (params) {
            invitationId = params['invitationId'];
        });
        this.route.queryParams.forEach(function (params) {
            _this.loading = true;
            _this.search = params['search'] || '';
            _this.getInvitations();
            _this.getLocations();
            _this.getProjects();
            _this.getCustomers();
            if (invitationId) {
                _this.getInvitation(invitationId);
            }
        });
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    };
    JobInvitationsComponent.prototype.ngOnDestroy = function () {
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
            this.customersReq.unsubscribe();
        }
        if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
            this.projectsReq.unsubscribe();
        }
        if (this.collaboratorsReq && typeof this.collaboratorsReq.unsubscribe === 'function') {
            this.collaboratorsReq.unsubscribe();
        }
        if (this.invitationReq && typeof this.invitationReq.unsubscribe === 'function') {
            this.invitationReq.unsubscribe();
        }
    };
    JobInvitationsComponent.prototype.getLocations = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        var request = this.locationService.list(query);
        if (next) {
            request = this.locationService.listNext();
        }
        else {
            this.customers = [];
        }
        if (this.filtersDialog) {
            this.filtersDialog.locationsConfig.loadingOptions = true;
        }
        this.locationsReq = this.locationService.list(query).subscribe(function (locations) {
            _this.locations.push.apply(_this.locations, locations);
        }, function (err) {
            _this.errors = err;
        }, function () {
            if (_this.filtersDialog) {
                _this.filtersDialog.locationsConfig.loadingOptions = false;
            }
        });
    };
    JobInvitationsComponent.prototype.getCustomers = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
            this.customersReq.unsubscribe();
        }
        var request = this.connectionService.list(query);
        if (next) {
            request = this.connectionService.listNext();
        }
        else {
            this.customers = [];
        }
        if (this.filtersDialog) {
            this.filtersDialog.customersConfig.loadingOptions = true;
        }
        this.customersReq = request.subscribe(function (res) {
            _this.customers.push.apply(_this.customers, res.map(function (connection) { return connection.organization; }));
        }, function (err) {
            _this.errors = err;
        }, function () {
            if (_this.filtersDialog) {
                _this.filtersDialog.customersConfig.loadingOptions = false;
            }
        });
    };
    JobInvitationsComponent.prototype.getProjects = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
            this.projectsReq.unsubscribe();
        }
        var request = this.projectService.list(query);
        if (next) {
            request = this.projectService.listNext();
        }
        else {
            this.projects = [];
        }
        if (this.filtersDialog) {
            this.filtersDialog.customersConfig.loadingOptions = true;
        }
        this.projectsReq = request.subscribe(function (res) {
            _this.projects.push.apply(_this.projects, res);
        }, function (err) {
            _this.errors = err;
        }, function () {
            if (_this.filtersDialog) {
                _this.filtersDialog.customersConfig.loadingOptions = false;
            }
        });
    };
    JobInvitationsComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.collaboratorService.getNext();
            if (o) {
                this.loading = true;
                o.subscribe(function (invitations) {
                    _this.invitations = _this.invitations.concat(invitations);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; });
            }
        }
    };
    JobInvitationsComponent.prototype.getInvitations = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.collaboratorsReq && typeof this.collaboratorsReq.unsubscribe === 'function') {
            this.collaboratorsReq.unsubscribe();
        }
        this.loading = true;
        this.collaboratorsReq = this.collaboratorService.getPending().subscribe(function (invitations) {
            _this.invitations = invitations;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    JobInvitationsComponent.prototype.getInvitation = function (invitationId) {
        var _this = this;
        if (this.invitationReq && typeof this.invitationReq.unsubscribe === 'function') {
            this.invitationReq.unsubscribe();
        }
        this.loading = true;
        this.invitationReq = this.invitationService.getInvitation(invitationId).subscribe(function (invitation) {
            _this.invitation = invitation;
            _this.openAcceptJob(_this.invitation);
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    JobInvitationsComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.getInvitations();
    };
    JobInvitationsComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    JobInvitationsComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getInvitations({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    JobInvitationsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(job_invitation_filters_dialog_component_1.JobInvitationFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.customers = this.customers.map(function (customer) {
            return __assign({}, customer, { selected: lodash_1.get(lodash_1.find(_this.filters, { key: 'customer' }), 'value.id') === customer.id });
        });
        dialog.componentInstance.locations = this.locations.map(function (location) {
            return __assign({}, location, { selected: lodash_1.get(lodash_1.find(_this.filters, { key: 'location' }), 'value.id') === location.id });
        });
        dialog.componentInstance.projects = this.projects.map(function (project) {
            return __assign({}, project, { selected: lodash_1.get(lodash_1.find(_this.filters, { key: 'project' }), 'value.id') === project.id });
        });
        dialog.componentInstance.dropdownNextPage = this.dropdownNextPage;
        dialog.componentInstance.dropdownSearch = this.dropdownSearch;
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    JobInvitationsComponent.prototype.dropdownNextPage = function (e, type) {
        switch (type) {
            case 'customer':
                this.getCustomers({}, true);
                break;
            case 'project':
                this.getProjects({}, true);
                break;
            case 'location':
                this.getLocations({}, true);
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    JobInvitationsComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'customer':
                this.getCustomers({ search: term });
                break;
            case 'project':
                this.getProjects({ search: term });
                break;
            case 'location':
                this.getLocations({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    JobInvitationsComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'job__customer_organizationt',
            project: 'job__project',
            location: 'job__end_location'
        };
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            query[queryKeys[key]] = filterRes[key].id;
            return {
                key: key,
                value: filterRes[key],
                query: query
            };
        });
        this.getInvitations();
    };
    JobInvitationsComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.filters, filter);
        this.getInvitations();
    };
    JobInvitationsComponent.prototype.selectInvitation = function (e, invitation) {
        var target = e.target || e.srcElement || e.currentTarget;
        if (target && target.className === 'icomoon icon-more') {
            // Do nothing
        }
        else {
            this.openAcceptJob(invitation);
        }
    };
    JobInvitationsComponent.prototype.openAcceptJob = function (invitation) {
        var modalSize = this.device.mobile ? { height: '100vh', width: '730px' } : { width: '730px' };
        var dialog = this.dialog.open(accept_job_dialog_component_1.AcceptJobDialogComponent, modalSize);
        dialog.componentInstance.invitation = invitation;
        dialog.componentInstance.callback = this.acceptJobCallback;
    };
    JobInvitationsComponent.prototype.isAcceptable = function () {
        // TODO: What scenarios would render a Job "acceptable"?
        return true;
    };
    return JobInvitationsComponent;
}());
exports.JobInvitationsComponent = JobInvitationsComponent;
