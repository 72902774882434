import { CondensedTrip } from '../trips/condensed-trip';
import { ImageResizer } from '../images/resizer';
import { clone, find, remove } from 'lodash';
import * as moment from 'moment';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class CondensedPunchCard {
  id: string;
  jobDate: string;
  customer: string;
  customerId: string;
  driver: string;
  driverId: string;
  _startTimeTimestamp: string;
  _endTimeTimestamp: string;
  haulRate: string;
  haulType: string;
  haulWeightUnit: string;
  invoiceRate: string;
  invoiceTotal: string;
  invoiceType: string;
  invoiceWeightUnit: string;
  job: string;
  jobDisplayName: string;
  jobId: string;
  project: string;
  projectId: string;
  payableTo: string;
  payableToId: string;
  ticketImage: string;
  ticketImageKey: string;
  ticketNumber: string;
  trips: CondensedTrip[];
  truck: string;
  truckId: string;
  truckType: string;
  selected = false;
  haulTotal: string;
  void: boolean;
  edited: boolean;
  loading = false;
  expenseId: string;
  expenseNumber: string;
  expenseSent = false;
  paystubId: string;
  paystubNumber: string;
  paystubSent = false;
  invoiceId: string;
  invoiceNumber: string;
  invoiceSent = false;
  invoiceQuantity: string;
  kind: string;

  // pre-formatted fields for display
  _duration: string;
  _startTime: string;
  _endTime: string;
  _startDate: Date;
  _endDate: Date;

  constructor(punchCardInfo: any) {
    punchCardInfo = camelcaseKeysDeep(punchCardInfo);

    this.id = punchCardInfo.id;
    this.customer = punchCardInfo.customer;
    this.customerId = punchCardInfo.customerId;
    this.driver = punchCardInfo.driver;
    this.driverId = punchCardInfo.driverId;
    this.startTimeTimestamp = punchCardInfo.startTime;
    this.endTimeTimestamp = punchCardInfo.endTime;
    this.haulRate = punchCardInfo.haulRate;
    this.haulType = punchCardInfo.haulType;
    this.haulWeightUnit = punchCardInfo.haulWeightUnit;
    this.invoiceRate = punchCardInfo.invoiceRate;
    this.invoiceTotal = punchCardInfo.invoiceTotal;
    this.invoiceType = punchCardInfo.invoiceType;
    this.invoiceWeightUnit = punchCardInfo.invoiceWeightUnit;
    this.job = punchCardInfo.job;
    this.jobDisplayName = punchCardInfo.jobDisplayName;
    this.jobId = punchCardInfo.jobId;
    this.project = punchCardInfo.project;
    this.projectId = punchCardInfo.projectId;
    this.payableTo = punchCardInfo.payableTo;
    this.payableToId = punchCardInfo.payableToId;
    this.ticketImage = punchCardInfo.ticketImage;
    this.ticketImageKey = punchCardInfo.ticketImageKey;
    this.ticketNumber = punchCardInfo.ticketNumber;
    if (punchCardInfo.trips && punchCardInfo.trips.length) {
      this.trips = punchCardInfo.trips.map(trip => {
        return new CondensedTrip(trip);
      });
    }
    this.truck = punchCardInfo.truck;
    this.truckId = punchCardInfo.truckId;
    this.truckType = punchCardInfo.truckType;
    this.haulTotal = punchCardInfo.haulTotal;
    this.void = punchCardInfo.void;
    this.edited = punchCardInfo.edited;
    this.expenseId = punchCardInfo.expenseId;
    this.expenseNumber = punchCardInfo.expenseNumber;
    this.expenseSent = punchCardInfo.expenseSent;
    this.paystubId = punchCardInfo.paystubId;
    this.paystubNumber = punchCardInfo.paystubNumber;
    this.paystubSent = punchCardInfo.paystubSent;
    this.invoiceId = punchCardInfo.invoiceId;
    this.invoiceNumber = punchCardInfo.invoiceNumber;
    this.invoiceSent = punchCardInfo.invoiceSent;
    this.invoiceQuantity = punchCardInfo.invoiceQuantity;
    this.jobDate = punchCardInfo.jobDate;
    this.kind = punchCardInfo.kind || 'invoice';
  }

  save() {
    // Save to API
    console.log(this.id);
  }

  formatDates(): void {
    let duration;
    if (this._endTimeTimestamp) {
      duration = moment.duration(moment(this._endTimeTimestamp).diff(this._startTimeTimestamp));
    } else {
      duration = moment.duration(moment().diff(this.startTimeTimestamp));
    }
    this._duration = duration.format('D[ days], H[ hrs], m[ mins]');

    this._startDate = new Date(this._startTimeTimestamp);
    if (!this._startTime) {
      this._startTime = moment(this._startDate).format('h:mm a');
    }
    this._endDate = new Date(this._endTimeTimestamp);
    if (!this._endTime) {
      this._endTime = moment(this._endDate).format('h:mm a');
    }
  }

  set startTimeTimestamp(value: any) {
    this._startTimeTimestamp = value;
    this.formatDates();
  }

  set endTimeTimestamp(value: any) {
    this._endTimeTimestamp = value;
    this.formatDates();
  }

  get endTimeTimestamp() {
    return this._endTimeTimestamp;
  }

  get startTimeTimestamp() {
    return this._startTimeTimestamp;
  }

  get duration(): string {
    return this._duration;
  }

  get startTime(): string {
    return this._startTime;
  }

  get endTime(): string {
    if (this._endTime === 'Invalid date') {
      return moment(this._endDate).format('h:mm a');
    }
    return this._endTime;
  }

  get startDate(): any {
    return this._startDate;
  }

  get endDate(): any {
    return this._endDate;
  }

  set startDate(value: any) {
    let date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.startTime);
    this.startTimeTimestamp = date.toISOString();
  }

  set endDate(value: any) {
    let date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.endTime);
    this.endTimeTimestamp = date.toISOString();
  }

  set startTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.startTimeTimestamp = parsedDate.toISOString();
  }

  set endTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.endTimeTimestamp = parsedDate.toISOString();
  }

  set duration(value: string) {
    this._duration = value;
  }

  get schedule(): string {
    let values = [];
    if (this.startTime && this.startTime.length) {
      values.push(this.startTime);
    }
    if (this.endTimeTimestamp && this.endTimeTimestamp.length) {
      values.push(this.endTime);
    } else {
      values.push('OPEN');
    }

    return values.filter(Boolean).join(' - ');
  }

  get truckName(): string {
    let values = [];
    if (this.truck && this.truck.length) {
      values.push(this.truck);
    }
    if (this.truckType && this.truckType.length) {
      values.push(this.truckType);
    }

    return values.filter(Boolean).join(' - ');
  }

  get invoiceRateUnit(): string {
    if (this.invoiceType === 'weight') {
      switch (this.invoiceWeightUnit) {
        case 'cuyds':
          return 'cu. yd.';
        default:
          return this.invoiceWeightUnit;
      }
    } else {
      return this.invoiceType;
    }
  }

  get haulRateUnit(): string {
    if (this.haulType === 'weight') {
      switch (this.haulWeightUnit) {
        case 'cuyds':
          return 'cu. yd.';
        default:
          return this.haulWeightUnit;
      }
    } else {
      return this.haulType;
    }
  }

  pluralizedInvoiceRateUnit(plural = true): string {
    if (this.invoiceType === 'weight') {
      switch (this.invoiceWeightUnit) {
        case 'cuyds':
          return plural ? 'cu. yds.' : 'cu. yd.';
        default:
          return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
      }
    } else {
      return this.invoiceType;
    }
  }

  pluralizedHaulRateUnit(plural = true): string {
    if (this.haulType === 'weight') {
      switch (this.haulWeightUnit) {
        case 'cuyds':
          return plural ? 'cu. yds.' : 'cu. yd.';
        default:
          return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
      }
    } else {
      return this.haulType;
    }
  }

  pluralizedInvoiceWeightUnitDisplay(plural = true): string {
    switch (this.invoiceWeightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
    }
  }

  pluralizedHaulWeightUnitDisplay(plural = true): string {
    switch (this.haulWeightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
    }
  }

  get invoiceWeightUnitDisplay(): string {
    switch (this.invoiceWeightUnit) {
      case 'cuyds':
        return 'cu. yd.';
      default:
        return this.invoiceWeightUnit;
    }
  }

  get haulWeightUnitDisplay(): string {
    switch (this.haulWeightUnit) {
      case 'cuyds':
        return 'cu. yd.';
      default:
        return this.haulWeightUnit;
    }
  }

  get hasTicketImage(): boolean {
    if (this.ticketImage && this.ticketImage.length) {
      return true;
    } else {
      return false;
    }
  }

  get inlineTicket(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getResizedUrl(this.ticketImageKey, 28, 28);
    }
  }

  get administrativeStatus(): string {
    if (this.void) {
      return 'void';
    } else if (this.edited) {
      return 'edited';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus && this.administrativeStatus.length) {
      return true;
    } else {
      return false;
    }
  }

  get expenseTitle(): string {
    let value = '';
    if (this.expenseSent) {
      value = 'Paid #' + this.expenseNumber;
    } else if (this.expenseNumber) {
      value = 'Draft #' + this.expenseNumber;
    }
    return value;
  }

  get paystubTitle(): string {
    let value = '';
    if (this.paystubSent) {
      value = 'Paid #' + this.paystubNumber;
    } else if (this.paystubNumber) {
      value = 'Draft #' + this.paystubNumber;
    }
    return value;
  }

  get invoiceTitle(): string {
    let value = '';
    if (this.invoiceSent) {
      value = 'Sent #' + this.invoiceNumber;
    } else if (this.invoiceNumber) {
      value = 'Draft #' + this.invoiceNumber;
    }
    return value;
  }

  get invoiceQuantityWithLabel(): string {
    let value = this.invoiceQuantity;
    let unit = this.haulRateUnit;

    if (this.kind === 'invoice') { unit = this.invoiceRateUnit; }

    if (parseFloat(this.invoiceQuantity) === 1) {
      value = value + ' ' + unit;
    } else {
      value = value + ' ' + unit + 's';
    }

    return value;
  }

  url(action): string {
    return '/punchcards/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, {action: 'void'});
      if (_option) { remove(options, _option); }
    }

    if (!this.void) {
      let _option = find(options, {action: 'unvoid'});
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
