"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var marketplace_service_1 = require("../marketplace/marketplace.service");
var shared_1 = require("../shared");
var preference_service_1 = require("../preferences/preference.service");
var JobOverviewComponent = /** @class */ (function () {
    function JobOverviewComponent(route, router, elementRef, marketplaceService, authenticationService, preferenceService) {
        this.route = route;
        this.router = router;
        this.elementRef = elementRef;
        this.marketplaceService = marketplaceService;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.loading = true;
        this.errors = [];
        this.displayNotes = false;
    }
    JobOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.disableSidebar();
        this.route.params
            .subscribe(function (params) { return _this.getMarketplace(params['id']); });
        this.userPreferences$ = this.preferenceService.userPreference$;
        this.getPreferences();
    };
    JobOverviewComponent.prototype.disableSidebar = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.sidebar = false;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    };
    JobOverviewComponent.prototype.getPreferences = function () {
        var currentUser = this.authenticationService.user();
        this.preferenceService.getUserPreferenceObservable(currentUser && currentUser.id);
    };
    JobOverviewComponent.prototype.getMarketplace = function (id) {
        var _this = this;
        if (this.marketplaceReq) {
            this.marketplaceReq.unsubscribe();
        }
        this.marketplaceReq = this.marketplaceService.getMarketplace(id)
            .subscribe(function (marketplace) { return _this.marketplace = marketplace; }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    JobOverviewComponent.prototype.acceptJob = function () {
        this.router.navigate(['/marketplace/' + this.marketplace.id + '/accept']);
    };
    return JobOverviewComponent;
}());
exports.JobOverviewComponent = JobOverviewComponent;
