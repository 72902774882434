import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';

// libraries
import { difference, get, find as _find } from 'lodash';
import moment = require('moment');

// models
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { CyberTrip } from '../../cyber-trips/cyber-trip';
import { Driver } from '../driver';
import {
  FieldOption,
  ExportDialogComponent,
  ExportDialogData,
} from '../../shared/export-dialog/export-dialog.component';

// services
import { CyberTripService } from '../../cyber-trips/cyber-trip.service';

// components
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { DriverTripFiltersDialogComponent } from '../driver-trip-filters-dialog/driver-trip-filters-dialog.component';
import { ColumnToggleComponent } from '../../shared/column-toggle/column-toggle.component';

// constants
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS } from './columns';

// utils
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'driver-geotrips',
  templateUrl: './driver-geotrips.component.html',
  styleUrls: ['./driver-geotrips.component.scss'],
})
export class DriverGeoTripsComponent implements OnInit {
  @Input() driver: Driver;

  availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  displayedColumns = [...DISPLAYEDCOLUMNS];
  appliedFilters: FilterOption[] = [];
  search = '';
  tableConfig = {
    hasHeader: true,
    pageSize: 25,
    service: CyberTripService,
    filterQuery: false,
    preferenceKey: 'driver-page-geotrips',
    preferencesEnabled: true,
    query: {},
    collectionTitle: this.translationService.instant('GeoTrips'),
    sortBy: 'created_at',
    sortDirection: 'asc',
    automaticallyHidePagination: false,
    hasNoResultsAction: false,
    menuOptions: [
      {
        name: this.translationService.instant('Edit'),
        action: 'edit',
        link: true,
        external: false,
      },
    ],
  };
  multipleActionDropdownOptions = [
    {
      name: this.translationService.instant('Export'),
      action: 'export',
      link: false,
      url: '',
    },
  ];
  @ViewChild('columnToggle', { static: false })
  columnToggle: ColumnToggleComponent;
  @ViewChild('fancyTable', { static: false }) fancyTable: FancyTableComponent;

  datePipe = new DatePipe('en-US');
  loading = true;
  errors = [];
  allSelected = false;
  allLoadedTrips: CyberTrip[] = [];
  selectedTrips: CyberTrip[] = [];
  sortParameter: string;
  filtersDialog: DriverTripFiltersDialogComponent;

  constructor(
    private cyberTripService: CyberTripService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.tableConfig.query = {
      ...this.tableConfig.query,
      driver: this.driver.id,
    };
  }

  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  openFilters(): void {
    const dialog = this.dialog.open(DriverTripFiltersDialogComponent, {
      width: '430px',
    });

    dialog.componentInstance.title =
      this.translationService.instant('Filter GeoTrips');
    dialog.componentInstance.callback = (res) => this.filterChanges(res);

    let startDate = get(
      _find(this.appliedFilters, { key: 'startDate' }),
      'values'
    );
    if (startDate && startDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.startDate = new Date(startDate);
    }
    let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
    if (endDate && endDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.endDate = new Date(endDate);
    }

    dialog.componentInstance.model.job = get(
      _find(this.appliedFilters, { key: 'job' }),
      'values'
    );
    dialog.componentInstance.model.project = get(
      _find(this.appliedFilters, { key: 'project' }),
      'values'
    );
    dialog.componentInstance.model.customer = get(
      _find(this.appliedFilters, { key: 'customer' }),
      'values'
    );
    dialog.componentInstance.model = Object.assign(
      dialog.componentInstance.model,
      this.appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values;
        return acc;
      }, {})
    );
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      job: 'jobevent__job',
      edited: 'edited',
      incomplete: 'completed',
      retake: 'retake_status',
      carrier: 'driver__carrier',
      duplicates: 'only_dupes',
      uninvoiced: 'uninvoiced',
      startDate: 'jobevent__shift1_start__gte',
      endDate: 'jobevent__shift1_start__lte',
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues =
        filterRes[key] && filterRes[key]['name']
          ? filterRes[key]['name']
          : values;
      if (typeof values === 'boolean') {
        if (key === 'incomplete' && values) {
          displayValues = values.toString();
          displayValues =
            displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
          let filterValue = (!values).toString();
          filterValue =
            filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (key === 'retake' && values) {
          values = 'requested';
          query[queryKeys[key]] = values;
        } else if (values) {
          values = values.toString();
          values = values.charAt(0).toUpperCase() + values.slice(1);
          query[queryKeys[key]] = values;
        }
        displayValues = values;
      } else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
        if (typeof values === 'string') {
          query[queryKeys[key]] = values;
        } else {
          query[queryKeys[key]] =
            filterRes[key] && filterRes[key].id
              ? filterRes[key].id
              : filterRes[key];
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let filter = new FilterOption({
        filterType:
          ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query,
      });
      if (filter.values === 'False' || !filter.values) {
        falseyFilters.push(filter);
      }
      return filter;
    });

    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

  getDuration(startDatetime: string, endDatetime: string): string {
    if (startDatetime && endDatetime) {
      let minuteDiff = moment(endDatetime).diff(
        moment(startDatetime),
        'minutes'
      );
      return Math.floor(minuteDiff / 60) + 'h ' + (minuteDiff % 60) + 'm';
    } else {
      return 'N/A';
    }
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedTrips();
        break;
    }
  }

  selector(event: {
    allSelected: boolean;
    selection: SelectionModel<CyberTrip>;
    exclusion: SelectionModel<CyberTrip>;
  }) {
    this.allSelected = event.allSelected;
    this.selectedTrips = this.allSelected
      ? (this.selectedTrips = this.allLoadedTrips.filter(
          (t) => !event.exclusion.selected.some((ex) => ex.id === t.id)
        ))
      : [...event.selection.selected];
  }

  onDataLoaded({ data }: { data: CyberTrip[] }) {
    this.allLoadedTrips = [...data];
  }

  exportSelectedTrips() {
    let { params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters,
      this.selectedTrips,
      [],
      this.allSelected,
      this.search
    );
    params = params.set('driver', this.driver.id);
    this.cyberTripService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'trips',
            buttonText: this.translationService.instant('Export Data to CSV'),
            callback: () => this.fancyTable.deselectAll(),
            fields,
            params,
            scope,
            service: this.cyberTripService,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }
}
