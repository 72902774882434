<form #tripSignatures="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Trip Signatures</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div class="checkin">
      <div class="form-content">
        <label translate class="loading-label"
          >Loading Signature Image(s)</label
        >

        <div class="signatures-container">
          <ng-container
            *ngIf="loadingSignatures | filterBy: 'isDeleted' as images"
          >
            <div class="ticket-photo" *ngFor="let image of images">
              <image-edit
                [image]="image"
                [options]="{ editable: true, light: true, showDelete: true }"
                (uploadData)="fileChange('loading', $event)"
              >
              </image-edit>
            </div>
            <image-edit
              *ngIf="images.length === 0"
              [image]="images[0]"
              [options]="{ editable: true, light: true }"
              (uploadData)="fileChange('loading', $event)"
            >
            </image-edit>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="checkin">
      <div class="form-content">
        <label class="unloading-label" translate
          >Unloading Signature Image</label
        >

        <div class="signatures-container">
          <ng-container
            *ngIf="unloadingSignatures | filterBy: 'isDeleted' as images"
          >
            <div class="ticket-photo" *ngFor="let image of images">
              <!-- <img [src]="images[0].file" /> -->
              <image-edit
                [image]="image"
                [options]="{ editable: true, light: true, showDelete: true }"
                (uploadData)="fileChange('unloading', $event)"
              >
              </image-edit>
            </div>
            <image-edit
              *ngIf="images.length === 0"
              [image]="images[0]"
              [options]="{ editable: true, light: true }"
              (uploadData)="fileChange('unloading', $event)"
            >
            </image-edit>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <button
    class="btn btn-primary save-btn-container"
    [ngClass]="{ loading: loading }"
    (click)="onSave()"
  >
    Save
  </button>
</form>
