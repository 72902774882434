import {
  Component, Input, Output, EventEmitter, OnInit, OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { find as _find } from 'lodash';
import { Subscription, Observable } from 'rxjs';

import { parseErrors } from '../api.service';
import { AuthenticationService } from '../index';
import { TagService } from '../../tags/tag.service';
import { UserService } from '../../users/user.service';
import { UserSerializer } from '../../users/user.serializer';
import { Tag } from '../../tags/tag';

@Component({
  selector: 'my-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: []
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() color = '#2C2C2D';
  @Input() textColor = '#FFFFFF';
  @Input() tagsBackgroundColor = 'rgba(255, 255, 255, 0.15)';
  @Input() logo = false;
  @Input() sidebar = false;
  @Input() userDropdown = true;
  @Input() menuButton = false;
  @Input() icon;
  @Input() iconLink;
  @Output() updatedMarkets = new EventEmitter();
  user;
  loggedIn = false;
  menuOptions = [];
  favoriteTags: any[] = [];
  tags: any[] = [];
  tagsReq: Subscription;
  tagsObservable: Observable<Tag[]>;
  errors;
  userTagSelect = new FormControl();
  userReq: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tagService: TagService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.favoriteTags = this.authenticationService.hasFavoriteTags() ? this.user.favoriteTags : [];
    this.loggedIn = this.authenticationService.isLoggedIn();
    this.menuOptions = [
      { name: 'Account', action: ['/settings', 'user', this.user && this.user.id, 'edit'], link: true },
      { name: 'Update Account', action: 'update-account' },
      { name: 'Logout', action: '/logout', link: true }
    ];
    if (this.user) { this.getTags(); }
  }

  ngOnDestroy() {
    if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
      this.tagsReq.unsubscribe();
    }
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }
  }

  compareTags(tag1, tag2): boolean {
    return tag1 && tag2 ? tag1.id === tag2.id : tag1 === tag2;
  }

  getTags(query = {}, next = false) {
    if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
      this.tagsReq.unsubscribe();
    }
    if (next) {
      this.tagsObservable = this.tagService.listNext();
    } else {
      this.tags = [];
      this.tagsObservable = this.tagService.list(query);
    }
    if (this.tagsObservable) {
      this.tagsReq = this.tagsObservable.subscribe(res => {
        this.tags.push.apply(this.tags, res);
      }, err => {
        this.errors = err;
      });
    }
  }

  selectTags(event) {
    let selectedTags = event && event.value ? event.value : [];
    let originalTags, modifiedTags = [];
    if (this.authenticationService.hasFavoriteTags()) {
      originalTags = this.user.favoriteTags.map(tag => { return tag.id; });
    }
    if (selectedTags.length > 0) {
      for (const tag of selectedTags) { modifiedTags.push(tag.id); }
    }

    if (this.user && (modifiedTags !== originalTags)) {
      this.user.favoriteTags = modifiedTags;
      this.userService.save({
        id: this.user.id, favoriteTags: this.user.favoriteTags
      }).subscribe((user) => {
        this.user = (new UserSerializer).fromJson(user);
        let currentUser = this.authenticationService.user();
        if (currentUser) {
          currentUser.name = this.user.firstName + ' ' + this.user.lastName;
          currentUser.username = this.user.email;
          currentUser.favoriteTags = [];
          user.favoriteTags.forEach(tagId => {
            currentUser.favoriteTags.push(_find(this.tags, { id: tagId }));
          });
          this.favoriteTags = currentUser.favoriteTags;
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        this.updatedMarkets.emit(true);
      }, (err) => {
        this.user.favoriteTags = originalTags;
        this.errors = parseErrors(err);
      });
    }
  }

  setClasses() {
    return {
      [this.color]: this.color,
      'with-logo': this.logo,
      'without-logo': !this.logo
    };
  }

  openIconLink() {
    if (this.iconLink && this.iconLink.includes('?')) {
      let route = this.iconLink.split('?')[0];
      let params = {};
      this.iconLink.split('?')[1].split('&').forEach(param => {
        let paramData = param.split('=');
        params[paramData[0]] = paramData[1];
      });
      this.router.navigate([route], { queryParams: params });
    } else {
      this.router.navigate([this.iconLink]);
    }
  }

  menuAction(action: string) {
    switch (action) {
      case 'update-account':
        this.userReq = this.userService.get(this.user.id).subscribe(user => {
          this.authenticationService.storeUser(
            Object.assign(this.user, {
              features: user.features,
              enabledFeatures: user.enabledFeatures,
              organization: user.organization
            })
          );
        });
        break;
    }
  }
}
