"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var notifications_report_1 = require("./notifications-report");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var NotificationsReportSerializer = /** @class */ (function () {
    function NotificationsReportSerializer() {
    }
    NotificationsReportSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var notificationReport = new notifications_report_1.NotificationsReport();
        if (!json) {
            return notificationReport;
        }
        notificationReport.id = json.id;
        notificationReport.name = json.name;
        notificationReport.lastPeriod = json.lastPeriod;
        notificationReport.currentPeriod = json.currentPeriod;
        if (notificationReport.lastPeriod) {
            notificationReport.percentageChange =
                (notificationReport.currentPeriod - notificationReport.lastPeriod) / notificationReport.lastPeriod;
        }
        return notificationReport;
    };
    NotificationsReportSerializer.prototype.toJson = function (notificationReport) {
        var json = {
            id: notificationReport.id,
            name: notificationReport.name,
            lastPeriod: notificationReport.lastPeriod,
            currentPeriod: notificationReport.currentPeriod
        };
        return decamelizeKeysDeep(json);
    };
    return NotificationsReportSerializer;
}());
exports.NotificationsReportSerializer = NotificationsReportSerializer;
