<trip-history-widget title="{{ 'Billing Details' | translate }}">
  <div class="ticket-details">
    <div *ngFor="let field of fields" class="billing-details__field">
      <div class="billing-details__field__values">
        <div class="billing-details__field__values__name">{{ field.name | translate }}</div>
        <div class="billing-details__field__values__value">
          <a *ngIf="field.url && field.value; else showNonURL" target="_blank" href="{{ field.url }}">
            {{ getValueString(field.value) }}
          </a>
          <ng-template #showNonURL>
            <div [style.color]="field.color">{{ getValueString(field.value) }}</div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="field.image" class="billing-details__field__image">
        <img src="{{ field.image }}" alt="{{ field.name | translate }}" />
      </div>
    </div>
  </div>
</trip-history-widget>
