<my-loader *ngIf="loading"></my-loader>

<ng-container *ngIf="device.desktop && !loading">
  <div class="container">
    <div class="header">
      <div class="driver-header">
        <div class="driver-header-label" translate>DRIVER AND TRUCK</div>
        <ng-content select="[display-controls]"></ng-content>
      </div>
      <ng-container *ngIf="controlState !== 'timeline'">
        <div class="en-route-one-header"><span translate>EN ROUTE</span></div>
        <div class="loading-header"><span translate>LOADING</span></div>
        <div class="en-route-two-header"><span translate>EN ROUTE</span></div>
        <div class="unloading-header"><span translate>UNLOADING</span></div>
      </ng-container>
    </div>

    <div *ngFor="let assignment of assignments" class="assignment-list-items" [ngClass]="{'timeline': controlState === 'timeline'}">
      <div class="dispatch-row">
        <div class="expand" [ngClass]="{
          'expand-disabled': ((controlState != 'gps' && !assignment.expandableTrips) ||
                              (controlState === 'gps' && !assignment.expandablePredictedTrips))
        }" (click)="expandDriver(assignment.driver)">
          <i *ngIf="!expandedDriver(assignment.driver)" class="icon-down-arrow"></i>
          <i *ngIf="expandedDriver(assignment.driver)" class="icon-up-arrow"></i>
        </div>
        <div class="driver">
          <div class="driver-photo">
            <span *ngIf="!assignment.driver.image">{{assignment.driver.name[0]}}</span>
            <img *ngIf="assignment.driver.image" [src]="assignment.driver.jobDetailImage"/>
          </div>
          <div class="truck-photo" [ngClass]="{empty: !assignment.truck}">
            <div class="image" *ngIf="assignment.truck">
              <img *ngIf="assignment.truck.image" [src]="assignment.truck.jobDetailImage"/>
              <i *ngIf="!assignment.truck.image" class="icon-fleet"></i>
            </div>
          </div>
          <div class="info">
            <driver-name [driver]="assignment.driver" [assignment]="assignment" (contextmenu)="openContextMenu($event, assignment.driver)"></driver-name>
            <div *ngIf="assignment.truck" class="truck" title="{{ assignment.truck.name }} {{ assignment.truck.truckTypeName }}">{{ assignment.truck.name }} {{ assignment.truck.truckTypeName }}</div>
            <div *ngIf="!assignment.truck" class="label-danger" translate>truck unassigned</div>
            <div *ngIf="assignment.driver && jobEvent">
              <a class="replay-link"
                [routerLink]="['/replay']"
                [queryParams]="{ date: jobEvent.shift1StartTimestamp | moment: 'YYYYMMDD', driver: assignment.driver.id }"
                translate
              >View Replay</a>
            </div>
            <!-- <div *ngIf="assignment.driver && (assignment.truck && !assignment.truck.leasedOrg)" class="organization">{{ assignment.driver.organizationName }}</div>
            <div *ngIf="assignment.driver && (assignment.truck && assignment.truck.leasedOrg)" class="leased">Leased</div>
            <div *ngIf="assignment.driver.previousJob" class="previous-job" title="{{ 'Previous Job:' | translate }} {{ assignment.driver.previousJob.name }}">{{ 'Previous Job:' | translate }} {{ assignment.driver.previousJob.name }}</div> -->
          </div>
          <div class="trip-time">
            <ng-container *ngIf="jobEvent && jobEvent.invoiceType === 'hour' && controlState === 'timeline'; else tripTime">
              <div *ngIf="assignment.driver && assignment.driver.punchCard" class="job-time">
                {{ assignment.driver.punchCard.startTimeTimestamp | formattedDuration:assignment.driver.punchCard.endTimeTimestamp }}
              </div>
            </ng-container>
            <ng-template #tripTime>
              <div *ngIf="assignment.driver && assignment.driver.trip && assignment.driver.isActive(assignment.driver.trip, 'any')" class="job-time">
                {{ assignment.driver.trip.startTimeTimestamp | formattedDuration:assignment.driver.trip.endTimeTimestamp }}
              </div>
            </ng-template>
            <div *ngIf="!assignment.requiresConfirmation && assignment.waiting" class="job-time waiting" translate>Waiting</div>
            <div *ngIf="assignment.requiresConfirmation && assignment.confirmed" class="job-time accepted" translate>Driver Accepted</div>
            <div *ngIf="assignment.requiresConfirmation && assignment.unconfirmed" class="job-time unconfirmed" translate>Unconfirmed</div>
          </div>
        </div>
        <ng-container *ngIf="controlState !== 'timeline'">
          <div class="en-route-one" [ngClass]="{active: controlState === 'gps' && jobEvent && jobEvent.hasGeofences && assignment.tripStats.lastValues.status === 'enroute_loading'}">
            <div *ngIf="controlState !== 'gps' && jobEvent" class="progress" [ngClass]="{active: assignment.driver.isActive(assignment.driver.trip, 'en-route-one'), complete: assignment.driver.isComplete(assignment.driver.trip, 'en-route-one')}"></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences" class="last"><i></i><span>{{ assignment.tripStats.lastValues.enrouteLoading }}</span></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences" class="avg"><i>avg: </i><span>{{ assignment.tripStats.averages.enrouteLoading }}</span></div>
          </div>
          <div class="loading" [ngClass]="{active: controlState === 'gps' && jobEvent && jobEvent.hasGeofences && assignment.tripStats.lastValues.status === 'loading'}">
            <div *ngIf="controlState !== 'gps' && jobEvent" class="progress" [ngClass]="{active: assignment.driver.isActive(assignment.driver.trip, 'loading'), complete: assignment.driver.isComplete(assignment.driver.trip, 'loading')}"></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences " class="last"><i></i><span>{{ assignment.tripStats.lastValues.loading }}</span> </div>
            <div *ngIf="controlState ==='gps' && jobEvent && jobEvent.hasGeofences" class="avg"><i>avg: </i> <span>{{ assignment.tripStats.averages.loading }}</span></div>
          </div>
          <div class="en-route-two" [ngClass]="{active: controlState === 'gps' && jobEvent && jobEvent.hasGeofences && (assignment.tripStats.lastValues.status === 'enroute_unloading' || assignment.tripStats.lastValues.status === 'loading_complete')}">
            <div *ngIf="controlState !== 'gps' && jobEvent" class="progress" [ngClass]="{active: assignment.driver.isActive(assignment.driver.trip, 'en-route-two'), complete: assignment.driver.isComplete(assignment.driver.trip, 'en-route-two')}"></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences" class="last"><i></i><span>{{ assignment.tripStats.lastValues.enrouteUnloading }}</span></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences" class="avg"><i>avg: </i><span>{{ assignment.tripStats.averages.enrouteUnloading }}</span></div>
          </div>
          <div class="unloading" [ngClass]="{active: controlState === 'gps' && jobEvent && jobEvent.hasGeofences && assignment.tripStats.lastValues.status === 'unloading'}">
            <div *ngIf="controlState !== 'gps' && jobEvent" class="progress" [ngClass]="{active: assignment.driver.isActive(assignment.driver.trip, 'unloading'), complete: assignment.driver.isComplete(assignment.driver.trip, 'unloading')}"></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences" class="last"><i></i><span>{{ assignment.tripStats.lastValues.unloading }}</span></div>
            <div *ngIf="controlState === 'gps' && jobEvent && jobEvent.hasGeofences" class="avg"><i>avg: </i><span>{{ assignment.tripStats.averages.unloading }}</span></div>
          </div>
        </ng-container>
      </div>

      <ruckit-trip-times [driver]="assignment.driver" [trips]="assignment.trips" *ngIf="expandedDriver(assignment.driver) && controlState != 'gps'"></ruckit-trip-times>
      <ruckit-trip-times [driver]="assignment.driver" [trips]="assignment.predictedTrips" *ngIf="expandedDriver(assignment.driver) && controlState === 'gps' && jobEvent.hasGeofences"></ruckit-trip-times>
    </div>
  </div>
  <timeline *ngIf="controlState === 'timeline'"
            [rows]="timelineData" [range]="timelineRange" [jobEvent]="jobEvent"
            [timelineConfig]="timelineConfig" [timeInterval]="30"></timeline>
</ng-container>

<div class="mobile-assignment-list" *ngIf="device.mobile || device.tablet">
  <ng-container *ngFor="let section of activeStatuses">
    <ng-container *ngIf="section.active === true">
      <div class="section-header">
        <h3 translate>{{ section.name }}</h3>
        <h4 translate>Status Time</h4>
      </div>
      <div *ngFor="let assignment of assignmentsOfStatus(section)" class="assignment-details"
        (click)="selectActiveAssignment(assignment)">
        <div class="driver">
          <div class="truck-photo" [ngClass]="{empty: !assignment.truck}">
            <div class="image" *ngIf="assignment.truck">
              <img *ngIf="assignment.truck.image" [src]="assignment.truck.jobDetailImage" />
              <i *ngIf="!assignment.truck.image" class="icon-fleet"></i>
            </div>
          </div>
          <div class="driver-photo">
            <span *ngIf="!assignment.driver.image">{{ assignment.driver.name[0] }}</span>
            <img *ngIf="assignment.driver.image" [src]="assignment.driver.jobDetailImage" />
          </div>
        </div>
        <div class="details">
          <h1>{{ assignment.truck.name }} - {{ assignment.truck.truckTypeName }}</h1>
          <h3>{{ assignment.driver.name }}</h3>
        </div>
        <div class="status">
          <h3>{{ assignment.tripStatusTime }}</h3>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>