import * as moment from 'moment';

const camelcaseKeysDeep = require('camelcase-keys-deep');

class TripStatsAverages {
  enrouteLoading: string;
  loading: string;
  enrouteUnloading: string;
  unloading: string;

  constructor(averages: any) {
    this.enrouteLoading = this.prettyTime(averages.enrouteLoading);
    this.loading = this.prettyTime(averages.loading);
    this.enrouteUnloading = this.prettyTime(averages.enrouteUnloading);
    this.unloading = this.prettyTime(averages.unloading);
  }

  prettyTime(value): string {
    if (value && value !== 0) {
      return moment.duration(parseInt(value, 10) | 0, 'seconds').format('m[ mins]');
    } else {
      return '--';
    }
  }
}

class TripStatsLastValues {
  enrouteLoading: string;
  loading: string;
  enrouteUnloading: string;
  unloading: string;
  status: string;

  constructor(lastValues: any) {
    this.enrouteLoading = this.prettyTime(lastValues.enrouteLoading);
    this.loading = this.prettyTime(lastValues.loading);
    this.enrouteUnloading = this.prettyTime(lastValues.enrouteUnloading);
    this.unloading = this.prettyTime(lastValues.unloading);
    this.status = lastValues.status;
  }

  prettyTime(value): string {
    if (value && value !== 0) {
      return moment.duration(parseInt(value, 10) | 0, 'seconds').format('m[ mins]');
    } else {
      return '--';
    }
  }
}

export class TripStats {
  count: number;
  averages: TripStatsAverages;
  lastValues: TripStatsLastValues;

  constructor(statusInfo: any) {
    statusInfo = camelcaseKeysDeep(statusInfo);
    this.count = statusInfo.count;
    this.averages = new TripStatsAverages(statusInfo.averages);
    this.lastValues = new TripStatsLastValues(statusInfo.lastValues);
  }
}
