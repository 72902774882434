"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".alert-panel[_ngcontent-%COMP%]{margin-right:20px;font-size:14px;z-index:1;display:flex;flex-direction:column;flex:1 1 auto;height:46px}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]{color:#fff;background:#262626;border-radius:6px;font-weight:600;align-self:center;text-align:center;padding:18px 22px;height:100%;display:flex;align-items:center;justify-content:space-between}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{display:flex;align-items:center}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{text-decoration:none;color:#015bc5;font-weight:600;font-size:18px;cursor:pointer;margin-right:20px}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:first-child{margin-left:40px}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:last-child{margin-right:-10px;margin-top:4px}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]   i.large[_ngcontent-%COMP%]{font-size:40px;color:#fff}.alert-panel[_ngcontent-%COMP%]   .panel-content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover{color:#9db9d4}"];
exports.styles = styles;
