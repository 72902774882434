"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var app_constants_1 = require("../app.constants");
var attachment_service_1 = require("../attachments/attachment.service");
var rxjs_1 = require("rxjs");
var shared_1 = require("../shared");
var ViewSignaturesDialogComponent = /** @class */ (function () {
    function ViewSignaturesDialogComponent(dialogRef, data, tripAttachmentService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.tripAttachmentService = tripAttachmentService;
        this.loading = false;
        this.errors = [];
        this.signatureImageType = app_constants_1.TRIPSIGNATUREIMAGETYPE;
        this.loadingSignatures = [];
        this.unloadingSignatures = [];
        this.imagesToDelete = [];
    }
    ViewSignaturesDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data && this.data.trip) {
            this.loadingSignatures = this.data.trip.loadingCheckin.attachments
                .filter(function (img) { return img.fileType === _this.signatureImageType; })
                .map(function (a) { return ({
                id: a.id,
                src: a.file,
            }); });
            this.unloadingSignatures = this.data.trip.unloadingCheckin.attachments
                .filter(function (img) { return img.fileType === _this.signatureImageType; })
                .map(function (a) { return ({
                id: a.id,
                src: a.file,
            }); });
        }
    };
    ViewSignaturesDialogComponent.prototype.fileChange = function (checkinType, img) {
        var _this = this;
        var images = checkinType === 'loading'
            ? this.loadingSignatures.slice() : this.unloadingSignatures.slice();
        if (img && img.id) {
            // if existing image
            if (img.isDeleted) {
                this.imagesToDelete.push(img);
            }
            else {
                images = images.map(function (i) {
                    return i.id === img.id
                        ? Object.assign(img, {
                            tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                        })
                        : i;
                });
            }
        }
        else {
            // if new image
            if (img.isNew && !img.isEdited) {
                images.push(Object.assign(img, {
                    tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
                }));
            }
            else {
                images = images.map(function (i) {
                    return i.id === img.id
                        ? Object.assign(img, {
                            tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                        })
                        : i;
                });
            }
        }
        if (checkinType === 'loading') {
            this.loadingSignatures = images;
        }
        else {
            this.unloadingSignatures = images;
        }
    };
    ViewSignaturesDialogComponent.prototype.parseImageOnChange = function (image) {
        if (image && (image.isNew || image.isEdited)) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                image.file = reader_1.result;
                image.src = reader_1.result;
            };
            reader_1.readAsDataURL(image);
            return image;
        }
        return image;
    };
    ViewSignaturesDialogComponent.prototype.onSave = function () {
        var _this = this;
        this.errors = null;
        this.loading = true;
        var requests$ = this.tripAttachmentService.combineTicketImagesAndAttachments(this.imagesToDelete, [], this.loadingSignatures, [], this.unloadingSignatures, this.data.trip.loadingCheckin.id, this.data.trip.unloadingCheckin.id);
        rxjs_1.forkJoin(requests$).subscribe(function (res) {
            _this.dialogRef.close();
            _this.data.callback();
            _this.loading = false;
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return ViewSignaturesDialogComponent;
}());
exports.ViewSignaturesDialogComponent = ViewSignaturesDialogComponent;
