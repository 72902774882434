"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var material_1 = require("@angular/material");
var connection_service_1 = require("./connection.service");
var connection_dialog_component_1 = require("./connection-dialog.component");
var invitation_service_1 = require("../invitation/invitation.service");
var ConnectionsComponent = /** @class */ (function () {
    function ConnectionsComponent(route, router, connectionService, invitationService, dialog) {
        this.route = route;
        this.router = router;
        this.connectionService = connectionService;
        this.invitationService = invitationService;
        this.dialog = dialog;
        this.connections = [];
        this.connectionType = true;
        this.noConnections = false;
        this.type = '';
        this.selectedType = {};
        this.loading = true;
        this.errors = [];
        this.search = '';
        this.actionDropdownOptions = [
            { id: '', name: 'All Connections', routerLink: '/connections' },
            { id: 'broker', name: 'Brokers', routerLink: '/connections/broker' },
            { id: 'carrier', name: 'Carriers', routerLink: '/connections/carrier' },
            { id: 'contractor', name: 'Contractors', routerLink: '/connections/contractor' }
        ];
        this.setSelectedRoute(this.actionDropdownOptions);
    }
    ConnectionsComponent.prototype.openDialog = function (view) {
        var dialog = this.dialog.open(connection_dialog_component_1.ConnectionDialogComponent, {
            width: '640px'
        });
        dialog.componentInstance.activeView = view;
    };
    ConnectionsComponent.prototype.changeSearch = function (term) {
        if (term === void 0) { term = ''; }
        this.search = term;
    };
    ConnectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route && this.route.params) {
            this.route.params.forEach(function (params) {
                _this.determineDataType(params['type']);
            });
        }
    };
    ConnectionsComponent.prototype.setSelectedOption = function (option) {
        this.router.navigate([option.routerLink]);
    };
    ConnectionsComponent.prototype.determineDataType = function (type) {
        this.type = type;
        switch (type) {
            case 'sent':
            case 'received':
                this.connectionType = false;
                break;
            default:
                this.connectionType = true;
                break;
        }
        this.selectedType = lodash_1.find(this.actionDropdownOptions, { id: type });
    };
    ConnectionsComponent.prototype.setSelectedRoute = function (options) {
        var currentRoute = this.router.url;
        this.actionDropdownOptions = options.map(function (option) {
            return option = option.routerLink === currentRoute ? Object.assign(option, { selected: true }) : option;
        });
    };
    return ConnectionsComponent;
}());
exports.ConnectionsComponent = ConnectionsComponent;
