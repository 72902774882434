import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// angular material
import {
  MatTableDataSource,
  MatTable,
  MatDialog,
  MatDialogRef,
} from '@angular/material';

// libraries
import { find as _find } from 'lodash';
import * as moment from 'moment';

// components
import { RuckitConfirmDialogComponent } from '../../shared/dialogs/index';
import { DriverNoteDialogComponent } from '../driver-note/driver-note-dialog.component';
import { EditTripDialogComponent } from '../../trips/edit-trip-dialog/edit-trip-dialog.component';

// models
import { Trip } from '../../trips/trip';
import { Organization } from '../../organizations/organization';
import { TripDecision } from '../../trips/trip-decision';
import { User } from '../../users/user';
import { JobEventShare } from '../../job-event-shares/job-event-share';

// services
import { TripDecisionService } from '../../trips/trip-decision.service';
import { TripService } from '../../trips/trip.service';
import { AuthenticationService } from '../../shared';

@Component({
  selector: 'driver-trips',
  templateUrl: './driver-trips.component.html',
  styleUrls: ['./driver-trips.component.scss'],
  providers: [TripDecisionService]
})
export class DriverTripsComponent implements OnInit, OnChanges {
  @Input() trips: Trip[] = [];
  @Input() jobEventShare: JobEventShare;
  @Input() invoiceRate: number;
  @Input() haulRate: number;
  @Input() invoiceUnit: string;
  @Input() haulUnit: string;
  @Input() hideApproved = false;
  @Input() showVoided = false;
  @Output() shouldAuditDecisions: EventEmitter<any> = new EventEmitter();
  @Output() updateTripData: EventEmitter<Trip[]> = new EventEmitter();
  organization: Organization;
  user: User;
  loading = true;
  errors = [];
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  displayedColumns: string[] = [
    'total-time', 'start-time', 'end-time', 'loading-ticket-number',
    'unloading-ticket-number', 'amount', 'invoice', 'expense', 'exported', 'note', 'approved-by', 'actions'
  ];
  @ViewChild('tripsTable', { static: false }) tripsTable: MatTable<Trip>;
  dataSource = new MatTableDataSource<Trip>();
  returnTo: string;
  confirmDialog: MatDialogRef<any>;

  constructor(
    public tripDecisionService: TripDecisionService,
    public tripService: TripService,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public dialog: MatDialog
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnInit() {
    this.organization = this.authenticationService.getOrganization();
    this.user = this.authenticationService.user();
    this.returnTo = this.location.path();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    let trips = this.trips.filter(v => v.jobEventShare.id === this.jobEventShare.id);
    trips = trips.filter(v => {
      if (this.hideApproved) {
        return !v.latestDecisionStatus || (v.latestDecisionStatus && v.latestDecisionStatus !== 'approved');
      } else {
        return true;
      }
    });
    trips = trips.filter(v => {
      return this.showVoided ? true : !v.void;
    });
    this.dataSource.data = trips;
    if (this.tripsTable) { this.tripsTable.renderRows(); }
    this.loading = false;
  }

  setSelectedBulkAction(action, trip: Trip) {
    switch (action) {
      case 'void':
        this.voidTrip(trip);
        break;
      case 'unvoid':
        this.unvoidTrip(trip);
        break;
    }
  }

  voidTrip(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Trip?',
      body: 'This trip will be marked as \'Void\' and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: true })
          .subscribe(
          (result) => {
            trip.void = true;
            trip.loading = false;
          },
          (err) => {
            this.errors = err;
            trip.loading = false;
          }
          );
      }
      this.confirmDialog = null;
    });
  }

  unvoidTrip(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unvoid Trip?',
      body: 'This trip will be unmarked as \'Void\' and will be visible for the Job.',
      close: 'Cancel',
      accept: 'Unvoid'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: false })
          .subscribe(
          () => {
            trip.void = false;
            trip.loading = false;
          },
          (err) => {
            this.errors = err;
            trip.loading = false;
          }
          );
      }
      this.confirmDialog = null;
    });
  }

  /**
   * Makes an edit to either the startTime or endTime field and saves that edit to the API trip record
   *
   * @param {Trip} trip The target trip
   * @param {string} field The time field we will apply the edit to
   * @param {any} value The edit value for the time adjustment
   */
   editTripTime(trip: Trip, field: string, value: string) {
    let saveData: any = { id: trip.id };
    let hasChanges = false;
    const newTime = moment(value);
    if (field === 'start' && !newTime.isSame(trip.startTimeTimestamp)) {
      hasChanges = true;
      saveData.startTime = value;
      if (newTime.isAfter(trip.endTimeTimestamp)) {
        saveData.endTime = value;
      }
    } else if (field === 'end' && !newTime.isSame(trip.endTimeTimestamp)) {
      hasChanges = true;
      saveData.endTime = value;
      if (newTime.isBefore(trip.startTimeTimestamp)) {
        saveData.startTime = value;
      }
    }
    if (!hasChanges) { return; }

    // Change detected, send to server
    trip.loading = true;
    this.tripService.save(saveData).subscribe(updates => {
      let tripUpdates: Trip = new Trip(updates);
      this.updateTrip(Object.assign(trip, {
        startTimeTimestamp: tripUpdates.startTimeTimestamp,
        endTimeTimestamp: tripUpdates.endTimeTimestamp,
        duration: tripUpdates.duration,
        completedTripDuration: tripUpdates.completedTripDuration
      }));
      trip.loading = false;
    }, (err) => {
      this.errors = err;
      trip.loading = false;
    });
  }

  editTrip(trip: Trip) {
    const dialog = this.dialog.open(EditTripDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.tripId = trip.id;
    dialog.componentInstance.callback = (edittedTrip: Trip) => {
      const queryParamRefreshPage: boolean = this.route.snapshot.queryParams['refreshPage'];

      if (edittedTrip && edittedTrip.driver) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            driver: edittedTrip.driver.id,
            'refreshPage': queryParamRefreshPage ? null : true,
          },
          queryParamsHandling: 'merge'
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            'refreshPage': queryParamRefreshPage ? null : true,
          },
          queryParamsHandling: 'merge'
        });
      }
    };
  }

  unapproveTrip(trip: Trip): void {
    this.approveTrip(trip, 'pending');
  }

  approveTrip(trip: Trip, decisionStatus = 'approved'): void {
    trip.loading = true;
    let data = {
      trip: trip, decisionStatus: decisionStatus, decidedBy: this.user,
      organization: this.organization, decidedAt: new Date().toISOString()
    };
    this.tripDecisionService.save(data).subscribe((decision: TripDecision) => {
      trip.latestDecision = decision.id;
      trip.latestDecisionStatus = decision.decisionStatus;
      trip.latestExportedAt = decision.exportedAt;
      trip.latestDecider = decision.decidedBy && decision.decidedBy.id;
      trip.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
      this.shouldAuditDecisions.emit();
      this.updateTrip(trip);
      trip.loading = false;
    }, (err) => {
      this.errors = err;
      trip.loading = false;
    });
  }

  toggleExportedFlag(trip: Trip, value: boolean) {
    trip.loading = true;
    let data = {
      id: trip.latestDecision,
      trip: trip,
      exportedAt: value ? new Date().toISOString() : null,
      organization: this.organization,
      decisionStatus: trip.latestDecisionStatus
    };
    this.tripDecisionService.save(data).subscribe((decision: TripDecision) => {
      trip.latestExportedAt = decision.exportedAt;
      this.updateTrip(trip);
      trip.loading = false;
    }, (err) => {
      this.errors = err;
      trip.loading = false;
    });
  }

  updateTrip(updates: Trip) {
    const updatedIndex = this.dataSource.data.findIndex(t => (t['id'] === updates['id']));
    this.dataSource.data[updatedIndex] = Object.assign(
      this.dataSource.data[updatedIndex], {
        duration: updates.duration,
        startTimeTimestamp: updates.startTimeTimestamp,
        endTimeTimestamp: updates.endTimeTimestamp,
        completedTripDuration: updates.completedTripDuration,
        latestExportedAt: updates.latestExportedAt,
        exported: !!updates.latestExportedAt
      }
    );
    if (this.tripsTable) { this.tripsTable.renderRows(); }
    this.updateTripData.emit(this.dataSource.data);
  }

  onDriverNoteClick(tripId: string, note: string) {
    const dialog = this.dialog.open(DriverNoteDialogComponent, {
      width: '430px',
    });
    dialog.componentInstance.tripId = tripId;
    dialog.componentInstance.note = note ? note : '';
    dialog
      .afterClosed()
      .subscribe(
        (data: { tripId: string; note: string }) => {
          if (data && data.note !== null && data.note !== undefined) {
            const updatedIndex = this.dataSource.data.findIndex(
              (t) => t['id'] === data.tripId
            );
            this.dataSource.data[updatedIndex] = Object.assign(
              this.dataSource.data[updatedIndex],
              {
                reviewerNotes: data.note,
              }
            );
            if (this.tripsTable) {
              this.tripsTable.renderRows();
            }
            this.updateTripData.emit(this.dataSource.data);
          }
        }
      );
  }
}
