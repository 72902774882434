import {
  Component, Output, OnInit, EventEmitter, Input, ViewChild, TemplateRef
} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { ConnectionService } from '../connections/connection.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';
import { RuckitAddEmployeeDialogComponent } from '../team/add-employee-dialog.component';
import { Connection } from '../connections/connection';

/**
 * Accounts Component: Displays the connections, ordered by organization name
 * available space.
 *
 * The Accounts table is rendered using the FancyTable component and includes
 * Name, Phone Number, Email, Unique Billing ID, and Actions columns
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <accounts></accounts>
 */
@Component({
  selector: 'accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  providers: []
})
export class AccountsComponent implements OnInit {
  /**
   * Defines the columns available to the FancyTable component with the settings
   * and configuration for each one of the columns.
   */
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'organization__name' },
    { key: 'phone-number', title: this.translationService.instant('Phone Number'), sortable: true, sortBy: 'organization__phone_number' },
    { key: 'email', title: this.translationService.instant('Email'), sortable: true, sortBy: 'organization__email' },
    { key: 'billing-id', title: this.translationService.instant('Billing Id'), sortable: true, sortBy: 'unique_billing_id' },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false }
  ];
  /**
   * Defines the keys for the columns to be displyed in the FancyTable component
   * by default in the order they are to be displayed.
   */
  @Input() displayedColumns = [
    'name', 'phone-number', 'email', 'billing-id', 'action'
  ];
  /**
   * Defines the filters available to the FancyTable component with the settings
   * and configuration for each one.
   */
  @Input() availableFilters = [];
  /**
   * Defines the filters that are automatically applied to the FancyTable
   * component along with the settings for each.
   */
  @Input() appliedFilters = [];
  /**
   * Stores and tracks the search term between components.
   */
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  /**
   * FancyTable configuration settings
   */
  accountTableConfig = {
    hasHeader: true,
    service: ConnectionService,
    preferenceKey: 'AccountsComponent-ConnectionService',
    query: {},
    collectionTitle: this.translationService.instant('Accounts'),
    noResultsText: this.translationService.instant('an account'),
    newRecordRoute: [],
    sortBy: 'organization__name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false },
      { name: this.translationService.instant('Invite'), action: 'invite', link: false, external: false },
    ]
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('accountTable', { static: false }) accountTable;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;
  loading = true;
  errors = [];
  accountType = 'leased-fleet';
  confirmDialog: MatDialogRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private connectionService: ConnectionService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  /**
  * Examines the route and query parameters to determine the Account Type and
  * persisted search value.
  *
  * If the account type equals `leased-fleet` the `leased_org: True` filter is
  * added to the query and the `newRecordRoute` is set to `/leased-fleet/new`.
  */
  ngOnInit() {
    if (this.route && this.route.queryParams) {
      this.route.queryParams.forEach((param: Params) => {
        this.loading = true;
        this.accountType = this.router.url.includes('accounts') ? 'accounts' : 'leased-fleet';
        if (param['search']) { this.search = param['search']; }
      });
    }

    if (this.accountType === 'leased-fleet' && this.router.url.includes('leased-fleet')) {
      this.accountTableConfig.query = { leased_org: 'True' };
      this.accountTableConfig.newRecordRoute = ['/leased-fleet/new'];
    } else {
      this.accountTableConfig.query = { customer_only: 'True', leased_org: 'False' };
      this.accountTableConfig.newRecordRoute = ['/accounts/new'];
    }
  }

  /**
  * Maps a menu link to a function that cooresponds with the desired behavior
  * and calls the function with the provided `account` object.
  *
  * @param {} name The action name to call
  * @param {} account The account object to perform the action on
  */
  menuAction(name, account: Connection): void {
    switch (name) {
      case 'edit':
        this.router.navigateByUrl(account.url('edit', this.location.path()));
        break;
      case 'remove':
        this.removeAccount(account);
        break;
      case 'invite':
        this.inviteCustomer(account);
        break;
    }
  }

  /**
  * Presents a modal dialog using {@link RuckitAddEmployeeDialogComponent} to
  * capture the email address an invitation will be sent to.
  *
  * @param {} account The account object to invite the email address to
  */
  inviteCustomer(account): void {
    const { id, email } = account.organization;

    this.dialog.open(RuckitAddEmployeeDialogComponent, {
      width: '444px',
      data: {
        type: 'customer',
        organizationId: id,
        email
      },
    });
  }

  /**
  * Presents a modal dialog using {@link RuckitConfirmDialogComponent} to verify
  * the removal action. Upon a successful removal, the table data is reloaded
  * from the API.
  *
  * @param {} account The account object to remove
  */
  removeAccount(account): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Account?'),
      body: this.translationService.instant('This account will be deleted and cannot be recovered.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.connectionService.disconnectConnection(account.id).subscribe((res) => {
          this.refreshTable();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  /**
  * Updates the URL with the given parameters and ensures that the `search`
  * value is persisted.
  *
  * @param {} params Query parameters to be included in the URL.
  */
  updateUrl(params): void {
    params['search'] = params['search'] ? params['search'] : this.search;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  /**
   * refresh/reload the account table on any action performed like
   * add, edit, remove
   */
  refreshTable(): void {
    this.accountTable.getRecords();
  }

  /**
   * Used for reseting the filters or when filter is changed
   *
   * @param {} appliedFilters
   */
  filtersModified(appliedFilters): void {
    if (!appliedFilters || !appliedFilters.length) {
      // Unimplemented
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
