import { CondensedPunchCard } from './condensed-punch-card';

export let CONDENSEDPUNCHCARD: CondensedPunchCard = <CondensedPunchCard> {
  id: '00000000-0000-0000-0000-000000000001',
  customer: 'B\&R Broker Group LLC',
  customerId: '00000000-0000-0000-0000-000000000001',
  driver: 'John Doe',
  driverId: '00000000-0000-0000-0000-000000000001',
  startTimeTimestamp: '2017-07-27T11:22:49Z',
  endTimeTimestamp: '2017-07-27T11:24:32Z',
  haulRate: '100.00',
  haulType: 'load',
  haulWeightUnit: 'ton',
  invoiceRate: '100.00',
  invoiceTotal: '100.00',
  invoiceType: 'load',
  invoiceWeightUnit: 'ton',
  job: 'Specialty Hauling Services Export',
  jobDisplayName: 'Specialty Hauling Services Export',
  jobId: '00000000-0000-0000-0000-000000000001',
  project: 'Specialty Project',
  projectId: '00000000-0000-0000-0000-000000000001',
  ticketImage: null,
  ticketNumber: '1234',
  trips: [],
  truck: 'T-1000',
  truckId: '00000000-0000-0000-0000-000000000001',
  truckType: 'Tri-axle',
  administrativeStatus: '',
  displayableStatus: false,
  haulTotal: '0.00',
  void: false,
  edited: false,
  loading: false,
  expenseId: null,
  expenseNumber: null,
  expenseSent: false,
  paystubId: null,
  paystubNumber: null,
  paystubSent: false,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  },
  jobDate: '2018-02-15',
  kind: 'invoice',
  invoiceWeightUnitDisplay: '',
  haulWeightUnitDisplay: '',
  pluralizedHaulRateUnit: (plural = true) => {
    return '';
  },
  pluralizedHaulWeightUnitDisplay: (plural = true) => {
    return '';
  },
  pluralizedInvoiceRateUnit: (plural = true) => {
    return '';
  },
  pluralizedInvoiceWeightUnitDisplay: (plural = true) => {
    return '';
  }
};
