import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import * as moment from 'moment-timezone';
import { find as _find, reject } from 'lodash';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import { parseErrors } from '../shared/api.service';

import { PunchCardService } from './punch-card.service';
import { JobService } from '../jobs/job.service';
import { OrganizationService } from '../organizations/organization.service';
import { JobEventService } from '../job-events/job-event.service';
import { DriverService } from '../drivers/driver.service';
import { TruckService } from '../trucks/truck.service';

import { Job } from '../jobs/job';
import { Carrier } from '../carriers/carrier';
import { Truck } from '../trucks/truck';
import { Driver } from '../drivers/driver';
import { JobEvent } from '../job-events/job-event';
import { PunchCard } from './punch-card';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-punch-card',
  templateUrl: './edit-punch-card.component.html',
  styleUrls: ['./edit-punch-card.component.scss']
})
export class EditPunchCardComponent implements OnInit, OnDestroy {
  punchCard: PunchCard;
  jobDates: Date[] = [];
  returnTo: string;
  errors = [];
  jobEventError: string = null;
  job: Job;
  carrier: Carrier;
  truck: Truck;
  driver: Driver;
  jobEvent: JobEvent;
  callback;

  jobReq: Subscription;
  punchCardReq: Subscription;
  daysReq: Subscription;
  jobsReq: Subscription;
  jobEventReq: Subscription;
  jobEventsReq: Subscription;
  trucksReq: Subscription;
  driversReq: Subscription;

  loading = false;
  daysLoading = false;
  jobEventsLoading = false;

  punchCardImage: any;

  @ViewChild('jobDropdown', { static: false }) jobDropdown;
  @ViewChild('driverDropdown', { static: false }) driverDropdown;
  @ViewChild('truckDropdown', { static: false }) truckDropdown;
  @ViewChild('carrierDropdown', { static: false }) carriersDropdown: RuckitDropdownComponent;
  @ViewChild('jobEventsDropdown', { static: false }) jobEventsDropdown;

  jobDropdownOptions = [];
  jobDropdownConfig = {
    nameProperty: 'displayName',
    searchable: true,
    group: true,
    groupBy: job => job.group,
    loadingOptions: false
  };
  driverDropdownOptions = [];
  driverDropdownConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  truckDropdownOptions = [];
  truckDropdownConfig = {
    nameProperty: 'displayName',
    searchable: true,
    loadingOptions: false
  };
  carrierDropdownConfig = {
    nameProperty: 'name',
    idProperty: 'carrier.id',
    selectText: this.translateService.instant('Select Carrier'),
    loadingText: this.translateService.instant('Loading Carriers...'),
    noResultsText: this.translateService.instant('No Carriers'),
    service: OrganizationService,
    serviceFunction: 'get',
    query: {
      exclude: null,
      carriers_for_jobevent: null,
    },
    includeFullObject: true,
    prefilledOptions: [],
  };
  jobEventsDropdownOptions = [];
  jobEventsDropdownConfig = {
    nameProperty: 'name',
    searchable: true,
    multiple: true,
    loadingOptions: false
  };
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private punchCardService: PunchCardService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    private organizationService: OrganizationService,
    private driverService: DriverService,
    private truckService: TruckService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loading = true;

    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.returnTo = result && result.qparams['returnTo'];
        if (result && result.params['id']) { this.getPunchCard(result.params['id']); }
      })
    );
  }

  ngOnDestroy() {
    if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
      this.jobReq.unsubscribe();
    }
    if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
      this.jobsReq.unsubscribe();
    }
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
    if (this.punchCardReq && typeof this.punchCardReq.unsubscribe === 'function') {
      this.punchCardReq.unsubscribe();
    }
    if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
      this.daysReq.unsubscribe();
    }
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
    if (this.trucksReq && typeof this.trucksReq.unsubscribe === 'function') {
      this.trucksReq.unsubscribe();
    }
    if (this.punchCardReq && typeof this.punchCardReq.unsubscribe() === 'function') {
      this.punchCardReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getPunchCard(id): void {
    if (id) {
      if (this.punchCardReq && typeof this.punchCardReq.unsubscribe() === 'function') {
        this.punchCardReq.unsubscribe();
      }

      this.punchCardReq = this.punchCardService.getPunchCard(id).subscribe(punchCard => {
        this.punchCard = punchCard;
        if (punchCard.ticketImage) {
          this.punchCardImage = {...this.punchCardImage, src: punchCard.ticketImage};
        }

        this.jobEvent = new JobEvent({ id: this.punchCard.jobEvent.id });
        this.carrier = this.punchCard.driver && this.punchCard.driver.carrier;
        this.carrierDropdownConfig.prefilledOptions.push({
          ...this.punchCard.driver.carrier,
        });
        this.carrierDropdownConfig.query = {
          ...this.carrierDropdownConfig.query,
          carriers_for_jobevent: punchCard.jobEvent.id,
        }
        this.getJob(this.punchCard.jobEvent.job.id);
        this.getJobs();
        this.getTrucks();
        this.getDrivers();
      }, err => {
        this.errors = err;
      });
    }
  }

  submitPunchCard(): void {
    this.loading = true;
    if (this.punchCardImage && this.punchCardImage.name) {
      this.submitWithImage();
    } else {
      this.submitWithoutImage();
    }
  }

  submitWithoutImage(): void {
    this.errors = [];
    this.punchCardService.save(
      this.punchCard
    ).subscribe(() => {
      this.loading = false;
      this.router.navigate([this.returnTo]);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  submitWithImage(): void {
    this.errors = [];
    this.punchCardService.saveWithFile(
      this.punchCard, this.punchCardImage
    ).subscribe(() => {
      this.loading = false;
      this.router.navigate([this.returnTo]);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  onStartDateChanged(dates: Date[]): void {
    this.punchCard.startDate = dates[0];
  }

  onEndDateChanged(dates: Date[]): void {
    this.punchCard.endDate = dates[0];
  }

  onStartTimeChange(value: any) {
    const time = value.target.value;
    this.punchCard.startTime = time;
  }

  onEndTimeChange(value: any) {
    const time = value.target.value;
    this.punchCard.endTime = time;
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        this.job = e;
        this.getJobDays(this.job && this.job.id);
        this.getJobEvents({}, false, true);
        break;
      case 'jobevent':
        this.jobEvent = e;
        this.punchCard.jobEvent = e;
        const query = {
          ...this.carrierDropdownConfig.query,
          carriers_for_jobevent: e.id,
        };

        this.carriersDropdown.config.query = query;
        this.carriersDropdown.getRecords(query);
        break;
      case 'driver':
        this.driver = e;
        break;
      case 'truck':
        this.truck = e;
        break;
      case 'carrier':
        this.carrier = e;
        this.getDrivers();
        this.getTrucks();
        break;
    }
    this.punchCard[filterType] = e;
  }

  getJob(id: string): void {
    this.jobDropdownOptions = [];
    if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
      this.jobReq.unsubscribe();
    }

    this.jobReq = this.jobService.get(id).subscribe(job => {
      let _job = _find(this.jobDropdownOptions, { id: job && job.id });
      if (_job) {
        this.jobDropdownOptions = reject(this.jobDropdownOptions, _job);
      }
      this.jobDropdownOptions.unshift(job);
      this.job = job;
      this.jobDropdown.selectedOption = this.job;
      this.getJobDays(this.job && this.job.id);
      this.getJobEvent(this.punchCard.jobEvent.id);
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  getJobs(query = {}): void {
    this.jobDropdownOptions = [];
    this.jobDropdownConfig.loadingOptions = true;
    if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
      this.jobsReq.unsubscribe();
    }

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    this.jobsReq = this.jobService.list({
      ordering: 'project__name,name,start_date',
      serializer: 'JobGroupedDropdown',
      ...query
    }).subscribe(jobs => {
      this.jobDropdownOptions = this.jobDropdownOptions.concat(jobs);
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
      this.jobDropdownConfig.loadingOptions = false;
    });
  }

  getJobEvent(id: string): void {
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }

    this.jobEventReq = this.jobEventService.getJobEvent(id).subscribe(jobEvent => {
      let _jobEvent = _find(this.jobEventsDropdownOptions, { id: jobEvent && jobEvent.id });
      if (_jobEvent) {
        this.jobEventsDropdownOptions = reject(this.jobEventsDropdownOptions, _jobEvent);
      }
      this.jobEventsDropdownOptions.unshift(jobEvent);
      this.jobEvent = jobEvent;
      this.jobEventsDropdown.selectedOption = this.jobEvent;
      this.getJobs();
      this.getJobEvents();
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  getJobEvents(query = {}, append = true, clearSelection = false): void {
    this.jobEventsLoading = true;
    this.jobEventError = null;
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
    if (clearSelection) {
      this.jobEventsDropdown.selectedOption = null;
      this.jobEvent = null;
      this.punchCard.jobEvent = null;
    }

    this.jobEventsReq = this.jobEventService.getJobEvents({
      job: this.job && this.job.id,
      ordering: '-shift1_start',
      page_size: 25,
      ...query
    }).subscribe(jobEvents => {
      if (jobEvents && jobEvents.length) {
        if (append) {
          this.jobEventsDropdownOptions = this.jobEventsDropdownOptions.concat(jobEvents);
        } else {
          this.jobEventsDropdownOptions = jobEvents;
        }
      } else {
        this.jobEventError = 'No Job Events found for this job!';
      }
      this.jobEventsLoading = false;
    }, err => {
      this.errors = err;
      this.jobEventsLoading = false;
    }, () => {
      this.loading = false;
    });
  }

  getJobDays(jobId: string): void {
    if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
      this.daysReq.unsubscribe();
    }

    this.daysLoading = true;
    this.daysReq = this.jobService.getDays(jobId).subscribe(days => {
      this.jobDates = days.map(day => moment(day).toDate());
      this.daysLoading = false;
    }, err => {
      this.errors = err;
      this.daysLoading = false;
    }, () => {
      this.loading = false;
    });
  }

  getDrivers(query = {}): void {
    this.driverDropdownOptions = [];
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }

    this.driversReq = this.driverService.list({
      ordering: 'name',
      carrier: this.carrier && this.carrier.id,
      ...query
    }).subscribe(drivers => {
      this.driverDropdownOptions = drivers;
      this.driver = _find(this.driverDropdownOptions, {id: this.punchCard.driver.id});
      if (this.driver) {
        this.driverDropdownOptions = reject(this.driverDropdownOptions, this.driver);
        this.driverDropdownOptions.unshift(this.driver);
        if (this.driverDropdown) {
          this.driverDropdown.selectedOption = this.driver;
        }
      } else if (this.punchCard.driver) {
        let _driver = { name: this.punchCard.driver.name, id: this.punchCard.driver.id };
        this.driverDropdownOptions.unshift(_driver);
        if (this.driverDropdown) {
          this.driverDropdown.selectedOption = _driver;
        }
      }
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  getTrucks(query = {}): void {
    this.truckDropdownOptions = [];
    if (this.trucksReq && typeof this.trucksReq.unsubscribe === 'function') {
      this.trucksReq.unsubscribe();
    }

    this.trucksReq = this.truckService.list({
      ordering: 'name',
      carrier: this.carrier && this.carrier.id,
      shared_jobevent: this.jobEvent && this.jobEvent.id,
      ...query
    }).subscribe(trucks => {
      this.truckDropdownOptions = trucks;
      this.truck = _find(this.truckDropdownOptions, { id: this.punchCard.truck.id });

      if (this.truck) {
        this.truckDropdownOptions = reject(this.truckDropdownOptions, this.truck);
        this.truckDropdownOptions.unshift(this.truck);
        if (this.truckDropdown) {
          this.truckDropdown.selectedOption = this.truck;
        }
      } else if (this.punchCard.truck) {
        let _truck = { displayName: this.punchCard.truck.displayName, id: this.punchCard.truck.id };
        this.truckDropdownOptions.unshift(_truck);
        if (this.truckDropdown) {
          this.truckDropdown.selectedOption = _truck;
        }
      }
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  dropdownSearch(term: string, type: string): void {
    switch (type) {
      case 'job':
        this.getJobs({ search: term });
        break;
      case 'jobEvents':
        this.getJobEvents({ search: term });
        break;
      case 'truck':
        this.getTrucks({ search: term });
        break;
      case 'driver':
        this.getDrivers({ search: term });
        break;
      case 'carrier':
        break;
    }
  }

  dropdownNextPage(e, type: string): void {
    let config, service, options;

    switch (type) {
      case 'job':
        config = this.jobDropdownConfig;
        service = this.jobService;
        options = this.jobDropdownOptions;
        break;
      case 'jobEvents':
        config = this.jobEventsDropdownConfig;
        service = this.jobEventService;
        options = this.jobEventsDropdownOptions;
        break;
      case 'driver':
        config = this.driverDropdownConfig;
        service = this.driverService;
        options = this.driverDropdownOptions;
        break;
      case 'truck':
        config = this.truckDropdownConfig;
        service = this.truckService;
        options = this.truckDropdownOptions;
        break;
      case 'carrier':
        break;
    }

    if (!config.loadingOptions) {
      let o;
      if (type === 'driver' || type === 'truck' || type === 'job' || type === 'jobEvents') {
        o = service && service.listNext();
      } else {
        o = service && service.getNext();
      }
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'job':
              this.jobDropdownOptions = this.jobDropdownOptions.concat(results);
              break;
            case 'jobEvents':
              this.jobEventsDropdownOptions = this.jobEventsDropdownOptions.concat(results);
              break;
            case 'driver':
              this.driverDropdownOptions = this.driverDropdownOptions.concat(results);
              break;
            case 'truck':
              this.truckDropdownOptions = this.truckDropdownOptions.concat(results);
              break;
            case 'carrier':
              break;
              case 'jobEvents':
              this.jobEventsDropdownOptions = this.jobEventsDropdownOptions.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  fileChange(e): void {
    this.punchCardImage = e;
  }
}
