import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../shared/resource.service';
import { UnitOfMeasure } from './unit-of-measure';
import { UnitOfMeasureSerializer } from './unit-of-measure.serializer';

@Injectable()
export class UnitsOfMeasureService extends ResourceService<UnitOfMeasure> {
  constructor(http: HttpClient) {
    super(http, 'units-of-measure/', new UnitOfMeasureSerializer());
  }
}
