"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cd-autocomplete.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./cd-autocomplete.component");
var styles_CdAutocompleteComponent = [i0.styles];
var RenderType_CdAutocompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CdAutocompleteComponent, data: {} });
exports.RenderType_CdAutocompleteComponent = RenderType_CdAutocompleteComponent;
function View_CdAutocompleteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addValue(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-circle-thin select-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "item-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "item-detail"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(3, "", ((_co.lastSelected === _v.context.$implicit) ? "selected" : ""), " ", ((_co.lastSelected.name && (_co.lastSelected !== _v.context.$implicit)) ? "unselected" : ""), " ", (_v.context.$implicit.noResults ? "no-results" : ""), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.detail; _ck(_v, 4, 0, currVal_2); }); }
function View_CdAutocompleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], [[4, "top", null], [4, "left", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CdAutocompleteComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.top; var currVal_1 = _co.left; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CdAutocompleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CdAutocompleteComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.show && (_co.data.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_CdAutocompleteComponent_0 = View_CdAutocompleteComponent_0;
function View_CdAutocompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cd-autocomplete", [], null, null, null, View_CdAutocompleteComponent_0, RenderType_CdAutocompleteComponent)), i1.ɵdid(1, 49152, null, 0, i3.CdAutocompleteComponent, [], null, null)], null, null); }
exports.View_CdAutocompleteComponent_Host_0 = View_CdAutocompleteComponent_Host_0;
var CdAutocompleteComponentNgFactory = i1.ɵccf("cd-autocomplete", i3.CdAutocompleteComponent, View_CdAutocompleteComponent_Host_0, { data: "data", hide: "hide" }, { onChanged: "onChanged" }, []);
exports.CdAutocompleteComponentNgFactory = CdAutocompleteComponentNgFactory;
