"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var product_type_1 = require("./product-type");
var organization_1 = require("../organizations/organization");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ProductTypeSerializer = /** @class */ (function () {
    function ProductTypeSerializer() {
    }
    /**
    * @param  {any} json
    * @returns ProductType
    */
    ProductTypeSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var productType = new product_type_1.ProductType();
        if (!json) {
            return productType;
        }
        productType.id = json.id;
        productType.createdAt = json.createdAt;
        productType.lastModified = json.lastModified;
        productType.name = json.name;
        productType.parameterizedName = json.name && json.name.toLowerCase();
        if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
            productType.ownerOrganization = new organization_1.Organization(json.ownerOrganization);
        }
        else {
            productType.ownerOrganization = new organization_1.Organization({ id: json.ownerOrganization });
        }
        return productType;
    };
    /**
     * @param  {ProductType} productType
     * @returns any
     */
    ProductTypeSerializer.prototype.toJson = function (productType) {
        var json = {
            id: productType.id,
            name: productType.name
        };
        return decamelizeKeysDeep(json);
    };
    return ProductTypeSerializer;
}());
exports.ProductTypeSerializer = ProductTypeSerializer;
