<div class="app-content fleet-container">
  <my-toolbar
    *ngIf="view"
    title="{{ 'Fleet ' + view.title | titlecase | translate }}"
    (updatedMarkets)="updatedMarkets()"
  ></my-toolbar>

  <ng-container *ngIf="view && view.stub === 'drivers'; else truckView">
    <drivers
      #driverTableWrapper
      [query]="query"
      [customClasses]="customClasses"
      (viewArchive)="viewArchive($event)"
      class="drivers"
    ></drivers>
  </ng-container>
  <ng-template #truckView>
    <trucks
      #truckTableWrapper
      [query]="query"
      [customClasses]="customClasses"
      (viewArchive)="viewArchive($event)"
      class="trucks with-action-bar"
    ></trucks>
  </ng-template>
</div>
