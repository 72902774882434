<a mat-icon-button class="column-toggle-button" [matMenuTriggerFor]="columnMenu" (menuOpened)="icon='close'" (menuClosed)="icon='settings'">
  <mat-icon>{{ icon }}</mat-icon>
</a>
<mat-menu #columnMenu="matMenu" backdropClass="column-toggle-menu" [overlapTrigger]="false">
  <form [formGroup]="form" (click)="$event.stopPropagation()">
    <div class="header">
      <mat-checkbox [formControl]="allSelected" (change)="onSelectAllChange()">All</mat-checkbox>
    </div>

    <div *ngFor="let column of columns.controls; let i=index" class="column"
      [ngClass]="{ 'drag-over': column.dragOver }"
      (dragenter)="column.dragOver=true"
      (dragleave)="column.dragOver=false"
      (dragend)="onDragEnd($event, availableColumns[i])"
      ondragover="event.preventDefault()"
      (drop)="onDrop(column, availableColumns[i])">
      <mat-checkbox [formControl]="column" (change)="onColumnChange(column, availableColumns[i])" [disabled]="availableColumns[i].key === 'select' || availableColumns[i].key === 'updates' || availableColumns[i].key === 'expand' || availableColumns[i].key ==='actions'">
        {{ availableColumns[i].title || availableColumns[i].key | titleCase }}
      </mat-checkbox>
      <mat-icon *ngIf="column.value && availableColumns[i].key !== 'select' && availableColumns[i].key !== 'updates' && availableColumns[i].key !== 'expand' && availableColumns[i].key !=='actions'" class="drag-indicator" (mousedown)="onHandleMouseDown($event)">density_small</mat-icon>
    </div>
  </form>
</mat-menu>
