"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var SplitButtonComponent = /** @class */ (function () {
    function SplitButtonComponent() {
        this.title = 'Actions';
        this.disabled = false;
        this.primaryRouterLink = null;
        this.icon = 'keyboard_arrow_down';
        this.dropdownEnabled = true;
        this.buttonClasses = '';
        this.controlButtonClasses = '';
        this.primaryActionOpenMenu = false;
        this.onPrimaryAction = new core_1.EventEmitter();
    }
    SplitButtonComponent.prototype.ngOnInit = function () { };
    SplitButtonComponent.prototype.primaryActionClicked = function (event) {
        if (this.primaryActionOpenMenu) {
            this.splitMenuTrigger.openMenu();
        }
        else {
            this.onPrimaryAction.emit(event);
        }
    };
    return SplitButtonComponent;
}());
exports.SplitButtonComponent = SplitButtonComponent;
