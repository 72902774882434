<mat-table #punchCardsTable *ngIf="dataSource.data && dataSource.data.length" [dataSource]="dataSource">
  <ng-container matColumnDef="start-time">
    <mat-header-cell *matHeaderCellDef translate>START TIME</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <date-time-picker-inline [value]="row.startTimeTimestamp"
        (dateTimeChange)="editPunchCardTime(row, 'start', $event)"></date-time-picker-inline>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="end-time">
    <mat-header-cell *matHeaderCellDef translate>END TIME</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <date-time-picker-inline [value]="row.endTimeTimestamp"
        (dateTimeChange)="editPunchCardTime(row, 'end', $event)"></date-time-picker-inline>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total-time">
    <mat-header-cell *matHeaderCellDef translate>TOTAL TIME</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.duration || '&mdash;' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="loading-ticket-number">
    <mat-header-cell *matHeaderCellDef translate>TICKET#</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.ticketNumber"><i class="icon-ticket"></i></span>
      <span class="ticket-text">{{ row.ticketNumber || '&mdash;' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="approved-by">
    <mat-header-cell *matHeaderCellDef translate>APPROVED BY</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container *ngIf="row.latestDecisionStatus === 'approved'">
        {{ row.latestDeciderName }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef translate>APPROVED</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div *ngIf="row.loading" [ngClass]="{loading: row.loading}">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <div *ngIf="!row.loading" [ngClass]="{
        approved: row.latestDecisionStatus === 'approved',
        unapproved: !row.latestDecisionStatus || row.latestDecisionStatus !== 'approved'
      }">
        <span class="icon-success" *ngIf="row.endTimeTimestamp"></span>
        <ng-container *ngIf="!row.endTimeTimestamp">
          <span class="icon-info" title="{{ 'End Time must be set before approving' | translate }}"></span> {{ 'Set End Time' | translate }}
        </ng-container>
      </div>
      <button *ngIf="row.endTimeTimestamp && (!row.latestDecisionStatus || row.latestDecisionStatus !== 'approved')"
        class="btn btn-primary" [ngClass]="{loading: row.loading}"
        (click)="approvePunchCard(row)" translate
      >
        Approve
      </button>
      <button *ngIf="row.endTimeTimestamp && (row.latestDecisionStatus && row.latestDecisionStatus === 'approved')"
        class="btn btn-delete" [ngClass]="{loading: row.loading}"
        (click)="unapprovePunchCard(row)" translate
      >
        Unapprove
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<div class="no-results" *ngIf="!dataSource.data || !dataSource.data.length">
  <ng-container *ngIf="hideApproved && !loading" translate>
    All Punch Cards for this job have been approved.
  </ng-container>
  <ng-container *ngIf="!hideApproved && !loading" translate>
    No Punch Cards
  </ng-container>
  <ng-container *ngIf="loading" translate>
    Loading Punch Cards
  </ng-container>
</div>
