<div class="app-content">
  <my-toolbar title="{{ 'Settings' | translate }}"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <div class="subnav">
        <div class="links" *ngIf="area === 'organization' && organization">
          <a [routerLink]="['/settings', 'organization', organization && organization.id, 'edit']" routerLinkActive="active" class="organization-details" translate>Organization Details</a>
          <a [routerLink]="['/settings', 'organization', organization && organization.id, 'permissions']" routerLinkActive="active" class="organization-permissions" translate>Permission Settings</a>
          <a [routerLink]="['/settings', 'organization', organization && organization.id, 'markets']" routerLinkActive="active" class="organization-markets" translate>Markets</a>
          <a [routerLink]="['/settings', 'organization', organization && organization.id, 'fields']" routerLinkActive="active" class="organization-custom-fields" translate>Custom Fields</a>
        </div>
        <div class="links" *ngIf="area === 'user' && user">
          <a [routerLink]="['/settings', 'user', user && user.id, 'edit']" routerLinkActive="active" translate>Profile</a>
          <a [routerLink]="['/settings', 'user', user && user.id, 'preferences']" routerLinkActive="active" translate>Preferences</a>
          <a [routerLink]="['/settings', 'user', user && user.id, 'work-orders']" routerLinkActive="active" *ngIf="user.organization && user.organization.hasWorkOrder" translate>Work Orders</a>
        </div>
      </div>
    </div>
    <div class="right-actions">
      <button id="add-market-button" *ngIf="tab === 'markets'" class="btn btn-primary" (click)="openAddOrganizationMarket()">
        <span class="icon-plus"></span> {{ 'Add a Market' | translate }}</button>
    </div>
  </action-bar>

  <div class="settings" *ngIf="area === 'organization' && organization">
    <ruckit-edit-organization *ngIf="tab === 'edit'" [organization]=organization></ruckit-edit-organization>
    <ruckit-edit-organization-permissions *ngIf="tab === 'permissions'" [organization]=organization></ruckit-edit-organization-permissions>
    <ruckit-edit-organization-markets #editOrganizationMarkets *ngIf="tab === 'markets'" [organization]=organization></ruckit-edit-organization-markets>
    <ruckit-edit-custom-fields *ngIf="tab === 'fields'" [organization]=organization></ruckit-edit-custom-fields>
  </div>

  <div class="settings" *ngIf="area === 'user' && user">
    <ruckit-edit-user *ngIf="tab === 'edit'" [user]=user></ruckit-edit-user>
    <ruckit-edit-user-notifications *ngIf="tab === 'preferences'" [user]=user></ruckit-edit-user-notifications>
    <ruckit-edit-user-preferences *ngIf="tab === 'preferences'" [user]=user></ruckit-edit-user-preferences>
    <ruckit-edit-user-work-order-settings *ngIf="tab === 'work-orders'" [user]=user></ruckit-edit-user-work-order-settings>
  </div>
</div>
