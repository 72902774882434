<div class="completed-punch-cards">
  <h3>Completed Punch Cards</h3>
  <div *ngFor="let punchCard of punchCards">
    <div class="punch-card">
      <div class="driver">
        <div class="driver-photo">
          <span *ngIf="!punchCard.driver.image">{{punchCard.driver.name[0]}}</span>
          <img *ngIf="punchCard.driver.image" [src]="punchCard.driver.jobDetailImage" />
        </div>
        <div class="driver-info">
          <div class="info">
            <driver-name [driver]="punchCard.driver"></driver-name>
            <div class="phone">{{ punchCard.driver.profile.phoneNumber }}</div>
          </div>
        </div>
        <div class="truck-photo" [ngClass]="{empty: ! punchCard.driver.truck}">
          <div class="image" *ngIf="punchCard.driver.truck">
            <img *ngIf="punchCard.driver.truck.image" [src]="punchCard.driver.truck.jobDetailImage" />
            <i *ngIf="! punchCard.driver.truck.image" class="icon-fleet"></i>
          </div>
        </div>
        <div class="info">
          <div class="name">{{ punchCard.truck.name }}</div>
          <div class="phone">{{ punchCard.truck.truckTypeName }}</div>
        </div>
        <div class="punch-card-time info">
          <div class="length">{{ punchCard.duration }}</div>
          <div class="times">{{ punchCard.startTime }} - {{ punchCard.endTime }}</div>
        </div>
        <div class="ticket-number">
          <div class="value" [ngClass]="{'missing-required': !punchCard.ticketNumber}">
            {{ punchCard.ticketNumber || '&nbsp;' }}
          </div>
          <div class="label" translate>Ticket #</div>
        </div>
        <div class="ticket-photo" [ngClass]="{'no-tickets': !punchCard.hasTicketImage}">
          <a [routerLink]="punchCard.url('edit')" [queryParams]="{ returnTo: '/jobs/' + this.jobEvent.job.id + '/' + this.jobEvent.id }">
            <div class="ticket-photo" *ngIf="!punchCard.hasTicketImage">
              <div class="value" class="missing-required">&nbsp;</div>
              <div class="label" translate>No Ticket</div>
            </div>
            <div class="ticket-photo" *ngIf="punchCard.hasTicketImage">
              <span>
                <i class="icon-ticket"></i>
              </span>
            </div>
          </a>
        </div>
        <div class="punch-card-actions">
          <action-menu icon="icon-more">
            <ng-container *ngFor="let option of punchCard.filterOptions(menuOptions)">
              <a *ngIf="option.link" mat-menu-item [routerLink]="punchCard.url(option.action)" [queryParams]="{ returnTo: '/jobs/' + this.jobEvent.job.id + '/' + this.jobEvent.id }">{{ option.name }}</a>
              <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, punchCard)">{{ option.name }}</a>
            </ng-container>
          </action-menu>
        </div>
      </div>
    </div>
  </div>

  <no-results itemName="Completed Punch Cards"
              [results]="punchCards && punchCards.length" [loading]="loading"
              [styles]="{'min-height': '180px', 'padding-top': '15px'}"
              [bordered]="true" [hasAction]="false" [hasAddText]="false"></no-results>

  <my-loader *ngIf="loading" [ngClass]="{'full-height': punchCards.length === 0}"></my-loader>
</div>
