import { Job } from '../jobs/job';
import { Organization } from '../organizations/organization';
import { CondensedTrip } from '../trips/condensed-trip';
import { CondensedPunchCard } from '../punch-cards/condensed-punch-card';
import { remove, find, clone } from 'lodash';
import * as moment from 'moment';
import { JobSerializer } from '../jobs/job.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Invoice {
  id: string;
  number: string;
  amount: string;
  subtotal: string;
  expenseAmount: string;
  surchargeAmount: string;
  tripsAmount: string;
  tripsExpenseAmount: string;
  punchcardsAmount: string;
  punchcardsExpenseAmount: string;
  haulTotal: string;
  ownerOrganization: Organization;
  customerOrganization: Organization;
  job: Job;
  sent: boolean;
  draft: boolean;
  sentTime: string;
  sentBy: string;
  sendable: boolean;
  expensed: boolean;
  sentTrips: CondensedTrip[];
  sentPunchCards: CondensedPunchCard[];
  billFromContactEmail: string;
  billFromCcEmails: string;
  billFromAddress: string;
  billToContactEmail: string;
  billToCcEmails: string;
  billToAddress: string;
  createdAt: string;
  remittanceInfo: string;
  earliestBillableItemTime: string;
  latestBillableItemTime: string;
  startTime: string;
  endTime: string;
  pdf: string;
  html: string;
  selected = false;
  invoiceDate: string;
  kind: string;

  // Missing Fields
  startDate: string; // Minimum Trip / Punchcard Start Date
  endDate: string;  // Maximum Trip / Punchcard Start Date

  constructor(invoiceInfo: any) {
    invoiceInfo = camelcaseKeysDeep(invoiceInfo);

    this.id = invoiceInfo.id;
    this.number = invoiceInfo.number;
    this.amount = invoiceInfo.amount;
    this.subtotal = invoiceInfo.subtotal;
    this.expenseAmount = invoiceInfo.expenseAmount || 0;
    this.surchargeAmount = invoiceInfo.surchargeAmount || 0;
    this.tripsAmount = invoiceInfo.tripsAmount || 0;
    this.tripsExpenseAmount = invoiceInfo.tripsExpenseAmount || 0;
    this.punchcardsAmount = invoiceInfo.punchcardsAmount || 0;
    this.punchcardsExpenseAmount = invoiceInfo.punchcardsExpenseAmount || 0;
    this.sendable = invoiceInfo.sendable;
    this.expensed = invoiceInfo.expensed;
    if (invoiceInfo.ownerOrganization) {
      this.ownerOrganization = new Organization(invoiceInfo.ownerOrganization);
    }
    if (invoiceInfo.customerOrganization) {
      this.customerOrganization = new Organization(invoiceInfo.customerOrganization);
    }
    if (invoiceInfo.job) {
      this.job = new JobSerializer().fromJson(invoiceInfo.job);
    }
    this.sent = invoiceInfo.sent;
    this.draft = !invoiceInfo.sent;
    this.sentTime = invoiceInfo.sentTime;
    this.sentBy = invoiceInfo.sentBy;
    if (invoiceInfo.sentTrips && invoiceInfo.sentTrips.length) {
      this.sentTrips = invoiceInfo.sentTrips.map((trip) => {
        return new CondensedTrip(trip);
      });
    }
    if (invoiceInfo.sentPunchCards && invoiceInfo.sentPunchCards.length) {
      this.sentPunchCards = invoiceInfo.sentPunchCards.map((punchCard) => {
        return new CondensedPunchCard(punchCard);
      });
    }
    this.billFromContactEmail = invoiceInfo.billFromContactEmail;
    if (invoiceInfo.billFromCcEmails && (typeof invoiceInfo.billFromCcEmails === 'object')) {
      this.billFromCcEmails = invoiceInfo.billFromCcEmails.join(',');
    }
    this.billFromAddress = invoiceInfo.billFromAddress;
    this.billToContactEmail = invoiceInfo.billToContactEmail;
    if (invoiceInfo.billToCcEmails && (typeof invoiceInfo.billToCcEmails === 'object')) {
      this.billToCcEmails = invoiceInfo.billToCcEmails.join(',');
    }
    this.billToAddress = invoiceInfo.billToAddress;
    this.createdAt = invoiceInfo.createdAt;
    this.remittanceInfo = invoiceInfo.remittanceInfo;

    this.pdf = invoiceInfo.pdf;
    this.html = invoiceInfo.html;
    this.earliestBillableItemTime = invoiceInfo.earliestBillableItemTime;
    this.latestBillableItemTime = invoiceInfo.latestBillableItemTime;
    this.startTime = invoiceInfo.startTime;
    this.endTime = invoiceInfo.endTime;
    this.haulTotal = invoiceInfo.haulTotal;
    this.invoiceDate = invoiceInfo.invoiceDate;
    this.kind = invoiceInfo.kind;
  }

  get administrativeStatus(): string {
    if (this.sent) {
      return 'sent';
    } else if (this.draft) {
      return 'draft';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus &&
        this.administrativeStatus.length &&
        this.administrativeStatus !== 'sent' &&
        this.administrativeStatus !== 'draft'
      ) {
      return true;
    } else {
      return false;
    }
  }

  get tripDates(): string {
    let values = [];
    if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
      let _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_earliestBillableItemTime);
    }
    if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
      let _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_latestBillableItemTime);
    }

    if (!values.length) { values.push(this.shortTitle + ' ' + this.number); }

    return values.filter(Boolean).join(' - ');
  }

  get invoicePeriod(): string {
    let values = [];
    if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
      let _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_earliestBillableItemTime);
    }
    if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
      let _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_latestBillableItemTime);
    }

    if (!values.length) { values.push(this.shortTitle + ' ' + this.number); }

    return values.filter(Boolean).join(' - ');
  }

  get shortTitle(): string {
    if (this.draft) {
      return 'Invoice Draft ';
    } else {
      return 'Sent Invoice ';
    }
  }

  get title(): string {
    let name = this.job && this.job.name;
    if (!name) {
      name = this.customerOrganization && this.customerOrganization.name;
    }
    if (this.draft) {
      return 'Invoice Draft ' + name;
    } else {
      return 'Sent Invoice ' + name;
    }
  }

  url(action): string {
    if (action === 'view') {
      return this.html;
    } else if (action === 'download') {
      return this.pdf;
    } else {
      return '/invoices/' + this.id + '/' + action;
    }
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.draft) {
      let _option = find(options, { action: 'resend' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'view' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'download' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'unlock' });
      if (_option) { remove(options, _option); }
    } else {
      let _option = find(options, { action: 'edit' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'remove' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'preview' });
      if (_option) { remove(options, _option); }
    }

    if (!this.sendable) {
      let _option = find(options, { action: 'send' });
      if (_option) { remove(options, _option); }
    }

    if (this.expensed) {
      let _option = find(options, { action: 'expense' });
      if (_option) { remove(options, _option); }
    }

    if (!this.pdf) {
      let _option = find(options, { action: 'download' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
