"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var core_2 = require("@ngx-translate/core");
var fleet_health_stats_service_1 = require("./../fleet-health-stats.service");
var FleetHealthStatsComponent = /** @class */ (function () {
    // text color - red (#d6273c), green - (#33aa64), blue (#4990e2), purple (#7A4AF9), yellow (#FCE53F)
    function FleetHealthStatsComponent(fleetHealthService, translate) {
        this.fleetHealthService = fleetHealthService;
        this.translate = translate;
        this.filterChanges = new core_1.EventEmitter();
        this.versionsCount = 0;
        this.moreThanTwoVersions = false;
        this.showOtherVersions = false;
        this.pieChartType = 'pie';
        this.pieChartOptions = {
            responsive: false,
            legend: {
                position: 'right',
                labels: {
                    fontColor: ['#d6273c', '#1986d8'],
                },
            },
        };
        this.driversChartColors = [
            {
                backgroundColor: ['#c0dcf8', '#f8d9dd'],
            },
        ];
        this.driversTextColors = ['#d6273c', '#4990e2'];
        this.versionsChartColors = [
            {
                backgroundColor: ['#d4f2e0', '#f8d9dd', '#c0dcf8', '#7A4AF9', '#FCE53F'],
            },
        ];
        this.versionsTextColors = [
            '#33aa64',
            '#d6273c',
            '#4990e2',
            '#6B36F9',
            '#FCE229',
        ];
    }
    FleetHealthStatsComponent.prototype.ngOnChanges = function () {
        var filters = this.appliedFiltersToQuery(this.appliedFilters);
        var query = __assign({}, this.query, { filters: filters && filters.length ? filters : null, search: this.searchQuery ? this.searchQuery : null });
        this.getFleetHealthStats(query);
    };
    FleetHealthStatsComponent.prototype.getFleetHealthStats = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        this.fleetHealthStats$ = this.fleetHealthService
            .get(null, query, false)
            .pipe(operators_1.map(function (stats) {
            _this.driversPreparePieCharts(stats);
            return __assign({}, stats, { applicationVersions: _this.groupApplicationVersions(stats.applicationVersions) });
        }));
    };
    FleetHealthStatsComponent.prototype.groupApplicationVersions = function (applicationVersions) {
        var versions = applicationVersions.reduce(function (arrayAcc, arrayCurr) {
            return Object.keys(arrayCurr).reduce(function (acc, curr) {
                var _a;
                return (__assign({}, acc, (_a = {}, _a[curr] = arrayCurr[curr] + (acc[curr] || 0), _a)));
            }, arrayAcc);
        }, {});
        var appVersions = Object.keys(versions)
            .map(function (k) { return ({
            key: k,
            count: versions[k],
        }); })
            .sort(function (a, b) { return b.count - a.count; });
        this.versionsPreparePieCharts(appVersions);
        return appVersions;
    };
    FleetHealthStatsComponent.prototype.driversPreparePieCharts = function (stats) {
        var translatedNoIssues = this.translate.instant('No issues');
        var translatedIssues = this.translate.instant('Has issues');
        this.driversPieChartLabels = [translatedNoIssues, translatedIssues];
        this.driversPieChartData = [
            stats.driverCount - stats.driversWithIssues,
            stats.driversWithIssues,
        ];
    };
    FleetHealthStatsComponent.prototype.versionsPreparePieCharts = function (versions) {
        var labels = versions.map(function (v) { return v.key; });
        var data = versions.map(function (v) { return v.count; });
        var versionsCount = data.reduce(function (sum, x) { return sum + x; }, 0);
        this.versionsPieChartLabels = labels;
        this.versionsPieChartData = data;
        this.versionsCount = versionsCount;
    };
    FleetHealthStatsComponent.prototype.appliedFiltersToQuery = function (appliedFilters) {
        return appliedFilters.map(function (filter) {
            if (filter.query) {
                var queryFilter = filter.query;
                var key = Object.keys(queryFilter)[0];
                return {
                    key: key,
                    values: queryFilter[key],
                };
            }
            else {
                return null;
            }
        });
    };
    FleetHealthStatsComponent.prototype.filterByOnShift = function () {
        this.filterChanges.emit('on_shift');
    };
    FleetHealthStatsComponent.prototype.filterByIssues = function () {
        this.filterChanges.emit('issues');
    };
    FleetHealthStatsComponent.prototype.filterExists = function (key) {
        var appliedFilter = this.appliedFilters.find(function (af) { return af.key === key; });
        return appliedFilter && appliedFilter.values === 'True' ? true : false;
    };
    return FleetHealthStatsComponent;
}());
exports.FleetHealthStatsComponent = FleetHealthStatsComponent;
