"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var truck_service_1 = require("../../trucks/truck.service");
var driver_service_1 = require("../../drivers/driver.service");
var api_service_1 = require("../../shared/api.service");
var shared_1 = require("../../shared");
var driver_1 = require("../../drivers/driver");
var AssignmentTruckManagementDialogComponent = /** @class */ (function () {
    function AssignmentTruckManagementDialogComponent(driverService, dialogRef) {
        this.driverService = driverService;
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
        this.dutyStatusConfig = { nameProperty: 'name' };
        this.dutyStatusOptions = driver_1.DRIVER_DUTY_OPTIONS;
        this.trucksDropdownConfig = {
            small: true,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'name',
            service: truck_service_1.TruckService,
            query: { ordering: 'name' },
            includeFullObject: true
        };
        this.noTrucksDropdownConfig = __assign({}, this.trucksDropdownConfig, { initialLoad: true });
        this.jobEventTruckTypes = '';
    }
    AssignmentTruckManagementDialogComponent.prototype.ngOnInit = function () {
        if (this.assignmentErrors) {
            this.jobEventTruckTypes = this.assignmentErrors[0].assignment.jobevent.truckTypes.map(function (t) { return (t.name); }).join(', ');
            var errorText = this.assignmentErrors.map(function (a) { return (Object.keys(a.errors).map(function (key) { return (a.errors[key]); }).join(', ')); }).join(', ').toLowerCase();
            this.visibleFields = errorText.includes('truck type') && errorText.includes('field is required') ? 'both' :
                errorText.includes('truck type') && !errorText.includes('field is required') ? 'truckType' : 'noTruck';
        }
    };
    AssignmentTruckManagementDialogComponent.prototype.selectTruck = function (error, truck) {
        var _this = this;
        if (!error.assignment.truck || error.assignment.truck.id !== truck.id) {
            this.driverService.assign(Object.assign(error.assignment.driver, { truck: truck })).subscribe(function (res) {
                _this.assignmentErrors[_this.assignmentErrors.findIndex(function (a) { return (a.assignment.driver.id === error.assignment.driver.id); })].assignment['truck'] = truck;
                error.assignment.driver.truckDriverDisplayName = (res.dutyStatus === 'on-duty' ? truck.name : 'Off Duty') + ' - ' + res.name;
                _this.refreshGrid();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    AssignmentTruckManagementDialogComponent.prototype.updateDutyStatus = function (error, status) {
        var _this = this;
        this.driverService.save({ id: error.assignment.driver.id, dutyStatus: status }).subscribe(function (res) {
            _this.assignmentErrors[_this.assignmentErrors.findIndex(function (a) { return (a.assignment.driver.id === error.assignment.driver.id); })].assignment['dutyStatus'] = status;
            _this.refreshGrid();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    AssignmentTruckManagementDialogComponent.prototype.submit = function () {
        this.callback(this.assignmentErrors.map(function (e) { return (e.assignment); }));
        this.dialogRef.close();
    };
    AssignmentTruckManagementDialogComponent.prototype.configSetup = function (config, error) {
        return Object.assign(config, {
            query: {
                ordering: 'name',
                carrier: error &&
                    error.assignment &&
                    error.assignment.driver &&
                    error.assignment.driver.carrier &&
                    error.assignment.driver.carrier.id
            }
        });
    };
    return AssignmentTruckManagementDialogComponent;
}());
exports.AssignmentTruckManagementDialogComponent = AssignmentTruckManagementDialogComponent;
