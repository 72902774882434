'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ActionMenuComponent = /** @class */ (function () {
    function ActionMenuComponent() {
        this.options = [];
        this.disabled = false;
    }
    ActionMenuComponent.prototype.ngOnInit = function () { };
    return ActionMenuComponent;
}());
exports.ActionMenuComponent = ActionMenuComponent;
