"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var collaborator_stat_1 = require("./collaborator-stat");
var job_event_stat_serializer_1 = require("../job-event-stats/job-event-stat.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CollaboratorStatSerializer = /** @class */ (function () {
    function CollaboratorStatSerializer() {
    }
    CollaboratorStatSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var collaboratorStat = new collaborator_stat_1.CollaboratorStat();
        if (!json) {
            return collaboratorStat;
        }
        collaboratorStat.id = json.id;
        collaboratorStat.name = json.name;
        collaboratorStat.assignedTrucks = json.assignedTrucks;
        collaboratorStat.averageTripTime = json.averageTripTime;
        collaboratorStat.confirmedTrucks = json.confirmedTrucks;
        collaboratorStat.deliveredLoads = json.deliveredLoads;
        collaboratorStat.dispatchedTrucks = json.dispatchedTrucks;
        if (json.jobeventStats && json.jobeventStats.length) {
            collaboratorStat.jobEventStats = json.jobeventStats.map(function (jobeventStat) {
                if (jobeventStat && typeof jobeventStat === 'object') {
                    return new job_event_stat_serializer_1.JobEventStatSerializer().fromJson(jobeventStat);
                }
                else if (jobeventStat) {
                    return new job_event_stat_serializer_1.JobEventStatSerializer().fromJson({ id: jobeventStat });
                }
            });
        }
        else {
            collaboratorStat.jobEventStats = [];
        }
        if (collaboratorStat.jobEventStats && collaboratorStat.jobEventStats.length > 0) {
            collaboratorStat.canExpand = true;
        }
        collaboratorStat.requestedTrucks = json.requestedTrucks;
        collaboratorStat.ticketedWeight = json.ticketedWeight;
        collaboratorStat.truckTypes = json.truckTypes;
        collaboratorStat.weightInTransit = json.weightInTransit;
        collaboratorStat.driversOnShift = json.driversOnShift;
        collaboratorStat.dispatchedLoads = json.dispatchedLoads;
        collaboratorStat.dispatchedTons = json.dispatchedTons;
        collaboratorStat.invoiceTotal = json.invoiceTotal;
        collaboratorStat.haulTotal = json.haulTotal;
        collaboratorStat.loading = json.loading;
        collaboratorStat.selected = json.selected;
        return collaboratorStat;
    };
    CollaboratorStatSerializer.prototype.toJson = function (collaboratorStat) {
        var json = {
            id: collaboratorStat.id,
            name: collaboratorStat.name
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return CollaboratorStatSerializer;
}());
exports.CollaboratorStatSerializer = CollaboratorStatSerializer;
