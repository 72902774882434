"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var collaborator_stat_service_1 = require("../../collaborators/collaborator-stat.service");
var index_1 = require("../../shared/index");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var CollaboratorStatsComponent = /** @class */ (function () {
    function CollaboratorStatsComponent(authenticationService, translationService, dialog) {
        this.authenticationService = authenticationService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'expand' },
            { key: 'collaborator', title: this.translationService.instant('Collaborator'), sortable: true, sortBy: 'organization__name' },
            { key: 'avg-trip-time', title: this.translationService.instant('AVG Trip Time'), sortable: false },
            { key: 'dispatched-trucks', title: this.translationService.instant('Dispatched Trucks'), sortable: false },
            { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks'), sortable: false },
            { key: 'requested-trucks', title: this.translationService.instant('Requested Trucks'), sortable: false },
            { key: 'truck-types', title: this.translationService.instant('Truck Types'), sortable: false },
            { key: 'drivers-on-shift', title: this.translationService.instant('Drivers on Shift'), sortable: false },
            { key: 'delivered-loads', title: this.translationService.instant('Delivered Loads'), sortable: false },
            { key: 'weight-in-transit', title: this.translationService.instant('Weight in Transit'), sortable: false },
            { key: 'ticketed-weight', title: this.translationService.instant('Ticketed Weight'), sortable: false },
            { key: 'dispatched-loads', title: this.translationService.instant('Dispatched Loads'), sortable: false },
            { key: 'dispatched-tons', title: this.translationService.instant('Dispatched Tons'), sortable: false },
            { key: 'invoice-total', title: this.translationService.instant('Invoice Total'), sortable: false },
            { key: 'haul-total', title: this.translationService.instant('Haul Total'), sortable: false },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'select', 'expand', 'collaborator', 'avg-trip-time', 'dispatched-trucks',
            'confirmed-trucks', 'requested-trucks', 'truck-types', 'drivers-on-shift',
            'delivered-loads', 'weight-in-transit', 'ticketed-weight',
            'dispatched-loads', 'dispatched-tons', 'invoice-total', 'haul-total',
            'action'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.hideCancelled = true;
        this.loading = true;
        this.detailColumns = ['detail'];
        this.tableConfig = {
            hasHeader: true,
            pageSize: 25,
            service: collaborator_stat_service_1.CollaboratorStatService,
            preferenceKey: 'DailyBoardListComponent-CollaboratorStatService',
            query: {},
            collectionTitle: this.translationService.instant('Collaborators'),
            noResultsText: this.translationService.instant('a collaborator'),
            newRecordRoute: ['/collaborators/new'],
            sortBy: 'organization__name',
            sortDirection: 'asc',
            customHeight: true,
            automaticallyHidePagination: false
        };
    }
    CollaboratorStatsComponent.prototype.ngOnInit = function () {
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
            this.loading = false;
        }
        else {
            this.loading = false;
        }
        this.tableConfig['query'] = __assign({}, this.tableConfig['query'], query);
    };
    CollaboratorStatsComponent.prototype.ngOnChanges = function (changes) {
        var jobEventDateStart = new Date();
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        if (changes['jobEventDate']) {
            var jobEventDate = changes['jobEventDate'].currentValue;
            if (jobEventDate) {
                jobEventDateStart = new Date(this.jobEventDate);
                jobEventDateEnd = lodash_1.clone(jobEventDateStart);
            }
        }
        else if (this.jobEventDate) {
            jobEventDateStart = new Date(this.jobEventDate);
            jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        }
        jobEventDateStart.setHours(0, 0, 0, 0);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        this.tableConfig['query'] = {
            exclude_pending: 'True',
            cancelled: !this.hideCancelled ? 'False' : undefined,
            shift1_start__gte: jobEventDateStart.toISOString(),
            shift1_start__lte: jobEventDateEnd.toISOString()
        };
        if (this.collaboratorStatsTable) {
            this.collaboratorStatsTable.config = this.tableConfig;
            this.collaboratorStatsTable.getRecords();
        }
    };
    CollaboratorStatsComponent.prototype.filtersModified = function (appliedFilters) {
        // let filter = _find(this.appliedFilters, { key: '' });
        // if (!appliedFilters || !appliedFilters.length ) {
        //   this.filterChanges(_find(this.priceListsReq, { id: null }));
        // }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    CollaboratorStatsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    CollaboratorStatsComponent.prototype.refreshTable = function () {
        if (this.collaboratorStatsTable) {
            var query = {};
            if (this.authenticationService.hasFavoriteTags()) {
                query['user_tags'] = 'True';
            }
            this.collaboratorStatsTable.getRecords(__assign({}, this.tableConfig['query'], query));
        }
    };
    return CollaboratorStatsComponent;
}());
exports.CollaboratorStatsComponent = CollaboratorStatsComponent;
