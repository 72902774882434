"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var truck_type_serializer_1 = require("../trucks/truck-type.serializer");
var Collaborator = /** @class */ (function () {
    function Collaborator(collaboratorInfo) {
        this.editing = false;
        this.readonly = false;
        this.selected = false;
        this.readonlyStatuses = ['upcoming', 'cancelled', 'expired', 'rejected', 'future'];
        this.underDispatched = false;
        this.id = collaboratorInfo.id;
        this.organization = collaboratorInfo.organization;
        this.organizationId = collaboratorInfo.organizationId;
        this.days = collaboratorInfo.days;
        this.jobDays = collaboratorInfo.jobDays;
        this.numTrucks = collaboratorInfo.numTrucks || 0;
        this.confirmedTrucks = collaboratorInfo.confirmedTrucks || 0;
        this.dispatched = collaboratorInfo.dispatched;
        this.invoiceRate = collaboratorInfo.invoiceRate;
        this.invoiceType = collaboratorInfo.invoiceType;
        this.invoiceWeightUnit = collaboratorInfo.invoiceWeightUnit;
        this.haulRate = collaboratorInfo.haulRate;
        this.haulType = collaboratorInfo.haulType;
        this.haulWeightUnit = collaboratorInfo.haulWeightUnit;
        this.status = collaboratorInfo.status;
        this.job = collaboratorInfo.job;
        this.jobId = collaboratorInfo.jobId;
        this.externalIdentifier = collaboratorInfo.externalIdentifier;
        this.jobDisplayName = [this.externalIdentifier, this.job].filter(Boolean).join(': ');
        this.sharedBy = collaboratorInfo.sharedBy;
        this.sharedById = collaboratorInfo.sharedById;
        this.startLocation = collaboratorInfo.startLocation;
        this.endLocation = collaboratorInfo.endLocation;
        this.shift1Start = collaboratorInfo.shift1Start;
        this.shift1End = collaboratorInfo.shift1End;
        this.shift2Start = collaboratorInfo.shift2Start;
        this.shift2End = collaboratorInfo.shift2End;
        this.material = collaboratorInfo.material;
        this.senderFirstName = collaboratorInfo.senderFirstName;
        this.senderLastName = collaboratorInfo.senderLastName;
        this.senderOrganizationPhoneNumber = collaboratorInfo.senderOrganizationPhoneNumber;
        this.createdAt = collaboratorInfo.createdAt;
        this.readonly = this.readonlyStatuses.includes(this.status);
        this.checkinOptions = collaboratorInfo.checkinOptions || {};
        this.checkoutOptions = collaboratorInfo.checkoutOptions || {};
        this.underDispatched = this.numTrucks > this.dispatched;
        this.jobEventId = collaboratorInfo.jobeventId;
        this.organizationLeasedOrg = collaboratorInfo.organizationLeasedOrg;
        this.multipliers = collaboratorInfo.multipliers;
        this.workOrderTemplate = collaboratorInfo.workOrderTemplate;
        this.workOrderRenderedHtml = collaboratorInfo.workOrderRenderedHtml;
        this.workOrderRenderedPdf = collaboratorInfo.workOrderRenderedPdf;
        this.workOrderSigned = collaboratorInfo.workOrderSigned;
        this.workOrderSignedBy = collaboratorInfo.workOrderSignedBy;
        this.notes = collaboratorInfo.notes;
        this.requestedAmount = collaboratorInfo.requestedAmount;
        this.requestedUnit = collaboratorInfo.requestedUnit;
        this.confirmedAmount = collaboratorInfo.confirmedAmount;
        // tslint:disable-next-line:max-line-length
        this.name = moment(this.shift1Start).format('MM/DD/YYYY h:mm a') + " - " + moment(this.shift2End || this.shift1End).format('MM/DD/YYYY h:mm a');
        this.allowWeight = this.invoiceType === 'weight' || this.haulType === 'weight';
        this.valid = this.numTrucks && this.invoiceRate && this.days && this.days.length > 0;
        this.shifts = this.shift2End ? 2 : 1;
        this.invoiceRateUnit = this.invoiceType === 'weight' ? this.invoiceWeightUnit : this.invoiceType;
        this.allowAnyTruckType = collaboratorInfo.allowAnyTruckType;
        if (collaboratorInfo.truckTypes && collaboratorInfo.truckTypes.length) {
            this.truckTypes = collaboratorInfo.truckTypes.map(function (truckType) {
                if (truckType && typeof truckType === 'object') {
                    return new truck_type_serializer_1.TruckTypeSerializer().fromJson(truckType);
                }
                else if (collaboratorInfo.truckTypes) {
                    return new truck_type_serializer_1.TruckTypeSerializer().fromJson({ id: truckType });
                }
            });
        }
        else {
            this.truckTypes = [];
        }
    }
    Object.defineProperty(Collaborator.prototype, "dateRange", {
        get: function () {
            if (this.days.length === 0) {
                return '';
            }
            else if (this.days.length === 1) {
                return moment(this.days[0]).format('MMM D YYYY');
            }
            else {
                this.days = this.days.sort(function (a, b) {
                    return +new Date(a) - +new Date(b);
                });
                return moment(this.days[0]).format('MMM D YYYY') + ' to ' + moment(this.days[this.days.length - 1]).format('MMM D YYYY');
            }
        },
        enumerable: true,
        configurable: true
    });
    return Collaborator;
}());
exports.Collaborator = Collaborator;
