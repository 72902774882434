"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".heading[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-items:center;height:100%}.heading[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 25px 0 0;font-size:15px;font-weight:600;color:#3f536e}.content[_ngcontent-%COMP%]{height:100%}.content[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]{min-height:77px;padding:0 20px}.content[_ngcontent-%COMP%]   .upload-file-dropzone[_ngcontent-%COMP%]{width:100%;margin-bottom:0;height:100%}.content[_ngcontent-%COMP%]   .upload-file-dropzone[_ngcontent-%COMP%]   .upload-label[_ngcontent-%COMP%]{padding-top:60px}.content[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{margin-bottom:0;height:100%}.content[_ngcontent-%COMP%]   .errors[_ngcontent-%COMP%], .content[_ngcontent-%COMP%]   .orders[_ngcontent-%COMP%]{padding:0 20px}.content[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{padding:20px}"];
exports.styles = styles;
