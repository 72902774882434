import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { NewTripComponent } from './new-trip/new-trip.component';
import { EditTripComponent } from './edit-trip/edit-trip.component';
import { CondensedTripsComponent } from './condensed-trips/condensed-trips.component';

const routes: Routes = [
  { path: '', component: CondensedTripsComponent, canActivate: [AuthGuard] },
  { path: 'new', component: NewTripComponent, canActivate: [AuthGuard] },
  {
    path: 'new/:jobId/:jobEventId',
    component: NewTripComponent,
    canActivate: [AuthGuard],
  },
  { path: ':id/edit', component: EditTripComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TripsRoutingModule {}
