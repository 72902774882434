"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var moment = require("moment");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var shared_1 = require("../../../shared");
var fancy_table_component_1 = require("../../../shared/fancy-table/fancy-table.component");
var column_toggle_component_1 = require("../../../shared/column-toggle/column-toggle.component");
var assignment_service_1 = require("../../../assignments/assignment.service");
var driver_service_1 = require("../../../drivers/driver.service");
var job_event_service_1 = require("../../../job-events/job-event.service");
var DriverPayPrereportComponent = /** @class */ (function () {
    function DriverPayPrereportComponent(assignmentService, jobEventService, translationService) {
        this.assignmentService = assignmentService;
        this.jobEventService = jobEventService;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'status', title: ' ', sortable: false },
            { key: 'driverEdit', title: this.translationService.instant('Driver'), sortable: true, sortBy: 'driver__last_name' },
            { key: 'truckNumber', title: this.translationService.instant('Truck #'), sortable: true, sortBy: 'truck__name' },
            { key: 'job', title: this.translationService.instant('Job'), sortable: true, sortBy: 'jobevent__job__name' },
            { key: 'orderNumber', title: this.translationService.instant('Order Number'), sortable: false },
            { key: 'shiftStart', title: this.translationService.instant('Shift Start'), sortable: true, sortBy: 'jobevent__shift1_start' },
            { key: 'shiftEnd', title: this.translationService.instant('Shift End'), sortable: true, sortBy: 'jobevent__shift1_end' },
            { key: 'tripsAmount', title: this.translationService.instant('Trips Amount'), sortable: false },
            { key: 'geotripsAmount', title: this.translationService.instant('GeoTrips Amount'), sortable: false },
            { key: 'row-actions', title: this.translationService.instant('Actions'), sortable: false }
        ];
        this.displayedColumns = [
            'status', 'driverEdit', 'truckNumber', 'job', 'orderNumber', 'shiftStart',
            'shiftEnd', 'tripsAmount', 'geotripsAmount', 'row-actions'
        ];
        this.tableConfig = {
            hasHeader: false,
            service: assignment_service_1.AssignmentService,
            ignorePreferences: true,
            automaticallyHidePagination: false,
            query: {},
            collectionTitle: this.translationService.instant('Prereport Assignments'),
            noResultsText: this.translationService.instant('an assignment'),
            sortBy: 'date',
            sortDirection: 'asc',
            hasNoResultsAction: true,
            customRecordFields: {
                edited: false,
                updated: false
            },
        };
        this.query = {};
        this.searchClear = new core_1.EventEmitter();
        this.jobEvents = [];
    }
    DriverPayPrereportComponent.prototype.ngOnChanges = function (changes) {
        if ((changes.reportRows && !changes.reportRows.firstChange) ||
            (changes.selectedDate && !changes.selectedDate.firstChange) ||
            (changes.search && !changes.search.firstChange)) {
            this.setupDateQuery(this.selectedDate);
            if (this.prereportTable) {
                this.prereportTable.getRecords();
            }
        }
    };
    DriverPayPrereportComponent.prototype.ngOnInit = function () {
        this.setupDateQuery(this.selectedDate);
    };
    DriverPayPrereportComponent.prototype.setupDateQuery = function (date) {
        this.tableConfig = __assign({}, this.tableConfig, { query: {
                jobevent__shift1_start__gte: moment(date).startOf('day').toISOString(),
                jobevent__shift1_start__lte: moment(date).endOf('day').toISOString(),
                dispatched: 'True', include_trips: 'True', include_punchcards: 'True',
                carrier_type: this.reportType,
                search: this.search
            } });
        this.dateParam = moment(date).format('YYYYMMDD');
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    DriverPayPrereportComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    DriverPayPrereportComponent.prototype.driverDropdownConfig = function (row) {
        return {
            service: driver_service_1.DriverService,
            selectText: this.translationService.instant('Select Driver'),
            loadingText: this.translationService.instant('Loading Driver...'),
            noResultsText: this.translationService.instant('No Driver'),
            customOptionKeys: ['carrier'],
            query: {
                ordering: 'profile__last_name,name',
                carrier: row.driver.carrier.id,
                jobevent_available: row.jobevent && row.jobevent.id
            }
        };
    };
    DriverPayPrereportComponent.prototype.changeAssignmentDriver = function (row, driver) {
        if (!row['edited']) {
            this.prereportTable.record = Object.assign(row, { edited: row.driver.id !== driver.id });
        }
        this.prereportTable.record = Object.assign(row, { driver: driver });
    };
    DriverPayPrereportComponent.prototype.saveAssignmentUpdate = function (row) {
        var _this = this;
        var saveData = __assign({}, row);
        delete saveData['edited'];
        delete saveData['updated'];
        this.assignmentService.save(saveData).subscribe(function () {
            _this.prereportTable.record = Object.assign(row, { edited: false, updated: true });
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DriverPayPrereportComponent.prototype.driverStatus = function (row) {
        var status = 'inactive';
        var startTime = Date.parse(row.shifts && row.shifts[0] && row.shifts[0].startTime);
        if (Date.now() > startTime && !!!(row.shifts[0] && row.shifts[0].endTime)) {
            status = Date.now() < (startTime + 86400000) ? 'active' : 'warning';
        }
        else if (row.shifts[0] && row.shifts[0].endTime) {
            status = 'complete';
        }
        else if (startTime && row.trips.length === 0 && row.predictedTrips.length === 0) {
            status = 'nodata';
        }
        return status;
    };
    DriverPayPrereportComponent.prototype.getJobEventData = function (assignments) {
        var _this = this;
        var jobEventIds = lodash_1.uniq(assignments.map(function (a) { return (a.jobevent.id); }));
        jobEventIds.forEach(function (id) {
            var matchedAssignments = assignments.filter(function (a) { return (a.jobevent.id === id); });
            var matchedJobEvent = lodash_1.find(_this.jobEvents, { 'id': id });
            if (matchedJobEvent) {
                matchedAssignments.forEach(function (a) {
                    a.jobevent = matchedJobEvent;
                    _this.prereportTable.updateTable(a);
                });
            }
            else {
                _this.jobEventService.get(id).pipe(operators_1.take(1)).subscribe(function (res) {
                    _this.jobEvents.push(res);
                    matchedAssignments.forEach(function (a) {
                        a.jobevent = res;
                        _this.prereportTable.updateTable(a);
                    });
                });
            }
        });
    };
    DriverPayPrereportComponent.prototype.onSearchChange = function (searchVal) {
        if (searchVal !== this.search) {
            this.search = searchVal;
            this.searchClear.emit(this.search);
        }
    };
    return DriverPayPrereportComponent;
}());
exports.DriverPayPrereportComponent = DriverPayPrereportComponent;
