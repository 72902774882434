import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { cloneDeep } from 'lodash';

import { Invitation } from './invitation';
import { ApiService } from '../shared/api.service';
import { DriverService } from '../drivers/driver.service';
import { InvitationService } from './invitation.service';
import { AuthenticationService } from '../shared/authentication.service';

// const camelcaseKeysDeep = require('camelcase-keys-deep');

@Component({
  selector: 'ruckit-driver-invitation',
  templateUrl: './driver-invitation.component.html',
  styleUrls: ['./driver-invitation.component.scss']
})
export class DriverInvitationComponent implements OnInit {
  inviteCode: string;
  invitation: Invitation;
  loading = true;
  errors = [];
  data: any = {};
  user: any = {};
  driver: any = {};
  step = 0;
  steps: string[] = ['User Details', 'Account Details', 'User Photo', 'App Download'];
  completedSteps: string[] = [];
  states: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private apiService: ApiService,
    private invitationService: InvitationService,
    private driverService: DriverService,
    private authenticationService: AuthenticationService
  ) {
    this.states = this.apiService.getStates().map((state) => {
      return {name: state.abbreviation};
    });
  }

  register() {
    this.loading = true;
    this.errors = [];
    this.invitationService.registerFromInvitation(this.invitation.id, this.invitation.kind, {'driver': this.data, 'user': this.user})
      .subscribe(
        (result) => {
          this.ngOnInit();
          this.loading = false;
          this.step += 1;
          this.completedSteps.push('User Details');
        },
        (err) => {
          this.errors = err;
          this.loading = false;
        }
      );
  }

  updateAccountDetail() {
    this.loading = true;
    this.errors = [];
    this.driverService.save(this.driver)
      .subscribe(
        (result) => {
          this.loading = false;
          this.step += 1;
          this.completedSteps.push('Account Details');
        },
        (err) => {
          this.errors = err;
          this.loading = false;
        }
      );
  }

  updateDriverPhoto() {
    const fileInput = this.elementRef.nativeElement.querySelector('input[name="file"]');
    let driver = cloneDeep(this.driver);
    delete driver.profile.image;
    this.errors = [];

    if (this.driver.profile.image) {
      this.driverService.uploadImage('PUT', driver.id, fileInput.files)
        .subscribe(
          (result) => {
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.errors = err;
          }
        );
    }

    this.step += 1;
    this.completedSteps.push('User Photo');
  }

  fileChange(e) {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (ev) => {
      this.driver.profile.image = ev.target['result'];
    };
    reader.readAsDataURL(file);
  }

  ngOnInit() {
    this.loading = true;
    this.errors = [];
    this.route.params.forEach((params: Params) => {
      this.inviteCode = params['invite_code'];

      this.invitationService.getInvitation(this.inviteCode).subscribe(invitation => {
        // invitation = camelcaseKeysDeep(invitation);
        this.invitation = <Invitation>invitation;
        if (this.invitation.status === 'pending') {
          this.authenticationService.logout();
        } else if (this.invitation.status === 'accepted') {
          this.completedSteps.push('User Details');
        }
        this.loadData(this.invitation);
        this.loading = false;

        if (this.completedSteps.indexOf('User Details', 0) > -1) {
          this.step = 2;
        } else {
          this.step = +params['step'] || 1;
        }
      }, err => {
        this.errors = err;
      });
    });
  }

  private loadData(invitation) {
    this.data = {
      'profile': {
        'firstName': invitation.data && invitation.data.driver.profile.firstName,
        'lastName': invitation.data && invitation.data.driver.profile.lastName,
        'email': invitation.data && invitation.data.driver.profile.email
      },
      'cdl': invitation.data && invitation.data.driver.cdl
    };
    this.user = {
      'password': ''
    };
    this.driver = {
      'id': invitation.resultUser && invitation.resultUser.driver.id,
      'street': invitation.data && invitation.data.driver.street,
      'city': invitation.data && invitation.data.driver.city,
      'state': invitation.data && invitation.data.driver.state,
      'zipcode': invitation.data && invitation.data.driver.zipcode,
      'cdl': invitation.data && invitation.data.driver.cdl,
      'profile': {
        'phoneNumber': invitation.data && invitation.data.driver.profile.phoneNumber,
        'firstName': invitation.data && invitation.data.driver.profile.firstName,
        'lastName': invitation.data && invitation.data.driver.profile.lastName,
        'image': invitation.data && invitation.data.driver.profile.image,
        'email': invitation.data && invitation.data.driver.email
      }
    };
  }
}
