"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./trip-history-route.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../node_modules/@ngui/map/ngui-map.ngfactory");
var i3 = require("@ngui/map");
var i4 = require("@angular/common");
var i5 = require("@ngx-translate/core");
var i6 = require("../trip-history-widget/trip-history-widget.component.ngfactory");
var i7 = require("../trip-history-widget/trip-history-widget.component");
var i8 = require("../../../jobs/locationUpdate.service");
var i9 = require("@angular/common/http");
var i10 = require("../../../job-events/job-event.service");
var i11 = require("./trip-history-route.component");
var i12 = require("../../../jobs/job.service");
var styles_TripHistoryRouteComponent = [i0.styles];
var RenderType_TripHistoryRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripHistoryRouteComponent, data: {} });
exports.RenderType_TripHistoryRouteComponent = RenderType_TripHistoryRouteComponent;
function View_TripHistoryRouteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "custom-marker", [], null, null, null, i2.View_CustomMarker_0, i2.RenderType_CustomMarker)), i1.ɵdid(1, 770048, null, 0, i3.CustomMarker, [i3.NguiMapComponent, i1.ElementRef, i3.NguiMap], { position: [0, "position"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "img", [["src", "assets/img/map/locationmarker-loading.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.startLocation.latitude, _co.startLocation.longitude); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TripHistoryRouteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "custom-marker", [], null, null, null, i2.View_CustomMarker_0, i2.RenderType_CustomMarker)), i1.ɵdid(1, 770048, null, 0, i3.CustomMarker, [i3.NguiMapComponent, i1.ElementRef, i3.NguiMap], { position: [0, "position"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "img", [["src", "assets/img/map/locationmarker-unloading.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.endLocation.latitude, _co.endLocation.longitude); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TripHistoryRouteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "polygon", [], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i3.Polygon, [i3.NguiMapComponent], { editable: [0, "editable"], fillColor: [1, "fillColor"], fillOpacity: [2, "fillOpacity"], paths: [3, "paths"], strokeColor: [4, "strokeColor"], strokeOpacity: [5, "strokeOpacity"], strokeWeight: [6, "strokeWeight"] }, null)], function (_ck, _v) { var currVal_0 = false; var currVal_1 = "#FF8C00"; var currVal_2 = 0.35; var currVal_3 = _v.context.$implicit.paths; var currVal_4 = "#FF8C00"; var currVal_5 = 0.8; var currVal_6 = 2; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_TripHistoryRouteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ngui-map", [["class", "map-container"]], null, [[null, "mapReady$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady$" === en)) {
        var pd_0 = (_co.initMap($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NguiMapComponent_0, i2.RenderType_NguiMapComponent)), i1.ɵprd(512, null, i3.OptionBuilder, i3.OptionBuilder, []), i1.ɵprd(512, null, i3.NavigatorGeolocation, i3.NavigatorGeolocation, []), i1.ɵprd(131584, null, i3.GeoCoder, i3.GeoCoder, [i3.NgMapApiLoader]), i1.ɵprd(512, null, i3.NguiMap, i3.NguiMap, [i3.GeoCoder, i3.OptionBuilder, i1.NgZone]), i1.ɵdid(5, 13287424, [[1, 4]], 0, i3.NguiMapComponent, [i3.OptionBuilder, i1.ElementRef, i3.NavigatorGeolocation, i3.GeoCoder, i3.NguiMap, i3.NgMapApiLoader, i1.NgZone], { options: [0, "options"] }, { mapReady$: "mapReady$" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TripHistoryRouteComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TripHistoryRouteComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TripHistoryRouteComponent_4)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "heatmap-layer", [["class", "desktop-map-element"], ["dissipating", "true"], ["maxIntensity", "5"]], null, [[null, "initialized$"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("initialized$" === en)) {
        var pd_0 = (_co.initHeatmap($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 737280, [[2, 4]], 0, i3.HeatmapLayer, [i3.NguiMapComponent], { data: [0, "data"], dissipating: [1, "dissipating"], maxIntensity: [2, "maxIntensity"], radius: [3, "radius"] }, { initialized$: "initialized$" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mapOptions; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.startLocation && _co.startLocation.latitude) && _co.startLocation.longitude); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.endLocation && _co.endLocation.latitude) && _co.endLocation.longitude); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.polygons; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.heatmapPoints; var currVal_5 = "true"; var currVal_6 = "5"; var currVal_7 = 20; _ck(_v, 13, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_TripHistoryRouteComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "no-results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-locations"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["No GPS Data"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["This Trip does not have any associated GPS data"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); var currVal_1 = ""; _ck(_v, 7, 0, currVal_1); }, null); }
function View_TripHistoryRouteComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { nguiMapComponent: 0 }), i1.ɵqud(671088640, 2, { heatmapLayer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "trip-history-widget", [["fullBleed", "true"]], null, null, null, i6.View_TripHistoryWidgetComponent_0, i6.RenderType_TripHistoryWidgetComponent)), i1.ɵdid(3, 49152, null, 0, i7.TripHistoryWidgetComponent, [], { title: [0, "title"], fullBleed: [1, "fullBleed"] }, null), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 1, 4, "div", [["class", "trip-history-route"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripHistoryRouteComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripHistoryRouteComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Route")), ""); var currVal_1 = "true"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.mapData; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.mapData; _ck(_v, 9, 0, currVal_3); }, null); }
exports.View_TripHistoryRouteComponent_0 = View_TripHistoryRouteComponent_0;
function View_TripHistoryRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "trip-history-route", [], null, null, null, View_TripHistoryRouteComponent_0, RenderType_TripHistoryRouteComponent)), i1.ɵprd(512, null, i8.LocationUpdateService, i8.LocationUpdateService, [i9.HttpClient]), i1.ɵprd(512, null, i10.JobEventService, i10.JobEventService, [i9.HttpClient]), i1.ɵdid(3, 4243456, null, 0, i11.TripHistoryRouteComponent, [i8.LocationUpdateService, i10.JobEventService, i12.JobService], null, null)], null, null); }
exports.View_TripHistoryRouteComponent_Host_0 = View_TripHistoryRouteComponent_Host_0;
var TripHistoryRouteComponentNgFactory = i1.ɵccf("trip-history-route", i11.TripHistoryRouteComponent, View_TripHistoryRouteComponent_Host_0, { trip: "trip" }, {}, []);
exports.TripHistoryRouteComponentNgFactory = TripHistoryRouteComponentNgFactory;
