"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
// angular material
var material_1 = require("@angular/material");
// components
var ruckit_dropdown_component_1 = require("../../../shared/ruckit-dropdown/ruckit-dropdown.component");
var DispatchScheduleFiltersDialogComponent = /** @class */ (function () {
    function DispatchScheduleFiltersDialogComponent(translateService, dialogRef, data) {
        var _this = this;
        this.translateService = translateService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.errors = [];
        this.selectedOptions = [];
        this.jobs = [];
        this.jobsOriginal = [];
        this.jobsConfig = {
            selectText: this.translateService.instant('Select Job'),
            loadingText: this.translateService.instant('Loading Jobs...'),
            noResultsText: this.translateService.instant('No Jobs'),
            searchable: true,
        };
        this.projects = [];
        this.projectsOriginal = [];
        this.projectsConfig = {
            selectText: this.translateService.instant('Select Project'),
            loadingText: this.translateService.instant('Loading Projects...'),
            noResultsText: this.translateService.instant('No Projects'),
        };
        this.customers = [];
        this.customersOriginal = [];
        this.customersConfig = {
            selectText: this.translateService.instant('Select Customer'),
            loadingText: this.translateService.instant('Loading Customers...'),
            noResultsText: this.translateService.instant('No Customers'),
        };
        this.startLocations = [];
        this.endLocations = [];
        this.startLocationsOriginal = [];
        this.endLocationsOriginal = [];
        this.locationsConfig = {
            selectText: this.translateService.instant('Select Location'),
            loadingText: this.translateService.instant('Loading Locations...'),
            noResultsText: this.translateService.instant('No Locations'),
        };
        this.model = {
            jobNumber: null,
        };
        Object.keys(data.filterOptions).forEach(function (key) {
            _this[key] = data.filterOptions[key];
            _this[key + "Original"] = data.filterOptions[key].slice();
        });
        if (data.filterSelection.length) {
            this.selectedOptions = data.filterSelection;
            this.selectedOptions.forEach(function (o) {
                _this['selected' + o.title] = o.values[0];
            });
        }
    }
    DispatchScheduleFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        if (this.selectedOptions.findIndex(function (o) { return o.title === filterType; }) > -1) {
            this.selectedOptions = this.selectedOptions.filter(function (o) { return o.title !== filterType; });
            this["" + filterType[0].toLowerCase() + filterType
                .slice(1)
                .replace(/\s/g, '') + "sDropdown"].deselectOptions();
        }
        else {
            this.selectedOptions.push({
                title: filterType,
                displayValues: [e.name],
                values: [e.id || e.name],
                hidden: false,
            });
        }
    };
    DispatchScheduleFiltersDialogComponent.prototype.onSearch = function (type, search) {
        this[type] = this[type + "Original"].filter(function (j) {
            return j.name.toLowerCase().includes(search.toLowerCase());
        });
    };
    DispatchScheduleFiltersDialogComponent.prototype.clear = function () {
        this.selectedOptions = [];
        this.jobsDropdown.deselectOptions();
        this.projectsDropdown.deselectOptions();
        this.customersDropdown.deselectOptions();
        this.startLocationsDropdown.deselectOptions();
        this.endLocationsDropdown.deselectOptions();
        this.model.jobNumber = '';
    };
    DispatchScheduleFiltersDialogComponent.prototype.clearModelFilters = function () {
        this.selectedOptions = this.selectedOptions.filter(function (o) { return o.title !== 'Job #'; });
    };
    DispatchScheduleFiltersDialogComponent.prototype.submit = function () {
        this.clearModelFilters();
        if (this.model.jobNumber) {
            this.selectedOptions = this.selectedOptions.concat([
                {
                    title: 'Job #',
                    displayValues: [this.model.jobNumber],
                    values: [this.model.jobNumber],
                    hidden: false,
                }
            ]);
        }
        this.callback(this.selectedOptions);
        this.dialogRef.close();
    };
    return DispatchScheduleFiltersDialogComponent;
}());
exports.DispatchScheduleFiltersDialogComponent = DispatchScheduleFiltersDialogComponent;
