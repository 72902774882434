<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" class="context-menu-container">
  <ng-container *ngIf="currentDriverShiftData; else smallLoader">
    <div class="context-menu-title">
      <ng-container *ngIf="!showError; else showErrorTemplate">
        <i
          class="icomoon icon-uniE915"
          *ngIf="currentDriverShiftData.shiftStatus !== 'long'"
          [ngClass]="
            currentDriverShiftData.shiftStatus === 'active' ? 'green' : 'red'
          "
        ></i>
        <i
          class="icomoon icon-clock"
          *ngIf="currentDriverShiftData.shiftStatus === 'long'"
        ></i>
        <div class="title-text">
          <span class="shift-text">{{ currentDriverShiftData.text }}</span>
          <span
            class="time-text"
            [ngClass]="
              currentDriverShiftData.shiftStatus === 'long' ? 'red' : ''
            "
            >{{ currentDriverShiftData.value }}</span
          >
        </div>
      </ng-container>
    </div>
  </ng-container>
  <!-- removes focus on first item in the menu  -->
  <div [style.display]="'none'" aria-hidden="true" mat-menu-item></div>
  <ng-container *ngFor="let menu of contextMenuOptions">
    <div
      class="context-menu-row"
      mat-menu-item
      [ngClass]="{ disabled: menu.disabled }"
      [disabled]="menu.disabled"
    >
      <i *ngIf="menu.icon" ngClass="icon-{{ menu.icon }}"></i>
      <img *ngIf="menu.iconSvgName" [src]="'assets/img/' + menu.iconSvgName" />
      <div
        (click)="onContextMenuAction($event, menu)"
        [ngClass]="menu.classNames"
        translate
      >
        {{ menu.name }}
      </div>
    </div>
  </ng-container>
</mat-menu>

<ng-template #smallLoader>
  <my-loader class="full-height"></my-loader>
</ng-template>

<ng-template #showErrorTemplate>
  <div class="context-menu-title show-error" translate>
    Something went wrong. Try again later.
  </div>
</ng-template>
