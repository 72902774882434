"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ngx_device_detector_1 = require("ngx-device-detector");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var index_1 = require("../shared/index");
var preference_service_1 = require("../preferences/preference.service");
var edit_job_event_dialog_component_1 = require("../job-events/edit-job-event-dialog.component");
var edit_share_dialog_component_1 = require("../collaborators/edit-share-dialog.component");
var effective_rate_dialog_component_1 = require("./effective-rate-dialog.component");
var job_1 = require("./job");
var job_event_1 = require("../job-events/job-event");
var DayDetailsComponent = /** @class */ (function () {
    function DayDetailsComponent(deviceDetectorService, authenticationService, preferenceService, translateService, dialog) {
        var _this = this;
        this.deviceDetectorService = deviceDetectorService;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.translateService = translateService;
        this.dialog = dialog;
        this.includeJobStats = false;
        this.linkToJobEvent = false;
        this.controlPosition = 'right';
        this.view = 'dayDetails';
        this.errors = [];
        this.effectiveRatePreferences = {
            rate: 0,
            rateType: 'hour',
            effectiveTons: 0,
            effectiveTime: 0
        };
        this.effectiveRate = 0;
        this.effectiveRateUnit = '';
        this.tooltipMessage = '';
        this.saveJobEventCallback = function (e) {
            if (e && e.jobEvent) {
                _this.jobEvent = e.jobEvent;
            }
        };
        this.saveShareCallback = function (result) {
            var jobEventShare = result.jobEventShare;
            var updates = lodash_1.pick(jobEventShare, [
                'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
                'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
            ]);
            updates['rate'] = updates['invoiceRate'];
            Object.assign(_this.jobEvent, updates);
        };
        this.effectiveRateCallback = function (preference) {
            if (preference && preference.blob) {
                _this.effectiveRatePreferences = lodash_1.pick(preference.blob, ['rate', 'rateType', 'effectiveTons', 'effectiveTime']);
                _this.calculateEffectiveRate();
            }
        };
    }
    DayDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.user = this.authenticationService.user();
        this.getPreferences(function () {
            setTimeout(function () { _this.calculateEffectiveRate(); }, 750); // Wait for stats property to populate on jobEvent
        });
    };
    DayDetailsComponent.prototype.getPreferences = function (callback) {
        var _this = this;
        var params = {
            name: 'EffectiveRateUserPreferences',
            type: 'user',
            profile: this.user.id
        };
        this.preferenceService.list(params).subscribe(function (preferences) {
            if (preferences.length) {
                _this.effectiveRatePreferences = lodash_1.pick(preferences[0].blob, ['rate', 'rateType', 'effectiveTons', 'effectiveTime']);
            }
        });
        callback();
    };
    DayDetailsComponent.prototype.openEditJobEvent = function () {
        var dialog = this.dialog.open(edit_job_event_dialog_component_1.EditJobEventDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.callback = this.saveJobEventCallback;
        }
    };
    DayDetailsComponent.prototype.openEditShare = function (change) {
        var dialog = this.dialog.open(edit_share_dialog_component_1.EditShareDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.change = change;
            dialog.componentInstance.callback = this.saveShareCallback;
        }
    };
    DayDetailsComponent.prototype.openEffectiveRate = function () {
        var dialog = this.dialog.open(effective_rate_dialog_component_1.EffectiveRateDialogComponent, {
            width: '430px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.user = this.user;
            dialog.componentInstance.model = {
                rate: this.effectiveRatePreferences.rate,
                rateType: this.effectiveRatePreferences.rateType,
                effectiveTons: this.effectiveRatePreferences.effectiveTons,
                effectiveTime: this.effectiveRatePreferences.effectiveTime
            };
            dialog.componentInstance.callback = this.effectiveRateCallback;
        }
    };
    DayDetailsComponent.prototype.calculateEffectiveRate = function () {
        if (this.jobEvent && this.jobEvent.stats) {
            var effectiveTons = this.effectiveRatePreferences.effectiveTons === 0 ?
                this.jobEvent.stats.totalTons : this.jobEvent.stats.ticketedWeight;
            var effectiveTime = this.effectiveRatePreferences.effectiveTime === 0 ?
                this.jobEvent.stats.totalTripHours : this.jobEvent.stats.totalCompletedTripHours;
            var effectiveTimeTitle = this.effectiveRatePreferences.effectiveTime === 0 ?
                this.translateService.instant('Total Active Trip Time') :
                this.translateService.instant('Total Completed Trip Time');
            var effectiveTonsTitle = this.effectiveRatePreferences.effectiveTons === 0 ?
                this.translateService.instant('Tickets Delivered') :
                this.translateService.instant('Tickets Ticketed');
            if (this.effectiveRatePreferences.rateType === 'ton') {
                this.effectiveRate = effectiveTons > 0 ? (this.effectiveRatePreferences.rate * effectiveTons) / effectiveTime : 0;
                this.effectiveRateUnit = 'hour';
                this.tooltipMessage = this.translateService.instant('Ton Rate') + " (" + this.effectiveRatePreferences.rate + ") * " + effectiveTonsTitle + " (" + effectiveTons + ") / " + effectiveTimeTitle + " (" + effectiveTime + ")";
            }
            else if (this.effectiveRatePreferences.rateType === 'hour') {
                this.effectiveRate = effectiveTime > 0 ? (this.effectiveRatePreferences.rate * effectiveTime) / effectiveTons : 0;
                this.effectiveRateUnit = 'ton';
                this.tooltipMessage = this.translateService.instant('Hourly Rate') + " (" + this.effectiveRatePreferences.rate + ") * " + effectiveTimeTitle + " (" + effectiveTime + ") / " + effectiveTonsTitle + " (" + effectiveTons + ")";
            }
        }
    };
    return DayDetailsComponent;
}());
exports.DayDetailsComponent = DayDetailsComponent;
