<form #newProject="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>New Project</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <p translate>
        Create a new project so you can group this job with similar jobs.
      </p>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group name" fxFlex="100">
          <label class="required" translate>Project Title</label>
          <input type="hidden" name="customer_organization" required [(ngModel)]="model.customerOrganization" #customerOrganization="ngModel" />
          <input type="text" name="project-name" placeholder="{{ 'Project Title' | translate }}" required [(ngModel)]="model.name" #name="ngModel" />
          <span [ngClass]="{'ng-valid': name.valid, 'ng-invalid': name.invalid, 'ng-pristine': name.pristine}"></span>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="newProject.form.valid && submit(); false"
            [disabled]=" ! newProject.form.valid || loading"
            [ngClass]="{loading:loading}"
            translate>
      Save Project
    </button>
  </div>
</form>
