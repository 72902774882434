import { timer as observableTimer, Subscription, Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

// angular material
import { MatDialog } from '@angular/material';

// libraries
import * as moment from 'moment';

// models
import { ShiftReport } from '../../shifts/shift-report';

// services
import { ShiftDashboardService } from '../../shifts/shift-dashboard.service';
import { AuthenticationService } from '../../shared';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'daily-shifts',
  templateUrl: './daily-shifts.component.html',
  styleUrls: ['./daily-shifts.component.scss'],
  providers: []
})

export class DailyShiftsComponent implements OnInit, OnDestroy {
  shifts: ShiftReport[] = [];
  loading = true;
  errors = [];
  sortBy = 'driver__first_name';
  sortAsc = true;
  sortParameter;
  private shiftsTimerSub;
  shiftsReq: Subscription;
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor(
    private shiftDashboardService: ShiftDashboardService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    if (window.innerWidth > 900) {
      this.getShifts({}, true);
      this.shiftsTimerSub = observableTimer(1, 120000);
      this.shiftsTimerSub.subscribe(t => {
        this.getShifts({}, false);
      });
    }
  }

  ngOnDestroy() {
    if (this.shiftsTimerSub) {
      try { this.shiftsTimerSub.unsubscribe(); } catch (e) { }
    }
    if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
      this.shiftsReq.unsubscribe();
    }
  }

  onResize(event) {
    if (this.shifts.length === 0) { this.ngOnInit(); }
  }

  getShifts(query = {}, loading = true) {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    this.loading = loading;
    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
      this.shiftsReq.unsubscribe();
    }

    this.shiftsReq = this.shiftDashboardService.list({
      ordering: order,
      ...query,
      start_time__gte: startDate.toISOString(),
      start_time__lte: endDate.toISOString()
    }).subscribe(shifts => {
      const momentObj = moment();
      this.shifts = shifts;
      // TODO: Should figure out whether on active shift or nor and calculate the shift duration.
      // moment();
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getShifts({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
  }

  openContextMenu(event: any, shift: ShiftReport) {
    this.contextMenuEventSubject.next({
      event,
      shiftReport: shift,
      driverId: shift.driver.id,
    });
  }
}
