"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var shared_1 = require("../../shared");
var device_1 = require("../../shared/device");
var table_constants_1 = require("./table-constants");
var lodash_1 = require("lodash");
var moment = require("moment");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var job_event_share_service_1 = require("../../job-event-shares/job-event-share.service");
var HaulerSharesComponent = /** @class */ (function () {
    function HaulerSharesComponent(route, router, translateService, deviceDetectorService, authenticationService) {
        this.route = route;
        this.router = router;
        this.translateService = translateService;
        this.deviceDetectorService = deviceDetectorService;
        this.authenticationService = authenticationService;
        this.device = new device_1.Device();
        this.search = '';
        this.loading = true;
        this.canCreateJobs = true;
        this.hasAllDriversEnabled = false;
        this.sortAsc = true;
        this.availableColumns = table_constants_1.AVAILABLE_COLUMNS(this.translateService).slice();
        this.displayedColumns = table_constants_1.DISPLAYED_COLUMNS.slice();
        this.availableFilters = table_constants_1.AVAILABLE_FILTERS().slice();
        this.appliedFilters = [];
        this.haulerSharesTableConfig = {
            hasHeader: true,
            pageSize: 25,
            service: job_event_share_service_1.JobEventShareService,
            preferenceKey: 'HaulerSharesComponent-JobEventSharesService',
            preferencesEnabled: true,
            query: {},
            filterQuery: true,
            collectionTitle: this.translateService.instant('Shares'),
            hasNoResultsAction: false,
            customHeight: true,
            automaticallyHidePagination: false,
        };
    }
    HaulerSharesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.canCreateJobs = this.authenticationService.canCreateJobs();
        this.hasAllDriversEnabled =
            this.authenticationService.hasAllDriversEnabled();
        this.route.queryParams.forEach(function (params) {
            _this.loading = true;
            _this.search = params['search'] || '';
            if (params['date']) {
                _this.jobEventDate = moment(params['date'], 'YYYYMMDD').toDate();
            }
        });
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop(),
        };
    };
    HaulerSharesComponent.prototype.ngOnChanges = function (changes) {
        var jobEventDateStart = new Date();
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        if (changes['jobEventDate']) {
            var jobEventDate = changes['jobEventDate'].currentValue;
            if (jobEventDate) {
                jobEventDateStart = new Date(this.jobEventDate);
                jobEventDateEnd = lodash_1.clone(jobEventDateStart);
            }
        }
        else if (this.jobEventDate) {
            jobEventDateStart = new Date(this.jobEventDate);
            jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        }
        jobEventDateStart.setHours(0, 0, 0, 0);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        this.haulerSharesTableConfig['query'] = {
            exclude_pending: 'True',
            jobevent__shift1_start__gte: jobEventDateStart.toISOString(),
            jobevent__shift1_start__lte: jobEventDateEnd.toISOString(),
        };
        if (this.authenticationService.hasFavoriteTags()) {
            this.haulerSharesTableConfig['query']['user_tags'] = 'True';
        }
        if (this.haulerSharesTable) {
            this.haulerSharesTable.config = __assign({}, this.haulerSharesTableConfig);
        }
    };
    HaulerSharesComponent.prototype.getRecords = function () {
        var startDate = this.jobEventDate ? lodash_1.clone(this.jobEventDate) : new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        this.haulerSharesTable.query = __assign({}, this.haulerSharesTable.query, { exclude_pending: 'True', jobevent__shift1_start__gte: startDate.toISOString(), jobevent__shift1_start__lte: endDate.toISOString() });
    };
    HaulerSharesComponent.prototype.onDateChanged = function (dates) {
        this.jobEventDate = dates[0];
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        var date = moment(this.jobEventDate).format('YYYYMMDD');
        queryParams['date'] = date;
        queryParams['search'] = this.search;
        this.router.navigate([], { queryParams: queryParams });
        this.getRecords();
    };
    HaulerSharesComponent.prototype.onSearchClear = function () {
        this.search = '';
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    HaulerSharesComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return HaulerSharesComponent;
}());
exports.HaulerSharesComponent = HaulerSharesComponent;
