import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absoluteValue'
})
export class AbsoluteValuePipe implements PipeTransform {
  transform(value): string {
    if (!value) { return '0'; }
    return Math.abs(parseFloat(value) || 0).toString();
  }
}
