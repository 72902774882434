import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResourceService } from '../shared/resource.service';
import { environment } from '../../environments/environment';
import { LocationOwnerSerializer } from './location-owner.serializer';
import { LocationOwner } from './location-owner';

@Injectable()
export class LocationOwnerService extends ResourceService<LocationOwner> {
  organizationId: string;
  senderId: string;
  baseUrl = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http, 'locationowners/', new LocationOwnerSerializer());
  }
}
