<form #sendNotes="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Send Note</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="selected-shares">
        <div class="row">
          <div class="field notes">
            <div class="label" translate>Notes</div>
            <div class="input-group">
              <textarea [(ngModel)]="notes" name="share_notes"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary save-button"
      (click)="sendNotes.form.valid && submit(); false"
      [disabled]="!sendNotes.form.valid || sendNotes.pristine || loading"
      [ngClass]="{loading: loading}"
      translate>
      Send Note
    </button>
  </div>
</form>
