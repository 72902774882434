import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'void-ticket-dialog',
  templateUrl: './void-ticket-dialog.component.html',
  styleUrls: ['./void-ticket-dialog.component.scss']
})
export class VoidTicketConfirmDialogComponent {
  attributes: any = {};

  createCopy = false;

  constructor(public confirmDialog: MatDialogRef<any>) {
    this.attributes = {
      title: 'Confirm your action!',
      body: 'Are you sure you wish to do this?',
      close: 'Cancel',
      accept: 'Continue'
    };
  }

  onVoidButtonClicked() {
    let voidObject = { create_copy: this.createCopy };
    this.confirmDialog.close(voidObject);
  }
}
