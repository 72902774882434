import { FilterField } from '../../shared/filters-dialog/filter-field';
import { ConnectionService } from '../../connections/connection.service';
import { DropdownConfig } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

export const FILTERS: FilterField[] = [
  {
    type: 'dateRange',
    field: 'dateRange',
    label: 'Date Range',
  },
  {
    type: 'boolean',
    field: 'truckChanged',
    label: 'Changed Trucks',
  },
  {
    type: 'number',
    field: 'lunchBreak',
    label: 'Lunch Break Duration Minimum',
  },
  {
    type: 'boolean',
    field: 'includeLeasedFleetDrivers',
    label: 'Include Leased Fleet Drivers',
  },
  {
    type: 'dropdown',
    field: 'carrier',
    label: 'Carriers',
    dropdownConfig: <DropdownConfig>{
      service: ConnectionService,
      idProperty: 'organization.carrier.id',
    },
  },
];
