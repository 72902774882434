<div class="search-container {{ loading ? 'loading' : '' }}">
  <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
</div>

<div class="drivers" [ngClass]="{driver_filters: filtersApplied}" *ngIf="!loading">
  <div class="driver" *ngFor="let _driver of drivers"
    [ngClass]="{selected: driver === _driver}"
    (click)="selectDriver(_driver)">
    <div class="driver-detail">
      <div class="driver-name" (contextmenu)="openContextMenu($event, _driver.id)">{{ _driver.name }}</div>
      <div class="truck-name sub-title">
        {{ _driver.latestTruck && _driver.latestTruck.displayName }}
      </div>
      <div class="organization-name sub-title">
        {{ _driver.organization }}
      </div>
      <div class="sub-title replay">
        <a *ngIf="reportDate" class="replay-link"
            routerLinkActive="active" [routerLink]="['/replay']"
            [queryParams]="{ date: reportDate.toISOString() | moment: 'YYYYMMDD', driver: _driver.id }"
            target="_blank"
            translate>View Replay</a>
      </div>
    </div>

    <div class="counts">
      <div class="planned-trips">
        {{ 'Planned' | translate }}<br />
        <span>{{ _driver.loadCount || 0 }}</span>
      </div>
      <div class="completed-trips">
        {{ 'Completed' | translate }}<br />
        <span>{{ _driver.completedTripsCount || 0 }}</span>
      </div>
    </div>

    <div class="approval">
      <div *ngIf="!loading" [ngClass]="{
        approved: _driver.allDecisionsApproved,
        unapproved: !_driver.allDecisionsApproved
      }">
        <span class="icon-success"></span>
      </div>
    </div>
  </div>

  <no-results itemName="Drivers" addText="{{ 'data for drivers' | translate }}"
    [styles]="{'min-height': 'calc(100% - 20px)', 'margin-top': '20px', 'background-color': 'transparent'}"
    [results]="drivers.length" [hasAction]="false" [search]="search"
    [loading]="loading" [bordered]="true" (clearSearch)="changeSearch()"></no-results>
</div>
<app-driver-context-menu *ngIf="contextMenuOpened" [contextMenuEventSubject]="contextMenuEventSubject">
</app-driver-context-menu>
<my-loader *ngIf="loading" class="full-height"></my-loader>