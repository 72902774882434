import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { remove, omit, difference, get, pull, find as _find } from 'lodash';

import { InvoiceService } from  './invoice.service';
import { TripService } from  '../trips/trip.service';
import { NewInvoiceDialogComponent } from './new-invoice-dialog.component';
import { InvoiceFiltersDialogComponent } from './invoice-filters-dialog.component';
import { parseErrors } from '../shared/api.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';

@Component({
  selector: 'ruckit-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [InvoiceService, TripService]
})
export class InvoicesComponent implements OnInit, OnDestroy {
  invoices: any = [];
  loading = true;
  errors = [];
  count = 0;
  page = 1;
  pageSize = 25;
  firstRun = true;
  selectedCount = {
    draft: 0,
    sent: 0
  };
  allSelected = {
    draft: false,
    sent: false
  };
  selectedInvoices = {
    draft: [],
    sent: []
  };
  excludeInvoices = {
    draft: [],
    sent: []
  };
  state = 'draft';
  invoicesReq;
  search = '';
  sortBy;
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  selectedInvoice;
  confirmDialog: MatDialogRef<any>;
  lastExpensed = null;
  dropdownOptions = {
    draft: [
      { name: 'Export', button: true }
    ],
    sent: [
      { name: 'Export', button: true },
      { name: 'Download', button: true}
    ]
  };
  expenseLabel = 'Make Expenses';
  menuOptions = [
    { name: 'Edit', action: 'edit', link: true, external: false },
    { name: 'Remove', action: 'remove', link: false, external: false },
    { name: 'Send', action: 'send', link: false, external: false },
    { name: 'Resend', action: 'resend', link: false, external: false },
    { name: 'Unlock', action: 'unlock', link: false, external: false },
    { name: this.expenseLabel, action: 'expense', link: false, external: false },
    { name: 'View', action: 'view', link: true, external: true },
    { name: 'Download', action: 'download', link: true, external: true },
    { name: 'Export', action: 'export', link: false, external: false },
    { name: 'Preview', action: 'preview', link: true, external: false}
  ];
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  newInvoiceCallback = (e) => {
    // Update trip data
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private tripService: TripService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        this.sortBy = result.qparams.sortBy ? result.qparams.sortBy : 'created_at';
        this.sortAsc = result.qparams.sortAsc ? (result.qparams.sortAsc === 'true') : false;
        this.page = result.qparams.page ? result.qparams.page : 1;
        this.state = result.params['state'] ? result.params['state'] : 'draft';

        this.getInvoices();
      })
    );

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.carrier) {
      this.expenseLabel = 'Make Expenses & Paystubs';
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  pageChange(event) {
    this.page = event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        page: this.page > 1 ? this.page : 1
      }
    });
  }

  getInvoices(query = {}) {
    this.loading = true;
    this.invoices = [];
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return {...acc, ...filter.query};
    }, {});
    delete filters['undefined'];

    if (this.invoicesReq) { this.invoicesReq.unsubscribe(); }

    this.invoicesReq = this.invoiceService.getInvoices({
      ordering: order,
      search: this.search,
      page_size: this.pageSize,
      page: this.page,
      sent: this.state === 'draft' ? 'False' : 'True',
      ...omit(filters, 'jobDay'),
      ...query
    }).subscribe(invoices => {
      this.invoices = invoices;
      this.count = this.invoiceService.count;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortBy: this.sortBy,
        sortAsc: this.sortAsc
      }
    });
    this.loading = true;
    this.getInvoices({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getInvoices({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  openNewInvoice() {
    const dialog = this.dialog.open(NewInvoiceDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = this.newInvoiceCallback;
  }

  changeSearch(term?: string) {
    this.search = term || '';
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['search'] = this.search;
    this.router.navigate(['invoices', 'filter', this.state], { queryParams: queryParams });

    this.getInvoices();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters() {
    const dialog = this.dialog.open(InvoiceFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);
    dialog.componentInstance.model.job = get(_find(this.filters, {key: 'job'}), 'value');
    dialog.componentInstance.model.customer = get(_find(this.filters, {key: 'customer'}), 'value');

    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'customer_organization',
      job: 'job',
      sent: 'sent',
      startDate: 'jobday_start__gte',
      endDate: 'jobday_end__lte'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
          let filterValue = value.toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let displayKey = key;
      let hidden = false;
      if (key === 'startDate') { hidden = true; }
      if (key === 'endDate') { hidden = true; }
      if (key === 'jobDay') { displayKey = 'Job Day'; }
      let filter = {
        key: displayKey,
        value: displayValue || value,
        query: query,
        hidden: hidden
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });

    this.filters = difference(this.filters, falseyFilters);
    this.getInvoices();
  }

  removeFilter(filter) {
    if (filter.key === 'Job Day') {
      remove(this.filters, _find(this.filters, { key: 'Start Date' }));
      remove(this.filters, _find(this.filters, { key: 'End Date' }));
    }
    remove(this.filters, filter);
    this.getInvoices();
  }

  menuAction(name, invoice) {
    switch (name) {
      case 'remove':
        this.removeInvoice(invoice);
        break;
      case 'send':
        this.sendInvoice(invoice);
        break;
      case 'resend':
        this.resendInvoice(invoice);
        break;
      case 'expense':
        this.createExpenseAndPaystubs(invoice);
        break;
      case 'export':
        this.createExport([invoice.id]);
        break;
      case 'unlock':
        this.unlockInvoice(invoice);
        break;
    }
  }

  removeInvoice(invoice) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Remove Invoice?',
      body: 'This invoice will be deleted and cannot be recovered.',
      close: 'Cancel',
      accept: 'Remove'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.invoiceService.remove(invoice).subscribe((res) => {
          this.getInvoices();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  sendInvoice(invoice) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Send Invoice?',
      body: 'Are you sure you want to send this invoice? You will no longer be able to edit the invoice once it has been sent.',
      close: 'Cancel',
      accept: 'Send'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.invoiceService.send(invoice).subscribe((res) => {
          invoice = res;
          this.getInvoices();
          this.loading = false;
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  unlockInvoice(invoice) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unlock Invoice?',
      body: 'This invoice will be unlocked and can be modified.',
      close: 'Cancel',
      accept: 'Unlock'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.invoiceService.unlock(invoice).subscribe((res) => {
          this.getInvoices();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  resendInvoice(invoice) {
    this.loading = true;
    this.invoiceService.send(invoice).subscribe((res) => {
      invoice = res;
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  createExpenseAndPaystubs(invoice) {
    this.loading = true;
    this.invoiceService.expense(invoice.id).subscribe(() => {
      this.loading = false;
      this.lastExpensed = invoice.number;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  createExport(invoices, section = null) {

    let scope = {};
    let params = new HttpParams();

    if (invoices && invoices.length) {
      Object.assign(scope, { invoices: invoices });
    } else if (this.allSelected) {
      Object.assign(scope, { excludeInvoices: this.excludeInvoices[section] });
      Object.keys(this.filters).map(key => {
        if (this.filters[key]) {
          let query = this.filters[key]['query'];
          Object.keys(query).map(queryKey => {
            params = params.set(queryKey, query[queryKey]);
          });
        }
      });
      params = params.set('search', this.search);
      params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
    }

    this.dialog.open(ExportDialogComponent, {
      width: '430px',
      data: <ExportDialogData>{
        type: 'invoices',
        buttonText: 'Export Data to CSV',
        params: params,
        scope: scope,
        service: this.invoiceService
      }
    });
  }

  createDownload(invoices, section = null) {
    let scope = {};
    let params = new HttpParams();

    if (invoices && invoices.length) {
      Object.assign(scope, { invoices: invoices });
    } else if (this.allSelected) {
      Object.assign(scope, { excludeInvoices: this.excludeInvoices[section] });
      Object.keys(this.filters).map(key => {
        if (this.filters[key]) {
          let query = this.filters[key]['query'];
          Object.keys(query).map(queryKey => {
            params = params.set(queryKey, query[queryKey]);
          });
        }
      });
      params = params.set('search', this.search);
      params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
    }
    this.invoiceService.downloadPdfs(scope, params).subscribe(() => {
      this.loading = false;
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '430px',
        height: '250px'
      });
      this.confirmDialog.componentInstance.attributes = {
        title: 'Download Link Sent',
        body: 'Your zip file with the selected invoice data is on its way to your inbox',
        close: 'Close',
        accept: null
      };
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  displayLastExpensed(): boolean {
    if (this.lastExpensed) {
      return true;
    } else {
      return false;
    }
  }

  selector(event, section = 'draft', invoice = null) {
    if (invoice) {
      if (!event.target.checked) {
        invoice.selected = false;
        pull(this.selectedInvoices[section], invoice.id);
        if (this.allSelected) {
          this.excludeInvoices[section].push(invoice.id);
          this.selectedCount[section] = (this.count - this.excludeInvoices[section].length);
        } else {
          this.selectedCount[section] = this.selectedInvoices[section].length;
        }
      } else {
        invoice.selected = true;
        if (this.allSelected[section]) {
          pull(this.excludeInvoices[section], invoice.id);
          this.selectedCount[section] = (this.count - this.excludeInvoices[section].length);
        } else {
          this.selectedInvoices[section].push(invoice.id);
          this.selectedCount[section] = this.selectedInvoices[section].length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected[section] = false;
        this.invoices.forEach((_invoice) => { _invoice.selected = false; });
        this.selectedCount[section] = 0;
      } else {
        this.allSelected[section] = true;
        this.selectedCount[section] = (this.count - this.excludeInvoices[section].length);
      }
      this.selectedInvoices[section] = [];
      this.excludeInvoices[section] = [];
    }
  }

  setSelectedAction(option, section) {
    switch (option.name) {
      case 'Export':
        this.createExport(this.selectedInvoices[section], section);
        break;
      case 'Download':
        this.createDownload(this.selectedInvoices[section], section);
        break;
    }
  }
}
