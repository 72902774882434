"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./collapsible-section.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i6 = require("@angular/material/icon");
var i7 = require("@ngx-translate/core");
var i8 = require("./collapsible-section.component");
var styles_CollapsibleSectionComponent = [i0.styles];
var RenderType_CollapsibleSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollapsibleSectionComponent, data: {} });
exports.RenderType_CollapsibleSectionComponent = RenderType_CollapsibleSectionComponent;
function View_CollapsibleSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "collapsible-section"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "left-controls": 0, "right-controls": 1 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "left-controls": 0, "right-controls": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "collapse-control"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.collapsed = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "section-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "collapsible-section"; var currVal_1 = _ck(_v, 3, 0, (_co.controlPosition === "left"), (_co.controlPosition === "right")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.controlPosition === "left"), (_co.controlPosition === "right")); var currVal_3 = "collapsible-section"; _ck(_v, 4, 0, currVal_2, currVal_3); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 8).inline; var currVal_5 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_4, currVal_5); }); }
function View_CollapsibleSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "collapsible-section collapsed"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.collapsed = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "left-controls": 0, "right-controls": 1 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "left-controls": 0, "right-controls": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h3", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "expand-control"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.collapsed = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(12, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["keyboard_arrow_right"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "collapsible-section collapsed"; var currVal_1 = _ck(_v, 3, 0, (_co.controlPosition === "left"), (_co.controlPosition === "right")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.controlPosition === "left"), (_co.controlPosition === "right")); var currVal_3 = "collapsible-section collapsed"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = ""; _ck(_v, 8, 0, currVal_4); _ck(_v, 12, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.title; _ck(_v, 9, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 12).inline; var currVal_7 = (((i1.ɵnov(_v, 12).color !== "primary") && (i1.ɵnov(_v, 12).color !== "accent")) && (i1.ɵnov(_v, 12).color !== "warn")); _ck(_v, 11, 0, currVal_6, currVal_7); }); }
function View_CollapsibleSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.collapsed; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.collapsed; _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_CollapsibleSectionComponent_0 = View_CollapsibleSectionComponent_0;
function View_CollapsibleSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "collapsible-section", [], null, null, null, View_CollapsibleSectionComponent_0, RenderType_CollapsibleSectionComponent)), i1.ɵdid(1, 49152, null, 0, i8.CollapsibleSectionComponent, [], null, null)], null, null); }
exports.View_CollapsibleSectionComponent_Host_0 = View_CollapsibleSectionComponent_Host_0;
var CollapsibleSectionComponentNgFactory = i1.ɵccf("collapsible-section", i8.CollapsibleSectionComponent, View_CollapsibleSectionComponent_Host_0, { controlPosition: "controlPosition", title: "title", collapsed: "collapsed" }, {}, ["*"]);
exports.CollapsibleSectionComponentNgFactory = CollapsibleSectionComponentNgFactory;
