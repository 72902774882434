import { OnInit, Component, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { DriverService } from './../driver.service';
import { TruckService } from '../../trucks/truck.service';
import { parseErrors } from '../../shared/api.service';
import { DRIVER_DUTY_OPTIONS } from '../../drivers/driver';

@Component({
  selector: 'assign-truck-dialog',
  templateUrl: './assign-truck-dialog.component.html',
  styleUrls: ['./assign-truck-dialog.component.scss'],
  providers: [DriverService, TruckService]
})
export class AssignTruckDialogComponent implements OnInit {
  title: string;
  message: string;
  searching = false;
  truckChanged = false;
  truckOptions = [];
  truckDropdownConfig = {
    subtitle: true,
    subtitleProperty: 'listName',
    image: true,
    imageProperty: 'image',
    nameProperty: 'truckTypeName',
    searchable: true
  };
  requireOnDuty = false;
  isOnDuty = false;
  dutyStatusOptions: any = undefined;
  dutyStatusConfig = { nameProperty: 'name' };
  loading = false;
  driver: any = {};
  truck: any = {};
  jobEvent: any = {};
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<AssignTruckDialogComponent>,
    private translateService: TranslateService,
    private driverService: DriverService,
    private truckService: TruckService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.title = this.translateService.instant('Assign a Truck');

    if (!this.driver.carrier) { // Required to retrieve trucks
      this.errors = ['Driver has no carrier associated, unable to get trucks.'];
      return;
    }

    // Check if driver is on/off duty and alter message accordingly, load driver duty status options
    this.isOnDuty = this.driver.dutyStatus === 'on-duty';
    if (this.requireOnDuty && !this.isOnDuty) {
      this.message = this.translateService.instant('The driver ' + this.driver.name + ' does not have an assigned truck or is off duty. Select a truck and change to on duty in order to assign to ' + this.jobEvent.jobDisplayName + '.');
      this.dutyStatusOptions = DRIVER_DUTY_OPTIONS;
    } else {
      this.message = this.translateService.instant('Please assign a Truck to ' + this.driver.name + ' before assigning them to a job.');
    }

    this.loading = true;
    this.getTrucks();
  }

  getTrucks(query = {}) {
    this.loading = true;
    this.truckService.list({
      carrier: this.driver.carrier.id,
      jobevent_available: this.jobEvent.id,
      ...query
    }).subscribe(
      trucks => {
        this.truckOptions = trucks;
        this.loading = false;
        this.searching = false;
      },
      (err) => {
        this.errors = err;
        this.loading = false;
        this.searching = false;
      }
    );
  }

  selectTruck(truck) {
    this.truck = truck;
    this.driver.truck = truck;
    this.truckChanged = true;
  }

  submit() {
    this.loading = true;
    this.setDriverOnDuty()
      .subscribe(success => {
        if (success) {
          this.driverService.assign(this.driver).subscribe((res) => {
            this.dialogRef.close();
            res.truck = this.truck;
            this.callback(res);
          }, (err) => {
            this.errors = parseErrors(err);
            this.loading = false;
          });
        } else {
          this.errors = ['Unable to update driver duty status to On Duty'];
          this.loading = false;
        }
      });
  }

  dropdownNextPage(e, type) {
    let config;
    let service;
    let options;

    switch (type) {
      case 'truck':
        config = this.truckDropdownConfig;
        service = this.truckService;
        options = this.truckOptions;
        break;
    }

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'truck':
              this.truckOptions = this.truckOptions.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  dropdownSearch(term = '', type) {
    switch (type) {
      case 'truck':
        this.searching = true;
        this.getTrucks({ search: term });
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  optionSelected(value: any, field: string): void {
    switch (field) {
      case 'dutyStatus':
        this.isOnDuty = value === 'on-duty';
        break;
    }
  }

  private setDriverOnDuty(): Observable<boolean> {
    return (!this.requireOnDuty || this.driver.dutyStatus === 'on-duty') ?
      of(true) :
      this.driverService.save({ id: this.driver.id, dutyStatus: 'on-duty' })
        .pipe(
          tap(driver => this.driver.dutyStatus = driver.dutyStatus),
          map(() => this.driver.dutyStatus === 'on-duty'),
          catchError(() => { return of(false); })
        );
  }
}
