import { Component, Input, OnChanges } from '@angular/core';
import { clone } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../shared';
import { parseErrors } from '../../shared/api.service';
import { EodFilter } from '../eod-filter';
import { EndOfDayOverallStats } from './end-of-day-overall-stats';
import { EndOfDayOverallStatsService } from './end-of-day-overall-stats.service';

@Component({
  selector: 'end-of-day-overall-stats',
  templateUrl: './end-of-day-overall-stats.component.html',
  styleUrls: ['./end-of-day-overall-stats.component.scss'],
})
export class EndOfDayOverallStatsComponent implements OnChanges {
  @Input() reportDate: Date;
  @Input() search: string;
  @Input() filters: EodFilter[];
  stats$: Observable<EndOfDayOverallStats>;
  errors = [];

  constructor(
    private endOfDayOverallStatsService: EndOfDayOverallStatsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnChanges() {
    this.getEndOfDayOverallStats();
  }

  getEndOfDayOverallStats() {
    const startDate = this.reportDate;
    startDate.setHours(0, 0, 0, 0);
    const endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);
    const query = {};

    const carrierFilter = this.filters.find((c) => c.key === 'carrier');
    const carrierFilterValue =
      carrierFilter &&
      carrierFilter.values.length > 0 &&
      carrierFilter.values[0].value;

    const currentUserCarrier = this.authenticationService.getOrganization();
    const currentUserOrganizationId =
      currentUserCarrier &&
      currentUserCarrier.carrier &&
      currentUserCarrier.carrier.id
        ? currentUserCarrier.carrier.id
        : '';

    if (this.authenticationService.hasFavoriteTags()) {
      query['user_tags'] = 'True';
    }

    this.stats$ = this.endOfDayOverallStatsService
      .get(
        null,
        {
          active_range: `${startDate.toISOString()},${endDate.toISOString()}`,
          search: this.search,
          ...(carrierFilterValue
            ? {
                carrier:
                  carrierFilterValue === currentUserOrganizationId
                    ? null
                    : carrierFilterValue,
              }
            : { all_carriers: 'True' }),
          ...this.parseFiltersToQuery([...this.filters]),
          ...query,
        },
        false
      )
      .pipe(
        catchError((err) => {
          this.errors = parseErrors(err);
          return throwError(err);
        })
      );
  }

  parseFiltersToQuery(filters: EodFilter[]) {
    const activeFilters = filters.filter(
      (f) =>
        (f.key !== 'carrier' && f.values && f.values.length > 0) ||
        f.selected === true
    );
    return activeFilters.reduce(
      (result, item) => ({
        [item.key]:
          item.values && item.values.length ? item.values[0].value : 'True',
      }),
      {}
    );
  }
}
