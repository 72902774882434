<form #newJobReport="ngForm" [ngClass]="{
  'mobile': device && device.mobile,
  'tablet': device && device.tablet,
  'desktop': device && device.desktop || !device
}">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add a Driver Job Report</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group job" fxFlex="100">
          <label class="required" translate>Job</label>
          <ruckit-dropdown #jobDropdown
            [config]="jobDropdownConfig"
            [selectedOption]="model.job && model.job.id"
            (onUserSelect)="onSelect('job', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group driver" fxFlex="100">
          <label class="required" translate>Driver</label>
          <ruckit-dropdown #driverDropdown
            [config]="driverDropdownConfig"
            [selectedOption]="model.driver && model.driver.id"
            (onUserSelect)="onSelect('driver', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>

  <div class="modal-footer">
    <button id="submit-new-job-report-button" class="btn btn-primary" (click)="submit(); false"
      [disabled]="!newJobReport.form.valid || loading" [ngClass]="{loading: loading}" translate>
      Add Driver Job Report
    </button>
  </div>
</form>
