import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { parseErrors } from '../api.service';

import { UserNotificationService } from '../user-notifications/user-notification.service';
import { UserNotification } from '../user-notifications/user-notification';

@Component({
  selector: 'user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
  providers: [UserNotificationService]
})

export class UserNotificationsComponent implements OnInit, OnDestroy {
  @Input() viewType: 'navbar' | 'fullview' = 'navbar';
  notificationDays: { date: string, notifications: UserNotification[] }[] = [];
  notifications: UserNotification[] = [];
  notificationsReq: Subscription;
  notificationsLoading = false;
  unreadCount: number;
  unreadCountLabel: string;
  pageSize = 1;

  errors = [];

  constructor(
    private router: Router,
    private userNotificationService: UserNotificationService,
  ) { }

  ngOnInit() { this.getNotifications(); }

  ngOnDestroy() {
    if (this.notificationsReq && typeof this.notificationsReq.unsubscribe === 'function') {
      this.notificationsReq.unsubscribe();
    }
  }

  onScroll(e: Event) {
    if (this.viewType === 'fullview' &&
      (e.target['scrollTop'] > e.target['scrollHeight'] - e.target['clientHeight'] * 3)
    ) { this.getNextNotifications(); }
  }

  getNotifications(): void {
    if (this.notificationsReq && typeof this.notificationsReq.unsubscribe === 'function') {
      this.notificationsReq.unsubscribe();
    }
    this.notificationsReq = this.viewType === 'navbar' ? this.userNotificationService.listUpdate(60000, {
        page_size: this.pageSize
      }).subscribe(notifications => {
        this.notifications = notifications;
        this.parseUnreadCount(this.userNotificationService.unreadCount);
      }, err => this.errors = parseErrors(err) ) :
      this.userNotificationService.list({
        'mark_as_read': 'True',
        page_size: 50
      }).subscribe(notifications => {
        this.parseNotifications(notifications);
      }, err => this.errors = parseErrors(err) );
  }

  getNextNotifications(): void {
    if (this.notificationsReq && typeof this.notificationsReq.unsubscribe === 'function') {
      this.notificationsReq.unsubscribe();
    }
    this.notificationsReq = this.userNotificationService.listNext().subscribe(notifications => {
        this.parseNotifications(notifications);
      }, err => this.errors = parseErrors(err) );
  }

  parseNotifications(notifications: UserNotification[]) {
    notifications.forEach(n => {
      const matchedDayIndex = this.notificationDays.findIndex(d => (
        d.date === moment(n.timestamp).format('YYYY-MM-DD')
      ));
      if (matchedDayIndex > -1) {
        this.notificationDays[matchedDayIndex].notifications.push(n);
      } else {
        this.notificationDays.push({
          date: moment(n.timestamp).format('YYYY-MM-DD'),
          notifications: [n]
        });
      }
    });
  }

  selectNotification(notification: UserNotification): void {
    this.router.navigateByUrl(notification.url);
  }

  openNotifications(): void {
    this.pageSize = 50;
    this.getNotifications();
  }

  closeNotifications(): void {
    this.pageSize = 1;
    if (this.notificationsReq) { this.notificationsReq.unsubscribe(); }
    this.userNotificationService.list({'mark_as_read': 'True'}).subscribe(() => {
      this.parseUnreadCount(this.userNotificationService.unreadCount);
    }, err => this.errors = parseErrors(err) );
  }

  parseUnreadCount(unreadCount: number): void {
    this.unreadCount = unreadCount;
    this.unreadCountLabel = this.unreadCount < 1000 ?
                            this.unreadCount.toString() :
                            Math.round(this.unreadCount / 1000) + 'k';
  }
}
