<form #punchCardTickets="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Punch Card Ticket</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="punchCard" class="punch-card">
      <div class="form-content">
        <label class="label" translate>Ticket Image</label>

        <div class="field-group ticket-image">
          <div class="upload-image-dropzone" [ngClass]="{filled: punchCard.ticketImage}">
            <img *ngIf="punchCard.ticketImage" [src]="punchCard.ticketImage" />
            <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
            <label class="upload-label" for="file">
              <i class="icon-upload"></i>
              <span *ngIf="!punchCard.ticketImage" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
              <span *ngIf="punchCard.ticketImage" translate>Upload a new photo</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
