import { clone, remove, find } from 'lodash';
import { Surcharge } from '../surcharges/surcharge';

export class CondensedTicket {
  carrier: string;
  carrierId: string;
  carrierOrganization: string;
  completedDatetime: string;
  correctionWeight: string;
  createdAt: string;
  customUnitPrice: string;
  unitPrice: string;
  customer: string;
  customerId: string;
  customerOrganization: string;
  customerPaymentType: string;
  deliveryDatetime: string;
  discountPercentage: string;
  driver: string;
  driverId: string;
  driverImage: string;
  driverPhone: string;
  driverName: string;
  grossWeight: string;
  grossWeightScale: string;
  grossWeightScaleId: string;
  grossWeightUnit: string;
  grossWeightDatetime: string;
  id: string;
  location: string;
  net: string;
  netPrice: string;
  netWeightUnit: string;
  notes: string;
  number: number;
  originalUnitPrice: string;
  paymentType: string;
  product: string;
  productId: string;
  purchaseOrder: string;
  purchaseOrderId: string;
  selected = false;
  seller: string;
  sellerId: string;
  sellerOrganization: string;
  status: string; // in-progress, on-hold, complete
  statusText: string;
  statusLabel: string;
  tareWeight: string;
  tareWeightScale: string;
  tareWeightScaleId: string;
  storedTareWeightId: string;
  tareWeightUnit: string;
  tareWeightDatetime: string;
  totalPrice: string;
  totalTax: string;
  totalSurcharge: string;
  truck: string;
  truckId: string;
  truckCarrierId: string;
  truckImage: string;
  truckType: string;
  truckLicensePlate: string;
  truckDisplayName: string;
  void: boolean;
  voidReason: string;
  productUnitOfMeasurement: string;
  surcharges: Surcharge[] = [];
  loading: false;
  classes: any;

  url(action): string {
    return '/scale/tickets/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
