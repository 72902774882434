"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EodFilterPipe = /** @class */ (function () {
    function EodFilterPipe() {
    }
    EodFilterPipe.prototype.transform = function (filters) {
        return filters.some(function (f) { return (f.values && f.values.length > 0) || f.selected === true; });
    };
    return EodFilterPipe;
}());
exports.EodFilterPipe = EodFilterPipe;
