import { interval as observableInterval } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Scale } from './scale';
import { ScaleService } from './scale.service';
import { parseErrors } from '../shared';

@Component({
  selector: 'scales',
  templateUrl: './scales.component.html',
  styleUrls: ['./scales.component.scss'],
  providers: [DecimalPipe]
})
export class ScalesComponent implements OnInit, OnDestroy {
  loading = false;
  errors = [];
  scales: Scale[] = [];
  loadScalesInterval = 10000;
  scalesReq: any;
  sortBy = 'name';
  sortAsc = true;

  constructor(
    private scaleService: ScaleService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit() {
    this.getScales();
    this.loadScalesEveryMiliseconds(this.loadScalesInterval);
  }

  ngOnDestroy() {
    if (this.scalesReq) { this.scalesReq.unsubscribe(); }
  }

  loadScalesEveryMiliseconds(miliseconds: number) {
    this.scalesReq = observableInterval(miliseconds).subscribe(val => {
      this.getScales();
    });
  }

  getScales() {
    this.loading = true;
    this.scales = [];
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    if (this.scalesReq) { this.scalesReq.unsubscribe(); }

    this.scalesReq = this.scaleService.getScales({
      ordering: order
    }).subscribe(scales => {
      this.scales = scales;
      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
  }

  getWeightUnit(): string {
    return this.scales.length > 0 ? this.scales[0].weightUnit : 'lbs';
  }

  getScaleWeight(scale: Scale) {
    if (scale.status === 'disconnected' || scale.status === 'unstable') {
      return '-';
    }

    return this.decimalPipe.transform(scale.weight);
  }

  truncateTextByElipses(value: string, stringLength = 16) {
    if (value === null || value === undefined) { return '—'; }

    if (value.length > stringLength) {
      return value.substring(0, stringLength) + ' ...';
    }

    return value;
  }
}
