import { LocationUpdate } from '../jobs/locationUpdate';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class LocationUpdates {
  paverLocationUpdates: any[];
  locationUpdates: any[];
  locations: any[];
  routes: any[];

  constructor(data: any) {
    data = camelcaseKeysDeep(data);

    this.locationUpdates = data.locationUpdates && data.locationUpdates.map(locationUpdate => {
      return new LocationUpdate(locationUpdate);
    });
    this.paverLocationUpdates = data.paverLocationUpdates && data.paverLocationUpdates.map(locationUpdate => {
      return new LocationUpdate(locationUpdate);
    });
    this.locations = data.locations;
    this.routes = data.routes;
  }
}
