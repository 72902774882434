<form #tripTickets="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Trip Tickets</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="loadingCheckin" class="checkin">
      <div class="form-content">
        <label class="loading-label" translate>Loading Ticket Image</label>

        <div class="field-group ticket-number">
          <label class="required" translate>Ticket #</label>
          <input type="text" name="loading-ticket-number" placeholder="" [(ngModel)]="loadingCheckin.ticketNumber" #loadingTicketNumber="ngModel" />
        </div>

        <div class="field-group weight">
          <label class="required" translate>Weight</label>
          <input type="text" name="loading-weight" placeholder="" [(ngModel)]="loadingCheckin.weight" #loadingWeight="ngModel" />
        </div>

        <div class="field-group ticket-image">
          <div class="upload-image-dropzone" [ngClass]="{filled: loadingCheckin.ticketImage}">
            <img *ngIf="loadingCheckin.ticketImage" [src]="loadingCheckin.uploadDialogTicketImage" />
            <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event, 'loading')" #fileInput/>
            <label class="upload-label" for="file">
              <i class="icon-upload"></i>
              <span *ngIf="!loadingCheckin.ticketImage" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
              <span *ngIf="loadingCheckin.ticketImage" translate>Upload a new photo</span>
            </label>
          </div>
        </div>

        <button class="submit-button btn btn-primary"
                (click)="requestRetake(loadingCheckin)"
                [disabled]="trip.retakeRequested || loading"
                [ngClass]="{loading: loading}"
                translate>
          Request Ticket Image Retake
        </button>
      </div>
    </div>

    <div class="divider"></div>

    <div *ngIf="unloadingCheckin" class="checkin">
      <div class="form-content">
        <label class="unloading-label" translate>Unloading Ticket Image</label>

        <div class="field-group ticket-number">
          <label class="required" translate>Ticket #</label>
          <input type="text" name="unloading-ticket-number" placeholder="" [(ngModel)]="unloadingCheckin.ticketNumber" #loadingTicketNumber="ngModel" />
        </div>

        <div class="field-group weight">
          <label class="required" translate>Weight</label>
          <input type="text" name="unloading-weight" placeholder="" [(ngModel)]="unloadingCheckin.weight" #unloadingWeight="ngModel" />
        </div>

        <div class="field-group ticket-image">
          <div class="upload-image-dropzone" [ngClass]="{filled: unloadingCheckin.ticketImage}">
          <img *ngIf="unloadingCheckin.ticketImage" [src]="unloadingCheckin.uploadDialogTicketImage" />

          <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event, 'unloading')" #fileInput/>
          <label class="upload-label" for="file">
            <i class="icon-upload"></i>
            <span *ngIf="!unloadingCheckin.ticketImage" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
            <span *ngIf="unloadingCheckin.ticketImage" translate>Upload a new photo</span>
          </label>
        </div>
        </div>

        <button class="submit-button btn btn-primary"
                (click)="requestRetake(unloadingCheckin)"
                [disabled]="trip.retakeRequested || loading"
                [ngClass]="{loading: loading}"
                translate>
          Request Ticket Image Retake
        </button>
      </div>
    </div>
  </div>
</form>
