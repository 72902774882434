<div class="name">
  <span (mouseenter)="hover = true" (mouseleave)="hover = false">
    <ng-container *ngIf="!countOnly">{{ names }}</ng-container>
    <ng-container *ngIf="countOnly">
      <div *ngIf="truckTypes" [ngPlural]="truckTypes.length">
        <ng-template ngPluralCase="=0" translate>0 Types</ng-template>
        <ng-template ngPluralCase="=1" translate>{{ truckTypes.length }} Type</ng-template>
        <ng-template ngPluralCase="other" translate>{{ truckTypes.length }} Types</ng-template>
      </div>
    </ng-container>
  </span>
  <div class="popover" [style.bottom]="popoverBottom + 'px'"
                       [style.left]="popoverLeft + 'px'"
                       [ngClass]="{visible: hover}">
    <div *ngFor="let truckType of truckTypes">
      {{ truckType.name }}
    </div>
  </div>
</div>
