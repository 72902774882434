import { Component, ViewChild, OnInit } from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { combineLatest as observableCombineLatest, Observable, Subscription } from 'rxjs';
import * as moment from 'moment';

import { User } from '../../users/user';
import { DropdownComponent, AuthenticationService, parseErrors } from '../../shared';
import { Preference } from '../../preferences/preference';
import { PreferenceService } from '../../preferences/preference.service';
import { Driver } from '../../drivers/driver';
import { DriverService } from '../../drivers/driver.service';
import { AssignmentService } from '../../assignments/assignment.service';
import { Assignment } from '../../assignments/assignment';
import { CondensedJobEventService } from '../../job-events/condensed-job-event.service';
import { JobEventService } from '../../job-events/job-event.service';
import { CondensedJobEvent } from '../../job-events/condensed-job-event';
import { ConnectionService } from '../../connections/connection.service';
import { Carrier } from '../../carriers/carrier';
import { EventAlertService } from '../../shared/event-alert/event-alert.service';

import { ExportDialogComponent, ExportDialogData } from '../../shared/export-dialog/export-dialog.component';
import { DriverAssignmentsListComponent } from '../../assignments/driver-assignments-list.component';
import { DispatchScheduleTableComponent } from './schedule-table/dispatch-schedule-table.component';
import { AssignmentErrorDialogComponent } from '../../messages/assignment-error-dialog.component';
import { DispatchScheduleFiltersDialogComponent } from './filters-dialog/dispatch-schedule-filters-dialog.component';
import { Job } from '../../jobs/job';
import { Project } from '../../projects/project';
import { Customer } from '../../customers/customer';
import { AssignmentTruckManagementDialogComponent } from '../../messages/assignment-truck-management-dialog/assignment-truck-management-dialog.component';
import { Location } from '../../locations/location';
import { Connection } from '../../connections/connection';
import { Collaborator } from '../../collaborators/collaborator';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { DispatchScheduleFilterOptions, DispatchScheduleFilterSelection } from './filters-dialog/types';
import { Truck } from '../../trucks/truck';
import { JobEvent } from '../../job-events/job-event';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import { ConfirmChangeJobLoadsService } from '../dispatch-by-job/confirm-change-job-loads.service';

type RegionOption = {
  name: string,
  value: string
};

export type DispatchDriver = Driver & {
  assignments: Assignment[],
  loadCount?: number
};
export type DispatchJobEvent = CondensedJobEvent & {
  shares: Collaborator[],
  assignments: Assignment[],
  assignedLoads?: number,
  assignedTons?: number,
  truckList?: string,
  shareList?: string,
  selected: boolean
};
export type DispatchConnection = Connection & {
  shares: Collaborator[],
  truckCount?: number
};

export type DispatchAssignment = {
  driver: DispatchDriver,
  truck: Truck;
  jobevent: JobEvent | DispatchJobEvent,
  uniqueStart: string | Date,
  shift: string,
  maxNumberOfLoads: number
}

@Component({
  selector: 'dispatch-schedule',
  templateUrl: './dispatch-schedule.component.html',
  styleUrls: ['./dispatch-schedule.component.scss']
})
export class DispatchScheduleComponent implements OnInit {
  user: User;
  preference: Preference;
  preferenceKey = 'GeneralUserPreferences';
  hasAllDriversEnabled = false;
  view: 'jobEvents' | 'drivers' | 'collaborators' = 'jobEvents';
  activeLoadType: 'Loads Ordered' | 'Trucks Requested' = 'Loads Ordered';
  driverLoadingProgress = 0;
  jobEventLoadingProgress = 0;
  connectionLoadingProgress = 0;
  assignmentLoadingProgress = 0;
  shareLoadingProgress = 0;
  errors = [];
  selectedDate: Date;
  assignmentsDate: Date;
  selectedDateParam: string;
  selectedDateObj: { year: number, month: number, day: number };
  selectedFilters: DispatchScheduleFilterSelection = <DispatchScheduleFilterSelection>{};
  filterOptions: DispatchScheduleFilterOptions = <DispatchScheduleFilterOptions>{};
  showCancelledJobEvents = false;
  processingUpdate = false;
  assigningTrucks = false;

  driverQuery: any = { all_carriers: true };
  driversSub: Observable<Driver[]>;
  drivers: DispatchDriver[];
  filteredDrivers: DispatchDriver[];
  selectedDrivers: string[] = [];

  jobEvents: DispatchJobEvent[];
  updatedJobEvents: CondensedJobEvent[] = [];
  updatedAssignments: Assignment[] = [];
  jobEventsLastRequestedAt: string;
  assignmentsList: Assignment[];
  sharesList: Collaborator[];
  filteredJobEvents: DispatchJobEvent[];
  autoOpenJobEvent: string;
  jobEventEditPanelOpen = false;
  selectedJobEvents: string[] = [];

  connectionQuery: any = {
    ordering: 'organization__name',
    leased_org: 'False',
    is_carrier: 'True',
    customer_only: 'False',
    status: 'active'
  };
  connectionsSub: Observable<Collaborator[]>;
  connections: DispatchConnection[];
  selectedConnections: string[] = [];

  selectedDriver: DispatchDriver;
  selectedJobEvent: DispatchJobEvent;
  selectedCollaborator: DispatchConnection;

  @ViewChild('regionsDropdown', { static: false }) regionsDropdown: DropdownComponent;
  regionOptions: RegionOption[] = [{ name: 'Austin', value: 'austin' }];
  regionsDropdownConfig = {
    nameProperty: 'name',
    showLoading: true
  };

  carriersReq: Subscription;
  carrierDropdownData: {
    carrier?: Carrier,
    carriers: Carrier[],
    config: any,
    loading: boolean
  } = {
    carriers: [],
    config: {
      nameProperty: 'name',
      searchable: true,
      loadingOptions: false
    },
    loading: false
  };

  loadStats = { remaining: 0, assigned: 0,
    ordered: { loads: 0, tons: 0, tonnes: 0, lbs: 0, kgs: 0, bushels: 0, bags: 0 }
  };
  truckStats = { remaining: 0, assigned: 0, requested: 0 };
  truckTypeStats = { required: 0, assigned: 0, available: 0, needed: 0 };
  tonStats = { remaining: 0, assigned: 0, requested: 0 };

  @ViewChild(DriverAssignmentsListComponent, { static: false }) driverAssignmentsList: DriverAssignmentsListComponent;
  @ViewChild(DispatchScheduleTableComponent, { static: false }) dispatchTable: DispatchScheduleTableComponent;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    public condensedJobEventService: CondensedJobEventService,
    private jobEventService: JobEventService,
    public driverService: DriverService,
    public collaboratorService: CollaboratorService,
    public assignmentService: AssignmentService,
    public connectionService: ConnectionService,
    public eventAlertService: EventAlertService,
    private confirmChangeJobLoadsService: ConfirmChangeJobLoadsService
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    this.getPreferences();
    this.getCarriers();
    this.allSubscriptionsToUnsubscribe.push(
      observableCombineLatest(
        this.route.params, this.route.queryParams,
        (params, qparams) => ({ params, qparams })
      ).subscribe(result => {
        if (result.qparams['date']) {
          this.selectDate([moment(result.qparams['date'], 'YYYYMMDD').toDate()]);
        }
        if (result.qparams['view']) {
          this.switchView(result.qparams['view']);
        } else { this.switchView('dispatch'); }
        if (result.qparams['jobEvent']) {
          this.autoOpenJobEvent = result.qparams['jobEvent'];
        }
      })
    );
    this.allSubscriptionsToUnsubscribe.push(
      this.driverService.listAllProgress.subscribe(progress => {
          this.driverLoadingProgress = Math.ceil(progress * 100);
      }),
      this.condensedJobEventService.listAllProgress.subscribe(progress => {
        if (!this.processingUpdate) {
          this.jobEventLoadingProgress = Math.ceil(progress * 100);
        }
      }),
      this.connectionService.listAllProgress.subscribe(progress => {
        this.connectionLoadingProgress = Math.ceil(progress * 100);
      }),
      this.assignmentService.listAllProgress.subscribe(progress => {
        if (!this.processingUpdate) {
          this.assignmentLoadingProgress = Math.ceil(progress * 100);
        }
      }),
      this.collaboratorService.listAllProgress.subscribe(progress => {
        this.shareLoadingProgress = Math.ceil(progress * 100);
      })
    );
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  switchView(view: 'dispatch' | 'review' | 'collaborate') {
    this.view = view === 'collaborate' ? 'collaborators' : view === 'dispatch' ? 'jobEvents' : 'drivers';
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    if (queryParams['view'] !== view) {
      this.selectedDrivers = [];
      this.selectedJobEvents = [];
      this.selectedConnections = [];
    }
    queryParams['view'] = view;
    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge' });
  }

  selectDate(dates: Date[]) {
    if (dates && dates[0] && (!!!this.selectedDate || this.selectedDate.getUTCDate() !== dates[0].getUTCDate())) {
      this.selectedDrivers = [];
      this.selectedDate = dates[0];
      this.selectedDateObj = {
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth() + 1,
        day: this.selectedDate.getDate()
      };
      const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
      this.getJobEvents();
      this.selectedDateParam = moment(this.selectedDate).format('YYYYMMDD');
      queryParams['date'] = this.selectedDateParam;
      if (
        !(this.preference && this.preference.blob && this.preference.blob['date']) ||
        (this.preference.blob['date'] !== this.selectedDate.toISOString())
      ) { this.savePreferences({ date: this.selectedDate.toISOString() }); }
      this.router.navigate([], { relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge' });
    }
  }

  toggleCancelled(e: Event) {
    this.showCancelledJobEvents = e.target['checked'];
    this.getJobEvents();
  }

  getJobEvents(jobEventQuery = {}) {
    const query = {
      shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(),
      shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(),
      exclude_pending: 'True',
      cancelled: this.showCancelledJobEvents ? undefined : 'False',
      ...jobEventQuery
    };
    this.jobEventsLastRequestedAt = moment().toISOString();
    this.condensedJobEventService.listAll(50, query).subscribe(jobEvents => {
      this.jobEvents = jobEvents.map(jobEvent => (Object.assign(jobEvent, { assignments: [], shares: [], selected: false })));
      this.filterJobEvents();
      if (!this.drivers) {
        this.getDrivers();
      } else {
        this.getAssignments(this.drivers);
      }
    }, (err) => this.errors = parseErrors(err));
  }

  getModifiedJobEvents(jobEventQuery = {}) {
    if(!jobEventQuery['last_modified__gt']) {
      this.getJobEvents(jobEventQuery);
      return;
    }
    this.processingUpdate = true;
    const query = {
      shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(),
      shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(),
      exclude_pending: 'True',
      cancelled: this.showCancelledJobEvents ? undefined : 'False',
      page_size: 10,
      ...jobEventQuery
    };

    this.condensedJobEventService.list(query).subscribe(jobEvents => {
      // this.jobEventsLastRequestedAt = moment().subtract(1, 'minutes').toISOString();
      this.getAssignmentUpdates(jobEventQuery);
      jobEvents.forEach(jobEvent => {
        const existingJobEventIdx = this.jobEvents.findIndex(j => j.id === jobEvent.id);
        const mergedJobEvent = Object.assign({...this.jobEvents[existingJobEventIdx]}, jobEvent);
        const existingJobEventUnchanged = existingJobEventIdx > -1 ? JSON.stringify(mergedJobEvent) === JSON.stringify(this.jobEvents[existingJobEventIdx]) : false;
        const existingUpdateIdx = this.updatedJobEvents.findIndex(j => j.id === jobEvent.id);
        const mergedUpdate = Object.assign({...this.updatedJobEvents[existingUpdateIdx]}, jobEvent);
        // Check if jobEvent is already in jobEvents array and if it has changes
        if (!existingJobEventUnchanged) {
          // Check if jobEvent is in the updates array already and if it has changes
          if (existingUpdateIdx > -1 && JSON.stringify(mergedUpdate) !== JSON.stringify(jobEvent)) {
            this.updatedJobEvents.splice(existingUpdateIdx, 1, jobEvent);
          } else {
            this.updatedJobEvents.push(jobEvent);
          }
        }
      });
    }, (err) => this.errors = parseErrors(err));
  }

  showUpdateAlert(): void {
    const hasUpdatedJobEvents = this.hasUpdatedJobEvents();
    const hasUpdatedAssignments = this.hasUpdatedAssignments();
    if (hasUpdatedJobEvents || hasUpdatedAssignments) {
      this.allSubscriptionsToUnsubscribe.push(
        this.eventAlertService.showAlert('Jobs have been changed.', 'Show Changes').subscribe(clicked => {
          if (clicked) { this.onShowChanges(); }
        })
      )
    }
  }

  onShowChanges(): void {
    const modifiedJobEvents = [...this.jobEvents];
    this.updatedJobEvents.forEach(jobEvent => {
      const existingJobEventIdx = modifiedJobEvents.findIndex(j => j.id === jobEvent.id);
      if (existingJobEventIdx > -1) {
        modifiedJobEvents[existingJobEventIdx]['updates'] = true;
        Object.assign(modifiedJobEvents[existingJobEventIdx], jobEvent);
      }
    });
    this.jobEvents = modifiedJobEvents;
    this.filterJobEvents();
    this.mergeAssignmentUpdates();
    this.updatedJobEvents = [];
  }

  getJobEventStats(jobEvents: DispatchJobEvent[]) {
    this.loadStats = { remaining: 0, assigned: 0,
      ordered: { loads: 0, tons: 0, tonnes: 0, lbs: 0, kgs: 0, bushels: 0, bags: 0 }
    };
    this.truckStats = { remaining: 0, assigned: 0, requested: 0 };
    this.tonStats = { remaining: 0, assigned: 0, requested: 0 };
    this.truckTypeStats = { required: 0, assigned: 0, available: 0, needed: 0 };
    jobEvents.forEach(j => {
      this.loadStats.assigned += Number(j.assignedLoads);
      this.loadStats.ordered[j.dailyDeliveryTargetType] += Number(j.dailyDeliveryTarget);
      this.truckStats.assigned += j.assignments.length;
      this.truckStats.requested += Number(j.numTrucks);
      if (j.requestedUnit && j.requestedUnit.key !== 'Tons') { this.truckTypeStats.required += Number(j.requestedAmount); }
      this.truckTypeStats.assigned += j.assignments.length;

      if (j.dailyDeliveryTargetType === 'tons') {
        this.tonStats.requested += Number(j.dailyDeliveryTarget);
      }
    });
    this.loadStats.remaining = this.loadStats.ordered.loads - this.loadStats.assigned;
    this.truckStats.remaining = this.truckStats.requested - this.truckStats.assigned;
    this.truckTypeStats.available = this.filteredDrivers && this.filteredDrivers.length ? this.filteredDrivers.length - this.filteredDrivers.filter(driver => (driver.assignments.length > 0)).length : 0;
    this.truckTypeStats.needed = this.truckTypeStats.required > this.truckTypeStats.available ? this.truckTypeStats.required - this.truckTypeStats.available : 0;
  }

  changeDriverQuery(query: { carrier?: string, all_carriers?: boolean, all_leased?: boolean, tags?: string }) {
    this.driverQuery = Object.assign(this.driverQuery, query);
    this.getDrivers();
  }

  getDrivers() {
    this.driverService.listAll(100, this.driverQuery).subscribe(drivers => {
      if (this.drivers && this.assignmentsDate.toISOString() === this.selectedDate.toISOString()) {
        let fullAssignmentList = [];
        this.drivers.forEach(d => {
          fullAssignmentList.push(...d.assignments);
        });
        this.assignmentsList.forEach(a => {
          if (fullAssignmentList.findIndex(existingAssignment => (existingAssignment.id === a.id))) {
            fullAssignmentList.push(a);
          }
        });
        this.assignmentsList = fullAssignmentList.sort((a, b) => (moment(a.uniqueStart).diff(b.uniqueStart, 'minutes')));
        let dispatchDrivers: DispatchDriver[] = drivers.map(driver => (Object.assign(driver, { assignments: [], loadCount: 0 })));
        this.assignmentsList.forEach(assignment => {
          const driverIndex = drivers.findIndex(driver => (driver.id === assignment.driver.id));
          if (driverIndex > -1) {
            dispatchDrivers[driverIndex].assignments.push(assignment);
            dispatchDrivers[driverIndex].loadCount += Number(assignment.maxNumberOfLoads);
          }
        });
        this.drivers = [...dispatchDrivers];
        this.filteredDrivers = [...this.drivers];
      } else {
        let dispatchDrivers: DispatchDriver[] = drivers.map(driver => (Object.assign(driver, { assignments: [], loadCount: 0 })));
        this.getAssignments(dispatchDrivers);
      }
    }, (err) => this.errors = parseErrors(err));
  }

  getConnections() {
    // this.connectionService.listAll(100, this.connectionQuery).subscribe(connections => {
    //   if (this.connections && this.assignmentsDate.toISOString() === this.selectedDate.toISOString()) {
    //     let fullShareList = [];
    //     this.connections = connections.map(connection => (Object.assign(connection, { shares: [], truckCount: 0 })));
    //     this.connections.forEach(c => {
    //       fullShareList.push(...c.shares);
    //     });
    //     this.sharesList.forEach(s => {
    //       if (fullShareList.findIndex(existingShare => (existingShare.id === s.id)) < 0) {
    //         fullShareList.push(s);
    //       }
    //     });
    //     this.sharesList = fullShareList;
    //     let dispatchConnections: DispatchConnection[] = connections.map(connection => (
    //       Object.assign(connection, { shares: [], truckCount: 0 })
    //     ));
    //     this.sharesList.forEach(share => {
    //       const connectionIndex = connections.findIndex(connection => (connection.organization.id === share.organizationId));
    //       if (connectionIndex > -1) {
    //         dispatchConnections[connectionIndex].shares.push(share);
    //         dispatchConnections[connectionIndex].truckCount += share.numTrucks;
    //       }
    //     });
    //     this.connections = [...dispatchConnections];
    //   } else {
    //     let dispatchConnections: DispatchConnection[] = connections.map(connection => (
    //       Object.assign(connection, { shares: [], truckCount: 0 })
    //     ));
    //     this.getShares(dispatchConnections);
    //   }
    // }, (err) => this.errors = parseErrors(err));
  }

  getAssignments(drivers: DispatchDriver[]) {
    this.filteredDrivers = drivers.map(driver => (Object.assign(driver, { assignments: [], loadCount: 0 })));
    this.filteredJobEvents = this.filteredJobEvents.map(jobEvent => (
      Object.assign(jobEvent, { assignments: [], assignedLoads: 0, assignedTons: 0, truckList: '', shareList: '' })
    ));
    this.assignmentService.listAll(10, {
      jobevent__shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(),
      jobevent__shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(),
      can_dispatch: true
    }).subscribe(assignments => {
      this.assignmentsDate = this.selectedDate;
      this.assignmentsList = assignments.sort((a, b) => (moment(a.uniqueStart).diff(b.uniqueStart, 'minutes')));
      this.assignmentsList.forEach(assignment => {
        const driverIndex = drivers.findIndex(driver => (driver.id === assignment.driver.id));
        if (driverIndex > -1) {
          drivers[driverIndex].assignments.push(assignment);
          drivers[driverIndex].loadCount += Number(assignment.maxNumberOfLoads);
        }
        const jobIndex = this.filteredJobEvents.findIndex(jobEvent => (jobEvent['id'] === assignment.jobevent.id));
        if (jobIndex > -1) {
          const found = 
            this.filteredJobEvents[jobIndex] && 
            this.filteredJobEvents[jobIndex].assignments && 
            this.filteredJobEvents[jobIndex].assignments.length ? 
            this.filteredJobEvents[jobIndex].assignments.some(a => a.id === assignment.id) : false
          // only push to assignments if it is not already there
          if (!found) {
            this.filteredJobEvents[jobIndex].assignments.push(assignment);
            this.filteredJobEvents[jobIndex].assignedLoads += Number(assignment.maxNumberOfLoads);
            this.filteredJobEvents[jobIndex].assignedTons = Number(this.filteredJobEvents[jobIndex].loadDeliveryTarget) *
            this.filteredJobEvents[jobIndex].assignedLoads;
            this.filteredJobEvents[jobIndex].truckList += assignment.truck.name && assignment.truck.name + '\n';
          }
        }
      });
      this.getJobEventStats(this.filteredJobEvents);
      this.filteredDrivers = [...drivers];
      this.filteredJobEvents = [...this.filteredJobEvents];
      if (this.autoOpenJobEvent) {
        this.selectedJobEvents = [this.autoOpenJobEvent];
        this.openJobEventEditPanel(this.filteredJobEvents[this.filteredJobEvents.findIndex(j => (j.id === this.autoOpenJobEvent))]);
        this.autoOpenJobEvent = undefined;
      }
    }, (err) => this.errors = parseErrors(err));
  }

  getAssignmentUpdates(assignmentsQuery = {}) {
    const query = {
      jobevent__shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(),
      jobevent__shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(),
      page_size: 10,
      can_dispatch: true,
      ...assignmentsQuery
    };
    this.assignmentService.list(query).subscribe(assignments => {
      this.assignmentsDate = this.selectedDate;
      let existingAssignments: Assignment[] = this.getExistingAssignments();
      assignments.forEach(assignment => {
        const existingAssignmentIdx = existingAssignments.findIndex(a => a.id === assignment.id);
        const updateIdx = this.updatedAssignments.findIndex(a => a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id);
        const mergedAssignmentUpdate = Object.assign({...this.updatedAssignments[updateIdx]}, assignment);
        // Check if assignment is already in assignmentList array and if it has changes
        if (existingAssignmentIdx === -1) {
          // Check if assignment is in the updates array already and if it has changes
          if (updateIdx > -1 && JSON.stringify(this.updatedAssignments[updateIdx]) !== JSON.stringify(mergedAssignmentUpdate)) {
            this.updatedAssignments.splice(updateIdx, 1, assignment);
          } else {
            this.updatedAssignments.push(assignment);
          }
        }
      });
      if (this.authenticationService.getFeature('dispatchSchedulerChangeTracking')) {
        this.showUpdateAlert();
      }
    }, (err) => this.errors = parseErrors(err));
  }

  mergeAssignmentUpdates(): void {
    this.updatedAssignments.forEach(assignment => {
      const assignmentIdx = this.assignmentsList.findIndex(a => a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id);
      if (assignmentIdx > -1) {
        this.assignmentsList.splice(assignmentIdx, 1, assignment);
      } else {
        this.assignmentsList.push(assignment);
      }
    });
    this.assignmentsList.sort((a, b) => (moment(a.uniqueStart).diff(b.uniqueStart, 'minutes')));
    
    this.assignmentsList.forEach(assignment => {
      const driverIndex = this.filteredDrivers.findIndex(driver => (driver.id === assignment.driver.id));
      if (driverIndex > -1) {
        const assignmentIdx = this.filteredDrivers[driverIndex].assignments.findIndex(a => a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id);
        if (assignmentIdx === -1) {
          this.filteredDrivers[driverIndex].assignments.push(assignment);
          this.filteredDrivers[driverIndex].loadCount += Number(assignment.maxNumberOfLoads);
        }
      }
      const jobIndex = this.filteredJobEvents.findIndex(jobEvent => (jobEvent.id === assignment.jobevent.id));
      if (jobIndex > -1) {
        const assignmentIdx = this.filteredJobEvents[jobIndex].assignments.findIndex(a => a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id);
        if (assignmentIdx === -1) {
          this.filteredJobEvents[jobIndex].assignments.push(assignment);
          this.filteredJobEvents[jobIndex].assignedLoads += Number(assignment.maxNumberOfLoads);
          this.filteredJobEvents[jobIndex].assignedTons = Number(this.filteredJobEvents[jobIndex].loadDeliveryTarget) *
            this.filteredJobEvents[jobIndex].assignedLoads;
          this.filteredJobEvents[jobIndex].truckList += assignment.truck.name && assignment.truck.name + '\n';
          this.filteredJobEvents[jobIndex]['updates'] = true;
        }
      }
    });
    this.getJobEventStats(this.filteredJobEvents);
    this.filteredDrivers = [...this.filteredDrivers];
    this.filteredJobEvents = [...this.filteredJobEvents];
    if (this.autoOpenJobEvent) {
      this.selectedJobEvents = [this.autoOpenJobEvent];
      this.openJobEventEditPanel(this.filteredJobEvents[this.filteredJobEvents.findIndex(j => (j.id === this.autoOpenJobEvent))]);
      this.autoOpenJobEvent = undefined;
    }
    this.updatedAssignments = [];
    this.processingUpdate = false;
  }

  getShares(connections: DispatchConnection[]) {
    // this.collaboratorService.getAllShares(20, {
    //   start__gte: moment(this.selectedDate).startOf('day').toISOString(),
    //   start__lte: moment(this.selectedDate).endOf('day').toISOString()
    // }).subscribe(shares => {
    //   this.sharesList = shares;
    //   this.sharesList.forEach(share => {
    //     const connectionIndex = connections.findIndex(connection => (connection.organization.id === share.organizationId));
    //     if (connectionIndex > -1) {
    //       connections[connectionIndex].shares.push(share);
    //       connections[connectionIndex].truckCount += share.numTrucks;
    //     }
    //     const jobIndex = this.filteredJobEvents.findIndex(jobEvent => (jobEvent['id'] === share.jobEventId));
    //     if (jobIndex > -1) {
    //       this.filteredJobEvents[jobIndex].shares.push(share);
    //       this.filteredJobEvents[jobIndex].shareList += share.organization + '\n';
    //     }
    //   });
    //   this.connections = [...connections];
    //   this.filteredJobEvents = [...this.filteredJobEvents];
    // });
  }

  updateAssignment(assignments: Assignment[]) {
    this.assignmentService.bulkUpdate(assignments).subscribe(bulkRes => {
      this.applyAssignmentChanges(bulkRes.assignments);
      if (bulkRes.errors && bulkRes.errors.length) { this.openDisplayErrorModal(bulkRes.errors); }
    }, err => {
      if (err.errors && err.errors.length) { this.openDisplayErrorModal(err.errors); }
    });
  }

  applyAssignmentChanges(assignments: Assignment[], action: 'edit' | 'delete' = 'edit') {
    assignments.forEach(assignment => {
      const driverIndex = this.filteredDrivers.findIndex(d => (d.id === assignment.driver.id));
      const matchedDriverAssignment = this.filteredDrivers[driverIndex] &&
        this.filteredDrivers[driverIndex].assignments.findIndex(a => a.id === assignment.id);
      const jobEventIndex = this.filteredJobEvents.findIndex(d => (d.id === assignment.jobevent.id));
      const matchedJobEventAssignment = this.filteredJobEvents[jobEventIndex] &&
        this.filteredJobEvents[jobEventIndex].assignments.findIndex(a => a.id === assignment.id);
      if (action === 'edit') {
        if (matchedDriverAssignment > -1) {
          this.filteredDrivers[driverIndex].assignments[matchedDriverAssignment] = assignment;
        } else {
          this.filteredDrivers[driverIndex].assignments.push(assignment);
        }
        if (matchedJobEventAssignment > -1) {
          this.filteredJobEvents[jobEventIndex].assignments[matchedJobEventAssignment] = assignment;
        } else {
          this.filteredJobEvents[jobEventIndex].assignments.push(assignment);
        }
      } else {
        this.filteredDrivers[driverIndex].assignments.splice(matchedDriverAssignment, 1);
        this.filteredJobEvents[jobEventIndex].assignments.splice(matchedJobEventAssignment, 1);
      }
    });
    this.filteredDrivers = [
      ...this.filteredDrivers.map(d => {
        d.loadCount = 0;
        d.assignments.forEach(a => d.loadCount += Number(a.maxNumberOfLoads));
        return d;
      })
    ];
    this.filteredJobEvents = [
      ...this.filteredJobEvents.map(j => {
        j.assignedLoads = 0;
        j.truckList = '';
        j.assignments.forEach(a => {
          j.assignedLoads += Number(a.maxNumberOfLoads);
          if (a.truck.name) { j.truckList += a.truck.name + '\n'; }
        });
        j.assignedTons = Number(j.loadDeliveryTarget) * j.assignedLoads;
        return j;
      })
    ];
    this.assignmentService.listAllProgress.next(100);
    this.getJobEventStats(this.filteredJobEvents);
  }

  assignSelectedDrivers(jobEvent: DispatchJobEvent) {
    if (this.selectedDrivers && !this.assigningTrucks) {
      this.assigningTrucks = true
      let uniqueStart = this.getLastAssignmentTime(jobEvent);
      let newAssignments = [];
      let assignmentsPending = []
      let countSameChunk = 0
      
      this.selectedDrivers.forEach((driverId, index) => {
        const driver = this.filteredDrivers.find(d => (d.id === driverId));
        let maxNumberOfLoads = 1;
        if (this.hasAllDriversEnabled) {
          if (!this.preference || !this.preference.hasOwnProperty('blob') || this.preference.blob['dispatchLoadType'] === 'all-day') {
            maxNumberOfLoads = 0;
          } else if (this.preference.blob['dispatchLoadType'] === 'by-load') {
            maxNumberOfLoads = this.preference.blob['dispatchLoadCount'] ?
                               Number(this.preference.blob['dispatchLoadCount']) : 1;
          }
        }

       const disabledStaggeredTime =
        this.preference &&
        this.preference.blob &&
        this.preference.blob['staggerAssignmentTime'] &&
        this.preference.blob['staggerAssignmentTime'] === 'shift1_start'
          ? true
          : false;
        
        uniqueStart = Number(jobEvent.deliveryInterval) > 0 && index > 0 && !disabledStaggeredTime ?
          moment(uniqueStart).add(Number(jobEvent.deliveryInterval), 'minutes').toISOString() : uniqueStart;

        const assignment = {
          driver: driver,
          truck: driver.truck,
          jobevent: jobEvent,
          uniqueStart: uniqueStart,
          shift: 'shift1',
          maxNumberOfLoads: maxNumberOfLoads
        };

        const validationParams = {
          jobEvent,
          countSameChunk,
          currentAssignation: {
            numberOfLoadsType: assignment.maxNumberOfLoads ? 'numbered' : 'allDay',
            maxNumberOfLoads: assignment.maxNumberOfLoads,
          },
        }
        
        countSameChunk += assignment.maxNumberOfLoads
        if (this.confirmChangeJobLoadsService.isGreaterAssigned(validationParams)) {
          assignmentsPending.push(assignment)
        } else {
          newAssignments.push(assignment);
        }
      });


      if (assignmentsPending.length) {
        const loadsAssigned = assignmentsPending.reduce((total, pending) => total + Number(pending.maxNumberOfLoads), 0)
        const validationParams = {
          jobEvent,
          currentAssignation: {
            numberOfLoadsType: loadsAssigned ? 'numbered' : 'allDay',
            maxNumberOfLoads: loadsAssigned,
          },
        }
  
        this.allSubscriptionsToUnsubscribe.push(
          this.confirmChangeJobLoadsService.validateLoadsRequested(validationParams).subscribe(
            (response) => {
              const query = response['byPass'] ? { bypass_limit_validation: 'True' } : undefined
              this.createAssignments(
                assignmentsPending, 
                () => this.assigningTrucks = false, 
                query
              )
            }, 
            () => this.assigningTrucks = false
          )  
        )      
      }

      if (!newAssignments.length) return;

      /// Validate the trucks assigned vs trucks requested
      const countCurrentAssignments = jobEvent.assignments.length
      const totalAssignments = countCurrentAssignments + newAssignments.length
      const assignDrivers = () => {
        const successCallback = () => {
          this.filteredJobEvents.map(j => ({...j, selected: false}))
          this.assigningTrucks = false
        }

        this.createAssignments(newAssignments, successCallback)
      }

      if (jobEvent.numTrucks && totalAssignments > jobEvent.numTrucks) {
        this.confirmUpdateTrucksRequested({
          numTrucksAssigned: countCurrentAssignments,
          numTrucksToAssign: newAssignments.length,
          totalAssignments,
          jobEventId: jobEvent.id,
          successCallback: assignDrivers
        })
      } else {
        assignDrivers()
      }
    }
  }

  private confirmUpdateTrucksRequested({
    numTrucksToAssign,
    numTrucksAssigned,
    totalAssignments,
    jobEventId, 
    successCallback,
    jobEventName = '',
  }) {
    const jobText = jobEventName ? jobEventName : 'this job'
    const messageAssigned = numTrucksAssigned > 0 ? ` and the ${numTrucksAssigned} previously assigned` : ''

    const confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '530px',
      data: {
        title: `Trucks Requested Exceeded`,
        message: `The number of trucks required has exceeded by the ${numTrucksToAssign} truck(s) selected${messageAssigned}. 
        Do you want to assign the selected truck(s) and increase the total number for ${jobText} to ${totalAssignments}?`,
        acceptText: 'Assign & Update Trucks'
      }
    });

    confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.jobEventService.save(
          { id: jobEventId, numTrucks: totalAssignments }
        ).subscribe(
          () => {
            successCallback()
            const indexUpdated = this.filteredJobEvents.findIndex((jobEvent) => jobEvent.id === jobEventId)
            this.filteredJobEvents[indexUpdated].numTrucks = totalAssignments
          }, 
          (err) => this.errors = err
        );
      } else {
        this.assigningTrucks = false
      }
    });
  }

  private createAssignments(newAssignments: DispatchAssignment[], successCallback?: () => void, query?: any){
    this.assignmentService.bulkCreate(newAssignments as Assignment[], query).subscribe(bulkRes => {
      if(successCallback) successCallback()
      this.applyAssignmentChanges(bulkRes.assignments);
      if (bulkRes.errors && bulkRes.errors.length) { this.openDisplayErrorModal(bulkRes.errors); }
    }, err => {
      this.assigningTrucks = false
      if (err.errors && err.errors.length) { this.openDisplayErrorModal(err.errors); }
    });
  }

  assignSelectedJobEvents(driver: DispatchDriver) {
    if (this.selectedJobEvents) {
      let newAssignments = [] as DispatchAssignment[];
      this.dispatchTable.getUnassignedIds(driver, this.selectedJobEvents).forEach(jobEventId => {
        const jobEvent = this.filteredJobEvents.find(j => (j.id === jobEventId));
        let uniqueStart = this.getLastAssignmentTime(jobEvent);
        let maxNumberOfLoads = 1;
        if (this.hasAllDriversEnabled) {
          if (!this.preference || !this.preference.hasOwnProperty('blob') || this.preference.blob['dispatchLoadType'] === 'all-day') {
            maxNumberOfLoads = 0;
          } else if (this.preference.blob['dispatchLoadType'] === 'by-load') {
            maxNumberOfLoads = 1;
          }
        }

        uniqueStart = Number(jobEvent.deliveryInterval) > 0 && (jobEvent.assignments && jobEvent.assignments.length > 0) ?
          moment(uniqueStart).add(Number(jobEvent.deliveryInterval), 'minutes').toISOString() : uniqueStart;
        const assignment = {
          driver: driver,
          truck: driver.truck,
          jobevent: jobEvent,
          uniqueStart: uniqueStart,
          shift: 'shift1',
          maxNumberOfLoads: maxNumberOfLoads
        };

        const countCurrentAssignments = jobEvent.assignments.length
        const totalAssignments = countCurrentAssignments + 1
        if (jobEvent.numTrucks && totalAssignments > jobEvent.numTrucks) {
          this.confirmUpdateTrucksRequested({
            numTrucksToAssign: 1,
            numTrucksAssigned: countCurrentAssignments,
            totalAssignments,
            jobEventId: jobEvent.id,
            jobEventName: jobEvent.jobDisplayName,
            successCallback: () => this.createAssignments([assignment])
          })
        } else {
          newAssignments.push(assignment)
        }
      });

      if (newAssignments.length) {
        this.createAssignments(newAssignments)
      }
    }
  }

  exportAssignments(): void {
    let filters = {
      'jobevent__shift1_start__gte': moment(this.selectedDate).startOf('day').toISOString(),
      'jobevent__shift1_start__lte': moment(this.selectedDate).endOf('day').toISOString()
    };
    this.assignmentService.export({}, filters).subscribe(() => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{ type: 'assignments' }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          params: params,
          service: this.assignmentService,
          buttonText: 'Try to Export Again'
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
    });
  }

  getLastAssignmentTime(jobEvent: DispatchJobEvent): string {
    const assignmentMoments = jobEvent.assignments.map(a => (moment(a.uniqueStart)));
    return jobEvent.assignments && jobEvent.assignments.length > 0 ?
      moment.max(assignmentMoments).toISOString() :
      jobEvent.shift1Start;
  }

  openDriverEditPanel(driver: DispatchDriver) {
    this.selectedDriver = driver;
    this.driverAssignmentsList.setOpen(driver);
  }

  openJobEventEditPanel(jobEvent: DispatchJobEvent) {
    this.selectedJobEvent = jobEvent;
    this.jobEventEditPanelOpen = true;
  }

  closeEditPanel(e) {
    this.selectedDrivers = [];
    this.jobEventEditPanelOpen = false;
  }

  editPanelCallback(jobEvent: DispatchJobEvent) {
    jobEvent.assignedLoads = jobEvent.assignments.reduce((a, obj) => (a + Number(obj.maxNumberOfLoads)), 0);
    jobEvent.assignedTons = Number(jobEvent.loadDeliveryTarget) * jobEvent.assignedLoads;
    jobEvent.truckList = jobEvent.assignments.reduce((a, obj) => (a + (obj.truck.name && obj.truck.name + '\n')), '');
    this.filteredJobEvents[this.filteredJobEvents.findIndex(j => (j.id === jobEvent.id))] = jobEvent;
    this.getJobEventStats(this.filteredJobEvents);
  }

  driverEditPanelCallback(assignment: Assignment) {
    let assignmentIndex: number;
    let updatedDriver: DispatchDriver = this.filteredDrivers.find(d => {
      const foundAssignment = d.assignments.findIndex(a => (a.id === assignment.id));
      if (foundAssignment > -1) {
        assignmentIndex = foundAssignment;
        return true;
      } else { return false; }
    });
    updatedDriver.assignments[assignmentIndex] = assignment;
    this.updateAssignment(updatedDriver.assignments);
  }

  // preference methods
  getPreferences(): void {
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: this.user && this.user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preference = preferences[0];
        if (this.preference.blob) {
          this.activeLoadType = this.preference.blob['activeLoadType'] ? this.preference.blob['activeLoadType'] :
            this.preference.blob['dispatchLoadType'] === 'by-load' ? 'Loads Ordered' : 'Trucks Requested';
          if (this.preference.blob['date'] && !this.selectedDateParam) {
            this.selectDate([new Date(this.preference.blob['date'])]);
          } else if (!this.selectedDateParam) { this.selectDate([new Date()]); }
        }
      } else if (!this.selectedDateParam) { this.selectDate([(new Date())]); }
    }, (err) => this.errors = parseErrors(err));
  }

  savePreferences(updates: any): void {
    if (
      this.preference && this.preference.blob &&
      (updates['date'] || !updates['activeLoadType'] || this.preference.blob['activeLoadType'] !== updates['activeLoadType'])
    ) {
      this.preferenceService.save({
        name: this.preferenceKey,
        type: 'user',
        profile: this.user && this.user.id,
        blob: this.preference && this.preference.blob ? Object.assign(this.preference.blob, updates) : updates
      }).subscribe(preference => this.preference = preference, (err) => this.errors = parseErrors(err));
    }
  }

  getCarriers(query = {}): void {
    if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
      this.carriersReq.unsubscribe();
    }
    this.carrierDropdownData.carriers = [
      <Carrier>{ name: 'My Drivers', id: 'my_drivers' },
      <Carrier>{ name: 'All Carriers', id: 'all_carriers' },
      <Carrier>{ name: 'Leased', id: 'all_leased' }
    ];
    this.carriersReq = this.connectionService.list({
      ordering: 'organization__name',
      allow_dispatch: 'True',
      is_carrier: 'True',
      ...query
    }).subscribe(connections => {
      let _carriers = connections.map(connection => {
        return <Carrier>{
          name: connection.organization.name,
          id: connection.organization.carrier.id
        };
      });
      this.carrierDropdownData.carriers = this.carrierDropdownData.carriers.concat(_carriers);
      this.carrierDropdownData.loading = false;
    }, err => this.errors = parseErrors(err));
  }

  dropdownNextPage(type: string): void {
    let config, service, options;

    switch (type) {
      case 'carrier':
        config = this.carrierDropdownData.config;
        service = this.connectionService;
        options = this.carrierDropdownData.carriers;
        break;
    }

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'carrier':
              let _carriers = results.map(connection => {
                return {
                  name: connection.organization.name,
                  id: connection.organization.carrier.id
                };
              });
              this.carrierDropdownData.carriers = this.carrierDropdownData.carriers.concat(_carriers);
              break;
          }
        }, (err) => this.errors = parseErrors(err), () => config.loadingOptions = false);
      }
    }
  }

  selectCarrier(carrier: Carrier): void {
    this.selectedDrivers = [];
    this.carrierDropdownData.carrier = carrier;
    let carrierQuery = {
      all_carriers: null,
      all_leased: null,
      carrier: null
    };
    switch (carrier.id) {
      case 'my_drivers':
        break;
      case 'all_carriers':
        carrierQuery.all_carriers = true;
        break;
      case 'all_leased':
        carrierQuery.all_leased = true;
        break;
      default:
        carrierQuery.carrier = carrier.id;
        break;
    }
    if (this.driverQuery.all_carriers && carrierQuery.carrier && this.drivers) {
      this.filteredDrivers = this.drivers.filter(d => (d.carrier.id === carrierQuery.carrier));
    } else if (this.driverQuery.all_carriers && carrierQuery.all_carriers && this.drivers) {
      this.filteredDrivers = [...this.drivers];
    } else {
      this.changeDriverQuery(carrierQuery);
    }
  }

  openDisplayErrorModal(errors: any[]): void {
    this.assignmentService.listAllProgress.next(100);
    let _errors = [];
    let _truckErrors = [];
    if (errors && errors.length) {
      errors.forEach(error => {
        let driver, truck, jobEvent;
        if (error.item && this.filteredDrivers) {
          driver = error.item.driver && this.filteredDrivers.find(d => (d.id === (error.item && error.item.driver)));
          truck = error.item.truck && driver.truck;
          jobEvent = error.item.jobevent && this.jobEvents.find(j => (j.id === (error.item && error.item.jobevent)));
        } else if (error.data && this.filteredDrivers) {
          driver = this.filteredDrivers.find(d => (d.id === (error.data && error.data.driver)));
        }
        
        const errorMessage = error.errors && error.errors.non_field_errors

        if (
          error.errors && (
            error.errors.truck || (error.errors.non_field_errors && error.errors.non_field_errors.join(' ').includes('Invalid truck type'))
          )
        ) {
          _truckErrors.push({
            errors: error.errors,
            assignment: Object.assign(error.item, {
              driver: driver,
              truck: truck,
              jobevent: jobEvent
            })
          });
        } else if (driver) {
          _errors.push({
            error: error.errors.non_field_errors,
            driverName: driver.name,
            truckName: driver.truck && driver.truck.displayName
          });
        } else if (errorMessage) {
          _errors.push({
            error: errorMessage,
          });
        }
      });
    }
    if (_truckErrors.length) {
      const dialog = this.dialog.open(AssignmentTruckManagementDialogComponent, {
        width: '430px'
      });
      dialog.componentInstance.assignmentErrors = _truckErrors;
      dialog.componentInstance.callback = (assignments: Assignment[]) => {
        this.assignmentService.bulkCreate(assignments).subscribe(bulkRes => {
          this.filteredJobEvents.map(j => ({...j, selected: false}));
          this.applyAssignmentChanges(bulkRes.assignments);
          this.selectedDrivers = [];
          if (bulkRes.errors && bulkRes.errors.length) { this.openDisplayErrorModal(bulkRes.errors); }
        }, err => {
          if (err.errors && err.errors.length) { this.openDisplayErrorModal(err.errors); }
        });
      };
      dialog.componentInstance.refreshGrid = () => {
          this.filteredDrivers = [...this.filteredDrivers];
      };
    } else if (_errors.length) {
      const dialog = this.dialog.open(AssignmentErrorDialogComponent, {
        width: '430px'
      });
      dialog.componentInstance.errors = _errors;
    }
  }

  openFilters(): void {
    const dialog = this.dialog.open(DispatchScheduleFiltersDialogComponent, {
      width: '430px',
      data: {
        filterSelection: this.selectedFilters,
        filterOptions: this.filterOptions
      }
    });

    if (this.selectedFilters && this.selectedFilters.length) {
      if (Object.keys(this.filterOptions)) {
        const selectedJobFilter = this.selectedFilters.find(filter => filter.title === 'Job');
        const selectedProjectFilter = this.selectedFilters.find(filter => filter.title === 'Project');
        const selectedCustomerFilter = this.selectedFilters.find(filter => filter.title === 'Customer');
        const selectedStartLocationFilter = this.selectedFilters.find(filter => filter.title === 'Start Location');
        const selectedEndLocationFilter = this.selectedFilters.find(filter => filter.title === 'End Location');
        if (selectedJobFilter && selectedJobFilter.values && selectedJobFilter.values.length) {
          dialog.componentInstance.selectedJob = this.filterOptions.jobs &&
                                                 this.filterOptions.jobs.find(job => job.id === selectedJobFilter.values[0]);
        }
        if (selectedProjectFilter && selectedProjectFilter.values && selectedProjectFilter.values.length) {
          dialog.componentInstance.selectedProject =
          this.filterOptions.projects && this.filterOptions.projects.find(project => project.name === selectedProjectFilter.values[0]);
        }
        if (selectedCustomerFilter && selectedCustomerFilter.values && selectedCustomerFilter.values.length) {
          dialog.componentInstance.selectedCustomer =
          this.filterOptions.customers && this.filterOptions.customers.find(customer => customer.id === selectedCustomerFilter.values[0]);
        }
        if (selectedStartLocationFilter && selectedStartLocationFilter.values && selectedStartLocationFilter.values.length) {
          dialog.componentInstance.selectedStartLocation =
          this.filterOptions.startLocations &&
          this.filterOptions.startLocations.find(loc => loc.id === selectedStartLocationFilter.values[0]);
        }
        if (selectedEndLocationFilter && selectedEndLocationFilter.values && selectedEndLocationFilter.values.length) {
          dialog.componentInstance.selectedEndLocation =
          this.filterOptions.endLocations && this.filterOptions.endLocations.find(loc => loc.id === selectedEndLocationFilter.values[0]);
        }
      }
      
      const jobNumberFilter = this.selectedFilters.find(filter => filter.title === 'Job #');
      if (jobNumberFilter && jobNumberFilter.values && jobNumberFilter.values.length) {
        dialog.componentInstance.model.jobNumber = jobNumberFilter.values[0]
      }
    }
    
    dialog.componentInstance.callback = res => {
      this.selectedFilters = res;
      this.filterChanges(res);
    };
  }

  filterJobEvents(): void {
    this.filteredJobEvents = [...this.jobEvents];
    this.filterOptions = {
      jobs: Array.from(
        new Set(this.jobEvents.map(j => (j.jobId)))
      ).map(id => (<Job>{id: id, name: this.jobEvents.find(j => (j.jobId === id)).jobName})),
      projects: Array.from(
        new Set(this.jobEvents.map(j => (j.projectName)))
      ).map(name => (<Project>{name: name})),
      customers: Array.from(
        new Set(this.jobEvents.map(j => (j.customerId)))
      ).map(id => (<Customer>{id: id, name: this.jobEvents.find(j => (j.customerId === id)).customer})),
      startLocations: Array.from(
        new Set(this.jobEvents.map(j => (j.startLocation)))
      ).map(id => (<Location>{id: id, name: this.jobEvents.find(j => (j.startLocation === id)).startLocationName})),
      endLocations: Array.from(
        new Set(this.jobEvents.map(j => (j.endLocation)))
      ).map(id => (<Location>{id: id, name: this.jobEvents.find(j => (j.endLocation === id)).endLocationName}))
    };
  }

  filterChanges(filters: DispatchScheduleFilterSelection) {
    this.filteredJobEvents = [...this.jobEvents];
    filters.forEach(filter => {
      this.filteredJobEvents = this.filteredJobEvents.filter(row => {
        if (filter.title === 'Job') { return filter.values[0] === row.jobId; }
        if (filter.title === 'Project') { return filter.displayValues[0] === row.projectName; }
        if (filter.title === 'Customer') { return filter.values[0] === row.customerId; }
        if (filter.title === 'Start Location') { return filter.values[0] === row.startLocation; }
        if (filter.title === 'End Location') { return filter.values[0] === row.endLocation; }
        if (filter.title === 'Job #') { return row.jobNumber && row.jobNumber.toLowerCase().includes(filter.values[0].toLowerCase()) }
      });
    });
  }

  filtersActive(): boolean {
    return this.selectedFilters.length > 0;
  }

  handleErrors(errors: string[]) {
    this.errors = this.errors.concat(errors);
  }

  getExistingAssignments(): Assignment[] {
    let existingAssignments: Assignment[] = [];
    if (this.jobEvents) {
      this.jobEvents.forEach(jobEvent => {
        if (jobEvent.assignments && jobEvent.assignments.length) {
          existingAssignments = existingAssignments.concat(jobEvent.assignments);
        }
      });
    }
    return existingAssignments;
  }

  hasUpdatedJobEvents(): boolean {
    let hasUpdates = false;
    if (this.jobEvents && this.updatedJobEvents.length) {
      this.updatedJobEvents.forEach(jobEvent => {
        const existingJobEventIdx = this.jobEvents.findIndex(j => j.id === jobEvent.id);
        const mergedJobEvent = Object.assign({...this.jobEvents[existingJobEventIdx]}, jobEvent);
        if (this.jobEvents[existingJobEventIdx].hasOwnProperty('updates')) {
          mergedJobEvent['updates'] = this.jobEvents[existingJobEventIdx];
        }
        const existingJobEventUnchanged = existingJobEventIdx > -1 ? JSON.stringify(mergedJobEvent) === JSON.stringify(this.jobEvents[existingJobEventIdx]) : false;
        if (!existingJobEventUnchanged) {
          hasUpdates = true;
        }
      });
    }
    return hasUpdates;
  }

  hasUpdatedAssignments(): boolean {
    let hasUpdates = false;
    if (this.filteredJobEvents && this.updatedAssignments.length) {
      this.updatedAssignments.forEach(assignment => {
        const jobIndex = this.filteredJobEvents.findIndex(jobEvent => (jobEvent.id === assignment.jobevent.id));
        if (jobIndex > -1) {
          const assignmentIdx = this.filteredJobEvents[jobIndex].assignments.findIndex(a => a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id);
          if (assignmentIdx === -1) {
            hasUpdates = true;
          }
        }
      });
    }
    return hasUpdates;
  }
}
