<form
  #newDriver="ngForm"
  class="new-driver-dialog-container"
  [ngClass]="{
    mobile: device && device.mobile,
    tablet: device && device.tablet,
    desktop: (device && device.desktop) || !device
  }"
>
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add a Driver</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="driver-image" *ngIf="model.image">
        <img src="{{ model.image }}" />
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group first-name" fxFlex="50">
          <label class="required" translate>First</label>
          <input
            required
            type="text"
            name="first-name"
            placeholder="{{ 'First Name' | translate }}"
            [(ngModel)]="model.profile.firstName"
            #firstName="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': firstName.valid,
              'ng-invalid': firstName.invalid,
              'ng-pristine': firstName.pristine
            }"
          ></span>
        </div>

        <div class="field-group last-name" fxFlex="50">
          <label class="required" translate>Last</label>
          <input
            required
            type="text"
            name="last-name"
            placeholder="{{ 'Last Name' | translate }}"
            [(ngModel)]="model.profile.lastName"
            #lastName="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': lastName.valid,
              'ng-invalid': lastName.invalid,
              'ng-pristine': lastName.pristine
            }"
          ></span>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group cdl" fxFlex="50">
          <label translate>CDL</label>
          <input
            type="text"
            name="cdl"
            placeholder="{{ 'CDL' | translate }}"
            [(ngModel)]="model.cdl"
            #cdl="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': cdl.valid,
              'ng-invalid': cdl.invalid,
              'ng-pristine': cdl.pristine
            }"
          ></span>
        </div>

        <div class="field-group billing-id" fxFlex="50">
          <label translate>Billing ID</label>
          <input
            type="text"
            name="billing-id"
            placeholder="{{ 'Billing ID' | translate }}"
            [(ngModel)]="model.uniqueBillingId"
            #billingId="ngModel"
          />
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group username" fxFlex="50">
          <label class="required" translate>Username</label>
          <input
            type="text"
            name="username"
            placeholder="{{ 'Username' | translate }}"
            required
            [(ngModel)]="model.user.username"
            #username="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': username.valid,
              'ng-invalid': username.invalid,
              'ng-pristine': username.pristine
            }"
          ></span>
        </div>

        <div class="field-group password" fxFlex="50">
          <label class="required" translate>Password</label>
          <input
            type="password"
            name="password"
            placeholder="{{ 'Password' | translate }}"
            required
            [(ngModel)]="model.user.password"
            #password="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': password.valid,
              'ng-invalid': password.invalid,
              'ng-pristine': password.pristine
            }"
          ></span>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngIf="leasedMode"
      >
        <div class="field-group organization" fxFlex="100">
          <label class="required" translate>Carrier</label>
          <ruckit-dropdown
            [config]="carriersConfig"
            [selectedOption]="carrierId"
            (onSelect)="selectCarrier($event)"
          >
          </ruckit-dropdown>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group email" fxFlex="100">
          <label translate>Email</label>
          <input
            type="text"
            name="email"
            placeholder="{{ 'Email' | translate }}"
            [(ngModel)]="model.profile.email"
            #email="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': email.valid,
              'ng-invalid': email.invalid,
              'ng-pristine': email.pristine
            }"
          ></span>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group address" fxFlex="100">
          <label translate>Address</label>
          <input
            type="text"
            name="address"
            placeholder="{{ 'Address' | translate }}"
            [(ngModel)]="model.street"
            #street="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': street.valid,
              'ng-invalid': street.invalid,
              'ng-pristine': street.pristine
            }"
          ></span>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group city" fxFlex="100">
          <label translate>City</label>
          <input
            type="text"
            name="city"
            placeholder="{{ 'City' | translate }}"
            [(ngModel)]="model.city"
            #city="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': city.valid,
              'ng-invalid': city.invalid,
              'ng-pristine': city.pristine
            }"
          ></span>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group country" fxFlex="50">
          <label translate>Country</label>
          <select name="country" (change)="selectCountry($event.target.value)">
            <option [selected]="!model.country">
              {{ 'Select Country' | translate }}
            </option>
            <option
              *ngFor="let countryOption of countryOptions"
              [value]="countryOption.id"
              [selected]="countryOption.id === model.country"
            >
              {{ countryOption.name }}
            </option>
          </select>
        </div>

        <div class="field-group state" fxFlex="50">
          <ng-container *ngIf="model.country !== 'CA'">
            <label translate>State</label>
            <select name="state" (change)="selectState($event.target.value)">
              <option [selected]="!model.state">
                {{ 'Select State' | translate }}
              </option>
              <option
                *ngFor="let stateOption of stateOptions"
                [value]="stateOption.id"
                [selected]="stateOption.id === model.state"
              >
                {{ stateOption.name }}
              </option>
            </select>
          </ng-container>
          <ng-container *ngIf="model.country === 'CA'">
            <label translate>Province</label>
            <select name="state" (change)="selectState($event.target.value)">
              <option [selected]="!model.state">
                {{ 'Select Province' | translate }}
              </option>
              <option
                *ngFor="let stateOption of stateOptions"
                [value]="stateOption.id"
                [selected]="stateOption.id === model.state"
              >
                {{ stateOption.name }}
              </option>
            </select>
          </ng-container>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group zip" fxFlex="35">
          <label translate>Zip</label>
          <input
            type="text"
            name="zipcode"
            placeholder="Zip"
            [(ngModel)]="model.zipcode"
            #zipcode="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': zipcode.valid,
              'ng-invalid': zipcode.invalid,
              'ng-pristine': zipcode.pristine
            }"
          ></span>
        </div>

        <div class="field-group phone" fxFlex="65">
          <label translate>Phone</label>
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            [(ngModel)]="model.profile.phoneNumber"
            #phoneNumber="ngModel"
          />
          <span
            [ngClass]="{
              'ng-valid': phoneNumber.valid,
              'ng-invalid': phoneNumber.invalid,
              'ng-pristine': phoneNumber.pristine
            }"
          ></span>
        </div>
      </div>

      <div
        class="field-row"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="field-group" fxFlex="100">
          <label translate>Markets</label>
          <tag-input name="tags" (onSelect)="tagChange($event)"></tag-input>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let field of customFields"
        class="field-row"
      >
        <div class="field-group custom-field" fxFlex="100">
          <label>{{ field.displayName }}</label>
          <input
            type="text"
            placeholder="{{ 'Value' | translate }}"
            [name]="field.key"
            [value]="field.value"
            [(ngModel)]="model.customFieldData[field.key]"
          />
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>

  <div class="modal-footer">
    <button
      id="submit-new-driver-button"
      class="btn btn-primary"
      (click)="submit(); (false)"
      [disabled]="
        !newDriver.form.valid || loading || (leasedMode && !model.carrier)
      "
      [ngClass]="{ loading: loading }"
      translate
    >
      Add Driver
    </button>
  </div>
</form>
