"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var index_1 = require("../shared/index");
var UploadService = /** @class */ (function () {
    function UploadService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    UploadService.prototype.getS3Policy = function (path) {
        if (path === void 0) { path = 's3'; }
        var url = this.baseUrl + "organizations/" + path + "/";
        return this.http.get(url, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return res; }), operators_1.catchError(index_1.handleError));
    };
    UploadService.prototype.uploadToS3 = function (policy, fileData, fileType) {
        if (fileType === void 0) { fileType = 'text/csv'; }
        var body = new FormData();
        var blob = typeof fileData === 'string' ? new Blob([fileData], { type: fileType }) : fileData;
        body.append('Content-Type', fileType);
        body.append('key', policy.fields.key);
        body.append('AWSAccessKeyId', policy.fields.AWSAccessKeyId);
        body.append('acl', 'public-read');
        body.append('Policy', policy.fields.policy);
        body.append('Signature', policy.fields.signature);
        body.append('file', blob);
        return this.http.post(policy.url, body);
    };
    UploadService.prototype.generateUUID = function () {
        var d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now();
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };
    UploadService.prototype.convertDataUriToFile = function (data, fileName) {
        var arr = data.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    };
    return UploadService;
}());
exports.UploadService = UploadService;
