"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var project_service_1 = require("../projects/project.service");
var connection_service_1 = require("../connections/connection.service");
var api_service_1 = require("../shared/api.service");
var new_customer_dialog_component_1 = require("../customers/new-customer-dialog.component");
var NewProjectComponent = /** @class */ (function () {
    function NewProjectComponent(router, projectService, connectionService, dialog) {
        var _this = this;
        this.router = router;
        this.projectService = projectService;
        this.connectionService = connectionService;
        this.dialog = dialog;
        this.loading = false;
        this.model = {};
        this.errors = [];
        this.newCustomer = false;
        this.customerSearch = '';
        this.customerOptions = [];
        this.customerLoading = false;
        this.customerDropdownConfig = {
            nameProperty: 'name'
        };
        this.saveCustomerCallback = function (customer) {
            _this.reloadCustomerOptions();
            _this.model.customer = customer;
            _this.model.customerOrganization = customer.id;
        };
    }
    NewProjectComponent.prototype.ngOnInit = function () {
        this.customerDropdownConfig = { searchable: true, loadingOptions: false };
        this.reloadCustomerOptions();
    };
    NewProjectComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.projectService.save(this.model).subscribe(function () {
            // Project created -- determine where to go. For now, go to /project-list
            _this.router.navigate(['/project-list']);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    NewProjectComponent.prototype.submitAndContinue = function () {
        var _this = this;
        this.loading = true;
        this.projectService.save(this.model).subscribe(function (project) {
            _this.router.navigate(['/projects/' + project.id + '/new-job']);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    NewProjectComponent.prototype.selectCustomer = function (customer) {
        if (customer.name === 'New Customer') {
            this.model.originalCustomer = this.model.customer;
            this.openNewCustomer();
        }
        else {
            this.model.customer = customer;
            this.model.customerOrganization = customer.id;
            this.model.originalCustomer = null;
        }
    };
    NewProjectComponent.prototype.reloadCustomerOptions = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.customerOptions = [{ name: 'New Customer', id: null, button: true }];
        }
        this.customerLoading = true;
        if (this.connectionsReq) {
            this.connectionsReq.unsubscribe();
        }
        this.connectionsReq = this.connectionService.list({
            ordering: 'organization__name',
            search: this.customerSearch
        }).subscribe(function (connections) {
            if (append) {
                _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                    return { name: connection.organization.name, id: connection.organization.id };
                }));
            }
            else {
                _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                    return { name: connection.organization.name, id: connection.organization.id };
                }));
            }
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        });
    };
    NewProjectComponent.prototype.customerDropdownSearch = function (term) {
        this.customerOptions = [];
        this.customerDropdownConfig.loadingOptions = true;
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.customerSearch = term;
        this.reloadCustomerOptions();
        this.customerDropdownConfig.loadingOptions = false;
    };
    NewProjectComponent.prototype.customerDropdownNextPage = function () {
        var _this = this;
        if (!this.customerDropdownConfig.loadingOptions) {
            var o = this.connectionService.listNext();
            if (o) {
                this.customerDropdownConfig.loadingOptions = true;
                o.subscribe(function (connections) {
                    _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                        return { name: connection.organization.name, id: connection.organization.id };
                    }));
                    _this.customerDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.customerDropdownConfig.loadingOptions = false;
                });
            }
        }
    };
    NewProjectComponent.prototype.openNewCustomer = function () {
        var _this = this;
        this.newCustomer = true;
        this.dialogRef = this.dialog.open(new_customer_dialog_component_1.NewCustomerDialogComponent, {
            width: '444px'
        });
        this.dialogRef.componentInstance.callback = this.saveCustomerCallback;
        this.dialogRef.afterClosed().subscribe(function (result) {
            if (_this.model.customer) {
                _this.selectCustomer({ name: _this.model.customer.name, id: _this.model.customer.id });
            }
            else {
                _this.customerDropdown.deselectAll();
            }
        });
    };
    return NewProjectComponent;
}());
exports.NewProjectComponent = NewProjectComponent;
