import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PlantManagerRoutingModule } from './plant-manager-routing.module';
import { PlantsComponent } from './plants.component';
import { TruckMapComponent } from '../map/truck-map/truck-map.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PlantManagerRoutingModule
  ],
  declarations: [
    PlantsComponent,
    TruckMapComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class PlantManagerModule { }
