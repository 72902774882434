"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var AssignmentErrorDialogComponent = /** @class */ (function () {
    function AssignmentErrorDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
    }
    return AssignmentErrorDialogComponent;
}());
exports.AssignmentErrorDialogComponent = AssignmentErrorDialogComponent;
