<form #filterJobs="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Active Locations</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group location-type" fxFlex="100">
          <label translate>Location Type</label>
          <ruckit-dropdown #dropdownWrapper
            class="location-type-dropdown"
            [config]="locationTypeDropdownConfig"
            [selectedOption]="model.location_type"
            (onUserSelect)="selectLocationType($event)"
            (onSelect)="selectLocationType($event, false)">
          </ruckit-dropdown>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>
