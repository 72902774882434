import { ProductType } from './product-type';
import { Organization } from '../organizations/organization';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ProductTypeSerializer {

  /**
  * @param  {any} json
  * @returns ProductType
  */
  fromJson(json: any): ProductType {
    json = camelcaseKeysDeep(json);
    const productType = new ProductType();
    if (!json) { return productType; }

    productType.id = json.id;
    productType.createdAt = json.createdAt;
    productType.lastModified = json.lastModified;
    productType.name = json.name;
    productType.parameterizedName = json.name && json.name.toLowerCase();
    if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
      productType.ownerOrganization = new Organization(json.ownerOrganization);
    } else {
      productType.ownerOrganization = new Organization({ id: json.ownerOrganization });
    }

    return productType;
  }

  /**
   * @param  {ProductType} productType
   * @returns any
   */
  toJson(productType: ProductType): any {
    let json = {
      id: productType.id,
      name: productType.name
    };

    return decamelizeKeysDeep(json);
  }
}
