import { Component, ViewChild, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// angular material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// components
import { RuckitDropdownComponent } from '../../../shared/ruckit-dropdown/ruckit-dropdown.component';

// types
import { Job } from '../../../jobs/job';
import { Project } from '../../../projects/project';
import { Customer } from '../../../customers/customer';
import { Location } from '../../../locations/location';
import {
  DispatchScheduleFilterOptions,
  DispatchScheduleFilterSelection,
} from './types';

@Component({
  selector: 'assignment-filters-dialog',
  templateUrl: './dispatch-schedule-filters-dialog.component.html',
  styleUrls: ['./dispatch-schedule-filters-dialog.component.scss'],
})
export class DispatchScheduleFiltersDialogComponent {
  loading = false;
  errors = [];
  selectedOptions: DispatchScheduleFilterSelection = <
    DispatchScheduleFilterSelection
  >[];
  callback: Function;

  jobs: Job[] = [];
  jobsOriginal: Job[] = [];
  selectedJob: Job;
  jobsConfig = {
    selectText: this.translateService.instant('Select Job'),
    loadingText: this.translateService.instant('Loading Jobs...'),
    noResultsText: this.translateService.instant('No Jobs'),
    searchable: true,
  };
  @ViewChild('jobsDropdown', { static: false })
  jobsDropdown: RuckitDropdownComponent;
  projects: Project[] = [];
  projectsOriginal: Project[] = [];
  selectedProject: Project;
  projectsConfig = {
    selectText: this.translateService.instant('Select Project'),
    loadingText: this.translateService.instant('Loading Projects...'),
    noResultsText: this.translateService.instant('No Projects'),
  };
  @ViewChild('projectsDropdown', { static: false })
  projectsDropdown: RuckitDropdownComponent;
  customers: Customer[] = [];
  customersOriginal: Customer[] = [];
  selectedCustomer: Customer;
  customersConfig = {
    selectText: this.translateService.instant('Select Customer'),
    loadingText: this.translateService.instant('Loading Customers...'),
    noResultsText: this.translateService.instant('No Customers'),
  };
  @ViewChild('customersDropdown', { static: false })
  customersDropdown: RuckitDropdownComponent;
  startLocations: Location[] = [];
  endLocations: Location[] = [];
  startLocationsOriginal: Location[] = [];
  endLocationsOriginal: Location[] = [];
  selectedStartLocation: Location;
  selectedEndLocation: Location;
  locationsConfig = {
    selectText: this.translateService.instant('Select Location'),
    loadingText: this.translateService.instant('Loading Locations...'),
    noResultsText: this.translateService.instant('No Locations'),
  };
  @ViewChild('startLocationsDropdown', { static: false })
  startLocationsDropdown: RuckitDropdownComponent;
  @ViewChild('endLocationsDropdown', { static: false })
  endLocationsDropdown: RuckitDropdownComponent;

  model: { [filter: string]: any } = {
    jobNumber: null,
  };

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DispatchScheduleFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filterOptions: DispatchScheduleFilterOptions;
      filterSelection: DispatchScheduleFilterSelection;
    }
  ) {
    Object.keys(data.filterOptions).forEach((key) => {
      this[key] = data.filterOptions[key];
      this[`${key}Original`] = [...data.filterOptions[key]];
    });
    if (data.filterSelection.length) {
      this.selectedOptions = data.filterSelection;
      this.selectedOptions.forEach((o) => {
        this['selected' + o.title] = o.values[0];
      });
    }
  }

  onSelect(
    filterType:
      | 'Job'
      | 'Customer'
      | 'Project'
      | 'Start Location'
      | 'End Location',
    e: Project | Customer | Job | Location
  ): void {
    if (this.selectedOptions.findIndex((o) => o.title === filterType) > -1) {
      this.selectedOptions = this.selectedOptions.filter(
        (o) => o.title !== filterType
      );
      this[
        `${filterType[0].toLowerCase()}${filterType
          .slice(1)
          .replace(/\s/g, '')}sDropdown`
      ].deselectOptions();
    } else {
      this.selectedOptions.push({
        title: filterType,
        displayValues: [e.name],
        values: [e.id || e.name],
        hidden: false,
      });
    }
  }

  onSearch(type: string, search: string) {
    this[type] = this[`${type}Original`].filter((j) =>
      j.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  clear() {
    this.selectedOptions = <DispatchScheduleFilterSelection>[];
    this.jobsDropdown.deselectOptions();
    this.projectsDropdown.deselectOptions();
    this.customersDropdown.deselectOptions();
    this.startLocationsDropdown.deselectOptions();
    this.endLocationsDropdown.deselectOptions();
    this.model.jobNumber = ''
  }

  clearModelFilters(): void {
    this.selectedOptions = this.selectedOptions.filter(
      (o) => o.title !== 'Job #'
    )
  }

  submit(): void {
    this.clearModelFilters()

    if (this.model.jobNumber) {
      this.selectedOptions = this.selectedOptions.concat([
        {
          title: 'Job #',
          displayValues: [this.model.jobNumber],
          values: [this.model.jobNumber],
          hidden: false,
        }
      ])
    }
    this.callback(this.selectedOptions);
    this.dialogRef.close();
  }
}
