"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var api_service_1 = require("../../shared/api.service");
var JobLoadService = /** @class */ (function () {
    function JobLoadService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    JobLoadService.prototype.getLoads = function (jobEventId) {
        var url = this.baseUrl + "connex/schedules/" + jobEventId + "/get/";
        return this.http
            .get(url, { headers: api_service_1.requestHeaders() })
            .pipe(operators_1.catchError(function (err) { return api_service_1.handleError(err); }));
    };
    JobLoadService.prototype.patchLoads = function (jobEventId, data) {
        var url = this.baseUrl + "connex/schedules/" + jobEventId + "/patch/";
        return this.http
            .post(url, data, {
            headers: api_service_1.requestHeaders(),
        })
            .pipe(operators_1.catchError(function (err) { return api_service_1.handleError(err); }));
    };
    return JobLoadService;
}());
exports.JobLoadService = JobLoadService;
