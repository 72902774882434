import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Trackable } from './trackable';
import { TrackableSerializer } from './trackable.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class TrackableService extends ResourceService<Trackable> {
  constructor(http: HttpClient) {
    super(http, 'trackables/', new TrackableSerializer());
  }
}
