import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';

// services
import { CheckinService } from '../../../checkins/checkin.service';
import { TripService } from '../../trip.service';
import { AttachmentService } from '../../../attachments/attachment.service';

// components
import { MultipleImagesEditComponent } from '../../../shared/multiple-images-edit/multiple-images-edit.component';

// models
import { CondensedTrip } from '../../condensed-trip';

// libs
import { parseErrors } from '../../../shared';

// constants
import {
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
} from '../../../app.constants';

@Component({
  selector: 'trip-history-ticket',
  templateUrl: './trip-history-ticket.component.html',
  styleUrls: ['./trip-history-ticket.component.scss'],
})
export class TripHistoryTicketComponent implements OnChanges {
  @Input()
  trip: CondensedTrip;

  @ViewChild('editImagesContainer', { static: false })
  editImagesContainer: MultipleImagesEditComponent;

  loadingImages = [];
  unloadingImages = [];
  loadingSignatureImages = [];
  unloadingSignatureImages = [];
  imagesToDelete = [];
  errors = [];

  constructor(
    private checkinService: CheckinService,
    private tripService: TripService,
    private attachmentService: AttachmentService
  ) {}

  ngOnChanges(): void {
    if (this.trip) {
      this.parseAttachments(this.trip);
    }
  }

  handleImagesDialogVisibility() {
    this.editImagesContainer.handleVisibility();
  }

  parseAttachments(trip: CondensedTrip) {
    this.loadingImages = trip.loadingAttachments
      .filter((att) => att.fileType === TRIPTICKETIMAGETYPE)
      .map((file) => ({
        id: file.id,
        src: file.file,
      }));

    this.loadingSignatureImages = trip.loadingAttachments
      .filter((att) => att.fileType === TRIPSIGNATUREIMAGETYPE)
      .map((file) => ({
        id: file.id,
        src: file.file,
      }));

    this.unloadingImages = trip.unloadingAttachments
      .filter((att) => att.fileType === TRIPTICKETIMAGETYPE)
      .map((file) => ({
        id: file.id,
        src: file.file,
      }));

    this.unloadingSignatureImages = trip.unloadingAttachments
      .filter((att) => att.fileType === TRIPSIGNATUREIMAGETYPE)
      .map((file) => ({
        id: file.id,
        src: file.file,
      }));
  }

  requestRetake(id: string) {
    this.checkinService.requestRetake(id).subscribe(
      () => {
        this.trip.retakeStatus = 'requested';
      },
      (err) => {
        this.errors = parseErrors(err);
      }
    );
  }

  undoRequestRetake() {
    if (this.trip.retakeRequested) {
      this.tripService.undoRetakeRequest(this.trip).subscribe(
        () => {
          this.trip.retakeStatus = null;
        },
        (err) => {
          this.errors = parseErrors(err);
        }
      );
    }
  }

  parseImageOnChange(image: any) {
    if (image && (image.isNew || image.isEdited)) {
      let reader = new FileReader();
      reader.onload = () => {
        image.file = reader.result;
        image.src = reader.result;
      };
      reader.readAsDataURL(image);
      return image;
    }
    if (image && image.isDeleted) {
      this.imagesToDelete.push(image);
    }
    return image;
  }

  onLoadingImagesChange(images: any[]) {
    this.loadingImages = images.map((img: any) =>
      img.isNew || img.isEdited
        ? Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        : this.parseImageOnChange(img)
    );
  }

  onLoadingSignaturesChange(images: any[]) {
    this.loadingSignatureImages = images.map((img: any) =>
      img.isNew || img.isEdited
        ? Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        : this.parseImageOnChange(img)
    );
  }

  onUnloadingImagesChange(images: any[]) {
    this.unloadingImages = images.map((img: any) =>
      img.isNew || img.isEdited
        ? Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        : this.parseImageOnChange(img)
    );
  }

  onUnloadingSignaturesChange(images: any[]) {
    this.unloadingSignatureImages = images.map((img: any) =>
      img.isNew || img.isEdited
        ? Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        : this.parseImageOnChange(img)
    );
  }

  uploading(uploading: boolean) {
    console.log(uploading);
  }

  saveImages() {
    const requests = this.attachmentService.combineTicketImagesAndAttachments(
      this.imagesToDelete,
      this.loadingImages,
      this.loadingSignatureImages,
      this.unloadingImages,
      this.unloadingSignatureImages,
      this.trip.loadingCheckin.id,
      this.trip.unloadingCheckin.id
    );

    forkJoin(requests).subscribe(
      (res) => {
        this.handleImagesDialogVisibility();
      },
      (err) => {
        this.errors = parseErrors(err);
      }
    );
  }
}
