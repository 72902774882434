"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
// libraries
var ngx_device_detector_1 = require("ngx-device-detector");
var lodash_1 = require("lodash");
var moment = require("moment");
// services
var core_2 = require("@ngx-translate/core");
var shared_1 = require("../../shared");
var api_service_1 = require("../../shared/api.service");
var end_of_day_service_1 = require("./end-of-day.service");
var preference_service_1 = require("../../preferences/preference.service");
var end_of_day_drivers_service_1 = require("./end-of-day-drivers.service");
// components
var driver_daily_component_1 = require("../../drivers/driver-daily/driver-daily.component");
var driver_daily_tasks_component_1 = require("../../drivers/driver-daily-tasks/driver-daily-tasks.component");
var end_of_day_overall_stats_component_1 = require("../end-of-day-overall-stats/end-of-day-overall-stats.component");
var dialogs_1 = require("../../shared/dialogs");
var end_of_day_filters_component_1 = require("../end-of-day-filters/end-of-day-filters.component");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var driver_1 = require("../../drivers/driver");
// pipes
var eod_filter_pipe_1 = require("../../shared/pipes/eod-filter.pipe");
var default_filters_1 = require("../end-of-day-filters/default-filters");
// constants
var app_constants_1 = require("../../app.constants");
var EndOfDayComponent = /** @class */ (function () {
    function EndOfDayComponent(route, router, location, authenticationService, deviceDetectorService, endOfDayService, endOfDayDriversService, translationService, preferenceService, eodFilterPipe, dialog) {
        this.route = route;
        this.router = router;
        this.location = location;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.endOfDayService = endOfDayService;
        this.endOfDayDriversService = endOfDayDriversService;
        this.translationService = translationService;
        this.preferenceService = preferenceService;
        this.eodFilterPipe = eodFilterPipe;
        this.dialog = dialog;
        this.loading = true;
        this.driverDataLoading = false;
        this.errors = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.page = 1;
        this.pageSize = 25;
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.overallStatsEnabled = false;
        this.drivers = [];
        this.originalDrivers = [];
        this.keyStatus = [];
        this.enabledFeatures = [];
        this.appliedFilters = default_filters_1.defaultFilters(this.translationService).slice();
        this.uniqueCarriers = [];
        this.preferenceKey = 'EndOfDayReports-Filters';
        this.allSubscriptionsToUnsubscribe = [];
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    EndOfDayComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = {};
        this.organization = this.authenticationService.getOrganization();
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        this.getFiltersPreference();
        if (this.enabledFeatures.includes('endOfDayOverallStatsEnabled')) {
            this.overallStatsEnabled = true;
        }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.pipe(operators_1.delay(0)).subscribe(function (result) {
            _this.loading = true;
            if (result.qparams['date']) {
                _this.reportDate = moment(result.qparams['date'], 'YYYYMMDD').toDate();
            }
            else {
                _this.setDefaultDate();
            }
            if (result.qparams['driver']) {
                _this.driverId = result.qparams['driver'];
            }
            if (result.qparams['search']) {
                _this.search = result.qparams['search'];
            }
            _this.getDrivers();
        }));
    };
    EndOfDayComponent.prototype.ngOnChanges = function (changes) {
        var reportDate = changes['reportDate'];
        if (reportDate) {
            var previousReportDate = reportDate.previousValue && reportDate.previousValue.toDateString();
            var currentReportDate = reportDate.currentValue && reportDate.currentValue.toDateString();
            if (previousReportDate !== currentReportDate) {
                this.getDrivers();
            }
        }
    };
    EndOfDayComponent.prototype.ngOnDestroy = function () {
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    EndOfDayComponent.prototype.updatedMarkets = function () {
    };
    EndOfDayComponent.prototype.setDefaultDate = function () {
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        var date = moment();
        queryParams['date'] = date.format('YYYYMMDD');
        this.reportDate = date.toDate();
        this.router.navigate([], { queryParams: queryParams });
    };
    EndOfDayComponent.prototype.onDateChanged = function (dates) {
        if (dates && dates[0]) {
            this.loading = true;
            this.reportDate = dates[0];
            var queryParams = Object.assign({}, this.route.snapshot.queryParams);
            var date = moment(this.reportDate).format('YYYYMMDD');
            queryParams['date'] = date;
            queryParams['driver'] = null;
            this.router.navigate(['end-of-day-reports'], { queryParams: queryParams });
            this.drivers = [];
            this.selectedDriver = null;
        }
    };
    EndOfDayComponent.prototype.changedSearch = function (search) {
        this.search = search;
        this.updateUrl({ search: search });
        this.searchChanged.next(search);
    };
    EndOfDayComponent.prototype.selectDriver = function (driver) {
        if (driver === void 0) { driver = null; }
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        if (driver) {
            this.selectedDriver = driver;
            this.driverId = driver.id;
            queryParams['driver'] = driver.id;
            this.getSingleDriverData(driver.id, queryParams);
        }
        else {
            if (this.driverId) {
                var selectedDriver = lodash_1.find(this.drivers, { id: this.driverId });
                if (selectedDriver) {
                    this.selectDriver(selectedDriver);
                }
                else {
                    this.selectDriver(this.drivers[0]);
                }
            }
            else if (this.drivers && this.drivers.length) {
                this.selectDriver(this.drivers[0]);
            }
        }
    };
    EndOfDayComponent.prototype.getSingleDriverData = function (driverId, queryParams) {
        var _this = this;
        this.driverDataLoading = true;
        var startDate = this.reportDate;
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        var filters = this.parseFiltersToQuery(this.appliedFilters.slice());
        this.endOfDayService.get(driverId, __assign({ active_range: startDate.toISOString() + ',' + endDate.toISOString(), all_carriers: 'True', omit: 'invoice_total,expense_total' }, (filters))).subscribe(function (driver) {
            _this.selectedDriver = driver;
            _this.drivers = _this.drivers.map(function (d) { return d.id === driver.id ? driver : d; });
            var url = _this.router.createUrlTree([], {
                relativeTo: _this.route,
                queryParams: queryParams
            }).toString();
            _this.location.go(url);
            _this.driverDailyTasks.view = 'trips';
            _this.driverDataLoading = false;
        }, function (error) {
            _this.driverDataLoading = false;
            _this.errors = api_service_1.parseErrors(error);
        });
    };
    EndOfDayComponent.prototype.getDrivers = function (query, skipLoading) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (skipLoading === void 0) { skipLoading = false; }
        if (!skipLoading) {
            this.loading = true;
        }
        // if (this.authenticationService.hasFavoriteTags() && !!!this.filters.find(f => (f.key === 'tags'))) { query['user_tags'] = 'True'; }
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        var startDate = this.reportDate;
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        var filters = this.parseFiltersToQuery(this.appliedFilters.slice());
        this.driversReq = this.endOfDayDriversService.listAll(100, __assign({ page_size: this.pageSize, search: this.search, page: this.page, active_range: startDate.toISOString() + ',' + endDate.toISOString(), all_carriers: 'True' }, (filters), query)).subscribe(function (drivers) {
            _this.drivers = drivers;
            _this.originalDrivers = drivers.slice();
            if (_this.drivers && _this.drivers.length) {
                _this.auditAllDecisions();
                _this.selectDriver();
            }
            else {
                _this.selectedDriver = null;
            }
            // optimize this!!
            _this.uniqueCarriers = drivers
                .map(function (driver) { return driver.carrierId; })
                .filter(function (value, index, self) { return self.indexOf(value) === index; })
                .map(function (uniqCarrierId) { return ({
                id: uniqCarrierId,
                name: drivers.find(function (d) { return d.carrierId === uniqCarrierId; }).carrierOrganizationName,
                selected: false,
            }); });
            _this.filterCarriers(_this.appliedFilters);
            _this.loading = false;
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.loading = false;
        });
    };
    EndOfDayComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        this.page = this.page > 1 && this.search ? 1 : this.page;
        params['page'] = this.page > 1 ? this.page : null;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    /**
     * Analyzes each decision and caches the resulting statuses by Driver.
     */
    EndOfDayComponent.prototype.auditAllDecisions = function () {
        var _this = this;
        this.drivers.forEach(function (driver) {
            _this.auditDecisions(driver);
        });
    };
    /**
     * Analyzes each trip and decision and caches the result on the Driver.
     *
     * @param {Driver} driver The Driver to consider when caching the approval statuses
     */
    EndOfDayComponent.prototype.auditDecisions = function (driver) {
        var allDecisionsApproved = true;
        if (!(driver instanceof driver_1.Driver)) {
            driver = lodash_1.find(this.drivers, { id: driver });
        }
        if (driver && driver.trips) {
            driver.trips.forEach(function (trip) {
                var decisionStatus = trip.latestDecisionStatus;
                if (!decisionStatus || decisionStatus !== 'approved') {
                    allDecisionsApproved = false;
                }
            });
            if (!driver.trips.length) {
                allDecisionsApproved = false;
            }
        }
        else {
            allDecisionsApproved = false;
        }
        driver.allDecisionsApproved = allDecisionsApproved;
        this.determineApprovedJobs();
    };
    EndOfDayComponent.prototype.rateChanged = function () {
        this.getDrivers();
        this.overallStats.getEndOfDayOverallStats();
    };
    EndOfDayComponent.prototype.determineApprovedJobs = function () {
        var _this = this;
        this.drivers.forEach(function (driver) {
            _this.processStatuses(driver);
            _this.keyStatus.forEach(function (status) {
                var hasUnapprovedStatus = false;
                if (!status.statuses.length) {
                    hasUnapprovedStatus = true;
                }
                else {
                    hasUnapprovedStatus = status.statuses.some(function (element) { return element !== 'approved'; });
                }
                if (!hasUnapprovedStatus && !driver.allTripsApproved.includes(status.key)) {
                    driver.allTripsApproved.push(status.key);
                }
                else if (hasUnapprovedStatus) {
                    driver.allTripsApproved = driver.allTripsApproved.filter(function (e) { return e !== status.key; });
                }
            });
        });
    };
    EndOfDayComponent.prototype.processStatuses = function (driver) {
        var _this = this;
        var jobs = lodash_1.groupBy(driver.trips, 'jobDisplayName');
        Object.keys(jobs).forEach(function (key) {
            var trips = jobs[key];
            var newStatus = false;
            var status = lodash_1.find(_this.keyStatus, { driverId: driver.id, key: key });
            if (status) {
                status.loading = true;
                status.statuses = [];
            }
            else {
                newStatus = true;
                status = { driverId: driver.id, key: key, loading: true, statuses: [] };
            }
            if (trips && trips.length) {
                trips.forEach(function (trip) {
                    if (trip.latestDecision) {
                        status.statuses.push(trip.latestDecisionStatus);
                    }
                });
                status.loading = false;
                status.statuses = lodash_1.uniq(status.statuses);
                if (newStatus) {
                    _this.keyStatus.push(status);
                }
            }
        });
        this.keyStatus = this.keyStatus.slice();
    };
    /**
     * Presents a modal dialog using {@link RuckitConfirmDialogComponent} to
     * verify the export action. Upon an affermative confirmation, the API request
     * is submitted.
     */
    EndOfDayComponent.prototype.export = function () {
        var _this = this;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, app_constants_1.DEFAULT_DIALOG_SIZE);
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Export End of Day Report?'),
            body: this.translationService.instant('This will export all voided data.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Export')
        };
        var exportBody = {};
        if (this.enabledFeatures.includes('tripExportFields')) {
            exportBody['fields'] = this.authenticationService.getFeature('tripExportFields');
        }
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                var startDate = _this.reportDate;
                startDate.setHours(0, 0, 0, 0);
                var endDate = lodash_1.clone(startDate);
                endDate.setHours(23, 59, 59, 999);
                var params = {
                    page_size: _this.pageSize,
                    search: _this.search,
                    page: _this.page,
                    dispatched: null,
                    unexported: null,
                    trips_with_decision_status: null,
                    void: 'True',
                    all_carriers: 'True',
                    active_range: startDate.toISOString() + ',' + endDate.toISOString()
                };
                if (_this.enabledFeatures.includes('hasExportProtection')) {
                    params['unexported'] = 'True';
                }
                if (_this.enabledFeatures.includes('hasExportTracking')) {
                    exportBody['mark_as_exported'] = true;
                }
                // probably no need to unsubscribe from this, but just in case
                _this.allSubscriptionsToUnsubscribe.push(_this.endOfDayService.export(exportBody, params, 'trips/export/').subscribe(function () {
                    _this.loading = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                }));
            }
            _this.confirmDialog = null;
        });
    };
    EndOfDayComponent.prototype.exportPunchcards = function (type) {
        var _this = this;
        if (type === void 0) { type = null; }
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, app_constants_1.DEFAULT_DIALOG_SIZE);
        var body = this.translationService.instant('This will export all approved data and the data will be marked as exported.');
        if (type === 'all') {
            body = this.translationService.instant('This will export all data.');
        }
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Export End of Day Report?'),
            body: body,
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Export')
        };
        var exportBody = {};
        if (this.enabledFeatures.includes('punchcardExportFields')) {
            exportBody['fields'] = this.authenticationService.getFeature('punchcardExportFields');
        }
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                var startDate = _this.reportDate;
                startDate.setHours(0, 0, 0, 0);
                var endDate = lodash_1.clone(startDate);
                endDate.setHours(23, 59, 59, 999);
                var params = {
                    page_size: _this.pageSize,
                    search: _this.search,
                    page: _this.page,
                    dispatched: 'True',
                    unexported: null,
                    void: null,
                    punchcards_with_decision_status: 'approved',
                    all_carriers: 'True',
                    active_range: startDate.toISOString() + ',' + endDate.toISOString()
                };
                if (_this.enabledFeatures.includes('hasExportProtection')) {
                    params['unexported'] = 'True';
                }
                if (_this.enabledFeatures.includes('hasExportTracking')) {
                    exportBody['mark_as_exported'] = true;
                }
                _this.allSubscriptionsToUnsubscribe.push(_this.endOfDayService.export(exportBody, params, 'punchcards/export/').subscribe(function () {
                    _this.loading = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                }));
            }
            _this.confirmDialog = null;
        });
    };
    EndOfDayComponent.prototype.customExport = function (type, approvedOnly) {
        var _this = this;
        if (approvedOnly === void 0) { approvedOnly = false; }
        var params = new http_1.HttpParams();
        var scope = {};
        if (this.enabledFeatures.includes('hasExportTracking')) {
            scope['markAsExported'] = true;
        }
        if (this.enabledFeatures.includes('hasExportProtection')) {
            params = params.set('unexported', 'True');
        }
        if (this.search) {
            params = params.set('search', this.search);
        }
        params = params.set('all_carriers', 'True');
        if (approvedOnly) {
            params = params.set(type === 'trips' ? 'trips_with_decision_status' : 'punchcards_with_decision_status', 'approved');
        }
        this.appliedFilters.forEach(function (filter) {
            if (filter.values && filter.values.length) {
                params = params.set(filter.key, filter.values[0].value);
            }
        });
        this.endOfDayService.getExportFields(type + "/pandas-export/").subscribe(function (fields) {
            var startDate = _this.reportDate;
            startDate.setHours(0, 0, 0, 0);
            var endDate = lodash_1.clone(startDate);
            endDate.setHours(23, 59, 59, 999);
            params = params.set('active_range', startDate.toISOString() + ',' + endDate.toISOString());
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    title: type === 'trips' ? _this.translationService.instant('trips') : _this.translationService.instant('punchcards'),
                    type: "eod-" + type,
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: function () { },
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.endOfDayService,
                    customUrl: "endofday/" + type + "/export/"
                }
            });
        }, function (err) { _this.errors = err; });
    };
    EndOfDayComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(end_of_day_filters_component_1.EndOfDayFiltersDialogComponent, {
            width: '430px',
        });
        if (dialog) {
            dialog.componentInstance.title =
                this.translationService.instant('Filter Drivers');
            dialog.componentInstance.callback = function (res, carriers) { return _this.filterChanges(res, carriers); };
            dialog.componentInstance.uniqueCarriers = this.uniqueCarriers.slice();
            dialog.componentInstance.appliedFilters = this.appliedFilters.slice();
            dialog.componentInstance.date = this.reportDate;
            this.filtersDialog = dialog.componentInstance;
        }
    };
    EndOfDayComponent.prototype.filterCarriers = function (filters) {
        var carrier = filters.find(function (f) { return f.key === 'carrier'; });
        if (carrier && carrier.values.length) {
            // only allow one filter for now. TODO ZAN
            var drivers = this.originalDrivers.filter(function (d) { return d.carrierId === carrier.values[0].value; });
            this.drivers = drivers.slice();
            if (drivers.length) {
                this.selectDriver();
            }
            else {
                this.driverId = null;
                this.selectedDriver = null;
            }
        }
    };
    EndOfDayComponent.prototype.filterChanges = function (filters, carriers) {
        this.uniqueCarriers = carriers;
        this.appliedFilters = filters.slice();
        this.getDrivers();
        this.saveFiltersPreference(filters);
    };
    EndOfDayComponent.prototype.saveFiltersPreference = function (filters) {
        var _this = this;
        var currentUser = this.authenticationService.user();
        var preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: currentUser.id, page_size: 1, blob: { filters: filters } });
        this.preferenceService
            .save(preference)
            .subscribe(function (updatedPreference) {
            _this.preference = updatedPreference;
        });
    };
    EndOfDayComponent.prototype.getFiltersPreference = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        var preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: currentUser.id });
        this.preferenceService.list(preference).subscribe(function (preferences) {
            if (preferences.length &&
                preferences[0].blob &&
                preferences[0].blob.filters &&
                _this.eodFilterPipe.transform(preferences[0].blob.filters)) {
                var filters = preferences[0].blob.filters;
                _this.appliedFilters = filters.slice();
                _this.filterCarriers(filters.slice());
                _this.getDrivers();
            }
        });
    };
    EndOfDayComponent.prototype.removeFilterOption = function (filter) {
        // only one filter for now. TODO ZAN
        if (filter.key === 'carrier') {
            this.drivers = this.originalDrivers.slice();
            this.uniqueCarriers = this.uniqueCarriers.map(function (u) { return (__assign({}, u, { selected: false })); });
        }
        this.appliedFilters = this.appliedFilters.map(function (a) {
            if (a.key === filter.key) {
                return a.selected ? __assign({}, a, { selected: false }) : __assign({}, a, { values: [] });
            }
            else {
                return a;
            }
        });
        this.saveFiltersPreference(this.appliedFilters);
        this.getDrivers();
    };
    EndOfDayComponent.prototype.resetFilters = function () {
        this.drivers = this.originalDrivers.slice();
        this.uniqueCarriers = this.uniqueCarriers.map(function (u) { return (__assign({}, u, { selected: false })); });
        this.appliedFilters = default_filters_1.defaultFilters(this.translationService).slice();
        this.saveFiltersPreference(this.appliedFilters);
        this.getDrivers();
    };
    EndOfDayComponent.prototype.parseFiltersToQuery = function (filters) {
        var activeFilters = filters.filter(function (f) {
            return (f.key !== 'carrier' && f.values && f.values.length > 0) ||
                f.selected === true;
        });
        return activeFilters.reduce(function (result, item) {
            var _a;
            return (_a = {},
                _a[item.key] = item.values && item.values.length ? item.values[0].value : 'True',
                _a);
        }, {});
    };
    return EndOfDayComponent;
}());
exports.EndOfDayComponent = EndOfDayComponent;
