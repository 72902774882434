<div class="driver-pay-timeline-list-container">
  <notification *ngFor="let error of errors">{{ error }}</notification>
  <div class="top-bar">
    <p class="smaller" translate>{{ jobEvent.shift1Start | moment: 'MMMM Do, YYYY' }} Assignments</p>
    <mat-icon (click)="close.emit(false)">close</mat-icon>
  </div>
  <div class="job">
    <h2><a [routerLink]="['/jobs', jobEvent.jobId, jobEvent.id]">{{ jobEvent.jobName }} - {{ jobEvent.orderNumber }}</a></h2>
    <p translate>Job</p>
  </div>
  <div class="bulk-edits">
    <p translate>Quick Apply</p>
    <div class="edits">
      <label for="bulk-edit-load-count" translate>Loads:</label>
      <input 
        type="number" name="bulk-edit-load-count" placeholder="0"
        [(ngModel)]="bulkEdits.maxNumberOfLoads" min="1"/>
      <label for="driverStartTime">Start Time:</label>
      <input
        type="text" required name="driverStartTime"
        class="timepicker"
        placeholder="{{ jobEvent.shift1Start | moment: 'H:mm A' }}"
        [(ngModel)]="bulkEdits.uniqueStartTime"
        [options]="{
          disableTextInput: false,
          timeFormat: 'g:i A',
          showDuration: false,
          step: 5,
          minTime: getAssignmentStartRange(jobEvent).start,
          maxTime: getAssignmentStartRange(jobEvent).end
        }"/>
    </div>
    <div class="actions">
      <button class="btn btn-default" [disabled]="!bulkEdits.maxNumberOfLoads && !bulkEdits.uniqueStartTime" (click)="bulkEdit(true)">Apply to All</button>
      <button class="btn btn-default" [disabled]="!bulkEdits.maxNumberOfLoads && !bulkEdits.uniqueStartTime" (click)="bulkEdit()">Apply to Selected</button>
    </div>
  </div>
  <div class="assignment overview">
    <div class="assignment-details" *ngFor="let assignment of jobEvent.assignments">
      <div class="details">
        <mat-checkbox (change)="selectAssignment(assignment)"
                      [checked]="assignment.selected">
        </mat-checkbox>
        <div class="truck">
          <div class="assignment-header">
            <h3>{{ assignment.driver.name }}</h3>
            <a class="action" (click)="removeAssignment(assignment)" translate>REMOVE</a>
          </div>
          <ruckit-dropdown [config]="trucksDropdownConfig"
                           [selectedOption]="assignment.truck && assignment.truck.id"
                           (onSelect)="updateAssignment(assignment, 'truck', $event)">
          </ruckit-dropdown>
          <div class="org-name">
            {{ assignment.driver.carrier && assignment.driver.carrier.leasedOrg ? ('Leased' | translate) : assignment.driver.organizationName }}
          </div>
          <div class="times">
            <div class="start-time">
              <span class="label" translate>Start Time: </span>
              <date-range-picker
                [config]="{decreaseControl: false, increaseControl: false}"
                [selectedDates]="[assignment.uniqueStart]"
                (dateChanged)="updateAssignment(assignment, 'uniqueStart', $event)">
              </date-range-picker>
              <input type="text"
                required
                name="driverStartTime"
                class="timepicker"
                [placeholder]="assignment.uniqueStartTime"
                [(ngModel)]="assignment.uniqueStartTime"
                (change)="updateAssignment(assignment, 'uniqueStartTime', $event.target.value)"
                [options]="{
                  disableTextInput: false,
                  timeFormat: 'g:i A',
                  showDuration: false,
                  step: 5,
                  minTime: getAssignmentStartRange(jobEvent).start | date: 'h:mm a',
                  maxTime: getAssignmentStartRange(jobEvent).end | date: 'h:mm a'
                }"/>
              <span class="dropdown-arrow"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="edit">
        <div class="load-type">
          <input type="radio" name="{{ 'max-loads-' + assignment.id }}"
                 required (click)="assignment.maxNumberOfLoads = 1"
                 (change)="updateAssignment(assignment, 'maxNumberOfLoads', 1)"
                 [checked]="assignment.maxNumberOfLoads > 0"/>
          <label for="{{ 'max-loads-' + assignment.id }}"
                 [ngClass]="{ 'active': assignment.maxNumberOfLoads > 0 }"
                 translate>Loads:</label>
          <input type="number" name="{{ 'load-count-' + assignment.id }}"
                 [disabled]="assignment.maxNumberOfLoads < 1"
                 (blur)="updateAssignment(assignment, 'maxNumberOfLoads', $event.target.value)"
                 [(ngModel)]="assignment.maxNumberOfLoads"
                 min="1" />
          <input type="radio" name="{{ 'max-loads-' + assignment.id }}"
                 required (click)="assignment.maxNumberOfLoads = 0"
                 (change)="updateAssignment(assignment, 'maxNumberOfLoads', 0)"
                 [(ngModel)]="assignment.maxNumberOfLoads" [value]="0" />
          <label for="{{ 'max-loads-' + assignment.id }}"
                 [ngClass]="{ 'active': assignment.maxNumberOfLoads === 0 }"
                 translate>All Day</label>
        </div>
        <div class="assignment-notes">
          <span class="label" translate>Notes</span>
          <textarea name="assignmentNotes" [(ngModel)]="assignment.notes"
                    (blur)="updateAssignment(assignment, 'notes', $event.target.value)"></textarea>
        </div>
        <div class="status">
          <div class="completed-status">
            <input type="checkbox" [(ngModel)]="assignment.completed"
              (change)="updateAssignment(assignment, 'completed', assignment.completed)" name="assignment-completed">
            <label for="assignment-completed" translate [class.completed]="assignment.completed">Completed</label>
          </div>
          <div class="dispatch-status">
            <div class="dispatch-icon" name="dispatch-icon">
              <i *ngIf="assignment && assignment.dispatched" class="icon-dispatched"></i>
              <i *ngIf="!assignment || !assignment.dispatched" class="icon-not-dispatched"></i>
            </div>
            <label for="dispatch-icon" translate>Received</label>
          </div>
          <div class="accepted-status">
            <i name="assignment-icon" class="icon-assignment_turned_in" [class.accepted]="assignment.driverStatus === 'confirmed'"></i>
            <label for="assignment-icon" translate>Confirmed</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>