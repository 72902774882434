"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".context-menu-container{width:270px}.context-menu-row[_ngcontent-%COMP%]{display:flex;cursor:pointer;padding:0 20px;align-items:center}.context-menu-row[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354053}.context-menu-row[_ngcontent-%COMP%]   i[_ngcontent-%COMP%], .context-menu-row[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{font-size:20px;margin-right:15px}.context-menu-row.disabled[_ngcontent-%COMP%]{opacity:.4;cursor:not-allowed}.context-menu-title[_ngcontent-%COMP%]{padding:0 20px;height:74px;border-bottom:1px solid #dadada;display:flex;align-items:center;gap:15px}.context-menu-title[_ngcontent-%COMP%]   .green[_ngcontent-%COMP%]{color:#00c852}.context-menu-title[_ngcontent-%COMP%]   .red[_ngcontent-%COMP%]{color:#d0021b}.context-menu-title[_ngcontent-%COMP%]   .title-text[_ngcontent-%COMP%]{display:flex;flex-direction:column;font-size:14px;font-weight:600;color:#354053}.context-menu-title[_ngcontent-%COMP%]   .title-text[_ngcontent-%COMP%]   .shift-text[_ngcontent-%COMP%]{font-size:12px;color:#8a99ac}.context-menu-cursor[_ngcontent-%COMP%]{cursor:pointer}.show-error[_ngcontent-%COMP%]{color:#d0021b;font-size:16px}"];
exports.styles = styles;
