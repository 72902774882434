import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, remove, difference, get, find as _find, omit as _omit } from 'lodash';
import * as moment from 'moment';

import { PunchCardService } from  '../punch-cards/punch-card.service';
import { ExpenseService } from  './expense.service';
import { DriverService } from  '../drivers/driver.service';
import { TruckService } from  '../trucks/truck.service';
import { PunchCardFiltersDialogComponent } from '../punch-cards/punch-card-filters-dialog.component';

@Component({
  selector: 'ruckit-expense-punch-cards-dialog',
  templateUrl: './expense-punch-cards-dialog.component.html',
  styleUrls: ['./expense-punch-cards-dialog.component.scss'],
  providers: [
    PunchCardService, ExpenseService, DriverService, TruckService
  ]
})
export class ExpensePunchCardsDialogComponent implements OnInit {
  expense;
  count;
  selectedCount = 0;
  punchCards: any = [];
  allSelected = false;
  selectedPunchCards = [];
  excludePunchCards = [];
  punchCardFilters;
  loading = true;
  errors = [];
  routeToExpense = true;
  expenseReq;
  punchCardReq;
  search = '';
  sortBy = '';
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  startDate = null;
  endDate = null;
  callback;
  multipleActionDropdownOptions = [];
  menuOptions = [
    {name: 'Add to Expense', action: 'add', link: false}
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private punchCardService: PunchCardService,
    private expenseService: ExpenseService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExpensePunchCardsDialogComponent>
  ) { }

  ngOnInit() {
    this.getPunchCards();
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.punchCardService.getNextCondensed();
      if (o) {
        this.loading = true;
        o.subscribe(
          punchCards => {
            this.punchCards = this.punchCards.concat(punchCards);
            this.loading = false;
          },
          err => this.errors = err,
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getPunchCards(query = {}, append = false) {
    if (!append) {
      this.punchCards = [];
    }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return {...acc, ...filter.query};
    }, {});

    if (this.punchCardReq) {
      this.punchCardReq.unsubscribe();
    }
    if (this.startDate) {
      let date = new Date(this.startDate);
      query['start_time__gte'] = date.toISOString();
    }
    if (this.endDate) {
      let date = new Date(this.endDate);
      date.setHours(23, 59, 59, 999);
      query['start_time__lte'] = date.toISOString();
    }

    this.punchCardFilters = {
      ordering: order,
      search: this.search,
      unexpensed: 'True',
      completed: 'True',
      expenseable: 'True',
      exclude_cf: 'True',
      ...filters,
      ...query
    };

    this.punchCardReq = this.punchCardService.getCondensedPunchCards(this.punchCardFilters).subscribe(
      punchCards => {
        if (append) {
          this.punchCards = this.punchCards.concat(punchCards);
        } else {
          this.punchCards = punchCards;
        }
        this.count = this.punchCardService.count;
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getPunchCards({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getPunchCards({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.getPunchCards();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters() {
    const dialog = this.dialog.open(PunchCardFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);

    let startDate = get(_find(this.filters, {key: 'startDate'}), 'value');
    if (startDate) {
      dialog.componentInstance.model.startDate = <Date>startDate;
    }
    let endDate = get(_find(this.filters, {key: 'endDate'}), 'value');
    if (endDate) {
      dialog.componentInstance.model.endDate = <Date>endDate;
    }
    dialog.componentInstance.model.job = get(_find(this.filters, {key: 'job'}), 'value');
    dialog.componentInstance.model.project = get(_find(this.filters, {key: 'project'}), 'value');
    dialog.componentInstance.model.customer = get(_find(this.filters, {key: 'customer'}), 'value');
    dialog.componentInstance.model.driver = get(_find(this.filters, {key: 'driver'}), 'value');
    dialog.componentInstance.model.truck = get(_find(this.filters, {key: 'truck'}), 'value');

    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      payableTo: 'jobevent__owner_organization',
      job: 'jobevent__job',
      driver: 'driver',
      truck: 'truck',
      startDate: 'start_time__gte',
      endDate: 'start_time__lte',
      edited: 'edited',
      incomplete: 'completed',
      retake: 'retake_status'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
          let filterValue = value.toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (key === 'retake' && value) {
          value = 'requested';
          query[queryKeys[key]] = value;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let filter = {
        key: key,
        value: displayValue || value,
        query: query
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });

    this.filters = difference(this.filters, falseyFilters);
    this.getPunchCards();
  }

  removeFilter(filter) {
    remove(this.filters, filter);
    this.getPunchCards();
  }

  menuAction(name, punchCard) {
    switch (name) {
      case 'add':
        this.addToExpense([punchCard.id]);
        break;
    }
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }

  selector(event, punchCard = null) {
    if (punchCard) {
      if (!event.target.checked) {
        punchCard.selected = false;
        pull(this.selectedPunchCards, punchCard.id);
        if (this.allSelected) {
          this.excludePunchCards.push(punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedCount = this.selectedPunchCards.length;
        }
      } else {
        punchCard.selected = true;
        if (this.allSelected) {
          pull(this.excludePunchCards, punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedPunchCards.push(punchCard.id);
          this.selectedCount = this.selectedPunchCards.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.punchCards.forEach((_punchCard) => { _punchCard.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludePunchCards.length);
      }
      this.selectedPunchCards = [];
      this.excludePunchCards = [];
    }
  }

  addToExpense(punchCards = null) {
    let model = {};
    if (this.expense && this.expense.id) {
      Object.assign(model, {id: this.expense.id});
      if (punchCards || this.selectedPunchCards.length) {
        Object.assign(model, {punchCards: punchCards || this.selectedPunchCards});
      } else if (this.allSelected) {
        let params = new HttpParams();
        let filters = _omit(this.punchCardFilters, ['ordering', 'expense']);
        Object.keys(filters).map(key => {
          if (filters[key].length) {
            params = params.set(key, filters[key]);
          }
        });
        if (params.toString().length) {
          Object.assign(model, {
            filters: params.toString(), excludePunchCards: this.excludePunchCards
          });
        }
      }

      this.expenseReq = this.expenseService.addToExpense(model, 'punchcards').subscribe((res) => {
        this.expense = res;
        this.callback();
        this.dialogRef.close();
      }, err => console.error(err));
    } else {
      this.saveExpense();
    }
  }

  saveExpense() {
    let model = {
      customerOrganization: this.expense.customerOrganization.id,
      punchCards: this.selectedPunchCards
    };

    this.expenseReq = this.expenseService.save(model).subscribe((res) => {
      this.expense = res;
      this.dialogRef.close();
      if (this.routeToExpense) {
        this.router.navigate(['/expenses/' + this.expense.id + '/edit']);
      }
    }, err => console.error(err));
  }

  onDateChanged(values: Date[], type: string): void {
    if (values && values.length) {
      switch (type) {
        case 'startDate':
          this.startDate = values[0];
          break;
        case 'endDate':
          this.endDate = values[0];
          break;
      }
    }
    this.getPunchCards();
  }
}
