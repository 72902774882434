"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var surcharge_serializer_1 = require("../surcharges/surcharge.serializer");
var condensed_ticket_1 = require("./condensed-ticket");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CondensedTicketSerializer = /** @class */ (function () {
    function CondensedTicketSerializer() {
    }
    CondensedTicketSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var condensedTicket = new condensed_ticket_1.CondensedTicket();
        if (!json) {
            return condensedTicket;
        }
        json = camelcaseKeysDeep(json);
        condensedTicket.id = json.id;
        condensedTicket.createdAt = json.createdAt;
        condensedTicket.void = json.void;
        condensedTicket.voidReason = json.voidReason;
        condensedTicket.status = condensedTicket.void ? 'void' : json.status;
        switch (condensedTicket.status) {
            case 'in-progress': {
                condensedTicket.statusText = 'In Progress';
                break;
            }
            case 'complete': {
                condensedTicket.statusText = 'Finished';
                break;
            }
            case 'on-hold': {
                condensedTicket.statusText = 'On Hold';
                break;
            }
        }
        if (condensedTicket.void) {
            condensedTicket.statusLabel = 'label-void';
        }
        else {
            switch (condensedTicket.status) {
                case 'on-hold':
                    condensedTicket.statusLabel = 'label-pending';
                    break;
                case 'in-progress':
                    condensedTicket.statusLabel = 'label-upcoming';
                    break;
                case 'void':
                    condensedTicket.statusLabel = 'label-void';
                    break;
                default:
                    condensedTicket.statusLabel = 'label-info';
                    break;
            }
        }
        condensedTicket.number = json.number;
        condensedTicket.unitPrice = json.unitPrice;
        condensedTicket.originalUnitPrice = json.unitPrice;
        condensedTicket.customUnitPrice = json.customUnitPrice;
        condensedTicket.discountPercentage = json.discountPercentage;
        condensedTicket.notes = json.notes;
        condensedTicket.tareWeight = json.tareWeight;
        condensedTicket.tareWeightScale = json.tareWeightScale;
        condensedTicket.tareWeightScaleId = json.tareWeightScaleId;
        condensedTicket.storedTareWeightId = json.storedTareWeightId;
        condensedTicket.tareWeightUnit = json.tareWeightUnit;
        condensedTicket.tareWeightDatetime = json.tareWeightDatetime;
        condensedTicket.grossWeight = json.grossWeight;
        condensedTicket.grossWeightDatetime = json.grossWeightDatetime;
        condensedTicket.grossWeightScale = json.grossWeightScale;
        condensedTicket.grossWeightScaleId = json.grossWeightScaleId;
        condensedTicket.grossWeightUnit = json.grossWeightUnit;
        condensedTicket.correctionWeight = json.correctionWeight;
        condensedTicket.netWeightUnit = json.productUnitOfMeasurement || json.grossWeightUnit;
        condensedTicket.net = json.net;
        condensedTicket.netPrice = json.netPrice;
        condensedTicket.totalPrice = json.totalPrice;
        condensedTicket.totalTax = json.totalTax;
        condensedTicket.totalSurcharge = json.totalSurcharge;
        condensedTicket.paymentType = json.paymentType;
        condensedTicket.seller = json.seller;
        condensedTicket.sellerId = json.sellerId;
        condensedTicket.sellerOrganization = json.sellerId;
        condensedTicket.product = json.product;
        condensedTicket.productId = json.productId;
        condensedTicket.customer = json.customer;
        condensedTicket.customerId = json.customerId;
        condensedTicket.customerOrganization = json.customerId;
        condensedTicket.customerPaymentType = json.customerPaymentType;
        condensedTicket.driver = json.driver;
        condensedTicket.driverId = json.driverId;
        condensedTicket.driverImage = json.driverImage;
        condensedTicket.driverPhone = json.driverPhone;
        condensedTicket.driverName = json.driverName;
        condensedTicket.carrier = json.carrier;
        condensedTicket.carrierId = json.carrierId;
        condensedTicket.carrierOrganization = json.carrierId;
        condensedTicket.truck = json.truck;
        condensedTicket.truckId = json.truckId;
        condensedTicket.truckCarrierId = json.truckCarrierId;
        condensedTicket.truckImage = json.truckImage;
        condensedTicket.truckType = json.truckType;
        condensedTicket.truckLicensePlate = json.truckLicensePlate;
        var nameValues = [
            json.truckLicensePlate,
            json.truck
        ];
        condensedTicket.truckDisplayName = nameValues.filter(Boolean).join(' - ');
        condensedTicket.purchaseOrder = json.purchaseOrder;
        condensedTicket.purchaseOrderId = json.purchaseOrderId;
        condensedTicket.deliveryDatetime = json.deliveryDatetime;
        condensedTicket.completedDatetime = json.completedDatetime;
        condensedTicket.surcharges = json.surcharges && json.surcharges.map(function (surcharge) {
            if (surcharge && typeof (surcharge) === 'object') {
                return (new surcharge_serializer_1.SurchargeSerializer).fromJson(surcharge);
            }
            else {
                return (new surcharge_serializer_1.SurchargeSerializer).fromJson({ id: surcharge.id });
            }
        });
        return condensedTicket;
    };
    CondensedTicketSerializer.prototype.toJson = function (condensedTicket) {
        var json = {
            id: condensedTicket.id,
            createdAt: condensedTicket.createdAt,
            status: condensedTicket.status,
            statusText: condensedTicket.statusText,
            statusLabel: condensedTicket.statusLabel,
            number: condensedTicket.number,
            originalUnitPrice: condensedTicket.originalUnitPrice,
            customUnitPrice: condensedTicket.customUnitPrice,
            discountPercentage: condensedTicket.discountPercentage,
            notes: condensedTicket.notes,
            tareWeight: condensedTicket.tareWeight,
            tareWeightScale: condensedTicket.tareWeightScaleId,
            tareWeightScaleId: condensedTicket.tareWeightScaleId,
            storedTareWeightId: condensedTicket.storedTareWeightId,
            tareWeightUnit: condensedTicket.tareWeightUnit,
            grossWeight: condensedTicket.grossWeight,
            grossWeightScale: condensedTicket.grossWeightScaleId,
            grossWeightScaleId: condensedTicket.grossWeightScaleId,
            grossWeightUnit: condensedTicket.grossWeightUnit,
            correctionWeight: condensedTicket.correctionWeight,
            netWeightUnit: condensedTicket.netWeightUnit,
            net: condensedTicket.net,
            netPrice: condensedTicket.netPrice,
            totalPrice: condensedTicket.totalPrice,
            totalTax: condensedTicket.totalTax,
            totalSurcharge: condensedTicket.totalSurcharge,
            paymentType: condensedTicket.paymentType,
            void: condensedTicket.void,
            voidReason: condensedTicket.voidReason,
            seller: condensedTicket.seller,
            sellerId: condensedTicket.sellerId,
            sellerOrganization: condensedTicket.sellerId,
            product: condensedTicket.productId,
            productId: condensedTicket.productId,
            customer: condensedTicket.customer,
            customerId: condensedTicket.customerId,
            customerOrganization: condensedTicket.customerId,
            customerPaymentType: condensedTicket.customerPaymentType,
            driver: condensedTicket.driverId,
            driverName: condensedTicket.driverName,
            carrier: condensedTicket.carrier,
            carrierId: condensedTicket.carrierId,
            carrierOrganization: condensedTicket.carrierId,
            truck: condensedTicket.truckId,
            truckId: condensedTicket.truckId,
            truckCarrierId: condensedTicket.truckCarrierId,
            truckImage: condensedTicket.truckImage,
            truckType: condensedTicket.truckType,
            truckLicensePlate: condensedTicket.truckLicensePlate,
            truckDisplayName: condensedTicket.truckDisplayName,
            purchaseOrder: condensedTicket.purchaseOrderId,
            purchaseOrderId: condensedTicket.purchaseOrderId,
            deliveryDatetime: condensedTicket.deliveryDatetime,
            tareWeightDatetime: condensedTicket.tareWeightDatetime,
            grossWeightDatetime: condensedTicket.grossWeightDatetime,
            completedDatetime: condensedTicket.completedDatetime,
            selected: condensedTicket.selected,
            surcharges: condensedTicket.surcharges
        };
        return decamelizeKeysDeep(json);
    };
    return CondensedTicketSerializer;
}());
exports.CondensedTicketSerializer = CondensedTicketSerializer;
