"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var api_service_1 = require("../shared/api.service");
var punch_card_service_1 = require("./punch-card.service");
var job_service_1 = require("../jobs/job.service");
var organization_service_1 = require("../organizations/organization.service");
var job_event_service_1 = require("../job-events/job-event.service");
var driver_service_1 = require("../drivers/driver.service");
var truck_service_1 = require("../trucks/truck.service");
var job_event_1 = require("../job-events/job-event");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var core_2 = require("@ngx-translate/core");
var EditPunchCardComponent = /** @class */ (function () {
    function EditPunchCardComponent(route, router, dialog, punchCardService, jobService, jobEventService, organizationService, driverService, truckService, translateService) {
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.punchCardService = punchCardService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.organizationService = organizationService;
        this.driverService = driverService;
        this.truckService = truckService;
        this.translateService = translateService;
        this.jobDates = [];
        this.errors = [];
        this.jobEventError = null;
        this.loading = false;
        this.daysLoading = false;
        this.jobEventsLoading = false;
        this.jobDropdownOptions = [];
        this.jobDropdownConfig = {
            nameProperty: 'displayName',
            searchable: true,
            group: true,
            groupBy: function (job) { return job.group; },
            loadingOptions: false
        };
        this.driverDropdownOptions = [];
        this.driverDropdownConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.truckDropdownOptions = [];
        this.truckDropdownConfig = {
            nameProperty: 'displayName',
            searchable: true,
            loadingOptions: false
        };
        this.carrierDropdownConfig = {
            nameProperty: 'name',
            idProperty: 'carrier.id',
            selectText: this.translateService.instant('Select Carrier'),
            loadingText: this.translateService.instant('Loading Carriers...'),
            noResultsText: this.translateService.instant('No Carriers'),
            service: organization_service_1.OrganizationService,
            serviceFunction: 'get',
            query: {
                exclude: null,
                carriers_for_jobevent: null,
            },
            includeFullObject: true,
            prefilledOptions: [],
        };
        this.jobEventsDropdownOptions = [];
        this.jobEventsDropdownConfig = {
            nameProperty: 'name',
            searchable: true,
            multiple: true,
            loadingOptions: false
        };
        this.allSubscriptionsToUnsubscribe = [];
    }
    EditPunchCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.returnTo = result && result.qparams['returnTo'];
            if (result && result.params['id']) {
                _this.getPunchCard(result.params['id']);
            }
        }));
    };
    EditPunchCardComponent.prototype.ngOnDestroy = function () {
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
            this.jobsReq.unsubscribe();
        }
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.punchCardReq && typeof this.punchCardReq.unsubscribe === 'function') {
            this.punchCardReq.unsubscribe();
        }
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        if (this.trucksReq && typeof this.trucksReq.unsubscribe === 'function') {
            this.trucksReq.unsubscribe();
        }
        if (this.punchCardReq && typeof this.punchCardReq.unsubscribe() === 'function') {
            this.punchCardReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    EditPunchCardComponent.prototype.getPunchCard = function (id) {
        var _this = this;
        if (id) {
            if (this.punchCardReq && typeof this.punchCardReq.unsubscribe() === 'function') {
                this.punchCardReq.unsubscribe();
            }
            this.punchCardReq = this.punchCardService.getPunchCard(id).subscribe(function (punchCard) {
                _this.punchCard = punchCard;
                if (punchCard.ticketImage) {
                    _this.punchCardImage = __assign({}, _this.punchCardImage, { src: punchCard.ticketImage });
                }
                _this.jobEvent = new job_event_1.JobEvent({ id: _this.punchCard.jobEvent.id });
                _this.carrier = _this.punchCard.driver && _this.punchCard.driver.carrier;
                _this.carrierDropdownConfig.prefilledOptions.push(__assign({}, _this.punchCard.driver.carrier));
                _this.carrierDropdownConfig.query = __assign({}, _this.carrierDropdownConfig.query, { carriers_for_jobevent: punchCard.jobEvent.id });
                _this.getJob(_this.punchCard.jobEvent.job.id);
                _this.getJobs();
                _this.getTrucks();
                _this.getDrivers();
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    EditPunchCardComponent.prototype.submitPunchCard = function () {
        this.loading = true;
        if (this.punchCardImage && this.punchCardImage.name) {
            this.submitWithImage();
        }
        else {
            this.submitWithoutImage();
        }
    };
    EditPunchCardComponent.prototype.submitWithoutImage = function () {
        var _this = this;
        this.errors = [];
        this.punchCardService.save(this.punchCard).subscribe(function () {
            _this.loading = false;
            _this.router.navigate([_this.returnTo]);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.submitWithImage = function () {
        var _this = this;
        this.errors = [];
        this.punchCardService.saveWithFile(this.punchCard, this.punchCardImage).subscribe(function () {
            _this.loading = false;
            _this.router.navigate([_this.returnTo]);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.onStartDateChanged = function (dates) {
        this.punchCard.startDate = dates[0];
    };
    EditPunchCardComponent.prototype.onEndDateChanged = function (dates) {
        this.punchCard.endDate = dates[0];
    };
    EditPunchCardComponent.prototype.onStartTimeChange = function (value) {
        var time = value.target.value;
        this.punchCard.startTime = time;
    };
    EditPunchCardComponent.prototype.onEndTimeChange = function (value) {
        var time = value.target.value;
        this.punchCard.endTime = time;
    };
    EditPunchCardComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                this.job = e;
                this.getJobDays(this.job && this.job.id);
                this.getJobEvents({}, false, true);
                break;
            case 'jobevent':
                this.jobEvent = e;
                this.punchCard.jobEvent = e;
                var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: e.id });
                this.carriersDropdown.config.query = query;
                this.carriersDropdown.getRecords(query);
                break;
            case 'driver':
                this.driver = e;
                break;
            case 'truck':
                this.truck = e;
                break;
            case 'carrier':
                this.carrier = e;
                this.getDrivers();
                this.getTrucks();
                break;
        }
        this.punchCard[filterType] = e;
    };
    EditPunchCardComponent.prototype.getJob = function (id) {
        var _this = this;
        this.jobDropdownOptions = [];
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        this.jobReq = this.jobService.get(id).subscribe(function (job) {
            var _job = lodash_1.find(_this.jobDropdownOptions, { id: job && job.id });
            if (_job) {
                _this.jobDropdownOptions = lodash_1.reject(_this.jobDropdownOptions, _job);
            }
            _this.jobDropdownOptions.unshift(job);
            _this.job = job;
            _this.jobDropdown.selectedOption = _this.job;
            _this.getJobDays(_this.job && _this.job.id);
            _this.getJobEvent(_this.punchCard.jobEvent.id);
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.getJobs = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.jobDropdownOptions = [];
        this.jobDropdownConfig.loadingOptions = true;
        if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
            this.jobsReq.unsubscribe();
        }
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        this.jobsReq = this.jobService.list(__assign({ ordering: 'project__name,name,start_date', serializer: 'JobGroupedDropdown' }, query)).subscribe(function (jobs) {
            _this.jobDropdownOptions = _this.jobDropdownOptions.concat(jobs);
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
            _this.jobDropdownConfig.loadingOptions = false;
        });
    };
    EditPunchCardComponent.prototype.getJobEvent = function (id) {
        var _this = this;
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        this.jobEventReq = this.jobEventService.getJobEvent(id).subscribe(function (jobEvent) {
            var _jobEvent = lodash_1.find(_this.jobEventsDropdownOptions, { id: jobEvent && jobEvent.id });
            if (_jobEvent) {
                _this.jobEventsDropdownOptions = lodash_1.reject(_this.jobEventsDropdownOptions, _jobEvent);
            }
            _this.jobEventsDropdownOptions.unshift(jobEvent);
            _this.jobEvent = jobEvent;
            _this.jobEventsDropdown.selectedOption = _this.jobEvent;
            _this.getJobs();
            _this.getJobEvents();
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.getJobEvents = function (query, append, clearSelection) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = true; }
        if (clearSelection === void 0) { clearSelection = false; }
        this.jobEventsLoading = true;
        this.jobEventError = null;
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (clearSelection) {
            this.jobEventsDropdown.selectedOption = null;
            this.jobEvent = null;
            this.punchCard.jobEvent = null;
        }
        this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ job: this.job && this.job.id, ordering: '-shift1_start', page_size: 25 }, query)).subscribe(function (jobEvents) {
            if (jobEvents && jobEvents.length) {
                if (append) {
                    _this.jobEventsDropdownOptions = _this.jobEventsDropdownOptions.concat(jobEvents);
                }
                else {
                    _this.jobEventsDropdownOptions = jobEvents;
                }
            }
            else {
                _this.jobEventError = 'No Job Events found for this job!';
            }
            _this.jobEventsLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.jobEventsLoading = false;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.getJobDays = function (jobId) {
        var _this = this;
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        this.daysLoading = true;
        this.daysReq = this.jobService.getDays(jobId).subscribe(function (days) {
            _this.jobDates = days.map(function (day) { return moment(day).toDate(); });
            _this.daysLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.daysLoading = false;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.getDrivers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.driverDropdownOptions = [];
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        this.driversReq = this.driverService.list(__assign({ ordering: 'name', carrier: this.carrier && this.carrier.id }, query)).subscribe(function (drivers) {
            _this.driverDropdownOptions = drivers;
            _this.driver = lodash_1.find(_this.driverDropdownOptions, { id: _this.punchCard.driver.id });
            if (_this.driver) {
                _this.driverDropdownOptions = lodash_1.reject(_this.driverDropdownOptions, _this.driver);
                _this.driverDropdownOptions.unshift(_this.driver);
                if (_this.driverDropdown) {
                    _this.driverDropdown.selectedOption = _this.driver;
                }
            }
            else if (_this.punchCard.driver) {
                var _driver = { name: _this.punchCard.driver.name, id: _this.punchCard.driver.id };
                _this.driverDropdownOptions.unshift(_driver);
                if (_this.driverDropdown) {
                    _this.driverDropdown.selectedOption = _driver;
                }
            }
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.getTrucks = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.truckDropdownOptions = [];
        if (this.trucksReq && typeof this.trucksReq.unsubscribe === 'function') {
            this.trucksReq.unsubscribe();
        }
        this.trucksReq = this.truckService.list(__assign({ ordering: 'name', carrier: this.carrier && this.carrier.id, shared_jobevent: this.jobEvent && this.jobEvent.id }, query)).subscribe(function (trucks) {
            _this.truckDropdownOptions = trucks;
            _this.truck = lodash_1.find(_this.truckDropdownOptions, { id: _this.punchCard.truck.id });
            if (_this.truck) {
                _this.truckDropdownOptions = lodash_1.reject(_this.truckDropdownOptions, _this.truck);
                _this.truckDropdownOptions.unshift(_this.truck);
                if (_this.truckDropdown) {
                    _this.truckDropdown.selectedOption = _this.truck;
                }
            }
            else if (_this.punchCard.truck) {
                var _truck = { displayName: _this.punchCard.truck.displayName, id: _this.punchCard.truck.id };
                _this.truckDropdownOptions.unshift(_truck);
                if (_this.truckDropdown) {
                    _this.truckDropdown.selectedOption = _truck;
                }
            }
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'job':
                this.getJobs({ search: term });
                break;
            case 'jobEvents':
                this.getJobEvents({ search: term });
                break;
            case 'truck':
                this.getTrucks({ search: term });
                break;
            case 'driver':
                this.getDrivers({ search: term });
                break;
            case 'carrier':
                break;
        }
    };
    EditPunchCardComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config, service, options;
        switch (type) {
            case 'job':
                config = this.jobDropdownConfig;
                service = this.jobService;
                options = this.jobDropdownOptions;
                break;
            case 'jobEvents':
                config = this.jobEventsDropdownConfig;
                service = this.jobEventService;
                options = this.jobEventsDropdownOptions;
                break;
            case 'driver':
                config = this.driverDropdownConfig;
                service = this.driverService;
                options = this.driverDropdownOptions;
                break;
            case 'truck':
                config = this.truckDropdownConfig;
                service = this.truckService;
                options = this.truckDropdownOptions;
                break;
            case 'carrier':
                break;
        }
        if (!config.loadingOptions) {
            var o = void 0;
            if (type === 'driver' || type === 'truck' || type === 'job' || type === 'jobEvents') {
                o = service && service.listNext();
            }
            else {
                o = service && service.getNext();
            }
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'job':
                            _this.jobDropdownOptions = _this.jobDropdownOptions.concat(results);
                            break;
                        case 'jobEvents':
                            _this.jobEventsDropdownOptions = _this.jobEventsDropdownOptions.concat(results);
                            break;
                        case 'driver':
                            _this.driverDropdownOptions = _this.driverDropdownOptions.concat(results);
                            break;
                        case 'truck':
                            _this.truckDropdownOptions = _this.truckDropdownOptions.concat(results);
                            break;
                        case 'carrier':
                            break;
                        case 'jobEvents':
                            _this.jobEventsDropdownOptions = _this.jobEventsDropdownOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    EditPunchCardComponent.prototype.fileChange = function (e) {
        this.punchCardImage = e;
    };
    return EditPunchCardComponent;
}());
exports.EditPunchCardComponent = EditPunchCardComponent;
