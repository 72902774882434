"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var project_service_1 = require("../projects/project.service");
var ProjectListComponent = /** @class */ (function () {
    function ProjectListComponent(route, router, projectService) {
        this.route = route;
        this.router = router;
        this.projectService = projectService;
        this.projects = [];
        this.loading = true;
        this.errors = [];
        this.search = '';
        this.sortAsc = true;
        this.stringify = JSON.stringify;
    }
    ProjectListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.loading = true;
            _this.search = params['search'] || '';
            _this.sortBy = 'name';
            _this.projectService.list({ ordering: _this.sortBy }).subscribe(function (projects) {
                _this.projects = projects;
                _this.loading = false;
            }, function (err) {
                _this.errors = err;
            });
        });
    };
    ProjectListComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.projectService.listNext();
            if (o) {
                this.loading = true;
                o.subscribe(function (projects) {
                    _this.projects = _this.projects.concat(projects);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; });
            }
        }
    };
    ProjectListComponent.prototype.reload = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.projects = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.projectsReq) {
            this.projectsReq.unsubscribe();
        }
        this.projectsReq = this.projectService.list({
            ordering: order,
            search: this.search
        }).subscribe(function (projects) {
            if (append) {
                _this.projects = _this.projects.concat(projects);
            }
            else {
                _this.projects = projects;
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
        });
    };
    ProjectListComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.reload();
    };
    ProjectListComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    ProjectListComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.reload({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    ProjectListComponent.prototype.openAddProject = function () {
        this.router.navigate(['/projects/new']);
    };
    ProjectListComponent.prototype.openAddJob = function (project) {
        this.router.navigateByUrl('/projects/' + project.id + '/new-job');
    };
    return ProjectListComponent;
}());
exports.ProjectListComponent = ProjectListComponent;
