"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column}.app-content[_ngcontent-%COMP%]     .account-drivers.fancy-table-wrapper, .app-content[_ngcontent-%COMP%]     .container.drivers-container .fancy-table-wrapper, .app-content[_ngcontent-%COMP%]     .container.trucks-container .fancy-table-wrapper{height:calc(100vh - 70px - 30px - 130px)!important}.app-content[_ngcontent-%COMP%]     .grid-container{height:calc(100vh - 275px)!important}.sidenav-container[_ngcontent-%COMP%]{margin-top:-19px}.sidenav-container[_ngcontent-%COMP%]   fancy-table[_ngcontent-%COMP%]{margin-top:20px}action-bar[_ngcontent-%COMP%]{margin-bottom:20px}  .list-table{overflow:scroll;height:calc(100vh - 188px)}"];
exports.styles = styles;
