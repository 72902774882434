import { clone } from 'lodash';

export class Plant {
  id: string;
  createdAt: string;
  selected = false;
  loading: boolean;
  statusLabel: string;
  classes: string;

  url(action): string {
    return '/plant-manager/plants/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}

