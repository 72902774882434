<div class="app-content">
  <my-toolbar title="{{ 'Fleet Health' | titlecase | translate }}" (updatedMarkets)="updatedMarkets()"></my-toolbar>

  <div class="container fleet-health-container">
    <fleet-health-stats
      [appliedFilters]="appliedFilters"
      [searchQuery]="search"
      [query]="query"
      (filterChanges)="filterByEvent($event)">
    </fleet-health-stats>

    <!-- for opening edit driver dialog -->
    <ruckit-edit-driver
      #editDrawer
      [driver]="driver"
      (completed)="onEditComplete()"
    ></ruckit-edit-driver>
    <!-- using fancy table: reusable component -->
    <div class="actions-wrapper" >
      <fancy-table #driverTable [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(filters)]="appliedFilters" [(search)]="search"
                   [filtersDisplayed]="primaryFilters && primaryFilters.length > 0"
                   [config]="tableConfig"
                   [query]="query"
                   [customClasses]="customClasses"
                   (onDataLoaded)="onRecordsLoaded($event)"
                   (onClickAction)="clickAction($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" [matTooltip]="row.name" *ngSwitchCase="'name'" (contextmenu)="openContextMenu($event, row.id)">
              <div class="driver-photo">
                <span *ngIf="!row.image">{{row.name[0]}}</span>
                <img *ngIf="row.image" [src]="row.driverListImage" />
              </div>
              <div class="data driver-name"> {{ row.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'truck'">
              <div class="data">{{ row.truck && row.truck.displayName || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'phone-number'">
              <div class="data">{{ row.profile && row.profile.phoneNumber || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'billing-id'">
              <div class="data">{{ row.uniqueBillingId || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'carrier'">
              <div class="data"> {{ row.carrier.name || row.carrierName || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'markets'">
              <div class="data">
                <tag-display [tags]="row.tags" label="Markets"></tag-display>
              </div>
            </div>
            <div class="value duty-status" *ngSwitchCase="'duty-status'">
              <div class="data"> {{ (row.displayDutyStatus | translate) || '&nbsp;' }}</div>
            </div>
            <!-- shift status -->
            <div class="value shift-status" *ngSwitchCase="'shift-status'">
              <div *ngIf="row.loading">
                <my-loader [small]="true"></my-loader>
              </div>
              <ng-container *ngIf="!row.loading">
                <div class="data" *ngIf="!row.lastShiftId"> {{ (row.displayShiftStatus | translate) || '&nbsp;' }}</div>
                <div class="data" *ngIf="row.lastShiftId">
                  <a [routerLink]="['/shifts']" routerLinkActive="active" [queryParams]="{search: row.lastShiftId}">
                    {{ (row.displayShiftStatus | translate) || '&nbsp;' }}
                  </a>
                </div>
              </ng-container>
            </div>
            <!-- shift status end -->
            <div class="value" *ngSwitchCase="'deleted'">
              <div class="data">
                <span class="value">{{ row.lastModified | moment: 'M-D-YYYY' }}</span>
                <div class="label">{{ row.lastModifiedBy }}</div>
              </div>
            </div>
            <div class="value device-status" *ngSwitchCase="'device-status'">
              <div class="data">
                <div *ngIf="!row.lastDeviceStatusEvent">
                  <div class="no-status" translate>
                    <i class="icon-info"></i> No Device Status
                  </div>
                </div>
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="warning" translate *ngIf="row.deviceErrors.length > 0"  matTooltip="{{row.deviceErrors.join('\n')}}" matTooltipClass="device-errors-tooltip">
                    <i class="icon-error"></i> {{ row.deviceErrors.length }} Issues
                  </div>
                  <div class="no-issues" translate *ngIf="!row.deviceErrors.length">
                    No Issues
                  </div>
                </div>
              </div>
            </div>
            <div class="value location-authorization" *ngSwitchCase="'location-authorization'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element warning" *ngIf="row.lastDeviceStatusEvent.locationAuthorization !== 'Always'">
                    <div class="not-always" translate>Not Always</div>
                  </div>
                  <div class="device-status-element" *ngIf="row.lastDeviceStatusEvent.locationAuthorization === 'Always'">
                    <div translate>Always</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value low-power-mode" *ngSwitchCase="'low-power-mode'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element warning" *ngIf="row.lastDeviceStatusEvent.lowPowerMode">
                    <div class="low-power-enabled" translate>Enabled</div>
                  </div>
                  <div class="device-status-element" *ngIf="!row.lastDeviceStatusEvent.lowPowerMode">
                    <div translate>Disabled</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value device-storage" *ngSwitchCase="'device-storage'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element warning" *ngIf="row.lastDeviceStatusEvent.lowDeviceStorage">
                    <div class="low-storage" translate>Low Storage</div>
                  </div>
                  <div class="device-status-element" *ngIf="!row.lastDeviceStatusEvent.lowDeviceStorage">
                    <div translate>Adequate Storage</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value background-app-refresh" *ngSwitchCase="'background-app-refresh'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element" *ngIf="row.lastDeviceStatusEvent.backgroundAppRefresh" translate>
                    <div translate>Enabled</div>
                  </div>
                  <div class="device-status-element warning" *ngIf="!row.lastDeviceStatusEvent.backgroundAppRefresh">
                    <div class="refresh-disabled" translate>Disabled</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value app-version" *ngSwitchCase="'app-version'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element">
                    <span>{{ row.lastDeviceStatusEvent.data && row.lastDeviceStatusEvent.data['applicationVersion'] || '&nbsp;' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="value device-os" *ngSwitchCase="'device-os'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element">
                    <ng-container *ngIf="row.lastDeviceStatusEvent.data && row.lastDeviceStatusEvent.data['deviceOs']">
                      <span *ngIf="row.lastDeviceStatusEvent.data['deviceOs'].toLowerCase().includes('ios') || row.lastDeviceStatusEvent.data['deviceOs'].toLowerCase().includes('android')">
                        {{ row.lastDeviceStatusEvent.data['deviceOs'] }}
                      </span>
                      <span *ngIf="!row.lastDeviceStatusEvent.data['deviceOs'].toLowerCase().includes('android') && !row.lastDeviceStatusEvent.data['deviceOs'].toLowerCase().includes('ios')">
                        Android {{ row.lastDeviceStatusEvent.data['deviceOs'] }}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="!row.lastDeviceStatusEvent.data || !row.lastDeviceStatusEvent.data['deviceOs']">
                      <span>&nbsp;</span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="value last-communication" *ngSwitchCase="'last-communication'">
              <div class="data">
                <div *ngIf="row.lastDeviceStatusEvent">
                  <div class="device-status-element">
                    <span>{{ (row.lastDeviceStatusEvent.createdAt | date: 'MM/dd/yyyy h:mm a') || '&nbsp;' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="value last-location-update" *ngSwitchCase="'last-location-update'">
              <div class="data">
                <div *ngIf="row.lastLocationUpdateLoading">
                  <my-loader [small]="true"></my-loader>
                </div>
                <a *ngIf="row.lastLocationUpdate && !row.lastLocationUpdateLoading" 
                  [routerLink]="['/replay']"
                  [queryParams]="{ date: row.lastLocationUpdate.createdAt | date: 'yyyyMMdd', driver: row.id}"
                >
                  <span *ngIf="(row.activeShifts && row.activeShifts.length > 0) && row.lastLocationUpdate.moreThanThirtyMinutesAgo; else show_timestamp">
                    {{row.lastLocationUpdate.createdAt | getDurationString}}
                  </span>
                  <ng-template #show_timestamp>
                    <span translate>Updated </span>
                    {{ row.lastLocationUpdate.createdAt | date: 'MM/dd/yyyy h:mm a' }}
                  </ng-template>
                </a>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'action'">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of row.filterOptions(tableConfig.menuOptions)">
                  <a *ngIf="option.link" mat-menu-item
                     [routerLink]="row.url(option.action)"
                     [queryParams]="{ returnTo: '/drivers' }">
                    {{ option.name }}
                  </a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="carrier-dropdown-container">
            <dropdown
              #carriersDropdown
              *ngIf="carrierDropdownData && carrierDropdownData.carriers"
              [options]="carrierDropdownData.carriers"
              [config]="carrierDropdownData.config"
              title="{{ 'All Carriers' | translate }}"
              [selectedOption]="carrierDropdownData.carrier"
              (onSelect)="saveFilterPreference({ key: 'carrier', value: $event.id })"
              (onSearch)="dropdownSearch($event)"
              (nextPage)="dropdownNextPage()">
            </dropdown>
          </div>
          <div class="segmented-buttons">
            <a (click)="filterByIssues()" class="btn btn-default" [ngClass]="{active: issuesSelected}" translate>
              Issues
            </a>
            <a (click)="filterByShifts()" class="btn btn-default" [ngClass]="{active: onShiftSelected}" translate>
              On Shift Drivers
            </a>
          </div>
        </div>
        <div applied-filter-content>
          <applied-filters
            [(filters)]="appliedFilters"
            [primaryFilters]="primaryFilters"
            [(search)]="search"
            (filtersChange)="appliedFilters=$event; deleteAllPrimaryFilters()"
            (primaryFiltersChange)="saveFilterPreference($event)">
          </applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [displayedColumns]="displayedColumns"
                       (displayedColumnsChange)="columnsChanged($event)">
                      </column-toggle>
        <filters-panel [(appliedFilters)]="appliedFilters"
                       [customFilter]="true"
                       (openCustomFilter)="openFilters()"
                       [search]="search" (searchChange)="search = $event"></filters-panel>
      </div>
    </div>
  </div>
  <!-- fancy table :ends here -->
  <!-- driver context menu -->
  <app-driver-context-menu 
    [contextMenuEventSubject]="contextMenuEventSubject" 
    (onShiftEnd)="onShiftEnd()">
  </app-driver-context-menu>
</div>