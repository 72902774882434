"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".collaborators-widget[_ngcontent-%COMP%]{position:absolute;top:280px;left:10px;width:340px;height:200px;padding:15px 20px;border-radius:4px;background-color:#fff;border:1px solid #dfe2e5}.collaborators-widget[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 0 20px;font-size:18px;color:#354052}.collaborators-widget[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]   a.collaborators-link[_ngcontent-%COMP%]{text-decoration:none;color:inherit}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]{display:flex;padding-bottom:10px;margin-bottom:10px;border-bottom:1px solid rgba(230,234,238,.5)}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .column[_ngcontent-%COMP%]{flex:1 1 200px;align-self:center}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .column[_ngcontent-%COMP%]:last-child{width:60px}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .column[_ngcontent-%COMP%]   .name[_ngcontent-%COMP%]{font-size:14px;color:#354052}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .column[_ngcontent-%COMP%]   .dispatched[_ngcontent-%COMP%]{font-size:12px;color:#7f8fa4}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .column[_ngcontent-%COMP%]   .rate[_ngcontent-%COMP%]{font-size:10px;text-align:right;color:#02963f}.collaborators-widget[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .column[_ngcontent-%COMP%]   .no-rate[_ngcontent-%COMP%]{font-size:10px;font-weight:600;text-align:right;color:#d0021b}.collaborators-widget[_ngcontent-%COMP%]   .view-more[_ngcontent-%COMP%]{display:block;position:absolute;left:0;bottom:0;width:100%;height:20px;border-radius:0 0 4px 4px;background-color:#f0f3f8;text-decoration:none;border-top:1px solid #dfe2e5;font-size:12px;text-align:center;color:#015bc5}"];
exports.styles = styles;
