<div class="app-content">
  <form #editInvoice="ngForm" class="invoice-form">
    <my-toolbar [title]="invoice && invoice.title"></my-toolbar>

    <action-bar>
      <div class="left-actions"></div>
      <div class="right-actions">
        <button id="save-invoice-button" class="btn btn-default" (click)="save(); false" *ngIf="invoice && invoice.draft" [disabled]="loading || !editInvoice.form.valid" [ngClass]="{loading:loading}" translate>Save</button>
        <button id="send-invoice-button" class="btn btn-primary" (click)="submit(); false" *ngIf="invoice && invoice.draft" [disabled]="loading || !editInvoice.form.valid || !invoice.sendable" [ngClass]="{loading:loading}" translate>Send Invoice</button>
      </div>
    </action-bar>

    <div class="invoice-container" *ngIf="invoice">
      <div class="invoice-content">
          <div class="meta-data" translate>
            DRAFT &mdash; CREATED {{ invoice.createdAt | date:'shortDate' }}

            <notification *ngFor="let error of errors">{{ error }}</notification>
          </div>
          <div class="billing">
            <div class="billing-left">
              <div>
                <div class="title" translate>INVOICE</div>
                <div class="field-group invoice-number">
                  <label translate>Invoice #</label>
                  <input type="text" name="invoice-number" required [(ngModel)]="invoice.number" #number="ngModel" (blur)="save(); false" />
                </div>

                <div class="field-group invoice-date">
                  <label translate>Date</label>
                  <date-range-picker [config]="{decreaseControl: false, increaseControl: false}"
                    [selectedDates]="[invoiceDate]" (dateChanged)="onDateChanged($event)"></date-range-picker>
                </div>
              </div>

              <div class="sender-address">
                <div class="edit-action" (click)="editing = !editing"><i class="icon-pencil"></i></div>
                <h2>Sender</h2>
                <div class="field-group">
                  <label>{{ invoice.ownerOrganization && invoice.ownerOrganization.name }}</label>
                  <textarea required [(ngModel)]="invoice.billFromAddress" name="bill_from_address" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
                </div>
                <div class="field-group">
                  <input type="email" required class="light" placeholder="{{ 'Email' | translate }}" [(ngModel)]="invoice.billFromContactEmail" name="bill_from_contact_email" (blur)="save(); false" [ngClass]="{editing: editing}" />
                </div>
                <div class="field-group">
                  <input type="text" class="light" placeholder="{{ 'CC Email' | translate }}" [(ngModel)]="invoice.billFromCcEmails" name="bill_from_cc_emails" (blur)="save(); false" [ngClass]="{editing: editing}" />
                </div>
              </div>
            </div>

            <div class="billing-right">
              <div class="logo-image upload-image-dropzone" [ngClass]="{filled: invoice.logo || invoice.ownerOrganization.image}">
                <img *ngIf='invoice.logo || invoice.ownerOrganization.image' [src]="invoice.logo || invoice.ownerOrganization.image" />

                <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
                <label for="file">
                  <i class="icon-upload"></i>
                  <span *ngIf="!invoice.logo && !invoice.ownerOrganization.image" translate>Click to upload a logo</span>
                  <span *ngIf="invoice.logo || invoice.ownerOrganization.image" translate>Upload a new logo image</span>
                </label>
              </div>

              <div class="receipient-address">
                <div class="edit-action" (click)="editing = !editing"><i class="icon-pencil"></i></div>
                <h2>Recipient</h2>
                <div class="field-group">
                  <label>{{ invoice.customerOrganization && invoice.customerOrganization.name }}</label>
                  <textarea required [(ngModel)]="invoice.billToAddress" name="bill_to_address" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
                </div>
                <div class="field-group">
                  <input type="email" required class="light" placeholder="{{ 'Email' | translate }}" [(ngModel)]="invoice.billToContactEmail" name="bill_to_contact_email" (blur)="save(); false" [ngClass]="{editing: editing}" />
                </div>
                <div class="field-group">
                  <input type="text" class="light" placeholder="{{ 'CC Email' | translate }}" [(ngModel)]="invoice.billToCcEmails" name="bill_to_cc_emails" (blur)="save(); false" [ngClass]="{editing: editing}" />
                </div>
              </div>
            </div>
          </div>

          <div class="title-container">
            <div class="title" translate>
              Invoice Items - {{ invoice.job && invoice.job.name || invoice.customerOrganization && invoice.customerOrganization.name }}
            </div>
            <div class="search-container {{ loading ? 'loading' : '' }}">
              <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
            </div>
          </div>

          <div class="invoice-trips">
            <ruckit-sent-trips [search]="search" [invoice]="invoice" (updateInvoice)="updateInvoice($event)"></ruckit-sent-trips>
          </div>

          <div class="invoice-punch-cards">
            <ruckit-sent-punch-cards [search]="search" [invoice]="invoice" (updateInvoice)="updateInvoice($event)"></ruckit-sent-punch-cards>
          </div>

          <div class="invoice-surcharges">
            <ruckit-sent-surcharges [search]="search" [invoice]="invoice" (updateInvoice)="updateInvoice($event)"></ruckit-sent-surcharges>
          </div>

          <div class="horizontal-rule"></div>

          <div class="total-summary-container">
            <div class="total-summary">
              <div class="row tickets-sub-total">
                <label translate>Tickets Subtotal</label>
                <div class="value">{{ (invoice.subtotal | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              </div>
              <div class="row surcharges">
                <label translate>Surcharges</label>
                <div class="value">{{ (invoice.surchargeAmount | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              </div>
              <div class="row invoice-total">
                <label translate>Invoice Total</label>
                <div class="value">{{ (invoice.amount | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
              </div>
            </div>

            <div class="expense-summary">
              <div class="row expense-total">
                <label translate>Expense Total</label>
                <div class="value">{{ (invoice.expenseAmount | currency : 'USD' : 'symbol') }}</div>
              </div>
            </div>
          </div>

          <div class="remittance-info">
            <div class="field-group">
              <label translate>Remittance Info</label>
              <textarea [(ngModel)]="invoice.remittanceInfo" name="remittance_info" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
            </div>
          </div>
        </div>
    </div>
  </form>
</div>
