"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{padding:30px;position:relative}  .mat-tab-header{background:#fff;border-radius:4px 4px 0 0;border:1px solid #e6e9ec}.new-surcharge-template[_ngcontent-%COMP%]{font-weight:600;font-size:15px;color:#58b4f9;cursor:pointer}"];
exports.styles = styles;
