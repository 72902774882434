import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { LocationsRoutingModule } from './locations-routing.module';
import { LocationsComponent } from './locations.component';
import { NewLocationComponent } from './new-location.component';
import { EditLocationComponent } from './edit-location.component';
import { LocationManagerComponent } from './location-manager/location-manager.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LocationsRoutingModule
  ],
  declarations: [
    LocationsComponent, NewLocationComponent, EditLocationComponent,
    LocationManagerComponent
  ]
})
export class LocationsModule {
  constructor(translateService: TranslateService) {
    let browserLang = translateService.getBrowserLang();
    translateService.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');
  }
}
