import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { parseErrors } from '../shared/api.service';
import { CondensedTicket } from './condensed-ticket';
import { CondensedTicketService } from './condensed-ticket.service';

@Component({
  selector: 'incomplete-tickets',
  templateUrl: './incomplete-tickets.component.html',
  styleUrls: ['./incomplete-tickets.component.scss'],
  providers: [CondensedTicketService]
})
export class IncompleteTicketsComponent implements OnInit {
  loading = false;
  errors = [];
  tickets: any = [];
  selectedTicket;
  ticketsReq;
  sortBy = 'delivery_time';
  sortAsc = true;
  sortParameter;

  @Output() onTicketSelected = new EventEmitter<CondensedTicket>();

  constructor(
    private condensedTicketService: CondensedTicketService
  ) {}

  ngOnInit() {
    this.getTickets();
  }

  getTickets(query = {}) {
    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    if (this.ticketsReq) { this.ticketsReq.unsubscribe(); }

    this.ticketsReq = this.condensedTicketService.list({
      ordering: order,
      ticket_status: 'in-progress,on-hold',
      ...query
    }).subscribe(tickets => {
      this.tickets = tickets;
      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
  }

  highlightTicket(ticket: CondensedTicket, refresh = true) {
    if (ticket) {
      if (refresh) { this.getTickets(); }
      this.selectedTicket = ticket;
      this.scrollToTicket('ticket-' + ticket.number);
    }
  }

  selectTicket(ticket: CondensedTicket) {
    this.tickets.forEach(_ticket => {
      _ticket.selected = false;
    });
    this.onTicketSelected.emit(ticket);
    ticket.selected = true;
  }

  scrollToTicket(element) {
    let _element = window.document.getElementById(element.toString());
    if (_element) {
      _element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
    }
  }

  private ticketPresentWithLicenceNumberAndDriverName(licensePlate: string, driverName: string) {
    return this.tickets.filter(
      ticket => ticket.truck === licensePlate && ticket.driver === driverName
    )[0];
  }

  getTicketWithDriver(licencePlateNumber: string, driverName: string): Observable<CondensedTicket> {
    return new Observable(observer => {
      let ticketFound: CondensedTicket;
      ticketFound = this.ticketPresentWithLicenceNumberAndDriverName(licencePlateNumber, driverName);
      // if (!ticketFound) {
      //   ticketFound = this.ticketPresentWithLicenceNumber(licencePlateNumber);
      // }
      observer.next(ticketFound);
      observer.complete();
    });
  }
}
