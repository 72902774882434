"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./overall-job-production.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./job-event-stat.service");
var i4 = require("@angular/common/http");
var i5 = require("./overall-job-production.component");
var styles_OverallJobProductionComponent = [i0.styles];
var RenderType_OverallJobProductionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverallJobProductionComponent, data: {} });
exports.RenderType_OverallJobProductionComponent = RenderType_OverallJobProductionComponent;
function View_OverallJobProductionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " loads"])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 1), (_co.job.stats.totalLoads || 0), 0)); _ck(_v, 0, 0, currVal_0); }); }
function View_OverallJobProductionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " load"])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 1), (_co.job.stats.totalLoads || 0), 0)); _ck(_v, 0, 0, currVal_0); }); }
function View_OverallJobProductionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " loads"])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 1), (_co.job.stats.totalLoads || 0), 0)); _ck(_v, 0, 0, currVal_0); }); }
function View_OverallJobProductionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "total-loads"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgPluralCase, [[8, "=0"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job.stats.totalLoads; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OverallJobProductionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 1), (_co.job.stats.totalTons || 0), 0)); var currVal_1 = _co.job.measurementUnit(true); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " ", ""])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_co.job.stats.totalTons || 0), 0)); var currVal_1 = _co.job.measurementUnit(true); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " ", ""])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_co.job.stats.totalTons || 0), 0)); var currVal_1 = _co.job.measurementUnit(false); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " ", ""])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_co.job.stats.totalTons || 0), 0)); var currVal_1 = _co.job.measurementUnit(true); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "total-tons value"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_9)), i1.ɵdid(3, 16384, null, 0, i2.NgPluralCase, [[8, "=0"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_10)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_11)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job.stats.totalTons; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OverallJobProductionComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "measurement-unit value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["0 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job.measurementUnit(true); _ck(_v, 1, 0, currVal_0); }); }
function View_OverallJobProductionComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " ", ""])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_co.job.stats.totalOrdered || 0), 0)); var currVal_1 = _co.job.measurementUnit(true); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " ", ""])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_co.job.stats.totalOrdered || 0), 0)); var currVal_1 = _co.job.measurementUnit(false); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " ", ""])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_co.job.stats.totalOrdered || 0), 0)); var currVal_1 = _co.job.measurementUnit(true); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OverallJobProductionComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [["class", "total-tons value"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_14)), i1.ɵdid(3, 16384, null, 0, i2.NgPluralCase, [[8, "=0"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_15)), i1.ɵdid(5, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_16)), i1.ɵdid(7, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job.stats.totalOrdered; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OverallJobProductionComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "measurement-unit value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["0 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job.measurementUnit(true); _ck(_v, 1, 0, currVal_0); }); }
function View_OverallJobProductionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "production-stats"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "job-tons-delivered"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_12)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delivered"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "job-tons-ordered"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_13)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_17)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ordered"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.job.stats.totalTons; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.job.stats.totalTons; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.job.stats.totalOrdered; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.job.stats.totalOrdered; _ck(_v, 12, 0, currVal_3); }, null); }
function View_OverallJobProductionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "job-production"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Overall Job Production"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "job-amount"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 3), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "job-loads"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_6)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_7)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.job.haulType === "load"); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.job.stats.totalTons; _ck(_v, 10, 0, currVal_2); var currVal_3 = ((_co.job.haulType === "weight") || (_co.job.invoiceType === "weight")); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), (_co.job.stats.totalAmount || 0), "USD", "symbol")); _ck(_v, 4, 0, currVal_0); }); }
function View_OverallJobProductionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverallJobProductionComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.job && _co.job.stats); _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_OverallJobProductionComponent_0 = View_OverallJobProductionComponent_0;
function View_OverallJobProductionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "overall-job-production", [], null, null, null, View_OverallJobProductionComponent_0, RenderType_OverallJobProductionComponent)), i1.ɵprd(512, null, i3.JobEventStatService, i3.JobEventStatService, [i4.HttpClient]), i1.ɵdid(2, 114688, null, 0, i5.OverallJobProductionComponent, [i3.JobEventStatService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_OverallJobProductionComponent_Host_0 = View_OverallJobProductionComponent_Host_0;
var OverallJobProductionComponentNgFactory = i1.ɵccf("overall-job-production", i5.OverallJobProductionComponent, View_OverallJobProductionComponent_Host_0, { job: "job" }, {}, []);
exports.OverallJobProductionComponentNgFactory = OverallJobProductionComponentNgFactory;
