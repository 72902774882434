"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Carrier = /** @class */ (function () {
    function Carrier(carrierInfo) {
        carrierInfo = camelcaseKeysDeep(carrierInfo);
        this.id = carrierInfo.id;
        this.name = carrierInfo.name;
        this.dotNumber = carrierInfo.dotNumber;
        this.leasedOrg = carrierInfo.leasedOrg;
        this.uniqueBillingId = carrierInfo.uniqueBillingId;
    }
    return Carrier;
}());
exports.Carrier = Carrier;
