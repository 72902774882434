"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column;position:relative}.app-content[_ngcontent-%COMP%]   .action[_ngcontent-%COMP%]{display:flex;justify-content:space-between}.app-content[_ngcontent-%COMP%]   .action[_ngcontent-%COMP%]   .left-action[_ngcontent-%COMP%], .app-content[_ngcontent-%COMP%]   .action[_ngcontent-%COMP%]   .right-action[_ngcontent-%COMP%]{display:flex}.app-content[_ngcontent-%COMP%]   .action[_ngcontent-%COMP%]   .right-action[_ngcontent-%COMP%]   .account-action[_ngcontent-%COMP%]{padding-right:20px}.container[_ngcontent-%COMP%]{padding:30px;position:relative}.container.header-visible[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100VH - 200px)}.value[_ngcontent-%COMP%]{display:flex;align-items:center}"];
exports.styles = styles;
