"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Widget = /** @class */ (function () {
    function Widget(widgetInfo) {
        this.id = widgetInfo.id;
        this.size = widgetInfo.size || [1, 1];
    }
    return Widget;
}());
exports.Widget = Widget;
