import { Component, OnInit, Input } from '@angular/core';
import { Organization } from '../organizations/organization';
import { CustomFieldService } from './custom-field.service';
import { CustomField, CustomFieldKind } from './custom-field';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'ruckit-edit-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
  providers: [CustomFieldService],
})
export class CustomFieldsComponent implements OnInit {
  @Input() organization: Organization;
  fields: CustomField[];
  loading = false;

  constructor(private customFieldService: CustomFieldService) { }

  ngOnInit() {
    this.getFields();
  }

  addField() {
    const field = new CustomField();
    const uuid =  UUID.UUID().replace(/-/g, '');

    field.ownerOrganization = this.organization.id;
    field.key = uuid;
    field.kind = CustomFieldKind.Truck;

    this.fields.push(field);
  }

  getFields() {
    this.customFieldService.list({active: true}).subscribe(fields => {
      this.fields = fields;
    });
  }

  onDelete(index: number) {
    const field = this.fields[index];

    if (!field || !field.displayName || field.displayName === '' || !field.id || !field.kind) {
      this.fields.splice(index, 1);

      return;
    }

    field.active = !field.active;

    this.onSubmit();
  }

  onSubmit() {
    this.loading = true;

    this.customFieldService.updateFields(this.fields).subscribe(fields => {
      this.loading = false;

      this.getFields();
    });
  }

  onScroll() {
    this.customFieldService.listNext(null, {active: true}).subscribe(fields => {
      this.fields = this.fields.concat(fields);
    });
  }
}
