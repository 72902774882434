"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var material_1 = require("@angular/material");
var api_service_1 = require("../shared/api.service");
var shared_1 = require("../scaleit/shared");
var ticket_form_component_1 = require("./ticket-form.component");
var incomplete_tickets_component_1 = require("./incomplete-tickets.component");
var shared_2 = require("../shared");
var shared_3 = require("../shared");
var truck_service_1 = require("../trucks/truck.service");
var scales_component_1 = require("../scales/scales.component");
var condensed_ticket_serializer_1 = require("./condensed-ticket.serializer");
var condensed_ticket_service_1 = require("./condensed-ticket.service");
var CreateTicketComponent = /** @class */ (function () {
    function CreateTicketComponent(route, router, truckService, condensedTicketService, dialog) {
        this.route = route;
        this.router = router;
        this.truckService = truckService;
        this.condensedTicketService = condensedTicketService;
        this.dialog = dialog;
        this.showTicketsPane = false;
        this.showScalesPane = false;
        this.errors = [];
        this.notificationLevel = 'info'; // Can be danger, warn, success, info
        this.notificationMessage = '';
        this.notificationTimeout = 3000;
        this.notificationHidden = true;
        this.licensePlateNumber = null;
        this.minLicensePlateLength = 1;
        this.searchStatusToSearch = true;
        this.searchStatusSearching = false;
        this.searchStatusNotFound = false;
        this.selectedTruck = null;
        this.truckOptions = [];
        this.truckDropdownConfig = {
            open: false,
            searchable: false,
            loadingOptions: false
        };
        this.saveTicketObservableSubscription = null;
        this.tickets = [];
        this.ticketsLoading = false;
    }
    CreateTicketComponent.prototype.ngOnInit = function () {
        if (this.notification) {
            this.notification.open = false;
        }
    };
    // This handles browser navigation away from this page, when unsaved changes exist
    CreateTicketComponent.prototype.handleOnBeforeUnload = function ($event) {
        if (this.ticketForm &&
            this.ticketForm.hasUnsavedChanges &&
            this.ticketForm.hasUnsavedChanges()) {
            // For IE and Firefox prior to version 4
            if ($event) {
                $event.returnValue = 'You have unsaved changes!';
            }
            // For Safari
            return 'You have unsaved changes!';
        }
    };
    CreateTicketComponent.prototype.toggle = function (accordion) {
        if (accordion === void 0) { accordion = 'tickets'; }
        if (accordion === 'tickets') {
            this.showTicketsPane = !this.showTicketsPane;
        }
        else if (accordion === 'scales') {
            this.showScalesPane = !this.showScalesPane;
        }
    };
    CreateTicketComponent.prototype.handleTicketFormNotification = function (notificationInfo) {
        this.showNotification(notificationInfo.message, notificationInfo.level, notificationInfo.timeout);
    };
    CreateTicketComponent.prototype.highlightTicket = function (event) {
        this.refreshTickets();
        if (this.incompleteTicketsComponent.tickets.length) {
            this.incompleteTicketsComponent.highlightTicket(this.ticketForm.currentTicket);
        }
        if (event === true) { // when onTicketFinished event emitter emits true
            this.selectedTruck = null;
            this.licensePlateNumber = null;
            this.incompleteTicketsComponent.selectedTicket = null;
        }
    };
    CreateTicketComponent.prototype.handleTicketVoided = function () {
        this.selectedTruck = null;
        this.licensePlateNumber = null;
        this.refreshTickets();
    };
    CreateTicketComponent.prototype.refreshTickets = function () {
        this.incompleteTicketsComponent.getTickets();
    };
    CreateTicketComponent.prototype.setSearchStatusToSearch = function () {
        this.searchStatusToSearch = true;
        this.searchStatusSearching = false;
        this.searchStatusNotFound = false;
    };
    CreateTicketComponent.prototype.setSearchStatusSearching = function () {
        this.searchStatusToSearch = false;
        this.searchStatusSearching = true;
        this.searchStatusNotFound = false;
    };
    CreateTicketComponent.prototype.setSearchStatusNotFound = function () {
        this.searchStatusToSearch = false;
        this.searchStatusSearching = false;
        this.searchStatusNotFound = true;
    };
    CreateTicketComponent.prototype.selectTruck = function (truck) {
        this.selectedTruck = truck;
        this.licensePlateNumber = truck.licensePlate;
        this.getTickets({
            truck: this.selectedTruck && this.selectedTruck.id,
            ticket_status: 'in-progress,on-hold'
        }, true);
    };
    CreateTicketComponent.prototype.getTickets = function (query, selectFirst) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (selectFirst === void 0) { selectFirst = false; }
        this.ticketsLoading = true;
        if (this.ticketsReq) {
            this.ticketsReq.unsubscribe();
        }
        this.ticketsReq = this.condensedTicketService.list(__assign({}, query)).subscribe(function (tickets) {
            _this.tickets = tickets;
            if (selectFirst && tickets.length) {
                _this.selectedTicket = tickets[0];
                _this.ticketForm.autoFillFormWithTicket(_this.selectedTicket);
                if (_this.selectedTicket.statusText === 'On Hold') {
                    _this.selectedTicket.onHold = true;
                    _this.ticketForm.currentTicket.onHold = true;
                }
            }
            else if (selectFirst) {
                if (_this.selectedTruck) {
                    _this.selectedTicket = new condensed_ticket_serializer_1.CondensedTicketSerializer().fromJson({
                        truckId: _this.selectedTruck.id,
                        truck: _this.selectedTruck.id,
                        truckLicensePlate: _this.selectedTruck.licensePlate,
                        truckCarrierId: _this.selectedTruck.carrierOrganizationId,
                        truckImage: _this.selectedTruck.truckImage,
                        truckType: _this.selectedTruck.truckType
                    });
                }
                _this.ticketForm.autoFillFormWithTicket(_this.selectedTicket, true);
            }
            _this.ticketsLoading = false;
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.ticketsLoading = false;
        });
    };
    CreateTicketComponent.prototype.getTrucks = function () {
        var _this = this;
        this.setSearchStatusToSearch();
        if (this.trucksReq && this.trucksReq.unsubscribe) {
            this.trucksReq.unsubscribe();
        }
        if (this.licensePlateNumber && this.licensePlateNumber.length >= this.minLicensePlateLength) {
            this.setSearchStatusSearching();
            this.trucksReq = this.truckService.list({
                search: this.licensePlateNumber,
                include_all: 'True'
            }).subscribe(function (trucks) {
                if (trucks.length > 0) {
                    _this.setSearchStatusToSearch();
                    _this.truckOptions = trucks;
                    _this.trucksDropdown.openMenu();
                }
                else {
                    _this.setSearchStatusNotFound();
                }
            }, function (error) {
                _this.setSearchStatusNotFound();
            });
        }
    };
    CreateTicketComponent.prototype.hasNoValue = function (objToCheck) {
        switch (typeof objToCheck) {
            case 'string':
                return (objToCheck === undefined || objToCheck === null || objToCheck === '');
            default:
                return objToCheck === undefined || objToCheck === null;
        }
    };
    CreateTicketComponent.prototype.newTicket = function () {
        var _this = this;
        if (this.ticketForm.hasUnsavedChanges()) {
            this.ticketForm.warnUnsavedChanges().subscribe(function (dialogResult) {
                switch (dialogResult) {
                    case shared_1.ScaleitConfirmDialogResult.No:
                        _this.licensePlateNumber = null;
                        _this.ticketForm.resetEntireForm();
                        break;
                    case shared_1.ScaleitConfirmDialogResult.Yes:
                        _this.ticketForm.saveTicket(true);
                        break;
                }
            });
        }
        else {
            this.licensePlateNumber = null;
            this.ticketForm.resetEntireForm();
        }
        this.selectedTicket = null;
        this.incompleteTicketsComponent.selectedTicket = null;
    };
    CreateTicketComponent.prototype.showNotification = function (notificationMessage, notificationLevel, notificationTimeout) {
        var _this = this;
        if (notificationTimeout === void 0) { notificationTimeout = this.notificationTimeout; }
        this.notificationLevel = notificationLevel;
        this.notificationMessage = notificationMessage;
        this.notification.reset();
        setTimeout(function () {
            _this.notification.close();
        }, notificationTimeout);
    };
    CreateTicketComponent.prototype.onTicketSelected = function (ticket) {
        var _this = this;
        if (this.ticketForm.currentTicket && ticket && ticket.id === this.ticketForm.currentTicket.id) {
            return;
        }
        if (this.ticketForm.hasUnsavedChanges()) {
            this.ticketForm.warnUnsavedChanges().subscribe(function (dialogResult) {
                switch (dialogResult) {
                    case shared_1.ScaleitConfirmDialogResult.No:
                        _this.ticketForm.autoFillFormWithTicket(ticket);
                        _this.licensePlateNumber = null;
                        _this.incompleteTicketsComponent.highlightTicket(ticket, false);
                        break;
                    case shared_1.ScaleitConfirmDialogResult.Yes:
                        _this.ticketForm.saveTicket(true).subscribe(function (res) {
                            if (res) {
                                _this.ticketForm.autoFillFormWithTicket(ticket);
                                _this.licensePlateNumber = null;
                                _this.incompleteTicketsComponent.selectedTicket = ticket;
                                _this.incompleteTicketsComponent.highlightTicket(ticket, false);
                            }
                        }, function (error) {
                            _this.errors = api_service_1.parseErrors(error);
                        });
                        break;
                }
            });
        }
        else {
            if (ticket.statusText === 'On Hold') {
                ticket.onHold = true;
                this.ticketForm.currentTicket.onHold = true;
            }
            this.ticketForm.autoFillFormWithTicket(ticket);
            this.licensePlateNumber = null;
            this.incompleteTicketsComponent.highlightTicket(ticket, false);
        }
    };
    // Modal to show method will show warning for navigation if unsaved
    CreateTicketComponent.prototype.checkUnsavedForNavigation = function () {
        var _this = this;
        if (this.ticketForm.hasUnsavedChanges()) {
            return new rxjs_1.Observable(function (observer) {
                _this.ticketForm.warnUnsavedChanges().subscribe(function (dialogResult) {
                    switch (dialogResult) {
                        case shared_1.ScaleitConfirmDialogResult.No:
                            _this.ticketForm.resetEntireForm();
                            observer.next(true);
                            break;
                        case shared_1.ScaleitConfirmDialogResult.Yes:
                            _this.ticketForm.saveTicket(true);
                            break;
                        default:
                            observer.next(false);
                    }
                });
            });
        }
        else {
            return rxjs_1.of(true);
        }
    };
    return CreateTicketComponent;
}());
exports.CreateTicketComponent = CreateTicketComponent;
