<div class="sidebar">
  <div class="pinned-top">
    <div class="sidebar-logo-container" (click)="toggleMobileOpen(); toggleSidebarExpanded(); true">
      <img src="assets/img/nav-logo-full.svg" alt="Ruckit Logo" class="nav-logo" />
      <img src="assets/img/nav-logo.svg" alt="Ruckit Logo" class="nav-logo-small" />
    </div>

    <div class="sidebar-menu">
      <div *ngFor="let section of (sections | menuFilter:'top'); let isFirst = first"
           [ngClass]="{'first': isFirst, 'menu-heading': section.type === 'heading', 'menu-section': section.type !== 'heading', 'menu-toggle-section': section.type === 'toggle'}">
        <h2 class="menu-heading mat-subhead" *ngIf="section.type === 'heading'" id="heading_{{ section.name }}">
          {{section.name}}
        </h2>
        <menu-link [section]="section" *ngIf="section.type === 'link' && !section.hidden" (hover)="toggleMobileOpen(); true" (click)="toggleMobileOpen(); true"></menu-link>
        <menu-link [section]="section" [external]="true" *ngIf="section.type === 'external-link' && !section.hidden" (hover)="toggleMobileOpen(); true" (click)="toggleMobileOpen(); true"></menu-link>
        
        <menu-toggle [section]="section" *ngIf="section.type === 'toggle' && !section.hidden"></menu-toggle>
      </div>
    </div>
  </div>

  <div class="pinned-bottom">
    <div class="sidebar-menu">
      <div *ngFor="let section of (sections | menuFilter:'bottom'); let isFirst = first"
           [ngClass]="{'first': isFirst, 'menu-heading': section.type === 'heading', 'menu-section': section.type !== 'heading', 'menu-toggle-section': section.type === 'toggle'}">
        <h2 class="menu-heading mat-subhead" *ngIf="section.type === 'heading'" id="heading_{{ section.name }}">
          {{section.name}}
        </h2>
        <menu-link [section]="section" *ngIf="section.type === 'link' && !section.hidden" (click)="toggleMobileOpen(); true"></menu-link>
        <menu-link [section]="section" [external]="true" *ngIf="section.type === 'external-link' && !section.hidden" (click)="toggleMobileOpen(); true"></menu-link>
        <menu-link [section]="section" [external]="true" (menuClick)="onMenuClick($event)" *ngIf="section.type === 'click' && !section.hidden" (hover)="toggleMobileOpen(); true" (click)="toggleMobileOpen(); true"></menu-link>
        
        <menu-toggle [section]="section" *ngIf="section.type === 'toggle' && !section.hidden"></menu-toggle>
      </div>
    </div>
    <div class="application-version">
      BUILD {{ applicationVersion | slice:0:7 }}
      <div style="display: none;">{{ applicationVersion }}</div>
    </div>
  </div>
</div>
