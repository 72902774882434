import { PurchaseOrder } from './purchase-order';
import { Organization } from '../organizations/organization';
import { ProductSerializer } from '../products/product.serializer';
import { LocationSerializer } from '../locations/location.serializer';
import { PriceSerializer } from '../prices/price.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');


export class PurchaseOrderSerializer {

  fromJson(json: any): PurchaseOrder {
    json = camelcaseKeysDeep(json);
    const purchaseOrder = new PurchaseOrder();
    if (!json) { return purchaseOrder; }

    purchaseOrder.id = json.id;
    if (json.customerOrganization && typeof (json.customerOrganization) === 'object') {
      purchaseOrder.customerOrganization = new Organization(json.customerOrganization);
    } else {
      purchaseOrder.customerOrganization = new Organization({ id: json.customerOrganization });
    }
    if (json.sellerOrganization && typeof json.sellerOrganization === 'object') {
      purchaseOrder.sellerOrganization = new Organization(json.sellerOrganization);
    } else {
      purchaseOrder.sellerOrganization = new Organization({ id: json.sellerOrganization });
    }
    if (json.product && typeof json.product === 'object') {
      purchaseOrder.product = new ProductSerializer().fromJson(json.product);
    } else {
      purchaseOrder.product = new ProductSerializer().fromJson({ id: json.product });
    }
    purchaseOrder.createdAt = json.createdAt;
    purchaseOrder.lastModified = json.lastModified;
    purchaseOrder.number = json.number;
    purchaseOrder.name = json.name;
    purchaseOrder.unitPrice = json.unitPrice;
    purchaseOrder.quantity = json.quantity;
    purchaseOrder.taxExempt = json.taxExempt;
    purchaseOrder.email = json.email;
    purchaseOrder.phoneNumber = json.phoneNumber;
    purchaseOrder.faxNumber = json.faxNumber;
    purchaseOrder.fullStop = json.fullStop;
    purchaseOrder.fulfilled = json.fulfilled || json.fullfilled;
    purchaseOrder.void = json.void;
    purchaseOrder.deleted = json.deleted;
    if (json.deliveryLocation && typeof json.deliveryLocation === 'object') {
      purchaseOrder.deliveryLocation = (new LocationSerializer).fromJson(json.deliveryLocation);
    } else {
      purchaseOrder.deliveryLocation = (new LocationSerializer).fromJson({ id: json.deliveryLocation });
    }
    if (json.customPrice && typeof json.customPrice === 'object') {
      purchaseOrder.customPrice = (new PriceSerializer).fromJson(json.customPrice);
    } else if (json.customPrice) {
      purchaseOrder.customPrice = (new PriceSerializer).fromJson({ id: json.customPrice });
    }
    purchaseOrder.notes = json.notes;
    if (json.unitOfMeasurement) {
      purchaseOrder.unitOfMeasurement = json.unitOfMeasurement;
    } else if (json.product && json.product.unitOfMeasurement) {
      purchaseOrder.unitOfMeasurement = json.product.unitOfMeasurement;
    }

    if (purchaseOrder.void) {
      purchaseOrder.status = 'Void';
      purchaseOrder.statusLabel = 'label-void';
    } else {
      purchaseOrder.status = '';
      purchaseOrder.statusLabel = '';
    }

    return purchaseOrder;
  }

  toJson(purchaseOrder: PurchaseOrder): any {
    let json = {
      id: purchaseOrder.id,
      customerOrganization: purchaseOrder.customerOrganization && purchaseOrder.customerOrganization.id,
      sellerOrganization: purchaseOrder.sellerOrganization && purchaseOrder.sellerOrganization.id,
      product: purchaseOrder.product && purchaseOrder.product.id,
      createdAt: purchaseOrder.createdAt,
      lastModified: purchaseOrder.lastModified,
      number: purchaseOrder.number,
      name: purchaseOrder.name,
      unitPrice: purchaseOrder.unitPrice,
      quantity: purchaseOrder.quantity,
      taxExempt: purchaseOrder.taxExempt,
      email: purchaseOrder.email,
      phoneNumber: purchaseOrder.phoneNumber,
      faxNumber: purchaseOrder.faxNumber,
      fullStop: purchaseOrder.fullStop,
      fulfilled: purchaseOrder.fulfilled,
      void: purchaseOrder.void,
      deleted: purchaseOrder.deleted,
      deliveryLocation: purchaseOrder.deliveryLocation && purchaseOrder.deliveryLocation.id,
      notes: purchaseOrder.notes,
      customPrice: purchaseOrder.customPrice && purchaseOrder.customPrice.id,
      unitOfMeasurement: purchaseOrder.unitOfMeasurement,
      status: purchaseOrder.status,
      statusLabel: purchaseOrder.statusLabel
    };
    return decamelizeKeysDeep(json);
  }
}
