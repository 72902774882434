"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var cropperjs_1 = require("cropperjs");
// utilities
var app_utilities_1 = require("../app-utilities");
var ImageEditComponent = /** @class */ (function () {
    function ImageEditComponent(translateService) {
        this.translateService = translateService;
        this.options = {
            editable: true,
            light: false,
            showDelete: false,
            disabled: false,
        };
        this.uploading = new core_1.EventEmitter();
        this.uploadData = new core_1.EventEmitter();
        this.cropperId = Math.floor(Math.random() * 10000 + 1);
        this.imageElementId = 'cropper-image-' + this.cropperId;
        this.inputElementId = 'image-input-' + this.cropperId;
        this.existingImage = false;
        this.imageId = null;
        this.legacyImgToDataURL = function (url) {
            return fetch(url)
                .then(function (response) { return response.blob(); })
                .then(function (blob) {
                return new Promise(function (resolve, reject) {
                    var reader = new FileReader();
                    reader.onloadend = function () { return resolve(reader.result); };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            });
        };
    }
    ImageEditComponent.prototype.ngOnInit = function () {
        this.onInit();
    };
    ImageEditComponent.prototype.ngAfterViewInit = function () {
        this.initImageCropper();
    };
    ImageEditComponent.prototype.onInit = function () {
        if (this.image) {
            this.imageId = this.image.id ? this.image.id : '';
            this.cropperImage = this.uploadedImageURL = this.image.src
                ? this.image.src
                : URL.createObjectURL(this.image);
            this.existingImage = true;
        }
    };
    ImageEditComponent.prototype.initImageCropper = function () {
        this.imageElement = (document.getElementById(this.imageElementId));
        this.initCropper(this.imageElement);
    };
    ImageEditComponent.prototype.initCropper = function (el) {
        el = el
            ? el
            : document.getElementById(this.imageElementId);
        this.cropper = new cropperjs_1.default(el, {
            autoCrop: false,
            autoCropArea: 1,
            background: false,
            guides: false,
            highlight: false,
            modal: false,
            movable: false,
            restore: false,
            toggleDragModeOnDblclick: false,
            viewMode: 2,
            zoomable: false,
        });
        // for legacy images - temporary
        if (this.image && this.image.legacy) {
            this.imgUrlToBase64();
        }
    };
    ImageEditComponent.prototype.imgUrlToBase64 = function () {
        return __awaiter(this, void 0, void 0, function () {
            var base64Img;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.legacyImgToDataURL(this.image.src)];
                    case 1:
                        base64Img = _a.sent();
                        this.image['file'] = base64Img;
                        return [2 /*return*/];
                }
            });
        });
    };
    ImageEditComponent.prototype.convertBlobtoFile = function (blob) {
        var b = blob;
        b.lastModifiedDate = new Date();
        b.name = 'uploaded-image-' + b.lastModifiedDate.toISOString() + '.jpg';
        b.isEdited = true;
        b.isNew = this.image && this.image.isNew ? true : false;
        b.id = this.image && this.image.id ? this.image.id : null;
        return blob;
    };
    ImageEditComponent.prototype.newImage = function (e) {
        var _this = this;
        var files = e.target.files;
        var file;
        var uploadedImageType, uploadedImageName;
        if (this.cropper && files && files.length > 0) {
            this.uploading.emit(true);
            file = files[0];
            if (app_utilities_1.AppUtilities.fileSmallerThanAllowed(file.size)) {
                if (/^image\/\w+/.test(file.type)) {
                    this.image = file;
                    uploadedImageType = file.type;
                    uploadedImageName = file.name;
                    if (this.uploadedImageURL) {
                        URL.revokeObjectURL(this.uploadedImageURL);
                    }
                    this.imageElement.src = this.uploadedImageURL =
                        URL.createObjectURL(file);
                    if (this.imageId) {
                        file['isEdited'] = true;
                        file['id'] = this.imageId;
                    }
                    else {
                        file['isNew'] = true;
                    }
                    this.cropper.destroy();
                    if (file.size > 1000000) {
                        // 1mb
                        this.initCropper(this.imageElement);
                        var timesRun_1 = 0;
                        var interval_1 = setInterval(function () {
                            timesRun_1 += 1;
                            if (_this.cropper && _this.cropper.getCroppedCanvas()) {
                                if (file.size > 5000000) {
                                    // if image is bigger than 5mb, reduce the quality by 95%
                                    _this.resizeImage(file.type, 0.05);
                                }
                                else {
                                    // else reduce the quality by 85%
                                    _this.resizeImage(file.type);
                                }
                                clearInterval(interval_1);
                            }
                            if (timesRun_1 === 10) {
                                clearInterval(interval_1);
                            }
                        }, 500);
                    }
                    else {
                        this.emitData(file);
                        this.initCropper(this.imageElement);
                    }
                }
                else {
                    window.alert(this.translateService.instant('Please choose an image file.'));
                }
            }
            else {
                window.alert(this.translateService.instant('Please choose a smaller image (under 25MB).'));
            }
        }
    };
    ImageEditComponent.prototype.resizeImage = function (fileType, quality) {
        var _this = this;
        if (quality === void 0) { quality = 0.15; }
        // lowering quality by 85%
        var croppedCanvas = this.cropper.getCroppedCanvas();
        if (croppedCanvas) {
            croppedCanvas.toBlob(function (blob) {
                var edittedImage = _this.convertBlobtoFile(blob);
                _this.emitData(edittedImage);
            }, fileType, quality);
        }
    };
    ImageEditComponent.prototype.emitData = function (image) {
        image['legacy'] = this.image && this.image.legacy ? true : false;
        this.uploadData.emit(image);
        this.uploading.emit(false);
    };
    ImageEditComponent.prototype.rotateImage = function (fileType) {
        var _this = this;
        this.uploading.emit(true);
        var croppedCanvas = this.cropper.rotate(90).getCroppedCanvas();
        if (croppedCanvas) {
            croppedCanvas.toBlob(function (blob) {
                var edittedImage = _this.convertBlobtoFile(blob);
                _this.emitData(edittedImage);
            }, fileType, 1.0);
        }
    };
    ImageEditComponent.prototype.removeImage = function () {
        // this.image = null;
        this.image['isDeleted'] = true;
        this.imageElement.src = null;
        this.uploadedImageURL = null;
        this.fileInputElement.nativeElement.value = '';
        this.cropper.destroy();
        this.initImageCropper();
        this.uploadData.emit(this.image);
    };
    return ImageEditComponent;
}());
exports.ImageEditComponent = ImageEditComponent;
