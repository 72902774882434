"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%;overflow:auto}.form-content[_ngcontent-%COMP%]{flex:1 1 auto;padding-top:30px;color:#7f8fa4}.heavy[_ngcontent-%COMP%]{font-weight:600}.separator[_ngcontent-%COMP%]{margin-bottom:30px}"];
exports.styles = styles;
