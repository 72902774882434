import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../shared/resource.service';
import { DriverJobReport } from './driver-job-report';
import { DriverJobReportSerializer } from './driver-job-report.serializer';

@Injectable()
export class DriverJobReportService extends ResourceService<DriverJobReport> {

  constructor(
    http: HttpClient
  ) {
    super(http, 'assignments/job_report/', new DriverJobReportSerializer());
  }
}
