"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var shared_1 = require("../../shared");
var api_service_1 = require("../../shared/api.service");
var end_of_day_overall_stats_service_1 = require("./end-of-day-overall-stats.service");
var EndOfDayOverallStatsComponent = /** @class */ (function () {
    function EndOfDayOverallStatsComponent(endOfDayOverallStatsService, authenticationService) {
        this.endOfDayOverallStatsService = endOfDayOverallStatsService;
        this.authenticationService = authenticationService;
        this.errors = [];
    }
    EndOfDayOverallStatsComponent.prototype.ngOnChanges = function () {
        this.getEndOfDayOverallStats();
    };
    EndOfDayOverallStatsComponent.prototype.getEndOfDayOverallStats = function () {
        var _this = this;
        var startDate = this.reportDate;
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        var query = {};
        var carrierFilter = this.filters.find(function (c) { return c.key === 'carrier'; });
        var carrierFilterValue = carrierFilter &&
            carrierFilter.values.length > 0 &&
            carrierFilter.values[0].value;
        var currentUserCarrier = this.authenticationService.getOrganization();
        var currentUserOrganizationId = currentUserCarrier &&
            currentUserCarrier.carrier &&
            currentUserCarrier.carrier.id
            ? currentUserCarrier.carrier.id
            : '';
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.stats$ = this.endOfDayOverallStatsService
            .get(null, __assign({ active_range: startDate.toISOString() + "," + endDate.toISOString(), search: this.search }, (carrierFilterValue
            ? {
                carrier: carrierFilterValue === currentUserOrganizationId
                    ? null
                    : carrierFilterValue,
            }
            : { all_carriers: 'True' }), this.parseFiltersToQuery(this.filters.slice()), query), false)
            .pipe(operators_1.catchError(function (err) {
            _this.errors = api_service_1.parseErrors(err);
            return rxjs_1.throwError(err);
        }));
    };
    EndOfDayOverallStatsComponent.prototype.parseFiltersToQuery = function (filters) {
        var activeFilters = filters.filter(function (f) {
            return (f.key !== 'carrier' && f.values && f.values.length > 0) ||
                f.selected === true;
        });
        return activeFilters.reduce(function (result, item) {
            var _a;
            return (_a = {},
                _a[item.key] = item.values && item.values.length ? item.values[0].value : 'True',
                _a);
        }, {});
    };
    return EndOfDayOverallStatsComponent;
}());
exports.EndOfDayOverallStatsComponent = EndOfDayOverallStatsComponent;
