"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var invoice_trips_dialog_component_1 = require("./invoice-trips-dialog.component");
var CreateInvoiceComponent = /** @class */ (function () {
    function CreateInvoiceComponent(route, router, dialog) {
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.search = '';
        this.errors = [];
        this.addTripsCallback = function (e) {
            // Update trip data
        };
    }
    CreateInvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.forEach(function (params) {
            _this.jobId = params['jobId'];
        });
        this.route.queryParams.forEach(function (params) {
            _this.search = params['search'] || '';
        });
        this.openAddTrips();
    };
    CreateInvoiceComponent.prototype.openAddTrips = function () {
        var dialog = this.dialog.open(invoice_trips_dialog_component_1.InvoiceTripsDialogComponent, {
            width: '100%',
            height: '100%',
            disableClose: true
        });
        // dialog.disableClose = true;
        dialog.componentInstance.jobId = this.jobId;
        dialog.componentInstance.search = this.search;
        dialog.componentInstance.callback = this.addTripsCallback;
    };
    return CreateInvoiceComponent;
}());
exports.CreateInvoiceComponent = CreateInvoiceComponent;
