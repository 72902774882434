import * as moment from 'moment';

import { DriverSerializer } from '../drivers/driver.serializer';
import { JobSerializer } from '../jobs/job.serializer';
import { DriverJobReport } from './driver-job-report';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class DriverJobReportSerializer {
  fromJson(json: any): DriverJobReport {
    json = camelcaseKeysDeep(json);
    const driverJobReport = new DriverJobReport();
    if (!json) { return driverJobReport; }

    driverJobReport.id = json.id;
    driverJobReport.createdAt = json.createdAt;
    driverJobReport.createdBy = json.createdBy;
    driverJobReport.lastModified = json.lastModified;
    driverJobReport.lastModifiedBy = json.lastModifiedBy;
    driverJobReport.reportingOrganization = json.reportingOrganization;
    driverJobReport.reportDate = json.reportDate;
    if (json.driver && typeof (json.driver) === 'object') {
      driverJobReport.driver = new DriverSerializer().fromJson(json.driver);
    } else {
      driverJobReport.driver = new DriverSerializer().fromJson({ id: json.driver });
    }
    if (json.job && typeof (json.job) === 'object') {
      driverJobReport.job = new JobSerializer().fromJson(json.job);
    } else {
      driverJobReport.job = new JobSerializer().fromJson({ id: json.job });
    }
    driverJobReport.isApproved = json.isApproved;
    driverJobReport.clockInTime = json.clockInTime;
    driverJobReport.clockOutTime = json.clockOutTime;
    driverJobReport.firstLoadTime = json.firstLoadTime;
    driverJobReport.loadingWaitTime = this.asMinutes(json.loadingWaitTime) || '-';
    driverJobReport.unloadingWaitTime = this.asMinutes(json.unloadingWaitTime) || '-';
    driverJobReport.averageRoundTripTime = this.asMinutes(json.averageRoundTripTime) || '-';
    driverJobReport.tripCount = json.tripCount;
    driverJobReport.punchcardCount = json.punchcardCount;
    driverJobReport.lastLoadTime = json.lastLoadTime;
    driverJobReport.freightBillTime = this.asMinutes(json.freightBillTime) || '-';
    driverJobReport.shiftTime = this.asMinutes(json.shiftTime) || '-';
    if (json.shiftTime && json.freightBillTime) {
      let deficientSeconds = moment.duration(
        moment.duration(json.shiftTime).subtract(moment.duration(json.freightBillTime))
      ).asSeconds();
      driverJobReport.deficientTime = this.asMinutes(deficientSeconds) + ' minutes';
    } else {
      driverJobReport.deficientTime = '-';
    }

    return driverJobReport;
  }

  toJson(driverJobReport: DriverJobReport): any {
    const json: { [key: string]: any } = {
      id: driverJobReport.id,
      createdAt: driverJobReport.createdAt,
      createdBy: driverJobReport.createdBy,
      lastModified: driverJobReport.lastModified,
      lastModifiedBy: driverJobReport.lastModifiedBy,
      reportDate: driverJobReport.reportDate,
      driver: driverJobReport.driver && driverJobReport.driver.id,
      job: driverJobReport.job && driverJobReport.job.id,
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }

  asMinutes(value: number): string {
    let minutes = '0';
    if (value) { minutes = (value / 60).toFixed(2); }

    return minutes + ' minutes';
  }
}
