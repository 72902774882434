import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { DriverContextEvent } from '../../../../drivers/driver-context-menu/interfaces/driver-context-event';

import { PayRecord } from '../../../pay-record';

@Component({
  selector: 'ruckit-driver-pay-timeline-list',
  templateUrl: './driver-pay-timeline-list.component.html',
  styleUrls: ['./driver-pay-timeline-list.component.scss']
})

export class DriverPayTimelineListComponent implements OnInit {
  @Input() rows: PayRecord[];
  @Output() editing: EventEmitter<string> = new EventEmitter();
  @Output() selected: EventEmitter<string[]> = new EventEmitter();
  @Output() selectAllRows: EventEmitter<boolean> = new EventEmitter();
  jobEventDate: string;
  allSelected = false;
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor() {}

  ngOnInit() {
    this.rows.map(row => {
      row.data.uniqueStartDate = row.data.assignment.jobevent && row.data.assignment.jobevent.shift1StartTimestamp;
      row.data.date = moment(row.data.assignment.jobevent && row.data.assignment.jobevent.shift1StartTimestamp).format('YYYYMMDD');
      return row;
    });
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    this.rows.map(row => {
      row.data.selected = this.allSelected;
      return row;
    });
    this.emitSelection();
    this.selectAllRows.emit(this.allSelected);
  }

  emitSelection() {
    const selectedRows = this.rows.filter(row => row.data.selected);
    this.selected.emit(selectedRows.map(row => row.data.referenceId));
    if (selectedRows.length !== this.rows.length) {
      this.allSelected = false;
      this.selectAllRows.emit(this.allSelected);
    }
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }
}
