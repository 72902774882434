<div class="app-content">
  <my-toolbar title="{{ 'Work Orders' | translate }}"></my-toolbar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="work-orders-container">
    <div class="actions-wrapper">
      <fancy-table #workOrdersTable
                   [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   [config]="workOrdersTableConfig"
                   (columnChange)="columnsChanged($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value name" *ngSwitchCase="'name'">
              <div class="data">{{ row.job ? row.job : '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'job-number'">
              <div class="data">{{ row.jobNumber ? row.jobNumber : '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'order-number'">
              <div class="data">{{ row.jobOrderNumber ? row.jobOrderNumber : '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'startDate'">
              <div class="data">{{ row.startDate ? (row.startDate | date:'mediumDate') : '&nbsp;'}}</div>
            </div>
            <div class="value" *ngSwitchCase="'startTime'">
              <div class="data">{{ row.startTime ? row.startTime : '&nbsp;'}}</div>
            </div>
            <div class="value" *ngSwitchCase="'endDate'">
              <div class="data">{{ row.endDate ? (row.endDate | date:'mediumDate') : '&nbsp;'}}</div>
            </div>
            <div class="value" *ngSwitchCase="'endTime'">
              <div class="data">{{ row.endTime ? row.endTime : '&nbsp;'}}</div>
            </div>
            <div class="value numeric" *ngSwitchCase="'dispatched'">
              <div class="data">{{ row.dispatched ? (row.dispatched | number) : 0}}</div>
            </div>
            <div class="value numeric" *ngSwitchCase="'confirmed-trucks'">
              <div class="data">{{ row.confirmedTrucks ? (row.confirmedTrucks | number) : 0}}
              </div>
            </div>
            <div class="value numeric" *ngSwitchCase="'num-trucks'">
              <div class="data">{{ row.numTrucks ? (row.numTrucks | number) : 0}}</div>
            </div>
            <div class="value" *ngSwitchCase="'haul-rate-unit'">
              <div class="data">{{ row.haulRateUnit ? row.haulRateUnit : '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'workOrderSigned'">
              <div class="data">{{ row.workOrderSigned ? 'Yes' : 'No'}}</div>
            </div>
            <div class="value" *ngSwitchCase="'workOrderSignedBy'">
              <div class="data">{{ row.workOrderSignedByName ? row.workOrderSignedByName : '&nbsp;'}}</div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Work Orders</div>
        </div>
        <div applied-filter-content>
           <applied-filters #appliedFiltersDisplay
                          [(filters)]="appliedFilters"
                          [(search)]="search"
                          (filtersChange)="filtersModified($event)"
                          (filtersReset)="removeFilter($event)">
          </applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <dropdown [disabled]="multiselectDisabled()"
                  class="record-actions" title="{{ 'Actions' | translate }}"
                  [options]="multipleActionDropdownOptions" (onSelect)="setSelectedAction($event)">
      </dropdown>
        <a class="link" [routerLink]="['/work-orders', 'templates']">
          <mat-icon>picture_as_pdf</mat-icon>
        </a>
        <column-toggle #columnToggle
                      [(availableColumns)]="availableColumns"
                      [(displayedColumns)]="displayedColumns">
        </column-toggle>
        <filters-panel #filtersPanelDisplay
                        [(availableFilters)]="availableFilters"
                        [(appliedFilters)]="appliedFilters"
                        [(search)]="search"
                        (appliedFiltersChange)="filtersModified($event)">
        </filters-panel>
      </div>
    </div>
  </div>
</div>
