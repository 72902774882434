import { Resource } from '../shared/resource';

export enum CustomFieldKind {
  'Truck' = 'truck',
  'Job' = 'job',
  'Driver' = 'driver',
  'Share' = 'jobeventshare'
}

export class CustomField extends Resource {
  displayName?: string;
  key: string;
  kind: CustomFieldKind;
  ownerOrganization: string;
  active = true;
}
