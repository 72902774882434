import { clone } from 'lodash';
import { Organization } from '../organizations/organization';
import { Price } from '../prices/price';
import { Resource } from '../shared/resource';

export class PriceList extends Resource {
  name: string;
  createdAt: string;
  lastModified: string;
  ownerOrganization: Organization;
  notes: string;
  status: string;
  prices: Price[];
  id: string;

  url(action): string {
    return '/inventory/price-lists/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
