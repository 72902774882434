import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { requestHeaders, handleError } from '../../shared/api.service';
import { JobLoad } from './job-load';

@Injectable()
export class JobLoadService {
  baseUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}

  getLoads(jobEventId: string): Observable<any> {
    const url = `${this.baseUrl}connex/schedules/${jobEventId}/get/`;
    return this.http
      .get(url, { headers: requestHeaders() })
      .pipe(catchError((err) => handleError(err)));
  }

  patchLoads(jobEventId: string, data: JobLoad[]) {
    const url = `${this.baseUrl}connex/schedules/${jobEventId}/patch/`;
    return this.http
      .post(url, data, {
        headers: requestHeaders(),
      })
      .pipe(catchError((err) => handleError(err)));
  }
}
