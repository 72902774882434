'use strict';

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {
  @Input() options = [];
  @Input() icon: string;
  @Input() title: string;
  @Input() disabled = false;

  constructor() { }

  ngOnInit() { }
}
