"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var AssignmentTripsComponent = /** @class */ (function () {
    function AssignmentTripsComponent(route, router) {
        this.route = route;
        this.router = router;
        this.displayedColumns = [];
        this.view = 'list';
        this.loading = false;
        this.errors = [];
        this.trips = [];
        this.activeTrips = false;
        this.count = 0;
        this.menuOptions = [
            { name: 'Export', action: 'export', link: false, external: false },
            { name: 'Void', action: 'void', link: false, external: false },
        ];
        this.dataSource = new AssignmentTripDataSource();
    }
    AssignmentTripsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataSource.setData(this.trips);
        this.count = this.trips.length;
        this.route.queryParams.forEach(function (params) {
            _this.view = params['view'] ? params['view'] : _this.view;
            if (_this.view === 'stats') {
                _this.displayedColumns = [
                    'ticket', 'total-time', 'en-route1', 'loading', 'en-route2',
                    'unloading', 'weight', 'invoice-total', 'expense-total', 'assignments'
                ];
            }
            else {
                _this.displayedColumns = [
                    'ticket', 'total-time', 'assignments'
                ];
            }
        });
    };
    AssignmentTripsComponent.prototype.setSelectedAction = function (option) {
        switch (option.name) {
            case 'Export':
                break;
        }
    };
    return AssignmentTripsComponent;
}());
exports.AssignmentTripsComponent = AssignmentTripsComponent;
var AssignmentTripDataSource = /** @class */ (function () {
    function AssignmentTripDataSource() {
        this.data = new rxjs_1.BehaviorSubject([]);
    }
    AssignmentTripDataSource.prototype.setData = function (trips) {
        var rows = trips;
        this.data.next(rows);
    };
    AssignmentTripDataSource.prototype.connect = function () {
        return this.data;
    };
    AssignmentTripDataSource.prototype.disconnect = function () { };
    return AssignmentTripDataSource;
}());
