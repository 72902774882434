import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { RuckitReplayRoutingModule } from './ruckit-replay-routing.module';
import { RuckitReplayComponent } from './ruckit-replay/ruckit-replay.component';
import { RuckitReplayTimelineComponent } from './ruckit-replay-timeline/ruckit-replay-timeline.component';
import { RuckitReplayMapComponent } from './ruckit-replay-map/ruckit-replay-map.component';

import { AssignmentFilterService } from '../assignments/assignment.filter.service';
import { DriverService } from '../drivers/driver.service';

@NgModule({
  imports: [CommonModule, SharedModule, RuckitReplayRoutingModule],
  declarations: [
    RuckitReplayComponent,
    RuckitReplayTimelineComponent,
    RuckitReplayMapComponent,
  ],
  providers: [AssignmentFilterService, DriverService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class RuckitReplayModule {}
