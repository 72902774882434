"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var map_1 = require("@ngui/map");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var ngx_device_detector_1 = require("ngx-device-detector");
var operators_1 = require("rxjs/operators");
var core_2 = require("@ngx-translate/core");
var location_service_1 = require("./location.service");
var index_1 = require("../shared/dialogs/index");
var api_service_1 = require("../shared/api.service");
var shared_1 = require("../shared");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var static_data_1 = require("../shared/static-data");
var LocationsComponent = /** @class */ (function () {
    function LocationsComponent(route, router, locationService, authenticationService, deviceDetectorService, translationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.locationService = locationService;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'location-type', title: this.translationService.instant('Location Type'), sortable: true, sortBy: 'loading_type__name' },
            { key: 'location-owner', title: this.translationService.instant('Location Owner'), sortable: true, sortBy: 'owner__name' },
            { key: 'name', title: this.translationService.instant('Location Name'), sortable: true },
            { key: 'address', title: this.translationService.instant('Address'), sortable: true },
            { key: 'city', title: this.translationService.instant('City'), sortable: true },
            { key: 'state', title: this.translationService.instant('State'), sortable: true },
            { key: 'zip', title: this.translationService.instant('Zip'), sortable: true, sortBy: 'zipcode' },
            { key: 'load-tgt', title: this.translationService.instant('Load Tgt'), sortable: true, sortBy: 'average_loading_time' },
            { key: 'unload-tgt', title: this.translationService.instant('Unload Tgt'), sortable: true, sortBy: 'average_unloading_time' },
            { key: 'created-at', title: this.translationService.instant('Created At'), sortable: true, sortBy: 'created_at' },
            { key: 'geofence', title: this.translationService.instant('Geofence'), sortable: true, sortBy: 'has_geofence' },
            { key: 'timezone', title: this.translationService.instant('Timezone'), sortable: false },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'select', 'location-type', 'name', 'address', 'city', 'state', 'zip',
            'load-tgt', 'unload-tgt', 'geofence', 'timezone', 'created-at', 'action'
        ];
        this.availableFilters = [
            new filter_option_1.FilterOption({
                key: 'has_geofence', filterType: 'checkbox',
                title: this.translationService.instant('Has Geofence')
            }),
        ];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        // config for fancy table
        this.locationTableConfig = {
            hasHeader: true,
            service: location_service_1.LocationService,
            preferenceKey: 'LocationsComponent-LocationService',
            query: {},
            collectionTitle: this.translationService.instant('Locations'),
            noResultsText: this.translationService.instant('a location'),
            newRecordRoute: ['locations/new'],
            sortBy: 'name',
            sortDirection: 'asc',
            ignorePreferences: false,
            menuOptions: [
                { name: this.translationService.instant('Edit Location'), action: 'edit', link: true, external: false },
                { name: this.translationService.instant('Archive'), action: 'archive', link: false, external: false },
                { name: this.translationService.instant('Unarchive'), action: 'unarchive', link: false, external: false }
            ]
        };
        this.query = {};
        this.locations = [];
        this.loading = true;
        this.errors = [];
        this.count = 0;
        this.windowHeight = 0;
        this.hideArchived = true;
        this.view = 'map';
        this.selectedCount = 0;
        this.polygons = [];
        this.markers = [];
        this.customMarkers = [];
        this.filters = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.dropdownOptions = [
            { name: 'Export', button: true }
        ];
        this.menuOptions = [
            { name: this.translationService.instant('Edit Location'), action: 'edit', link: true, external: false },
            { name: this.translationService.instant('Archive'), action: 'archive', link: false, external: false },
            { name: this.translationService.instant('Unarchive'), action: 'unarchive', link: false, external: false },
        ];
        this.mapOptions = static_data_1.mapOptions({
            disableDefaultUI: true,
            scrollwheel: true,
            fullscreenControl: true,
            streetViewControl: false,
            zoomControl: true,
            zoom: 10,
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'hybrid']
            },
            center: { lat: 30.2178214, lng: -91.093285 }
        }, {}, {
            mapStyle: 'google-map-style'
        });
        this.newLocationCallback = function (e) {
            _this.refreshTable();
        };
        this.editLocationCallback = function (e) {
            _this.refreshTable();
        };
        this.allSubscriptionsToUnsubscribe = [];
        this.windowHeight = window.innerHeight;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        if (!this.device.desktop) {
            this.locationTableConfig.ignorePreferences = true;
            this.displayedColumns = ['name', 'address'];
        }
    }
    LocationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.locationTableConfig['query'] = __assign({}, this.locationTableConfig['query'], query);
        this.locationTableConfig.hasHeader = this.device.desktop;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.pipe(operators_1.delay(0)).subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            _this.locationTableConfig.sortBy = result.qparams.sortBy || _this.locationTableConfig.sortBy;
            _this.locationTableConfig.sortDirection = result.qparams.sortAsc || _this.locationTableConfig.sortDirection;
            _this.hideArchived = result.qparams['hideArchived'] === 'true' ? true : false;
            _this.query['archived'] = _this.hideArchived ? 'False' : null;
            _this.view = result.params['view'] || 'list';
            _this.getLocations();
        }));
    };
    LocationsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    LocationsComponent.prototype.clickAction = function (event) {
        if (this.device.desktop) {
            if (event) {
                this.selectLocation(event[1]);
            }
        }
        else {
            if (event) {
                this.editLocation(event[1]);
            }
        }
    };
    LocationsComponent.prototype.filtersModified = function (appliedFilters) {
    };
    LocationsComponent.prototype.onSearchChange = function () {
        this.search = '';
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        delete queryParams['search'];
        this.router.navigate([], { queryParams: queryParams });
    };
    LocationsComponent.prototype.switchMap = function (view) {
        var _this = this;
        if (view === void 0) { view = 'list'; }
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        queryParams['hideArchived'] = this.hideArchived;
        this.query['archived'] = this.hideArchived ? 'False' : null;
        this.router.navigate(['/'], { skipLocationChange: true }).then(function () {
            _this.router.navigate(['/locations/' + view], { queryParams: queryParams });
        });
    };
    LocationsComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.locationService.listNext();
            if (o) {
                this.loading = true;
                o.subscribe(function (locations) {
                    _this.locations = _this.locations.concat(locations);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; }, function () {
                    _this.loading = false;
                });
            }
        }
    };
    LocationsComponent.prototype.getLocations = function (query, append, select) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (select === void 0) { select = null; }
        if (!append) {
            this.locations = [];
        }
        var order = (this.locationTableConfig.sortDirection === 'asc' ? '' : '-') + this.locationTableConfig.sortBy;
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        delete filters['undefined'];
        if (this.locationsReq) {
            this.locationsReq.unsubscribe();
        }
        if (this.hideArchived) {
            filters['archived'] = 'False';
        }
        else {
            delete filters['archived'];
        }
        this.locationsReq = this.locationService.list(__assign({ ordering: order, search: this.search }, filters, query)).subscribe(function (locations) {
            if (append) {
                _this.locations = _this.locations.concat(locations);
            }
            else {
                _this.locations = locations;
            }
            _this.mapLocations();
            if (select) {
                var location_1 = lodash_1.find(_this.locations, { id: select.id });
                if (location_1) {
                    _this.selectLocation(location_1);
                }
            }
            else {
                _this.selectedLocation = null;
            }
            _this.count = _this.locationService.count;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    LocationsComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        queryParams['search'] = this.search;
        this.router.navigate([], { queryParams: queryParams });
        this.getLocations();
    };
    LocationsComponent.prototype.menuAction = function (action, location) {
        switch (action) {
            case 'archive':
                this.archive(location);
                break;
            case 'unarchive':
                this.unarchive(location);
                break;
        }
    };
    LocationsComponent.prototype.archive = function (location) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Archive Location?'),
            body: this.translationService.instant('This location will be archived and will not be visible in the locations list or dropdown.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Archive')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.locationService.archive(location).subscribe(function (res) {
                    _this.refreshTable();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    LocationsComponent.prototype.unarchive = function (location) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Unarchive Location?'),
            body: this.translationService.instant('This location will be un-archived and will be visible in the locations list and dropdown.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Unarchive')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.locationService.unarchive(location).subscribe(function (res) {
                    _this.refreshTable();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    LocationsComponent.prototype.toggleArchived = function () {
        var _this = this;
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        queryParams['hideArchived'] = this.hideArchived;
        this.query['archived'] = this.hideArchived ? 'False' : null;
        this.router.navigate(['/'], { skipLocationChange: true }).then(function () {
            _this.router.navigate(['/locations/' + _this.view], { queryParams: queryParams });
        });
    };
    LocationsComponent.prototype.onMapLoaded = function (e) {
        if (e === void 0) { e = null; }
        if (e) {
            this.map = e.target.map;
        }
        var map = this.map;
        var locations = this.locations.map(function (location) {
            return new google.maps.LatLng(location.latitude, location.longitude);
        });
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0, LtLgLen = locations.length; i < LtLgLen; i++) {
            var list = locations[i];
            bounds.extend(list);
        }
        if (map) {
            map.fitBounds(bounds);
        }
    };
    LocationsComponent.prototype.initMap = function (event) {
        this.map = event;
        google.maps.event.trigger(this.map, 'resize');
        window.dispatchEvent(new Event('resize'));
    };
    LocationsComponent.prototype.onCustomMarkerInit = function (customMarker) {
        this.customMarkers.push(customMarker);
    };
    LocationsComponent.prototype.openInfoWindow = function (e, marker) {
        var _marker = e.target;
        var bounds = new google.maps.LatLngBounds();
        var latLng;
        if (marker && marker.location) {
            this.infoWindowModel = {
                title: marker.location.displayName,
                address: marker.location.street,
                city: marker.location.city,
                state: marker.location.state,
                zip: marker.location.zip,
                activeJobs: this.translationService.instant('0 Active Jobs')
            };
            latLng = new google.maps.LatLng(marker.location.latitude, marker.location.longitude);
            bounds.extend(latLng);
        }
        _marker.nguiMapComponent.openInfoWindow('info-window', _marker);
        if (marker && marker.location && marker.location.paths) {
            marker.location.paths.forEach(function (path) {
                if (path.lat && path.lng) {
                    bounds.extend(new google.maps.LatLng(path.lat, path.lng));
                }
            });
        }
        else if (this.map && (typeof this.map.setCenter === 'function') && latLng) {
            this.map.setCenter(latLng);
        }
        if (this.map) {
            this.map.fitBounds(bounds);
            var currentZoom = this.map.getZoom();
            if (!marker || !marker.locations || !marker.location.paths) {
                this.map.setZoom(currentZoom - 5);
            }
            else {
                this.map.setZoom(currentZoom - 3);
            }
        }
    };
    LocationsComponent.prototype.mapLocations = function () {
        var _this = this;
        if (typeof google !== 'undefined') {
            var bounds_1 = new google.maps.LatLngBounds();
            this.markers = [];
            this.polygons = [];
            this.locations.forEach(function (location) {
                var latLng = new google.maps.LatLng(location.latitude, location.longitude);
                bounds_1.extend(latLng);
                _this.markers.push({
                    location: location, lat: location.latitude, lon: location.longitude
                });
                if (location.geofence) {
                    _this.polygons.push({ id: location.id, paths: location.paths });
                }
                if (location.paths) {
                    location.paths.forEach(function (path) {
                        if (path.lat && path.lng) {
                            bounds_1.extend(new google.maps.LatLng(path.lat, path.lng));
                        }
                    });
                }
            });
            if (this.map) {
                this.map.fitBounds(bounds_1);
            }
        }
    };
    LocationsComponent.prototype.selectLocation = function (location) {
        lodash_1.without(this.locations, location).forEach(function (_location) { _location.selected = false; });
        location.selected = true;
        if (location.selected && location.longitude && location.latitude) {
            this.selectedLocation = location;
            this.markers = [
                { id: location.id, lat: location.latitude, lon: location.longitude, location: location }
            ];
            if (location.geofence) {
                this.polygons = [{ id: location.id, paths: location.paths }];
            }
            if (this.map) {
                var bounds_2 = new google.maps.LatLngBounds();
                var latLng = new google.maps.LatLng(location.latitude, location.longitude);
                bounds_2.extend(latLng);
                if (location.paths) {
                    location.paths.forEach(function (path) {
                        if (path.lat && path.lng) {
                            bounds_2.extend(new google.maps.LatLng(path.lat, path.lng));
                        }
                    });
                }
                else if (latLng && typeof this.map.setCenter === 'function') {
                    this.map.setCenter(latLng);
                }
                this.map.fitBounds(bounds_2);
                if (!location.paths) {
                    var currentZoom = this.map.getZoom();
                    this.map.setZoom(currentZoom - 3);
                }
            }
        }
        else {
            this.selectedLocation = null;
            this.markers = [];
            this.polygons = [];
        }
    };
    LocationsComponent.prototype.editLocation = function (location) {
        this.router.navigate(['/locations/' + location.id + '/edit'], { queryParams: { returnTo: '/locations' } });
    };
    /**
     * refresh/reload the location table on any action performed like
     * add, edit, remove
     * It also refreshed the map.
     */
    LocationsComponent.prototype.refreshTable = function () {
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.locationTable.getRecords(__assign({}, this.locationTableConfig['query'], query));
        this.getLocations(query, false, null);
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    LocationsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return LocationsComponent;
}());
exports.LocationsComponent = LocationsComponent;
