"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ContextMenuComponent = /** @class */ (function () {
    function ContextMenuComponent() {
        this.menuOptions = [];
        this.menuClosed = new core_1.EventEmitter();
        this.menuSelection = new core_1.EventEmitter();
        this.contextMenuPosition = { x: '0px', y: '0px' };
    }
    ContextMenuComponent.prototype.ngAfterContentInit = function () {
        this.openContextMenu(this.event);
    };
    ContextMenuComponent.prototype.openContextMenu = function (event) {
        var _this = this;
        if (event) {
            event.preventDefault();
            this.contextMenuPosition.x = event.clientX + 'px';
            this.contextMenuPosition.y = event.clientY + 'px';
            this.contextMenu.menuData = this.menuOptions;
            this.contextMenu.menuClosed.subscribe(function () {
                _this.menuClosed.emit();
            });
            // this.contextMenu.menu.focusFirstItem('mouse');
            setTimeout(function () {
                _this.contextMenu.openMenu();
            });
        }
    };
    ContextMenuComponent.prototype.onContextMenuAction = function (item) {
        if (!item.disabled) {
            this.menuSelection.emit(item);
        }
    };
    return ContextMenuComponent;
}());
exports.ContextMenuComponent = ContextMenuComponent;
