<div class="subnav">
  <div class="links">
    <a [routerLink]="['/jobs/daily']" routerLinkActive="active" translate
      >Daily Board</a
    >
    <a
      [routerLink]="['/jobs']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      translate
      >All Jobs</a
    >
    <a
      [routerLink]="['/jobs/drivers']"
      routerLinkActive="active"
      translate
      *ngIf="hasAllDriversEnabled"
      >All Drivers</a
    >
    <a
      *ngIf="carrierMode"
      [routerLink]="['/jobs/locations/active']"
      routerLinkActive="active"
      translate
    >
      Active Locations
    </a>
    <a
      [routerLink]="['/jobs/hauler-shares']"
      routerLinkActive="active"
      translate
    >
      Hauler Shares
    </a>
    <a
      *ngIf="carrierMode"
      [routerLink]="['/jobs/pending']"
      routerLinkActive="active"
    >
      {{ 'Received' | translate }}
      <div class="badge" *ngIf="pendingJobsCount > 0">
        {{ pendingJobsCount }}
      </div>
    </a>
  </div>
</div>
