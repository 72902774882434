import { NotificationsReport } from "./notifications-report";

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class NotificationsReportSerializer {
  fromJson(json: any): NotificationsReport {
    json = camelcaseKeysDeep(json);
    const notificationReport = new NotificationsReport();
    if (!json) { return notificationReport; }

    notificationReport.id = json.id;
    notificationReport.name = json.name;
    notificationReport.lastPeriod = json.lastPeriod;
    notificationReport.currentPeriod = json.currentPeriod;

    if (notificationReport.lastPeriod) {
      notificationReport.percentageChange =
        (notificationReport.currentPeriod - notificationReport.lastPeriod) / notificationReport.lastPeriod;
    }

    return notificationReport;
  }

  toJson(notificationReport: NotificationsReport) {
    let json = {
      id: notificationReport.id,
      name: notificationReport.name,
      lastPeriod: notificationReport.lastPeriod,
      currentPeriod: notificationReport.currentPeriod
    };
    return decamelizeKeysDeep(json);
  }
}