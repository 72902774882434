"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mock_user_1 = require("../users/mock-user");
var mock_broker_1 = require("../brokers/mock-broker");
var mock_carrier_1 = require("../carriers/mock-carrier");
exports.ORGANIZATION = {
    id: '00000000-0000-0000-0000-000000000001',
    createdAt: '2017-01-09T19:38:51.304432Z',
    lastModified: '2017-01-11T15:26:21.137791Z',
    name: 'Sample Organization',
    email: '',
    contactName: '',
    inviteCode: 'VT4RFJ',
    image: null,
    street: '206 E. 9th Street',
    address2: null,
    city: 'Austin',
    state: 'TX',
    country: 'US',
    zipcode: '78701',
    phoneNumber: '1235556789',
    faxNumber: '',
    paymentTerms: '5',
    primaryContact: mock_user_1.USER,
    billingContact: mock_user_1.USER,
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'US',
    billingZipcode: '',
    billingPhoneNumber: '',
    billingEmail: '',
    broker: mock_broker_1.BROKER,
    carrier: mock_carrier_1.CARRIER,
    contractor: null,
    detail: 'San Diego, CA<br />Carrier',
    remittanceInfo: null,
    dvirRequired: false,
    driverFleetCanViewRates: true,
    driverFleetQuickTicket: true,
    canCreateJobs: true,
    invoiceablePunchcardCount: '0',
    invoiceableTripCount: '0',
    posEnabled: false,
    isCrh: false,
    allDriversEnabled: false,
    hasLeasedOrgs: false,
    startingTicketNumber: '0',
    startingInvoiceNumber: '0',
    posPriceList: null,
    uniqueBillingId: '',
    driverFleetRequireAssignmentConfirmation: false,
    avoidTolls: false,
    qrEnabled: false,
    advBillingEnabled: false,
    hasCopyAssignment: false,
    hasWorkOrder: false,
    workOrderDbaName: 'Sample Organization, LLC',
    workOrderCompanyType: 'llc',
    features: null,
    enabledFeatures: null,
    dotNumber: '0001',
    mcNumber: '0001',
    dirNumber: '0001',
    einNumber: '0001',
    isAb5Compliant: true,
    ab5SubmittedDate: '2017-01-09T19:38:51.304432Z',
    ab5SubmittedBy: '00000000-0000-0000-0000-000000000001',
    insuranceDocument: 'overweight-permit.pdf',
    insuranceExpirationDate: '2020-12-12T19:38:51.304432Z',
    overweightPermitExpirationDate: '2020-12-12T19:38:51.304432Z',
    overweightPermit: 'overweight-permit.pdf',
    senderEmail: '',
    senderName: ''
};
