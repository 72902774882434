"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var ScaleitConfirmDialogComponent = /** @class */ (function () {
    function ScaleitConfirmDialogComponent(confirmDialog) {
        this.confirmDialog = confirmDialog;
        this.attributes = {};
        this.attributes = {
            title: 'Confirm your action!',
            body: 'Are you sure you wish to do this?',
            yes: 'Yes',
            no: 'No'
        };
    }
    return ScaleitConfirmDialogComponent;
}());
exports.ScaleitConfirmDialogComponent = ScaleitConfirmDialogComponent;
