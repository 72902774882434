import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterBy', pure: true })
export class FilterByPipe implements PipeTransform {
  transform(items: any[], filter: any, value: any, reverseFilter: false): any {
    return reverseFilter
      ? items.filter((item) => item[filter] !== value)
      : items.filter((item) => item[filter] === value);
  }
}
