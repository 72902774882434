import { TrackableType } from './trackable-type';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TrackableTypeSerializer {
  fromJson(json: any): TrackableType {
    json = camelcaseKeysDeep(json);
    const trackableType = new TrackableType();
    if (!json) { return trackableType; }

    trackableType.id = json.id;
    trackableType.name = json.name;
    trackableType.active = json.active;

    return trackableType;
  }

  toJson(trackableType: TrackableType): any {
    let json = {
      id: trackableType.id,
      name: trackableType.name,
      active: trackableType.active
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
