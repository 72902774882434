"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var ImageResizer = /** @class */ (function () {
    function ImageResizer() {
    }
    ImageResizer.getResizedUrl = function (imageKey, width, height) {
        imageKey = imageKey + "?d=" + width + "x" + height;
        var time = Math.round((new Date()).getTime() / 60000);
        var cacheBuster = '?cache=' + time;
        return environment_1.environment.resizeImageServerUrl + "/" + imageKey + cacheBuster;
    };
    ImageResizer.getFullsizeUrl = function (imageKey) {
        var time = Math.round((new Date()).getTime() / 60000);
        var cacheBuster = '?cache=' + time;
        return environment_1.environment.imageServerUrl + "/" + imageKey + cacheBuster;
    };
    return ImageResizer;
}());
exports.ImageResizer = ImageResizer;
