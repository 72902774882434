"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var common_1 = require("@angular/common");
var core_2 = require("@ngx-translate/core");
// libraries
var lodash_1 = require("lodash");
var moment = require("moment");
// models
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var driver_1 = require("../driver");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
// services
var cyber_trip_service_1 = require("../../cyber-trips/cyber-trip.service");
// components
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var driver_trip_filters_dialog_component_1 = require("../driver-trip-filters-dialog/driver-trip-filters-dialog.component");
var column_toggle_component_1 = require("../../shared/column-toggle/column-toggle.component");
// constants
var columns_1 = require("./columns");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var DriverGeoTripsComponent = /** @class */ (function () {
    function DriverGeoTripsComponent(cyberTripService, translationService, dialog) {
        this.cyberTripService = cyberTripService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS.slice();
        this.appliedFilters = [];
        this.search = '';
        this.tableConfig = {
            hasHeader: true,
            pageSize: 25,
            service: cyber_trip_service_1.CyberTripService,
            filterQuery: false,
            preferenceKey: 'driver-page-geotrips',
            preferencesEnabled: true,
            query: {},
            collectionTitle: this.translationService.instant('GeoTrips'),
            sortBy: 'created_at',
            sortDirection: 'asc',
            automaticallyHidePagination: false,
            hasNoResultsAction: false,
            menuOptions: [
                {
                    name: this.translationService.instant('Edit'),
                    action: 'edit',
                    link: true,
                    external: false,
                },
            ],
        };
        this.multipleActionDropdownOptions = [
            {
                name: this.translationService.instant('Export'),
                action: 'export',
                link: false,
                url: '',
            },
        ];
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = true;
        this.errors = [];
        this.allSelected = false;
        this.allLoadedTrips = [];
        this.selectedTrips = [];
    }
    DriverGeoTripsComponent.prototype.ngOnInit = function () {
        this.tableConfig.query = __assign({}, this.tableConfig.query, { driver: this.driver.id });
    };
    DriverGeoTripsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    DriverGeoTripsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(driver_trip_filters_dialog_component_1.DriverTripFiltersDialogComponent, {
            width: '430px',
        });
        dialog.componentInstance.title =
            this.translationService.instant('Filter GeoTrips');
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
        if (startDate && startDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.startDate = new Date(startDate);
        }
        var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
        if (endDate && endDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.endDate = new Date(endDate);
        }
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'job' }), 'values');
        dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'project' }), 'values');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'customer' }), 'values');
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    DriverGeoTripsComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            job: 'jobevent__job',
            edited: 'edited',
            incomplete: 'completed',
            retake: 'retake_status',
            carrier: 'driver__carrier',
            duplicates: 'only_dupes',
            uninvoiced: 'uninvoiced',
            startDate: 'jobevent__shift1_start__gte',
            endDate: 'jobevent__shift1_start__lte',
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name']
                ? filterRes[key]['name']
                : values;
            if (typeof values === 'boolean') {
                if (key === 'incomplete' && values) {
                    displayValues = values.toString();
                    displayValues =
                        displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
                    var filterValue = (!values).toString();
                    filterValue =
                        filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                    query[queryKeys[key]] = filterValue;
                }
                else if (key === 'retake' && values) {
                    values = 'requested';
                    query[queryKeys[key]] = values;
                }
                else if (values) {
                    values = values.toString();
                    values = values.charAt(0).toUpperCase() + values.slice(1);
                    query[queryKeys[key]] = values;
                }
                displayValues = values;
            }
            else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
                if (typeof values === 'string') {
                    query[queryKeys[key]] = values;
                }
                else {
                    query[queryKeys[key]] =
                        filterRes[key] && filterRes[key].id
                            ? filterRes[key].id
                            : filterRes[key];
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var filter = new filter_option_1.FilterOption({
                filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query,
            });
            if (filter.values === 'False' || !filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    DriverGeoTripsComponent.prototype.getDuration = function (startDatetime, endDatetime) {
        if (startDatetime && endDatetime) {
            var minuteDiff = moment(endDatetime).diff(moment(startDatetime), 'minutes');
            return Math.floor(minuteDiff / 60) + 'h ' + (minuteDiff % 60) + 'm';
        }
        else {
            return 'N/A';
        }
    };
    DriverGeoTripsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedTrips();
                break;
        }
    };
    DriverGeoTripsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        this.selectedTrips = this.allSelected
            ? (this.selectedTrips = this.allLoadedTrips.filter(function (t) { return !event.exclusion.selected.some(function (ex) { return ex.id === t.id; }); }))
            : event.selection.selected.slice();
    };
    DriverGeoTripsComponent.prototype.onDataLoaded = function (_a) {
        var data = _a.data;
        this.allLoadedTrips = data.slice();
    };
    DriverGeoTripsComponent.prototype.exportSelectedTrips = function () {
        var _this = this;
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, this.selectedTrips, [], this.allSelected, this.search), params = _a.params, scope = _a.scope;
        params = params.set('driver', this.driver.id);
        this.cyberTripService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'trips',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: function () { return _this.fancyTable.deselectAll(); },
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.cyberTripService,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    return DriverGeoTripsComponent;
}());
exports.DriverGeoTripsComponent = DriverGeoTripsComponent;
