<div class="daily-board-grid-container">
  <div class="mobile-filter-select" *ngIf="device.mobile">
    <dropdown [config]="actionsDropdownConfig" [options]="filterOptions" (onSelect)="selectFilter($event.value)"
              [selectedOption]="filterOptions[0]">
    </dropdown>
  </div>

  <div class="list-table" (scroll)="onScroll($event)" [ngClass]="{grid: displayAsGrid, mobile: device.mobile}">
    <div class="table-body">
      <div id="daily-board-no-results" class="no-results mobile" *ngIf="!loading && jobEvents && jobEvents.length === 0"
        fxLayout="column" fxLayoutAlign="start center">
        <div *ngIf="!search">
          <div class="icon"><i class="icon-project"></i></div>
          <h2 translate>No Jobs</h2>
          <p translate>When you add a job you'll see it here.</p>
          <div class="actions">
            <button [routerLink]="['/jobs/new']"
                    [queryParams]="{returnTo: '/jobs/daily'}"
                    id="daily-board-no-results-new-job-button"
                    class="btn btn-primary new-job" *ngIf="canCreateJobs">
              <span class="icon-plus"></span>
              {{ 'New Job' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="search">
          <h2 translate>No Results Found</h2>
          <div class="actions">
            <button class="btn btn-primary" [ngClass]="{loading: loading}" (click)="expandSearch()" translate>
              Clear Filters
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="jobEvents && jobEvents.length">
        <div *ngFor="let jobEvent of jobEvents" (click)="selectJobEvent($event, jobEvent)" [ngClass]="{
          'selected': isSelected(jobEvent)
        }" class="row" [class.carrier]="carrierMode">
          <div class="name" title="{{ jobEvent && jobEvent.jobDisplayName }}">
            {{ jobEvent && jobEvent.jobDisplayName }}
            <job-status *ngIf="carrierMode" [status]="jobEvent.status" [statusText]="jobEvent.statusText"></job-status>
            <action-menu *ngIf="device.mobile && !carrierMode" icon="icon-more">
              <a *ngFor="let option of jobEventStatuses" class="action" mat-menu-item (click)="option.action(jobEvent)">{{ option.name }}</a>
            </action-menu>
          </div>
          <div class="job-notice" *ngIf="jobEvent.notice"
            [title]="jobEvent.notice.statusText" [ngClass]="{
            alert: jobEvent.notice.statusType === 'alert',
            sent: jobEvent.notice.statusType === 'sent'
          }">
            {{ jobEvent.notice.statusText }}
            <ng-container *ngIf="jobEvent && jobEvent.tags">
              <span *ngFor="let tag of jobEvent.tags; first as isFirst; last as isLast">
                <ng-container *ngIf="isFirst"> - </ng-container>
                {{ tag.name }}
                <ng-container *ngIf="!isLast">, </ng-container>
              </span>
            </ng-container>
          </div>
          <div class="route-map" *ngIf="jobEvent && !device.mobile">
            <ngui-map *ngIf="!jobEvent.mapImageURL" [center]="jobEvent.mapCenter" [disableDefaultUI]="true"
              [disableDoubleClickZoom]="true" [draggable]="false" [zoom]="10">
              <directions-renderer *ngIf="jobEvent.directions" [suppressMarkers]="true" [polylineOptions]="polylineOptions"
                [directions-request]="jobEvent.directions">
              </directions-renderer>
            </ngui-map>
            <img [src]="jobEvent.mapImageURL" *ngIf="jobEvent.mapImageURL" />
          </div>
          <div class="route-notice">
            <span>
              <i *ngIf="device.mobile" class="icon-locations"></i>
              {{ jobEvent.startLocationName }}
              <mat-icon title="Geofence set for start location" *ngIf="jobEvent.startLocationGeofence">layers</mat-icon>
              <mat-icon color="warn" title="Geofence missing for start location" *ngIf="!jobEvent.startLocationGeofence">layers_clear</mat-icon>
              <ng-container *ngIf="device.mobile; else desktopLocationLink">
                <span class="location-path-indicator">- - -</span>
              </ng-container>
              <ng-template #desktopLocationLink>
                to 
              </ng-template>
              <i *ngIf="device.mobile" class="icon-locations"></i>
              {{ jobEvent.endLocationName }}
              <mat-icon title="Geofence set for end location" *ngIf="jobEvent.endLocationGeofence">layers</mat-icon>
              <mat-icon color="warn" title="Geofence missing for end location" *ngIf="!jobEvent.endLocationGeofence">layers_clear</mat-icon>
            </span>
          </div>
          <div class="job-row">
            <div *ngIf="carrierMode" class="job-owner">
              {{ jobEvent.customer }}
              <div class="label" translate>Job Owner</div>
            </div>
            <div *ngIf="carrierMode" class="order-number">
              {{ jobEvent.orderNumber }}
              <div class="label" translate>Order #</div>
            </div>
            <div class="schedule field" *ngIf="!carrierMode">
              {{ jobEvent.jobStartDate | date:'EEE M/d' }} – {{ jobEvent.jobEndDate | date:'EEE M/d' }}
              <i *ngIf="jobEvent.overnight" class="icon-moon"></i>
              <div class="label" translate>Schedule</div>
            </div>
            <div *ngIf="!carrierMode" class="material field" [title]="jobEvent.material">
              {{ jobEvent.material }}
              <div class="label" translate>Material</div>
            </div>
          </div>
          <div class="job-row" *ngIf="carrierMode">
            <div class="schedule field">
              {{ jobEvent.jobStartDate | date:'EEE M/d' }} – {{ jobEvent.jobEndDate | date:'EEE M/d' }}
              <i *ngIf="jobEvent.overnight" class="icon-moon"></i>
              <div class="label" translate>Schedule</div>
            </div>
            <div *ngIf="carrierMode" class="field">
              <span *ngIf="jobEvent.shift1Start; else noDailyShift">
                {{ jobEvent.shift1Start | date: 'h:mm a' | lowercase }} - 
                <ng-container *ngIf="jobEvent.jobEnd">{{ jobEvent.jobEnd | date: 'h:mm a' | lowercase }}</ng-container>
              </span>
              <ng-template #noDailyShift>
                <span class="value" translate>None</span>
              </ng-template>
              <span class="label" translate>Daily Schedule</span>
            </div>
          </div>
          <div class="job-row material-rate-info">
            <div *ngIf="carrierMode" class="material" [title]="jobEvent.material">
              {{ jobEvent.material }}
              <div class="label" translate>Material</div>
            </div>
            <div *ngIf="carrierMode && jobEvent.status === 'accepted'" class="material" [title]="jobEvent.material">
              {{ jobEvent.stats ? (jobEvent.stats.numTrucks || 0) : (jobEvent.numTrucks || 0) }}
              <div class="label" translate>Trucks Requested</div>
            </div>
            <div *ngIf="!carrierMode" class="invoice-rate rate">
              <div class="value">{{ jobEvent.rate | ratesCurrency : jobEvent.invoiceRateUnit : 'USD' : 'symbol' : '1.2-4' }}</div>
              <div class="label" translate>Invoice Rate</div>
            </div>
            <div class="effective-rate rate">
              <div class="small-loader-container" *ngIf="statsReqLoading">
                <my-loader [small]="true"></my-loader>
              </div>
              <ng-container *ngIf="!statsReqLoading && jobEvent.stats && !carrierMode">
                <div class="value">{{ jobEvent.stats.effectiveTonRate | ratesCurrency : jobEvent.stats.totalOrderedType : 'USD' : 'symbol' : '1.2-4' }}</div>
                <div class="label" translate>Effective Rate</div>
              </ng-container>
              <div class="label" *ngIf="statsReqLoading" translate>Effective Rate</div>
            </div>
            <div class="job-event-status" *ngIf="jobEvent.cancelled && !carrierMode">
              <job-status [status]="jobEvent.status" [statusText]="jobEvent.statusText"></job-status>
            </div>
            <div class="drivers" *ngIf="!jobEvent.cancelled || (carrierMode && jobEvent.status === 'accepted')">
              <div class="value" *ngIf="isAcceptedCountRequired">{{ jobEvent.stats?.confirmedAssignmentsCount || 0 }} / {{jobEvent.stats?.assignedDriversCount || 0 }} accepted</div>
              <div class="value" *ngIf="!isAcceptedCountRequired">{{jobEvent.stats?.assignedDriversCount || 0 }} assigned</div>
              <div class="label" translate>Assignment Status</div>
            </div>
          </div>

          <div class="job-separator"></div>

          <ng-container *ngIf="carrierMode; else jobStats">
            <div class="job-row">
              <!-- <ng-container *ngIf="jobEvent.status === 'accepted'; else requestedAmount">
                <div class="large-stat">
                  <div class="value">
                    {{ jobEvent.stats && jobEvent.stats.dispatchedDriversCount }}
                  </div>
                  <div class="label" translate>Trucks Dispatched</div>
                </div> 
              </ng-container> -->
              <!-- <ng-template #requestedAmount> -->
                <div class="card-bottom">
                  <div class="large-stat">
                    <div class="value">
                      {{ jobEvent.stats ? (jobEvent.stats.requestedAmount || 0) : (jobEvent.requestedAmount || 0) }}
                    </div>
                    <div>{{jobEvent.requestedUnit?.name || '-'}}</div>
                  </div>
                  <div class="label" translate>Requested Amount</div>
                </div>
              <!-- </ng-template> -->
              
              
              <div class="truck-type rate">
                <div class="value">{{ jobEvent.allowAnyTruckType ? 'Any' : jobEvent.truckTypeNames }}</div>
                <div class="label" translate>Truck Type</div>
              </div>
              <div class="effective-rate rate">
                <div class="value">{{ jobEvent.rate | ratesCurrency : jobEvent.invoiceRateUnit : 'USD' : 'symbol' : '1.2-4' }}</div>
                <div class="label" translate>Invoice Rate</div>
              </div>
            </div>
          </ng-container>
          <ng-template #jobStats>
            <div class="job-row job-stats">
              <div class="job-dollar-amount">
                <div class="value" [ngClass]="{'zero': !(jobEvent.stats && jobEvent.stats.totalAmount)}">
                  {{ (jobEvent.stats ? jobEvent.stats.totalAmount : 0) | currency:'USD':'symbol' }}
                </div>
              </div>
              <div class="right-section">
                <div class="job-delivered-count">
                  <div class="small-loader-container" *ngIf="statsReqLoading">
                    <my-loader [small]="true"></my-loader>
                  </div>
                  <ng-container *ngIf="!statsReqLoading">
                    <div class="value" *ngIf="jobEvent.stats && jobEvent.stats.invoiceType === 'weight'">{{ jobEvent.stats.totalTons | number }}</div>
                    <div class="value" *ngIf="jobEvent.stats && jobEvent.stats.invoiceType === 'load'">{{ jobEvent.stats.totalLoads | number }}</div>
                    <div class="value" *ngIf="!jobEvent.stats || !jobEvent.stats.invoiceType || jobEvent.stats.invoiceType === 'hour'">-</div>
                  </ng-container>
                  <div class="label" translate>Delivered</div>
                </div>
                <div class="job-ordered-count">
                  <div class="value" *ngIf="jobEvent.dailyDeliveryTarget">{{ jobEvent.dailyDeliveryTarget | number }}</div>
                  <div class="value" *ngIf="!jobEvent.dailyDeliveryTarget">-</div>
                  <div class="label" translate>Ordered</div>
                </div>
              </div>
            </div>
          </ng-template>
          
          

          <div *ngIf="carrierMode && !jobEvent.cancelled" class="job-row actions">
            <ng-container *ngIf="jobEvent.status === 'accepted'; else dispatchButtons">
              <button class="btn btn-default" [routerLink]="['/jobs', jobEvent.jobId, jobEvent.id]" translate>View Job Details</button>
              <dropdown #actionsDropdown
                        title="Actions"
                        [options]="jobActionsDropdownOptions"
                        [config]="actionsDropdownConfig"
                        (onSelect)="setJobAction($event, jobEvent)"
                        class="actions-dropdown"></dropdown>
            </ng-container>
            <ng-template #dispatchButtons>
              <button class="btn btn-delete" (click)="rejectShare(jobEvent.id)" translate>Reject Job</button>
              <button class="btn btn-primary" [ngClass]="{'loading': loading}" (click)="openCarrierDispatchDialog(jobEvent)" translate>Accept & Dispatch</button>
            </ng-template>
          </div>

          <div *ngIf="!carrierMode && device.mobile" class="job-row mobile-job-links">
            <a class="action" [routerLink]="['/jobs', jobEvent.jobId, jobEvent.id]">
              <button><i class="icon-reports"></i></button>
              <label>Order Summary</label>
            </a>
            <a class="action" [routerLink]="[ '/orders', jobEvent.id, 'tickets' ]">
              <button><i class="icon-ticket"></i></button>
              <label>Ticket List</label>
            </a>
          </div>
        </div>
      </ng-container>
      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading" [ngClass]="{'full-height': jobEvents && jobEvents.length === 0}"></my-loader>
    </div>
  </div>
</div>