"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{padding:30px;position:relative}  .mat-tab-header{background:#fff;border-radius:4px 4px 0 0;border:1px solid #e6e9ec}.value.team-member-name[_ngcontent-%COMP%]{display:flex;align-items:center}.value.team-member-name[_ngcontent-%COMP%]   .user-photo[_ngcontent-%COMP%]{width:72px;margin:6px 0}.value.team-member-name[_ngcontent-%COMP%]   .user-photo[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:52px;height:52px;border-radius:50%}.value.team-member-name[_ngcontent-%COMP%]   .user-photo[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{color:#fff;font-weight:100;font-size:36px;line-height:52px;text-align:center;display:inline-block;width:52px;height:52px;background:#015bc5;border-radius:50%;vertical-align:middle}"];
exports.styles = styles;
