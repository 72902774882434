import { clone } from 'lodash';

import { Resource } from '../../shared/resource';

export class WorkOrderTemplate extends Resource {
  id: string;
  createdAt: string;
  name: string;
  html: string;
  pdf: string;

  url(action: string): string {
    if (action === 'html') {
      return this.html;
    } else if (action === 'pdf') {
      return this.pdf;
    } else {
      return `/work-orders/templates/${this.id}/${action}`;
    }
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}

