<div class="container">
  <mat-table [dataSource]="data">
    <ng-container matColumnDef="index">
      <mat-header-cell *matHeaderCellDef>{{ type | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let index = index">{{ index + 1 }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="job">
      <mat-header-cell *matHeaderCellDef translate>Job</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.jobEvent.job.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="ticketNumber">
      <mat-header-cell *matHeaderCellDef translate>Ticket Number</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.ticketNumber }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="orderNumber">
      <mat-header-cell *matHeaderCellDef translate>Order #</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.jobEvent.job.orderNumber }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="poNumber">
      <mat-header-cell *matHeaderCellDef translate>PO #</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.jobEvent.job.poNumber }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="weight">
      <mat-header-cell *matHeaderCellDef translate>Weight</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.weight }} {{ row.weightUnit }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="start">
      <mat-header-cell *matHeaderCellDef translate>{{ type | translate }} Start</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold">{{ row.startTimeTimestamp | moment: 'hh:mm a' }}</p>
        <p>{{ row.startTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="end">
      <mat-header-cell *matHeaderCellDef translate>{{ type | translate }} End</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold">{{ row.endTimeTimestamp | moment: 'hh:mm a' }}</p>
        <p>{{ row.endTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalTime">
      <mat-header-cell *matHeaderCellDef translate>Total Time</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ paySheetService.getDurationString(row.startTimeTimestamp, row.endTimeTimestamp) }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple
              class="assignment-row"></mat-row>
  </mat-table>
</div>