<form #filterTrips="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1>{{ title }}</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body driver-trip-filters-body-container">
      <div class="date-range-container">
        <div *ngIf="visibleFields.showFromDate">
          <label translate>From</label>
          <date-range-picker
            [selectedDates]="model.startDate ? [model.startDate] : []"
            (dateChanged)="onDateChanged($event, 'startDate')"
          >
          </date-range-picker>
        </div>
        <div *ngIf="visibleFields.showDateTo">
          <label translate>To</label>
          <date-range-picker
            [selectedDates]="model.endDate ? [model.endDate] : []"
            (dateChanged)="onDateChanged($event, 'endDate')"
          >
          </date-range-picker>
        </div>
      </div>
      <div class="toggle-container">
        <div *ngIf="visibleFields.showEdited">
          <label
            attr.data-label="{{ 'Edited' | translate }}"
            class="toggle-label"
          >
            <input
              type="checkbox"
              name="edited"
              [checked]="model.edited"
              [(ngModel)]="model.edited"
              class="toggle-input"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="visibleFields.showRetake">
          <label
            attr.data-label="{{ 'Retake' | translate }}"
            class="toggle-label"
          >
            <input
              type="checkbox"
              name="retake"
              [checked]="model.retake"
              [(ngModel)]="model.retake"
              class="toggle-input"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="visibleFields.showIncomplete">
          <label
            attr.data-label="{{ 'Incomplete' | translate }}"
            class="toggle-label"
          >
            <input
              type="checkbox"
              name="incomplete"
              [checked]="model.incomplete"
              [(ngModel)]="model.incomplete"
              class="toggle-input"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div *ngIf="visibleFields.showUninvoiced">
          <label
            attr.data-label="{{ 'Uninvoiced' | translate }}"
            class="toggle-label"
          >
            <input
              type="checkbox"
              name="uninvoiced"
              [checked]="model.uninvoiced"
              [(ngModel)]="model.uninvoiced"
              class="toggle-input"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="dropdown-row">
        <label translate>Job</label>
        <ruckit-dropdown
          [config]="jobDropdownConfig"
          [selectedOption]="model.job && model.job.id"
          (onSelect)="onSelect('job', $event)"
        >
        </ruckit-dropdown>
      </div>

      <div class="dropdown-row">
        <label translate>Project</label>
        <ruckit-dropdown
          [config]="projectDropdownConfig"
          [selectedOption]="model.project && model.project.id"
          (onSelect)="onSelect('project', $event)"
        >
        </ruckit-dropdown>
      </div>

      <div class="dropdown-row">
        <label translate>Customer</label>
        <ruckit-dropdown
          [config]="customerDropdownConfig"
          [selectedOption]="model.customer && model.customer.id"
          (onSelect)="onSelect('customer', $event)"
        >
        </ruckit-dropdown>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); (false)" translate>
      Filter Results
    </button>
  </div>
</form>
