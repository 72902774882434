import { EsbOrder } from './esb-order';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class EsbOrderSerializer {
  fromJson(json: any): EsbOrder {
    json = camelcaseKeysDeep(json);
    const esbOrder = new EsbOrder();
    if (!json) { return esbOrder; }

    esbOrder.id = json.id;
    esbOrder.eventKind = json.eventKind;
    esbOrder.slorderId = json.slorderId;
    esbOrder.slcustId = json.slcustId;
    esbOrder.dsorderId = json.dsorderId;
    esbOrder.dsorderDescription1 = json.dsorderDescription1;
    esbOrder.dsorderDate = json.dsorderDate;
    esbOrder.versionNo = json.versionNo;
    esbOrder.commitStatus = json.commitStatus;
    esbOrder.jobDailyDeliveryTarget = json.jobDailyDeliveryTarget;
    esbOrder.jobDeliveryInterval = json.jobDeliveryInterval;
    esbOrder.jobDeliveryIntervalUnit = json.jobDeliveryIntervalUnit;
    esbOrder.jobProjectCustomerOrganizationName = json.jobProjectCustomerOrganizationName;
    esbOrder.jobProjectCustomerOrganizationPhoneNumber = json.jobProjectCustomerOrganizationPhoneNumber;
    esbOrder.jobProjectCustomerOrganizationEmail = json.jobProjectCustomerOrganizationEmail;
    esbOrder.jobProjectOwnerOrganizationId = json.jobProjectOwnerOrganizationId;
    esbOrder.jobProjectName = json.jobProjectName;
    esbOrder.jobName = json.jobName;
    esbOrder.jobOrderNumber = json.jobOrderNumber;
    esbOrder.jobPoNumber = json.jobPoNumber;
    esbOrder.jobJobNumber = json.jobJobNumber;
    esbOrder.jobAllowAnyTruckType = json.jobAllowAnyTruckType;
    esbOrder.jobMaterial = json.jobMaterial;
    esbOrder.jobNumTrucks = json.jobNumTrucks;
    esbOrder.jobInvoiceType = json.jobInvoiceType;
    esbOrder.jobRate = json.jobRate;
    esbOrder.jobHaulType = json.jobHaulType;
    esbOrder.jobHaulRate = json.jobHaulRate;
    esbOrder.startDatetime = json.startDatetime;
    esbOrder.endDatetime = json.endDatetime;
    esbOrder.jobAmountNeeded = json.jobAmountNeeded;
    esbOrder.jobNotes = json.jobNotes;
    esbOrder.jobStartLocationId = json.jobStartLocationId;
    esbOrder.jobStartLocationName = json.jobStartLocationName;
    esbOrder.jobStartLocationStreet = json.jobStartLocationStreet;
    esbOrder.jobStartLocationCity = json.jobStartLocationCity;
    esbOrder.jobStartLocationState = json.jobStartLocationState;
    esbOrder.jobStartLocationZipcode = json.jobStartLocationZipcode;
    esbOrder.jobEndLocationId = json.jobEndLocationId;
    esbOrder.jobEndLocationName = json.jobEndLocationName;
    esbOrder.jobEndLocationStreet = json.jobEndLocationStreet;
    esbOrder.jobEndLocationCity = json.jobEndLocationCity;
    esbOrder.jobEndLocationState = json.jobEndLocationState;
    esbOrder.jobEndLocationZipcode = json.jobEndLocationZipcode;
    esbOrder.jobCheckinTicketNumber = json.jobCheckinTicketNumber;
    esbOrder.jobCheckinTicketImage = json.jobCheckinTicketImage;
    esbOrder.jobCheckinSignature = json.jobCheckinSignature;
    esbOrder.jobCheckinActiveTracking = json.jobCheckinActiveTracking;
    esbOrder.jobCheckinWeight = json.jobCheckinWeight;
    esbOrder.jobCheckoutTicketNumber = json.jobCheckoutTicketNumber;
    esbOrder.jobCheckoutTicketImage = json.jobCheckoutTicketImage;
    esbOrder.jobCheckoutSignature = json.jobCheckoutSignature;
    esbOrder.jobCheckoutActiveTracking = json.jobCheckoutActiveTracking;
    esbOrder.jobCheckoutWeight = json.jobCheckoutWeight;
    esbOrder.action = json.action;
    esbOrder.jobTruckTypes = json.jobTruckTypes;

    return esbOrder;
  }

  toJson(esbOrder: EsbOrder): any {
    let json = {
      id: esbOrder.id,
      eventKind: esbOrder.eventKind,
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
