"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var job_event_1 = require("../job-events/job-event");
var MobileDispatchShiftsComponent = /** @class */ (function () {
    function MobileDispatchShiftsComponent() {
        this.selectShift = new core_1.EventEmitter();
        this.shifts = [{ slots: [] }];
    }
    MobileDispatchShiftsComponent.prototype.ngOnInit = function () {
        this.shifts = [{ slots: [] }];
        if (this.jobEvent && this.jobEvent.shift2StartTimestamp) {
            this.shifts.push({ slots: [] });
        }
    };
    MobileDispatchShiftsComponent.prototype.select = function (shiftIndex) {
        this.selectShift.emit(shiftIndex);
    };
    return MobileDispatchShiftsComponent;
}());
exports.MobileDispatchShiftsComponent = MobileDispatchShiftsComponent;
