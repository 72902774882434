"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./split-button.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("@angular/router");
var i6 = require("../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i7 = require("@angular/material/menu");
var i8 = require("@angular/cdk/overlay");
var i9 = require("@angular/cdk/bidi");
var i10 = require("@angular/cdk/a11y");
var i11 = require("@angular/material/button");
var i12 = require("@angular/cdk/platform");
var i13 = require("@angular/platform-browser/animations");
var i14 = require("../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i15 = require("@angular/material/icon");
var i16 = require("../../../../../node_modules/@angular/material/menu/typings/index.ngfactory");
var i17 = require("./split-button.component");
var styles_SplitButtonComponent = [i0.styles];
var RenderType_SplitButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplitButtonComponent, data: {} });
exports.RenderType_SplitButtonComponent = RenderType_SplitButtonComponent;
function View_SplitButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.primaryActionClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "split-dropdown-enabled": 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "split-dropdown-enabled": 0 }), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "split-button ", _co.buttonClasses, ""); var currVal_2 = _ck(_v, 3, 0, _co.dropdownEnabled); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 5, 0, _co.dropdownEnabled); var currVal_4 = i1.ɵinlineInterpolate(1, "split-button ", _co.buttonClasses, ""); _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_5 = _co.title; _ck(_v, 6, 0, currVal_5); }); }
function View_SplitButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "split-dropdown-enabled": 0 }), i1.ɵdid(4, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { "split-dropdown-enabled": 0 }), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "split-button ", _co.buttonClasses, ""); var currVal_2 = _ck(_v, 3, 0, _co.dropdownEnabled); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.primaryRouterLink; _ck(_v, 4, 0, currVal_3); var currVal_4 = _ck(_v, 6, 0, _co.dropdownEnabled); var currVal_5 = i1.ɵinlineInterpolate(1, "split-button ", _co.buttonClasses, ""); _ck(_v, 5, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); var currVal_6 = _co.title; _ck(_v, 7, 0, currVal_6); }); }
function View_SplitButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "button", [["aria-haspopup", "true"], ["mat-icon-button", ""], ["type", "button"]], [[8, "className", 0], [1, "aria-expanded", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(1, 1196032, [[1, 4], ["splitMenuTrigger", 4]], 0, i7.MatMenuTrigger, [i8.Overlay, i1.ElementRef, i1.ViewContainerRef, i7.MAT_MENU_SCROLL_STRATEGY, [2, i7.MatMenu], [8, null], [2, i9.Directionality], i10.FocusMonitor], { menu: [0, "menu"] }, null), i1.ɵdid(2, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i10.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_4 = i1.ɵnov(_v.parent, 8); _ck(_v, 1, 0, currVal_4); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "control-button ", _co.controlButtonClasses, ""); var currVal_1 = (i1.ɵnov(_v, 1).menuOpen || null); var currVal_2 = (i1.ɵnov(_v, 2).disabled || null); var currVal_3 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 4).inline; var currVal_6 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_5, currVal_6); var currVal_7 = _co.icon; _ck(_v, 5, 0, currVal_7); }); }
function View_SplitButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { splitMenuTrigger: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "split-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplitButtonComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplitButtonComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "mat-menu", [], null, null, null, i16.View_MatMenu_0, i16.RenderType_MatMenu)), i1.ɵprd(6144, null, i7.ɵf24, null, [i7.MatMenu]), i1.ɵdid(8, 1294336, [["splitDropdownMenu", 4]], 2, i7.MatMenu, [i1.ElementRef, i1.NgZone, i7.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 2, { items: 1 }), i1.ɵqud(335544320, 3, { lazyContent: 0 }), i1.ɵncd(0, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SplitButtonComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.primaryRouterLink; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.primaryRouterLink; _ck(_v, 5, 0, currVal_1); _ck(_v, 8, 0); var currVal_2 = _co.dropdownEnabled; _ck(_v, 13, 0, currVal_2); }, null); }
exports.View_SplitButtonComponent_0 = View_SplitButtonComponent_0;
function View_SplitButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "split-button", [], null, null, null, View_SplitButtonComponent_0, RenderType_SplitButtonComponent)), i1.ɵdid(1, 114688, null, 0, i17.SplitButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SplitButtonComponent_Host_0 = View_SplitButtonComponent_Host_0;
var SplitButtonComponentNgFactory = i1.ɵccf("split-button", i17.SplitButtonComponent, View_SplitButtonComponent_Host_0, { title: "title", disabled: "disabled", primaryRouterLink: "primaryRouterLink", icon: "icon", dropdownEnabled: "dropdownEnabled", buttonClasses: "buttonClasses", controlButtonClasses: "controlButtonClasses", primaryActionOpenMenu: "primaryActionOpenMenu" }, { onPrimaryAction: "onPrimaryAction" }, ["*"]);
exports.SplitButtonComponentNgFactory = SplitButtonComponentNgFactory;
