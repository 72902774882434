import * as moment from 'moment';
import { uniq } from 'lodash';

import { Job } from '../jobs/job';
import { TruckType } from '../trucks/truck-type';
import { JobSerializer } from '../jobs/job.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Marketplace {
  id: string;
  job: Job;
  truckTypes: TruckType[];
  dailyDeliveryTarget: string;
  numTrucks: string;
  deliveryInterval: string;
  haulRate: string;
  shift1Start: string;
  shift1End: string;
  shift1Status: string;
  shift2Start: string;
  shift2End: string;
  shift2Status: string;
  lastDispatchedBy: string;
  lastDispatchedTime: string;
  estimatedNumberOfLoads: string;
  assignmentsCount: string;
  estimatedNumberTons: string;
  allowAnyTruckType: boolean;
  marketInvoiceRate: string;
  marketNumTrucks: string;
  accepted: boolean;
  claimedTruckSlots: string;
  notes: string;

  constructor(marketplaceInfo: any) {
    marketplaceInfo = camelcaseKeysDeep(marketplaceInfo);

    this.id = marketplaceInfo.id;
    if (marketplaceInfo.job) {
      this.job = new JobSerializer().fromJson(marketplaceInfo.job);
    }
    this.truckTypes = marketplaceInfo.truckTypes;
    this.dailyDeliveryTarget = marketplaceInfo.dailyDeliveryTarget;
    this.numTrucks = marketplaceInfo.numTrucks;
    this.deliveryInterval = marketplaceInfo.deliveryInterval;
    this.haulRate = marketplaceInfo.haulRate;
    this.shift1Start = marketplaceInfo.shift1Start;
    this.shift1End = marketplaceInfo.shift1End;
    this.shift1Status = marketplaceInfo.shift1Status;
    this.shift2Start = marketplaceInfo.shift2Start;
    this.shift2End = marketplaceInfo.shift2End;
    this.shift2Status = marketplaceInfo.shift2Status;
    this.lastDispatchedBy = marketplaceInfo.lastDispatchedBy;
    this.lastDispatchedTime = marketplaceInfo.lastDispatchedTime;
    if (marketplaceInfo.estimatedNumberOfLoads && marketplaceInfo.estimatedNumberOfLoads > 0) {
      this.estimatedNumberOfLoads = marketplaceInfo.estimatedNumberOfLoads;
    } else {
      this.estimatedNumberOfLoads = '';
    }
    this.assignmentsCount = marketplaceInfo.assignmentsCount;
    this.estimatedNumberTons = marketplaceInfo.estimatedNumberTons;
    this.allowAnyTruckType = marketplaceInfo.allowAnyTruckType;
    this.marketInvoiceRate = marketplaceInfo.marketInvoiceRate;
    this.marketNumTrucks = marketplaceInfo.marketNumTrucks;
    this.accepted = marketplaceInfo.accepted;
    this.claimedTruckSlots = marketplaceInfo.claimedTruckSlots;
    this.notes = marketplaceInfo.notes;
  }

  get truckTypeNames(): string {
    let names = [];
    if (this.allowAnyTruckType) {
      names = ['Any Type'];
    } else if (this.truckTypes) {
      names = this.truckTypes.map((truckType) => {
        return truckType.name;
      });
    }

    return names.filter(Boolean).join(', ');
  }

  get shift1StartTime(): string {
    let d = new Date(this.shift1Start);
    return moment(d).format('h:mm a');
  }

  get shift1EndTime(): string {
    let d = new Date(this.shift1End);
    return moment(d).format('h:mm a');
  }

  get shift2StartTime(): string {
    let d = new Date(this.shift2Start);
    return moment(d).format('h:mm a');
  }

  get shift2EndTime(): string {
    let d = new Date(this.shift2End);
    return moment(d).format('h:mm a');
  }

  get endTime(): string {
    if (this.shift2End) {
      return this.shift2EndTime;
    } else {
      return this.shift1EndTime;
    }
  }

  get endDate(): string {
    if (this.shift2End) {
      return this.shift2End;
    } else {
      return this.shift1End;
    }
  }

  get date(): string {
    let d = new Date(this.shift1Start);
    let today = new Date();
    if (today.toDateString() === d.toDateString()) {
      return 'Today';
    } else {
      return moment(d).format('dddd, MMMM Do');
    }
  }

  get dateRange(): string {
    let range = [];
    let startDate = new Date(this.shift1Start);
    let endDate = new Date(this.endDate);
    if (startDate) { range.push(moment(startDate).format('dddd, MMMM Qo')); }
    if (endDate) { range.push(moment(endDate).format('dddd, MMMM Qo')); }

    return uniq(range.filter(Boolean)).join(' - ');
  }

  get customer(): string {
    if (this.job && this.job.project && this.job.project.customerOrganization) {
      return this.job.project.customerOrganization.name;
    } else {
      return '';
    }
  }

  get checkinOptions(): any {
    let checkinOptions = this.job.checkinOptions;
    let options = [];
    for (let option in checkinOptions) {
      if (checkinOptions[option] === true) {
        options.push(option);
      }
    }
    if (options.length === 0) { options.push('None'); }
    return options;
  }

  get checkoutOptions(): any {
    let checkoutOptions = this.job.checkoutOptions;
    let options = [];
    for (let option in checkoutOptions) {
      if (checkoutOptions[option] === true) {
        options.push(option);
      }
    }
    if (options.length === 0) { options.push('None'); }
    return options;
  }

  get startLocation(): string {
    let value = '';
    let location = this.job.startLocation;
    if (this.job && this.job.startLocation) {
      value  = location.name + '<br />';
      value += location.street + '<br />';
      value += location.city + ', ' + location.state + ' ' + location.zipcode + '<br />';
    }

    return value;
  }

  get endLocation(): string {
    let value = '';
    let location = this.job.endLocation;
    if (this.job && this.job.endLocation) {
      value  = location.name + '<br />';
      value += location.street + '<br />';
      value += location.city + ', ' + location.state + ' ' + location.zipcode + '<br />';
    }

    return value;
  }
}
