import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { ExpenseService } from './expense.service';
import { OrganizationService } from '../organizations/organization.service';
import { parseErrors } from '../shared/api.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'ruckit-edit-expense',
  templateUrl: './edit-expense.component.html',
  styleUrls: ['./edit-expense.component.scss'],
  providers: [ExpenseService, OrganizationService]
})
export class EditExpenseComponent implements OnInit {
  expense: any;
  loading = true;
  editing = false;
  errors = [];
  search;
  confirmDialog: MatDialogRef<any>;
  createdAt: Date;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private expenseService: ExpenseService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.params
      .subscribe((params: Params) => this.getExpense(params['id']));
  }

  getExpense(id) {
    if (id) {
      this.expenseService.getExpense(id).subscribe((res) => {
        this.expense = res;
        let _createdAt = this.expense.createdAt && new Date(this.expense.createdAt);
        if (_createdAt) {
          this.createdAt = _createdAt;
        }
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
      });
    }
  }

  updateExpense(update = false) {
    if (update) { this.getExpense(this.expense.id); }
  }

  fileChange(e) {
    console.log('photo event triggered');
    console.log(e);
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (_e) => {
      this.expense.ownerOrganization.image = _e.target['result'];
    };
    reader.readAsDataURL(file);
    this.organizationService.saveOnlyImage(this.expense.ownerOrganization.id, file).subscribe((organization) => {
      this.save();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  save() {
    this.loading = true;
    this.expenseService.save(this.expense).subscribe(() => {
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  submit() {
    this.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Send Expense?',
      body: 'Are you sure you want to send this expense? You will no longer be able to edit the expense once it has been sent.',
      close: 'Cancel',
      accept: 'Send'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.expenseService.save(this.expense).subscribe(() => {
          this.expenseService.send(this.expense).subscribe(() => {
            this.loading = false;
            this.router.navigate(['/expenses/filter/paid']);
          }, (err) => {
            this.errors = parseErrors(err);
            this.loading = false;
          });
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
      this.confirmDialog = null;
    });
  }

  onDateChanged(values: Date[]) {
    if (values && values.length) {
      this.createdAt = values[0];
      this.expense.createdAt = values[0];
    }
  }

  changeSearch(term?: string) {
    this.search = term || '';
  }
}
