"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".job-production[_ngcontent-%COMP%]{margin-top:40px;border-top:1px solid rgba(230,234,238,.6);padding-top:25px;position:relative}.job-production[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]{font-size:18px;color:#354052;margin-bottom:25px}.job-production[_ngcontent-%COMP%]   .job-amount[_ngcontent-%COMP%]{font-size:40px;font-weight:300;color:#002649;margin-bottom:5px}.job-production[_ngcontent-%COMP%]   .job-loads[_ngcontent-%COMP%]{font-size:14px;color:#7f8fa4;margin-bottom:25px}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   .job-value[_ngcontent-%COMP%]{font-size:40px;font-weight:300;color:#002649}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   .actual-values[_ngcontent-%COMP%]{font-size:14px;color:#354052;margin-top:10px}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   .estimated-values[_ngcontent-%COMP%]{font-size:12px;color:#7f8fa4}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   .dispatch-count[_ngcontent-%COMP%]{font-size:14px;color:#354052;margin-top:10px}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{flex-grow:1;flex-basis:0}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{font-size:16px;font-weight:600;color:#354052}.job-production[_ngcontent-%COMP%]   .production-stats[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{font-size:14px;color:#7f8fa4}"];
exports.styles = styles;
