"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Resource = /** @class */ (function () {
    function Resource() {
        this.loading = false;
        this.selected = false;
        this.classes = '';
    }
    return Resource;
}());
exports.Resource = Resource;
