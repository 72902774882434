<div class="container collaborators-list" [ngClass]="{'with-records': withRecords}">
  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="actions-wrapper">
    <form #collaboratorForm="ngForm" (keydown.enter)="$event.preventDefault()">
      <fancy-table *ngIf="jobEvent" #fancyTable
                   [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   [config]="tableConfig"
                   (columnChange)="columnsChanged($event)"
                   (selectionChanged)="selectionChanged($event)"
                   (onDataLoaded)="mapCustomFields($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value organization-name" *ngSwitchCase="'organization'">
              <div class="name" [title]="row.organization" [ngClass]="{leased: row.organizationLeasedOrg}">
                <div class="title">
                  {{ row.organization || '&nbsp;' }}
                  <span class="tag" *ngIf="row.organizationLeasedOrg">Leased</span>
                </div>
                <div class="label-{{ row.status === 'expired'? 'not-shared' : row.status }}">
                  {{ row.status === 'expired'? 'No Days Shared' : row.status}}
                </div>
              </div>
            </div>
            <div class="value days-allowed" *ngSwitchCase="'days-allowed'">
              <button (click)="openExtendCollaboration(row); false"
                      class="calendar-button btn btn-default"
                      [disabled]="row.editing" name="dates[{{row.id}}]">
                <div class="calendar-icon"></div> {{ row.dateRange }}
              </button>
            </div>

            <ng-container *ngIf="!hasLoadListsEnabled">
              <div class="value requested-amount-cell" *ngSwitchCase="'requested-amount'">
                <div class="input-group requested-amount">
                  <input 
                    required 
                    [disabled]="!row.editing" 
                    type="text"
                    numberInRange 
                    minValue=0
                    name="requested_amount[{{row.id}}]"
                    [(ngModel)]="row.requestedAmount" 
                    />
                  <dropdown
                    [options]="unitsOfMeasure"
                    [disabled]="!row.editing || row.readonly"
                    [selectedOption]="row.requestedUnit"
                    (onSelect)="setRequestedUnit($event, row)"></dropdown>
                </div>
              </div>
              <div class="value dispatched-trucks dispatched" *ngSwitchCase="'dispatched-trucks'"
                  [ngClass]="{'below-quota': row.dispatched < row.requestedAmount}">
                {{ row.dispatched }}
              </div>
              <div class="value confirmed-amount" *ngSwitchCase="'confirmed-amount'">
                {{ row.confirmedAmount }}
              </div>
            </ng-container>

            <!-- load list stuff -->
            <ng-container *ngIf="hasLoadListsEnabled">
              <div
                class="value requested-loads-cell"
                *ngSwitchCase="'requested-loads'"
              >
                <div
                  class="dropdown"
                  [ngClass]="{ disabled: !row.editing || row.readonly }"
                >
                  <button
                    class="dropdown-button"
                    [matMenuTriggerFor]="loads"
                    [disabled]="!row.editing || row.readonly"
                  >
                    <ng-container *ngIf="row.loadSchedule && row.loadSchedule.length">
                      <ng-container>
                        <span translate>Loads:</span>
                        <ng-container *ngFor="let load of row.loadSchedule; let i = index">
                          {{ i === 0 ? load.loadNumber : ', ' + load.loadNumber }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!row.loadSchedule || row.loadSchedule.length === 0">
                      <span translate>None Selected</span>
                    </ng-container>
                  </button>
                  <mat-menu
                    #loads="matMenu"
                    class="loadlist-dropdown-menu"
                    [overlapTrigger]="false"
                  >
                    <div
                      *ngFor="let load of loadList"
                      class="loadlist-load-option"
                      (click)="onLoadlistDropdownClick($event, load, row)"
                    >
                      <mat-checkbox
                        (change)="loadDropdownSelectionChanged($event, load, row)"
                        [checked]="isLoadScheduled(row.loadSchedule, load.loadNumber)"
                        translate
                      >
                        {{ load.loadNumber }} &nbsp;&nbsp; Load Time -
                        {{ load.ticketEvents.LOADING_STARTED | moment : 'h:mm a' }}
                      </mat-checkbox>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </ng-container>
            <!-- end load list stuff -->
            
            <div class="value rate" *ngSwitchCase="'rate'">
              <div *ngIf="row.multipliers.length === 0">
                <div class="input-group" data-prefix="$">
                  <input required type="number" min="0"
                         name="invoice_rate[{{row.id}}]"
                         [disabled]="!row.editing || row.readonly"
                         [(ngModel)]="row.invoiceRate" />
                  <dropdown *ngIf="row.invoiceType === 'weight'"
                            [options]="row.weightOptions"
                            [disabled]="!row.editing || row.readonly"
                            [selectedOption]="row.weightOption"
                            (onSelect)="setSelectedRate($event, row)"></dropdown>
                  <div class="add-on" [ngClass]="{disabled: !row.editing}"
                       *ngIf="row.invoiceType !== 'weight'">{{row.invoiceType | titlecase}}</div>
                </div>
              </div>
              
              <div *ngIf="row.multipliers.length > 0">
                <div class="percentage-input" *ngFor="let multiplier of row.multipliers; index as i;">
                  <div class="label" translate>{{ multiplier.truckTypeName !== null ? multiplier.truckTypeName : 'Default' }} Rate
                  </div>
                  <div class="input-group percent" data-prefix="">
                    <input required [disabled]="!row.editing || row.readonly" type="number"
                      name="haul_percentage[{{ i }}]" [(ngModel)]="multiplier.haulPercentage" min="0" />
                    <div class="add-on">percentage</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value rate-tracking" *ngSwitchCase="'rate-tracking'">
              <div class="rate-tracking" [ngClass]="{ hidden : row.multipliers && row.multipliers.length > 0 }">
                <input type="hidden" required [(ngModel)]="row.invoiceType"
                       name="rateTrackingOptions[{{row.id}}]" />
                <dropdown class="rate-tracking-dropdown" required
                          [options]="row.rateTrackingOptions"
                          [config]="rateTrackingConfig"
                          [disabled]="!row.editing || row.readonly"
                          [selectedOption]="row.invoiceTypeOption"
                          (onSelect)="setSelectedAction(row, $event, collaboratorForm)">
                </dropdown>
              </div>
            </div>
            <div class="value broker-rate-code" *ngSwitchCase="'broker-rate-code'">
              <div class="broker-rate-code" *ngIf="brokerRateCodeKey">
                <input name="broker_rate_code[{{row.id}}]" [disabled]="!row.editing || row.readonly" [(ngModel)]="row.brokerRateCode" />
              </div>
            </div>
            <div class="value notes" *ngSwitchCase="'notes'">
              <div *ngIf="!row.editing">{{ row.notes }}</div>
              <div *ngIf="row.editing">
                <textarea 
                  [disabled]="!row.editing || row.readonly"
                  [(ngModel)]="row.notes"
                  name="organization_notes[{{row.id}}]">
                </textarea>
              </div>
            </div>
            <div class="value notes" *ngSwitchCase="'internal-notes'">
              <ng-container *ngIf="userOrgId && jobOrgId && userOrgId === jobOrgId">
                <div *ngIf="!row.editing">{{ row.internalNotes }}</div>
                <div *ngIf="row.editing">
                  <textarea 
                    [disabled]="!row.editing || row.readonly"
                    [(ngModel)]="row.internalNotes"
                    name="internal_notes[{{row.id}}]">
                  </textarea>
                </div>
              </ng-container>
            </div>
            <div class="form-actions" *ngSwitchCase="'form-actions'">
              <split-button title="Edit" *ngIf="!row.editing"
                            buttonClasses="green-button"
                            controlButtonClasses="green-button"
                            (onPrimaryAction)="toggleEditAction(row)">
                <button class="delete" [disabled]="row.status !== 'pending'" (click)="deleteCollaborator(row)" translate>Remove</button>
              </split-button>

              <split-button title="Today + Future Days" *ngIf="row.editing"
                            buttonClasses="green-button"
                            controlButtonClasses="green-button"
                            [disabled]="collaboratorForm.form.pristine || !collaboratorForm.form.valid || row.loading || !(row.requestedAmount > 0)"
                            (onPrimaryAction)="submit(row, 'future')">
                <a translate class="update-all-days" (click)="submit(row, 'all'); false"
                  [ngClass]="{
                    loading: row.loading,
                    disabled: collaboratorForm.form.pristine || !collaboratorForm.form.valid || row.loading || !(row.requestedAmount > 0)
                  }">
                  Update All Days
                </a>
                <a translate class="update-today-only" (click)="submit(row); false"
                  [ngClass]="{
                    loading: row.loading,
                    disabled: collaboratorForm.form.pristine || !collaboratorForm.form.valid || row.loading || !(row.requestedAmount > 0)
                  }">
                  Today Only
                </a>
                <a translate class="update-today-and-future" (click)="toggleEditAction(row)">
                  Cancel
                </a>
              </split-button>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Collaborators</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>

        <filters-panel [(availableFilters)]="availableFilters"
                       [(appliedFilters)]="appliedFilters"
                       [(search)]="search"></filters-panel>

        <action-menu icon="icon-more" [disabled]="actionMenuDisabled">
          <ng-container *ngFor="let option of multipleActionDropdownOptions">
            <a *ngIf="option.link" mat-menu-item [routerLink]="option.url">{{ option.name }}</a>
            <a *ngIf="!option.link" mat-menu-item (click)="setSelectedBulkAction(option)">{{ option.name }}</a>
          </ng-container>
        </action-menu>

        <a mat-icon-button color="accent" class="add-button" *ngIf="jobEvent.canShare" (click)="openAddCollaborators()">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </form>
  </div>
</div>
