import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { MatDialog } from '@angular/material';

import { ApiService } from '../shared/api.service';
import { OrganizationService } from './organization.service';
import { TruckTypeService } from '../trucks/truck-type.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'ruckit-edit-organization-permissions',
  templateUrl: './edit-organization-permissions.component.html',
  styleUrls: ['./edit-organization-permissions.component.scss'],
  providers: [OrganizationService, ApiService, TruckTypeService]
})
export class EditOrganizationPermissionsComponent implements OnInit {
  @Input() organization: any;
  loading = true;
  errors = [];
  model = {
    driverFleetCanViewRates: false,
    driverFleetQuickTicket: false,
    dvirRequired: false,
    driverFleetRequireAssignmentConfirmation: false,
    avoidTolls: false,
    qrEnabled: false
  };
  truckSettings = [];
  columnLength: Number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private organizationService: OrganizationService,
    private truckTypeService: TruckTypeService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loading = false;
    this.model.driverFleetCanViewRates = this.organization.driverFleetCanViewRates;
    this.model.driverFleetQuickTicket = this.organization.driverFleetQuickTicket;
    this.model.dvirRequired = this.organization.dvirRequired;
    this.model.driverFleetRequireAssignmentConfirmation = this.organization.driverFleetRequireAssignmentConfirmation;
    this.model.avoidTolls = this.organization.avoidTolls;
    this.model.qrEnabled = this.organization.qrEnabled;
    this.setUpTruckTypes();
  }

  savePermissions() {
    this.loading = true;
    let _organization = Object.assign({ id: this.organization.id }, this.model);
    this.organizationService.save(_organization).subscribe(() => {
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  setUpTruckTypes() {
    this.truckTypeService.list({
      ordering: 'name',
      page_size: 100
    }).subscribe(truckTypes => {
      this.truckSettings = truckTypes;
      this.columnLength = Math.ceil(truckTypes.length / 2);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  saveTruckTypePreferences() {
    this.loading = true;
    let _truckSettings = this.truckSettings.map(setting => {
      return {
        truckType: setting.id,
        isFavorite: setting.isFavorite,
        averageTonnage: setting.averageTonnage
      };
    });
    this.truckTypeService.updateTruckTypes(_truckSettings).subscribe(() => {
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selectAll(form: NgForm, bool) {
    form.control.markAsDirty();
    this.truckSettings.forEach(setting => setting.isFavorite = bool );
  }
}
