"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var ConfirmDriversDialogComponent = /** @class */ (function () {
    function ConfirmDriversDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.title = 'One Or More Drivers Are Busy';
        this.drivers = [];
    }
    ConfirmDriversDialogComponent.prototype.submit = function () {
        this.dialogRef.close();
        this.callback(this.shift, this.slot);
    };
    return ConfirmDriversDialogComponent;
}());
exports.ConfirmDriversDialogComponent = ConfirmDriversDialogComponent;
