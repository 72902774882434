"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var locationUpdate_1 = require("../jobs/locationUpdate");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var LocationUpdates = /** @class */ (function () {
    function LocationUpdates(data) {
        data = camelcaseKeysDeep(data);
        this.locationUpdates = data.locationUpdates && data.locationUpdates.map(function (locationUpdate) {
            return new locationUpdate_1.LocationUpdate(locationUpdate);
        });
        this.paverLocationUpdates = data.paverLocationUpdates && data.paverLocationUpdates.map(function (locationUpdate) {
            return new locationUpdate_1.LocationUpdate(locationUpdate);
        });
        this.locations = data.locations;
        this.routes = data.routes;
    }
    return LocationUpdates;
}());
exports.LocationUpdates = LocationUpdates;
