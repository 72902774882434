<form #forgotPassword="ngForm">
  <div class="logo-container">
    <img
      class="logo"
      src="../../assets/img/command-alkon-logo.svg"
      alt="Command Alkon"
    />
  </div>
  <h1 translate>Password Recovery</h1>
  
  <div mat-dialog-content class="form-content">
    <p translate>
      Enter your Integrated Trucking username below, and we'll send you instructions on how to update your password.
    </p>

    <mat-form-field appearance="outline" class="login-mat-input">
      <mat-label translate>Email/Username</mat-label>

      <input 
        matInput 
        required
        type="text" 
        name="email" 
        [(ngModel)]="model.email"
        [attr.aria-label]="'Email/Username' | translate"
      />
    </mat-form-field>

    <notification *ngFor="let error of errors">{{error}}</notification>

    <div class="buttons-container">    
      <button
        [type]="button.type"
        class="btn btn-primary"
        aria-label="Send email"
        [disabled]="!forgotPassword.form.valid || loading || button.disabled"
        [ngClass]="{ loading: loading }"
        (click)="forgotPassword.form.valid && submit(); false"
        translate
      >
        {{button.title}}
      </button>

      <button
        type="reset"
        class="btn btn-outline"
        aria-label="Back to login"
        translate
        (click)="close()"
      >
        <mat-icon>arrow_back</mat-icon>
        Back to Login
      </button>
    </div>
  </div>
</form>
