import { find as _find, reject } from 'lodash';
import {
  Component, ElementRef, Input, Output, EventEmitter, ViewChild, OnDestroy,
  AfterContentInit
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

import { CollaboratorService } from './collaborator.service';
import { parseErrors } from '../shared/api.service';
import { JobEventService } from '../job-events/job-event.service';
import { JobEvent } from '../job-events/job-event';
import { DropdownComponent } from '../shared';

@Component({
  selector: 'extend-collaboration',
  templateUrl: './extend-collaboration-dialog.component.html',
  styleUrls: ['./extend-collaboration-dialog.component.scss']
})
export class ExtendCollaborationDialogComponent implements AfterContentInit, OnDestroy {
  @Input() collaborator: any = {};
  @Input() jobEvent: JobEvent;
  @Output() completed = new EventEmitter();
  jobEventsDropdownConfig = {
    multiselect: true,
    loadingOptions: true,
    showLoading: true
  };
  loading = false;
  errors = [];
  jobEvents: JobEvent[] = [];
  callback: Function;
  collaboratorReq: Subscription;
  jobEventsReq: Subscription;
  model = {
    jobevents: [],
    numTrucks: null,
    recipientOrganization: null
  };
  @ViewChild('jobEventsDropdown', { static: true }) jobEventsDropdown: DropdownComponent;

  constructor(
    public dialogRef: MatDialogRef<ExtendCollaborationDialogComponent>,
    private collaboratorService: CollaboratorService,
    private jobEventService: JobEventService,
    private elementRef: ElementRef
  ) { }

  ngAfterContentInit() {
    this.getJobEvents();
  }

  ngOnDestroy() {
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
  }

  submit(): void {
    this.loading = true;
    this.model.jobevents = this.model.jobevents.map(j => j.id);
    this.model.recipientOrganization = this.collaborator.organizationId;
    this.model.numTrucks = this.collaborator.numTrucks;

    this.collaboratorService.updateDays(this.jobEvent.id, this.model).subscribe(result => {
      this.callback(this.collaborator);
      this.dialogRef.close();
      this.loading = false;
    }, (err) => {
      this.errors = ['Collaboration could not be updated.'];
      this.errors.push(parseErrors(err));
      this.loading = false;
    });
  }

  getJobEvents(query = {}): void {
    this.loading = true;
    if (this.jobEventsDropdown) { this.jobEventsDropdown.loading = true; }
    this.jobEventsDropdownConfig.loadingOptions = true;
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }

    if (this.jobEventsDropdown) { this.jobEventsDropdown.selectedItems = []; }
    this.jobEventsReq = this.jobEventService.getJobEvents({
      ordering: 'shift1_start',
      job: this.jobEvent && this.jobEvent.job && this.jobEvent.job.id,
      collaborator: this.collaborator && this.collaborator.organizationId,
      ...query
    }).subscribe(jobEvents => {
      let selectedJobEvents = [];
      this.jobEvents = jobEvents;
      if (this.jobEvent) {
        let jobEvent = _find(this.jobEvents, { id: this.jobEvent.id });
        if (jobEvent) {
          this.jobEvents = reject(this.jobEvents, jobEvent);
        } else {
          jobEvent = this.jobEvent;
        }
        this.jobEvents.unshift(jobEvent);
        selectedJobEvents.push(jobEvent);
        this.loading = false;
      }
      const sharedJobEvents = reject(this.jobEvents, { isShared: false });
      if (this.jobEventsDropdown) {
        this.jobEventsDropdown.selectedItems = selectedJobEvents.concat(sharedJobEvents);
        this.model.jobevents = this.jobEventsDropdown.selectedItems;
      } else {
        this.model.jobevents = selectedJobEvents;
      }
      if (this.jobEventsDropdown) { this.jobEventsDropdown.loading = false; }
      this.jobEventsDropdownConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.loading = false;
      if (this.jobEventsDropdown) { this.jobEventsDropdown.loading = false; }
      this.jobEventsDropdownConfig.loadingOptions = false;
    });
  }

  selectJobEvent(jobEvents: JobEvent[]): void {
    this.model.jobevents = jobEvents;
  }

  dropdownNextPage(event: MouseEvent): void {
    if (!this.jobEventsDropdownConfig.loadingOptions) {
      let request = this.jobEventService.listNext();
      if (request) {
        if (this.jobEventsDropdown) { this.jobEventsDropdown.loading = true; }
        this.jobEventsDropdownConfig.loadingOptions = true;
        this.jobEventsReq = request.subscribe(jobEvents => {
          this.jobEvents = this.jobEvents.concat(jobEvents);
          if (this.jobEventsDropdown) { this.jobEventsDropdown.loading = false; }
          this.jobEventsDropdownConfig.loadingOptions = false;
        }, err => {
          this.errors = err;
          if (this.jobEventsDropdown) { this.jobEventsDropdown.loading = false; }
          this.jobEventsDropdownConfig.loadingOptions = false;
        });
      }
    }
  }
}
