import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { remove, find as _find } from 'lodash';

import { PunchCardService } from './punch-card.service';
import { JobService } from '../jobs/job.service';
import { DriverService } from '../drivers/driver.service';
import { TruckService } from '../trucks/truck.service';

@Component({
  selector: 'completed-punch-cards',
  templateUrl: './completed-punch-cards.component.html',
  styleUrls: ['./completed-punch-cards.component.scss'],
  providers: [PunchCardService, JobService, DriverService, TruckService]
})
export class CompletedPunchCardsComponent implements OnInit {
  @Input() jobEvent: any;
  punchCards: any = [];
  expandedPunchCards = [];
  loading = true;
  errors = [];
  menuOptions = [
    { name: 'Edit', action: 'edit', link: true },
    { name: 'Unvoid', action: 'unvoid', link: false },
    { name: 'Void', action: 'void', link: false }
  ];
  punchCardReq;
  confirmDialog: MatDialogRef<any>;
  viewSignaturesCallback = () => {
    // Update Signature Status Icon
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private punchCardService: PunchCardService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getPunchCards();
  }

  onScroll(e) {
    if (!this.loading &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.punchCardService.getNextCondensed();
      if (o) {
        this.loading = true;
        o.subscribe(
          punchCards => {
            this.punchCards = this.punchCards.concat(punchCards);
            this.loading = false;
          },
          err => this.errors = err,
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getPunchCards() {
    this.loading = true;

    if (this.punchCardReq) {
      this.punchCardReq.unsubscribe();
    }

    this.punchCardReq = this.punchCardService.getPunchCards({
      jobevent: this.jobEvent && this.jobEvent.id,
      end_time__isnull: 'False',
    }).subscribe(
      punchCards => {
        this.punchCards = punchCards;
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  menuAction(name, punchCard) {
    switch (name) {
      case 'void':
        // this.voidTrip(trip);
        break;
      case 'unvoid':
        // this.unvoidTrip(trip);
        break;
    }
  }

  expandPunchCard(punchCard) {
    if (_find(this.expandedPunchCards, { id: punchCard.id })) {
      remove(this.expandedPunchCards, { id: punchCard.id });
    } else {
      this.expandedPunchCards.push(punchCard);
    }
  }

  expandedPunchCard(punchCard): boolean {
    if (_find(this.expandedPunchCards, { id: punchCard.id })) {
      return true;
    }
    return false;
  }
}
