"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var tag_service_1 = require("../tags/tag.service");
var organization_market_dialog_component_1 = require("./organization-market-dialog.component");
var index_1 = require("../shared/dialogs/index");
var api_service_1 = require("../shared/api.service");
var EditOrganizationMarketsComponent = /** @class */ (function () {
    function EditOrganizationMarketsComponent(route, tagService, translationService, dialog) {
        var _this = this;
        this.route = route;
        this.tagService = tagService;
        this.translationService = translationService;
        this.dialog = dialog;
        // NOTE: Make sortable to true after sorting support has been added to API
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: this.translationService.instant('Name'), sortable: false, sortBy: 'name' },
            { key: 'itemCount', title: this.translationService.instant('# Tagged'), sortable: false, sortBy: 'item_count' },
            { key: 'notes', title: this.translationService.instant('Notes'), sortable: false, sortBy: 'notes' },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'name', 'itemCount', 'notes', 'action'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        // config for fancy table
        this.marketTableConfig = {
            hasHeader: true,
            service: tag_service_1.TagService,
            preferenceKey: 'EditOrganizationMarketsComponent-TagService',
            query: {},
            collectionTitle: this.translationService.instant('Markets'),
            noResultsText: this.translationService.instant('a market'),
            newRecordModal: function () { _this.openNewOrganizationMarket(); },
            sortBy: '',
            sortDirection: 'asc',
            customHeight: true,
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false },
            ]
        };
        this.orgMarkets = [];
        this.loading = true;
        this.errors = [];
        this.count = 0;
        this.newOrganizationMarketCallback = function (e) {
            _this.refreshTableData();
        };
    }
    EditOrganizationMarketsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.loading = true;
        });
    };
    /**
    * @param  {} event
    * This function would get called on the click of row of the table
    * On click of row it will open a dialog box for editing the market.
    */
    EditOrganizationMarketsComponent.prototype.clickAction = function (event) {
        if (event) {
            this.editOrganizationMarket(event[1]);
        }
    };
    /**
     * @param  {} appliedFilters
     * Used for reseting the filters or when filter is changed
     */
    EditOrganizationMarketsComponent.prototype.filtersModified = function (appliedFilters) {
        // NOTE: API does not support filter for now
    };
    /**
     * opens a dialog box for adding new market
     */
    EditOrganizationMarketsComponent.prototype.openNewOrganizationMarket = function () {
        var dialog = this.dialog.open(organization_market_dialog_component_1.OrganizationMarketDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = this.newOrganizationMarketCallback;
    };
    /**
     * @param  {} action
     * @param  {} market
     * Check the action to be performed and call the corresponding function.
     * other functions like export can be added here in future.
     */
    EditOrganizationMarketsComponent.prototype.menuAction = function (action, market) {
        switch (action) {
            case 'edit':
                this.editOrganizationMarket(market);
                break;
            case 'remove':
                this.removeOrganizationMarket(market);
                break;
        }
    };
    /**
     * @param  {} market
     * opens a dialog box for editing the selected market
     */
    EditOrganizationMarketsComponent.prototype.editOrganizationMarket = function (market) {
        var dialog = this.dialog.open(organization_market_dialog_component_1.OrganizationMarketDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.model = market;
        dialog.componentInstance.callback = this.newOrganizationMarketCallback;
    };
    /**
     * @param  {} market
     * Removes the selected market and refresh the table with new data
     * Opens the remove confirm dialog
     */
    EditOrganizationMarketsComponent.prototype.removeOrganizationMarket = function (market) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Market?'),
            body: this.translationService.instant('This market will be deleted and cannot be recovered.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.tagService.remove(market).subscribe(function (res) {
                    _this.refreshTableData();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    /**
    * refresh/reload the product table on any action performed like
    * add, edit, remove
    */
    EditOrganizationMarketsComponent.prototype.refreshTableData = function () {
        this.marketTable.getRecords();
    };
    return EditOrganizationMarketsComponent;
}());
exports.EditOrganizationMarketsComponent = EditOrganizationMarketsComponent;
