import { Component } from '@angular/core';

/**
 * Not Found Component: Displays an error page for 404-style errors
 */
@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  /**
   * @ignore
   */
  constructor() { }
}
