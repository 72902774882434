"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewEndShiftAction = 'endShiftAction';
exports.ViewMessageDriverAction = 'messageDriverAction';
exports.ViewDriverProfileAction = 'viewDriverProfileAction';
exports.ViewAssignmentsOptionAction = 'viewAssignmentsAction';
exports.FleetHealthOptionAction = 'fleetHealthAction';
exports.shiftMenuOptions = [
    {
        name: 'End Shift',
        action: exports.ViewEndShiftAction,
        icon: 'alarm-off',
        disabled: false,
    },
    // {
    //   name: 'Message Driver',
    //   action: 'messageDriver',
    //   icon: 'chat-bubble',
    //   disabled: true,
    // },
    {
        name: 'View Driver Profile',
        action: exports.ViewDriverProfileAction,
        icon: 'social-person',
        disabled: false,
    },
    {
        name: 'View Assignments',
        action: exports.ViewAssignmentsOptionAction,
        icon: 'assignment',
        classNames: ['assignment-menu'],
        disabled: false,
    },
];
exports.FleetHealthAction = {
    name: 'Fleet Health',
    action: exports.FleetHealthOptionAction,
    icon: 'network-check'
};
