"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./alert-panel.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./alert-panel.component");
var i4 = require("@angular/common/http");
var i5 = require("../authentication.service");
var i6 = require("../../users/user.service");
var styles_AlertPanelComponent = [i0.styles];
var RenderType_AlertPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertPanelComponent, data: {} });
exports.RenderType_AlertPanelComponent = RenderType_AlertPanelComponent;
function View_AlertPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "alert-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "panel-content update-available"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["REFRESH"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.updateNoticeHidden = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon-close large"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alert; _ck(_v, 3, 0, currVal_0); }); }
function View_AlertPanelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertPanelComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.updateNoticeHidden && _co.updateAvailable); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_AlertPanelComponent_0 = View_AlertPanelComponent_0;
function View_AlertPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert-panel", [], null, null, null, View_AlertPanelComponent_0, RenderType_AlertPanelComponent)), i1.ɵdid(1, 245760, null, 0, i3.AlertPanelComponent, [i4.HttpClient, i5.AuthenticationService, i6.UserService, i1.Compiler], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AlertPanelComponent_Host_0 = View_AlertPanelComponent_Host_0;
var AlertPanelComponentNgFactory = i1.ɵccf("alert-panel", i3.AlertPanelComponent, View_AlertPanelComponent_Host_0, {}, {}, []);
exports.AlertPanelComponentNgFactory = AlertPanelComponentNgFactory;
