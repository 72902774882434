<div class="app-content">
  <my-toolbar title="{{ 'Jobs' | translate }}"></my-toolbar>

  <action-bar>
    <div [style.width.%]="100">
      <jobs-header></jobs-header>
    </div>
    <div class="left-actions">
     
      <div class="segmented-buttons">
        <a (click)="onChangeTimeRange(1)" class="btn btn-default" [ngClass]="{active: selectedTimeRange === 1}">
          1h
        </a>
        <a (click)="onChangeTimeRange(3)" class="btn btn-default" [ngClass]="{active: selectedTimeRange === 3}">
          3h
        </a>
        <a (click)="onChangeTimeRange(12)" class="btn btn-default" [ngClass]="{active: selectedTimeRange === 12}">
          12h
        </a>
        <a (click)="onChangeTimeRange(24)" class="btn btn-default" [ngClass]="{active: selectedTimeRange === 24}">
          24h
        </a>
      </div>

      <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: appliedFilters.length}">
        <i class="icon-filter"></i> {{ 'Filters' | translate }}
      </button>
      <div class="segmented-buttons" *ngIf="false">
        <a (click)="switchView('cards')" class="btn btn-default" [ngClass]="{active: view === 'cards'}">
          <i class="icon-rows"></i>
        </a>
      </div>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <action-menu title="Actions">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a md-menu-item (click)="menuAction(option.action)">{{ option.name }}</a>
        </ng-container>
      </action-menu>
    </div>
  </action-bar>
  <div>
    <applied-filters [(filters)]="appliedFilters" [(search)]="search"
      (filtersChange)="filterChanges($event)"
      (filtersReset)="filtersReset()"
      (searchChange)="changeSearch($event)">
    </applied-filters>
  </div>

  <div class="location-stats">
    <div class="container">
      <div class="location-stat" *ngFor="let locationStat of locationStats">
        <div class="location-name">
          <h2 title="{{ locationStat.displayName }}">{{ locationStat.displayName }}</h2>
          <a class="geofence-edit" [routerLink]="['/locations', locationStat.id, 'edit']"
             [queryParams]="{returnTo: '/jobs/locations/active'}" routerLinkActive="active">
            <mat-icon title="{{ 'Geofence set for location (click to edit)' | translate }}" *ngIf="locationStat.hasGeofence">
              layers
            </mat-icon>
            <mat-icon color="warn" title="{{ 'Geofence missing for location (click to add)' | translate }}" *ngIf="!locationStat.hasGeofence">
              layers_clear
            </mat-icon>
          </a>
        </div>
        <div class="location-type">
          <ruckit-dropdown #dropdownWrapper
            class="location-type-dropdown"
            [config]="locationTypeDropdownConfig"
            [selectedOption]="locationStat.locationType && locationStat.locationType.id"
            (onUserSelect)="selectLocationType($event, locationStat)"
            (onSelect)="selectLocationType($event, locationStat, false)">
          </ruckit-dropdown>
        </div>

        <div class="loading-stats" [ngClass]="{'below-target': (locationStat.loadingTargetDifference > 0)}">
          <div class="header" translate>Loading</div>
          <div class="stats">
            <div class="difference {{ locationStat.loadingTargetRange }}">
              {{ (locationStat.loadingTargetDifference || 0) | absoluteValue | number:'1.0-0' }} <span class="unit-of-measure" translate>min</span>
              <mat-icon *ngIf="(locationStat.loadingTargetDifference || 0) >= 0">arrow_upward</mat-icon>
              <mat-icon *ngIf="locationStat.loadingTargetDifference < 0">arrow_downward</mat-icon>
            </div>
            <div class="avg">
              {{ (locationStat.loadingTimeSpent || 0 | number:'1.0-0') }}<br />
              <label translate>AVG</label>
            </div>
            <div class="target">
              <input type="text" class="inline" name="loading-time-target" matInput
                     [value]="(locationStat.loadingTimeTarget || 0 | number:'1.0-0')"
                     (blur)="updateTimeTarget(locationStat, 'loading', $event)"><br />
              <label translate>Target</label>
            </div>
            <div class="visits">
              {{ (locationStat.loadingVisits || 0 | number:'1.0-0') }}<br />
              <label translate>Visits</label>
            </div>
          </div>
        </div>
        <div class="unloading-stats" [ngClass]="{'below-target': (locationStat.unloadingTargetDifference > 0)}">
          <div class="header" translate>Unloading</div>
          <div class="stats">
            <div class="difference {{ locationStat.unloadingTargetRange }}">
              {{ (locationStat.unloadingTargetDifference || 0) | absoluteValue | number:'1.0-0' }} <span class="unit-of-measure" translate>min</span>
              <mat-icon *ngIf="(locationStat.unloadingTargetDifference || 0) >= 0">arrow_upward</mat-icon>
              <mat-icon *ngIf="locationStat.unloadingTargetDifference < 0">arrow_downward</mat-icon>
            </div>
            <div class="avg">
              {{ (locationStat.unloadingTimeSpent || 0 | number:'1.0-0') }}<br />
              <label translate>AVG</label>
            </div>
            <div class="target">
              <input type="text" class="inline" name="unloading-time-target" matInput
                     [value]="(locationStat.unloadingTimeTarget || 0 | number:'1.0-0')"
                     (blur)="updateTimeTarget(locationStat, 'unloading', $event)"><br />
              <label translate>Target</label>
            </div>
            <div class="visits">
              {{ (locationStat.unloadingVisits || 0 | number:'1.0-0') }}<br />
              <label translate>Visits</label>
            </div>
          </div>
        </div>
      </div>
      <my-loader *ngIf="loading"></my-loader>
      <no-results itemName="Location Stats" [hasAddText]="false"
        customText="When drivers visit locations with geofences, the stats will appear here."
        [results]="count" [hasAction]="false" [styles]="{'min-height': '400px'}"
        [loading]="loading"></no-results>
    </div>
  </div>
</div>
