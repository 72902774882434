<div class="container">
  <div class="actions-wrapper">
    <fancy-table #priceTable [(availableColumns)]="availableColumns" 
        [(displayedColumns)]="displayedColumns"
        [(availableFilters)]="availableFilters" 
        [(filters)]="appliedFilters" 
        [(search)]="search" 
        [config]="priceTableConfig"
        (columnChange)="columnsChanged($event)"
        (onClickAction)="clickAction($event)">
      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'product'">
            <div class="data"> {{ row.product && row.product.name || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'change-type'">
            <div class="data">{{ row.priceChangeType && row.priceChangeType.name || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'change-amount'">
            <div class="data" *ngIf="!row.priceChangeType || row.priceChangeType.id !== 'percent'">
              {{(row.priceChangeAmount || 0) | currency : 'USD' : 'symbol'}}
            </div>
            <div class="data" *ngIf="row.priceChangeType && row.priceChangeType.id === 'percent'">
              {{(row.priceChangeAmount || 0) | number: '1.2-3'}}%
            </div>
          </div>
          <div class="value" *ngSwitchCase="'notes'">
            <div class="data">{{row.notes || '-' }}</div>
          </div>

          <div class="value" *ngSwitchCase="'action'">
            <action-menu icon="icon-more">
              <ng-container *ngFor="let option of row.filterOptions(priceTableConfig.menuOptions)">
                <a *ngIf="option.link" mat-menu-item [routerLink]="row.url(option.action)" 
                          [queryParams]="{ returnTo: '/inventory/price-lists' }">
                  {{ option.name }}
                </a>
                <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
              </ng-container>
            </action-menu>
          </div>
          <div class="value" *ngSwitchDefault>{{ value }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>{{ count }} Prices</div>
      </div>
      <div applied-filter-content>
        <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)"></applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle #columnToggle
                     [(availableColumns)]="availableColumns" 
                     [(displayedColumns)]="displayedColumns"></column-toggle>
      <filters-panel [(availableFilters)]="availableFilters" 
                     [(appliedFilters)]="appliedFilters" [(search)]="search"></filters-panel>
      <!-- add new price list dialog-->
      <a mat-icon-button color="accent" class="add-button" (click)="openPrice()">
        <mat-icon>add_circle</mat-icon>
      </a>
    </div>
  </div>
</div>
