'use strict';

import {
  Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter
} from '@angular/core';

@Component({
  selector: 'ruckit-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalCount = 0;
  @Input() currentPage = 0;
  @Input() perPage = 20;
  @Input() hideSinglePage = true;
  @Output() page: EventEmitter<number> = new EventEmitter<number>();

  totalPages = 0;
  pages = [];
  pagesCondensed = [];

  constructor() { }

  ngOnInit() {
    this.setPageDetail();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setPageDetail();
  }

  setPage(page) {
    this.page.emit(page);
  }

  setPageDetail(): void {
    this.totalPages = Math.ceil(this.totalCount / this.perPage);
    this.pages = Array.from({ length: this.totalPages }, (v, k) => k + 1);
    this.createPagination();
  }

  createPagination() {
    this.pagesCondensed = [];
    let delta = 2,
        left = this.currentPage - delta,
        right = this.currentPage + delta + 1,
        pageRange = [],
        l;

    for (let i = 1; i <= this.totalPages; i++) {
      if (i === 1 || i === this.totalPages || i >= left && i < right) {
        pageRange.push(i);
      }
    }

    for (let i of pageRange) {
      if (l) {
        if (i - l === 2) {
          this.pagesCondensed.push(l + 1);
        } else if (i - l !== 1) {
          this.pagesCondensed.push('...');
        }
      }
      this.pagesCondensed.push(i);
      l = i;
    }
  }
}
