"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var user_serializer_1 = require("../users/user.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DispatchService = /** @class */ (function () {
    function DispatchService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    DispatchService.prototype.save = function (options) {
        return this.http.post(this.baseUrl + 'jobevents/' + options.jobEvent + '/dispatch/', decamelizeKeysDeep(options), { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) {
            var dispatch = camelcaseKeysDeep(res);
            dispatch.lastDispatchedBy = (new user_serializer_1.UserSerializer).fromJson(dispatch.lastDispatchedBy);
            return dispatch;
        }), operators_1.catchError(api_service_1.handleError));
    };
    return DispatchService;
}());
exports.DispatchService = DispatchService;
