import * as moment from 'moment-timezone';
import { find as _find } from 'lodash';

import { JobEventShare } from './job-event-share';
import { LocationSerializer } from '../locations/location.serializer';
import { JOBWEIGHTOPTIONS } from '../app.constants';
import { UnitOfMeasureSerializer } from '../units/unit-of-measure.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class JobEventShareSerializer {
  fromJson(json: any): JobEventShare {
    json = camelcaseKeysDeep(json);
    const jobEventShare = new JobEventShare();
    if (!json) { return jobEventShare; }

    jobEventShare.id = json.id;
    jobEventShare.organization = json.organization;
    jobEventShare.organizationId = json.organizationId;
    jobEventShare.organizationLeasedOrg = json.organizationLeasedOrg;
    jobEventShare.sharedBy = json.sharedBy;
    jobEventShare.sharedById = json.sharedById;
    jobEventShare.job = json.job;
    jobEventShare.jobId = json.jobId;
    jobEventShare.jobNumber = json.jobNumber;
    jobEventShare.jobOrderNumber = json.jobOrderNumber;
    jobEventShare.jobeventId = json.jobeventId;
    if (json.startLocation && typeof (json.startLocation) === 'object') {
      jobEventShare.startLocation = new LocationSerializer().fromJson(jobEventShare.startLocation);
    } else {
      jobEventShare.startLocation = new LocationSerializer().fromJson({ id: jobEventShare.startLocation });
    }
    if (json.endLocation && typeof (json.endLocation) === 'object') {
      jobEventShare.endLocation = new LocationSerializer().fromJson(jobEventShare.endLocation);
    } else {
      jobEventShare.endLocation = new LocationSerializer().fromJson({ id: jobEventShare.endLocation });
    }
    jobEventShare.days = json.days;
    jobEventShare.jobDays = json.jobDays;
    jobEventShare.numTrucks = json.numTrucks || 0;
    jobEventShare.confirmedTrucks = json.confirmedTrucks || 0;
    jobEventShare.dispatched = json.dispatched || 0;
    jobEventShare.invoiceRate = json.invoiceRate;
    jobEventShare.invoiceType = json.invoiceType;
    jobEventShare.invoiceWeightUnit = json.invoiceWeightUnit;
    jobEventShare.haulRate = json.haulRate;
    jobEventShare.haulType = json.haulType;
    jobEventShare.haulWeightUnit = json.haulWeightUnit;
    if (jobEventShare.haulType === 'weight') {
      jobEventShare.haulRateUnit = jobEventShare.haulWeightUnit === 'cuyds' ? 'cu. yd.' : jobEventShare.haulWeightUnit;
    } else {
      jobEventShare.haulRateUnit = jobEventShare.haulType;
    }
    jobEventShare.weightOptions = [...JOBWEIGHTOPTIONS];
    jobEventShare.rateTrackingOptions = [
      { value: 'weight', label: 'Weight', name: 'Weight' },
      { value: 'load', label: 'Load', name: 'Load' },
      { value: 'hour', label: 'Hour', name: 'Hour' }
    ];
    jobEventShare.readonlyStatuses = [
      'upcoming', 'cancelled', 'expired', 'rejected', 'future'
    ];
    jobEventShare.weightOption = _find(jobEventShare.weightOptions, { value: jobEventShare.invoiceWeightUnit });
    jobEventShare.invoiceTypeOption = _find(jobEventShare.rateTrackingOptions, { value: jobEventShare.invoiceType });
    jobEventShare.haulTypeOption = _find(jobEventShare.rateTrackingOptions, { value: jobEventShare.haulType });
    jobEventShare.status = json.status;
    jobEventShare.readonly = jobEventShare.readonlyStatuses.includes(jobEventShare.status);
    jobEventShare.createdAt = json.createdAt;
    jobEventShare.senderFirstName = json.senderFirstName;
    jobEventShare.senderLastName = json.senderLastName;
    jobEventShare.senderOrganizationPhoneNumber = json.senderOrganizationPhoneNumber;
    jobEventShare.shift1Start = json.shift1Start;
    jobEventShare.shift1End = json.shift1End;
    jobEventShare.shift2Start = json.shift2Start;
    jobEventShare.shift2End = json.shift2End;
    jobEventShare.material = json.material;
    jobEventShare.checkinOptions = json.checkinOptions || {};
    jobEventShare.checkoutOptions = json.checkoutOptions || {};
    jobEventShare.multipliers = json.multipliers;
    jobEventShare.externalIdentifier = json.externalIdentifier;
    jobEventShare.workOrderTemplate = json.workOrderTemplate;
    jobEventShare.workOrderRenderedHtml = json.workOrderRenderedHtml;
    jobEventShare.workOrderRenderedPdf = json.workOrderRenderedPdf;
    jobEventShare.workOrderSigned = json.workOrderSigned;
    jobEventShare.workOrderSignedBy = json.workOrderSignedBy;
    jobEventShare.workOrderSignedByName = json.workOrderSignedByName;
    jobEventShare.notes = json.notes;
    jobEventShare.internalNotes = json.internalNotes;
    jobEventShare.carrierId = json.carrierId;
    jobEventShare.carrierName = json.carrierName;
    jobEventShare.loadSchedule = json.loadSchedule;
    if (jobEventShare.days && jobEventShare.days.length === 0) {
      jobEventShare.dateRange = '';
    } else if (jobEventShare.days && jobEventShare.days.length === 1) {
      jobEventShare.dateRange = moment(jobEventShare.days[0]).format('MMM D YYYY');
    } else if (jobEventShare.days) {
      const days = jobEventShare.days.sort((a, b) => {
        return +new Date(a) - +new Date(b);
      });
      jobEventShare.dateRange = moment(days[0]).format('MMM D YYYY') + ' to ' + moment(days[days.length - 1]).format('MMM D YYYY');
    }

    if (json.shift1Start || json.shift2Start) {
      let value = json.shift1Start ? json.shift1Start : json.shift2Start;
      jobEventShare.startDate = moment(value).format('YYYY-MM-DD');
      jobEventShare.startTime = moment(value).format('h:mm a');
    }

    if (json.shift1End || json.shift2End) {
      let value = json.shift1End ? json.shift1End : json.shift2End;
      jobEventShare.endDate = moment(value).format('YYYY-MM-DD');
      jobEventShare.endTime = moment(value).format('h:mm a');
    }

    const defaultUnit = new UnitOfMeasureSerializer().fromJson({name: 'Trucks', key: 'trucks'});
    jobEventShare.requestedAmount = json.requestedAmount || json.numTrucks;
    jobEventShare.requestedUnit = json.requestedUnit || defaultUnit;
    jobEventShare.collaboration = {
      ...{
        jobEventsLoading: false,
        jobEventOptions: [],
        recipientOrganization: jobEventShare.organizationId,
        jobevents: [],
        numTrucks: null,
        requestedAmount: null,
        requestedUnit: defaultUnit,
        invoiceWeightUnit: 'ton',
        invoiceType: null,
        invoiceRate: null,
        notes: '',
        internalNotes: ''
      }, ...{
        numTrucks: jobEventShare.numTrucks,
        requestedAmount: jobEventShare.requestedAmount || jobEventShare.numTrucks,
        requestedUnit: jobEventShare.requestedUnit || defaultUnit,
        invoiceWeightUnit: jobEventShare.invoiceWeightUnit,
        invoiceType: jobEventShare.invoiceType,
        invoiceRate: jobEventShare.invoiceRate,
        notes: jobEventShare.notes,
        internalNotes: jobEventShare.internalNotes
      }
    };

    jobEventShare.brokerRateCodeKey = json.brokerRateCodeKey;
    jobEventShare.customFieldData = json.customFieldData || {};
    jobEventShare.confirmedAmount = json.confirmedAmount;

    return jobEventShare;
  }

  toJson(jobEventShare: JobEventShare): any {
    let customFieldData = {
      [jobEventShare.brokerRateCodeKey]: jobEventShare.brokerRateCode
    };
    let json = {
      id: jobEventShare.id,
      organization: jobEventShare.organizationId,
      job: jobEventShare.jobId,
      jobevent: jobEventShare.jobeventId,
      days: jobEventShare.days,
      jobDays: jobEventShare.jobDays,
      numTrucks: jobEventShare.numTrucks,
      confirmedTrucks: jobEventShare.confirmedTrucks,
      invoiceRate: jobEventShare.invoiceRate,
      invoiceType: jobEventShare.invoiceType,
      invoiceWeightUnit: jobEventShare.invoiceWeightUnit,
      status: jobEventShare.status,
      notes: jobEventShare.notes,
      internalNotes: jobEventShare.internalNotes,
      shift1Start: jobEventShare.shift1Start,
      shift1End: jobEventShare.shift1End,
      shift2Start: jobEventShare.shift2Start,
      shift2End: jobEventShare.shift2End,
      material: jobEventShare.material,
      checkinOptions: jobEventShare.checkinOptions,
      checkoutOptions: jobEventShare.checkoutOptions,
      multipliers: jobEventShare.multipliers,
      externalIdentifier: jobEventShare.externalIdentifier,
      workOrderTemplate: jobEventShare.workOrderTemplate,
      workOrderSigned: jobEventShare.workOrderSigned,
      workOrderSignedBy: jobEventShare.workOrderSignedBy,
      customFieldData: customFieldData,
      requestedAmount: jobEventShare.requestedAmount,
      requestedUnit: jobEventShare.requestedUnit,
      carrierId: jobEventShare.carrierId,
      carrierName: jobEventShare.carrierName,
      loadSchedule: jobEventShare.loadSchedule,
    };

    return decamelizeKeysDeep(json);
  }
}
