import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Product } from './product';
import { ProductSerializer } from './product.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class ProductService extends ResourceService<Product> {
  constructor(http: HttpClient) {
    super(http, 'products/', new ProductSerializer());
   }
}
