<form #assignTruck="ngForm" class="assign-a-truck">
  <div mat-dialog-title class="modal-title">
    <h1>{{title}}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="form-content">
      <p>{{message}}</p>

      <div class="field-group truck full-width" *ngIf="dutyStatusOptions">
        <label translate>Duty Status</label>
        <input type="hidden" name="dutyStatus" [(ngModel)]="driver.dutyStatus" />
        <dropdown #dutyStatusDropdown
          title="{{ 'Select Duty Status' | translate }}"
          [options]="dutyStatusOptions"
          [config]="dutyStatusConfig"
          [selectedOption]="driver.displayDutyStatus"
          (onSelect)="optionSelected($event.id, 'dutyStatus')">
        </dropdown>
      </div>

      <div class="field-group truck-type full-width">
        <label class="required" translate>Available Trucks</label>
        <input type="hidden" name="truck" required [(ngModel)]="driver.truck" />
        <dropdown #truckDropdown
                  [disabled]="(!searching && (loading || !truckOptions || !truckOptions.length)) || (requireOnDuty && !isOnDuty)"
                  [options]="truckOptions"
                  [config]="truckDropdownConfig"
                  (onSelect)="selectTruck($event)"
                  (onSearch)="dropdownSearch($event, 'truck')"
                  (nextPage)="dropdownNextPage($event, 'truck')"
                  title="{{ 'Available Trucks' | translate }}"></dropdown>

        <notification level="warn" *ngIf="!loading && (!truckOptions || !truckOptions.length)">
          <ng-container *ngIf="jobEvent.truckTypeNames === 'Any Type'" translate>
            No Trucks are available for this Job.
          </ng-container>
          <ng-container *ngIf="jobEvent.truckTypeNames !== 'Any Type'" translate>
            No {{ jobEvent.truckTypeNames }} Trucks are available for this Job.
          </ng-container>
        </notification>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="dialogRef.close()" translate>Cancel</button>
    <button class="btn btn-primary"
            (click)="assignTruck.form.valid && submit(); false"
            [disabled]="!assignTruck.form.valid || !truckChanged || loading || (requireOnDuty && !isOnDuty)"
            [ngClass]="{loading:loading}"
            translate>
      Assign Truck
    </button>
  </div>
</form>
