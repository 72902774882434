import { clone } from 'lodash';
import * as moment from 'moment';

import { Resource } from '../shared/resource';
import { Truck } from '../trucks/truck';
import { Driver } from '../drivers/driver';

type ShiftBreak = {
  organization: string,
  truck: string,
  driver: string,
  start: string,
  end: string,
  duration: string,
  billableTotal: string,
  expenseTotal: string,
  id: string,
  createdAt: string,
  lastModified: string
};

export class ShiftReport extends Resource {
  id: string;
  driver: Driver;
  truckAssignments: {
    id: string;
    startTime: string;
    endTime: string;
    truck: Truck;
  }[];
  startTime: string;
  endTime: string;
  adjustment: number;
  breakDowns: ShiftBreak[];
  lunchBreaks: ShiftBreak[];
  shiftCheckups: {
    id: string,
    createdAt: string,
    lastModified: string,
    organization: string,
    driver: string,
    truck: string,
    mileage: string,
    engineHours: string,
    confirmedInspection: boolean,
    confirmedSafetyReminders: boolean,
    safetyReminderSet: boolean,
    completedAt: string
  }[];
  autoClosed: boolean;

  /**
   * Calculates the shift report duration using the start and end times as well as the adjustment field
   *
   * @returns {string} The formatted (pretty) duration for display
   */
  duration(): string {
    if (this.startTime && this.endTime) {
      let minuteDiff = moment(this.endTime).diff(moment(this.startTime), 'minutes') + (this.adjustment || 0);
      return Math.floor(minuteDiff / 60) + 'h ' + (minuteDiff % 60) + 'm';
    } else {
      return 'N/A';
    }
  }

  filterOptions(options): any[] {
    options = clone(options);
    return options;
  }
}
