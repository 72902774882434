import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';

// material
import { MatDialog } from '@angular/material';

// libraries
import { difference, get, find as _find, filter as _filter } from 'lodash';
import * as moment from 'moment';

// services
import {
  DriverShiftService,
  ShiftReportService,
  ShiftService,
} from './../../shifts/shift.service';
import { AuthenticationService } from '../../shared/authentication.service';

// models
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { ShiftReport } from './../../shifts/shift-report';
import { Driver } from '../driver';

// components
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { FiltersDialogComponent } from '../../shared/filters-dialog/filters-dialog.component';
import { ColumnToggleComponent } from '../../shared/column-toggle/column-toggle.component';
import { NewShiftDialogComponent } from '../../shifts/new-shift-dialog/new-shift-dialog.component';
import {
  FieldOption,
  ExportDialogComponent,
  ExportDialogData,
} from '../../shared/export-dialog/export-dialog.component';

// constants
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS } from './columns';

// utils
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'driver-shifts',
  templateUrl: './driver-shifts.component.html',
  styleUrls: ['./driver-shifts.component.scss'],
})
export class DriverShiftsComponent implements OnInit, OnDestroy {
  @Input() driver: Driver;
  availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  displayedColumns = [...DISPLAYEDCOLUMNS];

  appliedFilters: FilterOption[] = [];
  search = '';
  tableConfig = {
    collectionTitle: this.translationService.instant('Shifts'),
    noResultsText: this.translationService.instant('a shift'),
    hasHeader: true,
    pageSize: 25,
    service: ShiftReportService,
    filterQuery: false,
    preferenceKey: 'driverShifts',
    preferencesEnabled: true,
    query: {
      driver: '',
    },
    sortBy: 'driver__profile__last_name,driver__profile__first_name',
    sortDirection: 'asc',
    automaticallyHidePagination: false,
    newRecordModal: () => this.openAddShift(),
  };
  @ViewChild('columnToggle', { static: false })
  columnToggle: ColumnToggleComponent;
  @ViewChild('shiftsFancyTable', { static: false })
  shiftsFancyTable: FancyTableComponent;

  datePipe = new DatePipe('en-US');
  errors = [];
  allSelected = false;
  selectedShifts: ShiftReport[] = [];
  allLoadedShifts: ShiftReport[] = [];
  sortParameter: string;
  filtersDialog: FiltersDialogComponent;

  multipleActionDropdownOptions = [
    {
      name: this.translationService.instant('Export'),
      action: 'export',
      button: true,
      link: false,
    },
    {
      name: this.translationService.instant('Driver Shifts Export'),
      action: 'driver-shifts-export',
      link: false,
      button: true,
    },
  ];
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  exportCallback = () => {};

  constructor(
    private route: ActivatedRoute,
    private shiftService: ShiftService,
    private translationService: TranslateService,
    private driverShiftService: DriverShiftService,
    private authenticationService: AuthenticationService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  /**
   * Processes the current URL params and applies them as filters / queries to the table data requests
   */
  ngOnInit() {
    const enabledFeatures = this.authenticationService.enabledFeatures();
    const driverShiftsExportEnabled = enabledFeatures.includes(
      'hasDriverShiftExport'
    );
    if (!driverShiftsExportEnabled) {
      this.multipleActionDropdownOptions =
        this.multipleActionDropdownOptions.filter(
          (option) => option.action !== 'driver-shifts-export'
        );
    }
    let combinedParams = observableCombineLatest(
      this.route.params,
      this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe((result) => {
        this.search = result.qparams['search'] || '';
        if (result.qparams['sortBy']) {
          this.tableConfig.sortBy = result.qparams['sortBy'] || '';
          this.tableConfig.sortDirection = result.qparams['sortAsc'] || '';
        }
      })
    );

    if (this.appliedFilters.length === 0) {
      let startDate = moment();
      startDate = startDate.subtract(1, 'month');
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let defaultDateFilter = new FilterOption({
        filterType: 'date',
        default: true,
        key: 'startDate',
        title: this.translationService.instant('Start Date'),
        displayValues: startDate.format('MM/DD/YYYY') || null,
        values: startDate.toISOString(),
        query: {
          start_time__gte: startDate.toISOString(),
        },
      });
      this.appliedFilters.push(defaultDateFilter);
    }
    this.tableConfig.query = {
      ...this.tableConfig.query,
      driver: this.driver.id,
    };
  }

  ngOnDestroy() {
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * Opens the filter dialog and sets up the callback to handle changes made to any available filters
   */
  openFilters(): void {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px',
    });
    dialog.componentInstance.filters = [
      {
        type: 'dateRange',
        field: 'dateRange',
        label: 'Date Range',
      },
      {
        type: 'boolean',
        field: 'truckChanged',
        label: 'Changed Trucks',
      },
    ];
    dialog.componentInstance.callback = (res) => this.filterChanges(res);
    dialog.componentInstance.model = Object.assign(
      dialog.componentInstance.model,
      this.appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values;
        return acc;
      }, {})
    );

    let startDate = get(
      _find(this.appliedFilters, { key: 'startDate' }),
      'values'
    );
    if (startDate) {
      dialog.componentInstance.model.startDate = new Date(startDate);
    }
    let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
    if (endDate) {
      dialog.componentInstance.model.endDate = new Date(endDate);
    }
    dialog.componentInstance.model.carrier = null;

    this.filtersDialog = dialog.componentInstance;
  }

  /**
   * Handles the filter changes and applys them to the table data API request
   *
   * @param {any} filterRes The filter change object
   */
  filterChanges(filterRes): void {
    const queryKeys = {
      startDate: 'start_time__gte',
      endDate: 'end_time__lte',
      carrier: 'driver__carrier',
      truckChanged: 'truck_changed',
      vehicleBreakdown: 'vehicle_breakdown_duration__gte',
      includeLeasedFleetDrivers: 'include_leased_fleet_drivers',
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues =
        filterRes[key] && filterRes[key]['name']
          ? filterRes[key]['name']
          : values;
      if (typeof values === 'boolean') {
        if (values) {
          values = values.toString();
          values = values.charAt(0).toUpperCase() + values.slice(1);
          query[queryKeys[key]] = values;
        }
        displayValues = values;
      } else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
        if (typeof values === 'string') {
          query[queryKeys[key]] = values;
        } else {
          query[queryKeys[key]] = values.toISOString();
        }
      } else if (filterRes[key]) {
        query[queryKeys[key]] =
          filterRes[key] && filterRes[key].id
            ? filterRes[key].id
            : filterRes[key];
      }
      let filter = new FilterOption({
        filterType:
          ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
        key: key,
        title: (key.charAt(0).toUpperCase() + key.slice(1))
          .replace(/([A-Z])/g, ' $1')
          .trim(),
        displayValues: displayValues || null,
        values: values,
        query: query,
      });
      if (filter.values === 'False' || !filter.values) {
        falseyFilters.push(filter);
      }
      return filter;
    });
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  /**
   * Drives the row selection functionality, including 'allSelected' states for paginated data
   *
   * @param {{ allSelected: boolean, selection: SelectionModel<ShiftReport>, exclusion: SelectionModel<ShiftReport> }} shift
   * The selection event object
   */
  selector(event: {
    allSelected: boolean;
    selection: SelectionModel<ShiftReport>;
    exclusion: SelectionModel<ShiftReport>;
  }) {
    this.allSelected = event.allSelected;
    this.selectedShifts = this.allSelected
      ? (this.selectedShifts = this.allLoadedShifts.filter(
          (t) => !event.exclusion.selected.some((ex) => ex.id === t.id)
        ))
      : [...event.selection.selected];
  }

  onDataLoaded({ data }: { data: ShiftReport[] }) {
    this.allLoadedShifts = [...data];
  }

  /**
   * Makes an edit to either the startTime or endTime field and saves that edit to the API shift record
   *
   * @param {ShiftReport} shift The target shift report
   * @param {string} field The time field we will apply the edit to
   * @param {any} value The edit value for the time adjustment
   */
  editTime(shift: ShiftReport, field: string, value: string) {
    let saveData: any = { id: shift.id };
    const newTime = moment(value);

    if (field === 'start' && !newTime.isSame(shift.startTime)) {
      saveData.startTime = value;
      if (newTime.isAfter(shift.endTime)) {
        saveData.endTime = value;
      }
    } else if (field === 'end' && !newTime.isSame(shift.endTime)) {
      saveData.endTime = value;
      if (newTime.isBefore(shift.startTime)) {
        saveData.startTime = value;
      }
    }
    this.shiftService
      .save(saveData, { include_leased_fleet_drivers: 'True' })
      .subscribe(
        (updates) => {
          if (shift) {
            shift.startTime = updates.startTime;
            shift.endTime = updates.endTime;
          }
        },
        (err) => {
          this.errors = err;
        }
      );
  }

  openAddShift() {
    const dialog = this.dialog.open(NewShiftDialogComponent, {
      width: '455px',
    });
    const driver: Driver = <Driver>{ ...this.driver };
    dialog.componentInstance.driverForShift = driver;
    this.allSubscriptionsToUnsubscribe.push(
      dialog.componentInstance.shiftCreated.subscribe(() => {
        this.shiftsFancyTable.getRecords();
      })
    );
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedShifts();
        break;
      case 'driver-shifts-export':
        this.exportDriverShifts();
        break;
    }
  }

  exportSelectedShifts() {
    let { params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters,
      this.selectedShifts,
      [],
      this.allSelected,
      this.search
    );
    params = params.set('driver', this.driver.id);

    this.shiftService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'shifts',
            buttonText: this.translationService.instant('Export Data to CSV'),
            callback: () => this.shiftsFancyTable.deselectAll(),
            fields,
            params,
            scope,
            service: this.shiftService,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  /**
   * Generates a shift export using the pandas export API functionality
   */
  exportDriverShifts() {
    const customUrl = 'drivers/shifts/pandas-export/';
    let scope = {
      include: [this.driver.id],
    };
    let params = new HttpParams();

    this.shiftService.getExportFields(customUrl).subscribe(
      (fields: FieldOption[]) => {
        let lockedFieldKeys = [
          'start_day_of_month',
          'end_day_of_month',
          'driver',
          'driver_unique_billing_id',
          'regot',
          'duration',
        ];
        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'driver-shifts',
            buttonText: this.translationService.instant('Export Data to CSV'),
            callback: () => this.shiftsFancyTable.deselectAll(),
            fields: fields,
            lockedFields: lockedFieldKeys,
            params: params,
            scope: scope,
            service: this.driverShiftService,
            customUrl,
            excludeLeasedFleetOption: true,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  /**
   * Makes an edit to the adjustment field and saves that edit to the API shift record
   *
   * @param {ShiftReport} shift The target shift report
   * @param {any} value The edit value for the adjustment
   */
  editAdjustment(shift: ShiftReport, value: any) {
    let saveData: any = { id: shift.id, adjustment: Number(value) };
    if (saveData.adjustment !== shift.adjustment) {
      this.shiftService.save(saveData).subscribe(
        (updates) => {
          if (shift) {
            shift.adjustment = updates.adjustment;
          }
        },
        (err) => {
          this.errors = err;
        }
      );
    }
  }
}
