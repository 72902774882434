"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resource_1 = require("../shared/resource");
var PurchaseOrder = /** @class */ (function (_super) {
    __extends(PurchaseOrder, _super);
    function PurchaseOrder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selected = false;
        return _this;
    }
    PurchaseOrder.prototype.url = function (action) {
        return '/purchase-orders/' + this.id + '/' + action;
    };
    PurchaseOrder.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        else if (!this.void) {
            var _option = lodash_1.find(options, { action: 'unvoid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return PurchaseOrder;
}(resource_1.Resource));
exports.PurchaseOrder = PurchaseOrder;
