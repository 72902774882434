import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'confirm-drivers-dialog',
  templateUrl: './confirm-drivers-dialog.component.html',
  styleUrls: ['./confirm-drivers-dialog.component.scss']
})
export class ConfirmDriversDialogComponent {
  title = 'One Or More Drivers Are Busy';
  @Input() drivers: any = [];
  shift;
  slot;
  callback;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDriversDialogComponent>
  ) { }

  submit() {
    this.dialogRef.close();
    this.callback(this.shift, this.slot);
  }
}
