"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var moment = require("moment");
var date_time_picker_inline_config_1 = require("./date-time-picker-inline-config");
var INPUT_TIME_FORMAT = 'HH:mm';
var DateTimePickerInlineComponent = /** @class */ (function () {
    function DateTimePickerInlineComponent() {
        this.dateTimeChange = new core_1.EventEmitter();
        this.editMode = false;
    }
    Object.defineProperty(DateTimePickerInlineComponent.prototype, "value", {
        set: function (value) { this.setValue(value); },
        enumerable: true,
        configurable: true
    });
    DateTimePickerInlineComponent.prototype.ngOnInit = function () {
        if (!this.config) {
            this.config = date_time_picker_inline_config_1.DEFAULT_DATETIMEPICKERINLINE_CONFIG;
        }
        this.limitL = this.config.AbsoluteMin ? moment(this.config.AbsoluteMin) : undefined;
        this.limitH = this.config.AbsoluteMax ? moment(this.config.AbsoluteMax) : undefined;
    };
    DateTimePickerInlineComponent.prototype.onEditClick = function () {
        var editDateTime = this.dateTime ? this.dateTime.clone() : moment(new Date());
        this.setEditValue(editDateTime, true);
        this.editMode = true;
    };
    DateTimePickerInlineComponent.prototype.onSaveClick = function () {
        var value = this.editDateTime.toDate();
        value.setHours(+this.editTime.substr(0, 2), +this.editTime.substr(3, 2), 0, 0);
        // Emit updated value and rely on Input() value to be set externally for updating display
        this.dateTimeChange.emit(value.toISOString());
        this.closeForm();
    };
    DateTimePickerInlineComponent.prototype.onCancelClick = function () {
        this.closeForm();
    };
    DateTimePickerInlineComponent.prototype.adjustDate = function (numOfDays) {
        this.setEditValue(this.editDateTime.add(numOfDays, 'day'));
    };
    DateTimePickerInlineComponent.prototype.onDateChange = function (event) {
        this.setEditValue(event.value);
    };
    DateTimePickerInlineComponent.prototype.setEditValue = function (value, initTime) {
        if (initTime === void 0) { initTime = false; }
        if (initTime) {
            this.editTime = value.format(INPUT_TIME_FORMAT);
        }
        this.editDateTime = this.applyDateLimits(value);
        this.editDateDisplay = this.editDateTime.format(this.getDateFormat());
    };
    DateTimePickerInlineComponent.prototype.setValue = function (value) {
        this.dateTime = value ? moment(value) : undefined;
        if (this.dateTime && this.editMode) {
            // If the value is changed while being edited, update the edit time as well
            this.setEditValue(this.dateTime.clone(), true);
        }
        this.refreshDisplay();
    };
    DateTimePickerInlineComponent.prototype.applyDateLimits = function (value) {
        if (!value) {
            return undefined;
        }
        if (this.limitL && value.isBefore(this.limitL)) {
            return this.limitL.clone();
        }
        if (this.limitH && value.isAfter(this.limitH)) {
            return this.limitH.clone();
        }
        return value;
    };
    DateTimePickerInlineComponent.prototype.refreshDisplay = function () {
        if (this.dateTime) {
            this.displayDate = this.dateTime.format(this.getDateFormat());
            this.displayTime = this.dateTime.format(this.getTimeFormat());
        }
    };
    DateTimePickerInlineComponent.prototype.getDateFormat = function () {
        return this.config && this.config.DateFormat ? this.config.DateFormat : date_time_picker_inline_config_1.DEFAULT_DATETIMEPICKERINLINE_CONFIG.DateFormat;
    };
    DateTimePickerInlineComponent.prototype.getTimeFormat = function () {
        return this.config && this.config.TimeFormat ? this.config.TimeFormat : date_time_picker_inline_config_1.DEFAULT_DATETIMEPICKERINLINE_CONFIG.TimeFormat;
    };
    DateTimePickerInlineComponent.prototype.closeForm = function () {
        this.editMode = false;
        // Cleanup
        this.editTime = undefined;
        this.editDateTime = undefined;
        this.editDateDisplay = undefined;
    };
    return DateTimePickerInlineComponent;
}());
exports.DateTimePickerInlineComponent = DateTimePickerInlineComponent;
