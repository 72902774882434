import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResourceService } from '../shared/resource.service';
import { CondensedTicketSerializer } from './condensed-ticket.serializer';
import { CondensedTicket } from './condensed-ticket';
import { Observable } from 'rxjs';
import { clone } from 'lodash';
import { map } from 'rxjs/operators';


@Injectable()
export class CondensedTicketService extends ResourceService<CondensedTicket> {
  baseUrl = environment.serverUrl + 'scaletickets/condensed/';

  constructor(http: HttpClient) {
    super(http, 'scaletickets/condensed/', new CondensedTicketSerializer());
  }

  saveTicket(model): Observable<any> {
    const resourceUrl = environment.serverUrl + 'scaletickets/';

    model = clone(model);
    model = new CondensedTicketSerializer().toJson(model);

    if (!model.id) {
      return this.http.post(resourceUrl, model, {
        headers: this.requestHeaders()
      }).pipe(
        map(res => {
          try {
            res = res;
          } catch (err) {
          }
          return new CondensedTicketSerializer().fromJson(res);
        })
      );
    } else {
      return this.http.put(`${resourceUrl}${model.id}/`, model, {
        headers: this.requestHeaders()
      }).pipe(
        map(res => {
          try {
            res = res;
          } catch (err) { }

          return new CondensedTicketSerializer().fromJson(res);
        })
      );
    }
  }
}
