<my-toolbar class="desktop" title="{{ 'Dispatch Scheduler' | translate }}"></my-toolbar>
<action-bar>
  <div class="left-actions dispatch-schedule-actions-container">
    <div class="segmented-buttons">
      <a (click)="switchView('dispatch')" class="btn btn-default" [ngClass]="{active: view === 'jobEvents'}">
        <i class="icon-fleet" matTooltip="{{ 'Dispatch' | translate }}"></i>
      </a>
      <a (click)="switchView('review')" class="btn btn-default" [ngClass]="{active: view === 'drivers'}">
        <i class="icon-projects" matTooltip="{{ 'Review' | translate }}"></i>
      </a>
      <!-- <a (click)="switchView('collaborate')" class="btn btn-default" [ngClass]="{active: view === 'collaborators'}">
        <i class="icon-connections" matTooltip="{{ 'Collaborate' | translate }}"></i>
      </a> -->
    </div>
    <date-range-picker [selectedDates]="[selectedDate]"
                       (dateChanged)="selectDate($event)"></date-range-picker>
    <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filtersActive()}">
      <i class="icon-filter"></i> {{ 'Filters' | translate }}
    </button>
    <div class="cancelled">
      <input type="checkbox" id="hide_cancelled" (change)="toggleCancelled($event)" />
      <label attr.data-label="{{ 'Show Cancelled Jobs' | translate }}" for="hide_cancelled"></label>
    </div>
  </div>
  <div class="right-actions">
    <button class="btn btn-default" (click)="exportAssignments()" translate>
      Export Assignments
    </button>
    <button 
      class="btn btn-primary" [routerLink]="['/jobs/new']"
      [queryParams]="{returnTo: '/dispatch-schedule?date='+ selectedDateParam}" translate>
      New Job
    </button>
  </div>
</action-bar>
<applied-filters [(filters)]="selectedFilters" (filtersChange)="filterChanges($event)"></applied-filters>
<div class="container dispatch-schedule-container">
  <notification *ngFor="let error of errors">{{ error }}</notification>

  <span [ngSwitch]="view">
    <ng-container *ngSwitchCase="'drivers'">
      <ruckit-driver-assignments-list
        [dispatch]="true" [date]="selectedDateObj" #driverAssignmentsList
        [availableJobEvents]="jobEvents"
        (assignmentsReload)="getAssignments(drivers)"
        (updateAssignmentData)="applyAssignmentChanges($event)"
        (assignmentsDeleted)="applyAssignmentChanges($event, 'delete')"
      >
      </ruckit-driver-assignments-list>
      <dispatch-schedule-jobevent-grid
        [loadingProgress]="jobEventLoadingProgress"
        [jobEvents]="filteredJobEvents"
        [(selectedJobEvents)]="selectedJobEvents">
      </dispatch-schedule-jobevent-grid>
    </ng-container>
    <ng-container *ngSwitchCase="'jobEvents'">
      <dispatch-schedule-truck-grid
        [loadingProgress]="driverLoadingProgress"
        [drivers]="filteredDrivers"
        [(selectedDrivers)]="selectedDrivers"
        [carrierDropdownData]="carrierDropdownData"
        (selectCarrier)="selectCarrier($event)" (searchCarriers)="getCarriers($event)"
        (dropdownNextPage)="dropdownNextPage($event)">
      </dispatch-schedule-truck-grid>
      <dispatch-schedule-jobevent-edit-panel
        *ngIf="selectedJobEvent && jobEventEditPanelOpen"
        [(jobEvent)]="selectedJobEvent"
        (close)="closeEditPanel($event)"
        [ngClass]="{'open': jobEventEditPanelOpen}"
        (editsMade)="editPanelCallback($event)"
        (assignmentsDeleted)="applyAssignmentChanges($event, 'delete')">
      </dispatch-schedule-jobevent-edit-panel>
    </ng-container>
    <ng-container *ngSwitchCase="'collaborators'">
      <dispatch-schedule-collaborator-grid
        [loadingProgress]="connectionLoadingProgress"
        [connections]="connections"
        [(selectedConnections)]="selectedConnections">
      </dispatch-schedule-collaborator-grid>
    </ng-container>
  </span>
  
  <dispatch-schedule-table #dispatchTable
    [view]="view" [drivers]="filteredDrivers" [jobEvents]="filteredJobEvents" [jobEventsLastRequestedAt]="jobEventsLastRequestedAt"
    [selectedDate]="selectedDate" [loadStats]="loadStats" [truckStats]="truckStats" [truckTypeStats]="truckTypeStats"
    [tonStats]="tonStats" [activeLoadType]="activeLoadType"
    [jobEventLoadingProgress]="jobEventLoadingProgress"
    [driverLoadingProgress]="driverLoadingProgress"
    [assignmentLoadingProgress]="assignmentLoadingProgress"
    [shareLoadingProgress]="shareLoadingProgress"
    [carrierDropdownData]="carrierDropdownData"
    (selectCarrier)="selectCarrier($event)" (searchCarriers)="getCarriers($event)"
    (dropdownNextPage)="dropdownNextPage($event)"
    [(selectedDrivers)]="selectedDrivers" [(selectedJobEvents)]="selectedJobEvents"
    [selectedConnections]="selectedConnections"
    (getJobEvents)="getModifiedJobEvents($event)"
    (getAssignments)="getAssignments($event)"
    (getShares)="getShares($event)"
    (assignSelectedDrivers)="assignSelectedDrivers($event)"
    (assignSelectedJobEvents)="assignSelectedJobEvents($event)"
    (openJobEventPanel)="openJobEventEditPanel($event)"
    (openDriverPanel)="driverAssignmentsList.setOpen($event)"
    (driverQuery)="changeDriverQuery($event)"
    (updatePreference)="savePreferences($event)"
    (updateStats)="getJobEventStats($event)"
    (errors)="handleErrors($event)"
  ></dispatch-schedule-table>
</div>
