"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var BulkEditJobEventsSerializer = /** @class */ (function () {
    function BulkEditJobEventsSerializer() {
    }
    BulkEditJobEventsSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        return json;
    };
    BulkEditJobEventsSerializer.prototype.toJson = function (resource) {
        var editModelData = {};
        if (resource.include && resource.include.length) {
            editModelData.include = resource.include;
        }
        if (resource.exclude && resource.exclude.length) {
            editModelData.exclude = resource.exclude;
        }
        if (resource.cancelled) {
            editModelData.cancelled = resource.cancelled;
        }
        if (resource.allowAnyTruckType) {
            editModelData.allowAnyTruckType = resource.allowAnyTruckType;
        }
        if (resource.dailyDeliveryTarget) {
            editModelData.dailyDeliveryTarget = resource.dailyDeliveryTarget;
        }
        if (resource.dailyDeliveryTargetType) {
            editModelData.dailyDeliveryTargetType = resource.dailyDeliveryTargetType;
        }
        if (resource.defaultYardBufferTime) {
            editModelData.defaultYardBufferTime = resource.defaultYardBufferTime;
        }
        if (resource.numTrucks) {
            editModelData.numTrucks = resource.numTrucks;
        }
        return decamelizeKeysDeep(editModelData);
    };
    return BulkEditJobEventsSerializer;
}());
exports.BulkEditJobEventsSerializer = BulkEditJobEventsSerializer;
