import { Resource } from '../shared/resource';
import { CheckinOption } from '../checkins/checkin-option';

export class Preference extends Resource {
  organization?: string;
  profile?: string;
  name?: string;
  type?: string;
  blob?: Blob;
}

export class Blob {
  checkinOptions?: CheckinOption;
  checkoutOptions?: CheckinOption;
  [key: string]: any;
}
