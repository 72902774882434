<div class="markets-container">
  <notification *ngFor="let error of errors" translate>{{error}}</notification>
  <!-- using fancy table: reusable component -->
  <div class="container">
    <div class="actions-wrapper">
      <fancy-table #marketTable [(availableColumns)]="availableColumns"
                    [(displayedColumns)]="displayedColumns"
                    [(availableFilters)]="availableFilters"
                    [(filters)]="appliedFilters" [(search)]="search"
                    [config]="marketTableConfig"
                    (onClickAction)="clickAction($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'name'">
              <div class="data"> {{ row.name || '-' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'itemCount'">
              <div class="data">{{ row.itemCount || 0  }}</div>
            </div>
            <div class="value" *ngSwitchCase="'notes'">
              <div class="data">{{row.notes || '&#8211;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'action'">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of row.filterOptions(marketTableConfig.menuOptions)">
                  <a *ngIf="option.link" mat-menu-item
                      [routerLink]="row.url(option.action)"
                      [queryParams]="{ returnTo: '/settings/organization/' + organization.id + '/markets' }">
                    {{ option.name }}
                  </a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Markets</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)"></applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns"></column-toggle>
        <!-- NOTE: Need to add support in API for filter -->
        <!-- <filters-panel [(availableFilters)]="availableFilters" [(appliedFilters)]="appliedFilters" [(search)]="search"></filters-panel> -->
        <!-- add new market tag dialog-->
        <a mat-icon-button color="accent" class="add-button" (click)="openNewOrganizationMarket()">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>
  </div>
  <!-- fancy table :ends here -->
</div>
