import { of as observableOf, Observable } from 'rxjs';
import { HostListener, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { parseErrors } from '../shared/api.service';
import { ScaleitConfirmDialogResult } from '../scaleit/shared';
import { TicketFormComponent } from './ticket-form.component';
import { IncompleteTicketsComponent } from './incomplete-tickets.component';

import { NotificationComponent } from '../shared';
import { TruckDropdownComponent } from '../shared';
import { TruckService } from '../trucks/truck.service';
import { ScalesComponent } from '../scales/scales.component';
import { CondensedTicketSerializer } from './condensed-ticket.serializer';
import { CondensedTicketService } from './condensed-ticket.service';

@Component({
  selector: 'create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
  providers: [TruckService, CondensedTicketService]
})
export class CreateTicketComponent implements OnInit {
  @ViewChild(NotificationComponent, { static: true }) notification: NotificationComponent;
  @ViewChild(TicketFormComponent, { static: true }) ticketForm: TicketFormComponent;
  @ViewChild(IncompleteTicketsComponent, { static: true }) incompleteTicketsComponent: IncompleteTicketsComponent;
  @ViewChild(ScalesComponent, { static: true }) scalesComponent: ScalesComponent;
  @ViewChild(TruckDropdownComponent, { static: true }) trucksDropdown: TruckDropdownComponent;

  showTicketsPane = false;
  showScalesPane = false;
  errors = [];
  notificationLevel = 'info'; // Can be danger, warn, success, info
  notificationMessage = '';
  notificationTimeout = 3000;
  notificationHidden = true;
  licensePlateNumber: string = null;
  minLicensePlateLength = 1;
  searchStatusToSearch = true;
  searchStatusSearching = false;
  searchStatusNotFound = false;
  selectedTruck = null;
  truckOptions = [];
  truckDropdownConfig = {
    open: false,
    searchable: false,
    loadingOptions: false
  };
  confirmDialog: MatDialogRef<any>;
  saveTicketObservableSubscription = null;
  trucksReq;
  selectedTicket;
  tickets = [];
  ticketsLoading = false;
  ticketsReq;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private truckService: TruckService,
    private condensedTicketService: CondensedTicketService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.notification) { this.notification.open = false; }
  }

  // This handles browser navigation away from this page, when unsaved changes exist
  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:unload', ['$event']) // for android devices.
  handleOnBeforeUnload($event) {
    if (
      this.ticketForm &&
      this.ticketForm.hasUnsavedChanges &&
      this.ticketForm.hasUnsavedChanges()
    ) {
      // For IE and Firefox prior to version 4
      if ($event) {
        $event.returnValue = 'You have unsaved changes!';
      }
      // For Safari
      return 'You have unsaved changes!';
    }
  }

  toggle(accordion = 'tickets') {
    if (accordion === 'tickets') {
      this.showTicketsPane = !this.showTicketsPane;
    } else if (accordion === 'scales') {
      this.showScalesPane = !this.showScalesPane;
    }
  }

  handleTicketFormNotification(notificationInfo) {
    this.showNotification(notificationInfo.message, notificationInfo.level, notificationInfo.timeout);
  }

  highlightTicket(event) {
    this.refreshTickets();
    if (this.incompleteTicketsComponent.tickets.length) {
      this.incompleteTicketsComponent.highlightTicket(this.ticketForm.currentTicket);
    }
    if (event === true) { // when onTicketFinished event emitter emits true
      this.selectedTruck = null;
      this.licensePlateNumber = null;
      this.incompleteTicketsComponent.selectedTicket = null;
    }
  }

  handleTicketVoided() {
    this.selectedTruck = null;
    this.licensePlateNumber = null;
    this.refreshTickets();
  }

  refreshTickets() {
    this.incompleteTicketsComponent.getTickets();
  }

  setSearchStatusToSearch() {
    this.searchStatusToSearch = true;
    this.searchStatusSearching = false;
    this.searchStatusNotFound = false;
  }

  setSearchStatusSearching() {
    this.searchStatusToSearch = false;
    this.searchStatusSearching = true;
    this.searchStatusNotFound = false;
  }

  setSearchStatusNotFound() {
    this.searchStatusToSearch = false;
    this.searchStatusSearching = false;
    this.searchStatusNotFound = true;
  }

  selectTruck(truck) {
    this.selectedTruck = truck;
    this.licensePlateNumber = truck.licensePlate;
    this.getTickets({
      truck: this.selectedTruck && this.selectedTruck.id,
      ticket_status: 'in-progress,on-hold'
    }, true);
  }

  getTickets(query = {}, selectFirst = false) {
    this.ticketsLoading = true;
    if (this.ticketsReq) { this.ticketsReq.unsubscribe(); }

    this.ticketsReq = this.condensedTicketService.list({
      ...query
    }).subscribe(tickets => {
      this.tickets = tickets;
      if (selectFirst && tickets.length) {
        this.selectedTicket = tickets[0];
        this.ticketForm.autoFillFormWithTicket(this.selectedTicket);
        if (this.selectedTicket.statusText === 'On Hold') {
          this.selectedTicket.onHold = true;
          this.ticketForm.currentTicket.onHold = true;
        }
      } else if (selectFirst) {
        if (this.selectedTruck) {
          this.selectedTicket = new CondensedTicketSerializer().fromJson({
            truckId: this.selectedTruck.id,
            truck: this.selectedTruck.id,
            truckLicensePlate: this.selectedTruck.licensePlate,
            truckCarrierId: this.selectedTruck.carrierOrganizationId,
            truckImage: this.selectedTruck.truckImage,
            truckType: this.selectedTruck.truckType
          });
        }
        this.ticketForm.autoFillFormWithTicket(this.selectedTicket, true);
      }
      this.ticketsLoading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.ticketsLoading = false;
    });
  }

  getTrucks() {
    this.setSearchStatusToSearch();
    if (this.trucksReq && this.trucksReq.unsubscribe) {
      this.trucksReq.unsubscribe();
    }
    if (this.licensePlateNumber && this.licensePlateNumber.length >= this.minLicensePlateLength) {
      this.setSearchStatusSearching();
      this.trucksReq = this.truckService.list({
        search: this.licensePlateNumber,
        include_all: 'True'
      }).subscribe(trucks => {
        if (trucks.length > 0) {
          this.setSearchStatusToSearch();
          this.truckOptions = trucks;
          this.trucksDropdown.openMenu();
        } else {
          this.setSearchStatusNotFound();
        }
      }, error => {
        this.setSearchStatusNotFound();
      });
    }
  }

  hasNoValue(objToCheck: any) {
    switch (typeof objToCheck) {
      case 'string':
        return (
          objToCheck === undefined || objToCheck === null || objToCheck === ''
        );
      default:
        return objToCheck === undefined || objToCheck === null;
    }
  }

  newTicket() {
    if (this.ticketForm.hasUnsavedChanges()) {
      this.ticketForm.warnUnsavedChanges().subscribe(dialogResult => {
        switch (dialogResult) {
          case ScaleitConfirmDialogResult.No:
            this.licensePlateNumber = null;
            this.ticketForm.resetEntireForm();
            break;
          case ScaleitConfirmDialogResult.Yes:
            this.ticketForm.saveTicket(true);
            break;
        }
      });
    } else {
      this.licensePlateNumber = null;
      this.ticketForm.resetEntireForm();
    }
    this.selectedTicket = null;
    this.incompleteTicketsComponent.selectedTicket = null;
  }

  showNotification(notificationMessage, notificationLevel, notificationTimeout = this.notificationTimeout) {
    this.notificationLevel = notificationLevel;
    this.notificationMessage = notificationMessage;
    this.notification.reset();

    setTimeout(() => {
      this.notification.close();
    }, notificationTimeout);
  }

  onTicketSelected(ticket) {
    if (this.ticketForm.currentTicket && ticket && ticket.id === this.ticketForm.currentTicket.id) {
      return;
    }
    if (this.ticketForm.hasUnsavedChanges()) {
      this.ticketForm.warnUnsavedChanges().subscribe(dialogResult => {
        switch (dialogResult) {
          case ScaleitConfirmDialogResult.No:
            this.ticketForm.autoFillFormWithTicket(ticket);
            this.licensePlateNumber = null;
            this.incompleteTicketsComponent.highlightTicket(ticket, false);
            break;
          case ScaleitConfirmDialogResult.Yes:
            this.ticketForm.saveTicket(true).subscribe((res) => {
              if (res) {
                this.ticketForm.autoFillFormWithTicket(ticket);
                this.licensePlateNumber = null;
                this.incompleteTicketsComponent.selectedTicket = ticket;
                this.incompleteTicketsComponent.highlightTicket(ticket, false);
              }
            }, error => {
              this.errors = parseErrors(error);
            });
            break;
        }
      });
    } else {
      if (ticket.statusText === 'On Hold') {
        ticket.onHold = true;
        this.ticketForm.currentTicket.onHold = true;
      }
      this.ticketForm.autoFillFormWithTicket(ticket);
      this.licensePlateNumber = null;
      this.incompleteTicketsComponent.highlightTicket(ticket, false);
    }
  }

  // Modal to show method will show warning for navigation if unsaved
  checkUnsavedForNavigation(): Observable<boolean> {
    if (this.ticketForm.hasUnsavedChanges()) {
      return new Observable(observer => {
        this.ticketForm.warnUnsavedChanges().subscribe(dialogResult => {
          switch (dialogResult) {
            case ScaleitConfirmDialogResult.No:
              this.ticketForm.resetEntireForm();
              observer.next(true);
              break;
            case ScaleitConfirmDialogResult.Yes:
              this.ticketForm.saveTicket(true);
              break;
            default:
              observer.next(false);
          }
        });
      });
    } else {
      return observableOf(true);
    }
  }
}
