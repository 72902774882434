import { TableConfig } from './table.types';

const defaultConfig: TableConfig = {
  hasHeader: false,
  hasCustomFields: false,
  customFieldsLoaded: false,
  service: null,
  serviceFunction: 'list',
  filterQuery: true,
  collectionTitle: 'Records',
  noResultsText: 'a record',
  hasNoResultsAction: true,
  addButtonText: null,
  sortBy: 'id',
  sortDirection: 'asc',
  newRecordRoute: ['new'],
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  hidePageSize: false,
  customHeight: false,
  condensedLayout: false,
  ignorePreferences: false,
  menuOptions: [],
  query: {},
  newRecordModal: null,
  automaticallyHidePagination: false,
  preferenceKey: 'null',
  preferencesEnabled: true,
  customRecordFields: null,
  allowDrop: false,
};

export default defaultConfig;
