"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var checkin_1 = require("../checkins/checkin");
var invoice_service_1 = require("./invoice.service");
var trip_service_1 = require("../trips/trip.service");
var punch_card_service_1 = require("../punch-cards/punch-card.service");
var surcharge_service_1 = require("../surcharges/surcharge.service");
var InvoiceComponent = /** @class */ (function () {
    function InvoiceComponent(route, router, dialog, invoiceService, tripService, punchCardService, surchargeService) {
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.invoiceService = invoiceService;
        this.tripService = tripService;
        this.punchCardService = punchCardService;
        this.surchargeService = surchargeService;
        this.title = 'Invoice';
        this.loading = false;
        this.loadingTrips = false;
        this.loadingCards = false;
        this.loadingSurcharges = false;
        this.errors = [];
        this.trips = [];
        this.punchCards = [];
        this.surcharges = [];
        this.tickets = [];
        this.tripCount = 0;
        this.punchCardCount = 0;
        this.surchargeCount = 0;
        this.ticketCount = 0;
        this.sortDirection = 'asc';
        this.showTickets = false;
    }
    InvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.route.queryParams.forEach(function (params) {
            _this.returnTo = params['returnTo'];
        });
        this.route.params.forEach(function (params) {
            _this.invoiceId = params['id'];
            _this.getInvoice(_this.invoiceId);
        });
        this.loadingTrips = true;
        this.loadingCards = true;
        this.loadingSurcharges = true;
        this.indexingTimer = rxjs_1.timer(1000, 500);
        this.indexingTimerSub = this.indexingTimer.subscribe(function (t) {
            _this.indexRecords();
        });
    };
    InvoiceComponent.prototype.ngOnDestroy = function () {
        if (this.indexingTimerSub) {
            try {
                this.indexingTimerSub.unsubscribe();
                this.indexingTimer = null;
            }
            catch (e) {
                this.indexingTimerSub = null;
                this.indexingTimer = null;
            }
        }
    };
    InvoiceComponent.prototype.getInvoice = function (id) {
        var _this = this;
        this.invoiceService.getPublicInvoice(id).subscribe(function (invoice) {
            _this.title = invoice.title;
            if (invoice.html && invoice.sent) {
                _this.openInvoice(invoice);
            }
            else {
                _this.invoice = invoice;
                _this.getTrips();
                _this.getPunchCards();
                _this.getSurcharges();
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
        });
    };
    InvoiceComponent.prototype.openInvoice = function (invoice) {
        window.open(invoice.html);
    };
    InvoiceComponent.prototype.openReturnTo = function () {
        if (this.returnTo) {
            this.router.navigate([this.returnTo]);
        }
        else {
            this.router.navigate(['/invoices']);
        }
    };
    InvoiceComponent.prototype.getTrips = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loadingTrips = true;
        if (this.tripReq) {
            this.tripReq.unsubscribe();
        }
        this.tripReq = this.tripService.getCondensedTrips({
            invoice: this.invoice.id,
            page_size: 100
        }).subscribe(function (trips) {
            _this.trips = trips;
            _this.captureCheckins(trips);
            _this.getNextTrips();
            _this.tripCount = _this.tripService.count;
        }, function (err) {
            _this.errors = err;
            _this.loadingTrips = false;
        });
    };
    InvoiceComponent.prototype.getNextTrips = function () {
        var _this = this;
        if (this.tripService.nextUri) {
            this.loading = true;
            this.tripService.getNextCondensed().subscribe(function (trips) {
                _this.trips = _this.trips.concat(trips);
                _this.captureCheckins(trips);
                if (_this.tripService.nextUri) {
                    _this.getNextTrips();
                }
                else {
                    _this.loadingTrips = false;
                }
            }, function (err) {
                _this.errors = err;
                _this.loadingTrips = false;
            });
        }
        else {
            this.loadingTrips = false;
        }
    };
    InvoiceComponent.prototype.getPunchCards = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loadingCards = true;
        if (this.punchCardReq) {
            this.punchCardReq.unsubscribe();
        }
        this.punchCardReq = this.punchCardService.getCondensedPunchCards({
            invoice: this.invoice.id,
            page_size: 100
        }).subscribe(function (punchCards) {
            _this.punchCards = punchCards;
            _this.captureCheckins(punchCards, 'punchCards');
            _this.getNextPunchCards();
            _this.punchCardCount = _this.punchCardService.count;
        }, function (err) {
            _this.errors = err;
            _this.loadingCards = false;
        });
    };
    InvoiceComponent.prototype.getNextPunchCards = function () {
        var _this = this;
        if (this.punchCardService.nextUri) {
            this.loadingCards = true;
            this.punchCardService.getNextCondensed().subscribe(function (punchCards) {
                _this.punchCards = _this.punchCards.concat(punchCards);
                _this.captureCheckins(punchCards, 'punchCards');
                if (_this.punchCardService.nextUri) {
                    _this.getNextPunchCards();
                }
                else {
                    _this.loadingCards = false;
                }
            }, function (err) {
                _this.errors = err;
                _this.loadingCards = false;
            });
        }
        else {
            this.loadingCards = false;
        }
    };
    InvoiceComponent.prototype.getSurcharges = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loadingSurcharges = true;
        if (this.surchargeReq) {
            this.surchargeReq.unsubscribe();
        }
        this.surchargeReq = this.surchargeService.list({
            invoice: this.invoice.id,
            page_size: 100
        }).subscribe(function (surcharges) {
            _this.surcharges = surcharges;
            _this.getNextSurcharges();
            _this.surchargeCount = _this.surchargeService.count;
        }, function (err) {
            _this.errors = err;
            _this.loadingSurcharges = false;
        });
    };
    InvoiceComponent.prototype.getNextSurcharges = function () {
        var _this = this;
        if (this.surchargeService.nextUri) {
            this.loadingSurcharges = true;
            this.surchargeService.listNext().subscribe(function (surcharges) {
                _this.surcharges = _this.surcharges.concat(surcharges);
                if (_this.surchargeService.nextUri) {
                    _this.getNextSurcharges();
                }
                else {
                    _this.loadingSurcharges = false;
                }
            }, function (err) {
                _this.errors = err;
                _this.loadingSurcharges = false;
            });
        }
        else {
            this.loadingSurcharges = false;
        }
    };
    InvoiceComponent.prototype.sort = function (records, property) {
        switch (records) {
            case 'trips': {
                this.trips = this.trips && this.trips.sort(function (a, b) {
                    return (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0);
                });
                this.trips = this.sortDirection === 'asc' ? this.trips : this.trips.reverse();
                break;
            }
            case 'cards': {
                this.punchCards = this.punchCards && this.punchCards.sort(function (a, b) {
                    return (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0);
                });
                this.punchCards = this.sortDirection === 'asc' ? this.punchCards : this.punchCards.reverse();
                break;
            }
            case 'surcharges': {
                this.surcharges = this.surcharges && this.surcharges.sort(function (a, b) {
                    return (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0);
                });
                this.surcharges = this.sortDirection === 'asc' ? this.surcharges : this.surcharges.reverse();
                break;
            }
        }
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    };
    InvoiceComponent.prototype.indexRecords = function () {
        if (this.loadingTrips || this.loadingCards || this.loadingSurcharges) {
            return;
        }
        var indexProperty = 1;
        for (var index = 0; index < this.trips.length; index++) {
            this.trips[index].index = indexProperty;
            indexProperty += 1;
        }
        for (var index = 0; index < this.punchCards.length; index++) {
            this.punchCards[index].index = indexProperty;
            indexProperty += 1;
        }
        for (var index = 0; index < this.surcharges.length; index++) {
            this.surcharges[index].index = indexProperty;
            indexProperty += 1;
        }
        for (var index = 0; index < this.tickets.length; index++) {
            if (this.tickets[index].kind === 'punch card') {
                if (this.tickets[index].checkin1 !== undefined) {
                    var punchCard = lodash_1.find(this.punchCards, { id: this.tickets[index].checkin1.index });
                    this.tickets[index].checkin1.index = punchCard && punchCard.index;
                }
                else {
                    this.tickets[index].checkin1.index = '';
                }
            }
            else {
                if (this.tickets[index].checkin1 !== undefined) {
                    var trip = lodash_1.find(this.trips, { id: this.tickets[index].checkin1.index });
                    this.tickets[index].checkin1.index = trip && trip.index;
                }
                else {
                    this.tickets[index].checkin1.index = '';
                }
                if (this.tickets[index].checkin2 !== undefined && this.tickets[index].checkin2.index !== undefined) {
                    var trip = lodash_1.find(this.trips, { id: this.tickets[index].checkin2.index });
                    this.tickets[index].checkin2.index = trip && trip.index;
                }
                else {
                    this.tickets[index].checkin2.index = '';
                }
            }
        }
        if (this.indexingTimerSub) {
            try {
                this.indexingTimerSub.unsubscribe();
                this.indexingTimer = null;
            }
            catch (e) {
                this.indexingTimerSub = null;
                this.indexingTimer = null;
            }
        }
    };
    InvoiceComponent.prototype.captureCheckins = function (records, type) {
        if (type === void 0) { type = 'trips'; }
        records = [].concat.apply([], records);
        var tickets = [];
        if (type === 'trips') {
            tickets = records.map(function (record) {
                var loadingCheckin = new checkin_1.Checkin({
                    index: record.id,
                    kind: 'loading',
                    ticketImage: record.loadingTicketImage,
                    ticketImageKey: record.loadingTicketImageKey,
                    ticketNumber: record.loadingTicketNumber,
                    weight: record.loadingWeight,
                    invoiceWeightUnit: record.invoiceWeightUnitDisplay,
                    haulWeightUnit: record.haulWeightUnitDisplay,
                    date: record.loadingCompleteDatetime
                });
                var unloadingCheckin = new checkin_1.Checkin({
                    index: record.id,
                    kind: 'unloading',
                    ticketImage: record.unloadingTicketImage,
                    ticketImageKey: record.unloadingTicketImageKey,
                    ticketNumber: record.unloadingTicketNumber,
                    weight: record.unloadingWeight,
                    invoiceWeightUnit: record.invoiceWeightUnitDisplay,
                    haulWeightUnit: record.haulWeightUnitDisplay,
                    date: record.unloadingCompleteDatetime
                });
                return { checkin1: loadingCheckin, checkin2: unloadingCheckin };
            });
        }
        else if (type === 'punchCards') {
            tickets = records.map(function (record) {
                var checkin = new checkin_1.Checkin({
                    index: record.id,
                    kind: 'punch card',
                    ticketImage: record.ticketImage,
                    ticketImageKey: record.ticketImageKey,
                    ticketNumber: record.ticketNumber,
                    weight: record.invoiceQuantity,
                    weightWithLabel: record.invoiceQuantityWithLabel,
                    invoiceWeightUnit: record.invoiceWeightUnitDisplay,
                    haulWeightUnit: record.haulWeightUnitDisplay,
                    date: record.jobDate
                });
                return { checkin1: checkin, checkin2: null };
            });
        }
        this.tickets = this.tickets.concat(tickets);
    };
    InvoiceComponent.prototype.toggleTickets = function () {
        this.showTickets = !this.showTickets;
    };
    return InvoiceComponent;
}());
exports.InvoiceComponent = InvoiceComponent;
