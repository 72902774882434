<div class="app-content">
  <my-toolbar class="desktop-visible" title="{{ 'Reports' | translate }}" (updatedMarkets)="updatedMarkets()"></my-toolbar>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/trips']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" queryParamsHandling="merge" translate>Trips</a>
        <a [routerLink]="['/geotrips']" routerLinkActive="active" translate>GeoTrips</a>
        <a [routerLink]="['/punchcards']" routerLinkActive="active" translate>Punch Cards</a>
        <a [routerLink]="['/shifts']" routerLinkActive="active" translate>Shifts</a>
        <a [routerLink]="['/driver-reports']" routerLinkActive="active" translate>Driver Job Reports</a>
        <a [routerLink]="['/end-of-day-reports']" routerLinkActive="active" translate>End of Day Reports</a>
      </div>
    </div>
    <div class="eod-topbar">
      <div class="left-actions">
        <date-range-picker [selectedDates]="[reportDate]" (dateChanged)="onDateChanged($event)"></date-range-picker>
      </div>
      <div class="overall-stats" *ngIf="reportDate">
        <end-of-day-overall-stats #overallStats [reportDate]="reportDate" [search]="search" [filters]="appliedFilters" ></end-of-day-overall-stats>
      </div>
      <div class="right-actions">
        <button 
          class="btn btn-default btn-filters" 
          (click)="openFilters()" 
          [ngClass]="{active: appliedFilters | eodFilterPipe}"
        >
          <i class="icon-filter"></i> {{ 'Filters' | translate }}
        </button>
        <split-button title="{{ 'Export Approved' | translate }}"
                      [ngClass]="{loading: loading}"
                      buttonClasses="green-button"
                      controlButtonClasses="green-button"
                      [disabled]="!drivers.length || loading"
                      [primaryActionOpenMenu]="true">
          <a translate class="export-voided" (click)="customExport('trips', true)"
            [ngClass]="{
              loading: loading,
              disabled: !drivers.length || loading
            }">
            Export Approved Trips
          </a>
          <a translate class="export-voided" (click)="export()"
            [ngClass]="{
              loading: loading,
              disabled: !drivers.length || loading
            }">
            Export Voided Trips
          </a>
          <a translate class="export-all" (click)="customExport('trips')"
            [ngClass]="{
              loading: loading,
              disabled: !drivers.length || loading
            }">
            Export All Trips
          </a>
          <a
            translate
            class="export-voided"
            (click)="customExport('punchcards', true)"
            [ngClass]="{
              loading: loading,
              disabled: !drivers.length || loading
            }"
          >
            Export Approved Punch Cards
          </a>
          <a
            translate
            class="export-all"
            (click)="customExport('punchcards')"
            [ngClass]="{
              loading: loading,
              disabled: !drivers.length || loading
            }"
          >
            Export All Punch Cards
          </a>
        </split-button>
      </div>
    </div>
  </action-bar>

  <div class="filters-container" *ngIf="appliedFilters | eodFilterPipe">
    <ng-container *ngFor="let appliedFilter of appliedFilters">
      <div class="filter" *ngIf="(appliedFilter.values && appliedFilter.values.length) || appliedFilter.selected">
        <span class="label">{{appliedFilter.label}}: &nbsp;</span>
        <span *ngIf="appliedFilter.values && appliedFilter.values.length && appliedFilter.values[0].label"> {{appliedFilter.values[0].label}}</span>
        <span *ngIf="appliedFilter.selected" translate>True</span>
        <a mat-icon-button class="remove" color="accent" (click)="removeFilterOption(appliedFilter)">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </ng-container>
    <div class="filter reset" (click)="resetFilters()">
      <span class="label" translate>Reset</span>
      <a mat-icon-button class="remove" color="accent">
        <mat-icon>add_circle</mat-icon>
      </a>
    </div>
  </div>

  <div class="container" *ngIf="reportDate">
      <driver-daily #driverDaily [loading]="loading"
                    [reportDate]="reportDate" [drivers]="drivers"
                    [driver]="selectedDriver"
                    [filtersApplied]="appliedFilters | eodFilterPipe"
                    [search]="search"
                    (changedSearch)="changedSearch($event)"
                    (selectedDriver)="selectDriver($event)">
      </driver-daily>

      <driver-daily-tasks #driverDailyTasks [loading]="driverDataLoading"
                          [driver]="selectedDriver"
                          [filtersApplied]="appliedFilters | eodFilterPipe"
                          [(keyStatus)]="keyStatus" [reportDate]="reportDate"
                          (shouldAuditDecisions)="auditDecisions($event)"
                          (rateChanged)="rateChanged()">
      </driver-daily-tasks>
  </div>
</div>
