import { of as observableOf, Observable } from 'rxjs';

import { USER } from '../users/mock-user';
import { ORGANIZATION } from '../organizations/mock-organization';
import { User } from '../users/user';

export class MockAuthenticationService {
  login(username: string, password: string): Observable<boolean> {
    localStorage.setItem('currentUser', JSON.stringify({
      username: USER.email, token: USER.token, name: USER.name,
      organization: USER.organization, driver: false, sidebar: true,
      carrier: false, ruckit: true, id: USER.id, canCreateJobs: true
    }));

    return observableOf(true);
  }

  user() {
    return USER;
  }

  getOrganization() {
    return ORGANIZATION;
  }

  isRuckit() {
    return true;
  }

  displaySidebar(): boolean {
    return true;
  }

  isLoggedIn(): boolean {
    return true;
  }

  isCrh(): boolean {
    return true;
  }

  getFilterProperty(filter): any {
    return false;
  }

  hasAllDriversEnabled(): boolean {
    return true;
  }

  canCreateJobs(): boolean {
    return true;
  }

  logout(): void {
    localStorage.removeItem('currentUser');
  }

  hasFavoriteTags(): boolean {
    return false;
  }

  isScaleit(): boolean {
    return false;
  }

  hasWorkOrder(): boolean {
    return false;
  }

  hasSignature(): boolean {
    return false;
  }

  storeUser(user: User): boolean {
    localStorage.setItem('currentUser', JSON.stringify({
      username: user.email, token: user.token, name: user.name,
      organization: user.organization, driver: user.isDriver,
      sidebar: user.sidebar, carrier: user.isCarrier, ruckit: user.isRuckit,
      id: user.id, image: user.image,
      canCreateJobs: user.organization && user.organization.canCreateJobs
    }));
    return true;
  }

  enabledFeatures(): string[] {
    return [];
  }

  getFeature(feature: string): any {
    return null;
  }

  getOrgPropertyValue(prop: string): any {
    return null;
  }
}
