<my-toolbar [logo]=false [userDropdown]=false [menuButton]=false title="Marketplace" color="ruckit-green"></my-toolbar>

<div class="marketplace-listings" *ngIf="!loading">
  <div class="jobs">
    <div class="table-body" (scroll)="onScroll($event)">
      <div class="no-results" *ngIf="!loading && (!marketplaces || marketplaces.length === 0)" fxLayout="column" fxLayoutAlign="start center">
        <div class="icon"><i class="icon-team"></i></div>
        <h2>No Marketplace Jobs</h2>
        <p>When jobs have been posted you’ll see them here.</p>
      </div>

      <ng-container *ngIf="marketplaces && marketplaces.length">
        <div *ngFor="let section of marketplaces | groupBy: 'date'">
          <div class="section-header">{{ section.key }}</div>

          <div *ngFor="let marketplace of section.value" class="row" (click)="openOverview(marketplace)">
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
              <div class="job-name">{{ marketplace.job.name || '&nbsp;' }}</div>
              <div class="schedule">{{ marketplace.shift1StartTime || '&nbsp;' }} - {{ marketplace.endTime || '&nbsp;' }}</div>
            </div>
            <div class="field-row customer-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
              <div class="customer">{{ marketplace.customer || '&nbsp;' }}</div>
            </div>
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
              <div class="material"><i class="icon-material"></i> {{ marketplace.job.material || '&nbsp;' }}</div>
              <div class="invoice-rate currency">{{ (marketplace.haulRate || 0 | currency:'USD':'symbol':'1.2-4') }} / {{ marketplace.job.haulRateUnit || '&nbsp;' | titleCase }}</div>
            </div>
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
              <div class="job-distance"><i class="icon-distance"></i> {{ (marketplace.job.distanceInMiles | number:'1.1-2') || '0.0' }} mi</div>
              <div class="distance-to-job">{{ (marketplace.distanceToJob | number:'1.1-2') || '0.0' }} mi away</div>
            </div>
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
              <div class="number-trucks"><i class="icon-truck"></i>{{ marketplace.assignmentsCount || '0' }} / {{ marketplace.marketNumTrucks || '&nbsp;' }} - {{ marketplace.truckTypeNames || '&nbsp;' }}</div>
            </div>
          </div>
        </div>
      </ng-container>

      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading" [ngClass]="{'full-height': !marketplaces || marketplaces.length === 0}"></my-loader>
    </div>
  </div>
</div>
