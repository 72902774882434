import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TruckType } from './truck-type';
import { TruckTypeSerializer } from './truck-type.serializer';
import { ResourceService } from '../shared/resource.service';
import { requestHeaders } from '../shared/index';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class TruckTypeService extends ResourceService<TruckType> {
  constructor(http: HttpClient) {
    super(http, 'trucktypes/', new TruckTypeSerializer());
  }

  updateTruckTypes(truckTypeData: any[]): Observable<boolean> {
    const truckTypeUrl = this.baseUrl + 'trucktypes/config/';
    const _truckTypeData = decamelizeKeysDeep(clone(truckTypeData));
    return this.http.put(truckTypeUrl, _truckTypeData, { headers: requestHeaders() }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }
}

