import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { uniq } from 'lodash';
import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';
import { User } from '../users/user';
import { UserSerializer } from '../users/user.serializer';
import { Organization } from '../organizations/organization';
import { DataService } from './data.service';
import { handleError } from '../shared/api.service';

const camelcaseKeysDeep = require('camelcase-keys-deep');


@Injectable()
export class AuthenticationService {
  public token: string;
  private loggedIn = false;
  private ruckit = false;
  private scaleit = false;
  private crh = false;
  private posEnabled = false;
  private advancedBilling = false;
  private allDriversEnabled = false;
  private sidebar = true;
  private createJobs = false;
  private leasedOrgs = false;
  private trackables = false;
  private ruckit3 = false;
  private workOrders = false;
  private signature = false;
  private lafargeRegion = false;
  private billingModuleEnabled = false;
  private loadListsEnabled = false;
  baseUrl = environment.serverUrl;

  constructor(
    private http: HttpClient,
    private dataService: DataService
  ) {
    // set token if saved in local storage
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  login(username: string, password: string): Observable<boolean> {
    let params = { username: username, password: password };
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/login/users/', params, { headers: headers }).pipe(
      map(this.extractData),
      catchError(handleError),
      map(this.storeUser)
    );
  }

  logout(): void {
    // clear token and remove user from local storage to log out
    this.token = null;
    this.loggedIn = false;
    this.dataService.changeData({ authenticated: false, sidebar: false });
    localStorage.removeItem('currentUser');

    Sentry.configureScope((scope) => {
      scope.setUser({});
    });
  }

  forgot(username: string): Observable<boolean> {
    let params = { username: username };
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/forgot/', params, { headers: headers }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      }),
      catchError(handleError)
    );
  }

  reset(token: string, uid: string, password: string): Observable<boolean> {
    let params = { token: token, uid: uid, password: password };
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.baseUrl + 'auth/reset/', params, { headers: headers }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      }),
      catchError(handleError)
    );
  }

  getProfile(token: string, uid: string) {
    let params: HttpParams = new HttpParams();
    params = params.set('token', token);
    params = params.set('uid', uid);

    return this.http.get(this.baseUrl + 'auth/reset/', {params: params}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  isLoggedIn(): boolean {
    let storedUser = localStorage.getItem('currentUser');
    let currentUser = storedUser && JSON.parse(storedUser);
    if (currentUser && currentUser.token) {
      this.loggedIn = true;
    }

    return this.loggedIn;
  }

  user(): User {
    let storedUser = localStorage.getItem('currentUser');
    let currentUser = storedUser && JSON.parse(storedUser);
    return <User>currentUser;
  }

  isRuckit(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.ruckit) {
      this.ruckit = true;
    }

    return this.ruckit;
  }

  isCrh(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.crh) {
      this.crh = true;
    }

    return this.crh;
  }

  isScaleit(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.posEnabled) {
      this.scaleit = true;
    }

    return this.scaleit;
  }

  hasPOSEnabled(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.posEnabled) {
      this.posEnabled = true;
    }

    return this.posEnabled;
  }

  hasAdvancedBilling(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.advancedBilling) {
      this.advancedBilling = true;
    }

    return this.advancedBilling;
  }

  hasAllDriversEnabled(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.allDriversEnabled) {
      this.allDriversEnabled = true;
    }

    return this.allDriversEnabled;
  }

  canCreateJobs(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if ((currentUser && currentUser.canCreateJobs) ||
      (currentUser && currentUser.organization && currentUser.organization.can_create_jobs)) {
      this.createJobs = true;
    }
    return this.createJobs;
  }

  hasLeasedOrgs(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasLeasedOrgs) {
      this.leasedOrgs = true;
    }

    return this.leasedOrgs;
  }

  hasTrackables(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasTrackables) {
      this.trackables = true;
    }

    return this.trackables;
  }

  hasRuckit3(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasRuckit3) {
      this.ruckit3 = true;
    }

    return this.ruckit3;
  }

  hasLafargeRegion(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && (
      currentUser.lafargeRegion || currentUser.organization && currentUser.organization.lafargeRegion
    )) {
      this.lafargeRegion = true;
    } else {
      this.lafargeRegion = false;
    }

    return this.lafargeRegion;
  }

  hasWorkOrder(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasWorkOrder) {
      this.workOrders = true;
    }

    return this.workOrders;
  }

  hasSignature(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasSignature) {
      this.signature = true;
    }

    return this.signature;
  }

  hasFavoriteTags(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser && currentUser.favoriteTags && currentUser.favoriteTags.length > 0 ? true : false;
  }

  hasBillingModule(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.organization && currentUser.organization.features && currentUser.organization.features.hasBillingModule) {
      this.billingModuleEnabled = true;
    }

    return this.billingModuleEnabled;
  }

  hasLoadListsEnabled(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.organization && currentUser.organization.features && currentUser.organization.features.hasLoadListsEnabled) {
      this.loadListsEnabled = true;
    }

    return this.loadListsEnabled;
  }

  getOrganization(): Organization {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.organization) {
      return new Organization(currentUser.organization);
    }

    return null;
  }

  updateOrganization(organization): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      currentUser.organization = organization;
      this.storeUser(currentUser);
    }
  }

  myFavoriteTags(): any {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.favoriteTags && currentUser.favoriteTags.map(tag => tag.name);
  }

  getFilterProperty(filter): any {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.hasOwnProperty('filters')) {
      return currentUser.filters[filter];
    } else {
      return false;
    }
  }

  setFilterProperty(filter, state): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && !currentUser.hasOwnProperty('filters')) {
      currentUser['filters'] = {};
    }
    currentUser['filters'][filter] = state;
    this.storeUser(currentUser);
  }

  displaySidebar(): boolean {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.sidebar === false) {
      this.sidebar = false;
    } else {
      this.sidebar = true;
    }

    return this.sidebar;
  }

  private extractData(res: Object) {
    let body = res['results'];
    if (body) {
      return body.map(user => {
        user = camelcaseKeysDeep(user);
        return (new UserSerializer).fromJson(user);
      });
    } else if (res) {
      let user = camelcaseKeysDeep(res);
      return (new UserSerializer).fromJson(user);
    } else {
      return [];
    }
  }

  storeUser(user: User): boolean {
    if (user.token) {
      // set token property
      this.token = user.token;
      let sidebar = !(user.driver && !user.organization);
      let enabledFeatures = [];
      if (user.organization && user.organization.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.organization.enabledFeatures);
      }
      if (user.enabledFeatures) {
        enabledFeatures = enabledFeatures.concat(user.enabledFeatures);
      }

      const userInfo = {
        username: user.email,
        name: user.name,
        email: user.email,
        organization: user.organization,
        driver: user.isDriver,
        carrier: user.isCarrier,
        ruckit: user.isRuckit,
        crh: user.isCrh,
        id: user.id,
        canCreateJobs: user.organization && user.organization.canCreateJobs,
        posEnabled: user.organization && user.organization.posEnabled,
        advancedBilling: user.organization && user.organization.advBillingEnabled,
        scaleit: user.organization && user.organization.posEnabled,
        allDriversEnabled: user.organization && user.organization.allDriversEnabled,
        hasLeasedOrgs: user.organization && user.organization.hasLeasedOrgs,
        hasTrackables: user.organization && user.organization.hasTrackables,
        hasWorkOrder: user.organization && user.organization.hasWorkOrder,
        hasSignature: user.workOrderSignatureImage && user.workOrderSignatureImage.length,
        favoriteTags: user.favoriteTags, filters: user['filters'] || {},
        enabledFeatures: uniq(enabledFeatures),
        features: user.features,
        lafargeRegion: user.organization && user.organization.lafargeRegion
      };

      // store user data and JWT token in local storage to persist user session
      localStorage.setItem('currentUser', JSON.stringify({
        ...userInfo,
        token: user.token,
        sidebar,
        image: user.image,
      }));

      // return true to indicate successful login
      this.loggedIn = true;

      // sentry integration
      Sentry.configureScope((scope) => {
        scope.setUser(userInfo);
      });

      // fullstory integration
      const fullstory = (<any>window).FS;
      if (fullstory) {
        fullstory.identify(user.id, {
          ...userInfo,
          email: user.email,
          displayName: user.email,
        });
      }

      return true;
    } else {
      // return false to indicate failed login
      this.loggedIn = true;
      return false;
    }
  }

  enabledFeatures(): string[] {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return currentUser.enabledFeatures || [];
  }

  getFeature(feature: string) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    try {
      if (currentUser.features && currentUser.features[feature]) {
        return currentUser.features[feature];
      }
      if (currentUser.organization.features && currentUser.organization.features[feature]) {
        return currentUser.organization.features[feature];
      }
    } catch {
      return null;
    }
  }

  getOrgPropertyValue(propertyName: string): any {
    const currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.organization[propertyName];
  }
}
