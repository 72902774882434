import { timer as observableTimer, Subject, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import {
  Component, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild,
  ChangeDetectorRef, Input, Output, EventEmitter, ElementRef
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { NguiMapComponent, HeatmapLayer, CustomMarker } from '@ngui/map';
import * as moment from 'moment-timezone';
import { find as _find, clone, filter } from 'lodash';

import { DriverSerializer } from './../../drivers/driver.serializer';
import { mapOptions } from '../../shared/static-data';
import { MapService } from '../../map/map.service';
import { AuthenticationService } from '../../shared/index';
import { AssignmentService } from '../../assignments/assignment.service';
import { JobEventService } from '../../job-events/job-event.service';
import { LocationService } from '../../locations/location.service';
import { Organization } from '../../organizations/organization';
import { Job } from '../../jobs/job';
import { JobEvent } from '../../job-events/job-event';
import { PreferenceService } from '../../preferences/preference.service';
import { KeyCodes } from '../../shared/enums/key-codes.enum';
import { TruckSerializer } from '../../trucks/truck.serializer';
import { DEFAULT_MAP_REFRESH_TIME } from '../../../app/app.constants';

declare let google: any;

/**
 * Active Trucks Component: Displays a map with truck/driver markers for each
 * location update. The map also includes job-site locations, route polylines,
 * heatmaps, and info windows with additional marker detail.
 */
@Component({
  selector: 'active-trucks',
  templateUrl: './active-trucks.component.html',
  styleUrls: ['./active-trucks.component.scss']
})

export class ActiveTrucksComponent implements OnInit, OnDestroy, OnChanges {
  startDate: Date;
  mapData;
  mapReq: Subscription;
  assignmentsReq: Subscription;
  organizationLocationReq: Subscription;
  jobEventReq: Subscription;
  jobEventsTimerSub: Subscription;
  jobEventsTimer: Observable<number>;
  locations: any = [];
  locationUpdates: any = [];
  paverLocationUpdates: any = [];
  routes: any = [];
  parsedRoutes: any = [];
  polygons: any = [];
  loading = true;
  mapLoading = true;
  errors = [];
  map: google.maps.Map;
  heatmap: google.maps.visualization.HeatmapLayer;
  customLocationMarkers = [];
  customUpdateMarkers = [];
  customPaverUpdateMarkers = [];
  coordinates = [];
  mapControls = {
    traffic: false,
    heatmap: false,
    heatmapRadius: 20
  };
  showPending = true;
  organization: Organization;
  mapOptions = {
    disableDefaultUI: true,
    scrollwheel: true,
    fullscreenControl: true,
    zoomControl: true,
    mapTypeControl: true,
    fullscreenControlOptions: { position: 12 },
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'hybrid']
    },
    streetViewControl: false
  };
  markerType = 'driver-initials';
  markerStatus = 'each-segment';
  heatmapPoints = [];
  driverInfoWindowModel;
  truckInfoWindowModel;
  locationInfoWindowModel;
  @ViewChild(HeatmapLayer, { static: false }) heatmapLayer: HeatmapLayer;
  @ViewChild(NguiMapComponent, { static: false }) nguiMapComponent: NguiMapComponent;
  @ViewChild('mapSearch', { static: false }) mapSearch: ElementRef;
  search = '';
  searching = false;
  searchChanged: Subject<string> = new Subject<string>();
  driverListOpen = false;
  hours = [];
  assignments = {
    items: [],
    errors: [],
    loading: false,
  };
  selectedJob: Job;
  selectedJobEvent: JobEvent;
  activeJobs: Job[] = [];
  activeJobEvents: JobEvent[] = [];
  punchCardFlag = false;
  driversList: any[] = [];
  assignmentDetails: any = {};
  preferenceReqs: any = {};
  preferences: any = {};
  loadingMapPreferences = true;
  @Output() assignmentTitle: EventEmitter<any> = new EventEmitter<any>();
  @Input() assignmentToggle: boolean;
  public refreshMapMins = 2

  constructor(
    private mapService: MapService,
    private locationService: LocationService,
    private jobEventService: JobEventService,
    private assignmentService: AssignmentService,
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.mapLoading = true;
    this.getPreferences();
    this.getPreferences('GeneralUserPreferences');
    const activeTrucksMapRefresh = this.authenticationService.getFeature('activeTrucksMapRefresh') || DEFAULT_MAP_REFRESH_TIME;
    this.refreshMapMins = activeTrucksMapRefresh / 60 / 1000
    this.jobEventsTimer = observableTimer(activeTrucksMapRefresh, activeTrucksMapRefresh);
    this.jobEventsTimerSub = this.jobEventsTimer.subscribe(t => {
      this.getMapLocationUpdates(false, false);
    });
    this.organization = this.authenticationService.getOrganization();
    if (window.innerWidth <= 900) { this.loadAssignments(); }

    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.search = search;
      this.getMapLocationUpdates(this.mapLoading, true, true);
    });
  }

  ngOnDestroy() {
    if (this.jobEventsTimerSub && typeof this.jobEventsTimerSub.unsubscribe === 'function') {
      this.jobEventsTimerSub.unsubscribe();
    }
    if (this.jobEventsTimer) { this.jobEventsTimer = null; }
    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }
    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
    Object.keys(this.preferenceReqs).forEach(preferenceReqKey => {
      if (this.preferenceReqs[preferenceReqKey] && typeof this.preferenceReqs[preferenceReqKey].unsubscribe === 'function') {
        try { this.preferenceReqs[preferenceReqKey].unsubscribe(); } catch (e) { }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.assignmentToggle) {
      this.assignmentDetails = {};
      this.assignmentTitle.emit(null);
    }
  }

  onResize(event): void {
    if (window.innerWidth <= 900 && this.assignments.loading !== false) {
      this.loadAssignments();
    }
  }

  onSearchboxKeypress(event: KeyboardEvent) {
    if (event.code === KeyCodes.Enter) {
      this.changeSearch(this.search);
      event.preventDefault();
    }
  }

  changeSearch(term?: string): void {
    this.searchChanged.next(term);
    this.assignmentDetails = {};
    this.assignmentTitle.emit(null);
  }

  clearSearch(): void {
    this.searching = false;
    this.search = '';
    this.getMapLocationUpdates(this.mapLoading, true, true);
    if (this.nguiMapComponent) {
      this.nguiMapComponent.closeInfoWindow('driver-info-window');
      this.nguiMapComponent.closeInfoWindow('truck-info-window');
    }
  }

  getMapLocationUpdates(loading = true, centerMap = false, openInfoWindow = false, query = {}) {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
    this.loading = loading;
    this.startDate = new Date();
    let endDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }

    if (this.showPending) {
      query = { ...query, 'exclude_pending': 'True' };
    }

    this.mapReq = this.mapService.getLocationUpdates({
      driver__assignments__jobevent__shift1_start__gte: this.startDate.toISOString(),
      driver__assignments__jobevent__shift1_start__lte: endDate.toISOString(),
      ordering: 'jobs__name',
      search: this.search,
      ...query
    }, 'dashboard').subscribe(locationUpdates => {
      this.resetMarkers();
      this.locations = locationUpdates.locations;
      this.locationUpdates = locationUpdates.locationUpdates.map(update => {
        if (update.driver) { update.driver = new DriverSerializer().fromJson(update.driver); }
        return update;
      });
      this.paverLocationUpdates = locationUpdates.paverLocationUpdates.map(update => {
        if (update.truck) { update.truck = new TruckSerializer().fromJson(update.truck); }
        return update;
      });
      this.routes = locationUpdates.routes;
      this.parseRoutes();
      this.polygons = this.locations.map(location => {
        return location.paths;
      }).filter(Boolean);
      this.loading = false;
      this.mapData = locationUpdates;
      if (centerMap) { setTimeout(() => { this.centerMap(); }, 1000); }
      if (
        openInfoWindow && this.search && this.search.length &&
        this.locationUpdates.length === 1 && window.innerWidth > 900
      ) {
        this.openDriverInfoWindow(0, this.locationUpdates[0]);
      } else if (!this.search.length || this.locationUpdates.length === 0) {
        if (this.nguiMapComponent) {
          this.nguiMapComponent.closeInfoWindow('driver-info-window');
        }
      }
    }, err => {
      this.errors = err;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  parseRoutes(): void {
    if (this.map) {
      this.parsedRoutes = this.routes.map((route) => {
        if (route) {
          try {
            return new google.maps.geometry.encoding.decodePath(route);
          } catch (e) {
            console.log(e);
          }
        }
      }).filter(Boolean);
    }
  }

  centerMap(): void {
    let _centerOnOrganization = true;
    if (this.map) {
      let bounds = new google.maps.LatLngBounds();
      if (this.locations && this.locations.length) {
        this.locations.forEach(location => {
          let _lat = location.location && location.location.coordinates[1];
          let _lng = location.location && location.location.coordinates[0];
          if (_lat && _lng) {
            let latLng = new google.maps.LatLng(_lat, _lng);
            bounds.extend(latLng);
          }
        });
        _centerOnOrganization = false;
      }

      let locationUpdates = this.locationUpdates.map(locationUpdate => {
        let _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
        let _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
        if (_lat && _lng) {
          return new google.maps.LatLng(_lat, _lng);
        }
      });
      for (let i = 0, latLngLen = locationUpdates.length; i < latLngLen; i++) {
        let list = locationUpdates[i];
        bounds.extend(list);
      }

      let paverLocationUpdates = this.paverLocationUpdates.map(locationUpdate => {
        let _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
        let _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
        if (_lat && _lng) {
          return new google.maps.LatLng(_lat, _lng);
        }
      });
      for (let i = 0, latLngLen = paverLocationUpdates.length; i < latLngLen; i++) {
        let list = locationUpdates[i];
        bounds.extend(list);
      }

      if ((locationUpdates && locationUpdates.length) || (paverLocationUpdates && paverLocationUpdates.length)) {
        _centerOnOrganization = false;
      }

      if (_centerOnOrganization) { this.centerOnOrganization(); }
      this.map.fitBounds(bounds);
    }
  }

  centerOnOrganization(): void {
    if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
      this.organizationLocationReq.unsubscribe();
    }
    this.locationService.getLocationByAddress(`${this.organization.street}, ${this.organization.location}`).subscribe(coords => {
      if (coords && coords[0] && coords[0].geometry) {
        let fallbackCenter = new google.maps.LatLng(
          coords[0].geometry.location.lat, coords[0].geometry.location.lng
        );
        this.mapOptions = mapOptions({
          disableDefaultUI: true,
          scrollwheel: true,
          fullscreenControl: true,
          streetViewControl: false,
          center: fallbackCenter,
          geoFallbackCenter: fallbackCenter
        }, {}, {
          mapStyle: 'google-map-style'
        });
      }
    }, (err) => this.errors = ['Cannot get your organizations location']);
  }

  initMap(event: any): void {
    if (event) { this.map = event; }
    this.parseRoutes();
  }

  onCustomLocationMarkerInit(customMarker: CustomMarker): void {
    this.customLocationMarkers.push(customMarker);
  }

  onCustomUpdateMarkerInit(customMarker: CustomMarker): void {
    this.customUpdateMarkers.push(customMarker);
  }

  onCustomPaverUpdateMarkerInit(customMarker: CustomMarker): void {
    this.customPaverUpdateMarkers.push(customMarker);
  }
  
  onAssignmentTitle($event) {
    this.assignmentTitle.emit($event)
  }

  onOpenDriverInfoWindow({ index, update }){
    this.openDriverInfoWindow(index, update)
  }

  openDriverInfoWindow(index: number, update): void {
    this.driverInfoWindowModel = update;
    if (update && update.driver) {
      let markers = filter(this.customUpdateMarkers, (m) => {
        return m.htmlEl.id === update.driver.id;
      });
      if (markers && markers.length) {
        this.nguiMapComponent.openInfoWindow(
          'driver-info-window', markers[0]
        );
      }
    }
  }

  openTruckInfoWindow(index: number, update): void {
    this.truckInfoWindowModel = update;
    if (update && update.truck) {
      let markers = filter(this.customPaverUpdateMarkers, (m) => {
        return m.htmlEl.id === update.truck.id;
      });
      if (markers && markers.length) {
        this.nguiMapComponent.openInfoWindow(
          'truck-info-window', markers[0]
        );
      }
    }
  }

  openLocationInfoWindow(index: number, location): void {
    this.locationInfoWindowModel = {
      title: location.displayName,
      address: location.street,
      city: location.city,
      state: location.state,
      zip: location.zip
    };
    this.nguiMapComponent.openInfoWindow('location-info-window', this.customLocationMarkers[index]);
  }

  toggleTraffic(): void {
    if (this.mapControls.traffic) {
      this.mapControls.traffic = false;
    } else {
      this.mapControls.traffic = true;
    }
    this.cdr.detectChanges();
  }

  toggleHeatmap(): void {
    if (this.mapControls.heatmap) {
      this.mapControls.heatmap = false;
      this.heatmap.setMap(null);
    } else {
      this.mapControls.heatmap = true;
      this.heatmap.setMap(this.map);
    }
    this.cdr.detectChanges();
  }

  onHeatmapInitialized(e = null): void {
    this.heatmap = e;
    this.coordinates = [];
    this.updateHeatmap();
  }

  onMapIdle(e = null): void {
    if (this.heatmap) { this.updateHeatmap(); }
  }

  updateHeatmap() {
    // this.locationUpdateService.coordinates(this.map.getBounds(), {
    //   jobevent: this.jobEvent && this.jobEvent.id,
    //   page_size: 2500
    // }).subscribe(coordinates => {
    //   this.coordinates = coordinates;
    //   this.heatmapPoints = coordinates && coordinates.map((coordinate) => {
    //     return { location: new google.maps.LatLng(coordinate.lon, coordinate.lat), weight: coordinate.weight || 1 };
    //   });
    //   if (this.heatmapPoints && this.heatmapPoints.length > 0) {
    //     this.heatmap.setData(this.heatmapPoints);
    //   }
    //   this.cdr.detectChanges();
    // }, err => { console.error(err); });
  }

  changeHeatmapRadius(e = null) {
    if (e && this.mapControls) {
      this.mapControls.heatmapRadius = e.target.value;
      this.heatmap.set('radius', e.target.value);
      // this.cdr.detectChanges();
    }
  }

  openDriverList() {
    this.driverListOpen = !this.driverListOpen;
  }

  loadAssignments(query = {}) {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    this.assignments.loading = true;
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    let endDate = clone(this.startDate);
    endDate.setHours(23, 59, 59, 999);

    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }

    this.assignmentsReq = this.assignmentService.list({
      include_trips: 'True',
      completed: 'False',
      dispatched: 'True',
      jobevent__shift1_start__gte: this.startDate.toISOString(),
      jobevent__shift1_start__lte: endDate.toISOString(),
      ...query
    }).subscribe(assignments => {
      this.assignments.items = assignments.map((assignment) => {
        if (assignment.driver) {
          assignment.driver = new DriverSerializer().fromJson(assignment.driver);

          if (assignment.driver) {
            let driverMatch = _find(this.driversList, assignment.driver.id);
            if (!driverMatch) { this.driversList.push(assignment.driver.id); }
          }

          if (assignment.trips && assignment.trips.length) {
            if (!assignment.trips[0].completed) {
              assignment.driver.trip = assignment.trips[0];
              this.durationInMinutes(assignment.driver.trip);
            }
          }
        }
        if (assignment.jobevent) {
          let jobEventId = assignment.jobevent.toString();
          let jobEventMatch = _find(this.activeJobEvents, { id: jobEventId });
          if (!jobEventMatch) {
            if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
              this.jobEventReq.unsubscribe();
            }

            this.jobEventReq = this.jobEventService.getJobEvent(jobEventId).subscribe(jobEvent => {
              this.activeJobEvents.push(jobEvent);
              this.punchCardFlag = (jobEvent.invoiceType === 'hourly') ? true : false;
              this.loading = false;
              let jobId = jobEvent.job && jobEvent.job.id;
              let jobMatch = _find(this.activeJobs, { id: jobId });
              if (!jobMatch) {
                this.activeJobs.push(jobEvent.job);
              }
            }, err => {
              this.errors = err;
              this.loading = false;
            });
          }
        }
        return assignment;
      });
      this.getMapLocationUpdates();
    }, err => {
      this.assignments.errors = err;
    }, () => {
      this.assignments.loading = false;
    });
  }

  durationInMinutes(obj) {
    obj.durationTimer = observableTimer(1, 60000);
    obj.durationTimer.subscribe(t => {
      let duration = moment().diff(obj.startTimeTimestamp, 'minutes') + ' mins';
      obj.duration = duration;
    });
  }

  /**
  * If the window is less than 900px wide, the assignment details are assigned
  * to component properties and displayed in the template.
  *
  * If the window is greater than or equal to 900px wide, the Driver Info Window
  * is opened on the map.
  *
  * @param {any} update The location update
  * @param {any} index The marker index in the array
  */
  openAssignmentDetails(update, index): void {
    if (window.innerWidth < 900) {
      let latLng;
      this.assignmentDetails = {};
      if (update && update.location && update.driver && update.driver.id) {
        this.assignmentDetails = _find(this.assignments.items, { driver: { id: update.driver.id } });
        latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
      } else if (update && update.shift && update.driver && update.driver.id) {
        this.assignmentDetails = update;
        let locationUpdate = _find(this.locationUpdates, { driver: { id: update.driver.id } });
        if (locationUpdate && locationUpdate['location']) {
          latLng = new google.maps.LatLng(locationUpdate['location'].coordinates[1], locationUpdate['location'].coordinates[0]);
        }
      }
      if (this.map && latLng) {
        // this.map.setCenter(latLng);
        this.map.setZoom(17);
      }
      if (this.assignmentDetails && this.assignmentDetails.jobevent) {
        this.selectedJobEvent = _find(this.activeJobEvents, { id: this.assignmentDetails['jobevent'] });
        this.selectedJob = _find(this.activeJobs, { id: this.selectedJobEvent['job']['id'] });
        this.assignmentTitle.emit(this.assignmentDetails['driver']['profile']['name']);
      } else {
        this.errors = ['There was an issue getting the assignment details.'];
      }
    } else {
      this.openDriverInfoWindow(index, update);
    }
  }

  /**
  * If the window is less than 900px wide, the truck details are assigned
  * to component properties and displayed in the template.
  *
  * If the window is greater than or equal to 900px wide, the Truck Info Window
  * is opened on the map.
  *
  * @param {any} update The location update
  * @param {any} index The marker index in the array
  */
  openTruckDetails(update, index): void {
    if (window.innerWidth < 900) {
      let latLng;
      if (update && update.location && update.truck && update.truck.id) {
        latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
      }
      if (this.map && latLng) {
        this.map.setCenter(latLng);
        this.map.setZoom(17);
      }
    } else {
      this.openTruckInfoWindow(index, update);
    }
  }

  /**
  * Focuses the mapSearch element after a 300ms delay
  */
  showSearch(): void {
    this.searching = true;
    setTimeout(() => {
      if (this.mapSearch) { this.mapSearch.nativeElement.focus(); }
    }, 300);
  }

  /**
  * Removes all exsiting location and location update markers
  */
  resetMarkers(): void {
    this.customLocationMarkers = [];
    this.customUpdateMarkers = [];
    this.locationUpdates = [];
    this.paverLocationUpdates = [];
  }

  togglePending(): void {
    this.showPending = !this.showPending;
    this.savePreferences('ActiveTrucks-LocationUpdates', {
      filters: { showPending: this.showPending }
    });
    this.getMapLocationUpdates(this.mapLoading, true, true);
  }

  getPreferences(preferenceKey = 'ActiveTrucks-LocationUpdates', params = null): void {
    if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
      try { this.preferenceReqs[preferenceKey].unsubscribe(); } catch (e) { }
    }
    let currentUser = this.authenticationService.user();
    if (!params) {
      params = {
        name: preferenceKey,
        type: 'user',
        profile: currentUser.id
      };
    }
    this.preferenceReqs[preferenceKey] = this.preferenceService.list(params).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preferences[preferenceKey] = preferences[0];
        this.parsePreferences(preferenceKey);
      } else if (preferenceKey === 'GeneralUserPreferences') {
        this.loadingMapPreferences = false;
        this.getMapLocationUpdates(this.mapLoading, true, true);
      }
    }, err => {
      this.errors = err;
      this.loadingMapPreferences = false;
    }, () => {
      this.loadingMapPreferences = false;
    });
  }

  savePreferences(preferenceKey = 'ActiveTrucks-LocationUpdates', blob): void {
    if (preferenceKey) {
      let currentUser = this.authenticationService.user();
      this.preferences[preferenceKey] = {
        ...this.preferences[preferenceKey],
        name: preferenceKey,
        type: 'user',
        profile: currentUser.id,
        blob: blob
      };
      this.preferenceService.save(this.preferences[preferenceKey]).subscribe(preference => {
        this.preferences[preferenceKey] = preference;
      });
    }
  }

  /**
   * Examine the found preference for the provided key to determine if it has
   * the necessary data.
   *
   * Once the preferences data is handled, update the map
   */
  parsePreferences(preferenceKey = 'ActiveTrucks-LocationUpdates'): void {
    if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['filters']) {
      let filters = this.preferences[preferenceKey].blob['filters'];
      this.showPending = filters && filters['showPending'];
    }
    if (preferenceKey === 'GeneralUserPreferences') {
      let mapStyle = 'google-map-style';
      if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
        mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
      }
      this.markerType = 'driver-initials';
      if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
        this.markerType = this.preferences[preferenceKey].blob['markerType'];
      }
      if (!this.markerStatus) { this.markerStatus = 'each-segment'; }
      if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerStatus']) {
        this.markerStatus = this.preferences[preferenceKey].blob['markerStatus'];
      }
      this.mapOptions = mapOptions({
        disableDefaultUI: true,
        scrollwheel: true,
        fullscreenControl: true,
        streetViewControl: false
      }, {}, {
        mapStyle: mapStyle
      });
      this.loadingMapPreferences = false;
      this.getMapLocationUpdates(this.mapLoading, true, true);
    }
    this.loadingMapPreferences = false;
  }
}
