"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./confirm-drivers-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/flex-layout/flex");
var i3 = require("@angular/flex-layout/core");
var i4 = require("@ngx-translate/core");
var i5 = require("@angular/common");
var i6 = require("@angular/forms");
var i7 = require("@angular/material/dialog");
var i8 = require("./confirm-drivers-dialog.component");
var styles_ConfirmDriversDialogComponent = [i0.styles];
var RenderType_ConfirmDriversDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDriversDialogComponent, data: {} });
exports.RenderType_ConfirmDriversDialogComponent = RenderType_ConfirmDriversDialogComponent;
function View_ConfirmDriversDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Driver Is Busy"]))], null, null); }
function View_ConfirmDriversDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Drivers Are Busy"]))], null, null); }
function View_ConfirmDriversDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "field-row"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵted(3, null, [" ", ": ", " "]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.jobeventName; var currVal_3 = _v.context.$implicit.time; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_ConfirmDriversDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "body form-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "field-row heavy separator"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵted(4, null, [" ", " ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["is assigned to another job at this time."])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDriversDialogComponent_4)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 3, 0, currVal_1); var currVal_4 = ""; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.drivers[0].conflicts; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.drivers[0].profile.firstName; var currVal_3 = _co.drivers[0].profile.lastName; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
function View_ConfirmDriversDialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.jobeventName; var currVal_1 = _v.context.$implicit.time; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ConfirmDriversDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "field-row separator"], ["fxLayout", "column"], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "heavy"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDriversDialogComponent_7)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 2, 0, currVal_1); var currVal_4 = _v.context.$implicit.conflicts; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.profile.firstName; var currVal_3 = _v.context.$implicit.profile.lastName; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
function View_ConfirmDriversDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "body form-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "field-row separator"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "heavy"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["drivers are assigned to another job at this time:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDriversDialogComponent_6)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 3, 0, currVal_1); var currVal_3 = ""; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.drivers; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.drivers.length; _ck(_v, 5, 0, currVal_2); }); }
function View_ConfirmDriversDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 30, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, [["editJobDay", 4]], 0, i6.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.NgForm]), i1.ɵdid(4, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(6, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "span", [], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i5.NgPlural, [i5.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ConfirmDriversDialogComponent_1)), i1.ɵdid(11, 16384, null, 0, i5.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i5.NgPlural], null, null), i1.ɵdid(12, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ConfirmDriversDialogComponent_2)), i1.ɵdid(14, 16384, null, 0, i5.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i5.NgPlural], null, null), i1.ɵdid(15, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(19, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDriversDialogComponent_3)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDriversDialogComponent_5)), i1.ɵdid(23, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["class", "btn btn-cancel"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.dialogRef.close();
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.submit();
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Assign Job "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_8 = _co.drivers.length; _ck(_v, 9, 0, currVal_8); var currVal_9 = ""; _ck(_v, 12, 0, currVal_9); var currVal_10 = ""; _ck(_v, 15, 0, currVal_10); var currVal_11 = (_co.drivers.length == 1); _ck(_v, 21, 0, currVal_11); var currVal_12 = (_co.drivers.length > 1); _ck(_v, 23, 0, currVal_12); var currVal_13 = ""; _ck(_v, 26, 0, currVal_13); var currVal_14 = ""; _ck(_v, 29, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 6).id; _ck(_v, 5, 0, currVal_7); }); }
exports.View_ConfirmDriversDialogComponent_0 = View_ConfirmDriversDialogComponent_0;
function View_ConfirmDriversDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-drivers-dialog", [], null, null, null, View_ConfirmDriversDialogComponent_0, RenderType_ConfirmDriversDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.ConfirmDriversDialogComponent, [i7.MatDialogRef], null, null)], null, null); }
exports.View_ConfirmDriversDialogComponent_Host_0 = View_ConfirmDriversDialogComponent_Host_0;
var ConfirmDriversDialogComponentNgFactory = i1.ɵccf("confirm-drivers-dialog", i8.ConfirmDriversDialogComponent, View_ConfirmDriversDialogComponent_Host_0, { drivers: "drivers" }, {}, []);
exports.ConfirmDriversDialogComponentNgFactory = ConfirmDriversDialogComponentNgFactory;
