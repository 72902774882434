"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var shared_1 = require("../shared");
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(authenticationService, cdr, route, router) {
        this.authenticationService = authenticationService;
        this.cdr = cdr;
        this.route = route;
        this.router = router;
        this.loading = true;
        this.errors = [];
        this.toolbarTitle = 'Dashboard';
        this.assignmentToggle = true;
        this.enabledFeatures = [];
    }
    DashboardComponent.prototype.ngOnInit = function () {
        this.enabledFeatures = this.authenticationService.enabledFeatures();
    };
    DashboardComponent.prototype.ngAfterViewInit = function () {
        this.toolbarTitle = (window.innerWidth > 900) ? 'Dashboard' : 'Live Map';
        this.cdr.detectChanges();
    };
    DashboardComponent.prototype.onResize = function (event) {
        this.toolbarTitle = (event.target.innerWidth > 900) ? 'Dashboard' : 'Live Map';
        this.cdr.detectChanges();
    };
    DashboardComponent.prototype.toolbarState = function (assignmentTitle) {
        this.toolbarTitle = assignmentTitle ? assignmentTitle : 'Live Map';
        this.assignmentToggle = true;
        this.cdr.detectChanges();
    };
    DashboardComponent.prototype.updatedMarkets = function () {
        if (this.activeTrucks) {
            this.activeTrucks.ngOnInit();
        }
        if (this.needsDispatched) {
            this.needsDispatched.ngOnInit();
        }
        if (this.locationStatus) {
            this.locationStatus.ngOnInit();
        }
        if (this.dailyProduction) {
            this.dailyProduction.ngOnInit();
        }
        if (this.assignmentAcceptance) {
            this.assignmentAcceptance.ngOnInit();
        }
    };
    return DashboardComponent;
}());
exports.DashboardComponent = DashboardComponent;
