"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var dialogs_1 = require("../../shared/dialogs");
var job_event_service_1 = require("../../job-events/job-event.service");
var rxjs_1 = require("rxjs");
var ConfirmChangeJobLoadsService = /** @class */ (function () {
    function ConfirmChangeJobLoadsService(dialog, jobEventService) {
        this.dialog = dialog;
        this.jobEventService = jobEventService;
    }
    ConfirmChangeJobLoadsService.prototype.validateLoadsRequested = function (params) {
        var _this = this;
        var id = params.jobEvent.id;
        return new rxjs_1.Observable(function (observer) {
            _this._observer = observer;
            _this._jobEventId = id;
            _this._validateLimitLoads(params);
        });
    };
    ConfirmChangeJobLoadsService.prototype.isGreaterAssigned = function (_a) {
        var jobEvent = _a.jobEvent, shifts = _a.shifts, currentAssignation = _a.currentAssignation, _b = _a.countSameChunk, countSameChunk = _b === void 0 ? 0 : _b;
        var currentAssignmentId = currentAssignation.assignmentId, numberOfLoadsType = currentAssignation.numberOfLoadsType, maxNumberOfLoads = currentAssignation.maxNumberOfLoads;
        var dailyDeliveryTarget = jobEvent.dailyDeliveryTarget, dailyDeliveryTargetType = jobEvent.dailyDeliveryTargetType;
        if (dailyDeliveryTargetType === 'loads' && numberOfLoadsType !== 'allDay' && maxNumberOfLoads > 0) {
            var loadsRequested = Number(dailyDeliveryTarget);
            var currentLoadsToAssign = Number(currentAssignation.maxNumberOfLoads) || 1;
            var loadsAssigned = this._getLoadsAssigned({ jobEvent: jobEvent, shifts: shifts, currentAssignmentId: currentAssignmentId }) + countSameChunk;
            return loadsRequested < (loadsAssigned + currentLoadsToAssign);
        }
        return false;
    };
    ConfirmChangeJobLoadsService.prototype._validateLimitLoads = function (params) {
        if (this.isGreaterAssigned(params)) {
            var jobEvent = params.jobEvent;
            if (jobEvent.canEdit) {
                this._showConfirmationModal(params);
            }
            else {
                this.showConfirmationProceedModal();
            }
        }
        else {
            this._observer.next(true);
            this._observer.complete();
        }
    };
    ConfirmChangeJobLoadsService.prototype._getAssignments = function (_a) {
        var jobEvent = _a.jobEvent, shifts = _a.shifts;
        if (jobEvent && jobEvent['assignments']) {
            return jobEvent['assignments'];
        }
        else if (shifts) {
            return shifts.map(function (shift) { return (shift.slots.map(function (slot) { return slot.assignment; })); }).flat();
        }
        else {
            return [];
        }
    };
    ConfirmChangeJobLoadsService.prototype._getLoadsAssigned = function (_a) {
        var jobEvent = _a.jobEvent, shifts = _a.shifts, currentAssignmentId = _a.currentAssignmentId;
        var assignments = this._getAssignments({ jobEvent: jobEvent, shifts: shifts });
        var loadsAssigned = assignments.reduce(function (loads, assignment) {
            var assignmentId = assignment.id;
            var loadSlot = assignmentId !== currentAssignmentId ? assignment.maxNumberOfLoads : 0;
            return loads + (loadSlot > 0 ? Number(loadSlot) : 0);
        }, 0);
        return loadsAssigned || 0;
    };
    ConfirmChangeJobLoadsService.prototype._showConfirmationModal = function (params) {
        var _this = this;
        var jobEvent = params.jobEvent, shifts = params.shifts, currentAssignation = params.currentAssignation, _a = params.countSameChunk, countSameChunk = _a === void 0 ? 0 : _a;
        var loadsRequested = Number(jobEvent.dailyDeliveryTarget);
        var currentLoadsToAssign = Number(currentAssignation.maxNumberOfLoads) || 1;
        var currentAssignmentId = currentAssignation.assignmentId;
        var loadsAssigned = this._getLoadsAssigned({ jobEvent: jobEvent, shifts: shifts, currentAssignmentId: currentAssignmentId }) + countSameChunk;
        var totalLoadsAssigned = (loadsAssigned + currentLoadsToAssign);
        var messageAssigned = loadsAssigned > 0 ? " and the " + loadsAssigned + " previously assigned" : '';
        var confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '530px',
            data: {
                title: "Loads Ordered Exceeded",
                message: "\n          The number of loads ordered has exceeded by the " + currentLoadsToAssign + " load(s) selected" + messageAssigned + ".\n          Do you want to assign the selected load(s) and increase the total loads ordered for this job to " + totalLoadsAssigned + "?\n        ",
                acceptText: 'Assign & Update Loads'
            }
        });
        confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.jobEventService.save({ id: _this._jobEventId, dailyDeliveryTarget: totalLoadsAssigned }).subscribe(function () {
                    jobEvent.dailyDeliveryTarget = totalLoadsAssigned;
                    _this._observer.next(true);
                    _this._observer.complete();
                }, function (err) {
                    _this._observer.error(err);
                    _this._observer.complete();
                });
            }
            else {
                var maxLoads = loadsRequested - loadsAssigned;
                _this._observer.error({ maxLoads: maxLoads <= 0 ? 1 : maxLoads });
                _this._observer.complete();
            }
        });
    };
    ConfirmChangeJobLoadsService.prototype.showConfirmationProceedModal = function () {
        var fnObserver;
        var observable = new rxjs_1.Observable(function (observer) { return (fnObserver = observer); });
        if (this._observer && !this._observer.closed) {
            fnObserver = this._observer;
        }
        var confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '530px',
            data: {
                title: "Loads Requested Exceeded",
                message: "The number of loads you have selected exceeds the loads requested for this job. Do you want to proceed with assigning the selected loads?",
                acceptText: 'Proceed and Assign Loads'
            }
        });
        confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                fnObserver.next({ byPass: true });
            }
            else {
                fnObserver.error();
            }
            fnObserver.complete();
        });
        return observable;
    };
    return ConfirmChangeJobLoadsService;
}());
exports.ConfirmChangeJobLoadsService = ConfirmChangeJobLoadsService;
