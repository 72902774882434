import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as moment from 'moment-timezone';
import { reject, find as _find, findIndex, clone, sortBy } from 'lodash';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { JobService } from '../jobs/job.service';
import { Job } from '../jobs/job';
import { JobEventService } from '../job-events/job-event.service';
import { JobEventStatService } from '../job-event-stats/job-event-stat.service';
import { CollaboratorService } from './collaborator.service';
import { AuthenticationService } from '../shared';
import { PublishJobDialogComponent } from '../jobs/publish-job-dialog.component';
import { JobEvent } from '../job-events/job-event';
import { JobEventShare } from '../job-event-shares/job-event-share';
import { CollaboratorListComponent } from './collaborator-list/collaborator-list.component';
import { JOBWEIGHTOPTIONS } from '../app.constants';

@Component({
  selector: 'ruckit-collaborator',
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss'],
  providers: [JobService, JobEventService, JobEventStatService, CollaboratorService]
})
export class CollaboratorsComponent implements OnInit {
  job: Job;
  jobId: string;
  jobEvent: JobEvent;
  jobEventId: string;
  jobEvents: JobEvent[] = [];
  jobDays: any = [];
  enabledDays: any = [];
  sortBy: string;
  addingCollaborator = false;
  sortAsc = true;
  sortParameter: string;
  private jobDate: Date;
  collaborators: any = [];
  pristineCollaborators: any = [];
  loading = true;
  jobLoading = true;
  jobEventsLoading = true;
  daysLoading = true;
  weightOptions = [...JOBWEIGHTOPTIONS];
  rateTrackingOptions = [
    { value: 'load', label: 'Load', name: 'Load' },
    { value: 'hour', label: 'Hour', name: 'Hour' }
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  startDate = new Date('2017-01-01');
  endDate = new Date('2037-12-31');
  datesOptions = {
    min: this.startDate,
    max: this.endDate
  };
  actionsDropdownOptions = [];
  actionsDropdownConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  returnTo: string;
  returnToQueryParams: any = {};
  returnToTitle = 'Back';
  @ViewChild('actionsDropdown', { static: false }) actionsDropdown;
  @ViewChild('jobEventsDropdown', { static: true }) jobEventsDropdown;
  @ViewChild('collaboratorList', { static: false }) collaboratorList: CollaboratorListComponent;

  days = {};
  daysReq: Subscription;
  jobEventsReq: Subscription;
  errors = [];
  dialogRef: MatDialogRef<PublishJobDialogComponent>;
  @ViewChild('content', { static: false }) private contentContainer: ElementRef;

  publishJobCallback = (e) => {
    // Update Published Status
  }
  extendCollaborationCallback = (collaborator) => {
    let index = findIndex(this.collaborators, {id: collaborator.id});
    this.collaborators.splice(index, 1, collaborator);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private jobService: JobService,
    private jobEventService: JobEventService,
    private jobEventStatService: JobEventStatService,
    private collaboratorService: CollaboratorService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.sortBy = 'status';
    this.route.fragment.subscribe(fragment => {
      this.addingCollaborator = fragment === 'addCollaborator' ? true : false;
    });
    this.route.params.forEach((params: Params) => {
      this.jobId = params['jobId'];
      this.jobEventId = params['jobEventId'];
      if (this.jobEventId) { this.getJob(this.jobEventId); }
    });
    this.route.queryParams.forEach((qparams: Params) => {
      if (qparams['returnTo'] && qparams['returnTo'].length) {
        const [ routerPart, queryParamsPart ] = qparams['returnTo'].split('?');
        this.returnTo = routerPart;
        if (queryParamsPart) {
          const queryParamsArray = queryParamsPart.split('&');
          queryParamsArray.forEach((param: string) => {
            const [ key, value ] = param.split('=');
            this.returnToQueryParams[key] = decodeURIComponent(value);
          });
        }
      }
      if (qparams['returnToTitle'] && qparams['returnToTitle'].length) {
        this.returnToTitle = qparams['returnToTitle'];
      }
    });
  }

  ngOnDestroy() {
    if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
      this.daysReq.unsubscribe();
    }
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
  }

  isSendable(): boolean {
    // TODO: What scenarios would render a Job "unsendable"?
    return true;
  }

  isDispatchable(): boolean {
    if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.project && this.jobEvent.job.project.customerOrganization) {
      const organization = this.authenticationService.getOrganization();
      if (organization && organization.id !== this.jobEvent.job.project.customerOrganization.id) {
        let endDate = new Date(this.jobEvent.jobEnd);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (endDate >= today) {
          return true;
        }
      }
    }
    return false;
  }

  openShareDialog(): void {
    if (this.isSendable()) {
      this.dialogRef = this.dialog.open(PublishJobDialogComponent, {
        width: '870px',
        data: { job: this.job, jobevent: this.jobEvent }
      });
      this.dialogRef.componentInstance.callback = this.publishJobCallback;
    }
  }

  addActionButtons(): void {
    if (this.jobEvent && this.jobEvent.canEdit) {
      this.actionsDropdownOptions.push({ name: 'Edit Job Days', button: true });
    }
  }

  validMultipliers(collaborator): boolean {
    collaborator.multipliers.forEach(multiplier => {
      if (multiplier.haulPercentage < 0 || multiplier.haulPercentage > 100) {
        this.errors = ['Ensure any percentages are between 0 and 100.'];
        return false;
      }
    });
    return true;
  }

  submit(collaborator, range = null) {
    if (range === 'all') {
      collaborator['allDays'] = true;
    } else if (range === 'future') {
      collaborator['future'] = true;
    } else {
      collaborator['allDays'] = false;
      collaborator['future'] = false;
    }
    if (collaborator.multipliers && collaborator.multipliers.length > 0) {
      collaborator['invoiceType'] = this.jobEvent.invoiceType;
      if (this.validMultipliers(collaborator) === false) {
        return;
      }
    }
    this.collaboratorService.save(this.jobEventId, collaborator).subscribe(() => {
      collaborator.editing = false;
      this.loading = false;
    }, err => {
      console.error(err);
      this.loading = false;
    });
  }

  getJob(jobEventId = null): void {
    this.jobLoading = true;
    this.jobService.get(this.jobId).subscribe(job => {
      this.job = job;
      this.getJobDays(this.jobId);
      if (this.job.allowWeight) {
        this.rateTrackingOptions = this.rateTrackingOptions.concat([
          { value: 'weight', label: 'Weight', name: 'Weight' }
        ]);
      }
      let start = new Date(this.jobDate);
      let jobStart = new Date(this.job.startDate);
      if (start && jobStart > start) {
        this.setDefaultDate();
        start = new Date(this.jobDate);
      }

      start.setHours(0, 0, 0, 0);
      let end = clone(start);
      end.setHours(23, 59, 59, 999);

      if (jobEventId) {
        this.getJobEvent(jobEventId);
      } else {
        this.getJobEvents({
          shift1_start__gte: start.toISOString(),
          shift1_start__lte: end.toISOString()
        }, true);
      }
      this.addActionButtons();
    });
  }

  getJobDays(jobId: string): void {
    if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
      this.daysReq.unsubscribe();
    }

    this.daysLoading = true;

    this.daysReq = this.jobService.getDays(jobId).subscribe(days => {
      this.jobDays = days.map(day => {
        let parsedDate = moment(day);
        return { day: parsedDate.date(), month: parsedDate.month() + 1, year: parsedDate.year() };
      });
      this.enabledDays = clone(this.jobDays);
      this.datesOptions = {
        min: this.startDate,
        max: this.endDate,
        // enableDays: this.enabledDays
      };
      this.daysLoading = false;
    }, err => {
      this.errors = err;
      this.daysLoading = false;
    });
  }

  setDefaultDate(): void {
    let d: Date = new Date();
    if (this.job && this.job.startDate) {
      let start = new Date(this.job.startDate);
      start.setDate(start.getDate() + 1);
      if (start > d) {
        d = start;
      }
    }

    this.jobDate = d;
  }

  showAddCollaboratorForm(): void {
    this.addingCollaborator = true;
    // NOTE: setTimeout is required to ensure full loading of the Add
    // Collaborator Form. Otherwise, the view isn't scrolled all the way down.
    setTimeout(() => {
      this.contentContainer.nativeElement.scrollTop = this.contentContainer.nativeElement.scrollHeight;
    }, 10);
  }

  collaboratorAdded(jobEventShare: JobEventShare) {
    if (jobEventShare) {
      this.addingCollaborator = false;
      if (this.collaboratorList) { this.collaboratorList.reloadRecords(); }
    }
  }

  getJobEvent(jobEventId: string): void {
    this.jobEventService.getJobEvent(jobEventId).subscribe(jobEvent => {
      this.jobEvent = jobEvent;
      this.getJobEventStats();
      this.getJobEvents({ job: this.jobId });
      this.jobEventId = jobEvent && jobEvent.id;
      if (this.jobEvent) {
        this.jobDate = new Date(jobEvent.shift1StartTimestamp);
        if (this.jobEvent.multipliers && this.jobEvent.multipliers.length > 0) {
          this.rateTrackingOptions = this.rateTrackingOptions.concat([
            { value: 'percentage', label: 'Percentage', name: 'Percentage' }
          ]);
        }
      }
      this.jobLoading = false;
    }, err => {
      this.errors = err;
      this.jobLoading = false;
    });
  }

  getJobEvents(query = {}, selectFirst = false): void {
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
    this.jobEventsReq = this.jobEventService.getJobEvents({
      ordering: 'shift1_start',
      job: this.job && this.job.id,
      ...query
    }).subscribe(jobEvents => {
      this.jobEvents = jobEvents;
      if (selectFirst) {
        this.selectJobEvent(this.jobEvents[0]);
        this.jobLoading = false;
      }
      if (this.jobEvent) {
        let jobEvent = _find(this.jobEvents, { id: this.jobEventId });
        if (jobEvent) {
          this.jobEvents = reject(this.jobEvents, jobEvent);
        } else {
          jobEvent = this.jobEvent;
        }
        this.jobEvents.unshift(jobEvent);
        if (this.jobEventsDropdown) {
          this.jobEventsDropdown.selectedOption = jobEvent;
        }
        this.jobEventsLoading = false;
      }
    }, err => {
      this.errors = err;
      this.jobLoading = false;
      this.jobEventsLoading = false;
    });
  }

  datepickerToDateFormat(value: any) {
    let _day = <Date>value;
    let timezone = moment.tz.guess();
    let date = new Date(_day.toDateString() + ' 00:00:00');
    return moment.tz(date, timezone).toDate();
  }

  getNextDate(selectedDate, advance = true) {
    let _dates = this.enabledDays.map((day) => {
      return this.datepickerToDateFormat(day);
    });
    _dates = sortBy(_dates, (d) => { return d; });
    let pickedDate = selectedDate;
    let futureDates = _dates.filter((date) => {
      return (date.getTime() - pickedDate.getTime()) > 0;
    });
    let pastDates = _dates.filter((date) => {
      return (date.getTime() - pickedDate.getTime()) < 0;
    });

    if (advance) {
      return futureDates[0];
    } else {
      return pastDates[pastDates.length - 1];
    }
  }

  selectJobEvent(jobEvent: JobEvent): void {
    this.jobEvent = jobEvent;
    this.jobEventId = this.jobEvent && this.jobEvent.id;
    this.getJobEventStats();
    if (this.job && this.jobEvent) {
      this.location.replaceState(
        'jobs/' + this.jobId + '/' + this.jobEventId + '/collaborators'
      );
      if (this.jobEvent.multipliers && this.jobEvent.multipliers.length > 0) {
        this.rateTrackingOptions = this.rateTrackingOptions.concat([
          { value: 'percentage', label: 'Percentage', name: 'Percentage' }
        ]);
      }
    }
    if (this.collaboratorList) { this.collaboratorList.reloadRecords(); }
  }

  getJobEventStats(): void {
    let jobEventId = this.jobEvent && this.jobEvent.id;

    let effectiveRateCalculator = '';
    const enabledFeatures = this.authenticationService.enabledFeatures();
    if (enabledFeatures && enabledFeatures.includes('effectiveRateCalculator')) {
      effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
    }

    this.jobEventStatService.getStats(jobEventId, {
      calculator: effectiveRateCalculator
    }).subscribe(
      stats => {
        this.jobEvent.stats = stats;
      },
      err => console.error(err)
    );
  }
}
