"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
// libraries
var moment = require("moment");
var lodash_1 = require("lodash");
// services
var api_service_1 = require("../../shared/api.service");
var assignment_filter_service_1 = require("../../assignments/assignment.filter.service");
var driver_service_1 = require("../../drivers/driver.service");
var assignment_service_1 = require("../../assignments/assignment.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var locationUpdate_service_1 = require("../../jobs/locationUpdate.service");
var location_service_1 = require("../../locations/location.service");
var shared_1 = require("../../shared");
// components
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
var RuckitReplayComponent = /** @class */ (function () {
    function RuckitReplayComponent(route, router, location, assignmentFilterService, locationService, assignmentsService, jobEventService, authenticationService, locationUpdateService) {
        this.route = route;
        this.router = router;
        this.location = location;
        this.assignmentFilterService = assignmentFilterService;
        this.locationService = locationService;
        this.assignmentsService = assignmentsService;
        this.jobEventService = jobEventService;
        this.authenticationService = authenticationService;
        this.locationUpdateService = locationUpdateService;
        this.loading = false;
        this.loadingLocationUpdates = true;
        this.loadingLocations = true;
        this.user = this.authenticationService.user();
        this.legend = false;
        this.showVoided = false;
        this.jobEvents = [];
        this.locations = [];
        this.assignments = [];
        this.playIcons = [0];
        this.playbackSpeed = 1;
        this.timelineLabels = [
            {
                name: 'Punchcards',
                color: '#09366c'
            },
            {
                name: 'Trips',
                color: '#015BC5'
            },
            {
                name: 'Scale Sync',
                color: '#7e34bf'
            },
            {
                name: 'Geofence Duration',
                color: '#f5a623'
            },
            {
                name: 'GeoTrip Duration',
                color: '#fde0af'
            },
            {
                name: 'Stopped',
                color: 'white',
                icon: 'stop-hand'
            },
            {
                name: 'Signal Lost',
                color: '#565554',
                icon: 'no-signal'
            },
        ];
        this.timeIntervalConfig = { nameProperty: 'title' };
        this.timeIntervalOptions = [
            { title: '1 Hour', amount: 60 },
            { title: 'Half Hour', amount: 30 },
            { title: 'Quarter Hour', amount: 15 }
        ];
        this.playing = false;
        this.driverLocationUpdates = [];
        this.driverDropdownConfig = {
            searchable: true,
            showLoading: true,
            group: true,
            groupProperty: 'organizationName',
            nameProperty: 'name',
            sortBy: 'carrier__name,profile__first_name,profile__last_name',
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            service: driver_service_1.DriverService,
            serviceFunction: 'listCondensed',
            query: {},
            customOptionKeys: ['truck', 'carrier', 'organizationName']
        };
        this.allSubscriptionsToUnsubscribe = [];
    }
    RuckitReplayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            if (result && result.params) {
                _this.driverId = result.qparams['driver'];
            }
        }));
        this.selectedDate = this.route.snapshot.queryParams.date ?
            (this.route.snapshot.queryParams.date.length === 8 ?
                moment(this.route.snapshot.queryParams.date, 'YYYYMMDD') :
                moment(this.route.snapshot.queryParams.date)) : moment();
        this.selectedTime = this.selectedDate.startOf('day').toISOString();
        this.getLocations();
        if (this.intervalSelect && this.timeIntervalOptions) {
            this.intervalSelect.selectedOption = this.timeIntervalOptions[1];
        }
        this.timeInterval = this.timeIntervalOptions[1].amount;
        this.driverDropdownConfig.query = __assign({}, this.driverDropdownConfig.query, { activity: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString() });
        this.setTimeRange();
    };
    RuckitReplayComponent.prototype.ngOnDestroy = function () {
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        if (this.locationUpdatesReq && typeof this.locationUpdatesReq.unsubscribe === 'function') {
            this.locationUpdatesReq.unsubscribe();
        }
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    RuckitReplayComponent.prototype.onDateChanged = function (e) {
        if (!this.loading) {
            this.selectedDate = moment(e[0]);
            this.selectedTime = this.selectedDate.startOf('day').toISOString();
            var url = this.router.createUrlTree([], {
                relativeTo: this.route,
                queryParams: __assign({}, this.route.snapshot.queryParams, { date: this.selectedDate.format('YYYYMMDD') })
            }).toString();
            this.location.go(url);
            if (this.driverDropdownWrapper) {
                this.driverDropdownConfig.query = __assign({}, this.driverDropdownConfig.query, { activity: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString() });
                if (this.driverDropdownWrapper.config) {
                    this.driverDropdownWrapper.config.query = this.driverDropdownConfig.query;
                }
                if (typeof this.driverDropdownWrapper.getRecords === 'function') {
                    this.driverDropdownWrapper.getRecords();
                }
            }
            this.getLocations();
            if (this.selectedDriver) {
                this.getDriverAssignments(this.selectedDriver);
            }
        }
        this.loading = false;
    };
    RuckitReplayComponent.prototype.getDriverAssignments = function (selectedDriver) {
        var _this = this;
        this.loadingLocationUpdates = true;
        this.selectedDriver = selectedDriver;
        this.jobEvents = [];
        if (selectedDriver) {
            if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
                this.assignmentsReq.unsubscribe();
            }
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: __assign({}, this.route.snapshot.queryParams, { driver: selectedDriver.id })
            });
            this.assignmentsReq = this.assignmentsService.list({
                active_range: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString(),
                driver: selectedDriver.id,
                include_trips: 'True',
                can_dispatch: 'True'
            }).subscribe(function (assignments) {
                var jobEventReqs = [];
                assignments.forEach(function (assignment) {
                    if (!_this.jobEvents.find(function (jobEvent) { return (jobEvent.id === assignment.jobevent.id); })) {
                        jobEventReqs.push(_this.jobEventService.getJobEvent(assignment.jobevent.id));
                    }
                });
                rxjs_1.forkJoin(jobEventReqs).subscribe(function (jobevents) {
                    _this.assignments = assignments;
                    _this.jobEvents = jobevents;
                    _this.setTimeRange(jobevents);
                    _this.getDriverLocationUpdates(selectedDriver, assignments);
                }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
            }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
        }
    };
    RuckitReplayComponent.prototype.getLocations = function () {
        var _this = this;
        this.loadingLocations = true;
        this.locations = [];
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        this.locationsReq = this.locationService.list({
            active_range: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString()
        }).subscribe(function (locations) {
            _this.locations = locations;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    RuckitReplayComponent.prototype.getDriverLocationUpdates = function (driver, assignments) {
        var _this = this;
        this.driverLocationUpdates = [];
        if (this.locationUpdatesReq && typeof this.locationUpdatesReq.unsubscribe === 'function') {
            this.locationUpdatesReq.unsubscribe();
        }
        var currentDate = moment(this.selectedTime);
        var startDate = currentDate.clone().startOf('day');
        var endDate = currentDate.clone().endOf('day');
        var startDates = [
            startDate
        ].concat(lodash_1.flatten(assignments.map(function (a) { return ([
            moment(a.uniqueStart)
        ].concat(a.shifts.map(function (s) { return (s ? moment(s.startTime) : moment.invalid()); }), a.trips.map(function (t) { return (t ? moment(t.startTimeTimestamp) : moment.invalid()); }), a.predictedTrips.map(function (p) { return (p ? moment(p.startTimeTimestamp) : moment.invalid()); }), a.punchCards.map(function (p) { return (p ? moment(p.startTimeTimestamp) : moment.invalid()); }))); })).filter(function (t) { return t.isValid(); }));
        var endDates = [
            endDate
        ].concat(lodash_1.flatten(assignments.map(function (a) { return (a.shifts.map(function (s) { return (s ? moment(s.endTime) : moment.invalid()); }).concat(a.trips.map(function (t) { return (t ? moment(t.endTimeTimestamp) : moment.invalid()); }), a.predictedTrips.map(function (p) { return (p ? moment(p.endTimeTimestamp) : moment.invalid()); }), a.punchCards.map(function (p) { return (p ? moment(p.endTimeTimestamp) : moment.invalid()); }))); })).filter(function (t) { return t.isValid(); }));
        // Add safety check for dates way beyond acceptable range
        startDates = startDates.filter(function (_startDate) {
            return startDate.clone().subtract(1, 'days').isBefore(moment(_startDate));
        });
        endDates = endDates.filter(function (_endDate) {
            return endDate.clone().add(1, 'days').isAfter(moment(_endDate));
        });
        if (endDates.length === 0) {
            endDates = [moment.min(startDates).add(26, 'hours')];
        }
        var requestRange = {
            start: moment.min(startDates).clone().subtract(2, 'hours').toISOString(),
            end: moment.max(endDates).clone().add(2, 'hours').toISOString()
        };
        this.locationUpdatesReq = this.locationUpdateService.getAll(1000, {
            date__gte: requestRange.start,
            date__lte: requestRange.end,
            driver: driver.id,
            ordering: 'date'
        }).pipe(operators_1.first()).subscribe(function (locationUpdates) {
            _this.driverLocationUpdates = locationUpdates;
            _this.loadingLocationUpdates = false;
        });
    };
    RuckitReplayComponent.prototype.play = function (speed) {
        var _this = this;
        if (speed === void 0) { speed = 0; }
        this.playing = true;
        if (speed !== 0) {
            this.playbackSpeed += 1;
            if (this.playIcons.length > 2) {
                this.playIcons = [0];
                this.playbackSpeed = 1;
            }
            else {
                this.playIcons.push(0);
            }
        }
        var time = moment(this.selectedTime).add(10 * Math.pow(this.playbackSpeed, 2), 'seconds');
        this.selectedTime = time.toISOString();
        setTimeout(function () {
            if (time.isBefore(_this.timeRange.endDatetime) && _this.playing) {
                _this.play();
            }
        }, 100);
    };
    RuckitReplayComponent.prototype.pause = function () { this.playing = false; };
    RuckitReplayComponent.prototype.setTimeRange = function (jobEvents) {
        var _this = this;
        var startTime, endTime;
        if (jobEvents) {
            jobEvents.forEach(function (jobEvent) {
                if (_this.timeRange) {
                    startTime = moment(jobEvent.shift1StartTimestamp).isBefore(moment(_this.timeRange.startDatetime)) ?
                        jobEvent.shift1StartTimestamp : _this.timeRange.startDatetime;
                    endTime = jobEvent.shift2EndTimestamp ?
                        (moment(jobEvent.shift2EndTimestamp).isAfter(moment(_this.timeRange.endDatetime)) ?
                            jobEvent.shift2EndTimestamp : _this.timeRange.endDatetime) :
                        (moment(jobEvent.shift1EndTimestamp).isAfter(moment(_this.timeRange.endDatetime)) ?
                            jobEvent.shift1EndTimestamp : _this.timeRange.endDatetime);
                }
                else {
                    startTime = jobEvent.shift1StartTimestamp;
                    endTime = jobEvent.shift2EndTimestamp ? jobEvent.shift2EndTimestamp : jobEvent.shift1EndTimestamp;
                }
            });
        }
        else if (this.selectedDate) {
            startTime = this.selectedDate.startOf('day').toISOString();
            endTime = this.selectedDate.endOf('day').toISOString();
        }
        this.selectedTime = startTime;
        this.timeRange = {
            startDatetime: startTime,
            endDatetime: endTime
        };
    };
    RuckitReplayComponent.prototype.toggleVoided = function () {
        this.showVoided = !this.showVoided;
    };
    RuckitReplayComponent.prototype.onRefresh = function () {
        this.getDriverAssignments(this.selectedDriver);
    };
    return RuckitReplayComponent;
}());
exports.RuckitReplayComponent = RuckitReplayComponent;
