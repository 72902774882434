import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { timer as observableTimer, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { JobEvent } from '../../job-events/job-event';
import { JobEventStatService } from '../../job-event-stats/job-event-stat.service';
import { Device } from '../../shared/device';
import { AuthenticationService } from '../../shared';

@Component({
  selector: 'job-stats-widget',
  templateUrl: './job-stats-widget.component.html',
  styleUrls: ['./job-stats-widget.component.scss']
})
export class JobStatsWidgetComponent implements OnInit, OnDestroy {
  device: Device = new Device();
  enabledFeatures: string[];
  totalHoursField: 'totalHours' | 'totalTripHours' | 'totalCompletedTripHours' = 'totalHours';
  @Input() jobEvent: JobEvent;
  loading = false;
  errors = [];
  jobEventStatsReq: Subscription;
  lastUpdated: string;
  public jobEventStatsTimerSub;
  public jobEventStatsTimer;

  constructor(
    private jobEventStatService: JobEventStatService,
    private deviceDetectorService: DeviceDetectorService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.enabledFeatures = this.authenticationService && this.authenticationService.enabledFeatures();
    if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
      const totalHoursPreference = this.authenticationService.getFeature('calculator');
      this.totalHoursField = totalHoursPreference === 'trips' ?
        'totalTripHours' : totalHoursPreference === 'completed_trips' ?
        'totalCompletedTripHours' : 'totalHours';
    }

    this.jobEventStatsTimer = observableTimer(1, 120000);
    if (this.jobEventStatsTimerSub && typeof this.jobEventStatsTimerSub.unsubscribe === 'function') {
      this.jobEventStatsTimerSub.unsubscribe();
    }
    this.jobEventStatsTimerSub = this.jobEventStatsTimer.subscribe(t => {
      this.getJobEventStats();
    });
  }

  ngOnDestroy() {
    if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
      this.jobEventStatsReq.unsubscribe();
    }
    if (this.jobEventStatsTimerSub && typeof this.jobEventStatsTimerSub.unsubscribe === 'function') {
      this.jobEventStatsTimerSub.unsubscribe();
    }
  }

  getJobEventStats(): void {
    if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
      this.jobEventStatsReq.unsubscribe();
    }
    if (this.jobEvent) {
      this.loading = true;
      let effectiveRateCalculator = '';
      if (this.enabledFeatures && this.enabledFeatures.includes('effectiveRateCalculator')) {
        effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
      }
      this.jobEventStatsReq = this.jobEventStatService.getStats(this.jobEvent.id, {
        calculator: effectiveRateCalculator
      }).subscribe(
        stats => {
          this.lastUpdated = new Date().toISOString();
          this.jobEvent.stats = Object.assign(this.jobEvent.stats, stats);
        },
        err => console.error(err),
        () => this.loading = false
      );
    }
  }
}
