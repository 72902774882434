import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';

import { environment } from '../../environments/environment';
import { Unit } from './unit';
import { requestHeaders } from '../shared/api.service';

@Injectable()
export class UnitService {
  baseUrl = environment.serverUrl;
  params: HttpParams = new HttpParams();
  public nextUri;
  public count = 0;

  constructor(private http: HttpClient) {
    this.params = new HttpParams();
  }

  list(query: any = null): Observable<Unit[]> {
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          this.params = this.params.set(key, query[key].toString());
        }
      });
    }
    let unitsUrl = '/assets/files/units.json';
    return this.http.get(unitsUrl, {
      headers: requestHeaders(),
      params: this.params
    }).map(res => {
      return this.extractData(res);
    });
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(unit => {
        return new Unit(unit);
      });
    } else if (resObj) {
      return new Unit(resObj);
    } else {
      return {};
    }
  }
}
