"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var marketplace_1 = require("./marketplace");
var mock_marketplace_1 = require("./mock-marketplace");
var api_service_1 = require("../shared/api.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var MarketplaceService = /** @class */ (function () {
    function MarketplaceService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    MarketplaceService.prototype.getMockMarketplace = function () { return mock_marketplace_1.MARKETPLACE; };
    MarketplaceService.prototype.getMarketplace = function (marketplaceId) {
        var marketplaceUrl = this.baseUrl + 'marketplace/' + marketplaceId + '/';
        return this.http.get(marketplaceUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    MarketplaceService.prototype.getMockMarketplaces = function (count) {
        var marketplaces = [];
        for (var i = 1; i <= count; i++) {
            var marketplace = Object.assign({}, mock_marketplace_1.MARKETPLACE);
            marketplaces.push(marketplace);
        }
        return marketplaces;
    };
    MarketplaceService.prototype.getMarketplaces = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var marketplacesUrl = this.baseUrl + 'marketplace/';
        return this.http.get(marketplacesUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    MarketplaceService.prototype.save = function (model) {
        var uri = this.baseUrl + 'marketplace/';
        model = decamelizeKeysDeep(model);
        if (!model.id) {
            return this.http.post(uri, model, { headers: api_service_1.requestHeaders() });
        }
        else {
            return this.http.put(uri + model.id + '/', model, { headers: api_service_1.requestHeaders() });
        }
    };
    MarketplaceService.prototype.remove = function (model) {
        var id = typeof model === 'string' ? model : model.id;
        return this.http.delete(this.baseUrl + 'marketplace/' + id + '/', {
            headers: api_service_1.requestHeaders()
        });
    };
    MarketplaceService.prototype.publish = function (model) {
        var uri = this.baseUrl + 'marketplace/' + model.data.shareId + '/publish/';
        model = decamelizeKeysDeep(model);
        return this.http.post(uri, model.data, { headers: api_service_1.requestHeaders() });
    };
    MarketplaceService.prototype.accept = function (model) {
        var uri = this.baseUrl + 'marketplace/' + model.shareId + '/accept/';
        model = decamelizeKeysDeep(model);
        return this.http.post(uri, model, { headers: api_service_1.requestHeaders() });
    };
    MarketplaceService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (marketplace) {
                return new marketplace_1.Marketplace(marketplace);
            });
        }
        else if (resObj) {
            return new marketplace_1.Marketplace(resObj);
        }
    };
    MarketplaceService.prototype.getNext = function () {
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() });
        }
        else {
            return null;
        }
    };
    return MarketplaceService;
}());
exports.MarketplaceService = MarketplaceService;
