import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Address } from '../address';

@Component({
  selector: 'address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.scss']
})
export class AddressSelectComponent {
  loading = false;
  @Input() addresses: Address[] = [];
  @Input() selectedAddress: Address;
  @Input() showingAddresses = false;
  @Output() addressChanged = new EventEmitter();
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };

  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  addressSelected(address: Address): void {
    this.selectedAddress = address;
    this.addressChanged.emit(this.selectedAddress);
    this.showingAddresses = false;
  }

  showAddresses(): void {
    this.showingAddresses = !this.showingAddresses;
  }
}
