import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { pick } from 'lodash';

import { AuthenticationService } from '../shared';
import { JobEventStatService } from '../job-event-stats/job-event-stat.service';
import { JobEvent } from '../job-events/job-event';
import { JobEventStat } from '../job-event-stats/job-event-stat';
import { EditShareDialogComponent } from './edit-share-dialog.component';
import { JobEventShare } from '../job-event-shares/job-event-share';

@Component({
  selector: 'collaborator-summary',
  templateUrl: './collaborator-summary.component.html',
  styleUrls: ['./collaborator-summary.component.scss']
})
export class CollaboratorSummaryComponent implements OnChanges {
  enabledFeatures: string[];
  totalHoursField: 'totalHours' | 'totalTripHours' | 'totalCompletedTripHours' = 'totalHours';
  @Input() jobEvent: JobEvent;
  stats: JobEventStat;
  statsLoading = true;
  errors = [];

  saveShareCallback = (result: {
    jobEventShare: JobEventShare,
    jobEvent: JobEvent
  }) => {
    const jobEventShare = result.jobEventShare;
    const updates = pick(jobEventShare, [
      'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
      'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
    ]);
    updates['rate'] = updates['invoiceRate'];
    Object.assign(this.jobEvent, updates);
  }

  constructor(
    private jobEventStatService: JobEventStatService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
      const totalHoursPreference = this.authenticationService.getFeature('calculator');
      this.totalHoursField = totalHoursPreference === 'trips' ?
        'totalTripHours' : totalHoursPreference === 'completed_trips' ?
        'totalCompletedTripHours' : 'totalHours';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getStats();
  }

  getStats(): void {
    this.statsLoading = true;
    this.jobEventStatService.getStats(this.jobEvent && this.jobEvent.id).subscribe(stats => {
      this.stats = stats;
      this.statsLoading = false;
    }, err => {
      console.error(err);
    });
  }

  openEditShare(change: string): void {
    const dialog = this.dialog.open(EditShareDialogComponent, {
      width: '430px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.change = change;
      dialog.componentInstance.callback = this.saveShareCallback;
    }
  }
}
