<div *ngIf="collaborators && collaborators.length" class="collaborators-widget">
  <h2>
    <a *ngIf="jobEvent && collaborators"
       [routerLink]="['/jobs', jobEvent.job.id, jobEvent.id, 'collaborators']"
       class="collaborators-link" translate>
      Collaborators
    </a>
  </h2>

  <div class="collaborator" *ngFor="let collaborator of collaborators | slice:0:3">
    <div class="column">
      <div class="name">{{ collaborator.organization }}</div>
      <div class="dispatched">{{ collaborator.dispatched }} Dispatched</div>
    </div>
    <div class="column">
      <div class="rate" *ngIf="collaborator.invoiceRate">
        {{ (collaborator.invoiceRate || 0) | currency : 'USD' : 'symbol' : '1.2-4' }} / {{ collaborator.invoiceType }}
      </div>
      <div class="no-rate" *ngIf="!collaborator.invoiceRate" translate>
        Set Rate!
      </div>
    </div>
  </div>
  <a *ngIf="jobEvent && collaborators && collaborators.length"
    [routerLink]="['/jobs', jobEvent.job.id, jobEvent.id, 'collaborators']"
     class="view-more">
     <ng-container *ngIf="collaborators.length <= 3" translate>view</ng-container>
     <ng-container *ngIf="collaborators.length > 3" translate>view all</ng-container>
  </a>
</div>
