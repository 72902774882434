import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { TicketService } from './ticket.service';

@Component({
  selector: 'void-ticket-dialog',
  templateUrl: './void-ticket-dialog.component.html',
  styleUrls: ['./void-ticket-dialog.component.scss'],
  providers: [ TicketService ]
})
export class VoidTicketDialogComponent implements OnInit {
  loading = false;
  errors = [];
  @Input() ticket;
  callback;
  model = {
    voidReason: null,
    void: true,
    number: null,
    id: null
  };
  ticketReq;

  constructor(
    private ticketService: TicketService,
    public dialogRef: MatDialogRef<VoidTicketDialogComponent>
  ) { }

  ngOnInit() {
    this.model.id = this.ticket && this.ticket.id;
    this.model.number = this.ticket && this.ticket.number;
  }

  submit() {
    this.ticketService.void(this.model).subscribe(response => {
      this.callback(response);
      this.dialogRef.close();
    }, err => {
      console.error(err);
    });
  }

}
