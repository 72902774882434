<div class="job-production" *ngIf="job && job.stats">
  <div class="header">Overall Job Production</div>

  <div class="job-amount">{{ (job.stats.totalAmount || 0) | currency : 'USD' : 'symbol' }}</div>
  <div class="job-loads">
    <span class='total-loads' [ngPlural]="job.stats.totalLoads" *ngIf="job.haulType === 'load'">
      <ng-template ngPluralCase="=0">{{ job.stats.totalLoads || 0 | number:0 }} loads</ng-template>
      <ng-template ngPluralCase="=1">{{ job.stats.totalLoads || 0 | number:0 }} load</ng-template>
      <ng-template ngPluralCase="other">{{ job.stats.totalLoads || 0 | number:0 }} loads</ng-template>
    </span>
    <span class='value' *ngIf="job.stats.totalTons">{{ job.stats.totalTons || 0 | number:0 }} {{ job.measurementUnit(true) }}</span>
  </div>
  <div class="production-stats" *ngIf="job.haulType === 'weight' || job.invoiceType === 'weight'">
    <div class="job-tons-delivered">
      <span class='total-tons value' [ngPlural]="job.stats.totalTons" *ngIf="job.stats.totalTons">
        <ng-template ngPluralCase="=0">{{ job.stats.totalTons || 0 | number:0 }} {{ job.measurementUnit(true) }}</ng-template>
        <ng-template ngPluralCase="=1">{{ job.stats.totalTons || 0 | number:0 }} {{ job.measurementUnit(false) }}</ng-template>
        <ng-template ngPluralCase="other">{{ job.stats.totalTons || 0 | number:0 }} {{ job.measurementUnit(true) }}</ng-template>
      </span>
      <span class='measurement-unit value' *ngIf="!job.stats.totalTons">0 {{ job.measurementUnit(true) }}</span>
      <span class='label'>Delivered</span>
    </div>
    <div class="job-tons-ordered">
      <span class='total-tons value' [ngPlural]="job.stats.totalOrdered" *ngIf="job.stats.totalOrdered">
        <ng-template ngPluralCase="=0">{{ job.stats.totalOrdered || 0 | number:0 }} {{ job.measurementUnit(true) }}</ng-template>
        <ng-template ngPluralCase="=1">{{ job.stats.totalOrdered || 0 | number:0 }} {{ job.measurementUnit(false) }}</ng-template>
        <ng-template ngPluralCase="other">{{ job.stats.totalOrdered || 0 | number:0 }} {{ job.measurementUnit(true) }}</ng-template>
      </span>
      <span class='measurement-unit value' *ngIf="!job.stats.totalOrdered">0 {{ job.measurementUnit(true) }}</span>
      <span class='label'>Ordered</span>
    </div>
  </div>
</div>
