<form #filterJobInvitations="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Job Invitations</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group project-name" fxFlex="100">
          <label translate>Project</label>
          <dropdown [options]="projects" (onSelect)="onSelect('project', $event)" [config]="locationsConfig"
                    title="&ndash;"
                    (onSearch)="dropdownSearch($event, 'project')" (nextPage)="dropdownNextPage($event, 'project')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group customer" fxFlex="100">
          <label translate>Customer</label>
          <dropdown [options]="customers" (onSelect)="onSelect('customer', $event)" [config]="customersConfig"
                    title="&ndash;"
                    (onSearch)="dropdownSearch($event, 'customer')" (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group location" fxFlex="100">
          <label translate>Location</label>
          <dropdown [options]="locations" (onSelect)="onSelect('location', $event)" [config]="locationsConfig"
                    title="&ndash;"
                    (onSearch)="dropdownSearch($event, 'location')" (nextPage)="dropdownNextPage($event, 'location')"></dropdown>
        </div>
      </div>
    </div>


    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="submit(); false"
            translate>
      Filter Results
    </button>
  </div>
</form>
