"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var punch_card_service_1 = require("./punch-card.service");
var CompletedPunchCardsComponent = /** @class */ (function () {
    function CompletedPunchCardsComponent(route, router, punchCardService, dialog) {
        this.route = route;
        this.router = router;
        this.punchCardService = punchCardService;
        this.dialog = dialog;
        this.punchCards = [];
        this.expandedPunchCards = [];
        this.loading = true;
        this.errors = [];
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Unvoid', action: 'unvoid', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.viewSignaturesCallback = function () {
            // Update Signature Status Icon
        };
    }
    CompletedPunchCardsComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.getPunchCards();
    };
    CompletedPunchCardsComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.punchCardService.getNextCondensed();
            if (o) {
                this.loading = true;
                o.subscribe(function (punchCards) {
                    _this.punchCards = _this.punchCards.concat(punchCards);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; }, function () {
                    _this.loading = false;
                });
            }
        }
    };
    CompletedPunchCardsComponent.prototype.getPunchCards = function () {
        var _this = this;
        this.loading = true;
        if (this.punchCardReq) {
            this.punchCardReq.unsubscribe();
        }
        this.punchCardReq = this.punchCardService.getPunchCards({
            jobevent: this.jobEvent && this.jobEvent.id,
            end_time__isnull: 'False',
        }).subscribe(function (punchCards) {
            _this.punchCards = punchCards;
            _this.loading = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    CompletedPunchCardsComponent.prototype.menuAction = function (name, punchCard) {
        switch (name) {
            case 'void':
                // this.voidTrip(trip);
                break;
            case 'unvoid':
                // this.unvoidTrip(trip);
                break;
        }
    };
    CompletedPunchCardsComponent.prototype.expandPunchCard = function (punchCard) {
        if (lodash_1.find(this.expandedPunchCards, { id: punchCard.id })) {
            lodash_1.remove(this.expandedPunchCards, { id: punchCard.id });
        }
        else {
            this.expandedPunchCards.push(punchCard);
        }
    };
    CompletedPunchCardsComponent.prototype.expandedPunchCard = function (punchCard) {
        if (lodash_1.find(this.expandedPunchCards, { id: punchCard.id })) {
            return true;
        }
        return false;
    };
    return CompletedPunchCardsComponent;
}());
exports.CompletedPunchCardsComponent = CompletedPunchCardsComponent;
