<widget [size]="[2,1]" (window:resize)="onResize($event)" [noPadding]="true">
  <div class="heading" widget-header>
    <h2 translate>Import Orders</h2>
  </div>
  <div class="content" widget-content>
    <div class="field-group esb-upload" *ngIf="errors && errors.length === 0 && orders && orders.length === 0">
      <div class="upload-file-dropzone" [ngClass]="{filled: upload && upload.file}">
        <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput />
        <label class="upload-label" for="file">
          <i class="icon-upload"></i>
          <span translate>Drag a CSV here or browse for a file to upload.</span>
        </label>
      </div>
    </div>

    <div class="field-group esb-upload" *ngIf="errors && errors.length > 0 || orders && orders.length > 0">
      <div class="errors" *ngIf="errors && errors.length > 0">
        <h3 translate>Errors ({{ errors.length }})</h3>
        <ul>
          <li *ngFor="let error of errors">
            {{ error['row'] && error['row']['dsorder_id'] }}: {{ error['errors'] || error }}
          </li>
        </ul>
      </div>
      <div class="orders" *ngIf="orders && orders.length > 0">
        <h3 translate>Order Summary ({{ orders.length }})</h3>
        <ul>
          <li *ngFor="let order of orders">
            {{ order.dsorderId }}
          </li>
        </ul>
      </div>

      <div class="actions">
        <button id="reload-button" class="btn btn-primary" (click)="reload()" [ngClass]="{loading: loading}" translate>
          Continue
        </button>
      </div>
    </div>

    <my-loader *ngIf="loading"></my-loader>
  </div>
</widget>
