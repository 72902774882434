import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { clone } from 'lodash';

import { Invitation } from './invitation';
import { parseErrors, requestHeaders, handleError } from '../shared/api.service';
import { environment } from '../../environments/environment';
import { User } from '../users/user';

const decamelizeKeysDeep = require('decamelize-keys-deep');
const camelcaseKeysDeep = require('camelcase-keys-deep');

@Injectable()
export class InvitationService {
  public nextUri;
  baseUrl = environment.serverUrl;
  invitation: Invitation;
  token: string;
  params: HttpParams;

  constructor(private http: HttpClient) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.params = new HttpParams();
  }

  registerFromInvitation(invitationId: string, invitationKind: string, params?: {}): Observable<boolean> {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/' + invitationKind + '/';
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    params = decamelizeKeysDeep(params);

    return this.http.post(invitationUrl, params, {headers: headers}).pipe(
      map((response: User & {
        status: number,
        first_name: string,
        last_name: string
      }) => {
        if (response) {
          this.token = response && response.token;
          let email = response && response.email;
          let firstName = response && response.first_name;
          let lastName = response && response.last_name;
          let organization = response && response.organization;
          let id = response && response.id;
          let name = firstName + ' ' + lastName;
          let driver = false;
          if (response && response.driver) {
            driver = true;
          }

          if (this.token) {
            // store user data and JWT token in local storage to persist user session
            localStorage.setItem('currentUser', JSON.stringify({
              id: id,
              username: email,
              name: name,
              token: this.token,
              organization: organization,
              sidebar: (invitationKind === 'new_org' ? true : false),
              driver: driver
            }));
          }
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        return observableThrowError(parseErrors(error));
      })
    );
  }

  createInvitation(params: {}): Observable<any> {
    let invitationUrl = this.baseUrl + 'invitations/';
    params = decamelizeKeysDeep(params);
    return this.http.post(invitationUrl, params, {headers: requestHeaders()});
  }

  acceptInvitation(invitationId: string): Observable<any> {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/accept/';

    return this.http.get(invitationUrl, {headers: requestHeaders()});
  }

  updateInvitation(invitationId: string, invitationData: {}): Observable<any> {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    const _invitationData = decamelizeKeysDeep(clone(invitationData));
    return this.http.put(invitationUrl, _invitationData, {headers: headers});
  }

  rejectInvitation(invitationId: string): Observable<any> {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';

    return this.http.put(invitationUrl, {status: 'declined'}, {headers: requestHeaders()});
  }

  cancelInvitation(invitationId: string): Observable<any> {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';

    return this.http.put(invitationUrl, {status: 'cancelled'}, {headers: requestHeaders()});
  }

  resendInvitation(invitationId: string) {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/resend/';

    return this.http.get(invitationUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  acceptJob(params: any): Observable<boolean> {
    let invitationUrl = this.baseUrl + 'invitations/' + params.invitation + '/new_job/';
    if (typeof(params.project) === 'object') {
      params.project = params.project.id;
    }
    params = decamelizeKeysDeep(params);

    return this.http.post(invitationUrl, params, {headers: requestHeaders()}).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }

  rejectJob(invitationId: string): Observable<boolean> {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';

    return this.http.put(invitationUrl, {status: 'declined'}, {headers: requestHeaders()}).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }

  getInvitation(invitationId: string) {
    let invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';

    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.get(invitationUrl, {headers: headers}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getInvitations(type: string, organizationId: string, query: string = null, kind = 'connection'): Observable<Invitation[]> {
    let invitationsUrl = this.baseUrl + 'invitations/';
    this.buildSearchQuery(type, organizationId, kind);

    return this.http.get(invitationsUrl, {
      headers: requestHeaders(),
      params: this.params
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  private extractData(res: Object) {
    let body = res['results'];
    if (body) {
      return body.map(inv => {
        inv = camelcaseKeysDeep(inv);
        return new Invitation(inv);
      });
    } else if (res) {
      let inv = camelcaseKeysDeep(res);
      return new Invitation(inv);
    } else {
      return [ ];
    }
  }

  private buildSearchQuery(type: string, organizationId: string, kind = 'connection') {
    this.params = this.params.set('kind', kind);
    this.params = this.params.set('status', 'pending');

    switch (type) {
      case 'received':
        this.params = this.params.set('recipient_organization', organizationId);
        this.params = this.params.delete('sender_organization');
        break;
      case 'sent':
        this.params = this.params.set('sender_organization', organizationId);
        this.params = this.params.delete('recipient_organization');
        break;
    }
  }

  getNext() {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()});
    } else {
      return null;
    }
  }
}
