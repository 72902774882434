<div *ngIf="!loading && (searching && results === 0) || (!loading && results === 0)"
     [ngStyle]="styles" class="no-results {{ customClasses }}"
     [ngClass]="{
       bordered: bordered, 'mat-table-no-results': matTable,
       'has-header': hasHeader
     }">
  <div class="inner-container">
    <div *ngIf="(!search || search === '') && (!filters || filters.length === 0)">
      <div class="icon">
        <i class="{{ iconClass }}"></i>
      </div>
      <h2 translate>No {{ itemName }}</h2>
      <p class="sub-title" *ngIf="subTitle">{{ subTitle }}</p>
      <p translate *ngIf="hasAddText">When you add {{ addText }} you’ll see it here.</p>
      <p *ngIf="customText && customText.length">{{ customText }}</p>
      <div class="actions" *ngIf="hasAction">
        <button class="btn btn-primary" (click)="add()">
          <span class="icon-plus"></span>
          <ng-container translate>Add {{ addButtonText || itemName }}</ng-container>
        </button>
      </div>
    </div>
    <div *ngIf="(search && results === 0) || (filters.length > 0 && results === 0)">
      <div class="icon">
        <i class="{{ iconClass }}"></i>
      </div>
      <h2 translate>No {{ itemName }} Found</h2>
      <div class="actions">
        <button class="btn btn-primary" [ngClass]="{loading: loading}" (click)="clear()" translate>
          Clear Filters
        </button>
      </div>
    </div>
  </div>
</div>
