<form #inviteEmployee="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1>{{title}}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <p>
      <ng-container>{{blurb}}</ng-container>
    </p>

    <div class="field-group full-width">
      <label class="required">Email</label>
      <input type="email" name="email" placeholder="{{ 'Email Address' | translate }}" required [(ngModel)]="model.email" #email="ngModel" />
      <span [ngClass]="{'input-icon fa': true, 'ng-valid': email.valid, 'ng-invalid': email.invalid, 'ng-pristine': email.pristine}"></span>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <ruckit-button id="invite-employee-button" title="{{button.title}}" fullWidth=true (click)="!inviteEmployee.form.invalid && submit(); false" [type]="button.type" [isDisabled]="inviteEmployee.form.invalid || loading || button.disabled"></ruckit-button>
  </div>
</form>
