window._genesys = {
  widgets: {
    webchat: {
      transport: {
        type: 'purecloud-v2-sockets',
        dataURL: 'https://api.usw2.pure.cloud',
        deploymentKey: 'fd3b487f-a93f-48df-8b31-29346228cb70',
        orgGuid: '8f3e7034-5bc6-4232-9495-00b26df66a4d',
        interactionData: {
          routing: {
            targetType: 'QUEUE',
            targetAddress: 'Test Queue - 205 879 8690',
            priority: 2,
            skills: ['Ruckit'],
            language: 'English',
          },
        },
      },
      userData: {
        phoneType: '',
      },
    },
  },
};
