<div class="app-content">
  <form #editExpense="ngForm" class="expense-form">
    <my-toolbar [title]="expense && expense.title"></my-toolbar>

    <action-bar>
      <div class="left-actions"></div>
      <div class="right-actions">
        <button id="send-expense-button" class="btn btn-primary" (click)="submit(); false" *ngIf="expense && expense.draft" [disabled]="loading || !editExpense.form.valid || !expense.sendable"
          [ngClass]="{loading:loading}" translate>Mark as Paid</button>
      </div>
    </action-bar>

    <div class="expense-container" *ngIf="expense">
      <div class="expense-content">
        <div class="meta-data" translate>
          DRAFT &mdash; CREATED {{ expense.createdAt | date:'shortDate' }}
          <notification *ngFor="let error of errors">{{ error }}</notification>
        </div>
        <div class="billing">
          <div class="billing-left">
            <div>
              <div class="title" translate>EXPENSE</div>
              <div class="field-group expense-number">
                <label translate>Expense #</label>
                <input type="text" name="expense-number" required [(ngModel)]="expense.number" #number="ngModel" (blur)="save(); false" />
              </div>

              <div class="field-group expense-date">
                <label translate>Date</label>
                <date-range-picker [config]="{decreaseControl: false, increaseControl: false}"
                  [selectedDates]="[createdAt]" (dateChanged)="onDateChanged($event)"></date-range-picker>
              </div>
            </div>

            <div class="sender-address">
              <div class="edit-action" (click)="editing = !editing">
                <i class="icon-pencil"></i>
              </div>
              <h2 translate>Sender</h2>
              <div class="field-group">
                <label>{{ expense.ownerOrganization && expense.ownerOrganization.name }}</label>
                <textarea required [(ngModel)]="expense.billFromAddress" name="bill_from_address" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
              </div>
              <div class="field-group">
                <input type="email" required class="light" placeholder="{{ 'Email' | translate }}" [(ngModel)]="expense.billFromContactEmail" name="bill_from_contact_email" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
              <div class="field-group">
                <input type="email" class="light" placeholder="{{ 'CC Email' | translate }}" [(ngModel)]="expense.billFromCcEmails" name="bill_from_cc_emails" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
            </div>
          </div>

          <div class="billing-right">
            <div class="logo-image upload-image-dropzone" [ngClass]="{filled: expense.logo || expense.ownerOrganization.image}">
              <img *ngIf='expense.logo || expense.ownerOrganization.image' [src]="expense.logo || expense.ownerOrganization.image" />

              <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
              <label for="file">
                <i class="icon-upload"></i>
                <span *ngIf="!expense.logo && !expense.ownerOrganization.image" translate>Click to upload a logo</span>
                <span *ngIf="expense.logo || expense.ownerOrganization.image" translate>Upload a new logo image</span>
              </label>
            </div>

            <div class="receipient-address">
              <div class="edit-action" (click)="editing = !editing">
                <i class="icon-pencil"></i>
              </div>
              <h2 translate>Recipient</h2>
              <div class="field-group">
                <label>{{ expense.customerOrganization && expense.customerOrganization.name }}</label>
                <textarea required [(ngModel)]="expense.billToAddress" name="bill_to_address" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
              </div>
              <div class="field-group">
                <input type="email" required class="light" placeholder="{{ 'Email' | translate }}" [(ngModel)]="expense.billToContactEmail" name="bill_to_contact_email" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
              <div class="field-group">
                <input type="email" class="light" placeholder="{{ 'CC Email' | translate }}" [(ngModel)]="expense.billToCcEmails" name="bill_to_cc_emails" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
            </div>
          </div>
        </div>

        <div class="title-container">
          <div class="title" translate>
            Expense Items
          </div>
          <div class="search-container {{ loading ? 'loading' : '' }}">
            <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
          </div>
        </div>

        <div class="expense-trips">
          <ruckit-expense-trips [search]="search" [expense]="expense" (updateExpense)="updateExpense($event)"></ruckit-expense-trips>
        </div>

        <div class="expense-punch-cards">
          <ruckit-expense-punch-cards [search]="search" [expense]="expense" (updateExpense)="updateExpense($event)"></ruckit-expense-punch-cards>
        </div>

        <div class="expense-surcharges">
          <ruckit-expense-surcharges [search]="search" [expense]="expense" (updateExpense)="updateExpense($event)"></ruckit-expense-surcharges>
        </div>

        <div class="horizontal-rule"></div>

        <div class="total-summary-container">
          <div class="total-summary">
            <div class="row tickets-sub-total">
              <label translate>Tickets Subtotal</label>
              <div class="value">{{ (expense.subtotal | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
            <div class="row surcharges">
              <label translate>Surcharges</label>
              <div class="value">{{ (expense.surchargeAmount | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
            <div class="row expense-total">
              <label translate>Expense Total</label>
              <div class="value">{{ (expense.expenseAmount | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
