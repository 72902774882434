import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({name: 'filter', pure: false})

export class FilterPipe implements PipeTransform {
  transform(items: any, iterator: any): any {
    if (!items) {
      return [];
    }
    return filter(items, iterator);
  }
}
