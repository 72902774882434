import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import * as moment from 'moment';
import { remove } from 'lodash';

import { JobEvent } from '../../job-events/job-event';
import { JobEventService } from '../../job-events/job-event.service';
import { Assignment } from '../../assignments/assignment';
import { AssignmentService } from '../../assignments/assignment.service';
import { DriverService } from '../../drivers/driver.service';
import { DriverSerializer } from '../../drivers/driver.serializer';
import { TruckSerializer } from '../../trucks/truck.serializer';
import { Carrier } from '../../carriers/carrier';
import { Driver } from '../../drivers/driver';
import { Truck } from '../../trucks/truck';
import { AssignmentSerializer } from '../../assignments/assignment.serializer';
import { DispatchService } from '../dispatch.service';

enum Panel {
  shift = 1,
  assign,
  carrier,
  driver,
  truck
}

@Component({
  selector: 'mobile-dispatch-assign',
  templateUrl: './mobile-dispatch-assign.component.html',
  styleUrls: ['./mobile-dispatch-assign.component.scss']
})
export class MobileDispatchAssignComponent implements OnInit {
  loading = false;
  panel = Panel;
  errors = [];
  jobEvent: JobEvent;
  jobEventReq: Subscription;
  activePanel: Panel = 1;
  shift = { slots: [] };
  selectedShift = 0;
  selectedAssignment: Assignment;
  selectedCarrier: Carrier;
  selectedDriver: Driver;
  selectedTruck: Truck;
  selectedSlot;
  dispatchedTotal = 0;
  editAssignment = false;
  editMode = false;
  scrollEvent: any;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private driverService: DriverService,
    private dispatchService: DispatchService,
    private jobEventService: JobEventService,
    private assignmentService: AssignmentService
  ) { }

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.getJobEvent(result.params['jobEventId']);
        this.selectedShift = Number(result.qparams['shift']) || 0;
        switch (result.params['panel']) {
          case 'shift': {
            this.activePanel = Panel.shift;
            break;
          }
          case 'assign': {
            this.activePanel = Panel.assign;
            break;
          }
          case 'carrier': {
            this.activePanel = Panel.carrier;
            break;
          }
          case 'driver': {
            this.activePanel = Panel.driver;
            break;
          }
          case 'truck': {
            this.activePanel = Panel.truck;
            break;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }


  getJobEvent(id) {
    this.loading = true;
    this.jobEventService.getJobEvent(id).subscribe(jobEvent => {
      this.jobEvent = jobEvent;
      this.getAssignments(id);
    }, err => {
      this.errors = err;
    });
  }

  getAssignments(jobEventId) {
    const shift = `shift${this.selectedShift + 1}`;
    this.assignmentService.list({
      jobevent: jobEventId, can_dispatch: 'True', shift: shift
    }).subscribe(assignments => {
      this.shift = { slots: [] };
      assignments.forEach(assignment => {
        if (assignment['uniqueStart']) {
          assignment['uniqueStart'] = moment(assignment['uniqueStart']).format('h:mm A');
        }
        let driver = new DriverSerializer().fromJson(assignment.driver);
        let truck = (new TruckSerializer().fromJson(assignment.truck));
        let _assignment = new AssignmentSerializer().fromJson(assignment);
        let numberOfLoadsType = 'numbered';
        if (_assignment.numberOfLoadsType) {
          numberOfLoadsType = _assignment.numberOfLoadsType;
        } else {
          numberOfLoadsType = (_assignment.maxNumberOfLoads === 0 || _assignment.maxNumberOfLoads === null) ? 'allDay' : 'numbered';
        }
        this.shift.slots.push({
          assignment: _assignment,
          driver: driver,
          truck: truck,
          numberOfLoadsType: numberOfLoadsType,
          saveButton: false
        });
      });
      let max = this.jobEvent.numTrucks || this.shift.slots.length + 1;
      for (let i = this.shift.slots.length; i < max; i++) {
        this.shift.slots.push({});
      }
      if (this.shift && this.shift.slots) {
        this.dispatchedTotal = this.calculateDispatchedTotal(this.shift.slots);
      }
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  back(): void {
    if (this.activePanel === Panel.assign && this.jobEvent && this.jobEvent.shifts === 2) {
      this.activePanel = Panel.shift;
      this.router.navigate([
        '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'shift'
      ]);
    } else if (this.jobEvent && (this.activePanel === Panel.shift || this.activePanel === Panel.assign)) {
      const jobEventDate = moment(this.jobEvent.shift1StartTimestamp).format('YYYYMMDD');
      this.router.navigate(['/dispatch'], {queryParams: {date: jobEventDate}});
    } else if (this.jobEvent) {
      this.activePanel = Panel[Panel[this.activePanel - 1]];
      this.router.navigate(['/dispatch', this.jobEvent.job.id, this.jobEvent.id, Panel[this.activePanel]]);
    }
  }

  calculateDispatchedTotal(slots): number {
    let amtDispatched = 0;
    if (slots) {
      slots.forEach(slot => {
        amtDispatched = slot.driver ? amtDispatched + 1 : amtDispatched;
      });
    }
    return amtDispatched;
  }

  onShiftSelected(shift: number): void {
    this.selectedShift = shift;
    this.activePanel = Panel.assign;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { shift: this.selectedShift },
      queryParamsHandling: 'merge'
    });
  }

  onCarrierSelected(carrier: Carrier): void {
    this.selectedCarrier = carrier;
    this.activePanel = Panel.driver;
    this.router.navigate([
      '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'driver'
    ]);
  }

  onSlotSelected(data) {
    // tslint:disable-next-line:no-unused-variable
    const {shift, index} = data;
    this.selectedSlot = index;
    this.activePanel = Panel.carrier;
    this.router.navigate([
      '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'carrier'
    ]);
  }

  onDriverSelected(driver: Driver): void {
    this.selectedDriver = driver;
    this.assignDriver(this.selectedDriver);
  }

  onEditTruckSelected(driver: Driver): void {
    this.selectedDriver = driver;
    this.activePanel = Panel.truck;
    this.router.navigate([
      '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'truck'
    ]);
  }

  onTruckSelected(data): void {
    const { truck, driver } = data;
    this.selectedTruck = truck;
    this.selectedDriver = driver && driver.driver || driver;
    this.assignTruckAndDriver(truck);
  }

  onRemoveSlotSelected(slot) {
    if (slot.assignment) {
      if (this.shift) {
        this.assignmentService.remove(slot.assignment.id).subscribe(res => {
          slot.driver = undefined;
          slot.assignment = undefined;
          remove(this.shift.slots, slot);
          let max = this.jobEvent.numTrucks || this.shift.slots.length + 1;
          for (let i = this.shift.slots.length; i < max; i++) {
            this.shift.slots.push({});
          }
        }, err => {
          this.errors = err;
        });
      }
    }
    // this.router.navigate([
    //   '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'assign'
    // ]);
    this.editMode = false;
    this.getJobEvent(this.jobEvent.id);
  }

  onAssignmentSelected(assignment: Assignment) {

  }

  onDispatchAssignments(): void {
    this.dispatch();
  }

  assignDriver(driver: Driver) {
    this.loading = true;
    let jobEventId = this.jobEvent && this.jobEvent.id;
    let assignment = new AssignmentSerializer().fromJson({
      driver: driver.id,
      truck: driver.truck.id,
      jobevent: jobEventId,
      shift: 'shift' + (this.selectedShift + 1),
      maxNumberOfLoads: 0 // this.allDriversEnabled ? 0 : 1
    });
    this.assignmentService.save(assignment).subscribe(res => {
      this.router.navigate([
        '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'assign'
      ]);
      // this.getJobEvent(this.jobEvent.id);
      // this.mobileSearch('', 'drivers');
      // this.selectedAssignment = res;
      // this.editAssignment = true;
      // this.loading = false;
    }, (err) => {
      // parseErrors(err);
      // this.loading = false;
    });
  }

  assignTruckAndDriver(truck: Truck): void {
    if (this.selectedDriver && this.selectedDriver.id) {
      this.selectedDriver.truck = truck;
      this.driverService.assign(this.selectedDriver).subscribe((res) => {
        this.selectedTruck = null;
        this.assignDriver(this.selectedDriver);
      }, (err) => {
        // this.errors = parseErrors(err);
      });
    }
  }

  // updateAssignment(assignment) {
  //   let _assignment = clone(assignment);
  //   let jobId = this.job && this.job.id;
  //   let jobEventId = this.jobEvent && this.jobEvent.id;
  //   let dateString = moment(this.displayedDate).format('MM/DD/YYYY');
  //   let timeString = _assignment['uniqueStart'];
  //   let formatString = 'MM/DD/YYYY h:mm A';
  //   _assignment['uniqueStart'] = moment(dateString + ' ' + timeString, [formatString]).format();
  //   this.assignmentService.save(_assignment).subscribe(() => {
  //     if (this.driverList) {
  //       this.driverList = false;
  //       this.getJob.emit({ jobId, jobEventId });
  //     }
  //     this.selectedDriver = null;
  //     this.editAssignment = false;
  //     this.loading = false;
  //   }, (err) => {
  //     parseErrors(err);
  //     this.loading = false;
  //   });
  // }

  dispatch() {
    this.loading = true;
    this.dispatchService.save({
      jobEvent: this.jobEvent && this.jobEvent.id,
      notifyNew: true
    }).subscribe(res => {
      this.jobEvent.lastDispatchedTime = res['lastDispatchTime'];
      this.jobEvent.lastDispatchedBy = res['lastDispatchedBy'];
      this.shift.slots.forEach((slot) => {
        if (slot.driver) {
          slot.assignment.dispatched = true;
        }
      });
      this.loading = false;
      this.router.navigate(['/dispatch']);
    }, (err) => {
      // parseErrors(err);
      this.loading = false;
    });
  }
}
