"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var api_service_1 = require("../shared/api.service");
var driver_service_1 = require("../drivers/driver.service");
var invitation_service_1 = require("./invitation.service");
var authentication_service_1 = require("../shared/authentication.service");
// const camelcaseKeysDeep = require('camelcase-keys-deep');
var DriverInvitationComponent = /** @class */ (function () {
    function DriverInvitationComponent(route, router, elementRef, apiService, invitationService, driverService, authenticationService) {
        this.route = route;
        this.router = router;
        this.elementRef = elementRef;
        this.apiService = apiService;
        this.invitationService = invitationService;
        this.driverService = driverService;
        this.authenticationService = authenticationService;
        this.loading = true;
        this.errors = [];
        this.data = {};
        this.user = {};
        this.driver = {};
        this.step = 0;
        this.steps = ['User Details', 'Account Details', 'User Photo', 'App Download'];
        this.completedSteps = [];
        this.states = [];
        this.states = this.apiService.getStates().map(function (state) {
            return { name: state.abbreviation };
        });
    }
    DriverInvitationComponent.prototype.register = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.invitationService.registerFromInvitation(this.invitation.id, this.invitation.kind, { 'driver': this.data, 'user': this.user })
            .subscribe(function (result) {
            _this.ngOnInit();
            _this.loading = false;
            _this.step += 1;
            _this.completedSteps.push('User Details');
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DriverInvitationComponent.prototype.updateAccountDetail = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.driverService.save(this.driver)
            .subscribe(function (result) {
            _this.loading = false;
            _this.step += 1;
            _this.completedSteps.push('Account Details');
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DriverInvitationComponent.prototype.updateDriverPhoto = function () {
        var _this = this;
        var fileInput = this.elementRef.nativeElement.querySelector('input[name="file"]');
        var driver = lodash_1.cloneDeep(this.driver);
        delete driver.profile.image;
        this.errors = [];
        if (this.driver.profile.image) {
            this.driverService.uploadImage('PUT', driver.id, fileInput.files)
                .subscribe(function (result) {
                _this.loading = false;
            }, function (err) {
                _this.loading = false;
                _this.errors = err;
            });
        }
        this.step += 1;
        this.completedSteps.push('User Photo');
    };
    DriverInvitationComponent.prototype.fileChange = function (e) {
        var _this = this;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (ev) {
            _this.driver.profile.image = ev.target['result'];
        };
        reader.readAsDataURL(file);
    };
    DriverInvitationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.route.params.forEach(function (params) {
            _this.inviteCode = params['invite_code'];
            _this.invitationService.getInvitation(_this.inviteCode).subscribe(function (invitation) {
                // invitation = camelcaseKeysDeep(invitation);
                _this.invitation = invitation;
                if (_this.invitation.status === 'pending') {
                    _this.authenticationService.logout();
                }
                else if (_this.invitation.status === 'accepted') {
                    _this.completedSteps.push('User Details');
                }
                _this.loadData(_this.invitation);
                _this.loading = false;
                if (_this.completedSteps.indexOf('User Details', 0) > -1) {
                    _this.step = 2;
                }
                else {
                    _this.step = +params['step'] || 1;
                }
            }, function (err) {
                _this.errors = err;
            });
        });
    };
    DriverInvitationComponent.prototype.loadData = function (invitation) {
        this.data = {
            'profile': {
                'firstName': invitation.data && invitation.data.driver.profile.firstName,
                'lastName': invitation.data && invitation.data.driver.profile.lastName,
                'email': invitation.data && invitation.data.driver.profile.email
            },
            'cdl': invitation.data && invitation.data.driver.cdl
        };
        this.user = {
            'password': ''
        };
        this.driver = {
            'id': invitation.resultUser && invitation.resultUser.driver.id,
            'street': invitation.data && invitation.data.driver.street,
            'city': invitation.data && invitation.data.driver.city,
            'state': invitation.data && invitation.data.driver.state,
            'zipcode': invitation.data && invitation.data.driver.zipcode,
            'cdl': invitation.data && invitation.data.driver.cdl,
            'profile': {
                'phoneNumber': invitation.data && invitation.data.driver.profile.phoneNumber,
                'firstName': invitation.data && invitation.data.driver.profile.firstName,
                'lastName': invitation.data && invitation.data.driver.profile.lastName,
                'image': invitation.data && invitation.data.driver.profile.image,
                'email': invitation.data && invitation.data.driver.email
            }
        };
    };
    return DriverInvitationComponent;
}());
exports.DriverInvitationComponent = DriverInvitationComponent;
