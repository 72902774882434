import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '../shared/resource.service';
import { CondensedJobEvent } from './condensed-job-event';
import { CondensedJobEventSerializer } from './condensed-job-event.serializer';

@Injectable()
export class CondensedJobEventService extends ResourceService<CondensedJobEvent> {
  constructor(http: HttpClient) {
    super(http, 'jobevents/condensed/', new CondensedJobEventSerializer());
  }
}
