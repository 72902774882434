"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var resource_service_1 = require("../../shared/resource.service");
var notifications_report_serializer_1 = require("./notifications-report.serializer");
var shared_1 = require("../../shared");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("../../shared/authentication.service");
var NotificationsReportService = /** @class */ (function (_super) {
    __extends(NotificationsReportService, _super);
    function NotificationsReportService(http, authenticationService) {
        var _this = _super.call(this, http, 'controls/notification-totals/', new notifications_report_serializer_1.NotificationsReportSerializer()) || this;
        _this.authenticationService = authenticationService;
        _this.defaulThresholds = [
            { value: 0.25, color: 'rgba(244, 238, 120, 1)' },
            { value: 0.5, color: 'rgba(250, 147, 21, 1)' },
            { value: 0.75, color: 'rgba(226, 70, 46, 1)' }
        ];
        _this.preferenceKey = 'table-preferences[ControlReports-Notifications]';
        return _this;
    }
    NotificationsReportService.prototype.getReportConfig = function () {
        // TODO: Add call to report config endpoint when ready
        var organization = this.authenticationService.getOrgPropertyValue('id');
        if (!organization) {
            return rxjs_1.of(this.defaulThresholds.sort(function (a, b) { return b.value - a.value; }));
        }
        var thresholdsConfig = JSON.parse(localStorage.getItem(organization + "_thresholdsConfig"));
        var userThresholds = !thresholdsConfig ? this.defaulThresholds : thresholdsConfig;
        return rxjs_1.of(userThresholds.sort(function (a, b) { return b.value - a.value; }));
    };
    NotificationsReportService.prototype.saveReportConfig = function (thresholdConfig) {
        // TODO: Update with endpoint when ready
        var organization = this.authenticationService.getOrgPropertyValue('id');
        localStorage.setItem(organization + "_thresholdsConfig", JSON.stringify(thresholdConfig));
    };
    NotificationsReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsReportService_Factory() { return new NotificationsReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService)); }, token: NotificationsReportService, providedIn: "root" });
    return NotificationsReportService;
}(resource_service_1.ResourceService));
exports.NotificationsReportService = NotificationsReportService;
