<ng-container *ngIf="customFilter; else defaultFilter">
  <div class="search" *ngIf="allowSearch">
    <search (searchTermChange)="searchChange.emit($event)" [(searchTerm)]="search"></search>
  </div>
  <a
    mat-icon-button
    class="btn btn-default btn-filters"
    [ngClass]="{
      active: (appliedFilters | filterBy: 'key':'duplicates':true).length
    }"
    (click)="openCustomFilter.emit(true)"
  >
    <i class="icon-filter"></i> {{ 'Filters' | translate }}
  </a>
</ng-container>

<ng-template #defaultFilter>
  <a mat-icon-button class="btn btn-default btn-filters" [matMenuTriggerFor]="filtersMenu" (menuOpened)="setValues()">
    <i class="icon-filter"></i> {{ 'Filters' | translate }}
  </a>
  <mat-menu #filtersMenu="matMenu" [overlapTrigger]="false">
    <form [formGroup]="form" (click)="$event.stopPropagation()">
      <div class="reset-filters" (click)="resetFilters()" translate>
        Reset Filters
      </div>
  
      <div class="search" *ngIf="allowSearch"
           [ngClass]="{'no-filters': !availableFilters || !availableFilters.length}">
        <mat-form-field>
          <input matInput placeholder="{{ 'Search' | translate }}"
            formControlName="search" (keypress)="onSearchboxKeypress($event)"
            (blur)="onBlur()" #searchInput>
        </mat-form-field>
      </div>
  
      <div *ngFor="let filter of availableFilters; let isFirst = first"
        class="filter {{ filter.filterType }}"
        [ngClass]="{first: isFirst}">
        <mat-form-field *ngIf="filter.filterType === 'select'">
          <mat-select [formControlName]="filter.key" [compareWith]="compareIds"
                      [placeholder]="filter.title" [multiple]="filter.multiple"
                      (selectionChange)="setValue($event)">
            <div class="scroll-container" cdk-scrollable [attr.data-filter]="filter.key">
              <mat-option *ngIf="filter.searchable">
                <ngx-mat-select-search [formControlName]="filter.searchKey"
                  noEntriesFoundLabel="No results found."
                  placeholderLabel="Search">
                  <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of filter.options" [value]="option.id">
                {{option.name}}
              </mat-option>
              <mat-option translate *ngIf="filter.loading" [value]="null" [disabled]="true">
                Loading...
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field *ngIf="filter.filterType === 'autocomplete'">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let value of filter.values; let idx=index"
              [selectable]="true" [removable]="true"
              (removed)="removeFilterOption(value, filter)">
              {{ filter.options[idx] && filter.options[idx].name }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input [placeholder]="filter.title" #filterInput [formControlName]="filter.key"
                  [matAutocomplete]="filterAutocomplete" [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
  
          <mat-autocomplete #filterAutocomplete="matAutocomplete"
                            (optionSelected)="addFilterOption($event, filter)">
            <mat-option *ngFor="let option of filter.options" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
  
        <ng-container *ngIf="filter.filterType === 'date'">
          <label>{{ filter.title }}</label>
          <date-range-picker [selectedDates]="filter.values"
                             [config]="{decreaseControl: false, increaseControl: false}"
                             (dateChanged)="onDateChanged(filter.key, $event)">
          </date-range-picker>
        </ng-container>
  
        <ng-container *ngIf="filter.filterType === 'text'">
          <label>{{ filter.title }}</label>
          <div class="text-input-wrapper">
            <input matInput
                  [formControlName]="filter.key"
                  [placeholder]="filter.title"
                  [attr.disabled]="(filter.values === true || form.controls[filter.key].value === true) ? true : null"
                  (change)="setValue($event)" />
            <mat-checkbox [formControlName]="filter.key" *ngIf="filter.withNullOption !== false">
              {{ filter.withNullOption }}
            </mat-checkbox>
          </div>
        </ng-container>
  
        <mat-checkbox [formControlName]="filter.key" *ngIf="filter.filterType === 'checkbox'">
          {{ filter.title }}
        </mat-checkbox>
      </div>
    </form>
  </mat-menu>
</ng-template>


