"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var api_service_1 = require("../shared/api.service");
var core_2 = require("@ngx-translate/core");
var user_service_1 = require("../users/user.service");
var tag_service_1 = require("../tags/tag.service");
var RuckitAddEmployeeDialogComponent = /** @class */ (function () {
    function RuckitAddEmployeeDialogComponent(data, dialogRef, router, userService, tagService, translateService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.router = router;
        this.userService = userService;
        this.tagService = tagService;
        this.translateService = translateService;
        this.title = 'Invite a New Employee';
        this.blurb = 'Invite an Employee to begin using Ruckit.';
        this.model = { tags: [] };
        this.loading = false;
        this.button = {
            title: 'Invite',
            disabled: false,
            type: 'primary'
        };
        this.errors = [];
        this.tags = [];
        this.tagOptions = [];
    }
    RuckitAddEmployeeDialogComponent.prototype.ngOnInit = function () {
        if (this.data.email) {
            this.model.email = this.data.email;
        }
        this.fetchTags();
        this.setContent();
    };
    RuckitAddEmployeeDialogComponent.prototype.setContent = function () {
        switch (this.data.type) {
            case 'customer': {
                this.title = this.translateService.instant('Invite Customer');
                this.blurb = this.translateService.instant('Invite your customer to begin using Ruckit. Once they accept ' +
                    'their account, they will be able to see jobs where they are ' +
                    'listed as the Customer.');
                break;
            }
            default: {
                this.title = this.translateService.instant('Invite a New Employee');
                this.blurb = this.translateService.instant('Invite an Employee to begin using Ruckit.');
                break;
            }
        }
    };
    RuckitAddEmployeeDialogComponent.prototype.fetchTags = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
            this.tagsReq.unsubscribe();
        }
        if (next) {
            this.tagsReq = this.tagService.listNext();
        }
        else {
            this.tags.length = 0;
            this.tagsReq = this.tagService.list(query);
        }
        if (this.tagsReq) {
            this.tagsReq.subscribe(function (res) {
                _this.tags.push.apply(_this.tags, res);
                _this.tags.forEach(function (tag) { return _this.tagOptions.push(tag.name); });
            }, function (err) { return _this.errors = err; });
        }
    };
    RuckitAddEmployeeDialogComponent.prototype.tagChange = function (e) {
        this.model['tags'] = e;
    };
    RuckitAddEmployeeDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.userService.inviteUser(this.model.email, this.data.organizationId, this.data.type === 'customer').subscribe(function (result) {
            _this.success = true;
            _this.button = {
                title: 'Invite Sent!',
                disabled: true,
                type: 'primary'
            };
            setTimeout(function () {
                _this.loading = false;
                _this.dialogRef.close();
            }, 1000);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.success = false;
            _this.button = {
                title: 'Invite Failed!',
                disabled: true,
                type: 'error'
            };
            setTimeout(function () {
                _this.loading = false;
                _this.dialogRef.close();
                _this.button = {
                    title: 'Invite',
                    disabled: false,
                    type: 'primary'
                };
            }, 4000);
        });
    };
    return RuckitAddEmployeeDialogComponent;
}());
exports.RuckitAddEmployeeDialogComponent = RuckitAddEmployeeDialogComponent;
