import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { DriversComponent } from '../drivers/drivers.component';
import { ConnectionService } from '../connections/connection.service';
import { ConnectionSerializer } from '../connections/connection.serializer';
import { TrucksComponent } from '../trucks/trucks.component';
import { View } from '../shared/view/view';

/**
 * Account Fleet Component: Requests and displays navigation links and,
 * through the {@link DriversComponent} or {@link TrucksComponent}, the fleet associated with the
 * selected Account.
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <account-fleet></account-fleet>
 */
@Component({
  selector: 'account-fleet',
  templateUrl: './account-fleet.component.html',
  styleUrls: ['./account-fleet.component.scss'],
  providers: []
})
export class AccountFleetComponent implements OnInit, OnDestroy {
  view: View = { stub: 'drivers', title: 'Drivers' };
  account = new ConnectionSerializer().fromJson({});
  loading = true;
  errors = [];
  search = '';
  query = {};
  accountId: string;
  carrierId: string;
  displayedColumns: string[] = [
    'select', 'name', 'cdl', 'phone-number', 'billing-id', 'carrier', 'truck', 'markets',
    'action'
  ];
  accountType: 'accounts' | 'leased-fleet' = 'leased-fleet';
  connectionReq: Subscription;
  @ViewChild(DriversComponent, { static: false }) driverTableWrapper: DriversComponent;
  @ViewChild(TrucksComponent, { static: false }) truckTableWrapper: TrucksComponent;

  /**
   * An anonymous callback function to call the FancyTable's `refreshTable()`
   * function that is called when drivers are created.
   */
  saveDriverCallback = () => {
    if (this.driverTableWrapper) { this.driverTableWrapper.refreshTable(); }
  }

  saveTruckCallback = () => {
    if (this.truckTableWrapper) { this.truckTableWrapper.refreshTable(); }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private connectionService: ConnectionService
  ) {}

  /**
  * Examines the route and query parameters to determine the Account Type and
  * persisted search value.
  *
  * If the route includes `accounts` the `customer_only: True` filter is added
  * to the query.
  *
  * If the `account` has an {@link Organization} with a {@link Carrier} the
  * `carrier: CARRIER_ID` filter is added to the query.
  */
  ngOnInit() {
    if (this.route && this.route.snapshot && this.route.snapshot.url.length) {
      this.view = <View>this.route.snapshot.data['view'];
      this.accountType = this.route.snapshot.url[0].path === 'accounts' ? 'accounts' : 'leased-fleet';
      this.displayedColumns = this.view.stub === 'drivers' ? [
        'select', 'name', 'cdl', 'phone-number', 'billing-id', 'carrier', 'truck', 'markets', 'action'
      ] : [
        'select', 'truck-type', 'name', 'license-plate', 'carrier', 'markets', 'action'
      ];
    }

    if (this.route && this.route.params) {
      this.route.params.subscribe(params => {
        if (params['accountId']) {
          this.accountId = params['accountId'];
          this.getAccount(this.accountId);
        } else if (this.router.url.includes('accounts')) {
          this.query = {...this.query, ...{customer_only: 'True'}};
        } else {
          this.query = {...this.query, ...{all_leased: 'True'}};
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
      this.connectionReq.unsubscribe();
    }
  }

  /**
   * Handles changing to and from the archive view, navigating to the correct url
   *
   * @param {boolean} view Should the view be changed to archive or not
   */
   viewArchive(view: boolean) {
    if (view) {
      this.query = {...this.query, ...{status: 'deleted'}};
      this.updateRowActions('archive');
    } else {
      delete this.query['status'];
      this.query = { ...this.query };
      this.updateRowActions('main');
    }
  }

  updatedMarkets() {
    if (this.driverTableWrapper) { this.driverTableWrapper.refreshTable(); }
    if (this.truckTableWrapper) { this.truckTableWrapper.refreshTable(); }
  }

  /**
   * Returns a boolean indicating whether or not the current URL, excluding
   * query parameters, is equal to the `url` value passed in for comparison.
   *
   * @param {} url An array of strings representing the anchor tag's URL parts
   */
  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);

    return baseUrl === url;
  }

  /**
   * Requests the {@link Connection} or "account" and captures the `carrierId`
   * and generates `query` parameters.
   *
   * @param {} accountId The UUID of the connection to get from the API
   */
  getAccount(accountId: string): void {
    this.loading = true;
    if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
      this.connectionReq.unsubscribe();
    }

    this.connectionReq = this.connectionService.get(accountId).subscribe(account => {
      this.account = account;
      this.carrierId = this.account &&
                       this.account.organization &&
                       this.account.organization.carrier &&
                       this.account.organization.carrier.id;
      if (this.router.url.includes('accounts')) {
        this.query = {...this.query, ...{customer_only: 'True'}};
      }
      if (this.router.url.includes('leased') && !this.carrierId) {
        this.query = {...this.query, ...{all_leased: 'True'}};
      }
      if (this.carrierId) {
        this.query = {...this.query, ...{carrier: this.carrierId}};
      }
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  updateRowActions(type: 'main' | 'archive') {
    if (type === 'main') {
      if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
        this.driverTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Edit', action: 'edit', link: false, external: false }
        ];
      }
      if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
        this.truckTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Edit', action: 'edit', link: false },
          { name: 'Remove', action: 'remove', link: false, external: false }
        ];
      }
    } else {
      if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
        this.driverTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Reactivate', action: 'unarchive', link: false, external: false }
        ];
      }
      if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
        this.truckTableWrapper.tableConfig['menuOptions'] = [
          { name: 'Reactivate', action: 'unarchive', link: false, external: false }
        ];
      }
    }
  }
}
