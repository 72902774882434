import { JobEventStat } from '../job-event-stats/job-event-stat';

export class CollaboratorStat {
  id: string;
  name: string;
  assignedTrucks: number;
  averageTripTime: number;
  confirmedTrucks: number;
  deliveredLoads: number;
  dispatchedTrucks: number;
  jobEventStats: JobEventStat[];
  requestedTrucks: number;
  ticketedWeight: number;
  truckTypes: number;
  weightInTransit: number;
  driversOnShift: number;
  dispatchedLoads: number;
  dispatchedTons: number;
  invoiceTotal: number;
  haulTotal: number;
  loading: boolean;
  selected = false;
  classes: string;
  canExpand = false;
}
