<div class="app-content">
  <form #newPunchCard="ngForm" class="punch-card-form">
    <my-toolbar title="{{ 'New Punch Card' | translate }}"></my-toolbar>

    <action-bar>
      <div class="left-actions"></div>
      <div class="right-actions">
        <button class="btn btn-primary" (click)="submitPunchCard(); false"
                [disabled]="loading || !newPunchCard.form.valid"
                [ngClass]="{loading:loading}">
          Save
        </button>
      </div>
    </action-bar>

    <div class="punch-card-container">
      <notification *ngFor="let error of errors">{{error}}</notification>

      <div class="punch-card-data">
        <div class="form-content">
          <div class="field-group job full-width">
            <label class="required" translate>Job</label>
            <dropdown [options]="jobDropdownOptions"
                      (onSelect)="onSelect('job', $event)"
                      [config]="jobDropdownConfig"
                      #jobDropdown
                      title="&ndash;"
                      (onSearch)="dropdownSearch($event, 'job')"
                      (nextPage)="dropdownNextPage($event, 'job')"></dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group job-event full-width">
            <label class="required" translate>Jobs Allowed</label>
            <dropdown id="jobEvents-dropdown"
                      [disabled]="!job || jobEventsLoading"
                      [options]="jobEventsDropdownOptions"
                      [config]="jobEventsDropdownConfig"
                      #jobEventsDropdown
                      title="{{ jobEventsLoading ? 'Loading Job Days...' : 'Select a Job Day' }}"
                      (onSelect)="onSelect('jobevent', $event)"
                      (onSearch)="dropdownSearch($event, 'jobEvents')"
                      (nextPage)="dropdownNextPage($event, 'jobEvents')"></dropdown>
          </div>
        </div>

        <notification *ngIf="jobEventError">{{jobEventError}}</notification>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Carrier</label>
              <ruckit-dropdown
                #carriersDropdown
                [disabled]="!jobEvent"
                [config]="carrierDropdownConfig"
                (onSelect)="onSelect('carrier', $event)"
            ></ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Driver</label>
            <dropdown id="driver-dropdown"
                      [options]="driverDropdownOptions"
                      [disabled]="!jobEvent || !carrier"
                      [config]="driverDropdownConfig"
                      #driverDropdown
                      title="&ndash;"
                      (onSelect)="onSelect('driver', $event)"
                      (onSearch)="dropdownSearch($event, 'driver')"
                      (nextPage)="dropdownNextPage($event, 'driver')"></dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group truck full-width">
            <label class="required" translate>Truck</label>
            <dropdown id="truck-dropdown"
                      [options]="truckDropdownOptions"
                      [disabled]="!jobEvent || !carrier"
                      [config]="truckDropdownConfig"
                      #truckDropdown
                      title="&ndash;"
                      (onSelect)="onSelect('truck', $event)"
                      (onSearch)="dropdownSearch($event, 'truck')"
                      (nextPage)="dropdownNextPage($event, 'truck')"></dropdown>
          </div>
        </div>

        <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="field-group pickers">
            <div class="start-date group" fxFlex="50">
              <label class="required" translate>Start Time</label>
              <date-range-picker [disabled]="!jobEvent"
                                 [highlightedDates]="jobDates"
                                 (dateChanged)="onStartDateChanged($event)">
              </date-range-picker>
            </div>

            <div class="start-time group" fxFlex="50">
              <label>&nbsp;</label>
              <div class="timepicker-wrapper">
                <input 
                  type="time" 
                  name="start_time" 
                  class="timepicker"
                  (focusout)="onStartTimeChange($event)"
                  [disabled]="!jobEvent" 
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="end-date group" fxFlex="50">
            <label class="required" translate>End Time</label>
            <date-range-picker [disabled]="!jobEvent"
                               [highlightedDates]="jobDates"
                               (dateChanged)="onEndDateChanged($event)">
            </date-range-picker>
          </div>

          <div class="end-time group" fxFlex="50">
            <label>&nbsp;</label>
            <div class="timepicker-wrapper">
              <input 
              type="time" 
              name="end_time" 
              class="timepicker"
              (focusout)="onEndTimeChange($event)"
              [disabled]="!jobEvent" 
            />
            </div>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group ticket-number">
            <label class="required" translate>Ticket #</label>
            <input type="text" name="ticket-number" placeholder=""
                   [disabled]="!jobEvent" [(ngModel)]="punchCard.ticketNumber"
                   #ticketNumber="ngModel" />
          </div>
        </div>

        <div class="field-group ticket-image">
          <label translate>Ticket Image</label>

          <div class="upload-image-dropzone" [ngClass]="{filled: ticketImageFile && ticketImageFile.file}">
            <img *ngIf="ticketImageFile.file" [src]="ticketImageFile.dataUri" />
            <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
            <label class="upload-label" for="file">
              <i class="icon-upload"></i>
              <span *ngIf="!ticketImageFile" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
              <span *ngIf="ticketImageFile" translate>Upload a new photo</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
