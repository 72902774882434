"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var unit_of_measure_1 = require("./unit-of-measure");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var UnitOfMeasureSerializer = /** @class */ (function () {
    function UnitOfMeasureSerializer() {
    }
    /**
     * @param  {any} json
     * @returns {UnitOfMeasure} UnitsOfMeasure
     */
    UnitOfMeasureSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var unitOfMeasure = new unit_of_measure_1.UnitOfMeasure();
        if (!json) {
            return unitOfMeasure;
        }
        unitOfMeasure.id = json.id;
        unitOfMeasure.active = json.active;
        unitOfMeasure.name = json.name;
        unitOfMeasure.isFavorite = json.isFavorite;
        unitOfMeasure.key = json.key;
        return unitOfMeasure;
    };
    /**
     * @param  {UnitOfMeasure} UnitOfMeasure
     * @returns any
     */
    UnitOfMeasureSerializer.prototype.toJson = function (unitOfMeasure) {
        var json = {
            id: unitOfMeasure.id,
            active: unitOfMeasure.active,
            name: unitOfMeasure.name,
            isFavorite: unitOfMeasure.isFavorite,
            key: unitOfMeasure.key,
        };
        return decamelizeKeysDeep(json);
    };
    return UnitOfMeasureSerializer;
}());
exports.UnitOfMeasureSerializer = UnitOfMeasureSerializer;
