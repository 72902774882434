import { Invoice } from './invoice';
import { JOB } from '../jobs/mock-job';
import { CONDENSEDTRIP } from '../trips/mock-condensed-trip';
import { CONDENSEDPUNCHCARD } from '../punch-cards/mock-condensed-punch-card';
import { ORGANIZATION } from '../organizations/mock-organization';

export let INVOICE: Invoice = <Invoice> {
  id: '00000000-0000-0000-0000-000000000001',
  number: '1234',
  amount: '1234.56',
  subtotal: '1234.44',
  expenseAmount: '123.00',
  surchargeAmount: '123.00',
  tripsAmount: '0.0',
  tripsExpenseAmount: '0.0',
  punchcardsAmount: '0.0',
  punchcardsExpenseAmount: '0.0',
  haulTotal: '123.00',
  ownerOrganization: ORGANIZATION,
  customerOrganization: ORGANIZATION,
  job: JOB,
  sent: false,
  draft: true,
  sendable: false,
  expensed: false,
  sentTime: '2017-05-03T08:00:00Z',
  sentBy: '',
  sentTrips: [CONDENSEDTRIP, CONDENSEDTRIP],
  sentPunchCards: [CONDENSEDPUNCHCARD, CONDENSEDPUNCHCARD],
  billFromContactEmail: 'invoice\@goruckit.com',
  billFromCcEmails: 'diego\@goruckit.com, adriana\@goruckit.me',
  billFromAddress: 'Ruckit',
  billToContactEmail: 'johndoe\@brbrokergroup.biz',
  billToCcEmails: '',
  billToAddress: 'B\&R Broker Group LLC',
  createdAt: '2017-05-03T08:00:00Z',
  invoiceUrl: '',
  startDate: '2017-04-03T08:00:00Z',
  endDate: '2017-05-03T08:00:00Z',
  remittanceInfo: 'Remittance Info',
  earliestBillableItemTime: '',
  latestBillableItemTime: '',
  invoicePeriod: 'April 3, 2017 - May 3, 2017',
  title: 'Invoice Draft',
  administrativeStatus: 'draft',
  displayableStatus: false,
  shortTitle: 'Invoice Draft ',
  pdf: '',
  html: '',
  startTime: '',
  endTime: '',
  tripDates: 'April 3, 2017 - May 3, 2017',
  url: null,
  filterOptions: null,
  selected: false,
  invoiceDate: '2017-05-03T08:00:00Z',
  kind: 'invoice'
};
