import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ResourceService } from '../shared/resource.service';
import { JobEventShare } from './job-event-share';
import { JobEventShareSerializer } from './job-event-share.serializer';
import { requestHeaders, handleError } from '../shared/index';

@Injectable()
export class JobEventShareService extends ResourceService<JobEventShare> {
  constructor (http: HttpClient) {
    super(http, 'shares/', new JobEventShareSerializer());
  }

  removeCollaboration(jobEventId: string, jobEventShareId: string): Observable<JobEventShare> {
    const resourceUrl = `${this.baseUrl}jobevents/${jobEventId}/collaborators/${jobEventShareId}/remove/`;
    return this.http.post(resourceUrl, {}, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.convertJobEventShare(res)),
      catchError(handleError)
    );
  }

  private convertJobEventShare(data: any): JobEventShare {
    return <JobEventShare>this.serializer.fromJson(data);
  }
}
