<ng-template #dialogRef let-data>
  <h2 class="title" translate>No Assignments</h2>
  <p class="body">
    {{ data.name }} does not have any assignments for the date selected. Do you
    want to dispatch {{ data.name }} to a job?
  </p>
  <div class="actions">
    <button
      class="btn btn-cancel close"
      (click)="dispatchDialog.close(false)"
      translate
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      (click)="onCreateNewAssignmentClick(data)"
      translate
    >
      <span class="icon-plus"></span>
      Dispatch Driver
    </button>
  </div>
</ng-template>
