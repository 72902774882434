"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var user_service_1 = require("../users/user.service");
var organization_service_1 = require("../organizations/organization.service");
var organization_market_dialog_component_1 = require("../organizations/organization-market-dialog.component");
var authentication_service_1 = require("../shared/authentication.service");
var api_service_1 = require("../shared/api.service");
var organization_1 = require("../organizations/organization");
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(route, router, userService, organizationService, authenticationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.organizationService = organizationService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.loading = true;
        this.errors = [];
        this.tab = 'edit';
        this.area = 'user';
        this.currentUrl = '';
        this.saveOrganizationCallback = function (e) {
            // Update organization data
        };
        this.newOrganizationMarketCallback = function (e) {
            _this.editOrganizationMarkets.getOrganizationMarkets();
        };
        this.currentUrl = this.route.snapshot.url.join('/');
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            if (params['tab']) {
                _this.tab = params['tab'];
            }
            _this.area = params['area'];
            switch (_this.area) {
                case 'user': {
                    _this.getUser();
                    break;
                }
                case 'organization':
                default: {
                    _this.getOrganization();
                    break;
                }
            }
        });
    };
    SettingsComponent.prototype.ngOnDestroy = function () {
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
        if (this.organizationReq && typeof this.organizationReq.unsubscribe === 'function') {
            this.organizationReq.unsubscribe();
        }
    };
    SettingsComponent.prototype.getUser = function () {
        var _this = this;
        this.loading = true;
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
        var user = this.authenticationService.user();
        if (user) {
            this.userReq = this.userService.get(user.id).subscribe(function (_user) {
                _this.user = _user;
                _this.routeToTab('user', _user.id, _this.tab);
                _this.loading = false;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    SettingsComponent.prototype.getOrganization = function () {
        var _this = this;
        this.loading = true;
        if (this.organizationReq && typeof this.organizationReq.unsubscribe === 'function') {
            this.organizationReq.unsubscribe();
        }
        var org = new organization_1.Organization(this.authenticationService.getOrganization());
        if (org) {
            this.organizationReq = this.organizationService.getOrganization(org.id).subscribe(function (organization) {
                _this.organization = organization;
                _this.routeToTab('organization', organization.id, _this.tab);
                _this.loading = false;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    SettingsComponent.prototype.openAddOrganizationMarket = function () {
        var dialog = this.dialog.open(organization_market_dialog_component_1.OrganizationMarketDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = this.newOrganizationMarketCallback;
    };
    SettingsComponent.prototype.routeToTab = function (area, objectId, tab) {
        this.tab = tab;
        if (objectId && this.currentUrl !== "settings/" + area + "/" + objectId + "/" + tab) {
            this.router.navigate(['/settings', area, objectId, tab]);
        }
    };
    return SettingsComponent;
}());
exports.SettingsComponent = SettingsComponent;
