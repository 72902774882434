<div class="dropdown" role="menu" (window:keydown)="onKeyPress($event)" [ngClass]="{
    open: open,
    disabled: disabled,
    empty: ! selectedOption,
    action: config.style === 'action',
    user: config.user,
    'bottom-right': config.pin === 'bottom-right',
    'custom-icon': icon,
    searchable: config.searchable,
    'loading-options': config.loadingOptions
  }">

  <div class="dropdown-menu" [style.height]="menuHeight" [style.width]="menuWidth">
    <div class="dropdown-options" #optionsEl [attr.aria-hidden]="(open ? 'false':'true') " aria-label="submenu">

      <div class="scrollable" (mousewheel)="onScroll($event)">

        <div *ngIf="!config.group">
          <div class="option" attr.data-id="{{option.id}}" role="menuitem" *ngFor="let option of options" (click)="toggleOption(option)"
            tabindex="-1" (keypress)="optionKeyPress($event, option)" [ngClass]="{selected: isSelected(option), button: option.button, 'has-image': config.image}">
            <div *ngIf="config.image">
              <img class="image" src="{{optionImageDisplay(option)}}" *ngIf="optionImageDisplay(option)" />
              <div class="default-image" *ngIf="!optionImageDisplay(option)">
                <i class="icon-fleet"></i>
              </div>
            </div>
            <div class="title">
              {{optionDisplayName(option)}}
            </div>
            <div class="subtitle">
              <span class="license">License Plate: {{optionDisplayLicense(option)}}</span>
              <span class="truck-id">Truck Number: {{optionDisplayTruckId(option)}}</span>
            </div>
          </div>

          <div class="option loading-option" *ngIf="config.loadingOptions">
            <my-loader></my-loader>
          </div>
        </div>

      </div>
    </div>
  </div>
