"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var api_service_1 = require("../shared/api.service");
var punch_card_service_1 = require("./punch-card.service");
var job_service_1 = require("../jobs/job.service");
var job_event_service_1 = require("../job-events/job-event.service");
var driver_service_1 = require("../drivers/driver.service");
var truck_service_1 = require("../trucks/truck.service");
var organization_service_1 = require("../organizations/organization.service");
var punch_card_1 = require("./punch-card");
var job_serializer_1 = require("../jobs/job.serializer");
var project_serializer_1 = require("../projects/project.serializer");
var core_2 = require("@ngx-translate/core");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var NewPunchCardComponent = /** @class */ (function () {
    function NewPunchCardComponent(route, router, dialog, punchCardService, jobService, jobEventService, driverService, truckService, organizationService, translateService) {
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.punchCardService = punchCardService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.driverService = driverService;
        this.truckService = truckService;
        this.organizationService = organizationService;
        this.translateService = translateService;
        this.errors = [];
        this.jobEventError = null;
        this.ticketImageFile = {
            dataUri: null,
            file: null
        };
        this.punchCard = new punch_card_1.PunchCard({});
        this.jobDates = [];
        this.loading = false;
        this.daysLoading = false;
        this.jobEventsLoading = false;
        this.jobDropdownOptions = [];
        this.jobDropdownConfig = {
            nameProperty: 'nameWithDate',
            searchable: true,
            group: true,
            groupBy: function (job) { return job.group; },
            loadingOptions: false
        };
        this.driverDropdownOptions = [];
        this.driverDropdownConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.truckDropdownOptions = [];
        this.truckDropdownConfig = {
            nameProperty: 'displayName',
            searchable: true,
            loadingOptions: false
        };
        this.carrierDropdownConfig = {
            nameProperty: 'name',
            idProperty: 'carrier.id',
            selectText: this.translateService.instant('Select Carrier'),
            loadingText: this.translateService.instant('Loading Carriers...'),
            noResultsText: this.translateService.instant('No Carriers'),
            service: organization_service_1.OrganizationService,
            serviceFunction: 'get',
            query: {
                exclude: null,
            },
            includeFullObject: true,
            prefilledOptions: [],
        };
        this.jobEventsDropdownOptions = [];
        this.jobEventsDropdownConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.allSubscriptionsToUnsubscribe = [];
    }
    NewPunchCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.getJobs();
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.returnTo = result && result.qparams['returnTo'];
            if (result && result.params['id']) {
                _this.jobId = result.params['jobId'];
                _this.jobEventId = result.params['jobEventId'];
                _this.prefillPunchCard(_this.jobId, _this.jobEventId);
            }
        }));
    };
    NewPunchCardComponent.prototype.ngOnDestroy = function () {
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
            this.jobsReq.unsubscribe();
        }
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.punchCardReq && typeof this.punchCardReq.unsubscribe === 'function') {
            this.punchCardReq.unsubscribe();
        }
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        if (this.trucksReq && typeof this.trucksReq.unsubscribe === 'function') {
            this.trucksReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    NewPunchCardComponent.prototype.submitPunchCard = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.punchCardService.saveWithFile(this.punchCard, this.ticketImageFile.file).subscribe(function () {
            _this.loading = false;
            if (_this.returnTo) {
                _this.router.navigate([_this.returnTo]);
            }
            else {
                _this.router.navigate(['/punchcards']);
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    NewPunchCardComponent.prototype.prefillPunchCard = function (jobId, jobEventId) {
        this.getJob(jobId);
    };
    NewPunchCardComponent.prototype.onStartDateChanged = function (dates) {
        this.punchCard.startDate = dates[0];
    };
    NewPunchCardComponent.prototype.onEndDateChanged = function (dates) {
        this.punchCard.endDate = dates[0];
    };
    NewPunchCardComponent.prototype.onStartTimeChange = function (value) {
        var time = value.target.value;
        this.punchCard.startTime = time;
    };
    NewPunchCardComponent.prototype.onEndTimeChange = function (value) {
        var time = value.target.value;
        this.punchCard.endTime = time;
    };
    NewPunchCardComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                this.job = e;
                this.getJobDays(this.job && this.job.id);
                this.getJobEvents({});
                break;
            case 'jobevent':
                this.jobEvent = e;
                var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: e.id });
                this.carriersDropdown.config.query = query;
                this.carriersDropdown.getRecords(query);
                break;
            case 'driver':
                this.driver = e;
                break;
            case 'truck':
                this.truck = e;
                break;
            case 'carrier':
                this.carrier = e;
                this.getDrivers();
                this.getTrucks();
                break;
        }
        this.punchCard[filterType] = e;
    };
    NewPunchCardComponent.prototype.getJob = function (id) {
        var _this = this;
        this.jobDropdownOptions = [];
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        if (id) {
            this.loading = true;
            this.jobReq = this.jobService.get(id).subscribe(function (job) {
                _this.job = job;
                if (_this.job) {
                    _this.jobDropdownOptions = lodash_1.reject(_this.jobDropdownOptions, _this.job);
                    _this.jobDropdownOptions.unshift(_this.job);
                    _this.getJobDays(_this.job && _this.job.id);
                    _this.jobDropdown.selectedOption = _this.job;
                }
                else {
                    var project = new project_serializer_1.ProjectSerializer().fromJson({ id: _this.job.project.id, name: _this.job.project.name });
                    _this.job = new job_serializer_1.JobSerializer().fromJson({ id: _this.job.id, name: _this.job.name, project: project });
                    _this.jobDropdownOptions.unshift(_this.job);
                    _this.getJobDays(_this.job && _this.job.id);
                    _this.jobDropdown.selectedOption = _this.job;
                }
                _this.loading = false;
            }, function (err) {
                _this.errors = err;
                _this.loading = false;
            });
        }
    };
    NewPunchCardComponent.prototype.getJobDays = function (jobId) {
        var _this = this;
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        this.daysLoading = true;
        this.daysReq = this.jobService.getDays(jobId).subscribe(function (days) {
            _this.jobDates = days.map(function (day) { return moment(day).toDate(); });
            _this.daysLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.daysLoading = false;
        });
    };
    NewPunchCardComponent.prototype.getJobs = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.jobDropdownOptions = [];
        if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
            this.jobsReq.unsubscribe();
        }
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        this.jobsReq = this.jobService.list(__assign({ ordering: 'project__name,name,start_date', serializer: 'JobGroupedDropdown' }, query)).subscribe(function (jobs) {
            _this.jobDropdownOptions = _this.jobDropdownOptions.concat(jobs);
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    NewPunchCardComponent.prototype.getJobEvents = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        this.jobEventsLoading = true;
        this.jobEventError = null;
        this.jobEventsDropdownOptions = [];
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ job: this.job && this.job.id || this.jobId, ordering: '-shift1_start', page_size: 25 }, query)).subscribe(function (jobEvents) {
            if (jobEvents && jobEvents.length) {
                _this.jobEventsDropdownOptions = jobEvents;
            }
            else {
                _this.jobEventError = 'No Job Event found for that date!';
            }
            _this.jobEventsLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.jobEventsLoading = false;
        }, function () {
            _this.loading = false;
        });
    };
    NewPunchCardComponent.prototype.getDrivers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.driverDropdownOptions = [];
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        this.driversReq = this.driverService.list(__assign({ ordering: 'name', carrier: this.carrier && this.carrier.id }, query)).subscribe(function (drivers) {
            _this.driverDropdownOptions = drivers;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    NewPunchCardComponent.prototype.getTrucks = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.truckDropdownOptions = [];
        if (this.trucksReq && typeof this.trucksReq.unsubscribe === 'function') {
            this.trucksReq.unsubscribe();
        }
        this.trucksReq = this.truckService.list(__assign({ ordering: 'name', carrier: this.carrier && this.carrier.id, shared_jobevent: this.jobEvent && this.jobEvent.id }, query)).subscribe(function (trucks) {
            _this.truckDropdownOptions = trucks;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    NewPunchCardComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'job':
                this.getJobs({ search: term });
                break;
            case 'jobEvents':
                this.getJobEvents({ search: term });
                break;
            case 'truck':
                this.getTrucks({ search: term });
                break;
            case 'driver':
                this.getDrivers({ search: term });
                break;
            case 'carrier':
                break;
        }
    };
    NewPunchCardComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config, service, options, o;
        switch (type) {
            case 'job':
                config = this.jobDropdownConfig;
                service = this.jobService;
                options = this.jobDropdownOptions;
                o = service.listNext();
                break;
            case 'jobEvents':
                config = this.jobEventsDropdownConfig;
                service = this.jobEventService;
                options = this.jobEventsDropdownOptions;
                o = service.listNext();
                break;
            case 'carrier':
                break;
            case 'driver':
                config = this.driverDropdownConfig;
                service = this.driverService;
                options = this.driverDropdownOptions;
                o = service.listNext();
                break;
            case 'truck':
                config = this.truckDropdownConfig;
                service = this.truckService;
                options = this.truckDropdownOptions;
                o = service.listNext();
                break;
        }
        if (!config.loadingOptions) {
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'job':
                            _this.jobDropdownOptions = _this.jobDropdownOptions.concat(results);
                            break;
                        case 'jobEvents':
                            _this.jobEventsDropdownOptions = _this.jobEventsDropdownOptions.concat(results);
                            break;
                        case 'carrier':
                            break;
                        case 'driver':
                            _this.driverDropdownOptions = _this.driverDropdownOptions.concat(results);
                            break;
                        case 'truck':
                            _this.truckDropdownOptions = _this.truckDropdownOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    NewPunchCardComponent.prototype.fileChange = function (e) {
        var checkin;
        checkin = this.ticketImageFile;
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
            checkin.dataUri = loadEvent.target.result;
        };
        var file = e.srcElement.files[0];
        checkin.file = file;
        reader.readAsDataURL(file);
    };
    return NewPunchCardComponent;
}());
exports.NewPunchCardComponent = NewPunchCardComponent;
