<div class="app-content">
  <my-toolbar title="{{ 'Plants' | translate }}"></my-toolbar>
  <notification *ngFor="let error of errors" translate>{{error}}</notification>

  <action-bar>
    <div class="left-actions">
      <ruckit-dropdown [config]="locationDropdownConfig"
        [selectedOption]="selectedLocationId"
        (onUserSelect)="onSelectLocation($event)">
      </ruckit-dropdown>
    </div>
    <div class="right-actions">
      <dropdown class="actions" title="{{ 'Actions' | translate }}"
                [disabled]="multiselectDisabled()"
                [options]="multipleActionDropdownOptions"
                (onSelect)="setSelectedAction($event)">
      </dropdown>
      <div class="segmented-buttons">
        <a (click)="zoomTo('trucks')" class="btn btn-default" translate>
          Show All Trucks
        </a>
        <a (click)="zoomTo('location')" class="btn btn-default" translate>
          Zoom to Location
        </a>
      </div>
    </div>
  </action-bar>

  <div class="container">
    <div class="actions-wrapper" *ngIf="driverAssignmentsTableConfigReady">
      <fancy-table #driverAssignmentsTable
                   [(availableColumns)]="availableColumns" 
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters" 
                   [(filters)]="appliedFilters" 
                   [(search)]="search"
                   [config]="driverAssignmentsTableConfig"
                   (onDataLoaded)="onDataLoaded($event)"
                   (selectionChanged)="onSelectionChanged($event)"
                   (onClickAction)="clickAction($event)"
                   (onMenuAction)="menuAction($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'truck-carrier'">
              <div class="data">
                <div class="truck-name">{{ row.latestTruck && row.latestTruck.displayName }}</div>
                <div class="carrier-name">{{ row.latestTruck && row.latestTruck.carrier || '&nbsp;' }}</div>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'eta-customer'">
              <div class="data">
                <div class="eta">TODO</div>
                <div class="customer">{{ row.organization || '&nbsp;' }}</div>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'status'">
              <div class="data">{{ row.status || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
      </fancy-table>
    </div>

    <div class="truck-map">
      <truck-map [listComponent]="driverAssignmentsTable"
                 [dateRange]="activeRange"
                 [selectedLocationId]="selectedLocationId"
                 (selectedDriver)="scrollToSelectedDriver($event)"></truck-map>
    </div>
  </div>
</div>
