"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column}.app-content[_ngcontent-%COMP%]  .action-bar-content{padding-bottom:0;min-height:73px}.app-content[_ngcontent-%COMP%]  .subnav .links{margin:0;border-bottom:none}.actions-wrapper[_ngcontent-%COMP%]{position:relative}.actions-wrapper[_ngcontent-%COMP%]  mat-header-row, .actions-wrapper[_ngcontent-%COMP%]  mat-row{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.actions-wrapper[_ngcontent-%COMP%]  .fancy-table-wrapper{height:calc(100VH - 70px - 30px - 44px)}.actions-wrapper[_ngcontent-%COMP%]  .mat-cell, .actions-wrapper[_ngcontent-%COMP%]  .mat-header-cell{min-width:100px}.actions-wrapper[_ngcontent-%COMP%]  .mat-column-select{min-width:64px}.actions-wrapper[_ngcontent-%COMP%]  .mat-column-job-title{min-width:200px}.actions-wrapper[_ngcontent-%COMP%]  .mat-column-end-time, .actions-wrapper[_ngcontent-%COMP%]  .mat-column-loading-time, .actions-wrapper[_ngcontent-%COMP%]  .mat-column-start-time, .actions-wrapper[_ngcontent-%COMP%]  .mat-column-unloading-time{min-width:120px}"];
exports.styles = styles;
