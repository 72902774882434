import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { combineLatest as observableCombineLatest, Observable, of, Subscription } from 'rxjs';
import { clone, find as _find, pick } from 'lodash';
import { NgForm } from '@angular/forms';

import { CollaboratorService } from '../collaborators/collaborator.service';
import { JobEventShare } from '../job-event-shares/job-event-share';
import { JobEvent } from './../job-events/job-event';
import { JobService } from './../jobs/job.service';
import { parseErrors } from '../shared/api.service';

export enum Concept {
  Job = 'job',
  JobEventShareId = 'job_event_share_id',
  Share = 'share'
}

@Component({
  selector: 'edit-share-dialog',
  templateUrl: './edit-share-dialog.component.html',
  styleUrls: ['./edit-share-dialog.component.scss'],
  providers: [CollaboratorService]
})
export class EditShareDialogComponent implements OnInit, OnDestroy {
  loading = false;
  @Input() jobEvent: JobEvent;
  @Input() jobEventShare: JobEventShare;
  @Input() change = 'invoice';

  concepts: Concept[] = [];
  model: any = {};
  title = 'Change Rates';
  unitFor: any = {};
  truckTypes = [];
  truckTypeMap = {};
  anyTruckType = true;
  errors = [];
  callback;
  typeConfig = {
    nameProperty: 'name',
    group: true,
    groupBy: type => type.group,
  };
  haulTypeOption;
  haulTypeOptions = [
    { group: 'Load', value: 'load', label: 'Load', name: 'Load' },
    { group: 'Hour', value: 'hour', label: 'Hour', name: 'Hour' },
    { group: 'Weight', value: 'ton', label: 'Tons', name: 'Ton' },
    { group: 'Weight', value: 'metric-tons', label: 'Metric Tons', name: 'Metric Ton' },
    { group: 'Weight', value: 'pound', label: 'Pounds', name: 'Pound' },
    { group: 'Weight', value: 'cuyds', label: 'Cu. Yds.', name: 'Cubic Yard' },
    { group: 'Weight', value: 'bushel', label: 'Bushels', name: 'Bushel' },
    { group: 'Weight', value: 'bag', label: 'Bags', name: 'Bag' }
  ];
  invoiceTypeOption;
  invoiceTypeOptions = clone(this.haulTypeOptions);
  @ViewChild('editShare', { static: false }) editShare;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditShareDialogComponent>,
    private collaboratorService: CollaboratorService,
    private jobService: JobService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.configureTypeOptions();
    this.determineConcept();

    const pertinentFields = [
      'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
      'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
    ];
    if (this.jobEventShare) {
      this.model = pick(this.jobEventShare, pertinentFields);
    } else if (this.jobEvent) {
      this.model = pick(this.jobEvent, pertinentFields);
      this.model['invoiceRate'] = this.jobEvent.rate;
    }
    try { this.model['invoiceRate'] = Number.parseFloat(this.model['invoiceRate']).toFixed(2); } catch (e) { }
    try { this.model['haulRate'] = Number.parseFloat(this.model['haulRate']).toFixed(2); } catch (e) { }

    switch (this.change) {
      case 'trucks': {
        this.title = 'Change Trucks';
        break;
      }
      default: {
        this.title = 'Change Rates';
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /**
   * Determines who is editing the values presented to map the requests to the
   * appropriate endpoints.
   *
   * As the owner of the Job, update the Job and my Share as defined by
   * jobEvent.shareId.
   *
   * All other actions should only be relevant to the JobEventShare ID.
   */
  determineConcept(): void {
    if (this.jobEvent && this.jobEvent.job) {
      const ownerOrganization = this.jobEvent.job.project.ownerOrganization;
      const currentOrganization = this.jobEventShare ? this.jobEventShare.organizationId : ownerOrganization;

      if (ownerOrganization === currentOrganization) {
        this.concepts.push(Concept.JobEventShareId, Concept.Job);
      } else {
        this.concepts.push(Concept.Share);
      }
    } else if (this.jobEventShare) {
      this.concepts.push(Concept.Share);
    }
  }

  /**
   * Checks job settings to determine possible haul and invoice type options.
   *
   * Once the options have been determined, set the selected option for each.
   */
  configureTypeOptions(): void {
    const job = this.jobEvent ? this.jobEvent : this.jobEventShare;
    if (job && job.job) {
      if (job && job.multipliers && job.multipliers.length > 0) {
        this.haulTypeOptions = this.haulTypeOptions.concat([{
          group: 'Percentage', value: 'percentage', label: 'Percentage', name: 'Percentage'
        }]);
        this.invoiceTypeOptions = this.invoiceTypeOptions.concat([{
          group: 'Percentage', value: 'percentage', label: 'Percentage', name: 'Percentage'
        }]);
      }
      this.invoiceTypeOption = _find(this.invoiceTypeOptions, {
        value: job.invoiceType === 'weight' ? job.invoiceWeightUnit : job.invoiceType
      });
      this.haulTypeOption = _find(this.haulTypeOptions, {
        value: job.haulType === 'weight' ? job.haulWeightUnit : job.haulType
      });
      this.unitFor['invoice'] = this.invoiceTypeOption ? this.invoiceTypeOption.value : '';
      this.unitFor['haul'] = this.haulTypeOption ? this.haulTypeOption.value : '';
    }
  }

  /**
   * Combine and subscribe to each API request. Upon finishing, hit the callback
   * with with the updated share response as well as the original jobEvent
   * object for updating associated rate values.
   *
   */
  submit() {
    let combinedParams = observableCombineLatest(
      this.submitJobEventShare(), this.submitJob(),
      (jobEventShare, job) => ({ jobEventShare, job })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.callback({
          jobEventShare: result.jobEventShare,
          jobEvent: this.jobEvent,
          change: this.change
        });
        this.dialogRef.close();
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
        this.loading = false;
      })
    );
  }

  /**
   * Upon a weight unit change, update the cooresponding model attribute and
   * mark the form as dirty.
   */
  weightUnitChange(tracking, e, form: NgForm): void {
    this.unitFor[tracking] = e.value;
    form.controls[tracking].markAsDirty();
    this.model[tracking + 'Type'] = e.group.toLowerCase();
    switch (e.group) {
      case 'Weight': {
        this.model[tracking + 'WeightUnit'] = e.value;
        break;
      }
    }
  }

  /**
   * When the concept of "Job" is included, update the Job object with the changes.
   *
   * @returns Observable
   */
  submitJob(): Observable<any> {
    if (this.concepts.includes(Concept.Job)) {
      let model = {
        id: this.jobEvent.job.id,
        numTrucks: this.model.numTrucks,
        rate: this.model.invoiceRate,
        invoiceType: this.model.invoiceType,
        invoiceWeightUnit: this.model.invoiceWeightUnit,
        haulRate: this.model.haulRate,
        haulType: this.model.haulType,
        haulWeightUnit: this.model.haulWeightUnit
      };
      return this.jobService.save(model);
    } else {
      return of({});
    }
  }

  /**
   * Determine which Share ID to use based on the concept and which data to
   * update based on the change type.
   *
   * @returns Observable
   */
  submitJobEventShare(): Observable<any> {
    let model = this.model;
    if (this.jobEvent || this.jobEventShare) {
      if (this.concepts.includes(Concept.JobEventShareId)) {
        model['id'] = this.jobEvent.shareId;
      } else if (this.concepts.includes(Concept.Share)) {
        model['id'] = this.jobEventShare.id;
      } else {
        return of(model);
      }

      return this.collaboratorService.save(null, model);
    } else {
      return of(model);
    }
  }
}
