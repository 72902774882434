"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./item-grid.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("../dropdown/dropdown.component.ngfactory");
var i4 = require("../dropdown/dropdown.component");
var i5 = require("@angular/common");
var i6 = require("@angular/flex-layout/extended");
var i7 = require("@angular/flex-layout/core");
var i8 = require("../../drivers/driver-context-menu/driver-context-menu.component.ngfactory");
var i9 = require("../../drivers/driver-context-menu/driver-context-menu.component");
var i10 = require("../../shifts/shift.service");
var i11 = require("@angular/router");
var i12 = require("../search/search.component.ngfactory");
var i13 = require("../search/search.component");
var i14 = require("./item-grid.component");
var styles_ItemGridComponent = [i0.styles];
var RenderType_ItemGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemGridComponent, data: {} });
exports.RenderType_ItemGridComponent = RenderType_ItemGridComponent;
function View_ItemGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "name-toggle"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDisplayedNames() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Toggle Displayed Names"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "groupby-dropdown"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Group By: "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "dropdown", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.selectGroupBy($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DropdownComponent_0, i3.RenderType_DropdownComponent)), i1.ɵdid(4, 770048, [["groupByDropdown", 4]], 0, i4.DropdownComponent, [i1.ElementRef, i1.ElementRef], { selectedOption: [0, "selectedOption"], options: [1, "options"] }, { onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.activeGroupBy; var currVal_2 = _co.groupByOptions; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ItemGridComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", "/", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.assigned; var currVal_1 = _v.parent.parent.context.$implicit.ordered; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ItemGridComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", "/", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.assignedLoads; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), (_v.parent.context.$implicit.dailyDeliveryTarget || 0), "1.0-0")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ItemGridComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), i1.ɵdid(5, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { "selected": 0 }), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_9)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "item-name ", _co.itemClassNames(_v.context.$implicit), ""); var currVal_1 = _ck(_v, 4, 0, _co.isSelected(_v.context.$implicit.id)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _co.isSelected(_v.context.$implicit.id)); var currVal_3 = i1.ɵinlineInterpolate(1, "item-name ", _co.itemClassNames(_v.context.$implicit), ""); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.dailyDeliveryTarget; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _v.context.$implicit[_co.activeDisplayKey]; _ck(_v, 7, 0, currVal_4); }); }
function View_ItemGridComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_8)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemGridComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", "/", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.assignedLoads; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), (_v.parent.context.$implicit.dailyDeliveryTarget || 0), "1.0-0")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ItemGridComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, [[null, "click"], [null, "contextmenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } if (("contextmenu" === en)) {
        var pd_1 = (_co.openContextMenu($event, _v.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), i1.ɵdid(5, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { "selected": 0 }), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_12)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "item-name ", _co.itemClassNames(_v.context.$implicit), ""); var currVal_1 = _ck(_v, 4, 0, _co.isSelected(_v.context.$implicit.id)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _co.isSelected(_v.context.$implicit.id)); var currVal_3 = i1.ɵinlineInterpolate(1, "item-name ", _co.itemClassNames(_v.context.$implicit), ""); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.dailyDeliveryTarget; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _v.context.$implicit[_co.activeDisplayKey]; _ck(_v, 7, 0, currVal_4); }); }
function View_ItemGridComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_11)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemGridComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_6)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_7)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_10)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.parent.context.$implicit.assigned || _v.parent.context.$implicit.ordered); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.showDriverContextMenu; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.showDriverContextMenu; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.name || i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Ungrouped"))); _ck(_v, 2, 0, currVal_0); }); }
function View_ItemGridComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_5)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.items.length; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_4)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayedItemGroups; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemGridComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" - ", "/", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.assignedLoads; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), (_v.parent.context.$implicit.dailyDeliveryTarget || 0), "1.0-0")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ItemGridComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "selected": 0 }), i1.ɵdid(5, 933888, null, 0, i6.DefaultClassDirective, [i1.ElementRef, i7.StyleUtils, i7.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { "selected": 0 }), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_17)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "item-name ", _co.itemClassNames(_v.context.$implicit), ""); var currVal_1 = _ck(_v, 4, 0, _co.isSelected(_v.context.$implicit.id)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _co.isSelected(_v.context.$implicit.id)); var currVal_3 = i1.ɵinlineInterpolate(1, "item-name ", _co.itemClassNames(_v.context.$implicit), ""); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.dailyDeliveryTarget; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _v.context.$implicit[_co.activeDisplayKey]; _ck(_v, 7, 0, currVal_4); }); }
function View_ItemGridComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "group ungrouped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_16)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.items; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ItemGridComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_15)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.items.length; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemGridComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_14)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayedItemGroups; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemGridComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-driver-context-menu", [], null, null, null, i8.View_DriverContextMenuComponent_0, i8.RenderType_DriverContextMenuComponent)), i1.ɵdid(2, 4440064, null, 0, i9.DriverContextMenuComponent, [i2.TranslateService, i10.ShiftService, i10.ShiftReportService, i11.Router, i1.Renderer2, i1.ElementRef], { contextMenuEventSubject: [0, "contextMenuEventSubject"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextMenuEventSubject; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ItemGridComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "section-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "header"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "search", [], null, [[null, "searchTermChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTermChange" === en)) {
        var pd_0 = (_co.changeSearch($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTermChange" === en)) {
        var pd_1 = ((_co.searchTerm = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_SearchComponent_0, i12.RenderType_SearchComponent)), i1.ɵdid(12, 4440064, null, 0, i13.SearchComponent, [], { searchTerm: [0, "searchTerm"] }, { searchTermChange: "searchTermChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_3)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["ungrouped", 2]], null, 0, null, View_ItemGridComponent_13)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemGridComponent_18)), i1.ɵdid(17, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displayKeys && (_co.displayKeys.length > 1)); _ck(_v, 7, 0, currVal_0); var currVal_1 = (_co.groupByOptions && (_co.groupByOptions.length > 1)); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.searchTerm; _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.groupByOptions && (_co.groupByOptions.length > 1)); var currVal_4 = i1.ɵnov(_v, 15); _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_5 = _co.showDriverContextMenu; _ck(_v, 17, 0, currVal_5); }, null); }
exports.View_ItemGridComponent_0 = View_ItemGridComponent_0;
function View_ItemGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "item-grid", [], null, [["document", "keypress"]], function (_v, en, $event) { var ad = true; if (("document:keypress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ItemGridComponent_0, RenderType_ItemGridComponent)), i1.ɵdid(1, 638976, null, 0, i14.ItemGridComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ItemGridComponent_Host_0 = View_ItemGridComponent_Host_0;
var ItemGridComponentNgFactory = i1.ɵccf("item-grid", i14.ItemGridComponent, View_ItemGridComponent_Host_0, { itemGroups: "itemGroups", itemClassNames: "itemClassNames", displayKeys: "displayKeys", groupByOptions: "groupByOptions", activeGroupBy: "activeGroupBy", searchProps: "searchProps", multiselect: "multiselect", showDriverContextMenu: "showDriverContextMenu", selectedItems: "selectedItems" }, { groupByChange: "groupByChange", onSelect: "onSelect", selectedItemsChange: "selectedItemsChange" }, ["[header-stats]", "[header-dropdowns]"]);
exports.ItemGridComponentNgFactory = ItemGridComponentNgFactory;
