
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../shared/resource.service';
import { JobEventStat } from '../job-event-stats/job-event-stat';
import { CollaboratorStatSerializer } from './collaborator-stat.serializer';

@Injectable()
export class CollaboratorStatService extends ResourceService<JobEventStat> {
  public nextUri;
  public count;

  constructor(http: HttpClient) {
    super(http, 'jobevents/stats-by-collaborators/', new CollaboratorStatSerializer());
  }
}
