"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var ngx_device_detector_1 = require("ngx-device-detector");
var job_event_1 = require("../../job-events/job-event");
var job_event_stat_service_1 = require("../../job-event-stats/job-event-stat.service");
var device_1 = require("../../shared/device");
var shared_1 = require("../../shared");
var JobStatsWidgetComponent = /** @class */ (function () {
    function JobStatsWidgetComponent(jobEventStatService, deviceDetectorService, authenticationService) {
        this.jobEventStatService = jobEventStatService;
        this.deviceDetectorService = deviceDetectorService;
        this.authenticationService = authenticationService;
        this.device = new device_1.Device();
        this.totalHoursField = 'totalHours';
        this.loading = false;
        this.errors = [];
    }
    JobStatsWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.enabledFeatures = this.authenticationService && this.authenticationService.enabledFeatures();
        if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
            var totalHoursPreference = this.authenticationService.getFeature('calculator');
            this.totalHoursField = totalHoursPreference === 'trips' ?
                'totalTripHours' : totalHoursPreference === 'completed_trips' ?
                'totalCompletedTripHours' : 'totalHours';
        }
        this.jobEventStatsTimer = rxjs_1.timer(1, 120000);
        if (this.jobEventStatsTimerSub && typeof this.jobEventStatsTimerSub.unsubscribe === 'function') {
            this.jobEventStatsTimerSub.unsubscribe();
        }
        this.jobEventStatsTimerSub = this.jobEventStatsTimer.subscribe(function (t) {
            _this.getJobEventStats();
        });
    };
    JobStatsWidgetComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
            this.jobEventStatsReq.unsubscribe();
        }
        if (this.jobEventStatsTimerSub && typeof this.jobEventStatsTimerSub.unsubscribe === 'function') {
            this.jobEventStatsTimerSub.unsubscribe();
        }
    };
    JobStatsWidgetComponent.prototype.getJobEventStats = function () {
        var _this = this;
        if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
            this.jobEventStatsReq.unsubscribe();
        }
        if (this.jobEvent) {
            this.loading = true;
            var effectiveRateCalculator = '';
            if (this.enabledFeatures && this.enabledFeatures.includes('effectiveRateCalculator')) {
                effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
            }
            this.jobEventStatsReq = this.jobEventStatService.getStats(this.jobEvent.id, {
                calculator: effectiveRateCalculator
            }).subscribe(function (stats) {
                _this.lastUpdated = new Date().toISOString();
                _this.jobEvent.stats = Object.assign(_this.jobEvent.stats, stats);
            }, function (err) { return console.error(err); }, function () { return _this.loading = false; });
        }
    };
    return JobStatsWidgetComponent;
}());
exports.JobStatsWidgetComponent = JobStatsWidgetComponent;
