import { clone } from 'lodash';
import { Organization } from '../organizations/organization';
import { ProductType } from '../product-types/product-type';
import { Resource } from '../shared/resource';

export class Product extends Resource {
  id: string;
  productType: ProductType;
  createdAt: string;
  lastModified: string;
  name: string;
  unitPrice: number;
  units: string;
  minTotalPrice: number;
  salesTaxRate: number;
  salesTaxRateDecimal: number;
  notes: string;
  ownerOrganization: Organization;
  unitOfMeasurement: string;
  discountPercentage: number;
  number: string;
  status: string;
  selected = false;
  loading: boolean;

  /**
   * @param  {} action
   * @returns string
   */
  url(action: any): string {
    return '/inventory/products/' + this.id + '/' + action;
  }

  /**
   * @param  {any} options
   * @returns any
   */
  filterOptions(options: any): any[] {
    options = clone(options);

    return options;
  }
}
