import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone } from 'lodash';

import { EXPENSE } from './mock-expense';
import { Expense } from './expense';
import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/api.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class ExpenseService {
  baseUrl = environment.serverUrl;
  expense: Expense;
  public nextUri;
  public count = 0;

  constructor(private http: HttpClient) { }

  getMockExpense() { return EXPENSE; }

  getExpense(expenseId: string) {
    let expenseUrl = this.baseUrl + 'expenses/' + expenseId + '/';

    return this.http.get(expenseUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getMockExpenses(count: Number) {
    let expenses = [];

    for (let i = 1; i <= count; i++) {
      let expense = Object.assign({}, EXPENSE);
      expenses.push(expense);
    }
    return expenses;
  }

  getExpenses(query: any = null): Observable<Expense[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '10');
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let expensesUrl = this.baseUrl + 'expenses/';
    return this.http.get(expensesUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  save(expense) {
    expense = clone(expense);
    const expenseUrl = this.baseUrl + 'expenses/';

    if (typeof expense.job === 'object') {
      expense.job = expense.job.id;
    }
    if (typeof expense.customerOrganization === 'object') {
      expense.customerOrganization = expense.customerOrganization.id;
    }
    if (typeof expense.billFromCcEmails === 'string') {
      expense.billFromCcEmails = expense.billFromCcEmails.split(',');
    }
    if (typeof expense.billToCcEmails === 'string') {
      expense.billToCcEmails = expense.billToCcEmails.split(',');
    }

    expense = decamelizeKeysDeep(expense);

    if (!expense.id) {
      return this.http.post(expenseUrl, expense, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError(handleError)
      );
    } else {
      return this.http.put(expenseUrl + expense.id + '/', expense, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError(handleError)
      );
    }
  }

  remove(expense) {
    expense = decamelizeKeysDeep(expense);
    const expenseUrl = this.baseUrl + 'expenses/';

    return this.http.delete(expenseUrl + expense.id + '/', {headers: requestHeaders()});
  }

  unlock(expense) {
    expense = decamelizeKeysDeep(expense);
    const expenseUrl = this.baseUrl + 'expenses/';

    return this.http.put(expenseUrl + expense.id + '/', { sent: false }, { headers: requestHeaders() });
  }

  send(expense) {
    expense = decamelizeKeysDeep(expense);
    const expenseUrl = this.baseUrl + 'expenses/';

    return this.http.post(expenseUrl + expense.id + '/send/', {
      payment_type: expense.payment_type
    }, {headers: requestHeaders()});
  }

  addToExpense(expense, type = 'trips') {
    expense = clone(expense);
    let params, filterParams;

    const expenseUrl = this.baseUrl + 'expenses/' + expense.id + '/add/';

    if (expense.filters && expense.filters.length) {
      filterParams = '?' + expense.filters;
      if (type === 'trips') {
        params = {trips: [], exclude_trips: expense.excludeTrips};
      } else if (type === 'punchcards') {
        params = {punchcards: [], exclude_punchcards: expense.excludePunchCards};
      }
    } else {
      filterParams = '';
      if (type === 'trips') {
        params = {trips: expense.trips || []};
      } else if (type === 'punchcards') {
        params = {punchcards: expense.punchCards || []};
      }
    }

    expense = decamelizeKeysDeep(expense);

    return this.http.post(expenseUrl + filterParams, params, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  removeFromExpense(expense, type = 'trips') {
    expense = decamelizeKeysDeep(clone(expense));
    let params, filterParams;

    const expenseUrl = this.baseUrl + 'expenses/' + expense.id + '/remove/';

    if (expense.filters && expense.filters.length) {
      filterParams = '?' + expense.filters;
      if (type === 'trips') {
        params = { trips: [], exclude_trips: expense.excludeTrips };
      } else if (type === 'punchcards') {
        params = { punchcards: [], exclude_punchcards: expense.excludePunchCards || expense.exclude_punch_cards };
      } else if (type === 'surcharges') {
        params = { surcharges: [], exclude_surcharges: expense.excludeSurcharges || expense.exclude_surcharges };
      }
    } else {
      filterParams = '';
      if (type === 'trips') {
        params = { trips: expense.trips || [] };
      } else if (type === 'punchcards') {
        params = { punchcards: expense.punchCards || expense.punchcards || [] };
      } else if (type === 'surcharges') {
        params = { surcharges: expense.surcharges || [] };
      }
    }

    return this.http.post(expenseUrl + filterParams, params, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  export(scope, params: any = null) {
    const expenseUrl = this.baseUrl + 'expenses/export/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(expenseUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  downloadPdfs(scope, params: any = null) {
    const expenseUrl = this.baseUrl + 'invoices/download_pdfs/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(expenseUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  getNext(): Observable<Expense[]> {
     if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()}).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];
    if (body) {
      return body.map(expense => {
        return new Expense(expense);
      });
    } else if (resObj) {
      return new Expense(resObj);
    }
  }
}
