<div class="app-content">
  <my-toolbar title="{{ 'Scale' | translate }}"></my-toolbar>
  <div class="container">
    <notification class="notification" [level]="notificationLevel" [ngClass]="{hidden: notificationHidden}">{{notificationMessage}}</notification>

    <div class="left-pane">
      <button (click)="toggle('tickets')" [ngClass]="{active: !showTicketsPane}" class="accordion">
        {{ 'Incomplete Tickets' | translate }} ({{incompleteTicketsComponent.tickets.length}})
      </button>
      <div class="panel" [ngClass]="{active: !showTicketsPane, hidePanel: showTicketsPane}">
        <incomplete-tickets (onTicketSelected)="onTicketSelected($event)"></incomplete-tickets>
      </div>

      <button (click)="toggle('scales')" [ngClass]="{active: !showScalesPane}" class="accordion">
        {{ 'Scales' | translate }} ({{scalesComponent.scales.length}})
      </button>
      <div class="panel" [ngClass]="{active: !showScalesPane, hidePanel: showScalesPane}">
        <scales></scales>
      </div>
    </div>

    <div class="right-pane">
      <div class="search-ticket-container">
        <div class="container-header">
          <form class="search-licence-plate">
            <div class="licence-plate">
              <input autofocus licensePlateNumber class="default" [ngClass]="{error: searchStatusNotFound}"
                name="licencePlate" id="licence-plate-search" [(ngModel)]="licensePlateNumber" #licencePlate="ngModel"
                (keyup.enter)="getTrucks()" autocomplete="off"
                [readonly]="ticketForm.currentTicket && ticketForm.currentTicket.id"
                placeholder="{{ 'Search for truck' | translate }}"
                [disabled]="selectedTruck || incompleteTicketsComponent.selectedTicket">
              <truck-dropdown [options]="truckOptions" [selectedOption]="selectedTruck" [config]="truckDropdownConfig"
                (onSelect)="selectTruck($event)"></truck-dropdown>
              <div class="btn-go" [ngClass]="{hidden: !searchStatusToSearch || !hasNoValue(licensePlateNumber)}" (click)="getTrucks()">
                <i class="icon-search"></i>
              </div>
              <div class="loading" [ngClass]="{hidden: !searchStatusSearching}"></div>
              <div class="message" [ngClass]="{hidden: !searchStatusNotFound}" translate>No Trucks Found</div>
            </div>
          </form>
          <button [ngClass]="{hidden: !ticketForm.currentTicket || !ticketForm.currentTicket.id}" class="btn btn-primary-inverted btn-new-ticket" (click)="newTicket()">
            <span class="icon-plus"></span>
            {{ 'New Ticket' | translate }}
          </button>
        </div>
      </div>
      <ticket-form (onNotification)="handleTicketFormNotification($event)"
                   (onTicketVoided)="handleTicketVoided()"
                   (onTicketSaved)="highlightTicket($event)"
                   (onTicketFinished)="highlightTicket($event)"></ticket-form>
    </div>
  </div>
</div>
