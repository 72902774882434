import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TRIPSIGNATUREIMAGETYPE } from '../app.constants';
import { AttachmentService } from '../attachments/attachment.service';
import { forkJoin } from 'rxjs';
import { parseErrors } from '../shared';

@Component({
  selector: 'view-signatures-dialog',
  templateUrl: './view-signatures-dialog.component.html',
  styleUrls: ['./view-signatures-dialog.component.scss'],
})
export class ViewSignaturesDialogComponent implements OnInit {
  loading = false;
  errors = [];
  signatureImageType = TRIPSIGNATUREIMAGETYPE;
  loadingSignatures = [];
  unloadingSignatures = [];
  imagesToDelete = [];

  constructor(
    public dialogRef: MatDialogRef<ViewSignaturesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tripAttachmentService: AttachmentService
  ) {}

  ngOnInit() {
    if (this.data && this.data.trip) {
      this.loadingSignatures = this.data.trip.loadingCheckin.attachments
        .filter((img) => img.fileType === this.signatureImageType)
        .map((a) => ({
          id: a.id,
          src: a.file,
        }));
      this.unloadingSignatures = this.data.trip.unloadingCheckin.attachments
        .filter((img) => img.fileType === this.signatureImageType)
        .map((a) => ({
          id: a.id,
          src: a.file,
        }));
    }
  }

  fileChange(checkinType: string, img: any) {
    let images =
      checkinType === 'loading'
        ? [...this.loadingSignatures]
        : [...this.unloadingSignatures];

    if (img && img.id) {
      // if existing image
      if (img.isDeleted) {
        this.imagesToDelete.push(img);
      } else {
        images = images.map((i) =>
          i.id === img.id
            ? Object.assign(img, {
                tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
              })
            : i
        );
      }
    } else {
      // if new image
      if (img.isNew && !img.isEdited) {
        images.push(
          Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        );
      } else {
        images = images.map((i) =>
          i.id === img.id
            ? Object.assign(img, {
                tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
              })
            : i
        );
      }
    }

    if (checkinType === 'loading') {
      this.loadingSignatures = images;
    } else {
      this.unloadingSignatures = images;
    }
  }

  parseImageOnChange(image: any) {
    if (image && (image.isNew || image.isEdited)) {
      let reader = new FileReader();
      reader.onload = () => {
        image.file = reader.result;
        image.src = reader.result;
      };
      reader.readAsDataURL(image);
      return image;
    }
    return image;
  }

  onSave() {
    this.errors = null;
    this.loading = true;
    const requests$ =
      this.tripAttachmentService.combineTicketImagesAndAttachments(
        this.imagesToDelete,
        [],
        this.loadingSignatures,
        [],
        this.unloadingSignatures,
        this.data.trip.loadingCheckin.id,
        this.data.trip.unloadingCheckin.id
      );
    forkJoin(requests$).subscribe(
      (res) => {
        this.dialogRef.close();
        this.data.callback();
        this.loading = false;
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }
}
