<div class="app-content condensed-trips-container">
  <my-toolbar title="{{ 'Trips' | translate }}" (updatedMarkets)="updateMarkets()"></my-toolbar>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/trips']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" queryParamsHandling="merge" translate>Trips</a>
        <a [routerLink]="['/geotrips']" routerLinkActive="active" translate>GeoTrips</a>
        <a [routerLink]="['/punchcards']" routerLinkActive="active" translate>Punch Cards</a>
        <a [routerLink]="['/shifts']" routerLinkActive="active" translate>Shifts</a>
        <a [routerLink]="['/driver-reports']" routerLinkActive="active" translate>Driver Job Reports</a>
        <a [routerLink]="['/end-of-day-reports']" routerLinkActive="active" translate>End of Day Reports</a>
      </div>
    </div>
  </action-bar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table #tripsTable [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns"
        [(filters)]="appliedFilters" [(search)]="search"
        (columnChange)="columnsChanged($event)" (onMenuAction)="menuAction($event)"
        (selectionChanged)="selector($event)" [config]="tableConfig">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'job-date'">
              {{ (row.jobDate | date:'mediumDate') || '&nbsp;' }}
              <div class="trip-status">
                <div class="label-{{row.administrativeStatus}}" *ngIf="row.displayableStatus">{{row.administrativeStatus}}</div>
                <div class="spacer" *ngIf="!row.displayableStatus">&nbsp;</div>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'job'">
              <div class="value">{{ row.jobDisplayName || '&nbsp;' }}</div>
              <div class="label">{{ row.project || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'job-number'">
              <div class="value">{{ row.jobNumber || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'load-ticket-number'">
              {{ row.loadingTicketNumber || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'load-ticket-weight'">
              {{ row.loadingWeight || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'load-ticket-image'">
              <a *ngIf="row" [routerLink]="row.url('edit')" [queryParams]="{ returnTo: '/trips' }">
                <ng-container *ngIf="!row.hasLoadingTicketImages && row.loadingTicketImagesRequired; else loadingTicketIcon">
                  <div class="ticket-photo">
                    <div class="value" class="missing-required">&nbsp;</div>
                    <div class="label" translate>No Ticket</div>
                  </div>
                </ng-container>
                <ng-template #loadingTicketIcon>
                  <div class="ticket-photo" *ngIf="row.hasLoadingTicketImages">
                    <span><i class="icon-ticket"></i></span>
                  </div>
                </ng-template>
              </a>
            </div>
            <div class="value" *ngSwitchCase="'load-signature-image'">
              <ng-container *ngIf="row.loadingAttachments | attachmentType: signatureImageType as images">
                <div class="signature-photo" *ngIf="row.loadingSignatureImagesRequired && !images.length">
                  <div class="value" class="missing-required">&nbsp;</div>
                  <div class="label" translate>No Signature</div>
                </div>
                <div class="ticket-photo" *ngIf="images.length">
                  <img [src]="images[0].file" />
                </div>
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'unload-ticket-number'">
              {{ row.unloadingTicketNumber || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'unload-ticket-weight'">
              {{ row.unloadingWeight || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'unload-ticket-image'">
              <a *ngIf="row" [routerLink]="row.url('edit')" [queryParams]="{ returnTo: '/trips' }">
                <ng-container *ngIf="!row.hasUnloadingTicketImages && row.unloadingTicketImagesRequired; else unloadingTicketIcon">
                  <div class="ticket-photo">
                    <div class="value" class="missing-required">&nbsp;</div>
                    <div class="label" translate>No Ticket</div>
                  </div>
                </ng-container>
                <ng-template #unloadingTicketIcon>
                  <div class="ticket-photo" *ngIf="row.hasUnloadingTicketImages">
                    <span><i class="icon-ticket"></i></span>
                  </div>
                </ng-template>
              </a>
            </div>
            <div class="value" *ngSwitchCase="'unload-signature-image'">
              <ng-container *ngIf="row.unloadingAttachments | attachmentType: signatureImageType as images">
                <div class="signature-photo" *ngIf="row.unloadingSignatureImagesRequired && !images.length">
                  <div class="value" class="missing-required">&nbsp;</div>
                  <div class="label" translate>No Signature</div>
                </div>
                <div class="ticket-photo" *ngIf="images.length">
                  <img [src]="images[0].file" />
                </div>
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'carrier'">
              {{ row.carrier || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'driver'">
              <span (contextmenu)="openContextMenu($event, row.driverId)">{{ row.driver || '&nbsp;' }}</span>
              <div class="label">
                <a class="replay-link" target="_blank" href="/replay?date={{ row.jobDate | moment: 'YYYYMMDD' }}&driver={{ row.driverId }}">View Replay</a>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'truck'">
              {{ row.truckName || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'time'">
              {{ row.schedule || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'duration'">
              {{ row.formattedCompletedTripTime || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'payable-to'">
              {{ row.payableTo || '&nbsp;' }}
              <div class="label">{{ (row.payableToPhone | telephone) || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'customer'">
              {{ row.customer || '&nbsp;' }}
              <div class="label">{{ (row.customerPhone | telephone) || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'invoice'">
              {{ (row.invoiceRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}
              <div class="label">{{ row.invoiceTypeName || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'invoice-total'">
              {{ (row.invoiceTotal || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}
              <div class="label">&nbsp;</div>
            </div>
            <div class="value" *ngSwitchCase="'haul'">
              {{ (row.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}
              <div class="label">{{ row.haulTypeName || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'haul-total'">
              {{ (row.haulTotal || 0 | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}
              <div class="label">&nbsp;</div>
            </div>
            <div class="value" *ngSwitchCase="'invoice-number'">
              {{ row.invoiceTitle || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'expense-title'">
              {{ row.expenseTitle || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'paystub-title'">
              {{ row.paystubTitle || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'billing-id'">
              {{ row.billingId || '&nbsp;' }}
            </div>
            <div class="value {{ row.reviewedStatus }}" *ngSwitchCase="'reviewed-status'">
              {{ (row.reviewedStatus | titlecase) || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'reviewed-by'">
              {{ row.reviewedBy && (row.reviewedBy.firstName + ' ' + row.reviewedBy.lastName) || '&nbsp;' }}
              <div class="label">{{ (row.reviewedAt | date:'mediumDate') || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'plant-qc'">
              {{ row.plantQc || 'N/A' }}
              <div class="label">{{ row.plantQcReviewer && 'By ' + row.plantQcReviewer.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'plant-vt'">
              {{ row.plantVt || 'N/A' }}
              <div class="label">{{ row.plantVtReviewer && 'By ' + row.plantVtReviewer.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'roadway-qc'">
              {{ row.roadwayQc || 'N/A' }}
              <div class="label">{{ row.roadwayQcReviewer && 'By ' + row.roadwayQcReviewer.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'roadway-vt'">
              {{ row.roadwayVt || 'N/A' }}
              <div class="label">{{ trirowp.roadwayVtReviewer && 'By ' + row.roadwayVtReviewer.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'connex-paver-start-unloading'">
              <ng-container *ngIf="row.connexPaverStartUnloading !== null">
                {{ row.connexPaverStartUnloading | date:'short' || '&nbsp;' }}
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'connex-paver-end-unloading'">
              <ng-container *ngIf="row.connexPaverEndUnloading !== null">
                {{ row.connexPaverEndUnloading | date:'short' || '&nbsp;' }}
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'loading-location'">
              <ng-container *ngIf="row.startLocationName !== null">
                {{ row.startLocationName || '&nbsp;' }}
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'geo-loading-location'">
              <ng-container *ngIf="row.geoStartLocationName !== null">
                {{ row.geoStartLocationName || '&nbsp;' }}
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'unloading-location'">
              <ng-container *ngIf="row.endLocationName !== null">
                {{ row.endLocationName || '&nbsp;' }}
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'geo-unloading-location'">
              <ng-container *ngIf="row.geoEndLocationName !== null">
                {{ row.geoEndLocationName || '&nbsp;' }}
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'phase-code'">
              {{ row.phaseCode || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchDefault>{{ value || '&nbsp;' }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Trips</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns">
        </column-toggle>

        <div class="duplicates" [ngClass]="{active: checkingDuplicates}">
          <button class="btn btn-default duplicate-check" (click)="duplicateCheck()"
            [ngClass]="{active: checkingDuplicates}" translate>
            Duplicate Check
          </button>
        </div>

        <filters-panel 
          [(appliedFilters)]="appliedFilters"
          [customFilter]="true"
          [search]="search"
          (openCustomFilter)="openFilters()"
          (searchChange)="search = $event">
        </filters-panel>

        <button [routerLink]="['/trips/new']" [queryParams]="{returnTo: '/trips'}" class="btn btn-primary new-trip">
          <span class="icon-plus"></span> {{ 'Add Trip' | translate }}
        </button>

        <action-menu icon="icon-more">
          <ng-container *ngFor="let option of multipleActionDropdownOptions">
            <a *ngIf="option.link" mat-menu-item [routerLink]="option.url">{{ option.name }}</a>
            <a *ngIf="!option.link" mat-menu-item (click)="setSelectedBulkAction(option)">{{ option.name }}</a>
          </ng-container>
        </action-menu>
      </div>
    </div>
  </div>
</div>

<!-- driver context menu -->
<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject">
</app-driver-context-menu>