"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var angular2_uuid_1 = require("angular2-uuid");
var operators_1 = require("rxjs/operators");
var app_constants_1 = require("../../app/app.constants");
var environment_1 = require("../../environments/environment");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var camelizeKeysDeep = require('camelcase-keys-deep');
var AttachmentService = /** @class */ (function () {
    function AttachmentService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    AttachmentService.prototype.getHeaders = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return {
            Authorization: "Token " + currentUser.token,
        };
    };
    AttachmentService.prototype.updateTicketImage = function (data, file) {
        var postData = decamelizeKeysDeep(data);
        var formData = new FormData();
        formData.append('json', JSON.stringify(postData));
        formData.append('checkin_attachment_file', file, file.name);
        return this.http
            .put(this.baseUrl + "attachments/", formData, {
            headers: this.getHeaders(),
        })
            .pipe(operators_1.map(function (res) { return camelizeKeysDeep(res); }));
    };
    AttachmentService.prototype.createAttachment = function (attachment, checkinId) {
        var attachmentData = {
            file_extension: attachment.type.split('/')[1],
            file_format: 'image',
            file_type: app_constants_1.TRIPTICKETIMAGETYPE,
            name: attachment.name,
            file: attachment.file,
            checkin: checkinId,
        };
        return this.http.post(this.baseUrl + "attachments/", attachmentData, {
            headers: this.getHeaders(),
        });
    };
    AttachmentService.prototype.createMultipleAttachments = function (attachments) {
        return this.http.post(this.baseUrl + "attachments/", attachments, {
            headers: this.getHeaders(),
        });
    };
    AttachmentService.prototype.updateAttachment = function (attachment) {
        return this.http.patch(this.baseUrl + "attachments/" + attachment.id + "/", attachment, {
            headers: this.getHeaders(),
        });
    };
    AttachmentService.prototype.deleteAttachment = function (attachmentId) {
        return this.http.delete(this.baseUrl + "attachments/" + attachmentId + "/", {
            headers: this.getHeaders(),
        });
    };
    AttachmentService.prototype.parseAttachment = function (attachment, file_type, checkin) {
        var file_extension = attachment && attachment.type ? attachment.type.split('/')[1] : 'jpg'; // changing image/png to png; if no type, default to jpg
        return __assign({}, (attachment.id && { id: attachment.id }), { file_extension: file_extension, file_format: 'image', file_type: file_type, name: angular2_uuid_1.UUID.UUID() + "." + file_extension, file: attachment.file }, (checkin && { checkin: checkin }));
    };
    // should be refactored soon
    AttachmentService.prototype.combineTicketImagesAndAttachments = function (imagesToDelete, loadingTicketImages, loadingSignatures, unloadingTicketImages, unloadingSignatures, loadingCheckinId, unloadingCheckinId) {
        var _this = this;
        var deleteImagesApiCalls = [];
        var imagesToUpdateApiCalls = [];
        var imagesToCreate = [];
        imagesToDelete.forEach(function (img) {
            if (img.id) {
                deleteImagesApiCalls.push(_this.deleteAttachment(img.id));
            }
        });
        loadingTicketImages.forEach(function (img) {
            if (img.isDeleted)
                return;
            if (img.legacy) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPTICKETIMAGETYPE, loadingCheckinId));
                return;
            }
            if (img.isNew) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPTICKETIMAGETYPE, loadingCheckinId));
                return;
            }
            if (img.isEdited && img.isNew !== true) {
                imagesToUpdateApiCalls.push(_this.updateAttachment(_this.parseAttachment(img, app_constants_1.TRIPTICKETIMAGETYPE, loadingCheckinId)));
                return;
            }
        });
        loadingSignatures.forEach(function (img) {
            if (img.isDeleted)
                return;
            if (img.legacy) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPSIGNATUREIMAGETYPE, loadingCheckinId));
                return;
            }
            if (img.isNew) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPSIGNATUREIMAGETYPE, loadingCheckinId));
                return;
            }
            if (img.isEdited && img.isNew !== true) {
                imagesToUpdateApiCalls.push(_this.updateAttachment(_this.parseAttachment(img, app_constants_1.TRIPSIGNATUREIMAGETYPE, loadingCheckinId)));
                return;
            }
        });
        unloadingTicketImages.forEach(function (img) {
            if (img.isDeleted)
                return;
            if (img.legacy) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPTICKETIMAGETYPE, unloadingCheckinId));
                return;
            }
            if (img.isNew) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPTICKETIMAGETYPE, unloadingCheckinId));
                return;
            }
            if (img.isEdited && img.isNew !== true) {
                imagesToUpdateApiCalls.push(_this.updateAttachment(_this.parseAttachment(img, app_constants_1.TRIPTICKETIMAGETYPE, unloadingCheckinId)));
                return;
            }
        });
        unloadingSignatures.forEach(function (img) {
            if (img.isDeleted)
                return;
            if (img.legacy) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPSIGNATUREIMAGETYPE, unloadingCheckinId));
                return;
            }
            if (img.isNew) {
                imagesToCreate.push(_this.parseAttachment(img, app_constants_1.TRIPSIGNATUREIMAGETYPE, unloadingCheckinId));
                return;
            }
            if (img.isEdited && img.isNew !== true) {
                imagesToUpdateApiCalls.push(_this.updateAttachment(_this.parseAttachment(img, app_constants_1.TRIPSIGNATUREIMAGETYPE, unloadingCheckinId)));
                return;
            }
        });
        return deleteImagesApiCalls.concat(imagesToUpdateApiCalls, (imagesToCreate.length
            ? [this.createMultipleAttachments(imagesToCreate)]
            : []));
    };
    AttachmentService.prototype.saveOrganizationAttachment = function (id, attachment, file_type) {
        var payload = {
            // file_extension: attachment.type.split('/')[1],
            file_extension: attachment.name.split('.').pop(),
            file_format: this.getFileFormatFromFilename(attachment.name),
            file_type: file_type,
            name: attachment.name,
            file: attachment.file,
            organization: id,
        };
        return attachment.id
            ? this.http.put(this.baseUrl + "attachments/" + attachment.id + "/", payload, {
                headers: this.getHeaders(),
            })
            : this.http.post(this.baseUrl + "attachments/", payload, {
                headers: this.getHeaders(),
            });
    };
    AttachmentService.prototype.getFileFormatFromFilename = function (filename) {
        var fileExtension = filename.split('.').pop();
        if (fileExtension === 'pdf') {
            return 'pdf';
        }
        if (['png', 'jpg', 'jpeg', 'img'].includes(fileExtension)) {
            return 'image';
        }
        if (['doc', '.docx'].includes(fileExtension)) {
            return 'document';
        }
        return 'image';
    };
    AttachmentService.prototype.getFirstAttachmentIdFromAttachments = function (attachments, fileType) {
        var attachment = attachments.find(function (a) { return a.fileType === fileType; });
        return attachment ? attachment.id : null;
    };
    return AttachmentService;
}());
exports.AttachmentService = AttachmentService;
// paylod example
// {
//   "file":"base64",
//   "file_type":"", #usually a ticket_image or a signature_image
//   "file_format":"", #pdf/imasge
//   "file_extension":"", #pdf/png/jpg/jpeg/img/gif
//   "extra_data":"", {} (youu can omit this one if you do not want to add it in, that's just for some random stuff)
//   "checkin": uuid, #not needed in patch
//   "ticket": uuid, #not needed in patch
//   }
