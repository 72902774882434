import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

declare let google: any;

@Component({
  selector: 'project-filters-dialog',
  templateUrl: './project-filters-dialog.component.html',
  styleUrls: ['./project-filters-dialog.component.scss'],
  providers: []
})
export class ProjectFiltersDialogComponent implements OnInit {
  loading = false;
  errors = [];
  locations = [];
  locationsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  projects = [];
  projectsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  customers = [];
  customersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  callback;
  model = {};
  dropdownSearch;
  dropdownNextPage;


  constructor(
    public dialogRef: MatDialogRef<ProjectFiltersDialogComponent>,
  ) { }

  ngOnInit() {
  }

  onSelect(filterType, e) {
    this.model[filterType] = e;
  }

  submit() {
    this.callback(this.model);
    this.dialogRef.close();
  }
}
