import { Component, ViewChild, Output, EventEmitter, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MatPaginator, PageEvent, MatCheckboxChange } from '@angular/material';
import { Router } from '@angular/router';
import { timer as observableTimer, Subscription, forkJoin, Subject } from 'rxjs';
import { intersectionWith, findIndex, uniqBy, find } from 'lodash';
import * as moment from 'moment';

import { DispatchDriver, DispatchJobEvent } from '../dispatch-schedule.component';
import { AuthenticationService, DropdownComponent } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { DispatchService } from '../../dispatch.service';
import { PreferenceService } from '../../../preferences/preference.service';
import { Preference } from '../../../preferences/preference';
import { AssignmentService } from '../../../assignments/assignment.service';
import { Assignment } from '../../../assignments/assignment';
import { JobService } from '../../../jobs/job.service';
import { JobEventService } from '../../../job-events/job-event.service';
import { Carrier } from '../../../carriers/carrier';
import { TagService } from '../../../tags/tag.service';
import { Tag } from '../../../tags/tag';
import { DriverService } from '../../../drivers/driver.service';
import { TruckService } from '../../../trucks/truck.service';
import { Truck } from '../../../trucks/truck';
import { TruckTypeService } from '../../../trucks/truck-type.service';
import { TruckType } from '../../../trucks/truck-type';

import { RuckitConfirmDialogComponent } from '../../../shared/dialogs';
import { MoveAssignmentsDialogComponent } from '../../../assignments/move-assignments/move-assignments-dialog.component';
import { AutoAssignConflictDialogComponent } from '../../../assignments/auto-assign-conflict-dialog.component';
import { EditJobEventDialogComponent } from '../../../job-events/edit-job-event-dialog.component';
import { CancelJobDialogComponent } from '../../../jobs/cancel-job-dialog.component';
import { AddCollaboratorsDialogComponent } from '../../../collaborators/add-collaborators/add-collaborators-dialog.component';
import { EditCollaboratorsDialogComponent } from '../../../collaborators/edit-collaborators/edit-collaborators-dialog.component';
import { SendCollaboratorNotesDialogComponent } from '../../../collaborators/send-collaborator-notes/send-collaborator-notes-dialog.component';
import { CustomFieldService } from '../../../custom-fields/custom-field.service';
import { CustomFieldKind } from '../../../custom-fields/custom-field';
import { DriverContextEvent } from '../../../drivers/driver-context-menu/interfaces/driver-context-event';
import { FilterOption } from '../../../shared/filters-panel/filter-option';
import { CondensedJobEvent } from '../../../job-events/condensed-job-event';
import { EditJobDialogComponent } from '../../../jobs/edit-job-dialog.component';

const camelcaseKeysDeep = require('camelcase-keys-deep');

@Component({
  selector: 'dispatch-schedule-table',
  templateUrl: './dispatch-schedule-table.component.html',
  styleUrls: ['../dispatch-schedule.component.scss']
})
export class DispatchScheduleTableComponent implements OnDestroy {
  @Output() errors: EventEmitter<any[]> = new EventEmitter();
  @Input() view: 'jobEvents' | 'drivers' | 'collaborators';
  @Input() jobEventLoadingProgress = 0;
  @Input() driverLoadingProgress = 0;
  @Input() assignmentLoadingProgress = 0;
  @Input() shareLoadingProgress = 0;
  brokerRateCodeKey: string;
  confirmDialog: MatDialogRef<any>;

  loadTypeOptions = [];
  @Input() activeLoadType: 'Loads Ordered' | 'Tons Ordered' | 'Trucks Requested' | 'Truck Type Requested';
  orderTypeOptions = [
    { id: 'tons', name: 'Tons' },
    { id: 'tonnes', name: 'Tonnes' },
    { id: 'metric-tons', name: 'Metric Tons' },
    { id: 'loads', name: 'Loads' },
    { id: 'lbs', name: 'Pounds' },
    { id: 'kgs', name: 'Kilograms' },
    { id: 'cuyds', name: 'Cubic Yards' },
    { id: 'bushels', name: 'Bushels' },
    { id: 'bags', name: 'Bags' }
  ];
  truckTypes: TruckType[] = [];
  truckTypesReq: Subscription;
  truckTypesConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false,
    multiselect: true,
    deselectOption: 'Any Type'
  };
  truckTypesUrlList: string;
  tags: Tag[] = [];
  tagsReq: Subscription;
  tagsConfig = {
    nameProperty: 'name',
    loadingOptions: false,
    multiselect: true,
    deselectOption: 'All Markets'
  };
  tagsUrlList: string;
  jobEventAppliedFilters = [];
  @ViewChild('tagsDropdown', { static: false }) tagsDropdown: DropdownComponent;
  @ViewChild('jobEventTagsDropdown', { static: false }) jobEventTagsDropdown: DropdownComponent;
  @ViewChild('jobEventTruckTypeDropdown', { static: false }) jobEventTruckTypeDropdown: DropdownComponent;
  @Input() carrierDropdownData: {
    carrier?: Carrier,
    carriers: Carrier[],
    config: any,
    loading: boolean
  };
  @ViewChild('carriersDropdown', { static: false }) carriersDropdown: DropdownComponent;
  @Output() selectCarrier: EventEmitter<Carrier> = new EventEmitter();
  @Output() searchCarriers: EventEmitter<{search: string}> = new EventEmitter();
  @Output() dropdownNextPage: EventEmitter<any> = new EventEmitter();
  @Output() updatePreference: EventEmitter<any> = new EventEmitter();
  trucksDropdownConfig = {
    small: true,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'ticketName',
    service: TruckService,
    query: { ordering: 'name' }
  };

  preference: Preference;
  initPrefs = false;
  jobEventsPreferenceKey = 'table-preferences[DispatchScheduleTableComponent-JobEvents]';
  driversPreferenceKey = 'table-preferences[DispatchScheduleTableComponent-Drivers]';
  jobEventsTimerSub;
  jobEventsTimer;
  jobEventUpdates = false;

  availableJobEventsColumns = [
    { key: 'select' },
    { key: 'updates' },
    { key: 'job', title: this.translateService.instant('Job'), sortable: true, sortBy: 'name' },
    { key: 'status', title: this.translateService.instant('Status'), sortable: true, sortBy: 'status' },
    { key: 'customer-name', title: this.translateService.instant('Customer'), sortable: true, sortBy: 'customer__name' },
    { key: 'order-number', title: this.translateService.instant('Order #'), sortable: true, sortBy: 'order_number_sort' },
    { key: 'job-number', title: this.translateService.instant('Job #'), sortable: true, sortBy: 'job_number' },
    { key: 'start-time', title: this.translateService.instant('Start Time'), sortable: true, sortBy: 'shift_1_Start' },
    { key: 'end-time', title: this.translateService.instant('End Time'), sortable: true, sortBy: 'shift_1_End' },
    { key: 'interval', title: this.translateService.instant('Stagger (Minutes)'), sortable: false },
    { key: 'start-location', title: this.translateService.instant('Loading Location'), sortable: true, sortBy: 'start_location_name' },
    { key: 'end-location', title: this.translateService.instant('Unloading Location'), sortable: false, sortBy: 'end_location_name' },
    { key: 'material', title: this.translateService.instant('Material'), sortable: true, sortBy: 'material' },
    { key: 'truck-type', title: this.translateService.instant('Truck Type'), sortable: true, sortBy: 'truck_types' },
    { key: 'amount-ordered', title: this.translateService.instant('Ordered Amount'), sortable: true, sortBy: 'daily_delivery_target' },
    { key: 'total-ordered', title: this.translateService.instant('Total Ordered'), sortable: true, sortBy: 'job_amount_needed' },
    { key: 'trucks-requested', title: this.translateService.instant('Trucks Requested'), sortable: true, sortBy: 'num_trucks' },
    { key: 'trucks-remaining', title: this.translateService.instant('Trucks Remaining'), sortable: false },
    { key: 'truck-tons', title: this.translateService.instant('Truck Tons'), sortable: false },
    { key: 'loads-assigned', title: this.translateService.instant('Assigned Loads'), sortable: true, sortBy: 'assigned_loads' },
    { key: 'loads-remaining', title: this.translateService.instant('Remaining Loads'), sortable: false },
    { key: 'tons-assigned', title: this.translateService.instant('Assigned Tons'), sortable: false },
    { key: 'tons-remaining', title: this.translateService.instant('Remaining Tons'), sortable: false },
    { key: 'job-notes', title: this.translateService.instant('Job Notes'), sortable: false },
    { key: 'share-notes', title: this.translateService.instant('Share Notes'), sortable: false },
    { key: 'internal-notes', title: this.translateService.instant('Internal Notes'), sortable: false },
    { key: 'assigned-trucks', title: this.translateService.instant('Assigned Trucks'), sortable: false },
    { key: 'actions', title: this.translateService.instant('Actions'), sortable: false }
  ];
  displayedJobEventColumns = [];
  loadsOrderedDefaultColumns = [
    'select', 'updates', 'job', 'status', 'customer-name' , 'order-number', 'job-number',
    'start-time', 'end-time', 'interval', 'start-location',
    'end-location', 'material', 'truck-type', 'amount-ordered', 'total-ordered',
    'trucks-requested', 'trucks-remaining', 'loads-assigned','internal-notes',
    'loads-remaining', 'job-notes', 'share-notes', 'assigned-trucks',
    'actions'
  ];
  tonsOrderedDefaultColumns = [
    'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
    'start-time', 'end-time', 'interval', 'start-location',
    'end-location', 'material', 'truck-type', 'amount-ordered', 'total-ordered',
    'trucks-requested', 'trucks-remaining', 'truck-tons',
    'loads-assigned', 'internal-notes', 'tons-assigned', 'tons-remaining',
    'job-notes', 'share-notes',  'assigned-trucks', 'actions'
  ];
  trucksRequestedDefaultColumns = [
    'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
    'start-time', 'end-time', 'interval', 'start-location',
    'end-location', 'material', 'truck-type', 'amount-ordered', 'total-ordered',
    'trucks-requested', 'trucks-remaining', 'internal-notes', 'job-notes', 'share-notes', 
    'assigned-trucks', 'actions'
  ];
  truckTypeRequestedDefaultColumns = [
    'select', 'updates', 'job', 'status', 'customer-name', 'order-number', 'job-number',
    'start-time', 'end-time', 'interval', 'start-location',
    'end-location', 'material', 'truck-type', 'amount-ordered','internal-notes', 'total-ordered',
    'trucks-requested', 'trucks-remaining', 'job-notes', 'share-notes', 
    'assigned-trucks', 'actions'
  ];
  availableDriverColumns = [
    { key: 'select' },
    { key: 'driver', title: this.translateService.instant('Driver'), sortable: true, sortBy: 'name' },
    { key: 'jobs', title: this.translateService.instant('Jobs'), sortable: false },
    { key: 'assigned-loads', title: this.translateService.instant('Assigned Loads'), sortable: true, sortBy: 'assignments__length' },
    { key: 'actions', title: this.translateService.instant('Actions'), sortable: false }
  ];
  displayedDriverColumns = [];
  driverDefaultColumns = ['select', 'driver', 'jobs', 'assigned-loads', 'actions'];
  assignmentOptions = [
    'Edit Job',
    'Clone Job',
    'Edit Job Days',
    'Edit Day Details',
    'Auto-Assign Previous Trucks & Drivers',
    'Move to a New Day',
    'Copy to a New Day',
    'Remove Assignments',
    'Add Collaborators',
    'Cancel Job'
  ];
  shareOptions = [
    'Edit Collaborators',
    'Send Notes to Collaborators',
    'Edit Day Details',
    'Cancel Job'
  ];
  driverAssignmentOptions = [
    'Remove Assignments'
  ];
  multipleActionDropdownOptions = [
    { name: 'Cancel Jobs', action: 'cancel', link: false }
  ];

  activeStatsType = 'loads';
  @Input() loadStats;
  @Input() truckStats;
  @Input() tonStats;
  @Input() truckTypeStats;
  @Output() driverQuery: EventEmitter<{
    carrier?: string, all_carriers?: boolean, all_leased?: boolean, tags?: string
  }> = new EventEmitter();
  @Output() updateStats: EventEmitter<DispatchJobEvent[]> = new EventEmitter();

  @ViewChild('driverPaginator', { static: false }) driverPaginator: MatPaginator;
  driverPageSize = 100;

  @ViewChild('jobEventPaginator', { static: false }) jobEventPaginator: MatPaginator;
  jobEventPageSize = 100;

  @Output() openDriverPanel: EventEmitter<DispatchDriver> = new EventEmitter();
  @Output() openJobEventPanel: EventEmitter<DispatchJobEvent> = new EventEmitter();
  @Output() getJobEvents: EventEmitter<{ truck_types_in?: string, last_modified__gt?: string }> = new EventEmitter();
  @Output() getAssignments: EventEmitter<DispatchDriver[]> = new EventEmitter();
  @Output() getShares: EventEmitter<DispatchJobEvent[]> = new EventEmitter();
  @Output() assignSelectedDrivers: EventEmitter<DispatchJobEvent> = new EventEmitter();
  @Output() assignSelectedJobEvents: EventEmitter<DispatchDriver> = new EventEmitter();
  @Output() assignSelectedConnections: EventEmitter<DispatchJobEvent> = new EventEmitter();

  @Input() selectedDate: Date;

  @Input() drivers: DispatchDriver[] = [];
  displayedDrivers: DispatchDriver[] = [];
  driversDataSource: MatTableDataSource<DispatchDriver>;
  driverSearch: string;
  driverSortKey: string;
  driverSortDirection: string;
  @Input() jobEvents: DispatchJobEvent[] = [];
  displayedJobEvents: DispatchJobEvent[] = [];
  jobEventsDataSource: MatTableDataSource<DispatchJobEvent>;
  jobEventSearch: string;
  jobEventSortKey: string;
  jobEventSortDirection: string;
  @Input() jobEventsLastRequestedAt: string;

  @Input() selectedConnections: string[] = [];

  allSelected = false;
  selectedJobEventsValue: string[] = [];
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  @Output() selectedJobEventsChange: EventEmitter<string[]> = new EventEmitter();
  selectedDriversValue: string[] = [];
  @Output() selectedDriversChange: EventEmitter<string[]> = new EventEmitter();

  @Input() get selectedJobEvents() { return this.selectedJobEventsValue; }
  set selectedJobEvents(data: string[]) {
    this.selectedJobEventsValue = data;
    this.selectedJobEventsChange.emit(data);
  }
  @Input() get selectedDrivers() { return this.selectedDriversValue; }
  set selectedDrivers(data: string[]) {
    this.selectedDriversValue = data;
    this.selectedDriversChange.emit(data);
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public authenticationService: AuthenticationService,
    public translateService: TranslateService,
    public preferenceService: PreferenceService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    public assignmentService: AssignmentService,
    public truckTypeService: TruckTypeService,
    public tagService: TagService,
    public dispatchService: DispatchService,
    private driverService: DriverService,
    private customFieldService: CustomFieldService
  ) {
    this.jobEventsDataSource = new MatTableDataSource([]);
    this.driversDataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit(): void {
    this.selectLoadType(this.activeLoadType);
    this.getCustomFields();
    this.getPreferences();
    this.getTruckTypes({ page_size: 100 });
    this.getTags();
    if (this.authenticationService.getFeature('dispatchSchedulerChangeTracking')) {
      setTimeout(() => { this.startJobEventsTimer(); }, 60000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeLoadType && changes.activeLoadType.currentValue) { this.selectLoadType(changes.activeLoadType.currentValue); }
    if (changes.view && changes.view.currentValue) {
      if (changes.view.currentValue === 'jobEvents') {
        this.loadTypeOptions = [ 'Loads Ordered', 'Tons Ordered', 'Trucks Requested', 'Truck Type Requested' ];
      } else if (changes.view.currentValue === 'drivers') {
        this.loadTypeOptions = [ 'Loads Ordered', 'Tons Ordered', 'Trucks Requested' ];
        if (this.activeLoadType === 'Truck Type Requested') {
          this.activeLoadType = 'Loads Ordered';
        }
      }
      this.allSelected = false;
      this.selectLoadType(this.activeLoadType);
      this.getPreferences();
    }
    if (changes.drivers && changes.drivers.currentValue) {
      this.displayedDrivers = changes.drivers.currentValue.sort((a: DispatchDriver, b: DispatchDriver) => (
        (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
      ));
      this.searchDrivers(this.driverSearch);
    }
    if (changes.jobEvents && changes.jobEvents.currentValue) {
      this.displayedJobEvents = changes.jobEvents.currentValue;
      this.searchJobEvents(this.jobEventSearch);
      this.applyTagsAndSetTableData();
      this.checkIfJobEventsHaveUpdates();
    }
  }

  ngOnDestroy(): void {
    if (this.jobEventsTimerSub) {
      try {
        this.jobEventsTimerSub.unsubscribe();
        this.jobEventsTimer = null;
      } catch (e) {
        this.jobEventsTimerSub = null;
        this.jobEventsTimer = null;
      }
    }
  }

  startJobEventsTimer(): void {
    this.jobEventsTimer = observableTimer(0, 60000);
    if (this.authenticationService.getFeature('dispatchSchedulerChangeTracking')) {
      this.jobEventsTimerSub = this.jobEventsTimer.subscribe(t => {
        const last_modified__gt = moment().subtract(1, 'minutes').toISOString();
        this.getJobEvents.emit({ last_modified__gt });
      });
    }
  }

  getAssignmentOptions (jobEvent: CondensedJobEvent ) {
    if (!jobEvent.canEdit) {
      return this.assignmentOptions.filter(
        (option) => !['Edit Job', 'Edit Job Days', 'Cancel Job'].includes(option)
      );
    }

    if (jobEvent.cancelled) {
      return this.assignmentOptions.filter(option => option !== 'Cancel Job');
    }
    
    return this.assignmentOptions
  }

  /**
   * Sums up the total of trucks requested on all shares for a jobEvent
   *
   * @param {DispatchJobEvent} dispatchJobEvent The input jobEvent data
   * @return {number} The amount of requested trucks
   */
  getSharedRequestedAmount(dispatchJobEvent: DispatchJobEvent): number {
    return dispatchJobEvent.shares && dispatchJobEvent.shares.length ?
      dispatchJobEvent.shares.map(s => (s.numTrucks)).reduce((a, b) => (a + b)) :
      0;
  }

  /**
   * Sums up the total of trucks confirmed on all shares for a jobEvent
   *
   * @param {DispatchJobEvent} dispatchJobEvent The input jobEvent data
   * @return {number} The amount of confirmed trucks
   */
  getSharedConfirmedAmount(dispatchJobEvent: DispatchJobEvent): number {
    return dispatchJobEvent.shares && dispatchJobEvent.shares.length ?
      dispatchJobEvent.shares.map(s => (s.confirmedTrucks)).reduce((a, b) => (a + b)) :
      0;
  }

  /**
   * Sums up the total of trucks dispatched on all shares for a jobEvent
   *
   * @param {DispatchJobEvent} dispatchJobEvent The input jobEvent data
   * @return {number} The amount of dispatched trucks
   */
  getSharedDispatchedAmount(dispatchJobEvent: DispatchJobEvent): number {
    return dispatchJobEvent.shares && dispatchJobEvent.shares.length ?
      dispatchJobEvent.shares.map(s => (s.dispatched)).reduce((a, b) => (a + b)) :
      0;
  }

  getRemainingLoadCount(a, b): number {
    return Number(a) - Number(b);
  }

  getDispatchedCount(assignments: Assignment[]): number {
    return assignments ? assignments.filter(a => (a.dispatched)).length : 0;
  }

  getAssignedTons(a, b): number {
    return Number(a) * Number(b);
  }

  dispatchAvailable(): boolean {
    let available = false;
    if (this.jobEvents) {
      this.jobEvents.forEach(jobEvent => {
        if (!available && jobEvent.selected && jobEvent.assignments.length > 0) {
          available = true;
        }
      });
    }
    return available;
  }

  getUndispatchedIds(jobEvent: DispatchJobEvent): string[] {
    const undispatchedIds = [];
    jobEvent.assignments.forEach(assignment => {
      if (!assignment.dispatched) {
        undispatchedIds.push(assignment.driver.id);
      }
    });
    return undispatchedIds;
  }

  getUnassignedIds(row: DispatchJobEvent | DispatchDriver, selectedIds: string[]): string[] {
    const unassignedIds = [];
    selectedIds.forEach(id => {
      if (row.assignments.findIndex(assignment => (assignment.driver.id === id)) === -1) {
        unassignedIds.push(id);
      }
    });
    return unassignedIds;
  }

  selectLoadType(option: 'Loads Ordered' | 'Tons Ordered' | 'Trucks Requested' | 'Truck Type Requested') {
    if (this.activeLoadType !== option) {
      this.activeLoadType = option;
      this.updatePreference.emit({ activeLoadType: this.activeLoadType });
    }
    if (this.view === 'collaborators') {
      this.displayedJobEventColumns = [
        'job', 'status', 'customer-name', 'trucks-requested', 'trucks-assigned',
        'trucks-dispatched', 'collaborator-actions', 'actions'
      ];
    } else if (this.view === 'drivers') {
      this.displayedDriverColumns = this.driverDefaultColumns;
    } else {
      switch (option) {
        case 'Loads Ordered':
          this.displayedJobEventColumns = this.loadsOrderedDefaultColumns;
          break;
        case 'Tons Ordered':
          this.displayedJobEventColumns = this.tonsOrderedDefaultColumns;
          break;
        case 'Trucks Requested':
          this.displayedJobEventColumns = this.trucksRequestedDefaultColumns;
          break;
        case 'Truck Type Requested':
          this.displayedJobEventColumns = this.displayedJobEventColumns;
          break;
      }
    }
    this.parsePreferences();
  }

  selectStatsType() {
    const activeTypes = Object.keys(this.loadStats.ordered).filter(key => (
      this.loadStats.ordered[key] > 0 || key === 'loads'
      ));
    const currentSelectedTypeIndex = activeTypes.findIndex(t => (t === this.activeStatsType));
    this.activeStatsType = activeTypes[
      currentSelectedTypeIndex + 1 < activeTypes.length ?
      currentSelectedTypeIndex + 1 : 0
    ];
  }

  editJobEvent(row: DispatchJobEvent) {
    this.jobEvents.map(jobEvent => {
      row.selected = (jobEvent.id === row.id);
      return row;
    });
    this.selectedDrivers = [];
    row.assignments.forEach(assignment => {
      this.selectedDrivers.push(assignment.driver.id);
    });
    this.openJobEventPanel.emit(row);
  }

  editDriver(row: DispatchDriver) {
    this.drivers.map(driver => {
      row.selected = (driver.id === row.id);
      return row;
    });
    this.selectedDrivers = [row.id];
    this.openDriverPanel.emit(row);
  }

  /**
   * Evaluates the assignments on a driver and figures out whether it has been fully or incompletely dispatched
   *
   * @param {DispatchDriver} row The input driver data
   * @return {'unassigned' | 'dispatched incomplete' | 'dispatched'} The current dispatch status
   */
  driverDispatchedStatus(row: DispatchDriver): 'unassigned' | 'dispatched incomplete' | 'dispatched' {
    let status: 'unassigned' | 'dispatched incomplete' | 'dispatched' = 'unassigned';
    if (row.assignments.length) {
      status = row.assignments.map(
        a => (a.dispatched)
      ).findIndex(dispatched => (!dispatched)) > -1 ? 'dispatched incomplete' : 'dispatched';
    }
    return status;
  }

  /**
   * Evaluates the assignments on a jobEvent and figures out whether it has been fully or incompletely dispatched
   *
   * @param {DispatchJobEvent} row The input jobEvent data
   * @return {'unassigned' | 'dispatched incomplete' | 'dispatched'} The current dispatch status
   */
  jobEventDispatchedStatus(row: DispatchJobEvent): 'unassigned' | 'dispatched incomplete' | 'dispatched' {
    let status: 'unassigned' | 'dispatched incomplete' | 'dispatched' = 'unassigned';
    if (row.assignments.length) {
      status = row.assignments.map(
        a => (a.dispatched)
      ).findIndex(dispatched => (!dispatched)) > -1 ? 'dispatched incomplete' : 'dispatched';
    }
    return status;
  }

  /**
   * Evaluates the shares on a jobEvent and figures out whether it has been fully or incompletely dispatched
   *
   * @param {DispatchJobEvent} row The input jobEvent data
   * @return {'unassigned' | 'dispatched incomplete' | 'dispatched'} The current dispatch status
   */
  shareDispatchedStatus(row: DispatchJobEvent): 'unassigned' | 'dispatched incomplete' | 'dispatched' {
    let status: 'unassigned' | 'dispatched incomplete' | 'dispatched' = 'unassigned';
    if (row.shares.length) {
      status = row.shares.map(
        a => (a.dispatched >= a.numTrucks)
      ).findIndex(dispatched => (!dispatched)) > -1 ? 'dispatched incomplete' : 'dispatched';
    }
    return status;
  }

  select(row: DispatchJobEvent) {
    row.selected = !row.selected;
    if (row.selected) {
      row.assignments.forEach(assignment => {
        this.selectedDrivers.push(assignment.driver.id);
      });
    } else {
      row.assignments.forEach(assignment => {
        this.selectedDrivers = this.selectedDrivers.filter(id => (id !== assignment.driver.id));
      });
    }
  }

  selectAll(e: MatCheckboxChange, type: 'jobEvents' | 'drivers') {
    this.allSelected = e.checked;
    if (type === 'jobEvents') {
      this.jobEvents.map(row => {
        row.selected = this.allSelected;
        if (this.allSelected) {
          row.assignments.forEach(assignment => {
            this.selectedDrivers.push(assignment.driver.id);
          });
        } else { this.selectedDrivers = []; }
        return row;
      });
    } else {
      this.drivers.map(row => {
        row.selected = this.allSelected;
        this.selectedJobEvents = [];
        return row;
      });
    }
  }

  searchJobEvents(e: string) {
    if (e) {
      this.jobEventSearch = e.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      e = e.toUpperCase();
      this.displayedJobEvents = this.jobEvents.filter(j => (
        (j.jobName && j.jobName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(e)) ||
        (j.orderNumber && j.orderNumber.toUpperCase().includes(e))
      ));
    } else { this.displayedJobEvents = this.jobEvents; }
    this.applyTagsAndSetTableData();
  }

  searchDrivers(e: string) {
    if (e) {
      this.driverSearch = e.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      e = e.toUpperCase();
      this.displayedDrivers = this.drivers.filter(driver => (
        (driver.name && driver.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(e)) ||
        (driver.truck && driver.truck.name.toUpperCase().includes(e)) ||
        driver.assignments.map(a => (
          a.jobevent && a.jobevent.job && a.jobevent.job.orderNumber && a.jobevent.job.orderNumber.toUpperCase()
        )).join(' ').includes(e) ||
        driver.assignments.map(a => (
          a.jobevent && a.jobevent.job &&
          a.jobevent.job.name && a.jobevent.job.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
        )).join(' ').includes(e)
      ));
    } else { this.displayedDrivers = this.drivers; }
    this.setTablePage('driver');
  }

  sort(sortEvent: {active: string, direction: 'asc' | 'desc'}, table: 'jobEvent' | 'driver') {
    switch (sortEvent.active) {
      case 'job':
        this[table + 'SortKey'] = 'jobName';
        break;
      case 'status':
        this[table + 'SortKey'] = 'status';
        break;
      case 'customer-name':
        this[table + 'SortKey'] = 'customer';
        break;
      case 'start-time':
        this[table + 'SortKey'] = 'shift1Start';
        break;
      case 'end-time':
        this[table + 'SortKey'] = 'shift1End';
        break;
      case 'amount-ordered':
        this[table + 'SortKey'] = 'dailyDeliveryTarget';
        break;
      case 'total-ordered':
        this[table + 'SortKey'] = 'stats.totalOrdered';
        break;
      case 'loads-assigned':
        this[table + 'SortKey'] = 'assignedLoads';
        break;
      case 'trucks-requested':
        this[table + 'SortKey'] = 'numTrucks';
        break;
      case 'driver':
        this[table + 'SortKey'] = 'name';
        break;
      case 'assigned-trucks':
        this[table + 'SortKey'] = 'assignments.length';
        break;
      case 'assigned-loads':
        this[table + 'SortKey'] = 'loadCount';
        break;
      case 'collaborator-actions':
        this[table + 'SortKey'] = 'shares.length';
        break;
      default:
        this[table + 'SortKey'] = sortEvent.active.replace(/([-_][a-z])/g, (x) => x.toUpperCase().replace('-', ''));
        break;
    }
    this[table + 'SortDirection'] = sortEvent.direction;
    this[
      'displayed' + table.charAt(0).toUpperCase() + table.slice(1) + 's'
    ] = this['displayed' + table.charAt(0).toUpperCase() + table.slice(1) + 's'].sort((a: DispatchJobEvent, b: DispatchJobEvent) => {
      if (sortEvent.active === 'amount-ordered') {
        return this[table + 'SortDirection'] === 'asc' ?
        (a[this[table + 'SortKey']] - b[this[table + 'SortKey']]) :
        (b[this[table + 'SortKey']] - a[this[table + 'SortKey']]);
      } else {
        return this[table + 'SortDirection'] === 'asc' ?
        sortEvent.active === 'assigned-trucks' ?
        ((a.assignments.length < b.assignments.length) ? -1 : (a.assignments.length > b.assignments.length) ? 1 : 0) :
        ((a[this[table + 'SortKey']] < b[this[table + 'SortKey']]) ? -1 :
        (a[this[table + 'SortKey']] > b[this[table + 'SortKey']]) ? 1 : 0) :
        sortEvent.active === 'assigned-trucks' ?
        ((a.assignments.length > b.assignments.length) ? -1 : (a.assignments.length < b.assignments.length) ? 1 : 0) :
        ((a[this[table + 'SortKey']] > b[this[table + 'SortKey']]) ? -1 :
        (a[this[table + 'SortKey']] < b[this[table + 'SortKey']]) ? 1 : 0);
      }
    });
    this.setTablePage(table);
  }

  setTablePage(table: 'jobEvent' | 'driver', e?: PageEvent, save = false) {
    let startPos: number;
    if (e) {
      this[table + 'PageSize'] = e.pageSize;
      startPos = e.pageIndex * e.pageSize;
    } else {
      startPos = 0;
      if (this[table + 'PageSize'] === 100) { this[table + 'PageSize'] = 10; }
      if (this.jobEventPaginator) { this.jobEventPaginator.firstPage(); }
      if (this.driverPaginator) { this.driverPaginator.firstPage(); }
    }
    if (table === 'jobEvent' && this.displayedJobEvents) {
      this.jobEventsDataSource.data = this.displayedJobEvents.slice(startPos, (startPos + this.jobEventPageSize));
    } else if (table === 'driver' && this.displayedDrivers) {
      this.driversDataSource.data = this.displayedDrivers.slice(startPos, (startPos + this.driverPageSize));
    }
    if (save) {
      this.savePreferences();
    }
  }

  setSelectedAction(option, row: DispatchJobEvent) {
    switch (option) {
      case 'Edit Job':
        this.editJob(row);
        break;
      case 'Edit Job Days':
        this.editJobDays(row);
        break;
      case 'Clone Job':
        this.cloneJob(row);
        break;
      case 'Edit Day Details':
        this.editDayDetails(row);
        break;
      case 'Auto-Assign Previous Trucks & Drivers':
        this.autoAssign(row);
        break;
      case 'Move to a New Day':
        this.modifyAssignments(row, 'move');
        break;
      case 'Copy to a New Day':
        this.modifyAssignments(row, 'copy');
        break;
      case 'Remove Assignments':
        this.removeAssignments(row);
        break;
      case 'Cancel Job':
        this.cancelJobs(row);
        break;
      case 'Edit Collaborators':
        this.openEditCollaborators(row);
        break;
      case 'Send Notes to Collaborators':
        this.openSendNotes(row);
        break;
      case 'Add Collaborators':
        this.navigateToAddCollaborator(row);
        break;
    }
  }

  addNewJob(): void {
    this.router.navigate(['/jobs/new'], {
      queryParams: {
        returnTo: '/dispatch-schedule?date=' + moment(this.selectedDate).format('YYYYMMDD')
      }
    });
  }

  editJob(jobEvent: DispatchJobEvent): void {
    this.router.navigate(['/jobs/' + jobEvent.jobId + '/edit'], {
      queryParams: {
        returnTo: '/dispatch-schedule?date=' + moment(this.selectedDate).format('YYYYMMDD')
      }
    });
  }

  editJobDays(jobEvent: DispatchJobEvent): void {
    const dialog = this.dialog.open(EditJobDialogComponent, {
      width: '430px',
      data: { jobId: jobEvent.jobId }
    });

    dialog.componentInstance.callback = () => this.getJobEvents.emit();
  }


  cloneJob(jobEvent: DispatchJobEvent): void {
    this.router.navigate(['/jobs/clone/' + jobEvent.jobId], {
      queryParams: {
        returnTo: '/dispatch-schedule?date=' + moment(this.selectedDate).format('YYYYMMDD')
      }
    });
  }

  editDayDetails(jobEvent: DispatchJobEvent): void {
    this.jobEventService.getJobEvent(jobEvent.id).subscribe(res => {
      const dialog = this.dialog.open(EditJobEventDialogComponent, {
        width: '430px'
      });
      if (dialog) {
        dialog.componentInstance.jobEvent = res;
        dialog.componentInstance.callback = () => this.getJobEvents.emit();
      }
    }, (err) => this.errors.emit(err));
  }

  autoAssign(jobEvent: DispatchJobEvent): void {
    this.jobEventService.autoAssign(jobEvent.id).subscribe(res => {
      this.getAssignments.emit(this.drivers);
    }, err => {
      let results = JSON.parse(err._body);
      results = camelcaseKeysDeep(results);
      const dialog = this.dialog.open(AutoAssignConflictDialogComponent, {
        width: '430px'
      });
      dialog.componentInstance.results = results;
      dialog.componentInstance.callback = () => this.getAssignments.emit(this.drivers);
    });
  }

  modifyAssignments(jobEvent: DispatchJobEvent, action: 'copy' | 'move'): void {
    const dialog = this.dialog.open(MoveAssignmentsDialogComponent, {width: '850px'});
    if (dialog) {
      const convertedJobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
      dialog.componentInstance.copy = action === 'copy';
      dialog.componentInstance.job = convertedJobEvent.job;
      dialog.componentInstance.start = moment(this.selectedDate).startOf('day').toDate();
      dialog.componentInstance.jobEvent = convertedJobEvent;
      dialog.componentInstance.assignments = jobEvent.assignments;
      dialog.componentInstance.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
      dialog.componentInstance.callback = () => this.getAssignments.emit(this.drivers);
    }
  }

  removeAssignments(row: DispatchJobEvent | DispatchDriver): void {
    const assignmentIds = row.assignments.map(assignment => (assignment.id));
    if (assignmentIds && assignmentIds.length) {
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '430px',
        height: '250px'
      });
      this.confirmDialog.componentInstance.attributes = {
        title: `Remove ${assignmentIds.length} Assignments?`,
        body: `This action will remove the selected assignments from this day.`,
        close: 'Cancel',
        accept: 'Remove'
      };
      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.assignmentService.bulkRemove(assignmentIds).subscribe(() => {
            this.getAssignments.emit(this.drivers);
          }, err => this.errors.emit(err));
        }
        this.confirmDialog = null;
      }, (err) => this.errors.emit(err));
    }
  }

  getTags(query = {}, next = false): void {
    if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
      this.tagsReq.unsubscribe();
    }

    let request = this.tagService.list(query);
    if (next) {
      request = this.tagService.listNext();
    } else {
      this.tags = [];
    }
    if (request) {
      this.tagsReq = request.subscribe(res => {
        this.tags.push.apply(this.tags, res);
      }, (err) => this.errors.emit(err));
    }
  }

  getTruckTypes(query = {}, next = false): void {
    if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
      this.truckTypesReq.unsubscribe();
    }

    let request = this.truckTypeService.list(query);
    if (next) {
      request = this.truckTypeService.listNext();
    } else {
      this.truckTypes = [];
    }
    if (request) {
      this.truckTypesReq = request.subscribe(res => {
        this.truckTypes.push.apply(this.truckTypes, res);
      }, (err) => this.errors.emit(err));
    }
  }

  selectTags(tags: Tag[]): void {
    if (this.tagsDropdown) {
      this.tagsDropdown.selectedItems = tags;
      this.tagsDropdown.setButtonText();
      if (tags && tags.length > 0) {
        this.tagsUrlList = tags.map(tag => { return tag.name; }).join(',') + ',';
      } else {
        this.tagsUrlList = null;
      }
      this.driverQuery.emit({ tags: this.tagsUrlList });
    }
  }

  selectJobEventTags(tags: Tag[]): void {
    if (this.jobEventTagsDropdown) {
      this.jobEventTagsDropdown.selectedItems = tags;
      this.jobEventTagsDropdown.setButtonText();
      this.setJobEventAppliedFilters('tags', tags);
    }
    this.searchJobEvents(this.jobEventSearch);
  }

  get jobEventTagsSelected() {
    const tagsSelected = this.jobEventAppliedFilters && find(this.jobEventAppliedFilters, { key: 'tags'})
    return tagsSelected ? tagsSelected.values : []
  }

  selectJobEventTruckType(truckTypes: TruckType[]): void {
    if (this.jobEventTruckTypeDropdown) {
      this.jobEventTruckTypeDropdown.selectedItems = truckTypes;
      this.jobEventTruckTypeDropdown.setButtonText();
      this.setJobEventAppliedFilters('truckTypes', truckTypes);
    }
    if (truckTypes && truckTypes.length > 0) {
      this.truckTypesUrlList = truckTypes.map(type => { return type.id; }).join(',');
    } else {
      this.truckTypesUrlList = null;
    }
    this.getJobEvents.emit({ truck_types_in: this.truckTypesUrlList });
  }

  setJobEventAppliedFilters(key: string, value: any): void {
    const filterIdx = this.jobEventAppliedFilters.findIndex(f => f.key === key);
    if (value && value.length) {
      if (filterIdx < 0) {
        const newFilter = new FilterOption({
          key: key,
          values: value
        });
        this.jobEventAppliedFilters.push(newFilter);
      } else {
        this.jobEventAppliedFilters.forEach(filter => {
          if (filter.key === key) {
            filter.values = value;
          }
        });
      }
    } else {
      const filterIdx = this.jobEventAppliedFilters.findIndex(f => f.key === key);
      if (filterIdx > -1) { this.jobEventAppliedFilters.splice(filterIdx, 1); }
    }

    this.savePreferences(this.jobEventAppliedFilters)
  }

  expandSearch(): void {
    if (this.jobEventTagsDropdown && this.jobEventTagsDropdown.selectedItems && this.jobEventTagsDropdown.selectedItems.length) {
      this.selectJobEventTags([]);
    }
    if (
      this.jobEventTruckTypeDropdown &&
      this.jobEventTruckTypeDropdown.selectedItems &&
      this.jobEventTruckTypeDropdown.selectedItems.length
    ) {
      this.selectJobEventTruckType([]);
    }
    if (this.jobEventSearch) {
      this.jobEventSearch = '';
      this.searchJobEvents('');
    }
  }

  applyTagsAndSetTableData() {
    if (this.jobEventTagsDropdown && this.jobEventTagsDropdown.selectedItems && this.jobEventTagsDropdown.selectedItems.length) {
      const tagIds = this.jobEventTagsDropdown.selectedItems.map(tag => { return tag.id; });
      this.displayedJobEvents = this.displayedJobEvents.filter(j => (
        j.tags && j.tags.filter(tag => (tagIds.findIndex(id => (id === tag.id))) > -1).length > 0
      ));
    }
    this.setTablePage('jobEvent');
  }

  updateJobEvent(field: string, value: any, jobEvent: DispatchJobEvent) {
    if (field === 'shift1Start' && jobEvent.shift1Start) {
      const shift1StartDate = moment(jobEvent.shift1Start).toDate().toLocaleDateString()

      jobEvent[field] = moment(`${shift1StartDate} ${value}`, 'MM/DD/YYYY HH:mm').toISOString()
    } else if (field === 'shift1End' && jobEvent.shift1End) {
      const shift1EndDate = moment(jobEvent.shift1End).toDate().toLocaleDateString()

      jobEvent[field] = moment(`${shift1EndDate} ${value}`, 'MM/DD/YYYY HH:mm').toISOString()
    } else if (field === 'numTrucks') {
      // dev note: once jobEvent is given a serializer move this to that method
      jobEvent[field] = Number(value);
    } else { jobEvent[field] = value; }

    // dev note: delete this once BE resolves the 'valid interger' errors for these fields
    let convertedJobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
    if (field !== 'deliveryInterval') {
      delete convertedJobEvent.deliveryInterval;
    } else if (value.length === 0) {
      convertedJobEvent.deliveryInterval = '0';
    }
    if (field !== 'dailyDeliveryTarget') {
      delete convertedJobEvent.dailyDeliveryTarget;
    } else if (value.length === 0) {
      convertedJobEvent.dailyDeliveryTarget = '0';
    }

    field === 'orderNumber' ? this.jobService.save(
      { id: convertedJobEvent.job.id, orderNumber: value }
    ).subscribe(() => {}, (err) => this.errors.emit(err)) :
    this.jobEventService.save(convertedJobEvent).subscribe(
      () => this.updateStats.emit(this.jobEvents), (err) => this.errors.emit(err)
    );
    field === 'jobNumber' ? this.jobService.save(
      { id: convertedJobEvent.job.id, jobNumber: value }
    ).subscribe(() => {}, (err) => this.errors.emit(err)) :
    this.jobEventService.save(convertedJobEvent).subscribe(
      () => this.updateStats.emit(this.jobEvents), (err) => this.errors.emit(err)
    );
  }

  dispatch() {
    let dispatchList = [];
    this.jobEvents.forEach(jobEvent => {
      if (jobEvent.selected && jobEvent.assignments && jobEvent.assignments.length > 0) {
        dispatchList.push(this.dispatchService.save({
          jobEvent: jobEvent.id,
          notify_new: true
        }));
      }
    });
    forkJoin(dispatchList).subscribe(
      (updatedJobEvents) => {
        updatedJobEvents.forEach((jobEvent: any) => {
          this.jobEvents[this.jobEvents.findIndex(j => (j.id === jobEvent.id))] = Object.assign(
            this.jobEvents[this.jobEvents.findIndex(j => (j.id === jobEvent.id))], {
              lastDispatchedTime: jobEvent.lastDispatchedTime,
              assignments: this.jobEvents[this.jobEvents.findIndex(j => (j.id === jobEvent.id))].assignments.map(a => (
                Object.assign(a, {dispatched: true})
              ))
            }
          );
        });
      }, (err) => this.errors.emit(err)
    );
  }

  arrangeAssignment(driver: DispatchDriver, index: number, order: 'next' | 'previous') {
    let startTime1, startTime2;
    if (order === 'next' && driver.assignments && ((index + 1) < driver.assignments.length)) {
      startTime1 = driver.assignments[index].uniqueStart;
      startTime2 = driver.assignments[index + 1].uniqueStart;
      driver.assignments[index].uniqueStart = startTime2;
      driver.assignments[index + 1].uniqueStart = startTime1;
      [driver.assignments[index], driver.assignments[index + 1]] = [driver.assignments[index + 1], driver.assignments[index]];
    } else if (order === 'previous' && index > 0) {
      startTime1 = driver.assignments[index - 1].uniqueStart;
      startTime2 = driver.assignments[index].uniqueStart;
      driver.assignments[index - 1].uniqueStart = startTime2;
      driver.assignments[index].uniqueStart = startTime1;
      [driver.assignments[index - 1], driver.assignments[index]] = [driver.assignments[index], driver.assignments[index - 1]];
    }
    if (startTime1 && startTime2) {
      this.assignmentService.bulkUpdate(
        driver.assignments.map(a => (<Assignment>{id: a.id, uniqueStart: a.uniqueStart}))
      ).subscribe(bulkRes => {
        if (bulkRes.errors) { this.errors.emit(bulkRes.errors); }
      });
    }
  }

  changeTruck(driver: DispatchDriver, truck: Truck) {
    if (!driver.truck || (driver.truck.id !== truck.id)) {
      if (driver.assignments) {
        driver.assignments = driver.assignments.map(a => (Object.assign(a, { truck: truck })));
        this.assignmentService.bulkUpdate(driver.assignments).subscribe(bulkRes => {
          if (bulkRes.errors) { this.errors.emit(bulkRes.errors); }
        });
        driver.truck = truck;
        driver.assignments = driver.assignments.map(a => (Object.assign(a, { truck: truck })));
        this.driverService.save({ id: driver.id, truck: truck }).subscribe(res => {
          this.assignmentService.bulkUpdate(driver.assignments).subscribe(bulkRes => {
            if (bulkRes.errors) { this.errors.emit(bulkRes.errors); }
          });
        }, err => this.errors.emit(err));
      }
    }
  }

  cancelJobs(jobEvent?: DispatchJobEvent): void {
    const jobsSelected = this.jobEvents.filter(j => (j.selected))

    if (jobEvent && !jobEvent.cancelled && jobEvent.canEdit) {
      const dialog = this.dialog.open(CancelJobDialogComponent, {
        width: '430px',
        data: { jobEventIds: [jobEvent.id] }
      });
      dialog.componentInstance.callback = () => this.getJobEvents.emit();
    } else if (!jobEvent && jobsSelected.length) {
      const dialog = this.dialog.open(CancelJobDialogComponent, {
        width: '430px',
        data: { 
          jobEventIds: jobsSelected.map(j => (j.id)),
          allJobEventsCount: jobsSelected.length,
          jobEventsDate: this.selectedDate
        }
      });
      dialog.componentInstance.callback = () => this.getJobEvents.emit();
    }
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'cancel':
        this.cancelJobs();
        break;
    }
  }

  /**
   * Opens the add collaborator dialog for a specified jobevent and preselect
   * the currently set selectedConnections
   *
   * @param {DispatchJobEvent} jobEvent The selected jobEvent
   */
   openAddCollaborators(jobEvent: DispatchJobEvent) {
    const dialog = this.dialog.open(AddCollaboratorsDialogComponent, {
      width: '1100px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.selectedConnections = this.selectedConnections;
      dialog.componentInstance.jobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
      dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
      dialog.componentInstance.callback = () => this.getShares.emit();
    }
  }

  /**
   * Opens the edit collaborators dialog for a specified jobevent
   *
   * @param {DispatchJobEvent} jobEvent The selected jobEvent
   */
  openEditCollaborators(jobEvent: DispatchJobEvent): void {
    const dialog = this.dialog.open(EditCollaboratorsDialogComponent, {
      width: '790px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.jobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
      dialog.componentInstance.allSelected = true;
      dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
      dialog.componentInstance.callback = () => this.getShares.emit();
    }
  }

  /**
   * Opens the send notes dialog for a specified jobevent
   *
   * @param {DispatchJobEvent} jobEvent The selected jobEvent
   */
  openSendNotes(jobEvent: DispatchJobEvent): void {
    const dialog = this.dialog.open(SendCollaboratorNotesDialogComponent, {
      width: '790px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.jobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
      dialog.componentInstance.allSelected = true;
    }
  }

  onColumnChange(): void {
    this.savePreferences();
  }

  /**
   * Gets any available custom fields for the currently logged in org and
   * set the brokerRateCodeKey for sharing any jobEvents
   *
   */
  getCustomFields() {
    this.customFieldService.list({
      kind: CustomFieldKind.Share,
      display_name: 'Broker Rate Code',
      active: 'True'
    }).subscribe(fields => {
      this.brokerRateCodeKey = fields && fields[0] && fields[0].key;
    });
  }

  getPreferences(): void {
    let currentUser = this.authenticationService.user();
  
    this.preferenceService.list({
      name: this.view === 'jobEvents' ? this.jobEventsPreferenceKey : this.driversPreferenceKey,
      type: 'user',
      profile: currentUser.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preference = preferences[0];
        this.parsePreferences();
      }
    }, err => {
      this.errors = err;
    });
  }

  savePreferences(filters?: any): void {
    let currentUser = this.authenticationService.user();
    const jobEvents = this.preference && this.preference.blob && this.preference.blob.columns && this.preference.blob.columns.jobEvents ? this.preference.blob.columns.jobEvents : null;
    const drivers = this.preference && this.preference.blob && this.preference.blob.columns && this.preference.blob.columns.drivers ? this.preference.blob.columns.drivers : null;

    this.preference = {
      ...this.preference,
      name: this.view === 'jobEvents' ? this.jobEventsPreferenceKey : this.driversPreferenceKey,
      type: 'user',
      profile: currentUser.id,
      blob: {
        columns : {},
        pageSize: this.view === 'jobEvents' ? this.jobEventPageSize : this.driverPageSize,
      }
    };

    if (this.view === 'jobEvents') {
      this.preference.blob.columns.jobEvents = {
        loadsOrdered : this.getColumnPreference('Loads Ordered', this.displayedJobEventColumns, jobEvents, 'loadsOrdered', this.loadsOrderedDefaultColumns),
        tonsOrdered : this.getColumnPreference('Tons Ordered', this.displayedJobEventColumns, jobEvents, 'tonsOrdered', this.tonsOrderedDefaultColumns),
        trucksRequested : this.getColumnPreference('Trucks Requested', this.displayedJobEventColumns, jobEvents, 'trucksRequested', this.trucksRequestedDefaultColumns),
        truckTypeRequested : this.getColumnPreference('Truck Type Requested', this.displayedJobEventColumns, jobEvents, 'truckTypeRequested', this.truckTypeRequestedDefaultColumns)
      };
    } else {
      this.preference.blob.columns.drivers = {
        loadsOrdered : this.getColumnPreference('Loads Ordered', this.displayedDriverColumns, drivers, 'loadsOrdered', this.driverDefaultColumns),
        tonsOrdered : this.getColumnPreference('Tons Ordered', this.displayedDriverColumns, drivers, 'tonsOrdered', this.driverDefaultColumns),
        trucksRequested : this.getColumnPreference('Trucks Requested', this.displayedDriverColumns, drivers, 'trucksRequested', this.driverDefaultColumns)
      };
    }

    if (filters) {
      this.preference.blob.filters = [
        ...(this.preference.blob.filters || []),
        ...filters,
      ]
    }

    this.preferenceService.save(this.preference).subscribe(preference => {
      this.preference = preference;
    });
  }

  getColumnPreference(loadType: string, displayedColumns: string[], storedPreference: string[], storedPreferenceKey: string, defaultColumns: string[]): string[] {
    return this.activeLoadType === loadType ? displayedColumns :
      storedPreference && storedPreference[storedPreferenceKey] ? storedPreference[storedPreferenceKey] :
      defaultColumns;
  }

  parsePreferences(): void {
    if (!this.preference || !this.preference.blob) return;

    if (this.preference.blob['columns']) {
      let displayedColumns = this.view === 'jobEvents' ? this.displayedJobEventColumns : this.displayedDriverColumns;
      let availableColumns = this.view === 'jobEvents' ? this.availableJobEventsColumns : this.availableDriverColumns;
      let currentLoadType = '';
      if (this.activeLoadType === 'Loads Ordered') { currentLoadType = 'loadsOrdered'; }
      if (this.activeLoadType === 'Tons Ordered') { currentLoadType = 'tonsOrdered'; }
      if (this.activeLoadType === 'Trucks Requested') { currentLoadType = 'trucksRequested'; }
      if (this.activeLoadType === 'Truck Type Requested') { currentLoadType = 'truckTypeRequested'; }

      let columns: string[] = this.preference && this.preference.blob && this.preference.blob.columns && this.preference.blob.columns[this.view] && this.preference.blob.columns[this.view][currentLoadType] ? this.preference.blob.columns[this.view][currentLoadType] : null;
      if (columns) {
        columns = intersectionWith(columns, availableColumns, (col, c) => col === c.key);
        if (this.view === 'jobEvents') {
          const updatesIdx = columns.findIndex(c => c === 'updates');
          if (updatesIdx === -1) {
            columns.splice(1, 0, 'updates');
          }
          this.displayedJobEventColumns = columns;
        } else {
          this.displayedDriverColumns = columns;
        }
        displayedColumns = columns;
      }
    }

    if (this.preference.blob['pageSize']) {
      let pageSize = this.preference.blob['pageSize'];
      if (this.view === 'jobEvents') {
        this.jobEventPageSize = pageSize;
      }
      if (this.view === 'drivers') {
        this.driverPageSize = pageSize;
      }
    }
    
    let filters = this.preference.blob['filters'] || [];
    if (filters && filters.length) {
      if (this.view === 'jobEvents') {
        filters = filters.filter((field) => !field['default']);
        filters.forEach((f) => {
          const filterIndex = findIndex(this.jobEventAppliedFilters, {
            key: f.key,
            default: true,
          });

          if (filterIndex !== -1) {
            this.jobEventAppliedFilters[filterIndex] = f;
          }
        });
        this.jobEventAppliedFilters = uniqBy([...this.jobEventAppliedFilters, ...filters], 'key');
      }
    }

    this.initPrefs = true;
  }

  navigateToAddCollaborator(jobEvent: DispatchJobEvent) {
    let newRelativeUrl = this.router.createUrlTree(['/jobs', jobEvent.jobId, jobEvent.id, 'collaborators']);
    let baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }

  removeUpdateNotification(row: any): void {
    row['updates'] = false;
    const updates = this.jobEvents.findIndex(j => j['updates']);
    if (updates === -1) { this.jobEventUpdates = false; }
  }

  removeUpdateNotifications(): void {
    this.jobEvents.forEach(jobEvent => {
      if (jobEvent['updates']) {
        jobEvent['updates'] = false;
      }
    });
    this.jobEventUpdates = false;
  }

  checkIfJobEventsHaveUpdates(): void {
    this.jobEventUpdates = false;
    if (this.jobEvents.length) {
      const updates = this.jobEvents.findIndex(j => j['updates']);
      if (updates > -1) { this.jobEventUpdates = true; }
    }
  }
}
