"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var job_event_1 = require("./job-event");
var job_event_map_1 = require("./job-event-map");
var api_service_1 = require("../shared/api.service");
var lodash_1 = require("lodash");
var bulk_edit_job_events_serializers_1 = require("./bulk-edit-job-events.serializers");
var app_utilities_1 = require("../shared/app-utilities");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var JobEventService = /** @class */ (function () {
    function JobEventService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.listAllProgress = new rxjs_1.Subject();
        this.count = 0;
    }
    JobEventService.prototype.get = function (jobEventId) {
        var jobEventUrl = this.baseUrl + 'jobevents/' + jobEventId + '/';
        return this.http.get(jobEventUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.getJobEvent = function (jobEventId) {
        var jobEventUrl = this.baseUrl + 'jobevents/' + jobEventId + '/';
        return this.http.get(jobEventUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.getJobEvents = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var jobEventsUrl = this.baseUrl + 'jobevents/';
        return this.http.get(jobEventsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.list = function (query) {
        if (query === void 0) { query = null; }
        return this.getJobEvents(query);
    };
    JobEventService.prototype.listCompact = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var jobEventsUrl = this.baseUrl + 'jobevents/compact/';
        return this.http.get(jobEventsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.listAll = function (pageSize, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 50; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        params = params.set('page_size', '1');
        var requestCount = 0;
        this.listAllProgress.next(0);
        var jobEventsUrl = this.baseUrl + 'jobevents/';
        return this.http.get(jobEventsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(jobEventsUrl, {
                    headers: api_service_1.requestHeaders(),
                    params: params
                }).map(function (res) {
                    _this.listAllProgress.next(1);
                    return _this.extractData(res);
                }));
            }
            else {
                _this.listAllProgress.next(1 / Math.ceil(_this.count / pageSize));
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(jobEventsUrl, {
                        headers: api_service_1.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        requestCount++;
                        _this.listAllProgress.next(requestCount / Math.ceil(_this.count / pageSize));
                        return res;
                    }), operators_1.map(function (res) { return _this.extractData(res); })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return mergedList;
        }), operators_1.catchError(function (res) { return api_service_1.handleError(res); }));
    };
    JobEventService.prototype.save = function (model) {
        var _this = this;
        var uri = this.baseUrl + 'jobevents/';
        var _model = decamelizeKeysDeep(model);
        _model.job = _model.job && _model.job.id;
        _model.truck_types = _model.truck_types && _model.truck_types.map(function (truck_type) {
            if (typeof (truck_type) === 'object') {
                return truck_type.id;
            }
            else {
                return truck_type;
            }
        });
        if (_model.default_yard_buffer_minutes && parseFloat(_model.default_yard_buffer_minutes) > 0) {
            _model.default_yard_buffer_time = parseFloat(_model.default_yard_buffer_minutes) * 60;
        }
        if (!_model.id) {
            return this.http.post(uri, _model, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return this.http.put(uri + _model.id + '/', _model, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
    };
    JobEventService.prototype.remove = function (model) {
        var id = typeof model === 'string' ? model : model.id;
        return this.http.delete(this.baseUrl + 'jobevents/' + id + '/', {
            headers: api_service_1.requestHeaders()
        });
    };
    JobEventService.prototype.cancel = function (model) {
        var _this = this;
        var uri = this.baseUrl + 'jobevents/';
        var _model = decamelizeKeysDeep(model);
        return this.http.put(uri + _model.id + '/', _model, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.autoAssign = function (jobEventId, force) {
        var _this = this;
        if (force === void 0) { force = false; }
        var jobEventsUrl = this.baseUrl + 'jobevents/' + jobEventId + '/autoassign/?';
        var params = new http_1.HttpParams();
        if (force) {
            params = params.set('force', 'True');
        }
        return this.http.post(jobEventsUrl + params.toString(), {}, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }));
    };
    JobEventService.prototype.getMap = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var jobEventsUrl = this.baseUrl + 'jobevents/map/';
        return this.http.get(jobEventsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractMapData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(function (res) { return api_service_1.handleError(res); }));
        }
        else {
            return null;
        }
    };
    JobEventService.prototype.listNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(function (res) { return api_service_1.handleError(res); }));
        }
        else {
            return null;
        }
    };
    JobEventService.prototype.getExportFields = function (customUrl) {
        if (customUrl === void 0) { customUrl = null; }
        var exportFieldUrl = this.baseUrl + "jobevents/export/";
        if (customUrl && customUrl.length) {
            exportFieldUrl = "" + this.baseUrl + customUrl;
        }
        return this.http.get(exportFieldUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return lodash_1.sortBy(res, 'label'); }), operators_1.catchError(api_service_1.handleError));
    };
    JobEventService.prototype.export = function (body, query, endpointUrl) {
        if (query === void 0) { query = null; }
        if (endpointUrl === void 0) { endpointUrl = 'export/'; }
        var exportUrl = this.baseUrl + "jobevents/" + endpointUrl;
        var params = new http_1.HttpParams();
        if (query) {
            if (typeof query === 'string') {
                exportUrl = exportUrl + '?' + query;
            }
            else {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params = params.set(key, query[key].toString());
                    }
                });
            }
        }
        return this.http.post(exportUrl, body, {
            responseType: 'text',
            headers: api_service_1.requestHeaders(),
            params: params
        });
    };
    JobEventService.prototype.convertCondensedJobEvent = function (jobEvent) {
        return Object.assign(new job_event_1.JobEvent(jobEvent), {
            job: {
                id: jobEvent.jobId,
                name: jobEvent.jobName,
                deliveryInterval: jobEvent.deliveryInterval,
                orderNumber: jobEvent.orderNumber,
                project: { name: jobEvent.projectName },
                startLocation: {
                    id: jobEvent.startLocation,
                    name: jobEvent.startLocationName
                },
                endLocation: {
                    id: jobEvent.endLocation,
                    name: jobEvent.endLocationName
                }
            },
        });
    };
    JobEventService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (jobEvent) {
                return new job_event_1.JobEvent(jobEvent);
            });
        }
        else if (resObj) {
            return new job_event_1.JobEvent(resObj);
        }
    };
    JobEventService.prototype.extractMapData = function (res) {
        var resObj = res;
        if (resObj) {
            return new job_event_map_1.JobEventMap(resObj);
        }
    };
    /**
     * Allow update multiple job events in the same request
     * @param model - data to update in the job events
     * @param query - filters
     * @returns
     */
    JobEventService.prototype.bulkUpdate = function (model, query) {
        var params = app_utilities_1.AppUtilities.getParamsFromQuery(query);
        var updatedModel = new bulk_edit_job_events_serializers_1.BulkEditJobEventsSerializer().toJson(model);
        var jobEventsBulkUrl = this.baseUrl + 'jobevents/bulk-update/';
        return this.http.put(jobEventsBulkUrl, updatedModel, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (response) { return ({
            errors: response.permission_denied,
            success: response.updated
        }); }));
    };
    return JobEventService;
}());
exports.JobEventService = JobEventService;
