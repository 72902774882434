import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Driver } from '../../drivers/driver';
import { ResourceService } from '../../shared/resource.service';
import { DriverSerializer } from '../../drivers/driver.serializer';

@Injectable()
export class EndOfDayDriversService extends ResourceService<Driver> {
  constructor (http: HttpClient) {
    super(http, 'endofdaydrivers/', new DriverSerializer());
  }
}
