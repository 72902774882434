import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Location } from './location';
import { LocationStat } from './location-stat';
import { LocationSerializer } from './location.serializer';
import { ResourceService } from '../shared/resource.service';
import { requestHeaders, handleError } from '../shared/index';
import { environment } from '../../environments/environment';

@Injectable()
export class LocationService extends ResourceService<Location> {
  organizationId: string;
  senderId: string;
  baseUrl = environment.serverUrl;
  mapsApiKey = environment.googleMapsKey;

  constructor(http: HttpClient) {
    super(http, 'locations/', new LocationSerializer());
  }

  archive(location) {
    const locationUrl = this.baseUrl + 'locations/';

    return this.http.put(locationUrl + location.id + '/', { archived: true }, { headers: requestHeaders() });
  }

  unarchive(location) {
    const locationUrl = this.baseUrl + 'locations/';

    return this.http.put(locationUrl + location.id + '/', { archived: false }, { headers: requestHeaders() });
  }

  averageTimeSpent(query?: any): Observable<LocationStat[]> {
    let params: HttpParams = new HttpParams();
    if (query && typeof query === 'object') {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let locationsUrl = `${this.baseUrl}locations/average_time_spent/?${params.toString()}`;

    return this.http.get(locationsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractStatData(res); }),
      catchError((res: Response) => handleError(res))
    );
  }

  getLocationByIP() {
    return this.http.get('https://ipapi.co/json/').pipe(map(res => res));
  }

  getLocationByAddress(address) {
    let geocodeURL = 'https://maps.googleapis.com/maps/api/geocode/json';
    geocodeURL += '?address=' + address;
    geocodeURL += '&key=' + this.mapsApiKey;

    return this.http.get(geocodeURL).pipe(
      map((res) => {
        let resBody = res;
        return resBody['results'] || resBody;
      }),
      catchError((res: Response) => handleError(res))
    );
  }

  getAddressByCoordinates(coordinates) {
    let geocodeURL = 'https://maps.googleapis.com/maps/api/geocode/json';
    geocodeURL += '?latlng=' + coordinates;
    geocodeURL += '&key=' + this.mapsApiKey;

    return this.http.get(geocodeURL).pipe(
      map((res) => {
        let resBody = res;
        return resBody['results'] || resBody;
      }),
      catchError((res: Response) => handleError(res))
    );
  }

  getLocationTimezone(coordinates) {
    let timezoneURL = 'https://maps.googleapis.com/maps/api/timezone/json';
    const now = new Date();
    let params: HttpParams = new HttpParams();
    params = params.set('location', coordinates);
    params = params.set('timestamp', now.getUTCSeconds().toString());
    params = params.set('key', this.mapsApiKey);

    return this.http.get(timezoneURL, {
      params: params
    }).pipe(
      map((res) => { return res; }),
      catchError((res: Response) => handleError(res))
    );
  }

  private extractStatData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];

    if (body) {
      return body.map(location => {
        return new LocationStat(location);
      });
    } else if (resObj) {
      return new LocationStat(resObj);
    }
  }
}

