"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var environment_1 = require("../../environments/environment");
var resource_service_1 = require("../shared/resource.service");
var api_service_1 = require("../shared/api.service");
var job_serializer_1 = require("./job.serializer");
var bulk_edit_jobs_serializer_1 = require("./bulk-edit-jobs.serializer");
var JobService = /** @class */ (function (_super) {
    __extends(JobService, _super);
    function JobService(http) {
        var _this = _super.call(this, http, 'jobs/', new job_serializer_1.JobSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        return _this;
    }
    JobService.prototype.getDays = function (jobId) {
        var _this = this;
        var daysUrl = this.baseUrl + 'jobs/' + jobId + '/days/';
        return this.http.get(daysUrl, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractDays(res); }), operators_1.catchError(function (res) { return api_service_1.handleError(res); }));
    };
    JobService.prototype.updateDays = function (jobId, model) {
        var daysUrl = this.baseUrl + 'jobs/' + jobId + '/days/';
        return this.http.put(daysUrl, model, {
            headers: api_service_1.requestHeaders(), responseType: 'text'
        });
    };
    JobService.prototype.archive = function (job) {
        job.archived = true;
        var _job = { id: job.id, archived: job.archived };
        var jobUrl = this.baseUrl + 'jobs/';
        return this.http.put(jobUrl + _job.id + '/', _job, { headers: api_service_1.requestHeaders() });
    };
    JobService.prototype.unarchive = function (job) {
        job.archived = false;
        var _job = { id: job.id, archived: job.archived };
        var jobUrl = this.baseUrl + 'jobs/';
        return this.http.put(jobUrl + job.id + '/', _job, { headers: api_service_1.requestHeaders() });
    };
    JobService.prototype.bulkUpdate = function (model, query) {
        if (!model) {
            return rxjs_1.of();
        }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var serializer = new bulk_edit_jobs_serializer_1.BulkEditJobsSerialzier();
        var updatedmodel = serializer.toJson(model);
        var assigmentBulkUrl = this.baseUrl + 'jobs/bulk-update/';
        return this.http.put(assigmentBulkUrl, updatedmodel, {
            headers: api_service_1.requestHeaders(),
            params: params
        });
    };
    JobService.prototype.extractDays = function (res) {
        var resObj = res;
        return resObj['results'] || resObj;
    };
    return JobService;
}(resource_service_1.ResourceService));
exports.JobService = JobService;
