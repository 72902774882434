"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var material_1 = require("@angular/material");
var common_1 = require("@angular/common");
var lodash_1 = require("lodash");
var purchase_order_service_1 = require("../purchase-orders/purchase-order.service");
var index_1 = require("../shared/dialogs/index");
var connection_service_1 = require("../connections/connection.service");
var product_service_1 = require("../products/product.service");
var ticket_service_1 = require("./ticket.service");
var authentication_service_1 = require("../shared/authentication.service");
var scale_service_1 = require("../scales/scale.service");
var tare_weight_service_1 = require("../tare-weights/tare-weight.service");
var driver_service_1 = require("../drivers/driver.service");
var surcharge_template_service_1 = require("../surcharge-templates/surcharge-template.service");
var api_service_1 = require("../shared/api.service");
var scale_1 = require("../scales/scale");
var surcharge_serializer_1 = require("../surcharges/surcharge.serializer");
var surcharge_service_1 = require("../surcharges/surcharge.service");
var price_service_1 = require("../prices/price.service");
var void_ticket_dialog_component_1 = require("./void-ticket-dialog.component");
var shared_1 = require("../scaleit/shared");
var condensed_ticket_serializer_1 = require("./condensed-ticket.serializer");
var condensed_ticket_service_1 = require("./condensed-ticket.service");
var deepEqual = require('deep-equal');
var TicketFormComponent = /** @class */ (function () {
    function TicketFormComponent(route, router, purchaseOrderService, productService, ticketService, condensedTicketService, scaleService, connectionService, tareWeightService, driverService, surchargeTemplateService, surchargeService, authenticationService, priceService, dialog, decimalPipe) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.purchaseOrderService = purchaseOrderService;
        this.productService = productService;
        this.ticketService = ticketService;
        this.condensedTicketService = condensedTicketService;
        this.scaleService = scaleService;
        this.connectionService = connectionService;
        this.tareWeightService = tareWeightService;
        this.driverService = driverService;
        this.surchargeTemplateService = surchargeTemplateService;
        this.surchargeService = surchargeService;
        this.authenticationService = authenticationService;
        this.priceService = priceService;
        this.dialog = dialog;
        this.decimalPipe = decimalPipe;
        this.onTicketVoided = new core_1.EventEmitter();
        this.onTicketSaved = new core_1.EventEmitter();
        this.onTicketFinished = new core_1.EventEmitter();
        this.onNotification = new core_1.EventEmitter();
        this.errors = [];
        this.notificationTimeout = 3000;
        this.ticketInOpenState = false;
        this.ticketInProgressState = false;
        this.ticketInFinishedHold = false;
        this.ticketInHoldState = false;
        this.currentTicket = null;
        this.originalTicket = null;
        this.onHold = false;
        this.licensePlate = null;
        this.unitPrice = null;
        this.customDriver = false;
        this.haulerName = null;
        this.discount = null;
        this.notes = null;
        this.formEnabled = false;
        this.voidButtonloading = false;
        this.finishButtonloading = false;
        this.selectedDriver = null;
        this.selectedCarrier = null;
        this.minLicensePlateLength = 1;
        this.searchStatusToSearch = true;
        this.searchStatusSearching = false;
        this.searchStatusNotFound = false;
        this.customers = [];
        this.selectedCustomer = null;
        this.customerDropdownConfig = {
            searchable: true,
            loadingOptions: false,
            nameProperty: 'name'
        };
        this.customerDropdownEnabled = false;
        this.drivers = [];
        this.driverDropdownConfig = {
            searchable: true,
            loadingOptions: false,
            nameProperty: 'name'
        };
        this.driverDropdownEnabled = false;
        this.purchaseOrders = [];
        this.selectedPurchaseOrder = null;
        this.purchaseOrderDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.purchaseOrderDropdownEnabled = false;
        this.products = [];
        this.selectedProduct = null;
        this.productDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.paymentTypeNone = { id: null, name: 'None' };
        this.paymentTypes = [
            { id: 'cash', name: 'Cash' },
            { id: 'check', name: 'Check' },
            { id: 'card', name: 'Card' },
            this.paymentTypeNone
        ];
        this.storedWeights = [];
        this.tareDropdownConfig = {
            width: 288,
            loadingScales: true,
            group: true,
            groupBy: 'weightType'
        };
        this.grossDropdownConfig = {
            width: 288,
            loadingScales: true,
            group: true,
            groupBy: 'weightType'
        };
        this.tareScale = null;
        this.tareWeight = null;
        this.grossScale = null;
        this.grossWeight = null;
        this.correctionWeight = null;
        this.defaultWeightUnit = 'lbs';
        this.netWeightUnit = 'lbs';
        this.netWeight = 0;
        this.surchargeTemplates = [];
        this.surchargeTemplateDropdownConfig = {
            searchable: true,
            loadingOptions: false,
            nameProperty: 'title'
        };
        this.priceList = null;
        this.searchChanged = new rxjs_1.Subject();
        this.backgroundColor = 'rgba(234, 238, 241, 1)';
        this.voidTicketCallback = function (ticket) {
            _this.onTicketVoided.emit(ticket);
            _this.resetEntireForm();
        };
    }
    TicketFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var seller = this.authenticationService.getOrganization();
        this.currentTicket = new condensed_ticket_serializer_1.CondensedTicketSerializer().fromJson({
            surcharges: [(new surcharge_serializer_1.SurchargeSerializer).fromJson({})],
            sellerOrganization: seller
        });
        this.originalTicket = lodash_1.cloneDeep(this.currentTicket);
        this.setDefaultDate();
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (data) {
            _this.dropdownSearch(data[0], data[1]);
        });
    };
    TicketFormComponent.prototype.ngOnDestroy = function () {
        if (this.productsReq) {
            this.productsReq.unsubscribe();
        }
        if (this.scalesReq) {
            this.scalesReq.unsubscribe();
        }
        if (this.tareWeightsReq) {
            this.tareWeightsReq.unsubscribe();
        }
        if (this.customersReq) {
            this.customersReq.unsubscribe();
        }
        if (this.driversReq) {
            this.driversReq.unsubscribe();
        }
        if (this.purchaseOrdersReq) {
            this.purchaseOrdersReq.unsubscribe();
        }
    };
    TicketFormComponent.prototype.handleOnBeforeUnload = function ($event) {
        if (this.hasUnsavedChanges && this.hasUnsavedChanges()) {
            // For IE and Firefox prior to version 4
            if ($event) {
                $event.returnValue = 'You have unsaved changes!';
            }
            // For Safari
            return 'You have unsaved changes!';
        }
    };
    TicketFormComponent.prototype.setDefaultDate = function () {
        this.deliveryDate = new Date();
    };
    TicketFormComponent.prototype.deliveryDateChanged = function (values) {
        if (values && values.length) {
            this.deliveryDate = values[0];
            var date = new Date(this.deliveryDate);
            this.currentTicket.deliveryTime = date.toISOString();
            this.saveTicket();
        }
    };
    TicketFormComponent.prototype.handleSearchOnEnter = function (target) {
        if (target) {
            target.blur();
        }
    };
    TicketFormComponent.prototype.setSearchStatusToSearch = function () {
        this.searchStatusToSearch = true;
        this.searchStatusSearching = false;
        this.searchStatusNotFound = false;
    };
    TicketFormComponent.prototype.setSearchStatusSearching = function () {
        this.searchStatusToSearch = false;
        this.searchStatusSearching = true;
        this.searchStatusNotFound = false;
    };
    TicketFormComponent.prototype.setSearchStatusNotFound = function () {
        this.searchStatusToSearch = false;
        this.searchStatusSearching = false;
        this.searchStatusNotFound = true;
    };
    TicketFormComponent.prototype.getProducts = function (query, saveTicket, resetCustomPrice) {
        var _this = this;
        if (query === void 0) { query = null; }
        if (saveTicket === void 0) { saveTicket = false; }
        if (resetCustomPrice === void 0) { resetCustomPrice = false; }
        if (this.productsReq) {
            this.productsReq.unsubscribe();
        }
        this.productService.list(__assign({ purchase_order: this.selectedPurchaseOrder && this.selectedPurchaseOrder.id }, query)).subscribe(function (products) {
            _this.products = products;
            if (_this.products.length > 0) {
                if (_this.products.length === 1 && !_this.selectedProduct) {
                    _this.selectProduct(products[0], saveTicket, resetCustomPrice);
                }
                else if (_this.selectedProduct) {
                    _this.selectProduct(_this.selectedProduct, saveTicket, resetCustomPrice);
                }
            }
            else if (!_this.selectedProduct) {
                _this.selectedProduct = null;
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
        if (saveTicket) {
            this.saveTicket();
        }
    };
    TicketFormComponent.prototype.onScaleDropdownToggle = function (isOpen) {
        if (isOpen) {
            this.getScales();
        }
    };
    // Todo: Add support for Deselection
    TicketFormComponent.prototype.selectTareWeight = function (scale) {
        if (Object.keys(scale).includes('scale')) {
            this.tareScale = scale.scale;
        }
        else {
            this.tareScale = scale;
        }
        this.tareWeight = this.tareScale.weight.toString();
        this.defaultWeightUnit = scale.weightUnit;
        this.currentTicket.tareWeight = this.tareWeight;
        this.currentTicket.tareWeightScaleId = this.tareScale.id;
        this.currentTicket.tareWeightScale = this.tareScale;
        this.currentTicket.tareWeightTime = new Date().toISOString();
        this.currentTicket.tareUnit = this.defaultWeightUnit;
        this.saveTicket();
    };
    TicketFormComponent.prototype.selectGrossWeight = function (scale) {
        this.grossScale = scale;
        this.grossWeight = scale.weight.toString();
        this.defaultWeightUnit = scale.weightUnit;
        this.currentTicket.grossWeight = this.grossWeight;
        this.currentTicket.grossWeightScaleId = this.grossScale.id;
        this.currentTicket.grossWeightScale = this.grossScale;
        this.currentTicket.grossWeightTime = new Date().toISOString();
        this.currentTicket.grossUnit = this.defaultWeightUnit;
        this.saveTicket();
    };
    TicketFormComponent.prototype.getClient = function (id) {
        var _this = this;
        this.connectionService.get(id).subscribe(function (clients) {
            if (clients) {
                // this.setSelectedCustomer(clients[0]);
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    TicketFormComponent.prototype.resetEntireForm = function () {
        this.resetFormFields();
        this.setDefaultDate();
        this.selectedProduct = null;
        this.selectedDriver = null;
        this.customDriver = false;
        this.discount = null;
        this.notes = null;
        this.tareScale = null;
        this.tareWeight = null;
        this.grossScale = null;
        this.grossWeight = null;
        this.correctionWeight = null;
        this.netWeight = 0;
        this.netWeightUnit = this.defaultWeightUnit;
    };
    TicketFormComponent.prototype.resetFormFields = function () {
        this.formEnabled = false;
        this.currentTicket = new condensed_ticket_serializer_1.CondensedTicketSerializer().fromJson({});
        this.originalTicket = lodash_1.cloneDeep(this.currentTicket);
        this.unitPrice = null;
        this.purchaseOrders = [];
        this.selectedPurchaseOrder = null;
        this.purchaseOrderDropdownEnabled = false;
        this.customers = [];
        this.selectedCustomer = null;
        this.customerDropdownEnabled = false;
        this.driverDropdownEnabled = false;
        this.licensePlate = '';
        this.haulerName = '';
        this.discount = this.hasNoValue(this.discount) ? '0' : this.discount;
        this.onHold = false;
    };
    TicketFormComponent.prototype.validToFinish = function () {
        if (this.currentTicket.onHold ||
            !this.currentTicket.customerId ||
            !this.currentTicket.productId ||
            !this.tareScale ||
            !this.grossScale ||
            this.hasNoValue(this.currentTicket.tareWeight) ||
            this.hasNoValue(this.currentTicket.grossWeight)) {
            return false;
        }
        return true;
    };
    TicketFormComponent.prototype.hasNoValue = function (objToCheck) {
        switch (typeof objToCheck) {
            case 'string':
                return (objToCheck === undefined || objToCheck === null || objToCheck === '');
            default:
                return objToCheck === undefined || objToCheck === null;
        }
    };
    TicketFormComponent.prototype.hasValue = function (objToCheck) {
        return !this.hasNoValue(objToCheck);
    };
    TicketFormComponent.prototype.newTicket = function () {
        var _this = this;
        if (this.hasUnsavedChanges()) {
            this.warnUnsavedChanges().subscribe(function (dialogResult) {
                switch (dialogResult) {
                    case shared_1.ScaleitConfirmDialogResult.No:
                        _this.resetEntireForm();
                        break;
                    case shared_1.ScaleitConfirmDialogResult.Yes:
                        _this.saveTicket(true);
                        break;
                }
            });
        }
        else {
            this.resetEntireForm();
        }
    };
    TicketFormComponent.prototype.saveTicket = function (resetOnSuccess, objectOverrides) {
        var _this = this;
        if (resetOnSuccess === void 0) { resetOnSuccess = false; }
        if (objectOverrides === void 0) { objectOverrides = {}; }
        var currentTicket = lodash_1.cloneDeep(this.currentTicket);
        if (!currentTicket.customUnitPrice || currentTicket.customUnitPrice === '') {
            currentTicket.customUnitPrice = Number(currentTicket.unitPrice) < 0 ? 0 : currentTicket.unitPrice;
        }
        if (currentTicket.customUnitPrice === currentTicket.originalUnitPrice) {
            delete currentTicket.customUnitPrice;
        }
        var seller = this.authenticationService.getOrganization();
        currentTicket.seller = seller.id;
        currentTicket.sellerOrganization = seller.id;
        currentTicket.sellerId = seller.id;
        currentTicket.customerOrganization = currentTicket.customerId;
        currentTicket = Object.assign(currentTicket, objectOverrides);
        delete currentTicket.surcharges;
        return new rxjs_1.Observable(function (observer) {
            _this.condensedTicketService.saveTicket(currentTicket).subscribe(function (ticket) {
                _this.unitPrice = ticket.customUnitPrice || ticket.unitPrice;
                _this.currentTicket = ticket;
                if (_this.customDriver) {
                    _this.currentTicket.driverName = ticket.driver;
                }
                _this.stubSurcharges();
                _this.onTicketSaved.emit(_this.currentTicket);
                if (_this.currentTicket.statusText === 'On Hold') {
                    _this.currentTicket.onHold = true;
                }
                if (resetOnSuccess) {
                    _this.onTicketFinished.emit(resetOnSuccess);
                    _this.resetEntireForm();
                }
                observer.next(true);
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                observer.next(false);
            });
        });
    };
    TicketFormComponent.prototype.setUnitPrice = function () {
        if (this.unitPrice !== this.currentTicket.unitPrice) {
            this.currentTicket.customUnitPrice = this.unitPrice;
        }
        else {
            this.currentTicket.customUnitPrice = null;
        }
        this.saveTicket();
    };
    TicketFormComponent.prototype.saveSurcharges = function (surcharges) {
        var _this = this;
        if (surcharges === void 0) { surcharges = null; }
        if (!surcharges) {
            surcharges = this.currentTicket && this.currentTicket.surcharges;
        }
        else if (!Array.isArray(surcharges)) {
            surcharges = [surcharges];
        }
        surcharges.forEach(function (surcharge) {
            _this.surchargeService.save(surcharge).subscribe(function (_surcharge) {
                surcharge = _surcharge;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        });
    };
    TicketFormComponent.prototype.finishTicket = function () {
        var _this = this;
        if (this.currentTicket) {
            if (parseFloat(this.currentTicket.net) !== 0.0) {
                this.saveTicket(true, { status: 'complete' });
            }
            else {
                this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
                    width: '430px',
                    height: '280px'
                });
                this.confirmDialog.componentInstance.attributes = {
                    title: 'Warning',
                    body: 'Net is 0. Are you sure you want to finish the ticket?',
                    close: 'No',
                    accept: 'Yes'
                };
                this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                    if (dialogResult) {
                        _this.saveTicket(true, { status: 'complete' });
                    }
                    _this.confirmDialog = null;
                });
            }
        }
    };
    TicketFormComponent.prototype.toggleHold = function (event) {
        var _this = this;
        if (this.currentTicket) {
            if (this.currentTicket.statusText === 'On Hold') {
                this.saveTicket(false, { status: 'in-progress' });
            }
            else {
                this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
                    width: '430px',
                    height: '280px'
                });
                this.confirmDialog.componentInstance.attributes = {
                    title: 'Warning',
                    body: 'A ticket cannot be completed while it is on hold. Are you sure you want to put this ticket on hold?',
                    close: 'No',
                    accept: 'Yes'
                };
                this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                    if (dialogResult) {
                        _this.onHold = true;
                        _this.currentTicket.onHold = true;
                        _this.saveTicket(false, { status: 'on-hold' });
                    }
                    else {
                        _this.onHold = false;
                        _this.currentTicket.onHold = false;
                    }
                    _this.confirmDialog = null;
                });
            }
        }
    };
    TicketFormComponent.prototype.showNotification = function (notificationMessage, notificationLevel, notificationTimeout) {
        if (notificationTimeout === void 0) { notificationTimeout = this.notificationTimeout; }
        this.onNotification.emit({
            message: notificationMessage,
            level: notificationLevel,
            timeout: notificationTimeout
        });
    };
    TicketFormComponent.prototype.hasUnsavedChanges = function () {
        var currentTicket = lodash_1.omit(this.currentTicket, ['productUnitOfMeasurement', 'surcharges']);
        var originalTicket = lodash_1.omit(this.originalTicket, ['productUnitOfMeasurement', 'surcharges']);
        var changedValues = lodash_1.reduce(originalTicket, function (result, value, key) {
            return deepEqual(value, currentTicket[key]) ?
                result : result.concat(key);
        }, []);
        console.log('changed values: ', changedValues);
        return deepEqual(originalTicket, currentTicket) ? false : true;
    };
    TicketFormComponent.prototype.warnUnsavedChanges = function () {
        this.confirmDialog = this.dialog.open(shared_1.ScaleitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Warning',
            body: 'Would you like to save the changes made to this ticket?',
            yes: 'Yes',
            no: 'No'
        };
        var observable = this.confirmDialog.afterClosed();
        this.confirmDialog = null;
        return observable;
    };
    TicketFormComponent.prototype.autoFillFormWithTicket = function (ticket, saveTicket) {
        if (saveTicket === void 0) { saveTicket = false; }
        this.resetEntireForm();
        this.parseCondensedTicket(ticket);
        if (saveTicket) {
            this.saveTicket();
        }
        this.formEnabled = true;
        this.getCustomers();
        this.getDrivers();
        this.getScales();
        this.getSurchargeTemplates();
        this.getProducts();
    };
    TicketFormComponent.prototype.parseCondensedTicket = function (ticket) {
        this.currentTicket = ticket;
        if (this.currentTicket.deliveryDatetime) {
            var date = new Date(this.currentTicket.deliveryDatetime);
            this.deliveryDate = date;
        }
        this.notes = ticket.notes;
        if (ticket.tareWeightScaleId) {
            this.tareScale = new scale_1.Scale({
                id: ticket.tareWeightScaleId, name: ticket.tareWeightScale,
                weightUnit: ticket.tareWeightUnit
            });
            this.tareWeight = ticket.tareWeight;
        }
        if (ticket.grossWeightScaleId) {
            this.grossScale = new scale_1.Scale({
                id: ticket.grossWeightScaleId, name: ticket.grossWeightScale,
                weightUnit: ticket.grossWeightUnit
            });
            this.grossWeight = ticket.grossWeight;
        }
        this.correctionWeight = ticket.correctionWeight;
        this.netWeight = ticket.net || 0;
        this.netWeightUnit = ticket.netWeightUnit || this.defaultWeightUnit;
        this.selectedPurchaseOrder = {
            id: ticket.purchaseOrderId, name: ticket.purchaseOrder || '-'
        };
        this.selectedProduct = {
            id: ticket.productId, name: ticket.product || ''
        };
        this.selectCustomer({
            id: ticket.customerId, name: ticket.customer
        }, false);
        if (ticket.driver && ticket.driver.length && !ticket.driverId) {
            this.customDriver = true;
            this.currentTicket.driverName = ticket.driver;
        }
        else {
            this.customDriver = false;
            this.selectDriver({ id: ticket.driverId, name: ticket.driver }, false);
        }
        this.selectCarrier({
            id: ticket.truckCarrierId, name: ticket.carrier
        }, false);
        this.onHold = this.currentTicket.onHold;
        this.originalTicket = lodash_1.cloneDeep(this.currentTicket);
        this.unitPrice = this.currentTicket.customUnitPrice || this.currentTicket.unitPrice;
    };
    TicketFormComponent.prototype.warnExistingTicket = function () {
        this.confirmDialog = this.dialog.open(shared_1.ScaleitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Warning',
            body: 'An incomplete ticket for that truck already exists. Would you like to open and continue editing it?',
            yes: 'Yes',
            no: 'No'
        };
        var observable = this.confirmDialog.afterClosed();
        this.confirmDialog = null;
        return observable;
    };
    TicketFormComponent.prototype.checkUnsavedForNavigation = function () {
        var _this = this;
        if (this.hasUnsavedChanges()) {
            return new rxjs_1.Observable(function (observer) {
                _this.warnUnsavedChanges().subscribe(function (dialogResult) {
                    switch (dialogResult) {
                        case shared_1.ScaleitConfirmDialogResult.No:
                            _this.resetEntireForm();
                            observer.next(true);
                            break;
                        case shared_1.ScaleitConfirmDialogResult.Yes:
                            _this.saveTicket(true);
                            break;
                        default:
                            observer.next(false);
                    }
                });
            });
        }
        else {
            return rxjs_1.of(true);
        }
    };
    TicketFormComponent.prototype.getScales = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.scalesReq) {
            this.scalesReq.unsubscribe();
        }
        this.scalesReq = this.scaleService.getScales(__assign({ ordering: 'name' }, query)).subscribe(function (scales) {
            _this.tareScales = scales;
            _this.grossScales = scales;
            _this.grossDropdownConfig.loadingScales = false;
            _this.grossDropdownConfig = lodash_1.cloneDeep(_this.grossDropdownConfig);
            if (_this.storedWeights && _this.storedWeights.length > 0) {
                _this.tareScales = _this.tareScales.concat(_this.storedWeights);
                _this.tareDropdownConfig.loadingScales = false;
                _this.tareDropdownConfig = lodash_1.cloneDeep(_this.tareDropdownConfig);
            }
            else {
                _this.getTareWeights();
            }
        }, function (err) {
            _this.grossDropdownConfig.loadingScales = false;
            _this.tareDropdownConfig.loadingScales = false;
            _this.grossDropdownConfig = lodash_1.cloneDeep(_this.grossDropdownConfig);
            _this.tareDropdownConfig = lodash_1.cloneDeep(_this.tareDropdownConfig);
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    TicketFormComponent.prototype.getTareWeights = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.tareWeightsReq) {
            this.tareWeightsReq.unsubscribe();
        }
        this.tareWeightsReq = this.tareWeightService.getTareWeights(__assign({ ordering: 'name', truck: this.currentTicket && this.currentTicket.truckId }, query)).subscribe(function (tareWeights) {
            _this.storedWeights = tareWeights;
            _this.tareScales = _this.tareScales.concat(tareWeights);
            _this.tareDropdownConfig.loadingScales = false;
            _this.tareDropdownConfig = lodash_1.cloneDeep(_this.tareDropdownConfig);
        }, function (err) {
            _this.tareDropdownConfig.loadingScales = false;
            _this.tareDropdownConfig = lodash_1.cloneDeep(_this.tareDropdownConfig);
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    TicketFormComponent.prototype.getCustomers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.customersReq) {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.connectionService.list(__assign({ status: 'active' }, query)).subscribe(function (connections) {
            if (connections.length > 0) {
                var organizations = connections.map(function (connection) {
                    var organization = connection.organization;
                    organization.posPriceList = connection.posPriceList;
                    organization['paymentType'] = connection.posPaymentType;
                    return organization;
                });
                _this.customerDropdownEnabled = true;
                _this.customers = organizations;
                if (_this.customers.length > 0) {
                    if (_this.customers.length === 1 && !_this.selectedCustomer) {
                        _this.selectedCustomer = _this.customers[0];
                        _this.currentTicket.paymentType = _this.customers[0].paymentType;
                    }
                    else if (_this.selectedCustomer) {
                        var _customer = lodash_1.find(_this.customers, { id: _this.selectedCustomer.id });
                        _this.selectedCustomer = _customer;
                        _this.currentTicket.paymentType = _customer.paymentType;
                    }
                }
            }
            else {
                if (_this.currentTicket && _this.currentTicket.customerId) {
                    _this.selectedCustomer = {
                        id: _this.currentTicket.customerId,
                        name: _this.currentTicket.customer
                    };
                    _this.customers = [_this.selectedCustomer];
                }
                else {
                    _this.customers = [{ id: null, name: 'No Results Found' }];
                }
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    TicketFormComponent.prototype.getDrivers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.drivers = [{ id: null, name: 'New Driver', button: true }];
        if (this.currentTicket && this.currentTicket.truckCarrierId) {
            var organization = this.authenticationService.getOrganization();
            if (organization && organization.id !== this.currentTicket.truckCarrierId) {
                query = Object.assign(query, {
                    organization: this.currentTicket && this.currentTicket.truckCarrierId
                });
            }
        }
        if (this.driversReq) {
            this.driversReq.unsubscribe();
        }
        this.driversReq = this.driverService.list(__assign({}, query)).subscribe(function (drivers) {
            _this.drivers = _this.drivers.concat(drivers);
            _this.driverDropdownEnabled = true;
            if (_this.drivers.length > 0) {
                if (_this.drivers.length === 1 && !_this.selectedDriver) {
                    _this.selectedDriver = drivers[0];
                }
            }
            else {
                if (_this.currentTicket) {
                    _this.drivers = [_this.currentTicket.driverId];
                    _this.selectedDriver = {
                        id: _this.currentTicket.driverId, name: _this.currentTicket.driver
                    };
                }
                else {
                    _this.drivers = [{ id: null, name: 'No Results Found' }];
                }
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.resetFormFields();
        });
    };
    TicketFormComponent.prototype.getPurchaseOrders = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.purchaseOrdersReq) {
            this.purchaseOrdersReq.unsubscribe();
        }
        this.purchaseOrdersReq = this.purchaseOrderService.list(__assign({ customer_organization: this.selectedCustomer && this.selectedCustomer.id }, query)).subscribe(function (purchaseOrders) {
            _this.purchaseOrders = [{ id: null, name: '-' }].concat(purchaseOrders);
            _this.purchaseOrderDropdownEnabled = true;
            if (_this.purchaseOrders.length > 0) {
                if (_this.selectedPurchaseOrder) {
                    var _purchaseOrder = lodash_1.find(_this.purchaseOrders, { id: _this.selectedPurchaseOrder.id });
                    if (_purchaseOrder) {
                        _this.selectPurchaseOrder(_purchaseOrder, false);
                    }
                }
            }
            else {
                _this.purchaseOrderDropdownEnabled = false;
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    TicketFormComponent.prototype.getSurchargeTemplates = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.surchargeTemplatesReq) {
            this.surchargeTemplatesReq.unsubscribe();
        }
        this.surchargeTemplatesReq = this.surchargeTemplateService.list(__assign({ kind: 'scaleticket' }, query)).subscribe(function (surchargeTemplates) {
            _this.surchargeTemplates = surchargeTemplates;
            _this.stubSurcharges();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    TicketFormComponent.prototype.stubSurcharges = function () {
        if (!this.currentTicket.surcharges || !this.currentTicket.surcharges.length) {
            this.currentTicket.surcharges = [(new surcharge_serializer_1.SurchargeSerializer).fromJson({
                    scaleticket: this.currentTicket.id, kind: 'scaleticket'
                })];
        }
    };
    TicketFormComponent.prototype.selectCustomer = function (customer, saveTicket, resetCustomPrice) {
        if (saveTicket === void 0) { saveTicket = true; }
        if (resetCustomPrice === void 0) { resetCustomPrice = false; }
        if (this.currentTicket && customer && customer.id) {
            if (this.currentTicket.customerId !== customer.id) {
                this.currentTicket.purchaseOrderId = null;
                this.selectedPurchaseOrder = { id: null, name: '-' };
            }
            this.selectedCustomer = customer;
            this.currentTicket.customerId = this.selectedCustomer.id;
            this.currentTicket.customer = this.selectedCustomer.name;
            if (!this.currentTicket.paymentType && customer.customerPaymentType) {
                this.currentTicket.paymentType = customer.customerPaymentType;
            }
            if (resetCustomPrice) {
                this.currentTicket.customUnitPrice = null;
                this.unitPrice = this.currentTicket.unitPrice;
            }
            this.getPurchaseOrders();
            if (saveTicket) {
                this.saveTicket();
            }
        }
    };
    TicketFormComponent.prototype.selectDriver = function (driver, saveTicket) {
        if (saveTicket === void 0) { saveTicket = true; }
        if (this.currentTicket && driver && driver.name && driver.name.length) {
            if (driver.name === 'New Driver') {
                this.customDriver = true;
                this.currentTicket.driver = null;
                this.currentTicket.driverId = null;
                this.currentTicket.driverImage = null;
                this.currentTicket.driverPhone = null;
                this.currentTicket.driverName = null;
                this.selectedDriver = null;
                saveTicket = false;
            }
            else {
                this.selectedDriver = driver;
                this.currentTicket.driver = this.selectedDriver.name;
                this.currentTicket.driverId = this.selectedDriver.id;
                this.currentTicket.driverImage = this.selectedDriver.driverImage;
                this.currentTicket.driverPhone = this.selectedDriver.driverPhone;
                this.currentTicket.driverName = null;
            }
            if (saveTicket) {
                this.saveTicket();
            }
        }
    };
    TicketFormComponent.prototype.selectCarrier = function (carrier, saveTicket) {
        if (saveTicket === void 0) { saveTicket = true; }
        if (this.currentTicket) {
            this.selectedCarrier = carrier;
            this.currentTicket.carrierId = this.selectedCarrier.id;
            this.currentTicket.carrier = this.selectedCarrier.name;
            this.haulerName = this.selectedCarrier && this.selectedCarrier.name || '';
            if (saveTicket) {
                this.saveTicket();
            }
        }
    };
    TicketFormComponent.prototype.selectPurchaseOrder = function (purchaseOrder, saveTicket, resetCustomPrice) {
        if (saveTicket === void 0) { saveTicket = true; }
        if (resetCustomPrice === void 0) { resetCustomPrice = false; }
        if (this.currentTicket && purchaseOrder && purchaseOrder.id) {
            this.selectedPurchaseOrder = purchaseOrder;
            this.currentTicket.purchaseOrder = this.selectedPurchaseOrder.id;
            if (this.selectedPurchaseOrder) {
                this.currentTicket.purchaseOrderId = this.selectedPurchaseOrder.id;
            }
            if (this.selectedPurchaseOrder && this.selectedPurchaseOrder.product) {
                this.selectedProduct = {
                    id: this.selectedPurchaseOrder.product.id,
                    name: this.selectedPurchaseOrder.product.name
                };
                this.currentTicket.product = this.selectedProduct.name;
                this.currentTicket.productId = this.selectedProduct.id;
            }
            this.getProducts({
                purchase_order: this.selectedPurchaseOrder && this.selectedPurchaseOrder.id
            }, false, resetCustomPrice);
        }
        else if (purchaseOrder && purchaseOrder.name === '-') {
            this.selectedPurchaseOrder = { id: null, name: '-' };
            this.currentTicket.purchaseOrderId = null;
            this.getProducts();
        }
        else {
            this.getProducts();
        }
        if (resetCustomPrice) {
            this.currentTicket.customUnitPrice = null;
            this.unitPrice = this.currentTicket.unitPrice;
        }
        if (saveTicket) {
            this.saveTicket();
        }
    };
    TicketFormComponent.prototype.selectProduct = function (product, saveTicket, resetCustomPrice) {
        if (saveTicket === void 0) { saveTicket = true; }
        if (resetCustomPrice === void 0) { resetCustomPrice = false; }
        if (this.currentTicket && product && product.id) {
            this.selectedProduct = { id: product.id, name: product.name };
            this.currentTicket.productId = this.selectedProduct.id;
            this.currentTicket.productUnitOfMeasurement = this.selectedProduct.unitOfMeasurement;
            if (resetCustomPrice) {
                this.currentTicket.customUnitPrice = null;
                this.unitPrice = this.currentTicket.unitPrice;
            }
            if (saveTicket) {
                this.saveTicket();
            }
        }
    };
    TicketFormComponent.prototype.selectPaymentType = function (paymentType) {
        if (this.currentTicket) {
            this.currentTicket.paymentType = paymentType;
            this.saveTicket();
        }
    };
    TicketFormComponent.prototype.selectSurchargeTemplate = function (surchargeTemplate, index) {
        if (this.currentTicket) {
            if (surchargeTemplate.id === 'new_surcharge') {
                //
            }
            else {
                var surcharge = this.currentTicket.surcharges[index];
                if (surcharge) {
                    surcharge.title = surchargeTemplate.title;
                    surcharge.amount = surchargeTemplate.amount;
                    surcharge.scaleticket = this.currentTicket.id;
                    surcharge.kind = 'scaleticket';
                    surcharge.description = surchargeTemplate.description;
                    this.saveSurcharges([(new surcharge_serializer_1.SurchargeSerializer().fromJson(surcharge))]);
                }
            }
            this.saveTicket();
        }
    };
    TicketFormComponent.prototype.delaySearch = function (term, type) {
        this.searchChanged.next([term, type]);
    };
    TicketFormComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'customer':
                this.getCustomers({ search: term });
                break;
            case 'driver':
                this.getDrivers({ search: term });
                break;
            case 'product':
                this.getProducts({ search: term });
                break;
            case 'purchaseOrder':
                this.getPurchaseOrders({ search: term });
                break;
            case 'surchargeTemplate':
                this.getSurchargeTemplates({ search: term });
                break;
        }
    };
    TicketFormComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'customer':
                config = this.customerDropdownConfig;
                service = this.connectionService;
                options = this.customers;
                break;
            case 'driver':
                config = this.driverDropdownConfig;
                service = this.driverService;
                options = this.drivers;
                break;
            case 'product':
                config = this.productDropdownConfig;
                service = this.productService;
                options = this.products;
                break;
            case 'purchaseOrder':
                config = this.purchaseOrderDropdownConfig;
                service = this.purchaseOrderService;
                options = this.purchaseOrders;
                break;
            case 'surchargeTemplate':
                config = this.surchargeTemplateDropdownConfig;
                service = this.surchargeTemplateService;
                options = this.surchargeTemplates;
                break;
        }
        if (!config.loadingOptions) {
            var o = void 0;
            switch (type) {
                case 'customer':
                    o = service.listNext();
                    break;
                case 'driver':
                    o = service.listNext();
                    break;
                case 'product':
                    o = service.listNext();
                    break;
                case 'surchargeTemplate':
                    o = service.listNext();
                    break;
                default:
                    o = service.getNext();
                    break;
            }
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'customer':
                            _this.customers = _this.customers.concat(results);
                            break;
                        case 'driver':
                            _this.drivers = _this.drivers.concat(results);
                            break;
                        case 'product':
                            _this.products = _this.products.concat(results);
                            break;
                        case 'purchaseOrder':
                            _this.purchaseOrders = _this.purchaseOrders.concat(results);
                            break;
                        case 'surchargeTemplate':
                            _this.surchargeTemplates = _this.surchargeTemplates.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    TicketFormComponent.prototype.deleteSurcharge = function (surcharge, index) {
        var _this = this;
        if (surcharge.id) {
            this.surchargeService.remove(surcharge).subscribe(function () {
                _this.currentTicket.surcharges.pop(index);
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
        else {
            this.currentTicket.surcharges.pop(index);
        }
    };
    TicketFormComponent.prototype.clearSurcharge = function (surcharge, index) {
        var _this = this;
        if (surcharge.id) {
            this.surchargeService.remove(surcharge).subscribe(function () {
                _this.currentTicket.surcharges[index] = (new surcharge_serializer_1.SurchargeSerializer).fromJson({
                    scaleticket: _this.currentTicket.id, kind: 'scaleticket'
                });
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
        else {
            this.currentTicket.surcharges[index] = (new surcharge_serializer_1.SurchargeSerializer).fromJson({
                scaleticket: this.currentTicket.id, kind: 'scaleticket'
            });
        }
    };
    TicketFormComponent.prototype.addSurcharge = function () {
        this.currentTicket.surcharges.push((new surcharge_serializer_1.SurchargeSerializer).fromJson({
            scaleticket: this.currentTicket.id, kind: 'scaleticket'
        }));
    };
    TicketFormComponent.prototype.voidTicket = function () {
        var _this = this;
        this.voidDialog = this.dialog.open(void_ticket_dialog_component_1.VoidTicketDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.voidDialog.componentInstance.ticket = this.currentTicket;
        this.voidDialog.componentInstance.callback = this.voidTicketCallback;
        this.voidDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                //
            }
            _this.voidDialog = null;
        });
    };
    return TicketFormComponent;
}());
exports.TicketFormComponent = TicketFormComponent;
