import { remove, find, clone } from 'lodash';
import * as moment from 'moment';

import { Job } from '../jobs/job';
import { Organization } from '../organizations/organization';
import { CondensedTrip } from '../trips/condensed-trip';
import { CondensedPunchCard } from '../punch-cards/condensed-punch-card';
import { JobSerializer } from '../jobs/job.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Expense {
  id: string;
  number: string;
  amount: string;
  expenseAmount: string;
  surchargeAmount: string;
  tripsAmount: string;
  tripsExpenseAmount: string;
  punchcardsAmount: string;
  punchcardsExpenseAmount: string;
  subtotal: string;
  ownerOrganization: Organization;
  customerOrganization: Organization;
  job: Job;
  sendable: boolean;
  sent: boolean;
  draft: boolean;
  sentTime: string;
  sentBy: string;
  sentTrips: CondensedTrip[];
  sentPunchCards: CondensedPunchCard[];
  billFromContactEmail: string;
  billFromCcEmails: string;
  billFromAddress: string;
  billToContactEmail: string;
  billToCcEmails: string;
  billToAddress: string;
  createdAt: string;
  remittanceInfo: string;
  earliestBillableItemTime: string;
  latestBillableItemTime: string;
  paymentType: string;
  pdf: string;
  html: string;

  // Missing Fields
  startDate: string; // Minimum Trip / Punchcard Start Date
  endDate: string;  // Maximum Trip / Punchcard Start Date

  constructor(expenseInfo: any) {
    expenseInfo = camelcaseKeysDeep(expenseInfo);

    this.id = expenseInfo.id;
    this.number = expenseInfo.number;
    this.amount = expenseInfo.amount;
    this.expenseAmount = expenseInfo.expenseAmount || 0;
    this.surchargeAmount = expenseInfo.surchargeAmount || 0;
    this.tripsAmount = expenseInfo.tripsAmount || 0;
    this.tripsExpenseAmount = expenseInfo.tripsExpenseAmount || 0;
    this.punchcardsAmount = expenseInfo.punchcardsAmount || 0;
    this.punchcardsExpenseAmount = expenseInfo.punchcardsExpenseAmount || 0;
    this.subtotal = expenseInfo.subtotal || 0;
    if (expenseInfo.ownerOrganization) {
      this.ownerOrganization = new Organization(expenseInfo.ownerOrganization);
    }
    if (expenseInfo.customerOrganization) {
      this.customerOrganization = new Organization(expenseInfo.customerOrganization);
    }
    if (expenseInfo.job) {
      this.job = new JobSerializer().fromJson(expenseInfo.job);
    }
    this.sendable = expenseInfo.sendable;
    this.sent = expenseInfo.sent;
    this.draft = !expenseInfo.sent;
    this.sentTime = expenseInfo.sentTime;
    this.sentBy = expenseInfo.sentBy;
    if (expenseInfo.sentTrips && expenseInfo.sentTrips.length) {
      this.sentTrips = expenseInfo.sentTrips.map((trip) => {
        return new CondensedTrip(trip);
      });
    }
    if (expenseInfo.sentPunchCards && expenseInfo.sentPunchCards.length) {
      this.sentPunchCards = expenseInfo.sentPunchCards.map((punchCard) => {
        return new CondensedPunchCard(punchCard);
      });
    }
    this.billFromContactEmail = expenseInfo.billFromContactEmail;
    if (expenseInfo.billFromCcEmails && (typeof expenseInfo.billFromCcEmails === 'object')) {
      this.billFromCcEmails = expenseInfo.billFromCcEmails.join(',');
    }
    this.billFromAddress = expenseInfo.billFromAddress;
    this.billToContactEmail = expenseInfo.billToContactEmail;
    if (expenseInfo.billToCcEmails && (typeof expenseInfo.billToCcEmails === 'object')) {
      this.billToCcEmails = expenseInfo.billToCcEmails.join(',');
    }
    this.billToAddress = expenseInfo.billToAddress;
    this.createdAt = expenseInfo.createdAt;
    this.remittanceInfo = expenseInfo.remittanceInfo;

    this.pdf = expenseInfo.pdf;
    this.html = expenseInfo.html;
    this.earliestBillableItemTime = expenseInfo.earliestBillableItemTime;
    this.latestBillableItemTime = expenseInfo.latestBillableItemTime;
    this.paymentType = expenseInfo.paymentType;
  }

  get administrativeStatus(): string {
    if (this.sent) {
      return 'paid';
    } else if (this.draft) {
      return 'draft';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus &&
        this.administrativeStatus.length &&
        this.administrativeStatus !== 'paid' &&
        this.administrativeStatus !== 'draft'
      ) {
      return true;
    } else {
      return false;
    }
  }

  get expensePeriod(): string {
    let values = [];
    if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
      let _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_earliestBillableItemTime);
    }
    if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
      let _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_latestBillableItemTime);
    }

    if (!values.length) { values.push(this.shortTitle + ' ' + this.number); }

    return values.filter(Boolean).join(' - ');
  }

  get shortTitle(): string {
    if (this.draft) {
      return 'Expense Draft ';
    } else {
      return 'Paid Expense ';
    }
  }

  get title(): string {
    return 'Expenses | ' + this.customerOrganization.name;
  }

  url(action): string {
    if (action === 'view') {
      return this.html;
    } else if (action === 'download') {
      return this.pdf;
    } else {
      return '/expenses/' + this.id + '/' + action;
    }
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.administrativeStatus === 'draft') {
      let _option = find(options, { action: 'view' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'download' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'unlock' });
      if (_option) { remove(options, _option); }
    }

    if (this.administrativeStatus === 'paid') {
      let _option = find(options, { action: 'edit' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'mark_as_paid' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'remove' });
      if (_option) { remove(options, _option); }
    }

    if (!this.pdf) {
      let _option = find(options, { action: 'download' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
