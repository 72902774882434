<div class="app-content">
  <my-toolbar color="#FFFFFF" textColor="#3f536e"
              tagsBackgroundColor="rgba(63, 83, 110, 0.15)"
              title="{{ 'Rate Sheets' | translate }}"></my-toolbar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <!-- <side-panel></side-panel> -->
  <div class="container">
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
        <ng-template matTabContent>
          <div class="actions-wrapper">
            <fancy-table [(availableColumns)]="availableColumns"
                         [(displayedColumns)]="displayedColumns"
                         [(availableFilters)]="availableFilters"
                         [(filters)]="appliedFilters"
                         [(search)]="search"
                         (columnChange)="columnsChanged($event)"
                         [config]="tableConfig">
              <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
                <ng-container [ngSwitch]="column">
                  <div class="value" *ngSwitchCase="'unit'">{{ row.unit | titleCase }}</div>
                  <div class="value" *ngSwitchCase="'rate'">
                    {{ (row.rate || 0) | currency : 'USD' : 'symbol' }}
                  </div>
                  <div class="value" *ngSwitchDefault>{{ value }}</div>
                </ng-container>
              </ng-template>
              <div applied-filter-content>
                <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
              </div>
            </fancy-table>

            <div class="actions">
              <column-toggle #columnToggle
                             [(availableColumns)]="availableColumns"
                             [(displayedColumns)]="displayedColumns"></column-toggle>

              <filters-panel [(availableFilters)]="availableFilters"
                             [(appliedFilters)]="appliedFilters"
                             [(search)]="search"></filters-panel>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
