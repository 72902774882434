"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var location_type_1 = require("./location-type");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var LocationTypeSerializer = /** @class */ (function () {
    function LocationTypeSerializer() {
    }
    LocationTypeSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var locationType = new location_type_1.LocationType();
        if (!json) {
            return locationType;
        }
        locationType.id = json.id;
        locationType.name = json.name;
        return locationType;
    };
    LocationTypeSerializer.prototype.toJson = function (locationType) {
        var json = {
            id: locationType.id,
            name: locationType.name
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return LocationTypeSerializer;
}());
exports.LocationTypeSerializer = LocationTypeSerializer;
