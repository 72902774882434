<div class="main-container">
  <mat-table
    [dataSource]="rows" [multiTemplateDataRows]="true" matSort
    (matSortChange)="sortRows.emit($event)"
  >
    <ng-container matColumnDef="selector">
      <mat-header-cell *matHeaderCellDef class="selector">
        <label>
          <mat-checkbox (change)="selector($event)"
                        [checked]="allSelected">
          </mat-checkbox>
        </label>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="selector">
        <label>
          <mat-checkbox (change)="selector($event, row)"
                        [checked]="isSelected(row)">
          </mat-checkbox>
        </label>
        <div class="expand" (click)="expandRow(row)">
          <i *ngIf="!expandedRow(row)" class="icon-down-arrow"></i>
          <i *ngIf="expandedRow(row)" class="icon-up-arrow"></i>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="truckNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header translate>Truck Number</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.data.assignment && row.data.assignment.truck && row.data.assignment.truck.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="driver">
      <mat-header-cell *matHeaderCellDef mat-sort-header translate>Driver</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold context-menu" (contextmenu)="openContextMenu($event, row.data.assignment.driver.id)">{{ row.data.assignment && row.data.assignment.driver && row.data.assignment.driver.name }}</p>
        <a *ngIf="row.data.date && row.data.assignment && row.data.assignment.driver" class="replay-link" target="_blank" [routerLink]="['/replay']" [queryParams]="{ date: row.data.date, driver: row.data.assignment.driver.id }" translate>View Replay</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="carrier">
      <mat-header-cell *matHeaderCellDef mat-sort-header translate>Carrier</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.data.assignment && row.data.assignment.driver && row.data.assignment.driver.carrier && row.data.assignment.driver.carrier.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="orderNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header translate>Order Number</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold">{{ row.data.assignment && row.data.assignment.jobevent && row.data.assignment.jobevent.job && row.data.assignment.jobevent.job.orderNumber }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="scheduledStart">
      <mat-header-cell *matHeaderCellDef translate>Scheduled Start</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold">{{ (row.data.assignment && row.data.assignment.uniqueStart) || (row.data.assignment.jobevent && row.data.assignment.jobevent.shift1StartTimestamp) | moment: 'hh:mm a' }}</p>
        <p>{{ (row.data.assignment && row.data.assignment.uniqueStart) || (row.data.assignment.jobevent && row.data.assignment.jobevent.shift1StartTimestamp) | moment: 'MMM DD, YYYY' }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="shiftStart">
      <mat-header-cell *matHeaderCellDef translate>Shift Start</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold">{{ row.data.assignment && row.data.assignment.shifts && row.data.assignment.shifts[0] && row.data.assignment.shifts[0].startTime | moment: 'hh:mm a' }}</p>
        <p>{{ row.data.assignment && row.data.assignment.shifts && row.data.assignment.shifts[0] && row.data.assignment.shifts[0].startTime | moment: 'MMM DD, YYYY' }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="geofenceStart">
      <mat-header-cell *matHeaderCellDef translate>Geofence Start</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.predictedTrips && row.data.rowData.predictedTrips[0]">
          <p class="bold">{{ row.data.rowData.predictedTrips[0].startTimeTimestamp | moment: 'hh:mm a' }}</p>
          <p>{{ row.data.rowData.predictedTrips[0].startTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="punchcardStart">
      <mat-header-cell *matHeaderCellDef translate>Punchcard Start</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.punchcards && row.data.rowData.punchcards[0]">
          <p class="bold">{{ row.data.rowData.punchcards[0].startTimeTimestamp | moment: 'hh:mm a' }}</p>
          <p>{{ row.data.rowData.punchcards[0].startTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="firstLoad">
      <mat-header-cell *matHeaderCellDef translate>First Load</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.trips && row.data.rowData.trips[0]">
          <p class="bold">{{ row.data.rowData.trips[0].loadingCompleteTime | moment: 'hh:mm a' }}</p>
          <p>{{ row.data.rowData.trips[0].loadingCompleteTime | moment: 'MMM DD, YYYY' }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payStart">
      <mat-header-cell *matHeaderCellDef class="editable" translate>Pay Start</mat-header-cell>
      <mat-cell *matCellDef="let row" class="editable">
        <input type="time" value="{{ (row.data.rowData && row.data.rowData.payLines && row.data.rowData.payLines[0] ? row.data.rowData.payLines[0].startDatetime : '') | moment: 'HH:mm' }}" (blur)="editRow(row, 'payStartTime', $event)"/>
        <i [ngClass]="{'icon-pencil': !row.data.editing, 'icon-close': row.data.editing}" (click)="row.data.editing = !row.data.editing"></i>
        <input type="date" value="{{ (row.data.rowData && row.data.rowData.payLines && row.data.rowData.payLines[0] ? row.data.rowData.payLines[0].startDatetime : '') | moment: 'YYYY-MM-DD' }}" (blur)="editRow(row, 'payStartDate', $event)"/>
        <div class="pay-basis-dropdown" *ngIf="row.data.editing">
          <div class="dropdown-container">
            <div *ngFor="let option of payStartOptions" (click)="changePayBasis(row, option)">
              {{ option | translate }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="shiftEnd">
      <mat-header-cell *matHeaderCellDef translate>Shift End</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <p class="bold">{{ row.data.assignment && row.data.assignment.shifts && row.data.assignment.shifts[0] && row.data.assignment.shifts[0].endTime | moment: 'hh:mm a' }}</p>
        <p>{{ row.data.assignment && row.data.assignment.shifts && row.data.assignment.shifts[0] && row.data.assignment.shifts[0].endTime | moment: 'MMM DD, YYYY' }}</p>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="geofenceEnd">
      <mat-header-cell *matHeaderCellDef translate>Geofence End</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.predictedTrips && row.data.rowData.predictedTrips[row.data.rowData.predictedTrips.length - 1]">
          <p class="bold">{{ row.data.rowData.predictedTrips[row.data.rowData.predictedTrips.length - 1].endTimeTimestamp | moment: 'hh:mm a' }}</p>
          <p>{{ row.data.rowData.predictedTrips[row.data.rowData.predictedTrips.length - 1].endTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="punchcardEnd">
      <mat-header-cell *matHeaderCellDef translate>Punchcard End</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.punchcards && row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1]">
          <p class="bold">{{ row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1].endTimeTimestamp | moment: 'hh:mm a' }}</p>
          <p>{{ row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1].endTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastLoad">
      <mat-header-cell *matHeaderCellDef translate>Last Load</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.trips && row.data.rowData.trips[row.data.rowData.trips.length - 1]">
          <p class="bold">{{ row.data.rowData.trips[row.data.rowData.trips.length - 1].endTimeTimestamp | moment: 'hh:mm a' }}</p>
          <p>{{ row.data.rowData.trips[row.data.rowData.trips.length - 1].endTimeTimestamp | moment: 'MMM DD, YYYY' }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payEnd">
      <mat-header-cell *matHeaderCellDef class="editable" translate>Pay End</mat-header-cell>
      <mat-cell *matCellDef="let row" class="editable">
        <input class="time" type="time" value="{{ (row.data.rowData && row.data.rowData.payLines && row.data.rowData.payLines[0] ? row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime : '') | moment: 'HH:mm'}}" (blur)="editRow(row, 'payEndTime', $event)"/>
        <i [ngClass]="{'icon-pencil': !row.data.editing, 'icon-close': row.data.editing}" (click)="row.data.editing = !row.data.editing"></i>
        <input type="date" value="{{ (row.data.rowData && row.data.rowData.payLines && row.data.rowData.payLines[0] ? row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime : '') | moment: 'YYYY-MM-DD'}}" (blur)="editRow(row, 'payEndDate', $event)"/>
        <div class="pay-basis-dropdown" *ngIf="row.data.editing">
          <div class="dropdown-container">
            <div *ngFor="let option of payEndOptions"
                (click)="changePayBasis(row, option)">
              {{ option | translate }}
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalTime">
      <mat-header-cell *matHeaderCellDef translate>Shift Time</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.assignment && row.data.assignment.shifts && row.data.assignment.shifts[0]">
          <p class="bold">{{ paySheetService.getDurationString(row.data.assignment.shifts[0].startTime, row.data.assignment.shifts[0].endTime) }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payTime">
      <mat-header-cell *matHeaderCellDef translate>Pay Time</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.data.rowData && row.data.rowData.payLines && row.data.rowData.payLines[row.data.rowData.payLines.length - 1]">
          <p class="bold">{{ paySheetService.getPayTotalDurationString(row) }}</p>
        </ng-container>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="adjustment">
      <mat-header-cell *matHeaderCellDef translate>Adjustment</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <input type="number" value="{{ row.data.rowData && row.data.rowData.payAdjustmentTotal || 0 }}" (blur)="editRow(row, 'adjustment', $event)"/><!-- <span><i class="icon-pencil"></i></span> -->
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="verified">
      <mat-header-cell *matHeaderCellDef translate>Verified</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <i class="icon-success"></i>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sublist">
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="expandedRow(row)">
          <mat-table #payLineTable class="pay-line-table" [dataSource]="row.data.rowData && row.data.rowData.payLines">
            <ng-container matColumnDef="payLineAction">
              <mat-header-cell *matHeaderCellDef translate>
                <i (click)="createPayLine(row)" class="icon-plus-circle"></i>
              </mat-header-cell>
              <mat-cell *matCellDef="let payLine">
                <button class="btn btn-default" (click)="deletePayLine(row, i)"><i class="icon-delete"></i></button>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef translate>Pay Line</mat-header-cell>
              <mat-cell *matCellDef="let payLine; let i = index">{{ i + 1 }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="payStart">
              <mat-header-cell *matHeaderCellDef class="editable" translate>Pay Start</mat-header-cell>
              <mat-cell *matCellDef="let payLine; let i = index" class="editable">
                <input type="time" value="{{ (payLine.startDatetime || '') | moment: 'HH:mm' }}" (blur)="editRow(row, 'payStartTime', $event, i)"/>
                <input type="date" value="{{ (payLine.startDatetime || '') | moment: 'YYYY-MM-DD' }}" (blur)="editRow(row, 'payStartDate', $event, i)"/>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="payEnd">
              <mat-header-cell *matHeaderCellDef class="editable" translate>Pay End</mat-header-cell>
              <mat-cell *matCellDef="let payLine; let i = index" class="editable">
                <input type="time" value="{{ (payLine.endDatetime || '') | moment: 'HH:mm'}}" (blur)="editRow(row, 'payEndTime', $event, i)"/>
                <input type="date" value="{{ (payLine.endDatetime || '') | moment: 'YYYY-MM-DD'}}" (blur)="editRow(row, 'payEndDate', $event, i)"/>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="payTime">
              <mat-header-cell *matHeaderCellDef translate>Pay Time</mat-header-cell>
              <mat-cell *matCellDef="let payLine">
                <p>{{ paySheetService.getDurationString(payLine.startDatetime, payLine.endDatetime) }}</p>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedPayLineColumns"></mat-header-row>
            <mat-row *matRowDef="let payLine; columns: displayedPayLineColumns;" matRipple class="pay-line-row"></mat-row>
          </mat-table>
          <driver-pay-table-sublist *ngIf="row.data.rowData && row.data.rowData.trips && row.data.rowData.trips.length"
                                    [type]="'Trip'" [data]="row.data.rowData && row.data.rowData.trips"></driver-pay-table-sublist>
          <driver-pay-table-sublist *ngIf="row.data.rowData && row.data.rowData.predictedTrips && row.data.rowData.predictedTrips.length"
                                    [type]="'GeoTrip'" [data]="row.data.rowData && row.data.rowData.predictedTrips"></driver-pay-table-sublist>
          <driver-pay-table-sublist *ngIf="row.data.rowData && row.data.rowData.punchcards && row.data.rowData.punchcards.length"
                                    [type]="'Punch Card'" [data]="row.data.rowData && row.data.rowData.punchcards"></driver-pay-table-sublist>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple
              class="assignment-row"></mat-row>
    <mat-row *matRowDef="let row; columns: ['sublist'];"
              class="detail-row" [ngClass]="{ 'expanded': expandedRow(row) }"></mat-row>
  </mat-table>
  <span *ngIf="selectedRowIds.length" class="selection-length" translate>{{ allSelected ? 'All' : selectedRowIds.length }} Rows Selected</span>
</div>
<!-- driver context menu -->
<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>