"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var driver_1 = require("../drivers/driver");
var confirm_drivers_dialog_component_1 = require("./confirm-drivers-dialog.component");
var job_1 = require("../jobs/job");
var job_event_1 = require("../job-events/job-event");
var driver_service_1 = require("../drivers/driver.service");
var carrier_1 = require("../carriers/carrier");
var MobileDispatchDriversComponent = /** @class */ (function () {
    function MobileDispatchDriversComponent(driverService, dialog) {
        var _this = this;
        this.driverService = driverService;
        this.dialog = dialog;
        this.selectDriver = new core_1.EventEmitter();
        this.selectEditTruck = new core_1.EventEmitter();
        this.drivers = [];
        this.loading = false;
        this.errors = [];
        this.confirmDriverCallback = function () {
            _this.selectDriver.emit(_this.selectedDriver);
        };
    }
    MobileDispatchDriversComponent.prototype.ngOnChanges = function (changes) {
        if (changes.scrollEvent && !this.loading &&
            this.scrollEvent && this.scrollEvent.target &&
            this.scrollEvent.target.scrollTop >
                this.scrollEvent.target.scrollHeight -
                    this.scrollEvent.target.clientHeight * 3) {
            this.getDrivers(true);
        }
    };
    MobileDispatchDriversComponent.prototype.ngOnInit = function () {
        this.getDrivers();
    };
    MobileDispatchDriversComponent.prototype.ngOnDestroy = function () {
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
    };
    MobileDispatchDriversComponent.prototype.select = function (driver) {
        this.selectedDriver = driver;
    };
    MobileDispatchDriversComponent.prototype.add = function (driver) {
        if (driver.conflicts && driver.conflicts.length > 0) {
            var dialog = this.dialog.open(confirm_drivers_dialog_component_1.ConfirmDriversDialogComponent, {
                width: '80vw'
            });
            if (dialog) {
                dialog.componentInstance.drivers = [driver];
                dialog.componentInstance.callback = this.confirmDriverCallback;
            }
        }
        else {
            this.selectDriver.emit(driver);
        }
    };
    MobileDispatchDriversComponent.prototype.editTruck = function (driver) {
        this.selectEditTruck.emit(driver);
    };
    MobileDispatchDriversComponent.prototype.getDrivers = function (next) {
        var _this = this;
        if (next === void 0) { next = false; }
        this.loading = true;
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        var allCarriers, allLeased, carrierId;
        if (this.carrier && this.carrier.id === 'all_carriers') {
            allCarriers = true;
        }
        else if (this.carrier && this.carrier.id === 'all_leased') {
            allLeased = true;
        }
        else if (this.carrier && this.carrier.id === 'my_drivers') {
            // Nothing necessary
        }
        else if (this.carrier && this.carrier.id) {
            carrierId = this.carrier.id;
        }
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var driverServiceReq = next ? this.driverService.listNext() :
            this.driverService.list({
                ordering: 'profile__last_name,name',
                // search: this.search,
                carrier: carrierId,
                all_carriers: allCarriers,
                all_leased: allLeased,
                jobevent_available: jobEventId
            });
        this.driversReq = driverServiceReq && driverServiceReq.subscribe(function (drivers) {
            _this.drivers = next ? _this.drivers.concat(drivers) : drivers;
        }, function (err) {
            _this.errors = err;
        }, function () { return _this.loading = false; });
        if (!driverServiceReq) {
            this.loading = false;
        }
    };
    return MobileDispatchDriversComponent;
}());
exports.MobileDispatchDriversComponent = MobileDispatchDriversComponent;
