import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

// libraries
import { remove, find as _find } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';

// models
import { Assignment } from './assignment';
import { Driver } from '../drivers/driver';
import { JobEvent } from '../job-events/job-event';
import { DateTimeRange, TimelineRowData, TimelineConfig } from '../shared';
import { DriverContextEvent } from '../drivers/driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'trip-assignments',
  templateUrl: './trip-assignments.component.html',
  styleUrls: ['./trip-assignments.component.scss'],
})
export class TripAssignmentsComponent implements OnInit {
  @Input() assignments: Assignment[] = [];
  @Input() jobEvent: JobEvent;
  @Input() controlState: string;
  @Input() loading = false;
  @Output() activeAssignment: EventEmitter<any> = new EventEmitter<any>();
  timelineRange: DateTimeRange;
  timelineData: TimelineRowData[] = [];
  timelineConfig: TimelineConfig = {
    visibleBars: ['punchcards', 'predictedTrips', 'trips'],
    headerHeight: 52,
    rowHeight: 100,
    scroll: true,
    currentTime: true,
    selectedTime: false
  };

  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  expandedDrivers: Driver[] = [];
  activeStatuses: any[] = [{
    name: 'Waiting',
    active: false
  }, {
    name: 'Unloading',
    active: false
  }, {
    name: 'Enroute to Unloading',
    active: false
  }, {
    name: 'Loading',
    active: false
  }, {
    name: 'Enroute to Loading',
    active: false
  }];

  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.jobEvent && changes.assignments) {
      this.setupTimelineData(this.jobEvent, changes.assignments.currentValue);
    }
  }

  ngOnInit() {
    this.getAvailableStatuses(this.assignments);
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  getAvailableStatuses(assignments: Assignment[]): void {
    if (assignments) {
      assignments.forEach(assignment => {
        if (assignment.waiting) {
          this.activeStatuses[0].active = true;
        } else if (assignment.driver.isActive(assignment.driver.trip, 'en-route-one')) {
          this.activeStatuses[4].active = true;
        } else if (assignment.driver.isActive(assignment.driver.trip, 'loading')) {
          this.activeStatuses[3].active = true;
        } else if (assignment.driver.isActive(assignment.driver.trip, 'en-route-two')) {
          this.activeStatuses[2].active = true;
        } else if (assignment.driver.isActive(assignment.driver.trip, 'unloading')) {
          this.activeStatuses[1].active = true;
        }
      });
    }
  }

  assignmentsOfStatus(status): Assignment[] {
    let assignments = [];

    switch (status.name) {
      case 'Waiting': {
        assignments = this.assignments.map(assignment => {
          return assignment.waiting ? assignment : null;
        });
        break;
      }
      case 'Enroute to Loading': {
        assignments = this.assignments.map(assignment => {
          return assignment.driver.isActive(assignment.driver.trip, 'en-route-one') ? assignment : null;
        });
        break;
      }
      case 'Loading': {
        assignments = this.assignments.map(assignment => {
          return assignment.driver.isActive(assignment.driver.trip, 'loading') ? assignment : null;
        });
        break;
      }
      case 'Enroute to Unloading': {
        assignments = this.assignments.map(assignment => {
          return assignment.driver.isActive(assignment.driver.trip, 'en-route-two') ? assignment : null;
        });
        break;
      }
      case 'Unloading': {
        assignments = this.assignments.map(assignment => {
          return assignment.driver.isActive(assignment.driver.trip, 'unloading') ? assignment : null;
        });
        break;
      }
    }

    return assignments.filter(Boolean);
  }

  expandDriver(driver: Driver): void {
    if (_find(this.expandedDrivers, { id: driver.id })) {
      remove(this.expandedDrivers, { id: driver.id });
    } else {
      this.expandedDrivers.push(driver);
    }
  }

  expandedDriver(driver: Driver): boolean {
    if (_find(this.expandedDrivers, { id: driver.id })) {
      return true;
    }
    return false;
  }

  selectActiveAssignment(assignment: Assignment): void {
    this.activeAssignment.emit(assignment);
  }

  setupTimelineData(jobEvent: JobEvent, assignments: Assignment[]) {
    let startTimes = [], endTimes = [];
    if (jobEvent) {
      startTimes.push(jobEvent.shift1StartTimestamp);
      jobEvent.shift2EndTimestamp ? endTimes.push(jobEvent.shift2EndTimestamp) : endTimes.push(jobEvent.shift1EndTimestamp);
      this.timelineData = assignments.map(assignment => {
        assignment.trips.forEach(trip => {
          if (trip.startTimeTimestamp) { startTimes.push(trip.startTimeTimestamp); }
          if (trip.endTimeTimestamp) { endTimes.push(trip.endTimeTimestamp); }
          if (trip.connexPaverStartUnloading) {
            assignment.pavers.push({
              startDatetime: trip.connexPaverStartUnloading,
              endDatetime: trip.connexPaverEndUnloading
            });
          }
        });
        assignment.predictedTrips.forEach(predictedTrip => {
          if (predictedTrip.startTimeTimestamp) { startTimes.push(predictedTrip.startTimeTimestamp); }
          if (predictedTrip.endTimeTimestamp) { endTimes.push(predictedTrip.endTimeTimestamp); }
        });
        assignment.punchCards.forEach(punchCard => {
          if (punchCard.startTimeTimestamp) { startTimes.push(punchCard.startTimeTimestamp); }
          if (punchCard.endTimeTimestamp) { endTimes.push(punchCard.endTimeTimestamp); }
        });
        return <TimelineRowData>{
          referenceId: assignment.id,
          trips: assignment.trips,
          predictedTrips: assignment.predictedTrips,
          punchcards: assignment.punchCards,
          pavers: assignment.pavers
        };
      });
    }
    this.timelineRange = this.setTimelineRange(startTimes, endTimes);
  }

  setTimelineRange(startTimes: string[], endTimes: string[]): DateTimeRange {
    const startMoments = startTimes.map(time => (moment(time)));
    const endMoments = endTimes.map(time => (moment(time)));
    return {
      startDatetime: moment.min(startMoments).startOf('hour').toISOString(),
      endDatetime: moment.max(endMoments).endOf('hour').toISOString()
    };
  }

  openContextMenu(event: any, driver: Driver) {
    this.contextMenuEventSubject.next({
      event,
      driverId: driver.id,
    });
  }
}
