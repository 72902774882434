"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".resources-container[_ngcontent-%COMP%]{height:calc(100% - 70px);overflow-y:scroll}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]{height:418px;border-radius:4px;background-color:#fff;box-shadow:0 1px 5px 0 rgba(0,0,0,.2),0 3px 1px -2px rgba(0,0,0,.12),0 2px 2px 0 rgba(0,0,0,.14);margin:30px;padding:24px;color:#595959;font-family:'Source Sans Pro',sans-serif}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{font-size:32px;font-weight:600}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-description[_ngcontent-%COMP%]{width:100%;max-width:55%}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-container[_ngcontent-%COMP%]{width:100%;max-width:45%}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-connex[_ngcontent-%COMP%]{max-width:578px;width:100%}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-driver[_ngcontent-%COMP%], .resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-field[_ngcontent-%COMP%]{max-width:282px;width:100%;margin-bottom:-24px}@media (max-width:991.98px){.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-driver[_ngcontent-%COMP%], .resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-field[_ngcontent-%COMP%]{padding-left:12%}}@media (max-width:767.98px){.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-description[_ngcontent-%COMP%]{width:100%;max-width:none}.resources-container[_ngcontent-%COMP%]   .banner[_ngcontent-%COMP%]   .resource-img-container[_ngcontent-%COMP%]{display:none!important}}"];
exports.styles = styles;
