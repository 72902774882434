"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var invoice_service_1 = require("./invoice.service");
var trip_service_1 = require("../trips/trip.service");
var new_invoice_dialog_component_1 = require("./new-invoice-dialog.component");
var invoice_filters_dialog_component_1 = require("./invoice-filters-dialog.component");
var api_service_1 = require("../shared/api.service");
var index_1 = require("../shared/dialogs/index");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var InvoicesComponent = /** @class */ (function () {
    function InvoicesComponent(route, router, invoiceService, tripService, dialog) {
        this.route = route;
        this.router = router;
        this.invoiceService = invoiceService;
        this.tripService = tripService;
        this.dialog = dialog;
        this.invoices = [];
        this.loading = true;
        this.errors = [];
        this.count = 0;
        this.page = 1;
        this.pageSize = 25;
        this.firstRun = true;
        this.selectedCount = {
            draft: 0,
            sent: 0
        };
        this.allSelected = {
            draft: false,
            sent: false
        };
        this.selectedInvoices = {
            draft: [],
            sent: []
        };
        this.excludeInvoices = {
            draft: [],
            sent: []
        };
        this.state = 'draft';
        this.search = '';
        this.sortAsc = true;
        this.filters = [];
        this.lastExpensed = null;
        this.dropdownOptions = {
            draft: [
                { name: 'Export', button: true }
            ],
            sent: [
                { name: 'Export', button: true },
                { name: 'Download', button: true }
            ]
        };
        this.expenseLabel = 'Make Expenses';
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true, external: false },
            { name: 'Remove', action: 'remove', link: false, external: false },
            { name: 'Send', action: 'send', link: false, external: false },
            { name: 'Resend', action: 'resend', link: false, external: false },
            { name: 'Unlock', action: 'unlock', link: false, external: false },
            { name: this.expenseLabel, action: 'expense', link: false, external: false },
            { name: 'View', action: 'view', link: true, external: true },
            { name: 'Download', action: 'download', link: true, external: true },
            { name: 'Export', action: 'export', link: false, external: false },
            { name: 'Preview', action: 'preview', link: true, external: false }
        ];
        this.allSubscriptionsToUnsubscribe = [];
        this.newInvoiceCallback = function (e) {
            // Update trip data
        };
    }
    InvoicesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            _this.sortBy = result.qparams.sortBy ? result.qparams.sortBy : 'created_at';
            _this.sortAsc = result.qparams.sortAsc ? (result.qparams.sortAsc === 'true') : false;
            _this.page = result.qparams.page ? result.qparams.page : 1;
            _this.state = result.params['state'] ? result.params['state'] : 'draft';
            _this.getInvoices();
        }));
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.carrier) {
            this.expenseLabel = 'Make Expenses & Paystubs';
        }
    };
    InvoicesComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    InvoicesComponent.prototype.pageChange = function (event) {
        this.page = event;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { page: this.page > 1 ? this.page : 1 })
        });
    };
    InvoicesComponent.prototype.getInvoices = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        this.invoices = [];
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        delete filters['undefined'];
        if (this.invoicesReq) {
            this.invoicesReq.unsubscribe();
        }
        this.invoicesReq = this.invoiceService.getInvoices(__assign({ ordering: order, search: this.search, page_size: this.pageSize, page: this.page, sent: this.state === 'draft' ? 'False' : 'True' }, lodash_1.omit(filters, 'jobDay'), query)).subscribe(function (invoices) {
            _this.invoices = invoices;
            _this.count = _this.invoiceService.count;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    InvoicesComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortBy: this.sortBy, sortAsc: this.sortAsc })
        });
        this.loading = true;
        this.getInvoices({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    InvoicesComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.loading = true;
        this.getInvoices((_a = {}, _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy, _a));
    };
    InvoicesComponent.prototype.openNewInvoice = function () {
        var dialog = this.dialog.open(new_invoice_dialog_component_1.NewInvoiceDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = this.newInvoiceCallback;
    };
    InvoicesComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        queryParams['search'] = this.search;
        this.router.navigate(['invoices', 'filter', this.state], { queryParams: queryParams });
        this.getInvoices();
    };
    InvoicesComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    InvoicesComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(invoice_filters_dialog_component_1.InvoiceFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.filters, { key: 'job' }), 'value');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.filters, { key: 'customer' }), 'value');
        dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    InvoicesComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'customer_organization',
            job: 'job',
            sent: 'sent',
            startDate: 'jobday_start__gte',
            endDate: 'jobday_end__lte'
        };
        var falseyFilters = [];
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var value = filterRes[key];
            var displayValue;
            if (typeof (value) === 'boolean') {
                if (key === 'incomplete' && value) {
                    displayValue = value.toString();
                    displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
                    value = !value;
                    var filterValue = value.toString();
                    filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                    query[queryKeys[key]] = filterValue;
                }
                else if (value) {
                    value = value.toString();
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    query[queryKeys[key]] = value;
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var displayKey = key;
            var hidden = false;
            if (key === 'startDate') {
                hidden = true;
            }
            if (key === 'endDate') {
                hidden = true;
            }
            if (key === 'jobDay') {
                displayKey = 'Job Day';
            }
            var filter = {
                key: displayKey,
                value: displayValue || value,
                query: query,
                hidden: hidden
            };
            if (filter.value === 'False' || !filter.value) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.filters = lodash_1.difference(this.filters, falseyFilters);
        this.getInvoices();
    };
    InvoicesComponent.prototype.removeFilter = function (filter) {
        if (filter.key === 'Job Day') {
            lodash_1.remove(this.filters, lodash_1.find(this.filters, { key: 'Start Date' }));
            lodash_1.remove(this.filters, lodash_1.find(this.filters, { key: 'End Date' }));
        }
        lodash_1.remove(this.filters, filter);
        this.getInvoices();
    };
    InvoicesComponent.prototype.menuAction = function (name, invoice) {
        switch (name) {
            case 'remove':
                this.removeInvoice(invoice);
                break;
            case 'send':
                this.sendInvoice(invoice);
                break;
            case 'resend':
                this.resendInvoice(invoice);
                break;
            case 'expense':
                this.createExpenseAndPaystubs(invoice);
                break;
            case 'export':
                this.createExport([invoice.id]);
                break;
            case 'unlock':
                this.unlockInvoice(invoice);
                break;
        }
    };
    InvoicesComponent.prototype.removeInvoice = function (invoice) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Remove Invoice?',
            body: 'This invoice will be deleted and cannot be recovered.',
            close: 'Cancel',
            accept: 'Remove'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.invoiceService.remove(invoice).subscribe(function (res) {
                    _this.getInvoices();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    InvoicesComponent.prototype.sendInvoice = function (invoice) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Send Invoice?',
            body: 'Are you sure you want to send this invoice? You will no longer be able to edit the invoice once it has been sent.',
            close: 'Cancel',
            accept: 'Send'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.invoiceService.send(invoice).subscribe(function (res) {
                    invoice = res;
                    _this.getInvoices();
                    _this.loading = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    InvoicesComponent.prototype.unlockInvoice = function (invoice) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unlock Invoice?',
            body: 'This invoice will be unlocked and can be modified.',
            close: 'Cancel',
            accept: 'Unlock'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.invoiceService.unlock(invoice).subscribe(function (res) {
                    _this.getInvoices();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    InvoicesComponent.prototype.resendInvoice = function (invoice) {
        var _this = this;
        this.loading = true;
        this.invoiceService.send(invoice).subscribe(function (res) {
            invoice = res;
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    InvoicesComponent.prototype.createExpenseAndPaystubs = function (invoice) {
        var _this = this;
        this.loading = true;
        this.invoiceService.expense(invoice.id).subscribe(function () {
            _this.loading = false;
            _this.lastExpensed = invoice.number;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    InvoicesComponent.prototype.createExport = function (invoices, section) {
        var _this = this;
        if (section === void 0) { section = null; }
        var scope = {};
        var params = new http_1.HttpParams();
        if (invoices && invoices.length) {
            Object.assign(scope, { invoices: invoices });
        }
        else if (this.allSelected) {
            Object.assign(scope, { excludeInvoices: this.excludeInvoices[section] });
            Object.keys(this.filters).map(function (key) {
                if (_this.filters[key]) {
                    var query_1 = _this.filters[key]['query'];
                    Object.keys(query_1).map(function (queryKey) {
                        params = params.set(queryKey, query_1[queryKey]);
                    });
                }
            });
            params = params.set('search', this.search);
            params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
        }
        this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
            width: '430px',
            data: {
                type: 'invoices',
                buttonText: 'Export Data to CSV',
                params: params,
                scope: scope,
                service: this.invoiceService
            }
        });
    };
    InvoicesComponent.prototype.createDownload = function (invoices, section) {
        var _this = this;
        if (section === void 0) { section = null; }
        var scope = {};
        var params = new http_1.HttpParams();
        if (invoices && invoices.length) {
            Object.assign(scope, { invoices: invoices });
        }
        else if (this.allSelected) {
            Object.assign(scope, { excludeInvoices: this.excludeInvoices[section] });
            Object.keys(this.filters).map(function (key) {
                if (_this.filters[key]) {
                    var query_2 = _this.filters[key]['query'];
                    Object.keys(query_2).map(function (queryKey) {
                        params = params.set(queryKey, query_2[queryKey]);
                    });
                }
            });
            params = params.set('search', this.search);
            params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
        }
        this.invoiceService.downloadPdfs(scope, params).subscribe(function () {
            _this.loading = false;
            _this.confirmDialog = _this.dialog.open(index_1.RuckitConfirmDialogComponent, {
                width: '430px',
                height: '250px'
            });
            _this.confirmDialog.componentInstance.attributes = {
                title: 'Download Link Sent',
                body: 'Your zip file with the selected invoice data is on its way to your inbox',
                close: 'Close',
                accept: null
            };
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    InvoicesComponent.prototype.displayLastExpensed = function () {
        if (this.lastExpensed) {
            return true;
        }
        else {
            return false;
        }
    };
    InvoicesComponent.prototype.selector = function (event, section, invoice) {
        if (section === void 0) { section = 'draft'; }
        if (invoice === void 0) { invoice = null; }
        if (invoice) {
            if (!event.target.checked) {
                invoice.selected = false;
                lodash_1.pull(this.selectedInvoices[section], invoice.id);
                if (this.allSelected) {
                    this.excludeInvoices[section].push(invoice.id);
                    this.selectedCount[section] = (this.count - this.excludeInvoices[section].length);
                }
                else {
                    this.selectedCount[section] = this.selectedInvoices[section].length;
                }
            }
            else {
                invoice.selected = true;
                if (this.allSelected[section]) {
                    lodash_1.pull(this.excludeInvoices[section], invoice.id);
                    this.selectedCount[section] = (this.count - this.excludeInvoices[section].length);
                }
                else {
                    this.selectedInvoices[section].push(invoice.id);
                    this.selectedCount[section] = this.selectedInvoices[section].length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected[section] = false;
                this.invoices.forEach(function (_invoice) { _invoice.selected = false; });
                this.selectedCount[section] = 0;
            }
            else {
                this.allSelected[section] = true;
                this.selectedCount[section] = (this.count - this.excludeInvoices[section].length);
            }
            this.selectedInvoices[section] = [];
            this.excludeInvoices[section] = [];
        }
    };
    InvoicesComponent.prototype.setSelectedAction = function (option, section) {
        switch (option.name) {
            case 'Export':
                this.createExport(this.selectedInvoices[section], section);
                break;
            case 'Download':
                this.createDownload(this.selectedInvoices[section], section);
                break;
        }
    };
    return InvoicesComponent;
}());
exports.InvoicesComponent = InvoicesComponent;
