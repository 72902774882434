"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./view-punch-card-ticket-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("@angular/flex-layout/extended");
var i5 = require("@angular/flex-layout/core");
var i6 = require("@angular/forms");
var i7 = require("@angular/material/dialog");
var i8 = require("./punch-card.service");
var i9 = require("@angular/common/http");
var i10 = require("./view-punch-card-ticket-dialog.component");
var styles_ViewPunchCardTicketDialogComponent = [i0.styles];
var RenderType_ViewPunchCardTicketDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewPunchCardTicketDialogComponent, data: {} });
exports.RenderType_ViewPunchCardTicketDialogComponent = RenderType_ViewPunchCardTicketDialogComponent;
function View_ViewPunchCardTicketDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.punchCard.ticketImage; _ck(_v, 0, 0, currVal_0); }); }
function View_ViewPunchCardTicketDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Drag a JPG or PNG under 25mb here or browse for an image to upload."]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ViewPunchCardTicketDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Upload a new photo"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ViewPunchCardTicketDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "punch-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "form-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [["class", "label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Ticket Image"])), (_l()(), i1.ɵeld(5, 0, null, null, 15, "div", [["class", "field-group ticket-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "upload-image-dropzone"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { filled: 0 }), i1.ɵdid(10, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i3.ɵNgClassImpl, [6, i3.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(11, { filled: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPunchCardTicketDialogComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, [["fileInput", 1]], null, 0, "input", [["class", "inputfile"], ["id", "file"], ["name", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "label", [["class", "upload-label"], ["for", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "icon-upload"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPunchCardTicketDialogComponent_3)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPunchCardTicketDialogComponent_4)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = "upload-image-dropzone"; var currVal_2 = _ck(_v, 9, 0, _co.punchCard.ticketImage); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 11, 0, _co.punchCard.ticketImage); var currVal_4 = "upload-image-dropzone"; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = _co.punchCard.ticketImage; _ck(_v, 13, 0, currVal_5); var currVal_6 = !_co.punchCard.ticketImage; _ck(_v, 18, 0, currVal_6); var currVal_7 = _co.punchCard.ticketImage; _ck(_v, 20, 0, currVal_7); }, null); }
function View_ViewPunchCardTicketDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, [["punchCardTickets", 4]], 0, i6.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.NgForm]), i1.ɵdid(4, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(6, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Punch Card Ticket"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "mat-dialog-content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewPunchCardTicketDialogComponent_1)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_8 = ""; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.punchCard; _ck(_v, 15, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 6).id; _ck(_v, 5, 0, currVal_7); }); }
exports.View_ViewPunchCardTicketDialogComponent_0 = View_ViewPunchCardTicketDialogComponent_0;
function View_ViewPunchCardTicketDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "view-punch-card-ticket-dialog", [], null, null, null, View_ViewPunchCardTicketDialogComponent_0, RenderType_ViewPunchCardTicketDialogComponent)), i1.ɵprd(512, null, i8.PunchCardService, i8.PunchCardService, [i9.HttpClient]), i1.ɵdid(2, 114688, null, 0, i10.ViewPunchCardTicketDialogComponent, [i7.MatDialogRef, i8.PunchCardService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_ViewPunchCardTicketDialogComponent_Host_0 = View_ViewPunchCardTicketDialogComponent_Host_0;
var ViewPunchCardTicketDialogComponentNgFactory = i1.ɵccf("view-punch-card-ticket-dialog", i10.ViewPunchCardTicketDialogComponent, View_ViewPunchCardTicketDialogComponent_Host_0, { punchCard: "punchCard" }, { completed: "completed" }, []);
exports.ViewPunchCardTicketDialogComponentNgFactory = ViewPunchCardTicketDialogComponentNgFactory;
