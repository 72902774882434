import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { parseErrors } from '../shared/api.service';
import { CustomerService } from './customer.service';

@Component({
  selector: 'my-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  providers: [CustomerService]
})
export class CustomersComponent implements OnInit {
  customers: any = [];
  loading = true;
  errors = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = false;
      this.errors = [];
      this.customerService.getCustomers().subscribe(customers => {
        this.customers = customers;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.errors = parseErrors(err);
      });
    });
  }

  openDialog() {}
}
