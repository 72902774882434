"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var scale_1 = require("../scales/scale");
var location_serializer_1 = require("../locations/location.serializer");
var truck_serializer_1 = require("../trucks/truck.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var TareWeight = /** @class */ (function () {
    function TareWeight(tareWeightInfo) {
        tareWeightInfo = camelcaseKeysDeep(tareWeightInfo);
        this.id = tareWeightInfo.id;
        if (tareWeightInfo.truck && typeof (tareWeightInfo.truck) === 'object') {
            this.truck = (new truck_serializer_1.TruckSerializer().fromJson(tareWeightInfo.truck));
        }
        else {
            this.truck = (new truck_serializer_1.TruckSerializer().fromJson({ id: tareWeightInfo.truck }));
        }
        if (tareWeightInfo.scale && typeof (tareWeightInfo.scale) === 'object') {
            this.scale = new scale_1.Scale(tareWeightInfo.scale);
        }
        else {
            this.scale = new scale_1.Scale({ id: tareWeightInfo.scale });
        }
        if (tareWeightInfo.location && typeof (tareWeightInfo.location) === 'object') {
            this.location = (new location_serializer_1.LocationSerializer).fromJson(tareWeightInfo.location);
        }
        else {
            this.location = (new location_serializer_1.LocationSerializer).fromJson({ id: tareWeightInfo.location });
        }
        this.createdAt = tareWeightInfo.createdAt;
        this.lastModified = tareWeightInfo.lastModified;
        this.name = tareWeightInfo.name;
        this.date = tareWeightInfo.date;
        this.weight = tareWeightInfo.weight;
        this.weightUnit = tareWeightInfo.weightUnit;
        this.weightType = 'stored';
    }
    return TareWeight;
}());
exports.TareWeight = TareWeight;
