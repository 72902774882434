"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var organization_service_1 = require("./organization.service");
var truck_type_service_1 = require("../trucks/truck-type.service");
var api_service_1 = require("../shared/api.service");
var EditOrganizationPermissionsComponent = /** @class */ (function () {
    function EditOrganizationPermissionsComponent(route, router, organizationService, truckTypeService, dialog) {
        this.route = route;
        this.router = router;
        this.organizationService = organizationService;
        this.truckTypeService = truckTypeService;
        this.dialog = dialog;
        this.loading = true;
        this.errors = [];
        this.model = {
            driverFleetCanViewRates: false,
            driverFleetQuickTicket: false,
            dvirRequired: false,
            driverFleetRequireAssignmentConfirmation: false,
            avoidTolls: false,
            qrEnabled: false
        };
        this.truckSettings = [];
    }
    EditOrganizationPermissionsComponent.prototype.ngOnInit = function () {
        this.loading = false;
        this.model.driverFleetCanViewRates = this.organization.driverFleetCanViewRates;
        this.model.driverFleetQuickTicket = this.organization.driverFleetQuickTicket;
        this.model.dvirRequired = this.organization.dvirRequired;
        this.model.driverFleetRequireAssignmentConfirmation = this.organization.driverFleetRequireAssignmentConfirmation;
        this.model.avoidTolls = this.organization.avoidTolls;
        this.model.qrEnabled = this.organization.qrEnabled;
        this.setUpTruckTypes();
    };
    EditOrganizationPermissionsComponent.prototype.savePermissions = function () {
        var _this = this;
        this.loading = true;
        var _organization = Object.assign({ id: this.organization.id }, this.model);
        this.organizationService.save(_organization).subscribe(function () {
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditOrganizationPermissionsComponent.prototype.setUpTruckTypes = function () {
        var _this = this;
        this.truckTypeService.list({
            ordering: 'name',
            page_size: 100
        }).subscribe(function (truckTypes) {
            _this.truckSettings = truckTypes;
            _this.columnLength = Math.ceil(truckTypes.length / 2);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditOrganizationPermissionsComponent.prototype.saveTruckTypePreferences = function () {
        var _this = this;
        this.loading = true;
        var _truckSettings = this.truckSettings.map(function (setting) {
            return {
                truckType: setting.id,
                isFavorite: setting.isFavorite,
                averageTonnage: setting.averageTonnage
            };
        });
        this.truckTypeService.updateTruckTypes(_truckSettings).subscribe(function () {
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditOrganizationPermissionsComponent.prototype.selectAll = function (form, bool) {
        form.control.markAsDirty();
        this.truckSettings.forEach(function (setting) { return setting.isFavorite = bool; });
    };
    return EditOrganizationPermissionsComponent;
}());
exports.EditOrganizationPermissionsComponent = EditOrganizationPermissionsComponent;
