import { Project } from './project';
import { Organization } from '../organizations/organization';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ProjectSerializer {
  /**
  * @param  {any} json
  * @returns Job
  */
  fromJson(json: any): Project {
    json = camelcaseKeysDeep(json);
    const project = new Project();
    if (!json) { return project; }

    project.id = json.id;
    project.name = json.name;
    project.ownerOrganization = json.ownerOrganization;
    project.poNumber = json.poNumber;

    if (json.customerOrganization && typeof (json.customerOrganization) === 'object') {
      project.customerOrganization = new Organization(json.customerOrganization);
    } else if (json.customerOrganization) {
      project.customerOrganization = new Organization({ id: json.customerOrganization });
    } else {
      project.customerOrganization = new Organization({ id: '', name: '' });
    }

    return project;
  }

  /**
  * @param  {Project} project
  * @returns any
  */
  toJson(project: Project): any {
    let json = {
      id: project.id,
      name: project.name,
      customerOrganization: project.customerOrganization && project.customerOrganization.id,
      ownerOrganization: project.ownerOrganization,
      poNumber: project.poNumber
    };

    return decamelizeKeysDeep(json);
  }
}
