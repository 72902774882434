import {
  Component, ElementRef, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgForm } from '@angular/forms';
import { reject, find as _find } from 'lodash';
import { Subscription } from 'rxjs';

import { InvitationService } from '../invitation/invitation.service';
import { ConnectionService } from  '../connections/connection.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'send-job-dialog',
  templateUrl: './send-job-dialog.component.html',
  styleUrls: ['./send-job-dialog.component.scss'],
  providers: [InvitationService, ConnectionService]
})
export class SendJobDialogComponent implements OnInit, OnDestroy {
  @Input() job: any = {};
  @Input() jobevent: any = {};
  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  connections = [];
  connectionsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  callback;
  invitationsReq: Subscription;
  connectionsReq: Subscription;

  model = {
    data: {
      dates: [],
      jobevent: null,
      numTrucks: null,
    },
    recipientOrganization: null,
    kind: 'new_job'
  };

  @ViewChild('connectionsDropdown', { static: false }) connectionsDropdown;

  constructor(
    public dialogRef: MatDialogRef<SendJobDialogComponent>,
    private invitationService: InvitationService,
    private connectionService: ConnectionService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.getConnections();
    this.model.data.jobevent = this.jobevent.id;
  }

  ngOnDestroy() {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
  }

  submit() {
    this.model.data.dates = this.model.data.dates.map(d => this.convertDateFormat(d));
    this.invitationService.createInvitation(this.model).subscribe(result => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  getConnections(query = {}): void {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }

    this.connectionsReq = this.connectionService.list({
      ordering: 'organization__name',
      can_accept_jobs: 'True',
      ...query
    }).subscribe(connections => {
        this.connections = connections.map(connection => {
          return {name: connection.organization.name, id: connection.organization.id};
        });
        if (this.model.recipientOrganization) {
          this.connections = reject(this.connections, this.model.recipientOrganization);
          this.connections.unshift(this.model.recipientOrganization);
          this.connectionsDropdown.selectedOption = _find(this.connections, {id: this.model.recipientOrganization.id});
        }
        this.connectionsDropdown.config.loadingOptions = false;
      }, err => {
        this.errors = err;
      }, () => {
        this.connectionsDropdown.config.loadingOptions = false;
      }
    );
  }

  selectConnection(e, form: NgForm) {
    this.model.recipientOrganization = e.id;
    form.controls['recipient_organization'].markAsDirty();
  }

  dropdownNextPage(e, type) {
    let config;
    let service;
    let options;

    switch (type) {
      case 'connection':
        config = this.connectionsConfig;
        service = this.connectionService;
        options = this.connections;
        break;
    }

    if (!config.loadingOptions) {
      let o = service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(
          results => {
            switch (type) {
              case 'connection':
                this.connections = this.connections.concat(results);
                break;
            }
            config.loadingOptions = false;
          }, (err) => {
            this.errors = parseErrors(err);
            config.loadingOptions = false;
          }
        );
      }
    }
  }

  dropdownSearch(term = '', type) {
    switch (type) {
      case 'connection':
        this.getConnections({ search: term });
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  convertDateFormat(value: any) {
    let d = value && new Date(value);
    if (isNaN(+d)) { return; }
    return d.toISOString().split('T')[0];
  }
}
