<div class="app-content driver-job-reports">
  <my-toolbar title="{{ 'Driver Job Reports' | translate }}"></my-toolbar>
  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/trips']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" queryParamsHandling="merge" translate>Trips</a>
        <a [routerLink]="['/geotrips']" routerLinkActive="active" translate>GeoTrips</a>
        <a [routerLink]="['/punchcards']" routerLinkActive="active" translate>Punch Cards</a>
        <a [routerLink]="['/shifts']" routerLinkActive="active" translate>Shifts</a>
        <a [routerLink]="['/driver-reports']" routerLinkActive="active" translate>Driver Job Reports</a>
        <a [routerLink]="['/end-of-day-reports']" routerLinkActive="active" translate>End of Day Reports</a>
      </div>
    </div>
  </action-bar>
  <div class="actions-wrapper">
    <fancy-table #driverJobReportsTable
                 [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(availableFilters)]="availableFilters"
                 [(query)]="query"
                 [(filters)]="appliedFilters"
                 [(search)]="search"
                 (selectionChanged)="selector($event)"
                 (onClickAction)="openReport($event)"
                 [config]="tableConfig">
      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'driver'">
            <div class="data" (contextmenu)="openContextMenu($event, row.driver.id)">
              {{ row.driver && row.driver.name }}<br />
              {{ row.driver && row.driver.truck && row.driver.truck.displayName }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'company'">
            {{ row.driver && row.driver.carrier && row.driver.carrier.name }}
          </div>
          <div class="value" *ngSwitchCase="'job'">
            {{ row.job && row.job.displayName }}
          </div>
          <div class="value" *ngSwitchCase="'job-number'">
            {{ row.job && row.job.jobNumber }}
          </div>
          <div class="value" *ngSwitchCase="'customer'">
            {{ row.job && row.job.project && row.job.project.customerOrganization && row.job.project.customerOrganization.name }}
          </div>
          <div class="value" *ngSwitchCase="'customer-address'">
            <ng-container *ngIf="row.job && row.job.project && row.job.project.customerOrganization">
              {{ row.job.project.customerOrganization.street }} {{ row.job.project.customerOrganization.street2 }}<br />
              {{ row.job.project.customerOrganization.city }}, {{ row.job.project.customerOrganization.state }} {{ row.job.project.customerOrganization.zipcode }}
            </ng-container>
          </div>
          <div class="value" *ngSwitchCase="'clock-in-time'">
            {{ row.clockInTime | date: 'MM/dd/yyyy hh:mm a' }}
          </div>
          <div class="value" *ngSwitchCase="'first-load-time'">
            {{ row.firstLoadTime | date: 'MM/dd/yyyy hh:mm a' }}
          </div>
          <div class="value" *ngSwitchCase="'loading-wait-time'">
            {{ row.loadingWaitTime }}
          </div>
          <div class="value" *ngSwitchCase="'unloading-wait-time'">
            {{ row.unloadingWaitTime }}
          </div>
          <div class="value" *ngSwitchCase="'average-round-trip-time'">
            {{ row.averageRoundTripTime }}
          </div>
          <div class="value" *ngSwitchCase="'trip-count'">
            {{ row.tripCount }}
          </div>
          <!-- <div class="value" *ngSwitchCase="'shift-end'">
            {{ row.shiftEnd }}
          </div> -->
          <div class="value" *ngSwitchCase="'last-load-time'">
            {{ row.lastLoadTime | date: 'MM/dd/yyyy hh:mm a' }}
          </div>
          <!-- <div class="value" *ngSwitchCase="'at-yard'">
            {{ row.atYard }}
          </div> -->
          <div class="value" *ngSwitchCase="'freight-bill-time'">
            {{ row.freightBillTime }}
          </div>
          <div class="value" *ngSwitchCase="'shift-time'">
            {{ row.shiftTime }}
          </div>
          <div class="value" *ngSwitchCase="'deficient-time'">
            {{ row.deficientTime }}
          </div>
          <div class="value" *ngSwitchDefault>{{ value }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>
          <date-range-picker [selectedDates]="[reportDate]"
            (dateChanged)="onDateChanged($event)"></date-range-picker>
        </div>
      </div>
      <div applied-filter-content>
        <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <filters-panel [(availableFilters)]="availableFilters"
                     [(appliedFilters)]="appliedFilters"
                     [(search)]="search"></filters-panel>
      <column-toggle #columnToggle
                     [(availableColumns)]="availableColumns"
                     [(displayedColumns)]="displayedColumns"></column-toggle>
      <a mat-icon-button color="accent" class="add-button" (click)="openAddJobReport()">
        <mat-icon>add_circle</mat-icon>
      </a>
    </div>
  </div>
</div>

<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject">
</app-driver-context-menu>