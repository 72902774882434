import { CustomField } from './custom-field';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class CustomFieldSerializer {
  fromJson(json: any): CustomField {
    json = camelcaseKeysDeep(json);

    const customField = new CustomField();

    if (!json) { return customField; }

    customField.id = json.id;
    customField.displayName = json.displayName;
    customField.key = json.key;
    customField.kind = json.kind;
    customField.ownerOrganization = json.ownerOrganization;
    customField.active = json.active;

    return customField;
  }

  toJson(data: any) {
    if (data.length) {
      return data.map(customField => ({
        id: customField.id,
        display_name: customField.displayName,
        key: customField.key,
        kind: customField.kind,
        owner_organization: customField.ownerOrganization,
        active: customField.active,
      }));
    }

    return {
      id: data.id,
      display_name: data.displayName,
      key: data.key,
      kind: data.kind,
      owner_organization: data.ownerOrganization,
      active: data.active
    };
  }
}
