"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var TripFilter = /** @class */ (function () {
    function TripFilter(tripFilterInfo) {
        tripFilterInfo = camelcaseKeysDeep(tripFilterInfo);
        this.id = tripFilterInfo.id;
        this.name = tripFilterInfo.name;
    }
    return TripFilter;
}());
exports.TripFilter = TripFilter;
