<form #editShifts="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Selected Shifts</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="form-content">
      <div class="field-group shift-start full-width">
        <label translate>Shift Start</label>
        <div class="shift-time">
          <input type="time" value="{{ attributes.startTime | moment: 'HH:mm' }}" (blur)="editTime('startTime', $event.target.value)"/>
          <input type="date" value="{{ attributes.startTime | moment: 'YYYY-MM-DD' }}" (blur)="editTime('startDate', $event.target.value)"/>
        </div>
      </div>

      <div class="field-group shift-end full-width">
        <label translate>Shift End</label>
        <div class="shift-time">
          <input type="time" value="{{ attributes.endTime | moment: 'HH:mm' }}" (blur)="editTime('endTime', $event.target.value)"/>
          <input type="date" value="{{ attributes.endTime | moment: 'YYYY-MM-DD' }}" (blur)="editTime('endDate', $event.target.value)"/>
        </div>
      </div>

      <div class="field-group shift-adjustment full-width">
        <label translate>Adjustment (Minutes)</label>
        <input type="number" value="{{ attributes.adjustment }}" (blur)="editAdjustment($event.target.value)"/>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false"
            [disabled]="!editShifts.form.valid || loading"
            [ngClass]="{loading: loading}" translate>
      Edit
    </button>
  </div>
</form>
