"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var index_1 = require("../index");
var environment_1 = require("../../../environments/environment");
var MobileSidebarComponent = /** @class */ (function () {
    function MobileSidebarComponent(authenticationService) {
        this.authenticationService = authenticationService;
        this.topSections = [];
        this.bottomSections = [];
        this.applicationVersion = environment_1.environment.applicationVersion;
        this.open = false;
        this.canCreateJobs = false;
        this.sidebarOpenChange = new core_1.EventEmitter();
        this.topSections = [];
        this.bottomSections = [];
        this.topSections = this.topSections.concat([
            { name: 'Live Map', icon: 'icon-locations', type: 'link', url: '/dashboard' },
            { name: 'Active Jobs', icon: 'icon-projects', type: 'link', url: '/daily' },
            { name: 'Create Jobs', icon: 'icon-dispatch', type: 'link', url: '/jobs/new' },
            { name: 'Dispatch', icon: 'icon-dispatch', type: 'link', url: '/dispatch' },
            { name: 'Locations', icon: 'icon-locations', type: 'link', url: '/locations' }
        ]);
        this.bottomSections = this.bottomSections.concat([
            { name: 'Sign Out', icon: 'icon-signout', type: 'link', url: '/logout' }
        ]);
    }
    MobileSidebarComponent.prototype.ngOnInit = function () {
        this.user = this.authenticationService.user();
        if (this.user && this.user.organization) {
            var organization = this.user.organization;
            this.canCreateJobs = organization && organization.canCreateJobs ? true : false;
        }
        if (!this.canCreateJobs) {
            this.topSections = lodash_1.reject(this.topSections, { name: 'Create Jobs' });
        }
    };
    MobileSidebarComponent.prototype.isLoggedIn = function () {
        return this.authenticationService.isLoggedIn();
    };
    MobileSidebarComponent.prototype.toggleOpen = function () {
        this.open = !this.open;
        this.sidebarOpenChange.emit(this.open);
    };
    return MobileSidebarComponent;
}());
exports.MobileSidebarComponent = MobileSidebarComponent;
