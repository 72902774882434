import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { ShiftReportSerializer } from './shift-report.serializer';
import { ResourceService } from '../shared/resource.service';
import { ShiftReport } from './shift-report';
import { Shift } from './shift';
import { ShiftSerializer } from './shift.serializer';
import { requestHeaders, handleError } from '../shared/index';

@Injectable()
export class ShiftReportService extends ResourceService<ShiftReport> {

  constructor(http: HttpClient) {
    super(http, 'shifts/report/', new ShiftReportSerializer());
  }
}

@Injectable()
export class ShiftService extends ResourceService<Shift> {

  constructor(http: HttpClient) {
    super(http, 'shifts/', new ShiftSerializer());
  }

  bulkUpdate(shifts: Shift[], query?: any): Observable<{errors: any, shifts: Shift[]}> {
    if (shifts && shifts.length) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const _shifts = shifts.map(shift => {
        return new ShiftSerializer().toJson(shift);
      });
      const shiftBulkUrl = this.baseUrl + 'shifts/bulk-update/';
      return this.http.put(shiftBulkUrl, _shifts, {
        headers: requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  private convertBulkResponse(res: any): {errors: any, shifts: Shift[]} {
    let errors = [];
    let shifts: Shift[] = [];
    if (res && res.errors) { errors = res.errors; }
    if (res && res.success) {
      shifts = res.success.map(record => new ShiftSerializer().fromJson(record));
    }

    return { errors: errors, shifts: shifts };
  }
}

@Injectable()
export class DriverShiftService extends ResourceService<Shift> {

  constructor(http: HttpClient) {
    super(http, 'drivers/shifts/', new ShiftSerializer());
  }
}
