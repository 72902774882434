"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CollapsibleSectionComponent = /** @class */ (function () {
    function CollapsibleSectionComponent() {
        this.controlPosition = 'left';
        this.title = 'Expand';
        this.collapsed = false;
    }
    return CollapsibleSectionComponent;
}());
exports.CollapsibleSectionComponent = CollapsibleSectionComponent;
