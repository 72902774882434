'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var MenuToggleComponent = /** @class */ (function () {
    function MenuToggleComponent(router) {
        this.router = router;
        this.renderContent = false;
        this.routes = [];
        this.isOpen = false;
        this.hasActiveRoute = false;
    }
    MenuToggleComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.isOpen = this.shouldExpand(this.['url'])
        var url = this.router.url;
        if (url && url.split('?').length > 1) {
            url = url.split('?')[0];
        }
        this.isOpen = this.shouldExpand(url);
        this.routeListener = this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; })).subscribe(function (event) { return _this.isOpen = _this.shouldExpand(event['url']); });
    };
    MenuToggleComponent.prototype.ngOnDestroy = function () {
        this.routeListener.unsubscribe();
    };
    MenuToggleComponent.prototype.expandWith = function (state, sectionRoutes, currentRoute) {
        if (currentRoute === void 0) { currentRoute = this.router.url; }
        this.isOpen = !this.isOpen;
        this.routes = sectionRoutes;
        this.shouldExpand(currentRoute);
    };
    MenuToggleComponent.prototype.shouldExpand = function (currentRoute) {
        var hasActiveRoute = false;
        if (this.routes && this.routes.length) {
            this.routes.map(function (route) {
                if (route.url === currentRoute) {
                    hasActiveRoute = true;
                }
            });
            if (this.section.url === currentRoute || (currentRoute && (currentRoute.split('?')[0] === this.section.url))) {
                hasActiveRoute = true;
            }
        }
        else {
            if (this.section.url === currentRoute) {
                hasActiveRoute = true;
            }
            else if (currentRoute) {
                var parts = currentRoute.split('/');
                var url = currentRoute;
                // if (parts && parts[1]) { url = '/' + parts[1]; }
                if (url && this.section.pages) {
                    var urls = this.section.pages.map(function (page) { return page.url; });
                    if (urls && urls.includes(url)) {
                        hasActiveRoute = true;
                    }
                }
                if (currentRoute.split('?')[0] === this.section.url) {
                    hasActiveRoute = true;
                }
            }
        }
        this.hasActiveRoute = hasActiveRoute;
        return hasActiveRoute;
    };
    return MenuToggleComponent;
}());
exports.MenuToggleComponent = MenuToggleComponent;
