<div class="app-content">
  <my-toolbar title="{{ 'Locations' | translate }}" (updatedMarkets)="refreshTable()"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <ng-container *ngIf="device.desktop && !loading">
        <div class="segmented-buttons">
          <a (click)="switchMap('map')" class="btn btn-default" [ngClass]="{active: view === 'map'}">
            <i class="icon-locations"></i>
          </a>
          <a (click)="switchMap('list')" class="btn btn-default" [ngClass]="{active: view === 'list'}">
            <i class="icon-rows"></i>
          </a>
        </div>
        <div class="archived">
          <input type="checkbox" name="hide_archived" [(ngModel)]="hideArchived" (change)="toggleArchived()" />
          <label attr.data-label="{{ 'Hide Archived' | translate }}" for="hide_archived"></label>
        </div>
      </ng-container>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <a id="add-location-btn" class="btn btn-primary" [routerLink]="['/locations', 'new']" [queryParams]="{returnTo: '/locations'}">
        <span class="icon-plus"></span> {{ 'Add Location' | translate }}
      </a>
    </div>
  </action-bar>

  <div class="locations" [ngClass]="{'full-map': view === 'map', 'mobile': !device.desktop}">
    <ng-container *ngIf="device.desktop && !loading">
      <div class="map">
        <div class="overlay" *ngIf="selectedLocation && !selectedLocation.mappable">
          <a [routerLink]="['/locations', selectedLocation.id, 'edit']" [queryParams]="{returnTo: '/locations'}">
            <button class="btn btn-default" translate>Add Address</button>
          </a>
        </div>
        <ngui-map (initialized$)="onMapLoaded($event)" (mapReady$)="initMap($event)" [options]="mapOptions">
          <polygon *ngFor="let polygon of polygons" 
                  [editable]="false" 
                  [paths]="polygon.paths" 
                  [strokeColor]="'#FF8C00'"
                  [strokeOpacity]="0.8" 
                  [strokeWeight]="2" 
                  [fillColor]="'#FF8C00'" 
                  [fillOpacity]="0.35">
          </polygon>
          <ng-container *ngFor="let marker of markers">
            <marker *ngIf="marker.location.mappable" 
              [position]="[marker.lat, marker.lon]" 
              (click)="openInfoWindow($event, marker)">
            </marker>
          </ng-container>
          <info-window id="info-window">
            <div *ngIf="infoWindowModel" class="info-window">
              <div class="title">{{ infoWindowModel.title }}</div>
              <div class="address">{{ infoWindowModel.address }}</div>
              <div class="city">{{ infoWindowModel.city }} {{ infoWindowModel.state }} {{ infoWindowModel.zip }}</div>
              <div class="active-jobs">{{ infoWindowModel.activeJobs }}</div>
            </div>
          </info-window>
        </ngui-map>
      </div>
    </ng-container>

    <div class="container">
      <div class="actions-wrapper">
        <fancy-table #locationTable [(availableColumns)]="availableColumns" 
                                    [(displayedColumns)]="displayedColumns"
                                    [(availableFilters)]="availableFilters" 
                                    [(filters)]="appliedFilters" 
                                    [(query)]="query"
                                    [(search)]="search"
                                    (columnChange)="columnsChanged($event)"
                                    [config]="locationTableConfig"
                                    (onClickAction)="clickAction($event)">
          <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
            <ng-container [ngSwitch]="column">
              <div class="value" *ngSwitchCase="'location-type'">
                <div class="data">{{ row.locationType && row.locationType.name }}</div>
              </div>
              <div class="value" *ngSwitchCase="'location-owner'">
                <div class="data">{{ row.locationOwner && row.locationOwner.name }}</div>
              </div>
              <div class="value" *ngSwitchCase="'name'">
                <div class="data"> {{ row.name }}</div>
              </div>
              <div class="value" *ngSwitchCase="'address'">
                <div class="data">{{ row.street }}</div>
              </div>
              <div class="value" *ngSwitchCase="'city'">
                <div class="data">{{ row.city }}</div>
              </div>
              <div class="value" *ngSwitchCase="'state'">
                <div class="data">{{ row.state }}</div>
              </div>
              <div class="value" *ngSwitchCase="'zip'">
                <div class="data">{{ row.zipcode }}</div>
              </div>
              <div class="value" *ngSwitchCase="'load-tgt'">
                <div class="data">{{ row.averageLoadingTime }}</div>
              </div>
              <div class="value" *ngSwitchCase="'unload-tgt'">
                <div class="data">{{ row.averageUnloadingTime }}</div>
              </div>
              <div class="value" *ngSwitchCase="'geofence'">
                <div class="data" *ngIf="row.geofence">
                  <mat-icon color="accent">check_circle_outline</mat-icon>
                </div>
              </div>
              <div class="value" *ngSwitchCase="'timezone'">
                <div class="data" *ngIf="row.timezone && row.timezone != 'UTC'">
                  {{ row.timezone }}
                </div>
                <div class="data unset-timezone" *ngIf="!row.timezone || row.timezone == 'UTC'"
                     title="No timezone has been set, UTC will be used."
                >
                  <i class="icon-timezone"></i>&nbsp;&nbsp;{{ row.timezone || 'Not Set' | translate }}
                </div>
              </div>
              <div class="value" *ngSwitchCase="'created-at'">
                <div class="data">{{ (row.createdAt | date:'medium') || '&nbsp;' }}</div>
              </div>
              <div class="value" *ngSwitchCase="'action'">
                <action-menu icon="icon-more">
                  <ng-container *ngFor="let option of row.filterOptions(locationTableConfig.menuOptions)">
                    <a *ngIf="option.link && !option.external" mat-menu-item 
                        [routerLink]="row.url(option.action)"
                        [queryParams]="{ returnTo: '/locations' }">
                        {{ option.name }}
                    </a>
                    <a *ngIf="option.link && option.external" mat-menu-item [href]="option.url(option.action)" 
                        target="_blank">{{option.name }}</a>
                    <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">
                        {{ option.name }}</a>
                  </ng-container>
                </action-menu>
              </div>
              <div class="value" *ngSwitchDefault>{{ value }}</div>
            </ng-container>
          </ng-template>
          <div header-content>
            <div class="title" translate>Locations</div>
          </div>
          <div applied-filter-content>
            <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)" (searchChange)="onSearchChange()"></applied-filters>
          </div>
        </fancy-table>
        <div class="actions" *ngIf="device.desktop">
          <column-toggle #columnToggle
                         [(availableColumns)]="availableColumns"
                         [(displayedColumns)]="displayedColumns"></column-toggle>
          <filters-panel [(availableFilters)]="availableFilters"
                         [(appliedFilters)]="appliedFilters"
                         [(search)]="search"></filters-panel>
          <!-- add new location page-->
          <a mat-icon-button class="add-button" [routerLink]="['/locations', 'new']" [queryParams]="{returnTo: '/locations'}">
            <mat-icon color="accent" >add_circle</mat-icon>
          </a>
        </div>
      </div>
    </div>
    <!-- fancy table :ends here -->
  </div>
</div>
