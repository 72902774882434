"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var stats_job_event_filter_1 = require("./stats-job-event.filter");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var StatsJobEventFilterSerializer = /** @class */ (function () {
    function StatsJobEventFilterSerializer() {
    }
    StatsJobEventFilterSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var jobEventFilter = new stats_job_event_filter_1.StatsJobEventFilter();
        if (!json) {
            return jobEventFilter;
        }
        jobEventFilter.id = json.id;
        jobEventFilter.name = json.name;
        return jobEventFilter;
    };
    StatsJobEventFilterSerializer.prototype.toJson = function (jobEventFilter) {
        var json = {
            id: jobEventFilter.id,
            name: jobEventFilter.name
        };
        return decamelizeKeysDeep(json);
    };
    return StatsJobEventFilterSerializer;
}());
exports.StatsJobEventFilterSerializer = StatsJobEventFilterSerializer;
