"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var router_1 = require("@angular/router");
var moment = require("moment");
var rxjs_1 = require("rxjs");
// angular material
var material_1 = require("@angular/material");
// lodash
var lodash_1 = require("lodash");
// components
var driver_assignments_list_component_1 = require("../../assignments/driver-assignments-list.component");
var new_trip_dialog_component_1 = require("../../trips/new-trip-dialog/new-trip-dialog.component");
// services
var shared_1 = require("../../shared");
var trip_decision_service_1 = require("../../trips/trip-decision.service");
var punch_card_decision_service_1 = require("../../punch-cards/punch-card-decision.service");
// types
var driver_1 = require("../driver");
var DriverDailyTasksComponent = /** @class */ (function () {
    function DriverDailyTasksComponent(deviceDetectorService, dialog, route, router, authenticationService, tripDecisionService, punchCardDecisionService) {
        var _this = this;
        this.deviceDetectorService = deviceDetectorService;
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.tripDecisionService = tripDecisionService;
        this.punchCardDecisionService = punchCardDecisionService;
        this.loading = true;
        this.filtersApplied = false;
        this.keyStatus = [];
        this.shouldAuditDecisions = new core_1.EventEmitter();
        this.rateChanged = new core_1.EventEmitter();
        this.errors = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.displayedColumns = [
            'driver', 'completed-trips', 'geo-trips', 'total-time', 'invoice-total', 'expense-total', 'assignments'
        ];
        this.hideApproved = false;
        this.showVoided = false;
        this.showUnworkedJobs = false;
        this.dataSource = new material_1.MatTableDataSource();
        this.view = 'trips';
        this.tripTimes = {};
        this.totalTimeOfAllJobs = '';
        this.saveTripCallback = function (trip) {
            if (_this.route.snapshot.queryParamMap.get('refreshPage')) {
                _this.router.navigate([], {
                    relativeTo: _this.route,
                    queryParams: {
                        'refreshPage': null,
                    },
                    queryParamsHandling: 'merge'
                });
            }
            else {
                _this.router.navigateByUrl(_this.router.url + "&refreshPage=true");
            }
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    DriverDailyTasksComponent.prototype.ngOnChanges = function (changes) {
        this.dataSource.data = [this.driver];
        if (this.driver && this.driver.jobEventShares) {
            this.getTripTimes();
        }
        if (this.driverTable) {
            this.driverTable.renderRows();
        }
    };
    DriverDailyTasksComponent.prototype.switchView = function (view) {
        this.view = view;
    };
    DriverDailyTasksComponent.prototype.toggleApproved = function () {
        this.hideApproved = !this.hideApproved;
    };
    DriverDailyTasksComponent.prototype.toggleVoided = function () {
        this.showVoided = !this.showVoided;
    };
    DriverDailyTasksComponent.prototype.toggleUnworkedJobs = function () {
        this.showUnworkedJobs = !this.showUnworkedJobs;
    };
    DriverDailyTasksComponent.prototype.showDriverAssignments = function (driverData) {
        this.driverAssignmentsList.setOpen(driverData);
    };
    DriverDailyTasksComponent.prototype.getTripTimes = function () {
        var _this = this;
        this.tripTimes = {};
        var totalTimeOfAllJobs = 0;
        this.driver.jobEventShares.forEach(function (jobEventShare) {
            var tripTimesForJob = [];
            var totalTime = 0;
            _this.driver.trips.forEach(function (trip) {
                var tripJobId = trip.jobEventShare ? trip.jobEventShare.jobId : trip.jobEvent.job.id;
                if (tripJobId === jobEventShare.jobId && !trip.void) {
                    tripTimesForJob.push({
                        'start': trip.startTimeTimestamp ? moment(trip.startTimeTimestamp).toDate() : '',
                        'end': trip.endTimeTimestamp ? moment(trip.endTimeTimestamp).toDate() : ''
                    });
                    totalTime += trip.completedTripDurationRounded;
                }
            });
            if (tripTimesForJob.length) {
                tripTimesForJob.sort(function (a, b) {
                    return a.start - b.start;
                });
                _this.tripTimes[jobEventShare.jobId + jobEventShare.organizationId] = {
                    'start': tripTimesForJob[0].start,
                    'end': tripTimesForJob[tripTimesForJob.length - 1].end,
                    'totalTime': moment.duration(totalTime, 'milliseconds').format('D[ days] H[ hrs] m[ min] total')
                };
            }
            totalTimeOfAllJobs += totalTime;
        });
        this.totalTimeOfAllJobs = moment.duration(totalTimeOfAllJobs, 'milliseconds').format('H[ hrs] m[ min]');
    };
    DriverDailyTasksComponent.prototype.openAddTrip = function (driver) {
        var dialog = this.dialog.open(new_trip_dialog_component_1.NewTripDialogComponent, {
            width: '455px'
        });
        dialog.componentInstance.model.driver = driver;
        dialog.componentInstance.model.truck = driver.latestTruck;
        dialog.componentInstance.jobEventDate = this.reportDate;
        dialog.componentInstance.callback = this.saveTripCallback;
    };
    DriverDailyTasksComponent.prototype.changeStatus = function (decisionStatus) {
        if (decisionStatus === void 0) { decisionStatus = 'approved'; }
        var user = this.authenticationService.user();
        var organization = this.authenticationService.getOrganization();
        if (this.view === 'trips') {
            this.changeTripDecisionStatuses(decisionStatus, user, organization);
        }
        else if (this.view === 'punch-cards') {
            this.changePunchCardsDecisionStatuses(decisionStatus, user, organization);
        }
    };
    DriverDailyTasksComponent.prototype.changeTripDecisionStatuses = function (decisionStatus, user, organization) {
        var _this = this;
        this.driver.jobEventShares.forEach(function (jobEventShare) {
            var requests = [];
            var newStatus = false;
            var status = lodash_1.find(_this.keyStatus, {
                driverId: _this.driver.id,
                key: jobEventShare.jobId,
            });
            if (status) {
                status.loading = true;
            }
            else {
                newStatus = true;
                status = {
                    driverId: _this.driver.id,
                    key: jobEventShare.jobId,
                    loading: true,
                    statuses: [],
                };
            }
            if (_this.driver) {
                _this.driver.trips.forEach(function (trip) {
                    var alreadyApproved = trip.latestDecisionStatus === 'approved' &&
                        decisionStatus === 'approved';
                    if (trip &&
                        trip.jobEvent.job.id === jobEventShare.jobId &&
                        !alreadyApproved) {
                        trip.loading = true;
                        var data = {
                            trip: trip,
                            decisionStatus: decisionStatus,
                            decidedBy: user,
                            organization: organization,
                            decidedAt: new Date().toISOString(),
                        };
                        requests.push(_this.tripDecisionService.save(data));
                    }
                });
            }
            rxjs_1.combineLatest(requests).subscribe(function (decisions) {
                decisions.forEach(function (decision) {
                    var trip = lodash_1.find(_this.driver.trips, {
                        id: decision.trip && decision.trip.id,
                    });
                    if (trip) {
                        trip.latestDecision = decision.id;
                        trip.latestDecisionStatus = decision.decisionStatus;
                        trip.latestDecider = decision.decidedBy && decision.decidedBy.id;
                        trip.latestDeciderName =
                            decision.decidedBy && decision.decidedBy.name;
                        status.statuses = [decision.decisionStatus];
                        trip.loading = false;
                    }
                });
            }, function (errors) {
                console.log('Decision Errors: ', errors);
            }, function () {
                status.loading = false;
                _this.driver.trips = _this.driver.trips.slice();
                if (newStatus) {
                    _this.keyStatus.push(status);
                }
            });
        });
    };
    DriverDailyTasksComponent.prototype.changePunchCardsDecisionStatuses = function (decisionStatus, user, organization) {
        var _this = this;
        this.driver.jobEventShares.forEach(function (jobEventShare) {
            var requests = [];
            var newStatus = false;
            var status = lodash_1.find(_this.keyStatus, {
                driverId: _this.driver.id,
                key: jobEventShare.jobId,
            });
            if (status) {
                status.loading = true;
            }
            else {
                newStatus = true;
                status = {
                    driverId: _this.driver.id,
                    key: jobEventShare.jobId,
                    loading: true,
                    statuses: [],
                };
            }
            if (_this.driver) {
                _this.driver.punchCards.forEach(function (punchCard) {
                    var alreadyApproved = punchCard.latestDecisionStatus === 'approved' &&
                        decisionStatus === 'approved';
                    if (punchCard &&
                        punchCard.job.id === jobEventShare.jobId &&
                        punchCard.job.project.ownerOrganization ===
                            jobEventShare.organizationId &&
                        !alreadyApproved) {
                        punchCard.loading = true;
                        var data = {
                            punchcard: punchCard,
                            decisionStatus: decisionStatus,
                            decidedBy: user,
                            organization: organization,
                            decidedAt: new Date().toISOString(),
                        };
                        requests.push(_this.punchCardDecisionService.save(data));
                    }
                });
            }
            rxjs_1.combineLatest(requests).subscribe(function (decisions) {
                decisions.forEach(function (decision) {
                    var punchCard = (lodash_1.find(_this.driver.punchCards, {
                        id: decision.punchcard && decision.punchcard.id,
                    }));
                    if (punchCard) {
                        punchCard.latestDecision = decision.id;
                        punchCard.latestDecisionStatus = decision.decisionStatus;
                        punchCard.latestDecider =
                            decision.decidedBy && decision.decidedBy.id;
                        punchCard.latestDeciderName =
                            decision.decidedBy && decision.decidedBy.name;
                        status.statuses = [decision.decisionStatus];
                        punchCard.loading = false;
                    }
                });
            }, function (errors) {
                console.log('Decision Errors: ', errors);
            }, function () {
                status.loading = false;
                _this.driver.punchCards = _this.driver.punchCards.slice();
                if (newStatus) {
                    _this.keyStatus.push(status);
                }
            });
        });
    };
    return DriverDailyTasksComponent;
}());
exports.DriverDailyTasksComponent = DriverDailyTasksComponent;
