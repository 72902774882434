"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var project_service_1 = require("../projects/project.service");
var connection_service_1 = require("../connections/connection.service");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var tag_service_1 = require("../tags/tag.service");
var JobFiltersDialogComponent = /** @class */ (function () {
    function JobFiltersDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = false;
        this.errors = [];
        this.customersConfig = {
            searchable: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: 'Select Customer',
            loadingText: 'Loading Customer...',
            noResultsText: 'No Customers',
            service: connection_service_1.ConnectionService,
            query: {
                ordering: 'organization__name',
                customer_only: 'True'
            },
            customOptionKeys: ['organization']
        };
        this.projectsConfig = {
            searchable: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: 'Select Project',
            loadingText: 'Loading Project...',
            noResultsText: 'No Projects',
            service: project_service_1.ProjectService,
            query: { ordering: 'name' }
        };
        this.marketsConfig = {
            searchable: true,
            multiselect: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: 'Select Markets',
            loadingText: 'Loading Markets...',
            noResultsText: 'No Markets',
            service: tag_service_1.TagService,
            query: {}
        };
        this.model = {
            startDate: null,
            endDate: null,
            project: null,
            customer: null,
            payableTo: null,
            orderNumber: null,
            jobNumber: null,
            tags: []
        };
        this.customFields = [];
    }
    JobFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    JobFiltersDialogComponent.prototype.submit = function () {
        this.convertDates();
        if (this.model.orderNumber && !this.model.orderNumber.trim()) {
            delete this.model.orderNumber;
        }
        this.callback(this.model);
        this.dialogRef.close();
    };
    JobFiltersDialogComponent.prototype.convertDates = function (assign) {
        if (assign === void 0) { assign = true; }
        var startDate, endDate;
        if (this.model.startDate) {
            startDate = new Date(this.model.startDate);
            var name_1 = this.datePipe.transform(startDate, 'MM/dd/yyyy');
            startDate.setHours(0, 0, 0, 0);
            if (assign) {
                Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name_1 });
            }
        }
        if (this.model.endDate) {
            endDate = new Date(this.model.endDate);
            var name_2 = this.datePipe.transform(endDate, 'MM/dd/yyyy');
            endDate.setHours(23, 59, 59, 999);
            if (assign) {
                Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name_2 });
            }
        }
        if (!assign) {
            return {
                startDate: startDate && startDate.toISOString(),
                endDate: endDate && endDate.toISOString()
            };
        }
    };
    JobFiltersDialogComponent.prototype.onDateChanged = function (dates, type) {
        if (type === void 0) { type = ''; }
        this.model[type] = dates[0];
    };
    return JobFiltersDialogComponent;
}());
exports.JobFiltersDialogComponent = JobFiltersDialogComponent;
