'use strict';

import { Compiler, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, timer as observableTimer } from 'rxjs';

import { AuthenticationService } from '../index';
import { environment } from '../../../environments/environment';
import { UserService } from '../../users/user.service';

@Component({
  selector: 'alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent implements OnInit {
  alert = 'Update Available';
  updateNoticeHidden = false;
  updateAvailable = false;
  applicationVersion = environment.applicationVersion;
  releasedVersion: string;
  revisionCheckTimer: Observable<number>;
  revisionCheckTimerSub: Subscription;
  userReq: Subscription;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private compiler: Compiler
  ) {}

  ngOnInit() {
    this.revisionCheckTimer = observableTimer(1, 120000);
    this.revisionCheckTimerSub = this.revisionCheckTimer.subscribe(t => {
      this.getRevision();
    });
    this.allSubscriptionsToUnsubscribe.push(this.revisionCheckTimerSub);
  }

  ngOnDestroy(): void {
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  compareVersions(): void {
    if (this.applicationVersion && this.releasedVersion &&
        this.applicationVersion.toLowerCase().trim() !== this.releasedVersion.toLowerCase().trim()) {
      this.updateAvailable = true;
    } else {
      this.updateAvailable = false;
    }
  }

  getRevision(): void {
    let expire = Date.now();
    this.http.get('REVISION?expire=' + expire, {
      responseType: 'text'
    }).subscribe(response => {
      this.releasedVersion = response.toString();
      this.compareVersions();
    }, err => {
      this.updateNoticeHidden = true;
    });

    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }

    let currentUser = this.authenticationService.user();
    if (currentUser && currentUser.id) {
      this.userReq = this.userService.get(currentUser.id).subscribe(user => {
        this.authenticationService.storeUser(
          Object.assign(currentUser, {
            features: user.features,
            enabledFeatures: user.enabledFeatures,
            organization: user.organization
          })
        );
      });
    }
  }

  reload(): void {
    this.compiler.clearCache();
    window.location.reload(true);
  }
}
