import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { PunchCard } from '../../punch-cards/punch-card';
import { Trip } from '../../trips/trip';
import { CompleteHourPipe } from './complete-hour.pipe';

@Pipe({
  name: 'activeHour', pure: true
})
export class ActiveHourPipe implements PipeTransform {
  transform(record: Trip|PunchCard, hour: string, format = 'h a'): boolean {
    let active = false;
    let currentTime = moment();
    const completePipe = new CompleteHourPipe();
    if (!record || !record.startTimeTimestamp) { return false; }
    if (completePipe.transform(record, hour)) { return false; }
    let startTime, endTime, timeAtHour, timeAtEndOfHour;

    startTime = moment(record.startTimeTimestamp).toDate();
    let _date = moment(startTime).format('YYYY-MM-DD');
    timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
    timeAtEndOfHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
    timeAtEndOfHour.add(3599, 'seconds');

    if (record.endTimeTimestamp) {
      endTime = moment(record.endTimeTimestamp).toDate();
    }

    if (
      (timeAtHour < currentTime && currentTime < timeAtEndOfHour) &&
      (endTime === undefined || !endTime)
    ) {
      active = true;
    }

    return active;
  }
}
