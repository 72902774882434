"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var punch_card_service_1 = require("./punch-card.service");
var job_service_1 = require("../jobs/job.service");
var job_event_service_1 = require("../job-events/job-event.service");
var driver_service_1 = require("../drivers/driver.service");
var truck_service_1 = require("../trucks/truck.service");
var api_service_1 = require("../shared/api.service");
var truck_serializer_1 = require("../trucks/truck.serializer");
var job_serializer_1 = require("../jobs/job.serializer");
var punch_card_1 = require("./punch-card");
var core_2 = require("@ngx-translate/core");
var EditPunchCardDialogComponent = /** @class */ (function () {
    function EditPunchCardDialogComponent(dialogRef, punchCardService, jobService, jobEventService, driverService, truckService, translateService, elementRef) {
        this.dialogRef = dialogRef;
        this.punchCardService = punchCardService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.driverService = driverService;
        this.truckService = truckService;
        this.translateService = translateService;
        this.elementRef = elementRef;
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
        this.jobEventError = null;
        this.jobEventDatePickerOptions = {
            min: null,
            max: null
        };
        this.jobDropdownOptions = [];
        this.jobDropdownConfig = {
            nameProperty: 'displayName',
            searchable: true,
            group: true,
            groupBy: function (job) { return job.project.name; },
            loadingOptions: false
        };
        this.driverDropdownOptions = [];
        this.driverDropdownConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.truckDropdownOptions = [];
        this.truckDropdownConfig = {
            nameProperty: 'displayName',
            searchable: true,
            loadingOptions: false
        };
    }
    EditPunchCardDialogComponent.prototype.ngOnInit = function () {
        this.loading = true;
        if (this.punchCardId) {
            this.getPunchCard(this.punchCardId);
        }
        else {
            // Configure Dates
            this.jobEventDate = this.punchCard.startDate;
            this.startDate = this.punchCard.startDate;
            this.endDate = this.punchCard.endDate;
            this.getJobs();
            this.getTrucks();
            this.getDrivers();
        }
    };
    EditPunchCardDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobsReq) {
            this.jobsReq.unsubscribe();
        }
        if (this.jobEventsReq) {
            this.jobEventsReq.unsubscribe();
        }
        if (this.driversReq) {
            this.driversReq.unsubscribe();
        }
        if (this.trucksReq) {
            this.trucksReq.unsubscribe();
        }
    };
    EditPunchCardDialogComponent.prototype.submitPunchCard = function () {
        var _this = this;
        this.loading = true;
        this.punchCardService.save(this.punchCard).subscribe(function (res) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardDialogComponent.prototype.getPunchCard = function (punchCardId) {
        var _this = this;
        this.loading = true;
        this.punchCardService.getPunchCard(punchCardId).subscribe(function (punchCard) {
            _this.punchCard = punchCard;
            _this.job = _this.punchCard.job;
            _this.jobEventDate = punchCard.startDate;
            _this.startDate = punchCard.startDate;
            _this.endDate = punchCard.endDate;
            _this.getJobs();
            _this.getTrucks();
            _this.getDrivers();
        }, function (err) {
            _this.loading = false;
        });
    };
    EditPunchCardDialogComponent.prototype.onJobEventDateChanged = function (values) {
        if (values && values.length) {
            this.jobEventDate = values[0];
        }
        this.getJobEvents();
    };
    EditPunchCardDialogComponent.prototype.onStartDateChanged = function (values) {
        if (values && values.length) {
            this.punchCard.startDate = values[0];
        }
    };
    EditPunchCardDialogComponent.prototype.onEndDateChanged = function (values) {
        if (values && values.length) {
            this.punchCard.endDate = values[0];
        }
    };
    EditPunchCardDialogComponent.prototype.onStartTimeChange = function (value) {
        var time = value.target.value;
        this.punchCard.startTime = time;
    };
    EditPunchCardDialogComponent.prototype.onEndTimeChange = function (value) {
        var time = value.target.value;
        this.punchCard.endTime = time;
    };
    EditPunchCardDialogComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                this.job = e;
                this.disableDates(this.job.startDate, this.job.endDate);
                this.getJobEvents({}, true);
                break;
            case 'driver':
                this.driver = e;
                break;
            case 'truck':
                this.truck = e;
                break;
        }
        this.punchCard[filterType] = e;
    };
    EditPunchCardDialogComponent.prototype.getJobs = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.jobDropdownOptions = [];
        this.jobDropdownConfig.loadingOptions = true;
        this.jobDropdownConfig = lodash_1.clone(this.jobDropdownConfig);
        if (this.jobsReq) {
            this.jobsReq.unsubscribe();
        }
        this.jobsReq = this.jobService.list(__assign({ ordering: 'project__name,name,start_date' }, query)).subscribe(function (jobs) {
            _this.jobDropdownOptions = jobs;
            setTimeout(function () {
                var jobevent = _this.punchCard.jobEvent;
                if (jobevent) {
                    if (jobevent.job) {
                        _this.jobDropdownOptions = lodash_1.reject(_this.jobDropdownOptions, jobevent.job);
                        _this.jobDropdownOptions.unshift(jobevent.job);
                        _this.job = new job_serializer_1.JobSerializer().fromJson(lodash_1.find(_this.jobDropdownOptions, { id: jobevent.job.id }));
                        _this.disableDates(_this.job.startDate, _this.job.endDate);
                        if (_this.job) {
                            _this.jobDropdown.selectedOption = _this.job;
                        }
                    }
                }
            }, 0);
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
            _this.jobDropdownConfig.loadingOptions = false;
            _this.jobDropdownConfig = lodash_1.clone(_this.jobDropdownConfig);
        });
    };
    EditPunchCardDialogComponent.prototype.getJobEvents = function (query, setSelected) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (setSelected === void 0) { setSelected = false; }
        this.loading = true;
        this.jobEventError = null;
        if (this.jobEventsReq) {
            this.jobEventsReq.unsubscribe();
        }
        var jobEventDateStart = new Date();
        jobEventDateStart.setHours(0, 0, 0, 0);
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        if (this.jobEventDate) {
            jobEventDateStart = new Date(this.jobEventDate);
            jobEventDateStart.setHours(0, 0, 0, 0);
            jobEventDateEnd = lodash_1.clone(jobEventDateStart);
            jobEventDateEnd.setHours(23, 59, 59, 999);
        }
        this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ job: this.job ? this.job.id : this.punchCard.jobEvent.job.id, shift1_start__gte: jobEventDateStart.toISOString(), shift1_start__lte: jobEventDateEnd.toISOString(), ordering: '-shift1_start' }, query)).subscribe(function (jobEvents) {
            if (jobEvents.length) {
                if (setSelected) {
                    var _jobEvent = jobEvents[0];
                    var _date = new Date(_this.jobEventDate);
                    if (_jobEvent.jobStart.setHours(0, 0, 0, 0) === _date.setHours(0, 0, 0, 0)) {
                        _this.jobEvent = _jobEvent;
                        _this.punchCard.jobEvent = _this.jobEvent;
                    }
                    else {
                        _this.jobEventDate = null;
                        _this.jobEventError = _this.translateService.instant('No Job Event found for that date!');
                    }
                }
                else {
                    _this.jobEvent = jobEvents[0];
                    _this.punchCard.jobEvent = _this.jobEvent;
                }
            }
            else {
                _this.jobEventDate = null;
                _this.jobEventError = _this.translateService.instant('No Job Event found for that date!');
            }
            _this.loading = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    EditPunchCardDialogComponent.prototype.getDrivers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.driverDropdownOptions = [];
        this.driverDropdownConfig.loadingOptions = true;
        this.driverDropdownConfig = lodash_1.clone(this.driverDropdownConfig);
        if (this.driversReq) {
            this.driversReq.unsubscribe();
        }
        this.driversReq = this.driverService.list(__assign({ ordering: 'name', all_carriers: 'True' }, query)).subscribe(function (drivers) {
            _this.driverDropdownOptions = drivers;
            setTimeout(function () {
                var driver = _this.punchCard.driver;
                _this.driverDropdownOptions = lodash_1.reject(_this.driverDropdownOptions, driver);
                _this.driverDropdownOptions.unshift(driver);
                _this.driverDropdown.selectedOption = lodash_1.find(_this.driverDropdownOptions, { id: driver.id });
            }, 0);
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
            _this.driverDropdownConfig.loadingOptions = false;
            _this.driverDropdownConfig = lodash_1.clone(_this.driverDropdownConfig);
        });
    };
    EditPunchCardDialogComponent.prototype.getTrucks = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.truckDropdownOptions = [];
        this.truckDropdownConfig.loadingOptions = true;
        this.truckDropdownConfig = lodash_1.clone(this.truckDropdownConfig);
        if (this.trucksReq) {
            this.trucksReq.unsubscribe();
        }
        this.trucksReq = this.truckService.list(__assign({ ordering: 'name' }, query)).subscribe(function (trucks) {
            _this.truckDropdownOptions = trucks;
            setTimeout(function () {
                var truck = (new truck_serializer_1.TruckSerializer().fromJson(_this.punchCard.truck));
                _this.truckDropdownOptions = lodash_1.reject(_this.truckDropdownOptions, truck);
                _this.truckDropdownOptions.unshift(truck);
                _this.truckDropdown.selectedOption = lodash_1.find(_this.truckDropdownOptions, { id: truck.id });
            }, 0);
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
            _this.truckDropdownConfig.loadingOptions = false;
            _this.truckDropdownConfig = lodash_1.clone(_this.truckDropdownConfig);
        });
    };
    EditPunchCardDialogComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'job':
                this.getJobs({ search: term });
                break;
            case 'truck':
                this.getTrucks({ search: term });
                break;
            case 'driver':
                this.getDrivers({ search: term });
                break;
        }
    };
    EditPunchCardDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'job':
                config = this.jobDropdownConfig;
                service = this.jobService;
                options = this.jobDropdownOptions;
                break;
            case 'driver':
                config = this.driverDropdownConfig;
                service = this.driverService;
                options = this.driverDropdownOptions;
                break;
            case 'truck':
                config = this.truckDropdownConfig;
                service = this.truckService;
                options = this.truckDropdownOptions;
                break;
        }
        if (!config.loadingOptions) {
            var o = service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'job':
                            _this.jobDropdownOptions = _this.jobDropdownOptions.concat(results);
                            break;
                        case 'driver':
                            _this.driverDropdownOptions = _this.driverDropdownOptions.concat(results);
                            break;
                        case 'truck':
                            _this.truckDropdownOptions = _this.truckDropdownOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    EditPunchCardDialogComponent.prototype.disableDates = function (startDate, endDate) {
        var copy = JSON.parse(JSON.stringify(this.jobEventDatePickerOptions));
        var min = new Date(startDate);
        var max = new Date(endDate);
        max.setDate(max.getDate() + 2);
        copy.min = min;
        copy.max = max;
        this.jobEventDatePickerOptions = copy;
    };
    return EditPunchCardDialogComponent;
}());
exports.EditPunchCardDialogComponent = EditPunchCardDialogComponent;
