"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
var location_service_1 = require("./location.service");
var location_type_service_1 = require("./location-type.service");
var authentication_service_1 = require("../shared/authentication.service");
var location_owner_service_1 = require("./location-owner.service");
var api_service_1 = require("../shared/api.service");
var map_1 = require("@ngui/map");
var static_data_1 = require("../shared/static-data");
var shared_1 = require("../shared");
var address_1 = require("./address");
var location_owner_serializer_1 = require("./location-owner.serializer");
var job_service_1 = require("../jobs/job.service");
var NewLocationComponent = /** @class */ (function () {
    function NewLocationComponent(route, router, locationService, jobService, apiService, locationOwnerService, authenticationService, deviceDetectorService, ngZone) {
        this.route = route;
        this.router = router;
        this.locationService = locationService;
        this.jobService = jobService;
        this.apiService = apiService;
        this.locationOwnerService = locationOwnerService;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.ngZone = ngZone;
        this.loading = false;
        this.showLocationOwnerTextField = false;
        this.model = {
            latitude: 29.9291564, longitude: -94.9320884, geofence: null, tags: [],
            locationOwner: { name: '', id: null, organization: null }
        };
        this.errors = [];
        this.states = [];
        this.stateOptions = [];
        this.countryOptions = [];
        this.ownerOptions = [];
        this.ownersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.address = new address_1.Address();
        this.selectedAddress = new address_1.Address();
        this.addresses = [];
        this.zoom = 14;
        this.drawingMode = undefined;
        this.paths = [];
        this.mapOptions = {};
        this.qrEnabled = false;
        this.laserFicheEnabled = false;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.canGeolocate = false;
        this.locationTypeDropdownConfig = {
            service: location_type_service_1.LocationTypeService,
            selectText: 'Select Location Type',
            loadingText: 'Loading Location Types...',
            noResultsText: 'No Location Types'
        };
        this.isGeoFenceAvailable = false;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        if (navigator && navigator.geolocation) {
            this.canGeolocate = true;
        }
    }
    NewLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.states = this.apiService.getStates();
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name
            };
        });
        this.selectCountry('US');
        this.mapOptions = static_data_1.mapOptions({
            zoom: 10,
            disableDefaultUI: true,
            scrollwheel: true,
            fullscreenControl: false,
            streetViewControl: false,
            center: null,
            geoFallbackCenter: null
        }, {}, {
            mapStyle: 'google-map-style'
        });
        var user = this.authenticationService.user();
        this.qrEnabled = user && user.organization && user.organization.qrEnabled;
        var enabledFeatures = this.authenticationService.enabledFeatures();
        if (enabledFeatures && enabledFeatures.includes('useLaserficheExport')) {
            this.laserFicheEnabled = true;
        }
        this.getLocationOwners();
        this.route.queryParams.forEach(function (params) {
            _this.returnTo = params['returnTo'];
            _this.autoApplyJob = params['job'];
            _this.jobLocationType = params['type'];
        });
        this.organization = this.authenticationService.getOrganization();
        if (this.organization && this.organization.defaultAverageLoadingTime) {
            this.model.averageLoadingTime = this.organization.defaultAverageLoadingTime;
        }
        if (this.organization && this.organization.defaultAverageUnloadingTime) {
            this.model.averageUnloadingTime = this.organization.defaultAverageUnloadingTime;
        }
    };
    NewLocationComponent.prototype.ngOnDestroy = function () {
        if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
            this.locationOwnersReq.unsubscribe();
        }
    };
    NewLocationComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        if (this.model.latitude && this.model.longitude) {
            var coordinates = this.model.latitude.toString() + ',' + this.model.longitude.toString();
            this.locationService.getLocationTimezone(coordinates).pipe(operators_1.mergeMap(function (timezoneRes) {
                _this.model.timezone = timezoneRes['timeZoneId'];
                return _this.locationService.save(_this.model);
            })).subscribe(function (res) {
                var _a;
                if (_this.autoApplyJob) {
                    _this.jobService.save((_a = { id: _this.autoApplyJob }, _a[_this.jobLocationType + "Location"] = res, _a)).subscribe(function () {
                        _this.loading = false;
                        _this.router.navigate([_this.returnTo]);
                    }, function (err) { return _this.errors.push('The job location was not updated!'); });
                }
                else {
                    _this.loading = false;
                    _this.router.navigate([_this.returnTo]);
                }
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
        else {
            this.errors.push('No location coordinates provided!');
        }
    };
    NewLocationComponent.prototype.cancel = function () {
        this.loading = false;
        this.router.navigate([this.returnTo]);
    };
    NewLocationComponent.prototype.tagChange = function (tags) {
        this.model['tags'] = tags.map(function (t) { return (t.name); });
    };
    NewLocationComponent.prototype.selectState = function (state) {
        this.model.state = state;
        this.geolocate();
    };
    NewLocationComponent.prototype.selectCountry = function (country) {
        this.model.country = country;
        this.stateOptions = lodash_1.filter(this.states, { country: country }).map(function (state) {
            return {
                id: state.abbreviation,
                name: state.name
            };
        });
    };
    NewLocationComponent.prototype.getLocationOwners = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
            this.locationOwnersReq.unsubscribe();
        }
        this.locationOwnersReq = this.locationOwnerService.list(__assign({ ordering: 'organization__name' }, query)).subscribe(function (locationOwners) {
            _this.ownerOptions = locationOwners.map(function (locationOwner) {
                return { name: locationOwner.name, id: locationOwner.id };
            });
            var ownOrganization = _this.authenticationService.getOrganization();
            if (ownOrganization) {
                var _locationOwner = new location_owner_serializer_1.LocationOwnerSerializer().fromJson({
                    organization: ownOrganization.id,
                    name: ownOrganization.name
                });
                _this.ownerOptions = lodash_1.reject(_this.ownerOptions, _locationOwner);
                _this.ownerOptions.unshift(_locationOwner);
            }
            if (_this.model.locationOwner && _this.model.locationOwner.id) {
                _this.ownerOptions = lodash_1.reject(_this.ownerOptions, _this.model.locationOwner);
                _this.ownerOptions.unshift(_this.model.locationOwner);
                _this.ownersDropdown.selectedOption = lodash_1.find(_this.ownerOptions, { id: _this.model.locationOwner.id });
            }
            _this.ownerOptions.unshift({
                name: 'Add a New Owner',
                id: 'new-location-owner',
                button: true
            });
            if (_this.ownersDropdown) {
                _this.ownersDropdown.config.loadingOptions = false;
            }
            _this.ownersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.ownersConfig.loadingOptions = false;
        });
    };
    NewLocationComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'owner':
                config = this.ownersConfig;
                service = this.locationOwnerService;
                options = this.ownerOptions;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'owner':
                            var _ownerOptions = results.map(function (result) {
                                return { name: result.name, id: result.id };
                            });
                            _this.ownerOptions = _this.ownerOptions.concat(_ownerOptions);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    NewLocationComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'owner':
                this.getLocationOwners({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    NewLocationComponent.prototype.selectOwner = function (owner) {
        if (owner['id'] === 'new-location-owner') {
            this.showLocationOwnerTextField = true;
            this.model.locationOwner.id = null;
        }
        else {
            this.model.locationOwner = owner;
        }
    };
    NewLocationComponent.prototype.placeChanged = function (place) {
        var _this = this;
        if (place && place.geometry) {
            // set latitude, longitude and zoom
            this.model.latitude = place.geometry.location && place.geometry.location.lat();
            this.model.longitude = place.geometry.location && place.geometry.location.lng();
            this.model.marker = {
                latitude: this.model.latitude, longitude: this.model.longitude
            };
            this.zoom = 14;
            this.address.street = null;
            this.model.street = this.extractFromAddress(place.address_components, 'street_number')
                + ' '
                + this.extractFromAddress(place.address_components, 'route');
            this.model.city = this.extractFromAddress(place.address_components, 'locality');
            this.countryOptions.map(function (country) {
                if (country.name === _this.extractFromAddress(place.address_components, 'country')) {
                    _this.selectCountry(country.id);
                }
            });
            this.states.map(function (state) {
                if (state.name === _this.extractFromAddress(place.address_components, 'administrative_area_level_1')) {
                    _this.model.state = state.abbreviation;
                }
            });
            this.model.zipcode = this.extractFromAddress(place.address_components, 'postal_code');
            this.centerOnCoordinates({ latitude: this.model.latitude, longitude: this.model.longitude });
            this.model.name = place.name;
            if (this.model.street && this.model.street.trim().length === 0) {
                this.model.street = this.model.name;
            }
        }
        else {
            this.errors.push('No location data could be found for the input address. Please try again.');
        }
    };
    NewLocationComponent.prototype.geolocate = function () {
        var _this = this;
        var displayName = this.model.street + " " + this.model.street2 + ", " + this.model.city + ", " + this.model.state + " " + this.model.zipcode;
        if (this.model.street && this.model.street.trim().length > 0) {
            displayName = displayName.replace('undefined', '');
            displayName = displayName.replace(' ,', ',');
        }
        this.address = {
            street: this.model.street,
            street2: null,
            city: this.model.city,
            state: this.model.state,
            zipcode: this.model.zipcode,
            country: this.model.country,
            displayName: displayName
        };
        var status = Object.keys(this.address).every(function (x) {
            return _this.address[x] && _this.address[x].length;
        }) === true;
        var address = Object.keys(this.address).map(function (x) {
            return _this.address[x];
        }).join(', ');
        this.copyUpdatedAddress();
        if (status) {
            this.locationService.getLocationByAddress(address).subscribe(function (res) {
                var coordinates = res && res[0];
                if (!_this.model.latitude || !_this.model.longitude) {
                    _this.model.latitude = coordinates.geometry.location.lat;
                    _this.model.longitude = coordinates.geometry.location.lng;
                    _this.model.marker = {
                        latitude: _this.model.latitude, longitude: _this.model.longitude
                    };
                }
                _this.centerOnCoordinates({ latitude: _this.model.latitude, longitude: _this.model.longitude });
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
        else {
            this.centerOnCoordinates({ latitude: this.model.latitude, longitude: this.model.longitude });
        }
    };
    NewLocationComponent.prototype.initMap = function (event) {
        var _this = this;
        this.map = event;
        this.initDrawingManager();
        if (!navigator.geolocation) {
            this.centerOnOrganization();
            console.log('Position not supported!');
        }
        else {
            this.geolocationId = navigator.geolocation.watchPosition(function (position) {
                _this.centerOnCoordinates(position.coords);
            }, function (error) {
                switch (error.code) {
                    case error.TIMEOUT:
                        console.log('Position timed out!');
                        break;
                    case error.PERMISSION_DENIED:
                        console.log('Permission was denied!');
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log('Position unavailable!');
                        break;
                    default:
                        console.log('error', error);
                        break;
                }
                _this.centerOnOrganization();
            }, {
                maximumAge: 600000, timeout: 10000
            });
        }
    };
    NewLocationComponent.prototype.centerOnCoordinates = function (coordinates) {
        var fallbackCenter = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);
        this.mapOptions = static_data_1.mapOptions({
            zoom: 15,
            disableDefaultUI: true,
            scrollwheel: true,
            fullscreenControl: false,
            streetViewControl: false,
            center: fallbackCenter,
            geoFallbackCenter: fallbackCenter
        }, {}, {
            mapStyle: 'google-map-style'
        });
        navigator.geolocation.clearWatch(this.geolocationId);
        this.geolocationId = null;
        google.maps.event.trigger(this.map, 'resize');
    };
    NewLocationComponent.prototype.centerOnOrganization = function () {
        var _this = this;
        if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
            this.organizationLocationReq.unsubscribe();
        }
        this.locationService.getLocationByAddress(this.organization.street + ", " + this.organization.location).subscribe(function (coords) {
            if (coords && coords[0] && coords[0].geometry) {
                var fallbackCenter = new google.maps.LatLng(coords[0].geometry.location.lat, coords[0].geometry.location.lng);
                _this.mapOptions = static_data_1.mapOptions({
                    zoom: 10,
                    disableDefaultUI: true,
                    scrollwheel: true,
                    fullscreenControl: false,
                    streetViewControl: false,
                    center: fallbackCenter,
                    geoFallbackCenter: fallbackCenter
                }, {}, {
                    mapStyle: 'google-map-style'
                });
                navigator.geolocation.clearWatch(_this.geolocationId);
                _this.geolocationId = null;
                google.maps.event.trigger(_this.map, 'resize');
            }
        }, function (err) { return _this.errors = ['Cannot get your organizations location']; });
    };
    NewLocationComponent.prototype.initDrawingManager = function () {
        var _this = this;
        if (this.drawingManager) {
            this.drawingManager['initialized$'].subscribe(function (dm) {
                google.maps.event.addListener(dm, 'overlaycomplete', function (event) {
                    dm.map.data.setStyle({
                        editable: true,
                        draggable: true
                    });
                    if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                        _this.drawingComplete(event.overlay);
                        dm.setDrawingMode(null);
                    }
                });
            });
        }
    };
    NewLocationComponent.prototype.addMarker = function (event) {
        if (!this.model.marker && event.latLng) {
            this.model.latitude = event.latLng.lat();
            this.model.longitude = event.latLng.lng();
            this.model.marker = {
                latitude: this.model.latitude, longitude: this.model.longitude
            };
            this.zoom = 18;
        }
        this.reverseGeocode();
    };
    NewLocationComponent.prototype.markerDragEnd = function (event) {
        if (event.coords) {
            this.model.latitude = event.coords.lat;
            this.model.longitude = event.coords.lng;
        }
        else if (event.latLng) {
            this.model.latitude = event.latLng.lat();
            this.model.longitude = event.latLng.lng();
        }
        this.reverseGeocode();
    };
    NewLocationComponent.prototype.extractFromAddress = function (components, type, property) {
        if (property === void 0) { property = null; }
        for (var i = 0; i < components.length; i++) {
            for (var j = 0; j < components[i].types.length; j++) {
                if (components[i].types[j] === type) {
                    return property ? components[i][property] : components[i].long_name;
                }
            }
        }
        return '';
    };
    NewLocationComponent.prototype.reverseGeocode = function () {
        var _this = this;
        if (this.model.latitude && this.model.longitude) {
            var coordinates = [this.model.latitude, this.model.longitude];
            this.locationService.getAddressByCoordinates(coordinates).subscribe(function (res) {
                _this.addresses = _this.processAddresses(res);
                _this.selectedAddress = _this.addresses[0];
                _this.address = _this.addresses[0];
                if (_this.model && !_this.model.street) {
                    _this.copyUpdatedAddress();
                }
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    NewLocationComponent.prototype.processAddresses = function (results) {
        var _this = this;
        var addresses = [];
        results.forEach(function (result) {
            var address = new address_1.Address();
            address.street = _this.extractFromAddress(result.address_components, 'street_number')
                + ' '
                + _this.extractFromAddress(result.address_components, 'route');
            address.city = _this.extractFromAddress(result.address_components, 'locality');
            _this.apiService.getStates().map(function (state) {
                if (state.name === _this.extractFromAddress(result.address_components, 'administrative_area_level_1') ||
                    state.abbreviation === _this.extractFromAddress(result.address_components, 'administrative_area_level_1', 'short_name')) {
                    address.state = state.abbreviation;
                }
            });
            address.zipcode = _this.extractFromAddress(result.address_components, 'postal_code');
            _this.countryOptions.map(function (country) {
                if (country.name === _this.extractFromAddress(result.address_components, 'country')) {
                    address.country = country.id;
                }
            });
            address.displayName = address.street + " " + address.street2 + ", " + address.city + ", " + address.state + " " + address.zipcode;
            if (address.street && address.street.trim().length > 0) {
                address.displayName = address.displayName.replace('undefined', '');
                address.displayName = address.displayName.replace(' ,', ',');
                addresses.push(address);
            }
        });
        return addresses;
    };
    NewLocationComponent.prototype.updateAddress = function (address) {
        if (address && this.model) {
            this.model.street = address.street;
            this.model.city = address.city;
            this.model.state = address.state;
            this.model.zipcode = address.zipcode;
            this.model.country = address.country;
        }
    };
    NewLocationComponent.prototype.copyUpdatedAddress = function () {
        if (this.address && this.model && this.address.street !== this.model.street) {
            this.selectCountry(this.address.country);
            this.model.street = this.address.street;
            this.model.city = this.address.city;
            this.model.state = this.address.state;
            this.model.zipcode = this.address.zipcode;
            this.model.country = this.address.country;
        }
        this.address = new address_1.Address();
    };
    NewLocationComponent.prototype.drawingComplete = function (overlay) {
        var _this = this;
        var map = overlay.map;
        // Remove all existing features since we only support one right now.
        map.data.forEach(function (feature) {
            map.data.remove(feature);
        });
        map.data.add(new google.maps.Data.Feature({
            geometry: new google.maps.Data.Polygon([overlay.getPath().getArray()])
        }));
        this.isGeoFenceAvailable = true;
        map.data.forEach(function (feature) {
            map.data.overrideStyle(feature, {
                strokeColor: '#FF8C00',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF8C00',
                fillOpacity: 0.35
            });
        });
        overlay.setMap(null); // Remove the original overlay from the map
        map.data.toGeoJson(function (json) {
            if (json && json.features && json.features.length) {
                _this.model.geofence = json.features[0].geometry;
            }
        });
        map.data.addListener('click', function (event) {
            _this.setSelectedOverlay(map, event.feature);
        });
        map.data.addListener('rightclick', function (event) {
            _this.deleteSelectedOverlay(map, event.feature);
        });
        map.data.addListener('setgeometry', function (event) {
            _this.setSelectedOverlay(map, event.feature);
            map.data.toGeoJson(function (json) {
                if (json && json.features && json.features.length) {
                    _this.model.geofence = json.features[0].geometry;
                }
            });
        });
    };
    NewLocationComponent.prototype.setSelectedOverlay = function (map, feature) {
        map.data.revertStyle();
        this.selectedOverlay = feature;
        map.data.overrideStyle(this.selectedOverlay, {
            fillColor: '#1D68D1'
        });
    };
    NewLocationComponent.prototype.deleteSelectedOverlay = function (map, overlay) {
        var _this = this;
        if (overlay === void 0) { overlay = null; }
        this.isGeoFenceAvailable = false;
        if (overlay) {
            this.selectedOverlay = overlay;
        }
        if (this.selectedOverlay) {
            map.data.remove(this.selectedOverlay);
            map.data.toGeoJson(function (json) {
                if (json && json.features && json.features.length) {
                    _this.model.geofence = json.features[0].geometry;
                }
            });
            delete this.selectedOverlay;
        }
    };
    NewLocationComponent.prototype.onSelect = function (propertyName, e) {
        this.model[propertyName] = e;
    };
    NewLocationComponent.prototype.getMyLocation = function () {
        var _this = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.model.longitude = position.coords.longitude;
                _this.model.latitude = position.coords.latitude;
            });
        }
        else {
            console.log('No support for geolocation');
        }
    };
    NewLocationComponent.prototype.clearGeoFence = function () {
        var _this = this;
        if (this.map) {
            this.map.data.forEach(function (feature) {
                _this.map.data.remove(feature);
            });
        }
        this.isGeoFenceAvailable = false;
    };
    return NewLocationComponent;
}());
exports.NewLocationComponent = NewLocationComponent;
