'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PaginationComponent = /** @class */ (function () {
    function PaginationComponent() {
        this.totalCount = 0;
        this.currentPage = 0;
        this.perPage = 20;
        this.hideSinglePage = true;
        this.page = new core_1.EventEmitter();
        this.totalPages = 0;
        this.pages = [];
        this.pagesCondensed = [];
    }
    PaginationComponent.prototype.ngOnInit = function () {
        this.setPageDetail();
    };
    PaginationComponent.prototype.ngOnChanges = function (changes) {
        this.setPageDetail();
    };
    PaginationComponent.prototype.setPage = function (page) {
        this.page.emit(page);
    };
    PaginationComponent.prototype.setPageDetail = function () {
        this.totalPages = Math.ceil(this.totalCount / this.perPage);
        this.pages = Array.from({ length: this.totalPages }, function (v, k) { return k + 1; });
        this.createPagination();
    };
    PaginationComponent.prototype.createPagination = function () {
        this.pagesCondensed = [];
        var delta = 2, left = this.currentPage - delta, right = this.currentPage + delta + 1, pageRange = [], l;
        for (var i = 1; i <= this.totalPages; i++) {
            if (i === 1 || i === this.totalPages || i >= left && i < right) {
                pageRange.push(i);
            }
        }
        for (var _i = 0, pageRange_1 = pageRange; _i < pageRange_1.length; _i++) {
            var i = pageRange_1[_i];
            if (l) {
                if (i - l === 2) {
                    this.pagesCondensed.push(l + 1);
                }
                else if (i - l !== 1) {
                    this.pagesCondensed.push('...');
                }
            }
            this.pagesCondensed.push(i);
            l = i;
        }
    };
    return PaginationComponent;
}());
exports.PaginationComponent = PaginationComponent;
