"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var resource_service_1 = require("../shared/resource.service");
var custom_field_serializer_1 = require("./custom-field.serializer");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var operators_1 = require("rxjs/operators");
var CustomFieldService = /** @class */ (function (_super) {
    __extends(CustomFieldService, _super);
    function CustomFieldService(http) {
        return _super.call(this, http, 'customfields/', new custom_field_serializer_1.CustomFieldSerializer()) || this;
    }
    CustomFieldService.prototype.updateFields = function (fields) {
        var validFields = fields.filter(function (field) { return field.key && field.key !== ''; });
        var sortedFields = lodash_1.groupBy(validFields, function (field) { return field.id ? 'old' : 'new'; });
        var requests = [];
        if (sortedFields.new && sortedFields.new.length > 0) {
            requests.push(this.save(sortedFields.new));
        }
        if (sortedFields.old && sortedFields.old.length > 0) {
            requests.push(this.bulkUpdate(sortedFields.old));
        }
        return rxjs_1.forkJoin(requests);
    };
    CustomFieldService.prototype.bulkUpdate = function (fields) {
        var resourceUrl = this.resourceUrl + 'bulkupdate/';
        var serializer = new custom_field_serializer_1.CustomFieldSerializer();
        var formattedFields = fields.map(function (field) { return serializer.toJson(field); });
        return this.http.put(resourceUrl, formattedFields, { headers: this.requestHeaders() });
    };
    CustomFieldService.prototype.getFieldsForKind = function (kind) {
        return this.list({ active: true, kind: kind }).pipe(operators_1.map(function (fieldList) { return fieldList.filter(function (field) { return field.kind === kind && field.active; }); }));
    };
    CustomFieldService.prototype.getIndexedFieldsForKind = function (kind) {
        return this.getFieldsForKind(kind).pipe(operators_1.map(function (fields) {
            return fields.reduce(function (collection, field) {
                collection[field.key] = field;
                return collection;
            }, {});
        }));
    };
    return CustomFieldService;
}(resource_service_1.ResourceService));
exports.CustomFieldService = CustomFieldService;
