import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'metersToMiles'})

export class MetersToMilesPipe implements PipeTransform {
  transform(input: any): any {
    let inches = 39.370078 * input;
    return Math.round(inches / 63360);
  }
}
