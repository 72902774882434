<div class="app-content">
  <form
    [formGroup]="editTripForm"
    (ngSubmit)="submitTrip()"
    class="trip-form"
    *ngIf="editTripForm"
  >
    <my-toolbar title="{{ 'Edit Trip' | translate }}"></my-toolbar>

    <action-bar>
      <div class="left-actions">{{ trip && trip.name }}</div>
      <div class="right-actions">
        <button
          class="btn btn-primary"
          (click)="submitTrip()"
          *ngIf="trip"
          [disabled]="loading || !editTripForm.valid || uploading"
          [ngClass]="{ loading: loading }"
        >
          Save
        </button>
      </div>
    </action-bar>

    <div class="trip-container" *ngIf="trip">
      <multiple-images-edit
        #editImagesContainer
        [editable]="true"
        [loadingImages]="loadingTicketImages"
        [unloadingImages]="unloadingTicketImages"
        [loadingSignatureImages]="loadingSignatures"
        [unloadingSignatureImages]="unloadingSignatures"
        [requiredFields]="requiredImages"
        [showLoadingImages]="
          jobEvent?.job?.checkinOptions?.ticketImageConfig !== 'hidden'
        "
        [showUnloadingImages]="
          jobEvent?.job?.checkoutOptions?.ticketImageConfig !== 'hidden'
        "
        (onLoadingImagesChange)="
          onImagesChange('loadingTicketImages', 'loadingCheckin', $event)
        "
        (onUnloadingImagesChange)="
          onImagesChange('unloadingTicketImages', 'unloadingCheckin', $event)
        "
        (onLoadingSignatureImagesChange)="
          onImagesChange('loadingSignatures', 'loadingCheckin', $event)
        "
        (onUnloadingSignatureImagesChange)="
          onImagesChange('unloadingSignatures', 'unloadingCheckin', $event)
        "
        (onUploading)="fileUploading($event)"
      >
      </multiple-images-edit>
      <notification *ngFor="let error of errors">{{ error }}</notification>
      <div
        class="error-container"
        *ngIf="editTripForm.errors && editTripForm.errors.globalTripTimeError"
      >
        <notification>
          <ng-container [ngSwitch]="editTripForm.errors.globalTripTimeError">
            <span *ngSwitchCase="'startLoadingTime'" translate
              >Loading Time can't be earlier than Trip Start Time</span
            >
            <span *ngSwitchCase="'endLoadingTime'" translate
              >Unloading Time can't be earlier than Trip Start Time / Loading
              time</span
            >
            <span *ngSwitchDefault translate
              >End Time can't be earlier than Trip Start Time / Loading Time /
              Unloading Time</span
            >
          </ng-container>
        </notification>
      </div>

      <div class="trip-data">
        <div class="form-content">
          <div class="field-group job full-width">
            <label class="required" translate>Job</label>
            <ruckit-dropdown
              #jobDropdown
              [config]="jobDropdownConfig"
              [selectedOption]="job"
              (onSelect)="onSelect('job', $event)"
            >
            </ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group job-event full-width">
            <label class="required" translate>Jobs Allowed</label>
            <date-range-picker
              [disabled]="!job"
              [config]="configRangePicker"
              [disabledDates]="disabledDates"
              [selectedDates]="jobEventDate"
              (dateChanged)="onJobEventDateChange($event)"
            ></date-range-picker>
          </div>
        </div>

        <notification *ngIf="jobEventError">{{ jobEventError }}</notification>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Carrier</label>
            <ruckit-dropdown
              #carriersDropdown
              [disabled]="!jobEvent"
              [config]="carrierDropdownConfig"
              [selectedOption]="carrier"
              (onSelect)="onSelect('carrier', $event)"
            ></ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Driver</label>
            <ruckit-dropdown
              #driversDropdown
              [disabled]="!carrier"
              [config]="driverDropdownConfig"
              [selectedOption]="driver"
              (onSelect)="onSelect('driver', $event)"
            >
            </ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group truck full-width">
            <label class="required" translate>Truck</label>
            <ruckit-dropdown
              #truckDropdown
              [disabled]="!carrier"
              [config]="truckDropdownConfig"
              [selectedOption]="truck"
              (onSelect)="onSelect('truck', $event)"
            >
            </ruckit-dropdown>
          </div>
        </div>

        <!-- start trip start -->
        <div class="form-content time" formGroupName="start">
          <div>
            <label class="required" translate>Trip Start Time</label>
            <date-range-picker
              [disabled]="!jobEvent"
              [selectedDates]="[startDate]"
              [highlightedDates]="jobDates"
              (dateChanged)="onDateChange(['start', 'date'], $event)"
            >
            </date-range-picker>
          </div>
          <div>
            <input type="time" name="start_time" formControlName="time" />
          </div>
        </div>
        <!-- end trip start -->

        <!-- start trip end -->
        <div class="form-content time end-time" formGroupName="end">
          <div>
            <label translate>Trip End Time</label>
            <date-range-picker
              [disabled]="!jobEvent"
              [selectedDates]="[endDate]"
              [highlightedDates]="jobDates"
              (dateChanged)="onDateChange(['end', 'date'], $event)"
            >
            </date-range-picker>
          </div>

          <div>
            <input type="time" name="end_time" formControlName="time" />
          </div>
        </div>
        <!-- end trip end -->
      </div>
      <!-- end trip data -->

      <div class="checkin-data">
        <!-- start loading checkin data -->
        <div class="new-checkin" formGroupName="loadingCheckin">
          <div class="form-content">
            <div
              class="field-group ticket-number"
              *ngIf="
                jobEvent?.job?.checkinOptions?.ticketNumberConfig !== 'hidden'
              "
            >
              <label
                [ngClass]="{
                  required:
                    jobEvent?.job?.checkinOptions?.ticketNumberConfig ===
                    'required'
                }"
                for="loading-ticket-number"
                translate
                >Loading Ticket #</label
              >
              <input
                type="text"
                name="loading-ticket-number"
                formControlName="ticketNumber"
              />
            </div>

            <div
              class="field-group weight"
              *ngIf="jobEvent?.job?.checkinOptions?.weightConfig !== 'hidden'"
            >
              <label
                [ngClass]="{
                  required:
                    jobEvent?.job?.checkinOptions?.weightConfig === 'required'
                }"
                translate
                >Loading Weight</label
              >
              <input
                type="number"
                name="loading-weight"
                placeholder=""
                formControlName="weight"
              />
            </div>

            <div
              class="field-group time"
              *ngIf="
                jobEvent?.job?.checkinOptions?.activeTrackingConfig !== 'hidden'
              "
            >
              <div>
                <label
                  [ngClass]="{
                    required:
                      jobEvent?.job?.checkinOptions?.activeTrackingConfig ===
                      'required'
                  }"
                  translate
                  >Loading Time</label
                >
                <date-range-picker
                  [disabled]="!jobEvent"
                  [selectedDates]="[loadingCheckingDate]"
                  [highlightedDates]="jobDates"
                  (dateChanged)="
                    onDateChange(['loadingCheckin', 'date'], $event)
                  "
                >
                </date-range-picker>
              </div>
              <div>
                <input type="time" name="loading_time" formControlName="time" />
              </div>
            </div>
          </div>

          <div
            class="field-group ticket-image"
            *ngIf="
              jobEvent?.job?.checkinOptions?.ticketImageConfig !== 'hidden'
            "
          >
            <label
              translate
              [ngClass]="{
                required:
                  requiredImages.loadingImages ||
                  requiredImages.loadingSignature
              }"
              >Loading Ticket Image(s)</label
            >
            <ng-container
              *ngIf="
                loadingTicketImages
                  | filterBy : 'isDeleted' : true : true as images
              "
            >
              <ng-container
                *ngIf="
                  loadingSignatures
                    | filterBy : 'isDeleted' : true : true as signatureImages
                "
              >
                <div
                  class="images-count-container"
                  (click)="handleImagesDialogVisibility()"
                >
                  <span
                    *ngIf="images.length + signatureImages.length !== 1"
                    translate
                    >{{ images.length + signatureImages.length }} Images</span
                  >
                  <span
                    *ngIf="images.length + signatureImages.length === 1"
                    translate
                    >1 Image</span
                  >
                </div>
                <div
                  class="upload-image-dropzone"
                  *ngIf="
                    (images && images.length) ||
                    (signatureImages && signatureImages.length)
                  "
                  (click)="handleImagesDialogVisibility()"
                >
                  <image-edit
                    [image]="images.length ? images[0] : signatureImages[0]"
                    [options]="{ editable: false, disabled: true }"
                    (uploadData)="fileChange('loadingTicketImages', $event)"
                    (uploading)="fileUploading($event)"
                  >
                  </image-edit>
                </div>
                <image-edit
                  *ngIf="images.length === 0 && signatureImages.length === 0"
                  [image]="images[0]"
                  [options]="{ editable: true, light: true }"
                  (uploadData)="fileChange('loadingTicketImages', $event)"
                  (uploading)="fileUploading($event)"
                >
                </image-edit>
              </ng-container>
            </ng-container>
            <split-button
              title="{{ 'Request Ticket Retake' | translate }}"
              *ngIf="trip && trip.loadingCheckin && trip.loadingCheckin.id"
              buttonClasses="green-button"
              controlButtonClasses="green-button"
              [disabled]="trip.retakeRequested || loading"
              [ngClass]="{ loading: loading }"
              (onPrimaryAction)="requestRetake(trip.loadingCheckin); (false)"
            >
              <a
                translate
                class="undo-retake-request"
                (click)="undoRequestRetake(trip); (false)"
                [ngClass]="{
                  loading: loading,
                  disabled: !trip.retakeRequested || loading
                }"
              >
                Cancel Retake Request
              </a>
            </split-button>
          </div>
        </div>
        <!-- end loading checkin data -->

        <!-- start unloading checkout data -->
        <div class="new-checkin" formGroupName="unloadingCheckin">
          <div class="form-content">
            <div
              class="field-group ticket-number"
              *ngIf="
                jobEvent?.job?.checkoutOptions?.ticketNumberConfig !== 'hidden'
              "
            >
              <label
                [ngClass]="{
                  required:
                    jobEvent?.job?.checkoutOptions?.ticketNumberConfig ===
                    'required'
                }"
                translate
                >Unloading Ticket #</label
              >
              <input
                type="text"
                name="unloading-ticket-number"
                placeholder=""
                formControlName="ticketNumber"
              />
            </div>

            <div
              class="field-group weight"
              *ngIf="jobEvent?.job?.checkoutOptions?.weightConfig !== 'hidden'"
            >
              <label
                [ngClass]="{
                  required:
                    jobEvent?.job?.checkoutOptions?.weightConfig === 'required'
                }"
                translate
                >Unloading Weight</label
              >
              <input
                type="number"
                name="unloading-weight"
                placeholder=""
                formControlName="weight"
              />
            </div>

            <div
              class="field-group time"
              *ngIf="
                jobEvent?.job?.checkoutOptions?.activeTrackingConfig !==
                'hidden'
              "
            >
              <div>
                <label
                  [ngClass]="{
                    required:
                      jobEvent?.job?.checkoutOptions?.activeTrackingConfig ===
                      'required'
                  }"
                  translate
                  >Unloading Time</label
                >
                <date-range-picker
                  [disabled]="!jobEvent"
                  [selectedDates]="[unloadingCheckingDate]"
                  [highlightedDates]="jobDates"
                  (dateChanged)="
                    onDateChange(['unloadingCheckin', 'date'], $event)
                  "
                >
                </date-range-picker>
              </div>
              <div>
                <input
                  type="time"
                  name="unloading_time"
                  formControlName="time"
                />
              </div>
            </div>
          </div>

          <div
            class="field-group ticket-image"
            *ngIf="
              jobEvent?.job?.checkoutOptions?.ticketImageConfig !== 'hidden'
            "
          >
            <label
              translate
              [ngClass]="{
                required:
                  requiredImages.unloadingImages ||
                  requiredImages.unloadingSignature
              }"
              >Unloading Ticket Image(s)</label
            >
            <ng-container
              *ngIf="
                unloadingTicketImages
                  | filterBy : 'isDeleted' : true : true as images
              "
            >
              <ng-container
                *ngIf="
                  unloadingSignatures
                    | filterBy : 'isDeleted' : true : true as signatureImages
                "
              >
                <div
                  class="images-count-container"
                  (click)="handleImagesDialogVisibility()"
                >
                  <span
                    *ngIf="images.length + signatureImages.length !== 1"
                    translate
                    >{{ images.length + signatureImages.length }} Images</span
                  >
                  <span
                    *ngIf="images.length + signatureImages.length === 1"
                    translate
                    >1 Image</span
                  >
                </div>
                <div
                  class="upload-image-dropzone"
                  *ngIf="
                    (images && images.length) ||
                    (signatureImages && signatureImages.length)
                  "
                  (click)="handleImagesDialogVisibility()"
                >
                  <image-edit
                    [image]="images.length ? images[0] : signatureImages[0]"
                    [options]="{ editable: false, disabled: true }"
                    (uploadData)="fileChange('unloadingTicketImages', $event)"
                    (uploading)="fileUploading($event)"
                  >
                  </image-edit>
                </div>
                <image-edit
                  *ngIf="images.length === 0 && signatureImages.length === 0"
                  [image]="images[0]"
                  [options]="{ editable: true, light: true }"
                  (uploadData)="fileChange('unloadingTicketImages', $event)"
                  (uploading)="fileUploading($event)"
                >
                </image-edit>
              </ng-container>
            </ng-container>

            <split-button
              title="{{ 'Request Ticket Retake' | translate }}"
              type="button"
              *ngIf="trip && trip.unloadingCheckin && trip.unloadingCheckin.id"
              buttonClasses="green-button"
              controlButtonClasses="green-button"
              [disabled]="trip.retakeRequested || loading"
              [ngClass]="{ loading: loading }"
              (onPrimaryAction)="requestRetake(trip.unloadingCheckin)"
            >
              <a
                type="button"
                translate
                class="undo-retake-request"
                (click)="undoRequestRetake(trip)"
                [ngClass]="{
                  loading: loading,
                  disabled: !trip.retakeRequested || loading
                }"
              >
                Cancel Retake Request
              </a>
            </split-button>
          </div>
        </div>
        <!-- end unloading checkin data -->
      </div>
    </div>
  </form>
</div>
