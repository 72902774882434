"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var resource_service_1 = require("../shared/resource.service");
var collaborator_stat_serializer_1 = require("./collaborator-stat.serializer");
var CollaboratorStatService = /** @class */ (function (_super) {
    __extends(CollaboratorStatService, _super);
    function CollaboratorStatService(http) {
        return _super.call(this, http, 'jobevents/stats-by-collaborators/', new collaborator_stat_serializer_1.CollaboratorStatSerializer()) || this;
    }
    return CollaboratorStatService;
}(resource_service_1.ResourceService));
exports.CollaboratorStatService = CollaboratorStatService;
