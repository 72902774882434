<form #publishJob="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Share With Marketplace</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors"><notification *ngFor="let error of errors">{{error}}</notification></div>

    <div class="content">
      <div *ngIf="job" class="publish-job">
        <div class="form-content">
          <p translate>Jobs shared with the Marketplace will be visible to all of your connections on Ruckit.</p>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group dates" fxFlex="100">
              <label class="required" translate>Select the dates to publish</label>
              <date-range-picker [config]="{multipleDates: true, decreaseControl: false, increaseControl: false}"
                [selectedDates]="model.data.dates"
                (dateChanged)="onDateChanged($event)"></date-range-picker>
            </div>
          </div>

          <div class="horizontal-divider"></div>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group truck-slots" fxFlex="30">
              <label class="required" translate>Truck Slots</label>
              <input type="text" numberInRange minValue=1 [maxValue]="jobevent.numTrucks" name="num_trucks" placeholder="{{jobevent.numTrucks ? 'Max: ' + jobevent.numTrucks : undefined }}" required [(ngModel)]="model.data.numTrucks" #numTrucks="ngModel" />
            </div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div *ngIf="job" class="job-detail">
        <div class="form-content">
          <h2>{{ jobevent.jobDisplayName }}</h2>

          <div class="job-locations">
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="field-group start-location" fxFlex="100">
                <div class="value">{{ job.startLocation && job.startLocation.name }}</div>
              </div>
            </div>
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="field-group end-location" fxFlex="100">
                <div class="value">{{ job.endLocation && job.endLocation.name }}</div>
              </div>
            </div>
          </div>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group invoice-rate" fxFlex="50">
              <div class="label" translate>Haul Rate</div>
              <div class="value"><span class="currency">{{ jobevent.haulRate || 0 | currency:'USD':'symbol':'1.2-4' }}</span> per {{ jobevent.haulRateUnit }}</div>
            </div>

            <div class="field-group material" fxFlex="50">
              <div class="label" translate>Material</div>
              <div class="value">{{ job.material }}</div>
            </div>
          </div>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group date-range" fxFlex="50">
              <div class="label" translate>Start and End Day</div>
              <div class="value">{{ job.startDate | date:'MMM d' }} – {{ job.endDate | date:'MMM d' }}</div>
              <div class="value" *ngIf="job.shifts > 1">&nbsp;</div>
            </div>

            <div class="field-group daily-schedule" fxFlex="50">
              <div class="label" translate>Daily Schedule</div>
              <div class="value">{{ jobevent.shift1Start }} - {{ jobevent.shift1End }}</div>
              <div class="value" *ngIf="job.shifts > 1">{{ jobevent.shift2Start }} - {{ jobevent.shift2End }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="publishJob.form.valid && submit(); false"
            [disabled]="!publishJob.form.valid || loading || model.data.numTrucks < 1"
            [ngClass]="{loading:loading}"
            translate>
      Share Job With Marketplace
    </button>
  </div>
</form>
