"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONDENSEDPUNCHCARD = {
    id: '00000000-0000-0000-0000-000000000001',
    customer: 'B\&R Broker Group LLC',
    customerId: '00000000-0000-0000-0000-000000000001',
    driver: 'John Doe',
    driverId: '00000000-0000-0000-0000-000000000001',
    startTimeTimestamp: '2017-07-27T11:22:49Z',
    endTimeTimestamp: '2017-07-27T11:24:32Z',
    haulRate: '100.00',
    haulType: 'load',
    haulWeightUnit: 'ton',
    invoiceRate: '100.00',
    invoiceTotal: '100.00',
    invoiceType: 'load',
    invoiceWeightUnit: 'ton',
    job: 'Specialty Hauling Services Export',
    jobDisplayName: 'Specialty Hauling Services Export',
    jobId: '00000000-0000-0000-0000-000000000001',
    project: 'Specialty Project',
    projectId: '00000000-0000-0000-0000-000000000001',
    ticketImage: null,
    ticketNumber: '1234',
    trips: [],
    truck: 'T-1000',
    truckId: '00000000-0000-0000-0000-000000000001',
    truckType: 'Tri-axle',
    administrativeStatus: '',
    displayableStatus: false,
    haulTotal: '0.00',
    void: false,
    edited: false,
    loading: false,
    expenseId: null,
    expenseNumber: null,
    expenseSent: false,
    paystubId: null,
    paystubNumber: null,
    paystubSent: false,
    url: function (action) {
        return '';
    },
    filterOptions: function (options) {
        return options;
    },
    jobDate: '2018-02-15',
    kind: 'invoice',
    invoiceWeightUnitDisplay: '',
    haulWeightUnitDisplay: '',
    pluralizedHaulRateUnit: function (plural) {
        if (plural === void 0) { plural = true; }
        return '';
    },
    pluralizedHaulWeightUnitDisplay: function (plural) {
        if (plural === void 0) { plural = true; }
        return '';
    },
    pluralizedInvoiceRateUnit: function (plural) {
        if (plural === void 0) { plural = true; }
        return '';
    },
    pluralizedInvoiceWeightUnitDisplay: function (plural) {
        if (plural === void 0) { plural = true; }
        return '';
    }
};
