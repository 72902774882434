"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".content[_ngcontent-%COMP%]{max-height:600px;overflow-y:auto}.content[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{font-size:20px;color:#354052;margin-top:30px;margin-bottom:5px}.content[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#7f8fa4;margin:0 0 30px}.content[_ngcontent-%COMP%]   p.job-specs[_ngcontent-%COMP%]{font-weight:400}.content[_ngcontent-%COMP%]   p.job-specs[_ngcontent-%COMP%]   span.label[_ngcontent-%COMP%]{font-weight:600}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]{border-bottom:1px solid #dee2e5;padding-bottom:15px;margin-bottom:15px}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]:last-child{border-bottom:0}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .collaborator-selection[_ngcontent-%COMP%]{display:flex;width:300px;padding-top:20px}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .collaborator-selection[_ngcontent-%COMP%]   .selector[_ngcontent-%COMP%]{margin-right:20px}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .collaborator-selection[_ngcontent-%COMP%]   .collaborator-name[_ngcontent-%COMP%]{color:#354052;font-size:16px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{color:#7f8fa4;font-weight:600;font-size:14px}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .rate[_ngcontent-%COMP%]{width:175px}.content[_ngcontent-%COMP%]   .collaborator[_ngcontent-%COMP%]   .trucks[_ngcontent-%COMP%]{width:100px}.modal-footer[_ngcontent-%COMP%]{flex-direction:column}.modal-footer[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{align-self:flex-end;width:160px}"];
exports.styles = styles;
