import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { JobEventService } from '../../job-events/job-event.service';
import { CondensedJobEventService } from '../../job-events/condensed-job-event.service';
import { Job } from '../../jobs/job';
import { JobService } from '../../jobs/job.service';
import { TripFilterService } from '../../trips/trip.filter.service';
import { EodFilter } from '../eod-filter';

@Component({
  selector: 'end-of-day-filters-dialog',
  templateUrl: './end-of-day-filters.component.html',
  styleUrls: ['./end-of-day-filters.component.scss'],
})
export class EndOfDayFiltersDialogComponent implements OnInit {
  @Input() title: string;
  @Input() uniqueCarriers: { id: string; name: string; selected: boolean }[];
  @Input() appliedFilters: EodFilter[];
  @Input() date: Date;

  assignedTruck: string;

  callback: Function;
  filters: EodFilter[];
  switchFilters: EodFilter[] = [];

  defaultDropdownConfig = {
    service: TripFilterService,
    serviceFunction: 'listFilters',
    searchKey: 'filter_search',
  };

  jobDropdownConfig = {
    service: JobService,
    selectText: this.translate.instant('Select Job'),
    loadingText: this.translate.instant('Loading Jobs...'),
    noResultsText: this.translate.instant('No Jobs'),
    searchable: true,
    group: true,
    groupBy: (job: Job) => job.project.name,
    groupProperty: 'project.name',
    sortBy: 'project__name,name,start_date',
    serializer: 'AllJobs',
    includeFullObject: true,
  };

  jobEventsConfig = {
    service: JobEventService,
    selectText: this.translate.instant('Select Job Event'),
    loadingText: this.translate.instant('Loading Job Events...'),
    noResultsText: this.translate.instant('No Job Events'),
    searchable: true,
    includeFullObject: true,
    nameProperty: 'jobNameWithDsOrderId',
    subtitleProperty: 'name',
    subtitle: true,
    disableSubtitleOnSelect: true,
    query: {
      shift1_start__gte: null,
      shift1_start__lte: null,
    },
  };

  projectDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: this.translate.instant('Select Project'),
    loadingText: this.translate.instant('Loading Projects...'),
    noResultsText: this.translate.instant('No Projects'),
    serviceFunctionScope: 'projects',
    getFilter: 'jobevent__job__project',
    query: { serializer: 'ProjectFilter' },
  };

  customerDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: this.translate.instant('Select Customer'),
    loadingText: this.translate.instant('Loading Customers...'),
    noResultsText: this.translate.instant('No Customers'),
    serviceFunctionScope: 'customers',
    getFilter: 'jobevent__customer_organization',
    query: { serializer: 'OrganizationFilter' },
  };
  carriers: { id: string; name: string; selected: boolean }[] = [];

  payableCustomerDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: this.translate.instant('Select Customer'),
    loadingText: this.translate.instant('Loading Customers...'),
    noResultsText: this.translate.instant('No Customers'),
    serviceFunctionScope: 'customers',
    getFilter: 'jobevent__customer_organization',
    query: { serializer: 'OrganizationFilter' },
  };

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<EndOfDayFiltersDialogComponent>
  ) {}

  ngOnInit() {
    this.carriers = [...this.uniqueCarriers];
    this.filters = [...this.appliedFilters];

    this.switchFilters = [...this.appliedFilters].filter(
      (af) => af.selected !== undefined && af.selected !== null
    );

    const assignedTruckFilter = this.appliedFilters.find(
      (f) => f.key === 'assigned_truck'
    );
    if (assignedTruckFilter.values && assignedTruckFilter.values.length) {
      this.assignedTruck =
        assignedTruckFilter.values &&
        assignedTruckFilter.values[0] &&
        assignedTruckFilter.values[0].value;
    }

    const carrierFilter = this.appliedFilters.find((f) => f.key === 'carrier');
    if (carrierFilter.values && carrierFilter.values.length) {
      this.carriers = this.uniqueCarriers.map((c) =>
        carrierFilter.values.some((cf) => cf.value === c.id)
          ? { ...c, selected: true }
          : c
      );
    }

    if (this.date) {
      const newDate = new Date(this.date);
      newDate.setDate(newDate.getDate() + 1);
      this.jobEventsConfig.query = {
        ...this.jobEventsConfig.query,
        shift1_start__gte: this.date.toISOString(),
        shift1_start__lte: newDate.toISOString(),
      };
    }
  }

  onSelect(filterType: string, e: any, property = 'name'): void {
    switch (filterType) {
      case 'carrier':
        this.carriers = this.uniqueCarriers.map((uniqC) => ({
          ...uniqC,
          selected: uniqC.id === e.id,
        }));
        break;

      default:
        break;
    }

    this.filters = this.filters.map((appliedFilter) =>
      appliedFilter.key === filterType
        ? { ...appliedFilter, values: [{ label: e[property], value: e.id }] }
        : appliedFilter
    );
  }

  onSwitch(filter: EodFilter) {
    this.switchFilters = this.switchFilters.map((f) =>
      f.key === filter.key ? { ...f, selected: !f.selected } : f
    );
  }

  onCarrierSearch(carrierName: string) {
    this.carriers = this.uniqueCarriers.filter((c) =>
      c.name.toLowerCase().includes(carrierName.toLowerCase())
    );
  }

  submit(): void {
    const filters = this.filters.map((f) => {
      const switchFilter = this.switchFilters.find((sf) => sf.key === f.key);
      if (
        f.key === 'assigned_truck' &&
        this.assignedTruck &&
        this.assignedTruck.length
      ) {
        f.values = [{ label: this.assignedTruck, value: this.assignedTruck }];
      }
      return switchFilter ? switchFilter : f;
    });
    this.callback(filters, this.carriers);
    this.dialogRef.close();
  }
}
