"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".collaborator-stats-container[_ngcontent-%COMP%]{padding:30px}.collaborator-stats-container[_ngcontent-%COMP%]   .dropdown-arrow[_ngcontent-%COMP%]{margin-top:-4px}.collaborator-stats-container[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#58b4f9;text-decoration:none}.collaborator-stats-container[_ngcontent-%COMP%]   .currency[_ngcontent-%COMP%], .collaborator-stats-container[_ngcontent-%COMP%]   .numeric[_ngcontent-%COMP%]{margin:0 auto}.collaborator-stats-container[_ngcontent-%COMP%]     .mat-column-truck-types{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.collaborator-stats-container[_ngcontent-%COMP%]     .mat-column-name{padding-right:10px}.collaborator-stats-container[_ngcontent-%COMP%]     .mat-column-detail .data{width:100%;padding:0}.collaborator-stats-container[_ngcontent-%COMP%]     .titleize{text-transform:capitalize}.collaborator-stats-container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]{height:calc(100vh - 144px - 60px - 30px - 30px - 200px)}"];
exports.styles = styles;
