import { Resource } from './resource';

export class DeviceStatusEvent extends Resource {
  deviceId: string = null;
  organization: string = null;
  driver: string = null;
  data: any = {};
  createdAt: string = null;
  locationAuthorization: string = null;
  lowPowerMode: boolean = null;
  lowDeviceStorage: boolean = null;
  backgroundAppRefresh: boolean = null;
  applicationName: string = null;
  applicationVersion: string = null;
  deviceOS: string = null;
  signal: string = null;
  battery: string = null;
}
