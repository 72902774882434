"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".time-input[_ngcontent-%COMP%]{background:url(/assets/img/icons/icn-time.svg) 12px center no-repeat,linear-gradient(to top,#f2f4f7,#fff) no-repeat;font-weight:600;color:#354052;padding-left:40px;display:inline-block;padding-right:20px;height:36px;border-radius:4px;border:1px solid #ced0da}input[_ngcontent-%COMP%]{display:inline-block;padding:0 0 2px;width:20px;text-align:right;border:0;background:0 0;font-weight:600}input[name=hours][_ngcontent-%COMP%]{padding-right:1px}input[name=minutes][_ngcontent-%COMP%]{width:15px}"];
exports.styles = styles;
