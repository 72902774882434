"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var index_1 = require("../../../shared/index");
var DriverPayTimelineComponent = /** @class */ (function () {
    function DriverPayTimelineComponent() {
        this.statsMinimized = false;
        this.readOnly = false;
        this.selectedRowIds = [];
        this.selectedRows = new core_1.EventEmitter();
        this.allSelected = false;
        this.selectAll = new core_1.EventEmitter();
        this.resetRowIndex = new core_1.EventEmitter();
        this.timelineLabels = [
            {
                name: 'Punchcards',
                color: '#09366c'
            },
            {
                name: 'Trips',
                color: '#015BC5'
            },
            {
                name: 'Scale Sync',
                color: '#7e34bf'
            },
            {
                name: 'Geofence Duration',
                color: '#f5a623'
            },
            {
                name: 'GeoTrip Duration',
                color: '#fde0af'
            },
            {
                name: 'Pay Duration',
                color: 'rgba(42, 129, 215, 0.15)'
            },
        ];
        this.timelineConfig = {
            currentTime: false,
            selectedTime: false,
            scroll: false,
            headerHeight: 47,
            rowHeight: 115,
            visibleBars: ['punchcards', 'trips', 'predictedTrips',
                'truckStatuses', 'shifts', 'payPeriods']
        };
        this.editPanelOpen = false;
        this.editingRow = {};
        this.rowEdits = new core_1.EventEmitter();
        this.payRecordsChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DriverPayTimelineComponent.prototype, "payRecords", {
        get: function () { return this.payRecordsValue; },
        set: function (data) {
            this.payRecordsValue = data;
            this.timelineRowData = data.map(function (row) { return (row.data.rowData); });
            this.payRecordsChange.emit(data);
            this.selectedRowIds = [];
        },
        enumerable: true,
        configurable: true
    });
    DriverPayTimelineComponent.prototype.editTimelineRow = function (id) {
        this.payRecords.map(function (row) {
            row.data.editing = false;
            return row;
        });
        this.editingRowIndex = this.payRecords.findIndex(function (data) { return (data.data.referenceId === id); });
        this.payRecords[this.editingRowIndex].data.editing = !this.readOnly;
        this.openEditPanel(this.payRecords[this.editingRowIndex]);
    };
    DriverPayTimelineComponent.prototype.openEditPanel = function (data) {
        this.editPanelOpen = true;
        this.editingRow = data;
    };
    DriverPayTimelineComponent.prototype.updateRowData = function (updates) {
        this.editingRow = this.payRecords[this.payRecords.findIndex(function (data) { return (data.data.referenceId === updates.referenceId); })];
        this.editingRow.data.rowData = updates;
        if (this.editingRow &&
            this.editingRow.data &&
            this.editingRow.data.rowData &&
            this.editingRow.data.rowData.referenceId === updates.referenceId) {
            this.editingRow.data.rowData = updates;
            this.editingRow = Object.assign({}, this.editingRow);
            this.resetTimelineSubs();
        }
        this.rowEdits.emit([this.editingRow]);
    };
    DriverPayTimelineComponent.prototype.selectRows = function (rows) {
        this.selectedRowIds = rows;
        this.selectedRows.emit(rows);
        if (this.selectedRowIds.length !== this.payRecords.length) {
            this.selectAllRows(false);
        }
    };
    DriverPayTimelineComponent.prototype.selectAllRows = function (value) {
        this.selectAll.emit(value);
        this.allSelected = value;
    };
    DriverPayTimelineComponent.prototype.resetRow = function (index) {
        this.resetRowIndex.emit(index);
        this.resetTimelineSubs();
    };
    DriverPayTimelineComponent.prototype.resetTimelineSubs = function () { if (this.timeline) {
        this.timeline.resetPayLineSubs();
    } };
    return DriverPayTimelineComponent;
}());
exports.DriverPayTimelineComponent = DriverPayTimelineComponent;
