import { clone } from 'lodash';
import { Organization } from '../organizations/organization';
import { PriceList } from '../price-lists/price-list';
import { Resource } from '../shared/resource';

export class Connection extends Resource {
  id: string;
  name: string;
  status: string;
  organization: Organization;
  organizationName: string;
  createdAt: string;
  lastModified: string;
  invoiceablePunchcardCount: string;
  invoiceableTripCount: string;
  loading: boolean;
  customerOnly: boolean;
  autoAcceptJobs: boolean;
  allowDispatch: boolean;
  paymentType: string;
  invitation: string;
  oneRate = true;
  multipliers: any[];
  accountType = 'leased-fleet';
  posPrintPrices: boolean;
  posPrintTicket: boolean;
  posCashPay: boolean;
  posTaxExempt: boolean;
  posPaymentType: string;
  posTaxPercentage: string;
  posPriceList: PriceList;
  notes: string;
  uniqueBillingId: string;
  selected: boolean;
  classes: string;
  identifiers: string;
  types: string;
  location: string;
  primaryContactName: string;
  canEdit: boolean;
  collaboration: any;

  url(action: string, returnTo?: string): string {
    return '/' + this.accountType + '/' + this.id + '/' + action + '?returnTo=' +
      (returnTo ? encodeURIComponent(returnTo) : '/connections');
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}

