"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".dropdown[_ngcontent-%COMP%]   .dropdown-menu[_ngcontent-%COMP%]{top:4px}.dropdown[_ngcontent-%COMP%]   .dropdown-menu[_ngcontent-%COMP%]   .dropdown-options[_ngcontent-%COMP%]{max-height:350px}.dropdown[_ngcontent-%COMP%]   .dropdown-menu[_ngcontent-%COMP%]   .option[_ngcontent-%COMP%]{padding:14px;display:flex;flex-direction:column}.dropdown[_ngcontent-%COMP%]   .dropdown-menu[_ngcontent-%COMP%]   .option[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]{padding:2px 0}.dropdown[_ngcontent-%COMP%]   .dropdown-menu[_ngcontent-%COMP%]   .option[_ngcontent-%COMP%]   .subtitle[_ngcontent-%COMP%]{display:flex;justify-content:space-between}"];
exports.styles = styles;
