<form [formGroup]="addCollaborators">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add Collaborator</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">

      <!-- left sidebar -->
      <div class="available-connections">
        <div class="header" translate>Connections</div>
        <div class="connection-controls">
          <search (searchTermChange)="changeConnectionSearch($event)" [(searchTerm)]="search"></search>
        </div>

        <div class="connection-options" (scroll)="onScroll($event)">
          <div class="option" *ngFor="let connection of connections">
            <input type="checkbox" (change)="onCheckboxChange($event, connection)"
                   name="connection_{{ connection.organization.id }}" >
            <label attr.data-label="{{ connection.organization.name }}" for="connection_{{ connection.organization.id }}"></label>
          </div>
          <my-loader *ngIf="loading" [ngClass]="{'full-height': connections.length === 0}"></my-loader>
        </div>
      </div>
      <!-- end left sidebar -->

      <div class="selected-connections">
        <!-- apply to all section -->
        <collaboration-template *ngIf="connections && connections.length"
          [loading]="loading"
          [hasLoadListsEnabled]="hasLoadListsEnabled"
          [rateTrackingOptions]="rateTrackingOptions"
          [unitsOfMeasure]="unitsOfMeasure"
          (onApplyToAll)="applyTemplate($event)"
        ></collaboration-template>
        <!-- end apply to all section -->

        <!-- start load list stuff -->
        <div class="loadlist-accordion" *ngIf="loadList.length && connections && connections.length">
          <div class="loadlist-accordion-header" (click)="loadListExpanded = !loadListExpanded">
            <div translate>LOAD LIST</div>
            <div class="loadlist-accordion-arrow">
              <mat-icon (click)="loadListExpanded=true" *ngIf="!loadListExpanded">expand_more</mat-icon>
              <mat-icon (click)="loadListExpanded=false" *ngIf="loadListExpanded">expand_less</mat-icon>
            </div>
          </div>
          <div class="loadlist-accordion-body" [@expanded]="loadListExpanded ? 'expanded' : 'collapsed'">
            <table mat-table #loadListTable class="load-list-table" [dataSource]="loadList">
              <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef translate>LOAD</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.loadNumber || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="loadTime">
                <th mat-header-cell *matHeaderCellDef translate>LOAD TIME</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ticketEvents && row.ticketEvents.LOADING_STARTED | moment: 'h:mm a' || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef translate>TRUCK</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.vehicle && row.ruckit.vehicle.description ? '#' + row.ruckit.vehicle.description : '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef translate>DRIVER</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.driverName ? row.ruckit.driverName : '&mdash;' }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>

        <div class="option" *ngFor="let connection of connections | propertyFilter: 'selected': 'true'">
          <h3>{{ connection.organization && connection.organization.name }}</h3>
          <div class="connection" formGroupName="organization_{{ connection.id }}">
            <div class="row">
              <div class="field days">
                <div class="label required" translate>Days Allowed</div>
                <button class="calendar-button btn btn-default">
                  <div class="calendar-icon"></div> {{ (jobEvent.shift1StartTimestamp | date:'mediumDate') || '&nbsp;' }}
                </button>
              </div>

              <div class="field loads" *ngIf="loadList.length > 0">
                <div class="label" translate>Loads</div>
                <div class="dropdown">
                  <div class="dropdown-button" [matMenuTriggerFor]="loads">
                    <ng-container *ngIf="connection.collaboration.loadSchedule.length">
                      <ng-container *ngFor="let load of connection.collaboration.loadSchedule; let i = index">
                        {{ i === 0 ? load.loadNumber : ', ' + load.loadNumber }}
                      </ng-container>
                    </ng-container>
                    <span *ngIf="!connection.collaboration.loadSchedule.length" translate>None Selected</span>
                  </div>
                  <mat-menu #loads="matMenu" class="loadlist-dropdown-menu" [overlapTrigger]="false">
                      <div class="loadlist-load-option" *ngFor="let load of loadList" (click)="$event.stopPropagation()">
                        <mat-checkbox (change)="loadDropdownSelectionChanged($event, load, connection)" translate>
                          {{ load.loadNumber }} &nbsp;&nbsp; Load Time - {{ load.ticketEvents.LOADING_STARTED | moment: 'h:mm a' }}
                        </mat-checkbox>
                      </div>
                  </mat-menu>
                </div>
              </div>

              <div class="field rate" *ngIf="!loadList.length">
                <div class="label required" translate>Requested Amount</div>
                <div class="input-group">
                  <input
                    type="number"
                    formControlName="requestedAmount"
                    min="0">
                  <dropdown
                    [options]="unitsOfMeasure"
                    [selectedOption]="controls['organization_' + connection.id].controls.requestedUnit.value"
                    (onSelect)="setFormControlValue(connection, 'requestedUnit', $event)">
                  </dropdown>
                </div>
              </div>
              <!-- end load list stuff -->

              <div class="field rate-tracking">
                <div class="label" translate>How is the rate tracked?</div>
                <dropdown [options]="rateTrackingOptions"
                          [config]="rateTrackingConfig"
                          [selectedOption]="controls['organization_' + connection.id].controls.invoiceType.value || haulTypeOption"
                          (onSelect)="setFormControlValue(connection, 'invoiceType', $event)"
                          class="rate-tracking-dropdown"></dropdown>
              </div>
            </div>

            <div class="row">
              <div class="field rate">
                <div class="label" translate>What is the rate?</div>
                <div class="input-group" data-prefix="$">
                  <input
                    type="number"
                    formControlName="invoiceRate"
                    min="0"
                  >
                  <dropdown
                    *ngIf="controls['organization_' + connection.id].controls.invoiceType.value &&
                      controls['organization_' + connection.id].controls.invoiceType.value.value === 'weight'; else addOn"
                    title="-"
                    [config]="rateWeightConfig"
                    [options]="weightOptions"
                    [selectedOption]="controls['organization_' + connection.id].controls.invoiceWeightUnit.value"
                    (onSelect)="setFormControlValue(connection, 'invoiceWeightUnit', $event)"
                  >
                  </dropdown>
                  <ng-template #addOn>
                    <div class="add-on">
                      {{ controls['organization_' + connection.id].controls.invoiceType.value &&
                        controls['organization_' + connection.id].controls.invoiceType.value.value | titlecase }}
                    </div>
                  </ng-template>
                  
                </div>
              </div>

              <div class="field broker-rate-code">
                <div class="label" translate>What is the broker rate code?</div>
                <input formControlName="brokerRateCode">
              </div>

              <div class="field notes">
                <div class="label" translate>Notes</div>
                <div class="input-group">
                  <textarea formControlName="notes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="no-results-section" *ngIf="(connections | propertyFilter: 'selected': 'true').length < 1">
          <no-results 
            itemName="{{'Connection Selected' | translate }}" 
            subTitle="{{'Choose connection(s) from the list on the left to begin adding collaborators.' | translate }}"
            [search]="search" 
            [loading]="loading"
            [results]="(connections | propertyFilter: 'selected': 'true').length"
            [styles]="noResultsStyles"
            [hasAddText]="false" 
            [hasAction]="false" 
            (clearSearch)="expandSearch()"
          >
          </no-results>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="dialogRef.close()"
      [ngClass]="{loading: loading}" translate>
      Cancel
    </button>
    <button class="btn btn-primary save-button"
      (click)="addCollaborators.valid && submit(); false"
      [disabled]="!checkedConnections.length || !addCollaborators.valid || addCollaborators.pristine || loading"
      [ngClass]="{loading: loading}"
      translate>
      Save and Send
    </button>
  </div>
</form>