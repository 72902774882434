"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./edit-organization-markets.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../shared/notification/notification.component.ngfactory");
var i3 = require("@ngx-translate/core");
var i4 = require("../shared/notification/notification.component");
var i5 = require("../../../node_modules/@angular/material/menu/typings/index.ngfactory");
var i6 = require("@angular/material/menu");
var i7 = require("@angular/common");
var i8 = require("@angular/cdk/a11y");
var i9 = require("@angular/router");
var i10 = require("../shared/action-menu/action-menu.component.ngfactory");
var i11 = require("../shared/action-menu/action-menu.component");
var i12 = require("../shared/fancy-table/fancy-table.component.ngfactory");
var i13 = require("../shared/fancy-table/fancy-table.component");
var i14 = require("@angular/material/dialog");
var i15 = require("ng2-dragula");
var i16 = require("../preferences/preference.service");
var i17 = require("../shared/authentication.service");
var i18 = require("../shared/applied-filters/applied-filters.component.ngfactory");
var i19 = require("../shared/applied-filters/applied-filters.component");
var i20 = require("../shared/column-toggle/column-toggle.component.ngfactory");
var i21 = require("../shared/column-toggle/column-toggle.component");
var i22 = require("@angular/forms");
var i23 = require("../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i24 = require("@angular/material/button");
var i25 = require("@angular/cdk/platform");
var i26 = require("@angular/platform-browser/animations");
var i27 = require("../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i28 = require("@angular/material/icon");
var i29 = require("./organization.service");
var i30 = require("@angular/common/http");
var i31 = require("../tags/tag.service");
var i32 = require("./edit-organization-markets.component");
var styles_EditOrganizationMarketsComponent = [i0.styles];
var RenderType_EditOrganizationMarketsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditOrganizationMarketsComponent, data: {} });
exports.RenderType_EditOrganizationMarketsComponent = RenderType_EditOrganizationMarketsComponent;
function View_EditOrganizationMarketsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "notification", [["translate", ""]], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(2, 245760, null, 0, i4.NotificationComponent, [], null, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_EditOrganizationMarketsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.name || "-"); _ck(_v, 2, 0, currVal_0); }); }
function View_EditOrganizationMarketsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.itemCount || 0); _ck(_v, 2, 0, currVal_0); }); }
function View_EditOrganizationMarketsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.notes || "\u2013"); _ck(_v, 2, 0, currVal_0); }); }
function View_EditOrganizationMarketsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_MatMenuItem_0, i5.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, null, 0, i6.MatMenuItem, [i1.ElementRef, i7.DOCUMENT, i8.FocusMonitor, [2, i6.ɵf24]], null, null), i1.ɵdid(2, 671744, null, 0, i9.RouterLinkWithHref, [i9.Router, i9.ActivatedRoute, i7.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(3, { returnTo: 0 }), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _ck(_v, 3, 0, (("/settings/organization/" + _co.organization.id) + "/markets")); var currVal_9 = _v.parent.parent.parent.context.row.url(_v.parent.context.$implicit.action); _ck(_v, 2, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).role; var currVal_1 = i1.ɵnov(_v, 1)._highlighted; var currVal_2 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 1).disabled || null); var currVal_6 = i1.ɵnov(_v, 2).target; var currVal_7 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_10); }); }
function View_EditOrganizationMarketsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.menuAction(_v.parent.context.$implicit.action, _v.parent.parent.parent.context.row) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_MatMenuItem_0, i5.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, null, 0, i6.MatMenuItem, [i1.ElementRef, i7.DOCUMENT, i8.FocusMonitor, [2, i6.ɵf24]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).role; var currVal_1 = i1.ɵnov(_v, 1)._highlighted; var currVal_2 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_6); }); }
function View_EditOrganizationMarketsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_8)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_9)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.link; _ck(_v, 4, 0, currVal_1); }, null); }
function View_EditOrganizationMarketsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "action-menu", [["icon", "icon-more"]], null, null, null, i10.View_ActionMenuComponent_0, i10.RenderType_ActionMenuComponent)), i1.ɵdid(2, 114688, null, 0, i11.ActionMenuComponent, [], { icon: [0, "icon"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EditOrganizationMarketsComponent_7)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "icon-more"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.row.filterOptions(_co.marketTableConfig.menuOptions); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EditOrganizationMarketsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.value; _ck(_v, 1, 0, currVal_0); }); }
function View_EditOrganizationMarketsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i7.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_3)), i1.ɵdid(3, 278528, null, 0, i7.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i7.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_4)), i1.ɵdid(5, 278528, null, 0, i7.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i7.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_5)), i1.ɵdid(7, 278528, null, 0, i7.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i7.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_6)), i1.ɵdid(9, 278528, null, 0, i7.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i7.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_10)), i1.ɵdid(11, 16384, null, 0, i7.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i7.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.column; _ck(_v, 1, 0, currVal_0); var currVal_1 = "name"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "itemCount"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "notes"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "action"; _ck(_v, 9, 0, currVal_4); }, null); }
function View_EditOrganizationMarketsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { columnTemplates: 0 }), i1.ɵqud(671088640, 2, { marketTable: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "markets-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditOrganizationMarketsComponent_1)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 21, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 20, "div", [["class", "actions-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "fancy-table", [], null, [[null, "availableColumnsChange"], [null, "displayedColumnsChange"], [null, "availableFiltersChange"], [null, "filtersChange"], [null, "searchChange"], [null, "onClickAction"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("availableColumnsChange" === en)) {
        var pd_1 = ((_co.availableColumns = $event) !== false);
        ad = (pd_1 && ad);
    } if (("displayedColumnsChange" === en)) {
        var pd_2 = ((_co.displayedColumns = $event) !== false);
        ad = (pd_2 && ad);
    } if (("availableFiltersChange" === en)) {
        var pd_3 = ((_co.availableFilters = $event) !== false);
        ad = (pd_3 && ad);
    } if (("filtersChange" === en)) {
        var pd_4 = ((_co.appliedFilters = $event) !== false);
        ad = (pd_4 && ad);
    } if (("searchChange" === en)) {
        var pd_5 = ((_co.search = $event) !== false);
        ad = (pd_5 && ad);
    } if (("onClickAction" === en)) {
        var pd_6 = (_co.clickAction($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i12.View_FancyTableComponent_0, i12.RenderType_FancyTableComponent)), i1.ɵdid(8, 770048, [[2, 4], ["marketTable", 4]], 2, i13.FancyTableComponent, [i9.ActivatedRoute, i9.Router, i14.MatDialog, i1.Injector, i15.DragulaService, i16.PreferenceService, i17.AuthenticationService, i1.Renderer2], { availableColumns: [0, "availableColumns"], displayedColumns: [1, "displayedColumns"], filters: [2, "filters"], availableFilters: [3, "availableFilters"], search: [4, "search"], config: [5, "config"] }, { searchChange: "searchChange", filtersChange: "filtersChange", onClickAction: "onClickAction" }), i1.ɵqud(603979776, 3, { columnTemplates: 0 }), i1.ɵqud(603979776, 4, { detailTemplates: 0 }), (_l()(), i1.ɵand(0, [[3, 2], [1, 2], ["columnTemplates", 2]], 2, 0, null, View_EditOrganizationMarketsComponent_2)), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "div", [["header-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "title"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Markets"])), (_l()(), i1.ɵeld(16, 0, null, 1, 2, "div", [["applied-filter-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "applied-filters", [], null, [[null, "filtersChange"], [null, "searchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filtersChange" === en)) {
        var pd_0 = ((_co.appliedFilters = $event) !== false);
        ad = (pd_0 && ad);
    } if (("searchChange" === en)) {
        var pd_1 = ((_co.search = $event) !== false);
        ad = (pd_1 && ad);
    } if (("filtersChange" === en)) {
        var pd_2 = (_co.filtersModified($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i18.View_AppliedFiltersComponent_0, i18.RenderType_AppliedFiltersComponent)), i1.ɵdid(18, 638976, null, 0, i19.AppliedFiltersComponent, [], { filters: [0, "filters"], search: [1, "search"] }, { searchChange: "searchChange", filtersChange: "filtersChange" }), (_l()(), i1.ɵeld(19, 0, null, null, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "column-toggle", [], null, [[null, "availableColumnsChange"], [null, "displayedColumnsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("availableColumnsChange" === en)) {
        var pd_0 = ((_co.availableColumns = $event) !== false);
        ad = (pd_0 && ad);
    } if (("displayedColumnsChange" === en)) {
        var pd_1 = ((_co.displayedColumns = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i20.View_ColumnToggleComponent_0, i20.RenderType_ColumnToggleComponent)), i1.ɵdid(21, 638976, null, 0, i21.ColumnToggleComponent, [i22.FormBuilder], { availableColumns: [0, "availableColumns"], displayedColumns: [1, "displayedColumns"] }, { availableColumnsChange: "availableColumnsChange", displayedColumnsChange: "displayedColumnsChange" }), (_l()(), i1.ɵeld(22, 0, null, null, 4, "a", [["class", "add-button"], ["color", "accent"], ["mat-icon-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openNewOrganizationMarket() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i23.View_MatAnchor_0, i23.RenderType_MatAnchor)), i1.ɵdid(23, 180224, null, 0, i24.MatAnchor, [i25.Platform, i8.FocusMonitor, i1.ElementRef, [2, i26.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(24, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i27.View_MatIcon_0, i27.RenderType_MatIcon)), i1.ɵdid(25, 9158656, null, 0, i28.MatIcon, [i1.ElementRef, i28.MatIconRegistry, [8, null], [2, i28.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_circle"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.availableColumns; var currVal_2 = _co.displayedColumns; var currVal_3 = _co.appliedFilters; var currVal_4 = _co.availableFilters; var currVal_5 = _co.search; var currVal_6 = _co.marketTableConfig; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.appliedFilters; var currVal_9 = _co.search; _ck(_v, 18, 0, currVal_8, currVal_9); var currVal_10 = _co.availableColumns; var currVal_11 = _co.displayedColumns; _ck(_v, 21, 0, currVal_10, currVal_11); var currVal_16 = "accent"; _ck(_v, 23, 0, currVal_16); _ck(_v, 25, 0); }, function (_ck, _v) { var currVal_12 = (i1.ɵnov(_v, 23).disabled ? (0 - 1) : (i1.ɵnov(_v, 23).tabIndex || 0)); var currVal_13 = (i1.ɵnov(_v, 23).disabled || null); var currVal_14 = i1.ɵnov(_v, 23).disabled.toString(); var currVal_15 = (i1.ɵnov(_v, 23)._animationMode === "NoopAnimations"); _ck(_v, 22, 0, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_17 = i1.ɵnov(_v, 25).inline; var currVal_18 = (((i1.ɵnov(_v, 25).color !== "primary") && (i1.ɵnov(_v, 25).color !== "accent")) && (i1.ɵnov(_v, 25).color !== "warn")); _ck(_v, 24, 0, currVal_17, currVal_18); }); }
exports.View_EditOrganizationMarketsComponent_0 = View_EditOrganizationMarketsComponent_0;
function View_EditOrganizationMarketsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ruckit-edit-organization-markets", [], null, null, null, View_EditOrganizationMarketsComponent_0, RenderType_EditOrganizationMarketsComponent)), i1.ɵprd(4608, null, i29.OrganizationService, i29.OrganizationService, [i30.HttpClient]), i1.ɵprd(512, null, i31.TagService, i31.TagService, [i30.HttpClient]), i1.ɵdid(3, 114688, null, 0, i32.EditOrganizationMarketsComponent, [i9.ActivatedRoute, i31.TagService, i3.TranslateService, i14.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
exports.View_EditOrganizationMarketsComponent_Host_0 = View_EditOrganizationMarketsComponent_Host_0;
var EditOrganizationMarketsComponentNgFactory = i1.ɵccf("ruckit-edit-organization-markets", i32.EditOrganizationMarketsComponent, View_EditOrganizationMarketsComponent_Host_0, { availableColumns: "availableColumns", displayedColumns: "displayedColumns", organization: "organization", availableFilters: "availableFilters", appliedFilters: "appliedFilters", search: "search" }, { availableColumnsChange: "availableColumnsChange", displayedColumnsChange: "displayedColumnsChange", availableFiltersChange: "availableFiltersChange", appliedFiltersChange: "appliedFiltersChange", searchChange: "searchChange" }, []);
exports.EditOrganizationMarketsComponentNgFactory = EditOrganizationMarketsComponentNgFactory;
