import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LocationType } from './location-type';
import { LocationTypeSerializer } from './location-type.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class LocationTypeService extends ResourceService<LocationType> {
  constructor(http: HttpClient) {
    super(http, 'locationtypes/', new LocationTypeSerializer());
  }
}
