<div class="ticket-create-container">
  <div class="container-header">
    <div class="title-bar">
      <div class="input-date-select-wrapper">
        <div class="label-delivery-date" translate>Delivery Date</div>
        <div class="input-date-select">
          <div class="input-date">
            <date-range-picker [disabled]="!formEnabled" [selectedDates]="[deliveryDate]"
              (dateChanged)="deliveryDateChanged($event)"></date-range-picker>
          </div>
        </div>
      </div>
      <div class="container-title">
        <ng-container *ngIf="currentTicket && currentTicket.id" translate>Ticket #{{ currentTicket.number }}</ng-container>
        <ng-container *ngIf="!currentTicket || !currentTicket.id" translate>Create Ticket</ng-container>
        <div *ngIf="currentTicket && currentTicket.id" class="ticket-status {{  currentTicket && currentTicket.id ? currentTicket.statusLabel : 'label-info' }}">
          {{ currentTicket && currentTicket.statusText | translate }}
        </div>
      </div>
      <div class="container-subtitle" [ngClass]="{hidden: !currentTicket || !currentTicket.id}" translate>
        Created on: {{ (!currentTicket ? '' : currentTicket.createdAt) | date:'short' }}
      </div>
    </div>
  </div>

  <div class="container-body">
    <div class="ticket-form-section">
      <div class="ticket-form-section-title" translate>Truck</div>
      <div class="ticket-form-container">
        <div class="form-control ticket-form-truck-container">
          <label class="required" translate>Truck</label>
          <input id="truck-name" name="truck_name" [disabled]="true" [(ngModel)]="currentTicket.truckDisplayName">
        </div>
        <div class="form-control ticket-form-truck-container">
          <label class="required" translate>Driver</label>
          <input type="hidden" name="driver" required [(ngModel)]="selectedDriver" #driver="ngModel" />
          <dropdown *ngIf="currentTicket && !currentTicket.driverName && !customDriver" [disabled]="!driverDropdownEnabled"
            id="driver-dropdown" #driverDropdown required [options]="drivers" [selectedOption]="selectedDriver" [backgroundColor]="backgroundColor"
            [config]="driverDropdownConfig" title="{{ 'Select Driver' | translate }}" (onSelect)="selectDriver($event)"
            (onSearch)="delaySearch($event, 'driver')" (nextPage)="dropdownNextPage($event, 'driver')"></dropdown>
          <input *ngIf="currentTicket && currentTicket.driverName && currentTicket.driverName.length || customDriver"
            required type="text" name="driverName" [disabled]="loading" [(ngModel)]="currentTicket.driverName"
            #driver="ngModel" (blur)="saveTicket()" />
        </div>
        <div class="form-control ticket-form-truck-container">
          <label translate>Carrier</label>
          <input id="hauler-name" name="haulerName" [disabled]="true" [(ngModel)]="haulerName">
        </div>
      </div>
    </div>

    <div class="ticket-form-section purchase-order-container">
      <div class="ticket-form-section-title" translate>Order</div>
      <div class="ticket-form-container">
        <div class="form-control customer">
          <label class="required" translate>Customer</label>
          <input type="hidden" name="customer" required [(ngModel)]="selectedCustomer" #customer="ngModel" />
          <dropdown [disabled]="!customerDropdownEnabled" id="customer-dropdown" #customerDropdown required [options]="customers"
            [selectedOption]="selectedCustomer" [config]="customerDropdownConfig" title="{{ 'Select Customer' | translate }}" [backgroundColor]="backgroundColor"
            (onSelect)="selectCustomer($event, true, true)" (onSearch)="delaySearch($event, 'customer')" (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
        </div>

        <div class="form-control purchase-order">
          <label translate>Order</label>
          <input type="hidden" name="purchase-order" [(ngModel)]="selectedPurchaseOrder" #purchaseOrder="ngModel" />
          <dropdown [disabled]="!selectedCustomer || !purchaseOrderDropdownEnabled" id="purchase-order-dropdown"
            #purchaseOrderDropdown [options]="purchaseOrders" [selectedOption]="selectedPurchaseOrder" [config]="purchaseOrderDropdownConfig"
            (onSelect)="selectPurchaseOrder($event, true, true)" (onSearch)="delaySearch($event, 'purchaseOrder')" [backgroundColor]="backgroundColor"
            (nextPage)="dropdownNextPage($event, 'purchaseOrder')" title="-"></dropdown>
        </div>
      </div>
    </div>

    <div class="ticket-form-section material-container">
      <div class="ticket-form-section-title" translate>Material</div>
      <div class="ticket-form-container">
        <div class="form-control field-group product">
          <label class="required" translate>Product</label>

          <input type="hidden" name="product" required [(ngModel)]="selectedProduct" #product="ngModel" />
          <dropdown [disabled]="!selectedCustomer" id="product-dropdown" #productDropdown required [options]="products" [backgroundColor]="backgroundColor"
            [selectedOption]="selectedProduct" [config]="productDropdownConfig" (onSelect)="selectProduct($event, true, true)"
            (onSearch)="delaySearch($event, 'product')" (nextPage)="dropdownNextPage($event, 'product')" title="{{ 'Select Product' | translate }}"></dropdown>
        </div>

        <div class="form-control unit-price field-group">
          <label class="required" translate>Unit Price</label>
          <div [ngClass]="{inactive: !formEnabled}" class="input-group" data-prefix="$">
            <input ticketFormNumber required id="unit-price" name="unit-price" [(ngModel)]="unitPrice"
              [readonly]="!selectedCustomer" [ngClass]="{inactive: !selectedCustomer}" (blur)="setUnitPrice()">
            <div class="add-on">
              {{ currentTicket && currentTicket.productUnitOfMeasurement || '&mdash;' }}
            </div>
          </div>
        </div>

        <div class="form-control discount">
          <label translate>Discount</label>
          <span class="percentage-mask">
            <input id="discount-percentage" [ngClass]="{inactive: !formEnabled || !selectedCustomer}" [readonly]="!selectedCustomer"
              name="discount-percentage" numberInRange minValue=0 maxValue=100 (blur)="saveTicket()"
              [(ngModel)]="currentTicket.discountPercentage" #discountPercentage="ngModel">
          </span>
        </div>
      </div>
    </div>

    <div class="ticket-form-section surcharges-container">
      <div class="ticket-form-section-title" translate>Surcharges</div>
      <div class="ticket-form-container">
        <div class="surcharge-row" *ngFor="let surcharge of currentTicket.surcharges; let index = index; let first = first; let last = last">
          <div class="form-control field-group surcharge">
            <label *ngIf="first" translate>Surcharge</label>
            <input *ngIf="surcharge.title" [disabled]="true" [(ngModel)]="currentTicket.surcharges[index].title"
              [ngClass]="{inactive: !formEnabled}">
            <dropdown *ngIf="!surcharge.title" [options]="surchargeTemplates" title="-" [config]="surchargeTemplateDropdownConfig"
              [disabled]="!currentTicket || !currentTicket.id" [selectedOption]="currentTicket.surcharges[index].selectedSurchargeTemplate"
              (onSelect)="selectSurchargeTemplate($event, index)" (onSearch)="delaySearch($event, 'surchargeTemplate')" [backgroundColor]="backgroundColor"
              (nextPage)="dropdownNextPage($event, 'surchargeTemplate')"></dropdown>
          </div>
          <div class="form-control field-group amount">
            <label *ngIf="first" translate>Amount</label>
            <div [ngClass]="{inactive: !formEnabled}" class="input-group" data-prefix="$">
              <input [(ngModel)]="currentTicket.surcharges[index].amount" (blur)="saveSurcharges(surcharge)" disabled>
            </div>
          </div>
          <div class="form-control field-group actions">
            <label *ngIf="first" translate>&nbsp;</label>
            <button *ngIf="!first || first && currentTicket && currentTicket.surcharges.length > 1" (click)="deleteSurcharge(surcharge, index); false"
              class="btn btn-cancel" [disabled]="!formEnabled">
              <i class="icon icon-delete" title="Remove"></i>
            </button>
            <button *ngIf="first && currentTicket && currentTicket.surcharges.length === 1" (click)="clearSurcharge(surcharge, index); false"
              class="btn btn-cancel" [disabled]="!formEnabled">
              <i class="icon icon-delete" title="Clear"></i>
            </button>
            <button class="btn btn-primary" *ngIf="last" (click)="addSurcharge(); false" [disabled]="!formEnabled">
              <i class="icon icon-plus" title="Add"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="ticket-form-section notes-section">
      <div class="ticket-form-section-title" translate>Notes</div>
      <div class="ticket-form-container">
        <div class="form-control note-container">
          <textarea class="note-textarea" [maxLength]="255" id="notes" name="notes" [(ngModel)]="currentTicket.notes"
            [readonly]="!formEnabled" [ngClass]="{inactive: !formEnabled}" (blur)="saveTicket()"></textarea>
        </div>
      </div>
    </div>

    <div class="ticket-form-section payment-and-summary-section">
      <div class="payments-section">
        <div class="ticket-form-section-title" translate>Payment</div>
        <div class="form-control field-group payment-type">
          <label translate>Payment Type</label>
          <select name="paymentType" [disabled]="!formEnabled || !currentTicket" (change)="selectPaymentType($event.target.value)">
            <option *ngFor="let paymentType of paymentTypes" [selected]="paymentType.id === currentTicket.paymentType"
              [value]="paymentType.id">{{ paymentType.name }}</option>
          </select>
        </div>
      </div>

      <div class="summary-section">
        <div class="summary">
          <div class="form-control field-group">
            <label translate>Material Price</label>
            <div class="value">{{ currentTicket.netPrice | currency:'USD':'symbol':'1.2-2' }}</div>
          </div>
          <div class="form-control field-group taxes-total">
            <label translate>Taxes</label>
            <div class="value">{{ currentTicket.totalTax | currency:'USD':'symbol':'1.2-2' }}</div>
          </div>
          <div class="form-control field-group surcharge-total">
            <label translate>Surcharges</label>
            <div class="value">{{ currentTicket.totalSurcharge | currency:'USD':'symbol':'1.2-2' }}</div>
          </div>

          <hr />

          <div class="form-control field-group total">
            <label translate>Total Price</label>
            <div class="value">{{ currentTicket.totalPrice | currency:'USD':'symbol':'1.2-2' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ticket-form-section scale-container">
      <div class="scale-title" translate>Scale</div>
      <div class="scale">
        <div class="field-group tare-field scale-dropdown-group">
          <label class="required" translate>Tare</label>
          <div class="input-group ">
            <scale-dropdown required (onScaleRefresh)="getScales()" title="-" [disabled]="!formEnabled" class="add-on scale-width scale-dropdown"
              [options]="tareScales" [config]="tareDropdownConfig" (onSelect)="selectTareWeight($event)" (onToggle)="onScaleDropdownToggle($event)"
              [selectedOption]="tareScale"></scale-dropdown>
            <input required ticketFormNumber [decimalsAllowed]="(tareScale ? tareScale.weightUnit : defaultWeightUnit) !== 'lbs'"
              type="number" min="0" class="scale-field" id="tare" name="tare" [(ngModel)]="tareWeight" (blur)="saveTicket()"
              [readonly]="!formEnabled" [ngClass]="{inactive: !formEnabled}">
            <div class="weight-unit add-on">{{ tareScale ? tareScale.weightUnit : defaultWeightUnit }}</div>
          </div>
          <div *ngIf="currentTicket.tareWeightDatetime" class="date" translate>Weight Captured On: {{
            currentTicket.tareWeightDatetime | date:'short' }}</div>
        </div>

        <div class="field-group gross-field scale-dropdown-group">
          <label class="required" translate>Gross</label>
          <div class="input-group">
            <scale-dropdown required (onScaleRefresh)="getScales()" [disabled]="!formEnabled" class="add-on scale-width scale-dropdown"
              title="-" [options]="grossScales" [config]="grossDropdownConfig" (onSelect)="selectGrossWeight($event)"
              (onToggle)="onScaleDropdownToggle($event)" [selectedOption]="grossScale"></scale-dropdown>
            <input required ticketFormNumber [decimalsAllowed]="(grossScale ? grossScale.weightUnit : defaultWeightUnit) !== 'lbs'"
              type="number" min="0" class="scale-field" id="gross" name="gross" [(ngModel)]="grossWeight" (blur)="saveTicket()"
              [readonly]="!formEnabled" [ngClass]="{inactive: !formEnabled}">
            <div class="weight-unit add-on">{{ grossScale ? grossScale.weightUnit : defaultWeightUnit }}</div>
          </div>
          <div *ngIf="currentTicket.grossWeightDatetime" class="date" translate>Weight Captured On: {{
            currentTicket.grossWeightDatetime | date:'short' }}</div>
        </div>

        <div class="field-group corr-field">
          <label translate>Correction</label>
          <input ticketFormNumber [decimalsAllowed]="defaultWeightUnit !== 'lbs'" type="number" id="correction" name="correction"
            [maxLength]="6" [(ngModel)]="currentTicket.correctionWeight" (blur)="saveTicket()" [readonly]="!formEnabled"
            [ngClass]="{inactive: !formEnabled}">
        </div>

        <div class="field-group net-amount">
          <label translate>Net</label>
          <div class="amount">{{ currentTicket && currentTicket.net | number }} {{ currentTicket.netWeightUnit }}</div>
        </div>
      </div>

      <div class="ticket-controls">
        <div class="calculations">
          <div class="price total-price" translate>Total Price:
            <span class="amount">{{ currentTicket && currentTicket.totalPrice | currency:'USD':'symbol':'1.2-2' }}</span>
          </div>
        </div>

        <div class="scale-actions">
          <div class="actions">
            <label attr.data-label="{{ 'Put On Hold' | translate }}" class="put-on-hold toggle-label">
              <input class="toggle-input put-hold-checkbox" type="checkbox" name="put_on_hold" [disabled]="!currentTicket.truckId" (change)="toggleHold($event)"
                [(ngModel)]="currentTicket.onHold">
              <span class="slider round"></span>
            </label>
            <div class="button-void">
              <button id="void-ticket-button" [ngClass]="{loading: voidButtonloading}" [disabled]="!currentTicket || !currentTicket.id || currentTicket.void"
                class="btn btn-primary-inverted void-button" (click)="voidTicket()" translate>Void</button>
            </div>

            <div class="button-finish">
              <button id="finish-ticket-button" [ngClass]="{loading: finishButtonloading}" [disabled]="!validToFinish() || currentTicket.onHold"
                class="btn btn-primary" (click)="finishTicket()" translate>Finish Ticket</button>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
