"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["div.dialog-header[_ngcontent-%COMP%]{background-color:#2c2c2d;padding:20px 30px;position:relative;border-radius:4px 4px 0 0}div.dialog-header[_ngcontent-%COMP%]   h2.dialog-header-text[_ngcontent-%COMP%]{font-size:18px;font-weight:600;text-align:left;color:#fff;line-height:20px;margin:0}div.dialog-header[_ngcontent-%COMP%]   .close[_ngcontent-%COMP%]{transform:rotate(-315deg) translate(-6px,-5px);opacity:.4;font-size:30px;font-weight:500;cursor:pointer;position:absolute;right:30px;top:30px;line-height:15px;color:#fff}div.dialog-body[_ngcontent-%COMP%]   h3.dialog-body-text[_ngcontent-%COMP%]{width:100%;padding:0 65px;font-size:16px;font-weight:600;line-height:1.81;text-align:center;color:#7f8fa4;margin-top:30px;margin-bottom:40px}div.dialog-actions[_ngcontent-%COMP%]{padding:12px;text-align:center;background-color:#f0f3f8;border-radius:0 0 4px 4px}div.dialog-actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:175px;height:36px}div.dialog-actions[_ngcontent-%COMP%]   button.btn[_ngcontent-%COMP%]{margin-bottom:0}div.dialog-actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]:first-child{margin-right:20px}"];
exports.styles = styles;
