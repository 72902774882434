"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var collaborator_service_1 = require("./collaborator.service");
var job_event_1 = require("../job-events/job-event");
var CollaboratorsWidgetComponent = /** @class */ (function () {
    function CollaboratorsWidgetComponent(collaboratorService) {
        this.collaboratorService = collaboratorService;
        this.collaborators = [];
        this.loading = true;
        this.errors = [];
    }
    CollaboratorsWidgetComponent.prototype.ngOnInit = function () {
        this.getCollaborators();
    };
    CollaboratorsWidgetComponent.prototype.getCollaborators = function () {
        var _this = this;
        if (this.jobEvent) {
            this.collaboratorService.getCollaborators(this.jobEvent.id, { organization__leased_org: 'False' }).subscribe(function (collaborators) {
                _this.collaborators = collaborators;
                _this.loading = false;
            });
        }
    };
    return CollaboratorsWidgetComponent;
}());
exports.CollaboratorsWidgetComponent = CollaboratorsWidgetComponent;
