import { LocationType } from './location-type';
import { LocationTypeSerializer } from './location-type.serializer';
import { LocationOwner } from './location-owner';
import { LocationOwnerSerializer } from './location-owner.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class LocationStat {
  id: string;
  name: string;
  displayName: string;
  locationOwner: LocationOwner;
  loadingTimeSpent: string;
  unloadingTimeSpent: string;
  loadingTimeTarget: string;
  unloadingTimeTarget: string;
  averageTimeSpent: string;
  loadingVisits: string;
  unloadingVisits: string;
  locationType: LocationType;
  hasGeofence: boolean;
  loadingTargetDifference: number;
  unloadingTargetDifference: number;
  loadingTargetRange: string;
  unloadingTargetRange: string;

  constructor(locationStatInfo: any) {
    locationStatInfo = camelcaseKeysDeep(locationStatInfo);

    this.id = locationStatInfo.id;
    this.name = locationStatInfo.name;
    if (locationStatInfo.locationOwner) {
      this.locationOwner = new LocationOwnerSerializer().fromJson(locationStatInfo.locationOwner);
    }
    this.displayName = [
      this.locationOwner && this.locationOwner.name, this.name
    ].filter(Boolean).join(': ');

    this.loadingTimeSpent = locationStatInfo.loadingTimeSpent ? (locationStatInfo.loadingTimeSpent / 60).toString() : null;
    this.unloadingTimeSpent = locationStatInfo.unloadingTimeSpent ? (locationStatInfo.unloadingTimeSpent / 60).toString() : null;
    this.averageTimeSpent = locationStatInfo.averageTimeSpent;
    this.loadingVisits = locationStatInfo.loadingVisits;
    this.unloadingVisits = locationStatInfo.unloadingVisits;
    if (locationStatInfo.locationType) {
      this.locationType = new LocationTypeSerializer().fromJson(locationStatInfo.locationType);
    }
    this.loadingTimeTarget = locationStatInfo.loadingTimeTarget;
    this.unloadingTimeTarget = locationStatInfo.unloadingTimeTarget;
    this.hasGeofence = locationStatInfo.hasGeofence;
    this.loadingTargetDifference = parseFloat(this.loadingTimeSpent) - parseFloat(this.loadingTimeTarget);
    this.unloadingTargetDifference = parseFloat(this.unloadingTimeSpent) - parseFloat(this.unloadingTimeTarget);

    if (this.loadingTargetDifference < 0) {
      this.loadingTargetRange = 'target-exceeded';
    } else if (this.loadingTargetDifference > 0) {
      this.loadingTargetRange = 'target-miss';
    } else {
      this.loadingTargetRange = 'target-hit';
    }
    if (this.unloadingTargetDifference < 0) {
      this.unloadingTargetRange = 'target-exceeded';
    } else if (this.unloadingTargetDifference > 0) {
      this.unloadingTargetRange = 'target-miss';
    } else {
      this.unloadingTargetRange = 'target-hit';
    }
  }
}
