"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var AB5QuestionsDialogComponent = /** @class */ (function () {
    function AB5QuestionsDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.questions = [
            'Is your subcontract with the construction trucker in writing?',
            'The construction trucker is a business entity formed as a sole proprietorship, partnership, LLC, LLP, or corporation.',
            'The construction trucker is registered with the DIR as a public works contractor (even if the work involved is not public work).',
            // tslint:disable-next-line:max-line-length
            'The construction trucker utilizes its own employees to perform the trucking, unless the construction trucker is a sole proprietor who operates his or her own truck to perform the entire subcontract and holds a valid motor carrier permit issued by the DMV.',
            'You negotiate and contract with the construction trucker, and you compensate the construction trucker directly.',
            // tslint:disable-next-line:max-line-length
            'The construction trucker has a business license or business tax registration (unless the jurisdiction where the subcontractor is domiciled doesn’t require it).',
            'The construction trucker maintains a business location separate from yours.'
        ];
        this.answers = [];
    }
    AB5QuestionsDialogComponent.prototype.saveAnswers = function () {
        if (this.answers.includes(false) || this.answers.includes(null)) {
            this.error = 'You are not in compliance with AB-5 unless you answer yes to all of these questions!';
        }
        else {
            this.callback();
            this.dialogRef.close();
        }
    };
    return AB5QuestionsDialogComponent;
}());
exports.AB5QuestionsDialogComponent = AB5QuestionsDialogComponent;
