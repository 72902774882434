"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var organization_1 = require("../organizations/organization");
var price_list_1 = require("./price-list");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PriceListSerializer = /** @class */ (function () {
    function PriceListSerializer() {
    }
    PriceListSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var priceList = new price_list_1.PriceList();
        if (!json) {
            return priceList;
        }
        priceList.id = json.id;
        priceList.name = json.name;
        priceList.createdAt = json.createdAt;
        priceList.lastModified = json.lastModified;
        if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
            priceList.ownerOrganization = new organization_1.Organization(json.ownerOrganization);
        }
        else if (json.ownerOrganization) {
            priceList.ownerOrganization = new organization_1.Organization({ id: json.ownerOrganization });
        }
        priceList.notes = json.notes;
        priceList.status = json.status;
        if (json.prices && typeof (json.prices) === 'object') {
            priceList.prices = json.prices;
            // TO BE CHECKED
            // priceList.prices = (new PriceSerializer()).fromJson(json.prices);
        }
        // if (json.prices) {
        //   priceList.prices = json.prices.map(price => {
        //     if (price && typeof price === 'object') {
        //       return new Price(price);
        //     } else {
        //       return new Price({ id: price });
        //     }
        //   });
        // } else {
        //   priceList.prices = [];
        // }
        return priceList;
    };
    PriceListSerializer.prototype.toJson = function (priceList) {
        var json = {
            id: priceList.id,
            name: priceList.name,
            ownerOrganization: priceList.ownerOrganization && priceList.ownerOrganization.id,
            notes: priceList.notes,
            status: priceList.status
        };
        return decamelizeKeysDeep(json);
    };
    return PriceListSerializer;
}());
exports.PriceListSerializer = PriceListSerializer;
