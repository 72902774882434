"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resource_1 = require("../shared/resource");
var lodash_1 = require("lodash");
var JobEventStat = /** @class */ (function (_super) {
    __extends(JobEventStat, _super);
    function JobEventStat() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.expanded = false;
        return _this;
    }
    JobEventStat.prototype.url = function (action) {
        var route = '/jobevents/' + this.id + '/' + action;
        switch (action) {
            case 'edit':
                route = '/jobs/' + this.jobId + '/' + action;
                break;
            case 'add-collaborators':
                this.routeFragment = 'addCollaborator';
                route = '/jobs/' + this.jobId + '/' + this.id + '/collaborators';
                break;
            case 'dispatch-drivers':
                route = '/dispatch/' + this.jobId + '/' + this.id;
                break;
        }
        return route;
    };
    JobEventStat.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        return options;
    };
    return JobEventStat;
}(resource_1.Resource));
exports.JobEventStat = JobEventStat;
var JobEventStatsSummary = /** @class */ (function () {
    function JobEventStatsSummary() {
    }
    return JobEventStatsSummary;
}());
exports.JobEventStatsSummary = JobEventStatsSummary;
