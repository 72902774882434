"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var lodash_1 = require("lodash");
var job_event_share_stat_service_1 = require("../../job-event-stats/job-event-share-stat.service");
var CollaboratorJobsComponent = /** @class */ (function () {
    function CollaboratorJobsComponent(jobEventShareStatService, translationService) {
        this.jobEventShareStatService = jobEventShareStatService;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'name', title: this.translationService.instant('Job Name') },
            { key: 'customer', title: this.translationService.instant('Customer') },
            { key: 'status', title: this.translationService.instant('Status') },
            { key: 'dispatched-trucks', title: this.translationService.instant('Dispatched Trucks') },
            { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
            { key: 'num-trucks', title: this.translationService.instant('Requested Trucks') },
            { key: 'truck-type', title: this.translationService.instant('Truck Type') },
            { key: 'drivers-on-shift', title: this.translationService.instant('Drivers on Shift') },
            { key: 'delivered-loads', title: this.translationService.instant('Delivered Loads') },
            { key: 'delivered-tons', title: this.translationService.instant('Delivered Tons') },
            { key: 'daily-delivery-target', title: this.translationService.instant('Daily Delivery Target') },
            { key: 'ordered-loads', title: this.translationService.instant('Ordered Loads') },
            { key: 'shares-count', title: this.translationService.instant('Shares Count') },
            { key: 'loading-location', title: this.translationService.instant('Loading Location') },
            { key: 'unloading-location', title: this.translationService.instant('Unloading Location') },
            { key: 'order-number', title: this.translationService.instant('Order Number') },
            { key: 'material', title: this.translationService.instant('Material') },
            { key: 'start-time', title: this.translationService.instant('Start Time') },
            { key: 'haul-total', title: this.translationService.instant('Haul Total') },
        ];
        this.displayedColumns = [
            'name', 'customer', 'status', 'dispatched-trucks', 'confirmed-trucks',
            'num-trucks', 'truck-type', 'drivers-on-shift', 'delivered-loads',
            'delivered-tons', 'daily-delivery-target', 'ordered-loads', 'shares-count',
            'loading-location', 'unloading-location', 'order-number', 'material',
            'start-time', 'haul-total'
        ];
        this.filters = [];
        this.collaboratorJobsTableConfig = {
            hasHeader: false,
            service: job_event_share_stat_service_1.JobEventShareStatService,
            preferenceKey: 'CollaboratorJobsComponent-JobEventShareStatService',
            query: {},
            collectionTitle: this.translationService.instant('Collaborators'),
            noResultsText: this.translationService.instant('a collaborator'),
            customHeight: true,
            automaticallyHidePagination: true
        };
    }
    CollaboratorJobsComponent.prototype.ngOnInit = function () {
        this.jobEventShareStatService.getJobEventStatId(this.jobEventStat.id);
    };
    CollaboratorJobsComponent.prototype.ngOnChanges = function (changes) {
        var jobEventDateStart = new Date();
        jobEventDateStart.setHours(0, 0, 0, 0);
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        if (changes['jobEventDate']) {
            var jobEventDate = changes['jobEventDate'].currentValue;
            if (jobEventDate && jobEventDate.year !== 0) {
                jobEventDateStart = new Date(this.jobEventDate);
                jobEventDateStart.setHours(0, 0, 0, 0);
                jobEventDateEnd = lodash_1.clone(jobEventDateStart);
                jobEventDateEnd.setHours(23, 59, 59, 999);
            }
        }
    };
    return CollaboratorJobsComponent;
}());
exports.CollaboratorJobsComponent = CollaboratorJobsComponent;
