import { Slot } from './slot';
import { AssignmentSerializer } from './assignment.serializer';
import { DriverSerializer } from '../drivers/driver.serializer';
import { TruckSerializer } from '../trucks/truck.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class SlotSerializer {
  /**
  * @param  {any} json
  * @returns Assignment
  */
  fromJson(json: any): Slot {
    json = camelcaseKeysDeep(json);
    const slot = new Slot();
    if (!json) { return slot; }

    slot.id = json.id;
    slot.over = json.over || false;
    slot.updating = json.updating || false;
    slot.numberOfLoadsType = json.numberOfLoadsType;
    slot.saveButton = json.saveButton || false;
    slot.dispatched = json.dispatched || false;

    if (json.assignment && typeof (json.assignment) === 'object') {
      slot.assignment = (new AssignmentSerializer().fromJson(json.assignment));
      if (json.assignment) {
        slot.assignment['yardBufferTime'] = json.assignment['yardBufferTime'];
        slot.assignment['yardBufferMinutes'] = json.assignment['yardBufferMinutes'];
      }
    } else {
      slot.assignment = new AssignmentSerializer().fromJson({ id: json.assignment });
      if (json.assignment) {
        slot.assignment['yardBufferTime'] = json.assignment['yardBufferTime'];
        slot.assignment['yardBufferMinutes'] = json.assignment['yardBufferMinutes'];
      }
    }
    if (json.driver && typeof (json.driver) === 'object') {
      slot.driver = new DriverSerializer().fromJson(json.driver);
    } else {
      slot.driver = new DriverSerializer().fromJson({ id: json.driver });
    }
    if (json.truck && typeof (json.truck) === 'object') {
      slot.truck = new TruckSerializer().fromJson(json.truck);
    } else {
      slot.truck = new TruckSerializer().fromJson({ id: json.truck });
    }

    return slot;
  }

  /**
  * @param  {Assignment} assignment
  * @returns any
  */
  toJson(slot: Slot): any {
    let json = {
      id: slot.id,
      over: slot.over,
      updating: slot.updating,
      numberOfLoadsType: slot.numberOfLoadsType,
      saveButton: slot.saveButton,
      assignment: slot.assignment,
      truck: slot.truck && slot.truck.id,
      driver: slot.driver && slot.driver.id,
      yardBufferTime: slot.assignment && slot.assignment['yardBufferTime']
    };
    return decamelizeKeysDeep(json);
  }
}
