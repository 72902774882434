"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var ticket_filters_dialog_component_1 = require("./ticket-filters-dialog.component");
var void_ticket_dialog_component_1 = require("./void-ticket-dialog.component");
var condensed_ticket_service_1 = require("./condensed-ticket.service");
var ticket_filter_service_1 = require("./ticket.filter.service");
var ticket_service_1 = require("./ticket.service");
var TicketsComponent = /** @class */ (function () {
    function TicketsComponent(route, router, ticketService, translationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.ticketService = ticketService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'date', title: this.translationService.instant('Date'), sortable: true, sortBy: 'delivery_time' },
            { key: 'number', title: this.translationService.instant('Ticket #'), sortable: true, sortBy: 'number' },
            { key: 'truck', title: this.translationService.instant('Truck #'), sortable: true, sortBy: 'truck__name' },
            { key: 'customer', title: this.translationService.instant('Customer'), sortable: true, sortBy: 'customer_organization__name' },
            { key: 'purchase-order', title: this.translationService.instant('Order'), sortable: true, sortBy: 'purchase_order__name' },
            { key: 'location', title: this.translationService.instant('Location'), sortable: true, sortBy: 'location__name' },
            { key: 'product', title: this.translationService.instant('Product'), sortable: true, sortBy: 'product__name' },
            { key: 'net', title: this.translationService.instant('Net'), sortable: true, sortBy: 'net' },
            { key: 'net-price', title: this.translationService.instant('Ticket Total'), sortable: true, sortBy: 'net_price' },
            { key: 'actions', title: this.translationService.instant('Action') }
        ];
        this.displayedColumns = [
            'select', 'date', 'number', 'truck', 'customer', 'purchase-order',
            'location', 'product', 'net', 'net-price', 'actions'
        ];
        this.availableFilters = [
            new filter_option_1.FilterOption({
                key: 'customer_organization', title: this.translationService.instant('Customer'),
                filterType: 'select', service: ticket_filter_service_1.TicketFilterService, slug: 'customers'
            }),
            new filter_option_1.FilterOption({
                key: 'truck', title: this.translationService.instant('Truck'),
                filterType: 'select', service: ticket_filter_service_1.TicketFilterService, slug: 'truck'
            }),
            new filter_option_1.FilterOption({
                key: 'purchase_order', title: this.translationService.instant('Order'),
                filterType: 'select', service: ticket_filter_service_1.TicketFilterService, slug: 'order'
            }),
            new filter_option_1.FilterOption({
                key: 'product', title: this.translationService.instant('Product'),
                filterType: 'select', service: ticket_filter_service_1.TicketFilterService, slug: 'products'
            }),
            new filter_option_1.FilterOption({
                key: 'location', title: this.translationService.instant('Location'),
                filterType: 'select', service: ticket_filter_service_1.TicketFilterService, slug: 'location'
            }),
            new filter_option_1.FilterOption({
                key: 'ticket_status', title: this.translationService.instant('void'),
                filterType: 'toggle'
            }),
            new filter_option_1.FilterOption({
                key: 'ticket_status', title: this.translationService.instant('in-progress'),
                filterType: 'toggle'
            }),
            new filter_option_1.FilterOption({
                key: 'ticket_status', title: this.translationService.instant('on-hold'),
                filterType: 'toggle'
            }),
            new filter_option_1.FilterOption({
                key: 'ticket_status', title: this.translationService.instant('complete'),
                filterType: 'toggle'
            }),
        ];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.ticketTableConfig = {
            hasHeader: true,
            service: condensed_ticket_service_1.CondensedTicketService,
            preferenceKey: 'TicketsComponent-CondensedTicketService',
            query: {},
            collectionTitle: this.translationService.instant('Tickets'),
            noResultsText: this.translationService.instant('a ticket'),
            customHeight: true,
            newRecordModal: function () { _this.openNewScaleTicket(); },
            sortBy: '',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Export'), action: 'export', link: false, external: false },
                { name: this.translationService.instant('Void'), action: 'void', link: false, external: false }
            ]
        };
        this.loading = true;
        this.tickets = [];
        this.pageSize = 25;
        this.filters = [];
        this.selectedCount = 0;
        this.allSelected = false;
        this.selectedTickets = [];
        this.excludeTickets = [];
        this.count = 0;
        this.page = 1;
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Export'), action: 'export', button: true, link: false }
        ];
        this.changeSearchEmitter = new core_1.EventEmitter();
        this.voidTicketCallback = function (e) {
            _this.ticket = e;
            _this.ticketTable.getRecords();
        };
    }
    TicketsComponent.prototype.ngOnInit = function () { };
    TicketsComponent.prototype.clickAction = function (event) {
        // Note: For future use
    };
    TicketsComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    TicketsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(ticket_filters_dialog_component_1.TicketFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = this.appliedFilters.reduce(function (acc, filter) {
            if (filter.filterType === 'checkbox') {
                if (filter.values === true) {
                    filter.values = ['True'];
                }
                acc[filter.key] = filter.values && filter.values[0] === 'True';
            }
            else if (filter.filterType === 'select') {
                acc[filter.key] = {
                    id: filter.values && filter.values[0],
                    name: filter.displayValues && filter.displayValues[0]
                };
            }
            else {
                acc[filter.key] = filter.value;
            }
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    TicketsComponent.prototype.filterChanges = function (filterRes) {
        var triggerRefresh = false;
        if (filterRes) {
            if (filterRes.product !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'product'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'product', title: 'Product', filterType: 'select',
                    service: ticket_filter_service_1.TicketFilterService, values: [filterRes.product.id],
                    displayValues: [filterRes.product.name], slug: 'products'
                }));
            }
            if (filterRes.customer !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'customer'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'customer_organization', title: 'Customer', filterType: 'select',
                    service: ticket_filter_service_1.TicketFilterService,
                    values: [filterRes.customer.id],
                    displayValues: [filterRes.customer.name], slug: 'customers'
                }));
            }
            if (filterRes.location !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'location'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'location', title: 'Location', filterType: 'select',
                    service: ticket_filter_service_1.TicketFilterService,
                    values: [filterRes.location.id],
                    displayValues: [filterRes.location.name], slug: 'locations'
                }));
            }
            if (filterRes.order !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'order'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'purchase_order', title: 'Order', filterType: 'select',
                    service: ticket_filter_service_1.TicketFilterService,
                    values: [filterRes.order.id],
                    displayValues: [filterRes.order.name], slug: 'orders'
                }));
            }
            if (filterRes.truck !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'truck'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'truck', title: 'Truck', filterType: 'select',
                    service: ticket_filter_service_1.TicketFilterService,
                    values: [filterRes.truck.id],
                    displayValues: [filterRes.truck.name], slug: 'trucks'
                }));
            }
            if (filterRes.void !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'ticket_status'; });
                if (filterRes.void) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'ticket_status', title: 'Void', filterType: 'toggle',
                        values: ['void'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
            if (filterRes.completed !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'ticket_status'; });
                if (filterRes.completed) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'ticket_status', title: 'Completed', filterType: 'toggle',
                        values: ['complete'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
            if (filterRes.inProgress !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'ticket_status'; });
                if (filterRes.inProgress) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'ticket_status', title: 'inProgress', filterType: 'toggle',
                        values: ['in-progress'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
            if (filterRes.onHold !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'ticket_status'; });
                if (filterRes.onHold) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'ticket_status', title: 'onHold', filterType: 'toggle',
                        values: ['on-hold'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
        }
        if (triggerRefresh) {
            this.ticketTable.getRecords();
        }
    };
    /**
     * @param  {} appliedFilters
     * Used for reseting the filters or when filter is changed
     */
    TicketsComponent.prototype.filtersModified = function (appliedFilters) {
        if (this.appliedFiltersDisplay) {
            this.appliedFiltersDisplay.ngOnInit();
        }
        this.appliedFilters = appliedFilters;
    };
    TicketsComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.appliedFilters, filter);
        this.ticketTable.getRecords();
    };
    TicketsComponent.prototype.menuAction = function (event) {
        switch (event[0]) {
            case 'void':
                this.void(event[1]);
                break;
            case 'export':
                this.createExport([event[1].id]);
                break;
        }
    };
    TicketsComponent.prototype.createExport = function (selectedTickets, excludeTickets) {
        var _this = this;
        if (selectedTickets === void 0) { selectedTickets = null; }
        if (excludeTickets === void 0) { excludeTickets = null; }
        var scope = { scaletickets: selectedTickets, exclude_scaletickets: excludeTickets, };
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        delete filters['undefined'];
        filters.search = this.search;
        this.ticketService.export(scope, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'tickets'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'tickets',
                    scope: scope,
                    params: params,
                    service: _this.ticketService,
                    buttonText: _this.translationService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    TicketsComponent.prototype.void = function (ticket) {
        var _this = this;
        this.voidDialog = this.dialog.open(void_ticket_dialog_component_1.VoidTicketDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.voidDialog.componentInstance.ticket = ticket;
        this.voidDialog.componentInstance.callback = this.voidTicketCallback;
        this.voidDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                //
            }
            _this.voidDialog = null;
        });
    };
    TicketsComponent.prototype.openNewScaleTicket = function () {
        this.router.navigate(['/scale/create-ticket']);
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    TicketsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    /**
     * @param {} option
     * called on selecting action option from the header of the table
     */
    TicketsComponent.prototype.setSelectedAction = function (option) {
        switch (option.action) {
            case 'export':
                if (this.ticketTable) {
                    var selectedTicketIds = void 0;
                    if (!this.ticketTable.allSelected) {
                        selectedTicketIds = this.ticketTable.selection.selected.map(function (ticket) { return ticket.id; });
                    }
                    var excludedTicketIds = void 0;
                    if (this.ticketTable.allSelected) {
                        excludedTicketIds = this.ticketTable.exclusion.selected.map(function (ticket) { return ticket.id; });
                    }
                    this.createExport(selectedTicketIds, excludedTicketIds);
                }
                break;
        }
    };
    return TicketsComponent;
}());
exports.TicketsComponent = TicketsComponent;
