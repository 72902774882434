const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Carrier {
  id: string;
  name: string;
  dotNumber: string;
  leasedOrg: boolean;
  uniqueBillingId: string;

  constructor(carrierInfo: any) {
    carrierInfo = camelcaseKeysDeep(carrierInfo);

    this.id = carrierInfo.id;
    this.name = carrierInfo.name;
    this.dotNumber = carrierInfo.dotNumber;
    this.leasedOrg = carrierInfo.leasedOrg;
    this.uniqueBillingId = carrierInfo.uniqueBillingId;
  }
}
