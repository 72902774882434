import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
const decamelizeKeysDeep = require('decamelize-keys-deep');

import { ResourceService } from '../shared/resource.service';
import { PaySheet, PaySheetTimeRange } from './pay-sheet';
import { PaySheetSerializer } from './pay-sheet.serializer';
import { PayRecord } from './pay-record';
import { PayRecordSerializer } from './pay-record.serializer';
import { cloneDeep } from 'lodash';
import { AuthenticationService } from '../shared';

export type DurationData = {
  date: string;
  startTime: string;
  endTime: string;
  duration: number;
};

@Injectable()
export class PaySheetService extends ResourceService<PaySheet> {

  constructor(
    public http: HttpClient,
    public authenticationService: AuthenticationService
  ) {
    super(http, 'driver-pay/pay-sheets/', new PaySheetSerializer());
  }

  listReportTable(query?: any): Observable<PaySheet[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    if (this.slug) {
      this.resourceUrl = this.baseUrl + this.slug;
    } else if (this.endpoint.includes('LOCAL:')) {
      // Do not manipulate the URL
    } else {
      this.resourceUrl = this.baseUrl + this.endpoint;
    }

    return this.http.get(this.resourceUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => this.captureReportTableMetaData(res, params)),
      map(data => this.filterLocally(data, params)),
      map(data => this.paginateLocally(data, params)),
      map(data => this.convertData(data)),
      map(data => this.generateReportTableRows(data, params)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  generateReportTableRows(reports: PaySheet[], query: HttpParams): PaySheet[] {
    let tableRecords: PaySheet[] = [];
    const reportKindFilter = query.get('carrier_type');
    reports.forEach((report) => {
      if (reportKindFilter !== 'external') { tableRecords.push({ ...report, type: 'internal' }); }
      if (reportKindFilter !== 'internal') { tableRecords.push({ ...report, type: 'external' }); }
    });
    return tableRecords;
  }

  getReportRecords(reportId: string, query?: any): Observable<PayRecord[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('pay_sheet', reportId);
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let resourceUrl: string;
    if (this.slug) {
      resourceUrl = this.baseUrl + this.slug;
    } else if (this.endpoint.includes('LOCAL:')) {
      // Do not manipulate the URL
    } else {
      resourceUrl = this.baseUrl + 'driver-pay/pay-records/';
    }

    return this.http.get(resourceUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => this.captureMetaData(res)),
      map(res => (res && res.map(item => new PayRecordSerializer().fromJson(item)))),
      catchError((res: Response) => this.handleError(res))
    );
  }

  getAllReportRecords(reportId: string, pageSize = 50, query?: any): Observable<PayRecord[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('pay_sheet', reportId);
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let resourceUrl: string;
    if (this.slug) {
      resourceUrl = this.baseUrl + this.slug;
    } else if (this.endpoint.includes('LOCAL:')) {
      // Do not manipulate the URL
    } else {
      resourceUrl = this.baseUrl + 'driver-pay/pay-records/';
    }

    let requestCount = 0;
    this.listAllProgress.next(0);

    return this.http.get(resourceUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => (this.captureMetaData(res))),
      mergeMap(() => {
        params = params.set('page_size', pageSize.toString());
        let nextReqs: Observable<PayRecord[]>[] = [];
        if (this.count < pageSize) {
          nextReqs.push(
            this.http.get(this.baseUrl + 'driver-pay/pay-records/', {
              headers: this.requestHeaders(),
              params: params
            }).map(res => {
              this.listAllProgress.next(1);
              return this.captureMetaData(res);
            })
          );
        } else {
          this.listAllProgress.next(1 / Math.ceil(this.count / pageSize));
          for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
            params = params.set('page', i.toString());
            nextReqs.push(
              this.http.get(this.baseUrl + 'driver-pay/pay-records/', {
                headers: this.requestHeaders(),
                params: params
              }).pipe(
                map(res => {
                  requestCount++;
                  this.listAllProgress.next(requestCount / Math.ceil(this.count / pageSize));
                  return res;
                }),
                map(res => this.captureMetaData(res))
              )
            );
          }
        }
        return combineLatest(nextReqs);
      }),
      map((data: any[]) => {
        let mergedList: PayRecord[] = [];
        data.forEach(list => {
          mergedList = mergedList.concat(list);
        });
        return mergedList.map(item => new PayRecordSerializer().fromJson(item));
      }),
      catchError((res: Response) => this.handleError(res))
    );
  }

  convertToDuration(startDateTime: string, endDateTime: string): DurationData {
    const startMoment = moment(startDateTime);
    const endMoment = moment(endDateTime);
    return {
      date: startMoment.format('YYYY-MM-DD'),
      startTime: startMoment.format('HH:mm'),
      endTime: endMoment.format('HH:mm'),
      duration: moment(endDateTime).diff(startDateTime, 'minutes')
    };
  }

  getDurationString(startTime: string, endTime: string): string {
    const payLineTotal = this.convertToDuration(startTime, endTime);
    return (startTime && endTime) ? Math.floor(payLineTotal.duration / 60) + 'h' + (payLineTotal.duration % 60) + 'm' : '-h--m';
  }

  getPayTotalDurationString(record: PayRecord): string {
    let minuteTotal = 0;
    record.data.rowData.payLines.forEach(line => {
      minuteTotal += moment(line.endDatetime).diff(line.startDatetime, 'minutes');
    });
    minuteTotal += record.data.rowData.payAdjustmentTotal;
    return Math.floor(minuteTotal / 60) + 'h' + (minuteTotal % 60) + 'm';
  }

  createReport(
    payBasis: 'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip',
    id: string, reportRange: PaySheetTimeRange
  ): Observable<PaySheet> {
    const resourceUrl = this.baseUrl + 'driver-pay/pay-sheets/';
    return this.http.post(`${resourceUrl}${id}/${payBasis}/`, reportRange, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => this.convertRecord(res))
    );
  }

  saveReport(
    payBasis: 'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip',
    id: string, reportRange: PaySheetTimeRange
  ): Observable<PaySheet> {
    const resourceUrl = this.baseUrl + 'driver-pay/pay-sheets/';
    return this.http.put(`${resourceUrl}${id}/${payBasis}/`, reportRange, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => this.convertRecord(res))
    );
  }

  saveRecord(recordId: string, data: PayRecord): Observable<PayRecord> {
    let saveData = data.data && data.data.rowData && decamelizeKeysDeep({
      payBasis: data.data.rowData.payBasis,
      payLines: data.data.rowData.payLines,
      payAdjustmentTotal: data.data.rowData.payAdjustmentTotal || 0,
      notes: data.data.rowData.notes
    });
    const resourceUrl = `${this.baseUrl}driver-pay/pay-records/${recordId}/save-pay-lines/`;
    return this.http.post(resourceUrl, saveData, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => <PayRecord>res)
    );
  }

  approveReport(date: string): Observable<PaySheet> {
    const resourceUrl = this.baseUrl + 'driver-pay/pay-sheets/';
    return this.http.post(`${resourceUrl}${date}/approve/`, null, {
      headers: this.requestHeaders()
    }).pipe(
      map(res => this.convertRecord(res))
    );
  }

  captureReportTableMetaData(res: any, query: HttpParams): any {
    let json = res;
    this.nextUri = json['next'];
    this.previousUri = json['previous'];
    this.count = (query.get('carrier_type') || query.get('carrier_type') !== 'all') ?
    (json['count'] || json['results'] && json['results'].length) :
    (json['count'] || json['results'] && json['results'].length) * 2;
    this.unreadCount = json['unread_count'] || 0;
    this.mockEndpoint = json['mock'];
    this.mockSearchKeys = json['mockSearchKeys'];
    this.metaData = json['meta'];
    return json.results || json;
  }

  formatExport(
    exportData: PayRecord[],
    exportType: 'raw' | 'hiredHauler' | 'payroll' | 'equipment' | 'hourlyTicket'
  ): any[] {
    const organization = this.authenticationService.getOrganization();
    let data = cloneDeep(exportData);

    return data.map(row => {
      let payDuration = 0;
      if (row.data && row.data.rowData) {
        if (row.data.rowData.payLines && row.data.rowData.payLines.length) {
          row.data.rowData.payLines.forEach((line, i) => {
            payDuration += this.convertToDuration(row.data.rowData.payLines[i].startDatetime,
              row.data.rowData.payLines[i].endDatetime).duration;
          });
        }
        if (row.data.rowData.payAdjustmentTotal) { payDuration += row.data.rowData.payAdjustmentTotal; }
        payDuration = payDuration / 60;
      }

      switch (exportType) {
        case 'raw':
          return Object.assign({}, ...function flatten(o) {
            return o ? [].concat(...Object.keys(o).map(key =>
                typeof o[key] === 'object' ? flatten(o[key]) : ({[key]: o[key]})
            )) : {};
          }(row));
        case 'hiredHauler':
          return {
            'Company #': organization.viewpointCompanyNumber,
            'Financial Month': '',
            'SaleDate': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.shift1StartTimestamp &&
              moment(row.data.assignment.jobevent.shift1StartTimestamp).format('M/D/YYYY'),
            'FromLoc': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.startLocation &&
              row.data.assignment.jobevent.job.startLocation.name &&
              row.data.assignment.jobevent.job.startLocation.name.split(' - ')[0],
            'Ticket #': '',
            'Matl Vendor': '',
            'Sale Type': 'J',
            'JCCo': organization.viewpointJcco,
            'Job': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.jobNumber,
            'Customer': '',
            'Cust Job': '',
            'Cust PO': '',
            'Paymt Type': '',
            'INCo': '',
            'To Loc': '',
            'Material': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.material &&
              row.data.assignment.jobevent.job.material.split(' - ')[0].replace(/"/g, '""'),
            'UM': '',
            'Matl Phase': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.poNumber,
            'Matl CT': 21,
            'Hauler Type': 'H',
            'Hauler Vendor': row.data &&
              row.data.assignment.carrierUniqueBillingId,
            'Truck': row.data &&
              row.data.assignment &&
              row.data.assignment.truck &&
              row.data.assignment.truck.name,
            'Driver': row.data &&
              row.data.assignment &&
              row.data.assignment.driver &&
              row.data.assignment.driver.name,
            'Truck Type': row.data &&
              row.data.assignment &&
              row.data.assignment.truck &&
              row.data.assignment.truck.truckTypeName,
            'Start Time': row.data &&
              row.data.rowData &&
              row.data.rowData.payLines &&
              row.data.rowData.payLines[0] &&
              moment(row.data.rowData.payLines[0].startDatetime).format('h:mm A'),
            'Stop Time':  row.data &&
              row.data.rowData &&
              row.data.rowData.payLines &&
              row.data.rowData.payLines[row.data.rowData.payLines.length - 1] &&
              moment(row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime).format('h:mm A'),
            'Loads': row.data &&
              row.data.rowData &&
              row.data.rowData.trips &&
              row.data.rowData.trips.length,
            'Miles': '',
            'Hours': '',
            'Zone': '',
            'Gross': 0,
            'Tare': 0,
            'Haul Code': 21,
            'Haul Phase': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.poNumber,
            'Haul CT': 21,
            'Units Sold': 0,
            'Unit Price': 0,
            'ECM': 'E',
            'Material Total': 0,
            'Haul Basis': payDuration.toFixed(2),
            'Haul Rate': '',
            'Haul Charge': '',
            'Pay Code': 20,
            'Pay Basis': payDuration.toFixed(2),
            'Pay Rate': '',
            'Pay Total': '',
            'Tax Type': 1,
            'Tax Code': 'TX0000',
            'Tax Basis': payDuration.toFixed(2),
            'Tax Total': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.rate &&
              (payDuration * Number(row.data.assignment.jobevent.rate)).toFixed(2),
          };
        case 'payroll': // PR format
          return {
            'Payroll Company': organization.viewpointJcco,
            'Employee Number': row.data &&
              row.data.assignment &&
              row.data.assignment.driver &&
              row.data.assignment.driver.uniqueBillingId,
            'Posting Date': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.shift1StartTimestamp &&
              moment(row.data.assignment.jobevent.shift1StartTimestamp).format('MM/DD/YY'),
            'Job Cost Company': organization.viewpointJcco,
            'Job': row.data.assignment.jobevent.job.jobNumber,
            'Phase': row.data.assignment.jobevent.job.poNumber,
            'EM Company': organization.viewpointJcco,
            'Equipment': row.data &&
              row.data.assignment &&
              row.data.assignment.truck &&
              row.data.assignment.truck.name,
            'Cost Code': '80.450.',
            'Hours': payDuration.toFixed(2)
          };
        case 'equipment': // EM format
          return {
            'Company': organization.viewpointCompanyNumber,
            'Sale Type': 'J',
            'Truck': row.data &&
              row.data.assignment &&
              row.data.assignment.truck &&
              row.data.assignment.truck.name,
            'JCCo': organization.viewpointJcco,
            'Job': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.jobNumber,
            'Matl Phase': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.poNumber,
            'Actual Date': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.shift1StartTimestamp &&
              moment(row.data.assignment.jobevent.shift1StartTimestamp).format('MM/DD/YY'),
            'Revenue Time Units': payDuration.toFixed(2)
          };
        case 'hourlyTicket':
          return {
            'Hourly Ticket #': row.ticketNumber,
            'Hauler Name': row.data &&
              row.data.assignment &&
              row.data.assignment.driver &&
              row.data.assignment.driver.carrier &&
              row.data.assignment.driver.carrier.name,
            'Truck #': row.data &&
              row.data.assignment &&
              row.data.assignment.truck &&
              row.data.assignment.truck.name,
            'Customer Name And #': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.project &&
              row.data.assignment.jobevent.job.project.customerOrganization &&
              row.data.assignment.jobevent.job.project.customerOrganization.name,
            'Date': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.shift1StartTimestamp &&
              moment(row.data.assignment.jobevent.shift1StartTimestamp).format('MM/DD/YY'),
            'Job Name': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.name,
            'Job Number': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.jobNumber,
            'Product': '',
            'Delivery Method': '',
            'Location': '',
            'Phase Code': row.data &&
              row.data.assignment &&
              row.data.assignment.jobevent &&
              row.data.assignment.jobevent.job &&
              row.data.assignment.jobevent.job.poNumber,
            'Start Time': row.data &&
              row.data.rowData &&
              row.data.rowData.payLines &&
              row.data.rowData.payLines[0] &&
              moment(row.data.rowData.payLines[0].startDatetime).format('h:mm A'),
            'Finished Time': row.data &&
              row.data.rowData &&
              row.data.rowData.payLines &&
              row.data.rowData.payLines[row.data.rowData.payLines.length - 1] &&
              moment(row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime).format('h:mm A'),
            'Adjusted Time': row.data &&
              row.data.rowData &&
              row.data.rowData.payAdjustmentTotal,
            'Total Time': payDuration.toFixed(2),
            'Load Count': row.data &&
              row.data.rowData &&
              row.data.rowData.trips &&
              row.data.rowData.trips.length,
            'Freight': '',
            'Freight Pay': ''
          };
      }
    });
  }

  parsePayRecordErrors(err): string[] {
    let errors = [];
    if (err.status >= 500) {
      errors.push(err.statusText);
    } else if (err.error) {
      Object.keys(err.error).forEach((key) => {
        if (Array.isArray(err.error[key])) {
          errors = errors.concat(
            err.error[key].map(details => (
              key.replace(/_/g, ' ') + ': ') + JSON.stringify(details)
            )
          );
        }
      });
    } else {
      errors.push(err);
    }
    return errors;
  }
}
