"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var invitation_service_1 = require("./invitation.service");
var user_service_1 = require("../users/user.service");
var api_service_1 = require("../shared/api.service");
var organization_service_1 = require("../organizations/organization.service");
var authentication_service_1 = require("../shared/authentication.service");
var user_serializer_1 = require("../users/user.serializer");
var api_service_2 = require("../shared/api.service");
var data_service_1 = require("../shared/data.service");
// const camelcaseKeysDeep = require('camelcase-keys-deep');
var InvitationComponent = /** @class */ (function () {
    function InvitationComponent(route, router, dataService, invitationService, userService, apiService, organizationService, authenticationService) {
        this.route = route;
        this.router = router;
        this.dataService = dataService;
        this.invitationService = invitationService;
        this.userService = userService;
        this.apiService = apiService;
        this.organizationService = organizationService;
        this.authenticationService = authenticationService;
        this.loading = true;
        this.errors = [];
        this.data = {};
        this.organization = {};
        this.step = 0;
        this.steps = ['Your Details', 'Profile Photo', 'Organization Details'];
        this.completedSteps = [];
        this.states = [];
        this.imageChanged = false;
        this.notFound = false;
        this.profileImage = {
            dataUri: null,
            file: null
        };
    }
    InvitationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.states = this.apiService.getStates();
        this.route.params.forEach(function (params) {
            _this.inviteCode = params['invite_code'];
            if (params['step'] && params['step'].length) {
                _this.step = +params['step'];
            }
            _this.getInvitation();
        });
    };
    InvitationComponent.prototype.getInvitation = function () {
        var _this = this;
        this.invitationService.getInvitation(this.inviteCode).subscribe(function (invitation) {
            // invitation = camelcaseKeysDeep(invitation);
            _this.invitation = invitation;
            if (_this.invitation.status === 'pending') {
                _this.authenticationService.logout();
            }
            else if (_this.invitation.status === 'accepted') {
                _this.completedSteps.push('Your Details');
            }
            _this.loadData(_this.invitation);
            // Remove the Organization Details step if a Primary Contact has been set.
            if (_this.invitation && _this.invitation.recipientOrganization && _this.invitation.recipientOrganization.primaryContact) {
                var index = _this.steps.indexOf('Organization Details', 0);
                if (index > -1) {
                    _this.steps.splice(index, 1);
                }
            }
            _this.step = 1;
            _this.loading = false;
        }, function (err) {
            if (err.includes('detail: Not found.')) {
                _this.completedSteps.push('Your Details');
                var index = _this.steps.indexOf('Organization Details', 0);
                if (index > -1) {
                    _this.steps.splice(index, 1);
                }
                _this.step = _this.completedSteps.length + 1;
            }
            _this.loading = false;
            if (!_this.authenticationService.isLoggedIn()) {
                _this.step = 0;
                _this.notFound = true;
                _this.errors = api_service_2.parseErrors(err);
            }
        });
    };
    InvitationComponent.prototype.skipStep = function () {
        var currentStep = this.steps[this.step - 1];
        this.completedSteps.push(currentStep);
        if (this.completedSteps && this.steps && this.completedSteps.length === this.steps.length) {
            if (this.authenticationService.isLoggedIn()) {
                this.activateSidebar();
                this.router.navigate(['/daily']);
            }
            else {
                this.router.navigate(['/login']);
            }
        }
        else {
            this.step += 1;
            if (this.invitation && this.invitation.id) {
                this.router.navigate(['/invitations', this.invitation.id, this.step]);
            }
            else {
                this.errors = api_service_2.parseErrors('Invitation was not found.');
            }
        }
    };
    InvitationComponent.prototype.cancelImageChange = function () {
        this.imageChanged = false;
        this.profileImage['file'] = null;
    };
    InvitationComponent.prototype.register = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.invitationService.registerFromInvitation(this.invitation.id, this.invitation.kind, this.data).subscribe(function (result) {
            _this.loading = false;
            _this.step += 1;
            _this.completedSteps.push('Your Details');
            if (_this.completedSteps && _this.steps && _this.completedSteps.length === _this.steps.length) {
                _this.activateSidebar();
                _this.router.navigate(['/daily']);
            }
            else {
                if (_this.invitation && _this.invitation.id) {
                    _this.router.navigate(['/invitations', _this.invitation.id, _this.step]);
                }
                else {
                    _this.errors = api_service_2.parseErrors('Invitation was not found.');
                }
            }
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.loading = false;
        });
    };
    InvitationComponent.prototype.updatePhoto = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var userId = currentUser && currentUser.id;
        this.userService.saveWithImage({ id: userId }, this.profileImage.file).subscribe(function (user) {
            user = (new user_serializer_1.UserSerializer).fromJson(user);
            var _user = _this.authenticationService.user();
            if (_user) {
                _user.image = user.image;
                localStorage.setItem('currentUser', JSON.stringify(_user));
                _this.loading = false;
                _this.completedSteps.push('Profile Photo');
                if (_this.completedSteps && _this.steps && _this.completedSteps.length === _this.steps.length) {
                    _this.activateSidebar();
                    _this.router.navigate(['/daily']);
                }
                else {
                    _this.step += 1;
                    if (_this.invitation && _this.invitation.id) {
                        _this.router.navigate(['/invitations', _this.invitation.id, _this.step]);
                    }
                    else {
                        _this.errors = api_service_2.parseErrors('Invitation was not found.');
                    }
                }
            }
            else {
                _this.errors = api_service_2.parseErrors('User was not returned.');
                _this.loading = false;
            }
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.loading = false;
        });
    };
    InvitationComponent.prototype.updateOrganization = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.organization.id = this.invitation.recipientOrganization && this.invitation.recipientOrganization.id;
        this.organizationService.save(this.organization).subscribe(function (result) {
            _this.loading = false;
            _this.step += 1;
            _this.completedSteps.push('Organization Details');
            _this.activateSidebar();
            _this.router.navigate(['/daily']);
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    InvitationComponent.prototype.fileChange = function (e, form) {
        var profileImage = this.profileImage;
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
            profileImage.dataUri = loadEvent.target.result;
        };
        var file = e.srcElement.files[0];
        profileImage.file = file;
        reader.readAsDataURL(file);
        this.imageChanged = true;
    };
    InvitationComponent.prototype.activateSidebar = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.sidebar = true;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.dataService.changeData({
            authenticated: true,
            displaySidebar: true
        });
    };
    InvitationComponent.prototype.loadData = function (invitation) {
        this.data = {
            'profile': {
                'firstName': invitation.resultUser && invitation.resultUser.firstName,
                'lastName': invitation.resultUser && invitation.resultUser.lastName,
                'email': invitation.recipientEmail,
                'title': invitation.resultUser && invitation.resultUser.title,
                'phoneNumber': invitation.resultUser && invitation.resultUser.phoneNumber
            },
            'user': {
                'password': ''
            }
        };
        if (invitation && invitation.recipientOrganization) {
            this.organization = {
                'name': invitation.recipientOrganization.name,
                'street': invitation.recipientOrganization.street,
                'city': invitation.recipientOrganization.city,
                'state': invitation.recipientOrganization.state,
                'zipcode': invitation.recipientOrganization.zipcode,
                'phoneNumber': invitation.recipientOrganization.phoneNumber
            };
        }
    };
    return InvitationComponent;
}());
exports.InvitationComponent = InvitationComponent;
