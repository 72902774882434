import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { ProjectService } from '../projects/project.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'ruckit-marketplace-new-project-dialog',
  templateUrl: './marketplace-new-project-dialog.component.html',
  styleUrls: ['./marketplace-new-project-dialog.component.scss']
})
export class MarketplaceNewProjectDialogComponent implements OnInit {
  loading = false;
  @Input() model: any = {
    customerOrganization: '',
    name: ''
  };
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<MarketplaceNewProjectDialogComponent>,
    private projectService: ProjectService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  submit() {
    this.loading = true;

    this.projectService.save(this.model).subscribe(project => {
      this.dialogRef.close();
      this.callback(project);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }
}
