import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { PaySheetFilter } from './pay-sheet.filter';
import { requestHeaders, handleError } from '../shared/api.service';

@Injectable()
export class PaySheetFilterService {
  baseUrl = environment.serverUrl;
  public nextUris = {};

  constructor(private http: HttpClient) { }

  getFilters(id: string, filterType: string, search: string = null, filters: any = null): Observable<PaySheetFilter[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '6');
    if (search) { params = params.set('filter_search', search); }
    for (let property in filters) {
      if (filters.hasOwnProperty(property)) {
        params = params.set(property, filters[property]);
      }
    }

    let filtersUrl = this.baseUrl + 'driver-pay/pay-sheets/' + id + '/filters/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(filterType, res); }),
      catchError(handleError)
    );
  }

  getNext(filterType: string): Observable<PaySheetFilter[]> {
    if (this.nextUris[filterType]) {
      return this.http.get(this.nextUris[filterType], { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(filterType, res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  listFilters(id: string, filterType: string, query?: any): Observable<PaySheetFilter[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '6');
    for (let property in query) {
      if (query.hasOwnProperty(property)) {
        params = params.set(property, query[property]);
      }
    }

    let filtersUrl = this.baseUrl + 'driver-pay/pay-sheets/' + id + '/filters/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(filterType, res); }),
      catchError(handleError)
    );
  }

  listNext(filterType: string): Observable<PaySheetFilter[]> {
    if (this.nextUris[filterType]) {
      return this.http.get(this.nextUris[filterType], { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(filterType, res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(filterType: string, res: Object, condensed = false) {
    let resObj = res;
    this.nextUris[filterType] = resObj['next'];
    let body = resObj['results'];
    if (body) {
      return body.map(filter => {
        return new PaySheetFilter(filter);
      });
    } else {
      return {};
    }
  }
}
