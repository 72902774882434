"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".dropdown[_ngcontent-%COMP%]{width:100%}input.license-plate[_ngcontent-%COMP%]{text-transform:uppercase}.form-content[_ngcontent-%COMP%]{margin-top:30px}.truck-image[_ngcontent-%COMP%]{height:194px}.truck-image[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{width:370px;height:194px;border-radius:4px;overflow:hidden;-o-object-fit:cover;object-fit:cover}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{padding:0;position:relative;flex-grow:1;min-width:50%}form[_ngcontent-%COMP%]   .field-group.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .field-group.half-width[_ngcontent-%COMP%]{min-width:25%;margin-left:20px}form[_ngcontent-%COMP%]   .field-group.truck-number[_ngcontent-%COMP%]{margin-left:0;padding-right:10px}form[_ngcontent-%COMP%]   .field-group.license-plate[_ngcontent-%COMP%]{margin-left:0;padding-left:10px}form[_ngcontent-%COMP%]   .field-group.license-plate[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{text-transform:uppercase}.custom-field[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{margin-top:10px}.mat-dialog-content.add-truck[_ngcontent-%COMP%]{overflow:auto;max-height:calc(100% - 60px - 60px)}.tablet[_ngcontent-%COMP%]   .mat-dialog-content[_ngcontent-%COMP%]{max-height:calc(100% - 120px);overflow:auto}.tablet[_ngcontent-%COMP%]   .truck-image[_ngcontent-%COMP%]{height:104px}.tablet[_ngcontent-%COMP%]   .truck-image[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{padding:0 20px}"];
exports.styles = styles;
