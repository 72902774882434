"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_constants_1 = require("../../app.constants");
var AttachmentTypePipe = /** @class */ (function () {
    function AttachmentTypePipe() {
    }
    AttachmentTypePipe.prototype.transform = function (images, type) {
        if (type === void 0) { type = app_constants_1.TRIPTICKETIMAGETYPE; }
        return images && images.length
            ? images.filter(function (img) { return img.fileType === type; })
            : [];
    };
    return AttachmentTypePipe;
}());
exports.AttachmentTypePipe = AttachmentTypePipe;
