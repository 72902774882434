"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var CompleteHourPipe = /** @class */ (function () {
    function CompleteHourPipe() {
    }
    CompleteHourPipe.prototype.transform = function (record, hour) {
        var complete = false;
        var currentTime = moment().toDate();
        if (!record || !record.startTimeTimestamp) {
            return complete;
        }
        var startTime = moment(record.startTimeTimestamp).toDate();
        var _date = moment(startTime).format('YYYY-MM-DD');
        var timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
        timeAtHour.add(3599, 'seconds');
        if (startTime < timeAtHour.toDate() && !record.endTimeTimestamp && timeAtHour.toDate() < currentTime) {
            complete = true;
        }
        else if (startTime < timeAtHour.toDate() && timeAtHour.toDate() < moment(record.endTimeTimestamp).toDate()) {
            complete = true;
        }
        return complete;
    };
    return CompleteHourPipe;
}());
exports.CompleteHourPipe = CompleteHourPipe;
