import { DeviceStatusEvent } from './device-status-event';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class DeviceStatusEventSerializer {
  /**
   * @param  {any} json
   * @returns DeviceStatusEvent
   */
  fromJson(json: any): DeviceStatusEvent {
    json = camelcaseKeysDeep(json);
    const deviceStatusEvent = new DeviceStatusEvent();
    if (!json) {
      return deviceStatusEvent;
    }

    deviceStatusEvent.id = json.id;
    deviceStatusEvent.deviceId = json.deviceId;
    deviceStatusEvent.organization = json.organization;
    deviceStatusEvent.driver = json.driver;
    deviceStatusEvent.data = json.data;
    deviceStatusEvent.createdAt = json.createdAt;
    if (
      json.locationAuthorization === 'Always' ||
      json.locationAuthorization === 'always' ||
      json.locationAuthorization === 'Authorized Always' ||
      json.locationAuthorization === 'true'
    ) {
      deviceStatusEvent.locationAuthorization = 'Always';
    } else {
      deviceStatusEvent.locationAuthorization = 'Not Always';
    }
    deviceStatusEvent.lowPowerMode = json.lowPowerMode;
    deviceStatusEvent.backgroundAppRefresh = json.backgroundAppRefresh;
    deviceStatusEvent.lowDeviceStorage = json.lowDeviceStorage;
    if (deviceStatusEvent.data) {
      deviceStatusEvent.applicationVersion =
        deviceStatusEvent.data['applicationVersion'];
      deviceStatusEvent.applicationName =
        deviceStatusEvent.data['applicationName'];
      const deviceOS = deviceStatusEvent.data['deviceOs'];
      if (
        deviceOS &&
        (deviceOS.includes('iOS') ||
          deviceOS.includes('ios') ||
          deviceOS.includes('Android') ||
          deviceOS.includes('android'))
      ) {
        deviceStatusEvent.deviceOS = deviceOS;
      } else {
        deviceStatusEvent.deviceOS = `Android ${deviceOS}`;
      }
      deviceStatusEvent.signal = deviceStatusEvent.data['signal'];
      deviceStatusEvent.battery = deviceStatusEvent.data['battery'];
    }

    return deviceStatusEvent;
  }

  /**
   * @param  {DeviceStatusEvent} deviceStatusEvent
   * @returns any
   */
  toJson(deviceStatusEvent: DeviceStatusEvent): any {
    let json = {
      id: deviceStatusEvent.id,
    };

    return decamelizeKeysDeep(json);
  }
}
