"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var trip_filter_service_1 = require("../../trips/trip.filter.service");
var DriverTripFiltersDialogComponent = /** @class */ (function () {
    function DriverTripFiltersDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = false;
        this.errors = [];
        this.defaultDropdownConfig = {
            service: trip_filter_service_1.TripFilterService,
            serviceFunction: 'listFilters',
            searchKey: 'filter_search',
        };
        this.customerDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Customer', loadingText: 'Loading Customers...', noResultsText: 'No Customers', serviceFunctionScope: 'customers', getFilter: 'jobevent__customer_organization', query: { serializer: 'OrganizationFilter' } });
        this.projectDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Project', loadingText: 'Loading Projects...', noResultsText: 'No Projects', serviceFunctionScope: 'projects', getFilter: 'jobevent__job__project', query: { serializer: 'ProjectFilter' } });
        this.jobDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Job', loadingText: 'Loading Jobs...', noResultsText: 'No Jobs', serviceFunctionScope: 'jobs', getFilter: 'jobevent__job', query: {
                serializer: 'JobFilter',
                jobevent__shift1_start__gte: null,
                jobevent__shift1_start__lte: null,
            } });
        this.visibleFields = {
            showEdited: true,
            showIncomplete: true,
            showUninvoiced: true,
            showRetake: true,
            showFromDate: true,
            showDateTo: true,
        };
        this.model = {};
    }
    DriverTripFiltersDialogComponent.prototype.ngOnInit = function () {
        this.filterTrips();
    };
    DriverTripFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    DriverTripFiltersDialogComponent.prototype.submit = function () {
        this.convertDates();
        this.callback(this.model);
        this.dialogRef.close();
    };
    DriverTripFiltersDialogComponent.prototype.convertDates = function (assign) {
        if (assign === void 0) { assign = true; }
        var startDate, endDate;
        if (this.model.startDate) {
            startDate = new Date(this.model.startDate);
            var name_1 = this.datePipe.transform(startDate, 'MM/dd/yyyy');
            startDate.setHours(0, 0, 0, 0);
            if (assign) {
                Object.assign(this.model.startDate, {
                    id: startDate.toISOString(),
                    name: name_1,
                });
            }
        }
        if (this.model.endDate) {
            endDate = new Date(this.model.endDate);
            var name_2 = this.datePipe.transform(endDate, 'MM/dd/yyyy');
            endDate.setHours(23, 59, 59, 999);
            if (assign) {
                Object.assign(this.model.endDate, {
                    id: endDate.toISOString(),
                    name: name_2,
                });
            }
        }
        if (!assign) {
            return {
                startDate: startDate && startDate.toISOString(),
                endDate: endDate && endDate.toISOString(),
            };
        }
    };
    DriverTripFiltersDialogComponent.prototype.filterTrips = function () {
        var _a = this.convertDates(false), startDate = _a.startDate, endDate = _a.endDate;
        this.jobDropdownConfig.query = __assign({}, this.jobDropdownConfig.query, {
            jobevent__shift1_start__gte: startDate,
            jobevent__shift1_start__lte: endDate,
        });
        this.jobDropdownConfig = __assign({}, this.jobDropdownConfig);
    };
    DriverTripFiltersDialogComponent.prototype.onDateChanged = function (dates, type) {
        if (type === void 0) { type = ''; }
        this.model[type] = dates[0];
        this.filterTrips();
    };
    return DriverTripFiltersDialogComponent;
}());
exports.DriverTripFiltersDialogComponent = DriverTripFiltersDialogComponent;
