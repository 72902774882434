export class StaticData {

}

function featureToggle(feature, normal = true) {
  let _normal = normal ? 'yes' : 'no';
  return feature ? 'yes' : _normal;
}

export function mapOptions(options = {}, features = {}, preferences = {}): any {
  let _options = {
    disableDefaultUI: false,
    scrollwheel: false,
    streetViewControl: false,
    zoomControl: true,
    fullscreenControl: true,
    mapTypeControl: true,
    fullscreenControlOptions: { position: 12 },
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'hybrid']
    },
    zoom: 10,
    tilt: 0,
    center: { lat: 30.271095, lng: -97.728443 },
  };

  if (!preferences['mapStyle'] || preferences['mapStyle'] === 'ruckit-map-style') {
    _options = Object.assign(_options, {
      styles: [
        {
          'featureType': 'all',
          'elementType': 'geometry',
          'stylers': [{ 'color': '#f2f2f2' }]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['administrative.country'], false) }]
        },
        {
          'featureType': 'administrative.province',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['administrative.province'], true) }]
        },
        {
          'featureType': 'administrative.locality',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['administrative.locality'], true) }]
        },
        {
          'featureType': 'administrative.neighborhood',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['administrative.neighborhood'], true) }]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['administrative.land_parcel'], true) }]
        },
        {
          'featureType': 'landscape.man_made',
          'elementType': 'labels.icon',
          'stylers': [{ 'visibility': featureToggle(features['landscape.man_made'], false) }]
        },
        {
          'featureType': 'landscape.natural.landcover',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['landscape.natural.landcover'], false) }]
        },
        {
          'featureType': 'landscape.natural.terrain',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['landscape.natural.terrain'], false) }]
        },
        {
          'featureType': 'poi',
          'elementType': 'all',
          'stylers': [{ 'visibility': 'simplified' }]
        },
        {
          'featureType': 'poi.attraction',
          'elementType': 'geometry',
          'stylers': [{ 'visibility': featureToggle(features['poi.attraction.geometry'], false) }]
        },
        {
          'featureType': 'poi.attraction',
          'elementType': 'labels',
          'stylers': [{ 'visibility': featureToggle(features['poi.attraction.labels'], false) }]
        },
        {
          'featureType': 'poi.business',
          'elementType': 'labels.text',
          'stylers': [{ 'visibility': featureToggle(features['poi.business.labels.text'], false) }]
        },
        {
          'featureType': 'poi.business',
          'elementType': 'labels.icon',
          'stylers': [{ 'visibility': featureToggle(features['poi.business.labels.icon'], false) }]
        },
        {
          'featureType': 'poi.government',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['poi.government.all'], false) }]
        },
        {
          'featureType': 'poi.medical',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['poi.medical.all'], false) }]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'all',
          'stylers': [{ 'visibility': 'simplified' }]
        },
        {
          'featureType': 'poi.place_of_worship',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['poi.place_of_worship.all'], false) }]
        },
        {
          'featureType': 'poi.school',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['poi.school.all'], false) }]
        },
        {
          'featureType': 'poi.sports_complex',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['poi.sports_complex.all'], false) }]
        },
        {
          'featureType': 'road',
          'elementType': 'labels',
          'stylers': [{ 'lightness': '7' }]
        },
        {
          'featureType': 'road',
          'elementType': 'labels.text',
          'stylers': [{ 'visibility': featureToggle(features['road.labels.text'], false) }]
        },
        {
          'featureType': 'road',
          'elementType': 'labels.text.fill',
          'stylers': [{ 'color': '#333333' }]
        },
        {
          'featureType': 'road',
          'elementType': 'labels.icon',
          'stylers': [{ 'visibility': featureToggle(features['road.labels.icon'], true) }]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry.fill',
          'stylers': [{ 'visibility': featureToggle(features['road.highway.geometry.fill'], true) }, { 'color': '#ffffff' }]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'labels.text.fill',
          'stylers': [{ 'color': '#ffffff' }, { 'visibility': featureToggle(features['road.highway.labels.text.fill'], true) }]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'labels.text.stroke',
          'stylers': [{ 'visibility': featureToggle(features['road.highway.labels.text.stroke'], true) }, { 'color': '#000000' }]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['road.arterial.all'], true) }]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'geometry',
          'stylers': [{ 'color': '#ffffff' }]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'labels.text.fill',
          'stylers': [{ 'color': '#ffffff' }, { 'visibility': featureToggle(features['road.arterial.labels.text.fill'], true) }]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'labels.text.stroke',
          'stylers': [{ 'visibility': featureToggle(features['road.arterial.labels.text.stroke'], false) }]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'labels',
          'stylers': [{ 'visibility': featureToggle(features['road.arterial.labels'], true) }]
        },
        {
          'featureType': 'road.local',
          'elementType': 'geometry',
          'stylers': [{ 'visibility': featureToggle(features['road.local.all'], true) }, { 'saturation': '100' }, { 'lightness': '100' }]
        },
        {
          'featureType': 'road.local',
          'elementType': 'labels',
          'stylers': [{ 'visibility': featureToggle(features['road.local.labels'], false) }]
        },
        {
          'featureType': 'road.local',
          'elementType': 'labels.text',
          'stylers': [{ 'visibility': featureToggle(features['road.local.labels.text'], true) }]
        },
        {
          'featureType': 'road.local',
          'elementType': 'labels.text.fill',
          'stylers': [{ 'visibility': featureToggle(features['road.local.labels.text.fill'], true) }, { 'color': '#333333' }]
        },
        {
          'featureType': 'transit',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['transit.all'], false) }]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['transit.line.all'], false) }]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'geometry',
          'stylers': [{ 'visibility': featureToggle(features['transit.line.geometry'], false) }]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'labels',
          'stylers': [{ 'visibility': featureToggle(features['transit.line.labels'], false) }]
        },
        {
          'featureType': 'transit.station',
          'elementType': 'geometry',
          'stylers': [{ 'visibility': featureToggle(features['transit.station.geometry'], false) }]
        },
        {
          'featureType': 'transit.station',
          'elementType': 'labels',
          'stylers': [{ 'visibility': featureToggle(features['transit.station.labels'], false) }]
        },
        {
          'featureType': 'transit.station.bus',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['transit.station.bus'], false) }]
        },
        {
          'featureType': 'water',
          'elementType': 'all',
          'stylers': [{ 'visibility': featureToggle(features['water.all'], true) }, { 'color': '#c0e4f3' }]
        }
      ]
    });
  }

  _options = Object.assign(_options, options);

  return _options;
}
