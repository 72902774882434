<div
  class="app-content driver-details-page-container"
  *ngIf="driver$ | async as driver; else loadingOrError"
>
  <!-- {{ driver.name }} -->
  <my-toolbar
    icon="icon-back"
    [iconLink]="returnUrl"
    [title]="
      'Driver Details - ' + (driver.name | truncate: [30, '...']) | translate
    "
    (updatedMarkets)="updatedMarkets()"
  ></my-toolbar>
  <action-bar>
    <div class="subnav">
      <div class="links">
        <a
          [routerLink]="['/drivers', driver.id, 'details', 'details']"
          [ngClass]="{active: selectedTab === 'details'}"
          queryParamsHandling="merge"
          translate
          >Details</a
        >
        <a
          [routerLink]="['/drivers', driver.id, 'details', 'trips']"
          queryParamsHandling="merge"
          [ngClass]="{active: selectedTab === 'trips'}"
          translate
          >Trips</a
        >
        <a
          [routerLink]="['/drivers', driver.id, 'details', 'geotrips']"
          queryParamsHandling="merge"
          [ngClass]="{active: selectedTab === 'geotrips'}"
          translate
          >GeoTrips</a
        >
        <a
          [routerLink]="['/drivers', driver.id, 'details', 'punchcards']"
          [ngClass]="{active: selectedTab === 'punchcards'}"
          queryParamsHandling="merge"
          translate
          >Punch Cards</a
        >
        <a
          [routerLink]="['/drivers', driver.id, 'details', 'shifts']"
          [ngClass]="{active: selectedTab === 'shifts'}"
          queryParamsHandling="merge"
          translate
          >Shifts</a
        >
      </div>
    </div>
  </action-bar>

  <driver-details *ngIf="selectedTab === 'details'" [driver]="driver">
  </driver-details>

  <driver-all-trips
    *ngIf="selectedTab === 'trips'"
    [driver]="driver"
  ></driver-all-trips>

  <driver-geotrips
    *ngIf="selectedTab === 'geotrips'"
    [driver]="driver"
  ></driver-geotrips>

  <driver-punch-cards
    *ngIf="selectedTab === 'punchcards'"
    [driver]="driver"
  ></driver-punch-cards>

  <driver-shifts
    *ngIf="selectedTab === 'shifts'"
    [driver]="driver"
  ></driver-shifts>
</div>

<ng-template #loadingOrError>
  <my-toolbar
    icon="icon-back"
    [iconLink]="returnUrl"
    [title]="
    'Driver Details '  | translate
  "
  ></my-toolbar>
  <!-- some code here -->
  <div class="not-found" *ngIf="error$ | async; else loading">
    <!-- <div translate>User not found.</div>
    <a class="btn btn-primary" [href]="returnUrl">Go back</a> -->
    <div>
      <img src="assets/img/icon-404-barricade.svg" />

      <h1 translate>404</h1>
      <h2 translate>Oops! User not found.</h2>

      <p class="actions">
        <a [href]="returnUrl" translate>Take me back</a>
      </p>
    </div>
  </div>

  <ng-template #loading>
    <my-loader></my-loader>
  </ng-template>
</ng-template>
