// angular dependencies
import {
  Component, Input, Output, OnInit, EventEmitter,
  ViewChild, TemplateRef
} from '@angular/core';
import {
  trigger, transition, style, state, animate
} from '@angular/animations';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Location } from '@angular/common';
import { difference, clone } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

// price related components
import { PriceService } from './price.service';
// import { PriceFiltersDialogComponent } from './price-filters-dialog.component';
import { PriceDialogComponent } from './price-dialog.component';
// shared components and services
import { parseErrors } from '../shared/api.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { FilterOption } from '../shared/filters-panel/filter-option';

@Component({
  selector: 'prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss'],
  providers: [PriceService],
  animations: [
    trigger('statusAnimation', [
      state('updated', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      state('removed', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      transition('* => removed', [
        animate('300ms ease-out', style({ background: '#fbd0d1', opacity: 0 }))
      ]),
      transition('* => updated', [
        animate('300ms', style({ background: '#d4e6f7' })),
        animate('300ms ease-out', style({ background: '#FFFFFF' }))
      ])
    ])
  ]
})
export class PricesComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'product', title: this.translationService.instant('Product'), sortable: true, sortBy: 'product' },
    { key: 'change-type', title: this.translationService.instant('Change Type'), sortable: false },
    { key: 'change-amount', title: this.translationService.instant('Change Amount'), sortable: false },
    { key: 'notes', title: this.translationService.instant('Notes'), sortable: false },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false },
  ];
  @Input() displayedColumns = [
    'select', 'product', 'change-type', 'change-amount', 'notes', 'action'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  priceTableConfig = {
    hasHeader: true,
    service: PriceService,
    preferenceKey: 'PricesComponent-PriceService',
    query: {},
    collectionTitle: this.translationService.instant('Price'),
    noResultsText: this.translationService.instant('a price'),
    newRecordModal: this.openPrice,
    sortBy: '',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
    ]
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('priceTable', { static: false }) priceTable;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;
  errors = [];
  @Input() priceList;
  loading = true;
  count = 0;
  selectedCount = 0;
  filters = [];
  filtersDialog;
  pricesReq;
  type = 'all';
  allSelected = false;

  confirmDialog: MatDialogRef<any>;
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Remove'), action: 'remove', link: false }
  ];

  savePriceCallback = (e) => {
    this.priceTable.getRecords();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private priceService: PriceService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
      this.type = params['type'];
    });
    this.appliedFilters = this.appliedFilters.concat(new FilterOption({
      key: 'price_list', title: this.translationService.instant('Name'),
      filterType: '', hidden: true, service: PriceService,
      values: [this.priceList.id], displayValues: [this.priceList.name]
    }));
    this.getPrices();
  }

  getPrices(query = {}, append = false) {
    if (this.pricesReq) { this.pricesReq.unsubscribe(); }
    this.priceService.list({
      search: this.search,
      price_list: this.priceList['id'],
      ...query
    }).subscribe(prices => {
      this.count = this.priceService.count;
      this.loading = false;
      this.pricesReq = prices;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  clickAction(event) {
    if (event) {
      this.openPrice(event[1]);
    }
  }

  menuAction(action: string, price): void {
    switch (action) {
      case 'edit':
        this.openPrice(price);
        break;
      case 'remove':
        this.removePrice(price);
        break;
    }
  }

  removePrice(price) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Price?'),
      body: this.translationService.instant('This price will be deleted and cannot be recovered.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.priceService.remove(price).subscribe((res) => {
          this.priceTable.getRecords();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;
    params['type'] = params['type'] ? params['type'] : this.type;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      owner: 'owner_organization'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof (value) === 'boolean') {
        if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let displayKey = key;
      let hidden = false;
      let filter = {
        key: displayKey,
        value: displayValue || value,
        query: query,
        hidden: hidden
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });
    this.filters = difference(this.filters, falseyFilters);
    // this.getPrices();
  }

  filtersModified(appliedFilters) {
  }

  openPrice(price = null) {
    const dialog = this.dialog.open(PriceDialogComponent, {
      width: '500px'
    });
    dialog.componentInstance.priceList = this.priceList['id'];
    dialog.componentInstance.price = clone(price);
    dialog.componentInstance.callback = this.savePriceCallback;
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
