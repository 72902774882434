import { Location } from '../locations/location';
import { LocationSerializer } from '../locations/location.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class JobEventMap {
  locationUpdates: any[];
  locations: Location[];
  routes: any[];

  constructor(jobEventMapInfo: any) {
    jobEventMapInfo = camelcaseKeysDeep(jobEventMapInfo);

    this.locationUpdates = jobEventMapInfo.locationUpdates;
    this.locations = jobEventMapInfo.locations.map((location) => {
      return (new LocationSerializer).fromJson(location);
    });
    this.routes = jobEventMapInfo.routes;
  }
}
