"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var index_1 = require("../../shared/index");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var job_event_stat_service_1 = require("../../job-event-stats/job-event-stat.service");
var JobStatsComponent = /** @class */ (function () {
    function JobStatsComponent(authenticationService, translationService, dialog) {
        this.authenticationService = authenticationService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'requested-trucks', title: this.translationService.instant('Requested Trucks') },
            { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
            { key: 'dispatched-trucks', title: this.translationService.instant('Dispatched Trucks') },
            { key: 'ordered-loads', title: this.translationService.instant('Ordered Loads') },
            { key: 'delivered-loads', title: this.translationService.instant('Delivered Loads') },
            { key: 'ordered-tons', title: this.translationService.instant('Ordered Tons') },
            { key: 'delivered-tons', title: this.translationService.instant('Delivered Tons') },
            { key: 'drivers-on-shift', title: this.translationService.instant('Drivers on Shift') },
            { key: 'connex-actual-tons', title: this.translationService.instant('Connex Actual Tons') },
        ];
        this.displayedColumns = [
            'requested-trucks', 'confirmed-trucks', 'dispatched-trucks',
            'ordered-loads', 'delivered-loads', 'ordered-tons', 'delivered-tons',
            'drivers-on-shift', 'connex-actual-tons'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.hideCancelled = false;
        this.loading = true;
        this.tableConfig = {
            hasHeader: true,
            ignorePreferences: true,
            pageSize: 1,
            service: job_event_stat_service_1.JobEventStatService,
            serviceFunction: 'tableList',
            query: {},
            collectionTitle: this.translationService.instant('Stats'),
            noResultsText: this.translationService.instant('a job'),
            customHeight: true,
            condensedLayout: true,
            automaticallyHidePagination: true,
        };
    }
    JobStatsComponent.prototype.ngOnInit = function () {
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
            this.loading = false;
        }
        else {
            this.loading = false;
        }
    };
    JobStatsComponent.prototype.ngOnChanges = function (changes) {
        var jobEventDateStart = new Date();
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        if (changes['jobEventDate']) {
            var jobEventDate = changes['jobEventDate'].currentValue;
            if (jobEventDate) {
                jobEventDateStart = new Date(this.jobEventDate);
                jobEventDateEnd = lodash_1.clone(jobEventDateStart);
            }
        }
        else if (this.jobEventDate) {
            jobEventDateStart = new Date(this.jobEventDate);
            jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        }
        jobEventDateStart.setHours(0, 0, 0, 0);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        this.tableConfig['query'] = {
            exclude_pending: 'True',
            cancelled: this.hideCancelled ? 'False' : undefined,
            shift1_start__gte: jobEventDateStart.toISOString(),
            shift1_start__lte: jobEventDateEnd.toISOString()
        };
        if (this.jobStatsTable) {
            this.jobStatsTable.config = this.tableConfig;
            this.jobStatsTable.getRecords();
        }
    };
    JobStatsComponent.prototype.filtersModified = function (appliedFilters) {
        // let filter = _find(this.appliedFilters, { key: '' });
        // if (!appliedFilters || !appliedFilters.length ) {
        //   this.filterChanges(_find(this.priceListsReq, { id: null }));
        // }
    };
    JobStatsComponent.prototype.refreshTable = function () {
        if (this.jobStatsTable) {
            var query = {};
            if (this.authenticationService.hasFavoriteTags()) {
                query['user_tags'] = 'True';
            }
            this.jobStatsTable.getRecords(__assign({}, this.tableConfig['query'], query));
        }
    };
    return JobStatsComponent;
}());
exports.JobStatsComponent = JobStatsComponent;
