"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var authentication_service_1 = require("../shared/authentication.service");
var PasswordResetComponent = /** @class */ (function () {
    function PasswordResetComponent(route, router, authenticationService) {
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.loading = false;
        this.errors = [];
    }
    PasswordResetComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.authenticationService.reset(this.token, this.uid, this.data.password)
            .subscribe(function (result) {
            _this.loading = false;
            _this.router.navigate(['/login']);
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    PasswordResetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.token = params['token'];
            _this.uid = params['uid'];
            if (_this.token === 'mock-token') {
                _this.loading = false;
                // Build this out
            }
            else if (_this.token && _this.uid) {
                _this.authenticationService.getProfile(_this.token, _this.uid)
                    .subscribe(function (result) {
                    _this.loading = false;
                    _this.data = {
                        firstName: result.firstName,
                        lastName: result.lastName,
                        email: result.email
                    };
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                });
            }
        });
    };
    return PasswordResetComponent;
}());
exports.PasswordResetComponent = PasswordResetComponent;
