"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".form-content[_ngcontent-%COMP%]{margin-top:30px}.md-dialog-content[_ngcontent-%COMP%]{height:auto;overflow-y:auto}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]{padding:0;position:relative;flex-grow:1;min-width:50%;margin-top:15px}form[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{font-size:14px;font-weight:600;line-height:1.36;color:#354052;margin-bottom:15px}form[_ngcontent-%COMP%]   .form-content.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .submit-button[_ngcontent-%COMP%]{min-width:100%;margin-top:15px}form[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]   textarea[_ngcontent-%COMP%]{padding:10px;height:60px;font-size:14px;font-weight:600;line-height:1.36;color:#354052}"];
exports.styles = styles;
