"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".subnav[_ngcontent-%COMP%]   .links[_ngcontent-%COMP%]{margin:0 0 15px;padding:0;border-bottom:1px solid #e6eaee;display:flex;flex:1 1 auto;flex-direction:row}"];
exports.styles = styles;
