
export class TicketFilter {
  id: string;
  name: string;
  loading: boolean;
  selected: boolean;
  classes: string;

  constructor() {}
}
