"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var complete_hour_pipe_1 = require("./complete-hour.pipe");
var ActiveHourPipe = /** @class */ (function () {
    function ActiveHourPipe() {
    }
    ActiveHourPipe.prototype.transform = function (record, hour, format) {
        if (format === void 0) { format = 'h a'; }
        var active = false;
        var currentTime = moment();
        var completePipe = new complete_hour_pipe_1.CompleteHourPipe();
        if (!record || !record.startTimeTimestamp) {
            return false;
        }
        if (completePipe.transform(record, hour)) {
            return false;
        }
        var startTime, endTime, timeAtHour, timeAtEndOfHour;
        startTime = moment(record.startTimeTimestamp).toDate();
        var _date = moment(startTime).format('YYYY-MM-DD');
        timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
        timeAtEndOfHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
        timeAtEndOfHour.add(3599, 'seconds');
        if (record.endTimeTimestamp) {
            endTime = moment(record.endTimeTimestamp).toDate();
        }
        if ((timeAtHour < currentTime && currentTime < timeAtEndOfHour) &&
            (endTime === undefined || !endTime)) {
            active = true;
        }
        return active;
    };
    return ActiveHourPipe;
}());
exports.ActiveHourPipe = ActiveHourPipe;
