import {
  Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef, Input,
  Output, EventEmitter
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { find as _find, filter } from 'lodash';
import { NguiMapComponent, HeatmapLayer, CustomMarker, DrawingManager } from '@ngui/map';
import {
  timer as observableTimer, Subject, Subscription, Observable
} from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

import { MapService } from '../map.service';
import { LocationUpdateService } from '../../jobs/locationUpdate.service';
import { DriverSerializer } from '../../drivers/driver.serializer';
import { AuthenticationService } from '../../shared/authentication.service';
import { mapOptions } from '../../shared/static-data';
import { JobEvent } from '../../job-events/job-event';
import { Preference } from '../../preferences/preference';
import { PreferenceSerializer } from '../../preferences/preference.serializer';
import { PreferenceService } from '../../preferences/preference.service';
import { Location } from '../../locations/location';
import { Device } from '../../shared/device';
import { EditLocationDetailsComponent } from '../../locations/edit-location-details/edit-location-details.component';
import { KeyCodes } from '../../shared/enums/key-codes.enum';
import { TruckSerializer } from '../../trucks/truck.serializer';

declare let google: any;
const polyline = require('google-polyline');

@Component({
  selector: 'job-map',
  templateUrl: './job-map.component.html',
  styleUrls: ['./job-map.component.scss']
})
export class JobMapComponent implements OnInit, OnDestroy {
  @Input() jobEvent: JobEvent;
  @Input() metaData: any;
  @Output() onMarkerDragEnd: EventEmitter<boolean> = new EventEmitter();
  device: Device = new Device();

  private locationUpdateTimer: Observable<number>;
  private statusChangeTimerSub: Subscription;
  private locationUpdateSub: Subscription;
  private mapReq: Subscription;
  private preferenceReq: Subscription;

  preference: Preference = new PreferenceSerializer().fromJson({
    blob: { mapStyle: 'ruckit-map-style' }
  });
  preferenceReqs: any = {};
  loadingMapPreferences = true;
  enableLocationEditMode = false;
  drawingMode = undefined;
  polygon;
  polygons: any = [];
  routePolyline: any[] = [];
  hours = [];
  customLocationMarkers: any[] = [];
  customUpdateMarkers: any[] = [];
  customPaverUpdateMarkers: any[] = [];
  heatmapPoints = [];
  mapCentered = false;
  markerType = 'driver-initials';
  markerStatus = 'each-segment';
  jobEventDate: Date;
  assignments = {
    items: [],
    errors: [],
    loading: false,
  };
  mapControls = {
    stats: true,
    traffic: false,
    heatmap: false,
    heatmapRadius: 20
  };
  search = '';
  searching = false;
  searchChanged: Subject<string> = new Subject<string>();
  searchResultsOpen = false;
  loading = true;
  errors = [];
  paverLocationUpdates = [];
  locationUpdates = [];
  locations = [];
  routes = [];
  parsedRoutes = [];
  coordinates = [];
  heatmap: google.maps.visualization.HeatmapLayer;
  map: google.maps.Map;
  mapOptions = mapOptions({
    zoom: 10,
    disableDefaultUI: true,
    fullscreenControl: true,
    streetViewControl: false,
    mapTypeControl: true
  });
  direction = {
    origin: '',
    destination: '',
    travelMode: 'DRIVING'
  };
  polylineOptions = {
    strokeColor: '#002649',
    strokeWeight: '4',
    strokeOpacity: 0.6
  };
  tripConfirmDialog: MatDialogRef<any>;
  driverInfoWindowModel;
  truckInfoWindowModel;
  selectedLocation: Location;
  selectedGeoFence: any;
  driverListOpen = false;
  jobOverviewOpen = false;
  assignmentDetails = {};
  isGeoFenceAvailable = false;
  drawingManagerInstance: any;
  @ViewChild(NguiMapComponent, { static: false }) nguiMapComponent: NguiMapComponent;
  @ViewChild('mapSearch', { static: false }) mapSearch: ElementRef;
  @ViewChild(HeatmapLayer, { static: false }) heatmapLayer: HeatmapLayer;
  @ViewChild(DrawingManager, { static: false }) drawingManager: DrawingManager;
  @ViewChild('editLocationDetails', { static: false }) editLocationDetails: EditLocationDetailsComponent;

  constructor(
    private cdr: ChangeDetectorRef,
    private mapService: MapService,
    private deviceDetectorService: DeviceDetectorService,
    private locationUpdateService: LocationUpdateService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };

    this.loading = true;
    if (this.jobEvent) {
      this.jobEventDate = new Date(this.jobEvent.shift1StartTimestamp);
      this.plotPolygons();
      this.mapOptions['center'] = this.jobEvent.mapCenterCoordinates;
      if (
        this.jobEvent.job && this.jobEvent.job.startLocationMappable &&
        this.jobEvent.job.endLocationMappable &&
        this.jobEvent.startCoordinates && this.jobEvent.endCoordinates
      ) {
        this.direction = {
          origin: this.jobEvent.startCoordinates.latitude + ', ' + this.jobEvent.startCoordinates.longitude,
          destination: this.jobEvent.endCoordinates.latitude + ', ' + this.jobEvent.endCoordinates.longitude,
          travelMode: 'DRIVING'
        };
      }
    }
    this.getPreferences('GeneralUserPreferences');

    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.mapCentered = false;
      this.search = search;
      this.getMapLocationUpdates(true);
    });
  }

  ngOnDestroy() {
    if (this.locationUpdateSub) {
      try { this.locationUpdateSub.unsubscribe(); } catch (e) { }
    }
    if (this.assignments && this.assignments.items.length) {
      try {
        this.assignments.items.forEach((assignment) => {
          if (assignment.durationTimer) {
            assignment.durationTimer.unsubscribe();
          }
        });
      } catch (e) { }
    }
    if (this.statusChangeTimerSub) {
      try { this.statusChangeTimerSub.unsubscribe(); } catch (e) { }
    }
    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }
    if (this.preferenceReq && typeof this.preferenceReq.unsubscribe === 'function') {
      this.preferenceReq.unsubscribe();
    }
  }

  getPreferences(preferenceKey = 'GeneralUserPreferences', params = null): void {
    if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
      try { this.preferenceReqs[preferenceKey].unsubscribe(); } catch (e) { }
    }
    let currentUser = this.authenticationService.user();
    if (!params) {
      params = {
        name: preferenceKey,
        type: 'user',
        profile: currentUser.id
      };
    }
    this.preferenceReqs[preferenceKey] = this.preferenceService.list(params).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preference = preferences[0];
        this.parsePreferences(preferenceKey);
      } else if (preferenceKey === 'GeneralUserPreferences') {
        this.loadingMapPreferences = false;
        this.parsePreferences(preferenceKey);
      }
    }, err => {
      this.errors = err;
    }, () => {
      this.loadingMapPreferences = false;
    });
  }

  /**
   * Examine the found preference for the provided key to determine if it has
   * the necessary data.
   *
   * Once the preferences data is handled, update the map
   */
  parsePreferences(preferenceKey = 'JobDetail'): void {
    if (preferenceKey === 'GeneralUserPreferences') {
      let mapStyle = 'ruckit-map-style';
      if (this.preference && this.preference.blob && this.preference.blob['mapStyle']) {
        mapStyle = this.preference.blob['mapStyle'];
      }
      this.markerType = 'driver-initials';
      if (this.preference && this.preference.blob && this.preference.blob['markerType']) {
        this.markerType = this.preference.blob['markerType'];
      }
      if (!this.markerStatus) { this.markerStatus = 'each-segment'; }
      if (this.preference && this.preference.blob && this.preference.blob['markerStatus']) {
        this.markerStatus = this.preference.blob['markerStatus'];
      }
      this.mapOptions = mapOptions({
        zoom: 10,
        disableDefaultUI: true,
        fullscreenControl: true,
        streetViewControl: false,
        mapTypeControl: true,
        center: this.jobEvent.mapCenterCoordinates
      }, {}, {
        mapStyle: mapStyle
      });

      this.triggerMapUpdate();
    }
  }

  onSearchboxKeyPress(event: KeyboardEvent) {
    if (event.code === KeyCodes.Enter) {
      this.searchChanged.next(this.search);
      this.assignmentDetails = {};
      event.preventDefault();
    }
  }

  clearSearch(): void {
    this.searching = false;
    this.search = '';
    this.getMapLocationUpdates();
    if (this.nguiMapComponent) {
      this.nguiMapComponent.closeInfoWindow('driver-info-window');
    }
  }

  plotPolygons(): void {
    this.polygons = [];
    if (this.jobEvent && this.jobEvent.job) {
      if (this.jobEvent.job.startLocation && this.jobEvent.job.startLocation.paths && this.jobEvent.job.startLocation.paths.length) {
        this.polygons.push({ paths: this.jobEvent.job.startLocation.paths });
      }
      if (this.jobEvent.job.endLocation && this.jobEvent.job.endLocation.paths && this.jobEvent.job.endLocation.paths.length) {
        this.polygons.push({ paths: this.jobEvent.job.endLocation.paths });
      }
    }
  }

  getMapLocationUpdates(openInfoWindow = false): void {
    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }

    this.mapReq = this.mapService.getLocationUpdates({
      driver__assignments__jobevent: this.jobEvent && this.jobEvent.id,
      ordering: 'jobs__name',
      search: this.search
    }).subscribe(locationUpdates => {
      this.resetMarkers();
      if (locationUpdates.locationUpdates) {
        this.locationUpdates = locationUpdates.locationUpdates.map(update => {
          if (update.driver) { update.driver = new DriverSerializer().fromJson(update.driver); }
          return update;
        });
      }
      if (locationUpdates.paverLocationUpdates) {
        this.paverLocationUpdates = locationUpdates.paverLocationUpdates.map(update => {
          if (update.truck) { update.truck = new TruckSerializer().fromJson(update.truck); }
          return update;
        });
      }
      if (!this.mapCentered) {
        this.centerMap();
        this.mapCentered = true;
      }
      if (
        openInfoWindow && this.search && this.search.length &&
        this.locationUpdates.length === 1 && window.innerWidth > 900
      ) {
        this.openDriverInfoWindow(0, this.locationUpdates[0]);
      } else if (!this.search.length || this.locationUpdates.length === 0) {
        if (this.nguiMapComponent) {
          this.nguiMapComponent.closeInfoWindow('driver-info-window');
        }
      }
    }, err => {
      this.errors = err;
    });
  }

  triggerMapUpdate(): void {
    this.getMapLocationUpdates();
    const jobDetailMapRefresh = this.authenticationService.getFeature('jobDetailMapRefresh') || 300000;
    this.locationUpdateTimer = observableTimer(jobDetailMapRefresh, jobDetailMapRefresh);
    this.locationUpdateSub = this.locationUpdateTimer.subscribe(t => {
      this.getMapLocationUpdates();
    });
  }

  updateHeatmap(): void {
    this.locationUpdateService.coordinates(this.map.getBounds(), {
      jobevent: this.jobEvent && this.jobEvent.id,
      page_size: 2500
    }).subscribe(coordinates => {
      this.coordinates = coordinates;
      this.heatmapPoints = coordinates && coordinates.map((coordinate) => {
        return { location: new google.maps.LatLng(coordinate.lon, coordinate.lat), weight: coordinate.weight || 1 };
      });
      if (this.heatmapPoints && this.heatmapPoints.length > 0) {
        this.heatmap.setData(this.heatmapPoints);
      }
      this.cdr.detectChanges();
    }, err => { console.error(err); });
  }

  onHeatmapInitialized(e = null): void {
    this.heatmap = e;
    this.coordinates = [];
    this.updateHeatmap();
  }

  onMapIdle(e = null): void {
    if (this.heatmap) { this.updateHeatmap(); }
  }

  initMap(event: any): void {
    if (event) {
      this.map = event;
      this.initDrawingManager();
    }
    if (this.jobEvent.job.routePolyline) {
      this.routePolyline = polyline.decode(this.jobEvent.job.routePolyline).map(path => {
        return new google.maps.LatLng(path[0], path[1]);
      });
    }
  }

  onMapLoaded(e = null): void {
    if (e) { this.map = e.target.map; }
  }

  centerMap(withPadding = true): void {
    let _centerOnStartLocation = true;
    if (this.map) {
      let bounds = new google.maps.LatLngBounds();
      let latLngList = [];

      let updates = this.locationUpdates.map((locationUpdate) => {
        let _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
        let _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
        if (_lat && _lng) {
          return new google.maps.LatLng(_lat, _lng);
        }
      });

      let paverUpdates = this.paverLocationUpdates.map((locationUpdate) => {
        let _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
        let _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
        if (_lat && _lng) {
          return new google.maps.LatLng(_lat, _lng);
        }
      });

      if (this.jobEvent && this.jobEvent.startCoordinates && this.jobEvent.endCoordinates) {
        latLngList = [
          this.jobEvent.job.startLocationMappable ?
            new google.maps.LatLng(this.jobEvent.startCoordinates.latitude, this.jobEvent.startCoordinates.longitude) : null,
          this.jobEvent.job.endLocationMappable ?
            new google.maps.LatLng(this.jobEvent.endCoordinates.latitude, this.jobEvent.endCoordinates.longitude) : null,
          ...updates,
          ...paverUpdates
        ];
      }

      for (let i = 0, LtLgLen = latLngList.length; i < LtLgLen; i++) {
        let list = latLngList[i];
        // if (list[0] && list[1]) {
        bounds.extend(list);
        // }
      }

      this.polygons = [];
      if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.startLocation && this.jobEvent.job.startLocation.geofence) {
        if (this.jobEvent.job.startLocation.paths && this.jobEvent.job.startLocation.paths.length) {
          this.polygons.push({ paths: this.jobEvent.job.startLocation.paths });
          this.jobEvent.job.startLocation.paths.forEach((path) => {
            if (path.lat && path.lng) {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        }
      }
      if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.endLocation && this.jobEvent.job.endLocation.geofence) {
        if (this.jobEvent.job.endLocation.paths && this.jobEvent.job.endLocation.paths.length) {
          this.polygons.push({ paths: this.jobEvent.job.endLocation.paths });
          this.jobEvent.job.endLocation.paths.forEach((path) => {
            if (path.lat && path.lng) {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        }
      }

      if (this.map.getZoom() > 17) { this.map.setZoom(17); }
      if (latLngList && latLngList.length || this.polygons && this.polygons.length) {
        _centerOnStartLocation = false;
      }

      if (withPadding) {
        const padding = { left: 300, right: 20 };
        this.map.fitBounds(bounds, <google.maps.Padding>padding);
      } else {
        this.map.fitBounds(bounds);
      }
    }
  }

  centerOnStartLocation(): void {
    if (this.jobEvent && this.jobEvent.job) {
      let fallbackCenter = new google.maps.LatLng(
        this.jobEvent.job.startCoordinates.latitude,
        this.jobEvent.job.startCoordinates.longitude
      );
      this.mapOptions = mapOptions({
        zoom: 10,
        center: fallbackCenter,
        disableDefaultUI: true,
        geoFallbackCenter: fallbackCenter
      });
    }
  }

  onCustomLocationMarkerInit(customMarker: CustomMarker): void {
    this.customLocationMarkers.push(customMarker);
  }

  onCustomUpdateMarkerInit(customMarker: CustomMarker): void {
    this.customUpdateMarkers.push(customMarker);
  }

  onCustomPaverUpdateMarkerInit(customMarker: CustomMarker): void {
    this.customPaverUpdateMarkers.push(customMarker);
  }

  openDriverInfoWindow(index: number, update): void {
    this.driverInfoWindowModel = update;
    if (update && update.driver) {
      let markers = filter(this.customUpdateMarkers, (m) => {
        return m.htmlEl.id === update.driver.id;
      });
      if (markers && markers.length) {
        this.nguiMapComponent.openInfoWindow('driver-info-window', markers[0]);
      }
    }
  }

  openTruckInfoWindow(index: number, update): void {
    this.truckInfoWindowModel = update;
    if (update && update.truck) {
      let markers = filter(this.customPaverUpdateMarkers, (m) => {
        return m.htmlEl.id === update.truck.id;
      });
      if (markers && markers.length) {
        this.nguiMapComponent.openInfoWindow('truck-info-window', markers[0]);
      }
    }
  }

  openLocationInfoWindow(location: Location, index: number): void {
    this.selectedLocation = location;
    let markers = filter(this.customLocationMarkers, (m) => {
      return m.htmlEl.id === location.id;
    });
    if (markers && markers.length) {
      this.nguiMapComponent.openInfoWindow('location-info-window', markers[0]);
    }
    this.isGeoFenceAvailable = !!this.selectedLocation.geofence;
  }

  toggleStats(): void {
    if (this.mapControls) {
      this.mapControls.stats = !this.mapControls.stats;
    }
    this.cdr.detectChanges();
  }

  toggleTraffic(): void {
    if (this.mapControls) {
      this.mapControls.traffic = !this.mapControls.traffic;
    }
    this.cdr.detectChanges();
  }

  toggleHeatmap(): void {
    if (this.mapControls) {
      this.mapControls.heatmap = !this.mapControls.heatmap;
    }
    this.cdr.detectChanges();
  }

  changeHeatmapRadius(e = null): void {
    if (e) {
      if (this.mapControls) {
        this.mapControls.heatmapRadius = e.target.value;
        this.heatmap.set('radius', e.target.value);
        // this.cdr.detectChanges();
      }
    }
  }

  openAssignmentDetails(update, index: number): void {
    if (window.innerWidth < 900) {
      let latLng;
      this.assignmentDetails = {};
      // if map marker triggers this method
      if (update && update.location && update.driver && update.driver.id) {
        // this.driverListOpen = true;
        this.assignmentDetails = _find(this.assignments.items, { driver: { id: update.driver.id } });
        if (update.location.latitude && update.location.longitude) {
          latLng = new google.maps.LatLng(
            parseFloat(update.location.coordinates[1]),
            parseFloat(update.location.coordinates[0])
          );
        }
        // else if a list item triggers this method
      } else if (update && update.shift && update.driver && update.driver.id) {
        this.assignmentDetails = update;
        let locationUpdate = _find(this.locationUpdates, { driver: { id: update.driver.id } });
        if (
          locationUpdate && locationUpdate.location &&
          locationUpdate.location.coordinates &&
          locationUpdate.location.coordinates.length === 2
        ) {
          latLng = new google.maps.LatLng(
            parseFloat(locationUpdate.location.coordinates[1]),
            parseFloat(locationUpdate.location.coordinates[0])
          );
        }
      }
      if (latLng && this.map && typeof this.map.setCenter === 'function') {
        this.map.setCenter(latLng);
        this.map.setZoom(17);
      }
    } else {
      this.openDriverInfoWindow(index, update);
    }
  }

  showSearch(): void {
    this.searching = true;
    setTimeout(() => {
      if (this.mapSearch) { this.mapSearch.nativeElement.focus(); }
    }, 300);
  }

  resetMarkers(): void {
    this.customUpdateMarkers = [];
    this.customPaverUpdateMarkers = [];
  }

  replaceLocation(location: Location): void {
    if (this.jobEvent && this.jobEvent.job) {
      if (this.jobEvent.job.startLocation && this.jobEvent.job.startLocation.id === location.id) {
        this.jobEvent.job.startLocation = location;
        this.jobEvent.startCoordinates = {
          longitude: location.longitude, latitude: location.latitude
        };
      }
      if (this.jobEvent.job.endLocation && this.jobEvent.job.endLocation.id === location.id) {
        this.jobEvent.job.endLocation = location;
        this.jobEvent.endCoordinates = {
          longitude: location.longitude, latitude: location.latitude
        };
      }
      this.direction = {
        origin: this.jobEvent.startCoordinates.latitude + ', ' + this.jobEvent.startCoordinates.longitude,
        destination: this.jobEvent.endCoordinates.latitude + ', ' + this.jobEvent.endCoordinates.longitude,
        travelMode: 'DRIVING'
      };
    }
  }

  placeChanged(location: Location): void {
    this.replaceLocation(location);
    this.centerMap(false);
  }

  setDrawingManagerOptions() {
    const options = {
      'drawingControl': this.enableLocationEditMode,
      ...this.drawingManagerInstance.drawingControlOptions
    };
    this.drawingManagerInstance.setOptions(options);
  }

  toggleLocationEditMode(location: Location): void {
    this.enableLocationEditMode = !this.enableLocationEditMode;
    if (this.drawingManager) {
      this.setDrawingManagerOptions();
    }
    if (!this.enableLocationEditMode) {
      if (location) { this.replaceLocation(location); }
      this.drawingMode = undefined;
      if (this.map) {
        this.map.data.forEach(feature => {
          this.deleteSelectedOverlay(this.map, feature, location);
        });
      }
      this.selectedLocation = null;
      if (this.polygon) {
        this.polygon.setMap(null);
        this.polygon = null;
      }
      this.centerMap();
    } else {
      this.polygons = [];
      this.nguiMapComponent.closeInfoWindow('location-info-window');
      const latLng = new google.maps.LatLng(
        parseFloat(location.latitude), parseFloat(location.longitude)
      );
      if (latLng && this.map) {
        this.map.panTo(latLng);
        this.map.setZoom(16);
      }

      if (!this.polygon) {
        this.polygon = new google.maps.Polygon({
          paths: this.selectedLocation && this.selectedLocation.paths || [],
          strokeColor: '#FF8C00',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF8C00',
          fillOpacity: 0.35,
          editable: true,
          draggable: true
        });
        this.polygon.setMap(this.map);
      }

      google.maps.event.addListener(this.polygon, 'mouseup', e => {
        this.drawingComplete(this.polygon);
      });

      google.maps.event.addListener(this.polygon, 'rightclick', e => {
        this.polygon.setMap(null);
        this.selectedLocation.geofence = null;
      });
    }
  }

  initDrawingManager(): void {
    if (this.polygon) { this.polygon.setMap(null); }
    if (this.drawingManager) {
      this.drawingManager['initialized$'].subscribe(dm => {
        this.drawingManagerInstance = dm;
        dm.setDrawingMode(null);
        this.setDrawingManagerOptions();
        google.maps.event.addListener(dm, 'overlaycomplete', event => {
          dm.map.data.setStyle({
            editable: true,
            draggable: true
          });
          if (event.type !== google.maps.drawing.OverlayType.MARKER) {
            this.drawingComplete(event.overlay);
            dm.setDrawingMode(null);
          }
        });
      });
    }
  }

  drawingComplete(overlay): void {
    let map = overlay.map;
    map.data.add(new google.maps.Data.Feature({
      geometry: new google.maps.Data.Polygon([overlay.getPath().getArray()])
    }));
    overlay.setMap(null); // Remove the original overlay from the map
    map.data.toGeoJson(json => this.selectedGeoFence = json.features);
    map.data.forEach(feature => {
      map.data.overrideStyle(feature, {
        strokeColor: '#FF8C00',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF8C00',
        fillOpacity: 0.35,
        editable: true,
        draggable: true
      });
    });
    this.isGeoFenceAvailable = true;
    map.data.addListener('click', event => {
      // this.setSelectedOverlay(map, event.feature);
    });

    map.data.addListener('rightclick', event => {
      this.deleteSelectedOverlay(map, event.feature, null);
    });

    map.data.addListener('setgeometry', event => {
      // this.setSelectedOverlay(map, event.feature);
      console.log('setgeometry');
      map.data.toGeoJson(json => this.selectedGeoFence = json.features);
    });
  }

  deleteSelectedOverlay(map, overlay = null, location: Location): void {
    this.isGeoFenceAvailable =  false;
    if (overlay) {
      map.data.remove(overlay);
      map.data.toGeoJson(json => this.selectedGeoFence = json.features);
      this.isGeoFenceAvailable = !!this.selectedGeoFence;
      if (location) {
        map.data.toGeoJson(json => location.geofence = json.features);
      }
    }
  }

  updatePaths(pathArray): void {
    this.selectedLocation.paths = pathArray.map((path) => {
      return { lat: path.lat(), lng: path.lng() };
    });
    if (this.selectedLocation.paths[0] === this.selectedLocation.paths.reverse[0]) {
      console.log('Valid GeoJSON');
    } else {
      console.log('Invalid GeoJSON!');
    }
  }

  markerDragEnd(event) {
    if (event.coords) {
      this.selectedLocation.latitude = event.coords.lat;
      this.selectedLocation.longitude = event.coords.lng;
    } else if (event.latLng) {
      this.selectedLocation.latitude = event.latLng.lat();
      this.selectedLocation.longitude = event.latLng.lng();
    }
    this.onMarkerDragEnd.emit(true);
    if (this.editLocationDetails) {
      this.editLocationDetails.reverseGeocode();
    }
  }

  onClearGeoFence() {
    if (this.map) {
      if (this.polygon) {
        this.polygon.setMap(null);
        this.polygon = null;
      }
      this.map.data.forEach((feature) => {
        this.map.data.remove(feature);
      });
      this.selectedLocation.geofence = null;
    }
    this.selectedGeoFence = null;
    this.isGeoFenceAvailable = false;
  }
}
