<div class="app-content notifications-report">
  <my-toolbar title="{{ 'Notifications Report' | translate }}"></my-toolbar>

  <control-reports-actionbar></control-reports-actionbar>

  <div class="container">
    <div class="chart" *ngIf="showChart">
      <div class="chart-container">
        <canvas
          baseChart
          [chartType]="barChartType"
          [labels]="barChartData.labels"
          [datasets]="barChartData.datasets"
          [options]="barChartOptions"
        ></canvas>
      </div>
    </div>
    
    <div class="actions-wrapper" #container>
      <fancy-table #notificationsReportTable
        [availableColumns]="availableColumns"
        [displayedColumns]="displayedColumns"
        [config]="tableConfig"
        [(search)]="search"
        (onDataLoaded)="getDataSource($event)"
      >
        <ng-template #columnTemplates let-row="row", let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'name'">
              <div class="data">{{ row.name || '&mdash;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'lastPeriod'">
              <div class="data">{{ row.lastPeriod }}</div>
            </div>
            <div class="value" *ngSwitchCase="'currentPeriod'">
              <div class="data">{{ row.currentPeriod }}</div>
            </div>

            <ng-container *ngSwitchCase="'percentageChange'">
              <div *ngIf="row.percentageChange || row.percentageChange === 0; else notApplicable"
                class="value percChange"
                [style.background]="row.ruleColor"
                [style.color]="row.textColor"
              >
                <div class="data">{{ row.percentageChange | percent: '1.2-2' }}</div>
              </div>
              <ng-template #notApplicable>
                <div class="value percChange">
                  <div class="data">N/A</div>
                </div>
              </ng-template>
            </ng-container>

            <div class="value chart-opt" *ngSwitchCase="'chart'">
              <mat-icon (click)="openChartDialog(row)">bar_chart</mat-icon>
            </div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div>
            <dropdown
              #framesDropdown
              [options]="frameDropdownData.frames"
              [selectedOption]="currentTimeFrame"
              [config]="frameDropdownData.config"
              (onSelect)="changeFrameSelection($event)"
            ></dropdown>
          </div>
          <div class="actions">
            <div class="search-container">
              <search [(searchTerm)]="search" ></search>
            </div>
            <button class="btn btn-primary" translate (click)="showChart = !showChart">
              {{ showChart ? 'Hide Chart' : 'Show Chart' }}
            </button>
            <button class="btn btn-default" translate (click)="openThresholdsDialog()">
              Thresholds Settings
            </button>
          </div>
        </div>
        <div applied-filter-content>
          <applied-filters [(search)]="search"></applied-filters>
        </div>
      </fancy-table>
    </div>
  </div>
</div>

