"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{position:relative;flex-grow:1}form[_ngcontent-%COMP%]   .field-group.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .field-group.half-width[_ngcontent-%COMP%]{padding-right:30px;min-width:25%;margin-left:20px}.form-content[_ngcontent-%COMP%]{flex:1 1 auto;padding-top:30px}.form-content[_ngcontent-%COMP%]   .msg[_ngcontent-%COMP%]   textarea[_ngcontent-%COMP%]{padding:15px;font-size:14px;font-weight:600;line-height:1.36;color:#354052}.form-content[_ngcontent-%COMP%]   .message[_ngcontent-%COMP%]{padding:20px 50px 30px 13px}.form-content[_ngcontent-%COMP%]   .message[_ngcontent-%COMP%]   .sending-info[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354052;display:flex;flex-direction:column;justify-content:space-between}.form-content[_ngcontent-%COMP%]   .message[_ngcontent-%COMP%]   .sending-info[_ngcontent-%COMP%]   .time[_ngcontent-%COMP%]{font-size:12px;font-weight:600;text-align:right;color:#7f8fa4}.form-content[_ngcontent-%COMP%]   .message[_ngcontent-%COMP%]   .msg[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-size:14px;color:#7f8fa4}"];
exports.styles = styles;
