"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var TokenValidationInterceptor = /** @class */ (function () {
    function TokenValidationInterceptor(router) {
        this.router = router;
    }
    TokenValidationInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(operators_1.catchError(function (requestError) {
            if (requestError.status === 401) {
                _this.router.navigate(['/login']);
            }
            return rxjs_1.throwError(requestError);
        }));
    };
    return TokenValidationInterceptor;
}());
exports.TokenValidationInterceptor = TokenValidationInterceptor;
