import { Resource } from '../shared/resource';
import { clone } from 'lodash';

export class TrackableType extends Resource {
  id: string;
  name: string;
  active: boolean;

  url(action): string {
    return '/trackabletypes/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
