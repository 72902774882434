import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { timer } from 'rxjs';


@Component({
  selector: 'notification',
  templateUrl: './notification.component.html'
})

export class NotificationComponent implements OnInit, OnDestroy {
  @Input() level = 'danger';
  @Input() autoClose = false;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  open = true;
  transition = '';
  private timerSubscription: Subscription;
  private timer: Observable<any>;

  constructor() {}

  ngOnInit() {
    if (this.autoClose) {
      this.timer = timer(5000); // 5000 millisecond means 5 seconds
      this.timerSubscription = this.timer.subscribe(() => {
        this.close();
      });
    }
  }

  ngOnDestroy() {
    if (this.timerSubscription && this.timerSubscription instanceof Subscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  close() {
    this.transition = 'fade';
    setTimeout(() => {
      this.open = false;
    }, 1000);
    setTimeout(() => {
      this.transition = 'fade slide';
    }, 500);
    if (this.onClose) {
      this.onClose.emit(true);
    }
  }

  reset() {
    this.transition = '';
    this.open = true;
  }
}
