"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
// angular dependencies
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var common_1 = require("@angular/common");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
// price related components
var price_service_1 = require("./price.service");
// import { PriceFiltersDialogComponent } from './price-filters-dialog.component';
var price_dialog_component_1 = require("./price-dialog.component");
// shared components and services
var api_service_1 = require("../shared/api.service");
var index_1 = require("../shared/dialogs/index");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var PricesComponent = /** @class */ (function () {
    function PricesComponent(route, router, location, priceService, translationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.location = location;
        this.priceService = priceService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'product', title: this.translationService.instant('Product'), sortable: true, sortBy: 'product' },
            { key: 'change-type', title: this.translationService.instant('Change Type'), sortable: false },
            { key: 'change-amount', title: this.translationService.instant('Change Amount'), sortable: false },
            { key: 'notes', title: this.translationService.instant('Notes'), sortable: false },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false },
        ];
        this.displayedColumns = [
            'select', 'product', 'change-type', 'change-amount', 'notes', 'action'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.priceTableConfig = {
            hasHeader: true,
            service: price_service_1.PriceService,
            preferenceKey: 'PricesComponent-PriceService',
            query: {},
            collectionTitle: this.translationService.instant('Price'),
            noResultsText: this.translationService.instant('a price'),
            newRecordModal: this.openPrice,
            sortBy: '',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
            ]
        };
        this.errors = [];
        this.loading = true;
        this.count = 0;
        this.selectedCount = 0;
        this.filters = [];
        this.type = 'all';
        this.allSelected = false;
        this.changeSearchEmitter = new core_1.EventEmitter();
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Remove'), action: 'remove', link: false }
        ];
        this.savePriceCallback = function (e) {
            _this.priceTable.getRecords();
        };
    }
    PricesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.loading = true;
            _this.type = params['type'];
        });
        this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
            key: 'price_list', title: this.translationService.instant('Name'),
            filterType: '', hidden: true, service: price_service_1.PriceService,
            values: [this.priceList.id], displayValues: [this.priceList.name]
        }));
        this.getPrices();
    };
    PricesComponent.prototype.getPrices = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.pricesReq) {
            this.pricesReq.unsubscribe();
        }
        this.priceService.list(__assign({ search: this.search, price_list: this.priceList['id'] }, query)).subscribe(function (prices) {
            _this.count = _this.priceService.count;
            _this.loading = false;
            _this.pricesReq = prices;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    PricesComponent.prototype.clickAction = function (event) {
        if (event) {
            this.openPrice(event[1]);
        }
    };
    PricesComponent.prototype.menuAction = function (action, price) {
        switch (action) {
            case 'edit':
                this.openPrice(price);
                break;
            case 'remove':
                this.removePrice(price);
                break;
        }
    };
    PricesComponent.prototype.removePrice = function (price) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Price?'),
            body: this.translationService.instant('This price will be deleted and cannot be recovered.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.priceService.remove(price).subscribe(function (res) {
                    _this.priceTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    PricesComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        params['type'] = params['type'] ? params['type'] : this.type;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    PricesComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            owner: 'owner_organization'
        };
        var falseyFilters = [];
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var value = filterRes[key];
            var displayValue;
            if (typeof (value) === 'boolean') {
                if (value) {
                    value = value.toString();
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    query[queryKeys[key]] = value;
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var displayKey = key;
            var hidden = false;
            var filter = {
                key: displayKey,
                value: displayValue || value,
                query: query,
                hidden: hidden
            };
            if (filter.value === 'False' || !filter.value) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.filters = lodash_1.difference(this.filters, falseyFilters);
        // this.getPrices();
    };
    PricesComponent.prototype.filtersModified = function (appliedFilters) {
    };
    PricesComponent.prototype.openPrice = function (price) {
        if (price === void 0) { price = null; }
        var dialog = this.dialog.open(price_dialog_component_1.PriceDialogComponent, {
            width: '500px'
        });
        dialog.componentInstance.priceList = this.priceList['id'];
        dialog.componentInstance.price = lodash_1.clone(price);
        dialog.componentInstance.callback = this.savePriceCallback;
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    PricesComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return PricesComponent;
}());
exports.PricesComponent = PricesComponent;
