import { UserNotification, UserNotificationKind } from './user-notification';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class UserNotificationSerializer {
  fromJson(json: any): UserNotification {
    json = camelcaseKeysDeep(json);
    const notification = new UserNotification();
    if (!json) { return notification; }

    notification.verb = json.verb;
    notification.description = json.description;
    notification.data = json.data;
    notification.target = json.target;
    notification.targetId = json.targetId;
    notification.actor = json.actor;
    notification.timestamp = json.timestamp;
    notification.unread = json.unread;

    const kind = UserNotificationKind;
    const kindJob = [kind.JobCreated, kind.JobDayChanged, kind.JobEventUpdated, kind.JobEventShared];
    const kindShare = [kind.JobEventShareAccepted, kind.JobEventShareDeclined];
    const kindConnection = [kind.NewConnectionInvitation, kind.NewConnectionAccepted];
    const kindAssignment = [kind.OwnDriversDispatched, kind.SharedDriversDispatched, kind.AugmentedFleetDriversDispatched,
                            kind.AssignmentAccepted, kind.AssignmentRejected, kind.AssignmentUpdated, kind.TruckTicketedWithoutAssignment,
                            kind.Dispatch, kind.QRCodeScanned, kind.Retake];

    if (kindJob.includes(notification.verb)) {
      notification.icon = 'icon-projects';
      notification.url = (notification.verb === kind.JobEventShared) ? '/jobs/daily' : '/jobs';
    } else if (kindConnection.includes(notification.verb)) {
      notification.icon = 'icon-team';
      notification.url = (notification.verb === kind.NewConnectionInvitation) ? '/connections/received' : '/connections';
    } else if (kindAssignment.includes(notification.verb)) {
      notification.url = '/jobs/' + notification.targetId;
      notification.icon = ([kind.QRCodeScanned, kind.Retake, kind.TruckTicketedWithoutAssignment].indexOf(notification.verb) > -1) ? 'icon-ticket' :
                          ([kind.AssignmentAccepted, kind.AssignmentRejected].indexOf(notification.verb) > -1) ? 'icon-dispatch' :
                          'icon-fleet';
    } else if (kindShare.includes(notification.verb)) {
      notification.icon = 'icon-projects';
      notification.url = '/jobs/daily';
    }

    return notification;
  }

  toJson(notification: UserNotification): any {
    let json = {
      verb: notification.verb,
      description: notification.description,
      data: notification.data,
      target: notification.target,
      targetId: notification.targetId,
      actor: notification.actor,
      timestamp: notification.timestamp,
      unread: notification.unread,
      icon: notification.icon
    };

    return decamelizeKeysDeep(json);
  }
}
