import { clone } from 'lodash';

import { Resource } from '../shared/resource';

export class CyberTrip extends Resource {
  id: string;
  carrier: string;
  carrierId: string;
  checkinOptions: Object;
  checkoutOptions: Object;
  completed: boolean;
  customer: string;
  customerId: string;
  date: string;
  distance: string;
  driver: string;
  driverId: string;
  driverUniqueBillingId: string;
  endTime: string;
  haulRate: number;
  haulTotal: number;
  haulType: string;
  haulWeightUnit: string;
  hourlyCost: number;
  invoiceRate: number;
  invoiceTotal: number;
  invoiceType: string;
  invoiceWeightUnit: string;
  jobDate: string;
  job: string;
  jobDisplayName: string;
  jobId: string;
  phaseCode: string;
  jobEventId: string;
  loadingArrivalDatetime: string;
  loadingCompleteDatetime: string;
  loadingLocationName: string;
  loadingTicket: string;
  loadingWeight: number;
  localizedEndTime: string;
  localizedEndDatetime: string;
  localizedEndDate: string;
  localizedJobDate: string;
  localizedLoadingArrivalDatetime: string;
  localizedLoadingCompleteDatetime: string;
  localizedStartTime: string;
  localizedStartDatetime: string;
  localizedStartDate: string;
  localizedUnloadingArrivalDatetime: string;
  localizedUnloadingCompleteDatetime: string;
  material: string;
  payableTo: string;
  payableToId: string;
  project: string;
  projectId: string;
  startTime: string;
  tripStatus: string;
  truck: string;
  truckId: string;
  truckType: string;
  unloadingArrivalDatetime: string;
  unloadingCompleteDatetime: string;
  unloadingTicket: string;
  unloadingWeight: number;

  filterOptions(options): any[] {
    options = clone(options);
    return options;
  }
}
