<div class="app-content pay-sheets">
  <my-toolbar title="{{ 'Pay Reports' | translate }}"></my-toolbar>
  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/pay-reports/all']" routerLinkActive="active" translate>My Reports</a>
        <a [routerLink]="['/pay-reports/received']" routerLinkActive="active" translate>Received</a>
      </div>
    </div>
  </action-bar>
  <div class="actions-wrapper">
    <fancy-table #paySheetTable
                 [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(query)]="query"
                 [(search)]="search"
                 (onClickAction)="openReport($event)"
                 [config]="paySheetTableConfig">
      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'date'">
            <div class="data">{{ formatReportDate(row.reportDate) | translate }}</div>
          </div>
          <div class="value" *ngSwitchCase="'approved'">
            <i class="icon-success" [ngClass]="{'active': row.isApproved}"></i>
          </div>
          <div class="value" *ngSwitchCase="'type'">{{ row.type | titlecase }}</div>
          <div class="value" *ngSwitchDefault>{{ value }}</div>
        </ng-container>
      </ng-template>
      <div header-content class="header-content">
        <div class="title">{{ (reportType | titlecase) + ' Reports' | translate }}</div>
        <ul display-controls class="segmented-control">
          <li *ngFor="let control of controls" class="item {{ control.classes }}"
              [ngClass]="{'only-child': controls.length === 1}">
            <input class="input" type="checkbox" [name]="control.name" [id]="control.name"
                  [checked]="control.selected" (change)="onSelectToggle(control, $event)">
            <label class="label" [for]="control.name" translate>{{ control.label }}</label>
          </li>
        </ul>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle #columnToggle
                     [(availableColumns)]="availableColumns"
                     [(displayedColumns)]="displayedColumns"></column-toggle>
      <a *ngIf="type !== 'received'" mat-icon-button color="accent" class="add-button" (click)="createReport(reportType)">
        <mat-icon>add_circle</mat-icon>
      </a>
    </div>
  </div>
</div>
