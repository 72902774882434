"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resizer_1 = require("../images/resizer");
exports.DRIVER_DUTY_OPTIONS = [
    { id: 'on-duty', name: 'On Duty' },
    { id: 'off-duty', name: 'Off Duty' }
];
var Driver = /** @class */ (function () {
    function Driver() {
        this.selected = false;
        this.jobEventShares = [];
        this.lastLocationUpdateLoading = false;
        this.geoTrips = [];
    }
    Object.defineProperty(Driver.prototype, "dispatchImage", {
        get: function () {
            if (this.image && this.imageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.imageKey, 50, 50);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Driver.prototype, "driverListImage", {
        get: function () {
            if (this.image && this.imageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.imageKey, 52, 52);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Driver.prototype, "jobDetailImage", {
        get: function () {
            if (this.image && this.imageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.imageKey, 30, 30);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Driver.prototype, "assignmentBubbleImage", {
        get: function () {
            if (this.image && this.imageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.imageKey, 22, 22);
            }
        },
        enumerable: true,
        configurable: true
    });
    Driver.prototype.isOnShift = function () {
        var value = false;
        if (!this.punchCard) {
            return value;
        }
        if (this.punchCard.startTimeTimestamp && !this.punchCard.endTimeTimestamp) {
            value = true;
        }
        return value;
    };
    Driver.prototype.isActive = function (trip, status) {
        if (status === void 0) { status = 'loading'; }
        var value = false;
        if (!trip || trip.tripStatus === 'unloading_complete') {
            return value;
        }
        switch (status) {
            case 'en-route-one':
                value = trip.tripStatus === 'enroute_loading';
                break;
            case 'loading':
                value = trip.tripStatus === 'loading';
                break;
            case 'en-route-two':
                value = (trip.tripStatus === 'enroute_unloading' ||
                    trip.tripStatus === 'loading_complete');
                break;
            case 'unloading':
                value = trip.tripStatus === 'unloading';
                break;
            case 'any':
                value = !!trip.tripStatus;
                break;
        }
        return value;
    };
    Driver.prototype.isComplete = function (trip, status) {
        if (status === void 0) { status = 'loading'; }
        var value = false;
        if (!trip || trip.tripStatus === 'unloading_complete') {
            return value;
        }
        switch (status) {
            case 'en-route-one':
                value = (trip.tripStatus === 'loading' ||
                    trip.tripStatus === 'loading_complete' ||
                    trip.tripStatus === 'enroute_unloading' ||
                    trip.tripStatus === 'unloading');
                break;
            case 'loading':
                value = (trip.tripStatus === 'loading_complete' ||
                    trip.tripStatus === 'enroute_unloading' ||
                    trip.tripStatus === 'unloading');
                break;
            case 'en-route-two':
                value = trip.tripStatus === 'unloading';
                break;
            case 'unloading':
                value = !!trip.unloadingCompleteTime;
                break;
        }
        return value;
    };
    Object.defineProperty(Driver.prototype, "organizationName", {
        get: function () {
            return this.carrier && this.carrier.name || this.carrierName;
        },
        enumerable: true,
        configurable: true
    });
    Driver.prototype.otherConflicts = function (jobEvent) {
        var values = [];
        if (jobEvent && jobEvent.id && this.conflicts && this.conflicts.length) {
            values = this.conflicts.map(function (conflict) {
                if (conflict.jobevent !== jobEvent.id) {
                    return conflict;
                }
            });
        }
        return values.filter(Boolean);
    };
    Driver.prototype.url = function (action) {
        return '/drivers/' + this.id + '/' + action;
    };
    Driver.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        return options;
    };
    return Driver;
}());
exports.Driver = Driver;
