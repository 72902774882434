"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var surcharge_template_1 = require("./surcharge-template");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var SurchargeTemplateSerializer = /** @class */ (function () {
    function SurchargeTemplateSerializer() {
    }
    SurchargeTemplateSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var surchargeTemplate = new surcharge_template_1.SurchargeTemplate();
        if (!json) {
            return surchargeTemplate;
        }
        surchargeTemplate.id = json.id;
        surchargeTemplate.title = json.title;
        surchargeTemplate.createdAt = json.createdAt;
        surchargeTemplate.description = json.description;
        surchargeTemplate.amount = json.amount;
        if (parseFloat(json.amount) >= 0) {
            surchargeTemplate.positive = true;
        }
        if (json.kind) {
            surchargeTemplate.kind = json.kind;
            if (json.kind === 'invoice') {
                surchargeTemplate.kindTitle = 'Billing';
            }
            else if (json.kind === 'scaleticket') {
                surchargeTemplate.kindTitle = 'Scale Ticket';
            }
        }
        return surchargeTemplate;
    };
    SurchargeTemplateSerializer.prototype.toJson = function (surchargeTemplate) {
        var json = {
            id: surchargeTemplate.id,
            title: surchargeTemplate.title,
            createdAt: surchargeTemplate.createdAt,
            description: surchargeTemplate.description,
            amount: surchargeTemplate.amount,
            kind: surchargeTemplate.kind
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return SurchargeTemplateSerializer;
}());
exports.SurchargeTemplateSerializer = SurchargeTemplateSerializer;
