<div class="app-content">
  <my-toolbar
    *ngIf="view && accountId" icon="icon-back"
    [iconLink]="'/' + accountType + '/' + accountId + '/edit'"
    title="{{
      (account && account.organization && account.organization.name ? account.organization.name + ': ' : 'Account ') + view.title | titlecase | translate
    }}"
    (updatedMarkets)="updatedMarkets()"
  ></my-toolbar>
  <my-toolbar *ngIf="view && !accountId" title="{{ 'Leased ' + view.title | titlecase | translate }}" (updatedMarkets)="updatedMarkets()"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <div class="subnav custom-subnav">
        <div class="links">
          <ng-container *ngIf="accountId; else leasedLinks">
            <a [routerLink]="['/', accountType, accountId, 'edit']" routerLinkActive="active" class='account-info' translate>Account Info</a>
            <a [routerLink]="['/', accountType, 'trucks', accountId]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class='trucks' translate>Account Trucks</a>
            <a [routerLink]="['/', accountType, 'drivers', accountId]" routerLinkActive="active" [class.active]="isLinkActive(['/', accountType, 'drivers', accountId])" class='drivers' translate>Account Drivers</a>
          </ng-container>
          <ng-template #leasedLinks>
            <a [routerLink]="['/', accountType]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:true}" class='account-info' translate>Accounts</a>
            <a [routerLink]="['/', accountType, 'trucks']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class='trucks' translate>Leased Trucks</a>
            <a [routerLink]="['/', accountType, 'drivers']" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class='drivers' translate>Leased Drivers</a>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="right-actions"></div>
  </action-bar>

  <ng-container *ngIf="view && view.stub === 'drivers'; else truckView">
    <drivers
      #drivers 
      customClasses="account-drivers" 
      [query]="query"
      [customHeight]="true" 
      [carrierId]="carrierId" 
      [displayedColumns]="displayedColumns" 
      (viewArchive)="viewArchive($event)">
    </drivers>
  </ng-container>
  <ng-template #truckView>
    <trucks 
      #trucks 
      class="with-action-bar"
      customClasses="account-trucks"
      [query]="query" 
      [customHeight]="true" 
      [carrierId]="carrierId" 
      [displayedColumns]="displayedColumns" 
      (viewArchive)="viewArchive($event)">
    </trucks>
  </ng-template>
</div>
