import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { combineLatest as observableCombineLatest, Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// libraries
import { difference, get, find as _find } from 'lodash';
import moment = require('moment');

// angular material
import { MatDialog } from '@angular/material';

// components
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { ExportDialogComponent, ExportDialogData, FieldOption } from '../shared/export-dialog/export-dialog.component';
import { CyberTripFiltersDialogComponent } from './cyber-trip-filters-dialog.component';

// types
import { FilterOption } from '../shared/filters-panel/filter-option';
import { CyberTrip } from './cyber-trip';
import { DriverContextEvent } from '../drivers/driver-context-menu/interfaces/driver-context-event';

// services
import { CyberTripService } from './cyber-trip.service';

// utils
import { AppUtilities } from '../shared/app-utilities';

@Component({
  selector: 'ruckit-cyber-trips',
  templateUrl: './cyber-trips.component.html',
  styleUrls: ['./cyber-trips.component.scss']
})
export class CyberTripsComponent implements OnInit, OnDestroy {
  availableColumns = [
    { key: 'select' },
    { key: 'job-date', title: this.translationService.instant('Job Date'), sortable: true, sortBy: 'job_date' },
    { key: 'job-title', title: this.translationService.instant('Job'), sortable: true, sortBy: 'jobevent__job__name' },
    { key: 'phase-code', title: this.translationService.instant('Phase Code'), sortable: true, sortBy: 'jobevent__job__phase_code' },
    { key: 'start-time', title: this.translationService.instant('Start Time'), sortable: true, sortBy: 'jobevent__shift1_start' },
    { key: 'end-time', title: this.translationService.instant('End Time'), sortable: true, sortBy: 'jobevent__shift1_end' },
    { key: 'trip-duration', title: this.translationService.instant('Trip Duration'), sortable: true, sortBy: 'duration' },
    { key: 'loading-time', title: this.translationService.instant('Loading Time'), sortable: true, sortBy: 'duration' },
    { key: 'transit-time', title: this.translationService.instant('Transit Time'), sortable: true, sortBy: 'travel_time' },
    { key: 'unloading-time', title: this.translationService.instant('Unloading Time'), sortable: true, sortBy: 'travel_time' },
    { key: 'load-ticket-number', title: this.translationService.instant('Load Ticket #'),
      sortable: true, sortBy: 'ticket_number_ordering' },
    { key: 'load-ticket-weight', title: this.translationService.instant('Load Wt'), sortable: true, sortBy: 'weight_ordering' },
    { key: 'unload-ticket-number', title: this.translationService.instant('Unload Ticket #'), sortable: true, sortBy: 'ticket_number_ordering' },
    { key: 'unload-ticket-weight', title: this.translationService.instant('Unload Wt'), sortable: true, sortBy: 'weight_ordering' },
    { key: 'carrier', title: this.translationService.instant('Carrier'), sortable: true, sortBy: 'driver__carrier__name' },
    { key: 'driver', title: this.translationService.instant('Driver'), sortable: true, sortBy: 'driver__profile__first_name' },
    { key: 'truck', title: this.translationService.instant('Truck'), sortable: true, sortBy: 'truck__name' },
    { key: 'duration', title: this.translationService.instant('Duration') },
    { key: 'payable', title: this.translationService.instant('Payable To'), sortable: true, sortBy: 'jobevent__owner_organization__name' },
    { key: 'customer', title: this.translationService.instant('Customer'), sortable: true, sortBy: 'jobevent__customer_organization__name' },
    { key: 'invoice-rate', title: this.translationService.instant('Invoice Rate') },
    { key: 'invoice-total', title: this.translationService.instant('Invoice Total') },
    { key: 'haul-rate', title: this.translationService.instant('Haul Rate') },
    { key: 'haul-total', title: this.translationService.instant('Haul Total') },
    { key: 'hourly-cost', title: this.translationService.instant('Hourly Cost') },
  ];
  displayedColumns = [
    'select', 'job-date', 'job-title', 'start-time', 'end-time', 'trip-duration', 'loading-time',
    'transit-time', 'unloading-time', 'load-ticket-number', 'load-ticket-weight', 'unload-ticket-number',
    'unload-ticket-weight', 'carrier', 'driver', 'truck', 'duration', 'payable', 'customer',
    'invoice-rate', 'invoice-total', 'haul-rate', 'haul-total', 'hourly-cost', 'phase-code'
  ];
  appliedFilters: FilterOption[] = [];
  search = '';
  tableConfig = {
    hasHeader: true,
    pageSize: 25,
    service: CyberTripService,
    filterQuery: false,
    preferenceKey: 'geo-trips',
    preferencesEnabled: true,
    query: {},
    collectionTitle: this.translationService.instant('GeoTrips'),
    hasNoResultsAction: false,
    sortBy: 'created_at',
    sortDirection: 'asc',
    automaticallyHidePagination: false,
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false }
    ]
  };
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Export'), action: 'export', link: false }
  ];
  @ViewChild('fancyTable', { static: false }) fancyTable: FancyTableComponent;

  datePipe = new DatePipe('en-US');
  loading = true;
  errors = [];
  allSelected = false;
  selectedTrips: CyberTrip[] = [];
  excludeTrips: CyberTrip[] = [];
  sortParameter: string;
  filtersDialog;
  // context menu
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  exportCallback = () => {};

  constructor(
    private route: ActivatedRoute,
    private cyberTripService: CyberTripService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        this.tableConfig.sortBy = result.qparams['sortBy'] || '';
        this.tableConfig.sortDirection = result.qparams['sortAsc'] || '';
      })
    );

    if (this.appliedFilters.length === 0) {
      let startDate = moment();
      startDate = startDate.subtract(1, 'month');
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let defaultFilter = new FilterOption({
        filterType: 'date',
        default: true,
        key: 'startDate',
        title: 'Start Date',
        displayValues: startDate.format('MM/DD/YYYY') || null,
        values: startDate.toISOString(),
        query: {
          jobevent__shift1_start__gte: startDate.toISOString()
        }
      });
      this.appliedFilters.push(defaultFilter);
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  openFilters(): void {
    const dialog = this.dialog.open(CyberTripFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);

    let startDate = get(_find(this.appliedFilters, { key: 'startDate' }), 'values');
    if (startDate) {
      dialog.componentInstance.model.startDate = new Date(startDate);
    }
    let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
    if (endDate) {
      dialog.componentInstance.model.endDate = new Date(endDate);
    }
    dialog.componentInstance.model.job = get(_find(this.appliedFilters, { key: 'job' }), 'values');
    dialog.componentInstance.model.project = get(_find(this.appliedFilters, { key: 'project' }), 'values');
    dialog.componentInstance.model.customer = get(_find(this.appliedFilters, { key: 'customer' }), 'values');
    dialog.componentInstance.model.driver = get(_find(this.appliedFilters, { key: 'driver' }), 'values');
    dialog.componentInstance.model.truck = get(_find(this.appliedFilters, { key: 'truck' }), 'values');
    dialog.componentInstance.model.payableTo = get(_find(this.appliedFilters, { key: 'payableTo' }), 'values');
    dialog.componentInstance.model.carrier = get(_find(this.appliedFilters, { key: 'carrier' }), 'values');

    dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
      acc[filter.key] = filter.values;
      return acc;
    }, {}));
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      customer: 'jobevent__customer_organization__id',
      project: 'jobevent__job__project',
      payableTo: 'jobevent__owner_organization__id',
      job: 'jobevent__job',
      driver: 'driver',
      truck: 'truck',
      startDate: 'jobevent__shift1_start__gte',
      endDate: 'jobevent__shift1_start__lte',
      edited: 'edited',
      incomplete: 'completed',
      retake: 'retake_status',
      carrier: 'driver__carrier',
      duplicates: 'only_dupes',
      uninvoiced: 'uninvoiced'
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (typeof (values) === 'boolean') {
        if (key === 'incomplete' && values) {
          displayValues = values.toString();
          displayValues = displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
          let filterValue = (!values).toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (key === 'retake' && values) {
          values = 'requested';
          query[queryKeys[key]] = values;
        } else if (values) {
          values = values.toString();
          values = values.charAt(0).toUpperCase() + values.slice(1);
          query[queryKeys[key]] = values;
        }
        displayValues = values;
      }  else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
        if ((typeof values) === 'string') {
          query[queryKeys[key]] = values;
        } else {
          query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
      }
      let filter = new FilterOption({
        filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (filter.values === 'False' || !filter.values) { falseyFilters.push(filter); }
      return filter;
    });

    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedTrips();
        break;
    }
  }

  exportSelectedTrips() {
    let {params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters, 
      this.selectedTrips, 
      this.excludeTrips, 
      this.allSelected, 
      this.search
    );
  
    this.cyberTripService.getExportFields().subscribe((fields: FieldOption[]) => {
      this.dialog.open(ExportDialogComponent, {
        width: 'auto',
        data: <ExportDialogData>{
          type: 'trips',
          buttonText: this.translationService.instant('Export Data to CSV'),
          callback: this.exportCallback,
          fields,
          params,
          scope,
          service: this.cyberTripService
        }
      });
    }, (err) => { this.errors = err; });
  }

  selector(event: { allSelected: boolean, selection: SelectionModel<CyberTrip>, exclusion: SelectionModel<CyberTrip> }) {
    this.allSelected = event.allSelected;
    if (!this.allSelected) {
      this.selectedTrips = event.selection.selected;
      this.excludeTrips = [];
    } else {
      this.selectedTrips = [];
      this.excludeTrips = event.exclusion.selected;
    }
  }

  getDuration(startDatetime: string, endDatetime: string): string {
    if (startDatetime && endDatetime) {
      let minuteDiff = moment(endDatetime).diff(moment(startDatetime), 'minutes');
      return Math.floor(minuteDiff / 60) + 'h ' + (minuteDiff % 60) + 'm';
    } else {
      return 'N/A';
    }
  }

  prettyDate(dateString: string): string {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime()) ? this.datePipe.transform(date, 'MM/dd/yyyy') : '';
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }

  /**
   * Currently used to override the displayed columns if they are different from the preferences
   * Update/Remove if the column toggle is added
   * @param data 
   */
  dataLoaded(data: any): void {
    if (this.displayedColumns.length !== this.fancyTable.displayedColumns.length) {
      this.fancyTable.displayedColumns = this.displayedColumns;
      this.fancyTable.savePreferences();
    }
    if (!this.displayedColumns.every(el => this.fancyTable.displayedColumns.includes(el))) {
      this.fancyTable.displayedColumns = this.displayedColumns;
      this.fancyTable.savePreferences();
    }
  }
}
