"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_ngcontent-%COMP%]:focus{outline:0;border:0}button[_ngcontent-%COMP%]{border:0;background:0 0}button[_ngcontent-%COMP%]   .action-menu-icon[_ngcontent-%COMP%]{transform:rotate(90deg) scale(1.5)}button.titled-button[_ngcontent-%COMP%]{width:auto;margin-left:30px;padding:0 20px;border-radius:4px;background:linear-gradient(to top,#f2f4f7,#fff);border:1px solid #e6e9ec;line-height:34px;height:36px}button.titled-button[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{font-size:14px;color:#354052;font-weight:600;vertical-align:top}button.titled-button[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]:after{font-family:icomoon!important;speak:none;font-style:normal;font-variant:normal;text-transform:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:'\\e904';color:#354052;margin-top:-4px;margin-left:0;margin-right:15px;line-height:14px;width:0;font-size:26px;display:inline-block;vertical-align:middle;font-weight:900}button.titled-button[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{vertical-align:middle}  .mat-menu-panel .mat-menu-content:not(:empty) a{cursor:pointer}"];
exports.styles = styles;
