"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ticket_1 = require("./ticket");
var organization_1 = require("../organizations/organization");
var payment_type_1 = require("../payment-types/payment-type");
var scale_1 = require("../scales/scale");
var driver_serializer_1 = require("./../drivers/driver.serializer");
var product_serializer_1 = require("../products/product.serializer");
var location_serializer_1 = require("../locations/location.serializer");
var surcharge_serializer_1 = require("../surcharges/surcharge.serializer");
var truck_serializer_1 = require("../trucks/truck.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TicketSerializer = /** @class */ (function () {
    function TicketSerializer() {
    }
    TicketSerializer.prototype.fromJson = function (ticketInfo) {
        ticketInfo = camelcaseKeysDeep(ticketInfo);
        var ticket = new ticket_1.Ticket();
        if (!ticketInfo) {
            return ticket;
        }
        ticketInfo = camelcaseKeysDeep(ticketInfo);
        ticket.id = ticketInfo.id;
        ticket.createdAt = ticketInfo.createdAt;
        ticket.deliveryTime = ticketInfo.deliveryTime;
        if (ticketInfo.status === 'on-hold') {
            ticket.statusLabel = 'label-pending';
        }
        else if (ticketInfo.status === 'in-progress') {
            ticket.statusLabel = 'label-upcoming';
        }
        else if (ticketInfo.status === 'void') {
            ticket.statusLabel = 'label-void';
        }
        if (ticketInfo.onHold) {
            ticket.status = 'on-hold';
            ticket.statusLabel = 'label-pending';
        }
        else {
            ticket.status = 'in-progress';
            ticket.statusLabel = 'label-upcoming';
        }
        ticket.void = ticketInfo.void;
        ticket.voidReason = ticketInfo.voidReason;
        if (ticket.void) {
            ticket.status = 'void';
            ticket.statusLabel = 'label-void';
        }
        ticket.number = ticketInfo.number;
        if (ticketInfo.customerOrganization && typeof (ticketInfo.customerOrganization) === 'object') {
            ticket.customerOrganization = new organization_1.Organization(ticketInfo.customerOrganization);
        }
        else {
            ticket.customerOrganization = new organization_1.Organization({ id: ticketInfo.customerOrganization });
        }
        if (ticketInfo.sellerOrganization && typeof (ticketInfo.sellerOrganization) === 'object') {
            ticket.sellerOrganization = new organization_1.Organization(ticketInfo.sellerOrganization);
        }
        else {
            ticket.sellerOrganization = new organization_1.Organization({ id: ticketInfo.sellerOrganization });
        }
        if (ticketInfo.carrierOrganization && typeof (ticketInfo.carrierOrganization) === 'object') {
            ticket.carrierOrganization = new organization_1.Organization(ticketInfo.carrierOrganization);
        }
        else {
            ticket.carrierOrganization = new organization_1.Organization({ id: ticketInfo.carrierOrganization });
        }
        if (ticketInfo.driver && typeof (ticketInfo.driver) === 'object') {
            ticket.driver = new driver_serializer_1.DriverSerializer().fromJson(ticketInfo.driver);
        }
        else {
            ticket.driver = new driver_serializer_1.DriverSerializer().fromJson({ id: ticketInfo.driver });
        }
        ticket.driverName = ticketInfo.driverName;
        if (ticketInfo.truck && typeof (ticketInfo.truck) === 'object') {
            ticket.truck = new truck_serializer_1.TruckSerializer().fromJson(ticketInfo.truck);
        }
        else {
            ticket.truck = new truck_serializer_1.TruckSerializer().fromJson({ id: ticketInfo.truck });
        }
        if (ticketInfo.location && typeof (ticketInfo.location) === 'object') {
            ticket.location = (new location_serializer_1.LocationSerializer).fromJson(ticketInfo.location);
        }
        else {
            ticket.location = (new location_serializer_1.LocationSerializer).fromJson({ id: ticketInfo.location });
        }
        if (ticketInfo.product && typeof (ticketInfo.product) === 'object') {
            ticket.product = (new product_serializer_1.ProductSerializer().fromJson(ticketInfo.product));
        }
        else {
            ticket.product = (new product_serializer_1.ProductSerializer().fromJson({ id: ticketInfo.product }));
        }
        ticket.completedTime = ticketInfo.completedTime;
        ticket.notes = ticketInfo.notes;
        ticket.tareWeight = ticketInfo.tareWeight;
        ticket.tareWeightTime = ticketInfo.tareWeightTime;
        ticket.tareWeightUnit = ticketInfo.tareWeightUnit;
        if (ticketInfo.tareWeightScale && typeof (ticketInfo.tareWeightScale) === 'object') {
            ticket.tareWeightScale = new scale_1.Scale(ticketInfo.tareWeightScale);
        }
        else if (ticketInfo.tareWeightScale) {
            ticket.tareWeightScale = new scale_1.Scale({ id: ticketInfo.tareWeightScale });
        }
        ticket.grossWeight = ticketInfo.grossWeight;
        ticket.grossWeightTime = ticketInfo.grossWeightTime;
        ticket.grossWeightUnit = ticketInfo.grossWeightUnit;
        if (ticketInfo.grossWeightScale && typeof (ticketInfo.grossWeightScale) === 'object') {
            ticket.grossWeightScale = new scale_1.Scale(ticketInfo.grossWeightScale);
        }
        else if (ticket.grossWeightScale) {
            ticket.grossWeightScale = new scale_1.Scale({ id: ticketInfo.grossWeightScale });
        }
        ticket.net = ticketInfo.net || 0;
        ticket.netPrice = ticketInfo.netPrice || 0;
        ticket.netWeightUnit = ticketInfo.tareWeightUnit;
        ticket.totalPrice = ticketInfo.totalPrice || 0;
        ticket.unitPrice = ticketInfo.unitPrice || 0;
        if (ticketInfo.paymentType && typeof (ticketInfo.paymentType) === 'object') {
            ticket.paymentType = new payment_type_1.PaymentType(ticketInfo.paymentType);
        }
        else {
            ticket.paymentType = new payment_type_1.PaymentType({
                id: ticketInfo.paymentType, name: ticketInfo.paymentType
            });
        }
        ticket.purchaseOrder = ticketInfo.purchaseOrder;
        ticket.discountPercentage = ticketInfo.discountPercentage || 0;
        ticket.correctionWeight = ticketInfo.correctionWeight || 0;
        ticket.surcharges = ticketInfo.surcharges && ticketInfo.surcharges.map(function (surcharge) {
            if (surcharge && typeof (surcharge) === 'object') {
                return new surcharge_serializer_1.SurchargeSerializer().fromJson(surcharge);
            }
            else {
                return new surcharge_serializer_1.SurchargeSerializer().fromJson({ id: surcharge });
            }
        });
        ticket.surchargeTotal = ticketInfo.surchargeTotal || 0;
        ticket.taxesTotal = ticketInfo.taxesTotal || 0;
        return ticket;
    };
    TicketSerializer.prototype.toJson = function (ticket) {
        var json = {
            id: ticket.id,
            createdAt: ticket.createdAt,
            // recordDeleted: boolean;
            statusLabel: ticket.statusLabel,
            deliveryTime: ticket.deliveryTime,
            status: ticket.status,
            number: ticket.number,
            customerOrganization: ticket.customerOrganization && ticket.customerOrganization.id,
            sellerOrganization: ticket.sellerOrganization && ticket.sellerOrganization.id,
            carrierOrganization: ticket.carrierOrganization && ticket.carrierOrganization.id,
            driver: ticket.driver,
            driverName: ticket.driverName,
            truck: ticket.truck,
            location: ticket.location,
            product: ticket.product,
            completedTime: ticket.completedTime,
            notes: ticket.notes,
            tareWeight: ticket.tareWeight,
            tareWeightTime: ticket.tareWeightTime,
            tareWeightUnit: ticket.tareWeightUnit,
            tareWeightScale: ticket.tareWeightScale,
            grossWeight: ticket.grossWeight,
            grossWeightTime: ticket.grossWeightTime,
            grossWeightUnit: ticket.grossWeightUnit,
            grossWeightScale: ticket.grossWeightScale,
            net: ticket.net,
            netPrice: ticket.netPrice,
            netWeightUnit: ticket.netWeightUnit,
            totalPrice: ticket.totalPrice,
            unitPrice: ticket.unitPrice,
            paymentType: ticket.paymentType && ticket.paymentType.id,
            purchaseOrder: ticket.purchaseOrder,
            discountPercentage: ticket.discountPercentage,
            correctionWeight: ticket.correctionWeight,
            surcharges: ticket.surcharges,
            surchargeTotal: ticket.surchargeTotal,
            taxesTotal: ticket.taxesTotal,
            void: ticket.void,
            voidReason: ticket.voidReason,
            selected: ticket.selected
        };
        return decamelizeKeysDeep(json);
    };
    return TicketSerializer;
}());
exports.TicketSerializer = TicketSerializer;
