"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var esb_service_1 = require("../../esb/esb.service");
var EsbUploaderComponent = /** @class */ (function () {
    function EsbUploaderComponent(esbService) {
        this.esbService = esbService;
        this.loading = false;
        this.errors = [];
        this.orders = [];
    }
    EsbUploaderComponent.prototype.onResize = function (event) { };
    EsbUploaderComponent.prototype.fileChange = function (event) {
        var _this = this;
        this.loading = true;
        var file = event.target.files[0];
        this.esbService.import('order', file).subscribe(function (res) {
            _this.orders = res['orders'];
            _this.errors = res['errors'];
            _this.loading = false;
        }, function (err) {
            console.error('err', err);
            this.loading = false;
        });
    };
    EsbUploaderComponent.prototype.reload = function () {
        this.errors = [];
        this.orders = [];
    };
    return EsbUploaderComponent;
}());
exports.EsbUploaderComponent = EsbUploaderComponent;
