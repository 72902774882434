"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var NoResultsComponent = /** @class */ (function () {
    function NoResultsComponent(translateService) {
        this.translateService = translateService;
        this.itemName = this.translateService.instant('Items');
        this.iconClass = 'icon-no-results';
        this.styles = {
            height: 'calc(100VH - 148px)'
        };
        this.addText = this.translateService.instant('an item');
        this.search = '';
        this.filters = [];
        this.results = 0;
        this.matTable = false;
        this.bordered = false;
        this.loading = false;
        this.hasHeader = false;
        this.hasAction = true;
        this.hasAddText = true;
        this.customText = '';
        this.clearSearch = new core_1.EventEmitter();
        this.addItem = new core_1.EventEmitter();
        this.searching = false;
    }
    NoResultsComponent.prototype.ngOnInit = function () {
        if (this.search && this.search.length > 0) {
            this.searching = true;
        }
        else if (this.filters && this.filters.length > 0) {
            this.searching = true;
        }
    };
    NoResultsComponent.prototype.clear = function () {
        this.clearSearch.emit(true);
    };
    NoResultsComponent.prototype.add = function () {
        this.addItem.emit(true);
    };
    return NoResultsComponent;
}());
exports.NoResultsComponent = NoResultsComponent;
