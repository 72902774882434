import { Driver } from '../drivers/driver';
import { Job } from '../jobs/job';
import { Resource } from '../shared/resource';

export class DriverJobReport extends Resource {
  id: string;
  createdAt: string;
  createdBy: string;
  lastModified: string;
  lastModifiedBy: string;
  reportingOrganization: string;
  reportDate: string;
  driver: Driver;
  job: Job;
  isApproved: boolean;
  clockInTime: string;
  clockOutTime: string;
  firstLoadTime: string;
  loadingWaitTime: string;
  unloadingWaitTime: string;
  averageRoundTripTime: string;
  tripCount: number;
  punchcardCount: number;
  lastLoadTime: string;
  freightBillTime: string;
  shiftTime: string;
  deficientTime: string;
}
