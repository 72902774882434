"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
// libraries
var moment = require("moment");
// services
var api_service_1 = require("../../shared/api.service");
var driver_service_1 = require("../../drivers/driver.service");
var truck_service_1 = require("../../trucks/truck.service");
var shift_service_1 = require("../shift.service");
// models
var driver_1 = require("../../drivers/driver");
// angular material
var material_1 = require("@angular/material");
var NewShiftDialogComponent = /** @class */ (function () {
    // TODO CALLBACK
    function NewShiftDialogComponent(shiftService, fb, dialogRef) {
        this.shiftService = shiftService;
        this.fb = fb;
        this.dialogRef = dialogRef;
        this.driverForShift = null;
        this.shiftCreated = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
        this.driverDropdownConfig = {
            service: driver_service_1.DriverService,
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            sortBy: 'profile__first_name,profile__last_name',
        };
        this.truckDropdownConfig = {
            service: truck_service_1.TruckService,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'displayName',
            sortBy: 'name',
        };
    }
    NewShiftDialogComponent.prototype.ngOnInit = function () {
        this.initForm(this.driverForShift);
    };
    NewShiftDialogComponent.prototype.ngOnDestroy = function () { };
    NewShiftDialogComponent.prototype.initForm = function (driver) {
        var currentDate = new Date();
        this.newShiftForm = this.fb.group({
            driver: [driver, [forms_1.Validators.required]],
            truck: [
                driver && driver.truck ? driver.truck : null,
                [forms_1.Validators.required],
            ],
            startDate: [currentDate, [forms_1.Validators.required]],
            startTime: [null, [forms_1.Validators.required]],
            endTime: [null],
            endDate: [null],
            adjustment: [null, [forms_1.Validators.pattern('[^.]+')]],
        }, {
            validator: this.dateLessThan('startDate', 'endDate', 'startTime', 'endTime'),
        });
    };
    Object.defineProperty(NewShiftDialogComponent.prototype, "startDate", {
        get: function () {
            return this.newShiftForm.get('startDate').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewShiftDialogComponent.prototype, "endDate", {
        get: function () {
            return this.newShiftForm.get('endDate').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewShiftDialogComponent.prototype, "driver", {
        get: function () {
            return this.newShiftForm.get('driver');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewShiftDialogComponent.prototype, "truck", {
        get: function () {
            return this.newShiftForm.get('truck');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewShiftDialogComponent.prototype, "adjustment", {
        get: function () {
            return this.newShiftForm.get('adjustment');
        },
        enumerable: true,
        configurable: true
    });
    NewShiftDialogComponent.prototype.onDateChanged = function (date, dates) {
        var _a;
        this.newShiftForm.patchValue((_a = {},
            _a[date] = dates[0],
            _a));
    };
    NewShiftDialogComponent.prototype.onSelect = function (type, value) {
        var _a;
        this.newShiftForm.patchValue((_a = {},
            _a[type] = value,
            _a));
    };
    NewShiftDialogComponent.prototype.dateLessThan = function (dFrom, dTo, tFrom, tTo) {
        var _this = this;
        return function (group) {
            var dateFrom = group.controls[dFrom];
            var dateTo = group.controls[dTo];
            var timeFrom = group.controls[tFrom];
            var timeTo = group.controls[tTo];
            var fullDateFrom = moment(moment(dateFrom.value).format('MM/DD/YYYY') + ' ' + timeFrom.value, 'MM/DD/YYYY HH:mm');
            var fullDateTo = moment(moment(dateTo.value).format('MM/DD/YYYY') + ' ' + timeTo.value, 'MM/DD/YYYY HH:mm');
            _this.startShiftDate = fullDateFrom;
            _this.endShiftDate = fullDateTo;
            if (!fullDateTo.isValid()) {
                return {};
            }
            if (!fullDateTo.isSameOrAfter(fullDateFrom)) {
                return {
                    dates: 'Date from should be less than start date',
                };
            }
            return {};
        };
    };
    NewShiftDialogComponent.prototype.submit = function () {
        var _this = this;
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.errors = [];
        var model = {
            driver: this.driver.value.id,
            truck: this.truck.value.id,
            startTime: this.startShiftDate.toISOString(),
            endTime: this.endShiftDate.toISOString(),
            adjustment: this.adjustment.value,
        };
        this.shiftService.save(model).subscribe(function (newShift) {
            _this.loading = false;
            _this.shiftCreated.emit(newShift);
            _this.dialogRef.close();
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    return NewShiftDialogComponent;
}());
exports.NewShiftDialogComponent = NewShiftDialogComponent;
