"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var organization_1 = require("../organizations/organization");
var custom_field_service_1 = require("./custom-field.service");
var custom_field_1 = require("./custom-field");
var angular2_uuid_1 = require("angular2-uuid");
var CustomFieldsComponent = /** @class */ (function () {
    function CustomFieldsComponent(customFieldService) {
        this.customFieldService = customFieldService;
        this.loading = false;
    }
    CustomFieldsComponent.prototype.ngOnInit = function () {
        this.getFields();
    };
    CustomFieldsComponent.prototype.addField = function () {
        var field = new custom_field_1.CustomField();
        var uuid = angular2_uuid_1.UUID.UUID().replace(/-/g, '');
        field.ownerOrganization = this.organization.id;
        field.key = uuid;
        field.kind = custom_field_1.CustomFieldKind.Truck;
        this.fields.push(field);
    };
    CustomFieldsComponent.prototype.getFields = function () {
        var _this = this;
        this.customFieldService.list({ active: true }).subscribe(function (fields) {
            _this.fields = fields;
        });
    };
    CustomFieldsComponent.prototype.onDelete = function (index) {
        var field = this.fields[index];
        if (!field || !field.displayName || field.displayName === '' || !field.id || !field.kind) {
            this.fields.splice(index, 1);
            return;
        }
        field.active = !field.active;
        this.onSubmit();
    };
    CustomFieldsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.loading = true;
        this.customFieldService.updateFields(this.fields).subscribe(function (fields) {
            _this.loading = false;
            _this.getFields();
        });
    };
    CustomFieldsComponent.prototype.onScroll = function () {
        var _this = this;
        this.customFieldService.listNext(null, { active: true }).subscribe(function (fields) {
            _this.fields = _this.fields.concat(fields);
        });
    };
    return CustomFieldsComponent;
}());
exports.CustomFieldsComponent = CustomFieldsComponent;
