"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var invitation_service_1 = require("../invitation/invitation.service");
var connection_dialog_component_1 = require("../connections/connection-dialog.component");
var index_1 = require("../shared/dialogs/index");
var InvitationsComponent = /** @class */ (function () {
    function InvitationsComponent(route, router, invitationService, dialog) {
        this.route = route;
        this.router = router;
        this.invitationService = invitationService;
        this.dialog = dialog;
        this.invitations = [];
        this.loading = true;
        this.errors = [];
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser.organization !== null) {
            this.organizationId = currentUser.organization.id;
        }
    }
    InvitationsComponent.prototype.openConnectionDialog = function () {
        // let dialogRef = this.dialog.open(ConnectionDialog);
        var dialogRef = this.dialog.open(connection_dialog_component_1.ConnectionDialogComponent, {
            width: '444px',
            height: '440px'
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // result;
        });
    };
    InvitationsComponent.prototype.accept = function (invitation) {
        var _this = this;
        invitation.loading = true;
        this.invitationService.acceptInvitation(invitation.id).subscribe(function (result) {
            invitation.status = 'accepted';
            _this.getInvitations();
            invitation.loading = false;
        }, function (err) {
            _this.errors = err;
            invitation.loading = false;
        });
    };
    InvitationsComponent.prototype.reject = function (invitation) {
        var _this = this;
        invitation.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        var body = 'This organization will not be able to send or receive jobs with you';
        if (invitation.recipientOrganization) {
            body = "'" + (invitation.recipientOrganization && invitation.recipientOrganization.name) + "'";
            body = body + " will not be able to send or receive jobs with you.";
        }
        this.confirmDialog.componentInstance.attributes = {
            title: 'Reject Connection Request?',
            body: body,
            close: 'Cancel',
            accept: 'Reject'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.invitationService.rejectInvitation(invitation.id).subscribe(function (result) {
                    invitation.status = 'rejected';
                    invitation.loading = false;
                }, function (err) {
                    _this.errors = err;
                    invitation.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    InvitationsComponent.prototype.resend = function (event, invitation) {
        var _this = this;
        invitation.loading = true;
        this.invitationService.resendInvitation(invitation.id).subscribe(function (result) {
            invitation.status = 'resent';
            invitation.loading = false;
            invitation.resent = true;
            invitation.resentAt = result.resentAt;
        }, function (err) {
            _this.errors = err;
            invitation.loading = false;
        });
    };
    InvitationsComponent.prototype.cancel = function (invitation) {
        var _this = this;
        invitation.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Cancel Invitation?',
            body: 'Are you sure you want to cancel your invitation?',
            close: 'No',
            accept: 'Yes'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.invitationService.cancelInvitation(invitation.id).subscribe(function (result) {
                    invitation.status = 'cancelled';
                    invitation.loading = false;
                }, function (err) {
                    _this.errors = err;
                    invitation.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    InvitationsComponent.prototype.ngOnInit = function () {
        this.getInvitations();
    };
    InvitationsComponent.prototype.ngOnChanges = function (changes) {
        this.getInvitations();
    };
    InvitationsComponent.prototype.changeSearch = function () {
        this.getInvitations();
    };
    InvitationsComponent.prototype.getInvitations = function () {
        var _this = this;
        this.loading = true;
        this.invitationService.getInvitations(this.type, this.organizationId).subscribe(function (invitations) {
            _this.invitations = invitations;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    return InvitationsComponent;
}());
exports.InvitationsComponent = InvitationsComponent;
