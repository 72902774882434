"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var moment = require("moment");
var api_service_1 = require("../api.service");
var user_notification_service_1 = require("../user-notifications/user-notification.service");
var UserNotificationsComponent = /** @class */ (function () {
    function UserNotificationsComponent(router, userNotificationService) {
        this.router = router;
        this.userNotificationService = userNotificationService;
        this.viewType = 'navbar';
        this.notificationDays = [];
        this.notifications = [];
        this.notificationsLoading = false;
        this.pageSize = 1;
        this.errors = [];
    }
    UserNotificationsComponent.prototype.ngOnInit = function () { this.getNotifications(); };
    UserNotificationsComponent.prototype.ngOnDestroy = function () {
        if (this.notificationsReq && typeof this.notificationsReq.unsubscribe === 'function') {
            this.notificationsReq.unsubscribe();
        }
    };
    UserNotificationsComponent.prototype.onScroll = function (e) {
        if (this.viewType === 'fullview' &&
            (e.target['scrollTop'] > e.target['scrollHeight'] - e.target['clientHeight'] * 3)) {
            this.getNextNotifications();
        }
    };
    UserNotificationsComponent.prototype.getNotifications = function () {
        var _this = this;
        if (this.notificationsReq && typeof this.notificationsReq.unsubscribe === 'function') {
            this.notificationsReq.unsubscribe();
        }
        this.notificationsReq = this.viewType === 'navbar' ? this.userNotificationService.listUpdate(60000, {
            page_size: this.pageSize
        }).subscribe(function (notifications) {
            _this.notifications = notifications;
            _this.parseUnreadCount(_this.userNotificationService.unreadCount);
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); }) :
            this.userNotificationService.list({
                'mark_as_read': 'True',
                page_size: 50
            }).subscribe(function (notifications) {
                _this.parseNotifications(notifications);
            }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    UserNotificationsComponent.prototype.getNextNotifications = function () {
        var _this = this;
        if (this.notificationsReq && typeof this.notificationsReq.unsubscribe === 'function') {
            this.notificationsReq.unsubscribe();
        }
        this.notificationsReq = this.userNotificationService.listNext().subscribe(function (notifications) {
            _this.parseNotifications(notifications);
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    UserNotificationsComponent.prototype.parseNotifications = function (notifications) {
        var _this = this;
        notifications.forEach(function (n) {
            var matchedDayIndex = _this.notificationDays.findIndex(function (d) { return (d.date === moment(n.timestamp).format('YYYY-MM-DD')); });
            if (matchedDayIndex > -1) {
                _this.notificationDays[matchedDayIndex].notifications.push(n);
            }
            else {
                _this.notificationDays.push({
                    date: moment(n.timestamp).format('YYYY-MM-DD'),
                    notifications: [n]
                });
            }
        });
    };
    UserNotificationsComponent.prototype.selectNotification = function (notification) {
        this.router.navigateByUrl(notification.url);
    };
    UserNotificationsComponent.prototype.openNotifications = function () {
        this.pageSize = 50;
        this.getNotifications();
    };
    UserNotificationsComponent.prototype.closeNotifications = function () {
        var _this = this;
        this.pageSize = 1;
        if (this.notificationsReq) {
            this.notificationsReq.unsubscribe();
        }
        this.userNotificationService.list({ 'mark_as_read': 'True' }).subscribe(function () {
            _this.parseUnreadCount(_this.userNotificationService.unreadCount);
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    UserNotificationsComponent.prototype.parseUnreadCount = function (unreadCount) {
        this.unreadCount = unreadCount;
        this.unreadCountLabel = this.unreadCount < 1000 ?
            this.unreadCount.toString() :
            Math.round(this.unreadCount / 1000) + 'k';
    };
    return UserNotificationsComponent;
}());
exports.UserNotificationsComponent = UserNotificationsComponent;
