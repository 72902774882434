"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{background:#fff;height:auto;padding:15px 30px}.container[_ngcontent-%COMP%]   .geo-trip-table[_ngcontent-%COMP%]{padding-bottom:15px}.container[_ngcontent-%COMP%]   .geo-trip-table[_ngcontent-%COMP%]   mat-header-cell[_ngcontent-%COMP%]{padding-left:8px}.container[_ngcontent-%COMP%]   .geo-trip-table[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{margin:0 0 4px;font-size:14px;font-weight:600;color:#015bc5}.container[_ngcontent-%COMP%]   .geo-trip-table[_ngcontent-%COMP%]   .complete[_ngcontent-%COMP%]{color:#27af5e}.container[_ngcontent-%COMP%]   .geo-trip-table[_ngcontent-%COMP%]   .incomplete[_ngcontent-%COMP%]{color:#be2f1f}.container[_ngcontent-%COMP%]   .no-results[_ngcontent-%COMP%]{text-align:center;font-weight:600;color:#787579}"];
exports.styles = styles;
