"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".ruckit-replay[_ngcontent-%COMP%]{overflow-y:auto;box-sizing:border-box;min-height:100vh;background:#fff}.ruckit-replay__dashboard[_ngcontent-%COMP%]{padding:25px;max-width:1100px;margin:auto}notification[_ngcontent-%COMP%]{position:absolute;top:0;width:calc(100vw - 56px);padding:100px;z-index:1}.filters[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap;align-items:center;padding:10px 30px;border-top:1px solid #e8e8e8;border-bottom:1px solid #e8e8e8}.filters[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{margin-right:30px}.filters[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{margin-top:5px;color:#00c852}.filters[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]:not(:first-child){margin-left:-16px}.filters[_ngcontent-%COMP%]   button.play-button[_ngcontent-%COMP%]{width:66px;padding:0}.filters[_ngcontent-%COMP%]     dropdown .dropdown-button{height:36px}.filters[_ngcontent-%COMP%]   .legend-toggle[_ngcontent-%COMP%]{display:flex;font-size:14px;font-weight:600;color:#595959;cursor:pointer}.filters[_ngcontent-%COMP%]   .legend-toggle[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:36px;line-height:0;margin:10px 0 0}.filters[_ngcontent-%COMP%]   .show-voided[_ngcontent-%COMP%]{margin-right:20px}.filters[_ngcontent-%COMP%]   .show-voided[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{margin-bottom:0}.filters[_ngcontent-%COMP%]   .legend-container[_ngcontent-%COMP%]{height:36px;border-radius:4px;border:1px solid #dadada;background-color:#fff}"];
exports.styles = styles;
