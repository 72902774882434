"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Angular 5
require("@angular/platform-browser");
require("@angular/platform-browser-dynamic");
require("@angular/core");
require("@angular/common");
require("@angular/http");
require("@angular/router");
require("rxjs");
// Other vendors for example jQuery, Lodash or Bootstrap
// You can import js, ts, css, sass, ...
require("./assets/js/loadGenesys.js");
require("../node_modules/timepicker/jquery.timepicker.js");
require("../node_modules/timepicker/jquery.timepicker.css");
require("./public/javascript/sdxWebchatWidget.js");
require("./public/javascript/cxbus.min.js");
require("moment-duration-format");
