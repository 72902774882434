"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".form-content[_ngcontent-%COMP%]{margin-top:30px}.mat-dialog-content[_ngcontent-%COMP%], .md-dialog-content[_ngcontent-%COMP%]{height:auto;overflow-y:auto;display:flex;flex-direction:row;flex:1 1 auto;margin-bottom:0;padding-bottom:0}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .punch-card[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex:1 1 auto;width:300px}form[_ngcontent-%COMP%]   .punch-card[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]{padding:0;position:relative}form[_ngcontent-%COMP%]   .punch-card[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .label[_ngcontent-%COMP%]{margin-bottom:20px}form[_ngcontent-%COMP%]   .punch-card[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .submit-button[_ngcontent-%COMP%]{min-width:300px;margin-top:10px;margin-bottom:30px}form[_ngcontent-%COMP%]   .punch-card[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .ticket-image[_ngcontent-%COMP%]{float:left;margin-top:10px}form[_ngcontent-%COMP%]   .punch-card[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .ticket-image[_ngcontent-%COMP%] > img[_ngcontent-%COMP%]{width:300px;height:400px}"];
exports.styles = styles;
