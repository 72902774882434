"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var tickets_component_1 = require("./tickets.component");
var create_ticket_component_1 = require("./create-ticket.component");
var scaleit_ticket_save_guard_1 = require("../guards/scaleit-ticket-save.guard");
var routes = [
    {
        path: 'scale', component: create_ticket_component_1.CreateTicketComponent, canActivate: [auth_guard_1.AuthGuard],
        canDeactivate: [scaleit_ticket_save_guard_1.TicketSaveGuard]
    },
    {
        path: 'scale/create-ticket', component: create_ticket_component_1.CreateTicketComponent, canActivate: [auth_guard_1.AuthGuard],
        canDeactivate: [scaleit_ticket_save_guard_1.TicketSaveGuard]
    },
    {
        path: 'scale/create-ticket/:voidTicketId',
        component: create_ticket_component_1.CreateTicketComponent,
        canActivate: [auth_guard_1.AuthGuard],
        canDeactivate: [scaleit_ticket_save_guard_1.TicketSaveGuard]
    },
    { path: 'tickets', component: tickets_component_1.TicketsComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var TicketsRoutingModule = /** @class */ (function () {
    function TicketsRoutingModule() {
    }
    return TicketsRoutingModule;
}());
exports.TicketsRoutingModule = TicketsRoutingModule;
