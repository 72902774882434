<ng-container *ngIf="markerType === 'driver-initials'">
  <img class="map-element driver-image" *ngIf="locationUpdate.driver && locationUpdate.driver.image" src="{{ locationUpdate.driver.image }}" />
  <ng-container *ngIf="!locationUpdate.expired; else expired">
    <img class="map-element" [src]="markerBackground" />
  </ng-container>
  <ng-template #expired>
    <img class="map-element" *ngIf="locationUpdate.expired" src="assets/img/map/truck-marker-red.png" />
  </ng-template>
</ng-container>

<ng-container *ngIf="markerType === 'truck-number'">
  <img class="map-element" [src]="markerBackground" />
</ng-container>
