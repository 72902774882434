<!-- single date selection -->
<div
  *ngIf="config && !config.multipleDates && !config.rangeMode"
  class="date-picker-container"
  [ngClass]="{ mobile: config.mobile }"
>
  <input
    matInput
    disabled
    [value]="selectedDates && selectedDates[0]"
    [min]="config.min"
    [max]="config.max"
    [satDatepicker]="picker"
    (dateChange)="addEvent($event)"
    [matDatepickerFilter]="dateFilter"
  />
  <mat-icon
    *ngIf="config.decreaseControl && displayValue"
    class="decrease-date"
    (click)="!disabled && decreaseDate()"
    >chevron_left</mat-icon
  >
  <div
    class="date-picker-button"
    [ngClass]="{ disabled: disabled }"
    (click)="!disabled && picker.open()"
  >
    <sat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>
        <img src="assets/img/icon-calendar.svg" />
      </mat-icon>
    </sat-datepicker-toggle>
    <sat-datepicker
      #picker
      disabled="false"
      [dateClass]="dateClass"
      [closeAfterSelection]="true"
      [rangeMode]="false"
    ></sat-datepicker>
    {{ displayValue || placeholderText || 'Choose a date' | translate }}
  </div>
  <mat-icon
    *ngIf="config.increaseControl && displayValue"
    class="increase-date"
    (click)="!disabled && increaseDate()"
    >chevron_right</mat-icon
  >
</div>

<!-- multiple dates selection -->
<div
  *ngIf="config && (config.multipleDates || config.rangeMode)"
  class="date-picker-container"
  [ngClass]="{ mobile: config.mobile }"
>
  <input
    matInput
    disabled
    [min]="config.min"
    [max]="config.max"
    [satDatepicker]="picker"
    (dateChange)="addEvent($event)"
    [matDatepickerFilter]="dateFilter"
  />
  <div
    class="date-picker-button"
    [ngClass]="{ 'multiple-dates': config.multipleDates, disabled: disabled }"
    (click)="!disabled && picker.open()"
  >
    <sat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>
        <img src="assets/img/icon-calendar.svg" />
      </mat-icon>
    </sat-datepicker-toggle>
    <sat-datepicker
      #picker
      disabled="false"
      [dateClass]="dateClass"
      [closeAfterSelection]="!config.multipleDates"
      [rangeMode]="config.rangeMode"
    >
    </sat-datepicker>
    {{ displayValue || placeholderText || 'Choose a date' | translate }}
  </div>
</div>
