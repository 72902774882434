"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".end-of-day-container[_ngcontent-%COMP%]{display:flex;width:100%}.end-of-day-container[_ngcontent-%COMP%]   .eod-cell[_ngcontent-%COMP%]{font-size:12px;margin-right:20px;color:rgba(0,0,0,.54)}.end-of-day-container[_ngcontent-%COMP%]   .eod-cell[_ngcontent-%COMP%]   .eod-cell-value[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#595959}.end-of-day-container[_ngcontent-%COMP%]   .eod-cell[_ngcontent-%COMP%]   .eod-cell-value.expense-total[_ngcontent-%COMP%], .end-of-day-container[_ngcontent-%COMP%]   .eod-cell[_ngcontent-%COMP%]   .eod-cell-value.invoice-total[_ngcontent-%COMP%]{color:#01963e}.end-of-day-container[_ngcontent-%COMP%]   div.eod-cell[_ngcontent-%COMP%]:last-child{margin-right:0}"];
exports.styles = styles;
