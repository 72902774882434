import { Location } from './location';
import { LocationTypeSerializer } from './location-type.serializer';
import { LocationOwnerSerializer } from './location-owner.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class LocationSerializer {
  fromJson(json: any): Location {
    json = camelcaseKeysDeep(json);
    const location = new Location();
    if (!json) { return location; }

    location.id = json.id;
    location.name = json.name;
    location.street = json.street;
    location.city = json.city;
    location.state = json.state && json.state.toUpperCase();
    location.country = json.country || 'US';
    location.zipcode = json.zipcode;
    location.location = json.location;
    if (json.location && json.location.coordinates && json.location.coordinates.length === 2) {
      location.mappable = true;
    } else {
      location.mappable = false;
    }
    if (json.longitude) {
      location.longitude = json.longitude;
    } else if (location.location && location.location.coordinates && location.location.coordinates[0]) {
      location.longitude = location.location.coordinates[0];
    } else {
      location.longitude = '-97.6023238';
    }
    if (json.latitude) {
      location.latitude = json.latitude;
    } else if (location.location && location.location.coordinates && location.location.coordinates[1]) {
      location.latitude = location.location.coordinates[1];
    } else {
      location.latitude = '30.2178214';
    }
    if (location.latitude && location.longitude) {
      location.marker = {
        lat: location.latitude,
        lon: location.longitude,
        mappable: location.mappable
      };
    }
    location.geofence = json.geofence;
    location.archived = json.archived;
    location.averageLoadingTime = json.averageLoadingTime;
    location.averageUnloadingTime = json.averageUnloadingTime;
    location.organization = json.organization;
    location.timezone = json.timezone;
    location.createdAt = json.createdAt;
    location.tags = json.tags;
    if (json.archived) {
      location.classes = `${json.classes} archived`;
    } else {
      location.classes = json.classes;
    }
    if (json.locationType && typeof (json.locationType) === 'object') {
      location.locationType = new LocationTypeSerializer().fromJson(json.locationType);
    } else {
      location.locationType = new LocationTypeSerializer().fromJson({ id: json.locationType });
    }
    location.locationTypeName = location.locationType && location.locationType.name;
    if (json.locationOwner && typeof (json.locationOwner) === 'object') {
      location.locationOwner = new LocationOwnerSerializer().fromJson(json.locationOwner);
    } else {
      location.locationOwner = new LocationOwnerSerializer().fromJson({
        id: json.locationOwner, name: json.locationOwnerName
      });
    }
    location.displayName = [
      location.locationOwner && location.locationOwner.name, location.name
    ].filter(Boolean).join(': ');
    if (location.geofence && location.geofence.coordinates) {
      location.paths = location.geofence.coordinates[0].map((path) => {
        return { lat: path[1], lng: path[0] };
      });
    } else {
      location.paths = [];
    }

    return location;
  }

  toJson(location: Location): any {
    if (typeof location.latitude === 'string') {
      location.latitude = Number(location.latitude);
    }
    if (typeof location.longitude === 'string') {
      location.longitude = Number(location.longitude);
    }
    location.location = { type: 'Point', coordinates: [location.longitude, location.latitude] };

    const tags = location.tags && location.tags.map(tag => {
      if (typeof tag === 'object') { tag = tag['name']; }
      return tag;
    });

    let json = {
      id: location.id,
      name: location.name,
      street: location.street,
      city: location.city,
      state: location.state,
      country: location.country,
      zipcode: location.zipcode,
      location: location.location,
      longitude: location.longitude,
      latitude: location.latitude,
      geofence: location.geofence,
      archived: location.archived,
      averageLoadingTime: location.averageLoadingTime,
      averageUnloadingTime: location.averageUnloadingTime,
      organization: location.organization,
      tags: tags,
      timezone: location.timezone,
      locationOwner: location.locationOwner && location.locationOwner.id,
      locationOwnerName: location.locationOwner && location.locationOwner.name,
      locationType: location.locationType && location.locationType.id,
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}

