import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';

import { DispatchConnection } from '../dispatch-schedule.component';
import { ItemGroup, ItemList } from '../../../shared/item-grid/item-grid.component';

@Component({
  selector: 'dispatch-schedule-collaborator-grid',
  templateUrl: './dispatch-schedule-collaborator-grid.component.html',
  styleUrls: ['../dispatch-schedule.component.scss']
})
export class DispatchScheduleCollaboratorGridComponent {
  searchTerm: string;
  @Input() loadingProgress = 0;
  @Input() connections: DispatchConnection[];
  connectionList: ItemList;
  filteredConnectionList: ItemList;
  displayKeys = ['shareStats'];
  connectionCount = { available: 0, assigned: 0 };

  selectedConnectionsValue: string[] = [];
  @Output() selectedConnectionsChange: EventEmitter<string[]> = new EventEmitter();
  @Input() get selectedConnections() { return this.selectedConnectionsValue; }
  set selectedConnections(data: string[]) {
    this.selectedConnectionsValue = data;
    this.selectedConnectionsChange.emit(data);
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.connections && changes.connections.currentValue) {
      this.setupItemList(changes.connections.currentValue);
    }
  }

  /**
   * Uses a groupBy to generate a list of groups for the input connection list
   *
   * @param {DispatchConnection[]} connections The input connection list
   */
  setupItemList(connections: DispatchConnection[]) {
    this.connectionCount = {
      available: connections.length,
      assigned: connections.filter(connection => (connection.shares.length > 0)).length
    };
    this.connectionList = [<ItemGroup>{
      name: 'Collaborators',
      groupBy: 'default',
      items: []
    }].map(group => (Object.assign(group, {
      items: connections.map(c => (
        Object.assign(c, {
          shareStats: c.name + ' - ' + c.shares.length
        })
      ))
    })));
    this.connectionList = this.connectionList.sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
    this.filteredConnectionList = cloneDeep(this.connectionList);
  }

  /**
   * Creates a list of class names to append to each of the item elements based on specified collaborator states
   *
   * @param {DispatchConnection} connection The input connection
   * @return {string} The list of class names combined in a single string
   */
  generateItemClassNames(connection: DispatchConnection): string {
    let classNames = '';
    // if (collaborator.dutyStatus === 'off-duty') {
    //   classNames += 'red ';
    // } else
    if (connection.shares.length) {
      classNames += 'blue ';
    }
    return classNames;
  }
}
