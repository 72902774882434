import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { clone, get, isEmpty } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { LocationService } from '../../locations/location.service';
import { LocationSerializer } from '../../locations/location.serializer';
import { Job } from '../job';
import { AuthenticationService } from '../../shared/authentication.service';
import { User } from '../../users/user';
import { JobService } from '../job.service';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs/ruckit-confirm-dialog/ruckit-confirm-dialog.component';
import { Location } from '../../locations/location';

@Component({
  selector: 'app-edit-selected-jobs',
  templateUrl: './edit-selected-jobs.component.html',
  styleUrls: ['./edit-selected-jobs.component.scss']
})
export class EditSelectedJobsComponent implements OnInit {

  @Output() editSuccess = new EventEmitter();

  user: User;
  selectedJobs: Job[] = [];
  excludeJobs: Job[] = [];
  allJobsCount: number;
  isAllJobsSelected: boolean;
  query: any;
  weightOptions = [
    { id: 'ton', name: 'Ton' },
    { id: 'metric-tons', name: 'Metric Ton' },
    { id: 'pound', name: 'Pound' },
    { id: 'cuyds', name: 'Cubic Yard' },
    { id: 'bushel', name: 'Bushel' },
    { id: 'bag', name: 'Bag' }
  ];
  jobWeightOptions = clone(this.weightOptions);
  haulWeightOptions = clone(this.weightOptions);
  orderTypeOptions = [
    { id: 'tons', name: 'Tons' },
    { id: 'metric-tons', name: 'Metric Tons' },
    { id: 'tonnes', name: 'Tonnes' },
    { id: 'loads', name: 'Loads' },
    { id: 'lbs', name: 'Pounds' },
    { id: 'kgs', name: 'Kilograms' },
    { id: 'cuyds', name: 'Cubic Yards' },
    { id: 'bushels', name: 'Bushels' },
    { id: 'bags', name: 'Bags' }
  ];
  deliveryIntervalUnits = [{
    id: 'hours', name: 'Hours'
  }, {
    id: 'minutes', name: 'Minutes', selected: true
  }];

  locationsConfig = {
    selectText: this.translateService.instant('Select Location'),
    loadingText: this.translateService.instant('Loading Locations...'),
    noResultsText: this.translateService.instant('No Locations'),
    service: LocationService,
    query: {
      archived: 'False'
    },
    pageSize: null,
    sortDirection: 'desc'
  };

  locationsDropdownConfig = {
    start: { searchable: true, nameProperty: 'displayName' },
    end: { searchable: true, nameProperty: 'displayName' }
  };
  configOptions = [
    { name: 'Required', id: 'required' },
    { name: 'Optional', id: 'optional' },
    { name: 'Hidden', id: 'hidden' },
  ];
  defaultLocation: any;
  model = {
    invoiceType: null,
    rate: null,
    invoiceWeightUnit: null,
    cloneHaulRate: undefined,
    haulType: null,
    haulRate: null,
    haulWeightUnit: null,
    dates: [],
    material: null,
    amountNeeded: null,
    totalAmountType: null,
    dailyDeliveryTarget: null,
    dailyDeliveryTargetType: null,
    deliveryInterval: null,
    deliveryIntervalUnit: null,
    startLocation: null,
    checkinOptions: {
      loadImageConfig: null,
      qrConfig: null,
      signatureImageConfig: null,
      ticketImageConfig: null,
      ticketNumberConfig: null,
      weightConfig: null
    },
    endLocation: null,
    checkoutOptions: {
      loadImageConfig: null,
      qrConfig: null,
      signatureImageConfig: null,
      ticketImageConfig: null,
      ticketNumberConfig: null,
      weightConfig: null
    },
    include: [],
    exclude: undefined,
  };
  archiveJob = false;
  errors = [];
  jobsCountWarningTranslationKey = '{{jobsCount}} {{jobtext}} will be edited based on changes made.';

  constructor(public dialogRef: MatDialogRef<EditSelectedJobsComponent>,
    private locationService: LocationService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private jobService: JobService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.locationService.getLocationByIP().subscribe(res => {
      this.defaultLocation = res;
    });
  }

  tagUrlWith(fragment: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams
      },
      fragment: fragment
    });
  }

  invoiceTypeChanged(): void {
    this.changeCloneHaulRate();
  }

  changeCloneHaulRate(): void {
    if (this.model.cloneHaulRate) {
      this.model.haulWeightUnit = this.model.invoiceWeightUnit;
      this.model.haulType = this.model.invoiceType;
      this.model.haulRate = this.model.rate;
    }
  }

  weightUnitChange(tracking: string, unit) {
    this.model[tracking + 'WeightUnit'] = unit.id;
    if (tracking === 'invoice') {
      this.changeCloneHaulRate();
    }
  }

  onDateChanged(values: Date[]) {
    this.model.dates = values;
  }

  changeTotalAmountType(type: any) {
    this.model.totalAmountType = type.id;
  }

  changeDailyDeliveryTargetType(type: any) {
    this.model.dailyDeliveryTargetType = type.id;
  }

  changeLocation(location: Location, place: string) {
    this.model[place + 'Location'] = location;
  }

  getPaths(which: string): any[] {
    let paths = [];
    let location = (new LocationSerializer).fromJson(which === 'start' ? this.model.startLocation : this.model.endLocation);

    if (location && location.geofence) {
      paths = location.geofence.coordinates[0].map((path) => {
        return { lat: path[1], lng: path[0] };
      });
    }

    return [paths];
  }

  getCoord(which: string, index: number) {
    let defaultCoords = [
      get(this.defaultLocation, 'longitude', -97.6023238),
      get(this.defaultLocation, 'latitude', 30.2178214)
    ];
    if (which === 'start') {
      return get(this.model.startLocation, 'location.coordinates[' + index + ']', defaultCoords[index]);
    } else {
      return get(this.model.endLocation, 'location.coordinates[' + index + ']', defaultCoords[index]);
    }
  }

  setDeliveryInterval() {
    if (!this.model.deliveryIntervalUnit && (this.model.deliveryInterval || this.model.deliveryInterval === 0)) {
      this.model.deliveryIntervalUnit = this.deliveryIntervalUnits[1].id;
    }
  }

  isSubmitBtnDisabled(editJobsForm: NgForm) {
    const hasCheckinOptions = Object.keys(this.model.checkinOptions).findIndex(k => this.model.checkinOptions[k] !== null) > -1 ? true : false;
    const hasCheckoutOptions = Object.keys(this.model.checkoutOptions).findIndex(k => this.model.checkoutOptions[k] !== null) > -1 ? true : false;
    return ((editJobsForm.untouched || !editJobsForm.dirty) &&
    this.model.dates.length === 0 && !this.model.startLocation &&
    !this.model.endLocation && !hasCheckinOptions &&
    !hasCheckoutOptions && !this.archiveJob);
  }

  submit() {
    const confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    const message = this.translateService.instant('{{jobsCount}} {{jobsText}} will be edited with the changes made. Are you sure you want to make these changes?', {
      jobsCount: this.allJobsCount,
      jobsText: this.allJobsCount > 1 ? 'jobs' : 'job'
    });
    confirmDialog.componentInstance.attributes = {
      title: this.translateService.instant('Save edits?'),
      body: message,
      close: this.translateService.instant('Cancel'),
      accept: this.translateService.instant('Save'),
      isErrorBtn: true
    };

    confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        let model = { ...this.model };
        if (this.isAllJobsSelected) {
          model.include = null;
          model.exclude = this.excludeJobs.map(job => job.id)
        } else {
          model.include = this.selectedJobs.map(job => job.id);
        }
        if (model.checkinOptions) {
          this.removeEmptyValuesFromObject(model.checkinOptions);
          if (Object.keys(model.checkinOptions).length === 0) {
            delete model.checkinOptions;
          }
        }
        if (model.checkoutOptions) {
          this.removeEmptyValuesFromObject(model.checkoutOptions);
          if (Object.keys(model.checkoutOptions).length === 0) {
            delete model.checkoutOptions;
          }
        }
        if (this.archiveJob) {
          this.query['archived'] = true;
        } else {
          if (this.query.hasOwnProperty('archived')) {
            delete this.query['archived'];
          }
        }

        this.jobService.bulkUpdate(model, this.query).subscribe((response) => {
          this.editSuccess.emit();
          this.dialog.closeAll();
        }, err => {
          this.errors = [err];
        });
      }
    });
  }

  removeEmptyValuesFromObject(obj: any): any {
    Object.keys(obj).forEach(k => {
      if (obj[k] === null || obj[k] === undefined || obj[k] === '') {
        delete obj[k];
      }
    });
    return obj;
  }

  ngOnDestroy() {
    this.selectedJobs = [];
    this.allJobsCount = null;
    this.excludeJobs = [];
  }
}
