"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SecondsToPrettyTime = /** @class */ (function () {
    function SecondsToPrettyTime() {
    }
    SecondsToPrettyTime.prototype.transform = function (seconds) {
        if (!seconds) {
            return '';
        }
        else if (seconds < 1) {
            return '0';
        }
        else {
            var d = Number(seconds);
            var h = Math.floor(d / 3600);
            var m = Math.floor((d % 3600) / 60);
            var s = Math.floor((d % 3600) % 60);
            var hDisplay = h > 0 ? h + 'h ' : '';
            var mDisplay = m > 0 ? m + 'm ' : '';
            var sDisplay = s > 0 ? s + 's ' : '';
            return hDisplay + mDisplay + sDisplay;
        }
    };
    return SecondsToPrettyTime;
}());
exports.SecondsToPrettyTime = SecondsToPrettyTime;
