"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var LocationUpdateStatus;
(function (LocationUpdateStatus) {
    LocationUpdateStatus["ENROUTE_UNLOADING"] = "enroute_unloading";
    LocationUpdateStatus["LOADING_COMPLETE"] = "loading_complete";
    LocationUpdateStatus["UNLOADING"] = "unloading";
    LocationUpdateStatus["ENROUTE_LOADING"] = "enroute_loading";
    LocationUpdateStatus["LOADING"] = "loading";
})(LocationUpdateStatus = exports.LocationUpdateStatus || (exports.LocationUpdateStatus = {}));
var LocationUpdate = /** @class */ (function () {
    function LocationUpdate(locationInfo) {
        this.id = locationInfo.id;
        this.trip = locationInfo.trip;
        this.driver = locationInfo.driver;
        this.truck = locationInfo.truck;
        this.assignment = locationInfo.assignment;
        this.date = locationInfo.date;
        this.jobevent = locationInfo.jobevent;
        this.location = locationInfo.location;
        this.orientation = locationInfo.orientation;
        this.speed = locationInfo.speed;
        this.provider = locationInfo.provider;
        this.tripStatus = locationInfo.tripStatus || '';
        this.createdAt = locationInfo.createdAt && new Date(locationInfo.createdAt);
        var currentDate = moment();
        this.expired = this.createdAt && currentDate.diff(moment(this.createdAt), 'minutes') > 10;
        this.moreThanThirtyMinutesAgo = this.createdAt && currentDate.diff(moment(this.createdAt), 'minutes') > 30;
    }
    return LocationUpdate;
}());
exports.LocationUpdate = LocationUpdate;
