"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./trip-history-billing.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("../trip-history-widget/trip-history-widget.component.ngfactory");
var i5 = require("../trip-history-widget/trip-history-widget.component");
var i6 = require("./trip-history-billing.component");
var styles_TripHistoryBillingComponent = [i0.styles];
var RenderType_TripHistoryBillingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripHistoryBillingComponent, data: {} });
exports.RenderType_TripHistoryBillingComponent = RenderType_TripHistoryBillingComponent;
function View_TripHistoryBillingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.url, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getValueString(_v.parent.context.$implicit.value); _ck(_v, 1, 0, currVal_1); }); }
function View_TripHistoryBillingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.color; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getValueString(_v.parent.context.$implicit.value); _ck(_v, 1, 0, currVal_1); }); }
function View_TripHistoryBillingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "billing-details__field__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.image, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.name)), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TripHistoryBillingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "billing-details__field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "billing-details__field__values"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "billing-details__field__values__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "billing-details__field__values__value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripHistoryBillingComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showNonURL", 2]], null, 0, null, View_TripHistoryBillingComponent_3)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripHistoryBillingComponent_4)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.url && _v.context.$implicit.value); var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.image; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit.name)); _ck(_v, 3, 0, currVal_0); }); }
function View_TripHistoryBillingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "trip-history-widget", [], null, null, null, i4.View_TripHistoryWidgetComponent_0, i4.RenderType_TripHistoryWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i5.TripHistoryWidgetComponent, [], { title: [0, "title"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 1, 2, "div", [["class", "ticket-details"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripHistoryBillingComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Billing Details")), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.fields; _ck(_v, 5, 0, currVal_1); }, null); }
exports.View_TripHistoryBillingComponent_0 = View_TripHistoryBillingComponent_0;
function View_TripHistoryBillingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "trip-history-billing", [], null, null, null, View_TripHistoryBillingComponent_0, RenderType_TripHistoryBillingComponent)), i1.ɵdid(1, 114688, null, 0, i6.TripHistoryBillingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TripHistoryBillingComponent_Host_0 = View_TripHistoryBillingComponent_Host_0;
var TripHistoryBillingComponentNgFactory = i1.ɵccf("trip-history-billing", i6.TripHistoryBillingComponent, View_TripHistoryBillingComponent_Host_0, { trip: "trip" }, {}, []);
exports.TripHistoryBillingComponentNgFactory = TripHistoryBillingComponentNgFactory;
