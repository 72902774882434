"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var AppliedFiltersComponent = /** @class */ (function () {
    function AppliedFiltersComponent() {
        this.filters = [];
        this.primaryFilters = [];
        this.searchChange = new core_1.EventEmitter();
        this.filtersChange = new core_1.EventEmitter();
        this.filtersReset = new core_1.EventEmitter();
        this.primaryFiltersChange = new core_1.EventEmitter();
        this.hasVisibleFilters = false;
    }
    AppliedFiltersComponent.prototype.ngOnInit = function () {
        this.checkVisibleFilters();
    };
    AppliedFiltersComponent.prototype.ngOnChanges = function (changes) {
        if (changes['filters'] && changes['filters'].currentValue) {
            this.checkVisibleFilters();
        }
    };
    AppliedFiltersComponent.prototype.remove = function (deletedFilter) {
        if (deletedFilter.key) {
            this.filters = lodash_1.reject(this.filters, { key: deletedFilter.key });
        }
        else {
            this.filters = this.filters.filter(function (_filter) { return _filter !== deletedFilter; });
        }
        this.filtersChange.emit(this.filters);
    };
    AppliedFiltersComponent.prototype.removePrimaryFilter = function (deletedFilter) {
        this.primaryFiltersChange.emit(deletedFilter);
    };
    AppliedFiltersComponent.prototype.clearSearch = function () {
        this.search = '';
        this.searchChange.emit(this.search);
    };
    AppliedFiltersComponent.prototype.reset = function () {
        this.filtersReset.emit();
        this.clearSearch();
        this.filters = lodash_1.filter(this.filters, function (filtr) { return filtr.hidden; });
        this.filtersChange.emit(this.filters);
    };
    AppliedFiltersComponent.prototype.getFilterText = function (filtr) {
        var filterText;
        if (typeof filtr.displayValues === 'string') {
            filterText = filtr.displayValues;
        }
        else {
            if (typeof filtr.displayValues[0] === 'string') {
                filterText = filtr.displayValues.join(', ');
            }
            else {
                filterText = filtr.displayValues.map(function (fltr) { return fltr.name || ''; }).join(', ');
            }
        }
        return filterText;
    };
    AppliedFiltersComponent.prototype.checkVisibleFilters = function () {
        var visibleFilters = lodash_1.reject(this.filters, { hidden: true });
        this.hasVisibleFilters = visibleFilters && visibleFilters.length > 0;
    };
    return AppliedFiltersComponent;
}());
exports.AppliedFiltersComponent = AppliedFiltersComponent;
