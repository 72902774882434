"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content.fleet-container[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column}.app-content.fleet-container[_ngcontent-%COMP%]     .drivers.fancy-table-wrapper, .app-content.fleet-container[_ngcontent-%COMP%]     .trucks.fancy-table-wrapper{height:calc(100vh - 70px - 30px - 130px)!important}.app-content.fleet-container[_ngcontent-%COMP%]   .sidenav-container[_ngcontent-%COMP%]{margin-top:-19px}.app-content.fleet-container[_ngcontent-%COMP%]   .sidenav-container[_ngcontent-%COMP%]   fancy-table[_ngcontent-%COMP%]{margin-top:20px}.app-content.fleet-container[_ngcontent-%COMP%]     .list-table{overflow:scroll;height:calc(100vh - 188px)}"];
exports.styles = styles;
