<div class="app-content">
  <my-toolbar title="{{ 'Accounts' | translate }}"></my-toolbar>
  <action-bar *ngIf="accountType === 'leased-fleet'">
    <div class="left-actions">
      <div class="subnav custom-subnav">
        <div class="links">
          <a [routerLink]="['/', accountType]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}" class="account-info" translate>Accounts</a>
          <a [routerLink]="['/', accountType, 'trucks']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}"  class="trucks" translate>Leased Trucks</a>
          <a [routerLink]="['/', accountType, 'drivers']" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}"  class="drivers" translate>Leased Drivers</a>
        </div>
      </div>
    </div>
  </action-bar>
  <notification *ngFor="let error of errors" translate>{{error}}</notification>

  <!-- using fancy table: reusable component -->
  <div class="container" [ngClass]="{'header-visible': accountType === 'leased-fleet'}">
    <div class="actions-wrapper">
      <fancy-table #accountTable [(availableColumns)]="availableColumns" 
                  [(displayedColumns)]="displayedColumns"
                  [(availableFilters)]="availableFilters"
                  [(filters)]="appliedFilters" 
                  (columnChange)="columnsChanged($event)"
                  [(search)]="search" [config]="accountTableConfig">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'name'">
              <div class="data"> {{ row.organization && row.organization.name || '-' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'phone-number'">
              <div class="data">{{ row.organization && row.organization.phoneNumber }}</div>
            </div>
            <div class="value" *ngSwitchCase="'email'">
              <div class="data">{{ row.organization && row.organization.email }}</div>
            </div>
            <div class="value" *ngSwitchCase="'billing-id'">
              <div class="data">{{ row.uniqueBillingId }}</div>
            </div>
            <div class="value" *ngSwitchCase="'action'">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of row.filterOptions(accountTableConfig.menuOptions)">
                  <a mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Account List</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)"></applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>
        <filters-panel [(availableFilters)]="availableFilters" [(appliedFilters)]="appliedFilters" [(search)]="search"></filters-panel>
        <!-- add new product dialog-->
        <a mat-icon-button color="accent" class="add-button" [routerLink]="['/' + this.accountType, 'new']">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- fancy table :ends here -->

