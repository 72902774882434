<div class="collaborator-stats-container">
  <div class="actions-wrapper">
    <fancy-table #collaboratorStatsTable *ngIf="!loading" [config]="tableConfig"
      [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns"
      [(detailColumns)]="detailColumns" [(availableFilters)]="availableFilters"
      [(filters)]="appliedFilters" [(search)]="search" (columnChange)="columnsChanged($event)">
      <ng-template #detailTemplates let-row="row" let-column="column" let-value="value">
        <div class="data">
          <collaborator-jobs *ngIf="row.expanded && row.jobEventStats"
            [jobEventStat]="row.jobEventStats" [jobEventDate]="jobEventDate"
            [filters]="appliedFilters">
          </collaborator-jobs>
        </div>
      </ng-template>

      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'collaborator'">
            <div class="data">{{ row.name }}</div>
          </div>
          <div class="value" *ngSwitchCase="'avg-trip-time'">
            <div class="data">{{ row.averageTripTime }}</div>
          </div>
          <div class="value" *ngSwitchCase="'dispatched-trucks'">
            <div class="data">{{ row.dispatchedTrucks }}</div>
          </div>
          <div class="value" *ngSwitchCase="'confirmed-trucks'">
            <div class="data">{{ row.confirmedTrucks }}</div>
          </div>
          <div class="value" *ngSwitchCase="'requested-trucks'">
            <div class="data">{{ row.requestedTrucks }}</div>
          </div>
          <div class="value" *ngSwitchCase="'truck-types'">
            <div class="data" *ngIf="row.truckTypes && row.truckTypes.length > 1">
              <truck-type-names [countOnly]="true" [truckTypes]="row.truckTypes"></truck-type-names>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'drivers-on-shift'">
            <div class="data">{{ row.driversOnShift }}</div>
          </div>
          <div class="value" *ngSwitchCase="'delivered-loads'">
            <div class="data">{{ row.deliveredLoads }}</div>
          </div>
          <div class="value" *ngSwitchCase="'weight-in-transit'">
            <div class="data">{{ row.weightInTransit }}</div>
          </div>
          <div class="value" *ngSwitchCase="'ticketed-weight'">
            <div class="data">{{ row.ticketedWeight }}</div>
          </div>
          <div class="value" *ngSwitchCase="'dispatched-loads'">
            <div class="data">{{ row.dispatchedLoads }}</div>
          </div>
          <div class="value" *ngSwitchCase="'dispatched-tons'">
            <div class="data">{{ row.dispatchedTons }}</div>
          </div>
          <div class="value" *ngSwitchCase="'invoice-total'">
            <div class="data">{{ (row.invoiceTotal || 0) | currency : 'USD' : 'symbol' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'haul-total'">
            <div class="data">{{ (row.haulTotal || 0) | currency : 'USD' : 'symbol' }}</div>
          </div>
          <div class="value" *ngSwitchDefault>{{ value || '-' }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>
          <button mat-button [matMenuTriggerFor]="menu" translate>
            Collaborator Stats <mat-icon class="dropdown-arrow">arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item translate [routerLink]="['/jobs/daily']"
                    [queryParams]="{date: jobEventDate | date:'yyyyMMdd'}">
              Job Stats
            </button>
          </mat-menu>
        </div>
      </div>
      <div applied-filter-content>
        <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)">
        </applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle #columnToggle [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns">
      </column-toggle>
      <filters-panel [(availableFilters)]="availableFilters" [(appliedFilters)]="appliedFilters" [(search)]="search">
      </filters-panel>
    </div>
  </div>
</div>
