"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("./../../guards/auth.guard");
var trip_history_component_1 = require("./trip-history.component");
var routes = [
    { path: '', component: trip_history_component_1.TripHistoryComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var TripHistoryRoutingModule = /** @class */ (function () {
    function TripHistoryRoutingModule() {
    }
    return TripHistoryRoutingModule;
}());
exports.TripHistoryRoutingModule = TripHistoryRoutingModule;
