"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var DriverAssignment = /** @class */ (function () {
    function DriverAssignment() {
        this.assignmentCount = 0;
        this.expanded = false;
        this.highlighted = false;
        this.selected = false;
        this.totalTrips = 0;
        this.totalGeoTrips = 0;
    }
    DriverAssignment.prototype.url = function (action) {
        return '/scale/tickets/' + this.id + '/' + action;
    };
    DriverAssignment.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        return options;
    };
    return DriverAssignment;
}());
exports.DriverAssignment = DriverAssignment;
