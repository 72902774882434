"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var LocationsModule = /** @class */ (function () {
    function LocationsModule(translateService) {
        var browserLang = translateService.getBrowserLang();
        translateService.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');
    }
    return LocationsModule;
}());
exports.LocationsModule = LocationsModule;
