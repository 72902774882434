"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var api_service_1 = require("../shared/api.service");
var collaborator_1 = require("./collaborator");
var environment_1 = require("../../environments/environment");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CollaboratorService = /** @class */ (function () {
    function CollaboratorService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.count = 0;
        this.listAllProgress = new rxjs_1.Subject();
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.token = currentUser.token;
            this.organizationId = currentUser.organization && currentUser.organization.id;
        }
        this.params = new http_1.HttpParams();
        this.listAllProgress.next(0);
    }
    CollaboratorService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    CollaboratorService.prototype.getCollaborators = function (jobEventId, query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var collaboratorsUrl = this.baseUrl + 'shares/';
        params = params.set('jobevent', jobEventId);
        params = params.set('shared_by', this.organizationId);
        return this.http.get(collaboratorsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    CollaboratorService.prototype.getShares = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var collaboratorsUrl = this.baseUrl + 'shares/';
        return this.http.get(collaboratorsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    CollaboratorService.prototype.getAllShares = function (pageSize, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 50; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        params = params.set('page_size', '1');
        var collaboratorsUrl = this.baseUrl + 'shares/';
        var requestCount = 0;
        this.listAllProgress.next(0);
        return this.http.get(collaboratorsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return (_this.captureMetaData(res)); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(collaboratorsUrl, {
                    headers: api_service_1.requestHeaders(),
                    params: params
                }).map(function (res) {
                    _this.listAllProgress.next(1);
                    return _this.captureMetaData(res);
                }));
            }
            else {
                _this.listAllProgress.next(1 / Math.ceil(_this.count / pageSize));
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(collaboratorsUrl, {
                        headers: api_service_1.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        requestCount++;
                        _this.listAllProgress.next(requestCount / Math.ceil(_this.count / pageSize));
                        return _this.captureMetaData(res);
                    })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return mergedList.map(function (collaborator) {
                collaborator = camelcaseKeysDeep(collaborator);
                return new collaborator_1.Collaborator(collaborator);
            });
        }), operators_1.catchError(api_service_1.handleError));
    };
    CollaboratorService.prototype.getPending = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var collaboratorsUrl = this.baseUrl + 'shares/';
        params = params.set('status', 'pending');
        params = params.set('organization', this.organizationId);
        var endDate = new Date();
        endDate.setHours(0, 0, 0, 0);
        params = params.set('end__gte', endDate.toISOString());
        // params = params.set('group_by_job', 'True');
        return this.http.get(collaboratorsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    CollaboratorService.prototype.save = function (jobEventId, collaborator) {
        var _this = this;
        var collaboratorUrl = this.baseUrl + 'jobevents/' + jobEventId + '/share/';
        var _collaborator = lodash_1.clone(collaborator);
        if (!_collaborator.customFieldData) {
            _collaborator.customFieldData = {};
        }
        _collaborator.customFieldData[_collaborator.brokerRateCodeKey] = _collaborator.brokerRateCode;
        _collaborator = decamelizeKeysDeep(_collaborator);
        if (!collaborator.id) {
            return this.http.post(collaboratorUrl, _collaborator, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            delete _collaborator.status;
            collaboratorUrl = this.baseUrl + 'shares/' + collaborator.id + '/';
            return this.http.put(collaboratorUrl, _collaborator, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
    };
    CollaboratorService.prototype.accept = function (collaborator) {
        var _this = this;
        var collaboratorUrl = this.baseUrl + 'shares/' + collaborator.id + '/';
        var _collaborator = decamelizeKeysDeep(lodash_1.clone(collaborator));
        _collaborator.status = 'accepted';
        return this.http.put(collaboratorUrl, _collaborator, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    CollaboratorService.prototype.reject = function (collaboratorId) {
        var _this = this;
        var collaboratorUrl = this.baseUrl + 'shares/' + collaboratorId + '/';
        return this.http.put(collaboratorUrl, { status: 'declined' }, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    CollaboratorService.prototype.updateDays = function (jobEventId, collaborator) {
        var collaboratorUrl = this.baseUrl + 'jobevents/' + jobEventId + '/share/';
        collaborator.removeMissing = true;
        var _collaborator = decamelizeKeysDeep(collaborator);
        return this.http.post(collaboratorUrl, _collaborator, {
            headers: api_service_1.requestHeaders(), responseType: 'text'
        });
    };
    CollaboratorService.prototype.multiShare = function (jobId, model) {
        var collaboratorUrl = this.baseUrl + 'jobs/' + jobId + '/share/';
        var _model = decamelizeKeysDeep(lodash_1.clone(model));
        return this.http.post(collaboratorUrl, _model, {
            headers: api_service_1.requestHeaders(), responseType: 'text'
        });
    };
    CollaboratorService.prototype.bulkSave = function (jobId, collaborations) {
        if (collaborations === void 0) { collaborations = []; }
        var collaboratorUrl = this.baseUrl + 'jobs/' + jobId + '/jobevents/share/';
        collaborations.forEach(function (collaboration) {
            delete collaboration.jobEventOptions;
            delete collaboration.jobEventsLoading;
            if (collaboration.invoiceWeightUnit === 'load' || collaboration.invoiceWeightUnit === 'hour') {
                collaboration.invoiceWeightUnit = 'ton';
            }
        });
        return this.http.post(collaboratorUrl, {
            collaborators: decamelizeKeysDeep(collaborations)
        }, {
            headers: api_service_1.requestHeaders(), responseType: 'text'
        });
    };
    CollaboratorService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (collaborator) {
                collaborator = camelcaseKeysDeep(collaborator);
                return new collaborator_1.Collaborator(collaborator);
            });
        }
        else if (resObj) {
            return new collaborator_1.Collaborator(camelcaseKeysDeep(resObj));
        }
        else {
            return {};
        }
    };
    CollaboratorService.prototype.captureMetaData = function (res, paginated) {
        if (paginated === void 0) { paginated = true; }
        var json = res;
        if (paginated) {
            this.nextUri = json['next'];
        }
        this.count = json['count'] || json['results'] && json['results'].length;
        return json.results || json;
    };
    return CollaboratorService;
}());
exports.CollaboratorService = CollaboratorService;
