"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h2.title[_ngcontent-%COMP%]{font-size:22px;font-weight:400;color:#333;text-align:center;padding-top:50px}p.body[_ngcontent-%COMP%]{width:100%;text-align:center;font-size:14px;padding:0 20px;color:#72839b}.actions[_ngcontent-%COMP%]{display:flex;justify-content:center;gap:20px;margin:0 30px}.actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{flex:1}"];
exports.styles = styles;
