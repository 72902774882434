<collapsible-section [controlPosition]="controlPosition"
  [collapsed]="device && device.tablet" title="{{ 'Day Details' | translate }}">
  <div class="mobile-view-controls">
    <button [ngClass]="{'active': view === 'dayDetails'}" (click)="view = 'dayDetails'">Day Details</button>
    <button [ngClass]="{'active': view === 'orderSummary'}" (click)="view = 'orderSummary'">Order Summary</button>
  </div>
  <div *ngIf="jobEvent && view === 'dayDetails'" class="day-details">
    <div class="job-event-amounts">
      <h2 class="job-event-date readonly">
        <ng-container *ngIf="jobEvent.shift1StartTimestamp">{{ jobEvent.shift1StartTimestamp | date }}</ng-container>
        {{ 'Rates' | translate }}
      </h2>
      <div class="view-job-day" *ngIf="linkToJobEvent && job && jobEvent">
        <a [routerLink]="['/jobs', job.id, jobEvent.id]">View Job Day</a>
      </div>
      <div class="job-customer">
        <span class="value">{{ job.project.customerOrganization.name }}</span>
        <span class="label" translate>Customer</span>
      </div>
      <div class="job-rates">
        <div class="invoice-rate">
          <span class="value edit-link" (click)="openEditShare('invoice')">
            {{ jobEvent.rate | ratesCurrency : (jobEvent.invoiceRateUnit === 'cuyds' ? 'cu. yd.' : jobEvent.invoiceRateUnit) : 'USD' : 'symbol' : '1.2-4' }}
          </span>
          <span class="label" translate>Invoice Rate</span>
        </div>
        <div class="haul-rate">
          <span class="value edit-link" (click)="openEditShare('haul')">
            {{ jobEvent.haulRate | ratesCurrency : (jobEvent.haulRateUnit === 'cuyds' ? 'cu. yd.' : jobEvent.haulRateUnit) : 'USD' : 'symbol' : '1.2-4' }}
          </span>
          <span class="label" translate>Your Driver's Pay Rate</span>
        </div>
        <div class="max-trucks">
          <span class="value edit-link" (click)="openEditShare('trucks')">{{ jobEvent.numTrucks || 'No Limit' }}</span>
          <span class="label" translate># Trucks Allowed</span>
        </div>
        <div class="effective-rate" [matTooltip]="tooltipMessage" *ngIf="jobEvent.stats && jobEvent.stats.effectiveTonRate !== null">
          <div class="effective-rate-details" fxLayout="row" fxLayoutAlign="start center">
            <div>
              <!-- <span class="value edit-link" (click)="openEffectiveRate()">{{ (jobEvent.stats.effectiveTonRate || 0) | currency : 'USD' : 'symbol' : '1.2-4' }} / {{ jobEvent.stats.totalOrderedType }}</span> -->
              <span class="value edit-link" (click)="openEffectiveRate()">{{ (effectiveRate|| 0) | currency : 'USD' : 'symbol' : '1.2-2' }} / {{ effectiveRateUnit }}</span>
              <span class="label" translate>Effective Rate</span>
            </div>
            <div>
              <i class="icon-pencil edit" (click)="openEffectiveRate()"></i>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <div class="job-detail">
      <h2 class="heading" *ngIf="jobEvent && jobEvent.canEdit" (click)="openEditJobEvent()">{{ 'Job Details' | translate }} <i class="icomoon icon-pencil"></i></h2>
      <h2 class="heading readonly" *ngIf="!jobEvent || !jobEvent.canEdit">{{ 'Job Details' | translate }}</h2>
      <div class="job-event-status" *ngIf="jobEvent.cancelled">
        <job-status [status]="jobEvent.status" [statusText]="jobEvent.statusText"></job-status>
      </div>
      <div class="detail-field external-identifier" *ngIf="jobEvent.externalIdentifier">
        <span class="value">{{ jobEvent.externalIdentifier }}</span>
        <span class="label" translate>External Identifier</span>
      </div>
      <div class="detail-field pin" *ngIf="jobEvent.pin">
        <span class="value">{{ jobEvent.pin }}</span>
        <span class="label" translate>PIN</span>
      </div>
      <div class="detail-field job-date-range">
        <span class="value">
          <ng-container *ngIf="job.startDate">{{ job.startDate | date : 'MMM d, y' }}</ng-container> -
          <ng-container *ngIf="job.endDate">{{ job.endDate | date : 'MMM d, y' }}</ng-container>
        </span>
        <span class="label" translate>Project Date Range</span>
      </div>
      <div class="detail-field job-daily-schedule" *ngIf="jobEvent">
        <span class="value" *ngIf="jobEvent.shift1StartTimestamp">
          <ng-container *ngIf="jobEvent.shift1StartTimestamp">{{ jobEvent.shift1StartTimestamp | date: 'h:mm a' | lowercase }}</ng-container> -
          <ng-container *ngIf="jobEvent.jobEnd">{{ jobEvent.jobEnd | date: 'h:mm a' | lowercase }}</ng-container>
        </span>
        <span class="value" *ngIf="!jobEvent.shift1StartTimestamp" translate>None</span>
        <span class="label" translate>Daily Schedule</span>
      </div>
      <div class="shifts" *ngIf="jobEvent">
        <div class="detail-field" *ngIf="jobEvent && jobEvent.shift1StartTimestamp">
          <span class="value">
            <ng-container *ngIf="jobEvent.shift1StartTimestamp">{{ jobEvent.shift1StartTimestamp | date: 'h:mm a' | lowercase }}</ng-container> -
            <ng-container *ngIf="jobEvent.shift1EndTimestamp">{{ jobEvent.shift1EndTimestamp | date: 'h:mm a' | lowercase }}</ng-container>
          </span>
          <span class="label" translate>Shift 1</span>
        </div>
        <div class="detail-field" *ngIf="jobEvent && jobEvent.shift2StartTimestamp">
          <span class="value">
            <ng-container *ngIf="jobEvent.shift2StartTimestamp">{{ jobEvent.shift2StartTimestamp | date: 'h:mm a' | lowercase }}</ng-container> -
            <ng-container *ngIf="jobEvent.shift2EndTimestamp">{{ jobEvent.shift2EndTimestamp | date: 'h:mm a' | lowercase }}</ng-container>
          </span>
          <span class="label" translate>Shift 2</span>
        </div>
      </div>
      <div class="detail-field job-material">
        <span class="value">{{ job.material || '&nbsp;' }}</span>
        <span class="label" translate>Material</span>
      </div>
      <div class="detail-field truck-types">
        <span class="value">{{ jobEvent.truckTypeNames || '&nbsp;' }}</span>
        <span class="label" translate>Truck Types Requested</span>
      </div>
      <div class="detail-field loading-location" *ngIf="job.startLocationMappable && job.startLocation && (user.organization.id === job.startLocation.organization)">
        <a [routerLink]="['/locations', job.startLocation.id, 'edit']"
          [queryParams]="{ returnTo: '/jobs/' + job.id + '/' + jobEvent.id }">
          <span class="value">{{ job.startLocation.street }}</span>
          <span class="value">{{ job.startLocation.city }}, {{ job.startLocation.state }} {{ job.startLocation.zipcode }}</span>
          <span class="label" translate>Loading Location</span>
          <mat-icon title="Geofence set for location" *ngIf="job.startLocation.geofence">layers</mat-icon>
          <mat-icon color="warn" title="Geofence missing for location" *ngIf="!job.startLocation.geofence">layers_clear</mat-icon>
        </a>
      </div>
      <div class="detail-field loading-location" *ngIf="job.startLocationMappable && job.startLocation && (user.organization.id !== job.startLocation.organization)">
        <span class="value">{{ job.startLocation.street }}</span>
        <span class="value">{{ job.startLocation.city }}, {{ job.startLocation.state }} {{ job.startLocation.zipcode }}</span>
        <span class="label" translate>Loading Location</span>
        <mat-icon title="Geofence set for location" *ngIf="job.startLocation.geofence">layers</mat-icon>
        <mat-icon color="warn" title="Geofence missing for location" *ngIf="!job.startLocation.geofence">layers_clear</mat-icon>
      </div>
      <div class="detail-field loading-location" *ngIf="!job.startLocationMappable && job.startLocation">
        <a [routerLink]="['/locations', job.startLocation.id, 'edit']"
          [queryParams]="{ returnTo: '/jobs/' + job.id + '/' + jobEvent.id }">
          <span class="value link" translate>Add Address</span>
          <span class="label" translate>Loading Location</span>
        </a>
      </div>
      <div class="detail-field unloading-location" *ngIf="job.endLocationMappable && job.endLocation && (user.organization.id === job.endLocation.organization)">
        <a [routerLink]="['/locations', job.endLocation.id, 'edit']"
          [queryParams]="{ returnTo: '/jobs/' + job.id + '/' + jobEvent.id }">
          <span class="value">{{ job.endLocation.street }}</span>
          <span class="value">{{ job.endLocation.city }}, {{ job.endLocation.state }} {{ job.endLocation.zipcode }}</span>
          <span class="label" translate>Unloading Location</span>
          <mat-icon title="Geofence set for location" *ngIf="job.endLocation.geofence">layers</mat-icon>
          <mat-icon color="warn" title="Geofence missing for location" *ngIf="!job.endLocation.geofence">layers_clear</mat-icon>
        </a>
      </div>
      <div class="detail-field unloading-location" *ngIf="job.endLocationMappable && job.endLocation && (user.organization.id !== job.endLocation.organization)">
        <span class="value">{{ job.endLocation.street }}</span>
        <span class="value">{{ job.endLocation.city }}, {{ job.endLocation.state }} {{ job.endLocation.zipcode }}</span>
        <span class="label" translate>Unloading Location</span>
        <mat-icon title="Geofence set for location" *ngIf="job.endLocation.geofence">layers</mat-icon>
        <mat-icon color="warn" title="Geofence missing for location" *ngIf="!job.endLocation.geofence">layers_clear</mat-icon>
      </div>
      <div class="detail-field unloading-location" *ngIf="!job.endLocationMappable && job.endLocation">
        <a [routerLink]="['/locations', job.endLocation.id, 'edit']"
          [queryParams]="{ returnTo: '/jobs/' + job.id + '/' + jobEvent.id }">
          <span class="value link" translate>Add Address</span>
          <span class="label" translate>Unloading Location</span>
        </a>
      </div>
      <div class="detail-field job-specs">
        <div class="daily-delivery-target" *ngIf="(jobEvent && jobEvent.dailyDeliveryTarget) || job.dailyDeliveryTarget">
          <span class="value">
            {{ jobEvent && jobEvent.dailyDeliveryTarget || job.dailyDeliveryTarget }} {{ jobEvent && jobEvent.dailyDeliveryTargetType }}
          </span>
          <span class="label" translate>Daily Delivery Target</span>
        </div>

        <div class="delivery-interval" *ngIf="(jobEvent && jobEvent.deliveryInterval) || job.deliveryInterval">
          <span class="value" *ngIf="jobEvent.deliveryIntervalMinutes < 60">{{ jobEvent && jobEvent.deliveryIntervalMinutes || job.deliveryIntervalMinutes }} {{ 'minutes' | translate }}</span>
          <span class="value" *ngIf="jobEvent.deliveryIntervalMinutes >= 60">{{ jobEvent && jobEvent.deliveryIntervalHours || job.deliveryIntervalHours }} {{ 'hours' | translate }}</span>
          <span class="label" translate>Delivery Interval</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="jobEvent && view === 'dayDetails'" class="overall-job-production">
    <overall-job-production [job]="job" *ngIf="includeJobStats"></overall-job-production>
  </div>
  <order-summary-status *ngIf="view === 'orderSummary'" [jobEvent]="jobEvent"></order-summary-status>
</collapsible-section>
