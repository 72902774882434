import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone, sortBy } from 'lodash';

import { PunchCard } from './punch-card';
import { CondensedPunchCard } from './condensed-punch-card';
import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/api.service';
import { FieldOption, ExportDialogData } from '../shared/export-dialog/export-dialog.component';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class PunchCardService {
  baseUrl = environment.serverUrl;
  punchCard: PunchCard;
  condensedPunchCard: CondensedPunchCard;
  public nextUri;
  public count;
  progress$;
  progress;

  constructor (private http: HttpClient) { }

  getPunchCard(punchCardId: string) {
    let punchCardUrl = this.baseUrl + 'punchcards/' + punchCardId + '/';

    return this.http.get(punchCardUrl, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  save(punchCard) {
    const punchCardsUrl = this.baseUrl + 'punchcards/';
    const _punchCard = decamelizeKeysDeep(clone(punchCard));

    if (_punchCard.assignment && typeof _punchCard.assignment === 'object') {
      _punchCard.assignment = _punchCard.assignment.id;
    }
    if (typeof _punchCard.driver === 'object') {
      _punchCard.driver = _punchCard.driver.id;
    }
    if (typeof _punchCard.truck === 'object') {
      _punchCard.truck = _punchCard.truck.id;
    }
    if (typeof _punchCard.carrier === 'object') {
      _punchCard.carrier = _punchCard.carrier.id;
    }
    if (typeof _punchCard.job_event === 'object') {
      _punchCard.jobevent = _punchCard.job_event.id;
    }
    if (typeof _punchCard.jobevent === 'object') {
      _punchCard.jobevent = _punchCard.jobevent.id;
    }
    delete _punchCard.ticket_image;

    if (!punchCard.id) {
      _punchCard.start_time = _punchCard.start_time_timestamp || _punchCard._start_time_timestamp;
      _punchCard.end_time = _punchCard.end_time_timestamp || _punchCard._end_time_timestamp;
      delete _punchCard.id;
      return this.http.post(punchCardsUrl, _punchCard, { headers: requestHeaders() });
    } else {
      _punchCard.start_time = _punchCard.start_time_timestamp || _punchCard._start_time_timestamp;
      _punchCard.end_time = _punchCard.end_time_timestamp || _punchCard._end_time_timestamp;
      delete _punchCard.assignment;
      return this.http.put(punchCardsUrl + punchCard.id + '/', _punchCard, { headers: requestHeaders() });
    }
  }

  saveWithFile(punchCard, ticketImageFile: File = null) {
    if (punchCard) {
      let _punchCard = clone(punchCard);

      if (_punchCard.assignment && typeof _punchCard.assignment === 'object') {
        _punchCard.assignment = _punchCard.assignment.id;
      }
      if (_punchCard.driver && typeof _punchCard.driver === 'object') {
        _punchCard.driver = _punchCard.driver.id;
      }
      if (_punchCard.truck && typeof _punchCard.truck === 'object') {
        _punchCard.truck = _punchCard.truck.id;
      }
      if (_punchCard.jobEvent && typeof _punchCard.jobEvent === 'object') {
        _punchCard.jobevent = _punchCard.jobEvent.id;
        delete _punchCard.job_event;
      }
      if (_punchCard.carrier && typeof _punchCard.carrier === 'object') {
        _punchCard.carrier = _punchCard.carrier.id;
      }
      if (_punchCard.jobevent && typeof _punchCard.jobevent === 'object') {
        _punchCard.jobevent = _punchCard.jobevent.id;
      } else if (_punchCard.jobeventId) {
        _punchCard.jobevent = _punchCard.jobeventId;
      }

      delete _punchCard.job;
      delete _punchCard.ticketImage;
      delete _punchCard.assignment;
      delete _punchCard.endLocation;
      delete _punchCard.startLocation;
      delete _punchCard.void;
      if (_punchCard.id === undefined) { delete _punchCard.id; }

      _punchCard = decamelizeKeysDeep(_punchCard);
      _punchCard.start_time = _punchCard.start_time_timestamp || _punchCard._start_time_timestamp;
      _punchCard.end_time = _punchCard.end_time_timestamp || _punchCard._end_time_timestamp;

      return Observable.create(observer => {
        let formData: FormData = new FormData(),
          xhr: XMLHttpRequest = new XMLHttpRequest();

        Object.keys(_punchCard).map(function (key, index) {
          let value = _punchCard[key];
          formData.append(key, value);
        });
        if (ticketImageFile) {
          formData.append('ticket_image', ticketImageFile, ticketImageFile.name);
        }

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 201) {
              observer.next(JSON.parse(xhr.response));
              observer.complete();
            } else {
              observer.error(xhr.response);
            }
          }
        };

        if (_punchCard && _punchCard.id) {
          delete _punchCard.assignment;
          let punchCardUrl = this.baseUrl + 'punchcards/' + _punchCard.id + '/';
          xhr.open('PUT', punchCardUrl, true);
        } else {
          let punchCardUrl = this.baseUrl + 'punchcards/';
          xhr.open('POST', punchCardUrl, true);
        }
        requestHeaders(xhr);
        xhr.send(formData);
      });
    }
  }

  getPunchCards(query: any = null): Observable<PunchCard[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let punchCardsUrl = this.baseUrl + 'punchcards/';
    return this.http.get(punchCardsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getCondensedPunchCards(query: any = null): Observable<PunchCard[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString()) {
          params = params.set(key, query[key] && query[key].toString());
        }
      });
    }
    let punchCardsUrl = this.baseUrl + 'punchcards/condensed/';
    return this.http.get(punchCardsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  getCondensedPunchCard(punchCardId: string) {
    let punchCardUrl = this.baseUrl + 'punchcards/' + punchCardId + '/condensed/';

    return this.http.get(punchCardUrl, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  getExportFields() {
    let punchCardUrl = this.baseUrl + 'punchcards/pandas-export/';
    return this.http.get(punchCardUrl, { headers: requestHeaders() }).pipe(
      map(res => sortBy(<FieldOption[]>res, 'label')),
      catchError(handleError)
    );
  }

  export(scope: ExportDialogData['scope'], params: any = null) {
    let punchCardUrl = this.baseUrl + 'punchcards/pandas-export/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(punchCardUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  getNext(): Observable<PunchCard[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  getNextCondensed(): Observable<CondensedPunchCard[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res, true); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  public uploadImage(method: string, punchCardId: string[], propertyName: string, files: File[]): Observable<any> {
    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (let i = 0; i < files.length; i++) {
        formData.append(propertyName, files[i], files[i].name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };
      const url = this.baseUrl + 'punchcards/' + punchCardId + '/';

      xhr.open(method, url, true);
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }

  private extractData(res: Object, condensed = false) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (condensed) {
      return body.map(punchCard => {
        return new CondensedPunchCard(punchCard);
      });
    } else if (body) {
      return body.map(punchCard => {
        return new PunchCard(punchCard);
      });
    } else if (resObj) {
      return new PunchCard(resObj);
    } else {
      return {};
    }
  }
}
