import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Marketplace } from './marketplace';
import { MARKETPLACE } from './mock-marketplace';
import { requestHeaders, handleError } from '../shared/api.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class MarketplaceService {
  baseUrl = environment.serverUrl;
  public nextUri;
  progress$;
  progress;

  constructor(private http: HttpClient) { }

  getMockMarketplace() { return MARKETPLACE; }

  getMarketplace(marketplaceId: string) {
    let marketplaceUrl = this.baseUrl + 'marketplace/' + marketplaceId + '/';

    return this.http.get(marketplaceUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getMockMarketplaces(count: Number) {
    let marketplaces = [];

    for (let i = 1; i <= count; i++) {
      let marketplace = Object.assign({}, MARKETPLACE);
      marketplaces.push(marketplace);
    }
    return marketplaces;
  }

  getMarketplaces(query: any = null): Observable<Marketplace[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let marketplacesUrl = this.baseUrl + 'marketplace/';
    return this.http.get(marketplacesUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  save(model) {
    const uri = this.baseUrl + 'marketplace/';
    model = decamelizeKeysDeep(model);

    if ( ! model.id ) {
      return this.http.post(uri, model, {headers: requestHeaders()});
    } else {
      return this.http.put(uri + model.id + '/', model, {headers: requestHeaders()});
    }
  }

  remove(model) {
    const id = typeof model === 'string' ? model : model.id;
    return this.http.delete(this.baseUrl + 'marketplace/' + id + '/', {
      headers: requestHeaders()
    });
  }

  publish(model) {
    const uri = this.baseUrl + 'marketplace/' + model.data.shareId + '/publish/';
    model = decamelizeKeysDeep(model);
    return this.http.post(uri, model.data, {headers: requestHeaders()});
  }

  accept(model) {
    const uri = this.baseUrl + 'marketplace/' + model.shareId + '/accept/';

    model = decamelizeKeysDeep(model);
    return this.http.post(uri, model, {headers: requestHeaders()});
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    if (body) {
      return body.map(marketplace => {
        return new Marketplace(marketplace);
      });
    } else if (resObj) {
      return new Marketplace(resObj);
    }
  }

  getNext() {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()});
    } else {
      return null;
    }
  }
}
