import {
  Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

// libraries
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { clone, find as _find, findIndex, pull } from 'lodash';
import { Subscription } from 'rxjs';

// services
import { CustomFieldService } from '../../custom-fields/custom-field.service';
import { JobEventShareService } from '../../job-event-shares/job-event-share.service';
import { parseErrors } from '../../shared/api.service';
import { AuthenticationService } from '../../shared/index';
import { UnitsOfMeasureService } from '../../units/units-of-measure.service';

// types
import { CustomField, CustomFieldKind } from '../../custom-fields/custom-field';
import { JobLoad } from '../../dispatch/dispatch-by-job/job-load';
import { JobLoadService } from '../../dispatch/dispatch-by-job/job-load.service';
import { JobEventShare } from '../../job-event-shares/job-event-share';
import { JobEvent } from '../../job-events/job-event';

// components
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { AddCollaboratorsDialogComponent } from '../add-collaborators/add-collaborators-dialog.component';
import { EditCollaboratorsDialogComponent } from '../edit-collaborators/edit-collaborators-dialog.component';
import { ExtendCollaborationDialogComponent } from '../extend-collaboration-dialog.component';

// constants
import { DEFAULT_DIALOG_SIZE } from '../../app.constants';
import { CollaboratorService } from '../collaborator.service';
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS } from './columns';

@Component({
  selector: 'collaborator-list',
  templateUrl: './collaborator-list.component.html',
  styleUrls: ['./collaborator-list.component.scss'],
  providers: [JobLoadService]
})
export class CollaboratorListComponent implements OnInit {
  @Input() availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  @Input() displayedColumns = [...DISPLAYEDCOLUMNS()];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() jobEvent: JobEvent;
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  loading = false;
  user;
  userOrgId;
  jobOrgId;
  hasLoadListsEnabled = true;
  actionMenuDisabled = true;
  errors = [];
  confirmDialog: MatDialogRef<any>;
  tableConfig = {
    hasHeader: true,
    service: JobEventShareService,
    customHeight: true,
    query: {},
    collectionTitle: this.translationService.instant('Collaborators'),
    noResultsText: this.translationService.instant('a collaborator'),
    hasNoResultsAction: false,
    sortBy: 'created_at',
    sortDirection: 'desc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false }
    ]
  };
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Edit Selected'), action: 'edit', link: false },
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  originalRecords: JobEventShare[] = [];
  customFields: CustomField[] = [];
  brokerRateCodeKey: string;
  unitsOfMeasure: any[] = [];
  withRecords = false;
  loadListReq: Subscription;
  loadList: JobLoad[] = [];
  @ViewChild('fancyTable', { static: false }) fancyTable: FancyTableComponent;
  @ViewChild('collaboratorForm', { static: false }) collaboratorForm: NgForm;
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  modifyCollaboratorsCallback = () => {
    this.reloadRecords();
  }

  extendCollaborationCallback = (collaborator) => {
    if (this.fancyTable) { this.fancyTable.updateTable(collaborator); }
    this.reloadRecords();
  }

  constructor(
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private jobEventShareService: JobEventShareService,
    private collaboratorService: CollaboratorService,
    private customFieldService: CustomFieldService,
    private translationService: TranslateService,
    private unitsOfMeasureService: UnitsOfMeasureService,
    private jobLoadService: JobLoadService
  ) { }

  ngOnInit() {
    const hasLoadListsEnabled = this.authenticationService.hasLoadListsEnabled();
    this.hasLoadListsEnabled = hasLoadListsEnabled;
    this.setColumns(hasLoadListsEnabled);
    this.user = this.authenticationService.user();
    this.userOrgId = this.user && this.user.organization && this.user.organization.id;
    this.jobOrgId = this.jobEvent && this.jobEvent.ownerOrganization && this.jobEvent.ownerOrganization.id;
    this.tableConfig.query = {
      ...this.tableConfig.query, ...{
        jobevent: this.jobEvent && this.jobEvent.id
      }
    };
    this.getUnitsOfMeasure();
    this.getCustomFields();
    if (this.userOrgId && this.jobOrgId && this.userOrgId === this.jobOrgId) {
      // Do nothing
    } else {
      this.availableColumns = this.availableColumns.filter(column => column.key !== 'internal-notes');
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'internal-notes');
    }
    if (hasLoadListsEnabled) {
      this.getLoadList(this.jobEvent.id);
    }
  }

  setColumns(hasLoadListsEnabled: boolean): void {
    this.availableColumns = [...AVAILABLECOLUMNS(this.translationService, hasLoadListsEnabled)];
    this.displayedColumns = [...DISPLAYEDCOLUMNS(hasLoadListsEnabled)];
  }

  getLoadList(jobEventId: string): void {
    if (this.loadListReq && typeof this.loadListReq.unsubscribe === 'function') {
      this.loadListReq.unsubscribe();
    }
    this.jobLoadService.getLoads(jobEventId).subscribe(results => {
      this.loadList = results;
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  loadDropdownSelectionChanged(event: any, load: JobLoad, jobEventShare: JobEventShare): void {
    const loadSchedule: JobLoad[] = jobEventShare.loadSchedule;
    const existingLoad = loadSchedule.findIndex(l => l.loadNumber === load.loadNumber);
    if (event.checked) {
      if (existingLoad === -1) {
        loadSchedule.push(load);
      }
    } else {
      if (existingLoad > -1) {
        loadSchedule.splice(existingLoad, 1);
      }
    }
    loadSchedule.sort((a, b) => a.loadNumber - b.loadNumber);
  }

  isLoadScheduled(loadList: any[] = [], loadNumber: number): boolean {
    return loadList && loadList.length ? loadList.filter(l => l.loadNumber === loadNumber).length > 0 : false;
  }

  reloadRecords(): void {
    if (this.fancyTable) { this.fancyTable.getRecords(); }
  }

  openExtendCollaboration(jobEventShare: JobEventShare): void {
    const dialog = this.dialog.open(ExtendCollaborationDialogComponent, {
      width: '420px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.collaborator = jobEventShare;
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.callback = this.extendCollaborationCallback;
    }
  }

  toggleEditAction(jobEventShare: JobEventShare): void {
    const originalRecord = _find(this.originalRecords, { id: jobEventShare.id });
    if (jobEventShare.editing && originalRecord) {
      pull(this.originalRecords, originalRecord);
      if (this.fancyTable) { this.fancyTable.updateTable(originalRecord); }
    } else {
      this.originalRecords.push(clone(jobEventShare));
      jobEventShare.editing = true;
      if (!jobEventShare.requestedUnit) {
        jobEventShare.requestedUnit = this.unitsOfMeasure && _find(this.unitsOfMeasure, { key: 'trucks' });
      }
    }
  }

  deleteCollaborator(jobEventShare: JobEventShare): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, DEFAULT_DIALOG_SIZE);
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Collaborator?'),
      body: this.translationService.instant('This collaborator will be removed for this day only.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.jobEventShareService.removeCollaboration(
          this.jobEvent.id, jobEventShare.organizationId
        ).subscribe(() => {
          if (jobEventShare.status === 'pending') {
            this.fancyTable.removeRecord(jobEventShare.id);
          }
        }, err => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  openAddCollaborators() {
    const dialog = this.dialog.open(AddCollaboratorsDialogComponent, {
      width: '1100px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
      dialog.componentInstance.hasLoadListsEnabled = this.hasLoadListsEnabled;
      dialog.componentInstance.loadList = this.loadList;
      dialog.componentInstance.callback = this.modifyCollaboratorsCallback;
    }
  }

  openEditCollaborators(selectedShareIds: string[] = [], excludedShareIds: string[] = []): void {
    const dialog = this.dialog.open(EditCollaboratorsDialogComponent, {
      width: '790px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.allSelected = this.fancyTable.allSelected;
      dialog.componentInstance.selectedShareIds = selectedShareIds;
      dialog.componentInstance.excludedShareIds = excludedShareIds;
      dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
      dialog.componentInstance.hasLoadListsEnabled = this.hasLoadListsEnabled;
      dialog.componentInstance.loadList = this.loadList;
      dialog.componentInstance.callback = this.modifyCollaboratorsCallback;
    }
  }

  setSelectedAction(collaborator, option, form: NgForm): void {
    collaborator.invoiceType = option.value;
    if (form && form.controls && form.controls['invoice_rate']) {
      form.controls['invoice_rate'].markAsDirty();
    }
  }

  setSelectedBulkAction(option): void {
    let selectedShareIds, excludedShareIds;
    if (this.fancyTable) {
      if (!this.fancyTable.allSelected) {
        selectedShareIds = this.fancyTable.selection.selected.map(share => share.id);
      }
      if (this.fancyTable.allSelected) {
        excludedShareIds = this.fancyTable.exclusion.selected.map(share => share.id);
      }
    }

    switch (option.action) {
      case 'edit':
        this.openEditCollaborators(selectedShareIds, excludedShareIds);
        break;
    }
  }

  validMultipliers(jobEventShare: JobEventShare): boolean {
    jobEventShare.multipliers.forEach(multiplier => {
      if (multiplier.haulPercentage < 0 || multiplier.haulPercentage > 100) {
        this.errors = [
          this.translationService.instant('Ensure any percentages are between 0 and 100.')
        ];
        return false;
      }
    });
    return true;
  }

  submit(jobEventShare: JobEventShare, range = null): void {
    if (range === 'all') {
      jobEventShare['allDays'] = true;
    } else if (range === 'future') {
      jobEventShare['future'] = true;
    } else {
      jobEventShare['allDays'] = false;
      jobEventShare['future'] = false;
    }
    if (jobEventShare.multipliers && jobEventShare.multipliers.length > 0) {
      jobEventShare['invoiceType'] = this.jobEvent.invoiceType;
      if (this.validMultipliers(jobEventShare) === false) { return; }
    }
    if (!this.jobEvent || !this.jobEvent.id) { return; }
    jobEventShare.brokerRateCodeKey = this.brokerRateCodeKey;
    if (jobEventShare.invoiceType === 'weight') {
      jobEventShare.invoiceWeightUnit = jobEventShare.weightOption.value;
    }
    if (jobEventShare.requestedUnit && jobEventShare.requestedUnit.key === 'trucks') {
      jobEventShare.numTrucks = jobEventShare.requestedAmount;
    } else {
      jobEventShare.numTrucks = null;
    }
    const updatedJobEventShare = {
      ...jobEventShare,
      requestedUnit: jobEventShare.requestedUnit ? jobEventShare.requestedUnit.value : null
    };

    // jobEventShareService not working as it should - loadSchedule doesn't get saved.
    // using collaboratorService instead
    const collaborations: any[] = [
      {
        ...updatedJobEventShare.collaboration,
        ...updatedJobEventShare,
        jobevents: [this.jobEvent.id],
        loadSchedule: updatedJobEventShare.loadSchedule,
      },
    ];
    this.collaboratorService
      .bulkSave(this.jobEvent.job.id, collaborations)
      .subscribe(
        () => {
          jobEventShare.editing = false;
          this.loading = false;
        },
        (err) => {
          console.error(err);
          this.loading = false;
        }
      );
    // this.jobEventShareService.save(updatedJobEventShare).subscribe(() => {
    //   jobEventShare.editing = false;
    //   this.loading = false;
    // }, err => {
    //   console.error(err);
    //   this.loading = false;
    // });
  }

  selectionChanged(event): void {
    this.actionMenuDisabled = !event || (
      event && (!event.allSelected && event.selection.selected.length === 0)
    );
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  getCustomFields() {
    this.customFieldService.list({
      kind: CustomFieldKind.Share,
      display_name: 'Broker Rate Code',
      active: 'True'
    }).subscribe(fields => {
      this.customFields = fields;
      this.brokerRateCodeKey = this.customFields && this.customFields[0] && this.customFields[0].key;
      if (this.brokerRateCodeKey) {
        const noteIndex = findIndex(this.displayedColumns, 'notes');
        this.displayedColumns.splice(noteIndex, 0, 'broker-rate-code');
      }
    });
  }

  getUnitsOfMeasure() {
    this.unitsOfMeasureService.list().subscribe((units) => {
      this.unitsOfMeasure = units.map(unit => ({
        value: unit.id,
        label: unit.name,
        name: unit.name,
        key: unit.key,
        selected: false,
      }));

      this.fancyTable.records.forEach(record => {
        if (!record.requestedUnit) {
          record.requestedUnit = this.unitsOfMeasure && _find(this.unitsOfMeasure, { key: 'trucks' });
        }
      });
    });
  }

  mapCustomFields(event) {
    this.withRecords = event && event.data && event.data.length > 0 ? true : false;
    event.data.forEach(row => {
      row.brokerRateCode = row.customFieldData[this.brokerRateCodeKey];
      row.weightOptions = row.weightOptions.map(opt => ({...opt, selected: false}));
      row.unitsOfMeasure = [...this.unitsOfMeasure];
      if (row.requestedUnit === null) {
        row.requestedUnit = [...this.unitsOfMeasure].find(u => u.name === 'Trucks');
      }
    });
    this.expiredSharesToEnd();
  }

  expiredSharesToEnd() {
    const records = [...this.fancyTable.dataSource.data];
    let expiredShares = [];
    let activeShares = [];
    for (let i = 0; i < records.length; i++) {
      if (records[i].hasOwnProperty('status') && records[i]['status'] === 'expired') {
        expiredShares.push(records[i]);
      } else {
        activeShares.push(records[i]);
      }
    }
    this.fancyTable.dataSource.data = [...activeShares, ...expiredShares];
  }

  setSelectedRate(option, row) {
    row.weightOption = option;
  }

  setRequestedUnit(option, row) {
    row.requestedUnit = option;
  }

  onLoadlistDropdownClick(event) {
    event.stopPropagation();
    this.collaboratorForm.form.markAsTouched();
    this.collaboratorForm.form.markAsDirty();
  }
}
