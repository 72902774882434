"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./multiple-images-edit.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("@ngx-translate/core");
var i6 = require("../image-edit/image-edit.component.ngfactory");
var i7 = require("../image-edit/image-edit.component");
var i8 = require("./multiple-images-edit.component");
var styles_MultipleImagesEditComponent = [i0.styles];
var RenderType_MultipleImagesEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultipleImagesEditComponent, data: { "animation": [{ type: 7, name: "slideRightToLeft", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "500ms ease-in" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "500ms ease-in" }], options: null }], options: {} }] } });
exports.RenderType_MultipleImagesEditComponent = RenderType_MultipleImagesEditComponent;
function View_MultipleImagesEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MultipleImagesEditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "save-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "button", [["class", "btn btn-primary"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { loading: 0 }), i1.ɵdid(5, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { loading: 0 }), i1.ɵdid(7, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Save "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-primary"; var currVal_2 = _ck(_v, 4, 0, _co.loadingSave); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 6, 0, _co.loadingSave); var currVal_4 = "btn btn-primary"; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = ""; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.saveDisabled; _ck(_v, 1, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["editImagesContainer", 1]], null, 10, "div", [["class", "multiple-images-edit-container"]], [[24, "@slideRightToLeft", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "mi-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Attachments"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleVisibility() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_2)), i1.ɵdid(8, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 5); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.showOnSave; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MultipleImagesEditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "multiple-images-edit-container-no-animation"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_5)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 5); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MultipleImagesEditComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onLoadingImageChange($event, 0) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLoadingImages[0]; var currVal_1 = _ck(_v, 3, 0, _co.editable, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onLoadingImageChange($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _ck(_v, 3, 0, _co.editable, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary btn-add-img"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLoadingImageAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Upload Image "]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentLoadingImages.length !== 0) && (_co.currentLoadingImages[(_co.currentLoadingImages.length - 1)] === null)); _ck(_v, 0, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MultipleImagesEditComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "loading-tickets-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["translate", ""]], [[2, "required", null]], null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Loading Ticket Image(s)"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_9)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_10)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_11)), i1.ɵdid(11, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.currentLoadingImages.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.currentLoadingImages; _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.editable && (_co.currentLoadingImages.length > 0)); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.buttonLoadingRef; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requiredFields.loadingImages; _ck(_v, 1, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onLoadingSignatureImageChange($event, 0) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLoadingSignatureImages[0]; var currVal_1 = _ck(_v, 3, 0, _co.editable, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onLoadingSignatureImageChange($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _ck(_v, 3, 0, true, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary btn-add-img"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLoadingSignatureImageAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Upload Image "]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentLoadingSignatureImages.length !== 0) && (_co.currentLoadingSignatureImages[(_co.currentLoadingSignatureImages.length - 1)] === null)); _ck(_v, 0, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "loading-tickets-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["translate", ""]], [[2, "required", null]], null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Loading Signature"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_13)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_14)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_15)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.currentLoadingSignatureImages.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.currentLoadingSignatureImages; _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.editable && (_co.currentLoadingSignatureImages.length > 0)); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requiredFields.loadingSignature; _ck(_v, 1, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onUnloadingImageChange($event, 0) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUnloadingImages[0]; var currVal_1 = _ck(_v, 3, 0, _co.editable, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onUnloadingImageChange($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _ck(_v, 3, 0, _co.editable, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary btn-add-img"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUnloadingImageAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Upload Image "]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentUnloadingImages.length !== 0) && (_co.currentUnloadingImages[(_co.currentUnloadingImages.length - 1)] === null)); _ck(_v, 0, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MultipleImagesEditComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "loading-tickets-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["translate", ""]], [[2, "required", null]], null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Unloading Ticket Image(s)"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_17)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_18)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_19)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_20)), i1.ɵdid(11, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.currentUnloadingImages.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.currentUnloadingImages; _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.editable && (_co.currentUnloadingImages.length > 0)); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.buttonLoadingRef; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requiredFields.unloadingImages; _ck(_v, 1, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onUnloadingSignatureImageChange($event, 0) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUnloadingSignatureImages[0]; var currVal_1 = _ck(_v, 3, 0, _co.editable, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ticket-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "image-edit", [], null, [[null, "uploadData"], [null, "uploading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadData" === en)) {
        var pd_0 = (_co.onUnloadingSignatureImageChange($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("uploading" === en)) {
        var pd_1 = (_co.uploading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ImageEditComponent_0, i6.RenderType_ImageEditComponent)), i1.ɵdid(2, 4308992, null, 0, i7.ImageEditComponent, [i5.TranslateService], { image: [0, "image"], options: [1, "options"] }, { uploading: "uploading", uploadData: "uploadData" }), i1.ɵpod(3, { editable: 0, light: 1, showDelete: 2 })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _ck(_v, 3, 0, true, true, true); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MultipleImagesEditComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary btn-add-img"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUnloadingSignatureImageAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Upload Image "]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentUnloadingSignatureImages.length !== 0) && (_co.currentUnloadingSignatureImages[(_co.currentUnloadingSignatureImages.length - 1)] === null)); _ck(_v, 0, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "loading-tickets-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["translate", ""]], [[2, "required", null]], null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Unloading Signature"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_22)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_23)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_24)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.currentUnloadingSignatureImages.length; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.currentUnloadingSignatureImages; _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.editable && (_co.currentUnloadingSignatureImages.length > 0)); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.requiredFields.unloadingSignature; _ck(_v, 1, 0, currVal_0); }); }
function View_MultipleImagesEditComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_7)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_12)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_16)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_21)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoadingImages; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showLoadingSignatureImages; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.showUnloadingImages; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.showUnloadingSignatureImages; _ck(_v, 7, 0, currVal_3); }, null); }
function View_MultipleImagesEditComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { editImagesContainer: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleImagesEditComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["multipleImagesContent", 2]], null, 0, null, View_MultipleImagesEditComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.withAnimation && _co.open); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.withAnimation; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_MultipleImagesEditComponent_0 = View_MultipleImagesEditComponent_0;
function View_MultipleImagesEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "multiple-images-edit", [], null, null, null, View_MultipleImagesEditComponent_0, RenderType_MultipleImagesEditComponent)), i1.ɵdid(1, 704512, null, 0, i8.MultipleImagesEditComponent, [i1.Renderer2], null, null)], null, null); }
exports.View_MultipleImagesEditComponent_Host_0 = View_MultipleImagesEditComponent_Host_0;
var MultipleImagesEditComponentNgFactory = i1.ɵccf("multiple-images-edit", i8.MultipleImagesEditComponent, View_MultipleImagesEditComponent_Host_0, { withAnimation: "withAnimation", loadingImages: "loadingImages", unloadingImages: "unloadingImages", loadingSignatureImages: "loadingSignatureImages", unloadingSignatureImages: "unloadingSignatureImages", showLoadingImages: "showLoadingImages", showUnloadingImages: "showUnloadingImages", showLoadingSignatureImages: "showLoadingSignatureImages", showUnloadingSignatureImages: "showUnloadingSignatureImages", editable: "editable", buttonLoadingRef: "buttonLoadingRef", buttonUnloadingRef: "buttonUnloadingRef", showOnSave: "showOnSave", requiredFields: "requiredFields" }, { onLoadingImagesChange: "onLoadingImagesChange", onUnloadingImagesChange: "onUnloadingImagesChange", onLoadingSignatureImagesChange: "onLoadingSignatureImagesChange", onUnloadingSignatureImagesChange: "onUnloadingSignatureImagesChange", onSave: "onSave", onUploading: "onUploading" }, []);
exports.MultipleImagesEditComponentNgFactory = MultipleImagesEditComponentNgFactory;
