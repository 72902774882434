"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var JobInvitationFiltersDialogComponent = /** @class */ (function () {
    function JobInvitationFiltersDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.title = 'Filter Jobs';
        this.loading = false;
        this.errors = [];
        this.locations = [];
        this.locationsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.projects = [];
        this.projectsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.customers = [];
        this.customersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.model = {};
    }
    JobInvitationFiltersDialogComponent.prototype.ngOnInit = function () {
    };
    JobInvitationFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    JobInvitationFiltersDialogComponent.prototype.submit = function () {
        this.callback(this.model);
        this.dialogRef.close();
    };
    return JobInvitationFiltersDialogComponent;
}());
exports.JobInvitationFiltersDialogComponent = JobInvitationFiltersDialogComponent;
