"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var TicketSaveGuard = /** @class */ (function () {
    function TicketSaveGuard(router) {
        this.router = router;
    }
    TicketSaveGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        return component.checkUnsavedForNavigation();
    };
    return TicketSaveGuard;
}());
exports.TicketSaveGuard = TicketSaveGuard;
