"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h2[_ngcontent-%COMP%]{margin:0 0 15px}.form-block[_ngcontent-%COMP%]{margin-bottom:30px;display:flex;flex:1 1 auto;padding:30px;border-radius:4px;background-color:#fff;border:1px solid #e6eaee}.form-block[_ngcontent-%COMP%]   .left-side[_ngcontent-%COMP%]{flex:0 1 600px;padding-right:60px;margin-right:60px}.form-block[_ngcontent-%COMP%]   .right-side[_ngcontent-%COMP%]{flex:1 1 auto}.row[_ngcontent-%COMP%]{flex:1 1 100%;display:flex;margin-bottom:20px}.row[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{flex:1 1 auto;margin-right:20px}.row[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]:last-child{margin-right:0}.row[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{display:flex}.row[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]   .toggle-note[_ngcontent-%COMP%]{flex:0 0 370px;margin-right:80px}.row[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%] > input[type=checkbox][_ngcontent-%COMP%]{flex:0 0 38px;margin-right:40px;width:38px;margin-left:0}.row[_ngcontent-%COMP%]   .label-group[_ngcontent-%COMP%]{display:flex}.row[_ngcontent-%COMP%]   .label-group[_ngcontent-%COMP%] > label[_ngcontent-%COMP%]{flex:0 0 58px;margin-right:20px;width:58px}.row[_ngcontent-%COMP%]   .label-group[_ngcontent-%COMP%] > label[_ngcontent-%COMP%]:first-child{flex:0 0 370px;margin-right:80px}.toggle-note[_ngcontent-%COMP%]{font-size:14px;text-align:left;color:#848c98}.toggles[_ngcontent-%COMP%]{margin-right:38px}.user-data[_ngcontent-%COMP%]{padding:30px 30px 0;display:flex;flex-direction:column}.user-data[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354052;margin-bottom:5px}.user-data[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354052}.user-data[_ngcontent-%COMP%]   .user-form[_ngcontent-%COMP%]{display:flex;flex:1 0 auto;width:100%;height:auto}"];
exports.styles = styles;
