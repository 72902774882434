<div class="app-content">
  <my-toolbar title="{{ 'Surcharge Templates' | translate }}"></my-toolbar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   (columnChange)="columnsChanged($event)"
                   [config]="tableConfig" #surchargeTemplatesTable>
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'amount'">
              <div class="data">{{ (row.amount || 0) | currency:'USD':'symbol':'1.2-2' || '&#8211;' }}</div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value || '&#8211;' }}</div>
            <div class="value" *ngSwitchCase="'action'">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of row.filterOptions(tableConfig.menuOptions)">
                  <a *ngIf="option.link" mat-menu-item
                     [routerLink]="row.url(option.action)"
                     [queryParams]="{ returnTo: '/surcharge-templates' }">
                    {{ option.name }}
                  </a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Surcharge Templates</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>

        <filters-panel [(availableFilters)]="availableFilters" [(appliedFilters)]="appliedFilters" [(search)]="search"></filters-panel>

        <a mat-icon-button color="accent" class="add-button" (click)="openNewSurchargeTemplate()">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
