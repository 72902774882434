"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var job_event_service_1 = require("../../job-events/job-event.service");
var job_service_1 = require("../../jobs/job.service");
var trip_filter_service_1 = require("../../trips/trip.filter.service");
var EndOfDayFiltersDialogComponent = /** @class */ (function () {
    function EndOfDayFiltersDialogComponent(translate, dialogRef) {
        this.translate = translate;
        this.dialogRef = dialogRef;
        this.switchFilters = [];
        this.defaultDropdownConfig = {
            service: trip_filter_service_1.TripFilterService,
            serviceFunction: 'listFilters',
            searchKey: 'filter_search',
        };
        this.jobDropdownConfig = {
            service: job_service_1.JobService,
            selectText: this.translate.instant('Select Job'),
            loadingText: this.translate.instant('Loading Jobs...'),
            noResultsText: this.translate.instant('No Jobs'),
            searchable: true,
            group: true,
            groupBy: function (job) { return job.project.name; },
            groupProperty: 'project.name',
            sortBy: 'project__name,name,start_date',
            serializer: 'AllJobs',
            includeFullObject: true,
        };
        this.jobEventsConfig = {
            service: job_event_service_1.JobEventService,
            selectText: this.translate.instant('Select Job Event'),
            loadingText: this.translate.instant('Loading Job Events...'),
            noResultsText: this.translate.instant('No Job Events'),
            searchable: true,
            includeFullObject: true,
            nameProperty: 'jobNameWithDsOrderId',
            subtitleProperty: 'name',
            subtitle: true,
            disableSubtitleOnSelect: true,
            query: {
                shift1_start__gte: null,
                shift1_start__lte: null,
            },
        };
        this.projectDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: this.translate.instant('Select Project'), loadingText: this.translate.instant('Loading Projects...'), noResultsText: this.translate.instant('No Projects'), serviceFunctionScope: 'projects', getFilter: 'jobevent__job__project', query: { serializer: 'ProjectFilter' } });
        this.customerDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: this.translate.instant('Select Customer'), loadingText: this.translate.instant('Loading Customers...'), noResultsText: this.translate.instant('No Customers'), serviceFunctionScope: 'customers', getFilter: 'jobevent__customer_organization', query: { serializer: 'OrganizationFilter' } });
        this.carriers = [];
        this.payableCustomerDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: this.translate.instant('Select Customer'), loadingText: this.translate.instant('Loading Customers...'), noResultsText: this.translate.instant('No Customers'), serviceFunctionScope: 'customers', getFilter: 'jobevent__customer_organization', query: { serializer: 'OrganizationFilter' } });
    }
    EndOfDayFiltersDialogComponent.prototype.ngOnInit = function () {
        this.carriers = this.uniqueCarriers.slice();
        this.filters = this.appliedFilters.slice();
        this.switchFilters = this.appliedFilters.slice().filter(function (af) { return af.selected !== undefined && af.selected !== null; });
        var assignedTruckFilter = this.appliedFilters.find(function (f) { return f.key === 'assigned_truck'; });
        if (assignedTruckFilter.values && assignedTruckFilter.values.length) {
            this.assignedTruck =
                assignedTruckFilter.values &&
                    assignedTruckFilter.values[0] &&
                    assignedTruckFilter.values[0].value;
        }
        var carrierFilter = this.appliedFilters.find(function (f) { return f.key === 'carrier'; });
        if (carrierFilter.values && carrierFilter.values.length) {
            this.carriers = this.uniqueCarriers.map(function (c) {
                return carrierFilter.values.some(function (cf) { return cf.value === c.id; })
                    ? __assign({}, c, { selected: true }) : c;
            });
        }
        if (this.date) {
            var newDate = new Date(this.date);
            newDate.setDate(newDate.getDate() + 1);
            this.jobEventsConfig.query = __assign({}, this.jobEventsConfig.query, { shift1_start__gte: this.date.toISOString(), shift1_start__lte: newDate.toISOString() });
        }
    };
    EndOfDayFiltersDialogComponent.prototype.onSelect = function (filterType, e, property) {
        if (property === void 0) { property = 'name'; }
        switch (filterType) {
            case 'carrier':
                this.carriers = this.uniqueCarriers.map(function (uniqC) { return (__assign({}, uniqC, { selected: uniqC.id === e.id })); });
                break;
            default:
                break;
        }
        this.filters = this.filters.map(function (appliedFilter) {
            return appliedFilter.key === filterType
                ? __assign({}, appliedFilter, { values: [{ label: e[property], value: e.id }] }) : appliedFilter;
        });
    };
    EndOfDayFiltersDialogComponent.prototype.onSwitch = function (filter) {
        this.switchFilters = this.switchFilters.map(function (f) {
            return f.key === filter.key ? __assign({}, f, { selected: !f.selected }) : f;
        });
    };
    EndOfDayFiltersDialogComponent.prototype.onCarrierSearch = function (carrierName) {
        this.carriers = this.uniqueCarriers.filter(function (c) {
            return c.name.toLowerCase().includes(carrierName.toLowerCase());
        });
    };
    EndOfDayFiltersDialogComponent.prototype.submit = function () {
        var _this = this;
        var filters = this.filters.map(function (f) {
            var switchFilter = _this.switchFilters.find(function (sf) { return sf.key === f.key; });
            if (f.key === 'assigned_truck' &&
                _this.assignedTruck &&
                _this.assignedTruck.length) {
                f.values = [{ label: _this.assignedTruck, value: _this.assignedTruck }];
            }
            return switchFilter ? switchFilter : f;
        });
        this.callback(filters, this.carriers);
        this.dialogRef.close();
    };
    return EndOfDayFiltersDialogComponent;
}());
exports.EndOfDayFiltersDialogComponent = EndOfDayFiltersDialogComponent;
