"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notifications.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("ng2-charts");
var i3 = require("@angular/common");
var i4 = require("../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i5 = require("@angular/material/icon");
var i6 = require("../../shared/toolbar/toolbar.component.ngfactory");
var i7 = require("../../shared/toolbar/toolbar.component");
var i8 = require("@angular/router");
var i9 = require("../../shared/authentication.service");
var i10 = require("../../tags/tag.service");
var i11 = require("../../users/user.service");
var i12 = require("@ngx-translate/core");
var i13 = require("../control-reports-actionbar/control-reports-actionbar.component.ngfactory");
var i14 = require("../control-reports-actionbar/control-reports-actionbar.component");
var i15 = require("../../shared/fancy-table/fancy-table.component.ngfactory");
var i16 = require("../../shared/fancy-table/fancy-table.component");
var i17 = require("@angular/material/dialog");
var i18 = require("ng2-dragula");
var i19 = require("../../preferences/preference.service");
var i20 = require("../../shared/dropdown/dropdown.component.ngfactory");
var i21 = require("../../shared/dropdown/dropdown.component");
var i22 = require("../../shared/search/search.component.ngfactory");
var i23 = require("../../shared/search/search.component");
var i24 = require("../../shared/applied-filters/applied-filters.component.ngfactory");
var i25 = require("../../shared/applied-filters/applied-filters.component");
var i26 = require("./notifications.component");
var i27 = require("../services/notifications-report.service");
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
exports.RenderType_NotificationsComponent = RenderType_NotificationsComponent;
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "chart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "chart-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(3, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.barChartData.datasets; var currVal_1 = _co.barChartData.labels; var currVal_2 = _co.barChartOptions; var currVal_3 = _co.barChartType; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_NotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.name || "\u2014"); _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.row.lastPeriod; _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.row.currentPeriod; _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "value percChange"]], [[4, "background", null], [4, "color", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.row.ruleColor; var currVal_1 = _v.parent.parent.context.row.textColor; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.parent.context.row.percentageChange, "1.2-2")); _ck(_v, 2, 0, currVal_2); }); }
function View_NotificationsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value percChange"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N/A"]))], null, null); }
function View_NotificationsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_7)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notApplicable", 2]], null, 0, null, View_NotificationsComponent_8))], function (_ck, _v) { var currVal_0 = (_v.parent.context.row.percentageChange || (_v.parent.context.row.percentageChange === 0)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NotificationsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "value chart-opt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChartDialog(_v.parent.context.row) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["bar_chart"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_3)), i1.ɵdid(3, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_4)), i1.ɵdid(5, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_5)), i1.ɵdid(7, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_6)), i1.ɵdid(9, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_9)), i1.ɵdid(11, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.column; _ck(_v, 1, 0, currVal_0); var currVal_1 = "name"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "lastPeriod"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "currentPeriod"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "percentageChange"; _ck(_v, 9, 0, currVal_4); var currVal_5 = "chart"; _ck(_v, 11, 0, currVal_5); }, null); }
function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.PercentPipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { notificationsReportTable: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 31, "div", [["class", "app-content notifications-report"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "my-toolbar", [], null, null, null, i6.View_ToolbarComponent_0, i6.RenderType_ToolbarComponent)), i1.ɵdid(4, 245760, null, 0, i7.ToolbarComponent, [i8.Router, i9.AuthenticationService, i10.TagService, i11.UserService], { title: [0, "title"] }, null), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "control-reports-actionbar", [], null, null, null, i13.View_ControlReportsActionbarComponent_0, i13.RenderType_ControlReportsActionbarComponent)), i1.ɵdid(7, 49152, null, 0, i14.ControlReportsActionbarComponent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 25, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, [["container", 1]], null, 22, "div", [["class", "actions-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 21, "fancy-table", [], null, [[null, "searchChange"], [null, "onDataLoaded"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchChange" === en)) {
        var pd_1 = ((_co.search = $event) !== false);
        ad = (pd_1 && ad);
    } if (("onDataLoaded" === en)) {
        var pd_2 = (_co.getDataSource($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i15.View_FancyTableComponent_0, i15.RenderType_FancyTableComponent)), i1.ɵdid(13, 770048, [[1, 4], ["notificationsReportTable", 4]], 2, i16.FancyTableComponent, [i8.ActivatedRoute, i8.Router, i17.MatDialog, i1.Injector, i18.DragulaService, i19.PreferenceService, i9.AuthenticationService, i1.Renderer2], { availableColumns: [0, "availableColumns"], displayedColumns: [1, "displayedColumns"], search: [2, "search"], config: [3, "config"] }, { searchChange: "searchChange", onDataLoaded: "onDataLoaded" }), i1.ɵqud(603979776, 2, { columnTemplates: 0 }), i1.ɵqud(603979776, 3, { detailTemplates: 0 }), (_l()(), i1.ɵand(0, [[2, 2], ["columnTemplates", 2]], 2, 0, null, View_NotificationsComponent_2)), (_l()(), i1.ɵeld(17, 0, null, 0, 13, "div", [["header-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "dropdown", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.changeFrameSelection($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_DropdownComponent_0, i20.RenderType_DropdownComponent)), i1.ɵdid(20, 770048, [["framesDropdown", 4]], 0, i21.DropdownComponent, [i1.ElementRef, i1.ElementRef], { selectedOption: [0, "selectedOption"], config: [1, "config"], options: [2, "options"] }, { onSelect: "onSelect" }), (_l()(), i1.ɵeld(21, 0, null, null, 9, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "search-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "search", [], null, [[null, "searchTermChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTermChange" === en)) {
        var pd_0 = ((_co.search = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i22.View_SearchComponent_0, i22.RenderType_SearchComponent)), i1.ɵdid(24, 4440064, null, 0, i23.SearchComponent, [], { searchTerm: [0, "searchTerm"] }, { searchTermChange: "searchTermChange" }), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showChart = !_co.showChart) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 8536064, null, 0, i12.TranslateDirective, [i12.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(27, null, [" ", " "])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["class", "btn btn-default"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openThresholdsDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 8536064, null, 0, i12.TranslateDirective, [i12.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Thresholds Settings "])), (_l()(), i1.ɵeld(31, 0, null, 1, 2, "div", [["applied-filter-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "applied-filters", [], null, [[null, "searchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchChange" === en)) {
        var pd_0 = ((_co.search = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i24.View_AppliedFiltersComponent_0, i24.RenderType_AppliedFiltersComponent)), i1.ɵdid(33, 638976, null, 0, i25.AppliedFiltersComponent, [], { search: [0, "search"] }, { searchChange: "searchChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Notifications Report")), ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.showChart; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.availableColumns; var currVal_3 = _co.displayedColumns; var currVal_4 = _co.search; var currVal_5 = _co.tableConfig; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.currentTimeFrame; var currVal_7 = _co.frameDropdownData.config; var currVal_8 = _co.frameDropdownData.frames; _ck(_v, 20, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.search; _ck(_v, 24, 0, currVal_9); var currVal_10 = ""; _ck(_v, 26, 0, currVal_10); var currVal_12 = ""; _ck(_v, 29, 0, currVal_12); var currVal_13 = _co.search; _ck(_v, 33, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_11 = (_co.showChart ? "Hide Chart" : "Show Chart"); _ck(_v, 27, 0, currVal_11); }); }
exports.View_NotificationsComponent_0 = View_NotificationsComponent_0;
function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i26.NotificationsComponent, [i9.AuthenticationService, i27.NotificationsReportService, i19.PreferenceService, i12.TranslateService, i17.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NotificationsComponent_Host_0 = View_NotificationsComponent_Host_0;
var NotificationsComponentNgFactory = i1.ɵccf("notifications", i26.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
exports.NotificationsComponentNgFactory = NotificationsComponentNgFactory;
