import { BulkEditJobEvent } from './bulk-edit-job-event';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class BulkEditJobEventsSerializer {
    fromJson(json: any): BulkEditJobEvent {
        json = camelcaseKeysDeep(json);
    return json;
    }

    toJson(resource: BulkEditJobEvent) {
        let editModelData = { } as BulkEditJobEvent;

        if (resource.include && resource.include.length) {
            editModelData.include = resource.include
        }
        
        if (resource.exclude && resource.exclude.length) {
            editModelData.exclude = resource.exclude
        }

        if (resource.cancelled) {
            editModelData.cancelled = resource.cancelled
        }
        
        if (resource.allowAnyTruckType) {
            editModelData.allowAnyTruckType = resource.allowAnyTruckType
        }
        
        if (resource.dailyDeliveryTarget) {
            editModelData.dailyDeliveryTarget = resource.dailyDeliveryTarget
        }
        
        if (resource.dailyDeliveryTargetType) {
            editModelData.dailyDeliveryTargetType = resource.dailyDeliveryTargetType
        }
        
        if (resource.defaultYardBufferTime) {
            editModelData.defaultYardBufferTime = resource.defaultYardBufferTime
        }
        
        if (resource.numTrucks) {
            editModelData.numTrucks = resource.numTrucks
        }

        return decamelizeKeysDeep(editModelData);
    }
}