"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".job-stats[_ngcontent-%COMP%]{position:absolute;top:55px;left:10px;width:340px;height:220px;padding:10px 20px;border-radius:4px;background-color:#fff;border:1px solid #dfe2e5}.job-stats[_ngcontent-%COMP%]   .last-updated[_ngcontent-%COMP%]{text-align:center;display:block;color:#7f8fa4;font-size:12px;margin-bottom:6px}.job-stats[_ngcontent-%COMP%]   .main-stats[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:space-between}.job-stats[_ngcontent-%COMP%]   .secondary-stats[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap;margin-top:4px}.job-stats[_ngcontent-%COMP%]   .secondary-stats[_ngcontent-%COMP%] > p[_ngcontent-%COMP%]{width:100%}.job-stats[_ngcontent-%COMP%]   .secondary-stats[_ngcontent-%COMP%]   .calculated-stat[_ngcontent-%COMP%]{margin-top:10px;width:50%}.job-stats[_ngcontent-%COMP%]   .job-value[_ngcontent-%COMP%]{font-size:28px;font-weight:500;color:#002649;margin-right:12px}.job-stats[_ngcontent-%COMP%]   .actual-values[_ngcontent-%COMP%]{font-size:14px;color:#354052}.job-stats[_ngcontent-%COMP%]   .estimated-values[_ngcontent-%COMP%], .job-stats[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-weight:500;font-size:14px;color:#7f8fa4;margin:0}.job-stats[_ngcontent-%COMP%]   .estimated-values.green[_ngcontent-%COMP%], .job-stats[_ngcontent-%COMP%]   p.green[_ngcontent-%COMP%]{color:#4ece3d}.job-stats[_ngcontent-%COMP%]   .bold[_ngcontent-%COMP%], .job-stats[_ngcontent-%COMP%]   b[_ngcontent-%COMP%]{font-weight:700;color:#354052}.job-stats[_ngcontent-%COMP%]   .dispatch-count[_ngcontent-%COMP%]{border-top:1px solid #dfe2e5;font-size:14px;font-weight:700;color:#354052;margin-top:6px;padding-top:6px}.job-stats.mobile[_ngcontent-%COMP%]{top:0;left:0;right:0;width:auto;padding:14px;border-radius:0}"];
exports.styles = styles;
