<dropdown #dropdownComponent
  title="{{ loading ? config.loadingText : config.selectText }}"
  [ngClass]="{'small': config.small}"
  [open]="open"
  [icon]="icon"
  [toggle]="toggle"
  [disabled]="disabled"
  [loading]="loading"
  [config]="config"
  [options]="options"
  [selectedItems]="selectedItems"
  [selectedOption]="selectedOption"
  (onSelect)="toggleOption($event)"
  (onSearch)="changeSearch($event)"
  (nextPage)="onScroll()">
</dropdown>
