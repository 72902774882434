<div mat-dialog-title class="modal-title">
  <h1 translate>Ticket Note</h1>
  <div class="close-action" (click)="dialogRef.close()">
    <i class="icomoon icon-close"></i>
  </div>
</div>
<div class="dialog-content" mat-dialog-content>
  <div class="content-title" translate>
    Leave a note on this ticket which can be seen by other team members.
  </div>
  <div class="driver-note">
    <textarea
      [(ngModel)]="newNote"
      [maxlength]="maxlength"
      [disabled]="disabled"
      name="note"
    ></textarea>
  </div>
  <div class="character-limit">
    <span>{{ maxlength - newNote.length }}/{{ maxlength }} </span>
    <span translate>character limit</span>
  </div>
</div>
<div class="modal-footer">
  <button
    [disabled]="disabled || newNote === note"
    (click)="onSave()"
    class="btn btn-primary"
    translate
  >
    Save Note
  </button>
</div>
