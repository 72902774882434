import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formattedDuration'
})
export class FormattedDurationPipe implements PipeTransform {
  transform(startTime, currentTime: moment.Moment = moment()): string {
    if (!currentTime) { currentTime = moment(); }
    let duration = moment.duration(moment(currentTime).diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }
}
