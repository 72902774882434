import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { FilterOption } from '../../shared/filters-panel/filter-option';
import { UnitService } from '../../units/unit.service';
import { RateTypeService } from '../../rate-types/rate-type.service';
import { BillingRateService } from './billing-rate.service';

@Component({
  selector: 'billing-rates',
  templateUrl: './billing-rates.component.html',
  styleUrls: ['./billing-rates.component.scss']
})
export class BillingRatesComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'code', title: this.translationService.instant('Code'), sortable: true },
    { key: 'description', title: this.translationService.instant('Description') },
    { key: 'rateType', title: this.translationService.instant('Rate Type') },
    { key: 'unit', title: this.translationService.instant('Unit') },
    { key: 'rate', title: this.translationService.instant('Rate') },
    { key: 'actions' }
  ];
  @Input() displayedColumns = ['select', 'code', 'description', 'actions'];
  @Input() availableFilters = [
    // new FilterOption({
    //   key: 'rate_group', title: 'Rate Types', filterType: 'select',
    //   multiple: true, service: RateTypeService
    // }),
    new FilterOption({
      key: 'rate_type', title: this.translationService.instant('Rate Types'),
      filterType: 'autocomplete', multiple: true, service: RateTypeService
    }),
    new FilterOption({
      key: 'unit', title: this.translationService.instant('Units'),
      filterType: 'select', multiple: true, service: UnitService
    }),
    // new FilterOption({
    //   key: 'unit_boolean', title: this.translationService.instant('Units'),
    //   filterType: 'checkbox'
    // })
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  tabs = [
    { id: 'primary', name: this.translationService.instant('Primary Rates') },
    { id: 'secondary', name: this.translationService.instant('Secondary Rates') }
  ];
  errors = [];
  tableConfig = {
    service: BillingRateService,
    query: {rate_group: 'primary'},
    collectionTitle: this.translationService.instant('Rates'),
    noResultsText: this.translationService.instant('a rate'),
    newRecordRoute: ['/billing-rates/new'],
    sortBy: 'code',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
    ],
    newRecordModal: () => { this.openNewRate(); }
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplate', { static: false }) columnTemplate: TemplateRef<any>;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  constructor(private translationService: TranslateService) {}

  ngOnInit() {}

  tabChanged(tab: MatTabChangeEvent): void {
    let selectedTab = this.tabs[tab.index];
    if (selectedTab) {
      this.tableConfig.query = Object.assign(
        this.tableConfig.query,
        { rate_group: selectedTab.id }
      );
    }
  }

  openNewRate() {
    //
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
