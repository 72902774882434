"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TitleCasePipe = /** @class */ (function () {
    function TitleCasePipe() {
    }
    TitleCasePipe.prototype.transform = function (input) {
        if (!input) {
            return '';
        }
        else {
            input = input.replace(/_/g, ' ');
            input = input.replace(/([A-Z])/g, ' $1');
            return input.replace(/\w\S*/g, (function (txt) { return txt[0].toUpperCase() + txt.substr(1).toLowerCase(); }));
        }
    };
    return TitleCasePipe;
}());
exports.TitleCasePipe = TitleCasePipe;
