"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fleet_health_stats_1 = require("./fleet-health-stats");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var FleetHealthStatsSerializer = /** @class */ (function () {
    function FleetHealthStatsSerializer() {
    }
    /**
     * @param  {any} json
     * @returns {FleetHealthStats} FleetHealthStats
     */
    FleetHealthStatsSerializer.prototype.fromJson = function (json) {
        // otherwise string to number (1.10.2 === 1102)
        var applicationVersions = json && json.application_versions ? json.application_versions : [];
        json = camelcaseKeysDeep(json);
        var fleetHealthStats = new fleet_health_stats_1.FleetHealthStats();
        if (!json) {
            return fleetHealthStats;
        }
        fleetHealthStats.onShiftCount = json.onShiftCount;
        fleetHealthStats.driversWithIssues = json.driversWithIssues;
        fleetHealthStats.driverCount = json.driverCount;
        fleetHealthStats.applicationVersions = applicationVersions;
        return fleetHealthStats;
    };
    /**
     * @param  {FleetHealthStats} fleetHealthStats
     * @returns any
     */
    FleetHealthStatsSerializer.prototype.toJson = function (fleetHealthStats) {
        var json = {
            onShiftCount: fleetHealthStats.onShiftCount,
            driversWithIssues: fleetHealthStats.driversWithIssues,
            driverCount: fleetHealthStats.driverCount,
            applicationVersions: fleetHealthStats.applicationVersions,
        };
        return decamelizeKeysDeep(json);
    };
    return FleetHealthStatsSerializer;
}());
exports.FleetHealthStatsSerializer = FleetHealthStatsSerializer;
