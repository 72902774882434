import {
  Injectable, NgModule, ApplicationRef, ErrorHandler
} from '@angular/core';
import {
  HammerGestureConfig, HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/browser';

import { RuckitButtonComponent } from './shared/buttons/index';
import { RuckitConfirmDialogComponent } from './shared/dialogs/index';

import { InvitationComponent } from './invitation/invitation.component';
import { DriverInvitationComponent } from './invitation/driver-invitation.component';
import { InvitationsComponent } from './invitations/invitations.component';
import { OrganizationInvitationComponent } from './invitation/organization-invitation.component';
import { AB5QuestionsDialogComponent } from './invitation/ab5-questions/ab5-questions-dialog.component';

import { ConnectionsComponent } from './connections/connections.component';
import { ConnectionsListComponent } from './connections/connections-list.component';
import { ConnectionDialogComponent } from './connections/connection-dialog.component';

import { EditShiftsDialogComponent } from './shifts/edit-shifts/edit-shifts-dialog.component';
import { NewShiftDialogComponent } from './shifts/new-shift-dialog/new-shift-dialog.component';
import { RuckitAddEmployeeDialogComponent } from './team/add-employee-dialog.component';
import { ExportDialogComponent } from './shared/export-dialog/export-dialog.component';
import { NewTruckDialogComponent } from './trucks/new-truck-dialog.component';
import { NewDriverDialogComponent } from './drivers/new-driver-dialog/new-driver-dialog.component';
import { NewTrackableDialogComponent } from './trackables/new-trackable-dialog.component';
import { InviteDriverDialogComponent } from './drivers/invite-driver-dialog//invite-driver-dialog.component';
import { JobFiltersDialogComponent } from './jobs/job-filters-dialog.component';
import { JobInvitationFiltersDialogComponent } from './job-invitations/job-invitation-filters-dialog.component';
import { DailyBoardFiltersDialogComponent } from './daily-board/daily-board-filters-dialog.component';
import { RuckitForgotPasswordDialogComponent } from './shared/forgot-password-dialog/forgot-password-dialog.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';
import { TagDisplayComponent } from './shared/tag-display/tag-display.component';

// Fleet
import { FleetComponent } from './fleet/fleet.component';
import { FleetHealthComponent } from './fleet/health/fleet-health.component';
import { FleetHealthStatsComponent } from './fleet/health-stats/fleet-health-stats/fleet-health-stats.component';

import { DriversComponent } from './drivers/drivers.component';
import { TrucksComponent } from './trucks/trucks.component';
import { EditTruckComponent } from './trucks/edit-truck.component';
import { EditTrackableComponent } from './trackables/edit-trackable.component';
import { TrackablesComponent } from './trackables/trackables.component';
import { EditDriverComponent } from './drivers/driver-edit/edit-driver.component';
import { DailyBoardComponent } from './daily-board/daily-board.component';
import { DailyBoardGridComponent } from './daily-board/daily-board-grid/daily-board-grid.component';
import { DailyBoardListComponent } from './daily-board/daily-board-list/daily-board-list.component';
import { CollaboratorStatsComponent } from './daily-board/collaborator-stats/collaborator-stats.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectFiltersDialogComponent } from './projects/project-filters-dialog.component';
import { JobInvitationsComponent } from './job-invitations/job-invitations.component';
import { NewProjectComponent } from './project-list/new-project.component';
import { TripFiltersDialogComponent } from './trips/trip-filters-dialog.component';
import { PunchCardFiltersDialogComponent } from './punch-cards/punch-card-filters-dialog.component';
import { NewCustomerDialogComponent } from './customers/new-customer-dialog.component';
import { AssignmentErrorDialogComponent } from './messages/assignment-error-dialog.component';
import { AssignmentTruckManagementDialogComponent } from './messages/assignment-truck-management-dialog/assignment-truck-management-dialog.component';
import { AssignTruckDialogComponent } from './drivers/assign-truck-dialog/assign-truck-dialog.component';
import { DriverNameComponent } from './drivers/driver-name/driver-name.component';
import { DriverInfoboxComponent } from './drivers/driver-infobox/driver-infobox.component';
import { TruckTypeNamesComponent } from './trucks/truck-type-names.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { SampleDialogComponent } from './styleguide/styleguide.component';
import { TeamComponent } from './team/team.component';
import { DriverDetailsPage } from './drivers/driver-details-page/driver-details-page.page';
import { DriverDetailsComponent } from './drivers/driver-details/driver-details.component';
import { DriverAllTrips } from './drivers/driver-all-trips/driver-all-trips.component';
import { DriverTripFiltersDialogComponent } from './drivers/driver-trip-filters-dialog/driver-trip-filters-dialog.component';
import { DriverGeoTripsComponent } from './drivers/driver-geotrips/driver-geotrips.component';
import { DriverPunchCardsComponent } from './drivers/driver-punch-cards/driver-punch-cards.component';
import { DriverShiftsComponent } from './drivers/driver-shifts/driver-shifts.component';

// Dispatch
import { DispatchModule } from './dispatch/dispatch.module';
import { EditJobEventDialogComponent } from './job-events/edit-job-event-dialog.component';

import { EditPunchCardDialogComponent } from './punch-cards/edit-punch-card-dialog.component';
import { NewPunchCardDialogComponent } from './punch-cards/new-punch-card-dialog.component';
import { NewTripDialogComponent } from './trips/new-trip-dialog/new-trip-dialog.component';
import { ViewTicketsDialogComponent } from './checkins/view-tickets-dialog.component';
import { ViewSignaturesDialogComponent } from './checkins/view-signatures-dialog.component';
import { ViewPunchCardTicketDialogComponent } from './punch-cards/view-punch-card-ticket-dialog.component';
import { TripTimesComponent } from './trips/trip-times.component';

// Jobs
import { NewJobComponent } from './jobs/new-job.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobDetailComponent } from './jobs/job-detail.component';
import { JobMapComponent } from './map/job-map/job-map.component';
import { CancelJobDialogComponent } from './jobs/cancel-job-dialog.component';
import { SendJobDialogComponent } from './jobs/send-job-dialog.component';
import { PublishJobDialogComponent } from './jobs/publish-job-dialog.component';
import { EditJobDialogComponent } from './jobs/edit-job-dialog.component';
import { AcceptJobDialogComponent } from './jobs/accept-job-dialog.component';
import { EditSLOrderIDDialogComponent } from './jobs/edit-slorder-id-dialog.component';
import { EditJobComponent } from './jobs/edit-job.component';
import { HaulerSharesComponent } from './jobs/hauler-shares/hauler-shares.component';
import { NewJobReportDialogComponent } from './driver-reports/new-job-report-dialog.component';
import { EffectiveRateDialogComponent } from './jobs/effective-rate-dialog.component';

// Locations
import { NewLocationDialogComponent } from './locations/new-location-dialog.component';
import { EditLocationDialogComponent } from './locations/edit-location-dialog.component';

// Organizations
import { EditOrganizationComponent } from './organizations/edit-organization.component';
import { EditOrganizationPermissionsComponent } from './organizations/edit-organization-permissions.component';
import { EditOrganizationMarketsComponent } from './organizations/edit-organization-markets.component';
import { OrganizationMarketDialogComponent } from './organizations/organization-market-dialog.component';

// Messages
import { MessageDriversDialogComponent } from './messages/message-drivers-dialog.component';

// Users
import { EditUserComponent } from './users/edit-user.component';
import { EditUserNotificationsComponent } from './users/edit-user-notifications.component';
import { EditUserPreferencesComponent } from './users/edit-user-preferences.component';
import { EditUserWorkOrderSettingsComponent } from './users/edit-user-work-order-settings.component';

// Invoices
import { EditInvoiceComponent } from './invoices/edit-invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceComponent } from './invoices/invoice.component';
import { CreateInvoiceComponent } from './invoices/create-invoice.component';
import { NewInvoiceDialogComponent } from './invoices/new-invoice-dialog.component';
import { InvoiceFiltersDialogComponent } from './invoices/invoice-filters-dialog.component';
import { InvoiceTripsDialogComponent } from './invoices/invoice-trips-dialog.component';
import { InvoicePunchCardsDialogComponent } from './invoices/invoice-punch-cards-dialog.component';
import { SentTripsComponent } from './invoices/sent-trips.component';
import { SentPunchCardsComponent } from './invoices/sent-punch-cards.component';
import { SentSurchargesComponent } from './invoices/sent-surcharges.component';

// Expenses
import { ExpenseTripsDialogComponent } from './expenses/expense-trips-dialog.component';
import { ExpensePunchCardsDialogComponent } from './expenses/expense-punch-cards-dialog.component';
import { ExpenseFiltersDialogComponent } from './expenses/expense-filters-dialog.component';
import { ExpensePayTypeDialogComponent } from './expenses/expense-pay-type-dialog.component';

// Paystubs
import { PaystubTripsDialogComponent } from './paystubs/paystub-trips-dialog.component';
import { PaystubPunchCardsDialogComponent } from './paystubs/paystub-punch-cards-dialog.component';
import { PaystubFiltersDialogComponent } from './paystubs/paystub-filters-dialog.component';
import { PaystubPayTypeDialogComponent } from './paystubs/paystub-pay-type-dialog.component';

// Marketplace
import { MarketplaceNewProjectDialogComponent } from './marketplace/marketplace-new-project-dialog.component';

// Trips
import { EditTripDialogComponent } from './trips/edit-trip-dialog/edit-trip-dialog.component';
import { CompletedTripsComponent } from './trips/completed-trips/completed-trips.component';

// Punch Cards
import { CompletedPunchCardsComponent } from './punch-cards/completed-punch-cards.component';

// Surcharges
import { SurchargeDialogComponent } from './surcharges/surcharge-dialog.component';
import { SurchargeTemplateDialogComponent } from './surcharge-templates/surcharge-template-dialog.component';

// Collaborators
import { ExtendCollaborationDialogComponent } from './collaborators/extend-collaboration-dialog.component';
import { EditShareDialogComponent } from './collaborators/edit-share-dialog.component';
import { NewDaysCollaborationDialogComponent } from './collaborators/new-days-collaboration-dialog.component';
import { AllCollaboratorsComponent } from './collaborators/all-collaborators/all-collaborators.component';
import { CollaboratorJobsComponent } from './collaborators/collaborator-jobs/collaborator-jobs.component';

// Assignments
import { AssignmentBubblesComponent } from './assignments/assignment-bubbles.component';
import { PunchCardAssignmentsComponent } from './assignments/punch-card-assignments.component';
import { TripAssignmentsComponent } from './assignments/trip-assignments.component';
import { ConfirmAssignmentComponent } from './assignments/confirm-assignment.component';
import { AutoAssignConflictDialogComponent } from './assignments/auto-assign-conflict-dialog.component';
import { AllDriverAssignmentsComponent } from './assignments/all-driver-assignments/all-driver-assignments.component';
import { AssignmentTripsComponent } from './assignments/assignment-trips.component';
import { DriverMapComponent } from './assignments/driver-map.component';
import { NoDriverAssignmentsComponent } from './assignments/no-driver-assignments/no-driver-assignments.component';

// Settings
import { SettingsComponent } from './settings/settings.component';

// Dashboard
import { DashboardComponent } from './dashboard/dashboard.component';
import { WidgetComponent } from './widgets/widget.component';
import { NeedsDispatchedComponent } from './widgets/needs-dispatched/needs-dispatched.component';
import { DailyProductionComponent } from './widgets/daily-production/daily-production.component';
import { AssignmentAcceptanceComponent } from './widgets/assignment-acceptance/assignment-acceptance.component';
import { LocationStatusComponent } from './widgets/location-status/location-status.component';
import { ActiveTrucksComponent } from './widgets/active-trucks/active-trucks.component';
import { DailyShiftsComponent } from './widgets/daily-shifts/daily-shifts.component';
import { EsbUploaderComponent } from './widgets/esb-uploader/esb-uploader.component';

import { AuthGuard } from './guards/auth.guard';
import { TicketSaveGuard } from './guards/scaleit-ticket-save.guard';
import { DriverGuard } from './guards/driver.guard';
import { AnyoneGuard } from './guards/anyone.guard';
import { LeasedOrgsGuard } from './guards/leased-orgs.guard';
import { DispatchGuard } from './guards/dispatch.guard';

// Mobile
import { MobileJobOverviewComponent } from './jobs/mobile-job-overview.component';
import { MobileAssignmentDetailsComponent } from './jobs/mobile-assignment-details.component';
import { MapStatusMarkerComponent } from './jobs/map-status-marker.component';

// Services
import { ApiService } from './shared';
import { AuthenticationService } from './shared';
import { InvitationService } from './invitation/invitation.service';
import { TicketService } from './tickets/ticket.service';
import { TruckService } from './trucks/truck.service';
import { TruckTypeService } from './trucks/truck-type.service';
import { TrackableService } from './trackables/trackable.service';
import { TrackableTypeService } from './trackable-types/trackable-type.service';
import { DriverService } from './drivers/driver.service';
import { TripService } from './trips/trip.service';
import { TripFilterService } from './trips/trip.filter.service';
import { CondensedTripService } from './trips/condensed-trip.service';
import { ScaleService } from './scales/scale.service';
import { EndOfDayService } from './reports/end-of-day/end-of-day.service';
import { EndOfDayDriversService } from './reports/end-of-day/end-of-day-drivers.service';
import { UnitsOfMeasureService } from './units/units-of-measure.service';
import { PunchCardService } from './punch-cards/punch-card.service';
import { PunchCardFilterService } from './punch-cards/punch-card.filter.service';
import { JobService } from './jobs/job.service';
import { JobEventService } from './job-events/job-event.service';
import { LocationUpdateService } from './jobs/locationUpdate.service';
import { CollaboratorService } from './collaborators/collaborator.service';
import { MessageService } from './messages/message.service';
import { TagService } from './tags/tag.service';
import { PriceListService } from './price-lists/price-list.service';
import { PriceService } from './prices/price.service';
import { TicketFilterService } from './tickets/ticket.filter.service';
import { AssignmentService } from './assignments/assignment.service';
import { DriverAssignmentsService } from './assignments/driver-assignments.service';
import { AllDriversMetadataService } from './drivers/all-drivers-metadata/all-drivers-maps-metadata.service';
import { CustomFieldService } from './custom-fields/custom-field.service';
import { SurchargeTemplateService } from './surcharge-templates/surcharge-template.service';
import { CondensedJobEventService } from './job-events/condensed-job-event.service';
import { UserService } from './users/user.service';
import { CondensedTicketService } from './tickets/condensed-ticket.service';
import { LocationService } from './locations/location.service';
import { LocationTypeService } from './locations/location-type.service';
import { MapService } from './map/map.service';
import { DriverShiftService, ShiftService, ShiftReportService } from './shifts/shift.service';
import { ShiftDashboardService } from './shifts/shift-dashboard.service';
import { AssignmentFilterService } from './assignments/assignment.filter.service';
import { PaySheetFilterService } from './pay-sheets/pay-sheet.filter.service';
import { TripDecisionService } from './trips/trip-decision.service';
import { PunchCardDecisionService } from './punch-cards/punch-card-decision.service';

import { routing } from './app.routing';

import {
  CdAutocompleteComponent,
  CdAutocompleteDirective,
  PhoneInputMaskDirective,
  PercentInputMaskDirective
} from './directives/index';

import {
  EqualValidatorDirective,
  NumberInRangeDirective
} from './directives/validators/index';

// Accounts
import { AccountFleetComponent } from './accounts/account-fleet.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountComponent } from './accounts/account.component';

// Tickets
import { TicketFiltersDialogComponent } from './tickets/ticket-filters-dialog.component';
import { VoidTicketDialogComponent } from './tickets/void-ticket-dialog.component';

// Purchase Orders
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { PurchaseOrderComponent } from './purchase-orders/purchase-order.component';
import { PurchaseOrderFiltersDialogComponent } from './purchase-orders/purchase-order-filters-dialog.component';

// Prices
import { PricesComponent } from './prices/prices.component';
import { PriceDialogComponent } from './prices/price-dialog.component';



import { CopyAssignmentsDialogComponent } from './assignments/copy-assignments-dialog.component';
import { MoveAssignmentsDialogComponent } from './assignments/move-assignments/move-assignments-dialog.component';

import {
  LicensePlateNumberDirective,
  TicketFormNumberDirective,
  TextTrimDirective,
  BlockCopyPasteDirective
} from './scaleit/directives';

import {
  ScaleitConfirmDialogComponent,
  VoidTicketConfirmDialogComponent
} from './scaleit/shared';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { ProductService } from './products/product.service';
import { PurchaseOrderFilterService } from './purchase-orders/purchase-order.filter.service';
import { ConnectionService } from './connections/connection.service';
import { PreferenceService } from './preferences/preference.service';
import { JobEventShareService } from './job-event-shares/job-event-share.service';
import { JobEventStatService } from './job-event-stats/job-event-stat.service';
import { JobEventShareStatService } from './job-event-stats/job-event-share-stat.service';
import { CheckinService } from './checkins/checkin.service';
import { OrganizationService } from './organizations/organization.service';
import { WorkOrderTemplateService } from './work-orders/work-order-templates/work-order-template.service';
import { ProjectService } from './projects/project.service';
import { CustomerService } from './customers/customer.service';
import { MarketplaceService } from './marketplace/marketplace.service';
import { EditLocationDetailsComponent } from './locations/edit-location-details/edit-location-details.component';

import { DriverPayModule } from './pay-sheets/pay-sheets.module';
import { OrdersModule } from './orders/orders.module';
import { PlantService } from './plant-manager/plant.service';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { LocationManagerFiltersDialogComponent } from './locations/location-manager/location-manager-filters-dialog.component';
import { UploadService } from './shared/upload.service';
import { CollaboratorStatService } from './collaborators/collaborator-stat.service';
import { JobStatsComponent } from './daily-board/job-stats/job-stats.component';
import { StatsByCollaboratorService } from './collaborators/all-collaborators/stats-by-collaborator.service';
import { CondensedJobEventFilterService } from './job-events/condensed-job-event.filter.service';
import { JobEventFilterService } from './job-events/job-event.filter.service';
import { StatsJobEventFilterService } from './job-events/stats-job-event.filter.service';
import { LocationOwnerService } from './locations/location-owner.service';
import { EsbService } from './esb/esb.service';
import { DriverJobReportService } from './driver-reports/driver-job-report.service';
import { FleetHealthStatsService } from './fleet/health-stats/fleet-health-stats.service';
import { CyberTripService } from './cyber-trips/cyber-trip.service';
import { EditSelectedJobsComponent } from './jobs/edit-selected-jobs/edit-selected-jobs.component';
import { ConfirmChangeJobLoadsService } from './dispatch/dispatch-by-job/confirm-change-job-loads.service';
import { TokenValidationInterceptor } from './shared/token-validation.interceptor';
import { MapMarkersLocationUpdatesComponent } from './jobs/map-markers-location-updates/map-markers-location-updates.component';
import { MapMarkersLegendComponent } from './jobs/map-markers-legend/map-markers-legend.component';

// Gross but so are sentrys ts defs
// cast to any so we can add a beforeSend
if (!['local', 'local-dev', 'dev'].includes(environment.errorTrackingEnv)) {
  (<any>Sentry).init({
    dsn: 'https://3b14efc7832d4b29952f7758c3c72de0@sentry.io/246434',
    environment: environment.errorTrackingEnv,
    release: environment.applicationVersion
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    if (error) { Sentry.captureException(error.originalError || error); }
    throw error;
  }
}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    pan: { threshold: 5 },
    swipe: {
      velocity: 0.4,
      threshold: 20,
      direction: 31 // /!\ ugly hack to allow swipe in all direction
    }
  };
}

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    DispatchModule,
    DriverPayModule,
    OrdersModule,
    routing
  ],
  declarations: [
    AppComponent,

    SettingsComponent,

    EditOrganizationComponent,
    EditOrganizationPermissionsComponent,
    EditOrganizationMarketsComponent,
    OrganizationMarketDialogComponent,

    EditUserComponent,
    EditUserNotificationsComponent,
    EditUserPreferencesComponent,
    EditUserWorkOrderSettingsComponent,

    InvitationComponent,
    DriverInvitationComponent,
    InvitationsComponent,
    OrganizationInvitationComponent,
    AB5QuestionsDialogComponent,
    ConnectionsComponent,
    ConnectionsListComponent,
    ConnectionDialogComponent,
    RuckitAddEmployeeDialogComponent,
    ExportDialogComponent,
    NewTruckDialogComponent,
    NewDriverDialogComponent,
    NewTrackableDialogComponent,
    InviteDriverDialogComponent,
    PasswordResetComponent,
    RuckitForgotPasswordDialogComponent,
    JobFiltersDialogComponent,
    LocationManagerFiltersDialogComponent,
    JobInvitationFiltersDialogComponent,
    NewJobReportDialogComponent,
    EffectiveRateDialogComponent,
    DailyBoardFiltersDialogComponent,
    LoginComponent,
    TagDisplayComponent,
    StyleguideComponent,
    SampleDialogComponent,
    CdAutocompleteComponent,
    CdAutocompleteDirective,
    PhoneInputMaskDirective,
    PercentInputMaskDirective,

    OrderDetailsComponent,

    // Fleet
    FleetComponent,
    FleetHealthComponent,
    FleetHealthStatsComponent,
    DriversComponent,
    TrucksComponent,

    // Trackables
    TrackablesComponent,

    // Driver Page
    DriverDetailsPage,
    DriverDetailsComponent,
    DriverAllTrips,
    DriverTripFiltersDialogComponent,
    DriverGeoTripsComponent,
    DriverPunchCardsComponent,
    DriverShiftsComponent,

    EditTruckComponent,
    EditTrackableComponent,
    EditDriverComponent,
    DailyBoardComponent,
    DailyBoardGridComponent,
    DailyBoardListComponent,
    CollaboratorStatsComponent,
    JobStatsComponent,
    ProjectsComponent,
    ProjectListComponent,
    ProjectFiltersDialogComponent,
    JobInvitationsComponent,
    NewProjectComponent,
    SentTripsComponent,
    SentPunchCardsComponent,
    SentSurchargesComponent,
    NewCustomerDialogComponent,
    AssignTruckDialogComponent,
    DriverNameComponent,
    DriverInfoboxComponent,
    TruckTypeNamesComponent,
    AssignmentErrorDialogComponent,
    AssignmentTruckManagementDialogComponent,
    RuckitButtonComponent,
    RuckitConfirmDialogComponent,
    EqualValidatorDirective,
    NumberInRangeDirective,
    TeamComponent,
    EditTripDialogComponent,
    EditPunchCardDialogComponent,
    NewPunchCardDialogComponent,
    NewTripDialogComponent,
    ViewTicketsDialogComponent,
    ViewSignaturesDialogComponent,
    ViewPunchCardTicketDialogComponent,

    NewJobComponent,
    JobsComponent,
    JobDetailComponent,
    JobMapComponent,
    EditSelectedJobsComponent,
    EditLocationDetailsComponent,
    CancelJobDialogComponent,
    SendJobDialogComponent,
    PublishJobDialogComponent,
    EditJobDialogComponent,
    AcceptJobDialogComponent,
    EditSLOrderIDDialogComponent,
    EditJobComponent,
    HaulerSharesComponent,

    EditShiftsDialogComponent,
    NewShiftDialogComponent,

    TripFiltersDialogComponent,
    TripTimesComponent,
    CompletedTripsComponent,

    PunchCardFiltersDialogComponent,
    CompletedPunchCardsComponent,

    EditJobEventDialogComponent,

    EditInvoiceComponent,
    InvoicesComponent,
    InvoiceComponent,
    CreateInvoiceComponent,
    NewInvoiceDialogComponent,
    InvoiceFiltersDialogComponent,
    InvoiceTripsDialogComponent,
    InvoicePunchCardsDialogComponent,

    ExpenseTripsDialogComponent,
    ExpensePunchCardsDialogComponent,
    ExpenseFiltersDialogComponent,
    ExpensePayTypeDialogComponent,

    PaystubTripsDialogComponent,
    PaystubPunchCardsDialogComponent,
    PaystubFiltersDialogComponent,
    PaystubPayTypeDialogComponent,

    MarketplaceNewProjectDialogComponent,

    SurchargeDialogComponent,
    SurchargeTemplateDialogComponent,

    ExtendCollaborationDialogComponent,
    EditShareDialogComponent,
    NewDaysCollaborationDialogComponent,
    AllCollaboratorsComponent,
    CollaboratorJobsComponent,

    AssignmentBubblesComponent,
    PunchCardAssignmentsComponent,
    TripAssignmentsComponent,
    AllDriverAssignmentsComponent,
    NoDriverAssignmentsComponent,
    AssignmentTripsComponent,
    DriverMapComponent,

    // Tickets
    TicketFiltersDialogComponent,
    VoidTicketDialogComponent,

    // Purchase Orders
    PurchaseOrdersComponent,
    PurchaseOrderComponent,
    PurchaseOrderFiltersDialogComponent,

    // Prices
    PricesComponent,
    PriceDialogComponent,

    ScaleitConfirmDialogComponent,
    VoidTicketConfirmDialogComponent,
    LicensePlateNumberDirective,
    BlockCopyPasteDirective,
    TicketFormNumberDirective,
    TextTrimDirective,

    // Accounts
    AccountsComponent,
    AccountComponent,
    AccountFleetComponent,

    ConfirmAssignmentComponent,
    AutoAssignConflictDialogComponent,
    MessageDriversDialogComponent,
    NewLocationDialogComponent,
    EditLocationDialogComponent,

    DashboardComponent,
    WidgetComponent,
    NeedsDispatchedComponent,
    DailyProductionComponent,
    AssignmentAcceptanceComponent,
    LocationStatusComponent,
    ActiveTrucksComponent,
    DailyShiftsComponent,
    EsbUploaderComponent,

    // Mobile
    MobileJobOverviewComponent,
    MobileAssignmentDetailsComponent,
    MapStatusMarkerComponent,

    CustomFieldsComponent,

    CopyAssignmentsDialogComponent,
    MoveAssignmentsDialogComponent,

    EditUserPreferencesComponent,

    MapMarkersLocationUpdatesComponent,

    MapMarkersLegendComponent,
  ],
  providers: [
    AuthGuard,
    TicketSaveGuard,
    DriverGuard,
    AnyoneGuard,
    LeasedOrgsGuard,
    DispatchGuard,
    AuthenticationService,
    ApiService,
    InvitationService,
    TicketService,
    TruckService,
    TruckTypeService,
    TrackableService,
    TrackableTypeService,
    DriverService,
    TripService,
    TripFilterService,
    CondensedTripService,
    ScaleService,
    JobService,
    JobEventService,
    PunchCardService,
    PunchCardFilterService,
    CondensedJobEventFilterService,
    StatsJobEventFilterService,
    JobEventFilterService,
    LocationUpdateService,
    LocationOwnerService,
    CollaboratorService,
    MessageService,
    TagService,
    PriceListService,
    PriceService,
    TicketFilterService,
    AssignmentService,
    DriverAssignmentsService,
    AllDriversMetadataService,
    CustomFieldService,
    SurchargeTemplateService,
    CondensedJobEventService,
    CondensedTicketService,
    UserService,
    LocationService,
    LocationTypeService,
    PlantService,
    ProductService,
    PurchaseOrderFilterService,
    ConnectionService,
    PreferenceService,
    JobEventShareService,
    JobEventStatService,
    JobEventShareStatService,
    CollaboratorStatService,
    MapService,
    CheckinService,
    OrganizationService,
    WorkOrderTemplateService,
    ProjectService,
    CustomerService,
    MarketplaceService,
    UploadService,
    StatsByCollaboratorService,
    EsbService,
    ShiftService,
    DriverShiftService,
    ShiftReportService,
    ShiftDashboardService,
    AssignmentFilterService,
    PaySheetFilterService,
    TripDecisionService,
    PunchCardDecisionService,
    CyberTripService,
    DriverJobReportService,
    EndOfDayService,
    EndOfDayDriversService,
    UnitsOfMeasureService,
    FleetHealthStatsService,
    ConfirmChangeJobLoadsService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenValidationInterceptor,
      multi: true,
    },
  ],
  entryComponents: [
    CopyAssignmentsDialogComponent,
    MoveAssignmentsDialogComponent,
    ConnectionDialogComponent,
    CdAutocompleteComponent,
    RuckitForgotPasswordDialogComponent,
    RuckitConfirmDialogComponent,
    RuckitAddEmployeeDialogComponent,
    ExportDialogComponent,
    NewCustomerDialogComponent,
    NewTruckDialogComponent,
    NewDriverDialogComponent,
    NewTrackableDialogComponent,
    ProjectFiltersDialogComponent,
    JobInvitationFiltersDialogComponent,
    NewLocationDialogComponent,
    EditLocationDialogComponent,
    AssignTruckDialogComponent,
    EditJobEventDialogComponent,
    AssignmentErrorDialogComponent,
    AssignmentTruckManagementDialogComponent,
    JobFiltersDialogComponent,
    LocationManagerFiltersDialogComponent,
    CancelJobDialogComponent,
    EditSLOrderIDDialogComponent,
    NewJobReportDialogComponent,
    EffectiveRateDialogComponent,
    EditTripDialogComponent,
    ViewTicketsDialogComponent,
    ViewSignaturesDialogComponent,
    TripFiltersDialogComponent,
    SendJobDialogComponent,
    AcceptJobDialogComponent,
    DailyBoardFiltersDialogComponent,
    PunchCardFiltersDialogComponent,
    PublishJobDialogComponent,
    MarketplaceNewProjectDialogComponent,
    NewInvoiceDialogComponent,
    InvoiceFiltersDialogComponent,
    InvoiceTripsDialogComponent,
    InvoicePunchCardsDialogComponent,
    EditJobDialogComponent,
    NewTripDialogComponent,
    SurchargeDialogComponent,
    SurchargeTemplateDialogComponent,
    EditPunchCardDialogComponent,
    NewPunchCardDialogComponent,
    EditShiftsDialogComponent,
    NewShiftDialogComponent,
    ExpenseFiltersDialogComponent,
    ExpenseTripsDialogComponent,
    ExpensePunchCardsDialogComponent,
    PaystubFiltersDialogComponent,
    PaystubTripsDialogComponent,
    PaystubPunchCardsDialogComponent,
    ViewPunchCardTicketDialogComponent,
    InviteDriverDialogComponent,
    ExtendCollaborationDialogComponent,
    ExpensePayTypeDialogComponent,
    PaystubPayTypeDialogComponent,
    EditShareDialogComponent,
    NewDaysCollaborationDialogComponent,
    AutoAssignConflictDialogComponent,
    MessageDriversDialogComponent,
    OrganizationMarketDialogComponent,
    PurchaseOrderFiltersDialogComponent,
    TicketFiltersDialogComponent,
    VoidTicketDialogComponent,
    PriceDialogComponent,
    DriverTripFiltersDialogComponent,

    ScaleitConfirmDialogComponent,
    VoidTicketConfirmDialogComponent,
    AB5QuestionsDialogComponent,
    EditSelectedJobsComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) { }
}
