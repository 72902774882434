<form [formGroup]="newPunchCardForm" (ngSubmit)="submit()">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add Punch Card</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div class="tab-links">
    <ul>
      <li (click)="activeTab='punch-card-data'" [ngClass]="{active: activeTab === 'punch-card-data'}" translate>Punch Card Data</li>
      <li (click)="activeTab='ticket-image'" [ngClass]="{active: activeTab === 'ticket-image'}" translate>Ticket Image</li>
    </ul>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div class="tab punch-card-data" [hidden]="activeTab !== 'punch-card-data'">

      <ng-container *ngIf="lockedFields === false; else lockedFieldsTemplate">
      <div class="form-content">
        <div class="field-group job full-width">
          <label class="required" translate>Job</label>
          <ruckit-dropdown [config]="jobDropdownConfig"
            [selectedOption]="model.job && model.job.id ? model.job.id : null"
            (onSelect)="onSelect('job', $event)">
          </ruckit-dropdown>
        </div>
      </div>
  
      <div class="form-content">
        <div class="field-group trip-date full-width">
          <label class="required" translate>Day</label>
          <input type="hidden" name="job_event_date" required [value]="jobEventDate" />
          <date-range-picker
            [selectedDates]="[jobEventDate]" 
            [highlightedDates]="highlightedDates"
            [config]="jobEventDatePickerOptions"
            (dateChanged)="onJobEventDateChanged($event)">
          </date-range-picker>
        </div>
      </div>
  
      <notification *ngIf="jobEventError" (onClose)="jobEventError = false">
        <span translate>No Job Event found for that date!</span>
      </notification>

      <div class="form-content">
        <div class="field-group carrier full-width">
          <label class="required" translate>Carrier</label>
          <ruckit-dropdown #carrierDropdown [config]="carrierDropdownConfig"
            [selectedOption]="model.carrier"
            (onSelect)="onSelect('carrier', $event)">
          </ruckit-dropdown>
        </div>
      </div>
  
      <div class="form-content">
        <div class="field-group driver full-width">
          <label class="required" translate>Driver</label>
          <ruckit-dropdown #driversDropdown [config]="driverDropdownConfig"
            [selectedOption]="model.driver"
            (onSelect)="onSelect('driver', $event)">
          </ruckit-dropdown>
        </div>
      </div>
      </ng-container>

      <div class="form-content">
        <div class="field-group truck full-width">
          <label class="required" translate>Truck</label>
          <ruckit-dropdown #truckDropdown [config]="truckDropdownConfig"
            [selectedOption]="model.truck"
            (onSelect)="onSelect('truck', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="form-content">
        <div class="form-group-row">
          <div class="field-group ticket-number full-width">
            <label class="required" translate>Ticket #</label>
            <input type="text" name="ticket-number"
            formControlName="ticketNumber"
            (change)="model.ticketNumber = $event.target.value" />
          </div>
        </div>
      </div>
  
      <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="start-date group" fxFlex="50">
          <label class="required" translate>Start Time</label>
          <date-range-picker
            [selectedDates]="[model.startDate]"
            (dateChanged)="onDateChanged('startDate', $event)">
          </date-range-picker>
        </div>

        <div class="start-time group" fxFlex="50">
          <label>&nbsp;</label>
          <input
            type="time"
            name="start_time"
            formControlName="startTime"
            (blur)="editDateTime('startTime', $event.target.value)"/>
        </div>
      </div>
  
      <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="end-date group" fxFlex="50">
          <label class="required" translate>End Time</label>
          <date-range-picker
            [selectedDates]="[model.endDate]"
            (dateChanged)="onDateChanged('endDate', $event)">
          </date-range-picker>
        </div>

        <div class="end-time group" fxFlex="50">
          <label>&nbsp;</label>
          <input
            type="time"
            name="end_time"
            formControlName="endTime"
            (blur)="editDateTime('endTime', $event.target.value)"/>
        </div>
      </div>
    </div>

    <div class="tab ticket-image" [hidden]="activeTab !== 'ticket-image'">
      <div class="form-content">
        <div class="field-group ticket-image">
          <label translate>Ticket Image(s)</label>
  
          <div class="upload-image-dropzone" [ngClass]="{filled: ticketImageFile && ticketImageFile.file}">
            <img *ngIf="ticketImageFile.file" [src]="ticketImageFile.dataUri" />
            <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
            <label class="upload-label" for="file">
              <i class="icon-upload"></i>
              <span *ngIf="!ticketImageFile.file" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
              <span *ngIf="ticketImageFile.file" translate>Upload a new photo</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            type="submit"
            [disabled]="!newPunchCardForm.valid || loading"
            [ngClass]="{ loading:loading }"
            translate>
      Add Punch Card
    </button>
  </div>
</form>

<ng-template #lockedFieldsTemplate>
  <div class="eod-locked-fields">
    <div class="locked-field">
      <label class="required" translate>Job</label>
      <div>{{ jobName }}</div>
    </div>

    <div class="locked-field">
      <label class="required" translate>Day</label>
      <div>{{ jobEventDate | date: 'MMM dd, yyyy' }}</div>
    </div>

    <div class="locked-field">
      <label class="required" translate>Carrier</label>
      <div>{{ model.carrier?.name }}</div>
    </div>

    <div class="locked-field">
      <label class="required" translate>Driver</label>
      <div>{{ model.driver?.name }}</div>
    </div>
    <div class="enable-editing" *ngIf="lockedFields">
      <span (click)="enableEditing()" translate>
        <mat-icon class="pencil-icn">edit</mat-icon>
        Enable Editing
      </span>
    </div>
  </div>
</ng-template>