"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{display:flex}.timeline-container[_ngcontent-%COMP%]{overflow:hidden;height:180px;display:flex;align-items:flex-start}.timeline-container[_ngcontent-%COMP%]     .ruckit-tooltip{border-radius:1px;box-shadow:0 1px 5px 0 rgba(0,0,0,.2),0 3px 1px -2px rgba(0,0,0,.12),0 2px 2px 0 rgba(0,0,0,.14)!important;background-color:#fafafa;color:#4a4a4a!important;padding:10px;font-size:14px;white-space:pre-line}.timeline-container.open[_ngcontent-%COMP%]   timeline[_ngcontent-%COMP%]{width:calc(100% - 544px)}.job-table[_ngcontent-%COMP%]   input[type=time][_ngcontent-%COMP%]{background:0 0;padding-left:0}timeline[_ngcontent-%COMP%]{width:calc(100% - 220px)}.driver-info[_ngcontent-%COMP%]{min-width:220px;height:100%;background-color:#fafafa;border-right:1px solid #e8e8e8}.timeline-list-header[_ngcontent-%COMP%]{height:47px;border-bottom:1px solid #e8e8e8;display:flex;padding:14px 30px}.row-header[_ngcontent-%COMP%]{height:135px;border-bottom:1px solid #e8e8e8;display:flex;align-items:center;padding:30px}p[_ngcontent-%COMP%]{margin:0}p.description[_ngcontent-%COMP%]{font-weight:500}.details[_ngcontent-%COMP%]{display:flex;justify-content:space-between;align-items:center;width:100%}.details[_ngcontent-%COMP%]   .text[_ngcontent-%COMP%]{cursor:pointer}.details[_ngcontent-%COMP%]   .info[_ngcontent-%COMP%]{width:18px;height:18px}.details[_ngcontent-%COMP%]   .info[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{color:#015bc5;font-size:18px;cursor:help}"];
exports.styles = styles;
