import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { NgForm } from '@angular/forms';

import { Invitation } from './invitation';
import { InvitationService } from './invitation.service';
import { UserService } from '../users/user.service';
import { ApiService } from '../shared/api.service';
import { OrganizationService } from '../organizations/organization.service';
import { AuthenticationService } from '../shared/authentication.service';
import { UserSerializer } from '../users/user.serializer';
import { parseErrors } from '../shared/api.service';
import { DataService } from '../shared/data.service';

// const camelcaseKeysDeep = require('camelcase-keys-deep');

@Component({
  selector: 'my-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  inviteCode: string;
  invitation: Invitation;
  loading = true;
  errors = [];
  data: any = {};
  organization: any = {};
  step = 0;
  steps: string[] = ['Your Details', 'Profile Photo', 'Organization Details'];
  completedSteps: string[] = [];
  states: any = [];
  imageChanged = false;
  notFound = false;
  profileImage = {
    dataUri: null,
    file: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private invitationService: InvitationService,
    private userService: UserService,
    private apiService: ApiService,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService
  ) { }


  ngOnInit() {
    this.loading = true;
    this.states = this.apiService.getStates();
    this.route.params.forEach((params: Params) => {
      this.inviteCode = params['invite_code'];
      if (params['step'] && params['step'].length) {
        this.step = +params['step'];
      }
      this.getInvitation();
    });
  }

  getInvitation() {
    this.invitationService.getInvitation(this.inviteCode).subscribe(invitation => {
      // invitation = camelcaseKeysDeep(invitation);
      this.invitation = <Invitation>invitation;
      if (this.invitation.status === 'pending') {
        this.authenticationService.logout();
      } else if (this.invitation.status === 'accepted') {
        this.completedSteps.push('Your Details');
      }
      this.loadData(this.invitation);
      // Remove the Organization Details step if a Primary Contact has been set.
      if (this.invitation && this.invitation.recipientOrganization && this.invitation.recipientOrganization.primaryContact) {
        let index = this.steps.indexOf('Organization Details', 0);
        if (index > -1) {
          this.steps.splice(index, 1);
        }
      }
      this.step = 1;
      this.loading = false;
    }, err => {
      if (err.includes('detail: Not found.')) {
        this.completedSteps.push('Your Details');
        let index = this.steps.indexOf('Organization Details', 0);
        if (index > -1) {
          this.steps.splice(index, 1);
        }
        this.step = this.completedSteps.length + 1;
      }
      this.loading = false;
      if (!this.authenticationService.isLoggedIn()) {
        this.step = 0;
        this.notFound = true;
        this.errors = parseErrors(err);
      }
    });
  }

  skipStep() {
    let currentStep = this.steps[this.step - 1];
    this.completedSteps.push(currentStep);
    if (this.completedSteps && this.steps && this.completedSteps.length === this.steps.length) {
      if (this.authenticationService.isLoggedIn()) {
        this.activateSidebar();
        this.router.navigate(['/daily']);
      } else {
        this.router.navigate(['/login']);
      }
    } else {
      this.step += 1;
      if (this.invitation && this.invitation.id) {
        this.router.navigate(['/invitations', this.invitation.id, this.step]);
      } else {
        this.errors = parseErrors('Invitation was not found.');
      }
    }
  }

  cancelImageChange() {
    this.imageChanged = false;
    this.profileImage['file'] = null;
  }

  register(): void {
    this.loading = true;
    this.errors = [];
    this.invitationService.registerFromInvitation(
      this.invitation.id, this.invitation.kind, this.data
    ).subscribe(result => {
      this.loading = false;
      this.step += 1;
      this.completedSteps.push('Your Details');
      if (this.completedSteps && this.steps && this.completedSteps.length === this.steps.length) {
        this.activateSidebar();
        this.router.navigate(['/daily']);
      } else {
        if (this.invitation && this.invitation.id) {
          this.router.navigate(['/invitations', this.invitation.id, this.step]);
        } else {
          this.errors = parseErrors('Invitation was not found.');
        }
      }
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  updatePhoto(): void {
    this.loading = true;
    this.errors = [];
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let userId = currentUser && currentUser.id;

    this.userService.saveWithImage({ id: userId }, this.profileImage.file).subscribe(user => {
      user = (new UserSerializer).fromJson(user);
      let _user = this.authenticationService.user();
      if (_user) {
        _user.image = user.image;
        localStorage.setItem('currentUser', JSON.stringify(_user));
        this.loading = false;
        this.completedSteps.push('Profile Photo');
        if (this.completedSteps && this.steps && this.completedSteps.length === this.steps.length) {
          this.activateSidebar();
          this.router.navigate(['/daily']);
        } else {
          this.step += 1;
          if (this.invitation && this.invitation.id) {
            this.router.navigate(['/invitations', this.invitation.id, this.step]);
          } else {
            this.errors = parseErrors('Invitation was not found.');
          }
        }
      } else {
        this.errors = parseErrors('User was not returned.');
        this.loading = false;
      }
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  updateOrganization(): void {
    this.loading = true;
    this.errors = [];
    this.organization.id = this.invitation.recipientOrganization && this.invitation.recipientOrganization.id;
    this.organizationService.save(this.organization).subscribe(result => {
      this.loading = false;
      this.step += 1;
      this.completedSteps.push('Organization Details');
      this.activateSidebar();
      this.router.navigate(['/daily']);
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  fileChange(e, form: NgForm): void {
    let profileImage = this.profileImage;

    let reader = new FileReader();
    reader.onload = function (loadEvent: any) {
      profileImage.dataUri = loadEvent.target.result;
    };

    let file = e.srcElement.files[0];
    profileImage.file = file;
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }

  private activateSidebar(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser.sidebar = true;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.dataService.changeData({
      authenticated: true,
      displaySidebar: true
    });
  }

  private loadData(invitation): void {
    this.data = {
      'profile': {
        'firstName': invitation.resultUser && invitation.resultUser.firstName,
        'lastName': invitation.resultUser && invitation.resultUser.lastName,
        'email': invitation.recipientEmail,
        'title': invitation.resultUser && invitation.resultUser.title,
        'phoneNumber': invitation.resultUser && invitation.resultUser.phoneNumber
      },
      'user': {
        'password': ''
      }
    };

    if (invitation && invitation.recipientOrganization) {
      this.organization = {
        'name': invitation.recipientOrganization.name,
        'street': invitation.recipientOrganization.street,
        'city': invitation.recipientOrganization.city,
        'state': invitation.recipientOrganization.state,
        'zipcode': invitation.recipientOrganization.zipcode,
        'phoneNumber': invitation.recipientOrganization.phoneNumber
      };
    }
  }
}
