import {
  Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, omit as _omit } from 'lodash';

import { SurchargeService } from '../surcharges/surcharge.service';
import { PaystubService } from '../paystubs/paystub.service';
import { SurchargeDialogComponent } from '../surcharges/surcharge-dialog.component';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'ruckit-paystub-surcharges',
  templateUrl: './paystub-surcharges.component.html',
  styleUrls: ['./paystub-surcharges.component.scss'],
  providers: [SurchargeService, PaystubService]
})
export class PaystubSurchargesComponent implements OnInit, OnChanges {
  @Input() paystub;
  @Input() search = '';
  @Output() updatePaystub = new EventEmitter<boolean>();
  count;
  selectedCount = 0;
  surcharges: any = [];
  loading = true;
  errors = [];
  surchargeReq;
  paystubReq;
  page = 1;
  sortBy: string;
  sortAsc = true;
  sortParameter: string;
  allSelected = false;
  selectedSurcharges = [];
  excludeSurcharges = [];
  surchargeFilters;
  confirmDialog: MatDialogRef<any>;
  multipleActionDropdownOptions = [
    { name: 'Remove from Paystub', action: 'remove', link: false }
  ];
  menuOptions = [
    { name: 'Edit', action: 'edit', link: false },
    { name: 'Remove from Paystub', action: 'remove', link: false }
  ];
  addSurchargeCallback = (e) => {
    this.updatePaystub.emit(true);
    this.getSurcharges();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surchargeService: SurchargeService,
    private paystubService: PaystubService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.forEach((params: Params) => {
      this.sortBy = params.sortSurchargesBy ? params.sortSurchargesBy : 'date';
      this.sortAsc = params.sortSurchargesAsc ? (params.sortSurchargesAsc === 'true') : false;
      this.sortParameter = params.sortSurchargesParameter ? params.sortSurchargesParameter : null;
      this.page = params.sortSurchargesPage ? params.sortSurchargesPage : 1;
    });
    this.getSurcharges({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  ngOnChanges(changes: { [search: string]: SimpleChange }) {
    for (let propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let chng = changes[propName];
        if (propName === 'search') { this.search = chng.currentValue; }
      }
    }

    this.changeSearch(this.search);
  }

  pageChange(event) {
    this.page = event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortSurchargesPage: this.page > 1 ? this.page : null
      }
    });
    this.getSurcharges({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  getSurcharges(query = {}, append = false) {
    if (!append) { this.surcharges = []; }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    if (this.surchargeReq) { this.surchargeReq.unsubscribe(); }

    this.surchargeFilters = {
      ordering: order,
      search: this.search,
      invoice: this.paystub.id,
      ...query
    };

    this.surchargeReq = this.surchargeService.list(this.surchargeFilters).subscribe(
      surcharges => {
        if (append) {
          this.surcharges = this.surcharges.concat(surcharges);
        } else {
          this.surcharges = surcharges;
        }
        this.count = this.surchargeService.count;
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) { this.sortAsc = !this.sortAsc; }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortSurchargesBy: this.sortBy,
        sortSurchargesAsc: this.sortAsc,
        sortSurchargesParameter: null
      }
    });
    this.loading = true;
    this.getSurcharges({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      page: this.page
    });
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortSurchargesBy: this.sortBy,
        sortSurchargesAsc: this.sortAsc,
        sortSurchargesParameter: this.sortParameter
      }
    });
    this.loading = true;
    this.getSurcharges({
      [this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy,
      page: this.page
    });
  }

  openAddSurcharge() {
    const dialog = this.dialog.open(SurchargeDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.invoice = this.paystub;
    dialog.componentInstance.callback = this.addSurchargeCallback;
  }

  removeSelectedSurcharges(surcharges = null) {
    let model = { id: this.paystub.id };

    if (surcharges || this.selectedSurcharges.length) {
      Object.assign(model, { surcharges: surcharges || this.selectedSurcharges });
    } else if (this.allSelected) {
      let params = new HttpParams();
      let filters = _omit(this.surchargeFilters, ['ordering', 'invoice']);
      if (filters) {
        Object.keys(filters).map(key => {
          if (filters[key] && filters[key].length) {
            params = params.set(key, filters[key]);
          }
        });
      }
      if (params.toString().length) {
        Object.assign(model, { filters: params.toString() });
      }
      Object.assign(model, { excludeSurcharges: this.excludeSurcharges });
    }

    this.paystubReq = this.paystubService.removeFromPaystub(model, 'surcharges').subscribe((res) => {
      this.paystub = res;
      this.search = '';
      this.allSelected = false;
      this.updatePaystub.emit(true);
      this.getSurcharges();
    }, err => console.error(err));
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.getSurcharges();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  menuAction(name, surcharge) {
    switch (name) {
      case 'remove':
        surcharge ? this.removeSelectedSurcharges([surcharge.id]) : this.removeSelectedSurcharges();
        break;
      case 'edit':
        this.editSurcharge(surcharge);
        break;
    }
  }

  editSurcharge(surcharge) {
    const dialog = this.dialog.open(SurchargeDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.model = surcharge;
    dialog.componentInstance.invoice = this.paystub;
    dialog.componentInstance.callback = this.addSurchargeCallback;
  }

  selector(event, surcharge = null) {
    if (surcharge) {
      if (!event.target.checked) {
        surcharge.selected = false;
        pull(this.selectedSurcharges, surcharge.id);
        if (this.allSelected) {
          this.excludeSurcharges.push(surcharge.id);
          this.selectedCount = (this.count - this.excludeSurcharges.length);
        } else {
          this.selectedCount = this.selectedSurcharges.length;
        }
      } else {
        surcharge.selected = true;
        if (this.allSelected) {
          pull(this.excludeSurcharges, surcharge.id);
          this.selectedCount = (this.count - this.excludeSurcharges.length);
        } else {
          this.selectedSurcharges.push(surcharge.id);
          this.selectedCount = this.selectedSurcharges.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.surcharges.forEach((_surcharge) => { _surcharge.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludeSurcharges.length);
      }
      this.selectedSurcharges = [];
      this.excludeSurcharges = [];
    }
  }
}
