<div class="mobile-search-results">
  <div *ngFor="let result of results; let i = index" class="result-details" (click)="selectActiveResult(result, i)">
    <div class="driver">
      <div class="truck-photo" [ngClass]="{empty: result.driver && !result.driver.truck}">
        <div class="image" *ngIf="result.driver && result.driver.truck">
          <img *ngIf="result.driver.truck.image" [src]="result.driver.truck.image"/>
          <i *ngIf="!result.driver.truck.image" class="icon-fleet"></i>
        </div>
      </div>
      <div class="driver-photo" *ngIf="result.driver && result.driver.profile">
        <span *ngIf="!result.driver.image">{{ result.driver.profile.firstName[0] }}</span>
        <img *ngIf="result.driver.image" [src]="result.driver.image"/>
      </div>
    </div>
    <div class="details" *ngIf="result.driver && result.driver.truck && result.driver.profile">
      <h1>{{ result.driver.truck.name }} - {{ result.driver.truck.truckTypeName }}</h1>
      <h3>{{ result.driver.profile.firstName }} {{ result.driver.profile.lastName }}</h3>
    </div>
  </div>
</div>