"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MultipleImagesEditComponent = /** @class */ (function () {
    function MultipleImagesEditComponent(renderer) {
        this.renderer = renderer;
        this.open = false;
        this.withAnimation = true;
        this.loadingImages = [];
        this.unloadingImages = [];
        this.loadingSignatureImages = [];
        this.unloadingSignatureImages = [];
        this.showLoadingImages = true;
        this.showUnloadingImages = true;
        this.showLoadingSignatureImages = true;
        this.showUnloadingSignatureImages = true;
        this.editable = true;
        this.showOnSave = false;
        this.requiredFields = {
            loadingImages: false,
            unloadingImages: false,
            loadingSignature: false,
            unloadingSignature: false,
        };
        this.onLoadingImagesChange = new core_1.EventEmitter();
        this.onUnloadingImagesChange = new core_1.EventEmitter();
        this.onLoadingSignatureImagesChange = new core_1.EventEmitter();
        this.onUnloadingSignatureImagesChange = new core_1.EventEmitter();
        this.onSave = new core_1.EventEmitter();
        this.onUploading = new core_1.EventEmitter();
        this.currentLoadingImages = [];
        this.currentUnloadingImages = [];
        this.currentLoadingSignatureImages = [];
        this.currentUnloadingSignatureImages = [];
        this.loadingSave = false;
        this.saveDisabled = false;
    }
    MultipleImagesEditComponent.prototype.ngOnChanges = function () {
        this.currentLoadingImages = this.loadingImages
            ? this.loadingImages.filter(function (img) { return img.isDeleted !== true; })
            : [];
        this.currentUnloadingImages = this.unloadingImages
            ? this.unloadingImages.filter(function (img) { return img.isDeleted !== true; })
            : [];
        this.currentLoadingSignatureImages = this.loadingSignatureImages
            ? this.loadingSignatureImages.filter(function (img) { return img.isDeleted !== true; })
            : [];
        this.currentLoadingSignatureImages = this.loadingSignatureImages
            ? this.loadingSignatureImages.filter(function (img) { return img.isDeleted !== true; })
            : [];
        this.currentUnloadingSignatureImages = this.unloadingSignatureImages
            ? this.unloadingSignatureImages.filter(function (img) { return img.isDeleted !== true; })
            : [];
    };
    MultipleImagesEditComponent.prototype.ngOnDestroy = function () {
        if (this.unlistener) {
            this.unlistener();
        }
    };
    MultipleImagesEditComponent.prototype.setListenerForOutsideClick = function () {
        var _this = this;
        this.unlistener = this.renderer.listen('window', 'click', function (e) {
            if (_this.open &&
                _this.editImagesContainer &&
                !_this.editImagesContainer.nativeElement.contains(e.target) &&
                e.target.className !== 'icon-delete') {
                _this.handleVisibility();
            }
            else {
                _this.open = true;
            }
        });
    };
    MultipleImagesEditComponent.prototype.handleVisibility = function () {
        var open = this.open;
        this.loadingSave = false;
        this.saveDisabled = false;
        if (open) {
            this.open = false;
            this.unlistener();
        }
        else {
            this.setListenerForOutsideClick();
        }
    };
    MultipleImagesEditComponent.prototype.onLoadingImageChange = function (image, index) {
        var images = this.currentLoadingImages.length
            ? this.currentLoadingImages.map(function (img, i) {
                return i === index ? image : img;
            })
            : [image];
        this.onLoadingImagesChange.emit(images);
    };
    MultipleImagesEditComponent.prototype.onUnloadingImageChange = function (image, index) {
        var images = this.currentUnloadingImages.length
            ? this.currentUnloadingImages.map(function (img, i) { return (i === index ? image : img); })
            : [image];
        this.onUnloadingImagesChange.emit(images);
    };
    MultipleImagesEditComponent.prototype.onLoadingSignatureImageChange = function (image, index) {
        var images = this.currentLoadingSignatureImages.length
            ? this.currentLoadingSignatureImages.map(function (img, i) {
                return i === index ? image : img;
            })
            : [image];
        this.onLoadingSignatureImagesChange.emit(images);
    };
    MultipleImagesEditComponent.prototype.onUnloadingSignatureImageChange = function (image, index) {
        var images = this.currentUnloadingSignatureImages.length
            ? this.currentUnloadingSignatureImages.map(function (img, i) {
                return i === index ? image : img;
            })
            : [image];
        this.onUnloadingSignatureImagesChange.emit(images);
    };
    MultipleImagesEditComponent.prototype.onLoadingImageAdd = function () {
        this.currentLoadingImages.push(null);
    };
    MultipleImagesEditComponent.prototype.onLoadingSignatureImageAdd = function () {
        this.currentLoadingSignatureImages.push(null);
    };
    MultipleImagesEditComponent.prototype.onUnloadingImageAdd = function () {
        this.currentUnloadingImages.push(null);
    };
    MultipleImagesEditComponent.prototype.onUnloadingSignatureImageAdd = function () {
        this.currentUnloadingSignatureImages.push(null);
    };
    MultipleImagesEditComponent.prototype.save = function () {
        this.loadingSave = true;
        this.saveDisabled = true;
        this.onSave.emit();
    };
    MultipleImagesEditComponent.prototype.uploading = function (uploading) {
        this.onUploading.emit(uploading);
        this.saveDisabled = uploading;
    };
    return MultipleImagesEditComponent;
}());
exports.MultipleImagesEditComponent = MultipleImagesEditComponent;
