"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var shared_1 = require("../shared");
var user_1 = require("./user");
var user_service_1 = require("./user.service");
var user_serializer_1 = require("./user.serializer");
var api_service_1 = require("../shared/api.service");
var EditUserWorkOrderSettingsComponent = /** @class */ (function () {
    function EditUserWorkOrderSettingsComponent(route, router, userService, authenticationService, dialog) {
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.loading = false;
        this.signatureImage = {
            dataUri: null,
            file: null
        };
        this.imageChanged = false;
        this.errors = [];
    }
    EditUserWorkOrderSettingsComponent.prototype.ngOnInit = function () { };
    EditUserWorkOrderSettingsComponent.prototype.saveUser = function () {
        var _this = this;
        this.loading = true;
        this.userService.saveWithSignatureImage(this.user, this.signatureImage.file).subscribe(function (user) {
            _this.user = (new user_serializer_1.UserSerializer).fromJson(user);
            var _user = _this.authenticationService.user();
            _user.name = _this.user.firstName + ' ' + _this.user.lastName;
            _user.username = _this.user.email;
            _user.image = _this.user.image;
            _user.hasSignature = true;
            localStorage.setItem('currentUser', JSON.stringify(_user));
            _this.router.navigate(['/settings', 'user']);
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditUserWorkOrderSettingsComponent.prototype.fileChange = function (e, form) {
        var signatureImage = this.signatureImage;
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
            signatureImage.dataUri = loadEvent.target.result;
        };
        var file = e.srcElement.files[0];
        signatureImage.file = file;
        reader.readAsDataURL(file);
        this.imageChanged = true;
    };
    return EditUserWorkOrderSettingsComponent;
}());
exports.EditUserWorkOrderSettingsComponent = EditUserWorkOrderSettingsComponent;
