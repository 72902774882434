"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column}.form-container[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:column;overflow-y:auto}form[_ngcontent-%COMP%]{display:block;max-width:640px;padding:0 20px;margin:0 auto;width:100%}.input-group[_ngcontent-%COMP%], input[_ngcontent-%COMP%]{max-width:500px}h1[_ngcontent-%COMP%]{font-size:26px}h2[_ngcontent-%COMP%]{font-size:18px;font-weight:600;margin-top:50px;margin-bottom:20px}.radio-buttons[_ngcontent-%COMP%]{display:flex;flex-direction:row;margin-bottom:23px}.radio-buttons[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{flex:0 1 120px;margin-top:6px}.input-group[_ngcontent-%COMP%]{max-width:240px}.input-group[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{width:55%}label[_ngcontent-%COMP%]{margin-top:15px}button[_ngcontent-%COMP%]{margin:30px 0}button.submit[_ngcontent-%COMP%]{float:right}.location[_ngcontent-%COMP%]{display:flex;flex-direction:row}.location[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{flex:0 0 50%;width:50%}.location[_ngcontent-%COMP%]   .fields[_ngcontent-%COMP%]{padding-right:50px}.map[_ngcontent-%COMP%]{border-radius:4px;overflow:hidden;z-index:0;margin-top:40px;height:300px}"];
exports.styles = styles;
