import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import 'rxjs/add/observable/fromEvent';

// angular material
import { MatDialog } from '@angular/material';

// libraries
import * as moment from 'moment';
import { clone } from 'lodash';

// models
import { Trip } from '../../trips/trip';
import { PunchCard } from '../../punch-cards/punch-card';
import { Location } from '../../locations/location';
import { JobEvent } from '../../job-events/job-event';
import { MenuItem } from '../../shared/context-menu/interfaces/menu-item';

// components
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { EditPunchCardDialogComponent } from '../../punch-cards/edit-punch-card-dialog.component';
import { EditTripDialogComponent } from '../../trips/edit-trip-dialog/edit-trip-dialog.component';

// services
import { TranslateService } from '@ngx-translate/core';

export type TimelineRowData = {
  referenceId: string,
  trips?: Trip[],
  predictedTrips?: Trip[],
  geofences?: GeofenceBar[],
  punchcards?: PunchCard[],
  shifts?: DateTimeRange[],
  payLines?: DateTimeRange[],
  pavers?: DateTimeRange[],
  payBasis?: string,
  waitTimes?: DateTimeRange[],
  signalLosses?: DateTimeRange[],
  payAdjustmentTotal?: number,
  notes?: string
};

export type DateTimeRange = {
  type?: string,
  startDatetime: string,
  endDatetime: string
};

export type GeofenceBar = {
  range: DateTimeRange,
  location?: Location,
  type: string
};

type BarStyle = {
  left: string,
  width?: string
};

export type TimelineConfig = {
  currentTime: boolean,
  selectedTime: boolean,
  headerHeight: number,
  rowHeight: number,
  scroll: boolean,
  visibleBars?: string[]
};

export enum TimeInterval {
  Hour = 60,
  HalfHour = 30,
  QuarterHour = 15
}

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() timelineConfig = {
    currentTime: false,
    selectedTime: false,
    scroll: true,
    headerHeight: 47,
    rowHeight: 115,
    visibleBars: ['trips', 'punchcards', 'predictedTrips',
                  'truckStatuses', 'shifts', 'payLines']
  };
  @Input() jobEvent?: JobEvent;
  @Input() rows: TimelineRowData[] = [];
  @Input() range: DateTimeRange;
  @Input() timeInterval: TimeInterval = TimeInterval.Hour;
  @Input() playing: boolean;
  @Input() selectedRowIndex: number;
  @Output() select: EventEmitter<string> = new EventEmitter();
  @Output() rowEdits: EventEmitter<TimelineRowData> = new EventEmitter();
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  currentTime: moment.Moment;
  times: string[];
  barDisplay = 'times';
  barDisplayOptions = ['times', 'duration', 'type'];
  payLineEventSubs: Subscription[] = [];
  payLineEditing: string;
  @ViewChild('tripContextMenu', { static: false }) tripContextMenu: ContextMenuComponent;
  @ViewChild('punchcardContextMenu', { static: false }) punchcardContextMenu: ContextMenuComponent;
  tripContextMenuItems: MenuItem [] = [
    {
      name: this.translateService.instant('Edit Trip'),
      icon: 'pencil',
      disabled: false,
    }
  ];
  punchcardContextMenuItems: MenuItem [] = [
    {
      name: this.translateService.instant('Edit Punch Card'),
      icon: 'pencil',
      disabled: false,
    }
  ];
  tripContextMenuSelected = '';
  contextMenuSelectedTrip: Trip = null;
  keyContextMenuSelected: string = null;

  selectedTimeValue: string;
  @Output() selectedTimeChange: EventEmitter<string> = new EventEmitter();
  @Input() get selectedTime() { return this.selectedTimeValue; }
  set selectedTime(time: string) {
    this.selectedTimeValue = time;
    this.selectedTimeChange.emit(time);
  }

  timeWithinRange(time: string | moment.Moment): boolean {
    return moment(time).isBefore(this.range.endDatetime);
  }

  constructor(
    private translateService: TranslateService,
    public dialog: MatDialog
    ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.range) { this.generateTimelineRange(); }
    if (changes.rows) {
      this.rows.forEach(row => {
        if (row.predictedTrips) {
          row.geofences = this.generateGeofenceBars(row.predictedTrips, this.jobEvent);
        }
      });
      this.autoScroll();
    }
    if (changes.timeInterval) { this.generateTimelineRange(); }
  }

  ngOnInit() {
    this.generateTimelineRange();
    this.rows.forEach(row => {
      if (row.predictedTrips) {
        row.geofences = this.generateGeofenceBars(row.predictedTrips, this.jobEvent);
      }
    });
    if (this.timelineConfig.currentTime) {
      this.currentTime = moment();
      setInterval(() => { this.currentTime = moment(); }, 60000);
    }
    if (this.timelineConfig.selectedTime) {
      const timelineEl = document.getElementById('timeline');
      Observable.fromEvent(timelineEl, 'mousemove')
                .subscribe((e: MouseEvent) => {
        if (!(<Element>e.target).classList.contains('bar') &&
            !(<Element>e.target).classList.contains('label') &&
            !(<Element>e.target).classList.contains('shift-line') &&
            !(<Element>e.target).classList.contains('wait-line') &&
            !(<Element>e.target).classList.contains('status-icon') &&
            !this.playing) {
          let x = e['layerX'] + timelineEl.scrollLeft;
          const leftOffset = 275; // width in pixels left of the timeline
          const timeSegmentWidth = 95; // width in pixels of an individual time segment
          const minuteOffset = Math.floor((x - leftOffset) * (this.timeInterval / timeSegmentWidth));
          this.selectedTime = moment(this.range.startDatetime).subtract(2, 'hours')
                                                              .startOf('hour')
                                                              .add(minuteOffset, 'minutes')
                                                              .startOf('minute')
                                                              .toISOString();
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.rows.some(row => row.payLines && row.payLines.length > 0)) {
      this.generatePayLineEventSubs(this.rows);
    }
  }

  generatePayLineEventSubs(rows: TimelineRowData[]) {
    rows.forEach(row => {
      row.payLines.forEach((period, i) => {
        const payLineEl = document.getElementById(row.referenceId + '-' + i);
        let startPos: number;
        let originalTimes = clone(period);
        if (payLineEl) {
          this.payLineEventSubs.push(
            Observable.fromEvent(payLineEl, 'mousedown').subscribe((e: MouseEvent) => {
              if ((<Element>e.target).classList.contains('edit-handle')) {
                this.payLineEditing = (<Element>e.target).classList.contains('start') ? 'start' : 'end';
                startPos = e.pageX;
              }
            })
          );
          this.payLineEventSubs.push(
            Observable.fromEvent(payLineEl, 'mousemove').subscribe((e: MouseEvent) => {
              if (this.payLineEditing) {
                let minuteOffset = Math.floor((e.pageX - startPos) * (this.timeInterval / 95));
                if (this.payLineEditing === 'start') {
                  // adjust beginning time to minute
                  row.payLines[i].startDatetime = moment(originalTimes.startDatetime).add(minuteOffset, 'minutes').toISOString();
                } else if (this.payLineEditing === 'end') {
                  // adjust the end time to minute
                  row.payLines[i].endDatetime = moment(originalTimes.endDatetime).add(minuteOffset, 'minutes').toISOString();
                }
              }
            })
          );
          this.payLineEventSubs.push(
            Observable.fromEvent(payLineEl, 'mouseup').subscribe((e: MouseEvent) => {
              this.payLineEditing = null;
              originalTimes = clone(row.payLines[i]);
              this.rowEdits.emit(row);
            })
          );
        }
      });
    });
  }

  resetPayLineSubs() {
    this.payLineEventSubs.forEach(sub => sub.unsubscribe());
    setTimeout(() => this.generatePayLineEventSubs(this.rows));
  }

  generateTimelineRange() {
    const startTime = this.range ?
                      moment(this.range.startDatetime).subtract(2, 'hours').startOf('hour') :
                      moment().startOf('day');
    const endTime = this.range ?
                    moment(this.range.endDatetime).add(2, 'hours').startOf('hour') :
                    moment().endOf('day');
    this.times = [];
    for (let time = startTime; time.isBefore(endTime); time.add(this.timeInterval, 'minutes')) {
      this.times.push(time.format('h:mma'));
    }
  }

  generateGeofenceBars(predictedTrips: Trip[], jobEvent?: JobEvent): GeofenceBar[] {
    let geofences: GeofenceBar[] = [];
    predictedTrips.forEach((trip, i) => {
      if (trip.unloadingArrivalTime && trip.unloadingCompleteTime) {
        let unloadingGeofence: GeofenceBar = {
          range: {
            startDatetime: trip.unloadingArrivalTime,
            endDatetime: trip.unloadingCompleteTime
          },
          type: 'Unloading'
        };
        if (jobEvent) {
          unloadingGeofence.location = jobEvent.job.endLocation;
        } else if (trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.endLocation) {
          unloadingGeofence.location = trip.jobEvent.job.endLocation;
        }
        geofences.push(unloadingGeofence);
      }
      if (trip.loadingArrivalTime && trip.loadingCompleteTime) {
        let loadingGeofence: GeofenceBar = {
          range: {
            startDatetime: trip.loadingArrivalTime,
            endDatetime: trip.loadingCompleteTime
          },
          type: 'Loading'
        };
        if (jobEvent) {
          loadingGeofence.location = jobEvent.job.startLocation;
        } else if (trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.startLocation) {
          loadingGeofence.location = trip.jobEvent.job.startLocation;
        }
        geofences.push(loadingGeofence);
      }
    });
    return geofences;
  }

  generateTravelTimes(predictedTrips: Trip[]): DateTimeRange[] {
    let travelTimes: DateTimeRange[] = [];
    predictedTrips.forEach(trip => {
      if (trip.loadingCompleteTime && trip.unloadingArrivalTime) {
        travelTimes.push({
          startDatetime: trip.loadingCompleteTime,
          endDatetime: trip.unloadingArrivalTime
        });
      }
    });
    return travelTimes;
  }

  barStyles(startDatetime: string, endDatetime?: string, offset = 0): BarStyle {
    let values = <BarStyle>{};
    const timeScale = 95 / this.timeInterval;
    let startUnixTime = Date.parse(startDatetime);
    let left = ((startUnixTime - Number(moment(this.range.startDatetime)
                                          .subtract(2, 'hours')
                                          .startOf('hour').format('x'))) / 60000) * timeScale;
    values.left = left + offset + 'px';
    let width = 1;
    if (endDatetime && Date.parse(endDatetime) < Date.parse(startDatetime)) {
      endDatetime = moment(
        new Date(Date.parse(startDatetime)).toDateString() + ' ' + new Date(Date.parse(endDatetime)).toTimeString()
      ).toISOString();
      width = ((Date.parse(endDatetime) - startUnixTime) / 60000) * timeScale;
    } else if (endDatetime) {
      width = ((Date.parse(endDatetime) - startUnixTime) / 60000) * timeScale;
    } else if (Date.parse(this.range.endDatetime) > Date.now()) {
      width = ((Date.now() - startUnixTime) / 60000) * timeScale;
    }
    values.width = width + 'px';

    const timeline = document.getElementById('timeline');
    if (timeline && typeof timeline.scrollTo === 'function' && this.playing) { timeline.scrollTo({ left: left - 50, behavior: 'auto' }); }

    return values;
  }

  selectRow(row: TimelineRowData) {
    this.select.emit(row.referenceId);
  }

  switchBarDisplay() {
    this.barDisplay = this.barDisplayOptions.indexOf(this.barDisplay) + 1 === this.barDisplayOptions.length ?
                      this.barDisplayOptions[0] :
                      this.barDisplayOptions[this.barDisplayOptions.indexOf(this.barDisplay) + 1];
  }

  getDurationString(startDatetime: string, endDatetime: string, durationType = 'hours'): string {
    if (startDatetime && endDatetime) {
      const minuteDuration = moment(endDatetime).diff(startDatetime, 'minutes');
      return durationType === 'minutes' ? minuteDuration + 'm' : Math.floor(minuteDuration / 60) + 'h ' + (minuteDuration % 60) + 'm';
    } else if (startDatetime) {
      const minuteDuration = moment(new Date()).diff(startDatetime, 'minutes');
      return durationType === 'minutes' ? minuteDuration + 'm' : Math.floor(minuteDuration / 60) + 'h ' + (minuteDuration % 60) + 'm';
    } else {
      return '-h--m';
    }
  }

  autoScroll() {
    const timelineEl = document.getElementById('timeline');
    if (this.rows[0] && timelineEl) {
      let firstTime: string;
      Object.keys(this.rows[0]).forEach(key => {
        if (['trips', 'predictedTrips', 'punchcards'].indexOf(key) !== -1 &&
            (!firstTime || moment(key['startTimeTimestamp']).isBefore(firstTime)) &&
            this.rows[0][key]) {
            this.rows[0][key].forEach(element => {
            firstTime = element.startTimeTimestamp;
          });
        }
      });
      if (typeof timelineEl.scrollTo === 'function') {
        timelineEl.scrollTo({
          left: Number(this.barStyles(firstTime).left.split('px')[0]) - 50,
          behavior: 'smooth'
        });
      }
    }
  }

  openContextMenu(event, trip: Trip, key: string) {
    this.keyContextMenuSelected = key;
    this.tripContextMenuSelected = this.getDurationString(trip.startTimeTimestamp, trip.endTimeTimestamp);
    this.contextMenuSelectedTrip = trip;

    if (key === 'trips') { // trips context menu
      this.tripContextMenuItems = [
        {
          name: this.translateService.instant(trip.endTimeTimestamp ? 'Edit Trip' : 'Trip In Progress'),
          icon: 'pencil',
          disabled: trip.endTimeTimestamp ? false : true
        }
      ]
      this.tripContextMenu.openContextMenu(event);
    } else { // punchcards context menu
      this.punchcardContextMenuItems = [
        {
          name: this.translateService.instant(trip.endTimeTimestamp ? 'Edit Punch Card' : 'Punch Card In Progress'),
          icon: 'pencil',
          disabled: trip.endTimeTimestamp ? false : true
        }
      ]
      this.punchcardContextMenu.openContextMenu(event);
    }
  }

  onEditTripClick() {
    const dialog = this.dialog.open(EditTripDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.tripId = this.contextMenuSelectedTrip.id;
    dialog.componentInstance.callback = () => {
      this.contextMenuSelectedTrip = null;
      this.keyContextMenuSelected = null;
      this.onRefresh.emit();
    };
  }

  onEditPunchcardClick() {
    const dialog = this.dialog.open(EditPunchCardDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.punchCardId = this.contextMenuSelectedTrip.id;
    dialog.componentInstance.callback = () => {
      this.contextMenuSelectedTrip = null;
      this.keyContextMenuSelected = null;
      this.onRefresh.emit();
    };
  }
}
