"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var organization_service_1 = require("../organizations/organization.service");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var DriverJobReportFiltersDialogComponent = /** @class */ (function () {
    function DriverJobReportFiltersDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = false;
        this.errors = [];
        this.companyConfig = {
            searchable: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: 'Select Company',
            loadingText: 'Loading Companies...',
            noResultsText: 'No Companies',
            service: organization_service_1.OrganizationService,
            query: {}
        };
        this.model = {
            company: null,
        };
    }
    DriverJobReportFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    DriverJobReportFiltersDialogComponent.prototype.submit = function () {
        this.callback(this.model);
        this.dialogRef.close();
    };
    DriverJobReportFiltersDialogComponent.prototype.onDateChanged = function (dates, type) {
        if (type === void 0) { type = ''; }
        this.model[type] = dates[0];
    };
    return DriverJobReportFiltersDialogComponent;
}());
exports.DriverJobReportFiltersDialogComponent = DriverJobReportFiltersDialogComponent;
