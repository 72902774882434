import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';

import { TripService } from  './trip.service';
import { EditTripDialogComponent } from './edit-trip-dialog/edit-trip-dialog.component';
import { ViewTicketsDialogComponent } from '../checkins/view-tickets-dialog.component';

@Component({
  selector: 'ruckit-trip-detail',
  templateUrl: './trip-detail.component.html',
  styleUrls: ['./trip-detail.component.scss'],
  providers: [TripService]
})
export class TripDetailComponent implements OnInit {
  @Input() trips = [];
  errors = [];
  tripReq;
  actionDropdownOptions = [
    {name: 'Edit', button: true}
  ];
  editTripCallback = (e) => {
    // Update trip data
  }
  viewTicketsCallback = (e) => {
    // Update Ticket Status Icon
  }

  constructor(
    private tripService: TripService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  openTickets(trip) {
    this.tripService.getTrip(trip.id).subscribe(
      _trip => {
        const dialog = this.dialog.open(ViewTicketsDialogComponent, {
          width: '722px',
          data: { trip: _trip }
        });
        dialog.componentInstance.callback = this.viewTicketsCallback;
      },
      err => console.error(err)
    );
  }

  setSelectedOption(data, trip) {
    switch (data.name) {
      case 'Edit':
        this.editTrip(trip);
        break;
    }
  }

  editTrip(trip) {
    this.tripService.getTrip(trip.id).subscribe(
      _trip => {
        const dialog = this.dialog.open(EditTripDialogComponent, {
          width: '430px'
        });
         dialog.componentInstance.tripId = trip.id;
         dialog.componentInstance.callback = this.editTripCallback;
      },
      err => console.error(err)
    );
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }
}
