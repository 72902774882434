<form [formGroup]="editTripForm" (ngSubmit)="submit()" *ngIf="!tripLoading">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Trip</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <div class="tab-links">
    <ul>
      <li
        (click)="activeTab = 'trip-data'"
        [ngClass]="{ active: activeTab === 'trip-data' }"
        translate
      >
        Trip Data
      </li>
      <li
        (click)="activeTab = 'check-ins'"
        [ngClass]="{ active: activeTab === 'check-ins' }"
        translate
      >
        Check-ins
      </li>
      <li
        (click)="activeTab = 'ticket-images'"
        [ngClass]="{ active: activeTab === 'ticket-images' }"
        translate
      >
        Ticket Images
      </li>
    </ul>
  </div>

  <!-- new trip -->
  <div mat-dialog-content class="mat-dialog-content">
    <div class="tab trip-data" [hidden]="activeTab !== 'trip-data'">
      <ng-container *ngIf="lockedFields === false; else lockedFieldsTemplate">
        <div class="form-content">
          <div class="field-group job full-width">
            <label class="required" translate>Job</label>
            <ruckit-dropdown
              #jobDropdown
              [config]="jobDropdownConfig"
              [selectedOption]="job.id"
              (onSelect)="onSelect('job', $event)"
            >
            </ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group trip-date full-width">
            <label class="required" translate>Day</label>
            <input
              type="hidden"
              name="job_event_date"
              required
              [value]="jobEventDate"
            />
            <date-range-picker
              [disabled]="disabledFields.calendar"
              [selectedDates]="[jobEventDate]"
              [highlightedDates]="highlightedDates"
              [config]="jobEventDatePickerOptions"
              (dateChanged)="onJobEventDateChanged($event)"
            ></date-range-picker>
          </div>
        </div>

        <notification *ngIf="jobEventError" (onClose)="jobEventError = false">
          <span translate>No Job Event found for that date!</span>
        </notification>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Carrier</label>
            <ruckit-dropdown
              #carriersDropdown
              [disabled]="!job || !job.id || disabledFields.carrier"
              [config]="carrierDropdownConfig"
              [selectedOption]="edittedTrip.carrier"
              (onSelect)="onSelect('carrier', $event)"
            ></ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Driver</label>
            <ruckit-dropdown
              #driversDropdown
              [disabled]="!edittedTrip.carrier || disabledFields.driver"
              [config]="driverDropdownConfig"
              [selectedOption]="edittedTrip.driver"
              (onSelect)="onSelect('driver', $event)"
            >
            </ruckit-dropdown>
          </div>
        </div>
      </ng-container>

      <div class="form-content">
        <div class="field-group truck full-width">
          <label class="required" translate>Truck</label>
          <ruckit-dropdown
            #truckDropdown
            [disabled]="!edittedTrip.carrier"
            [config]="truckDropdownConfig"
            [selectedOption]="edittedTrip.truck"
            (onSelect)="onSelect('truck', $event)"
          >
          </ruckit-dropdown>
        </div>
      </div>

      <!-- start trip date -->
      <div class="form-content time" formGroupName="start">
        <label class="required" translate>Trip Start Time</label>
        <div class="field-group-row">
          <date-range-picker
            [selectedDates]="[startDate]"
            [highlightedDates]="highlightedDates"
            (dateChanged)="onDateChange(['start', 'date'], $event)"
          >
          </date-range-picker>
          <input type="time" name="start_time" formControlName="time" />
        </div>
      </div>
      <!-- end trip start -->

      <!-- start trip end -->
      <div class="form-content time end-time" formGroupName="end">
        <label translate>Trip End Time</label>
        <div class="field-group-row">
          <date-range-picker
            [selectedDates]="[endDate]"
            [highlightedDates]="highlightedDates"
            (dateChanged)="onDateChange(['end', 'date'], $event)"
          >
          </date-range-picker>
          <input type="time" name="end_time" formControlName="time" />
        </div>
      </div>
      <!-- end trip end date -->
    </div>
    <!-- end trip data -->

    <div class="tab check-ins" [hidden]="activeTab !== 'check-ins'">
      <div class="checkin" formGroupName="loadingCheckin">
        <div class="form-content">
          <div class="field-group-row">
            <div
              class="field-group"
              *ngIf="job?.checkinOptions?.ticketNumberConfig !== 'hidden'"
            >
              <label
                [ngClass]="{
                  required:
                    job?.checkinOptions?.ticketNumberConfig === 'required'
                }"
                translate
                >Loading Ticket #</label
              >
              <input
                type="text"
                name="loading-ticket-number"
                formControlName="ticketNumber"
              />
            </div>
            <div
              class="field-group"
              *ngIf="job?.checkinOptions?.weightConfig !== 'hidden'"
            >
              <label
                [ngClass]="{
                  required: job?.checkinOptions?.weightConfig === 'required'
                }"
                translate
                >Loading Weight</label
              >
              <input
                type="number"
                min="0"
                name="loading-weight"
                formControlName="weight"
              />
            </div>
          </div>
          <ng-container
            *ngIf="job?.checkinOptions?.activeTrackingConfig !== 'hidden'"
          >
            <label
              [ngClass]="{
                required:
                  job?.checkinOptions?.activeTrackingConfig === 'required'
              }"
              translate
              >Loading Time</label
            >
            <div class="field-group-row">
              <date-range-picker
                [selectedDates]="[loadingCheckingDate]"
                [highlightedDates]="highlightedDates"
                (dateChanged)="onDateChange(['loadingCheckin', 'date'], $event)"
              ></date-range-picker>
              <input
                type="time"
                formControlName="time"
                name="loading_time"
                (blur)="editDateTime('loadingTime', $event.target.value)"
              />
            </div>
          </ng-container>
          <div class="ticket-images">
            <ng-container
              *ngIf="
                loadingTicketImages
                  | filterBy : 'isDeleted' : true : true as images
              "
            >
              <ng-container
                *ngIf="
                  loadingSignatures
                    | filterBy : 'isDeleted' : true : true as signatureImages
                "
              >
                <label
                  translate
                  [ngClass]="{
                    required:
                      jobEvent?.job?.checkinOptions?.ticketImageConfig ===
                        'required' ||
                      jobEvent?.job?.checkinOptions?.signatureImageConfig ===
                        'required'
                  }"
                  >Loading Ticket Image(s)</label
                >
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="activeTab = 'ticket-images'"
                  translate
                >
                  {{
                    images.length === 0 && signatureImages.length === 0
                      ? 'Add an Image'
                      : images.length +
                        signatureImages.length +
                        (images.length + signatureImages.length === 1
                          ? ' Image'
                          : ' Images')
                  }}
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div class="checkin" formGroupName="unloadingCheckin">
        <div class="form-content">
          <div class="field-group-row">
            <div
              class="field-group ticket-number"
              *ngIf="job?.checkoutOptions?.ticketNumberConfig !== 'hidden'"
            >
              <label
                [ngClass]="{
                  required:
                    job?.checkoutOptions?.ticketNumberConfig === 'required'
                }"
                translate
                >Unloading Ticket #</label
              >
              <input
                type="text"
                name="unloading-ticket-number"
                formControlName="ticketNumber"
              />
            </div>

            <div
              class="field-group weight"
              *ngIf="job?.checkoutOptions?.weightConfig !== 'hidden'"
            >
              <label
                [ngClass]="{
                  required: job?.checkoutOptions?.weightConfig === 'required'
                }"
                translate
                >Unloading Weight</label
              >
              <input
                type="number"
                min="0"
                name="unloading-weight"
                formControlName="weight"
              />
            </div>
          </div>

          <ng-container
            *ngIf="job?.checkoutOptions?.activeTrackingConfig !== 'hidden'"
          >
            <label
              [ngClass]="{
                required:
                  job?.checkoutOptions?.activeTrackingConfig === 'required'
              }"
              translate
              >Unloading Time</label
            >
            <div class="field-group-row">
              <date-range-picker
                [selectedDates]="[unloadingCheckingDate]"
                [highlightedDates]="highlightedDates"
                (dateChanged)="
                  onDateChange(['unloadingCheckin', 'date'], $event)
                "
              ></date-range-picker>
              <input
                type="time"
                formControlName="time"
                name="unloading_time"
                (blur)="editDateTime('unloadingTime', $event.target.value)"
              />
            </div>
          </ng-container>

          <div class="ticket-images">
            <ng-container
              *ngIf="
                unloadingTicketImages
                  | filterBy : 'isDeleted' : true : true as images
              "
            >
              <ng-container
                *ngIf="
                  unloadingSignatures
                    | filterBy : 'isDeleted' : true : true as signatureImages
                "
              >
                <label
                  translate
                  translate
                  [ngClass]="{
                    required:
                      jobEvent?.job?.checkoutOptions?.ticketImageConfig ===
                        'required' ||
                      jobEvent?.job?.checkoutOptions?.signatureImageConfig ===
                        'required'
                  }"
                  >Unloading Ticket Image(s)</label
                >
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="activeTab = 'ticket-images'"
                  translate
                >
                  {{
                    images.length === 0 && signatureImages.length === 0
                      ? 'Add an Image'
                      : images.length +
                        signatureImages.length +
                        (images.length + signatureImages.length === 1
                          ? ' Image'
                          : ' Images')
                  }}
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- END unloadingCheckin -->
    </div>

    <div class="tab ticket-images" *ngIf="activeTab === 'ticket-images'">
      <multiple-images-edit
        [withAnimation]="false"
        [loadingImages]="loadingTicketImages"
        [unloadingImages]="unloadingTicketImages"
        [loadingSignatureImages]="loadingSignatures"
        [unloadingSignatureImages]="unloadingSignatures"
        [requiredFields]="requiredImages"
        (onLoadingImagesChange)="
          onImagesChange('loadingTicketImages', 'loadingCheckin', $event)
        "
        (onUnloadingImagesChange)="
          onImagesChange('unloadingTicketImages', 'unloadingCheckin', $event)
        "
        (onLoadingSignatureImagesChange)="
          onImagesChange('loadingSignatures', 'loadingCheckin', $event)
        "
        (onUnloadingSignatureImagesChange)="
          onImagesChange('unloadingSignatures', 'unloadingCheckin', $event)
        "
        (onUploading)="fileUploading($event)"
      ></multiple-images-edit>
    </div>
  </div>

  <div class="error-container" *ngIf="errors && errors.length > 0">
    <notification *ngFor="let error of errors">
      <span class="nested-error">{{ error }}</span>
    </notification>
  </div>
  <div
    class="error-container"
    *ngIf="editTripForm.errors && editTripForm.errors.globalTripTimeError"
  >
    <notification>
      <ng-container [ngSwitch]="editTripForm.errors.globalTripTimeError">
        <span *ngSwitchCase="'startLoadingTime'" translate
          >Loading Time can't be earlier than Trip Start Time</span
        >
        <span *ngSwitchCase="'endLoadingTime'" translate
          >Unloading Time can't be earlier than Trip Start Time / Loading
          time</span
        >
        <span *ngSwitchDefault translate
          >End Time can't be earlier than Trip Start Time / Loading Time /
          Unloading Time</span
        >
      </ng-container>
    </notification>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="
        !editTripForm.valid ||
        loading ||
        uploading ||
        (firstLoad && !lockedFields)
      "
      [ngClass]="{ loading: loading }"
      translate
    >
      Update Trip
    </button>
  </div>
</form>

<ng-template #lockedFieldsTemplate>
  <div class="eod-locked-fields">
    <div class="locked-field">
      <label class="required" translate>Job</label>
      <div>{{ job.name }}</div>
    </div>

    <div class="locked-field">
      <label class="required" translate>Day</label>
      <div>{{ jobEventDate | date : 'MMM dd, yyyy' }}</div>
    </div>

    <div class="locked-field">
      <label class="required" translate>Carrier</label>
      <div>{{ carrier?.name }}</div>
    </div>

    <div class="locked-field">
      <label class="required" translate>Driver</label>
      <div>{{ driver?.name }}</div>
    </div>
    <div class="enable-editing" *ngIf="lockedFields">
      <span (click)="enableEditing()" translate>
        <mat-icon class="pencil-icn">edit</mat-icon>
        Enable Editing
      </span>
    </div>
  </div>
</ng-template>

<div *ngIf="tripLoading">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Trip</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div class="mat-dialog-content">
    <my-loader class="loader"></my-loader>
  </div>
  <div class="modal-footer"></div>
</div>
