import { Assignment } from './assignment';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { remove, find as _find } from 'lodash';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ControlOption } from '../shared/control-option';
import { JobEvent } from '../job-events/job-event';
import { Device } from '../shared/device';

@Component({
  selector: 'punch-card-assignments',
  templateUrl: './punch-card-assignments.component.html',
  styleUrls: ['./punch-card-assignments.component.scss'],
})
export class PunchCardAssignmentsComponent implements OnInit {
  device: Device = new Device();
  @Input() assignments: Assignment[];
  @Input() jobEvent: JobEvent;
  @Input() hours: any;
  @Input() loading = false;
  @Output() activeAssignment: EventEmitter<any> = new EventEmitter<any>();
  expandedDrivers = [];
  activeStatuses: any[] = [
    {
      name: 'Unloading',
      active: false
    }, {
      name: 'Enroute to Unloading',
      active: false
    }, {
      name: 'Loading',
      active: false
    }, {
      name: 'Enroute to Loading',
      active: false
    }
  ];
  @Input() controlState = 'trips';
  @Input() controls: ControlOption[] = [];

  constructor (private deviceDetectorService: DeviceDetectorService) { }

  ngOnInit() {
    this.getAvailableStatuses(this.assignments);
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  getAvailableStatuses(assignments) {
    assignments.forEach(assignment => {
      if (assignment.tripStats.lastValues.unloading || assignment.tripStats.lastValues.status === 'unloading') {
        this.activeStatuses[0].active = true;
      } else if (assignment.tripStats.lastValues.enrouteUnloading || assignment.tripStats.lastValues.status === 'enroute_unloading') {
        this.activeStatuses[1].active = true;
      } else if (assignment.tripStats.lastValues.loading || assignment.tripStats.lastValues.status === 'loading') {
        this.activeStatuses[2].active = true;
      } else if (assignment.tripStats.lastValues.enrouteLoading || assignment.tripStats.lastValues.status === 'enroute_loading') {
        this.activeStatuses[3].active = true;
      }
    });
  }

  expandDriver(driver) {
    if (_find(this.expandedDrivers, {id: driver.id})) {
      remove(this.expandedDrivers, {id: driver.id});
    } else {
      this.expandedDrivers.push(driver);
    }
  }

  expandedDriver(driver): boolean {
    if (_find(this.expandedDrivers, {id: driver.id})) {
      return true;
    }
    return false;
  }

  startHour(assignment, hour): boolean {
    let start = false;
      if (assignment.driver && assignment.driver.punchCard) {
      let time = moment(assignment.driver.punchCard.startTimeTimestamp).format('h a');
      if (time === hour) { start = true; }
      }
    return start;
    }

  completeHour(assignment, hour, index): boolean {
    let complete = false;
    let currentTime = moment();

    if (assignment.driver && assignment.driver.punchCard) {
      let startTime, endTime, timeAtHour;

      if (assignment.driver.punchCard.startTimeTimestamp) {
        startTime = new Date(assignment.driver.punchCard.startTimeTimestamp);
        let _date = moment(startTime).format('YYYY-MM-DD');
        timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
        timeAtHour.add(3599, 'seconds');
      } else {
        return false;
      }

      if (assignment.driver.punchCard.endTimeTimestamp) {
        endTime = new Date(assignment.driver.punchCard.endTimeTimestamp);
      }

      if (startTime < timeAtHour && !endTime && timeAtHour < currentTime) {
        complete = true;
      } else if (startTime < timeAtHour && timeAtHour < endTime) {
        complete = true;
      }
    }
    return complete;
  }

  styles(assignment, hour, index) {
    let style = {};

    let startStyle = this.startStyles(assignment, hour);
    if (startStyle) {
      style = Object.assign({}, style, startStyle);
    }
    let activeStyle = this.activeStyles(assignment, hour, index);
    if (activeStyle) {
      style = Object.assign({}, style, activeStyle);
    }
    return style;
  }

  startStyles(assignment, hour) {
    if (this.startHour(assignment, hour)) {
      let timeOffset;
      if (assignment.driver.punchCard.startTimeTimestamp) {
        let startTime = new Date(assignment.driver.punchCard.startTimeTimestamp);
        let minutes = Number(moment(startTime).format('mm'));
        if (minutes <= 5) {
          return;
        } else if (minutes <= 15) {
          timeOffset = '25px';
        } else if (minutes <= 30) {
          timeOffset = '35px';
        } else if (minutes <= 45) {
          timeOffset = '45px';
        } else if (minutes <= 55) {
          timeOffset = '50px';
        }
      }

      return {'margin-left': timeOffset};
    }
  }

  activeStyles(assignment, hour, index) {
    if (!this.startHour(assignment, hour) && !this.completeHour(assignment, hour, index)) {
      let timeOffset;
      if (
        !assignment.driver ||
        !assignment.driver.punchCard ||
        !assignment.driver.punchCard.endTimeTimestamp
      ) {
        let currentTime = moment();
        let minutes = Number(currentTime.format('mm'));
        if (minutes <= 5) {
          timeOffset = '75px';
        } else if (minutes <= 15) {
          timeOffset = '55px';
        } else if (minutes <= 30) {
          timeOffset = '45px';
        } else if (minutes <= 45) {
          timeOffset = '25px';
        } else if (minutes <= 55) {
          timeOffset = '10px';
        }
      }

      return {'margin-right': timeOffset};
    }
  }

  timeOverlayStyles(hours) {
    let style = {};
    const width = 90;
    let hoursSkipped = 0;
    let timeOffset = 0;
    let currentTime = moment();
    let hourTime = moment(currentTime.format('YYYY-MM-DD') + ' ' + hours[0], 'YYYY-MM-DD h:mm a');

    while (hourTime < currentTime) {
      hourTime.add(1, 'hour');
      hoursSkipped += 1;
      timeOffset = width * (hoursSkipped - 1);

      let minutes = Number(currentTime.format('mm'));
      if (minutes <= 5) {
        timeOffset += 15;
      } else if (minutes <= 15) {
        timeOffset += 35;
      } else if (minutes <= 30) {
        timeOffset += 45;
      } else if (minutes <= 45) {
        timeOffset += 65;
      } else if (minutes <= 55) {
        timeOffset += 80;
      } else {
        timeOffset += 85;
      }
    }

    if (timeOffset > 0) {
      style = {'left': timeOffset + 'px'};
      }

    return style;
  }

  selectActiveAssignment(assignment): void {
    this.activeAssignment.emit(assignment);
  }

  /**
   * @param  {} control
   * @param  {} event
   * @returns void
   * This function is called on toggling the segmented controls: GPS, Trips, or Hourly
   */
  onSelectToggle(control: ControlOption, event: MouseEvent): void {
    const checkbox = event.target as HTMLInputElement;
    this.controls.forEach(_control => { _control.selected = false; });
    control.selected = checkbox.checked;
    this.controlState = control.name;
  }
}
