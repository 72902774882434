import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

// libraries
import { MatDialog } from '@angular/material';
import { find as _find } from 'lodash';
import * as moment from 'moment';

// services
import { parseErrors } from '../../shared/api.service';
import { DriverService } from '../../drivers/driver.service';
import { TruckService } from '../../trucks/truck.service';
import { ShiftService } from '../shift.service';

// components
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'new-shift',
  templateUrl: './new-shift.component.html',
  styleUrls: ['./new-shift.component.scss']
})

export class NewShiftComponent implements OnInit, OnDestroy {
  model = {
    id: '',
    driver: null,
    truck: null,
    startTime: null,
    startDate: null,
    endTime: null,
    endDate: null,
    adjustment: null
  };
  loading = false;
  returnTo: string;
  private queryParamsSub;
  errors = [];

  driverDropdownConfig = {
    service: DriverService,
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    sortBy: 'profile__first_name,profile__last_name',
    includeFullObject: true,
    query: {
      all_carriers: 'True'
    }
  };
  @ViewChild('truckDropdown', { static: false })
  truckDropdown: RuckitDropdownComponent;
  truckDropdownConfig = {
    service: TruckService,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'displayName',
    query: {},
    sortBy: 'name'
  };

  constructor(
    private route: ActivatedRoute,
    private shiftService: ShiftService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.queryParamsSub = this.route.queryParams && this.route.queryParams.forEach((params: Params) => {
      if (params['returnTo'] && params['returnTo'].length) {
        this.returnTo = params['returnTo'];
      }
    });
  }

  ngOnDestroy() {
    if (this.queryParamsSub && typeof this.queryParamsSub.unsubscribe === 'function') {
      this.queryParamsSub.unsubscribe();
    }
  }

  isValid(): boolean {
    let validTimes = false;
    if (this.model.endTime && this.model.startTime) {
      validTimes = !(moment(this.model.endTime).isBefore(moment(this.model.startTime)));
    }
    return !!this.model.driver && !!this.model.startTime && (validTimes || !this.model.endTime);
  }

  submit(): void {
    if (this.loading || !this.isValid()) { return; }
    this.loading = true;

    let model = {
      driver: this.model.driver && this.model.driver.id,
      truck: this.model.truck && this.model.truck.id,
      startTime: this.model.startTime && this.model.startTime.toISOString(),
      endTime: this.model.endTime && this.model.endTime.toISOString(),
      adjustment: this.model.adjustment
    };
    this.shiftService.save(model).subscribe(() => {
      this.loading = false;
      if (this.returnTo && this.returnTo.length) {
        if (this.returnTo.includes('?')) {
          let route = this.returnTo.split('?')[0];
          let params = {};
          this.returnTo.split('?')[1].split('&').forEach(param => {
            let paramData = param.split('=');
            params[paramData[0]] = paramData[1];
          });
          this.router.navigate([route], { queryParams: params });
        } else { this.router.navigate([this.returnTo]); }
      } else {
        this.router.navigate(['/shifts']);
      }
    }, (err) => {
      this.loading = false;
      this.errors = parseErrors(err);
    });
  }

  onDateChanged(field: 'startDate' | 'endDate', dates: Date[]) {
    this.model[field] = dates[0];
    this.editDateTime(field, dates[0]);
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'driver':
        this.model.truck = null;
        this.model.driver = e;
        if (e.carrier) {
          this.truckDropdown.getRecords({
            carrier: e.carrier.id
          });
        }
        break;
      case 'truck':
        this.model.truck = e;
        break;
    }
    this.model[filterType] = e;
  }

  /**
   * Makes an edit to either the date or time for the startTime or endTime field
   * and pairs that value with the date to set the overall timestamp.
   *
   * @param {string} field The datetime field we will apply the edit to
   * @param {any} value The edit value for the datetime
   */
  editDateTime(field: 'startTime' | 'endTime' | 'startDate' | 'endDate', value: any) {
    if (['startTime', 'endTime'].includes(field) && value) {
      let dateField = 'startTime' === field ? 'startDate' : 'endDate';
      let date = this.model[dateField] || new Date();
      if (!!this.model[dateField]) { this.model[dateField] = date; }
      this.model[field] = moment(
        `${moment(date).format('YYYY-MM-DD')} ${value}`, 'YYYY-MM-DD HH:mm'
      ).toDate();
    } else if (['startDate', 'endDate'].includes(field) && value) {
      let timeField = 'startDate' === field ? 'startTime' : 'endTime';
      let time = this.model[timeField];
      this.model[field] = moment(value, 'YYYY-MM-DD').toDate();
      if (time) {
        this.model[timeField] = moment(
          `${moment(this.model[field]).format('YYYY-MM-DD')} ${moment(time).format('HH:mm')}`, 'YYYY-MM-DD HH:mm'
        ).toDate();
      }
    } else if (field && value) {
      this.model[field] = moment(value).toDate();
    }
  }
}
