"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
// angular material
var material_1 = require("@angular/material");
var moment = require("moment");
var lodash_1 = require("lodash");
var DateRangePickerComponent = /** @class */ (function () {
    function DateRangePickerComponent() {
        var _this = this;
        this.defaultConfig = {
            multipleDates: false,
            mobile: false,
            decreaseControl: true,
            increaseControl: true,
            rangeMode: false,
            min: null,
            max: null,
            showSelectedDate: true,
        };
        this.dateFormat = 'MMM D, YYYY';
        this.selectedDates = [];
        this.disabledDates = [];
        this.highlightedDates = [];
        this.disabled = false;
        this.config = this.defaultConfig;
        this.dateChanged = new core_1.EventEmitter();
        this.shiftActive = false;
        this.lastSelectedDateIndex = -1;
        this.allSubscriptionsToUnsubscribe = [];
        this.dateClass = function (d) {
            var date = d.toDate();
            var selectedIndex = lodash_1.findIndex(_this.selectedDates, function (s) {
                if (!s) {
                    return false;
                }
                s = typeof s === 'string' ? moment(s).toDate() : s;
                return s.toDateString() === date.toDateString();
            });
            var disabledIndex = lodash_1.findIndex(_this.disabledDates, function (s) {
                if (!s) {
                    return false;
                }
                s = typeof s === 'string' ? moment(s).toDate() : s;
                return s.toDateString() === date.toDateString();
            });
            var highlightedIndex = lodash_1.findIndex(_this.highlightedDates, function (s) {
                if (!s) {
                    return false;
                }
                s = typeof s === 'string' ? moment(s).toDate() : s;
                return s.toDateString() === date.toDateString();
            });
            if (_this.selectedDates && selectedIndex !== -1) {
                return 'selected-date';
            }
            else if (_this.highlightedDates && highlightedIndex !== -1) {
                return 'highlighted-date';
            }
            return undefined;
        };
        this.dateFilter = function (d) {
            var date = d.toDate();
            if (_this.disabledDates && _this.disabledDates.length) {
                var isDisabled = _this.disabledDates.some(function (disabledDate) {
                    if (!disabledDate || !moment(disabledDate).isValid()) {
                        return false;
                    }
                    return moment(disabledDate).toDate().toDateString() === date.toDateString();
                });
                return !isDisabled;
            }
            return true;
        };
    }
    DateRangePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.config = __assign({}, this.defaultConfig, this.config);
        if (this.selectedDates && this.selectedDates.length) {
            setTimeout(function () {
                _this.updateDisplayValue();
            });
        }
        if (this.config.multipleDates) {
            this.allSubscriptionsToUnsubscribe.push(rxjs_1.Observable.fromEvent(document, 'keydown').subscribe(function (e) {
                _this.shiftActive = e.key === 'Shift';
            }));
            this.allSubscriptionsToUnsubscribe.push(rxjs_1.Observable.fromEvent(document, 'keyup').subscribe(function (e) {
                _this.shiftActive = e.key === 'Shift' && false;
            }));
        }
    };
    DateRangePickerComponent.prototype.ngOnChanges = function (changes) {
        if (changes['selectedDates']) {
            this.updateDisplayValue(false);
        }
    };
    DateRangePickerComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (s) { return s.unsubscribe(); });
    };
    DateRangePickerComponent.prototype.decreaseDate = function () {
        this.increaseDate(-1);
    };
    DateRangePickerComponent.prototype.increaseDate = function (amount) {
        if (amount === void 0) { amount = 1; }
        var date = new Date();
        if (this.selectedDates && this.selectedDates.length === 1) {
            date = this.selectedDates[0];
            date = moment(date).add(amount, 'day').toDate();
        }
        this.selectedDates = [date];
        this.updateDisplayValue();
    };
    DateRangePickerComponent.prototype.addEvent = function (event) {
        var date, range;
        if (event.value && event.value['begin'] && event.value['end']) {
            range = event.value;
        }
        else {
            date = new Date(event.value);
        }
        // tried to simplify this logic but it's a bit tricky
        if (this.config && this.config.multipleDates && this.selectedDates) {
            var dateIndex = lodash_1.findIndex(this.selectedDates, function (s) { return s.toDateString() === date.toDateString(); });
            if (dateIndex !== -1) {
                if (this.shiftActive && this.lastSelectedDateIndex > -1) {
                    this.selectedDates.splice(this.lastSelectedDateIndex, dateIndex - this.lastSelectedDateIndex + 1);
                    this.selectedDates = lodash_1.clone(this.selectedDates);
                }
                else {
                    this.selectedDates.splice(dateIndex, 1);
                    this.selectedDates = lodash_1.clone(this.selectedDates);
                    this.lastSelectedDateIndex = dateIndex;
                }
            }
            else if (this.shiftActive) {
                var dateMoments = this.selectedDates.map(function (d) { return moment(d); });
                if (moment.max(dateMoments).isBefore(moment(date))) {
                    var dates = [];
                    var addedDate = moment.max(dateMoments);
                    while (addedDate.isBefore(moment(date))) {
                        addedDate = addedDate.add(1, 'days');
                        dates.push(addedDate.toDate());
                    }
                    this.selectedDates = this.selectedDates.concat(dates);
                }
            }
            else {
                this.selectedDates.push(date);
            }
        }
        else if (this.config && this.config.rangeMode && range) {
            this.selectedDates = [range['begin'].toDate(), range['end'].toDate()];
        }
        else {
            this.selectedDates = [date];
        }
        event.target.writeValue(null); // This triggers a datepicker ui refresh
        this.updateDisplayValue();
    };
    DateRangePickerComponent.prototype.updateDisplayValue = function (emit) {
        var _this = this;
        if (emit === void 0) { emit = true; }
        var minDate = lodash_1.min(this.selectedDates);
        var maxDate = lodash_1.max(this.selectedDates);
        this.displayValue = lodash_1.uniq([minDate, maxDate])
            .filter(Boolean)
            .map(function (d) { return moment(d).format(_this.dateFormat); })
            .join(' - ');
        if (emit) {
            this.dateChanged.emit(this.selectedDates);
        }
    };
    return DateRangePickerComponent;
}());
exports.DateRangePickerComponent = DateRangePickerComponent;
