import { Component, Input } from '@angular/core';
import { Note } from './note.types';
import { NotesService } from './notes.service';

@Component({
  selector: 'notes',
  templateUrl: 'notes.component.html',
  styleUrls: ['notes.component.scss'],
})
export class NotesComponent {
  @Input() note: Note;
  @Input() name = 'notes';
  @Input() disabled = false;
  @Input() saveOnFocusOut = true;

  // job | jobevent | jobevent_share
  @Input() saveProperty: { [key: string]: string };

  constructor(private notesService: NotesService) {}

  onFocusOut(): void {
    if (this.saveOnFocusOut) {
      if (this.note && this.note.id) {
        if (this.note.text) {
          this.notesService
            .patchNote(this.note.id, { text: this.note.text })
            .subscribe();
        } else {
          this.notesService
            .deleteNote(this.note.id)
            .subscribe(() => delete this.note.id);
        }
      } else {
        this.notesService
          .saveNote({ ...this.note, ...this.saveProperty })
          .subscribe((createdNote: Note) => (this.note = createdNote));
      }
    }
  }
}
