"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var project_1 = require("./project");
var organization_1 = require("../organizations/organization");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ProjectSerializer = /** @class */ (function () {
    function ProjectSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Job
    */
    ProjectSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var project = new project_1.Project();
        if (!json) {
            return project;
        }
        project.id = json.id;
        project.name = json.name;
        project.ownerOrganization = json.ownerOrganization;
        project.poNumber = json.poNumber;
        if (json.customerOrganization && typeof (json.customerOrganization) === 'object') {
            project.customerOrganization = new organization_1.Organization(json.customerOrganization);
        }
        else if (json.customerOrganization) {
            project.customerOrganization = new organization_1.Organization({ id: json.customerOrganization });
        }
        else {
            project.customerOrganization = new organization_1.Organization({ id: '', name: '' });
        }
        return project;
    };
    /**
    * @param  {Project} project
    * @returns any
    */
    ProjectSerializer.prototype.toJson = function (project) {
        var json = {
            id: project.id,
            name: project.name,
            customerOrganization: project.customerOrganization && project.customerOrganization.id,
            ownerOrganization: project.ownerOrganization,
            poNumber: project.poNumber
        };
        return decamelizeKeysDeep(json);
    };
    return ProjectSerializer;
}());
exports.ProjectSerializer = ProjectSerializer;
