"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var map_1 = require("@ngui/map");
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var driver_serializer_1 = require("./../../drivers/driver.serializer");
var static_data_1 = require("../../shared/static-data");
var map_service_1 = require("../../map/map.service");
var index_1 = require("../../shared/index");
var assignment_service_1 = require("../../assignments/assignment.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var location_service_1 = require("../../locations/location.service");
var preference_service_1 = require("../../preferences/preference.service");
var key_codes_enum_1 = require("../../shared/enums/key-codes.enum");
var truck_serializer_1 = require("../../trucks/truck.serializer");
var app_constants_1 = require("../../../app/app.constants");
/**
 * Active Trucks Component: Displays a map with truck/driver markers for each
 * location update. The map also includes job-site locations, route polylines,
 * heatmaps, and info windows with additional marker detail.
 */
var ActiveTrucksComponent = /** @class */ (function () {
    function ActiveTrucksComponent(mapService, locationService, jobEventService, assignmentService, preferenceService, authenticationService, cdr, dialog) {
        this.mapService = mapService;
        this.locationService = locationService;
        this.jobEventService = jobEventService;
        this.assignmentService = assignmentService;
        this.preferenceService = preferenceService;
        this.authenticationService = authenticationService;
        this.cdr = cdr;
        this.dialog = dialog;
        this.locations = [];
        this.locationUpdates = [];
        this.paverLocationUpdates = [];
        this.routes = [];
        this.parsedRoutes = [];
        this.polygons = [];
        this.loading = true;
        this.mapLoading = true;
        this.errors = [];
        this.customLocationMarkers = [];
        this.customUpdateMarkers = [];
        this.customPaverUpdateMarkers = [];
        this.coordinates = [];
        this.mapControls = {
            traffic: false,
            heatmap: false,
            heatmapRadius: 20
        };
        this.showPending = true;
        this.mapOptions = {
            disableDefaultUI: true,
            scrollwheel: true,
            fullscreenControl: true,
            zoomControl: true,
            mapTypeControl: true,
            fullscreenControlOptions: { position: 12 },
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'hybrid']
            },
            streetViewControl: false
        };
        this.markerType = 'driver-initials';
        this.markerStatus = 'each-segment';
        this.heatmapPoints = [];
        this.search = '';
        this.searching = false;
        this.searchChanged = new rxjs_1.Subject();
        this.driverListOpen = false;
        this.hours = [];
        this.assignments = {
            items: [],
            errors: [],
            loading: false,
        };
        this.activeJobs = [];
        this.activeJobEvents = [];
        this.punchCardFlag = false;
        this.driversList = [];
        this.assignmentDetails = {};
        this.preferenceReqs = {};
        this.preferences = {};
        this.loadingMapPreferences = true;
        this.assignmentTitle = new core_1.EventEmitter();
        this.refreshMapMins = 2;
    }
    ActiveTrucksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapLoading = true;
        this.getPreferences();
        this.getPreferences('GeneralUserPreferences');
        var activeTrucksMapRefresh = this.authenticationService.getFeature('activeTrucksMapRefresh') || app_constants_1.DEFAULT_MAP_REFRESH_TIME;
        this.refreshMapMins = activeTrucksMapRefresh / 60 / 1000;
        this.jobEventsTimer = rxjs_1.timer(activeTrucksMapRefresh, activeTrucksMapRefresh);
        this.jobEventsTimerSub = this.jobEventsTimer.subscribe(function (t) {
            _this.getMapLocationUpdates(false, false);
        });
        this.organization = this.authenticationService.getOrganization();
        if (window.innerWidth <= 900) {
            this.loadAssignments();
        }
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.getMapLocationUpdates(_this.mapLoading, true, true);
        });
    };
    ActiveTrucksComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.jobEventsTimerSub && typeof this.jobEventsTimerSub.unsubscribe === 'function') {
            this.jobEventsTimerSub.unsubscribe();
        }
        if (this.jobEventsTimer) {
            this.jobEventsTimer = null;
        }
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        Object.keys(this.preferenceReqs).forEach(function (preferenceReqKey) {
            if (_this.preferenceReqs[preferenceReqKey] && typeof _this.preferenceReqs[preferenceReqKey].unsubscribe === 'function') {
                try {
                    _this.preferenceReqs[preferenceReqKey].unsubscribe();
                }
                catch (e) { }
            }
        });
    };
    ActiveTrucksComponent.prototype.ngOnChanges = function (changes) {
        if (!this.assignmentToggle) {
            this.assignmentDetails = {};
            this.assignmentTitle.emit(null);
        }
    };
    ActiveTrucksComponent.prototype.onResize = function (event) {
        if (window.innerWidth <= 900 && this.assignments.loading !== false) {
            this.loadAssignments();
        }
    };
    ActiveTrucksComponent.prototype.onSearchboxKeypress = function (event) {
        if (event.code === key_codes_enum_1.KeyCodes.Enter) {
            this.changeSearch(this.search);
            event.preventDefault();
        }
    };
    ActiveTrucksComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
        this.assignmentDetails = {};
        this.assignmentTitle.emit(null);
    };
    ActiveTrucksComponent.prototype.clearSearch = function () {
        this.searching = false;
        this.search = '';
        this.getMapLocationUpdates(this.mapLoading, true, true);
        if (this.nguiMapComponent) {
            this.nguiMapComponent.closeInfoWindow('driver-info-window');
            this.nguiMapComponent.closeInfoWindow('truck-info-window');
        }
    };
    ActiveTrucksComponent.prototype.getMapLocationUpdates = function (loading, centerMap, openInfoWindow, query) {
        var _this = this;
        if (loading === void 0) { loading = true; }
        if (centerMap === void 0) { centerMap = false; }
        if (openInfoWindow === void 0) { openInfoWindow = false; }
        if (query === void 0) { query = {}; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.loading = loading;
        this.startDate = new Date();
        var endDate = new Date();
        this.startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        if (this.showPending) {
            query = __assign({}, query, { 'exclude_pending': 'True' });
        }
        this.mapReq = this.mapService.getLocationUpdates(__assign({ driver__assignments__jobevent__shift1_start__gte: this.startDate.toISOString(), driver__assignments__jobevent__shift1_start__lte: endDate.toISOString(), ordering: 'jobs__name', search: this.search }, query), 'dashboard').subscribe(function (locationUpdates) {
            _this.resetMarkers();
            _this.locations = locationUpdates.locations;
            _this.locationUpdates = locationUpdates.locationUpdates.map(function (update) {
                if (update.driver) {
                    update.driver = new driver_serializer_1.DriverSerializer().fromJson(update.driver);
                }
                return update;
            });
            _this.paverLocationUpdates = locationUpdates.paverLocationUpdates.map(function (update) {
                if (update.truck) {
                    update.truck = new truck_serializer_1.TruckSerializer().fromJson(update.truck);
                }
                return update;
            });
            _this.routes = locationUpdates.routes;
            _this.parseRoutes();
            _this.polygons = _this.locations.map(function (location) {
                return location.paths;
            }).filter(Boolean);
            _this.loading = false;
            _this.mapData = locationUpdates;
            if (centerMap) {
                setTimeout(function () { _this.centerMap(); }, 1000);
            }
            if (openInfoWindow && _this.search && _this.search.length &&
                _this.locationUpdates.length === 1 && window.innerWidth > 900) {
                _this.openDriverInfoWindow(0, _this.locationUpdates[0]);
            }
            else if (!_this.search.length || _this.locationUpdates.length === 0) {
                if (_this.nguiMapComponent) {
                    _this.nguiMapComponent.closeInfoWindow('driver-info-window');
                }
            }
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    ActiveTrucksComponent.prototype.parseRoutes = function () {
        if (this.map) {
            this.parsedRoutes = this.routes.map(function (route) {
                if (route) {
                    try {
                        return new google.maps.geometry.encoding.decodePath(route);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            }).filter(Boolean);
        }
    };
    ActiveTrucksComponent.prototype.centerMap = function () {
        var _centerOnOrganization = true;
        if (this.map) {
            var bounds_1 = new google.maps.LatLngBounds();
            if (this.locations && this.locations.length) {
                this.locations.forEach(function (location) {
                    var _lat = location.location && location.location.coordinates[1];
                    var _lng = location.location && location.location.coordinates[0];
                    if (_lat && _lng) {
                        var latLng = new google.maps.LatLng(_lat, _lng);
                        bounds_1.extend(latLng);
                    }
                });
                _centerOnOrganization = false;
            }
            var locationUpdates = this.locationUpdates.map(function (locationUpdate) {
                var _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
                var _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
                if (_lat && _lng) {
                    return new google.maps.LatLng(_lat, _lng);
                }
            });
            for (var i = 0, latLngLen = locationUpdates.length; i < latLngLen; i++) {
                var list = locationUpdates[i];
                bounds_1.extend(list);
            }
            var paverLocationUpdates = this.paverLocationUpdates.map(function (locationUpdate) {
                var _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
                var _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
                if (_lat && _lng) {
                    return new google.maps.LatLng(_lat, _lng);
                }
            });
            for (var i = 0, latLngLen = paverLocationUpdates.length; i < latLngLen; i++) {
                var list = locationUpdates[i];
                bounds_1.extend(list);
            }
            if ((locationUpdates && locationUpdates.length) || (paverLocationUpdates && paverLocationUpdates.length)) {
                _centerOnOrganization = false;
            }
            if (_centerOnOrganization) {
                this.centerOnOrganization();
            }
            this.map.fitBounds(bounds_1);
        }
    };
    ActiveTrucksComponent.prototype.centerOnOrganization = function () {
        var _this = this;
        if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
            this.organizationLocationReq.unsubscribe();
        }
        this.locationService.getLocationByAddress(this.organization.street + ", " + this.organization.location).subscribe(function (coords) {
            if (coords && coords[0] && coords[0].geometry) {
                var fallbackCenter = new google.maps.LatLng(coords[0].geometry.location.lat, coords[0].geometry.location.lng);
                _this.mapOptions = static_data_1.mapOptions({
                    disableDefaultUI: true,
                    scrollwheel: true,
                    fullscreenControl: true,
                    streetViewControl: false,
                    center: fallbackCenter,
                    geoFallbackCenter: fallbackCenter
                }, {}, {
                    mapStyle: 'google-map-style'
                });
            }
        }, function (err) { return _this.errors = ['Cannot get your organizations location']; });
    };
    ActiveTrucksComponent.prototype.initMap = function (event) {
        if (event) {
            this.map = event;
        }
        this.parseRoutes();
    };
    ActiveTrucksComponent.prototype.onCustomLocationMarkerInit = function (customMarker) {
        this.customLocationMarkers.push(customMarker);
    };
    ActiveTrucksComponent.prototype.onCustomUpdateMarkerInit = function (customMarker) {
        this.customUpdateMarkers.push(customMarker);
    };
    ActiveTrucksComponent.prototype.onCustomPaverUpdateMarkerInit = function (customMarker) {
        this.customPaverUpdateMarkers.push(customMarker);
    };
    ActiveTrucksComponent.prototype.onAssignmentTitle = function ($event) {
        this.assignmentTitle.emit($event);
    };
    ActiveTrucksComponent.prototype.onOpenDriverInfoWindow = function (_a) {
        var index = _a.index, update = _a.update;
        this.openDriverInfoWindow(index, update);
    };
    ActiveTrucksComponent.prototype.openDriverInfoWindow = function (index, update) {
        this.driverInfoWindowModel = update;
        if (update && update.driver) {
            var markers = lodash_1.filter(this.customUpdateMarkers, function (m) {
                return m.htmlEl.id === update.driver.id;
            });
            if (markers && markers.length) {
                this.nguiMapComponent.openInfoWindow('driver-info-window', markers[0]);
            }
        }
    };
    ActiveTrucksComponent.prototype.openTruckInfoWindow = function (index, update) {
        this.truckInfoWindowModel = update;
        if (update && update.truck) {
            var markers = lodash_1.filter(this.customPaverUpdateMarkers, function (m) {
                return m.htmlEl.id === update.truck.id;
            });
            if (markers && markers.length) {
                this.nguiMapComponent.openInfoWindow('truck-info-window', markers[0]);
            }
        }
    };
    ActiveTrucksComponent.prototype.openLocationInfoWindow = function (index, location) {
        this.locationInfoWindowModel = {
            title: location.displayName,
            address: location.street,
            city: location.city,
            state: location.state,
            zip: location.zip
        };
        this.nguiMapComponent.openInfoWindow('location-info-window', this.customLocationMarkers[index]);
    };
    ActiveTrucksComponent.prototype.toggleTraffic = function () {
        if (this.mapControls.traffic) {
            this.mapControls.traffic = false;
        }
        else {
            this.mapControls.traffic = true;
        }
        this.cdr.detectChanges();
    };
    ActiveTrucksComponent.prototype.toggleHeatmap = function () {
        if (this.mapControls.heatmap) {
            this.mapControls.heatmap = false;
            this.heatmap.setMap(null);
        }
        else {
            this.mapControls.heatmap = true;
            this.heatmap.setMap(this.map);
        }
        this.cdr.detectChanges();
    };
    ActiveTrucksComponent.prototype.onHeatmapInitialized = function (e) {
        if (e === void 0) { e = null; }
        this.heatmap = e;
        this.coordinates = [];
        this.updateHeatmap();
    };
    ActiveTrucksComponent.prototype.onMapIdle = function (e) {
        if (e === void 0) { e = null; }
        if (this.heatmap) {
            this.updateHeatmap();
        }
    };
    ActiveTrucksComponent.prototype.updateHeatmap = function () {
        // this.locationUpdateService.coordinates(this.map.getBounds(), {
        //   jobevent: this.jobEvent && this.jobEvent.id,
        //   page_size: 2500
        // }).subscribe(coordinates => {
        //   this.coordinates = coordinates;
        //   this.heatmapPoints = coordinates && coordinates.map((coordinate) => {
        //     return { location: new google.maps.LatLng(coordinate.lon, coordinate.lat), weight: coordinate.weight || 1 };
        //   });
        //   if (this.heatmapPoints && this.heatmapPoints.length > 0) {
        //     this.heatmap.setData(this.heatmapPoints);
        //   }
        //   this.cdr.detectChanges();
        // }, err => { console.error(err); });
    };
    ActiveTrucksComponent.prototype.changeHeatmapRadius = function (e) {
        if (e === void 0) { e = null; }
        if (e && this.mapControls) {
            this.mapControls.heatmapRadius = e.target.value;
            this.heatmap.set('radius', e.target.value);
            // this.cdr.detectChanges();
        }
    };
    ActiveTrucksComponent.prototype.openDriverList = function () {
        this.driverListOpen = !this.driverListOpen;
    };
    ActiveTrucksComponent.prototype.loadAssignments = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.assignments.loading = true;
        this.startDate = new Date();
        this.startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(this.startDate);
        endDate.setHours(23, 59, 59, 999);
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        this.assignmentsReq = this.assignmentService.list(__assign({ include_trips: 'True', completed: 'False', dispatched: 'True', jobevent__shift1_start__gte: this.startDate.toISOString(), jobevent__shift1_start__lte: endDate.toISOString() }, query)).subscribe(function (assignments) {
            _this.assignments.items = assignments.map(function (assignment) {
                if (assignment.driver) {
                    assignment.driver = new driver_serializer_1.DriverSerializer().fromJson(assignment.driver);
                    if (assignment.driver) {
                        var driverMatch = lodash_1.find(_this.driversList, assignment.driver.id);
                        if (!driverMatch) {
                            _this.driversList.push(assignment.driver.id);
                        }
                    }
                    if (assignment.trips && assignment.trips.length) {
                        if (!assignment.trips[0].completed) {
                            assignment.driver.trip = assignment.trips[0];
                            _this.durationInMinutes(assignment.driver.trip);
                        }
                    }
                }
                if (assignment.jobevent) {
                    var jobEventId = assignment.jobevent.toString();
                    var jobEventMatch = lodash_1.find(_this.activeJobEvents, { id: jobEventId });
                    if (!jobEventMatch) {
                        if (_this.jobEventReq && typeof _this.jobEventReq.unsubscribe === 'function') {
                            _this.jobEventReq.unsubscribe();
                        }
                        _this.jobEventReq = _this.jobEventService.getJobEvent(jobEventId).subscribe(function (jobEvent) {
                            _this.activeJobEvents.push(jobEvent);
                            _this.punchCardFlag = (jobEvent.invoiceType === 'hourly') ? true : false;
                            _this.loading = false;
                            var jobId = jobEvent.job && jobEvent.job.id;
                            var jobMatch = lodash_1.find(_this.activeJobs, { id: jobId });
                            if (!jobMatch) {
                                _this.activeJobs.push(jobEvent.job);
                            }
                        }, function (err) {
                            _this.errors = err;
                            _this.loading = false;
                        });
                    }
                }
                return assignment;
            });
            _this.getMapLocationUpdates();
        }, function (err) {
            _this.assignments.errors = err;
        }, function () {
            _this.assignments.loading = false;
        });
    };
    ActiveTrucksComponent.prototype.durationInMinutes = function (obj) {
        obj.durationTimer = rxjs_1.timer(1, 60000);
        obj.durationTimer.subscribe(function (t) {
            var duration = moment().diff(obj.startTimeTimestamp, 'minutes') + ' mins';
            obj.duration = duration;
        });
    };
    /**
    * If the window is less than 900px wide, the assignment details are assigned
    * to component properties and displayed in the template.
    *
    * If the window is greater than or equal to 900px wide, the Driver Info Window
    * is opened on the map.
    *
    * @param {any} update The location update
    * @param {any} index The marker index in the array
    */
    ActiveTrucksComponent.prototype.openAssignmentDetails = function (update, index) {
        if (window.innerWidth < 900) {
            var latLng = void 0;
            this.assignmentDetails = {};
            if (update && update.location && update.driver && update.driver.id) {
                this.assignmentDetails = lodash_1.find(this.assignments.items, { driver: { id: update.driver.id } });
                latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
            }
            else if (update && update.shift && update.driver && update.driver.id) {
                this.assignmentDetails = update;
                var locationUpdate = lodash_1.find(this.locationUpdates, { driver: { id: update.driver.id } });
                if (locationUpdate && locationUpdate['location']) {
                    latLng = new google.maps.LatLng(locationUpdate['location'].coordinates[1], locationUpdate['location'].coordinates[0]);
                }
            }
            if (this.map && latLng) {
                // this.map.setCenter(latLng);
                this.map.setZoom(17);
            }
            if (this.assignmentDetails && this.assignmentDetails.jobevent) {
                this.selectedJobEvent = lodash_1.find(this.activeJobEvents, { id: this.assignmentDetails['jobevent'] });
                this.selectedJob = lodash_1.find(this.activeJobs, { id: this.selectedJobEvent['job']['id'] });
                this.assignmentTitle.emit(this.assignmentDetails['driver']['profile']['name']);
            }
            else {
                this.errors = ['There was an issue getting the assignment details.'];
            }
        }
        else {
            this.openDriverInfoWindow(index, update);
        }
    };
    /**
    * If the window is less than 900px wide, the truck details are assigned
    * to component properties and displayed in the template.
    *
    * If the window is greater than or equal to 900px wide, the Truck Info Window
    * is opened on the map.
    *
    * @param {any} update The location update
    * @param {any} index The marker index in the array
    */
    ActiveTrucksComponent.prototype.openTruckDetails = function (update, index) {
        if (window.innerWidth < 900) {
            var latLng = void 0;
            if (update && update.location && update.truck && update.truck.id) {
                latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
            }
            if (this.map && latLng) {
                this.map.setCenter(latLng);
                this.map.setZoom(17);
            }
        }
        else {
            this.openTruckInfoWindow(index, update);
        }
    };
    /**
    * Focuses the mapSearch element after a 300ms delay
    */
    ActiveTrucksComponent.prototype.showSearch = function () {
        var _this = this;
        this.searching = true;
        setTimeout(function () {
            if (_this.mapSearch) {
                _this.mapSearch.nativeElement.focus();
            }
        }, 300);
    };
    /**
    * Removes all exsiting location and location update markers
    */
    ActiveTrucksComponent.prototype.resetMarkers = function () {
        this.customLocationMarkers = [];
        this.customUpdateMarkers = [];
        this.locationUpdates = [];
        this.paverLocationUpdates = [];
    };
    ActiveTrucksComponent.prototype.togglePending = function () {
        this.showPending = !this.showPending;
        this.savePreferences('ActiveTrucks-LocationUpdates', {
            filters: { showPending: this.showPending }
        });
        this.getMapLocationUpdates(this.mapLoading, true, true);
    };
    ActiveTrucksComponent.prototype.getPreferences = function (preferenceKey, params) {
        var _this = this;
        if (preferenceKey === void 0) { preferenceKey = 'ActiveTrucks-LocationUpdates'; }
        if (params === void 0) { params = null; }
        if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
            try {
                this.preferenceReqs[preferenceKey].unsubscribe();
            }
            catch (e) { }
        }
        var currentUser = this.authenticationService.user();
        if (!params) {
            params = {
                name: preferenceKey,
                type: 'user',
                profile: currentUser.id
            };
        }
        this.preferenceReqs[preferenceKey] = this.preferenceService.list(params).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preferences[preferenceKey] = preferences[0];
                _this.parsePreferences(preferenceKey);
            }
            else if (preferenceKey === 'GeneralUserPreferences') {
                _this.loadingMapPreferences = false;
                _this.getMapLocationUpdates(_this.mapLoading, true, true);
            }
        }, function (err) {
            _this.errors = err;
            _this.loadingMapPreferences = false;
        }, function () {
            _this.loadingMapPreferences = false;
        });
    };
    ActiveTrucksComponent.prototype.savePreferences = function (preferenceKey, blob) {
        var _this = this;
        if (preferenceKey === void 0) { preferenceKey = 'ActiveTrucks-LocationUpdates'; }
        if (preferenceKey) {
            var currentUser = this.authenticationService.user();
            this.preferences[preferenceKey] = __assign({}, this.preferences[preferenceKey], { name: preferenceKey, type: 'user', profile: currentUser.id, blob: blob });
            this.preferenceService.save(this.preferences[preferenceKey]).subscribe(function (preference) {
                _this.preferences[preferenceKey] = preference;
            });
        }
    };
    /**
     * Examine the found preference for the provided key to determine if it has
     * the necessary data.
     *
     * Once the preferences data is handled, update the map
     */
    ActiveTrucksComponent.prototype.parsePreferences = function (preferenceKey) {
        if (preferenceKey === void 0) { preferenceKey = 'ActiveTrucks-LocationUpdates'; }
        if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['filters']) {
            var filters = this.preferences[preferenceKey].blob['filters'];
            this.showPending = filters && filters['showPending'];
        }
        if (preferenceKey === 'GeneralUserPreferences') {
            var mapStyle = 'google-map-style';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
                mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
                this.markerType = this.preferences[preferenceKey].blob['markerType'];
            }
            if (!this.markerStatus) {
                this.markerStatus = 'each-segment';
            }
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerStatus']) {
                this.markerStatus = this.preferences[preferenceKey].blob['markerStatus'];
            }
            this.mapOptions = static_data_1.mapOptions({
                disableDefaultUI: true,
                scrollwheel: true,
                fullscreenControl: true,
                streetViewControl: false
            }, {}, {
                mapStyle: mapStyle
            });
            this.loadingMapPreferences = false;
            this.getMapLocationUpdates(this.mapLoading, true, true);
        }
        this.loadingMapPreferences = false;
    };
    return ActiveTrucksComponent;
}());
exports.ActiveTrucksComponent = ActiveTrucksComponent;
