"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment");
var trip_service_1 = require("../trips/trip.service");
var invoice_service_1 = require("../invoices/invoice.service");
var invoice_trips_dialog_component_1 = require("./invoice-trips-dialog.component");
var index_1 = require("../shared/dialogs/index");
var SentTripsComponent = /** @class */ (function () {
    function SentTripsComponent(route, router, tripService, invoiceService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.tripService = tripService;
        this.invoiceService = invoiceService;
        this.dialog = dialog;
        this.search = '';
        this.updateInvoice = new core_1.EventEmitter();
        this.selectedCount = 0;
        this.trips = [];
        this.loading = true;
        this.errors = [];
        this.page = 1;
        this.sortAsc = true;
        this.allSelected = false;
        this.selectedTrips = [];
        this.excludeTrips = [];
        this.checkingDuplicates = false;
        this.multipleActionDropdownOptions = [
            { name: 'Remove from Invoice', action: 'remove', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Remove from Invoice', action: 'remove', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.addTripsCallback = function (e) {
            _this.updateInvoice.emit(true);
            _this.getTrips();
        };
        this.viewTicketsCallback = function (e) {
            // Update Ticket Status Icon
        };
        this.viewSignaturesCallback = function () {
            // Update Signature Status Icon
        };
    }
    SentTripsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.loading = true;
        this.route.queryParams.forEach(function (params) {
            _this.sortBy = params.sortTripsBy ? params.sortTripsBy : 'start_time';
            _this.sortAsc = params.sortTripsAsc ? (params.sortTripsAsc === 'true') : false;
            _this.sortParameter = params.sortTripsParameter ? params.sortTripsParameter : null;
            _this.page = params.sortTripsPage ? params.sortTripsPage : 1;
        });
        this.getTrips((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    SentTripsComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                var chng = changes[propName];
                if (propName === 'search') {
                    this.search = chng.currentValue;
                }
            }
        }
        this.changeSearch(this.search);
    };
    SentTripsComponent.prototype.pageChange = function (event) {
        var _a;
        this.page = event;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortTripsPage: this.page > 1 ? this.page : null })
        });
        this.getTrips((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    SentTripsComponent.prototype.getTrips = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.trips = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.tripReq) {
            this.tripReq.unsubscribe();
        }
        this.tripFilters = __assign({ sort: order, search: this.search, invoice: this.invoice.id }, query);
        if (this.checkingDuplicates) {
            this.tripFilters['only_dupes'] = 'True';
        }
        this.tripReq = this.tripService.getCondensedTrips(this.tripFilters).subscribe(function (trips) {
            if (append) {
                _this.trips = _this.trips.concat(trips);
            }
            else {
                _this.trips = trips;
            }
            _this.count = _this.tripService.count;
            _this.loading = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    SentTripsComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortTripsBy: this.sortBy, sortTripsAsc: this.sortAsc, sortTripsParameter: null })
        });
        this.loading = true;
        this.getTrips({
            ordering: (this.sortAsc ? '' : '-') + this.sortBy,
            page: this.page
        });
    };
    SentTripsComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortTripsBy: this.sortBy, sortTripsAsc: this.sortAsc, sortTripsParameter: this.sortParameter })
        });
        this.loading = true;
        this.getTrips((_a = {},
            _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy,
            _a.page = this.page,
            _a));
    };
    SentTripsComponent.prototype.openAddTrip = function () {
        var dialog = this.dialog.open(invoice_trips_dialog_component_1.InvoiceTripsDialogComponent, {
            width: '100%',
            height: '100%',
            disableClose: true
        });
        dialog.componentInstance.invoice = this.invoice;
        dialog.componentInstance.customerId = this.invoice.customerOrganization.id;
        dialog.componentInstance.customer = this.invoice.customerOrganization;
        dialog.componentInstance.routeToInvoice = false;
        dialog.componentInstance.callback = this.addTripsCallback;
    };
    SentTripsComponent.prototype.removeSelectedTrips = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        var model = { id: this.invoice.id };
        if (trips || this.selectedTrips.length) {
            Object.assign(model, { trips: trips || this.selectedTrips });
        }
        else if (this.allSelected) {
            var params_1 = new http_1.HttpParams();
            var filters_1 = lodash_1.omit(this.tripFilters, ['ordering', 'invoice']);
            if (filters_1) {
                Object.keys(filters_1).map(function (key) {
                    if (filters_1[key] && filters_1[key].length) {
                        params_1 = params_1.set(key, filters_1[key]);
                    }
                });
            }
            if (params_1.toString().length) {
                Object.assign(model, { filters: params_1.toString() });
            }
            Object.assign(model, { excludeTrips: this.excludeTrips });
        }
        this.invoiceReq = this.invoiceService.removeFromInvoice(model, 'trips').subscribe(function (res) {
            _this.invoice = res;
            _this.search = '';
            _this.allSelected = false;
            _this.selectedCount = 0;
            _this.selectedTrips = [];
            _this.updateInvoice.emit(true);
            _this.getTrips();
        }, function (err) { return console.error(err); });
    };
    SentTripsComponent.prototype.voidSelectedTrips = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        trips = trips ? trips : this.selectedTrips;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Trips?',
            body: 'These trips will be marked as \'Void\' and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                var observableBatch_1 = [];
                trips.forEach(function (trip) {
                    observableBatch_1.push(_this.tripService.save({ id: trip, void: true }));
                });
                rxjs_1.forkJoin(observableBatch_1).subscribe(function (result) {
                    _this.loading = false;
                    _this.getTrips();
                    _this.updateInvoice.emit(true);
                }, function (err) {
                    _this.errors = _this.errors.concat(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    SentTripsComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.getTrips();
    };
    SentTripsComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    SentTripsComponent.prototype.menuAction = function (name, trip) {
        switch (name) {
            case 'remove':
                trip ? this.removeSelectedTrips([trip.id]) : this.removeSelectedTrips();
                break;
            case 'void':
                trip ? this.voidSelectedTrips([trip.id]) : this.voidSelectedTrips();
                break;
        }
    };
    SentTripsComponent.prototype.editTrip = function (trip) {
        this.router.navigate(['/trips/' + trip.id + '/edit'], {
            queryParams: { returnTo: '/invoices/' + this.invoice.id + '/edit' }
        });
    };
    SentTripsComponent.prototype.formattedDuration = function (startTime) {
        var duration = moment.duration(moment().diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    SentTripsComponent.prototype.duplicateCheck = function () {
        this.getTrips();
    };
    SentTripsComponent.prototype.selector = function (event, trip) {
        if (trip === void 0) { trip = null; }
        if (trip) {
            if (!event.target.checked) {
                trip.selected = false;
                lodash_1.pull(this.selectedTrips, trip.id);
                if (this.allSelected) {
                    this.excludeTrips.push(trip.id);
                    this.selectedCount = (this.count - this.excludeTrips.length);
                }
                else {
                    this.selectedCount = this.selectedTrips.length;
                }
            }
            else {
                trip.selected = true;
                if (this.allSelected) {
                    lodash_1.pull(this.excludeTrips, trip.id);
                    this.selectedCount = (this.count - this.excludeTrips.length);
                }
                else {
                    this.selectedTrips.push(trip.id);
                    this.selectedCount = this.selectedTrips.length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected = false;
                this.trips.forEach(function (_trip) { _trip.selected = false; });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                this.selectedCount = (this.count - this.excludeTrips.length);
            }
            this.selectedTrips = [];
            this.excludeTrips = [];
        }
    };
    return SentTripsComponent;
}());
exports.SentTripsComponent = SentTripsComponent;
