"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var job_event_filter_1 = require("./job-event.filter");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var JobEventFilterSerializer = /** @class */ (function () {
    function JobEventFilterSerializer() {
    }
    JobEventFilterSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var jobEventFilter = new job_event_filter_1.JobEventFilter();
        if (!json) {
            return jobEventFilter;
        }
        jobEventFilter.id = json.id;
        jobEventFilter.name = json.name;
        return jobEventFilter;
    };
    JobEventFilterSerializer.prototype.toJson = function (jobEventFilter) {
        var json = {
            id: jobEventFilter.id,
            name: jobEventFilter.name
        };
        return decamelizeKeysDeep(json);
    };
    return JobEventFilterSerializer;
}());
exports.JobEventFilterSerializer = JobEventFilterSerializer;
