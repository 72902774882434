"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mobile-dispatch-shifts.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("./mobile-dispatch-shifts.component");
var styles_MobileDispatchShiftsComponent = [i0.styles];
var RenderType_MobileDispatchShiftsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDispatchShiftsComponent, data: {} });
exports.RenderType_MobileDispatchShiftsComponent = RenderType_MobileDispatchShiftsComponent;
function View_MobileDispatchShiftsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "slot"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h3", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, [" Shift ", " ", " - ", " "])), i1.ɵppd(5, 2), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon-next-arrow"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.index + 1); var currVal_2 = i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.jobEvent[(("shift" + (_v.context.index + 1)) + "StartTimestamp")], "h:mm a")); var currVal_3 = i1.ɵunv(_v, 4, 2, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.jobEvent[(("shift" + (_v.context.index + 1)) + "EndTimestamp")], "h:mm a")); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }); }
function View_MobileDispatchShiftsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mobile-dispatch-shifts mobile-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchShiftsComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shifts; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_MobileDispatchShiftsComponent_0 = View_MobileDispatchShiftsComponent_0;
function View_MobileDispatchShiftsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-dispatch-shifts", [], null, null, null, View_MobileDispatchShiftsComponent_0, RenderType_MobileDispatchShiftsComponent)), i1.ɵdid(1, 114688, null, 0, i4.MobileDispatchShiftsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileDispatchShiftsComponent_Host_0 = View_MobileDispatchShiftsComponent_Host_0;
var MobileDispatchShiftsComponentNgFactory = i1.ɵccf("mobile-dispatch-shifts", i4.MobileDispatchShiftsComponent, View_MobileDispatchShiftsComponent_Host_0, { jobEvent: "jobEvent" }, { selectShift: "selectShift" }, []);
exports.MobileDispatchShiftsComponentNgFactory = MobileDispatchShiftsComponentNgFactory;
