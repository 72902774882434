"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var resource_service_1 = require("../shared/resource.service");
var job_event_share_serializer_1 = require("./job-event-share.serializer");
var index_1 = require("../shared/index");
var JobEventShareService = /** @class */ (function (_super) {
    __extends(JobEventShareService, _super);
    function JobEventShareService(http) {
        return _super.call(this, http, 'shares/', new job_event_share_serializer_1.JobEventShareSerializer()) || this;
    }
    JobEventShareService.prototype.removeCollaboration = function (jobEventId, jobEventShareId) {
        var _this = this;
        var resourceUrl = this.baseUrl + "jobevents/" + jobEventId + "/collaborators/" + jobEventShareId + "/remove/";
        return this.http.post(resourceUrl, {}, {
            headers: index_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.convertJobEventShare(res); }), operators_1.catchError(index_1.handleError));
    };
    JobEventShareService.prototype.convertJobEventShare = function (data) {
        return this.serializer.fromJson(data);
    };
    return JobEventShareService;
}(resource_service_1.ResourceService));
exports.JobEventShareService = JobEventShareService;
