<widget [size]="[2,1]" (window:resize)="onResize($event)">
  <div class="heading" widget-header>
    <h2 translate>Needs Dispatched</h2>
    <div class="actions">
      <a (click)="setRange('today')" [ngClass]="{active: range === 'today'}" translate>Today</a>
      <a (click)="setRange('tomorrow')" [ngClass]="{active: range === 'tomorrow'}" translate>Tomorrow</a>
    </div>
    <div class="export">
      <i (click)="exportAssignments()" class="icomoon icon-export"></i>
    </div>
  </div>
  <div class="content" widget-content>
    <div class="widget-table">
      <div class="table-body">
        <div class="header">
          <div class="head job sort" (click)="sort('jobevent__job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__job__name'}" translate>
            Job
          </div>
          <div class="head trucks sort" (click)="sort('num_trucks')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'num_trucks'}" translate>
            Trucks
          </div>
        </div>

        <a href="/dispatch/{{ collaborator.jobId }}/{{ collaborator.jobEventId }}" *ngFor="let collaborator of collaborators" class="row">
          <div class="job">{{ collaborator.jobDisplayName }}</div>
          <div class="trucks">
            {{ collaborator.dispatched || 0 }} / {{ collaborator.numTrucks || 0 }} <i class="icon-next-arrow"></i>
          </div>
        </a>

        <div class="row" *ngIf="!loading && !collaborators">
          <div class="job" translate>No Jobs Need Dispatching</div>
        </div>

        <my-loader *ngIf="loading"></my-loader>
      </div>
    </div>
  </div>
</widget>
