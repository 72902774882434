"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
require("rxjs/add/observable/fromEvent");
// angular material
var material_1 = require("@angular/material");
// libraries
var moment = require("moment");
var lodash_1 = require("lodash");
var job_event_1 = require("../../job-events/job-event");
// components
var context_menu_component_1 = require("../context-menu/context-menu.component");
var edit_punch_card_dialog_component_1 = require("../../punch-cards/edit-punch-card-dialog.component");
var edit_trip_dialog_component_1 = require("../../trips/edit-trip-dialog/edit-trip-dialog.component");
// services
var core_2 = require("@ngx-translate/core");
var TimeInterval;
(function (TimeInterval) {
    TimeInterval[TimeInterval["Hour"] = 60] = "Hour";
    TimeInterval[TimeInterval["HalfHour"] = 30] = "HalfHour";
    TimeInterval[TimeInterval["QuarterHour"] = 15] = "QuarterHour";
})(TimeInterval = exports.TimeInterval || (exports.TimeInterval = {}));
var TimelineComponent = /** @class */ (function () {
    function TimelineComponent(translateService, dialog) {
        this.translateService = translateService;
        this.dialog = dialog;
        this.timelineConfig = {
            currentTime: false,
            selectedTime: false,
            scroll: true,
            headerHeight: 47,
            rowHeight: 115,
            visibleBars: ['trips', 'punchcards', 'predictedTrips',
                'truckStatuses', 'shifts', 'payLines']
        };
        this.rows = [];
        this.timeInterval = TimeInterval.Hour;
        this.select = new core_1.EventEmitter();
        this.rowEdits = new core_1.EventEmitter();
        this.onRefresh = new core_1.EventEmitter();
        this.barDisplay = 'times';
        this.barDisplayOptions = ['times', 'duration', 'type'];
        this.payLineEventSubs = [];
        this.tripContextMenuItems = [
            {
                name: this.translateService.instant('Edit Trip'),
                icon: 'pencil',
                disabled: false,
            }
        ];
        this.punchcardContextMenuItems = [
            {
                name: this.translateService.instant('Edit Punch Card'),
                icon: 'pencil',
                disabled: false,
            }
        ];
        this.tripContextMenuSelected = '';
        this.contextMenuSelectedTrip = null;
        this.keyContextMenuSelected = null;
        this.selectedTimeChange = new core_1.EventEmitter();
    }
    Object.defineProperty(TimelineComponent.prototype, "selectedTime", {
        get: function () { return this.selectedTimeValue; },
        set: function (time) {
            this.selectedTimeValue = time;
            this.selectedTimeChange.emit(time);
        },
        enumerable: true,
        configurable: true
    });
    TimelineComponent.prototype.timeWithinRange = function (time) {
        return moment(time).isBefore(this.range.endDatetime);
    };
    TimelineComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.range) {
            this.generateTimelineRange();
        }
        if (changes.rows) {
            this.rows.forEach(function (row) {
                if (row.predictedTrips) {
                    row.geofences = _this.generateGeofenceBars(row.predictedTrips, _this.jobEvent);
                }
            });
            this.autoScroll();
        }
        if (changes.timeInterval) {
            this.generateTimelineRange();
        }
    };
    TimelineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.generateTimelineRange();
        this.rows.forEach(function (row) {
            if (row.predictedTrips) {
                row.geofences = _this.generateGeofenceBars(row.predictedTrips, _this.jobEvent);
            }
        });
        if (this.timelineConfig.currentTime) {
            this.currentTime = moment();
            setInterval(function () { _this.currentTime = moment(); }, 60000);
        }
        if (this.timelineConfig.selectedTime) {
            var timelineEl_1 = document.getElementById('timeline');
            rxjs_1.Observable.fromEvent(timelineEl_1, 'mousemove')
                .subscribe(function (e) {
                if (!e.target.classList.contains('bar') &&
                    !e.target.classList.contains('label') &&
                    !e.target.classList.contains('shift-line') &&
                    !e.target.classList.contains('wait-line') &&
                    !e.target.classList.contains('status-icon') &&
                    !_this.playing) {
                    var x = e['layerX'] + timelineEl_1.scrollLeft;
                    var leftOffset = 275; // width in pixels left of the timeline
                    var timeSegmentWidth = 95; // width in pixels of an individual time segment
                    var minuteOffset = Math.floor((x - leftOffset) * (_this.timeInterval / timeSegmentWidth));
                    _this.selectedTime = moment(_this.range.startDatetime).subtract(2, 'hours')
                        .startOf('hour')
                        .add(minuteOffset, 'minutes')
                        .startOf('minute')
                        .toISOString();
                }
            });
        }
    };
    TimelineComponent.prototype.ngAfterViewInit = function () {
        if (this.rows.some(function (row) { return row.payLines && row.payLines.length > 0; })) {
            this.generatePayLineEventSubs(this.rows);
        }
    };
    TimelineComponent.prototype.generatePayLineEventSubs = function (rows) {
        var _this = this;
        rows.forEach(function (row) {
            row.payLines.forEach(function (period, i) {
                var payLineEl = document.getElementById(row.referenceId + '-' + i);
                var startPos;
                var originalTimes = lodash_1.clone(period);
                if (payLineEl) {
                    _this.payLineEventSubs.push(rxjs_1.Observable.fromEvent(payLineEl, 'mousedown').subscribe(function (e) {
                        if (e.target.classList.contains('edit-handle')) {
                            _this.payLineEditing = e.target.classList.contains('start') ? 'start' : 'end';
                            startPos = e.pageX;
                        }
                    }));
                    _this.payLineEventSubs.push(rxjs_1.Observable.fromEvent(payLineEl, 'mousemove').subscribe(function (e) {
                        if (_this.payLineEditing) {
                            var minuteOffset = Math.floor((e.pageX - startPos) * (_this.timeInterval / 95));
                            if (_this.payLineEditing === 'start') {
                                // adjust beginning time to minute
                                row.payLines[i].startDatetime = moment(originalTimes.startDatetime).add(minuteOffset, 'minutes').toISOString();
                            }
                            else if (_this.payLineEditing === 'end') {
                                // adjust the end time to minute
                                row.payLines[i].endDatetime = moment(originalTimes.endDatetime).add(minuteOffset, 'minutes').toISOString();
                            }
                        }
                    }));
                    _this.payLineEventSubs.push(rxjs_1.Observable.fromEvent(payLineEl, 'mouseup').subscribe(function (e) {
                        _this.payLineEditing = null;
                        originalTimes = lodash_1.clone(row.payLines[i]);
                        _this.rowEdits.emit(row);
                    }));
                }
            });
        });
    };
    TimelineComponent.prototype.resetPayLineSubs = function () {
        var _this = this;
        this.payLineEventSubs.forEach(function (sub) { return sub.unsubscribe(); });
        setTimeout(function () { return _this.generatePayLineEventSubs(_this.rows); });
    };
    TimelineComponent.prototype.generateTimelineRange = function () {
        var startTime = this.range ?
            moment(this.range.startDatetime).subtract(2, 'hours').startOf('hour') :
            moment().startOf('day');
        var endTime = this.range ?
            moment(this.range.endDatetime).add(2, 'hours').startOf('hour') :
            moment().endOf('day');
        this.times = [];
        for (var time = startTime; time.isBefore(endTime); time.add(this.timeInterval, 'minutes')) {
            this.times.push(time.format('h:mma'));
        }
    };
    TimelineComponent.prototype.generateGeofenceBars = function (predictedTrips, jobEvent) {
        var geofences = [];
        predictedTrips.forEach(function (trip, i) {
            if (trip.unloadingArrivalTime && trip.unloadingCompleteTime) {
                var unloadingGeofence = {
                    range: {
                        startDatetime: trip.unloadingArrivalTime,
                        endDatetime: trip.unloadingCompleteTime
                    },
                    type: 'Unloading'
                };
                if (jobEvent) {
                    unloadingGeofence.location = jobEvent.job.endLocation;
                }
                else if (trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.endLocation) {
                    unloadingGeofence.location = trip.jobEvent.job.endLocation;
                }
                geofences.push(unloadingGeofence);
            }
            if (trip.loadingArrivalTime && trip.loadingCompleteTime) {
                var loadingGeofence = {
                    range: {
                        startDatetime: trip.loadingArrivalTime,
                        endDatetime: trip.loadingCompleteTime
                    },
                    type: 'Loading'
                };
                if (jobEvent) {
                    loadingGeofence.location = jobEvent.job.startLocation;
                }
                else if (trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.startLocation) {
                    loadingGeofence.location = trip.jobEvent.job.startLocation;
                }
                geofences.push(loadingGeofence);
            }
        });
        return geofences;
    };
    TimelineComponent.prototype.generateTravelTimes = function (predictedTrips) {
        var travelTimes = [];
        predictedTrips.forEach(function (trip) {
            if (trip.loadingCompleteTime && trip.unloadingArrivalTime) {
                travelTimes.push({
                    startDatetime: trip.loadingCompleteTime,
                    endDatetime: trip.unloadingArrivalTime
                });
            }
        });
        return travelTimes;
    };
    TimelineComponent.prototype.barStyles = function (startDatetime, endDatetime, offset) {
        if (offset === void 0) { offset = 0; }
        var values = {};
        var timeScale = 95 / this.timeInterval;
        var startUnixTime = Date.parse(startDatetime);
        var left = ((startUnixTime - Number(moment(this.range.startDatetime)
            .subtract(2, 'hours')
            .startOf('hour').format('x'))) / 60000) * timeScale;
        values.left = left + offset + 'px';
        var width = 1;
        if (endDatetime && Date.parse(endDatetime) < Date.parse(startDatetime)) {
            endDatetime = moment(new Date(Date.parse(startDatetime)).toDateString() + ' ' + new Date(Date.parse(endDatetime)).toTimeString()).toISOString();
            width = ((Date.parse(endDatetime) - startUnixTime) / 60000) * timeScale;
        }
        else if (endDatetime) {
            width = ((Date.parse(endDatetime) - startUnixTime) / 60000) * timeScale;
        }
        else if (Date.parse(this.range.endDatetime) > Date.now()) {
            width = ((Date.now() - startUnixTime) / 60000) * timeScale;
        }
        values.width = width + 'px';
        var timeline = document.getElementById('timeline');
        if (timeline && typeof timeline.scrollTo === 'function' && this.playing) {
            timeline.scrollTo({ left: left - 50, behavior: 'auto' });
        }
        return values;
    };
    TimelineComponent.prototype.selectRow = function (row) {
        this.select.emit(row.referenceId);
    };
    TimelineComponent.prototype.switchBarDisplay = function () {
        this.barDisplay = this.barDisplayOptions.indexOf(this.barDisplay) + 1 === this.barDisplayOptions.length ?
            this.barDisplayOptions[0] :
            this.barDisplayOptions[this.barDisplayOptions.indexOf(this.barDisplay) + 1];
    };
    TimelineComponent.prototype.getDurationString = function (startDatetime, endDatetime, durationType) {
        if (durationType === void 0) { durationType = 'hours'; }
        if (startDatetime && endDatetime) {
            var minuteDuration = moment(endDatetime).diff(startDatetime, 'minutes');
            return durationType === 'minutes' ? minuteDuration + 'm' : Math.floor(minuteDuration / 60) + 'h ' + (minuteDuration % 60) + 'm';
        }
        else if (startDatetime) {
            var minuteDuration = moment(new Date()).diff(startDatetime, 'minutes');
            return durationType === 'minutes' ? minuteDuration + 'm' : Math.floor(minuteDuration / 60) + 'h ' + (minuteDuration % 60) + 'm';
        }
        else {
            return '-h--m';
        }
    };
    TimelineComponent.prototype.autoScroll = function () {
        var _this = this;
        var timelineEl = document.getElementById('timeline');
        if (this.rows[0] && timelineEl) {
            var firstTime_1;
            Object.keys(this.rows[0]).forEach(function (key) {
                if (['trips', 'predictedTrips', 'punchcards'].indexOf(key) !== -1 &&
                    (!firstTime_1 || moment(key['startTimeTimestamp']).isBefore(firstTime_1)) &&
                    _this.rows[0][key]) {
                    _this.rows[0][key].forEach(function (element) {
                        firstTime_1 = element.startTimeTimestamp;
                    });
                }
            });
            if (typeof timelineEl.scrollTo === 'function') {
                timelineEl.scrollTo({
                    left: Number(this.barStyles(firstTime_1).left.split('px')[0]) - 50,
                    behavior: 'smooth'
                });
            }
        }
    };
    TimelineComponent.prototype.openContextMenu = function (event, trip, key) {
        this.keyContextMenuSelected = key;
        this.tripContextMenuSelected = this.getDurationString(trip.startTimeTimestamp, trip.endTimeTimestamp);
        this.contextMenuSelectedTrip = trip;
        if (key === 'trips') { // trips context menu
            this.tripContextMenuItems = [
                {
                    name: this.translateService.instant(trip.endTimeTimestamp ? 'Edit Trip' : 'Trip In Progress'),
                    icon: 'pencil',
                    disabled: trip.endTimeTimestamp ? false : true
                }
            ];
            this.tripContextMenu.openContextMenu(event);
        }
        else { // punchcards context menu
            this.punchcardContextMenuItems = [
                {
                    name: this.translateService.instant(trip.endTimeTimestamp ? 'Edit Punch Card' : 'Punch Card In Progress'),
                    icon: 'pencil',
                    disabled: trip.endTimeTimestamp ? false : true
                }
            ];
            this.punchcardContextMenu.openContextMenu(event);
        }
    };
    TimelineComponent.prototype.onEditTripClick = function () {
        var _this = this;
        var dialog = this.dialog.open(edit_trip_dialog_component_1.EditTripDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.tripId = this.contextMenuSelectedTrip.id;
        dialog.componentInstance.callback = function () {
            _this.contextMenuSelectedTrip = null;
            _this.keyContextMenuSelected = null;
            _this.onRefresh.emit();
        };
    };
    TimelineComponent.prototype.onEditPunchcardClick = function () {
        var _this = this;
        var dialog = this.dialog.open(edit_punch_card_dialog_component_1.EditPunchCardDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.punchCardId = this.contextMenuSelectedTrip.id;
        dialog.componentInstance.callback = function () {
            _this.contextMenuSelectedTrip = null;
            _this.keyContextMenuSelected = null;
            _this.onRefresh.emit();
        };
    };
    return TimelineComponent;
}());
exports.TimelineComponent = TimelineComponent;
