"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
// angular material
var material_1 = require("@angular/material");
// lodash
var lodash_1 = require("lodash");
// services
var job_service_1 = require("../jobs/job.service");
var authentication_service_1 = require("../shared/authentication.service");
var invitation_service_1 = require("../invitation/invitation.service");
var api_service_1 = require("../shared/api.service");
var custom_field_service_1 = require("../custom-fields/custom-field.service");
// components
var edit_job_dialog_component_1 = require("../jobs/edit-job-dialog.component");
var job_filters_dialog_component_1 = require("./job-filters-dialog.component");
var index_1 = require("../shared/dialogs/index");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var column_toggle_component_1 = require("../shared/column-toggle/column-toggle.component");
var edit_selected_jobs_component_1 = require("./edit-selected-jobs/edit-selected-jobs.component");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var custom_field_1 = require("../custom-fields/custom-field");
// constants
var jobs_constants_1 = require("./jobs.constants");
var moment = require("moment");
var JobsComponent = /** @class */ (function () {
    function JobsComponent(route, jobService, invitationService, customFieldService, authenticationService, translateService, dialog) {
        var _this = this;
        this.route = route;
        this.jobService = jobService;
        this.invitationService = invitationService;
        this.customFieldService = customFieldService;
        this.authenticationService = authenticationService;
        this.translateService = translateService;
        this.dialog = dialog;
        this.availableColumns = jobs_constants_1.AVAILABLECOLUMNS(this.translateService);
        this.displayedColumns = jobs_constants_1.DISPLAYEDCOLUMNS;
        this.appliedFilters = [];
        this.appliedFilterQueryKeys = {
            project: 'project',
            customer: 'customer_organization',
            tags: 'tags',
            startDate: 'jobevents__start__gte',
            endDate: 'jobevents__start__lte',
            orderNumber: 'order_number__icontains',
            jobNumber: 'job_number__icontains'
        };
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            hasCustomFields: true,
            service: job_service_1.JobService,
            filterQuery: false,
            preferenceKey: 'JobsComponent-JobService',
            query: {
                serializer: 'AllJobs',
                owner_org: 'True'
            },
            collectionTitle: this.translateService.instant('Jobs'),
            noResultsText: this.translateService.instant('a job'),
            newRecordRoute: ['/jobs/new'],
            sortBy: 'name',
            sortDirection: 'asc',
            menuOptions: jobs_constants_1.MENUOPTIONS(this.translateService)
        };
        this.customFields = [];
        this.customFieldsLoaded = false;
        this.loading = true;
        this.crh = false;
        this.pendingJobsCount = 0;
        this.canCreateJobs = true;
        this.hasAllDriversEnabled = false;
        this.showArchived = true;
        this.isAllJobsSelected = false;
        this.selectedJobs = [];
        this.excludeJobs = [];
        this.saveJobDayCallback = function (e) {
            _this.jobsTable.getRecords();
        };
    }
    JobsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.canCreateJobs = this.authenticationService.canCreateJobs();
        this.crh = this.authenticationService.isCrh();
        this.setDefaultFilters();
        this.getCustomFields();
        if (this.crh) {
            lodash_1.remove(this.displayedColumns, 'schedule');
        }
        if (this.tableConfig && this.authenticationService.hasFavoriteTags()) {
            this.tableConfig.query['user_tags'] = 'True';
        }
        if (this.route && this.route.queryParams) {
            this.route.queryParams.forEach(function (params) {
                _this.loading = true;
                _this.search = params['search'] || '';
                // TODO(jlee): Re-enable once the Archived filter is performant.
                // this.showArchived = !!this.authenticationService.getFilterProperty('hideArchivedJobs');
                _this.tableConfig.query['archived'] = _this.showArchived ? undefined : 'False';
                _this.hasAllDriversEnabled = _this.authenticationService.hasAllDriversEnabled();
            });
        }
        this.getInvitationsCount();
    };
    JobsComponent.prototype.ngOnDestroy = function () {
        if (this.invitationsReq && typeof this.invitationsReq.unsubscribe === 'function') {
            this.invitationsReq.unsubscribe();
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    JobsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    JobsComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
    };
    JobsComponent.prototype.setDefaultFilters = function () {
        var _a;
        var startDate = moment();
        startDate = startDate.subtract(2, 'week');
        startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        var defaultDateFilter = new filter_option_1.FilterOption({
            filterType: 'date',
            default: true,
            key: 'startDate',
            title: 'Start Date',
            displayValues: startDate.format('MM/DD/YYYY') || null,
            values: startDate.toISOString(),
            query: (_a = {},
                _a[this.appliedFilterQueryKeys.startDate] = startDate.toISOString(),
                _a)
        });
        this.appliedFilters.push(defaultDateFilter);
    };
    JobsComponent.prototype.editJob = function (job) {
        var dialog = this.dialog.open(edit_job_dialog_component_1.EditJobDialogComponent, {
            width: '430px',
            data: { job: job }
        });
        dialog.componentInstance.callback = this.saveJobDayCallback;
    };
    JobsComponent.prototype.getInvitationsCount = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.invitationsReq && typeof this.invitationsReq.unsubscribe === 'function') {
            this.invitationsReq.unsubscribe();
        }
        if (currentUser) {
            var organizationId = currentUser.organization && currentUser.organization.id;
            this.invitationsReq = this.invitationService.getInvitations('received', organizationId, null, 'new_job').subscribe(function (invitations) {
                _this.pendingJobsCount = invitations && invitations.length;
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    JobsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(job_filters_dialog_component_1.JobFiltersDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
            dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'customer' }), 'values');
            dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'project' }), 'values');
            dialog.componentInstance.model.tags = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'tags' }), 'values');
            dialog.componentInstance.model.orderNumber = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'orderNumber' }), 'values');
            dialog.componentInstance.customFields = this.customFields;
            var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
            if (startDate) {
                dialog.componentInstance.model.startDate = new Date(startDate);
            }
            var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
            if (endDate) {
                dialog.componentInstance.model.endDate = new Date(endDate);
            }
            this.customFields.forEach(function (field) {
                dialog.componentInstance.model[field.key] = lodash_1.get(lodash_1.find(_this.appliedFilters, { key: field.key }), 'values');
            });
            dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
                acc[filter.key] = filter.values;
                return acc;
            }, {}));
            this.filtersDialog = dialog.componentInstance;
        }
    };
    JobsComponent.prototype.filterChanges = function (filterRes) {
        var _this = this;
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            if (!filterRes[key]) {
                return;
            }
            var query = {};
            var matchedCustomField = _this.customFields.find(function (field) { return (field.key === key); });
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (matchedCustomField) {
                values = key + ',' + filterRes[key];
                displayValues = filterRes[key];
                if (values) {
                    query[_this.appliedFilterQueryKeys[key]] = values;
                }
                key = 'custom_field';
            }
            else if (filterRes[key] && key === 'tags') {
                values = filterRes[key].map(function (v) { return (v.name); }).join(',');
                query[_this.appliedFilterQueryKeys[key]] = values;
            }
            else if (filterRes[key] && key === 'customer') {
                query[_this.appliedFilterQueryKeys[key]] = filterRes[key].organization && filterRes[key].organization.id;
            }
            else {
                query[_this.appliedFilterQueryKeys[key]] = filterRes[key] && filterRes[key].id || filterRes[key];
            }
            var title = key.charAt(0).toUpperCase() + key.slice(1);
            if (matchedCustomField) {
                title = matchedCustomField.displayName;
            }
            else {
                if (key === 'orderNumber') {
                    title = 'Order #';
                }
                else if (key === 'jobNumber') {
                    title = 'Job #';
                }
            }
            var filter = new filter_option_1.FilterOption({
                filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                key: matchedCustomField ? 'custom_field' : key,
                title: title,
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (filter.values === 'False' || !filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        }).filter(function (value) { return value !== undefined; });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    JobsComponent.prototype.getFilterQuery = function () {
        var query = {};
        this.appliedFilters.forEach(function (filter) {
            var queryKey = Object.keys(filter.query)[0];
            if (!query.hasOwnProperty(queryKey)) {
                query[queryKey] = filter.query[queryKey];
            }
        });
        return query;
    };
    JobsComponent.prototype.menuAction = function (event) {
        switch (event[0]) {
            case 'edit-days':
                this.editJob(event[1]);
                break;
            case 'archive':
                this.archive(event[1]);
                break;
            case 'unarchive':
                this.unarchive(event[1]);
                break;
            case 'remove':
                this.delete(event[1]);
                break;
        }
    };
    JobsComponent.prototype.archive = function (job) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translateService.instant('Archive Job?'),
            body: this.translateService.instant('This job will be archived and will not be visible in the jobs list or dropdown.'),
            close: this.translateService.instant('Cancel'),
            accept: this.translateService.instant('Archive')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.jobService.archive(job).subscribe(function () {
                    _this.jobsTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    JobsComponent.prototype.unarchive = function (job) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translateService.instant('Unarchive Job?'),
            body: this.translateService.instant('This job will be un-archived and will be visible in the jobs list and dropdown.'),
            close: this.translateService.instant('Cancel'),
            accept: this.translateService.instant('Unarchive')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.jobService.unarchive(job).subscribe(function () {
                    _this.jobsTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    JobsComponent.prototype.delete = function (job) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translateService.instant('Delete Job?'),
            body: this.translateService.instant('This job will be deleted and will not be visible in the jobs list or dropdown.'),
            close: this.translateService.instant('Cancel'),
            accept: this.translateService.instant('Delete')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.jobService.remove(job).subscribe(function () {
                    _this.jobsTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    JobsComponent.prototype.toggleArchived = function () {
        this.authenticationService.setFilterProperty('hideArchivedJobs', !!this.showArchived);
        this.tableConfig.query['archived'] = this.showArchived ? undefined : 'False';
        this.jobsTable.getRecords();
    };
    JobsComponent.prototype.getCustomFields = function () {
        var _this = this;
        var customFields = [];
        this.customFieldService.list({ kind: custom_field_1.CustomFieldKind.Job, active: 'True' }).subscribe(function (fields) {
            var _a;
            _this.customFields = fields;
            fields.forEach(function (field) {
                if (!lodash_1.find(_this.availableColumns, { key: field.key })) {
                    customFields.push({
                        key: field.key, title: field.displayName, sortable: false,
                        sortBy: null, customField: true
                    });
                }
            });
            (_a = _this.availableColumns).splice.apply(_a, [-1, 0].concat(customFields));
            _this.customFieldsLoaded = true;
        });
    };
    JobsComponent.prototype.openEditSelectionDialog = function () {
        var _this = this;
        var dialog = this.dialog.open(edit_selected_jobs_component_1.EditSelectedJobsComponent);
        var query = __assign({}, this.tableConfig.query, this.getFilterQuery(), { search: this.search });
        dialog.componentInstance.query = query;
        dialog.componentInstance.selectedJobs = this.selectedJobs;
        dialog.componentInstance.excludeJobs = this.excludeJobs;
        dialog.componentInstance.allJobsCount = this.allJobsCount;
        dialog.componentInstance.isAllJobsSelected = this.isAllJobsSelected;
        dialog.componentInstance.editSuccess.subscribe(function () {
            _this.selectedJobs = [];
            _this.jobsTable.resetSelections();
            _this.jobsTable.getRecords();
            _this.dialog.closeAll();
        });
    };
    JobsComponent.prototype.onSelectionChange = function (event) {
        this.isAllJobsSelected = event.allSelected;
        var exclusionIds = event.exclusion.selected.map(function (e) { return e.id; });
        var data = this.jobsTable.dataSource.data;
        this.selectedJobs = this.isAllJobsSelected ? data.filter(function (j) { return !exclusionIds.includes(j.id); }) : event.selection.selected;
        this.allJobsCount = this.isAllJobsSelected ? this.jobsTable.count - event.exclusion.selected.length : event.selection.selected.length;
        this.excludeJobs = event.exclusion.selected;
    };
    return JobsComponent;
}());
exports.JobsComponent = JobsComponent;
