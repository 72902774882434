import {
  Component, ElementRef, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { PunchCard } from '../punch-cards/punch-card';
import { PunchCardService } from '../punch-cards/punch-card.service';
import { extend } from 'lodash';

@Component({
  selector: 'view-punch-card-ticket-dialog',
  templateUrl: './view-punch-card-ticket-dialog.component.html',
  styleUrls: ['./view-punch-card-ticket-dialog.component.scss'],
  providers: [PunchCardService]
})

export class ViewPunchCardTicketDialogComponent implements OnInit {
  @Input() punchCard: any = {};
  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<ViewPunchCardTicketDialogComponent>,
    private punchCardService: PunchCardService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() { }

  fileChange(e) {
    this.punchCardService.uploadImage('PUT', this.punchCard.id, 'ticket_image', e.srcElement.files).subscribe((res) => {
      let _punchCard = new PunchCard(res);
      _punchCard.ticketImage = _punchCard.ticketImage + '&cb=' + encodeURIComponent(new Date().toISOString());
      extend(this.punchCard, _punchCard);
      this.completed.emit(this.punchCard);
    }, function(err) {
      console.error('err', err);
    });
  }
}
