"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var lodash_1 = require("lodash");
var job_serializer_1 = require("../jobs/job.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Marketplace = /** @class */ (function () {
    function Marketplace(marketplaceInfo) {
        marketplaceInfo = camelcaseKeysDeep(marketplaceInfo);
        this.id = marketplaceInfo.id;
        if (marketplaceInfo.job) {
            this.job = new job_serializer_1.JobSerializer().fromJson(marketplaceInfo.job);
        }
        this.truckTypes = marketplaceInfo.truckTypes;
        this.dailyDeliveryTarget = marketplaceInfo.dailyDeliveryTarget;
        this.numTrucks = marketplaceInfo.numTrucks;
        this.deliveryInterval = marketplaceInfo.deliveryInterval;
        this.haulRate = marketplaceInfo.haulRate;
        this.shift1Start = marketplaceInfo.shift1Start;
        this.shift1End = marketplaceInfo.shift1End;
        this.shift1Status = marketplaceInfo.shift1Status;
        this.shift2Start = marketplaceInfo.shift2Start;
        this.shift2End = marketplaceInfo.shift2End;
        this.shift2Status = marketplaceInfo.shift2Status;
        this.lastDispatchedBy = marketplaceInfo.lastDispatchedBy;
        this.lastDispatchedTime = marketplaceInfo.lastDispatchedTime;
        if (marketplaceInfo.estimatedNumberOfLoads && marketplaceInfo.estimatedNumberOfLoads > 0) {
            this.estimatedNumberOfLoads = marketplaceInfo.estimatedNumberOfLoads;
        }
        else {
            this.estimatedNumberOfLoads = '';
        }
        this.assignmentsCount = marketplaceInfo.assignmentsCount;
        this.estimatedNumberTons = marketplaceInfo.estimatedNumberTons;
        this.allowAnyTruckType = marketplaceInfo.allowAnyTruckType;
        this.marketInvoiceRate = marketplaceInfo.marketInvoiceRate;
        this.marketNumTrucks = marketplaceInfo.marketNumTrucks;
        this.accepted = marketplaceInfo.accepted;
        this.claimedTruckSlots = marketplaceInfo.claimedTruckSlots;
        this.notes = marketplaceInfo.notes;
    }
    Object.defineProperty(Marketplace.prototype, "truckTypeNames", {
        get: function () {
            var names = [];
            if (this.allowAnyTruckType) {
                names = ['Any Type'];
            }
            else if (this.truckTypes) {
                names = this.truckTypes.map(function (truckType) {
                    return truckType.name;
                });
            }
            return names.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "shift1StartTime", {
        get: function () {
            var d = new Date(this.shift1Start);
            return moment(d).format('h:mm a');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "shift1EndTime", {
        get: function () {
            var d = new Date(this.shift1End);
            return moment(d).format('h:mm a');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "shift2StartTime", {
        get: function () {
            var d = new Date(this.shift2Start);
            return moment(d).format('h:mm a');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "shift2EndTime", {
        get: function () {
            var d = new Date(this.shift2End);
            return moment(d).format('h:mm a');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "endTime", {
        get: function () {
            if (this.shift2End) {
                return this.shift2EndTime;
            }
            else {
                return this.shift1EndTime;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "endDate", {
        get: function () {
            if (this.shift2End) {
                return this.shift2End;
            }
            else {
                return this.shift1End;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "date", {
        get: function () {
            var d = new Date(this.shift1Start);
            var today = new Date();
            if (today.toDateString() === d.toDateString()) {
                return 'Today';
            }
            else {
                return moment(d).format('dddd, MMMM Do');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "dateRange", {
        get: function () {
            var range = [];
            var startDate = new Date(this.shift1Start);
            var endDate = new Date(this.endDate);
            if (startDate) {
                range.push(moment(startDate).format('dddd, MMMM Qo'));
            }
            if (endDate) {
                range.push(moment(endDate).format('dddd, MMMM Qo'));
            }
            return lodash_1.uniq(range.filter(Boolean)).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "customer", {
        get: function () {
            if (this.job && this.job.project && this.job.project.customerOrganization) {
                return this.job.project.customerOrganization.name;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "checkinOptions", {
        get: function () {
            var checkinOptions = this.job.checkinOptions;
            var options = [];
            for (var option in checkinOptions) {
                if (checkinOptions[option] === true) {
                    options.push(option);
                }
            }
            if (options.length === 0) {
                options.push('None');
            }
            return options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "checkoutOptions", {
        get: function () {
            var checkoutOptions = this.job.checkoutOptions;
            var options = [];
            for (var option in checkoutOptions) {
                if (checkoutOptions[option] === true) {
                    options.push(option);
                }
            }
            if (options.length === 0) {
                options.push('None');
            }
            return options;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "startLocation", {
        get: function () {
            var value = '';
            var location = this.job.startLocation;
            if (this.job && this.job.startLocation) {
                value = location.name + '<br />';
                value += location.street + '<br />';
                value += location.city + ', ' + location.state + ' ' + location.zipcode + '<br />';
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marketplace.prototype, "endLocation", {
        get: function () {
            var value = '';
            var location = this.job.endLocation;
            if (this.job && this.job.endLocation) {
                value = location.name + '<br />';
                value += location.street + '<br />';
                value += location.city + ', ' + location.state + ' ' + location.zipcode + '<br />';
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    return Marketplace;
}());
exports.Marketplace = Marketplace;
