import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { SurchargeTemplatesRoutingModule } from './surcharge-templates-routing.module';

import { SurchargeTemplatesComponent } from './surcharge-templates.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SurchargeTemplatesRoutingModule
  ],
  declarations: [
    SurchargeTemplatesComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SurchargeTemplatesModule { }
