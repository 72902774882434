<div class="dropdown" role="menu" (window:keydown)="onKeyPress($event)" [ngClass]="{
    open: open,
    disabled: disabled,
    empty: ! selectedOption,
    action: config.style === 'action',
    user: config.user,
    'bottom-right': config.pin === 'bottom-right',
    'custom-icon': icon,
    searchable: config.searchable,
    'loading-options': config.loadingOptions
  }">
  <div class="dropdown-button" aria-haspopup="true" tabindex="0" (click)="toggleMenu()" [ngClass]="{'custom-icon': icon}"
    (keypress)="buttonKeyPress($event)" [attr.aria-disabled]="(disabled ? 'true':'false')">
    <div *ngIf="config.user" class="user-pic">
      <img *ngIf="config.user && config.user.image" [src]="user.image" />
      <span *ngIf="!config.user || !config.user.image">{{ config.user.name && config.user.name[0] }}</span>
    </div>
    <div class="scale-status-indicator" [ngClass]="getButtonStatus()"></div>
    {{getButtonText()}}
    <i *ngIf="icon" class="{{icon}}"></i>
  </div>

  <div class="dropdown-menu" [style.height]="menuHeight" [style.width]="menuWidth">
    <div class="dropdown-options" #optionsEl [attr.aria-hidden]="(open ? 'false':'true') " aria-label="submenu">

      <form id="dropdown-search" class="option search-option" *ngIf="config.searchable">
        <input name="search" type="text" class="search" [(ngModel)]="searchTerm" placeholder="Search" autocomplete="off" 
        (keypress)="onSearchboxKeyPress($event)" (blur)="onBlur()"/>
        <span class="icomoon icon-search"></span>
      </form>

      <div class="no-scales-wrapper" *ngIf="(!options || options.length === 0) && !config.loadingScales">
        <i _ngcontent-dcb-82="" class="icon-digital-scale">
          <span class="red-x">+</span>
        </i>
        <div class="no-scales-text" translate>No Scales Connected</div>
      </div>
      <div class="loading-scales-wrapper" *ngIf="config.loadingScales">
        <my-loader></my-loader>
      </div>

      <div class="scrollable" (mousewheel)="onScroll($event)" *ngIf="options && options.length > 0 && !config.loadingScales">

        <div *ngIf="config.group">
          <div *ngFor="let groupKey of groupKeys" class="group" [ngClass]="{empty: groupKey.length === 0}">
            <div class="group-header" *ngIf="groupKey && groupKey.length > 0">
              {{ groupKey }}
            </div>
            <div class="option" attr.data-id="{{option.id}}" role="menuitem" *ngFor="let option of groups[groupKey]" (click)="toggleOption(option)"
              tabindex="-1" (keypress)="optionKeyPress($event, option)" [ngClass]="{selected: isSelected(option), disabled: option.disabled, button: option.button, 'has-image': config.image}">
              <div *ngIf="!isTareWeight(option)" class="scale-info-item scale-status">
                <div class="scale-status-indicator" [ngClass]="optionScaleStatus(option)"></div>
              </div>
              <div class="scale-info-item scale-name">
                {{optionScaleName(option)}}
                <div *ngIf="isTareWeight(option)" class="scale-info-tare-date" translate>
                  Last captured date: {{ option.tareWeightDate | date:'shortDate':'UTC' }}
                </div>
              </div>
              <div class="scale-info-item scale-weight">{{optionScaleWeight(option)}}</div>
            </div>
          </div>
        </div>

        <div *ngIf="!config.group">
          <div class="option" attr.data-id="{{option.id}}" role="menuitem" *ngFor="let option of options" (click)="toggleOption(option)"
            tabindex="-1" (keypress)="optionKeyPress($event, option)" [ngClass]="{selected: isSelected(option), disabled: option.disabled, button: option.button, 'has-image': config.image}">
            <div class="scale-info-item scale-status">
              <div class="scale-status-indicator" [ngClass]="optionScaleStatus(option)"></div>
            </div>
            <div class="scale-info-item scale-name">{{optionScaleName(option)}}</div>
            <div class="scale-info-item scale-weight">{{optionScaleWeight(option)}}</div>
          </div>
        </div>

        <div class="option loading-option" *ngIf="config.loadingOptions">
          <my-loader></my-loader>
        </div>
      </div>
    </div>
  </div>
</div>
