import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'moment', pure: false})

export class MomentPipe implements PipeTransform {
  transform(input: any, format: any): any {
    if (!input) {
      return '';
    }
    return moment(input).format(format);
  }
}
