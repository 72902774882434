import { Component, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { clone } from 'lodash';

import { parseErrors } from '../../shared';
import { DispatchService } from '../dispatch.service';
import { JobEvent } from '../../job-events/job-event';
import { JobEventService } from '../../job-events/job-event.service';

@Component({
  selector: 'mobile-dispatch-jobs',
  templateUrl: './mobile-dispatch-jobs.component.html',
  styleUrls: ['./mobile-dispatch-jobs.component.scss']
})
export class MobileDispatchJobsComponent {
  @Input() jobEvents: JobEvent[];
  @Input() selectedDates: any[];
  @Output() getJobEvents = new EventEmitter();
  errors: string[];

  constructor(
    private jobEventService: JobEventService,
    private dispatchService: DispatchService
  ) { }

  dispatchAll() {
    let dispatchReqs = [];

    let startDate: Date = new Date();
    startDate.setHours(0, 0, 0, 0);
    if (this.selectedDates && this.selectedDates.length) {
      startDate = this.selectedDates[0];
    } else {
      this.selectedDates = [startDate];
    }
    let endDate: Date = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    this.jobEventService.listAll(10, {
      shift1_start__gte: startDate && startDate.toISOString(),
      shift1_start__lte: endDate && endDate.toISOString(),
      ordering: 'project__name,name',
      archived: 'False',
      cancelled: 'False',
      exclude_pending: 'True',
      dispatched: 'False'
    }).pipe(first()).subscribe(jobEvents => {
      jobEvents.forEach(j => {
        if (j.lastDispatchedTime) {
          dispatchReqs.push(this.dispatchService.save({
            jobEvent: j.id,
            notifyNew: true
          }));
        }
      });
      forkJoin(dispatchReqs).subscribe(() => {
        this.getJobEvents.emit(true);
      }, (err) => {
        this.errors = parseErrors(err);
      });
    });
  }
}
