<div class="app-content">
  <my-toolbar title="{{ 'Shifts' | translate }}"></my-toolbar>
  <notification *ngFor="let error of errors" translate>{{error}}</notification>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/trips']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" queryParamsHandling="merge" translate>Trips</a>
        <a [routerLink]="['/geotrips']" routerLinkActive="active" translate>GeoTrips</a>
        <a [routerLink]="['/punchcards']" routerLinkActive="active" translate>Punch Cards</a>
        <a [routerLink]="['/shifts']" routerLinkActive="active" translate>Shifts</a>
        <a [routerLink]="['/driver-reports']" routerLinkActive="active" translate>Driver Job Reports</a>
        <a [routerLink]="['/end-of-day-reports']" routerLinkActive="active" translate>End of Day Reports</a>
      </div>
    </div>
  </action-bar>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table #fancyTable
                  [availableColumns]="availableColumns"
                  [displayedColumns]="displayedColumns"
                  [(filters)]="appliedFilters"
                  [(search)]="search"
                  [config]="tableConfig"
                  (selectionChanged)="selector($event)"
                  (columnChange)="columnsChanged($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" (contextmenu)="openContextMenu($event, row.driver.id, row)" *ngSwitchCase="'driver'">
              <div class="data"> {{ row.driver && row.driver.name || '&nbsp;' }}</div>
            </div>
            <div class="value shift-date-picker" *ngSwitchCase="'shiftStartTime'">
              <div class="data">
                <date-time-picker-inline 
                [value]="row.startTime"
                (dateTimeChange)="editTime(row, 'start', $event)">
              </date-time-picker-inline>
              </div>
            </div>
            <div class="value shift-date-picker" *ngSwitchCase="'shiftEndTime'">
              <div class="data">
                <date-time-picker-inline 
                  [value]="row.endTime"
                  (dateTimeChange)="editTime(row, 'end', $event)">
                </date-time-picker-inline>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'adjustment'">
              <div class="data">
                <input type="number" value="{{ row.adjustment }}" (blur)="editAdjustment(row, $event.target.value)"/>
              </div>
            </div>
            <!-- shift duration -->
            <div class="value" *ngSwitchCase="'shiftDuration'">
              <div class="data">
                <p>{{ row.duration && row.duration() }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'confirmedTruck'">
              <div class="data">
                <p>{{ row.driver && row.driver.truck && row.driver.truck.name }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'assignedTruck'">
              <div class="data" *ngIf="row.truckAssignments && row.truckAssignments[0]">
                <p>{{ row.truckAssignments[0] && row.truckAssignments[0].truck && row.truckAssignments[0].truck.name }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'lunchBreakStartTime'">
              <div class="data" *ngIf="row.lunchBreaks && row.lunchBreaks[0]">
                <p class="bold">{{ row.lunchBreaks[0].start | moment: 'h:mm a' }}</p>
                <label>{{ row.lunchBreaks[0].start | moment: 'MMM DD, YYYY' }}</label>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'lunchBreakEndTime'">
              <div class="data" *ngIf="row.lunchBreaks && row.lunchBreaks[0]">
                <p class="bold">{{ row.lunchBreaks[0].end | moment: 'h:mm a' }}</p>
                <label>{{ row.lunchBreaks[0].end | moment: 'MMM DD, YYYY' }}</label>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'lunchBreakDuration'">
              <div class="data" *ngIf="row.lunchBreaks && row.lunchBreaks[0]">
                <p >{{ row.lunchBreaks[0].duration | formattedSeconds }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'vehicleMileageStart'">
              <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[row.shiftCheckups.length - 1]">
                <p >{{ row.shiftCheckups[row.shiftCheckups.length - 1].mileage }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'vehicleMileageEnd'">
              <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[0]">
                <p >{{ row.shiftCheckups[0].mileage }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'totalMileage'">
              <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[0] && row.shiftCheckups[row.shiftCheckups.length - 1]">
                <p >{{ row.shiftCheckups[0].mileage - row.shiftCheckups[row.shiftCheckups.length - 1].mileage }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'engineHoursStart'">
              <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[row.shiftCheckups.length - 1]">
                <p >{{ row.shiftCheckups[row.shiftCheckups.length - 1].engineHours | formattedSeconds: 'hours' }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'engineHoursEnd'">
              <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[0]">
                <p >{{ row.shiftCheckups[0].engineHours | formattedSeconds: 'hours' }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'totalEngineHours'">
              <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[0] && row.shiftCheckups[row.shiftCheckups.length - 1]">
                <p >{{ row.shiftCheckups[0].engineHours - row.shiftCheckups[row.shiftCheckups.length - 1].engineHours | formattedSeconds: 'hours' }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'vehicleBreakdownStart'">
              <div class="data" *ngIf="row.breakDowns && row.breakDowns[0]">
                <p class="bold">{{ row.breakDowns[0].start | moment: 'h:mm a' }}</p>
                <label>{{ row.breakDowns[0].start | moment: 'MMM DD, YYYY' }}</label>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'vehicleBreakdownEnd'">
              <div class="data" *ngIf="row.breakDowns && row.breakDowns[0]">
                <p class="bold">{{ row.breakDowns[0].end | moment: 'h:mm a' }}</p>
                <label>{{ row.breakDowns[0].end | moment: 'MMM DD, YYYY' }}</label>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'vehicleBreakdownDuration'">
              <div class="data" *ngIf="row.breakDowns && row.breakDowns[0]">
                <p >{{ row.breakDowns[0].duration | formattedSeconds }}</p>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'autoClosed'">
              <div class="data">
                <p *ngIf="row.autoClosed" translate>Yes</p>
                <p *ngIf="!row.autoClosed" translate>No</p>
              </div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Shifts</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <a [routerLink]="['/shifts/new']" [queryParams]="{returnTo: router.url}" class="btn btn-primary" translate>
          <i class="icon-plus"></i>Create Shift
        </a>
        <button *ngIf="driverShiftsExportEnabled" (click)="exportDriverShifts()" class="btn btn-default shift-export-btn" translate>
          Driver Shift Export
        </button>
        <column-toggle #columnToggle [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns">
        </column-toggle>
        <filters-panel [(appliedFilters)]="appliedFilters"
                       [customFilter]="true"
                       (openCustomFilter)="openFilters()"
                       [search]="search" (searchChange)="search = $event"></filters-panel>
        <action-menu icon="icon-more">
          <ng-container *ngFor="let option of multipleActionDropdownOptions">
            <button *ngIf="option.link" [disabled]="option.disabled" [ngClass]="{disabled: option.disabled}" mat-button [routerLink]="option.url">{{ option.name }}</button>
            <button *ngIf="!option.link" [disabled]="option.disabled" [ngClass]="{disabled: option.disabled}" mat-button (click)="setSelectedBulkAction(option)">{{ option.name }}</button>
          </ng-container>
        </action-menu>
      </div>
    </div>
  </div>
</div>

<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject" (onShiftEnd)="onShiftEnd()">
</app-driver-context-menu>