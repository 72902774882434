<my-toolbar [logo]=false [userDropdown]=false [menuButton]=false title="{{ 'Confirm Assignment' | translate }}" color="ruckit-green"></my-toolbar>

<div class="job-overview" *ngIf="!loading">
  <div class="no-results" *ngIf="!loading && !assignment" fxLayout="column" fxLayoutAlign="start center">
    <div class="icon">
      <i class="icon-projects"></i>
    </div>
    <h2 translate>No Assignment Found</h2>
    <p translate>No assignment was found with this ID.</p>
  </div>

  <div class="assignment-confirmed" *ngIf="!loading && assignment && assignment.driverConfirmed" fxLayout="column" fxLayoutAlign="start center">
    <div class="icon">
      <i class="icon-truck"></i>
    </div>
    <h2 translate>Assignment Confirmed</h2>
    <p translate>Your assignment to {{ job.name }} has been confirmed.</p>
  </div>

  <ng-container *ngIf="assignment && !assignment.driverConfirmed">
    <div class="job-detail" *ngIf="assignment && jobEvent && job">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="job-name">{{ job.name || '&nbsp;' }}</div>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="job-date">{{ jobEvent.dateRange || '&nbsp;' }}</div>
        <div class="invoice-rate currency">{{ (jobEvent.haulRate || 0 | currency:'USD':'symbol':'1.2-4' ) }} / {{ jobEvent.haulRateUnit || '&nbsp;' | titleCase }}</div>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="schedule">{{ jobEvent.shift1StartTime || '&nbsp;' }} - {{ jobEvent.endTime || '&nbsp;' }}</div>
        <div class="material">{{ job.material || '&nbsp;' }}</div>
      </div>
    </div>

    <div class="extended-job-detail" *ngIf="assignment && jobEvent && job">
      <div class="field-row route-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="route">
          <img [src]="job.mapImageURLWide" *ngIf="job.mapImageURLWide" />
        </div>
      </div>

      <div class="field-row locations-row" fxLayout="row" fxLayoutAlign="space-between flex-start" fxFlex="100">
        <div class="loading">
          <h3 translate>1. LOADING</h3>
          <div class="address" [innerHTML]="job.startLocationDescription"></div>
        </div>

        <div class="distance">
          <div class="distance-content">
            <ng-container *ngIf="(userPreferences$ | async) && (userPreferences$ | async).blob['distanceUnit'] === 'kilometers'; else distanceInMiles">
              {{ (job.distanceInKilometers || 0 | number:'1.1-2') || '0.0' }} km
            </ng-container>
            
            <ng-template #distanceInMiles>
              {{ (job.distanceInMiles || 0 | number:'1.1-2') || '0.0' }} mi
            </ng-template>
          </div>
        </div>

        <div class="unloading">
          <h3 translate>2. UNLOADING</h3>
          <div class="address right" [innerHTML]="job.endLocationDescription"></div>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="job-details" translate>Assignment Details</div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="centered estimated-loads label">
          <i class="icon-material"></i> {{ 'Estimated Loads' | translate }}</div>
        <div class="centered estimated-loads value">{{ assignment.estimatedNumberOfLoads }}</div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="centered estimated-loads label">
          <i class="icon-weight"></i> {{ 'Estimated Tons' | translate }}</div>
        <div class="centered estimated-loads value">{{ assignment.estimatedNumberOfTons }}</div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="centered truck label">
          <i class="icon-truck"></i> {{ 'Truck Number' | translate }}</div>
        <div class="centered truck value">{{ assignment.truck && assignment.truck.name }}</div>
      </div>

      <div class="field-row notes-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100" *ngIf="job.notes">
        <div class="notes" (click)="displayNotes = !displayNotes" translate>VIEW NOTES</div>
        <div class="notes-data" *ngIf="displayNotes">{{ job.notes }}</div>
      </div>

      <div class="field-row requirements-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
        <div class="loading-requirements">
          <h3 translate>Loading Requirements</h3>
          <div class="options">
            <div *ngFor="let option of job.checkinOptionDescriptions" class="centered option">
              <i class="{{option}}"></i>
              {{ option | titleCase }}
            </div>
          </div>
        </div>

        <div class="unloading-requirements">
          <h3 translate>Unloading Requirements</h3>
          <div class="options">
            <div *ngFor="let option of job.checkoutOptionDescriptions" class="centered option">
              <i class="{{option}}"></i>
              {{ option | titleCase }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
    <my-loader *ngIf="loading" [ngClass]="{'full-height': !assignment}"></my-loader>

    <div class="actions">
      <button type="submit" [disabled]="loading" class="btn btn-primary" (click)="confirmAssignment()" [ngClass]="{'loading': loading}"
        translate>
        CONFIRM ASSIGNMENT
      </button>
    </div>
  </ng-container>
</div>
