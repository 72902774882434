import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { ResourceService } from '../shared/resource.service';
import { JobEventShareStat } from './job-event-share.stat';
import { JobEventShareStatSerializer } from './job-event-share-stat.serializer';

@Injectable()
export class JobEventShareStatService extends ResourceService<JobEventShareStat> {
  private subject = new Subject<any>();

  constructor (http: HttpClient) {
    super(http, `jobevents/`, new JobEventShareStatSerializer());
  }

  getJobEventStatId(jobEventStatId: string) {
    this.subject.next({ id: jobEventStatId });
    this.slug = `jobevents/${jobEventStatId}/stats-by-collaborator/`;
  }
}
