"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./collaborators-widget.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("@ngx-translate/core");
var i5 = require("./collaborators-widget.component");
var i6 = require("./collaborator.service");
var styles_CollaboratorsWidgetComponent = [i0.styles];
var RenderType_CollaboratorsWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollaboratorsWidgetComponent, data: {} });
exports.RenderType_CollaboratorsWidgetComponent = RenderType_CollaboratorsWidgetComponent;
function View_CollaboratorsWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "collaborators-link"], ["translate", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 4), i1.ɵdid(3, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Collaborators "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "/jobs", _co.jobEvent.job.id, _co.jobEvent.id, "collaborators"); _ck(_v, 1, 0, currVal_2); var currVal_3 = ""; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CollaboratorsWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "rate"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " / ", " "])), i1.ɵppd(2, 4)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), (_v.parent.context.$implicit.invoiceRate || 0), "USD", "symbol", "1.2-4")); var currVal_1 = _v.parent.context.$implicit.invoiceType; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CollaboratorsWidgetComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-rate"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Set Rate! "]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CollaboratorsWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "collaborator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "dispatched"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " Dispatched"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.invoiceRate; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_v.context.$implicit.invoiceRate; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.organization; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.dispatched; _ck(_v, 5, 0, currVal_1); }); }
function View_CollaboratorsWidgetComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["view"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CollaboratorsWidgetComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["view all"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CollaboratorsWidgetComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "view-more"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 4), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_8)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "/jobs", _co.jobEvent.job.id, _co.jobEvent.id, "collaborators"); _ck(_v, 1, 0, currVal_2); var currVal_3 = (_co.collaborators.length <= 3); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_co.collaborators.length > 3); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CollaboratorsWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "collaborators-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CollaboratorsWidgetComponent_3)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_6)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.jobEvent && _co.collaborators); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.collaborators, 0, 3)); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.jobEvent && _co.collaborators) && _co.collaborators.length); _ck(_v, 8, 0, currVal_2); }, null); }
function View_CollaboratorsWidgetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollaboratorsWidgetComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.collaborators && _co.collaborators.length); _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_CollaboratorsWidgetComponent_0 = View_CollaboratorsWidgetComponent_0;
function View_CollaboratorsWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "collaborators-widget", [], null, null, null, View_CollaboratorsWidgetComponent_0, RenderType_CollaboratorsWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i5.CollaboratorsWidgetComponent, [i6.CollaboratorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CollaboratorsWidgetComponent_Host_0 = View_CollaboratorsWidgetComponent_Host_0;
var CollaboratorsWidgetComponentNgFactory = i1.ɵccf("collaborators-widget", i5.CollaboratorsWidgetComponent, View_CollaboratorsWidgetComponent_Host_0, { jobEvent: "jobEvent" }, {}, []);
exports.CollaboratorsWidgetComponentNgFactory = CollaboratorsWidgetComponentNgFactory;
