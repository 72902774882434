import { PunchCardDecision } from './punch-card-decision';
import { Organization } from '../organizations/organization';
import { PunchCard } from './punch-card';
import { UserSerializer } from '../users/user.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PunchCardDecisionSerializer {
  /**
  * @param  {any} json
  * @returns PunchCardDecision
  */
  fromJson(json: any): PunchCardDecision {
    json = camelcaseKeysDeep(json);
    const punchCardDecision = new PunchCardDecision();
    if (!json) { return punchCardDecision; }

    punchCardDecision.id = json.id;
    punchCardDecision.decidedAt = json.decidedAt;
    punchCardDecision.decisionStatus = json.decisionStatus;
    punchCardDecision.notes = json.notes;
    punchCardDecision.loading = json.loading;

    if (json.punchcard && typeof (json.punchcard) === 'object') {
      punchCardDecision.punchcard = new PunchCard(json.punchcard);
    } else if (json.punchCardDecision) {
      punchCardDecision.punchcard = new PunchCard({
        id: json.punchCard
      });
    }
    if (json.organization && typeof (json.organization) === 'object') {
      punchCardDecision.organization = new Organization(json.organization);
    } else if (json.organization) {
      punchCardDecision.organization = new Organization({
        id: json.organization
      });
    }
    if (json.decidedBy && typeof (json.decidedBy) === 'object') {
      punchCardDecision.decidedBy = new UserSerializer().fromJson(json.decidedBy);
    } else if (json.decidedBy) {
      punchCardDecision.decidedBy = new UserSerializer().fromJson({
        id: json.decidedBy
      });
    }

    return punchCardDecision;
  }

  /**
  * @param  {PunchCardDecision} punchCardDecision
  * @returns any
  */
  toJson(punchCardDecision: PunchCardDecision): any {
    let json = {
      id: punchCardDecision.id,
      decidedAt: punchCardDecision.decidedAt,
      decisionStatus: punchCardDecision.decisionStatus,
      notes: punchCardDecision.notes,
      punchcard: punchCardDecision.punchcard && punchCardDecision.punchcard.id,
      organization: punchCardDecision.organization && punchCardDecision.organization.id,
      decidedBy: punchCardDecision.decidedBy && punchCardDecision.decidedBy.id,
    };

    return decamelizeKeysDeep(json);
  }
}
