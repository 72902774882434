"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var api_service_1 = require("../api.service");
var index_1 = require("../index");
var tag_service_1 = require("../../tags/tag.service");
var user_service_1 = require("../../users/user.service");
var user_serializer_1 = require("../../users/user.serializer");
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(router, authenticationService, tagService, userService) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.tagService = tagService;
        this.userService = userService;
        this.color = '#2C2C2D';
        this.textColor = '#FFFFFF';
        this.tagsBackgroundColor = 'rgba(255, 255, 255, 0.15)';
        this.logo = false;
        this.sidebar = false;
        this.userDropdown = true;
        this.menuButton = false;
        this.updatedMarkets = new core_1.EventEmitter();
        this.loggedIn = false;
        this.menuOptions = [];
        this.favoriteTags = [];
        this.tags = [];
        this.userTagSelect = new forms_1.FormControl();
    }
    ToolbarComponent.prototype.ngOnInit = function () {
        this.user = this.authenticationService.user();
        this.favoriteTags = this.authenticationService.hasFavoriteTags() ? this.user.favoriteTags : [];
        this.loggedIn = this.authenticationService.isLoggedIn();
        this.menuOptions = [
            { name: 'Account', action: ['/settings', 'user', this.user && this.user.id, 'edit'], link: true },
            { name: 'Update Account', action: 'update-account' },
            { name: 'Logout', action: '/logout', link: true }
        ];
        if (this.user) {
            this.getTags();
        }
    };
    ToolbarComponent.prototype.ngOnDestroy = function () {
        if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
            this.tagsReq.unsubscribe();
        }
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
    };
    ToolbarComponent.prototype.compareTags = function (tag1, tag2) {
        return tag1 && tag2 ? tag1.id === tag2.id : tag1 === tag2;
    };
    ToolbarComponent.prototype.getTags = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
            this.tagsReq.unsubscribe();
        }
        if (next) {
            this.tagsObservable = this.tagService.listNext();
        }
        else {
            this.tags = [];
            this.tagsObservable = this.tagService.list(query);
        }
        if (this.tagsObservable) {
            this.tagsReq = this.tagsObservable.subscribe(function (res) {
                _this.tags.push.apply(_this.tags, res);
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    ToolbarComponent.prototype.selectTags = function (event) {
        var _this = this;
        var selectedTags = event && event.value ? event.value : [];
        var originalTags, modifiedTags = [];
        if (this.authenticationService.hasFavoriteTags()) {
            originalTags = this.user.favoriteTags.map(function (tag) { return tag.id; });
        }
        if (selectedTags.length > 0) {
            for (var _i = 0, selectedTags_1 = selectedTags; _i < selectedTags_1.length; _i++) {
                var tag = selectedTags_1[_i];
                modifiedTags.push(tag.id);
            }
        }
        if (this.user && (modifiedTags !== originalTags)) {
            this.user.favoriteTags = modifiedTags;
            this.userService.save({
                id: this.user.id, favoriteTags: this.user.favoriteTags
            }).subscribe(function (user) {
                _this.user = (new user_serializer_1.UserSerializer).fromJson(user);
                var currentUser = _this.authenticationService.user();
                if (currentUser) {
                    currentUser.name = _this.user.firstName + ' ' + _this.user.lastName;
                    currentUser.username = _this.user.email;
                    currentUser.favoriteTags = [];
                    user.favoriteTags.forEach(function (tagId) {
                        currentUser.favoriteTags.push(lodash_1.find(_this.tags, { id: tagId }));
                    });
                    _this.favoriteTags = currentUser.favoriteTags;
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                }
                _this.updatedMarkets.emit(true);
            }, function (err) {
                _this.user.favoriteTags = originalTags;
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    ToolbarComponent.prototype.setClasses = function () {
        var _a;
        return _a = {},
            _a[this.color] = this.color,
            _a['with-logo'] = this.logo,
            _a['without-logo'] = !this.logo,
            _a;
    };
    ToolbarComponent.prototype.openIconLink = function () {
        if (this.iconLink && this.iconLink.includes('?')) {
            var route = this.iconLink.split('?')[0];
            var params_1 = {};
            this.iconLink.split('?')[1].split('&').forEach(function (param) {
                var paramData = param.split('=');
                params_1[paramData[0]] = paramData[1];
            });
            this.router.navigate([route], { queryParams: params_1 });
        }
        else {
            this.router.navigate([this.iconLink]);
        }
    };
    ToolbarComponent.prototype.menuAction = function (action) {
        var _this = this;
        switch (action) {
            case 'update-account':
                this.userReq = this.userService.get(this.user.id).subscribe(function (user) {
                    _this.authenticationService.storeUser(Object.assign(_this.user, {
                        features: user.features,
                        enabledFeatures: user.enabledFeatures,
                        organization: user.organization
                    }));
                });
                break;
        }
    };
    return ToolbarComponent;
}());
exports.ToolbarComponent = ToolbarComponent;
