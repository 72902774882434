"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAYEDCOLUMNS = [
    'select',
    'driver',
    'shiftStartTime',
    'shiftEndTime',
    'adjustment',
    'shiftDuration',
    'assignedTruck',
    'confirmedTruck',
    'lunchBreakStartTime',
    'lunchBreakEndTime',
    'lunchBreakDuration',
    'vehicleMileageStart',
    'vehicleMileageEnd',
    'totalMileage',
    'engineHoursStart',
    'engineHoursEnd',
    'totalEngineHours',
    'vehicleBreakdownStart',
    'vehicleBreakdownEnd',
    'vehicleBreakdownDuration',
];
exports.AVAILABLECOLUMNS = function (translationService) { return [
    {
        key: 'select',
    },
    {
        key: 'driver',
        title: translationService.instant('Driver'),
        sortable: true,
        sortBy: 'driver__profile__last_name,driver__profile__first_name',
    },
    {
        key: 'shiftStartTime',
        title: translationService.instant('Shift Start'),
        sortable: true,
        sortBy: 'start_time',
    },
    {
        key: 'shiftEndTime',
        title: translationService.instant('Shift End'),
        sortable: true,
        sortBy: 'end_time',
    },
    {
        key: 'adjustment',
        title: translationService.instant('Adjustment (Minutes)'),
        sortable: false,
    },
    {
        key: 'shiftDuration',
        title: translationService.instant('Shift Duration'),
        sortable: false,
        sortBy: 'shift_duration',
    },
    {
        key: 'assignedTruck',
        title: translationService.instant('Assigned Truck'),
        sortable: false,
        sortBy: 'assigned_truck',
    },
    {
        key: 'confirmedTruck',
        title: translationService.instant('Confirmed Truck'),
        sortable: false,
        sortBy: 'confirmed_truck',
    },
    {
        key: 'lunchBreakStartTime',
        title: translationService.instant('Lunch Break Start'),
        sortable: false,
        sortBy: 'lunch_break_start_time',
    },
    {
        key: 'lunchBreakEndTime',
        title: translationService.instant('Lunch Break End'),
        sortable: false,
        sortBy: 'lunch_break_end_time',
    },
    {
        key: 'lunchBreakDuration',
        title: translationService.instant('Lunch Break Duration'),
        sortable: false,
        sortBy: 'lunch_break_duration',
    },
    {
        key: 'vehicleMileageStart',
        title: translationService.instant('Vehicle Mileage Start'),
        sortable: false,
    },
    {
        key: 'vehicleMileageEnd',
        title: translationService.instant('Vehicle Mileage End'),
        sortable: false,
    },
    {
        key: 'totalMileage',
        title: translationService.instant('Total Mileage'),
        sortable: false,
    },
    {
        key: 'engineHoursStart',
        title: translationService.instant('Engine Hours Start'),
        sortable: false,
    },
    {
        key: 'engineHoursEnd',
        title: translationService.instant('Engine Hours End'),
        sortable: false,
    },
    {
        key: 'totalEngineHours',
        title: translationService.instant('Total Engine Hours'),
        sortable: false,
    },
    {
        key: 'vehicleBreakdownStart',
        title: translationService.instant('Vehicle Breakdown Start'),
        sortable: false,
    },
    {
        key: 'vehicleBreakdownEnd',
        title: translationService.instant('Vehicle Breakdown End'),
        sortable: false,
    },
    {
        key: 'vehicleBreakdownDuration',
        title: translationService.instant('Vehicle Breakdown Duration'),
        sortable: false,
    },
    {
        key: 'autoClosed',
        title: translationService.instant('Auto Closed'),
        sortable: false,
    },
]; };
