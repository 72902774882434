import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MatDialog } from '@angular/material';
import { NgForm } from '@angular/forms';

import { ApiService } from '../shared/api.service';
import { UserService } from './user.service';
import { AuthenticationService } from '../shared/authentication.service';
import { parseErrors } from '../shared/api.service';
import { UserSerializer } from './user.serializer';
import { User } from './user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ruckit-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  @Input() user: User;
  loading = true;
  savePasswordLoading = true;
  accountUpdateLoading = false;
  userReq: Subscription;
  imageChanged = false;
  stateOptions = [];
  errors = [];
  passwordErrors = [];
  userPassword = {
    passwordCurrent: '',
    password: '',
    passwordConfirmation: ''
  };
  profileImage = {
    dataUri: null,
    file: null
  };
  @ViewChild('editUser', { static: false }) editUser;
  @ViewChild('editPassword', { static: false }) editPassword;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = false;
    this.savePasswordLoading = false;
  }

  ngOnDestroy() {
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }
  }

  saveUser() {
    this.loading = true;
    this.userService.saveWithImage(this.user, this.profileImage.file).subscribe((user) => {
      this.user = (new UserSerializer).fromJson(user);
      let _user = this.authenticationService.user();
      _user.name = this.user.firstName + ' ' + this.user.lastName;
      _user.username = this.user.email;
      _user.image = this.user.image;
      localStorage.setItem('currentUser', JSON.stringify(_user));

      this.router.navigate(['/settings', 'user']);
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  savePassword() {
    this.savePasswordLoading = true;
    let _user = Object.assign({ id: this.user.id }, this.userPassword);
    this.userService.save(_user).subscribe(() => {
      this.savePasswordLoading = false;
      this.userPassword = {
        passwordCurrent: '',
        password: '',
        passwordConfirmation: ''
      };
      this.editPassword.form.markAsPristine();
      this.editPassword.form.markAsUntouched();
    }, (err) => {
      this.passwordErrors = parseErrors(err);
      this.savePasswordLoading = false;
    });
  }

  fileChange(e, form: NgForm) {
    let profileImage = this.profileImage;

    let reader = new FileReader();
    reader.onload = function (loadEvent: any) {
      profileImage.dataUri = loadEvent.target.result;
    };

    let file = e.srcElement.files[0];
    profileImage.file = file;
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }

  updateAccount() {
    this.accountUpdateLoading = true;
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }

    this.userReq = this.userService.get(this.user.id).subscribe(user => {
      this.authenticationService.storeUser(
        Object.assign(this.user, {
          features: user.features,
          enabledFeatures: user.enabledFeatures,
          organization: user.organization
        })
      );
      this.accountUpdateLoading = false;
    }, (err) => {
      this.accountUpdateLoading = false;
    });
  }
}
