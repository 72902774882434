"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CoordinatesPipe = /** @class */ (function () {
    function CoordinatesPipe() {
    }
    CoordinatesPipe.prototype.transform = function (input) {
        var lat = Math.abs(input[1]).toString();
        var lon = Math.abs(input[0]).toString();
        var vDirection = input[1] > 0 ? 'N' : 'S';
        var hDirection = input[0] > 0 ? 'E' : 'W';
        return lat.substring(0, 2) + '&deg;' + lat.substring(3, 5) + '&#39;' + lat.substring(5, 7) + '&quot;' + vDirection + ', ' +
            lon.substring(0, 2) + '&deg;' + lon.substring(3, 5) + '&#39;' + lon.substring(5, 7) + '&quot;' + hDirection;
    };
    return CoordinatesPipe;
}());
exports.CoordinatesPipe = CoordinatesPipe;
