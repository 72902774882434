"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mobile-dispatch-carriers.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../directives/loader/loader.component.ngfactory");
var i3 = require("../directives/loader/loader.component");
var i4 = require("@angular/common");
var i5 = require("./mobile-dispatch-carriers.component");
var i6 = require("../connections/connection.service");
var styles_MobileDispatchCarriersComponent = [i0.styles];
var RenderType_MobileDispatchCarriersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDispatchCarriersComponent, data: {} });
exports.RenderType_MobileDispatchCarriersComponent = RenderType_MobileDispatchCarriersComponent;
function View_MobileDispatchCarriersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "slot"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-next-arrow"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_MobileDispatchCarriersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-loader", [["class", "mobile-loader full-height"]], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(1, 180224, null, 0, i3.LoaderComponent, [], null, null)], null, null); }
function View_MobileDispatchCarriersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mobile-dispatch-carriers"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchCarriersComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDispatchCarriersComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carriers; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_MobileDispatchCarriersComponent_0 = View_MobileDispatchCarriersComponent_0;
function View_MobileDispatchCarriersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-dispatch-carriers", [], null, null, null, View_MobileDispatchCarriersComponent_0, RenderType_MobileDispatchCarriersComponent)), i1.ɵdid(1, 770048, null, 0, i5.MobileDispatchCarriersComponent, [i6.ConnectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MobileDispatchCarriersComponent_Host_0 = View_MobileDispatchCarriersComponent_Host_0;
var MobileDispatchCarriersComponentNgFactory = i1.ɵccf("mobile-dispatch-carriers", i5.MobileDispatchCarriersComponent, View_MobileDispatchCarriersComponent_Host_0, { jobEvent: "jobEvent", scrollEvent: "scrollEvent" }, { selectCarrier: "selectCarrier" }, []);
exports.MobileDispatchCarriersComponentNgFactory = MobileDispatchCarriersComponentNgFactory;
