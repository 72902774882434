"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var tag_service_1 = require("../../tags/tag.service");
var ruckit_dropdown_component_1 = require("../ruckit-dropdown/ruckit-dropdown.component");
var TagInputComponent = /** @class */ (function () {
    function TagInputComponent() {
        this.marketsConfig = {
            searchable: true,
            multiselect: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: 'Select Markets',
            loadingText: 'Loading Markets...',
            noResultsText: 'No Markets',
            service: tag_service_1.TagService,
            query: {}
        };
        this.selectedOptions = [];
        this.onSelect = new core_1.EventEmitter();
    }
    TagInputComponent.prototype.selectTags = function (tags) {
        this.onSelect.emit(tags);
    };
    TagInputComponent.prototype.forceSelectedTags = function (tags) {
        this.selectedOptions = tags;
        this.marketsDropdown.selectedItems = this.selectedOptions;
    };
    return TagInputComponent;
}());
exports.TagInputComponent = TagInputComponent;
