import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { pickBy } from 'lodash';

import { JOBWEIGHTOPTIONS } from './../../app.constants';
import { SimpleCollaborationTemplate } from './collaboration-template';

export interface DropdownOption {
  value: string;
  label: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'collaboration-template',
  templateUrl: './collaboration-template.component.html',
  styleUrls: ['./collaboration-template.component.scss']
})
export class CollaborationTemplateComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() hasLoadListsEnabled = false;
  @Input() rateTrackingOptions: DropdownOption[] = [];
  @Input() unitsOfMeasure: DropdownOption[] = [];

  @Output() onApplyToAll: EventEmitter<SimpleCollaborationTemplate> = new EventEmitter();

  weightOptions: DropdownOption[] = [...JOBWEIGHTOPTIONS];
  defaultWeightOption: DropdownOption;
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  rateWeightConfig = {
    nameProperty: 'label',
    loadingOptions: false
  };
  collaborationTemplate: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.setCollaborationTemplate();
    if (this.unitsOfMeasure !== undefined && this.unitsOfMeasure.length > 0) {
      this.collaborationTemplate.controls['requestedUnit'].setValue(
        this.unitsOfMeasure.find((unit) => unit.name === 'Trucks') || null
      );
    }
    this.defaultWeightOption = this.weightOptions.find((opt) => opt.selected) || this.weightOptions[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitsOfMeasure'] && changes['unitsOfMeasure'].currentValue.length > 0 && this.collaborationTemplate) {
      this.collaborationTemplate.controls['requestedUnit'].setValue(
        this.unitsOfMeasure.find((unit) => unit.name === 'Trucks') || null
      );
    }
  }

  private setCollaborationTemplate(): void {
    this.collaborationTemplate = this.fb.group({
      requestedAmount: [null, Validators.min(1)],
      requestedUnit: [null],
      invoiceType: [null],
      invoiceRate: [null, Validators.min(0)],
      invoiceWeightUnit: [null],
      notes: ['']
    });
  }

  setFormControlValue(control: string, option: DropdownOption): void {
    this.collaborationTemplate.controls[control].setValue(option);

    if (control === 'invoiceType' && option.value === 'weight') {
      this.collaborationTemplate.controls.invoiceWeightUnit.setValue(this.defaultWeightOption);
    } else if (control === 'invoiceType') {
      this.collaborationTemplate.controls.invoiceWeightUnit.setValue(option);
    }
  }

  applyTemplate(): void {
    const template = pickBy(this.collaborationTemplate.value, v => v !== null && v !== undefined && v !== '' && v['length'] !== 0);
    this.onApplyToAll.emit(template as SimpleCollaborationTemplate);
  }
}
