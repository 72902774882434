<form #editLocation="ngForm" [ngClass]="{'mobile': !device.desktop}">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Location</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content class="mat-dialog-content">
    <div class="left-side">
      <ng-container *ngIf="device.desktop">
        <h2 translate>Enter Details and Draw Geofence</h2>
        <div class="instructions">
          <p translate>To improve location accuracy and trip status times, draw a geofence around the location on the map to
            the right.</p>
          <img src="assets/img/location-geofence.gif" alt="{{ 'Geofencing steps' | translate }}" />
        </div>
      </ng-container>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group street" fxFlex="100">
          <label translate>Address</label>
          <input places-auto-complete (place_changed)="placeChanged($event)" type="text" id="street" name="street"
            placeholder="{{ 'Address' | translate }}" autocomplete="off" autocorrect="off" autocapitalize="off"
            spellcheck="off" (blur)="geolocate()" [(ngModel)]="location.street" #street="ngModel" [disabled]="loading" />
          <address-select [addresses]="addresses" [selectedAddress]="selectedAddress" [showingAddresses]="showingAddresses"
            (addressChanged)="updateAddress($event)"></address-select>
          <div (click)="onConfirmNewMarkerLocation()" class="update-address" translate *ngIf="markerMoved">
            <mat-icon>gps_fixed</mat-icon>Marker placed at new address, click here to update
          </div>
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group country" fxFlex="50">
          <label translate>Country</label>
          <select name="country" [disabled]="loading" required (change)="selectCountry($event.target.value)">
            <option value="" selected disabled hidden>{{ 'Select Country' | translate }}</option>
            <option *ngFor="let countryOption of countryOptions" [value]="countryOption.id"
              [selected]="countryOption.id === location.country">{{ countryOption.name }}</option>
          </select>
        </div>
        <div class="field-group state" fxFlex="50">
          <ng-container *ngIf="location.country !== 'CA'">
            <label translate>State</label>
            <select name="state" [disabled]="loading" (change)="selectState($event.target.value)">
              <option value="" selected disabled hidden>{{ 'Select State' | translate }}</option>
              <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id"
                [selected]="stateOption.id === location.state">{{ stateOption.name }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="location.country === 'CA'">
            <label translate>Province</label>
            <select name="state" [disabled]="loading" (change)="selectState($event.target.value)">
              <option value="" selected disabled hidden>{{ 'Select Province' | translate }}</option>
              <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id"
                [selected]="stateOption.id === location.state">{{ stateOption.name }}</option>
            </select>
          </ng-container>
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group city" fxFlex="50">
          <ng-container *ngIf="location.country !== 'CA'">
            <label translate>City</label>
            <input type="text" [disabled]="loading" name="city" placeholder="{{ 'City' | translate }}"
              [(ngModel)]="location.city" #city="ngModel" (blur)="geolocate()" />
            <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
          </ng-container>
          <ng-container *ngIf="location.country === 'CA'">
            <label translate>Municipality</label>
            <input type="text" [disabled]="loading" name="city" placeholder="{{ 'Municipality' | translate }}"
              [(ngModel)]="location.city" #city="ngModel" (blur)="geolocate()" />
            <span [ngClass]="{'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
          </ng-container>
        </div>
        <div class="field-group zipcode" fxFlex="50">
          <ng-container *ngIf="location.country !== 'CA'">
            <label translate>Zip</label>
            <input type="text" [disabled]="loading" name="zipcode" placeholder="{{ 'Zip' | translate }}"
              (blur)="geolocate()" [(ngModel)]="location.zipcode" #zipcode="ngModel" />
            <span
              [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
          </ng-container>
          <ng-container *ngIf="location.country === 'CA'">
            <label translate>Postal Code</label>
            <input type="text" [disabled]="loading" name="zipcode" placeholder="{{ 'Postal Code' | translate }}"
              (blur)="geolocate()" [(ngModel)]="location.zipcode" #zipcode="ngModel" />
            <span
              [ngClass]="{'ng-valid': zipcode.valid, 'ng-invalid': zipcode.invalid, 'ng-pristine': zipcode.pristine}"></span>
          </ng-container>
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group location-name" fxFlex="100">
          <label translate>Location Name</label>
          <input type="text" name="location-name" [disabled]="loading" placeholder="{{ 'Location Name' | translate }}"
            required [(ngModel)]="location.name" #name="ngModel" />
        </div>
      </div>
    
      <div class="field-row" fxLayout="column" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="field-group latitude" fxFlex="50">
            <label translate>Latitude</label>
            <input type="text" id="latitude" name="latitude" [(ngModel)]="location.latitude" #latitude="ngModel" required />
          </div>
          <div class="field-group longitude" fxFlex="50">
            <label translate>Longitude</label>
            <input type="text" id="longitude" name="longitude" [(ngModel)]="location.longitude" #longitude="ngModel"
              required />
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between center">
          <div (click)="getMyLocation()" fxFlex="100" class="geolocate-device" translate
            *ngIf="!device.desktop && canGeolocate">
            <mat-icon>gps_fixed</mat-icon>Use my device's location
          </div>
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group" fxFlex="100">
          <label translate>Markets</label>
          <tag-input *ngIf="location && location.id" name="tags" [selectedOptions]="location.tags"
            (onSelect)="tagChange($event)"></tag-input>
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group location-type" fxFlex="100">
          <label translate>Select Location Type</label>
          <ruckit-dropdown [config]="locationTypeDropdownConfig"
            [selectedOption]="location.locationType && location.locationType.id"
            (onSelect)="onSelect('locationType', $event)">
          </ruckit-dropdown>
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="qrEnabled || laserFicheEnabled">
        <div class="field-group location-owner" fxFlex="100" *ngIf="!showLocationOwnerTextField">
          <label translate>Select Owner</label>
          <dropdown title="{{ 'Location Owner' | translate }}" #ownersDropdown [selectedOption]="location.locationOwner"
            [options]="ownerOptions" (onSelect)="selectOwner($event)" (onSearch)="dropdownSearch($event, 'owner')"
            (nextPage)="dropdownNextPage($event, 'owner')" [config]="ownersConfig"></dropdown>
        </div>
        <div class="field-group location-owner" fxFlex="100" *ngIf="showLocationOwnerTextField">
          <label translate>New Owner</label>
          <input type="text" id="location-owner" [disabled]="loading" name="location_owner" autocomplete="off"
            autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="location.locationOwner.name"
            #locationOwnerName="ngModel" />
        </div>
      </div>
    
      <h2 class="target-times" translate>Target Times</h2>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group loading-target" fxFlex="50">
          <label translate>Loading Target</label>
          <input type="text" id="loading-target" [disabled]="loading" name="loading_target" autocomplete="off"
            autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="location.averageLoadingTime"
            #averageLoadingTime="ngModel" />
        </div>
        <div class="field-group unloading-target" fxFlex="50">
          <label translate>Unloading Target</label>
          <input type="text" id="unloading-target" [disabled]="loading" name="unloading_target" autocomplete="off"
            autocorrect="off" autocapitalize="off" spellcheck="off" [(ngModel)]="location.averageUnloadingTime"
            #averageunLoadingTime="ngModel" />
        </div>
      </div>
    
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group action cancel" fxFlex="20">
          <button class="btn btn-default" (click)="cancel(); false" translate>
            Cancel
          </button>
        </div>
        <div class="field-group action clear" fxFlex="40">
          <button id="clear-geofence-button" class="btn btn-primary clear-geofence-btn" (click)="clearGeoFence()" 
            [ngClass]="{loading:loading}" [disabled]="!isGeoFenceAvailable" translate>
            Clear Geofence
          </button>
        </div>
        <div class="field-group action save" fxFlex="40">
          <button id="new-location-button" class="btn btn-primary" (click)="editLocation.form.valid && submit(); false" [disabled]="!editLocation.form.valid || loading"
            [ngClass]="{loading:loading}" translate>
            Save Location
          </button>
        </div>
      </div>
    </div>
    
    <div class="right-side" *ngIf="device.desktop">
      <notification *ngFor="let error of errors">{{error}}</notification>
    
      <div class="field-group map">
        <div class="overlay" *ngIf="marker && !marker.mappable">
          <h2 translate>Please Add an Address</h2>
        </div>
        <ngui-map *ngIf="location && location.latitude" (mapReady$)="initMap($event)"
          center="{{location.latitude}}, {{location.longitude}}" [options]="mapOptions">
          <drawing-manager [drawingMode]="drawingMode" [drawingControl]="true" [drawingControlOptions]="{
                               position: 'TOP_RIGHT',
                               drawingModes: ['polygon']
                             }" [polygonOptions]="{
                               strokeColor: '#FF8C00',
                               strokeOpacity: 0.8,
                               strokeWeight: 2,
                               fillColor: '#FF8C00',
                               fillOpacity: 0.35
                             }">
          </drawing-manager>
          <marker *ngIf="marker && marker.lat && marker.lon" [position]="[marker.lat, marker.lon]"
            [geoFallbackPosition]="[marker.lat, marker.lon]" draggable="true" (dragend)="markerDragEnd($event)">
          </marker>
          <marker *ngIf="marker && marker.latLng" [position]="marker.latLng" [geoFallbackPosition]="marker.latLng"
            draggable="true" (dragend)="markerDragEnd($event)">
          </marker>
        </ngui-map>
      </div>
    </div>
  </div>
</form>
