"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CollaboratorStat = /** @class */ (function () {
    function CollaboratorStat() {
        this.selected = false;
        this.canExpand = false;
    }
    return CollaboratorStat;
}());
exports.CollaboratorStat = CollaboratorStat;
