"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var invitation_service_1 = require("../invitation/invitation.service");
var connection_service_1 = require("../connections/connection.service");
var api_service_1 = require("../shared/api.service");
var SendJobDialogComponent = /** @class */ (function () {
    function SendJobDialogComponent(dialogRef, invitationService, connectionService, elementRef) {
        this.dialogRef = dialogRef;
        this.invitationService = invitationService;
        this.connectionService = connectionService;
        this.elementRef = elementRef;
        this.job = {};
        this.jobevent = {};
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
        this.yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        this.connections = [];
        this.connectionsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.model = {
            data: {
                dates: [],
                jobevent: null,
                numTrucks: null,
            },
            recipientOrganization: null,
            kind: 'new_job'
        };
    }
    SendJobDialogComponent.prototype.ngOnInit = function () {
        this.getConnections();
        this.model.data.jobevent = this.jobevent.id;
    };
    SendJobDialogComponent.prototype.ngOnDestroy = function () {
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
    };
    SendJobDialogComponent.prototype.submit = function () {
        var _this = this;
        this.model.data.dates = this.model.data.dates.map(function (d) { return _this.convertDateFormat(d); });
        this.invitationService.createInvitation(this.model).subscribe(function (result) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    SendJobDialogComponent.prototype.getConnections = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
        this.connectionsReq = this.connectionService.list(__assign({ ordering: 'organization__name', can_accept_jobs: 'True' }, query)).subscribe(function (connections) {
            _this.connections = connections.map(function (connection) {
                return { name: connection.organization.name, id: connection.organization.id };
            });
            if (_this.model.recipientOrganization) {
                _this.connections = lodash_1.reject(_this.connections, _this.model.recipientOrganization);
                _this.connections.unshift(_this.model.recipientOrganization);
                _this.connectionsDropdown.selectedOption = lodash_1.find(_this.connections, { id: _this.model.recipientOrganization.id });
            }
            _this.connectionsDropdown.config.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.connectionsDropdown.config.loadingOptions = false;
        });
    };
    SendJobDialogComponent.prototype.selectConnection = function (e, form) {
        this.model.recipientOrganization = e.id;
        form.controls['recipient_organization'].markAsDirty();
    };
    SendJobDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'connection':
                config = this.connectionsConfig;
                service = this.connectionService;
                options = this.connections;
                break;
        }
        if (!config.loadingOptions) {
            var o = service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'connection':
                            _this.connections = _this.connections.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    SendJobDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'connection':
                this.getConnections({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    SendJobDialogComponent.prototype.convertDateFormat = function (value) {
        var d = value && new Date(value);
        if (isNaN(+d)) {
            return;
        }
        return d.toISOString().split('T')[0];
    };
    return SendJobDialogComponent;
}());
exports.SendJobDialogComponent = SendJobDialogComponent;
