"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TimeFromNowPipe = /** @class */ (function () {
    function TimeFromNowPipe() {
    }
    TimeFromNowPipe.prototype.transform = function (input) {
        if (!input) {
            return '';
        }
        var inputTime = new Date(input);
        var currentTime = new Date();
        var timeDiff = Math.abs(currentTime.getTime() - inputTime.getTime());
        if (timeDiff > 31556952000) {
            return Math.floor(timeDiff / 31556952000) + 'y';
        }
        else if (timeDiff > 2592000000) {
            return Math.floor(timeDiff / 2592000000) + 'mo';
        }
        else if (timeDiff > 604800000) {
            return Math.floor(timeDiff / 604800000) + 'w';
        }
        else if (timeDiff > 86400000) {
            return Math.floor(timeDiff / 86400000) + 'd';
        }
        else if (timeDiff > 3600000) {
            return Math.floor(timeDiff / 3600000) + 'h';
        }
        else {
            return Math.floor(timeDiff / 60000) + 'm';
        }
    };
    return TimeFromNowPipe;
}());
exports.TimeFromNowPipe = TimeFromNowPipe;
