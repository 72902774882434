import { Injectable } from '@angular/core';
import { ResourceService } from '../shared/resource.service';
import { HttpClient } from '@angular/common/http';
import { Preference } from './preference';
import { PreferenceSerializer } from './preference.serializer';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PreferenceService extends ResourceService<Preference> {
  private userPreferenceKey = 'GeneralUserPreferences';

  userPreference$: BehaviorSubject<Preference> = new BehaviorSubject(
    new PreferenceSerializer().fromJson({
      blob: {
        dispatchLoadType: 'all-day',
        mapStyle: 'ruckit-map-style',
        markerType: 'driver-initials',
        markerStatus: 'each-segment',
        staggerAssignmentTime: 'staggered_start',
        distanceUnit: 'miles'
      }
    })
  );

  constructor(http: HttpClient) {
    super(http, 'preferences/', new PreferenceSerializer());
  }

  getUserPreferenceObservable(userId: string): void {
    this.list({
      name: this.userPreferenceKey,
      type: 'user',
      profile: userId
    }).subscribe(
      (preference) => {
        let userPreference: Preference;
        if (preference && preference.length) {
          userPreference = preference[0];
        }
        if (userPreference.blob && !userPreference.blob.hasOwnProperty('distanceUnit')) {
          userPreference.blob['distanceUnit'] = 'miles';
        }
        this.userPreference$.next(userPreference);
      }
    );
  }
}
