import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { filter } from 'lodash';

import { JobEvent } from '../../job-events/job-event';
import { Assignment } from '../../assignments/assignment';

@Component({
  selector: 'mobile-dispatch-assignments',
  templateUrl: './mobile-dispatch-assignments.component.html',
  styleUrls: ['./mobile-dispatch-assignments.component.scss']
})
export class MobileDispatchAssignmentsComponent implements OnInit {
  loading = false;
  errors = [];
  emptySlots = [];
  filledSlots = [];
  @Input() jobEvent: JobEvent;
  @Input() shift;
  @Input() editMode: boolean;
  @Output() selectSlot: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAssignment: EventEmitter<Assignment> = new EventEmitter<Assignment>();
  @Output() selectRemoveSlot: EventEmitter<any> = new EventEmitter<any>();
  @Output() dispatchAssignments: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
    this.emptySlots = filter(this.shift.slots, (slot) => { return !slot.driver; });
    this.filledSlots = filter(this.shift.slots, (slot) => { return slot.driver; });
  }

  select(shift, slot = null, index: number = null) {
    if (slot && slot.driver && slot.assignment) {
      // slot.editSlot = !slot.editSlot;
    } else {
      this.selectSlot.emit({shift: shift, index: index});
    }
  }

  remove(slot) {
    this.selectRemoveSlot.emit(slot);
  }

  dispatch() {
    this.dispatchAssignments.emit();
  }
}
