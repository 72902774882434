import { TranslateService } from '@ngx-translate/core';

export const DISPLAYEDCOLUMNS = [
  'select',
  'name',
  'device-status',
  'phone-number',
  'truck',
  'duty-status',
  'shift-status',
  'location-authorization',
  'low-power-mode',
  'background-app-refresh',
  'app-version',
  'device-os',
  'last-communication',
  'action',
];

export const AVAILABLECOLUMNS = (
  translationService: TranslateService
): { key: string; title?: string; sortable?: boolean; sortBy?: string }[] => [
  { key: 'select' },
  {
    key: 'name',
    title: translationService.instant('Name'),
    sortable: true,
    sortBy: 'profile__first_name',
  },
  {
    key: 'phone-number',
    title: translationService.instant('Phone Number'),
    sortable: true,
    sortBy: 'profile__phone_number',
  },
  {
    key: 'carrier',
    title: translationService.instant('Carrier'),
    sortable: true,
    sortBy: 'carrier__name',
  },
  { key: 'truck', title: translationService.instant('Truck'), sortable: false },
  {
    key: 'billing-id',
    title: translationService.instant('Billing ID'),
    sortable: false,
  },
  {
    key: 'markets',
    title: translationService.instant('Markets'),
    sortable: false,
  },
  {
    key: 'duty-status',
    title: translationService.instant('Duty Status'),
    sortable: false,
  },
  {
    key: 'shift-status',
    title: translationService.instant('Shift Status'),
    sortable: false,
  },
  {
    key: 'device-status',
    title: translationService.instant('Issues'),
    sortable: false,
  },
  {
    key: 'location-authorization',
    title: translationService.instant('Location Authorization'),
    sortable: true,
    sortBy: 'last_device_status_event__location_authorization',
  },
  {
    key: 'low-power-mode',
    title: translationService.instant('Low Power Mode'),
    sortable: true,
    sortBy: 'last_device_status_event__low_power_mode',
  },
  {
    key: 'background-app-refresh',
    title: translationService.instant('Background App Refresh'),
    sortable: true,
    sortBy: 'last_device_status_event__background_app_refresh',
  },
  {
    key: 'device-storage',
    title: translationService.instant('Storage'),
    sortable: true,
    sortBy: 'last_device_status_event__low_device_storage',
  },
  {
    key: 'app-version',
    title: translationService.instant('App Version'),
    sortable: false,
  },
  {
    key: 'device-os',
    title: translationService.instant('Device OS'),
    sortable: false,
  },
  {
    key: 'last-communication',
    title: translationService.instant('Last Communication'),
    sortable: false,
  },
  {
    key: 'last-location-update',
    title: translationService.instant('Last Location Update'),
    sortable: false,
  },
  {
    key: 'deleted',
    title: translationService.instant('Deleted'),
    sortable: false,
  },
  {
    key: 'action',
    title: translationService.instant('Action'),
    sortable: false,
  },
];
