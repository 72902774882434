import { User } from '../users/user';
import { UserSerializer } from '../users/user.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Message {
  id: string;
  user: User;
  msg: string;
  jobEventId: string;

  constructor(messageInfo: any) {
    messageInfo = camelcaseKeysDeep(messageInfo);

    this.id = messageInfo.id;
    if (typeof (messageInfo.user) === 'object') {
      this.user = (new UserSerializer).fromJson(messageInfo.user);
    } else {
      this.user = (new UserSerializer).fromJson({ id: messageInfo.user });
    }
    this.msg = messageInfo.msg;
  }
}
