"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var LastHourPipe = /** @class */ (function () {
    function LastHourPipe() {
    }
    LastHourPipe.prototype.transform = function (record, hour, complete, active) {
        if (complete === void 0) { complete = false; }
        if (active === void 0) { active = false; }
        var ended = false;
        var endTime, timeAtHour, timeAtEndOfHour;
        if (complete || active || !record || !record.endTimeTimestamp) {
            return false;
        }
        if (record.endTimeTimestamp) {
            endTime = new Date(record.endTimeTimestamp);
            var _date = moment(endTime).format('YYYY-MM-DD');
            timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
            timeAtEndOfHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
            timeAtEndOfHour.add(3599, 'seconds');
            var _endTime = moment(endTime);
            if (timeAtHour < _endTime && _endTime <= timeAtEndOfHour) {
                ended = true;
            }
        }
        return ended;
    };
    return LastHourPipe;
}());
exports.LastHourPipe = LastHourPipe;
