import { Carrier } from './carrier';

export let CARRIER: Carrier = <Carrier> {
  id: '00000000-0000-0000-0000-000000000001',
  name: 'Sample Carrier',
  dotNumber: 'ABC12345',
  canCreateJobs: true,
  leasedOrg: false,
  uniqueBillingId: '01010101'
};
