import { PayRecord } from './pay-record';
import { DateTimeRange } from '../shared';
import { AssignmentSerializer } from '../assignments/assignment.serializer';
import { Trip } from '../trips/trip';
import { PunchCard } from '../punch-cards/punch-card';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PayRecordSerializer {
  fromJson(json: any): PayRecord {
    json = camelcaseKeysDeep(json);
    const payRecord = new PayRecord();
    if (!json) { return payRecord; }
    payRecord.id = json.id;
    payRecord.ticketNumber = json.ticketNumber;
    payRecord.paySheet = json.paySheet;
    payRecord.processStatus = json.processStatus;
    payRecord.data = {
      referenceId: json.data && json.data.assignment && json.data.assignment.id,
      assignment: json.data && json.data.assignment && new AssignmentSerializer().fromJson(json.data.assignment),
      uniqueStartDate: json.data && json.data.assignment && json.data.assignment.uniqueStart,
      shift1StartTimestamp: json.data && json.data.assignment && json.data.assignment.jobevent && json.data.assignment.jobevent.shift1Start,
      shift1EndTimestamp: json.data && json.data.assignment && json.data.assignment.jobevent && json.data.assignment.jobevent.shift1End,
      rowData: {
        referenceId: json.data && json.data.assignment && json.data.assignment.id,
        trips: json.data && json.data.assignment &&
          json.data.assignment.trips && json.data.assignment.trips.map(trip => (new Trip(trip))).reverse(),
        predictedTrips: json.data && json.data.assignment &&
          json.data.assignment.predictedTrips && json.data.assignment.predictedTrips.map(trip => (new Trip(trip))).reverse(),
        punchcards: json.data && json.data.assignment &&
          json.data.assignment.punchcards && json.data.assignment.punchcards.map(punchcard => (new PunchCard(punchcard))).reverse(),
        shifts: json.data && json.data.assignment &&
          json.data.assignment.shifts && json.data.assignment.shifts.map(shift => ({
            type: 'shift',
            startDatetime: shift.startTime,
            endDatetime: shift.endTime,
          })).reverse(),
        payLines: json.payLines,
        payBasis: json.payBasis,
        payAdjustmentTotal: json.payAdjustmentTotal,
        notes: json.notes
      }
    };
    if (
      (json.data && json.data.assignment && json.data.assignment.uniqueStart) ||
      (json.data && json.data.assignment && json.data.assignment.jobevent && json.data.assignment.jobevent.shift1StartTimestamp)
    ) {
      payRecord.data.rowData.shifts.push(<DateTimeRange>{
        type: 'scheduled',
        startDatetime: json.data.assignment.uniqueStart || json.data.assignment.jobevent.shift1StartTimestamp
      });
    }
    return payRecord;
  }

  toJson(payRecord: PayRecord): any {
    const json: { [key: string]: any } = {
      id: payRecord.id,
      paySheet: payRecord.paySheet,
      data: payRecord.data,
      payAdjustmentTotal: payRecord.data.rowData.payAdjustmentTotal,
      notes: payRecord.data.rowData.notes,
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
