<form #bulkEdit="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Selected Rows</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="field-group full-width">
      <label translate>Adjust Durations (Minutes)</label>
      <input [(ngModel)]="changes.duration" name="duration" type="number" placeholder="{{ 'Type a number' | translate }}">
    </div>
    <ng-container *ngIf="data && data.view === 'timeline'; else tableOptions">
      <div class="field-group half-width">
        <label translate>Adjust Start Time</label>
        <input [(ngModel)]="changes.startTime" name="startTime" type="time">
      </div>
      <div class="field-group half-width">
        <label translate>Adjust End Time</label>
        <input [(ngModel)]="changes.endTime" name="endTime" type="time">
      </div>
    </ng-container>
    <ng-template #tableOptions>
      <div class="field-group full-width">
        <label translate>Change Pay Start</label>
        <dropdown #payStartSelect
                  title="{{ 'Select Start Option' | translate }}"
                  [config]="payDropdownConfig"
                  [options]="payStartOptions"
                  (onSelect)="changes.payStart = $event.value"></dropdown>
      </div>
      <div class="field-group full-width">
        <label translate>Change Pay End</label>
        <dropdown #payEndSelect
                  title="{{ 'Select End Option' | translate }}"
                  [config]="payDropdownConfig"
                  [options]="payEndOptions"
                  (onSelect)="changes.payEnd = $event.value"></dropdown>
      </div>
    </ng-template>
    
      
   
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="submit()" translate>
      Perform Edits
    </button>
  </div>
</form>
