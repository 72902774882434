import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone } from 'lodash';

import { Driver } from './driver';
import { DriverSerializer } from './driver.serializer';
import { environment } from '../../environments/environment';
import { handleError, requestHeaders } from '../shared/api.service';
import { ResourceService } from '../shared/resource.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class DriverService extends ResourceService<Driver> {
  baseUrl = environment.serverUrl;
  driver: Driver;
  public nextUri;
  progress$;
  progress;
  public count = 0;

  constructor (http: HttpClient) {
    super(http, 'drivers/', new DriverSerializer());
  }

  saveDriver(driver) {
    const driverUrl = this.baseUrl + 'drivers/';
    const _driver = decamelizeKeysDeep(clone(driver));
    if (typeof _driver.carrier === 'object') {
      _driver.carrier = _driver.carrier.id;
    }
    if (_driver.user && _driver.user.username === _driver.username) {
      delete _driver.user.username;
    }
    if (_driver.user && _driver.user.password.length === 0) {
      delete _driver.user.password;
    }

    _driver.tags = _driver.tags && _driver.tags.map((tag) => {
      if (typeof tag === 'object') { tag = tag.name; }
      return tag;
    });

    if (_driver.image) { delete _driver.image; }
    if (_driver.truck && typeof _driver.truck === 'object') {
      _driver.truck = _driver.truck.id;
    }

    if (!driver.id) {
      _driver['unmanned_weighing'] = false;
      return this.http.post(driverUrl, _driver, {headers: requestHeaders()});
    } else {
      return this.http.put(driverUrl + driver.id + '/', _driver, {headers: requestHeaders()});
    }
  }

  invite(driver) {
    const inviteUrl = this.baseUrl + 'invitations/';
    const _driver = decamelizeKeysDeep(clone(driver));

    let params = { kind: 'new_driver', data: { driver: _driver } };
    if (!driver.id) {
      return this.http.post(inviteUrl, params, { headers: requestHeaders() });
    }
  }

  assign(driver): Observable<Driver> {
    const assignUrl = this.baseUrl + 'drivers/' + driver.id + '/?all_carriers=True';
    return this.http.put(assignUrl, {
      truck: driver.truck.id
    }, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  listCondensed(params: HttpParams): Observable<Driver[]> {
    return this.http.get(this.baseUrl + 'drivers/compact/', {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => (this.extractData(res))),
      catchError(handleError)
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(driver => {
        return new DriverSerializer().fromJson(driver);
      });
    } else if (res) {
      return new DriverSerializer().fromJson(res);
    } else {
      return {};
    }
  }

  public uploadImage(method: string, driverId: string[], files: File[]): Observable<any> {
    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (let i = 0; i < files.length; i++) {
        formData.append('image', files[i], files[i].name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };
      const url = this.baseUrl + 'drivers/' + driverId + '/';

      xhr.open(method, url, true);
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }
}
