"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_constants_1 = require("../../app.constants");
var WeightPipe = /** @class */ (function () {
    function WeightPipe() {
    }
    WeightPipe.prototype.transform = function (value) {
        var weight = app_constants_1.JOBWEIGHTOPTIONS.find(function (opt) { return opt.value === value; });
        return weight ? weight.label : '';
    };
    return WeightPipe;
}());
exports.WeightPipe = WeightPipe;
