"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var common_1 = require("@angular/common");
var Sentry = require("@sentry/browser");
var FiltersDialogComponent = /** @class */ (function () {
    function FiltersDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = false;
        this.errors = [];
        this.model = {};
    }
    FiltersDialogComponent.prototype.ngOnInit = function () { };
    FiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    FiltersDialogComponent.prototype.submit = function () {
        this.removeEmptySpacesTextFields();
        this.callback(this.model);
        this.dialogRef.close();
    };
    FiltersDialogComponent.prototype.removeEmptySpacesTextFields = function () {
        var _this = this;
        var keys = Object.keys(this.model);
        keys.forEach(function (key) {
            if (_this.model[key]) {
                try {
                    var property = _this.filters.find(function (filter) { return filter.field === key; });
                    if (property && property.type === 'text' && (!_this.model[key] || (_this.model[key] && !_this.model[key].trim()))) {
                        delete _this.model[key];
                    }
                }
                catch (exception) {
                    Sentry.captureException(exception);
                }
            }
        });
    };
    FiltersDialogComponent.prototype.convertDates = function (assign) {
        if (assign === void 0) { assign = true; }
        var startDate, endDate;
        if (this.model.startDate) {
            startDate = new Date(this.model.startDate);
            var name_1 = this.datePipe.transform(startDate, 'MM/dd/yyyy');
            startDate.setHours(0, 0, 0, 0);
            if (assign) {
                Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name_1 });
            }
        }
        if (this.model.endDate) {
            endDate = new Date(this.model.endDate);
            var name_2 = this.datePipe.transform(endDate, 'MM/dd/yyyy');
            endDate.setHours(23, 59, 59, 999);
            if (assign) {
                Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name_2 });
            }
        }
        if (!assign) {
            return {
                startDate: startDate && startDate.toISOString(),
                endDate: endDate && endDate.toISOString()
            };
        }
    };
    FiltersDialogComponent.prototype.onDateChanged = function (dates, type) {
        if (type === void 0) { type = ''; }
        if (type === 'endDate' && dates && dates.length) {
            dates[0].setHours(23, 59, 59);
        }
        this.model[type] = dates[0];
    };
    return FiltersDialogComponent;
}());
exports.FiltersDialogComponent = FiltersDialogComponent;
