'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var findIndex = function (arr, el) {
    return Array.prototype.indexOf.call(arr, el);
};
var ɵ0 = findIndex;
exports.ɵ0 = ɵ0;
var includes = function (arr, el) {
    return findIndex(arr, el) !== -1;
};
var ɵ1 = includes;
exports.ɵ1 = ɵ1;
var DropdownComponent = /** @class */ (function () {
    function DropdownComponent(el, _eref) {
        this.el = el;
        this._eref = _eref;
        this.open = false;
        this.menuHeight = '0px';
        this.menuWidth = '100%';
        this.keySearchTerm = '';
        this.searching = false;
        this.groups = {};
        this.buttonText = '';
        this.selectedItems = [];
        this.setTitle = true;
        this.changeTitle = true;
        this.loading = false;
        this.options = [];
        this.backgroundColor = 'rgba(255, 255, 255, 0.15)';
        this.onSelect = new core_1.EventEmitter();
        this.nextPage = new core_1.EventEmitter();
        this.onSearch = new core_1.EventEmitter();
        this.blur = true;
        this.toggle = false;
        this.config = this.config || {};
    }
    DropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.config = this.config || {};
        this.config = __assign({ nameProperty: 'name', subtitleProperty: 'subtitle', subtitle: false, disableSubtitleOnSelect: false, imageProperty: 'image', image: false, multiselect: false, deselectOption: null, selectedProp: 'selected', style: '', pin: '', groupBy: 'group', group: false, selectable: true, rightAlign: false, autocomplete: false, showLoading: false, loadingOptions: false, optionIcon: false, iconProperty: 'icon', numbered: false }, this.config);
        if (!Array.isArray(this.options)) {
            throw { error: 'dropdown options must be an array.', dropdown: this };
        }
        this.setSelectedOption();
        this.setButtonText();
        if (this.open) {
            this.menuHeight = 'auto';
        }
        if (this.refreshObservable$) {
            this.refreshSubscription = this.refreshObservable$.subscribe(function () {
                _this.deselectAll();
            });
        }
    };
    DropdownComponent.prototype.ngOnChanges = function (changes) {
        if (this.config && this.config.group) {
            this.groups = this.config.groupProperty ?
                lodash_1.groupBy(this.options, this.config.groupProperty) :
                lodash_1.groupBy(this.options, this.config.groupBy);
        }
        this.groupKeys = Object.keys(this.groups);
        this.setButtonText();
    };
    DropdownComponent.prototype.ngOnDestroy = function () {
        if (this.toggleClick && typeof this.toggleClick.unsubscribe === 'function') {
            this.toggleClick.unsubscribe();
        }
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    };
    DropdownComponent.prototype.setSelectedOption = function () {
        var _this = this;
        if (this.options && Array.isArray(this.options)) {
            if (!this.config.multiselect) {
                this.selectedOption = this.options.find(function (option) {
                    return option && (option[_this.config.selectedProp] || (option.id && option.id === _this.getIdValue(_this.selectedOption)));
                }) || this.selectedOption;
                this.autocompleteTerm = this.config.autocomplete ? this.selectedOption[this.config.nameProperty] : null;
            }
        }
        if (this.config && this.config.multiselect && this.selectedItems &&
            Array.isArray(this.selectedItems) && this.selectedItems.length > 0) {
            this.selectedItems.forEach(function (item) {
                _this.options.forEach(function (option) {
                    if (option.id === item.id) {
                        return option[_this.config.selectedProp] = true;
                    }
                });
            });
        }
    };
    DropdownComponent.prototype.onKeyPress = function (e) {
        var _this = this;
        if (this.el && this.el.nativeElement &&
            this.el.nativeElement.contains(document.activeElement) &&
            !this.config.autocomplete) {
            if (e.code === 'Escape' && this.open) {
                this.toggleMenu();
            }
            var options = this.optionsElRef && this.optionsElRef.nativeElement &&
                this.optionsElRef.nativeElement.children &&
                this.optionsElRef.nativeElement.children.length &&
                this.optionsElRef.nativeElement.children[0].children;
            if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
                if (!this.open) {
                    this.toggleMenu();
                }
                else if (options.length) {
                    var lastOption = options[options.length - 1];
                    var index = findIndex(options, document.activeElement);
                    if (e.code === 'ArrowDown') {
                        if (!includes(options, document.activeElement)) {
                            options[0].focus();
                        }
                        else if (document.activeElement !== lastOption) {
                            options[index + 1].focus();
                        }
                    }
                    else if (e.code === 'ArrowUp') {
                        if (!includes(options, document.activeElement)) {
                            lastOption.focus();
                        }
                        else if (index !== 0) {
                            options[index - 1].focus();
                        }
                    }
                }
                e.preventDefault();
            }
            else if (e.key.match(/^\w$/) && e.target.nodeName.toLowerCase() !== 'input') {
                if (this.keyTimeout) {
                    clearTimeout(this.keyTimeout);
                }
                this.keyTimeout = setTimeout(function () {
                    _this.keySearchTerm = '';
                }, 1000);
                this.keySearchTerm += e.key.toLowerCase();
                var option = lodash_1.find(options, function (o) {
                    return o.innerText.toLowerCase().match(RegExp('^' + _this.keySearchTerm));
                });
                if (option) {
                    option.focus();
                }
            }
        }
        setTimeout(function () {
            if (_this.open && !_this.el.nativeElement.contains(document.activeElement)) {
                _this.toggleMenu();
            }
        }, 0);
    };
    DropdownComponent.prototype.buttonKeyPress = function (e) {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            this.toggleMenu();
        }
    };
    DropdownComponent.prototype.optionKeyPress = function (e, option) {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            this.toggleOption(option);
        }
    };
    DropdownComponent.prototype.toggleMenu = function () {
        var _this = this;
        if (!this.disabled) {
            this.open = !this.open;
        }
        if (this.blur) {
            if (this.open) {
                this.toggleClick = rxjs_1.Observable.fromEvent(document, 'click').subscribe(function (e) {
                    if (!_this._eref.nativeElement.contains(e.target) && _this.open) {
                        _this.toggleMenu();
                    }
                });
            }
            else if (this.toggleClick && typeof this.toggleClick.unsubscribe === 'function') {
                this.toggleClick.unsubscribe();
            }
        }
        this.searching = false;
        this.setSize();
    };
    DropdownComponent.prototype.setSize = function () {
        if (this.optionsElRef && this.optionsElRef.nativeElement) {
            var menu = this.optionsElRef.nativeElement;
            this.menuHeight = (this.open ? menu.offsetHeight : 0) + 'px';
            this.menuWidth = this.optionsElRef.nativeElement.offsetWidth + 'px';
            var rect = menu.getBoundingClientRect();
            if (rect.height + menu.parentElement.getBoundingClientRect().top > window.innerHeight) {
                menu.parentElement.style.transform = 'translateY(-' +
                    (rect.height + menu.parentElement.getBoundingClientRect().top - window.innerHeight) + 'px)';
            }
            else if (!this.open) {
                menu.parentElement.style.transform = 'opacity(0)';
                menu.parentElement.style.transition = 'opacity 2s linear';
            }
            else if (this.open) {
                menu.parentElement.style.transform = 'opacity(1)';
                menu.parentElement.style.transition = 'opacity 2s linear';
            }
        }
    };
    DropdownComponent.prototype.toggleOption = function (option, toggle) {
        var _this = this;
        if (toggle === void 0) { toggle = true; }
        if (option && !option.disabled) {
            if (this.config.multiselect) {
                option[this.config.selectedProp] = !option[this.config.selectedProp];
                this.selectedItems = this.options.filter(function (_option) { return _option[_this.config.selectedProp]; });
                this.onSelect.emit(this.selectedItems);
            }
            else {
                this.deselectAll();
                this.selectedOption = option;
                this.onSelect.emit(this.selectedOption);
                if (toggle) {
                    this.toggleMenu();
                }
                this.autocompleteTerm = this.config.autocomplete ? this.selectedOption[this.config.nameProperty] : null;
            }
            this.setButtonText();
        }
    };
    DropdownComponent.prototype.isSelected = function (option) {
        if (!this.config.multiselect) {
            return (!option || !option.button) && (option && option[this.config.selectedProp] || option === this.selectedOption);
        }
        else {
            return option && option[this.config.selectedProp];
        }
    };
    DropdownComponent.prototype.deselectAll = function () {
        var _this = this;
        this.options.forEach(function (option) {
            if (typeof option === 'object') {
                option[_this.config.selectedProp] = undefined;
            }
        });
        this.selectedOption = undefined;
        if (this.config.multiselect) {
            this.selectedItems.length = 0;
            this.onSelect.emit(this.selectedItems);
        }
        this.setButtonText();
    };
    DropdownComponent.prototype.setButtonText = function () {
        this.setSelectedOption();
        if (this.config.multiselect) {
            this.buttonText = this.selectedItems && this.selectedItems.length > 0 ?
                this.optionDisplayMultiple(this.selectedItems) :
                this.title;
        }
        else {
            // single select
            this.buttonText = this.selectedOption ?
                this.optionDisplay(this.selectedOption) +
                    (this.config.subtitle && !this.config.disableSubtitleOnSelect ?
                        ' ' + this.optionSubtitleDisplay(this.selectedOption) : '') :
                this.title;
        }
        if (!this.setTitle) {
            this.buttonText = null;
        }
        if (!this.changeTitle) {
            this.buttonText = this.title;
        }
    };
    DropdownComponent.prototype.getIdValue = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, 'id');
        }
        else {
            return option;
        }
    };
    DropdownComponent.prototype.optionDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.nameProperty);
        }
        else {
            return option;
        }
    };
    DropdownComponent.prototype.optionDisplayMultiple = function (items) {
        var _this = this;
        return Array.isArray(items) ? items.map(function (item) { return lodash_1.get(item, _this.config.nameProperty); }).join(', ') : null;
    };
    DropdownComponent.prototype.optionSubtitleDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.subtitleProperty);
        }
        else {
            return option;
        }
    };
    DropdownComponent.prototype.optionImageDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.imageProperty);
        }
        else {
            return option;
        }
    };
    DropdownComponent.prototype.optionIconDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.iconProperty);
        }
        else {
            return option;
        }
    };
    DropdownComponent.prototype.changeSearch = function () {
        this.searching = true;
        this.onSearch.emit(this.searchTerm);
    };
    DropdownComponent.prototype.changeAutocomplete = function () {
        this.onSearch.emit(this.autocompleteTerm);
        this.deselectAll();
        this.toggleMenu();
    };
    DropdownComponent.prototype.onScroll = function () {
        this.nextPage.emit();
    };
    DropdownComponent.prototype.windowScroll = function (e) {
        if (this.open) {
            e.preventDefault();
        }
        return false;
    };
    return DropdownComponent;
}());
exports.DropdownComponent = DropdownComponent;
