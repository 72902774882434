import { JobEventShareStat } from './job-event-share.stat';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class JobEventShareStatSerializer {
  fromJson(json: any): JobEventShareStat {
    json = camelcaseKeysDeep(json);
    const jobEventShareStat = new JobEventShareStat();
    if (!json) { return jobEventShareStat; }

    jobEventShareStat.dispatchedEstimatedNumberOfLoads = json.dispatchedEstimatedNumberOfLoads;
    jobEventShareStat.dispatchedEstimatedNumberOfTons = json.dispatchedEstimatedNumberOfTons;
    jobEventShareStat.dispatchedDriversCount = json.dispatchedDriversCount || 0;
    jobEventShareStat.numTrucks = json.numTrucks || 0;
    jobEventShareStat.confirmedTrucks = json.confirmedTrucks || 0;
    jobEventShareStat.totalAmount = json.totalAmount;
    jobEventShareStat.totalLoads = json.totalLoads;
    jobEventShareStat.totalTons = json.totalTons;
    jobEventShareStat.totalHours = json.totalHours;
    jobEventShareStat.invoiceRate = json.invoiceRate;
    jobEventShareStat.invoiceType = json.invoiceType;
    jobEventShareStat.invoiceWeightUnit = json.invoiceWeightUnit;
    jobEventShareStat.weightInTransit = json.weightInTransit;
    jobEventShareStat.ticketedWeight = json.ticketedWeight;
    jobEventShareStat.avgTripTime = json.avgTripTime;
    jobEventShareStat.collaboratorName = json.collaboratorName;
    jobEventShareStat.collaboratorId = json.collaboratorId;

    return jobEventShareStat;
  }

  toJson(jobEventShareStat: JobEventShareStat): any {
    let json = {
      dispatchedEstimatedNumberOfLoads: jobEventShareStat.dispatchedEstimatedNumberOfLoads,
      dispatchedEstimatedNumberOfTons: jobEventShareStat.dispatchedEstimatedNumberOfTons,
      dispatchedDriversCount: jobEventShareStat.dispatchedDriversCount,
      numTrucks: jobEventShareStat.numTrucks,
      confirmedTrucks: jobEventShareStat.confirmedTrucks,
      totalAmount: jobEventShareStat.totalAmount,
      totalLoads: jobEventShareStat.totalLoads,
      totalTons: jobEventShareStat.totalTons,
      totalHours: jobEventShareStat.totalHours,
      invoiceRate: jobEventShareStat.invoiceRate,
      invoiceType: jobEventShareStat.invoiceType,
      invoiceWeightUnit: jobEventShareStat.invoiceWeightUnit,
      weightInTransit: jobEventShareStat.weightInTransit,
      ticketedWeight: jobEventShareStat.ticketedWeight,
      avgTripTime: jobEventShareStat.avgTripTime,
      collaboratorName: jobEventShareStat.collaboratorName,
      collaboratorId: jobEventShareStat.collaboratorId
    };

    return decamelizeKeysDeep(json);
  }
}
