import { Truck } from '../trucks/truck';
import { Scale } from '../scales/scale';
import { Location } from '../locations/location';
import { LocationSerializer } from '../locations/location.serializer';
import { TruckSerializer } from '../trucks/truck.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class TareWeight {
  id: string;
  truck: Truck;
  scale: Scale;
  location: Location;
  createdAt: string;
  lastModified: string;
  name: string;
  date: string;
  weight: number;
  weightUnit: string;
  disabled: false;
  weightType: 'stored';

  constructor(tareWeightInfo: any) {
    tareWeightInfo = camelcaseKeysDeep(tareWeightInfo);

    this.id = tareWeightInfo.id;
    if (tareWeightInfo.truck && typeof (tareWeightInfo.truck) === 'object') {
      this.truck = (new TruckSerializer().fromJson(tareWeightInfo.truck));
    } else {
      this.truck = (new TruckSerializer().fromJson({ id: tareWeightInfo.truck }));
    }
    if (tareWeightInfo.scale && typeof (tareWeightInfo.scale) === 'object') {
      this.scale = new Scale(tareWeightInfo.scale);
    } else {
      this.scale = new Scale({ id: tareWeightInfo.scale });
    }
    if (tareWeightInfo.location && typeof (tareWeightInfo.location) === 'object') {
      this.location = (new LocationSerializer).fromJson(tareWeightInfo.location);
    } else {
      this.location = (new LocationSerializer).fromJson({ id: tareWeightInfo.location });
    }
    this.createdAt = tareWeightInfo.createdAt;
    this.lastModified = tareWeightInfo.lastModified;
    this.name = tareWeightInfo.name;
    this.date = tareWeightInfo.date;
    this.weight = tareWeightInfo.weight;
    this.weightUnit = tareWeightInfo.weightUnit;
    this.weightType = 'stored';
  }
}
