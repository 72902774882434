<my-loader *ngIf="loading"></my-loader>

<div class="assignments" *ngIf="device.desktop && !loading">
  <div class="left-column">
    <div class="header">
      <div class="driver-header">
        <div class="driver-header-label" translate>DRIVER AND TRUCK</div>
        <ng-content select="[display-controls]"></ng-content>
      </div>
    </div>
    <div *ngFor="let assignment of assignments" class="assignment">
      <div class="dispatch-row">
        <div class="expand"
             [ngClass]="{'expand-disabled': !assignment.trips || !assignment.trips.length}"
             (click)="assignment.trips.length && expandDriver(assignment.driver)">
          <i *ngIf="!expandedDriver(assignment.driver)" class="icon-down-arrow"></i>
          <i *ngIf="expandedDriver(assignment.driver)" class="icon-up-arrow"></i>
        </div>
        <div class="driver">
          <div class="driver-photo">
            <span *ngIf="!assignment.driver.image">{{assignment.driver.name[0]}}</span>
            <img *ngIf="assignment.driver.image" [src]="assignment.driver.jobDetailImage"/>
          </div>
          <div class="truck-photo" [ngClass]="{empty: !assignment.truck}">
            <div class="image" *ngIf="assignment.truck">
              <img *ngIf="assignment.truck.image" [src]="assignment.truck.jobDetailImage"/>
              <i *ngIf="!assignment.truck.image" class="icon-fleet"></i>
            </div>
          </div>
          <div class="info">
            <driver-name [driver]="assignment.driver" [assignment]="assignment"></driver-name>
            <div *ngIf="assignment.truck" class="truck" title="{{ assignment.truck.name }} {{ assignment.truck.truckTypeName }}">{{ assignment.truck.name }} {{ assignment.truck.truckTypeName }}</div>
            <div *ngIf="!assignment.truck" class="label-danger" translate>truck unassigned</div>
            <div *ngIf="assignment.driver && jobEvent">
              <a class="replay-link" target="_blank" href="/replay?date={{ jobEvent.shift1StartTimestamp | moment: 'YYYYMMDD' }}&driver={{ assignment.driver.id }}">View Replay</a>
            </div>
            <!-- <div *ngIf="assignment.driver" class="organization">{{ assignment.driver.organizationName }}</div>
            <div *ngIf="assignment.driver.previousJob" class="previous-job" title="{{ 'Previous Job:' | translate }} {{ assignment.driver.previousJob.name }}">{{ 'Previous Job:' | translate }} {{ assignment.driver.previousJob.name }}</div> -->
          </div>
          <div class="trip-time">
            <div *ngIf="assignment.driver.isOnShift()" class="job-time">
              <i class="icomoon icon-time"></i>
              <ng-container *ngIf="assignment.driver && assignment.driver.punchCard">
                {{ assignment.driver.punchCard.startTimeTimestamp | formattedDuration:assignment.driver.punchCard.endTimeTimestamp }}
              </ng-container>
            </div>
            <div *ngIf="!assignment.driver.isOnShift()" class="job-time waiting" translate>
              Waiting
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-column">
    <div class="current-time-overlay" [ngStyle]="timeOverlayStyles(hours)"></div>
    <div class="header">
      <div class="hours">
        <div *ngFor="let hour of hours" class="hour"><span>{{ hour }}</span></div>
      </div>
    </div>

    <div *ngFor="let assignment of assignments" class="assignment">
      <div class="dispatch-row">
        <div class="hours">
          <div *ngFor="let hour of hours; let i = index" class="hour">
            <div class="progress" [ngStyle]="styles(assignment, hour, i)" [ngClass]="{
              start: (assignment.driver && assignment.driver.punchCard | startHour:hour),
              active: (assignment.driver && assignment.driver.punchCard | activeHour:hour),
              ended: (assignment.driver && assignment.driver.punchCard | lastHour:hour),
              complete: (assignment.driver && assignment.driver.punchCard | completeHour:hour)
            }">
              <div *ngIf="(assignment.driver && assignment.driver.punchCard | startHour:hour)" class="start-time">
                {{ assignment.driver.punchCard.startTimeTimestamp | date:'h:mm a' }}
              </div>
              <div *ngIf="(assignment.driver && assignment.driver.punchCard | lastHour:hour)" class="end-time">
                {{ assignment.driver.punchCard.endTimeTimestamp | date:'h:mm a' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <ruckit-trip-times [driver]="assignment.driver" [trips]="assignment.trips" *ngIf="expandedDriver(assignment.driver)"></ruckit-trip-times> -->
    </div>
  </div>
</div>

<div class="mobile-assignment-list" *ngIf="device.mobile || device.tablet">
  <ng-container *ngFor="let section of activeStatuses">
    <ng-container *ngIf="section.active === true">
      <div class="section-header">
        <h3 translate>{{ section.name }}</h3>
        <h4 translate>Status Time</h4>
      </div>
      <div *ngFor="let assignment of assignments" class="assignment-details" (click)="selectActiveAssignment(assignment)">
        <div class="driver">
          <div class="truck-photo" [ngClass]="{empty: !assignment.truck}">
            <div class="image" *ngIf="assignment.truck">
              <img *ngIf="assignment.truck.image" [src]="assignment.truck.jobDetailImage"/>
              <i *ngIf="!assignment.truck.image" class="icon-fleet"></i>
            </div>
          </div>
          <div class="driver-photo">
            <span *ngIf="!assignment.driver.image">{{ assignment.driver.name[0] }}</span>
            <img *ngIf="assignment.driver.image" [src]="assignment.driver.jobDetailImage"/>
          </div>
        </div>
        <div class="details">
          <h1 translate>{{ assignment.truck.name }} - {{ assignment.truck.truckTypeName }}</h1>
          <h3 translate>{{ assignment.driver.name }}</h3>
        </div>
        <div class="status">
          <h3 translate>{{ assignment.tripStats.lastValues.unloading }}</h3>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
