'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var findIndex = function (arr, el) {
    return Array.prototype.indexOf.call(arr, el);
};
var ɵ0 = findIndex;
exports.ɵ0 = ɵ0;
var includes = function (arr, el) {
    return findIndex(arr, el) !== -1;
};
var ɵ1 = includes;
exports.ɵ1 = ɵ1;
var TruckDropdownComponent = /** @class */ (function () {
    function TruckDropdownComponent(el, _eref) {
        this.el = el;
        this._eref = _eref;
        this.open = false;
        this.selectedItems = [];
        this.menuHeight = '0px';
        this.menuWidth = '100%';
        this.keySearchTerm = '';
        this.groups = {};
        this.setTitle = true;
        this.changeTitle = true;
        this.options = [];
        this.onSelect = new core_1.EventEmitter();
        this.nextPage = new core_1.EventEmitter();
        this.onSearch = new core_1.EventEmitter();
        this.config = this.config || {};
    }
    TruckDropdownComponent.prototype.documentClick = function (event) {
        if (!this._eref.nativeElement.contains(event.target) && this.open) {
            this.toggleMenu();
        }
    };
    TruckDropdownComponent.prototype.ngOnInit = function () {
        this.config = this.config || {};
        this.config = __assign({ nameProperty: 'displayName', licenseProperty: 'licensePlate', truckIdProperty: 'name', imageProperty: 'image', image: false, multiselect: false, selectedProp: 'selected', style: '', pin: '', groupBy: 'group', group: false, selectable: true }, this.config);
        if (!Array.isArray(this.options)) {
            throw { error: 'drowpdown options must be an array.', dropdown: this };
        }
        this.setSelectedOption();
    };
    TruckDropdownComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.config && this.config.group) {
            this.groups = lodash_1.groupBy(this.options, this.config.groupBy);
        }
        this.groupKeys = Object.keys(this.groups);
        if (this.open) {
            setTimeout(function () {
                _this.setSize();
            }, 0);
        }
    };
    TruckDropdownComponent.prototype.setSelectedOption = function () {
        var _this = this;
        this.selectedOption =
            (this.options &&
                this.options.find(function (option) {
                    return option[_this.config.selectedProp];
                })) ||
                this.selectedOption;
    };
    TruckDropdownComponent.prototype.onKeyPress = function (e) {
        var _this = this;
        if (this.el.nativeElement.contains(document.activeElement)) {
            if (e.code === 'Escape' && this.open) {
                this.toggleMenu();
            }
            var options = this.optionsElRef.nativeElement.children[0].children;
            if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
                if (!this.open) {
                    this.toggleMenu();
                }
                else if (options.length) {
                    var lastOption = options[options.length - 1];
                    var index = findIndex(options, document.activeElement);
                    if (e.code === 'ArrowDown') {
                        if (!includes(options, document.activeElement)) {
                            options[0].focus();
                        }
                        else if (document.activeElement !== lastOption) {
                            options[index + 1].focus();
                        }
                    }
                    else if (e.code === 'ArrowUp') {
                        if (!includes(options, document.activeElement)) {
                            lastOption.focus();
                        }
                        else if (index !== 0) {
                            options[index - 1].focus();
                        }
                    }
                }
                e.preventDefault();
            }
            else if (e.key.match(/^\w$/) &&
                e.target.nodeName.toLowerCase() !== 'input') {
                if (this.keyTimeout) {
                    clearTimeout(this.keyTimeout);
                }
                this.keyTimeout = setTimeout(function () {
                    _this.keySearchTerm = '';
                }, 1000);
                this.keySearchTerm += e.key.toLowerCase();
                var option = lodash_1.find(options, function (o) {
                    return o.innerText
                        .toLowerCase()
                        .match(RegExp('^' + _this.keySearchTerm));
                });
                if (option) {
                    option.focus();
                }
            }
        }
        setTimeout(function () {
            if (_this.open &&
                !_this.el.nativeElement.contains(document.activeElement)) {
                _this.toggleMenu();
            }
        }, 0);
    };
    TruckDropdownComponent.prototype.buttonKeyPress = function (e) {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            this.toggleMenu();
        }
    };
    TruckDropdownComponent.prototype.optionKeyPress = function (e, option) {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            this.toggleOption(option);
        }
    };
    TruckDropdownComponent.prototype.toggleMenu = function () {
        if (!this.disabled) {
            this.open = !this.open;
        }
        this.setSize();
    };
    TruckDropdownComponent.prototype.openMenu = function () {
        if (!this.disabled) {
            this.open = true;
        }
        this.setSize();
    };
    TruckDropdownComponent.prototype.setSize = function () {
        var menu = this.optionsElRef.nativeElement;
        this.menuHeight = (this.open ? menu.offsetHeight : 0) + 'px';
        this.menuWidth = this.optionsElRef.nativeElement.offsetWidth + 'px';
        var rect = menu.getBoundingClientRect();
        if (rect.height + menu.parentElement.getBoundingClientRect().top >
            window.innerHeight) {
            menu.parentElement.style.transform =
                'translateY(-' +
                    (rect.height +
                        menu.parentElement.getBoundingClientRect().top -
                        window.innerHeight) +
                    'px)';
        }
    };
    TruckDropdownComponent.prototype.toggleOption = function (option) {
        var _this = this;
        if (!this.config.selectable) {
            return;
        }
        if (this.config.multiselect) {
            option[this.config.selectedProp] = !option[this.config.selectedProp];
            this.onSelect.emit(this.options.filter(function (_option) {
                return _option[_this.config.selectedProp];
            }));
        }
        else {
            this.deselectAll();
            this.selectedOption = option;
            this.toggleMenu();
            this.onSelect.emit(this.selectedOption);
        }
    };
    TruckDropdownComponent.prototype.isSelected = function (option) {
        return (!option.button &&
            (option[this.config.selectedProp] || option === this.selectedOption));
    };
    TruckDropdownComponent.prototype.deselectAll = function () {
        var _this = this;
        this.options.forEach(function (option) {
            if (typeof option === 'object') {
                option[_this.config.selectedProp] = undefined;
            }
        });
        this.selectedOption = undefined;
    };
    TruckDropdownComponent.prototype.optionDisplayName = function (option) {
        if (typeof option === 'object') {
            var name_1 = lodash_1.get(option, this.config.nameProperty);
            if (!name_1) {
                name_1 = '-';
            }
            return name_1;
        }
        else {
            return option;
        }
    };
    TruckDropdownComponent.prototype.optionDisplayLicense = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.licenseProperty);
        }
        else {
            return option;
        }
    };
    TruckDropdownComponent.prototype.optionDisplayTruckId = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.truckIdProperty);
        }
        else {
            return option;
        }
    };
    TruckDropdownComponent.prototype.optionImageDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.imageProperty);
        }
        else {
            return option;
        }
    };
    TruckDropdownComponent.prototype.onScroll = function (e) {
        if (!this.config.loadingOptions &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            this.nextPage.emit();
        }
        var div = e.currentTarget;
        if (div.clientHeight + div.scrollTop + e.deltaY >= div.scrollHeight) {
            e.preventDefault();
            div.scrollTop = div.scrollHeight;
        }
        else if (div.scrollTop + e.deltaY <= 0) {
            e.preventDefault();
            div.scrollTop = 0;
        }
    };
    TruckDropdownComponent.prototype.windowScroll = function (e) {
        if (this.open) {
            e.preventDefault();
        }
        return false;
    };
    return TruckDropdownComponent;
}());
exports.TruckDropdownComponent = TruckDropdownComponent;
