"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var ngx_device_detector_1 = require("ngx-device-detector");
// services
var trackable_service_1 = require("./trackable.service");
var trackable_type_service_1 = require("../trackable-types/trackable-type.service");
var truck_service_1 = require("../trucks/truck.service");
var api_service_1 = require("../shared/api.service");
var shared_1 = require("../shared");
var trackable_1 = require("./trackable");
var NewTrackableDialogComponent = /** @class */ (function () {
    function NewTrackableDialogComponent(dialogRef, truckService, trackableService, elementRef, deviceDetectorService, translate, authentiationService) {
        this.dialogRef = dialogRef;
        this.truckService = truckService;
        this.trackableService = trackableService;
        this.elementRef = elementRef;
        this.deviceDetectorService = deviceDetectorService;
        this.translate = translate;
        this.authentiationService = authentiationService;
        this.loading = false;
        this.model = new trackable_1.Trackable();
        this.errors = [];
        this.trackableTypesDropdownConfig = {
            selectText: this.translate.instant('Select Trackable Type'),
            loadingText: this.translate.instant('Loading Trackable Types...'),
            noResultsText: this.translate.instant('No Trackable Types'),
            nameProperty: 'name',
            service: trackable_type_service_1.TrackableTypeService,
            query: { ordering: 'name' },
        };
        // trucks dropdown config
        this.trucksConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'id',
            selectText: this.translate.instant('Select Truck'),
            loadingText: this.translate.instant('Loading Trucks...'),
            noResultsText: this.translate.instant('No Trucks'),
            service: truck_service_1.TruckService,
            serviceFunction: 'list',
            query: {
                ordering: 'name',
            },
            prefilledOptions: [],
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop(),
        };
    }
    NewTrackableDialogComponent.prototype.ngOnInit = function () { };
    NewTrackableDialogComponent.prototype.selectTrackableType = function (trackableType) {
        this.selectedTrackableType = trackableType;
        this.model.trackableType = trackableType;
    };
    NewTrackableDialogComponent.prototype.selectTruck = function (truck) {
        this.truck = truck;
        this.model.truck = truck;
    };
    NewTrackableDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var model = lodash_1.cloneDeep(this.model);
        this.trackableService.save(model).subscribe(function (trackable) {
            if (typeof _this.callback === 'function') {
                _this.callback();
            }
            _this.dialogRef.close();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return NewTrackableDialogComponent;
}());
exports.NewTrackableDialogComponent = NewTrackableDialogComponent;
