"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var preference_service_1 = require("../preferences/preference.service");
var shared_1 = require("../shared");
var EffectiveRateDialogComponent = /** @class */ (function () {
    function EffectiveRateDialogComponent(dialogRef, preferenceService) {
        this.dialogRef = dialogRef;
        this.preferenceService = preferenceService;
        this.loading = false;
        this.errors = [];
        this.haulRateType = '';
        this.typeConfig = {
            nameProperty: 'name',
            group: false
        };
        this.rateTypeOptions = [
            { value: 'hour', label: 'Hour', name: 'Hour' },
            { value: 'ton', label: 'Ton', name: 'Ton' },
        ];
        this.effectiveTonsOptions = [
            { value: 0, label: 'Tickets Delivered', name: 'Tickets Delivered' },
            { value: 1, label: 'Tickets Ticketed', name: 'Tickets Ticketed' }
        ];
        this.effectiveTimeOptions = [
            { value: 0, label: 'Total Active Trip Time', name: 'Total Active Trip Time' },
            { value: 1, label: 'Total Completed Trip Time', name: 'Total Completed Trip Time' }
        ];
        this.model = {
            rate: 0,
            rateType: this.rateTypeOptions[0],
            effectiveTons: 0,
            effectiveTime: 0
        };
    }
    EffectiveRateDialogComponent.prototype.rateUnitChange = function (rateType, form) {
        this.model.rateType = rateType.value;
        form.controls['rate_type'].markAsDirty();
    };
    EffectiveRateDialogComponent.prototype.effectiveTonsChange = function (effectiveTons, form) {
        this.model.effectiveTons = effectiveTons.value;
        form.controls['effective_tons'].markAsDirty();
    };
    EffectiveRateDialogComponent.prototype.effectiveTimeChange = function (effectiveTime, form) {
        this.model.effectiveTime = effectiveTime.value;
        form.controls['effective_time'].markAsDirty();
    };
    EffectiveRateDialogComponent.prototype.submit = function (form) {
        var _this = this;
        if (form.dirty) {
            var updatedPreference = {
                name: 'EffectiveRateUserPreferences',
                type: 'user',
                profile: this.user.id,
                blob: this.model
            };
            this.loading = true;
            this.preferenceService.save(updatedPreference).subscribe(function (preference) {
                _this.loading = true;
                _this.dialogRef.close();
                _this.callback(preference);
            }, function (err) {
                _this.errors = shared_1.parseErrors(err);
                _this.loading = false;
            });
        }
        else {
            this.dialogRef.close();
        }
    };
    return EffectiveRateDialogComponent;
}());
exports.EffectiveRateDialogComponent = EffectiveRateDialogComponent;
