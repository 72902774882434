import { OnInit, Component, EventEmitter, Output, Input } from '@angular/core';

export type InputConfig = {
  type?: 'text' | 'number' | 'date' | 'time',
  small?: boolean
};

@Component({
  selector: 'ruckit-input',
  templateUrl: './ruckit-input.component.html',
  styleUrls: ['./ruckit-input.component.scss']
})
export class RuckitInputComponent implements OnInit {
  @Input() config: InputConfig;
  defaultConfig: InputConfig = {
    type: 'text',
    small: false
  };
  @Input() value;
  updatedValue;
  @Output() editsMade: EventEmitter<any> = new EventEmitter();
  editMode = false;

  constructor() {}

  ngOnInit() {
    this.updatedValue = this.value;
    this.config = {
      ...this.defaultConfig,
      ...this.config
    };
  }

  enableEditing(enable: boolean) {
    this.editMode = enable;
    if (!this.editMode) { this.updatedValue = this.value; }
  }

  submitEdit(value: any) {
    this.editsMade.emit(value);
    this.editMode = false;
  }
}
