import { PaySheet } from './pay-sheet';
import { PayRecordSerializer } from './pay-record.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PaySheetSerializer {
  fromJson(json: any): PaySheet {
    json = camelcaseKeysDeep(json);
    const payRecord = new PaySheet();
    if (!json) { return payRecord; }

    payRecord.id = json.id;
    payRecord.createdAt = json.createdAt;
    payRecord.createdBy = json.createdBy;
    payRecord.lastModified = json.lastModified;
    payRecord.lastModifiedBy = json.lastModifiedBy;
    payRecord.payerOrganization = json.payerOrganization;
    payRecord.payeeOrganizations = json.payeeOrganizations;
    payRecord.reportDate = json.reportDate;
    payRecord.startTime = json.startTime;
    payRecord.endTime = json.endTime;
    payRecord.isApproved = json.isApproved;
    payRecord.processStatus = json.processStatus;

    return payRecord;
  }

  toJson(payRecord: PaySheet): any {
    const json: { [key: string]: any } = {
      id: payRecord.id,
      createdAt: payRecord.createdAt,
      createdBy: payRecord.createdBy,
      lastModified: payRecord.lastModified,
      lastModifiedBy: payRecord.lastModifiedBy,
      payerOrganization: payRecord.payerOrganization,
      payeeOrganizations: payRecord.payeeOrganizations,
      reportDate: payRecord.reportDate,
      startTime: payRecord.startTime,
      endTime: payRecord.endTime,
      isApproved: payRecord.isApproved
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }
}
