"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var condensed_trip_1 = require("../trips/condensed-trip");
var trip_service_1 = require("../trips/trip.service");
var map_service_1 = require("../map/map.service");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../../environments/environment");
var location_serializer_1 = require("../locations/location.serializer");
var OrderService = /** @class */ (function () {
    function OrderService(tripService, mapService) {
        var _this = this;
        this.tripService = tripService;
        this.mapService = mapService;
        this.mapsApiKey = environment_1.environment.googleMapsKey;
        this.initOrderValue = [[new condensed_trip_1.CondensedTrip({})], { locationUpdates: [], locations: [{}, {}] }];
        this.orderData = new rxjs_1.BehaviorSubject(this.initOrderValue);
        this.jobEventId = new rxjs_1.BehaviorSubject('');
        this.kilometerConversionFactor = 1.609344;
        this.jobEventId.subscribe(function (id) {
            if (_this.orderDataReq && typeof _this.orderDataReq.unsubscribe === 'function') {
                _this.orderDataReq.unsubscribe();
            }
            _this.orderDataReq = rxjs_1.timer(0, 30000).pipe(operators_1.switchMap(function () { return (id ? rxjs_1.forkJoin([
                _this.tripService.getAllCondensedTrips(20, { jobevent: _this.jobEventId.getValue() }),
                _this.mapService.getLocationUpdates({ driver__assignments__jobevent: _this.jobEventId.getValue() })
            ]) : rxjs_1.of(_this.orderData.getValue())); })).subscribe(function (data) {
                data[1].locations = data[1].locations.map(function (location) { return (new location_serializer_1.LocationSerializer().fromJson(location)); });
                _this.orderData.next(data);
            });
        });
    }
    OrderService.prototype.setJobEventId = function (id) { this.jobEventId.next(id); };
    OrderService.prototype.getDistanceInMiles = function (origin, destination) {
        var R = 6959; // earth's radius (mi)
        var latDistance = (destination.lat - origin.lat) * Math.PI / 180;
        var lngDistance = (destination.lng - destination.lng) * Math.PI / 180;
        var lat1 = destination.lat * Math.PI / 180;
        var lat2 = origin.lat * Math.PI / 180;
        var a = Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
            Math.sin(lngDistance / 2) * Math.sin(lngDistance / 2) *
                Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var distance = Math.round(R * c * 100) / 100;
        return distance;
    };
    OrderService.prototype.convertMilesToKilometers = function (distanceInMiles) {
        return Math.round(distanceInMiles * this.kilometerConversionFactor * 100) / 100;
    };
    OrderService.prototype.getLoadStatus = function (tripStatus) {
        return tripStatus === 'loading_complete' ||
            tripStatus === 'enroute_unloading' ||
            tripStatus === 'waiting_to_unload' ||
            tripStatus === 'active_unloading' ||
            tripStatus === 'unloading' ? 'Loaded' :
            tripStatus === 'unloading' ||
                tripStatus === 'unloading_complete' ? 'Unloaded' :
                'N/A';
    };
    return OrderService;
}());
exports.OrderService = OrderService;
