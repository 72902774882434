import {
  Component, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../index';
import { environment } from '../../../environments/environment';
import { User } from '../../users/user';
declare let CXBus, SDXSurvey;
@Component({
  selector: 'my-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  sections = [];
  applicationVersion = environment.applicationVersion;
  user: User;

  @Input() expanded = false;
  @Input() mobileOpen: boolean;
  @Output() mobileOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authenticationService: AuthenticationService,
    private translationService: TranslateService
  ) {
    this.sections = [];

    let fleetPages = [
      { name: this.translationService.instant('Trucks'), icon: null, type: 'link', url: '/trucks' },
      { name: this.translationService.instant('Drivers'), icon: null, type: 'link', url: '/drivers' },
      { name: this.translationService.instant('Fleet Health'), icon: null, type: 'link', url: '/fleet-health' }
    ];

    if (this.hasLeasedOrgs()) {
      fleetPages.push(
        { name: this.translationService.instant('Leased Fleet'), icon: null, type: 'link', url: '/leased-fleet' }
      );
    }

    if (this.hasTrackables()) {
      fleetPages.push(
        { name: this.translationService.instant('Trackables'), icon: null, type: 'link', url: '/trackables' }
      );
    }

    let projectPages = [];

    if (this.hasLafargeRegion()) {
      projectPages = projectPages.concat([
        { name: this.translationService.instant('Add Order'), icon: null, type: 'external-link', url: environment.ordersUrl }
      ]);
    }
    projectPages = projectPages.concat([
      { name: this.translationService.instant('Daily Board'), icon: null, type: 'link', url: '/jobs/daily' },
      { name: this.translationService.instant('All Jobs'), icon: null, type: 'link', url: '/jobs/all' }
    ]);
    if (this.hasAllDriversEnabled()) {
      projectPages.push({ name: this.translationService.instant('All Drivers'), icon: null, type: 'link', url: '/jobs/drivers' });
    }
    projectPages = projectPages.concat([
      ...this.isCarrierMode() ? [
        { name: this.translationService.instant('Active Locations'), icon: null, type: 'link', url: '/jobs/locations/active' },
        { name: this.translationService.instant('Hauler Shares'), icon: null, type: 'link', url: '/jobs/hauler-shares' },
        { name: this.translationService.instant('Received'), icon: null, type: 'link', url: '/jobs/pending' },
      ] : [
        { name: this.translationService.instant('Hauler Shares'), icon: null, type: 'link', url: '/jobs/hauler-shares' },
      ],
      { name: this.translationService.instant('Dispatch Scheduler'), icon: null, type: 'link', url: '/dispatch-schedule' }
    ]);
    let reportPages = [
      { name: this.translationService.instant('Trips'), icon: null, type: 'link', url: '/trips' },
      { name: this.translationService.instant('GeoTrips'), icon: null, type: 'link', url: '/geotrips' },
      { name: this.translationService.instant('Punch Cards'), icon: null, type: 'link', url: '/punchcards' },
      { name: this.translationService.instant('Shifts'), icon: null, type: 'link', url: '/shifts' },
      { name: this.translationService.instant('Pay Reports'), icon: null, type: 'link', url: '/pay-reports/all' },
      { name: this.translationService.instant('Driver Job Reports'), icon: null, type: 'link', url: '/driver-reports' },
      { name: this.translationService.instant('End of Day Reports'), icon: null, type: 'link', url: '/end-of-day-reports' }
    ];

    if (this.hasRuckit3()) {
      reportPages.push(
        { name: this.translationService.instant('Advanced Reports'), icon: null, type: 'external-link', url: 'https://integrated-trucking.herokuapp.com/' }
      );
    }

    let billingPages = [
      { name: this.translationService.instant('Invoices'), icon: null, type: 'link', url: '/invoices/filter/draft' },
      { name: this.translationService.instant('Expenses'), icon: null, type: 'link', url: '/expenses/filter/draft' },
      { name: this.translationService.instant('Paystubs'), icon: null, type: 'link', url: '/paystubs/filter/draft' },
      { name: this.translationService.instant('Surcharges'), icon: null, type: 'link', url: '/surcharge-templates' },
    ];

    if (this.hasWorkOrder()) {
      projectPages.push({ name: this.translationService.instant('Work Orders'), icon: null, type: 'link', url: '/work-orders' });
    }

    this.sections = this.sections.concat([
      { name: this.translationService.instant('MENU'), menu: 'top', type: 'heading' },
      { name: this.translationService.instant('Dashboard'), icon: 'icon-dashboard', menu: 'top', type: 'link', url: '/dashboard' },
      { name: this.translationService.instant('Jobs'), icon: 'icon-projects', menu: 'top', type: 'toggle',
        url: '/jobs/daily', pages: projectPages },
      { name: this.translationService.instant('Reports'), icon: 'icon-reports', menu: 'top', type: 'toggle',
        url: '/trips', pages: reportPages },
      { name: this.translationService.instant('Fleet'), icon: 'icon-fleet', menu: 'top', type: 'toggle',
        url: '/trucks', pages: fleetPages }
    ]);

    if (this.hasBillingModule()) {
      this.sections = this.sections.concat([
        { name: this.translationService.instant('Billing'), icon: 'icon-invoices', menu: 'top', type: 'toggle',
        url: '/invoices/filter/draft', pages: billingPages }
      ]);
    }

    const controlReportPages = [];

    if (this.hasControlReports()) {
      this.sections = this.sections.concat([
        { name: this.translationService.instant('Control Reports'), icon: 'icon-reports', menu: 'top', type: 'toggle',
        url: '/control-reports', pages: controlReportPages }
      ]);

      controlReportPages.push(
        { name: this.translationService.instant('Notifications'), icon: null, type: 'link', url: '/control-reports/notifications' }
      );
    }

    this.sections = this.sections.concat([
      {
        name: this.translationService.instant('Accounts'), icon: 'icon-connections', menu: 'top', type: 'toggle', url: '/accounts', pages: [
          { name: this.translationService.instant('Customers'), icon: null, menu: 'top', type: 'link', url: '/accounts' },
          { name: this.translationService.instant('Connections'), icon: null, menu: 'top', type: 'link', url: '/connections' },
        ]
      },
      { name: this.translationService.instant('Locations'), icon: 'icon-locations', menu: 'top', type: 'link', url: '/locations' },
      { name: this.translationService.instant('Replay'), icon: 'icon-replay', menu: 'top', type: 'link', url: '/replay' },
      {
        name: 'Ticket Manager', icon: 'icon-ticket', menu: 'top',
        type: 'external-link', url: environment.ticketManagerUrl
      }
    ]);

    this.sections = this.sections.concat([
      { name: this.translationService.instant('ORGANIZATION'), menu: 'top', type: 'heading' },
      { name: this.translationService.instant('Team'), icon: 'icon-team', menu: 'top', type: 'link', url: '/team' },
      { name: this.translationService.instant('Settings'), icon: 'icon-settings', menu: 'top', type: 'link',
        url: '/settings/organization'
      },
      {
        name: this.translationService.instant('Live Support'), icon: 'icon-live-support', menu: 'bottom', type: 'click',
      },
      // {
      //   name: this.translationService.instant('Videos'), icon: 'icon-play', menu: 'bottom', type: 'external-link',
      //   url: 'https://fast.wistia.net/embed/channel/7eihjs84gv'
      // },
      { name: this.translationService.instant('Resources'), icon: 'icon-links', menu: 'bottom', type: 'link', url: '/resources' },
      {
        name: this.translationService.instant('Help'), icon: 'icon-help', menu: 'bottom', type: 'external-link',
        url: 'https://mastery.commandalkon.com/ruckit'
      },
    ]);
  }

  ngOnInit() {
    this.initializeGenesys();
    this.user = this.authenticationService.user();
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  hasAllDriversEnabled(): boolean {
    return this.authenticationService.hasAllDriversEnabled();
  }

  hasLeasedOrgs(): boolean {
    return this.authenticationService.hasLeasedOrgs();
  }

  hasTrackables(): boolean {
    return this.authenticationService.hasTrackables();
  }

  hasRuckit3(): boolean {
    return this.authenticationService.hasRuckit3() || this.authenticationService.enabledFeatures().includes('hasRuckit3');
  }

  hasLafargeRegion(): boolean {
    return this.authenticationService.hasLafargeRegion();
  }

  hasWorkOrder(): boolean {
    return this.authenticationService.hasWorkOrder();
  }

  hasBillingModule(): boolean {
    return this.authenticationService.hasBillingModule();
  }

  hasControlReports(): boolean {
    return this.authenticationService.enabledFeatures().includes('hasControlReports');
  }

  isCarrierMode(): boolean {
    return this.authenticationService.enabledFeatures().includes('ruckitBilling');
  }

  toggleMobileOpen() {
    this.mobileOpen = !this.mobileOpen;
    this.mobileOpenChange.emit(this.mobileOpen);
  }

  toggleSidebarExpanded() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  onMenuClick(section: any) {
    if (section.icon === 'icon-live-support') {
      this.openChat(this.user);
    }
  }

  initializeGenesys(): void {
    let customPlugin = window['customPlugin'];
    if (customPlugin) {
      return;
    }
    CXBus.configure({
      debug: false,
      pluginsPath: 'https://apps.mypurecloud.com/widgets/9.0/plugins/',
    });
    CXBus.loadPlugin('widgets-core');
    customPlugin = CXBus.registerPlugin('Custom');

    let conversation_id = localStorage.getItem('sdx_genesys_conversation_id');
    customPlugin.subscribe('WebChatService.started', function (e) {
      conversation_id = e.data.data.conversationId;
      localStorage.setItem('sdx_genesys_conversation_id', conversation_id);
    });
    customPlugin.subscribe('WebChatService.ended', function (e) {
      SDXSurvey.showSurvey({
        survey_url:
          'https://us3.surveydynamix.com/webhook/web_survey/c994caa0-c312-11eb-9df1-413f5cfcad9e?_start=true',
        config: {
          location: 'bottom-left',
        },
        survey_attributes: {
          respondent_language: 'en-US',
          external_ref: conversation_id,
        },
      });
      // Let's remove the conversation ID from local storage, in case the customer starts a new webchat
      localStorage.removeItem('sdx_genesys_conversation_id');
      // Let's close the survey 3 seconds after it is completed
      SDXSurvey.surveyCompleted(function () {
        setTimeout(function () {
          SDXSurvey.closeSurvey();
        }, 3000);
      });
    });
    window['customPlugin'] = customPlugin;
  }

  openChat(user: User): void {
    let customPlugin = window['customPlugin'];
    if (customPlugin === undefined) {
      this.initializeGenesys();
    }

    const str = user.name;
    const firstName = str.substring(0, str.indexOf(' '));
    const lastName = str.substring(str.indexOf(' ') + 1);
    customPlugin
      .command('WebChat.open', {
        formJSON: {
          wrapper: '<table></table>',
          inputs: [
            {
              name: 'company',
              label: 'Company',
              value: user.organization.name
            },
            {
              id: 'cx_webchat_form_firstname',
              name: 'firstname',
              maxlength: '25',
              placeholder: 'Required',
              label: 'First Name',
              value: firstName
            },
            {
              id: 'cx_webchat_form_lastname',
              name: 'lastname',
              maxlength: '25',
              placeholder: 'Required',
              label: 'Last Name',
              value: lastName
            },
            {
              id: 'cx_webchat_form_email',
              name: 'email',
              maxlength: '25',
              placeholder: 'Required',
              label: 'Email',
              value: user.email
            },
            {
              id: 'cx_webchat_form_subject',
              name: 'subject',
              label: 'Subject',
              maxlength: '100',
              placeholder: 'Required',
            },
          ],
        },
      })
      .done(function (e) {})
      .fail(function (e) {});
  }
}
