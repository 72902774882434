import { Injectable } from '@angular/core';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { CreateTicketComponent } from '../tickets/create-ticket.component';


@Injectable()
export class TicketSaveGuard implements CanDeactivate<CreateTicketComponent> {
  constructor(private router: Router) { }

  canDeactivate(component: CreateTicketComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> {
      return component.checkUnsavedForNavigation();
  }
}
