import { clone } from 'lodash';

import { Resource } from '../shared/resource';

export class Tag extends Resource {
  id: string;
  name: string;
  itemCount: number;
  notes: string;
  loading = false;
  classes: string;

  filterOptions(options): any[] {
    options = clone(options);
    return options;
  }
}
