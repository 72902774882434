"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap}.label[_ngcontent-%COMP%]{margin:7px 21px 7px 10px;display:flex}.label[_ngcontent-%COMP%]:last-of-type{margin-right:10px}.color[_ngcontent-%COMP%]{width:20px;height:20px;display:flex;align-items:center;justify-content:center;border-radius:1.5px;border:.8px solid #aeaeae;background-color:#fff;margin-right:10px}i[_ngcontent-%COMP%]{color:#fff}i.icon-stop-hand[_ngcontent-%COMP%]{color:#565554}p[_ngcontent-%COMP%]{margin:0;font-size:14px;font-weight:600;color:#595959;white-space:nowrap}"];
exports.styles = styles;
