import { Component } from '@angular/core';

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html'
})

export class NotificationsListComponent {
  constructor() {}
}
