"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment");
var driver_serializer_1 = require("./../drivers/driver.serializer");
var organization_1 = require("../organizations/organization");
var condensed_trip_1 = require("../trips/condensed-trip");
var condensed_punch_card_1 = require("../punch-cards/condensed-punch-card");
var job_serializer_1 = require("../jobs/job.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Paystub = /** @class */ (function () {
    function Paystub(paystubInfo) {
        paystubInfo = camelcaseKeysDeep(paystubInfo);
        this.id = paystubInfo.id;
        this.number = paystubInfo.number;
        this.amount = paystubInfo.amount;
        this.expenseAmount = paystubInfo.expenseAmount || 0;
        this.surchargeAmount = paystubInfo.surchargeAmount || 0;
        this.tripsAmount = paystubInfo.tripsAmount || 0;
        this.tripsExpenseAmount = paystubInfo.tripsExpenseAmount || 0;
        this.punchcardsAmount = paystubInfo.punchcardsAmount || 0;
        this.punchcardsExpenseAmount = paystubInfo.punchcardsExpenseAmount || 0;
        this.subtotal = paystubInfo.subtotal || 0;
        if (paystubInfo.ownerOrganization) {
            this.ownerOrganization = new organization_1.Organization(paystubInfo.ownerOrganization);
        }
        if (paystubInfo.customerOrganization) {
            this.customerOrganization = new organization_1.Organization(paystubInfo.customerOrganization);
        }
        if (paystubInfo.driver) {
            this.driver = new driver_serializer_1.DriverSerializer().fromJson(paystubInfo.driver);
        }
        if (paystubInfo.job) {
            this.job = new job_serializer_1.JobSerializer().fromJson(paystubInfo.job);
        }
        this.sendable = paystubInfo.sendable;
        this.sent = paystubInfo.sent;
        this.draft = !paystubInfo.sent;
        this.sentTime = paystubInfo.sentTime;
        this.sentBy = paystubInfo.sentBy;
        if (paystubInfo.sentTrips && paystubInfo.sentTrips.length) {
            this.sentTrips = paystubInfo.sentTrips.map(function (trip) {
                return new condensed_trip_1.CondensedTrip(trip);
            });
        }
        if (paystubInfo.sentPunchCards && paystubInfo.sentPunchCards.length) {
            this.sentPunchCards = paystubInfo.sentPunchCards.map(function (punchCard) {
                return new condensed_punch_card_1.CondensedPunchCard(punchCard);
            });
        }
        this.billFromContactEmail = paystubInfo.billFromContactEmail;
        if (paystubInfo.billFromCcEmails && (typeof paystubInfo.billFromCcEmails === 'object')) {
            this.billFromCcEmails = paystubInfo.billFromCcEmails.join(',');
        }
        this.billFromAddress = paystubInfo.billFromAddress;
        this.billToContactEmail = paystubInfo.billToContactEmail;
        if (!this.billToContactEmail) {
            this.billToContactEmail = this.driver && this.driver.profile && this.driver.profile.email;
        }
        if (paystubInfo.billToCcEmails && (typeof paystubInfo.billToCcEmails === 'object')) {
            this.billToCcEmails = paystubInfo.billToCcEmails.join(',');
        }
        this.billToAddress = paystubInfo.billToAddress;
        if (!this.billToAddress) {
            var address = [];
            var csz = [];
            address.push(this.driver && this.driver.street);
            csz.push(this.driver && this.driver.city);
            var state = this.driver && this.driver.state;
            if (state) {
                csz.push(state + ',');
            }
            csz.push(this.driver && this.driver.zipcode);
            address.push(csz.filter(Boolean).join(' '));
            this.billToAddress = address.filter(Boolean).join('\n');
        }
        this.createdAt = paystubInfo.createdAt;
        this.remittanceInfo = paystubInfo.remittanceInfo;
        this.pdf = paystubInfo.pdf;
        this.html = paystubInfo.html;
        this.earliestBillableItemTime = paystubInfo.earliestBillableItemTime;
        this.latestBillableItemTime = paystubInfo.latestBillableItemTime;
        this.paymentType = paystubInfo.paymentType;
    }
    Object.defineProperty(Paystub.prototype, "administrativeStatus", {
        get: function () {
            if (this.sent) {
                return 'paid';
            }
            else if (this.draft) {
                return 'draft';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paystub.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus &&
                this.administrativeStatus.length &&
                this.administrativeStatus !== 'paid' &&
                this.administrativeStatus !== 'draft') {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paystub.prototype, "paystubPeriod", {
        get: function () {
            var values = [];
            if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
                var _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_earliestBillableItemTime);
            }
            if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
                var _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_latestBillableItemTime);
            }
            if (!values.length) {
                values.push(this.shortTitle + ' ' + this.number);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paystub.prototype, "shortTitle", {
        get: function () {
            if (this.draft) {
                return 'Paystub Draft ';
            }
            else {
                return 'Paid Paystub ';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paystub.prototype, "title", {
        get: function () {
            return 'Paystubs | ' + this.driver && this.driver.name;
        },
        enumerable: true,
        configurable: true
    });
    Paystub.prototype.url = function (action) {
        if (action === 'view') {
            return this.html;
        }
        else if (action === 'download') {
            return this.pdf;
        }
        else {
            return '/paystubs/' + this.id + '/' + action;
        }
    };
    Paystub.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.administrativeStatus === 'draft') {
            var _option = lodash_1.find(options, { action: 'view' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'download' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'unlock' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (this.administrativeStatus === 'paid') {
            var _option = lodash_1.find(options, { action: 'edit' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'mark_as_paid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.pdf) {
            var _option = lodash_1.find(options, { action: 'download' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return Paystub;
}());
exports.Paystub = Paystub;
