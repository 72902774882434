import { clone, remove, find } from 'lodash';
import { Organization } from '../organizations/organization';
import { Scale } from '../scales/scale';
import { Truck } from '../trucks/truck';
import { Driver } from '../drivers/driver';
import { Location } from '../locations/location';
import { Product } from '../products/product';
import { PurchaseOrder } from '../purchase-orders/purchase-order';
import { PaymentType } from '../payment-types/payment-type';
import { Surcharge } from '../surcharges/surcharge';

export class Ticket {
  id: string;
  createdAt: string;
  // recordDeleted: boolean;
  deliveryTime: string;
  status: string; // in-progress, on-hold, complete
  number: number;
  customerOrganization: Organization;
  sellerOrganization: Organization;
  carrierOrganization: Organization;
  driver: Driver;
  driverName: string;
  truck: Truck;
  location: Location;
  product: Product;
  completedTime: string;
  notes: string;
  tareWeight: number;
  tareWeightTime: string;
  tareWeightUnit: string;
  tareWeightScale: Scale;
  grossWeight: number;
  grossWeightTime: string;
  grossWeightUnit: string;
  grossWeightScale: Scale;
  net: number;
  netPrice: number;
  netWeightUnit: string;
  totalPrice: number;
  unitPrice: number;
  paymentType: PaymentType;
  purchaseOrder: PurchaseOrder;
  discountPercentage: number;
  correctionWeight: number;
  surcharges: Surcharge[] = [];
  surchargeTotal: number;
  taxesTotal: number;
  void: boolean;
  voidReason: string;
  selected = false;
  loading: boolean;
  statusLabel: string;
  classes: string;

  url(action): string {
    return '/scale/tickets/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}

