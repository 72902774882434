"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var material_1 = require("@angular/material");
var ngx_device_detector_1 = require("ngx-device-detector");
var moment = require("moment");
var daily_board_filters_dialog_component_1 = require("./daily-board-filters-dialog.component");
var collaborator_service_1 = require("../collaborators/collaborator.service");
var index_1 = require("../shared/index");
var assignment_service_1 = require("../assignments/assignment.service");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var preference_service_1 = require("../preferences/preference.service");
var DailyBoardComponent = /** @class */ (function () {
    function DailyBoardComponent(dialog, route, router, collaboratorService, authenticationService, assignmentService, deviceDetectorService, preferenceService, cd) {
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.collaboratorService = collaboratorService;
        this.authenticationService = authenticationService;
        this.assignmentService = assignmentService;
        this.deviceDetectorService = deviceDetectorService;
        this.preferenceService = preferenceService;
        this.cd = cd;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.loading = true;
        this.errors = [];
        this.pendingJobsCount = 0;
        this.count = 0;
        this.canCreateJobs = true;
        this.hideCancelled = true;
        this.hasAllDriversEnabled = false;
        this.search = '';
        this.sortAsc = true;
        this.stringify = JSON.stringify;
        this.actionDropdownOptions = [
            { name: 'Edit', button: true }
        ];
        this.table = 'job-stats';
        this.view = 'grid';
        this.displayAsGrid = true;
        this.filters = [];
        this.enabledFeatures = [];
        this.carrierMode = false;
        this.preferenceKey = 'daily-board-component-preferences';
        this.allSubscriptionsToUnsubscribe = [];
    }
    DailyBoardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.getPendingCount();
        this.getPreferences();
        this.canCreateJobs = this.authenticationService.canCreateJobs();
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        this.carrierMode = !this.enabledFeatures.includes('ruckitBilling');
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.hideCancelled = _this.authenticationService.getFilterProperty('hideCancelledJobs');
            _this.search = result.qparams['search'] || '';
            _this.view = result.qparams['view'] ? result.qparams['view'] : _this.view;
            _this.table = result.params['table'] ? result.params['table'] : 'job-stats';
            if (result.qparams['date']) {
                var date = moment(result.qparams['date'], 'YYYYMMDD').toDate();
                _this.jobEventDate = date || new Date();
            }
            else {
                _this.setDefaultDate();
            }
            _this.sortBy = 'job__name';
            _this.loading = false;
        }));
    };
    DailyBoardComponent.prototype.ngOnDestroy = function () {
        if (this.pendingCountReq && typeof this.pendingCountReq.unsubscribe === 'function') {
            this.pendingCountReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DailyBoardComponent.prototype.switchView = function (view) {
        var _this = this;
        if (view === void 0) { view = 'grid'; }
        this.view = view !== 'grid' && (this.device.desktop || this.device.tablet) && !this.carrierMode ? view : 'grid';
        var params = { view: view };
        var currentParams = this.route.snapshot.queryParams;
        this.router.navigate([], { skipLocationChange: true }).then(function () {
            _this.router.navigate([], {
                relativeTo: _this.route,
                queryParams: __assign({}, currentParams, params)
            });
        });
        if (this.preference && this.preference.blob &&
            this.preference.blob['view'] && this.preference.blob['view'] !== this.view) {
            this.savePreferences();
        }
        else if (!this.preference) {
            this.savePreferences();
        }
    };
    // otherwise it crashes when changing views - expressionChangedAfterItHasBeenCheckedError
    DailyBoardComponent.prototype.ngAfterViewChecked = function () {
        this.cd.detectChanges();
    };
    DailyBoardComponent.prototype.savePreferences = function () {
        var _this = this;
        if (this.preferenceKey) {
            var currentUser = this.authenticationService.user();
            this.preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: currentUser.id, blob: { view: this.view, table: this.table } });
            this.preferenceService.save(this.preference).subscribe(function (preference) {
                _this.preference = preference;
                _this.parsePreferences();
            });
        }
    };
    DailyBoardComponent.prototype.getPreferences = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        if (this.preference && this.preference.id) {
            this.preferenceService.get(this.preference.id).subscribe(function (preference) {
                _this.preference = preference;
                _this.parsePreferences();
            });
        }
        else {
            this.preferenceService.list({
                name: this.preferenceKey,
                type: 'user',
                profile: currentUser.id
            }).subscribe(function (preferences) {
                if (preferences && preferences.length) {
                    _this.preference = preferences[0];
                    _this.parsePreferences();
                }
            });
        }
    };
    DailyBoardComponent.prototype.parsePreferences = function () {
        if (this.preference.blob && this.preference.blob['view']) {
            var view = this.preference.blob['view'];
            this.switchView(view);
        }
    };
    DailyBoardComponent.prototype.getPendingCount = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        this.pendingCountReq = this.collaboratorService.getPending({ page_size: 1 }).subscribe(function (invitations) {
            _this.pendingJobsCount = _this.collaboratorService.count;
        }, function (err) {
            _this.errors = err;
        });
    };
    DailyBoardComponent.prototype.selectListStyle = function (style) {
        this.displayAsGrid = (style === 'grid');
    };
    DailyBoardComponent.prototype.setDefaultDate = function () {
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        var date = moment();
        queryParams['date'] = date.format('YYYYMMDD');
        this.jobEventDate = date.toDate();
        this.router.navigate(['jobs', 'daily'], { queryParams: queryParams });
    };
    DailyBoardComponent.prototype.onDateChanged = function (dates) {
        this.jobEventDate = dates[0];
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        var date = moment(this.jobEventDate).format('YYYYMMDD');
        queryParams['date'] = date;
        queryParams['search'] = this.search;
        this.router.navigate([], { queryParams: queryParams });
    };
    DailyBoardComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(daily_board_filters_dialog_component_1.DailyBoardFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        dialog.componentInstance.model.apexCustomerJob = lodash_1.get(lodash_1.find(this.filters, { key: 'customerJob' }), 'value');
        this.filtersDialog = dialog.componentInstance;
    };
    DailyBoardComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'customer_organization',
            project: 'job__project',
            startLocation: 'job__start_location',
            endLocation: 'job__end_location',
            apexCustomerJob: 'apex_customer_job',
        };
        this.filters = Object.keys(filterRes).filter(function (key) {
            return !!filterRes[key];
        }).map(function (key) {
            var query = {};
            var displayKey = key;
            if (key === 'apexCustomerJob') {
                displayKey = 'customerJob';
            }
            query[queryKeys[key]] = filterRes[key].id || filterRes[key];
            return {
                key: displayKey,
                value: filterRes[key],
                query: query
            };
        });
        this.dailyBoardGrid.getJobs();
        this.loading = false;
    };
    DailyBoardComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.filters, filter);
        this.dailyBoardGrid.getJobs();
    };
    DailyBoardComponent.prototype.exportAssignments = function () {
        var _this = this;
        var startOfDay = new Date(this.jobEventDate);
        startOfDay.setHours(0, 0, 0, 0);
        var endOfDay = lodash_1.clone(startOfDay);
        endOfDay.setHours(23, 59, 59, 999);
        var filters = {
            'jobevent__shift1_start__gte': startOfDay && startOfDay.toISOString(),
            'jobevent__shift1_start__lte': endOfDay && endOfDay.toISOString()
        };
        this.assignmentService.export({}, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: { type: 'assignments' }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    params: params,
                    service: _this.assignmentService,
                    buttonText: 'Try to Export Again'
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    DailyBoardComponent.prototype.toggleCancelled = function () {
        this.authenticationService.setFilterProperty('hideCancelledJobs', this.hideCancelled);
        if (this.dailyBoardGrid) {
            this.dailyBoardGrid.getJobs();
        }
        if (this.dailyBoardList) {
            this.dailyBoardList.refreshTable();
        }
    };
    DailyBoardComponent.prototype.updatedMarkets = function () {
        if (this.dailyBoardGrid) {
            this.dailyBoardGrid.getJobs();
        }
        if (this.dailyBoardList) {
            this.dailyBoardList.refreshTable();
        }
    };
    DailyBoardComponent.prototype.changeSearch = function (term) {
        if (term === void 0) { term = ''; }
        this.search = term;
    };
    return DailyBoardComponent;
}());
exports.DailyBoardComponent = DailyBoardComponent;
