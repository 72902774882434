"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ColumnToggleComponent = /** @class */ (function () {
    function ColumnToggleComponent(fb) {
        this.fb = fb;
        this.availableColumns = [];
        this.displayedColumns = [];
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.icon = 'settings';
    }
    ColumnToggleComponent.prototype.ngOnInit = function () {
        this.form = this.fb.group({
            allSelected: this.availableColumns.length === this.displayedColumns.length,
            columns: this.buildColumnControls(true)
        });
    };
    ColumnToggleComponent.prototype.ngOnChanges = function (changes) {
        if (changes['displayedColumns']) {
            if (this.form && this.form.controls) {
                this.form.controls['columns'] = this.buildColumnControls(true);
                if (this.availableColumns.length === this.displayedColumns.length) {
                    this.form.controls['allSelected'].setValue(true, { emitEvent: false });
                }
                else if (this.form.controls['allSelected'].value === true) {
                    this.form.controls['allSelected'].setValue(false, { emitEvent: false });
                }
            }
        }
    };
    Object.defineProperty(ColumnToggleComponent.prototype, "columns", {
        get: function () {
            return this.form.get('columns');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColumnToggleComponent.prototype, "allSelected", {
        get: function () {
            return this.form.get('allSelected');
        },
        enumerable: true,
        configurable: true
    });
    ColumnToggleComponent.prototype.buildColumnControls = function (init) {
        var _this = this;
        if (init === void 0) { init = false; }
        if (init) {
            this.sortAvailableColumns();
        }
        var arr = this.availableColumns.map(function (column) {
            return _this.fb.control(_this.displayedColumns.includes(column.key));
        });
        return this.fb.array(arr);
    };
    ColumnToggleComponent.prototype.onSelectAllChange = function () {
        var formValue = this.form.controls['allSelected'].value;
        this.displayedColumns = formValue
            ? this.availableColumns.map(function (column) { return column.key; })
            : ['select', 'expand', 'actions'];
        this.form.controls['columns'] = this.buildColumnControls();
        var selectIdx = this.availableColumns.findIndex(function (c) { return c.key === 'select'; });
        var expandIdx = this.availableColumns.findIndex(function (c) { return c.key === 'expand'; });
        var actionsIdx = this.availableColumns.findIndex(function (c) { return c.key === 'actions'; });
        if (selectIdx > -1 && selectIdx !== 0) {
            this.moveFormControl('select', 0);
        }
        if (expandIdx > -1 && expandIdx !== 1) {
            this.moveFormControl('expand', 1);
        }
        if (actionsIdx > -1 && actionsIdx !== (this.displayedColumns.length - 1)) {
            this.moveFormControl('actions', this.displayedColumns.length - 1);
        }
        var formValues = this.form.controls['columns'].value;
        this.displayedColumns = this.availableColumns.map(function (column, index) {
            if (formValues[index]) {
                return column.key;
            }
        }).filter(Boolean);
        this.displayedColumnsChange.emit(this.displayedColumns);
    };
    ColumnToggleComponent.prototype.onColumnChange = function (node, column) {
        var formControls = this.form.controls['columns'];
        if (!node.value) {
            this.removeFormControl(column.key);
        }
        if (node.value) {
            var newPosition = this.displayedColumns.length;
            if (column.key === 'select') {
                newPosition = 0;
            }
            if (column.key === 'expand') {
                newPosition = 1;
            }
            if (column.key !== 'actions' && column.key !== 'select' && column.key !== 'expand' && this.displayedColumns.includes('actions')) {
                newPosition = this.displayedColumns.length - 1;
            }
            this.moveFormControl(column.key, newPosition);
        }
        var formValues = this.form.controls['columns'].value;
        this.displayedColumns = this.availableColumns.map(function (column, index) {
            if (formValues[index]) {
                return column.key;
            }
        }).filter(Boolean);
        this.displayedColumnsChange.emit(this.displayedColumns);
    };
    ColumnToggleComponent.prototype.onDragEnd = function (event, column) {
        var _this = this;
        event.target.draggable = false;
        this.lastColumnDragged = column;
        if (this.lastNodeDropped && this.lastNodeDropped.dragOver) {
            this.lastNodeDropped.dragOver = false;
        }
        if (this.lastColumnDragged && this.lastColumnDropped) {
            var draggedIdx = this.availableColumns.findIndex(function (c) { return c.key === _this.lastColumnDragged.key; });
            var droppedIdx = this.availableColumns.findIndex(function (c) { return c.key === _this.lastColumnDropped.key; });
            if (draggedIdx > -1 && droppedIdx > -1) {
                this.availableColumns.splice(draggedIdx, 1);
                this.availableColumns.splice(droppedIdx, 0, this.lastColumnDragged);
            }
            this.displayedColumns = this.availableColumns.map(function (column) {
                if (_this.displayedColumns.includes(column.key)) {
                    return column.key;
                }
            }).filter(Boolean);
            this.displayedColumnsChange.emit(this.displayedColumns);
        }
        this.resetDragAndDrop();
    };
    ColumnToggleComponent.prototype.onDrop = function (node, column) {
        node.dragOver = false;
        // If column is not checked, column cannot be a drop zone
        if (!node.value || column.key === 'select' || column.key === 'expand' || column.key === 'actions') {
            this.resetDragAndDrop();
        }
        else {
            this.lastColumnDropped = column;
            this.lastNodeDropped = node;
        }
    };
    ColumnToggleComponent.prototype.onHandleMouseDown = function (event) {
        event.target.parentNode.draggable = true;
    };
    ColumnToggleComponent.prototype.removeFormControl = function (key) {
        if (!key) {
            return;
        }
        var formControls = this.form.controls['columns'];
        var currentIdx = this.availableColumns.findIndex(function (c) { return c.key === key; });
        var column = this.availableColumns.find(function (c) { return c.key === key; });
        var node = formControls.controls[currentIdx];
        this.availableColumns.splice(currentIdx, 1);
        this.availableColumns.push(column);
        formControls.removeAt(currentIdx);
        formControls.push(node);
    };
    ColumnToggleComponent.prototype.moveFormControl = function (key, newIdx) {
        if (!key || newIdx < 0) {
            return;
        }
        var formControls = this.form.controls['columns'];
        var currentIdx = this.availableColumns.findIndex(function (c) { return c.key === key; });
        var column = this.availableColumns.find(function (c) { return c.key === key; });
        var node = formControls.controls[currentIdx];
        if (currentIdx && column && node) {
            this.availableColumns.splice(currentIdx, 1);
            this.availableColumns.splice(newIdx, 0, column);
            formControls.removeAt(currentIdx);
            formControls.insert(newIdx, node);
        }
    };
    ColumnToggleComponent.prototype.resetDragAndDrop = function () {
        this.lastColumnDragged = '';
        this.lastColumnDropped = '';
    };
    ColumnToggleComponent.prototype.sortAvailableColumns = function () {
        var _this = this;
        this.availableColumns.sort(function (a, b) {
            var aInDisplay = _this.displayedColumns.indexOf(a.key);
            var bInDisplay = _this.displayedColumns.indexOf(b.key);
            if (aInDisplay === -1 && bInDisplay === -1) {
                return 1;
            }
            if (aInDisplay === -1) {
                return 1;
            }
            if (bInDisplay === -1) {
                return -1;
            }
            return aInDisplay > bInDisplay ? 1 : -1;
        });
    };
    return ColumnToggleComponent;
}());
exports.ColumnToggleComponent = ColumnToggleComponent;
