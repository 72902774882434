<div class="app-content">
  <form #editPaystub="ngForm" class="paystub-form">
    <my-toolbar [title]="paystub && paystub.title"></my-toolbar>

    <action-bar>
      <div class="left-actions"></div>
      <div class="right-actions">
        <button id="send-paystub-button"
                class="btn btn-primary"
                (click)="submit(); false"
                *ngIf="paystub && paystub.draft"
                [disabled]="loading || !editPaystub.form.valid || !paystub.sendable"
                [ngClass]="{loading:loading}" translate>
          Mark as Paid
        </button>
      </div>
    </action-bar>

    <div class="paystub-container" *ngIf="paystub">
      <div class="paystub-content">
        <div class="meta-data" translate>
          DRAFT &mdash; CREATED {{ paystub.createdAt | date:'shortDate' }}
          <notification *ngFor="let error of errors">{{ error }}</notification>
        </div>
        <div class="billing">
          <div class="billing-left">
            <div>
              <div class="title" translate>PAYSTUB</div>
              <div class="field-group paystub-number">
                <label translate>Paystub #</label>
                <input type="text" name="paystub-number" required [(ngModel)]="paystub.number" #number="ngModel" (blur)="save(); false" />
              </div>

              <div class="field-group paystub-date">
                <label translate>Date</label>
                <date-range-picker [config]="{decreaseControl: false, increaseControl: false}" [selectedDates]="[createdAt]"
                  (dateChanged)="onDateChanged($event)"></date-range-picker>
              </div>
            </div>

            <div class="sender-address">
              <div class="edit-action" (click)="editing = !editing">
                <i class="icon-pencil"></i>
              </div>
              <h2>Sender</h2>
              <div class="field-group">
                <label>{{ paystub.ownerOrganization && paystub.ownerOrganization.name }}</label>
                <textarea required [(ngModel)]="paystub.billFromAddress" name="bill_from_address" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
              </div>
              <div class="field-group">
                <input type="email" required class="light" placeholder="{{ 'Email' | translate }}" [(ngModel)]="paystub.billFromContactEmail" name="bill_from_contact_email" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
              <div class="field-group">
                <input type="email" class="light" placeholder="{{ 'CC Email' | translate }}" [(ngModel)]="paystub.billFromCcEmails" name="bill_from_cc_emails" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
            </div>
          </div>

          <div class="billing-right">
            <div class="logo-image upload-image-dropzone" [ngClass]="{filled: paystub.logo || paystub.ownerOrganization.image}">
              <img *ngIf='paystub.logo || paystub.ownerOrganization.image' [src]="paystub.logo || paystub.ownerOrganization.image" />

              <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
              <label for="file">
                <i class="icon-upload"></i>
                <span *ngIf="!paystub.logo && !paystub.ownerOrganization.image" translate>Click to upload a logo</span>
                <span *ngIf="paystub.logo || paystub.ownerOrganization.image" translate>Upload a new logo image</span>
              </label>
            </div>

            <div class="receipient-address">
              <div class="edit-action" (click)="editing = !editing">
                <i class="icon-pencil"></i>
              </div>
              <h2>Recipient</h2>
              <div class="field-group">
                <label>{{ paystub.driver && paystub.driver.name }}</label>
                <textarea required [(ngModel)]="paystub.billToAddress" name="bill_to_address" (blur)="save(); false" [ngClass]="{editing: editing}"></textarea>
              </div>
              <div class="field-group">
                <input type="email" required class="light" placeholder="{{ 'Email' | translate }}" [(ngModel)]="paystub.billToContactEmail" name="bill_to_contact_email" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
              <div class="field-group">
                <input type="email" class="light" placeholder="{{ 'CC Email' | translate }}" [(ngModel)]="paystub.billToCcEmails" name="bill_to_cc_emails" (blur)="save(); false" [ngClass]="{editing: editing}" />
              </div>
            </div>
          </div>
        </div>

        <div class="title-container">
          <div class="title" translate>
            Paystub Items
          </div>
          <div class="search-container {{ loading ? 'loading' : '' }}">
            <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
          </div>
        </div>

        <div class="paystub-trips">
          <ruckit-paystub-trips [search]="search" [paystub]="paystub" (updatePaystub)="updatePaystub($event)"></ruckit-paystub-trips>
        </div>

        <div class="paystub-punch-cards">
          <ruckit-paystub-punch-cards [search]="search" [paystub]="paystub" (updatePaystub)="updatePaystub($event)"></ruckit-paystub-punch-cards>
        </div>

        <div class="paystub-surcharges">
          <ruckit-paystub-surcharges [search]="search" [paystub]="paystub" (updatePaystub)="updatePaystub($event)"></ruckit-paystub-surcharges>
        </div>

        <div class="horizontal-rule"></div>

        <div class="total-summary-container">
          <div class="total-summary">
            <div class="row tickets-sub-total">
              <label translate>Tickets Subtotal</label>
              <div class="value">{{ (paystub.subtotal | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
            <div class="row surcharges">
              <label translate>Surcharges</label>
              <div class="value">{{ (paystub.surchargeAmount | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
            <div class="row paystub-total">
              <label translate>Paystub Total</label>
              <div class="value">{{ (paystub.expenseAmount | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
