import { Component, ViewChild, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

// libraries
import moment = require('moment-timezone');

// angular material
import { MatDialogRef } from '@angular/material';

// services
import { TripService } from './../trip.service';
import { JobEventService } from '../../job-events/job-event.service';
import { parseErrors } from '../../shared/api.service';
import { AuthenticationService } from '../../shared';
import { AttachmentService } from '../../attachments/attachment.service';

// models
import { Job } from '../../jobs/job';
import { JobEvent } from '../../job-events/job-event';
import { Trip } from './../trip';
import { CondensedTrip } from '../condensed-trip';

// components
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

// constants
import {
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
} from '../../app.constants';
import {
  JOB_DROPDOWN_CONFIG,
  JOB_EVENTS_DROPDOWN_CONFIG,
  CARRIER_DROPDOWN_CONFIG,
  DRIVER_DROPDOWN_CONFIG,
  TRUCK_DROPDOWN_CONFIG,
} from './dropdownsConfig';

// validators
import { globalTripTimeValidator } from '../trip-time-validators';

@Component({
  selector: 'new-trip-dialog',
  templateUrl: './new-trip-dialog.component.html',
  styleUrls: ['./new-trip-dialog.component.scss'],
})
export class NewTripDialogComponent implements OnInit {
  lockedFields = false;
  jobEventDate = null;
  jobEventDatePickerOptions = null;
  highlightedDates = [];
  jobEvents = [];
  model = {
    jobevent: null,
    carrier: null,
    truck: null,
    driver: null,
    startTime: null,
    startDate: null,
    endTime: null,
    endDate: null,
    loadingCheckin: {
      attachments: [],
      ticketNumber: null,
      weight: null,
      date: null,
      time: null,
    },
    unloadingCheckin: {
      attachments: [],
      ticketNumber: null,
      weight: null,
      date: null,
      time: null,
    },
  };

  loading = false;
  errors = [];
  jobEventError = false;
  job: Job;
  jobId: string;
  jobName: string;
  jobEvent: JobEvent;
  tripJobEvent: JobEvent;
  callback: Function;
  jobEventsReq: Subscription;
  checkinsReq: Subscription;
  activeTab = 'trip-data';

  @ViewChild('jobDropdown', { static: false })
  jobsDropdown: RuckitDropdownComponent;
  jobDropdownConfig = JOB_DROPDOWN_CONFIG(this.translate);

  @ViewChild('jobEventsDropdown', { static: false })
  jobEventsDropdown: RuckitDropdownComponent;
  jobEventsDropdownConfig = JOB_EVENTS_DROPDOWN_CONFIG(this.translate);

  @ViewChild('carriersDropdown', { static: false })
  carriersDropdown: RuckitDropdownComponent;
  carrierDropdownConfig = CARRIER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('driversDropdown', { static: false })
  driversDropdown: RuckitDropdownComponent;
  driverDropdownConfig = DRIVER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('truckDropdown', { static: false })
  truckDropdown: RuckitDropdownComponent;
  truckDropdownConfig = TRUCK_DROPDOWN_CONFIG(this.translate);

  firstLoad = true;

  loadingTicketImages = [];
  unloadingTicketImages = [];
  loadingSignatures = [];
  unloadingSignatures = [];
  newTripForm: FormGroup;
  uploading = false;

  fieldsToCheck = [
    'activeTracking',
    'ticketImage',
    'signatureImage',
    'ticketNumber',
    'weight',
  ];
  disabledFields = {
    carrier: false,
    driver: false,
    calendar: false,
  };

  requiredImages = {
    loadingImages: false,
    unloadingImages: false,
    loadingSignature: false,
    unloadingSignature: false,
  };

  constructor(
    public dialogRef: MatDialogRef<NewTripDialogComponent>,
    private tripService: TripService,
    private attachmentsService: AttachmentService,
    private jobEventService: JobEventService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const user = this.authenticationService.user();
    this.carrierDropdownConfig.query = {
      exclude: user.organization.id,
    };
    this.carrierDropdownConfig.prefilledOptions.push(user.organization);
    this.initForm();
  }

  submit(): void {
    this.loading = true;
    this.errors = [];
    const newTrip = this.parseTrip();
    this.tripService.saveWithCheckinsAndAttachments(newTrip).subscribe(
      (res) => {
        const trip = new Trip(res);
        this.dialogRef.close();
        this.callback(trip);
      },
      (err) => {
        this.errors = parseErrors(Array.isArray(err) ? err : [err]);
        this.loading = false;
      }
    );
  }

  parseTrip(): CondensedTrip {
    const formTrip = this.newTripForm.value;
    const loadingImages = [
      ...this.loadingTicketImages,
      ...this.loadingSignatures.map((signature: File) => {
        Object.assign(signature, {
          tempType: TRIPSIGNATUREIMAGETYPE,
        });
        return signature;
      }),
    ];
    const unloadingImages = [
      ...this.unloadingTicketImages,
      ...this.unloadingSignatures.map((signature) => {
        Object.assign(signature, {
          tempType: TRIPSIGNATUREIMAGETYPE,
        });
        return signature;
      }),
    ];

    const parsedLoadingImages = loadingImages.map((img: any) =>
      this.attachmentsService.parseAttachment(
        img,
        img.tempType ? img.tempType : TRIPTICKETIMAGETYPE,
        null
      )
    );

    const parsedUnloadingImages = unloadingImages.map((img: any) =>
      this.attachmentsService.parseAttachment(
        img,
        img.tempType ? img.tempType : TRIPTICKETIMAGETYPE,
        null
      )
    );

    const trip: CondensedTrip = {
      ...this.newTripForm.value,
      job: formTrip.job.id,
      jobEvent: formTrip.jobevent,
      jobevent: formTrip.jobevent,
      carrier: formTrip.carrier.id,
      driver: formTrip.driver.id,
      truck: formTrip.truck.id,
      startTime: this.parseDateTime(formTrip.start.date, formTrip.start.time),
      endTime: this.parseDateTime(formTrip.end.date, formTrip.end.time),
      loadingCheckin: {
        ...formTrip.loadingCheckin,
        attachments: parsedLoadingImages,
        ...{
          date: this.parseDateTime(
            formTrip.loadingCheckin.date,
            formTrip.loadingCheckin.time
          ),
        },
      },
      unloadingCheckin: {
        ...formTrip.unloadingCheckin,
        ...{
          date: this.parseDateTime(
            formTrip.unloadingCheckin.date,
            formTrip.unloadingCheckin.time
          ),
        },
        attachments: parsedUnloadingImages,
      },
    };
    return trip;
  }

  parseDateTime = (date: any, time: string): string => {
    return !!date && !!time
      ? moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).toISOString()
      : null;
  };

  onJobEventDateChanged(values: Date[]): void {
    this.jobEventError = false;
    if (!this.firstLoad && this.job && values && values.length) {
      if (!this.disabledFields.carrier && !this.disabledFields.driver) {
        this.model.carrier = null;
        this.model.driver = null;
      }
      this.model.truck = null;

      const selectedDate = moment(values[0]).format().split('T')[0];
      const jobEvent = this.jobEvents.find((j) =>
        j.shift1Start.includes(selectedDate)
      );
      this.jobEventDate = values[0];

      if (jobEvent) {
        this.jobEvent = jobEvent;
        this.newTripFormSetRequiredFields(jobEvent);
        this.setRequiredImages(jobEvent);
        const query = {
          ...this.carrierDropdownConfig.query,
          carriers_for_jobevent: jobEvent.id,
        };

        this.carriersDropdown.config.query = query;
        this.carriersDropdown.getRecords(query);

        this.model.jobevent = jobEvent.id;
        this.newTripFormPatchValue('jobevent', jobEvent.id);
      } else {
        this.jobEventError = true;
        this.jobEvent = null;
      }
    }
  }

  onDateChange(args: string[], dates: Date[]) {
    console.log(this.newTripForm);
    if (args && args.length > 0 && dates) {
      this.newTripFormPatchValueNested(args, dates[0]);
    }
  }

  editDateTime(field: 'loadingTime' | 'unloadingTime', value: any) {
    if (value) {
      let checkinField =
        'loadingTime' === field ? 'loadingCheckin' : 'unloadingCheckin';
      let date = this.model[checkinField].date || new Date();
      if (!!this.model[checkinField].date) {
        this.model[checkinField].date = date;
        this.newTripFormPatchValueNested([checkinField, 'date'], date);
      }

      this.model[checkinField].time = value;
      this.newTripFormPatchValueNested([checkinField, 'time'], value);
    }
  }

  enableEditing(): void {
    this.lockedFields = false;
    this.firstLoad = true;
    this.disabledFields.carrier = true;
    this.disabledFields.calendar = true;
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        if (this.disabledFields.carrier || this.disabledFields.calendar) {
          this.disabledFields.carrier = false;
          this.disabledFields.calendar = false;
        }
        // if newly selected job is the same as existing selected job
        if (this.job && this.job.id === e.id) {
          if (this.firstLoad) {
            this.firstLoad = false;
            this.getJobEvents(e.id);
            this.newTripFormPatchValue(filterType, e);
          }
          break;
        }
        this.jobEventDatePickerOptions = null;
        this.job = e;
        this.jobId = e.id;
        if (this.firstLoad) {
          this.firstLoad = false;
        } else {
          this.resetForm();
        }
        this.getJobEvents(e.id);
        this.newTripFormPatchValue(filterType, e);
        break;
      case 'carrier':
        const carrier = e.carrier;
        if (
          this.jobEvent &&
          (this.model.carrier === null || this.model.carrier.id !== carrier.id)
        ) {
          this.model.carrier = carrier;
          this.model.driver = null;
          this.model.truck = null;
          this.driversDropdown.getRecords({
            carrier: carrier.id,
            shared_jobevent: this.jobEvent.id,
          });
          this.truckDropdown.getRecords({
            carrier: carrier.id,
          });
          this.newTripFormPatchValue('driver', null);
          this.newTripFormPatchValue('truck', null);
        }
        this.newTripFormPatchValue(filterType, carrier);
        break;
      default:
        this.model[filterType] = e;
        this.newTripFormPatchValue(filterType, e);
        break;
    }
  }

  getJobEvents(jobId: string, query = {}): void {
    this.loading = true;
    this.jobEventError = false;

    // shift1Start
    this.jobEventService
      .list({
        ordering: 'shift1_start_timestamp',
        job: jobId,
        page_size: 100,
        ...query,
      })
      .subscribe(
        (jobEvents) => {
          const newDates = jobEvents.map((j) => j.shift1Start);
          this.jobEvents = [...jobEvents];
          const jobEvent = this.jobEvent
            ? this.jobEvent
            : this.getJobEventWithDateAndJobEvents(
                jobEvents,
                this.jobEventDate || newDates[0]
              );

          if (newDates.length && jobEvent && jobEvent.id) {
            this.highlightedDates = newDates;
            this.jobEventDatePickerOptions = {
              min: newDates[0],
              max: newDates[newDates.length - 1],
            };
            const query = {
              ...this.carrierDropdownConfig.query,
              ...(jobEvent &&
                jobEvent.id && {
                  carriers_for_jobevent: jobEvent.id,
                }),
            };
            this.jobEvent = jobEvent;
            this.setRequiredImages(jobEvent);
            this.model.jobevent = jobEvent.id;
            this.newTripFormPatchValue('jobevent', jobEvent.id);

            this.carriersDropdown.config.query = query;
            if (!this.disabledFields.carrier && !this.disabledFields.driver) {
              this.carriersDropdown.getRecords(query);
            }
            this.newTripFormSetRequiredFields(jobEvent);
          } else {
            this.jobEventError = true;
          }
          this.loading = false;
        },
        (err) => {
          this.errors = err;
        }
      );
  }

  getJobEventWithDateAndJobEvents(jobEvents, jobEventDate) {
    const selectedDate = moment(jobEventDate).format().split('T')[0];
    return jobEvents.find((j) => j.shift1Start.includes(selectedDate));
  }

  getJobEventWithId(jobEventId: string) {
    this.jobEventService.get(jobEventId).subscribe((jobEvent) => {
      this.jobEvent = jobEvent;
      this.job = jobEvent.job;
      this.newTripFormSetRequiredFields(jobEvent);
    });
  }

  newTripFormPatchValue(key: string, value: any): void {
    this.newTripForm.patchValue({
      [key]: value,
    });
  }

  newTripFormPatchValueNested(keys: string[], value: any) {
    if (keys && keys.length > 0 && value) {
      let objToPatch = {};
      keys.reduce((prev, curr, i) => {
        return (prev[curr] = i + i === keys.length ? value : {});
      }, objToPatch);
      this.newTripForm.patchValue(objToPatch);
    }
  }

  newTripFormSetRequiredFields(jobEvent: JobEvent) {
    this.fieldsToCheck.forEach((field) => {
      this.newTripFormValidatorsSwitch(
        'loadingCheckin',
        field,
        jobEvent.job.checkinOptions[`${field}Config`] === 'required'
      );
    });
    this.fieldsToCheck.forEach((field) => {
      this.newTripFormValidatorsSwitch(
        'unloadingCheckin',
        field,
        jobEvent.job.checkoutOptions[`${field}Config`] === 'required'
      );
    });

    this.updateFormValueAndValidity(this.newTripForm);
  }

  updateFormValueAndValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (
        abstractControl instanceof FormGroup ||
        abstractControl instanceof FormArray
      ) {
        this.updateFormValueAndValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  newTripFormValidatorsSwitch(parent: string, key: string, required: boolean) {
    switch (key) {
      case 'activeTracking':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'date'],
            [Validators.required, Validators.minLength(1)]
          );
          this.newTripFormSetValidators(
            [parent, 'time'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.newTripFormClearValidators([parent, 'date']);
          this.newTripFormClearValidators([parent, 'time']);
        }
        break;

      case 'ticketImage':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'images'],
            [Validators.required]
          );
        } else {
          this.newTripFormClearValidators([parent, 'images']);
        }
        break;

      case 'signatureImage':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'signatures'],
            [Validators.required]
          );
        } else {
          this.newTripFormClearValidators([parent, 'signatures']);
        }
        break;

      case 'ticketNumber':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'ticketNumber'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.newTripFormClearValidators([parent, 'ticketNumber']);
        }
        break;

      case 'weight':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'weight'],
            [Validators.required, Validators.min(0)]
          );
        } else {
          this.newTripFormClearValidators([parent, 'weight']);
        }
        break;

      default:
        break;
    }
  }

  newTripFormSetValidators(field: string[], validators) {
    this.newTripForm.get(field).setValidators(validators);
  }

  newTripFormClearValidators(field: string[]) {
    this.newTripForm.get(field).clearValidators();
  }

  initForm() {
    this.model.startDate = this.jobEventDate;

    this.newTripForm = this.fb.group(
      {
        job: [this.job, Validators.required],
        jobevent: [this.model.jobevent, Validators.required],
        driver: [this.model.driver, Validators.required],
        carrier: [this.model.carrier, Validators.required],
        truck: [this.model.truck, Validators.required],
        start: this.fb.group({
          date: [this.model.startDate, [Validators.required]],
          time: [this.model.startTime, [Validators.required]],
        }),
        end: this.fb.group({
          date: [this.model.endDate],
          time: [this.model.endTime],
        }),

        loadingCheckin: this.fb.group({
          ticketNumber: [this.model.loadingCheckin.ticketNumber],
          weight: [this.model.loadingCheckin.weight],
          date: [this.model.loadingCheckin.date],
          time: [this.model.loadingCheckin.time],
          images: [null],
          signatures: [null],
        }),
        unloadingCheckin: this.fb.group({
          ticketNumber: [this.model.unloadingCheckin.ticketNumber],
          weight: [this.model.unloadingCheckin.weight],
          date: [this.model.unloadingCheckin.date],
          time: [this.model.unloadingCheckin.time],
          images: [null],
          signatures: [null],
        }),
      },
      { validators: globalTripTimeValidator }
    );

    if (this.model.jobevent) {
      this.getJobEventWithId(this.model.jobevent);
    }
  }

  parseImageOnChange(image: any) {
    if (image) {
      let reader = new FileReader();
      reader.onload = () => {
        image.file = reader.result;
        image.src = reader.result;
      };
      reader.readAsDataURL(image);
      return image;
    }
    return image;
  }

  onImagesChangeParser = (images: any[]): any[] =>
    images
      .filter((img: any) => img.isDeleted !== true)
      .map((img: any) =>
        img.isNew || img.isEdited
          ? Object.assign(img, {
              tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
            })
          : this.parseImageOnChange(img)
      );

  onImagesChange(
    type:
      | 'loadingTicketImages'
      | 'unloadingTicketImages'
      | 'unloadingSignatures'
      | 'loadingSignatures',
    checkin: 'loadingCheckin' | 'unloadingCheckin',
    images: any[]
  ) {
    const parsedImages = this.onImagesChangeParser(images);
    this[type] = parsedImages;
    this.newTripFormPatchValueNested(
      [checkin, type.includes('Signature') ? 'signatures' : 'images'],
      [...parsedImages]
    );
  }

  setRequiredImages(jobEvent: JobEvent) {
    this.requiredImages = {
      loadingImages:
        jobEvent.job.checkinOptions.ticketImageConfig === 'required',
      unloadingImages:
        jobEvent.job.checkoutOptions.ticketImageConfig === 'required',
      loadingSignature:
        jobEvent.job.checkinOptions.signatureImageConfig === 'required',
      unloadingSignature:
        jobEvent.job.checkoutOptions.signatureImageConfig === 'required',
    };
  }

  fileUploading(uploading: boolean) {
    this.uploading = uploading;
  }

  resetForm() {
    this.model.jobevent = null;
    this.jobEventDate = null;
    this.model.truck = null;
    if (!this.disabledFields.carrier) {
      this.model.carrier = null;
    }
    if (!this.disabledFields.driver) {
      this.model.driver = null;
    }
    this.newTripForm.reset();
  }

  get startDate() {
    return this.newTripForm.get(['start', 'date']).value;
  }

  get endDate() {
    return this.newTripForm.get(['end', 'date']).value;
  }

  get loadingCheckingDate() {
    return this.newTripForm.get(['loadingCheckin', 'date']).value;
  }

  get unloadingCheckingDate() {
    return this.newTripForm.get(['unloadingCheckin', 'date']).value;
  }

  get loadingCheckingTime() {
    return this.newTripForm.get(['loadingCheckin', 'time']).value;
  }

  get unloadingCheckingTime() {
    return this.newTripForm.get(['unloadingCheckin', 'time']).value;
  }
}
