<div class="app-content">
  <my-toolbar title="{{ 'Paystubs' | translate }}"></my-toolbar>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/paystubs', 'filter', 'draft']" routerLinkActive="active" translate>Draft</a>
        <a [routerLink]="['/paystubs', 'filter', 'paid']" routerLinkActive="active" translate>Paid</a>
      </div>
    </div>

    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filters.length}"><i class="icon-filter"></i>{{ 'Filters' | translate }}</button>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <dropdown title="Actions" [options]="dropdownOptions[state]" [changeTitle]="false" (onSelect)="setSelectedAction($event, state)"
        class="actions-dropdown" [disabled]="loading || (!allSelected[state] && !selectedPaystubs[state].length)"></dropdown>
    </div>
  </action-bar>

  <div class="filters" *ngIf="filters.length">
    <ng-container *ngIf="!loading">{{ count }} {{ 'Results' | translate }}</ng-container>
    <ng-container *ngIf="loading">{{ 'Loading...' | translate }}</ng-container>
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters">
      {{ filter.key | titleCase }}: {{ filter.value && (filter.value.selectName || filter.value.name || filter.value) }}
      <i  class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <div class="paystubs">
    <div class="list-table" (scroll)="onScroll($event)">
      <div class="table-body">
        <div class="header">
          <div class="head selector">
            <label>
              <input type="checkbox" (change)="selector($event, state)" [(ngModel)]="allSelected[state]" />
            </label>
          </div>
          <div class="head date sort" (click)="sort('start_time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'start_time'}" translate>
            Date
          </div>
          <div class="head paystub-number sort" (click)="sort('number')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'number'}" translate>
            Number
          </div>
          <div class="head amount sort" (click)="sort('expense_amount')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'expense_amount'}" translate>
            Amount
          </div>
          <div class="head driver sort" (click)="sort('driver__profile__first_name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__first_name'}" translate>
            Driver
          </div>
          <div class="head payment-type sort" (click)="sort('payment_type')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'payment_type'}"
            translate>
            Payment Type
          </div>
          <div class="head created sort" (click)="sort('created_at')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'created_at'}" translate>
            Created
          </div>
          <div class="head actions" translate>
            Actions
          </div>
        </div>

        <div class="no-results" *ngIf="!loading && paystubs.length === 0" fxLayout="column" fxLayoutAlign="start center">
          <div class="icon"><i class="icon-paystub"></i></div>
          <h2 translate>No Paystubs</h2>
          <p translate>When you create paystubs you’ll see them here.</p>
        </div>

        <div *ngFor="let paystub of paystubs | propertyFilter: 'administrativeStatus': state; let i = index">
          <div class="row" [ngClass]="{selected: paystub === selectedPaystub || paystub.selected}">
            <div class="selector">
              <label>
                <input type="checkbox" (change)="selector($event, state, paystub)" [checked]="allSelected[state] || paystub.selected"
                />
              </label>
            </div>
            <div class="date" [ngClass]="{'spacer': !paystub.displayableStatus}">
              <div class="value">{{ paystub.paystubPeriod || '&nbsp;' }}</div>
              <div class="paystub-status">
                <div class="label-{{paystub.administrativeStatus}}" *ngIf="paystub.displayableStatus">{{paystub.administrativeStatus}}</div>
                <div class="spacer" *ngIf="!paystub.displayableStatus">&nbsp;</div>
              </div>
            </div>
            <div class="paystub-number">
              <div class="value">#{{ paystub.number || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="amount">
              <div class="value currency">{{ (paystub.expenseAmount || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="driver" (contextmenu)="openContextMenu($event, paystub.driver.id)">
              <div class="value">{{ paystub.driver?.name || '&nbsp;' }}</div>
              <div class="label" translate>Payee</div>
            </div>
            <div class="payment-type">
              <div class="value" *ngIf="paystub.paymentType">{{ paystub.paymentType | titleCase }}</div>
              <div class="value" *ngIf="!paystub.paymentType" class="missing-required">&nbsp;</div>
              <div class="label" translate>Payment Type</div>
            </div>
            <div class="created">
              <div class="value">{{ (paystub.sentTime || paystub.createdAt) | date:'mediumDate' || '&nbsp;' }}</div>
              <div class="label">{{ paystub.sentTime ? ('Paid' | translate) : ('Created' | translate) }}</div>
            </div>
            <div class="actions">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of paystub.filterOptions(menuOptions)">
                  <a *ngIf="option.link && !option.external" mat-menu-item [routerLink]="paystub.url(option.action)" [queryParams]="{ returnTo: '/paystubs' }">{{ option.name }}</a>
                  <a *ngIf="option.link && option.external" mat-menu-item [href]="paystub.url(option.action)" target="_blank">{{ option.name }}</a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, paystub)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
          </div>
        </div>

        <notification *ngFor="let error of errors">{{error}}</notification>
        <my-loader *ngIf="loading" [ngClass]="{'full-height': paystubs.length === 0}"></my-loader>
      </div>
    </div>
  </div>
</div>

<!-- driver context menu -->
<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>