"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var core_1 = require("@ngx-translate/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var index_1 = require("./shared/index");
var data_service_1 = require("./shared/data.service");
require("../style/app.scss");
var AppComponent = /** @class */ (function () {
    function AppComponent(router, dataService, deviceDetectorService, authenticationService, translate) {
        var _this = this;
        this.router = router;
        this.dataService = dataService;
        this.deviceDetectorService = deviceDetectorService;
        this.authenticationService = authenticationService;
        this.url = 'https://goruckit.com';
        this.sidebarOpen = false;
        this.expanded = false;
        this.loggedIn = false;
        this.displaySidebar = false;
        translate.addLangs(['en', 'es', 'fr']);
        translate.setDefaultLang('en');
        //
        // // this language will be used as a fallback when a translation isn't found
        // // in the current language
        // translate.setDefaultLang('fr');
        // // the lang to use, if the lang isn't available, it will use the current
        // // loader to get them
        // translate.use('fr');
        var browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');
        // this.initializeAppcues();
        this.dataService.changeData({
            device: {
                info: this.deviceDetectorService.getDeviceInfo(),
                mobile: this.deviceDetectorService.isMobile(),
                tablet: this.deviceDetectorService.isTablet(),
                desktop: this.deviceDetectorService.isDesktop()
            }
        });
        this.subscription = this.dataService.data$.subscribe(function (data) {
            Promise.resolve(null).then(function () {
                _this.data = data;
                if (_this.data && _this.data.authenticated === undefined) {
                    _this.loggedIn = _this.authenticationService.isLoggedIn();
                }
                else {
                    _this.loggedIn = _this.data.authenticated;
                }
                if (_this.data && _this.data.sidebar === undefined) {
                    _this.displaySidebar = _this.authenticationService.displaySidebar();
                }
                else {
                    _this.displaySidebar = _this.data.sidebar;
                    _this.displaySidebar = true;
                }
                if (!data.device) {
                    data.device = {
                        info: _this.deviceDetectorService.getDeviceInfo(),
                        mobile: _this.deviceDetectorService.isMobile(),
                        tablet: _this.deviceDetectorService.isTablet(),
                        desktop: _this.deviceDetectorService.isDesktop()
                    };
                }
            });
        });
    }
    AppComponent.prototype.onLogout = function () {
        this.loggedIn = false;
        this.displaySidebar = false;
        this.authenticationService.logout();
    };
    AppComponent.prototype.initializeAppcues = function () {
        var Appcues = window['Appcues'];
        if (Appcues && Appcues !== undefined) {
            Appcues.start();
            var user = this.authenticationService.user();
            if (user) {
                Appcues.identify(user.id, {
                    name: user.name, email: user.username,
                    organization: user.organization && user.organization.name
                });
                this.router.events.subscribe(function (event) {
                    if (event instanceof router_1.NavigationEnd) {
                        if (Appcues) {
                            Appcues.page();
                        }
                    }
                });
            }
        }
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
