<div class="mobile-dispatch-carriers">
  <div *ngFor="let carrier of carriers" class="slot" (click)="select(carrier)">
    <div class="info">
      <h3>{{ carrier.name }}</h3>
    </div>
    <div class="actions">
      <i class="icon-next-arrow"></i>
    </div>
  </div>
  <my-loader *ngIf="loading" class="mobile-loader full-height"></my-loader>
</div>
