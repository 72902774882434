import {
  Component, OnInit, Inject, Output, EventEmitter
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';

import { MarketplaceService } from '../marketplace/marketplace.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'publish-job-dialog',
  templateUrl: './publish-job-dialog.component.html',
  styleUrls: ['./publish-job-dialog.component.scss'],
  providers: [MarketplaceService]
})
export class PublishJobDialogComponent implements OnInit {
  job: any = {};
  jobevent: any = {};
  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  callback;
  invitationsReq: Subscription;

  model = {
    data: {
      dates: [],
      shareId: null,
      numTrucks: null,
      haulRate: null
    },
    recipientOrganization: null,
    kind: 'new_job'
  };

  constructor(
    public dialogRef: MatDialogRef<PublishJobDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private marketplaceService: MarketplaceService,
  ) {
    this.job = this.data && this.data.job;
    this.jobevent = this.data && this.data.jobevent;
  }

  ngOnInit() {
    this.model.data.shareId = this.jobevent.shareId;
    this.model.data.haulRate = this.jobevent.haulRate;
  }

  submit(): void {
    this.model.data.dates = this.model.data.dates.map(d => this.convertDateFormat(d));
    this.marketplaceService.publish(this.model).subscribe(result => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
    });
  }

  onDateChanged(values: Date[]): void {
    this.model.data.dates = values;
  }

  convertDateFormat(value: Date): string {
    let d = value && new Date(value);
    if (isNaN(+d)) { return; }
    return d.toISOString().split('T')[0];
  }
}
