import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mobile-search-results',
  templateUrl: './mobile-search-results.component.html',
  styleUrls: ['./mobile-search-results.component.scss'],
})
export class MobileSearchResultsComponent {
  @Input() results: any;
  @Output() activeResult: EventEmitter<any> = new EventEmitter<any>();

  selectActiveResult(result, index) {
    this.activeResult.emit({ result, index });
  }
}
