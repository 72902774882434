"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var surcharge_1 = require("./surcharge");
var invoice_1 = require("../invoices/invoice");
var surcharge_template_serializer_1 = require("../surcharge-templates/surcharge-template.serializer");
var ticket_serializer_1 = require("../tickets/ticket.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var SurchargeSerializer = /** @class */ (function () {
    function SurchargeSerializer() {
    }
    SurchargeSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var surcharge = new surcharge_1.Surcharge();
        if (!json) {
            return surcharge;
        }
        surcharge.id = json.id;
        if (json.invoice && typeof (json.invoice) === 'object') {
            surcharge.invoice = new invoice_1.Invoice(json.invoice);
        }
        else if (json.invoice) {
            surcharge.invoice = new invoice_1.Invoice({ id: json.invoice });
        }
        if (json.scaleticket && typeof (json.scaleticket) === 'object') {
            surcharge.scaleticket = new ticket_serializer_1.TicketSerializer().fromJson(json.scaleticket);
        }
        else if (json.scaleticket) {
            surcharge.scaleticket = new ticket_serializer_1.TicketSerializer().fromJson({ id: json.scaleticket });
        }
        surcharge.title = json.title;
        surcharge.time = json.time;
        surcharge.createdAt = json.createdAt;
        surcharge.description = json.description;
        surcharge.amount = json.amount;
        if (parseFloat(json.amount) >= 0) {
            surcharge.positive = true;
        }
        if (json.kind) {
            surcharge.kind = json.kind;
        }
        if (json.template && typeof (json.template) === 'object') {
            surcharge.template = (new surcharge_template_serializer_1.SurchargeTemplateSerializer().fromJson(json.template));
        }
        else if (json.scaleticket) {
            surcharge.template = (new surcharge_template_serializer_1.SurchargeTemplateSerializer().fromJson({ id: json.template }));
        }
        return surcharge;
    };
    SurchargeSerializer.prototype.toJson = function (surcharge) {
        var invoice = surcharge.invoice;
        var scaleticket = surcharge.scaleticket;
        if (surcharge.scaleticket && typeof (surcharge.scaleticket) === 'object') {
            scaleticket = surcharge.scaleticket.id;
        }
        if (surcharge.invoice && typeof (surcharge.invoice) === 'object') {
            invoice = surcharge.invoice.id;
        }
        var json = {
            id: surcharge.id,
            invoice: invoice,
            scaleticket: scaleticket,
            title: surcharge.title,
            time: surcharge.time,
            createdAt: surcharge.createdAt,
            description: surcharge.description,
            amount: surcharge.amount,
            kind: surcharge.kind
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return SurchargeSerializer;
}());
exports.SurchargeSerializer = SurchargeSerializer;
