import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ShiftFilter } from './shift.filter';
import { requestHeaders, handleError } from '../shared/api.service';

@Injectable()
export class ShiftFilterService {
  baseUrl = environment.serverUrl;
  public nextUris = {};

  constructor(private http: HttpClient) { }

  listFilters(filterType: string, query?: any): Observable<ShiftFilter[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '6');
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    let filtersUrl = this.baseUrl + 'shifts/report/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(filterType, res); }),
      catchError(handleError)
    );
  }

  get(filterType: string, query?: any): Observable<ShiftFilter> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '1');
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    let filtersUrl = this.baseUrl + 'shifts/report/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(filterType, res, false, true); }),
      catchError(handleError)
    );
  }

  getFilters(filterType: string, search: string = null): Observable<ShiftFilter[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '6');
    if (search) {
      params = params.set('search', search);
    }

    let filtersUrl = this.baseUrl + 'shifts/report/' + filterType + '/';

    return this.http.get(filtersUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(filterType, res); }),
      catchError(handleError)
    );
  }

  getNext(filterType: string): Observable<ShiftFilter[]> {
    if (this.nextUris[filterType]) {
      return this.http.get(this.nextUris[filterType], { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(filterType, res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  listNext(filterType: string): Observable<ShiftFilter[]> {
    if (this.nextUris[filterType]) {
      return this.http.get(this.nextUris[filterType], { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(filterType, res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(filterType: string, res: Object, condensed = false, single = false) {
    let resObj = res;
    if (!single) { this.nextUris[filterType] = resObj['next']; }
    let body = resObj['results'];
    if (body) {
      let results = body.map(filter => {
        return new ShiftFilter(filter);
      });
      return single ? results && results[0] : results;
    } else {
      return {};
    }
  }

  private mergeFilters(filters): string {
    return filters.map(filter => {
      if (filter.multiple && filter.values) {
        return filter.values.map(value => {
          const _value = [filter.key, value].join('=');
          return `(${_value})`;
        }).filter(Boolean).join('|');
      } else if (filter.values) {
        let values = filter.values;
        if (values === true) { values = 'True'; }
        if (values === false) { values = 'False'; }
        const _value = [filter.key, values].join('=');
        return `(${_value})`;
      }
    }).filter(Boolean).join('&');
  }
}
