"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".driver-details-page-container[_ngcontent-%COMP%]     .action-bar-content{padding-bottom:0!important;align-items:flex-end!important;box-shadow:none}.driver-details-page-container[_ngcontent-%COMP%]     .action-bar-content .links{margin-bottom:0;border-bottom:none}.driver-details-page-container[_ngcontent-%COMP%]     mat-cell:first-of-type, .driver-details-page-container[_ngcontent-%COMP%]     mat-table mat-header-row mat-header-cell:first-of-type{padding-left:24px!important}.not-found[_ngcontent-%COMP%]{flex-direction:row;align-items:center;display:flex;height:100%;overflow-y:auto}.not-found[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{height:auto;display:flex;flex-direction:column;align-items:center;flex:1 1 auto}.not-found[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%]{font-size:150px;font-weight:900;text-align:center;color:#c8d4da;margin:40px 0 0}.not-found[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{font-size:36px;text-align:center;color:#002649;font-weight:600;margin:0}.not-found[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-size:20px;text-align:center;font-weight:400;color:#b1b3b5;margin-bottom:10px}.not-found[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]   p.actions[_ngcontent-%COMP%]{font-size:20px;font-weight:600;line-height:1.36;text-align:center;margin:0}.not-found[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]   p.actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#015bc5;text-decoration:none}"];
exports.styles = styles;
