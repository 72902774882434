<div class="name">
  <div class="driver-status">
    <span *ngIf="status === 'active' || status === 'inactive'" class="status-icon" [ngClass]="{'active': status === 'active'}"></span>
    <span *ngIf="status === 'nodata'" class="warning-icon"
          matTooltip="{{ 'This Assignment has no Trip data' | translate }}"
          matTooltipPosition="right"
          matTooltipClass="ruckit-tooltip">!</span>
    <i *ngIf="status === 'complete'" class="icon-checkmark"></i>
    <i *ngIf="status === 'warning'" class="icon-clock"
        matTooltip="{{ 'This Driver has been active for over 24 hours' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="ruckit-tooltip"></i>
  </div>
  <span class="displayed-name" (mouseenter)="hover = true" (mouseleave)="hover = false">{{ driver.name }}</span>
  <div class="popover" [ngClass]="{visible: hover}">
    <div class="driver-photo">
      <span *ngIf="!driver.image">{{driver.name[0]}}</span>
      <img *ngIf="driver.image" [src]="driver.jobDetailImage"/>
    </div>

    <div class="details">
      <div class="driver-name">{{ driver.name }}</div>
      <div class="driver-organization">{{ driver.organizationName }}</div>
      <div class="driver-phone" *ngIf="driver.phoneNumber">{{ driver.phoneNumber | telephone }}</div>
      <div class="driver-phone" *ngIf="driver.profile">{{ driver.profile.phoneNumber | telephone }}</div>
      <div class="driver-cdl">{{ driver.cdl }}</div>
    </div>
  </div>
</div>
