import { CondensedTrip } from './condensed-trip';
import { USER } from '../users/mock-user';
import { cloneDeep } from 'lodash';

export let CONDENSEDTRIP: CondensedTrip = <CondensedTrip>{
  id: '00000000-0000-0000-0000-000000000001',
  name: 'Sample Trip',
  loading: false,
  date: '2017-07-27T11:22:49Z',
  job: 'Specialty Hauling Services Export',
  jobDisplayName: 'Specialty Hauling Services Export',
  jobId: '00000000-0000-0000-0000-000000000001',
  jobEventId: '00000000-0000-0000-0000-000000000001',
  phaseCode: '',
  project: 'Specialty Project',
  projectId: '00000000-0000-0000-0000-000000000001',
  customer: 'B\&R Broker Group LLC',
  customerId: '00000000-0000-0000-0000-000000000001',
  customerPhone: '1235551234',
  driver: 'John Doe',
  driverId: '00000000-0000-0000-0000-000000000001',
  truck: 'T-1000',
  truckId: '00000000-0000-0000-0000-000000000001',
  truckType: 'Tri-axle',
  payableTo: 'Specialty Contractors',
  payableToId: '00000000-0000-0000-0000-000000000001',
  payableToPhone: '9875551234',
  carrier: 'Specialty Contractors',
  carrierId: '00000000-0000-0000-0000-000000000001',
  loadingTicketImage: null,
  loadingTicketImageKey: null,
  loadingTicketNumber: '1234',
  loadingWeight: '100',
  loadingSignature: null,
  loadingSignatureKey: null,
  loadingAttachments: [],
  unloadingTicketImage: null,
  unloadingTicketImageKey: null,
  unloadingTicketNumber: '1234',
  unloadingWeight: '100',
  unloadingSignature: null,
  unloadingSignatureKey: null,
  unloadingAttachments: [],
  startTimeTimestamp: '2017-07-27T11:22:49Z',
  endTimeTimestamp: '2017-07-27T11:24:32Z',
  _startTimeTimestamp: '2017-07-27T11:22:49Z',
  _endTimeTimestamp: '2017-07-27T11:24:32Z',
  connexPaverStartUnloading: null,
  connexPaverEndUnloading: null,
  invoiceRate: '100.00',
  invoiceType: 'load',
  invoiceWeightUnit: 'ton',
  invoiceQuantity: '1',
  invoiceTotal: '100.00',
  haulRate: '100.00',
  haulType: 'load',
  haulWeightUnit: 'ton',
  route: null,
  checkinOptions: {
    ticketNumber: false,
    weight: false,
    ticketImage: false,
    signatureImage: false
  },
  checkoutOptions: {
    ticketNumber: false,
    weight: false,
    ticketImage: false,
    signatureImage: false
  },
  retakeStatus: null,
  edited: true,
  completed: true,
  tripStatus: null,
  reviewedStatus: 'approved',
  reviewedBy: USER,
  reviewedAt: '2017-07-27T11:30:32Z',
  selected: false,
  completedTripTime: '10 mins',
  _completedTripTime: '10 mins',
  startTime: '10:00 am',
  endTime: '2:00 pm',
  _startTime: '10:00 am',
  _endTime: '2:00 pm',
  startDate: new Date(),
  endDate: new Date(),
  _startDate: new Date(),
  _endDate: new Date(),
  schedule: '10:00 am - 2:00 pm',
  signatureImagesRequired: false,
  hasSignatureImages: false,
  loadingSignatureImagesRequired: false,
  hasLoadingSignatureImages: false,
  inlineLoadingSignature: null,
  unloadingSignatureImagesRequired: false,
  hasUnloadingSignatureImages: false,
  inlineUnloadingSignature: null,
  inlineUnloadingTicket: null,
  ticketImagesRequired: false,
  inlineLoadingTicket: null,
  inlineUnloadingTicker: null,
  hasTicketImages: false,
  hasBothTickets: false,
  loadingTicketImagesRequired: false,
  hasLoadingTicketImages: false,
  unloadingTicketImagesRequired: false,
  hasUnloadingTicketImages: false,
  administrativeStatus: 'edited',
  displayableStatus: true,
  retakeRequested: false,
  ticketNumbers: '1234, 1234',
  truckName: 'T-1000 - Tri-axle',
  invoiceTypeName: 'load',
  haulTypeName: 'load',
  invoiceRateUnit: 'load',
  haulRateUnit: 'load',
  routePolyline: null,
  formatDates: null,
  haulTotal: '0.00',
  void: false,
  expenseId: null,
  expenseNumber: null,
  expenseSent: false,
  paystubId: null,
  paystubNumber: null,
  paystubSent: false,
  invoiceId: null,
  invoiceNumber: null,
  invoiceSent: false,
  invoiceTitle: null,
  expenseTitle: null,
  expenseTotal: '',
  paystubTitle: null,
  reviewerNotes: null,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  },
  _duration: null,
  duration: null,
  weightRequired: null,
  jobDate: '2018-02-15',
  mapImageURL: null,
  fullsizeMapImageURL: null,
  customDriver: null,
  driverPhone: null,
  truckImage: null,
  driverImage: null,
  weight: '100',
  ticketNumber: '1234',
  timezone: 'America/Chicago',
  origin: '',
  _loadingCompleteDatetime: null,
  _unloadingCompleteDatetime: null,
  loadingCompleteDatetime: '',
  unloadingCompleteDatetime: '',
  invoiceWeightUnitDisplay: '',
  haulWeightUnitDisplay: '',
  loadingCheckin: null,
  unloadingCheckin: null,
  loadingArrivalDatetime: '',
  unloadingArrivalDatetime: '',
  _loadingArrivalDatetime: '',
  _unloadingArrivalDatetime: '',
  localizedStartTime: '',
  localizedStartDatetime: '',
  localizedStartDate: '',
  localizedEndTime: '',
  localizedEndDatetime: '',
  localizedEndDate: '',
  plantQc: 0.00,
  plantQcReviewer: cloneDeep(USER),
  plantQcWasteAmt: 0.00,
  plantVt: 0.00,
  plantVtReviewer: cloneDeep(USER),
  plantVtWasteAmt: 0.00,
  roadwayQc: 0.00,
  roadwayQcReviewer: cloneDeep(USER),
  roadwayQcWasteAmt: 0.00,
  roadwayVt: 0.00,
  roadwayVtReviewer: cloneDeep(USER),
  roadwayVtWasteAmt: 0.00,
  startLocationId: '',
  startLocationName: '',
  endLocationId: '',
  endLocationName: '',
  geoStartLocationId: '',
  geoStartLocationName: '',
  geoEndLocationId: '',
  geoEndLocationName: '',
  pluralizedHaulRateUnit: (plural = true) => {
    return '';
  },
  pluralizedHaulWeightUnitDisplay: (plural = true) => {
    return '';
  },
  pluralizedInvoiceRateUnit: (plural = true) => {
    return '';
  },
  pluralizedInvoiceWeightUnitDisplay: (plural = true) => {
    return '';
  }
};

export let CONDENSEDTRIPORDER1: CondensedTrip = <CondensedTrip>{
  id: '00000000-0000-0000-0000-000000000001',
  name: 'Sample Trip',
  loading: false,
  date: '2017-07-27T11:22:49Z',
  job: 'Specialty Hauling Services Export',
  jobDisplayName: 'Specialty Hauling Services Export',
  jobId: '00000000-0000-0000-0000-000000000001',
  jobEventId: '00000000-0000-0000-0000-000000000001',
  phaseCode: '',
  project: 'Specialty Project',
  projectId: '00000000-0000-0000-0000-000000000001',
  customer: 'B\&R Broker Group LLC',
  customerId: '00000000-0000-0000-0000-000000000001',
  customerPhone: '1235551234',
  driver: 'John Doe',
  driverId: '0001',
  truck: 'T-1000',
  truckId: '00000000-0000-0000-0000-000000000001',
  truckType: 'Tri-axle',
  payableTo: 'Specialty Contractors',
  payableToId: '00000000-0000-0000-0000-000000000001',
  payableToPhone: '9875551234',
  carrier: 'Specialty Contractors',
  carrierId: '00000000-0000-0000-0000-000000000001',
  loadingTicketImage: null,
  loadingTicketImageKey: null,
  loadingTicketNumber: '1234',
  loadingWeight: '100',
  loadingSignature: null,
  loadingSignatureKey: null,
  loadingAttachments: [],
  unloadingTicketImage: null,
  unloadingTicketImageKey: null,
  unloadingTicketNumber: '1234',
  unloadingWeight: '100',
  unloadingSignature: null,
  unloadingSignatureKey: null,
  unloadingAttachments: [],
  startTimeTimestamp: '2017-07-27T11:22:49Z',
  endTimeTimestamp: '2017-07-27T11:24:32Z',
  _startTimeTimestamp: '2017-07-27T11:22:49Z',
  _endTimeTimestamp: '2017-07-27T11:24:32Z',
  connexPaverStartUnloading: null,
  connexPaverEndUnloading: null,
  invoiceRate: '100.00',
  invoiceType: 'load',
  invoiceWeightUnit: 'ton',
  invoiceQuantity: '1',
  invoiceTotal: '100.00',
  haulRate: '100.00',
  haulType: 'load',
  haulWeightUnit: 'ton',
  route: null,
  checkinOptions: {
    ticketNumber: false,
    weight: false,
    ticketImage: false,
    signatureImage: false
  },
  checkoutOptions: {
    ticketNumber: false,
    weight: false,
    ticketImage: false,
    signatureImage: false
  },
  retakeStatus: null,
  edited: true,
  completed: true,
  tripStatus: null,
  reviewedStatus: 'approved',
  reviewedBy: USER,
  reviewedAt: '2017-07-27T11:30:32Z',
  selected: false,
  completedTripTime: '10 mins',
  _completedTripTime: '10 mins',
  startTime: '10:00 am',
  endTime: '2:00 pm',
  _startTime: '10:00 am',
  _endTime: '2:00 pm',
  startDate: new Date(),
  endDate: new Date(),
  _startDate: new Date(),
  _endDate: new Date(),
  schedule: '10:00 am - 2:00 pm',
  signatureImagesRequired: false,
  hasSignatureImages: false,
  loadingSignatureImagesRequired: false,
  hasLoadingSignatureImages: false,
  inlineLoadingSignature: null,
  unloadingSignatureImagesRequired: false,
  hasUnloadingSignatureImages: false,
  inlineUnloadingSignature: null,
  inlineUnloadingTicket: null,
  ticketImagesRequired: false,
  inlineLoadingTicket: null,
  inlineUnloadingTicker: null,
  hasTicketImages: false,
  hasBothTickets: false,
  loadingTicketImagesRequired: false,
  hasLoadingTicketImages: false,
  unloadingTicketImagesRequired: false,
  hasUnloadingTicketImages: false,
  administrativeStatus: 'edited',
  displayableStatus: true,
  retakeRequested: false,
  ticketNumbers: '1234, 1234',
  truckName: 'T-1000 - Tri-axle',
  invoiceTypeName: 'load',
  haulTypeName: 'load',
  invoiceRateUnit: 'load',
  haulRateUnit: 'load',
  routePolyline: null,
  formatDates: null,
  haulTotal: '0.00',
  void: false,
  expenseId: null,
  expenseNumber: null,
  expenseSent: false,
  paystubId: null,
  paystubNumber: null,
  paystubSent: false,
  invoiceId: null,
  invoiceNumber: null,
  invoiceSent: false,
  invoiceTitle: null,
  expenseTitle: null,
  expenseTotal: '',
  paystubTitle: null,
  reviewerNotes: null,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  },
  _duration: null,
  duration: null,
  weightRequired: null,
  jobDate: '2018-02-15',
  mapImageURL: null,
  fullsizeMapImageURL: null,
  customDriver: null,
  driverPhone: null,
  truckImage: null,
  driverImage: null,
  weight: '100',
  ticketNumber: '1234',
  timezone: 'America/Chicago',
  origin: '',
  _loadingCompleteDatetime: null,
  _unloadingCompleteDatetime: null,
  loadingCompleteDatetime: '',
  unloadingCompleteDatetime: '',
  invoiceWeightUnitDisplay: '',
  haulWeightUnitDisplay: '',
  loadingCheckin: null,
  unloadingCheckin: null,
  loadingArrivalDatetime: '',
  unloadingArrivalDatetime: '',
  _loadingArrivalDatetime: '',
  _unloadingArrivalDatetime: '',
  localizedStartTime: '',
  localizedStartDatetime: '',
  localizedStartDate: '',
  localizedEndTime: '',
  localizedEndDatetime: '',
  localizedEndDate: '',
  plantQc: 0.00,
  plantQcReviewer: cloneDeep(USER),
  plantQcWasteAmt: 0.00,
  plantVt: 0.00,
  plantVtReviewer: cloneDeep(USER),
  plantVtWasteAmt: 0.00,
  roadwayQc: 0.00,
  roadwayQcReviewer: cloneDeep(USER),
  roadwayQcWasteAmt: 0.00,
  roadwayVt: 0.00,
  roadwayVtReviewer: cloneDeep(USER),
  roadwayVtWasteAmt: 0.00,
  startLocationId: '',
  startLocationName: '',
  endLocationId: '',
  endLocationName: '',
  geoStartLocationId: '',
  geoStartLocationName: '',
  geoEndLocationId: '',
  geoEndLocationName: '',
  pluralizedHaulRateUnit: (plural = true) => {
    return '';
  },
  pluralizedHaulWeightUnitDisplay: (plural = true) => {
    return '';
  },
  pluralizedInvoiceRateUnit: (plural = true) => {
    return '';
  },
  pluralizedInvoiceWeightUnitDisplay: (plural = true) => {
    return '';
  }
};

export let CONDENSEDTRIPORDER2: CondensedTrip = <CondensedTrip>{
  id: '00000000-0000-0000-0000-000000000001',
  name: 'Sample Trip',
  loading: false,
  date: '2017-07-27T11:22:49Z',
  job: 'Specialty Hauling Services Export',
  jobDisplayName: 'Specialty Hauling Services Export',
  jobId: '00000000-0000-0000-0000-000000000001',
  jobEventId: '00000000-0000-0000-0000-000000000001',
  phaseCode: '',
  project: 'Specialty Project',
  projectId: '00000000-0000-0000-0000-000000000001',
  customer: 'B\&R Broker Group LLC',
  customerId: '00000000-0000-0000-0000-000000000001',
  customerPhone: '1235551234',
  driver: 'Jane Doe',
  driverId: '0002',
  truck: 'T-X',
  truckId: '00000000-0000-0000-0000-000000000001',
  truckType: 'Tri-axle',
  payableTo: 'Specialty Contractors',
  payableToId: '00000000-0000-0000-0000-000000000001',
  payableToPhone: '9875551234',
  carrier: 'Specialty Contractors',
  carrierId: '00000000-0000-0000-0000-000000000001',
  loadingTicketImage: null,
  loadingTicketImageKey: null,
  loadingTicketNumber: '1234',
  loadingWeight: '100',
  loadingSignature: null,
  loadingSignatureKey: null,
  loadingAttachments: [],
  unloadingTicketImage: null,
  unloadingTicketImageKey: null,
  unloadingTicketNumber: '1234',
  unloadingWeight: '100',
  unloadingSignature: null,
  unloadingSignatureKey: null,
  unloadingAttachments: [],
  startTimeTimestamp: '2017-07-27T11:22:49Z',
  endTimeTimestamp: '2017-07-27T11:24:32Z',
  _startTimeTimestamp: '2017-07-27T11:22:49Z',
  _endTimeTimestamp: '2017-07-27T11:24:32Z',
  connexPaverStartUnloading: null,
  connexPaverEndUnloading: null,
  invoiceRate: '100.00',
  invoiceType: 'load',
  invoiceWeightUnit: 'ton',
  invoiceQuantity: '1',
  invoiceTotal: '100.00',
  haulRate: '100.00',
  haulType: 'load',
  haulWeightUnit: 'ton',
  route: null,
  checkinOptions: {
    ticketNumber: false,
    weight: false,
    ticketImage: false,
    signatureImage: false
  },
  checkoutOptions: {
    ticketNumber: false,
    weight: false,
    ticketImage: false,
    signatureImage: false
  },
  retakeStatus: null,
  edited: true,
  completed: true,
  tripStatus: null,
  reviewedStatus: 'rejected',
  reviewedBy: USER,
  reviewedAt: '2017-07-27T11:30:32Z',
  selected: false,
  completedTripTime: '10 mins',
  _completedTripTime: '10 mins',
  startTime: '10:00 am',
  endTime: '2:00 pm',
  _startTime: '10:00 am',
  _endTime: '2:00 pm',
  startDate: new Date(),
  endDate: new Date(),
  _startDate: new Date(),
  _endDate: new Date(),
  schedule: '10:00 am - 2:00 pm',
  signatureImagesRequired: false,
  hasSignatureImages: false,
  loadingSignatureImagesRequired: false,
  hasLoadingSignatureImages: false,
  inlineLoadingSignature: null,
  unloadingSignatureImagesRequired: false,
  hasUnloadingSignatureImages: false,
  inlineUnloadingSignature: null,
  inlineUnloadingTicket: null,
  ticketImagesRequired: false,
  inlineLoadingTicket: null,
  inlineUnloadingTicker: null,
  hasTicketImages: false,
  hasBothTickets: false,
  loadingTicketImagesRequired: false,
  hasLoadingTicketImages: false,
  unloadingTicketImagesRequired: false,
  hasUnloadingTicketImages: false,
  administrativeStatus: 'edited',
  displayableStatus: true,
  retakeRequested: false,
  ticketNumbers: '1234, 1234',
  truckName: 'T-X - Tri-axle',
  invoiceTypeName: 'load',
  haulTypeName: 'load',
  invoiceRateUnit: 'load',
  haulRateUnit: 'load',
  routePolyline: null,
  formatDates: null,
  haulTotal: '0.00',
  void: false,
  expenseId: null,
  expenseNumber: null,
  expenseSent: false,
  paystubId: null,
  paystubNumber: null,
  paystubSent: false,
  invoiceId: null,
  invoiceNumber: null,
  invoiceSent: false,
  invoiceTitle: null,
  expenseTitle: null,
  expenseTotal: '',
  paystubTitle: null,
  reviewerNotes: null,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  },
  _duration: null,
  duration: null,
  weightRequired: null,
  jobDate: '2018-02-15',
  mapImageURL: null,
  fullsizeMapImageURL: null,
  customDriver: null,
  driverPhone: null,
  truckImage: null,
  driverImage: null,
  weight: '100',
  ticketNumber: '1234',
  timezone: 'America/Chicago',
  origin: '',
  _loadingCompleteDatetime: null,
  _unloadingCompleteDatetime: null,
  loadingCompleteDatetime: '',
  unloadingCompleteDatetime: '',
  invoiceWeightUnitDisplay: '',
  haulWeightUnitDisplay: '',
  loadingCheckin: null,
  unloadingCheckin: null,
  loadingArrivalDatetime: '',
  unloadingArrivalDatetime: '',
  _loadingArrivalDatetime: '',
  _unloadingArrivalDatetime: '',
  localizedStartTime: '',
  localizedStartDatetime: '',
  localizedStartDate: '',
  localizedEndTime: '',
  localizedEndDatetime: '',
  localizedEndDate: '',
  plantQc: 0.00,
  plantQcReviewer: cloneDeep(USER),
  plantQcWasteAmt: 0.00,
  plantVt: 0.00,
  plantVtReviewer: cloneDeep(USER),
  plantVtWasteAmt: 0.00,
  roadwayQc: 0.00,
  roadwayQcReviewer: cloneDeep(USER),
  roadwayQcWasteAmt: 0.00,
  roadwayVt: 0.00,
  roadwayVtReviewer: cloneDeep(USER),
  roadwayVtWasteAmt: 0.00,
  startLocationId: '',
  startLocationName: '',
  endLocationId: '',
  endLocationName: '',
  geoStartLocationId: '',
  geoStartLocationName: '',
  geoEndLocationId: '',
  geoEndLocationName: '',
  pluralizedHaulRateUnit: (plural = true) => {
    return '';
  },
  pluralizedHaulWeightUnitDisplay: (plural = true) => {
    return '';
  },
  pluralizedInvoiceRateUnit: (plural = true) => {
    return '';
  },
  pluralizedInvoiceWeightUnitDisplay: (plural = true) => {
    return '';
  }
};
