"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var notes_service_1 = require("./notes.service");
var NotesComponent = /** @class */ (function () {
    function NotesComponent(notesService) {
        this.notesService = notesService;
        this.name = 'notes';
        this.disabled = false;
        this.saveOnFocusOut = true;
    }
    NotesComponent.prototype.onFocusOut = function () {
        var _this = this;
        if (this.saveOnFocusOut) {
            if (this.note && this.note.id) {
                if (this.note.text) {
                    this.notesService
                        .patchNote(this.note.id, { text: this.note.text })
                        .subscribe();
                }
                else {
                    this.notesService
                        .deleteNote(this.note.id)
                        .subscribe(function () { return delete _this.note.id; });
                }
            }
            else {
                this.notesService
                    .saveNote(__assign({}, this.note, this.saveProperty))
                    .subscribe(function (createdNote) { return (_this.note = createdNote); });
            }
        }
    };
    return NotesComponent;
}());
exports.NotesComponent = NotesComponent;
