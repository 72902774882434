"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var job_service_1 = require("../jobs/job.service");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("../jobs/job.service");
var DispatchGuard = /** @class */ (function () {
    function DispatchGuard(router, jobService) {
        this.router = router;
        this.jobService = jobService;
        this.errors = [];
    }
    DispatchGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.jobReq = this.jobService.get(route.params.jobId).subscribe(function (job) {
            _this.job = job;
            if (currentUser && currentUser.organization && _this.job && _this.job.project && _this.job.project.customerOrganization) {
                if (currentUser.organization.id === _this.job.project.customerOrganization.id) {
                    _this.router.navigateByUrl('/jobs/daily');
                    return false;
                }
            }
        });
        return true;
    };
    DispatchGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DispatchGuard_Factory() { return new DispatchGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.JobService)); }, token: DispatchGuard, providedIn: "root" });
    return DispatchGuard;
}());
exports.DispatchGuard = DispatchGuard;
