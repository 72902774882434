import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/api.service';
import { UserSerializer } from '../users/user.serializer';
import { Observable } from 'rxjs';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class DispatchService {
  baseUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  save(options): Observable<any> {
    return this.http.post(
      this.baseUrl + 'jobevents/' + options.jobEvent + '/dispatch/',
      decamelizeKeysDeep(options),
      {headers: requestHeaders()}
    ).pipe(
      map((res) => {
        let dispatch = camelcaseKeysDeep(res);
        dispatch.lastDispatchedBy = (new UserSerializer).fromJson(dispatch.lastDispatchedBy);
        return dispatch;
      }),
      catchError(handleError)
    );
  }
}
