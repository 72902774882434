import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TrackableType } from './trackable-type';
import { TrackableTypeSerializer } from './trackable-type.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class TrackableTypeService extends ResourceService<TrackableType> {
  constructor(http: HttpClient) {
    super(http, 'trackabletypes/', new TrackableTypeSerializer());
  }
}

