import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { JobEvent } from '../job-events/job-event';

@Component({
  selector: 'mobile-dispatch-shifts',
  templateUrl: './mobile-dispatch-shifts.component.html',
  styleUrls: ['./mobile-dispatch-shifts.component.scss']
})
export class MobileDispatchShiftsComponent implements OnInit {
  @Input() jobEvent: JobEvent;
  @Output() selectShift: EventEmitter<number> = new EventEmitter<number>();
  shifts = [{ slots: [] }];

  ngOnInit() {
    this.shifts = [{ slots: [] }];
    if (this.jobEvent && this.jobEvent.shift2StartTimestamp) {
      this.shifts.push({ slots: [] });
    }
  }

  select(shiftIndex): void {
    this.selectShift.emit(shiftIndex);
  }
}
