export const DEFAULT_DATETIMEPICKERINLINE_CONFIG: DateTimePickerInlineConfig = {
  DateFormat: 'MMM D, YYYY',
  TimeFormat: 'h:mm a',
  Readonly: false
};

export interface DateTimePickerInlineConfig {
  DateFormat?: string;
  TimeFormat?: string;
  Readonly?: boolean;
  AbsoluteMax?: string;
  AbsoluteMin?: string;
}
