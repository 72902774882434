<form #messageDrivers="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Message Today's Drivers</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="msg" fxFlex="100">
        <textarea required
                  name="msg"
                  placeholder="Type a message..."
                  [disabled]="messageSent"
                  [(ngModel)]="message.msg"></textarea>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            [ngClass]="{loading: loading}"
            (click)="submit(); false"
            [disabled]="loading || !messageDrivers.form.valid">
      {{ buttonLabel }}
    </button>
  </div>
</form>
