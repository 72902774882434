export class ControlOption {
  id: string;
  name: string;
  label: string;
  classes = '';
  selected: boolean;
  disabled: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.label = data.label;
    this.classes = data.classes;
    this.disabled = data.disabled || false;
    this.selected = data.selected || false;
  }
}
