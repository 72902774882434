"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var truck_service_1 = require("./truck.service");
var index_1 = require("../shared/dialogs/index");
var api_service_1 = require("../shared/api.service");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var new_truck_dialog_component_1 = require("./new-truck-dialog.component");
var shared_1 = require("../shared");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var filters_dialog_component_1 = require("../shared/filters-dialog/filters-dialog.component");
var tag_service_1 = require("../tags/tag.service");
var truck_type_service_1 = require("./truck-type.service");
var TrucksComponent = /** @class */ (function () {
    function TrucksComponent(route, router, dialog, truckService, authenticationService, cdr, translationService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.truckService = truckService;
        this.authenticationService = authenticationService;
        this.cdr = cdr;
        this.translationService = translationService;
        this.view = 'list';
        this.viewArchive = new core_1.EventEmitter();
        this.loadingProgress = 0;
        this.displayKeys = ['name', 'displayName'];
        this.groupByOptions = ['Carrier', 'Truck Type', 'Market', 'Condition'];
        this.activeGroupBy = 'Truck Type';
        // DEV NOTE: removing this only for now until BE supports this capability
        // gridLabels: TimelineLabel[] = [
        //   {
        //     name: 'Driver Assigned',
        //     color: '#015BC5'
        //   },
        //   {
        //     name: 'No Driver Assigned',
        //     color: '#ffffff'
        //   },
        //   {
        //     name: 'Out of Service',
        //     color: 'rgba(208, 2, 27, 0.15)'
        //   }
        // ];
        this.gridLabels = [
            {
                name: 'In Service',
                color: '#ffffff'
            },
            {
                name: 'Out of Service',
                color: 'rgba(208, 2, 27, 0.15)'
            }
        ];
        this.availableColumns = [
            { key: 'select' },
            { key: 'truck-type', title: this.translationService.instant('Truck Type'), sortable: true, sortBy: 'truck_type__name' },
            { key: 'name', title: this.translationService.instant('Truck #'), sortable: true },
            { key: 'license-plate', title: this.translationService.instant('License Plate'), sortable: true },
            { key: 'carrier', title: this.translationService.instant('Fleet'), sortable: true, sortBy: 'carrier_organization_name' },
            { key: 'markets', title: this.translationService.instant('Markets') },
            { key: 'created-by', title: this.translationService.instant('Created by') },
            { key: 'connex-container', title: this.translationService.instant('Connex container') },
            { key: 'serviceStatus', title: this.translationService.instant('Condition') },
            { key: 'deleted', title: this.translationService.instant('Deleted') },
            { key: 'action', title: this.translationService.instant('Action') },
        ];
        this.displayedColumns = [
            'select', 'truck-type', 'name', 'license-plate', 'markets', 'created-by', 'connex-container', 'serviceStatus', 'action'
        ];
        this.appliedFilters = [];
        this.search = '';
        this.query = {};
        this.customClasses = 'trucks';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            service: truck_service_1.TruckService,
            preferenceKey: 'TrucksComponent-TruckService',
            filterQuery: false,
            query: {},
            collectionTitle: this.translationService.instant('Truck'),
            noResultsText: this.translationService.instant('a truck'),
            newRecordModal: function () { _this.addTruck(); },
            sortBy: 'name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
            ]
        };
        this.filters = [];
        this.loading = true;
        this.type = 'all';
        this.allSubscriptionsToUnsubscribe = [];
        this.saveTruckCallback = function () {
            _this.trucksTable.getRecords();
        };
    }
    /**
     * Parses user settings to set user tags as part of the table query.
     * Subs to any updates in the listAllProgress subject in the truck service
     */
    TrucksComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = __assign({}, this.query);
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.tableConfig['query'] = this.query = __assign({}, this.tableConfig['query'], query);
        this.allSubscriptionsToUnsubscribe.push(this.truckService.listAllProgress.subscribe(function (progress) {
            _this.loadingProgress = Math.ceil(progress * 100);
        }));
    };
    /**
     * Parses the url params and queries to set values used by the table and grid
     */
    TrucksComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig['customHeight'] = this.customHeight;
        if (this.route && this.route.queryParams) {
            this.route.queryParams.forEach(function (params) {
                _this.loading = true;
                _this.type = params['type'];
                _this.search = params['search'] || '';
            });
        }
        this.cdr.detectChanges();
    };
    TrucksComponent.prototype.ngOnChanges = function (changes) {
        if (this.trucksTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
            this.trucksTable.query = this.query;
            this.refreshTable();
            if (this.query['status']) {
                this.archive = !!(this.query['status'] === 'deleted');
            }
            else {
                this.archive = false;
            }
        }
    };
    TrucksComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * @param  {} event
     * This function would get called on the click of row of the table
     * On click of row it will open the edit drawer for the selected truck.
     */
    TrucksComponent.prototype.clickAction = function (event) {
        var _this = this;
        if (event) {
            if (this.editDrawer && this.editDrawer.truckForm && !this.editDrawer.truckForm.form.pristine) {
                this.editDrawer.confirmBeforeClosing().subscribe(function (dialogResult) {
                    if (dialogResult) {
                        _this.editDrawer.submit();
                    }
                    else {
                        _this.editDrawer.close();
                        _this.selectTruck(event, event[1]);
                    }
                });
            }
            else {
                this.editDrawer.close();
                this.selectTruck(event, event[1]);
            }
        }
    };
    /**
     * @param  {} action
     * @param  {} truck
     * Check the action to be performed and call the corresponding function.
     * other functions like export can be added here in future.
     */
    TrucksComponent.prototype.menuAction = function (action, truck) {
        switch (action) {
            case 'edit':
                this.selectTruck(null, truck);
                break;
            case 'remove':
                this.removeTruck(truck);
                break;
            case 'unarchive':
                this.unarchive(truck);
                break;
        }
    };
    /**
     * @param  {} e
     * @param  {} truck
     * This function would open a edit window for updating or removing
     * the truck
     */
    TrucksComponent.prototype.selectTruck = function (e, truck) {
        var _this = this;
        var target = e && (e.target || e.srcElement || e.currentTarget);
        if (target && target.className && target.className.includes('action-menu-icon') ||
            target && target.type === 'checkbox') {
            // Do nothing
        }
        else {
            // if click action is performed on the opened truck then editor will be closed.
            if (truck && this.truck && this.truck.id === truck.id) {
                this.truck = null;
            }
            else {
                setTimeout(function () {
                    _this.truck = truck;
                    _this.editDrawer.setOpen();
                    _this.editDrawer.afterEdit = _this.saveTruckCallback;
                }, 100);
            }
        }
    };
    TrucksComponent.prototype.handleGridSelection = function (truckIds) {
        if (truckIds[0]) {
            var matchedTruck = this.truckList.find(function (t) { return (t.id === truckIds[0]); });
            this.selectTruck({}, matchedTruck);
        }
        else {
            this.editDrawer.close();
        }
    };
    TrucksComponent.prototype.unarchive = function (truck) {
        var _this = this;
        this.truckService.save({ id: truck.id, status: 'active' }).subscribe(function () {
            _this.refreshTable();
        });
    };
    /**
     * @param  {} truck
     * Removes the selected truck and refresh the table with new data
     * Opens the remove confirm dialog
     */
    TrucksComponent.prototype.removeTruck = function (truck) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Truck?'),
            body: this.translationService.instant('This truck will be deleted and placed in the archived section where you can reactivate at a later time.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.truckService.remove(truck).subscribe(function (res) {
                    _this.refreshTable();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    TrucksComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        params['type'] = params['type'] ? params['type'] : this.type;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    /**
     * opens a page for adding new price list
     */
    TrucksComponent.prototype.addTruck = function () {
        var dialog = this.dialog.open(new_truck_dialog_component_1.NewTruckDialogComponent, {
            width: '430px',
            height: '800px',
        });
        if (dialog && dialog.componentInstance) {
            if (this.carrierId) {
                dialog.componentInstance.carrierId = this.carrierId;
            }
            dialog.componentInstance.callback = this.saveTruckCallback;
        }
    };
    /**
     * refresh/reload the trucks table on any action performed like
     * add, edit, remove
     */
    TrucksComponent.prototype.refreshTable = function () {
        if (this.trucksTable) {
            var query = {};
            if (this.authenticationService.hasFavoriteTags()) {
                query['user_tags'] = 'True';
            }
            this.trucksTable.getRecords(__assign({}, this.tableConfig['query'], this.query, query));
        }
        else {
            var query = __assign({}, this.tableConfig['query'], this.query);
            if (this.authenticationService.hasFavoriteTags()) {
                query['user_tags'] = 'True';
            }
            if (this.view === 'grid') {
                this.getFullTruckList(query);
            }
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    TrucksComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    TrucksComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.filters = [
            {
                type: 'dropdown',
                field: 'tags',
                label: 'Markets',
                dropdownConfig: {
                    service: tag_service_1.TagService,
                    multiselect: true
                }
            }, {
                type: 'dropdown',
                field: 'truckType',
                label: 'Truck Type',
                dropdownConfig: {
                    service: truck_type_service_1.TruckTypeService
                }
            },
            {
                type: 'text',
                field: 'licensePlate',
                label: 'License Plate Search'
            }
        ];
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    TrucksComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            truckType: 'truck_type',
            tags: 'tags',
            licensePlate: 'license_plate'
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (filterRes[key]) {
                if (key === 'tags') {
                    if (values && values.length > 0) {
                        values = values.map(function (tag) { return tag.name; }).join(',');
                        query[queryKeys[key]] = values;
                    }
                }
                else {
                    query[queryKeys[key]] = filterRes[key] && filterRes[key].id || values;
                }
            }
            var filter = new filter_option_1.FilterOption({
                filterType: 'text',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (!filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    /**
    * This function is called when edit is successfully completed
    * This would update the table with the changed fields
    *
    * @returns {void}
    */
    TrucksComponent.prototype.onEditComplete = function (modifiedTruck) {
        if (this.view === 'list') {
            this.trucksTable.updateTable(modifiedTruck);
        }
        else {
            this.truckList = this.truckList.map(function (t) { return (t.id === modifiedTruck.id ?
                Object.assign(t, modifiedTruck) : t); });
            this.setupItemList(this.truckList, this.activeGroupBy);
        }
    };
    /**
     * Switched the UI view and then triggers the truck list setup if no list is currently set up
     *
     * @param {'list' | 'grid'} view The selected view
     */
    TrucksComponent.prototype.switchView = function (view) {
        var query = __assign({}, this.tableConfig.query, this.query);
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
            this.tableConfig.query = query;
        }
        if (this.view === 'list' && view === 'grid') {
            this.view = view;
        }
        else if (this.view === 'grid' && view === 'list') {
            this.truckGroups = undefined;
            this.view = view;
        }
        if (view === 'grid' && !this.truckGroups) {
            this.getFullTruckList(query);
        }
    };
    /**
     * Fetches the full truck list based on the same query as the truck table
     *
     * @param {any} query The query to be appended to the full list request
     */
    TrucksComponent.prototype.getFullTruckList = function (query) {
        var _this = this;
        this.truckService.listAll(25, query).subscribe(function (trucks) {
            _this.truckList = trucks;
            _this.setupItemList(_this.truckList, _this.activeGroupBy);
        });
    };
    /**
     * Sets up the truck groups based on the specified groupBy param
     *
     * @param {Truck[]} trucks The truck list to be grouped
     * @param {'Carrier' | 'Truck Type' | 'Condition' | 'Market'} groupBy The selected groupBy option
     */
    TrucksComponent.prototype.setupItemList = function (trucks, groupBy) {
        this.activeGroupBy = groupBy;
        switch (groupBy) {
            case 'Carrier':
                this.truckGroups = Array.from(new Set(trucks.map(function (t) { return ({
                    id: t.carrierOrganizationId,
                    name: t.carrierOrganizationName,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: trucks.filter(function (truck) { return (truck.carrierOrganizationId === group.id); })
                })); });
                break;
            case 'Market':
                var marketGroups_1 = [];
                trucks.forEach(function (t) {
                    if (t.tags && t.tags.length) {
                        t.tags.forEach(function (tag) {
                            marketGroups_1.push({
                                id: tag.id,
                                name: tag.name,
                                groupBy: groupBy,
                                items: []
                            });
                        });
                    }
                    else {
                        marketGroups_1.push({
                            id: '',
                            groupBy: groupBy,
                            items: []
                        });
                    }
                });
                this.truckGroups = marketGroups_1.filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: trucks.filter(function (truck) { return (group.id === '' ? (!truck.tags || truck.tags.length === 0) :
                        truck.tags.map(function (t) { return (t.id); }).join(' ').includes(group.id)); })
                })); });
                break;
            case 'Truck Type':
                this.truckGroups = Array.from(new Set(trucks.map(function (t) { return ({
                    name: t.truckType && t.truckType.name,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: trucks.filter(function (truck) { return ((truck.truckType && truck.truckType.name) === group.name); })
                })); });
                break;
            case 'Condition':
                this.truckGroups = Array.from(new Set(trucks.map(function (t) { return ({
                    id: t.serviceStatus,
                    name: t.displayServiceStatus,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: trucks.filter(function (truck) { return (group.id === truck.serviceStatus); })
                })); });
                break;
        }
        this.truckGroups = this.truckGroups.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
    };
    TrucksComponent.prototype.onDataLoaded = function () {
        var query = __assign({}, this.tableConfig.query, this.query);
        if (this.view === 'grid') {
            this.getFullTruckList(query);
        }
    };
    /**
     * Selects a new groupBy option and re-triggers the truck grid setup based on that option
     *
     * @param {'Carrier' | 'Truck Type' | 'Condition'} groupBy The selected groupBy option
     */
    TrucksComponent.prototype.selectGroupBy = function (groupBy) {
        this.setupItemList(this.truckList, groupBy);
    };
    /**
     * Returns a set of class names in a single string to be appended to item elements in the item grid
     *
     * @param {Truck} truck The truck object
     * @returns {string} The class names to be appended to item elements
     */
    TrucksComponent.prototype.generateItemClassNames = function (truck) {
        var classNames = '';
        if (truck.serviceStatus === 'out-of-service') {
            classNames += 'red ';
        }
        // DEV NOTE: removing this only for now until BE supports this capability
        // } else if (truck.driver && truck.driver.id) {
        //   classNames += 'blue ';
        // }
        return classNames;
    };
    TrucksComponent.prototype.onClose = function () {
        this.truck = null;
    };
    return TrucksComponent;
}());
exports.TrucksComponent = TrucksComponent;
