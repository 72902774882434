"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var driver_serializer_1 = require("../drivers/driver.serializer");
var job_serializer_1 = require("../jobs/job.serializer");
var driver_job_report_1 = require("./driver-job-report");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DriverJobReportSerializer = /** @class */ (function () {
    function DriverJobReportSerializer() {
    }
    DriverJobReportSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var driverJobReport = new driver_job_report_1.DriverJobReport();
        if (!json) {
            return driverJobReport;
        }
        driverJobReport.id = json.id;
        driverJobReport.createdAt = json.createdAt;
        driverJobReport.createdBy = json.createdBy;
        driverJobReport.lastModified = json.lastModified;
        driverJobReport.lastModifiedBy = json.lastModifiedBy;
        driverJobReport.reportingOrganization = json.reportingOrganization;
        driverJobReport.reportDate = json.reportDate;
        if (json.driver && typeof (json.driver) === 'object') {
            driverJobReport.driver = new driver_serializer_1.DriverSerializer().fromJson(json.driver);
        }
        else {
            driverJobReport.driver = new driver_serializer_1.DriverSerializer().fromJson({ id: json.driver });
        }
        if (json.job && typeof (json.job) === 'object') {
            driverJobReport.job = new job_serializer_1.JobSerializer().fromJson(json.job);
        }
        else {
            driverJobReport.job = new job_serializer_1.JobSerializer().fromJson({ id: json.job });
        }
        driverJobReport.isApproved = json.isApproved;
        driverJobReport.clockInTime = json.clockInTime;
        driverJobReport.clockOutTime = json.clockOutTime;
        driverJobReport.firstLoadTime = json.firstLoadTime;
        driverJobReport.loadingWaitTime = this.asMinutes(json.loadingWaitTime) || '-';
        driverJobReport.unloadingWaitTime = this.asMinutes(json.unloadingWaitTime) || '-';
        driverJobReport.averageRoundTripTime = this.asMinutes(json.averageRoundTripTime) || '-';
        driverJobReport.tripCount = json.tripCount;
        driverJobReport.punchcardCount = json.punchcardCount;
        driverJobReport.lastLoadTime = json.lastLoadTime;
        driverJobReport.freightBillTime = this.asMinutes(json.freightBillTime) || '-';
        driverJobReport.shiftTime = this.asMinutes(json.shiftTime) || '-';
        if (json.shiftTime && json.freightBillTime) {
            var deficientSeconds = moment.duration(moment.duration(json.shiftTime).subtract(moment.duration(json.freightBillTime))).asSeconds();
            driverJobReport.deficientTime = this.asMinutes(deficientSeconds) + ' minutes';
        }
        else {
            driverJobReport.deficientTime = '-';
        }
        return driverJobReport;
    };
    DriverJobReportSerializer.prototype.toJson = function (driverJobReport) {
        var json = {
            id: driverJobReport.id,
            createdAt: driverJobReport.createdAt,
            createdBy: driverJobReport.createdBy,
            lastModified: driverJobReport.lastModified,
            lastModifiedBy: driverJobReport.lastModifiedBy,
            reportDate: driverJobReport.reportDate,
            driver: driverJobReport.driver && driverJobReport.driver.id,
            job: driverJobReport.job && driverJobReport.job.id,
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    DriverJobReportSerializer.prototype.asMinutes = function (value) {
        var minutes = '0';
        if (value) {
            minutes = (value / 60).toFixed(2);
        }
        return minutes + ' minutes';
    };
    return DriverJobReportSerializer;
}());
exports.DriverJobReportSerializer = DriverJobReportSerializer;
