"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var EventAlertService = /** @class */ (function () {
    function EventAlertService() {
        this.alert = new rxjs_1.Subject();
        this.clicked = new rxjs_1.Subject();
    }
    EventAlertService.prototype.showAlert = function (descriptionText, actionText) {
        this.alert.next({ 'description': descriptionText, 'action': actionText });
        return this.clicked.asObservable();
    };
    EventAlertService.prototype.getAlert = function () {
        return this.alert.asObservable();
    };
    EventAlertService.prototype.click = function () {
        this.clicked.next(true);
    };
    return EventAlertService;
}());
exports.EventAlertService = EventAlertService;
