"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var moment = require("moment-timezone");
var AppUtilities = /** @class */ (function () {
    function AppUtilities() {
    }
    /**
     * Returns the current time by rounding it to the nearest multiple of the duration provided.
     * @param duration Duration to round off
     * @param roundingMethod Rounding method
     * @returns Rounded time
     */
    AppUtilities.getCurrentTimeByRoundingMinutesAndSeconds = function (currentDate, currentTime, duration, roundingMethod) {
        if (currentDate === void 0) { currentDate = moment(); }
        if (currentTime === void 0) { currentTime = null; }
        if (duration === void 0) { duration = moment.duration(5, 'minutes'); }
        if (roundingMethod === void 0) { roundingMethod = 'ceil'; }
        var round = false;
        if (!currentTime) {
            currentTime = moment();
            round = true;
        }
        var time = moment(moment(currentDate).format('YYYY-MM-DD') + " " + moment(currentTime).format('H:mm:ss'));
        if (round) {
            return AppUtilities.roundTime(time, moment.duration(5, 'minutes'), 'ceil');
        }
        else {
            return time;
        }
    };
    AppUtilities.roundTime = function (date, duration, method) {
        return moment(Math[method](+date / +duration) * +duration);
    };
    /**
     * Returns true or false if file size in bytes is smaller than allowed with default value of 25mb.
     * @param fileSize File size in bytes
     * @param maxFileSize Max file size allowed in bytes - default is 25mb
     * @returns True or false
     */
    AppUtilities.fileSmallerThanAllowed = function (fileSize, maxFileSize) {
        if (maxFileSize === void 0) { maxFileSize = 25000000; }
        return fileSize < maxFileSize;
    };
    /**
     * Returns formatted string with hours and minutes with minutes input
     * E.g. - 90minutes returns 1h 30m
     * @param totalMinutes
     * @returns string with hours and minutes
     */
    AppUtilities.getFormattedHoursAndMinutesFromMinutes = function (totalMinutes) {
        var hours = Math.trunc(totalMinutes / 60);
        var minutes = Math.floor(totalMinutes % 60);
        return hours + "h " + minutes + "m";
    };
    AppUtilities.getExportParamsAndScope = function (appliedFilters, selectedRows, excludeRows, allSelected, search, params, scope) {
        if (appliedFilters === void 0) { appliedFilters = []; }
        if (selectedRows === void 0) { selectedRows = []; }
        if (excludeRows === void 0) { excludeRows = []; }
        if (params === void 0) { params = new http_1.HttpParams(); }
        if (scope === void 0) { scope = {}; }
        if (selectedRows.length) {
            Object.assign(scope, { include: selectedRows.map(function (t) { return t.id; }) });
        }
        else if (allSelected) {
            Object.assign(scope, { exclude: excludeRows.map(function (t) { return t.id; }) });
        }
        Object.keys(appliedFilters).map(function (key) {
            if (appliedFilters[key] && appliedFilters[key]['query']) {
                var query_1 = appliedFilters[key]['query'];
                Object.keys(query_1).map(function (queryKey) {
                    params = params.set(queryKey, query_1[queryKey]);
                });
            }
        });
        if (search && search.length) {
            params = params.set('search', search);
        }
        return { params: params, scope: scope };
    };
    AppUtilities.getFilterQuery = function (appliedFilters, search) {
        var query = {};
        appliedFilters.forEach(function (filter) {
            var filterQueries = Object.keys(filter.query);
            filterQueries.map(function (queryKey) {
                filterQueries[queryKey] = filter.query[queryKey];
            });
        });
        query['search'] = search;
        return query;
    };
    AppUtilities.getParamsFromQuery = function (query) {
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, String(query[key]));
                }
            });
        }
        return params;
    };
    return AppUtilities;
}());
exports.AppUtilities = AppUtilities;
