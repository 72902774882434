'use strict';
import { filter } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'menu-toggle',
  templateUrl: './menu-toggle.component.html',
  styleUrls: ['./menu-toggle.component.scss']
})
export class MenuToggleComponent implements OnInit, OnDestroy {
  @Input() section: any;
  @Input() renderContent = false;
  routes: any = [];
  isOpen = false;
  hasActiveRoute = false;
  routeListener;

  constructor(private router: Router) { }

  ngOnInit(): any {
    // this.isOpen = this.shouldExpand(this.['url'])
    let url = this.router.url;
    if (url && url.split('?').length > 1) {
      url = url.split('?')[0];
    }
    this.isOpen = this.shouldExpand(url);
    this.routeListener = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => this.isOpen = this.shouldExpand(event['url']));
  }

  ngOnDestroy() {
    this.routeListener.unsubscribe();
  }

  expandWith(state, sectionRoutes, currentRoute = this.router.url) {
    this.isOpen = !this.isOpen;
    this.routes = sectionRoutes;
    this.shouldExpand(currentRoute);
  }

  shouldExpand(currentRoute): boolean {
    let hasActiveRoute = false;
    if (this.routes && this.routes.length) {
      this.routes.map(function(route) {
        if (route.url === currentRoute) {
          hasActiveRoute = true;
        }
      });
      if (this.section.url === currentRoute || (currentRoute && (currentRoute.split('?')[0] === this.section.url))) {
        hasActiveRoute = true;
      }
    } else {
      if (this.section.url === currentRoute) {
        hasActiveRoute = true;
      } else if (currentRoute) {
        let parts = currentRoute.split('/');
        let url = currentRoute;
        // if (parts && parts[1]) { url = '/' + parts[1]; }
        if (url && this.section.pages) {
          let urls = this.section.pages.map(page => page.url);
          if (urls && urls.includes(url)) { hasActiveRoute = true; }
        }
        if (currentRoute.split('?')[0] === this.section.url) {
          hasActiveRoute = true;
        }
      }
    }
    this.hasActiveRoute = hasActiveRoute;
    return hasActiveRoute;
  }
}
