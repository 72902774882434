"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var PunchCardFilter = /** @class */ (function () {
    function PunchCardFilter(punchCardFilterInfo) {
        punchCardFilterInfo = camelcaseKeysDeep(punchCardFilterInfo);
        this.id = punchCardFilterInfo.id;
        this.name = punchCardFilterInfo.name;
    }
    return PunchCardFilter;
}());
exports.PunchCardFilter = PunchCardFilter;
