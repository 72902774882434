
<div class="user-container">
  <div class="user-data">
    <h2 translate>Personal Information</h2>
    <form #editUser="ngForm" class="user-form" *ngIf="user">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="first-name">
              <label class="required" translate>First Name</label>
              <input type="text" required [(ngModel)]="user.firstName" name="user_first_name" />
            </div>
            <div class="last-name">
              <label class="required" translate>Last Name</label>
              <input type="text" required [(ngModel)]="user.lastName" name="user_last_name" />
            </div>
          </div>
          <div class="row">
            <div class="job-title">
              <label class="required" translate>Job Title</label>
              <input type="text" required [(ngModel)]="user.title" name="user_title" />
            </div>
          </div>
          <div class="row">
            <div class="email">
              <label class="required" translate>Email</label>
              <input type="text" required [(ngModel)]="user.email" name="user_email" />
            </div>
          </div>
          <div class="row">
            <div class="phone-number">
              <label class="required" translate>Phone</label>
              <input type="text" required [(ngModel)]="user.phoneNumber" name="user_phone_number" />
            </div>
          </div>

          <div class="row" *ngIf="errors && errors.length">
            <notification *ngFor="let error of errors">{{error}}</notification>
          </div>

          <div class="row">
            <button class="btn btn-primary"
                    (click)="saveUser(); false"
                    [disabled]="loading || !editUser.form.valid || (editUser.form.pristine && !imageChanged)"
                    [ngClass]="{loading: loading}"
                    translate>
              Save Profile
            </button>
          </div>
        </div>
        <div class="right-side">
          <div class="field-group profile-image">
            <div class="upload-image-dropzone round" [ngClass]="{filled: (user && user.image) || profileImage.file}">
              <div class="img">
                <img *ngIf="!profileImage.file && user && user.image" [src]="user.image" />
                <img *ngIf="profileImage.file" [src]="profileImage.dataUri" />
              </div>
              <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event, editUser)" #fileInput/>
              <label class="upload-label" for="file">
                <i class="icon-upload"></i>
                <span *ngIf="(user && !user.image) && !profileImage.file" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
                <span *ngIf="(user && user.image) || profileImage.file" translate>Upload a new photo</span>
              </label>
            </div>

            <label translate>Your Profile Picture</label>
          </div>
        </div>
      </div>
    </form>
    
    <h2 translate>Password</h2>
    <form #editPassword="ngForm" class="password-form" *ngIf="user">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="current-password">
              <label class="required" translate>Current Password</label>
              <input type="password" required [(ngModel)]="userPassword.passwordCurrent" name="password_current" />
            </div>
          </div>
          <div class="row">
            <div class="new-password1">
              <label class="required" translate>New Password</label>
              <input type="password" name="password" required pattern=".{8,}" title="8 characters minimum" [(ngModel)]="userPassword.password" #password="ngModel" />
              <span [ngClass]="{'input-icon fa': true, 'ng-valid': password.valid, 'ng-invalid': password.invalid, 'ng-pristine': password.pristine}"></span>
            </div>
            <div class="new-password2">
              <label translate>Re-enter Password</label>
              <input type="password" name="passwordConfirmation" required pattern=".{8,}" title="8 characters minimum" validateEqual="password" [(ngModel)]="userPassword.passwordConfirmation" #passwordConfirmation="ngModel" />
              <span [ngClass]="{'input-icon fa': true, 'ng-valid': passwordConfirmation.valid, 'ng-invalid': passwordConfirmation.invalid, 'ng-pristine': passwordConfirmation.pristine}"></span>
            </div>
          </div>

          <div class="row" *ngIf="passwordErrors && passwordErrors.length">
            <notification *ngFor="let error of passwordErrors">{{error}}</notification>
          </div>
          
          <div class="row">
            <button class="btn btn-primary"
                    (click)="savePassword(); false"
                    [disabled]="savePasswordLoading || !editPassword.valid || editPassword.pristine"
                    [ngClass]="{loading: savePasswordLoading}"
                    translate>
              Save Password
            </button>
          </div>
        </div>
        <div class="right-side"></div>
      </div>
    </form>

    <h2 translate>Enabled Features</h2>
    <form #editFeatures="ngForm" class="features-form" *ngIf="user">
      <div class="form-block">
        <div class="left-side">
          <div class="row feature" *ngIf="!user.enabledFeatures" translate>
            No experimental features enabled.
          </div>
          <div class="row feature" *ngFor="let feature of user.enabledFeatures">
            <label translate>{{ feature | titleCase }}</label>
            <div class="field-group feature-state">
              <label class="toggle-label">
                <input type="checkbox" name="" checked="true" disabled="true" class="toggle-input">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="right-side">
          <button class="btn btn-primary"
                  (click)="updateAccount(); false"
                  [disabled]="accountUpdateLoading"
                  [ngClass]="{loading: accountUpdateLoading}"
                  translate>
            Update Account
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
