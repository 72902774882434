import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone } from 'lodash';

import { PAYSTUB } from './mock-paystub';
import { Paystub } from './paystub';
import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/api.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class PaystubService {
  baseUrl = environment.serverUrl;
  paystub: Paystub;
  public nextUri;
  public count = 0;

  constructor(private http: HttpClient) { }

  getMockPaystub() { return PAYSTUB; }

  getPaystub(paystubId: string) {
    let paystubUrl = this.baseUrl + 'paystubs/' + paystubId + '/';

    return this.http.get(paystubUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getMockPaystubs(count: Number) {
    let paystubs = [];

    for (let i = 1; i <= count; i++) {
      let paystub = Object.assign({}, PAYSTUB);
      paystubs.push(paystub);
    }
    return paystubs;
  }

  getPaystubs(query: any = null): Observable<Paystub[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('page_size', '10');
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let paystubsUrl = this.baseUrl + 'paystubs/';
    return this.http.get(paystubsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  save(paystub) {
    paystub = clone(paystub);
    const paystubUrl = this.baseUrl + 'paystubs/';

    if (typeof paystub.job === 'object') {
      paystub.job = paystub.job.id;
    }
    if (typeof paystub.driver === 'object') {
      paystub.driver = paystub.driver.id;
    }
    if (typeof paystub.billFromCcEmails === 'string') {
      paystub.billFromCcEmails = paystub.billFromCcEmails.split(',');
    }
    if (typeof paystub.billToCcEmails === 'string') {
      paystub.billToCcEmails = paystub.billToCcEmails.split(',');
    }

    paystub = decamelizeKeysDeep(paystub);

    if (!paystub.id) {
      return this.http.post(paystubUrl, paystub, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError(handleError)
      );
    } else {
      return this.http.put(paystubUrl + paystub.id + '/', paystub, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError(handleError)
      );
    }
  }

  remove(paystub) {
    paystub = decamelizeKeysDeep(paystub);
    const paystubUrl = this.baseUrl + 'paystubs/';

    return this.http.delete(paystubUrl + paystub.id + '/', {headers: requestHeaders()});
  }

  unlock(paystub) {
    paystub = decamelizeKeysDeep(paystub);
    const paystubUrl = this.baseUrl + 'paystubs/';

    return this.http.put(paystubUrl + paystub.id + '/', { sent: false }, { headers: requestHeaders() });
  }

  send(paystub) {
    paystub = decamelizeKeysDeep(paystub);
    const paystubUrl = this.baseUrl + 'paystubs/';

    return this.http.post(paystubUrl + paystub.id + '/send/', {
      payment_type: paystub.payment_type
    }, {headers: requestHeaders()});
  }

  addToPaystub(paystub, type = 'trips') {
    paystub = clone(paystub);
    let params, filterParams;

    const paystubUrl = this.baseUrl + 'paystubs/' + paystub.id + '/add/';

    if (paystub.filters && paystub.filters.length) {
      filterParams = '?' + paystub.filters;
      if (type === 'trips') {
        params = {trips: [], exclude_trips: paystub.excludeTrips || paystub.exclude_trips};
      } else if (type === 'punchcards') {
        params = {punchcards: [], exclude_punchcards: paystub.excludePunchCards || paystub.exclude_punch_cards};
      }
    } else {
      filterParams = '';
      if (type === 'trips') {
        params = {trips: paystub.trips || []};
      } else if (type === 'punchcards') {
        params = {punchcards: paystub.punchCards || paystub.punchcards || []};
      }
    }

    paystub = decamelizeKeysDeep(paystub);

    return this.http.post(paystubUrl + filterParams, params, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  removeFromPaystub(paystub, type = 'trips') {
    paystub = decamelizeKeysDeep(clone(paystub));
    let params, filterParams;

    const paystubUrl = this.baseUrl + 'paystubs/' + paystub.id + '/remove/';

    if (paystub.filters && paystub.filters.length) {
      filterParams = '?' + paystub.filters;
      if (type === 'trips') {
        params = { trips: [], exclude_trips: paystub.excludeTrips || paystub.exclude_trips };
      } else if (type === 'punchcards') {
        params = { punchcards: [], exclude_punchcards: paystub.excludePunchCards || paystub.exclude_punch_cards };
      } else if (type === 'surcharges') {
        params = { surcharges: [], exclude_surcharges: paystub.excludeSurcharges || paystub.exclude_surcharges };
      }
    } else {
      filterParams = '';
      if (type === 'trips') {
        params = { trips: paystub.trips || [] };
      } else if (type === 'punchcards') {
        params = { punchcards: paystub.punchCards || paystub.punchcards || [] };
      } else if (type === 'surcharges') {
        params = { surcharges: paystub.surcharges || [] };
      }
    }

    return this.http.post(paystubUrl + filterParams, params, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  export(scope, params: any = null) {
    const paystubUrl = this.baseUrl + 'paystubs/export/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(paystubUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  downloadPdfs(scope, params: any = null) {
    const paystubUrl = this.baseUrl + 'invoices/download_pdfs/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(paystubUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  getNext(): Observable<Paystub[]> {
     if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()}).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];
    if (body) {
      return body.map(paystub => {
        return new Paystub(paystub);
      });
    } else if (resObj) {
      return new Paystub(resObj);
    }
  }
}
