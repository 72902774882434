"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./action-bar.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory");
var i3 = require("@angular/material/toolbar");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/common");
var i6 = require("./action-bar.component");
var styles_ActionBarComponent = [i0.styles];
var RenderType_ActionBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionBarComponent, data: {} });
exports.RenderType_ActionBarComponent = RenderType_ActionBarComponent;
function View_ActionBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], null, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [["class", "action-bar-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
exports.View_ActionBarComponent_0 = View_ActionBarComponent_0;
function View_ActionBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "action-bar", [], null, null, null, View_ActionBarComponent_0, RenderType_ActionBarComponent)), i1.ɵdid(1, 49152, null, 0, i6.ActionBarComponent, [], null, null)], null, null); }
exports.View_ActionBarComponent_Host_0 = View_ActionBarComponent_Host_0;
var ActionBarComponentNgFactory = i1.ɵccf("action-bar", i6.ActionBarComponent, View_ActionBarComponent_Host_0, {}, {}, ["*"]);
exports.ActionBarComponentNgFactory = ActionBarComponentNgFactory;
