<div class="banner">
  <div class="section tagline">
    <div class="icon"><i class="icon-connections"></i></div>
    <h1 translate>One Platform.<br/>Work with Everyone.</h1>
  </div>
  <div class="section">
    <div class="icon"><img src="/assets/img/icons/icn-connections-new.svg"></div>
    <h2 translate>Invite Carriers to Ruckit</h2>
    <p translate>Work with your network of carriers on Ruckit. Share jobs and track driver progress. Get more things done.</p>
    <button translate class="btn btn-primary" (click)="invite()">Send Invite</button>
  </div>
  <div class="section">
    <div class="icon"><img src="/assets/img/icons/icn-connections-existing.svg"></div>
    <h2 translate>Work with Carriers on Ruckit</h2>
    <p translate>Work with existing Ruckit organizations to maximize time and money.</p>
    <button translate class="btn btn-primary" (click)="connect()">Connect</button>
  </div>
</div>
