<my-toolbar *ngIf="!job" title="{{ 'Edit Job' | translate }}"></my-toolbar>
<my-toolbar *ngIf="job" title="{{ job.name }} - {{ 'Edit Job' | translate }}"></my-toolbar>
<div class="edit-job-container">
  <form #editJob='ngForm' *ngIf="job" [ngClass]="{'mobile': !device.desktop}">
    <h1 translate>Edit Job Information</h1>
    <h2 translate>Job Details</h2>
    <div class="field-group dropdown">
      <label class="required" translate>Project</label>
      <input type="text" required name="project" [disabled]="!isCreator" [(ngModel)]="projectName" />
    </div>
    <div class="field-group dropdown">
      <label class="input-field-toggle" (click)="customerEditEnabled = true" translate>Edit Customer</label>
      <dropdown id="customer-dropdown" #customerDropdown required
        [disabled]="!isCreator || !customerEditEnabled"
        [options]="customerOptions" [selectedOption]="selectedCustomer"
        [config]="customerDropdownConfig" (onSelect)="selectCustomer($event)"
        (onSearch)="customerDropdownSearch($event)" (nextPage)="customerDropdownNextPage()"
        title="{{ 'Select Customer' | translate }}"></dropdown>
    </div>
    <div class="field-group">
      <label class="required" translate>Job Title</label>
      <input type="text" required [disabled]="!isCreator" name="name" [(ngModel)]="model.name" />
    </div>
    <div class="field-group">
      <label translate>PO #</label>
      <input type="text" [disabled]="!isCreator" name="po-num" [(ngModel)]="model.poNumber" />
    </div>
    <div class="field-group">
      <label translate>Order #</label>
      <input type="text" [disabled]="!isCreator" name="order-num" [(ngModel)]="model.orderNumber" />
    </div>
    <div class="field-group">
      <label translate>Job #</label>
      <input type="text" [disabled]="!isCreator" name="job-num" [(ngModel)]="model.jobNumber" />
    </div>
    <div class="field-group">
      <label translate>Markets</label>
      <tag-input name="tags" [selectedOptions]="model.tags" (onSelect)="model.tags = $event"></tag-input>
    </div>
    <div class="field-group">
      <label class="required" translate>What material is being moved?</label>
      <input type="text" required [disabled]="!isCreator" name="material" [(ngModel)]="model.material" />
    </div>
    <div class="field-group">
      <label translate>Phase Code</label>
      <input type="text" [disabled]="!isCreator" name="phase-code" [(ngModel)]="model.phaseCode" />
    </div>

    <div class="field-group amount-needed">
      <label translate>Total amount needed?</label>
      <div class="input-group">
        <input type="text" name="amount_needed" [(ngModel)]="model.amountNeeded" pattern="[^.]+" placeholder="Amount" />
        <dropdown [options]="orderTypeOptions" [selectedOption]="selectedTotalAmountType" (onSelect)="changeTotalAmountType($event)"></dropdown>
      </div>
    </div>

    <div class="field-group delivery-target">
      <label translate>Daily Delivery Target</label>
      <div class="input-group">
        <input type="number" [disabled]="!isCreator" placeholder="Amount" name="daily_delivery_target" [(ngModel)]="model.dailyDeliveryTarget" />
        <dropdown [options]="orderTypeOptions" [selectedOption]="selectedDailyDeliveryTargetType" (onSelect)="changeDailyDeliveryTargetType($event)"></dropdown>
      </div>
    </div>
    <div class="field-group">
      <label translate>Stagger Start Time</label>
      <div class="input-group">
        <input type="number" [disabled]="!isCreator" placeholder="Time" name="delivery_interval" min="0" [(ngModel)]="model.deliveryInterval" />
        <dropdown [options]="deliveryIntervalUnits" [selectedOption]="selectedDeliveryIntervalUnit" (onSelect)="changeDeliveryIntervalUnits($event)"></dropdown>
      </div>
    </div>

    <h2>Rate</h2>
    <div class="field-group">
      <label translate>How is this job invoiced?</label>
      <div class="radio-buttons">
        <input type="radio" id="invoice_type_weight" name="invoice_type" [(ngModel)]="model.invoiceType" value="weight"
          (change)="invoiceTypeChanged()" />
        <label attr.data-label="{{ 'Weight' | translate }}" for="invoice_type_weight"></label>

        <input type="radio" id="invoice_type_load" name="invoice_type" [(ngModel)]="model.invoiceType" value="load"
          (change)="invoiceTypeChanged()" />
        <label attr.data-label="{{ 'Load' | translate }}" for="invoice_type_load"></label>

        <input type="radio" id="invoice_type_hour" name="invoice_type" [(ngModel)]="model.invoiceType" value="hour"
          (change)="invoiceTypeChanged()" />
        <label attr.data-label="{{ 'Hour' | translate }}" for="invoice_type_hour"></label>
      </div>
    </div>
    <div class="field-group">
      <label class="required" translate>What is the rate?</label>
      <div class="input-group" data-prefix="$">
        <input numberInRange [minValue]="0" type="text" name="job_rate" [(ngModel)]="model.rate" min="0" (change)="changeCloneHaulRate()" />
        <dropdown [options]="jobWeightOptions" *ngIf="model.invoiceType === 'weight'" title="-"
          (onSelect)="weightUnitChange('invoice', $event)" [selectedOption]="selectedInvoiceUnit"></dropdown>
        <div class="add-on" *ngIf="model.invoiceType !== 'weight'">{{model.invoiceType}}</div>
      </div>
    </div>

    <div class="field-group clone-haul-rate">
      <input type="checkbox" id="clone_haul_rate" name="clone_haul_rate" [(ngModel)]="cloneHaulRate" (change)="changeCloneHaulRate()" />
      <label attr.data-label="{{ 'Haul rate is the same' | translate }}" for="clone_haul_rate"></label>
    </div>

    <div class="field-group haul-rate-tracking" *ngIf="!cloneHaulRate">
      <label translate>How is the haul rate tracked?</label>
      <div class="radio-buttons">
        <input type="radio" id="haul_type_weight" name="haul_type" [(ngModel)]="model.haulType" (change)="haulTypeChanged()"
          value="weight" [disabled]="cloneHaulRate" />
        <label attr.data-label="{{ 'Weight' | translate }}" for="haul_type_weight"></label>
        <input type="radio" id="haul_type_load" name="haul_type" [(ngModel)]="model.haulType" (change)="haulTypeChanged()"
          value="load" [disabled]="cloneHaulRate" />
        <label attr.data-label="{{ 'Load' | translate }}" for="haul_type_load"></label>
        <input type="radio" id="haul_type_hour" name="haul_type" [(ngModel)]="model.haulType" (change)="haulTypeChanged()"
          value="hour" [disabled]="cloneHaulRate" />
        <label attr.data-label="{{ 'Hour' | translate }}" for="haul_type_hour"></label>
      </div>
    </div>
    <div class="field-group" *ngIf="!cloneHaulRate">
      <label class="required" translate>What is the haul rate?</label>
      <div class="input-group" data-prefix="$">
        <input numberInRange [minValue]="0" type="text" name="haul_rate" [(ngModel)]="model.haulRate" [disabled]="cloneHaulRate" min="0" />
        <dropdown [options]="haulWeightOptions" *ngIf="model.haulType === 'weight'" title="-" [disabled]="cloneHaulRate"
          (onSelect)="weightUnitChange('haul', $event)" [selectedOption]="selectedHaulUnit"></dropdown>
        <div class="add-on" *ngIf="model.haulType !== 'weight'">{{model.haulType}}</div>
      </div>
    </div>

    <h2 translate>Yard Times</h2>
    <div class="field-group default-yard-buffer-time">
      <label translate>Default Yard Buffer Time</label>
      <div class="input-group">
        <input type="number" min="0" [(ngModel)]="model.defaultYardBufferMinutes" name="default_yard_buffer_minutes" />
        <div class="add-on" translate>Minutes</div>
      </div>
    </div>

    <ng-container *ngIf="user.organization && user.organization.qrEnabled">
      <h2 translate>Job Type</h2>
      <div class="field-group">
        <label translate>Are materials imported to the job site or exported from the job site?</label>
        <div class="fields">
          <div class="radio-buttons">
            <input type="radio" name="job_type" id="job_type_loading_import" [(ngModel)]="model.qrJobType" value="loading_import"
              (change)="jobTypeChanged()" />
            <label attr.data-label="{{ 'Import (Ticket at Loading)' | translate }}" for="job_type_loading_import'"></label>
            <input type="radio" name="job_type" id="job_type_unloading_import" [(ngModel)]="model.qrJobType" value="unloading_import"
              (change)="jobTypeChanged()" />
            <label attr.data-label="{{ 'Import (Ticket at Unloading)' | translate }}" for="job_type_unloading_import"></label>
            <input type="radio" name="job_type" id="job_type_export" [(ngModel)]="model.qrJobType" value="export"
              (change)="jobTypeChanged()" />
            <label attr.data-label="{{ 'Export' | translate }}" for="job_type_export"></label>
            <input type="radio" name="job_type" id="job_type_other" [(ngModel)]="model.qrJobType" value="other"
              (change)="jobTypeChanged()" />
            <label attr.data-label="{{ 'Other' | translate }}" for="job_type_other"></label>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="job-type-description" *ngIf="user.organization && user.organization.qrEnabled">
      <label translate>Job Type Description</label>
      <div *ngIf="model.qrJobType === 'loading_import'" translate>
      Use this option when the driver
      <span class="description-highlight">receives a paper ticket at loading</span>
      and the Ruckit Driver App generates a QR code for the Pit TicketPro App to scan at unloading.
      </div>
      <div *ngIf="model.qrJobType === 'unloading_import'" translate>
        Use this option when there is
        <span class="description-highlight">no ticket at loading</span>
        and the Pit TicketPro App generates a QR code for the Ruckit Driver App to scan at unloading.
      </div>
      <div *ngIf="model.qrJobType === 'export'" translate>
        Use this option when
        <span class="description-highlight">materials are removed from the construction site</span>.
        The Pit TicketPro App will generate a QR code for the Ruckit Driver App to scan at loading.
      </div>
      <div *ngIf="model.qrJobType === 'other'" translate>
        Use this option when creating a job that does not integrate with Pit TicketPro.
      </div>
    </div>

    <h2 translate>Loading</h2>
    <div class="location">
      <div class="fields">
        <label class="required" translate>Select Address</label>
        <dropdown id="loading-dropdown" title="-" #startLocationDropdown [options]="loadingLocations" (onSelect)="changeLoadingLocation($event)"
          [config]="locationsDropdownConfig.start" (onSearch)="locationDropdownSearch('start', $event)" (nextPage)="locationDropdownNextPage('start')"></dropdown>
        <label class="options-header" translate>Loading Options</label>

        <div class="config-option">
          <label>{{ 'Log Weight' | translate }}</label>
          <mat-select [(value)]="model.checkinOptions.weightConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['log_weight']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Ticket Number' | translate }}</label>
          <mat-select [(value)]="model.checkinOptions.ticketNumberConfig"
            [disabled]="!isCreator || model.checkinOptionsDisabled['ticket_number']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Ticket Photo' | translate }}</label>
          <mat-select [(value)]="model.checkinOptions.ticketImageConfig"
            [disabled]="!isCreator || model.checkinOptionsDisabled['ticket_image']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Signature' | translate }}</label>
          <mat-select [(value)]="model.checkinOptions.signatureImageConfig"
            [disabled]="!isCreator || model.checkinOptionsDisabled['signature_image']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Track Loading Time' | translate }}</label>
          <mat-select [(value)]="model.checkinOptions.activeTrackingConfig"
            [disabled]="!isCreator || model.checkinOptionsDisabled['active_tracking']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
          <label>{{ 'QR Code' | translate }}</label>
          <mat-select [(value)]="model.checkinOptions.qrConfig"
            [disabled]="!isCreator || model.checkinOptionsDisabled['qr_code']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="map">
        <div class="map-actions" *ngIf="model.startLocation">
          <button class="btn btn-primary-alt btn-small" (click)="editLocation(model.startLocation, 'start')" translate>
            Edit
          </button>
        </div>
        <div class="overlay" *ngIf="model.startLocation && !model.startLocation.mappable">
          <a *ngIf="device.desktop"
             [routerLink]="['/locations', model.startLocation.id, 'edit']"
             [queryParams]="{ returnTo: '/jobs/' + job.id + '/edit' }">
            <button class="btn btn-default" translate>Add Address</button>
          </a>
        </div>
        <ngui-map center="{{ model.startLocation.latitude }}, {{ model.startLocation.longitude }}" disableDefaultUI="true"
          disableDoubleClickZoom="true" fullscreenControl="false" mapTypeControl="false" streetViewControl="false"
          zoomControl="false" clickableIcons="false" panControl="false" [zoom]="15">
          <marker *ngIf="model.startLocation && model.startLocation.mappable" draggable="false" [label]="''" [title]="model.startLocation.name"
            position="{{ model.startLocation.latitude }}, {{ model.startLocation.longitude }}">
          </marker>
          <polygon *ngIf="model.startLocation" [editable]="false" [paths]="model.startLocation.paths">
          </polygon>
        </ngui-map>
      </div>
    </div>

    <h2 translate>Unloading</h2>
    <div class="location">
      <div class="fields">
        <label class="required" translate>Select Address</label>
        <dropdown id="unloading-dropdown" title="-" #endLocationDropdown [(options)]="unloadingLocations" (onSelect)="changeUnloadingLocation($event)"
          [config]="locationsDropdownConfig.end" (onSearch)="locationDropdownSearch('end', $event)" (nextPage)="locationDropdownNextPage('end')"></dropdown>
        <label class="options-header" translate>Unloading Options</label>

        <div class="config-option">
          <label>{{ 'Log Weight' | translate }}</label>
          <mat-select [(value)]="model.checkoutOptions.weightConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['log_weight']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Ticket Number' | translate }}</label>
          <mat-select [(value)]="model.checkoutOptions.ticketNumberConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['ticket_number']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Ticket Photo' | translate }}</label>
          <mat-select [(value)]="model.checkoutOptions.ticketImageConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['ticket_image']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option">
          <label>{{ 'Signature' | translate }}</label>
          <mat-select [(value)]="model.checkoutOptions.signatureImageConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['signature_image']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option" *ngIf="user.organization && user.organization.activeTrackingEnabled">
          <label>{{ 'Track Unloading Time' | translate }}</label>
          <mat-select [(value)]="model.checkoutOptions.activeTrackingConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['active_tracking']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>

        <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
          <label>{{ 'QR Code' | translate }}</label>
          <mat-select [(value)]="model.checkoutOptions.qrConfig"
            [disabled]="!isCreator || model.checkoutOptionsDisabled['qr_code']">
            <mat-option *ngFor="let option of configOptions" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="map">
        <div class="map-actions" *ngIf="model.endLocation">
          <button class="btn btn-primary-alt btn-small" (click)="editLocation(model.endLocation, 'end')" translate>
            Edit
          </button>
        </div>
        <div class="overlay" *ngIf="model.endLocation && !model.endLocation.mappable">
          <a *ngIf="device.desktop"
             [routerLink]="['/locations', model.endLocation.id, 'edit']"
             [queryParams]="{ returnTo: '/jobs/' + job.id + '/edit' }">
            <button class="btn btn-default" translate>Add Address</button>
          </a>
        </div>
        <ngui-map center="{{ model.endLocation.latitude }}, {{ model.endLocation.longitude }}" disableDefaultUI="true"
          disableDoubleClickZoom="true" fullscreenControl="false" mapTypeControl="false" streetViewControl="false"
          zoomControl="false" clickableIcons="false" panControl="false" [zoom]="15">
          <marker *ngIf="model.endLocation && model.endLocation.mappable" draggable="false"
                  [label]="''" [title]="model.endLocation.name"
                  position="{{ model.endLocation.latitude }}, {{ model.endLocation.longitude }}">
          </marker>
          <polygon *ngIf="model.endLocation && model.endLocation.paths"
                   [editable]="false" [paths]="model.endLocation.paths">
          </polygon>
        </ngui-map>
      </div>
    </div>

    <ng-container *ngIf="hasWorkOrder">
      <h2 translate>Work Order</h2>
      <div class="field-group dropdown work-order-template">
        <label translate>Work Order Template</label>
        <input type="hidden" name="work_order_template" [(ngModel)]="job.workOrderTemplate" #workOrderTemplate="ngModel" />
        <div class="work-order-template-inner">
          <dropdown id="work-order-template-dropdown" #workOrderTemplateDropdown
                    [options]="workOrderTemplateOptions"
                    [selectedOption]="job.workOrderTemplate"
                    [config]="workOrderTemplateDropdownConfig"
                    (onSelect)="onSelect('workOrderTemplate', $event)"
                    (onSearch)="dropdownSearch('workOrderTemplate', $event)"
                    (nextPage)="dropdownNextPage('workOrderTemplate', $event)"
                    title="{{ 'Select Work Order Template' | translate }}"></dropdown>
          <div class="template-preview">
            <a *ngIf="job.workOrderTemplate" [href]="job.workOrderTemplate.html" target="_blank">
              <mat-icon>picture_as_pdf</mat-icon> Preview
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="model.customFieldData">
      <h2 translate>Custom Fields</h2>
      <div class="field-group">
        <div class="fields">
          <ng-container *ngFor="let field of customFields | keyvalue" class="custom-fields">
            <label>{{ field.value.displayName }}</label>
            <input name="{{ field.key }}" type="text" placeholder="Value" [(ngModel)]="model.customFieldData[field.key]">
          </ng-container>
        </div>
      </div>
    </ng-container>

    <h2 translate>Additional Notes</h2>
    <label translate>Notes</label>
    <textarea [disabled]="!isCreator" [(ngModel)]="model.notes" name="notes"></textarea>

    <notification *ngFor="let error of errors">{{error}}</notification>
    
    <button id="save-job-button" class="btn btn-primary submit"
            [disabled]="!isValid()" (click)="submit(); false"
            [ngClass]="{loading: loading}" translate>
      Save Job
    </button>

    <button id="save-job-and-dispatch-button" class="btn btn-primary-alt submit"
        [disabled]="!isValid()" (click)="submit(true); false"
        [ngClass]="{loading: loading}" translate>
      Save and Dispatch Job
    </button>
  </form>

  <not-found *ngIf="!loading && !job"></not-found>
</div>
