import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../users/user.service';
import { RuckitAddEmployeeDialogComponent } from './add-employee-dialog.component';
import { AuthenticationService } from '../shared';

@Component({
  selector: 'team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'last_name' },
    { key: 'title', title: this.translationService.instant('Title'), sortable: true },
    { key: 'email', title: this.translationService.instant('Email'), sortable: true },
    { key: 'phone', title: this.translationService.instant('Phone Number') },
    { key: 'markets', title: this.translationService.instant('Markets') }
  ];
  @Input() displayedColumns = [
    'select', 'name', 'title', 'email', 'phone', 'markets'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  errors = [];
  tableConfig = {
    hasHeader: true,
    service: UserService,
    preferenceKey: 'TeamComponent-UserService',
    query: {},
    collectionTitle: this.translationService.instant('Users'),
    noResultsText: this.translationService.instant('a user'),
    newRecordRoute: ['/users/new'],
    sortBy: 'last_name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
    ]
  };

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  openDialog() {
    const organization = this.authenticationService.getOrganization();
    this.dialog.open(RuckitAddEmployeeDialogComponent, {
      width: '444px',
      data: { type: 'employee', organizationId: organization && organization.id }
    });
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
