const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Unit {
  id: string;
  name: string;

  constructor(data: any) {
    data = camelcaseKeysDeep(data);

    this.id = data.id;
    this.name = data.name;
  }
}
