<form #sendJob="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 i18n='Trip signatures header'>Send Job</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors"><notification *ngFor="let error of errors">{{error}}</notification></div>

    <div class="content">
      <div *ngIf="job" class="send-job">
        <div class="form-content">
          <p>Send this job to one of your connections so that outside trucks can be assigned.</p>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group" fxFlex="100">
              <label class="required">Select days to send</label>
              <date-range-picker [selectedDates]="model.data.dates"></date-range-picker>
            </div>
          </div>

          <div class="horizontal-divider"></div>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group connection" fxFlex="70">
              <label class="required">Choose a connection</label>
              <input type="hidden" name="recipient_organization" required [(ngModel)]="model.recipientOrganization" />
              <dropdown #connectionsDropdown
                        [options]="connections"
                        (onSelect)="selectConnection($event, sendJob)"
                        [config]="connectionsConfig"
                        title="Select a Connection"
                        (onSearch)="dropdownSearch($event, 'connection')"
                        (nextPage)="dropdownNextPage($event, 'connection')">
              </dropdown>
            </div>

            <div class="field-group truck-slots" fxFlex="30">
              <label class="required">Truck Slots</label>
              <input type="text" name="num_trucks" placeholder="Truck Slots" required [(ngModel)]="model.data.numTrucks" #numTrucks="ngModel" />
            </div>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div *ngIf="job" class="job-detail">
        <div class="form-content">
          <h2>{{ job.name }}</h2>

          <div class="job-locations">
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="field-group start-location" fxFlex="100">
                <div class="value">{{ job.startLocation && job.startLocation.name }}</div>
              </div>
            </div>
            <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="field-group end-location" fxFlex="100">
                <div class="value">{{ job.endLocation && job.endLocation.name }}</div>
              </div>
            </div>
          </div>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group invoice-rate" fxFlex="50">
              <div class="label">Invoice Rate</div>
              <div class="value"><span class="currency">{{ job.rate || 0 | currency:'USD':'symbol':'1.2-4' }}</span> per {{ job.invoiceRateUnit }}</div>
            </div>

            <div class="field-group material" fxFlex="50">
              <div class="label">Material</div>
              <div class="value">{{ job.material }}</div>
            </div>
          </div>

          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group date-range" fxFlex="50">
              <div class="label">Start and End Day</div>
              <div class="value">{{ job.startDate | date:'MMM d' }} – {{ job.endDate | date:'MMM d' }}</div>
              <div class="value" *ngIf="job.shifts > 1">&nbsp;</div>
            </div>

            <div class="field-group daily-schedule" fxFlex="50">
              <div class="label">Daily Schedule</div>
              <div class="value">{{ job.shift1Start }} - {{ job.shift1End }}</div>
              <div class="value" *ngIf="job.shifts > 1">{{ job.shift2Start }} - {{ job.shift2End }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="sendJob.form.valid && submit(); false"
            [disabled]="!sendJob.form.valid || loading"
    [ngClass]="{loading:loading}">
      Send Job
    </button>
  </div>
</form>
