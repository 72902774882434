import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Slot } from '../../assignments/slot';
import { JobEvent } from '../../job-events/job-event';
import { Shift } from '../../assignments/shift';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import { JobEventService } from '../../job-events/job-event.service';
import { Observable, Subscriber } from 'rxjs';
import { CondensedJobEvent } from '../../job-events/condensed-job-event';

type ParamsUpdate = {
  jobEvent: JobEvent | CondensedJobEvent,
  shifts?: Shift[], 
  currentAssignation: {
    assignmentId?: string,
    numberOfLoadsType: string, // 'allDay' | 'numbered'
    maxNumberOfLoads: number
  },
  countSameChunk?: number
}

type ReturnedValue = boolean | { maxLoads: number } | { byPass: boolean }

@Injectable()
export class ConfirmChangeJobLoadsService {
  private _jobEventId: string
  private _observer: Subscriber<ReturnedValue>

  constructor(
    public dialog: MatDialog,
    private jobEventService: JobEventService,
  ) { }

  public validateLoadsRequested(params: ParamsUpdate): Observable<ReturnedValue>{
    const { jobEvent: { id } } = params
    return new Observable(observer => {
      this._observer = observer
      this._jobEventId = id

      this._validateLimitLoads(params)
    })
  }

  public isGreaterAssigned({ jobEvent, shifts, currentAssignation, countSameChunk = 0 }: ParamsUpdate) {
    const { assignmentId: currentAssignmentId, numberOfLoadsType, maxNumberOfLoads} = currentAssignation
    const { dailyDeliveryTarget, dailyDeliveryTargetType } = jobEvent

    if (dailyDeliveryTargetType === 'loads' && numberOfLoadsType !== 'allDay' && maxNumberOfLoads > 0){
      const loadsRequested = Number(dailyDeliveryTarget)
      const currentLoadsToAssign = Number(currentAssignation.maxNumberOfLoads) || 1;
      const loadsAssigned = this._getLoadsAssigned({ jobEvent, shifts, currentAssignmentId }) + countSameChunk

      return loadsRequested < (loadsAssigned + currentLoadsToAssign)
    }

    return false
  }

  private _validateLimitLoads(params: ParamsUpdate) {
    if(this.isGreaterAssigned(params)) {
      const { jobEvent } = params
      
      if (jobEvent.canEdit) {
        this._showConfirmationModal(params)
      } else {
        this.showConfirmationProceedModal()
      }
    } else {
      this._observer.next(true)
      this._observer.complete()
    }
  }

  private _getAssignments({jobEvent, shifts}) {
    if(jobEvent && jobEvent['assignments'] ) {
      return jobEvent['assignments'] 
    } else if (shifts) {
      return shifts.map((shift) => (
        shift.slots.map(slot => slot.assignment)
      )).flat()
    } else {
      return []
    }
  }

  private _getLoadsAssigned({ jobEvent, shifts, currentAssignmentId }) {
    const assignments = this._getAssignments({ jobEvent, shifts })

    const loadsAssigned = assignments.reduce((loads, assignment) => {
      const assignmentId = assignment.id
      const loadSlot = assignmentId !== currentAssignmentId ? assignment.maxNumberOfLoads : 0
      return loads + (loadSlot > 0 ? Number(loadSlot) : 0)
    }, 0)

    return loadsAssigned || 0
  }

  private _showConfirmationModal(params: ParamsUpdate) {
    const { jobEvent, shifts, currentAssignation, countSameChunk = 0 } = params
    const loadsRequested = Number(jobEvent.dailyDeliveryTarget)
    const currentLoadsToAssign = Number(currentAssignation.maxNumberOfLoads) || 1;
    const currentAssignmentId = currentAssignation.assignmentId
    const loadsAssigned = this._getLoadsAssigned({ jobEvent, shifts, currentAssignmentId }) + countSameChunk
    const totalLoadsAssigned = (loadsAssigned + currentLoadsToAssign)

    const messageAssigned = loadsAssigned > 0 ? ` and the ${loadsAssigned} previously assigned` : ''
    const confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '530px',
      data: {
        title: `Loads Ordered Exceeded`,
        message: `
          The number of loads ordered has exceeded by the ${currentLoadsToAssign} load(s) selected${messageAssigned}.
          Do you want to assign the selected load(s) and increase the total loads ordered for this job to ${totalLoadsAssigned}?
        `,
        acceptText: 'Assign & Update Loads'
      }
    });

    confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.jobEventService.save(
          { id: this._jobEventId, dailyDeliveryTarget: totalLoadsAssigned }
        ).subscribe(() => {
          jobEvent.dailyDeliveryTarget = totalLoadsAssigned
          this._observer.next(true)
          this._observer.complete()
        }, err => {
          this._observer.error(err)
          this._observer.complete()
        });
      } else {
        const maxLoads = loadsRequested - loadsAssigned
        this._observer.error({ maxLoads: maxLoads <= 0 ? 1 : maxLoads })
        this._observer.complete()
      }
    });
  }

  public showConfirmationProceedModal(): Observable<ReturnedValue> {
    let fnObserver: Subscriber<ReturnedValue>;
    const observable = new Observable<ReturnedValue>(observer => (fnObserver = observer))

    if (this._observer && !this._observer.closed) {
      fnObserver = this._observer
    }

    const confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '530px',
      data: {
        title: `Loads Requested Exceeded`,
        message: `The number of loads you have selected exceeds the loads requested for this job. Do you want to proceed with assigning the selected loads?`,
        acceptText: 'Proceed and Assign Loads'
      }
    });

    confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        fnObserver.next({ byPass: true})
      } else {
        fnObserver.error()
      }
      
      fnObserver.complete()
    });

    return observable
  }
}
