<div
  class="edit-drawer"
  #editDriver
  [ngClass]="{
    mobile: device && device.mobile,
    tablet: device && device.tablet,
    desktop: (device && device.desktop) || !device,
    open: open
  }"
>
  <form
    *ngIf="editDriverForm"
    [formGroup]="editDriverForm"
    (ngSubmit)="onSubmit()"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="driver-image" *ngIf="driver.image">
      <img [src]="driver.image" />
    </div>

    <!-- first and last name -->
    <div class="form-row" formGroupName="profile">
      <div>
        <label class="required" translate>First</label>
        <input
          placeholder="{{ 'First Name' | translate }}"
          required
          formControlName="firstName"
        />
      </div>
      <div>
        <label class="required" translate>Last</label>
        <input
          placeholder="{{ 'Last Name' | translate }}"
          required
          formControlName="lastName"
        />
      </div>
    </div>

    <!-- cdl and billing -->
    <div class="form-row">
      <div>
        <label translate>CDL</label>
        <input placeholder="{{ 'CDL' | translate }}" formControlName="cdl" />
      </div>
      <div>
        <label translate>Billing ID</label>
        <input
          placeholder="{{ 'Billing ID' | translate }}"
          formControlName="uniqueBillingId"
        />
      </div>
    </div>

    <!-- username and change password -->
    <div class="form-row" formGroupName="user">
      <div>
        <label class="required" translate>Username</label>
        <input
          placeholder="{{ 'Username' | translate }}"
          required
          formControlName="username"
        />
      </div>
      <div class="password">
        <label translate (click)="this.onPasswordChange()"
          >Change Password</label
        >
        <input
          type="password"
          placeholder="{{ 'Password' | translate }}"
          formControlName="password"
        />
      </div>
    </div>

    <!-- carrier -->
    <div class="form-row">
      <div class="single-row">
        <label translate>Fleet</label>
        <ruckit-dropdown
          #carriersDropdown
          [config]="carriersConfig"
          [selectedOption]="driver.carrier"
          (onSelect)="optionSelected($event, 'carrier')"
        >
        </ruckit-dropdown>
      </div>
    </div>

    <!-- truck -->
    <div class="form-row">
      <div class="single-row">
        <label translate>Assigned truck</label>
        <ruckit-dropdown
          #trucksDropdown
          [config]="trucksConfig"
          [selectedOption]="driver.truck"
          (onSelect)="optionSelected($event, 'truck')"
        >
        </ruckit-dropdown>
      </div>
    </div>

    <!-- duty status -->
    <div class="form-row">
      <div class="single-row">
        <label translate>Duty Status</label>
        <dropdown
          #dutyStatusDropdown
          title="{{ 'Select Duty Status' | translate }}"
          [config]="dutyStatusConfig"
          [options]="dutyStatusOptions"
          [selectedOption]="driver.displayDutyStatus"
          (onSelect)="optionSelected($event.id, 'dutyStatus')"
        >
        </dropdown>
      </div>
    </div>

    <!-- email and address -->
    <div class="form-row">
      <div class="single-row" formGroupName="profile">
        <label translate>Email</label>
        <input
          formControlName="email"
          type="text"
          name="email"
          placeholder="{{ 'Email' | translate }}"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="single-row">
        <label translate>Address</label>
        <input
          formControlName="street"
          type="text"
          name="address"
          placeholder="{{ 'Address' | translate }}"
        />
      </div>
    </div>

    <!-- city -->
    <div class="form-row">
      <div class="single-row">
        <label translate>City</label>
        <input
          formControlName="city"
          type="text"
          name="city"
          placeholder="{{ 'City' | translate }}"
        />
      </div>
    </div>

    <!-- country and state -->
    <div class="form-row">
      <div>
        <label translate>Country</label>
        <select
          name="country"
          (change)="optionSelected($event.target.value, 'country')"
        >
          <option [selected]="!driver.country">
            {{ 'Select Country' | translate }}
          </option>
          <option
            *ngFor="let countryOption of countryOptions"
            [value]="countryOption.id"
            [selected]="countryOption.id === driver.country"
          >
            {{ countryOption.name }}
          </option>
        </select>
      </div>
      <div>
        <ng-container *ngIf="driver.country !== 'CA'">
          <label translate>State</label>
          <select
            name="state"
            (change)="optionSelected($event.target.value, 'state')"
          >
            <option [selected]="!driver.state">
              {{ 'Select State' | translate }}
            </option>
            <option
              *ngFor="let stateOption of stateOptions"
              [value]="stateOption.id"
              [selected]="stateOption.id === driver.state"
            >
              {{ stateOption.name }}
            </option>
          </select>
        </ng-container>
        <ng-container *ngIf="driver.country === 'CA'">
          <label translate>Province</label>
          <select
            name="state"
            (change)="optionSelected($event.target.value, 'state')"
          >
            <option [selected]="!driver.state">
              {{ 'Select Province' | translate }}
            </option>
            <option
              *ngFor="let stateOption of stateOptions"
              [value]="stateOption.id"
              [selected]="stateOption.id === driver.state"
            >
              {{ stateOption.name }}
            </option>
          </select>
        </ng-container>
      </div>
    </div>

    <!-- zip and phone -->
    <div class="form-row">
      <div>
        <label translate>Zip</label>
        <input
          placeholder="{{ 'Zip' | translate }}"
          formControlName="zipcode"
        />
      </div>
      <div formGroupName="profile">
        <label translate>Phone</label>
        <input
          type="text"
          placeholder="{{ 'Phone' | translate }}"
          formControlName="phoneNumber"
        />
      </div>
    </div>

    <!-- markets -->
    <div class="form-row">
      <div class="single-row">
        <label translate>Markets</label>
        <tag-input
          #tagInput
          name="tags"
          [selectedOptions]="driver.tags"
          (onSelect)="optionSelected($event, 'tags')"
        >
        </tag-input>
      </div>
    </div>

    <!-- custom field data -->
    <div
      class="form-row"
      formGroupName="customFieldData"
      *ngIf="driver.hasOwnProperty('customFieldData')"
    >
      <div class="single-row" *ngFor="let field of customFields | keyvalue">
        <label>{{ field.value.displayName }}</label>
        <input
          type="text"
          placeholder="{{ 'Value' | translate }}"
          [name]="field.key"
          [formControlName]="field.key"
        />
      </div>
    </div>

    <!-- potential errors -->
    <div *ngIf="errors && errors.length" class="notifications-container">
      <notification *ngFor="let error of errors">{{ error }}</notification>
    </div>

    <!-- save and delete buttons -->
    <div class="buttons-row">
      <button
        type="button"
        class="btn btn-inverted-delete"
        (click)="remove()"
        [disabled]="loading"
      >
        <i class="icomoon icon-delete"></i> {{ 'Delete' | translate }}
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!editDriverForm.dirty || !editDriverForm.valid"
        [ngClass]="{ loading: loading }"
        translate
      >
        Save Changes
      </button>
    </div>
  </form>
</div>
