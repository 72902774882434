import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TagSerializer } from './tag.serializer';
import { ResourceService } from '../shared/resource.service';
import { Tag } from './tag';

@Injectable()
export class TagService extends ResourceService<Tag> {

  constructor(http: HttpClient) {
    super(http, 'tags/', new TagSerializer());
   }
}
