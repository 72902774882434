import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { forkJoin } from 'rxjs';

// libraries
import {
  difference,
  find as _find,
  findIndex,
  get,
  remove,
  uniqBy,
} from 'lodash';

// models
import { User } from '../../users/user';
import { Driver } from '../driver';
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { CondensedTrip } from '../../trips/condensed-trip';
import { Preference } from '../../preferences/preference';

// services
import { CondensedTripService } from '../../trips/condensed-trip.service';
import { TripService } from '../../trips/trip.service';
import { PreferenceService } from '../../preferences/preference.service';

// components
import { DriverTripFiltersDialogComponent } from '../driver-trip-filters-dialog/driver-trip-filters-dialog.component';
import { ColumnToggleComponent } from '../../shared/column-toggle/column-toggle.component';
import { NewTripDialogComponent } from '../../trips/new-trip-dialog/new-trip-dialog.component';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import {
  FieldOption,
  ExportDialogComponent,
  ExportDialogData,
} from '../../shared/export-dialog/export-dialog.component';

// table columns and actions
import {
  AVAILABLECOLUMNS,
  DISPLAYEDCOLUMNS,
  SINGLEROWACTIONS,
} from './columns';

// constants
import { TRIPSIGNATUREIMAGETYPE } from '../../app.constants';

// utilities
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'driver-all-trips',
  templateUrl: './driver-all-trips.component.html',
  styleUrls: ['./driver-all-trips.component.scss'],
})
export class DriverAllTrips implements OnInit {
  @Input() driver: Driver;

  user: User;
  loading = false;
  errors = [];
  appliedFilters: any[] = [];
  displayedColumns = [...DISPLAYEDCOLUMNS];
  availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  search = '';
  tableConfig = {
    hasHeader: true,
    hasCustomFields: true,
    filterQuery: false,
    service: CondensedTripService,
    preferenceKey: 'DriverAllTripsComponent-CondensedTripService',
    query: {
      serializer: 'AllTrips',
      exclude_cf: true,
      driver: '',
    },
    collectionTitle: this.translationService.instant('Trips'),
    noResultsText: this.translationService.instant('a trip'),
    sortBy: '-start_time',
    sortDirection: 'asc',
    menuOptions: [...SINGLEROWACTIONS(this.translationService)],
    newRecordModal: () => this.openAddTrip(),
  };
  signatureImageType = TRIPSIGNATUREIMAGETYPE;

  // dropdown actions
  multipleActionDropdownOptions = [
    { action: 'export', name: 'Export', button: true },
    { action: 'void', name: 'Void', button: true, disabled: true },
  ];
  confirmDialog: MatDialogRef<any>;
  enabledFeatures: string[] = [];
  allLoadedTrips: CondensedTrip[] = [];
  selectedTrips = [];
  filters = [];
  allSelected = false;
  count = 0;

  // duplicates
  checkingDuplicates = false;
  preference: Preference;

  @ViewChild('columnToggle', { static: false })
  columnToggle: ColumnToggleComponent;

  @ViewChild('tripsTable', { static: false }) tripsTable: FancyTableComponent;

  filterDate = new Date();
  filtersDialog: DriverTripFiltersDialogComponent;
  appliedFilterQueryKeys = {
    customer: 'jobevent__customer_organization',
    project: 'jobevent__job__project',
    job: 'jobevent__job',
    edited: 'edited',
    incomplete: 'completed',
    duplicates: 'only_dupes',
    retake: 'retake_status',
    uninvoiced: 'uninvoiced',
    startDate: 'jobevent__shift1_start__gte',
    endDate: 'jobevent__shift1_start__lte',
  };

  constructor(
    private translationService: TranslateService,
    private tripService: TripService,
    private preferenceService: PreferenceService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.tableConfig.query = {
      ...this.tableConfig.query,
      driver: this.driver.id,
    };
  }

  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  openAddTrip() {
    const dialog = this.dialog.open(NewTripDialogComponent, {
      width: '455px',
    });

    const driver = { ...this.driver };
    const carrier = {
      id: driver.carrierId,
      name: driver.carrierOrganizationName,
      carrier: {
        name: driver.carrierName,
        id: driver.carrierId,
      },
    };
    dialog.componentInstance.model.driver = driver;
    dialog.componentInstance.model.truck = driver.latestTruck;
    dialog.componentInstance.model.carrier = carrier;
    // dialog.componentInstance.disabledFields = {
    //   driver: true,
    //   carrier: true,
    // };
    dialog.componentInstance.callback = this.saveTripCallback;
  }

  saveTripCallback = () => {
    this.getTrips();
  };

  openFilters() {
    const dialog = this.dialog.open(DriverTripFiltersDialogComponent, {
      width: '430px',
    });
    if (dialog) {
      dialog.componentInstance.title =
        this.translationService.instant('Filter Trips');
      dialog.componentInstance.callback = (res) => this.filterChanges(res);

      let startDate = get(
        _find(this.appliedFilters, { key: 'startDate' }),
        'values'
      );
      if (startDate && startDate.hasOwnProperty('year')) {
        dialog.componentInstance.model.startDate = new Date(startDate);
      }
      let endDate = get(
        _find(this.appliedFilters, { key: 'endDate' }),
        'values'
      );
      if (endDate && endDate.hasOwnProperty('year')) {
        dialog.componentInstance.model.endDate = new Date(endDate);
      }

      dialog.componentInstance.model.job = get(
        _find(this.appliedFilters, { key: 'job' }),
        'values'
      );
      dialog.componentInstance.model.project = get(
        _find(this.appliedFilters, { key: 'project' }),
        'values'
      );
      dialog.componentInstance.model.customer = get(
        _find(this.appliedFilters, { key: 'customer' }),
        'values'
      );
      dialog.componentInstance.model = Object.assign(
        dialog.componentInstance.model,
        this.appliedFilters.reduce((acc, filter) => {
          acc[filter.key] = filter.values;
          return acc;
        }, {})
      );
      this.filtersDialog = dialog.componentInstance;
    }
  }

  filterChanges(filterRes): void {
    let falseyFilters = [];
    const displayKeys = {
      duplicates: 'Duplicates',
    };

    Object.keys(filterRes).forEach((key) => {
      if (filterRes[key]) {
        const query = {};
        let values, displayValues;
        values =
          filterRes[key] && key === 'tags'
            ? filterRes[key].map((v) => v.name).join(',')
            : filterRes[key];
        displayValues =
          filterRes[key] && filterRes[key]['name']
            ? filterRes[key]['name']
            : values;
        if (typeof values === 'boolean') {
          if (key === 'incomplete' && values) {
            displayValues = values.toString();
            displayValues =
              displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
            let filterValue = (!values).toString();
            filterValue =
              filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
            query[this.appliedFilterQueryKeys[key]] = filterValue;
          } else if (key === 'retake' && values) {
            values = 'requested';
            query[this.appliedFilterQueryKeys[key]] = values;
          } else if (values) {
            values = values.toString();
            values = values.charAt(0).toUpperCase() + values.slice(1);
            query[this.appliedFilterQueryKeys[key]] = values;
          }
        } else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
          if (typeof values === 'string') {
            query[this.appliedFilterQueryKeys[key]] = values;
          } else {
            query[this.appliedFilterQueryKeys[key]] =
              filterRes[key] && filterRes[key].id
                ? filterRes[key].id
                : filterRes[key];
          }
        } else if (values) {
          query[this.appliedFilterQueryKeys[key]] =
            filterRes[key] && (filterRes[key].id || filterRes[key]);
        }
        let filter = new FilterOption({
          filterType:
            ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
          key,
          title: key.charAt(0).toUpperCase() + key.slice(1),
          displayValues: displayValues || null,
          displayKey: displayKeys[key] || null,
          values,
          query,
        });
        if (filter.values === 'False' || !filter.values) {
          falseyFilters.push(filter);
        }
        let existingFilterIndex = findIndex(this.appliedFilters, { key: key });
        if (existingFilterIndex !== -1) {
          this.appliedFilters[existingFilterIndex] = filter;
        } else {
          this.appliedFilters.push(filter);
        }
      } else {
        let existingFilter = _find(this.appliedFilters, { key: key });
        if (existingFilter) {
          remove(this.appliedFilters, existingFilter);
        }
      }
    });
    this.appliedFilters = uniqBy(
      difference(this.appliedFilters, falseyFilters),
      'key'
    );
  }

  setSelectedAction(option): void {
    switch (option.name) {
      case 'Export':
        this.exportSelectedTrips();
        break;
      case 'Void':
        this.voidSelectedTrips();
        break;
    }
  }

  exportSelectedTrips() {
    const selectedTrips = [...this.selectedTrips];
    let { params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters,
      selectedTrips,
      [],
      this.allSelected,
      this.search
    );
    if (this.enabledFeatures.includes('hasExportTracking')) {
      scope['markAsExported'] = true;
    }
    if (this.enabledFeatures.includes('hasExportProtection')) {
      params = params.set('unexported', 'True');
    }

    params = params.set('driver', this.driver.id);

    this.tripService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'trips',
            buttonText: this.translationService.instant('Export Data to CSV'),
            callback: () => {
              this.tripsTable.deselectAll();
            },
            fields,
            params,
            scope,
            service: this.tripService,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  voidSelectedTrips() {
    const trips = [...this.selectedTrips];
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Trips?',
      body: 'These trips will be marked as "Void" and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.tripsTable.loading = true;
        forkJoin(
          trips.map((trip) =>
            this.tripService.save({ id: trip.id, void: true })
          )
        ).subscribe(
          () => this.getTrips(),
          (err) => {
            this.tripsTable.loading = false;
            this.errors = err;
          }
        );
      }
      this.confirmDialog = null;
      this.tripsTable.deselectAll();
    });
  }

  voidTrip(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Trip?',
      body: 'This trip will be marked as "Void" and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: true }).subscribe(
          (result) => {
            trip.void = true;
            trip.loading = false;
            this.getTrips();
          },
          (err) => {
            this.errors = err;
            trip.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  duplicateCheck(): void {
    if (!this.checkingDuplicates) {
      const filter = {
        filterType: 'text',
        key: 'duplicates',
        title: 'Duplicates',
        displayValues: 'True',
        values: true,
        query: {
          only_dupes: 'True',
        },
      } as FilterOption;
      const oldFilters = this.appliedFilters.map((f) => f);
      oldFilters.push(filter);
      this.appliedFilters = oldFilters;
      this.checkingDuplicates = !this.checkingDuplicates;
    } else {
      this.appliedFilters = this.appliedFilters.filter(
        (f) => f.key !== 'duplicates'
      );
      this.checkingDuplicates = !this.checkingDuplicates;
    }
  }

  removeFilter(filter): void {
    remove(this.appliedFilters, filter);
    let duplicateFilter = _find(this.appliedFilters, { key: 'duplicates' });
    if (!duplicateFilter) {
      this.checkingDuplicates = false;
    }
    this.savePreferences(this.appliedFilters);
    this.getTrips();
  }

  savePreferences(filters) {
    const preference = this.tripsTable.preference;
    if (preference) {
      this.preference = {
        ...preference,
        blob: { filters: filters },
      };
      this.preferenceService.save(this.preference).subscribe((updatedPref) => {
        this.preference = updatedPref;
      });
    }
  }

  unvoidTrip(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unvoid Trip?',
      body: 'This trip will be unmarked as "Void" and will be visible for the Job.',
      close: 'Cancel',
      accept: 'Unvoid',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      this.tripsTable.loading = true;
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: false }).subscribe(
          (result) => {
            trip.void = false;
            trip.loading = false;
            this.getTrips();
          },
          (err) => {
            this.errors = err;
            trip.loading = false;
            this.tripsTable.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  getTrips() {
    this.tripsTable.getRecords();
  }

  menuAction([name, trip]): void {
    switch (name) {
      case 'void':
        this.voidTrip(trip);
        break;
      case 'unvoid':
        this.unvoidTrip(trip);
        break;
    }
  }

  selector(event: {
    allSelected: boolean;
    selection: SelectionModel<CondensedTrip>;
    exclusion: SelectionModel<CondensedTrip>;
  }) {
    this.allSelected = event.allSelected;

    this.selectedTrips = this.allSelected
      ? (this.selectedTrips = this.allLoadedTrips.filter(
          (t) => !event.exclusion.selected.some((ex) => ex.id === t.id)
        ))
      : [...event.selection.selected];

    this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map(
      (opt) =>
        opt.action === 'void'
          ? {
              ...opt,
              disabled:
                event.selection.selected.length === 0 && !event.allSelected,
            }
          : opt
    );
  }

  onDataLoaded({ data }: { data: CondensedTrip[] }) {
    this.tableConfig.menuOptions[0].returnTo =
      window.location.pathname + window.location.search;
    this.tableConfig.menuOptions[3].returnTo =
      window.location.pathname + window.location.search;
    this.allLoadedTrips = [...data];
    if (
      this.tripsTable.preference &&
      this.tripsTable.preference.blob &&
      this.tripsTable.preference.blob.filters
    ) {
      this.checkingDuplicates = this.tripsTable.preference.blob.filters.some(
        (f: FilterOption) => f.key === 'duplicates'
      );
    }
  }
}
