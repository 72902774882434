<div class="assignment-trips new-table">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="ticket">
      <mat-cell *matCellDef="let row" class="medium">
        <a [routerLink]="['/trips', row.id, 'edit']" routerLinkActive="active" translate>
          <ng-container *ngIf="activeTrips">
            <div class="value" translate>Current Trip</div>
          </ng-container>
          <ng-container *ngIf="!activeTrips">
            <div class="value" *ngIf="!activeTrips" translate>
              Trip {{ row.index }} <i *ngIf="row.origin === 'scale-sync'" class="icon-sync"></i>
            </div>
            <div class="sub-title ticket-number">
              <span><i class="icon-ticket"></i></span> 
              <span class="ticket-number-value" title="{{ row.ticketNumbers }}">{{ row.ticketNumbers || '--' }}</span>
            </div>
          </ng-container>
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="total-time">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value">{{ row.completedTripTime }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="en-route1">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value">{{ row.loadingEnRouteTime }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="loading">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value">{{ row.loadingTime }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="en-route2">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value">{{ row.unloadingEnRouteTime }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="unloading">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value">{{ row.unloadingTime }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="weight">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value" *ngIf="row.weight && row.pluralizedWeightUnit">{{ row.weight || '--' }} {{ row.pluralizedWeightUnit }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="invoice-total">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value"></div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expense-total">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value"></div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignments">
      <mat-cell *matCellDef="let row" class="medium">
        <div class="value"></div>
      </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: displayedColumns;" [class.current]="row.currentTrip"></mat-row>

    <notification *ngFor="let error of errors">{{error}}</notification>
    <my-loader *ngIf="loading"></my-loader>
  </mat-table>
</div>
