import { Component, Input, OnInit } from '@angular/core';
import { Driver } from './../driver';
import { Assignment } from '../../assignments/assignment';

/**
 * Driver Name Component: Displays a driver's shift status using green or red
 * dots beside their name. The driver's name may be truncated if it exceeds the
 * available space.
 *
 * On hover, a popover displays the driver's photo or photo icon along with the
 * full, untruncated name, organization name, phone number, and CDL.
 *
 * @example
 * <driver-name [driver]="driver" [status]="false"></driver-name>
 */
@Component({
  selector: 'driver-name',
  templateUrl: './driver-name.component.html',
  styleUrls: ['./driver-name.component.scss'],
})
export class DriverNameComponent implements OnInit {
  @Input() driver: Driver;
  @Input() assignment: Assignment;
  status: 'active' | 'inactive' | 'warning' | 'complete' | 'nodata' = 'inactive';
  hover = false;

  constructor() {}

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    if (this.assignment) {
      const startTime = Date.parse(this.assignment.shifts && this.assignment.shifts[0] && this.assignment.shifts[0].startTime);
      if (Date.now() > startTime && !!!(this.assignment.shifts[0] && this.assignment.shifts[0].endTime)) {
        this.status = Date.now() < (startTime + 86400000) ? 'active' : 'warning';
      } else if (this.assignment.shifts[0] && this.assignment.shifts[0].endTime) {
        this.status = 'complete';
      } else if (startTime && this.assignment.trips.length === 0 && this.assignment.predictedTrips.length === 0) {
        this.status = 'nodata';
      }
    }
  }
}
