import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formattedSeconds' })
export class FormattedSecondsPipe implements PipeTransform {
  transform(seconds: string, type?: 'minutes' | 'hours'): string {
    if (!seconds) { return 'N/A'; }
    const minutes = Math.floor(Number(seconds) / 60);
    if (type && type === 'hours') {
      return (Math.floor(minutes / 60)) + 'h';
    } else {
      return minutes > 1440 ?
        (Math.floor(minutes / 1440)) + 'd ' + (Math.floor((minutes - 1440) / 60)) + 'h ' + (minutes % 60) + 'm' :
        minutes > 60 ?
        (Math.floor(minutes / 60)) + 'h ' + (minutes % 60) + 'm' :
        (minutes % 60) + 'm';
    }
  }
}
