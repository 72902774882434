"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column}.app-content[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 265px)!important}.app-content[_ngcontent-%COMP%]   .hauler-shares-container[_ngcontent-%COMP%]{padding:30px}.app-content[_ngcontent-%COMP%]   .hauler-shares-container[_ngcontent-%COMP%]   .dropdown-arrow[_ngcontent-%COMP%]{margin-top:-4px}.app-content[_ngcontent-%COMP%]   .hauler-shares-container[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#58b4f9;text-decoration:none}.app-content[_ngcontent-%COMP%]   .hauler-shares-container[_ngcontent-%COMP%]   .currency[_ngcontent-%COMP%], .app-content[_ngcontent-%COMP%]   .hauler-shares-container[_ngcontent-%COMP%]   .numeric[_ngcontent-%COMP%]{margin:0 auto}"];
exports.styles = styles;
