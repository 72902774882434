"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var esb_order_serializer_1 = require("./esb-order.serializer");
var EsbService = /** @class */ (function () {
    function EsbService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.progress = 0;
    }
    EsbService.prototype.import = function (scope, file) {
        var _this = this;
        var importUrl = this.baseUrl + "imports/" + scope + "/";
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            formData.append('upload', file, file.name);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        var parsedResponse = JSON.parse(xhr.response);
                        observer.next(_this.convertBulkResponse(parsedResponse));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = function (event) {
                _this.progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', importUrl, true);
            api_service_1.requestHeaders(xhr, true);
            xhr.send(formData);
        });
    };
    EsbService.prototype.convertBulkResponse = function (res) {
        var _this = this;
        var errors = [];
        var orders = [];
        if (res && res.errors) {
            errors = res.errors.map(function (error) {
                return {
                    row: error['row'],
                    errors: _this.rescurseErrorObject(error['errors'], [])
                };
            });
        }
        if (res && res.success) {
            orders = res.success.map(function (record) { return new esb_order_serializer_1.EsbOrderSerializer().fromJson(record); });
        }
        return { errors: errors, orders: orders };
    };
    EsbService.prototype.rescurseErrorObject = function (obj, errors) {
        var _this = this;
        if (typeof obj === 'string') {
            errors.push(obj);
        }
        else {
            lodash_1.each(obj, function (msg, key) {
                if (Array.isArray(msg)) {
                    errors = errors.concat(msg.map(function (err) { return (key === 'non_field_errors' ? '' : key + ': ') + err; }));
                }
                else if (typeof msg === 'string') {
                    if (key !== 'non_field_errors') {
                        try {
                            errors.push(key + ': ' + msg);
                        }
                        catch (e) {
                            errors.push(msg);
                        }
                    }
                    else {
                        errors.push(msg);
                    }
                }
                else if (typeof msg === 'object') {
                    errors = _this.rescurseErrorObject(msg, errors);
                }
            });
        }
        return errors;
    };
    return EsbService;
}());
exports.EsbService = EsbService;
