"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[mat-dialog-content][_ngcontent-%COMP%]{max-height:80vh;overflow-y:scroll}p[_ngcontent-%COMP%]{font-size:16px;color:#595959;max-width:420px;margin:0}p.label[_ngcontent-%COMP%]{font-size:12px;font-weight:600;color:#7f8fa4}.question[_ngcontent-%COMP%]{display:flex;justify-content:space-between;margin-top:40px}.question[_ngcontent-%COMP%]:last-child{margin-bottom:30px}.options[_ngcontent-%COMP%]{font-size:18px}.options[_ngcontent-%COMP%]   mat-checkbox[_ngcontent-%COMP%]{margin-right:10px;margin-left:24px}"];
exports.styles = styles;
