"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var rxjs_1 = require("rxjs");
var core_2 = require("@ngx-translate/core");
var http_1 = require("@angular/common/http");
// material
var material_1 = require("@angular/material");
// libraries
var lodash_1 = require("lodash");
var moment = require("moment");
// services
var shift_service_1 = require("./../../shifts/shift.service");
var authentication_service_1 = require("../../shared/authentication.service");
// models
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var driver_1 = require("../driver");
// components
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var filters_dialog_component_1 = require("../../shared/filters-dialog/filters-dialog.component");
var column_toggle_component_1 = require("../../shared/column-toggle/column-toggle.component");
var new_shift_dialog_component_1 = require("../../shifts/new-shift-dialog/new-shift-dialog.component");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
// constants
var columns_1 = require("./columns");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var DriverShiftsComponent = /** @class */ (function () {
    function DriverShiftsComponent(route, shiftService, translationService, driverShiftService, authenticationService, router, dialog) {
        var _this = this;
        this.route = route;
        this.shiftService = shiftService;
        this.translationService = translationService;
        this.driverShiftService = driverShiftService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.dialog = dialog;
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS.slice();
        this.appliedFilters = [];
        this.search = '';
        this.tableConfig = {
            collectionTitle: this.translationService.instant('Shifts'),
            noResultsText: this.translationService.instant('a shift'),
            hasHeader: true,
            pageSize: 25,
            service: shift_service_1.ShiftReportService,
            filterQuery: false,
            preferenceKey: 'driverShifts',
            preferencesEnabled: true,
            query: {
                driver: '',
            },
            sortBy: 'driver__profile__last_name,driver__profile__first_name',
            sortDirection: 'asc',
            automaticallyHidePagination: false,
            newRecordModal: function () { return _this.openAddShift(); },
        };
        this.datePipe = new common_1.DatePipe('en-US');
        this.errors = [];
        this.allSelected = false;
        this.selectedShifts = [];
        this.allLoadedShifts = [];
        this.multipleActionDropdownOptions = [
            {
                name: this.translationService.instant('Export'),
                action: 'export',
                button: true,
                link: false,
            },
            {
                name: this.translationService.instant('Driver Shifts Export'),
                action: 'driver-shifts-export',
                link: false,
                button: true,
            },
        ];
        this.allSubscriptionsToUnsubscribe = [];
        this.exportCallback = function () { };
    }
    /**
     * Processes the current URL params and applies them as filters / queries to the table data requests
     */
    DriverShiftsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var enabledFeatures = this.authenticationService.enabledFeatures();
        var driverShiftsExportEnabled = enabledFeatures.includes('hasDriverShiftExport');
        if (!driverShiftsExportEnabled) {
            this.multipleActionDropdownOptions =
                this.multipleActionDropdownOptions.filter(function (option) { return option.action !== 'driver-shifts-export'; });
        }
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.search = result.qparams['search'] || '';
            if (result.qparams['sortBy']) {
                _this.tableConfig.sortBy = result.qparams['sortBy'] || '';
                _this.tableConfig.sortDirection = result.qparams['sortAsc'] || '';
            }
        }));
        if (this.appliedFilters.length === 0) {
            var startDate = moment();
            startDate = startDate.subtract(1, 'month');
            startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            var defaultDateFilter = new filter_option_1.FilterOption({
                filterType: 'date',
                default: true,
                key: 'startDate',
                title: this.translationService.instant('Start Date'),
                displayValues: startDate.format('MM/DD/YYYY') || null,
                values: startDate.toISOString(),
                query: {
                    start_time__gte: startDate.toISOString(),
                },
            });
            this.appliedFilters.push(defaultDateFilter);
        }
        this.tableConfig.query = __assign({}, this.tableConfig.query, { driver: this.driver.id });
    };
    DriverShiftsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * Opens the filter dialog and sets up the callback to handle changes made to any available filters
     */
    DriverShiftsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px',
        });
        dialog.componentInstance.filters = [
            {
                type: 'dateRange',
                field: 'dateRange',
                label: 'Date Range',
            },
            {
                type: 'boolean',
                field: 'truckChanged',
                label: 'Changed Trucks',
            },
        ];
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
        if (startDate) {
            dialog.componentInstance.model.startDate = new Date(startDate);
        }
        var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
        if (endDate) {
            dialog.componentInstance.model.endDate = new Date(endDate);
        }
        dialog.componentInstance.model.carrier = null;
        this.filtersDialog = dialog.componentInstance;
    };
    /**
     * Handles the filter changes and applys them to the table data API request
     *
     * @param {any} filterRes The filter change object
     */
    DriverShiftsComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            startDate: 'start_time__gte',
            endDate: 'end_time__lte',
            carrier: 'driver__carrier',
            truckChanged: 'truck_changed',
            vehicleBreakdown: 'vehicle_breakdown_duration__gte',
            includeLeasedFleetDrivers: 'include_leased_fleet_drivers',
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name']
                ? filterRes[key]['name']
                : values;
            if (typeof values === 'boolean') {
                if (values) {
                    values = values.toString();
                    values = values.charAt(0).toUpperCase() + values.slice(1);
                    query[queryKeys[key]] = values;
                }
                displayValues = values;
            }
            else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
                if (typeof values === 'string') {
                    query[queryKeys[key]] = values;
                }
                else {
                    query[queryKeys[key]] = values.toISOString();
                }
            }
            else if (filterRes[key]) {
                query[queryKeys[key]] =
                    filterRes[key] && filterRes[key].id
                        ? filterRes[key].id
                        : filterRes[key];
            }
            var filter = new filter_option_1.FilterOption({
                filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                key: key,
                title: (key.charAt(0).toUpperCase() + key.slice(1))
                    .replace(/([A-Z])/g, ' $1')
                    .trim(),
                displayValues: displayValues || null,
                values: values,
                query: query,
            });
            if (filter.values === 'False' || !filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    DriverShiftsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    /**
     * Drives the row selection functionality, including 'allSelected' states for paginated data
     *
     * @param {{ allSelected: boolean, selection: SelectionModel<ShiftReport>, exclusion: SelectionModel<ShiftReport> }} shift
     * The selection event object
     */
    DriverShiftsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        this.selectedShifts = this.allSelected
            ? (this.selectedShifts = this.allLoadedShifts.filter(function (t) { return !event.exclusion.selected.some(function (ex) { return ex.id === t.id; }); }))
            : event.selection.selected.slice();
    };
    DriverShiftsComponent.prototype.onDataLoaded = function (_a) {
        var data = _a.data;
        this.allLoadedShifts = data.slice();
    };
    /**
     * Makes an edit to either the startTime or endTime field and saves that edit to the API shift record
     *
     * @param {ShiftReport} shift The target shift report
     * @param {string} field The time field we will apply the edit to
     * @param {any} value The edit value for the time adjustment
     */
    DriverShiftsComponent.prototype.editTime = function (shift, field, value) {
        var _this = this;
        var saveData = { id: shift.id };
        var newTime = moment(value);
        if (field === 'start' && !newTime.isSame(shift.startTime)) {
            saveData.startTime = value;
            if (newTime.isAfter(shift.endTime)) {
                saveData.endTime = value;
            }
        }
        else if (field === 'end' && !newTime.isSame(shift.endTime)) {
            saveData.endTime = value;
            if (newTime.isBefore(shift.startTime)) {
                saveData.startTime = value;
            }
        }
        this.shiftService
            .save(saveData, { include_leased_fleet_drivers: 'True' })
            .subscribe(function (updates) {
            if (shift) {
                shift.startTime = updates.startTime;
                shift.endTime = updates.endTime;
            }
        }, function (err) {
            _this.errors = err;
        });
    };
    DriverShiftsComponent.prototype.openAddShift = function () {
        var _this = this;
        var dialog = this.dialog.open(new_shift_dialog_component_1.NewShiftDialogComponent, {
            width: '455px',
        });
        var driver = __assign({}, this.driver);
        dialog.componentInstance.driverForShift = driver;
        this.allSubscriptionsToUnsubscribe.push(dialog.componentInstance.shiftCreated.subscribe(function () {
            _this.shiftsFancyTable.getRecords();
        }));
    };
    DriverShiftsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedShifts();
                break;
            case 'driver-shifts-export':
                this.exportDriverShifts();
                break;
        }
    };
    DriverShiftsComponent.prototype.exportSelectedShifts = function () {
        var _this = this;
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, this.selectedShifts, [], this.allSelected, this.search), params = _a.params, scope = _a.scope;
        params = params.set('driver', this.driver.id);
        this.shiftService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'shifts',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: function () { return _this.shiftsFancyTable.deselectAll(); },
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.shiftService,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Generates a shift export using the pandas export API functionality
     */
    DriverShiftsComponent.prototype.exportDriverShifts = function () {
        var _this = this;
        var customUrl = 'drivers/shifts/pandas-export/';
        var scope = {
            include: [this.driver.id],
        };
        var params = new http_1.HttpParams();
        this.shiftService.getExportFields(customUrl).subscribe(function (fields) {
            var lockedFieldKeys = [
                'start_day_of_month',
                'end_day_of_month',
                'driver',
                'driver_unique_billing_id',
                'regot',
                'duration',
            ];
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'driver-shifts',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: function () { return _this.shiftsFancyTable.deselectAll(); },
                    fields: fields,
                    lockedFields: lockedFieldKeys,
                    params: params,
                    scope: scope,
                    service: _this.driverShiftService,
                    customUrl: customUrl,
                    excludeLeasedFleetOption: true,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Makes an edit to the adjustment field and saves that edit to the API shift record
     *
     * @param {ShiftReport} shift The target shift report
     * @param {any} value The edit value for the adjustment
     */
    DriverShiftsComponent.prototype.editAdjustment = function (shift, value) {
        var _this = this;
        var saveData = { id: shift.id, adjustment: Number(value) };
        if (saveData.adjustment !== shift.adjustment) {
            this.shiftService.save(saveData).subscribe(function (updates) {
                if (shift) {
                    shift.adjustment = updates.adjustment;
                }
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    return DriverShiftsComponent;
}());
exports.DriverShiftsComponent = DriverShiftsComponent;
