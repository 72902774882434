"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./no-driver-assignments.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("./no-driver-assignments.component");
var i4 = require("../../drivers/driver.service");
var i5 = require("@angular/material/dialog");
var styles_NoDriverAssignmentsComponent = [i0.styles];
var RenderType_NoDriverAssignmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoDriverAssignmentsComponent, data: {} });
exports.RenderType_NoDriverAssignmentsComponent = RenderType_NoDriverAssignmentsComponent;
function View_NoDriverAssignmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "title"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["No Assignments"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " does not have any assignments for the date selected. Do you want to dispatch ", " to a job? "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-cancel close"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dispatchDialog.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCreateNewAssignmentClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dispatch Driver "]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_3 = ""; _ck(_v, 7, 0, currVal_3); var currVal_4 = ""; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_NoDriverAssignmentsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { dialogRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["dialogRef", 2]], null, 0, null, View_NoDriverAssignmentsComponent_1))], null, null); }
exports.View_NoDriverAssignmentsComponent_0 = View_NoDriverAssignmentsComponent_0;
function View_NoDriverAssignmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "no-driver-assignments", [], null, null, null, View_NoDriverAssignmentsComponent_0, RenderType_NoDriverAssignmentsComponent)), i1.ɵdid(1, 114688, null, 0, i3.NoDriverAssignmentsComponent, [i4.DriverService, i5.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NoDriverAssignmentsComponent_Host_0 = View_NoDriverAssignmentsComponent_Host_0;
var NoDriverAssignmentsComponentNgFactory = i1.ɵccf("no-driver-assignments", i3.NoDriverAssignmentsComponent, View_NoDriverAssignmentsComponent_Host_0, { driverId: "driverId" }, { onCreateNewAssignment: "onCreateNewAssignment" }, []);
exports.NoDriverAssignmentsComponentNgFactory = NoDriverAssignmentsComponentNgFactory;
