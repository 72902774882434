import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { RuckitReplayComponent } from './ruckit-replay/ruckit-replay.component';

const routes: Routes = [
  { path: '', component: RuckitReplayComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RuckitReplayRoutingModule {}
