"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./event-alert.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("@angular/platform-browser");
var i6 = require("@ngx-translate/core");
var i7 = require("./event-alert.component");
var i8 = require("./event-alert.service");
var styles_EventAlertComponent = [i0.styles];
var RenderType_EventAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventAlertComponent, data: {} });
exports.RenderType_EventAlertComponent = RenderType_EventAlertComponent;
function View_EventAlertComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "alert-panel"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "display": 0 }), i1.ɵdid(5, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "display": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "panel-content update-available"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(9, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵppd(15, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (_co.showAlert ? "flex" : "none")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (_co.showAlert ? "flex" : "none")); _ck(_v, 5, 0, currVal_1); var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); var currVal_4 = ""; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.eventAlert && _co.eventAlert.description); _ck(_v, 10, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), (_co.eventAlert && _co.eventAlert.action))); _ck(_v, 14, 0, currVal_5); }); }
exports.View_EventAlertComponent_0 = View_EventAlertComponent_0;
function View_EventAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "event-alert", [], null, null, null, View_EventAlertComponent_0, RenderType_EventAlertComponent)), i1.ɵdid(1, 245760, null, 0, i7.EventAlertComponent, [i8.EventAlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_EventAlertComponent_Host_0 = View_EventAlertComponent_Host_0;
var EventAlertComponentNgFactory = i1.ɵccf("event-alert", i7.EventAlertComponent, View_EventAlertComponent_Host_0, {}, {}, []);
exports.EventAlertComponentNgFactory = EventAlertComponentNgFactory;
