"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tag-display.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("./tag-display.component");
var styles_TagDisplayComponent = [i0.styles];
var RenderType_TagDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagDisplayComponent, data: {} });
exports.RenderType_TagDisplayComponent = RenderType_TagDisplayComponent;
function View_TagDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.label; _ck(_v, 2, 0, currVal_1); }); }
function View_TagDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "value"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagDisplayComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.label && _co.tags) && _co.tags.length); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tagNames; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.tagNames ? _co.tagNames : "\u00A0"); _ck(_v, 1, 0, currVal_1); }); }
exports.View_TagDisplayComponent_0 = View_TagDisplayComponent_0;
function View_TagDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tag-display", [], null, null, null, View_TagDisplayComponent_0, RenderType_TagDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i4.TagDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TagDisplayComponent_Host_0 = View_TagDisplayComponent_Host_0;
var TagDisplayComponentNgFactory = i1.ɵccf("tag-display", i4.TagDisplayComponent, View_TagDisplayComponent_Host_0, { tags: "tags", label: "label" }, {}, []);
exports.TagDisplayComponentNgFactory = TagDisplayComponentNgFactory;
