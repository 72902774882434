"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var pay_sheet_filter_1 = require("./pay-sheet.filter");
var api_service_1 = require("../shared/api.service");
var PaySheetFilterService = /** @class */ (function () {
    function PaySheetFilterService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.nextUris = {};
    }
    PaySheetFilterService.prototype.getFilters = function (id, filterType, search, filters) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (filters === void 0) { filters = null; }
        var params = new http_1.HttpParams();
        params = params.set('page_size', '6');
        if (search) {
            params = params.set('filter_search', search);
        }
        for (var property in filters) {
            if (filters.hasOwnProperty(property)) {
                params = params.set(property, filters[property]);
            }
        }
        var filtersUrl = this.baseUrl + 'driver-pay/pay-sheets/' + id + '/filters/' + filterType + '/';
        return this.http.get(filtersUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
    };
    PaySheetFilterService.prototype.getNext = function (filterType) {
        var _this = this;
        if (this.nextUris[filterType]) {
            return this.http.get(this.nextUris[filterType], { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PaySheetFilterService.prototype.listFilters = function (id, filterType, query) {
        var _this = this;
        var params = new http_1.HttpParams();
        params = params.set('page_size', '6');
        for (var property in query) {
            if (query.hasOwnProperty(property)) {
                params = params.set(property, query[property]);
            }
        }
        var filtersUrl = this.baseUrl + 'driver-pay/pay-sheets/' + id + '/filters/' + filterType + '/';
        return this.http.get(filtersUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
    };
    PaySheetFilterService.prototype.listNext = function (filterType) {
        var _this = this;
        if (this.nextUris[filterType]) {
            return this.http.get(this.nextUris[filterType], { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PaySheetFilterService.prototype.extractData = function (filterType, res, condensed) {
        if (condensed === void 0) { condensed = false; }
        var resObj = res;
        this.nextUris[filterType] = resObj['next'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (filter) {
                return new pay_sheet_filter_1.PaySheetFilter(filter);
            });
        }
        else {
            return {};
        }
    };
    return PaySheetFilterService;
}());
exports.PaySheetFilterService = PaySheetFilterService;
