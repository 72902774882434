"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column}i[_ngcontent-%COMP%]{color:rgba(0,0,0,.25)}i.active[_ngcontent-%COMP%]{color:#27af5e}.actions-wrapper[_ngcontent-%COMP%]{position:relative;padding:30px}.actions-wrapper[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{position:absolute;top:30px;right:40px;height:57px;display:flex;align-items:center}.pay-sheets[_ngcontent-%COMP%]     .action-bar-content{min-height:72px!important;height:72px!important}.pay-sheets[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 200px)!important}.segmented-control[_ngcontent-%COMP%]{flex:0;height:unset}.segmented-control[_ngcontent-%COMP%]   .item[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{border-right:none}.segmented-control[_ngcontent-%COMP%]   .item[_ngcontent-%COMP%]:last-child   .label[_ngcontent-%COMP%]{border-right:1px solid #ced0da}.segmented-control[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{padding:4px 10px}"];
exports.styles = styles;
