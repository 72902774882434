"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../environments/environment");
var NotesService = /** @class */ (function () {
    function NotesService(http) {
        var _this = this;
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.saveNote = function (note) {
            return _this.http.post(_this.baseUrl + "notes/", note, {
                headers: _this.getHeaders(),
            });
        };
        this.patchNote = function (noteId, note) {
            return _this.http.patch(_this.baseUrl + "notes/" + noteId + "/", note, {
                headers: _this.getHeaders(),
            });
        };
        this.updateNote = function (note) {
            return _this.http.put(_this.baseUrl + "notes/" + note.id + "/", note, {
                headers: _this.getHeaders(),
            });
        };
        this.deleteNote = function (id) {
            return _this.http.delete(_this.baseUrl + "notes/" + id + "/", {
                headers: _this.getHeaders(),
            });
        };
    }
    NotesService.prototype.getHeaders = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return {
            Authorization: "Token " + currentUser.token,
        };
    };
    return NotesService;
}());
exports.NotesService = NotesService;
