import * as moment from 'moment';
import { Shift } from './shift';
import { DriverSerializer } from '../drivers/driver.serializer';
import { TruckSerializer } from '../trucks/truck.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ShiftSerializer {

  /**
  * @param  {any} json
  * @returns Shift
  */
  fromJson(json: any): Shift {
    json = camelcaseKeysDeep(json);
    const shift = new Shift();
    if (!json) { return shift; }

    shift.id = json.id;
    shift.driver = new DriverSerializer().fromJson(json.driver);
    shift.truck = new TruckSerializer().fromJson(json.truck);
    shift.startTime = json.startTime;
    shift.endTime = json.endTime;
    // json.adjustment is returned as `00.0`, we'll convert this to minutes
    shift.adjustment = Number(json.adjustment) / 60;
    shift.haulTotal = json.haulTotal;
    shift.jobeventsCount = json.jobeventsCount;
    shift.tripsCount = json.tripsCount;
    if (json.totalHours) {
      shift.totalHours = json.totalHours;
    } else {
      shift.totalHours = moment().diff(json.startTime, 'hours', true);
    }
    shift.totalTons = json.totalTons;
    shift.autoClosed = json.autoClosed;

    return shift;
  }

  /**
   * @param  {Shift} Shift
   * @returns any
   */
  toJson(shift: Shift): any {
    // need to format our seconds into the server's expected `00.0` format
    const adjustment = shift.adjustment ? (shift.adjustment * 60) + '.0' : undefined;
    let json = {
      id: shift.id,
      driver: shift.driver,
      truck: shift.truck,
      startTime: shift.startTime,
      endTime: shift.endTime,
      adjustment: adjustment,
      haulTotal: shift.haulTotal,
      jobeventsCount: shift.jobeventsCount,
      tripsCount: shift.tripsCount,
      totalHours: shift.totalHours,
      totalTons: shift.totalTons
    };
    return decamelizeKeysDeep(json);
  }
}
