"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-menu-content{min-width:200px;max-height:calc(60vh - 48px)}.header[_ngcontent-%COMP%]{padding:12px 15px;border-bottom:1px solid #e4e4e4}.column[_ngcontent-%COMP%]{border-bottom:1px solid #e4e4e4;padding:12px 15px;display:flex;align-items:center;justify-content:space-between;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.column[_ngcontent-%COMP%]:last-child{border-bottom:0}.column[_ngcontent-%COMP%]     .mat-checkbox label{margin:0}.column[_ngcontent-%COMP%]   .drag-indicator[_ngcontent-%COMP%]{width:18px;height:18px;font-size:18px}.column[_ngcontent-%COMP%]   .drag-indicator[_ngcontent-%COMP%]:hover{cursor:move}.column.drag-over[_ngcontent-%COMP%]{background-color:#e4e4e4}  .column-toggle-menu+* .cdk-overlay-pane{margin-top:10px}  .column-toggle-button.mat-icon-button{width:24px;height:24px;line-height:24px}  .column-toggle-button.mat-icon-button .mat-icon{line-height:24px!important}form[_ngcontent-%COMP%]{height:390px}"];
exports.styles = styles;
