"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TelephonePipe = /** @class */ (function () {
    function TelephonePipe() {
    }
    TelephonePipe.prototype.transform = function (input) {
        if (!input) {
            return '';
        }
        switch (input.length) {
            case 7:
                return input.slice(0, 3) + '-' + input.slice(3);
            case 10:
                return input.slice(0, 3) + '-' + input.slice(3, 6) + '-' + input.slice(6);
            default:
                return input;
        }
    };
    return TelephonePipe;
}());
exports.TelephonePipe = TelephonePipe;
