<ng-container *ngFor="let slot of filledSlots; let i = index">
  <div class="slot filled" *ngIf="slot.driver && !slot.editSlot" (click)="select(shift, slot, i)">
    <div class="icon">
      <span *ngIf="!slot.driver.image">{{ slot.driver.name[0] }}</span>
      <img *ngIf="slot.driver.image" [src]="slot.driver.dispatchImage" />
    </div>
    <div class="info">
      <h3>{{ slot.driver.name }}</h3>
      <h4>{{ slot.truck ? slot.truck.displayName : '--' }}</h4>
    </div>
    <div class="actions" *ngIf="editMode && slot.assignment">
      <i class="icon-delete" (click)="remove(slot)"></i>
    </div>
    <div class="actions" *ngIf="!editMode && slot.assignment">
      <h4>{{ 'Start Time' | translate }}: {{ slot.assignment.uniqueStart }}</h4>
      <h5 translate>
        <ng-container *ngIf="slot.assignment.maxNumberOfLoads === 0">All Day</ng-container>
        <ng-container *ngIf="slot.assignment.maxNumberOfLoads === 1">Single Load</ng-container>
        <ng-container *ngIf="slot.assignment.maxNumberOfLoads > 1">{{ slot.assignment.maxNumberOfLoads }} Loads
        </ng-container>
      </h5>
    </div>
  </div>

  <ng-container *ngIf="slot.editSlot">
    <div class="slot" *ngIf="slot.editSlot">
      <div class="icon" *ngIf="slot.driver">
        <span *ngIf="!slot.driver.image">{{ slot.driver.name[0] }}</span>
        <img *ngIf="slot.driver.image" [src]="slot.driver.dispatchImage"/>
      </div>
      <div class="info" *ngIf="slot.driver">
        <h3>{{ slot.driver.name }}</h3>
        <h4>{{ slot.truck ? slot.truck.displayName : '--' }}</h4>
      </div>
    </div>
    <div class="start-time">
      <label translate>Start Time: </label>
      <input type="text" 
              required
              name="driverStartTime"
              class="timepicker"
              [placeholder]="slot.uniqueStart ? slot.uniqueStart : (jobEvent['shift' + (selectedShift + 1) + 'StartTimestamp'] | date: 'h:mm a')"
              [(ngModel)]="slot.uniqueStart"
              [options]="{
                disableTextInput: false,
                'timeFormat': 'g:i A',
                showDuration: false,
                step: 5,
                minTime: jobEvent['shift' + (selectedShift + 1) + 'StartTimestamp'] | date: 'h:mm a',
                maxTime: jobEvent['shift' + (selectedShift + 1) + 'EndTimestamp'] | date: 'h:mm a'
              }"/>
      <span class="icon-next-arrow"></span>
    </div>
    <div class="load-type grouped">
      <input type="radio" name="{{ 'max-loads-' + slot.id }}" required [(ngModel)]="slot.numberOfLoadsType" value="numbered"/>
      <label translate>Loads:</label>
      <input type="number" name="{{ 'load-count-' + slot.id }}" [disabled]="slot.numberOfLoadsType !== 'numbered'" [(ngModel)]="slot.maxNumberOfLoads" min="1"/>    
    </div>
    <div class="load-type">
      <input type="radio" name="{{ 'max-loads-' + slot.id }}" required (change)="slot.maxNumberOfLoads = 0" [(ngModel)]="slot.numberOfLoadsType" [value]="'allDay'"/>
      <label translate>All Day</label>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="emptySlots && emptySlots.length && !editMode">
  <div class="slot empty" (click)="select(shift)">
    <div class="icon">
      <i class="icon-plus"></i>
    </div>
    <div class="info">
      <h3 translate>Assign a Truck and Driver</h3>
    </div>
  </div>
</ng-container>

<div class="bottom-action">
  <div class="notification-container">
    <notification [autoClose]="true" level="info" *ngIf="jobEvent && jobEvent.lastDispatchedTime">
      <ng-container translate>
        Dispatched at {{ jobEvent.lastDispatchedTime | date:'shortTime'}} on
        {{ jobEvent.lastDispatchedTime | date:'shortDate' }}
      </ng-container>
      <ng-container *ngIf="jobEvent.lastDispatchedBy" translate>
        by {{ jobEvent.lastDispatchedBy.firstName }} {{ jobEvent.lastDispatchedBy.lastName }}
      </ng-container>
    </notification>
  </div>
  <!-- <button *ngIf="editAssignment" translate
    (click)="updateAssignment(selectedAssignment)">SAVE ASSIGNMENT</button> -->
  <button *ngIf="editMode" translate (click)="editMode = false">SAVE CHANGES</button>
  <button *ngIf="!editMode && filledSlots && filledSlots.length" translate (click)="dispatch()">
    DISPATCH DRIVERS
  </button>
</div>
