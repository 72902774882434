"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".pagination[_ngcontent-%COMP%]{display:flex;justify-content:flex-end;margin:30px}.pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]{display:flex;list-style:none;height:36px;width:auto;margin:0;padding:0;border-radius:4px;background-color:#fff;border:1px solid rgba(0,0,0,.15)}.pagination[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{height:34px;min-width:36px;line-height:36px;margin:0;padding:0;vertical-align:middle;color:#354052;cursor:pointer;font-size:14px;font-weight:600;text-align:center;border-right:1px solid rgba(0,0,0,.15)}.pagination[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:last-child{border-right:0}.pagination[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{display:inline-block;min-width:36px;height:36px;line-height:30px;text-align:center}.pagination[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{display:inline-block;font-size:34px;height:36px;line-height:32px;margin-top:2px;vertical-align:middle}.pagination[_ngcontent-%COMP%]   li.active[_ngcontent-%COMP%], .pagination[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:hover{color:#015bc5}.pagination[_ngcontent-%COMP%]   li.disabled[_ngcontent-%COMP%], .pagination[_ngcontent-%COMP%]   li.ellipsis[_ngcontent-%COMP%]{pointer-events:none;color:#ced0da}.pagination[_ngcontent-%COMP%]   li.ellipsis[_ngcontent-%COMP%]{font-weight:800;font-size:17px;letter-spacing:-1px;line-height:1px}"];
exports.styles = styles;
