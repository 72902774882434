"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
// angular material
var material_1 = require("@angular/material");
// services
var api_service_1 = require("../api.service");
var authentication_service_1 = require("../authentication.service");
var preference_service_1 = require("../../preferences/preference.service");
// constants
var export_dialog_options_constants_1 = require("./export-dialog-options.constants");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ExportDialogComponent = /** @class */ (function () {
    function ExportDialogComponent(dialogRef, dialog, data, authenticationService, preferenceService) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.loading = false;
        this.exportSubmitted = false;
        this.exportAllFields = 'export_fields_all';
        this.showFieldOptions = true;
        this.fieldsLocked = false;
        this.showExportDisplayedColumns = false;
        this.exportDisplayedColumns = false;
        this.errors = [];
        this.TYPELIST_OPTIONS = export_dialog_options_constants_1.TYPELIST_OPTIONS;
        if (this.includesImageOptions()) {
            this.data.scope.includeImages = false;
        }
        if (this.data.lockedFields && this.data.lockedFields.length) {
            this.fieldsLocked = true;
        }
        else if (this.includesFieldOptions()) {
            this.data.scope.includeLegacy = false;
            this.columns = this.generateFieldColumns(this.data.fields);
            this.preferenceService
                .list({
                name: this.data.type + '-export-preferences',
                type: 'user',
                profile: this.authenticationService.user().id,
            })
                .subscribe(function (preferences) { return _this.setUserPreferences(preferences); });
        }
        if (this.includesFormatOptions()) {
            this.data.dataFormats = ['summary', 'detail'];
            this.selectedDataFormat = this.data.dataFormats[0];
        }
        if (this.data && this.data.displayedColumns) {
            this.displayedColumns = this.generateFieldColumns(this.data.displayedColumns);
            this.showExportDisplayedColumns = true;
        }
    }
    ExportDialogComponent.prototype.includesImageOptions = function () {
        return export_dialog_options_constants_1.TYPELIST_OPTIONS.IMAGE_OPTIONS.indexOf(this.data.type) !== -1;
    };
    ExportDialogComponent.prototype.includesFieldOptions = function () {
        return export_dialog_options_constants_1.TYPELIST_OPTIONS.FIELD_OPTIONS.indexOf(this.data.type) !== -1;
    };
    ExportDialogComponent.prototype.includesFormatOptions = function () {
        return export_dialog_options_constants_1.TYPELIST_OPTIONS.FORMAT_OPTIONS.indexOf(this.data.type) !== -1;
    };
    ExportDialogComponent.prototype.setUserPreferences = function (preferences) {
        var _this = this;
        if (preferences[0] &&
            preferences[0].name === this.data.type + '-export-preferences') {
            this.preference = preferences[0];
            this.data.scope.includeImages = !!this.preference.blob.includeImages;
            this.data.scope.includeLegacy = !!this.preference.blob.includeLegacy;
            if (this.preference.blob.fields) {
                this.data.scope.fields = this.preference.blob.fields;
                this.showFieldOptions = !!!(this.preference.blob && this.preference.blob.fields.length);
                this.columns.forEach(function (column) {
                    column.forEach(function (field) {
                        field.selected = !!_this.preference.blob.fields.find(function (selectedField) { return field.key === selectedField; });
                    });
                });
            }
        }
    };
    ExportDialogComponent.prototype.saveUserPreferences = function () {
        var _this = this;
        this.preference = {
            name: this.data.type + '-export-preferences',
            type: 'user',
            profile: this.authenticationService.user().id,
            blob: this.data.scope,
        };
        this.preferenceService
            .save(this.preference)
            .subscribe(function (preference) { return (_this.preference = preference); });
    };
    ExportDialogComponent.prototype.getFieldsForExport = function () {
        if (this.exportAllFields === 'export_fields_all') {
            return null;
        }
        else if (this.showExportDisplayedColumns) {
            return this.fieldSelectionList(this.displayedColumns);
        }
        else {
            return this.fieldSelectionList(this.columns);
        }
    };
    ExportDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        if (this.fieldsLocked) {
            this.data.scope.fields = this.data.lockedFields;
        }
        else if (this.data.fields) {
            this.data.scope.fields = this.getFieldsForExport();
            this.saveUserPreferences();
        }
        if (this.data.removeFieldsProperty && this.data.scope.fields === null) {
            delete this.data.scope.fields;
        }
        if (this.data.dataFormats) {
            this.data.scope.summaryOnly = this.selectedDataFormat === 'summary';
        }
        if (this.data.service) {
            var scope = decamelizeKeysDeep(this.data.scope);
            var request = this.data.service.export(scope, this.data.params.toString());
            if (this.data.customUrl) {
                request = this.data.service.export(scope, this.data.params.toString(), null, this.data.customUrl);
            }
            request.subscribe(function () {
                _this.onSubmitSuccess();
            }, function (err) {
                if (err[0] === 'text: ok') {
                    _this.onSubmitSuccess();
                    return;
                }
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
        if (this.data.callback) {
            if (this.data.type === 'driver pay') {
                this.data.callback(this.data.scope.driverPayFormats);
            }
            else {
                this.data.callback();
            }
        }
    };
    ExportDialogComponent.prototype.onSubmitSuccess = function () {
        var _this = this;
        this.loading = false;
        this.exportSubmitted = true;
        setTimeout(function () { return _this.dialogRef.close(); }, 5000);
    };
    ExportDialogComponent.prototype.generateFieldColumns = function (fields, n) {
        if (n === void 0) { n = 20; }
        var columns = new Array();
        for (var i = 0, j = 0; i < fields.length; i++) {
            if (i >= n && i % n === 0) {
                j++;
            }
            columns[j] = columns[j] || [];
            columns[j].push(fields[i]);
        }
        return columns;
    };
    ExportDialogComponent.prototype.fieldSelectionList = function (columns) {
        var fieldSelection = [];
        columns.forEach(function (column) {
            column.forEach(function (field) {
                if (field.selected) {
                    fieldSelection.push(field.key);
                }
            });
        });
        return fieldSelection;
    };
    ExportDialogComponent.prototype.onDateChanged = function (dates) {
        if (this.data.type === 'driver-shifts' && dates && dates.length) {
            dates[dates.length - 1].setHours(23, 59, 59);
        }
        var dateRange = [
            dates[0].toISOString(),
            dates[dates.length - 1].toISOString(),
        ].join(',');
        var params = new http_1.HttpParams({
            fromObject: { date_range: dateRange },
        });
        this.data.params = params;
    };
    return ExportDialogComponent;
}());
exports.ExportDialogComponent = ExportDialogComponent;
