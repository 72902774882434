<div class="inner-content"
  [ngClass]="{ 'driver-list-open': (driverListOpen && !assignmentDetails || !assignmentDetails['id']), 'assignment-details-open': assignmentDetails && assignmentDetails['id'] }"
  widget-content>
  <my-loader *ngIf="loading || loadingMapPreferences"></my-loader>
  <div class="map" *ngIf="!loading && !loadingMapPreferences && mapData">
    <ngui-map [options]="mapOptions" (mapReady$)="initMap($event)">
      <polyline *ngFor="let route of parsedRoutes" [path]="route" [strokeColor]="'#869ab5'" [strokeOpacity]="0.3"
        [strokeWeight]="4">
      </polyline>

      <ng-container *ngFor="let location of locations; let i = index">
        <custom-marker *ngIf="location.location && location.location.coordinates"
          [position]="[location.location.coordinates[1], location.location.coordinates[0]]"
          (click)="openLocationInfoWindow(i, location)" (initialized$)="onCustomLocationMarkerInit($event)">
          <img src="assets/img/map/location-marker.png" />
        </custom-marker>
      </ng-container>

      <ng-container *ngFor="let update of locationUpdates; let i = index">
        <custom-marker *ngIf="update.location && update.location.coordinates" [id]="update.driver && update.driver.id"
          [position]="[update.location.coordinates[1], update.location.coordinates[0]]"
          (click)="openAssignmentDetails(update, i)" (initialized$)="onCustomUpdateMarkerInit($event)">
          <ng-container *ngIf="markerType === 'driver-initials'">
            <map-status-marker [locationUpdate]="update" [markerType]="markerType"></map-status-marker>
            <div class="driver trip-status-{{update.tripStatus}}">
              {{ update.driver && update.driver.initials }}
            </div>
          </ng-container>
          <ng-container *ngIf="markerType === 'truck-number'">
            <div class="truck trip-status-{{update.tripStatus}} {{update.tripStatus}}">
              <p>
                {{ update.driver && update.driver.truck && update.driver.truck.name }}
              </p>
            </div>
          </ng-container>
        </custom-marker>
      </ng-container>

      <polygon *ngFor="let polygon of polygons" [editable]="false" [paths]="polygon" [strokeColor]="'#015BC5'"
        [strokeOpacity]="0.8" [strokeWeight]="2" [fillColor]="'#4a90e2'" [fillOpacity]="0.35">
      </polygon>

      <traffic-layer *ngIf="mapControls.traffic"></traffic-layer>
      <heatmap-layer (initialized$)="onHeatmapInitialized($event)" *ngIf="mapControls.heatmap" dissipating="true"
        [radius]="mapControls.heatmapRadius" maxIntensity="5" [data]="heatmapPoints">
      </heatmap-layer>
      <info-window class="desktop-map-element" id="driver-info-window">
        <driver-infobox *ngIf="driverInfoWindowModel" [driver]="driverInfoWindowModel.driver" [date]="startDate">
        </driver-infobox>
      </info-window>
      <info-window id="location-info-window">
        <div *ngIf="locationInfoWindowModel" class="location-info-window">
          <div class="title">{{ locationInfoWindowModel.title }}</div>
          <div class="address">{{ locationInfoWindowModel.address }}</div>
          <div class="city">{{ locationInfoWindowModel.city }} {{ locationInfoWindowModel.state }}
            {{ locationInfoWindowModel.zip }}</div>
        </div>
      </info-window>
    </ngui-map>
    <div class="custom-map-controls" *ngIf="mapControls" [ngClass]="{'with-slider': mapControls.heatmap}">
      <div (click)="toggleTraffic()" [ngClass]="{active: mapControls.traffic}">Traffic</div>
      <div class="divider" *ngIf="1===0">|</div>
      <div (click)="toggleHeatmap()" *ngIf="1===0" [ngClass]="{active: mapControls.heatmap}">Heatmap</div>
    </div>
    <div class="heatmap-slider" *ngIf="mapControls.heatmap">
      <div class="slidecontainer">
        <input type="range" min="1" max="40" [value]="heatmapRadius" (change)="changeHeatmapRadius($event)"
          class="slider">
      </div>
    </div>
    <div class="legend">
      <div class="enroute-loading">Enroute</div>
      <div class="loading">Loading</div>
      <div class="enroute-unloading">Enroute</div>
      <div class="unloading">Unloading</div>
    </div>
  </div>
