"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var tag_service_1 = require("../tags/tag.service");
var api_service_1 = require("../shared/api.service");
var OrganizationMarketDialogComponent = /** @class */ (function () {
    function OrganizationMarketDialogComponent(dialogRef, tagService, elementRef) {
        this.dialogRef = dialogRef;
        this.tagService = tagService;
        this.elementRef = elementRef;
        this.model = {
            id: null,
            name: null,
            notes: null
        };
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
    }
    OrganizationMarketDialogComponent.prototype.ngOnInit = function () {
    };
    OrganizationMarketDialogComponent.prototype.submitOrganizationMarket = function () {
        var _this = this;
        this.loading = true;
        this.tagService.save(this.model).subscribe(function (res) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return OrganizationMarketDialogComponent;
}());
exports.OrganizationMarketDialogComponent = OrganizationMarketDialogComponent;
