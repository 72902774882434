import { clone } from 'lodash';

import { Resource } from '../shared/resource';

export class SurchargeTemplate extends Resource {
  id: string;
  title: string;
  createdAt: string;
  description: string;
  amount: string;
  positive = false;
  loading = false;
  kind = 'invoice'; // invoice or scaleticket
  kindTitle = 'Billing';
  classes: string;
  selected: boolean;

  filterOptions(options): any[] {
    options = clone(options);
    return options;
  }

  url(action): string {
    return '/surcharge-templates/' + this.id + '/' + action;
  }
}
