"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".address-context[_ngcontent-%COMP%]{position:absolute;right:15px;top:35px;cursor:context-menu}.address-list[_ngcontent-%COMP%]{position:absolute;background:#fff;z-index:2;width:100%;border:1px solid #eee;border-radius:4px;margin-top:4px}.address-list[_ngcontent-%COMP%]   .selected[_ngcontent-%COMP%]{background:#e2e2e2}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]{padding:0 0 20px;list-style-type:none}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{cursor:pointer;height:auto;font-size:12px;margin:0;padding:10px;text-align:left}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{margin:0;max-width:335px;font-weight:500}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   .item-detail[_ngcontent-%COMP%]{color:#72839b;font-size:12px}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]{position:absolute;color:#72839b;font-size:24px;right:10px;margin-top:0}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]:hover:before, .address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li.selected[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]:before{content:'\\f058'}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li.selected[_ngcontent-%COMP%]   .select-icon[_ngcontent-%COMP%]{color:#27af5e}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:hover{background:#f4f6f8}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]:hover   .select-icon[_ngcontent-%COMP%]:before{content:'\\f058'}.address-list[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li.unselected[_ngcontent-%COMP%]{opacity:.4}"];
exports.styles = styles;
