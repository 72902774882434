  <!-- using fancy table: reusable component -->
  <div class="container drivers-container">
    <!-- for opening edit driver dialog -->
    <ruckit-edit-driver [driver]="driver" #editDrawer
                        (completed)="onEditComplete($event)"></ruckit-edit-driver>

    <div class="actions-wrapper" *ngIf="view === 'list'">
      <fancy-table #driverTable [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(filters)]="appliedFilters" [(search)]="search"
                   [config]="tableConfig"
                   [customClasses]="customClasses"
                   (onDataLoaded)="dataLoaded($event)"
                   (columnChange)="columnsChanged($event)"
                   (onClickAction)="clickAction($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'name'" (contextmenu)="openContextMenu($event, row.id)">
              <div class="driver-photo">
                <span *ngIf="!row.image">{{row.name[0]}}</span>
                <img *ngIf="row.image" [src]="row.driverListImage" />
              </div>
              <div class="data driver-name"> {{ row.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'cdl'">
              <div class="data">{{ row.cdl || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'phone-number'">
              <div class="data">{{ row.profile && row.profile.phoneNumber || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'billing-id'">
              <div class="data">{{ row.uniqueBillingId || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'truck'">
              <div class="data">{{ row.truck && row.truck.displayName || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'carrier'">
              <div class="data"> {{ row.carrier.name || row.carrierName || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'markets'">
              <div class="data">
                <tag-display [tags]="row.tags" label="Markets"></tag-display>
              </div>
            </div>
            <div class="value duty-status" *ngSwitchCase="'duty-status'">
              <div class="data"> {{ (row.displayDutyStatus | translate) || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'deleted'">
              <div class="data">
                <span class="value">{{ row.lastModified | moment: 'M-D-YYYY' }}</span>
                <div class="label">{{ row.lastModifiedBy }}</div>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'action'">
              <action-menu *ngIf="row.filterOptions" icon="icon-more">
                <ng-container *ngFor="let option of row.filterOptions(tableConfig.menuOptions)">
                  <a *ngIf="option.link" mat-menu-item
                     [routerLink]="row.url(option.action)"
                     [queryParams]="{ returnTo: '/drivers' }">
                    {{ option.name }}
                  </a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="segmented-buttons">
            <a (click)="switchView('list')" class="btn btn-default" [ngClass]="{active: view === 'list'}">
              <i class="icon-rows" matTooltip="{{ 'Table' | translate }}"></i>
            </a>
            <a (click)="switchView('grid')" class="btn btn-default" [ngClass]="{active: view === 'grid'}">
              <i class="icon-grid" matTooltip="{{ 'Grid' | translate }}"></i>
            </a>
          </div>
          <div class="title" translate>Drivers</div>
          <div class="segmented-buttons">
            <a (click)="viewArchive.emit(false)" class="btn btn-default" [ngClass]="{active: !archive}" translate>
              Active
            </a>
            <a (click)="viewArchive.emit(true)" class="btn btn-default" [ngClass]="{active: archive}" translate>
              Archive
            </a>
          </div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="appliedFilters=$event"></applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>
        <filters-panel [(appliedFilters)]="appliedFilters"
                       [customFilter]="true"
                       (openCustomFilter)="openFilters()"
                       [search]="search" (searchChange)="search = $event"></filters-panel>
        <a mat-icon-button class="invite-button" *ngIf="inviteDriverEnabled" (click)="openInviteDriver()">
          <mat-icon>contact_mail</mat-icon>
        </a>
        <!-- add new driver dialog-->
        <a mat-icon-button color="accent" class="add-button" (click)="openAddDriver()">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>

    <div class="management-grid" *ngIf="view === 'grid'">
      <timeline-legend [labels]="gridLabels">
        <div title translate>Legend</div>
      </timeline-legend>
  
      <item-grid
        *ngIf="driverGroups && loadingProgress === 100"
        [multiselect]="false"
        [itemGroups]="driverGroups"
        [itemClassNames]="generateItemClassNames"
        [displayKeys]="displayKeys"
        [searchProps]="displayKeys"
        [groupByOptions]="groupByOptions"
        [activeGroupBy]="activeGroupBy"
        [showDriverContextMenu]="true"
        (groupByChange)="selectGroupBy($event)"
        (onSelect)="handleGridSelection($event)"
      >
        <div header-stats class="header-stats">
          <div class="segmented-buttons">
            <a (click)="switchView('list')" class="btn btn-default" [ngClass]="{active: view === 'list'}">
              <i class="icon-rows" matTooltip="{{ 'Table' | translate }}"></i>
            </a>
            <a (click)="switchView('grid')" class="btn btn-default" [ngClass]="{active: view === 'grid'}">
              <i class="icon-grid" matTooltip="{{ 'Grid' | translate }}"></i>
            </a>
          </div>
          {{ driverList.length + ' Drivers' | translate }}
        </div>
      </item-grid>
  
      <div class="grid-loader" *ngIf="!driverGroups || loadingProgress < 100">
        <h2 translate>Getting Full Driver List</h2>
        <h3 translate>This may take longer depending on your fleet size</h3>
        <div class="loading-bar">
          <span class="text">{{ loadingProgress + '% Loaded' | translate }}</span>
          <div class="progress" [ngStyle]="{'width': loadingProgress + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- fancy table :ends here -->

  <!-- driver context menu -->
  <app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>