"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var rxjs_1 = require("rxjs");
var core_2 = require("@ngx-translate/core");
// libraries
var lodash_1 = require("lodash");
var moment = require("moment");
// angular material
var material_1 = require("@angular/material");
// components
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var cyber_trip_filters_dialog_component_1 = require("./cyber-trip-filters-dialog.component");
// types
var filter_option_1 = require("../shared/filters-panel/filter-option");
// services
var cyber_trip_service_1 = require("./cyber-trip.service");
// utils
var app_utilities_1 = require("../shared/app-utilities");
var CyberTripsComponent = /** @class */ (function () {
    function CyberTripsComponent(route, cyberTripService, translationService, dialog) {
        this.route = route;
        this.cyberTripService = cyberTripService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'job-date', title: this.translationService.instant('Job Date'), sortable: true, sortBy: 'job_date' },
            { key: 'job-title', title: this.translationService.instant('Job'), sortable: true, sortBy: 'jobevent__job__name' },
            { key: 'phase-code', title: this.translationService.instant('Phase Code'), sortable: true, sortBy: 'jobevent__job__phase_code' },
            { key: 'start-time', title: this.translationService.instant('Start Time'), sortable: true, sortBy: 'jobevent__shift1_start' },
            { key: 'end-time', title: this.translationService.instant('End Time'), sortable: true, sortBy: 'jobevent__shift1_end' },
            { key: 'trip-duration', title: this.translationService.instant('Trip Duration'), sortable: true, sortBy: 'duration' },
            { key: 'loading-time', title: this.translationService.instant('Loading Time'), sortable: true, sortBy: 'duration' },
            { key: 'transit-time', title: this.translationService.instant('Transit Time'), sortable: true, sortBy: 'travel_time' },
            { key: 'unloading-time', title: this.translationService.instant('Unloading Time'), sortable: true, sortBy: 'travel_time' },
            { key: 'load-ticket-number', title: this.translationService.instant('Load Ticket #'),
                sortable: true, sortBy: 'ticket_number_ordering' },
            { key: 'load-ticket-weight', title: this.translationService.instant('Load Wt'), sortable: true, sortBy: 'weight_ordering' },
            { key: 'unload-ticket-number', title: this.translationService.instant('Unload Ticket #'), sortable: true, sortBy: 'ticket_number_ordering' },
            { key: 'unload-ticket-weight', title: this.translationService.instant('Unload Wt'), sortable: true, sortBy: 'weight_ordering' },
            { key: 'carrier', title: this.translationService.instant('Carrier'), sortable: true, sortBy: 'driver__carrier__name' },
            { key: 'driver', title: this.translationService.instant('Driver'), sortable: true, sortBy: 'driver__profile__first_name' },
            { key: 'truck', title: this.translationService.instant('Truck'), sortable: true, sortBy: 'truck__name' },
            { key: 'duration', title: this.translationService.instant('Duration') },
            { key: 'payable', title: this.translationService.instant('Payable To'), sortable: true, sortBy: 'jobevent__owner_organization__name' },
            { key: 'customer', title: this.translationService.instant('Customer'), sortable: true, sortBy: 'jobevent__customer_organization__name' },
            { key: 'invoice-rate', title: this.translationService.instant('Invoice Rate') },
            { key: 'invoice-total', title: this.translationService.instant('Invoice Total') },
            { key: 'haul-rate', title: this.translationService.instant('Haul Rate') },
            { key: 'haul-total', title: this.translationService.instant('Haul Total') },
            { key: 'hourly-cost', title: this.translationService.instant('Hourly Cost') },
        ];
        this.displayedColumns = [
            'select', 'job-date', 'job-title', 'start-time', 'end-time', 'trip-duration', 'loading-time',
            'transit-time', 'unloading-time', 'load-ticket-number', 'load-ticket-weight', 'unload-ticket-number',
            'unload-ticket-weight', 'carrier', 'driver', 'truck', 'duration', 'payable', 'customer',
            'invoice-rate', 'invoice-total', 'haul-rate', 'haul-total', 'hourly-cost', 'phase-code'
        ];
        this.appliedFilters = [];
        this.search = '';
        this.tableConfig = {
            hasHeader: true,
            pageSize: 25,
            service: cyber_trip_service_1.CyberTripService,
            filterQuery: false,
            preferenceKey: 'geo-trips',
            preferencesEnabled: true,
            query: {},
            collectionTitle: this.translationService.instant('GeoTrips'),
            hasNoResultsAction: false,
            sortBy: 'created_at',
            sortDirection: 'asc',
            automaticallyHidePagination: false,
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false }
            ]
        };
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Export'), action: 'export', link: false }
        ];
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = true;
        this.errors = [];
        this.allSelected = false;
        this.selectedTrips = [];
        this.excludeTrips = [];
        // context menu
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.allSubscriptionsToUnsubscribe = [];
        this.exportCallback = function () { };
    }
    CyberTripsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            _this.tableConfig.sortBy = result.qparams['sortBy'] || '';
            _this.tableConfig.sortDirection = result.qparams['sortAsc'] || '';
        }));
        if (this.appliedFilters.length === 0) {
            var startDate = moment();
            startDate = startDate.subtract(1, 'month');
            startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            var defaultFilter = new filter_option_1.FilterOption({
                filterType: 'date',
                default: true,
                key: 'startDate',
                title: 'Start Date',
                displayValues: startDate.format('MM/DD/YYYY') || null,
                values: startDate.toISOString(),
                query: {
                    jobevent__shift1_start__gte: startDate.toISOString()
                }
            });
            this.appliedFilters.push(defaultFilter);
        }
    };
    CyberTripsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    CyberTripsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(cyber_trip_filters_dialog_component_1.CyberTripFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
        if (startDate) {
            dialog.componentInstance.model.startDate = new Date(startDate);
        }
        var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
        if (endDate) {
            dialog.componentInstance.model.endDate = new Date(endDate);
        }
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'job' }), 'values');
        dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'project' }), 'values');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'customer' }), 'values');
        dialog.componentInstance.model.driver = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'driver' }), 'values');
        dialog.componentInstance.model.truck = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'truck' }), 'values');
        dialog.componentInstance.model.payableTo = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'payableTo' }), 'values');
        dialog.componentInstance.model.carrier = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'carrier' }), 'values');
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    CyberTripsComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            customer: 'jobevent__customer_organization__id',
            project: 'jobevent__job__project',
            payableTo: 'jobevent__owner_organization__id',
            job: 'jobevent__job',
            driver: 'driver',
            truck: 'truck',
            startDate: 'jobevent__shift1_start__gte',
            endDate: 'jobevent__shift1_start__lte',
            edited: 'edited',
            incomplete: 'completed',
            retake: 'retake_status',
            carrier: 'driver__carrier',
            duplicates: 'only_dupes',
            uninvoiced: 'uninvoiced'
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (typeof (values) === 'boolean') {
                if (key === 'incomplete' && values) {
                    displayValues = values.toString();
                    displayValues = displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
                    var filterValue = (!values).toString();
                    filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                    query[queryKeys[key]] = filterValue;
                }
                else if (key === 'retake' && values) {
                    values = 'requested';
                    query[queryKeys[key]] = values;
                }
                else if (values) {
                    values = values.toString();
                    values = values.charAt(0).toUpperCase() + values.slice(1);
                    query[queryKeys[key]] = values;
                }
                displayValues = values;
            }
            else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
                if ((typeof values) === 'string') {
                    query[queryKeys[key]] = values;
                }
                else {
                    query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
            }
            var filter = new filter_option_1.FilterOption({
                filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (filter.values === 'False' || !filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    CyberTripsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedTrips();
                break;
        }
    };
    CyberTripsComponent.prototype.exportSelectedTrips = function () {
        var _this = this;
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, this.selectedTrips, this.excludeTrips, this.allSelected, this.search), params = _a.params, scope = _a.scope;
        this.cyberTripService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'trips',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: _this.exportCallback,
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.cyberTripService
                }
            });
        }, function (err) { _this.errors = err; });
    };
    CyberTripsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        if (!this.allSelected) {
            this.selectedTrips = event.selection.selected;
            this.excludeTrips = [];
        }
        else {
            this.selectedTrips = [];
            this.excludeTrips = event.exclusion.selected;
        }
    };
    CyberTripsComponent.prototype.getDuration = function (startDatetime, endDatetime) {
        if (startDatetime && endDatetime) {
            var minuteDiff = moment(endDatetime).diff(moment(startDatetime), 'minutes');
            return Math.floor(minuteDiff / 60) + 'h ' + (minuteDiff % 60) + 'm';
        }
        else {
            return 'N/A';
        }
    };
    CyberTripsComponent.prototype.prettyDate = function (dateString) {
        var date = new Date(dateString);
        return date instanceof Date && !isNaN(date.getTime()) ? this.datePipe.transform(date, 'MM/dd/yyyy') : '';
    };
    CyberTripsComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    /**
     * Currently used to override the displayed columns if they are different from the preferences
     * Update/Remove if the column toggle is added
     * @param data
     */
    CyberTripsComponent.prototype.dataLoaded = function (data) {
        var _this = this;
        if (this.displayedColumns.length !== this.fancyTable.displayedColumns.length) {
            this.fancyTable.displayedColumns = this.displayedColumns;
            this.fancyTable.savePreferences();
        }
        if (!this.displayedColumns.every(function (el) { return _this.fancyTable.displayedColumns.includes(el); })) {
            this.fancyTable.displayedColumns = this.displayedColumns;
            this.fancyTable.savePreferences();
        }
    };
    return CyberTripsComponent;
}());
exports.CyberTripsComponent = CyberTripsComponent;
