import { MenuItem } from '../interfaces/menu-item';

export const ViewEndShiftAction = 'endShiftAction';
export const ViewMessageDriverAction = 'messageDriverAction';
export const ViewDriverProfileAction = 'viewDriverProfileAction';
export const ViewAssignmentsOptionAction = 'viewAssignmentsAction';
export const FleetHealthOptionAction = 'fleetHealthAction';

export const shiftMenuOptions: MenuItem[] = [
  {
    name: 'End Shift',
    action: ViewEndShiftAction,
    icon: 'alarm-off',
    disabled: false,
  },
  // {
  //   name: 'Message Driver',
  //   action: 'messageDriver',
  //   icon: 'chat-bubble',
  //   disabled: true,
  // },
  {
    name: 'View Driver Profile',
    action: ViewDriverProfileAction,
    icon: 'social-person',
    disabled: false,
  },
  {
    name: 'View Assignments',
    action: ViewAssignmentsOptionAction,
    icon: 'assignment',
    classNames: ['assignment-menu'],
    disabled: false,
  },
];

export const FleetHealthAction: MenuItem = {
  name: 'Fleet Health',
  action: FleetHealthOptionAction,
  icon: 'network-check'
};
