"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var resource_service_1 = require("../shared/resource.service");
var ticket_serializer_1 = require("./ticket.serializer");
var TicketService = /** @class */ (function (_super) {
    __extends(TicketService, _super);
    function TicketService(http) {
        var _this = _super.call(this, http, 'scaletickets/', new ticket_serializer_1.TicketSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl + 'scaletickets/';
        return _this;
    }
    TicketService.prototype.void = function (model) {
        var _this = this;
        var uri = this.baseUrl;
        model = new ticket_serializer_1.TicketSerializer().toJson(model);
        return this.http.put(uri + model.id + '/', lodash_1.omit(model, ['id', 'number']), {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(function (err) { return api_service_1.parseErrors(err); }));
    };
    TicketService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (ticket) {
                return new ticket_serializer_1.TicketSerializer().fromJson(ticket);
            });
        }
        else if (resObj) {
            return new ticket_serializer_1.TicketSerializer().fromJson(resObj);
        }
    };
    return TicketService;
}(resource_service_1.ResourceService));
exports.TicketService = TicketService;
