"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var truck_service_1 = require("../trucks/truck.service");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var location_service_1 = require("../locations/location.service");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var driver_assignments_service_1 = require("../assignments/driver-assignments.service");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var assignment_service_1 = require("../assignments/assignment.service");
var PlantsComponent = /** @class */ (function () {
    function PlantsComponent(route, router, dialog, assignmentService, translationService) {
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.assignmentService = assignmentService;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'select' },
            { key: 'truck-carrier', title: this.translationService.instant('Truck / Carrier'), sortable: true, sortBy: 'truck__name' },
            { key: 'eta-customer', title: this.translationService.instant('ETA / Customer'), sortable: true, sortBy: 'customer__name' },
            { key: 'status', title: this.translationService.instant('Status'), sortable: true, sortBy: 'status' }
        ];
        this.displayedColumns = [
            'select', 'truck-carrier', 'eta-customer', 'status'
        ];
        this.availableFilters = [
            new filter_option_1.FilterOption({
                key: 'truck', title: this.translationService.instant('Truck'), filterType: 'select',
                service: truck_service_1.TruckService, slug: 'trucks'
            }),
        ];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.count = 0;
        this.loading = true;
        this.driverAssignmentsTableConfig = {
            service: driver_assignments_service_1.DriverAssignmentsService,
            preferenceKey: 'PlantsComponent-DriverAssignmentsService',
            query: {},
            collectionTitle: this.translationService.instant('Assignments'),
            noResultsText: this.translationService.instant('an assignment'),
            newRecordModal: function () { },
            sortBy: '',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Export'), action: 'export', link: false, external: false }
            ]
        };
        this.driverAssignmentsTableConfigReady = false;
        this.locationDropdownConfig = {
            service: location_service_1.LocationService,
            selectText: this.translationService.instant('Select Location'),
            loadingText: this.translationService.instant('Loading Locations...'),
            noResultsText: this.translationService.instant('No Locations'),
            groupProperty: 'locationTypeName',
            group: true
        };
        this.multipleActionDropdownOptions = [
            { name: 'Export', button: true }
        ];
        this.allSelected = false;
        this.selectedAssignments = [];
        this.excludedAssignments = [];
    }
    PlantsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        combinedParams.subscribe(function (result) {
            _this.selectedLocationId = result.qparams['location'];
            var startDate = new Date(); // temp
            startDate.setDate(startDate.getDate() - 5);
            startDate.setHours(0, 0, 0, 0);
            var endDate = new Date();
            endDate.setHours(23, 59, 59, 999);
            _this.activeRange = startDate.toISOString() + ',' + endDate.toISOString();
            _this.setDriverAssignmentsQuery();
        });
    };
    PlantsComponent.prototype.onSelectLocation = function (location) {
        this.selectedLocationId = location.id;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                location: this.selectedLocationId
            },
            queryParamsHandling: 'merge'
        });
        this.driverAssignmentsTable.getRecords();
    };
    PlantsComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.appliedFilters, filter);
        this.driverAssignmentsTable.getRecords();
    };
    PlantsComponent.prototype.zoomTo = function (content) {
        //
    };
    PlantsComponent.prototype.multiselectDisabled = function () {
        return !this.count || this.count === 0 ||
            (!this.allSelected && !this.selectedAssignments.length) ||
            this.count === this.excludedAssignments.length;
    };
    PlantsComponent.prototype.setSelectedAction = function (option) {
        switch (option.action) {
            case 'export':
                this.createExport(this.selectedAssignments, this.excludedAssignments);
                break;
        }
    };
    PlantsComponent.prototype.createExport = function (selectedAssignments, excludedAssignments) {
        var _this = this;
        var scope = {
            assignments: selectedAssignments,
            exclude_assignments: excludedAssignments,
        };
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        var filters = {
            search: this.search,
            'jobevent__shift1_start__gte': startDate && startDate.toISOString(),
            'jobevent__shift1_start__lte': endDate && endDate.toISOString(),
            'exclude_assignments': excludedAssignments,
            'assignments': selectedAssignments
        };
        this.assignmentService.export(scope, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    scope: scope,
                    params: params,
                    service: _this.assignmentService,
                    buttonText: _this.translationService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    /**
     * @param  {} driver
     * @returns void
     * This function is triggered when driver icon is clicked on the map,
     * the driver list will be scrolled to the clicked driver.
     */
    PlantsComponent.prototype.scrollToSelectedDriver = function (driver) {
        // let drivers = this.rows;
        // if (_find(drivers, { id: driver.id })) {
        //   this.driverList.nativeElement.scrollBy(0, this.driverList.nativeElement.scrollHeight);
        // }
    };
    PlantsComponent.prototype.onDataLoaded = function (tableData) {
        this.count = tableData.count;
    };
    PlantsComponent.prototype.onSelectionChanged = function (selection) {
        this.allSelected = selection.allSelected;
        if (this.allSelected) {
            this.selectedAssignments = [];
            this.excludedAssignments = selection.exclusion.selected;
        }
        else {
            this.selectedAssignments = selection.selection.selected;
            this.excludedAssignments = [];
        }
    };
    PlantsComponent.prototype.setDriverAssignmentsQuery = function () {
        this.driverAssignmentsTableConfig.query = __assign({}, this.driverAssignmentsTableConfig.query, { active_range: this.activeRange, location: this.selectedLocationId });
        this.driverAssignmentsTableConfigReady = true;
    };
    return PlantsComponent;
}());
exports.PlantsComponent = PlantsComponent;
