import { Component, Input } from '@angular/core';
import { TMapMarkerStatus } from '../map-marker';

@Component({
  selector: 'map-markers-legend',
  templateUrl: './map-markers-legend.component.html',
  styleUrls: ['./map-markers-legend.component.scss']
})
export class MapMarkersLegendComponent {
  @Input() markerStatus: TMapMarkerStatus;
  @Input() hidden: boolean;

  constructor() { }
}
