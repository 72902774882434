"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var api_service_1 = require("../shared/api.service");
var condensed_ticket_service_1 = require("./condensed-ticket.service");
var IncompleteTicketsComponent = /** @class */ (function () {
    function IncompleteTicketsComponent(condensedTicketService) {
        this.condensedTicketService = condensedTicketService;
        this.loading = false;
        this.errors = [];
        this.tickets = [];
        this.sortBy = 'delivery_time';
        this.sortAsc = true;
        this.onTicketSelected = new core_1.EventEmitter();
    }
    IncompleteTicketsComponent.prototype.ngOnInit = function () {
        this.getTickets();
    };
    IncompleteTicketsComponent.prototype.getTickets = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.ticketsReq) {
            this.ticketsReq.unsubscribe();
        }
        this.ticketsReq = this.condensedTicketService.list(__assign({ ordering: order, ticket_status: 'in-progress,on-hold' }, query)).subscribe(function (tickets) {
            _this.tickets = tickets;
            _this.loading = false;
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.loading = false;
        });
    };
    IncompleteTicketsComponent.prototype.highlightTicket = function (ticket, refresh) {
        if (refresh === void 0) { refresh = true; }
        if (ticket) {
            if (refresh) {
                this.getTickets();
            }
            this.selectedTicket = ticket;
            this.scrollToTicket('ticket-' + ticket.number);
        }
    };
    IncompleteTicketsComponent.prototype.selectTicket = function (ticket) {
        this.tickets.forEach(function (_ticket) {
            _ticket.selected = false;
        });
        this.onTicketSelected.emit(ticket);
        ticket.selected = true;
    };
    IncompleteTicketsComponent.prototype.scrollToTicket = function (element) {
        var _element = window.document.getElementById(element.toString());
        if (_element) {
            _element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    };
    IncompleteTicketsComponent.prototype.ticketPresentWithLicenceNumberAndDriverName = function (licensePlate, driverName) {
        return this.tickets.filter(function (ticket) { return ticket.truck === licensePlate && ticket.driver === driverName; })[0];
    };
    IncompleteTicketsComponent.prototype.getTicketWithDriver = function (licencePlateNumber, driverName) {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            var ticketFound;
            ticketFound = _this.ticketPresentWithLicenceNumberAndDriverName(licencePlateNumber, driverName);
            // if (!ticketFound) {
            //   ticketFound = this.ticketPresentWithLicenceNumber(licencePlateNumber);
            // }
            observer.next(ticketFound);
            observer.complete();
        });
    };
    return IncompleteTicketsComponent;
}());
exports.IncompleteTicketsComponent = IncompleteTicketsComponent;
