// Angular 5
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/http';
import '@angular/router';

import 'rxjs';

// Other vendors for example jQuery, Lodash or Bootstrap
// You can import js, ts, css, sass, ...

import './assets/js/loadGenesys.js';
import '../node_modules/timepicker/jquery.timepicker.js';
import '../node_modules/timepicker/jquery.timepicker.css';
import './public/javascript/sdxWebchatWidget.js';
import './public/javascript/cxbus.min.js';
import 'moment-duration-format';
