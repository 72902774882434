"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var work_order_templates_component_1 = require("./work-order-templates/work-order-templates.component");
var work_orders_component_1 = require("./work-orders.component");
var routes = [
    {
        path: 'templates', component: work_order_templates_component_1.WorkOrderTemplatesComponent, canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: '', component: work_orders_component_1.WorkOrdersComponent, canActivate: [auth_guard_1.AuthGuard]
    }
];
var WorkOrdersRoutingModule = /** @class */ (function () {
    function WorkOrdersRoutingModule() {
    }
    return WorkOrdersRoutingModule;
}());
exports.WorkOrdersRoutingModule = WorkOrdersRoutingModule;
