"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
var api_service_1 = require("../shared/api.service");
var job_service_1 = require("./job.service");
var project_service_1 = require("../projects/project.service");
var connection_service_1 = require("../connections/connection.service");
var new_customer_dialog_component_1 = require("../customers/new-customer-dialog.component");
var location_service_1 = require("../locations/location.service");
var authentication_service_1 = require("../shared/authentication.service");
var new_location_dialog_component_1 = require("../locations/new-location-dialog.component");
var custom_field_1 = require("../custom-fields/custom-field");
var custom_field_service_1 = require("../custom-fields/custom-field.service");
var work_order_template_service_1 = require("../work-orders/work-order-templates/work-order-template.service");
var edit_location_dialog_component_1 = require("../locations/edit-location-dialog.component");
var EditJobComponent = /** @class */ (function () {
    function EditJobComponent(route, jobService, projectService, connectionService, locationService, authenticationService, workOrderTemplateService, router, dialog, formBuilder, customFieldService, deviceDetectorService) {
        var _this = this;
        this.route = route;
        this.jobService = jobService;
        this.projectService = projectService;
        this.connectionService = connectionService;
        this.locationService = locationService;
        this.authenticationService = authenticationService;
        this.workOrderTemplateService = workOrderTemplateService;
        this.router = router;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.customFieldService = customFieldService;
        this.deviceDetectorService = deviceDetectorService;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.hasWorkOrder = false;
        this.jobTypeOptions = {
            loading: [],
            unloading: []
        };
        this.model = {
            id: '',
            customFieldData: {},
            name: '',
            poNumber: '',
            orderNumber: '',
            jobNumber: '',
            qrJobType: 'loading_import',
            rate: 0,
            haulRate: 0,
            haulType: 'weight',
            haulWeightUnit: 'ton',
            invoiceType: 'weight',
            invoiceWeightUnit: 'ton',
            material: '',
            phaseCode: '',
            dailyDeliveryTarget: '',
            dailyDeliveryTargetType: '',
            totalAmountType: '',
            deliveryInterval: 0,
            deliveryIntervalUnit: '',
            checkinOptions: {},
            checkoutOptions: {},
            checkinOptionsDisabled: {},
            checkoutOptionsDisabled: {},
            startLocation: { id: '', name: '' },
            endLocation: { id: '', name: '' },
            notes: '',
            amountNeeded: '',
            dates: [],
            tags: [],
            workOrderTemplate: null,
            defaultYardBufferTime: null,
            defaultYardBufferMinutes: null
        };
        this.customerEditEnabled = false;
        this.newCustomer = false;
        this.customerSearch = '';
        this.customerOptions = [];
        this.customerLoading = false;
        this.customerDropdownConfig = {
            nameProperty: 'name'
        };
        this.loading = false;
        this.loadingLocations = [{
                displayName: 'Add a New Address',
                id: 'new-loading-location',
                button: true
            }];
        this.unloadingLocations = [{
                displayName: 'Add a New Address',
                id: 'new-unloading-location',
                button: true
            }];
        this.weightOptions = [
            { id: 'ton', name: 'Ton' },
            { id: 'metric-tons', name: 'Metric Ton' },
            { id: 'pound', name: 'Pound' },
            { id: 'cuyds', name: 'Cubic Yard' },
            { id: 'bushel', name: 'Bushel' },
            { id: 'bag', name: 'Bag' }
        ];
        this.jobWeightOptions = lodash_1.clone(this.weightOptions);
        this.haulWeightOptions = lodash_1.clone(this.weightOptions);
        this.locationsDropdownConfig = {
            start: { searchable: true, nameProperty: 'displayName' },
            end: { searchable: true, nameProperty: 'displayName' }
        };
        this.orderTypeOptions = [
            { id: 'tons', name: 'Tons' },
            { id: 'metric-tons', name: 'Metric Tons' },
            { id: 'tonnes', name: 'Tonnes' },
            { id: 'loads', name: 'Loads' },
            { id: 'lbs', name: 'Pounds' },
            { id: 'kgs', name: 'Kilograms' },
            { id: 'cuyds', name: 'Cubic Yards' },
            { id: 'bushels', name: 'Bushels' },
            { id: 'bags', name: 'Bags' }
        ];
        this.deliveryIntervalUnits = [{
                id: 'hours',
                name: 'Hours'
            }, {
                id: 'minutes',
                name: 'Minutes',
            }];
        this.errors = [];
        this.tags = [];
        this.isCreator = false;
        this.configOptions = [
            { name: 'Required', id: 'required' },
            { name: 'Optional', id: 'optional' },
            { name: 'Hidden', id: 'hidden' },
        ];
        this.workOrderTemplateOptions = [];
        this.workOrderTemplateDropdownConfig = {
            nameProperty: 'name'
        };
        this.saveCustomerCallback = function (customer) {
            _this.reloadCustomerOptions();
            _this.customerOrganization = customer;
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    EditJobComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customerDropdownConfig = { searchable: true, loadingOptions: false };
        this.reloadCustomerOptions();
        this.queryParamsSub = this.route.queryParams.forEach(function (params) {
            if (params['returnTo'] && params['returnTo'].length) {
                _this.returnTo = params['returnTo'];
            }
        });
        this.paramsSub = this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.getJob(params['id']);
            }
        });
        this.getCustomFields();
        this.user = this.authenticationService.user();
        this.hasWorkOrder = this.authenticationService.hasWorkOrder();
        if (this.hasWorkOrder) {
            this.getWorkOrderTemplates();
        }
    };
    EditJobComponent.prototype.ngOnDestroy = function () {
        if (this.paramsSub && typeof this.paramsSub.unsubscribe === 'function') {
            this.paramsSub.unsubscribe();
        }
        if (this.queryParamsSub && typeof this.queryParamsSub.unsubscribe === 'function') {
            this.queryParamsSub.unsubscribe();
        }
        if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
            this.workOrderTemplatesReq.unsubscribe();
        }
    };
    EditJobComponent.prototype.getCustomFields = function () {
        var _this = this;
        this.customFieldService.getIndexedFieldsForKind(custom_field_1.CustomFieldKind.Job).subscribe(function (fields) {
            _this.customFields = fields;
        });
    };
    EditJobComponent.prototype.changeTotalAmountType = function (type) {
        this.model.totalAmountType = type.id;
        this.selectedTotalAmountType = type;
    };
    EditJobComponent.prototype.changeDailyDeliveryTargetType = function (type) {
        this.model.dailyDeliveryTargetType = type.id;
        this.selectedDailyDeliveryTargetType = type;
    };
    EditJobComponent.prototype.changeDeliveryIntervalUnits = function (interval) {
        this.model.deliveryIntervalUnit = interval.id;
        this.selectedDeliveryIntervalUnit = interval;
    };
    EditJobComponent.prototype.weightUnitChange = function (tracking, unit) {
        this.model[tracking + 'WeightUnit'] = unit.id;
        this['selected' + tracking + 'Unit'] = unit;
        if (tracking === 'invoice') {
            this.changeCloneHaulRate();
        }
    };
    EditJobComponent.prototype.getJob = function (id) {
        var _this = this;
        this.loading = true;
        this.jobService.get(id).subscribe(function (res) {
            _this.job = res;
            for (var key in _this.model) {
                if (_this.job.hasOwnProperty(key)) {
                    _this.model[key] = _this.job[key];
                }
            }
            if (_this.model && _this.model.qrJobType === undefined) {
                _this.model.qrJobType = 'loading_import';
            }
            var myOrganization = _this.authenticationService.getOrganization();
            if (_this.job.project) {
                _this.isCreator = _this.job.project.ownerOrganization === myOrganization.id;
                _this.projectName = _this.job.project.name;
            }
            if (_this.job.customerOrganization) {
                _this.customerOrganization = _this.job.customerOrganization;
                var customer = { id: _this.customerOrganization.id, name: _this.job.customerOrganization.name };
                if (!lodash_1.find(_this.customerOptions, { id: customer.id })) {
                    _this.customerOptions.push(customer);
                }
                _this.selectedCustomer = customer;
            }
            _this.selectedDeliveryIntervalUnit = lodash_1.find(_this.deliveryIntervalUnits, { id: _this.job.deliveryIntervalUnit });
            _this.selectedDailyDeliveryTargetType = lodash_1.find(_this.orderTypeOptions, { id: _this.job.dailyDeliveryTargetType });
            _this.selectedTotalAmountType = lodash_1.find(_this.orderTypeOptions, { id: _this.job.totalAmountType });
            _this.selectedInvoiceUnit = lodash_1.find(_this.jobWeightOptions, { id: _this.job.invoiceWeightUnit });
            _this.selectedHaulUnit = lodash_1.find(_this.haulWeightOptions, { id: _this.job.haulWeightUnit });
            _this.getJobDays();
            _this.getLocations();
            if (_this.model && _this.user && _this.user.organization && !_this.user.organization.qrEnabled) {
                _this.model.qrJobType = 'other';
            }
            _this.jobTypeChanged();
            if (!_this.model.defaultYardBufferTime || !_this.model.defaultYardBufferMinutes) {
                _this.loadOrganizationDefaults();
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditJobComponent.prototype.selectCustomer = function (customer) {
        if (customer.name === 'New Customer') {
            this.openNewCustomer();
        }
        else {
            this.customerOrganization = customer;
        }
    };
    EditJobComponent.prototype.reloadCustomerOptions = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.customerOptions = [{ name: 'New Customer', id: 'new-customer', button: true }];
        }
        this.customerLoading = true;
        if (this.connectionsReq) {
            this.connectionsReq.unsubscribe();
        }
        this.connectionsReq = this.connectionService.list({
            ordering: 'organization__name',
            search: this.customerSearch
        }).subscribe(function (connections) {
            if (append) {
                _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                    return { name: connection.organization.name, id: connection.organization.id };
                }));
            }
            else {
                _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                    return { name: connection.organization.name, id: connection.organization.id };
                }));
            }
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        });
    };
    EditJobComponent.prototype.customerDropdownSearch = function (term) {
        this.customerOptions = [];
        this.customerDropdownConfig.loadingOptions = true;
        this.customerSearch = term;
        this.reloadCustomerOptions();
        this.customerDropdownConfig.loadingOptions = false;
    };
    EditJobComponent.prototype.customerDropdownNextPage = function () {
        var _this = this;
        if (!this.customerDropdownConfig.loadingOptions) {
            var o = this.connectionService.listNext();
            if (o) {
                this.customerDropdownConfig.loadingOptions = true;
                o.subscribe(function (connections) {
                    _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                        return { name: connection.organization.name, id: connection.organization.id };
                    }));
                    _this.customerDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.customerDropdownConfig.loadingOptions = false;
                });
            }
        }
    };
    EditJobComponent.prototype.openNewCustomer = function () {
        var _this = this;
        this.newCustomer = true;
        this.customerDialogRef = this.dialog.open(new_customer_dialog_component_1.NewCustomerDialogComponent, {
            width: '444px'
        });
        this.customerDialogRef.componentInstance.callback = this.saveCustomerCallback;
        this.customerDialogRef.afterClosed().subscribe(function (result) {
            if (_this.job.project && _this.job.project.customer) {
                _this.selectCustomer({ name: _this.job.project.customer.name, id: _this.job.project.customer.id });
            }
            else {
                _this.customerDropdown.deselectAll();
            }
        });
    };
    EditJobComponent.prototype.getWorkOrderTemplates = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
            this.workOrderTemplatesReq.unsubscribe();
        }
        var request = this.workOrderTemplateService.list(query);
        if (next) {
            request = this.workOrderTemplateService.listNext();
        }
        else {
            this.workOrderTemplateOptions = [];
        }
        if (request) {
            this.workOrderTemplatesReq = request.subscribe(function (res) {
                _this.workOrderTemplateOptions = _this.workOrderTemplateOptions.concat(res);
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    EditJobComponent.prototype.getJobDays = function () {
        var _this = this;
        if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
            this.jobDaysReq.unsubscribe();
        }
        this.loading = true;
        this.jobDaysReq = this.jobService.getDays(this.job.id).subscribe(function (days) {
            _this.model.dates = days;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    EditJobComponent.prototype.changeLoadingLocation = function (location, place) {
        var _this = this;
        if (place === void 0) { place = 'start'; }
        if (location['id'] === 'new-loading-location' || location['id'] === 'new-unloading-location') {
            // deselect
            location.selected = false;
            var dialog = this.dialog.open(new_location_dialog_component_1.NewLocationDialogComponent, {
                width: '100%',
                height: '100%',
                disableClose: true
            });
            this.tagUrlWith('new-location');
            dialog.afterClosed().subscribe(function () {
                _this.tagUrlWith(null);
            });
            dialog.componentInstance.callback = function (newLocation) {
                newLocation.displayName = newLocation.street ? (newLocation.displayName + ' - ' + newLocation.street) : newLocation.displayName;
                if (place === 'start') {
                    _this.loadingLocations.forEach(function (_location) {
                        _location['selected'] = false;
                    });
                    newLocation.selected = true;
                    _this.loadingLocations.push(newLocation);
                    if (_this.startLocationDropdown) {
                        _this.startLocationDropdown.toggleOption(_this.loadingLocations.find(function (l) { return (l.id === newLocation.id); }), false);
                    }
                    var _newLocation = lodash_1.clone(newLocation);
                    _newLocation.selected = false;
                    _this.unloadingLocations.push(_newLocation);
                }
                else {
                    _this.unloadingLocations.forEach(function (_location) {
                        _location['selected'] = false;
                    });
                    newLocation.selected = true;
                    _this.unloadingLocations.push(newLocation);
                    if (_this.endLocationDropdown) {
                        _this.endLocationDropdown.toggleOption(_this.unloadingLocations.find(function (l) { return (l.id === newLocation.id); }), false);
                    }
                    var _newLocation = lodash_1.clone(newLocation);
                    _newLocation.selected = false;
                    _this.loadingLocations.push(_newLocation);
                }
                _this.model[place + 'Location'] = newLocation;
            };
        }
        else {
            this.model[place + 'Location'] = location;
        }
    };
    EditJobComponent.prototype.changeUnloadingLocation = function (location) {
        this.changeLoadingLocation(location, 'end');
    };
    EditJobComponent.prototype.editLocation = function (location, place) {
        var _this = this;
        if (place === void 0) { place = 'start'; }
        location.selected = false;
        var dialog = this.dialog.open(edit_location_dialog_component_1.EditLocationDialogComponent, {
            width: '100%',
            height: '100%',
            disableClose: true,
            data: { locationId: location && location.id }
        });
        this.tagUrlWith('edit-location');
        dialog.afterClosed().subscribe(function () {
            _this.tagUrlWith(null);
        });
        dialog.componentInstance.callback = function (editLocation) {
            editLocation.displayName = editLocation.street ? (editLocation.displayName + ' - ' + editLocation.street) : editLocation.displayName;
            if (place === 'start') {
                _this.loadingLocations.forEach(function (_location) {
                    _location['selected'] = false;
                });
                editLocation.selected = true;
                _this.loadingLocations.push(editLocation);
                if (_this.startLocationDropdown) {
                    _this.startLocationDropdown.toggleOption(_this.loadingLocations.find(function (l) { return (l.id === editLocation.id); }), false);
                }
                var _editLocation = lodash_1.clone(editLocation);
                _editLocation.selected = false;
                _this.unloadingLocations.push(_editLocation);
            }
            else {
                _this.unloadingLocations.forEach(function (_location) {
                    _location['selected'] = false;
                });
                editLocation.selected = true;
                _this.unloadingLocations.push(editLocation);
                if (_this.endLocationDropdown) {
                    _this.endLocationDropdown.toggleOption(_this.unloadingLocations.find(function (l) { return (l.id === editLocation.id); }), false);
                }
                var _editLocation = lodash_1.clone(editLocation);
                _editLocation.selected = false;
                _this.loadingLocations.push(_editLocation);
            }
            _this.model[place + 'Location'] = editLocation;
        };
    };
    EditJobComponent.prototype.tagUrlWith = function (fragment) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams),
            fragment: fragment
        });
    };
    EditJobComponent.prototype.invoiceTypeChanged = function () {
        this.changeCloneHaulRate();
        this.jobTypeChanged();
    };
    EditJobComponent.prototype.haulTypeChanged = function () {
        this.jobTypeChanged();
    };
    EditJobComponent.prototype.changeCloneHaulRate = function () {
        if (this['cloneHaulRate']) {
            this.job['haulType'] = this.model.invoiceType;
            this.job['haulWeightUnit'] = this.model['invoiceWeightUnit'];
            this.job['haulRate'] = this.model['rate'];
        }
    };
    EditJobComponent.prototype.locationDropdownSearch = function (place, term) {
        var _this = this;
        if (this.searchReq) {
            this.searchReq.unsubscribe();
        }
        var optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
        this.locationsDropdownConfig[place].loadingOptions = true;
        this.searchReq = this.locationService.list({
            search: term,
            archived: 'False'
        }).subscribe(function (res) {
            _this[optionsKey].splice(0, _this[optionsKey].length);
            res = res.map(function (loc) {
                loc['name'] = loc.name + ' - ' + loc.street;
                return loc;
            });
            if (optionsKey === 'loadingLocations') {
                _this[optionsKey] = [{
                        displayName: 'Add a New Address',
                        id: 'new-loading-location',
                        button: true
                    }];
            }
            else if (optionsKey === 'unloadingLocations') {
                _this[optionsKey] = [{
                        displayName: 'Add a New Address',
                        id: 'new-unloading-location',
                        button: true
                    }];
            }
            _this[optionsKey] = _this[optionsKey].concat(res);
            _this.locationsDropdownConfig[place].loadingOptions = false;
        });
    };
    EditJobComponent.prototype.locationDropdownNextPage = function (place) {
        var _this = this;
        if (!this.locationsReq) {
            var optionsKey_1 = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
            this.locationsDropdownConfig[place].loadingOptions = true;
            var next = this.locationService.listNext();
            if (next) {
                this.locationsReq = next.subscribe(function (res) {
                    res = res.map(function (loc) {
                        loc['displayName'] = loc.displayName + ' - ' + loc.street;
                        return loc;
                    });
                    _this[optionsKey_1] = _this[optionsKey_1].concat(res);
                    _this.locationsDropdownConfig[place].loadingOptions = false;
                    _this.locationsReq = null;
                });
            }
            else {
                this.locationsDropdownConfig[place].loadingOptions = false;
            }
        }
    };
    EditJobComponent.prototype.getLocations = function () {
        var _this = this;
        this.locationService.list({
            archived: 'False'
        }).subscribe(function (res) {
            res = res.map(function (loc) {
                loc['displayName'] = loc.displayName + ' - ' + loc.street;
                return loc;
            });
            _this.loadingLocations = _this.loadingLocations.concat(res);
            _this.unloadingLocations = _this.unloadingLocations.concat(lodash_1.clone(res));
            _this.setStartLocation();
            _this.setEndLocation();
        });
        this.locationService.getLocationByIP().subscribe(function (res) {
            _this.defaultLocation = res;
        });
    };
    EditJobComponent.prototype.setStartLocation = function () {
        if (this.model.startLocation && this.model.startLocation.id) {
            var _location = lodash_1.find(this.loadingLocations, { id: this.model.startLocation.id });
            if (_location) {
                this.loadingLocations = lodash_1.reject(this.loadingLocations, _location);
            }
            else {
                _location = {
                    id: this.model.startLocation.id, displayName: this.model.startLocation.name, button: false
                };
            }
            this.loadingLocations.unshift(_location);
            if (this.startLocationDropdown) {
                this.startLocationDropdown.selectedOption = _location;
            }
        }
    };
    EditJobComponent.prototype.setEndLocation = function () {
        if (this.model.endLocation && this.model.endLocation.id) {
            var _location = lodash_1.find(this.unloadingLocations, { id: this.model.endLocation.id });
            if (_location) {
                this.unloadingLocations = lodash_1.reject(this.unloadingLocations, _location);
            }
            else {
                _location = {
                    id: this.model.endLocation.id, displayName: this.model.endLocation.name, button: false
                };
            }
            this.unloadingLocations.unshift(_location);
            if (this.endLocationDropdown) {
                this.endLocationDropdown.selectedOption = _location;
            }
        }
    };
    EditJobComponent.prototype.jobTypeChanged = function () {
        if (this.model && this.model.qrJobType === 'loading_import') {
            this.model.checkinOptionsDisabled['ticket_number'] = true;
            this.model.checkinOptionsDisabled['ticket_image'] = true;
            this.model.checkinOptionsDisabled['signature_image'] = false;
            this.model.checkinOptionsDisabled['active_tracking'] = false;
            this.model.checkinOptionsDisabled['log_weight'] = false;
            this.model.checkinOptionsDisabled['qr_code'] = true;
            this.model.checkoutOptionsDisabled['ticket_number'] = false;
            this.model.checkoutOptionsDisabled['ticket_image'] = true;
            this.model.checkoutOptionsDisabled['signature_image'] = false;
            this.model.checkoutOptionsDisabled['active_tracking'] = false;
            this.model.checkoutOptionsDisabled['log_weight'] = true;
            this.model.checkoutOptionsDisabled['qr_code'] = true;
        }
        else if (this.model && this.model.qrJobType === 'unloading_import') {
            this.model.checkinOptionsDisabled['ticket_number'] = true;
            this.model.checkinOptionsDisabled['ticket_image'] = true;
            this.model.checkinOptionsDisabled['signature_image'] = false;
            this.model.checkinOptionsDisabled['active_tracking'] = false;
            this.model.checkinOptionsDisabled['log_weight'] = true;
            this.model.checkinOptionsDisabled['qr_code'] = true;
            this.model.checkoutOptionsDisabled['ticket_number'] = true;
            this.model.checkoutOptionsDisabled['ticket_image'] = true;
            this.model.checkoutOptionsDisabled['signature_image'] = false;
            this.model.checkoutOptionsDisabled['active_tracking'] = false;
            this.model.checkoutOptionsDisabled['log_weight'] = true;
            this.model.checkoutOptionsDisabled['qr_code'] = true;
        }
        else if (this.model && this.model.qrJobType === 'export') {
            this.model.checkinOptionsDisabled['ticket_number'] = true;
            this.model.checkinOptionsDisabled['ticket_image'] = true;
            this.model.checkinOptionsDisabled['signature_image'] = false;
            this.model.checkinOptionsDisabled['active_tracking'] = false;
            this.model.checkinOptionsDisabled['log_weight'] = true;
            this.model.checkinOptionsDisabled['qr_code'] = true;
            this.model.checkoutOptionsDisabled['ticket_number'] = false;
            this.model.checkoutOptionsDisabled['ticket_image'] = false;
            this.model.checkoutOptionsDisabled['signature_image'] = false;
            this.model.checkoutOptionsDisabled['active_tracking'] = false;
            this.model.checkoutOptionsDisabled['log_weight'] = false;
            this.model.checkoutOptionsDisabled['qr_code'] = true;
        }
        else {
            this.model.checkinOptionsDisabled['ticket_number'] = false;
            this.model.checkinOptionsDisabled['ticket_image'] = false;
            this.model.checkinOptionsDisabled['signature_image'] = false;
            this.model.checkinOptionsDisabled['active_tracking'] = false;
            this.model.checkinOptionsDisabled['log_weight'] = false;
            this.model.checkinOptionsDisabled['qr_code'] = true;
            this.model.checkoutOptionsDisabled['ticket_number'] = false;
            this.model.checkoutOptionsDisabled['ticket_image'] = false;
            this.model.checkoutOptionsDisabled['signature_image'] = false;
            this.model.checkoutOptionsDisabled['active_tracking'] = false;
            this.model.checkoutOptionsDisabled['log_weight'] = false;
            this.model.checkoutOptionsDisabled['qr_code'] = true;
        }
    };
    EditJobComponent.prototype.isValid = function () {
        return !!this.model['name'] && this.model['material'] &&
            this.job.rate !== null && this.job.rate !== undefined && parseFloat(this.job.rate) >= 0 &&
            (this.cloneHaulRate || (this.job.haulRate !== null && this.job.haulRate !== undefined && parseFloat(this.job.haulRate) >= 0));
    };
    EditJobComponent.prototype.submit = function (dispatch) {
        var _this = this;
        if (dispatch === void 0) { dispatch = false; }
        if (this.loading || !this.isValid()) {
            return;
        }
        this.loading = true;
        if (this.model['tags'] && this.model['tags'].length) {
            this.model['tags'] = this.model['tags'].map(function (t) { return (t.name); });
        }
        var projectEdited = this.job.project && this.job.project.name !== this.projectName;
        var customerChanged = this.job.customerOrganization &&
            this.job.customerOrganization.id !== this.customerOrganization.id;
        if (projectEdited || customerChanged) {
            if (customerChanged) {
                this.job.customerOrganization = this.customerOrganization;
                // TODO(jlee): Remove legacy setters
                this.job.project.customerOrganization = this.customerOrganization;
            }
            else {
                delete this.job.customerOrganization;
                delete this.job.project.customerOrganization;
            }
            if (projectEdited) {
                this.job.project.name = this.projectName;
            }
            this.projectService.save(this.job.project).subscribe(function (project) {
                _this.jobService.save(_this.model).subscribe(function (res) {
                    _this.loading = false;
                    if (_this.returnTo && _this.returnTo.length) {
                        if (_this.returnTo.includes('?')) {
                            var route = _this.returnTo.split('?')[0];
                            var params_1 = {};
                            _this.returnTo.split('?')[1].split('&').forEach(function (param) {
                                var paramData = param.split('=');
                                params_1[paramData[0]] = paramData[1];
                            });
                            _this.router.navigate([route], { queryParams: params_1 });
                        }
                        else {
                            _this.router.navigate([_this.returnTo]);
                        }
                    }
                    else if (dispatch) {
                        _this.router.navigate(['/dispatch', _this.job.id]);
                    }
                    else {
                        _this.router.navigate(['/daily']);
                    }
                }, function (err) {
                    _this.loading = false;
                    _this.errors = api_service_1.parseErrors(err);
                });
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
        else {
            this.jobService.save(this.model).subscribe(function (res) {
                _this.loading = false;
                if (_this.returnTo && _this.returnTo.length) {
                    if (_this.returnTo.includes('?')) {
                        var route = _this.returnTo.split('?')[0];
                        var params_2 = {};
                        _this.returnTo.split('?')[1].split('&').forEach(function (param) {
                            var paramData = param.split('=');
                            params_2[paramData[0]] = paramData[1];
                        });
                        _this.router.navigate([route], { queryParams: params_2 });
                    }
                    else {
                        _this.router.navigate([_this.returnTo]);
                    }
                }
                else if (dispatch) {
                    _this.router.navigate(['/dispatch', _this.job.id]);
                }
                else {
                    _this.router.navigate(['/daily']);
                }
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    EditJobComponent.prototype.onSelect = function (dropdownType, e) {
        switch (dropdownType) {
            case 'workOrderTemplate':
                this.job.workOrderTemplate = e;
                break;
        }
        this.model[dropdownType] = e;
    };
    EditJobComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'workOrderTemplate':
                this.getWorkOrderTemplates({ search: term });
                break;
        }
    };
    EditJobComponent.prototype.dropdownNextPage = function (type, e) {
        var _this = this;
        var config, service, options, o;
        switch (type) {
            case 'workOrderTemplate':
                config = this.workOrderTemplateDropdownConfig;
                service = this.workOrderTemplateService;
                options = this.workOrderTemplateOptions;
                o = service.listNext();
                break;
        }
        if (!config.loadingOptions) {
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'workOrderTemplate':
                            _this.workOrderTemplateOptions = _this.workOrderTemplateOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    EditJobComponent.prototype.loadOrganizationDefaults = function () {
        if (this.user && this.user.organization) {
            this.model.defaultYardBufferTime = this.user.organization.defaultYardBufferTime;
            this.model.defaultYardBufferMinutes = this.user.organization.defaultYardBufferMinutes;
        }
    };
    return EditJobComponent;
}());
exports.EditJobComponent = EditJobComponent;
