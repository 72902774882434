"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MenuFilterPipe = /** @class */ (function () {
    function MenuFilterPipe() {
    }
    MenuFilterPipe.prototype.transform = function (value, args) {
        if (args === '') {
            return value;
        }
        var query = args.toLowerCase();
        return value.filter(function (obj) {
            return obj.hasOwnProperty('menu') && obj.menu.toLowerCase().indexOf(query) > -1;
        });
    };
    return MenuFilterPipe;
}());
exports.MenuFilterPipe = MenuFilterPipe;
