"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var connection_1 = require("../connections/connection");
var organization_1 = require("../organizations/organization");
var price_list_serializer_1 = require("../price-lists/price-list.serializer");
var lodash_1 = require("lodash");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ConnectionSerializer = /** @class */ (function () {
    function ConnectionSerializer() {
    }
    ConnectionSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var connection = new connection_1.Connection();
        if (!json) {
            return connection;
        }
        connection.id = json.id;
        connection.name = json.name;
        connection.types = json.types;
        connection.status = json.status;
        if (json.organization && typeof (json.organization) === 'object') {
            connection.organization = new organization_1.Organization(json.organization);
        }
        else {
            connection.organization = new organization_1.Organization({ id: json.organization });
        }
        connection.organizationName = connection.organization && connection.organization.name;
        if (!connection.name && connection.organization) {
            connection.name = connection.organization.name;
        }
        connection.createdAt = json.createdAt;
        connection.lastModified = json.lastModified;
        connection.invoiceablePunchcardCount = json.invoiceablePunchcardCount;
        connection.invoiceableTripCount = json.invoiceableTripCount;
        connection.customerOnly = json.customerOnly;
        connection.autoAcceptJobs = json.autoAcceptJobs;
        connection.allowDispatch = json.allowDispatch;
        connection.paymentType = json.paymentType;
        connection.invitation = json.invitation;
        connection.multipliers = json.multipliers;
        if (connection.multipliers && connection.multipliers.length > 1) {
            connection.oneRate = false;
        }
        if (connection.multipliers) {
            connection.multipliers.forEach(function (multiplier, index) {
                if (multiplier.truckType !== null && multiplier.truckType['id'] !== undefined) {
                    multiplier['selectedTruckType'] = {
                        id: multiplier.truckType, name: multiplier.truckTypeName
                    };
                    connection.oneRate = false;
                }
                else if (connection.multipliers.length === 1 && multiplier.truckType === null) {
                    multiplier['selectedTruckType'] = {
                        id: null, name: '-- Truck Type --'
                    };
                    connection.oneRate = true;
                }
                else {
                    multiplier['selectedTruckType'] = {
                        id: null, name: '-- Truck Type --'
                    };
                    connection.oneRate = false;
                }
            });
        }
        else {
            connection.multipliers = [];
        }
        if (json.oneRate !== undefined) {
            connection.oneRate = json.oneRate;
        }
        if (connection.organization && !connection.organization.leasedOrg) {
            connection.accountType = 'accounts';
        }
        connection.posPrintPrices = json.posPrintPrices;
        connection.posPrintTicket = json.posPrintTicket;
        connection.posCashPay = json.posCashPay;
        connection.posTaxExempt = json.posTaxExempt;
        connection.posPaymentType = json.posPaymentType;
        connection.posTaxPercentage = json.posTaxPercentage;
        connection.notes = json.notes;
        if (json.posPriceList && typeof json.posPriceList === 'object') {
            connection.posPriceList = (new price_list_serializer_1.PriceListSerializer()).fromJson(json.posPriceList);
        }
        else {
            connection.posPriceList = (new price_list_serializer_1.PriceListSerializer()).fromJson({ id: json.posPriceList });
        }
        connection.uniqueBillingId = json.uniqueBillingId;
        var identifier = [];
        if (json.organization && json.organization.carrier) {
            identifier.push(json.organization.carrier.dotNumber);
        }
        if (json.organization && json.organization.broker) {
            identifier.push(json.organization.broker.dotNumber);
        }
        connection.identifiers = identifier.filter(function (i) {
            return (i !== '' && i !== null);
        }).join(', ');
        var values = [];
        if (json.organization) {
            if (json.organization.carrier && json.organization.carrier.id) {
                values.push('Carrier');
            }
            if (json.organization.contractor && json.organization.contractor) {
                values.push('Contractor');
            }
            if (json.organization.broker && json.organization.broker.id) {
                values.push('Broker');
            }
        }
        connection.types = values.filter(function (v) {
            return (v !== '' && v !== null);
        }).join(' | ');
        if (json.organization && (json.organization.city && json.organization.state)) {
            connection.location = [json.organization.city, json.organization.state]
                .filter(Boolean).join(', ');
        }
        if (json.organization && json.organization.primaryContact) {
            connection.primaryContactName = [
                json.organization.primaryContact.firstName,
                json.organization.primaryContact.lastName
            ].filter(Boolean).join(' ');
        }
        if (!json.id) {
            connection.canEdit = true;
        }
        else {
            connection.canEdit = json.customerOnly || json.organization && json.organization.leasedOrg;
        }
        connection.collaboration = {
            jobEventsLoading: false,
            jobEventOptions: [],
            recipientOrganization: connection.organization && connection.organization.id,
            jobevents: [],
            numTrucks: null,
            invoiceWeightUnit: null,
            invoiceType: null,
            invoiceRate: null,
            notes: '',
            cancelMissing: false
        };
        return connection;
    };
    ConnectionSerializer.prototype.toJson = function (connection) {
        connection.organization = (connection.organization && typeof connection.organization === 'object') ?
            new organization_1.Organization(connection.organization) :
            new organization_1.Organization({ id: connection.organization.id });
        connection.multipliers.forEach(function (multiplier, index) {
            if (typeof multiplier.truckType === 'object') {
                multiplier.truckType = multiplier.truckType && multiplier.truckType.id || null;
            }
            else if (multiplier.truckType) {
                multiplier.truckType = multiplier.truckType;
            }
            else {
                multiplier.truckType = null;
            }
            delete connection.multipliers[index].remove;
            delete connection.multipliers[index].selectedTruckType;
        });
        connection.multipliers = lodash_1.reject(connection.multipliers, function (multiplier) {
            return !multiplier.haulPercentage;
        });
        var json = {
            id: connection.id,
            name: connection.name,
            type: connection.types,
            status: connection.status,
            organization: connection.organization,
            createdAt: connection.createdAt,
            lastModified: connection.lastModified,
            invoiceablePunchcardCount: connection.invoiceablePunchcardCount,
            invoiceableTripCount: connection.invoiceableTripCount,
            customerOnly: connection.customerOnly,
            autoAcceptJobs: connection.autoAcceptJobs,
            allowDispatch: connection.allowDispatch,
            paymentType: connection.paymentType,
            invitation: connection.invitation,
            multipliers: connection.multipliers,
            oneRate: connection.oneRate,
            accountType: connection.accountType,
            posPrintPrices: connection.posPrintPrices,
            posPrintTicket: connection.posPrintTicket,
            posCashPay: connection.posCashPay,
            posTaxExempt: connection.posTaxExempt,
            posPaymentType: connection.posPaymentType,
            posTaxPercentage: connection.posTaxPercentage ? connection.posTaxPercentage : null,
            notes: connection.notes,
            posPriceList: connection.posPriceList && connection.posPriceList.id,
            uniqueBillingId: connection.uniqueBillingId,
            identifiers: connection.identifiers,
            types: connection.types,
            location: connection.organization.location,
            primaryContactName: connection.organization.primaryContact.firstName +
                connection.organization.primaryContact.lastName,
            canEdit: connection.canEdit,
        };
        return decamelizeKeysDeep(json);
    };
    return ConnectionSerializer;
}());
exports.ConnectionSerializer = ConnectionSerializer;
