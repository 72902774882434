"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var new_trip_component_1 = require("./new-trip/new-trip.component");
var edit_trip_component_1 = require("./edit-trip/edit-trip.component");
var condensed_trips_component_1 = require("./condensed-trips/condensed-trips.component");
var routes = [
    { path: '', component: condensed_trips_component_1.CondensedTripsComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'new', component: new_trip_component_1.NewTripComponent, canActivate: [auth_guard_1.AuthGuard] },
    {
        path: 'new/:jobId/:jobEventId',
        component: new_trip_component_1.NewTripComponent,
        canActivate: [auth_guard_1.AuthGuard],
    },
    { path: ':id/edit', component: edit_trip_component_1.EditTripComponent, canActivate: [auth_guard_1.AuthGuard] },
];
var TripsRoutingModule = /** @class */ (function () {
    function TripsRoutingModule() {
    }
    return TripsRoutingModule;
}());
exports.TripsRoutingModule = TripsRoutingModule;
