<div fxFlex fxLayoutAlign="space-between" fxLayout="column" class="trip-history-widget">
  <div class="trip-history-widget__header">
    <div class="trip-history-widget__header__title">
      <h3>{{ title }}</h3>
    </div>
    <div class="trip-history-widget__header__actions">
      <ng-content select="[widget-actions]"></ng-content>
    </div>
  </div>
  <div [ngClass]="{ 'fullBleed': fullBleed }" class="trip-history-widget__content" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
    <ng-content></ng-content>
  </div>
</div>
