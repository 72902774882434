"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var driver_service_1 = require("./../driver.service");
var truck_service_1 = require("../../trucks/truck.service");
var api_service_1 = require("../../shared/api.service");
var driver_1 = require("../../drivers/driver");
var AssignTruckDialogComponent = /** @class */ (function () {
    function AssignTruckDialogComponent(dialogRef, translateService, driverService, truckService, elementRef) {
        this.dialogRef = dialogRef;
        this.translateService = translateService;
        this.driverService = driverService;
        this.truckService = truckService;
        this.elementRef = elementRef;
        this.searching = false;
        this.truckChanged = false;
        this.truckOptions = [];
        this.truckDropdownConfig = {
            subtitle: true,
            subtitleProperty: 'listName',
            image: true,
            imageProperty: 'image',
            nameProperty: 'truckTypeName',
            searchable: true
        };
        this.requireOnDuty = false;
        this.isOnDuty = false;
        this.dutyStatusOptions = undefined;
        this.dutyStatusConfig = { nameProperty: 'name' };
        this.loading = false;
        this.driver = {};
        this.truck = {};
        this.jobEvent = {};
        this.errors = [];
    }
    AssignTruckDialogComponent.prototype.ngOnInit = function () {
        this.title = this.translateService.instant('Assign a Truck');
        if (!this.driver.carrier) { // Required to retrieve trucks
            this.errors = ['Driver has no carrier associated, unable to get trucks.'];
            return;
        }
        // Check if driver is on/off duty and alter message accordingly, load driver duty status options
        this.isOnDuty = this.driver.dutyStatus === 'on-duty';
        if (this.requireOnDuty && !this.isOnDuty) {
            this.message = this.translateService.instant('The driver ' + this.driver.name + ' does not have an assigned truck or is off duty. Select a truck and change to on duty in order to assign to ' + this.jobEvent.jobDisplayName + '.');
            this.dutyStatusOptions = driver_1.DRIVER_DUTY_OPTIONS;
        }
        else {
            this.message = this.translateService.instant('Please assign a Truck to ' + this.driver.name + ' before assigning them to a job.');
        }
        this.loading = true;
        this.getTrucks();
    };
    AssignTruckDialogComponent.prototype.getTrucks = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        this.truckService.list(__assign({ carrier: this.driver.carrier.id, jobevent_available: this.jobEvent.id }, query)).subscribe(function (trucks) {
            _this.truckOptions = trucks;
            _this.loading = false;
            _this.searching = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
            _this.searching = false;
        });
    };
    AssignTruckDialogComponent.prototype.selectTruck = function (truck) {
        this.truck = truck;
        this.driver.truck = truck;
        this.truckChanged = true;
    };
    AssignTruckDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.setDriverOnDuty()
            .subscribe(function (success) {
            if (success) {
                _this.driverService.assign(_this.driver).subscribe(function (res) {
                    _this.dialogRef.close();
                    res.truck = _this.truck;
                    _this.callback(res);
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            else {
                _this.errors = ['Unable to update driver duty status to On Duty'];
                _this.loading = false;
            }
        });
    };
    AssignTruckDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'truck':
                config = this.truckDropdownConfig;
                service = this.truckService;
                options = this.truckOptions;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'truck':
                            _this.truckOptions = _this.truckOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    AssignTruckDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'truck':
                this.searching = true;
                this.getTrucks({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    AssignTruckDialogComponent.prototype.optionSelected = function (value, field) {
        switch (field) {
            case 'dutyStatus':
                this.isOnDuty = value === 'on-duty';
                break;
        }
    };
    AssignTruckDialogComponent.prototype.setDriverOnDuty = function () {
        var _this = this;
        return (!this.requireOnDuty || this.driver.dutyStatus === 'on-duty') ?
            rxjs_1.of(true) :
            this.driverService.save({ id: this.driver.id, dutyStatus: 'on-duty' })
                .pipe(operators_1.tap(function (driver) { return _this.driver.dutyStatus = driver.dutyStatus; }), operators_1.map(function () { return _this.driver.dutyStatus === 'on-duty'; }), operators_1.catchError(function () { return rxjs_1.of(false); }));
    };
    return AssignTruckDialogComponent;
}());
exports.AssignTruckDialogComponent = AssignTruckDialogComponent;
