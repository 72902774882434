import { clone } from 'lodash';

import { Organization } from '../organizations/organization';
import { Resource } from '../shared/resource';

export class ProductType extends Resource {
  createdAt: string;
  lastModified: string;
  name: string;
  ownerOrganization: Organization;
  parameterizedName: string;

  url(action): string {
    return '/scale/product-types/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
