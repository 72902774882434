import { Component, OnInit } from '@angular/core';

// libraries
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'lodash';

// angular material
import { MatDialogRef } from '@angular/material';

// services
import { TranslateService } from '@ngx-translate/core';
import { DriverService } from './../driver.service';
import { ConnectionService } from '../../connections/connection.service';
import { ApiService, parseErrors } from '../../shared/api.service';
import { CustomFieldService } from '../../custom-fields/custom-field.service';
import { AuthenticationService } from '../../shared';

// types
import { CustomField, CustomFieldKind } from '../../custom-fields/custom-field';
import { Tag } from '../../tags/tag';

@Component({
  selector: 'new-driver-dialog',
  templateUrl: './new-driver-dialog.component.html',
  styleUrls: ['./new-driver-dialog.component.scss'],
})
export class NewDriverDialogComponent implements OnInit {
  leasedMode = false;
  states = [];
  stateOptions = [];
  countryOptions = [];
  loading = false;
  model: any = { profile: {}, user: {}, tags: [], customFieldData: {} };
  errors = [];
  callback;
  device;
  customFields: CustomField[];
  carrierId;

  carriersConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'organization.carrier.id',
    selectText: this.translate.instant('Select Carrier'),
    loadingText: this.translate.instant('Loading Carriers...'),
    noResultsText: this.translate.instant('No Carriers'),
    service: ConnectionService,
    serviceFunction: 'list',
    query: {
      ordering: 'organization__name',
      is_carrier: 'True',
      allow_dispatch: 'True',
    },
    prefilledOptions: [],
  };

  constructor(
    public dialogRef: MatDialogRef<NewDriverDialogComponent>,
    private driverService: DriverService,
    private deviceDetectorService: DeviceDetectorService,
    private apiService: ApiService,
    private customFieldService: CustomFieldService,
    private translate: TranslateService,
    private authentiationService: AuthenticationService
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop(),
    };
  }

  ngOnInit() {
    this.prefillCarrier();
    this.states = this.apiService.getStates();
    this.countryOptions = this.apiService.getCountries().map((country) => {
      return {
        id: country.abbreviation,
        name: country.name,
      };
    });
    if (this.model && this.model.country) {
      this.selectCountry(this.model.country);
    } else {
      this.selectCountry('US');
    }
    if (this.carrierId) {
      this.model.carrier = this.carrierId;
    }
    this.getCustomFields();
  }

  prefillCarrier(): void {
    const organization = this.authentiationService.getOrganization();
    if (organization && organization.carrier) {
      this.carriersConfig.prefilledOptions.push(organization.carrier);
      if (this.carrierId) {
        this.model.carrier = this.carrierId;
      } else {
        this.carrierId = organization.carrier.id;
        this.model.carrier = organization.carrier.id;
      }
    }
  }

  getCustomFields(): void {
    this.customFieldService
      .getFieldsForKind(CustomFieldKind.Driver)
      .subscribe((fields) => {
        this.customFields = fields;
      });
  }

  selectState(state: any): void {
    this.model.state = state;
  }

  selectCountry(country: any): void {
    this.stateOptions = filter(this.states, { country }).map((_state) => {
      return {
        id: _state.abbreviation,
        name: _state.name,
      };
    });
    this.model.country = country;
  }

  submit(): void {
    this.loading = true;
    this.driverService.save(this.model).subscribe(
      () => {
        this.dialogRef.close();
        this.callback();
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  tagChange(tags: Tag[]): void {
    this.model['tags'] = tags.map((t) => t.name);
  }

  selectCarrier(e: any) {
    this.model.carrier = e.id;
  }
}
