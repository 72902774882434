"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
// libraries
var lodash_1 = require("lodash");
// services
var trip_service_1 = require("./../trip.service");
// components
var view_signatures_dialog_component_1 = require("../../checkins/view-signatures-dialog.component");
var index_1 = require("../../shared/dialogs/index");
// constants
var app_constants_1 = require("../../app.constants");
var CompletedTripsComponent = /** @class */ (function () {
    function CompletedTripsComponent(router, tripService, dialog) {
        this.router = router;
        this.tripService = tripService;
        this.dialog = dialog;
        this.trips = [];
        this.expandedTrips = [];
        this.loading = true;
        this.errors = [];
        this.signatureImageType = app_constants_1.TRIPSIGNATUREIMAGETYPE;
        this.ticketImageType = app_constants_1.TRIPTICKETIMAGETYPE;
        this.menuOptions = [
            { name: 'Details', action: '', link: true },
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Unvoid', action: 'unvoid', link: false },
            { name: 'Void', action: 'void', link: false },
        ];
        this.contextMenuEventSubject = new rxjs_1.Subject();
    }
    CompletedTripsComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.getTrips();
    };
    CompletedTripsComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.tripService.getNextCondensed();
            if (o) {
                this.loading = true;
                o.subscribe(function (trips) {
                    _this.trips = _this.trips.concat(trips);
                    _this.loading = false;
                }, function (err) { return (_this.errors = err); }, function () {
                    _this.loading = false;
                });
            }
        }
    };
    CompletedTripsComponent.prototype.getTrips = function () {
        var _this = this;
        this.loading = true;
        if (this.tripReq) {
            this.tripReq.unsubscribe();
        }
        this.tripReq = this.tripService
            .getCondensedTrips({
            jobevent: this.jobEvent && this.jobEvent.id,
            completed: 'True',
        })
            .subscribe(function (trips) {
            trips = trips.filter(function (trip) { return trip.completed === true; });
            _this.trips = trips;
            _this.loading = false;
        }, function (err) { return (_this.errors = err); }, function () {
            _this.loading = false;
        });
    };
    CompletedTripsComponent.prototype.openSignatures = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            _this.dialog.open(view_signatures_dialog_component_1.ViewSignaturesDialogComponent, {
                width: '722px',
                data: {
                    trip: _trip,
                    callback: function () { return _this.getTrips(); },
                },
            });
        }, function (err) { return console.error(err); });
    };
    CompletedTripsComponent.prototype.openAddTrip = function () {
        this.router.navigate(['/trips/new'], {
            queryParams: { returnTo: '/trips' },
        });
    };
    CompletedTripsComponent.prototype.menuAction = function (name, trip) {
        switch (name) {
            case 'void':
                this.voidTrip(trip);
                break;
            case 'unvoid':
                this.unvoidTrip(trip);
                break;
        }
    };
    CompletedTripsComponent.prototype.voidTrip = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Trip?',
            body: "This trip will be marked as 'Void' and will not be visible for the Job.",
            close: 'Cancel',
            accept: 'Void',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: true }).subscribe(function (result) {
                    trip.void = true;
                    trip.loading = false;
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    CompletedTripsComponent.prototype.unvoidTrip = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unvoid Trip?',
            body: 'This trip will be unmarked as \'Void\' and will be visible for the Job.',
            close: 'Cancel',
            accept: 'Unvoid',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: false }).subscribe(function () {
                    trip.void = false;
                    trip.loading = false;
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    CompletedTripsComponent.prototype.expandTrip = function (trip) {
        if (lodash_1.find(this.expandedTrips, { id: trip.id })) {
            lodash_1.remove(this.expandedTrips, { id: trip.id });
        }
        else {
            this.expandedTrips.push(trip);
        }
    };
    CompletedTripsComponent.prototype.expandedTrip = function (trip) {
        if (lodash_1.find(this.expandedTrips, { id: trip.id })) {
            return true;
        }
        return false;
    };
    CompletedTripsComponent.prototype.openContextMenu = function (event, driver) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driver.id,
        });
    };
    return CompletedTripsComponent;
}());
exports.CompletedTripsComponent = CompletedTripsComponent;
