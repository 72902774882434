import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';

import { environment } from '../../environments/environment';
import { Job } from './job';
import { ResourceService } from '../shared/resource.service';
import { requestHeaders, handleError } from '../shared/api.service';
import { JobSerializer } from './job.serializer';
import { BulkEditJobsSerialzier } from './bulk-edit-jobs.serializer';

@Injectable()
export class JobService extends ResourceService<Job> {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(http: HttpClient) {
    super(http, 'jobs/', new JobSerializer());
  }

  getDays(jobId: string) {
    let daysUrl = this.baseUrl + 'jobs/' + jobId + '/days/';
    return this.http.get(daysUrl, {
      headers: requestHeaders()
    }).pipe(
      map(res => { return this.extractDays(res); }),
      catchError((res: Response) => handleError(res))
    );
  }

  updateDays(jobId: string, model) {
    let daysUrl = this.baseUrl + 'jobs/' + jobId + '/days/';
    return this.http.put(daysUrl, model, {
      headers: requestHeaders(), responseType: 'text'
    });
  }

  archive(job: Job) {
    job.archived = true;
    let _job = { id: job.id, archived: job.archived };
    const jobUrl = this.baseUrl + 'jobs/';

    return this.http.put(jobUrl + _job.id + '/', _job, { headers: requestHeaders() });
  }

  unarchive(job: Job) {
    job.archived = false;
    let _job = { id: job.id, archived: job.archived };
    const jobUrl = this.baseUrl + 'jobs/';

    return this.http.put(jobUrl + job.id + '/', _job, { headers: requestHeaders() });
  }

  bulkUpdate(model: any, query?: any) {
    if (!model) {
      return of();
    }
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    const serializer = new BulkEditJobsSerialzier();
    const updatedmodel = serializer.toJson(model);
    const assigmentBulkUrl = this.baseUrl + 'jobs/bulk-update/';
    return this.http.put(assigmentBulkUrl, updatedmodel, {
      headers: requestHeaders(),
      params: params
    });
  }

  private extractDays(res: Object) {
    let resObj = res;
    return resObj['results'] || resObj;
  }
}
