"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var material_1 = require("@angular/material");
var common_1 = require("@angular/common");
var moment = require("moment");
var punch_card_service_1 = require("../../punch-cards/punch-card.service");
var punch_card_decision_service_1 = require("../../punch-cards/punch-card-decision.service");
var shared_1 = require("../../shared");
var punch_card_1 = require("../../punch-cards/punch-card");
var job_event_share_1 = require("../../job-event-shares/job-event-share");
var DriverEODPunchCardsComponent = /** @class */ (function () {
    function DriverEODPunchCardsComponent(punchCardService, punchCardDecisionService, authenticationService, deviceDetectorService, location) {
        this.punchCardService = punchCardService;
        this.punchCardDecisionService = punchCardDecisionService;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.location = location;
        this.trips = [];
        this.punchCards = [];
        this.hideApproved = false;
        this.showVoided = false;
        this.shouldAuditDecisions = new core_1.EventEmitter();
        this.updatePunchCardData = new core_1.EventEmitter();
        this.loading = true;
        this.errors = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.displayedColumns = [
            'start-time', 'end-time', 'total-time', 'loading-ticket-number', 'approved-by', 'actions'
        ]; // 'actions'
        this.dataSource = new material_1.MatTableDataSource();
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    DriverEODPunchCardsComponent.prototype.ngOnInit = function () {
        this.organization = this.authenticationService.getOrganization();
        this.user = this.authenticationService.user();
        this.returnTo = this.location.path();
    };
    DriverEODPunchCardsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        this.loading = true;
        var punchCards = this.punchCards.filter(function (v) { return v.job.id === _this.jobEventShare.jobId; });
        punchCards = punchCards.filter(function (v) {
            if (_this.hideApproved) {
                return !v.latestDecisionStatus || (v.latestDecisionStatus && v.latestDecisionStatus !== 'approved');
            }
            else {
                return true;
            }
        });
        punchCards = punchCards.filter(function (v) {
            return _this.showVoided ? true : !v.void;
        });
        this.dataSource.data = punchCards;
        if (this.punchCardsTable) {
            this.punchCardsTable.renderRows();
        }
        this.loading = false;
    };
    /**
     * Makes an edit to either the startTime or endTime field and saves that edit to the API trip record
     *
     * @param {PunchCard} punchCard The target punch card
     * @param {string} field The time field we will apply the edit to
     * @param {any} value The edit value for the time adjustment
     */
    DriverEODPunchCardsComponent.prototype.editPunchCardTime = function (punchCard, field, value) {
        var _this = this;
        var saveData = { id: punchCard.id };
        var hasChanges = false;
        var newTime = moment(value);
        if (field === 'start' && !newTime.isSame(punchCard.startTimeTimestamp)) {
            hasChanges = true;
            saveData.startTimeTimestamp = value;
            if (newTime.isAfter(punchCard.endTimeTimestamp)) {
                saveData.endTimeTimestamp = value;
            }
        }
        else if (field === 'end' && !newTime.isSame(punchCard.endTimeTimestamp)) {
            hasChanges = true;
            saveData.endTimeTimestamp = value;
            if (newTime.isBefore(punchCard.startTimeTimestamp)) {
                saveData.startTimeTimestamp = value;
            }
        }
        if (!hasChanges) {
            return;
        }
        // Change detected, send to server
        punchCard.loading = true;
        this.punchCardService.save(saveData).subscribe(function (updates) {
            var punchCardUpdates = new punch_card_1.PunchCard(updates);
            _this.updatePunchCard(Object.assign(punchCard, {
                startTimeTimestamp: punchCardUpdates.startTimeTimestamp,
                endTimeTimestamp: punchCardUpdates.endTimeTimestamp,
                duration: punchCardUpdates.duration
            }));
            punchCard.loading = false;
        }, function (err) {
            _this.errors = err;
            punchCard.loading = false;
        });
    };
    DriverEODPunchCardsComponent.prototype.unapprovePunchCard = function (punchCard) {
        this.approvePunchCard(punchCard, 'pending');
    };
    DriverEODPunchCardsComponent.prototype.approvePunchCard = function (punchCard, decisionStatus) {
        var _this = this;
        if (decisionStatus === void 0) { decisionStatus = 'approved'; }
        punchCard.loading = true;
        var data = {
            decisionStatus: decisionStatus,
            decidedBy: this.user,
            organization: this.organization,
            decidedAt: new Date().toISOString(),
            punchcard: punchCard
        };
        this.punchCardDecisionService.save(data).subscribe(function (decision) {
            punchCard.latestDecision = decision.id;
            punchCard.latestDecisionStatus = decision.decisionStatus;
            punchCard.latestDecider = decision.decidedBy && decision.decidedBy.id;
            punchCard.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
            _this.shouldAuditDecisions.emit();
            _this.updatePunchCard(punchCard);
            punchCard.loading = false;
        }, function (err) {
            _this.errors = err;
            punchCard.loading = false;
        });
    };
    DriverEODPunchCardsComponent.prototype.updatePunchCard = function (updates) {
        var updatedIndex = this.dataSource.data.findIndex(function (t) { return (t['id'] === updates['id']); });
        this.dataSource.data[updatedIndex] = Object.assign(this.dataSource.data[updatedIndex], {
            duration: updates.duration,
            startTimeTimestamp: updates.startTimeTimestamp,
            endTimeTimestamp: updates.endTimeTimestamp,
        });
        if (this.punchCardsTable) {
            this.punchCardsTable.renderRows();
        }
        this.updatePunchCardData.emit(this.dataSource.data);
    };
    return DriverEODPunchCardsComponent;
}());
exports.DriverEODPunchCardsComponent = DriverEODPunchCardsComponent;
