"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Not Found Component: Displays an error page for 404-style errors
 */
var NotFoundComponent = /** @class */ (function () {
    /**
     * @ignore
     */
    function NotFoundComponent() {
    }
    return NotFoundComponent;
}());
exports.NotFoundComponent = NotFoundComponent;
