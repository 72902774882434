"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var moment = require("moment");
// services
var shift_service_1 = require("../../shifts/shift.service");
// models
var driver_1 = require("../driver");
var DriverDetailsComponent = /** @class */ (function () {
    function DriverDetailsComponent(shiftReportService, translationService) {
        this.shiftReportService = shiftReportService;
        this.translationService = translationService;
        this.currentDate = new Date();
    }
    DriverDetailsComponent.prototype.ngOnInit = function () {
        this.getShifts(this.driver);
    };
    DriverDetailsComponent.prototype.getShifts = function (driver) {
        var _this = this;
        this.shiftReportService
            .list({
            driver: driver.id,
            page_size: 1,
            ordering: '-end_time',
            include_leased_fleet_drivers: 'True',
        })
            .subscribe(function (shifts) {
            if (shifts && shifts.length) {
                var shift = shifts[0];
                _this.displayShiftStatus(driver, shift.id, shift.startTime, shift.endTime);
            }
            else {
                driver.displayShiftStatus =
                    _this.translationService.instant('No Shifts');
            }
        }, function (err) {
            console.log(err);
        });
    };
    DriverDetailsComponent.prototype.displayShiftStatus = function (driver, shiftId, startTime, endTime) {
        driver.lastShiftId = shiftId;
        var totalHours = moment
            .duration(moment(endTime ? endTime : undefined).diff(startTime))
            .asHours();
        if (!endTime) {
            if (totalHours > 8) {
                driver.displayShiftStatus = this.translationService.instant('HAS ACTIVE SHIFT of {{ hours }} Hours', { hours: totalHours.toFixed(2) });
            }
            else {
                driver.displayShiftStatus =
                    this.translationService.instant('HAS ACTIVE SHIFT');
            }
        }
        else if (totalHours > 8) {
            driver.displayShiftStatus = this.translationService.instant('{{ hours }} Hours', { hours: totalHours.toFixed(2) });
        }
        else {
            driver.displayShiftStatus = this.translationService.instant('Ended {{ endTime }}', { endTime: moment(endTime).format('MM/DD/YYYY h:mm a') });
        }
    };
    return DriverDetailsComponent;
}());
exports.DriverDetailsComponent = DriverDetailsComponent;
