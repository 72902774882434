"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var PaystubFilter = /** @class */ (function () {
    function PaystubFilter(paystubFilterInfo) {
        paystubFilterInfo = camelcaseKeysDeep(paystubFilterInfo);
        this.id = paystubFilterInfo.id;
        this.name = paystubFilterInfo.name;
    }
    return PaystubFilter;
}());
exports.PaystubFilter = PaystubFilter;
