import { Component, OnInit, Input } from '@angular/core';

import { CollaboratorService } from './collaborator.service';
import { JobEvent } from '../job-events/job-event';
import { Collaborator } from './collaborator';

@Component({
  selector: 'collaborators-widget',
  templateUrl: './collaborators-widget.component.html',
  styleUrls: ['./collaborators-widget.component.scss']
})
export class CollaboratorsWidgetComponent implements OnInit {
  @Input() jobEvent: JobEvent;
  collaborators: Collaborator[] = [];
  loading = true;
  errors = [];

  constructor(
    private collaboratorService: CollaboratorService
  ) { }

  ngOnInit() {
    this.getCollaborators();
  }

  getCollaborators() {
    if (this.jobEvent) {
      this.collaboratorService.getCollaborators(
        this.jobEvent.id,
        { organization__leased_org: 'False' }
      ).subscribe(collaborators => {
        this.collaborators = collaborators;
        this.loading = false;
      });
    }
  }
}
