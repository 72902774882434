"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var collaborator_service_1 = require("../collaborator.service");
var job_event_1 = require("../../job-events/job-event");
var api_service_1 = require("../../shared/api.service");
var collaboration_template_1 = require("../collaboration-template");
var SendCollaboratorNotesDialogComponent = /** @class */ (function () {
    function SendCollaboratorNotesDialogComponent(dialogRef, collaboratorService) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.allSelected = false;
        this.selectedShareIds = [];
        this.excludedShareIds = [];
        this.loading = false;
        this.errors = [];
        this.jobEvents = [];
        this.jobEventReqs = [];
    }
    SendCollaboratorNotesDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        if (this.jobEvent) {
            var collaboration = new collaboration_template_1.CollaborationTemplate({ notes: this.notes });
            this.collaboratorService.bulkSave(this.jobEvent.id, [collaboration]).subscribe(function (res) {
                _this.dialogRef.close();
                _this.callback();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    return SendCollaboratorNotesDialogComponent;
}());
exports.SendCollaboratorNotesDialogComponent = SendCollaboratorNotesDialogComponent;
