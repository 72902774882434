"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var RuckitInputComponent = /** @class */ (function () {
    function RuckitInputComponent() {
        this.defaultConfig = {
            type: 'text',
            small: false
        };
        this.editsMade = new core_1.EventEmitter();
        this.editMode = false;
    }
    RuckitInputComponent.prototype.ngOnInit = function () {
        this.updatedValue = this.value;
        this.config = __assign({}, this.defaultConfig, this.config);
    };
    RuckitInputComponent.prototype.enableEditing = function (enable) {
        this.editMode = enable;
        if (!this.editMode) {
            this.updatedValue = this.value;
        }
    };
    RuckitInputComponent.prototype.submitEdit = function (value) {
        this.editsMade.emit(value);
        this.editMode = false;
    };
    return RuckitInputComponent;
}());
exports.RuckitInputComponent = RuckitInputComponent;
