import {
  Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges
} from '@angular/core';
import { reject, filter } from 'lodash';

@Component({
  selector: 'applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss']
})
export class AppliedFiltersComponent implements OnInit, OnChanges {
  @Input() filters = [];
  @Input() primaryFilters = [];
  @Input() search: string;
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  @Output() filtersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() filtersReset: EventEmitter<string> = new EventEmitter();
  @Output() primaryFiltersChange: EventEmitter<any> = new EventEmitter();
  hasVisibleFilters = false;

  constructor() { }

  ngOnInit() {
    this.checkVisibleFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filters'] && changes['filters'].currentValue) {
      this.checkVisibleFilters();
    }
  }

  remove(deletedFilter): void {
    if (deletedFilter.key) {
      this.filters = reject(this.filters, { key: deletedFilter.key });
    } else {
      this.filters = this.filters.filter(_filter => _filter !== deletedFilter);
    }
    this.filtersChange.emit(this.filters);
  }

  removePrimaryFilter(deletedFilter) {
    this.primaryFiltersChange.emit(deletedFilter);
  }

  clearSearch(): void {
    this.search = '';
    this.searchChange.emit(this.search);
  }

  reset(): void {
    this.filtersReset.emit();
    this.clearSearch();
    this.filters = filter(this.filters, filtr => filtr.hidden);
    this.filtersChange.emit(this.filters);
  }

  getFilterText(filtr) {
    let filterText: string;
    if (typeof filtr.displayValues === 'string') {
      filterText = filtr.displayValues;
    } else {
      if (typeof filtr.displayValues[0] === 'string') {
        filterText = filtr.displayValues.join(', ');
      } else {
        filterText = filtr.displayValues.map(fltr => fltr.name || '').join(', ');
      }
    }
    return filterText;
  }

  checkVisibleFilters(): void {
    const visibleFilters = reject(this.filters, { hidden: true });
    this.hasVisibleFilters = visibleFilters && visibleFilters.length > 0;
  }
}
