import { StatsJobEventFilter } from './stats-job-event.filter';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class StatsJobEventFilterSerializer {
  fromJson(json: any): StatsJobEventFilter {
    json = camelcaseKeysDeep(json);
    const jobEventFilter = new StatsJobEventFilter();
    if (!json) { return jobEventFilter; }
    jobEventFilter.id = json.id;
    jobEventFilter.name = json.name;

    return jobEventFilter;
  }

  toJson(jobEventFilter: StatsJobEventFilter): any {
    let json = {
      id: jobEventFilter.id,
      name: jobEventFilter.name
    };

    return decamelizeKeysDeep(json);
  }
}
