"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["a[_ngcontent-%COMP%]{color:#d7d8d6;text-decoration:none;font-weight:400;font-size:16px;padding:15px 20px 0;width:100%;height:50px;display:block;cursor:pointer}a[_ngcontent-%COMP%]:hover{background:#58595b;color:#fff}a.active[_ngcontent-%COMP%]{border-left:4px solid #e04e39;padding:15px 20px 0 16px}a.expanded[_ngcontent-%COMP%]{background:#58595b}.hidden[_ngcontent-%COMP%]{display:none}i[_ngcontent-%COMP%]{font-size:17px;width:16px;margin-right:15px;vertical-align:middle;line-height:22px}.menu-toggle-list[_ngcontent-%COMP%]{margin-bottom:0}"];
exports.styles = styles;
