import { Pipe, PipeTransform } from '@angular/core';
import moment = require('moment');

@Pipe({
  name: 'getDurationString',
  pure: true,
})
export class GetDurationStringPipe implements PipeTransform {
  transform(
    startDatetime: string,
    endDatetime: string,
    durationType = 'hours'
  ): string {
    endDatetime = endDatetime ? endDatetime : moment().toISOString();
    if (startDatetime && endDatetime) {
      const minuteDuration = moment(endDatetime).diff(startDatetime, 'minutes');
      return getDurationString(durationType, minuteDuration);
    } else if (startDatetime) {
      const minuteDuration = moment(new Date()).diff(startDatetime, 'minutes');
      return getDurationString(durationType, minuteDuration);
    } else {
      return '-h--m';
    }
  }
}
const getDurationString = (
  durationType: string,
  minuteDuration: number
): string => {
  switch (durationType) {
    case 'minutes':
      return minuteDuration + 'm';

    default:
      return (
        Math.floor(minuteDuration / 60) + 'h ' + (minuteDuration % 60) + 'm'
      );
  }
};
