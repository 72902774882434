"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./thresholds-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i3 = require("@angular/material/icon");
var i4 = require("ngx-color-picker");
var i5 = require("@angular/common");
var i6 = require("@angular/material/dialog");
var i7 = require("@ngx-translate/core");
var i8 = require("@angular/forms");
var i9 = require("@angular/flex-layout/extended");
var i10 = require("@angular/flex-layout/core");
var i11 = require("./thresholds-dialog.component");
var i12 = require("../services/notifications-report.service");
var styles_ThresholdsDialogComponent = [i0.styles];
var RenderType_ThresholdsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThresholdsDialogComponent, data: {} });
exports.RenderType_ThresholdsDialogComponent = RenderType_ThresholdsDialogComponent;
function View_ThresholdsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "input-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "mat-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "mat-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["percent"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ThresholdsDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "threshold-rule"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "threshold-rule-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" \u00B1 ", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "class-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "div", [["class", "threshold-rule-class"]], [[4, "background", null]], [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        _v.context.$implicit.color = $event;
        var pd_3 = ((_co.saveEnabled = true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i4.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i4.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeRule(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.$implicit.color || _co.defaultColor); var currVal_3 = "rgba"; _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.value, "1.2-2")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.color || _co.defaultColor); _ck(_v, 6, 0, currVal_1); }); }
function View_ThresholdsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "thresholds"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThresholdsDialogComponent_3)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ThresholdsDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.PercentPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i6.MatDialogTitle, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Thresholds Settings"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 32, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 25, "div", [["class", "form-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 18, "div", [["class", "field-group threshold-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "label", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Threshold"])), (_l()(), i1.ɵeld(14, 0, null, null, 7, "input", [["type", "number"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 16).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 16).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(16, 16384, null, 0, i8.NumberValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i8.DefaultValueAccessor, i8.NumberValueAccessor]), i1.ɵdid(18, 540672, null, 0, i8.FormControlDirective, [[8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR], [2, i8.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i8.NgControl, null, [i8.FormControlDirective]), i1.ɵdid(20, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, null, null, 7, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(24, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(25, { "ng-valid": 0, "ng-invalid": 1 }), i1.ɵdid(26, 933888, null, 0, i9.DefaultClassDirective, [i1.ElementRef, i10.StyleUtils, i10.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(27, { "ng-valid": 0, "ng-invalid": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThresholdsDialogComponent_1)), i1.ɵdid(29, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 5, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 16777216, null, null, 1, "div", [["class", "input-color-picker"]], [[4, "background", null]], [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 32).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 32).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = ((_co.newRuleColor = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 671744, null, 0, i4.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i4.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"] }, { colorPickerChange: "colorPickerChange" }), (_l()(), i1.ɵeld(33, 0, null, null, 2, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, "span", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add "])), (_l()(), i1.ɵeld(36, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current rules."])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ThresholdsDialogComponent_2)), i1.ɵdid(39, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(41, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Save Changes "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_10 = _co.thresholdsForm; _ck(_v, 18, 0, currVal_10); var currVal_11 = _ck(_v, 25, 0, _co.thresholdsForm.valid, _co.thresholdsForm.invalid); _ck(_v, 24, 0, currVal_11); var currVal_12 = _ck(_v, 27, 0, _co.thresholdsForm.valid, _co.thresholdsForm.invalid); _ck(_v, 26, 0, currVal_12); var currVal_13 = _co.thresholdsForm.valid; _ck(_v, 29, 0, currVal_13); var currVal_15 = _co.newRuleColor; var currVal_16 = "rgba"; _ck(_v, 32, 0, currVal_15, currVal_16); var currVal_18 = i1.ɵunv(_v, 39, 0, i1.ɵnov(_v, 40).transform(_co.thresholdConfig$)); _ck(_v, 39, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 21).transform("Value without %")), ""); var currVal_3 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 20).ngClassValid; var currVal_8 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_14 = _co.newRuleColor; _ck(_v, 31, 0, currVal_14); var currVal_17 = (!_co.thresholdsForm.valid || _co.duplicatedThreshold); _ck(_v, 33, 0, currVal_17); var currVal_19 = !_co.saveEnabled; _ck(_v, 42, 0, currVal_19); }); }
exports.View_ThresholdsDialogComponent_0 = View_ThresholdsDialogComponent_0;
function View_ThresholdsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "thresholds-dialog", [], null, null, null, View_ThresholdsDialogComponent_0, RenderType_ThresholdsDialogComponent)), i1.ɵdid(1, 245760, null, 0, i11.ThresholdsDialogComponent, [i6.MatDialogRef, i12.NotificationsReportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ThresholdsDialogComponent_Host_0 = View_ThresholdsDialogComponent_Host_0;
var ThresholdsDialogComponentNgFactory = i1.ɵccf("thresholds-dialog", i11.ThresholdsDialogComponent, View_ThresholdsDialogComponent_Host_0, {}, {}, []);
exports.ThresholdsDialogComponentNgFactory = ThresholdsDialogComponentNgFactory;
