"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h2.title[_ngcontent-%COMP%]{font-size:22px;font-weight:300;color:#333;text-align:center;width:100%;padding-top:50px;margin-top:0;margin-bottom:10px}p.body[_ngcontent-%COMP%]{width:100%;text-align:center;font-size:14px;padding:0 65px;color:#72839b}.actions[_ngcontent-%COMP%]{margin:35px;text-align:center}.actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{min-width:140px;height:46px}.actions[_ngcontent-%COMP%]   button.btn[_ngcontent-%COMP%]{margin-bottom:0}.actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]:first-child{margin-right:30px}"];
exports.styles = styles;
