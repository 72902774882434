"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var notifications_report_service_1 = require("../services/notifications-report.service");
var ThresholdsDialogComponent = /** @class */ (function () {
    function ThresholdsDialogComponent(dialogRef, notificationsReportService) {
        this.dialogRef = dialogRef;
        this.notificationsReportService = notificationsReportService;
        this.defaultColor = 'rgb(195, 52, 39)';
        this.thresholdConfig$ = new rxjs_1.BehaviorSubject([]);
        this.newRuleColor = this.defaultColor;
        this.duplicatedThreshold = false;
        this.saveEnabled = false;
    }
    ThresholdsDialogComponent.prototype.ngOnInit = function () {
        this.setForm();
    };
    ThresholdsDialogComponent.prototype.ngOnDestroy = function () {
        this.formSub$.unsubscribe();
    };
    ThresholdsDialogComponent.prototype.setForm = function () {
        var _this = this;
        this.thresholdsForm = new forms_1.FormControl(null, [forms_1.Validators.required, forms_1.Validators.min(0)]);
        this.formSub$ = this.thresholdsForm.valueChanges.subscribe(function (value) { return _this.duplicatedThreshold = _this.thresholdConfig$.value.some(function (el) { return el.value === (value / 100); }); });
    };
    ThresholdsDialogComponent.prototype.addRule = function () {
        var currentRules = new Set(this.thresholdConfig$.value);
        var newRule = {
            value: this.thresholdsForm.value / 100,
            color: this.newRuleColor
        };
        currentRules.add(newRule);
        var sortedRules = Array.from(currentRules).sort(function (a, b) { return b.value - a.value; });
        this.thresholdConfig$.next(sortedRules);
        this.thresholdsForm.reset();
        this.newRuleColor = this.defaultColor;
        this.saveEnabled = true;
    };
    ThresholdsDialogComponent.prototype.removeRule = function (value) {
        var filteredRules = this.thresholdConfig$.value.filter(function (rule) { return rule.value !== value; });
        filteredRules = filteredRules.sort(function (a, b) { return b.value - a.value; });
        this.thresholdConfig$.next(filteredRules);
        this.saveEnabled = true;
    };
    ThresholdsDialogComponent.prototype.saveChanges = function () {
        var currentRules = this.thresholdConfig$.value;
        this.notificationsReportService.saveReportConfig(currentRules);
        this.callback(currentRules);
        this.dialogRef.close();
    };
    return ThresholdsDialogComponent;
}());
exports.ThresholdsDialogComponent = ThresholdsDialogComponent;
