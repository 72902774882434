import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Project } from './project';
import { ResourceService } from '../shared/resource.service';
import { ProjectSerializer } from './project.serializer';

@Injectable()
export class ProjectService extends ResourceService<Project> {
  baseUrl = environment.serverUrl;
  public nextUri: string;
  public count: number;

  constructor(http: HttpClient) {
    super(http, 'projects/', new ProjectSerializer());
  }
}
