"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var purchase_order_1 = require("./purchase-order");
var organization_1 = require("../organizations/organization");
var product_serializer_1 = require("../products/product.serializer");
var location_serializer_1 = require("../locations/location.serializer");
var price_serializer_1 = require("../prices/price.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PurchaseOrderSerializer = /** @class */ (function () {
    function PurchaseOrderSerializer() {
    }
    PurchaseOrderSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var purchaseOrder = new purchase_order_1.PurchaseOrder();
        if (!json) {
            return purchaseOrder;
        }
        purchaseOrder.id = json.id;
        if (json.customerOrganization && typeof (json.customerOrganization) === 'object') {
            purchaseOrder.customerOrganization = new organization_1.Organization(json.customerOrganization);
        }
        else {
            purchaseOrder.customerOrganization = new organization_1.Organization({ id: json.customerOrganization });
        }
        if (json.sellerOrganization && typeof json.sellerOrganization === 'object') {
            purchaseOrder.sellerOrganization = new organization_1.Organization(json.sellerOrganization);
        }
        else {
            purchaseOrder.sellerOrganization = new organization_1.Organization({ id: json.sellerOrganization });
        }
        if (json.product && typeof json.product === 'object') {
            purchaseOrder.product = new product_serializer_1.ProductSerializer().fromJson(json.product);
        }
        else {
            purchaseOrder.product = new product_serializer_1.ProductSerializer().fromJson({ id: json.product });
        }
        purchaseOrder.createdAt = json.createdAt;
        purchaseOrder.lastModified = json.lastModified;
        purchaseOrder.number = json.number;
        purchaseOrder.name = json.name;
        purchaseOrder.unitPrice = json.unitPrice;
        purchaseOrder.quantity = json.quantity;
        purchaseOrder.taxExempt = json.taxExempt;
        purchaseOrder.email = json.email;
        purchaseOrder.phoneNumber = json.phoneNumber;
        purchaseOrder.faxNumber = json.faxNumber;
        purchaseOrder.fullStop = json.fullStop;
        purchaseOrder.fulfilled = json.fulfilled || json.fullfilled;
        purchaseOrder.void = json.void;
        purchaseOrder.deleted = json.deleted;
        if (json.deliveryLocation && typeof json.deliveryLocation === 'object') {
            purchaseOrder.deliveryLocation = (new location_serializer_1.LocationSerializer).fromJson(json.deliveryLocation);
        }
        else {
            purchaseOrder.deliveryLocation = (new location_serializer_1.LocationSerializer).fromJson({ id: json.deliveryLocation });
        }
        if (json.customPrice && typeof json.customPrice === 'object') {
            purchaseOrder.customPrice = (new price_serializer_1.PriceSerializer).fromJson(json.customPrice);
        }
        else if (json.customPrice) {
            purchaseOrder.customPrice = (new price_serializer_1.PriceSerializer).fromJson({ id: json.customPrice });
        }
        purchaseOrder.notes = json.notes;
        if (json.unitOfMeasurement) {
            purchaseOrder.unitOfMeasurement = json.unitOfMeasurement;
        }
        else if (json.product && json.product.unitOfMeasurement) {
            purchaseOrder.unitOfMeasurement = json.product.unitOfMeasurement;
        }
        if (purchaseOrder.void) {
            purchaseOrder.status = 'Void';
            purchaseOrder.statusLabel = 'label-void';
        }
        else {
            purchaseOrder.status = '';
            purchaseOrder.statusLabel = '';
        }
        return purchaseOrder;
    };
    PurchaseOrderSerializer.prototype.toJson = function (purchaseOrder) {
        var json = {
            id: purchaseOrder.id,
            customerOrganization: purchaseOrder.customerOrganization && purchaseOrder.customerOrganization.id,
            sellerOrganization: purchaseOrder.sellerOrganization && purchaseOrder.sellerOrganization.id,
            product: purchaseOrder.product && purchaseOrder.product.id,
            createdAt: purchaseOrder.createdAt,
            lastModified: purchaseOrder.lastModified,
            number: purchaseOrder.number,
            name: purchaseOrder.name,
            unitPrice: purchaseOrder.unitPrice,
            quantity: purchaseOrder.quantity,
            taxExempt: purchaseOrder.taxExempt,
            email: purchaseOrder.email,
            phoneNumber: purchaseOrder.phoneNumber,
            faxNumber: purchaseOrder.faxNumber,
            fullStop: purchaseOrder.fullStop,
            fulfilled: purchaseOrder.fulfilled,
            void: purchaseOrder.void,
            deleted: purchaseOrder.deleted,
            deliveryLocation: purchaseOrder.deliveryLocation && purchaseOrder.deliveryLocation.id,
            notes: purchaseOrder.notes,
            customPrice: purchaseOrder.customPrice && purchaseOrder.customPrice.id,
            unitOfMeasurement: purchaseOrder.unitOfMeasurement,
            status: purchaseOrder.status,
            statusLabel: purchaseOrder.statusLabel
        };
        return decamelizeKeysDeep(json);
    };
    return PurchaseOrderSerializer;
}());
exports.PurchaseOrderSerializer = PurchaseOrderSerializer;
