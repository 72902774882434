import { CondensedJobEvent } from './condensed-job-event';
import { TagSerializer } from '../tags/tag.serializer';
import * as moment from 'moment';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class CondensedJobEventSerializer {
  fromJson(json: any): CondensedJobEvent {
    json = camelcaseKeysDeep(json);
    const condensedJobEvent = new CondensedJobEvent();
    if (!json) { return condensedJobEvent; }

    condensedJobEvent.id = json.id;
    condensedJobEvent.jobId = json.jobId;
    condensedJobEvent.jobName = json.jobName;
    condensedJobEvent.jobNumber = json.jobNumber;
    condensedJobEvent.jobAmountNeeded = json.jobAmountNeeded;
    condensedJobEvent.projectName = json.projectName;
    condensedJobEvent.customerId = json.customerId;
    condensedJobEvent.customer = json.customer;
    condensedJobEvent.apexCustomerJob = json.apexCustomerJob;
    if (json.tags && json.tags.length) {
      condensedJobEvent.tags = json.tags && json.tags.map(tag => {
        if (typeof tag === 'object') {
          return new TagSerializer().fromJson(tag);
        } else {
          return new TagSerializer().fromJson({ id: tag });
        }
      });
    }
    condensedJobEvent.routePolyline = json.routePolyline;
    condensedJobEvent.startLocation = json.startLocation;
    condensedJobEvent.startLocationName = json.startLocationName;
    condensedJobEvent.startLocationPoint = json.startLocationPoint;
    condensedJobEvent.startLocationGeofence = json.startLocationGeofence;
    condensedJobEvent.endLocation = json.endLocation;
    condensedJobEvent.endLocationName = json.endLocationName;
    condensedJobEvent.endLocationPoint = json.endLocationPoint;
    condensedJobEvent.endLocationGeofence = json.endLocationGeofence;
    condensedJobEvent.jobStartDate = json.jobStartDate;
    condensedJobEvent.jobEndDate = json.jobEndDate;
    condensedJobEvent.material = json.material;
    condensedJobEvent.phaseCode = json.phaseCode;
    condensedJobEvent.numTrucks = json.numTrucks;
    condensedJobEvent.notes = json.notes;
    condensedJobEvent.shareNotes = json.shareNotes;
    condensedJobEvent.rate = json.rate;
    condensedJobEvent.invoiceType = json.invoiceType;
    condensedJobEvent.invoiceWeightUnit = json.invoiceWeightUnit;
    condensedJobEvent.externalIdentifier = json.externalIdentifier;
    condensedJobEvent.shift1Start = json.shift1Start;
    condensedJobEvent.shift1End = json.shift1End;
    condensedJobEvent.shift2Start = json.shift2Start;
    condensedJobEvent.shift2End = json.shift2End;
    condensedJobEvent.jobEnd = json.shift2End ? json.shift2End : json.shift1End;
    if (condensedJobEvent.shift1Start && condensedJobEvent.shift1End) {
      condensedJobEvent.overnight = moment(condensedJobEvent.shift1Start).format('YYYYMMDD') !== moment(condensedJobEvent.shift1End).format('YYYYMMDD');
    }
    if (!condensedJobEvent.overnight && condensedJobEvent.shift1Start && condensedJobEvent.shift2End) {
      condensedJobEvent.overnight = moment(condensedJobEvent.shift1Start).format('YYYYMMDD') !== moment(condensedJobEvent.shift2End).format('YYYYMMDD');
    }
    condensedJobEvent.cancelled = json.cancelled;
    condensedJobEvent.canEdit = json.canEdit === undefined ? true : json.canEdit;
    condensedJobEvent.loadDeliveryTarget = json.loadDeliveryTarget;
    condensedJobEvent.dailyDeliveryTarget = json.dailyDeliveryTarget;
    condensedJobEvent.dailyDeliveryTargetType = json.dailyDeliveryTargetType;
    condensedJobEvent.totalAmountType = json.totalAmountType;
    if (json.jobDisplayName) {
      condensedJobEvent.jobDisplayName = json.jobDisplayName;
    } else {
      condensedJobEvent.jobDisplayName = [
        condensedJobEvent.externalIdentifier,
        condensedJobEvent.jobName
      ].filter(Boolean).join(': ');
    }
    condensedJobEvent.status = json.status;
    condensedJobEvent.shift1Status = json.shift1Status;
    condensedJobEvent.shift2Status = json.shift2Status;
    if (
      (condensedJobEvent.shift1Status === 'cancelled' && condensedJobEvent.shift2Status === 'cancelled') ||
      condensedJobEvent.status === 'cancelled' || condensedJobEvent.cancelled
    ) {
      condensedJobEvent.statusText = 'Day Cancelled';
    } else if (condensedJobEvent.shift1Status === 'cancelled') {
      condensedJobEvent.statusText = 'Shift 1 Cancelled';
    } else if (condensedJobEvent.shift2Status === 'cancelled') {
      condensedJobEvent.statusText = 'Shift 2 Cancelled';
    } else {
      condensedJobEvent.statusText = condensedJobEvent.status;
    }

    if (condensedJobEvent.invoiceType === 'weight') {
      if (condensedJobEvent.invoiceWeightUnit === 'cuyds') {
        condensedJobEvent.invoiceRateUnit = 'cu. yd.';
      } else {
        condensedJobEvent.invoiceRateUnit = condensedJobEvent.invoiceWeightUnit;
      }
    } else {
      condensedJobEvent.invoiceRateUnit = condensedJobEvent.invoiceType;
    }
    if (condensedJobEvent.routePolyline) {
      condensedJobEvent.mapImageURL = 'https://maps.googleapis.com/maps/api/staticmap?size=418x178&path=color:0x002649|weight:4|enc:';
      condensedJobEvent.mapImageURL += condensedJobEvent.routePolyline;
      condensedJobEvent.mapImageURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
    } else {
      condensedJobEvent.mapImageURL = '/assets/img/missing-locations.png';
    }
    if (
      condensedJobEvent.startLocationPoint &&
      condensedJobEvent.startLocationPoint.coordinates &&
      condensedJobEvent.endLocationPoint &&
      condensedJobEvent.endLocationPoint.coordinates
    ) {
      condensedJobEvent.directions = {
        origin: condensedJobEvent.startLocationPoint.coordinates[1] + ',' + condensedJobEvent.startLocationPoint.coordinates[0],
        destination: condensedJobEvent.endLocationPoint.coordinates[1] + ',' + condensedJobEvent.endLocationPoint.coordinates[0],
        travelMode: 'DRIVING'
      };
    }

    condensedJobEvent.notice = {
      statusText: condensedJobEvent.projectName, statusType: 'standard'
    };

    condensedJobEvent.workOrderTemplate = json.workOrderTemplate;
    condensedJobEvent.workOrderTemplateName = json.workOrderTemplateName;
    condensedJobEvent.orderNumber = json.orderNumber;
    condensedJobEvent.orderNumberJobName = [
      condensedJobEvent.orderNumber,
      condensedJobEvent.jobName
    ].filter(Boolean).join(' - ');
    condensedJobEvent.deliveryInterval = json.deliveryInterval;
    condensedJobEvent.deliveryIntervalUnit = json.deliveryIntervalUnit;
    condensedJobEvent.truckTypes = json.truckTypes;
    if (condensedJobEvent.truckTypes) {
      condensedJobEvent.truckTypeNames = condensedJobEvent.truckTypes.map(t => (t.name)).join(', ');
    }
    condensedJobEvent.allowAnyTruckType = json.allowAnyTruckType;
    condensedJobEvent.lastDispatchedTime = json.lastDispatchedTime;
    condensedJobEvent.notesList = json.notesList;

    // buffer times
    condensedJobEvent.defaultRoundTripTime = json.defaultRoundTripTime;
    condensedJobEvent.defaultYardPrepTime = json.defaultYardPrepTime;
    condensedJobEvent.defaultYardBufferTime = json.defaultYardBufferTime;
    if (json.defaultYardBufferTime && json.defaultYardBufferTime > 0) {
      condensedJobEvent.defaultYardBufferMinutes = json.defaultYardBufferTime / 60;
    }
    condensedJobEvent.slorderId = json.slorderId;
    condensedJobEvent.dsorderId = json.dsorderId;
    condensedJobEvent.connexActualLoads = json.connexActualLoads;
    condensedJobEvent.connexActualQuantity = json.connexActualQuantity;
    condensedJobEvent.pin = json.pin;
    condensedJobEvent.requestedAmount = json.requestedAmount;
    condensedJobEvent.requestedUnit = json.requestedUnit;

    return condensedJobEvent;
  }

  toJson(condensedJobEvent: CondensedJobEvent): any {
    let json = {
      id: condensedJobEvent.id
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    if (json['defaultYardBufferMinutes']) {
      let minutes = json['defaultYardBufferMinutes'] || 0;
      json['defaultYardBufferTime'] = (minutes * 60) || json['defaultYardBufferTime'];
    }

    return decamelizeKeysDeep(json);
  }
}
