<div class="app-content">
  <my-toolbar title="Punch Cards - {{ expense && expense.customerOrganization.name }}" [userDropdown]=false>
    <div class="right-actions" right-last>
      <button class="link" (click)="dialogRef.close()" [disabled]="loading"><span class="icon-close"></span></button>
    </div>
  </my-toolbar>

  <action-bar>
    <div class="left-actions">
      <date-range-picker class="date-select" [selectedDates]="[startDate]" (dateChanged)="onDateChanged($event, 'startDate')"></date-range-picker>
      <date-range-picker class="date-select" [selectedDates]="[endDate]" (dateChanged)="onDateChanged($event, 'endDate')"></date-range-picker>
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Items Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Item Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Items Selected' | translate }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='punch-card-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Items' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Item' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Items' | translate }}</ng-template>
        </span>
      </div>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <button class="btn btn-primary" (click)="addToExpense()" [disabled]="loading || (!allSelected && !selectedPunchCards.length)">
        <span class="icon-plus"></span> Add to Expense
      </button>
    </div>
  </action-bar>

  <div class="punch-cards">
    <div class="list-table">
      <div class="table-body" (scroll)="onScroll($event)">
        <div class="header">
          <div class="head selector">
            <label>
              <input type="checkbox" (change)="selector($event)" [(ngModel)]="allSelected" />
            </label>
          </div>
          <div class="head date sort" (click)="sort('jobevent__shift1_start')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__shift1_start'}">
            Job Date
          </div>
          <div class="head job-title sort" (click)="sort('jobevent__job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__job__name'}">
            Job
          </div>
          <div class="head time sort" (click)="sort('start_time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'start_time'}">
            Time
          </div>
          <div class="head ticket-number sort" (click)="sort('ticket_number')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'ticket_number'}">
            Ticket #
          </div>
          <div class="head ticket-image">
            Ticket Image
          </div>
          <!-- <div class="head carrier">
            Customer
          </div> -->
          <div class="head driver sort" (click)="sort('driver__profile__first_name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__first_name'}">
            Driver
          </div>
          <div class="head truck sort" (click)="sort('truck__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'truck__name'}">
            Truck
          </div>
          <!-- <div class="head payable-to">
            Payable To
          </div> -->
          <div class="head customer sort" (click)="sort('jobevent__customer_organization__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__customer_organization__name'}">
            Customer
          </div>
          <div class="head invoice">
            Invoice Rate
          </div>
          <div class="head invoice-total">
            Invoice Total
          </div>
          <div class="head haul">
            Haul Rate
          </div>
          <div class="head haul-total">
            Haul Total
          </div>
          <div class="head actions">
            Actions
          </div>
        </div>

        <div class="no-results" *ngIf="!loading && punchCards.length === 0" fxLayout="column" fxLayoutAlign="start center">
          <div class="icon"><i class="icon-team"></i></div>
          <h2>No Invoiceable Punch Cards</h2>
          <p>When you complete punch cards you’ll see them here.</p>
        </div>

        <div *ngFor="let punchCard of punchCards" class="row" [ngClass]="{selected: allSelected || punchCard.selected}">
          <div class="selector">
            <label>
              <input type="checkbox" (change)="selector($event, punchCard)" [checked]="allSelected || punchCard.selected" />
            </label>
          </div>
          <div class="date">
            <div class="value">{{ punchCard.jobDate | date:'mediumDate' || '&nbsp;' }}</div>
            <div class="punch-card-status">
              <div class="label-{{punchCard.administrativeStatus}}" *ngIf="punchCard.displayableStatus">{{ punchCard.administrativeStatus }}</div>
              <div class="spacer" *ngIf="!punchCard.displayableStatus">&nbsp;</div>
            </div>
          </div>
          <div class="job-title">
            <div class="value">{{ punchCard.job || '&nbsp;' }}</div>
            <div class="label">{{ punchCard.project || '&nbsp;' }}</div>
          </div>
          <div class="time">
            <div class="value">{{ (punchCard.endTimeTimestamp ? punchCard.duration : formattedDuration(punchCard.startTimeTimestamp)) || '&nbsp;' }}</div>
            <div class="label">{{ punchCard.schedule || '&nbsp;' }}</div>
          </div>
          <div class="ticket-number">
            <div class="value" [ngClass]="{'missing-required': !punchCard.ticketNumber}">{{ punchCard.ticketNumber || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="ticket-image">
            <div class="ticket-photo" *ngIf="!punchCard.hasTicketImage">
              <div class="value" class="missing-required">&nbsp;</div>
              <div class="label">No Ticket</div>
            </div>
            <div class="ticket-photo" *ngIf="punchCard.hasTicketImage">
              <span><i class="icon-ticket"></i></span>
            </div>
          </div>
          <!-- <div class="carrier">
            <div class="value">{{ punchCard.carrier || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div> -->
          <div class="driver">
            <div class="value">{{ punchCard.driver || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="truck">
            <div class="value">{{ punchCard.truckName || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <!-- <div class="payable-to">
            <div class="value">{{ punchCard.payableTo || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div> -->
          <div class="customer">
            <div class="value">{{ punchCard.customer || '&nbsp;' }}</div>
            <div class="label">{{ (punchCard.customerPhone | telephone) || '&nbsp;' }}</div>
          </div>
          <div class="invoice">
            <div class="value" [ngClass]="{'missing-required': punchCard.invoiceRateUnit === null}">{{ (punchCard.invoiceRate | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
            <div class="label">{{ punchCard.invoiceRateUnit || '&nbsp;' | titleCase }}</div>
          </div>
          <div class="invoice-total">
            <div class="value" [ngClass]="{'missing-required': punchCard.invoiceTotal === null}">{{ (punchCard.invoiceTotal | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="haul">
            <div class="value" [ngClass]="{'missing-required': punchCard.haulRateUnit === null}">{{ (punchCard.haulRate | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
            <div class="label">{{ punchCard.haulRateUnit || '&nbsp;' | titleCase }}</div>
          </div>
          <div class="haul-total">
            <div class="value">{{ (punchCard.haulTotal || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="actions">
            <action-menu icon="icon-more">
              <ng-container *ngFor="let option of punchCard.filterOptions(menuOptions)">
                <a *ngIf="option.link" mat-menu-item (click)="dialogRef.close(); false" [routerLink]="punchCard.url(option.action)" [queryParams]="{ returnTo: '/expenses/' + this.expense.id + '/edit' }">{{ option.name }}</a>
                <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, punchCard)">{{ option.name }}</a>
              </ng-container>
            </action-menu>
          </div>
        </div>

        <notification *ngFor="let error of errors">{{error}}</notification>
        <my-loader *ngIf="loading" [ngClass]="{'full-height': punchCards.length === 0}"></my-loader>
      </div>
    </div>
  </div>
</div>
