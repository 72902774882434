import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material';

import { TripFilterService } from '../../trips/trip.filter.service';

@Component({
  selector: 'driver-trips-filters-dialog',
  templateUrl: './driver-trip-filters-dialog.component.html',
  styleUrls: ['./driver-trip-filters-dialog.component.scss'],
})
export class DriverTripFiltersDialogComponent implements OnInit {
  datePipe = new DatePipe('en-US');
  loading = false;
  errors = [];
  @Input()
  title: string;

  defaultDropdownConfig = {
    service: TripFilterService,
    serviceFunction: 'listFilters',
    searchKey: 'filter_search',
  };
  customerDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Customer',
    loadingText: 'Loading Customers...',
    noResultsText: 'No Customers',
    serviceFunctionScope: 'customers',
    getFilter: 'jobevent__customer_organization',
    query: { serializer: 'OrganizationFilter' },
  };
  projectDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Project',
    loadingText: 'Loading Projects...',
    noResultsText: 'No Projects',
    serviceFunctionScope: 'projects',
    getFilter: 'jobevent__job__project',
    query: { serializer: 'ProjectFilter' },
  };
  jobDropdownConfig = {
    ...this.defaultDropdownConfig,
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    serviceFunctionScope: 'jobs',
    getFilter: 'jobevent__job',
    query: {
      serializer: 'JobFilter',
      jobevent__shift1_start__gte: null,
      jobevent__shift1_start__lte: null,
    },
  };

  callback: Function;
  visibleFields = {
    showEdited: true,
    showIncomplete: true,
    showUninvoiced: true,
    showRetake: true,
    showFromDate: true,
    showDateTo: true,
  };
  model: {
    startDate?: Date;
    endDate?: Date;
    [fieldName: string]: any;
  } = {};

  constructor(
    public dialogRef: MatDialogRef<DriverTripFiltersDialogComponent>
  ) {}

  ngOnInit() {
    this.filterTrips();
  }

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.convertDates();
    this.callback(this.model);
    this.dialogRef.close();
  }

  convertDates(assign = true) {
    let startDate: Date, endDate: Date;
    if (this.model.startDate) {
      startDate = new Date(this.model.startDate);
      let name = this.datePipe.transform(startDate, 'MM/dd/yyyy');
      startDate.setHours(0, 0, 0, 0);
      if (assign) {
        Object.assign(this.model.startDate, {
          id: startDate.toISOString(),
          name: name,
        });
      }
    }

    if (this.model.endDate) {
      endDate = new Date(this.model.endDate);
      let name = this.datePipe.transform(endDate, 'MM/dd/yyyy');
      endDate.setHours(23, 59, 59, 999);
      if (assign) {
        Object.assign(this.model.endDate, {
          id: endDate.toISOString(),
          name: name,
        });
      }
    }

    if (!assign) {
      return {
        startDate: startDate && startDate.toISOString(),
        endDate: endDate && endDate.toISOString(),
      };
    }
  }

  filterTrips(): void {
    let { startDate, endDate } = this.convertDates(false);
    this.jobDropdownConfig.query = {
      ...this.jobDropdownConfig.query,
      ...{
        jobevent__shift1_start__gte: startDate,
        jobevent__shift1_start__lte: endDate,
      },
    };
    this.jobDropdownConfig = { ...this.jobDropdownConfig };
  }

  onDateChanged(dates: Date[], type = ''): void {
    this.model[type] = dates[0];
    this.filterTrips();
  }
}
