"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".dialog-content[_ngcontent-%COMP%]{padding:12px 30px}.dialog-content[_ngcontent-%COMP%]   .character-limit[_ngcontent-%COMP%], .dialog-content[_ngcontent-%COMP%]   .content-title[_ngcontent-%COMP%]{color:#7f8fa4;font-weight:600}.dialog-content[_ngcontent-%COMP%]   .driver-note[_ngcontent-%COMP%]{margin:12px 0}.dialog-content[_ngcontent-%COMP%]   textarea[_ngcontent-%COMP%]{resize:none}.close-action[_ngcontent-%COMP%]{color:#fff!important}"];
exports.styles = styles;
