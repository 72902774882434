import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { TicketFilterService } from './ticket.filter.service';
import { ConnectionService } from '../connections/connection.service';
import { parseErrors } from '../shared/api.service';
import { clone, find as _find, reject } from 'lodash';

@Component({
  selector: 'ticket-filter-dialog',
  templateUrl: './ticket-filters-dialog.component.html',
  styleUrls: ['./ticket-filters-dialog.component.scss'],
  providers: [
    TicketFilterService, ConnectionService
  ]
})
export class TicketFiltersDialogComponent implements OnInit {
  loading = false;
  errors = [];
  trucks = [];
  trucksConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  purchaseOrders = [];
  ordersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  customers = [];
  customersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  products = [];
  productsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  locations = [];
  locationsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  @ViewChild('ordersDropdown', { static: false }) ordersDropdown;
  @ViewChild('customersDropdown', { static: false }) customersDropdown;
  @ViewChild('trucksDropdown', { static: false }) trucksDropdown;
  @ViewChild('productsDropdown', { static: false }) productsDropdown;
  @ViewChild('locationsDropdown', { static: false }) locationsDropdown;
  callback;
  model = {
    truck: null,
    customer: null,
    order: null,
    product: null,
    location: null,
    void : false,
    onHold : false,
    completed : false,
    inProgress : false
  };
  trucksReq;
  customersReq;
  ordersReq;
  productsReq;
  locationsReq;

  constructor(
    private ticketFilterService: TicketFilterService,
    private connectionService: ConnectionService,
    public dialogRef: MatDialogRef<TicketFiltersDialogComponent>
  ) { }

  ngOnInit() {
    this.getOrders();
    this.getCustomers();
    this.getTrucks();
    this.getProducts();
    this.getLocations();
  }

  onSelect(filterType, e) {
    this.model[filterType] = e;
  }

  submit() {
    this.callback(this.model);
    this.dialogRef.close();
  }

  getTrucks(search = null) {
    this.trucks = [];
    this.trucksConfig.loadingOptions = true;
    this.trucksConfig = clone(this.trucksConfig);
    if (this.trucksReq) { this.trucksReq.unsubscribe(); }

    this.trucksReq = this.ticketFilterService.listFilters(
      'trucks', search
    ).subscribe(trucks => {
      this.trucks = trucks;
      if (this.model.truck) {
        let selectedOption = _find(this.trucks, { id: this.model.truck.id });
        this.trucks = reject(this.trucks, selectedOption);
        this.trucks.unshift(this.model.truck);
        this.trucksDropdown.selectedOption = selectedOption;
      }
      this.trucksDropdown.config.loadingOptions = false;
      this.trucksConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.loading = false;
      this.trucksDropdown.config.loadingOptions = false;
      this.trucksConfig.loadingOptions = false;
      this.trucksConfig = clone(this.trucksConfig);
    });
  }

  getCustomers(search = null) {
    if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
      this.customersReq.unsubscribe();
    }

    this.customersReq = this.ticketFilterService.listFilters(
      'customers', search
    ).subscribe(customers => {
      this.customers = customers;
      if (this.model.customer) {
        let selectedOption = _find(this.customers, { id: this.model.customer.id });
        this.customers = reject(this.customers, selectedOption);
        this.customers.unshift(this.model.customer);
        this.customersDropdown.selectedOption = selectedOption;
      }
      this.customersDropdown.config.loadingOptions = false;
      this.customersConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.customersConfig.loadingOptions = false;
    });
  }

  getOrders(search = null) {
    if (this.ordersReq && typeof this.ordersReq.unsubscribe === 'function') {
      this.ordersReq.unsubscribe();
    }

    this.ordersReq = this.ticketFilterService.listFilters(
      'purchaseorders', search
    ).subscribe(orders => {
      this.purchaseOrders = orders;
      if (this.model.order) {
        let selectedOption = _find(this.purchaseOrders, { id: this.model.order.id });
        this.purchaseOrders = reject(this.purchaseOrders, selectedOption);
        this.purchaseOrders.unshift(this.model.order);
        this.ordersDropdown.selectedOption = selectedOption;
      }
      this.ordersDropdown.config.loadingOptions = false;
      this.ordersConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.ordersConfig.loadingOptions = false;
    });
  }

  getProducts(search = null) {
    if (this.productsReq && typeof this.productsReq.unsubscribe === 'function') {
      this.productsReq.unsubscribe();
    }

    this.productsReq = this.ticketFilterService.listFilters(
      'products', search
    ).subscribe(products => {
      this.products = products;
      if (this.model.product) {
        let selectedOption = _find(this.products, { id: this.model.product.id });
        this.products = reject(this.products, selectedOption);
        this.products.unshift(this.model.product);
        this.productsDropdown.selectedOption = selectedOption;
      }
      this.productsDropdown.config.loadingOptions = false;
      this.productsConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.productsConfig.loadingOptions = false;
    });
  }

  getLocations(search = null) {
    if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
      this.locationsReq.unsubscribe();
    }

    this.locationsReq = this.ticketFilterService.listFilters(
      'locations', search
    ).subscribe(locations => {
      this.locations = locations;
      if (this.model.location) {
        let selectedOption = _find(this.locations, { id: this.model.location.id });
        this.locations = reject(this.locations, selectedOption);
        this.locations.unshift(this.model.location);
        this.locationsDropdown.selectedOption = selectedOption;
      }
      this.locationsDropdown.config.loadingOptions = false;
      this.locationsConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.locationsConfig.loadingOptions = false;
    });
  }

  dropdownNextPage(e, type) {
    let config;
    let filterType;
    let options;

    switch (type) {
      case 'customer':
        filterType = 'customers';
        config = this.customersConfig;
        options = this.customers;
        break;
      case 'truck':
        filterType = 'trucks';
        config = this.trucksConfig;
        options = this.trucks;
        break;
      case 'order':
        filterType = 'purchaseOrders';
        config = this.ordersConfig;
        options = this.purchaseOrders;
        break;
      case 'product':
        filterType = 'products';
        config = this.productsConfig;
        options = this.products;
        break;
      case 'location':
        filterType = 'locations';
        config = this.locationsConfig;
        options = this.locations;
        break;
    }

    if (!config.loadingOptions) {
      let o = this.ticketFilterService.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(
          results => {
            switch (type) {
              case 'customer':
                this.customers = this.customers.concat(results);
                break;
              case 'truck':
                this.trucks = this.trucks.concat(results);
                break;
              case 'order':
                this.purchaseOrders = this.purchaseOrders.concat(results);
                break;
              case 'product':
                this.products = this.products.concat(results);
                break;
              case 'location':
                this.locations = this.locations.concat(results);
                break;
            }
            config.loadingOptions = false;
          }, (err) => {
            this.errors = parseErrors(err);
            config.loadingOptions = false;
          }
        );
      }
    }
  }

  dropdownSearch(term = '', type) {
    switch (type) {
      case 'customer':
        this.getCustomers(term);
        break;
      case 'truck':
        this.getTrucks(term);
        break;
      case 'order':
        this.getOrders(term);
        break;
      case 'product':
        this.getProducts(term);
        break;
      case 'location':
        this.getLocations(term);
        break;
      default:
        throw 'invalid dropdown type';
    }
  }
}
