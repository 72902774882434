"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var location_owner_1 = require("./location-owner");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var LocationOwnerSerializer = /** @class */ (function () {
    function LocationOwnerSerializer() {
    }
    LocationOwnerSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var locationOwner = new location_owner_1.LocationOwner();
        if (!json) {
            return locationOwner;
        }
        locationOwner.id = json.id;
        locationOwner.organization = json.organization;
        locationOwner.createdAt = json.createdAt;
        locationOwner.lastModified = json.lastModified;
        locationOwner.ownerOrganization = json.ownerOrganization;
        locationOwner.name = json.name;
        return locationOwner;
    };
    LocationOwnerSerializer.prototype.toJson = function (locationOwner) {
        var json = {
            id: locationOwner.id,
            organization: locationOwner.organization,
            createdAt: locationOwner.createdAt,
            lastModified: locationOwner.lastModified,
            ownerOrganization: locationOwner.ownerOrganization,
            name: locationOwner.name
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return LocationOwnerSerializer;
}());
exports.LocationOwnerSerializer = LocationOwnerSerializer;
