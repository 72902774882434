import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Connection } from './connection';
import { requestHeaders } from '../shared/index';
import { ResourceService } from '../shared/resource.service';
import { ConnectionSerializer } from './connection.serializer';

@Injectable()
export class ConnectionService extends ResourceService<Connection> {
  baseUrl = environment.serverUrl;
  token: string;
  params: HttpParams = new HttpParams();
  public nextUri;
  public count = 10;

  constructor(http: HttpClient) {
    super(http, 'connections/', new ConnectionSerializer());
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
    this.params = new HttpParams();
  }

  getInvoiceStats(connectionId: string) {
    let connectionUrl = this.baseUrl + 'connections/' + connectionId + '/invoice_stats/';

    return this.http.get(connectionUrl, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res); })
    );
  }

  suspendConnection(connectionId: string): Observable<boolean> {
    let connectionUrl = this.baseUrl + 'connections/' + connectionId + '/';

    return this.http.put(connectionUrl, {status: 'suspended'}, {headers: requestHeaders()}).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }

  disconnectConnection(connectionId: string): Observable<boolean> {
    let connectionUrl = this.baseUrl + 'connections/' + connectionId + '/';

    return this.http.put(connectionUrl, {status: 'disconnected'}, {headers: requestHeaders()}).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(conn => {
        return new Connection();
      });
    } else if (resObj) {
      return new Connection();
    } else {
      return {};
    }
  }
}

