<item-grid
  *ngIf="connectionList && loadingProgress === 100"
  [itemGroups]="filteredConnectionList"
  [itemClassNames]="generateItemClassNames"
  [displayKeys]="displayKeys"
  [searchProps]="displayKeys"
  [(selectedItems)]="selectedConnections"
>
  <div header-stats translate>Collaborators</div>
</item-grid>

<div class="grid-loader" *ngIf="!connectionList || loadingProgress < 100">
  <h2 translate>Getting Full Collaborator List</h2>
  <h3 translate>This may take longer depending on the number of Connections you have</h3>
  <div class="loading-bar">
    <span class="text">{{ loadingProgress + '% Loaded' | translate }}</span>
    <div class="progress" [ngStyle]="{'width': loadingProgress + '%'}"></div>
  </div>
</div>
