import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { parseErrors } from '../../shared';
import { Shift } from '../shift';
import { ShiftService } from '../shift.service';

export type EditShiftsData = {
  shifts: string[],
  callback?: (param?) => void,
};

@Component({
  selector: 'edit-shifts-dialog',
  templateUrl: './edit-shifts-dialog.component.html',
  styleUrls: ['./edit-shifts-dialog.component.scss']
})
export class EditShiftsDialogComponent implements OnInit, OnDestroy {
  attributes: any = {};
  errors = [];
  loading = false;
  callback;

  shiftsReq: Subscription;

  constructor(
    public dialogRef: MatDialogRef<EditShiftsDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EditShiftsData,
    private shiftService: ShiftService
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
      this.shiftsReq.unsubscribe();
    }
  }

  submit() {
    if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
      this.shiftsReq.unsubscribe();
    }

    this.loading = true;

    let saveData = this.data.shifts.map(shiftId => {
      return <Shift>{
        id: shiftId,
        startTime: this.attributes['startTime'],
        endTime: this.attributes['endTime'],
        adjustment: this.attributes['adjustment']
      };
    });
    this.shiftService.bulkUpdate(saveData).subscribe(bulkRes => {
      if (bulkRes.errors && bulkRes.errors.length) {
        this.errors = bulkRes.errors.map(e => (e.error));
      } else {
        this.dialogRef.close();
        this.data.callback(bulkRes);
      }
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  /**
   * Makes an edit to either the startTime or endTime field and applies
   * that edit to all selected shift records
   *
   * @param {string} field The time field we will apply the edit to
   * @param {any} value The edit value for the time adjustment
   */
   editTime(field: string, value: any) {
    if (field.toLowerCase().includes('start')) {
      this.attributes['startTime'] = field.toLowerCase().includes('time') ?
        moment(`${moment(this.attributes['startTime']).format('YYYY-MM-DD')} ${value}`, 'YYYY-MM-DD HH:mm').toISOString() :
        moment(`${value} ${moment(this.attributes['startTime']).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').toISOString();
    } else if (field.toLowerCase().includes('end')) {
      this.attributes['endTime'] = field.toLowerCase().includes('time') ?
        moment(`${moment(this.attributes['endTime']).format('YYYY-MM-DD')} ${value}`, 'YYYY-MM-DD HH:mm').toISOString() :
        moment(`${value} ${moment(this.attributes['endTime']).format('HH:mm')}`, 'YYYY-MM-DD HH:mm').toISOString();
    }
  }

  /**
   * Makes an edit to the adjustment field and applies that edit to
   * all selected shift records
   *
   * @param {any} value The edit value for the adjustment
   */
  editAdjustment(value: any) {
    this.attributes['adjustment'] = Number(value);
  }

}
