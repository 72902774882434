"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resource_1 = require("../shared/resource");
var CondensedJobEvent = /** @class */ (function (_super) {
    __extends(CondensedJobEvent, _super);
    function CondensedJobEvent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CondensedJobEvent.prototype.url = function (action) {
        if (action === 'dispatch-drivers') {
            return "/dispatch/" + this.jobId + "/" + this.id;
        }
        else if (action === 'add-collaborators') {
            return "/jobs/" + this.jobId + "/" + this.id + "/collaborators";
        }
        else if (action === 'edit') {
            return "/jobs/" + this.jobId + "/edit";
        }
        else if (action === 'clone') {
            return "/jobs/" + this.jobId + "/clone";
        }
        else if (action === 'view-tickets') {
            return "/orders/" + this.id + "/tickets";
        }
        else {
            return "/jobs/" + this.jobId + "/" + this.id + "/" + action;
        }
    };
    CondensedJobEvent.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.cancelled) {
            var _option = lodash_1.find(options, { action: 'cancel-day' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.canEdit) {
            options = options.filter(function (option) { return !['edit', 'edit-days', 'cancel-day'].includes(option.action); });
        }
        return options;
    };
    return CondensedJobEvent;
}(resource_1.Resource));
exports.CondensedJobEvent = CondensedJobEvent;
