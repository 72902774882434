"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var job_serializer_1 = require("../jobs/job.serializer");
var truck_type_serializer_1 = require("../trucks/truck-type.serializer");
var Invitation = /** @class */ (function () {
    function Invitation(invitationInfo) {
        this.id = invitationInfo.id;
        this.kind = invitationInfo.kind;
        this.recipientEmail = invitationInfo.recipientEmail;
        this.status = invitationInfo.status;
        this.senderOrganization = invitationInfo.senderOrganization;
        this.recipientOrganization = invitationInfo.recipientOrganization;
        this.resultUser = invitationInfo.resultUser;
        this.createdAt = invitationInfo.createdAt;
        this.lastModified = invitationInfo.lastModified;
        this.resentAt = invitationInfo.resentAt;
        this.data = invitationInfo.data;
        this.senderFirstName = invitationInfo.senderFirstName;
        this.senderLastName = invitationInfo.senderLastName;
        this.allowAnyTruckType = invitationInfo.allowAnyTruckType;
        if (invitationInfo.truckTypes && invitationInfo.truckTypes.length) {
            this.truckTypes = invitationInfo.truckTypes.map(function (truckType) {
                if (truckType && typeof truckType === 'object') {
                    return new truck_type_serializer_1.TruckTypeSerializer().fromJson(truckType);
                }
                else if (invitationInfo.truckTypes) {
                    return new truck_type_serializer_1.TruckTypeSerializer().fromJson({ id: truckType });
                }
            });
        }
        else {
            this.truckTypes = [];
        }
        if (invitationInfo.job) {
            this.job = new job_serializer_1.JobSerializer().fromJson(invitationInfo.job);
        }
    }
    Invitation.prototype.save = function () {
        // Save to API
        console.log(this.id, this.kind, this.recipientEmail, this.status);
    };
    Object.defineProperty(Invitation.prototype, "senderIndentifiers", {
        get: function () {
            var identifiers = [];
            if (this.senderOrganization && this.senderOrganization.carrier) {
                identifiers.push(this.senderOrganization.carrier.dotNumber);
            }
            if (this.senderOrganization && this.senderOrganization.broker) {
                identifiers.push(this.senderOrganization.broker.dotNumber);
            }
            return identifiers.filter(function (i) {
                return (i !== '' && i !== null);
            }).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invitation.prototype, "recipientIndentifiers", {
        get: function () {
            var identifiers = [];
            if (this.recipientOrganization && this.recipientOrganization.carrier) {
                identifiers.push(this.recipientOrganization.carrier.dotNumber);
            }
            if (this.recipientOrganization && this.recipientOrganization.broker) {
                identifiers.push(this.recipientOrganization.broker.dotNumber);
            }
            return identifiers.filter(function (i) {
                return (i !== '' && i !== null);
            }).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invitation.prototype, "senderOrganizationTypes", {
        get: function () {
            var values = [];
            if (this.senderOrganization) {
                if (this.senderOrganization.carrier && this.senderOrganization.carrier.id) {
                    values.push('Carrier');
                }
                if (this.senderOrganization.contractor && this.senderOrganization.contractor.id) {
                    values.push('Contractor');
                }
                if (this.senderOrganization.broker && this.senderOrganization.broker.id) {
                    values.push('Broker');
                }
            }
            return values.filter(function (v) {
                return (v !== '' && v !== null);
            }).join(' | ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invitation.prototype, "recipientOrganizationTypes", {
        get: function () {
            var values = [];
            if (this.recipientOrganization) {
                if (this.recipientOrganization.carrier && this.recipientOrganization.carrier.id) {
                    values.push('Carrier');
                }
                if (this.recipientOrganization.contractor && this.recipientOrganization.contractor.id) {
                    values.push('Contractor');
                }
                if (this.recipientOrganization.broker && this.recipientOrganization.broker.id) {
                    values.push('Broker');
                }
            }
            return values.filter(function (v) {
                return (v !== '' && v !== null);
            }).join(' | ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invitation.prototype, "senderLocation", {
        get: function () {
            var value = [];
            if (this.senderOrganization) {
                value = [
                    this.senderOrganization.city, this.senderOrganization.state
                ];
            }
            return value.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invitation.prototype, "recipientLocation", {
        get: function () {
            var value = [];
            if (this.recipientOrganization) {
                value = [
                    this.recipientOrganization.city, this.recipientOrganization.state
                ];
            }
            return value.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    return Invitation;
}());
exports.Invitation = Invitation;
