"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var http_1 = require("@angular/common/http");
var core_2 = require("@ngx-translate/core");
var job_event_share_service_1 = require("../job-event-shares/job-event-share.service");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var user_service_1 = require("../users/user.service");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var column_toggle_component_1 = require("../shared/column-toggle/column-toggle.component");
var applied_filters_component_1 = require("../shared/applied-filters/applied-filters.component");
var filters_panel_component_1 = require("../shared/filters-panel/filters-panel.component");
var job_service_1 = require("../jobs/job.service");
var location_service_1 = require("../locations/location.service");
var organization_service_1 = require("../organizations/organization.service");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var WorkOrdersComponent = /** @class */ (function () {
    function WorkOrdersComponent(dialog, jobEventShareService, translationService) {
        this.dialog = dialog;
        this.jobEventShareService = jobEventShareService;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: this.translationService.instant('Job Name'), sortable: true, sortBy: 'jobevent__job__name' },
            { key: 'organization', title: this.translationService.instant('Organization Name'), sortable: true, sortBy: 'organization__name' },
            { key: 'startDate', title: this.translationService.instant('Start Date'), sortable: true, sortBy: 'jobevent__shift1_start' },
            { key: 'startTime', title: this.translationService.instant('Start Time'), sortable: false },
            { key: 'endDate', title: this.translationService.instant('End Date'), sortable: true, sortBy: 'jobevent__shift1_end' },
            { key: 'endTime', title: this.translationService.instant('End Time'), sortable: false },
            { key: 'dispatched', title: this.translationService.instant('Dispatched Trucks') },
            { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
            { key: 'num-trucks', title: this.translationService.instant('Requested Trucks') },
            { key: 'job-number', title: this.translationService.instant('Job Number') },
            { key: 'job-order-number', title: this.translationService.instant('Order Number') },
            { key: 'haul-rate-unit', title: this.translationService.instant('Haul Rate Unit') },
            { key: 'workOrderSigned', title: this.translationService.instant('Signing Status'), sortable: true, sortBy: 'work_order_signed' },
            { key: 'workOrderSignedBy', title: this.translationService.instant('Signed By'), sortable: true, sortBy: 'work_order_signed_by_name' },
            { key: 'actions', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'select', 'name', 'job-number', 'job-order-number', 'organization',
            'startDate', 'startTime', 'endDate', 'endTime', 'dispatched',
            'confirmed-trucks', 'num-trucks', 'haul-rate-unit', 'workOrderSigned',
            'workOrderSignedBy', 'actions'
        ];
        this.availableFilters = [
            new filter_option_1.FilterOption({
                key: 'start__gte', title: this.translationService.instant('Start Date'),
                filterType: 'date'
            }),
            new filter_option_1.FilterOption({
                key: 'end__lte', title: this.translationService.instant('End Date'),
                filterType: 'date'
            }),
            new filter_option_1.FilterOption({
                key: 'work_order_signed_by',
                title: this.translationService.instant('Work Order Signed By'),
                filterType: 'select', service: user_service_1.UserService, ordering: 'first_name,last_name'
            }),
            new filter_option_1.FilterOption({
                key: 'jobevent__job', title: this.translationService.instant('Job'),
                filterType: 'select', service: job_service_1.JobService
            }),
            new filter_option_1.FilterOption({
                key: 'jobevent__job__start_location',
                title: this.translationService.instant('Start Location'),
                filterType: 'select', service: location_service_1.LocationService
            }),
            new filter_option_1.FilterOption({
                key: 'jobevent__job__end_location',
                title: this.translationService.instant('End Location'),
                filterType: 'select', service: location_service_1.LocationService
            }),
            new filter_option_1.FilterOption({
                key: 'organization', filterType: 'select', service: organization_service_1.OrganizationService,
                title: this.translationService.instant('Organization')
            }),
            new filter_option_1.FilterOption({
                key: 'work_order_signed', filterType: 'checkbox',
                title: this.translationService.instant('Work Order Signed'),
            }),
        ];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.workOrdersTableConfig = {
            hasHeader: true,
            service: job_event_share_service_1.JobEventShareService,
            preferenceKey: 'WorkOrdersComponent-JobEventShareService',
            query: {},
            collectionTitle: this.translationService.instant('Work Orders'),
            noResultsText: this.translationService.instant('a work order'),
            hasNoResultsAction: false,
            sortBy: 'name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('View HTML'), action: 'html', link: true, external: true },
                { name: this.translationService.instant('View PDF'), action: 'pdf', link: true, external: true }
            ]
        };
        this.multipleActionDropdownOptions = [
            { name: 'Export', button: true }
        ];
    }
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    WorkOrdersComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    WorkOrdersComponent.prototype.filtersModified = function (appliedFilters) {
        if (this.appliedFiltersDisplay) {
            this.appliedFiltersDisplay.ngOnInit();
        }
        this.appliedFilters = appliedFilters;
    };
    WorkOrdersComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.appliedFilters, filter);
        this.filtersPanelDisplay.resetFilters();
        this.workOrdersTable.getRecords();
    };
    WorkOrdersComponent.prototype.multiselectDisabled = function () {
        return !this.workOrdersTable || !this.workOrdersTable.count ||
            this.workOrdersTable.count === 0 ||
            (!this.workOrdersTable.allSelected && !this.workOrdersTable.selection.selected.length) ||
            this.workOrdersTable.count === this.workOrdersTable.exclusion.selected.length;
    };
    WorkOrdersComponent.prototype.setSelectedAction = function (option) {
        switch (option.action) {
            case 'export':
                this.createExport();
                break;
        }
    };
    WorkOrdersComponent.prototype.createExport = function () {
        var _this = this;
        var selection = [];
        var exclusion = [];
        if (this.workOrdersTable) {
            selection = this.workOrdersTable.selection.selected.map(function (record) { return record.id; });
            exclusion = this.workOrdersTable.exclusion.selected.map(function (record) { return record.id; });
        }
        var scope = { include: selection, exclude: exclusion };
        var filters = this.mergeFilters(this.appliedFilters);
        var query = { search: this.search, filters: filters };
        this.jobEventShareService.export(scope, query).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    scope: scope,
                    params: params,
                    service: _this.jobEventShareService,
                    buttonText: _this.translationService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    WorkOrdersComponent.prototype.mergeFilters = function (filters) {
        return filters.map(function (filter) {
            if (filter.multiple && filter.values) {
                return filter.values.map(function (value) {
                    var _value = [filter.key, value].join('=');
                    return "(" + _value + ")";
                }).filter(Boolean).join('|');
            }
            else if (filter.values) {
                var values = filter.values;
                if (values === true) {
                    values = 'True';
                }
                if (values === false) {
                    values = 'False';
                }
                var _value = [filter.key, values].join('=');
                return "(" + _value + ")";
            }
        }).filter(Boolean).join('&');
    };
    return WorkOrdersComponent;
}());
exports.WorkOrdersComponent = WorkOrdersComponent;
