import { TripDecision } from './trip-decision';
import { Organization } from '../organizations/organization';
import { Trip } from './trip';
import { UserSerializer } from '../users/user.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TripDecisionSerializer {
  /**
  * @param  {any} json
  * @returns TripDecision
  */
  fromJson(json: any): TripDecision {
    json = camelcaseKeysDeep(json);
    const tripDecision = new TripDecision();
    if (!json) { return tripDecision; }

    tripDecision.id = json.id;
    tripDecision.decidedAt = json.decidedAt;
    tripDecision.decisionStatus = json.decisionStatus;
    tripDecision.exportedAt = json.exportedAt;
    tripDecision.notes = json.notes;
    tripDecision.loading = json.loading;

    if (json.trip && typeof (json.trip) === 'object') {
      tripDecision.trip = new Trip({
        ...json.trip,
        exportedAt: json.exportedAt
      });
    } else if (json.tripDecision) {
      tripDecision.trip = new Trip({
        id: json.trip,
        exportedAt: json.exportedAt
      });
    }
    if (json.organization && typeof (json.organization) === 'object') {
      tripDecision.organization = new Organization(json.organization);
    } else if (json.organization) {
      tripDecision.organization = new Organization({
        id: json.organization
      });
    }
    if (json.decidedBy && typeof (json.decidedBy) === 'object') {
      tripDecision.decidedBy = new UserSerializer().fromJson(json.decidedBy);
    } else if (json.decidedBy) {
      tripDecision.decidedBy = new UserSerializer().fromJson({
        id: json.decidedBy
      });
    }

    return tripDecision;
  }

  /**
  * @param  {TripDecision} tripDecision
  * @returns any
  */
  toJson(tripDecision: TripDecision): any {
    let json = {
      id: tripDecision.id,
      decidedAt: tripDecision.decidedAt,
      decisionStatus: tripDecision.decisionStatus || 'pending',
      exportedAt: tripDecision.exportedAt,
      notes: tripDecision.notes,
      trip: tripDecision.trip && tripDecision.trip.id,
      organization: tripDecision.organization && tripDecision.organization.id,
      decidedBy: tripDecision.decidedBy && tripDecision.decidedBy.id,
    };

    return decamelizeKeysDeep(json);
  }
}
