import { Plant } from './plant';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PlantSerializer {
  fromJson(plantInfo: any): Plant {
    plantInfo = camelcaseKeysDeep(plantInfo);
    const plant = new Plant();
    if (!plantInfo) { return plant; }

    plant.id = plantInfo.id;

    return plant;
  }

  toJson(plant: Plant): any {
    let json = {
      id: plant.id,
      selected: plant.selected
    };

    return decamelizeKeysDeep(json);
  }
}
