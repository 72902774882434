"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment-timezone");
var driver_1 = require("./driver");
var truck_serializer_1 = require("../trucks/truck.serializer");
var profile_1 = require("./profile");
var conflict_1 = require("./conflict");
var carrier_1 = require("../carriers/carrier");
var trip_1 = require("../trips/trip");
var device_status_event_serializer_1 = require("../shared/device-status-event.serializer");
var assignment_serializer_1 = require("../assignments/assignment.serializer");
var job_event_share_serializer_1 = require("../job-event-shares/job-event-share.serializer");
var punch_card_1 = require("../punch-cards/punch-card");
var organization_1 = require("../organizations/organization");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DriverSerializer = /** @class */ (function () {
    function DriverSerializer() {
    }
    /**
    * @param  {any} json
    * @returns {Driver} Driver
    */
    DriverSerializer.prototype.fromJson = function (json) {
        var _this = this;
        json = camelcaseKeysDeep(json);
        var driver = new driver_1.Driver();
        if (!json) {
            return driver;
        }
        driver.id = json.id;
        driver.busy = json.busy;
        driver.customFieldData = json.customFieldData;
        driver.image = json.image;
        driver.imageKey = json.imageKey;
        driver.lastStatus = json.lastStatus;
        driver.username = json.username;
        if (json.user) {
            driver.user = json.user;
        }
        else {
            driver.user = { username: driver.username, password: '' };
        }
        if (json.truck && typeof (json.truck) === 'object') {
            driver.truck = new truck_serializer_1.TruckSerializer().fromJson(json.truck);
        }
        else if (json.truck) {
            driver.truck = new truck_serializer_1.TruckSerializer().fromJson({ id: json.truck });
        }
        if (json.trip && typeof (json.trip) === 'object') {
            driver.trip = new trip_1.Trip(json.truck);
        }
        else if (json.trip) {
            driver.trip = new trip_1.Trip({ id: json.trip });
        }
        if (json.trucks) {
            driver.trucks = json.trucks.map(function (t) { return new truck_serializer_1.TruckSerializer().fromJson(t); });
        }
        if (json.profile && typeof (json.profile) === 'object') {
            driver.profile = new profile_1.Profile(json.profile);
        }
        else {
            driver.profile = json.profile;
        }
        if (driver.profile && driver.profile.firstName && driver.profile.lastName) {
            driver.initials = driver.profile.firstName[0] + driver.profile.lastName[0];
        }
        driver.city = json.city;
        driver.state = json.state;
        driver.country = json.country || 'US';
        driver.street = json.street;
        driver.zipcode = json.zipcode;
        driver.cdl = json.cdl;
        if (json.carrier && typeof (json.carrier) === 'object') {
            driver.carrier = new carrier_1.Carrier(json.carrier);
        }
        if (json.carrierOrganizationId) {
            driver.carrierOrganizationId = json.carrierOrganizationId;
        }
        else if (json.carrier) {
            driver.carrierOrganizationId = json.carrier.id;
        }
        else {
            driver.carrierOrganizationId = '';
        }
        if (json.carrierId) {
            driver.carrierId = json.carrierId;
        }
        else if (json.carrier) {
            driver.carrierId = json.carrier.id;
        }
        else {
            driver.carrierId = '';
        }
        if (json.carrierOrganizationName) {
            driver.carrierOrganizationName = json.carrierOrganizationName;
        }
        else if (json.carrier) {
            driver.carrierOrganizationName = json.carrier.name;
        }
        else {
            driver.carrierOrganizationName = '';
        }
        if (json.carrierOrganization && typeof (json.carrierOrganization) === 'object') {
            driver.carrierOrganization = new organization_1.Organization(json.carrierOrganization);
        }
        else if (driver.carrierOrganizationId && driver.carrierOrganizationName) {
            driver.carrierOrganization = new organization_1.Organization({
                id: driver.carrierOrganizationId,
                name: driver.carrierOrganizationName
            });
        }
        driver.carrierName = json.carrierName;
        driver.customName = json.customName;
        if (driver.profile) {
            driver.name = driver.profile.firstName + ' ' + driver.profile.lastName;
        }
        else {
            driver.name = driver.customName;
        }
        driver.truckName = driver.truck && driver.truck.name;
        driver.phoneNumber = json.phoneNumber;
        driver.activeShift = json.activeShift;
        driver.activeShifts = json.activeShifts;
        if (json.conflicts) {
            driver.conflicts = json.conflicts.map(function (c) { return new conflict_1.Conflict(c); });
            driver.conflictIds = driver.conflicts.map(function (c) { return c.jobevent; });
        }
        driver.requireAssignmentConfirmation = json.requireAssignmentConfirmation;
        driver.tags = json.tags;
        driver.uniqueBillingId = json.uniqueBillingId;
        driver.status = json.status;
        driver.dutyStatus = json.dutyStatus;
        driver.displayDutyStatus = driver.dutyStatus &&
            driver.dutyStatus[0].toUpperCase() + driver.dutyStatus.replace('-', ' ').substr(1).toLowerCase();
        driver.displayShiftStatus = json.displayShiftStatus;
        driver.lastShiftId = json.lastShiftId;
        driver.deviceErrors = json.deviceErrors || [];
        if (json.lastDeviceStatusEvent && typeof (json.lastDeviceStatusEvent) === 'object' && json.lastDeviceStatusEvent.id) {
            driver.lastDeviceStatusEvent = new device_status_event_serializer_1.DeviceStatusEventSerializer().fromJson(json.lastDeviceStatusEvent);
            if (driver.lastDeviceStatusEvent.lowPowerMode === true) {
                driver.deviceErrors.push('Low Power Mode');
            }
            if (driver.lastDeviceStatusEvent.locationAuthorization !== 'Always') {
                driver.deviceErrors.push('Location Authorization');
            }
            if (driver.lastDeviceStatusEvent.backgroundAppRefresh !== true) {
                driver.deviceErrors.push('Background App Refresh');
            }
        }
        else {
            driver.lastDeviceStatusEvent = json.lastDeviceStatusEvent;
        }
        driver.truckDriverDisplayName = (driver.dutyStatus === 'on-duty' ?
            driver.truckName ? driver.truckName : 'No Truck Assigned' :
            'Off Duty') + ' - ' + driver.name;
        driver.lastModified = json.lastModified;
        driver.lastModifiedBy = json.lastModifiedBy;
        driver.trips = json.trips && json.trips.map(function (trip) {
            if (trip && typeof (trip) === 'object') {
                return new trip_1.Trip(trip);
            }
            else {
                return new trip_1.Trip({ id: trip });
            }
        }) || [];
        if (json.latestTruck && typeof (json.latestTruck) === 'object') {
            driver.latestTruck = new truck_serializer_1.TruckSerializer().fromJson(json.latestTruck);
        }
        else {
            driver.latestTruck = json.latestTruck;
        }
        driver.loadCount = json.loadCount;
        driver.invoiceTotal = lodash_1.round(lodash_1.sum(driver.trips.map(function (trip) {
            var invoiceRate = parseFloat(trip.jobEventShare.invoiceRate);
            var invoiceUnit = trip.jobEventShare.invoiceWeightUnit;
            if (invoiceUnit === 'load') {
                return invoiceRate;
            }
            else if (invoiceUnit === 'ton') {
                return (invoiceRate * parseFloat(trip.weight));
            }
            else if (invoiceUnit === 'hour') {
                return invoiceRate * (trip.completedTripDuration ? (trip.completedTripDuration / 3600000) : 0);
            }
            else {
                return (invoiceRate * parseFloat(trip.weight));
            }
        })), 2);
        driver.expenseTotal = lodash_1.round(lodash_1.sum(driver.trips.map(function (trip) {
            var haulRate = parseFloat(trip.jobEventShare.haulRate);
            var haulUnit = trip.jobEventShare.haulWeightUnit;
            if (haulUnit === 'load') {
                return haulRate;
            }
            else if (haulUnit === 'ton') {
                return (haulRate * parseFloat(trip.weight));
            }
            else if (haulUnit === 'hour') {
                return haulRate * (trip.completedTripDuration ? (trip.completedTripDuration / 3600000) : 0);
            }
            else {
                return (haulRate * parseFloat(trip.weight));
            }
        })), 2);
        driver.timeTotal = moment.duration(lodash_1.sum(driver.trips.filter(function (trip) {
            return !trip.void;
        }).map(function (trip) {
            return (trip.completedTripDurationRounded ? trip.completedTripDurationRounded : 0);
        })), 'milliseconds').format('D[ days], H[ hrs], m[ mins]');
        driver.assignments = json.assignments && json.assignments.map(function (assignment) {
            if (assignment && typeof (assignment) === 'object') {
                return new assignment_serializer_1.AssignmentSerializer().fromJson(assignment);
            }
            else {
                return new assignment_serializer_1.AssignmentSerializer().fromJson({ id: assignment });
            }
        }) || [];
        driver.assignmentsCount = driver.assignments && driver.assignments.length;
        driver.punchCards = json.punchcards && json.punchcards.map(function (punchcard) {
            if (punchcard && typeof (punchcard) === 'object') {
                return new punch_card_1.PunchCard(punchcard);
            }
            else {
                return new punch_card_1.PunchCard({ id: punchcard });
            }
        }) || [];
        if (driver.trips) {
            driver.trips.forEach(function (trip) { return driver.jobEventShares.push(trip.jobEventShare); });
        }
        if (json.jobEventShares) {
            json.jobEventShares.forEach(function (jobEventShare) {
                if (jobEventShare.organizationId === driver.carrierOrganizationId) {
                    driver.jobEventShares.push(jobEventShare);
                }
            });
        }
        if (driver.jobEventShares) {
            driver.jobEventShares = driver.jobEventShares.concat(driver.jobEventShares.map(function (jobEventShare) {
                if (jobEventShare && typeof (jobEventShare) === 'object') {
                    return new job_event_share_serializer_1.JobEventShareSerializer().fromJson(jobEventShare);
                }
                else {
                    return new job_event_share_serializer_1.JobEventShareSerializer().fromJson({ id: jobEventShare });
                }
            }));
            driver.jobEventShares = lodash_1.uniqBy(driver.jobEventShares, function (e) {
                return e.id;
            });
        }
        driver.allDecisionsApproved = json.allDecisionsApproved;
        driver.allTripsApproved = json.allTripsApproved || [];
        if (json.completedTripsCount) {
            driver.completedTripsCount = json.completedTripsCount;
        }
        else {
            driver.completedTripsCount = lodash_1.sumBy(driver.trips, function (trip) { return trip.completed && !trip.void ? 1 : 0; });
        }
        driver.jobNames = lodash_1.uniq(driver.trips.map(function (trip) { return trip.jobName; }).concat(driver.assignments.map(function (assignment) { return assignment.jobDisplayName; }), driver.punchCards.map(function (punchCard) {
            if (punchCard.jobEvent && punchCard.jobEvent.jobDisplayName) {
                return punchCard.jobEvent.jobDisplayName;
            }
            else if (punchCard.job && punchCard.job.name) {
                return punchCard.job.name;
            }
        }))).filter(Boolean);
        driver.truckContainerId = json.truckContainerId;
        driver.geoTrips = json.geoTrips || [];
        driver.geoTrips.map(function (geoTrip) {
            if (geoTrip.startTime && geoTrip.endTime) {
                var tripTimesDifference = moment(geoTrip.endTime).diff(moment(geoTrip.startTime), 'minutes');
                geoTrip.tripDuration = Math.floor(tripTimesDifference / 60) + 'h ' + (tripTimesDifference % 60) + 'm';
            }
            geoTrip.geoStatus = _this.getGeoStatus(geoTrip.tripStatus);
        });
        return driver;
    };
    /**
    * @param  {Driver} driver
    * @returns any
    */
    DriverSerializer.prototype.toJson = function (driver) {
        var json = {
            id: driver.id,
            profile: driver.profile,
            name: driver.name,
            user: driver.user,
            image: driver.image,
            imageKey: driver.imageKey,
            lastStatus: driver.lastStatus,
            truck: driver.truck && driver.truck.id,
            trucks: driver.trucks,
            city: driver.city,
            state: driver.state,
            country: driver.country,
            street: driver.street,
            zipcode: driver.zipcode,
            activeShift: driver.activeShift,
            activeShifts: driver.activeShifts,
            cdl: driver.cdl,
            trip: driver.trip,
            punchCard: driver.punchCard,
            carrier: driver.carrier,
            conflicts: driver.conflicts,
            busy: driver.busy,
            username: driver.username,
            requireAssignmentConfirmation: driver.requireAssignmentConfirmation,
            initials: driver.initials,
            carrierName: driver.carrierName,
            carrierOrganizationName: driver.carrierOrganizationName,
            customName: driver.customName,
            phoneNumber: driver.phoneNumber,
            tags: driver.tags,
            uniqueBillingId: driver.uniqueBillingId === '' ? null : driver.uniqueBillingId,
            customFieldData: driver.customFieldData,
            status: driver.status,
            dutyStatus: driver.dutyStatus,
            truckContainerId: driver.truckContainerId
        };
        return decamelizeKeysDeep(json);
    };
    DriverSerializer.prototype.getGeoStatus = function (status) {
        var geoStatuses = {
            default: 'Waiting',
            enroute_loading: 'Enroute Loading',
            loading: 'Loading',
            enroute_unloading: 'Enroute Unloading',
            unloading: 'Unloading',
            paused: 'Paused',
            loading_complete: 'Loading Complete',
            unloading_complete: 'Unloading Complete',
            waiting_to_load: 'Waiting to Load',
            active_loading: 'Active Loading',
            waiting_to_unload: 'Waiting to Unload',
            active_unloading: 'Active Unloading'
        };
        return geoStatuses[status] || geoStatuses.default;
    };
    return DriverSerializer;
}());
exports.DriverSerializer = DriverSerializer;
