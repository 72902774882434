"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var map_1 = require("@ngui/map");
var rxjs_1 = require("rxjs");
var ngx_device_detector_1 = require("ngx-device-detector");
var location_service_1 = require("./location.service");
var authentication_service_1 = require("./../shared/authentication.service");
var api_service_1 = require("../shared/api.service");
var static_data_1 = require("../shared/static-data");
var location_owner_service_1 = require("./location-owner.service");
var location_1 = require("./location");
var location_serializer_1 = require("./location.serializer");
var location_type_service_1 = require("./location-type.service");
var shared_1 = require("../shared");
var address_1 = require("./address");
var location_owner_serializer_1 = require("./location-owner.serializer");
var EditLocationComponent = /** @class */ (function () {
    function EditLocationComponent(route, router, locationService, apiService, authenticationService, locationOwnerService, deviceDetectorService) {
        this.route = route;
        this.router = router;
        this.locationService = locationService;
        this.apiService = apiService;
        this.authenticationService = authenticationService;
        this.locationOwnerService = locationOwnerService;
        this.deviceDetectorService = deviceDetectorService;
        this.loading = false;
        this.showLocationOwnerTextField = false;
        this.location = new location_serializer_1.LocationSerializer().fromJson({
            latitude: 29.9291564, longitude: -94.9320884, geofence: null
        });
        this.errors = [];
        this.states = [];
        this.stateOptions = [];
        this.countryOptions = [];
        this.ownerOptions = [];
        this.ownersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.zoom = 8;
        this.drawingMode = undefined;
        this.paths = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.canGeolocate = false;
        this.address = new address_1.Address();
        this.selectedAddress = new address_1.Address();
        this.addresses = [];
        this.mapOptions = {};
        this.qrEnabled = false;
        this.laserFicheEnabled = false;
        this.markerLocation = new location_1.Location();
        this.markerMoved = false;
        this.isGeoFenceAvailable = false;
        this.locationTypeDropdownConfig = {
            service: location_type_service_1.LocationTypeService,
            selectText: 'Select Location Type',
            loadingText: 'Loading Location Types...',
            noResultsText: 'No Location Types'
        };
        this.allSubscriptionsToUnsubscribe = [];
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        if (navigator && navigator.geolocation) {
            this.canGeolocate = true;
        }
    }
    EditLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.states = this.apiService.getStates();
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name
            };
        });
        if (this.location && this.location.country) {
            this.selectCountry(this.location.country);
        }
        else {
            this.selectCountry('US');
        }
        var user = this.authenticationService.user();
        this.qrEnabled = user && user.organization && user.organization.qrEnabled;
        var enabledFeatures = this.authenticationService.enabledFeatures();
        if (enabledFeatures && enabledFeatures.includes('useLaserficheExport')) {
            this.laserFicheEnabled = true;
        }
        this.getLocationOwners();
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.returnTo = result.qparams['returnTo'];
            if (result && result.params['id']) {
                _this.getLocation(result.params['id']);
            }
            else {
                _this.router.navigate([_this.returnTo]);
            }
        }));
    };
    EditLocationComponent.prototype.ngOnDestroy = function () {
        if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
            this.locationOwnersReq.unsubscribe();
        }
        if (this.locationReq && typeof this.locationReq.unsubscribe === 'function') {
            this.locationReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    EditLocationComponent.prototype.getLocation = function (id) {
        var _this = this;
        if (this.locationReq && typeof this.locationReq.unsubscribe === 'function') {
            this.locationReq.unsubscribe();
        }
        if (id) {
            this.loading = true;
            this.locationReq = this.locationService.get(id).subscribe(function (location) {
                _this.location = location;
                _this.selectCountry(_this.location.country);
                _this.selectState(_this.location.state);
                if (_this.location.geofence) {
                    _this.isGeoFenceAvailable = true;
                    _this.paths = _this.location.paths || [];
                    if (_this.map) {
                        _this.initDrawingManager();
                    }
                }
                var options = {
                    scrollwheel: true,
                    mapTypeId: 'hybrid',
                    zoom: 17,
                    mapTypeControlOptions: {
                        mapTypeIds: ['roadmap', 'hybrid']
                    }
                };
                if (_this.location && _this.location.latitude && _this.location.longitude) {
                    _this.marker = {
                        lat: _this.location.latitude,
                        lon: _this.location.longitude,
                        mappable: _this.location.mappable
                    };
                    options['center'] = {
                        lat: parseFloat(_this.location.latitude),
                        lng: parseFloat(_this.location.longitude)
                    };
                }
                _this.mapOptions = static_data_1.mapOptions({
                    zoom: 15,
                    disableDefaultUI: true,
                    scrollwheel: true,
                    fullscreenControl: false,
                    streetViewControl: false
                }, {}, {
                    mapStyle: 'google-map-style'
                });
                _this.loading = false;
            }, function (err) {
                _this.errors = err;
                _this.loading = false;
            });
        }
    };
    EditLocationComponent.prototype.getLocationOwners = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.locationOwnersReq && typeof this.locationOwnersReq.unsubscribe === 'function') {
            this.locationOwnersReq.unsubscribe();
        }
        this.locationOwnersReq = this.locationOwnerService.list(__assign({ ordering: 'name' }, query)).subscribe(function (locationOwners) {
            _this.ownerOptions = locationOwners.map(function (locationOwner) {
                return { name: locationOwner.name, id: locationOwner.id };
            });
            var ownOrganization = _this.authenticationService.getOrganization();
            if (ownOrganization) {
                var _locationOwner = new location_owner_serializer_1.LocationOwnerSerializer().fromJson({
                    organization: ownOrganization.id,
                    name: ownOrganization.name
                });
                _this.ownerOptions = lodash_1.reject(_this.ownerOptions, _locationOwner);
                _this.ownerOptions.unshift(_locationOwner);
            }
            if (_this.location.locationOwner && _this.location.locationOwner.id) {
                _this.ownerOptions = lodash_1.reject(_this.ownerOptions, _this.location.locationOwner);
                _this.ownerOptions.unshift(_this.location.locationOwner);
                _this.ownersDropdown.selectedOption = lodash_1.find(_this.ownerOptions, { id: _this.location.locationOwner.id });
            }
            _this.ownerOptions.unshift({
                name: 'Add a New Owner',
                id: 'new-location-owner',
                button: true
            });
            if (_this.ownersDropdown) {
                _this.ownersDropdown.config.loadingOptions = false;
            }
            _this.ownersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.ownersConfig.loadingOptions = false;
        });
    };
    EditLocationComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'owner':
                config = this.ownersConfig;
                service = this.locationOwnerService;
                options = this.ownerOptions;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'owner':
                            var _ownerOptions = results.map(function (result) {
                                return { name: result.name, id: result.id };
                            });
                            _this.ownerOptions = _this.ownerOptions.concat(_ownerOptions);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    EditLocationComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'owner':
                this.getLocationOwners({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    EditLocationComponent.prototype.initMap = function (event) {
        this.map = event;
        var latLng;
        if (this.map) {
            var bounds_1 = new google.maps.LatLngBounds();
            if (this.location && this.location.latitude && this.location.longitude) {
                latLng = new google.maps.LatLng(parseFloat(this.location.latitude), parseFloat(this.location.longitude));
                bounds_1.extend(latLng);
            }
            if (this.location.paths) {
                this.location.paths.forEach(function (path) {
                    if (path.lat && path.lng) {
                        bounds_1.extend(new google.maps.LatLng(parseFloat(path.lat), parseFloat(path.lng)));
                    }
                });
            }
            else if (typeof this.map.setCenter === 'function' && latLng) {
                this.map.setCenter(latLng);
            }
            if (typeof this.map.fitBounds === 'function') {
                this.map.fitBounds(bounds_1);
            }
            if (!this.location.paths) {
                var currentZoom = this.map.getZoom();
                this.map.setZoom(currentZoom - 3);
            }
            this.initDrawingManager();
        }
    };
    EditLocationComponent.prototype.initDrawingManager = function () {
        var _this = this;
        var paths = (this.paths && this.paths.length) ? this.paths : this.location.paths;
        if (this.location && this.location.id && paths) {
            this.polygon = new google.maps.Polygon({
                paths: paths,
                strokeColor: '#FF8C00',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF8C00',
                fillOpacity: 0.35,
                editable: true,
                draggable: true
            });
            if (this.map) {
                this.polygon.setMap(this.map);
            }
        }
        if (this.drawingManager) {
            this.allSubscriptionsToUnsubscribe.push(this.drawingManager['initialized$'].subscribe(function (dm) {
                if (!_this.polygon) {
                    _this.polygon = new google.maps.Polygon({
                        paths: paths || [],
                        strokeColor: '#FF8C00',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF8C00',
                        fillOpacity: 0.35,
                        editable: true,
                        draggable: true
                    });
                    if (dm && dm.map) {
                        _this.polygon.setMap(dm.map);
                    }
                }
                google.maps.event.addListener(_this.polygon, 'insert_at', function (event) {
                    var pathArray = _this.polygon.getPath().getArray();
                    _this.updatePaths(pathArray);
                });
                google.maps.event.addListener(_this.polygon, 'set_at', function (event) {
                    var pathArray = _this.polygon.getPath().getArray();
                    _this.updatePaths(pathArray);
                });
                google.maps.event.addListener(_this.polygon, 'dragend', function (event) {
                    var pathArray = _this.polygon.getPath().getArray();
                    _this.updatePaths(pathArray);
                });
                google.maps.event.addListener(_this.polygon, 'rightclick', function (event) {
                    _this.deletePolygon();
                });
                google.maps.event.addListener(dm, 'overlaycomplete', function (event) {
                    dm.map.data.setStyle({
                        editable: true,
                        draggable: true
                    });
                    if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                        _this.drawingComplete(event.overlay);
                        dm.setDrawingMode(null);
                    }
                });
            }));
        }
    };
    EditLocationComponent.prototype.submit = function () {
        var _this = this;
        var coordinates = null;
        if (this.polygon) {
            var polygonPath = this.polygon.getPath();
            if (polygonPath) {
                var pathArray = polygonPath.getArray();
                if (pathArray) {
                    coordinates = pathArray.map(function (path) {
                        return [path.lng(), path.lat()];
                    });
                    coordinates = lodash_1.uniqWith(coordinates, lodash_1.isEqual);
                    if (coordinates && coordinates[0] !== coordinates.reverse[0]) {
                        coordinates.push(coordinates[0]);
                    }
                }
            }
        }
        this.loading = true;
        if (this.location.geofence && this.location.geofence instanceof Array) {
            var geofence = this.location.geofence[0];
            if (geofence && geofence.geometry) {
                this.location.geofence = geofence.geometry;
            }
        }
        else if (coordinates) {
            this.location.geofence = {
                type: 'Polygon',
                coordinates: [coordinates]
            };
        }
        if (this.location.tags && this.location.tags.length) {
            this.location.tags = this.location.tags.map(function (t) { return (typeof t === 'string' ? t : t.name); });
        }
        this.locationService.save(this.location).subscribe(function (res) {
            _this.loading = false;
            if (_this.returnTo.includes('?')) {
                var queryParams_1 = {};
                var splitUrl = _this.returnTo.split(/[\\?&]+/);
                splitUrl.forEach(function (urlPart) {
                    if (urlPart.includes('=')) {
                        queryParams_1[urlPart.split('=')[0]] = urlPart.split('=')[1];
                    }
                });
                _this.router.navigate([splitUrl[0].replace('/', '')], { queryParams: queryParams_1 });
            }
            else {
                _this.router.navigate([_this.returnTo]);
            }
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditLocationComponent.prototype.cancel = function () {
        this.loading = false;
        if (this.returnTo.includes('?')) {
            var queryParams_2 = {};
            var splitUrl = this.returnTo.split(/[\\?&]+/);
            splitUrl.forEach(function (urlPart) {
                if (urlPart.includes('=')) {
                    queryParams_2[urlPart.split('=')[0]] = urlPart.split('=')[1];
                }
            });
            this.router.navigate([splitUrl[0].replace('/', '')], { queryParams: queryParams_2 });
        }
        else {
            this.router.navigate([this.returnTo]);
        }
    };
    EditLocationComponent.prototype.placeChanged = function (place) {
        var _this = this;
        // set latitude, longitude and zoom
        if (place && place.geometry) {
            this.location.latitude = place.geometry.location.lat();
            this.location.longitude = place.geometry.location.lng();
        }
        this.location.marker = {
            latitude: this.location.latitude, longitude: this.location.longitude
        };
        if (this.location.latitude && this.location.longitude) {
            this.marker = null;
            this.marker = {
                latLng: new google.maps.LatLng(parseFloat(this.location.latitude), parseFloat(this.location.longitude)),
                mappable: true
            };
        }
        this.zoom = 14;
        this.address.street = null;
        this.location.city = this.extractFromAddress(place.address_components, 'locality');
        this.countryOptions.map(function (country) {
            if (country.name === _this.extractFromAddress(place.address_components, 'country')) {
                _this.selectCountry(country.id);
            }
        });
        this.states.map(function (state) {
            if (state.name === _this.extractFromAddress(place.address_components, 'administrative_area_level_1')) {
                _this.location.state = state.abbreviation;
            }
        });
        this.location.zipcode = this.extractFromAddress(place.address_components, 'postal_code');
        this.centerOnCoordinates({ latitude: this.location.latitude, longitude: this.location.longitude });
        if (!this.location.name || !this.location.name.length) {
            this.location.name = place.name;
        }
        this.location.street = this.extractFromAddress(place.address_components, 'street_number') + ' ' +
            this.extractFromAddress(place.address_components, 'route');
        if (this.location.street && this.location.street.trim().length === 0) {
            this.location.street = this.location.name;
        }
    };
    EditLocationComponent.prototype.centerOnCoordinates = function (coordinates) {
        var fallbackCenter = new google.maps.LatLng(coordinates.latitude, coordinates.longitude);
        this.mapOptions = static_data_1.mapOptions({
            zoom: 15,
            disableDefaultUI: true,
            scrollwheel: true,
            fullscreenControl: false,
            streetViewControl: false,
            center: fallbackCenter,
            geoFallbackCenter: fallbackCenter
        }, {}, {
            mapStyle: 'google-map-style'
        });
        navigator.geolocation.clearWatch(this.geolocationSub);
        this.geolocationSub = null;
        if (this.map) {
            google.maps.event.trigger(this.map, 'resize');
        }
    };
    EditLocationComponent.prototype.selectState = function (state) {
        this.location.state = state;
    };
    EditLocationComponent.prototype.selectCountry = function (country) {
        this.location.country = country;
        this.stateOptions = lodash_1.filter(this.states, { country: country }).map(function (state) {
            return {
                id: state.abbreviation,
                name: state.name
            };
        });
    };
    EditLocationComponent.prototype.selectOwner = function (owner) {
        if (owner['id'] === 'new-location-owner') {
            this.showLocationOwnerTextField = true;
            this.location.locationOwner.id = null;
        }
        else {
            this.location.locationOwner = owner;
        }
    };
    EditLocationComponent.prototype.markerDragEnd = function (event) {
        this.markerMoved = true;
        if (event.coords) {
            this.markerLocation.latitude = event.coords.lat;
            this.markerLocation.longitude = event.coords.lng;
        }
        else if (event.latLng) {
            this.markerLocation.latitude = event.latLng.lat();
            this.markerLocation.longitude = event.latLng.lng();
        }
    };
    EditLocationComponent.prototype.onPolyInit = function (polygon) {
        this.polygon = polygon;
    };
    EditLocationComponent.prototype.extractFromAddress = function (components, type, property) {
        if (property === void 0) { property = null; }
        for (var i = 0; i < components.length; i++) {
            for (var j = 0; j < components[i].types.length; j++) {
                if (components[i].types[j] === type) {
                    return property ? components[i][property] : components[i].long_name;
                }
            }
        }
        return '';
    };
    EditLocationComponent.prototype.geolocate = function () {
        var _this = this;
        var displayName = this.location.street + " " + this.location.city + ", " + this.location.state + " " + this.location.zipcode;
        if (this.location.street && this.location.street.trim().length > 0) {
            displayName = displayName.replace('undefined', '');
            displayName = displayName.replace(' ,', ',');
        }
        this.address = {
            street: this.location.street,
            street2: null,
            city: this.location.city,
            state: this.location.state,
            zipcode: this.location.zipcode,
            country: this.location.country,
            displayName: displayName
        };
        var status = Object.keys(this.address).every(function (x) {
            return _this.address[x] && _this.address[x].length;
        }) === true;
        var address = Object.keys(this.address).map(function (x) {
            return _this.address[x];
        }).join(', ');
        this.copyUpdatedAddress();
        if (status) {
            this.locationService.getLocationByAddress(address).subscribe(function (res) {
                var coordinates = res && res[0];
                _this.location.latitude = coordinates.geometry.location.lat;
                _this.location.longitude = coordinates.geometry.location.lng;
                _this.location.marker = {
                    latitude: _this.location.latitude, longitude: _this.location.longitude
                };
                _this.centerOnCoordinates({ latitude: _this.location.latitude, longitude: _this.location.longitude });
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
        else {
            this.centerOnCoordinates({ latitude: this.location.latitude, longitude: this.location.longitude });
        }
    };
    EditLocationComponent.prototype.onConfirmNewMarkerLocation = function () {
        this.markerMoved = false;
        this.location.latitude = this.markerLocation.latitude;
        this.location.longitude = this.markerLocation.longitude;
        this.reverseGeocode(this.markerLocation);
        this.copyUpdatedAddress();
    };
    EditLocationComponent.prototype.copyUpdatedAddress = function () {
        if (this.address && this.location && this.address.street !== this.location.street) {
            this.selectCountry(this.address.country);
            this.location.street = this.address.street;
            this.location.city = this.address.city;
            this.location.state = this.address.state;
            this.location.zipcode = this.address.zipcode;
            this.location.country = this.address.country;
        }
        this.address = new address_1.Address();
    };
    EditLocationComponent.prototype.reverseGeocode = function (coords) {
        var _this = this;
        if (coords && coords.latitude && coords.longitude) {
            var coordinates = [coords.latitude, coords.longitude];
            this.locationService.getAddressByCoordinates(coordinates).subscribe(function (res) {
                _this.addresses = _this.processAddresses(res);
                _this.selectedAddress = _this.addresses[0];
                _this.address = _this.addresses[0];
                if (!_this.location.street) {
                    _this.copyUpdatedAddress();
                }
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    EditLocationComponent.prototype.processAddresses = function (results) {
        var _this = this;
        var addresses = [];
        results.forEach(function (result) {
            var address = new address_1.Address();
            address.street = _this.extractFromAddress(result.address_components, 'street_number')
                + ' '
                + _this.extractFromAddress(result.address_components, 'route');
            address.city = _this.extractFromAddress(result.address_components, 'locality');
            _this.apiService.getStates().map(function (state) {
                if (state.name === _this.extractFromAddress(result.address_components, 'administrative_area_level_1') ||
                    state.abbreviation === _this.extractFromAddress(result.address_components, 'administrative_area_level_1', 'short_name')) {
                    address.state = state.abbreviation;
                }
            });
            address.zipcode = _this.extractFromAddress(result.address_components, 'postal_code');
            _this.countryOptions.map(function (country) {
                if (country.name === _this.extractFromAddress(result.address_components, 'country')) {
                    address.country = country.id;
                }
            });
            address.displayName = address.street + " " + address.street2 + ", " + address.city + ", " + address.state + " " + address.zipcode;
            if (address.street && address.street.trim().length > 0) {
                address.displayName = address.displayName.replace('undefined', '');
                address.displayName = address.displayName.replace(' ,', ',');
            }
            addresses.push(address);
        });
        addresses.sort(function (a, b) {
            var streetA = a.street && a.street.trim();
            var streetB = b.street && b.street.trim();
            if ((streetA && streetA.length > 0) && (!streetB || streetB.length === 0)) {
                return -1;
            }
            if ((!streetA || streetA.length === 0) && (streetB && streetB.length > 0)) {
                return 1;
            }
            var stateA = a.state && a.state.trim();
            var stateB = b.state && b.state.trim();
            if ((stateA && stateA.length > 0) && (!stateB || stateB.length === 0)) {
                return -1;
            }
            if ((!stateA || stateA.length === 0) && (stateB && stateB.length > 0)) {
                return 1;
            }
            return 0;
        });
        return addresses;
    };
    EditLocationComponent.prototype.updateAddress = function (address) {
        if (address && this.location) {
            this.location.street = address.street;
            this.location.city = address.city;
            this.location.state = address.state;
            this.location.zipcode = address.zipcode;
            this.location.country = address.country;
        }
    };
    EditLocationComponent.prototype.drawingComplete = function (overlay) {
        var _this = this;
        var map = overlay.map;
        console.log('drawingComplete');
        map.data.add(new google.maps.Data.Feature({
            geometry: new google.maps.Data.Polygon([overlay.getPath().getArray()])
        }));
        this.isGeoFenceAvailable = true;
        overlay.setMap(null); // Remove the original overlay from the map
        map.data.toGeoJson(function (json) { _this.location.geofence = json.features; });
        map.data.forEach(function (feature) {
            map.data.overrideStyle(feature, {
                strokeColor: '#FF8C00',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF8C00',
                fillOpacity: 0.35
            });
        });
        map.data.addListener('click', function (event) {
            _this.setSelectedOverlay(map, event.feature);
        });
        map.data.addListener('rightclick', function (event) {
            _this.deleteSelectedOverlay(map, event.feature);
        });
        map.data.addListener('setgeometry', function (event) {
            _this.setSelectedOverlay(map, event.feature);
            console.log('setgeometry');
            map.data.toGeoJson(function (json) { _this.location.geofence = json.features; });
        });
    };
    EditLocationComponent.prototype.setSelectedOverlay = function (map, feature) {
        map.data.revertStyle();
        this.selectedOverlay = feature;
        map.data.overrideStyle(this.selectedOverlay, {
            fillColor: '#1D68D1'
        });
    };
    EditLocationComponent.prototype.deleteSelectedOverlay = function (map, overlay) {
        var _this = this;
        if (overlay === void 0) { overlay = null; }
        this.isGeoFenceAvailable = false;
        if (overlay) {
            this.selectedOverlay = overlay;
        }
        if (this.selectedOverlay) {
            map.data.remove(this.selectedOverlay);
            map.data.toGeoJson(function (json) { _this.location.geofence = json.features; });
            delete this.selectedOverlay;
        }
    };
    EditLocationComponent.prototype.deletePolygon = function (polygon) {
        if (polygon === void 0) { polygon = null; }
        if (polygon) {
            this.polygon = polygon;
        }
        if (this.polygon) {
            this.polygon.setMap(null);
            this.location.geofence = null;
            delete this.polygon;
        }
    };
    EditLocationComponent.prototype.updatePaths = function (pathArray) {
        // let pathArray = event.target.getPath().getArray();
        this.paths = pathArray.map(function (path) {
            return { lat: path.lat(), lng: path.lng() };
        });
        if (this.paths[0] === this.paths.reverse[0]) {
            console.log('Valid GeoJSON');
        }
        else {
            console.log('Invalid GeoJSON!');
        }
    };
    EditLocationComponent.prototype.onSelect = function (propertyName, e) {
        this.location[propertyName] = e;
    };
    EditLocationComponent.prototype.clearGeoFence = function () {
        var _this = this;
        if (this.map) {
            if (this.polygon) {
                this.polygon.setMap(null);
                this.polygon = null;
            }
            this.map.data.forEach(function (feature) {
                _this.map.data.remove(feature);
            });
        }
        this.location.geofence = null;
        this.isGeoFenceAvailable = false;
    };
    return EditLocationComponent;
}());
exports.EditLocationComponent = EditLocationComponent;
