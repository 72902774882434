import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';

import { parseErrors } from '../shared';
import { PriceService } from './price.service';
import { ProductService } from '../products/product.service';
import { PriceSerializer } from './price.serializer';

@Component({
  selector: 'price-dialog',
  templateUrl: './price-dialog.component.html',
  styleUrls: ['./price-dialog.component.scss'],
  providers: [PriceService, ProductService]
})
export class PriceDialogComponent implements OnInit {
  @Input() priceList;
  @Input() price;
  errors = [];
  loading = false;
  callback;
  products = [];
  priceChangePrefix = '';
  priceChangeSuffix = '%';
  productsDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  selectedProduct;
  selectedPriceChangeType;
  @ViewChild('productDropdown', { static: false }) productDropdown;
  priceChangeTypesDropdownConfig = {
    searchable: false,
    loadingOptions: false
  };
  @ViewChild('priceChangeTypeDropdown', { static: false }) priceChangeTypeDropdown;

  constructor(
    public dialogRef: MatDialogRef<PriceDialogComponent>,
    public dialog: MatDialog,
    private priceService: PriceService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.getProducts();
    if (!this.price) { this.price = new PriceSerializer().fromJson({}); }
    if (this.price.product && this.price.product.id) {
      this.selectedProduct = this.price.product;
    }
    if (this.price.priceChangeType && this.price.priceChangeType) {
      this.selectedPriceChangeType = this.price.priceChangeType;
    }
    if (this.price && this.price.priceChangeType === 'value') {
      this.priceChangePrefix = '$';
      this.priceChangeSuffix = '';
    } else if (this.price && this.price.priceChangeType === 'percent') {
      this.priceChangePrefix = '';
      this.priceChangeSuffix = '%';
    } else if (this.price && this.price.priceChangeType === 'new_price') {
      this.priceChangePrefix = '$';
      this.priceChangeSuffix = '';
    }
  }

  submit() {
    this.loading = true;
    this.priceService.save(this.price).subscribe(price => {
      this.loading = true;
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  getProducts(query = {}, append = false) {
    this.productService.list({
      ...query
    }).subscribe(products => {
      if (append) {
        this.products = this.products.concat(products);
      } else {
        this.products = products;
      }
    });
  }

  productSelected(product) {
    this.price.product = product;
    this.selectedProduct = product;
  }

  productNextPage() {
    this.productsDropdownConfig.loadingOptions = true;
    let next = this.productService.listNext();
    if (next) {
      next.subscribe((res) => {
        this.products.push(res);
        this.productsDropdownConfig.loadingOptions = false;
      });
    } else {
      this.productsDropdownConfig.loadingOptions = false;
    }
  }

  productSearch(term: string) {
    this.getProducts({ search: term });
  }

  priceChangeTypeSelected(priceChangeType) {
    this.price.priceChangeType = priceChangeType;
    this.selectedPriceChangeType = priceChangeType;
    if (priceChangeType && priceChangeType.id === 'percent') {
      this.priceChangePrefix = '';
      this.priceChangeSuffix = '%';
    } else {
      this.priceChangePrefix = '$';
      this.priceChangeSuffix = '';
    }
  }
}
