"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Customer = /** @class */ (function () {
    function Customer(customerInfo) {
        this.id = customerInfo.id;
        this.name = customerInfo.name;
    }
    Customer.prototype.save = function () {
        // Save to API
        console.log(this.id, this.name);
    };
    return Customer;
}());
exports.Customer = Customer;
