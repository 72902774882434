"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var driver_serializer_1 = require("./../drivers/driver.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var AutoAssignConflictDialogComponent = /** @class */ (function () {
    function AutoAssignConflictDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.loading = false;
        this.results = {};
        this.errors = [];
        this.successes = [];
        this.failures = [];
        this.drivers = [];
        this.canForce = false;
    }
    AutoAssignConflictDialogComponent.prototype.ngOnInit = function () {
        if (this.results) {
            this.results = camelcaseKeysDeep(this.results);
            this.successes = this.results.success;
            this.failures = this.results.errors;
            this.drivers = this.results.driverConflicts.map(function (conflict) {
                return new driver_serializer_1.DriverSerializer().fromJson(conflict);
            });
            this.canForce = this.drivers && this.drivers.length > 0;
        }
    };
    AutoAssignConflictDialogComponent.prototype.forceAssign = function () {
        this.callback(true);
        this.dialogRef.close();
    };
    AutoAssignConflictDialogComponent.prototype.close = function () {
        this.callback();
        this.dialogRef.close();
    };
    return AutoAssignConflictDialogComponent;
}());
exports.AutoAssignConflictDialogComponent = AutoAssignConflictDialogComponent;
