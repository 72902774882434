import { Component, Input } from '@angular/core';
import { Driver } from '../driver';

@Component({
  selector: 'driver-daily-geotrips',
  templateUrl: './driver-daily-geotrips.component.html',
  styleUrls: ['./driver-daily-geotrips.component.scss']
})
export class DriverDailyGeotripsComponent {
  @Input() driver: Driver;
  displayedColumns: string[] = [
    'start-time',
    'end-time',
    'trip-duration',
    'enroute-loading-time',
    'loading-arrival-time',
    'loading-complete-time',
    'enroute-unloading-time',
    'unloading-arrival-time',
    'unloading-complete-time',
    'trip-status',
    'completed'
  ];
  displayedTimes = [
    { column: 'start-time', property: 'startTime', header: 'START TIME' },
    { column: 'end-time', property: 'endTime', header: 'END TIME' },
    { column: 'enroute-loading-time', property: 'enrouteLoadingTime', header: 'ENROUTE LOADING TIME' },
    { column: 'loading-arrival-time', property: 'loadingArrivalTime', header: 'LOADING ARRIVAL TIME' },
    { column: 'loading-complete-time', property: 'loadingCompleteTime', header: 'LOADING COMPLETE TIME' },
    { column: 'enroute-unloading-time', property: 'enrouteUnloadingTime', header: 'ENROUTE UNLOADING TIME' },
    { column: 'unloading-arrival-time', property: 'unloadingArrivalTime', header: 'UNLOADING ARRIVAL TIME' },
    { column: 'unloading-complete-time', property: 'unloadingCompleteTime', header: 'UNLOADING COMPLETE TIME' }
  ];
}
