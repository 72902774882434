import { TruckType } from './truck-type';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TruckTypeSerializer {
  fromJson(json: any): TruckType {
    json = camelcaseKeysDeep(json);
    const truckType = new TruckType();
    if (!json) { return truckType; }

    truckType.id = json.id;
    truckType.name = json.name;
    truckType.averageTonnage = json.averageTonnage;
    truckType.isFavorite = json.isFavorite;
    truckType.active = json.active;
    if (truckType.isFavorite) { truckType.icon = 'star'; }

    return truckType;
  }

  toJson(truckType: TruckType): any {
    let json = {
      id: truckType.id,
      name: truckType.name,
      averageTonnage: truckType.averageTonnage,
      isFavorite: truckType.isFavorite,
      active: truckType.active
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
