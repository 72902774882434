import { clone, remove, find } from 'lodash';

import { Organization } from '../organizations/organization';
import { Product } from '../products/product';
import { Location } from '../locations/location';
import { Resource } from '../shared/resource';

export class PurchaseOrder extends Resource {
  id: string;
  customerOrganization: Organization;
  sellerOrganization: Organization;
  product: Product;
  createdAt: string;
  lastModified: string;
  number: string;
  name: string;
  unitPrice: number;
  quantity: number;
  taxExempt: boolean;
  email: string;
  phoneNumber: string;
  faxNumber: string;
  fullStop: boolean;
  fulfilled: boolean;
  void: boolean;
  deleted: boolean;
  deliveryLocation: Location;
  notes: string;
  customPrice: any;
  unitOfMeasurement: string;
  status: string;
  statusLabel: string;
  selected = false;
  loading: boolean;
  classes: string;

  url(action): string {
    return '/purchase-orders/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) { remove(options, _option); }
    } else if (!this.void) {
      let _option = find(options, { action: 'unvoid' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
