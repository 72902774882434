import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BillingRate } from './billing-rate';
import { BillingRateSerializer } from './billing-rate.serializer';
import { ResourceService } from '../../shared/resource.service';

@Injectable()
export class BillingRateService extends ResourceService<BillingRate> {
  constructor(http: HttpClient) {
    super(http, 'LOCAL:/assets/files/billing_rates.json', new BillingRateSerializer());
  }
}
