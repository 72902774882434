<div class="scales-container">
  <div class="scales-header">
    <div class="scale-item"><div class="header" translate>Name</div></div>
    <div class="scale-item"><div class="header" translate>Status</div></div>
    <div class="scale-item">
      <div class="header" translate>Weight ({{ getWeightUnit() }})</div>
    </div>
  </div>

  <div class="scale-content" id="scale-{{ scale.id }}" *ngFor="let scale of scales" [ngClass]="{hidden: loading}">
    <div class="scale-item">
      <div class="content">{{ truncateTextByElipses(scale.name) }}</div>
    </div>
    <div class="scale-item">
      <div class="content content-status" [ngClass]="scale.status">{{ scale.status }}</div>
    </div>
    <div class="scale-item">
      <div class="content">{{ getScaleWeight(scale) }}</div>
    </div>
  </div>

  <my-loader *ngIf="loading"></my-loader>

  <div class="no-scales-found" *ngIf="scales.length === 0 && !loading">
    <i class="icon-digital-scale"><span class="red-x">+</span></i>
    <div class="no-scales-text" translate>No Scales Connected</div>
  </div>
</div>
