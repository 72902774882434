"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var product_1 = require("./product");
var organization_1 = require("../organizations/organization");
var product_type_serializer_1 = require("../product-types/product-type.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ProductSerializer = /** @class */ (function () {
    function ProductSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Product
    */
    ProductSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var product = new product_1.Product();
        if (!json) {
            return product;
        }
        product.id = json.id;
        if (json.productType && typeof (json.productType) === 'object') {
            product.productType = new product_type_serializer_1.ProductTypeSerializer().fromJson(json.productType);
        }
        else {
            product.productType = new product_type_serializer_1.ProductTypeSerializer().fromJson({ id: json.productType });
        }
        product.createdAt = json.createdAt;
        product.lastModified = json.lastModified;
        product.name = json.name;
        product.unitPrice = json.unitPrice;
        product.units = json.units;
        product.minTotalPrice = json.minTotalPrice;
        product.notes = json.notes;
        if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
            product.ownerOrganization = new organization_1.Organization(json.ownerOrganization);
        }
        else {
            product.ownerOrganization = new organization_1.Organization({ id: json.ownerOrganization });
        }
        product.unitOfMeasurement = json.unitOfMeasurement;
        product.discountPercentage = json.discountPercentage;
        product.number = json.number;
        product.status = json.status;
        if (json.salesTaxRate && json.salesTaxRate > 0) {
            product.salesTaxRate = json.salesTaxRate;
            product.salesTaxRateDecimal = json.salesTaxRate / 100;
        }
        return product;
    };
    /**
     * @param  {Product} product
     * @returns any
     */
    ProductSerializer.prototype.toJson = function (product) {
        var json = {
            id: product.id,
            productType: (product.productType && typeof product.productType === 'object') ?
                product.productType.id : product.productType,
            createdAt: product.createdAt,
            lastModified: product.lastModified,
            name: product.name,
            unitPrice: product.unitPrice,
            units: product.units,
            minTotalPrice: product.minTotalPrice,
            salesTaxRate: product.salesTaxRate || 0,
            notes: product.notes,
            ownerOrganization: product.ownerOrganization && product.ownerOrganization.id,
            unitOfMeasurement: product.unitOfMeasurement,
            discountPercentage: product.discountPercentage,
            number: product.number,
            status: product.status
        };
        return decamelizeKeysDeep(json);
    };
    return ProductSerializer;
}());
exports.ProductSerializer = ProductSerializer;
