"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./void-ticket-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../shared/notification/notification.component.ngfactory");
var i3 = require("../shared/notification/notification.component");
var i4 = require("@angular/forms");
var i5 = require("@angular/material/dialog");
var i6 = require("@ngx-translate/core");
var i7 = require("@angular/flex-layout/flex");
var i8 = require("@angular/flex-layout/core");
var i9 = require("@angular/common");
var i10 = require("./ticket.service");
var i11 = require("@angular/common/http");
var i12 = require("./void-ticket-dialog.component");
var styles_VoidTicketDialogComponent = [i0.styles];
var RenderType_VoidTicketDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VoidTicketDialogComponent, data: {} });
exports.RenderType_VoidTicketDialogComponent = RenderType_VoidTicketDialogComponent;
function View_VoidTicketDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(1, 245760, null, 0, i3.NotificationComponent, [], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_VoidTicketDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, [["voidTicket", 4]], 0, i4.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i1.ɵdid(4, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(6, 81920, null, 0, i5.MatDialogTitle, [[2, i5.MatDialogRef], i1.ElementRef, i5.MatDialog], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(9, null, ["Void Ticket #", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "close-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icomoon icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 17, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i5.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 13, "div", [["class", "body form-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 12, "div", [["class", "field-row"], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(16, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(17, 671744, null, 0, i7.DefaultLayoutAlignDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutAlignStyleBuilder], i8.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 9, "div", [["class", "field-group void-reason"], ["fxFlex", "100"]], null, null, null, null, null)), i1.ɵdid(19, 671744, null, 0, i7.DefaultFlexDirective, [i1.ElementRef, i8.StyleUtils, i8.LAYOUT_CONFIG, i7.FlexStyleBuilder, i8.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["What is the reason for voiding this ticket?"])), (_l()(), i1.ɵeld(22, 0, null, null, 5, "textarea", [["name", "void_reason"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 23)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 23)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.model.voidReason = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(25, 671744, null, 0, i4.NgModel, [[2, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(27, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VoidTicketDialogComponent_1)), i1.ɵdid(29, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.submit();
        var pd_0 = (false !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Void "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_8 = ""; _ck(_v, 8, 0, currVal_8); var currVal_10 = "row"; _ck(_v, 16, 0, currVal_10); var currVal_11 = "space-between center"; _ck(_v, 17, 0, currVal_11); var currVal_12 = "100"; _ck(_v, 19, 0, currVal_12); var currVal_20 = "void_reason"; var currVal_21 = _co.model.voidReason; _ck(_v, 25, 0, currVal_20, currVal_21); var currVal_22 = _co.errors; _ck(_v, 29, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 6).id; _ck(_v, 5, 0, currVal_7); var currVal_9 = _co.ticket.number; _ck(_v, 9, 0, currVal_9); var currVal_13 = i1.ɵnov(_v, 27).ngClassUntouched; var currVal_14 = i1.ɵnov(_v, 27).ngClassTouched; var currVal_15 = i1.ɵnov(_v, 27).ngClassPristine; var currVal_16 = i1.ɵnov(_v, 27).ngClassDirty; var currVal_17 = i1.ɵnov(_v, 27).ngClassValid; var currVal_18 = i1.ɵnov(_v, 27).ngClassInvalid; var currVal_19 = i1.ɵnov(_v, 27).ngClassPending; _ck(_v, 22, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }); }
exports.View_VoidTicketDialogComponent_0 = View_VoidTicketDialogComponent_0;
function View_VoidTicketDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "void-ticket-dialog", [], null, null, null, View_VoidTicketDialogComponent_0, RenderType_VoidTicketDialogComponent)), i1.ɵprd(512, null, i10.TicketService, i10.TicketService, [i11.HttpClient]), i1.ɵdid(2, 114688, null, 0, i12.VoidTicketDialogComponent, [i10.TicketService, i5.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_VoidTicketDialogComponent_Host_0 = View_VoidTicketDialogComponent_Host_0;
var VoidTicketDialogComponentNgFactory = i1.ɵccf("void-ticket-dialog", i12.VoidTicketDialogComponent, View_VoidTicketDialogComponent_Host_0, { ticket: "ticket" }, {}, []);
exports.VoidTicketDialogComponentNgFactory = VoidTicketDialogComponentNgFactory;
