"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var collections_1 = require("@angular/cdk/collections");
// rxjs
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
// libraries
var lodash_1 = require("lodash");
var moment = require("moment-timezone");
var camelcaseKeysDeep = require('camelcase-keys-deep');
// angular material
var material_1 = require("@angular/material");
// services
var preference_service_1 = require("../../preferences/preference.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var dispatch_service_1 = require("../dispatch.service");
var assignment_service_1 = require("../../assignments/assignment.service");
var job_service_1 = require("../../jobs/job.service");
var job_load_service_1 = require("./job-load.service");
var confirm_change_job_loads_service_1 = require("./confirm-change-job-loads.service");
// serializers
var assignment_serializer_1 = require("../../assignments/assignment.serializer");
var slot_serializer_1 = require("../../assignments/slot.serializer");
var shift_serializer_1 = require("../../assignments/shift.serializer");
// components
var new_days_collaboration_dialog_component_1 = require("../../collaborators/new-days-collaboration-dialog.component");
var assign_truck_dialog_component_1 = require("../../drivers/assign-truck-dialog/assign-truck-dialog.component");
var confirm_drivers_dialog_component_1 = require("../confirm-drivers-dialog.component");
var assignment_error_dialog_component_1 = require("../../messages/assignment-error-dialog.component");
var index_1 = require("../../shared/index");
var auto_assign_conflict_dialog_component_1 = require("../../assignments/auto-assign-conflict-dialog.component");
var edit_job_dialog_component_1 = require("../../jobs/edit-job-dialog.component");
var available_drivers_component_1 = require("../../drivers/available-drivers/available-drivers.component");
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
var dialogs_1 = require("../../shared/dialogs");
var move_assignments_dialog_component_1 = require("../../assignments/move-assignments/move-assignments-dialog.component");
// constants
var app_constants_1 = require("../../app.constants");
var DispatchByJobComponent = /** @class */ (function () {
    function DispatchByJobComponent(route, preferenceService, dispatchService, jobEventService, assignmentService, authenticationService, location, dialog, jobLoadService, confirmChangeJobLoadsService) {
        var _this = this;
        this.route = route;
        this.preferenceService = preferenceService;
        this.dispatchService = dispatchService;
        this.jobEventService = jobEventService;
        this.assignmentService = assignmentService;
        this.authenticationService = authenticationService;
        this.location = location;
        this.dialog = dialog;
        this.jobLoadService = jobLoadService;
        this.confirmChangeJobLoadsService = confirmChangeJobLoadsService;
        this.displayedColumns = ['select', 'loadNumber', 'loadTime', 'truck', 'driver'];
        this.lastCreatedAssignments = new rxjs_1.Subject();
        this.hasLoadListsEnabled = false;
        this.loadListSelection = new collections_1.SelectionModel(true, []);
        this.loadListSelectAll = false;
        this.assigningDriverToLoad = false;
        this.removingDriverLoadAssignments = false;
        this.loadToAssign = null;
        this.skipReload = false;
        this.loadListLegend = [
            { name: 'Received', color: '#ffd667' },
            { name: 'Accepted', color: '#27af5e' },
            { name: 'Rejected', color: '#be2f1f' }
        ];
        this.view = 'assignments';
        this.loading = false;
        this.errors = [];
        this.tempModel = [];
        this.jobEvents = [];
        this.jobs = [];
        this.loadList = [];
        this.preferenceKey = 'GeneralUserPreferences';
        this.selectedTags = [];
        this.preselectTags = false;
        this.jobsLoading = false;
        this.jobsLoadingNext = false;
        this.jobEventsLoading = false;
        this.jobEventsLoadingNext = false;
        this.assignmentsLoading = false;
        this.bulkSaveLoading = false;
        this.driversLoading = false;
        this.autoAssignLoading = false;
        this.dispatchLoading = false;
        this.selectedDrivers = [];
        this.selectedDriverCount = 0;
        this.truckOptionsForModal = [];
        this.jobEventsDropdownConfig = {
            nameProperty: 'name',
            showLoading: true
        };
        this.actionsDropdownOptions = [];
        this.actionsDropdownConfig = {
            nameProperty: 'name',
            showLoading: false
        };
        this.jobDropdownConfig = {
            searchable: true,
            group: true,
            showLoading: true,
            nameProperty: 'name',
            groupProperty: 'project.name',
            sortBy: 'project__name,name,start_date',
            selectText: 'Select Job',
            loadingText: 'Loading Jobs...',
            noResultsText: 'No Jobs',
            service: job_service_1.JobService,
            query: {}
        };
        this.drivers = {
            items: [],
            errors: [],
            loading: false,
            search: ''
        };
        this.driverCount = null;
        this.shifts = [];
        this.loadStatus = '';
        this.dayEstimate = '';
        this.hasAllDriversEnabled = false;
        this.sortBy = '';
        this.sortAsc = true;
        this.allSubscriptionsToUnsubscribe = [];
        this.saveJobDayCallback = function (e) {
            _this.jobEvent = e.jobEvent;
            _this.deselectAll();
            _this.reload(true);
        };
        this.autoAssignConflictCallback = function (e) {
            if (e) {
                _this.autoAssign(e);
            }
            _this.autoAssignLoading = false;
            _this.reload();
        };
        this.moveAssignmentsCallback = function (e) {
            _this.deselectAll();
            _this.reload();
        };
        this.cloneAssignmentsCallback = function (e) {
            _this.deselectAll();
        };
        this.showDriverInList = function (driver) {
            return !lodash_1.includes(_this.allSlots().map(function (slot) { return lodash_1.get(slot, 'driver.id'); }), driver.id);
        };
        this.saveJobDaysCallback = function (daysAdded) {
            if (_this.jobEvent && _this.jobEvent.canShare) {
                _this.openNewDaysDialog(daysAdded);
            }
            _this.reload(true);
        };
        this.confirmDriversCallback = function (shift, slot) {
            _this.addToSlot(shift, slot);
        };
    }
    DispatchByJobComponent.prototype.allSlots = function () {
        if (this.shifts.length) {
            return this.shifts.reduce(function (slots, shift) {
                return lodash_1.uniq(slots.concat(shift.slots));
            }, []);
        }
        else {
            return [];
        }
    };
    DispatchByJobComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.start = new Date();
        this.start.setHours(0, 0, 0, 0);
        this.jobDropdownConfig.query = {
            jobevents__end__gte: this.start.toISOString()
        };
        this.user = this.authenticationService.user();
        this.getPreferences();
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
        this.hasLoadListsEnabled = this.authenticationService.hasLoadListsEnabled();
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            if (result && result.params) {
                _this.jobId = result.params['jobId'];
            }
            if (result && result.params['jobEventId']) {
                _this.getJobEvent(result.params['jobEventId']);
            }
            else {
                _this.getJobEvents({}, false, true);
            }
        }));
    };
    DispatchByJobComponent.prototype.ngOnDestroy = function () {
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        if (this.loadListReq && typeof this.loadListReq.unsubscribe === 'function') {
            this.loadListReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DispatchByJobComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferenceService.list({
            name: this.preferenceKey,
            type: 'user',
            profile: this.user && this.user.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
            }
        });
        this.preferenceService.list({
            name: 'GeneralOrganizationPreferences',
            type: 'organization',
            organization: this.user && this.user.organization && this.user.organization.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.orgPreference = preferences[0];
                if (_this.orgPreference && _this.orgPreference.blob) {
                    var tagPreference = _this.orgPreference.blob['availableDrivers[jobBasedTagSelection]'];
                    if (tagPreference) {
                        _this.preselectTags = tagPreference === 'pre-select' ? true : false;
                    }
                }
            }
        });
    };
    DispatchByJobComponent.prototype.getJobEvent = function (jobEventId) {
        var _this = this;
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        this.jobEventReq = this.jobEventService.getJobEvent(jobEventId).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
            // this.setJobEvent(jobEvent);
            _this.getJobEvents();
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DispatchByJobComponent.prototype.getJobEvents = function (query, next, selectFirst) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (selectFirst === void 0) { selectFirst = false; }
        this.loading = true;
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        var jobEventServiceReq = this.jobEventService.getJobEvents(__assign({ ordering: 'shift1_start', page_size: 25, job: this.job && this.job.id || this.jobId, end__gte: this.start.toISOString() }, query));
        if (next) {
            this.jobEventsLoadingNext = true;
            jobEventServiceReq = this.jobEventService.listNext();
        }
        else {
            this.jobEventsLoading = true;
        }
        this.jobEventsReq = jobEventServiceReq && jobEventServiceReq.subscribe(function (jobEvents) {
            _this.jobEvents = next ? _this.jobEvents.concat(jobEvents) : jobEvents;
            if (jobEvents.length > 0 && selectFirst) {
                _this.jobEvent = jobEvents[0];
            }
            if (_this.jobEvent) {
                var jobEvent = lodash_1.find(_this.jobEvents, { id: _this.jobEvent.id });
                if (jobEvent) {
                    _this.jobEvents = lodash_1.reject(_this.jobEvents, jobEvent);
                }
                else {
                    jobEvent = _this.jobEvent;
                }
                _this.jobEvents.unshift(jobEvent);
                if (_this.jobEventsDropdown) {
                    _this.jobEventsDropdown.selectedOption = jobEvent;
                }
                _this.setJobEvent(_this.jobEvent);
            }
            _this.jobEventsLoadingNext = false;
            _this.jobEventsLoading = false;
            if (_this.hasLoadListsEnabled) {
                _this.getLoadList(_this.jobEvent.id);
            }
            _this.getLoadList(_this.jobEvent.id);
        }, function (err) {
            _this.errors = err;
            _this.jobEventsLoading = false;
            _this.jobEventsLoadingNext = false;
            _this.loading = false;
        });
        if (!jobEventServiceReq) {
            this.jobEventsLoadingNext = false;
        }
    };
    DispatchByJobComponent.prototype.getLoadList = function (jobEventId) {
        var _this = this;
        if (this.loadListReq && typeof this.loadListReq.unsubscribe === 'function') {
            this.loadListReq.unsubscribe();
        }
        this.jobLoadService.getLoads(this.jobEvent.id).subscribe(function (results) {
            _this.loadList = results;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DispatchByJobComponent.prototype.clickSelectAllLoads = function (event) {
        var checked = event.target.checked;
        this.toggleAllLoads(checked);
    };
    DispatchByJobComponent.prototype.toggleAllLoads = function (checked) {
        var _this = this;
        this.loadListSelectAll = checked;
        this.loadList.forEach(function (load) {
            if (_this.loadListSelectAll) {
                _this.loadListSelection.select(load);
            }
            else {
                _this.loadListSelection.deselect(load);
            }
        });
    };
    DispatchByJobComponent.prototype.toggleLoadListSelection = function (row, event) {
        if (!event.checked) {
            this.loadListSelection.deselect(row);
        }
        else {
            this.loadListSelection.select(row);
        }
        this.loadListSelectAll = this.loadListSelection.selected.length === this.loadList.length;
    };
    DispatchByJobComponent.prototype.setSelectedAction = function (option) {
        switch (option.name) {
            case 'Edit Job Days':
                this.openJobDays();
                break;
        }
        this.actionsDropdown.deselectAll();
    };
    DispatchByJobComponent.prototype.openJobDays = function () {
        var dialog = this.dialog.open(edit_job_dialog_component_1.EditJobDialogComponent, {
            width: '320px',
            data: { job: this.job }
        });
        if (dialog) {
            dialog.componentInstance.callback = this.saveJobDaysCallback;
        }
    };
    DispatchByJobComponent.prototype.openNewDaysDialog = function (daysAdded) {
        if (daysAdded && daysAdded.length > 0) {
            var dialog = this.dialog.open(new_days_collaboration_dialog_component_1.NewDaysCollaborationDialogComponent, {
                width: '850px'
            });
            if (dialog) {
                dialog.componentInstance.jobEvent = this.jobEvent;
                dialog.componentInstance.daysAdded = daysAdded;
            }
        }
    };
    DispatchByJobComponent.prototype.addActionButtons = function () {
        if (this.jobEvent && this.jobEvent.canEdit) {
            if (!lodash_1.find(this.actionsDropdownOptions, { name: 'Edit Job Days' })) {
                this.actionsDropdownOptions.push({ name: 'Edit Job Days', button: true });
            }
        }
    };
    DispatchByJobComponent.prototype.jobEventDropdownSearch = function (term) {
        this.getJobEvents({ search: term });
    };
    DispatchByJobComponent.prototype.dispatchDisabled = function () {
        var slots = lodash_1.filter(this.allSlots(), 'assignment');
        return !lodash_1.find(slots, function (slot) {
            return slot.driver && slot.driver.id && !lodash_1.get(slot, 'assignment.dispatched');
        });
    };
    DispatchByJobComponent.prototype.sendDispatch = function () {
        var _this = this;
        this.dispatchLoading = true;
        var bulkUpdateReqs = this.bulkUpdateAssignments();
        rxjs_1.forkJoin(bulkUpdateReqs).subscribe(function (bulkUpdates) {
            _this.processBulkUpdates(bulkUpdates);
            _this.dispatchService.save({
                jobEvent: _this.jobEvent.id,
                notify_new: true
            }).subscribe(function (res) {
                _this.jobEvent.lastDispatchedTime = res['lastDispatchTime'] || res['lastDispatchedTime'];
                _this.jobEvent.lastDispatchedBy = res['lastDispatchedBy'];
                if (_this.shifts.length) {
                    _this.shifts.forEach(function (shift) {
                        shift.slots.forEach(function (slot) {
                            if (slot.driver) {
                                slot.assignment.dispatched = true;
                            }
                        });
                    });
                }
                _this.reload();
            }, function (err) {
                _this.errors = err;
            }, function () {
                _this.dispatchLoading = false;
            });
        });
    };
    DispatchByJobComponent.prototype.reload = function (reloadJobEvents) {
        if (reloadJobEvents === void 0) { reloadJobEvents = false; }
        if (this.skipReload) {
            return;
        }
        if (this.jobEvent) {
            this.getJobEvent(this.jobEvent.id);
        }
        if (reloadJobEvents) {
            this.getJobEvents();
        }
    };
    DispatchByJobComponent.prototype.getAssignments = function (jobEventId, next) {
        var _this = this;
        if (next === void 0) { next = false; }
        this.sortAsc = true;
        this.sortBy = 'unique_start,created_at';
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var request = this.assignmentService.list({
            jobevent: jobEventId,
            can_dispatch: 'True',
            ordering: order
        });
        if (next) {
            request = this.assignmentService.listNext();
        }
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        this.assignmentsReq = request.subscribe(function (assignments) {
            if (_this.assignmentService.nextUri) {
                _this.processAssignments(assignments, true);
                _this.getAssignments(_this.jobEvent.id, true);
            }
            else {
                _this.processAssignments(assignments);
                _this.addActionButtons();
                _this.assignmentsLoading = false;
                _this.loading = false;
            }
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DispatchByJobComponent.prototype.processAssignments = function (assignments, hasNextPage) {
        var _this = this;
        if (hasNextPage === void 0) { hasNextPage = false; }
        this.shifts.forEach(function (shift) { if (!shift.slots) {
            shift.slots = [];
        } });
        assignments.forEach(function (assignment) {
            var shiftIndex = assignment.shift ? parseInt(assignment.shift.replace(/[^\d]/g, ''), 10) - 1 : 0;
            var shift = _this.shifts[shiftIndex];
            if (shift) {
                var numberOfLoadsType = 'numbered';
                if (assignment.numberOfLoadsType) {
                    numberOfLoadsType = assignment.numberOfLoadsType;
                }
                else {
                    numberOfLoadsType = assignment.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
                }
                if (!assignment.yardBufferTime) {
                    assignment.yardBufferTime = assignment.jobevent.defaultYardBufferTime ||
                        assignment.job.defaultYardBufferTime ||
                        _this.user.organization.defaultYardBufferTime;
                }
                if (!assignment.yardBufferMinutes) {
                    assignment.yardBufferMinutes = assignment.jobevent.defaultYardBufferMinutes ||
                        assignment.job.defaultYardBufferMinutes ||
                        _this.user.organization.defaultYardBufferMinutes;
                }
                shift.slots.push(new slot_serializer_1.SlotSerializer().fromJson({
                    assignment: assignment,
                    driver: assignment.driver,
                    truck: assignment.truck,
                    numberOfLoadsType: numberOfLoadsType
                }));
            }
        });
        if (!hasNextPage) {
            this.shifts.forEach(function (shift) {
                var max = _this.jobEvent && _this.jobEvent.numTrucks || shift.slots.length + 1;
                for (var i = shift.slots.length; i < max; i++) {
                    shift.slots.push(new slot_serializer_1.SlotSerializer().fromJson({}));
                }
            });
        }
    };
    DispatchByJobComponent.prototype.setJobEvent = function (jobEvent) {
        this.jobEvent = jobEvent;
        this.job = this.jobEvent.job;
        if (jobEvent) {
            var url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
            this.location.replaceState(url + '/' + jobEvent.job.id + '/' + jobEvent.id);
            this.start = jobEvent.jobStart;
            if (this.preselectTags && this.jobEvent.job && this.jobEvent.job.tags.length > 0) {
                this.selectedTags = this.jobEvent.job.tags;
            }
            else if (this.preselectTags && this.authenticationService.hasFavoriteTags()) {
                this.selectedTags = this.user.favoriteTags;
            }
            this.shifts = [new shift_serializer_1.ShiftSerializer().fromJson({ id: 0, slots: [] })];
            if (this.jobEvent.shift2StartTimestamp) {
                this.shifts.push(new shift_serializer_1.ShiftSerializer().fromJson({ id: 1, slots: [] }));
            }
            this.getAssignments(jobEvent.id);
        }
    };
    DispatchByJobComponent.prototype.dropDriver = function (shift, slot, event) {
        var _this = this;
        this.lastDraggedDriver = this.draggedDriver;
        if (!slot) {
            // remove
            var _slot = lodash_1.find(this.allSlots(), function (__slot) { return __slot.driver === _this.draggedDriver; });
            if (_slot) {
                this.removeDriver(_slot);
            }
        }
        else {
            var selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
            if (selectedDrivers.length === 0) {
                selectedDrivers = this.selectedDrivers;
            }
            var conflictedDrivers = selectedDrivers.filter(function (d) { return d.conflicts && d.conflicts.length > 0; });
            if (conflictedDrivers.length > 0 && !this.assigningDriverToLoad) {
                conflictedDrivers.forEach(function (driver) { return driver.busy = true; });
                var dialog = this.dialog.open(confirm_drivers_dialog_component_1.ConfirmDriversDialogComponent, {
                    width: '430px'
                });
                dialog.componentInstance.drivers = conflictedDrivers;
                dialog.componentInstance.shift = shift;
                dialog.componentInstance.slot = slot;
                dialog.componentInstance.callback = this.confirmDriversCallback;
            }
            else {
                this.addToSlot(shift, slot);
            }
        }
    };
    DispatchByJobComponent.prototype.addToSlot = function (shift, slot) {
        var _this = this;
        // add
        var selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        if (!selectedDrivers || !selectedDrivers.length) {
            selectedDrivers = this.selectedDrivers;
        }
        if (selectedDrivers.length) {
            selectedDrivers.forEach(function (driver, i) {
                var slotIndex = shift.slots.map(function (o) { return o.over; }).indexOf(true);
                if (shift.slots[slotIndex + i]) {
                    if (driver && driver.truck && driver.dutyStatus === 'on-duty') {
                        _this.tempModel.push({ shift: shift, slots: shift.slots[i + slotIndex], driver: driver });
                    }
                    else {
                        _this.addTruckAndDriver(shift, shift.slots[i], driver).subscribe(function (res) {
                            // Note: subscription of dialog response will occur after forEach completes qualifying selected drivers
                            if (res) {
                                // If successful response, add that driver
                                _this.addDriver([{ shift: shift, slots: shift.slots[i], driver: driver }]);
                            }
                        });
                    }
                }
                if (_this.assigningDriverToLoad || !shift.slots[slotIndex + i + 1] && (i + 1) !== selectedDrivers.length) {
                    shift.slots.push(new slot_serializer_1.SlotSerializer().fromJson({}));
                }
            });
            if (this.tempModel.length > 0) {
                this.addDriver(this.tempModel);
            }
        }
        else {
            this.addTruckAndDriver(shift, slot, this.lastDraggedDriver);
            if (!this.job.numTrucks) {
                shift.slots.push(new slot_serializer_1.SlotSerializer().fromJson({}));
            }
        }
        slot.over = false;
        this.tempModel = [];
    };
    DispatchByJobComponent.prototype.addTruckAndDriver = function (shift, slot, driver) {
        var _this = this;
        return new rxjs_1.Observable(function (observer) {
            if (driver && (!driver.truck || driver.dutyStatus !== 'on-duty')) {
                if (!lodash_1.filter(_this.drivers.items, 'selected').length) {
                    var dialog = _this.openAssignTruck(driver);
                    var _callback_1 = dialog.componentInstance.callback;
                    dialog.componentInstance.callback = function (res) {
                        _callback_1(res);
                        observer.next(true);
                    };
                }
            }
        });
    };
    /**
     * @param  {} tempModel
     * For each selected driver, it will create request payload for '/assignments' API
     * It sets the max_number_of_loads based on the preferences returned.
     */
    DispatchByJobComponent.prototype.addDriver = function (tempModel) {
        var _this = this;
        var disabledStaggeredTime = this.preference &&
            this.preference.blob &&
            this.preference.blob['staggerAssignmentTime'] === 'shift1_start';
        var removedSlot;
        var assignments = [];
        var assignment;
        var countSameChunk = 0;
        var assignmentsPending = [];
        var uniqueStart = moment(moment(this.jobEvent.shift1StartTimestamp).format('YYYY-MM-DD') + ' ' + this.jobEvent.staggerStartTime, 'YYYY-MM-DD H:mm A').toISOString();
        tempModel.forEach(function (element, index) {
            removedSlot = lodash_1.clone(element.slots);
            assignment = {
                driver: element.driver.id,
                truck: element.driver.truck.id,
                jobevent: _this.jobEvent.id,
                shift: 'shift' + (element.shift.id + 1),
                unique_start: disabledStaggeredTime ? uniqueStart : moment(uniqueStart).add(Number(_this.jobEvent.deliveryInterval) * index, 'minutes').toISOString()
            };
            if (_this.assigningDriverToLoad && _this.loadToAssign && _this.loadToAssign.ticketEvents && _this.loadToAssign.ticketEvents.LOADING_STARTED) {
                assignment.unique_start = _this.loadToAssign.ticketEvents.LOADING_STARTED;
            }
            if (element.driver.uniqueStart) {
                assignment.uniqueStart = element.driver.uniqueStart;
            }
            if (_this.assigningDriverToLoad) {
                assignment.max_number_of_loads = 1;
            }
            else if (_this.jobEvent.assignmentLoadCount) {
                assignment.max_number_of_loads = Number(_this.jobEvent.assignmentLoadCount);
            }
            else if (_this.hasAllDriversEnabled) {
                if (!_this.preference || !_this.preference.hasOwnProperty('blob') || _this.preference.blob['dispatchLoadType'] === 'all-day') {
                    assignment.max_number_of_loads = 0;
                }
                else if (_this.preference.blob['dispatchLoadType'] === 'by-load') {
                    assignment.max_number_of_loads = _this.preference.blob['dispatchLoadCount'] ?
                        Number(_this.preference.blob['dispatchLoadCount']) : 1;
                }
            }
            var validationParams = {
                currentAssignation: {
                    numberOfLoadsType: assignment.max_number_of_loads ? 'numbered' : 'allDay',
                    maxNumberOfLoads: assignment.max_number_of_loads,
                },
                shifts: _this.shifts,
                jobEvent: _this.jobEvent,
                countSameChunk: countSameChunk,
            };
            countSameChunk += assignment.max_number_of_loads;
            if (_this.confirmChangeJobLoadsService.isGreaterAssigned(validationParams)) {
                assignmentsPending.push({ element: element, assignment: assignment });
            }
            else {
                assignments.push(assignment);
            }
        });
        if (assignmentsPending.length) {
            this.assignmentsLoading = true;
            var loadsAssigned = assignmentsPending.reduce(function (total, pending) { return total + Number(pending.assignment.max_number_of_loads); }, 0);
            var validationParams = {
                currentAssignation: {
                    numberOfLoadsType: loadsAssigned ? 'numbered' : 'allDay',
                    maxNumberOfLoads: loadsAssigned,
                },
                shifts: this.shifts,
                jobEvent: this.jobEvent,
            };
            this.confirmChangeJobLoadsService.validateLoadsRequested(validationParams).subscribe(function (response) {
                var elements = assignmentsPending.map(function (e) { return e.element; });
                response && _this.addDriver(elements);
                _this.assignmentsLoading = false;
            }, function () { return _this.assignmentsLoading = false; });
        }
        if (!assignments.length)
            return;
        var countCurrentAssignments = this.shifts.map(function (s) { return (s.slots.filter(function (slot) { return (slot.assignment.id); }).length); }).reduce(function (a, b) { return (a + b); });
        var totalAssignments = countCurrentAssignments + assignments.length;
        if (!!this.jobEvent.numTrucks &&
            totalAssignments > this.jobEvent.numTrucks) {
            this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
                width: '530px',
            });
            var messageAssigned = countCurrentAssignments > 0 ? " and the " + countCurrentAssignments + " previously assigned" : '';
            this.confirmDialog.componentInstance.attributes = {
                title: "Trucks Requested Exceeded",
                body: "The number of trucks required has exceeded by the " + assignments.length + " truck(s) selected" + messageAssigned + ". \n        Do you want to assign the selected truck(s) and increase the total number for this job to " + totalAssignments + "?",
                close: 'Cancel',
                accept: 'Assign & Update Trucks'
            };
            this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    _this.jobEventService.save({
                        id: _this.jobEvent.id,
                        numTrucks: totalAssignments
                    }).pipe(operators_1.first()).subscribe(function () {
                        _this.createBulkAssignments(tempModel, assignments).subscribe(function (res) {
                            if (res) {
                                _this.removeDriver(removedSlot);
                            }
                        }, function (err) {
                            _this.openDisplayErrorModal(err.errors);
                            _this.loading = false;
                            _this.assignmentsLoading = false;
                        });
                    }, function (err) {
                        _this.errors = err;
                    });
                }
                _this.confirmDialog = null;
            });
        }
        else {
            this.createBulkAssignments(tempModel, assignments).subscribe(function (res) {
                if (res) {
                    _this.removeDriver(removedSlot);
                }
            }, function (err) {
                _this.openDisplayErrorModal(err.errors);
                _this.loading = false;
                _this.assignmentsLoading = false;
            });
        }
    };
    /**
     * @param  {} tempModel
     * @param  {} assignments
     * @returns Observable
     * This is used to create POST request to '/assignments' API
     * It takes assignments argument as its request payload
     */
    DispatchByJobComponent.prototype.createBulkAssignments = function (tempModel, assignments) {
        var _this = this;
        this.assignmentsLoading = true;
        return new rxjs_1.Observable(function (observer) {
            if (!!!_this.jobEvent.numTrucks || _this.jobEvent.numTrucks <= _this.allSlots().length) {
                _this.assignmentService.saveBulkAssignments(assignments).subscribe(function (_assignment) {
                    var assignmentArray = _assignment.assignments;
                    _this.lastCreatedAssignments.next(assignmentArray);
                    tempModel.forEach(function (element, index) {
                        element.slots.driver = element.driver;
                        element.slots.truck = element.driver.truck;
                        element.slots.updating = false;
                        element.slots.over = false;
                        element.slots.assignment = new assignment_serializer_1.AssignmentSerializer().fromJson(assignmentArray[index]);
                        _this.drivers.items.forEach(function (_driver) { return _driver.selected = false; });
                        observer.next(true);
                    });
                    if (_this.availableDrivers) {
                        _this.availableDrivers.getDrivers();
                    }
                }, function (err) {
                    _this.openDisplayErrorModal(err.errors);
                    observer.next(false);
                    _this.loading = false;
                    _this.assignmentsLoading = false;
                }, function () { return _this.loading = false; });
            }
        });
    };
    /**
     * @param  {} err
     * When the '/assignments' API fails, this dialog will display the error
     * listing the driver and truck name's followed by the error message.
     */
    DispatchByJobComponent.prototype.openDisplayErrorModal = function (errors) {
        var _this = this;
        var _errors = [];
        if (errors && errors.length) {
            [].concat.apply([], errors).forEach(function (error) {
                var driver;
                var errorMessage = error.errors && error.errors.non_field_errors;
                if (error.item) {
                    driver = lodash_1.find(_this.selectedDrivers, { id: error.item && error.item.driver });
                }
                else if (error.data) {
                    driver = lodash_1.find(_this.selectedDrivers, { id: error.data && error.data.driver });
                }
                if (driver) {
                    _errors.push({
                        error: errorMessage,
                        driverName: driver.name,
                        truckName: driver.truck && driver.truck.displayName
                    });
                }
                else if (errorMessage) {
                    _errors.push({
                        error: errorMessage
                    });
                }
            });
        }
        if (_errors.length) {
            var dialog = this.dialog.open(assignment_error_dialog_component_1.AssignmentErrorDialogComponent, {
                width: '430px'
            });
            this.tempModel = [];
            dialog.componentInstance.errors = _errors;
            dialog.afterClosed().subscribe(function (dialogResult) { _this.reload(); });
        }
    };
    DispatchByJobComponent.prototype.assignDriverToLoad = function (row) {
        var _this = this;
        if (row.ruckit && row.ruckit.assignmentId) {
            return;
        }
        this.assigningDriverToLoad = true;
        this.loadToAssign = row;
        this.skipReload = true;
        row['dragOver'] = false;
        this.selectDrivers([this.selectedDrivers[0]], true);
        var lastCreatedSub = this.lastCreatedAssignments.subscribe(function (assignments) {
            _this.assignmentsLoading = false;
            _this.loading = false;
            if (assignments) {
                _this.patchUpdateLoad(row, _this.selectedDrivers[0], assignments[0].id);
                lastCreatedSub.unsubscribe();
            }
        });
    };
    DispatchByJobComponent.prototype.patchUpdateLoad = function (row, driver, assignmentId) {
        var _this = this;
        var vehicleId = driver.truck && driver.truck.name ? driver.truck.name : '';
        var vehicleName = driver.truck && driver.truck.name ? driver.truck.name : '';
        var vehicleContainerId = driver.truckContainerId ? driver.truckContainerId : '';
        var driverId = driver.id;
        var driverName = driver.name;
        var loadUpdate = {
            loadNumber: row.loadNumber,
            vehicleId: vehicleName,
            ruckit: {
                assignmentId: assignmentId,
                driverId: driverId,
                driverName: driverName,
                vehicle: {
                    id: vehicleId,
                    description: vehicleName,
                    vehicleRef: vehicleContainerId
                }
            }
        };
        this.jobLoadService.patchLoads(this.jobEvent.id, [loadUpdate]).subscribe(function (response) {
            Object.assign(row, loadUpdate);
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.skipReload = false;
            _this.assigningDriverToLoad = false;
            _this.loadToAssign = null;
        });
    };
    DispatchByJobComponent.prototype.removeDriversFromLoads = function () {
        var _this = this;
        this.removingDriverLoadAssignments = true;
        this.skipReload = true;
        var loads = [];
        var emptyLoad = {
            vehicleId: '',
            ruckit: {
                vehicleName: '',
                assignmentId: '',
                driverId: '',
                driverName: '',
                vehicle: {
                    id: '',
                    description: '',
                    vehicleRef: ''
                }
            }
        };
        this.loadListSelection.selected.forEach(function (l) {
            var load = JSON.parse(JSON.stringify(l));
            Object.assign(load, emptyLoad);
            loads.push(load);
        });
        var slotsToRemove = [];
        this.allSlots().forEach(function (slot) {
            if (slot.assignment && slot.assignment.driver && slot.assignment.driver.id) {
                var removedLoad = _this.loadListSelection.selected.findIndex(function (l) { return l.ruckit && l.ruckit.driverId === slot.assignment.driver.id; });
                if (removedLoad > -1) {
                    slotsToRemove.push(slot);
                    slot.over = false;
                }
            }
        });
        this.bulkRemoveAssignments(slotsToRemove);
        this.jobLoadService.patchLoads(this.jobEvent.id, loads).subscribe(function (response) {
            _this.loadListSelection.selected.forEach(function (load) {
                Object.assign(load, emptyLoad);
            });
            _this.toggleAllLoads(false);
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.removingDriverLoadAssignments = false;
            _this.skipReload = false;
        });
    };
    DispatchByJobComponent.prototype.removeDriver = function (slot) {
        var _this = this;
        if (slot.assignment) {
            var shift_1 = lodash_1.find(this.shifts, function (_shift) { return lodash_1.includes(_shift.slots, slot); });
            if (shift_1) {
                this.assignmentService.remove(slot.assignment.id).subscribe(function (res) {
                    slot.driver = undefined;
                    slot.assignment = undefined;
                    lodash_1.remove(shift_1.slots, slot);
                    var max = _this.jobEvent.numTrucks || shift_1.slots.length + 1;
                    for (var i = shift_1.slots.length; i < max; i++) {
                        shift_1.slots.push(new slot_serializer_1.SlotSerializer().fromJson({}));
                    }
                    if (_this.availableDrivers) {
                        _this.availableDrivers.getDrivers();
                    }
                }, function (err) {
                    _this.errors = err;
                });
            }
        }
        this.reload();
        this.draggedDriver = undefined;
        this.selectedDrivers = [];
    };
    DispatchByJobComponent.prototype.driverIsDraggable = function (driver) {
        this.selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        return this.selectedDrivers.length === 0 || lodash_1.includes(this.selectedDrivers, driver);
    };
    DispatchByJobComponent.prototype.driverIsGrabbed = function (driver) {
        return this.driverIsDraggable(driver) ?
            !!(driver === this.draggedDriver || (this.draggedDriver && driver.selected)) : undefined;
    };
    DispatchByJobComponent.prototype.multipleDrag = function (driver) {
        this.selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        return this.draggedDriver === driver && this.selectedDrivers.length > 1;
    };
    DispatchByJobComponent.prototype.driverDragstart = function (driver) {
        this.draggedDriver = driver;
    };
    DispatchByJobComponent.prototype.ignoreDragevent = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    DispatchByJobComponent.prototype.slotDragover = function (e) {
        e.preventDefault();
    };
    DispatchByJobComponent.prototype.slotDrag = function (slot, event) {
        slot.over = (event && event.type === 'dragenter');
    };
    /**
     * @param  {Driver[]} drivers
     * Get the object of all the selected drivers
     * Set the slot to {over: true} if slot is available in the shift object
     * It also checks for which shift is selected in case of multi-shift
     */
    DispatchByJobComponent.prototype.selectDrivers = function (drivers, triggerDrop) {
        var _this = this;
        if (triggerDrop === void 0) { triggerDrop = false; }
        this.selectedDrivers = lodash_1.filter(drivers, 'selected');
        if (this.selectedDrivers.length === 0) {
            this.selectedDrivers = drivers;
        }
        var count = 0;
        var breaktheloop = false;
        this.shifts.forEach(function (shift) {
            if (_this.selectedShift && _this.selectedShift.id === shift.id) {
                shift.slots.forEach(function (slot, index) {
                    if (slot.driver && !slot.driver.id) {
                        if (_this.selectedDrivers.length === count) {
                            breaktheloop = true;
                        }
                        else if (!breaktheloop) {
                            count++;
                            shift.slots[index] = new slot_serializer_1.SlotSerializer().fromJson({ over: true });
                        }
                    }
                });
                if (triggerDrop) {
                    _this.dropDriver(shift, shift.slots[0], null);
                }
            }
        });
    };
    DispatchByJobComponent.prototype.removeSelectedAssignments = function () {
        var selectedSlots = [];
        this.shifts.forEach(function (shift) {
            selectedSlots = selectedSlots.concat(shift.slots.filter(function (slot) { return slot.assignment.selected; }));
        });
        if (selectedSlots && selectedSlots.length) {
            this.bulkRemoveAssignments(selectedSlots);
        }
    };
    DispatchByJobComponent.prototype.modifySelectedAssignments = function (copy) {
        if (copy === void 0) { copy = false; }
        var selectedSlots = [];
        var assignments = [];
        this.shifts.forEach(function (shift) {
            selectedSlots = selectedSlots.concat(shift.slots.filter(function (slot) { return slot.assignment.selected; }));
        });
        assignments = selectedSlots.map(function (slot) {
            slot.assignment['numberOfLoadsType'] = slot.numberOfLoadsType;
            return slot.assignment;
        }).filter(Boolean);
        if (selectedSlots && selectedSlots.length) {
            var dialog = this.dialog.open(move_assignments_dialog_component_1.MoveAssignmentsDialogComponent, {
                width: '850px'
            });
            if (dialog) {
                dialog.componentInstance.callback = this.moveAssignmentsCallback;
                dialog.componentInstance.copy = copy;
                dialog.componentInstance.job = this.job;
                dialog.componentInstance.start = this.start;
                dialog.componentInstance.jobEvent = this.jobEvent;
                dialog.componentInstance.assignments = lodash_1.clone(assignments);
                dialog.componentInstance.hasAllDriversEnabled = this.hasAllDriversEnabled;
            }
        }
    };
    /**
     * @param  {any} shift
     * gets the emitted value from the available-driver component and sets to the variable
     */
    DispatchByJobComponent.prototype.selectShift = function (shift) {
        this.selectedShift = shift;
    };
    /**
     * Update the assignment when information is valid
     * @param slot - save the assignment status in the UI
     */
    DispatchByJobComponent.prototype.updateAssignment = function (slot) {
        var _this = this;
        var currentAssignation = {
            assignmentId: slot.assignment.id,
            numberOfLoadsType: slot.numberOfLoadsType,
            maxNumberOfLoads: slot.assignment.maxNumberOfLoads,
        };
        this.confirmChangeJobLoadsService.validateLoadsRequested({
            currentAssignation: currentAssignation,
            shifts: this.shifts,
            jobEvent: this.jobEvent,
        }).subscribe(function (response) { return (response && _this._updateAssignmentLoads(slot)); }, function (error) {
            if (error && error.maxLoads) {
                slot.assignment.maxNumberOfLoads = error.maxLoads;
                _this._updateAssignmentLoads(slot);
            }
        });
    };
    DispatchByJobComponent.prototype._updateAssignmentLoads = function (slot) {
        var _this = this;
        slot.updating = true;
        var _assignment = lodash_1.clone(slot.assignment);
        var formatString = 'MM/DD/YYYY h:mm A';
        var dateString = moment(_assignment.uniqueStartDate).format('MM/DD/YYYY');
        _assignment.uniqueStart = moment(dateString + " " + _assignment.uniqueStartTime, formatString).format();
        _assignment['maxNumberOfLoads'] = slot.numberOfLoadsType === 'allDay' ? 0 : _assignment['maxNumberOfLoads'] || 1;
        this.assignmentService.save(_assignment, { can_dispatch: 'True' }).subscribe(function (assignment) {
            _this.assignmentService.get(assignment.id, { can_dispatch: 'True' }).subscribe(function (res) {
                slot.assignment = res;
                slot.updating = false;
            }, function (err) {
                _this.errors = err;
                slot.updating = false;
            });
        }, function (err) {
            _this.errors = err;
            slot.updating = false;
        });
    };
    DispatchByJobComponent.prototype.bulkUpdateAssignments = function () {
        var _this = this;
        this.bulkSaveLoading = true;
        var bulkUpdateReqs = [];
        this.shifts.forEach(function (shift) {
            if (shift && shift.slots) {
                var assignments = shift.slots.map(function (_slot) {
                    _slot.updating = true;
                    var assignment = lodash_1.clone(_slot.assignment);
                    var formatString = 'MM/DD/YYYY h:mm A';
                    var dateString = moment(assignment.uniqueStartDate).format('MM/DD/YYYY');
                    assignment.uniqueStart = moment(dateString + " " + assignment.uniqueStartTime, formatString).format();
                    assignment['maxNumberOfLoads'] = _slot.numberOfLoadsType === 'allDay' ? 0 : assignment['maxNumberOfLoads'] || 1;
                    return assignment;
                }).filter(function (assignment) { return assignment.id !== null && assignment.id !== undefined; });
                var request = _this.assignmentService.bulkUpdate(assignments, { can_dispatch: 'True' });
                bulkUpdateReqs.push(request);
            }
        });
        return bulkUpdateReqs;
    };
    DispatchByJobComponent.prototype.triggerBulkSave = function () {
        var _this = this;
        var bulkUpdateReqs = this.bulkUpdateAssignments();
        rxjs_1.forkJoin(bulkUpdateReqs).subscribe(function (bulkUpdates) {
            _this.processBulkUpdates(bulkUpdates);
        });
    };
    DispatchByJobComponent.prototype.processBulkUpdates = function (bulkUpdates) {
        var _this = this;
        bulkUpdates.forEach(function (res) {
            if (res && res.errors && res.errors.length) {
                _this.openDisplayErrorModal(res.errors);
            }
            if (res && res.assignments && res.assignments.length) {
                res.assignments.forEach(function (_assignment) {
                    var shiftIndex = _assignment.shift ? parseInt(_assignment.shift.replace(/[^\d]/g, ''), 10) - 1 : 0;
                    var shift = _this.shifts[shiftIndex];
                    var _slot = lodash_1.find(shift.slots, { assignment: { id: _assignment.id } });
                    if (_slot) {
                        _slot.assignment = _assignment;
                        if (_assignment.numberOfLoadsType) {
                            _slot.numberOfLoadsType = _assignment.numberOfLoadsType;
                        }
                        else {
                            _slot.numberOfLoadsType = (_assignment.maxNumberOfLoads === 0 || _assignment.maxNumberOfLoads === null) ? 'allDay' : 'numbered';
                        }
                        _slot.updating = false;
                    }
                });
            }
        });
        this.shifts.forEach(function (shift) {
            shift.slots.forEach(function (_slot) {
                _slot.updating = false;
            });
        });
        this.bulkSaveLoading = false;
    };
    DispatchByJobComponent.prototype.bulkRemoveAssignments = function (slots) {
        var _this = this;
        var assignmentIds = slots.map(function (slot) { return slot.assignment.id; }).filter(Boolean);
        if (assignmentIds && assignmentIds.length) {
            if (this.removingDriverLoadAssignments) {
                this.removeAssignments(assignmentIds, slots);
                return;
            }
            this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, app_constants_1.DEFAULT_DIALOG_SIZE);
            this.confirmDialog.componentInstance.attributes = {
                title: "Remove " + assignmentIds.length + " Assignments?",
                body: "This action will remove the selected assignments from this day of the " + this.job.name + " job.",
                close: 'Cancel',
                accept: 'Remove'
            };
            this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    _this.removeAssignments(assignmentIds);
                }
                _this.confirmDialog = null;
            });
        }
    };
    DispatchByJobComponent.prototype.removeAssignments = function (assignmentIds, slots) {
        var _this = this;
        if (slots === void 0) { slots = null; }
        this.bulkSaveLoading = true;
        this.assignmentService.bulkRemove(assignmentIds).subscribe(function () {
            if (_this.removingDriverLoadAssignments && slots) {
                slots.forEach(function (slot) {
                    var shift = lodash_1.find(_this.shifts, function (_shift) { return lodash_1.includes(_shift.slots, slot); });
                    slot.driver = undefined;
                    slot.assignment = undefined;
                    lodash_1.remove(shift.slots, slot);
                    var max = _this.jobEvent.numTrucks || shift.slots.length + 1;
                    for (var i = shift.slots.length; i < max; i++) {
                        shift.slots.push(new slot_serializer_1.SlotSerializer().fromJson({}));
                    }
                });
                if (_this.availableDrivers) {
                    _this.availableDrivers.getDrivers();
                }
                _this.draggedDriver = undefined;
                _this.selectedDrivers = [];
            }
            _this.bulkSaveLoading = false;
            _this.reload();
        });
    };
    DispatchByJobComponent.prototype.onTabClick = function (view) {
        this.view = view;
        this.deselectAll();
        this.toggleAllLoads(false);
    };
    DispatchByJobComponent.prototype.selectAssignment = function (event, slot, shift) {
        if (shift === void 0) { shift = null; }
        var checked = event.target.checked;
        if (!slot && shift) {
            shift.slots.forEach(function (_slot) { return _slot.assignment.selected = _slot.assignment.id && checked; });
        }
        else {
            slot.assignment.selected = checked;
        }
    };
    DispatchByJobComponent.prototype.deselectAll = function () {
        this.shifts.forEach(function (shift) {
            shift.slots.forEach(function (_slot) { return _slot.assignment.selected = false; });
        });
        if (this.selectAllAssignments) {
            this.selectAllAssignments.nativeElement.checked = false;
        }
    };
    DispatchByJobComponent.prototype.selectDriver = function (e, driver) {
        var checked = e.target.checked;
        driver.selected = checked;
        lodash_1.extend(lodash_1.find(this.drivers.items, { id: driver.id }), driver);
        this.selectedDriverCount = lodash_1.filter(this.drivers.items, 'selected').length;
    };
    DispatchByJobComponent.prototype.openAssignTruck = function (driver, assignmentId) {
        var _this = this;
        var dialog = this.dialog.open(assign_truck_dialog_component_1.AssignTruckDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.driver = lodash_1.cloneDeep(driver);
        dialog.componentInstance.jobEvent = this.jobEvent;
        dialog.componentInstance.requireOnDuty = true;
        dialog.componentInstance.callback = function (res) {
            Object.assign(driver, res);
            if (assignmentId !== undefined) {
                var assignment = new assignment_serializer_1.AssignmentSerializer().fromJson({
                    id: assignmentId,
                    driver: driver.id,
                    truck: driver.truck.id,
                    jobevent: _this.jobEvent.id
                });
                _this.assignmentService.save(assignment).subscribe(function (_assignment) {
                    _this.drivers.items.forEach(function (_driver) { return _driver.selected = false; });
                    _this.reload();
                }, function (err) {
                    _this.errors = err;
                }, function () { return _this.loading = false; });
            }
        };
        return dialog;
    };
    DispatchByJobComponent.prototype.displayLastDispatched = function () {
        return this.jobEvent && this.jobEvent.lastDispatchedTime && this.dispatchDisabled();
    };
    DispatchByJobComponent.prototype.autoAssign = function (force) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (this.jobEvent.canAutoAssign) {
            this.autoAssignLoading = true;
            this.jobEventService.autoAssign(this.jobEvent.id, force).subscribe(function (res) {
                _this.reload();
                _this.autoAssignLoading = false;
            }, function (err) {
                var results = JSON.parse(err._body);
                results = camelcaseKeysDeep(results);
                var dialog = _this.dialog.open(auto_assign_conflict_dialog_component_1.AutoAssignConflictDialogComponent, {
                    width: '430px'
                });
                dialog.componentInstance.results = results;
                dialog.componentInstance.callback = _this.autoAssignConflictCallback;
                dialog.afterClosed().subscribe(function (dialogResult) {
                    _this.autoAssignLoading = false;
                });
            });
        }
    };
    DispatchByJobComponent.prototype.assignmentUniqueStartDateChanged = function (slot, values) {
        var assignment = slot.assignment;
        if (values && values.length) {
            assignment.uniqueStartDate = values[0];
        }
    };
    DispatchByJobComponent.prototype.selectJob = function (job, userSelection) {
        if (userSelection === void 0) { userSelection = true; }
        this.setJobProperties(job);
        if (!userSelection) {
            return;
        }
        this.loading = true;
        this.jobEvent = null;
        this.jobEvents = [];
        var url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
        if (this.job && this.jobEvent) {
            this.location.replaceState(url + '/' + this.jobId);
        }
        this.getJobEvents({}, false, true);
    };
    DispatchByJobComponent.prototype.setJobProperties = function (job) {
        this.job = job;
        this.jobId = this.job && this.job.id;
    };
    DispatchByJobComponent.prototype.selectJobEvent = function (jobEvent) {
        this.loading = true;
        this.setJobEvent(jobEvent);
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
        if (this.job && this.jobEvent) {
            this.location.replaceState(url + '/' + this.jobId + '/' + jobEventId);
        }
    };
    return DispatchByJobComponent;
}());
exports.DispatchByJobComponent = DispatchByJobComponent;
