"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{padding:30px;position:relative}.container[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{margin-right:15px;cursor:pointer}  .mat-tab-header{background:#fff;border-radius:4px 4px 0 0;border:1px solid #e6e9ec;border-bottom:0}"];
exports.styles = styles;
