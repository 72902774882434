import { Driver } from '../drivers/driver';
import { TruckType } from './truck-type';
import { Resource } from '../shared/resource';
import { clone } from 'lodash';
import { Location } from '../locations/location';
import { User } from '../users/user';

export class Truck extends Resource {
  id: string;
  name: string;
  driver: Driver;
  truckType: TruckType;
  createdAt: string;
  createdBy: string;
  isConnexContainer: boolean;
  lastModified: string;
  lastModifiedBy: User;
  licensePlate: string;
  sunset: boolean;
  image: string;
  imageKey: string;
  status: string;
  serviceStatus: 'in-service' | 'out-of-service';
  displayServiceStatus: string;
  leasedOrg: boolean;
  ticketName: string;
  carrierOrganizationName: string;
  carrierOrganizationId: string;
  carrier: string;
  connectionId: string;
  noRate: boolean;
  state: string;
  truckTypeName: string;
  truckListImage: string;
  dispatchImage: string;
  editTruckImage: string;
  gridTruckImage: string;
  driverInfoWindowImage: string;
  jobDetailImage: string;
  displayName: string;
  listName: string;
  tags: any[];
  yardLocation: Location;
  customFieldData: { [key: string]: string } = {};

  url(action): string {
    return '/trucks/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
