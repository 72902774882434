"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var surcharge_service_1 = require("../surcharges/surcharge.service");
var expense_service_1 = require("../expenses/expense.service");
var surcharge_dialog_component_1 = require("../surcharges/surcharge-dialog.component");
var api_service_1 = require("../shared/api.service");
var ExpenseSurchargesComponent = /** @class */ (function () {
    function ExpenseSurchargesComponent(route, router, surchargeService, expenseService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.surchargeService = surchargeService;
        this.expenseService = expenseService;
        this.dialog = dialog;
        this.search = '';
        this.updateExpense = new core_1.EventEmitter();
        this.selectedCount = 0;
        this.surcharges = [];
        this.loading = true;
        this.errors = [];
        this.page = 1;
        this.sortAsc = true;
        this.allSelected = false;
        this.selectedSurcharges = [];
        this.excludeSurcharges = [];
        this.multipleActionDropdownOptions = [
            { name: 'Remove from Expense', action: 'remove', link: false }
        ];
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: false },
            { name: 'Remove from Expense', action: 'remove', link: false }
        ];
        this.addSurchargeCallback = function (e) {
            _this.updateExpense.emit(true);
            _this.getSurcharges();
        };
    }
    ExpenseSurchargesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.loading = true;
        this.route.queryParams.forEach(function (params) {
            _this.sortBy = params.sortSurchargesBy ? params.sortSurchargesBy : 'date';
            _this.sortAsc = params.sortSurchargesAsc ? (params.sortSurchargesAsc === 'true') : false;
            _this.sortParameter = params.sortSurchargesParameter ? params.sortSurchargesParameter : null;
            _this.page = params.sortSurchargesPage ? params.sortSurchargesPage : 1;
        });
        this.getSurcharges((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    ExpenseSurchargesComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                var chng = changes[propName];
                if (propName === 'search') {
                    this.search = chng.currentValue;
                }
            }
        }
        this.changeSearch(this.search);
    };
    ExpenseSurchargesComponent.prototype.pageChange = function (event) {
        var _a;
        this.page = event;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortSurchargesPage: this.page > 1 ? this.page : null })
        });
        this.getSurcharges((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    ExpenseSurchargesComponent.prototype.getSurcharges = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.surcharges = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.surchargeReq) {
            this.surchargeReq.unsubscribe();
        }
        this.surchargeFilters = __assign({ ordering: order, search: this.search, invoice: this.expense.id }, query);
        this.surchargeReq = this.surchargeService.list(this.surchargeFilters).subscribe(function (surcharges) {
            if (append) {
                _this.surcharges = _this.surcharges.concat(surcharges);
            }
            else {
                _this.surcharges = surcharges;
            }
            _this.count = _this.surchargeService.count;
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    ExpenseSurchargesComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortSurchargesBy: this.sortBy, sortSurchargesAsc: this.sortAsc, sortSurchargesParameter: null })
        });
        this.loading = true;
        this.getSurcharges({
            ordering: (this.sortAsc ? '' : '-') + this.sortBy,
            page: this.page
        });
    };
    ExpenseSurchargesComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortSurchargesBy: this.sortBy, sortSurchargesAsc: this.sortAsc, sortSurchargesParameter: this.sortParameter })
        });
        this.loading = true;
        this.getSurcharges((_a = {},
            _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy,
            _a.page = this.page,
            _a));
    };
    ExpenseSurchargesComponent.prototype.openAddSurcharge = function () {
        var dialog = this.dialog.open(surcharge_dialog_component_1.SurchargeDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.invoice = this.expense;
        dialog.componentInstance.callback = this.addSurchargeCallback;
    };
    ExpenseSurchargesComponent.prototype.removeSelectedSurcharges = function (surcharges) {
        var _this = this;
        if (surcharges === void 0) { surcharges = null; }
        var model = { id: this.expense.id };
        if (surcharges || this.selectedSurcharges.length) {
            Object.assign(model, { surcharges: surcharges || this.selectedSurcharges });
        }
        else if (this.allSelected) {
            var params_1 = new http_1.HttpParams();
            var filters_1 = lodash_1.omit(this.surchargeFilters, ['ordering', 'expense']);
            if (filters_1) {
                Object.keys(filters_1).map(function (key) {
                    if (filters_1[key] && filters_1[key].length) {
                        params_1 = params_1.set(key, filters_1[key]);
                    }
                });
            }
            if (params_1.toString().length) {
                Object.assign(model, { filters: params_1.toString() });
            }
            Object.assign(model, { excludeSurcharges: this.excludeSurcharges });
        }
        this.expenseReq = this.expenseService.removeFromExpense(model, 'surcharges').subscribe(function (res) {
            _this.expense = res;
            _this.search = '';
            _this.allSelected = false;
            _this.updateExpense.emit(true);
            _this.getSurcharges();
        }, function (err) { return console.error(err); });
    };
    ExpenseSurchargesComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.getSurcharges();
    };
    ExpenseSurchargesComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    ExpenseSurchargesComponent.prototype.menuAction = function (name, surcharge) {
        switch (name) {
            case 'remove':
                surcharge ? this.removeSelectedSurcharges([surcharge.id]) : this.removeSelectedSurcharges();
                break;
            case 'edit':
                this.editSurcharge(surcharge);
                break;
        }
    };
    ExpenseSurchargesComponent.prototype.editSurcharge = function (surcharge) {
        var dialog = this.dialog.open(surcharge_dialog_component_1.SurchargeDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.model = lodash_1.clone(surcharge);
        dialog.componentInstance.invoice = this.expense;
        dialog.componentInstance.callback = this.addSurchargeCallback;
    };
    ExpenseSurchargesComponent.prototype.selector = function (event, surcharge) {
        if (surcharge === void 0) { surcharge = null; }
        if (surcharge) {
            if (!event.target.checked) {
                surcharge.selected = false;
                lodash_1.pull(this.selectedSurcharges, surcharge.id);
                if (this.allSelected) {
                    this.excludeSurcharges.push(surcharge.id);
                    this.selectedCount = (this.count - this.excludeSurcharges.length);
                }
                else {
                    this.selectedCount = this.selectedSurcharges.length;
                }
            }
            else {
                surcharge.selected = true;
                if (this.allSelected) {
                    lodash_1.pull(this.excludeSurcharges, surcharge.id);
                    this.selectedCount = (this.count - this.excludeSurcharges.length);
                }
                else {
                    this.selectedSurcharges.push(surcharge.id);
                    this.selectedCount = this.selectedSurcharges.length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected = false;
                this.surcharges.forEach(function (_surcharge) { _surcharge.selected = false; });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                this.selectedCount = (this.count - this.excludeSurcharges.length);
            }
            this.selectedSurcharges = [];
            this.excludeSurcharges = [];
        }
    };
    return ExpenseSurchargesComponent;
}());
exports.ExpenseSurchargesComponent = ExpenseSurchargesComponent;
