import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { CustomerService } from './customer.service';
import { parseErrors } from '../shared/api.service';
import { Organization } from '../organizations/organization';

@Component({
  selector: 'ruckit-new-customer-dialog',
  templateUrl: './new-customer-dialog.component.html',
  styleUrls: ['./new-customer-dialog.component.scss'],
  providers: [CustomerService]
})
export class NewCustomerDialogComponent {
  title = 'Add a Customer';
  loading = false;
  model: any = {};
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<NewCustomerDialogComponent>,
    private customerService: CustomerService
  ) { }

  submit() {
    this.loading = true;
    this.customerService.save(this.model).subscribe((res) => {
      this.dialogRef.close();
      let customer = res;
      this.callback(new Organization({name: customer['organization']['name'], id: customer['organization']['id']}));
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }
}
