import { timer as observableTimer, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { MatDialog } from '@angular/material';

import { JobEventStatService } from '../../job-event-stats/job-event-stat.service';
import { AuthenticationService } from '../../shared';

@Component({
  selector: 'daily-production',
  templateUrl: './daily-production.component.html',
  styleUrls: ['./daily-production.component.scss'],
  providers: []
})

export class DailyProductionComponent implements OnInit, OnDestroy {
  enabledFeatures: string[];
  totalHoursField: 'totalHours' | 'totalTripHours' | 'totalCompletedTripHours' = 'totalHours';
  stats: any = [];
  loading = true;
  errors = [];
  sortBy = 'job__name';
  sortAsc = true;
  sortParameter;
  private statsTimerSub;
  statsReq: Subscription;

  constructor(
    private statService: JobEventStatService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    if (window.innerWidth > 900) {
      this.getStats({}, true);
      this.statsTimerSub = observableTimer(1, 120000);
      this.statsTimerSub.subscribe(t => {
        this.getStats({}, false);
      });
    }
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
      const totalHoursPreference = this.authenticationService.getFeature('calculator');
      this.totalHoursField = totalHoursPreference === 'trips' ?
        'totalTripHours' : totalHoursPreference === 'completed_trips' ?
        'totalCompletedTripHours' : 'totalHours';
    }
  }

  ngOnDestroy() {
    if (this.statsTimerSub) {
      try { this.statsTimerSub.unsubscribe(); } catch (e) { }
    }
    if (this.statsReq && typeof this.statsReq.unsubscribe === 'function') {
      this.statsReq.unsubscribe();
    }
  }

  onResize(event) {
    if (this.stats.length === 0) { this.ngOnInit(); }
  }

  getStats(query = {}, loading = true) {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    this.loading = loading;
    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    if (this.statsReq && typeof this.statsReq.unsubscribe === 'function') {
      this.statsReq.unsubscribe();
    }

    this.statsReq = this.statService.getAllStats({
      ordering: order,
      ...query,
      shift1_start__gte: startDate.toISOString(),
      shift1_start__lte: endDate.toISOString()
    }).subscribe(stats => {
      this.stats = stats;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getStats({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
  }
}
