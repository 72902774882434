const camelcaseKeysDeep = require('camelcase-keys-deep');

export class PaystubFilter {
  id: string;
  name: string;

  constructor(paystubFilterInfo: any) {
    paystubFilterInfo = camelcaseKeysDeep(paystubFilterInfo);

    this.id = paystubFilterInfo.id;
    this.name = paystubFilterInfo.name;
  }
}
