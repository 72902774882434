<div class="side-drawer" *ngIf="open" #driverAssignmentsList [ngClass]="{ open: open }">
  <my-loader class="full-height" *ngIf="loading"></my-loader>
  <div *ngIf="errors && errors.length" class="errors">
    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>

  <div *ngIf="driver && driver.assignments" class="sidenav-content">
    <section class="header">
      <h1 translate>{{ displayDate }} Assignments</h1>
      <div class="data-field">
        <h2>{{ driver.name }}</h2>
        <div class="sub-title">
          <ruckit-dropdown [config]="trucksDropdownConfig"
                           [selectedOption]="(driver.latestTruck) || (driver.truck)"
                           (onSelect)="changeTruck($event)">
          </ruckit-dropdown>
          <div *ngIf="!edit" class="edit-button" (click)="editAssignments($event)">Edit</div>
          <div *ngIf="edit" class="save-button" (click)="updateAssignments()">Save</div>
        </div>
      </div>
      <dropdown *ngIf="hasCopyAssignment && !loading && !dispatch"
                [disabled]="selectedAssignments.length === 0"
                title="{{ 'Select drivers' | translate }}"
                [options]="driversList"
                [config]="driverDropdownConfig"
                [selectedItems]="selectedDrivers"
                (onSelect)="setSelectedDriver($event)">
      </dropdown>
    </section>
    <section class="assignments-list">
      <div class="actions">
        <div class="remove-action"
             [ngClass]="{disabled: !allAssignmentsSelected && selectedAssignments.length === 0}"
             (click)="deleteAssignments(selectedAssignments)">
          <i class="icon-delete"></i>
        </div>
        <div class="copy-action"
             *ngIf="hasCopyAssignment" (click)="copyAssignments($event)"
             [ngClass]="{'disabled': (!allAssignmentsSelected && selectedAssignments.length === 0) || selectedDrivers.length === 0}">
          <i class="icon-copy"></i>
        </div>
        <div class="select-all" *ngIf="hasCopyAssignment">
          <input type="checkbox" name="select-all"
                 [(ngModel)]="allAssignmentsSelected"
                 (change)="selectAllAssignments($event)" />
        </div>
      </div>
      <div *ngFor="let assignment of driver.assignments; let i = index"
           [ngClass]="{'deleted': assignment.deleted}"
           class="assignment-details">
        <p class="deleted-message" translate>Assignment Deleted</p>
        <div class="status-icon">
          <i [ngClass]="{ 'icon-accepted': assignment.completed || assignment.driverStatus === 'confirmed',
                          'icon-declined': !assignment.completed && assignment.driverStatus === 'rejected',
                          'icon-notread': !assignment.completed && assignment.driverStatus === 'pending',
                          'icon-read': !assignment.completed && assignment.driverStatus === 'read' }"></i>
        </div>
        <div class="overview" *ngIf="assignment.details" [ngClass]="{ loading: assignment.updating }"
             [@expandRow]="assignment.checked ? 'expanded' : 'collapsed'">
          <div class="header">
            <h3 [ngClass]="{'job-title': hasCopyAssignment}">
              <a *ngIf="!assignment.checked || !dispatch"
                 [routerLink]="[ '/jobs', assignment.jobevent && assignment.jobevent.job && assignment.jobevent.job.id, assignment.jobevent && assignment.jobevent.id ]">
                {{ assignment.jobevent && assignment.jobevent.jobDisplayName || assignment.jobEvent && assignment.jobEvent.jobDisplayName || assignment.job.name }}
              </a>
              <dropdown *ngIf="assignment.checked && !loading && dispatch"
                        [options]="availableJobEvents"
                        [config]="jobEventDropdownConfig"
                        [selectedOption]="assignment.jobevent"
                        (onSelect)="changeAssignmentJobEvent(assignment, $event)">
              </dropdown>
            </h3>
            <input class="selector" *ngIf="hasCopyAssignment" name="assignment_{{i}}" type="checkbox"
              [(ngModel)]="assignment.checked" (change)="selectAssignments($event)" />
          </div>
          <p *ngIf="selectedAssignments.length === 0 || !assignment.checked">
            {{ (assignment.completed ? 'Completed' : assignment.driverStatus) | titlecase | translate }} |
            {{ assignment.details && assignment.details.startTime }} -
            {{ assignment.details && assignment.details.endTime }} |
            <span *ngIf="assignment.maxNumberOfLoads === 1" translate><br />Single Load</span>
            <span *ngIf="assignment.maxNumberOfLoads === 0 || assignment.maxNumberOfLoads === null" translate>All
              Day</span>
            <span *ngIf="assignment.maxNumberOfLoads > 1">
              <br/>
              {{ assignment.maxNumberOfLoads }} {{ 'Assigned' | translate }} / 
              {{ assignment.details && assignment.details.numberOfLoadsLeft ? (assignment.maxNumberOfLoads - assignment.details.numberOfLoadsLeft) : 0 }} 
              {{ 'Completed' | translate }}
            </span>
          </p>
          <div class="edit" *ngIf="!loading && selectedAssignments.length > 0 && assignment.checked">
            <div class="times">
              <div class="start-time">
                <span class="label" translate>Start Time: </span>
                <input type="text"
                  required
                  name="driverStartTime"
                  class="timepicker"
                  [placeholder]="assignment.details.startTime"
                  [(ngModel)]="assignment.details.startTime"
                  (blur)="dispatch && updateAssignments()"
                  [options]="{
                        disableTextInput: false,
                        timeFormat: 'g:i A',
                        showDuration: false,
                        step: 5,
                        minTime: assignment.details.startTime,
                        maxTime: assignment.details.endTime}" />
                <span class="dropdown-arrow"></span>
              </div>
              <!-- ===== END TIME FIELD NEEDS TO BE ADDED ONCE API IS UPDATED ===== -->
              <!-- <div class="end-time">
                      <span class="label" translate>End Time: </span>
                      <input type="text" required name="driverEndTime" class="timepicker" [placeholder]="assignment.details.endTime"
                        [(ngModel)]="assignment.details.endTime"
                        [options]="{
                              disableTextInput: false,
                              'timeFormat': 'g:i A',
                              showDuration: false,
                              step: 5,
                              minTime: assignment.details.jobevent['shift' + (shiftIndex + 1) + 'StartTimestamp'] | date: 'h:mm a',
                              maxTime: assignment.details.jobevent['shift' + (shiftIndex + 1) + 'EndTimestamp'] | date: 'h:mm a'
                              }" />
                      <span class="dropdown-arrow"></span>
                    </div> -->
            </div>
            <div *ngIf="hasAllDriversEnabled" class="load-type">
              <input type="radio" name="{{ 'max-loads-' + assignment.id }}"
                     required (click)="setDefaultLoadCount(assignment, 'numbered')"
                     [(ngModel)]="assignment.numberOfLoadsType" [value]="'numbered'"/>
              <label for="{{ 'max-loads-' + assignment.id }}"
                     [ngClass]="{ 'active': assignment.numberOfLoadsType === 'numbered' }"
                     translate>Loads:</label>
              <input type="number" name="{{ 'load-count-' + assignment.id }}"
                     [disabled]="assignment.numberOfLoadsType !== 'numbered'"
                     (blur)="dispatch && updateAssignments()"
                     [(ngModel)]="assignment.maxNumberOfLoads"
                     min="1" />
              <input type="radio" name="{{ 'max-loads-' + assignment.id }}"
                     required (click)="setDefaultLoadCount(assignment, 'allDay')"
                     [(ngModel)]="assignment.numberOfLoadsType" [value]="'allDay'" />
              <label for="{{ 'max-loads-' + assignment.id }}"
                     [ngClass]="{ 'active': assignment.numberOfLoadsType === 'allDay' }"
                     translate>All Day</label>
            </div>
            <div class="status">
              <input type="checkbox" [(ngModel)]="assignment.completed" name="assignment-completed">
              <label for="assignment-completed" translate [class.completed]="assignment.completed">Completed</label>
            </div>
          </div>
          <div translate *ngIf="assignment.updating" class="loading-text" translate>Updating Assignment...</div>
        </div>
        <div *ngIf="assignment.driverStatus === 'rejected'" class="expanded-content">
          <p translate class="label">Driver Message</p>
          <p>{{ (assignment.details && assignment.details.driverRejectionNote) || '--' | translate }}</p>
          <button class="btn btn-default" (click)="removeAssignment(assignment.id, i)" translate>
            <i class="icon-reject"></i> Remove Assignment
          </button>
        </div>
      </div>
    </section>
  </div>
</div>
