<form [formGroup]="newShiftForm" (ngSubmit)="submit()">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add Shift</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <!-- body -->
  <div mat-dialog-content class="mat-dialog-content">
    <!-- errors -->
    <div class="error-container" *ngIf="errors && errors.length > 0">
      <notification *ngFor="let error of errors">
        <span class="nested-error">{{ error }}</span>
      </notification>
    </div>
    <!-- driver -->
    <label class="required" translate>Driver</label>
    <ruckit-dropdown
      [config]="driverDropdownConfig"
      [selectedOption]="driver.value"
      (onSelect)="onSelect('driver', $event)"
    >
    </ruckit-dropdown>

    <!-- truck -->
    <label class="required" translate>Truck</label>
    <ruckit-dropdown
      [config]="truckDropdownConfig"
      [selectedOption]="truck.value"
      (onSelect)="onSelect('truck', $event)"
    >
    </ruckit-dropdown>

    <!-- start time -->
    <label class="required" translate>Start Time</label>
    <div class="group">
      <date-range-picker
        [selectedDates]="[startDate]"
        (dateChanged)="onDateChanged('startDate', $event)"
      >
      </date-range-picker>
      <input type="time" formControlName="startTime" />
    </div>

    <!-- end time -->
    <label translate>End Time</label>
    <div class="group">
      <date-range-picker
        [selectedDates]="[endDate]"
        (dateChanged)="onDateChanged('endDate', $event)"
      >
      </date-range-picker>
      <input type="time" formControlName="endTime" />
    </div>
    <div class="error" *ngIf="newShiftForm.errors?.dates" translate>
      * End Time must be after Start Time
    </div>

    <!-- adjustment -->
    <label translate>Adjustment (Minutes)</label>
    <div class="group">
      <input type="number" formControlName="adjustment" />
    </div>
    <div
      class="error"
      *ngIf="adjustment.dirty && adjustment.touched && adjustment.errors"
      translate
    >
      * Whole Numbers Only
    </div>
  </div>

  <!-- footer -->
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!newShiftForm.valid || loading"
      [ngClass]="{ loading: loading }"
      translate
    >
      Add Shift
    </button>
  </div>

  <!-- end form -->
</form>
