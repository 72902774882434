"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".applied-filters-wrapper[_ngcontent-%COMP%]{display:flex;background:#fff;border-bottom:1px solid #e6e9ec;padding:8px 20px}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]{display:flex;text-transform:capitalize;height:32px;padding:6px 8px 6px 12px;margin-right:15px;border-radius:16px;border:1px solid rgba(0,0,0,.12);font-size:14px;letter-spacing:.3px;color:rgba(0,0,0,.87)}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]   a.remove[_ngcontent-%COMP%]{cursor:pointer;margin:-12px -12px 0 5px}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]   a.remove[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]{color:#9e9e9e;transform:rotate(45deg)}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]   a.remove[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]:hover{color:#787878}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter.hidden[_ngcontent-%COMP%]{display:none}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter.reset[_ngcontent-%COMP%]{cursor:pointer}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter.reset[_ngcontent-%COMP%]:hover{background:#e6e9ec}.applied-filters-wrapper[_ngcontent-%COMP%]   .filter.reset[_ngcontent-%COMP%]:hover   a.remove[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]{color:#787878}"];
exports.styles = styles;
