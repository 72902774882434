import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'groupBy'})

export class GroupByPipe implements PipeTransform {
  transform(value: Array<any>, field: string, sort = false): Array<any> {
    if (!value) { return; }

    const groupedObj = value.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});

    let groups = Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));

    if (sort) {
      groups = groups.sort((a, b) => {
        if (a.key < b.key) {
          return -1;
        } else if (a.key > b.key) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return groups;
  }
}
