"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shift_report_1 = require("./shift-report");
var driver_serializer_1 = require("../drivers/driver.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ShiftReportSerializer = /** @class */ (function () {
    function ShiftReportSerializer() {
    }
    /**
    * @param  {any} json
    * @returns {ShiftReport} ShiftReport
    */
    ShiftReportSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var shift = new shift_report_1.ShiftReport();
        if (!json) {
            return shift;
        }
        shift.id = json.id;
        shift.driver = new driver_serializer_1.DriverSerializer().fromJson(json.driver);
        shift.truckAssignments = json.truckAssignments;
        shift.startTime = json.startTime;
        shift.endTime = json.endTime;
        // json.adjustment is returned as `00.0`, we'll convert this to minutes
        shift.adjustment = Number(json.adjustment) / 60;
        shift.breakDowns = json.breakDowns;
        shift.lunchBreaks = json.lunchBreaks;
        shift.shiftCheckups = json.shiftCheckups;
        shift.autoClosed = json.autoClosed;
        return shift;
    };
    /**
     * @param  {ShiftReport} ShiftReport
     * @returns any
     */
    ShiftReportSerializer.prototype.toJson = function (shift) {
        var json = {
            id: shift.id,
            driver: shift.driver,
            truckAssignments: shift.truckAssignments,
            startTime: shift.startTime,
            endTime: shift.endTime,
            // need to format our seconds into the server's expected `00.0` format
            adjustment: (shift.adjustment * 60) + '.0',
            breakDowns: shift.breakDowns,
            lunchBreaks: shift.lunchBreaks,
            shiftCheckups: shift.shiftCheckups
        };
        return decamelizeKeysDeep(json);
    };
    return ShiftReportSerializer;
}());
exports.ShiftReportSerializer = ShiftReportSerializer;
