import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ShiftsRoutingModule } from './shifts-routing.module';
import { ShiftsComponent } from './shifts/shifts.component';
import { ShiftService, ShiftReportService } from './shift.service';
import { ShiftFilterService } from './shift.filter.service';
import { NewShiftComponent } from './new-shift/new-shift.component';

@NgModule({
  imports: [CommonModule, SharedModule, ShiftsRoutingModule],
  declarations: [ShiftsComponent, NewShiftComponent],
  providers: [ShiftService, ShiftReportService, ShiftFilterService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class ShiftsModule {}
