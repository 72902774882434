<div class="container">
  <ng-container *ngIf="driver.geoTrips && driver.geoTrips.length else noGeoTrips">
    <ng-container *ngFor="let geoTrip of driver.geoTrips">
      <div class="geo-trip-table">
        <h3>{{ geoTrip.job.displayName }}</h3>

        <mat-table [dataSource]="[geoTrip]">
          <ng-container *ngFor="let timeItem of displayedTimes">
            <ng-container [matColumnDef]="timeItem.column">
              <mat-header-cell *matHeaderCellDef>{{ timeItem.header }}</mat-header-cell>
              <mat-cell *matCellDef>
                <span *ngIf="geoTrip[timeItem.property]">{{ geoTrip[timeItem.property] | moment: 'h:mm a' }}</span>
                <span *ngIf="!geoTrip[timeItem.property]">&mdash;</span>
              </mat-cell>
            </ng-container>
          </ng-container>
          
          <ng-container matColumnDef="trip-duration">
            <mat-header-cell *matHeaderCellDef>TRIP DURATION</mat-header-cell>
            <mat-cell *matCellDef>
              <span>{{ geoTrip.tripDuration || '&mdash;' }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="trip-status">
            <mat-header-cell *matHeaderCellDef>TRIP STATUS</mat-header-cell>
            <mat-cell *matCellDef>
              <span>{{ geoTrip.geoStatus || '&mdash;' }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="completed">
            <mat-header-cell *matHeaderCellDef>COMPLETED</mat-header-cell>
            <mat-cell *matCellDef>
              <div [ngClass]="geoTrip.completed ? 'complete' : 'incomplete'">
                <ng-container *ngIf="geoTrip.completed else incomplete">
                  <mat-icon>check_circle</mat-icon>
                </ng-container>
                <ng-template #incomplete>
                  <mat-icon>cancel</mat-icon>
                </ng-template>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noGeoTrips>
    <div class="no-results">
      No GeoTrips found
    </div>
  </ng-template>
</div>
