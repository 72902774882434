import {
  Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef, OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NguiMapComponent } from '@ngui/map';
import { without, find } from 'lodash';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { LocationService } from './location.service';
import { Location } from './location';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';
import { AuthenticationService } from '../shared';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { mapOptions } from '../shared/static-data';

declare let google: any;

@Component({
  selector: 'locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'location-type', title: this.translationService.instant('Location Type'), sortable: true, sortBy: 'loading_type__name' },
    { key: 'location-owner', title: this.translationService.instant('Location Owner'), sortable: true, sortBy: 'owner__name' },
    { key: 'name', title: this.translationService.instant('Location Name'), sortable: true },
    { key: 'address', title: this.translationService.instant('Address'), sortable: true },
    { key: 'city', title: this.translationService.instant('City'), sortable: true },
    { key: 'state', title: this.translationService.instant('State'), sortable: true },
    { key: 'zip', title: this.translationService.instant('Zip'), sortable: true, sortBy: 'zipcode' },
    { key: 'load-tgt', title: this.translationService.instant('Load Tgt'), sortable: true, sortBy: 'average_loading_time' },
    { key: 'unload-tgt', title: this.translationService.instant('Unload Tgt'), sortable: true, sortBy: 'average_unloading_time' },
    { key: 'created-at', title: this.translationService.instant('Created At'), sortable: true, sortBy: 'created_at' },
    { key: 'geofence', title: this.translationService.instant('Geofence'), sortable: true, sortBy: 'has_geofence' },
    { key: 'timezone', title: this.translationService.instant('Timezone'), sortable: false },
    { key: 'action', title: this.translationService.instant('Action'), sortable: false }
  ];

  @Input() displayedColumns = [
    'select', 'location-type', 'name', 'address', 'city', 'state', 'zip',
    'load-tgt', 'unload-tgt', 'geofence', 'timezone', 'created-at', 'action'
  ];
  @Input() availableFilters = [
    new FilterOption({
      key: 'has_geofence',  filterType: 'checkbox',
      title: this.translationService.instant('Has Geofence')
    }),
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  // config for fancy table
  locationTableConfig = {
    hasHeader: true,
    service: LocationService,
    preferenceKey: 'LocationsComponent-LocationService',
    query: {},
    collectionTitle: this.translationService.instant('Locations'),
    noResultsText: this.translationService.instant('a location'),
    newRecordRoute: ['locations/new'],
    sortBy: 'name',
    sortDirection: 'asc',
    ignorePreferences: false,
    menuOptions: [
      { name: this.translationService.instant('Edit Location'), action: 'edit', link: true, external: false },
      { name: this.translationService.instant('Archive'), action: 'archive', link: false, external: false },
      { name: this.translationService.instant('Unarchive'), action: 'unarchive', link: false, external: false }
    ]
  };
  query: any = {};
  locations: Location[] = [];
  selectedLocation: Location;
  loading = true;
  errors = [];
  map: google.maps.Map;
  count = 0;
  windowHeight = 0;
  hideArchived = true;
  view = 'map';
  selectedCount = 0;
  polygons = [];
  markers = [];
  customMarkers = [];
  infoWindowModel;
  confirmDialog: MatDialogRef<any>;
  locationsReq;
  filters = [];
  filtersDialog;
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  dropdownOptions = [
    { name: 'Export', button: true }
  ];
  menuOptions = [
    { name: this.translationService.instant('Edit Location'), action: 'edit', link: true, external: false },
    { name: this.translationService.instant('Archive'), action: 'archive', link: false, external: false },
    { name: this.translationService.instant('Unarchive'), action: 'unarchive', link: false, external: false },
  ];
  mapOptions = mapOptions({
    disableDefaultUI: true,
    scrollwheel: true,
    fullscreenControl: true,
    streetViewControl: false,
    zoomControl: true,
    zoom: 10,
    mapTypeControl: true,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'hybrid']
    },
    center: { lat: 30.2178214, lng: -91.093285 }
  }, {}, {
    mapStyle: 'google-map-style'
  });
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild(NguiMapComponent, { static: true }) nguiMapComponent: NguiMapComponent;
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('locationTable', { static: false }) locationTable;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  newLocationCallback = (e) => {
    this.refreshTable();
  }
  editLocationCallback = (e) => {
    this.refreshTable();
  }
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) {
    this.windowHeight = window.innerHeight;
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };

    if (!this.device.desktop) {
      this.locationTableConfig.ignorePreferences = true;
      this.displayedColumns = ['name', 'address'];
    }
  }

  ngOnInit() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
    this.locationTableConfig['query'] = {
      ...this.locationTableConfig['query'], ...query
    };
    this.locationTableConfig.hasHeader = this.device.desktop;

    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.pipe(delay(0)).subscribe(result => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        this.locationTableConfig.sortBy = result.qparams.sortBy || this.locationTableConfig.sortBy;
        this.locationTableConfig.sortDirection = result.qparams.sortAsc || this.locationTableConfig.sortDirection;
        this.hideArchived = result.qparams['hideArchived'] === 'true' ? true : false;

        this.query['archived'] = this.hideArchived ? 'False' : null;
        this.view = result.params['view'] || 'list';

        this.getLocations();
      })
    );
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  clickAction(event) {
    if (this.device.desktop) {
      if (event) { this.selectLocation(event[1]); }
    } else {
      if (event) { this.editLocation(event[1]); }
    }
  }

  filtersModified(appliedFilters) {

  }

  onSearchChange() {
    this.search = '';
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    delete queryParams['search'];
    this.router.navigate([], { queryParams: queryParams });
  }

  switchMap(view = 'list') {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['hideArchived'] = this.hideArchived;
    this.query['archived'] = this.hideArchived ? 'False' : null;
    this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/locations/' + view], { queryParams: queryParams });
    });
  }

  onScroll(e) {
    if (!this.loading &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.locationService.listNext();
      if (o) {
        this.loading = true;
        o.subscribe(
          locations => {
            this.locations = this.locations.concat(locations);
            this.loading = false;
          },
          err => this.errors = err,
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getLocations(query = {}, append = false, select = null) {
    if (!append) { this.locations = []; }

    let order = (this.locationTableConfig.sortDirection === 'asc' ? '' : '-') + this.locationTableConfig.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return { ...acc, ...filter.query };
    }, {});
    delete filters['undefined'];

    if (this.locationsReq) { this.locationsReq.unsubscribe(); }

    if (this.hideArchived) {
      filters['archived'] = 'False';
    } else {
      delete filters['archived'];
    }
    this.locationsReq = this.locationService.list({
      ordering: order,
      search: this.search,
      ...filters,
      ...query
    }).subscribe(locations => {
      if (append) {
        this.locations = this.locations.concat(locations);
      } else { this.locations = locations; }
      this.mapLocations();
      if (select) {
        let location = find(this.locations, { id: select.id });
        if (location) { this.selectLocation(location); }
      } else { this.selectedLocation = null; }
      this.count = this.locationService.count;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  changeSearch(term?: string) {
    this.search = term || '';
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['search'] = this.search;
    this.router.navigate([], { queryParams: queryParams });

    this.getLocations();
  }

  menuAction(action: string, location): void {
    switch (action) {
      case 'archive':
        this.archive(location);
        break;
      case 'unarchive':
        this.unarchive(location);
        break;
    }
  }

  archive(location) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Archive Location?'),
      body: this.translationService.instant('This location will be archived and will not be visible in the locations list or dropdown.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Archive')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.locationService.archive(location).subscribe((res) => {
          this.refreshTable();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  unarchive(location) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Unarchive Location?'),
      body: this.translationService.instant('This location will be un-archived and will be visible in the locations list and dropdown.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Unarchive')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.locationService.unarchive(location).subscribe((res) => {
          this.refreshTable();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  toggleArchived() {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['hideArchived'] = this.hideArchived;
    this.query['archived'] = this.hideArchived ? 'False' : null;
    this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/locations/' + this.view], { queryParams: queryParams });
    });
  }
  
  onMapLoaded(e = null) {
    if (e) { this.map = e.target.map; }
    let map = this.map;
    let locations = this.locations.map((location) => {
      return new google.maps.LatLng(location.latitude, location.longitude);
    });

    let bounds = new google.maps.LatLngBounds();
    for (let i = 0, LtLgLen = locations.length; i < LtLgLen; i++) {
      let list = locations[i];
      bounds.extend(list);
    }

    if (map) { map.fitBounds(bounds); }
  }

  initMap(event: any) {
    this.map = event;
    google.maps.event.trigger(this.map, 'resize');
    window.dispatchEvent(new Event('resize'));
  }

  onCustomMarkerInit(customMarker) {
    this.customMarkers.push(customMarker);
  }

  openInfoWindow(e, marker) {
    let _marker = e.target;
    let bounds = new google.maps.LatLngBounds();
    let latLng;

    if (marker && marker.location) {
      this.infoWindowModel = {
        title: marker.location.displayName,
        address: marker.location.street,
        city: marker.location.city,
        state: marker.location.state,
        zip: marker.location.zip,
        activeJobs: this.translationService.instant('0 Active Jobs')
      };
      latLng = new google.maps.LatLng(marker.location.latitude, marker.location.longitude);
      bounds.extend(latLng);
    }
    _marker.nguiMapComponent.openInfoWindow('info-window', _marker);

    if (marker && marker.location && marker.location.paths) {
      marker.location.paths.forEach((path) => {
        if (path.lat && path.lng) {
          bounds.extend(new google.maps.LatLng(path.lat, path.lng));
        }
      });
    } else if (this.map && (typeof this.map.setCenter === 'function') && latLng) {
      this.map.setCenter(latLng);
    }

    if (this.map) {
      this.map.fitBounds(bounds);
      let currentZoom = this.map.getZoom();
      if (!marker || !marker.locations || !marker.location.paths) {
        this.map.setZoom(currentZoom - 5);
      } else {
        this.map.setZoom(currentZoom - 3);
      }
    }
  }

  mapLocations() {
    if (typeof google !== 'undefined') {
      let bounds = new google.maps.LatLngBounds();
      this.markers = [];
      this.polygons = [];
      this.locations.forEach((location) => {
        let latLng = new google.maps.LatLng(location.latitude, location.longitude);
        bounds.extend(latLng);
        this.markers.push({
          location: location, lat: location.latitude, lon: location.longitude
        });
        if (location.geofence) {
          this.polygons.push({ id: location.id, paths: location.paths });
        }
        if (location.paths) {
          location.paths.forEach((path) => {
            if (path.lat && path.lng) {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        }
      });
      if (this.map) { this.map.fitBounds(bounds); }
    }
  }

  selectLocation(location) {
    without(this.locations, location).forEach((_location) => { _location.selected = false; });
    location.selected = true;

    if (location.selected && location.longitude && location.latitude) {
      this.selectedLocation = location;
      this.markers = [
        { id: location.id, lat: location.latitude, lon: location.longitude, location: location }
      ];
      if (location.geofence) {
        this.polygons = [{ id: location.id, paths: location.paths }];
      }
      if (this.map) {
        let bounds = new google.maps.LatLngBounds();
        let latLng = new google.maps.LatLng(location.latitude, location.longitude);
        bounds.extend(latLng);

        if (location.paths) {
          location.paths.forEach((path) => {
            if (path.lat && path.lng) {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        } else if (latLng && typeof this.map.setCenter === 'function') {
          this.map.setCenter(latLng);
        }

        this.map.fitBounds(bounds);
        if (!location.paths) {
          let currentZoom = this.map.getZoom();
          this.map.setZoom(currentZoom - 3);
        }
      }
    } else {
      this.selectedLocation = null;
      this.markers = [];
      this.polygons = [];
    }
  }

  editLocation(location): void {
    this.router.navigate(
      ['/locations/' + location.id + '/edit'],
      { queryParams: { returnTo: '/locations' } }
    );
  }

  /**
   * refresh/reload the location table on any action performed like
   * add, edit, remove
   * It also refreshed the map.
   */
  refreshTable(): void {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
    this.locationTable.getRecords({ ...this.locationTableConfig['query'], ...query });
    this.getLocations(query, false, null);
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
