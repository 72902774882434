"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./map-status-marker.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./map-status-marker.component");
var styles_MapStatusMarkerComponent = [i0.styles];
var RenderType_MapStatusMarkerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapStatusMarkerComponent, data: {} });
exports.RenderType_MapStatusMarkerComponent = RenderType_MapStatusMarkerComponent;
function View_MapStatusMarkerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "map-element driver-image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.locationUpdate.driver.image, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_MapStatusMarkerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "map-element"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.markerBackground; _ck(_v, 1, 0, currVal_0); }); }
function View_MapStatusMarkerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "map-element"], ["src", "assets/img/map/truck-marker-red.png"]], null, null, null, null, null))], null, null); }
function View_MapStatusMarkerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapStatusMarkerComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locationUpdate.expired; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MapStatusMarkerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapStatusMarkerComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapStatusMarkerComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["expired", 2]], null, 0, null, View_MapStatusMarkerComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.locationUpdate.driver && _co.locationUpdate.driver.image); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.locationUpdate.expired; var currVal_2 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_MapStatusMarkerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "map-element"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.markerBackground; _ck(_v, 1, 0, currVal_0); }); }
function View_MapStatusMarkerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapStatusMarkerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapStatusMarkerComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.markerType === "driver-initials"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.markerType === "truck-number"); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_MapStatusMarkerComponent_0 = View_MapStatusMarkerComponent_0;
function View_MapStatusMarkerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "map-status-marker", [], null, null, null, View_MapStatusMarkerComponent_0, RenderType_MapStatusMarkerComponent)), i1.ɵdid(1, 638976, null, 0, i3.MapStatusMarkerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapStatusMarkerComponent_Host_0 = View_MapStatusMarkerComponent_Host_0;
var MapStatusMarkerComponentNgFactory = i1.ɵccf("map-status-marker", i3.MapStatusMarkerComponent, View_MapStatusMarkerComponent_Host_0, { locationUpdate: "locationUpdate", markerType: "markerType", markerStatus: "markerStatus" }, {}, []);
exports.MapStatusMarkerComponentNgFactory = MapStatusMarkerComponentNgFactory;
