<div class="job-summary-container">
  <div class="job-summary" *ngIf="!statsLoading && stats">
    <div class="dispatched-count">
      <h2 translate>TRUCKS DISPATCHED TODAY</h2>
      <span translate>{{ stats.dispatchedDriversCount }} of {{ stats.numTrucks }}</span>
    </div>
    <div class="collaborator-count">
      <h2 translate>TOTAL # COLLABORATORS</h2>
      <span>{{ stats.sharesCount || 0 }}</span>
    </div>
    <div class="trucks-count">
      <h2 translate>REQUESTED / CONFIRMED AMOUNT</h2>
      <span>{{ stats.requestedAmount || stats.dailyDeliveryTarget || 0 }} / {{ stats.confirmedAmount || 0 }} {{stats.requestedUnit?.name}}</span>
    </div>
    <div class="rates">
      <h2 translate>INVOICE RATE</h2>
      <span class="value edit-link" (click)="openEditShare('invoice')">
        {{ (jobEvent.rate || 0) | currency:'USD':'symbol':'1.2-4' }} / 
      </span>
      <span class="value edit-link" (click)="openEditShare('haul')">
        {{ (jobEvent.haulRate || 0) | currency:'USD':'symbol':'1.2-4' }}
      </span>
    </div>
    <div class="load-count" *ngIf="stats.invoiceType === 'hour'">
      <h2 [ngPlural]="stats[totalHoursField]">
        <ng-template ngPluralCase="=0" translate>HOURS TO DATE</ng-template>
        <ng-template ngPluralCase="=1" translate>HOUR TO DATE</ng-template>
        <ng-template ngPluralCase="other" translate>HOURS TO DATE</ng-template>
      </h2>
      <span>{{ stats[totalHoursField] | number:'1.2-2' }}</span>
    </div>
    <div class="load-count">
      <h2 [ngPlural]="stats.totalLoads">
        <ng-template ngPluralCase="=0" translate>TOTAL LOADS</ng-template>
        <ng-template ngPluralCase="=1" translate>TOTAL LOADS</ng-template>
        <ng-template ngPluralCase="other" translate>TOTAL LOADS</ng-template>
      </h2>
      <span>{{ stats.totalLoads }}</span>
    </div>
    <div class="load-weight" *ngIf="stats.invoiceType === 'weight'">
      <h2 [ngPlural]="stats.totalTons">
        <ng-template ngPluralCase="=0" translate>{{ stats.invoiceWeightUnit }}S TO DATE</ng-template>
        <ng-template ngPluralCase="=1" translate>{{ stats.invoiceWeightUnit }} TO DATE</ng-template>
        <ng-template ngPluralCase="other" translate>{{ stats.invoiceWeightUnit }}S TO DATE</ng-template>
      </h2>
      <span>{{ stats.totalTons }}</span>
    </div>
  </div>
</div>
