<form #filterPunchCards="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Punch Cards</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group start-date" fxFlex="50">
          <label translate>From</label>
          <date-range-picker [selectedDates]="[model.startDate]"
                             (dateChanged)="onDateChanged($event, 'startDate')">
          </date-range-picker>
        </div>
        <div class="field-group end-date" fxFlex="50">
          <label translate>To</label>
          <date-range-picker [selectedDates]="[model.endDate]"
                             (dateChanged)="onDateChanged($event, 'endDate')">
          </date-range-picker>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group punch-card-state" fxFlex="33">
          <label attr.data-label="{{ 'Uninvoiced' | translate }}" class="toggle-label">
            <input type="checkbox" name="uninvoiced" checked="{model.uninvoiced}" [(ngModel)]="model.uninvoiced" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>


      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group job-name" fxFlex="100">
          <label translate>Job</label>
          <ruckit-dropdown [config]="jobDropdownConfig"
            [selectedOption]="model.job && model.job.id"
            (onSelect)="onSelect('job', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group project-name" fxFlex="100">
          <label translate>Project</label>
          <ruckit-dropdown [config]="projectDropdownConfig"
            [selectedOption]="model.project && model.project.id"
            (onSelect)="onSelect('project', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group customer-name" fxFlex="100">
          <label translate>Customer</label>
          <ruckit-dropdown [config]="customerDropdownConfig"
            [selectedOption]="model.customer && model.customer.id"
            (onSelect)="onSelect('customer', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group driver-name" fxFlex="100">
          <label translate>Driver</label>
          <ruckit-dropdown [config]="driverDropdownConfig"
            [selectedOption]="model.driver && model.driver.id"
            (onSelect)="onSelect('driver', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group truck-name" fxFlex="100">
          <label translate>Truck</label>
          <ruckit-dropdown [config]="truckDropdownConfig"
            [selectedOption]="model.truck && model.truck.id"
            (onSelect)="onSelect('truck', $event)">
          </ruckit-dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group carrier-name" fxFlex="100">
          <label translate>Carrier</label>
          <ruckit-dropdown [config]="carrierDropdownConfig"
            [selectedOption]="model.carrier && model.carrier.id"
            (onSelect)="onSelect('carrier', $event)">
          </ruckit-dropdown>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>
