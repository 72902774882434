<div class="container">
  <div class="driver-info">
    <div class="timeline-list-header">
      <p class="smaller" translate>Driver Details</p>
    </div>
    <div class="row-header">
      <div class="details" *ngIf="driver && driver.name">
        <div class="text" (contextmenu)="openContextMenu($event, driver.id)">
          <p class="smaller">{{ driver.name }}</p>
          <p class="description" *ngIf="driver.truck">{{ driver.truck.name }} - {{ driver.truck.truckType.name }}</p>
          <p class="description" *ngIf="driver.carrier">{{ driver.carrier.name }}</p>
        </div>
        <div class="info"><mat-icon matTooltip="{{ 'Scheduled Start' | translate }}
                                                {{ 'Time' | translate }} - {{ driverStart | date: 'h:mma' }} | {{ 'Date' | translate }} - {{ driverStart | moment: 'MMM Do, YYYY' }}"
                                    matTooltipPosition="above"
                                    matTooltipClass="ruckit-tooltip">info</mat-icon></div>
      </div>
    </div>
  </div>
  <timeline [rows]="timelineData" [range]="timeRange" [showVoided]="showVoided"
            [timelineConfig]="timelineConfig" [timeInterval]="timeInterval"
            [(selectedTime)]="selectedTime" [playing]="playing" (onRefresh)="onRefresh()"></timeline>
</div>

<app-driver-context-menu *ngIf="contextMenuOpened" [contextMenuEventSubject]="contextMenuEventSubject">
</app-driver-context-menu>
