import { timer as observableTimer } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { find as _find } from 'lodash';

import { Checkin } from '../checkins/checkin';
import { InvoiceService } from  './invoice.service';
import { TripService } from '../trips/trip.service';
import { PunchCardService } from '../punch-cards/punch-card.service';
import { SurchargeService } from '../surcharges/surcharge.service';

@Component({
  selector: 'ruckit-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [InvoiceService, TripService, PunchCardService, SurchargeService]
})
export class InvoiceComponent implements OnInit, OnDestroy {
  invoiceId;
  title = 'Invoice';
  invoiceHTML;
  loading = false;
  loadingTrips = false;
  loadingCards = false;
  loadingSurcharges = false;
  returnTo: string;
  errors = [];
  invoice;
  trips = [];
  punchCards = [];
  surcharges = [];
  tickets = [];
  tripReq;
  punchCardReq;
  surchargeReq;
  tripCount = 0;
  punchCardCount = 0;
  surchargeCount = 0;
  ticketCount = 0;
  sortDirection = 'asc';
  showTickets = false;
  private indexingTimerSub;
  private indexingTimer;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private invoiceService: InvoiceService,
    private tripService: TripService,
    private punchCardService: PunchCardService,
    private surchargeService: SurchargeService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.forEach((params: Params) => {
      this.returnTo = params['returnTo'];
    });
    this.route.params.forEach((params: Params) => {
      this.invoiceId = params['id'];
      this.getInvoice(this.invoiceId);
    });

    this.loadingTrips = true;
    this.loadingCards = true;
    this.loadingSurcharges = true;

    this.indexingTimer = observableTimer(1000, 500);
    this.indexingTimerSub = this.indexingTimer.subscribe(t => {
      this.indexRecords();
    });
  }

  ngOnDestroy() {
    if (this.indexingTimerSub) {
      try {
        this.indexingTimerSub.unsubscribe();
        this.indexingTimer = null;
      } catch (e) {
        this.indexingTimerSub = null;
        this.indexingTimer = null;
      }
    }
  }

  getInvoice(id) {
    this.invoiceService.getPublicInvoice(id).subscribe((invoice) => {
      this.title = invoice.title;

      if (invoice.html && invoice.sent) {
        this.openInvoice(invoice);
      } else {
        this.invoice = invoice;
        this.getTrips();
        this.getPunchCards();
        this.getSurcharges();
      }
      this.loading = false;
    }, err => {
      this.errors = err;
    });
  }

  openInvoice(invoice) {
    window.open(invoice.html);
  }

  openReturnTo() {
    if (this.returnTo) {
      this.router.navigate([this.returnTo]);
    } else {
      this.router.navigate(['/invoices']);
    }
  }

  getTrips(query = {}) {
    this.loadingTrips = true;
    if (this.tripReq) { this.tripReq.unsubscribe(); }

    this.tripReq = this.tripService.getCondensedTrips({
      invoice: this.invoice.id,
      page_size: 100
    }).subscribe(trips => {
      this.trips = trips;
      this.captureCheckins(trips);
      this.getNextTrips();
      this.tripCount = this.tripService.count;
    }, err => {
      this.errors = err;
      this.loadingTrips = false;
    });
  }

  getNextTrips() {
    if (this.tripService.nextUri) {
      this.loading = true;
      this.tripService.getNextCondensed().subscribe(trips => {
        this.trips = this.trips.concat(trips);
        this.captureCheckins(trips);
        if (this.tripService.nextUri) {
          this.getNextTrips();
        } else {
          this.loadingTrips = false;
        }
      }, err => {
        this.errors = err;
        this.loadingTrips = false;
      });
    } else {
      this.loadingTrips = false;
    }
  }

  getPunchCards(query = {}) {
    this.loadingCards = true;
    if (this.punchCardReq) { this.punchCardReq.unsubscribe(); }

    this.punchCardReq = this.punchCardService.getCondensedPunchCards({
      invoice: this.invoice.id,
      page_size: 100
    }).subscribe(punchCards => {
      this.punchCards = punchCards;
      this.captureCheckins(punchCards, 'punchCards');
      this.getNextPunchCards();
      this.punchCardCount = this.punchCardService.count;
    }, err => {
      this.errors = err;
      this.loadingCards = false;
    });
  }

  getNextPunchCards() {
    if (this.punchCardService.nextUri) {
      this.loadingCards = true;
      this.punchCardService.getNextCondensed().subscribe(punchCards => {
        this.punchCards = this.punchCards.concat(punchCards);
        this.captureCheckins(punchCards, 'punchCards');
        if (this.punchCardService.nextUri) {
          this.getNextPunchCards();
        } else {
          this.loadingCards = false;
        }
      }, err => {
        this.errors = err;
        this.loadingCards = false;
      });
    } else {
      this.loadingCards = false;
    }
  }

  getSurcharges(query = {}) {
    this.loadingSurcharges = true;
    if (this.surchargeReq) { this.surchargeReq.unsubscribe(); }

    this.surchargeReq = this.surchargeService.list({
      invoice: this.invoice.id,
      page_size: 100
    }).subscribe(surcharges => {
      this.surcharges = surcharges;
      this.getNextSurcharges();
      this.surchargeCount = this.surchargeService.count;
    }, err => {
      this.errors = err;
      this.loadingSurcharges = false;
    });
  }

  getNextSurcharges() {
    if (this.surchargeService.nextUri) {
      this.loadingSurcharges = true;
      this.surchargeService.listNext().subscribe(surcharges => {
        this.surcharges = this.surcharges.concat(surcharges);
        if (this.surchargeService.nextUri) {
          this.getNextSurcharges();
        } else {
          this.loadingSurcharges = false;
        }
      }, err => {
        this.errors = err;
        this.loadingSurcharges = false;
      });
    } else {
      this.loadingSurcharges = false;
    }
  }

  sort(records, property): void {
    switch (records) {
      case 'trips': {
        this.trips = this.trips && this.trips.sort(function (a, b) {
          return (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0);
        });
        this.trips = this.sortDirection === 'asc' ? this.trips : this.trips.reverse();
        break;
      }
      case 'cards': {
        this.punchCards = this.punchCards && this.punchCards.sort(function (a, b) {
          return (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0);
        });
        this.punchCards = this.sortDirection === 'asc' ? this.punchCards : this.punchCards.reverse();
        break;
      }
      case 'surcharges': {
        this.surcharges = this.surcharges && this.surcharges.sort(function (a, b) {
          return (a[property] > b[property]) ? 1 : ((b[property] > a[property]) ? -1 : 0);
        });
        this.surcharges = this.sortDirection === 'asc' ? this.surcharges : this.surcharges.reverse();
        break;
      }
    }
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  }

  indexRecords(): void {
    if (this.loadingTrips || this.loadingCards || this.loadingSurcharges) {
      return;
    }
    let indexProperty = 1;
    for (let index = 0; index < this.trips.length; index++) {
      this.trips[index].index = indexProperty;
      indexProperty += 1;
    }
    for (let index = 0; index < this.punchCards.length; index++) {
      this.punchCards[index].index = indexProperty;
      indexProperty += 1;
    }
    for (let index = 0; index < this.surcharges.length; index++) {
      this.surcharges[index].index = indexProperty;
      indexProperty += 1;
    }
    for (let index = 0; index < this.tickets.length; index++) {
      if (this.tickets[index].kind === 'punch card') {
        if (this.tickets[index].checkin1 !== undefined) {
          let punchCard = _find(this.punchCards, { id: this.tickets[index].checkin1.index });
          this.tickets[index].checkin1.index = punchCard && punchCard.index;
        } else {
          this.tickets[index].checkin1.index = '';
        }
      } else {
        if (this.tickets[index].checkin1 !== undefined) {
          let trip = _find(this.trips, { id: this.tickets[index].checkin1.index });
          this.tickets[index].checkin1.index = trip && trip.index;
        } else {
          this.tickets[index].checkin1.index = '';
        }

        if (this.tickets[index].checkin2 !== undefined && this.tickets[index].checkin2.index !== undefined) {
          let trip = _find(this.trips, { id: this.tickets[index].checkin2.index });
          this.tickets[index].checkin2.index = trip && trip.index;
        } else {
          this.tickets[index].checkin2.index = '';
        }
      }
    }

    if (this.indexingTimerSub) {
      try {
        this.indexingTimerSub.unsubscribe();
        this.indexingTimer = null;
      } catch (e) {
        this.indexingTimerSub = null;
        this.indexingTimer = null;
      }
    }
  }

  captureCheckins(records, type = 'trips') {
    records = [].concat.apply([], records);
    let tickets = [];
    if (type === 'trips') {
      tickets = records.map((record) => {
        let loadingCheckin = new Checkin({
          index: record.id,
          kind: 'loading',
          ticketImage: record.loadingTicketImage,
          ticketImageKey: record.loadingTicketImageKey,
          ticketNumber: record.loadingTicketNumber,
          weight: record.loadingWeight,
          invoiceWeightUnit: record.invoiceWeightUnitDisplay,
          haulWeightUnit: record.haulWeightUnitDisplay,
          date: record.loadingCompleteDatetime
        });
        let unloadingCheckin = new Checkin({
          index: record.id,
          kind: 'unloading',
          ticketImage: record.unloadingTicketImage,
          ticketImageKey: record.unloadingTicketImageKey,
          ticketNumber: record.unloadingTicketNumber,
          weight: record.unloadingWeight,
          invoiceWeightUnit: record.invoiceWeightUnitDisplay,
          haulWeightUnit: record.haulWeightUnitDisplay,
          date: record.unloadingCompleteDatetime
        });
        return { checkin1: loadingCheckin, checkin2: unloadingCheckin };
      });
    } else if (type === 'punchCards') {
      tickets = records.map((record) => {
        let checkin = new Checkin({
          index: record.id,
          kind: 'punch card',
          ticketImage: record.ticketImage,
          ticketImageKey: record.ticketImageKey,
          ticketNumber: record.ticketNumber,
          weight: record.invoiceQuantity,
          weightWithLabel: record.invoiceQuantityWithLabel,
          invoiceWeightUnit: record.invoiceWeightUnitDisplay,
          haulWeightUnit: record.haulWeightUnitDisplay,
          date: record.jobDate
        });
        return { checkin1: checkin, checkin2: null };
      });
    }
    this.tickets = this.tickets.concat(tickets);
  }

  toggleTickets() {
    this.showTickets = !this.showTickets;
  }
}
