import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CondensedJobEventFilter } from './condensed-job-event.filter';
import { ResourceService } from '../shared/resource.service';
import { HttpClient } from '@angular/common/http';
import { CondensedJobEventFilterSerializer } from './condensed-job-event.filter.serializer';

@Injectable()
export class CondensedJobEventFilterService extends ResourceService<CondensedJobEventFilter> {
  baseUrl = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http, 'jobevents/condensed/', new CondensedJobEventFilterSerializer());
  }
}
