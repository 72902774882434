"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var lodash_1 = require("lodash");
var collaborator_service_1 = require("../collaborators/collaborator.service");
var job_event_share_1 = require("../job-event-shares/job-event-share");
var job_event_1 = require("./../job-events/job-event");
var job_service_1 = require("./../jobs/job.service");
var api_service_1 = require("../shared/api.service");
var Concept;
(function (Concept) {
    Concept["Job"] = "job";
    Concept["JobEventShareId"] = "job_event_share_id";
    Concept["Share"] = "share";
})(Concept = exports.Concept || (exports.Concept = {}));
var EditShareDialogComponent = /** @class */ (function () {
    function EditShareDialogComponent(dialogRef, collaboratorService, jobService, dialog) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.jobService = jobService;
        this.dialog = dialog;
        this.loading = false;
        this.change = 'invoice';
        this.concepts = [];
        this.model = {};
        this.title = 'Change Rates';
        this.unitFor = {};
        this.truckTypes = [];
        this.truckTypeMap = {};
        this.anyTruckType = true;
        this.errors = [];
        this.typeConfig = {
            nameProperty: 'name',
            group: true,
            groupBy: function (type) { return type.group; },
        };
        this.haulTypeOptions = [
            { group: 'Load', value: 'load', label: 'Load', name: 'Load' },
            { group: 'Hour', value: 'hour', label: 'Hour', name: 'Hour' },
            { group: 'Weight', value: 'ton', label: 'Tons', name: 'Ton' },
            { group: 'Weight', value: 'metric-tons', label: 'Metric Tons', name: 'Metric Ton' },
            { group: 'Weight', value: 'pound', label: 'Pounds', name: 'Pound' },
            { group: 'Weight', value: 'cuyds', label: 'Cu. Yds.', name: 'Cubic Yard' },
            { group: 'Weight', value: 'bushel', label: 'Bushels', name: 'Bushel' },
            { group: 'Weight', value: 'bag', label: 'Bags', name: 'Bag' }
        ];
        this.invoiceTypeOptions = lodash_1.clone(this.haulTypeOptions);
        this.allSubscriptionsToUnsubscribe = [];
    }
    EditShareDialogComponent.prototype.ngOnInit = function () {
        this.configureTypeOptions();
        this.determineConcept();
        var pertinentFields = [
            'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
            'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
        ];
        if (this.jobEventShare) {
            this.model = lodash_1.pick(this.jobEventShare, pertinentFields);
        }
        else if (this.jobEvent) {
            this.model = lodash_1.pick(this.jobEvent, pertinentFields);
            this.model['invoiceRate'] = this.jobEvent.rate;
        }
        try {
            this.model['invoiceRate'] = Number.parseFloat(this.model['invoiceRate']).toFixed(2);
        }
        catch (e) { }
        try {
            this.model['haulRate'] = Number.parseFloat(this.model['haulRate']).toFixed(2);
        }
        catch (e) { }
        switch (this.change) {
            case 'trucks': {
                this.title = 'Change Trucks';
                break;
            }
            default: {
                this.title = 'Change Rates';
                break;
            }
        }
    };
    EditShareDialogComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * Determines who is editing the values presented to map the requests to the
     * appropriate endpoints.
     *
     * As the owner of the Job, update the Job and my Share as defined by
     * jobEvent.shareId.
     *
     * All other actions should only be relevant to the JobEventShare ID.
     */
    EditShareDialogComponent.prototype.determineConcept = function () {
        if (this.jobEvent && this.jobEvent.job) {
            var ownerOrganization = this.jobEvent.job.project.ownerOrganization;
            var currentOrganization = this.jobEventShare ? this.jobEventShare.organizationId : ownerOrganization;
            if (ownerOrganization === currentOrganization) {
                this.concepts.push(Concept.JobEventShareId, Concept.Job);
            }
            else {
                this.concepts.push(Concept.Share);
            }
        }
        else if (this.jobEventShare) {
            this.concepts.push(Concept.Share);
        }
    };
    /**
     * Checks job settings to determine possible haul and invoice type options.
     *
     * Once the options have been determined, set the selected option for each.
     */
    EditShareDialogComponent.prototype.configureTypeOptions = function () {
        var job = this.jobEvent ? this.jobEvent : this.jobEventShare;
        if (job && job.job) {
            if (job && job.multipliers && job.multipliers.length > 0) {
                this.haulTypeOptions = this.haulTypeOptions.concat([{
                        group: 'Percentage', value: 'percentage', label: 'Percentage', name: 'Percentage'
                    }]);
                this.invoiceTypeOptions = this.invoiceTypeOptions.concat([{
                        group: 'Percentage', value: 'percentage', label: 'Percentage', name: 'Percentage'
                    }]);
            }
            this.invoiceTypeOption = lodash_1.find(this.invoiceTypeOptions, {
                value: job.invoiceType === 'weight' ? job.invoiceWeightUnit : job.invoiceType
            });
            this.haulTypeOption = lodash_1.find(this.haulTypeOptions, {
                value: job.haulType === 'weight' ? job.haulWeightUnit : job.haulType
            });
            this.unitFor['invoice'] = this.invoiceTypeOption ? this.invoiceTypeOption.value : '';
            this.unitFor['haul'] = this.haulTypeOption ? this.haulTypeOption.value : '';
        }
    };
    /**
     * Combine and subscribe to each API request. Upon finishing, hit the callback
     * with with the updated share response as well as the original jobEvent
     * object for updating associated rate values.
     *
     */
    EditShareDialogComponent.prototype.submit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.submitJobEventShare(), this.submitJob(), function (jobEventShare, job) { return ({ jobEventShare: jobEventShare, job: job }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.callback({
                jobEventShare: result.jobEventShare,
                jobEvent: _this.jobEvent,
                change: _this.change
            });
            _this.dialogRef.close();
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        }));
    };
    /**
     * Upon a weight unit change, update the cooresponding model attribute and
     * mark the form as dirty.
     */
    EditShareDialogComponent.prototype.weightUnitChange = function (tracking, e, form) {
        this.unitFor[tracking] = e.value;
        form.controls[tracking].markAsDirty();
        this.model[tracking + 'Type'] = e.group.toLowerCase();
        switch (e.group) {
            case 'Weight': {
                this.model[tracking + 'WeightUnit'] = e.value;
                break;
            }
        }
    };
    /**
     * When the concept of "Job" is included, update the Job object with the changes.
     *
     * @returns Observable
     */
    EditShareDialogComponent.prototype.submitJob = function () {
        if (this.concepts.includes(Concept.Job)) {
            var model = {
                id: this.jobEvent.job.id,
                numTrucks: this.model.numTrucks,
                rate: this.model.invoiceRate,
                invoiceType: this.model.invoiceType,
                invoiceWeightUnit: this.model.invoiceWeightUnit,
                haulRate: this.model.haulRate,
                haulType: this.model.haulType,
                haulWeightUnit: this.model.haulWeightUnit
            };
            return this.jobService.save(model);
        }
        else {
            return rxjs_1.of({});
        }
    };
    /**
     * Determine which Share ID to use based on the concept and which data to
     * update based on the change type.
     *
     * @returns Observable
     */
    EditShareDialogComponent.prototype.submitJobEventShare = function () {
        var model = this.model;
        if (this.jobEvent || this.jobEventShare) {
            if (this.concepts.includes(Concept.JobEventShareId)) {
                model['id'] = this.jobEvent.shareId;
            }
            else if (this.concepts.includes(Concept.Share)) {
                model['id'] = this.jobEventShare.id;
            }
            else {
                return rxjs_1.of(model);
            }
            return this.collaboratorService.save(null, model);
        }
        else {
            return rxjs_1.of(model);
        }
    };
    return EditShareDialogComponent;
}());
exports.EditShareDialogComponent = EditShareDialogComponent;
