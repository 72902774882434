import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from '../shared/index';
import { DataService } from '../shared/data.service';
import { RuckitForgotPasswordDialogComponent } from '../shared/forgot-password-dialog/forgot-password-dialog.component';

/**
 * Login Component: Displays a login form and allows users to login using their
 * email address and password. Users are also able to select "Forgot Password"
 * and have a Password Reset email sent to their email.
 *
 * @todo Add types for properties
 * @todo Create an ErrorMessage type
 */
@Component({
  selector: 'my-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  loading = false;
  showPassword = false;
  noLocalStorage = false;
  errors = [];
  returnUrl: string;
  returnParams: string;
  public isShowingForgotPwd: boolean

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authenticationService: AuthenticationService,
    public forgotPasswordDialog: MatDialog,
    private fb: FormBuilder
  ) {}

  /**
  * Calls the logout function from {@link AuthenticationService#logout} to
  * ensure that no user is logged in.
  *
  * If a parameter of `returnUrl` has been
  * set, the parts are split at the `?` into {@link #returnUrl} and
  * {@link #returnParams}.
  *
  * If the device does not support
  * [localStorage]{@link https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage}
  * an error message is set and displayed for the user.
  */
  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    if (this.route && this.route.queryParams) {
      this.route.queryParams.subscribe((params: Params) => {
        if (params['returnUrl'] && params['returnUrl'].length) {
          let urlParts = params['returnUrl'].split('?');
          this.returnUrl = urlParts[0];
          this.returnParams = urlParts[1];
        }
      });
    }

    try {
      localStorage.setItem('localStorage', '1');
    } catch (e) {
      this.noLocalStorage = true;
      this.errors.push('This website does not support mobile browsers in Private Browsing mode.');
    }
  }

  /**
   * Calls the login function from {@link AuthenticationService#login} with the
   * entered email and password as arguments.
   *
   * Upon a successful response, the user is
   * navitated to the {@link #returnUrl} (unless it equals `/logout` or `/`), to
   * `/scale` if they are a Scaleit user, and `/jobs/daily` in all other cases.
   *
   * If login was unsuccessful, the user is presented with an error message.
   */
  login(): void {
    this.loading = true;
    this.errors = [];
    this.authenticationService.login(
      this.loginForm.value.email, this.loginForm.value.password
    ).subscribe(result => {
      this.dataService.changeData({
        authenticated: true,
        displaySidebar: this.authenticationService.displaySidebar()
      });
      this.loading = false;
      if (this.returnUrl && this.returnUrl.length && this.returnUrl !== '/logout' && this.returnUrl !== '/') {
        this.ngZone.run(() => this.router.navigate([this.returnUrl]));
      } else if (this.authenticationService.isScaleit()) {
        this.ngZone.run(() => this.router.navigate(['/scale']));
      } else {
        this.ngZone.run(() => this.router.navigate(['/jobs/daily']));
      }
    }, err => {
      this.loading = false;
      // this.errors = parseErrors(err);
      this.errors = ['Unable to log in with provided credentials.'];
    });
  }

  /**
  * Calls the logout function from {@link AuthenticationService#logout} and
  * navigates the user to `/login`
  */
  logout(): void {
    this.loading = true;
    this.authenticationService.logout();
    this.loading = false;
    this.ngZone.run(() => this.router.navigate(['/login']));
  }

  /**
  * Displays the {@link RuckitForgotPasswordDialogComponent} so that the user
  * can request a password reset.
  */
  forgotPassword(): void {
    let dialogRef = this.forgotPasswordDialog.open(RuckitForgotPasswordDialogComponent, {
      autoFocus: true,
      disableClose: true,
      restoreFocus: true,
      hasBackdrop: false,
      panelClass: 'ruckit-modal'
    });

    this.isShowingForgotPwd = true
    dialogRef.componentInstance.model = Object.assign({}, this.loginForm.value);
    dialogRef.beforeClosed().subscribe(result => {
      this.isShowingForgotPwd = false
    });
  }
}
