"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var order_service_1 = require("../order.service");
var shared_1 = require("../../shared");
var TicketReviewDialogComponent = /** @class */ (function () {
    function TicketReviewDialogComponent(orderService, dialog, dialogRef, data) {
        this.orderService = orderService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.errors = [];
        this.saveModel = { id: this.data.ticket.id };
    }
    TicketReviewDialogComponent.prototype.saveDisabled = function () {
        return !!!this.saveModel.plantQc &&
            !!!this.saveModel.plantVt &&
            !!!this.saveModel.roadwayQc &&
            !!!this.saveModel.roadwayVt;
    };
    TicketReviewDialogComponent.prototype.reviewField = function (field, event) {
        this.saveModel[field] = Number(event.target['value']);
    };
    TicketReviewDialogComponent.prototype.save = function () {
        var _this = this;
        this.saveModel['id'] = this.data.ticket.id;
        var reviewReqs = [];
        Object.keys(this.saveModel).forEach(function (key) {
            var _a;
            var field = key === 'plantQc' ? 'plant_qc' : key === 'plantVt' ? 'plant_vt' :
                key === 'roadwayQc' ? 'roadway_qc' : key === 'roadwayVt' ? 'roadway_vt' : 'id';
            if (field !== 'id') {
                reviewReqs.push(_this.orderService.tripService.reviewTicket(_this.saveModel['id'], field, (_a = {}, _a[field] = _this.saveModel[key], _a)).pipe(operators_1.first()));
            }
        });
        rxjs_1.forkJoin(reviewReqs).subscribe(function (tickets) {
            var updatedTicket = _this.data.ticket;
            tickets.forEach(function (ticket) {
                if (ticket.plantQc) {
                    updatedTicket.plantQc = ticket.plantQc;
                    updatedTicket.plantQcReviewer = ticket.plantQcReviewer;
                }
                else if (ticket.plantVt) {
                    updatedTicket.plantVt = ticket.plantVt;
                    updatedTicket.plantVtReviewer = ticket.plantVtReviewer;
                }
                else if (ticket.roadwayQc) {
                    updatedTicket.roadwayQc = ticket.roadwayQc;
                    updatedTicket.roadwayQcReviewer = ticket.roadwayQcReviewer;
                }
                else if (ticket.roadwayVt) {
                    updatedTicket.roadwayVt = ticket.roadwayVt;
                    updatedTicket.roadwayVtReviewer = ticket.roadwayVtReviewer;
                }
            });
            _this.callback(updatedTicket);
            _this.dialogRef.close();
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    return TicketReviewDialogComponent;
}());
exports.TicketReviewDialogComponent = TicketReviewDialogComponent;
