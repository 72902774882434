import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PriceList } from './price-list';
import { PriceListSerializer } from './price-list.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class PriceListService extends ResourceService<PriceList> {
  constructor(http: HttpClient) {
    super(http, 'custompricelists/', new PriceListSerializer());
  }
}
