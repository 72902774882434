"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var DispatchScheduleJobEventGridComponent = /** @class */ (function () {
    function DispatchScheduleJobEventGridComponent() {
        this.loadingProgress = 0;
        this.displayKeys = ['jobName', 'orderNumber', 'orderNumberJobName'];
        this.jobEventCount = { available: 0 };
        this.groupByOptions = ['Market', 'Customer', 'Origin', 'Destination'];
        this.activeGroupBy = 'Customer';
        this.assignedSearch = '';
        this.highlightedJobEvents = [];
        this.selectedJobEventsValue = [];
        this.selectedJobEventsChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DispatchScheduleJobEventGridComponent.prototype, "selectedJobEvents", {
        get: function () { return this.selectedJobEventsValue; },
        set: function (data) {
            this.selectedJobEventsValue = data;
            this.selectedJobEventsChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleJobEventGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes.jobEvents && changes.jobEvents.currentValue) {
            this.setupJobEventList(changes.jobEvents.currentValue, this.activeGroupBy);
        }
    };
    /**
     * Sets up the jobevent groups based on the specified groupBy param
     *
     * @param {DispatchJobEvent[]} jobEvents The jobEvent list to be grouped
     * @param {'Market' | 'Customer' | 'Origin' | 'Destination'} groupBy The selected groupBy option
     */
    DispatchScheduleJobEventGridComponent.prototype.setupJobEventList = function (jobEvents, groupBy) {
        this.jobEventCount = {
            available: jobEvents.length
        };
        this.activeGroupBy = groupBy;
        switch (groupBy) {
            case 'Market':
                this.jobEventList = Array.from(new Set(jobEvents.map(function (j) { return ({
                    id: j.tags && j.tags.length && j.tags[0] && j.tags[0].id,
                    name: j.tags && j.tags.length && j.tags[0] && j.tags[0].name,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) {
                    var filteredJobEvents = jobEvents.filter(function (jobEvent) { return ((jobEvent.tags && jobEvent.tags.length && jobEvent.tags[0] && jobEvent.tags[0].id) === group.id); });
                    return Object.assign(group, {
                        items: filteredJobEvents,
                        assigned: filteredJobEvents.map(function (j) { return (j.assignedLoads); }).reduce(function (a, b) { return (a + b); }),
                        ordered: filteredJobEvents.map(function (j) { return (Number(j.dailyDeliveryTarget)); }).reduce(function (a, b) { return (a + b); })
                    });
                });
                break;
            case 'Customer':
                this.jobEventList = Array.from(new Set(jobEvents.map(function (j) { return ({
                    id: j.customerId,
                    name: j.customer,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) {
                    var filteredJobEvents = jobEvents.filter(function (jobEvent) { return (jobEvent.customerId === group.id); });
                    return Object.assign(group, {
                        items: filteredJobEvents,
                        assigned: filteredJobEvents.map(function (j) { return (j.assignedLoads); }).reduce(function (a, b) { return (a + b); }),
                        ordered: filteredJobEvents.map(function (j) { return (Number(j.dailyDeliveryTarget)); }).reduce(function (a, b) { return (a + b); })
                    });
                });
                break;
            case 'Origin':
                this.jobEventList = Array.from(new Set(jobEvents.map(function (j) { return ({
                    id: j.startLocation,
                    name: j.startLocationName,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) {
                    var filteredJobEvents = jobEvents.filter(function (jobEvent) { return (jobEvent.startLocation === group.id); });
                    return Object.assign(group, {
                        items: filteredJobEvents,
                        assigned: filteredJobEvents.map(function (j) { return (j.assignedLoads); }).reduce(function (a, b) { return (a + b); }),
                        ordered: filteredJobEvents.map(function (j) { return (Number(j.dailyDeliveryTarget)); }).reduce(function (a, b) { return (a + b); })
                    });
                });
                break;
            case 'Destination':
                this.jobEventList = Array.from(new Set(jobEvents.map(function (j) { return ({
                    id: j.endLocation,
                    name: j.endLocationName,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) {
                    var filteredJobEvents = jobEvents.filter(function (jobEvent) { return (jobEvent.endLocation === group.id); });
                    return Object.assign(group, {
                        items: filteredJobEvents,
                        assigned: filteredJobEvents.map(function (j) { return (j.assignedLoads); }).reduce(function (a, b) { return (a + b); }),
                        ordered: filteredJobEvents.map(function (j) { return (Number(j.dailyDeliveryTarget)); }).reduce(function (a, b) { return (a + b); })
                    });
                });
                break;
        }
        this.jobEventList = this.jobEventList.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
        this.filteredJobEventList = lodash_1.cloneDeep(this.jobEventList);
    };
    /**
     * Selects a new groupBy option and re-triggers the jobevent grid setup based on that option
     *
     * @param {'Market' | 'Customer' | 'Origin' | 'Destination'} groupBy The selected groupBy option
     */
    DispatchScheduleJobEventGridComponent.prototype.selectGroupBy = function (groupBy) {
        this.setupJobEventList(this.jobEvents, groupBy);
    };
    /**
     * Returns a set of class names in a single string to be appended to item elements in the item grid
     *
     * @param {DispatchJobEvent} jobevent The jobevent object
     * @returns {string} The class names to be appended to item elements
     */
    DispatchScheduleJobEventGridComponent.prototype.generateItemClassNames = function (jobEvent) {
        var classNames = '';
        if ((Number(jobEvent.dailyDeliveryTarget) - jobEvent.assignedLoads) > 0) {
            classNames += 'red ';
        }
        else if ((Number(jobEvent.dailyDeliveryTarget) - jobEvent.assignedLoads) < 0) {
            classNames += 'black ';
        }
        else if (Number(jobEvent.dailyDeliveryTarget) > 0 && jobEvent.assignedLoads > 0 &&
            (Number(jobEvent.dailyDeliveryTarget) - jobEvent.assignedLoads) === 0) {
            classNames += 'green ';
        }
        return classNames;
    };
    return DispatchScheduleJobEventGridComponent;
}());
exports.DispatchScheduleJobEventGridComponent = DispatchScheduleJobEventGridComponent;
