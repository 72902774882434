"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var punch_cards_component_1 = require("./punch-cards.component");
var new_punch_card_component_1 = require("./new-punch-card.component");
var edit_punch_card_component_1 = require("./edit-punch-card.component");
var routes = [
    { path: '', component: punch_cards_component_1.PunchCardsComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'new', component: new_punch_card_component_1.NewPunchCardComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: 'new/:jobId/:jobEventId', component: new_punch_card_component_1.NewPunchCardComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: ':id/edit', component: edit_punch_card_component_1.EditPunchCardComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var PunchCardsRoutingModule = /** @class */ (function () {
    function PunchCardsRoutingModule() {
    }
    return PunchCardsRoutingModule;
}());
exports.PunchCardsRoutingModule = PunchCardsRoutingModule;
