import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { LocationsComponent } from './locations.component';
import { NewLocationComponent } from './new-location.component';
import { EditLocationComponent } from './edit-location.component';
import { LocationManagerComponent } from './location-manager/location-manager.component';

const routes: Routes = [
  { path: '', component: LocationsComponent, canActivate: [AuthGuard] },
  { path: 'new', component: NewLocationComponent, canActivate: [AuthGuard] },
  { path: 'manager', component: LocationManagerComponent, canActivate: [AuthGuard] },
  { path: 'manager/:view', component: LocationManagerComponent, canActivate: [AuthGuard] },
  { path: 'active', component: LocationManagerComponent, canActivate: [AuthGuard] },
  { path: 'active/:view', component: LocationManagerComponent, canActivate: [AuthGuard] },
  { path: ':id/edit', component: EditLocationComponent, canActivate: [AuthGuard] },
  { path: ':view', component: LocationsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocationsRoutingModule { }
