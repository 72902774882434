import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription, combineLatest as observableCombineLatest } from 'rxjs';
import * as moment from 'moment';
import { clone } from 'lodash';

import { JobEvent } from '../../job-events/job-event';
import { JobEventService } from '../../job-events/job-event.service';

@Component({
  selector: 'mobile-dispatch',
  templateUrl: './mobile-dispatch.component.html',
  styleUrls: ['./mobile-dispatch.component.scss']
})

export class MobileDispatchComponent implements OnInit, OnDestroy {
  externalNavigation = false;
  loading = false;
  errors = [];
  selectedDates: Date[];
  displayedDate: Date;
  jobEvents: JobEvent[] = [];
  jobEventsReq: Subscription;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobEventService: JobEventService,
  ) {}

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        if (result.params['shifts'] === '2') {
          this.externalNavigation = true;
          this.router.navigate([
            '/dispatch', result.params['jobId'], result.params['jobEventId'], 'shifts'
          ]);
        } else if (result.params['jobEventId'] && result.params['jobId']) {
          this.externalNavigation = true;
          this.router.navigate([
            '/dispatch', result.params['jobId'], result.params['jobEventId'], 'assign'
          ]);
        } else if (result.params['jobId']) {
          this.externalNavigation = true;
          this.router.navigate([
            '/dispatch', result.params['jobId']
          ]);
        } else {
          this.loading = true;
          if (result.qparams['date']) {
            let date = moment(result.qparams['date'], 'YYYYMMDD').toDate();
            this.selectedDates = [date || new Date()];
          } else {
            this.selectedDates = [new Date()];
          }
          this.displayedDate = this.selectedDates[0];
          this.getJobEvents();
        }
      })
    );

    if (!this.externalNavigation && (!this.selectedDates || !this.selectedDates.length)) {
      this.selectedDates = [new Date()];
      this.getJobEvents();
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onDateChanged(dates: Date[]): void {
    this.displayedDate = dates && dates.length ? dates[0] : new Date();
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['date'] = moment(this.displayedDate).format('YYYYMMDD');
    this.router.navigate(['/dispatch'], { queryParams: queryParams });
  }

  getJobEvents(query = {}): void {
    this.loading = true;
    this.jobEvents = [];
    if (this.jobEventsReq) { this.jobEventsReq.unsubscribe(); }

    let startDate: Date = new Date();
    startDate.setHours(0, 0, 0, 0);
    if (this.selectedDates && this.selectedDates.length) {
      startDate = this.selectedDates[0];
    } else {
      this.selectedDates = [startDate];
    }
    let endDate: Date = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    this.jobEventsReq = this.jobEventService.getJobEvents({
      shift1_start__gte: startDate && startDate.toISOString(),
      shift1_start__lte: endDate && endDate.toISOString(),
      ordering: 'project__name,name',
      archived: 'False',
      cancelled: 'False',
      exclude_pending: 'True',
      ...query
    }).subscribe(jobEvents => {
      this.jobEvents = jobEvents;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }
}
