"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var driver_service_1 = require("../../drivers/driver.service");
var NoDriverAssignmentsComponent = /** @class */ (function () {
    function NoDriverAssignmentsComponent(driverService, dialog) {
        this.driverService = driverService;
        this.dialog = dialog;
        this.onCreateNewAssignment = new core_1.EventEmitter();
    }
    NoDriverAssignmentsComponent.prototype.ngOnInit = function () {
        this.getDriverData();
    };
    NoDriverAssignmentsComponent.prototype.getDriverData = function () {
        var _this = this;
        this.driverService.get(this.driverId).subscribe(function (driver) {
            _this.openDialog(driver);
        });
    };
    NoDriverAssignmentsComponent.prototype.openDialog = function (driver) {
        this.dispatchDialog = this.dialog.open(this.dialogRef, {
            width: '430px',
            height: '250px',
            data: driver,
        });
    };
    NoDriverAssignmentsComponent.prototype.onCreateNewAssignmentClick = function (driver) {
        this.onCreateNewAssignment.emit(driver);
        this.dispatchDialog.close();
    };
    return NoDriverAssignmentsComponent;
}());
exports.NoDriverAssignmentsComponent = NoDriverAssignmentsComponent;
