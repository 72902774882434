import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'ruckit-trip-times',
  templateUrl: './trip-times.component.html',
  styleUrls: ['./trip-times.component.scss'],
  providers: []
})
export class TripTimesComponent implements OnInit {
  @Input() driver: any = {};
  @Input() trip: any = null;
  @Input() trips: any = null;
  jobEvent: string = null;
  loading = true;
  errors = [];
  tripsReq;

  constructor(
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.trips) {
      return;
    }
    if (this.trip) {
      this.driver = this.trip.driver;
      this.jobEvent = this.trip.jobEvent && this.trip.jobEvent.id;
      this.trips = [this.trip];
    }
  }
}
