"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var ExpenseFilter = /** @class */ (function () {
    function ExpenseFilter(expenseFilterInfo) {
        expenseFilterInfo = camelcaseKeysDeep(expenseFilterInfo);
        this.id = expenseFilterInfo.id;
        this.name = expenseFilterInfo.name;
    }
    return ExpenseFilter;
}());
exports.ExpenseFilter = ExpenseFilter;
