import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';

// rxjs
import {
  Observable, Subscription, combineLatest as observableCombineLatest, forkJoin, Subject
} from 'rxjs';
import { first } from 'rxjs/operators';

// libraries
import {
  uniq, cloneDeep, clone, get, remove, includes, find as _find, extend, filter,
  reject
} from 'lodash';
import * as moment from 'moment-timezone';
const camelcaseKeysDeep = require('camelcase-keys-deep');

// angular material
import { MatDialog, MatDialogRef, MatCheckboxChange } from '@angular/material';

// services
import { PreferenceService } from '../../preferences/preference.service';
import { JobEventService } from '../../job-events/job-event.service';
import { DispatchService } from '../dispatch.service';
import { AssignmentService } from '../../assignments/assignment.service';
import { JobService } from '../../jobs/job.service';
import { JobLoadService } from './job-load.service';
import { ConfirmChangeJobLoadsService } from './confirm-change-job-loads.service';

// types
import { Preference } from '../../preferences/preference';
import { Assignment } from '../../assignments/assignment';
import { Driver } from '../../drivers/driver';
import { User } from '../../users/user';
import { Job } from '../../jobs/job';
import { JobEvent } from '../../job-events/job-event';
import { Tag } from '../../tags/tag';
import { Truck } from '../../trucks/truck';
import { Slot } from '../../assignments/slot';
import { Shift } from '../../assignments/shift';

// serializers
import { AssignmentSerializer } from '../../assignments/assignment.serializer';
import { SlotSerializer } from '../../assignments/slot.serializer';
import { ShiftSerializer } from '../../assignments/shift.serializer';

// components
import { NewDaysCollaborationDialogComponent } from '../../collaborators/new-days-collaboration-dialog.component';
import { AssignTruckDialogComponent } from '../../drivers/assign-truck-dialog/assign-truck-dialog.component';
import { ConfirmDriversDialogComponent } from '../confirm-drivers-dialog.component';
import { AssignmentErrorDialogComponent } from '../../messages/assignment-error-dialog.component';
import { AuthenticationService, DropdownComponent } from '../../shared/index';
import { AutoAssignConflictDialogComponent } from '../../assignments/auto-assign-conflict-dialog.component';
import { EditJobDialogComponent } from '../../jobs/edit-job-dialog.component';
import { AvailableDriversComponent } from '../../drivers/available-drivers/available-drivers.component';
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import { MoveAssignmentsDialogComponent } from '../../assignments/move-assignments/move-assignments-dialog.component';
import { JobLoad } from './job-load';

// constants
import { DEFAULT_DIALOG_SIZE } from '../../app.constants';

@Component({
  selector: 'dispatch-by-job',
  templateUrl: './dispatch-by-job.component.html',
  styleUrls: ['./dispatch-by-job.component.scss'],
  providers: [ JobLoadService ]
})

export class DispatchByJobComponent implements OnInit, OnDestroy {
  @Input() displayedColumns = [ 'select', 'loadNumber', 'loadTime', 'truck', 'driver' ];
  lastCreatedAssignments: Subject<Assignment[]> = new Subject<Assignment[]>();
  
  hasLoadListsEnabled = false;
  loadListSelection: SelectionModel<any> = new SelectionModel<any>(true, []);
  loadListSelectAll = false;
  assigningDriverToLoad = false;
  removingDriverLoadAssignments = false;
  loadToAssign: JobLoad = null;
  skipReload = false;
  loadListLegend = [
    { name: 'Received', color: '#ffd667' },
    { name: 'Accepted', color: '#27af5e' },
    { name: 'Rejected', color: '#be2f1f' }
  ];

  selectedShift: Shift;
  view = 'assignments';
  loading = false;
  errors = [];
  tempModel = [];
  user: User;
  job: Job;
  jobEvent: JobEvent;
  jobEvents: JobEvent[] = [];
  jobs: Job[] = [];
  loadList: JobLoad[] = [];
  jobId: string;
  confirmDialog: MatDialogRef<any>;

  preference: Preference;
  preferenceKey = 'GeneralUserPreferences';
  orgPreference: Preference;
  selectedTags: Tag[] = [];
  preselectTags = false;

  assignmentsReq: Subscription;
  jobEventsReq: Subscription;
  jobEventReq: Subscription;
  jobsReq: Subscription;
  loadListReq: Subscription;

  jobsLoading = false;
  jobsLoadingNext = false;
  jobEventsLoading = false;
  jobEventsLoadingNext = false;
  assignmentsLoading = false;
  bulkSaveLoading = false;
  driversLoading = false;
  autoAssignLoading = false;
  dispatchLoading = false;

  selectedDrivers: Driver[] = [];
  selectedDriverCount = 0;
  truckOptionsForModal: Truck[] = [];
  jobEventsDropdownConfig = {
    nameProperty: 'name',
    showLoading: true
  };

  actionsDropdownOptions = [];
  actionsDropdownConfig = {
    nameProperty: 'name',
    showLoading: false
  };

  jobDropdownConfig = {
    searchable: true,
    group: true,
    showLoading: true,
    nameProperty: 'name',
    groupProperty: 'project.name',
    sortBy: 'project__name,name,start_date',
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    service: JobService,
    query: {}
  };

  @ViewChild('actionsDropdown', { static: false }) actionsDropdown: DropdownComponent;
  @ViewChild('jobEventsDropdown', { static: true }) jobEventsDropdown: DropdownComponent;
  @ViewChild('availableDrivers', { static: false }) availableDrivers: AvailableDriversComponent;
  @ViewChild('dropdownWrapper', { static: false }) dropdownWrapper: RuckitDropdownComponent;
  @ViewChild('selectAllAssignments', { static: false }) selectAllAssignments: ElementRef;

  drivers = {
    items: [],
    errors: [],
    loading: false,
    search: ''
  };
  driverCount: number = null;
  shifts: Shift[] = [];
  draggedDriver;
  lastDraggedDriver;
  loadStatus = '';
  dayEstimate = '';
  hasAllDriversEnabled = false;
  start: Date;
  sortBy = '';
  sortAsc = true;
  
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  saveJobDayCallback = (e) => {
    this.jobEvent = e.jobEvent;
    this.deselectAll();
    this.reload(true);
  }
  autoAssignConflictCallback = (e) => {
    if (e) { this.autoAssign(e); }
    this.autoAssignLoading = false;
    this.reload();
  }
  moveAssignmentsCallback = (e) => {
    this.deselectAll();
    this.reload();
  }
  cloneAssignmentsCallback = (e) => {
    this.deselectAll();
  }
  showDriverInList = (driver: Driver) => {
    return !includes(this.allSlots().map(slot => get(slot, 'driver.id')), driver.id);
  }
  allSlots() {
    if (this.shifts.length) {
      return this.shifts.reduce((slots: Slot[], shift: Shift) => {
        return uniq(slots.concat(shift.slots));
      }, []);
    } else {
      return [];
    }
  }
  saveJobDaysCallback = (daysAdded) => {
    if (this.jobEvent && this.jobEvent.canShare) {
      this.openNewDaysDialog(daysAdded);
    }
    this.reload(true);
  }

  constructor(
    private route: ActivatedRoute,
    private preferenceService: PreferenceService,
    private dispatchService: DispatchService,
    private jobEventService: JobEventService,
    private assignmentService: AssignmentService,
    private authenticationService: AuthenticationService,
    private location: Location,
    public dialog: MatDialog,
    private jobLoadService: JobLoadService,
    private confirmChangeJobLoadsService: ConfirmChangeJobLoadsService,
  ) { }

  ngOnInit() {
    this.start = new Date();
    this.start.setHours(0, 0, 0, 0);
    this.jobDropdownConfig.query = {
      jobevents__end__gte: this.start.toISOString()
    };
    this.user = this.authenticationService.user();
    this.getPreferences();
    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    this.hasLoadListsEnabled = this.authenticationService.hasLoadListsEnabled();
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        if (result && result.params) {
          this.jobId = result.params['jobId'];
        }

        if (result && result.params['jobEventId']) {
          this.getJobEvent(result.params['jobEventId']);
        } else {
          this.getJobEvents({}, false, true);
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
    if (this.loadListReq && typeof this.loadListReq.unsubscribe === 'function') {
      this.loadListReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getPreferences(): void {
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: this.user && this.user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preference = preferences[0];
      }
    });

    this.preferenceService.list({
      name: 'GeneralOrganizationPreferences',
      type: 'organization',
      organization: this.user && this.user.organization && this.user.organization.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.orgPreference = preferences[0];
        if (this.orgPreference && this.orgPreference.blob) {
          const tagPreference = this.orgPreference.blob['availableDrivers[jobBasedTagSelection]'];
          if (tagPreference) {
            this.preselectTags = tagPreference === 'pre-select' ? true : false;
          }
        }
      }
    });
  }

  getJobEvent(jobEventId: string): void {
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
    this.jobEventReq = this.jobEventService.getJobEvent(jobEventId).subscribe(jobEvent => {
      this.jobEvent = jobEvent;
      // this.setJobEvent(jobEvent);
      this.getJobEvents();
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  getJobEvents(query = {}, next = false, selectFirst = false): void {
    this.loading = true;
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }

    let jobEventServiceReq = this.jobEventService.getJobEvents({
      ordering: 'shift1_start',
      page_size: 25,
      job: this.job && this.job.id || this.jobId,
      end__gte: this.start.toISOString(),
      ...query
    });
    if (next) {
      this.jobEventsLoadingNext = true;
      jobEventServiceReq = this.jobEventService.listNext();
    } else {
      this.jobEventsLoading = true;
    }

    this.jobEventsReq = jobEventServiceReq && jobEventServiceReq.subscribe(jobEvents => {
      this.jobEvents = next ? this.jobEvents.concat(jobEvents) : jobEvents;
      if (jobEvents.length > 0 && selectFirst) {
        this.jobEvent = jobEvents[0];
      }
      if (this.jobEvent) {
        let jobEvent = _find(this.jobEvents, { id: this.jobEvent.id });
        if (jobEvent) {
          this.jobEvents = reject(this.jobEvents, jobEvent);
        } else {
          jobEvent = this.jobEvent;
        }
        this.jobEvents.unshift(jobEvent);
        if (this.jobEventsDropdown) {
          this.jobEventsDropdown.selectedOption = jobEvent;
        }
        this.setJobEvent(this.jobEvent);
      }
      this.jobEventsLoadingNext = false;
      this.jobEventsLoading = false;
      if (this.hasLoadListsEnabled) { this.getLoadList(this.jobEvent.id); }
      this.getLoadList(this.jobEvent.id);
    }, err => {
      this.errors = err;
      this.jobEventsLoading = false;
      this.jobEventsLoadingNext = false;
      this.loading = false;
    });
    if (!jobEventServiceReq) { this.jobEventsLoadingNext = false; }
  }

  getLoadList(jobEventId: string): void {
    if (this.loadListReq && typeof this.loadListReq.unsubscribe === 'function') {
      this.loadListReq.unsubscribe();
    }
    this.jobLoadService.getLoads(this.jobEvent.id).subscribe(results => {
      this.loadList = results;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  clickSelectAllLoads(event: MouseEvent): void {
    let checked = (<HTMLInputElement>event.target).checked;
    this.toggleAllLoads(checked);
  }

  toggleAllLoads(checked: boolean): void {
    this.loadListSelectAll = checked;
    this.loadList.forEach(load => {
      if (this.loadListSelectAll) {
        this.loadListSelection.select(<any>load);
      } else {
        this.loadListSelection.deselect(<any>load);
      }
    });
  }

  toggleLoadListSelection(row, event: MatCheckboxChange): void {
    if (!event.checked) {
      this.loadListSelection.deselect(<any>row);
    } else {
      this.loadListSelection.select(<any>row);
    }
    this.loadListSelectAll = this.loadListSelection.selected.length === this.loadList.length;
  }

  setSelectedAction(option): void {
    switch (option.name) {
      case 'Edit Job Days':
        this.openJobDays();
        break;
    }
    this.actionsDropdown.deselectAll();
  }

  openJobDays(): void {
    const dialog = this.dialog.open(EditJobDialogComponent, {
      width: '320px',
      data: { job: this.job }
    });
    if (dialog) {
      dialog.componentInstance.callback = this.saveJobDaysCallback;
    }
  }

  openNewDaysDialog(daysAdded): void {
    if (daysAdded && daysAdded.length > 0) {
      const dialog = this.dialog.open(NewDaysCollaborationDialogComponent, {
        width: '850px'
      });
      if (dialog) {
        dialog.componentInstance.jobEvent = this.jobEvent;
        dialog.componentInstance.daysAdded = daysAdded;
      }
    }
  }

  addActionButtons(): void {
    if (this.jobEvent && this.jobEvent.canEdit) {
      if (!_find(this.actionsDropdownOptions, { name: 'Edit Job Days' })) {
        this.actionsDropdownOptions.push({ name: 'Edit Job Days', button: true });
      }
    }
  }

  jobEventDropdownSearch(term: string): void {
    this.getJobEvents({ search: term });
  }

  dispatchDisabled(): boolean {
    let slots = filter(this.allSlots(), 'assignment');
    return !_find(slots, slot => {
      return slot.driver && slot.driver.id && !get(slot, 'assignment.dispatched');
    });
  }

  sendDispatch(): void {
    this.dispatchLoading = true;
    let bulkUpdateReqs = this.bulkUpdateAssignments();

    forkJoin(bulkUpdateReqs).subscribe(bulkUpdates => {
      this.processBulkUpdates(bulkUpdates);

      this.dispatchService.save({
        jobEvent: this.jobEvent.id,
        notify_new: true
      }).subscribe(res => {
        this.jobEvent.lastDispatchedTime = res['lastDispatchTime'] || res['lastDispatchedTime'];
        this.jobEvent.lastDispatchedBy = res['lastDispatchedBy'];

        if (this.shifts.length) {
          this.shifts.forEach(shift => {
            shift.slots.forEach(slot => {
              if (slot.driver) { slot.assignment.dispatched = true; }
            });
          });
        }

        this.reload();
      }, err => {
        this.errors = err;
      }, () => {
        this.dispatchLoading = false;
      });
    });
  }

  reload(reloadJobEvents = false): void {
    if (this.skipReload) { return; }
    if (this.jobEvent) { this.getJobEvent(this.jobEvent.id); }
    if (reloadJobEvents) { this.getJobEvents(); }
  }

  getAssignments(jobEventId: string, next = false): void {
    this.sortAsc = true;
    this.sortBy = 'unique_start,created_at';
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let request = this.assignmentService.list({
      jobevent: jobEventId,
      can_dispatch: 'True',
      ordering: order
    });
    if (next) { request = this.assignmentService.listNext(); }

    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }
    this.assignmentsReq = request.subscribe(assignments => {
      if (this.assignmentService.nextUri) {
        this.processAssignments(assignments, true);
        this.getAssignments(this.jobEvent.id, true);
      } else {
        this.processAssignments(assignments);
        this.addActionButtons();
        this.assignmentsLoading = false;
        this.loading = false;
      }
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  processAssignments(assignments: Assignment[], hasNextPage = false): void {
    this.shifts.forEach(shift => { if (!shift.slots) { shift.slots = []; } });

    assignments.forEach(assignment => {
      let shiftIndex = assignment.shift ? parseInt(assignment.shift.replace(/[^\d]/g, ''), 10) - 1 : 0;
      let shift = this.shifts[shiftIndex];
      if (shift) {
        let numberOfLoadsType = 'numbered';
        if (assignment.numberOfLoadsType) {
          numberOfLoadsType = assignment.numberOfLoadsType;
        } else {
          numberOfLoadsType = assignment.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
        }

        if (!assignment.yardBufferTime) {
          assignment.yardBufferTime = assignment.jobevent.defaultYardBufferTime ||
                                      assignment.job.defaultYardBufferTime ||
                                      this.user.organization.defaultYardBufferTime;
        }
        if (!assignment.yardBufferMinutes) {
          assignment.yardBufferMinutes = assignment.jobevent.defaultYardBufferMinutes ||
                                         assignment.job.defaultYardBufferMinutes ||
                                         this.user.organization.defaultYardBufferMinutes;
        }
        shift.slots.push(new SlotSerializer().fromJson({
          assignment: assignment,
          driver: assignment.driver,
          truck: assignment.truck,
          numberOfLoadsType: numberOfLoadsType
        }));
      }
    });
    if (!hasNextPage) {
      this.shifts.forEach(shift => {
        let max = this.jobEvent && this.jobEvent.numTrucks || shift.slots.length + 1;
        for (let i = shift.slots.length; i < max; i++) {
          shift.slots.push(new SlotSerializer().fromJson({}));
        }
      });
    }
  }

  setJobEvent(jobEvent: JobEvent): void {
    this.jobEvent = jobEvent;
    this.job = this.jobEvent.job;

    if (jobEvent) {
      let url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
      this.location.replaceState(url + '/' + jobEvent.job.id + '/' + jobEvent.id);

      this.start = jobEvent.jobStart;

      if (this.preselectTags && this.jobEvent.job && this.jobEvent.job.tags.length > 0) {
        this.selectedTags = this.jobEvent.job.tags;
      } else if (this.preselectTags && this.authenticationService.hasFavoriteTags()) {
        this.selectedTags = this.user.favoriteTags;
      }

      this.shifts = [new ShiftSerializer().fromJson({ id: 0, slots: [] })];
      if (this.jobEvent.shift2StartTimestamp) {
        this.shifts.push(new ShiftSerializer().fromJson({ id: 1, slots: [] }));
      }
      this.getAssignments(jobEvent.id);
    }
  }

  dropDriver(shift: Shift, slot: Slot, event: DragEvent) {
    this.lastDraggedDriver = this.draggedDriver;
    if (!slot) {
      // remove
      let _slot = _find(this.allSlots(), __slot => __slot.driver === this.draggedDriver);
      if (_slot) { this.removeDriver(_slot); }
    } else {
      let selectedDrivers = filter(this.drivers.items, 'selected');
      if (selectedDrivers.length === 0) {
        selectedDrivers = this.selectedDrivers;
      }
      let conflictedDrivers = selectedDrivers.filter(d => d.conflicts && d.conflicts.length > 0);

      if (conflictedDrivers.length > 0 && !this.assigningDriverToLoad) {
        conflictedDrivers.forEach((driver) => driver.busy = true);
        const dialog = this.dialog.open(ConfirmDriversDialogComponent, {
          width: '430px'
        });

        dialog.componentInstance.drivers = conflictedDrivers;
        dialog.componentInstance.shift = shift;
        dialog.componentInstance.slot = slot;
        dialog.componentInstance.callback = this.confirmDriversCallback;
      } else {
        this.addToSlot(shift, slot);
      }
    }
  }

  confirmDriversCallback = (shift: Shift, slot: Slot) => {
    this.addToSlot(shift, slot);
  }

  addToSlot(shift: Shift, slot: Slot): void {
    // add
    let selectedDrivers: Driver[] = filter(this.drivers.items, 'selected');
    if (!selectedDrivers || !selectedDrivers.length) {
      selectedDrivers = this.selectedDrivers;
    }
    if (selectedDrivers.length) {
      selectedDrivers.forEach((driver, i) => {
        let slotIndex = shift.slots.map((o) => o.over).indexOf(true);
        if (shift.slots[slotIndex + i]) {
          if (driver && driver.truck && driver.dutyStatus === 'on-duty') {
            this.tempModel.push({ shift: shift, slots: shift.slots[i + slotIndex], driver });
          } else {
            this.addTruckAndDriver(shift, shift.slots[i], driver).subscribe(res => {
              // Note: subscription of dialog response will occur after forEach completes qualifying selected drivers
              if (res) {
                // If successful response, add that driver
                this.addDriver([{ shift: shift, slots: shift.slots[i], driver }]);
              }
            });
          }
        }
        if (this.assigningDriverToLoad || !shift.slots[slotIndex + i + 1] && (i + 1) !== selectedDrivers.length) {
          shift.slots.push(new SlotSerializer().fromJson({}));
        }
      });
      if (this.tempModel.length > 0) {
        this.addDriver(this.tempModel);
      }
    } else {
      this.addTruckAndDriver(shift, slot, this.lastDraggedDriver);
      if (!this.job.numTrucks) { shift.slots.push(new SlotSerializer().fromJson({})); }
    }
    slot.over = false;
    this.tempModel = [];
  }

  addTruckAndDriver(shift: Shift, slot: Slot, driver: Driver): Observable<any> {
    return new Observable(observer => {
      if (driver && (!driver.truck || driver.dutyStatus !== 'on-duty')) {
        if (!filter(this.drivers.items, 'selected').length) {
          let dialog = this.openAssignTruck(driver);
          let _callback = dialog.componentInstance.callback;
          dialog.componentInstance.callback = (res) => {
            _callback(res);
            observer.next(true);
          };
        }
      }
    });
  }

  /**
   * @param  {} tempModel
   * For each selected driver, it will create request payload for '/assignments' API
   * It sets the max_number_of_loads based on the preferences returned.
   */
  addDriver(tempModel) {
    const disabledStaggeredTime = this.preference &&
      this.preference.blob &&
      this.preference.blob['staggerAssignmentTime'] === 'shift1_start';
    let removedSlot: Slot;
    let assignments = [];
    let assignment;
    let countSameChunk = 0
    let assignmentsPending = []
    let uniqueStart = moment(
      moment(this.jobEvent.shift1StartTimestamp).format('YYYY-MM-DD') + ' ' + this.jobEvent.staggerStartTime, 'YYYY-MM-DD H:mm A'
    ).toISOString();

    tempModel.forEach((element, index) => {
      removedSlot = clone(element.slots);
      assignment = {
        driver: element.driver.id,
        truck: element.driver.truck.id,
        jobevent: this.jobEvent.id,
        shift: 'shift' + (element.shift.id + 1),
        unique_start: disabledStaggeredTime ? uniqueStart : moment(uniqueStart).add(Number(this.jobEvent.deliveryInterval) * index, 'minutes').toISOString()
      };

      if (this.assigningDriverToLoad && this.loadToAssign && this.loadToAssign.ticketEvents && this.loadToAssign.ticketEvents.LOADING_STARTED) { assignment.unique_start = this.loadToAssign.ticketEvents.LOADING_STARTED; }
    
      if (element.driver.uniqueStart) {
        assignment.uniqueStart = element.driver.uniqueStart;
      }
      if (this.assigningDriverToLoad) {
        assignment.max_number_of_loads = 1;
      } else if (this.jobEvent.assignmentLoadCount) {
        assignment.max_number_of_loads = Number(this.jobEvent.assignmentLoadCount);
      } else if (this.hasAllDriversEnabled) {
        if (!this.preference || !this.preference.hasOwnProperty('blob') || this.preference.blob['dispatchLoadType'] === 'all-day') {
          assignment.max_number_of_loads = 0;
        } else if (this.preference.blob['dispatchLoadType'] === 'by-load') {
          assignment.max_number_of_loads = this.preference.blob['dispatchLoadCount'] ?
                                           Number(this.preference.blob['dispatchLoadCount']) : 1;
        }
      }

      const validationParams = {
        currentAssignation: {
          numberOfLoadsType: assignment.max_number_of_loads ? 'numbered' : 'allDay',
          maxNumberOfLoads: assignment.max_number_of_loads,
        },
        shifts: this.shifts,
        jobEvent: this.jobEvent,
        countSameChunk,
      }
      
      countSameChunk += assignment.max_number_of_loads
      if (this.confirmChangeJobLoadsService.isGreaterAssigned(validationParams)) {
        assignmentsPending.push({ element, assignment })
      } else {
        assignments.push(assignment);
      }
    });

    if (assignmentsPending.length) {
      this.assignmentsLoading = true;
      const loadsAssigned = assignmentsPending.reduce((total, pending) => total + Number(pending.assignment.max_number_of_loads), 0)
      const validationParams = {
        currentAssignation: {
          numberOfLoadsType: loadsAssigned ? 'numbered' : 'allDay',
          maxNumberOfLoads: loadsAssigned,
        },
        shifts: this.shifts,
        jobEvent: this.jobEvent,
      }

      this.confirmChangeJobLoadsService.validateLoadsRequested(validationParams).subscribe(
        (response) => {
          const elements = assignmentsPending.map((e) => e.element)
          response && this.addDriver(elements)
          this.assignmentsLoading = false;
        }, 
        () => this.assignmentsLoading = false
      )        
    }

    if (!assignments.length) return;

    const countCurrentAssignments = this.shifts.map(s => ( s.slots.filter(slot => (slot.assignment.id)).length)).reduce((a, b) => (a + b))
    const totalAssignments = countCurrentAssignments + assignments.length
    if (
      !!this.jobEvent.numTrucks &&
      totalAssignments > this.jobEvent.numTrucks
    ) {
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '530px',
      });
      const messageAssigned = countCurrentAssignments > 0 ? ` and the ${countCurrentAssignments} previously assigned` : ''
      this.confirmDialog.componentInstance.attributes = {
        title: `Trucks Requested Exceeded`,
        body: `The number of trucks required has exceeded by the ${assignments.length} truck(s) selected${messageAssigned}. 
        Do you want to assign the selected truck(s) and increase the total number for this job to ${totalAssignments}?`,
        close: 'Cancel',
        accept: 'Assign & Update Trucks'
      };

      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.jobEventService.save(
            {
              id: this.jobEvent.id,
              numTrucks: totalAssignments
            }
          ).pipe(first()).subscribe(() => {
            this.createBulkAssignments(tempModel, assignments).subscribe(res => {
              if (res) { this.removeDriver(removedSlot); }
            }, (err) => {
              this.openDisplayErrorModal(err.errors);
              this.loading = false;
              this.assignmentsLoading = false;
            });
          }, (err) => {
            this.errors = err;
          });
        }
        this.confirmDialog = null;
      });
    } else {
      this.createBulkAssignments(tempModel, assignments).subscribe(res => {
        if (res) { this.removeDriver(removedSlot); }
      }, (err) => {
        this.openDisplayErrorModal(err.errors);
        this.loading = false;
        this.assignmentsLoading = false;
      });
    }
  }

  /**
   * @param  {} tempModel
   * @param  {} assignments
   * @returns Observable
   * This is used to create POST request to '/assignments' API
   * It takes assignments argument as its request payload
   */
  createBulkAssignments(tempModel, assignments): Observable<any> {
    this.assignmentsLoading = true;
    return new Observable(observer => {
      if (!!!this.jobEvent.numTrucks || this.jobEvent.numTrucks <= this.allSlots().length) {
        this.assignmentService.saveBulkAssignments(assignments).subscribe(_assignment => {
          let assignmentArray = _assignment.assignments;
          this.lastCreatedAssignments.next(assignmentArray);
          tempModel.forEach((element, index: number) => {
            element.slots.driver = element.driver;
            element.slots.truck = element.driver.truck;
            element.slots.updating = false;
            element.slots.over = false;
            element.slots.assignment = new AssignmentSerializer().fromJson(assignmentArray[index]);
            this.drivers.items.forEach(_driver => _driver.selected = false);
            observer.next(true);
          });
          if (this.availableDrivers) { this.availableDrivers.getDrivers(); }
        }, (err) => {
          this.openDisplayErrorModal(err.errors);
          observer.next(false);
          this.loading = false;
          this.assignmentsLoading = false;
        }, () => this.loading = false
        );
      }
    });
  }

  /**
   * @param  {} err
   * When the '/assignments' API fails, this dialog will display the error
   * listing the driver and truck name's followed by the error message.
   */
  openDisplayErrorModal(errors: any[]): void {
    let _errors = [];
    if (errors && errors.length) {
      [].concat.apply([], errors).forEach(error => {
        let driver;
        const errorMessage = error.errors && error.errors.non_field_errors
        if (error.item) {
          driver = _find(this.selectedDrivers, { id: error.item && error.item.driver });
        } else if (error.data) {
          driver = _find(this.selectedDrivers, { id: error.data && error.data.driver });
        }

        if (driver) {
          _errors.push({
            error: errorMessage,
            driverName: driver.name,
            truckName: driver.truck && driver.truck.displayName
          });
        } else if (errorMessage) {
          _errors.push({
            error: errorMessage
          });
        }
      });
    }
    if (_errors.length) {
      const dialog = this.dialog.open(AssignmentErrorDialogComponent, {
        width: '430px'
      });
      this.tempModel = [];
      dialog.componentInstance.errors = _errors;
      dialog.afterClosed().subscribe(dialogResult => { this.reload(); });
    }
  }

  assignDriverToLoad(row: JobLoad): void {
    if (row.ruckit && row.ruckit.assignmentId) { return; }
    
    this.assigningDriverToLoad = true;
    this.loadToAssign = row;
    this.skipReload = true;
    row['dragOver'] = false;

    this.selectDrivers([this.selectedDrivers[0]], true);
    const lastCreatedSub = this.lastCreatedAssignments.subscribe(assignments => {
      this.assignmentsLoading = false;
      this.loading = false;
      if (assignments) {
        this.patchUpdateLoad(row, this.selectedDrivers[0], assignments[0].id);
        lastCreatedSub.unsubscribe();
      }
    });
  }

  patchUpdateLoad(row: JobLoad, driver: Driver, assignmentId: string): void {
    const vehicleId = driver.truck && driver.truck.name ? driver.truck.name : '';
    const vehicleName = driver.truck && driver.truck.name ? driver.truck.name : '';
    const vehicleContainerId = driver.truckContainerId ? driver.truckContainerId : '';
    const driverId = driver.id;
    const driverName = driver.name;
    const loadUpdate: JobLoad = {
      loadNumber: row.loadNumber,
      vehicleId: vehicleName,
      ruckit: {
        assignmentId: assignmentId,
        driverId: driverId,
        driverName: driverName,
        vehicle: {
          id: vehicleId,
          description: vehicleName,
          vehicleRef: vehicleContainerId
        }
      }
    };
  
    this.jobLoadService.patchLoads(this.jobEvent.id, [loadUpdate]).subscribe(response => {
      Object.assign(row, loadUpdate);
    }, err => {
      this.errors = err;
    }, () => {
      this.skipReload = false;
      this.assigningDriverToLoad = false;
      this.loadToAssign = null;
    });
  }

  removeDriversFromLoads(): void {
    this.removingDriverLoadAssignments = true;
    this.skipReload = true;
    const loads: JobLoad[] = [];
    const emptyLoad = {
      vehicleId: '',
      ruckit: {
        vehicleName: '',
        assignmentId: '',
        driverId: '',
        driverName: '',
        vehicle: {
          id: '',
          description: '',
          vehicleRef: ''
        }
      }
    };

    this.loadListSelection.selected.forEach(l => {
      const load = JSON.parse(JSON.stringify(l));
      Object.assign(load, emptyLoad);
      loads.push(load);
    });

    const slotsToRemove: Slot[] = [];
    this.allSlots().forEach(slot => {
      if (slot.assignment && slot.assignment.driver && slot.assignment.driver.id) {
        const removedLoad = this.loadListSelection.selected.findIndex(l => l.ruckit && l.ruckit.driverId === slot.assignment.driver.id);
        if (removedLoad > -1) {
          slotsToRemove.push(slot);
          slot.over = false;
        }
      }
    });
    this.bulkRemoveAssignments(slotsToRemove);

    this.jobLoadService.patchLoads(this.jobEvent.id, loads).subscribe(response => {
      this.loadListSelection.selected.forEach(load => {
        Object.assign(load, emptyLoad);
      });
      this.toggleAllLoads(false);
    }, err => {
      this.errors = err;
    }, () => {
      this.removingDriverLoadAssignments = false;
      this.skipReload = false;
    });
  }

  removeDriver(slot: Slot) {
    if (slot.assignment) {
      let shift = _find(this.shifts, _shift => includes(_shift.slots, slot));
      if (shift) {
        this.assignmentService.remove(slot.assignment.id).subscribe(res => {
          slot.driver = undefined;
          slot.assignment = undefined;
          remove(shift.slots, slot);
          let max = this.jobEvent.numTrucks || shift.slots.length + 1;
          for (let i = shift.slots.length; i < max; i++) {
            shift.slots.push(new SlotSerializer().fromJson({}));
          }
          if (this.availableDrivers) { this.availableDrivers.getDrivers(); }
        }, err => {
          this.errors = err;
        });
      }
    }
    this.reload();
    this.draggedDriver = undefined;
    this.selectedDrivers = [];
  }
  driverIsDraggable(driver: Driver): boolean {
    this.selectedDrivers = filter(this.drivers.items, 'selected');
    return this.selectedDrivers.length === 0 || includes(this.selectedDrivers, driver);
  }
  driverIsGrabbed(driver: Driver): boolean {
    return this.driverIsDraggable(driver) ?
      !!(driver === this.draggedDriver || (this.draggedDriver && driver.selected)) : undefined;
  }
  multipleDrag(driver): boolean {
    this.selectedDrivers = filter(this.drivers.items, 'selected');
    return this.draggedDriver === driver && this.selectedDrivers.length > 1;
  }
  driverDragstart(driver: Driver): void {
    this.draggedDriver = driver;
  }
  ignoreDragevent(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
  }
  slotDragover(e): void {
    e.preventDefault();
  }
  slotDrag(slot: Slot, event: DragEvent): void {
    slot.over = (event && event.type === 'dragenter');
  }

  /**
   * @param  {Driver[]} drivers
   * Get the object of all the selected drivers
   * Set the slot to {over: true} if slot is available in the shift object
   * It also checks for which shift is selected in case of multi-shift
   */
  selectDrivers(drivers: Driver[], triggerDrop = false): void {
    this.selectedDrivers = filter(drivers, 'selected');
    if (this.selectedDrivers.length === 0) { this.selectedDrivers = drivers; }
    let count = 0; let breaktheloop = false;
    this.shifts.forEach((shift: Shift) => {
      if (this.selectedShift && this.selectedShift.id === shift.id) {
        shift.slots.forEach((slot: Slot, index: number) => {
          if (slot.driver && !slot.driver.id) {
            if (this.selectedDrivers.length === count) {
              breaktheloop = true;
            } else if (!breaktheloop) {
              count++;
              shift.slots[index] = new SlotSerializer().fromJson({ over: true });
            }
          }
        });
        if (triggerDrop) { this.dropDriver(shift, shift.slots[0], null); }
      }
    });
  }

  removeSelectedAssignments(): void {
    let selectedSlots: Slot[] = [];
    this.shifts.forEach(shift => {
      selectedSlots = selectedSlots.concat(shift.slots.filter(slot => slot.assignment.selected));
    });

    if (selectedSlots && selectedSlots.length) {
      this.bulkRemoveAssignments(selectedSlots);
    }
  }

  modifySelectedAssignments(copy = false): void {
    let selectedSlots: Slot[] = [];
    let assignments: Assignment[] = [];
    this.shifts.forEach(shift => {
      selectedSlots = selectedSlots.concat(shift.slots.filter(slot => slot.assignment.selected));
    });
    assignments = selectedSlots.map(slot => {
      slot.assignment['numberOfLoadsType'] = slot.numberOfLoadsType;
      return slot.assignment;
    }).filter(Boolean);

    if (selectedSlots && selectedSlots.length) {
      const dialog = this.dialog.open(MoveAssignmentsDialogComponent, {
        width: '850px'
      });
      if (dialog) {
        dialog.componentInstance.callback = this.moveAssignmentsCallback;
        dialog.componentInstance.copy = copy;
        dialog.componentInstance.job = this.job;
        dialog.componentInstance.start = this.start;
        dialog.componentInstance.jobEvent = this.jobEvent;
        dialog.componentInstance.assignments = clone(assignments);
        dialog.componentInstance.hasAllDriversEnabled = this.hasAllDriversEnabled;
      }
    }
  }

  /**
   * @param  {any} shift
   * gets the emitted value from the available-driver component and sets to the variable
   */
  selectShift(shift: Shift): void {
    this.selectedShift = shift;
  }

  /**
   * Update the assignment when information is valid
   * @param slot - save the assignment status in the UI
   */
  updateAssignment(slot: Slot): void {
    const currentAssignation = {
      assignmentId: slot.assignment.id,
      numberOfLoadsType: slot.numberOfLoadsType,
      maxNumberOfLoads: slot.assignment.maxNumberOfLoads,
    }

    this.confirmChangeJobLoadsService.validateLoadsRequested({
      currentAssignation,
      shifts: this.shifts,
      jobEvent: this.jobEvent,
    }).subscribe(
      (response) => (response && this._updateAssignmentLoads(slot)), 
      (error) => {
        if (error && error.maxLoads) {
          slot.assignment.maxNumberOfLoads = error.maxLoads
          this._updateAssignmentLoads(slot)
        }
      }
    )
  }

  private _updateAssignmentLoads(slot: Slot): void {
    slot.updating = true;
    let _assignment = clone(slot.assignment);
    const formatString = 'MM/DD/YYYY h:mm A';
    const dateString = moment(_assignment.uniqueStartDate).format('MM/DD/YYYY');
    _assignment.uniqueStart = moment(`${dateString} ${_assignment.uniqueStartTime}`, formatString).format();
    _assignment['maxNumberOfLoads'] = slot.numberOfLoadsType === 'allDay' ? 0 : _assignment['maxNumberOfLoads'] || 1;

    this.assignmentService.save(_assignment, { can_dispatch: 'True' }).subscribe(assignment => {
      this.assignmentService.get(assignment.id, { can_dispatch: 'True' }).subscribe(res => {
        slot.assignment = res;
        slot.updating = false;
      }, err => {
        this.errors = err;
        slot.updating = false;
      });
    }, (err) => {
      this.errors = err;
      slot.updating = false;
    });
  }

  bulkUpdateAssignments(): Observable<{ errors: any; assignments: Assignment[]; }>[] {
    this.bulkSaveLoading = true;
    let bulkUpdateReqs: Observable<{ errors: any; assignments: Assignment[]; }>[] = [];

    this.shifts.forEach(shift => {
      if (shift && shift.slots) {
        let assignments = shift.slots.map(_slot => {
          _slot.updating = true;
          let assignment = clone(_slot.assignment);
          const formatString = 'MM/DD/YYYY h:mm A';
          const dateString = moment(assignment.uniqueStartDate).format('MM/DD/YYYY');
          assignment.uniqueStart = moment(`${dateString} ${assignment.uniqueStartTime}`, formatString).format();
          assignment['maxNumberOfLoads'] = _slot.numberOfLoadsType === 'allDay' ? 0 : assignment['maxNumberOfLoads'] || 1;

          return assignment;
        }).filter(assignment => assignment.id !== null && assignment.id !== undefined);
        let request = this.assignmentService.bulkUpdate(assignments, { can_dispatch: 'True' });
        bulkUpdateReqs.push(request);
      }
    });

    return bulkUpdateReqs;
  }

  triggerBulkSave(): void {
    let bulkUpdateReqs = this.bulkUpdateAssignments();
    forkJoin(bulkUpdateReqs).subscribe(bulkUpdates => {
      this.processBulkUpdates(bulkUpdates);
    });
  }

  processBulkUpdates(bulkUpdates): void {
    bulkUpdates.forEach(res => {
      if (res && res.errors && res.errors.length) {
        this.openDisplayErrorModal(res.errors);
      }

      if (res && res.assignments && res.assignments.length) {
        res.assignments.forEach(_assignment => {
          let shiftIndex = _assignment.shift ? parseInt(_assignment.shift.replace(/[^\d]/g, ''), 10) - 1 : 0;
          let shift = this.shifts[shiftIndex];
          let _slot = _find(shift.slots, { assignment: { id: _assignment.id } });
          if (_slot) {
            _slot.assignment = _assignment;
            if (_assignment.numberOfLoadsType) {
              _slot.numberOfLoadsType = _assignment.numberOfLoadsType;
            } else {
              _slot.numberOfLoadsType = (_assignment.maxNumberOfLoads === 0 || _assignment.maxNumberOfLoads === null) ? 'allDay' : 'numbered';
            }
            _slot.updating = false;
          }
        });
      }
    });

    this.shifts.forEach(shift => {
      shift.slots.forEach(_slot => {
        _slot.updating = false;
      });
    });
    this.bulkSaveLoading = false;
  }

  bulkRemoveAssignments(slots: Slot[]): void {
    const assignmentIds = slots.map(slot => slot.assignment.id).filter(Boolean);

    if (assignmentIds && assignmentIds.length) {

      if (this.removingDriverLoadAssignments) {
        this.removeAssignments(assignmentIds, slots);
        return;
      }

      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, DEFAULT_DIALOG_SIZE);
      this.confirmDialog.componentInstance.attributes = {
        title: `Remove ${assignmentIds.length} Assignments?`,
        body: `This action will remove the selected assignments from this day of the ${this.job.name} job.`,
        close: 'Cancel',
        accept: 'Remove'
      };

      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.removeAssignments(assignmentIds);
        }
        this.confirmDialog = null;
      });
    }
  }

  removeAssignments(assignmentIds: string[], slots: Slot[] = null): void {
    this.bulkSaveLoading = true;
    this.assignmentService.bulkRemove(assignmentIds).subscribe(() => {
      if (this.removingDriverLoadAssignments && slots) {
        slots.forEach(slot => {
          let shift = _find(this.shifts, _shift => includes(_shift.slots, slot));
          slot.driver = undefined;
          slot.assignment = undefined;
          remove(shift.slots, slot);
          let max = this.jobEvent.numTrucks || shift.slots.length + 1;
          for (let i = shift.slots.length; i < max; i++) {
            shift.slots.push(new SlotSerializer().fromJson({}));
          }
        });
        if (this.availableDrivers) { this.availableDrivers.getDrivers(); }
        this.draggedDriver = undefined;
        this.selectedDrivers = [];
      }

      this.bulkSaveLoading = false;
      this.reload();
    });
  }

  onTabClick(view: 'loadList' | 'assignments') {
    this.view = view;
    this.deselectAll();
    this.toggleAllLoads(false);
  }

  selectAssignment(event: MouseEvent, slot: Slot, shift: Shift = null): void {
    let checked = (<HTMLInputElement>event.target).checked;
    if (!slot && shift) {
      shift.slots.forEach(_slot => _slot.assignment.selected = _slot.assignment.id && checked);
    } else {
      slot.assignment.selected = checked;
    }
  }

  deselectAll(): void {
    this.shifts.forEach(shift => {
      shift.slots.forEach(_slot => _slot.assignment.selected = false);
    });
    if (this.selectAllAssignments) {
      this.selectAllAssignments.nativeElement.checked = false;
    }
  }

  selectDriver(e, driver: Driver): void {
    let checked = (<HTMLInputElement>e.target).checked;
    driver.selected = checked;
    extend(_find(this.drivers.items, { id: driver.id }), driver);
    this.selectedDriverCount = filter(this.drivers.items, 'selected').length;
  }

  openAssignTruck(driver: Driver, assignmentId?: string): MatDialogRef<AssignTruckDialogComponent, any> {
    const dialog = this.dialog.open(AssignTruckDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.driver = cloneDeep(driver);
    dialog.componentInstance.jobEvent = this.jobEvent;
    dialog.componentInstance.requireOnDuty = true;
    dialog.componentInstance.callback = (res) => {
      Object.assign(driver, res);
      if (assignmentId !== undefined) {
        let assignment = new AssignmentSerializer().fromJson({
          id: assignmentId,
          driver: driver.id,
          truck: driver.truck.id,
          jobevent: this.jobEvent.id
        });
        this.assignmentService.save(assignment).subscribe(_assignment => {
          this.drivers.items.forEach(_driver => _driver.selected = false);
          this.reload();
        }, (err) => {
          this.errors = err;
        }, () => this.loading = false);
      }
    };
    return dialog;
  }

  displayLastDispatched(): boolean {
    return this.jobEvent && this.jobEvent.lastDispatchedTime && this.dispatchDisabled();
  }

  autoAssign(force = false): void {
    if (this.jobEvent.canAutoAssign) {
      this.autoAssignLoading = true;
      this.jobEventService.autoAssign(this.jobEvent.id, force).subscribe(res => {
        this.reload();
        this.autoAssignLoading = false;
      }, err => {
        let results = JSON.parse(err._body);
        results = camelcaseKeysDeep(results);
        const dialog = this.dialog.open(AutoAssignConflictDialogComponent, {
          width: '430px'
        });

        dialog.componentInstance.results = results;
        dialog.componentInstance.callback = this.autoAssignConflictCallback;
        dialog.afterClosed().subscribe(dialogResult => {
          this.autoAssignLoading = false;
        });
      });
    }
  }

  assignmentUniqueStartDateChanged(slot: Slot, values: Date[]): void {
    let assignment: Assignment = slot.assignment;
    if (values && values.length) {
      assignment.uniqueStartDate = values[0];
    }
  }

  selectJob(job: Job, userSelection = true): void {
    this.setJobProperties(job);
    if (!userSelection) { return; }

    this.loading = true;
    this.jobEvent = null;
    this.jobEvents = [];

    let url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
    if (this.job && this.jobEvent) {
      this.location.replaceState(url + '/' + this.jobId);
    }
    this.getJobEvents({}, false, true);
  }

  setJobProperties(job: Job): void {
    this.job = job;
    this.jobId = this.job && this.job.id;
  }

  selectJobEvent(jobEvent: JobEvent): void {
    this.loading = true;
    this.setJobEvent(jobEvent);
    const jobEventId = this.jobEvent && this.jobEvent.id;
    let url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
    if (this.job && this.jobEvent) {
      this.location.replaceState(url + '/' + this.jobId + '/' + jobEventId);
    }
  }
}
