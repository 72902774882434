import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { InvoiceService } from './invoice.service';
import { OrganizationService } from '../organizations/organization.service';
import { parseErrors } from '../shared/api.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'ruckit-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.scss'],
  providers: [InvoiceService, OrganizationService]
})
export class EditInvoiceComponent implements OnInit {
  invoice: any;
  loading = true;
  editing = false;
  errors = [];
  search;
  confirmDialog: MatDialogRef<any>;
  invoiceDate: Date;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.params
      .subscribe((params: Params) => this.getInvoice(params['id']));
  }

  getInvoice(id) {
    if (id) {
      this.invoiceService.getInvoice(id).subscribe((res) => {
        this.invoice = res;
        let _invoiceDate = this.invoice.invoiceDate && new Date(this.invoice.invoiceDate);
        if (_invoiceDate) { this.invoiceDate = _invoiceDate; }
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
      });
    }
  }

  updateInvoice(update = false) {
    if (update) {
      this.getInvoice(this.invoice.id);
    }
  }

  fileChange(e) {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (_e) => {
      this.invoice.ownerOrganization.image = _e.target['result'];
    };
    reader.readAsDataURL(file);
    this.organizationService.saveOnlyImage(this.invoice.ownerOrganization.id, file).subscribe((organization) => {
      this.save();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  save() {
    this.loading = true;
    this.invoiceService.save(this.invoice).subscribe(() => {
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  submit() {
    this.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Send Invoice?',
      body: 'Are you sure you want to send this invoice? You will no longer be able to edit the invoice once it has been sent.',
      close: 'Cancel',
      accept: 'Send'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.invoiceService.save(this.invoice).subscribe(() => {
          this.invoiceService.send(this.invoice).subscribe(() => {
            this.loading = false;
            this.router.navigate(['/invoices/filter/sent']);
          }, (err) => {
            this.errors = parseErrors(err);
            this.loading = false;
          });
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
      this.confirmDialog = null;
    });
  }

  onDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.invoiceDate = values[0];
      this.invoice.invoiceDate = values[0].toISOString();
    }
  }

  changeSearch(term?: string) {
    this.search = term || '';
  }
}
