"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var drivers_component_1 = require("../drivers/drivers.component");
var connection_service_1 = require("../connections/connection.service");
var connection_serializer_1 = require("../connections/connection.serializer");
var trucks_component_1 = require("../trucks/trucks.component");
/**
 * Account Fleet Component: Requests and displays navigation links and,
 * through the {@link DriversComponent} or {@link TrucksComponent}, the fleet associated with the
 * selected Account.
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <account-fleet></account-fleet>
 */
var AccountFleetComponent = /** @class */ (function () {
    function AccountFleetComponent(route, router, connectionService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.connectionService = connectionService;
        this.view = { stub: 'drivers', title: 'Drivers' };
        this.account = new connection_serializer_1.ConnectionSerializer().fromJson({});
        this.loading = true;
        this.errors = [];
        this.search = '';
        this.query = {};
        this.displayedColumns = [
            'select', 'name', 'cdl', 'phone-number', 'billing-id', 'carrier', 'truck', 'markets',
            'action'
        ];
        this.accountType = 'leased-fleet';
        /**
         * An anonymous callback function to call the FancyTable's `refreshTable()`
         * function that is called when drivers are created.
         */
        this.saveDriverCallback = function () {
            if (_this.driverTableWrapper) {
                _this.driverTableWrapper.refreshTable();
            }
        };
        this.saveTruckCallback = function () {
            if (_this.truckTableWrapper) {
                _this.truckTableWrapper.refreshTable();
            }
        };
    }
    /**
    * Examines the route and query parameters to determine the Account Type and
    * persisted search value.
    *
    * If the route includes `accounts` the `customer_only: True` filter is added
    * to the query.
    *
    * If the `account` has an {@link Organization} with a {@link Carrier} the
    * `carrier: CARRIER_ID` filter is added to the query.
    */
    AccountFleetComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route && this.route.snapshot && this.route.snapshot.url.length) {
            this.view = this.route.snapshot.data['view'];
            this.accountType = this.route.snapshot.url[0].path === 'accounts' ? 'accounts' : 'leased-fleet';
            this.displayedColumns = this.view.stub === 'drivers' ? [
                'select', 'name', 'cdl', 'phone-number', 'billing-id', 'carrier', 'truck', 'markets', 'action'
            ] : [
                'select', 'truck-type', 'name', 'license-plate', 'carrier', 'markets', 'action'
            ];
        }
        if (this.route && this.route.params) {
            this.route.params.subscribe(function (params) {
                if (params['accountId']) {
                    _this.accountId = params['accountId'];
                    _this.getAccount(_this.accountId);
                }
                else if (_this.router.url.includes('accounts')) {
                    _this.query = __assign({}, _this.query, { customer_only: 'True' });
                }
                else {
                    _this.query = __assign({}, _this.query, { all_leased: 'True' });
                }
            });
        }
    };
    AccountFleetComponent.prototype.ngOnDestroy = function () {
        if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
            this.connectionReq.unsubscribe();
        }
    };
    /**
     * Handles changing to and from the archive view, navigating to the correct url
     *
     * @param {boolean} view Should the view be changed to archive or not
     */
    AccountFleetComponent.prototype.viewArchive = function (view) {
        if (view) {
            this.query = __assign({}, this.query, { status: 'deleted' });
            this.updateRowActions('archive');
        }
        else {
            delete this.query['status'];
            this.query = __assign({}, this.query);
            this.updateRowActions('main');
        }
    };
    AccountFleetComponent.prototype.updatedMarkets = function () {
        if (this.driverTableWrapper) {
            this.driverTableWrapper.refreshTable();
        }
        if (this.truckTableWrapper) {
            this.truckTableWrapper.refreshTable();
        }
    };
    /**
     * Returns a boolean indicating whether or not the current URL, excluding
     * query parameters, is equal to the `url` value passed in for comparison.
     *
     * @param {} url An array of strings representing the anchor tag's URL parts
     */
    AccountFleetComponent.prototype.isLinkActive = function (url) {
        var queryParamsIndex = this.router.url.indexOf('?');
        var baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
        return baseUrl === url;
    };
    /**
     * Requests the {@link Connection} or "account" and captures the `carrierId`
     * and generates `query` parameters.
     *
     * @param {} accountId The UUID of the connection to get from the API
     */
    AccountFleetComponent.prototype.getAccount = function (accountId) {
        var _this = this;
        this.loading = true;
        if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
            this.connectionReq.unsubscribe();
        }
        this.connectionReq = this.connectionService.get(accountId).subscribe(function (account) {
            _this.account = account;
            _this.carrierId = _this.account &&
                _this.account.organization &&
                _this.account.organization.carrier &&
                _this.account.organization.carrier.id;
            if (_this.router.url.includes('accounts')) {
                _this.query = __assign({}, _this.query, { customer_only: 'True' });
            }
            if (_this.router.url.includes('leased') && !_this.carrierId) {
                _this.query = __assign({}, _this.query, { all_leased: 'True' });
            }
            if (_this.carrierId) {
                _this.query = __assign({}, _this.query, { carrier: _this.carrierId });
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    AccountFleetComponent.prototype.updateRowActions = function (type) {
        if (type === 'main') {
            if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
                this.driverTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Edit', action: 'edit', link: false, external: false }
                ];
            }
            if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
                this.truckTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Edit', action: 'edit', link: false },
                    { name: 'Remove', action: 'remove', link: false, external: false }
                ];
            }
        }
        else {
            if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
                this.driverTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Reactivate', action: 'unarchive', link: false, external: false }
                ];
            }
            if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
                this.truckTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Reactivate', action: 'unarchive', link: false, external: false }
                ];
            }
        }
    };
    return AccountFleetComponent;
}());
exports.AccountFleetComponent = AccountFleetComponent;
