<form #copyAssignment="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Select Assignments and Drivers</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content>
    <div class="body form-content">
      <div class="dropdowns-container">
        <div>
          <input type="checkbox" [checked]="allAssignmentSelected" (change)="allSelectAssignments($event)" />
          <label attr.data-label="{{ 'Select all assignments' | translate}}" for="select_all"></label>
        </div>
        <dropdown [disabled]="(showAllAssignments && !deselectedAssignment) || allAssignmentSelected" title="Select assignments" [options]="displayAssignmentName"
          [config]="assignmentDropdownConfig" [selectedItems]="selectedAssignment" (onSelect)="setSelectedAssignment($event)"></dropdown>
        <dropdown *ngIf="assignmentList.length > 0 || selectedAssignment.length>  0 || allAssignmentSelected" title="Select drivers"
          [options]="driversList" [config]="driverDropdownConfig" [selectedItems]="selectedDrivers" (onSelect)="setSelectedDriver($event)"></dropdown>
      </div>
      <div class="selection-container">
        <mat-card class="assignments-list" *ngIf="showAllAssignments || allAssignmentSelected">
          <h3>Assignments</h3>
          <mat-list>
            <mat-list-item  class="assignment-name" *ngFor="let assignment of selectedAssignment">
              <div  class="assignment-title"> {{assignment.name}}</div>
              <i class="icon-error" (click)="deselectAssignment(assignment)"></i>
            </mat-list-item>
          </mat-list>
          <mat-list *ngIf="selectedAssignment.length==0">
            <mat-list-item class="assignment-name" *ngFor="let assignment of assignmentList">
              <div class="assignment-title"> {{assignment.job.name}}</div>
              <i class="icon-error" (click)="deselectAssignment(assignment)"></i>
            </mat-list-item>
          </mat-list>
        </mat-card>
        <mat-card class="drivers-list" *ngIf="selectedDrivers.length > 0">
          <h3>Drivers</h3>
          <mat-list>
            <mat-list-item class="driver-name" *ngFor="let driver of selectedDrivers">
              {{driver.name}}
            </mat-list-item>
          </mat-list>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="openConfirmDialog($event)">Copy</button>
  </div>
</form>
