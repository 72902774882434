"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var collaborator_service_1 = require("./collaborator.service");
var api_service_1 = require("../shared/api.service");
var job_event_service_1 = require("../job-events/job-event.service");
var job_event_1 = require("../job-events/job-event");
var shared_1 = require("../shared");
var ExtendCollaborationDialogComponent = /** @class */ (function () {
    function ExtendCollaborationDialogComponent(dialogRef, collaboratorService, jobEventService, elementRef) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.jobEventService = jobEventService;
        this.elementRef = elementRef;
        this.collaborator = {};
        this.completed = new core_1.EventEmitter();
        this.jobEventsDropdownConfig = {
            multiselect: true,
            loadingOptions: true,
            showLoading: true
        };
        this.loading = false;
        this.errors = [];
        this.jobEvents = [];
        this.model = {
            jobevents: [],
            numTrucks: null,
            recipientOrganization: null
        };
    }
    ExtendCollaborationDialogComponent.prototype.ngAfterContentInit = function () {
        this.getJobEvents();
    };
    ExtendCollaborationDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
    };
    ExtendCollaborationDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.model.jobevents = this.model.jobevents.map(function (j) { return j.id; });
        this.model.recipientOrganization = this.collaborator.organizationId;
        this.model.numTrucks = this.collaborator.numTrucks;
        this.collaboratorService.updateDays(this.jobEvent.id, this.model).subscribe(function (result) {
            _this.callback(_this.collaborator);
            _this.dialogRef.close();
            _this.loading = false;
        }, function (err) {
            _this.errors = ['Collaboration could not be updated.'];
            _this.errors.push(api_service_1.parseErrors(err));
            _this.loading = false;
        });
    };
    ExtendCollaborationDialogComponent.prototype.getJobEvents = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        if (this.jobEventsDropdown) {
            this.jobEventsDropdown.loading = true;
        }
        this.jobEventsDropdownConfig.loadingOptions = true;
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.jobEventsDropdown) {
            this.jobEventsDropdown.selectedItems = [];
        }
        this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ ordering: 'shift1_start', job: this.jobEvent && this.jobEvent.job && this.jobEvent.job.id, collaborator: this.collaborator && this.collaborator.organizationId }, query)).subscribe(function (jobEvents) {
            var selectedJobEvents = [];
            _this.jobEvents = jobEvents;
            if (_this.jobEvent) {
                var jobEvent = lodash_1.find(_this.jobEvents, { id: _this.jobEvent.id });
                if (jobEvent) {
                    _this.jobEvents = lodash_1.reject(_this.jobEvents, jobEvent);
                }
                else {
                    jobEvent = _this.jobEvent;
                }
                _this.jobEvents.unshift(jobEvent);
                selectedJobEvents.push(jobEvent);
                _this.loading = false;
            }
            var sharedJobEvents = lodash_1.reject(_this.jobEvents, { isShared: false });
            if (_this.jobEventsDropdown) {
                _this.jobEventsDropdown.selectedItems = selectedJobEvents.concat(sharedJobEvents);
                _this.model.jobevents = _this.jobEventsDropdown.selectedItems;
            }
            else {
                _this.model.jobevents = selectedJobEvents;
            }
            if (_this.jobEventsDropdown) {
                _this.jobEventsDropdown.loading = false;
            }
            _this.jobEventsDropdownConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
            if (_this.jobEventsDropdown) {
                _this.jobEventsDropdown.loading = false;
            }
            _this.jobEventsDropdownConfig.loadingOptions = false;
        });
    };
    ExtendCollaborationDialogComponent.prototype.selectJobEvent = function (jobEvents) {
        this.model.jobevents = jobEvents;
    };
    ExtendCollaborationDialogComponent.prototype.dropdownNextPage = function (event) {
        var _this = this;
        if (!this.jobEventsDropdownConfig.loadingOptions) {
            var request = this.jobEventService.listNext();
            if (request) {
                if (this.jobEventsDropdown) {
                    this.jobEventsDropdown.loading = true;
                }
                this.jobEventsDropdownConfig.loadingOptions = true;
                this.jobEventsReq = request.subscribe(function (jobEvents) {
                    _this.jobEvents = _this.jobEvents.concat(jobEvents);
                    if (_this.jobEventsDropdown) {
                        _this.jobEventsDropdown.loading = false;
                    }
                    _this.jobEventsDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = err;
                    if (_this.jobEventsDropdown) {
                        _this.jobEventsDropdown.loading = false;
                    }
                    _this.jobEventsDropdownConfig.loadingOptions = false;
                });
            }
        }
    };
    return ExtendCollaborationDialogComponent;
}());
exports.ExtendCollaborationDialogComponent = ExtendCollaborationDialogComponent;
