<form #filterTrips="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1>{{ title }}</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body end-of-day-filters-container">
      <div class="switch-filters-container">
        <div class="switch-filter" *ngFor="let filter of switchFilters">
          <label attr.data-label="{{ filter.label }}" class="toggle-label">
            <input
              type="checkbox"
              [name]="filter.label"
              [checked]="filter.selected"
              (change)="onSwitch(filter)"
              class="toggle-input"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <ng-container *ngFor="let filter of filters">
        <ng-container *ngIf="filter.selected === undefined">
          <ng-container [ngSwitch]="filter.key">
            <ng-container *ngSwitchCase="'carrier'">
              <div class="dropdown-row">
                <label translate>Carrier</label>
                <dropdown
                  title="{{ 'Select Carrier' | translate }}"
                  [options]="carriers"
                  [config]="{
                    searchable: true
                  }"
                  (onSelect)="onSelect('carrier', $event)"
                  (onSearch)="onCarrierSearch($event)"
                ></dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'job'">
              <div class="dropdown-row">
                <label translate>Job</label>
                <ruckit-dropdown
                  [config]="jobDropdownConfig"
                  [selectedOption]="
                    filter.values.length ? filter.values[0].label : null
                  "
                  (onSelect)="onSelect('job', $event)"
                >
                </ruckit-dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'jobevent'">
              <div class="dropdown-row">
                <label translate>Job Event</label>
                <ruckit-dropdown
                  [config]="jobEventsConfig"
                  [selectedOption]="
                    filter.values.length ? filter.values[0].label : null
                  "
                  (onSelect)="
                    onSelect('jobevent', $event, jobEventsConfig.nameProperty)
                  "
                >
                </ruckit-dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'project'">
              <div class="dropdown-row">
                <label translate>Project</label>
                <ruckit-dropdown
                  [config]="projectDropdownConfig"
                  [selectedOption]="
                    filter.values.length ? filter.values[0].label : null
                  "
                  (onSelect)="onSelect('project', $event)"
                >
                </ruckit-dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'customer'">
              <div class="dropdown-row">
                <label translate>Customer</label>
                <ruckit-dropdown
                  [config]="customerDropdownConfig"
                  [selectedOption]="
                    filter.values.length ? filter.values[0].label : null
                  "
                  (onSelect)="onSelect('customer', $event)"
                >
                </ruckit-dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'payable'">
              <div class="dropdown-row">
                <label translate>Payable To</label>
                <ruckit-dropdown
                  [config]="payableCustomerDropdownConfig"
                  [selectedOption]="
                    filter.values.length ? filter.values[0].label : null
                  "
                  (onSelect)="onSelect('payable', $event)"
                >
                </ruckit-dropdown>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'assigned_truck'">
              <div class="dropdown-row">
                <label translate>Assigned Truck Name</label>
                <input type="text" name="assigned_truck" autocomplete="off"
                  #assignedTruckInput="ngModel"
                  [(ngModel)]="assignedTruck" />
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <!-- <notification *ngFor="let error of errors">{{ error }}</notification> -->
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); (false)" translate>
      Filter Results
    </button>
  </div>
</form>
