"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment-timezone");
var job_service_1 = require("../jobs/job.service");
var job_event_service_1 = require("../job-events/job-event.service");
var connection_1 = require("../connections/connection");
var connection_service_1 = require("../connections/connection.service");
var tag_service_1 = require("../tags/tag.service");
var driver_1 = require("../drivers/driver");
var driver_service_1 = require("../drivers/driver.service");
var truck_1 = require("../trucks/truck");
var truck_service_1 = require("../trucks/truck.service");
var assignment_serializer_1 = require("../assignments/assignment.serializer");
var assignment_service_1 = require("../assignments/assignment.service");
var dispatch_service_1 = require("./dispatch.service");
var index_1 = require("../shared/index");
var api_service_1 = require("../shared/api.service");
var preference_service_1 = require("../preferences/preference.service");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var QuickDispatchDialogComponent = /** @class */ (function () {
    function QuickDispatchDialogComponent(dialogRef, dispatchService, jobService, jobEventService, driverService, assignmentService, authenticationService, preferenceService) {
        this.dialogRef = dialogRef;
        this.dispatchService = dispatchService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.driverService = driverService;
        this.assignmentService = assignmentService;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.loading = false;
        this.preferenceKey = 'GeneralUserPreferences';
        this.jobDaysLoading = false;
        this.dispatching = false;
        this.isCrh = false;
        this.errors = [];
        this.existingDates = [];
        this.jobsDropdownConfig = {
            selectText: 'Select Job',
            loadingText: 'Loading Jobs...',
            noResultsText: 'No Jobs',
            service: job_service_1.JobService,
            nameProperty: 'displayName',
            query: {
                ordering: 'project__name,name,start_date',
                archived: 'False',
                serializer: 'JobGroupedDropdown'
            }
        };
        this.jobEventsDropdownConfig = {
            selectText: 'Select Job',
            loadingText: 'Loading Jobs...',
            noResultsText: 'No Jobs',
            service: job_event_service_1.JobEventService,
            nameProperty: 'jobDisplayName',
            query: {
                ordering: 'job__project__name,job__name',
                archived: 'False'
            }
        };
        this.carriersDropdownConfig = {
            selectText: 'Select Carrier',
            loadingText: 'Loading Carriers...',
            noResultsText: 'No Carriers',
            service: connection_service_1.ConnectionService,
            prefilledOptions: [],
            query: {
                ordering: 'organization__name',
                allow_dispatch: 'True',
                is_carrier: 'True'
            }
        };
        this.marketsConfig = {
            multiselect: true,
            selectText: 'Select Markets',
            loadingText: 'Loading Markets...',
            noResultsText: 'No Markets',
            service: tag_service_1.TagService,
            query: {}
        };
        this.favoriteTags = [];
        this.driversDropdownConfig = {
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            service: driver_service_1.DriverService,
            query: { ordering: 'name', status: 'active' },
            customOptionKeys: ['truck']
        };
        this.trucksDropdownConfig = {
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            service: truck_service_1.TruckService,
            query: { ordering: 'name' }
        };
        this.maxLoads = 1;
    }
    QuickDispatchDialogComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.user = this.authenticationService.user();
        this.isCrh = this.authenticationService.isCrh();
        this.getPreferences();
        this.getAssignmentDate();
        this.getDispatchFirstTime();
        var carrierOptions = [
            { name: 'My Drivers', id: 'my_drivers' },
            { name: 'All Carriers', id: 'all_carriers' },
        ];
        if (this.selectedJob && this.selectedJob.project
            && this.selectedJob.project.customerOrganization
            && this.selectedJob.project.customerOrganization['hasLeasedOrgs']) {
            carrierOptions.push({ name: 'Leased', id: 'all_leased' });
        }
        this.carriersDropdownConfig.prefilledOptions = carrierOptions;
    };
    QuickDispatchDialogComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferenceService.list({
            name: this.preferenceKey,
            type: 'user',
            profile: this.user && this.user.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
                _this.parsePreferences();
            }
        });
    };
    QuickDispatchDialogComponent.prototype.parsePreferences = function () {
        if (this.preference.blob && this.preference.blob['dispatchLoadType']) {
            var loadType = this.preference.blob['dispatchLoadType'];
            this.numberOfLoadsType = loadType === 'all-day' ? 'allDay' : 'numbered';
        }
    };
    QuickDispatchDialogComponent.prototype.isValidAssignment = function () {
        return this.selectedJobEvent && this.selectedCarrier &&
            this.selectedDriver && this.selectedTruck && this.uniqueStart &&
            this.maxLoads;
    };
    QuickDispatchDialogComponent.prototype.getAssignmentDate = function () {
        this.dateStart = new Date();
        this.dateStart.setHours(0, 0, 0, 0);
        if (this.selectedDates && this.selectedDates.length) {
            this.dateStart = this.selectedDates[0];
        }
        else {
            this.selectedDates = [this.dateStart];
        }
        this.dateEnd = lodash_1.clone(this.dateStart);
        this.dateEnd.setHours(23, 59, 59, 999);
        this.jobsDropdownConfig.query['jobevents__end__gte'] = this.dateStart.toISOString();
        if (this.jobsDropdown) {
            this.jobsDropdown.getRecords();
        }
        this.jobEventsDropdownConfig.query['end__gte'] = this.dateStart.toISOString();
        if (this.jobEventsDropdown) {
            this.jobEventsDropdown.getRecords();
        }
    };
    QuickDispatchDialogComponent.prototype.getJobDays = function (jobId) {
        var _this = this;
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        this.jobDaysLoading = true;
        this.daysReq = this.jobService.getDays(jobId).subscribe(function (days) {
            _this.existingDates = days.map(function (day) { return moment(day).toDate(); });
            _this.jobDaysLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.jobDaysLoading = false;
        });
    };
    QuickDispatchDialogComponent.prototype.getDispatchFirstTime = function () {
        this.dispatchFirstTime = moment();
        var intervals = Math.floor(this.dispatchFirstTime.minutes() / 5);
        if (this.dispatchFirstTime.minutes() % 5 !== 0) {
            intervals++;
            if (intervals === 20) {
                this.dispatchFirstTime.add(1, 'hours');
                intervals = 0;
            }
            this.dispatchFirstTime.minutes(intervals * 5);
            this.dispatchFirstTime.seconds(0);
        }
    };
    QuickDispatchDialogComponent.prototype.selectJobEvent = function (jobEventId) {
        var _this = this;
        if (jobEventId === void 0) { jobEventId = null; }
        var jobEventSelection;
        if (!this.job && !jobEventId) {
            this.loading = false;
            return;
        }
        var timezone = this.job && this.job.startLocation && this.job.startLocation.timezone;
        var start = moment(this.selectedDates[0]);
        start = moment.tz(start, timezone).startOf('day');
        var end = moment.tz(start, timezone).endOf('day');
        if (!jobEventId) {
            jobEventSelection = this.jobEventService.getJobEvents({
                job: this.job && this.job.id,
                shift1_start__gte: start && start.toISOString(),
                shift1_start__lte: end && end.toISOString()
            });
        }
        else {
            jobEventSelection = this.jobEventService.getJobEvents({
                id: jobEventId
            });
        }
        jobEventSelection.subscribe(function (jobEvents) {
            if (jobEvents.length > 0) {
                _this.selectedJobEvent = jobEvents[0];
                if (_this.job) {
                    _this.getJobDays(_this.job.id);
                }
                else {
                    var startDate = _this.selectedJobEvent.shift1StartTimestamp.split('T')[0];
                    _this.selectedDates = [moment(startDate).toDate()];
                }
                var endTimestamp = _this.selectedJobEvent && _this.selectedJobEvent.shift1EndTimestamp;
                _this.selectedJobEventEndTime = endTimestamp ? _this.selectedJobEvent['shift1EndTimestamp'] : _this.dateEnd;
                if (moment(_this.dispatchFirstTime).isBefore(_this.selectedJobEvent['shift1StartTimestamp'])) {
                    _this.dispatchFirstTime = moment(_this.selectedJobEvent['shift1StartTimestamp']);
                }
                else {
                    _this.getDispatchFirstTime();
                }
                _this.uniqueStart = _this.dispatchFirstTime.format('h:mm a');
                if (_this.user && _this.user.organization && _this.user.organization.carrier) {
                    var userOrganization = _this.carriersDropdown.options.find(function (c) { return c.id === _this.user.organization.carrier.id; });
                    if (!userOrganization) {
                        _this.carriersDropdown.options.splice(0, 0, { id: _this.user.organization.carrier.id, name: _this.user.organization.carrier.name });
                    }
                    _this.selectCarrier(_this.user.organization.carrier);
                }
                if (_this.selectedJobEvent && _this.selectedJobEvent.job && _this.selectedJobEvent.job.tags.length > 0) {
                    _this.selectTags(_this.selectedJobEvent.job.tags);
                }
                else {
                    _this.authenticationService.hasFavoriteTags() ? _this.selectTags(_this.user.favoriteTags) : _this.selectTags([]);
                }
                _this.driversDropdownConfig.query['shared_jobevent'] = _this.selectedJobEvent && _this.selectedJobEvent.id;
                _this.driversDropdownConfig.query['jobevent_available'] = _this.selectedJobEvent && _this.selectedJobEvent.id;
                if (_this.driversDropdown) {
                    _this.driversDropdown.getRecords();
                }
                _this.trucksDropdownConfig.query['shared_jobevent'] = _this.selectedJobEvent && _this.selectedJobEvent.id;
                if (_this.trucksDropdown) {
                    _this.trucksDropdown.getRecords();
                }
            }
            else {
                // No Job Events found for selected job, default date selection
                _this.dateChanged(_this.selectedDates.length ? _this.selectedDates : [new Date()]);
            }
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    QuickDispatchDialogComponent.prototype.selectCarrier = function (e) {
        this.selectedCarrier = e;
        this.carrierId = this.selectedCarrier &&
            this.selectedCarrier.id !== 'my_drivers' &&
            this.selectedCarrier.id !== 'all_carriers' ? this.selectedCarrier.id : null;
        this.driversDropdownConfig.query['carrier'] = this.carrierId;
        this.driversDropdownConfig.query['all_carriers'] = this.selectedCarrier && this.selectedCarrier.id === 'all_carriers';
        if (this.driversDropdown) {
            this.driversDropdown.getRecords();
        }
        this.trucksDropdownConfig.query['carrier'] = this.carrierId;
        this.trucksDropdownConfig.query['all_carriers'] = this.selectedCarrier && this.selectedCarrier.id === 'all_carriers';
        if (this.trucksDropdown) {
            this.trucksDropdown.getRecords();
        }
    };
    QuickDispatchDialogComponent.prototype.selectTags = function (tags) {
        this.selectedTags = tags;
        if (tags.length > 0) {
            this.tagsUrlList = tags.map(function (tag) { return tag.name; }).join(',');
        }
        else {
            this.tagsUrlList = null;
        }
        this.driversDropdownConfig.query['tags'] = this.tagsUrlList;
        if (this.driversDropdown) {
            this.driversDropdown.getRecords();
        }
        this.trucksDropdownConfig.query['tags'] = this.tagsUrlList;
        if (this.trucksDropdown) {
            this.trucksDropdown.getRecords();
        }
    };
    QuickDispatchDialogComponent.prototype.selectDriver = function (e) {
        this.selectedDriver = e;
        if (this.selectedDriver.truck && this.selectedDriver.truck.id) {
            this.selectedTruck = this.selectedDriver.truck;
            if (this.trucksDropdown) {
                this.trucksDropdown.getRecords();
            }
        }
    };
    QuickDispatchDialogComponent.prototype.selectTruck = function (e) {
        var _this = this;
        this.selectedTruck = e;
        if (this.selectedDriver && (this.selectedDriver.truck && this.selectedDriver.truck.id !== this.selectedTruck.id)) {
            this.selectedDriver.truck = this.selectedTruck;
            this.driverService.assign(this.selectedDriver).subscribe(function (res) {
                _this.loading = false;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    QuickDispatchDialogComponent.prototype.onSelect = function (type, e) {
        switch (type) {
            case 'carriers':
                this.selectCarrier(e);
                break;
            case 'drivers':
                this.selectDriver(e);
                break;
            case 'trucks':
                this.selectTruck(e);
                break;
            case 'jobs':
                this.job = e;
                if (this.job) {
                    this.getJobDays(this.job.id);
                    this.selectJobEvent();
                }
                break;
            case 'jobEvents':
                this.selectJobEvent(e.id);
                break;
        }
    };
    QuickDispatchDialogComponent.prototype.dispatch = function () {
        var _this = this;
        this.dispatching = true;
        var date = this.selectedDates[0];
        var dateString = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        var formatString = 'MM/DD/YYYY h:mm A';
        var uniqueStartFormatted = this.uniqueStart ?
            moment(dateString + ' ' + this.uniqueStart, [formatString]).format() :
            moment(dateString + ' ' + this.dispatchFirstTime, [formatString]).format();
        var assignment = new assignment_serializer_1.AssignmentSerializer().fromJson({
            driver: this.selectedDriver.id,
            truck: this.selectedTruck.id,
            jobevent: this.selectedJobEvent && this.selectedJobEvent.id,
            shift: 'shift1',
            uniqueStart: uniqueStartFormatted,
            maxNumberOfLoads: this.numberOfLoadsType === 'allDay' ? 0 : this.maxLoads
        });
        this.assignmentService.save(assignment).subscribe(function (_assignment) {
            _this.dispatchService.save({
                jobEvent: _this.selectedJobEvent && _this.selectedJobEvent.id,
                notify_new: true
            }).subscribe(function (res) {
                _this.dialogRef.close();
                _this.dispatching = false;
                _this.callback();
            }, function (err) { _this.errors = api_service_1.parseErrors(err); });
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.dispatching = false;
        });
    };
    QuickDispatchDialogComponent.prototype.dateChanged = function (dates) {
        var _this = this;
        if (this.job) {
            this.selectedDates = dates;
            this.loading = true;
            var newDates = this.selectedDates.filter(function (d) { return !_this.existingDates.includes(d); });
            if (this.existingDates && this.existingDates.length && newDates && newDates.length) {
                this.addJobDays(lodash_1.union(this.selectedDates, this.existingDates), this.job.id);
            }
            else {
                this.refresh();
            }
        }
    };
    QuickDispatchDialogComponent.prototype.addJobDays = function (dates, jobId) {
        var _this = this;
        var _dates = dates.map(function (d) { return _this.convertDateFormat(d); });
        this.jobService.updateDays(jobId, { dates: _dates }).subscribe(function (result) {
            _this.refresh();
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    QuickDispatchDialogComponent.prototype.convertDateFormat = function (date) {
        if (isNaN(+date)) {
            return;
        }
        return date.toISOString().split('T')[0];
    };
    QuickDispatchDialogComponent.prototype.refresh = function () {
        this.getAssignmentDate();
        if (this.selectedJobEvent && this.selectedJobEvent.job) {
            this.job = this.selectedJobEvent.job;
        }
        this.selectJobEvent();
    };
    return QuickDispatchDialogComponent;
}());
exports.QuickDispatchDialogComponent = QuickDispatchDialogComponent;
