import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MatCheckboxChange, MatTable } from '@angular/material';
import * as moment from 'moment';

import { PayRecord } from '../../pay-record';
import { DateTimeRange } from '../../../shared';
import { PaySheetService } from '../../pay-sheet.service';
import { DriverContextEvent } from '../../../drivers/driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'driver-pay-table',
  templateUrl: './driver-pay-table.component.html',
  styleUrls: ['../driver-pay.component.scss']
})
export class DriverPayTableComponent {
  @Output() sortRows: EventEmitter<{
    active: string,
    direction: 'asc' | 'desc' | ''
  }> = new EventEmitter();

  selectedRowIds: string[] = [];
  allSelected = false;
  @Output() selectAll: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedRows: EventEmitter<string[]> = new EventEmitter();
  @Output() rowEdits: EventEmitter<PayRecord[]> = new EventEmitter();
  @Input() readOnly = false;

  expandedRowIds: string[] = [];
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  displayedColumns = [
    'selector', 'truckNumber', 'driver', 'carrier', 'orderNumber', 'scheduledStart',
    'shiftStart', 'geofenceStart', 'punchcardStart', 'firstLoad', 'payStart', 'shiftEnd',
    'geofenceEnd', 'punchcardEnd', 'lastLoad', 'payEnd', 'totalTime', 'payTime', 'adjustment'
  ];

  @ViewChild('payLineTable', { static: false }) payLineTable: MatTable<DateTimeRange>;
  displayedPayLineColumns = ['payLineAction', 'index', 'payStart', 'payEnd', 'payTime'];

  payStartOptions = ['Scheduled Start', 'Shift Start', 'Geofence Start', 'Punchcard Start', 'First Load'];
  payEndOptions = ['Shift End', 'Geofence End', 'Punchcard End', 'Last Load'];

  rowsValue: PayRecord[];
  @Output() rowsChange: EventEmitter<PayRecord[]> = new EventEmitter();
  @Input() get rows() { return this.rowsValue; }
  set rows(data: PayRecord[]) {
    this.rowsValue = data.map(row => {
      row.data.date = row.data.assignment &&
        row.data.assignment.jobevent &&
        row.data.assignment.jobevent.shift1StartTimestamp &&
        row.data.assignment.jobevent.shift1StartTimestamp.split('T')[0].replace(/-/g, '');
      if (row.data.rowData.trips) {
        row.data.rowData.trips.map(trip => (trip.jobEvent = row.data.assignment.jobevent));
      }
      if (row.data.rowData.predictedTrips) {
        row.data.rowData.predictedTrips.map(trip => (trip.jobEvent = row.data.assignment.jobevent));
      }
      if (row.data.rowData.punchcards) {
        row.data.rowData.punchcards.map(punchcard => (punchcard.jobEvent = row.data.assignment.jobevent));
      }
      return row;
    });
    this.rowsChange.emit(data);
    this.selectedRowIds = [];
  }

  // Dev Note: This service is used in the template for getting duration strings. Do not remove!
  constructor(private paySheetService: PaySheetService) {}

  isSelected(row): boolean {
    return this.selectedRowIds.findIndex(id => id === row.data.referenceId) > -1;
  }

  selector(event: MatCheckboxChange, row?: PayRecord) {
    if (row) {
      if (event.checked) {
        this.selectedRowIds.push(row.data.referenceId);
      } else {
        this.selectedRowIds = this.selectedRowIds.filter(v => (v !== row.data.referenceId));
      }
      this.allSelected = false;
    } else {
      this.selectedRowIds = event.checked ? this.rows.map(r => (r.data.referenceId)) : [];
      this.allSelected = event.checked;
    }
    this.selectedRows.emit(this.selectedRowIds);
    this.selectAll.emit(this.allSelected);
  }

  expandRow(row: PayRecord) {
    if (this.expandedRow(row)) {
      this.expandedRowIds = [];
    } else { this.expandedRowIds = [row.data.referenceId]; }
  }

  expandedRow(row: PayRecord): boolean { return this.expandedRowIds.includes(row.data.referenceId); }

  changePayBasis(row: PayRecord, option: string) {
    if (!row.data.rowData.payLines || !row.data.rowData.payLines[0]) { row.data.rowData.payLines = [<DateTimeRange>{}]; }
    row.data.rowData.payAdjustmentTotal = 0;
    switch (option) {
      case 'Scheduled Start':
        row.data.rowData.payLines[0].startDatetime = row.data.assignment.uniqueStart || row.data.assignment.jobevent.shift1StartTimestamp;
        break;
      case 'Shift Start':
        row.data.rowData.payLines[0].startDatetime = row.data.assignment.shifts &&
                                                row.data.assignment.shifts[0] &&
                                                row.data.assignment.shifts[0].startTime;
        break;
      case 'Geofence Start':
        row.data.rowData.payLines[0].startDatetime = row.data.rowData.predictedTrips &&
                                                row.data.rowData.predictedTrips[0] &&
                                                row.data.rowData.predictedTrips[0].startTimeTimestamp;
        break;
      case 'Punchcard Start':
        row.data.rowData.payLines[0].startDatetime = row.data.rowData.punchcards &&
                                                row.data.rowData.punchcards[0] &&
                                                row.data.rowData.punchcards[0].startTimeTimestamp;
        break;
      case 'First Load':
        row.data.rowData.payLines[0].startDatetime = row.data.rowData.trips &&
                                                row.data.rowData.trips[0] &&
                                                row.data.rowData.trips[0].loadingCompleteTime;
        break;
      case 'Shift End':
        row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.assignment.shifts &&
                                                                            row.data.assignment.shifts[0] &&
                                                                            row.data.assignment.shifts[0].endTime;
        break;
      case 'Geofence End':
        row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.predictedTrips &&
          row.data.rowData.predictedTrips[0] &&
          row.data.rowData.predictedTrips[row.data.rowData.predictedTrips.length - 1].endTimeTimestamp;
        break;
      case 'Punchcard End':
        row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.punchcards &&
          row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1] &&
          row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1].endTimeTimestamp;
        break;
      case 'Last Load':
        row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.trips &&
          row.data.rowData.trips[0] &&
          row.data.rowData.trips[row.data.rowData.trips.length - 1].endTimeTimestamp;
        break;
    }
    row.data.editing = false;
    this.rowEdits.emit([row]);
  }

  createPayLine(row: PayRecord) {
    let newStartTime;
    if (row.data.rowData.payLines && row.data.rowData.payLines.length) {
      const lastPayLine = row.data.rowData.payLines[row.data.rowData.payLines.length - 1];
      newStartTime = moment(lastPayLine.endDatetime);
    } else {
      row.data.rowData.payLines = [];
      newStartTime = moment(row.data.assignment.jobevent.shift1StartTimestamp);
    }
    row.data.rowData.payLines.push({
      startDatetime: newStartTime.toISOString(),
      endDatetime: newStartTime.add(1, 'hours').toISOString()
    });
    this.rowEdits.emit([row]);
    if (this.payLineTable) {
      this.payLineTable.dataSource = row.data.rowData && row.data.rowData.payLines;
      this.payLineTable.renderRows();
    }
  }

  deletePayLine(row: PayRecord, index: number) {
    row.data.rowData.payLines.splice(index, 1);
    this.rowEdits.emit([row]);
    if (this.payLineTable) {
      this.payLineTable.dataSource = row.data.rowData && row.data.rowData.payLines;
      this.payLineTable.renderRows();
    }
  }

  editRow(row: PayRecord, field: string, event: Event, payLineIndex?: number) {
    let time: moment.Moment, newTime: moment.Moment;
    if (row.data.rowData.payLines.length === 0) { row.data.rowData.payLines.push({ startDatetime: null, endDatetime: null }); }
    if (field === 'payStartTime' || field === 'payStartDate') {
      time = moment(row.data.rowData.payLines[(payLineIndex ? payLineIndex : 0)].startDatetime);
    } else if (field === 'payEndTime' || field === 'payEndDate' || field === 'adjustment') {
      time = moment(row.data.rowData.payLines[(payLineIndex ? payLineIndex : row.data.rowData.payLines.length - 1)].endDatetime);
    }
    if (!row.data.rowData.payAdjustmentTotal) { row.data.rowData.payAdjustmentTotal = 0; }
    switch (field) {
      case 'payStartTime':
        newTime = moment(time.format('YYYYMMDD') + event.target['value'], 'YYYYMMDDHH:mm');
        row.data.rowData.payLines[(payLineIndex ? payLineIndex : 0)].startDatetime = newTime.toISOString();
        break;
      case 'payStartDate':
        newTime = moment(event.target['value'] + time.format('HH:mm'), 'YYYY-MM-DDHH:mm');
        row.data.rowData.payLines[(payLineIndex ? payLineIndex : 0)].startDatetime = newTime.toISOString();
        break;
      case 'payEndTime':
        newTime = moment(time.format('YYYYMMDD') + event.target['value'], 'YYYYMMDDHH:mm');
        row.data.rowData.payLines[(payLineIndex ? payLineIndex : row.data.rowData.payLines.length - 1)].endDatetime = newTime.toISOString();
        break;
      case 'payEndDate':
        newTime = moment(event.target['value'] + time.format('HH:mm'), 'YYYY-MM-DDHH:mm');
        row.data.rowData.payLines[(payLineIndex ? payLineIndex : row.data.rowData.payLines.length - 1)].endDatetime = newTime.toISOString();
        break;
      case 'adjustment':
        row.data.rowData.payAdjustmentTotal = Number(event.target['value']);
        break;
      default:
        break;
    }
    this.rows[this.rows.findIndex(r => (r.id === row.id))] = row;
    this.rowEdits.emit([row]);
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }
}
