"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./plants.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../shared/notification/notification.component.ngfactory");
var i3 = require("@ngx-translate/core");
var i4 = require("../shared/notification/notification.component");
var i5 = require("@angular/common");
var i6 = require("../shared/fancy-table/fancy-table.component.ngfactory");
var i7 = require("../shared/fancy-table/fancy-table.component");
var i8 = require("@angular/router");
var i9 = require("@angular/material/dialog");
var i10 = require("ng2-dragula");
var i11 = require("../preferences/preference.service");
var i12 = require("../shared/authentication.service");
var i13 = require("../shared/toolbar/toolbar.component.ngfactory");
var i14 = require("../shared/toolbar/toolbar.component");
var i15 = require("../tags/tag.service");
var i16 = require("../users/user.service");
var i17 = require("../shared/action-bar/action-bar.component.ngfactory");
var i18 = require("../shared/action-bar/action-bar.component");
var i19 = require("../shared/ruckit-dropdown/ruckit-dropdown.component.ngfactory");
var i20 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var i21 = require("../shared/dropdown/dropdown.component.ngfactory");
var i22 = require("../shared/dropdown/dropdown.component");
var i23 = require("../map/truck-map/truck-map.component.ngfactory");
var i24 = require("../map/truck-map/truck-map.component");
var i25 = require("../map/map.service");
var i26 = require("../locations/location.service");
var i27 = require("../job-events/job-event.service");
var i28 = require("../assignments/assignment.service");
var i29 = require("./plants.component");
var styles_PlantsComponent = [i0.styles];
var RenderType_PlantsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlantsComponent, data: {} });
exports.RenderType_PlantsComponent = RenderType_PlantsComponent;
function View_PlantsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "notification", [["translate", ""]], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(2, 245760, null, 0, i4.NotificationComponent, [], null, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_PlantsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "truck-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "carrier-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.latestTruck && _v.parent.context.row.latestTruck.displayName); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_v.parent.context.row.latestTruck && _v.parent.context.row.latestTruck.carrier) || "\u00A0"); _ck(_v, 5, 0, currVal_1); }); }
function View_PlantsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "eta"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TODO"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "customer"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.organization || "\u00A0"); _ck(_v, 5, 0, currVal_0); }); }
function View_PlantsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "data"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.row.status || "\u00A0"); _ck(_v, 2, 0, currVal_0); }); }
function View_PlantsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.value; _ck(_v, 1, 0, currVal_0); }); }
function View_PlantsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlantsComponent_4)), i1.ɵdid(3, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlantsComponent_5)), i1.ɵdid(5, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlantsComponent_6)), i1.ɵdid(7, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlantsComponent_7)), i1.ɵdid(9, 16384, null, 0, i5.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.column; _ck(_v, 1, 0, currVal_0); var currVal_1 = "truck-carrier"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "eta-customer"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "status"; _ck(_v, 7, 0, currVal_3); }, null); }
function View_PlantsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "actions-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "fancy-table", [], null, [[null, "availableColumnsChange"], [null, "displayedColumnsChange"], [null, "availableFiltersChange"], [null, "filtersChange"], [null, "searchChange"], [null, "onDataLoaded"], [null, "selectionChanged"], [null, "onClickAction"], [null, "onMenuAction"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("availableColumnsChange" === en)) {
        var pd_1 = ((_co.availableColumns = $event) !== false);
        ad = (pd_1 && ad);
    } if (("displayedColumnsChange" === en)) {
        var pd_2 = ((_co.displayedColumns = $event) !== false);
        ad = (pd_2 && ad);
    } if (("availableFiltersChange" === en)) {
        var pd_3 = ((_co.availableFilters = $event) !== false);
        ad = (pd_3 && ad);
    } if (("filtersChange" === en)) {
        var pd_4 = ((_co.appliedFilters = $event) !== false);
        ad = (pd_4 && ad);
    } if (("searchChange" === en)) {
        var pd_5 = ((_co.search = $event) !== false);
        ad = (pd_5 && ad);
    } if (("onDataLoaded" === en)) {
        var pd_6 = (_co.onDataLoaded($event) !== false);
        ad = (pd_6 && ad);
    } if (("selectionChanged" === en)) {
        var pd_7 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_7 && ad);
    } if (("onClickAction" === en)) {
        var pd_8 = (_co.clickAction($event) !== false);
        ad = (pd_8 && ad);
    } if (("onMenuAction" === en)) {
        var pd_9 = (_co.menuAction($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, i6.View_FancyTableComponent_0, i6.RenderType_FancyTableComponent)), i1.ɵdid(2, 770048, [[2, 4], ["driverAssignmentsTable", 4]], 2, i7.FancyTableComponent, [i8.ActivatedRoute, i8.Router, i9.MatDialog, i1.Injector, i10.DragulaService, i11.PreferenceService, i12.AuthenticationService, i1.Renderer2], { availableColumns: [0, "availableColumns"], displayedColumns: [1, "displayedColumns"], filters: [2, "filters"], availableFilters: [3, "availableFilters"], search: [4, "search"], config: [5, "config"] }, { searchChange: "searchChange", filtersChange: "filtersChange", onClickAction: "onClickAction", selectionChanged: "selectionChanged", onMenuAction: "onMenuAction", onDataLoaded: "onDataLoaded" }), i1.ɵqud(603979776, 4, { columnTemplates: 0 }), i1.ɵqud(603979776, 5, { detailTemplates: 0 }), (_l()(), i1.ɵand(0, [[4, 2], [1, 2], ["columnTemplates", 2]], 2, 0, null, View_PlantsComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableColumns; var currVal_1 = _co.displayedColumns; var currVal_2 = _co.appliedFilters; var currVal_3 = _co.availableFilters; var currVal_4 = _co.search; var currVal_5 = _co.driverAssignmentsTableConfig; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_PlantsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { columnTemplates: 0 }), i1.ɵqud(671088640, 2, { driverAssignmentsTable: 0 }), i1.ɵqud(671088640, 3, { columnToggle: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 27, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "my-toolbar", [], null, null, null, i13.View_ToolbarComponent_0, i13.RenderType_ToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i14.ToolbarComponent, [i8.Router, i12.AuthenticationService, i15.TagService, i16.UserService], { title: [0, "title"] }, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlantsComponent_1)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 15, "action-bar", [], null, null, null, i17.View_ActionBarComponent_0, i17.RenderType_ActionBarComponent)), i1.ɵdid(10, 49152, null, 0, i18.ActionBarComponent, [], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "div", [["class", "left-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "ruckit-dropdown", [], null, [[null, "onUserSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onUserSelect" === en)) {
        var pd_0 = (_co.onSelectLocation($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_RuckitDropdownComponent_0, i19.RenderType_RuckitDropdownComponent)), i1.ɵdid(13, 770048, null, 0, i20.RuckitDropdownComponent, [i1.Injector, i3.TranslateService], { selectedOption: [0, "selectedOption"], config: [1, "config"] }, { onUserSelect: "onUserSelect" }), (_l()(), i1.ɵeld(14, 0, null, 0, 10, "div", [["class", "right-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "dropdown", [["class", "actions"]], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.setSelectedAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_DropdownComponent_0, i21.RenderType_DropdownComponent)), i1.ɵdid(16, 770048, null, 0, i22.DropdownComponent, [i1.ElementRef, i1.ElementRef], { title: [0, "title"], options: [1, "options"], disabled: [2, "disabled"] }, { onSelect: "onSelect" }), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "segmented-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["class", "btn btn-default"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomTo("trucks") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Show All Trucks "])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "a", [["class", "btn btn-default"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomTo("location") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Zoom to Location "])), (_l()(), i1.ɵeld(25, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlantsComponent_2)), i1.ɵdid(27, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 2, "div", [["class", "truck-map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "truck-map", [], null, [[null, "selectedDriver"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDriver" === en)) {
        var pd_0 = (_co.scrollToSelectedDriver($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i23.View_TruckMapComponent_0, i23.RenderType_TruckMapComponent)), i1.ɵdid(30, 770048, null, 0, i24.TruckMapComponent, [i25.MapService, i26.LocationService, i27.JobEventService, i28.AssignmentService, i12.AuthenticationService, i11.PreferenceService, i1.ChangeDetectorRef, i9.MatDialog], { dateRange: [0, "dateRange"], selectedLocationId: [1, "selectedLocationId"], listComponent: [2, "listComponent"] }, { selectedDriver: "selectedDriver" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("Plants")), ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.errors; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.selectedLocationId; var currVal_3 = _co.locationDropdownConfig; _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Actions")), ""); var currVal_5 = _co.multipleActionDropdownOptions; var currVal_6 = _co.multiselectDisabled(); _ck(_v, 16, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 20, 0, currVal_7); var currVal_8 = ""; _ck(_v, 23, 0, currVal_8); var currVal_9 = _co.driverAssignmentsTableConfigReady; _ck(_v, 27, 0, currVal_9); var currVal_10 = _co.activeRange; var currVal_11 = _co.selectedLocationId; var currVal_12 = _co.driverAssignmentsTable; _ck(_v, 30, 0, currVal_10, currVal_11, currVal_12); }, null); }
exports.View_PlantsComponent_0 = View_PlantsComponent_0;
function View_PlantsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "plants", [], null, null, null, View_PlantsComponent_0, RenderType_PlantsComponent)), i1.ɵdid(1, 114688, null, 0, i29.PlantsComponent, [i8.ActivatedRoute, i8.Router, i9.MatDialog, i28.AssignmentService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PlantsComponent_Host_0 = View_PlantsComponent_Host_0;
var PlantsComponentNgFactory = i1.ɵccf("plants", i29.PlantsComponent, View_PlantsComponent_Host_0, { availableColumns: "availableColumns", displayedColumns: "displayedColumns", availableFilters: "availableFilters", appliedFilters: "appliedFilters", search: "search" }, { availableColumnsChange: "availableColumnsChange", displayedColumnsChange: "displayedColumnsChange", availableFiltersChange: "availableFiltersChange", appliedFiltersChange: "appliedFiltersChange", searchChange: "searchChange" }, []);
exports.PlantsComponentNgFactory = PlantsComponentNgFactory;
