"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var moment = require("moment");
var core_2 = require("@ngx-translate/core");
var condensed_trip_service_1 = require("../condensed-trip.service");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var trip_filters_dialog_component_1 = require("../trip-filters-dialog.component");
var view_signatures_dialog_component_1 = require("../../checkins/view-signatures-dialog.component");
var index_1 = require("../../shared/dialogs/index");
var authentication_service_1 = require("../../shared/authentication.service");
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var column_toggle_component_1 = require("../../shared/column-toggle/column-toggle.component");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var trip_service_1 = require("../trip.service");
var app_constants_1 = require("../../app.constants");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var CondensedTripsComponent = /** @class */ (function () {
    function CondensedTripsComponent(route, router, tripService, authenticationService, translationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.tripService = tripService;
        this.authenticationService = authenticationService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'job-date', title: this.translationService.instant('Job Date'), sortable: true, sortBy: 'jobevent__shift1_start' },
            { key: 'job', title: this.translationService.instant('Job'), sortable: true, sortBy: 'jobevent__job__name' },
            { key: 'job-number', title: this.translationService.instant('Job #'), sortable: false },
            { key: 'phase-code', title: this.translationService.instant('Phase Code'), sortable: false },
            { key: 'load-ticket-number', title: this.translationService.instant('Load Ticket #'), sortable: false },
            { key: 'load-ticket-weight', title: this.translationService.instant('Load Wt'), sortable: false },
            { key: 'load-ticket-image', title: this.translationService.instant('Load Ticket'), sortable: false },
            { key: 'load-signature-image', title: this.translationService.instant('Load Signature'), sortable: false },
            { key: 'unload-ticket-number', title: this.translationService.instant('Unload Ticket #'), sortable: false },
            { key: 'unload-ticket-weight', title: this.translationService.instant('Unload Wt'), sortable: false },
            { key: 'unload-ticket-image', title: this.translationService.instant('Unload Ticket'), sortable: false },
            { key: 'unload-signature-image', title: this.translationService.instant('Unload Signature'), sortable: false },
            { key: 'carrier', title: this.translationService.instant('Carrier'), sortable: true, sortBy: 'driver__carrier__name' },
            { key: 'driver', title: this.translationService.instant('Driver'), sortable: true, sortBy: 'driver__profile__last_name,driver__profile__first_name' },
            { key: 'truck', title: this.translationService.instant('Truck'), sortable: true, sortBy: 'truck__name' },
            { key: 'time', title: this.translationService.instant('Time'), sortable: false },
            { key: 'duration', title: this.translationService.instant('Duration'), sortable: false },
            { key: 'payable-to', title: this.translationService.instant('Payable To'), sortable: true, sortBy: 'jobevent__owner_organization__name' },
            { key: 'customer', title: this.translationService.instant('Customer'), sortable: true, sortBy: 'jobevent__customer_organization__name' },
            { key: 'invoice', title: this.translationService.instant('Invoice Rate'), sortable: false },
            { key: 'invoice-total', title: this.translationService.instant('Invoice Total'), sortable: false },
            { key: 'haul', title: this.translationService.instant('Haul Rate'), sortable: false },
            { key: 'haul-total', title: this.translationService.instant('Haul Total'), sortable: false },
            { key: 'invoice-number', title: this.translationService.instant('Invoice #'), sortable: false },
            { key: 'expense-title', title: this.translationService.instant('Expense'), sortable: false },
            { key: 'paystub-title', title: this.translationService.instant('Paystub'), sortable: false },
            { key: 'billing-id', title: this.translationService.instant('Billing ID'), sortable: false },
            { key: 'reviewed-status', title: this.translationService.instant('Reviewed Status'), sortable: false },
            { key: 'reviewed-by', title: this.translationService.instant('Reviewed By'), sortable: false },
            { key: 'connex-paver-start-unloading', title: this.translationService.instant('Paver Start Unloading'), sortable: false },
            { key: 'connex-paver-end-unloading', title: this.translationService.instant('Paver End Unloading'), sortable: false },
            { key: 'loading-location', title: this.translationService.instant('Job Loading Location'), sortable: false },
            { key: 'geo-loading-location', title: this.translationService.instant('Geo Loading Location'), sortable: false },
            { key: 'unloading-location', title: this.translationService.instant('Job Unloading Location'), sortable: false },
            { key: 'geo-unloading-location', title: this.translationService.instant('Geo Unloading Location'), sortable: false },
            { key: 'actions', title: this.translationService.instant('Actions'), sortable: false }
        ];
        this.displayedColumns = [
            'select', 'job-date', 'job', 'job-number', 'load-ticket-number', 'load-ticket-weight',
            'load-ticket-image', 'load-signature-image', 'unload-ticket-number',
            'unload-ticket-weight', 'unload-ticket-image', 'unload-signature-image',
            'carrier', 'driver', 'truck', 'time', 'duration', 'payable-to', 'customer',
            'invoice', 'invoice-total', 'haul', 'haul-total', 'invoice-number',
            'expense-title', 'paystub-title', 'billing-id', 'reviewed-status',
            'reviewed-by', 'actions'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.appliedFilterQueryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            payableTo: 'jobevent__owner_organization',
            job: 'jobevent__job',
            jobNumber: 'jobevent__job__job_number__icontains',
            driver: 'driver',
            truck: 'truck',
            startDate: 'jobevent__shift1_start__gte',
            endDate: 'jobevent__shift1_start__lte',
            edited: 'edited',
            incomplete: 'completed',
            retake: 'retake_status',
            carrier: 'driver__carrier',
            duplicates: 'only_dupes',
            uninvoiced: 'uninvoiced',
            missedAssignedLocations: 'missed_assigned_locations',
            unvoid: 'void'
        };
        this.search = '';
        this.loading = true;
        this.allSelected = false;
        this.checkingDuplicates = false;
        this.selectedTrips = [];
        this.excludeTrips = [];
        this.enabledFeatures = [];
        this.selectedCount = 0;
        this.signatureImageType = app_constants_1.TRIPSIGNATUREIMAGETYPE;
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            hasCustomFields: true,
            filterQuery: false,
            service: condensed_trip_service_1.CondensedTripService,
            preferenceKey: 'CondensedTripsComponent-CondensedTripService',
            query: {
                serializer: 'AllTrips',
                exclude_cf: true
            },
            collectionTitle: this.translationService.instant('Trips'),
            noResultsText: this.translationService.instant('a trip'),
            newRecordRoute: ['/trips/new'],
            sortBy: '-start_time',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false, returnTo: '/trips' },
                { name: this.translationService.instant('Void'), action: 'void', link: false, external: false },
                { name: this.translationService.instant('Unvoid'), action: 'unvoid', link: false, external: false },
                { name: this.translationService.instant('Details'), action: '', link: true, external: false, returnTo: '/trips' }
            ]
        };
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Export'), action: 'export', link: false },
            { name: this.translationService.instant('Void'), action: 'void', link: false }
        ];
        this.lockedExportFields = null;
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.viewSignaturesCallback = function () {
            // Update Signature Status Icon
        };
        this.exportCallback = function () {
            _this.allSelected = false;
            _this.selectedCount = 0;
        };
        this.allSubscriptionsToUnsubscribe = [];
    }
    CondensedTripsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
        }));
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures.includes('hasTicketQcVt')) {
            (_a = this.availableColumns).splice.apply(_a, [-1, 0].concat([
                { key: 'plant-qc', title: this.translationService.instant('Plant QC'), sortable: false },
                { key: 'plant-vt', title: this.translationService.instant('Plant VT'), sortable: false },
                { key: 'roadway-qc', title: this.translationService.instant('Roadway QC'), sortable: false },
                { key: 'roadway-vt', title: this.translationService.instant('Roadway VT'), sortable: false }
            ]));
        }
        if (this.enabledFeatures.includes('tripExportFields')) {
            this.lockedExportFields = this.authenticationService.getFeature('tripExportFields');
            this.multipleActionDropdownOptions.push({ name: 'Spectrum Export', action: 'locked-export', link: false });
        }
        if (this.appliedFilters.length === 0) {
            var startDate = moment();
            var tripDays = this.authenticationService.getFeature('hasDefaultStartFilterForTrips');
            if (tripDays) {
                startDate = startDate.subtract(tripDays, 'day');
            }
            else {
                startDate = startDate.subtract(1, 'month');
            }
            startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            var defaultFilter = new filter_option_1.FilterOption({
                filterType: 'date',
                default: true,
                key: 'startDate',
                title: this.translationService.instant('Start Date'),
                displayValues: startDate.format('MM/DD/YYYY') || null,
                values: startDate.toISOString(),
                query: {
                    jobevent__shift1_start__gte: startDate.toISOString()
                }
            });
            this.appliedFilters.push(defaultFilter);
        }
    };
    CondensedTripsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    CondensedTripsComponent.prototype.openSignatures = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            _this.dialog.open(view_signatures_dialog_component_1.ViewSignaturesDialogComponent, {
                width: '722px',
                data: {
                    trip: _trip,
                    callback: _this.viewSignaturesCallback
                }
            });
        }, function (err) {
            console.error(err);
        });
    };
    CondensedTripsComponent.prototype.duplicateCheck = function () {
        var duplicateKey = 'duplicates';
        this.checkingDuplicates = !this.checkingDuplicates;
        if (this.checkingDuplicates) {
            var filter = new filter_option_1.FilterOption({
                filterType: 'checkbox',
                key: duplicateKey,
                title: this.translationService.instant('Duplicates'),
                displayValues: 'True' || null,
                values: true,
                query: {
                    only_dupes: 'True'
                }
            });
            this.appliedFilters.push(filter);
        }
        else {
            var filter = lodash_1.find(this.appliedFilters, { key: duplicateKey });
            if (filter) {
                this.appliedFilters = lodash_1.pull(this.appliedFilters, filter);
            }
        }
        this.appliedFilters = this.appliedFilters.slice();
    };
    CondensedTripsComponent.prototype.menuAction = function (event) {
        switch (event[0]) {
            case 'void':
                this.void(event[1]);
                break;
            case 'unvoid':
                this.unvoid(event[1]);
                break;
        }
    };
    CondensedTripsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedTrips();
                break;
            case 'locked-export':
                this.exportSelectedTrips(true);
                break;
            case 'void':
                this.voidSelectedTrips();
                break;
        }
    };
    CondensedTripsComponent.prototype.voidSelectedTrips = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        trips = trips ? trips : this.selectedTrips;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Void Trips?'),
            body: this.translationService.instant('These trips will be marked as \'Void\' and will not be visible for the Job.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Void')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                trips.forEach(function (trip, index) {
                    _this.tripService.save({ id: trip.id, void: true }).subscribe(function (result) {
                        trip['void'] = true;
                        trip['loading'] = false;
                        if (index === trips.length - 1) {
                            _this.allSelected = false;
                            _this.selectedTrips = [];
                            _this.selectedCount = 0;
                            setTimeout(function () {
                                _this.changeSearch();
                            }, 2000);
                        }
                    }, function (err) {
                        _this.errors = err;
                    });
                });
            }
            _this.confirmDialog = null;
        });
    };
    CondensedTripsComponent.prototype.exportSelectedTrips = function (locked) {
        var _this = this;
        if (locked === void 0) { locked = false; }
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, this.selectedTrips, this.excludeTrips, this.allSelected, this.search), params = _a.params, scope = _a.scope;
        if (this.enabledFeatures.includes('hasExportTracking')) {
            scope['markAsExported'] = true;
        }
        if (this.enabledFeatures.includes('hasExportProtection')) {
            params = params.set('unexported', 'True');
        }
        this.tripService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'trips',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: _this.exportCallback,
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.tripService,
                    lockedFields: locked ? _this.lockedExportFields : null
                }
            });
        }, function (err) { _this.errors = err; });
    };
    CondensedTripsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        if (!this.allSelected) {
            this.selectedTrips = event.selection.selected;
            this.excludeTrips = [];
        }
        else {
            this.selectedTrips = [];
            this.excludeTrips = event.exclusion.selected;
        }
    };
    CondensedTripsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(trip_filters_dialog_component_1.TripFiltersDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
            dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'job' }), 'values');
            dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'project' }), 'values');
            dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'customer' }), 'values');
            dialog.componentInstance.model.driver = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'driver' }), 'values');
            dialog.componentInstance.model.truck = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'truck' }), 'values');
            dialog.componentInstance.model.payableTo = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'payableTo' }), 'values');
            dialog.componentInstance.model.carrier = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'carrier' }), 'values');
            var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
            if (startDate) {
                dialog.componentInstance.model.startDate = new Date(startDate);
            }
            var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
            if (endDate) {
                dialog.componentInstance.model.endDate = new Date(endDate);
            }
            dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
                acc[filter.key] = filter.values;
                return acc;
            }, {}));
            this.filtersDialog = dialog.componentInstance;
        }
    };
    CondensedTripsComponent.prototype.filterChanges = function (filterRes) {
        var _this = this;
        var falseyFilters = [];
        Object.keys(filterRes).forEach(function (key) {
            if (filterRes[key]) {
                var query = {};
                var values = void 0, displayValues = void 0;
                values = filterRes[key] && key === 'tags' ? filterRes[key].map(function (v) { return (v.name); }).join(',') : filterRes[key];
                displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
                if (typeof (values) === 'boolean') {
                    if (key === 'incomplete' && values) {
                        displayValues = values.toString();
                        displayValues = displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
                        values = !values;
                        var filterValue = values.toString();
                        filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                        query[_this.appliedFilterQueryKeys[key]] = filterValue;
                    }
                    else if (key === 'retake' && values) {
                        values = 'requested';
                        query[_this.appliedFilterQueryKeys[key]] = values;
                    }
                    else if (key === 'unvoid' && values) {
                        query[_this.appliedFilterQueryKeys[key]] = 'False';
                    }
                    else if (values) {
                        values = values.toString();
                        values = values.charAt(0).toUpperCase() + values.slice(1);
                        query[_this.appliedFilterQueryKeys[key]] = values;
                    }
                }
                else if (values) {
                    query[_this.appliedFilterQueryKeys[key]] = filterRes[key] && (filterRes[key].id || filterRes[key]);
                }
                var filterName = key === 'unvoid' ? 'Not voided' : key.charAt(0).toUpperCase() + key.slice(1);
                var filter = new filter_option_1.FilterOption({
                    filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                    key: key,
                    title: filterName,
                    displayValues: displayValues || null,
                    values: values,
                    query: query
                });
                if (key !== 'incomplete' && (filter.values === 'False' || !filter.values)) {
                    falseyFilters.push(filter);
                }
                var existingFilterIndex = lodash_1.findIndex(_this.appliedFilters, { key: key });
                if (existingFilterIndex !== -1) {
                    _this.appliedFilters[existingFilterIndex] = filter;
                }
                else {
                    _this.appliedFilters.push(filter);
                }
            }
            else {
                var existingFilter = lodash_1.find(_this.appliedFilters, { key: key });
                if (existingFilter) {
                    lodash_1.remove(_this.appliedFilters, existingFilter);
                }
            }
        });
        this.appliedFilters = lodash_1.uniqBy(lodash_1.difference(this.appliedFilters, falseyFilters), 'key');
    };
    CondensedTripsComponent.prototype.void = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Void Trip?'),
            body: this.translationService.instant('This trip will be marked as \'Void\' and will not be visible for the Job.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Void')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: true }).subscribe(function (result) {
                    trip.void = true;
                    trip.updateStatus();
                    trip.loading = false;
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    CondensedTripsComponent.prototype.unvoid = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Unvoid Trip?'),
            body: this.translationService.instant('This trip will be unmarked as \'Void\' and will be visible for the Job.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Unvoid')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: false }).subscribe(function (result) {
                    trip.void = false;
                    trip.updateStatus();
                    trip.loading = false;
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    CondensedTripsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    CondensedTripsComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
    };
    CondensedTripsComponent.prototype.updateMarkets = function () {
        this.changeSearch();
    };
    CondensedTripsComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId,
        });
    };
    return CondensedTripsComponent;
}());
exports.CondensedTripsComponent = CondensedTripsComponent;
