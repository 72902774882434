"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{height:100%;width:100%}.trip-history-widget[_ngcontent-%COMP%]{width:100%;height:100%;background-color:#fff;border:1px solid #e6e9ec;border-radius:4px}.trip-history-widget__header[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-content:space-between;justify-content:space-between;width:100%;height:50px;border-bottom:1px solid #e6e9ec}.trip-history-widget__header__title[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{height:18px;font-size:15px;font-weight:500;color:#3f536e;margin-left:25px}.trip-history-widget__header__actions[_ngcontent-%COMP%]{margin-right:25px;color:#a3acaf;height:15px;font-family:Roboto;font-size:13px;margin-top:2px}.trip-history-widget__content[_ngcontent-%COMP%]{width:100%;height:100%;padding:25px}.trip-history-widget__content.fullBleed[_ngcontent-%COMP%]{padding:0}"];
exports.styles = styles;
