<div [hidden]="!isDelayedRunning" class="loader" [ngClass]="{'mat-table-loader': matTableLoader, 'intro': kind === 'full-size', 'small-loader': small}">
  <div *ngIf="kind !== 'full-size'" class="loader-container">
    <div class="dot dot1"></div>
    <div class="dot dot2"></div>
    <div class="dot dot3"></div>
    <div class="dot dot4"></div>
  </div>
  <div *ngIf="kind === 'full-size'" class="center">
    <h1>Hold tight while we set up your Ruckit experience.</h1>
    <div class="dots">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <img src="/assets/img/truck-with-load.svg">
  </div>
  <div *ngIf="kind === 'full-size'" class="tooltip">
    <p><b>Did you know?</b><br/>Ruckit can help you grow your business with powerful collaboration tools. Check out our <b>Help Center</b> to learn more.</p>
  </div>
</div>
