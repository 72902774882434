"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
// libraries
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
var moment = require("moment");
var job_event_1 = require("../job-events/job-event");
var TripAssignmentsComponent = /** @class */ (function () {
    function TripAssignmentsComponent(deviceDetectorService) {
        this.deviceDetectorService = deviceDetectorService;
        this.assignments = [];
        this.loading = false;
        this.activeAssignment = new core_1.EventEmitter();
        this.timelineData = [];
        this.timelineConfig = {
            visibleBars: ['punchcards', 'predictedTrips', 'trips'],
            headerHeight: 52,
            rowHeight: 100,
            scroll: true,
            currentTime: true,
            selectedTime: false
        };
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.expandedDrivers = [];
        this.activeStatuses = [{
                name: 'Waiting',
                active: false
            }, {
                name: 'Unloading',
                active: false
            }, {
                name: 'Enroute to Unloading',
                active: false
            }, {
                name: 'Loading',
                active: false
            }, {
                name: 'Enroute to Loading',
                active: false
            }];
        this.contextMenuEventSubject = new rxjs_1.Subject();
    }
    TripAssignmentsComponent.prototype.ngOnChanges = function (changes) {
        if (this.jobEvent && changes.assignments) {
            this.setupTimelineData(this.jobEvent, changes.assignments.currentValue);
        }
    };
    TripAssignmentsComponent.prototype.ngOnInit = function () {
        this.getAvailableStatuses(this.assignments);
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    };
    TripAssignmentsComponent.prototype.getAvailableStatuses = function (assignments) {
        var _this = this;
        if (assignments) {
            assignments.forEach(function (assignment) {
                if (assignment.waiting) {
                    _this.activeStatuses[0].active = true;
                }
                else if (assignment.driver.isActive(assignment.driver.trip, 'en-route-one')) {
                    _this.activeStatuses[4].active = true;
                }
                else if (assignment.driver.isActive(assignment.driver.trip, 'loading')) {
                    _this.activeStatuses[3].active = true;
                }
                else if (assignment.driver.isActive(assignment.driver.trip, 'en-route-two')) {
                    _this.activeStatuses[2].active = true;
                }
                else if (assignment.driver.isActive(assignment.driver.trip, 'unloading')) {
                    _this.activeStatuses[1].active = true;
                }
            });
        }
    };
    TripAssignmentsComponent.prototype.assignmentsOfStatus = function (status) {
        var assignments = [];
        switch (status.name) {
            case 'Waiting': {
                assignments = this.assignments.map(function (assignment) {
                    return assignment.waiting ? assignment : null;
                });
                break;
            }
            case 'Enroute to Loading': {
                assignments = this.assignments.map(function (assignment) {
                    return assignment.driver.isActive(assignment.driver.trip, 'en-route-one') ? assignment : null;
                });
                break;
            }
            case 'Loading': {
                assignments = this.assignments.map(function (assignment) {
                    return assignment.driver.isActive(assignment.driver.trip, 'loading') ? assignment : null;
                });
                break;
            }
            case 'Enroute to Unloading': {
                assignments = this.assignments.map(function (assignment) {
                    return assignment.driver.isActive(assignment.driver.trip, 'en-route-two') ? assignment : null;
                });
                break;
            }
            case 'Unloading': {
                assignments = this.assignments.map(function (assignment) {
                    return assignment.driver.isActive(assignment.driver.trip, 'unloading') ? assignment : null;
                });
                break;
            }
        }
        return assignments.filter(Boolean);
    };
    TripAssignmentsComponent.prototype.expandDriver = function (driver) {
        if (lodash_1.find(this.expandedDrivers, { id: driver.id })) {
            lodash_1.remove(this.expandedDrivers, { id: driver.id });
        }
        else {
            this.expandedDrivers.push(driver);
        }
    };
    TripAssignmentsComponent.prototype.expandedDriver = function (driver) {
        if (lodash_1.find(this.expandedDrivers, { id: driver.id })) {
            return true;
        }
        return false;
    };
    TripAssignmentsComponent.prototype.selectActiveAssignment = function (assignment) {
        this.activeAssignment.emit(assignment);
    };
    TripAssignmentsComponent.prototype.setupTimelineData = function (jobEvent, assignments) {
        var startTimes = [], endTimes = [];
        if (jobEvent) {
            startTimes.push(jobEvent.shift1StartTimestamp);
            jobEvent.shift2EndTimestamp ? endTimes.push(jobEvent.shift2EndTimestamp) : endTimes.push(jobEvent.shift1EndTimestamp);
            this.timelineData = assignments.map(function (assignment) {
                assignment.trips.forEach(function (trip) {
                    if (trip.startTimeTimestamp) {
                        startTimes.push(trip.startTimeTimestamp);
                    }
                    if (trip.endTimeTimestamp) {
                        endTimes.push(trip.endTimeTimestamp);
                    }
                    if (trip.connexPaverStartUnloading) {
                        assignment.pavers.push({
                            startDatetime: trip.connexPaverStartUnloading,
                            endDatetime: trip.connexPaverEndUnloading
                        });
                    }
                });
                assignment.predictedTrips.forEach(function (predictedTrip) {
                    if (predictedTrip.startTimeTimestamp) {
                        startTimes.push(predictedTrip.startTimeTimestamp);
                    }
                    if (predictedTrip.endTimeTimestamp) {
                        endTimes.push(predictedTrip.endTimeTimestamp);
                    }
                });
                assignment.punchCards.forEach(function (punchCard) {
                    if (punchCard.startTimeTimestamp) {
                        startTimes.push(punchCard.startTimeTimestamp);
                    }
                    if (punchCard.endTimeTimestamp) {
                        endTimes.push(punchCard.endTimeTimestamp);
                    }
                });
                return {
                    referenceId: assignment.id,
                    trips: assignment.trips,
                    predictedTrips: assignment.predictedTrips,
                    punchcards: assignment.punchCards,
                    pavers: assignment.pavers
                };
            });
        }
        this.timelineRange = this.setTimelineRange(startTimes, endTimes);
    };
    TripAssignmentsComponent.prototype.setTimelineRange = function (startTimes, endTimes) {
        var startMoments = startTimes.map(function (time) { return (moment(time)); });
        var endMoments = endTimes.map(function (time) { return (moment(time)); });
        return {
            startDatetime: moment.min(startMoments).startOf('hour').toISOString(),
            endDatetime: moment.max(endMoments).endOf('hour').toISOString()
        };
    };
    TripAssignmentsComponent.prototype.openContextMenu = function (event, driver) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driver.id,
        });
    };
    return TripAssignmentsComponent;
}());
exports.TripAssignmentsComponent = TripAssignmentsComponent;
