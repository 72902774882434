"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-dialog-content[_ngcontent-%COMP%]{width:1100px;height:705px;overflow:auto;border-radius:2px;background-color:#fff;margin:10px}.location-details[_ngcontent-%COMP%], .rate-fields[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap;justify-content:space-between}.rate-fields[_ngcontent-%COMP%]{align-items:baseline}.rate-field-row[_ngcontent-%COMP%]{display:flex;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;width:100%;justify-content:space-between}.field-group[_ngcontent-%COMP%]{height:100px;width:250px}.field-group[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{margin-bottom:5px}.field-group[_ngcontent-%COMP%]   .radio-buttons[_ngcontent-%COMP%]{margin-top:10px}.field-group[_ngcontent-%COMP%]   .radio-buttons[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{margin-right:20px}.field-group[_ngcontent-%COMP%]   .checkbox-field[_ngcontent-%COMP%]{margin-top:10px}.archive-job[_ngcontent-%COMP%]{display:flex;align-items:center}.config-option[_ngcontent-%COMP%]{display:flex;justify-content:space-between;align-items:center;margin-top:15px}.config-option[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{margin-top:0;margin-bottom:0}.config-option[_ngcontent-%COMP%]   mat-select[_ngcontent-%COMP%]{width:unset}.map[_ngcontent-%COMP%]{border-radius:4px;width:220px}.disabled[_ngcontent-%COMP%]{background:#e0e0e0;opacity:.4;cursor:default}.location[_ngcontent-%COMP%]{width:250px;height:300px}.modal-footer[_ngcontent-%COMP%]{justify-content:flex-end}.jobs-count[_ngcontent-%COMP%]{margin-right:20px;display:flex;align-self:center}.location-details[_ngcontent-%COMP%]   .address[_ngcontent-%COMP%]     .dropdown-options .scrollable{overflow-y:hidden}.location-details[_ngcontent-%COMP%]   .address[_ngcontent-%COMP%]     .dropdown.open .dropdown-menu .dropdown-options .scrollable{overflow-y:auto}"];
exports.styles = styles;
