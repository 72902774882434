"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var PaymentType = /** @class */ (function () {
    function PaymentType(paymentTypeInfo) {
        paymentTypeInfo = camelcaseKeysDeep(paymentTypeInfo);
        this.id = paymentTypeInfo.id;
        if (paymentTypeInfo.name && paymentTypeInfo.name.length) {
            this.name = paymentTypeInfo.name.charAt(0).toUpperCase() + paymentTypeInfo.name.substr(1);
        }
        else {
            this.name = this.id;
        }
    }
    return PaymentType;
}());
exports.PaymentType = PaymentType;
