"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var resource_service_1 = require("./../shared/resource.service");
var job_event_stat_serializer_1 = require("./job-event-stat.serializer");
var index_1 = require("../shared/index");
var app_utilities_1 = require("../shared/app-utilities");
var JobEventStatService = /** @class */ (function (_super) {
    __extends(JobEventStatService, _super);
    function JobEventStatService(http) {
        var _this = _super.call(this, http, 'jobevents/stats/', new job_event_stat_serializer_1.JobEventStatSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        return _this;
    }
    JobEventStatService.prototype.tableList = function (query) {
        var params = app_utilities_1.AppUtilities.getParamsFromQuery(query);
        var statsUrl = this.baseUrl + "jobevents/stats-summary/";
        return this.http.get(statsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (json) { return (new job_event_stat_serializer_1.JobEventStatsSummarySerializer().fromJson(json)); }), operators_1.map(function (data) { return ([data]); }), operators_1.catchError(index_1.handleError));
    };
    JobEventStatService.prototype.getStats = function (jobEventId, query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key] && query[key].toString());
                }
            });
        }
        var statsUrl = this.baseUrl + 'jobevents/' + jobEventId + '/stats/';
        return this.http.get(statsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
    };
    JobEventStatService.prototype.getJobStats = function (jobId, query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key] && query[key].toString());
                }
            });
        }
        var statsUrl = this.baseUrl + 'jobs/' + jobId + '/stats/';
        return this.http.get(statsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
    };
    JobEventStatService.prototype.getAllStats = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key] && query[key].toString());
                }
            });
        }
        var jobEventsUrl = this.baseUrl + 'jobevents/stats/';
        return this.http.get(jobEventsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
    };
    JobEventStatService.prototype.extractData = function (res) {
        var resObj = res;
        var body = resObj['results'];
        if (body) {
            return body.map(function (stat) {
                return new job_event_stat_serializer_1.JobEventStatSerializer().fromJson(stat);
            });
        }
        else if (resObj) {
            return new job_event_stat_serializer_1.JobEventStatSerializer().fromJson(resObj);
        }
    };
    return JobEventStatService;
}(resource_service_1.ResourceService));
exports.JobEventStatService = JobEventStatService;
