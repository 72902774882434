"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var customers_component_1 = require("./customers.component");
var routes = [
    { path: '', component: customers_component_1.CustomersComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var CustomersRoutingModule = /** @class */ (function () {
    function CustomersRoutingModule() {
    }
    return CustomersRoutingModule;
}());
exports.CustomersRoutingModule = CustomersRoutingModule;
