"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
// libraries
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
// angular material
var material_1 = require("@angular/material");
// services
var trackable_service_1 = require("./trackable.service");
var api_service_1 = require("../shared/api.service");
var truck_service_1 = require("../trucks/truck.service");
var trackable_type_service_1 = require("../trackable-types/trackable-type.service");
// components
var index_1 = require("../shared/dialogs/index");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
// types
var trackable_1 = require("./trackable");
var EditTrackableComponent = /** @class */ (function () {
    function EditTrackableComponent(_eref, dialog, truckService, trackableTypeService, trackableService, deviceDetectorService, translateService) {
        this._eref = _eref;
        this.dialog = dialog;
        this.truckService = truckService;
        this.trackableTypeService = trackableTypeService;
        this.trackableService = trackableService;
        this.deviceDetectorService = deviceDetectorService;
        this.translateService = translateService;
        this.completed = new core_1.EventEmitter();
        this.closed = new core_1.EventEmitter();
        this.open = false;
        this.loading = false;
        this.errors = [];
        this.tag = [];
        this.trucksConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'id',
            selectText: this.translateService.instant('Select Truck'),
            loadingText: this.translateService.instant('Loading Trucks...'),
            noResultsText: this.translateService.instant('No Trucks'),
            service: truck_service_1.TruckService,
            serviceFunction: 'list',
            query: {
                ordering: 'name'
            },
            prefilledOptions: [],
        };
        this.trackableTypesConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'id',
            selectText: this.translateService.instant('Select Trackable Type'),
            loadingText: this.translateService.instant('Loading Trackable Types...'),
            noResultsText: this.translateService.instant('No Trackable Types'),
            service: trackable_type_service_1.TrackableTypeService,
            serviceFunction: 'list',
            query: {
                ordering: 'name'
            },
            prefilledOptions: [],
        };
    }
    EditTrackableComponent.prototype.documentClick = function (event) {
        var _this = this;
        var row = event && event.target && event.target.closest('.primary-row');
        if (!lodash_1.includes(event.target.classList, 'row') && !(row && row.contains(event.target)) && this.open) {
            var confirmDialog = document.querySelector('ruckit-confirm-dialog');
            if (!lodash_1.includes(event.target.classList, 'icon-more') &&
                !lodash_1.includes(event.target.classList, 'mat-chip-remove') &&
                !lodash_1.includes(event.target.classList, 'mat-option-text') &&
                !this._eref.nativeElement.contains(event.target) &&
                !(confirmDialog && confirmDialog.contains(event.target))) {
                if (this.trackableForm && !this.trackableForm.form.pristine) {
                    this.confirmBeforeClosing().subscribe(function (dialogResult) {
                        if (dialogResult) {
                            _this.submit();
                        }
                        else {
                            _this.close();
                        }
                    });
                }
                else {
                    this.close();
                }
            }
        }
    };
    EditTrackableComponent.prototype.ngOnInit = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    };
    EditTrackableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.trackable && changes.trackable.currentValue && changes.trackable.currentValue.id) {
            this.modifiedTrackable = lodash_1.clone(this.trackable);
        }
    };
    EditTrackableComponent.prototype.ngOnDestroy = function () { };
    EditTrackableComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.trackableService.save(this.modifiedTrackable).subscribe(function (trackable) {
            _this.open = false;
            _this.trackable.selected = false;
            _this.completed.emit(_this.modifiedTrackable);
            if (_this.trackableForm) {
                _this.trackableForm.form.markAsPristine();
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditTrackableComponent.prototype.remove = function () {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        if (this.confirmDialog && this.confirmDialog.componentInstance) {
            this.confirmDialog.componentInstance.attributes = {
                title: this.translateService.instant('Delete Trackable?'),
                body: this.translateService.instant('You will no longer be able to recieve GPS information from this trackable. Deleting a trackable cannot be undone.'),
                close: this.translateService.instant('Cancel'),
                accept: this.translateService.instant('Delete')
            };
            this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    _this.trackableService.remove(_this.modifiedTrackable).subscribe(function () {
                        _this.modifiedTrackable.removed = true;
                        _this.open = false;
                        _this.trackable.selected = false;
                        if (_this.trackableForm) {
                            _this.trackableForm.form.markAsPristine();
                        }
                        _this.loading = false;
                        _this.completed.emit(_this.modifiedTrackable);
                    }, function (err) {
                        _this.errors = api_service_1.parseErrors(err);
                        _this.loading = false;
                    });
                }
                _this.confirmDialog = null;
            });
        }
    };
    /**
     * Updates a specified field on the trackable object, with specified functionality for particular fields
     *
     * @param {any} value The updated value to be applied to the trackable object
     * @param {string} field The field name which the value will be set on
     */
    EditTrackableComponent.prototype.optionSelected = function (e, field) {
        var editsMade = false;
        switch (field) {
            case 'truck':
                if (!this.modifiedTrackable.truck ||
                    (this.modifiedTrackable.truck && this.modifiedTrackable.truck.id !== e.id)) {
                    this.modifiedTrackable.truck = e;
                    editsMade = true;
                }
                break;
            case 'trackableType':
                if (!this.modifiedTrackable.trackableType ||
                    (this.modifiedTrackable.trackableType && this.modifiedTrackable.trackableType.id !== e.id)) {
                    this.modifiedTrackable.trackableType = e;
                    editsMade = true;
                }
                break;
            default:
                this.modifiedTrackable[field] = e;
                editsMade = true;
                break;
        }
        if (this.trackableForm && this.trackableForm.form && editsMade) {
            this.trackableForm.form.markAsDirty();
        }
    };
    EditTrackableComponent.prototype.close = function () {
        if (this.trackableForm && this.trackableForm.form) {
            this.trackableForm.form.markAsPristine();
            this.open = false;
            this.trackable.selected = false;
            if (this.trackable && this.modifiedTrackable && (this.trackable.id === this.modifiedTrackable.id)) {
                this.modifiedTrackable = undefined;
            }
            this.closed.emit();
        }
    };
    EditTrackableComponent.prototype.markAsPristine = function () {
        if (this.trackableForm && this.trackableForm.form) {
            this.trackableForm.form.markAsPristine();
        }
    };
    EditTrackableComponent.prototype.setOpen = function () {
        var _this = this;
        this.open = true;
        setTimeout(function () {
            if (_this.trucksDropdown) {
                _this.trucksDropdown.deselectOptions();
                _this.trucksConfig = __assign({}, _this.trucksConfig, { selectText: _this.trackable && _this.trackable.truck && _this.trackable.truck.name
                        ? _this.trackable.truck.name
                        : _this.translateService.instant('Select Truck') });
            }
            if (_this.trackableTypesDropdown) {
                _this.trackableTypesDropdown.deselectOptions();
                _this.trackableTypesConfig = __assign({}, _this.trackableTypesConfig, { selectText: _this.trackable && _this.trackable.trackableType && _this.trackable.trackableType.name
                        ? _this.trackable.trackableType.name
                        : _this.translateService.instant('Select Trackable Type') });
            }
        }, 500);
    };
    /**
     * To display confirm dialog
     * if user closes edit panel without saving data
     */
    EditTrackableComponent.prototype.confirmBeforeClosing = function () {
        var observable;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        if (this.confirmDialog && this.confirmDialog.componentInstance) {
            this.confirmDialog.componentInstance.attributes = {
                title: this.translateService.instant('You have unsaved Changes'),
                body: this.translateService.instant('Are you sure you want to leave? Your changes will not be saved.'),
                close: this.translateService.instant('Discard'),
                accept: this.translateService.instant('Save')
            };
            observable = this.confirmDialog.afterClosed();
            this.confirmDialog = null;
        }
        return observable;
    };
    return EditTrackableComponent;
}());
exports.EditTrackableComponent = EditTrackableComponent;
