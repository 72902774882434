<form #filterPaystubs="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 i18n='Filter paystubs header'>Filter Paystubs</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group trip-state" fxFlex="100">
          <label attr.data-label="{{ 'Paid' | translate }}" class="toggle-label">
            <input type="checkbox" name="paid" checked="{model.sent}" [(ngModel)]="model.sent" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group job-name" fxFlex="100">
          <label>Job</label>
          <dropdown #jobsDropdown [options]="jobs" (onSelect)="onSelect('job', $event)" [config]="jobsConfig" title="&ndash;"
            (onSearch)="dropdownSearch($event, 'job')" (nextPage)="dropdownNextPage($event, 'job')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group customer-name" fxFlex="100">
          <label>Payee</label>
          <dropdown #customersDropdown [options]="customers" (onSelect)="onSelect('customer', $event)" [config]="customersConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'customer')" (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false">
      Filter Results
    </button>
  </div>
</form>
