<div class="organization-container">
  <div class="organization-data">
    <h2 translate>Driver Settings</h2>
    <form #editPermissions="ngForm" class="permission-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="driver-rates">
              <label translate>Show Rates to Drivers</label>
              <div class="field-group driver-rates">
                <div class="toggle-note" translate>
                  Hiding rates from drivers will block the driver's ability to
                  see rates in the driver app. They will still see the total
                  number of hours, loads, or weight, but will not see monetary
                  totals. When this permission is enabled, the drivers will be
                  able to see the rates and monetary totals.
                </div>
                <label class="toggle-label">
                  <input type="checkbox" name="driver_rates" [checked]="model.driverFleetCanViewRates" [(ngModel)]="model.driverFleetCanViewRates"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="organization.carrier">
            <div class="quick-tickets">
              <label translate>Allow Drivers to make Quick Tickets</label>
              <div class="field-group quick-tickets">
                <div class="toggle-note" translate>
                  Turning on Quick Tickets will allow drivers to add a trip or
                  punch card manually without going through the trip flow or
                  clocking in/out in the driver app.
                </div>
                <label class="toggle-label">
                  <input type="checkbox" name="quick_tickets" checked="{model.driverFleetQuickTicket}" [(ngModel)]="model.driverFleetQuickTicket"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="organization.carrier">
            <div class="dvir-required">
              <label translate>Require DVIR for Driver Shifts</label>
              <div class="field-group dvir-required">
                <div class="toggle-note" translate>
                  Turning on DVIR will remind your drivers to finish their DVIR prior to starting their shift. This
                  alert will be presented when a new shift is started and ended.
                </div>
                <label class="toggle-label">
                  <input type="checkbox" name="dvir_required" [checked]="model.dvirRequired" [(ngModel)]="model.dvirRequired"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="organization.carrier">
            <div class="require-driver-confirmation">
              <label translate>Require Driver Confirmation for Jobs</label>
              <div class="field-group confirmation-required">
                <div class="toggle-note" translate>
                  Requiring confirmation will force the drivers to accept or
                  reject an assignment when dispatched. This allows the driver
                  to let you know if they plan to work that assignment.
                </div>
                <label class="toggle-label">
                  <input type="checkbox" name="driver_confirmation_required" [checked]="model.driverFleetRequireAssignmentConfirmation"
                    [(ngModel)]="model.driverFleetRequireAssignmentConfirmation" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="organization.carrier">
            <div class="avoid-tolls">
              <label translate>Avoid Tolls</label>
              <div class="field-group avoid-tolls">
                <div class="toggle-note" translate>
                  Avoid tolls when creating directions from loading to unloading.
                </div>
                <label class="toggle-label">
                  <input type="checkbox" name="avoid_tolls" [checked]="model.avoidTolls" [(ngModel)]="model.avoidTolls"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="organization.carrier">
            <div class="qr-enabled">
              <label translate>QR Enabled</label>
              <div class="field-group ptp-qr-enabled">
                <div class="toggle-note" translate>
                  Turning on Pit Ticket Pro QR support allows and enables QR Code capture on Jobs.
                </div>
                <label class="toggle-label">
                  <input type="checkbox" name="qr_enabled" [checked]="model.qrEnabled" [(ngModel)]="model.qrEnabled"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <button class="btn btn-primary" (click)="savePermissions(); false" [disabled]="loading || !editPermissions.form.valid || editPermissions.pristine"
              [ngClass]="{loading:loading}" translate>
              Save Preferences
            </button>
          </div>
        </div>
        <div class="right-side"></div>
      </div>
    </form>
    <h2 translate>Truck Settings</h2>
    <form #editTruckSettings="ngForm" class="permission-form" *ngIf="organization">
      <div class="form-block column">
        <div class="row">
          <div class="truck-toggles">
            <label translate>Default Truck Types</label>
            <div class="field-group column">
              <div class="text-block" translate>
                If you’d like to hide unneeded truck types, then check only the trucks that are important and used by
                your organization. Set the average tonnage to help with load estimates while dispatching.
              </div>
              <div class="select-buttons">
                <button translate class="btn btn-default" (click)="selectAll(editTruckSettings, true)">Select All
                  Trucks</button>
                <button translate class="btn btn-default" (click)="selectAll(editTruckSettings, false)">Deselect All
                  Trucks</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="truck-settings">
            <div class="settings-column">
              <div class="column-header">
                <p translate class="additional">Truck Type</p>
                <p translate class="additional">Average Tonnage</p>
              </div>
              <ng-container *ngFor="let truckSetting of truckSettings; let i = index">
                <div *ngIf="i < columnLength" class="setting-group" [ngClass]="{ 'selected': truckSetting.isFavorite }">
                  <input name="is-favorite-{{truckSetting.id}}" type="checkbox" [checked]="truckSetting.isFavorite"
                    [(ngModel)]="truckSetting.isFavorite" />
                  <label>{{ truckSetting.name }}</label>
                  <input name="avg-tonnage-{{truckSetting.id}}" type="text" placeholder="{{ truckSetting.averageTonnage }}"
                    [(ngModel)]="truckSetting.averageTonnage">
                </div>
              </ng-container>
            </div>
            <div class="settings-column">
              <div class="column-header">
                <p translate class="additional">Truck Type</p>
                <p translate class="additional">Average Tonnage</p>
              </div>
              <ng-container *ngFor="let truckSetting of truckSettings; let i = index">
                <div *ngIf="i >= columnLength" class="setting-group" [ngClass]="{ 'selected': truckSetting.isFavorite }">
                  <input name="is-favorite-{{truckSetting.id}}" type="checkbox" [checked]="truckSetting.isFavorite"
                    [(ngModel)]="truckSetting.isFavorite" />
                  <label>{{ truckSetting.name }}</label>
                  <input name="avg-tonnage-{{truckSetting.id}}" type="text" placeholder="{{ truckSetting.averageTonnage }}"
                    [(ngModel)]="truckSetting.averageTonnage">
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <button class="btn btn-primary" (click)="saveTruckTypePreferences(); false" [disabled]="loading || !editTruckSettings.form.valid || editTruckSettings.pristine"
            [ngClass]="{loading:loading}" translate>
            Save Preferences
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
