"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var price_1 = require("./price");
var organization_1 = require("../organizations/organization");
var price_list_serializer_1 = require("../price-lists/price-list.serializer");
var product_serializer_1 = require("../products/product.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var lodash_1 = require("lodash");
var PriceSerializer = /** @class */ (function () {
    function PriceSerializer() {
    }
    /**
    * @param {any} json
    * @returns Price
    */
    PriceSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var price = new price_1.Price();
        if (!json) {
            return price;
        }
        price.id = json.id;
        if (json.product && typeof (json.product) === 'object') {
            price.product = (new product_serializer_1.ProductSerializer().fromJson(json.product));
        }
        else if (json.product) {
            price.product = (new product_serializer_1.ProductSerializer().fromJson({ id: json.product }));
        }
        price.createdAt = json.createdAt;
        price.lastModified = json.lastModified;
        price.notes = json.notes;
        if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
            price.ownerOrganization = new organization_1.Organization(json.ownerOrganization);
        }
        else {
            price.ownerOrganization = new organization_1.Organization({ id: json.ownerOrganization });
        }
        price.priceChangeAmount = json.priceChangeAmount;
        if (json.priceList && typeof (json.priceList) === 'object') {
            price.priceList = (new price_list_serializer_1.PriceListSerializer()).fromJson(json.priceList);
        }
        else {
            price.priceList = (new price_list_serializer_1.PriceListSerializer()).fromJson({ id: json.priceList });
        }
        price.priceChangeTypeOptions = [
            { id: 'percent', name: 'Percent' },
            { id: 'new_price', name: 'New Price' },
            { id: 'value', name: 'Value' }
        ];
        if (json.priceChangeType) {
            price.priceChangeType = (lodash_1.find(price.priceChangeTypeOptions, { id: json.priceChangeType }));
        }
        return price;
    };
    /**
     * @param {Price} price
     * @returns any
     */
    PriceSerializer.prototype.toJson = function (price) {
        var json = {
            id: price.id,
            product: price.product && price.product.id,
            createdAt: price.createdAt,
            lastModified: price.lastModified,
            notes: price.notes,
            priceChangeAmount: price.priceChangeAmount,
            priceChangeType: price.priceChangeType && price.priceChangeType.id,
            priceList: price.priceList && price.priceList.id,
            ownerOrganization: price.ownerOrganization && price.ownerOrganization.id,
        };
        return decamelizeKeysDeep(json);
    };
    return PriceSerializer;
}());
exports.PriceSerializer = PriceSerializer;
