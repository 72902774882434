<my-toolbar logo=true title="{{ 'Create your account' | translate }}" color="ruckit-dark-blue"></my-toolbar>

<action-bar *ngIf="steps && steps.length > 1">
  <ul id="steps" fxLayout="row" fxFill fxLayoutAlign="center center" *ngIf="!loading">
    <li *ngFor="let stepOption of steps; let i = index" [ngClass]="{'active' : step === (i + 1), 'complete' : step > (i + 1)}">
      <div class="step-number-container" [ngClass]="{'active' : step === (i + 1), 'complete icomoon icon-success' : step > (i + 1)}">
        <span class="step-number">{{ i + 1 }}</span>
      </div>
      {{stepOption}}
    </li>
  </ul>
</action-bar>

<div fxLayout="column" fxLayoutAlign="start center" class="form-scroll">
  <div class="invitation-form" *ngIf="!loading">
    <div id="step-1" *ngIf="step === 1">
      <h2 translate>Welcome to Ruckit</h2>
      <p>
        {{this.invitation.recipientOrganization && this.invitation.recipientOrganization.name}} <ng-container translate>has invited you to join their team on Ruckit. </ng-container>
        <ng-container translate *ngIf="this.invitation.recipientOrganization && this.invitation.recipientOrganization.primaryContact === null">You are the primary contact and admin.</ng-container>
      </p>
      <notification *ngFor="let error of errors">{{error}}</notification>

      <form #invitationForm="ngForm">
        <div class="field-group full-width first-name">
          <label class="required" translate>First Name</label>
          <input type="text" name="firstName" placeholder="{{ 'First Name' | translate }}" required [(ngModel)]="data.profile.firstName" #firstName="ngModel"/>
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': firstName.valid, 'ng-invalid': firstName.invalid, 'ng-pristine': firstName.pristine}"></span>
        </div>
        <div class="field-group full-width last-name">
          <label class="required" translate>Last Name</label>
          <input type="text" name="lastName" placeholder="{{ 'Last Name' | translate }}" required [(ngModel)]="data.profile.lastName" #lastName="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': lastName.valid, 'ng-invalid': lastName.invalid, 'ng-pristine': lastName.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Email</label>
          <input type="email" name="email" placeholder="{{ 'Email Address' | translate}}" required [(ngModel)]="data.profile.email" #email="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': email.valid, 'ng-invalid': email.invalid, 'ng-pristine': email.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Phone Number</label>
          <input type="text" name="phoneNumber" placeholder="{{ 'Phone Number 1+' | translate }}" required
                 [(ngModel)]="data.profile.phoneNumber" #phoneNumber="ngModel"
                phoneInputMask
          />
          <!--(keydown)="phoneInputMask($event, data.profile.phoneNumber)"-->

          <span [ngClass]="{'input-icon fa': true, 'ng-valid': phoneNumber.valid, 'ng-invalid': phoneNumber.invalid, 'ng-pristine': phoneNumber.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Job Title</label>
          <input type="text" name="title" placeholder="{{ 'Job Title' | translate }}" required [(ngModel)]="data.profile.title" #title="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': title.valid, 'ng-invalid': title.invalid, 'ng-pristine': title.pristine}"></span>
        </div>

        <div class="field-group full-width">
          <hr class="divider" />
        </div>

        <div class="field-group full-width">
          <label class="required" translate>Password</label>
          <input type="password" name="password" placeholder="{{ 'Password' | translate }}" required pattern=".{8,}" title="{{ '8 characters minimum' | translate }}" [(ngModel)]="data.user.password" #password="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': password.valid, 'ng-invalid': password.invalid, 'ng-pristine': password.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Password Confirmation</label>
          <input type="password" name="passwordConfirmation" placeholder="{{ 'Re-enter Password' | translate }}" required pattern=".{8,}" title="{{ '8 characters minimum' | translate }}" validateEqual="password" [(ngModel)]="data.user.passwordConfirmation" #passwordConfirmation="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': passwordConfirmation.valid, 'ng-invalid': passwordConfirmation.invalid, 'ng-pristine': passwordConfirmation.pristine}"></span>
        </div>

        <div class="field-group submit-button">
          <ruckit-button title="{{ 'Next' | translate }}" (click)="invitationForm.form.valid && register(); false" [isDisabled]="!invitationForm.form.valid || loading"></ruckit-button>
        </div>
      </form>
    </div>

    <div id="step-2" *ngIf="step === 2">
      <h2 translate>Profile Photo</h2>
      <p translate>
        Upload a profile photo so other your coworkers can easily recognize you in Ruckit.
      </p>
      <notification *ngFor="let error of errors">{{error}}</notification>
      
      <form #profilePhotoForm="ngForm">
        <div class="field-group profile-image">
          <div class="upload-image-dropzone round" [ngClass]="{filled: profileImage.file}">
            <div class="img"><img *ngIf="profileImage.file" [src]="profileImage.dataUri" /></div>
            <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event, profilePhotoForm)" #fileInput/>
            <label class="upload-label" for="file">
              <i class="icon-upload"></i>
              <span *ngIf="!profileImage.file" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
              <span *ngIf="profileImage.file" translate>Upload a new photo</span>
            </label>
          </div>
        </div>
      
        <div class="field-group submit-button">
          <button class="btn btn-primary"
                  (click)="skipStep(); false"
                  *ngIf="!imageChanged"
                  [disabled]="loading"
                  [ngClass]="{loading: loading}"
                  translate>
            Skip
          </button>
          <button class="btn btn-default"
                  (click)="cancelImageChange(); false"
                  *ngIf="imageChanged"
                  [disabled]="loading"
                  [ngClass]="{loading: loading}"
                  translate>
            Cancel
          </button>
          <button class="btn btn-primary"
                  (click)="updatePhoto(); false"
                  *ngIf="imageChanged"
                  [disabled]="loading"
                  [ngClass]="{loading: loading}"
                  translate>
            Use
          </button>
        </div>
      </form>
    </div>

    <div id="step-3" *ngIf="step === 3">
      <h2 translate>Organization Details</h2>
      <p translate>Confirm that all of your organization's details are correct.</p>
      <notification *ngFor="let error of errors">{{error}}</notification>

      <form #organizationForm="ngForm" class="organization-form">
        <div class="field-group full-width">
          <label class="required" translate>Organization Name</label>
          <input type="text" name="organizationName" placeholder="{{ 'Organization Name' | translate }}" required [(ngModel)]="organization.name" #organizationName="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': organizationName.valid, 'ng-invalid': organizationName.invalid, 'ng-pristine': organizationName.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label class="required" translate>Address</label>
          <input type="text" name="street" placeholder="{{ 'Address' | translate }}" required [(ngModel)]="organization.street" #street="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': street.valid, 'ng-invalid': street.invalid, 'ng-pristine': street.pristine}"></span>
        </div>
        <div class="field-group full-width">
          <label translate>Address 2</label>
          <input type="text" name="street2" placeholder="{{ 'Address 2' | translate }}" [(ngModel)]="organization.street2" #street2="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': street2.valid, 'ng-invalid': street2.invalid, 'ng-pristine': street2.pristine}"></span>
        </div>
        <div class="field-group half-width city">
          <label class="required" translate>City</label>
          <input type="text" name="city" placeholder="{{ 'City' | translate }}" required [(ngModel)]="organization.city" #city="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': city.valid, 'ng-invalid': city.invalid, 'ng-pristine': city.pristine}"></span>
        </div>
        <div class="field-group half-width state">
          <label class="required" translate>State</label>
          <select name="state" required [(ngModel)]="organization.state" #state="ngModel">
            <option *ngFor="let state of states" [value]="state.abbreviation">{{state.abbreviation}}</option>
          </select>
        </div>
        <div class="field-group half-width zipcode">
          <label class="required" translate>Zip</label>
          <input type="text" name="zip" placeholder="{{ 'Zip' | translate }}" required [(ngModel)]="organization.zipcode" #zip="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': zip.valid, 'ng-invalid': zip.invalid, 'ng-pristine': zip.pristine}"></span>
        </div>
        <div class="field-group half-width phone-number">
          <label class="required" translate>Company Phone Number</label>
          <input type="text" name="phoneNumber" placeholder="{{ 'Company Phone +1' | translate }}" required [(ngModel)]="organization.phoneNumber" #phoneNumber="ngModel" />
          <span [ngClass]="{'input-icon fa': true, 'ng-valid': phoneNumber.valid, 'ng-invalid': phoneNumber.invalid, 'ng-pristine': phoneNumber.pristine}"></span>
        </div>

        <div class="field-group">
          <ruckit-button title="{{ 'Submit' | translate }}" (click)="organizationForm.form.valid && updateOrganization()" [isDisabled]="!organizationForm.form.valid || loading"></ruckit-button>
        </div>
      </form>
    </div>

    <div id="step-4" *ngIf="step === 4">
      <mat-card>
        <h2 translate>Submission Completed!</h2>
        <notification *ngFor="let error of errors">{{error}}</notification>
      </mat-card>
    </div>
    
    <div id="step-0" *ngIf="notFound">
      <h2 translate>Invitation Not Found</h2>
      <p translate>
        This invitation may have already been accepted.
      </p>
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>
</div>
