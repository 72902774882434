import { Profile } from './profile';

export let PROFILE: Profile = <Profile> {
  id: '00000000-0000-0000-0000-000000000001',
  firstName: 'Testy',
  lastName: 'Tester',
  name: 'Testy Tester',
  email: 'testy@tester.com',
  phoneNumber: '2255551234',
  title: 'Tester'
};
