"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var PhoneInputMaskDirective = /** @class */ (function () {
    function PhoneInputMaskDirective(el, renderer) {
        var _this = this;
        renderer.listen(el.nativeElement, 'keydown', function ($event) {
            _this.mask($event);
        });
    }
    PhoneInputMaskDirective.prototype.mask = function (e) {
        if (e.key && e.key.length !== 1) {
            return;
        }
        if (!e.key || e.key && !e.key.match(/\d/) && !e.metaKey) {
            e.preventDefault();
        }
        setTimeout(function () {
            if (e.target && e.target.value) {
                var value = e.target.value.match(/\d/g);
                if (value) {
                    if (value.length >= 3) {
                        value.splice(3, 0, '-');
                    }
                    if (value.length >= 7) {
                        value.splice(7, 0, '-');
                    }
                    value = value.slice(0, 12);
                    e.target.value = value.join('');
                }
                else {
                    e.target.value = '';
                }
            }
        }, 10);
    };
    PhoneInputMaskDirective.prototype.validate = function (c) {
        // self value
        var v = c.value;
        if (v && v.match) {
            var numbers = v.match(/\d/g);
            if (numbers && numbers.length >= 10) {
            }
            else {
                return { phone: false };
            }
        }
        return null;
    };
    return PhoneInputMaskDirective;
}());
exports.PhoneInputMaskDirective = PhoneInputMaskDirective;
