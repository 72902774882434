<div
  *ngIf="!collapsed"
  class="collapsible-section"
  [ngClass]="{
    'left-controls': controlPosition === 'left',
    'right-controls': controlPosition === 'right'
  }"
>
  <div class="collapse-control" (click)="collapsed = true">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <div class="section-content">
    <ng-content></ng-content>
  </div>
</div>

<div
  *ngIf="collapsed"
  (click)="collapsed = false"
  class="collapsible-section collapsed"
  [ngClass]="{
    'left-controls': controlPosition === 'left',
    'right-controls': controlPosition === 'right'
  }"
>
  <div class="title">
    <h3 translate>{{ title }}</h3>
  </div>
  <div class="expand-control" (click)="collapsed = false">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </div>
</div>
