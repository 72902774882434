<form [formGroup]="editCollaborators">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Collaborator</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="selected-shares">
        <!-- apply to all section -->
        <collaboration-template
          [loading]="loading"
          [hasLoadListsEnabled]="hasLoadListsEnabled"
          [rateTrackingOptions]="rateTrackingOptions"
          [unitsOfMeasure]="unitsOfMeasure"
          (onApplyToAll)="applyTemplate($event)"
        ></collaboration-template>
        <!-- end apply to all section -->

        <!-- start load list accordion -->
        <div class="loadlist-accordion" *ngIf="loadList.length">
          <div class="loadlist-accordion-header">
            <div translate>LOAD LIST</div>
            <div class="loadlist-accordion-arrow">
              <mat-icon (click)="loadListExpanded=true" *ngIf="!loadListExpanded">expand_more</mat-icon>
              <mat-icon (click)="loadListExpanded=false" *ngIf="loadListExpanded">expand_less</mat-icon>
            </div>
          </div>
          <div class="loadlist-accordion-body" [@expanded]="loadListExpanded ? 'expanded' : 'collapsed'">
            <table mat-table #loadListTable class="load-list-table" [dataSource]="loadList">
              <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef translate>LOAD</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.loadNumber || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="loadTime">
                <th mat-header-cell *matHeaderCellDef translate>LOAD TIME</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ticketEvents && row.ticketEvents.LOADING_STARTED | moment: 'h:mm a' || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef translate>TRUCK</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.vehicle && row.ruckit.vehicle.description ? '#' + row.ruckit.vehicle.description : '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef translate>DRIVER</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.driverName ? row.ruckit.driverName : '&mdash;' }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <!-- end load list accordion -->

        <!-- start shares -->
        <div class="option" *ngFor="let share of jobEventShares">
          <h3>{{ share.organization }}</h3>
          <div class="share" formGroupName="share_{{ share.id }}">
            <div class="row">
              <div class="field days">
                <div class="label required" translate>Days Allowed</div>
                <button class="calendar-button btn btn-default">
                  <div class="calendar-icon"></div> {{ (jobEvent.shift1StartTimestamp | date:'mediumDate') || '&nbsp;' }}
                </button>
              </div>

              <!-- if load list -->
              <div class="field loads" *ngIf="loadList.length > 0">
                <div class="label" translate>Loads</div>
                <div class="dropdown">
                  <div class="dropdown-button" [matMenuTriggerFor]="loads">
                    <ng-container *ngIf="share && share.loadSchedule && share.loadSchedule.length; else none_selected">
                      <ng-container *ngFor="let load of share.loadSchedule; let i = index">
                        {{ i === 0 ? load.loadNumber : ', ' + load.loadNumber }}
                      </ng-container>
                    </ng-container>
                    <ng-template #none_selected>
                      <span translate>None Selected</span>
                    </ng-template>
                  </div>
                  <mat-menu #loads="matMenu" class="loadlist-dropdown-menu" [overlapTrigger]="false">
                      <div class="loadlist-load-option" *ngFor="let load of loadList" (click)="$event.stopPropagation()">
                        <mat-checkbox
                          [checked]="share.loadSchedule | isInArray: load:'loadNumber'"
                          (change)="loadDropdownSelectionChanged($event, load, share)"
                          translate
                        >
                          {{ load.loadNumber }} &nbsp;&nbsp; Load Time - {{ load.ticketEvents.LOADING_STARTED | moment: 'h:mm a' }}
                        </mat-checkbox>
                      </div>
                  </mat-menu>
                </div>
              </div>

              <div class="field trucks" *ngIf="!loadList.length">
                <div class="label required" translate>Requested Amount</div>
                <div class="input-group">
                  <input
                    required
                    type="number"
                    formControlName="requestedAmount"
                    min="0">
                    <dropdown
                    [options]="unitsOfMeasure"
                    [selectedOption]="formControls['share_' + share.id].controls.requestedUnit.value"
                    (onSelect)="setFormControlValue(share, 'requestedUnit', $event)"></dropdown>
                </div>
              </div>

              <div class="field rate-tracking">
                <div class="label" translate>How is the rate tracked?</div>
                <dropdown [options]="rateTrackingOptions"
                          [config]="rateTrackingConfig"
                          [disabled]="share.readonly"
                          [selectedOption]="formControls['share_' + share.id].controls.invoiceType.value || haulTypeOption"
                          (onSelect)="setFormControlValue(share, 'invoiceType', $event)"
                          class="rate-tracking-dropdown"></dropdown>
              </div>
            </div>

            <div class="row">
              <div class="field rate">
                <div class="label" translate>What is the rate?</div>
                <div class="input-group" data-prefix="$">
                  <input type="number"
                         formControlName="invoiceRate"
                         min="0">
                  <dropdown [options]="weightOptions"
                            [selectedOption]="formControls['share_' + share.id].controls.invoiceWeightUnit.value"
                            [disabled]="share.readonly"
                            (onSelect)="setFormControlValue(share, 'invoiceWeightUnit', $event)"
                            title="-"
                            *ngIf="formControls['share_' + share.id].controls.invoiceType.value &&
                              formControls['share_' + share.id].controls.invoiceType.value.value === 'weight'; else addOn"></dropdown>
                  <ng-template #addOn>
                    <div class="add-on">
                      {{ formControls['share_' + share.id].controls.invoiceType.value &&
                        formControls['share_' + share.id].controls.invoiceType.value.value | titlecase }}
                    </div>
                  </ng-template>
                  
                </div>
              </div>

              <div class="field broker-rate-code">
                <div class="label" translate>What is the broker rate code?</div>
                <input formControlName="brokerRateCode">
              </div>

              <div class="field notes">
                <div class="label" translate>Notes</div>
                <div class="input-group">
                  <textarea formControlName="notes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end shares -->
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="dialogRef.close()"
      [ngClass]="{loading: loading}" translate>
      Cancel
    </button>
    <button class="btn btn-primary save-button"
      (click)="editCollaborators.valid && submit(); false"
      [disabled]="!editCollaborators.valid || editCollaborators.pristine || loading"
      [ngClass]="{loading: loading}"
      translate>
      Save
    </button>
  </div>
</form>
