import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  combineLatest as observableCombineLatest,
  Subject,
  Subscription,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { difference, get, find as _find, filter as _filter } from 'lodash';
import * as moment from 'moment';

// angular material
import { MatDialog } from '@angular/material';

// components
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import {
  ExportDialogComponent,
  ExportDialogData,
  FieldOption,
} from '../../shared/export-dialog/export-dialog.component';
import { FiltersDialogComponent } from '../../shared/filters-dialog/filters-dialog.component';
import { ColumnToggleComponent } from '../../shared/column-toggle/column-toggle.component';
import { EditShiftsDialogComponent } from '../edit-shifts/edit-shifts-dialog.component';
import { NewShiftDialogComponent } from '.././new-shift-dialog/new-shift-dialog.component';

// types
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { ShiftReport } from '../shift-report';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';

// services
import {
  DriverShiftService,
  ShiftReportService,
  ShiftService,
} from '../shift.service';
import { AuthenticationService } from '../../shared/authentication.service';

// constants
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS, MAPPEDCOLUMNS } from './columns';
import { FILTERS } from './filters';

// utils
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.scss'],
})
export class ShiftsComponent implements OnInit, OnDestroy {
  availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  displayedColumns = [...DISPLAYEDCOLUMNS];
  mappedColumns = [...MAPPEDCOLUMNS];
  appliedFilters: FilterOption[] = [];
  search = '';
  tableConfig = {
    hasHeader: true,
    pageSize: 25,
    service: ShiftReportService,
    filterQuery: false,
    preferenceKey: 'shifts',
    preferencesEnabled: true,
    query: {},
    collectionTitle: this.translationService.instant('Shifts'),
    noResultsText: this.translationService.instant('a shift'),
    sortBy: 'driver__profile__last_name,driver__profile__first_name',
    sortDirection: 'asc',
    automaticallyHidePagination: false,
    menuOptions: [
      {
        name: this.translationService.instant('Edit'),
        action: 'edit',
        link: true,
        external: false,
      },
    ],
    newRecordModal: () => this.openAddShift(),
  };
  multipleActionDropdownOptions = [
    {
      name: this.translationService.instant('Export'),
      action: 'export',
      link: false,
    },
    {
      name: this.translationService.instant('Edit Selected'),
      action: 'edit',
      link: false,
      disabled: true
    },
    {
      name: this.translationService.instant('Driver Shifts Export'),
      action: 'driver-shifts-export',
      link: false,
    },
  ];
  @ViewChild('columnToggle', { static: false })
  columnToggle: ColumnToggleComponent;
  @ViewChild('fancyTable', { static: false }) fancyTable: FancyTableComponent;

  datePipe = new DatePipe('en-US');
  loading = true;
  errors = [];
  allSelected = false;
  selectedShifts: ShiftReport[] = [];
  excludeShifts: ShiftReport[] = [];
  sortParameter: string;
  filtersDialog: FiltersDialogComponent;
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  exportCallback = () => {};
  editSelectedShiftsCallback = () => {
    this.fancyTable.getRecords();
  };

  driverShiftsExportEnabled = false;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private shiftService: ShiftService,
    private driverShiftService: DriverShiftService,
    private translationService: TranslateService,
    private authenticationService: AuthenticationService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  /**
   * Processes the current URL params and applies them as filters / queries to the table data requests
   */
  ngOnInit() {
    const enabledFeatures = this.authenticationService.enabledFeatures();
    const driverShiftsExportEnabled = enabledFeatures.includes(
      'hasDriverShiftExport'
    );
    this.driverShiftsExportEnabled = driverShiftsExportEnabled;
    if (!driverShiftsExportEnabled) {
      this.multipleActionDropdownOptions =
        this.multipleActionDropdownOptions.filter(
          (option) => option.action !== 'driver-shifts-export'
        );
    }

    let combinedParams = observableCombineLatest(
      this.route.params,
      this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe((result) => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        if (result.qparams['sortBy']) {
          this.tableConfig.sortBy = result.qparams['sortBy'] || '';
          this.tableConfig.sortDirection = result.qparams['sortAsc'] || '';
        } else {
          this.tableConfig.sortBy = 'start_time';
          this.tableConfig.sortDirection = 'desc';
        }
      })
    );

    if (this.appliedFilters.length === 0 && !this.search) {
      let startDate = moment();
      startDate = startDate.subtract(1, 'month');
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let defaultDateFilter = new FilterOption({
        filterType: 'date',
        default: true,
        key: 'startDate',
        title: this.translationService.instant('Start Date'),
        displayValues: startDate.format('MM/DD/YYYY') || null,
        values: startDate.toISOString(),
        query: {
          start_time__gte: startDate.toISOString(),
        },
      });
      this.appliedFilters.push(defaultDateFilter);
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * Opens the filter dialog and sets up the callback to handle changes made to any available filters
   */
  openFilters(): void {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px',
    });
    dialog.componentInstance.filters = [...FILTERS]
    dialog.componentInstance.callback = (res) => this.filterChanges(res);
    dialog.componentInstance.model = Object.assign(
      dialog.componentInstance.model,
      this.appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values;
        return acc;
      }, {})
    );

    let startDate = get(
      _find(this.appliedFilters, { key: 'startDate' }),
      'values'
    );
    if (startDate) {
      dialog.componentInstance.model.startDate = new Date(startDate);
    }
    let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
    if (endDate) {
      dialog.componentInstance.model.endDate = new Date(endDate);
    }
    dialog.componentInstance.model.carrier = null;

    this.filtersDialog = dialog.componentInstance;
  }

  /**
   * Handles the filter changes and applys them to the table data API request
   *
   * @param {any} filterRes The filter change object
   */
  filterChanges(filterRes): void {
    const queryKeys = {
      startDate: 'start_time__gte',
      endDate: 'end_time__lte',
      carrier: 'driver__carrier',
      truckChanged: 'truck_changed',
      lunchBreak: 'lunch_break_duration__gte',
      vehicleBreakdown: 'vehicle_breakdown_duration__gte',
      includeLeasedFleetDrivers: 'include_leased_fleet_drivers',
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues =
        filterRes[key] && filterRes[key]['name']
          ? filterRes[key]['name']
          : values;
      if (typeof values === 'boolean') {
        if (values) {
          values = values.toString();
          values = values.charAt(0).toUpperCase() + values.slice(1);
          query[queryKeys[key]] = values;
        }
        displayValues = values;
      } else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
        if (typeof values === 'string') {
          query[queryKeys[key]] = values;
        } else {
          query[queryKeys[key]] = displayValues = values.toISOString();
        }
      } else if (filterRes[key]) {
        query[queryKeys[key]] =
          filterRes[key] && filterRes[key].id
            ? filterRes[key].id
            : filterRes[key];
      }
      let filter = new FilterOption({
        filterType:
          ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
        key: key,
        title: (key.charAt(0).toUpperCase() + key.slice(1))
          .replace(/([A-Z])/g, ' $1')
          .trim(),
        displayValues: displayValues || null,
        values: values,
        query: query,
      });
      if (filter.values === 'False' || !filter.values) {
        falseyFilters.push(filter);
      }
      return filter;
    });
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

  /**
   * Handles the selected UI bulk action and fires the relevant action's class method
   *
   * @param {any} option The selected UI option data
   */
  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedShifts();
        break;
      case 'edit':
        this.editSelectedShifts();
        break;
      case 'driver-shifts-export':
        this.exportDriverShifts();
        break;
    }
  }

  /**
   * Bulk edit the selected shifts
   */
  editSelectedShifts() {
    let scope = {};
    let params = new HttpParams();

    this.shiftService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        if (this.selectedShifts.length) {
          Object.assign(scope, {
            include: this.selectedShifts.map((t) => t.id),
          });
        } else if (this.allSelected) {
          Object.assign(scope, {
            exclude: this.excludeShifts.map((t) => t.id),
          });
          Object.keys(this.appliedFilters).map((key) => {
            if (this.appliedFilters[key]) {
              let query = this.appliedFilters[key]['query'];
              Object.keys(query).map((queryKey) => {
                params = params.set(queryKey, query[queryKey]);
              });
            }
          });
          params = params.set('search', this.search);
          Object.assign(scope, {
            include: this.fancyTable.dataSource.data
              .map((s: ShiftReport) => s.id)
              .filter((id) => !scope['exclude'].includes(id)),
          });
        }
        this.dialog.open(EditShiftsDialogComponent, {
          width: '475px',
          data: {
            callback: this.editSelectedShiftsCallback,
            shifts: scope['include'],
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  /**
   * Generates a shift export using the pandas export API functionality
   */
  exportSelectedShifts() {
    let {params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters, 
      this.selectedShifts, 
      this.excludeShifts, 
      this.allSelected, 
      this.search
    );

    this.shiftService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        const orderedColumns = this.columnToggle.displayedColumns.map((col) => {
          const mappedCol = this.mappedColumns.find((c) => c.key === col);
          return {
            key: col,
            value: mappedCol ? mappedCol.value : col,
            selected: mappedCol ? true : false,
          };
        });

        const displayedColumns = fields.map((field) => {
          const col = [...orderedColumns].find((c) => c.value === field.key);
          return {
            ...field,
            value: col ? col.key : field.key,
            selected: col ? col.selected : false,
          };
        });

        // so it's in the same order as the displayed columns
        const reversedColumns = [...this.columnToggle.displayedColumns].reverse();
        displayedColumns.sort(
          (a, b) =>
            reversedColumns.indexOf(b.value) -
            reversedColumns.indexOf(a.value)
          );

        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'shifts',
            buttonText: this.translationService.instant('Export Data to CSV'),
            callback: this.exportCallback,
            fields,
            params,
            scope,
            service: this.shiftService,
            displayedColumns,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  /**
   * Generates a shift export using the pandas export API functionality
   */
  exportDriverShifts() {
    const customUrl = 'drivers/shifts/pandas-export/';
    let scope = {};
    let params = new HttpParams();

    this.shiftService.getExportFields(customUrl).subscribe(
      (fields: FieldOption[]) => {
        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'driver-shifts',
            buttonText: this.translationService.instant('Export Data to CSV'),
            callback: this.exportCallback,
            fields,
            params,
            scope,
            customUrl,
            service: this.driverShiftService,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  /**
   * Drives the row selection functionality, including 'allSelected' states for paginated data
   *
   * @param {{ allSelected: boolean, selection: SelectionModel<ShiftReport>, exclusion: SelectionModel<ShiftReport> }} shift
   * The selection event object
   */
  selector(event: {
    allSelected: boolean;
    selection: SelectionModel<ShiftReport>;
    exclusion: SelectionModel<ShiftReport>;
  }) {
    this.allSelected = event.allSelected;
    if (!this.allSelected) {
      this.selectedShifts = event.selection.selected;
      this.excludeShifts = [];
    } else {
      this.selectedShifts = [];
      this.excludeShifts = event.exclusion.selected;
    }

    // it works for 99% cases, except when you select all and then manually deselect all. angular material bug?
    this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map((opt) => 
      opt.action === 'edit' ? { ...opt, disabled: event.selection.selected.length === 0 && !event.allSelected } : opt
    );
  }

  /**
   * Makes an edit to either the startTime or endTime field and saves that edit to the API shift record
   *
   * @param {ShiftReport} shift The target shift report
   * @param {string} field The time field we will apply the edit to
   * @param {any} value The edit value for the time adjustment
   */
  editTime(shift: ShiftReport, field: string, value: string) {
    let saveData: any = { id: shift.id };
    const newTime = moment(value);

    if (field === 'start' && !newTime.isSame(shift.startTime)) {
      saveData.startTime = value;
      if (newTime.isAfter(shift.endTime)) {
        saveData.endTime = value;
      }
    } else if (field === 'end' && !newTime.isSame(shift.endTime)) {
      saveData.endTime = value;
      if (newTime.isBefore(shift.startTime)) {
        saveData.startTime = value;
      }
    }
    this.shiftService
      .save(saveData, { include_leased_fleet_drivers: 'True' })
      .subscribe(
        (updates) => {
          if (shift) {
            shift.startTime = updates.startTime;
            shift.endTime = updates.endTime;
          }
        },
        (err) => {
          this.errors = err;
        }
      );
  }

  /**
   * Makes an edit to the adjustment field and saves that edit to the API shift record
   *
   * @param {ShiftReport} shift The target shift report
   * @param {any} value The edit value for the adjustment
   */
  editAdjustment(shift: ShiftReport, value: any) {
    let saveData: any = { id: shift.id, adjustment: Number(value) };
    if (saveData.adjustment !== shift.adjustment) {
      this.shiftService.save(saveData).subscribe(
        (updates) => {
          if (shift) {
            shift.adjustment = updates.adjustment;
          }
        },
        (err) => {
          this.errors = err;
        }
      );
    }
  }

  openAddShift() {
    const dialog = this.dialog.open(NewShiftDialogComponent, {
      width: '455px',
    });
    this.allSubscriptionsToUnsubscribe.push(
      dialog.componentInstance.shiftCreated.subscribe(() => {
        this.fancyTable.getRecords();
      })
    );
  }

  /**
   * Formats an ISO date string into a human readable date format
   *
   * @param {string} dateString The ISO formatted date string
   * @returns {string} The formatted (pretty) date for display
   */
  prettyDate(dateString: string): string {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime())
      ? this.datePipe.transform(date, 'MM/dd/yyyy')
      : '';
  }

  openContextMenu(event: any, driverId: string, shiftReport: ShiftReport) {
    this.contextMenuEventSubject.next({
      event,
      shiftReport,
      driverId,
    });
  }

  onShiftEnd() {
    this.fancyTable.getRecords();
  }
}
