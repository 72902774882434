const camelcaseKeysDeep = require('camelcase-keys-deep');

export class PaySheetFilter {
  id: string;
  name: string;

  constructor(filterInfo: any) {
    filterInfo = camelcaseKeysDeep(filterInfo);

    this.id = filterInfo.id;
    this.name = filterInfo.name;
    if (filterInfo.externalIdentifier) {
      this.name = [
        filterInfo.externalIdentifier, filterInfo.job && filterInfo.job.name
      ].filter(Boolean).join(': ');
    } else if (filterInfo.job && filterInfo.job.name) {
      this.name = filterInfo.job && filterInfo.job.name;
    }
  }
}
