import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tag-display',
  templateUrl: './tag-display.component.html',
  styleUrls: ['./tag-display.component.scss'],
})
export class TagDisplayComponent implements OnInit {
  @Input() tags = [];
  @Input() label = 'Tags';
  tagNames = '';

  constructor() { }

  ngOnInit() {
    this.tagNames = this.tags && this.tags.map(tag => tag.name).join(', ');
  }
}
