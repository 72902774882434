<form #filterOptions="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Results</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div *ngFor="let filter of filters" class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container [ngSwitch]="filter.type">
          <ng-container *ngSwitchCase="'dateRange'">
            <div  class="field-group start-date" fxFlex="50">
              <label translate>From</label>
              <date-range-picker [selectedDates]="model.startDate ? [model.startDate] : []"
                                (dateChanged)="onDateChanged($event, 'startDate')">
              </date-range-picker>
            </div>
            <div class="field-group end-date" fxFlex="50">
              <label translate>To</label>
              <date-range-picker [selectedDates]="model.endDate ? [model.endDate]: []"
                                (dateChanged)="onDateChanged($event, 'endDate')">
              </date-range-picker>
            </div>
          </ng-container>
          <div *ngSwitchCase="'boolean'" class="field-group {{ filter.field }}" fxFlex="100">
            <label attr.data-label="{{ filter.label | translate }}" class="toggle-label">
              <input type="checkbox" name="{{ filter.field }}" checked="{{ model[filter.field] }}" [(ngModel)]="model[filter.field]" class="toggle-input">
              <span class="slider round"></span>
            </label>
          </div>
          <div *ngSwitchCase="'text'" class="field-group {{ filter.field }}" fxFlex="100">
            <label translate>{{ filter.label | translate }}</label>
            <input type="text" name="{{ filter.field }}" [(ngModel)]="model[filter.field]">
          </div>
          <div *ngSwitchCase="'number'" class="field-group {{ filter.field }}" fxFlex="100">
            <label translate>{{ filter.label | translate }}</label>
            <input type="number" name="{{ filter.field }}" [(ngModel)]="model[filter.field]">
          </div>
          <div *ngSwitchCase="'dropdown'" class="field-group {{ filter.field }}" fxFlex="100">
            <label translate>{{ filter.label | translate }}</label>
            <ruckit-dropdown
              [config]="filter.dropdownConfig"
              [selectedOption]="model[filter.field]"
              (onSelect)="onSelect(filter.field, $event)">
            </ruckit-dropdown>
          </div>
        </ng-container>
      </div>
    </div>
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>
