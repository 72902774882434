import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import moment = require('moment');

import { DateTimeRange, TimelineRowData, TimelineConfig, TimeInterval } from '../../shared';
import { Assignment } from '../../assignments/assignment';
import { Driver } from '../../drivers/driver';
import { LocationUpdate } from '../../jobs/locationUpdate';
import { JobEvent } from '../../job-events/job-event';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'ruckit-replay-timeline',
  templateUrl: './ruckit-replay-timeline.component.html',
  styleUrls: ['./ruckit-replay-timeline.component.scss']
})
export class RuckitReplayTimelineComponent implements OnInit {
  @Input() driver: Driver;
  @Input() locationUpdates: LocationUpdate[];
  @Input() selectedDate: moment.Moment;
  @Input() timeRange: DateTimeRange;
  @Input() timeInterval: TimeInterval;
  @Input() assignments: Assignment[] = [];
  @Input() jobEvents: JobEvent[] = [];
  @Input() playing: boolean;
  @Input() showVoided = false;
  @Output() onRefreshTimeline = new EventEmitter<boolean>();

  timelineRange: DateTimeRange;
  timelineData: TimelineRowData[] = [];
  timelineConfig: TimelineConfig = {
    visibleBars: ['punchcards', 'trips', 'predictedTrips', 'truckStatuses'],
    headerHeight: 47,
    rowHeight: 120,
    scroll: true,
    currentTime: false,
    selectedTime: true
  };
  driverStart: string;

  selectedTimeValue: string;
  contextMenuOpened = false;
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  @Output() selectedTimeChange: EventEmitter<string> = new EventEmitter();
  @Input() get selectedTime() { return this.selectedTimeValue; }
  set selectedTime(time) {
    this.selectedTimeValue = time;
    this.selectedTimeChange.emit(time);
  }


  constructor() {}

  ngOnInit() {
    this.timeRange = this.timeRange ? this.timeRange : {
      startDatetime: this.selectedDate.startOf('day').toISOString(),
      endDatetime: this.selectedDate.endOf('day').toISOString()
    };
    this.timelineData = [{
      referenceId: '', trips: [], predictedTrips: [], punchcards: [],
      signalLosses: [], waitTimes: [], shifts: [], pavers: []
    }];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (
        (changes.showVoided) ||
        (changes.assignments && changes.assignments.currentValue.length) ||
        (changes.locationUpdates && changes.locationUpdates.currentValue.length)
      ) && this.driver
    ) {
      this.setupTimelineData(this.assignments);
      const matchedJobEvent = this.jobEvents.find(jobEvent => (jobEvent.id === this.assignments[0].jobevent.id));
      this.driverStart = this.assignments[0] && this.assignments[0].uniqueStart ? this.assignments[0].uniqueStart :
      this.assignments[0] && matchedJobEvent && matchedJobEvent.shift1StartTimestamp;
    }
  }

  setupTimelineData(assignments: Assignment[] = []) {
    this.timelineRange = {
      startDatetime: this.selectedDate.startOf('day').toISOString(),
      endDatetime: this.selectedDate.endOf('day').toISOString()
    };
    this.timelineData = [{
      referenceId: '', trips: [], predictedTrips: [], punchcards: [],
      signalLosses: [], waitTimes: [], shifts: [], pavers: []
    }];
    let waitTimeIndex = 0;
    this.locationUpdates.forEach((update, i) => {
      if (this.locationUpdates[i + 1] &&
          Math.abs(moment(this.locationUpdates[i + 1].date).diff(moment(update.date), 'minutes')) > 1) {
          this.timelineData[0].signalLosses.push({
            startDatetime: update.date,
            endDatetime: this.locationUpdates[i + 1].date
          });
      }
      // STOPPED TIME SETUP CODE
      if (update.speed < 2) {
        if (!this.timelineData[0].waitTimes[waitTimeIndex]) {
          let stopped = false;
          for (let x = 1; x <= 5; x++) {
            if (this.locationUpdates[i + x] && this.locationUpdates[i + x].speed < 2) { stopped = true; }
          }
          if (stopped) {
            this.timelineData[0].waitTimes[waitTimeIndex] = <DateTimeRange>{
              startDatetime: update.date
            };
          }
        } else if (this.timelineData[0].waitTimes[waitTimeIndex] &&
                   this.timelineData[0].waitTimes[waitTimeIndex].startDatetime &&
                   (!this.locationUpdates[i + 1] || this.locationUpdates[i + 1].speed >= 2)) {
          if (moment(update.date).diff(this.timelineData[0].waitTimes[waitTimeIndex].startDatetime, 'minutes') >= 2) {
            this.timelineData[0].waitTimes[waitTimeIndex].endDatetime = update.date;
            waitTimeIndex++;
          } else {
            delete this.timelineData[0].waitTimes[waitTimeIndex];
          }
        }
      }
    });

    assignments.forEach(assignment => {
      const jobEvent = this.jobEvents.find(jobEvent => (jobEvent.id === assignment.jobevent.id));
      Object.keys(assignment).forEach(key => {
        if (['trips', 'predictedTrips', 'punchCards'].indexOf(key) !== -1 && assignment[key] !== null) {
          assignment[key].forEach(el => {
            el.jobEvent = this.jobEvents.find(jobEvent => (jobEvent.id === assignment.jobevent.id));
          });
        }
      });

      if (assignment.uniqueStart) {
        this.timelineData[0].shifts.push({
          type: 'scheduled',
          startDatetime: assignment.uniqueStart,
          endDatetime: undefined
        });
      } else {
        if (jobEvent && jobEvent.shift1StartTimestamp && jobEvent.shift1EndTimestamp) {
          this.timelineData[0].shifts.push({
            type: 'scheduled',
            startDatetime: jobEvent.shift1StartTimestamp,
            endDatetime: jobEvent.shift1EndTimestamp
          });
        }

        if (jobEvent && jobEvent.shift2StartTimestamp && jobEvent.shift2EndTimestamp) {
          this.timelineData[0].shifts.push({
            type: 'scheduled',
            startDatetime: jobEvent.shift2StartTimestamp,
            endDatetime: jobEvent.shift2EndTimestamp
          });
        }
      }

      let pavers = [];
      assignment.trips.forEach(trip => {
        if (trip.connexPaverStartUnloading) {
          pavers.push({
            startDatetime: trip.connexPaverStartUnloading,
            endDatetime: trip.connexPaverEndUnloading
          });
        }
      });
      
      assignment.shifts && assignment.shifts.forEach(shift => {
        this.timelineData[0].shifts.push({
          type: 'shift',
          startDatetime: shift.startTime,
          endDatetime: shift.endTime,
        })
      });

      const trips = [...this.timelineData[0].trips, ...assignment.trips].filter(v => {
        return this.showVoided ? true : !v.void;
      });
      const predictedTrips = [...this.timelineData[0].predictedTrips, ...assignment.predictedTrips].filter(trip => trip && !trip.void);
      this.timelineData = [{
        referenceId: assignment.id,
        trips: trips,
        predictedTrips: predictedTrips,
        punchcards: [...this.timelineData[0].punchcards, ...assignment.punchCards],
        signalLosses: [...this.timelineData[0].signalLosses],
        waitTimes: [...this.timelineData[0].waitTimes],
        shifts: [...this.timelineData[0].shifts],
        pavers: [...pavers]
      }];
    });
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuOpened = true;
    this.contextMenuEventSubject.next({
      event,
      driverId,
    });
  }

  onRefresh() {
    this.onRefreshTimeline.emit(true);
  }
}
