import { Organization } from '../organizations/organization';
import { Location } from '../locations/location';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Scale {
  id: string;
  weight: number;
  status: string;
  createdAt: string;
  lastModified: string;
  name: string;
  weightUnit: string;
  ownerOrganization: Organization;
  location: Location;
  weightType: string;
  disabled: boolean;

  constructor(scaleInfo: any) {
    scaleInfo = camelcaseKeysDeep(scaleInfo);

    this.id = scaleInfo.id;
    this.weight = scaleInfo.weight;
    this.status = scaleInfo.status;
    this.createdAt = scaleInfo.createdAt;
    this.lastModified = scaleInfo.lastModified;
    this.name = scaleInfo.name;
    this.weightUnit = scaleInfo.weightUnit;
    this.ownerOrganization = scaleInfo.ownerOrganization;
    this.location = scaleInfo.location;
    this.weightType = 'scales';
    this.disabled = false;

    switch (this.status) {
      case 'unstable': {
        this.disabled = true;
        break;
      }
      case 'disconnected': {
        this.disabled = true;
        break;
      }
    }
  }
}

export enum WeightType {
  TareWeight = 'Truck Weights',
  ScaleWeight = 'Scales',
}
