import { Observable, combineLatest } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocationUpdate } from './locationUpdate';
import { handleError, requestHeaders } from '../shared/api.service';

@Injectable()
export class LocationUpdateService {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(private http: HttpClient) { }

  get(query: any = null): Observable<LocationUpdate[]> {
    let params: HttpParams = new HttpParams();
    let url = this.baseUrl + 'locationupdates/';
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key].toString) {
          if (key === 'id') {
            this.baseUrl += query[key] + '/';
          } else {
            params = params.set(key, query[key].toString());
          }
        }
      });
    }
    return this.http.get(url, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getAll(pageSize = 1000, query: any = null): Observable<LocationUpdate[]> {
    let params: HttpParams = new HttpParams();
    let url = this.baseUrl + 'locationupdates/';
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key].toString) {
          if (key === 'id') {
            this.baseUrl += query[key] + '/';
          } else {
            params = params.set(key, query[key].toString());
          }
        }
      });
    }

    params = params.set('page_size', '1');
    let requestCount = 0;

    return this.http.get(url, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => this.extractData(res)),
      mergeMap(() => {
        params = params.set('page_size', pageSize.toString());
        let nextReqs: Observable<LocationUpdate[]>[] = [];
        if (this.count < pageSize) {
          nextReqs.push(
            this.http.get(url, {
              headers: requestHeaders(),
              params: params
            }).map(res => {
              return this.extractData(res);
            })
          );
        } else {
          for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
            params = params.set('page', i.toString());
            nextReqs.push(
              this.http.get(url, {
                headers: requestHeaders(),
                params: params
              }).pipe(
                map(res => {
                  requestCount++;
                  return res;
                }),
                map(res => this.extractData(res))
              )
            );
          }
        }
        return combineLatest(nextReqs);
      }),
      map(data => {
        let mergedList: LocationUpdate[] = [];
        data.forEach(list => {
          mergedList = mergedList.concat(list);
        });
        return mergedList;
      }),
      catchError(handleError)
    );
  }

  coordinates(bounds: any, query: any = null) {
    let params: HttpParams = new HttpParams();
    let url = this.baseUrl + 'locationupdates/coordinates/';
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key].toString) {
          if (key === 'id') {
            this.baseUrl += query[key] + '/';
          } else {
            params = params.set(key, query[key].toString());
          }
        }
      });
    }

    let model = bounds.toJSON();

    let response = this.http.post(url, model, {
      headers: requestHeaders(),
      params: params,
    }).pipe(
      map((res) => {
        let resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        let body = resObj['results'] || resObj;
        return body;
      }),
      catchError(handleError)
    );

    return response;
  }

  getNext(): Observable<any[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];
    if (body) {
      return body.map(result => {
        return new LocationUpdate(result);
      });
    } else if (resObj) {
      return new LocationUpdate(resObj);
    }
  }
}
