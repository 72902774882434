import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterContentInit,
  TemplateRef,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { MenuItem } from './interfaces/menu-item';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements AfterContentInit {
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;

  @Input() menuOptions: MenuItem[] = [];
  @Input() event: any;
  @Input() titleRef: TemplateRef<any>;
  @Output() menuClosed = new EventEmitter();
  @Output() menuSelection = new EventEmitter<MenuItem>();

  contextMenuPosition = { x: '0px', y: '0px' };

  constructor() {}

  ngAfterContentInit() {
    this.openContextMenu(this.event);
  }

  openContextMenu(event: MouseEvent) {
    if (event) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = this.menuOptions;

      this.contextMenu.menuClosed.subscribe(() => {
        this.menuClosed.emit();
      });
      // this.contextMenu.menu.focusFirstItem('mouse');
      setTimeout(() => {
        this.contextMenu.openMenu();
      });
    }
  }

  onContextMenuAction(item: MenuItem) {
    if (!item.disabled) {
      this.menuSelection.emit(item);
    }
  }
}
