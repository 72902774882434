"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{display:flex}.container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]{flex:1 1 40%}.container[_ngcontent-%COMP%]   .truck-map[_ngcontent-%COMP%]{flex:1 1 60%}.container[_ngcontent-%COMP%]   .truck-name[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354052}.container[_ngcontent-%COMP%]   .eta[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#595959}.right-actions[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{margin-right:20px}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]{height:34px;display:flex;flex-direction:row}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a.btn[_ngcontent-%COMP%]{height:34px;font-size:14px;margin-left:-1px;text-decoration:none}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a.btn.active[_ngcontent-%COMP%]   i[_ngcontent-%COMP%], .right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a.btn[_ngcontent-%COMP%]:hover   i[_ngcontent-%COMP%]{color:#015bc5}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a.btn[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#d0d2dc;margin-right:0;margin-top:0}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a.btn[_ngcontent-%COMP%]:first-child{border-radius:4px 0 0 4px;margin-left:0}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a.btn[_ngcontent-%COMP%]:last-child{border-radius:0 4px 4px 0}.right-actions[_ngcontent-%COMP%]   .segmented-buttons[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:first-child{margin-right:0}"];
exports.styles = styles;
