import { PurchaseOrderFilter } from './purchase-order.filter';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PurchaseOrderFilterSerializer {
  fromJson(filterInfo: any): PurchaseOrderFilter {
    filterInfo = camelcaseKeysDeep(filterInfo);
    const resource = new PurchaseOrderFilter();
    if (!filterInfo) { return resource; }

    resource.id = filterInfo.id;
    resource.name = filterInfo.name;

    return resource;
  }
  toJson(filter: PurchaseOrderFilter): any {
    let json = {
      id: filter.id,
      name: filter.name
    };

    return decamelizeKeysDeep(json);
  }
}
