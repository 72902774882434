import { Assignment } from '../assignments/assignment';
import { TimelineRowData } from '../shared';

export class PayRecord {
  id?: string;
  paySheet?: string;
  ticketNumber?: string;
  data: {
    referenceId: string;
    assignment: Assignment;
    uniqueStartDate: string;
    date?: string;
    editing?: boolean;
    selected?: boolean;
    shift1StartTimestamp: string;
    shift1EndTimestamp: string;
    rowData: TimelineRowData;
  };
  processStatus: 'pending' | 'processing' | 'complete';
}
