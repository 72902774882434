"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var plants_component_1 = require("./plants.component");
var routes = [
    {
        path: 'plants', component: plants_component_1.PlantsComponent, canActivate: [auth_guard_1.AuthGuard]
    }
];
var PlantManagerRoutingModule = /** @class */ (function () {
    function PlantManagerRoutingModule() {
    }
    return PlantManagerRoutingModule;
}());
exports.PlantManagerRoutingModule = PlantManagerRoutingModule;
