import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserNotification } from './user-notification';
import { UserNotificationSerializer } from './user-notification.serializer';
import { ResourceService } from '../resource.service';

@Injectable()
export class UserNotificationService extends ResourceService<UserNotification> {
  constructor(http: HttpClient) {
    super(http, 'notifications/', new UserNotificationSerializer());
  }
}
