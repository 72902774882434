"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mock_job_1 = require("../jobs/mock-job");
exports.MARKETPLACE = {
    id: '00000000-0000-0000-0000-000000000001',
    job: mock_job_1.JOB,
    truckTypes: [],
    dailyDeliveryTarget: '',
    numTrucks: '5',
    marketNumTrucks: '4',
    deliveryInterval: null,
    haulRate: '55.0',
    shift1Start: '',
    shift1End: '',
    shift1Status: '',
    shift2Start: '',
    shift2End: '',
    shift2Status: '',
    lastDispatchedBy: '',
    lastDispatchedTime: '',
    estimatedNumberOfLoads: '7',
    assignmentsCount: '2',
    estimatedNumberTons: '175',
    allowAnyTruckType: true,
    marketInvoiceRate: '55.0',
    accepted: false,
    dateRange: 'Thursday, May 25 - Saturday, May 27',
    shift1StartTime: '8:00 am',
    endTime: '5:00 pm',
    customer: 'Sample Customer',
    truckTypeNames: 'Truck Type Names',
    date: 'Saturday, March 31'
};
