import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';

// angular material
import { MatDialog } from '@angular/material';

// lodash
import { clone } from 'lodash';

// moment
import moment = require('moment-timezone');

// services
import { CondensedJobEventService } from '../../job-events/condensed-job-event.service';
import { JobEventStatService } from '../../job-event-stats/job-event-stat.service';
import { JobService } from './../../jobs/job.service';
import { AuthenticationService } from '../../shared/index';
import { JobEventService } from '../../job-events/job-event.service';
import { LocationService } from '../../locations/location.service';

// components
import { EditJobDialogComponent } from '../../jobs/edit-job-dialog.component';
import { CancelJobDialogComponent } from '../../jobs/cancel-job-dialog.component';
import { MessageDriversDialogComponent } from '../../messages/message-drivers-dialog.component';
import { RuckitAddEmployeeDialogComponent } from '../../team/add-employee-dialog.component';
import { EditJobEventDialogComponent } from '../../job-events/edit-job-event-dialog.component';
import { FancyTableComponent} from '../../shared/fancy-table/fancy-table.component';
import { ExportDialogComponent, ExportDialogData, FieldOption } from '../../shared/export-dialog/export-dialog.component';

// types
import { JobEventStat } from '../../job-event-stats/job-event-stat';
import { CondensedJobEvent } from '../../job-events/condensed-job-event';
import { Location } from '../../locations/location';
import { TableConfig, TableData } from '../../shared/fancy-table/table.types';

// constants
import { AVAILABLE_COLUMNS, AVAILABLE_FILTERS, DISPLAYED_COLUMNS, MENU_OPTIONS } from './daily-board-list-input-constants';

// utils
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'ruckit-daily-board-list',
  templateUrl: './daily-board-list.component.html',
  styleUrls: ['./daily-board-list.component.scss']
})

export class DailyBoardListComponent implements OnInit, OnChanges {
  allSelected = false;
  selectedJobs: CondensedJobEvent[] = [];
  excludeJobs: CondensedJobEvent[] = [];
  allJobsCount: number;
  @Input() availableColumns = [...AVAILABLE_COLUMNS(this.translateService)];
  @Input() displayedColumns = [...DISPLAYED_COLUMNS]
  @Input() availableFilters = [...AVAILABLE_FILTERS(this.translateService)];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() hideCancelled: boolean;
  @Input() jobEventDate: Date;
  urlDate = '';
  @Input() withJobStats = false;
  @Output() searchReset = new EventEmitter();
  loading = true;
  enabledFeatures: string[] = [];
  requiresConfirmationForJobs = false;
  totalHoursField: 'totalHours' | 'totalTripHours' | 'totalCompletedTripHours' = 'totalHours';
  detailColumns = ['detail'];
  dailyBoardTableConfig = {
    hasHeader: true,
    pageSize: 25,
    service: CondensedJobEventService,
    preferenceKey: 'DailyBoardListComponent-CondensedJobEventService',
    query: {},
    filterQuery: true,
    collectionTitle: this.translateService.instant('Jobs'),
    noResultsText: this.translateService.instant('a job'),
    newRecordRoute: ['/jobs/new'],
    sortBy: 'job__name',
    sortDirection: 'asc',
    customHeight: true,
    automaticallyHidePagination: false,
    menuOptions: [...MENU_OPTIONS(this.translateService)]
  };
  multipleActionDropdownOptions = [
    { name: this.translateService.instant('Cancel'), action: 'cancel', link: false },
    { name: this.translateService.instant('Export'), action: 'export', link: false },
  ];
  locationsConfig = {
    selectText: this.translateService.instant('Select Location'),
    loadingText: this.translateService.instant('Loading Locations...'),
    noResultsText: this.translateService.instant('No Locations'),
    service: LocationService,
    rightAlign: true
  };
  unloadingDropdownOpen;
  loadingDropdownOpen;
  errors = [];
  stats: JobEventStat[] = [];
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable detail columns.
   */
  @ViewChild('detailTemplates', { static: false }) detailTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('dailyBoardTable', { static: false }) dailyBoardTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  constructor(
    private jobEventService: JobEventService,
    private jobEventStatService: JobEventStatService,
    private condensedJobEventService: CondensedJobEventService,
    private authenticationService: AuthenticationService,
    private jobService: JobService,
    private locationService: LocationService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) {
      query['user_tags'] = 'True';
    }
    this.dailyBoardTableConfig['query'] = {
      ...this.dailyBoardTableConfig['query'], ...query
    };
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
      const totalHoursPreference = this.authenticationService.getFeature('calculator');
      this.totalHoursField = totalHoursPreference === 'trips' ?
        'totalTripHours' : totalHoursPreference === 'completed_trips' ?
        'totalCompletedTripHours' : 'totalHours';
    }
    if (this.enabledFeatures && this.enabledFeatures.includes('driverFleetRequireAssignmentConfirmation')) {
      this.requiresConfirmationForJobs = this.authenticationService.getFeature('driverFleetRequireAssignmentConfirmation');
      this.displayedColumns.push('assignment-status');
    } else {
      this.requiresConfirmationForJobs = false;
    }
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    let jobEventDateStart = new Date();
    let jobEventDateEnd = clone(jobEventDateStart);

    if (changes['jobEventDate']) {
      const jobEventDate = changes['jobEventDate'].currentValue;
      if (jobEventDate) {
        jobEventDateStart = new Date(this.jobEventDate);
        jobEventDateEnd = clone(jobEventDateStart);
      }
      
      const isSameDate = moment(jobEventDate).diff(changes['jobEventDate'].previousValue, 'days') === 0
      if (!isSameDate && this.dailyBoardTable && this.dailyBoardTable.page > 1){
        this.dailyBoardTable.resetPageNumber();
      } 
    } else if (this.jobEventDate) {
      jobEventDateStart = new Date(this.jobEventDate);
      jobEventDateEnd = clone(jobEventDateStart);
    }

    jobEventDateStart.setHours(0, 0, 0, 0);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    this.urlDate = moment(this.jobEventDate).format('YYYYMMDD');

    this.dailyBoardTableConfig = {
      ...this.dailyBoardTableConfig,
      query: {
        exclude_pending: 'True',
        cancelled: this.hideCancelled ? 'False' : undefined,
        shift1_start__gte: jobEventDateStart.toISOString(),
        shift1_start__lte: jobEventDateEnd.toISOString()
      }
    };
    if (changes['jobEventDate'] && changes['jobEventDate'].isFirstChange()) {
      this.availableFilters = this.availableFilters.map((filter) => {
        if (!filter.service) {
          return filter;
        }
        return {
          ...filter,
          query: {
            exclude_pending: 'True',
            cancelled: this.hideCancelled ? 'False' : undefined,
            shift1_start__gte: jobEventDateStart.toISOString(),
            shift1_start__lte: jobEventDateEnd.toISOString()
          }
        }
      });
    }
    if (this.authenticationService.hasFavoriteTags()) {
      this.dailyBoardTableConfig['query']['user_tags'] = 'True';
    }
    if (this.dailyBoardTable) {
      this.dailyBoardTable.config = <TableConfig>{...this.dailyBoardTableConfig};
    }
  }

  saveJobDayCallback = (e) => {
    this.dailyBoardTable.getRecords();
  }

  cancelJobCallback = (e) => {
    this.dailyBoardTable.getRecords();
    this.dailyBoardTable.resetSelections()
  }

  messageDriversCallback = () => {
    // Update Notice Message
  }

  editJob(jobEvent: CondensedJobEvent): void {
    const dialog = this.dialog.open(EditJobDialogComponent, {
      width: '430px',
      data: { jobId: jobEvent.jobId }
    });
    dialog.componentInstance.callback = this.saveJobDayCallback;
  }

  cancelJobs(jobEvent?: CondensedJobEvent): void {
    let jobEventIds
    const query = AppUtilities.getFilterQuery(this.appliedFilters, this.search)

    if (jobEvent && !jobEvent.cancelled && jobEvent.canEdit) {
      jobEventIds = [jobEvent.id]
    } else if (!jobEvent && this.selectedJobs.length) {
      jobEventIds = this.selectedJobs.map(j => (j.id))
    }

    if (jobEventIds || this.allSelected) {
      const dialog = this.dialog.open(CancelJobDialogComponent, {
        width: '430px',
        data: { 
          jobEventIds, 
          isAllJobEventsSelected: this.allSelected,
          allJobEventsCount: this.allJobsCount,
          jobEventIdsExclude: this.excludeJobs.map(j => j.id),
          jobEventsDate: this.jobEventDate,
          query: {
            ...this.dailyBoardTableConfig.query,
            ...query,
          }
        }
      });
      dialog.componentInstance.callback = this.cancelJobCallback;
    }
  }

  messageDrivers(jobEvent: CondensedJobEvent): void {
    const dialog = this.dialog.open(MessageDriversDialogComponent, {
      width: '870px'
    });
    dialog.componentInstance.jobEventId = jobEvent.id;
    dialog.componentInstance.callback = this.messageDriversCallback;
  }

  inviteCustomer(jobEvent: CondensedJobEvent): void {
    this.jobService.get(jobEvent.jobId).subscribe(currentJob => {
      const { id, email } = currentJob.project.customerOrganization;
      this.dialog.open(RuckitAddEmployeeDialogComponent, {
        width: '444px',
        data: {
          type: 'customer',
          organizationId: id,
          email: email
        },
      });
    });
  }

  /**
   * @param  {string} jobEventId
   * @returns void
   * This would be called when clicked on edit day details from action menu
   * It will make GET request for jobEvent to be able to make PUT request on
   * saving changes in the modal
   */
  openEditJobDayDetailEvent(jobEventId: string): void {
    this.jobEventService.getJobEvent(jobEventId).subscribe(event => {
      const dialog = this.dialog.open(EditJobEventDialogComponent, {
        width: '430px'
      });
      if (dialog) {
        dialog.componentInstance.jobEvent = event;
        dialog.componentInstance.callback = this.saveJobEventCallback;
      }
    });
  }

  /**
   * @param  {} e
   * This would be called on succesful completion of editing day details
   * and will refresh the table with new changes
   */
  saveJobEventCallback = (e) => {
    this.dailyBoardTable.getRecords();
  }

  menuAction(name, jobEvent: CondensedJobEvent): void {
    switch (name) {
      case 'edit-days':
        this.editJob(jobEvent);
        break;
      case 'cancel-day':
        this.cancelJobs(jobEvent);
        break;
      case 'message-drivers':
        this.messageDrivers(jobEvent);
        break;
      case 'invite':
        this.inviteCustomer(jobEvent);
        break;
      case 'edit-day-details':
        this.openEditJobDayDetailEvent(jobEvent.id);
        break;
    }
  }

  filtersModified(appliedFilters): void {
    // let filter = _find(this.appliedFilters, { key: '' });
    // if (!appliedFilters || !appliedFilters.length ) {
    //   this.filterChanges(_find(this.priceListsReq, { id: null }));
    // }
    this.dailyBoardTable.resetPageNumber();
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  refreshTable(): void {
    if (this.dailyBoardTable) {
      this.loadingDropdownOpen = null;
      this.unloadingDropdownOpen = null;
      let query = {
        cancelled: !this.hideCancelled ? 'False' : undefined
      };
      if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
      this.dailyBoardTable.getRecords({ ...this.dailyBoardTableConfig['query'], ...query });
    }
  }

  captureStats(tableData: TableData): void {
    let effectiveRateCalculator = '';
    if (this.enabledFeatures && this.enabledFeatures.includes('effectiveRateCalculator')) {
      effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
    }
    this.jobEventStatService.list(Object.assign(tableData.query, {
      calculator: effectiveRateCalculator
    })).subscribe(stats => {
      tableData.data.forEach((jobEvent: CondensedJobEvent) => {
        jobEvent.stats = stats.find(stat => (stat.jobId === jobEvent.jobId));
        if (!jobEvent.stats) {
          jobEvent.stats = stats.find(stat => (stat.jobId.replace(/[^a-zA-Z0-9]/g, '') === jobEvent.jobId));
        }
        jobEvent.loading = false;
      });
    }, err => {
      tableData.data.forEach((jobEvent: CondensedJobEvent) => jobEvent.loading = false);
    });
  }

  toggleLocationEdit(type: 'loading' | 'unloading', id: string) {
    if (this[type + 'DropdownOpen'] === id) {
      this[type + 'DropdownOpen'] = null;
    } else {
      this[type + 'DropdownOpen'] = id;
    }
    this[(type === 'loading' ? 'unloading' : 'loading') + 'DropdownOpen'] = null;
  }

  selectLocation(type: 'start' | 'end', jobEvent: CondensedJobEvent, location: Location) {
    if (jobEvent[type + 'Location'] !== location.id) {
      this.jobService.save(
        { id: jobEvent.jobId, [type + 'Location']: location }
      ).subscribe(() => this.refreshTable());
    }
  }

  exportSelectJobs() {
    let params = new HttpParams();
    let scope = {};

    if (this.jobEventDate) {
      const startDate = new Date(this.jobEventDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = clone(startDate);
      endDate.setHours(23, 59, 59, 999);
      params = params.set('shift1_start__gte', startDate.toISOString());
      params = params.set('shift1_start__lte', endDate.toISOString());
    }

    this.jobEventService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        if (this.selectedJobs.length) {
          Object.assign(scope, { include: this.selectedJobs.map((t) => t.id) });
        } else if (this.allSelected) {
          Object.assign(scope, { exclude: this.excludeJobs.map((t) => t.id) });
          Object.keys(this.appliedFilters).map((key) => {
            if (this.appliedFilters[key]) {
              let query = this.appliedFilters[key]['query'];
              Object.keys(query).map((queryKey) => {
                params = params.set(queryKey, query[queryKey]);
              });
            }
          });
          if (this.search && this.search.length) {
            params = params.set('search', this.search);
          }
        }

        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'jobs',
            buttonText: this.translateService.instant('Export Data to CSV'),
            callback: () => {
              this.refreshTable();
            },
            fields,
            params,
            scope,
            service: this.jobEventService,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  selector(event: {
    allSelected: boolean,
    selection: SelectionModel<CondensedJobEvent>,
    exclusion: SelectionModel<CondensedJobEvent>
  }) {
    this.allSelected = event.allSelected;
    if (!this.allSelected) {
      this.selectedJobs = event.selection.selected;
      this.excludeJobs = [];
      this.allJobsCount = this.selectedJobs.length
    } else {
      this.selectedJobs = [];
      this.excludeJobs = event.exclusion.selected;
      this.allJobsCount = this.dailyBoardTable.count - this.excludeJobs.length
    }
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectJobs();
        break;
      case 'cancel':
        this.cancelJobs();
        break;
    }
  }

  onSearchClear() {
    this.search = '';
    this.searchReset.emit();
  }
}
