<div class="incomplete-tickets-container" #incompleteticekts>
  <div *ngIf="tickets.length === 0 && !loading" class="not-found">
    <div class="icon"></div>
    <div class="title" translate>No incomplete tickets found!</div>
    <div class="message" translate>
      Incomplete tickets<br />
      will be shown here.
    </div>
  </div>

  <div *ngFor="let ticket of tickets" id="ticket-{{ ticket.id }}" class="ticket-container"
       [ngClass]="{selected: ticket.selected, hidden: loading}" (click)="selectTicket(ticket)">
    <div class="ticket-container-header">
      <div class="ticket-title">
        <div class="title" translate>Ticket #{{ ticket.number }}</div>
        <div *ngIf="ticket.status === 'on-hold'" class="status label-warn" translate>ON HOLD</div>
      </div>
      <div class="date">{{ ticket.createdAt | date:'short' }}</div>
    </div>

    <div class="ticket-container-detail">
      <div class="ticket-container-detail-item">
        <div class="title" translate>Order:</div>
        <div class="value" title="{{ ticket.purchaseOrder }}">
          {{ ticket.purchaseOrder | ngXtruncate:16 }}
        </div>
      </div>
      <div class="ticket-container-detail-item">
        <div class="title" translate>Customer:</div>
        <div class="value" title="{{ ticket.customer }}">
          {{ ticket.customer | ngXtruncate:16 }}
        </div>
      </div>
    </div>

    <div class="ticket-container-detail">
      <div class="ticket-container-detail-item">
        <div class="title" translate>Truck #:</div>
        <div class="value" title="{{ ticket.truckDisplayName }}">
          {{ ticket.truckDisplayName | ngXtruncate:10 }}
        </div>
      </div>
      <div class="ticket-container-detail-item">
        <div class="title" translate>Driver:</div>
        <div class="value" title="{{ ticket.driver || ticket.driverName }}">
          {{ (ticket.driver || ticket.driverName) | ngXtruncate:16 }}
        </div>
      </div>
    </div>
  </div>

  <my-loader *ngIf="loading"></my-loader>
</div>
