import {
  Component, ElementRef, OnInit, Inject, Output, EventEmitter
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TripService } from '../trips/trip.service';
import { CheckinService } from '../checkins/checkin.service';
import { parseErrors } from '../shared/api.service';
import { Checkin } from './checkin';
import { extend } from 'lodash';

@Component({
  selector: 'view-tickets-dialog',
  templateUrl: './view-tickets-dialog.component.html',
  styleUrls: ['./view-tickets-dialog.component.scss'],
  providers: [TripService, CheckinService]
})
export class ViewTicketsDialogComponent implements OnInit {
  @Output() completed = new EventEmitter();
  loadingCheckin;
  unloadingCheckin;
  loading = false;
  errors = [];
  callback;
  checkinsReq;

  constructor(
    public dialogRef: MatDialogRef<ViewTicketsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tripService: TripService,
    private checkinService: CheckinService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (this.data && this.data.trip) {
      this.loadingCheckin = this.data.trip.loadingCheckin;
      this.unloadingCheckin = this.data.trip.unloadingCheckin;
    }
  }

  submitCheckins() {
    this.loading = true;

    this.checkinService.save(this.loadingCheckin).subscribe((_loadingCheckin) => {
      this.checkinService.save(this.unloadingCheckin).subscribe((_unloadingCheckin) => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  requestRetake(checkin) {
    this.checkinService.requestRetake(checkin.id).subscribe((res) => {
      this.data.trip.retakeStatus = 'requested';
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  fileChange(e, checkinType) {
    let checkin;

    switch (checkinType) {
      case 'loading':
        checkin = this.loadingCheckin;
        break;
      case 'unloading':
        checkin = this.unloadingCheckin;
        break;
    }

    this.checkinService.uploadImage('PUT', checkin.id, 'ticket_image', e.srcElement.files).subscribe((res) => {
      let _checkin = new Checkin(res);
      _checkin.ticketImage = _checkin.ticketImage + '&cb=' + encodeURIComponent(new Date().toISOString());
      extend(checkin, _checkin);
      this.completed.emit(checkin);
    }, function(err) {
      console.error('err', err);
    });
  }
}
