<div class="container driver-punch-cards-container">
  <div class="actions-wrapper">
    <fancy-table
      #punchCardsTable
      [(availableColumns)]="availableColumns"
      [(displayedColumns)]="displayedColumns"
      [(filters)]="appliedFilters"
      [(search)]="search"
      (columnChange)="columnsChanged($event)"
      [config]="tableConfig"
      (selectionChanged)="selector($event)"
      (onDataLoaded)="onDataLoaded($event)"
    >
      <ng-template
        #columnTemplates
        let-row="row"
        let-column="column"
        let-value="value"
      >
        <ng-container [ngSwitch]="column">
          <div class="head selector" *ngSwitchCase="'select'">
            <label>
              <input type="checkbox" [checked]="allSelected || row.selected" />
            </label>
          </div>
          <div class="actions-dots" *ngSwitchCase="'action-dots'">
            <action-menu icon="icon-more">
              <ng-container
                *ngFor="let option of row.filterOptions(menuOptions)"
              >
                <a
                  *ngIf="option.link"
                  mat-menu-item
                  [routerLink]="row.url(option.action)"
                  [queryParams]="{ returnTo: currentUrl }"
                  >{{ option.name }}</a
                >
                <a
                  *ngIf="!option.link"
                  mat-menu-item
                  (click)="menuAction(option.action, row)"
                  >{{ option.name }}</a
                >
              </ng-container>
            </action-menu>
          </div>
          <div class="job-date" *ngSwitchCase="'job-date'">
            <div class="value">
              {{ row.jobDate | date : 'mediumDate' || '&nbsp;' }}
            </div>
            <div class="trip-status" *ngIf="row.displayableStatus">
              <div class="label-{{ row.administrativeStatus }}">
                {{ row.administrativeStatus }}
              </div>
            </div>
          </div>
          <div class="job-title" *ngSwitchCase="'job'">
            <div class="value">{{ row.job || '&nbsp;' }}</div>
            <div class="label">{{ row.project || '&nbsp;' }}</div>
          </div>
          <div class="value job-start-time" *ngSwitchCase="'start-time'">
            <div class="value">{{ row.duration || '&nbsp;' }}</div>
            <div class="label">{{ row.schedule || '&nbsp;' }}</div>
          </div>
          <div class="ticketNumber value" *ngSwitchCase="'ticket-number'">
            {{ row.ticketNumber }}
          </div>
          <div class="ticket-image" *ngSwitchCase="'ticket-image'">
            <a
              [routerLink]="row.url('edit')"
              [queryParams]="{ returnTo: currentUrl }"
            >
              <div class="ticket-photo" *ngIf="!row.ticketImage">
                <div class="value" class="missing-required">&nbsp;</div>
                <div class="label" translate>No Ticket</div>
              </div>
              <div class="ticket-photo" *ngIf="row.ticketImage">
                <span><i class="icon-ticket"></i></span>
              </div>
            </a>
          </div>
          <div class="driver" *ngSwitchCase="'driver'">
            <div class="value">{{ row.driver }}</div>
            <div class="label">
              <a
                class="replay-link"
                target="_blank"
                href="/replay?date={{
                  row.jobDate | moment : 'YYYYMMDD'
                }}&driver={{ row.driverId }}"
                >View Replay</a
              >
            </div>
          </div>
          <div class="value" *ngSwitchCase="'truck'">{{ row.truckName }}</div>
          <div class="value" *ngSwitchCase="'customer'">{{ row.customer }}</div>
          <div class="invoice" *ngSwitchCase="'invoice-rate'">
            <div
              class="value"
              [ngClass]="{ 'missing-required': row.invoiceRateUnit === null }"
            >
              {{
                (row.invoiceRate || 0
                  | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;'
              }}
            </div>
            <div class="label">
              {{ row.invoiceRateUnit || '&nbsp;' | titleCase }}
            </div>
          </div>
          <div class="invoice-total" *ngSwitchCase="'invoice-total'">
            <div
              class="value"
              [ngClass]="{ 'missing-required': row.invoiceTotal === null }"
            >
              {{
                (row.invoiceTotal || 0 | currency : 'USD' : 'symbol') ||
                  '&nbsp;'
              }}
            </div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="haul" *ngSwitchCase="'haul-rate'">
            <div
              class="value"
              [ngClass]="{ 'missing-required': row.haulRateUnit === null }"
            >
              {{
                (row.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') ||
                  '&nbsp;'
              }}
            </div>
            <div class="label">
              {{ row.haulRateUnit || '&nbsp;' | titleCase }}
            </div>
          </div>
          <div class="haul-total" *ngSwitchCase="'haul-total'">
            <div class="value">
              {{
                (row.haulTotal || 0 | currency : 'USD' : 'symbol') || '&nbsp;'
              }}
            </div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="invoice-number" *ngSwitchCase="'invoice'">
            <div class="value">{{ row.invoiceTitle || '' }}</div>
          </div>
          <div class="invoice-number" *ngSwitchCase="'expense'">
            <div class="value">{{ row.expenseTitle || '' }}</div>
          </div>
          <div class="invoice-number" *ngSwitchCase="'paystub'">
            <div class="value">{{ row.paystubTitle || '' }}</div>
          </div>
          <div class="invoice-number" *ngSwitchCase="'billing-id'">
            <div class="value">{{ row.billingId || '&nbsp;' }}</div>
          </div>

          <div class="value" *ngSwitchDefault>{{ row.value }}</div>
        </ng-container>
      </ng-template>

      <div header-content>
        <div class="title" translate>Punch Cards</div>
      </div>

      <div applied-filter-content>
        <applied-filters
          [(filters)]="appliedFilters"
          [(search)]="search"
        ></applied-filters>
      </div>
    </fancy-table>

    <div class="actions">
      <column-toggle
        #columnToggle
        [(availableColumns)]="availableColumns"
        [(displayedColumns)]="displayedColumns"
      >
      </column-toggle>
      <button
        class="btn btn-default duplicate-check"
        (click)="duplicateCheck()"
        [ngClass]="{ active: checkingDuplicates }"
        translate
      >
        Duplicate Check
      </button>
      <filters-panel
        [(appliedFilters)]="appliedFilters"
        [customFilter]="true"
        (openCustomFilter)="openFilters()"
        [search]="search"
        (searchChange)="search = $event"
      ></filters-panel>
      <dropdown
        title="Actions"
        [options]="multipleActionDropdownOptions"
        [changeTitle]="false"
        (onSelect)="setSelectedBulkAction($event)"
        class="actions-dropdown"
        [disabled]="loading"
      ></dropdown>
      <div class="add-new-button">
        <button class="btn btn-primary" (click)="openAddPunchCard()">
          <span class="icon-plus"></span> {{ 'Create Punch Card' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
