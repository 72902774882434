<div class="app-content" [ngClass]="{'mobile': device.mobile}">
  <my-toolbar title="{{ 'Jobs' | translate }}"></my-toolbar>

  <action-bar *ngIf="!device.mobile">
    <div [style.width.%]="100">
      <jobs-header></jobs-header>
    </div>
    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()"
              [ngClass]="{active: filters.length}"><i class="icon-filter"></i>{{ 'Filters' | translate }}</button>
    </div>
    <div class="right-actions">
      <button [routerLink]="['/jobs/new']"
              [queryParams]="{returnTo: '/jobs/pending'}"
              class="btn btn-primary" *ngIf="canCreateJobs">
        <span class="icon-plus"></span> {{ 'New Job' | translate }}
      </button>
    </div>
  </action-bar>
  <div class="filters" *ngIf="filters.length">
    {{ invitations.length }} {{ 'Results' | translate }}
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters">
      {{ filter.key | titleCase }}: {{ filter.value.name }}
      <i class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <div class="list-table">
    <div class="header">
      <div class="head rate sort" (click)="sort('invoice_rate')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'invoice_rate'}" translate>
        Rate
      </div>
      <div class="head sent-by sort" (click)="sort('shared_by__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'shared_by__name'}" translate>
        Sent By
      </div>
      <div class="head schedule sort" (click)="sort('jobevent__shift1_start')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__shift1_start'}" translate>
        Schedule
      </div>
      <div class="head job sort" (click)="sort('jobevent__job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__job__name'}" translate>
        Job
      </div>
      <div class="head number-trucks" translate>
        Truck Slots
      </div>
      <div class="head locations" translate>
        Locations
      </div>
    </div>
    <div class="table-body"  (scroll)="onScroll($event)">
      <div class="no-results" *ngIf="!loading && invitations.length === 0" fxLayout="column" fxLayoutAlign="start center">
        <div *ngIf="!search">
          <div class="icon"><i class="icon-project"></i></div>
          <h2 translate>No Job Invitations</h2>
          <p translate>When you are sent a job invitation you’ll see it here.</p>
        </div>
        <div *ngIf="search">
          <h2 translate>No Results Found</h2>
          <div class="actions">
            <button class="btn btn-primary" [ngClass]="{loading: loading}" (click)="expandSearch()" translate>Clear Filters</button>
          </div>
        </div>
      </div>

      <div *ngFor="let invitation of invitations" (click)="selectInvitation($event, invitation)" class="row">
        <div class="rate">
          <div class="value currency">{{ (invitation.invoiceRate | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }} / {{ invitation.invoiceRateUnit || '&nbsp;' }}</div>
          <div class="label" translate>Invoice Rate</div>
        </div>
        <div class="sent-by">
          <div class="value">{{ invitation.sharedBy || '&nbsp;' }}</div>
          <div class="label" translate>Sent By</div>
        </div>
        <div class="schedule">
          <div class="value">{{ invitation.name || '&nbsp;' }}</div>
          <div class="label" translate>Schedule</div>
        </div>
        <div class="job">
          <div class="value">{{ invitation.job || '&nbsp;' }}</div>
          <div class="label" translate>Job</div>
        </div>
        <div class="number-trucks">
          <div class="value">{{ invitation.numTrucks || '&nbsp;' }}</div>
          <div class="label" translate>Truck Slots</div>
        </div>
        <div class="locations">
          <div class="field-group start-location" fxFlex="100">
            <div class="value" title="{{ invitation.startLocation && invitation.startLocation.name }}">
              {{ invitation.startLocation && invitation.startLocation.name }}
            </div>
          </div>
          <div class="end-location" fxFlex="100">
            <div class="value" title="{{ invitation.endLocation && invitation.endLocation.name }}">
              {{ invitation.endLocation && invitation.endLocation.name }}
            </div>
          </div>
        </div>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading" [ngClass]="{'full-height': invitations.length === 0}"></my-loader>
    </div>
  </div>
</div>
