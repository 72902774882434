"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ticket_filter_1 = require("./ticket.filter");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TicketFilterSerializer = /** @class */ (function () {
    function TicketFilterSerializer() {
    }
    TicketFilterSerializer.prototype.fromJson = function (ticketFilterInfo) {
        ticketFilterInfo = camelcaseKeysDeep(ticketFilterInfo);
        var ticketFilter = new ticket_filter_1.TicketFilter();
        if (!ticketFilterInfo) {
            return ticketFilter;
        }
        ticketFilter.id = ticketFilterInfo.id;
        ticketFilter.name = ticketFilterInfo.name;
        return ticketFilter;
    };
    TicketFilterSerializer.prototype.toJson = function (ticketFilter) {
        var json = {
            id: ticketFilter.id,
            name: ticketFilter.name
        };
        return decamelizeKeysDeep(json);
    };
    return TicketFilterSerializer;
}());
exports.TicketFilterSerializer = TicketFilterSerializer;
