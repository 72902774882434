"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CollaborationTemplate = /** @class */ (function () {
    function CollaborationTemplate(data) {
        this.jobEventsLoading = data.jobEventsLoading;
        this.jobEventOptions = data.jobEventOptions;
        this.jobevents = data.jobevents;
        this.numTrucks = data.numTrucks || 1;
        this.invoiceWeightUnit = data.invoiceWeightUnit;
        this.invoiceType = data.invoiceType;
        this.invoiceRate = data.invoiceRate;
        this.notes = data.notes;
        this.cancelMissing = data.cancelMissing;
        this.requestedAmount = data.requestedAmount;
        this.requestedUnit = data.requestedUnit;
    }
    return CollaborationTemplate;
}());
exports.CollaborationTemplate = CollaborationTemplate;
