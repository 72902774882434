"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var index_1 = require("../shared/index");
var customer_1 = require("./customer");
var environment_1 = require("../../environments/environment");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CustomerService = /** @class */ (function () {
    function CustomerService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    CustomerService.prototype.getCustomer = function (customerId) {
        var customerUrl = this.baseUrl + 'customers/' + customerId + '/';
        return this.http.get(customerUrl).pipe(operators_1.map(this.extractData), operators_1.catchError(index_1.handleError));
    };
    CustomerService.prototype.getCustomers = function (query) {
        if (query === void 0) { query = null; }
        var customersUrl = this.baseUrl + 'customers/';
        return this.http.get(customersUrl, { headers: index_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(index_1.handleError));
    };
    CustomerService.prototype.save = function (customer) {
        customer = decamelizeKeysDeep(customer);
        var customerUrl = this.baseUrl + 'connections/';
        if (!customer.id) {
            return this.http.post(customerUrl, { organization: customer }, { headers: index_1.requestHeaders() });
        }
        else {
            return this.http.put(customerUrl + customer.id + '/', { organization: customer }, { headers: index_1.requestHeaders() });
        }
    };
    CustomerService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
        }
        else {
            return null;
        }
    };
    CustomerService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (customer) {
                return new customer_1.Customer(customer);
            });
        }
        else if (resObj) {
            return new customer_1.Customer(resObj);
        }
    };
    return CustomerService;
}());
exports.CustomerService = CustomerService;
