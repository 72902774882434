'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var index_1 = require("../index");
var environment_1 = require("../../../environments/environment");
var user_service_1 = require("../../users/user.service");
var AlertPanelComponent = /** @class */ (function () {
    function AlertPanelComponent(http, authenticationService, userService, compiler) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.compiler = compiler;
        this.alert = 'Update Available';
        this.updateNoticeHidden = false;
        this.updateAvailable = false;
        this.applicationVersion = environment_1.environment.applicationVersion;
        this.allSubscriptionsToUnsubscribe = [];
    }
    AlertPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.revisionCheckTimer = rxjs_1.timer(1, 120000);
        this.revisionCheckTimerSub = this.revisionCheckTimer.subscribe(function (t) {
            _this.getRevision();
        });
        this.allSubscriptionsToUnsubscribe.push(this.revisionCheckTimerSub);
    };
    AlertPanelComponent.prototype.ngOnDestroy = function () {
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    AlertPanelComponent.prototype.compareVersions = function () {
        if (this.applicationVersion && this.releasedVersion &&
            this.applicationVersion.toLowerCase().trim() !== this.releasedVersion.toLowerCase().trim()) {
            this.updateAvailable = true;
        }
        else {
            this.updateAvailable = false;
        }
    };
    AlertPanelComponent.prototype.getRevision = function () {
        var _this = this;
        var expire = Date.now();
        this.http.get('REVISION?expire=' + expire, {
            responseType: 'text'
        }).subscribe(function (response) {
            _this.releasedVersion = response.toString();
            _this.compareVersions();
        }, function (err) {
            _this.updateNoticeHidden = true;
        });
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
        var currentUser = this.authenticationService.user();
        if (currentUser && currentUser.id) {
            this.userReq = this.userService.get(currentUser.id).subscribe(function (user) {
                _this.authenticationService.storeUser(Object.assign(currentUser, {
                    features: user.features,
                    enabledFeatures: user.enabledFeatures,
                    organization: user.organization
                }));
            });
        }
    };
    AlertPanelComponent.prototype.reload = function () {
        this.compiler.clearCache();
        window.location.reload(true);
    };
    return AlertPanelComponent;
}());
exports.AlertPanelComponent = AlertPanelComponent;
