import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsToPrettyTime' })
export class SecondsToPrettyTime implements PipeTransform {
  transform(seconds: number): string {
    if (!seconds) {
      return '';
    } else if (seconds < 1) {
      return '0';
    } else {
      const d = Number(seconds);
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);

      const hDisplay = h > 0 ? h + 'h ' : '';
      const mDisplay = m > 0 ? m + 'm ' : '';
      const sDisplay = s > 0 ? s + 's ' : '';
      return hDisplay + mDisplay + sDisplay;
    }
  }
}
