import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsReport, TimeFrame } from '../services/notifications-report';
import { ChartData, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'chart-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss']
})
export class ChartDialogComponent implements OnInit {
  reportData: NotificationsReport;
  currentTimeFrame: TimeFrame = {
    name: 'Seven Days',
    id: 'seven-days',
    lastPeriodColumnName: 'Last 7 day period',
    currentPeriodColumnName: 'Current 7 day period'
  };
  barChartData: ChartData;
  barChartType: ChartType = 'bar';
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Notifications'
          },
          ticks: { beginAtZero: true }
        }
      ]
    },
    legend: {
      position: 'bottom'
    }
  };

  constructor(public dialogRef: MatDialogRef<ChartDialogComponent>) { }

  ngOnInit() {
    if (this.reportData) {
      this.barChartData = {
        labels: [ this.reportData.name ],
        datasets: [
          {
            data: [ this.reportData.lastPeriod ],
            label: this.currentTimeFrame.lastPeriodColumnName,
            backgroundColor: 'rgba(174, 214, 241, 0.8)',
            hoverBackgroundColor: '#AED6F1'
          },
          {
            data: [ this.reportData.currentPeriod ],
            label: this.currentTimeFrame.currentPeriodColumnName,
            backgroundColor: 'rgba(255, 218, 185, 0.8)',
            hoverBackgroundColor: '#FFDAB9'
          }
        ]
      };
    }
  }
}
