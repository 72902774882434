"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./project-list.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("@angular/flex-layout/flex");
var i6 = require("../directives/loader/loader.component.ngfactory");
var i7 = require("../directives/loader/loader.component");
var i8 = require("../shared/toolbar/toolbar.component.ngfactory");
var i9 = require("../shared/toolbar/toolbar.component");
var i10 = require("@angular/router");
var i11 = require("../shared/authentication.service");
var i12 = require("../tags/tag.service");
var i13 = require("../users/user.service");
var i14 = require("../shared/action-bar/action-bar.component.ngfactory");
var i15 = require("../shared/action-bar/action-bar.component");
var i16 = require("../shared/search/search.component.ngfactory");
var i17 = require("../shared/search/search.component");
var i18 = require("./project-list.component");
var i19 = require("../projects/project.service");
var styles_ProjectListComponent = [i0.styles];
var RenderType_ProjectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectListComponent, data: {} });
exports.RenderType_ProjectListComponent = RenderType_ProjectListComponent;
function View_ProjectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-projects"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Projects"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["When you add a project you\u2019ll see it here."])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddProject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Project"]))], null, null); }
function View_ProjectListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Results Found"])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expandSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { loading: 0 }), i1.ɵdid(8, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(9, { loading: 0 }), (_l()(), i1.ɵted(-1, null, ["Clear Filters"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-primary"; var currVal_1 = _ck(_v, 7, 0, _co.loading); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 9, 0, _co.loading); var currVal_3 = "btn btn-primary"; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
function View_ProjectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "no-results"], ["fxLayout", "column"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.search; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.search; _ck(_v, 6, 0, currVal_3); }, null); }
function View_ProjectListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddJob(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Job"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "customer-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.customerOrganization.name; _ck(_v, 8, 0, currVal_1); }); }
function View_ProjectListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "my-loader", [], null, null, null, i6.View_LoaderComponent_0, i6.RenderType_LoaderComponent)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "full-height": 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "full-height": 0 }), i1.ɵdid(6, 180224, null, 0, i7.LoaderComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.trucks && (_co.trucks.length === 0))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, (_co.trucks && (_co.trucks.length === 0))); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProjectListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "my-toolbar", [["title", "Projects"]], null, null, null, i8.View_ToolbarComponent_0, i8.RenderType_ToolbarComponent)), i1.ɵdid(2, 245760, null, 0, i9.ToolbarComponent, [i10.Router, i11.AuthenticationService, i12.TagService, i13.UserService], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "action-bar", [], null, null, null, i14.View_ActionBarComponent_0, i14.RenderType_ActionBarComponent)), i1.ɵdid(4, 49152, null, 0, i15.ActionBarComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "div", [["class", "left-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, 0, 6, "div", [["class", "right-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "search", [], null, [[null, "searchTermChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTermChange" === en)) {
        var pd_0 = (_co.changeSearch($event) !== false);
        ad = (pd_0 && ad);
    } if (("searchTermChange" === en)) {
        var pd_1 = ((_co.search = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i16.View_SearchComponent_0, i16.RenderType_SearchComponent)), i1.ɵdid(9, 4440064, null, 0, i17.SearchComponent, [], { searchTerm: [0, "searchTerm"] }, { searchTermChange: "searchTermChange" }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAddProject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Project"])), (_l()(), i1.ɵeld(13, 0, null, null, 12, "div", [["class", "list-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "head name sort"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("name") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Name "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "head customer-name sort"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sort("customer_organization__name") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Customer "])), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "table-body"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_1)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_4)), i1.ɵdid(23, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_5)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Projects"; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.search; _ck(_v, 9, 0, currVal_2); var currVal_3 = (!_co.loading && (_co.projects.length === 0)); _ck(_v, 21, 0, currVal_3); var currVal_4 = _co.projects; _ck(_v, 23, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 25, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "search-container ", (_co.loading ? "loading" : ""), ""); _ck(_v, 7, 0, currVal_1); }); }
exports.View_ProjectListComponent_0 = View_ProjectListComponent_0;
function View_ProjectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ruckit-project-list", [], null, null, null, View_ProjectListComponent_0, RenderType_ProjectListComponent)), i1.ɵdid(1, 114688, null, 0, i18.ProjectListComponent, [i10.ActivatedRoute, i10.Router, i19.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectListComponent_Host_0 = View_ProjectListComponent_Host_0;
var ProjectListComponentNgFactory = i1.ɵccf("ruckit-project-list", i18.ProjectListComponent, View_ProjectListComponent_Host_0, {}, {}, []);
exports.ProjectListComponentNgFactory = ProjectListComponentNgFactory;
