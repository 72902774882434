import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone } from 'lodash';

import { requestHeaders, handleError } from '../shared/api.service';
import { Checkin } from './checkin';
import { environment } from '../../environments/environment';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class CheckinService {
  baseUrl = environment.serverUrl;
  public nextUri;
  progress$;
  progress;
  public count = 0;

  constructor(private http: HttpClient) { }

  getCheckin(checkinId: string) {
    let checkinUrl = this.baseUrl + 'checkins/' + checkinId + '/';

    return this.http.get(checkinUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getCheckins(query: any = null): Observable<Checkin[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let checkinsUrl = this.baseUrl + 'checkins/';
    return this.http.get(checkinsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  save(model) {
    const uri = this.baseUrl + 'checkins/';
    let _model = decamelizeKeysDeep(clone(model));
    delete _model.signature_image;
    delete _model.ticket_image;
    delete _model.load_image;

    if ( ! _model.id ) {
      return this.http.post(uri, _model, {headers: requestHeaders()}).pipe(
        map(res => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return this.http.put(uri + _model.id + '/', _model, {headers: requestHeaders()}).pipe(
        map(res => { return this.extractData(res); }),
        catchError(handleError)
      );
    }
  }

  requestRetake(checkinId) {
    let checkinUrl = this.baseUrl + 'checkins/' + checkinId + '/retake/';

    return this.http.get(checkinUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];
    if (body) {
      return body.map(checkin => {
        return new Checkin(checkin);
      });
    } else if (resObj) {
      return new Checkin(resObj);
    }
  }

  getNext() {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()});
    } else {
      return null;
    }
  }

  public uploadImage(method: string, checkinId: string[], propertyName: string, files: File[]): Observable<any> {
    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (let i = 0; i < files.length; i++) {
        formData.append(propertyName, files[i], files[i].name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };
      const url = this.baseUrl + 'checkins/' + checkinId + '/';

      xhr.open(method, url, true);
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }
}
