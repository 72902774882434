"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var ItemGridComponent = /** @class */ (function () {
    function ItemGridComponent() {
        this.displayKeys = [];
        this.groupByOptions = [];
        this.groupByChange = new core_1.EventEmitter();
        this.searchTerm = '';
        this.searchProps = ['name'];
        this.multiselect = true;
        this.onSelect = new core_1.EventEmitter();
        this.selectedItemsValue = [];
        this.showDriverContextMenu = false;
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.selectedItemsChange = new core_1.EventEmitter();
    }
    Object.defineProperty(ItemGridComponent.prototype, "selectedItems", {
        get: function () { return this.selectedItemsValue; },
        set: function (data) {
            this.selectedItemsValue = data;
            this.selectedItemsChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    ItemGridComponent.prototype.handleKeyboardEvent = function (event) {
        var _this = this;
        if (event.keyCode === 13) {
            var unselectedVisibleItems = [].concat.apply([], this.displayedItemGroups.map(function (list) { return (list.items); })).filter(function (item) { return (_this.selectedItems.findIndex(function (id) { return (id === item.id); }) === -1); });
            if (unselectedVisibleItems.length === 1) {
                this.selectedItems.push(unselectedVisibleItems[0].id);
            }
        }
    };
    /**
     * On component init we pre-select the first display key from the input list
     */
    ItemGridComponent.prototype.ngOnInit = function () {
        this.activeDisplayKey = this.displayKeys[0];
    };
    /**
     * Applies the current search term to any changes in the item array
     *
     * @param {SimpleChanges} changes The change event object
     */
    ItemGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes.itemGroups && changes.itemGroups.currentValue) {
            this.displayedItemGroups = lodash_1.cloneDeep(this.itemGroups);
            this.changeSearch(this.searchTerm);
        }
    };
    /**
     * Checks if an id exists in the selection array
     *
     * @param {string} id The item id
     * @returns {boolean} Returns the boolean for if the item is selected or not
     */
    ItemGridComponent.prototype.isSelected = function (id) {
        return !!this.selectedItems.find(function (x) { return (x === id); });
    };
    /**
     * Either filters out an existing selection or adds that to the selection array
     *
     * @param {string} id The item id
     */
    ItemGridComponent.prototype.selectItem = function (id) {
        if (this.isSelected(id)) {
            this.selectedItems = this.selectedItems.filter(function (x) { return (x !== id); });
        }
        else if (this.multiselect) {
            this.selectedItems.push(id);
        }
        else {
            this.selectedItems = [id];
        }
        if (this.selectedItems.length) {
            this.onSelect.emit(this.selectedItems);
        }
    };
    /**
     * Emits the inteded groupBy selection to the parent component and sets it active
     *
     * @param {string} groupBy The selected groupBy
     */
    ItemGridComponent.prototype.selectGroupBy = function (groupBy) {
        this.groupByChange.emit(groupBy);
        this.activeGroupBy = groupBy;
    };
    /**
     * Uses search text to filter down the displayed items
     *
     * @param {string} e The search text
     */
    ItemGridComponent.prototype.changeSearch = function (e) {
        var _this = this;
        if (e && e.length) {
            this.searchTerm = e.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            e = e.toUpperCase();
            this.displayedItemGroups.forEach(function (group, i) {
                group.items = _this.itemGroups[i].items.filter(function (item) {
                    var match = false;
                    _this.searchProps.forEach(function (prop) {
                        if (((item[prop] && item[prop].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(e)) ||
                            (item.item && item.item[prop] && item.item[prop].toUpperCase().includes(e))) &&
                            _this.selectedItems.findIndex(function (id) { return (id === item.id); }) === -1) {
                            match = true;
                        }
                    });
                    return match;
                }).concat(_this.itemGroups[i].items.filter(function (d) { return (_this.selectedItems.findIndex(function (id) { return (id === d.id); }) > -1); }));
            });
        }
        else {
            this.displayedItemGroups = lodash_1.cloneDeep(this.itemGroups);
        }
    };
    /**
     * Toggle the next active displayed key
     */
    ItemGridComponent.prototype.toggleDisplayedNames = function () {
        var _this = this;
        var keyIndex = this.displayKeys.findIndex(function (k) { return (k === _this.activeDisplayKey); });
        this.activeDisplayKey = this.displayKeys[(keyIndex + 1) === this.displayKeys.length ? 0 : keyIndex + 1];
    };
    ItemGridComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    return ItemGridComponent;
}());
exports.ItemGridComponent = ItemGridComponent;
