const camelcaseKeysDeep = require('camelcase-keys-deep');

export class ExpenseFilter {
  id: string;
  name: string;

  constructor(expenseFilterInfo: any) {
    expenseFilterInfo = camelcaseKeysDeep(expenseFilterInfo);

    this.id = expenseFilterInfo.id;
    this.name = expenseFilterInfo.name;
  }
}
