"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var resizer_1 = require("../images/resizer");
// constants
var app_constants_1 = require("../app.constants");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Checkin = /** @class */ (function () {
    function Checkin(checkinInfo) {
        checkinInfo = camelcaseKeysDeep(checkinInfo);
        this.id = checkinInfo.id;
        this.index = checkinInfo.index;
        this.trip = checkinInfo.trip;
        this.kind = checkinInfo.kind;
        this.weight = checkinInfo.weight;
        this.qr = checkinInfo.qr;
        this.invoiceWeightUnit = checkinInfo.invoiceWeightUnit;
        this.haulWeightUnit = checkinInfo.haulWeightUnit;
        this.weightWithLabel = checkinInfo.weightWithLabel;
        this.location = checkinInfo.location;
        this.signatureImage = checkinInfo.signatureImage;
        this.signatureImageKey = checkinInfo.signatureImageKey;
        this.ticketImage = checkinInfo.ticketImage;
        this.ticketImageKey = checkinInfo.ticketImageKey;
        this.loadImage = checkinInfo.loadImage;
        this.ticketNumber = checkinInfo.ticketNumber;
        this.options = checkinInfo.options;
        this.attachments = checkinInfo.attachments;
        // for older trips, before there were attachments
        if (this.attachments && this.attachments.length === 0) {
            if (this.signatureImage) {
                this.attachments.unshift({
                    file: checkinInfo.signatureImage,
                    src: checkinInfo.signatureImage,
                    fileType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
                    checkin: this.id,
                    legacy: true,
                });
            }
            if (this.ticketImage) {
                this.attachments.unshift({
                    file: checkinInfo.ticketImage,
                    src: checkinInfo.ticketImage,
                    fileType: app_constants_1.TRIPTICKETIMAGETYPE,
                    checkin: this.id,
                    legacy: true,
                });
            }
        }
        if (checkinInfo.date) {
            this.date = new Date(checkinInfo.date);
            this.checkinTime = moment(this.date).format('h:mm a');
        }
    }
    Object.defineProperty(Checkin.prototype, "time", {
        get: function () {
            var d = new Date(this.date);
            return moment(d).format('h:mm a');
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i)
                ? 'YYYY-MM-DD h:mm a'
                : 'YYYY-MM-DD h:mm';
            var d = moment(this.date).format('YYYY-MM-DD');
            this.date = moment(d + ' ' + value, pattern).toDate();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Checkin.prototype, "editTripTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.ticketImageKey, 360, 481);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Checkin.prototype, "invoicedTripTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.ticketImageKey, 28, 28);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Checkin.prototype, "fullsizeTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getFullsizeUrl(this.ticketImageKey);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Checkin.prototype, "uploadDialogTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.ticketImageKey, 300, 400);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Checkin.prototype, "uploadDialogSignatureImage", {
        get: function () {
            if (this.signatureImage && this.signatureImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.signatureImageKey, 300, 100);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Checkin.prototype, "printableTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.ticketImageKey, 400, 300);
            }
        },
        enumerable: true,
        configurable: true
    });
    return Checkin;
}());
exports.Checkin = Checkin;
