import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { AvailableDriversComponent } from '../drivers/available-drivers/available-drivers.component';
import { DispatchComponent } from './dispatch.component';
import { DispatchScheduleComponent } from './schedule/dispatch-schedule.component';
import { DispatchScheduleTableComponent } from './schedule/schedule-table/dispatch-schedule-table.component';
import { DispatchScheduleTruckGridComponent } from './schedule/truck-grid/dispatch-schedule-truck-grid.component';
import { DispatchScheduleJobEventGridComponent } from './schedule/jobevent-grid/dispatch-schedule-jobevent-grid.component';
import { DispatchScheduleCollaboratorGridComponent } from './schedule/collaborator-grid/dispatch-schedule-collaborator-grid.component';
import { DispatchScheduleJobEventEditPanelComponent } from './schedule/jobevent-edit-panel/dispatch-schedule-jobevent-edit-panel.component';
import { DispatchScheduleFiltersDialogComponent } from './schedule/filters-dialog/dispatch-schedule-filters-dialog.component';
import { MobileDispatchCarriersComponent } from './mobile-dispatch-carriers.component';
import { MobileDispatchDriversComponent } from './mobile-dispatch-drivers.component';
import { MobileDispatchEditAssignmentComponent } from './mobile-dispatch-edit-assignment.component';
import { MobileDispatchShiftsComponent } from './mobile-dispatch-shifts.component';
import { MobileDispatchTrucksComponent } from './mobile-dispatch-trucks.component';
import { DispatchByDateComponent } from './dispatch-by-date/dispatch-by-date.component';
import { DispatchByJobComponent } from './dispatch-by-job/dispatch-by-job.component';
import { MobileDispatchComponent } from './mobile/mobile-dispatch.component';
import { MobileDispatchAssignComponent } from './mobile/mobile-dispatch-assign.component';
import { MobileDispatchAssignmentsComponent } from './mobile/mobile-dispatch-assignments.component';
import { MobileDispatchJobsComponent } from './mobile/mobile-dispatch-jobs.component';
import { ConfirmDriversDialogComponent } from './confirm-drivers-dialog.component';
import { QuickDispatchDialogComponent } from './quick-dispatch-dialog.component';
import { CarrierDispatchDialogComponent } from './carrier-dispatch/carrier-dispatch-dialog.component';
import { DispatchService } from './dispatch.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    DispatchComponent,
    AvailableDriversComponent,
    MobileDispatchCarriersComponent,
    MobileDispatchDriversComponent,
    MobileDispatchEditAssignmentComponent,
    MobileDispatchShiftsComponent,
    MobileDispatchTrucksComponent,
    DispatchByDateComponent,
    DispatchByJobComponent,
    MobileDispatchComponent,
    MobileDispatchAssignComponent,
    MobileDispatchAssignmentsComponent,
    MobileDispatchJobsComponent,
    DispatchScheduleComponent,
    DispatchScheduleTableComponent,
    DispatchScheduleTruckGridComponent,
    DispatchScheduleJobEventGridComponent,
    DispatchScheduleCollaboratorGridComponent,
    DispatchScheduleJobEventEditPanelComponent,
    DispatchScheduleFiltersDialogComponent,
    ConfirmDriversDialogComponent,
    QuickDispatchDialogComponent,
    CarrierDispatchDialogComponent
  ],
  entryComponents: [
    ConfirmDriversDialogComponent,
    QuickDispatchDialogComponent,
    CarrierDispatchDialogComponent,
    DispatchScheduleFiltersDialogComponent
  ],
  exports: [
    DispatchComponent,
    MobileDispatchCarriersComponent,
    MobileDispatchDriversComponent,
    MobileDispatchEditAssignmentComponent,
    MobileDispatchShiftsComponent,
    MobileDispatchTrucksComponent,
    DispatchByDateComponent,
    DispatchByJobComponent,
    MobileDispatchComponent,
    MobileDispatchAssignComponent,
    MobileDispatchAssignmentsComponent,
    MobileDispatchJobsComponent,
    DispatchScheduleComponent,
    DispatchScheduleTableComponent,
    DispatchScheduleTruckGridComponent,
    DispatchScheduleJobEventGridComponent,
    DispatchScheduleCollaboratorGridComponent,
    DispatchScheduleJobEventEditPanelComponent,
    DispatchScheduleFiltersDialogComponent,
    ConfirmDriversDialogComponent,
    QuickDispatchDialogComponent,
    CarrierDispatchDialogComponent
  ],
  providers: [DispatchService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class DispatchModule {}
