  <div *ngFor="let trip of trips; let i = index" class="trip-row">
  <div class="date">
    <div class="value">{{ trip.date | date:'mediumDate' || '&nbsp;' }}</div>
    <div class="trip-status">
      <div class="label-{{trip.administrativeStatus}}" *ngIf="trip.displayableStatus">{{trip.administrativeStatus}}</div>
      <div class="spacer" *ngIf="!trip.displayableStatus">&nbsp;</div>
    </div>
  </div>
  <div class="driver">
    <div class="value">{{ trip.driver || '&nbsp;' }}</div>
    <div class="label" translate>Driver</div>
  </div>
  <div class="truck">
    <div class="value">{{ trip.truckName || '&nbsp;' }}</div>
    <div class="label" translate>Truck</div>
  </div>
  <div class="payable-to">
    <div class="value">{{ trip.payableTo || '&nbsp;' }}</div>
    <div class="label">{{ (trip.payableToPhone | telephone) || '&nbsp;' }}</div>
  </div>
  <div class="load-ticket-number">
    <div class="value" [ngClass]="{'missing-required': trip.checkinOptions.ticketNumber && !trip.loadingTicketNumber}">{{ trip.loadingTicketNumber || '&nbsp;' }}</div>
    <div class="label">&nbsp;</div>
  </div>
  <div class="load-ticket-weight">
    <div class="value" [ngClass]="{'missing-required': trip.checkinOptions.weight && !trip.loadingWeight}">{{ trip.loadingWeight || '&nbsp;' }}</div>
    <div class="label">{{ trip.haulWeightUnit || '&nbsp;' | titleCase }}</div>
  </div>
  <div class="duration">
    <div class="value">{{ trip.schedule || '&nbsp;' }}</div>
    <div class="label">{{ (trip.endTimeTimestamp ? trip.completedTripTime : formattedDuration(trip.startTimeTimestamp)) || '&nbsp;' }}</div>
  </div>
  <div class="invoice">
    <div class="value">{{ (trip.invoiceRate || 0 | currency : 'USD' : 'symbol' : '1.2-4' ) || '&nbsp;' }}</div>
    <div class="label">{{ trip.invoiceTypeName || '&nbsp;' }}</div>
  </div>
  <div class="haul">
    <div class="value">{{ (trip.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4' ) || '&nbsp;' }}</div>
    <div class="label">{{ trip.haulTypeName || '&nbsp;' }}</div>
  </div>
  <div class="load-ticket-image" (click)="openTickets(trip)">
    <div class="ticket-photo" *ngIf="!trip.hasLoadingTicketImages && trip.loadingTicketImagesRequired">
      <div class="value" class="missing-required">&nbsp;</div>
      <div class="label" translate>No Ticket</div>
    </div>
    <div class="ticket-photo" *ngIf="trip.hasLoadingTicketImages">
      <span><i class="icon-ticket"></i></span>
    </div>
  </div>
  <div class="route">
    <a [href]="trip.fullsizeMapImageURL" target="_blank">
      <img [src]="trip.mapImageURL" *ngIf="trip.mapImageURL" />
    </a>
  </div>
  <!-- <div class="actions">
    <dropdown [config]="{style:'action', pin:'bottom-right'}" [setTitle]="false" icon="icomoon icon-more" (onSelect)="setSelectedOption($event, trip)" [options]="actionDropdownOptions"></dropdown>
  </div> -->
</div>
