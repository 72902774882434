<div class="login-container">
  <div class="login-form" *ngIf="!isShowingForgotPwd">
    <div class="logo-container">
      <img
        class="logo"
        src="../../assets/img/command-alkon-logo.svg"
        alt="Command Alkon"
      />
    </div>
    <h1 translate>Login with your credentials below</h1>

    <notification *ngFor="let error of errors">{{ error }}</notification>

    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-form-field appearance="outline" class="login-mat-input">
        <mat-label translate>Email/Username</mat-label>

        <input 
          matInput 
          required
          type="text" 
          name="email" 
          formControlName="email"
          [attr.aria-label]="'Email/Username' | translate"
        />
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="login-mat-input">
        <mat-label translate>Password</mat-label>

        <input 
          matInput 
          required
          name="password"
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
          [attr.aria-label]="'Password' | translate"
        />
        <mat-icon
          matSuffix
          [attr.aria-label]="'Show password' | translate"
          (click)="showPassword = !showPassword"
          >
          {{ showPassword ? 'visibility' : 'visibility_off' }}
        </mat-icon>
      </mat-form-field>

      <div class="actions-container">
        <div>
          <a class="forgot-password" (click)="forgotPassword()" translate>
            Forgot Password
          </a>
        </div>
      </div>

      <div class="login-button-container">
        <button
          type="submit"
          class="btn btn-primary"
          aria-label="Login"
          [disabled]="!this.loginForm.valid"
          [ngClass]="{ loading: loading }"
          translate
        >
          <mat-icon>login</mat-icon>
          Login
        </button>
      </div>
    </form>

    <div class="legal">
      <a
        href="https://www.commandalkon.com/privacy-policy"
        target="_blank"
        translate
        >Privacy Policy</a
      >
      <span>|</span>
      <a href="/eula" target="_blank" translate>EULA</a>
    </div>
  </div>
</div>
