import { TruckType } from '../trucks/truck-type';
import { TruckTypeSerializer } from '../trucks/truck-type.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Multiplier {
  id: string;
  truckType: TruckType;
  truckTypeName: string;
  createdAt: string;
  lastModified: string;
  haulMultiplier: number;
  connection: string;
  rateType: string;
  remove = false;
  haulPercentage: number;
  selectedTruckType: any;

  constructor(rateInfo: any) {
    rateInfo = camelcaseKeysDeep(rateInfo);

    this.id = rateInfo.id;
    this.truckType = rateInfo.truckType;
    this.rateType = rateInfo.rateType;
    this.haulPercentage = rateInfo.haulPercentage;
    this.truckTypeName = rateInfo.truckTypeName;
    if (typeof rateInfo.truckType === 'object') {
      this.truckType = (new TruckTypeSerializer().fromJson(rateInfo.truckType));
    } else {
      this.truckType = (new TruckTypeSerializer().fromJson({ id: rateInfo.truckType }));
    }
    this.connection = rateInfo.connection;
    this.haulPercentage = rateInfo.haulPercentage;
    this.createdAt = rateInfo.createdAt;
    this.lastModified = rateInfo.lastModified;

    if (this.truckType && this.truckType.id) {
      this.selectedTruckType = { id: this.truckType.id, name: this.truckType.name };
    } else {
      this.selectedTruckType = null;
    }
  }
}
