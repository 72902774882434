"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./timeline-legend.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("@angular/platform-browser");
var i6 = require("./timeline-legend.component");
var styles_TimelineLegendComponent = [i0.styles];
var RenderType_TimelineLegendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineLegendComponent, data: {} });
exports.RenderType_TimelineLegendComponent = RenderType_TimelineLegendComponent;
function View_TimelineLegendComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "icon-", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_TimelineLegendComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "span", [["class", "color"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-color": 0 }), i1.ɵdid(5, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "background-color": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineLegendComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, _v.context.$implicit.color); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, _v.context.$implicit.color); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.icon; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_3); }); }
function View_TimelineLegendComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineLegendComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labels; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_TimelineLegendComponent_0 = View_TimelineLegendComponent_0;
function View_TimelineLegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "timeline-legend", [], null, null, null, View_TimelineLegendComponent_0, RenderType_TimelineLegendComponent)), i1.ɵdid(1, 49152, null, 0, i6.TimelineLegendComponent, [], null, null)], null, null); }
exports.View_TimelineLegendComponent_Host_0 = View_TimelineLegendComponent_Host_0;
var TimelineLegendComponentNgFactory = i1.ɵccf("timeline-legend", i6.TimelineLegendComponent, View_TimelineLegendComponent_Host_0, { labels: "labels" }, {}, ["[title]"]);
exports.TimelineLegendComponentNgFactory = TimelineLegendComponentNgFactory;
