"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var moment = require("moment");
var lodash_1 = require("lodash");
var index_1 = require("../../shared/index");
var upload_service_1 = require("../../shared/upload.service");
var dialogs_1 = require("../../shared/dialogs");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var filters_dialog_component_1 = require("../../shared/filters-dialog/filters-dialog.component");
var api_service_1 = require("../../shared/api.service");
var pay_sheet_service_1 = require("../pay-sheet.service");
var generate_report_dialog_component_1 = require("./generate-report-dialog/generate-report-dialog.component");
var bulk_edit_dialog_component_1 = require("./bulk-edit-dialog/bulk-edit-dialog.component");
var driver_pay_timeline_component_1 = require("./timeline/driver-pay-timeline.component");
var preference_service_1 = require("../../preferences/preference.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var tag_service_1 = require("../../tags/tag.service");
var pay_sheet_filter_service_1 = require("../pay-sheet.filter.service");
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var DriverPayComponent = /** @class */ (function () {
    function DriverPayComponent(dialog, route, router, authenticationService, preferenceService, paySheetService, jobEventService, uploadService) {
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.paySheetService = paySheetService;
        this.jobEventService = jobEventService;
        this.uploadService = uploadService;
        this.reportActive = false;
        this.readOnly = false;
        this.receivedReport = false;
        this.editing = false;
        this.saving = false;
        this.processing = true;
        this.processingText = '';
        this.empty = false;
        this.errors = [];
        this.actionDropdownOptions = [
            { name: 'New Pay Basis', button: true },
            { name: 'Bulk Edit', button: true },
            { name: 'Reset Edits', button: true },
            { name: 'Reset Report', button: true }
        ];
        this.displayAsGrid = true;
        this.sortBy = 'assignment__driver__profile__last_name';
        this.sortDirection = 'desc';
        this.appliedFilters = [];
        this.timelineData = [];
        this.payRecords = [];
        this.allSelected = true;
        this.timeIntervalConfig = { nameProperty: 'title' };
        this.timeIntervalOptions = [
            { title: '1 Hour', amount: 60 },
            { title: 'Half Hour', amount: 30 },
            { title: 'Quarter Hour', amount: 15 }
        ];
        this.preferenceKey = 'DriverPayUserPreferences';
        this.allowNavigation = false;
        this.page = 1;
        this.pageSize = 10;
        this.allSubscriptionsToUnsubscribe = [];
    }
    DriverPayComponent.prototype.filtersActive = function (filters) {
        return !!(filters && Object.keys(filters).length);
    };
    DriverPayComponent.prototype.selectAll = function (value) {
        this.allSelected = value;
    };
    /**
     * Triggers a set for the activeSort and triggers a data get with the new sort props
     *
     * @param  {{ active: string, direction: 'asc' | 'desc' | '' }} event The matSort event properties
     */
    DriverPayComponent.prototype.sort = function (event) {
        this.sortDirection = event.direction;
        switch (event.active) {
            case 'driver':
                this.sortBy = 'assignment__driver__profile__last_name';
                break;
            case 'carrier':
                this.sortBy = 'assignment__driver__carrier__name';
                break;
            case 'truckNumber':
                this.sortBy = 'assignment__truck__name';
                break;
            case 'orderNumber':
                this.sortBy = 'assignment__jobevent__job__order_number';
                break;
        }
        this.loadPayRecords(this.paySheet);
    };
    /**
     * Uses both the report type and the report view to generate a specific header title
     *
     * @return {string} The header title
     */
    DriverPayComponent.prototype.headerTitle = function () {
        return (this.reportType ? (this.reportType.charAt(0).toUpperCase() + this.reportType.slice(1) + ' ') : 'All ') + 'Drivers Pay ' + (this.view === 'prereport' ? 'Prereport' : 'Report');
    };
    /**
     * Generates a specific returnTo url based on both the 'recievedReport' flag and the reportType
     *
     * @return {string} The generated returnTo url
     */
    DriverPayComponent.prototype.headerUrl = function () {
        return '/pay-reports/' + (this.receivedReport ? 'received' : 'all') + (this.reportType ? '?reportType=' + this.reportType : '');
    };
    DriverPayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authenticationService.user();
        this.getPreferences();
        this.allSubscriptionsToUnsubscribe.push(rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); }).subscribe(function (result) {
            _this.reportType = result.params['type'];
            if (/^\d{4}[-]\d{2}[-]\d{2}$/.test(result.params['id'])) {
                _this.jobEventDate = moment(result.params['id'], 'YYYY-MM-DD').toDate();
                _this.readOnly = !!(result.qparams['readOnly'] && result.qparams['readOnly'] === 'true');
            }
            else {
                _this.readOnly = true;
                _this.receivedReport = true;
                _this.empty = true;
            }
            if (_this.pollStatus) {
                _this.pollStatus.next();
                _this.pollStatus.complete();
            }
            _this.pollReportStatus(result.params['id']);
        }));
        this.intervalSelect.selectedOption = this.timeIntervalOptions[0];
        this.timeInterval = this.timeIntervalOptions[0].amount;
    };
    DriverPayComponent.prototype.ngOnDestroy = function () {
        if (this.pollStatus) {
            this.pollStatus.next();
            this.pollStatus.complete();
        }
        if (this.pollObservable && typeof this.pollObservable.unsubscribe === 'function') {
            this.pollObservable.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * Creates or regenerates a pay report using one of four duration options
     *
     * @param  {Date} date The selected date
     * @param  {'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip'} duration
     * The duration options used when generating pay records
     * @param  {DriverPayFilterSelection} filter Filter selection to be used in the report queries
     * @param  {boolean} save Flag for deciding if we need to create or regenerate a report
     */
    DriverPayComponent.prototype.createPaySheet = function (date, duration, filter, save) {
        var _this = this;
        if (duration === void 0) { duration = 'all-driver-items'; }
        this.processing = true;
        this.empty = false;
        this.saving = true;
        this.selectedDuration = duration;
        this.savePreferences();
        var timeRange = {
            start_time: moment(date).startOf('day').toISOString(),
            end_time: moment(date).endOf('day').toISOString(),
        };
        if (save) {
            this.processingText = 'Resetting the Report';
            this.paySheetService.saveReport(duration, moment(date).format('YYYY-MM-DD'), timeRange).subscribe(function (report) {
                _this.pollReportStatus(report.id);
            }, function (err) { return _this.errors = api_service_1.parseErrors(err); }, function () {
                _this.saving = false;
                _this.reportActive = true;
            });
        }
        else {
            this.processingText = 'Generating Report';
            this.paySheetService.createReport(duration, moment(date).format('YYYY-MM-DD'), timeRange).subscribe(function (report) {
                _this.saving = false;
                _this.pollReportStatus(report.id);
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.editing = false;
                _this.processing = false;
                _this.reportActive = false;
            });
        }
    };
    /**
     * Polls the paySheet endpoint for the prcessing status every 5 seconds and then sets the UI based on that status
     *
     * @param {string} id The paySheet id (key or mm-dd-yyyy date format)
     */
    DriverPayComponent.prototype.pollReportStatus = function (id) {
        var _this = this;
        this.pollStatus = new rxjs_1.Subject();
        if (this.receivedReport) {
            this.paySheetService.slug = 'driver-pay/pay-sheets/approvals/';
        }
        this.pollObservable = this.paySheetService.getUpdate(5000, id)
            .pipe(operators_1.takeUntil(this.pollStatus))
            .subscribe(function (reportData) {
            switch (reportData.processStatus) {
                case 'complete':
                    _this.pollStatus.next();
                    _this.pollStatus.complete();
                    _this.processing = true;
                    _this.loadPayRecords(reportData);
                    break;
                case 'processing':
                    _this.processing = true;
                    _this.empty = false;
                    _this.saving = true;
                    break;
                case 'failed':
                    _this.pollStatus.next();
                    _this.pollStatus.complete();
                    _this.editing = false;
                    _this.processing = false;
                    _this.reportActive = false;
                    _this.switchView('failed');
                    if (_this.receivedReport) {
                        _this.empty = true;
                    }
                    break;
                default:
                    _this.pollStatus.next();
                    _this.pollStatus.complete();
                    _this.editing = false;
                    _this.processing = false;
                    _this.reportActive = false;
                    _this.switchView('prereport');
                    if (_this.receivedReport) {
                        _this.empty = true;
                    }
            }
        }, function (err) {
            _this.pollStatus.next();
            _this.pollStatus.complete();
            _this.paySheet = null;
            _this.payRecords = [];
            _this.editing = false;
            _this.processing = false;
            _this.reportActive = false;
            _this.switchView('prereport');
            if (_this.receivedReport) {
                _this.empty = true;
            }
        });
    };
    /**
     * Get the selected report's records and set UI flags
     *
     * @param  {PaySheet} report The current Pay Report
     */
    DriverPayComponent.prototype.loadPayRecords = function (report) {
        var _this = this;
        if (report) {
            this.paySheet = report;
        }
        if (this.receivedReport) {
            this.paySheetService.slug = 'driver-pay/pay-records/approvals/';
        }
        var params = {
            page: this.page,
            page_size: this.pageSize,
            carrier_type: this.reportType,
            search: this.search,
            ordering: this.sortDirection === '' ?
                null : (this.sortDirection === 'asc' ? '' : '-') + this.sortBy
        };
        if (this.filters) {
            params = __assign({}, params, this.parseFilters(this.filters));
        }
        // this.processing = true;
        this.paySheetService.getReportRecords(report.id, params).subscribe(function (records) {
            report.payRecords = records;
            _this.setupReportData(report);
        }, function (err) {
            _this.editing = false;
            _this.processing = false;
            _this.reportActive = false;
            _this.switchView('prereport');
            if (_this.receivedReport) {
                _this.empty = true;
            }
        });
    };
    /**
     * Save a list of rowEdits and apply those updates to the UI correctly
     *
     * @param  {PayRecord[]} rowEdits An array of row edits to be saves
     */
    DriverPayComponent.prototype.savePaySheet = function (rowEdits) {
        var _this = this;
        this.saving = true;
        var saveReqs = [];
        rowEdits.forEach(function (row) {
            saveReqs.push(_this.paySheetService.saveRecord(row.id, row));
        });
        rxjs_1.forkJoin(saveReqs).subscribe(function () {
            // this.getTimelineStats(this.payRecords);
            _this.lastSaved = moment().calendar();
        }, function (err) { return _this.errors = _this.paySheetService.parsePayRecordErrors(err); }, function () { return _this.saving = false; });
    };
    /**
     * Take the report data and set it up for use in the various UI views
     *
     * @param  {PaySheet} report The full report data
     */
    DriverPayComponent.prototype.setupReportData = function (report) {
        if (!this.jobEventDate) {
            this.jobEventDate = moment(report.reportDate.split('T')[0], 'YYYY-MM-DD').toDate();
        }
        this.jobEvents = report.payRecords.map(function (p) { return (p.data.assignment.jobevent); }).filter(function (v, i, s) { return s.indexOf(v) === i; });
        this.editing = true;
        this.reportActive = true;
        this.empty = report.payRecords.length === 0;
        this.processing = false;
        this.readOnly = report.isApproved || this.user && this.user.organization && (report.payerOrganization !== this.user.organization.id);
        this.lastSaved = moment(report.lastModified).calendar();
        this.payRecords = report.payRecords.map(function (row) { return (__assign({}, row, { selected: false, editing: false })); });
        // this.getTimelineStats(this.payRecords);
        var startTimes = [], endTimes = [];
        this.payRecords.forEach(function (record) {
            if (record.data && record.data.assignment) {
                Object.keys(record.data.assignment).forEach(function (key) {
                    if (['trips', 'predictedTrips', 'punchCards'].indexOf(key) > -1 && record.data.assignment[key]) {
                        record.data.assignment[key].forEach(function (obj) {
                            if (obj && obj.startTimeTimestamp) {
                                startTimes.push(obj.startTimeTimestamp);
                            }
                            if (obj && obj.endTimeTimestamp) {
                                endTimes.push(obj.endTimeTimestamp);
                            }
                        });
                    }
                    if (['shifts'].indexOf(key) > -1 && record.data.assignment[key]) {
                        record.data.assignment[key].forEach(function (obj) {
                            if (obj && obj.startTime) {
                                startTimes.push(obj.startTime);
                            }
                            if (obj && obj.endTime) {
                                endTimes.push(obj.endTime);
                            }
                        });
                    }
                });
            }
            if ((record.data.assignment && record.data.assignment.uniqueStart) ||
                (record.data.assignment.jobevent && record.data.assignment.jobevent.shift1StartTimestamp)) {
                startTimes.push((record.data.assignment.uniqueStart || record.data.assignment.jobevent.shift1StartTimestamp));
            }
        });
        this.processing = false;
        this.timelineRange = this.setTimelineRange(startTimes, endTimes);
    };
    DriverPayComponent.prototype.switchView = function (view) {
        var previousVeiw = this.view;
        this.view = view;
        if (previousVeiw === 'prereport' && this.search && this.paySheet) {
            this.loadPayRecords(this.paySheet);
        }
    };
    /**
     * Change the search term and trigger a new record load
     *
     * @param {string} term The updated search term
     */
    DriverPayComponent.prototype.changeSearch = function (term) {
        this.search = term;
        if (this.paySheet) {
            this.loadPayRecords(this.paySheet);
        }
    };
    /**
     * Reset the poller subject, and navigate the url to the specified date
     *
     * @param  {Date[]} dates The selected date list (comes back as a list from the date selector)
     */
    DriverPayComponent.prototype.onDateChanged = function (dates) {
        this.jobEventDate = dates[0];
        if (this.reportType && this.reportType !== 'all') {
            this.router.navigate(['driver-pay', moment(this.jobEventDate).format('YYYY-MM-DD'), this.reportType]);
        }
        else {
            this.router.navigate(['driver-pay', moment(this.jobEventDate).format('YYYY-MM-DD')]);
        }
    };
    /**
     * Opens the generate report dialog and sets up the callback for creating a report
     *
     */
    DriverPayComponent.prototype.openGenerateReportDialog = function (save) {
        var _this = this;
        var saveFlag = save ? save : this.reportActive;
        this.editing = false;
        var dialog = this.dialog.open(generate_report_dialog_component_1.GenerateReportDialogComponent, {
            width: '380px',
            data: { selectedOption: this.selectedDuration ? this.selectedDuration : null }
        });
        dialog.componentInstance.callback = function (selectedOption) {
            return _this.createPaySheet(_this.jobEventDate, selectedOption.value, null, saveFlag);
        };
    };
    /**
     * Opens the bulk edit dialog and sets up the callback for resetting selected rows using a specified pay basis
     *
     */
    DriverPayComponent.prototype.openBulkPayBasisDialog = function () {
        var _this = this;
        var dialog = this.dialog.open(generate_report_dialog_component_1.GenerateReportDialogComponent, {
            width: '380px',
            data: {
                selectedOption: this.selectedDuration ? this.selectedDuration : null,
                title: 'Edit Selected Rows'
            }
        });
        dialog.componentInstance.callback = function (selectedOption) {
            if (!_this.readOnly) {
                var editedRows_1 = [];
                _this.payRecords.forEach(function (row) {
                    if (row.data && (_this.selectedRows.includes(row.data.referenceId) || _this.allSelected)) {
                        row.data.rowData.payBasis = selectedOption.value;
                        row.data.rowData.payLines = _this.generatePayPeriods(row.data.rowData);
                        editedRows_1.push(row);
                    }
                });
                _this.savePaySheet(_this.payRecords.filter(function (row) { return (_this.selectedRows.includes(row.data.referenceId)); }));
            }
        };
    };
    /**
     * Opens the pay report filter dialog and sets up the callback for parsing and using the selection from that
     *
     */
    DriverPayComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            var baseDropdownConfig = {
                selectText: 'Select Job',
                loadingText: 'Loading Jobs...',
                noResultsText: 'No Jobs',
                service: pay_sheet_filter_service_1.PaySheetFilterService,
                serviceId: this.paySheet.id,
                serviceFunction: 'listFilters',
                searchKey: 'filter_search',
                serviceFunctionScope: 'jobs'
            };
            dialog.componentInstance.filters = [
                {
                    type: 'dropdown', field: 'job', label: 'Job',
                    dropdownConfig: baseDropdownConfig
                }, {
                    type: 'text', field: 'orderNumber', label: 'Order Number'
                }, {
                    type: 'text', field: 'jobNumber', label: 'Job Number',
                }, {
                    type: 'dropdown', field: 'driver', label: 'Driver',
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: 'Select Driver', loadingText: 'Loading Drivers...', noResultsText: 'No Drivers', serviceFunctionScope: 'drivers' })
                }, {
                    type: 'dropdown', field: 'truckType', label: 'Truck Type',
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: 'Select Truck Type', loadingText: 'Loading Truck Type...', noResultsText: 'No Truck Types', serviceFunctionScope: 'truck-types' })
                }, {
                    type: 'dropdown', field: 'customer', label: 'Customer',
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: 'Select Customer', loadingText: 'Loading Customer...', noResultsText: 'No Customers', serviceFunctionScope: 'customers' })
                }, {
                    type: 'dropdown', field: 'project', label: 'Project',
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: 'Select Project', loadingText: 'Loading Project...', noResultsText: 'No Projects', serviceFunctionScope: 'projects' })
                }, {
                    type: 'dropdown', field: 'carrier', label: 'Carrier',
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: 'Select Carrier', loadingText: 'Loading Carrier...', noResultsText: 'No Carriers', serviceFunctionScope: 'carrier-organizations' })
                }, {
                    type: 'dropdown', field: 'tags', label: 'Markets',
                    dropdownConfig: __assign({}, baseDropdownConfig, { multiselect: true, service: tag_service_1.TagService, serviceFunctionScope: null, serviceId: null, serviceFunction: 'list', searchKey: 'search', query: {}, selectText: 'Select Markets', loadingText: 'Loading Markets...', noResultsText: 'No Markets' })
                }
            ];
            dialog.componentInstance.callback = function (res) {
                _this.page = 1;
                _this.filters = res;
                _this.paySheetPaginator.pageIndex = 0;
                _this.loadPayRecords(_this.paySheet);
            };
            if (this.filters) {
                dialog.componentInstance.model = __assign({}, this.filters);
            }
            this.filtersDialog = dialog.componentInstance;
        }
    };
    /**
     * Manages the current filter selection and generates the expected query format for the api request
     *
     * @param  {DriverPayFilterSelection} filters The selected filters from the filter dialog
     */
    DriverPayComponent.prototype.parseFilters = function (filters) {
        var _this = this;
        var query = {};
        var queryKeys = {
            job: 'assignment__jobevent__job',
            orderNumber: 'assignment__jobevent__job__order_number__icontains',
            jobNumber: 'assignment__jobevent__job__job_number',
            driver: 'assignment__driver',
            truckType: 'assignment__truck__truck_type',
            customer: 'assignment__jobevent__customer_organization',
            project: 'assignment__jobevent__job__project',
            carrier: 'assignment__driver__carrier__organization',
            tags: 'assignment__jobevent__job__tags'
        };
        if (filters) {
            this.appliedFilters = [];
            Object.keys(filters).forEach(function (key) {
                var value = filters[key];
                if (key === 'tags') {
                    if (value && value.length > 0) {
                        value = value.map(function (tag) { return tag.name; }).join(',');
                        query[queryKeys[key]] = value;
                    }
                }
                else {
                    query[queryKeys[key]] = filters[key] && filters[key].id || value;
                }
                var displayValues = filters[key] && filters[key]['name'] ? filters[key]['name'] : filters[key];
                var title = key.charAt(0).toUpperCase() + key.slice(1);
                if (key === 'orderNumber') {
                    title = 'Order #';
                }
                if (key === 'jobNumber') {
                    title = 'Job #';
                }
                var filter = new filter_option_1.FilterOption({
                    key: key,
                    filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                    title: title,
                    displayValues: displayValues || null,
                    query: query
                });
                _this.appliedFilters.push(filter);
            });
        }
        return query;
    };
    DriverPayComponent.prototype.filtersModified = function (appliedFilters) {
        var _this = this;
        Object.keys(this.filters).forEach(function (key) {
            var appliedFilter = lodash_1.find(appliedFilters, { key: key });
            if (!appliedFilter) {
                delete _this.filters[key];
            }
        });
        this.page = 1;
        this.loadPayRecords(this.paySheet);
    };
    /**
     * Opens the bulk edit dialog and sets up the callback for applying any of the selected edits from it
     *
     */
    DriverPayComponent.prototype.openBulkEditDialog = function () {
        var _this = this;
        var dialog = this.dialog.open(bulk_edit_dialog_component_1.BulkEditDialogComponent, { width: '380px', data: { view: this.view } });
        dialog.componentInstance.callback = function (changes) { _this.bulkEdits(changes); };
    };
    /**
     * Apply bulk time edits
     *
     * @param {TimelineEdits} index The specified row index
     */
    DriverPayComponent.prototype.bulkEdits = function (edits) {
        var _this = this;
        if (!this.readOnly) {
            this.payRecords.forEach(function (row) {
                if (_this.view === 'table' && row.data && row.data.rowData.payLines.length === 0) {
                    row.data.rowData.payLines = [{ startDatetime: '', endDatetime: '' }];
                }
                if (row.data && (_this.selectedRows.includes(row.data.referenceId) || _this.allSelected) && row.data.rowData.payLines.length) {
                    if (edits.duration !== 0) {
                        row.data.rowData.payLines[row.data.rowData.payLines.length - 1]
                            .endDatetime = moment(row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime)
                            .add(edits.duration, 'minutes').toISOString();
                    }
                    if (edits.startTime) {
                        row.data.rowData.payLines[0].startDatetime = moment(_this.jobEventDate.toISOString().split('T')[0] + edits.startTime, 'YYYY-MM-DDHH:mm').toISOString();
                    }
                    if (edits.endTime) {
                        row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = moment(_this.jobEventDate.toISOString().split('T')[0] + edits.endTime, 'YYYY-MM-DDHH:mm').toISOString();
                    }
                    if (edits.payStart) {
                        switch (edits.payStart) {
                            case 'scheduled-start':
                                row.data.rowData.payLines[0].startDatetime = row.data.assignment.uniqueStart ||
                                    row.data.assignment.jobevent.shift1StartTimestamp;
                                break;
                            case 'shift-start':
                                row.data.rowData.payLines[0].startDatetime = row.data.assignment.shifts &&
                                    row.data.assignment.shifts[0] &&
                                    row.data.assignment.shifts[0].startTime;
                                break;
                            case 'geofence-start':
                                row.data.rowData.payLines[0].startDatetime = row.data.rowData.predictedTrips &&
                                    row.data.rowData.predictedTrips[0] &&
                                    row.data.rowData.predictedTrips[0].startTimeTimestamp;
                                break;
                            case 'first-load':
                                row.data.rowData.payLines[0].startDatetime = row.data.rowData.trips &&
                                    row.data.rowData.trips[0] &&
                                    row.data.rowData.trips[0].loadingCompleteTime;
                                break;
                        }
                        if (!row.data.rowData.payLines[0].startDatetime) {
                            row.data.rowData.payLines[0].startDatetime = row.data.assignment.uniqueStart ||
                                row.data.assignment.jobevent.shift1StartTimestamp;
                        }
                    }
                    if (edits.payEnd) {
                        switch (edits.payEnd) {
                            case 'shift-end':
                                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.assignment.shifts &&
                                    row.data.assignment.shifts[0] &&
                                    row.data.assignment.shifts[0].endTime;
                                break;
                            case 'geofence-end':
                                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.predictedTrips &&
                                    row.data.rowData.predictedTrips[0] &&
                                    row.data.rowData
                                        .predictedTrips[row.data.rowData.predictedTrips.length - 1].endTimeTimestamp;
                                break;
                            case 'last-load':
                                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.trips &&
                                    row.data.rowData.trips[0] &&
                                    row.data.rowData
                                        .trips[row.data.rowData.trips.length - 1]
                                        .endTimeTimestamp;
                                break;
                        }
                        if (!row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime &&
                            row.data.rowData.payLines[0].startDatetime) {
                            row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = moment(row.data.rowData.payLines[0].startDatetime).add(1, 'hours').toISOString();
                        }
                    }
                }
            });
            if (this.allSelected) {
                this.savePaySheet(this.payRecords);
            }
            else {
                this.savePaySheet(this.payRecords.filter(function (row) { return (_this.selectedRows.includes(row.data.referenceId)); }));
            }
        }
        if (this.driverPayTimeline) {
            this.driverPayTimeline.resetTimelineSubs();
        }
    };
    /**
     * Figures out the timeline range based on a set of start and end times
     *
     * @param {string[]} startTimes The list of start times
     * @param {string[]} endTimes The list of end times
     * @return {DateTimeRange} The range used by the timeline to limit the displayed time range
     */
    DriverPayComponent.prototype.setTimelineRange = function (startTimes, endTimes) {
        var _this = this;
        var startMoments = startTimes.map(function (time) { return (moment(_this.jobEventDate).startOf('day').subtract(1, 'days').isBefore(time) ?
            moment(time) : moment(_this.jobEventDate).endOf('day')); });
        var endMoments = [];
        endTimes.forEach(function (time) { if (!!time) {
            endMoments.push(moment(time));
        } });
        endMoments.push(moment(this.jobEventDate).endOf('day').subtract(2, 'hours'));
        return {
            startDatetime: moment.min(startMoments).startOf('hour').toISOString(),
            endDatetime: moment.max(endMoments).endOf('hour').toISOString()
        };
    };
    /**
     * Generate pay periods based on a selected duration option
     *
     * @param {TimelineRowData} rowData Pay Record timeline Data
     * @return {DateTimeRange[]} the list of generated pay periods
     */
    DriverPayComponent.prototype.generatePayPeriods = function (rowData) {
        var payLines = [];
        switch (rowData.payBasis) {
            case 'all-driver-items':
                if (rowData.punchcards && rowData.punchcards.length &&
                    rowData.punchcards[0].endTimeTimestamp) {
                    payLines = [{
                            endDatetime: rowData.punchcards[rowData.punchcards.length - 1].endTimeTimestamp ?
                                rowData.punchcards[rowData.punchcards.length - 1].endTimeTimestamp :
                                (rowData.punchcards[rowData.punchcards.length - 2] &&
                                    rowData.punchcards[rowData.punchcards.length - 2].endTimeTimestamp ?
                                    rowData.punchcards[rowData.punchcards.length - 2].endTimeTimestamp :
                                    rowData.punchcards[0].endTimeTimestamp),
                            startDatetime: this.user.crh ? rowData.shifts.find(function (shift) { return (shift.type === 'scheduled'); }).startDatetime :
                                rowData.punchcards[0].startTimeTimestamp
                        }];
                }
                if (payLines.length === 0 && rowData.trips && rowData.trips.length &&
                    rowData.trips[0].endTimeTimestamp) {
                    payLines = [{
                            endDatetime: rowData.trips[rowData.trips.length - 1].endTimeTimestamp ?
                                rowData.trips[rowData.trips.length - 1].endTimeTimestamp :
                                (rowData.trips[rowData.trips.length - 2] &&
                                    rowData.trips[rowData.trips.length - 2].endTimeTimestamp ?
                                    rowData.trips[rowData.trips.length - 2].endTimeTimestamp :
                                    rowData.trips[0].endTimeTimestamp),
                            startDatetime: rowData.trips[0].startTimeTimestamp
                        }];
                }
                break;
            case 'each-driver-item':
                if (rowData.punchcards && rowData.punchcards.length) {
                    rowData.punchcards.forEach(function (punchcard) {
                        if (punchcard.startTimeTimestamp && punchcard.endTimeTimestamp) {
                            payLines.push({
                                startDatetime: punchcard.startTimeTimestamp,
                                endDatetime: punchcard.endTimeTimestamp,
                            });
                        }
                    });
                }
                else {
                    rowData.trips.forEach(function (trip) {
                        if (trip.startTimeTimestamp && trip.endTimeTimestamp) {
                            payLines.push({
                                startDatetime: trip.startTimeTimestamp,
                                endDatetime: trip.endTimeTimestamp,
                            });
                        }
                    });
                }
                break;
            case 'all-geo-trips':
                if (rowData.predictedTrips && rowData.predictedTrips.length &&
                    rowData.predictedTrips[0].endTimeTimestamp) {
                    payLines = [{
                            endDatetime: rowData.predictedTrips[rowData.predictedTrips.length - 1].endTimeTimestamp ?
                                rowData.predictedTrips[rowData.predictedTrips.length - 1].endTimeTimestamp :
                                (rowData.predictedTrips[rowData.predictedTrips.length - 2] &&
                                    rowData.predictedTrips[rowData.predictedTrips.length - 2].endTimeTimestamp ?
                                    rowData.predictedTrips[rowData.predictedTrips.length - 2].endTimeTimestamp :
                                    rowData.predictedTrips[0].endTimeTimestamp),
                            startDatetime: this.user.crh ? rowData.shifts.find(function (shift) { return (shift.type === 'scheduled'); }).startDatetime :
                                rowData.predictedTrips[0].startTimeTimestamp
                        }];
                }
                break;
            case 'each-geo-trip':
                rowData.predictedTrips.forEach(function (trip) {
                    if (trip.startTimeTimestamp && trip.endTimeTimestamp) {
                        payLines.push({
                            startDatetime: trip.startTimeTimestamp,
                            endDatetime: trip.endTimeTimestamp,
                        });
                    }
                });
                break;
        }
        return payLines;
    };
    /**
     * Gets the timeline stats from the displayed list of payRecords
     *
     * @param {PayRecord[]} payRecords The list of pay records
     */
    DriverPayComponent.prototype.getTimelineStats = function (payRecords) {
        var _this = this;
        this.timelineStats = { drivers: [], trucks: [], truckTypes: [], carriers: [], jobs: [] };
        var tripTimeList = [];
        var tripTimeAvg = 0;
        var payMinutes = 0;
        payRecords.forEach(function (row) {
            if (row.data.assignment) {
                if (row.data.assignment.driver) {
                    _this.timelineStats.carriers.push(row.data.assignment.driver.carrier);
                    _this.timelineStats.drivers.push(row.data.assignment.driver);
                }
                if (row.data.assignment.truck) {
                    _this.timelineStats.trucks.push(row.data.assignment.truck);
                    _this.timelineStats.truckTypes.push(row.data.assignment.truck.truckType);
                }
            }
            if (row.data.assignment.jobevent) {
                _this.timelineStats.jobs.push(row.data.assignment.jobevent);
            }
            if (row.data.rowData) {
                if (row.data.rowData.trips) {
                    row.data.rowData.trips.forEach(function (trip) { return trip && tripTimeList.push(trip.completedTripDuration); });
                }
                if (row.data.rowData.payAdjustmentTotal) {
                    payMinutes += row.data.rowData.payAdjustmentTotal;
                }
                row.data.rowData.payLines.forEach(function (period) {
                    if (period.startDatetime && period.endDatetime) {
                        payMinutes += moment(period.endDatetime).diff(period.startDatetime, 'minutes');
                    }
                });
            }
        });
        tripTimeList.forEach(function (val) { return tripTimeAvg += val; });
        this.timelineStats = {
            drivers: this.timelineStats.drivers &&
                this.timelineStats.drivers.filter(function (e, i) { return _this.timelineStats.drivers.findIndex(function (a) { return (a && a['id'] === e['id']); }) === i; }),
            trucks: this.timelineStats.trucks &&
                this.timelineStats.trucks.filter(function (e, i) { return _this.timelineStats.trucks.findIndex(function (a) { return (a && a['id'] === e['id']); }) === i; }),
            truckTypes: this.timelineStats.truckTypes &&
                this.timelineStats.truckTypes.filter(function (e, i) { return _this.timelineStats.truckTypes.findIndex(function (a) { return (a && a['id'] === e['id']); }) === i; }),
            carriers: this.timelineStats.carriers &&
                this.timelineStats.carriers.filter(function (e, i) { return _this.timelineStats.carriers.findIndex(function (a) { return (a && a['id'] === e['id']); }) === i; }),
            jobs: this.timelineStats.jobs &&
                this.timelineStats.jobs.filter(function (e, i) { return _this.timelineStats.jobs.findIndex(function (a) { return a && (a && a['id'] === e['id']); }) === i; }),
            avgTripTime: Number((tripTimeAvg / tripTimeList.length) / 60000).toFixed(2) + ' mins',
            payHours: Number(payMinutes / 60).toFixed(2)
        };
        this.timelineStats.tooltipText = {
            drivers: this.timelineStats.drivers && this.timelineStats.drivers.map(function (driver) { return driver.name; }).join('\n '),
            trucks: this.timelineStats.trucks && this.timelineStats.trucks.map(function (truck) { return truck.name; }).join('\n '),
            truckTypes: this.timelineStats.truckTypes && this.timelineStats.truckTypes.map(function (type) { return type.name; }).join('\n '),
            carriers: this.timelineStats.carriers && this.timelineStats.carriers.map(function (carrier) { return carrier.name; }).join('\n '),
            jobs: this.timelineStats.jobs && this.timelineStats.jobs.map(function (job) { return job.jobDisplayName; }).join('\n '),
        };
    };
    /**
     * Resets the pay records based on the currently applied duration
     *
     */
    DriverPayComponent.prototype.resetPayPeriods = function () {
        var _this = this;
        if (!this.readOnly) {
            this.payRecords.forEach(function (row) {
                row.data.rowData.payLines = _this.generatePayPeriods(row.data.rowData);
                row.data.rowData.payBasis = _this.selectedDuration;
            });
            this.savePaySheet(this.payRecords);
            this.setPaySheetPage();
        }
        if (this.driverPayTimeline) {
            this.driverPayTimeline.resetTimelineSubs();
        }
    };
    /**
     * Resets a specific pay record based on the currently applied duration
     *
     * @param {number} index The specified row index
     */
    DriverPayComponent.prototype.resetRow = function (index) {
        if (!this.readOnly) {
            this.payRecords[index].data.rowData.payLines = this.generatePayPeriods(this.payRecords[index].data.rowData);
            this.savePaySheet([this.payRecords[index]]);
        }
        if (this.driverPayTimeline) {
            this.driverPayTimeline.resetTimelineSubs();
        }
    };
    /**
     * Opens the report approval dialog and sets up the approval callback
     *
     * @param {string} type The specified status type
     */
    DriverPayComponent.prototype.changeReportStatus = function (type) {
        var _this = this;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Approve Report?',
            body: 'Once approved, this pay report cannot be altered or edited any further!',
            close: 'Cancel',
            accept: 'Approve'
        };
        this.confirmDialog.afterClosed().subscribe(function (approved) {
            if (approved) {
                var date = moment(_this.jobEventDate).format('YYYY-MM-DD');
                _this.paySheetService.approveReport(date).subscribe(function (report) { return _this.readOnly = report.isApproved; }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
            }
        });
    };
    /**
     * Opens the export dialog and lets the user select a specific export type
     *
     */
    DriverPayComponent.prototype.exportPayRecords = function () {
        var _this = this;
        var scope = {
            driverPayFormats: {
                raw: false,
                hiredHauler: false,
                payroll: false,
                equipment: false,
                hourlyTicket: false
            }
        };
        var params = new http_1.HttpParams();
        this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
            width: '430px',
            data: {
                type: 'driver pay',
                buttonText: 'Generate Selected Exports',
                params: params,
                scope: scope,
                callback: function (exportType) {
                    var selectedRows;
                    if (_this.selectedRows && _this.selectedRows.length && !_this.allSelected) {
                        selectedRows = _this.payRecords.filter(function (row) { return _this.selectedRows.includes(row.data.referenceId); });
                        _this.manageExportFile(selectedRows, exportType);
                        _this.dialog.closeAll();
                    }
                    else {
                        if (_this.receivedReport) {
                            _this.paySheetService.slug = 'driver-pay/pay-records/approvals/';
                        }
                        _this.paySheetService.getAllReportRecords(_this.paySheet.id, 10, __assign({ carrier_type: _this.reportType, search: _this.search, ordering: _this.sortDirection === '' ?
                                null : (_this.sortDirection === 'asc' ? '' : '-') + _this.sortBy }, _this.parseFilters(_this.filters))).subscribe(function (records) {
                            selectedRows = records;
                            _this.manageExportFile(selectedRows, exportType);
                            _this.dialog.closeAll();
                        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
                    }
                }
            }
        });
    };
    /**
     * Formats and uploads the export file based on the selected export type
     *
     * @param {PayRecord[]} payRecords The pay records to be exported
     * @param {boolean} exportType The exportType flag (specfies Viewpoint or not)
     */
    DriverPayComponent.prototype.manageExportFile = function (payRecords, exportType) {
        var _this = this;
        Object.keys(exportType).forEach(function (key) {
            if (exportType[key]) {
                var fileData_1 = _this.paySheetService.downloadCSV('driverpay-' + key + '-export-' + moment().format('YYYY-MM-DD') + (_this.reportType ? '-' + _this.reportType : ''), _this.paySheetService.formatExport(payRecords, key));
                _this.uploadService.getS3Policy().subscribe(function (policy) {
                    var organization = _this.authenticationService.getOrganization();
                    var dateString = moment().format('YYYY-MM-DD_hhmmssA');
                    policy['fields']['key'] = "exports/" + organization.id + "/driverpay-export-" + dateString + ".csv";
                    _this.uploadService.uploadToS3(policy, fileData_1, 'text/csv').subscribe(function () {
                        // Great!
                    }, function (err) {
                        throw err;
                    });
                });
            }
        });
    };
    /**
     * Action handler for the row actions
     *
     * @param {any} option The specified row action
     */
    DriverPayComponent.prototype.setSelectedAction = function (option) {
        switch (option.name) {
            case 'New Pay Basis':
                this.openBulkPayBasisDialog();
                break;
            case 'Bulk Edit':
                this.openBulkEditDialog();
                break;
            case 'Reset Edits':
                this.resetPayPeriods();
                break;
            case 'Reset Report':
                this.openGenerateReportDialog();
                break;
        }
    };
    /**
     * Sets the report page and ensures the various UI subs are reset properly
     *
     * @param {PageEvent} e The mat-paginator page change event
     */
    DriverPayComponent.prototype.setPaySheetPage = function (e) {
        if (e) {
            this.page = e.pageIndex + 1;
        }
        else {
            this.page = 1;
            if (this.paySheetPaginator) {
                this.paySheetPaginator.firstPage();
            }
        }
        this.selectedRows = [];
        this.loadPayRecords(this.paySheet);
        var timelineEl = document.getElementsByClassName('timeline-container');
        if (timelineEl[0]) {
            timelineEl[0].scrollTo({ top: 0 });
        }
        var tableEl = document.getElementsByClassName('mat-table');
        if (tableEl[0]) {
            tableEl[0].scrollTo({ top: 0 });
        }
        if (this.driverPayTimeline) {
            this.driverPayTimeline.resetTimelineSubs();
        }
    };
    // preference methods
    DriverPayComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferenceService.list({
            name: this.preferenceKey,
            type: 'user',
            profile: this.user && this.user.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
                _this.parsePreferences(_this.preference);
            }
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    DriverPayComponent.prototype.savePreferences = function () {
        var _this = this;
        this.preferenceService.save(__assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: this.user && this.user.id, blob: {
                payBasis: this.selectedDuration,
                view: this.view
            } })).subscribe(function (preference) {
            _this.preference = preference;
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    DriverPayComponent.prototype.parsePreferences = function (preference) {
        if (preference.blob) {
            this.selectedDuration = preference.blob['payBasis'] || this.selectedDuration;
            this.switchView(preference.blob['view'] || this.view || 'timeline');
        }
    };
    return DriverPayComponent;
}());
exports.DriverPayComponent = DriverPayComponent;
