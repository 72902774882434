"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slot_1 = require("./slot");
var assignment_serializer_1 = require("./assignment.serializer");
var driver_serializer_1 = require("../drivers/driver.serializer");
var truck_serializer_1 = require("../trucks/truck.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var SlotSerializer = /** @class */ (function () {
    function SlotSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Assignment
    */
    SlotSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var slot = new slot_1.Slot();
        if (!json) {
            return slot;
        }
        slot.id = json.id;
        slot.over = json.over || false;
        slot.updating = json.updating || false;
        slot.numberOfLoadsType = json.numberOfLoadsType;
        slot.saveButton = json.saveButton || false;
        slot.dispatched = json.dispatched || false;
        if (json.assignment && typeof (json.assignment) === 'object') {
            slot.assignment = (new assignment_serializer_1.AssignmentSerializer().fromJson(json.assignment));
            if (json.assignment) {
                slot.assignment['yardBufferTime'] = json.assignment['yardBufferTime'];
                slot.assignment['yardBufferMinutes'] = json.assignment['yardBufferMinutes'];
            }
        }
        else {
            slot.assignment = new assignment_serializer_1.AssignmentSerializer().fromJson({ id: json.assignment });
            if (json.assignment) {
                slot.assignment['yardBufferTime'] = json.assignment['yardBufferTime'];
                slot.assignment['yardBufferMinutes'] = json.assignment['yardBufferMinutes'];
            }
        }
        if (json.driver && typeof (json.driver) === 'object') {
            slot.driver = new driver_serializer_1.DriverSerializer().fromJson(json.driver);
        }
        else {
            slot.driver = new driver_serializer_1.DriverSerializer().fromJson({ id: json.driver });
        }
        if (json.truck && typeof (json.truck) === 'object') {
            slot.truck = new truck_serializer_1.TruckSerializer().fromJson(json.truck);
        }
        else {
            slot.truck = new truck_serializer_1.TruckSerializer().fromJson({ id: json.truck });
        }
        return slot;
    };
    /**
    * @param  {Assignment} assignment
    * @returns any
    */
    SlotSerializer.prototype.toJson = function (slot) {
        var json = {
            id: slot.id,
            over: slot.over,
            updating: slot.updating,
            numberOfLoadsType: slot.numberOfLoadsType,
            saveButton: slot.saveButton,
            assignment: slot.assignment,
            truck: slot.truck && slot.truck.id,
            driver: slot.driver && slot.driver.id,
            yardBufferTime: slot.assignment && slot.assignment['yardBufferTime']
        };
        return decamelizeKeysDeep(json);
    };
    return SlotSerializer;
}());
exports.SlotSerializer = SlotSerializer;
