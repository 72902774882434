"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var trackable_type_1 = require("./trackable-type");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TrackableTypeSerializer = /** @class */ (function () {
    function TrackableTypeSerializer() {
    }
    TrackableTypeSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var trackableType = new trackable_type_1.TrackableType();
        if (!json) {
            return trackableType;
        }
        trackableType.id = json.id;
        trackableType.name = json.name;
        trackableType.active = json.active;
        return trackableType;
    };
    TrackableTypeSerializer.prototype.toJson = function (trackableType) {
        var json = {
            id: trackableType.id,
            name: trackableType.name,
            active: trackableType.active
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return TrackableTypeSerializer;
}());
exports.TrackableTypeSerializer = TrackableTypeSerializer;
