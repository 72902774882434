"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var CheckinOption = /** @class */ (function () {
    function CheckinOption(json) {
        json = camelcaseKeysDeep(json);
        this.type = json.type || 'checkin';
        this.states = {
            ticket_number: false,
            ticket_image: false,
            signature_image: false,
            active_tracking: false,
            log_weight: false,
            qr_code: false
        };
        this.activeTrackingConfig = json.activeTrackingConfig || 'optional';
        this.loadImageConfig = json.loadImageConfig || 'optional';
        this.qrConfig = json.qrConfig || 'optional';
        this.signatureImageConfig = json.signatureImageConfig || 'optional';
        this.ticketImageConfig = json.ticketImageConfig || 'optional';
        this.ticketNumberConfig = json.ticketNumberConfig || 'optional';
        this.weightConfig = json.weightConfig || 'optional';
    }
    return CheckinOption;
}());
exports.CheckinOption = CheckinOption;
