"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ChartDialogComponent = /** @class */ (function () {
    function ChartDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.currentTimeFrame = {
            name: 'Seven Days',
            id: 'seven-days',
            lastPeriodColumnName: 'Last 7 day period',
            currentPeriodColumnName: 'Current 7 day period'
        };
        this.barChartType = 'bar';
        this.barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: 'Notifications'
                        },
                        ticks: { beginAtZero: true }
                    }
                ]
            },
            legend: {
                position: 'bottom'
            }
        };
    }
    ChartDialogComponent.prototype.ngOnInit = function () {
        if (this.reportData) {
            this.barChartData = {
                labels: [this.reportData.name],
                datasets: [
                    {
                        data: [this.reportData.lastPeriod],
                        label: this.currentTimeFrame.lastPeriodColumnName,
                        backgroundColor: 'rgba(174, 214, 241, 0.8)',
                        hoverBackgroundColor: '#AED6F1'
                    },
                    {
                        data: [this.reportData.currentPeriod],
                        label: this.currentTimeFrame.currentPeriodColumnName,
                        backgroundColor: 'rgba(255, 218, 185, 0.8)',
                        hoverBackgroundColor: '#FFDAB9'
                    }
                ]
            };
        }
    };
    return ChartDialogComponent;
}());
exports.ChartDialogComponent = ChartDialogComponent;
