<trip-history-widget title="{{ 'Route' | translate }}" fullBleed="true">
  <div class="trip-history-route">
    <ngui-map *ngIf="mapData" class="map-container" [options]="mapOptions" (mapReady$)="initMap($event)">
      <custom-marker *ngIf="startLocation && startLocation.latitude && startLocation.longitude"
                     [position]="[startLocation.latitude, startLocation.longitude]">
        <img src="assets/img/map/locationmarker-loading.png" />
      </custom-marker>
      <custom-marker *ngIf="endLocation && endLocation.latitude && endLocation.longitude"
                     [position]="[endLocation.latitude, endLocation.longitude]">
        <img src="assets/img/map/locationmarker-unloading.png" />
      </custom-marker>
      <polygon *ngFor="let polygon of polygons"
                 [editable]="false" [paths]="polygon.paths"
                 [strokeColor]="'#FF8C00'"
                 [strokeOpacity]="0.8"
                 [strokeWeight]="2"
                 [fillColor]="'#FF8C00'"
                 [fillOpacity]="0.35">
      </polygon>
      <heatmap-layer class="desktop-map-element"
                      (initialized$)="initHeatmap($event)"
                      dissipating="true"
                      [radius]="20"
                      maxIntensity="5"
                      [data]="heatmapPoints"></heatmap-layer>
    </ngui-map>
    <div *ngIf="!mapData" class="no-results">
      <div class="icon"><i class="icon-locations"></i></div>
      <h2 translate>No GPS Data</h2>
      <p translate>This Trip does not have any associated GPS data</p>
    </div>
  </div>
</trip-history-widget>
