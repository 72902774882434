import { Tag } from './tag';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TagSerializer {

  /**
  * @param  {any} json
  * @returns Tag
  */
  fromJson(json: any): Tag {
    json = camelcaseKeysDeep(json);
    const tag = new Tag();
    if (!json) { return tag; }

    tag.id = json.id;
    tag.name = json.name;
    tag.itemCount = json.itemCount;
    tag.notes = json.notes;

    return tag;
  }

  /**
   * @param  {Tag} Tag
   * @returns any
   */
  toJson(tag: Tag): any {
    let json = {
      id: tag.id,
      name: tag.name,
      itemCount: tag.itemCount,
      notes: tag.notes,
    };
    return decamelizeKeysDeep(json);
  }
}
