const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Profile {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phoneNumber: string;
  title: string;
  organization: string;
  lastModified: string;
  createdAt: string;
  driverNotificationEmail: boolean;
  jobNotificationEmail: boolean;

  constructor(profileInfo: any) {
    profileInfo = camelcaseKeysDeep(profileInfo);

    this.id = profileInfo.id;
    this.firstName = profileInfo.firstName;
    this.lastName = profileInfo.lastName;
    this.name = `${this.firstName} ${this.lastName}`;
    this.email = profileInfo.email;
    this.phoneNumber = profileInfo.phoneNumber;
    this.title = profileInfo.title;
    this.organization = profileInfo.organization;
    this.lastModified = profileInfo.lastModified;
    this.createdAt = profileInfo.created_at;
    this.driverNotificationEmail = profileInfo.driverNotificationEmail;
    this.jobNotificationEmail = profileInfo.jobNotificationEmail;
  }
}
