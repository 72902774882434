"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var job_event_service_1 = require("../job-events/job-event.service");
var CancelJobDialogComponent = /** @class */ (function () {
    function CancelJobDialogComponent(dialogRef, data, jobEventService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.jobEventService = jobEventService;
        this.successJobs = [];
        this.errorsJobs = [];
        this.loading = false;
        this.errors = [];
        if (this.data) {
            this.jobEventIds = this.data.jobEventIds;
            this.jobEventIdsExclude = this.data.jobEventIdsExclude;
            this.allJobEventsCount = this.data.allJobEventsCount;
            this.isAllJobEventsSelected = this.data.isAllJobEventsSelected;
            this.query = this.data.query;
            this.jobEventsDate = this.data.jobEventsDate;
        }
    }
    CancelJobDialogComponent.prototype.submit = function () {
        var _this = this;
        if (this.showSummary) {
            this.dialogRef.close();
            this.callback(this.successJobs);
            return;
        }
        this.loading = true;
        var bulkUpdated = { cancelled: true };
        if (this.isAllJobEventsSelected) {
            bulkUpdated.exclude = this.jobEventIdsExclude;
        }
        else {
            bulkUpdated.include = this.jobEventIds;
        }
        this.jobEventService.bulkUpdate(bulkUpdated, this.query).subscribe(function (response) {
            _this.loading = false;
            if (response.errors && response.errors.length) {
                _this.showSummary = true;
                _this.successJobs = response.success && response.success.map(function (values) { return (values[0]); });
                _this.errorsJobs = response.errors && response.errors.map(function (values) { return (values[0]); });
            }
            else {
                _this.callback(response.success);
                _this.dialogRef.close();
            }
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    return CancelJobDialogComponent;
}());
exports.CancelJobDialogComponent = CancelJobDialogComponent;
