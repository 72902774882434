"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Subscription_1 = require("rxjs/Subscription");
var rxjs_1 = require("rxjs");
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent() {
        this.level = 'danger';
        this.autoClose = false;
        this.onClose = new core_1.EventEmitter();
        this.open = true;
        this.transition = '';
    }
    NotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.autoClose) {
            this.timer = rxjs_1.timer(5000); // 5000 millisecond means 5 seconds
            this.timerSubscription = this.timer.subscribe(function () {
                _this.close();
            });
        }
    };
    NotificationComponent.prototype.ngOnDestroy = function () {
        if (this.timerSubscription && this.timerSubscription instanceof Subscription_1.Subscription) {
            this.timerSubscription.unsubscribe();
        }
    };
    NotificationComponent.prototype.close = function () {
        var _this = this;
        this.transition = 'fade';
        setTimeout(function () {
            _this.open = false;
        }, 1000);
        setTimeout(function () {
            _this.transition = 'fade slide';
        }, 500);
        if (this.onClose) {
            this.onClose.emit(true);
        }
    };
    NotificationComponent.prototype.reset = function () {
        this.transition = '';
        this.open = true;
    };
    return NotificationComponent;
}());
exports.NotificationComponent = NotificationComponent;
