"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".trip-history[_ngcontent-%COMP%]{overflow-y:auto;box-sizing:border-box;min-height:100vh}.trip-history[_ngcontent-%COMP%]   .trip-history-dashboard[_ngcontent-%COMP%]{padding:25px;max-width:1100px;margin:auto;display:flex;justify-content:center;gap:10px}.trip-history[_ngcontent-%COMP%]   .trip-history-ticket[_ngcontent-%COMP%]{width:100%}.trip-history[_ngcontent-%COMP%]   .billing-route-container[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:column;gap:10px}.trip-history[_ngcontent-%COMP%]   .billing-route-container[_ngcontent-%COMP%]   .trip-history-route[_ngcontent-%COMP%]{min-height:320px}"];
exports.styles = styles;
