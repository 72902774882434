import { remove, find, clone } from 'lodash';
import * as moment from 'moment';

import { Job } from '../jobs/job';
import { Driver } from '../drivers/driver';
import { DriverSerializer } from './../drivers/driver.serializer';
import { Organization } from '../organizations/organization';
import { CondensedTrip } from '../trips/condensed-trip';
import { CondensedPunchCard } from '../punch-cards/condensed-punch-card';
import { JobSerializer } from '../jobs/job.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Paystub {
  id: string;
  number: string;
  amount: string;
  expenseAmount: string;
  surchargeAmount: string;
  tripsAmount: string;
  tripsExpenseAmount: string;
  punchcardsAmount: string;
  punchcardsExpenseAmount: string;
  subtotal: string;
  ownerOrganization: Organization;
  customerOrganization: Organization;
  driver: Driver;
  job: Job;
  sendable: boolean;
  sent: boolean;
  draft: boolean;
  sentTime: string;
  sentBy: string;
  sentTrips: CondensedTrip[];
  sentPunchCards: CondensedPunchCard[];
  billFromContactEmail: string;
  billFromCcEmails: string;
  billFromAddress: string;
  billToContactEmail: string;
  billToCcEmails: string;
  billToAddress: string;
  createdAt: string;
  remittanceInfo: string;
  earliestBillableItemTime: string;
  latestBillableItemTime: string;
  pdf: string;
  html: string;
  paymentType: string;

  // Missing Fields
  startDate: string; // Minimum Trip / Punchcard Start Date
  endDate: string;  // Maximum Trip / Punchcard Start Date

  constructor(paystubInfo: any) {
    paystubInfo = camelcaseKeysDeep(paystubInfo);

    this.id = paystubInfo.id;
    this.number = paystubInfo.number;
    this.amount = paystubInfo.amount;
    this.expenseAmount = paystubInfo.expenseAmount || 0;
    this.surchargeAmount = paystubInfo.surchargeAmount || 0;
    this.tripsAmount = paystubInfo.tripsAmount || 0;
    this.tripsExpenseAmount = paystubInfo.tripsExpenseAmount || 0;
    this.punchcardsAmount = paystubInfo.punchcardsAmount || 0;
    this.punchcardsExpenseAmount = paystubInfo.punchcardsExpenseAmount || 0;
    this.subtotal = paystubInfo.subtotal || 0;
    if (paystubInfo.ownerOrganization) {
      this.ownerOrganization = new Organization(paystubInfo.ownerOrganization);
    }
    if (paystubInfo.customerOrganization) {
      this.customerOrganization = new Organization(paystubInfo.customerOrganization);
    }
    if (paystubInfo.driver) {
      this.driver = new DriverSerializer().fromJson(paystubInfo.driver);
    }
    if (paystubInfo.job) {
      this.job = new JobSerializer().fromJson(paystubInfo.job);
    }
    this.sendable = paystubInfo.sendable;
    this.sent = paystubInfo.sent;
    this.draft = !paystubInfo.sent;
    this.sentTime = paystubInfo.sentTime;
    this.sentBy = paystubInfo.sentBy;
    if (paystubInfo.sentTrips && paystubInfo.sentTrips.length) {
      this.sentTrips = paystubInfo.sentTrips.map((trip) => {
        return new CondensedTrip(trip);
      });
    }
    if (paystubInfo.sentPunchCards && paystubInfo.sentPunchCards.length) {
      this.sentPunchCards = paystubInfo.sentPunchCards.map((punchCard) => {
        return new CondensedPunchCard(punchCard);
      });
    }
    this.billFromContactEmail = paystubInfo.billFromContactEmail;
    if (paystubInfo.billFromCcEmails && (typeof paystubInfo.billFromCcEmails === 'object')) {
      this.billFromCcEmails = paystubInfo.billFromCcEmails.join(',');
    }
    this.billFromAddress = paystubInfo.billFromAddress;
    this.billToContactEmail = paystubInfo.billToContactEmail;
    if (!this.billToContactEmail) {
      this.billToContactEmail = this.driver && this.driver.profile && this.driver.profile.email;
    }
    if (paystubInfo.billToCcEmails && (typeof paystubInfo.billToCcEmails === 'object')) {
      this.billToCcEmails = paystubInfo.billToCcEmails.join(',');
    }
    this.billToAddress = paystubInfo.billToAddress;
    if (!this.billToAddress) {
      let address = [];
      let csz = [];
      address.push(this.driver && this.driver.street);
      csz.push(this.driver && this.driver.city);
      let state = this.driver && this.driver.state;
      if (state) { csz.push(state + ','); }
      csz.push(this.driver && this.driver.zipcode);
      address.push(csz.filter(Boolean).join(' '));

      this.billToAddress = address.filter(Boolean).join('\n');
    }
    this.createdAt = paystubInfo.createdAt;
    this.remittanceInfo = paystubInfo.remittanceInfo;

    this.pdf = paystubInfo.pdf;
    this.html = paystubInfo.html;
    this.earliestBillableItemTime = paystubInfo.earliestBillableItemTime;
    this.latestBillableItemTime = paystubInfo.latestBillableItemTime;
    this.paymentType = paystubInfo.paymentType;
  }

  get administrativeStatus(): string {
    if (this.sent) {
      return 'paid';
    } else if (this.draft) {
      return 'draft';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus &&
        this.administrativeStatus.length &&
        this.administrativeStatus !== 'paid' &&
        this.administrativeStatus !== 'draft'
      ) {
      return true;
    } else {
      return false;
    }
  }

  get paystubPeriod(): string {
    let values = [];
    if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
      let _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_earliestBillableItemTime);
    }
    if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
      let _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
      values.push(_latestBillableItemTime);
    }

    if (!values.length) { values.push(this.shortTitle + ' ' + this.number); }

    return values.filter(Boolean).join(' - ');
  }

  get shortTitle(): string {
    if (this.draft) {
      return 'Paystub Draft ';
    } else {
      return 'Paid Paystub ';
    }
  }

  get title(): string {
    return 'Paystubs | ' + this.driver && this.driver.name;
  }

  url(action): string {
    if (action === 'view') {
      return this.html;
    } else if (action === 'download') {
      return this.pdf;
    } else {
      return '/paystubs/' + this.id + '/' + action;
    }
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.administrativeStatus === 'draft') {
      let _option = find(options, { action: 'view' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'download' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'unlock' });
      if (_option) { remove(options, _option); }
    }

    if (this.administrativeStatus === 'paid') {
      let _option = find(options, { action: 'edit' });
      if (_option) { remove(options, _option); }
      _option = find(options, { action: 'mark_as_paid' });
      if (_option) { remove(options, _option); }
    }

    if (!this.pdf) {
      let _option = find(options, { action: 'download' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
