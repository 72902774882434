<div class="applied-filters-wrapper" *ngIf="filters && filters.length && hasVisibleFilters || search && search.length || primaryFilters && primaryFilters.length">
  <div class="filter" *ngFor="let filter of filters" [ngClass]="{hidden: filter.hidden}">
    <ng-container *ngIf="filter.onlyName">
      <span >{{filter.title}}</span>
    </ng-container>
    <ng-container *ngIf="!filter.onlyName">
      <span class="label">{{ filter.title }}:&nbsp;</span> 
      <ng-container *ngIf="filter.filterType !== 'date' && filter.displayValues && filter.displayValues.length">
        {{ getFilterText(filter) }}
      </ng-container>
      <ng-container *ngIf="filter.filterType === 'date' && filter.displayValues && filter.displayValues.length">
        {{ filter.displayValues && filter.displayValues | date:'mediumDate' | join:', ' }}
      </ng-container>
      <ng-container *ngIf="!filter.displayValues || !filter.displayValues.length">
        {{ filter.values && filter.values | join:', ' }}
      </ng-container>
    </ng-container>
    <a mat-icon-button class="remove" color="accent" (click)="remove(filter)">
      <mat-icon>add_circle</mat-icon>
    </a>
  </div>
  <!-- primary filters -->
  <div class="filter" *ngFor="let filter of primaryFilters">
    <span class="label">{{ (filter.name || filter.key) | titleCase }}:&nbsp;</span> 
    {{ filter.value }}
    <a mat-icon-button class="remove" color="accent" (click)="removePrimaryFilter(filter)">
      <mat-icon>add_circle</mat-icon>
    </a>
  </div>
  <div class="filter" *ngIf="search && search.length">
    <span class="label">{{ 'Search' | translate }}:&nbsp;</span> {{ search }} 
    <a mat-icon-button class="remove" color="accent" (click)="clearSearch()">
      <mat-icon>add_circle</mat-icon>
    </a>
  </div>
  <div class="filter reset" (click)="reset()">
    <span class="label" translate>Reset</span>
    <a mat-icon-button class="remove" color="accent">
      <mat-icon>add_circle</mat-icon>
    </a>
  </div>
</div>
