"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var authentication_service_1 = require("../shared/authentication.service");
var data_service_1 = require("../shared/data.service");
var upload_service_1 = require("../shared/upload.service");
var invitation_service_1 = require("./invitation.service");
var ab5_questions_dialog_component_1 = require("./ab5-questions/ab5-questions-dialog.component");
var OrganizationInvitationComponent = /** @class */ (function () {
    function OrganizationInvitationComponent(route, router, ngZone, invitationService, uploadService, apiService, dataService, authenticationService, dialog) {
        this.route = route;
        this.router = router;
        this.ngZone = ngZone;
        this.invitationService = invitationService;
        this.uploadService = uploadService;
        this.apiService = apiService;
        this.dataService = dataService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.loading = false;
        this.documentLoading = false;
        this.initializing = false;
        this.onboardingType = 'dependent';
        this.ab5 = false;
        this.errors = [];
        this.orgCreateStep = 0;
        this.inviteData = {
            organization: {},
            carrier: {},
            profile: {},
            data: {}
        };
        this.customOrgInfo = {};
        this.organization = {};
        this.profile = {};
        this.inviteList = [];
        this.states = [];
    }
    OrganizationInvitationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.states = this.apiService.getStates();
        this.route.params.forEach(function (params) {
            _this.inviteCode = params['invite_code'];
            if (params['step'] && params['step'].length) {
                _this.orgCreateStep = +params['step'];
            }
            else {
                _this.orgCreateStep = 0;
            }
            _this.inviteList.length = 2;
            _this.getInvitation();
        });
    };
    OrganizationInvitationComponent.prototype.getInvitation = function () {
        var _this = this;
        this.invitationService.getInvitation(this.inviteCode).subscribe(function (invitation) {
            // invitation = camelcaseKeysDeep(invitation);
            _this.invitation = invitation;
            _this.inviteData.profile.email = _this.invitation.recipientEmail;
            if (_this.invitation.status === 'pending') {
                _this.authenticationService.logout();
            }
            if (_this.invitation.data && !lodash_1.isEmpty(_this.invitation.data.organization)) {
                _this.inviteData.organization = _this.invitation.data.organization;
                if (!lodash_1.isEmpty(_this.invitation.data.carrier) || !lodash_1.isEmpty(_this.invitation.data.data) || _this.orgCreateStep > 2) {
                    _this.inviteData.data = _this.invitation.data;
                    if (_this.invitation.data.profile && _this.invitation.data.profile.title) {
                        _this.inviteData.profile = _this.invitation.data.profile;
                        _this.orgCreateStep = 4;
                    }
                    else {
                        _this.orgCreateStep = 3;
                    }
                }
                else {
                    _this.orgCreateStep = 2;
                }
            }
            _this.router.navigate(['/create-organization', _this.invitation.id, _this.orgCreateStep]);
            _this.loading = false;
        }, function (err) {
            if (err.indexOf('Not found.', 0) > -1) {
                _this.orgCreateStep = 1;
            }
            _this.loading = false;
            if (!_this.authenticationService.isLoggedIn()) {
                _this.orgCreateStep = 0;
                _this.errors = api_service_1.parseErrors(err);
            }
        });
    };
    OrganizationInvitationComponent.prototype.nextStep = function () {
        if (this.orgCreateStep !== 4) {
            this.orgCreateStep += 1;
            this.router.navigate(['/create-organization', this.invitation.id, this.orgCreateStep]);
        }
        else {
            this.router.navigate(['/daily']);
        }
    };
    OrganizationInvitationComponent.prototype.saveOrgDetails = function () {
        var _this = this;
        if (this.invitation) {
            this.invitationService.updateInvitation(this.invitation.id, { data: this.inviteDataMapped(this.inviteData) }).subscribe(function (result) {
                _this.nextStep();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    OrganizationInvitationComponent.prototype.inviteDataMapped = function (data) {
        if (data.organization) {
            this.inviteData.data.motorCarrierNumber = data.organization.mcNumber;
            this.inviteData.carrier.dotNumber = data.organization.dotNumber;
        }
        return this.inviteData;
    };
    OrganizationInvitationComponent.prototype.saveOrgProfile = function () {
        if (this.validatePassword(this.inviteData.profile.password, this.inviteData.profile.passwordConfirmation)) {
            this.saveOrgDetails();
        }
    };
    OrganizationInvitationComponent.prototype.validatePassword = function (password, passwordRepeat) {
        // password requirements: min 6 characters
        if (password.length < 6) {
            this.errors.push('Passwords must be at least 6 characters.');
            return false;
        }
        else if (password !== passwordRepeat) {
            this.errors.push('The passwords you entered do not match.');
            return false;
        }
        else {
            return true;
        }
    };
    OrganizationInvitationComponent.prototype.submitOrg = function (inviteList) {
        var _this = this;
        if (inviteList === void 0) { inviteList = null; }
        this.initializing = true;
        this.invitationService.registerFromInvitation(this.invitation.id, this.invitation.kind).subscribe(function (res) {
            var user = _this.authenticationService.user();
            if (user) {
                _this.dataService.changeData({ authenticated: true, sidebar: true });
            }
            var orgId = user && user.organization && user.organization.id;
            if (inviteList) {
                inviteList.forEach(function (email) {
                    if (email.length > 0) {
                        _this.invitationService.createInvitation({
                            kind: 'new_user',
                            recipient_organization: orgId,
                            recipient_email: email
                        }).subscribe(function (result) {
                            if (result !== true) {
                                _this.errors = ['Invite for ' + email + ' failed to send!'];
                            }
                        }, function (err) {
                            _this.loading = false;
                            _this.errors = api_service_1.parseErrors(err);
                        });
                    }
                });
            }
            setTimeout(function () {
                _this.initializing = false;
                _this.ngZone.run(function () { return _this.router.navigate(['/daily']); });
            }, 5000);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    OrganizationInvitationComponent.prototype.addInviteField = function () {
        this.inviteList.push('');
    };
    OrganizationInvitationComponent.prototype.inviteTrackBy = function (index, item) {
        return index;
    };
    OrganizationInvitationComponent.prototype.openAB5Questions = function () {
        var _this = this;
        var dialog = this.dialog.open(ab5_questions_dialog_component_1.AB5QuestionsDialogComponent, { width: '660px' });
        dialog.componentInstance.callback = function () {
            _this.inviteData.organization.isAb5Compliant = true;
        };
    };
    OrganizationInvitationComponent.prototype.setExpirationDate = function (e, type) {
        this.inviteData.organization[type + 'ExpirationDate'] = e[0] && typeof e[0].toISOString === 'function' ? e[0].toISOString() : e[0];
    };
    OrganizationInvitationComponent.prototype.uploadAB5Document = function (type, e) {
        var _this = this;
        if (type === void 0) { type = 'insuranceDocument'; }
        this.documentLoading = true;
        if (e.target['files'] && e.target['files'].length > 0) {
            var documentFile_1 = e.target['files'][0];
            this.uploadService.getS3Policy('s3_documents').subscribe(function (policy) {
                var organization = _this.authenticationService.getOrganization();
                var dateString = moment().format('YYYYMMDD');
                policy['fields']['key'] = "documents/" + organization.id + "/" + type + "-" + dateString + "." + documentFile_1.name.split('.').pop();
                _this.uploadService.uploadToS3(policy, documentFile_1, documentFile_1.type).subscribe(function () {
                    _this.inviteData.organization[type] = environment_1.environment.s3ServerUrl + policy['fields']['key'];
                }, function (err) { return _this.errors.push(err); }, function () { return _this.documentLoading = false; });
            }, function (err) { _this.documentLoading = false; _this.errors.push(err); });
        }
    };
    OrganizationInvitationComponent.prototype.complianceEntered = function () {
        return this.inviteData.organization &&
            !!this.inviteData.organization.dotNumber &&
            !!this.inviteData.organization.mcNumber &&
            !!this.inviteData.organization.dirNumber &&
            !!this.inviteData.organization.einNumber &&
            !!this.inviteData.organization.isAb5Compliant &&
            !!this.inviteData.organization.insuranceDocument &&
            !!this.inviteData.organization.insuranceExpirationDate &&
            !!this.inviteData.organization.overweightPermit &&
            !!this.inviteData.organization.overweightPermitExpirationDate &&
            !!this.inviteData.organization.businessLicense;
    };
    return OrganizationInvitationComponent;
}());
exports.OrganizationInvitationComponent = OrganizationInvitationComponent;
