"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var PaySheetFilter = /** @class */ (function () {
    function PaySheetFilter(filterInfo) {
        filterInfo = camelcaseKeysDeep(filterInfo);
        this.id = filterInfo.id;
        this.name = filterInfo.name;
        if (filterInfo.externalIdentifier) {
            this.name = [
                filterInfo.externalIdentifier, filterInfo.job && filterInfo.job.name
            ].filter(Boolean).join(': ');
        }
        else if (filterInfo.job && filterInfo.job.name) {
            this.name = filterInfo.job && filterInfo.job.name;
        }
    }
    return PaySheetFilter;
}());
exports.PaySheetFilter = PaySheetFilter;
