'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var event_alert_service_1 = require("./event-alert.service");
var EventAlertComponent = /** @class */ (function () {
    function EventAlertComponent(eventAlertService) {
        this.eventAlertService = eventAlertService;
        this.showAlert = false;
    }
    EventAlertComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.eventAlertSub = this.eventAlertService.getAlert().subscribe(function (alert) {
            _this.eventAlert = alert;
            _this.showAlert = true;
        });
    };
    EventAlertComponent.prototype.ngOnDestroy = function () {
        if (this.eventAlertSub && typeof this.eventAlertSub.unsubscribe === 'function') {
            this.eventAlertSub.unsubscribe();
        }
    };
    EventAlertComponent.prototype.onClick = function () {
        this.eventAlertService.click();
        this.showAlert = false;
    };
    return EventAlertComponent;
}());
exports.EventAlertComponent = EventAlertComponent;
