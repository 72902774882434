"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/sidebar/sidebar.component.ngfactory");
var i3 = require("./shared/sidebar/sidebar.component");
var i4 = require("./shared/authentication.service");
var i5 = require("@ngx-translate/core");
var i6 = require("./shared/mobile-sidebar/mobile-sidebar.component.ngfactory");
var i7 = require("./shared/mobile-sidebar/mobile-sidebar.component");
var i8 = require("@angular/common");
var i9 = require("@angular/flex-layout/extended");
var i10 = require("@angular/flex-layout/core");
var i11 = require("@angular/router");
var i12 = require("./app.component");
var i13 = require("./shared/data.service");
var i14 = require("ngx-device-detector");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-sidebar", [], null, [[null, "expandedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandedChange" === en)) {
        var pd_0 = ((_co.expanded = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SidebarComponent_0, i2.RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [i4.AuthenticationService, i5.TranslateService], null, { expandedChange: "expandedChange" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-sidebar", [], null, [[null, "sidebarOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sidebarOpenChange" === en)) {
        var pd_0 = ((_co.sidebarOpen = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MobileSidebarComponent_0, i6.RenderType_MobileSidebarComponent)), i1.ɵdid(1, 114688, null, 0, i7.MobileSidebarComponent, [i4.AuthenticationService], null, { sidebarOpenChange: "sidebarOpenChange" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "sidebar-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { expanded: 0 }), i1.ɵdid(4, 933888, null, 0, i9.DefaultClassDirective, [i1.ElementRef, i10.StyleUtils, i10.MediaMarshaller, i8.ɵNgClassImpl, [6, i8.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { expanded: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sidebar-container"; var currVal_1 = _ck(_v, 3, 0, _co.expanded); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _co.expanded); var currVal_3 = "sidebar-container"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = (((_co.data && _co.data.device) && !_co.data.device.mobile) || !_co.data); _ck(_v, 7, 0, currVal_4); var currVal_5 = ((_co.data && _co.data.device) && _co.data.device.mobile); _ck(_v, 9, 0, currVal_5); }, null); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "app"]], null, null, null, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "sidebar-open": 0, "mobile": 1, "tablet": 2, "desktop": 3 }), i1.ɵdid(4, 933888, null, 0, i9.DefaultClassDirective, [i1.ElementRef, i10.StyleUtils, i10.MediaMarshaller, i8.ɵNgClassImpl, [6, i8.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "sidebar-open": 0, "mobile": 1, "tablet": 2, "desktop": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "main-component-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.sidebarOpen = false;
        var pd_0 = (true !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i11.RouterOutlet, [i11.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app"; var currVal_1 = _ck(_v, 3, 0, (_co.sidebarOpen && _co.loggedIn), ((_co.data && _co.data.device) && _co.data.device.mobile), ((_co.data && _co.data.device) && _co.data.device.tablet), (((_co.data && _co.data.device) && _co.data.device.desktop) || !_co.data)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.sidebarOpen && _co.loggedIn), ((_co.data && _co.data.device) && _co.data.device.mobile), ((_co.data && _co.data.device) && _co.data.device.tablet), (((_co.data && _co.data.device) && _co.data.device.desktop) || !_co.data)); var currVal_3 = "app"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = (_co.loggedIn && _co.displaySidebar); _ck(_v, 7, 0, currVal_4); _ck(_v, 10, 0); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i12.AppComponent, [i11.Router, i13.DataService, i14.DeviceDetectorService, i4.AuthenticationService, i5.TranslateService], null, null)], null, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("my-app", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
