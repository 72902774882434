import * as moment from 'moment';
import { ShiftReport } from './shift-report';
import { DriverSerializer } from '../drivers/driver.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ShiftReportSerializer {

  /**
  * @param  {any} json
  * @returns {ShiftReport} ShiftReport
  */
  fromJson(json: any): ShiftReport {
    json = camelcaseKeysDeep(json);
    const shift = new ShiftReport();
    if (!json) { return shift; }

    shift.id = json.id;
    shift.driver = new DriverSerializer().fromJson(json.driver);
    shift.truckAssignments = json.truckAssignments;
    shift.startTime = json.startTime;
    shift.endTime = json.endTime;
    // json.adjustment is returned as `00.0`, we'll convert this to minutes
    shift.adjustment = Number(json.adjustment) / 60;
    shift.breakDowns = json.breakDowns;
    shift.lunchBreaks = json.lunchBreaks;
    shift.shiftCheckups = json.shiftCheckups;
    shift.autoClosed = json.autoClosed;

    return shift;
  }

  /**
   * @param  {ShiftReport} ShiftReport
   * @returns any
   */
  toJson(shift: ShiftReport): any {
    let json = {
      id: shift.id,
      driver: shift.driver,
      truckAssignments: shift.truckAssignments,
      startTime: shift.startTime,
      endTime: shift.endTime,
      // need to format our seconds into the server's expected `00.0` format
      adjustment: (shift.adjustment * 60) + '.0',
      breakDowns: shift.breakDowns,
      lunchBreaks: shift.lunchBreaks,
      shiftCheckups: shift.shiftCheckups
    };
    return decamelizeKeysDeep(json);
  }
}
