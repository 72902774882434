import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, remove, difference, get, find as _find } from 'lodash';

import { PaystubService } from  './paystub.service';
import { PaystubFiltersDialogComponent } from './paystub-filters-dialog.component';
import { PaystubPayTypeDialogComponent } from './paystub-pay-type-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';
import { ExportDialogData, ExportDialogComponent } from '../shared/export-dialog/export-dialog.component';
import { DriverContextEvent } from '../drivers/driver-context-menu/interfaces/driver-context-event';
import { Subject } from 'rxjs';

@Component({
  selector: 'ruckit-paystubs',
  templateUrl: './paystubs.component.html',
  styleUrls: ['./paystubs.component.scss'],
  providers: [PaystubService]
})
export class PaystubsComponent implements OnInit {
  paystubs: any = [];
  loading = true;
  errors = [];
  paystubsReq;
  search = '';
  sortBy;
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  selectedPaystub;
  confirmDialog: MatDialogRef<any>;
  paymentTypeDialog: MatDialogRef<any>;
  count = 0;
  selectedCount = {
    draft: 0,
    paid: 0
  };
  allSelected = {
    draft: false,
    paid: false
  };
  selectedPaystubs = {
    draft: [],
    paid: []
  };
  excludePaystubs = {
    draft: [],
    paid: []
  };
  state = 'draft';
  dropdownOptions = {
    draft: [
      { name: 'Export', button: true }
    ],
    paid: [
      { name: 'Export', button: true },
      { name: 'Download', button: true}
    ]
  };
  menuOptions = [
    { name: 'Edit', action: 'edit', link: true, external: false },
    { name: 'Mark as Paid', action: 'mark_as_paid', link: false, external: false },
    { name: 'View', action: 'view', link: true, external: true },
    { name: 'Unlock', action: 'unlock', link: false, external: false },
    { name: 'Download', action: 'download', link: true, external: true },
    { name: 'Export', action: 'export', link: false, external: false },
  ];
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paystubService: PaystubService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
      let mock = params['mock'];
      this.search = params['search'] || '';

      if (mock) {
        this.paystubs = this.paystubService.getMockPaystubs(10);
        this.loading = false;
      } else {
        this.sortBy = params.sortBy ? params.sortBy : 'created_at';
        this.sortAsc = params.sortAsc ? (params.sortAsc === 'true') : false;
        this.getPaystubs();
      }
    });

    this.route.params.subscribe((params: Params) => {
      this.state = params['state'];
      this.getPaystubs();
    });
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.paystubService.getNext();
      if (o) {
        this.loading = true;
        o.subscribe(
          paystubs => {
            this.paystubs = this.paystubs.concat(paystubs);
            this.loading = false;
          },
          err => this.errors = parseErrors(err),
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getPaystubs(query = {}, append = false) {
    if (!append) {
      this.paystubs = [];
    }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return {...acc, ...filter.query};
    }, {});

    if (this.paystubsReq) {
      this.paystubsReq.unsubscribe();
    }

    this.paystubsReq = this.paystubService.getPaystubs({
      ordering: order,
      search: this.search,
      sent: this.state === 'draft' ? 'False' : 'True',
      ...filters,
      ...query
    }).subscribe(
      paystubs => {
        if (append) {
          this.paystubs = this.paystubs.concat(paystubs);
        } else {
          this.paystubs = paystubs;
        }
        this.count = this.paystubService.count;
        this.loading = false;
      },
      err => this.errors = parseErrors(err),
      () => {
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortBy: this.sortBy,
        sortAsc: this.sortAsc
      }
    });
    this.loading = true;
    this.getPaystubs({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getPaystubs({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  changeSearch(term?: string) {
    this.search = term || '';
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['search'] = this.search;
    this.router.navigate(['paystubs', 'filter', this.state], { queryParams: queryParams });

    this.getPaystubs();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters() {
    const dialog = this.dialog.open(PaystubFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);

    let startDate = get(_find(this.filters, {key: 'startDate'}), 'value');
    if (startDate && startDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.startDate = startDate;
    }
    let endDate = get(_find(this.filters, {key: 'endDate'}), 'value');
    if (endDate && endDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.endDate = endDate;
    }
    dialog.componentInstance.model.job = get(_find(this.filters, {key: 'job'}), 'value');
    dialog.componentInstance.model.customer = get(_find(this.filters, {key: 'customer'}), 'value');

    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'customer_organization',
      job: 'job',
      sent: 'sent'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
          let filterValue = value.toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let filter = {
        key: key,
        value: displayValue || value,
        query: query
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });

    this.filters = difference(this.filters, falseyFilters);
    this.getPaystubs();
  }

  removeFilter(filter) {
    remove(this.filters, filter);
    this.getPaystubs();
  }

  menuAction(action, paystub) {
    switch (action) {
      case 'mark_as_paid':
        this.sendPaystub(paystub);
        break;
      case 'export':
        this.createExport([paystub.id]);
        break;
      case 'unlock':
        this.unlockPaystub(paystub);
        break;
    }
  }

  unlockPaystub(paystub) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unlock Paystub?',
      body: 'This paystub will be unlocked and can be modified.',
      close: 'Cancel',
      accept: 'Unlock'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.paystubService.unlock(paystub).subscribe((res) => {
          this.getPaystubs();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  sendPaystub(paystub) {
    if (!paystub.paymentType) {
      this.capturePaymentType(paystub);
    } else {
      this.loading = true;
      this.paystubService.send(paystub).subscribe(() => {
        paystub.sent = true;
        this.loading = false;
        this.getPaystubs();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  capturePaymentType(paystub) {
    this.paymentTypeDialog = this.dialog.open(PaystubPayTypeDialogComponent, {
      width: '430px'
    });
    this.paymentTypeDialog.componentInstance.paystub = paystub;
    this.paymentTypeDialog.componentInstance.callback = res => {
      paystub.paymentType = res.paymentType;
      this.sendPaystub(paystub);
    };
  }

  createExport(paystubs, section = null) {
    let scope = {};
    let params = new HttpParams();

    if (paystubs && paystubs.length) {
      Object.assign(scope, { paystubs: paystubs });
    } else if (this.allSelected) {
      Object.assign(scope, { excludePaystubs: this.excludePaystubs[section] });
      Object.keys(this.filters).map(key => {
        if (this.filters[key]) {
          let query = this.filters[key]['query'];
          Object.keys(query).map(queryKey => {
            params = params.set(queryKey, query[queryKey]);
          });
        }
      });
      params = params.set('search', this.search);
      params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
    }

    this.dialog.open(ExportDialogComponent, {
      width: '430px',
      data: <ExportDialogData>{
        type: 'paystubs',
        buttonText: 'Export Data to CSV',
        params: params,
        scope: scope,
        service: this.paystubService
      }
    });
  }

  createDownload(paystubs, section = null) {
    let scope = {};
    let params = new HttpParams();

    if (paystubs && paystubs.length) {
      Object.assign(scope, { paystubs: paystubs });
    } else if (this.allSelected) {
      Object.assign(scope, { excludePaystubs: this.excludePaystubs[section] });
      Object.keys(this.filters).map(key => {
        if (this.filters[key]) {
          let query = this.filters[key]['query'];
          Object.keys(query).map(queryKey => {
            params = params.set(queryKey, query[queryKey]);
          });
        }
      });
      params = params.set('search', this.search);
      params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
    }
    this.paystubService.downloadPdfs(scope, params).subscribe(() => {
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '430px',
        height: '250px'
      });
      this.confirmDialog.componentInstance.attributes = {
        title: 'Download Link Sent',
        body: 'Your zip file with the selected paystub data is on its way to your inbox',
        close: 'Close',
        accept: null
      };
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selector(event, section = 'draft', paystub = null) {
    if (paystub) {
      if (!event.target.checked) {
        paystub.selected = false;
        pull(this.selectedPaystubs[section], paystub.id);
        if (this.allSelected) {
          this.excludePaystubs[section].push(paystub.id);
          this.selectedCount[section] = (this.count - this.excludePaystubs[section].length);
        } else {
          this.selectedCount[section] = this.selectedPaystubs[section].length;
        }
      } else {
        paystub.selected = true;
        if (this.allSelected[section]) {
          pull(this.excludePaystubs[section], paystub.id);
          this.selectedCount[section] = (this.count - this.excludePaystubs[section].length);
        } else {
          this.selectedPaystubs[section].push(paystub.id);
          this.selectedCount[section] = this.selectedPaystubs[section].length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected[section] = false;
        this.paystubs.forEach((_paystub) => { _paystub.selected = false; });
        this.selectedCount[section] = 0;
      } else {
        this.allSelected[section] = true;
        this.selectedCount[section] = (this.count - this.excludePaystubs[section].length);
      }
      this.selectedPaystubs[section] = [];
      this.excludePaystubs[section] = [];
    }
  }

  setSelectedAction(option, section) {
    switch (option.name) {
      case 'Export':
        this.createExport(this.selectedPaystubs[section], section);
        break;
      case 'Download':
        this.createDownload(this.selectedPaystubs[section], section);
        break;
    }
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }
}
