<form #filterTickets="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Filter Tickets</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group truck" fxFlex="100">
          <label translate>Trucks</label>
          <dropdown #trucksDropdown [options]="trucks" (onSelect)="onSelect('truck', $event)" [config]="trucksConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'truck')" (nextPage)="dropdownNextPage($event, 'truck')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group customer-name" fxFlex="100">
          <label translate>Customer</label>
          <dropdown #customersDropdown [options]="customers" (onSelect)="onSelect('customer', $event)" [config]="customersConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'customer')" (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group driver" fxFlex="100">
          <label translate>Order</label>
          <dropdown #ordersDropdown [options]="purchaseOrders" (onSelect)="onSelect('order', $event)" [config]="ordersConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'order')" (nextPage)="dropdownNextPage($event, 'order')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group location" fxFlex="100">
          <label translate>Location</label>
          <dropdown #locationsDropdown [options]="locations" (onSelect)="onSelect('location', $event)" [config]="locationsConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'location')" (nextPage)="dropdownNextPage($event, 'location')"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group product" fxFlex="100">
          <label translate>Products</label>
          <dropdown #productsDropdown [options]="products" (onSelect)="onSelect('product', $event)" [config]="productsConfig"
            title="&ndash;" (onSearch)="dropdownSearch($event, 'product')" (nextPage)="dropdownNextPage($event, 'product')"></dropdown>
        </div>
      </div>



      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group ticket-state" fxFlex="50">
          <label attr.data-label="{{ 'Voided' | translate }}" class="toggle-label">
            <input type="checkbox" name="ticket_state[void]" value="void" checked="{model.void}" [(ngModel)]="model.void" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="field-group ticket-state" fxFlex="50">
          <label attr.data-label="{{ 'On Hold' | translate }}" class="toggle-label">
            <input type="checkbox" name="ticket_state[on_hold]" value="on-hold" checked="{model.onHold}" [(ngModel)]="model.onHold"
              class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group ticket-state" fxFlex="50">
          <label attr.data-label="{{ 'In Progress' | translate }}" class="toggle-label">
            <input type="checkbox" name="ticket_state[in_progress]" value="in-progress" checked="{model.inProgress}"
              [(ngModel)]="model.inProgress" class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="field-group ticket-state" fxFlex="50">
          <label attr.data-label="{{ 'Completed' | translate }}" class="toggle-label">
            <input type="checkbox" name="ticket_state[completed]" value="complete" checked="{model.completed}" [(ngModel)]="model.completed"
              class="toggle-input">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false" translate>
      Filter Results
    </button>
  </div>
</form>