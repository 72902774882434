import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { first } from 'rxjs/operators';

import { CondensedTrip } from '../../trips/condensed-trip';
import { JobEvent } from '../../job-events/job-event';
import { OrderService } from '../order.service';
import { parseErrors, AuthenticationService } from '../../shared';
import { TicketReviewDialogComponent } from '../ticket-review-dialog/ticket-review-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'ticket-detail-dialog',
  templateUrl: './ticket-detail-dialog.component.html',
  styleUrls: ['../order.scss']
})

export class TicketDetailDialogComponent implements OnInit {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  enabledFeatures: string[] = [];
  loading = false;
  errors = [];
  statusMode = false;
  tabs = ['Ticket Details', 'Ticket Images'];
  activeTab = this.tabs[0];
  callback: (ticket: CondensedTrip) => void;

  constructor(
    public orderService: OrderService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TicketDetailDialogComponent>,
    private deviceDetectorService: DeviceDetectorService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: { ticket: CondensedTrip, jobEvent: JobEvent }
  ) {}

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.enabledFeatures = this.authenticationService.enabledFeatures();
  }

  close() {
    this.callback(this.data.ticket);
    this.dialogRef.close();
  }

  accept() {
    this.orderService.tripService
      .approveTicket(this.data.ticket.id)
      .pipe(first())
      .subscribe((ticket) => {
        this.data.ticket.reviewedStatus = ticket.reviewedStatus;
        this.callback(this.data.ticket);
        this.dialogRef.close();
      }, err => this.errors = parseErrors(err));
  }

  reject() {
    this.orderService.tripService
      .rejectTicket(this.data.ticket.id)
      .pipe(first())
      .subscribe((ticket) => {
        this.data.ticket.reviewedStatus = ticket.reviewedStatus;
        this.callback(this.data.ticket);
        this.dialogRef.close();
      }, err => this.errors = parseErrors(err));
  }

  openTicketReview() {
    const dialog = this.dialog.open(TicketReviewDialogComponent, {
      width: this.device.mobile ? '320px' : '480px',
      data: {
        ticket: this.data.ticket
      }
    });
    dialog.componentInstance.callback = (ticket: CondensedTrip) => { this.data.ticket = ticket; };
  }
}
