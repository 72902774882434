import { forkJoin as observableForkJoin } from 'rxjs';
import {
  Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, omit as _omit } from 'lodash';
import * as moment from 'moment';

import { TripService } from  '../trips/trip.service';
import { InvoiceService } from  '../invoices/invoice.service';
import { InvoiceTripsDialogComponent } from './invoice-trips-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'ruckit-sent-trips',
  templateUrl: './sent-trips.component.html',
  styleUrls: ['./sent-trips.component.scss'],
  providers: [TripService, InvoiceService]
})
export class SentTripsComponent implements OnInit, OnChanges {
  @Input() invoice;
  @Input() search = '';
  @Output() updateInvoice = new EventEmitter<boolean>();
  count;
  selectedCount = 0;
  trips: any = [];
  loading = true;
  errors = [];
  tripReq;
  invoiceReq;
  page = 1;
  sortBy: string;
  sortAsc = true;
  sortParameter: string;
  allSelected = false;
  selectedTrips = [];
  excludeTrips = [];
  tripFilters;
  checkingDuplicates = false;
  confirmDialog: MatDialogRef<any>;
  multipleActionDropdownOptions = [
    {name: 'Remove from Invoice', action: 'remove', link: false},
    {name: 'Void', action: 'void', link: false}
  ];
  menuOptions = [
    {name: 'Edit', action: 'edit', link: true},
    {name: 'Remove from Invoice', action: 'remove', link: false},
    {name: 'Void', action: 'void', link: false}
  ];
  addTripsCallback = (e) => {
    this.updateInvoice.emit(true);
    this.getTrips();
  }
  viewTicketsCallback = (e) => {
    // Update Ticket Status Icon
  }
  viewSignaturesCallback = () => {
    // Update Signature Status Icon
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tripService: TripService,
    private invoiceService: InvoiceService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.forEach((params: Params) => {
      this.sortBy = params.sortTripsBy ? params.sortTripsBy : 'start_time';
      this.sortAsc = params.sortTripsAsc ? (params.sortTripsAsc === 'true') : false;
      this.sortParameter = params.sortTripsParameter ? params.sortTripsParameter : null;
      this.page = params.sortTripsPage ? params.sortTripsPage : 1;
    });
    this.getTrips({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  ngOnChanges(changes: { [search: string]: SimpleChange }) {
    for (let propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let chng = changes[propName];
        if (propName === 'search') { this.search = chng.currentValue; }
      }
    }

    this.changeSearch(this.search);
  }

  pageChange(event) {
    this.page = event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortTripsPage: this.page > 1 ? this.page : null
      }
    });
    this.getTrips({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  getTrips(query = {}, append = false) {
    if (!append) { this.trips = []; }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    if (this.tripReq) { this.tripReq.unsubscribe(); }

    this.tripFilters = {
      sort: order,
      search: this.search,
      invoice: this.invoice.id,
      ...query
    };

    if (this.checkingDuplicates) { this.tripFilters['only_dupes'] = 'True'; }

    this.tripReq = this.tripService.getCondensedTrips(this.tripFilters).subscribe(
      trips => {
        if (append) {
          this.trips = this.trips.concat(trips);
        } else {
          this.trips = trips;
        }
        this.count = this.tripService.count;
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) { this.sortAsc = !this.sortAsc; }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortTripsBy: this.sortBy,
        sortTripsAsc: this.sortAsc,
        sortTripsParameter: null
      }
    });
    this.loading = true;
    this.getTrips({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      page: this.page
    });
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortTripsBy: this.sortBy,
        sortTripsAsc: this.sortAsc,
        sortTripsParameter: this.sortParameter
      }
    });
    this.loading = true;
    this.getTrips({
      [this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy,
      page: this.page
    });
  }

  openAddTrip() {
    const dialog = this.dialog.open(InvoiceTripsDialogComponent, {
      width: '100%',
      height: '100%',
      disableClose: true
    });
    dialog.componentInstance.invoice = this.invoice;
    dialog.componentInstance.customerId = this.invoice.customerOrganization.id;
    dialog.componentInstance.customer = this.invoice.customerOrganization;
    dialog.componentInstance.routeToInvoice = false;
    dialog.componentInstance.callback = this.addTripsCallback;
  }

  removeSelectedTrips(trips = null) {
    let model = {id: this.invoice.id};
    if (trips || this.selectedTrips.length) {
      Object.assign(model, {trips: trips || this.selectedTrips});
    } else if (this.allSelected) {
      let params = new HttpParams();
      let filters = _omit(this.tripFilters, ['ordering', 'invoice']);
      if (filters) {
        Object.keys(filters).map(key => {
          if (filters[key] && filters[key].length) {
            params = params.set(key, filters[key]);
          }
        });
      }
      if (params.toString().length) {
        Object.assign(model, { filters: params.toString() });
      }
      Object.assign(model, { excludeTrips: this.excludeTrips });
    }

    this.invoiceReq = this.invoiceService.removeFromInvoice(model, 'trips').subscribe((res) => {
      this.invoice = res;
      this.search = '';
      this.allSelected = false;
      this.selectedCount = 0;
      this.selectedTrips = [];
      this.updateInvoice.emit(true);
      this.getTrips();
    }, err => console.error(err));
  }

  voidSelectedTrips(trips = null) {
    trips = trips ? trips : this.selectedTrips;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Trips?',
      body: 'These trips will be marked as \'Void\' and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        let observableBatch = [];

        trips.forEach((trip) => {
          observableBatch.push(this.tripService.save({ id: trip, void: true }));
        });

        observableForkJoin(observableBatch).subscribe((result) => {
          this.loading = false;
          this.getTrips();
          this.updateInvoice.emit(true);
        }, (err) => {
          this.errors = this.errors.concat(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.getTrips();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  menuAction(name, trip) {
    switch (name) {
      case 'remove':
        trip ? this.removeSelectedTrips([trip.id]) : this.removeSelectedTrips();
        break;
      case 'void':
        trip ? this.voidSelectedTrips([trip.id]) : this.voidSelectedTrips();
        break;
    }
  }

  editTrip(trip) {
    this.router.navigate(['/trips/' + trip.id + '/edit'], {
      queryParams: { returnTo: '/invoices/' + this.invoice.id + '/edit' }
    });
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }

  duplicateCheck() {
    this.getTrips();
  }

  selector(event, trip = null) {
    if (trip) {
      if (!event.target.checked) {
        trip.selected = false;
        pull(this.selectedTrips, trip.id);
        if (this.allSelected) {
          this.excludeTrips.push(trip.id);
          this.selectedCount = (this.count - this.excludeTrips.length);
        } else {
          this.selectedCount = this.selectedTrips.length;
        }
      } else {
        trip.selected = true;
        if (this.allSelected) {
          pull(this.excludeTrips, trip.id);
          this.selectedCount = (this.count - this.excludeTrips.length);
        } else {
          this.selectedTrips.push(trip.id);
          this.selectedCount = this.selectedTrips.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.trips.forEach((_trip) => { _trip.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludeTrips.length);
      }
      this.selectedTrips = [];
      this.excludeTrips = [];
    }
  }
}
