import * as moment from 'moment-timezone';
import { ImageResizer } from '../images/resizer';
import { remove, find, clone } from 'lodash';
import { Driver } from '../drivers/driver';
import { DriverSerializer } from './../drivers/driver.serializer';
import { Checkin } from '../checkins/checkin';
import { User } from '../users/user';
import { Attachment } from '../attachments/attachment.interface';
import { TRIPSIGNATUREIMAGETYPE, TRIPTICKETIMAGETYPE } from '../app.constants';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class CondensedTrip {
  id: string;
  name: string;
  date: string;
  jobDate: string;
  job: string;
  jobDisplayName: string;
  jobId: string;
  jobEventId: string;
  phaseCode: string;
  project: string;
  projectId: string;
  customer: string;
  customerId: string;
  customerPhone: string;
  driver: string;
  driverId: string;
  driverPhone: string;
  driverImage: string;
  customDriver: Driver;
  truck: string;
  truckId: string;
  truckType: string;
  truckImage: string;
  payableTo: string;
  payableToId: string;
  carrier: string;
  carrierId: string;
  payableToPhone: string;
  loadingTicketNumber: string;
  loadingWeight: string;
  loadingTicketImage: string;
  loadingTicketImageKey: string;
  loadingSignature: string;
  loadingSignatureKey: string;
  unloadingTicketNumber: string;
  unloadingWeight: string;
  unloadingTicketImage: string;
  unloadingTicketImageKey: string;
  unloadingSignature: string;
  unloadingSignatureKey: string;
  _startTimeTimestamp: string;
  _endTimeTimestamp: string;
  invoiceRate: string;
  invoiceType: string;
  invoiceWeightUnit: string;
  invoiceQuantity: string;
  haulRate: string;
  haulType: string;
  haulWeightUnit: string;
  routePolyline: string;
  checkinOptions: any;
  checkoutOptions: any;
  retakeStatus: string;
  edited: boolean;
  completed: boolean;
  tripStatus: string;
  reviewedStatus: 'approved' | 'rejected' | 'pending';
  reviewedBy: User;
  reviewedAt: string;
  selected = false;
  invoiceTotal: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceSent = false;
  haulTotal: string;
  loading = false;
  void: boolean;
  expenseId: string;
  expenseNumber: string;
  expenseSent = false;
  expenseTotal: string;
  paystubId: string;
  paystubNumber: string;
  paystubSent = false;
  weight: string;
  ticketNumber: string;
  timezone: string;
  origin: string;
  loadingCheckin: Checkin;
  unloadingCheckin: Checkin;

  loadingArrivalDatetime: string;
  loadingCompleteDatetime: string;
  unloadingArrivalDatetime: string;
  unloadingCompleteDatetime: string;

  connexPaverStartUnloading: string;
  connexPaverEndUnloading: string;

  loadingAttachments: Attachment[];
  unloadingAttachments: Attachment[];

  // pre-formatted fields for display
  _duration: string;
  _completedTripTime: string;
  _startTime: string;
  _endTime: string;
  _startDate: Date;
  _endDate: Date;
  _loadingCompleteDatetime;
  _unloadingCompleteDatetime;
  _loadingArrivalDatetime;
  _unloadingArrivalDatetime;

  // localized date times
  localizedStartTime: string;
  localizedStartDatetime: string;
  localizedStartDate: string;
  localizedEndTime: string;
  localizedEndDatetime: string;
  localizedEndDate: string;

  // qc/vt fields
  plantQc: number;
  plantQcReviewer: User;
  plantQcWasteAmt: number;
  plantVt: number;
  plantVtReviewer: User;
  plantVtWasteAmt: number;
  roadwayQc: number;
  roadwayQcReviewer: User;
  roadwayQcWasteAmt: number;
  roadwayVt: number;
  roadwayVtReviewer: User;
  roadwayVtWasteAmt: number;

  startLocationId: string;
  startLocationName: string;
  endLocationId: string;
  endLocationName: string;
  geoStartLocationId: string;
  geoStartLocationName: string;
  geoEndLocationId: string;
  geoEndLocationName: string;

  reviewerNotes: string;

  constructor(tripInfo: any) {
    tripInfo = camelcaseKeysDeep(tripInfo);

    this.id = tripInfo.id;
    this.date = tripInfo.date;
    this.jobDate = tripInfo.jobDate;
    this.job = tripInfo.job;
    this.jobDisplayName = tripInfo.jobDisplayName;
    this.jobId = tripInfo.jobId;
    this.jobEventId = tripInfo.jobeventId;
    this.phaseCode = tripInfo.phaseCode;
    this.project = tripInfo.project;
    this.projectId = tripInfo.projectId;
    this.customer = tripInfo.customer;
    this.customerId = tripInfo.customerId;
    this.customerPhone = tripInfo.customerPhone;
    this.driver = tripInfo.driver;
    this.driverId = tripInfo.driverId;
    this.driverPhone = tripInfo.driverPhone;
    this.driverImage = tripInfo.driverImage;
    this.customDriver = new DriverSerializer().fromJson({
      id: this.driverId,
      customName: this.driver,
      phoneNumber: this.driverPhone,
      image: this.driverImage,
      carrierName: tripInfo.carrier,
    });
    this.truck = tripInfo.truck;
    this.truckId = tripInfo.truckId;
    this.truckImage = tripInfo.truckImage;
    this.truckType = tripInfo.truckType;
    this.payableTo = tripInfo.payableTo;
    this.payableToId = tripInfo.payableToId;
    this.carrier = tripInfo.carrier;
    this.carrierId = tripInfo.carrierId;
    this.payableToPhone = tripInfo.payableToPhone;
    this.loadingTicketNumber = tripInfo.loadingTicketNumber;
    this.loadingWeight = tripInfo.loadingWeight;
    this.loadingTicketImage = tripInfo.loadingTicketImage;
    this.loadingTicketImageKey = tripInfo.loadingTicketImageKey;
    this.loadingSignature = tripInfo.loadingSignature;
    this.loadingSignatureKey = tripInfo.loadingSignatureKey;
    this.unloadingTicketNumber = tripInfo.unloadingTicketNumber;
    this.unloadingWeight = tripInfo.unloadingWeight;
    this.unloadingTicketImage = tripInfo.unloadingTicketImage;
    this.unloadingTicketImageKey = tripInfo.unloadingTicketImageKey;
    this.unloadingSignature = tripInfo.unloadingSignature;
    this.unloadingSignatureKey = tripInfo.unloadingSignatureKey;
    this.startTimeTimestamp = tripInfo.startTime;
    this.endTimeTimestamp = tripInfo.endTime;
    this.invoiceRate = tripInfo.invoiceRate;
    this.invoiceType = tripInfo.invoiceType;
    this.invoiceWeightUnit = tripInfo.invoiceWeightUnit || 'ton';
    this.invoiceQuantity = tripInfo.invoiceQuantity;
    this.haulRate = tripInfo.haulRate;
    this.haulType = tripInfo.haulType;
    this.haulWeightUnit = tripInfo.haulWeightUnit || 'ton';
    this.routePolyline = tripInfo.routePolyline;
    this.retakeStatus = tripInfo.retakeStatus;
    this.edited = tripInfo.edited;
    this.completed = tripInfo.completed;
    this.tripStatus = tripInfo.tripStatus;
    this.reviewedStatus = tripInfo.reviewedStatus;
    this.reviewedBy = tripInfo.reviewedBy;
    this.reviewedAt = tripInfo.reviewedAt;
    this.checkinOptions = tripInfo.checkinOptions;
    this.checkoutOptions = tripInfo.checkoutOptions;
    this.invoiceTotal = tripInfo.invoiceTotal;
    this.haulTotal = tripInfo.haulTotal;
    this.void = tripInfo.void;
    this.expenseId = tripInfo.expenseId;
    this.expenseNumber = tripInfo.expenseNumber;
    this.expenseSent = tripInfo.expenseSent;
    this.expenseTotal = tripInfo.expenseTotal;
    this.paystubId = tripInfo.paystubId;
    this.paystubNumber = tripInfo.paystubNumber;
    this.paystubSent = tripInfo.paystubSent;
    this.invoiceId = tripInfo.invoiceId;
    this.invoiceNumber = tripInfo.invoiceNumber;
    this.invoiceSent = tripInfo.invoiceSent;
    this.weight = tripInfo.weight;
    this.ticketNumber = tripInfo.ticketNumber;
    this.timezone = tripInfo.timezone || moment.tz.guess() || 'America/Chicago';
    this.origin = tripInfo.origin;
    this.loadingCompleteDatetime = tripInfo.localizedLoadingCompleteDatetime;
    this.unloadingCompleteDatetime =
      tripInfo.localizedUnloadingCompleteDatetime;
    this.loadingAttachments = tripInfo.loadingAttachments || [];
    this.unloadingAttachments = tripInfo.unloadingAttachments || [];
    this.loadingArrivalDatetime = tripInfo.loadingArrivalDatetime;
    this.unloadingArrivalDatetime = tripInfo.unloadingArrivalDatetime;
    this.localizedEndDate = tripInfo.localizedEndDate;
    this.localizedEndDatetime = tripInfo.localizedEndDatetime;
    this.localizedEndTime = tripInfo.localizedEndTime;
    this.localizedStartDate = tripInfo.localizedStartDate;
    this.localizedStartDatetime = tripInfo.localizedStartDatetime;
    this.localizedStartTime = tripInfo.localizedStartTime;
    this.plantQc = tripInfo.plantQc;
    this.plantQcReviewer = tripInfo.plantQcReviewer;
    this.plantQcWasteAmt = tripInfo.plantQcWasteAmt;
    this.plantVt = tripInfo.plantVt;
    this.plantVtReviewer = tripInfo.plantVtReviewer;
    this.plantVtWasteAmt = tripInfo.plantVtWasteAmt;
    this.roadwayQc = tripInfo.roadwayQc;
    this.roadwayQcReviewer = tripInfo.roadwayQcReviewer;
    this.roadwayQcWasteAmt = tripInfo.roadwayQcWasteAmt;
    this.roadwayVt = tripInfo.roadwayVt;
    this.roadwayVtReviewer = tripInfo.roadwayVtReviewer;
    this.roadwayVtWasteAmt = tripInfo.roadwayVtWasteAmt;

    this.startLocationId = tripInfo.startLocationId;
    this.startLocationName = tripInfo.startLocationName;
    this.endLocationId = tripInfo.endLocationId;
    this.endLocationName = tripInfo.endLocationName;
    this.geoStartLocationId = tripInfo.geoStartLocationId;
    this.geoStartLocationName = tripInfo.geoStartLocationName;
    this.geoEndLocationId = tripInfo.geoEndLocationId;
    this.geoEndLocationName = tripInfo.geoEndLocationName;

    this.connexPaverStartUnloading = tripInfo.connexPaverStartUnloading;
    this.connexPaverEndUnloading = tripInfo.connexPaverEndUnloading;

    // multiple images backwards compability
    if (
      this.loadingTicketImage === null &&
      this.loadingAttachments &&
      this.loadingAttachments.length
    ) {
      const attachment = this.loadingAttachments.find(
        (att) => att.fileType === TRIPTICKETIMAGETYPE
      );
      this.loadingTicketImage = attachment ? attachment.file : null;
    }

    if (
      this.unloadingTicketImage === null &&
      this.unloadingAttachments &&
      this.unloadingAttachments.length
    ) {
      const attachment = this.unloadingAttachments.find(
        (att) => att.fileType === TRIPTICKETIMAGETYPE
      );
      this.unloadingTicketImage = attachment ? attachment.file : null;
    }

    if (
      this.loadingTicketImage &&
      this.loadingAttachments &&
      !this.loadingAttachments.length
    ) {
      this.loadingAttachments.push({
        file: this.loadingTicketImage,
        fileType: TRIPTICKETIMAGETYPE,
      });
    }

    if (
      this.unloadingTicketImage &&
      this.unloadingAttachments &&
      !this.unloadingAttachments.length
    ) {
      this.unloadingAttachments.push({
        file: this.unloadingTicketImage,
        fileType: TRIPTICKETIMAGETYPE,
      });
    }

    if (this.loadingSignature && this.loadingAttachments) {
      this.loadingAttachments.push({
        file: this.loadingSignature,
        fileType: TRIPSIGNATUREIMAGETYPE,
      });
    }

    if (this.unloadingSignature && this.unloadingAttachments) {
      this.unloadingAttachments.push({
        file: this.unloadingSignature,
        fileType: TRIPSIGNATUREIMAGETYPE,
      });
    }
    // END multiple images backwards compability

    if (tripInfo.loadingArrivalDatetime) {
      let localizedArrivalLoading = moment
        .utc(tripInfo.loadingArrivalDatetime)
        .tz(this.timezone);
      let duration = moment.duration(
        moment(localizedArrivalLoading).diff(this.startDate)
      );
      this._loadingArrivalDatetime = duration.format(
        'D[ days], H[ hrs], m[ mins]'
      );
    }
    if (tripInfo.loadingCompleteDatetime) {
      let localizedCompleteLoading = moment
        .utc(tripInfo.loadingCompleteDatetime)
        .tz(this.timezone);
      let duration = moment.duration(
        moment(localizedCompleteLoading).diff(tripInfo.loadingArrivalDatetime)
      );
      this._loadingCompleteDatetime = duration.format(
        'D[ days], H[ hrs], m[ mins]'
      );
    }
    if (tripInfo.unloadingArrivalDatetime) {
      let localizedArrivalUnLoading = moment
        .utc(tripInfo.unloadingArrivalDatetime)
        .tz(this.timezone);
      let duration = moment.duration(
        moment(localizedArrivalUnLoading).diff(tripInfo.loadingCompleteDatetime)
      );
      this._unloadingArrivalDatetime = duration.format(
        'D[ days], H[ hrs], m[ mins]'
      );
    }
    if (tripInfo.unloadingCompleteDatetime) {
      let localizedCompleteUnLoading = moment
        .utc(tripInfo.unloadingCompleteDatetime)
        .tz(this.timezone);
      let duration = moment.duration(
        moment(localizedCompleteUnLoading).diff(
          tripInfo.unloadingArrivalDatetime
        )
      );
      this._unloadingCompleteDatetime = duration.format(
        'D[ days], H[ hrs], m[ mins]'
      );
    }
    this.loadingCheckin = new Checkin(
      tripInfo.loadingCheckin || { kind: 'loading', trip: this.id }
    );
    this.unloadingCheckin = new Checkin(
      tripInfo.unloadingCheckin || { kind: 'unloading', trip: this.id }
    );

    let values = [];
    if (this.job) {
      values.push(this.job);
    }
    if (this.driver) {
      let value = this.driver;
      if (this.truckName) {
        value = `${value} (${this.truck})`;
      }
      values.push(value);
    }

    this.name = values.filter(Boolean).join(' - ');
    this.reviewerNotes = tripInfo.reviewerNotes;
  }

  formatDates(): void {
    let duration;
    if (this._endTimeTimestamp) {
      duration = moment.duration(
        moment(this._endTimeTimestamp).diff(this._startTimeTimestamp)
      );
    } else {
      duration = moment.duration(moment().diff(this.startTimeTimestamp));
    }
    this._completedTripTime = duration.format('D[ days], H[ hrs], m[ mins]');

    if (this._startTimeTimestamp) {
      this._startDate = new Date(this._startTimeTimestamp);
      if (!this._startTime) {
        this._startTime = moment(this._startDate).format('h:mm a');
      }
    }
    if (this._endTimeTimestamp) {
      this._endDate = new Date(this._endTimeTimestamp);
      if (!this._endTime) {
        this._endTime = moment(this._endDate).format('h:mm a');
      }
    }

    if (this._loadingCompleteDatetime) {
      this._loadingCompleteDatetime = moment(this._loadingCompleteDatetime)
        .tz(this.timezone)
        .toDate();
    }
    if (this._unloadingCompleteDatetime) {
      this._unloadingCompleteDatetime = moment(this._unloadingCompleteDatetime)
        .tz(this.timezone)
        .toDate();
    }
  }

  set startTimeTimestamp(value: any) {
    this._startTimeTimestamp = value;
    this.formatDates();
  }

  set endTimeTimestamp(value: any) {
    this._endTimeTimestamp = value;
    this.formatDates();
  }

  get endTimeTimestamp() {
    return this._endTimeTimestamp;
  }

  get startTimeTimestamp() {
    return this._startTimeTimestamp;
  }

  get completedTripTime(): string {
    return this._completedTripTime;
  }

  get startTime(): string {
    return this._startTime;
  }

  get endTime(): string {
    return this._endTime;
  }

  get startDate(): any {
    return this._startDate;
  }

  get endDate(): any {
    return this._endDate;
  }

  get duration(): string {
    return this._duration;
  }

  set startDate(value: any) {
    const _value = moment(value).format('YYYY-MM-DD');
    this.startTimeTimestamp = moment(
      _value + ' ' + this.startTime,
      'YYYY-MM-DD h:mm a'
    ).toISOString();
  }

  set endDate(value: any) {
    if (value) {
      const _value = moment(value).format('YYYY-MM-DD');
      this.endTimeTimestamp = moment(
        _value + ' ' + this.endTime,
        'YYYY-MM-DD h:mm a'
      ).toISOString();
    }
  }

  set startTime(value: string) {
    if (value) {
      let pattern = value.match(/[ap]m/i)
        ? 'YYYY-MM-DD h:mm a'
        : 'YYYY-MM-DD h:mm';
      let date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
      let parsedDate = moment(date + ' ' + value, pattern);
      this.startTimeTimestamp = parsedDate.toISOString();
    }
  }

  set endTime(value: string) {
    if (value) {
      let pattern = value.match(/[ap]m/i)
        ? 'YYYY-MM-DD h:mm a'
        : 'YYYY-MM-DD h:mm';
      let date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
      let parsedDate = moment(date + ' ' + value, pattern);
      this.endTimeTimestamp = parsedDate.toISOString();
    }
  }

  set duration(value: string) {
    this._duration = value;
  }

  get schedule(): string {
    let values = [];
    if (this.startTime && this.startTime.length) {
      values.push(this.startTime);
    }
    if (this.endTimeTimestamp && this.endTimeTimestamp.length) {
      values.push(this.endTime);
    } else {
      values.push('OPEN');
    }

    return values.filter(Boolean).join(' - ');
  }

  get signatureImagesRequired(): boolean {
    if (
      this.checkinOptions.signatureImage ||
      this.checkoutOptions.signatureImage
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasSignatureImages(): boolean {
    if (
      (this.checkinOptions.signatureImage && !this.loadingSignature) ||
      (this.checkoutOptions.signatureImage && !this.unloadingSignature)
    ) {
      return false;
    } else {
      return true;
    }
  }

  get loadingSignatureImagesRequired(): boolean {
    if (this.checkinOptions.signatureImage) {
      return true;
    } else {
      return false;
    }
  }

  get hasLoadingSignatureImages(): boolean {
    if (this.checkinOptions.signatureImage && !this.loadingSignature) {
      return false;
    } else {
      return true;
    }
  }

  get inlineLoadingSignature(): string {
    if (this.loadingSignature && this.loadingSignatureKey) {
      return ImageResizer.getResizedUrl(this.loadingSignatureKey, 24, 24);
    }
  }

  get inlineLoadingTicket(): string {
    if (this.loadingTicketImage && this.loadingTicketImageKey) {
      return ImageResizer.getResizedUrl(this.loadingTicketImageKey, 28, 28);
    }
  }

  get unloadingSignatureImagesRequired(): boolean {
    if (this.checkoutOptions.signatureImage) {
      return true;
    } else {
      return false;
    }
  }

  get hasUnloadingSignatureImages(): boolean {
    if (this.checkoutOptions.signatureImage && !this.unloadingSignature) {
      return false;
    } else {
      return true;
    }
  }

  get inlineUnloadingSignature(): string {
    if (this.unloadingSignature && this.unloadingSignatureKey) {
      return ImageResizer.getResizedUrl(this.unloadingSignatureKey, 24, 24);
    }
  }

  get inlineUnloadingTicket(): string {
    if (this.unloadingTicketImage && this.unloadingTicketImageKey) {
      return ImageResizer.getResizedUrl(this.unloadingTicketImageKey, 28, 28);
    }
  }

  get ticketImagesRequired(): boolean {
    return this.checkinOptions.ticketImage || this.checkoutOptions.ticketImage
      ? true
      : false;
  }

  get hasTicketImages(): boolean {
    return this.loadingTicketImage || this.unloadingTicketImage ? true : false;
  }

  get hasBothTickets(): boolean {
    if (
      (this.loadingTicketNumber ||
        this.loadingTicketImage ||
        this.checkinOptions.ticketNumber ||
        this.checkinOptions.ticketImage) &&
      (this.unloadingTicketNumber ||
        this.unloadingTicketImage ||
        this.checkinOptions.ticketNumber ||
        this.checkoutOptions.ticketImage)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get loadingTicketImagesRequired(): boolean {
    return this.checkinOptions.ticketImage ? true : false;
  }

  get hasLoadingTicketImages(): boolean {
    return this.loadingTicketImage ? true : false;
  }

  get unloadingTicketImagesRequired(): boolean {
    return this.checkoutOptions.ticketImage ? true : false;
  }

  get hasUnloadingTicketImages(): boolean {
    return this.unloadingTicketImage ? true : false;
  }

  get administrativeStatus(): string {
    if (this.void) {
      return 'void';
    } else if (this.retakeStatus === 'requested') {
      return 'retake';
    } else if (this.edited) {
      return 'edited';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus && this.administrativeStatus.length) {
      return true;
    } else {
      return false;
    }
  }

  get retakeRequested(): boolean {
    if (this.retakeStatus && this.retakeStatus === 'requested') {
      return true;
    } else {
      return false;
    }
  }

  get ticketNumbers(): string {
    let values = [];
    if (this.loadingTicketNumber && this.loadingTicketNumber.length) {
      values.push(this.loadingTicketNumber);
    }
    if (this.unloadingTicketNumber && this.unloadingTicketNumber.length) {
      values.push(this.unloadingTicketNumber);
    }

    return values.filter(Boolean).join(', ');
  }

  get truckName(): string {
    let values = [];
    if (this.truck && this.truck.length) {
      values.push(this.truck);
    }
    if (this.truckType && this.truckType.length) {
      values.push(this.truckType);
    }

    return values.filter(Boolean).join(' - ');
  }

  get invoiceTypeName(): string {
    switch (this.invoiceType) {
      case 'weight':
        return this.invoiceWeightUnitDisplay;
      default:
        return this.invoiceType;
    }
  }

  get haulTypeName(): string {
    switch (this.haulType) {
      case 'weight':
        return this.haulWeightUnitDisplay;
      default:
        return this.haulType;
    }
  }

  get invoiceRateUnit(): string {
    if (this.invoiceType === 'weight') {
      switch (this.invoiceWeightUnit) {
        case 'cuyds':
          return 'cu. yd.';
        default:
          return this.invoiceWeightUnit;
      }
    } else {
      return this.invoiceType;
    }
  }

  get haulRateUnit(): string {
    if (this.haulType === 'weight') {
      switch (this.haulWeightUnit) {
        case 'cuyds':
          return 'cu. yd.';
        default:
          return this.haulWeightUnit;
      }
    } else {
      return this.haulType;
    }
  }

  pluralizedInvoiceRateUnit(plural = true): string {
    if (this.invoiceType === 'weight') {
      switch (this.invoiceWeightUnit) {
        case 'cuyds':
          return plural ? 'cu. yds.' : 'cu. yd.';
        default:
          return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
      }
    } else {
      return this.invoiceType;
    }
  }

  pluralizedHaulRateUnit(plural = true): string {
    if (this.haulType === 'weight') {
      switch (this.haulWeightUnit) {
        case 'cuyds':
          return plural ? 'cu. yds.' : 'cu. yd.';
        default:
          return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
      }
    } else {
      return this.haulType;
    }
  }

  get invoiceWeightUnitDisplay(): string {
    switch (this.invoiceWeightUnit) {
      case 'cuyds':
        return 'cu. yd.';
      default:
        return this.invoiceWeightUnit;
    }
  }

  get haulWeightUnitDisplay(): string {
    switch (this.haulWeightUnit) {
      case 'cuyds':
        return 'cu. yd.';
      default:
        return this.haulWeightUnit;
    }
  }

  pluralizedInvoiceWeightUnitDisplay(plural = true): string {
    switch (this.invoiceWeightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
    }
  }

  pluralizedHaulWeightUnitDisplay(plural = true): string {
    switch (this.haulWeightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
    }
  }

  get expenseTitle(): string {
    let value = '';
    if (this.expenseSent) {
      value = 'Paid #' + this.expenseNumber;
    } else if (this.expenseNumber) {
      value = 'Draft #' + this.expenseNumber;
    }
    return value;
  }

  get paystubTitle(): string {
    let value = '';
    if (this.paystubSent) {
      value = 'Paid #' + this.paystubNumber;
    } else if (this.paystubNumber) {
      value = 'Draft #' + this.paystubNumber;
    }
    return value;
  }

  get invoiceTitle(): string {
    let value = '';
    if (this.invoiceSent) {
      value = 'Sent #' + this.invoiceNumber;
    } else if (this.invoiceNumber) {
      value = 'Draft #' + this.invoiceNumber;
    }
    return value;
  }

  url(action): string {
    return '/trips/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) {
        remove(options, _option);
      }
    }

    if (!this.void) {
      let _option = find(options, { action: 'unvoid' });
      if (_option) {
        remove(options, _option);
      }
    }

    return options;
  }

  get weightRequired(): boolean {
    return this.invoiceType === 'weight' || this.haulType === 'weight';
  }

  get mapImageURL(): string {
    let staticURL;
    if (this.routePolyline) {
      staticURL =
        'https://maps.googleapis.com/maps/api/staticmap?size=60x45&path=color:0x002649|weight:1|enc:';
      staticURL += this.routePolyline;
      staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
    }

    return staticURL;
  }

  get fullsizeMapImageURL(): string {
    let staticURL;
    if (this.routePolyline) {
      staticURL =
        'https://maps.googleapis.com/maps/api/staticmap?size=800x600&path=color:0x002649|weight:4|enc:';
      staticURL += this.routePolyline;
      staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
    }

    return staticURL;
  }
}
