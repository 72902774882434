<div class="main prereport">
  <notification *ngFor="let error of errors">{{error}}</notification>
  <fancy-table #prereportTable
              [availableColumns]="availableColumns"
              [displayedColumns]="displayedColumns"
              [config]="tableConfig"
              [search]="search"
              (searchChange)="onSearchChange($event)"
              (columnChange)="columnsChanged($event)"
              (onDataLoaded)="getJobEventData($event.data)">
    <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
      <ng-container [ngSwitch]="column">
        <div class="value" *ngSwitchCase="'status'">
          <span *ngIf="driverStatus(row) === 'active' || driverStatus(row) === 'inactive'" class="status-icon" [ngClass]="{'active': driverStatus(row) === 'active'}"></span>
          <span *ngIf="driverStatus(row) === 'nodata'" class="warning-icon"
                matTooltip="{{ 'This Assignment has no Trip data' | translate }}"
                matTooltipPosition="right"
                matTooltipClass="ruckit-tooltip">!</span>
          <i *ngIf="driverStatus(row) === 'complete'" class="icon-checkmark"></i>
          <i *ngIf="driverStatus(row) === 'warning'" class="icon-clock"
              matTooltip="{{ 'This Driver has been active for over 24 hours' | translate }}"
              matTooltipPosition="right"
              matTooltipClass="ruckit-tooltip"></i>
        </div>
        <div class="value" *ngSwitchCase="'driverEdit'">
          <ruckit-dropdown [config]="driverDropdownConfig(row)"
            [selectedOption]="row.driver && row.driver.id"
            (onSelect)="changeAssignmentDriver(row, $event)">
          </ruckit-dropdown>
          <a class="replay-link" target="_blank" [routerLink]="['/replay']" [queryParams]="{ date: dateParam, driver: row.driver.id }">
            <i class="icon-replay"></i>
          </a>
        </div>
        <div class="value" *ngSwitchCase="'truckNumber'">
          <div class="value">{{ row && row.truck && row.truck.name }}</div>
        </div>
        <div class="value" *ngSwitchCase="'job'">
          <div class="value">
            <a [routerLink]="['/jobs', row.jobevent.job.id, row.jobevent.id]" *ngIf="row.jobevent && row.jobevent.job" routerLinkActive="active">
              {{ row.jobevent && row.jobevent.job && row.jobevent.job.name }}
            </a>
          </div>
        </div>
        <div class="value" *ngSwitchCase="'orderNumber'">
          <div class="value">{{ row.jobevent && row.jobevent.job && row.jobevent.job.orderNumber }}</div>
        </div>
        <div class="value" *ngSwitchCase="'shiftStart'">
          <div class="value">{{ row && row.shifts && row.shifts[0] && row.shifts[0].startTime | moment: 'hh:mm a' }}</div>
          <div class="label">{{ row && row.shifts && row.shifts[0] && row.shifts[0].startTime | moment: 'MMM DD, YYYY' }}</div>
        </div>
        <div class="value" *ngSwitchCase="'shiftEnd'">
          <div class="value">{{ row && row.shifts && row.shifts[0] && row.shifts[0].endTime | moment: 'hh:mm a' }}</div>
          <div class="label">{{ row && row.shifts && row.shifts[0] && row.shifts[0].endTime | moment: 'MMM DD, YYYY' }}</div>
        </div>
        <div class="value" *ngSwitchCase="'tripsAmount'">
          <ng-container *ngIf="row.trips">
            <div class="value">{{ row.trips.length }}</div>
          </ng-container>
        </div>
        <div class="value" *ngSwitchCase="'geotripsAmount'">
          <ng-container *ngIf="row.predictedTrips">
            <div class="value">{{ row.predictedTrips.length }}</div>
          </ng-container>
        </div>
        <div class="value" *ngSwitchCase="'row-actions'">
          <button class="btn btn-primary" [disabled]="!row.edited" (click)="saveAssignmentUpdate(row)">Save</button>
          <i class="icon-success" [ngClass]="{'active': row.updated}"></i>
        </div>
      </ng-container>
    </ng-template>
    <div header-content>
      <div class="title" translate>Prereport Assignments</div>
    </div>
  </fancy-table>
  <div class="actions">
    <column-toggle #columnToggle [(availableColumns)]="availableColumns" [(displayedColumns)]="displayedColumns">
    </column-toggle>
  </div>
</div>
