"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var api_service_1 = require("../../shared/api.service");
var CountryPipe = /** @class */ (function () {
    function CountryPipe(apiService) {
        this.apiService = apiService;
    }
    CountryPipe.prototype.transform = function (value) {
        return this.apiService
            .getCountries()
            .find(function (country) { return country.abbreviation === value; });
    };
    return CountryPipe;
}());
exports.CountryPipe = CountryPipe;
