"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var operators_1 = require("rxjs/operators");
var trip_filter_service_1 = require("./trip.filter.service");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var TripFiltersDialogComponent = /** @class */ (function () {
    function TripFiltersDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = false;
        this.errors = [];
        this.firstApiCall = true;
        this.onDateChange = new core_1.EventEmitter();
        this.defaultDropdownConfig = {
            service: trip_filter_service_1.TripFilterService,
            serviceFunction: 'listFilters',
            searchKey: 'filter_search',
            pageSize: 30,
        };
        this.driverDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Driver', loadingText: 'Loading Drivers...', noResultsText: 'No Drivers', serviceFunctionScope: 'drivers', getFilter: 'driver', query: { serializer: 'DriverFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.customerDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Customer', loadingText: 'Loading Customers...', noResultsText: 'No Customers', serviceFunctionScope: 'customers', getFilter: 'jobevent__customer_organization', query: { serializer: 'OrganizationFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.truckDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Truck', loadingText: 'Loading Trucks...', noResultsText: 'No Trucks', serviceFunctionScope: 'trucks', getFilter: 'truck', query: { serializer: 'TruckFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.carrierDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Carrier', loadingText: 'Loading Carriers...', noResultsText: 'No Carriers', serviceFunctionScope: 'carriers', getFilter: 'driver__carrier', query: { serializer: 'CarrierFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.payableToDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Payable', loadingText: 'Loading Payables...', noResultsText: 'No Payables', serviceFunctionScope: 'payables', getFilter: 'jobevent__owner_organization', query: { serializer: 'OrganizationFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.projectDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Project', loadingText: 'Loading Projects...', noResultsText: 'No Projects', serviceFunctionScope: 'projects', getFilter: 'jobevent__job__project', query: { serializer: 'ProjectFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.jobDropdownConfig = __assign({}, this.defaultDropdownConfig, { selectText: 'Select Job', loadingText: 'Loading Jobs...', noResultsText: 'No Jobs', serviceFunctionScope: 'jobs', getFilter: 'jobevent__job', query: { serializer: 'JobFilter', jobevent__shift1_start__gte: null, jobevent__shift1_start__lte: null } });
        this.model = {
            startDate: null,
            endDate: null,
            job: null,
            jobNumber: null,
            project: null,
            customer: null,
            driver: null,
            truck: null,
            payableTo: null,
            carrier: null,
            unvoid: null,
            edited: false,
            incomplete: false,
            retake: false,
            uninvoiced: false,
            missedAssignedLocations: false
        };
    }
    TripFiltersDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filterDropdowns();
        this.onDateChange.pipe(operators_1.debounceTime(1000), operators_1.distinctUntilChanged()).subscribe(function () {
            if (!_this.firstApiCall) {
                _this.filterDropdowns(true);
            }
            else {
                _this.firstApiCall = false;
            }
        });
    };
    TripFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    TripFiltersDialogComponent.prototype.submit = function () {
        this.convertDates();
        this.callback(this.model);
        this.dialogRef.close();
    };
    TripFiltersDialogComponent.prototype.convertDates = function (assign) {
        if (assign === void 0) { assign = true; }
        var startDate, endDate;
        if (this.model.startDate) {
            startDate = new Date(this.model.startDate);
            var name_1 = this.datePipe.transform(startDate, 'MM/dd/yyyy');
            startDate.setHours(0, 0, 0, 0);
            if (assign) {
                Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name_1 });
            }
        }
        if (this.model.endDate) {
            endDate = new Date(this.model.endDate);
            var name_2 = this.datePipe.transform(endDate, 'MM/dd/yyyy');
            endDate.setHours(23, 59, 59, 999);
            if (assign) {
                Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name_2 });
            }
        }
        return {
            startDate: startDate && startDate.toISOString(),
            endDate: endDate && endDate.toISOString()
        };
    };
    TripFiltersDialogComponent.prototype.onDateChanged = function (dates, type) {
        if (type === void 0) { type = ''; }
        this.model[type] = dates[0];
        var _a = this.convertDates(true), startDate = _a.startDate, endDate = _a.endDate;
        this.onDateChange.next({ startDate: startDate, endDate: endDate });
    };
    TripFiltersDialogComponent.prototype.filterDropdowns = function (fetch) {
        if (fetch === void 0) { fetch = false; }
        var _a = this.convertDates(false), startDate = _a.startDate, endDate = _a.endDate;
        this.filterJobs(startDate, endDate, fetch);
        this.filterProjects(startDate, endDate, fetch);
        this.filterCustomers(startDate, endDate, fetch);
        this.filterDrivers(startDate, endDate, fetch);
        this.filterTrucks(startDate, endDate, fetch);
        this.filterPayables(startDate, endDate, fetch);
        this.filterCarriers(startDate, endDate, fetch);
    };
    TripFiltersDialogComponent.prototype.filterJobs = function (startDate, endDate, fetch) {
        this.jobDropdownConfig.query = __assign({}, this.jobDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.jobDropdownConfig = __assign({}, this.jobDropdownConfig);
        if (fetch) {
            this.jobsDropdown.getRecords(this.jobDropdownConfig.query);
        }
    };
    TripFiltersDialogComponent.prototype.filterProjects = function (startDate, endDate, fetch) {
        this.projectDropdownConfig.query = __assign({}, this.projectDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.projectDropdownConfig = __assign({}, this.projectDropdownConfig);
        if (fetch) {
            this.projectsDropdown.getRecords(this.projectDropdownConfig.query);
        }
    };
    TripFiltersDialogComponent.prototype.filterCustomers = function (startDate, endDate, fetch) {
        this.customerDropdownConfig.query = __assign({}, this.customerDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.customerDropdownConfig = __assign({}, this.customerDropdownConfig);
        if (fetch) {
            this.customersDropdown.getRecords(this.customerDropdownConfig.query);
        }
    };
    TripFiltersDialogComponent.prototype.filterDrivers = function (startDate, endDate, fetch) {
        this.driverDropdownConfig.query = __assign({}, this.driverDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.driverDropdownConfig = __assign({}, this.driverDropdownConfig);
        if (fetch) {
            this.driversDropdown.getRecords(this.driverDropdownConfig.query);
        }
    };
    TripFiltersDialogComponent.prototype.filterTrucks = function (startDate, endDate, fetch) {
        this.truckDropdownConfig.query = __assign({}, this.truckDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.truckDropdownConfig = __assign({}, this.truckDropdownConfig);
        if (fetch) {
            this.trucksDropdown.getRecords(this.truckDropdownConfig.query);
        }
    };
    TripFiltersDialogComponent.prototype.filterPayables = function (startDate, endDate, fetch) {
        this.payableToDropdownConfig.query = __assign({}, this.payableToDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.payableToDropdownConfig = __assign({}, this.payableToDropdownConfig);
        if (fetch) {
            this.payablesDropdown.getRecords(this.payableToDropdownConfig.query);
        }
    };
    TripFiltersDialogComponent.prototype.filterCarriers = function (startDate, endDate, fetch) {
        this.carrierDropdownConfig.query = __assign({}, this.carrierDropdownConfig.query, { jobevent__shift1_start__gte: startDate, jobevent__shift1_start__lte: endDate });
        this.carrierDropdownConfig = __assign({}, this.carrierDropdownConfig);
        if (fetch) {
            this.carriersDropdown.getRecords(this.carrierDropdownConfig.query);
        }
    };
    return TripFiltersDialogComponent;
}());
exports.TripFiltersDialogComponent = TripFiltersDialogComponent;
