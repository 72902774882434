"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var resource_service_1 = require("../shared/resource.service");
var assignment_serializer_1 = require("./assignment.serializer");
var index_1 = require("../shared/index");
var app_utilities_1 = require("../shared/app-utilities");
var confirm_change_job_loads_service_1 = require("../dispatch/dispatch-by-job/confirm-change-job-loads.service");
var AssignmentService = /** @class */ (function (_super) {
    __extends(AssignmentService, _super);
    function AssignmentService(http, confirmChangeJobLoadsService) {
        var _this = _super.call(this, http, 'assignments/all/', new assignment_serializer_1.AssignmentSerializer()) || this;
        _this.confirmChangeJobLoadsService = confirmChangeJobLoadsService;
        return _this;
    }
    AssignmentService.prototype.save = function (model, query, raw) {
        var _this = this;
        if (raw === void 0) { raw = false; }
        return _super.prototype.save.call(this, model, query, raw).pipe(operators_1.catchError(function (err) {
            var callback = function (byPassQuery) {
                var queries = query ? __assign({}, query, byPassQuery) : byPassQuery;
                return _this.save(model, queries, raw);
            };
            return _this._validateAssignmentErrors(err, callback);
        }));
    };
    AssignmentService.prototype.saveBulkAssignments = function (body, params) {
        var _this = this;
        if (body) {
            var assigmentBulkUrl = this.baseUrl + 'assignments/';
            return this.http.post(assigmentBulkUrl, body, {
                headers: index_1.requestHeaders(),
                params: params,
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) {
                var callback = function (byPassQuery) {
                    var queryParams = app_utilities_1.AppUtilities.getParamsFromQuery(byPassQuery);
                    return _this.saveBulkAssignments(body, queryParams);
                };
                return _this._validateAssignmentErrors(err, callback);
            }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.bulkCreate = function (assignments, query) {
        var _this = this;
        if (assignments && assignments.length) {
            var params_1 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_1 = params_1.set(key, query[key].toString());
                    }
                });
            }
            var _assignments = assignments.map(function (assignment) {
                return new assignment_serializer_1.AssignmentSerializer().toJson(assignment);
            });
            var assigmentBulkUrl = this.baseUrl + 'assignments/';
            return this.http.post(assigmentBulkUrl, _assignments, {
                headers: index_1.requestHeaders(),
                params: params_1
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) {
                var callback = function (byPassQuery) {
                    var queries = query ? __assign({}, query, byPassQuery) : byPassQuery;
                    return _this.bulkCreate(assignments, queries);
                };
                return _this._validateAssignmentErrors(err, callback);
            }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.bulkUpdate = function (assignments, query) {
        var _this = this;
        if (assignments && assignments.length) {
            var params_2 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_2 = params_2.set(key, query[key].toString());
                    }
                });
            }
            var _assignments = assignments.map(function (assignment) {
                return new assignment_serializer_1.AssignmentSerializer().toJson(assignment);
            });
            var assigmentBulkUrl = this.baseUrl + 'assignments/bulk-update/';
            return this.http.put(assigmentBulkUrl, _assignments, {
                headers: index_1.requestHeaders(),
                params: params_2
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) {
                var callback = function (byPassQuery) {
                    var queries = query ? __assign({}, query, byPassQuery) : byPassQuery;
                    return _this.bulkUpdate(assignments, queries);
                };
                return _this._validateAssignmentErrors(err, callback);
            }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.bulkRemove = function (recordIds, query) {
        var _this = this;
        if (recordIds) {
            var params_3 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_3 = params_3.set(key, query[key].toString());
                    }
                });
            }
            var assigmentBulkUrl = this.baseUrl + 'assignments/bulk-delete/';
            return this.http.request('delete', assigmentBulkUrl, {
                body: recordIds,
                headers: index_1.requestHeaders(),
                params: params_3
            }).pipe(operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.copyAssignment = function (body) {
        if (body) {
            var assigmentCopyUrl = this.baseUrl + 'assignments/copy/';
            return this.http.post(assigmentCopyUrl, body, {
                headers: index_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return res; }), operators_1.catchError(index_1.handleError));
        }
    };
    AssignmentService.prototype._validateAssignmentErrors = function (err, callback) {
        var error = index_1.getFirstFieldError(err);
        if (error && error.includes('Loads assigned exceed loads requested')) {
            return this.confirmChangeJobLoadsService.showConfirmationProceedModal().pipe(operators_1.switchMap(function (result) {
                if (result && result['byPass']) {
                    var byPassQuery = { bypass_limit_validation: 'True' };
                    return callback(byPassQuery);
                }
            }), operators_1.catchError(function () { return rxjs_1.of(true); }));
        }
        else {
            return rxjs_1.throwError(err);
        }
    };
    AssignmentService.prototype.convertBulkResponse = function (res) {
        // const errorsParsed = parseErrors(res)
        // let errors = errorsParsed || [];
        var errors = [];
        var assignments = [];
        if (res && res.errors) {
            errors = res.errors;
        }
        if (res && res.success) {
            assignments = res.success.map(function (record) { return new assignment_serializer_1.AssignmentSerializer().fromJson(record); });
        }
        return { errors: errors, assignments: assignments };
    };
    return AssignmentService;
}(resource_service_1.ResourceService));
exports.AssignmentService = AssignmentService;
