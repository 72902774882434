<div class="available-drivers-container" (scroll)="driversScroll($event)">
  <header class="driver-header">
    <div class="shift-assign-driver">
      <button class="btn btn-default"
              [ngPlural]="selectedDriverCount"
              [disabled]="selectedDriverCount === 0"
              (click)="assignDrivers()">
        <ng-template ngPluralCase="=1">
          {{('Assign ' | translate ) + selectedDriverCount + (' Driver ' | translate)}}
        </ng-template>
        <ng-template ngPluralCase="other">
          {{('Assign ' | translate ) + selectedDriverCount + (' Drivers ' | translate)}}
        </ng-template>
        <ng-template ngPluralCase="=0">
          {{  driverService.count ? driverService.count + ' Available Drivers' : 'No Drivers'  | translate}}
        </ng-template>
      </button>
      <dropdown #shiftDropdown *ngIf="job.shifts && job.shifts > 1"
                [options]="shift" [config]="shiftConfig"
                [selectedOption]="selectedShift"
                (onSelect)="selectShift($event)">
      </dropdown>
    </div>
    <div class="driver-filters">
      <dropdown #tagsDropdown [options]="tags" [config]="tagsConfig"
                class="market-filters" title="{{ 'All Markets' | translate }}"
                (onSelect)="selectTags($event)"></dropdown>
      <dropdown #carriersDropdown [options]="carriers" [config]="carriersConfig"
                title="{{ 'My Drivers' | translate }}"
                (onSelect)="selectCarrier($event, true)"
                (onSearch)="dropdownSearch($event, 'carrier')"
                (nextPage)="dropdownNextPage($event, 'carrier')">
      </dropdown>
    </div>
    <div class="search-section">
      <search (searchTermChange)="changeDriversSearch($event)" [(searchTerm)]="drivers.search"></search>
      <input class="select-driver" type="checkbox" [checked]="allDriverSelected" (change)="allSelectDrivers($event)" />
    </div>
    <div class="bulk-edits">
      <div class="edits">
        <div class="input-field">
          <label for="driverStartTime" translate>Start Time</label>
          <!-- <input
            type="time" 
            required 
            name="driverStartTime" 
            class="timepicker"
            placeholder="{{ jobEvent.shift1StartTimestamp | moment: 'HH:mm' }}"
            [value]="jobEvent.shift1StartTimestamp | moment: 'HH:mm'"
            [(ngModel)]="jobEvent.staggerStartTime"
          /> -->
          <input
            type="time" 
            required 
            name="driverStartTime" 
            class="timepicker"
            placeholder="{{ jobEvent.shift1Start | moment: 'HH:mm' }}"
            [value]="jobEvent.shift1Start | moment: 'HH:mm'"
            (change)="jobEvent.staggerStartTime = $event.target.value"
          />
        </div>
        <div class="input-field">
          <label for="stagger-value" translate>Stagger (Minutes)</label>
          <input 
            type="text" name="stagger-value" placeholder="0"
            [(ngModel)]="jobEvent.deliveryInterval"/>
        </div>
        <div class="input-field">
          <label for="load-value" translate>Loads Per Driver</label>
          <input 
            type="string" name="load-value" placeholder="0"
            [(ngModel)]="jobEvent.assignmentLoadCount"/>
        </div>
        <div class="input-field radio">
          <input type="radio" name="load-value-all-day"
             required (click)="jobEvent.assignmentLoadCount = 0"
             [(ngModel)]="jobEvent.assignmentLoadCount" [value]="0" />
          <label for="load-value-all-day"
             [ngClass]="{ 'active': jobEvent.assignmentLoadCount === 0 }"
             translate>All Day</label>
        </div>
      </div>
    </div>
  </header>
  <ng-container *ngIf="!loading && !driversLoading">
    <div  *ngFor="let driver of drivers.items | filter : showDriverInList"
         class="driver"
         title="{{driverIsOnJobEvent(driver) ? 'This driver has already been assigned to this Job Day': ''}}"
         [draggable]="device && device.desktop && driverIsDraggable(driver)"
         (click)="selectDriver($event, driver)"
         [ngClass]="{
           draggable: driverIsDraggable(driver),
           'multi-drag': multipleDrag(driver),
           'on-job-event': driverIsOnJobEvent(driver)
          }"
         [attr.aria-grabbed]="driverIsGrabbed(driver)"
         (dragstart)="driverDragstart(driver)"
         (dragend)="driverDragend(driver)"
         (contextmenu)="openContextMenu($event, driver)"
         >
      <ng-container *ngIf="multipleDrag(driver)">
        <div *ngFor="let _driver of selectedDrivers | slice:0:3">
          <div class="driver-photo">
            <span *ngIf="!_driver.image">{{_driver.name[0]}}</span>
            <img *ngIf="_driver.image" [src]="_driver.dispatchImage" />
          </div>
        </div>
        <div class="driver-photo count" *ngIf="selectedDrivers.length > 3">
          <span>+{{selectedDrivers.length - 3}}</span>
        </div>
        <div class="driver-count" [ngPlural]="selectedDrivers.length">
          <ng-template ngPluralCase="=0">
            {{ (selectedDrivers.length || 0) | number:0 }}
            {{ 'Drivers Selected' | translate }}
          </ng-template>
          <ng-template ngPluralCase="=1">
            {{ (selectedDrivers.length || 0) | number:0 }}
            {{ 'Driver Selected' | translate }}
          </ng-template>
          <ng-template ngPluralCase="other">
            {{ (selectedDrivers.length || 0) | number:0 }}
            {{ 'Drivers Selected' | translate }}
          </ng-template>
        </div>
      </ng-container>
      <ng-container *ngIf="!multipleDrag(driver)">
        <input type="checkbox" class="select-driver"
               [(ngModel)]="driver.selected"
               (change)="selectDriver($event, driver)">
        <div class="photo-set">
          <div class="truck-photo"
               [ngClass]="{empty: !driver.truck, 'icon-plus': !driver.truck}"
               (click)="openAssignTruck(driver)">
            <div class="image" *ngIf="driver.truck">
              <img *ngIf="driver.truck.image && driver.truck.dispatchImage" [src]="driver.truck.dispatchImage" />
              <i *ngIf="!driver.truck.image" class="icon-fleet"></i>
            </div>
            <div class="overlay"><i class="icomoon icon-plus"></i></div>
          </div>
          <div class="driver-photo">
            <span *ngIf="!driver.image">{{driver.name[0]}}</span>
            <img *ngIf="driver.image && driver.dispatchImage" [src]="driver.dispatchImage" />
            <div class="overlay"></div>
          </div>
        </div>
        <div class="info">
          <div *ngIf="driver.truck && driverIsReady(driver)" class="truck"># {{driver.truck.displayName}}</div>
          <div *ngIf="!driver.truck && driverIsReady(driver)" class="label-danger" translate>truck unassigned</div>
          <div *ngIf="!driverIsReady(driver)" class="label-danger" translate>
            {{ (driver.dutyStatus === 'off-duty' ? 'Off Duty' : 'Truck Out of Service') | translate }}
          </div>
          <div class="name">{{ driver.name }}</div>
          <div class="driver-status" [ngClass]="{busy: driver.busy}">
            <ng-container *ngIf="!driver.busy">
              {{ driver.organizationName }}
              <ng-container *ngIf="driver.schedule">
                {{ ' | ' + driver.schedule }}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="driver.busy" translate>Driver is Busy</ng-container>
          </div>
          <div *ngIf="driver.conflicts && driver.conflicts.length" class="driver-assignments">
            {{ 'Assigned:' | translate }} {{driver.conflicts[0].jobeventName}} 
            <span *ngIf="driver.conflicts.length > 1">+{{driver.conflicts.length - 1}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <my-loader *ngIf="loading || driversLoading"
             class="driver-loader"
             [ngClass]="{'full-height': drivers.items.length === 0}"></my-loader>

  <div class="no-results" fxLayout="column" fxLayoutAlign="start center"
       *ngIf="!drivers.loading && !errors.length && drivers.items.length === 0">
    <div *ngIf="this.drivers && this.drivers.search">
      <h2 translate>No Results</h2>
      <p translate>Clear your search to see all available drivers</p>
    </div>
  </div>
</div>

<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>