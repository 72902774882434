"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var http_1 = require("@angular/common/http");
// angular material
var material_1 = require("@angular/material");
// lodash
var lodash_1 = require("lodash");
// moment
var moment = require("moment-timezone");
// services
var condensed_job_event_service_1 = require("../../job-events/condensed-job-event.service");
var job_event_stat_service_1 = require("../../job-event-stats/job-event-stat.service");
var job_service_1 = require("./../../jobs/job.service");
var index_1 = require("../../shared/index");
var job_event_service_1 = require("../../job-events/job-event.service");
var location_service_1 = require("../../locations/location.service");
// components
var edit_job_dialog_component_1 = require("../../jobs/edit-job-dialog.component");
var cancel_job_dialog_component_1 = require("../../jobs/cancel-job-dialog.component");
var message_drivers_dialog_component_1 = require("../../messages/message-drivers-dialog.component");
var add_employee_dialog_component_1 = require("../../team/add-employee-dialog.component");
var edit_job_event_dialog_component_1 = require("../../job-events/edit-job-event-dialog.component");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
// constants
var daily_board_list_input_constants_1 = require("./daily-board-list-input-constants");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var DailyBoardListComponent = /** @class */ (function () {
    function DailyBoardListComponent(jobEventService, jobEventStatService, condensedJobEventService, authenticationService, jobService, locationService, translateService, dialog) {
        var _this = this;
        this.jobEventService = jobEventService;
        this.jobEventStatService = jobEventStatService;
        this.condensedJobEventService = condensedJobEventService;
        this.authenticationService = authenticationService;
        this.jobService = jobService;
        this.locationService = locationService;
        this.translateService = translateService;
        this.dialog = dialog;
        this.allSelected = false;
        this.selectedJobs = [];
        this.excludeJobs = [];
        this.availableColumns = daily_board_list_input_constants_1.AVAILABLE_COLUMNS(this.translateService).slice();
        this.displayedColumns = daily_board_list_input_constants_1.DISPLAYED_COLUMNS.slice();
        this.availableFilters = daily_board_list_input_constants_1.AVAILABLE_FILTERS(this.translateService).slice();
        this.appliedFilters = [];
        this.search = '';
        this.urlDate = '';
        this.withJobStats = false;
        this.searchReset = new core_1.EventEmitter();
        this.loading = true;
        this.enabledFeatures = [];
        this.requiresConfirmationForJobs = false;
        this.totalHoursField = 'totalHours';
        this.detailColumns = ['detail'];
        this.dailyBoardTableConfig = {
            hasHeader: true,
            pageSize: 25,
            service: condensed_job_event_service_1.CondensedJobEventService,
            preferenceKey: 'DailyBoardListComponent-CondensedJobEventService',
            query: {},
            filterQuery: true,
            collectionTitle: this.translateService.instant('Jobs'),
            noResultsText: this.translateService.instant('a job'),
            newRecordRoute: ['/jobs/new'],
            sortBy: 'job__name',
            sortDirection: 'asc',
            customHeight: true,
            automaticallyHidePagination: false,
            menuOptions: daily_board_list_input_constants_1.MENU_OPTIONS(this.translateService).slice()
        };
        this.multipleActionDropdownOptions = [
            { name: this.translateService.instant('Cancel'), action: 'cancel', link: false },
            { name: this.translateService.instant('Export'), action: 'export', link: false },
        ];
        this.locationsConfig = {
            selectText: this.translateService.instant('Select Location'),
            loadingText: this.translateService.instant('Loading Locations...'),
            noResultsText: this.translateService.instant('No Locations'),
            service: location_service_1.LocationService,
            rightAlign: true
        };
        this.errors = [];
        this.stats = [];
        this.saveJobDayCallback = function (e) {
            _this.dailyBoardTable.getRecords();
        };
        this.cancelJobCallback = function (e) {
            _this.dailyBoardTable.getRecords();
            _this.dailyBoardTable.resetSelections();
        };
        this.messageDriversCallback = function () {
            // Update Notice Message
        };
        /**
         * @param  {} e
         * This would be called on succesful completion of editing day details
         * and will refresh the table with new changes
         */
        this.saveJobEventCallback = function (e) {
            _this.dailyBoardTable.getRecords();
        };
    }
    DailyBoardListComponent.prototype.ngOnInit = function () {
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.dailyBoardTableConfig['query'] = __assign({}, this.dailyBoardTableConfig['query'], query);
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
            var totalHoursPreference = this.authenticationService.getFeature('calculator');
            this.totalHoursField = totalHoursPreference === 'trips' ?
                'totalTripHours' : totalHoursPreference === 'completed_trips' ?
                'totalCompletedTripHours' : 'totalHours';
        }
        if (this.enabledFeatures && this.enabledFeatures.includes('driverFleetRequireAssignmentConfirmation')) {
            this.requiresConfirmationForJobs = this.authenticationService.getFeature('driverFleetRequireAssignmentConfirmation');
            this.displayedColumns.push('assignment-status');
        }
        else {
            this.requiresConfirmationForJobs = false;
        }
        this.loading = false;
    };
    DailyBoardListComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var jobEventDateStart = new Date();
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        if (changes['jobEventDate']) {
            var jobEventDate = changes['jobEventDate'].currentValue;
            if (jobEventDate) {
                jobEventDateStart = new Date(this.jobEventDate);
                jobEventDateEnd = lodash_1.clone(jobEventDateStart);
            }
            var isSameDate = moment(jobEventDate).diff(changes['jobEventDate'].previousValue, 'days') === 0;
            if (!isSameDate && this.dailyBoardTable && this.dailyBoardTable.page > 1) {
                this.dailyBoardTable.resetPageNumber();
            }
        }
        else if (this.jobEventDate) {
            jobEventDateStart = new Date(this.jobEventDate);
            jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        }
        jobEventDateStart.setHours(0, 0, 0, 0);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        this.urlDate = moment(this.jobEventDate).format('YYYYMMDD');
        this.dailyBoardTableConfig = __assign({}, this.dailyBoardTableConfig, { query: {
                exclude_pending: 'True',
                cancelled: this.hideCancelled ? 'False' : undefined,
                shift1_start__gte: jobEventDateStart.toISOString(),
                shift1_start__lte: jobEventDateEnd.toISOString()
            } });
        if (changes['jobEventDate'] && changes['jobEventDate'].isFirstChange()) {
            this.availableFilters = this.availableFilters.map(function (filter) {
                if (!filter.service) {
                    return filter;
                }
                return __assign({}, filter, { query: {
                        exclude_pending: 'True',
                        cancelled: _this.hideCancelled ? 'False' : undefined,
                        shift1_start__gte: jobEventDateStart.toISOString(),
                        shift1_start__lte: jobEventDateEnd.toISOString()
                    } });
            });
        }
        if (this.authenticationService.hasFavoriteTags()) {
            this.dailyBoardTableConfig['query']['user_tags'] = 'True';
        }
        if (this.dailyBoardTable) {
            this.dailyBoardTable.config = __assign({}, this.dailyBoardTableConfig);
        }
    };
    DailyBoardListComponent.prototype.editJob = function (jobEvent) {
        var dialog = this.dialog.open(edit_job_dialog_component_1.EditJobDialogComponent, {
            width: '430px',
            data: { jobId: jobEvent.jobId }
        });
        dialog.componentInstance.callback = this.saveJobDayCallback;
    };
    DailyBoardListComponent.prototype.cancelJobs = function (jobEvent) {
        var jobEventIds;
        var query = app_utilities_1.AppUtilities.getFilterQuery(this.appliedFilters, this.search);
        if (jobEvent && !jobEvent.cancelled && jobEvent.canEdit) {
            jobEventIds = [jobEvent.id];
        }
        else if (!jobEvent && this.selectedJobs.length) {
            jobEventIds = this.selectedJobs.map(function (j) { return (j.id); });
        }
        if (jobEventIds || this.allSelected) {
            var dialog = this.dialog.open(cancel_job_dialog_component_1.CancelJobDialogComponent, {
                width: '430px',
                data: {
                    jobEventIds: jobEventIds,
                    isAllJobEventsSelected: this.allSelected,
                    allJobEventsCount: this.allJobsCount,
                    jobEventIdsExclude: this.excludeJobs.map(function (j) { return j.id; }),
                    jobEventsDate: this.jobEventDate,
                    query: __assign({}, this.dailyBoardTableConfig.query, query)
                }
            });
            dialog.componentInstance.callback = this.cancelJobCallback;
        }
    };
    DailyBoardListComponent.prototype.messageDrivers = function (jobEvent) {
        var dialog = this.dialog.open(message_drivers_dialog_component_1.MessageDriversDialogComponent, {
            width: '870px'
        });
        dialog.componentInstance.jobEventId = jobEvent.id;
        dialog.componentInstance.callback = this.messageDriversCallback;
    };
    DailyBoardListComponent.prototype.inviteCustomer = function (jobEvent) {
        var _this = this;
        this.jobService.get(jobEvent.jobId).subscribe(function (currentJob) {
            var _a = currentJob.project.customerOrganization, id = _a.id, email = _a.email;
            _this.dialog.open(add_employee_dialog_component_1.RuckitAddEmployeeDialogComponent, {
                width: '444px',
                data: {
                    type: 'customer',
                    organizationId: id,
                    email: email
                },
            });
        });
    };
    /**
     * @param  {string} jobEventId
     * @returns void
     * This would be called when clicked on edit day details from action menu
     * It will make GET request for jobEvent to be able to make PUT request on
     * saving changes in the modal
     */
    DailyBoardListComponent.prototype.openEditJobDayDetailEvent = function (jobEventId) {
        var _this = this;
        this.jobEventService.getJobEvent(jobEventId).subscribe(function (event) {
            var dialog = _this.dialog.open(edit_job_event_dialog_component_1.EditJobEventDialogComponent, {
                width: '430px'
            });
            if (dialog) {
                dialog.componentInstance.jobEvent = event;
                dialog.componentInstance.callback = _this.saveJobEventCallback;
            }
        });
    };
    DailyBoardListComponent.prototype.menuAction = function (name, jobEvent) {
        switch (name) {
            case 'edit-days':
                this.editJob(jobEvent);
                break;
            case 'cancel-day':
                this.cancelJobs(jobEvent);
                break;
            case 'message-drivers':
                this.messageDrivers(jobEvent);
                break;
            case 'invite':
                this.inviteCustomer(jobEvent);
                break;
            case 'edit-day-details':
                this.openEditJobDayDetailEvent(jobEvent.id);
                break;
        }
    };
    DailyBoardListComponent.prototype.filtersModified = function (appliedFilters) {
        // let filter = _find(this.appliedFilters, { key: '' });
        // if (!appliedFilters || !appliedFilters.length ) {
        //   this.filterChanges(_find(this.priceListsReq, { id: null }));
        // }
        this.dailyBoardTable.resetPageNumber();
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    DailyBoardListComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    DailyBoardListComponent.prototype.refreshTable = function () {
        if (this.dailyBoardTable) {
            this.loadingDropdownOpen = null;
            this.unloadingDropdownOpen = null;
            var query = {
                cancelled: !this.hideCancelled ? 'False' : undefined
            };
            if (this.authenticationService.hasFavoriteTags()) {
                query['user_tags'] = 'True';
            }
            this.dailyBoardTable.getRecords(__assign({}, this.dailyBoardTableConfig['query'], query));
        }
    };
    DailyBoardListComponent.prototype.captureStats = function (tableData) {
        var effectiveRateCalculator = '';
        if (this.enabledFeatures && this.enabledFeatures.includes('effectiveRateCalculator')) {
            effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
        }
        this.jobEventStatService.list(Object.assign(tableData.query, {
            calculator: effectiveRateCalculator
        })).subscribe(function (stats) {
            tableData.data.forEach(function (jobEvent) {
                jobEvent.stats = stats.find(function (stat) { return (stat.jobId === jobEvent.jobId); });
                if (!jobEvent.stats) {
                    jobEvent.stats = stats.find(function (stat) { return (stat.jobId.replace(/[^a-zA-Z0-9]/g, '') === jobEvent.jobId); });
                }
                jobEvent.loading = false;
            });
        }, function (err) {
            tableData.data.forEach(function (jobEvent) { return jobEvent.loading = false; });
        });
    };
    DailyBoardListComponent.prototype.toggleLocationEdit = function (type, id) {
        if (this[type + 'DropdownOpen'] === id) {
            this[type + 'DropdownOpen'] = null;
        }
        else {
            this[type + 'DropdownOpen'] = id;
        }
        this[(type === 'loading' ? 'unloading' : 'loading') + 'DropdownOpen'] = null;
    };
    DailyBoardListComponent.prototype.selectLocation = function (type, jobEvent, location) {
        var _this = this;
        var _a;
        if (jobEvent[type + 'Location'] !== location.id) {
            this.jobService.save((_a = { id: jobEvent.jobId }, _a[type + 'Location'] = location, _a)).subscribe(function () { return _this.refreshTable(); });
        }
    };
    DailyBoardListComponent.prototype.exportSelectJobs = function () {
        var _this = this;
        var params = new http_1.HttpParams();
        var scope = {};
        if (this.jobEventDate) {
            var startDate = new Date(this.jobEventDate);
            startDate.setHours(0, 0, 0, 0);
            var endDate = lodash_1.clone(startDate);
            endDate.setHours(23, 59, 59, 999);
            params = params.set('shift1_start__gte', startDate.toISOString());
            params = params.set('shift1_start__lte', endDate.toISOString());
        }
        this.jobEventService.getExportFields().subscribe(function (fields) {
            if (_this.selectedJobs.length) {
                Object.assign(scope, { include: _this.selectedJobs.map(function (t) { return t.id; }) });
            }
            else if (_this.allSelected) {
                Object.assign(scope, { exclude: _this.excludeJobs.map(function (t) { return t.id; }) });
                Object.keys(_this.appliedFilters).map(function (key) {
                    if (_this.appliedFilters[key]) {
                        var query_1 = _this.appliedFilters[key]['query'];
                        Object.keys(query_1).map(function (queryKey) {
                            params = params.set(queryKey, query_1[queryKey]);
                        });
                    }
                });
                if (_this.search && _this.search.length) {
                    params = params.set('search', _this.search);
                }
            }
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'jobs',
                    buttonText: _this.translateService.instant('Export Data to CSV'),
                    callback: function () {
                        _this.refreshTable();
                    },
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.jobEventService,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    DailyBoardListComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        if (!this.allSelected) {
            this.selectedJobs = event.selection.selected;
            this.excludeJobs = [];
            this.allJobsCount = this.selectedJobs.length;
        }
        else {
            this.selectedJobs = [];
            this.excludeJobs = event.exclusion.selected;
            this.allJobsCount = this.dailyBoardTable.count - this.excludeJobs.length;
        }
    };
    DailyBoardListComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectJobs();
                break;
            case 'cancel':
                this.cancelJobs();
                break;
        }
    };
    DailyBoardListComponent.prototype.onSearchClear = function () {
        this.search = '';
        this.searchReset.emit();
    };
    return DailyBoardListComponent;
}());
exports.DailyBoardListComponent = DailyBoardListComponent;
