"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".widget[_ngcontent-%COMP%]{overflow-y:hidden;padding-bottom:20px;border-radius:4px;background-color:#fcfcfd;border:1px solid #e6e9ec;box-shadow:0 2px 4px 0 rgba(0,0,0,.2)}.widget.no-padding[_ngcontent-%COMP%]{padding:0}.widget[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]{height:53px;padding:0 20px;background-color:#fff;border-bottom:1px solid #e6e9ec}.widget[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{overflow-y:auto;height:calc(100% - 53px)}.widget.tall-1[_ngcontent-%COMP%]{height:calc(275 * 1px)}.widget.tall-2[_ngcontent-%COMP%]{height:calc(275 * 2px)}.widget.tall-3[_ngcontent-%COMP%]{height:calc(275 * 3px)}.widget.tall-4[_ngcontent-%COMP%]{height:calc(275 * 4px)}.widget.wide-1[_ngcontent-%COMP%]{width:calc(280 * 1px)}.widget.wide-2[_ngcontent-%COMP%]{width:calc(280 * 2px)}.widget.wide-3[_ngcontent-%COMP%]{width:calc(280 * 3px)}.widget.wide-4[_ngcontent-%COMP%]{width:calc((280 * 4px) + 30px)}@media screen and (max-width:720px){.widget[_ngcontent-%COMP%]{border-radius:0;border:none}.widget[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]{display:none}.widget[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{height:100%}.widget.wide-4[_ngcontent-%COMP%]{width:100%}.widget.tall-2[_ngcontent-%COMP%]{height:100%}}"];
exports.styles = styles;
