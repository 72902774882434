"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var shift_report_serializer_1 = require("./shift-report.serializer");
var resource_service_1 = require("../shared/resource.service");
var shift_serializer_1 = require("./shift.serializer");
var index_1 = require("../shared/index");
var ShiftReportService = /** @class */ (function (_super) {
    __extends(ShiftReportService, _super);
    function ShiftReportService(http) {
        return _super.call(this, http, 'shifts/report/', new shift_report_serializer_1.ShiftReportSerializer()) || this;
    }
    return ShiftReportService;
}(resource_service_1.ResourceService));
exports.ShiftReportService = ShiftReportService;
var ShiftService = /** @class */ (function (_super) {
    __extends(ShiftService, _super);
    function ShiftService(http) {
        return _super.call(this, http, 'shifts/', new shift_serializer_1.ShiftSerializer()) || this;
    }
    ShiftService.prototype.bulkUpdate = function (shifts, query) {
        var _this = this;
        if (shifts && shifts.length) {
            var params_1 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_1 = params_1.set(key, query[key].toString());
                    }
                });
            }
            var _shifts = shifts.map(function (shift) {
                return new shift_serializer_1.ShiftSerializer().toJson(shift);
            });
            var shiftBulkUrl = this.baseUrl + 'shifts/bulk-update/';
            return this.http.put(shiftBulkUrl, _shifts, {
                headers: index_1.requestHeaders(),
                params: params_1
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    ShiftService.prototype.convertBulkResponse = function (res) {
        var errors = [];
        var shifts = [];
        if (res && res.errors) {
            errors = res.errors;
        }
        if (res && res.success) {
            shifts = res.success.map(function (record) { return new shift_serializer_1.ShiftSerializer().fromJson(record); });
        }
        return { errors: errors, shifts: shifts };
    };
    return ShiftService;
}(resource_service_1.ResourceService));
exports.ShiftService = ShiftService;
var DriverShiftService = /** @class */ (function (_super) {
    __extends(DriverShiftService, _super);
    function DriverShiftService(http) {
        return _super.call(this, http, 'drivers/shifts/', new shift_serializer_1.ShiftSerializer()) || this;
    }
    return DriverShiftService;
}(resource_service_1.ResourceService));
exports.DriverShiftService = DriverShiftService;
