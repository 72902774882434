"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".all-collaborators-container[_ngcontent-%COMP%]   .centered[_ngcontent-%COMP%]{margin:0 auto}.all-collaborators-container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]     .titleize{text-transform:capitalize}"];
exports.styles = styles;
