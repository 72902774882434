<div class="main-container">
  <div class="stats-container" [ngClass]="{'minimized': statsMinimized}">
    <!-- <div class="summary">
      <div class="header" translate>
        Day Summary
        <span (click)="statsMinimized=!statsMinimized">{{statsMinimized ? 'Maximize' : 'Minimize' | translate}}</span>
      </div>
      <div class="titles">
        <div class="title" translate>Drivers</div>
        <div class="title" translate>Carriers</div>
        <div class="title" translate>Trucks</div>
        <div class="title" translate>Truck Types</div>
        <div class="title" translate>Jobs</div>
        <div class="title" translate>Avg Trip Time</div>
        <div class="title" translate>Pay Hours</div>
      </div>
      <div class="stats">
        <div class="stat"
              matTooltip="{{ timelineStats.tooltipText.drivers }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip">{{ timelineStats.drivers.length }}</div>
        <div class="stat"
              matTooltip="{{ timelineStats.tooltipText.carriers }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip">{{ timelineStats.carriers.length }}</div>
        <div class="stat"
              matTooltip="{{ timelineStats.tooltipText.trucks }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip">{{ timelineStats.trucks.length }}</div>
        <div class="stat"
              matTooltip="{{ timelineStats.tooltipText.truckTypes }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip">{{ timelineStats.truckTypes.length }} Types</div>
        <div class="stat"
              matTooltip="{{ timelineStats.tooltipText.jobs }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip">{{ timelineStats.jobs.length }}</div>
        <div class="stat">{{ timelineStats.avgTripTime }}</div>
        <div class="stat">{{ timelineStats.payHours }}</div>
      </div>
    </div> -->
    <div class="legend">
      <div class="header" translate>
        Legend
        <span (click)="statsMinimized=!statsMinimized">{{statsMinimized ? 'Maximize' : 'Minimize' | translate}}</span>
      </div>
      <div class="labels">
        <timeline-legend [labels]="timelineLabels"></timeline-legend>
      </div>
    </div>
  </div>
  <div class="timeline-container" [ngClass]="{ 'open': editPanelOpen, 'stats-minimized': statsMinimized }" *ngIf="timelineRowData && timelineRange">
    <ruckit-driver-pay-timeline-list [rows]="payRecords" (selected)="selectRows($event)" (selectAllRows)="selectAllRows($event)" (editing)="editTimelineRow($event)"></ruckit-driver-pay-timeline-list>
    <timeline #timeline [rows]="timelineRowData" [range]="timelineRange" [timeInterval]="timeInterval"
              [timelineConfig]="timelineConfig" (rowEdits)="updateRowData($event)"
              [selectedRowIndex]="editingRowIndex"></timeline>
    <ruckit-driver-pay-timeline-edit-panel [(selectedRow)]="editingRow" [readOnly]="readOnly"
                                            (editsMade)="updateRowData($event)"
                                            (close)="editPanelOpen = false"
                                            (reset)="resetRow(editingRowIndex)"></ruckit-driver-pay-timeline-edit-panel>
  </div>
  <span *ngIf="selectedRowIds.length" class="selection-length" translate>{{ allSelected ? 'All' : selectedRowIds.length }} Rows Selected</span>
</div>