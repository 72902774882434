"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var material_1 = require("@angular/material");
var moment = require("moment");
var pay_sheet_service_1 = require("../../pay-sheet.service");
var DriverPayTableComponent = /** @class */ (function () {
    // Dev Note: This service is used in the template for getting duration strings. Do not remove!
    function DriverPayTableComponent(paySheetService) {
        this.paySheetService = paySheetService;
        this.sortRows = new core_1.EventEmitter();
        this.selectedRowIds = [];
        this.allSelected = false;
        this.selectAll = new core_1.EventEmitter();
        this.selectedRows = new core_1.EventEmitter();
        this.rowEdits = new core_1.EventEmitter();
        this.readOnly = false;
        this.expandedRowIds = [];
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.displayedColumns = [
            'selector', 'truckNumber', 'driver', 'carrier', 'orderNumber', 'scheduledStart',
            'shiftStart', 'geofenceStart', 'punchcardStart', 'firstLoad', 'payStart', 'shiftEnd',
            'geofenceEnd', 'punchcardEnd', 'lastLoad', 'payEnd', 'totalTime', 'payTime', 'adjustment'
        ];
        this.displayedPayLineColumns = ['payLineAction', 'index', 'payStart', 'payEnd', 'payTime'];
        this.payStartOptions = ['Scheduled Start', 'Shift Start', 'Geofence Start', 'Punchcard Start', 'First Load'];
        this.payEndOptions = ['Shift End', 'Geofence End', 'Punchcard End', 'Last Load'];
        this.rowsChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DriverPayTableComponent.prototype, "rows", {
        get: function () { return this.rowsValue; },
        set: function (data) {
            this.rowsValue = data.map(function (row) {
                row.data.date = row.data.assignment &&
                    row.data.assignment.jobevent &&
                    row.data.assignment.jobevent.shift1StartTimestamp &&
                    row.data.assignment.jobevent.shift1StartTimestamp.split('T')[0].replace(/-/g, '');
                if (row.data.rowData.trips) {
                    row.data.rowData.trips.map(function (trip) { return (trip.jobEvent = row.data.assignment.jobevent); });
                }
                if (row.data.rowData.predictedTrips) {
                    row.data.rowData.predictedTrips.map(function (trip) { return (trip.jobEvent = row.data.assignment.jobevent); });
                }
                if (row.data.rowData.punchcards) {
                    row.data.rowData.punchcards.map(function (punchcard) { return (punchcard.jobEvent = row.data.assignment.jobevent); });
                }
                return row;
            });
            this.rowsChange.emit(data);
            this.selectedRowIds = [];
        },
        enumerable: true,
        configurable: true
    });
    DriverPayTableComponent.prototype.isSelected = function (row) {
        return this.selectedRowIds.findIndex(function (id) { return id === row.data.referenceId; }) > -1;
    };
    DriverPayTableComponent.prototype.selector = function (event, row) {
        if (row) {
            if (event.checked) {
                this.selectedRowIds.push(row.data.referenceId);
            }
            else {
                this.selectedRowIds = this.selectedRowIds.filter(function (v) { return (v !== row.data.referenceId); });
            }
            this.allSelected = false;
        }
        else {
            this.selectedRowIds = event.checked ? this.rows.map(function (r) { return (r.data.referenceId); }) : [];
            this.allSelected = event.checked;
        }
        this.selectedRows.emit(this.selectedRowIds);
        this.selectAll.emit(this.allSelected);
    };
    DriverPayTableComponent.prototype.expandRow = function (row) {
        if (this.expandedRow(row)) {
            this.expandedRowIds = [];
        }
        else {
            this.expandedRowIds = [row.data.referenceId];
        }
    };
    DriverPayTableComponent.prototype.expandedRow = function (row) { return this.expandedRowIds.includes(row.data.referenceId); };
    DriverPayTableComponent.prototype.changePayBasis = function (row, option) {
        if (!row.data.rowData.payLines || !row.data.rowData.payLines[0]) {
            row.data.rowData.payLines = [{}];
        }
        row.data.rowData.payAdjustmentTotal = 0;
        switch (option) {
            case 'Scheduled Start':
                row.data.rowData.payLines[0].startDatetime = row.data.assignment.uniqueStart || row.data.assignment.jobevent.shift1StartTimestamp;
                break;
            case 'Shift Start':
                row.data.rowData.payLines[0].startDatetime = row.data.assignment.shifts &&
                    row.data.assignment.shifts[0] &&
                    row.data.assignment.shifts[0].startTime;
                break;
            case 'Geofence Start':
                row.data.rowData.payLines[0].startDatetime = row.data.rowData.predictedTrips &&
                    row.data.rowData.predictedTrips[0] &&
                    row.data.rowData.predictedTrips[0].startTimeTimestamp;
                break;
            case 'Punchcard Start':
                row.data.rowData.payLines[0].startDatetime = row.data.rowData.punchcards &&
                    row.data.rowData.punchcards[0] &&
                    row.data.rowData.punchcards[0].startTimeTimestamp;
                break;
            case 'First Load':
                row.data.rowData.payLines[0].startDatetime = row.data.rowData.trips &&
                    row.data.rowData.trips[0] &&
                    row.data.rowData.trips[0].loadingCompleteTime;
                break;
            case 'Shift End':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.assignment.shifts &&
                    row.data.assignment.shifts[0] &&
                    row.data.assignment.shifts[0].endTime;
                break;
            case 'Geofence End':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.predictedTrips &&
                    row.data.rowData.predictedTrips[0] &&
                    row.data.rowData.predictedTrips[row.data.rowData.predictedTrips.length - 1].endTimeTimestamp;
                break;
            case 'Punchcard End':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.punchcards &&
                    row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1] &&
                    row.data.rowData.punchcards[row.data.rowData.punchcards.length - 1].endTimeTimestamp;
                break;
            case 'Last Load':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.trips &&
                    row.data.rowData.trips[0] &&
                    row.data.rowData.trips[row.data.rowData.trips.length - 1].endTimeTimestamp;
                break;
        }
        row.data.editing = false;
        this.rowEdits.emit([row]);
    };
    DriverPayTableComponent.prototype.createPayLine = function (row) {
        var newStartTime;
        if (row.data.rowData.payLines && row.data.rowData.payLines.length) {
            var lastPayLine = row.data.rowData.payLines[row.data.rowData.payLines.length - 1];
            newStartTime = moment(lastPayLine.endDatetime);
        }
        else {
            row.data.rowData.payLines = [];
            newStartTime = moment(row.data.assignment.jobevent.shift1StartTimestamp);
        }
        row.data.rowData.payLines.push({
            startDatetime: newStartTime.toISOString(),
            endDatetime: newStartTime.add(1, 'hours').toISOString()
        });
        this.rowEdits.emit([row]);
        if (this.payLineTable) {
            this.payLineTable.dataSource = row.data.rowData && row.data.rowData.payLines;
            this.payLineTable.renderRows();
        }
    };
    DriverPayTableComponent.prototype.deletePayLine = function (row, index) {
        row.data.rowData.payLines.splice(index, 1);
        this.rowEdits.emit([row]);
        if (this.payLineTable) {
            this.payLineTable.dataSource = row.data.rowData && row.data.rowData.payLines;
            this.payLineTable.renderRows();
        }
    };
    DriverPayTableComponent.prototype.editRow = function (row, field, event, payLineIndex) {
        var time, newTime;
        if (row.data.rowData.payLines.length === 0) {
            row.data.rowData.payLines.push({ startDatetime: null, endDatetime: null });
        }
        if (field === 'payStartTime' || field === 'payStartDate') {
            time = moment(row.data.rowData.payLines[(payLineIndex ? payLineIndex : 0)].startDatetime);
        }
        else if (field === 'payEndTime' || field === 'payEndDate' || field === 'adjustment') {
            time = moment(row.data.rowData.payLines[(payLineIndex ? payLineIndex : row.data.rowData.payLines.length - 1)].endDatetime);
        }
        if (!row.data.rowData.payAdjustmentTotal) {
            row.data.rowData.payAdjustmentTotal = 0;
        }
        switch (field) {
            case 'payStartTime':
                newTime = moment(time.format('YYYYMMDD') + event.target['value'], 'YYYYMMDDHH:mm');
                row.data.rowData.payLines[(payLineIndex ? payLineIndex : 0)].startDatetime = newTime.toISOString();
                break;
            case 'payStartDate':
                newTime = moment(event.target['value'] + time.format('HH:mm'), 'YYYY-MM-DDHH:mm');
                row.data.rowData.payLines[(payLineIndex ? payLineIndex : 0)].startDatetime = newTime.toISOString();
                break;
            case 'payEndTime':
                newTime = moment(time.format('YYYYMMDD') + event.target['value'], 'YYYYMMDDHH:mm');
                row.data.rowData.payLines[(payLineIndex ? payLineIndex : row.data.rowData.payLines.length - 1)].endDatetime = newTime.toISOString();
                break;
            case 'payEndDate':
                newTime = moment(event.target['value'] + time.format('HH:mm'), 'YYYY-MM-DDHH:mm');
                row.data.rowData.payLines[(payLineIndex ? payLineIndex : row.data.rowData.payLines.length - 1)].endDatetime = newTime.toISOString();
                break;
            case 'adjustment':
                row.data.rowData.payAdjustmentTotal = Number(event.target['value']);
                break;
            default:
                break;
        }
        this.rows[this.rows.findIndex(function (r) { return (r.id === row.id); })] = row;
        this.rowEdits.emit([row]);
    };
    DriverPayTableComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    return DriverPayTableComponent;
}());
exports.DriverPayTableComponent = DriverPayTableComponent;
