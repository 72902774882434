import { Component, Input, ViewChild } from '@angular/core';
import { CondensedTrip } from '../../condensed-trip';
import { JobEvent } from '../../../job-events/job-event';
import { NguiMapComponent } from '@ngui/map';
import { HeatmapLayer } from '@ngui/map';

import { LocationUpdateService } from '../../../jobs/locationUpdate.service';
import { JobEventService } from '../../../job-events/job-event.service';
import { JobService } from './../../../jobs/job.service';
import { mapOptions } from '../../../shared/static-data';

declare let google: any;

@Component({
  selector: 'trip-history-route',
  templateUrl: './trip-history-route.component.html',
  styleUrls: ['./trip-history-route.component.scss'],
  providers: [ LocationUpdateService, JobEventService ]
})
export class TripHistoryRouteComponent {
  tripId = '';
  jobId;
  jobEventId;
  polygons: any = [];
  mapData = true;
  coordinates = [];
  startLocation = {
    latitude: null,
    longitude: null
  };
  endLocation = {
    latitude: null,
    longitude: null
  };
  errors = [];
  @ViewChild(NguiMapComponent, { static: false }) nguiMapComponent: NguiMapComponent;
  mapCentered = false;
  heatmapPoints = [];
  @ViewChild(HeatmapLayer, { static: false }) heatmapLayer: HeatmapLayer;
  heatmap: google.maps.visualization.HeatmapLayer;
  map: google.maps.Map;
  mapOptions = {};

  @Input()
  set trip(trip: CondensedTrip) {
    if (!trip) { return; }
    this.tripId = trip.id;
    this.jobEventId = trip.jobEventId;
    this.jobId = trip.jobId;
    this.getJob();
    if (this.tripId) { this.getHeatmap(); }
  }

  constructor(
    private locationUpdateService: LocationUpdateService,
    private jobEventService: JobEventService,
    private jobService: JobService
  ) {}

  ngAfterViewInit() {
    this.mapOptions = mapOptions({
      zoom: 10,
      disableDefaultUI: true,
      fullscreenControl: false
    }, {
      'poi.business.labels.icon': true
    });
  }

  initMap(event: any) {
    if (event) { this.map = event; }
  }

  initHeatmap(event: any = null) {
    this.heatmap = event;
  }

  getHeatmap() {
    this.locationUpdateService.get({
      trip: this.tripId
    }).subscribe(locationUpdates => {
      if (locationUpdates.length && this.map) {
        this.mapData = true;
        let mapBounds = new google.maps.LatLngBounds();
        this.heatmapPoints = locationUpdates.map((locationUpdate) => {
          let lat = locationUpdate.location && locationUpdate.location.coordinates[0];
          let lng = locationUpdate.location && locationUpdate.location.coordinates[1];
          mapBounds.extend(new google.maps.LatLng(lng, lat));
          return { location: new google.maps.LatLng(lng, lat), weight: 1 };
        });
        this.map.fitBounds(mapBounds);
        if (this.map.getZoom() > 17) { this.map.setZoom(17); }
        this.getJobEventLocations(locationUpdates[0].jobevent);
      } else { this.mapData = false; }
    }, err => {
      this.mapData = false;
      this.errors = err;
    });
  }

  getJobEventLocations(jobEventId: string) {
    this.jobEventService.getJobEvent(this.jobEventId).subscribe(jobEvent => {
      const jobEventData: JobEvent = jobEvent;
      this.startLocation = {
        latitude: jobEventData.startCoordinates.latitude,
        longitude: jobEventData.startCoordinates.longitude
      };
      this.endLocation = {
        latitude: jobEventData.endCoordinates.latitude,
        longitude: jobEventData.endCoordinates.longitude
      };
    }, err => {
      this.errors = err;
    });
  }

  getJob(): void {
    this.jobService.get(this.jobId).subscribe(job => {
      let bounds = new google.maps.LatLngBounds();
      this.polygons = [];
      if (job && job.startLocation && job.startLocation.geofence) {
        if (job.startLocation.paths) {
          this.polygons.push({ paths: job.startLocation.paths });
          job.startLocation.paths.forEach((path) => {
            if (path.lat && path.lng) {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        }
      }
      if (job && job.endLocation && job.endLocation.geofence) {
        if (job.endLocation.paths) {
          this.polygons.push({ paths: job.endLocation.paths });
          job.endLocation.paths.forEach((path) => {
            if (path.lat && path.lng) {
              bounds.extend(new google.maps.LatLng(path.lat, path.lng));
            }
          });
        }
      }
    });
  }
}
