import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { WorkOrderTemplatesComponent } from './work-order-templates/work-order-templates.component';
import { WorkOrdersComponent } from './work-orders.component';

const routes: Routes = [
  {
    path: 'templates', component: WorkOrderTemplatesComponent, canActivate: [AuthGuard]
  },
  {
    path: '', component: WorkOrdersComponent, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WorkOrdersRoutingModule { }
