"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
// angular material
var material_1 = require("@angular/material");
// libraries
var lodash_1 = require("lodash");
// services
var api_service_1 = require("../shared/api.service");
var user_service_1 = require("../users/user.service");
var organization_service_1 = require("./organization.service");
var api_service_2 = require("../shared/api.service");
var preference_service_1 = require("../preferences/preference.service");
var shared_1 = require("../shared");
var attachment_service_1 = require("../attachments/attachment.service");
var organization_1 = require("./organization");
var preference_serializer_1 = require("../preferences/preference.serializer");
// components
var ab5_questions_dialog_component_1 = require("../invitation/ab5-questions/ab5-questions-dialog.component");
// constants
var app_constants_1 = require("../app.constants");
var EditOrganizationComponent = /** @class */ (function () {
    function EditOrganizationComponent(router, apiService, preferenceService, userService, organizationService, authenticationService, attachmentService, dialog) {
        this.router = router;
        this.apiService = apiService;
        this.preferenceService = preferenceService;
        this.userService = userService;
        this.organizationService = organizationService;
        this.authenticationService = authenticationService;
        this.attachmentService = attachmentService;
        this.dialog = dialog;
        this.businessLicense = app_constants_1.ORGANIZATIONBUSINESSLICENSE;
        this.insurance = app_constants_1.ORGANIZATIONINSURANCE;
        this.overweightPermit = app_constants_1.ORGANIZATIONOVERWEIGHTPERMIT;
        this.loading = true;
        this.accountUpdateLoading = false;
        this.documentLoading = false;
        this.billingLoading = true;
        this.uniqueBillingLoading = true;
        this.imageChanged = false;
        this.states = [];
        this.stateOptions = [];
        this.billingStateOptions = [];
        this.haulOptions = ['Load', 'Weight', 'Hour'];
        this.countryOptions = [];
        this.companyTypeOptions = [];
        this.errors = [];
        this.logoImage = {
            dataUri: null,
            file: null,
        };
        this.preference = new preference_serializer_1.PreferenceSerializer().fromJson({
            blob: {
                mapStyle: 'ruckit-map-style',
                markerType: 'driver-initials',
                markerStatus: 'each-segment',
                checkinOptions: {
                    ticketNumberConfig: 'optional',
                    weightConfig: 'optional',
                    ticketImageConfig: 'optional',
                    signatureImageConfig: 'optional',
                    activeTrackingConfig: 'optional',
                    qrConfig: 'optional',
                },
                checkoutOptions: {
                    ticketNumberConfig: 'optional',
                    weightConfig: 'optional',
                    ticketImageConfig: 'optional',
                    signatureImageConfig: 'optional',
                    activeTrackingConfig: 'optional',
                    qrConfig: 'optional',
                },
            },
        });
        this.preferenceKey = 'GeneralOrganizationPreferences';
        this.configOptions = [
            { name: 'Required', id: 'required' },
            { name: 'Optional', id: 'optional' },
            { name: 'Hidden', id: 'hidden' },
        ];
    }
    EditOrganizationComponent.prototype.ngOnInit = function () {
        this.user = this.authenticationService.user();
        this.getPreferences();
        this.states = this.apiService.getStates();
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name,
            };
        });
        if (this.organization && this.organization.country) {
            this.selectCountry(this.organization.country, 'organization');
        }
        else {
            this.selectCountry('US', 'organization');
        }
        if (this.organization && this.organization.billingCountry) {
            this.selectCountry(this.organization.billingCountry, 'billing');
        }
        else {
            this.selectCountry('US', 'billing');
        }
        this.companyTypeOptions = this.apiService
            .getCompanyTypes()
            .map(function (companyType) {
            return { id: companyType.id, name: companyType.name };
        });
        this.loading = false;
        this.billingLoading = false;
        this.uniqueBillingLoading = false;
    };
    EditOrganizationComponent.prototype.ngOnDestroy = function () {
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
    };
    EditOrganizationComponent.prototype.saveOrganization = function () {
        var _this = this;
        this.errors = [];
        this.loading = true;
        var _organization = lodash_1.cloneDeep(this.organization);
        delete _organization.tags;
        Object.keys(_organization).forEach(function (key) { return _organization[key] == null && delete _organization[key]; });
        this.organizationService.save(_organization).subscribe(function (organization) {
            _this.organization = new organization_1.Organization(organization);
            _this.router.navigate(['/settings', 'organization']);
            _this.savePreferences();
            _this.editOrganization.form.markAsPristine();
            _this.editOrganization.form.markAsUntouched();
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.loading = false;
        });
    };
    EditOrganizationComponent.prototype.saveBilling = function () {
        var _this = this;
        this.billingLoading = true;
        var _organization = lodash_1.cloneDeep(this.organization);
        delete _organization.tags;
        this.organizationService.save(_organization).subscribe(function () {
            _this.billingLoading = false;
            _this.editBilling.form.markAsPristine();
            _this.editBilling.form.markAsUntouched();
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.billingLoading = false;
        });
    };
    EditOrganizationComponent.prototype.saveUniqueBillingId = function () {
        var _this = this;
        this.uniqueBillingLoading = true;
        this.organizationService
            .save({
            id: this.organization.id,
            uniqueBillingId: this.organization.uniqueBillingId,
        })
            .subscribe(function () {
            _this.uniqueBillingLoading = false;
            _this.editUniqueBilling.form.markAsPristine();
            _this.editUniqueBilling.form.markAsUntouched();
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.uniqueBillingLoading = false;
        });
    };
    EditOrganizationComponent.prototype.selectCountry = function (country, area, onInit) {
        if (area === void 0) { area = 'organization'; }
        if (onInit === void 0) { onInit = true; }
        var filteredStates = lodash_1.filter(this.states, { country: country }).map(function (state) {
            return {
                id: state.abbreviation,
                name: state.name,
            };
        });
        if (area === 'organization') {
            this.stateOptions = filteredStates;
            this.organization.country = country;
            if (!onInit) {
                this.organization.state =
                    filteredStates && filteredStates.length > 0
                        ? filteredStates[0].id
                        : null;
            }
        }
        else {
            this.billingStateOptions = filteredStates;
            this.organization.billingCountry = country;
            if (!onInit) {
                this.organization.billingState =
                    filteredStates && filteredStates.length > 0
                        ? filteredStates[0].id
                        : null;
            }
        }
    };
    EditOrganizationComponent.prototype.selectHaulType = function (event) {
        this.organization.defaultHaulRateType = event.toLowerCase();
    };
    EditOrganizationComponent.prototype.defaultHaulRateChange = function (event) {
        var value = Number(event.target.value);
        if (value >= 0) {
            if (value !== this.organization.defaultHaulRate) {
                var newValue = Math.floor(value * 100) / 100;
                event.target.value = newValue;
                this.organization.defaultHaulRate = newValue;
            }
        }
        else {
            this.organization.defaultHaulRate = 0;
            event.target.value = 0;
        }
        this.editPreferences.form.markAsDirty();
    };
    EditOrganizationComponent.prototype.selectCheckinOption = function (field, event) {
        this.preference.blob['checkinOptions'][field] = event.value;
    };
    EditOrganizationComponent.prototype.onAutoShiftEndChange = function (event) {
        // this.organization.shiftMonitorAutoClose = event.target.checked;
    };
    EditOrganizationComponent.prototype.onShiftMonitorIntervalChange = function (event) {
        this.organization.features.shiftMonitorAutoCloseInterval =
            event > 0 ? event : 0;
    };
    EditOrganizationComponent.prototype.selectCheckoutOption = function (field, event) {
        this.preference.blob['checkoutOptions'][field] = event.value;
    };
    EditOrganizationComponent.prototype.fileChange = function (e) {
        var _this = this;
        var logoImage = this.logoImage;
        var attachmentId = this.attachmentService.getFirstAttachmentIdFromAttachments(this.organization.attachments, app_constants_1.ORGANIZATIONIMAGETYPE);
        var reader = new FileReader();
        var file = e.srcElement.files[0];
        logoImage.file = file;
        if (attachmentId) {
            file.id = attachmentId;
        }
        reader.onload = function (loadEvent) {
            logoImage.dataUri = loadEvent.target.result;
            file.file = reader.result;
            file.src = reader.result;
            _this.attachmentService
                .saveOrganizationAttachment(_this.organization.id, file, app_constants_1.ORGANIZATIONIMAGETYPE)
                .subscribe(function (res) {
                console.log(res);
            }, function (err) {
                console.log(err);
            });
        };
        reader.readAsDataURL(file);
        this.imageChanged = true;
    };
    EditOrganizationComponent.prototype.getPreferences = function () {
        var _this = this;
        if (this.preference && this.preference.id) {
            this.preferenceService.get(this.preference.id).subscribe(function (preference) {
                _this.preference = preference;
                _this.setupOrganizationCheckinFeatures(preference);
            });
        }
        else {
            this.preferenceService
                .list({
                name: this.preferenceKey,
                type: 'organization',
                organization: this.organization && this.organization.id,
            })
                .subscribe(function (preferences) {
                if (preferences && preferences.length) {
                    var preference = preferences[0];
                    if (preference && preference.blob) {
                        _this.preference = __assign({}, preference);
                        _this.setupOrganizationCheckinFeatures(preference);
                    }
                }
                else {
                    _this.setupOrganizationCheckinFeatures(_this.preference);
                }
            });
        }
    };
    EditOrganizationComponent.prototype.savePreferences = function () {
        var _this = this;
        if (this.preferenceKey && this.organization) {
            this.loading = true;
            this.preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'organization', organization: this.organization && this.organization.id });
            this.preferenceService.save(this.preference).subscribe(function (preference) {
                _this.preference = preference;
                _this.loading = false;
            });
        }
    };
    EditOrganizationComponent.prototype.setupOrganizationCheckinFeatures = function (preference) {
        // legacy)
        if (this.organization.features && this.organization.features.checkinOptions === undefined) {
            this.organization.features.checkinOptions = preference.blob.checkinOptions;
        }
        if (this.organization.features && this.organization.features.checkoutOptions === undefined) {
            this.organization.features.checkoutOptions = preference.blob.checkoutOptions;
        }
    };
    EditOrganizationComponent.prototype.openAB5Questions = function () {
        var _this = this;
        var dialog = this.dialog.open(ab5_questions_dialog_component_1.AB5QuestionsDialogComponent, {
            width: '660px',
        });
        dialog.componentInstance.callback = function () {
            _this.organization.isAb5Compliant = true;
        };
    };
    EditOrganizationComponent.prototype.setExpirationDate = function (e, type) {
        this.organization[type + 'ExpirationDate'] =
            e[0] && typeof e[0].toISOString === 'function'
                ? e[0].toISOString()
                : e[0];
    };
    EditOrganizationComponent.prototype.uploadAB5Document = function (type, e, propertyName) {
        var _this = this;
        this.documentLoading = true;
        if (e.target['files'] && e.target['files'].length > 0) {
            var file_1 = e.target['files'][0];
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                file_1.file = reader_1.result;
                file_1.src = reader_1.result;
                _this.attachmentService
                    .saveOrganizationAttachment(_this.organization.id, file_1, type)
                    .subscribe(function (res) {
                    _this.organization[propertyName] = res.file;
                }, function (err) {
                    console.log(err);
                }, function () { return (_this.documentLoading = false); });
            };
            reader_1.readAsDataURL(file_1);
        }
    };
    EditOrganizationComponent.prototype.removeAB5Document = function (type, fileType, legacyType) {
        var _this = this;
        var _a;
        var attachmentsToDelete = this.organization.attachments.filter(function (a) { return a.fileType === fileType; });
        if (attachmentsToDelete.length > 0) {
            attachmentsToDelete.forEach(function (attachment) {
                _this.attachmentService.deleteAttachment(attachment.id).subscribe(function () {
                    _this.organization[legacyType] = null;
                });
            });
        }
        else { // for legacy documents;
            this.organizationService
                .patch((_a = {}, _a[type] = '', _a), this.organization.id)
                .subscribe(function () {
                _this.organization[legacyType] = null;
            });
        }
    };
    EditOrganizationComponent.prototype.updateAccount = function () {
        var _this = this;
        this.accountUpdateLoading = true;
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
        this.userReq = this.userService.get(this.user.id).subscribe(function (user) {
            _this.authenticationService.storeUser(Object.assign(_this.user, {
                features: user.features,
                enabledFeatures: user.enabledFeatures,
                organization: user.organization,
            }));
            _this.organization = _this.user.organization;
            _this.accountUpdateLoading = false;
        }, function (err) {
            _this.accountUpdateLoading = false;
        });
    };
    return EditOrganizationComponent;
}());
exports.EditOrganizationComponent = EditOrganizationComponent;
