"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var api_service_1 = require("../../shared/api.service");
var StatePipe = /** @class */ (function () {
    function StatePipe(apiService) {
        this.apiService = apiService;
    }
    StatePipe.prototype.transform = function (value) {
        return this.apiService
            .getStates()
            .find(function (state) { return state.abbreviation === value; });
    };
    return StatePipe;
}());
exports.StatePipe = StatePipe;
