import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { PaystubService } from './paystub.service';
import { OrganizationService } from '../organizations/organization.service';
import { parseErrors } from '../shared/api.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'ruckit-edit-paystub',
  templateUrl: './edit-paystub.component.html',
  styleUrls: ['./edit-paystub.component.scss'],
  providers: [PaystubService, OrganizationService]
})
export class EditPaystubComponent implements OnInit {
  paystub: any;
  loading = true;
  editing = false;
  errors = [];
  search;
  confirmDialog: MatDialogRef<any>;
  createdAt: Date;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paystubService: PaystubService,
    private organizationService: OrganizationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.params
      .subscribe((params: Params) => this.getPaystub(params['id']));
  }

  getPaystub(id) {
    if (id) {
      this.paystubService.getPaystub(id).subscribe((res) => {
        this.paystub = res;
        let _createdAt = this.paystub.createdAt && new Date(this.paystub.createdAt);
        if (_createdAt) {
          this.createdAt = _createdAt;
        }
        this.loading = false;
      }, err => {
        this.errors = parseErrors(err);
      });
    }
  }

  updatePaystub(update = false) {
    if (update) { this.getPaystub(this.paystub.id); }
  }

  fileChange(e) {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (_e) => {
      this.paystub.ownerOrganization.image = _e.target['result'];
    };
    reader.readAsDataURL(file);
    this.organizationService.saveOnlyImage(this.paystub.ownerOrganization.id, file).subscribe((organization) => {
      this.save();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  save() {
    this.loading = true;
    this.paystubService.save(this.paystub).subscribe(() => {
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  submit() {
    this.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Send Paystub?',
      body: 'Are you sure you want to send this paystub? You will no longer be able to edit the paystub once it has been sent.',
      close: 'Cancel',
      accept: 'Send'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.paystubService.save(this.paystub).subscribe(() => {
          this.paystubService.send(this.paystub).subscribe(() => {
            this.loading = false;
            this.router.navigate(['/paystubs/filter/paid']);
          }, (err) => {
            this.errors = parseErrors(err);
            this.loading = false;
          });
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
      this.confirmDialog = null;
    });
  }

  onDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.createdAt = values[0];
      this.paystub.createdAt = values[0];
    }
  }

  changeSearch(term?: string) {
    this.search = term || '';
  }
}
