"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var environment_1 = require("../../environments/environment");
var http_1 = require("@angular/common/http");
var app_utilities_1 = require("./app-utilities");
var ResourceService = /** @class */ (function () {
    function ResourceService(http, endpoint, serializer) {
        this.http = http;
        this.endpoint = endpoint;
        this.serializer = serializer;
        this.baseUrl = environment_1.environment.serverUrl;
        this.listAllProgress = new rxjs_1.Subject();
        this.allSelected = false;
        this.mockEndpoint = false;
        this.mockSearchKeys = ['name'];
        this.resourceUrl = '';
        this.resourceUrl = this.baseUrl + this.endpoint;
        if (this.endpoint.includes('LOCAL:')) {
            var parts = this.endpoint.split('LOCAL:');
            if (parts) {
                this.resourceUrl = parts[parts.length - 1];
            }
        }
        this.listAllProgress.next(0);
    }
    ResourceService.prototype.list = function (query) {
        var _this = this;
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        else if (this.endpoint.includes('LOCAL:')) {
            // Do not manipulate the URL
        }
        else {
            this.resourceUrl = this.baseUrl + this.endpoint;
        }
        return this.http.get(this.resourceUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.filterLocally(data, params); }), operators_1.map(function (data) { return _this.paginateLocally(data, params); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.listNext = function (nextUri, query) {
        var _this = this;
        if (nextUri === void 0) { nextUri = null; }
        if (!nextUri) {
            nextUri = this.nextUri;
        }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        if (this.nextUri) {
            return this.http.get(nextUri, {
                headers: this.requestHeaders(),
                params: params
            }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
        }
        else {
            return null;
        }
    };
    ResourceService.prototype.listUpdate = function (timer, query) {
        var _this = this;
        if (timer === void 0) { timer = 30000; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        return rxjs_1.timer(0, timer).pipe(operators_1.switchMap(function () { return _this.http.get(_this.resourceUrl, {
            headers: _this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (data) { return _this.filterLocally(data, params); }), operators_1.map(function (data) { return _this.paginateLocally(data, params); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.catchError(function (res) { return _this.handleError(res); })); }));
    };
    ResourceService.prototype.listAll = function (pageSize, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 50; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        params = params.set('page_size', '1');
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        var requestCount = 0;
        this.listAllProgress.next(0);
        return this.http.get(this.resourceUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return (_this.captureMetaData(res)); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(_this.resourceUrl, {
                    headers: _this.requestHeaders(),
                    params: params
                }).map(function (res) {
                    _this.listAllProgress.next(1);
                    return _this.captureMetaData(res);
                }));
            }
            else {
                _this.listAllProgress.next(1 / Math.ceil(_this.count / pageSize));
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(_this.resourceUrl, {
                        headers: _this.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        requestCount++;
                        _this.listAllProgress.next(requestCount / Math.ceil(_this.count / pageSize));
                        return res;
                    }), operators_1.map(function (res) { return _this.captureMetaData(res); })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return _this.convertData(mergedList);
        }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.listAllUpdate = function (pageSize, timer, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 50; }
        if (timer === void 0) { timer = 30000; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        params = params.set('page_size', '1');
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        return rxjs_1.timer(0, timer).pipe(operators_1.switchMap(function () { return _this.http.get(_this.resourceUrl, {
            headers: _this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return (_this.captureMetaData(res)); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(_this.resourceUrl, {
                    headers: _this.requestHeaders(),
                    params: params
                }).map(function (res) {
                    return _this.captureMetaData(res);
                }));
            }
            else {
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(_this.resourceUrl, {
                        headers: _this.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        return res;
                    }), operators_1.map(function (res) { return _this.captureMetaData(res); })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return _this.convertData(mergedList);
        }), operators_1.catchError(function (res) { return _this.handleError(res); })); }));
    };
    ResourceService.prototype.get = function (id, query, useId) {
        var _this = this;
        if (useId === void 0) { useId = true; }
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        var resourceUrl = useId ? "" + this.resourceUrl + id + "/" : "" + this.resourceUrl;
        return this.http.get(resourceUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res, false); }), operators_1.map(function (data) { return _this.convertRecord(data); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.getUpdate = function (timer, id) {
        var _this = this;
        if (timer === void 0) { timer = 30000; }
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        var resourceUrl = "" + this.resourceUrl + id + "/";
        return rxjs_1.timer(0, timer).pipe(operators_1.switchMap(function () { return _this.http.get(resourceUrl, {
            headers: _this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res, false); }), operators_1.map(function (data) { return _this.convertRecord(data); }), operators_1.catchError(function (res) { return _this.handleError(res); })); }));
    };
    ResourceService.prototype.save = function (model, query, raw) {
        var _this = this;
        if (raw === void 0) { raw = false; }
        var resourceUrl = this.resourceUrl;
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        model = lodash_1.clone(model);
        if (!raw) {
            model = this.serializer.toJson(model);
        }
        if (!model.id) {
            delete model.id;
            return this.http.post(resourceUrl, model, {
                headers: this.requestHeaders(),
                params: params
            }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
        }
        else {
            return this.http.put("" + resourceUrl + model.id + "/", model, {
                headers: this.requestHeaders(),
                params: params
            }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
        }
    };
    ResourceService.prototype.remove = function (model) {
        var resourceUrl = this.resourceUrl;
        var id = typeof model === 'string' ? model : model.id;
        return this.http.delete("" + resourceUrl + id + "/", {
            headers: this.requestHeaders()
        });
    };
    ResourceService.prototype.listFilters = function (slug, query) {
        var _this = this;
        var filtersUrl = "" + this.resourceUrl + slug + "/";
        var params = app_utilities_1.AppUtilities.getParamsFromQuery(query);
        if (!params.has('page_size')) {
            params = params.set('page_size', '6');
        }
        return this.http.get(filtersUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (res) { return _this.convertData(res); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    ResourceService.prototype.getExportFields = function (customUrl) {
        if (customUrl === void 0) { customUrl = null; }
        var exportFieldUrl = "" + this.resourceUrl + 'pandas-export/';
        if (customUrl && customUrl.length) {
            exportFieldUrl = "" + this.baseUrl + customUrl;
        }
        return this.http.get(exportFieldUrl, { headers: this.requestHeaders() }).pipe(operators_1.map(function (res) { return lodash_1.sortBy(res, 'label'); }), operators_1.catchError(this.handleError));
    };
    ResourceService.prototype.export = function (body, query, endpointUrl, customUrl) {
        var _this = this;
        if (query === void 0) { query = null; }
        if (endpointUrl === void 0) { endpointUrl = 'export/'; }
        if (customUrl === void 0) { customUrl = null; }
        var customExportSupported = ['punchcards', 'trips', 'cybertrips', 'shifts'].some(function (endpoint) { return _this.resourceUrl.includes(endpoint); });
        endpointUrl = (customExportSupported ? 'pandas-export/' : endpointUrl);
        var exportUrl = "" + this.resourceUrl + endpointUrl;
        if (customUrl && customUrl.length) {
            exportUrl = "" + this.baseUrl + customUrl;
        }
        var params = new http_1.HttpParams();
        if (body.endpoint && body.endpoint.includes('operators')) {
            exportUrl = this.resourceUrl;
            exportUrl = exportUrl.replace('assignments/all/', 'assignments/operators/');
            var excludedParams_1 = ['include', 'exclude', 'endpoint'];
            var allParams_1 = __assign({}, body, query);
            Object.keys(allParams_1).forEach(function (key) {
                if (!excludedParams_1.includes(key) && allParams_1[key]) {
                    params = params.set(key, allParams_1[key].toString());
                }
            });
        }
        else if (query) {
            if (typeof query === 'string') {
                exportUrl = exportUrl + '?' + query;
            }
            else {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params = params.set(key, query[key].toString());
                    }
                });
            }
        }
        if (exportUrl.includes('all/')) {
            exportUrl = exportUrl.replace('all/', '');
        }
        return this.http.post(exportUrl, body, {
            responseType: 'text',
            headers: this.requestHeaders(),
            params: params
        });
    };
    ResourceService.prototype.downloadCSV = function (filename, data) {
        var csvBase = ['"' + Object.keys(data[0]).join('","') + '"'];
        data.forEach(function (o) { return (csvBase.push('"' + Object.keys(o).map(function (v) { return (o[v]); }).join('","') + '"')); });
        var csv = csvBase.join('\n').replace(/(^\[)|(\]$)/mg, '');
        var link = document.createElement('a');
        var encodedUri = encodeURIComponent(csv);
        document.body.appendChild(link);
        link.href = 'data:text/csv;charset=UTF-8,' + encodedUri;
        link.download = filename + '.csv';
        link.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(encodedUri);
            document.body.removeChild(link);
        }, 0);
        return csv;
    };
    ResourceService.prototype.captureMetaData = function (res, paginated) {
        if (paginated === void 0) { paginated = true; }
        var json = res;
        if (paginated) {
            this.nextUri = json['next'];
            this.previousUri = json['previous'];
        }
        this.count = json['count'] || json['results'] && json['results'].length;
        this.unreadCount = json['unread_count'] || 0;
        this.mockEndpoint = json['mock'];
        this.mockSearchKeys = json['mockSearchKeys'];
        this.metaData = json['meta'];
        return json.results || json;
    };
    ResourceService.prototype.convertData = function (data) {
        var _this = this;
        return data && data.map(function (item) { return _this.serializer.fromJson(item); });
    };
    ResourceService.prototype.convertRecord = function (data) {
        return this.serializer.fromJson(data);
    };
    ResourceService.prototype.filterLocally = function (data, params) {
        var _this = this;
        var ignoredKeys = ['ordering', 'filters', 'page', 'page_size'];
        if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
            params.forEach(function (values, key) {
                if (values && values.length && !ignoredKeys.includes(key)) {
                    data = lodash_1.filter(data, function (o) {
                        if (o.hasOwnProperty(key)) {
                            var value = values.join(',');
                            var property = o[key];
                            if (property && value) {
                                return property.toLowerCase() === value.toLowerCase();
                            }
                            else {
                                return false;
                            }
                        }
                        else if (key === 'search') {
                            var filter = false;
                            for (var _i = 0, _a = _this.mockSearchKeys; _i < _a.length; _i++) {
                                var mockKey = _a[_i];
                                if (o.hasOwnProperty(mockKey)) {
                                    var value = values.join(',');
                                    var property = o[mockKey];
                                    if (property && value) {
                                        filter = property.toLowerCase() === value.toLowerCase();
                                    }
                                    if (filter) {
                                        break;
                                    }
                                }
                            }
                            return filter;
                        }
                        else {
                            return true;
                        }
                    });
                }
                else if (values && values.length && key === 'filters') {
                    var _values = lodash_1.clone(values);
                    _values.filter(Boolean).forEach(function (value) {
                        value = value.replace(/\(|\)/g, '');
                        var _a = value.split('='), _key = _a[0], _value = _a[1];
                        data = lodash_1.filter(data, function (o) {
                            if (o.hasOwnProperty(_key)) {
                                var property = o[_key];
                                if (property && _value) {
                                    return property.toLowerCase() === _value.toLowerCase();
                                }
                                else {
                                    return false;
                                }
                            }
                        });
                    });
                }
            });
            this.count = data.length;
        }
        return data;
    };
    ResourceService.prototype.paginateLocally = function (data, params) {
        if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
            this.count = lodash_1.clone(data.length);
            var _params_1 = {};
            params.forEach(function (values, key) {
                if (values && values.length) {
                    _params_1[key] = values.join(',');
                }
            });
            if ((_params_1['page'] && _params_1['page'] !== '1') || !_params_1['page']) {
                _params_1['recordsToSkip'] = (_params_1['page'] - 1) * _params_1['page_size'];
            }
            else {
                _params_1['recordsToSkip'] = 0;
            }
            data = data.slice(_params_1['recordsToSkip'], _params_1['recordsToSkip'] + _params_1['page_size']);
        }
        return data;
    };
    ResourceService.prototype.mergeFilters = function (filters) {
        var filterString = filters.map(function (filter) {
            if (filter.multiple && filter.values) {
                return filter.values.map(function (value) {
                    var _value = [filter.key, value].join('=');
                    return "(" + _value + ")";
                }).filter(Boolean).join('|');
            }
            else if (filter.customField && filter.values) {
                var values = filter.values;
                if (values === true) {
                    values = 'None';
                    filter.displayValues = 'None';
                }
                else {
                    filter.displayValues = values;
                }
                var _value = [filter.key, values].join(',');
                return "custom_field=" + _value;
            }
            else if (filter.values) {
                var values = filter.values;
                if (values === true) {
                    values = 'True';
                }
                if (values === false) {
                    values = 'False';
                }
                var _value = [filter.key, values].join('=');
                return "" + _value;
            }
        }).filter(Boolean).join('&');
        return '(' + filterString + ')';
    };
    ResourceService.prototype.requestHeaders = function (xhr) {
        if (xhr === void 0) { xhr = null; }
        var tokenString;
        var headerObject = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            var token = currentUser && currentUser.token;
            tokenString = 'Token ' + token;
        }
        if (tokenString) {
            headerObject['Authorization'] = tokenString;
        }
        if (xhr && tokenString) {
            xhr.setRequestHeader('Authorization', tokenString);
        }
        return new http_1.HttpHeaders(headerObject);
    };
    ResourceService.prototype.handleError = function (error) {
        return rxjs_1.throwError(this.parseErrors(error));
    };
    ResourceService.prototype.parseErrors = function (err) {
        var errors = [];
        if (err.status >= 500) {
            errors.push(err.statusText);
        }
        else if (typeof err._body === 'string') {
            try {
                var body = JSON.parse(err._body);
                if (body.detail) {
                    errors.push(body.detail);
                }
                else {
                    errors = this.rescurseErrorObject(body, errors);
                }
            }
            catch (e) { }
        }
        else {
            errors.push(err);
        }
        return errors;
    };
    ResourceService.prototype.rescurseErrorObject = function (obj, errors) {
        var _this = this;
        lodash_1.each(obj, function (msg, key) {
            if (Array.isArray(msg)) {
                errors = errors.concat(msg.map(function (err) { return (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err; }));
            }
            else if (typeof msg === 'string') {
                errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
            }
            else if (typeof msg === 'object') {
                errors = _this.rescurseErrorObject(msg, errors);
            }
        });
        return errors;
    };
    return ResourceService;
}());
exports.ResourceService = ResourceService;
