import { Product } from './product';
import { Organization } from '../organizations/organization';
import { ProductTypeSerializer } from '../product-types/product-type.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class ProductSerializer {

  /**
  * @param  {any} json
  * @returns Product
  */
  fromJson(json: any): Product {
    json = camelcaseKeysDeep(json);
    const product = new Product();
    if (!json) { return product; }

    product.id = json.id;
    if (json.productType && typeof (json.productType) === 'object') {
      product.productType = new ProductTypeSerializer().fromJson(json.productType);
    } else {
      product.productType = new ProductTypeSerializer().fromJson({ id: json.productType });
    }
    product.createdAt = json.createdAt;
    product.lastModified = json.lastModified;
    product.name = json.name;
    product.unitPrice = json.unitPrice;
    product.units = json.units;
    product.minTotalPrice = json.minTotalPrice;
    product.notes = json.notes;
    if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
      product.ownerOrganization = new Organization(json.ownerOrganization);
    } else {
      product.ownerOrganization = new Organization({ id: json.ownerOrganization });
    }
    product.unitOfMeasurement = json.unitOfMeasurement;
    product.discountPercentage = json.discountPercentage;
    product.number = json.number;
    product.status = json.status;
    if (json.salesTaxRate && json.salesTaxRate > 0) {
      product.salesTaxRate = json.salesTaxRate;
      product.salesTaxRateDecimal = json.salesTaxRate / 100;
    }
    return product;
  }

  /**
   * @param  {Product} product
   * @returns any
   */
  toJson(product: Product): any {
    let json = {
      id: product.id,
      productType: (product.productType && typeof product.productType === 'object') ?
        product.productType.id : product.productType,
      createdAt: product.createdAt,
      lastModified: product.lastModified,
      name: product.name,
      unitPrice: product.unitPrice,
      units: product.units,
      minTotalPrice: product.minTotalPrice,
      salesTaxRate: product.salesTaxRate || 0,
      notes: product.notes,
      ownerOrganization: product.ownerOrganization && product.ownerOrganization.id,
      unitOfMeasurement: product.unitOfMeasurement,
      discountPercentage: product.discountPercentage,
      number: product.number,
      status: product.status
    };
    return decamelizeKeysDeep(json);
  }
}
