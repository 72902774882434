import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'paystub-pay-type-dialog',
  templateUrl: './paystub-pay-type-dialog.component.html',
  styleUrls: ['./paystub-pay-type-dialog.component.scss'],
  providers: []
})
export class PaystubPayTypeDialogComponent implements OnInit {
  @Input() paystub;
  loading = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<PaystubPayTypeDialogComponent>
  ) { }

  ngOnInit() {
    this.paystub = cloneDeep(this.paystub);
  }

  submit() {
    this.callback(this.paystub);
    this.dialogRef.close();
  }
}
