<form #editJobs="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Job</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors">
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>

    <h2 translate>Rate</h2>
    <div class="rate-fields">
      <div class="field-group">
        <label translate>How is this job invoiced?</label>
        <div class="radio-buttons">
          <input type="radio" id="invoice_type_weight" name="invoice_type" [(ngModel)]="model.invoiceType"
            value="weight" (change)="invoiceTypeChanged()" />
          <label class="label" attr.data-label="{{ 'Weight' | translate }}" for="invoice_type_weight"></label>

          <input type="radio" id="invoice_type_load" name="invoice_type" [(ngModel)]="model.invoiceType" value="load"
            (change)="invoiceTypeChanged()" />
          <label class="label" attr.data-label="{{ 'Load' | translate }}" for="invoice_type_load"></label>

          <input type="radio" id="invoice_type_hour" name="invoice_type" [(ngModel)]="model.invoiceType" value="hour"
            (change)="invoiceTypeChanged()" />
          <label attr.data-label="{{ 'Hour' | translate }}" for="invoice_type_hour"></label>
        </div>
      </div>
      <div class="field-group">
        <label translate>What is the rate?</label>
        <div class="input-group" data-prefix="$">
          <input numberInRange [minValue]="0" type="text" name="job_rate" [(ngModel)]="model.rate" min="0"
            (change)="changeCloneHaulRate()" />
          <dropdown [options]="jobWeightOptions" *ngIf="model.invoiceType === 'weight'" title="-"
            (onSelect)="weightUnitChange('invoice', $event)"></dropdown>
          <div class="add-on" *ngIf="model.invoiceType !== 'weight'">{{model.invoiceType}}</div>
        </div>
        <div class="checkbox-field clone-haul-rate">
          <input type="checkbox" id="clone_haul_rate" name="clone_haul_rate" [(ngModel)]="model.cloneHaulRate"
            (change)="changeCloneHaulRate()" />
          <label attr.data-label="{{ 'Haul rate is the same' | translate }}" for="clone_haul_rate"></label>
        </div>
      </div>


      <div class="field-group haul-rate-tracking" class.disabled="model.cloneHaulRate">
        <label translate>How is the haul rate tracked?</label>
        <div class="radio-buttons">
          <input type="radio" id="haul_type_weight" name="haul_type" [(ngModel)]="model.haulType" value="weight"
            [disabled]="model.cloneHaulRate" />
          <label class="label" attr.data-label="{{ 'Weight' | translate }}" for="haul_type_weight"></label>
          <input type="radio" id="haul_type_load" name="haul_type" [(ngModel)]="model.haulType" value="load"
            [disabled]="model.cloneHaulRate" />
          <label class="label" attr.data-label="{{ 'Load' | translate }}" for="haul_type_load"></label>
          <input type="radio" id="haul_type_hour" name="haul_type" [(ngModel)]="model.haulType" value="hour"
            [disabled]="model.cloneHaulRate" />
          <label class="label" attr.data-label="{{ 'Hour' | translate }}" for="haul_type_hour"></label>
        </div>
      </div>
      <div class="field-group" class.disabled="model.cloneHaulRate">
        <label translate>What is the haul rate?</label>
        <div class="input-group" data-prefix="$">
          <input numberInRange [minValue]="0" type="text" name="haul_rate" [(ngModel)]="model.haulRate"
            [disabled]="model.cloneHaulRate" min="0" />
          <dropdown [options]="haulWeightOptions" *ngIf="model.haulType === 'weight'" title="-"
            [disabled]="model.cloneHaulRate" [config]="{rightAlign: true}"
            (onSelect)="weightUnitChange('haul', $event)"></dropdown>
          <div class="add-on" *ngIf="model.haulType !== 'weight'">{{model.haulType}}</div>
        </div>
      </div>

      <div class="field-group">
        <div class="dates">
          <div class="group">
            <label translate>Dates</label>
            <date-range-picker [config]="{decreaseControl: false, increaseControl: false, multipleDates: true}"
              [selectedDates]="model.dates" (dateChanged)="onDateChanged($event)"></date-range-picker>
          </div>
        </div>
      </div>

      <div class="field-group">
        <label translate>What material is being moved?</label>
        <input type="text" name="material" [(ngModel)]="model.material" />
      </div>

      <div class="field-group amount-ordered">
        <label translate>Total amount ordered?</label>
        <div class="input-group">
          <input type="text" name="amount_ordered" [(ngModel)]="model.amountNeeded" pattern="[^.]+"
            placeholder="Amount" />
          <dropdown [options]="orderTypeOptions" [selectedOption]="model.totalAmountType"
            (onSelect)="changeTotalAmountType($event)"></dropdown>
        </div>
      </div>

      <div class="field-group delivery-target">
        <label translate>Daily Delivery Target</label>
        <div class="input-group">
          <input type="number" placeholder="Amount" name="daily_delivery_target"
            [(ngModel)]="model.dailyDeliveryTarget" />
          <dropdown [options]="orderTypeOptions" [selectedOption]="model.dailyDeliveryTargetType"
            [config]="{rightAlign: true}" (onSelect)="changeDailyDeliveryTargetType($event)"></dropdown>
        </div>
      </div>

      <div class="rate-field-row">
        <div class="field-group stagger-start">
          <label translate>Stagger Start Time</label>
          <div class="input-group">
            <input type="number" placeholder="Time" name="delivery_interval" min="0"
              [(ngModel)]="model.deliveryInterval" (change)="setDeliveryInterval()" />
            <dropdown [options]="deliveryIntervalUnits" [selectedOption]="model.deliveryIntervalUnit"
            (onSelect)="model.deliveryIntervalUnit=$event.id"></dropdown>
          </div>
        </div>
  
        <div class="field-group checkbox-field archive-job">
          <input type="checkbox" id="archive_job" name="archive_job" [(ngModel)]="archiveJob" />
          <label attr.data-label="{{ 'Archive Job' | translate }}" for="archive_job"></label>
        </div>
        <div class="field-group"></div>
        <div class="field-group"></div>
      </div>
      
      

    </div>
    <div class="location-details">
      <div class="location loading">
        <div class="address">
          <label translate>Select Address</label>
          <ruckit-dropdown class="locations-dropdown" [config]="locationsConfig" [selectedOption]="model.startLocation"
            (onSelect)="changeLocation($event, 'start')">
          </ruckit-dropdown>
        </div>
        <div class="options">
          <label class="options-header" translate>Loading Options</label>

          <div class="config-option">
            <label>{{ 'Log Weight' | translate }}</label>
            <mat-select [(value)]="model.checkinOptions.weightConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option">
            <label>{{ 'Ticket Number' | translate }}</label>
            <mat-select [(value)]="model.checkinOptions.ticketNumberConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option">
            <label>{{ 'Ticket Photo' | translate }}</label>
            <mat-select [(value)]="model.checkinOptions.ticketImageConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option">
            <label>{{ 'Signature' | translate }}</label>
            <mat-select [(value)]="model.checkinOptions.signatureImageConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
            <label>{{ 'QR Code' | translate }}</label>
            <mat-select [(value)]="model.checkinOptions.qrConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div class="map">
        <ngui-map center="{{getCoord('start', 1)}}, {{getCoord('start', 0)}}" disableDefaultUI="true"
          disableDoubleClickZoom="true" fullscreenControl="false" mapTypeControl="false" streetViewControl="false"
          zoomControl="false" clickableIcons="false" panControl="false" [zoom]="15">
          <marker *ngIf="model.startLocation && model.startLocation?.mappable" draggable="false" [label]="''"
            [title]="model.startLocation?.name" position="{{ getCoord('start', 1) }}, {{ getCoord('start', 0) }}">
          </marker>
          <polygon *ngIf="model.startLocation" [editable]="false" [paths]="getPaths('start')">
          </polygon>
        </ngui-map>
      </div>
      <div class="location unloading">
        <div class="address">
          <label translate>Select Address</label>
          <ruckit-dropdown class="locations-dropdown" [config]="locationsConfig" [selectedOption]="model.endLocation"
            (onSelect)="changeLocation($event, 'end')">
          </ruckit-dropdown>
        </div>
        <div class="options">
          <label class="options-header" translate>Loading Options</label>

          <div class="config-option">
            <label>{{ 'Log Weight' | translate }}</label>
            <mat-select [(value)]="model.checkoutOptions.weightConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option">
            <label>{{ 'Ticket Number' | translate }}</label>
            <mat-select [(value)]="model.checkoutOptions.ticketNumberConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option">
            <label>{{ 'Ticket Photo' | translate }}</label>
            <mat-select [(value)]="model.checkoutOptions.ticketImageConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option">
            <label>{{ 'Signature' | translate }}</label>
            <mat-select [(value)]="model.checkoutOptions.signatureImageConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>

          <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
            <label>{{ 'QR Code' | translate }}</label>
            <mat-select [(value)]="model.checkoutOptions.qrConfig">
              <mat-option *ngFor="let option of configOptions" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div class="map">
        <ngui-map center="{{getCoord('end', 1)}}, {{getCoord('end', 0)}}" disableDefaultUI="true"
          disableDoubleClickZoom="true" fullscreenControl="false" mapTypeControl="false" streetViewControl="false"
          zoomControl="false" clickableIcons="false" panControl="false" [zoom]="15">
          <marker *ngIf="model.endLocation && model.endLocation?.mappable" draggable="false" [label]="''"
            [title]="model.endLocation?.name" position="{{ getCoord('end', 1) }}, {{ getCoord('end', 0) }}">
          </marker>
          <polygon *ngIf="model.endLocation" [editable]="false" [paths]="getPaths('end')">
          </polygon>
        </ngui-map>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="jobs-count" *ngIf="allJobsCount > 0">
      {{ jobsCountWarningTranslationKey | translate: {jobsCount: allJobsCount, jobtext: allJobsCount > 1 ? 'jobs': 'job'} }}
    </div>
    <div class="action-btns">
      <button class="btn btn-default" (click)="dialogRef.close(); false" translate>
        Cancel
      </button>
      <button class="btn btn-primary" (click)="submit(); false" translate
        [disabled]="isSubmitBtnDisabled(editJobs)">
        Save
      </button>
    </div>
  </div>
</form>