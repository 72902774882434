"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var message_service_1 = require("./message.service");
var MessageDriversDialogComponent = /** @class */ (function () {
    function MessageDriversDialogComponent(dialogRef, messageService) {
        this.dialogRef = dialogRef;
        this.messageService = messageService;
        this.loading = false;
        this.message = {
            msg: null,
            jobEventId: null
        };
        this.messageSent = false;
        this.buttonLabel = 'Send Message';
        this.errors = [];
    }
    MessageDriversDialogComponent.prototype.ngOnInit = function () {
        this.message.jobEventId = this.jobEventId;
    };
    MessageDriversDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.messageService.send(this.message).subscribe(function (result) {
            _this.loading = false;
            _this.messageSent = true;
            _this.buttonLabel = 'Message Sent!';
            setTimeout(function () {
                _this.dialogRef.close();
                _this.callback();
            }, 500);
        }, function (err) {
            _this.loading = false;
            _this.errors = ['Message could not be sent.'];
        });
    };
    return MessageDriversDialogComponent;
}());
exports.MessageDriversDialogComponent = MessageDriversDialogComponent;
