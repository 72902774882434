

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Driver } from '../drivers/driver';
import { DriverService } from '../drivers/driver.service';
import { Job } from '../jobs/job';
import { JobService } from '../jobs/job.service';
import { Device } from '../shared/device';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { DriverJobReportService } from './driver-job-report.service';

@Component({
  selector: 'new-job-report-dialog',
  templateUrl: './new-job-report-dialog.component.html',
  styleUrls: ['./new-job-report-dialog.component.scss'],
})
export class NewJobReportDialogComponent implements OnInit {
  device: Device = new Device();
  reportDate = new Date();
  startDate = moment(this.reportDate).startOf('day').toISOString();
  endDate = moment(this.reportDate).endOf('day').toISOString();
  @ViewChild('driverDropdown', { static: false }) driverDropdown: RuckitDropdownComponent;
  @ViewChild('jobDropdown', { static: false }) jobDropdown: RuckitDropdownComponent;
  loading = false;
  model: any = { };
  errors = [];
  callback;
  driverDropdownConfig = {
    service: DriverService,
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    query: {
      serializer: 'DefaultDropdown',
      assignments__jobevent__shift1_start__range__exists: `${this.startDate},${this.endDate}`,
      assignments__jobevent__job__exists: this.model.job && this.model.job.id
    }
  };
  jobDropdownConfig = {
    service: JobService,
    selectText: 'Select Job',
    loadingText: 'Loading Jobs...',
    noResultsText: 'No Jobs',
    query: {
      serializer: 'DefaultDropdown',
      jobevents__start__gte: this.startDate,
      jobevents__start__lte: this.endDate,
    }
  };

  constructor(
    public dialogRef: MatDialogRef<NewJobReportDialogComponent>,
    private driverJobReportService: DriverJobReportService,
    private deviceDetectorService: DeviceDetectorService,
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.updateFilters();
  }

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
    this.updateFilters();
  }

  submit() {
    this.loading = true;
    this.model.reportDate = this.reportDate;
    this.driverJobReportService.save(this.model).subscribe(() => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = err;
      this.loading = false;
    });
  }

  updateFilters() {
    this.startDate = moment(this.reportDate).startOf('day').toISOString();
    this.endDate = moment(this.reportDate).endOf('day').toISOString();
    this.driverDropdownConfig['query'] = {
      ...this.driverDropdownConfig['query'],
      assignments__jobevent__shift1_start__range__exists: `${this.startDate},${this.endDate}`,
      assignments__jobevent__job__exists: this.model.job && this.model.job.id
    };
    this.driverDropdownConfig = { ...this.driverDropdownConfig };
    if (this.driverDropdown) { this.driverDropdown.getRecords(); }
    this.jobDropdownConfig['query'] = {
      ...this.jobDropdownConfig['query'],
      jobevents__start__gte: this.startDate,
      jobevents__start__lte: this.endDate,
    };
    this.jobDropdownConfig = { ...this.jobDropdownConfig };
    if (this.jobDropdown) { this.jobDropdown.getRecords(); }
  }
}
