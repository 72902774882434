"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var surcharge_templates_component_1 = require("./surcharge-templates.component");
var routes = [
    { path: '', component: surcharge_templates_component_1.SurchargeTemplatesComponent, canActivate: [auth_guard_1.AuthGuard] },
];
var SurchargeTemplatesRoutingModule = /** @class */ (function () {
    function SurchargeTemplatesRoutingModule() {
    }
    return SurchargeTemplatesRoutingModule;
}());
exports.SurchargeTemplatesRoutingModule = SurchargeTemplatesRoutingModule;
