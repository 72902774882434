"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ngx_device_detector_1 = require("ngx-device-detector");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var shared_1 = require("../../shared");
var trip_decision_service_1 = require("../../trips/trip-decision.service");
var edit_share_dialog_component_1 = require("../../collaborators/edit-share-dialog.component");
var driver_1 = require("../driver");
var new_trip_dialog_component_1 = require("../../trips/new-trip-dialog/new-trip-dialog.component");
var DriverDailyJobsComponent = /** @class */ (function () {
    function DriverDailyJobsComponent(route, router, authenticationService, deviceDetectorService, tripDecisionService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.tripDecisionService = tripDecisionService;
        this.dialog = dialog;
        this.hideApproved = false;
        this.showVoided = false;
        this.showUnworkedJobs = false;
        this.tripTimes = [];
        this.view = 'trips';
        this.keyStatus = [];
        this.shouldAuditDecisions = new core_1.EventEmitter();
        this.rateChanged = new core_1.EventEmitter();
        this.approvedStatuses = [];
        this.loading = true;
        this.errors = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.displayedColumns = [
            'total-time', 'schedule', 'loading-ticket-number',
            'unloading-ticket-number', 'amount', 'approved-by', 'approved', 'actions'
        ];
        this.invoiceRates = [];
        this.haulRates = [];
        this.invoiceRatesWeightUnits = [];
        this.haulRatesWeightUnits = [];
        this.allSubscriptionsToUnsubscribe = [];
        this.saveTripCallback = function (trip) {
            if (_this.route.snapshot.queryParamMap.get('refreshPage')) {
                _this.router.navigate([], {
                    relativeTo: _this.route,
                    queryParams: {
                        'refreshPage': null,
                    },
                    queryParamsHandling: 'merge'
                });
            }
            else {
                _this.router.navigateByUrl(_this.router.url + "&refreshPage=true");
            }
        };
        this.saveShareCallback = function (result) {
            var jobEventShare = result.jobEventShare;
            var updates = lodash_1.pick(jobEventShare, [
                'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
                'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
            ]);
            updates['rate'] = updates['invoiceRate'];
            var jobIdx = _this.driver.jobEventShares.findIndex(function (j) { return j.jobId === jobEventShare.jobId && j.organizationId === jobEventShare.organizationId; });
            if (jobIdx > -1) {
                Object.assign(_this.driver.jobEventShares[jobIdx], updates);
            }
            _this.rateChanged.emit(true);
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    DriverDailyJobsComponent.prototype.ngOnInit = function () {
        this.organization = this.authenticationService.getOrganization();
        this.user = this.authenticationService.user();
    };
    DriverDailyJobsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DriverDailyJobsComponent.prototype.switchView = function (view) {
        this.view = view;
    };
    DriverDailyJobsComponent.prototype.updateTripData = function (trips) {
        this.driver = Object.assign(this.driver, {
            trips: this.driver.trips.map(function (t) {
                var matchedIndex = trips.findIndex(function (trip) { return (trip.id === t.id); });
                if (matchedIndex > -1) {
                    return trips[matchedIndex];
                }
                else {
                    return t;
                }
            })
        });
    };
    DriverDailyJobsComponent.prototype.anyUnapprovedTrips = function (driver, jobEventShare) {
        var status = false;
        if (driver && driver.trips.length) {
            var tripCount = driver.trips.map(function (trip) {
                return trip.jobEvent.job.id === jobEventShare.jobId && trip.latestDecisionStatus !== 'approved';
            }).filter(Boolean).length;
            status = +tripCount > 0;
        }
        return status;
    };
    DriverDailyJobsComponent.prototype.approvedTrips = function (driver, jobEventShare) {
        var count = 0;
        if (driver && driver.trips.length) {
            var tripCount = driver.trips.map(function (trip) {
                return trip.jobEvent.job.id === jobEventShare.jobId && trip.latestDecisionStatus === 'approved';
            }).filter(Boolean).length;
            count = +tripCount;
        }
        return count;
    };
    DriverDailyJobsComponent.prototype.unapprove = function (jobEventShare) {
        this.approve(jobEventShare, 'pending');
    };
    DriverDailyJobsComponent.prototype.approve = function (jobEventShare, decisionStatus) {
        var _this = this;
        if (decisionStatus === void 0) { decisionStatus = 'approved'; }
        var requests = [];
        var newStatus = false;
        var status = lodash_1.find(this.keyStatus, { driverId: this.driver.id, key: jobEventShare.jobId });
        if (status) {
            status.loading = true;
        }
        else {
            newStatus = true;
            status = { driverId: this.driver.id, key: jobEventShare.jobId, loading: true, statuses: [] };
        }
        if (this.driver) {
            this.driver.trips.forEach(function (trip) {
                var alreadyApproved = trip.latestDecisionStatus === 'approved' && decisionStatus === 'approved';
                if (trip && trip.jobEvent.job.id === jobEventShare.jobId && !alreadyApproved) {
                    trip.loading = true;
                    var data = {
                        trip: trip, decisionStatus: decisionStatus, decidedBy: _this.user,
                        organization: _this.organization, decidedAt: new Date().toISOString()
                    };
                    requests.push(_this.tripDecisionService.save(data));
                }
            });
        }
        this.allSubscriptionsToUnsubscribe.push(rxjs_1.combineLatest(requests).subscribe(function (decisions) {
            decisions.forEach(function (decision) {
                var trip = lodash_1.find(_this.driver.trips, { id: decision.trip && decision.trip.id });
                if (trip) {
                    trip.latestDecision = decision.id;
                    trip.latestDecisionStatus = decision.decisionStatus;
                    trip.latestDecider = decision.decidedBy && decision.decidedBy.id;
                    trip.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
                    status.statuses = [decision.decisionStatus];
                    trip.loading = false;
                }
            });
        }, function (errors) {
            console.log('Decision Errors: ', errors);
        }, function () {
            status.loading = false;
            _this.driver.trips = _this.driver.trips.slice();
            if (newStatus) {
                _this.keyStatus.push(status);
            }
            _this.auditDecisions();
        }));
    };
    DriverDailyJobsComponent.prototype.auditDecisions = function () {
        this.shouldAuditDecisions.emit(this.driver.id);
    };
    DriverDailyJobsComponent.prototype.openEditShare = function (change, driver, jobEventShare) {
        var dialog = this.dialog.open(edit_share_dialog_component_1.EditShareDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            dialog.componentInstance.change = change;
            dialog.componentInstance.callback = this.saveShareCallback;
            if (driver && jobEventShare) {
                dialog.componentInstance.jobEventShare = jobEventShare;
            }
        }
    };
    DriverDailyJobsComponent.prototype.openAddTrip = function (driver, jobEventShare) {
        var dialog = this.dialog.open(new_trip_dialog_component_1.NewTripDialogComponent, {
            width: '455px'
        });
        var carrier = {
            id: driver.carrierId,
            name: driver.carrierOrganizationName,
            carrier: {
                name: driver.carrierName,
                id: driver.carrierId
            }
        };
        dialog.componentInstance.jobName = jobEventShare && jobEventShare.job ? jobEventShare.job : '';
        dialog.componentInstance.lockedFields = jobEventShare && jobEventShare.job ? true : false;
        dialog.componentInstance.firstLoad = jobEventShare && jobEventShare.job ? false : true;
        dialog.componentInstance.job = {
            id: jobEventShare && jobEventShare.jobId ? jobEventShare.jobId : ''
        };
        dialog.componentInstance.model.jobevent = jobEventShare && jobEventShare.jobeventId ? jobEventShare.jobeventId : '';
        dialog.componentInstance.model.carrier = carrier;
        dialog.componentInstance.model.driver = driver;
        dialog.componentInstance.model.truck = driver.latestTruck;
        dialog.componentInstance.jobEventDate = this.reportDate;
        dialog.componentInstance.jobId = jobEventShare && jobEventShare.jobId ? jobEventShare.jobId : '';
        var trip = driver.trips.find(function (t) { return t.jobEvent.job.id === jobEventShare.jobId && t.jobEvent.job.project.ownerOrganization === jobEventShare.organizationId; });
        if (trip) {
            dialog.componentInstance.tripJobEvent = trip.jobEvent;
        }
        dialog.componentInstance.callback = this.saveTripCallback;
    };
    return DriverDailyJobsComponent;
}());
exports.DriverDailyJobsComponent = DriverDailyJobsComponent;
