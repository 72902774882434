"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var flex_layout_1 = require("@angular/flex-layout");
var router_1 = require("@angular/router");
var trip_service_1 = require("../trip.service");
var operators_1 = require("rxjs/operators");
var checkin_service_1 = require("../../checkins/checkin.service");
var TripHistoryComponent = /** @class */ (function () {
    function TripHistoryComponent(mediaObserver, route, tripService, checkinService) {
        this.mediaObserver = mediaObserver;
        this.route = route;
        this.tripService = tripService;
        this.checkinService = checkinService;
        this.detailsHeight = 14;
        this.breakpoint = 3;
        this.breakpoints = {
            'xs': 1,
            'sm': 1,
            'md': 2,
            'lg': 2,
            'xl': 2,
        };
        this.activeMediaQuery = '';
    }
    TripHistoryComponent.prototype.ngOnInit = function () {
        this.setupBreakpoints();
        this.getTrip();
    };
    TripHistoryComponent.prototype.ngOnDestroy = function () {
        this.watcher.unsubscribe();
    };
    TripHistoryComponent.prototype.setupBreakpoints = function () {
        var _this = this;
        this.watcher = this.mediaObserver.media$.subscribe(function (change) {
            _this.activeMediaQuery = change ? "'" + change.mqAlias + "' = (" + change.mediaQuery + ")" : '';
            var breakpoint = change.mqAlias;
            _this.breakpoint = _this.breakpoints[breakpoint];
        });
    };
    TripHistoryComponent.prototype.getTrip = function () {
        var _this = this;
        var queryParams = this.route.snapshot.queryParams;
        this.returnToUrl = queryParams['returnTo'] ? queryParams['returnTo'] : '/trips';
        this.route.params.pipe(operators_1.switchMap(function (params) {
            var id = params.id;
            return _this.tripService.getCondensedTrip(id);
        })).subscribe(function (trip) {
            var detailsHeight = 14;
            if (!trip.loadingTicketImage) {
                detailsHeight = detailsHeight - 2;
            }
            if (!trip.unloadingTicketImage) {
                detailsHeight = detailsHeight - 2;
            }
            if (!trip.unloadingSignature) {
                detailsHeight = detailsHeight - 2;
            }
            if (!trip.loadingSignature) {
                detailsHeight = detailsHeight - 2;
            }
            _this.trip = trip;
            _this.detailsHeight = detailsHeight;
            _this.title = "History - " + trip.driver + " / " + trip.truckName;
            _this.getCheckins(trip.id, 'loading');
            _this.getCheckins(trip.id, 'unloading');
        });
    };
    TripHistoryComponent.prototype.getCheckins = function (tripId, kind) {
        var _this = this;
        if (kind === void 0) { kind = 'loading'; }
        this.checkinService.getCheckins({
            ordering: 'name',
            kind: kind,
            trip: tripId
        }).subscribe(function (checkins) {
            if (checkins && checkins.length) {
                var checkin = checkins[0];
                switch (kind) {
                    case 'loading':
                        _this.trip.loadingCheckin = checkin;
                        break;
                    case 'unloading':
                        _this.trip.unloadingCheckin = checkin;
                        break;
                }
            }
        });
    };
    return TripHistoryComponent;
}());
exports.TripHistoryComponent = TripHistoryComponent;
