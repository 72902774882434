import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Surcharge } from './surcharge';
import { SurchargeSerializer } from './surcharge.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class SurchargeService extends ResourceService<Surcharge> {
  constructor(http: HttpClient) {
    super(http, 'surcharges/', new SurchargeSerializer());
  }
}
