"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var DataService = /** @class */ (function () {
    function DataService() {
        this.dataSource = new rxjs_1.BehaviorSubject({});
        this.data$ = this.dataSource.asObservable();
    }
    DataService.prototype.changeData = function (data) {
        this.dataSource.next(data);
    };
    return DataService;
}());
exports.DataService = DataService;
