const camelcaseKeysDeep = require('camelcase-keys-deep');

export class CheckinOption {
  type: string;
  states: any;

  activeTrackingConfig: 'required' | 'optional' | 'hidden';
  loadImageConfig: 'required' | 'optional' | 'hidden';
  qrConfig: 'required' | 'optional' | 'hidden';
  signatureImageConfig: 'required' | 'optional' | 'hidden';
  ticketImageConfig: 'required' | 'optional' | 'hidden';
  ticketNumberConfig: 'required' | 'optional' | 'hidden';
  weightConfig: 'required' | 'optional' | 'hidden';

  constructor(json: any) {
    json = camelcaseKeysDeep(json);

    this.type = json.type || 'checkin';
    this.states = {
      ticket_number: false,
      ticket_image: false,
      signature_image: false,
      active_tracking: false,
      log_weight: false,
      qr_code: false
    };
    this.activeTrackingConfig = json.activeTrackingConfig || 'optional';
    this.loadImageConfig = json.loadImageConfig || 'optional';
    this.qrConfig = json.qrConfig || 'optional';
    this.signatureImageConfig = json.signatureImageConfig || 'optional';
    this.ticketImageConfig = json.ticketImageConfig || 'optional';
    this.ticketNumberConfig = json.ticketNumberConfig || 'optional';
    this.weightConfig = json.weightConfig || 'optional';
  }
}
