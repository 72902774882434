import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'scaleit-confirm-dialog',
  templateUrl: './scaleit-confirm-dialog.component.html',
  styleUrls: ['./scaleit-confirm-dialog.component.scss']
})
export class ScaleitConfirmDialogComponent {
  attributes: any = {};

  constructor(
    public confirmDialog: MatDialogRef<any>
  ) {
    this.attributes = {
      title: 'Confirm your action!',
      body: 'Are you sure you wish to do this?',
      yes: 'Yes',
      no: 'No'
    };
  }
}
