import { EventEmitter } from '@angular/core';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class FilterOption {
  key: string;
  title: string;
  options: any[];
  count: number;
  perPage: number;
  filterType: string;
  ordering: string;
  multiple =  false;
  idProperty: string;
  values = null;
  displayValues = null;
  service;
  slug: string;
  request;
  nextUri: string;
  errors: any[];
  loading = false;
  hidden = false;
  default = false;
  withNullOption: string|boolean = false;
  onlyName = false;
  customField = false;
  searchable = false;
  searching = false;
  searchKey: string;
  search: string;
  searchChange: EventEmitter<string> = new EventEmitter();
  query: any;
  removeFromFiltersQuery = false;

  constructor(data: any) {
    const rawData = data;
    data = camelcaseKeysDeep(data);

    this.key = data.key;
    this.title = data.title;
    this.options = data.options;
    this.count = data.count || 0;
    this.perPage = data.perPage || 6;
    this.filterType = data.filterType; // select, toggle, date
    this.ordering = data.ordering || 'name';
    this.multiple = data.multiple;
    this.values = data.values;
    this.idProperty = data.idProperty;
    this.displayValues = data.displayValues ? data.displayValues : data.values;
    this.service = data.service;
    this.slug = data.slug;
    this.nextUri = data.nextUri;
    this.hidden = data.hidden;
    this.default = data.default;
    this.withNullOption = data.withNullOption;
    this.onlyName = data.onlyName;
    this.customField = data.customField;
    this.searchable = data.searchable;
    this.searching = data.searching;
    this.search = data.search;
    this.searchKey = this.key + 'Search';
    this.query = rawData.query;
    this.removeFromFiltersQuery = data.removeFromFiltersQuery;
  }
}
