import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { LocationTypeService } from '../location-type.service';
import { LocationType } from '../location-type';

@Component({
  selector: 'location-manager-filters-dialog',
  templateUrl: './location-manager-filters-dialog.component.html',
  styleUrls: ['./location-manager-filters-dialog.component.scss']
})
export class LocationManagerFiltersDialogComponent {
  loading = false;
  errors = [];
  callback: Function;
  model = {
    location_type: null
  };
  locationTypeDropdownConfig = {
    searchable: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: 'Select Location Type',
    loadingText: 'Loading Location Types...',
    noResultsText: 'No Location Types',
    service: LocationTypeService,
    query: {}
  };

  constructor(
    public dialogRef: MatDialogRef<LocationManagerFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.model = this.data && this.data.model;
    this.locationTypeDropdownConfig = { ...this.locationTypeDropdownConfig };
  }

  submit(): void {
    this.callback(this.model);
    this.dialogRef.close();
  }

  selectLocationType(locationType: LocationType, userSelection = true): void {
    if (!userSelection) { return; }
    this.model.location_type = locationType;
  }
}
