"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TagDisplayComponent = /** @class */ (function () {
    function TagDisplayComponent() {
        this.tags = [];
        this.label = 'Tags';
        this.tagNames = '';
    }
    TagDisplayComponent.prototype.ngOnInit = function () {
        this.tagNames = this.tags && this.tags.map(function (tag) { return tag.name; }).join(', ');
    };
    return TagDisplayComponent;
}());
exports.TagDisplayComponent = TagDisplayComponent;
