"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".form-content[_ngcontent-%COMP%]{margin-top:30px}.mat-dialog-content[_ngcontent-%COMP%], .md-dialog-content[_ngcontent-%COMP%]{height:auto;overflow-y:auto;display:flex;flex-direction:row;flex:1 1 auto;margin-bottom:0;padding-bottom:0}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .checkin[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex:1 1 auto;width:300px;margin-bottom:30px}form[_ngcontent-%COMP%]   .checkin[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]{padding:0;position:relative;width:100%}form[_ngcontent-%COMP%]   .checkin[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .loading-label[_ngcontent-%COMP%], form[_ngcontent-%COMP%]   .checkin[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%] > .unloading-label[_ngcontent-%COMP%]{margin-bottom:20px}form[_ngcontent-%COMP%]   .divider[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex:1 0 auto;width:1px;margin:0 30px;border-right:1px solid #f0f3f8}form[_ngcontent-%COMP%]   .signatures-container[_ngcontent-%COMP%]{overflow-y:auto}form[_ngcontent-%COMP%]   .save-btn-container[_ngcontent-%COMP%]{margin:20px auto;width:150px}.signatures-container[_ngcontent-%COMP%]{overflow-y:auto}"];
exports.styles = styles;
