"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var moment = require("moment");
var lodash_1 = require("lodash");
var job_event_service_1 = require("../../job-events/job-event.service");
var MobileDispatchComponent = /** @class */ (function () {
    function MobileDispatchComponent(router, route, jobEventService) {
        this.router = router;
        this.route = route;
        this.jobEventService = jobEventService;
        this.externalNavigation = false;
        this.loading = false;
        this.errors = [];
        this.jobEvents = [];
        this.allSubscriptionsToUnsubscribe = [];
    }
    MobileDispatchComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            if (result.params['shifts'] === '2') {
                _this.externalNavigation = true;
                _this.router.navigate([
                    '/dispatch', result.params['jobId'], result.params['jobEventId'], 'shifts'
                ]);
            }
            else if (result.params['jobEventId'] && result.params['jobId']) {
                _this.externalNavigation = true;
                _this.router.navigate([
                    '/dispatch', result.params['jobId'], result.params['jobEventId'], 'assign'
                ]);
            }
            else if (result.params['jobId']) {
                _this.externalNavigation = true;
                _this.router.navigate([
                    '/dispatch', result.params['jobId']
                ]);
            }
            else {
                _this.loading = true;
                if (result.qparams['date']) {
                    var date = moment(result.qparams['date'], 'YYYYMMDD').toDate();
                    _this.selectedDates = [date || new Date()];
                }
                else {
                    _this.selectedDates = [new Date()];
                }
                _this.displayedDate = _this.selectedDates[0];
                _this.getJobEvents();
            }
        }));
        if (!this.externalNavigation && (!this.selectedDates || !this.selectedDates.length)) {
            this.selectedDates = [new Date()];
            this.getJobEvents();
        }
    };
    MobileDispatchComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    MobileDispatchComponent.prototype.onDateChanged = function (dates) {
        this.displayedDate = dates && dates.length ? dates[0] : new Date();
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        queryParams['date'] = moment(this.displayedDate).format('YYYYMMDD');
        this.router.navigate(['/dispatch'], { queryParams: queryParams });
    };
    MobileDispatchComponent.prototype.getJobEvents = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        this.jobEvents = [];
        if (this.jobEventsReq) {
            this.jobEventsReq.unsubscribe();
        }
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        if (this.selectedDates && this.selectedDates.length) {
            startDate = this.selectedDates[0];
        }
        else {
            this.selectedDates = [startDate];
        }
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ shift1_start__gte: startDate && startDate.toISOString(), shift1_start__lte: endDate && endDate.toISOString(), ordering: 'project__name,name', archived: 'False', cancelled: 'False', exclude_pending: 'True' }, query)).subscribe(function (jobEvents) {
            _this.jobEvents = jobEvents;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    return MobileDispatchComponent;
}());
exports.MobileDispatchComponent = MobileDispatchComponent;
