"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment-timezone");
var api_service_1 = require("../../shared/api.service");
var shared_1 = require("../../shared");
var job_1 = require("../../jobs/job");
var assignment_service_1 = require("../assignment.service");
var job_service_1 = require("../../jobs/job.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var job_event_1 = require("../../job-events/job-event");
var truck_service_1 = require("../../trucks/truck.service");
var MoveAssignmentsDialogComponent = /** @class */ (function () {
    function MoveAssignmentsDialogComponent(assignmentService, jobService, jobEventService, dialogRef) {
        this.assignmentService = assignmentService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.dialogRef = dialogRef;
        this.assignments = [];
        this.hasAllDriversEnabled = false;
        this.loading = false;
        this.errors = [];
        this.copy = false;
        this.model = {
            jobEvent: null
        };
        this.trucksEditable = false;
        this.trucksDropdownConfig = {
            small: true,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'ticketName',
            service: truck_service_1.TruckService,
            query: { ordering: 'name', carrier: null }
        };
        this.jobs = [];
        this.jobsConfig = {
            searchable: true,
            group: true,
            showLoading: true,
            nameProperty: 'name',
            loadingOptions: false
        };
        this.jobEvents = [];
        this.jobEventsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
    }
    MoveAssignmentsDialogComponent.prototype.ngOnInit = function () {
        this.getJobs();
        if (this.job) {
            this.getJobEvents();
        }
    };
    MoveAssignmentsDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
            this.jobsReq.unsubscribe();
        }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
    };
    MoveAssignmentsDialogComponent.prototype.onSelect = function (filterType, item) {
        switch (filterType) {
            case 'job':
                this.job = item;
                this.jobEvent = null;
                this.jobEventsDropdown.selectedOption = null;
                this.jobEvents = [];
                this.getJobEvents();
                break;
            case 'jobEvent':
                var jobEvent_1 = item;
                this.jobEvent = jobEvent_1;
                this.model[filterType] = jobEvent_1;
                this.assignments.forEach(function (assignment) { return assignment.jobevent = jobEvent_1; });
                var timezone = jobEvent_1.job && jobEvent_1.job.startLocation && jobEvent_1.job.startLocation.timezone;
                if (!timezone) {
                    timezone = 'UTC';
                }
                var startDate_1 = moment.tz(jobEvent_1.shift1StartTimestamp, timezone).format('YYYY-MM-DD');
                if (startDate_1) {
                    this.assignments.forEach(function (assignment) {
                        var uniqueStart = moment(startDate_1 + " " + assignment.uniqueStartTime);
                        assignment.uniqueStart = uniqueStart.toISOString();
                        assignment.uniqueStartDate = uniqueStart.toDate();
                    });
                }
                break;
        }
    };
    MoveAssignmentsDialogComponent.prototype.submit = function () {
        var _this = this;
        if (this.copy) {
            var assignments = lodash_1.clone(this.assignments);
            assignments.forEach(function (assignment) {
                if (_this.model.jobEvent) {
                    assignment.jobevent = _this.model.jobEvent;
                }
                delete assignment['id'];
            });
            this.assignmentService.bulkCreate(assignments).subscribe(function (res) {
                if (res && res.errors && res.errors.length) {
                    _this.errors = res.errors;
                }
                else {
                    _this.dialogRef.close();
                    _this.callback();
                }
            });
        }
        else {
            this.assignments.forEach(function (assignment) {
                if (_this.model.jobEvent) {
                    assignment.jobevent = _this.model.jobEvent;
                }
            });
            this.assignmentService.bulkUpdate(this.assignments).subscribe(function (res) {
                if (res && res.errors && res.errors.length) {
                    _this.errors = res.errors;
                }
                else {
                    _this.dialogRef.close();
                    _this.callback();
                }
            }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
        }
    };
    MoveAssignmentsDialogComponent.prototype.getJobs = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
            this.jobsReq.unsubscribe();
        }
        this.jobsReq = this.jobService.list({
            ordering: 'project__name,name,start_date',
            jobevents__end__gte: this.start && this.start.toISOString(),
            page_size: 6,
            search: search,
            serializer: 'JobGroupedDropdown'
        }).subscribe(function (jobs) {
            _this.jobs = jobs;
            if (_this.job && _this.jobsDropdown) {
                var selectedOption = lodash_1.find(_this.jobs, { id: _this.job && _this.job.id });
                if (selectedOption) {
                    _this.jobs = lodash_1.reject(_this.jobs, selectedOption);
                }
                _this.jobs.unshift(_this.job);
                _this.jobsDropdown.selectedOption = _this.job;
            }
            _this.jobsDropdown.config.loadingOptions = false;
            _this.jobsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.jobsConfig.loadingOptions = false;
        });
    };
    MoveAssignmentsDialogComponent.prototype.getJobEvents = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        this.jobEventsReq = this.jobEventService.getJobEvents({
            ordering: 'shift1_start',
            job: this.job && this.job.id,
            end__gte: this.start && this.start.toISOString(),
            page_size: 6,
            search: search,
            serializer: 'DefaultDropdown'
        }).subscribe(function (jobEvents) {
            _this.jobEvents = jobEvents;
            if (_this.jobEvent && _this.jobEventsDropdown) {
                var selectedOption = lodash_1.find(_this.jobEvents, { id: _this.jobEvent && _this.jobEvent.id });
                if (selectedOption) {
                    _this.jobEvents = lodash_1.reject(_this.jobEvents, selectedOption);
                }
                _this.jobEvents.unshift(_this.jobEvent);
                _this.jobEventsDropdown.selectedOption = _this.jobEvent;
                if (!_this.assignments || !_this.assignments.length) {
                    _this.getAssignments();
                }
            }
            _this.jobEventsDropdown.config.loadingOptions = false;
            _this.jobEventsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.jobEventsConfig.loadingOptions = false;
        });
    };
    MoveAssignmentsDialogComponent.prototype.getAssignments = function () {
        var _this = this;
        this.assignmentService.listAll(10, { jobevent: this.jobEvent.id }).subscribe(function (a) {
            _this.assignments = a;
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    MoveAssignmentsDialogComponent.prototype.dropdownNextPage = function (event, type) {
        var _this = this;
        var config;
        var service;
        switch (type) {
            case 'job':
                config = this.jobsConfig;
                service = this.jobService;
                break;
            case 'jobEvent':
                config = this.jobEventsConfig;
                service = this.jobEventService;
                break;
        }
        if (!config.loadingOptions) {
            var request = service.listNext();
            if (request) {
                config.loadingOptions = true;
                request.subscribe(function (results) {
                    switch (type) {
                        case 'job':
                            _this.jobs = _this.jobs.concat(results);
                            break;
                        case 'jobEvent':
                            _this.jobEvents = _this.jobEvents.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    MoveAssignmentsDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'job':
                this.getJobs(term);
                break;
            case 'jobEvent':
                this.getJobEvents(term);
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    MoveAssignmentsDialogComponent.prototype.assignmentUniqueStartDateChanged = function (assignment, values) {
        if (values && values.length) {
            assignment.uniqueStartDate = values[0];
        }
    };
    return MoveAssignmentsDialogComponent;
}());
exports.MoveAssignmentsDialogComponent = MoveAssignmentsDialogComponent;
