<item-grid
  *ngIf="truckList && loadingProgress === 100"
  [itemGroups]="filteredTruckList"
  [itemClassNames]="generateItemClassNames"
  [displayKeys]="displayKeys"
  [searchProps]="displayKeys"
  [groupByOptions]="groupByOptions"
  [activeGroupBy]="activeGroupBy"
  (groupByChange)="selectGroupBy($event)"
  [(selectedItems)]="selectedDrivers"
  [showDriverContextMenu]="true"
>
  <div header-stats>
    {{ truckCount.assigned + '/' + truckCount.available + ' Trucks Assigned' | translate }}
  </div>
  <div header-dropdowns class="header-dropdowns">
    <dropdown #carriersDropdown *ngIf="carrierDropdownData && carrierDropdownData.carriers"
              [options]="carrierDropdownData.carriers" [config]="carrierDropdownData.config"
              title="{{ 'All Carriers' | translate }}" [selectedOption]="carrierDropdownData.carrier"
              (onSelect)="selectsCarrier($event)" (onSearch)="searchCarriers.emit({ search: $event })"
              (nextPage)="dropdownNextPage.emit('carrier')"></dropdown>
    <dropdown #truckTypeDropdown *ngIf="truckTypeOptions"
              [options]="truckTypeOptions" [config]="{nameProperty: 'name'}"
              title="{{ 'All Truck Types' | translate }}" [selectedOption]="selectedTruckType"
              (onSelect)="selectTruckType($event)"></dropdown>
  </div>
</item-grid>

<div class="grid-loader" *ngIf="!truckList || loadingProgress < 100">
  <h2 translate>Getting Full Driver List</h2>
  <h3 translate>This may take longer depending on your fleet size</h3>
  <div class="loading-bar">
    <span class="text">{{ loadingProgress + '% Loaded' | translate }}</span>
    <div class="progress" [ngStyle]="{'width': loadingProgress + '%'}"></div>
  </div>
</div>
