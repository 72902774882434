<div class="app-content">
  <my-toolbar title="Customers"></my-toolbar>

  <action-bar>
    <div class="page-actions">
      <ruckit-button title="+ {{ 'Add Customer' | translate }}" (click)="openDialog()"></ruckit-button>
    </div>
  </action-bar>

  <h1 translate>Customers</h1>
  <div class="page-actions">
    <button mat-button (click)="openDialog()">+ {{ 'Add Customer' | translate }}</button>
  </div>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <mat-tab-group>
    <mat-tab label="Customers">

    </mat-tab>
  </mat-tab-group>
</div>
