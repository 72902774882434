<div class="trips" *ngIf="(!loading && trips.length !== 0) || loading || checkingDuplicates" [ngClass]="{loading: loading}">
  <div class="section-header">
    <div class="left-actions">
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Trips Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Trip Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Trips Selected' | translate }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='trip-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Trips' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Trip' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Trips' | translate }}</ng-template>
        </span>
        <span *ngIf="loading" class='trip-results' translate>
          Loading Trips...
        </span>
      </div>
      <a (click)="openAddTrip()" class="new-trip">
        <i class="icon-plus-circle"></i>
      </a>
      <action-menu title="Actions">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a *ngIf="option.link" md-menu-item [routerLink]="" [queryParams]="{ returnTo: '/expenses/' + this.expense.id + '/edit' }">{{ option.name }}</a>
          <a *ngIf="!option.link" md-menu-item (click)="menuAction(option.action)">{{ option.name }}</a>
        </ng-container>
      </action-menu>
      <input type="checkbox" class="toggle duplicate-check" [(ngModel)]="checkingDuplicates" (change)="duplicateCheck()" />
      <label attr.data-label="{{ 'Show Duplicates' | translate }}" for="duplicate_check"></label>
    </div>
    <div class="right-actions">
      <div class="expense-total">
        {{ expense.tripsExpenseAmount || 0 | currency : 'USD' : 'symbol' }}
      </div>
    </div>
  </div>
  <div class="list-table condensed">
    <div class="table-body" [ngClass]="{'full-height': loading && trips.length === 0}">
      <div class="header">
        <div class="head selector">
          <label>
            <input type="checkbox" (change)="selector($event)" [(ngModel)]="allSelected" />
          </label>
        </div>
        <div class="head ticket-number sort" (click)="customSort('ticket_number_ordering', 'loading')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loading' && sortParameter === 'ticket_number_ordering'}"
          translate>
          Ticket #
        </div>
        <div class="head date sort" (click)="sort('jobevent__shift1_start')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__shift1_start'}"
          translate>
          Date
        </div>
        <div class="head driver sort" (click)="sort('driver__profile__first_name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__first_name'}"
          translate>
          Driver - Truck
        </div>
        <div class="head amount" translate>
          Quantity
        </div>
        <div class="head rate" translate>
          Rate
        </div>
        <div class="head total" translate>
          Total
        </div>
        <div class="head actions" translate></div>
      </div>

      <div *ngFor="let trip of trips" class="row" [ngClass]="{selected: allSelected || trip.selected, tall: trip.hasBothTickets || trip.hasUnloadingTicketImages}">
        <div class="selector">
          <label>
            <input type="checkbox" (change)="selector($event, trip)" [checked]="allSelected || trip.selected" />
          </label>
        </div>
        <div class="ticket-number">
          <a [routerLink]="trip.url('edit')" [queryParams]="{ returnTo: '/expenses/' + this.expense.id + '/edit' }">
            <div class="ticket-photo">
              <img class="loading" *ngIf="trip.hasLoadingTicketImages" [src]="trip.inlineLoadingTicket" />
              <div class="missing-image loading" *ngIf="trip.loadingTicketImagesRequired && !trip.hasLoadingTicketImages">!</div>
              <div class="loading" *ngIf="!trip.loadingTicketImagesRequired && !trip.hasLoadingTicketImages">&nbsp;</div>
              <img class="unloading" *ngIf="trip.hasUnloadingTicketImages" [src]="trip.inlineUnloadingTicket" />
              <div class="missing-image unloading" *ngIf="trip.unloadingTicketImagesRequired && !trip.hasUnloadingTicketImages">!</div>
            </div>
            <div class="value">
              <div class="loading">
                <span *ngIf="trip.loadingTicketNumber || (trip.checkinOptions && !trip.checkinOptions.ticketNumber)">{{
                  trip.loadingTicketNumber || '-' }}</span>
                <span class="missing-value" *ngIf="!trip.loadingTicketNumber && trip.checkinOptions && trip.checkinOptions.ticketNumber">Missing!</span>
              </div>
              <div class="unloading" *ngIf="trip.hasUnloadingTicketImages || trip.unloadingTicketNumber || (trip.checkoutOptions && trip.checkoutOptions.ticketNumber)">
                <span *ngIf="trip.unloadingTicketNumber || (trip.checkoutOptions && !trip.checkoutOptions.ticketNumber)">{{
                  trip.unloadingTicketNumber || '–' }}</span>
                <span class="missing-value" *ngIf="!trip.unloadingTicketNumber && trip.checkoutOptions && trip.checkoutOptions.ticketNumber">Missing!</span>
              </div>
            </div>
          </a>
        </div>
        <div class="date">
          <div class="value">{{ trip.jobDate | date:'shortDate' || '&nbsp;' }}</div>
        </div>
        <div class="driver">
          <div class="value">{{ trip.driver || '&nbsp;' }} - {{ trip.truckName || '&nbsp;' }}</div>
        </div>
        <div class="amount">
          <div class="value" [ngClass]="{'missing-required': !trip.invoiceQuantity}">
            {{ trip.invoiceQuantity || '!' }} {{ trip.pluralizedInvoiceRateUnit(trip.invoiceQuantity !== 1) || '&nbsp;'
            | titleCase }}
          </div>
        </div>
        <div class="rate">
          <div class="value" [ngClass]="{'missing-required': !trip.invoiceQuantity}">
            {{ (trip.haulRate | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }} per {{ trip.haulRateUnit || '&nbsp;' |
            titleCase }}
          </div>
        </div>
        <div class="total">
          <div class="value" [ngClass]="{'missing-required': !trip.invoiceQuantity}" title="{{ (trip.haulRate | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }} per {{ trip.haulRateUnit || '&nbsp;' | titleCase }}">
            {{ (trip.haulTotal | currency : 'USD' : 'symbol') || '&nbsp;' }}
          </div>
        </div>
        <div class="actions">
          <action-menu icon="icon-more">
            <ng-container *ngFor="let option of trip.filterOptions(menuOptions)">
              <a *ngIf="option.link" mat-menu-item (click)="dialogRef.close(); false" [routerLink]="trip.url(option.action)" [queryParams]="{ returnTo: '/expenses/' + this.expense.id + '/edit' }">{{ option.name }}</a>
              <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, trip)">{{ option.name }}</a>
            </ng-container>
          </action-menu>
        </div>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading"></my-loader>
    </div>
  </div>

  <ruckit-pagination *ngIf="(!loading && count) || loading" [totalCount]="count" [currentPage]="page" (page)="pageChange($event)"></ruckit-pagination>
</div>

<div class="trips no-results" *ngIf="!loading && trips.length === 0 && !checkingDuplicates">
  <div class="section-header">
    <div class="left-actions">
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Trips Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Trip Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Trips Selected' | translate }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='trip-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Trips' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Trip' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Trips' | translate }}</ng-template>
        </span>
      </div>
      <a (click)="openAddTrip()" class="new-trip">
        <i class="icon-plus-circle"></i>
      </a>
    </div>
    <div class="right-actions"></div>
  </div>
</div>
