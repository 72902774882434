import { Resource } from '../shared/resource';
import { PayRecord } from './pay-record';

export type PaySheetTimeRange = {
  start_time: string;
  end_time: string;
};

export class PaySheet extends Resource {
  id: string;
  createdAt: string;
  createdBy: string;
  lastModified: string;
  lastModifiedBy: string;
  payerOrganization: string;
  payeeOrganizations: string[];
  reportDate: string;
  startTime: string;
  endTime: string;
  isApproved: boolean;
  processStatus: 'pending' | 'processing' | 'complete' | 'failed';
  payRecords: PayRecord[];
  type?: 'internal' | 'external';
}
