<div class="loader" [ngClass]="{ 'active': loading, 'init': !loaded }" translate>
  <ng-container *ngIf="loading">
    <h1 translate>Map Data Loading</h1>
    <h3 translate>This may take up to two minutes</h3>
  </ng-container>
  <ng-container *ngIf="!loaded">
    <button class="btn btn-primary-alt"
            (click)="getMapData(jobEvents, driver)"
            [disabled]="!locationUpdates.length"
            translate>Load Map Data</button>
  </ng-container>
</div>
<div class="info-panel">
  <div class="section bottom">
    <div class="meta time"><mat-icon>schedule</mat-icon>{{ selectedTime | date: 'MM/dd/yyyy h:mm a' }}</div>
    <ng-container *ngIf="!loadingLocationUpdates; else loadingUpdates">
      <div class="meta location" *ngIf="condensedLocationUpdates.length && condensedLocationUpdates[activeIndex] && condensedLocationUpdates[activeIndex].location">
        <mat-icon>room</mat-icon>
        <span class="coordinates" [innerHTML]="condensedLocationUpdates[activeIndex].location.coordinates | coordinates"></span>
      </div>
      <div class="meta location" *ngIf="!condensedLocationUpdates || !condensedLocationUpdates.length" translate>
        <mat-icon>room</mat-icon> No Location Data Available
      </div>
    </ng-container>
    <ng-template #loadingUpdates>
      <div class="meta location" translate>
        <mat-icon>room</mat-icon> Loading Location Updates
      </div>
    </ng-template>
  </div>
</div>
<ngui-map class="map-container"
          [options]="mapOptions" (mapReady$)="initMap($event)">
  <ng-container *ngFor="let jobEvent of jobEvents">
    <custom-marker *ngIf="jobEvent.job.startLocationMappable"
                  [id]="jobEvent.job.startLocation && jobEvent.job.startLocation.id"
                  [position]="[jobEvent.startCoordinates.latitude, jobEvent.startCoordinates.longitude]">
                  <a [routerLink]="[ '/locations', jobEvent.job.startLocation.id, 'edit' ]"
                     [queryParams]="{ returnTo: '/replay/?date='+ selectedDate +'&driver='+ driver.id }"><img src="assets/img/map/locationmarker-loading.png" /></a>
    </custom-marker>
    <custom-marker *ngIf="jobEvent.job.endLocationMappable"
                  [id]="jobEvent.job.endLocation && jobEvent.job.endLocation.id"
                  [position]="[jobEvent.endCoordinates.latitude, jobEvent.endCoordinates.longitude]">
                  <a [routerLink]="[ '/locations', jobEvent.job.endLocation.id , 'edit' ]"
                     [queryParams]="{ returnTo: '/replay/?date='+ selectedDate +'&driver='+ driver.id }"><img src="assets/img/map/locationmarker-unloading.png" /></a>
    </custom-marker>
  </ng-container>

  <ng-container *ngIf="jobEventRoutes.length">
    <directions-renderer *ngFor="let route of jobEventRoutes" [suppressMarkers]="true" panel="#my-panel"
                        [preserveViewport]="true" [directions-request]="route"
                        [polylineOptions]="polylineOptions" (directions_changed)="onMapLoaded($event)">
    </directions-renderer>
  </ng-container>

  <polygon *ngFor="let geofence of geofences" [editable]="false" [paths]="geofence.paths" [strokeColor]="'#FF8C00'"
           [strokeOpacity]="0.8" [strokeWeight]="2" [fillColor]="'#FF8C00'" [fillOpacity]="0.35">
  </polygon>

  <polygon *ngFor="let geofence of allLocationGeofences" [editable]="false" [paths]="geofence.paths" [strokeColor]="'#ff00d4'"
           [strokeOpacity]="0.8" [strokeWeight]="2" [fillColor]="'#ff00d4'" [fillOpacity]="0.35">
  </polygon>

  <info-window id="geofence-info-window">
    <div *ngIf="geofenceInfoWindowModel" class="geofence-info-window">
      <div class="title">{{ geofenceInfoWindowModel.name }}</div>
    </div>
  </info-window>

  <ng-container *ngFor="let geofence of allLocationGeofences; let i = index">
    <custom-marker *ngIf="geofence.name"
                   [id]="geofence.id"
                   [position]="[geofence.coordinates[1], geofence.coordinates[0]]"
                   (click)="openGeofenceInfoWindow(i, geofence)"
                   (initialized$)="onCustomGeofenceMarkerInit($event)">
      <div class="location"><img src="/assets/img/icons/locations-pin.svg" /></div>
    </custom-marker>
  </ng-container>

  <ng-container *ngFor="let update of condensedLocationUpdates; let i = index">
    <custom-marker *ngIf="update.location && update.location.coordinates && (i < activeIndex + 10 && i > activeIndex - 10)" [id]="update.date"
                   [position]="[update.location.coordinates[1], update.location.coordinates[0]]">
      <div class="truck-icon" [ngStyle]="{
        'transform': 'rotate(' + (update.orientation - 90) + 'deg)',
        'opacity': (i <= activeIndex && i > activeIndex - 3) ? 1 / (activeIndex - i + 1) : 0
      }">
        <span class="truck-status"></span>
      </div>
    </custom-marker>
  </ng-container>
</ngui-map>
