"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".custom-fields[_ngcontent-%COMP%]{padding:30px}.custom-fields__content[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:column;padding:30px;border-radius:4px;background-color:#fff;border:1px solid #e6eaee}.custom-fields__content__blurb[_ngcontent-%COMP%]{width:500px;margin-bottom:25px}.custom-fields__content__blurb--head[_ngcontent-%COMP%]{font-size:12px;color:#000}.custom-fields__form__fields[_ngcontent-%COMP%]{margin-top:50px;margin-bottom:50px;display:flex;flex-direction:column;width:625px;max-height:300px;overflow-y:auto}.custom-fields__form__fields__labels[_ngcontent-%COMP%]{display:flex;width:100%;flex-direction:row;padding-left:40px;padding-bottom:20px;color:#7f8fa4}.custom-fields__form__fields__labels__display[_ngcontent-%COMP%]{margin-right:210px}.custom-fields__form__fields__field[_ngcontent-%COMP%]{display:flex;flex-direction:row;margin-bottom:10px}.custom-fields__form__fields__field[_ngcontent-%COMP%]   input[type=checkbox][_ngcontent-%COMP%]{margin-top:10px}.custom-fields__form__fields__field[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{font-size:18px;margin-top:-5px}.custom-fields__form__fields__field[_ngcontent-%COMP%]   input[type=text][_ngcontent-%COMP%]{width:275px;margin-right:15px}.custom-field__select[_ngcontent-%COMP%]{width:275px}.custom-field__select[_ngcontent-%COMP%]::after{font-family:icomoon!important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;margin-left:-25px;margin-top:10px;content:'\\e904'}.custom-field__select[_ngcontent-%COMP%]   select[_ngcontent-%COMP%]{width:275px}"];
exports.styles = styles;
