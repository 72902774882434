import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { ProjectService } from '../projects/project.service';
import { Project } from '../projects/project';

@Component({
  selector: 'ruckit-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  projects: Project[] = [];
  loading = true;
  errors = [];
  search = '';
  sortBy: string;
  sortAsc = true;
  stringify = JSON.stringify;
  projectsReq: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
      this.search = params['search'] || '';
      this.sortBy = 'name';

      this.projectService.list({ordering: this.sortBy}).subscribe(projects => {
        this.projects = projects;
        this.loading = false;
      }, err => {
        this.errors = err;
      });
    });
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.projectService.listNext();
      if (o) {
        this.loading = true;
        o.subscribe(
          projects => {
            this.projects = this.projects.concat(projects);
            this.loading = false;
          },
          err => this.errors = err
        );
      }
    }
  }

  reload(query = {}, append = false) {
    if (!append) { this.projects = []; }
    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    if (this.projectsReq) {
      this.projectsReq.unsubscribe();
    }
    this.projectsReq = this.projectService.list({
      ordering: order,
      search: this.search
    }).subscribe(projects => {
      if (append) {
        this.projects = this.projects.concat(projects);
      } else {
        this.projects = projects;
      }
      this.loading = false;
    }, err => {
      this.errors = err;
    });
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.reload();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = ! this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.reload({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  openAddProject() {
    this.router.navigate(['/projects/new']);
  }
  openAddJob(project) {
    this.router.navigateByUrl('/projects/' + project.id + '/new-job');
  }
}
