"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tag-input.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../ruckit-dropdown/ruckit-dropdown.component.ngfactory");
var i3 = require("../ruckit-dropdown/ruckit-dropdown.component");
var i4 = require("@ngx-translate/core");
var i5 = require("./tag-input.component");
var styles_TagInputComponent = [i0.styles];
var RenderType_TagInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagInputComponent, data: {} });
exports.RenderType_TagInputComponent = RenderType_TagInputComponent;
function View_TagInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { marketsDropdown: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ruckit-dropdown", [], null, [[null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelect" === en)) {
        var pd_0 = (_co.selectTags($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RuckitDropdownComponent_0, i2.RenderType_RuckitDropdownComponent)), i1.ɵdid(2, 770048, [[1, 4], ["marketsDropdown", 4]], 0, i3.RuckitDropdownComponent, [i1.Injector, i4.TranslateService], { selectedItems: [0, "selectedItems"], config: [1, "config"] }, { onSelect: "onSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedOptions; var currVal_1 = _co.marketsConfig; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
exports.View_TagInputComponent_0 = View_TagInputComponent_0;
function View_TagInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tag-input", [], null, null, null, View_TagInputComponent_0, RenderType_TagInputComponent)), i1.ɵdid(1, 49152, null, 0, i5.TagInputComponent, [], null, null)], null, null); }
exports.View_TagInputComponent_Host_0 = View_TagInputComponent_Host_0;
var TagInputComponentNgFactory = i1.ɵccf("tag-input", i5.TagInputComponent, View_TagInputComponent_Host_0, { selectedOptions: "selectedOptions" }, { onSelect: "onSelect" }, []);
exports.TagInputComponentNgFactory = TagInputComponentNgFactory;
