"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var trip_service_1 = require("../trips/trip.service");
var checkin_service_1 = require("../checkins/checkin.service");
var api_service_1 = require("../shared/api.service");
var checkin_1 = require("./checkin");
var lodash_1 = require("lodash");
var ViewTicketsDialogComponent = /** @class */ (function () {
    function ViewTicketsDialogComponent(dialogRef, data, tripService, checkinService, elementRef) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.tripService = tripService;
        this.checkinService = checkinService;
        this.elementRef = elementRef;
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
    }
    ViewTicketsDialogComponent.prototype.ngOnInit = function () {
        if (this.data && this.data.trip) {
            this.loadingCheckin = this.data.trip.loadingCheckin;
            this.unloadingCheckin = this.data.trip.unloadingCheckin;
        }
    };
    ViewTicketsDialogComponent.prototype.submitCheckins = function () {
        var _this = this;
        this.loading = true;
        this.checkinService.save(this.loadingCheckin).subscribe(function (_loadingCheckin) {
            _this.checkinService.save(_this.unloadingCheckin).subscribe(function (_unloadingCheckin) {
                _this.dialogRef.close();
                _this.callback();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    ViewTicketsDialogComponent.prototype.requestRetake = function (checkin) {
        var _this = this;
        this.checkinService.requestRetake(checkin.id).subscribe(function (res) {
            _this.data.trip.retakeStatus = 'requested';
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    ViewTicketsDialogComponent.prototype.fileChange = function (e, checkinType) {
        var _this = this;
        var checkin;
        switch (checkinType) {
            case 'loading':
                checkin = this.loadingCheckin;
                break;
            case 'unloading':
                checkin = this.unloadingCheckin;
                break;
        }
        this.checkinService.uploadImage('PUT', checkin.id, 'ticket_image', e.srcElement.files).subscribe(function (res) {
            var _checkin = new checkin_1.Checkin(res);
            _checkin.ticketImage = _checkin.ticketImage + '&cb=' + encodeURIComponent(new Date().toISOString());
            lodash_1.extend(checkin, _checkin);
            _this.completed.emit(checkin);
        }, function (err) {
            console.error('err', err);
        });
    };
    return ViewTicketsDialogComponent;
}());
exports.ViewTicketsDialogComponent = ViewTicketsDialogComponent;
