import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'control-reports-actionbar',
  templateUrl: './control-reports-actionbar.component.html',
  styleUrls: ['./control-reports-actionbar.component.scss']
})
export class ControlReportsActionbarComponent {
  constructor() { }
}
