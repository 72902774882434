import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule, MatDatepickerModule, MatTooltipModule,
  MatTableModule, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { DriverJobReportsComponent } from './driver-job-reports.component';
import { DriverJobReportService } from './driver-job-report.service';
import { DriverReportsRoutingModule } from './driver-reports-routing.module';
import { DriverJobReportFiltersDialogComponent } from './driver-job-report-filters-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule,
    DriverReportsRoutingModule
  ],
  providers: [
    DriverJobReportService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  declarations: [
    DriverJobReportsComponent,
    DriverJobReportFiltersDialogComponent
  ],
  entryComponents: [
    DriverJobReportFiltersDialogComponent
  ],
  exports: [
    DriverJobReportsComponent
  ]
})
export class DriverReportsModule { }
