const camelcaseKeysDeep = require('camelcase-keys-deep');

export class PunchCardFilter {
  id: string;
  name: string;

  constructor(punchCardFilterInfo: any) {
    punchCardFilterInfo = camelcaseKeysDeep(punchCardFilterInfo);

    this.id = punchCardFilterInfo.id;
    this.name = punchCardFilterInfo.name;
  }
}
