import { Component, Input } from '@angular/core';

@Component({
  selector: 'job-status',
  templateUrl: './job-status.component.html'
})
export class JobStatusComponent {
  @Input() status: string;
  @Input() statusText: string;

  constructor() {}
}
