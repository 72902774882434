"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var driver_service_1 = require("./../driver.service");
var InviteDriverDialogComponent = /** @class */ (function () {
    function InviteDriverDialogComponent(dialogRef, driverService) {
        this.dialogRef = dialogRef;
        this.driverService = driverService;
        this.stateOptions = {};
        this.loading = false;
        this.model = { profile: {} };
        this.errors = [];
    }
    InviteDriverDialogComponent.prototype.selectState = function (state) {
        this.model.state = state;
    };
    InviteDriverDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.driverService.invite(this.model).subscribe(function () {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    return InviteDriverDialogComponent;
}());
exports.InviteDriverDialogComponent = InviteDriverDialogComponent;
