import { Component, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';

// angular material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

// services
import { parseErrors } from '../api.service';
import { AuthenticationService } from '../authentication.service';
import { PreferenceService } from '../../preferences/preference.service';

// types
import { Preference } from '../../preferences/preference';

// constants
import { TYPELIST_OPTIONS } from './export-dialog-options.constants';

const decamelizeKeysDeep = require('decamelize-keys-deep');

export type ExportDialogData = {
  type: string;
  title?: string;
  buttonText?: string;
  callback?: (param?) => void;
  dataFormats?: string[];
  fields?: FieldOption[];
  lockedFields?: string[];
  displayedColumns?: FieldOption[];
  excludeLeasedFleetOption?: boolean;
  removeFieldsProperty?: boolean;
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  scope?: {
    include?: string[];
    exclude?: string[];
    includeLegacy?: boolean;
    includeImages?: boolean;
    excludeLeasedFleetDrivers?: boolean;
    summaryOnly?: boolean;
    paystubs?: string[];
    fields?: string[];
    driverPayFormats?: {
      raw: boolean;
      hiredHauler: boolean;
      payroll: boolean;
      equipment: boolean;
      hourlyTicket: boolean;
    };
  };
  service?: any;
  endpoint?: string;
  customUrl?: string;
};

export type FieldOption = {
  key: string;
  label: string;
  group?: string;
  selected: boolean;
  value?: string;
};

@Component({
  selector: 'export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent {
  loading = false;
  exportSubmitted = false;
  exportAllFields:
    | 'export_fields_all'
    | 'export_fields_custom'
    | 'export_fields_displayed' = 'export_fields_all';
  showFieldOptions = true;
  fieldsLocked = false;
  showExportDisplayedColumns = false;
  exportDisplayedColumns = false;
  displayedColumns: FieldOption[][];
  selectedDataFormat: string;
  errors = [];
  columns: FieldOption[][];
  preference: Preference;
  TYPELIST_OPTIONS = TYPELIST_OPTIONS;

  constructor(
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ExportDialogData,
    public authenticationService: AuthenticationService,
    public preferenceService: PreferenceService
  ) {
    if (this.includesImageOptions()) {
      this.data.scope.includeImages = false;
    }
    if (this.data.lockedFields && this.data.lockedFields.length) {
      this.fieldsLocked = true;
    } else if (this.includesFieldOptions()) {
      this.data.scope.includeLegacy = false;
      this.columns = this.generateFieldColumns(this.data.fields);
      this.preferenceService
        .list({
          name: this.data.type + '-export-preferences',
          type: 'user',
          profile: this.authenticationService.user().id,
        })
        .subscribe((preferences) => this.setUserPreferences(preferences));
    }
    if (this.includesFormatOptions()) {
      this.data.dataFormats = ['summary', 'detail'];
      this.selectedDataFormat = this.data.dataFormats[0];
    }
    if (this.data && this.data.displayedColumns) {
      this.displayedColumns = this.generateFieldColumns(
        this.data.displayedColumns
      );
      this.showExportDisplayedColumns = true;
    }
  }

  includesImageOptions(): boolean {
    return TYPELIST_OPTIONS.IMAGE_OPTIONS.indexOf(this.data.type) !== -1;
  }

  includesFieldOptions(): boolean {
    return TYPELIST_OPTIONS.FIELD_OPTIONS.indexOf(this.data.type) !== -1;
  }

  includesFormatOptions(): boolean {
    return TYPELIST_OPTIONS.FORMAT_OPTIONS.indexOf(this.data.type) !== -1;
  }

  setUserPreferences(preferences: Preference[]) {
    if (
      preferences[0] &&
      preferences[0].name === this.data.type + '-export-preferences'
    ) {
      this.preference = preferences[0];
      this.data.scope.includeImages = !!this.preference.blob.includeImages;
      this.data.scope.includeLegacy = !!this.preference.blob.includeLegacy;
      if (this.preference.blob.fields) {
        this.data.scope.fields = this.preference.blob.fields;
        this.showFieldOptions = !!!(
          this.preference.blob && this.preference.blob.fields.length
        );

        this.columns.forEach((column) => {
          column.forEach((field) => {
            field.selected = !!this.preference.blob.fields.find(
              (selectedField) => field.key === selectedField
            );
          });
        });
      }
    }
  }

  saveUserPreferences() {
    this.preference = <Preference>{
      name: this.data.type + '-export-preferences',
      type: 'user',
      profile: this.authenticationService.user().id,
      blob: this.data.scope,
    };
    this.preferenceService
      .save(this.preference)
      .subscribe((preference) => (this.preference = preference));
  }

  getFieldsForExport() {
    if (this.exportAllFields === 'export_fields_all') {
      return null;
    } else if (this.showExportDisplayedColumns) {
      return this.fieldSelectionList(this.displayedColumns);
    } else {
      return this.fieldSelectionList(this.columns);
    }
  }

  submit() {
    this.loading = true;
    if (this.fieldsLocked) {
      this.data.scope.fields = this.data.lockedFields;
    } else if (this.data.fields) {
      this.data.scope.fields = this.getFieldsForExport();
      this.saveUserPreferences();
    }
    if (this.data.removeFieldsProperty && this.data.scope.fields === null) {
      delete this.data.scope.fields;
    }
    if (this.data.dataFormats) {
      this.data.scope.summaryOnly = this.selectedDataFormat === 'summary';
    }
    if (this.data.service) {
      const scope = decamelizeKeysDeep(this.data.scope);
      let request = this.data.service.export(
        scope,
        this.data.params.toString()
      );
      if (this.data.customUrl) {
        request = this.data.service.export(
          scope,
          this.data.params.toString(),
          null,
          this.data.customUrl
        );
      }
      request.subscribe(
        () => {
          this.onSubmitSuccess();
        },
        (err) => {
          if (err[0] === 'text: ok') {
            this.onSubmitSuccess();
            return;
          }
          this.errors = parseErrors(err);
          this.loading = false;
        }
      );
    }
    if (this.data.callback) {
      if (this.data.type === 'driver pay') {
        this.data.callback(this.data.scope.driverPayFormats);
      } else {
        this.data.callback();
      }
    }
  }

  onSubmitSuccess() {
    this.loading = false;
    this.exportSubmitted = true;
    setTimeout(() => this.dialogRef.close(), 5000);
  }

  generateFieldColumns(fields: FieldOption[], n = 20): Array<FieldOption[]> {
    let columns = new Array<FieldOption[]>();
    for (let i = 0, j = 0; i < fields.length; i++) {
      if (i >= n && i % n === 0) {
        j++;
      }
      columns[j] = columns[j] || [];
      columns[j].push(fields[i]);
    }
    return columns;
  }

  fieldSelectionList(columns: Array<FieldOption[]>): string[] {
    let fieldSelection: string[] = [];
    columns.forEach((column) => {
      column.forEach((field) => {
        if (field.selected) {
          fieldSelection.push(field.key);
        }
      });
    });
    return fieldSelection;
  }

  onDateChanged(dates: Date[]): void {
    if (this.data.type === 'driver-shifts' && dates && dates.length) {
      dates[dates.length - 1].setHours(23, 59, 59);
    }
    let dateRange = [
      dates[0].toISOString(),
      dates[dates.length - 1].toISOString(),
    ].join(',');
    let params: HttpParams = new HttpParams({
      fromObject: { date_range: dateRange },
    });
    this.data.params = params;
  }
}
