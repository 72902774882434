import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '../shared/resource.service';
import { CustomField, CustomFieldKind } from './custom-field';
import { CustomFieldSerializer } from './custom-field.serializer';
import { Observable, forkJoin } from 'rxjs';
import { groupBy } from 'lodash';
import { map } from 'rxjs/operators';

interface SortedFieldList {
  old?: CustomField[];
  new?: CustomField[];
}

@Injectable()
export class CustomFieldService extends ResourceService<CustomField> {
  constructor(http: HttpClient) {
    super(http, 'customfields/', new CustomFieldSerializer());
  }

  updateFields(fields: CustomField[]): Observable<any> {
    const validFields = fields.filter(field => field.key && field.key !== '');
    const sortedFields: SortedFieldList = groupBy(validFields, (field) => field.id ? 'old' : 'new');

    const requests = [];

    if (sortedFields.new && sortedFields.new.length > 0) {
      requests.push(this.save(sortedFields.new));
    }

    if (sortedFields.old && sortedFields.old.length > 0) {
      requests.push(this.bulkUpdate(sortedFields.old));
    }

    return forkJoin(requests);
  }

  bulkUpdate(fields: CustomField[]): Observable<any> {
    const resourceUrl = this.resourceUrl + 'bulkupdate/';

    const serializer = new CustomFieldSerializer();
    const formattedFields = fields.map(field => serializer.toJson(field));

    return this.http.put(resourceUrl, formattedFields, { headers: this.requestHeaders() });
  }

  getFieldsForKind(kind: CustomFieldKind): Observable<CustomField[]> {
    return this.list({ active: true, kind: kind }).pipe(
      map(fieldList => fieldList.filter(field => field.kind === kind && field.active))
    );
  }

  getIndexedFieldsForKind(kind: CustomFieldKind): Observable<{ [key: string]: CustomField }> {
    return this.getFieldsForKind(kind).pipe(
      map(fields => {
        return fields.reduce((collection, field) => {
          collection[field.key] = field;
          return collection;
        }, {});
      }),
    );
  }
}
