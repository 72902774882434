<form #editShare="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1>{{ title | translate }}</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="change === 'invoice'">        
        <div class="field-group invoice-rate" fxFlex="50">
          <label translate>Invoice Rate</label>
          <div class="input-group" *ngIf="!jobEventShare">
            <input [disabled]="!jobEvent || !jobEvent.canEdit" type="number" name="invoice_rate" [min]="0"
                   [(ngModel)]="model.invoiceRate" placeholder="{{ 'Invoice Rate' | translate }}" />
            <div>
              <input [disabled]="!jobEvent || !jobEvent.canEdit" required type="hidden" name="invoice" [(ngModel)]="unitFor['invoice']" />
              <dropdown [disabled]="!jobEvent || !jobEvent.canEdit"
                        [options]="invoiceTypeOptions"
                        title="-"
                        [config]="typeConfig"
                        [selectedOption]="invoiceTypeOption"
                        (onSelect)="weightUnitChange('invoice', $event, editShare)">
              </dropdown>
            </div>
          </div>
          <div class="input-group" *ngIf="jobEventShare">
            <input type="number" name="invoice_rate" [min]="0" [(ngModel)]="model.invoiceRate" placeholder="{{ 'Invoice Rate' | translate }}" />
            <div>
              <input required type="hidden" name="invoice" [(ngModel)]="unitFor['invoice']" />
              <dropdown [options]="invoiceTypeOptions"
                        title="-"
                        [config]="typeConfig"
                        [selectedOption]="invoiceTypeOption"
                        (onSelect)="weightUnitChange('invoice', $event, editShare)">
              </dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="change === 'shared-invoice'">
        <div class="field-group invoice-rate" fxFlex="50">
          <label translate>Haul Rate</label>
          <div class="input-group">
            <input type="number" name="invoice_rate" [min]="0" [(ngModel)]="model.invoiceRate" placeholder="{{ 'Haul Rate' | translate }}" />
            <div>
              <input required type="hidden" name="invoice" [(ngModel)]="unitFor['invoice']" />
              <dropdown [options]="invoiceTypeOptions"
                        title="-"
                        [config]="typeConfig"
                        [selectedOption]="invoiceTypeOption"
                        (onSelect)="weightUnitChange('invoice', $event, editShare)">
              </dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="change === 'haul'">
        <div class="field-group haul-rate" fxFlex="50">
          <label translate>Haul Rate</label>
          <div class="input-group">
            <input type="number" name="haul_rate" [min]="0" [(ngModel)]="model.haulRate" placeholder="{{ 'Haul Rate' | translate }}" />
            <div>
              <input required type="hidden" name="haul" [(ngModel)]="unitFor['haul']" />
              <dropdown [options]="haulTypeOptions"
                        title="-"
                        [config]="typeConfig"
                        [selectedOption]="haulTypeOption"
                        (onSelect)="weightUnitChange('haul', $event, editShare)">
              </dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="change === 'trucks'">
        <div class="field-group number-trucks" fxFlex="50">
          <label translate>Number of Trucks Needed</label>
          <input [disabled]="!jobEvent || !jobEvent.canEdit" type="text" name="number-trucks" placeholder="{{ 'Number Needed' | translate }}" [(ngModel)]="model.numTrucks" #numTrucks="ngModel" />
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary save-button"
      (click)="editShare.form.valid && submit(); false"
      [disabled]="!editShare.form.valid || editShare.pristine || loading"
      [ngClass]="{loading: loading}"
      translate>
      Save
    </button>
  </div>
</form>
