"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var connection_service_1 = require("../../connections/connection.service");
exports.FILTERS = [
    {
        type: 'dateRange',
        field: 'dateRange',
        label: 'Date Range',
    },
    {
        type: 'boolean',
        field: 'truckChanged',
        label: 'Changed Trucks',
    },
    {
        type: 'number',
        field: 'lunchBreak',
        label: 'Lunch Break Duration Minimum',
    },
    {
        type: 'boolean',
        field: 'includeLeasedFleetDrivers',
        label: 'Include Leased Fleet Drivers',
    },
    {
        type: 'dropdown',
        field: 'carrier',
        label: 'Carriers',
        dropdownConfig: {
            service: connection_service_1.ConnectionService,
            idProperty: 'organization.carrier.id',
        },
    },
];
