import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ResourceService } from '../shared/resource.service';
import { CondensedTripSerializer } from './condensed-trip.serializer';
import { RuckitCondensedTrip } from './ruckit-condensed-trip';

@Injectable()
export class CondensedTripService extends ResourceService<RuckitCondensedTrip> {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(http: HttpClient) {
    super(http, 'trips/condensed/', new CondensedTripSerializer());
  }
}
