import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { find as _find, reject, clone } from 'lodash';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';

import { parseErrors } from '../../shared/api.service';
import { Assignment } from '../assignment';
import { DropdownComponent } from '../../shared';
import { Job } from '../../jobs/job';
import { AssignmentService } from '../assignment.service';
import { JobService } from '../../jobs/job.service';
import { JobEventService } from '../../job-events/job-event.service';
import { JobEvent } from '../../job-events/job-event';
import { TruckService } from '../../trucks/truck.service';

@Component({
  selector: 'move-assignments-dialog',
  templateUrl: './move-assignments-dialog.component.html',
  styleUrls: ['./move-assignments-dialog.component.scss']
})
export class MoveAssignmentsDialogComponent implements OnInit, OnDestroy {
  @Input() assignments: Assignment[] = [];
  @Input() job: Job;
  @Input() jobEvent: JobEvent;
  @Input() start: Date;
  @Input() hasAllDriversEnabled = false;
  callback: Function;

  loading = false;
  errors = [];
  copy = false;
  model = {
    jobEvent: null
  };
  trucksEditable = false;
  trucksDropdownConfig = {
    small: true,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'ticketName',
    service: TruckService,
    query: { ordering: 'name', carrier: null }
  };
  jobs: Job[] = [];
  jobsReq: Subscription;
  jobsConfig = {
    searchable: true,
    group: true,
    showLoading: true,
    nameProperty: 'name',
    loadingOptions: false
  };
  jobEvents: JobEvent[] = [];
  jobEventsReq: Subscription;
  jobEventsConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };

  @ViewChild('jobsDropdown', { static: true }) jobsDropdown: DropdownComponent;
  @ViewChild('jobEventsDropdown', { static: true }) jobEventsDropdown: DropdownComponent;

  constructor(
    private assignmentService: AssignmentService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    public dialogRef: MatDialogRef<MoveAssignmentsDialogComponent>
  ) { }

  ngOnInit() {
    this.getJobs();
    if (this.job) { this.getJobEvents(); }
  }

  ngOnDestroy() {
    if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
      this.jobsReq.unsubscribe();
    }
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
  }

  onSelect(filterType: string, item: Job|JobEvent): void {
    switch (filterType) {
      case 'job':
        this.job = <Job>item;
        this.jobEvent = null;
        this.jobEventsDropdown.selectedOption = null;
        this.jobEvents = [];
        this.getJobEvents();
        break;
      case 'jobEvent':
        const jobEvent = <JobEvent>item;
        this.jobEvent = jobEvent;
        this.model[filterType] = jobEvent;
        this.assignments.forEach(assignment => assignment.jobevent = jobEvent);
        let timezone = jobEvent.job && jobEvent.job.startLocation && jobEvent.job.startLocation.timezone;
        if (!timezone) { timezone = 'UTC'; }
        const startDate = moment.tz(jobEvent.shift1StartTimestamp, timezone).format('YYYY-MM-DD');

        if (startDate) {
          this.assignments.forEach(assignment => {
            const uniqueStart = moment(`${startDate} ${assignment.uniqueStartTime}`);
            assignment.uniqueStart = uniqueStart.toISOString();
            assignment.uniqueStartDate = uniqueStart.toDate();
          });
        }
        break;
    }
  }

  submit(): void {
    if (this.copy) {
      const assignments = clone(this.assignments);
      assignments.forEach(assignment => {
        if (this.model.jobEvent) { assignment.jobevent = this.model.jobEvent; }
        delete assignment['id'];
      });
      this.assignmentService.bulkCreate(assignments).subscribe(res => {
        if (res && res.errors && res.errors.length) {
          this.errors = res.errors;
        } else {
          this.dialogRef.close();
          this.callback();
        }
      });
    } else {
      this.assignments.forEach(assignment => {
        if (this.model.jobEvent) { assignment.jobevent = this.model.jobEvent; }
      });
      this.assignmentService.bulkUpdate(this.assignments).subscribe(res => {
        if (res && res.errors && res.errors.length) {
          this.errors = res.errors;
        } else {
          this.dialogRef.close();
          this.callback();
        }
      }, err => this.errors = parseErrors(err));
    }
  }

  getJobs(search = null): void {
    if (this.jobsReq && typeof this.jobsReq.unsubscribe === 'function') {
      this.jobsReq.unsubscribe();
    }

    this.jobsReq = this.jobService.list({
      ordering: 'project__name,name,start_date',
      jobevents__end__gte: this.start && this.start.toISOString(),
      page_size: 6,
      search: search,
      serializer: 'JobGroupedDropdown'
    }).subscribe(jobs => {
      this.jobs = jobs;
      if (this.job && this.jobsDropdown) {
        let selectedOption = _find(this.jobs, { id: this.job && this.job.id });
        if (selectedOption) { this.jobs = reject(this.jobs, selectedOption); }
        this.jobs.unshift(this.job);
        this.jobsDropdown.selectedOption = this.job;
      }
      this.jobsDropdown.config.loadingOptions = false;
      this.jobsConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
      this.jobsConfig.loadingOptions = false;
    });
  }

  getJobEvents(search = null): void {
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }

    this.jobEventsReq = this.jobEventService.getJobEvents({
      ordering: 'shift1_start',
      job: this.job && this.job.id,
      end__gte: this.start && this.start.toISOString(),
      page_size: 6,
      search: search,
      serializer: 'DefaultDropdown'
    }).subscribe(jobEvents => {
      this.jobEvents = jobEvents;
      if (this.jobEvent && this.jobEventsDropdown) {
        let selectedOption = _find(this.jobEvents, { id: this.jobEvent && this.jobEvent.id });
        if (selectedOption) { this.jobEvents = reject(this.jobEvents, selectedOption); }
        this.jobEvents.unshift(this.jobEvent);
        this.jobEventsDropdown.selectedOption = this.jobEvent;
        if (!this.assignments || !this.assignments.length) { this.getAssignments(); }
      }
      this.jobEventsDropdown.config.loadingOptions = false;
      this.jobEventsConfig.loadingOptions = false;
    }, err => {
      this.errors = parseErrors(err);
      this.jobEventsConfig.loadingOptions = false;
    });
  }

  getAssignments() {
    this.assignmentService.listAll(10, { jobevent: this.jobEvent.id }).subscribe(a => {
      this.assignments = a;
    }, err => this.errors = parseErrors(err));
  }

  dropdownNextPage(event: MouseEvent, type: string): void {
    let config;
    let service;

    switch (type) {
      case 'job':
        config = this.jobsConfig;
        service = this.jobService;
        break;
      case 'jobEvent':
        config = this.jobEventsConfig;
        service = this.jobEventService;
        break;
    }

    if (!config.loadingOptions) {
      let request = service.listNext();
      if (request) {
        config.loadingOptions = true;
        request.subscribe(results => {
          switch (type) {
            case 'job':
              this.jobs = this.jobs.concat(results);
              break;
            case 'jobEvent':
              this.jobEvents = this.jobEvents.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  dropdownSearch(term = '', type: string): void {
    switch (type) {
      case 'job':
        this.getJobs(term);
        break;
      case 'jobEvent':
        this.getJobEvents(term);
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  assignmentUniqueStartDateChanged(assignment: Assignment, values: Date[]): void {
    if (values && values.length) { assignment.uniqueStartDate = values[0]; }
  }
}
