import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';

import { AssignmentService } from './assignment.service';
import { DriverService } from '../drivers/driver.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs';

@Component({
  selector: 'copy-assignments-dialog',
  templateUrl: './copy-assignments-dialog.component.html',
  styleUrls: ['./copy-assignments-dialog.component.scss']
})
export class CopyAssignmentsDialogComponent implements OnInit {
  assignmentList = [];
  driversList = [];
  selectedAssignment = [];
  deselectedAssignments = [];
  remainingAssignments = [];
  allInitialAssignments = [];
  selectedDrivers = [];
  displayAssignmentName = [];
  copyAssignmentObj = [];
  copyDriverObj = [];
  errors = [];
  loading = false;
  assignmentDropdownConfig = {
    nameProperty: 'name',
    searchable: false,
    group: false,
    loadingOptions: false,
    multiselect: true
  };
  driverDropdownConfig = {
    nameProperty: 'name',
    searchable: false,
    group: false,
    loadingOptions: false,
    multiselect: true
  };
  confirmDialog: MatDialogRef<any>;
  callback;
  allAssignmentSelected = false;
  showAllAssignments = false;
  deselectedAssignment = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CopyAssignmentsDialogComponent>,
    private assignmentService: AssignmentService,
    private driverService: DriverService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getAssignmentsList();
    this.getDriversList();
    this.showAllAssignments = true;
    if (this.showAllAssignments) {
      this.assignmentList.forEach(assign => {
        this.copyAssignmentObj.push(assign.id);
      });
    }
  }

  /**
   * fetch the list of assignments for a selected driver
   * this list is used to display assignments in dropdown
   */
  getAssignmentsList(): void {
    if (this.data && this.data.selectedItem) {
      this.assignmentList = this.data.selectedItem.assignments;
      this.allInitialAssignments = [...this.assignmentList];
      // this.assignmentList.forEach(assignment => {
      //   this.displayAssignmentName.push({ name: assignment.job.name, id: assignment.id });
      // });
    }
  }

  /**
   * fetch the list of all the drivers available in the fleet.
   * this list is used to display drivers in dropdown
   */
  getDriversList(): void {
    this.driverService.list().subscribe(drivers => {
      this.driversList = drivers.filter(driver => driver.truck);
    });
  }

  allSelectAssignments(event) {
    this.allAssignmentSelected = event.target.checked;
    if (this.allAssignmentSelected) {
      this.assignmentList = [...this.allInitialAssignments];
      this.assignmentList.forEach(assign => {
        this.copyAssignmentObj.push(assign.id);
      });
    }
  }

  /**
   * @param  {} option
   * @returns void
   * stores the list of selected assignments
   * creates a list of id's for selected assignments to be copied
   */
  setSelectedAssignment(option): void {
    this.selectedAssignment = option;
    this.selectedAssignment.forEach(res => {
      this.copyAssignmentObj.push(res.id);
    });
  }

  deselectAssignment(assignment) {
    this.deselectedAssignment = true;
    if (this.assignmentList) {
      this.assignmentList.forEach(currentAssignment => {
        this.assignmentList.splice(assignment, 1);
        this.displayAssignmentName.push({ name: currentAssignment.job.name, id: currentAssignment.id });
      });
      this.remainingAssignments = [...this.assignmentList];
      this.copyAssignmentObj = this.remainingAssignments.map(copiedAssignment => copiedAssignment.id);
    }
  }

  /**
   * @param  {} option
   * @returns void
   * stores the list of selected drivers
   * creates a list of id's for selected drivers to be copied
   */
  setSelectedDriver(option): void {
    this.selectedDrivers = option;
    this.selectedDrivers.forEach(res => {
      this.copyDriverObj.push(res.id);
    });
  }

  /**
   * @param  {} event
   * @returns void
   * opens the dialog to confirm to copy the assignments to drivers
   * if yes, then makes a request to copy assignments
   */
  openConfirmDialog(event): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
      disableClose: true
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Are you sure?',
      body: 'The selected drivers will be assigned to the selected assignment/s',
      close: 'Cancel',
      accept: 'Copy'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        const copyAssignmentPayload = {
          assignments: this.copyAssignmentObj,
          drivers: this.copyDriverObj
        };
        this.assignmentService.copyAssignment(copyAssignmentPayload).subscribe((res) => {
          this.dialogRef.close();
          this.callback();
        }, (err) => {
          this.errors = err;
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }
}
