import { Component, Input } from '@angular/core';

export type TimelineLabel = {
  name: string,
  color: string,
  icon?: string
};

@Component({
  selector: 'timeline-legend',
  templateUrl: './timeline-legend.component.html',
  styleUrls: ['./timeline-legend.component.scss'],
})
export class TimelineLegendComponent {
  @Input() labels: TimelineLabel[];
  constructor() {}
}
