"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".trip-row[_ngcontent-%COMP%]{display:flex;font-size:12px;font-weight:600;text-align:center;color:#354052}.trip-row.current-trip[_ngcontent-%COMP%]   .en-route-one.current[_ngcontent-%COMP%], .trip-row.current-trip[_ngcontent-%COMP%]   .en-route-two.current[_ngcontent-%COMP%], .trip-row.current-trip[_ngcontent-%COMP%]   .loading.current[_ngcontent-%COMP%], .trip-row.current-trip[_ngcontent-%COMP%]   .unloading.current[_ngcontent-%COMP%]{color:#015bc5}.trip-row[_ngcontent-%COMP%]   .overall[_ngcontent-%COMP%]{display:flex;flex:1 1 335px;height:44px;background-color:#fafbfc;border-bottom:1px solid #dfe2e5;padding:0 15px 0 42px;position:relative}.trip-row[_ngcontent-%COMP%]   .overall[_ngcontent-%COMP%]   .trip-label[_ngcontent-%COMP%]{height:44px;line-height:44px;font-size:14px;font-weight:600}.trip-row[_ngcontent-%COMP%]   .overall[_ngcontent-%COMP%]   .trip-time-container[_ngcontent-%COMP%]{flex-grow:1;padding:10px 0;margin:0 10px}.trip-row[_ngcontent-%COMP%]   .overall[_ngcontent-%COMP%]   .trip-time-container[_ngcontent-%COMP%]   .trip-time[_ngcontent-%COMP%]{display:flex;justify-content:center;flex-direction:column;height:100%;text-align:right}.trip-row[_ngcontent-%COMP%]   .en-route-one[_ngcontent-%COMP%], .trip-row[_ngcontent-%COMP%]   .en-route-two[_ngcontent-%COMP%], .trip-row[_ngcontent-%COMP%]   .loading[_ngcontent-%COMP%], .trip-row[_ngcontent-%COMP%]   .unloading[_ngcontent-%COMP%]{width:90px;height:44px;background-color:#fafbfc;border-bottom:1px solid #dfe2e5;padding:0;display:flex;flex:1 0 90px;flex-direction:column;align-items:center;justify-content:center}.trip-row[_ngcontent-%COMP%]   .en-route-one[_ngcontent-%COMP%]{padding-left:10px}.trip-row[_ngcontent-%COMP%]   .trip-status[_ngcontent-%COMP%]{width:125px;height:18px;border-radius:10px;text-align:center;font-size:12px;color:#fff;background-color:#2ea1f8}.trip-row[_ngcontent-%COMP%]   .trip-time[_ngcontent-%COMP%]{color:#7f8fa4;font-size:12px}"];
exports.styles = styles;
