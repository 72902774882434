import { Observable, combineLatest } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { clone, sortBy } from 'lodash';

import { Trip } from './trip';
import { CondensedTrip } from './condensed-trip';
import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/index';
import { ExportDialogData, FieldOption } from '../shared/export-dialog/export-dialog.component';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class TripService {
  baseUrl = environment.serverUrl;
  trip: Trip;
  condensedTrip: CondensedTrip;
  public nextUri;
  public count;
  progress$;
  progress;

  constructor(private http: HttpClient) { }

  getTrip(tripId: string) {
    let tripUrl = this.baseUrl + 'trips/' + tripId + '/';

    return this.http.get(tripUrl, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  save(trip) {
    const tripUrl = this.baseUrl + 'trips/';
    let _trip = decamelizeKeysDeep(clone(trip));

    if (typeof _trip.assignment === 'object') {
      _trip.assignment = _trip.assignment.id;
    }
    if (typeof _trip.driver === 'object') {
      _trip.driver = _trip.driver.id;
    } else if (_trip.driver_id) {
      _trip.driver = _trip.driver_id;
    }
    if (typeof _trip.truck === 'object') {
      _trip.truck = _trip.truck.id;
    } else if (_trip.truck_id) {
      _trip.truck = _trip.truck_id;
    }
    if (typeof _trip.job_event === 'object') {
      _trip.jobevent = _trip.job_event.id;
    }
    if (_trip._start_time_timestamp) {
      _trip.start_time = _trip._start_time_timestamp;
    }
    if (_trip._end_time_timestamp) {
      _trip.end_time = _trip._end_time_timestamp;
    }

    if (!trip.id) {
      return this.http.post(tripUrl, _trip, { headers: requestHeaders() });
    } else {
      return this.http.put(tripUrl + trip.id + '/', _trip, { headers: requestHeaders() });
    }
  }

  patchTrip(tripId: string, trip: any) {
    const tripUrl = `${this.baseUrl}trips/${tripId}/`;
    let _trip = decamelizeKeysDeep(clone(trip));
    return this.http.patch(tripUrl, _trip, { headers: requestHeaders() });
  }

  undoRetakeRequest(trip) {
    const tripUrl = this.baseUrl + 'trips/';
    let _trip = decamelizeKeysDeep(clone(trip));

    return this.http.put(tripUrl + trip.id + '/', {
      retake_status: null
    }, { headers: requestHeaders() });
  }

  legacySaveWithCheckins(trip, loadingCheckinFile: File, unloadingCheckinFile: File): Observable<any> {
    if (trip) {
      let _trip = clone(trip);

      if (
        typeof _trip.loadingCheckin === 'object' &&
        _trip.loadingCheckin.date &&
        typeof _trip.loadingCheckin.date.getMonth === 'function'
      ) {
        _trip.loadingCheckin.date = _trip.loadingCheckin.date.toISOString();
      }

      if (
        typeof _trip.unloadingCheckin === 'object' &&
        _trip.unloadingCheckin.date &&
        typeof _trip.unloadingCheckin.date.getMonth === 'function'
      ) {
        _trip.unloadingCheckin.date = _trip.unloadingCheckin.date.toISOString();
      }

      if (typeof _trip.assignment === 'object') {
        _trip.assignment = _trip.assignment.id;
      }
      if (typeof _trip.driver === 'object') {
        _trip.driver = _trip.driver.id;
      } else if (_trip.driverId) {
        _trip.driver = _trip.driverId;
      }
      if (typeof _trip.truck === 'object') {
        _trip.truck = _trip.truck.id;
      } else if (_trip.truckId) {
        _trip.truck = _trip.truckId;
      }
      if (typeof _trip.jobEvent === 'object') {
        _trip.jobevent = _trip.jobEvent.id;
      } else if (_trip.jobEventId) {
        _trip.jobevent = _trip.jobEventId;
      }
      if (typeof _trip.jobevent === 'object') {
        _trip.jobevent = _trip.jobevent.id;
      } else if (_trip.jobeventId) {
        _trip.jobevent = _trip.jobeventId;
      }
      if (typeof _trip.carrier === 'object') {
        _trip.carrier = _trip.carrier.id;
      } else if (_trip.carrierId) {
        _trip.carrier = _trip.carrierId;
      }
      if (typeof _trip.job === 'object') {
        _trip.job = _trip.job.id;
      } else if (_trip.jobId) {
        _trip.job = _trip.jobId;
      }

      if (_trip.loadingCheckin && _trip.loadingCheckin.checkinTime) {
        let value = _trip.loadingCheckin.checkinTime;
        let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
        let d = moment(_trip.loadingCheckin.date).format('YYYY-MM-DD');
        _trip.loadingCheckin.date = moment(d + ' ' + value, pattern).toDate();
      }

      if (_trip.unloadingCheckin && _trip.unloadingCheckin.checkinTime) {
        let value = _trip.unloadingCheckin.checkinTime;
        let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
        let d = moment(_trip.unloadingCheckin.date).format('YYYY-MM-DD');
        _trip.unloadingCheckin.date = moment(d + ' ' + value, pattern).toDate();
      }

      delete _trip.loadingTicketImage;
      delete _trip.unloadingTicketImage;
      delete _trip.loadingCheckin.ticketImage;
      delete _trip.loadingCheckin.signatureImage;
      delete _trip.unloadingCheckin.ticketImage;
      delete _trip.unloadingCheckin.signatureImage;

      _trip = decamelizeKeysDeep(_trip);
      _trip.start_time = _trip.start_time_timestamp || _trip._start_time_timestamp;
      _trip.end_time = _trip.end_time_timestamp || _trip._end_time_timestamp;

      return Observable.create(observer => {
        let formData: FormData = new FormData(),
          xhr: XMLHttpRequest = new XMLHttpRequest();

        formData.append('json', JSON.stringify(_trip));

        if (loadingCheckinFile) {
          formData.append('loading_checkin_ticket_image', loadingCheckinFile, loadingCheckinFile.name);
        }
        if (unloadingCheckinFile) {
          formData.append('unloading_checkin_ticket_image', unloadingCheckinFile, unloadingCheckinFile.name);
        }

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 201) {
              observer.next(JSON.parse(xhr.response));
              observer.complete();
            } else {
              observer.error(xhr.response);
            }
          }
        };

        if (_trip.id) {
          let tripUrl = this.baseUrl + 'trips/' + _trip.id + '/with_checkins/';
          xhr.open('PUT', tripUrl, true);
        } else {
          let tripUrl = this.baseUrl + 'trips/with_checkins/';
          xhr.open('POST', tripUrl, true);
        }
        requestHeaders(xhr);
        xhr.send(formData);
      });
    }
  }

  saveWithCheckinsAndAttachments(trip: CondensedTrip): Observable<any> {
    if (trip) {
      let _trip = clone(trip);
      
      if (_trip.endTime === null) delete _trip.endTime;
      if (_trip.loadingCheckin && _trip.loadingCheckin.date === null) delete _trip.loadingCheckin.date;
      if (_trip.unloadingCheckin && _trip.unloadingCheckin.date === null) delete _trip.unloadingCheckin.date;

      _trip = decamelizeKeysDeep(_trip);
      return _trip.id
        ? this.http.put(
            `${this.baseUrl}trips/${_trip.id}/with_checkins/`,
            _trip,
            {
              headers: requestHeaders(),
            }
          )
        : this.http.post(`${this.baseUrl}trips/with_checkins/`, _trip, {
            headers: requestHeaders(),
          });
    }
    return null;
  }

  saveWithCheckins(trip, loadingCheckinFile: File, unloadingCheckinFile: File): Observable<any> {
    if (trip) {
      let _trip = clone(trip);

      if (
        typeof _trip.loadingCheckin === 'object' &&
        _trip.loadingCheckin.time &&
        typeof _trip.loadingCheckin.time.getMonth === 'function'
      ) {
        _trip.loadingCheckin.date = _trip.loadingCheckin.time.toISOString();
      }

      if (
        typeof _trip.unloadingCheckin === 'object' &&
        _trip.unloadingCheckin.time &&
        typeof _trip.unloadingCheckin.time.getMonth === 'function'
      ) {
        _trip.unloadingCheckin.date = _trip.unloadingCheckin.time.toISOString();
      }
      if (typeof _trip.startTime === 'object' && typeof _trip.startTime.getMonth === 'function') {
        _trip.startTime = _trip.startTime.toISOString();
      }
      if (typeof _trip.endTime === 'object' && typeof _trip.endTime.getMonth === 'function') {
        _trip.endTime = _trip.endTime.toISOString();
      }
      if (typeof _trip.assignment === 'object') {
        _trip.assignment = _trip.assignment.id;
      }
      if (typeof _trip.driver === 'object') {
        _trip.driver = _trip.driver.id;
      } else if (_trip.driverId) {
        _trip.driver = _trip.driverId;
      }
      if (typeof _trip.truck === 'object') {
        _trip.truck = _trip.truck.id;
      } else if (_trip.truckId) {
        _trip.truck = _trip.truckId;
      }
      if (typeof _trip.jobEvent === 'object') {
        _trip.jobevent = _trip.jobEvent.id;
      } else if (_trip.jobEventId) {
        _trip.jobevent = _trip.jobEventId;
      }
      if (typeof _trip.jobevent === 'object') {
        _trip.jobevent = _trip.jobevent.id;
      } else if (_trip.jobeventId) {
        _trip.jobevent = _trip.jobeventId;
      }
      if (typeof _trip.carrier === 'object') {
        _trip.carrier = _trip.carrier.id;
      } else if (_trip.carrierId) {
        _trip.carrier = _trip.carrierId;
      }
      if (typeof _trip.job === 'object') {
        _trip.job = _trip.job.id;
      } else if (_trip.jobId) {
        _trip.job = _trip.jobId;
      }

      delete _trip.loadingTicketImage;
      delete _trip.unloadingTicketImage;
      delete _trip.loadingCheckin.ticketImage;
      delete _trip.loadingCheckin.signatureImage;
      delete _trip.unloadingCheckin.ticketImage;
      delete _trip.unloadingCheckin.signatureImage;

      _trip = decamelizeKeysDeep(_trip);

      return Observable.create(observer => {
        let formData: FormData = new FormData(),
          xhr: XMLHttpRequest = new XMLHttpRequest();

        formData.append('json', JSON.stringify(_trip));

        if (loadingCheckinFile) {
          formData.append('loading_checkin_ticket_image', loadingCheckinFile, loadingCheckinFile.name);
        }
        if (unloadingCheckinFile) {
          formData.append('unloading_checkin_ticket_image', unloadingCheckinFile, unloadingCheckinFile.name);
        }

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 201) {
              observer.next(JSON.parse(xhr.response));
              observer.complete();
            } else {
              observer.error(xhr.response);
            }
          }
        };

        if (_trip.id) {
          let tripUrl = this.baseUrl + 'trips/' + _trip.id + '/with_checkins/';
          xhr.open('PUT', tripUrl, true);
        } else {
          let tripUrl = this.baseUrl + 'trips/with_checkins/';
          xhr.open('POST', tripUrl, true);
        }
        requestHeaders(xhr);
        xhr.send(formData);
      });
    }
  }


  getTrips(query: any = null): Observable<Trip[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let tripsUrl = this.baseUrl + 'trips/';
    return this.http.get(tripsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getCondensedTrips(query: any = null): Observable<CondensedTrip[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString()) {
          params = params.set(key, query[key] && query[key].toString());
        }
      });
    }
    let tripsUrl = this.baseUrl + 'trips/condensed/';
    return this.http.get(tripsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  getAllCondensedTrips(pageSize = 20, query?: any): Observable<CondensedTrip[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    params = params.set('page_size', '1');
    let tripsUrl = this.baseUrl + 'trips/condensed/';
    let requestCount = 0;

    return this.http.get(tripsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => this.extractData(res, true)),
      mergeMap(() => {
        params = params.set('page_size', pageSize.toString());
        let nextReqs: Observable<CondensedTrip[]>[] = [];
        if (this.count < pageSize) {
          nextReqs.push(
            this.http.get(tripsUrl, {
              headers: requestHeaders(),
              params: params
            }).map(res => {
              return this.extractData(res, true);
            })
          );
        } else {
          for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
            params = params.set('page', i.toString());
            nextReqs.push(
              this.http.get(tripsUrl, {
                headers: requestHeaders(),
                params: params
              }).pipe(
                map(res => {
                  requestCount++;
                  return res;
                }),
                map(res => this.extractData(res, true))
              )
            );
          }
        }
        return combineLatest(nextReqs);
      }),
      map((data: CondensedTrip[][]) => {
        let mergedList: CondensedTrip[] = [];
        data.forEach(list => {
          mergedList = mergedList.concat(list);
        });
        return mergedList;
      }),
      catchError(handleError)
    );
  }

  getCondensedTrip(tripId: string) {
    let tripUrl = this.baseUrl + 'trips/' + tripId + '/condensed/';

    return this.http.get(tripUrl, { headers: requestHeaders() }).pipe(
      map(res => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  getExportFields() {
    let tripsUrl = this.baseUrl + 'trips/pandas-export/';
    return this.http.get(tripsUrl, { headers: requestHeaders() }).pipe(
      map(res => sortBy(<FieldOption[]>res, 'label')),
      catchError(handleError)
    );
  }

  export(scope: ExportDialogData['scope'], params: any = null) {
    let tripsUrl = this.baseUrl + 'trips/pandas-export/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(tripsUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  getNext(): Observable<Trip[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  getNextCondensed(): Observable<CondensedTrip[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res, true); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  approveTicket(ticketId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}trips/${ticketId}/approve/`, null, {
      headers: requestHeaders()
    }).pipe(
      map((res: Response) => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  rejectTicket(ticketId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}trips/${ticketId}/reject/`, null, {
      headers: requestHeaders()
    }).pipe(
      map((res: Response) => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  reviewTicket(ticketId: string, field: 'plant_qc' | 'plant_vt' | 'roadway_qc' | 'roadway_vt', edits: any): Observable<any> {
    return this.http.put(`${this.baseUrl}trips/${ticketId}/review/${field.replace('_', '-')}/`, edits, {
      headers: requestHeaders()
    }).pipe(
      map((res: Response) => { return this.extractData(res, true); }),
      catchError(handleError)
    );
  }

  private extractData(res: Object, condensed = false) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (condensed) {
      if (body) {
        return body.map(trip => {
          return new CondensedTrip(trip);
        });
      } else if (resObj) {
        return new CondensedTrip(resObj);
      }
    } else if (body) {
      return body.map(trip => {
        return new Trip(trip);
      });
    } else if (resObj) {
      return new Trip(resObj);
    } else {
      return {};
    }
  }
}
