"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var core_2 = require("@ngx-translate/core");
// angular material
var material_1 = require("@angular/material");
// libraries
var moment = require("moment");
var lodash_1 = require("lodash");
// services
var api_service_1 = require("../../shared/api.service");
var assignment_service_1 = require("./../assignment.service");
var driver_assignments_service_1 = require("./../driver-assignments.service");
var authentication_service_1 = require("../../shared/authentication.service");
var driver_assignments_list_component_1 = require("./../driver-assignments-list.component");
// components
var driver_map_component_1 = require("./../driver-map.component");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var quick_dispatch_dialog_component_1 = require("../../dispatch/quick-dispatch-dialog.component");
var copy_assignments_dialog_component_1 = require("./../copy-assignments-dialog.component");
var filters_dialog_component_1 = require("../../shared/filters-dialog/filters-dialog.component");
// serializers
var driver_serializer_1 = require("./../../drivers/driver.serializer");
// services
var preference_service_1 = require("../../preferences/preference.service");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var assignment_filter_service_1 = require("./../assignment.filter.service");
var location_service_1 = require("../../locations/location.service");
var tag_service_1 = require("../../tags/tag.service");
var all_drivers_maps_metadata_service_1 = require("../../drivers/all-drivers-metadata/all-drivers-maps-metadata.service");
// contants
var options_1 = require("../../drivers/driver-context-menu/data/options");
var AllDriverAssignmentsComponent = /** @class */ (function () {
    function AllDriverAssignmentsComponent(route, router, location, assignmentService, collaboratorService, driverAssignmentsService, authenticationService, preferenceService, translateService, allDriversMetadataService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.location = location;
        this.assignmentService = assignmentService;
        this.collaboratorService = collaboratorService;
        this.driverAssignmentsService = driverAssignmentsService;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.translateService = translateService;
        this.allDriversMetadataService = allDriversMetadataService;
        this.dialog = dialog;
        this.displayedColumns = [];
        this.rows = [];
        this.loading = false;
        this.pendingJobsCount = 0;
        this.view = 'list';
        this.type = 'latest';
        this.assignmentDateChanged = new rxjs_1.Subject();
        this.errors = [];
        this.assignments = [];
        this.expandedAssignments = [];
        this.hours = [];
        this.queryParams = {};
        this.sortBy = 'date';
        this.sortAsc = true;
        this.search = '';
        this.searchChanged = new rxjs_1.Subject();
        this.filters = [];
        this.selectedCount = 0;
        this.allSelected = false;
        this.selectedAssignments = [];
        this.excludeAssignments = [];
        this.count = 0;
        this.page = 1;
        this.pageSize = 10;
        this.multipleActionDropdownOptions = [
            { name: 'Export', button: true },
            { name: 'Operator\'s Export', button: true }
        ];
        this.timeIntervalDropdownOptions = [
            { id: 1, name: this.translateService.instant('1 hour') },
            { id: 2, name: this.translateService.instant('30 minutes') }
        ];
        this.timeIntervalDropdownConfig = {
            nameProperty: 'name',
            loadingOptions: false,
            multiselect: false,
        };
        this.timelineData = [];
        this.timelineConfig = {
            visibleBars: ['trips', 'predictedTrips'],
            headerHeight: 62,
            rowHeight: 99,
            scroll: true,
            currentTime: true,
            selectedTime: false
        };
        this.menuOptions = [
            { name: this.translateService.instant('Export'), action: 'export', link: false, external: false },
            { name: this.translateService.instant('Void'), action: 'void', link: false, external: false },
        ];
        this.changeSearchEmitter = new core_1.EventEmitter();
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.viewAssignmentsOptionAction = options_1.ViewAssignmentsOptionAction;
        this.showNoAssignmentsAlert = false;
        this.additionalMenuItems = [options_1.FleetHealthAction];
        this.showInvoiceTotal = false;
        this.isExpansionTripRow = function (i, row) { return row.hasOwnProperty('tripRow'); };
        this.copyAssignmentCallback = function (e) {
            _this.getDriverAssignments(); // gets called on success of copy assignments
        };
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.queryParams['search'] = search;
            _this.getDriverAssignments();
        });
    }
    AllDriverAssignmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.authenticationService.hasAllDriversEnabled()) {
            this.router.navigate(['/jobs/daily']);
        }
        this.route.queryParams.forEach(function (params) {
            _this.queryParams = params;
            _this.loading = true;
            _this.sortBy = _this.queryParams.sortBy ? _this.queryParams.sortBy : 'driver__profile__last_name';
            _this.sortAsc = _this.queryParams.sortAsc ? (_this.queryParams.sortAsc === 'true') : false;
            _this.search = _this.queryParams['search'];
            if (_this.queryParams['view'] || _this.queryParams['type']) {
                _this.switchView((_this.queryParams['view'] ? _this.queryParams['view'] : _this.view), (_this.queryParams['type'] ? _this.queryParams['type'] : _this.type));
            }
            _this.showInvoiceTotal = _this.queryParams['showInvoiceTotal'] === 'true';
            _this.getPreferences();
            _this.page = _this.queryParams['page'] ? Number(_this.queryParams['page']) : 1;
            if (_this.queryParams['date']) {
                _this.date = moment(_this.queryParams['date'], 'YYYYMMDD');
            }
            _this.setDefaultDate();
            if (_this.queryParams['driver_assignments']) {
                var driverId = _this.queryParams['driver_assignments'];
                _this.driverIdToOpenAssignments = driverId;
            }
            if (_this.queryParams['returnTo']) {
                history.pushState(null, null, _this.queryParams['returnTo']);
            }
            _this.getDriverAssignments();
        });
        this.getPendingCount();
    };
    AllDriverAssignmentsComponent.prototype.switchView = function (view, type) {
        if (type === void 0) { type = 'geotrip'; }
        this.queryParams = Object.assign(lodash_1.clone(this.queryParams), { type: type, view: view });
        var url = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: this.queryParams
        }).toString();
        this.location.go(url);
        this.view = view;
        this.type = type;
        this.setView();
    };
    AllDriverAssignmentsComponent.prototype.setView = function () {
        switch (this.view) {
            case 'latest':
                this.displayedColumns = this.type === 'geotrip' ? [
                    'selector', 'driver', 'geo-status', 'location', 'geo-duration', 'assignments'
                ] : [
                    'selector', 'driver', 'status', 'location', 'duration', 'assignments'
                ];
                break;
            case 'timeline':
                this.displayedColumns = this.type === 'geotrip' ? [
                    'selector', 'driver', 'geo-status'
                ] : [
                    'selector', 'driver', 'status'
                ];
                break;
            case 'stats':
                this.expandedAssignments = [];
                this.displayedColumns = this.type === 'geotrip' ? [
                    'selector', 'driver', 'geo-status', 'geo-en-route1', 'geo-loading', 'geo-en-route2',
                    'geo-unloading', 'load', 'weight'
                ].concat(this.showInvoiceTotal ? ['invoice-total', 'expense-total'] : [], [
                    'assignments'
                ]) : [
                    'selector', 'driver', 'status', 'en-route1', 'loading', 'en-route2',
                    'unloading', 'load', 'weight'
                ].concat(this.showInvoiceTotal ? ['invoice-total', 'expense-total'] : [], [
                    'assignments'
                ]);
                break;
            case 'map':
                this.displayedColumns = this.type === 'geotrip' ? [
                    'selector', 'driver', 'geo-status', 'assignments'
                ] : [
                    'selector', 'driver', 'status', 'assignments'
                ];
                break;
            default:
                this.expandedAssignments = [];
                this.displayedColumns = this.type === 'geotrip' ? [
                    'selector', 'driver', 'geo-status', 'location', 'geo-duration', 'assignments'
                ] : [
                    'selector', 'driver', 'status', 'location', 'duration', 'assignments'
                ];
                break;
        }
        if (this.preference && this.preference.blob) {
            if (this.preference.blob.view !== this.view) {
                this.savePreferences(this.preference.blob.filters);
            }
        }
        else {
            this.savePreferences(this.preference && this.preference.blob && this.preference.blob.filters);
        }
    };
    /**
     * @param  {} selectedItem
     * Opens the copy assignment dialog of the selected driver.
     */
    AllDriverAssignmentsComponent.prototype.copyAssignment = function (selectedItem) {
        var dialog = this.dialog.open(copy_assignments_dialog_component_1.CopyAssignmentsDialogComponent, {
            width: '500px',
            height: '600px',
            data: { selectedItem: selectedItem },
            disableClose: true
        });
        dialog.componentInstance.callback = this.copyAssignmentCallback;
    };
    AllDriverAssignmentsComponent.prototype.ngOnDestroy = function () {
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
    };
    AllDriverAssignmentsComponent.prototype.getDriverAssignments = function (query, skipLoading) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (skipLoading === void 0) { skipLoading = false; }
        if (!skipLoading) {
            this.loading = true;
        }
        var allDriversRefresh = this.authenticationService.getFeature('allDriversRefresh') || 300000;
        if (this.authenticationService.hasFavoriteTags() && !!!this.filters.find(function (f) { return (f.key === 'tags'); })) {
            query['user_tags'] = 'True';
        }
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var filters = this.filters.reduce(function (acc, filter) { return (__assign({}, acc, filter.query)); }, {});
        delete filters['undefined'];
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        if (this.assignmentDate) {
            startDate = new Date(this.assignmentDate);
            startDate.setHours(0, 0, 0, 0);
            endDate = lodash_1.clone(startDate);
            endDate.setHours(23, 59, 59, 999);
        }
        this.assignmentsReq = this.driverAssignmentsService.listUpdate(allDriversRefresh, __assign({ ordering: order, page_size: this.pageSize, search: this.search, page: this.page, dispatched: 'True', active_range: startDate.toISOString() + ',' + endDate.toISOString() }, (!this.showInvoiceTotal && { omit: 'invoice_total,expense_total' }), filters, query)).subscribe(function (assignments) {
            assignments = assignments.sort(function (a, b) { return ((moment.min(a.assignments.map(function (ass) { return (moment(ass.uniqueStart)); })).toISOString() < moment.min(b.assignments.map(function (ass) { return (moment(ass.uniqueStart)); })).toISOString()) ? -1 :
                (moment.min(a.assignments.map(function (ass) { return (moment(ass.uniqueStart)); })).toISOString() > moment.min(b.assignments.map(function (ass) { return (moment(ass.uniqueStart)); })).toISOString()) ? 1 : 0); });
            _this.count = _this.driverAssignmentsService.count || assignments.length;
            _this.setAssignmentData(assignments);
            _this.setupTimelineData(assignments);
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.loading = false;
        });
        this.allDriversMetadataService.listUpdate(allDriversRefresh, __assign({ ordering: order, page_size: this.pageSize, search: this.search, page: this.page, dispatched: 'True', active_range: startDate.toISOString() + ',' + endDate.toISOString(), omit: 'invoice_total,expense_total' }, filters, query)).subscribe();
    };
    AllDriverAssignmentsComponent.prototype.setAssignmentData = function (assignments) {
        var _this = this;
        var rowData = [];
        assignments.forEach(function (assignment) {
            if (assignment.tripStatus === 'Loading') {
                assignment.duration.target = assignment.latestTrip.job &&
                    assignment.latestTrip.job.startLocation &&
                    assignment.latestTrip.job.startLocation.averageLoadingTime ?
                    Number(assignment.latestTrip.job.startLocation.averageLoadingTime) : 0;
            }
            else if (assignment.tripStatus === 'Unloading') {
                assignment.duration.target = assignment.latestTrip.job &&
                    assignment.latestTrip.job.endLocation &&
                    assignment.latestTrip.job.endLocation.averageUnloadingTime ?
                    Number(assignment.latestTrip.job.endLocation.averageUnloadingTime) : 0;
            }
            if (assignment.geoTripStatus === 'Loading') {
                assignment.geoDuration.target = assignment.latestGeoTrip.job &&
                    assignment.latestGeoTrip.job.startLocation &&
                    assignment.latestGeoTrip.job.startLocation.averageLoadingTime ?
                    Number(assignment.latestGeoTrip.job.startLocation.averageLoadingTime) : 0;
            }
            else if (assignment.geoTripStatus === 'Unloading') {
                assignment.geoDuration.target = assignment.latestGeoTrip.job &&
                    assignment.latestGeoTrip.job.endLocation &&
                    assignment.latestGeoTrip.job.endLocation.averageUnloadingTime ?
                    Number(assignment.latestGeoTrip.job.endLocation.averageUnloadingTime) : 0;
            }
            rowData.push(assignment, { tripRow: true, assignment: assignment });
        });
        this.rows = rowData;
        this.assignments = assignments;
        if (this.driverIdToOpenAssignments) {
            var driverAssignment = assignments.find(function (a) { return a.id === _this.driverIdToOpenAssignments; });
            if (driverAssignment) {
                this.showNoAssignmentsAlert = false;
                this.openDriverAssignmentOnPageLoad(driverAssignment);
            }
            else {
                this.showNoAssignmentsAlert = true;
            }
            this.router.navigate([], {
                queryParams: {
                    'driver_assignments': null,
                },
                queryParamsHandling: 'merge'
            });
        }
        this.loading = false;
    };
    AllDriverAssignmentsComponent.prototype.getPendingCount = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        this.pendingCountReq = this.collaboratorService.getPending({ page_size: 1 }).subscribe(function (invitations) {
            _this.pendingJobsCount = _this.collaboratorService.count;
        }, function (err) {
            _this.errors = err;
        });
    };
    AllDriverAssignmentsComponent.prototype.changeSearch = function (term) {
        this.search = term;
        this.updateUrl({ search: term });
        this.searchChanged.next(term);
    };
    AllDriverAssignmentsComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        this.page = this.page > 1 && this.search ? 1 : this.page;
        params['page'] = this.page > 1 ? this.page : null;
        var url = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.queryParams, params)
        }).toString();
        this.location.go(url);
    };
    AllDriverAssignmentsComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.queryParams, { sortBy: this.sortBy, sortAsc: this.sortAsc })
        });
        this.loading = true;
        this.getDriverAssignments({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    AllDriverAssignmentsComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.loading = true;
        this.getDriverAssignments((_a = {}, _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy, _a));
    };
    AllDriverAssignmentsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            var isCrh = false;
            var startDate = new Date();
            startDate.setHours(0, 0, 0, 0);
            var endDate = lodash_1.clone(startDate);
            endDate.setHours(23, 59, 59, 999);
            if (this.assignmentDate) {
                startDate = new Date(this.assignmentDate);
                startDate.setHours(0, 0, 0, 0);
                endDate = lodash_1.clone(startDate);
                endDate.setHours(23, 59, 59, 999);
            }
            var baseDropdownConfig = {
                selectText: this.translateService.instant('Select Job'),
                loadingText: this.translateService.instant('Loading Jobs...'),
                noResultsText: this.translateService.instant('No Jobs'),
                service: assignment_filter_service_1.AssignmentFilterService,
                serviceFunction: 'listFilters',
                searchKey: 'filter_search',
                serviceFunctionScope: 'jobs',
                query: { active_range: startDate.toISOString() + ',' + endDate.toISOString() }
            };
            dialog.componentInstance.filters = [
                (isCrh ? {
                    type: 'dropdown', field: 'jobEvent',
                    label: this.translateService.instant('Job'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { serviceFunctionScope: 'jobevents' })
                } : {
                    type: 'dropdown', field: 'job',
                    label: this.translateService.instant('Job'),
                    dropdownConfig: baseDropdownConfig
                }), {
                    type: 'text', field: 'orderNumber',
                    label: this.translateService.instant('Order Number')
                }, {
                    type: 'dropdown', field: 'customer',
                    label: this.translateService.instant('Customer'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: this.translateService.instant('Select Customer'), loadingText: this.translateService.instant('Loading Customer...'), noResultsText: this.translateService.instant('No Customers'), serviceFunctionScope: 'customers' })
                }, {
                    type: 'dropdown', field: 'project',
                    label: this.translateService.instant('Project'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: this.translateService.instant('Select Project'), loadingText: this.translateService.instant('Loading Project...'), noResultsText: this.translateService.instant('No Projects'), serviceFunctionScope: 'projects' })
                }, {
                    type: 'dropdown', field: 'truckType',
                    label: this.translateService.instant('Truck Type'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: this.translateService.instant('Select Truck Type'), loadingText: this.translateService.instant('Loading Truck Type...'), noResultsText: this.translateService.instant('No Truck Types'), serviceFunctionScope: 'trucktypes' })
                }, {
                    type: 'dropdown', field: 'carrier',
                    label: this.translateService.instant('Carrier'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { selectText: this.translateService.instant('Select Carrier'), loadingText: this.translateService.instant('Loading Carrier...'), noResultsText: this.translateService.instant('No Carriers'), serviceFunctionScope: 'organization' })
                }, {
                    type: 'dropdown', field: 'startLocation',
                    label: this.translateService.instant('Start Location'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { service: location_service_1.LocationService, serviceFunctionScope: null, serviceFunction: 'list', searchKey: 'search', query: {}, selectText: this.translateService.instant('Select Location'), loadingText: this.translateService.instant('Loading Location...'), noResultsText: this.translateService.instant('No Locations') })
                }, {
                    type: 'dropdown', field: 'endLocation',
                    label: this.translateService.instant('End Location'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { service: location_service_1.LocationService, serviceFunctionScope: null, serviceFunction: 'list', searchKey: 'search', query: {}, selectText: this.translateService.instant('Select Location'), loadingText: this.translateService.instant('Loading Location...'), noResultsText: this.translateService.instant('No Locations') })
                }, {
                    type: 'dropdown', field: 'tags',
                    label: this.translateService.instant('Markets'),
                    dropdownConfig: __assign({}, baseDropdownConfig, { multiselect: true, service: tag_service_1.TagService, serviceFunctionScope: null, serviceFunction: 'list', searchKey: 'search', query: {}, selectText: this.translateService.instant('Select Markets'), loadingText: this.translateService.instant('Loading Markets...'), noResultsText: this.translateService.instant('No Markets') })
                }
            ];
            dialog.componentInstance.callback = function (res) {
                _this.savePreferences(res);
                _this.filterChanges(res);
            };
            dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
                acc[filter.key] = filter.value;
                return acc;
            }, {});
            this.filtersDialog = dialog.componentInstance;
        }
    };
    AllDriverAssignmentsComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            job: 'jobevent__job',
            orderNumber: 'order_number',
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            truckType: 'truck__truck_type',
            jobEvent: 'jobevent',
            carrier: 'driver__carrier__organization',
            startLocation: 'jobevent__job__start_location',
            endLocation: 'jobevent__job__end_location',
            tags: 'jobevent__job__tags'
        };
        var falseyFilters = [];
        if (filterRes) {
            this.filters = Object.keys(filterRes).map(function (key) {
                var query = {};
                var value = filterRes[key];
                var displayValue;
                if (typeof (value) === 'boolean') {
                    if (value) {
                        value = value.toString();
                        value = value.charAt(0).toUpperCase() + value.slice(1);
                        query[queryKeys[key]] = value;
                    }
                }
                else if (key === 'tags') {
                    if (value && value.length > 0) {
                        value = value.map(function (tag) { return tag.name; }).join(',');
                        query[queryKeys[key]] = value;
                    }
                }
                else {
                    query[queryKeys[key]] = filterRes[key] && filterRes[key].id || filterRes[key];
                }
                var displayKey = key;
                var hidden = false;
                if (key === 'defaultStatus') {
                    hidden = true;
                }
                var filter = {
                    key: displayKey,
                    value: displayValue || value,
                    query: query,
                    hidden: hidden
                };
                if (filter.value === 'False' || !filter.value) {
                    falseyFilters.push(filter);
                }
                return filter;
            });
            this.filters = lodash_1.difference(this.filters, falseyFilters);
            this.getDriverAssignments();
        }
    };
    AllDriverAssignmentsComponent.prototype.removeFilter = function (filter) {
        if (this.preference && this.preference.blob && this.preference.blob.filters && this.preference.blob.filters[filter.key]) {
            delete this.preference.blob.filters[filter.key];
            this.savePreferences(this.preference.blob.filters);
        }
        lodash_1.remove(this.filters, filter);
        this.getDriverAssignments();
    };
    AllDriverAssignmentsComponent.prototype.selector = function (event, assignment) {
        if (assignment === void 0) { assignment = null; }
        if (assignment) {
            if (event.checked) {
                if (this.allSelected) {
                    lodash_1.pull(this.excludeAssignments, assignment.id);
                    this.selectedCount = (this.count - this.excludeAssignments.length);
                }
                else {
                    this.selectedAssignments.push(assignment.id);
                    this.selectedCount = this.selectedAssignments.length;
                }
            }
            else {
                lodash_1.pull(this.selectedAssignments, assignment.id);
                if (this.allSelected) {
                    this.excludeAssignments.push(assignment.id);
                    this.selectedCount = (this.count - this.excludeAssignments.length);
                }
                else {
                    this.selectedCount = this.selectedAssignments.length;
                }
            }
            assignment.selected = event.checked;
        }
        else {
            if (!event.checked) {
                this.allSelected = false;
                this.selectedAssignments = [];
                this.excludeAssignments = [];
                var values = [];
                if (this.rows) {
                    values = this.rows;
                }
                values.forEach(function (_assignment) {
                    if (_assignment.hasOwnProperty('selected')) {
                        _assignment['selected'] = false;
                    }
                });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                var values = [];
                if (this.rows) {
                    values = this.rows;
                }
                values.forEach(function (_assignment) {
                    if (_assignment.hasOwnProperty('selected')) {
                        _assignment['selected'] = true;
                    }
                });
                this.selectedCount = (this.count - this.excludeAssignments.length);
            }
            this.selectedAssignments = [];
            this.excludeAssignments = [];
        }
    };
    AllDriverAssignmentsComponent.prototype.setSelectedAction = function (option) {
        switch (option.name) {
            case 'Export':
                this.createExport(this.selectedAssignments, this.excludeAssignments);
                break;
            case 'Operator\'s Export':
                this.createOperatorsExport(this.selectedAssignments, this.excludeAssignments);
                break;
        }
    };
    AllDriverAssignmentsComponent.prototype.createExport = function (selectedAssignments, excludeAssignments) {
        var _this = this;
        if (selectedAssignments === void 0) { selectedAssignments = null; }
        if (excludeAssignments === void 0) { excludeAssignments = null; }
        var scope = {
            assignments: selectedAssignments,
            exclude_assignments: excludeAssignments,
        };
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        delete filters['undefined'];
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        if (this.assignmentDate) {
            startDate = new Date(this.assignmentDate);
            startDate.setHours(0, 0, 0, 0);
            endDate = lodash_1.clone(startDate);
            endDate.setHours(23, 59, 59, 999);
        }
        filters = __assign({ search: this.search, 'jobevent__shift1_start__gte': startDate && startDate.toISOString(), 'jobevent__shift1_start__lte': endDate && endDate.toISOString(), 'exclude_assignments': excludeAssignments, 'assignments': selectedAssignments }, filters);
        this.assignmentService.export(scope, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    scope: scope,
                    params: params,
                    service: _this.assignmentService,
                    buttonText: _this.translateService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    AllDriverAssignmentsComponent.prototype.createOperatorsExport = function (selectedAssignments, excludeAssignments) {
        var _this = this;
        if (selectedAssignments === void 0) { selectedAssignments = null; }
        if (excludeAssignments === void 0) { excludeAssignments = null; }
        var scope = {
            assignments: selectedAssignments,
            exclude_assignments: excludeAssignments,
            endpoint: 'operators'
        };
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        delete filters['undefined'];
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        if (this.assignmentDate) {
            startDate = new Date(this.assignmentDate);
            startDate.setHours(0, 0, 0, 0);
            endDate = lodash_1.clone(startDate);
            endDate.setHours(23, 59, 59, 999);
        }
        filters = __assign({ 'active_range': (startDate && startDate.toISOString()) + "," + (endDate && endDate.toISOString()), 'exclude_assignments': excludeAssignments, 'assignments': selectedAssignments, 'ordering': 'jobevent__job__start__location__name,jobevent__job__order_number' }, filters);
        this.assignmentService.export(scope, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    endpoint: 'operators'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    scope: scope,
                    params: params,
                    service: _this.assignmentService,
                    buttonText: _this.translateService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    AllDriverAssignmentsComponent.prototype.setDefaultDate = function () {
        var d = new Date();
        if (this.date) {
            d = this.date.toDate();
        }
        this.assignmentDate = d;
        this.assignmentISODate = d;
        this.assignmentISODate.setHours(0, 0, 0, 0);
    };
    AllDriverAssignmentsComponent.prototype.onDateChanged = function (dates) {
        if (dates && dates.length) {
            this.assignmentDate = dates[0];
            this.assignmentISODate = dates[0];
            var date = moment(dates[0]).format('YYYYMMDD');
            this.queryParams = Object.assign({}, this.queryParams, { date: date, page: 1 });
            this.expandedAssignments = [];
            this.selectedAssignments = [];
            this.excludeAssignments = [];
            this.allSelected = false;
            this.page = 1;
            var values = [];
            if (this.rows) {
                values = this.rows;
            }
            values.forEach(function (_assignment) {
                if (_assignment.hasOwnProperty('selected')) {
                    _assignment['selected'] = false;
                }
            });
            this.assignmentDateChanged.next(dates[0]);
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: this.queryParams
            });
        }
    };
    AllDriverAssignmentsComponent.prototype.showInvoiceTotalChange = function () {
        if (this.showInvoiceTotal) {
            this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'invoice-total', 'expense-total');
        }
        else {
            this.displayedColumns = this.displayedColumns.filter(function (col) { return col !== 'invoice-total' && col !== 'expense-total'; });
        }
        this.queryParams = Object.assign(lodash_1.clone(this.queryParams), { 'showInvoiceTotal': this.showInvoiceTotal ? 'true' : 'false', });
        var url = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: this.queryParams
        }).toString();
        this.location.go(url);
        this.getDriverAssignments();
    };
    AllDriverAssignmentsComponent.prototype.expandAssignment = function (assignment) {
        if (lodash_1.find(this.expandedAssignments, { id: assignment.id })) {
            lodash_1.remove(this.expandedAssignments, { id: assignment.id });
        }
        else {
            this.expandedAssignments.push(assignment);
        }
    };
    AllDriverAssignmentsComponent.prototype.expandedAssignment = function (assignment) {
        if (lodash_1.find(this.expandedAssignments, { id: assignment.id })) {
            return true;
        }
        return false;
    };
    AllDriverAssignmentsComponent.prototype.openNewAssignment = function (assignment) {
        var _this = this;
        if (assignment === void 0) { assignment = null; }
        var driver, truck;
        if (assignment) {
            driver = new driver_serializer_1.DriverSerializer().fromJson({ id: assignment.id, customName: assignment.name });
            truck = assignment.latestTruck;
        }
        var dialog = this.dialog.open(quick_dispatch_dialog_component_1.QuickDispatchDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.selectedDates = [new Date(this.assignmentDate)];
        dialog.componentInstance.selectedDriver = driver;
        dialog.componentInstance.selectedTruck = truck;
        dialog.componentInstance.callback = function () { return _this.getDriverAssignments(); };
        this.dispatchDialog = dialog.componentInstance;
    };
    AllDriverAssignmentsComponent.prototype.onCreateNewAssignment = function (driver) {
        var _this = this;
        var dialog = this.dialog.open(quick_dispatch_dialog_component_1.QuickDispatchDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.selectedDates = [new Date(this.assignmentDate)];
        dialog.componentInstance.selectedDriver = driver;
        // dialog.componentInstance.selectedTruck = truck;
        dialog.componentInstance.callback = function () { return _this.getDriverAssignments(); };
        this.dispatchDialog = dialog.componentInstance;
    };
    AllDriverAssignmentsComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.filters = [];
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    AllDriverAssignmentsComponent.prototype.totalTripTimeDisplay = function (trips, jobName) {
        var totalTime = 0;
        trips.forEach(function (trip) {
            if (trip.job && trip.job.name === jobName) {
                totalTime += trip.completedTripDuration;
            }
        });
        return moment.duration(totalTime, 'milliseconds').format('D[ days], H[ hrs], m[ mins] total');
    };
    AllDriverAssignmentsComponent.prototype.totalWeightDisplay = function (trips, jobName) {
        if (!trips || !trips.length) {
            return '';
        }
        var totalWeight = 0;
        var weightUnit = trips[0].weightUnit;
        trips.forEach(function (trip) {
            if (!jobName || (trip.job && trip.job.name === jobName)) {
                totalWeight += parseFloat(trip.weight);
            }
        });
        var plural = true;
        if (totalWeight && totalWeight === 1.0) {
            plural = false;
        }
        switch (weightUnit) {
            case 'cuyds':
                weightUnit = plural ? 'cu. yds.' : 'cu. yd.';
                break;
            default:
                weightUnit = plural ? weightUnit + 's' : weightUnit;
                break;
        }
        return common_1.formatNumber(totalWeight, 'en-US', '1.1-4') + ' ' + weightUnit;
    };
    AllDriverAssignmentsComponent.prototype.pageChange = function (event) {
        var _a;
        this.page = event;
        this.queryParams = Object.assign(lodash_1.clone(this.queryParams), { page: this.page > 1 ? this.page : null, view: this.view });
        var url = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: this.queryParams
        }).toString();
        this.location.go(url);
        this.getDriverAssignments((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    AllDriverAssignmentsComponent.prototype.multiselectDisabled = function () {
        return !this.rows || !this.count || this.count === 0 ||
            (!this.allSelected && !this.selectedAssignments.length) ||
            this.count === this.excludeAssignments.length;
    };
    AllDriverAssignmentsComponent.prototype.showDriverAssignments = function (driverData) {
        this.driverAssignmentsList.setOpen(driverData);
    };
    AllDriverAssignmentsComponent.prototype.focusDriver = function (driver) {
        if (driver && this.driverMap) {
            var drivers = this.rows;
            drivers.forEach(function (_driver) {
                if (_driver.hasOwnProperty('highlighted') && _driver.id !== driver.id) {
                    _driver['highlighted'] = false;
                }
                else if (_driver.hasOwnProperty('highlighted') && _driver.id === driver.id) {
                    _driver['highlighted'] = true;
                }
            });
            this.driverMap.focusDriver(driver.id);
        }
    };
    AllDriverAssignmentsComponent.prototype.updatedMarkets = function () {
        this.getDriverAssignments();
    };
    /**
     * @param  {} driver
     * @returns void
     * This function is triggered when driver icon is clicked on the map,
     * the driver list will be scrolled to the clicked driver.
     */
    AllDriverAssignmentsComponent.prototype.scrollToSelectedDriver = function (driver) {
        var drivers = this.rows;
        if (lodash_1.find(drivers, { id: driver.id })) {
            this.driverList.nativeElement.scrollBy(0, this.driverList.nativeElement.scrollHeight);
        }
    };
    // driver timeline methods
    AllDriverAssignmentsComponent.prototype.setupTimelineData = function (driverAssignments) {
        var _this = this;
        var startTimes = [], endTimes = [];
        this.timelineData = [];
        driverAssignments.forEach(function (driverAssignment) {
            var dataObj = {
                referenceId: driverAssignment.id,
                trips: [],
                predictedTrips: [],
                pavers: []
            };
            driverAssignment.activeTrips.forEach(function (trip) {
                if (trip.startTimeTimestamp) {
                    startTimes.push(trip.startTimeTimestamp);
                }
                if (trip.endTimeTimestamp) {
                    endTimes.push(trip.endTimeTimestamp);
                }
                dataObj.trips.push(trip);
            });
            driverAssignment.trips.forEach(function (trip) {
                if (trip.endTimeTimestamp && Date.parse(trip.endTimeTimestamp) < Date.parse(trip.startTimeTimestamp)) {
                    if (trip.startTimeTimestamp) {
                        startTimes.push(trip.startTimeTimestamp);
                    }
                    if (trip.startTimeTimestamp) {
                        endTimes.push(trip.startTimeTimestamp);
                    }
                    trip.startTimeTimestamp = trip.startTimeTimestamp;
                    trip.endTimeTimestamp = trip.startTimeTimestamp;
                }
                else {
                    if (trip.startTimeTimestamp) {
                        startTimes.push(trip.startTimeTimestamp);
                    }
                    if (trip.endTimeTimestamp) {
                        endTimes.push(trip.endTimeTimestamp);
                    }
                }
                if (trip.connexPaverStartUnloading) {
                    dataObj.pavers.push({
                        startDatetime: trip.connexPaverStartUnloading,
                        endDatetime: trip.connexPaverEndUnloading
                    });
                }
                dataObj.trips.push(trip);
            });
            driverAssignment.activeGeoTrips.forEach(function (geoTrip) {
                if (geoTrip.startTimeTimestamp) {
                    startTimes.push(geoTrip.startTimeTimestamp);
                }
                if (geoTrip.endTimeTimestamp) {
                    endTimes.push(geoTrip.endTimeTimestamp);
                }
                dataObj.predictedTrips.push(geoTrip);
            });
            driverAssignment.geoTrips.forEach(function (geoTrip) {
                if (geoTrip.startTimeTimestamp) {
                    startTimes.push(geoTrip.startTimeTimestamp);
                }
                if (geoTrip.endTimeTimestamp) {
                    endTimes.push(geoTrip.endTimeTimestamp);
                }
                dataObj.predictedTrips.push(geoTrip);
            });
            _this.timelineData.push(dataObj);
        });
        this.timelineRange = this.setTimelineRange(startTimes, endTimes);
    };
    AllDriverAssignmentsComponent.prototype.setTimelineRange = function (startTimes, endTimes) {
        var startMoments = startTimes.map(function (time) { return (moment(time)); });
        var endMoments = endTimes.map(function (time) { return (moment(time)); });
        endMoments.push(moment(this.date).endOf('day').subtract(2, 'hours'));
        return {
            startDatetime: moment.min(startMoments).startOf('hour').toISOString(),
            endDatetime: moment.max(endMoments).endOf('hour').toISOString()
        };
    };
    AllDriverAssignmentsComponent.prototype.getPreferences = function () {
        var _this = this;
        if (this.preferencesReq && typeof this.preferencesReq.unsubscribe === 'function') {
            try {
                this.preferencesReq.unsubscribe();
            }
            catch (e) { }
        }
        var currentUser = this.authenticationService.user();
        if (this.preference && this.preference.id) {
            this.preferencesReq = this.preferenceService.get(this.preference.id)
                .subscribe(function (preference) {
                _this.preference = preference;
                _this.switchView(_this.preference.blob.view, _this.preference.blob.type);
                _this.filterChanges(_this.preference.blob.filters);
            });
        }
        else {
            this.preferenceService.list({
                name: 'AllDriverAssignmentsComponent-LastView',
                type: 'user',
                profile: currentUser.id,
                page_size: 1
            }).subscribe(function (preferences) {
                if (preferences && preferences.length) {
                    _this.preference = preferences[0];
                    _this.switchView(_this.preference.blob.view, _this.preference.blob.type);
                    _this.filterChanges(_this.preference.blob.filters);
                }
                else {
                    _this.switchView(_this.view, _this.type);
                }
            });
        }
    };
    AllDriverAssignmentsComponent.prototype.savePreferences = function (filters) {
        var _this = this;
        var currentUser = this.authenticationService.user();
        this.preference = __assign({}, this.preference, { name: 'AllDriverAssignmentsComponent-LastView', type: 'user', profile: currentUser.id, blob: { view: this.view, type: this.type, filters: filters } });
        this.preferenceService.save(this.preference)
            .subscribe(function (preference) { return _this.preference = preference; });
    };
    /**
     * Opens assignments drawer on page load if driver_assignments
     * query param is set with driverId
     *
     * @param driverAssignment which driver assignment to open
     */
    AllDriverAssignmentsComponent.prototype.openDriverAssignmentOnPageLoad = function (driverAssignment) {
        this.showDriverAssignments(driverAssignment);
        this.router.navigate([], {
            queryParams: {
                'driver_assignments': null
            },
            queryParamsHandling: 'merge'
        });
        this.driverIdToOpenAssignments = null;
    };
    AllDriverAssignmentsComponent.prototype.openContextMenu = function (event, driverAssignment) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverAssignment.id,
            name: driverAssignment.name
        });
    };
    AllDriverAssignmentsComponent.prototype.onContextMenuOpenAssignment = function (selectionEvent) {
        var driverAssignment = this.assignments.find(function (a) { return a.id === selectionEvent.driverId; });
        if (driverAssignment) {
            this.showDriverAssignments(driverAssignment);
        }
    };
    return AllDriverAssignmentsComponent;
}());
exports.AllDriverAssignmentsComponent = AllDriverAssignmentsComponent;
