import { WorkOrderTemplate } from './work-order-template';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class WorkOrderTemplateSerializer {
  fromJson(json: any): WorkOrderTemplate {
    json = camelcaseKeysDeep(json);
    const template = new WorkOrderTemplate();
    if (!json) { return template; }

    template.id = json.id;
    template.createdAt = json.createdAt;
    template.name = json.name;
    template.html = json.html;
    template.pdf = json.pdf;

    return template;
  }

  toJson(template: WorkOrderTemplate): any {
    let json = {
      id: template.id,
      createdAt: template.createdAt,
      name: template.name
    };

    return decamelizeKeysDeep(json);
  }
}
