"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var condensed_trip_1 = require("../trips/condensed-trip");
var resizer_1 = require("../images/resizer");
var lodash_1 = require("lodash");
var moment = require("moment");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var CondensedPunchCard = /** @class */ (function () {
    function CondensedPunchCard(punchCardInfo) {
        this.selected = false;
        this.loading = false;
        this.expenseSent = false;
        this.paystubSent = false;
        this.invoiceSent = false;
        punchCardInfo = camelcaseKeysDeep(punchCardInfo);
        this.id = punchCardInfo.id;
        this.customer = punchCardInfo.customer;
        this.customerId = punchCardInfo.customerId;
        this.driver = punchCardInfo.driver;
        this.driverId = punchCardInfo.driverId;
        this.startTimeTimestamp = punchCardInfo.startTime;
        this.endTimeTimestamp = punchCardInfo.endTime;
        this.haulRate = punchCardInfo.haulRate;
        this.haulType = punchCardInfo.haulType;
        this.haulWeightUnit = punchCardInfo.haulWeightUnit;
        this.invoiceRate = punchCardInfo.invoiceRate;
        this.invoiceTotal = punchCardInfo.invoiceTotal;
        this.invoiceType = punchCardInfo.invoiceType;
        this.invoiceWeightUnit = punchCardInfo.invoiceWeightUnit;
        this.job = punchCardInfo.job;
        this.jobDisplayName = punchCardInfo.jobDisplayName;
        this.jobId = punchCardInfo.jobId;
        this.project = punchCardInfo.project;
        this.projectId = punchCardInfo.projectId;
        this.payableTo = punchCardInfo.payableTo;
        this.payableToId = punchCardInfo.payableToId;
        this.ticketImage = punchCardInfo.ticketImage;
        this.ticketImageKey = punchCardInfo.ticketImageKey;
        this.ticketNumber = punchCardInfo.ticketNumber;
        if (punchCardInfo.trips && punchCardInfo.trips.length) {
            this.trips = punchCardInfo.trips.map(function (trip) {
                return new condensed_trip_1.CondensedTrip(trip);
            });
        }
        this.truck = punchCardInfo.truck;
        this.truckId = punchCardInfo.truckId;
        this.truckType = punchCardInfo.truckType;
        this.haulTotal = punchCardInfo.haulTotal;
        this.void = punchCardInfo.void;
        this.edited = punchCardInfo.edited;
        this.expenseId = punchCardInfo.expenseId;
        this.expenseNumber = punchCardInfo.expenseNumber;
        this.expenseSent = punchCardInfo.expenseSent;
        this.paystubId = punchCardInfo.paystubId;
        this.paystubNumber = punchCardInfo.paystubNumber;
        this.paystubSent = punchCardInfo.paystubSent;
        this.invoiceId = punchCardInfo.invoiceId;
        this.invoiceNumber = punchCardInfo.invoiceNumber;
        this.invoiceSent = punchCardInfo.invoiceSent;
        this.invoiceQuantity = punchCardInfo.invoiceQuantity;
        this.jobDate = punchCardInfo.jobDate;
        this.kind = punchCardInfo.kind || 'invoice';
    }
    CondensedPunchCard.prototype.save = function () {
        // Save to API
        console.log(this.id);
    };
    CondensedPunchCard.prototype.formatDates = function () {
        var duration;
        if (this._endTimeTimestamp) {
            duration = moment.duration(moment(this._endTimeTimestamp).diff(this._startTimeTimestamp));
        }
        else {
            duration = moment.duration(moment().diff(this.startTimeTimestamp));
        }
        this._duration = duration.format('D[ days], H[ hrs], m[ mins]');
        this._startDate = new Date(this._startTimeTimestamp);
        if (!this._startTime) {
            this._startTime = moment(this._startDate).format('h:mm a');
        }
        this._endDate = new Date(this._endTimeTimestamp);
        if (!this._endTime) {
            this._endTime = moment(this._endDate).format('h:mm a');
        }
    };
    Object.defineProperty(CondensedPunchCard.prototype, "startTimeTimestamp", {
        get: function () {
            return this._startTimeTimestamp;
        },
        set: function (value) {
            this._startTimeTimestamp = value;
            this.formatDates();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "endTimeTimestamp", {
        get: function () {
            return this._endTimeTimestamp;
        },
        set: function (value) {
            this._endTimeTimestamp = value;
            this.formatDates();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "duration", {
        get: function () {
            return this._duration;
        },
        set: function (value) {
            this._duration = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "startTime", {
        get: function () {
            return this._startTime;
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.startTimeTimestamp = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "endTime", {
        get: function () {
            if (this._endTime === 'Invalid date') {
                return moment(this._endDate).format('h:mm a');
            }
            return this._endTime;
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.endTimeTimestamp = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "startDate", {
        get: function () {
            return this._startDate;
        },
        set: function (value) {
            var date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.startTime);
            this.startTimeTimestamp = date.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "endDate", {
        get: function () {
            return this._endDate;
        },
        set: function (value) {
            var date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.endTime);
            this.endTimeTimestamp = date.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "schedule", {
        get: function () {
            var values = [];
            if (this.startTime && this.startTime.length) {
                values.push(this.startTime);
            }
            if (this.endTimeTimestamp && this.endTimeTimestamp.length) {
                values.push(this.endTime);
            }
            else {
                values.push('OPEN');
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "truckName", {
        get: function () {
            var values = [];
            if (this.truck && this.truck.length) {
                values.push(this.truck);
            }
            if (this.truckType && this.truckType.length) {
                values.push(this.truckType);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "invoiceRateUnit", {
        get: function () {
            if (this.invoiceType === 'weight') {
                switch (this.invoiceWeightUnit) {
                    case 'cuyds':
                        return 'cu. yd.';
                    default:
                        return this.invoiceWeightUnit;
                }
            }
            else {
                return this.invoiceType;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "haulRateUnit", {
        get: function () {
            if (this.haulType === 'weight') {
                switch (this.haulWeightUnit) {
                    case 'cuyds':
                        return 'cu. yd.';
                    default:
                        return this.haulWeightUnit;
                }
            }
            else {
                return this.haulType;
            }
        },
        enumerable: true,
        configurable: true
    });
    CondensedPunchCard.prototype.pluralizedInvoiceRateUnit = function (plural) {
        if (plural === void 0) { plural = true; }
        if (this.invoiceType === 'weight') {
            switch (this.invoiceWeightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
            }
        }
        else {
            return this.invoiceType;
        }
    };
    CondensedPunchCard.prototype.pluralizedHaulRateUnit = function (plural) {
        if (plural === void 0) { plural = true; }
        if (this.haulType === 'weight') {
            switch (this.haulWeightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
            }
        }
        else {
            return this.haulType;
        }
    };
    CondensedPunchCard.prototype.pluralizedInvoiceWeightUnitDisplay = function (plural) {
        if (plural === void 0) { plural = true; }
        switch (this.invoiceWeightUnit) {
            case 'cuyds':
                return plural ? 'cu. yds.' : 'cu. yd.';
            default:
                return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
        }
    };
    CondensedPunchCard.prototype.pluralizedHaulWeightUnitDisplay = function (plural) {
        if (plural === void 0) { plural = true; }
        switch (this.haulWeightUnit) {
            case 'cuyds':
                return plural ? 'cu. yds.' : 'cu. yd.';
            default:
                return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
        }
    };
    Object.defineProperty(CondensedPunchCard.prototype, "invoiceWeightUnitDisplay", {
        get: function () {
            switch (this.invoiceWeightUnit) {
                case 'cuyds':
                    return 'cu. yd.';
                default:
                    return this.invoiceWeightUnit;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "haulWeightUnitDisplay", {
        get: function () {
            switch (this.haulWeightUnit) {
                case 'cuyds':
                    return 'cu. yd.';
                default:
                    return this.haulWeightUnit;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "hasTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImage.length) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "inlineTicket", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.ticketImageKey, 28, 28);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "administrativeStatus", {
        get: function () {
            if (this.void) {
                return 'void';
            }
            else if (this.edited) {
                return 'edited';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus && this.administrativeStatus.length) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "expenseTitle", {
        get: function () {
            var value = '';
            if (this.expenseSent) {
                value = 'Paid #' + this.expenseNumber;
            }
            else if (this.expenseNumber) {
                value = 'Draft #' + this.expenseNumber;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "paystubTitle", {
        get: function () {
            var value = '';
            if (this.paystubSent) {
                value = 'Paid #' + this.paystubNumber;
            }
            else if (this.paystubNumber) {
                value = 'Draft #' + this.paystubNumber;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "invoiceTitle", {
        get: function () {
            var value = '';
            if (this.invoiceSent) {
                value = 'Sent #' + this.invoiceNumber;
            }
            else if (this.invoiceNumber) {
                value = 'Draft #' + this.invoiceNumber;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedPunchCard.prototype, "invoiceQuantityWithLabel", {
        get: function () {
            var value = this.invoiceQuantity;
            var unit = this.haulRateUnit;
            if (this.kind === 'invoice') {
                unit = this.invoiceRateUnit;
            }
            if (parseFloat(this.invoiceQuantity) === 1) {
                value = value + ' ' + unit;
            }
            else {
                value = value + ' ' + unit + 's';
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    CondensedPunchCard.prototype.url = function (action) {
        return '/punchcards/' + this.id + '/' + action;
    };
    CondensedPunchCard.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.void) {
            var _option = lodash_1.find(options, { action: 'unvoid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return CondensedPunchCard;
}());
exports.CondensedPunchCard = CondensedPunchCard;
