"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var FilterOption = /** @class */ (function () {
    function FilterOption(data) {
        this.multiple = false;
        this.values = null;
        this.displayValues = null;
        this.loading = false;
        this.hidden = false;
        this.default = false;
        this.withNullOption = false;
        this.onlyName = false;
        this.customField = false;
        this.searchable = false;
        this.searching = false;
        this.searchChange = new core_1.EventEmitter();
        this.removeFromFiltersQuery = false;
        var rawData = data;
        data = camelcaseKeysDeep(data);
        this.key = data.key;
        this.title = data.title;
        this.options = data.options;
        this.count = data.count || 0;
        this.perPage = data.perPage || 6;
        this.filterType = data.filterType; // select, toggle, date
        this.ordering = data.ordering || 'name';
        this.multiple = data.multiple;
        this.values = data.values;
        this.idProperty = data.idProperty;
        this.displayValues = data.displayValues ? data.displayValues : data.values;
        this.service = data.service;
        this.slug = data.slug;
        this.nextUri = data.nextUri;
        this.hidden = data.hidden;
        this.default = data.default;
        this.withNullOption = data.withNullOption;
        this.onlyName = data.onlyName;
        this.customField = data.customField;
        this.searchable = data.searchable;
        this.searching = data.searching;
        this.search = data.search;
        this.searchKey = this.key + 'Search';
        this.query = rawData.query;
        this.removeFromFiltersQuery = data.removeFromFiltersQuery;
    }
    return FilterOption;
}());
exports.FilterOption = FilterOption;
