"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var shared_1 = require("../../../shared");
var preference_service_1 = require("../../../preferences/preference.service");
var filter_option_1 = require("../../../shared/filters-panel/filter-option");
var DispatchScheduleTruckGridComponent = /** @class */ (function () {
    function DispatchScheduleTruckGridComponent(authenticationService, preferenceService) {
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.loadingProgress = 0;
        this.displayKeys = ['truckDriverDisplayName', 'name', 'truckName'];
        this.truckCount = { available: 0, assigned: 0 };
        this.groupByOptions = ['Carrier', 'Truck Type', 'Assigned', 'Market', 'Duty Status', 'Condition', 'Yard Location'];
        this.activeGroupBy = 'Carrier';
        this.gridPreferenceKey = 'table-preferences[DispatchScheduleTableComponent-Trucks]';
        this.filters = [];
        this.allSubscriptionsToUnsubscribe = [];
        this.selectCarrier = new core_1.EventEmitter();
        this.searchCarriers = new core_1.EventEmitter();
        this.dropdownNextPage = new core_1.EventEmitter();
        this.truckTypeOptions = [
            { id: '0', name: 'All Truck Types' }
        ];
        this.selectedDriversValue = [];
        this.selectedDriversChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DispatchScheduleTruckGridComponent.prototype, "selectedDrivers", {
        get: function () { return this.selectedDriversValue; },
        set: function (data) {
            this.selectedDriversValue = data;
            this.selectedDriversChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleTruckGridComponent.prototype.ngOnInit = function () {
        this.getPreferences();
    };
    DispatchScheduleTruckGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes.drivers && changes.drivers.currentValue) {
            this.setupItemList(changes.drivers.currentValue, this.activeGroupBy);
        }
    };
    DispatchScheduleTruckGridComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            if (sub && typeof sub.unsubscribe === 'function') {
                sub.unsubscribe();
            }
        });
    };
    /**
     * Uses a groupBy to generate a list of groups for the input driver list
     *
     * @param {DispatchDriver[]} drivers The input driver list
     * @param {'Carrier' | 'Truck Type' | 'Assigned' | 'Market'} groupBy The option to group the list of drivers by
     */
    DispatchScheduleTruckGridComponent.prototype.setupItemList = function (drivers, groupBy) {
        var _this = this;
        this.truckCount = {
            available: drivers.length,
            assigned: drivers.filter(function (driver) { return (driver.assignments.length > 0); }).length
        };
        this.activeGroupBy = groupBy;
        switch (groupBy) {
            case 'Carrier':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    id: d.carrier.id,
                    name: d.carrier.name,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (driver.carrier.id === group.id); })
                })); });
                break;
            case 'Market':
                var marketGroups_1 = [];
                drivers.forEach(function (d) {
                    if (d.tags && d.tags.length) {
                        d.tags.forEach(function (tag) {
                            marketGroups_1.push({
                                id: tag.id,
                                name: tag.name,
                                groupBy: groupBy,
                                items: []
                            });
                        });
                    }
                    else {
                        marketGroups_1.push({
                            id: '',
                            groupBy: groupBy,
                            items: []
                        });
                    }
                });
                this.truckList = marketGroups_1.filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.id === '' ? (!driver.tags || driver.tags.length === 0) :
                        driver.tags.map(function (t) { return (t.id); }).join(' ').includes(group.id)); })
                })); });
                break;
            case 'Truck Type':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.truck && d.truck.truckType && d.truck.truckType.name,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return ((driver.truck && driver.truck.truckType && driver.truck.truckType.name) === group.name); })
                })); });
                break;
            case 'Assigned':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.assignments.length > 0 ? 'Assigned' : 'Unassigned',
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.name === 'Assigned' ? driver.assignments.length > 0 : driver.assignments.length === 0); })
                })); });
                break;
            case 'Duty Status':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.displayDutyStatus,
                    id: d.dutyStatus,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return driver.dutyStatus === group.id; })
                })); });
                break;
            case 'Condition':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.truck && d.truck.displayServiceStatus,
                    id: d.truck && d.truck.serviceStatus,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.id ? driver.truck && driver.truck.serviceStatus === group.id : !driver.truck); })
                }); });
                break;
            case 'Yard Location':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.truck && d.truck.yardLocation && d.truck.yardLocation.name,
                    id: d.truck && d.truck.yardLocation && d.truck.yardLocation.id || null,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return Object.assign(group, {
                    items: drivers.filter(function (driver) {
                        if (!group.id && !driver.truck) {
                            return !driver.truck;
                        }
                        return driver.truck && driver.truck.yardLocation.id === group.id;
                    })
                }); });
                break;
        }
        this.truckList = this.truckList.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
        this.filteredTruckList = lodash_1.cloneDeep(this.truckList);
        if (this.truckTypeOptions.length === 1) {
            this.truckList.forEach(function (group) {
                group.items.forEach(function (d) {
                    if (d.truck && d.truck.truckType && _this.truckTypeOptions.findIndex(function (t) { return (t.name === d.truck.truckType.name); }) === -1) {
                        _this.truckTypeOptions.push(d.truck.truckType);
                    }
                });
            });
        }
        if (this.selectedTruckType) {
            this.selectTruckType(this.selectedTruckType);
        }
    };
    DispatchScheduleTruckGridComponent.prototype.selectGroupBy = function (groupBy) {
        this.setupItemList(this.drivers, groupBy);
        this.setAppliedFilters('groupBy', groupBy);
    };
    DispatchScheduleTruckGridComponent.prototype.selectsCarrier = function (carrier) {
        this.selectCarrier.emit(carrier);
        this.setAppliedFilters('carrier', carrier);
    };
    /**
     * Uses a selected truck type to filter down the current displayed truck list
     *
     * @param {TruckType} type The selected trucktype
     */
    DispatchScheduleTruckGridComponent.prototype.selectTruckType = function (type) {
        var _this = this;
        this.selectedTruckType = type;
        if (this.selectedTruckType.id === '0') {
            this.filteredTruckList = lodash_1.cloneDeep(this.truckList);
        }
        else {
            this.filteredTruckList = lodash_1.cloneDeep(this.truckList).map(function (group) {
                group.items = group.items.filter(function (d) { return (d.truck && d.truck.truckType && d.truck.truckType.name === _this.selectedTruckType.name); });
                return group;
            });
        }
        this.setAppliedFilters('truckType', type);
    };
    /**
     * Creates a list of class names to append to each of the item elements based on specified driver states
     *
     * @param {DispatchDriver} driver The input driver
     * @return {string} The list of class names combined in a single string
     */
    DispatchScheduleTruckGridComponent.prototype.generateItemClassNames = function (driver) {
        var classNames = '';
        if (driver.dutyStatus === 'off-duty' ||
            (driver.truck && driver.truck.serviceStatus === 'out-of-service')) {
            classNames += 'red ';
        }
        else if (driver.assignments.length) {
            classNames += 'blue ';
        }
        return classNames;
    };
    /**
     * Save the new filter value
     * @param key - filter name keyed
     * @param value - value of the filter
     */
    DispatchScheduleTruckGridComponent.prototype.setAppliedFilters = function (key, value) {
        var filterIdx = this.filters.findIndex(function (f) { return f.key === key; });
        if (value) {
            if (filterIdx < 0) {
                var newFilter = new filter_option_1.FilterOption({
                    key: key,
                    values: value
                });
                this.filters.push(newFilter);
            }
            else {
                this.filters.forEach(function (filter) {
                    if (filter.key === key) {
                        filter.values = value;
                        filter.displayValues = value;
                    }
                });
            }
        }
        else {
            var filterIdx_1 = this.filters.findIndex(function (f) { return f.key === key; });
            if (filterIdx_1 > -1) {
                this.filters.splice(filterIdx_1, 1);
            }
        }
        this.savePreferences(this.filters);
    };
    DispatchScheduleTruckGridComponent.prototype.getPreferences = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        var listReq = this.preferenceService
            .list({
            name: this.gridPreferenceKey,
            type: 'user',
            profile: currentUser.id,
        })
            .subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
                _this.parsePreferences();
            }
        });
        this.allSubscriptionsToUnsubscribe.push(listReq);
    };
    DispatchScheduleTruckGridComponent.prototype.savePreferences = function (filters) {
        var _this = this;
        if (!filters || !filters.length) {
            return;
        }
        var currentUser = this.authenticationService.user();
        this.preference = __assign({}, this.preference, { name: this.gridPreferenceKey, type: 'user', profile: currentUser.id, blob: {
                filters: filters,
            } });
        var saveReq = this.preferenceService.save(this.preference).subscribe(function (preference) {
            _this.preference = preference;
        });
        this.allSubscriptionsToUnsubscribe.push(saveReq);
    };
    DispatchScheduleTruckGridComponent.prototype.parsePreferences = function () {
        var _this = this;
        if (!this.preference || !this.preference.blob) {
            return;
        }
        var filters = this.preference.blob['filters'] || [];
        if (filters && filters.length) {
            filters = filters.filter(function (field) { return !field['default']; });
            filters.forEach(function (f) {
                var filterIndex = lodash_1.findIndex(_this.filters, {
                    key: f.key,
                    default: true,
                });
                if (filterIndex !== -1) {
                    _this.filters[filterIndex] = f;
                }
            });
            this.filters = lodash_1.uniqBy(this.filters.concat(filters), 'key');
        }
        var carrier = this.getFilterAppliedByKey('carrier');
        var groupBy = this.getFilterAppliedByKey('groupBy');
        var truckType = this.getFilterAppliedByKey('truckType');
        if (carrier) {
            this.selectsCarrier(carrier);
        }
        if (groupBy) {
            this.activeGroupBy = groupBy;
        }
        if (truckType) {
            this.selectTruckType(truckType);
        }
    };
    DispatchScheduleTruckGridComponent.prototype.getFilterAppliedByKey = function (key) {
        var tagsSelected = this.filters && lodash_1.find(this.filters, { key: key });
        return tagsSelected && tagsSelected.values;
    };
    return DispatchScheduleTruckGridComponent;
}());
exports.DispatchScheduleTruckGridComponent = DispatchScheduleTruckGridComponent;
