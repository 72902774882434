<form #newPrice="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate *ngIf="price && price.id" translate>Edit Price</h1>
    <h1 translate *ngIf="!price || !price.id" translate>Add Price</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="form-content">
      <div class="field-row">
        <div class="field-group">
          <label class="required" translate>Product</label>
          <dropdown [options]="products" (onSelect)="productSelected($event)"
                    [config]="productsDropdownConfig" #productDropdown
                    title="&ndash;" [disabled]="loading"
                    (onSearch)="productSearch($event)" (nextPage)="productNextPage()"
                    [selectedOption]="selectedProduct"></dropdown>           
        </div>
        <div class="field-group">
          <label class="required" translate>Price Change</label>
          <div class="input-pair">
            <dropdown [options]="price.priceChangeTypeOptions"
                    (onSelect)="priceChangeTypeSelected($event)"
                    [config]="priceChangeTypesDropdownConfig"
                    #priceChangeTypeDropdown title="&ndash;" [disabled]="loading"
                    [selectedOption]="selectedPriceChangeType"></dropdown>
            <div class="input-group" [attr.data-prefix]="priceChangePrefix" [attr.data-suffix]="priceChangeSuffix">
              <input type="number" name="change_amount" min="0"
                    [(ngModel)]="price.priceChangeAmount" />
            </div>
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-group">
          <label translate>Notes</label>
          <textarea [(ngModel)]="price.notes" name="notes"></textarea>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false"
            [disabled]="!newPrice.form.valid || loading"
            [ngClass]="{loading: loading}" translate>
      Save Price
    </button>
  </div>
</form>
