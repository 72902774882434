import { Surcharge } from './surcharge';
import { Invoice } from '../invoices/invoice';
import { SurchargeTemplateSerializer } from '../surcharge-templates/surcharge-template.serializer';
import { TicketSerializer } from '../tickets/ticket.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class SurchargeSerializer {
  fromJson(json: any): Surcharge {
    json = camelcaseKeysDeep(json);
    const surcharge = new Surcharge();
    if (!json) { return surcharge; }

    surcharge.id = json.id;
    if (json.invoice && typeof (json.invoice) === 'object') {
      surcharge.invoice = new Invoice(json.invoice);
    } else if (json.invoice) {
      surcharge.invoice = new Invoice({ id: json.invoice });
    }
    if (json.scaleticket && typeof (json.scaleticket) === 'object') {
      surcharge.scaleticket = new TicketSerializer().fromJson(json.scaleticket);
    } else if (json.scaleticket) {
      surcharge.scaleticket = new TicketSerializer().fromJson({ id: json.scaleticket });
    }
    surcharge.title = json.title;
    surcharge.time = json.time;
    surcharge.createdAt = json.createdAt;
    surcharge.description = json.description;
    surcharge.amount = json.amount;
    if (parseFloat(json.amount) >= 0) {
      surcharge.positive = true;
    }
    if (json.kind) {
      surcharge.kind = json.kind;
    }
    if (json.template && typeof (json.template) === 'object') {
      surcharge.template = (new SurchargeTemplateSerializer().fromJson(json.template));
    } else if (json.scaleticket) {
      surcharge.template = (new SurchargeTemplateSerializer().fromJson({ id: json.template }));
    }

    return surcharge;
  }

  toJson(surcharge: Surcharge): any {
    let invoice: any = surcharge.invoice;
    let scaleticket: any = surcharge.scaleticket;
    if (surcharge.scaleticket && typeof (surcharge.scaleticket) === 'object') {
      scaleticket = surcharge.scaleticket.id;
    }
    if (surcharge.invoice && typeof (surcharge.invoice) === 'object') {
      invoice = surcharge.invoice.id;
    }
    let json = {
      id: surcharge.id,
      invoice: invoice,
      scaleticket: scaleticket,
      title: surcharge.title,
      time: surcharge.time,
      createdAt: surcharge.createdAt,
      description: surcharge.description,
      amount: surcharge.amount,
      kind: surcharge.kind
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
