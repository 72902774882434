"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var operators_1 = require("rxjs/operators");
var order_service_1 = require("../order.service");
var shared_1 = require("../../shared");
var ticket_review_dialog_component_1 = require("../ticket-review-dialog/ticket-review-dialog.component");
var ngx_device_detector_1 = require("ngx-device-detector");
var TicketDetailDialogComponent = /** @class */ (function () {
    function TicketDetailDialogComponent(orderService, dialog, dialogRef, deviceDetectorService, authenticationService, data) {
        this.orderService = orderService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.deviceDetectorService = deviceDetectorService;
        this.authenticationService = authenticationService;
        this.data = data;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.enabledFeatures = [];
        this.loading = false;
        this.errors = [];
        this.statusMode = false;
        this.tabs = ['Ticket Details', 'Ticket Images'];
        this.activeTab = this.tabs[0];
    }
    TicketDetailDialogComponent.prototype.ngOnInit = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.enabledFeatures = this.authenticationService.enabledFeatures();
    };
    TicketDetailDialogComponent.prototype.close = function () {
        this.callback(this.data.ticket);
        this.dialogRef.close();
    };
    TicketDetailDialogComponent.prototype.accept = function () {
        var _this = this;
        this.orderService.tripService
            .approveTicket(this.data.ticket.id)
            .pipe(operators_1.first())
            .subscribe(function (ticket) {
            _this.data.ticket.reviewedStatus = ticket.reviewedStatus;
            _this.callback(_this.data.ticket);
            _this.dialogRef.close();
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    TicketDetailDialogComponent.prototype.reject = function () {
        var _this = this;
        this.orderService.tripService
            .rejectTicket(this.data.ticket.id)
            .pipe(operators_1.first())
            .subscribe(function (ticket) {
            _this.data.ticket.reviewedStatus = ticket.reviewedStatus;
            _this.callback(_this.data.ticket);
            _this.dialogRef.close();
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    TicketDetailDialogComponent.prototype.openTicketReview = function () {
        var _this = this;
        var dialog = this.dialog.open(ticket_review_dialog_component_1.TicketReviewDialogComponent, {
            width: this.device.mobile ? '320px' : '480px',
            data: {
                ticket: this.data.ticket
            }
        });
        dialog.componentInstance.callback = function (ticket) { _this.data.ticket = ticket; };
    };
    return TicketDetailDialogComponent;
}());
exports.TicketDetailDialogComponent = TicketDetailDialogComponent;
