import { Driver } from '../drivers/driver';
import { DriverSerializer } from './../drivers/driver.serializer';
import { Truck } from '../trucks/truck';
import { TruckSerializer } from '../trucks/truck.serializer';
import { JobEvent } from '../job-events/job-event';
import { Trip } from '../trips/trip';
import { Job } from '../jobs/job';
import { PunchCardDecision } from './punch-card-decision';
import { ImageResizer } from '../images/resizer';
import { remove, find, clone } from 'lodash';

import * as moment from 'moment';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class PunchCard {
  id: string;
  name: string;
  _startTimeTimestamp: string;
  _endTimeTimestamp: string;
  driver: Driver;
  truck: Truck;
  jobEvent: JobEvent;
  assignment: string;
  ticketImage: string;
  ticketImageKey: string;
  startLocation: any;
  endLocation: any;
  trips: Trip[];
  selected = false;
  loading = false;
  ticketNumber: string;
  haulTotal: string;
  void: boolean;
  edited: boolean;
  completed: boolean;
  job?: Job;
  approvedByName?: string;
  approved?: boolean;
  decision?: PunchCardDecision;
  latestDecision?: string;
  latestDecisionStatus?: string;
  latestDecider?: string;
  latestDeciderName?: string;

  // pre-formatted fields for display
  _duration: string;
  _startTime: string;
  _endTime: string;
  _startDate: Date;
  _endDate: Date;

  constructor(punchCardInfo: any) {
    delete punchCardInfo['duration'];
    punchCardInfo = camelcaseKeysDeep(punchCardInfo);

    this.id = punchCardInfo.id;
    this.startTimeTimestamp = punchCardInfo.startTime;
    this.endTimeTimestamp = punchCardInfo.endTime;
    if (punchCardInfo.driver) {
      this.driver = new DriverSerializer().fromJson(punchCardInfo.driver);
    }
    if (punchCardInfo.truck) {
      this.truck = (new TruckSerializer().fromJson(punchCardInfo.truck));
    }
    if (punchCardInfo.jobevent) {
      this.jobEvent = new JobEvent(punchCardInfo.jobevent);
    }
    this.assignment = punchCardInfo.assignment;
    this.ticketImage = punchCardInfo.ticketImage;
    this.ticketImageKey = punchCardInfo.ticketImageKey;
    this.ticketNumber = punchCardInfo.ticketNumber;
    this.startLocation = punchCardInfo.startLocation;
    this.endLocation = punchCardInfo.endLocation;
    this.haulTotal = punchCardInfo.haulTotal;
    this.void = punchCardInfo.void;
    this.edited = punchCardInfo.edited;
    this.completed = punchCardInfo.completed;
    this.job = punchCardInfo.job ? punchCardInfo.job : null;
    this.approvedByName = punchCardInfo.approvedByName ? punchCardInfo.approvedByName : null;
    this.approved = punchCardInfo.approved ? punchCardInfo.approved : null;
    this.decision = punchCardInfo.decision ? punchCardInfo.decision : null;
    this.latestDecision = punchCardInfo.latestDecision ? punchCardInfo.latestDecision : null;
    this.latestDecisionStatus = punchCardInfo.latestDecisionStatus ? punchCardInfo.latestDecisionStatus : null;
    this.latestDecider = punchCardInfo.latestDecider ? punchCardInfo.latestDecider : null;
    this.latestDeciderName = punchCardInfo.latestDeciderName ? punchCardInfo.latestDeciderName : null;

    let values = [];
    if (this.jobEvent && this.jobEvent.job) { values.push(this.jobEvent.job.name); }
    if (this.driver) {
      let value = this.driver.name;
      if (this.truck) { value = `${value} (${this.truck.name})`; }
      values.push(value);
    }

    this.name = values.filter(Boolean).join(' - ');
  }

  formatDates(): void {
    let d;
    if (this._endTimeTimestamp) {
      d = moment.duration(moment(this._endTimeTimestamp).diff(this._startTimeTimestamp));
    } else {
      d = moment.duration(moment().diff(this.startTimeTimestamp));
    }
    this._duration = d.format('D[ days], H[ hrs], m[ mins]');

    if (this._startTimeTimestamp) {
      this._startDate = new Date(this._startTimeTimestamp);
      this._startTime = moment(this._startDate).format('h:mm a');
    }
    if (this._endTimeTimestamp) {
      this._endDate = new Date(this._endTimeTimestamp);
      this._endTime = moment(this._endDate).format('h:mm a');
    }
  }

  set startTimeTimestamp(value: any) {
    this._startTimeTimestamp = value;
    this.formatDates();
  }

  set endTimeTimestamp(value: any) {
    this._endTimeTimestamp = value;
    this.formatDates();
  }

  get endTimeTimestamp() {
    return this._endTimeTimestamp;
  }

  get startTimeTimestamp() {
    return this._startTimeTimestamp;
  }

  get duration(): string {
    return this._duration;
  }

  get startTime(): string {
    return this._startTime;
  }

  get endTime(): string {
    return this._endTime;
  }

  get startDate(): any {
    return this._startDate;
  }

  get endDate(): any {
    return this._endDate;
  }

  set startDate(value: any) {
    const _value = moment(value).format('YYYY-MM-DD');
    this.startTimeTimestamp = moment(_value + ' ' + this.startTime, 'YYYY-MM-DD h:mm a').toISOString();
  }

  set endDate(value: any) {
    if (value) {
      const _value = moment(value).format('YYYY-MM-DD');
      this.endTimeTimestamp = moment(_value + ' ' + this.endTime, 'YYYY-MM-DD h:mm a').toISOString();
    }
  }

  set startTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.startTimeTimestamp = parsedDate.toISOString();
  }

  set endTime(value: string) {
    if (value) {
      let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
      let date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
      let parsedDate = moment(date + ' ' + value, pattern);
      this.endTimeTimestamp = parsedDate.toISOString();
    }
  }

  set duration(value: string) {
    this._duration = value;
  }

  get administrativeStatus(): string {
    if (this.void) {
      return 'void';
    } else if (this.edited) {
      return 'edited';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus && this.administrativeStatus.length) {
      return true;
    } else {
      return false;
    }
  }

  get hasTicketImage(): boolean {
    return true;
  }

  get ticketImageRequired(): boolean {
    return true;
  }

  get editTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getResizedUrl(this.ticketImageKey, 190, 254);
    }
  }

  get fullsizeTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getFullsizeUrl(this.ticketImageKey);
    }
  }

  url(action): string {
    return '/punchcards/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) { remove(options, _option); }
    }

    if (!this.void) {
      let _option = find(options, { action: 'unvoid' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
