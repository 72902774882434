"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var shared_1 = require("../../shared");
var JobsHeaderComponent = /** @class */ (function () {
    function JobsHeaderComponent(authenticationService, collaboratorService) {
        this.authenticationService = authenticationService;
        this.collaboratorService = collaboratorService;
        this.hasAllDriversEnabled = false;
        this.carrierMode = false;
        this.pendingJobsCount = 0;
    }
    JobsHeaderComponent.prototype.ngOnInit = function () {
        this.hasAllDriversEnabled =
            this.authenticationService.hasAllDriversEnabled();
        var enabledFeatures = this.authenticationService.enabledFeatures(); // -> ['ruckitBilling', 'carrierMode', '.....']
        this.carrierMode = enabledFeatures.includes('ruckitBilling');
        this.getPendingCount();
    };
    JobsHeaderComponent.prototype.getPendingCount = function () {
        var _this = this;
        this.collaboratorService.getPending({ page_size: 1 }).subscribe(function () {
            _this.pendingJobsCount = _this.collaboratorService.count;
        }, function () {
            // error
            _this.pendingJobsCount = 0;
        });
    };
    return JobsHeaderComponent;
}());
exports.JobsHeaderComponent = JobsHeaderComponent;
