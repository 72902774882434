import { FleetHealthStats } from './fleet-health-stats';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class FleetHealthStatsSerializer {
  /**
   * @param  {any} json
   * @returns {FleetHealthStats} FleetHealthStats
   */
  fromJson(json: any): FleetHealthStats {
    // otherwise string to number (1.10.2 === 1102)
    const applicationVersions =
      json && json.application_versions ? json.application_versions : [];

    json = camelcaseKeysDeep(json);
    const fleetHealthStats = new FleetHealthStats();
    if (!json) {
      return fleetHealthStats;
    }

    fleetHealthStats.onShiftCount = json.onShiftCount;
    fleetHealthStats.driversWithIssues = json.driversWithIssues;
    fleetHealthStats.driverCount = json.driverCount;
    fleetHealthStats.applicationVersions = applicationVersions;

    return fleetHealthStats;
  }

  /**
   * @param  {FleetHealthStats} fleetHealthStats
   * @returns any
   */
  toJson(fleetHealthStats: FleetHealthStats): any {
    let json = {
      onShiftCount: fleetHealthStats.onShiftCount,
      driversWithIssues: fleetHealthStats.driversWithIssues,
      driverCount: fleetHealthStats.driverCount,
      applicationVersions: fleetHealthStats.applicationVersions,
    };
    return decamelizeKeysDeep(json);
  }
}
