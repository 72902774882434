import { Truck } from './truck';
import { TruckTypeSerializer } from './truck-type.serializer';
import { DriverSerializer } from '../drivers/driver.serializer';
import { ImageResizer } from '../images/resizer';
import { LocationSerializer } from '../locations/location.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TruckSerializer {
  fromJson(json: any): Truck {
    json = camelcaseKeysDeep(json);
    const truck = new Truck();
    if (!json) { return truck; }

    truck.id = json.id;
    truck.name = json.name;
    if (json.truckType && typeof (json.truckType) === 'object') {
      truck.truckType = (new TruckTypeSerializer().fromJson(json.truckType));
      let truckName = json.truckType.name || '';
      truck.ticketName = json.name + ' - ' + truckName;
      truck.truckTypeName = truckName;
    } else if (json.truckType) {
      truck.truckType = (new TruckTypeSerializer().fromJson({ name: json.truckType }));
      truck.ticketName = json.name + ' - ' + json.truckType;
      truck.truckTypeName = json.truckType;
    }
    if (typeof (json.driver) === 'object') {
      truck.driver = new DriverSerializer().fromJson(json.driver);
    } else {
      truck.driver = new DriverSerializer().fromJson({ id: json.driver });
    }
    truck.createdAt = json.createdAt;
    truck.createdBy = json.createdBy;
    truck.isConnexContainer = (json.isConnexContainer || false).toString();
    truck.lastModified = json.lastModified;
    truck.lastModifiedBy = json.lastModifiedBy;
    truck.licensePlate = json.licensePlate;
    truck.sunset = json.sunset;
    truck.image = json.image;
    truck.imageKey = json.imageKey;
    truck.status = json.status;
    truck.serviceStatus = json.serviceStatus;
    truck.displayServiceStatus = truck.serviceStatus &&
      truck.serviceStatus[0].toUpperCase() + truck.serviceStatus.replace('-', ' ').substr(1).toLowerCase();
    truck.leasedOrg = json.leasedOrg;
    truck.carrierOrganizationName = json.carrierOrganizationName;
    truck.carrierOrganizationId = json.carrierOrganizationId;
    truck.connectionId = json.connectionId;
    truck.noRate = json.noRate;
    truck.state = json.state;
    truck.carrier = json.carrier;

    if (json.image && json.imageKey) {
      truck.truckListImage = ImageResizer.getResizedUrl(json.imageKey, 82, 52);
      truck.dispatchImage = ImageResizer.getResizedUrl(json.imageKey, 50, 50);
      truck.editTruckImage = ImageResizer.getResizedUrl(json.imageKey, 370, 194);
      truck.gridTruckImage = ImageResizer.getResizedUrl(json.imageKey, 348, 183);
      truck.driverInfoWindowImage = ImageResizer.getResizedUrl(json.imageKey, 110, 65);
      truck.jobDetailImage = ImageResizer.getResizedUrl(json.imageKey, 46, 30);
    }

    if (truck.name || truck.truckTypeName) {
      truck.displayName = [truck.name && truck.name, truck.truckTypeName && truck.truckTypeName].filter(Boolean).join(' - ');
      
    } else {
      truck.displayName = 'NO TRUCK';
    }
    
    if (!truck.createdBy) {
      truck.displayName = [truck.displayName, "CX"].join(' - ');
    }

    truck.listName = truck.leasedOrg ? truck.name + ' - LEASED' : truck.name;
    truck.tags = json.tags;
    truck.yardLocation = new LocationSerializer().fromJson({
      id: json.yardLocation,
      name: json.yardLocationName
    });
    truck.customFieldData = json.customFieldData;

    return truck;
  }

  toJson(truck: Truck): any {
    const tags = truck.tags && truck.tags.map((tag) => {
      if (typeof tag === 'object') { tag = tag.name; }
      return tag;
    });

    let json = {
      id: truck.id,
      name: truck.name,
      licensePlate: truck.licensePlate,
      truckType: truck.truckType && truck.truckType.id,
      status: truck.status,
      serviceStatus: truck.serviceStatus,
      state: truck.state,
      noRate: truck.noRate,
      leasedOrg: truck.leasedOrg,
      connectionId: truck.connectionId,
      carrierOrganizationId: truck.carrierOrganizationId,
      carrier: truck.carrier,
      customFieldData: truck.customFieldData,
      yardLocation: truck.yardLocation && truck.yardLocation.id,
      tags: tags
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
