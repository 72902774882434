<ng-container *ngIf="view !== 'drivers'; else driverTable">
  <div class="job-table {{ view }}">
    <div class="header" translate>
      Jobs
      <dropdown *ngIf="view === 'jobEvents'" #loadTypeDropdown [options]="loadTypeOptions" [selectedOption]="activeLoadType" (onSelect)="selectLoadType($event)"></dropdown>
      <dropdown *ngIf="activeLoadType === 'Truck Type Requested'" #jobEventTruckTypeDropdown [options]="truckTypes" [config]="truckTypesConfig"
                class="truck-type-filters" title="{{ 'All Truck Types' | translate }}"
                (onSelect)="selectJobEventTruckType($event)" (onSearch)="getTruckTypes({ search: $event })"></dropdown>
      <div *ngIf="view === 'jobEvents'" class="stats">
        <ng-container *ngIf="activeLoadType === 'Loads Ordered'">
          <p class="small" translate>Load Summary</p>
          <p translate>{{ loadStats.remaining }} Remaining | {{ loadStats.assigned }} Assigned | <span class="ordered-stat" (click)="selectStatsType()">{{ loadStats.ordered[activeStatsType] }} {{ activeStatsType | titlecase | translate}} Ordered<i class="icon-next-arrow"></i></span></p>
        </ng-container>
        <ng-container *ngIf="activeLoadType === 'Tons Ordered'">
          <p class="small" translate>Tons Summary</p>
          <p translate>{{ tonStats.remaining }} Remaining | {{ tonStats.assigned }} Assigned | <b>{{ tonStats.ordered }} Ordered</b></p>
        </ng-container>
        <ng-container *ngIf="activeLoadType === 'Trucks Requested'">
          <p class="small" translate>Truck Summary</p>
          <p translate>{{ truckStats.remaining }} Remaining | {{ truckStats.assigned }} Assigned | <b>{{ truckStats.ordered }} Ordered</b></p>
        </ng-container>
        <ng-container *ngIf="activeLoadType === 'Truck Type Requested'">
          <p class="small" translate>Equipment Summary</p>
          <p translate><b>{{ truckTypeStats.required }}</b> Req. | <b>{{ truckTypeStats.assigned }}</b> Assig. | <b>{{ truckTypeStats.available }}</b> Available | <b>{{ truckTypeStats.needed }}</b> Needed</p>
        </ng-container>
      </div>
      <column-toggle *ngIf="view === 'jobEvents' && initPrefs"
                       [(availableColumns)]="availableJobEventsColumns"
                       [(displayedColumns)]="displayedJobEventColumns"
                       (displayedColumnsChange)="onColumnChange()"></column-toggle>

      <dropdown 
        #jobEventTagsDropdown 
        *ngIf="view === 'jobEvents'" 
        class="market-filters" 
        title="{{ 'All Markets' | translate }}"
        [options]="tags" 
        [config]="tagsConfig"
        [selectedItems]="jobEventTagsSelected"
        (onSelect)="selectJobEventTags($event)"
      >
      </dropdown>

      <div class="actions">
        <search (searchTermChange)="searchJobEvents($event)" [(searchTerm)]="jobEventSearch"></search>
        <button *ngIf="view === 'jobEvents'" [disabled]="!dispatchAvailable()" (click)="dispatch()" class="btn btn-primary" translate>Dispatch Drivers</button>
      </div>
      <action-menu *ngIf="view === 'jobEvents'" icon="icon-more">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a *ngIf="option.link" mat-menu-item [routerLink]="option.url">{{ option.name }}</a>
          <a *ngIf="!option.link" mat-menu-item (click)="setSelectedBulkAction(option)">{{ option.name }}</a>
        </ng-container>
      </action-menu>
    </div>
    <mat-table *ngIf="jobEvents" [dataSource]="jobEventsDataSource" matSort matSortDisableClear
               [matSortActive]="jobEventSortKey" [matSortDirection]="jobEventSortDirection"
               (matSortChange)="sort($event, 'jobEvent')" style="min-height: 350px;">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="select">
          <label>
            <mat-checkbox (change)="selectAll($event, 'jobEvents')"
                          [checked]="allSelected">
            </mat-checkbox>
          </label>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="select">
          <label>
            <mat-checkbox (change)="select(row)"
                          [checked]="row.selected">
            </mat-checkbox>
          </label>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="updates">
        <mat-header-cell *matHeaderCellDef class="updates" >
          <div *ngIf="jobEventUpdates" class="update-notification" (click)="removeUpdateNotifications()"></div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="updates">
          <div *ngIf="row.updates" class="update-notification" (click)="removeUpdateNotification(row)"></div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="job">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Job</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a [routerLink]="['/jobs', row.jobId, row.id]">{{ row.jobDisplayName }}</a>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Status</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.status | titlecase }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="customer-name">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Customer</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.customer }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="order-number">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Order #</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <input type="text" value="{{row.orderNumber}}" (blur)="updateJobEvent('orderNumber', $event.target.value, row)"/>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="job-number">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Job #</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <input type="text" value="{{row.jobNumber}}" (blur)="updateJobEvent('jobNumber', $event.target.value, row)"/>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="truck-type">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Truck Type</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.allowAnyTruckType" translate>Any Type</ng-container>
          <ng-container *ngIf="!row.allowAnyTruckType">
            <ng-container *ngFor="let truckType of row.truckTypes; let i = index">{{ truckType.name }}{{ i + 1 === row.truckTypes.length ? '' : ', ' }}</ng-container>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="start-time">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Start Time</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <input type="time" value="{{row.shift1Start | moment: 'HH:mm'}}" (blur)="updateJobEvent('shift1Start', $event.target.value, row)"/>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="end-time">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>End Time</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <input type="time" value="{{row.shift1End | moment: 'HH:mm'}}" (blur)="updateJobEvent('shift1End', $event.target.value, row)"/>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="interval">
        <mat-header-cell *matHeaderCellDef translate>Stagger (Minutes)</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <input type="number" value="{{row.deliveryInterval}}" (blur)="updateJobEvent('deliveryInterval', $event.target.value, row)"/>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="material">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Material</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.material }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="start-location">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Loading Location</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.startLocationName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="end-location">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Unloading Location</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.endLocationName }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="truck-tons">
        <mat-header-cell *matHeaderCellDef translate>Truck Tons</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <input type="number" value="{{ row.loadDeliveryTarget }}" (blur)="updateJobEvent('loadDeliveryTarget', $event.target.value, row)"/>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="amount-ordered">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Ordered Amount</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="labelled-input-group">
            <input type="number" min="0" step="0.1" value="{{ row.dailyDeliveryTarget }}" oninput="(validity.valid || !value.includes('-'))||(value=0);" (blur)="updateJobEvent('dailyDeliveryTarget', $event.target.value, row)"/>
            <dropdown
              [options]="orderTypeOptions"
              [selectedOption]="row.dailyDeliveryTargetType || orderTypeOptions[0]"
              (onSelect)="updateJobEvent('dailyDeliveryTargetType', $event.id, row)"
            ></dropdown>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="total-ordered">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Total Ordered</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.jobAmountNeeded ? (row.jobAmountNeeded | number) : '-' }} {{ row.totalAmountType | translate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loads-assigned">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Assigned Loads</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          {{ row.assignedLoads }} {{ 'Loads' | translate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loads-remaining">
        <mat-header-cell *matHeaderCellDef translate>Remaining Loads</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          {{
            row.dailyDeliveryTargetType === 'loads' ?
            getRemainingLoadCount(row.dailyDeliveryTarget, row.assignedLoads) + (' Loads' | translate) :
            'N/A'
          }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tons-assigned">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Assigned Tons</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          {{ getAssignedTons(row.assignedLoads, row.loadDeliveryTarget) }} {{ 'Tons' | translate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tons-remaining">
        <mat-header-cell *matHeaderCellDef translate>Remaining Tons</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          {{
            row.dailyDeliveryTargetType === 'tons' || row.dailyDeliveryTargetType === 'tonnes' ?
            getRemainingLoadCount(row.dailyDeliveryTarget, getAssignedTons(row.assignedLoads, row.loadDeliveryTarget)) + (' Tons' | translate) :
            'N/A'
          }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="trucks-requested">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Trucks Requested</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="view !== 'collaborators'; else shareAmount">
            <input type="number" min="0" value="{{ row.numTrucks }}" oninput="validity.valid||(value=1);" (blur)="updateJobEvent('numTrucks', $event.target.value, row)"/>
          </ng-container>
          <ng-template #shareAmount>
            {{ getSharedRequestedAmount(row) }}
          </ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="trucks-assigned">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Trucks Assigned</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.assignments.length }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="trucks-confirmed">
        <mat-header-cell *matHeaderCellDef translate>Trucks Confirmed</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ getSharedConfirmedAmount(row) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="trucks-dispatched">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Trucks Dispatched</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ view !== 'collaborators' ? getDispatchedCount(row.assignments) : getSharedDispatchedAmount(row) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="trucks-remaining">
        <mat-header-cell *matHeaderCellDef translate>Trucks Remaining</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          {{ getRemainingLoadCount(row.numTrucks, row.assignments.length) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="job-notes">
        <mat-header-cell *matHeaderCellDef translate>Job Notes</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div title="{{ row.notes }}">{{ row.notes | truncate: [30, '...'] }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="share-notes">
        <mat-header-cell *matHeaderCellDef translate>Share Notes</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div title="{{ row.shareNotes }}">{{ row.shareNotes | truncate: [30, '...'] }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="internal-notes">
        <mat-header-cell *matHeaderCellDef translate>Internal Notes</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="notes-list-cell">
            <notes
            [note]="
              row.notesList && row.notesList.length > 0
                ? row.notesList[row.notesList.length - 1]
                : { text: '' }
            "
            [saveProperty]="{ jobevent: row.id }"
          ></notes>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="assigned-trucks">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Assigned Trucks</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          <div class="assignment-label"
              [ngClass]="{'filled': row.assignments.length > 0}"
              (click)="editJobEvent(row)"
              matTooltip="{{ row.truckList }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip dispatch-schedule-table-tooltip"
              translate>
            {{ row.assignments.length }} Trucks
          </div>
          <i class="icon-accepted {{ jobEventDispatchedStatus(row) }}"></i>
          <button class="btn btn-default"
            matTooltip="{{ row.jobName }}" matTooltipPosition="left" matTooltipClass="ruckit-tooltip dispatch-schedule-table-tooltip" [disabled]="selectedDrivers.length === 0"
            (click)="assignSelectedDrivers.emit(row)" translate>Assign {{ selectedDrivers.length || '' }} Trucks</button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="collaborator-actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Collaborators</mat-header-cell>
        <mat-cell [ngClass]="{'loading': shareLoadingProgress < 100}" *matCellDef="let row">
          <div class="assignment-label"
              [ngClass]="{'filled': row.shares.length > 0}"
              (click)="editJobEvent(row)"
              matTooltip="{{ row.shareList }}"
              matTooltipPosition="below"
              matTooltipClass="ruckit-tooltip dispatch-schedule-table-tooltip"
              translate>
            {{ row.shares.length }} Collaborators
          </div>
          <i class="icon-accepted {{ shareDispatchedStatus(row) }}"></i>
          <button class="btn btn-default"
            matTooltip="{{ row.jobName }}" matTooltipPosition="left" matTooltipClass="ruckit-tooltip dispatch-schedule-table-tooltip" [disabled]="selectedConnections.length === 0"
            (click)="openAddCollaborators(row)" translate>Add {{ selectedConnections.length || '' }} Collaborators</button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef translate></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <action-menu icon="icon-more">
            <ng-container *ngIf="view === 'jobEvents'; else collaboratorActions">
              <a *ngFor="let option of getAssignmentOptions(row)" (click)="setSelectedAction(option, row)">{{ option | translate }}</a>
            </ng-container>
            <ng-template #collaboratorActions>
              <a *ngFor="let option of shareOptions" (click)="setSelectedAction(option, row)">{{ option | translate }}</a>
            </ng-template>
          </action-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedJobEventColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedJobEventColumns;" matRipple class="row"></mat-row>
    </mat-table>
    <mat-paginator #jobEventPaginator *ngIf="displayedJobEvents" [length]="displayedJobEvents.length"
                    [pageSize]="jobEventPageSize" [pageSizeOptions]="[5, 10, 25, 50]"
                    (page)="setTablePage('jobEvent', $event, true)"></mat-paginator>
    <div class="loader-container" *ngIf="jobEventLoadingProgress < 100">
      <div class="table-loader">
        <h2 translate>Getting Full Job Event List</h2>
        <h3 translate>This may take longer depending on your Job amount</h3>
        <div class="loading-bar">
          <span class="text">{{ jobEventLoadingProgress + '% Loaded' | translate }}</span>
          <div class="progress" [ngStyle]="{'width': jobEventLoadingProgress + '%'}"></div>
        </div>
      </div>
    </div>
    <no-results *ngIf="!displayedJobEvents || !displayedJobEvents.length" [itemName]="'Jobs'" [addText]="'a job'"
                [results]="displayedJobEvents.length" [matTable]="true" [styles]="{'margin-top': '0'}"
                [search]="jobEventSearch" [loading]="jobEventLoadingProgress < 100"
                [filters]="jobEventAppliedFilters" [bordered]="false" [hasHeader]="true"
                [addButtonText]="'Job'" (clearSearch)="expandSearch()" (addItem)="addNewJob()">
    </no-results>
  </div>
</ng-container>
<ng-template #driverTable>
  <div class="driver-table">
    <div class="header" translate>
      Drivers
      <dropdown *ngIf="view === 'drivers'" #loadTypeDropdown [options]="loadTypeOptions" [selectedOption]="activeLoadType" (onSelect)="selectLoadType($event)"></dropdown>
      <div class="stats">
        <ng-container *ngIf="activeLoadType === 'Loads Ordered'; else truckSummary">
          <p class="small" translate>Load Summary</p>
          <p translate>{{ loadStats.remaining }} Remaining | {{ loadStats.assigned }} Assigned | <span class="ordered-stat" (click)="selectStatsType()">{{ loadStats.ordered[activeStatsType] }} {{ activeStatsType | titlecase | translate}} Ordered<i class="icon-next-arrow"></i></span></p>
        </ng-container>
        <ng-template #truckSummary>
          <p class="small" translate>Truck Summary</p>
          <p translate>{{ truckStats.remaining }} Remaining | {{ truckStats.assigned }} Assigned | <b>{{ truckStats.requested }} Requested</b></p>
        </ng-template>
      </div>
      <column-toggle *ngIf="view === 'drivers' && initPrefs"
                       [(availableColumns)]="availableDriverColumns"
                       [(displayedColumns)]="displayedDriverColumns"
                       (displayedColumnsChange)="onColumnChange()"></column-toggle>
      <dropdown #tagsDropdown [options]="tags" [config]="tagsConfig"
                class="market-filters" title="{{ 'All Markets' | translate }}"
                (onSelect)="selectTags($event)"></dropdown>
      <dropdown #carriersDropdown *ngIf="carrierDropdownData && carrierDropdownData.carriers"
                [options]="carrierDropdownData.carriers" [config]="carrierDropdownData.config"
                title="{{ 'All Carriers' | translate }}" [selectedOption]="carrierDropdownData.carrier"
                (onSelect)="selectCarrier.emit($event)" (onSearch)="searchCarriers.emit({ search: $event })"
                (nextPage)="dropdownNextPage.emit('carrier')"></dropdown>
      <div class="actions">
        <search (searchTermChange)="searchDrivers($event)" [(searchTerm)]="driverSearch"></search>
      </div>
    </div>
    <mat-table #driverTable *ngIf="displayedDrivers" [dataSource]="driversDataSource" matSort matSortDisableClear
               [matSortActive]="driverSortKey" [matSortDirection]="driverSortDirection"
               (matSortChange)="sort($event, 'driver')">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="select">
          <label>
            <mat-checkbox (change)="selectAll($event, 'drivers')"
                          [checked]="allSelected">
            </mat-checkbox>
          </label>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="select">
          <label>
            <mat-checkbox (change)="select(row)"
                          [checked]="row.selected">
            </mat-checkbox>
          </label>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="driver">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Driver</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngIf="!row.selected; else editTruck">
            <div class="driver-name" (contextmenu)="openContextMenu($event, row.id)">{{ row.name }}</div>
            <div class="truck-name">{{ row.truck && row.truck.ticketName }}</div>
          </ng-container>
          <ng-template #editTruck>
            <div class="driver-name edit-mode" (contextmenu)="openContextMenu($event, row.id)">{{ row.name }}</div>
            <ruckit-dropdown [config]="trucksDropdownConfig"
                             [selectedOption]="row.truck && row.truck.id"
                             (onSelect)="changeTruck(row, $event)">
            </ruckit-dropdown>
          </ng-template>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="jobs">
        <mat-header-cell *matHeaderCellDef translate>Jobs</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <ng-container *ngFor="let assignment of row.assignments; let i = index">
            <i *ngIf="row.selected" class="icon-previous-arrow" (click)="arrangeAssignment(row, i, 'previous')"></i>
            {{ (i + 1) }} - {{ assignment.jobevent.job.name }} 
            ({{ assignment.maxNumberOfLoads > 0 ? assignment.maxNumberOfLoads : 'All Day' }})
            | {{ (assignment.uniqueStart ? assignment.uniqueStart : assignment.jobevent[assignment.shift + 'StartTimestamp']) | date: 'h:mm a' }}
            <i *ngIf="row.selected" class="icon-next-arrow" (click)="arrangeAssignment(row, i, 'next')"></i>
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="assigned-loads">
        <mat-header-cell *matHeaderCellDef mat-sort-header translate>Assigned Loads</mat-header-cell>
        <mat-cell [ngClass]="{'loading': !drivers || assignmentLoadingProgress < 100}" *matCellDef="let row">
          <div class="assignment-label"
              [ngClass]="{
                'filled': row.assignments.length > 0
              }"
              (click)="editDriver(row)">
            {{ row.loadCount }} Loads
          </div>
          <i class="icon-accepted {{ driverDispatchedStatus(row) }}"></i>
          <button class="btn btn-default" [disabled]="selectedJobEvents.length === 0"
            (click)="assignSelectedJobEvents.emit(row)" translate>Assign {{ selectedJobEvents.length || '' }} Jobs</button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef translate></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <action-menu icon="icon-more">
            <a *ngFor="let option of driverAssignmentOptions" (click)="setSelectedAction(option, row)">{{ option | translate }}</a>
          </action-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedDriverColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedDriverColumns;" matRipple class="row"></mat-row>
    </mat-table>
    <mat-paginator #driverPaginator *ngIf="displayedDrivers" [length]="displayedDrivers.length"
                    [pageSize]="driverPageSize" [pageSizeOptions]="[5, 10, 25, 50]"
                    (page)="setTablePage('driver', $event, true)"></mat-paginator>
    <div class="loader-container" *ngIf="driverLoadingProgress < 100">
      <div class="table-loader">
        <h2 translate>Getting Full Driver List</h2>
        <h3 translate>This may take longer depending on how big your fleet is</h3>
        <div class="loading-bar">
          <span class="text">{{ driverLoadingProgress + '% Loaded' | translate }}</span>
          <div class="progress" [ngStyle]="{'width': driverLoadingProgress + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>