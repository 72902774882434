import { LocationType } from './location-type';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class LocationTypeSerializer {
  fromJson(json: any): LocationType {
    json = camelcaseKeysDeep(json);
    const locationType = new LocationType();
    if (!json) { return locationType; }

    locationType.id = json.id;
    locationType.name = json.name;

    return locationType;
  }

  toJson(locationType: LocationType): any {
    let json = {
      id: locationType.id,
      name: locationType.name
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}

