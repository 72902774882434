import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// angular material
import { MatDialog } from '@angular/material';

// libraries
import * as moment from 'moment';

// types
import { ControlOption } from '../shared/control-option';
import { PaySheet } from './pay-sheet';
import { TableConfig } from '../shared/fancy-table/table.types';

// components
import {
  FancyTableComponent
} from '../shared/fancy-table/fancy-table.component';
import {
  ReportTypeDialogComponent,
  ReportTypeOption,
} from './report-type-dialog/report-type-dialog.component';

// services
import { PaySheetService } from './pay-sheet.service';

@Component({
  selector: 'ruckit-pay-sheets',
  templateUrl: './pay-sheets.component.html',
  styleUrls: ['./pay-sheets.component.scss'],
})
export class PaySheetsComponent implements OnInit, OnDestroy {
  @ViewChild('paySheetTable', { static: false })
  paySheetTable: FancyTableComponent;
  @Input() availableColumns = [
    { key: 'date', title: this.translationService.instant('Report Date') },
    {
      key: 'approved',
      title: this.translationService.instant('Approved'),
      sortable: false,
    },
    {
      key: 'type',
      title: this.translationService.instant('Type'),
      sortable: false,
    },
  ];
  @Input() displayedColumns = ['date', 'approved', 'type'];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  reportType: 'all' | 'internal' | 'external' = 'all';
  controls: ControlOption[] = [
    new ControlOption({
      name: 'all',
      label: 'All',
      classes: 'all-toggle',
      selected: true,
    }),
    new ControlOption({
      name: 'internal',
      label: 'Internal',
      classes: 'internal-toggle',
    }),
    new ControlOption({
      name: 'external',
      label: 'External',
      classes: 'external-toggle',
    }),
  ];
  paySheetTableConfig: TableConfig = <TableConfig>{
    hasHeader: true,
    service: PaySheetService,
    serviceFunction: 'listReportTable',
    preferenceKey: 'PaySheetsComponent-PaySheetService',
    ignorePreferences: true,
    preferencesEnabled: false,
    query: {},
    newRecordRoute: ['driver-pay', moment().format('YYYY-MM-DD')],
    collectionTitle: this.translationService.instant('Pay Reports'),
    noResultsText: this.translationService.instant('a pay report'),
    sortBy: 'date',
    sortDirection: 'asc',
    menuOptions: [
      {
        name: this.translationService.instant('Edit'),
        action: 'edit',
        link: true,
        external: false,
      },
    ],
    newRecordModal: () => this.createReport(),
  };
  query: any = {};

  loading = true;
  errors = [];
  type: string;
  currentDate = moment().format('YYYY-MM-DD');
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params,
      this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe((result) => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        this.type = result.params['type'] || '';
        if (this.type === 'received') {
          this.paySheetTableConfig.slug = 'driver-pay/pay-sheets/approvals/';
          this.paySheetTableConfig.hasNoResultsAction = false;
        }
        if (result.qparams['reportType']) {
          this.reportType = result.qparams['reportType'];
          const controlOption = <ControlOption>{
            classes: this.reportType + '-toggle',
            disabled: false,
            id: undefined,
            label:
              this.reportType.charAt(0).toUpperCase() +
              this.reportType.slice(1),
            name: this.reportType,
            selected: true,
          };
          const mouseEvent = { target: { checked: true } };
          this.onSelectToggle(controlOption, <any>mouseEvent);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * @param  {[any, PaySheet]} event The event data passed in by the click event
   *
   * Navigates to the selected pay report based on the event data passed in
   */
  openReport([e, row]: [any, PaySheet]) {
    if (this.type === 'received') {
      this.router.navigate(['driver-pay', row.id, row.type]);
    } else {
      this.router.navigate([
        'driver-pay',
        row.reportDate.split('T')[0],
        row.type,
      ]);
    }
  }

  /**
   * @param  {string} reportDate ISO formatted date
   * @returns {string} MMMM Do, YYYY formatted date string
   *
   * Formats a date for display in table views
   */
  formatReportDate(reportDate: string): string {
    return moment(reportDate.split('T')[0], 'YYYY-MM-DD').format(
      'MMMM Do, YYYY'
    );
  }

  /**
   * @param  {ControlOption} control
   * @param  {MouseEvent} event
   * @returns void
   * This function is called on toggling the segmented controls: GPS, Trips, or Hourly
   */
  onSelectToggle(control: ControlOption, event: MouseEvent): void {
    const checkbox = event.target as HTMLInputElement;
    this.controls = this.controls.map((c) =>
      Object.assign(c, {
        selected: c.name === control.name && checkbox.checked,
      })
    );
    this.reportType = <'all' | 'internal' | 'external'>control.name;

    if (this.paySheetTable) {
      setTimeout(
        () =>
          this.paySheetTable.updateUrl({ reportType: this.reportType }, false),
        500
      );
    }

    this.paySheetTableConfig = {
      ...this.paySheetTableConfig,
      query: { carrier_type: this.reportType },
    };
  }

  /**
   * @param  {'all' | 'internal' | 'external'} type Which kind of report will be preselected in the dialog
   * @returns void
   * Opens the ReportTypeDialog and preselects whatever type option is currently selected/viewed in the table
   */
  createReport(type: 'all' | 'internal' | 'external' = 'all') {
    const dialog = this.dialog.open(ReportTypeDialogComponent, {
      width: '380px',
      data: { selectedOption: type },
    });
    dialog.componentInstance.callback = (selectedOption: ReportTypeOption) => {
      this.router.navigate([
        'driver-pay',
        this.currentDate,
        selectedOption.value,
      ]);
    };
  }
}
