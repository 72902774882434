"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var RatesCurrencyPipe = /** @class */ (function () {
    function RatesCurrencyPipe(_currencyPipe) {
        this._currencyPipe = _currencyPipe;
    }
    RatesCurrencyPipe.prototype.transform = function (value, rateUnit, currencyCode, display, digitsInfo, locale) {
        if (!value && value !== 0) {
            return this._currencyPipe.transform(0, currencyCode, display, digitsInfo, locale);
        }
        else {
            var currency = this._currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
            return currency + " / " + rateUnit;
        }
    };
    return RatesCurrencyPipe;
}());
exports.RatesCurrencyPipe = RatesCurrencyPipe;
