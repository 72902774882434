"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var collaborator_service_1 = require("../collaborator.service");
var job_event_1 = require("../../job-events/job-event");
var api_service_1 = require("../../shared/api.service");
var job_event_share_service_1 = require("../../job-event-shares/job-event-share.service");
var app_constants_1 = require("../../app.constants");
var units_of_measure_service_1 = require("../../units/units-of-measure.service");
var EditCollaboratorsDialogComponent = /** @class */ (function () {
    function EditCollaboratorsDialogComponent(dialogRef, collaboratorService, jobEventShareService, unitsOfMeasureService, fb) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.jobEventShareService = jobEventShareService;
        this.unitsOfMeasureService = unitsOfMeasureService;
        this.fb = fb;
        this.allSelected = false;
        this.selectedShareIds = [];
        this.excludedShareIds = [];
        this.hasLoadListsEnabled = false;
        this.loadList = [];
        this.collaborationTemplate = {
            jobEventsLoading: false,
            jobEventOptions: [],
            jobevents: [],
            numTrucks: null,
            invoiceWeightUnit: null,
            invoiceType: null,
            invoiceRate: null,
            notes: '',
            cancelMissing: false,
            requestedAmount: null,
            requestedUnit: null,
        };
        this.loading = false;
        this.errors = [];
        this.jobEventShares = [];
        this.rateTrackingOptions = [
            { value: 'load', label: 'Load', name: 'Load' },
            { value: 'hour', label: 'Hour', name: 'Hour' }
        ];
        this.rateTrackingConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        this.displayedColumns = ['loadNumber', 'loadTime', 'truck', 'driver'];
        this.unitsOfMeasure = [];
    }
    Object.defineProperty(EditCollaboratorsDialogComponent.prototype, "formControls", {
        get: function () {
            return this.editCollaborators.controls;
        },
        enumerable: true,
        configurable: true
    });
    EditCollaboratorsDialogComponent.prototype.ngOnInit = function () {
        this.getJobEventShares();
        this.haulTypeOption = lodash_1.find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
        if (this.jobEvent.job.allowWeight) {
            this.rateTrackingOptions = this.rateTrackingOptions.concat([
                { value: 'weight', label: 'Weight', name: 'Weight' }
            ]);
        }
        this.weightOption = lodash_1.find(this.weightOptions, { value: this.jobEvent.haulWeightUnit });
        this.haulTypeOption = lodash_1.find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
        this.getUnitsOfMeasure();
        this.editCollaborators = this.fb.group({});
    };
    EditCollaboratorsDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventSharesReq && typeof this.jobEventSharesReq.unsubscribe === 'function') {
            this.jobEventSharesReq.unsubscribe();
        }
    };
    EditCollaboratorsDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var collaborations = this.jobEventShares.map(function (jobEventShare) {
            var shareFormGroup = _this.getFormGroup(jobEventShare.id);
            var collaboration = __assign({}, jobEventShare.collaboration, shareFormGroup.value);
            if (!collaboration.customFieldData) {
                collaboration.customFieldData = {};
            }
            if (_this.brokerRateKey) {
                collaboration.customFieldData[_this.brokerRateKey] = shareFormGroup.get('brokerRateCode').value;
            }
            collaboration.numTrucks = shareFormGroup.get('requestedUnit').value.name === 'Trucks'
                ? shareFormGroup.get('requestedAmount').value : 0;
            collaboration.requestedUnit = collaboration.requestedUnit.value;
            collaboration.invoiceType = collaboration.invoiceType.value;
            collaboration.invoiceWeightUnit = collaboration.invoiceWeightUnit.value;
            collaboration.loadSchedule = jobEventShare.loadSchedule;
            return collaboration;
        });
        if (this.jobEvent && this.jobEvent.job) {
            this.collaboratorService.bulkSave(this.jobEvent.job.id, collaborations).subscribe(function (res) {
                _this.dialogRef.close();
                _this.callback();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    EditCollaboratorsDialogComponent.prototype.getJobEventShares = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.jobEventSharesReq && typeof this.jobEventSharesReq.unsubscribe === 'function') {
            this.jobEventSharesReq.unsubscribe();
        }
        this.loading = true;
        this.jobEventSharesReq = this.jobEventShareService.list(__assign({ ordering: 'created_at', jobevent: this.jobEvent && this.jobEvent.id, id__in: this.selectedShareIds }, query)).subscribe(function (jobEventShares) {
            _this.jobEventShares = lodash_1.filter(jobEventShares, function (jobEventShare) {
                if ((_this.allSelected || _this.selectedShareIds.includes(jobEventShare.id)) &&
                    !_this.excludedShareIds.includes(jobEventShare.id)) {
                    return jobEventShare;
                }
            });
            _this.jobEventShares.forEach(function (jobEventShare) {
                jobEventShare.brokerRateCodeKey = _this.brokerRateKey;
                jobEventShare.collaboration.jobevents = [_this.jobEvent.id];
                var shareFormGroup = _this.createCollaborationFormGroup(jobEventShare);
                _this.editCollaborators.addControl("share_" + jobEventShare.id, shareFormGroup);
            });
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditCollaboratorsDialogComponent.prototype.getFormGroup = function (shareId) {
        return this.editCollaborators.get("share_" + shareId);
    };
    EditCollaboratorsDialogComponent.prototype.createCollaborationFormGroup = function (share) {
        var invoiceType = this.rateTrackingOptions.find(function (opt) { return opt.value === share.collaboration.invoiceType; });
        var invoiceWeightUnit = this.weightOptions.find(function (opt) { return opt.value === share.collaboration.invoiceWeightUnit; });
        return this.fb.group({
            requestedAmount: [share.collaboration.requestedAmount, [forms_1.Validators.required, forms_1.Validators.min(1)]],
            requestedUnit: [share.collaboration.requestedUnit],
            invoiceType: [invoiceType || this.haulTypeOption],
            invoiceRate: [{
                    value: share.collaboration.invoiceRate,
                    disabled: share.readonly
                }, forms_1.Validators.min(0)],
            invoiceWeightUnit: [invoiceWeightUnit || this.weightOption],
            brokerRateCode: [share.collaboration.brokerRateCode || ''],
            notes: [{
                    value: share.collaboration.notes || '',
                    disabled: share.readonly
                }]
        });
    };
    EditCollaboratorsDialogComponent.prototype.setFormControlValue = function (share, control, option) {
        var targetShare = this.getFormGroup(share.id);
        targetShare.controls[control].setValue(option);
        if (option.value === 'weight') {
            targetShare.controls.invoiceWeightUnit.setValue(this.weightOption);
        }
        targetShare.markAsDirty();
    };
    EditCollaboratorsDialogComponent.prototype.applyTemplate = function (template) {
        var _this = this;
        this.jobEventShares.forEach(function (share) {
            if (!share.readonly) {
                var targetConnection = _this.getFormGroup(share.id);
                targetConnection.patchValue(template);
                targetConnection.markAsDirty();
            }
        });
    };
    EditCollaboratorsDialogComponent.prototype.loadDropdownSelectionChanged = function (event, load, share) {
        var loadSchedule = share.loadSchedule;
        var existingLoad = loadSchedule.findIndex(function (l) { return l.loadNumber === load.loadNumber; });
        if (event.checked) {
            if (existingLoad === -1) {
                loadSchedule.push(load);
            }
        }
        else {
            if (existingLoad > -1) {
                loadSchedule.splice(existingLoad, 1);
            }
        }
        loadSchedule.sort(function (a, b) { return a.loadNumber - b.loadNumber; });
        this.editCollaborators.markAsDirty();
    };
    EditCollaboratorsDialogComponent.prototype.getUnitsOfMeasure = function () {
        var _this = this;
        this.unitsOfMeasureService.list().subscribe(function (units) {
            _this.unitsOfMeasure = units.map(function (unit) { return ({
                value: unit.id,
                label: unit.name,
                name: unit.name,
                selected: false,
            }); });
        });
    };
    return EditCollaboratorsDialogComponent;
}());
exports.EditCollaboratorsDialogComponent = EditCollaboratorsDialogComponent;
