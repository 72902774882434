<widget [size]="[2,1]" (window:resize)="onResize($event)">
  <div class="heading" widget-header>
    <h2 translate>Daily Production Values</h2>
  </div>
  <div class="content" widget-content>
    <div class="widget-table">
      <div class="table-body">
        <div class="header">
          <div class="head job sort" (click)="sort('job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'job__name'}" translate>
            Job
          </div>
          <div class="head loads" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loads'}" translate>
            Loads
          </div>
          <div class="head tons" [ngClass]="{asc: sortAsc, sorted: sortBy === 'tons'}" translate>
            Tons
          </div>
          <div class="head hours" [ngClass]="{asc: sortAsc, sorted: sortBy === 'hours'}" translate>
            Hours
          </div>
        </div>

        <ng-container *ngIf="stats && stats.length">
          <div *ngFor="let stat of stats" class="row">
            <div class="job"><a [routerLink]="[ '/jobs', stat.jobId, stat.id ]">{{ stat.jobDisplayName }}</a></div>
            <div class="loads">{{ stat.totalLoads }}</div>
            <div class="tons">{{ stat.totalTons }}</div>
            <div class="hours">{{ stat[totalHoursField] }}</div>
          </div>
        </ng-container>

        <my-loader *ngIf="loading"></my-loader>
      </div>
    </div>
  </div>
</widget>
