<form #newInvoice="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Choose a Customer or Job to Invoice</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group customer" fxFlex="100">
          <label translate>Select a Customer</label>
          <input type="hidden" name="customer" [(ngModel)]="model.customer" #customer="ngModel" />
          <dropdown id="customer-to-invoice-dropdown"
                    #customerDropdown
                    [options]="customerOptions"
                    [selectedOption]="model.customer"
                    [config]="customerDropdownConfig"
                    [disabled]="loading || customerLoading"
                    (onSelect)="selectCustomer($event)"
                    (onSearch)="customerDropdownSearch($event)"
                    (nextPage)="customerDropdownNextPage()"
                    title="-"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group job" fxFlex="100">
          <label translate>Select a Job</label>
          <input type="hidden" name="job" [(ngModel)]="model.job" #customer="ngModel" />
          <dropdown id="job-to-invoice-dropdown"
                    #jobDropdown
                    [options]="jobOptions"
                    [selectedOption]="model.job"
                    [config]="jobDropdownConfig"
                    [disabled]="loading || jobLoading"
                    (onSelect)="selectJob($event)"
                    (onSearch)="jobDropdownSearch($event)"
                    (nextPage)="jobDropdownNextPage()"
                    title="-"></dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group invoice-type" fxFlex="100">
          <ul class="segmented-control">
            <li class="item" *ngFor="let item of invoiceTypes" (click)="selectInvoiceType(item)">
              <input class="input"
                     type="radio"
                     name="invoice_type"
                     #invoiceType="ngModel"
                     [value]="item"
                     [(ngModel)]="model.invoiceType">
              <label class="label" for="invoice_type">{{ item | titleCase }} ({{ (invoiceCounts[item] || 0) | number : '1.0-0' }})</label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>

  </div>

  <div class="modal-footer">
    <button id="next-button"
            class="btn btn-primary"
            (click)="newInvoice.form.valid && submit(); false"
            [disabled]="!newInvoice.form.valid || loading"
            [ngClass]="{loading: loading}"
            translate>
      Next
    </button>
  </div>
</form>
