import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PunchCardsRoutingModule } from './punch-cards-routing.module';

import { PunchCardsComponent } from './punch-cards.component';
import { NewPunchCardComponent } from './new-punch-card.component';
import { EditPunchCardComponent } from './edit-punch-card.component';
import { TripDetailComponent } from '../trips/trip-detail.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PunchCardsRoutingModule
  ],
  declarations: [
    PunchCardsComponent,
    TripDetailComponent,
    NewPunchCardComponent,
    EditPunchCardComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class PunchCardsModule { }
