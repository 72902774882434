"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var moment = require("moment");
var lodash_1 = require("lodash");
var job_event_service_1 = require("../../job-events/job-event.service");
var assignment_service_1 = require("../../assignments/assignment.service");
var driver_service_1 = require("../../drivers/driver.service");
var driver_serializer_1 = require("../../drivers/driver.serializer");
var truck_serializer_1 = require("../../trucks/truck.serializer");
var assignment_serializer_1 = require("../../assignments/assignment.serializer");
var dispatch_service_1 = require("../dispatch.service");
var Panel;
(function (Panel) {
    Panel[Panel["shift"] = 1] = "shift";
    Panel[Panel["assign"] = 2] = "assign";
    Panel[Panel["carrier"] = 3] = "carrier";
    Panel[Panel["driver"] = 4] = "driver";
    Panel[Panel["truck"] = 5] = "truck";
})(Panel || (Panel = {}));
var MobileDispatchAssignComponent = /** @class */ (function () {
    function MobileDispatchAssignComponent(router, route, driverService, dispatchService, jobEventService, assignmentService) {
        this.router = router;
        this.route = route;
        this.driverService = driverService;
        this.dispatchService = dispatchService;
        this.jobEventService = jobEventService;
        this.assignmentService = assignmentService;
        this.loading = false;
        this.panel = Panel;
        this.errors = [];
        this.activePanel = 1;
        this.shift = { slots: [] };
        this.selectedShift = 0;
        this.dispatchedTotal = 0;
        this.editAssignment = false;
        this.editMode = false;
        this.allSubscriptionsToUnsubscribe = [];
    }
    MobileDispatchAssignComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.getJobEvent(result.params['jobEventId']);
            _this.selectedShift = Number(result.qparams['shift']) || 0;
            switch (result.params['panel']) {
                case 'shift': {
                    _this.activePanel = Panel.shift;
                    break;
                }
                case 'assign': {
                    _this.activePanel = Panel.assign;
                    break;
                }
                case 'carrier': {
                    _this.activePanel = Panel.carrier;
                    break;
                }
                case 'driver': {
                    _this.activePanel = Panel.driver;
                    break;
                }
                case 'truck': {
                    _this.activePanel = Panel.truck;
                    break;
                }
            }
        }));
    };
    MobileDispatchAssignComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    MobileDispatchAssignComponent.prototype.getJobEvent = function (id) {
        var _this = this;
        this.loading = true;
        this.jobEventService.getJobEvent(id).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
            _this.getAssignments(id);
        }, function (err) {
            _this.errors = err;
        });
    };
    MobileDispatchAssignComponent.prototype.getAssignments = function (jobEventId) {
        var _this = this;
        var shift = "shift" + (this.selectedShift + 1);
        this.assignmentService.list({
            jobevent: jobEventId, can_dispatch: 'True', shift: shift
        }).subscribe(function (assignments) {
            _this.shift = { slots: [] };
            assignments.forEach(function (assignment) {
                if (assignment['uniqueStart']) {
                    assignment['uniqueStart'] = moment(assignment['uniqueStart']).format('h:mm A');
                }
                var driver = new driver_serializer_1.DriverSerializer().fromJson(assignment.driver);
                var truck = (new truck_serializer_1.TruckSerializer().fromJson(assignment.truck));
                var _assignment = new assignment_serializer_1.AssignmentSerializer().fromJson(assignment);
                var numberOfLoadsType = 'numbered';
                if (_assignment.numberOfLoadsType) {
                    numberOfLoadsType = _assignment.numberOfLoadsType;
                }
                else {
                    numberOfLoadsType = (_assignment.maxNumberOfLoads === 0 || _assignment.maxNumberOfLoads === null) ? 'allDay' : 'numbered';
                }
                _this.shift.slots.push({
                    assignment: _assignment,
                    driver: driver,
                    truck: truck,
                    numberOfLoadsType: numberOfLoadsType,
                    saveButton: false
                });
            });
            var max = _this.jobEvent.numTrucks || _this.shift.slots.length + 1;
            for (var i = _this.shift.slots.length; i < max; i++) {
                _this.shift.slots.push({});
            }
            if (_this.shift && _this.shift.slots) {
                _this.dispatchedTotal = _this.calculateDispatchedTotal(_this.shift.slots);
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    MobileDispatchAssignComponent.prototype.back = function () {
        if (this.activePanel === Panel.assign && this.jobEvent && this.jobEvent.shifts === 2) {
            this.activePanel = Panel.shift;
            this.router.navigate([
                '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'shift'
            ]);
        }
        else if (this.jobEvent && (this.activePanel === Panel.shift || this.activePanel === Panel.assign)) {
            var jobEventDate = moment(this.jobEvent.shift1StartTimestamp).format('YYYYMMDD');
            this.router.navigate(['/dispatch'], { queryParams: { date: jobEventDate } });
        }
        else if (this.jobEvent) {
            this.activePanel = Panel[Panel[this.activePanel - 1]];
            this.router.navigate(['/dispatch', this.jobEvent.job.id, this.jobEvent.id, Panel[this.activePanel]]);
        }
    };
    MobileDispatchAssignComponent.prototype.calculateDispatchedTotal = function (slots) {
        var amtDispatched = 0;
        if (slots) {
            slots.forEach(function (slot) {
                amtDispatched = slot.driver ? amtDispatched + 1 : amtDispatched;
            });
        }
        return amtDispatched;
    };
    MobileDispatchAssignComponent.prototype.onShiftSelected = function (shift) {
        this.selectedShift = shift;
        this.activePanel = Panel.assign;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { shift: this.selectedShift },
            queryParamsHandling: 'merge'
        });
    };
    MobileDispatchAssignComponent.prototype.onCarrierSelected = function (carrier) {
        this.selectedCarrier = carrier;
        this.activePanel = Panel.driver;
        this.router.navigate([
            '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'driver'
        ]);
    };
    MobileDispatchAssignComponent.prototype.onSlotSelected = function (data) {
        // tslint:disable-next-line:no-unused-variable
        var shift = data.shift, index = data.index;
        this.selectedSlot = index;
        this.activePanel = Panel.carrier;
        this.router.navigate([
            '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'carrier'
        ]);
    };
    MobileDispatchAssignComponent.prototype.onDriverSelected = function (driver) {
        this.selectedDriver = driver;
        this.assignDriver(this.selectedDriver);
    };
    MobileDispatchAssignComponent.prototype.onEditTruckSelected = function (driver) {
        this.selectedDriver = driver;
        this.activePanel = Panel.truck;
        this.router.navigate([
            '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'truck'
        ]);
    };
    MobileDispatchAssignComponent.prototype.onTruckSelected = function (data) {
        var truck = data.truck, driver = data.driver;
        this.selectedTruck = truck;
        this.selectedDriver = driver && driver.driver || driver;
        this.assignTruckAndDriver(truck);
    };
    MobileDispatchAssignComponent.prototype.onRemoveSlotSelected = function (slot) {
        var _this = this;
        if (slot.assignment) {
            if (this.shift) {
                this.assignmentService.remove(slot.assignment.id).subscribe(function (res) {
                    slot.driver = undefined;
                    slot.assignment = undefined;
                    lodash_1.remove(_this.shift.slots, slot);
                    var max = _this.jobEvent.numTrucks || _this.shift.slots.length + 1;
                    for (var i = _this.shift.slots.length; i < max; i++) {
                        _this.shift.slots.push({});
                    }
                }, function (err) {
                    _this.errors = err;
                });
            }
        }
        // this.router.navigate([
        //   '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'assign'
        // ]);
        this.editMode = false;
        this.getJobEvent(this.jobEvent.id);
    };
    MobileDispatchAssignComponent.prototype.onAssignmentSelected = function (assignment) {
    };
    MobileDispatchAssignComponent.prototype.onDispatchAssignments = function () {
        this.dispatch();
    };
    MobileDispatchAssignComponent.prototype.assignDriver = function (driver) {
        var _this = this;
        this.loading = true;
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var assignment = new assignment_serializer_1.AssignmentSerializer().fromJson({
            driver: driver.id,
            truck: driver.truck.id,
            jobevent: jobEventId,
            shift: 'shift' + (this.selectedShift + 1),
            maxNumberOfLoads: 0 // this.allDriversEnabled ? 0 : 1
        });
        this.assignmentService.save(assignment).subscribe(function (res) {
            _this.router.navigate([
                '/dispatch', _this.jobEvent.job.id, _this.jobEvent.id, 'assign'
            ]);
            // this.getJobEvent(this.jobEvent.id);
            // this.mobileSearch('', 'drivers');
            // this.selectedAssignment = res;
            // this.editAssignment = true;
            // this.loading = false;
        }, function (err) {
            // parseErrors(err);
            // this.loading = false;
        });
    };
    MobileDispatchAssignComponent.prototype.assignTruckAndDriver = function (truck) {
        var _this = this;
        if (this.selectedDriver && this.selectedDriver.id) {
            this.selectedDriver.truck = truck;
            this.driverService.assign(this.selectedDriver).subscribe(function (res) {
                _this.selectedTruck = null;
                _this.assignDriver(_this.selectedDriver);
            }, function (err) {
                // this.errors = parseErrors(err);
            });
        }
    };
    // updateAssignment(assignment) {
    //   let _assignment = clone(assignment);
    //   let jobId = this.job && this.job.id;
    //   let jobEventId = this.jobEvent && this.jobEvent.id;
    //   let dateString = moment(this.displayedDate).format('MM/DD/YYYY');
    //   let timeString = _assignment['uniqueStart'];
    //   let formatString = 'MM/DD/YYYY h:mm A';
    //   _assignment['uniqueStart'] = moment(dateString + ' ' + timeString, [formatString]).format();
    //   this.assignmentService.save(_assignment).subscribe(() => {
    //     if (this.driverList) {
    //       this.driverList = false;
    //       this.getJob.emit({ jobId, jobEventId });
    //     }
    //     this.selectedDriver = null;
    //     this.editAssignment = false;
    //     this.loading = false;
    //   }, (err) => {
    //     parseErrors(err);
    //     this.loading = false;
    //   });
    // }
    MobileDispatchAssignComponent.prototype.dispatch = function () {
        var _this = this;
        this.loading = true;
        this.dispatchService.save({
            jobEvent: this.jobEvent && this.jobEvent.id,
            notifyNew: true
        }).subscribe(function (res) {
            _this.jobEvent.lastDispatchedTime = res['lastDispatchTime'];
            _this.jobEvent.lastDispatchedBy = res['lastDispatchedBy'];
            _this.shift.slots.forEach(function (slot) {
                if (slot.driver) {
                    slot.assignment.dispatched = true;
                }
            });
            _this.loading = false;
            _this.router.navigate(['/dispatch']);
        }, function (err) {
            // parseErrors(err);
            _this.loading = false;
        });
    };
    return MobileDispatchAssignComponent;
}());
exports.MobileDispatchAssignComponent = MobileDispatchAssignComponent;
