<div class="app-content">
  <my-toolbar icon="icon-back" [iconLink]="returnTo" title="{{ (account && account.organization ? account.organization.name : 'Account') | translate }}"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <div class="subnav custom-subnav">
        <div class="links" *ngIf="accountId">
          <a [routerLink]="['/', accountType, accountId, 'edit']" routerLinkActive="active" class="account-info" translate>Account Info</a>
          <a [routerLink]="['/', accountType, 'trucks', accountId]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}"  class="trucks" translate>Account Trucks</a>
          <a [routerLink]="['/', accountType, 'drivers', accountId]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:true}"  class="drivers" translate>Account Drivers</a>
        </div>
        <div class="links solo" *ngIf="!accountId">
          <a [routerLink]="['/', accountType, 'new']" routerLinkActive="active" translate>Account Info</a>
        </div>
      </div>
    </div>
    <div class="right-actions">
      <button id="save-account-button" class="btn btn-primary" (click)="submit()" [ngClass]="{loading: loading}"
        [disabled]="!accountForm.form.valid" translate>
        Save Account
      </button>
    </div>
  </action-bar>

  <div class="content">
    <div class="error-container" *ngIf="errors && errors.length">
      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
    <my-loader *ngIf="loading"></my-loader>

    <form #accountForm="ngForm">
      <div *ngIf="account && !loading" class="content-body">
        <div class="section">
          <div class="left-section">
            <div class="title" translate>Contact Details</div>
          </div>

          <div class="right-section">
            <div class="form-input-group">
              <div class="field-group field company-name">
                <label class="required" translate>Company Name</label>
                <input required id="company-name" name="company-name" [(ngModel)]="account.organization.name"
                  [disabled]="!account.canEdit">
              </div>

              <div class="field-group field contact-name">
                <label translate>Contact Name</label>
                <input id="contact-name" name="contact-name" [(ngModel)]="account.organization.contactName" [disabled]="!account.canEdit">
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field dba-name">
                <label translate>Company DBA Name</label>
                <input id="dba-name" name="dba-name" [(ngModel)]="account.organization.workOrderDbaName"
                  [disabled]="!account.canEdit">
              </div>

              <div class="field-group field company-type">
                <div class="name">
                  <label translate>Company Type</label>
                  <select name="work_order_company_type" [(ngModel)]="account.organization.workOrderCompanyType">
                    <option value="" selected disabled hidden>{{ 'Select Company Type' | translate }}</option>
                    <option *ngFor="let companyType of companyTypeOptions" [value]="companyType.id">{{ companyType.name }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field address">
                <label translate>Address</label>
                <input id="address" name="address" [(ngModel)]="account.organization.street" [disabled]="!account.canEdit">
              </div>

              <div class="field-group field address2">
                <label translate>Address 2</label>
                <input name="address2" [(ngModel)]="account.organization.address2" [disabled]="!account.canEdit" />
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field city">
                <label translate>City</label>
                <input id="city" name="city" [(ngModel)]="account.organization.city" [disabled]="!account.canEdit">
              </div>

              <div class="form-input-group field">
                <div class="field-group field-margin country">
                  <label translate>Country</label>
                  <select name="country" (change)="selectCountry($event.target.value, 'contact')" [disabled]="!account.canEdit">
                    <option value="" selected disabled hidden translate>-- Country --</option>
                    <option 
                      *ngFor="let countryOption of countryOptions" 
                      [value]="countryOption.id"
                      [selected]="countryOption.id === account.organization.country"
                    >
                      {{ countryOption.name }}
                    </option>
                  </select>
                </div>

                <div class="field-group field-margin state">
                  <ng-container *ngIf="account.organization.country === 'CA'">
                    <label translate>Province</label>
                    <select name="state" (change)="selectState($event.target.value, 'contact')" [disabled]="!account.canEdit">
                      <option value="" selected disabled hidden translate>-- Province --</option>
                      <option *ngFor="let stateOption of stateOptions" [value]="stateOption.name"
                              [selected]="stateOption.name === account.organization.state">
                        {{ stateOption.name }}
                      </option>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="account.organization.country !== 'CA'">
                    <label translate>State</label>
                    <select name="state" (change)="selectState($event.target.value, 'contact')" [disabled]="!account.canEdit">
                      <option value="" selected disabled hidden translate>-- State --</option>
                      <option *ngFor="let stateOption of stateOptions" [value]="stateOption.name"
                        [selected]="stateOption.name === account.organization.state">
                        {{ stateOption.name }}
                      </option>
                    </select>
                  </ng-container>
                </div>

                <div class="field-group small-field zip">
                  <label translate>Zip</label>
                  <input id="zipcode" name="zipcode" [(ngModel)]="account.organization.zipcode" [disabled]="!account.canEdit">
                </div>
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field phone-number">
                <label class="required" translate>Phone Number</label>
                <input phoneInputMask required id="phone-number" name="phone-number" [(ngModel)]="account.organization.phoneNumber"
                  [disabled]="!account.canEdit">
              </div>

              <div class="field-group field fax-number">
                <label translate>Fax Number</label>
                <input id="fax-number" name="fax-number" [(ngModel)]="account.organization.faxNumber" [disabled]="!account.canEdit">
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field email">
                <label class="required" translate>Email</label>
                <input required id="email" name="email" [(ngModel)]="account.organization.email" [disabled]="!account.canEdit">
              </div>
              <div class="field-group field"></div>
            </div>
          </div>
        </div>

        <div class="section" *ngIf="posEnabled">
          <div class="left-section">
            <div class="title" translate>Account Details</div>
          </div>

          <div class="right-section">
            <div class="field-group field">
              <label translate>Account Options</label>
              <div class="field-group field">
                <input type="checkbox" [checked]="account.posPrintPrices" (change)="account.posPrintPrices = $event.target.checked ? true : false" />
                <label class="print-prices-checkbox" attr.data-label="{{ 'Print Prices' | translate}}" for="print_prices"></label>

                <input type="checkbox" [checked]="account.posCashPay" (change)="account.posCashPay = $event.target.checked ? true : false" />
                <label attr.data-label="{{ 'Cash Pay' | translate}}" for="cash_pay"></label>
              </div>
              <div class="field-group field">
                <input type="checkbox" [checked]="account.posPrintTicket" (change)="account.posPrintTicket=$event.target.checked ? true : false" />
                <label class="print-tickets-checkbox" attr.data-label="{{ 'Print Tickets' | translate}}" for="print_tickets"></label>

                <input type="checkbox" [checked]="account.posTaxExempt" (change)="account.posTaxExempt = $event.target.checked ? true : false" />
                <label attr.data-label="{{ 'Tax Exempt' | translate}}" for="tax_exempt"></label>
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field">
                <label translate>Pricelist</label>
                <input type="hidden" name="priceList" required [(ngModel)]="account.posPriceList" #priceList="ngModel" />
                <dropdown id="priceListDropdown" #priceListDropdown [options]="priceListOptions" [selectedOption]="selectedPriceList"
                  [config]="priceListDropdownConfig" [disabled]="!priceListOptions || !priceListOptions.length"
                  (onSelect)="selectPriceList($event)" title="-"></dropdown>
              </div>

              <div class="form-input-group field">
                <div class="field-group field-margin small-field">
                  <label translate>Tax</label>
                  <span class="percentage-mask">
                    <input id="pos-tax-percentage" name="pos-tax-percentage" numberInRange minValue=0 maxValue=100
                      [(ngModel)]="account.posTaxPercentage" #posTaxPercentage="ngModel">
                  </span>
                </div>
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field">
                <label translate>Payment Type</label>
                <dropdown id="paymentTypeDropdown" #paymentTypeDropdown [options]="paymentTypeOptions" [selectedOption]="selectedPaymentType"
                  (onSelect)="selectPaymentType($event)" title="-">
                </dropdown>
              </div>
              <div class="field-group field"></div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="left-section">
            <div class="title" translate>Billing Details</div>
          </div>

          <div class="right-section">

            <div class="field-group">
              <div class="field-group field billing-id">
                <label translate>Billing ID</label>
                <input id="billing-id" name="billing-id" [(ngModel)]="account.uniqueBillingId" />
              </div>
            </div>

            <div class="field-group">
              <label translate>Billing Address</label>
              <input type="checkbox" (change)="copyContactAddress($event)" [disabled]="!account.canEdit" />
              <label attr.data-label="{{ 'Same as Contact Address' | translate}}" for="same_as_contact_address"></label>

            </div>

            <div class="form-input-group">
              <div class="field-group field">
                <label translate>Address</label>
                <input id="billing-address1" name="billing-address1" [(ngModel)]="account.organization.billingAddress1"
                  [disabled]="!account.canEdit">
              </div>

              <div class="field-group field">
                <label translate>Address 2</label>
                <input id="billing-address2" name="billing-address2" [(ngModel)]="account.organization.billingAddress2"
                  [disabled]="!account.canEdit">
              </div>
            </div>

            <div class="form-input-group">
              <div class="field-group field">
                <label translate>City</label>
                <input id="billing-city" name="billing-city" [(ngModel)]="account.organization.billingCity" [disabled]="!account.canEdit">
              </div>

              <div class="form-input-group field">
                <div class="field-group field-margin">
                  <label translate>Country</label>
                  <select name="country" (change)="selectCountry($event.target.value, 'billing')" [disabled]="!account.canEdit">
                    <option value="" selected disabled hidden translate>-- Country --</option>
                    <option 
                      *ngFor="let countryOption of countryOptions" 
                      [value]="countryOption.id"
                      [selected]="countryOption.id === account.organization.billingCountry"
                    >
                      {{ countryOption.name }}
                    </option>
                  </select>
                </div>

                <div class="field-group field-margin">
                  <ng-container *ngIf="account.organization.billingCountry === 'CA'">
                    <label translate>Province</label>
                    <select name="state" (change)="selectState($event.target.value, 'billing')" [disabled]="!account.canEdit">
                      <option value="" selected disabled hidden translate>-- Province --</option>
                      <option *ngFor="let stateOption of billingStateOptions" [value]="stateOption.name"
                        [selected]="stateOption.name === account.organization.billingState">
                        {{ stateOption.name }}
                      </option>
                    </select>
                  </ng-container>
                  <ng-container *ngIf="account.organization.billingCountry !== 'CA'">
                    <label translate>State</label>
                    <select name="state" (change)="selectState($event.target.value, 'billing')" [disabled]="!account.canEdit">
                      <option value="" selected disabled hidden translate>-- State --</option>
                      <option *ngFor="let stateOption of billingStateOptions" [value]="stateOption.name"
                              [selected]="stateOption.name === account.organization.billingState">
                        {{ stateOption.name }}
                      </option>
                    </select>
                  </ng-container>
                </div>

                <div class="field-group small-field">
                  <label translate>Zip</label>
                  <input id="billing-zip" name="billing-zip" [(ngModel)]="account.organization.billingZipcode"
                    [disabled]="!account.canEdit">
                </div>
              </div>
            </div>

            <div class="field-group field billing-email">
              <label translate>Billing Email</label>
              <input type="checkbox" (change)="copyContactEmail($event)" [disabled]="!account.canEdit" />
              <label attr.data-label="{{ 'Same as Contact Email' | translate}}" for="same_as_contact_email"></label>
            </div>

            <div class="field-group field billing-email">
              <label translate>Email</label>
              <input type="email" id="billing-email" name="billing-email" [(ngModel)]="account.organization.billingEmail"
                [disabled]="!account.canEdit">
            </div>
            <div class="field-group field"></div>
          </div>
        </div>

        <div class="section" *ngIf="accountType === 'leased-fleet'">
          <div class="left-section">
            <div class="title" translate>Default Rates</div>
          </div>

          <div class="right-section">
            <div class="field-group">
              <p class="rate-type" translate>
                Default rates will be pre-populated when you share jobs. But,
                don’t worry. You can override these rates and change them at
                any time.
              </p>
            </div>

            <div class="form-input-group">
              <div class="field-group field">
                <label translate>How would you like to setup the Rate?</label>
                <div class="large-buttons">
                  <div class="large-button" [ngClass]="{selected: account.oneRate}">
                    <label for="rate-setup-one-rate" translate>One Default Rate</label>
                    <input type="radio" id="rate-setup-one-rate" name="rate-setup" [(ngModel)]="account.oneRate" value="true"
                      (change)="rateSetupChanged(true)">
                  </div>
                  <div class="large-button" [ngClass]="{selected: !account.oneRate}">
                    <label for="rate-setup-multi-rate" translate>Rates By Truck Type</label>
                    <input type="radio" id="rate-setup-multi-rate" name="rate-setup" [(ngModel)]="account.oneRate"
                      value="false" (change)="rateSetupChanged(false)">
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!account.oneRate" class="form-input-group multi-rates-group">
              <div class="multi-rates" *ngFor="let multiplier of account.multipliers; let index = index; let first = first; let last = last">
                <div class="field-group field truck-type">
                  <label translate *ngIf="first">Truck Type</label>
                  <dropdown *ngIf="!truckTypesLoading && truckTypeOptions.length" [options]="truckTypeOptions"
                    [disabled]="account.multipliers[index].remove" [selectedOption]="account.multipliers[index].truckTypeName"
                    (onSelect)="selectTruckType($event, index)" title="{{ '-- All Trucks --' | translate }}">
                  </dropdown>
                </div>

                <div class="form-input-group field rate-type">
                  <div class="field-group">
                    <label translate *ngIf="first">Rate Type</label>
                    <dropdown [options]="rateTypeOptions" [disabled]="account.multipliers[index].remove"
                      [selectedOption]="rateTypeOptions[0]" (onSelect)="selectRateType($event, index)" title="-- Rate Type --">
                    </dropdown>
                  </div>
                </div>

                <div class="form-input-group field multiplier">
                  <div class="field-group">
                    <label translate *ngIf="first">&nbsp;</label>
                    <span class="percentage-mask">
                      <input name="multiplier-{{ index }}" numberInRange minValue=1 maxValue=100 [disabled]="account.multipliers[index].remove"
                        [(ngModel)]="account.multipliers[index].haulPercentage" />
                    </span>
                  </div>
                </div>

                <div class="form-input-group field add" *ngIf="last">
                  <div class="field-group">
                    <label translate *ngIf="first">&nbsp;</label>
                    <button (click)="addMultiplier(index)">
                      <i class="icon-plus"></i>
                    </button>
                  </div>
                </div>

                <div class="form-input-group field remove" *ngIf="!last">
                  <div class="field-group">
                    <label translate *ngIf="first">&nbsp;</label>
                    <!-- <button (click)="removeMultiplier(index, account.multipliers[index].remove)"><div>&#8722;</div></button> -->
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="account.oneRate" class="form-input-group">
              <div class="field-group field multiplier">
                <label translate>Rate</label>
                <span class="percentage-mask">
                  <input id="multiplier" name="multiplier" numberInRange minValue=1 maxValue=100 [(ngModel)]="account.multipliers && account.multipliers[0].haulPercentage">
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="section">
          <div class="left-section">
            <div class="title" translate>Notes</div>
          </div>

          <div class="right-section">
            <div class="field-group field">
              <label translate>Notes</label>
              <textarea class="section-notes" id="notes" name="notes" [(ngModel)]="account.notes"></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
