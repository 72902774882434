"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var core_2 = require("@ngx-translate/core");
// angular material
var material_1 = require("@angular/material");
// libraries
var moment = require("moment");
// types
var control_option_1 = require("../shared/control-option");
// components
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var report_type_dialog_component_1 = require("./report-type-dialog/report-type-dialog.component");
// services
var pay_sheet_service_1 = require("./pay-sheet.service");
var PaySheetsComponent = /** @class */ (function () {
    function PaySheetsComponent(dialog, route, router, translationService) {
        var _this = this;
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'date', title: this.translationService.instant('Report Date') },
            {
                key: 'approved',
                title: this.translationService.instant('Approved'),
                sortable: false,
            },
            {
                key: 'type',
                title: this.translationService.instant('Type'),
                sortable: false,
            },
        ];
        this.displayedColumns = ['date', 'approved', 'type'];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.reportType = 'all';
        this.controls = [
            new control_option_1.ControlOption({
                name: 'all',
                label: 'All',
                classes: 'all-toggle',
                selected: true,
            }),
            new control_option_1.ControlOption({
                name: 'internal',
                label: 'Internal',
                classes: 'internal-toggle',
            }),
            new control_option_1.ControlOption({
                name: 'external',
                label: 'External',
                classes: 'external-toggle',
            }),
        ];
        this.paySheetTableConfig = {
            hasHeader: true,
            service: pay_sheet_service_1.PaySheetService,
            serviceFunction: 'listReportTable',
            preferenceKey: 'PaySheetsComponent-PaySheetService',
            ignorePreferences: true,
            preferencesEnabled: false,
            query: {},
            newRecordRoute: ['driver-pay', moment().format('YYYY-MM-DD')],
            collectionTitle: this.translationService.instant('Pay Reports'),
            noResultsText: this.translationService.instant('a pay report'),
            sortBy: 'date',
            sortDirection: 'asc',
            menuOptions: [
                {
                    name: this.translationService.instant('Edit'),
                    action: 'edit',
                    link: true,
                    external: false,
                },
            ],
            newRecordModal: function () { return _this.createReport(); },
        };
        this.query = {};
        this.loading = true;
        this.errors = [];
        this.currentDate = moment().format('YYYY-MM-DD');
        this.allSubscriptionsToUnsubscribe = [];
    }
    PaySheetsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            _this.type = result.params['type'] || '';
            if (_this.type === 'received') {
                _this.paySheetTableConfig.slug = 'driver-pay/pay-sheets/approvals/';
                _this.paySheetTableConfig.hasNoResultsAction = false;
            }
            if (result.qparams['reportType']) {
                _this.reportType = result.qparams['reportType'];
                var controlOption = {
                    classes: _this.reportType + '-toggle',
                    disabled: false,
                    id: undefined,
                    label: _this.reportType.charAt(0).toUpperCase() +
                        _this.reportType.slice(1),
                    name: _this.reportType,
                    selected: true,
                };
                var mouseEvent = { target: { checked: true } };
                _this.onSelectToggle(controlOption, mouseEvent);
            }
        }));
    };
    PaySheetsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * @param  {[any, PaySheet]} event The event data passed in by the click event
     *
     * Navigates to the selected pay report based on the event data passed in
     */
    PaySheetsComponent.prototype.openReport = function (_a) {
        var e = _a[0], row = _a[1];
        if (this.type === 'received') {
            this.router.navigate(['driver-pay', row.id, row.type]);
        }
        else {
            this.router.navigate([
                'driver-pay',
                row.reportDate.split('T')[0],
                row.type,
            ]);
        }
    };
    /**
     * @param  {string} reportDate ISO formatted date
     * @returns {string} MMMM Do, YYYY formatted date string
     *
     * Formats a date for display in table views
     */
    PaySheetsComponent.prototype.formatReportDate = function (reportDate) {
        return moment(reportDate.split('T')[0], 'YYYY-MM-DD').format('MMMM Do, YYYY');
    };
    /**
     * @param  {ControlOption} control
     * @param  {MouseEvent} event
     * @returns void
     * This function is called on toggling the segmented controls: GPS, Trips, or Hourly
     */
    PaySheetsComponent.prototype.onSelectToggle = function (control, event) {
        var _this = this;
        var checkbox = event.target;
        this.controls = this.controls.map(function (c) {
            return Object.assign(c, {
                selected: c.name === control.name && checkbox.checked,
            });
        });
        this.reportType = control.name;
        if (this.paySheetTable) {
            setTimeout(function () {
                return _this.paySheetTable.updateUrl({ reportType: _this.reportType }, false);
            }, 500);
        }
        this.paySheetTableConfig = __assign({}, this.paySheetTableConfig, { query: { carrier_type: this.reportType } });
    };
    /**
     * @param  {'all' | 'internal' | 'external'} type Which kind of report will be preselected in the dialog
     * @returns void
     * Opens the ReportTypeDialog and preselects whatever type option is currently selected/viewed in the table
     */
    PaySheetsComponent.prototype.createReport = function (type) {
        var _this = this;
        if (type === void 0) { type = 'all'; }
        var dialog = this.dialog.open(report_type_dialog_component_1.ReportTypeDialogComponent, {
            width: '380px',
            data: { selectedOption: type },
        });
        dialog.componentInstance.callback = function (selectedOption) {
            _this.router.navigate([
                'driver-pay',
                _this.currentDate,
                selectedOption.value,
            ]);
        };
    };
    return PaySheetsComponent;
}());
exports.PaySheetsComponent = PaySheetsComponent;
