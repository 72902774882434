<div class="organization-container">
  <div class="organization-data">
    <h2 translate>Organization Details</h2>
    <form #editOrganization="ngForm" class="organization-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="name">
              <label class="required" translate>Organization Name</label>
              <input type="text" required [(ngModel)]="organization.name" name="organization_name" />
            </div>
          </div>
          <div class="row" *ngIf="organization.hasWorkOrder">
            <div class="dba-name">
              <label translate>Organization DBA Name</label>
              <input type="text" [(ngModel)]="organization.workOrderDbaName" name="organization_work_order_dba_name" />
            </div>
          </div>
          <div class="row" *ngIf="organization.hasWorkOrder">
            <div class="name">
              <label translate>Company Type</label>
              <select required name="organization_work_order_company_type" [(ngModel)]="organization.workOrderCompanyType">
                <option value="" selected disabled hidden>{{ 'Select Company Type' | translate }}</option>
                <option *ngFor="let companyType of companyTypeOptions" [value]="companyType.id">{{ companyType.name }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="street1">
              <label class="required" translate>Address</label>
              <input type="text" required [(ngModel)]="organization.street" name="organization_street" />
            </div>
            <div class="street2">
              <label translate>Address 2</label>
              <input type="text" [(ngModel)]="organization.address2" name="organization_address2" />
            </div>
          </div>
          <div class="row">
            <div class="city">
              <label class="required" translate>City</label>
              <input type="text" required [(ngModel)]="organization.city" name="organization_city" />
            </div>
            <div class="zipcode">
              <label class="required" translate>Zip</label>
              <input type="text" required [(ngModel)]="organization.zipcode" name="organization_zipcode" />
            </div>
            <div class="country">
              <label translate>Country</label>
              <select name="organization_country" [(ngModel)]="organization.country" (change)="selectCountry($event.target.value, 'organization', false)">
                <option value="" selected disabled hidden>{{ 'Select Country' | translate }}</option>
                <option *ngFor="let countryOption of countryOptions" [value]="countryOption.id" [selected]="countryOption.id === organization.country">{{ countryOption.name }}</option>
              </select>
            </div>
            <div class="state">
              <ng-container *ngIf="organization.country !== 'CA'">
                <label translate>State</label>
                <select name="state" [disabled]="loading" [(ngModel)]="organization.state">
                  <option value="" selected disabled hidden>{{ 'Select State' | translate }}</option>
                  <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id" [selected]="stateOption.id === organization.state">{{ stateOption.name }}</option>
                </select>
              </ng-container>
              <ng-container *ngIf="organization.country === 'CA'">
                <label translate>Province</label>
                <select name="state" [disabled]="loading" [(ngModel)]="organization.state">
                  <option value="" selected disabled hidden>{{ 'Select Province' | translate }}</option>
                  <option *ngFor="let stateOption of stateOptions" [value]="stateOption.id" [selected]="stateOption.id === organization.state">{{ stateOption.name }}</option>
                </select>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="phone-number">
              <label class="required" translate>Company Phone Number</label>
              <input type="text" required [(ngModel)]="organization.phoneNumber" name="organization_phone_number" />
            </div>
          </div>
          <div class="row">
            <div class="sender-email">
              <label class="required" translate>Sender Email</label>
              <input type="email" required [(ngModel)]="organization.senderEmail" name="organization_sender_email" />
            </div>
            <div class="sender-name">
              <label class="required" translate>Sender Name</label>
              <input type="text" required [(ngModel)]="organization.senderName" name="organization_sender_name" />
            </div>
          </div>

          <div class="row">
            <button class="btn btn-primary"
                    (click)="saveOrganization(); false"
                    [disabled]="loading || !editOrganization.form.valid || (editOrganization.form.pristine && !imageChanged)"
                    [ngClass]="{loading: loading}"
                    translate>
              Save Details
            </button>
          </div>
        </div>
        <div class="right-side">
          <div class="field-group logo-image">
            <div class="upload-image-dropzone" [ngClass]="{filled: organization.image || logoImage.file}">
              <img *ngIf="!logoImage.file && organization.image" [src]="organization.image" />
              <img *ngIf="logoImage.file" [src]="logoImage.dataUri" />
              <input type="file" name="file" id="file" class="inputfile" (change)="fileChange($event)" #fileInput/>
              <label class="upload-label" for="file">
                <i class="icon-upload"></i>
                <span *ngIf="!organization.image && !logoImage.file" translate>Drag a JPG or PNG under 25mb here or browse for an image to upload.</span>
                <span *ngIf="organization.image || logoImage.file" translate>Upload a new photo</span>
              </label>
            </div>

            <label translate>Your Organization's Logo</label>
          </div>
        </div>
      </div>
    </form>

    <h2 translate>Billing Details</h2>
    <form #editBilling="ngForm" class="billing-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="name">
              <label class="required" translate>Billing Contact Email</label>
              <input type="text" required [(ngModel)]="organization.billingEmail" name="organization_billing_email" />
            </div>
          </div>
          <div class="row">
            <div class="street1">
              <label class="required" translate>Billing Address</label>
              <input type="text" required [(ngModel)]="organization.billingAddress1" name="organization_billing_address1" />
            </div>
            <div class="street2">
              <label translate>Address 2</label>
              <input type="text" [(ngModel)]="organization.billingAddress2" name="organization_billing_address2" />
            </div>
          </div>
          <div class="row">
            <div class="city">
              <label class="required" translate>City</label>
              <input type="text" required [(ngModel)]="organization.billingCity" name="organization_billing_city" />
            </div>
            <div class="zipcode">
              <label class="required" translate>Zip</label>
              <input type="text" required [(ngModel)]="organization.billingZipcode" name="organization_billing_zipcode" />
            </div>
            <div class="country">
              <label translate>Country</label>
              <select name="organization_billing_country" [(ngModel)]="organization.billingCountry" (change)="selectCountry($event.target.value, 'billing', false)">
                <option value="" selected disabled hidden>{{ 'Select Country' | translate }}</option>
                <option *ngFor="let countryOption of countryOptions" [value]="countryOption.id" [selected]="countryOption.id === organization.billingCountry">{{ countryOption.name }}</option>
              </select>
            </div>
            <div class="state">
              <ng-container *ngIf="organization.billingCountry !== 'CA'">
                <label translate>State</label>
                <select name="state" [disabled]="loading" [(ngModel)]="organization.billingState">
                  <option value="" selected disabled hidden>{{ 'Select State' | translate }}</option>
                  <option *ngFor="let stateOption of billingStateOptions" [value]="stateOption.id" [selected]="stateOption.id === organization.billingState">{{ stateOption.name }}</option>
                </select>
              </ng-container>
              <ng-container *ngIf="organization.billingCountry === 'CA'">
                <label translate>Province</label>
                <select name="state" [disabled]="loading" [(ngModel)]="organization.billingState">
                  <option value="" selected disabled hidden>{{ 'Select Province' | translate }}</option>
                  <option *ngFor="let stateOption of billingStateOptions" [value]="stateOption.id" [selected]="stateOption.id === organization.billingState">{{ stateOption.name }}</option>
                </select>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="phone-number">
              <label class="required" translate>Billing Contact Number</label>
              <input type="text" required [(ngModel)]="organization.billingPhoneNumber" name="organization_billing_phone_number" />
            </div>
            <div class="invoice-number">
              <label translate>Starting Invoice Number</label>
              <input type="text" numberInRange minValue=1 [(ngModel)]="organization.startingInvoiceNumber" name="organization_starting_invoice_number" />
            </div>
          </div>

          <div class="row">
            <button class="btn btn-primary"
                    (click)="saveBilling(); false"
                    [disabled]="billingLoading || !editBilling.form.valid || editBilling.pristine"
                    [ngClass]="{loading: billingLoading}"
                    translate>
              Save Billing Details
            </button>
          </div>
        </div>
        <div class="right-side">
          <div class="row remittance">
            <div class="remittance-info">
              <label translate>Remittance Information</label>
              <textarea [(ngModel)]="organization.remittanceInfo" name="organization_remittance_info"></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>

    <h2 translate>Unique Billing ID</h2>
    <form #editUniqueBilling="ngForm" class="unique-billing-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="unique-billing-id">
              <label translate>Billing ID</label>
              <input id="unique-billing-id" name="unique-billing-id" [(ngModel)]="organization.uniqueBillingId" />
            </div>
          </div>

          <div class="row">
            <button class="btn btn-primary" (click)="saveUniqueBillingId(); false"
              [disabled]="uniqueBillingLoading || !editUniqueBilling.form.valid || editUniqueBilling.pristine"
              [ngClass]="{loading: uniqueBillingLoading}" translate>
              Save Unique Billing ID
            </button>
          </div>
        </div>
      </div>
    </form>

    <h2 translate>General Preferences</h2>
    <form #editPreferences="ngForm" class="preferences-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="loading-preferences">
              <div class="label-group">
                <label translate>Location Load and Unload Times</label>
              </div>
              <div class="field-group new-location-loading-times">
                <div class="note" translate>
                  Select the default loading and unloading times for New Locations.
                </div>
                <div class="loading-time">
                  <label translate>Default Loading Time</label>
                  <input type="text" [(ngModel)]="organization.defaultAverageLoadingTime" name="default_average_loading_time" />
                </div>
                <div class="unloading-time">
                  <label translate>Default Unloading Time</label>
                  <input type="text" [(ngModel)]="organization.defaultAverageUnloadingTime" name="default_average_unloading_time" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="automatic-shift-end">
              <div class="label-group">
                <label translate>Automatic Shift End</label>
              </div>
              <div class="note" translate>
                Set the max shift duration for drivers. Shifts will be automatically ended when the set time is exceeded.
              </div>
              <div class="automatic-shift-end">
                <label translate>Shift Duration Max</label>
                <div class="shift-duration">
                  <input
                    [disabled]="true || !organization.features.shiftMonitorAutoClose"
                    [ngModel]="organization.features.shiftMonitorAutoCloseInterval | secondsToPrettyTime"
                    (ngModelChange)="onShiftMonitorIntervalChange($event)"
                    name="max_shift_duration"
                  />
                  <label class="auto-shift" attr.data-label="{{ 'Enable Automatic Shift End' | translate }}" for="auto-shift-end">
                    <input
                      type="checkbox"
                      disabled
                      id="auto-shift-end"
                      name="auto-shift-end"
                      value="auto-shift-end"
                      [(ngModel)]="organization.features.shiftMonitorAutoClose"
                      (change)="onAutoShiftEndChange($event)"
                    />
                  </label>
                </div>
                <div class="enable-auto-shift-end">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="job-preferences">
              <div class="label-group">
                <label translate>Job Preferences</label>
              </div>
              <div *ngIf="organization.features && organization.features.checkinOptions" class="field-group loading-options">
                <div class="note" translate>
                  Select preferred <b>loading</b> options.
                </div>
                <div class="checkbox-buttons">
                  <div class="config-option">
                    <label>{{ 'Ticket Number' | translate }}</label>
                    <mat-select name="ticket-number" [(ngModel)]="organization.features.checkinOptions.ticketNumberConfig" 
                      (selectionChange)="selectCheckinOption('ticketNumberConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  
                  <div class="config-option">
                    <label>{{ 'Ticket Photo' | translate }}</label>
                    <mat-select name="ticket-photo" [(ngModel)]="organization.features.checkinOptions.ticketImageConfig" 
                      (selectionChange)="selectCheckinOption('ticketImageConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  
                  <div class="config-option">
                    <label>{{ 'Signature Image' | translate }}</label>
                    <mat-select name="signature-image" [(ngModel)]="organization.features.checkinOptions.signatureImageConfig"
                      (selectionChange)="selectCheckinOption('signatureImageConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  
                  <div class="config-option">
                    <label>{{ 'Active Tracking' | translate }}</label>
                    <mat-select name="active-tracking" [(ngModel)]="organization.features.checkinOptions.activeTrackingConfig" 
                      (selectionChange)="selectCheckinOption('activeTrackingConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id" >
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  
                  <div class="config-option">
                    <label>{{ 'Weight' | translate }}</label>
                    <mat-select name="weight" [(ngModel)]="organization.features.checkinOptions.weightConfig" 
                      (selectionChange)="selectCheckinOption('weightConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                  
                  <div class="config-option">
                    <label>{{ 'QR Code' | translate }}</label>
                    <mat-select name="qr" [(ngModel)]="organization.features.checkinOptions.qrConfig" 
                      (selectionChange)="selectCheckinOption('qrConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
              <div *ngIf="organization.features && organization.features.checkoutOptions" class="field-group unloading-options">
                <div class="note" translate>
                  Select preferred <b>unloading</b> options.
                </div>
                <div class="checkbox-buttons">
                  <div class="config-option">
                    <label>{{ 'Ticket Number' | translate }}</label>
                    <mat-select [(ngModel)]="organization.features.checkoutOptions.ticketNumberConfig" name="checkout-ticket-number"
                      (selectionChange)="selectCheckoutOption('ticketNumberConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
        
                  <div class="config-option">
                    <label>{{ 'Ticket Photo' | translate }}</label>
                    <mat-select [(ngModel)]="organization.features.checkoutOptions.ticketImageConfig" name="checkout-ticket-photo"
                      (selectionChange)="selectCheckoutOption('ticketImageConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
        
                  <div class="config-option">
                    <label>{{ 'Signature Image' | translate }}</label>
                    <mat-select [(ngModel)]="organization.features.checkoutOptions.signatureImageConfig" name="checkout-signature-image"
                      (selectionChange)="selectCheckoutOption('signatureImageConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
        
                  <div class="config-option" *ngIf="user.organization && user.organization.activeTrackingEnabled">
                    <label>{{ 'Active Tracking' | translate }}</label>
                    <mat-select [(ngModel)]="organization.features.checkoutOptions.activeTrackingConfig" name="checkout-active-tracking"
                      (selectionChange)="selectCheckoutOption('activeTrackingConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>

                  <div class="config-option">
                    <label>{{ 'Weight' | translate }}</label>
                    <mat-select [(ngModel)]="organization.features.checkoutOptions.weightConfig" name="checkout-weight"
                      (selectionChange)="selectCheckoutOption('weightConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
        
                  <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
                    <label>{{ 'QR Code' | translate }}</label>
                    <mat-select [(ngModel)]="organization.features.checkoutOptions.qrConfig" name="qr"
                      (selectionChange)="selectCheckoutOption('qrConfig', $event)">
                      <mat-option *ngFor="let option of configOptions" [value]="option.id">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="timing-preferences">
              <div class="label-group">
                <label translate>Default Timing Preferences</label>
              </div>
              <div class="field-group default-times">
                <div class="note" translate>
                  Set the default timing values.<br /><br />
                </div>
              </div>
              <div class="field-group">
                <label translate>Default Round Trip Time</label>
                <div class="input-group">
                  <input type="number" min="0" [(ngModel)]="organization.defaultRoundTripMinutes" name="default_round_trip_minutes" />
                  <div class="add-on" translate>Minutes</div>
                </div>
              </div>
              <div class="field-group">
                <label translate>Default Yard Prep Time</label>
                <div class="input-group">
                  <input type="number" min="0" [(ngModel)]="organization.defaultYardPrepMinutes" name="default_yard_prep_minutes" />
                  <div class="add-on" translate>Minutes</div>
                </div>
              </div>
              <div class="field-group">
                <label translate>Default Yard Buffer Time</label>
                <div class="input-group">
                  <input type="number" min="0" [(ngModel)]="organization.defaultYardBufferMinutes" name="default_yard_buffer_minutes" />
                  <div class="add-on" translate>Minutes</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="haul-preference">
              <div class="field-group haul-type">
                <div class="note" translate>
                  Select Haul Type.
                </div>
                <div class="country">
                  <label translate>Default Haul Type</label>
                  <select name="organization_default_haul_type" [(ngModel)]="organization.defaultHaulRateType" (change)="selectHaulType($event.target.value)">
                    <option value="" selected disabled hidden>{{ 'Select Haul Type' | translate }}</option>
                    <option *ngFor="let haulOption of haulOptions" [value]="haulOption.toLowerCase()" [selected]="haulOption.toLowerCase() === haulOption.toLowerCase()">{{ haulOption }}</option>
                  </select>
                </div>
              </div>

              <div class="field-group">
                <label translate>Default Hourly Rate</label>
                <div class="input-group">
                  <input 
                    type="number" 
                    min="0"
                    step='0.01' 
                    [value]="organization.defaultHaulRate" 
                    (input)="defaultHaulRateChange($event)" 
                    name="default_haul_rate" />
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <button class="btn btn-primary" (click)="saveOrganization(); false"
              [disabled]="loading || !editPreferences.form.valid || editPreferences.pristine"
              [ngClass]="{loading: loading}" translate>
              Save Preferences
            </button>
          </div>
        </div>
        <div class="right-side"></div>
      </div>
    </form>

    <h2 translate>Dispatching Preferences</h2>
    <form #editDispatchingPreferences="ngForm" class="preferences-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="job-based-tags-preferences">
              <div class="label-group">
                <label translate>Pre-select Markets</label>
              </div>
              <div class="field-group new-connections">
                <div class="note" translate>
                  Job market selection for filterings available drivers during dispatch.
                </div>
                <div class="radio-buttons" *ngIf="preference && preference.blob">
                  <label attr.data-label="{{ 'Do not pre-select' | translate }}" for="all-day">
                    <input type="radio" id="no-pre-select" name="no-pre-select" value="no-pre-select"
                      [(ngModel)]="preference.blob['availableDrivers[jobBasedTagSelection]']" />
                  </label>
                  <label attr.data-label="{{ 'Pre-select' | translate }}" for="pre-select">
                    <input type="radio" id="pre-select" name="pre-select" value="pre-select"
                      [(ngModel)]="preference.blob['availableDrivers[jobBasedTagSelection]']" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <button class="btn btn-primary" (click)="savePreferences(); false"
              [disabled]="loading || !editDispatchingPreferences.form.valid || editDispatchingPreferences.pristine"
              [ngClass]="{loading: loading}" translate>
              Save Preferences
            </button>
          </div>
        </div>
        <div class="right-side"></div>
      </div>
    </form>

    <h2 translate>Enabled Features</h2>
    <form #editFeatures="ngForm" class="features-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row feature" *ngIf="!organization.enabledFeatures" translate>
            No experimental features enabled.
          </div>
          <div class="row feature" *ngFor="let feature of organization.enabledFeatures">
            <label translate>{{ feature | titleCase }}</label>
            <div class="field-group feature-state">
              <label class="toggle-label">
                <input type="checkbox" name="" checked="true" disabled="true" class="toggle-input">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="right-side">
          <button class="btn btn-primary"
                  (click)="updateAccount(); false"
                  [disabled]="accountUpdateLoading"
                  [ngClass]="{loading: accountUpdateLoading}"
                  translate>
            Update Account
          </button>
        </div>
      </div>
    </form>

    <h2 translate>Compliance</h2>
    <form #editCompliance="ngForm" class="compliance-form" *ngIf="organization">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="input-field width-half">
              <label translate for="org-dot-number">DOT#</label>
              <input name="org-dot-number" type="text" [(ngModel)]="organization.dotNumber" placeholder="{{ 'Enter DOT#' | translate }}">
            </div>
            <div class="input-field width-half">
              <label translate for="org-carrier-number">Motor Carrier#</label>
              <input name="org-carrier-number" type="text" [(ngModel)]="organization.mcNumber" placeholder="{{ 'Enter Motor Carrier#' | translate }}">
            </div>
          </div>
          <div class="row">
            <div class="input-field width-half">
              <label translate for="org-dir-number">DIR#</label>
              <input name="org-dir-number" type="text" [(ngModel)]="organization.dirNumber" placeholder="{{ 'Enter DIR#' | translate }}">
            </div>
            <div class="input-field width-half">
              <label translate for="org-ein-number">EIN#</label>
              <input name="org-ein-number" type="text" [(ngModel)]="organization.einNumber" placeholder="{{ 'Enter EIN#' | translate }}">
            </div>
          </div>
          <div class="row">
            <div class="compliance-questions">
              <p class="status">
                Compliance Questions<br/>
                <span class="label" [ngClass]="{ 'answered': organization.isAb5Compliant }">
                  {{ (organization.isAb5Compliant ? 'Answered' : 'Not Answered') | translate }}
                </span>
              </p>
              <button class="btn btn-primary-alt" (click)="openAB5Questions()" translate>{{ (organization.isAb5Compliant ? 'Review' : 'Answer') | translate }} Questions</button>
            </div>
          </div>
          <div class="row">
            <button class="btn btn-primary"
                    (click)="saveOrganization(); false"
                    [disabled]="!editCompliance.form.valid"
                    translate>
              Save Compliance
            </button>
          </div>
        </div>
        <div class="right-side">
          <div class="row">
            <div class="input-field">
              <label translate for="org-insurance">Insurance</label>
              <ng-container *ngIf="!organization.insuranceDocument; else insuranceDocumentPreview">
                <input name="org-insurance"
                       type="file" [ngClass]="{'loading': documentLoading}"
                       accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                       (change)="uploadAB5Document(insurance, $event, 'insuranceDocument')">
              </ng-container>
              <ng-template #insuranceDocumentPreview>
                <div class="document-preview">
                  <a class="preview" [href]="organization.insuranceDocument" translate>View Document</a>
                  <button class="btn btn-default reupload" (click)="removeAB5Document('insurance_document', insurance, 'insuranceDocument')" translate>Reupload</button>
                </div>
              </ng-template>
            </div>
            <div class="input-field">
              <label translate>Insurance Expiration Date</label>
              <date-range-picker [selectedDates]="[organization.insuranceExpirationDate]"
                                 [config]="{ decreaseControl: false, increaseControl: false }"
                                 (dateChanged)="setExpirationDate($event, 'insurance')"></date-range-picker>
            </div>
          </div>
          <div class="row">
            <div class="input-field">
              <label translate for="org-overweight-permit">Overweight Permit</label>
              <ng-container *ngIf="!organization.overweightPermit; else overweightPermitPreview">
                <input name="org-overweight-permit"
                       type="file" [ngClass]="{'loading': documentLoading}"
                       accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                       (change)="uploadAB5Document(overweightPermit, $event, 'overweightPermit')">
              </ng-container>
              <ng-template #overweightPermitPreview>
                <div class="document-preview">
                  <a class="preview" [href]="organization.overweightPermit" translate>View Document</a>
                  <button class="btn btn-default reupload" (click)="removeAB5Document('overweight_permit', overweightPermit, 'overweightPermit')" translate>Reupload</button>
                </div>
              </ng-template>
            </div>
            <div class="input-field">
              <label translate>Permit Expiration Date</label>
              <date-range-picker [selectedDates]="[organization.overweightPermitExpirationDate]"
                                  [config]="{ decreaseControl: false, increaseControl: false }"
                                  (dateChanged)="setExpirationDate($event, 'overweightPermit')"></date-range-picker>
            </div>
          </div>
          <div class="row">
            <div class="input-field">
              <label translate for="org-business-license">Business License</label>
              <ng-container *ngIf="!organization.businessLicense; else businessLicensePreview">
                <input name="org-business-license"
                       type="file" [ngClass]="{'loading': documentLoading}"
                       accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                       (change)="uploadAB5Document(businessLicense, $event, 'businessLicense')">
              </ng-container>
              <ng-template #businessLicensePreview>
                <div class="document-preview">
                  <a class="preview" [href]="organization.businessLicense" translate>View Document</a>
                  <button class="btn btn-default reupload" (click)="removeAB5Document('business_license', businessLicense, 'businessLicense')" translate>Reupload</button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="errors" class="error-container">
  <notification *ngFor="let error of errors" translate>{{error}}</notification>
</div>