<div class="container .connections-list">
  <div class="actions-wrapper">
    <fancy-table #connectionTable [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(availableFilters)]="availableFilters"
                 [(query)]="query"
                 [(filters)]="appliedFilters"
                 [(search)]="search"
                 (columnChange)="columnsChanged($event)"
                 [config]="connectionTableConfig">
      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'name'">
            <div class="data"> {{ row.organization && row.organization.name || '-' }}</div>
            <div class="organization-type">{{ row.types || '&nbsp;' }}</div>
            <div *ngIf="row.status === 'suspended'">
              <div class="label-warn">{{ row.status }}</div>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'location'">
            <div class="data">{{ row.location || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'identifiers'">
            <div class="data">{{ row.identifiers || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'primary-contact'">
            <div class="data">{{ row.organization && row.organization.primaryContact &&
              row.organization.primaryContact.firstName || '&nbsp;' }}
              {{ row.organization && row.organization.primaryContact &&
              row.organization.primaryContact.lastName || '&nbsp;' }}
            </div>
          </div>
          <div class="value email" *ngSwitchCase="'primary-contact-email'">
            <div class="data">{{ row.organization && row.organization.primaryContact &&
              row.organization.primaryContact.email || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'billing-id'">
            <div class="data">{{ row.organization && row.uniqueBillingId || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'action'">
            <action-menu icon="icon-more">
              <ng-container *ngFor="let option of row.filterOptions(connectionTableConfig.menuOptions)">
                <a mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
              </ng-container>
            </action-menu>
          </div>
          <div class="value" *ngSwitchDefault>{{ value }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>Connections</div>
      </div>
      <div applied-filter-content>
        <applied-filters [(filters)]="appliedFilters" [(search)]="search" (filtersChange)="filtersModified($event)"></applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle #columnToggle
                     [(availableColumns)]="availableColumns"
                     [(displayedColumns)]="displayedColumns"></column-toggle>
      <filters-panel [(availableFilters)]="availableFilters" [(appliedFilters)]="appliedFilters" [(search)]="search"></filters-panel>
      <!-- add new product dialog-->
      <a mat-icon-button color="accent" class="add-button" (click)="openDialog('invite')">
        <mat-icon>add_circle</mat-icon>
      </a>
  </div>
</div>
