<div class="dialog">
  <h2 class="title">{{ attributes.title }}</h2>

  <p class="content-body">{{ attributes.body }}</p>

  <div class="dialog-body">
    <label>What is the reason for voiding this ticket?</label>
    <textarea class="reason" [maxLength]="255"></textarea>
    <div class="check">
      <input type="checkbox" [checked]="createCopy" (change)="createCopy = $event.target.checked ? true : false"
      />
      <label attr.data-label="{{ 'Create a copy of voided ticket' | translate }}" for="voided_ticket"></label>
    </div>
  </div>

  <div class="dialog-actions">
    <button class="btn btn-cancel close" (click)="confirmDialog.close(false)">{{ attributes.close }}</button>
    <button class="btn btn-warning accept" (click)="onVoidButtonClicked()">{{ attributes.accept }}</button>
  </div>

</div>
