import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ExpensesRoutingModule } from './expenses-routing.module';

import { ExpensesComponent } from './expenses.component';
import { ExpenseSurchargesComponent } from './expense-surcharges.component';
import { ExpensePunchCardsComponent } from './expense-punch-cards.component';
import { ExpenseTripsComponent } from './expense-trips.component';
import { EditExpenseComponent } from './edit-expense.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ExpensesRoutingModule
  ],
  declarations: [
    ExpensesComponent,
    ExpenseSurchargesComponent,
    ExpensePunchCardsComponent,
    ExpenseTripsComponent,
    EditExpenseComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class ExpensesModule { }
