"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var job_event_1 = require("../job-events/job-event");
var MobileJobOverviewComponent = /** @class */ (function () {
    function MobileJobOverviewComponent() {
        this.notesOpen = false;
    }
    return MobileJobOverviewComponent;
}());
exports.MobileJobOverviewComponent = MobileJobOverviewComponent;
