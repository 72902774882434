<notification *ngFor="let error of errors" translate>{{ error }}</notification>

<div class="container driver-shifts-container">
  <div class="actions-wrapper">
    <fancy-table
      #shiftsFancyTable
      [availableColumns]="availableColumns"
      [displayedColumns]="displayedColumns"
      [(filters)]="appliedFilters"
      [(search)]="search"
      [config]="tableConfig"
      (selectionChanged)="selector($event)"
      (columnChange)="columnsChanged($event)"
      (onDataLoaded)="onDataLoaded($event)"
    >
      <ng-template
        #columnTemplates
        let-row="row"
        let-column="column"
        let-value="value"
      >
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'driver'">
            <div class="data">
              {{ (row.driver && row.driver.name) || '&nbsp;' }}
            </div>
          </div>
          <div class="value shift-date-picker" *ngSwitchCase="'shiftStartTime'">
            <div class="data">
              <date-time-picker-inline
                [value]="row.startTime"
                (dateTimeChange)="editTime(row, 'start', $event)"
              >
              </date-time-picker-inline>
            </div>
          </div>
          <div class="value shift-date-picker" *ngSwitchCase="'shiftEndTime'">
            <div class="data">
              <date-time-picker-inline
                [value]="row.endTime"
                (dateTimeChange)="editTime(row, 'end', $event)"
              >
              </date-time-picker-inline>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'adjustment'">
            <div class="data">
              <input
                type="number"
                value="{{ row.adjustment }}"
                (blur)="editAdjustment(row, $event.target.value)"
              />
            </div>
          </div>
          <!-- shift duration -->
          <div class="value" *ngSwitchCase="'shiftDuration'">
            <div class="data">
              <p>{{ row.duration && row.duration() }}</p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'confirmedTruck'">
            <div class="data">
              <p>
                {{ row.driver && row.driver.truck && row.driver.truck.name }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'assignedTruck'">
            <div
              class="data"
              *ngIf="row.truckAssignments && row.truckAssignments[0]"
            >
              <p>
                {{
                  row.truckAssignments[0] &&
                    row.truckAssignments[0].truck &&
                    row.truckAssignments[0].truck.name
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'lunchBreakStartTime'">
            <div class="data" *ngIf="row.lunchBreaks && row.lunchBreaks[0]">
              <p class="bold">
                {{ row.lunchBreaks[0].start | moment : 'h:mm a' }}
              </p>
              <label>{{
                row.lunchBreaks[0].start | moment : 'MMM DD, YYYY'
              }}</label>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'lunchBreakEndTime'">
            <div class="data" *ngIf="row.lunchBreaks && row.lunchBreaks[0]">
              <p class="bold">
                {{ row.lunchBreaks[0].end | moment : 'h:mm a' }}
              </p>
              <label>{{
                row.lunchBreaks[0].end | moment : 'MMM DD, YYYY'
              }}</label>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'lunchBreakDuration'">
            <div class="data" *ngIf="row.lunchBreaks && row.lunchBreaks[0]">
              <!-- <p>{{ row.lunchBreaks[0].duration | formattedSeconds }}</p> -->
              <p>
                {{
                  row.lunchBreaks[0].start
                    | formattedDuration : row.lunchBreaks[0].end
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'vehicleMileageStart'">
            <div
              class="data"
              *ngIf="
                row.shiftCheckups &&
                row.shiftCheckups[row.shiftCheckups.length - 1]
              "
            >
              <p>
                {{ row.shiftCheckups[row.shiftCheckups.length - 1].mileage }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'vehicleMileageEnd'">
            <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[0]">
              <p>{{ row.shiftCheckups[0].mileage }}</p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'totalMileage'">
            <div
              class="data"
              *ngIf="
                row.shiftCheckups &&
                row.shiftCheckups[0] &&
                row.shiftCheckups[row.shiftCheckups.length - 1]
              "
            >
              <p>
                {{
                  row.shiftCheckups[0].mileage -
                    row.shiftCheckups[row.shiftCheckups.length - 1].mileage
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'engineHoursStart'">
            <div
              class="data"
              *ngIf="
                row.shiftCheckups &&
                row.shiftCheckups[row.shiftCheckups.length - 1]
              "
            >
              <p>
                {{
                  row.shiftCheckups[row.shiftCheckups.length - 1].engineHours
                    | formattedSeconds : 'hours'
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'engineHoursEnd'">
            <div class="data" *ngIf="row.shiftCheckups && row.shiftCheckups[0]">
              <p>
                {{
                  row.shiftCheckups[0].engineHours | formattedSeconds : 'hours'
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'totalEngineHours'">
            <div
              class="data"
              *ngIf="
                row.shiftCheckups &&
                row.shiftCheckups[0] &&
                row.shiftCheckups[row.shiftCheckups.length - 1]
              "
            >
              <p>
                {{
                  row.shiftCheckups[0].engineHours -
                    row.shiftCheckups[row.shiftCheckups.length - 1].engineHours
                    | formattedSeconds : 'hours'
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'vehicleBreakdownStart'">
            <div class="data" *ngIf="row.breakDowns && row.breakDowns[0]">
              <p class="bold">
                {{ row.breakDowns[0].start | moment : 'h:mm a' }}
              </p>
              <label>{{
                row.breakDowns[0].start | moment : 'MMM DD, YYYY'
              }}</label>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'vehicleBreakdownEnd'">
            <div class="data" *ngIf="row.breakDowns && row.breakDowns[0]">
              <p class="bold">
                {{ row.breakDowns[0].end | moment : 'h:mm a' }}
              </p>
              <label>{{
                row.breakDowns[0].end | moment : 'MMM DD, YYYY'
              }}</label>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'vehicleBreakdownDuration'">
            <div class="data" *ngIf="row.breakDowns && row.breakDowns[0]">
              <!-- <p>{{ row.breakDowns[0].duration | formattedSeconds }}</p> -->
              <p>
                {{
                  row.breakDowns[0].start
                    | formattedDuration : row.breakDowns[0].end
                }}
              </p>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'autoClosed'">
            <div class="data">
              <p *ngIf="row.autoClosed" translate>Yes</p>
              <p *ngIf="!row.autoClosed" translate>No</p>
            </div>
          </div>
          <div class="value" *ngSwitchDefault>{{ value }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>Shifts</div>
      </div>
      <div applied-filter-content>
        <applied-filters
          [(filters)]="appliedFilters"
          [(search)]="search"
        ></applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle
        #columnToggle
        [(availableColumns)]="availableColumns"
        [(displayedColumns)]="displayedColumns"
      >
      </column-toggle>
      <filters-panel
        [(appliedFilters)]="appliedFilters"
        [customFilter]="true"
        (openCustomFilter)="openFilters()"
        [search]="search"
        (searchChange)="search = $event"
      ></filters-panel>
      <dropdown
        title="Actions"
        [options]="multipleActionDropdownOptions"
        [changeTitle]="false"
        (onSelect)="setSelectedBulkAction($event)"
        class="actions-dropdown"
      ></dropdown>
      <div class="add-new-button">
        <button class="btn btn-primary" (click)="openAddShift()">
          <span class="icon-plus"></span> {{ 'Create Shift' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
