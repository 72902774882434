"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./widget.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("./widget.component");
var styles_WidgetComponent = [i0.styles];
var RenderType_WidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetComponent, data: {} });
exports.RenderType_WidgetComponent = RenderType_WidgetComponent;
function View_WidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "no-padding": 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "no-padding": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "widget wide-", _co.widget.size[0], " tall-", _co.widget.size[1], ""); var currVal_1 = _ck(_v, 3, 0, _co.noPadding); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, _co.noPadding); var currVal_3 = i1.ɵinlineInterpolate(2, "widget wide-", _co.widget.size[0], " tall-", _co.widget.size[1], ""); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
exports.View_WidgetComponent_0 = View_WidgetComponent_0;
function View_WidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "widget", [], null, null, null, View_WidgetComponent_0, RenderType_WidgetComponent)), i1.ɵdid(1, 114688, null, 0, i5.WidgetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_WidgetComponent_Host_0 = View_WidgetComponent_Host_0;
var WidgetComponentNgFactory = i1.ɵccf("widget", i5.WidgetComponent, View_WidgetComponent_Host_0, { size: "size", noPadding: "noPadding" }, {}, ["[widget-header]", "[widget-content]"]);
exports.WidgetComponentNgFactory = WidgetComponentNgFactory;
