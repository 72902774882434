"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".action-bar-content[_ngcontent-%COMP%]{display:flex;width:100%;height:100%;flex-direction:row;align-items:center;justify-content:space-between;box-shadow:0 1px 1px 0 rgba(0,0,0,.07);position:relative;z-index:1}.action-bar-content[_ngcontent-%COMP%]   .custom-subnav[_ngcontent-%COMP%]{margin-bottom:-36px;position:relative}.action-bar-content[_ngcontent-%COMP%]   .custom-subnav[_ngcontent-%COMP%]   .links[_ngcontent-%COMP%]{margin:0}"];
exports.styles = styles;
