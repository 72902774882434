"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var shared_1 = require("../../../shared");
var GenerateReportDialogComponent = /** @class */ (function () {
    function GenerateReportDialogComponent(dialog, dialogRef, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.errors = [];
        this.durationDropdownConfig = { nameProperty: 'name' };
        this.durationOptions = [
            {
                value: 'all-driver-items',
                name: 'All Driver Reported Items'
            },
            {
                value: 'each-driver-item',
                name: 'Each Driver Reported Item'
            },
            {
                value: 'all-geo-trips',
                name: 'All GPS Reported Items'
            },
            {
                value: 'each-geo-trip',
                name: 'Each GPS Reported Item'
            }
        ];
        this.selectedOption = this.durationOptions.find(function (option) { return (data.selectedOption === option.value); });
    }
    GenerateReportDialogComponent.prototype.ngOnInit = function () {
        if (this.selectedOption && this.durationSelect) {
            this.durationSelect.selectedOption = this.selectedOption;
        }
    };
    GenerateReportDialogComponent.prototype.submit = function () {
        this.callback(this.selectedOption);
        this.dialogRef.close();
    };
    return GenerateReportDialogComponent;
}());
exports.GenerateReportDialogComponent = GenerateReportDialogComponent;
