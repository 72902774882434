"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".work-orders-container[_ngcontent-%COMP%]{padding:30px;position:relative}.work-orders-container[_ngcontent-%COMP%]   .numeric[_ngcontent-%COMP%]{justify-content:center}.work-orders-container[_ngcontent-%COMP%]     .mat-column-detail .data{width:100%;padding:0;margin:0 auto}.work-orders-container[_ngcontent-%COMP%]     .mat-column-confirmed-trucks, .work-orders-container[_ngcontent-%COMP%]     .mat-column-dispatched, .work-orders-container[_ngcontent-%COMP%]     .mat-column-num-trucks{justify-content:center}.work-orders-container[_ngcontent-%COMP%]     .mat-column-confirmed-trucks .value, .work-orders-container[_ngcontent-%COMP%]     .mat-column-dispatched .value, .work-orders-container[_ngcontent-%COMP%]     .mat-column-num-trucks .value{width:100%;text-align:center}.work-orders-container[_ngcontent-%COMP%]     .mat-column-actions{width:50px}  .work-orders-container .actions>*{height:100%;text-decoration:none;color:#000}  .work-orders-container .actions>.link{margin-top:4px}    .work-orders-container .actions>*>a{text-decoration:none;color:#000}  .mat-tab-header{background:#fff;border-radius:4px 4px 0 0;border:1px solid #e6e9ec}"];
exports.styles = styles;
