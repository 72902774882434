<div class="pagination">
  <ul *ngIf="!hideSinglePage || totalPages && totalPages > 1" class="pagination-links">
    <li [ngClass]="{disabled: currentPage === 1}">
      <a (click)="setPage(currentPage - 1)"><i class="icon-previous-arrow"></i></a>
    </li>
    <li *ngFor="let page of pagesCondensed" [ngClass]="{active: currentPage === page, ellipsis: page === '...'}">
      <a (click)="setPage(page)">{{ page }}</a>
    </li>
    <li [ngClass]="{disabled: currentPage === totalPages}">
      <a (click)="setPage(currentPage + 1)"><i class="icon-next-arrow"></i></a>
    </li>
  </ul>
</div>
