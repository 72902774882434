import { Component, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';

import { PayRecord } from '../../../pay-record';
import { JobEvent } from '../../../../job-events/job-event';
import { Assignment } from '../../../../assignments/assignment';
import { DurationData, PaySheetService } from '../../../pay-sheet.service';
import { TimelineRowData } from '../../../../shared';

type PayRecordDurations = {
  jobEvent: JobEvent,
  assignment: Assignment,
  originalAmount: string,
  modifiedAmount: string,
  total: number,
  adjustment: number,
  notes: string,
  payLines: PayDurationData[]
};

type PayDurationData = {
  originalDuration: DurationData,
  modifiedDuration: DurationData,
  // note: need to remove this property and references to it
  duration?: number
};

@Component({
  selector: 'ruckit-driver-pay-timeline-edit-panel',
  templateUrl: './driver-pay-timeline-edit-panel.component.html',
  styleUrls: ['./driver-pay-timeline-edit-panel.component.scss']
})

export class DriverPayTimelineEditPanelComponent {
  @Input() readOnly = false;
  @Output() editsMade: EventEmitter<TimelineRowData> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() reset: EventEmitter<boolean> = new EventEmitter();

  payRecordData: PayRecordDurations = <PayRecordDurations>{
    jobEvent: <JobEvent>{},
    total: 0,
    adjustment: 0,
    notes: '',
    payLines: []
  };

  selectedRowValue: PayRecord;
  @Output() selectedRowChange: EventEmitter<PayRecord> = new EventEmitter();
  @Input() get selectedRow() { return this.selectedRowValue; }
  set selectedRow(row) {
    this.selectedRowValue = row;
    this.selectedRowChange.emit(row);
  }

  constructor (private paySheetService: PaySheetService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRow && changes.selectedRow.currentValue) {
      this.setupEditPanel(changes.selectedRow.currentValue);
    }
  }

  setupEditPanel(rowData: PayRecord, edits = false) {
    const selectedRow = rowData;
    let payLineTotal = 0;
    this.payRecordData = {
      jobEvent: <JobEvent>{},
      assignment: <Assignment>{},
      total: 0,
      originalAmount: '',
      modifiedAmount: '',
      adjustment: 0,
      notes: '',
      payLines: []
    };
    if (selectedRow.data && selectedRow.data.rowData) {
      this.payRecordData.adjustment = selectedRow.data.rowData.payAdjustmentTotal;
      this.payRecordData.notes = selectedRow.data.rowData.notes;
      selectedRow.data.rowData.payLines.forEach(rowDataObj => {
        let convertedDurationObj: PayDurationData;
        convertedDurationObj = {
          originalDuration: this.paySheetService.convertToDuration(rowDataObj.startDatetime, rowDataObj.endDatetime),
          modifiedDuration: this.paySheetService.convertToDuration(rowDataObj.startDatetime, rowDataObj.endDatetime)
        };
        payLineTotal += convertedDurationObj.originalDuration.duration;
        this.payRecordData.payLines.push(convertedDurationObj);
      });
      if (selectedRow.data.assignment.jobevent) {
        this.payRecordData.jobEvent = selectedRow.data.assignment.jobevent;
        this.payRecordData.assignment = selectedRow.data.assignment;
        if (this.payRecordData.jobEvent.haulType === 'hour') {
          this.payRecordData.originalAmount =
            this.payRecordData.modifiedAmount =
              Math.floor(payLineTotal / 60) + 'h' + (payLineTotal % 60) + 'm';
          this.payRecordData.total = (payLineTotal / 60) * Number(this.payRecordData.jobEvent.haulRate);
        } else if (this.payRecordData.jobEvent.haulType === 'weight') {
          this.payRecordData.originalAmount =
            this.payRecordData.modifiedAmount =
              this.payRecordData.assignment.estimatedNumberOfTons + ' tons';
          this.payRecordData.total = Number(this.payRecordData.assignment.estimatedNumberOfTons) *
                                     Number(this.payRecordData.jobEvent.haulRate);
        }
      }
    }
    if (edits) { this.editsMade.emit(this.selectedRow.data.rowData); }
  }

  convertDurationStringToMinutes(durationString: string): number {
    const durationSplitString = durationString.split(/[hm]/);
    return (60 * Number(durationSplitString[0])) + Number(durationSplitString[1]);
  }

  updatePayDuration() {
    if (this.payRecordData.jobEvent.haulType === 'hour') {
      let changedMinutes = this.convertDurationStringToMinutes(this.payRecordData.originalAmount) -
                           this.convertDurationStringToMinutes(this.payRecordData.modifiedAmount);
      this.payRecordData.originalAmount = this.payRecordData.modifiedAmount;
      let payLineIndex = this.payRecordData.payLines.length - 1;
      if (changedMinutes > 0) {
        while (changedMinutes > 0) {
          if (this.payRecordData.payLines[payLineIndex].modifiedDuration.duration < changedMinutes) {
            changedMinutes -= this.payRecordData.payLines[payLineIndex].modifiedDuration.duration;
            delete this.payRecordData.payLines[payLineIndex];
            delete this.selectedRow.data.rowData.payLines[payLineIndex];
            payLineIndex -= 1;
            if (payLineIndex < 0) { changedMinutes = 0; }
          } else {
            this.payRecordData.payLines[payLineIndex].modifiedDuration.duration -= changedMinutes;
            this.selectedRow.data.rowData.payLines[payLineIndex].endDatetime =
              moment(this.selectedRow.data.rowData.payLines[payLineIndex].startDatetime)
                .add(this.payRecordData.payLines[payLineIndex].modifiedDuration.duration, 'minutes').toISOString();
            changedMinutes = 0;
          }
        }
      } else {
        this.payRecordData.payLines[payLineIndex].modifiedDuration.duration += Math.abs(changedMinutes);
        this.selectedRow.data.rowData.payLines[payLineIndex].endDatetime =
          moment(this.selectedRow.data.rowData.payLines[payLineIndex].startDatetime)
            .add(this.payRecordData.payLines[payLineIndex].modifiedDuration.duration, 'minutes').toISOString();
      }
      this.setupEditPanel(this.selectedRow, true);
      this.payRecordData.total = (this.convertDurationStringToMinutes(this.payRecordData.modifiedAmount) / 60) *
                                 Number(this.payRecordData.jobEvent.haulRate);
    } else if (this.payRecordData.jobEvent.haulType === 'weight') {
      this.payRecordData.total = Number(this.payRecordData.modifiedAmount.replace(' tons', '')) *
                                 Number(this.payRecordData.jobEvent.haulRate);
    }
  }

  createPayLine() {
    let newStartTime;
    if (this.payRecordData.payLines.length) {
      const lastPayLine = this.payRecordData.payLines[this.payRecordData.payLines.length - 1];
      newStartTime = moment(lastPayLine.modifiedDuration.date +
                            lastPayLine.modifiedDuration.endTime, 'YYYY-MM-DDHH:mm');
    } else {
      newStartTime = moment(this.payRecordData.jobEvent.shift1StartTimestamp);
    }
    this.selectedRow.data.rowData.payLines.push({
      startDatetime: newStartTime.toISOString(),
      endDatetime: newStartTime.add(1, 'hours').toISOString()
    });
    this.setupEditPanel(this.selectedRow, true);
  }

  deletePayLine(index: number) {
    delete this.selectedRow.data.rowData.payLines[index];
    this.setupEditPanel(this.selectedRow, true);
  }

  updatePayLine(index: number) {
    let payRecord = this.payRecordData.payLines[index];
    let rowData = this.selectedRow.data.rowData.payLines[index];
    let startDatetime = moment(payRecord.modifiedDuration.date +
                               payRecord.modifiedDuration.startTime,
                               'YYYY-MM-DDHH:mm');
    let endDatetime = moment(payRecord.modifiedDuration.date +
                             payRecord.modifiedDuration.endTime,
                             'YYYY-MM-DDHH:mm');
    rowData.startDatetime = startDatetime.toISOString();
    rowData.endDatetime = endDatetime.toISOString();
    this.editsMade.emit(this.selectedRow.data.rowData);
  }

  editPayRecord(field: string, event: Event) {
    if (this.selectedRow.data.rowData.payLines.length === 0) {
      this.selectedRow.data.rowData.payLines.push({ startDatetime: null, endDatetime: null });
    }
    if (!this.selectedRow.data.rowData.payAdjustmentTotal) {
      this.selectedRow.data.rowData.payAdjustmentTotal = 0;
    }
    switch (field) {
      case 'adjustment':
        this.selectedRow.data.rowData.payAdjustmentTotal = Number(event.target['value']);
        break;
      case 'notes':
        this.selectedRow.data.rowData.notes = event.target['value'];
        break;
      default:
        break;
    }
    this.setupEditPanel(this.selectedRow, true);
  }
}
