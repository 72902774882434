"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var custom_field_1 = require("./custom-field");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var CustomFieldSerializer = /** @class */ (function () {
    function CustomFieldSerializer() {
    }
    CustomFieldSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var customField = new custom_field_1.CustomField();
        if (!json) {
            return customField;
        }
        customField.id = json.id;
        customField.displayName = json.displayName;
        customField.key = json.key;
        customField.kind = json.kind;
        customField.ownerOrganization = json.ownerOrganization;
        customField.active = json.active;
        return customField;
    };
    CustomFieldSerializer.prototype.toJson = function (data) {
        if (data.length) {
            return data.map(function (customField) { return ({
                id: customField.id,
                display_name: customField.displayName,
                key: customField.key,
                kind: customField.kind,
                owner_organization: customField.ownerOrganization,
                active: customField.active,
            }); });
        }
        return {
            id: data.id,
            display_name: data.displayName,
            key: data.key,
            kind: data.kind,
            owner_organization: data.ownerOrganization,
            active: data.active
        };
    };
    return CustomFieldSerializer;
}());
exports.CustomFieldSerializer = CustomFieldSerializer;
