"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var trip_service_1 = require("../../trips/trip.service");
var DriverNoteDialogComponent = /** @class */ (function () {
    function DriverNoteDialogComponent(dialogRef, tripService) {
        this.dialogRef = dialogRef;
        this.tripService = tripService;
        this.note = '';
        this.noteProperty = 'reviewer_notes';
        this.disabled = false;
        this.newNote = '';
        this.maxlength = 255;
    }
    DriverNoteDialogComponent.prototype.ngOnInit = function () {
        this.newNote = this.note || '';
    };
    DriverNoteDialogComponent.prototype.onSave = function () {
        var _this = this;
        var _a;
        this.tripService
            .patchTrip(this.tripId, (_a = {},
            _a[this.noteProperty] = this.newNote,
            _a))
            .subscribe(function () {
            _this.dialogRef.close({
                tripId: _this.tripId,
                note: _this.newNote,
            });
        });
    };
    DriverNoteDialogComponent.prototype.onClose = function () {
        this.dialogRef.close();
    };
    return DriverNoteDialogComponent;
}());
exports.DriverNoteDialogComponent = DriverNoteDialogComponent;
