import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'telephone'})

export class TelephonePipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    }

    switch (input.length) {
      case 7:
        return input.slice(0, 3) + '-' + input.slice(3);
      case 10:
        return input.slice(0, 3) + '-' + input.slice(3, 6) + '-' + input.slice(6);
      default:
        return input;
    }
  }
}
