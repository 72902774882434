import {
  Component, Output, OnInit, EventEmitter, Input, ViewChild, TemplateRef
} from '@angular/core';
import {
  trigger, transition, style, state, animate
} from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { remove } from 'lodash';

import { PurchaseOrderService } from './purchase-order.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { PurchaseOrderFiltersDialogComponent } from './purchase-order-filters-dialog.component';
import { parseErrors } from '../shared/api.service';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';
import { PurchaseOrderFilterService } from './purchase-order.filter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss'],
  providers: [PurchaseOrderService],
  animations: [
    trigger('statusAnimation', [
      state('updated', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      state('removed', style({ opacity: 0, display: 'none', transform: 'translateX(0) scale(1)' })),
      transition('* => removed', [
        animate('300ms ease-out', style({ background: '#fbd0d1', opacity: 0 }))
      ]),
      transition('* => updated', [
        animate('300ms', style({ background: '#d4e6f7' })),
        animate('300ms ease-out', style({ background: '#FFFFFF' }))
      ])
    ])
  ]
})
export class PurchaseOrdersComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: 'Name', sortable: true, sortBy: 'name' },
    { key: 'number', title: ' P.O. #', sortable: true, sortBy: 'number' },
    { key: 'customer', title: 'Customer', sortable: true, sortBy: 'customer_organization__name' },
    { key: 'product', title: 'Product', sortable: true, sortBy: 'product__name' },
    { key: 'unit-price', title: 'Unit Price', sortable: true, sortBy: 'unit_price' },
    { key: 'quantity', title: 'Quantity', sortable: true, sortBy: 'quantity'  },
    { key: 'delivery-location', title: 'Delivery Location', sortable: true, sortBy: 'delivery_location__name' },
    { key: 'email', title: 'Email', sortable: true, sortBy: 'email' },
    { key: 'phone-number', title: 'Phone Number', sortable: true, sortBy: 'phone_number' },
    { key: 'actions', title: 'Action', sortable: false }
  ];

  @Input() displayedColumns = [
    'select', 'name', 'number', 'customer', 'product', 'unit-price', 'quantity',
    'delivery-location', 'email', 'phone-number', 'actions'
  ];
  @Input() availableFilters = [
    new FilterOption({
      key: 'product', title: this.translationService.instant('Product'),
      filterType: 'select', service: PurchaseOrderFilterService, slug: 'products'
    }),
    new FilterOption({
      key: 'customer_organization', title: this.translationService.instant('Customer'),
      filterType: 'select', service: PurchaseOrderFilterService, slug: 'customers'
    }),
    new FilterOption({
      key: 'delivery_location', title: this.translationService.instant('Location'),
      filterType: 'select', service: PurchaseOrderFilterService, slug: 'locations'
    }),
    new FilterOption({
      key: 'fulfilled', title: this.translationService.instant('Fulfilled'),
      filterType: 'checkbox'
    }),
    new FilterOption({
      key: 'full_stop', title: this.translationService.instant('Full Stop'),
      filterType: 'checkbox'
    }),
    new FilterOption({
      key: 'void', title: this.translationService.instant('Void'),
      filterType: 'checkbox'
    })
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  // config for fancy table
  purchaseOrdersTableConfig = {
    hasHeader: true,
    service: PurchaseOrderService,
    preferenceKey: 'PurchaseOrdersComponent-PurchaseOrderService',
    query: {},
    collectionTitle: this.translationService.instant('Purchase Orders'),
    noResultsText: this.translationService.instant('an order'),
    newRecordRoute: ['/purchase-orders/new'],
    sortBy: 'name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
      { name: this.translationService.instant('Void'), action: 'void', link: false, external: false },
      { name: this.translationService.instant('Unvoid'), action: 'unvoid', link: false, external: false }
    ]
  };
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  @ViewChild('columnToggle', { static: false }) columnToggle;
  @ViewChild('purchaseOrdersTable', { static: false }) purchaseOrdersTable;
  @ViewChild('appliedFiltersDisplay', { static: false }) appliedFiltersDisplay;
  loading = true;
  errors = [];
  count = 0;
  filters = [];
  filtersDialog;
  excludePurchaseOrders;
  purchaseOrdersReq;
  loadingProductTypes = false;
  selectedAction;
  // actionMenu = [{ name: 'Export', action: 'export' }];
  actionMenu = []; // No export API exists
  confirmDialog: MatDialogRef<any>;
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  menuOptions = [
    { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
    { name: this.translationService.instant('Void'), action: 'void', link: false, external: false },
    { name: this.translationService.instant('Unvoid'), action: 'unvoid', link: false, external: false }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private purchaseOrderService: PurchaseOrderService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  openFilters() {
    const dialog = this.dialog.open(PurchaseOrderFiltersDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = this.appliedFilters.reduce((acc, filter) => {
      if (filter.filterType === 'checkbox') {
        if (filter.values === true) { filter.values = ['True']; }
        acc[filter.key] = filter.values && filter.values[0] === 'True';
      } else if (filter.filterType === 'select') {
        acc[filter.key] = {
          id: filter.values && filter.values[0],
          name: filter.displayValues && filter.displayValues[0]
        };
      } else {
        acc[filter.key] = filter.value;
      }
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    let triggerRefresh = false;

    if (filterRes) {
      if (filterRes.product !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'product');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'product', title: 'Product', filterType: 'select',
          service: PurchaseOrderFilterService, values: [filterRes.product.id],
          displayValues: [filterRes.product.name], slug: 'products'
        }));
      }

      if (filterRes.customer_organization !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'customer_organization');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'customer_organization', title: 'Customer', filterType: 'select',
          service: PurchaseOrderFilterService,
          values: [filterRes.customer_organization.id],
          displayValues: [filterRes.customer_organization.name], slug: 'customers'
        }));
      }

      if (filterRes.delivery_location !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'delivery_location');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'delivery_location', title: 'Location', filterType: 'select',
          service: PurchaseOrderFilterService,
          values: [filterRes.delivery_location.id],
          displayValues: [filterRes.delivery_location.name], slug: 'locations'
        }));
      }

      if (filterRes.fulfilled !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'fulfilled');
        if (filterRes.fulfilled) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'fulfilled', title: 'Fulfilled', filterType: 'checkbox',
            values: ['True'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }

       if (filterRes.full_stop !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'full_stop');
        if (filterRes.full_stop) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'full_stop', title: 'Full Stop', filterType: 'checkbox',
            values: ['True'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }

      if (filterRes.void !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'void');
        if (filterRes.void) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'void', title: 'Void', filterType: 'checkbox',
            values: ['True'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }
    }

    if (triggerRefresh) { this.purchaseOrdersTable.getRecords(); }
  }

  filtersModified(appliedFilters) {
    if (this.appliedFiltersDisplay) {
      this.appliedFiltersDisplay.ngOnInit();
    }
    this.appliedFilters = appliedFilters;
  }

  removeFilter() {
    remove(this.appliedFilters);
    this.purchaseOrdersTable.getRecords();
  }

  menuAction(event) {
    switch (event[0]) {
      case 'void':
        this.voidPurchaseOrder(event[1]);
        break;
      case 'unvoid':
        this.unvoidPurchaseOrder(event[1]);
        break;
    }
  }

  voidPurchaseOrder(purchaseOrder) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Void Order?'),
      body: this.translationService.instant(
            'This order will be marked as ‘Void’ and you will not be able to create tickets for this order'
            ),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Void')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.purchaseOrderService.void(purchaseOrder).subscribe((res) => {
          this.purchaseOrdersTable.getRecords();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  unvoidPurchaseOrder(purchaseOrder) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Unvoid Order?'),
      body: this.translationService.instant('This order will be unmarked as ‘Void’ and you will be able to create tickets for this order'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Unvoid')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.purchaseOrderService.unvoid(purchaseOrder).subscribe((res) => {
          this.purchaseOrdersTable.getRecords();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  setSelectedAction(option) {
    switch (option) {
      case 'export':
        if (this.purchaseOrdersTable) {
          let selectedPurchaseOrderIds;
          if (!this.purchaseOrdersTable.allSelected) {
            selectedPurchaseOrderIds = this.purchaseOrdersTable.selection.selected.map(order => order.id);
          }
          let excludedPurchaseOrderIds;
          if (this.purchaseOrdersTable.allSelected) {
            excludedPurchaseOrderIds = this.purchaseOrdersTable.exclusion.selected.map(order => order.id);
          }
          this.createExport(selectedPurchaseOrderIds, excludedPurchaseOrderIds);
        }
        break;
    }
  }

  createExport(selectedPurchaseOrders = null, excludePurchaseOrders = null) {
    let scope = { purchaseorders: selectedPurchaseOrders, exclude_purchaseorders: excludePurchaseOrders };
    let filters = { search: this.search };
    this.purchaseOrderService.export(scope, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{ type: 'purchase orders' }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'purchase orders',
          scope: scope,
          params: params,
          service: this.purchaseOrderService,
          buttonText: this.translationService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
