"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var job_event_share_1 = require("./job-event-share");
var location_serializer_1 = require("../locations/location.serializer");
var app_constants_1 = require("../app.constants");
var unit_of_measure_serializer_1 = require("../units/unit-of-measure.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var JobEventShareSerializer = /** @class */ (function () {
    function JobEventShareSerializer() {
    }
    JobEventShareSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var jobEventShare = new job_event_share_1.JobEventShare();
        if (!json) {
            return jobEventShare;
        }
        jobEventShare.id = json.id;
        jobEventShare.organization = json.organization;
        jobEventShare.organizationId = json.organizationId;
        jobEventShare.organizationLeasedOrg = json.organizationLeasedOrg;
        jobEventShare.sharedBy = json.sharedBy;
        jobEventShare.sharedById = json.sharedById;
        jobEventShare.job = json.job;
        jobEventShare.jobId = json.jobId;
        jobEventShare.jobNumber = json.jobNumber;
        jobEventShare.jobOrderNumber = json.jobOrderNumber;
        jobEventShare.jobeventId = json.jobeventId;
        if (json.startLocation && typeof (json.startLocation) === 'object') {
            jobEventShare.startLocation = new location_serializer_1.LocationSerializer().fromJson(jobEventShare.startLocation);
        }
        else {
            jobEventShare.startLocation = new location_serializer_1.LocationSerializer().fromJson({ id: jobEventShare.startLocation });
        }
        if (json.endLocation && typeof (json.endLocation) === 'object') {
            jobEventShare.endLocation = new location_serializer_1.LocationSerializer().fromJson(jobEventShare.endLocation);
        }
        else {
            jobEventShare.endLocation = new location_serializer_1.LocationSerializer().fromJson({ id: jobEventShare.endLocation });
        }
        jobEventShare.days = json.days;
        jobEventShare.jobDays = json.jobDays;
        jobEventShare.numTrucks = json.numTrucks || 0;
        jobEventShare.confirmedTrucks = json.confirmedTrucks || 0;
        jobEventShare.dispatched = json.dispatched || 0;
        jobEventShare.invoiceRate = json.invoiceRate;
        jobEventShare.invoiceType = json.invoiceType;
        jobEventShare.invoiceWeightUnit = json.invoiceWeightUnit;
        jobEventShare.haulRate = json.haulRate;
        jobEventShare.haulType = json.haulType;
        jobEventShare.haulWeightUnit = json.haulWeightUnit;
        if (jobEventShare.haulType === 'weight') {
            jobEventShare.haulRateUnit = jobEventShare.haulWeightUnit === 'cuyds' ? 'cu. yd.' : jobEventShare.haulWeightUnit;
        }
        else {
            jobEventShare.haulRateUnit = jobEventShare.haulType;
        }
        jobEventShare.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        jobEventShare.rateTrackingOptions = [
            { value: 'weight', label: 'Weight', name: 'Weight' },
            { value: 'load', label: 'Load', name: 'Load' },
            { value: 'hour', label: 'Hour', name: 'Hour' }
        ];
        jobEventShare.readonlyStatuses = [
            'upcoming', 'cancelled', 'expired', 'rejected', 'future'
        ];
        jobEventShare.weightOption = lodash_1.find(jobEventShare.weightOptions, { value: jobEventShare.invoiceWeightUnit });
        jobEventShare.invoiceTypeOption = lodash_1.find(jobEventShare.rateTrackingOptions, { value: jobEventShare.invoiceType });
        jobEventShare.haulTypeOption = lodash_1.find(jobEventShare.rateTrackingOptions, { value: jobEventShare.haulType });
        jobEventShare.status = json.status;
        jobEventShare.readonly = jobEventShare.readonlyStatuses.includes(jobEventShare.status);
        jobEventShare.createdAt = json.createdAt;
        jobEventShare.senderFirstName = json.senderFirstName;
        jobEventShare.senderLastName = json.senderLastName;
        jobEventShare.senderOrganizationPhoneNumber = json.senderOrganizationPhoneNumber;
        jobEventShare.shift1Start = json.shift1Start;
        jobEventShare.shift1End = json.shift1End;
        jobEventShare.shift2Start = json.shift2Start;
        jobEventShare.shift2End = json.shift2End;
        jobEventShare.material = json.material;
        jobEventShare.checkinOptions = json.checkinOptions || {};
        jobEventShare.checkoutOptions = json.checkoutOptions || {};
        jobEventShare.multipliers = json.multipliers;
        jobEventShare.externalIdentifier = json.externalIdentifier;
        jobEventShare.workOrderTemplate = json.workOrderTemplate;
        jobEventShare.workOrderRenderedHtml = json.workOrderRenderedHtml;
        jobEventShare.workOrderRenderedPdf = json.workOrderRenderedPdf;
        jobEventShare.workOrderSigned = json.workOrderSigned;
        jobEventShare.workOrderSignedBy = json.workOrderSignedBy;
        jobEventShare.workOrderSignedByName = json.workOrderSignedByName;
        jobEventShare.notes = json.notes;
        jobEventShare.internalNotes = json.internalNotes;
        jobEventShare.carrierId = json.carrierId;
        jobEventShare.carrierName = json.carrierName;
        jobEventShare.loadSchedule = json.loadSchedule;
        if (jobEventShare.days && jobEventShare.days.length === 0) {
            jobEventShare.dateRange = '';
        }
        else if (jobEventShare.days && jobEventShare.days.length === 1) {
            jobEventShare.dateRange = moment(jobEventShare.days[0]).format('MMM D YYYY');
        }
        else if (jobEventShare.days) {
            var days = jobEventShare.days.sort(function (a, b) {
                return +new Date(a) - +new Date(b);
            });
            jobEventShare.dateRange = moment(days[0]).format('MMM D YYYY') + ' to ' + moment(days[days.length - 1]).format('MMM D YYYY');
        }
        if (json.shift1Start || json.shift2Start) {
            var value = json.shift1Start ? json.shift1Start : json.shift2Start;
            jobEventShare.startDate = moment(value).format('YYYY-MM-DD');
            jobEventShare.startTime = moment(value).format('h:mm a');
        }
        if (json.shift1End || json.shift2End) {
            var value = json.shift1End ? json.shift1End : json.shift2End;
            jobEventShare.endDate = moment(value).format('YYYY-MM-DD');
            jobEventShare.endTime = moment(value).format('h:mm a');
        }
        var defaultUnit = new unit_of_measure_serializer_1.UnitOfMeasureSerializer().fromJson({ name: 'Trucks', key: 'trucks' });
        jobEventShare.requestedAmount = json.requestedAmount || json.numTrucks;
        jobEventShare.requestedUnit = json.requestedUnit || defaultUnit;
        jobEventShare.collaboration = __assign({
            jobEventsLoading: false,
            jobEventOptions: [],
            recipientOrganization: jobEventShare.organizationId,
            jobevents: [],
            numTrucks: null,
            requestedAmount: null,
            requestedUnit: defaultUnit,
            invoiceWeightUnit: 'ton',
            invoiceType: null,
            invoiceRate: null,
            notes: '',
            internalNotes: ''
        }, {
            numTrucks: jobEventShare.numTrucks,
            requestedAmount: jobEventShare.requestedAmount || jobEventShare.numTrucks,
            requestedUnit: jobEventShare.requestedUnit || defaultUnit,
            invoiceWeightUnit: jobEventShare.invoiceWeightUnit,
            invoiceType: jobEventShare.invoiceType,
            invoiceRate: jobEventShare.invoiceRate,
            notes: jobEventShare.notes,
            internalNotes: jobEventShare.internalNotes
        });
        jobEventShare.brokerRateCodeKey = json.brokerRateCodeKey;
        jobEventShare.customFieldData = json.customFieldData || {};
        jobEventShare.confirmedAmount = json.confirmedAmount;
        return jobEventShare;
    };
    JobEventShareSerializer.prototype.toJson = function (jobEventShare) {
        var _a;
        var customFieldData = (_a = {},
            _a[jobEventShare.brokerRateCodeKey] = jobEventShare.brokerRateCode,
            _a);
        var json = {
            id: jobEventShare.id,
            organization: jobEventShare.organizationId,
            job: jobEventShare.jobId,
            jobevent: jobEventShare.jobeventId,
            days: jobEventShare.days,
            jobDays: jobEventShare.jobDays,
            numTrucks: jobEventShare.numTrucks,
            confirmedTrucks: jobEventShare.confirmedTrucks,
            invoiceRate: jobEventShare.invoiceRate,
            invoiceType: jobEventShare.invoiceType,
            invoiceWeightUnit: jobEventShare.invoiceWeightUnit,
            status: jobEventShare.status,
            notes: jobEventShare.notes,
            internalNotes: jobEventShare.internalNotes,
            shift1Start: jobEventShare.shift1Start,
            shift1End: jobEventShare.shift1End,
            shift2Start: jobEventShare.shift2Start,
            shift2End: jobEventShare.shift2End,
            material: jobEventShare.material,
            checkinOptions: jobEventShare.checkinOptions,
            checkoutOptions: jobEventShare.checkoutOptions,
            multipliers: jobEventShare.multipliers,
            externalIdentifier: jobEventShare.externalIdentifier,
            workOrderTemplate: jobEventShare.workOrderTemplate,
            workOrderSigned: jobEventShare.workOrderSigned,
            workOrderSignedBy: jobEventShare.workOrderSignedBy,
            customFieldData: customFieldData,
            requestedAmount: jobEventShare.requestedAmount,
            requestedUnit: jobEventShare.requestedUnit,
            carrierId: jobEventShare.carrierId,
            carrierName: jobEventShare.carrierName,
            loadSchedule: jobEventShare.loadSchedule,
        };
        return decamelizeKeysDeep(json);
    };
    return JobEventShareSerializer;
}());
exports.JobEventShareSerializer = JobEventShareSerializer;
