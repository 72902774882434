import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import { find as _find, uniq } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { parseErrors } from '../../../shared';
import { FancyTableComponent } from '../../../shared/fancy-table/fancy-table.component';
import { TableConfig } from '../../../shared/fancy-table/table.types';
import { ColumnToggleComponent } from '../../../shared/column-toggle/column-toggle.component';

import { Assignment } from '../../../assignments/assignment';
import { AssignmentService } from '../../../assignments/assignment.service';
import { Driver } from '../../../drivers/driver';
import { DriverService } from '../../../drivers/driver.service';
import { JobEvent } from '../../../job-events/job-event';
import { JobEventService } from '../../../job-events/job-event.service';

type DriverStatus = 'active' | 'inactive' | 'warning' | 'complete' | 'nodata';

@Component({
  selector: 'driver-pay-prereport',
  templateUrl: './driver-pay-prereport.component.html',
  styleUrls: ['../driver-pay.component.scss']
})
export class DriverPayPrereportComponent implements OnInit {
  @ViewChild('columnToggle', { static: false }) columnToggle: ColumnToggleComponent;
  @ViewChild('prereportTable', { static: false }) prereportTable: FancyTableComponent;
  availableColumns = [
    { key: 'status', title: ' ', sortable: false },
    { key: 'driverEdit', title: this.translationService.instant('Driver'), sortable: true, sortBy: 'driver__last_name' },
    { key: 'truckNumber', title: this.translationService.instant('Truck #'), sortable: true, sortBy: 'truck__name' },
    { key: 'job', title: this.translationService.instant('Job'), sortable: true, sortBy: 'jobevent__job__name' },
    { key: 'orderNumber', title: this.translationService.instant('Order Number'), sortable: false },
    { key: 'shiftStart', title: this.translationService.instant('Shift Start'), sortable: true, sortBy: 'jobevent__shift1_start' },
    { key: 'shiftEnd', title: this.translationService.instant('Shift End'), sortable: true, sortBy: 'jobevent__shift1_end' },
    { key: 'tripsAmount', title: this.translationService.instant('Trips Amount'), sortable: false },
    { key: 'geotripsAmount', title: this.translationService.instant('GeoTrips Amount'), sortable: false },
    { key: 'row-actions', title: this.translationService.instant('Actions'), sortable: false }
  ];
  displayedColumns = [
    'status', 'driverEdit', 'truckNumber', 'job', 'orderNumber', 'shiftStart',
    'shiftEnd', 'tripsAmount', 'geotripsAmount', 'row-actions'
  ];

  tableConfig: TableConfig = <TableConfig>{
    hasHeader: false,
    service: AssignmentService,
    ignorePreferences: true,
    automaticallyHidePagination: false,
    query: {},
    collectionTitle: this.translationService.instant('Prereport Assignments'),
    noResultsText: this.translationService.instant('an assignment'),
    sortBy: 'date',
    sortDirection: 'asc',
    hasNoResultsAction: true,
    customRecordFields: {
      edited: false,
      updated: false
    },
  };
  query: any = {};

  @Input() reportType: 'internal' | 'external' | 'all';
  @Input() selectedDate: Date;
  @Input() search: string;
  @Output() searchClear = new EventEmitter();
  dateParam: string;
  errors: any;
  jobEvents: JobEvent[] = [];

  constructor(
    public assignmentService: AssignmentService,
    private jobEventService: JobEventService,
    private translationService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.reportRows && !changes.reportRows.firstChange) ||
      (changes.selectedDate && !changes.selectedDate.firstChange) ||
      (changes.search && !changes.search.firstChange)
    ) {
      this.setupDateQuery(this.selectedDate);
      if (this.prereportTable) { this.prereportTable.getRecords(); }
    }
  }

  ngOnInit() {
    this.setupDateQuery(this.selectedDate);
  }

  setupDateQuery(date: Date) {
    this.tableConfig = {
      ...this.tableConfig,
      query: {
        jobevent__shift1_start__gte: moment(date).startOf('day').toISOString(),
        jobevent__shift1_start__lte: moment(date).endOf('day').toISOString(),
        dispatched: 'True', include_trips: 'True', include_punchcards: 'True',
        carrier_type: this.reportType,
        search: this.search
      }
    };
    this.dateParam = moment(date).format('YYYYMMDD');
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  driverDropdownConfig(row: Assignment) {
    return {
      service: DriverService,
      selectText: this.translationService.instant('Select Driver'),
      loadingText: this.translationService.instant('Loading Driver...'),
      noResultsText: this.translationService.instant('No Driver'),
      customOptionKeys: ['carrier'],
      query: {
        ordering: 'profile__last_name,name',
        carrier: row.driver.carrier.id,
        jobevent_available: row.jobevent && row.jobevent.id
      }
    };
  }

  changeAssignmentDriver(row: Assignment, driver: Driver) {
    if (!row['edited']) {
      this.prereportTable.record = Object.assign(
        row, {edited: row.driver.id !== driver.id}
      );
    }
    this.prereportTable.record = Object.assign(
      row, {driver: driver}
    );
  }

  saveAssignmentUpdate(row: Assignment) {
    let saveData = {...row};
    delete saveData['edited'];
    delete saveData['updated'];
    this.assignmentService.save(saveData).subscribe(() => {
      this.prereportTable.record = Object.assign(
        row, {edited: false, updated: true}
      );
    }, err => this.errors = parseErrors(err));
  }

  driverStatus(row: Assignment): DriverStatus {
    let status: DriverStatus = 'inactive';
    const startTime = Date.parse(row.shifts && row.shifts[0] && row.shifts[0].startTime);
    if (Date.now() > startTime && !!!(row.shifts[0] && row.shifts[0].endTime)) {
      status = Date.now() < (startTime + 86400000) ? 'active' : 'warning';
    } else if (row.shifts[0] && row.shifts[0].endTime) {
      status = 'complete';
    } else if (startTime && row.trips.length === 0 && row.predictedTrips.length === 0) {
      status = 'nodata';
    }
    return status;
  }

  getJobEventData(assignments: Assignment[]) {
    const jobEventIds = uniq(assignments.map(a => (a.jobevent.id)));
    jobEventIds.forEach(id => {
      const matchedAssignments = assignments.filter(a => (a.jobevent.id === id));
      let matchedJobEvent: JobEvent = _find(this.jobEvents, { 'id': id });
      if (matchedJobEvent) {
        matchedAssignments.forEach(a => {
          a.jobevent = matchedJobEvent;
          this.prereportTable.updateTable(a);
        });
      } else {
        this.jobEventService.get(id).pipe(
          take(1),
        ).subscribe(res => {
          this.jobEvents.push(res);
          matchedAssignments.forEach(a => {
            a.jobevent = res;
            this.prereportTable.updateTable(a);
          });
        });
      }
    });
  }

  onSearchChange(searchVal: string) {
    if (searchVal !== this.search) {
      this.search = searchVal;
      this.searchClear.emit(this.search);
    }
  }
}
