<div class="app-content">
  <my-toolbar title="{{ 'Trips' | translate }} - {{ job && job.name || customer && customer.name }}" [userDropdown]=false>
    <div class="right-actions" right-last>
      <button class="link" (click)="dialogRef.close()" [disabled]="loading"><span class="icon-close"></span></button>
    </div>
  </my-toolbar>

  <action-bar>
    <div class="left-actions">
      <date-range-picker class="date-select" [selectedDates]="[startDate]" (dateChanged)="onDateChanged($event, 'startDate')"></date-range-picker>
      <date-range-picker class="date-select" [selectedDates]="[endDate]" (dateChanged)="onDateChanged($event, 'endDate')"></date-range-picker>
      <dropdown #jobsDropdown
                [options]="jobs"
                (onSelect)="onSelect('job', $event)"
                [config]="jobsConfig"
                title="{{ 'All Jobs' | translate }}"
                (onSearch)="dropdownSearch($event, 'job')"
                (nextPage)="dropdownNextPage($event, 'job')"></dropdown>
      <dropdown #customersDropdown
                [options]="customers"
                (onSelect)="onSelect('customer', $event)"
                [config]="customersConfig"
                title="{{ 'All Customers' | translate }}"
                (onSearch)="dropdownSearch($event, 'customer')"
                (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Items Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Item Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Items Selected' | translate }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='trip-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Items' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Item' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Items' | translate }}</ng-template>
        </span>
      </div>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <button id="add-to-invoice-button" class="btn btn-primary" (click)="addToInvoice()"
              [disabled]="loading || (!allSelected && !selectedTrips.length)" translate>
        <span class="icon-plus"></span> Add to Invoice
      </button>
    </div>
  </action-bar>

  <div class="trips">
    <div class="list-table" (scroll)="onScroll($event)">
      <div class="table-body">
        <div class="header">
          <div class="head selector">
            <label>
              <input type="checkbox" (change)="selector($event)" [(ngModel)]="allSelected" />
            </label>
          </div>
          <div class="head date sort" translate (click)="sort('jobevent__shift1_start')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__shift1_start'}">
            Job Date
          </div>
          <div class="head job-title sort" translate (click)="sort('jobevent__job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__job__name'}">
            Job
          </div>
          <div class="head load-ticket-number sort" translate (click)="customSort('ticket_number_ordering', 'loading')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loading' && sortParameter === 'ticket_number_ordering'}">
            Load Ticket #
          </div>
          <div class="head load-ticket-weight sort" translate (click)="customSort('weight_ordering', 'loading')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loading' && sortParameter === 'weight_ordering'}">
            Load Wt
          </div>
          <div class="head load-ticket-image" translate>
            Load Ticket
          </div>
          <div class="head load-signature-image" translate>
            Load Signature
          </div>
          <div class="head unload-ticket-number sort" translate (click)="customSort('ticket_number_ordering', 'unloading')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'unloading' && sortParameter === 'ticket_number_ordering'}">
            Unload Ticket #
          </div>
          <div class="head unload-ticket-weight sort" translate (click)="customSort('weight_ordering', 'unloading')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'unloading' && sortParameter === 'weight_ordering'}">
            Unload Wt
          </div>
          <div class="head unload-ticket-image" translate>
            Unload Ticket
          </div>
          <div class="head unload-signature-image" translate>
            Unload Signature
          </div>
          <div class="head carrier sort" translate (click)="sort('driver__carrier__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__organization__carrier__name'}">
            Carrier
          </div>
          <div class="head driver sort" translate (click)="sort('driver__profile__first_name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__first_name'}">
            Driver
          </div>
          <div class="head truck sort" translate (click)="sort('truck__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'truck__name'}">
            Truck
          </div>
          <div class="head time" translate>
            Time
          </div>
          <div class="head duration" translate>
            Duration
          </div>
          <div class="head payable-to" translate>
            Payable To
          </div>
          <div class="head customer sort" translate (click)="sort('jobevent__customer_organization__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__customer_organization__name'}">
            Customer
          </div>
          <div class="head invoice" translate>
            Invoice Rate
          </div>
          <div class="head invoice-total" translate>
            Invoice Total
          </div>
          <div class="head haul" translate>
            Haul Rate
          </div>
          <div class="head haul-total" translate>
            Haul Total
          </div>
          <div class="head actions" translate>
            Actions
          </div>
        </div>

        <div class="no-results" *ngIf="!loading && trips.length === 0" fxLayout="column" fxLayoutAlign="start center">
          <div class="icon"><i class="icon-team"></i></div>
          <h2 translate>No Invoiceable Trips</h2>
          <p translate>When you complete trips you�ll see them here.</p>
        </div>

        <div *ngFor="let trip of trips" class="row" [ngClass]="{selected: allSelected || trip.selected}">
          <div class="selector">
            <label>
              <input type="checkbox" (change)="selector($event, trip)" [checked]="allSelected || trip.selected" />
            </label>
          </div>
          <div class="date">
            <div class="value">{{ trip.jobDate | date:'mediumDate' || '&nbsp;' }}</div>
            <div class="trip-status">
              <div class="label-{{trip.administrativeStatus}}" *ngIf="trip.displayableStatus">{{trip.administrativeStatus}}</div>
              <div class="spacer" *ngIf="!trip.displayableStatus">&nbsp;</div>
            </div>
          </div>
          <div class="job-title">
            <div class="value">{{ trip.job || '&nbsp;' }}</div>
            <div class="label">{{ trip.project || '&nbsp;' }}</div>
          </div>
          <div class="load-ticket-number">
            <div class="value" [ngClass]="{'missing-required': trip.checkinOptions.ticketNumber && !trip.loadingTicketNumber}">{{ trip.loadingTicketNumber || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="load-ticket-weight">
            <div class="value" [ngClass]="{'missing-required': trip.checkinOptions.weight && !trip.loadingWeight}">{{ trip.loadingWeight || '&nbsp;' }}</div>
            <div class="label">{{ trip.haulWeightUnit || '&nbsp;' | titleCase }}</div>
          </div>
          <div class="load-ticket-image">
            <a (click)="dialogRef.close(); false" [routerLink]="trip.url('edit')" [queryParams]="{ returnTo: '/invoices' }">
              <ng-container *ngIf="trip.loadingAttachments | attachmentType as loadingImgs">
                <div class="ticket-photo" *ngIf="!loadingImgs.length && trip.loadingTicketImagesRequired">
                  <div class="value" class="missing-required">&nbsp;</div>
                  <div class="label" translate >No Ticket</div>
                </div>
                <div class="ticket-photo" *ngIf="loadingImgs.length">
                  <span><i class="icon-ticket"></i></span>
                </div>
              </ng-container>
            </a>
          </div>
          <div class="load-signature-image" (click)="openSignatures(trip)">
            <ng-container *ngIf="trip.loadingAttachments | attachmentType: signatureImageType as images">
              <div class="signature-photo" *ngIf="trip.loadingSignatureImagesRequired && !images.length">
                <div class="value" class="missing-required"></div>
                <div class="label" translate>No Signature</div>
              </div>
              <div class="ticket-photo" *ngIf="images.length">
                <img [src]="images[0].file" />
              </div>
            </ng-container>
          </div>
          <div class="unload-ticket-number">
            <div class="value" [ngClass]="{'missing-required': trip.checkoutOptions.ticketNumber && !trip.unloadingTicketNumber}">{{ trip.unloadingTicketNumber || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="unload-ticket-weight">
            <div class="value" [ngClass]="{'missing-required': trip.checkoutOptions.weight && !trip.unloadingWeight}">{{ trip.unloadingWeight || '&nbsp;' }}</div>
            <div class="label">{{ trip.haulWeightUnit || '&nbsp;' | titleCase }}</div>
          </div>
          <div class="unload-ticket-image">
            <a (click)="dialogRef.close(); false" [routerLink]="trip.url('edit')" [queryParams]="{ returnTo: '/invoices' }">
              <ng-container *ngIf="trip.unloadingAttachments | attachmentType as images">
                <div class="ticket-photo" *ngIf="!images.length && trip.unloadingTicketImagesRequired">
                  <div class="value" class="missing-required">&nbsp;</div>
                  <div class="label" translate >No Ticket</div>
                </div>
                <div class="ticket-photo" *ngIf="images.length">
                  <span><i class="icon-ticket"></i></span>
                </div>
              </ng-container>
            </a>
          </div>
          <div class="unload-signature-image" (click)="openSignatures(trip)">
            <ng-container *ngIf="trip.unloadingAttachments | attachmentType: signatureImageType as images">
              <div class="signature-photo" *ngIf="trip.unloadingSignatureImagesRequired && !images.length">
                <div class="value" class="missing-required"></div>
                <div class="label" translate>No Signature</div>
              </div>
              <div class="ticket-photo" *ngIf="images.length">
                <img [src]="images[0].file" />
              </div>
            </ng-container>
          </div>
          <div class="carrier">
            <div class="value">{{ trip.carrier || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="driver">
            <div class="value">{{ trip.driver || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="truck">
            <div class="value">{{ trip.truckName || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="time">
            <div class="value">{{ trip.schedule }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="duration">
            <div class="value">{{ (trip.endTimeTimestamp ? trip.completedTripTime : formattedDuration(trip.startTimeTimestamp)) || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="payable-to">
            <div class="value">{{ trip.payableTo || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="customer">
            <div class="value">{{ trip.customer || '&nbsp;' }}</div>
            <div class="label">{{ (trip.customerPhone | telephone) || '&nbsp;' }}</div>
          </div>
          <div class="invoice">
            <div class="value">{{ (trip.invoiceRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
            <div class="label">{{ trip.invoiceTypeName || '&nbsp;' }}</div>
          </div>
          <div class="invoice-total">
            <div class="value">{{ (trip.invoiceTotal || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="haul">
            <div class="value">{{ (trip.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
            <div class="label">{{ trip.haulTypeName || '&nbsp;' }}</div>
          </div>
          <div class="haul-total">
            <div class="value">{{ (trip.haulTotal || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            <div class="label">&nbsp;</div>
          </div>
          <div class="actions">
            <action-menu icon="icon-more">
              <ng-container *ngFor="let option of trip.filterOptions(menuOptions)">
                <a *ngIf="option.link" mat-menu-item (click)="dialogRef.close(); false" [routerLink]="trip.url(option.action)" [queryParams]="{ returnTo: '/invoices' }">{{ option.name }}</a>
                <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, trip)">{{ option.name }}</a>
              </ng-container>
            </action-menu>
          </div>
        </div>

        <notification *ngFor="let error of errors">{{error}}</notification>
        <my-loader *ngIf="loading" [ngClass]="{'full-height': trips.length === 0}" class="loader"></my-loader>
      </div>
    </div>
  </div>
</div>
