"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var new_shift_component_1 = require("./new-shift/new-shift.component");
var shifts_component_1 = require("./shifts/shifts.component");
var routes = [
    { path: 'new', component: new_shift_component_1.NewShiftComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: '', component: shifts_component_1.ShiftsComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var ShiftsRoutingModule = /** @class */ (function () {
    function ShiftsRoutingModule() {
    }
    return ShiftsRoutingModule;
}());
exports.ShiftsRoutingModule = ShiftsRoutingModule;
