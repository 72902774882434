import { Price } from './price';
import { Organization } from '../organizations/organization';
import { PriceListSerializer } from '../price-lists/price-list.serializer';
import { ProductSerializer } from '../products/product.serializer';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');
import { find as _find } from 'lodash';

export class PriceSerializer {
  /**
  * @param {any} json
  * @returns Price
  */
  fromJson(json: any): Price {
    json = camelcaseKeysDeep(json);
    const price = new Price();
    if (!json) { return price; }

    price.id = json.id;
    if (json.product && typeof (json.product) === 'object') {
      price.product = (new ProductSerializer().fromJson(json.product));
    } else if (json.product) {
      price.product = (new ProductSerializer().fromJson({ id: json.product }));
    }
    price.createdAt = json.createdAt;
    price.lastModified = json.lastModified;
    price.notes = json.notes;
    if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
      price.ownerOrganization = new Organization(json.ownerOrganization);
    } else {
      price.ownerOrganization = new Organization({ id: json.ownerOrganization });
    }
    price.priceChangeAmount = json.priceChangeAmount;
    if (json.priceList && typeof (json.priceList) === 'object') {
      price.priceList = (new PriceListSerializer()).fromJson(json.priceList);
    } else {
      price.priceList = (new PriceListSerializer()).fromJson({ id: json.priceList });
    }
    price.priceChangeTypeOptions = [
      { id: 'percent', name: 'Percent' },
      { id: 'new_price', name: 'New Price' },
      { id: 'value', name: 'Value' }
    ];
    if (json.priceChangeType) {
      price.priceChangeType = (_find(price.priceChangeTypeOptions, { id: json.priceChangeType }));
    }

    return price;
  }

  /**
   * @param {Price} price
   * @returns any
   */
  toJson(price: Price): any {
    let json = {
      id: price.id,
      product: price.product && price.product.id,
      createdAt: price.createdAt,
      lastModified: price.lastModified,
      notes: price.notes,
      priceChangeAmount: price.priceChangeAmount,
      priceChangeType: price.priceChangeType && price.priceChangeType.id,
      priceList: price.priceList && price.priceList.id,
      ownerOrganization: price.ownerOrganization && price.ownerOrganization.id,
    };

    return decamelizeKeysDeep(json);
  }
}
