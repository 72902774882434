import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResourceService } from '../shared/resource.service';
import { TicketFilterSerializer } from './ticket.filter.serializer';
import { TicketFilter } from './ticket.filter';

@Injectable()
export class TicketFilterService extends ResourceService<TicketFilter> {
  baseUrl = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http, 'scaletickets/', new TicketFilterSerializer());
  }
}
