<div class="modal-container">
  <div class="modal-actions">
    <button class="btn btn-delete" (click)="dialogRef.close()" translate>
      Cancel
    </button>
    <button class="btn btn-success" [disabled]="saveDisabled()" (click)="save()" translate>
      Save
    </button>
  </div>
  <div mat-dialog-title>
    <h1 translate>Ticket QC/VT</h1>
  </div>
  <div mat-dialog-content>
    <notification *ngFor="let error of errors">{{ error | translate }}</notification>
    <p class="large" translate>Ticket {{ data.ticket.loadingTicketNumber || 'N/A' }}</p>
    <p class="status {{ data.ticket.reviewedStatus }}">{{ data.ticket.reviewedStatus | titlecase | translate }}</p>
    <div class="break"></div>
    <p translate>Ticket Image</p>
    <div class="image-container">
      <a *ngIf="data.ticket.loadingTicketImage" [href]="data.ticket.loadingTicketImage" target="_blank">
        <img [src]="data.ticket.loadingTicketImage">
      </a>
      <i *ngIf="!data.ticket.loadingTicketImage" class="icon-no-results"></i>
    </div>
    <div class="break"></div>
    <p translate>Plant QC</p>
    <input id="plant-qc" name="plant-qc" type="number" [value]="data.ticket.plantQc || 0.00" (change)="reviewField('plantQc', $event)"/>
    <p class="light" translate>Tested By: {{ data.ticket.plantQcReviewer ? data.ticket.plantQcReviewer.name : 'N/A' }}</p>
    <div class="break"></div>
    <p translate>Plant VT</p>
    <input id="plant-vt" name="plant-vt" type="number" [value]="data.ticket.plantVt || 0.00" (change)="reviewField('plantVt', $event)"/>
    <p class="light" translate>Tested By: {{ data.ticket.plantVtReviewer ? data.ticket.plantVtReviewer.name : 'N/A' }}</p>
    <div class="break"></div>
    <p translate>Roadway QC</p>
    <input id="roadway-qc" name="roadway-qc" type="number" [value]="data.ticket.roadwayQc || 0.00" (change)="reviewField('roadwayQc', $event)"/>
    <p class="light" translate>Tested By: {{ data.ticket.roadwayQcReviewer ? data.ticket.roadwayQcReviewer.name : 'N/A' }}</p>
    <div class="break"></div>
    <p translate>Roadway VT</p>
    <input id="roadway-vt" name="roadway-vt" type="number" [value]="data.ticket.roadwayVt || 0.00" (change)="reviewField('roadwayVt', $event)"/>
    <p class="light" translate>Tested By: {{ data.ticket.roadwayVtReviewer ? data.ticket.roadwayVtReviewer.name : 'N/A' }}</p>
    <div class="break"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-close" (click)="dialogRef.close()" translate>
      <i class="icon-close"></i>
    </button>
  </div>
</div>