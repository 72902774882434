"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".map-element[_ngcontent-%COMP%]{display:block}.driver-image[_ngcontent-%COMP%]{position:absolute;top:3px;left:4px;width:36px;height:36px;border-radius:50%;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center}"];
exports.styles = styles;
