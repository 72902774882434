"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var resource_service_1 = require("../shared/resource.service");
var job_event_share_stat_serializer_1 = require("./job-event-share-stat.serializer");
var JobEventShareStatService = /** @class */ (function (_super) {
    __extends(JobEventShareStatService, _super);
    function JobEventShareStatService(http) {
        var _this = _super.call(this, http, "jobevents/", new job_event_share_stat_serializer_1.JobEventShareStatSerializer()) || this;
        _this.subject = new rxjs_1.Subject();
        return _this;
    }
    JobEventShareStatService.prototype.getJobEventStatId = function (jobEventStatId) {
        this.subject.next({ id: jobEventStatId });
        this.slug = "jobevents/" + jobEventStatId + "/stats-by-collaborator/";
    };
    return JobEventShareStatService;
}(resource_service_1.ResourceService));
exports.JobEventShareStatService = JobEventShareStatService;
