import { Component, OnInit, OnDestroy } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';

import { TripService } from '../trip.service';
import { switchMap } from 'rxjs/operators';
import { CondensedTrip } from '../condensed-trip';
import { CheckinService } from '../../checkins/checkin.service';

@Component({
  selector: 'trip-history',
  templateUrl: './trip-history.component.html',
  styleUrls: ['./trip-history.component.scss']
})
export class TripHistoryComponent implements OnInit, OnDestroy {
  trip: CondensedTrip;
  title: string;
  returnToUrl: string;
  detailsHeight = 14;
  breakpoint = 3;
  breakpoints = {
    'xs': 1,
    'sm': 1,
    'md': 2,
    'lg': 2,
    'xl': 2,
  };
  watcher: Subscription;
  activeMediaQuery = '';

  constructor(
    private mediaObserver: MediaObserver,
    private route: ActivatedRoute,
    private tripService: TripService,
    private checkinService: CheckinService,
  ) { }

  ngOnInit() {
    this.setupBreakpoints();
    this.getTrip();
  }

  ngOnDestroy() {
    this.watcher.unsubscribe();
  }

  setupBreakpoints() {
    this.watcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      const breakpoint = change.mqAlias;

      this.breakpoint = this.breakpoints[breakpoint];
    });

  }

  getTrip() {
    const queryParams = this.route.snapshot.queryParams;
    this.returnToUrl = queryParams['returnTo'] ? queryParams['returnTo'] : '/trips';
    this.route.params.pipe(
      switchMap((params: Params) => {
        const id = params.id;
        return this.tripService.getCondensedTrip(id);
      })
    ).subscribe((trip: CondensedTrip) => {
      let detailsHeight = 14;

      if (!trip.loadingTicketImage) {
        detailsHeight = detailsHeight - 2;
      }

      if (!trip.unloadingTicketImage) {
        detailsHeight = detailsHeight - 2;
      }

      if (!trip.unloadingSignature) {
        detailsHeight = detailsHeight - 2;
      }

      if (!trip.loadingSignature) {
        detailsHeight = detailsHeight - 2;
      }

      this.trip = trip;
      this.detailsHeight = detailsHeight;
      this.title = `History - ${trip.driver} / ${trip.truckName}`;
      this.getCheckins(trip.id, 'loading');
      this.getCheckins(trip.id, 'unloading');
    });
  }

  getCheckins(tripId: string, kind = 'loading'): void {
    this.checkinService.getCheckins({
      ordering: 'name',
      kind: kind,
      trip: tripId
    }).subscribe(checkins => {
      if (checkins && checkins.length) {
        let checkin = checkins[0];
        switch (kind) {
          case 'loading':
            this.trip.loadingCheckin = checkin;
            break;
          case 'unloading':
            this.trip.unloadingCheckin = checkin;
            break;
        }
      }
    });
  }
}
