import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { PunchCardDecision } from './punch-card-decision';
import { ResourceService } from '../shared/resource.service';
import { PunchCardDecisionSerializer } from './punch-card-decision.serializer';

@Injectable()
export class PunchCardDecisionService extends ResourceService<PunchCardDecision> {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(http: HttpClient) {
    super(http, 'punchcarddecisions/', new PunchCardDecisionSerializer());
  }
}
