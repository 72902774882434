<div class="end-of-day-container" *ngIf="stats$ | async as stats; else loading">
  <div class="eod-cell">
    <span translate>TOTAL TRIPS: </span>
    <span class="eod-cell-value">{{ stats.totalTrips }}</span>
  </div>
  <div class="eod-cell">
    <span translate>PLANNED TRIPS: </span>
    <span class="eod-cell-value">{{ stats.plannedTrips }}</span>
  </div>
  <div class="eod-cell">
    <span translate>COMPLETED TRIPS: </span>
    <span class="eod-cell-value">{{ stats.completedTrips }}</span>
  </div>
  <div class="eod-cell">
    <span translate>INVOICE TOTAL: </span>
    <span class="eod-cell-value invoice-total">{{
      stats.invoiceTotal || 0 | currency: 'USD':'symbol':'1.2-4'
    }}</span>
  </div>
  <div class="eod-cell">
    <span translate>EXPENSE TOTAL: </span>
    <span class="eod-cell-value expense-total">{{
      stats.expenseTotal || 0 | currency: 'USD':'symbol':'1.2-4'
    }}</span>
  </div>
</div>

<ng-template #loading>
  <my-loader class="full-height"></my-loader>
</ng-template>
