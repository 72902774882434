"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var router_1 = require("@angular/router");
var collaborator_service_1 = require("../collaborators/collaborator.service");
var api_service_1 = require("../shared/api.service");
var shared_1 = require("../shared");
var ngx_device_detector_1 = require("ngx-device-detector");
var device_1 = require("../shared/device");
var AcceptJobDialogComponent = /** @class */ (function () {
    function AcceptJobDialogComponent(dialogRef, collaboratorService, authenticationService, dialog, router, elementRef, deviceDetectorService) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.router = router;
        this.elementRef = elementRef;
        this.deviceDetectorService = deviceDetectorService;
        this.device = new device_1.Device();
        this.invitation = {};
        this.completed = new core_1.EventEmitter();
        this.hasSignature = false;
        this.loading = false;
        this.confirmDeny = false;
        this.errors = [];
        this.weightUnits = [
            { name: 'Tons', id: 'ton' },
            { name: 'Metric Tons', id: 'metric-tons' },
            { name: 'Yards', id: 'yard' },
        ];
        this.weightUnitsConfig = {
            nameProperty: 'name'
        };
        this.model = {
            id: null,
            haulWeightUnit: null,
            haulRate: null,
            haulType: 'weight',
            status: 'pending',
            allDays: null,
            confirmedTrucks: null
        };
    }
    AcceptJobDialogComponent.prototype.ngOnInit = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.model.id = this.invitation.id;
        this.model.haulType = this.invitation.invoiceType;
        this.setWeightUnitOptions(this.model.haulType);
        this.model.haulWeightUnit = this.invitation.invoiceWeightUnit;
        this.user = this.authenticationService.user();
        this.hasSignature = this.authenticationService.hasSignature();
    };
    AcceptJobDialogComponent.prototype.submit = function (allDays) {
        var _this = this;
        if (allDays === void 0) { allDays = false; }
        this.loading = true;
        if (allDays) {
            this.model['allDays'] = 'True';
        }
        else {
            delete this.model['allDays'];
        }
        this.collaboratorService.accept(this.model).subscribe(function (result) {
            _this.loading = false;
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    AcceptJobDialogComponent.prototype.deny = function () {
        var _this = this;
        this.loading = true;
        if (this.confirmDeny) {
            this.collaboratorService.reject(this.invitation.id).subscribe(function (result) {
                _this.loading = false;
                _this.confirmDeny = false;
                _this.dialogRef.close();
                _this.callback();
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
            });
        }
        else {
            this.loading = false;
            this.confirmDeny = true;
        }
    };
    AcceptJobDialogComponent.prototype.selectWeightUnit = function (e, form) {
        this.model.haulWeightUnit = e.id;
        form.controls['weight_unit'].markAsDirty();
    };
    AcceptJobDialogComponent.prototype.changeHaulRateType = function (e, form) {
        this.setWeightUnitOptions(this.model.haulType);
        form.controls['weight_unit'].markAsDirty();
    };
    AcceptJobDialogComponent.prototype.setWeightUnitOptions = function (haulType) {
        if (!this.confirmDeny) {
            var values = [];
            switch (haulType) {
                case 'weight':
                    values = [
                        { name: 'Tons', id: 'ton' },
                        { name: 'Metric Tons', id: 'metric-tons' },
                        { name: 'Yards', id: 'yard' },
                    ];
                    break;
                case 'load':
                    values = [{ name: 'Load', id: 'load' }];
                    break;
                case 'hour':
                    values = [{ name: 'Hour', id: 'hour' }];
                    break;
            }
            this.weightUnits = values;
            this.haulTypeOption = lodash_1.find(this.weightUnits, {
                id: this.invitation.invoiceRateUnit
            });
            if (this.weightUnitDropdown) {
                this.weightUnitDropdown.options = values;
                this.weightUnitDropdown.selectedOption = values[0];
                this.haulTypeOption = lodash_1.find(this.weightUnits, {
                    id: haulType === 'weight' ? 'ton' : haulType
                });
            }
        }
    };
    AcceptJobDialogComponent.prototype.routeToSettings = function () {
        this.dialogRef.close();
        this.router.navigate(['/settings', 'user', this.user && this.user.id, 'work-orders']);
    };
    return AcceptJobDialogComponent;
}());
exports.AcceptJobDialogComponent = AcceptJobDialogComponent;
