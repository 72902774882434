'use strict';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ruckit-button',
  templateUrl: './ruckit-button.component.html',
  styleUrls: ['./ruckit-button.component.scss']
})
export class RuckitButtonComponent {
  @Input() isActive = false;
  @Input() isDisabled = false;
  @Input() fullWidth = false;
  @Input() title: string;
  @Input() leadingIcon: string;
  @Input() trailingIcon: string;
  @Input() type = 'primary';
  @Input() loading = false;

  constructor() { }

  focusSection(event) {
    event.preventDefault();
    // set flag to be used later when
    // $locationChangeSuccess calls openPage()
    // controller.autoFocusContent = true;
  }

  setClasses() {
    return {
      active: this.isActive,
      disabled: this.isDisabled,
      'full-width': this.fullWidth,
      [this.type]: this.type,
      ['btn-' + this.type]: true,
      loading: this.loading
    };
  }
}
