import { Observable } from 'rxjs';
import { map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Response } from '@angular/http';

import { environment } from '../../environments/environment';
import { Message } from './message';
import { requestHeaders, handleError } from '../shared/api.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class MessageService {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(private http: HttpClient) { }

  getMessage(jobEventId: string, messageId: string) {
    let messageUrl = this.baseUrl + 'jobevents/' + jobEventId + '/messaging/' + messageId + '/';

    return this.http.get(messageUrl, { headers: requestHeaders() }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getMessages(jobEventId: string, query: any = null): Observable<Message[]> {
    let messagesUrl = this.baseUrl + 'jobevents/' + jobEventId + '/messaging/';
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    return this.http.get(messagesUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  send(message): Observable<boolean> {
    let messageUrl = this.baseUrl + 'jobevents/' + message.jobEventId + '/messaging/';
    message = decamelizeKeysDeep(message);

    return this.http.post(messageUrl, message, { headers: requestHeaders() }).pipe(
      map((response: Response) => {
        if (response) {
          if (response.status === 201 || response.status === 200) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(message => {
        return new Message(message);
      });
    } else if (resObj) {
      return new Message(resObj);
    }
  }

  getNext(): Observable<Message[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }
}
