<ng-container *ngFor="let update of locationUpdates; let i = index">
  <custom-marker
    *ngIf="update.location && update.location.coordinates"
    [id]="update.driver && update.driver.id"
    [position]="[
      update.location.coordinates[1],
      update.location.coordinates[0]
    ]"
    (click)="openAssignmentDetails(update, i)"
    (initialized$)="onCustomUpdateMarkerInit($event)"
  >
    <ng-container *ngIf="markerType === 'driver-initials'">
      <map-status-marker
        [locationUpdate]="update"
        [markerStatus]="markerStatus"
        [markerType]="markerType"
      ></map-status-marker>

      <div
        class="driver trip-status-{{ update.tripStatus }} {{ markerStatus }}"
        [ngClass]="{ 'update-expired': update.expired }"
      >
        {{ update.driver && update.driver.initials }}
      </div>
    </ng-container>

    <ng-container *ngIf="markerType === 'truck-number'">
      <div
        class="truck trip-status-{{ update.tripStatus }} {{
          update.tripStatus
        }} {{ markerStatus }}"
        [ngClass]="{ 'update-expired': update.expired }"
      >
        <p>
          {{
            (update.driver &&
              update.driver.truck &&
              update.driver.truck.name) ||
              'No Truck'
          }}
        </p>
      </div>
    </ng-container>
  </custom-marker>
</ng-container>
