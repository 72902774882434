"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column;overflow:hidden}.container[_ngcontent-%COMP%]{display:flex;flex-direction:column;overflow:auto}.container[_ngcontent-%COMP%]   .chart[_ngcontent-%COMP%]{padding:30px 30px 0;display:flex;justify-content:center;position:relative;width:100%;height:100%}.container[_ngcontent-%COMP%]   .chart-container[_ngcontent-%COMP%]{width:75%;height:60vh}.notifications-report[_ngcontent-%COMP%]     .action-bar-content{min-height:72px!important;height:72px!important}.actions-wrapper[_ngcontent-%COMP%]{height:100%;padding:30px}.actions-wrapper[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{right:30px;gap:16px}.actions-wrapper[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{padding-left:8px}.actions-wrapper[_ngcontent-%COMP%]   .percChange[_ngcontent-%COMP%]{width:100%;display:flex;align-items:center}.actions-wrapper[_ngcontent-%COMP%]  .fancy-table-wrapper{height:100%!important}.actions-wrapper[_ngcontent-%COMP%]  .mat-paginator-container .mat-form-field-wrapper{padding-bottom:0}.actions-wrapper[_ngcontent-%COMP%]  .mat-cell:has(div.percChange), .actions-wrapper[_ngcontent-%COMP%]  .mat-header-cell:has(div.percChange){align-items:stretch}.actions-wrapper[_ngcontent-%COMP%]  .mat-cell:last-child, .actions-wrapper[_ngcontent-%COMP%]  .mat-header-cell:last-child{flex:0 0 7%}.actions-wrapper[_ngcontent-%COMP%]   .chart-opt[_ngcontent-%COMP%]{opacity:.8}.actions-wrapper[_ngcontent-%COMP%]   .chart-opt[_ngcontent-%COMP%]:hover{opacity:1}"];
exports.styles = styles;
