<div class="app-content">
  <form #newShift="ngForm" class="shift-form">
    <my-toolbar title="{{ 'New Shift' | translate }}"></my-toolbar>

    <action-bar>
      <div class="left-actions"></div>
      <div class="right-actions">
        <button id="save-shift-button" class="btn btn-primary" (click)="submit(); false" [disabled]="loading || !newShift.form.valid || !isValid()"
          [ngClass]="{ loading: loading }" translate>Save</button>
      </div>
    </action-bar>

    <div class="shift-container">
      <notification *ngFor="let error of errors">{{error}}</notification>

      <div class="shift-data">
        <div class="form-content">
          <div class="field-group dropdown">
            <label class="required" translate>Driver</label>
            <ruckit-dropdown 
              [config]="driverDropdownConfig"
              [selectedOption]="model.driver && model.driver.id"
              (onSelect)="onSelect('driver', $event)">
            </ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group dropdown">
            <label translate>Truck</label>
            <ruckit-dropdown 
              #truckDropdown
              [config]="truckDropdownConfig"
              [disabled]="!model.driver"
              [selectedOption]="model.truck && model.truck.id"
              (onSelect)="onSelect('truck', $event)">
            </ruckit-dropdown>
          </div>
        </div>

        <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="field-group pickers">
            <div class="start-date group" fxFlex="50">
              <label class="required" translate>Start Time</label>
              <date-range-picker
                [selectedDates]="[model.startTime]"
                (dateChanged)="onDateChanged('startDate', $event)">
              </date-range-picker>
            </div>

            <div class="start-time group" fxFlex="50">
              <label>&nbsp;</label>
              <input type="time" name="start_time" value="{{ model.startTime | moment: 'HH:mm' }}"
                (blur)="editDateTime('startTime', $event.target.value)"/>
            </div>
          </div>
        </div>

        <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="field-group pickers">
            <div class="end-date group" fxFlex="50">
              <label translate>End Time</label>
              <date-range-picker
                [selectedDates]="[model.endTime]"
                (dateChanged)="onDateChanged('endDate', $event)">
              </date-range-picker>
            </div>

            <div class="end-time group" fxFlex="50">
              <label>&nbsp;</label>
              <input type="time" name="end_time" value="{{ model.endTime | moment: 'HH:mm' }}"
                (blur)="editDateTime('endTime', $event.target.value)"/>
            </div>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group adjustment">
            <label translate>Adjustment</label>
            <input type="number" name="adjustment" [(ngModel)]="model.adjustment" pattern="[^.]+" />
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
