import { Pipe, PipeTransform } from '@angular/core';
import { JOBWEIGHTOPTIONS } from '../../app.constants';

@Pipe({
  name: 'weight',
})
export class WeightPipe implements PipeTransform {
  constructor() {}
  transform(value: string): string {
    const weight = JOBWEIGHTOPTIONS.find((opt) => opt.value === value);
    return weight ? weight.label : '';
  }
}
