"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var billing_rates_component_1 = require("./billing-rates/billing-rates.component");
var routes = [
    { path: '', component: billing_rates_component_1.BillingRatesComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var AdvancedBillingRoutingModule = /** @class */ (function () {
    function AdvancedBillingRoutingModule() {
    }
    return AdvancedBillingRoutingModule;
}());
exports.AdvancedBillingRoutingModule = AdvancedBillingRoutingModule;
