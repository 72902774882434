<div class="app-content">
  <my-toolbar title="{{ 'Orders' | translate }}"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()">
        <i class="icon-filter"></i> {{ 'Filters' | translate }}
      </button>
    </div>
  </action-bar>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table #purchaseOrdersTable
                   [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   [config]="purchaseOrdersTableConfig"
                   (columnChange)="columnsChanged($event)"
                   (onMenuAction)="menuAction($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'name'">
              <div class="data"> {{ row.name || '&nbsp;' }}</div>
              <div class="status" *ngIf="row.status">
                <div class="{{ row.statusLabel }}">{{ row.status }}</div>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'number'">
              <div class="data">{{ row.number || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'customer'">
              <div class="data">{{ row.customerOrganization && row.customerOrganization.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'product'">
              <div class="data">{{ row.product && row.product.name  || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'unit-price'">
              <div class="data">{{ (row.unitPrice || 0 | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'quantity'">
              <div class="data">
                {{ row.quantity }}
                <span [ngPlural]="row.quantity || 0" *ngIf="row.unitOfMeasurement">
                  <ng-template ngPluralCase="=0">{{ row.unitOfMeasurement + 's' | translate }}</ng-template>
                  <ng-template ngPluralCase="=1.00">{{ row.unitOfMeasurement | translate }}</ng-template>
                  <ng-template ngPluralCase="other">{{ row.unitOfMeasurement + 's' | translate }}</ng-template>
                </span>
                <span [ngPlural]="row.quantity || 0" *ngIf="!row.unitOfMeasurement">
                  <ng-template ngPluralCase="=0">{{ 'units' | translate }}</ng-template>
                  <ng-template ngPluralCase="=1.00">{{ 'unit' | translate }}</ng-template>
                  <ng-template ngPluralCase="other">{{ 'units' | translate }}</ng-template>
                </span>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'delivery-location'">
              <div class="data">{{ row.deliveryLocation && row.deliveryLocation.name  || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'email'">
              <div class="data">{{ row.email || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'phone-number'">
              <div class="data">{{ row.phoneNumber || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>

        <div header-content>
          <div class="title" translate>Orders</div>
        </div>
        <div applied-filter-content>
          <applied-filters #appliedFiltersDisplay [(filters)]="appliedFilters"
                           [(search)]="search" (filtersChange)="filtersModified($event)" (filtersReset)="removeFilter()"></applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>
        <filters-panel [(availableFilters)]="availableFilters"
                       [(appliedFilters)]="appliedFilters"
                       [(search)]="search"
                       (appliedFiltersChange)="filtersModified($event)"></filters-panel>
        <!-- <action-menu icon="icon-more">
          <ng-container *ngFor="let options of actionMenu">
            <a mat-menu-item (click)="setSelectedAction(options.action)">{{ options.name }}</a>
          </ng-container>
        </action-menu> -->
        <a mat-icon-button color="accent" class="add-button" [routerLink]="['/purchase-orders/new']">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
