"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Scale = /** @class */ (function () {
    function Scale(scaleInfo) {
        scaleInfo = camelcaseKeysDeep(scaleInfo);
        this.id = scaleInfo.id;
        this.weight = scaleInfo.weight;
        this.status = scaleInfo.status;
        this.createdAt = scaleInfo.createdAt;
        this.lastModified = scaleInfo.lastModified;
        this.name = scaleInfo.name;
        this.weightUnit = scaleInfo.weightUnit;
        this.ownerOrganization = scaleInfo.ownerOrganization;
        this.location = scaleInfo.location;
        this.weightType = 'scales';
        this.disabled = false;
        switch (this.status) {
            case 'unstable': {
                this.disabled = true;
                break;
            }
            case 'disconnected': {
                this.disabled = true;
                break;
            }
        }
    }
    return Scale;
}());
exports.Scale = Scale;
var WeightType;
(function (WeightType) {
    WeightType["TareWeight"] = "Truck Weights";
    WeightType["ScaleWeight"] = "Scales";
})(WeightType = exports.WeightType || (exports.WeightType = {}));
