import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthenticationService } from './shared/index';
import { DataService } from './shared/data.service';
import '../style/app.scss';

@Component({
  selector: 'my-app', // <my-app></my-app>
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  url = 'https://goruckit.com';
  currentUser: string;
  sidebarOpen = false;
  expanded = false;
  data;
  subscription;
  loggedIn = false;
  displaySidebar = false;

  constructor(
    private router: Router,
    private dataService: DataService,
    private deviceDetectorService: DeviceDetectorService,
    private authenticationService: AuthenticationService,
    translate: TranslateService
  ) {
    translate.addLangs(['en', 'es', 'fr']);
    translate.setDefaultLang('en');
    //
    // // this language will be used as a fallback when a translation isn't found
    // // in the current language
    // translate.setDefaultLang('fr');
    // // the lang to use, if the lang isn't available, it will use the current
    // // loader to get them
    // translate.use('fr');

    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');

    // this.initializeAppcues();
    this.dataService.changeData({
      device: {
        info: this.deviceDetectorService.getDeviceInfo(),
        mobile: this.deviceDetectorService.isMobile(),
        tablet: this.deviceDetectorService.isTablet(),
        desktop: this.deviceDetectorService.isDesktop()
      }
    });

    this.subscription = this.dataService.data$.subscribe(data => {
      Promise.resolve(null).then(() => {
        this.data = data;
          if (this.data && this.data.authenticated === undefined) {
            this.loggedIn = this.authenticationService.isLoggedIn();
          } else {
            this.loggedIn = this.data.authenticated;
          }
          if (this.data && this.data.sidebar === undefined) {
            this.displaySidebar = this.authenticationService.displaySidebar();
          } else {
            this.displaySidebar = this.data.sidebar;
            this.displaySidebar = true;
          }
          if (!data.device) {
            data.device = {
              info: this.deviceDetectorService.getDeviceInfo(),
              mobile: this.deviceDetectorService.isMobile(),
              tablet: this.deviceDetectorService.isTablet(),
              desktop: this.deviceDetectorService.isDesktop()
            };
          }
      });
    });
  }

  onLogout(): void {
    this.loggedIn = false;
    this.displaySidebar = false;
    this.authenticationService.logout();
  }

  initializeAppcues(): void {
    let Appcues = window['Appcues'];
    if (Appcues && Appcues !== undefined) {
      Appcues.start();
      let user = this.authenticationService.user();
      if (user) {
        Appcues.identify(user.id, {
          name: user.name, email: user.username,
          organization: user.organization && user.organization.name
        });

        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            if (Appcues) { Appcues.page(); }
          }
        });
      }
    }
  }
}
