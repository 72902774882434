import { TableConfig } from './table.types';

export const tableDropAction = (event, row, config, dropAction): void => {
  if (config.allowDrop) {
    dropAction.emit([event, row]);
    row.over = false;
  }
};

export const tableMenuAction = (name, row, menuAction) => {
  menuAction.emit([name, row]);
};

export const tableDragAction = (event, row): void => {
  if (event.type === 'dragenter') {
    row.over = true;
  }
  if (event.type === 'dragleave' || event.type === 'dragexit') {
    row.over = false;
  }
};

export const tableClickAction = (event, row, record, clickAction): void => {
  let target = event.target || event.srcElement || event.currentTarget;
  if (
    (target &&
      target.className &&
      target.className.includes('action-menu-icon')) ||
    (target && target.type === 'checkbox') ||
    target.type === 'href'
  ) {
    // Do nothing
  } else {
    if (record !== row) {
      record.selected = false;
    }
    record = row;
    row.selected = true;
    clickAction.emit([event, row]);
  }
};

export const tableReorderStuckFields = (displayedColumns): void => {
  const actionsIndex = displayedColumns.indexOf('actions');
  if (actionsIndex !== -1) {
    displayedColumns.splice(actionsIndex, 1);
    displayedColumns.push('actions');
  }
  const selectIndex = displayedColumns.indexOf('select');
  if (selectIndex !== -1) {
    displayedColumns.splice(selectIndex, 1);
    displayedColumns.unshift('select');
  }
};

export const tableRemoveRecord = (dataSource, value, property = 'id') => {
  dataSource.data = dataSource.data.filter(
    (record) => record[property] !== value
  );
};

export const tableParseRecords = (records: unknown[], config: TableConfig) => {
  return records.map((record: any) => {
    if (record['customFieldData']) {
      Object.keys(record['customFieldData']).forEach((key) => {
        record[key] = record['customFieldData'][key];
      });
    }
    if (config['customRecordFields']) {
      Object.keys(config['customRecordFields']).forEach((key) => {
        record[key] = config['customRecordFields'][key];
      });
    }
    return record;
  });
};
