import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef, PageEvent, MatPaginator } from '@angular/material';
import { combineLatest as observableCombineLatest, Subscription, Observable, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import { find as _find } from 'lodash';

import { AuthenticationService, TimelineRowData, DateTimeRange,
         TimeInterval, DropdownComponent } from '../../shared/index';
import { UploadService, S3PolicyData } from '../../shared/upload.service';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import { ExportDialogData, ExportDialogComponent } from '../../shared/export-dialog/export-dialog.component';
import { FiltersDialogComponent } from '../../shared/filters-dialog/filters-dialog.component';
import { DropdownConfig } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';
import { parseErrors } from '../../shared/api.service';

import { PaySheetService } from '../pay-sheet.service';
import { PaySheet, PaySheetTimeRange } from '../pay-sheet';
import { PayRecord } from '../pay-record';
import { GenerateReportDialogComponent, PayBasisOption } from './generate-report-dialog/generate-report-dialog.component';
import { BulkEditDialogComponent, PayDurationOption } from './bulk-edit-dialog/bulk-edit-dialog.component';
import { DriverPayTimelineComponent } from './timeline/driver-pay-timeline.component';

import { PreferenceService } from '../../preferences/preference.service';
import { Preference } from '../../preferences/preference';
import { JobEvent } from '../../job-events/job-event';
import { JobEventService } from '../../job-events/job-event.service';
import { Driver } from '../../drivers/driver';
import { TruckType } from '../../trucks/truck-type';
import { Carrier } from '../../carriers/carrier';
import { Truck } from '../../trucks/truck';
import { Customer } from '../../customers/customer';
import { Project } from '../../projects/project';
import { Job } from '../../jobs/job';
import { Tag } from '../../tags/tag';
import { TagService } from '../../tags/tag.service';
import { PaySheetFilterService } from '../pay-sheet.filter.service';
import { FilterOption } from '../../shared/filters-panel/filter-option';



export type TimelineEdits = {
  duration: number,
  startTime: string,
  endTime: string,
  payStart: string,
  payEnd: string
};

export type DriverPayStats = {
  drivers: Driver[],
  carriers: Carrier[],
  trucks: Truck[],
  truckTypes: TruckType[],
  jobs: JobEvent[],
  avgTripTime: string,
  payHours: string
  tooltipText: {
    drivers: string,
    carriers: string,
    trucks: string,
    truckTypes: string,
    jobs: string
  }
};

export type DriverPayFilterSelection = {
  job: Job,
  orderNumber: string,
  jobNumber: string,
  tags: Tag[],
  driver: Driver,
  carrier: Carrier,
  truckType: TruckType,
  customer: Customer,
  project: Project
};

@Component({
  selector: 'ruckit-driver-pay',
  templateUrl: './driver-pay.component.html',
  styleUrls: ['./driver-pay.component.scss']
})
export class DriverPayComponent implements OnInit, OnDestroy {
  reportActive = false;
  readOnly = false;
  receivedReport = false;
  editing = false;
  saving = false;
  processing = true;
  processingText = '';
  pollObservable: Subscription;
  pollStatus: Subject<any>;
  empty = false;
  errors = [];
  lastSaved: string;
  actionDropdownOptions = [
    { name: 'New Pay Basis', button: true },
    { name: 'Bulk Edit', button: true },
    { name: 'Reset Edits', button: true },
    { name: 'Reset Report', button: true }
  ];
  view: 'prereport' | 'table' | 'timeline' | 'failed';
  reportType: 'internal' | 'external' | 'all';
  displayAsGrid = true;
  search: string;

  sortBy = 'assignment__driver__profile__last_name';
  sortDirection: 'asc' | 'desc' | '' = 'desc';

  filters: DriverPayFilterSelection;
  filtersDialog: FiltersDialogComponent;
  appliedFilters: FilterOption[] = [];

  jobEvents: JobEvent[];
  jobEventDate: Date;

  paySheet: PaySheet;
  pendingCountReq: Subscription;
  timelineData: TimelineRowData[] = [];
  timelineRange: DateTimeRange;
  payRecords: PayRecord[] = [];
  timelineStats: DriverPayStats;
  selectedDuration: 'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip';
  selectedRows: string[];
  allSelected = true;

  timeInterval: TimeInterval;
  @ViewChild('intervalSelect', { static: true }) intervalSelect: DropdownComponent;
  timeIntervalConfig = { nameProperty: 'title' };
  timeIntervalOptions = [
    { title: '1 Hour', amount: 60 },
    { title: 'Half Hour', amount: 30 },
    { title: 'Quarter Hour', amount: 15 }
  ];

  preference: Preference;
  preferenceKey = 'DriverPayUserPreferences';
  user;
  allowNavigation = false;

  @ViewChild('driverPayTimeline', { static: false }) driverPayTimeline: DriverPayTimelineComponent;
  confirmDialog: MatDialogRef<any>;

  @ViewChild('paySheetPaginator', { static: false }) paySheetPaginator: MatPaginator;
  page = 1;
  pageSize = 10;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public authenticationService: AuthenticationService,
    public preferenceService: PreferenceService,
    public paySheetService: PaySheetService,
    public jobEventService: JobEventService,
    public uploadService: UploadService
  ) {}

  filtersActive(filters: DriverPayFilterSelection): boolean {
    return !!(filters && Object.keys(filters).length);
  }

  selectAll(value: boolean) {
    this.allSelected = value;
  }

  /**
   * Triggers a set for the activeSort and triggers a data get with the new sort props
   *
   * @param  {{ active: string, direction: 'asc' | 'desc' | '' }} event The matSort event properties
   */
  sort(event: { active: string, direction: 'asc' | 'desc' | '' }) {
    this.sortDirection = event.direction;
    switch (event.active) {
      case 'driver':
        this.sortBy = 'assignment__driver__profile__last_name';
        break;
      case 'carrier':
        this.sortBy = 'assignment__driver__carrier__name';
        break;
      case 'truckNumber':
        this.sortBy = 'assignment__truck__name';
        break;
      case 'orderNumber':
        this.sortBy = 'assignment__jobevent__job__order_number';
        break;
    }
    this.loadPayRecords(this.paySheet);
  }

  /**
   * Uses both the report type and the report view to generate a specific header title
   *
   * @return {string} The header title
   */
  headerTitle(): string {
    return (
      this.reportType ? (this.reportType.charAt(0).toUpperCase() + this.reportType.slice(1) + ' ') : 'All '
    ) + 'Drivers Pay ' + (
      this.view === 'prereport' ? 'Prereport' : 'Report'
    );
  }

  /**
   * Generates a specific returnTo url based on both the 'recievedReport' flag and the reportType
   *
   * @return {string} The generated returnTo url
   */
  headerUrl(): string {
    return '/pay-reports/' + (this.receivedReport ? 'received' : 'all') + (this.reportType ? '?reportType=' + this.reportType : '');
  }

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.getPreferences();
    this.allSubscriptionsToUnsubscribe.push(
      observableCombineLatest(
        this.route.params, this.route.queryParams,
        (params, qparams) => ({ params, qparams })
      ).subscribe(result => {
        this.reportType = result.params['type'];
        if (/^\d{4}[-]\d{2}[-]\d{2}$/.test(result.params['id'])) {
          this.jobEventDate = moment(result.params['id'], 'YYYY-MM-DD').toDate();
          this.readOnly = !!(result.qparams['readOnly'] && result.qparams['readOnly'] === 'true');
        } else {
          this.readOnly = true;
          this.receivedReport = true;
          this.empty = true;
        }
        if (this.pollStatus) {
          this.pollStatus.next();
          this.pollStatus.complete();
        }
        this.pollReportStatus(result.params['id']);
      })
    );
    this.intervalSelect.selectedOption = this.timeIntervalOptions[0];
    this.timeInterval = this.timeIntervalOptions[0].amount;
  }

  ngOnDestroy(): void {
    if (this.pollStatus) {
      this.pollStatus.next();
      this.pollStatus.complete();
    }
    if (this.pollObservable && typeof this.pollObservable.unsubscribe === 'function') {
      this.pollObservable.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /**
   * Creates or regenerates a pay report using one of four duration options
   *
   * @param  {Date} date The selected date
   * @param  {'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip'} duration
   * The duration options used when generating pay records
   * @param  {DriverPayFilterSelection} filter Filter selection to be used in the report queries
   * @param  {boolean} save Flag for deciding if we need to create or regenerate a report
   */
  createPaySheet(
    date: Date,
    duration: 'all-driver-items' | 'each-driver-item' | 'all-geo-trips' | 'each-geo-trip' = 'all-driver-items',
    filter?: DriverPayFilterSelection,
    save?: boolean
  ) {
    this.processing = true;
    this.empty = false;
    this.saving = true;
    this.selectedDuration = duration;
    this.savePreferences();
    const timeRange: PaySheetTimeRange = {
      start_time: moment(date).startOf('day').toISOString(),
      end_time: moment(date).endOf('day').toISOString(),
    };
    if (save) {
      this.processingText = 'Resetting the Report';
      this.paySheetService.saveReport(
        duration, moment(date).format('YYYY-MM-DD'), timeRange
      ).subscribe(
        report => {
          this.pollReportStatus(report.id);
        }, err => this.errors = parseErrors(err), () => {
          this.saving = false;
          this.reportActive = true;
        });
    } else {
      this.processingText = 'Generating Report';
      this.paySheetService.createReport(
        duration, moment(date).format('YYYY-MM-DD'), timeRange
      ).subscribe(report => {
        this.saving = false;
        this.pollReportStatus(report.id);
      }, err => {
        this.errors = parseErrors(err);
        this.editing = false;
        this.processing = false;
        this.reportActive = false;
      });
    }
  }

  /**
   * Polls the paySheet endpoint for the prcessing status every 5 seconds and then sets the UI based on that status
   *
   * @param {string} id The paySheet id (key or mm-dd-yyyy date format)
   */
  pollReportStatus(id: string) {
    this.pollStatus = new Subject();
    if (this.receivedReport) { this.paySheetService.slug = 'driver-pay/pay-sheets/approvals/'; }
    this.pollObservable = this.paySheetService.getUpdate(5000, id)
      .pipe(takeUntil(this.pollStatus))
      .subscribe(reportData => {
      switch (reportData.processStatus) {
        case 'complete':
          this.pollStatus.next();
          this.pollStatus.complete();
          this.processing = true;
          this.loadPayRecords(reportData);
          break;
        case 'processing':
          this.processing = true;
          this.empty = false;
          this.saving = true;
          break;
        case 'failed':
          this.pollStatus.next();
          this.pollStatus.complete();
          this.editing = false;
          this.processing = false;
          this.reportActive = false;
          this.switchView('failed');
          if (this.receivedReport) { this.empty = true; }
          break;
        default:
          this.pollStatus.next();
          this.pollStatus.complete();
          this.editing = false;
          this.processing = false;
          this.reportActive = false;
          this.switchView('prereport');
          if (this.receivedReport) { this.empty = true; }
      }
    }, err => {
      this.pollStatus.next();
      this.pollStatus.complete();
      this.paySheet = null;
      this.payRecords = [];
      this.editing = false;
      this.processing = false;
      this.reportActive = false;
      this.switchView('prereport');
      if (this.receivedReport) { this.empty = true; }
    });
  }

  /**
   * Get the selected report's records and set UI flags
   *
   * @param  {PaySheet} report The current Pay Report
   */
  loadPayRecords(report: PaySheet) {
    if (report) { this.paySheet = report; }
    if (this.receivedReport) { this.paySheetService.slug = 'driver-pay/pay-records/approvals/'; }
    let params = {
      page: this.page,
      page_size: this.pageSize,
      carrier_type: this.reportType,
      search: this.search,
      ordering: this.sortDirection === '' ?
        null : (this.sortDirection === 'asc' ? '' : '-') + this.sortBy
    };
    if (this.filters) { params = { ...params, ...this.parseFilters(this.filters) }; }
    // this.processing = true;
    this.paySheetService.getReportRecords(report.id, params).subscribe(records => {
      report.payRecords = records;
      this.setupReportData(report);
    }, (err) => {
      this.editing = false;
      this.processing = false;
      this.reportActive = false;
      this.switchView('prereport');
      if (this.receivedReport) { this.empty = true; }
    });
  }

  /**
   * Save a list of rowEdits and apply those updates to the UI correctly
   *
   * @param  {PayRecord[]} rowEdits An array of row edits to be saves
   */
  savePaySheet(rowEdits: PayRecord[]) {
    this.saving = true;
    let saveReqs: Observable<PayRecord>[] = [];
    rowEdits.forEach(row => {
      saveReqs.push(this.paySheetService.saveRecord(row.id, row));
    });
    forkJoin(saveReqs).subscribe(() => {
      // this.getTimelineStats(this.payRecords);
      this.lastSaved = moment().calendar();
    }, err => this.errors = this.paySheetService.parsePayRecordErrors(err), () => this.saving = false);
  }

  /**
   * Take the report data and set it up for use in the various UI views
   *
   * @param  {PaySheet} report The full report data
   */
  setupReportData(report: PaySheet) {
    if (!this.jobEventDate) {
      this.jobEventDate = moment(report.reportDate.split('T')[0], 'YYYY-MM-DD').toDate();
    }
    this.jobEvents = report.payRecords.map(p => (p.data.assignment.jobevent)).filter((v, i, s) => s.indexOf(v) === i);
    this.editing = true; this.reportActive = true; this.empty = report.payRecords.length === 0; this.processing = false;
    this.readOnly = report.isApproved || this.user && this.user.organization && (report.payerOrganization !== this.user.organization.id);
    this.lastSaved = moment(report.lastModified).calendar();
    this.payRecords = report.payRecords.map(row => ({...row, selected: false, editing: false}));
    // this.getTimelineStats(this.payRecords);

    let startTimes: string[] = [], endTimes: string[] = [];
    this.payRecords.forEach(record => {
      if (record.data && record.data.assignment) {
        Object.keys(record.data.assignment).forEach(key => {
          if (['trips', 'predictedTrips', 'punchCards'].indexOf(key) > -1 && record.data.assignment[key]) {
            record.data.assignment[key].forEach(obj => {
              if (obj && obj.startTimeTimestamp) { startTimes.push(obj.startTimeTimestamp); }
              if (obj && obj.endTimeTimestamp) { endTimes.push(obj.endTimeTimestamp); }
            });
          }
          if (['shifts'].indexOf(key) > -1 && record.data.assignment[key]) {
            record.data.assignment[key].forEach(obj => {
              if (obj && obj.startTime) { startTimes.push(obj.startTime); }
              if (obj && obj.endTime) { endTimes.push(obj.endTime); }
            });
          }
        });
      }
      if (
        (record.data.assignment && record.data.assignment.uniqueStart) ||
        (record.data.assignment.jobevent && record.data.assignment.jobevent.shift1StartTimestamp)
      ) {
        startTimes.push((record.data.assignment.uniqueStart || record.data.assignment.jobevent.shift1StartTimestamp));
      }
    });
    this.processing = false;
    this.timelineRange = this.setTimelineRange(startTimes, endTimes);
  }

  switchView(view: 'prereport' | 'table' | 'timeline' | 'failed') {
    const previousVeiw = this.view;
    this.view = view;
    if (previousVeiw === 'prereport' && this.search && this.paySheet) {
      this.loadPayRecords(this.paySheet);
    }
  }

  /**
   * Change the search term and trigger a new record load
   *
   * @param {string} term The updated search term
   */
  changeSearch(term: string) {
    this.search = term;
    if (this.paySheet) { this.loadPayRecords(this.paySheet); }
  }

  /**
   * Reset the poller subject, and navigate the url to the specified date
   *
   * @param  {Date[]} dates The selected date list (comes back as a list from the date selector)
   */
  onDateChanged(dates: Date[]) {
    this.jobEventDate = dates[0];
    if (this.reportType && this.reportType !== 'all') {
      this.router.navigate(['driver-pay', moment(this.jobEventDate).format('YYYY-MM-DD'), this.reportType]);
    } else {
      this.router.navigate(['driver-pay', moment(this.jobEventDate).format('YYYY-MM-DD')]);
    }
  }

  /**
   * Opens the generate report dialog and sets up the callback for creating a report
   *
   */
  openGenerateReportDialog(save?: boolean) {
    const saveFlag = save ? save : this.reportActive;
    this.editing = false;
    const dialog = this.dialog.open(GenerateReportDialogComponent, {
      width: '380px',
      data: { selectedOption: this.selectedDuration ? this.selectedDuration : null }
    });
    dialog.componentInstance.callback = (selectedOption: PayBasisOption) =>
                                        this.createPaySheet(this.jobEventDate, selectedOption.value, null, saveFlag);
  }

  /**
   * Opens the bulk edit dialog and sets up the callback for resetting selected rows using a specified pay basis
   *
   */
  openBulkPayBasisDialog() {
    const dialog = this.dialog.open(GenerateReportDialogComponent, {
      width: '380px',
      data: {
        selectedOption: this.selectedDuration ? this.selectedDuration : null,
        title: 'Edit Selected Rows'
      }
    });
    dialog.componentInstance.callback = (selectedOption: PayDurationOption) => {
      if (!this.readOnly) {
        let editedRows: PayRecord[] = [];
        this.payRecords.forEach(row => {
          if (row.data && (this.selectedRows.includes(row.data.referenceId) || this.allSelected)) {
            row.data.rowData.payBasis = selectedOption.value;
            row.data.rowData.payLines = this.generatePayPeriods(row.data.rowData);
            editedRows.push(row);
          }
        });
        this.savePaySheet(this.payRecords.filter(row => (this.selectedRows.includes(row.data.referenceId))));
      }
    };
  }

  /**
   * Opens the pay report filter dialog and sets up the callback for parsing and using the selection from that
   *
   */
  openFilters() {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px'
    });

    if (dialog) {
      const baseDropdownConfig = <DropdownConfig>{
        selectText: 'Select Job',
        loadingText: 'Loading Jobs...',
        noResultsText: 'No Jobs',
        service: PaySheetFilterService,
        serviceId: this.paySheet.id,
        serviceFunction: 'listFilters',
        searchKey: 'filter_search',
        serviceFunctionScope: 'jobs'
      };

      dialog.componentInstance.filters = [
        {
          type: 'dropdown', field: 'job', label: 'Job',
          dropdownConfig: baseDropdownConfig
        }, {
          type: 'text', field: 'orderNumber', label: 'Order Number'
        }, {
          type: 'text', field: 'jobNumber', label: 'Job Number',
        }, {
          type: 'dropdown', field: 'driver', label: 'Driver',
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            serviceFunctionScope: 'drivers'
          }
        }, {
          type: 'dropdown', field: 'truckType', label: 'Truck Type',
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: 'Select Truck Type',
            loadingText: 'Loading Truck Type...',
            noResultsText: 'No Truck Types',
            serviceFunctionScope: 'truck-types'
          }
        }, {
          type: 'dropdown', field: 'customer', label: 'Customer',
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: 'Select Customer',
            loadingText: 'Loading Customer...',
            noResultsText: 'No Customers',
            serviceFunctionScope: 'customers'
          }
        }, {
          type: 'dropdown', field: 'project', label: 'Project',
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: 'Select Project',
            loadingText: 'Loading Project...',
            noResultsText: 'No Projects',
            serviceFunctionScope: 'projects'
          }
        }, {
          type: 'dropdown', field: 'carrier', label: 'Carrier',
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: 'Select Carrier',
            loadingText: 'Loading Carrier...',
            noResultsText: 'No Carriers',
            serviceFunctionScope: 'carrier-organizations'
          }
        }, {
          type: 'dropdown', field: 'tags', label: 'Markets',
          dropdownConfig: {
            ...baseDropdownConfig,
            multiselect: true,
            service: TagService,
            serviceFunctionScope: null,
            serviceId: null,
            serviceFunction: 'list',
            searchKey: 'search', query: {},
            selectText: 'Select Markets',
            loadingText: 'Loading Markets...',
            noResultsText: 'No Markets'
          }
        }
      ];
      dialog.componentInstance.callback = res => {
        this.page = 1;
        this.filters = res;
        this.paySheetPaginator.pageIndex = 0;
        this.loadPayRecords(this.paySheet);
      };
      if (this.filters) { dialog.componentInstance.model = {...this.filters}; }
      this.filtersDialog = dialog.componentInstance;
    }
  }

  /**
   * Manages the current filter selection and generates the expected query format for the api request
   *
   * @param  {DriverPayFilterSelection} filters The selected filters from the filter dialog
   */
  parseFilters(filters: DriverPayFilterSelection): {[key: string]: string} {
    let query = {};
    const queryKeys = {
      job: 'assignment__jobevent__job',
      orderNumber: 'assignment__jobevent__job__order_number__icontains',
      jobNumber: 'assignment__jobevent__job__job_number',
      driver: 'assignment__driver',
      truckType: 'assignment__truck__truck_type',
      customer: 'assignment__jobevent__customer_organization',
      project: 'assignment__jobevent__job__project',
      carrier: 'assignment__driver__carrier__organization',
      tags: 'assignment__jobevent__job__tags'
    };
    if (filters) {
      this.appliedFilters = [];
      Object.keys(filters).forEach((key) => {
        let value = filters[key];
        if (key === 'tags') {
          if (value && value.length > 0) {
            value = value.map(tag => { return tag.name; }).join(',');
            query[queryKeys[key]] = value;
          }
        } else {
          query[queryKeys[key]] = filters[key] && filters[key].id || value;
        }

        let displayValues = filters[key] && filters[key]['name'] ? filters[key]['name'] : filters[key];
        let title = key.charAt(0).toUpperCase() + key.slice(1);
        if (key === 'orderNumber') { title = 'Order #'; }
        if (key === 'jobNumber') { title = 'Job #'; }
        let filter = new FilterOption({
          key: key,
          filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
          title: title,
          displayValues: displayValues || null,
          query: query
        });

        this.appliedFilters.push(filter);
      });
    }
    return query;
  }

  filtersModified(appliedFilters): void {
    Object.keys(this.filters).forEach((key) => {
      let appliedFilter = _find(appliedFilters, {key: key});
      if (!appliedFilter) { delete this.filters[key]; }
    });
    this.page = 1;
    this.loadPayRecords(this.paySheet);
  }

  /**
   * Opens the bulk edit dialog and sets up the callback for applying any of the selected edits from it
   *
   */
  openBulkEditDialog() {
    const dialog = this.dialog.open(BulkEditDialogComponent, { width: '380px', data: { view: this.view } });
    dialog.componentInstance.callback = (changes) => { this.bulkEdits(changes); };
  }

  /**
   * Apply bulk time edits
   *
   * @param {TimelineEdits} index The specified row index
   */
  bulkEdits(edits: TimelineEdits) {
    if (!this.readOnly) {
      this.payRecords.forEach(row => {
        if (this.view === 'table' && row.data && row.data.rowData.payLines.length === 0) {
          row.data.rowData.payLines = [{startDatetime: '', endDatetime: ''}];
        }
        if (row.data && (this.selectedRows.includes(row.data.referenceId) || this.allSelected) && row.data.rowData.payLines.length) {
          if (edits.duration !== 0) {
            row.data.rowData.payLines[row.data.rowData.payLines.length - 1]
              .endDatetime = moment(row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime)
                                .add(edits.duration, 'minutes').toISOString();
          }
          if (edits.startTime) {
            row.data.rowData.payLines[0].startDatetime = moment(
              this.jobEventDate.toISOString().split('T')[0] + edits.startTime, 'YYYY-MM-DDHH:mm'
            ).toISOString();
          }
          if (edits.endTime) {
            row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = moment(
              this.jobEventDate.toISOString().split('T')[0] + edits.endTime, 'YYYY-MM-DDHH:mm'
            ).toISOString();
          }
          if (edits.payStart) {
            switch (edits.payStart) {
              case 'scheduled-start':
                row.data.rowData.payLines[0].startDatetime = row.data.assignment.uniqueStart ||
                                                             row.data.assignment.jobevent.shift1StartTimestamp;
                break;
              case 'shift-start':
                row.data.rowData.payLines[0].startDatetime = row.data.assignment.shifts &&
                                                             row.data.assignment.shifts[0] &&
                                                             row.data.assignment.shifts[0].startTime;
                break;
              case 'geofence-start':
                row.data.rowData.payLines[0].startDatetime = row.data.rowData.predictedTrips &&
                                                             row.data.rowData.predictedTrips[0] &&
                                                             row.data.rowData.predictedTrips[0].startTimeTimestamp;
                break;
              case 'first-load':
                row.data.rowData.payLines[0].startDatetime = row.data.rowData.trips &&
                                                             row.data.rowData.trips[0] &&
                                                             row.data.rowData.trips[0].loadingCompleteTime;
                break;
            }
            if (!row.data.rowData.payLines[0].startDatetime) {
              row.data.rowData.payLines[0].startDatetime = row.data.assignment.uniqueStart ||
                                                           row.data.assignment.jobevent.shift1StartTimestamp;
            }
          }
          if (edits.payEnd) {
            switch (edits.payEnd) {
              case 'shift-end':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.assignment.shifts &&
                                                                                    row.data.assignment.shifts[0] &&
                                                                                    row.data.assignment.shifts[0].endTime;
                break;
              case 'geofence-end':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.predictedTrips &&
                                                                                    row.data.rowData.predictedTrips[0] &&
                                                                                    row.data.rowData
                                                                                      .predictedTrips[
                                                                                        row.data.rowData.predictedTrips.length - 1
                                                                                      ].endTimeTimestamp;
                break;
              case 'last-load':
                row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = row.data.rowData.trips &&
                                                                                    row.data.rowData.trips[0] &&
                                                                                    row.data.rowData
                                                                                      .trips[row.data.rowData.trips.length - 1]
                                                                                      .endTimeTimestamp;
                break;
            }
            if (
              !row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime &&
              row.data.rowData.payLines[0].startDatetime
            ) {
              row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime = moment(
                row.data.rowData.payLines[0].startDatetime
              ).add(1, 'hours').toISOString();
            }
          }
        }
      });
      if (this.allSelected) {
        this.savePaySheet(this.payRecords);
      } else {
        this.savePaySheet(this.payRecords.filter(row => (this.selectedRows.includes(row.data.referenceId))));
      }

    }
    if (this.driverPayTimeline) { this.driverPayTimeline.resetTimelineSubs(); }
  }

  /**
   * Figures out the timeline range based on a set of start and end times
   *
   * @param {string[]} startTimes The list of start times
   * @param {string[]} endTimes The list of end times
   * @return {DateTimeRange} The range used by the timeline to limit the displayed time range
   */
  setTimelineRange(startTimes: string[], endTimes: string[]): DateTimeRange {
    const startMoments = startTimes.map(time => (
      moment(this.jobEventDate).startOf('day').subtract(1, 'days').isBefore(time) ?
      moment(time) : moment(this.jobEventDate).endOf('day')
    ));
    let endMoments = [];
    endTimes.forEach(time => { if (!!time) { endMoments.push(moment(time)); }});
    endMoments.push(moment(this.jobEventDate).endOf('day').subtract(2, 'hours'));
    return {
      startDatetime: moment.min(startMoments).startOf('hour').toISOString(),
      endDatetime: moment.max(endMoments).endOf('hour').toISOString()
    };
  }

  /**
   * Generate pay periods based on a selected duration option
   *
   * @param {TimelineRowData} rowData Pay Record timeline Data
   * @return {DateTimeRange[]} the list of generated pay periods
   */
  generatePayPeriods(rowData: TimelineRowData): DateTimeRange[] {
    let payLines: DateTimeRange[] = [];
    switch (rowData.payBasis) {
      case 'all-driver-items':
        if (rowData.punchcards && rowData.punchcards.length &&
            rowData.punchcards[0].endTimeTimestamp) {
          payLines = [{
            endDatetime: rowData.punchcards[rowData.punchcards.length - 1].endTimeTimestamp ?
                         rowData.punchcards[rowData.punchcards.length - 1].endTimeTimestamp :
                         (rowData.punchcards[rowData.punchcards.length - 2] &&
                          rowData.punchcards[rowData.punchcards.length - 2].endTimeTimestamp ?
                          rowData.punchcards[rowData.punchcards.length - 2].endTimeTimestamp :
                          rowData.punchcards[0].endTimeTimestamp),
            startDatetime: this.user.crh ? rowData.shifts.find(shift => (shift.type === 'scheduled')).startDatetime :
                                           rowData.punchcards[0].startTimeTimestamp
          }];
        }
        if (payLines.length === 0 && rowData.trips && rowData.trips.length &&
            rowData.trips[0].endTimeTimestamp) {
          payLines = [{
            endDatetime: rowData.trips[rowData.trips.length - 1].endTimeTimestamp ?
                         rowData.trips[rowData.trips.length - 1].endTimeTimestamp :
                         (rowData.trips[rowData.trips.length - 2] &&
                          rowData.trips[rowData.trips.length - 2].endTimeTimestamp ?
                          rowData.trips[rowData.trips.length - 2].endTimeTimestamp :
                          rowData.trips[0].endTimeTimestamp),
            startDatetime: rowData.trips[0].startTimeTimestamp
          }];
        }
        break;
      case 'each-driver-item':
        if (rowData.punchcards && rowData.punchcards.length) {
          rowData.punchcards.forEach(punchcard => {
            if (punchcard.startTimeTimestamp && punchcard.endTimeTimestamp) {
              payLines.push({
                startDatetime: punchcard.startTimeTimestamp,
                endDatetime: punchcard.endTimeTimestamp,
              });
            }
          });
        } else {
          rowData.trips.forEach(trip => {
            if (trip.startTimeTimestamp && trip.endTimeTimestamp) {
              payLines.push({
                startDatetime: trip.startTimeTimestamp,
                endDatetime: trip.endTimeTimestamp,
              });
            }
          });
        }
        break;
      case 'all-geo-trips':
        if (rowData.predictedTrips && rowData.predictedTrips.length &&
            rowData.predictedTrips[0].endTimeTimestamp) {
          payLines = [{
            endDatetime: rowData.predictedTrips[rowData.predictedTrips.length - 1].endTimeTimestamp ?
                         rowData.predictedTrips[rowData.predictedTrips.length - 1].endTimeTimestamp :
                         (rowData.predictedTrips[rowData.predictedTrips.length - 2] &&
                          rowData.predictedTrips[rowData.predictedTrips.length - 2].endTimeTimestamp ?
                          rowData.predictedTrips[rowData.predictedTrips.length - 2].endTimeTimestamp :
                          rowData.predictedTrips[0].endTimeTimestamp),
            startDatetime: this.user.crh ? rowData.shifts.find(shift => (shift.type === 'scheduled')).startDatetime :
                                           rowData.predictedTrips[0].startTimeTimestamp
          }];
        }
        break;
      case 'each-geo-trip':
        rowData.predictedTrips.forEach(trip => {
          if (trip.startTimeTimestamp && trip.endTimeTimestamp) {
            payLines.push({
              startDatetime: trip.startTimeTimestamp,
              endDatetime: trip.endTimeTimestamp,
            });
          }
        });
        break;
    }
    return payLines;
  }

  /**
   * Gets the timeline stats from the displayed list of payRecords
   *
   * @param {PayRecord[]} payRecords The list of pay records
   */
  getTimelineStats(payRecords: PayRecord[]) {
    this.timelineStats = <DriverPayStats>{ drivers: [], trucks: [], truckTypes: [], carriers: [], jobs: [] };
    let tripTimeList: number[] = [];
    let tripTimeAvg = 0;
    let payMinutes = 0;
    payRecords.forEach(row => {
      if (row.data.assignment) {
        if (row.data.assignment.driver) {
          this.timelineStats.carriers.push(row.data.assignment.driver.carrier);
          this.timelineStats.drivers.push(row.data.assignment.driver);
        }
        if (row.data.assignment.truck) {
          this.timelineStats.trucks.push(row.data.assignment.truck);
          this.timelineStats.truckTypes.push(row.data.assignment.truck.truckType);
        }
      }
      if (row.data.assignment.jobevent) { this.timelineStats.jobs.push(row.data.assignment.jobevent); }
      if (row.data.rowData) {
        if (row.data.rowData.trips) { row.data.rowData.trips.forEach(trip => trip && tripTimeList.push(trip.completedTripDuration)); }
        if (row.data.rowData.payAdjustmentTotal) { payMinutes += row.data.rowData.payAdjustmentTotal; }
        row.data.rowData.payLines.forEach(period => {
          if (period.startDatetime && period.endDatetime) {
            payMinutes += moment(period.endDatetime).diff(period.startDatetime, 'minutes');
          }
        });
      }
    });
    tripTimeList.forEach(val => tripTimeAvg += val);
    this.timelineStats = <DriverPayStats>{
      drivers: this.timelineStats.drivers &&
        this.timelineStats.drivers.filter((e, i) => this.timelineStats.drivers.findIndex(a => (a && a['id'] === e['id'])) === i),
      trucks: this.timelineStats.trucks &&
        this.timelineStats.trucks.filter((e, i) => this.timelineStats.trucks.findIndex(a => (a && a['id'] === e['id'])) === i),
      truckTypes: this.timelineStats.truckTypes &&
        this.timelineStats.truckTypes.filter((e, i) => this.timelineStats.truckTypes.findIndex(a => (a && a['id'] === e['id'])) === i),
      carriers: this.timelineStats.carriers &&
        this.timelineStats.carriers.filter((e, i) => this.timelineStats.carriers.findIndex(a => (a && a['id'] === e['id'])) === i),
      jobs: this.timelineStats.jobs &&
        this.timelineStats.jobs.filter((e, i) => this.timelineStats.jobs.findIndex(a => a && (a && a['id'] === e['id'])) === i),
      avgTripTime: Number((tripTimeAvg / tripTimeList.length) / 60000).toFixed(2) + ' mins',
      payHours: Number(payMinutes / 60).toFixed(2)
    };
    this.timelineStats.tooltipText = {
      drivers: this.timelineStats.drivers && this.timelineStats.drivers.map(driver => driver.name).join('\n '),
      trucks: this.timelineStats.trucks && this.timelineStats.trucks.map(truck => truck.name).join('\n '),
      truckTypes: this.timelineStats.truckTypes && this.timelineStats.truckTypes.map(type => type.name).join('\n '),
      carriers: this.timelineStats.carriers && this.timelineStats.carriers.map(carrier => carrier.name).join('\n '),
      jobs: this.timelineStats.jobs && this.timelineStats.jobs.map(job => job.jobDisplayName).join('\n '),
    };
  }

  /**
   * Resets the pay records based on the currently applied duration
   *
   */
  resetPayPeriods() {
    if (!this.readOnly) {
      this.payRecords.forEach(row => {
        row.data.rowData.payLines = this.generatePayPeriods(row.data.rowData);
        row.data.rowData.payBasis = this.selectedDuration;
      });
      this.savePaySheet(this.payRecords);
      this.setPaySheetPage();
    }
    if (this.driverPayTimeline) { this.driverPayTimeline.resetTimelineSubs(); }
  }

  /**
   * Resets a specific pay record based on the currently applied duration
   *
   * @param {number} index The specified row index
   */
  resetRow(index: number) {
    if (!this.readOnly) {
      this.payRecords[index].data.rowData.payLines = this.generatePayPeriods(this.payRecords[index].data.rowData);
      this.savePaySheet([this.payRecords[index]]);
    }
    if (this.driverPayTimeline) { this.driverPayTimeline.resetTimelineSubs(); }
  }

  /**
   * Opens the report approval dialog and sets up the approval callback
   *
   * @param {string} type The specified status type
   */
  changeReportStatus(type: string) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Approve Report?',
      body: 'Once approved, this pay report cannot be altered or edited any further!',
      close: 'Cancel',
      accept: 'Approve'
    };
    this.confirmDialog.afterClosed().subscribe(approved => {
      if (approved) {
        const date = moment(this.jobEventDate).format('YYYY-MM-DD');
        this.paySheetService.approveReport(date).subscribe(
          report => this.readOnly = report.isApproved, err => this.errors = parseErrors(err)
        );
      }
    });
  }

  /**
   * Opens the export dialog and lets the user select a specific export type
   *
   */
  exportPayRecords() {
    let scope = {
      driverPayFormats: {
        raw: false,
        hiredHauler: false,
        payroll: false,
        equipment: false,
        hourlyTicket: false
      }
    };
    let params = new HttpParams();
    this.dialog.open(ExportDialogComponent, {
      width: '430px',
      data: <ExportDialogData>{
        type: 'driver pay',
        buttonText: 'Generate Selected Exports',
        params: params,
        scope: scope,
        callback: (exportType: {
          raw: boolean,
          hiredHauler: boolean,
          payroll: boolean,
          equipment: boolean,
          hourlyTicket: boolean
        }) => {
          let selectedRows: PayRecord[];
          if (this.selectedRows && this.selectedRows.length && !this.allSelected) {
            selectedRows = this.payRecords.filter(row => this.selectedRows.includes(row.data.referenceId));
            this.manageExportFile(selectedRows, exportType);
            this.dialog.closeAll();
          } else {
            if (this.receivedReport) { this.paySheetService.slug = 'driver-pay/pay-records/approvals/'; }
            this.paySheetService.getAllReportRecords(this.paySheet.id, 10, {
              carrier_type: this.reportType,
              search: this.search,
              ordering: this.sortDirection === '' ?
                null : (this.sortDirection === 'asc' ? '' : '-') + this.sortBy,
              ...this.parseFilters(this.filters)
            }).subscribe(records => {
              selectedRows = records;
              this.manageExportFile(selectedRows, exportType);
              this.dialog.closeAll();
            }, err => this.errors = parseErrors(err));
          }
        }
      }
    });
  }

  /**
   * Formats and uploads the export file based on the selected export type
   *
   * @param {PayRecord[]} payRecords The pay records to be exported
   * @param {boolean} exportType The exportType flag (specfies Viewpoint or not)
   */
  manageExportFile(payRecords: PayRecord[], exportType: {
    raw: boolean,
    hiredHauler: boolean,
    payroll: boolean,
    equipment: boolean,
    hourlyTicket: boolean
  }) {
    Object.keys(exportType).forEach((key: 'raw' | 'hiredHauler' | 'payroll' | 'equipment' | 'hourlyTicket') => {
      if (exportType[key]) {
        const fileData = this.paySheetService.downloadCSV(
          'driverpay-' + key + '-export-' + moment().format('YYYY-MM-DD') + (this.reportType ? '-' + this.reportType : ''),
          this.paySheetService.formatExport(payRecords, key)
        );
        this.uploadService.getS3Policy().subscribe((policy: S3PolicyData) => {
          const organization = this.authenticationService.getOrganization();
          const dateString = moment().format('YYYY-MM-DD_hhmmssA');
          policy['fields']['key'] = `exports/${organization.id}/driverpay-export-${dateString}.csv`;
          this.uploadService.uploadToS3(policy, fileData, 'text/csv').subscribe(() => {
            // Great!
          }, err => {
            throw err;
          });
        });
      }
    });
  }

  /**
   * Action handler for the row actions
   *
   * @param {any} option The specified row action
   */
  setSelectedAction(option) {
    switch (option.name) {
      case 'New Pay Basis':
        this.openBulkPayBasisDialog();
        break;
      case 'Bulk Edit':
        this.openBulkEditDialog();
        break;
      case 'Reset Edits':
        this.resetPayPeriods();
        break;
      case 'Reset Report':
        this.openGenerateReportDialog();
        break;
    }
  }

  /**
   * Sets the report page and ensures the various UI subs are reset properly
   *
   * @param {PageEvent} e The mat-paginator page change event
   */
  setPaySheetPage(e?: PageEvent) {
    if (e) { this.page = e.pageIndex + 1; } else {
      this.page = 1;
      if (this.paySheetPaginator) { this.paySheetPaginator.firstPage(); }
    }
    this.selectedRows = [];
    this.loadPayRecords(this.paySheet);
    let timelineEl = document.getElementsByClassName('timeline-container');
    if (timelineEl[0]) { timelineEl[0].scrollTo({ top: 0 }); }
    let tableEl = document.getElementsByClassName('mat-table');
    if (tableEl[0]) { tableEl[0].scrollTo({ top: 0 }); }
    if (this.driverPayTimeline) { this.driverPayTimeline.resetTimelineSubs(); }
  }

  // preference methods
  getPreferences() {
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: this.user && this.user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preference = preferences[0];
        this.parsePreferences(this.preference);
      }
    }, err => this.errors = parseErrors(err));
  }

  savePreferences() {
    this.preferenceService.save({
      ...this.preference,
      name: this.preferenceKey,
      type: 'user',
      profile: this.user && this.user.id,
      blob: {
        payBasis: this.selectedDuration,
        view: this.view
      }
    }).subscribe(preference => {
      this.preference = preference;
    }, err => this.errors = parseErrors(err));
  }

  parsePreferences(preference: Preference) {
    if (preference.blob) {
      this.selectedDuration = preference.blob['payBasis'] || this.selectedDuration;
      this.switchView(preference.blob['view'] || this.view || 'timeline');
    }
  }
}
