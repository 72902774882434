<ng-container *ngIf="viewType === 'navbar'; else fullview">
  <button class="btn btn-transparent" [matMenuTriggerFor]="notificationmenu" (menuOpened)="openNotifications()">
    <img src="assets/img/icon-bell-outline.svg" class="icon-bell" />
    <span *ngIf="unreadCount > 0" class="count">{{ unreadCountLabel }}</span>
  </button>
  <mat-menu class="notification-menu" #notificationmenu="matMenu" (closed)="closeNotifications()">
    <div class="no-notifications" *ngIf="!errors.length && notifications.length === 0">
      <i class="icon-bell"></i>
      <h2 translate>No Notifications</h2>
    </div>
    <ng-container *ngFor="let notification of notifications">
      <a mat-menu-item class="notification" [ngClass]="{'read': !notification.unread }" (click)="selectNotification(notification)">
        <div class="icon">
          <span class="{{ notification.icon }}"></span>
        </div>
        <div class="text">{{ notification.description | translate }}</div>
        <div class="time">{{ notification.timestamp | timeFromNow }}</div>
      </a>
    </ng-container>
    <a class="notifications-link" [routerLink]="['/notifications']">See All Notifications</a>
  </mat-menu>
</ng-container>
<ng-template #fullview>
  <div class="notification-list" (scroll)="onScroll($event)">
    <div class="date-group" *ngFor="let day of notificationDays">
      <div class="date-header">{{ day.date | moment: 'MM/DD/YYYY' }}</div>
      <div class="notification" *ngFor="let notification of day.notifications">
        <a (click)="selectNotification(notification)">
          <div class="icon">
            <span class="{{ notification.icon }}"></span>
          </div>
          <div class="text">{{ notification.description | translate }}</div>
          <div class="time">{{ notification.timestamp | moment: 'h:mm A' }}</div>
        </a>
      </div>
    </div>
  </div>
</ng-template>
