<div class="mobile-driver-details" *ngIf="assignment && assignment.driver">
  <div class="header">
    <div class="truck-photo" [ngClass]="{empty: !assignment.truck}">
      <div class="image" *ngIf="assignment.truck">
        <img *ngIf="assignment.truck.image" [src]="assignment.truck.editTruckImage" />
        <i *ngIf="!assignment.truck.image" class="icon-fleet"></i>
      </div>
    </div>
    <div class="driver-photo">
      <span *ngIf="!assignment.driver.image">{{assignment.driver.name[0]}}</span>
      <img *ngIf="assignment.driver.image" [src]="assignment.driver.jobDetailImage" />
    </div>
    <div class="truck-details">
      <h2>{{ assignment.driver.name }}</h2>
      <h3 *ngIf="assignment.truck">{{ assignment.truck.name }} - {{ assignment.truck.truckTypeName }}</h3>
      <p>{{ assignment.driver.organizationName }}</p>
    </div>
  </div>
  <div class="details">
    <div class="current-trip">
      <div class="trip-status">
        <span class="icon-locations"></span>
        <h3>{{ assignment.tripStatus }}</h3>
        <p>{{ jobEvent && jobEvent.jobDisplayName }}</p>
      </div>
      <div class="status-time">
        <h3>{{ assignment.tripStatusTime }}</h3>
      </div>
    </div>
    <div class="day-details">
      <p class="bold" translate>Day Details</p>
      <div>
        <span class="icon-material"></span>
        <p translate>
          Completed Trips
          <span class="value">{{ assignment.trips.length }}</span>
        </p>
      </div>
      <div>
        <span class="icon-clock"></span>
        <p translate>
          Shift Start Time
          <span class="value">{{ jobEvent['shift1StartTimestamp'] | date: 'h:mm a' }}</span>
        </p>
      </div>
    </div>
    <div class="call-driver">
      <a href="tel:{{ assignment.driver.profile.phoneNumber }}">
        <button translate>
          <span class="icon icon-phone"></span>
          CALL DRIVER
        </button>
      </a>
    </div>
  </div>
</div>
