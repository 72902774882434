"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".name[_ngcontent-%COMP%]{position:relative}.name[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{width:100%;text-overflow:ellipsis;overflow:hidden;display:inline-block;white-space:nowrap}.name[_ngcontent-%COMP%]   .popover[_ngcontent-%COMP%]{visibility:hidden;opacity:0;position:fixed;padding:6px 12px;background:#fff;border:1px solid #dfe2e5;transition:.2s ease-out;transition-property:opacity,padding,visibility;box-shadow:0 2px 4px 0 rgba(0,0,0,.3);font-size:14px;font-weight:400;text-align:center;color:#354052}.name[_ngcontent-%COMP%]   .popover.visible[_ngcontent-%COMP%]{visibility:visible;opacity:1;z-index:99999}.name[_ngcontent-%COMP%]   .popover[_ngcontent-%COMP%]:after, .name[_ngcontent-%COMP%]   .popover[_ngcontent-%COMP%]:before{content:'';display:block;position:absolute;left:15px;width:0;height:0;border:10px outset transparent}.name[_ngcontent-%COMP%]   .popover[_ngcontent-%COMP%]:before{bottom:-20px;border-top:10px solid #dfe2e5}.name[_ngcontent-%COMP%]   .popover[_ngcontent-%COMP%]:after{bottom:-18px;border-top:10px solid #fff}"];
exports.styles = styles;
