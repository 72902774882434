<div mat-dialog-title class="modal-title">
  <h1 *ngIf="reportData">{{ reportData.name }}</h1>
  <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
</div>

<div mat-dialog-content>
  <div class="chart-container" *ngIf="barChartData">
    <canvas
      baseChart
      [chartType]="barChartType"
      [labels]="barChartData.labels"
      [datasets]="barChartData.datasets"
      [options]="barChartOptions"
    ></canvas>
  </div>
</div>