import {
  Component, OnInit, OnDestroy, Output, EventEmitter, TemplateRef, ViewChild
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// angular material
import { MatDialog, MatDialogRef} from '@angular/material';

// lodash
import { remove, find as _find, get, difference } from 'lodash';

// services
import { JobService } from  '../jobs/job.service';
import { AuthenticationService } from '../shared/authentication.service';
import { InvitationService } from  '../invitation/invitation.service';
import { parseErrors } from '../shared/api.service';
import { CustomFieldService } from '../custom-fields/custom-field.service';

// components
import { EditJobDialogComponent } from '../jobs/edit-job-dialog.component';
import { JobFiltersDialogComponent } from './job-filters-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { Selection } from '../shared/fancy-table/table.types';
import { ColumnToggleComponent } from '../shared/column-toggle/column-toggle.component';
import { EditSelectedJobsComponent } from './edit-selected-jobs/edit-selected-jobs.component';

// models
import { Job } from './job';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { CustomFieldKind, CustomField } from '../custom-fields/custom-field';

// constants
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS, MENUOPTIONS } from './jobs.constants';

import moment = require('moment');

@Component({
  selector: 'ruckit-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit, OnDestroy {
  availableColumns = AVAILABLECOLUMNS(this.translateService);
  displayedColumns = DISPLAYEDCOLUMNS;
  appliedFilters: FilterOption[] = [];
  appliedFilterQueryKeys = {
    project: 'project',
    customer: 'customer_organization',
    tags: 'tags',
    startDate: 'jobevents__start__gte',
    endDate: 'jobevents__start__lte',
    orderNumber: 'order_number__icontains',
    jobNumber: 'job_number__icontains'
  };
  search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  errors = [];
  tableConfig = {
    hasHeader: true,
    hasCustomFields: true,
    service: JobService,
    filterQuery: false,
    preferenceKey: 'JobsComponent-JobService',
    query: {
      serializer: 'AllJobs',
      owner_org: 'True'
    },
    collectionTitle: this.translateService.instant('Jobs'),
    noResultsText: this.translateService.instant('a job'),
    newRecordRoute: ['/jobs/new'],
    sortBy: 'name',
    sortDirection: 'asc',
    menuOptions: MENUOPTIONS(this.translateService)
  };
  customFields: CustomField[] = [];
  customFieldsLoaded = false;

  invitationsReq: Subscription;

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle: ColumnToggleComponent;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('jobsTable', { static: false }) jobsTable: FancyTableComponent;

  loading = true;
  crh = false;
  filtersDialog;
  pendingJobsCount = 0;
  canCreateJobs = true;
  hasAllDriversEnabled = false;
  showArchived = true;
  confirmDialog: MatDialogRef<any>;
  isAllJobsSelected = false;
  selectedJobs: Job[] = [];
  excludeJobs: Job[] = [];
  allJobsCount: number;

  constructor(
    private route: ActivatedRoute,
    private jobService: JobService,
    private invitationService: InvitationService,
    private customFieldService: CustomFieldService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.canCreateJobs = this.authenticationService.canCreateJobs();
    this.crh = this.authenticationService.isCrh();
    this.setDefaultFilters();
    this.getCustomFields();
    if (this.crh) { remove(this.displayedColumns, 'schedule'); }
    if (this.tableConfig && this.authenticationService.hasFavoriteTags()) {
      this.tableConfig.query['user_tags'] = 'True';
    }
    if (this.route && this.route.queryParams) {
      this.route.queryParams.forEach((params: Params) => {
        this.loading = true;
        this.search = params['search'] || '';
        // TODO(jlee): Re-enable once the Archived filter is performant.
        // this.showArchived = !!this.authenticationService.getFilterProperty('hideArchivedJobs');
        this.tableConfig.query['archived'] = this.showArchived ? undefined : 'False';
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
      });
    }

    this.getInvitationsCount();
  }

  ngOnDestroy() {
    if (this.invitationsReq && typeof this.invitationsReq.unsubscribe === 'function') {
      this.invitationsReq.unsubscribe();
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  changeSearch(term?: string): void {
    this.search = term || '';
  }

  setDefaultFilters() {
    let startDate = moment();
    startDate = startDate.subtract(2, 'week');
    startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let defaultDateFilter = new FilterOption({
      filterType: 'date',
      default: true,
      key: 'startDate',
      title: 'Start Date',
      displayValues: startDate.format('MM/DD/YYYY') || null,
      values: startDate.toISOString(),
      query: {
        [this.appliedFilterQueryKeys.startDate]: startDate.toISOString()
      }
    });
    this.appliedFilters.push(defaultDateFilter);
  }

  saveJobDayCallback = (e: MouseEvent) => {
    this.jobsTable.getRecords();
  }

  editJob(job: Job): void {
    const dialog = this.dialog.open(EditJobDialogComponent, {
      width: '430px',
      data: { job: job }
    });
    dialog.componentInstance.callback = this.saveJobDayCallback;
  }

  getInvitationsCount(query = {}, append = false): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.invitationsReq && typeof this.invitationsReq.unsubscribe === 'function') {
      this.invitationsReq.unsubscribe();
    }

    if (currentUser) {
      let organizationId = currentUser.organization && currentUser.organization.id;
      this.invitationsReq = this.invitationService.getInvitations(
        'received', organizationId, null, 'new_job'
      ).subscribe(invitations => {
        this.pendingJobsCount = invitations && invitations.length;
      }, err => {
        this.errors = err;
      });
    }
  }

  openFilters() {
    const dialog = this.dialog.open(JobFiltersDialogComponent, {
      width: '430px'
    });

    if (dialog) {
      dialog.componentInstance.callback = res => this.filterChanges(res);
      dialog.componentInstance.model.customer = get(_find(this.appliedFilters, { key: 'customer' }), 'values');
      dialog.componentInstance.model.project = get(_find(this.appliedFilters, { key: 'project' }), 'values');
      dialog.componentInstance.model.tags = get(_find(this.appliedFilters, { key: 'tags' }), 'values');
      dialog.componentInstance.model.orderNumber = get(_find(this.appliedFilters, { key: 'orderNumber' }), 'values');
      dialog.componentInstance.customFields = this.customFields;

      let startDate = get(_find(this.appliedFilters, { key: 'startDate' }), 'values');
      if (startDate) {
        dialog.componentInstance.model.startDate = new Date(startDate);
      }
      let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
      if (endDate) {
        dialog.componentInstance.model.endDate = new Date(endDate);
      }

      this.customFields.forEach(field => {
        dialog.componentInstance.model[field.key] = get(_find(this.appliedFilters, { key: field.key }), 'values');
      });

      dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values;
        return acc;
      }, {}));
      this.filtersDialog = dialog.componentInstance;
    }
  }

  filterChanges(filterRes): void {
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      if (!filterRes[key]) { return; }
      const query = {};
      const matchedCustomField = this.customFields.find(field => (field.key === key));
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (matchedCustomField) {
        values = key + ',' + filterRes[key];
        displayValues = filterRes[key];
        if (values) {
          query[this.appliedFilterQueryKeys[key]] = values;
        }
        key = 'custom_field';
      } else if (filterRes[key] && key === 'tags') {
        values = filterRes[key].map(v => (v.name)).join(',');
        query[this.appliedFilterQueryKeys[key]] = values;
      } else if (filterRes[key] && key === 'customer') {
        query[this.appliedFilterQueryKeys[key]] = filterRes[key].organization && filterRes[key].organization.id;
      } else {
        query[this.appliedFilterQueryKeys[key]] = filterRes[key] && filterRes[key].id || filterRes[key];
      }
      let title = key.charAt(0).toUpperCase() + key.slice(1);
      if (matchedCustomField) {
        title = matchedCustomField.displayName;
      } else {
        if (key === 'orderNumber') {
          title = 'Order #';
        } else if (key === 'jobNumber') {
          title = 'Job #';
        }
      }
      let filter = new FilterOption({
        filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
        key: matchedCustomField ? 'custom_field' : key,
        title: title,
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (filter.values === 'False' || !filter.values) { falseyFilters.push(filter); }
      return filter;
    }).filter(value => value !== undefined);
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

  getFilterQuery(): any {
    let query = {};
    this.appliedFilters.forEach(filter => {
      const queryKey = Object.keys(filter.query)[0];
      if (!query.hasOwnProperty(queryKey)) {
        query[queryKey] = filter.query[queryKey];
      }
    });
    return query;
  }

  menuAction(event: [string, Job]): void {
    switch (event[0]) {
      case 'edit-days':
        this.editJob(event[1]);
        break;
      case 'archive':
        this.archive(event[1]);
        break;
      case 'unarchive':
        this.unarchive(event[1]);
        break;
      case 'remove':
        this.delete(event[1]);
        break;
    }
  }

  archive(job: Job): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translateService.instant('Archive Job?'),
      body: this.translateService.instant('This job will be archived and will not be visible in the jobs list or dropdown.'),
      close: this.translateService.instant('Cancel'),
      accept: this.translateService.instant('Archive')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.jobService.archive(job).subscribe(() => {
          this.jobsTable.getRecords();
        }, err => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  unarchive(job: Job): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translateService.instant('Unarchive Job?'),
      body: this.translateService.instant('This job will be un-archived and will be visible in the jobs list and dropdown.'),
      close: this.translateService.instant('Cancel'),
      accept: this.translateService.instant('Unarchive')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.jobService.unarchive(job).subscribe(() => {
          this.jobsTable.getRecords();
        }, err => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  delete(job: Job): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translateService.instant('Delete Job?'),
      body: this.translateService.instant('This job will be deleted and will not be visible in the jobs list or dropdown.'),
      close: this.translateService.instant('Cancel'),
      accept: this.translateService.instant('Delete')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.jobService.remove(job).subscribe(() => {
          this.jobsTable.getRecords();
        }, err => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  toggleArchived(): void {
    this.authenticationService.setFilterProperty('hideArchivedJobs', !!this.showArchived);
    this.tableConfig.query['archived'] = this.showArchived ? undefined : 'False';
    this.jobsTable.getRecords();
  }

  getCustomFields(): void {
    let customFields = [];
    this.customFieldService.list({ kind: CustomFieldKind.Job, active: 'True' }).subscribe(fields => {
      this.customFields = fields;
      fields.forEach((field: CustomField) => {
        if (!_find(this.availableColumns, { key: field.key })) {
          customFields.push({
            key: field.key, title: field.displayName, sortable: false,
            sortBy: null, customField: true
          });
        }
      });
      this.availableColumns.splice(-1, 0, ...customFields);
      this.customFieldsLoaded = true;
    });
  }

  openEditSelectionDialog() {
    const dialog = this.dialog.open(EditSelectedJobsComponent);
    const query = {
      ...this.tableConfig.query,
      ...this.getFilterQuery(),
      search: this.search
    };
    dialog.componentInstance.query = query;
    dialog.componentInstance.selectedJobs = this.selectedJobs;
    dialog.componentInstance.excludeJobs = this.excludeJobs;
    dialog.componentInstance.allJobsCount = this.allJobsCount;
    dialog.componentInstance.isAllJobsSelected = this.isAllJobsSelected;
    dialog.componentInstance.editSuccess.subscribe(() => {
      this.selectedJobs = [];
      this.jobsTable.resetSelections();
      this.jobsTable.getRecords();
      this.dialog.closeAll();
    });
  }

  onSelectionChange(event: Selection) {
    this.isAllJobsSelected = event.allSelected;
    const exclusionIds = event.exclusion.selected.map(e => e.id);
    const data: any[] = this.jobsTable.dataSource.data;
    this.selectedJobs = this.isAllJobsSelected ? data.filter(j => !exclusionIds.includes(j.id)) : event.selection.selected;
    this.allJobsCount = this.isAllJobsSelected ? this.jobsTable.count - event.exclusion.selected.length : event.selection.selected.length;
    this.excludeJobs = event.exclusion.selected;
  }
}
