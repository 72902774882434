import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ResourceService } from '../shared/resource.service';
import { Assignment } from './assignment';
import { AssignmentSerializer } from './assignment.serializer';
import { requestHeaders, handleError, getFirstFieldError } from '../shared/index';
import { AppUtilities } from '../shared/app-utilities'
import { ConfirmChangeJobLoadsService } from '../dispatch/dispatch-by-job/confirm-change-job-loads.service';

@Injectable()
export class AssignmentService extends ResourceService<Assignment> {

  constructor(http: HttpClient, private confirmChangeJobLoadsService: ConfirmChangeJobLoadsService) {
    super(http, 'assignments/all/', new AssignmentSerializer());
  }

  save(model, query?: any, raw = false): Observable<Assignment> {
    return super.save(model, query, raw).pipe(
      catchError(err => {
        const callback = (byPassQuery) => {
          const queries = query ? {...query, ...byPassQuery} : byPassQuery
          return this.save(model, queries, raw)
        }

        return this._validateAssignmentErrors(err, callback)
      })
    )
  }

  saveBulkAssignments(body: {}, params?: HttpParams): Observable<{errors: any, assignments: Assignment[]}> {
    if (body) {
      const assigmentBulkUrl = this.baseUrl + 'assignments/';
      return this.http.post(assigmentBulkUrl, body, {
        headers: requestHeaders(),
        params,
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => {
          const callback = (byPassQuery) => {
            const queryParams = AppUtilities.getParamsFromQuery(byPassQuery)
            return this.saveBulkAssignments(body, queryParams)
          }

          return this._validateAssignmentErrors(err, callback)
        }),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  bulkCreate(assignments: Assignment[], query?: any): Observable<{errors: any, assignments: Assignment[]}> {
    if (assignments && assignments.length) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const _assignments = assignments.map(assignment => {
        return new AssignmentSerializer().toJson(assignment);
      });
      const assigmentBulkUrl = this.baseUrl + 'assignments/';
      return this.http.post(assigmentBulkUrl, _assignments, {
        headers: requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => {
          const callback = (byPassQuery) => {
            const queries = query ? {...query, ...byPassQuery} : byPassQuery
            return this.bulkCreate(assignments, queries)
          }

          return this._validateAssignmentErrors(err, callback)
        }),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  bulkUpdate(assignments: Assignment[], query?: any): Observable<{errors: any, assignments: Assignment[]}> {
    if (assignments && assignments.length) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const _assignments = assignments.map(assignment => {
        return new AssignmentSerializer().toJson(assignment);
      });
      const assigmentBulkUrl = this.baseUrl + 'assignments/bulk-update/';
      return this.http.put(assigmentBulkUrl, _assignments, {
        headers: requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => {
          const callback = (byPassQuery) => {
            const queries = query ? {...query, ...byPassQuery} : byPassQuery
            return this.bulkUpdate(assignments, queries)
          }

          return this._validateAssignmentErrors(err, callback)
        }),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  bulkRemove(recordIds: string[], query?: any): Observable<any> {
    if (recordIds) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const assigmentBulkUrl = this.baseUrl + 'assignments/bulk-delete/';
      return this.http.request('delete', assigmentBulkUrl, {
        body: recordIds,
        headers: requestHeaders(),
        params: params
      }).pipe(
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  copyAssignment(body: {}): Observable<any> {
    if (body) {
      const assigmentCopyUrl = this.baseUrl + 'assignments/copy/';
      return this.http.post(assigmentCopyUrl, body, {
        headers: requestHeaders()
      }).pipe(
        map(res => { return res; }),
        catchError(handleError)
      );
    }
  }

  private _validateAssignmentErrors(err, callback: (extraQuery: any) => Observable<any>) {
    const error = getFirstFieldError(err)
    if (error && error.includes('Loads assigned exceed loads requested')) {
      return this.confirmChangeJobLoadsService.showConfirmationProceedModal().pipe(
        switchMap((result) => {
          if (result && result['byPass']){
            const byPassQuery = { bypass_limit_validation: 'True' }
            return callback(byPassQuery)
          }
        }),
        catchError(() => of(true))
      )
    } else {
      return observableThrowError(err)
    }
  }

  private convertBulkResponse(res: any): {errors: any, assignments: Assignment[]} {
    // const errorsParsed = parseErrors(res)
    // let errors = errorsParsed || [];
    let errors = [];
    let assignments: Assignment[] = [];
    if (res && res.errors) { errors = res.errors; }
    if (res && res.success) {
      assignments = res.success.map(record => new AssignmentSerializer().fromJson(record));
    }

    return { errors: errors, assignments: assignments };
  }
}
