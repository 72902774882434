import { sumBy, uniq, uniqBy, sum, round } from 'lodash';
import moment = require('moment-timezone');

import { Driver } from './driver';
import { TruckSerializer } from '../trucks/truck.serializer';
import { Profile } from './profile';
import { Conflict } from './conflict';
import { Carrier } from '../carriers/carrier';
import { Trip } from '../trips/trip';
import { DeviceStatusEventSerializer } from '../shared/device-status-event.serializer';
import { AssignmentSerializer } from '../assignments/assignment.serializer';
import { JobEventShareSerializer } from '../job-event-shares/job-event-share.serializer';
import { PunchCard } from '../punch-cards/punch-card';
import { Organization } from '../organizations/organization';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class DriverSerializer {
  /**
  * @param  {any} json
  * @returns {Driver} Driver
  */
  fromJson(json: any): Driver {
    json = camelcaseKeysDeep(json);
    const driver = new Driver();
    if (!json) { return driver; }

    driver.id = json.id;
    driver.busy = json.busy;
    driver.customFieldData = json.customFieldData;
    driver.image = json.image;
    driver.imageKey = json.imageKey;
    driver.lastStatus = json.lastStatus;
    driver.username = json.username;
    if (json.user) {
      driver.user = json.user;
    } else {
      driver.user = { username: driver.username, password: '' };
    }
    if (json.truck && typeof (json.truck) === 'object') {
      driver.truck = new TruckSerializer().fromJson(json.truck);
    } else if (json.truck) {
      driver.truck = new TruckSerializer().fromJson({ id: json.truck });
    }
    if (json.trip && typeof (json.trip) === 'object') {
      driver.trip = new Trip(json.truck);
    } else if (json.trip) {
      driver.trip = new Trip({ id: json.trip });
    }
    if (json.trucks) {
      driver.trucks = json.trucks.map(t => new TruckSerializer().fromJson(t));
    }
    if (json.profile && typeof (json.profile) === 'object') {
      driver.profile = new Profile(json.profile);
    } else {
      driver.profile = json.profile;
    }
    if (driver.profile && driver.profile.firstName && driver.profile.lastName) {
      driver.initials = driver.profile.firstName[0] + driver.profile.lastName[0];
    }
    driver.city = json.city;
    driver.state = json.state;
    driver.country = json.country || 'US';
    driver.street = json.street;
    driver.zipcode = json.zipcode;
    driver.cdl = json.cdl;
    if (json.carrier && typeof (json.carrier) === 'object') {
      driver.carrier = new Carrier(json.carrier);
    }
    if (json.carrierOrganizationId) {
      driver.carrierOrganizationId = json.carrierOrganizationId;
    } else if (json.carrier) {
      driver.carrierOrganizationId = json.carrier.id;
    } else {
      driver.carrierOrganizationId = '';
    }
    if (json.carrierId) {
      driver.carrierId = json.carrierId;
    } else if (json.carrier) {
      driver.carrierId = json.carrier.id;
    } else {
      driver.carrierId = '';
    }
    if (json.carrierOrganizationName) {
      driver.carrierOrganizationName = json.carrierOrganizationName;
    } else if (json.carrier) {
      driver.carrierOrganizationName = json.carrier.name;
    } else {
      driver.carrierOrganizationName = '';
    }
    if (json.carrierOrganization && typeof (json.carrierOrganization) === 'object') {
      driver.carrierOrganization = new Organization(json.carrierOrganization);
    } else if (driver.carrierOrganizationId && driver.carrierOrganizationName) {
      driver.carrierOrganization = new Organization({
        id: driver.carrierOrganizationId,
        name: driver.carrierOrganizationName
      });
    }
    driver.carrierName = json.carrierName;
    driver.customName = json.customName;
    if (driver.profile) {
      driver.name = driver.profile.firstName + ' ' + driver.profile.lastName;
    } else {
      driver.name = driver.customName;
    }
    driver.truckName = driver.truck && driver.truck.name;
    driver.phoneNumber = json.phoneNumber;
    driver.activeShift = json.activeShift;
    driver.activeShifts = json.activeShifts;
    if (json.conflicts) {
      driver.conflicts = json.conflicts.map(c => new Conflict(c));
      driver.conflictIds = driver.conflicts.map(c => c.jobevent);
    }
    driver.requireAssignmentConfirmation = json.requireAssignmentConfirmation;
    driver.tags = json.tags;
    driver.uniqueBillingId = json.uniqueBillingId;
    driver.status = json.status;
    driver.dutyStatus = json.dutyStatus;
    driver.displayDutyStatus = driver.dutyStatus &&
      driver.dutyStatus[0].toUpperCase() + driver.dutyStatus.replace('-', ' ').substr(1).toLowerCase();
    driver.displayShiftStatus = json.displayShiftStatus;
    driver.lastShiftId = json.lastShiftId;
    driver.deviceErrors = json.deviceErrors || [];
    if (json.lastDeviceStatusEvent && typeof (json.lastDeviceStatusEvent) === 'object' && json.lastDeviceStatusEvent.id) {
      driver.lastDeviceStatusEvent = new DeviceStatusEventSerializer().fromJson(json.lastDeviceStatusEvent);
      if (driver.lastDeviceStatusEvent.lowPowerMode === true) {
        driver.deviceErrors.push('Low Power Mode');
      }
      if (
        driver.lastDeviceStatusEvent.locationAuthorization !== 'Always'
      ) {
        driver.deviceErrors.push('Location Authorization');
      }
      if (driver.lastDeviceStatusEvent.backgroundAppRefresh !== true) {
        driver.deviceErrors.push('Background App Refresh');
      }
    } else {
      driver.lastDeviceStatusEvent = json.lastDeviceStatusEvent;
    }
    driver.truckDriverDisplayName = (
      driver.dutyStatus === 'on-duty' ?
      driver.truckName ? driver.truckName : 'No Truck Assigned' :
      'Off Duty'
    ) + ' - ' + driver.name;
    driver.lastModified = json.lastModified;
    driver.lastModifiedBy = json.lastModifiedBy;

    driver.trips = json.trips && json.trips.map(trip => {
      if (trip && typeof (trip) === 'object') {
        return new Trip(trip);
      } else {
        return new Trip({ id: trip });
      }
    }) || [];
    if (json.latestTruck && typeof (json.latestTruck) === 'object') {
      driver.latestTruck = new TruckSerializer().fromJson(json.latestTruck);
    } else {
      driver.latestTruck = json.latestTruck;
    }
    driver.loadCount = json.loadCount;

    driver.invoiceTotal = round(sum(driver.trips.map(trip => {
      const invoiceRate: number = parseFloat(trip.jobEventShare.invoiceRate);
      const invoiceUnit = trip.jobEventShare.invoiceWeightUnit;

      if (invoiceUnit === 'load') {
        return invoiceRate;
      } else if (invoiceUnit === 'ton') {
        return (invoiceRate * parseFloat(trip.weight));
      } else if (invoiceUnit === 'hour') {
        return invoiceRate * (trip.completedTripDuration ? (trip.completedTripDuration / 3600000) : 0);
      } else {
        return (invoiceRate * parseFloat(trip.weight));
      }
    })), 2);

    driver.expenseTotal = round(sum(driver.trips.map(trip => {
      const haulRate: number = parseFloat(trip.jobEventShare.haulRate);
      const haulUnit = trip.jobEventShare.haulWeightUnit;

      if (haulUnit === 'load') {
        return haulRate;
      } else if (haulUnit === 'ton') {
        return (haulRate * parseFloat(trip.weight));
      } else if (haulUnit === 'hour') {
        return haulRate * (trip.completedTripDuration ? (trip.completedTripDuration / 3600000) : 0);
      } else {
        return (haulRate * parseFloat(trip.weight));
      }
    })), 2);

    driver.timeTotal = moment.duration(sum(driver.trips.filter(trip => {
      return !trip.void;
    }).map(trip => {
      return (trip.completedTripDurationRounded ? trip.completedTripDurationRounded : 0);
    })), 'milliseconds').format('D[ days], H[ hrs], m[ mins]');

    driver.assignments = json.assignments && json.assignments.map(assignment => {
      if (assignment && typeof (assignment) === 'object') {
        return new AssignmentSerializer().fromJson(assignment);
      } else {
        return new AssignmentSerializer().fromJson({ id: assignment });
      }
    }) || [];
    driver.assignmentsCount = driver.assignments && driver.assignments.length;
    driver.punchCards = json.punchcards && json.punchcards.map(punchcard => {
      if (punchcard && typeof (punchcard) === 'object') {
        return new PunchCard(punchcard);
      } else {
        return new PunchCard({ id: punchcard });
      }
    }) || [];

    if (driver.trips) {
      driver.trips.forEach(trip => driver.jobEventShares.push(trip.jobEventShare));
    }
    if (json.jobEventShares) {
      json.jobEventShares.forEach(jobEventShare => {
        if (jobEventShare.organizationId === driver.carrierOrganizationId) {
          driver.jobEventShares.push(jobEventShare);
        }
      });
    }
    if (driver.jobEventShares) {
      driver.jobEventShares = driver.jobEventShares.concat(
        driver.jobEventShares.map(jobEventShare => {
          if (jobEventShare && typeof (jobEventShare) === 'object') {
            return new JobEventShareSerializer().fromJson(jobEventShare);
          } else {
            return new JobEventShareSerializer().fromJson({ id: jobEventShare });
          }
        })
      );

      driver.jobEventShares = uniqBy(driver.jobEventShares, (e) => {
        return e.id;
      });
    }

    driver.allDecisionsApproved = json.allDecisionsApproved;
    driver.allTripsApproved = json.allTripsApproved || [];
    if (json.completedTripsCount) {
      driver.completedTripsCount = json.completedTripsCount;
    } else {
      driver.completedTripsCount = sumBy(driver.trips, (trip) => trip.completed && !trip.void ? 1 : 0);
    }
    driver.jobNames = uniq([
      ...driver.trips.map(trip => trip.jobName),
      ...driver.assignments.map(assignment => assignment.jobDisplayName),
      ...driver.punchCards.map(punchCard => {
        if (punchCard.jobEvent && punchCard.jobEvent.jobDisplayName) {
          return punchCard.jobEvent.jobDisplayName;
        } else if (punchCard.job && punchCard.job.name) {
          return punchCard.job.name;
        }
      })
    ]).filter(Boolean);
    driver.truckContainerId = json.truckContainerId;
    driver.geoTrips = json.geoTrips || [];
    driver.geoTrips.map(geoTrip => {
      if (geoTrip.startTime && geoTrip.endTime) {
        const tripTimesDifference = moment(geoTrip.endTime).diff(moment(geoTrip.startTime), 'minutes');
        geoTrip.tripDuration = Math.floor(tripTimesDifference / 60) + 'h ' + (tripTimesDifference % 60) + 'm';
      }
      geoTrip.geoStatus = this.getGeoStatus(geoTrip.tripStatus);
    });

    return driver;
  }

  /**
  * @param  {Driver} driver
  * @returns any
  */
  toJson(driver: Driver): any {
    let json = {
      id: driver.id,
      profile: driver.profile,
      name: driver.name,
      user: driver.user,
      image: driver.image,
      imageKey: driver.imageKey,
      lastStatus: driver.lastStatus,
      truck: driver.truck && driver.truck.id,
      trucks: driver.trucks,
      city: driver.city,
      state: driver.state,
      country: driver.country,
      street: driver.street,
      zipcode: driver.zipcode,
      activeShift: driver.activeShift,
      activeShifts: driver.activeShifts,
      cdl: driver.cdl,
      trip: driver.trip,
      punchCard: driver.punchCard,
      carrier: driver.carrier,
      conflicts: driver.conflicts,
      busy: driver.busy,
      username: driver.username,
      requireAssignmentConfirmation: driver.requireAssignmentConfirmation,
      initials: driver.initials,
      carrierName: driver.carrierName,
      carrierOrganizationName: driver.carrierOrganizationName,
      customName: driver.customName,
      phoneNumber: driver.phoneNumber,
      tags: driver.tags,
      uniqueBillingId: driver.uniqueBillingId === '' ? null : driver.uniqueBillingId,
      customFieldData: driver.customFieldData,
      status: driver.status,
      dutyStatus: driver.dutyStatus,
      truckContainerId: driver.truckContainerId
    };
    return decamelizeKeysDeep(json);
  }

  getGeoStatus(status: string): string {
    const geoStatuses = {
      default: 'Waiting',
      enroute_loading: 'Enroute Loading',
      loading: 'Loading',
      enroute_unloading: 'Enroute Unloading',
      unloading: 'Unloading',
      paused: 'Paused',
      loading_complete: 'Loading Complete',
      unloading_complete: 'Unloading Complete',
      waiting_to_load: 'Waiting to Load',
      active_loading: 'Active Loading',
      waiting_to_unload: 'Waiting to Unload',
      active_unloading: 'Active Unloading'
    };
    return geoStatuses[status] || geoStatuses.default;
  }
}
