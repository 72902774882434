"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var job_event_stat_service_1 = require("../../job-event-stats/job-event-stat.service");
var shared_1 = require("../../shared");
var DailyProductionComponent = /** @class */ (function () {
    function DailyProductionComponent(statService, authenticationService, dialog) {
        this.statService = statService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.totalHoursField = 'totalHours';
        this.stats = [];
        this.loading = true;
        this.errors = [];
        this.sortBy = 'job__name';
        this.sortAsc = true;
    }
    DailyProductionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        if (window.innerWidth > 900) {
            this.getStats({}, true);
            this.statsTimerSub = rxjs_1.timer(1, 120000);
            this.statsTimerSub.subscribe(function (t) {
                _this.getStats({}, false);
            });
        }
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures && this.enabledFeatures.includes('calculator')) {
            var totalHoursPreference = this.authenticationService.getFeature('calculator');
            this.totalHoursField = totalHoursPreference === 'trips' ?
                'totalTripHours' : totalHoursPreference === 'completed_trips' ?
                'totalCompletedTripHours' : 'totalHours';
        }
    };
    DailyProductionComponent.prototype.ngOnDestroy = function () {
        if (this.statsTimerSub) {
            try {
                this.statsTimerSub.unsubscribe();
            }
            catch (e) { }
        }
        if (this.statsReq && typeof this.statsReq.unsubscribe === 'function') {
            this.statsReq.unsubscribe();
        }
    };
    DailyProductionComponent.prototype.onResize = function (event) {
        if (this.stats.length === 0) {
            this.ngOnInit();
        }
    };
    DailyProductionComponent.prototype.getStats = function (query, loading) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (loading === void 0) { loading = true; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.loading = loading;
        var startDate = new Date();
        var endDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.statsReq && typeof this.statsReq.unsubscribe === 'function') {
            this.statsReq.unsubscribe();
        }
        this.statsReq = this.statService.getAllStats(__assign({ ordering: order }, query, { shift1_start__gte: startDate.toISOString(), shift1_start__lte: endDate.toISOString() })).subscribe(function (stats) {
            _this.stats = stats;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DailyProductionComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getStats({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    return DailyProductionComponent;
}());
exports.DailyProductionComponent = DailyProductionComponent;
