import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { TicketsRoutingModule } from './tickets-routing.module';
import { ScalesComponent } from '../scales/scales.component';
import { TicketsComponent } from './tickets.component';
import { CreateTicketComponent } from './create-ticket.component';
import { TicketFormComponent } from './ticket-form.component';
import { IncompleteTicketsComponent } from './incomplete-tickets.component';
import { ScaleService } from '../scales/scale.service';
import { TruckDropdownComponent } from '../shared';
import { ScaleDropdownComponent } from '../scaleit/shared';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TicketsRoutingModule
  ],
  declarations: [
    ScalesComponent,
    TicketsComponent,
    CreateTicketComponent,
    TicketFormComponent,
    IncompleteTicketsComponent,
    TruckDropdownComponent,
    ScaleDropdownComponent
  ],
  providers: [ScaleService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class TicketsModule { }
