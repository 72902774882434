import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef
} from '@angular/core';

// angular material
import { MatDialog, MatDialogRef } from '@angular/material';

// services
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../shared';
import { SurchargeTemplateService } from './surcharge-template.service';

// components
import { SurchargeTemplateDialogComponent } from './surcharge-template-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'surcharge-templates',
  templateUrl: './surcharge-templates.component.html',
  styleUrls: ['./surcharge-templates.component.scss']
})
export class SurchargeTemplatesComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'kindTitle', title: this.translationService.instant('Title'), sortable: true, sortBy: 'kind' },
    { key: 'title', title: this.translationService.instant('Title'), sortable: true },
    { key: 'description', title: this.translationService.instant('Description'), sortable: true },
    { key: 'amount', title: this.translationService.instant('Amount') },
    { key: 'action', title: this.translationService.instant('Actions') }
  ];
  @Input() displayedColumns = [
    'select', 'title', 'description', 'amount', 'action'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  confirmDialog: MatDialogRef<any>;
  errors = [];
  hasPOS = false;
  tableConfig = {
    hasHeader: true,
    service: SurchargeTemplateService,
    query: {},
    collectionTitle: this.translationService.instant('Surcharge Templates'),
    noResultsText: this.translationService.instant('a surcharge template'),
    sortBy: 'created_at',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
    ],
    newRecordModal: () => this.openNewSurchargeTemplate()
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('surchargeTemplatesTable', { static: false }) surchargeTemplatesTable;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;
  newSurchargeTemplateCallback = (e) => {
    this.refreshTable();
  }

  constructor(
    private surchargeTemplateService: SurchargeTemplateService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    let organization = this.authenticationService.getOrganization();
    this.hasPOS = organization && organization.posEnabled ? true : false;

    if (this.hasPOS) {
      this.displayedColumns = [
        'select', 'kindTitle', 'title', 'description', 'amount', 'action'
      ];
    }
  }

  openNewSurchargeTemplate() {
    const dialog = this.dialog.open(SurchargeTemplateDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = this.newSurchargeTemplateCallback;
  }

  editSurchargeTemplate(surchargeTemplate) {
    const dialog = this.dialog.open(SurchargeTemplateDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.model = surchargeTemplate;
    dialog.componentInstance.callback = this.newSurchargeTemplateCallback;
  }

  removeSurchargeTemplate(surchargeTemplate) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Surcharge Template?'),
      body: this.translationService.instant('This surcharge template will be deleted and cannot be recovered.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.surchargeTemplateService.remove(surchargeTemplate).subscribe((res) => {
          this.refreshTable();
        }, (err) => {
          // this.errors = parseErrors(err);
        });
      }
      this.confirmDialog = null;
    });
  }

  refreshTable() {
    if (this.surchargeTemplatesTable) {
      this.surchargeTemplatesTable.getRecords();
    }
  }

  menuAction(action: string, surchargeTemplate) {
    switch (action) {
      case 'edit':
        this.editSurchargeTemplate(surchargeTemplate);
        break;
      case 'remove':
        this.removeSurchargeTemplate(surchargeTemplate);
        break;
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
