"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAYEDCOLUMNS = [
    'select',
    'action-dots',
    'job-date',
    'job',
    'start-time',
    'ticket-number',
    'ticket-image',
    'driver',
    'truck',
    'customer',
    'invoice-rate',
    'invoice-total',
    'haul-rate',
    'haul-total',
    'invoice',
    'expense',
    'paystub',
    'billing-id',
];
exports.AVAILABLECOLUMNS = function (translationService) { return [
    {
        key: 'select',
    },
    {
        key: 'action-dots',
        title: translationService.instant('Actions'),
    },
    {
        key: 'job-date',
        title: translationService.instant('Job Date'),
    },
    {
        key: 'job',
        title: translationService.instant('Job'),
    },
    {
        key: 'start-time',
        title: translationService.instant('Time'),
        sortable: true,
        sortBy: 'jobevent__shift1_start',
    },
    {
        key: 'ticket-number',
        title: translationService.instant('Ticket #'),
    },
    {
        key: 'ticket-image',
        title: translationService.instant('Ticket Image'),
    },
    {
        key: 'driver',
        title: translationService.instant('Driver'),
    },
    {
        key: 'truck',
        title: translationService.instant('Truck'),
        sortable: true,
        sortBy: 'name',
    },
    {
        key: 'customer',
        title: translationService.instant('Customer'),
        sortable: true,
        sortBy: 'name',
    },
    {
        key: 'invoice-rate',
        title: translationService.instant('Invoice Rate'),
        sortable: true,
        sortBy: 'rate',
    },
    {
        key: 'invoice-total',
        title: translationService.instant('Invoice Total'),
    },
    {
        key: 'haul-rate',
        title: translationService.instant('Haul Rate'),
    },
    {
        key: 'haul-total',
        title: translationService.instant('Haul Total'),
    },
    {
        key: 'invoice',
        title: translationService.instant('Invoice #'),
    },
    {
        key: 'expense',
        title: translationService.instant('Expense'),
    },
    {
        key: 'paystub',
        title: translationService.instant('Paystub'),
    },
    {
        key: 'billing-id',
        title: translationService.instant('Billing ID'),
    },
]; };
