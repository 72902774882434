import { Component, OnInit } from '@angular/core';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { AuthenticationService } from '../../shared';

@Component({
  selector: 'jobs-header',
  templateUrl: './jobs-header.component.html',
  styleUrls: ['./jobs-header.component.scss'],
})
export class JobsHeaderComponent implements OnInit {
  hasAllDriversEnabled = false;
  carrierMode = false;
  pendingJobsCount = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private collaboratorService: CollaboratorService
  ) {}

  ngOnInit() {
    this.hasAllDriversEnabled =
      this.authenticationService.hasAllDriversEnabled();
    const enabledFeatures = this.authenticationService.enabledFeatures(); // -> ['ruckitBilling', 'carrierMode', '.....']
    this.carrierMode = enabledFeatures.includes('ruckitBilling');

    this.getPendingCount();
  }

  getPendingCount(): void {
    this.collaboratorService.getPending({ page_size: 1 }).subscribe(
      () => {
        this.pendingJobsCount = this.collaboratorService.count;
      },
      () => {
        // error
        this.pendingJobsCount = 0;
      }
    );
  }
}
