import { Component, OnInit, SimpleChanges, Input, ViewChild, OnChanges } from '@angular/core';
import { CustomMarker, NguiMapComponent } from '@ngui/map';
import { Subscription } from 'rxjs';
import moment = require('moment');

import { mapOptions } from '../../shared/static-data';
import { Driver } from '../../drivers/driver';
import { JobEvent } from '../../job-events/job-event';
import { Location } from '../../locations/location';
import { DateTimeRange, AuthenticationService } from '../../shared';
import { LocationUpdate } from '../../jobs/locationUpdate';
import { Organization } from '../../organizations/organization';
import { LocationService } from '../../locations/location.service';

declare let google: any;

export type MapDirections = {
  origin: string,
  destination: string,
  travelMode: string
};

export type CondensedLocationUpdate = {
  location: { coordinates: string[] },
  orientation: number,
  date: moment.Moment
};

@Component({
  selector: 'ruckit-replay-map',
  templateUrl: './ruckit-replay-map.component.html',
  styleUrls: ['./ruckit-replay-map.component.scss']
})
export class RuckitReplayMapComponent implements OnInit, OnChanges {
  errors: string[] = [];
  @Input() loadingLocationUpdates: boolean;
  @Input() jobEvents: JobEvent[] = [];
  @Input() locations: Location[] = [];
  @Input() driver: Driver;
  @Input() locationUpdates: LocationUpdate[];
  @Input() selectedTime = '';
  @Input() timeRange: DateTimeRange = <DateTimeRange>{};
  timelineStart: moment.Moment;
  timelineStartUnix: number;
  selectedDate: string;
  activeIndex = 0;
  organization: Organization;

  loaded = true;
  loading = false;
  map: google.maps.Map;
  mapOptions = mapOptions({
    zoom: 10,
    disableDefaultUI: true,
    fullscreenControl: true,
    streetViewControl: false,
    mapTypeControl: true
  });
  organizationLocationReq: Subscription;
  condensedLocationUpdates: CondensedLocationUpdate[] = [];
  customUpdateMarkers: any[] = [];
  jobEventRoutes: MapDirections[] = [];
  polylineOptions = {
    strokeColor: '#002649',
    strokeWeight: '4',
    strokeOpacity: 0.6
  };
  geofenceInfoWindowModel;
  geofences: any[];
  allLocationGeofences: any[];
  customLocationMarkers = [];
  @ViewChild(NguiMapComponent, { static: false }) nguiMapComponent: NguiMapComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    this.organization = this.authenticationService.getOrganization();
  }

  jobEventsEqual(prevJobEvents: JobEvent[], currJobEvents: JobEvent[]): boolean {
    if (prevJobEvents.length !== currJobEvents.length) { return false; }
    for (let i = 0; i < currJobEvents.length; i++) {
      if (prevJobEvents[i].id !== currJobEvents[i].id) { return false; }
    }
    return true;
  }

  locationsEqual(prevLocations: Location[], currLocations: Location[]): boolean {
    if (prevLocations.length !== currLocations.length) { return false; }
    for (let i = 0; i < currLocations.length; i++) {
      if (prevLocations[i].id !== currLocations[i].id) { return false; }
    }
    return true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobEvents && changes.jobEvents.currentValue.length &&
        !this.jobEventsEqual(changes.jobEvents.previousValue, changes.jobEvents.currentValue)) {
      this.jobEventRoutes = this.createJobEventRoutes(changes.jobEvents.currentValue);
      this.geofences = this.setupGeofences(changes.jobEvents.currentValue);
      if (this.jobEventRoutes && this.jobEventRoutes.length) { this.centerMap(); }
    } else if (changes.jobEvents && !changes.jobEvents.currentValue.length) {
      this.jobEventRoutes = [];
    }
    if (changes.locations && changes.locations.currentValue.length &&
      !this.locationsEqual(changes.locations.previousValue, changes.locations.currentValue)) {
        this.customLocationMarkers = [];
        this.allLocationGeofences = this.setupAllLocationGeofences(changes.locations.currentValue);
    } else if (changes.locations && !changes.locations.currentValue.length) {
      this.allLocationGeofences = [];
    }
    if (changes.selectedTime && this.condensedLocationUpdates.length) {
      this.activeIndex = this.activeUpdateIndex(this.selectedTime);
    } else if (changes.locationUpdates && this.timeRange && this.driver) {
      this.getMapData(this.driver);
    }
  }

  initMap(e: any): void {
    if (e) { this.map = e; }
    this.centerMap();
  }

  onMapLoaded(e = null): void {
    if (e) { this.map = e.target.map; }
  }

  centerMap(): boolean {
    let _centerOnOrganization = true;
    if (this.map) {
      let bounds = new google.maps.LatLngBounds();
      let latLngList = [];
      let geofences = [];
      this.condensedLocationUpdates.forEach(update => {
        if (update.location) {
          _centerOnOrganization = false;
          bounds.extend(new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]));
        }
      });
      this.jobEvents.forEach(jobEvent => {
        if (jobEvent.job) {
          if (jobEvent.startCoordinates && jobEvent.endCoordinates) {
            latLngList = [
              jobEvent.job.startLocationMappable ?
                new google.maps.LatLng(jobEvent.startCoordinates.latitude, jobEvent.startCoordinates.longitude) : null,
              jobEvent.job.endLocationMappable ?
                new google.maps.LatLng(jobEvent.endCoordinates.latitude, jobEvent.endCoordinates.longitude) : null,
            ];
          }
          for (let i = 0, LtLgLen = latLngList.length; i < LtLgLen; i++) {
            _centerOnOrganization = false;
            let list = latLngList[i];
            bounds.extend(list);
          }
          if (jobEvent.job.startLocation && jobEvent.job.startLocation.geofence && jobEvent.job.startLocation.paths) {
            geofences.push({ paths: jobEvent.job.startLocation.paths });
            jobEvent.job.startLocation.paths.forEach((path) => {
              if (path.lat && path.lng) {
                _centerOnOrganization = false;
                bounds.extend(new google.maps.LatLng(path.lat, path.lng));
              }
            });
          }
          if (jobEvent.job.endLocation && jobEvent.job.endLocation.geofence && jobEvent.job.endLocation.paths) {
            geofences.push({ paths: jobEvent.job.endLocation.paths });
            jobEvent.job.endLocation.paths.forEach((path) => {
              if (path.lat && path.lng) {
                _centerOnOrganization = false;
                bounds.extend(new google.maps.LatLng(path.lat, path.lng));
              }
            });
          }
        }
      });
      if (this.map.getZoom() > 17) { this.map.setZoom(17); }
      if (_centerOnOrganization) { this.centerOnOrganization(); }
      this.map.fitBounds(bounds);
      return true;
    } else { return false; }
  }

  centerOnOrganization(): void {
    if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
      this.organizationLocationReq.unsubscribe();
    }
    if (!this.organization || this.organization === undefined) { return; }
    this.locationService.getLocationByAddress(`${this.organization.street}, ${this.organization.location}`).subscribe(coords => {
      if (coords && coords[0] && coords[0].geometry && coords[0].geometry.location) {
        let fallbackCenter = new google.maps.LatLng(
          coords[0].geometry.location.lat, coords[0].geometry.location.lng
        );
        this.mapOptions = mapOptions({
          zoom: 10,
          center: fallbackCenter,
          disableDefaultUI: true,
          scrollwheel: false,
          geoFallbackCenter: fallbackCenter
        }, {}, {
          mapStyle: 'google-map-style'
        });
      }
    }, (err) => this.errors = ['Cannot get your organizations location']);
  }

  getMapData(driver: Driver) {
    this.loading = true;
    this.condensedLocationUpdates = [];
    this.selectedDate = moment(this.timeRange.startDatetime).format('YYYYMMDD');
    this.timelineStart = moment(this.timeRange.startDatetime).subtract(2, 'hours').startOf('hour');
    this.timelineStartUnix = Number(this.timelineStart.format('x'));
    if (this.locationUpdates.length && (!this.condensedLocationUpdates.length || driver.id !== this.driver.id)) {
      this.customUpdateMarkers = [];
      this.condensedLocationUpdates = this.setupLocationUpdates(this.locationUpdates);
      this.centerMap();
    }
    this.loading = false;
  }

  setupGeofences(jobEvents: JobEvent[]): any[] {
    let geofences = [];
    jobEvents.forEach(jobEvent => {
      if (jobEvent.job) {
        if (jobEvent.job.startLocation && jobEvent.job.startLocation.paths) {
          geofences.push({
            id: jobEvent.job.startLocation.id,
            name: jobEvent.job.startLocation.name,
            paths: jobEvent.job.startLocation.paths
          });
        }
        if (jobEvent.job.endLocation && jobEvent.job.endLocation.paths) {
          geofences.push({
            id: jobEvent.job.endLocation.id,
            name: jobEvent.job.endLocation.name,
            paths: jobEvent.job.endLocation.paths
          });
        }
      }
    });
    return geofences;
  }

  setupAllLocationGeofences(locations: Location[]): any[] {
    let geofences = [];
    locations.forEach(location => {
      if (location.geofence && location.location) {
        if (geofences.findIndex(geofence => (geofence.id === location.id)) === -1) {
          geofences.push({
            id: location.id,
            name: location.name,
            paths: location.paths,
            coordinates: location.location && location.location.coordinates
          });
        }
      }
    });
    return geofences;
  }

  openGeofenceInfoWindow(index: number, location): void {
    this.geofenceInfoWindowModel = {
      name: location.name
    };
    this.nguiMapComponent.openInfoWindow('geofence-info-window', this.customLocationMarkers[index]);
  }

  onCustomGeofenceMarkerInit(customMarker: CustomMarker): void {
    this.customLocationMarkers.push(customMarker);
  }

  setupLocationUpdates(locationUpdates: LocationUpdate[]): CondensedLocationUpdate[] {
    let newLocationUpdates: CondensedLocationUpdate[] = [];
    let currUpdateIndex = 0;
    const endTime = Number(moment(this.timeRange.endDatetime).add(2, 'hours').startOf('hour').format('x'));
    if (locationUpdates.length) {
      for (let minute = this.timelineStartUnix; minute < endTime; minute += 60000) {
        while (locationUpdates[currUpdateIndex] && Date.parse(locationUpdates[currUpdateIndex].date) < minute) {
          currUpdateIndex++;
        }
        let updateData = locationUpdates[currUpdateIndex - 1];
        if (updateData) {
          newLocationUpdates.push({
            date: moment(minute, 'x'),
            location: updateData.location,
            orientation: updateData.orientation
          });
        } else {
          newLocationUpdates.push(<CondensedLocationUpdate>{ date: moment(minute, 'x') });
        }
      }
    }
    return newLocationUpdates;
  }

  createJobEventRoutes(jobEvents: JobEvent[]): MapDirections[] {
    let jobEventRoutes: MapDirections[] = [];
    jobEvents.forEach(jobEvent => {
      if (jobEvent.startCoordinates && jobEvent.endCoordinates) {
        jobEventRoutes.push({
          origin: jobEvent.startCoordinates.latitude + ', ' + jobEvent.startCoordinates.longitude,
          destination: jobEvent.endCoordinates.latitude + ', ' + jobEvent.endCoordinates.longitude,
          travelMode: 'DRIVING'
        });
      }
    });
    return jobEventRoutes;
  }

  activeUpdateIndex(selectedTime: string): number {
    // get the difference in minutes from the range start and set that number as the index
    let index = Date.parse(selectedTime) - this.timelineStartUnix;
    return (index / 60000);
  }
}
