"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AbsoluteValuePipe = /** @class */ (function () {
    function AbsoluteValuePipe() {
    }
    AbsoluteValuePipe.prototype.transform = function (value) {
        if (!value) {
            return '0';
        }
        return Math.abs(parseFloat(value) || 0).toString();
    };
    return AbsoluteValuePipe;
}());
exports.AbsoluteValuePipe = AbsoluteValuePipe;
