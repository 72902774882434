"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var truck_serializer_1 = require("./truck.serializer");
var resource_service_1 = require("../shared/resource.service");
var index_1 = require("../shared/index");
var TruckService = /** @class */ (function (_super) {
    __extends(TruckService, _super);
    function TruckService(http) {
        return _super.call(this, http, 'trucks/', new truck_serializer_1.TruckSerializer()) || this;
    }
    TruckService.prototype.uploadImage = function (method, truckId, files) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            for (var i = 0; i < files.length; i++) {
                formData.append('image', files[i], files[i].name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(new truck_serializer_1.TruckSerializer().fromJson(JSON.parse(xhr.response)));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = function (event) {
                _this.progress = Math.round(event.loaded / event.total * 100);
            };
            var url = _this.baseUrl + 'trucks/' + truckId + '/';
            xhr.open(method, url, true);
            index_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    return TruckService;
}(resource_service_1.ResourceService));
exports.TruckService = TruckService;
