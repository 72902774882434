<div class="surcharges" *ngIf="(!loading && surcharges.length !== 0) || loading" [ngClass]="{loading: loading}">
  <div class="section-header">
    <div class="left-actions">
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Surcharges Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Surcharge Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Surcharges Selected' | translate }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='surcharge-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Surcharges' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Surcharge' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Surcharges' | translate }}</ng-template>
        </span>
        <span *ngIf="loading" class='surcharge-results' translate>
          Loading Surcharges...
        </span>
      </div>
      <a (click)="openAddSurcharge()" class="new-surcharge">
        <i class="icon-plus-circle"></i>
      </a>
      <action-menu title="Actions">
        <ng-container *ngFor="let option of multipleActionDropdownOptions">
          <a *ngIf="option.link" md-menu-item [routerLink]="" [queryParams]="{ returnTo: '/expenses/' + this.expense.id + '/edit' }">{{ option.name }}</a>
          <a *ngIf="!option.link" md-menu-item (click)="menuAction(option.action)">{{ option.name }}</a>
        </ng-container>
      </action-menu>
    </div>
    <div class="right-actions">
      <div class="expense-surcharge-total">
        {{ expense.surchargeAmount || 0 | currency : 'USD' : 'symbol' }}
      </div>
    </div>
  </div>
  <div class="list-table condensed">
    <div class="table-body" [ngClass]="{'full-height': loading && surcharges.length === 0}">
      <div class="header">
        <div class="head selector">
          <label>
            <input type="checkbox" (change)="selector($event)" [(ngModel)]="allSelected" />
          </label>
        </div>
        <div class="head title sort" (click)="sort('title')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'title'}" translate>
          Item
        </div>
        <div class="head date sort" (click)="sort('time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'time'}" translate>
          Date
        </div>
        <div class="head description sort" (click)="sort('description')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'description'}"
          translate>
          Description
        </div>
        <div class="head amount" (click)="sort('amount')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'amount'}" translate>
          Total
        </div>
        <div class="head actions" translate></div>
      </div>

      <div *ngFor="let surcharge of surcharges" class="row" [ngClass]="{selected: allSelected || surcharge.selected}">
        <div class="selector">
          <label>
            <input type="checkbox" (change)="selector($event, surcharge)" [checked]="allSelected || surcharge.selected" />
          </label>
        </div>
        <div class="title">
          <div class="value">{{ surcharge.title || '&nbsp;' }}</div>
        </div>
        <div class="date">
          <div class="value">{{ surcharge.time | date:'shortDate' || '&nbsp;' }}</div>
        </div>
        <div class="description">
          <div class="value">{{ surcharge.description || '&nbsp;' }}</div>
        </div>
        <div class="amount">
          <div class="value" [ngClass]="{negative: surcharge.amount < 0}">{{ (surcharge.amount | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
        </div>
        <div class="actions">
          <action-menu icon="icon-more">
            <ng-container *ngFor="let option of surcharge.filterOptions(menuOptions)">
              <a *ngIf="option.link" md-menu-item [routerLink]="surcharge.url(option.action)" [queryParams]="{ returnTo: '/expenses/' + this.expense.id + '/edit' }">{{ option.name }}</a>
              <a *ngIf="!option.link" md-menu-item (click)="menuAction(option.action, surcharge)">{{ option.name }}</a>
            </ng-container>
          </action-menu>
        </div>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading"></my-loader>
    </div>
  </div>

  <ruckit-pagination *ngIf="(!loading && count) || loading" [totalCount]="count" [currentPage]="page" (page)="pageChange($event)"></ruckit-pagination>
</div>

<div class="surcharges no-results" *ngIf="!loading && surcharges.length === 0">
  <div class="section-header">
    <div class="left-actions">
      <div class="selection">
        <span *ngIf="!loading && selectedCount > 0" [ngPlural]="selectedCount || 0">
          <ng-template ngPluralCase="=0">{{ (selectedCount || 0) | number:0 }} {{ 'Surcharges Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ (selectedCount || 0) | number:0 }} {{ 'Surcharge Selected' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ (selectedCount || 0) | number:0 }} {{ 'Surcharges Selected' | translate }}</ng-template>
        </span>
        <span *ngIf="!loading && selectedCount === 0" class='surcharge-results' [ngPlural]="count || 0">
          <ng-template ngPluralCase="=0">{{ count || 0 | number:0 }} {{ 'Surcharges' | translate }}</ng-template>
          <ng-template ngPluralCase="=1">{{ count || 0 | number:0 }} {{ 'Surcharge' | translate }}</ng-template>
          <ng-template ngPluralCase="other">{{ count || 0 | number:0 }} {{ 'Surcharge' | translate }}</ng-template>
        </span>
      </div>
      <a (click)="openAddSurcharge()" class="new-surcharge">
        <i class="icon-plus-circle"></i>
      </a>
    </div>
    <div class="right-actions"></div>
  </div>
</div>
