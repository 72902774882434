import { environment } from '../../environments/environment';

export class ImageResizer {
  static getResizedUrl(imageKey: string, width: number, height: number): string {
    imageKey = `${imageKey}?d=${width}x${height}`;
    let time = Math.round((new Date()).getTime() / 60000);
    let cacheBuster = '?cache=' + time;
    return `${environment.resizeImageServerUrl}/${imageKey}${cacheBuster}`;
  }

  static getFullsizeUrl(imageKey: string) {
    let time = Math.round((new Date()).getTime() / 60000);
    let cacheBuster = '?cache=' + time;
    return `${environment.imageServerUrl}/${imageKey}${cacheBuster}`;
  }
}
