"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var InvoiceFilter = /** @class */ (function () {
    function InvoiceFilter(invoiceFilterInfo) {
        invoiceFilterInfo = camelcaseKeysDeep(invoiceFilterInfo);
        this.id = invoiceFilterInfo.id;
        this.name = invoiceFilterInfo.name;
    }
    return InvoiceFilter;
}());
exports.InvoiceFilter = InvoiceFilter;
