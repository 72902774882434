"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var condensed_trip_1 = require("../../condensed-trip");
var TripHistoryBillingComponent = /** @class */ (function () {
    function TripHistoryBillingComponent() {
    }
    Object.defineProperty(TripHistoryBillingComponent.prototype, "trip", {
        set: function (trip) {
            if (!trip) {
                return;
            }
            this.fields = this.getFieldsFromTrip(trip);
        },
        enumerable: true,
        configurable: true
    });
    TripHistoryBillingComponent.prototype.ngOnInit = function () { };
    TripHistoryBillingComponent.prototype.getValueString = function (value) {
        return value ? value : '-';
    };
    TripHistoryBillingComponent.prototype.getFieldsFromTrip = function (trip) {
        var getInvoiceNumber = function () {
            var status = trip.invoiceSent ? 'Sent' : 'Draft';
            if (trip.invoiceNumber) {
                return trip.invoiceNumber + " - " + status;
            }
            return null;
        };
        var fields = [
            {
                name: 'Invoice Rate',
                value: trip.invoiceRate ? "$" + trip.invoiceRate + " per " + trip.invoiceRateUnit : null,
            },
            {
                name: 'Invoice Total',
                value: trip.invoiceTotal ? "$" + trip.invoiceTotal : null,
                color: 'green',
            },
            {
                name: 'Haul Rate',
                value: trip.haulRate ? "$" + trip.haulRate + " per " + trip.haulRateUnit : null,
            },
            {
                name: 'Haul Total',
                value: (trip.haulTotal ? "$" + trip.haulTotal : null),
                color: 'red',
            },
            {
                name: 'Invoice #',
                value: getInvoiceNumber(),
                url: "/invoices/" + trip.invoiceId,
            },
            {
                name: 'Expense #',
                value: (trip.expenseNumber ? "" + trip.expenseNumber : null),
                url: "/expenses/" + trip.expenseId,
            },
            {
                name: 'Paystub #',
                value: (trip.paystubNumber ? "" + trip.paystubNumber : null),
                url: "/paystubs/" + trip.paystubId,
            }
        ];
        return fields;
    };
    return TripHistoryBillingComponent;
}());
exports.TripHistoryBillingComponent = TripHistoryBillingComponent;
