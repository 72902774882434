import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { MatDialog } from '@angular/material';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { TableColumn, TableData } from '../../shared/fancy-table/table.types';
import { ThresholdsDialogComponent } from '../thresholds-dialog/thresholds-dialog.component';
import { NotificationsReport, ThresholdConfig, TimeFrame } from '../services/notifications-report';
import { NotificationsReportService } from '../services/notifications-report.service';
import { PreferenceService } from '../../preferences/preference.service';
import { AuthenticationService } from '../../shared';
import { ChartDialogComponent } from '../chart-dialog/chart-dialog.component';
import { Preference } from '../../preferences/preference';
import { CONSTANTS } from './notifications.constants';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  availableColumns: TableColumn[] = [
    { key: 'name', title: this.translationService.instant('Organization'), sortable: true, sortBy: 'name' },
    { key: 'lastPeriod', title: this.translationService.instant('Last 7 day period'), sortable: true, sortBy: 'last_period' },
    { key: 'currentPeriod', title: this.translationService.instant('Current 7 day period'), sortable: true, sortBy: 'current_period' },
    { key: 'percentageChange', title: this.translationService.instant('Change %') },
    { key: 'chart', title: this.translationService.instant('Chart') },
  ];
  displayedColumns: string[] = CONSTANTS.displayedColumns;
  tableConfig = {
    hasHeader: true,
    ignorePreferences: true,
    service: NotificationsReportService,
    hasNoResultsAction: false
  };
  frameDropdownData = CONSTANTS.frameDropdownData;
  search: string = '';
  currentTimeFrame: TimeFrame = CONSTANTS.defaultTimeFrame;
  thresholdConfig$: BehaviorSubject<ThresholdConfig[]> = new BehaviorSubject([]);
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  showChart = false;
  barChartType: ChartType = 'bar';
  barChartOptions: ChartOptions = CONSTANTS.barChartOptions;
  dataSource: TableData;
  barChartData: ChartData;
  preference: Preference;

  @ViewChild('notificationsReportTable', { static: false }) notificationsReportTable: FancyTableComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private notificationReportService: NotificationsReportService,
    private preferenceService: PreferenceService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) { }
  
  ngOnInit(): void {
    this.allSubscriptionsToUnsubscribe.push(
      this.thresholdConfig$.subscribe(
        (config) => this.handleThresholdConfigChange(config)
      )
    );
    this.getReportConfig();
    this.getPreferences();
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => sub.unsubscribe());
  }

  handleThresholdConfigChange(config: ThresholdConfig[]) {
    if (!config.length || !this.dataSource || !this.dataSource.data.length) { return; }
    // config rules have to be ordered in descending order by value
    this.dataSource.data.forEach((el) => {
      const matchingElement = config.find(rule => Math.abs(el.percentageChange) >= rule.value);
      if (matchingElement) {
        const color = matchingElement.color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        const r = Number(color[1]);
        const g = Number(color[2]);
        const b = Number(color[3]);
        const luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);
        el.textColor = luma <= 128 ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, .78)';
        el.ruleColor = matchingElement.color;
      }
    });
    this.notificationsReportTable.dataSource.data = this.dataSource.data;
  }

  getDataSource(dataSource: TableData) {
    this.dataSource = dataSource;
    this.barChartData = {
      labels: dataSource.data.map(el => el.name),
      datasets: [
        {
          data: dataSource.data.map(el => el.lastPeriod),
          label: this.currentTimeFrame.lastPeriodColumnName,
          backgroundColor: 'rgba(174, 214, 241, 0.8)',
          hoverBackgroundColor: '#AED6F1'
        },
        {
          data: dataSource.data.map(el => el.currentPeriod),
          label: this.currentTimeFrame.currentPeriodColumnName,
          backgroundColor: 'rgba(255, 218, 185, 0.8)',
          hoverBackgroundColor: '#FFDAB9'
        }
      ]
    };
    if (this.thresholdConfig$.value.length) {
      const currentRules = this.thresholdConfig$.value;
      this.thresholdConfig$.next(currentRules);
    }
  }

  getReportConfig() {
    this.notificationReportService.getReportConfig().subscribe(
      (resp) => this.thresholdConfig$.next(resp)
    )
  }

  getPreferences() {
    const currentUser = this.authenticationService.user();
    this.preferenceService.list({
      name: this.notificationReportService.preferenceKey,
      type: 'user',
      profile: currentUser.id
    }).pipe(
      switchMap((resp) => {
        if (resp && resp.length) {
          return of(resp[0]); 
        } else {
          return this.preferenceService.save({
            name: this.notificationReportService.preferenceKey,
            type: 'user',
            profile: currentUser.id,
            blob: {
              timeFrame: 'seven-days'
            }
          });
        }
      })
    ).subscribe(
      (preference) => {
        this.preference = preference;
        const selectedFrame = this.frameDropdownData.frames.find((opt) => opt.id === this.preference.blob.timeFrame);
        this.currentTimeFrame = selectedFrame;
        this.notificationsReportTable.getRecords({ frame: this.preference.blob.timeFrame });
      }
    );
  }

  changeFrameSelection(data: TimeFrame) {
    this.currentTimeFrame = data;
    this.preference.blob.timeFrame = data.id;
    this.preferenceService.save(this.preference).subscribe();
    if (this.notificationsReportTable) {
      this.notificationsReportTable.getRecords({ frame: data.id });
      this.availableColumns.forEach(col => {
        if (col.key === 'lastPeriod') {
          col.title = this.translationService.instant(data.lastPeriodColumnName);
        }
        if (col.key === 'currentPeriod') {
          col.title = this.translationService.instant(data.currentPeriodColumnName);
        }
      });
    }
  }

  openThresholdsDialog() {
    const dialog = this.dialog.open(ThresholdsDialogComponent, {
      width: '380px',
      maxHeight: '600px'
    });
    dialog.componentInstance.thresholdConfig$.next(this.thresholdConfig$.value);
    dialog.componentInstance.callback = (rules: ThresholdConfig[]) => this.thresholdConfig$.next(rules);
  }

  openChartDialog(data: NotificationsReport) {
    const dialog = this.dialog.open(ChartDialogComponent);
    dialog.componentInstance.reportData = data;
    dialog.componentInstance.currentTimeFrame = this.currentTimeFrame;
  }
}
