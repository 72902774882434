<div class="dialog-header">
  <h2 class="dialog-header-text">{{ attributes.title }}</h2>
  <span class="close" (click)="confirmDialog.close(0)">+</span>
</div>
<div class="dialog-body">
  <h3 class="dialog-body-text">{{ attributes.body }}</h3>
</div>
<div class="dialog-actions">
  <button class="btn btn-cancel no" (click)="confirmDialog.close(1)">{{ attributes.no }}</button>
  <button class="btn btn-primary yes" (click)="confirmDialog.close(2)">{{ attributes.yes }}</button>
</div>
