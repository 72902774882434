"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var driver_serializer_1 = require("./driver.serializer");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var resource_service_1 = require("../shared/resource.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DriverService = /** @class */ (function (_super) {
    __extends(DriverService, _super);
    function DriverService(http) {
        var _this = _super.call(this, http, 'drivers/', new driver_serializer_1.DriverSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        _this.count = 0;
        return _this;
    }
    DriverService.prototype.saveDriver = function (driver) {
        var driverUrl = this.baseUrl + 'drivers/';
        var _driver = decamelizeKeysDeep(lodash_1.clone(driver));
        if (typeof _driver.carrier === 'object') {
            _driver.carrier = _driver.carrier.id;
        }
        if (_driver.user && _driver.user.username === _driver.username) {
            delete _driver.user.username;
        }
        if (_driver.user && _driver.user.password.length === 0) {
            delete _driver.user.password;
        }
        _driver.tags = _driver.tags && _driver.tags.map(function (tag) {
            if (typeof tag === 'object') {
                tag = tag.name;
            }
            return tag;
        });
        if (_driver.image) {
            delete _driver.image;
        }
        if (_driver.truck && typeof _driver.truck === 'object') {
            _driver.truck = _driver.truck.id;
        }
        if (!driver.id) {
            _driver['unmanned_weighing'] = false;
            return this.http.post(driverUrl, _driver, { headers: api_service_1.requestHeaders() });
        }
        else {
            return this.http.put(driverUrl + driver.id + '/', _driver, { headers: api_service_1.requestHeaders() });
        }
    };
    DriverService.prototype.invite = function (driver) {
        var inviteUrl = this.baseUrl + 'invitations/';
        var _driver = decamelizeKeysDeep(lodash_1.clone(driver));
        var params = { kind: 'new_driver', data: { driver: _driver } };
        if (!driver.id) {
            return this.http.post(inviteUrl, params, { headers: api_service_1.requestHeaders() });
        }
    };
    DriverService.prototype.assign = function (driver) {
        var _this = this;
        var assignUrl = this.baseUrl + 'drivers/' + driver.id + '/?all_carriers=True';
        return this.http.put(assignUrl, {
            truck: driver.truck.id
        }, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    DriverService.prototype.listCondensed = function (params) {
        var _this = this;
        return this.http.get(this.baseUrl + 'drivers/compact/', {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return (_this.extractData(res)); }), operators_1.catchError(api_service_1.handleError));
    };
    DriverService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (driver) {
                return new driver_serializer_1.DriverSerializer().fromJson(driver);
            });
        }
        else if (res) {
            return new driver_serializer_1.DriverSerializer().fromJson(res);
        }
        else {
            return {};
        }
    };
    DriverService.prototype.uploadImage = function (method, driverId, files) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            for (var i = 0; i < files.length; i++) {
                formData.append('image', files[i], files[i].name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = function (event) {
                _this.progress = Math.round(event.loaded / event.total * 100);
            };
            var url = _this.baseUrl + 'drivers/' + driverId + '/';
            xhr.open(method, url, true);
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    return DriverService;
}(resource_service_1.ResourceService));
exports.DriverService = DriverService;
