import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

// libraries
import {
  difference,
  get,
  find as _find,
  remove,
  uniqBy,
  findIndex,
} from 'lodash';

// material
import { MatDialog, MatDialogRef } from '@angular/material';

// services
import { PunchCardService } from './../../punch-cards/punch-card.service';
import { PreferenceService } from '../../preferences/preference.service';

// models
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { Driver } from '../driver';
import { Preference } from '../../preferences/preference';
import { CondensedPunchCard } from '../../punch-cards/condensed-punch-card';

// components
import { DriverTripFiltersDialogComponent } from '../driver-trip-filters-dialog/driver-trip-filters-dialog.component';
import { ColumnToggleComponent } from '../../shared/column-toggle/column-toggle.component';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import {
  FieldOption,
  ExportDialogComponent,
  ExportDialogData,
} from '../../shared/export-dialog/export-dialog.component';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { NewPunchCardDialogComponent } from '../../punch-cards/new-punch-card-dialog.component';

// constants
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS } from './columns';

// utils
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'driver-punch-cards',
  templateUrl: './driver-punch-cards.component.html',
  styleUrls: ['./driver-punch-cards.component.scss'],
})
export class DriverPunchCardsComponent implements OnInit {
  @Input() driver: Driver;

  loading = false;
  errors = [];
  appliedFilters: FilterOption[] = [];
  search = '';
  displayedColumns = [...DISPLAYEDCOLUMNS];
  availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  tableConfig = {
    collectionTitle: this.translationService.instant('Punchcards'),
    noResultsText: this.translationService.instant('a punchcard'),
    hasHeader: true,
    service: PunchCardService,
    serviceFunction: 'getCondensedPunchCards',
    filterQuery: false,
    preferenceKey: 'DriverPunchCards-CondensedPunchService',
    preferencesEnabled: true,
    newRecordModal: () => this.openAddPunchCard(),
    query: {
      serializer: 'CondensedPunchCard',
      driver: '',
    },
  };
  multipleActionDropdownOptions = [
    { name: 'Export', action: 'export', button: true, link: false },
    { name: 'Void', action: 'void', button: true, link: false, disabled: true },
  ];
  menuOptions = [
    { name: 'Edit', action: 'edit', link: true },
    { name: 'Unvoid', action: 'unvoid', link: false },
    { name: 'Void', action: 'void', link: false },
  ];
  filtersDialog: DriverTripFiltersDialogComponent;
  @ViewChild('columnToggle', { static: false })
  columnToggle: ColumnToggleComponent;

  punchCards: any = [];
  expandedPunchCards = [];
  enabledFeatures: string[] = [];
  count = 0;
  allSelected = false;
  selectedPunchCards = [];
  allLoadedPunchCards = [];
  confirmDialog: MatDialogRef<any>;
  lockedExportFields = null;
  checkingDuplicates = false;
  preference: Preference;
  currentUrl: string;

  @ViewChild('punchCardsTable', { static: false })
  punchCardsTable: FancyTableComponent;

  constructor(
    private translationService: TranslateService,
    private punchCardService: PunchCardService,
    private preferenceService: PreferenceService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.tableConfig.query = {
      ...this.tableConfig.query,
      driver: this.driver.id,
    };
    this.currentUrl = this.router.url.split('?')[0];
  }

  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  openFilters(): void {
    const dialog = this.dialog.open(DriverTripFiltersDialogComponent, {
      width: '430px',
    });

    dialog.componentInstance.title =
      this.translationService.instant('Filter Punch Cards');
    dialog.componentInstance.callback = (res) => this.filterChanges(res);

    let startDate = get(
      _find(this.appliedFilters, { key: 'startDate' }),
      'values'
    );
    if (startDate && startDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.startDate = new Date(startDate);
    }
    let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
    if (endDate && endDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.endDate = new Date(endDate);
    }

    dialog.componentInstance.model.job = get(
      _find(this.appliedFilters, { key: 'job' }),
      'values'
    );
    dialog.componentInstance.model.project = get(
      _find(this.appliedFilters, { key: 'project' }),
      'values'
    );
    dialog.componentInstance.model.customer = get(
      _find(this.appliedFilters, { key: 'customer' }),
      'values'
    );
    dialog.componentInstance.visibleFields.showEdited = false;
    dialog.componentInstance.visibleFields.showRetake = false;
    dialog.componentInstance.visibleFields.showIncomplete = false;

    dialog.componentInstance.model = Object.assign(
      dialog.componentInstance.model,
      this.appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values;
        return acc;
      }, {})
    );

    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      job: 'jobevent__job',
      carrier: 'driver__carrier',
      duplicates: 'only_dupes',
      uninvoiced: 'uninvoiced',
      startDate: 'jobevent__shift1_start__gte',
      endDate: 'jobevent__shift1_start__lte',
    };

    const displayKeys = {
      duplicates: 'Duplicates',
    };

    let falseyFilters = [];

    Object.keys(filterRes).forEach((key) => {
      if (filterRes[key]) {
        const query = {};
        let values, displayValues;
        values =
          filterRes[key] && key === 'tags'
            ? filterRes[key].map((v) => v.name).join(',')
            : filterRes[key];
        displayValues =
          filterRes[key] && filterRes[key]['name']
            ? filterRes[key]['name']
            : values;
        if (typeof values === 'boolean') {
          if (key === 'incomplete' && values) {
            displayValues = values.toString();
            displayValues =
              displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
            let filterValue = (!values).toString();
            filterValue =
              filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
            query[queryKeys[key]] = filterValue;
          } else if (values) {
            values = values.toString();
            values = values.charAt(0).toUpperCase() + values.slice(1);
            query[queryKeys[key]] = values;
          }
        } else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
          if (typeof values === 'string') {
            query[queryKeys[key]] = values;
          } else {
            query[queryKeys[key]] =
              filterRes[key] && filterRes[key].id
                ? filterRes[key].id
                : filterRes[key];
          }
        } else if (values) {
          query[queryKeys[key]] =
            filterRes[key] && (filterRes[key].id || filterRes[key]);
        }
        let filter = new FilterOption({
          filterType:
            ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
          key,
          title: key.charAt(0).toUpperCase() + key.slice(1),
          displayValues: displayValues || null,
          displayKey: displayKeys[key] || null,
          values,
          query,
        });
        if (filter.values === 'False' || !filter.values) {
          falseyFilters.push(filter);
        }
        let existingFilterIndex = findIndex(this.appliedFilters, { key: key });
        if (existingFilterIndex !== -1) {
          this.appliedFilters[existingFilterIndex] = filter;
        } else {
          this.appliedFilters.push(filter);
        }
      } else {
        let existingFilter = _find(this.appliedFilters, { key: key });
        if (existingFilter) {
          remove(this.appliedFilters, existingFilter);
        }
      }
    });
    this.appliedFilters = uniqBy(
      difference(this.appliedFilters, falseyFilters),
      'key'
    );
  }

  openAddPunchCard() {
    const driver = { ...this.driver };
    const dialog = this.dialog.open(NewPunchCardDialogComponent, {
      width: '455px',
    });
    dialog.componentInstance.model.driver = driver;
    dialog.componentInstance.model.carrier = driver.carrierId;
    dialog.componentInstance.model.truck = driver.truck;
    dialog.componentInstance.callback = this.savePunchCardCallback;
  }

  savePunchCardCallback = () => {
    this.getPunchCards();
  };

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedPunchCards();
        break;
      case 'locked-export':
        this.exportSelectedPunchCards(null, true);
        break;
      case 'void':
        this.voidSelectedPunchCards();
        break;
    }
  }

  menuAction(name, punchCard) {
    switch (name) {
      case 'void':
        this.voidPunchCard(punchCard);
        break;
      case 'unvoid':
        this.unvoidPunchCard(punchCard);
        break;
    }
  }

  exportSelectedPunchCards(punchCards = null, locked = false) {
    let { params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters,
      punchCards || this.selectedPunchCards,
      this.expandedPunchCards,
      this.allSelected,
      this.search
    );
    if (this.enabledFeatures.includes('hasExportTracking')) {
      scope['markAsExported'] = true;
    }
    if (this.enabledFeatures.includes('hasExportProtection')) {
      params = params.set('unexported', 'True');
    }

    params = params.set('driver', this.driver.id);

    this.punchCardService.getExportFields().subscribe(
      (fields: FieldOption[]) => {
        this.dialog.open(ExportDialogComponent, {
          width: 'auto',
          data: <ExportDialogData>{
            type: 'punchcards',
            buttonText: 'Export Data to CSV',
            callback: () => {
              this.punchCardsTable.deselectAll();
            },
            fields,
            params,
            scope,
            service: this.punchCardService,
            lockedFields: locked ? this.lockedExportFields : null,
          },
        });
      },
      (err) => {
        this.errors = err;
      }
    );
  }

  voidSelectedPunchCards() {
    const punchCards = this.selectedPunchCards;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Punch Cards?',
      body: 'These punch cards will be marked as "Void" and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.punchCardsTable.loading = true;
        forkJoin(
          punchCards.map((punchCard) =>
            this.punchCardService.save({ id: punchCard.id, void: true })
          )
        ).subscribe(
          () => this.getPunchCards(),
          (err) => {
            this.errors = err;
            this.punchCardsTable.loading = false;
          }
        );
      }
      this.confirmDialog = null;
      this.punchCardsTable.deselectAll();
    });
  }

  voidPunchCard(punchCard) {
    punchCard.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Punch Card?',
      body: 'This Punch Card will be marked as "Void" and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.punchCardsTable.loading = true;
        this.punchCardService.save({ id: punchCard.id, void: true }).subscribe(
          (result) => {
            punchCard.void = true;
            punchCard.loading = false;
            this.punchCardsTable.loading = false;
          },
          (err) => {
            this.errors = err;
            punchCard.loading = false;
            this.punchCardsTable.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  unvoidPunchCard(punchCard) {
    punchCard.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unvoid Punch Card?',
      body: 'This Punch Card will be unmarked as "Void" and will be visible for the Job.',
      close: 'Cancel',
      accept: 'Unvoid',
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.punchCardsTable.loading = true;
        this.punchCardService.save({ id: punchCard.id, void: false }).subscribe(
          (result) => {
            punchCard.void = false;
            punchCard.loading = false;
            this.punchCardsTable.loading = false;
          },
          (err) => {
            this.errors = err;
            punchCard.loading = false;
            this.punchCardsTable.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  duplicateCheck(): void {
    if (!this.checkingDuplicates) {
      const filter = {
        filterType: 'text',
        key: 'duplicates',
        title: 'Duplicates',
        displayValues: 'True',
        values: true,
        query: {
          only_dupes: 'True',
        },
      } as FilterOption;
      const oldFilters = this.appliedFilters.map((f) => f);
      oldFilters.push(filter);
      this.appliedFilters = oldFilters;
      this.checkingDuplicates = !this.checkingDuplicates;
    } else {
      this.appliedFilters = this.appliedFilters.filter(
        (f) => f.key !== 'duplicates'
      );
      this.checkingDuplicates = !this.checkingDuplicates;
    }
  }

  removeFilter(filter): void {
    remove(this.appliedFilters, filter);
    let duplicateFilter = _find(this.appliedFilters, { key: 'duplicates' });
    if (!duplicateFilter) {
      this.checkingDuplicates = false;
    }
    this.savePreferences(this.appliedFilters);
    this.getPunchCards();
  }

  savePreferences(filters) {
    const preference = this.punchCardsTable.preference;
    if (preference) {
      this.preference = {
        ...preference,
        blob: { filters: filters },
      };
      this.preferenceService
        .save(this.preference)
        .subscribe((updatedPreference) => {
          this.preference = updatedPreference;
        });
    }
  }

  selector(event: {
    allSelected: boolean;
    selection: SelectionModel<CondensedPunchCard>;
    exclusion: SelectionModel<CondensedPunchCard>;
  }) {
    this.allSelected = event.allSelected;
    this.selectedPunchCards = this.allSelected
      ? (this.selectedPunchCards = this.allLoadedPunchCards.filter(
          (p) => !event.exclusion.selected.some((ex) => ex.id === p.id)
        ))
      : [...event.selection.selected];

    this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map(
      (opt) =>
        opt.action === 'void'
          ? {
              ...opt,
              disabled:
                event.selection.selected.length === 0 && !event.allSelected,
            }
          : opt
    );
  }

  getPunchCards() {
    this.punchCardsTable.getRecords();
  }

  onDataLoaded({ data }: { data: CondensedPunchCard[] }) {
    this.allLoadedPunchCards = [...data];
    if (
      this.punchCardsTable.preference &&
      this.punchCardsTable.preference.blob &&
      this.punchCardsTable.preference.blob.filters
    ) {
      this.checkingDuplicates =
        this.punchCardsTable.preference.blob.filters.some(
          (f: FilterOption) => f.key === 'duplicates'
        );
    }
  }
}
