import { Assignment } from '../assignments/assignment';
import { Driver } from '../drivers/driver';
import { DriverSerializer } from './../drivers/driver.serializer';
import { JobEvent } from '../job-events/job-event';
import { Job } from '../jobs/job';
import { Truck } from '../trucks/truck';
import { Checkin } from '../checkins/checkin';
import { remove, find, clone } from 'lodash';
import * as moment from 'moment';
import { ImageResizer } from '../images/resizer';
import { TripDecision } from './trip-decision';
import { JobEventShare } from '../job-event-shares/job-event-share';
import { JobEventShareSerializer } from '../job-event-shares/job-event-share.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Trip {
  id: string;
  phaseCode: string;
  assignment: Assignment;
  driver: Driver;
  jobEvent: JobEvent;
  truck: Truck;
  loadingCheckin: Checkin;
  unloadingCheckin: Checkin;
  shift: string;
  tripStatus: string;
  enrouteLoadingTime: string;
  loadingArrivalTime: string;
  loadingCompleteTime: string;
  enrouteUnloadingTime: string;
  unloadingArrivalTime: string;
  unloadingCompleteTime: string;
  startTimeTimestamp: string;
  endTimeTimestamp: string;
  connexPaverStartUnloading: string;
  connexPaverEndUnloading: string;
  duration: string;
  durationRounded: string;
  completed: boolean;
  edited: boolean;
  retakeStatus: string;
  weight: string;
  weightUnit: string;
  invoiceTotal: string;
  added: boolean;
  void: boolean;
  routePolyline: string;
  haulTotal: string;
  ticketNumber: string;
  loading = false;
  index: number;
  currentTrip = false;
  job: Job;
  jobDisplayName: string;
  completedTripDuration: number;
  completedTripDurationRounded: number;
  completedTripTime: string;
  _startTime: string;
  _endTime: string;
  loadingEnRouteTime: string;
  loadingTime: string;
  unloadingEnRouteTime: string;
  unloadingTime: string;
  timezone: string;
  origin: string;
  schedule: string;
  approvedByName: string;
  approved: boolean;
  decision: TripDecision;
  latestDecision: string;
  latestDecisionStatus: string;
  latestDecider: string;
  latestDeciderName: string;
  exported?: boolean;
  latestExportedAt: string;
  reviewerNotes: string;
  jobEventShare: JobEventShare;

  constructor(tripInfo: any) {
    tripInfo = camelcaseKeysDeep(tripInfo);
    this.id = tripInfo.id;
    this.assignment = tripInfo.assignment;
    if (tripInfo.driver) {
      this.driver = new DriverSerializer().fromJson(tripInfo.driver);
    }
    if (tripInfo.loadingCheckin) {
      if (tripInfo.jobevent && tripInfo.jobevent.job) {
        tripInfo.loadingCheckin.options = tripInfo.jobevent.job.checkinOptions;
      }
      this.loadingCheckin = new Checkin(tripInfo.loadingCheckin);
    }
    if (tripInfo.unloadingCheckin) {
      if (tripInfo.jobevent && tripInfo.jobevent.job) {
        tripInfo.unloadingCheckin.options = tripInfo.jobevent.job.checkoutOptions;
      }
      this.unloadingCheckin = new Checkin(tripInfo.unloadingCheckin);
    }
    this.jobEvent = tripInfo.jobevent;
    this.truck = tripInfo.truck;
    this.shift = tripInfo.shift;
    this.phaseCode = tripInfo.phaseCode;
    this.tripStatus = tripInfo.tripStatus;
    this.routePolyline = tripInfo.routePolyline;
    this.enrouteLoadingTime = tripInfo.enrouteLoadingTime;
    this.loadingArrivalTime = tripInfo.loadingArrivalTime;
    this.loadingCompleteTime = tripInfo.loadingCompleteTime;
    this.enrouteUnloadingTime = tripInfo.enrouteUnloadingTime;
    this.unloadingArrivalTime = tripInfo.unloadingArrivalTime;
    this.unloadingCompleteTime = tripInfo.unloadingCompleteTime;
    this.connexPaverStartUnloading = tripInfo.connexPaverStartUnloading;
    this.connexPaverEndUnloading = tripInfo.connexPaverEndUnloading;
    this.startTimeTimestamp = tripInfo.startTime;
    this.endTimeTimestamp = tripInfo.endTime;
    this.completed = tripInfo.completed;
    this.edited = tripInfo.edited;
    this.retakeStatus = tripInfo.retakeStatus;
    this.weight = tripInfo.weight;
    this.weightUnit = tripInfo.weightUnit;
    this.invoiceTotal = tripInfo.invoiceTotal;
    this.added = tripInfo.added;
    this.void = tripInfo.void;
    this.haulTotal = tripInfo.haulTotal;
    this.ticketNumber = tripInfo.ticketNumber;
    this.index = tripInfo.index;
    this.currentTrip = tripInfo.currentTrip;
    this.job = tripInfo.job;
    this.jobDisplayName = this.job && this.job.name;
    this.timezone = tripInfo.timezone;
    this.origin = tripInfo.origin;
    this.decision = tripInfo.decision;
    this.latestDecision = tripInfo.latestDecision;
    this.latestDecisionStatus = tripInfo.latestDecisionStatus;
    this.latestDecider = tripInfo.latestDecider;
    this.latestDeciderName = tripInfo.latestDeciderName;
    this.latestExportedAt = tripInfo.latestExportedAt;
    this.exported = !!this.latestExportedAt;

    if (this.endTimeTimestamp && Date.parse(this.endTimeTimestamp) < Date.parse(this.startTimeTimestamp)) {
      this.endTimeTimestamp = moment(
        new Date(Date.parse(this.startTimeTimestamp)).toDateString() + ' ' + new Date(Date.parse(this.endTimeTimestamp)).toTimeString()
      ).toISOString();
    }

    if (this.startTimeTimestamp && !this.endTimeTimestamp) {
      let duration = <any>moment.duration(moment(new Date()).diff(this.startTimeTimestamp));
      this.completedTripDuration = duration.asMilliseconds();
      this.completedTripTime = duration.format('D[ days], H[ hrs], m[ mins] total');
    } else {
      let duration = <any>moment.duration(moment(this.endTimeTimestamp).diff(this.startTimeTimestamp));
      this.completedTripDuration = duration.asMilliseconds();
      this.completedTripTime = duration.format('D[ days], H[ hrs], m[ mins] total');
    }
    if (this.startTimeTimestamp) {
      const duration = this.getTimeDuration(this.startTimeTimestamp, this.endTimeTimestamp, 'minute');
      this.completedTripDurationRounded = duration.asMilliseconds();
    }
    if (this.startTimeTimestamp) {
      let d = new Date(this.startTimeTimestamp);
      this._startTime = moment(d).format('h:mm a');
    } else {
      this._startTime = '';
    }
    if (this.endTimeTimestamp) {
      let d = new Date(this.endTimeTimestamp);
      this._endTime = moment(d).format('h:mm a');
    } else {
      this._endTime = '';
    }
    if (this._endTime && this._endTime.length) {
      this.schedule = [this._startTime, this._endTime].join(' - ');
    } else {
      this.schedule = [this._startTime, 'OPEN'].join(' - ');
    }
    if (this.startTimeTimestamp && !this.endTimeTimestamp) {
      let duration = <any>moment.duration(moment(new Date()).diff(this.startTimeTimestamp));
      this.duration = duration.format('D[ days], H[ hrs], m[ mins]');
    } else {
      let duration = <any>moment.duration(moment(this.endTimeTimestamp).diff(this.startTimeTimestamp));
      this.duration = duration.format('D[ days], H[ hrs], m[ mins]');
    }
    if (this.startTimeTimestamp) {
      let duration = this.getTimeDuration(this.startTimeTimestamp, this.endTimeTimestamp, 'minute');
      this.durationRounded = duration.format('D[ days], H[ hrs], m[ mins]');
    }
    if (this.enrouteLoadingTime && !this.loadingArrivalTime) {
      let duration = <any>moment.duration(moment(new Date()).diff(this.enrouteLoadingTime));
      this.loadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else if (this.enrouteLoadingTime && this.loadingArrivalTime) {
      let duration = <any>moment.duration(moment(this.loadingArrivalTime).diff(this.enrouteLoadingTime));
      this.loadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else {
      this.loadingEnRouteTime = '';
    }

    if (this.loadingArrivalTime && !this.loadingCompleteTime) {
      let duration = <any>moment.duration(moment(new Date()).diff(this.loadingArrivalTime));
      this.loadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else if (this.loadingArrivalTime && this.loadingCompleteTime) {
      let duration = <any>moment.duration(moment(this.loadingCompleteTime).diff(this.loadingArrivalTime));
      this.loadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else {
      this.loadingTime = '';
    }

    let startTime = this.enrouteUnloadingTime || this.loadingCompleteTime;
    if (startTime && !this.unloadingArrivalTime) {
      let duration = <any>moment.duration(moment(new Date()).diff(startTime));
      this.unloadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else if (startTime && this.unloadingArrivalTime) {
      let duration = <any>moment.duration(moment(this.unloadingArrivalTime).diff(startTime));
      this.unloadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else {
      this.unloadingEnRouteTime = '';
    }

    if (this.unloadingArrivalTime && !this.unloadingCompleteTime) {
      let duration = <any>moment.duration(moment(new Date()).diff(this.unloadingArrivalTime));
      this.unloadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else if (this.unloadingArrivalTime && this.unloadingCompleteTime) {
      let duration = <any>moment.duration(moment(this.unloadingCompleteTime).diff(this.unloadingArrivalTime));
      this.unloadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
    } else {
      this.unloadingTime = '';
    }

    if (tripInfo.jobeventshare && typeof (tripInfo.jobeventshare) === 'object') {
      this.jobEventShare = new JobEventShareSerializer().fromJson(tripInfo.jobeventshare);
    } else {
      this.jobEventShare = new JobEventShareSerializer().fromJson({ id: tripInfo.jobeventshare });
    }

    this.reviewerNotes = tripInfo.reviewerNotes;
  }

  private getTimeDuration(startTime: string, endTime?: string, roundedTo?: moment.unitOfTime.StartOf): moment.Duration {
    let endMoment = (endTime && moment(endTime).isValid()) ? moment(endTime) : moment();
    let startMoment = moment(startTime);
    if (roundedTo) {
      endMoment = endMoment.startOf(roundedTo);
      startMoment = startMoment.startOf(roundedTo);
    }
    const duration = moment.duration(endMoment.diff(startMoment));
    return duration;
  }

  save() {
    // Save to API
    console.log(this.id);
  }

  get startTime(): string {
    return this._startTime;
  }

  get endTime(): string {
    return this._endTime;
  }

  get startDate(): any {
    return new Date(this.startTimeTimestamp);
  }

  get endDate(): any {
    if (this.endTimeTimestamp) {
      return new Date(this.endTimeTimestamp);
    } else {
      return this.startDate;
    }
  }

  set startDate(value: any) {
    let date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.startTime);
    this.startTimeTimestamp = date.toISOString();
  }

  set endDate(value: any) {
    let date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.endTime);
    this.endTimeTimestamp = date.toISOString();
  }

  set startTime(value: string) {
    if (value) {
      let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
      let date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
      let parsedDate = moment(date + ' ' + value, pattern);
      this.startTimeTimestamp = parsedDate.toISOString();
      this._startTime = parsedDate.format('h:mm a');
    }
  }

  set endTime(value: string) {
    if (value) {
      let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
      let date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
      let parsedDate = moment(date + ' ' + value, pattern);
      this.endTimeTimestamp = parsedDate.toISOString();
      this._endTime = parsedDate.format('h:mm a');
    }
  }

  get truckTypeName(): string {
    if (typeof this.truck.truckType === 'string') {
      return this.truck.truckType;
    } else {
      return this.truck.truckType.name;
    }
  }

  get loadingTicket(): string {
    return this.loadingCheckin && this.loadingCheckin.ticketImage;
  }

  get unloadingTicket(): string {
    return this.unloadingCheckin && this.unloadingCheckin.ticketImage;
  }

  get loadingTicketNumber(): string {
    return this.loadingCheckin && this.loadingCheckin.ticketNumber;
  }

  get unloadingTicketNumber(): string {
    return this.unloadingCheckin && this.unloadingCheckin.ticketNumber;
  }

  get loadingSignature(): string {
    return this.loadingCheckin && this.loadingCheckin.signatureImage;
  }

  get unloadingSignature(): string {
    return this.unloadingCheckin && this.unloadingCheckin.signatureImage;
  }

  get signatureImagesRequired(): boolean {
    if (
      (this.loadingCheckin && this.loadingCheckin.options.signatureImage) ||
      (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasSignatureImages(): boolean {
    if (
      (this.loadingCheckin && this.loadingCheckin.options.signatureImage && !this.loadingSignature) ||
      (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage && !this.unloadingSignature)) {
      return false;
    } else {
      return true;
    }
  }

  get loadingSignatureImagesRequired(): boolean {
    if (this.loadingCheckin && this.loadingCheckin.options.signatureImage) {
      return true;
    } else {
      return false;
    }
  }

  get hasLoadingSignatureImages(): boolean {
    if (this.loadingCheckin && this.loadingCheckin.options.signatureImage && !this.loadingSignature) {
      return false;
    } else {
      return true;
    }
  }

  get inlineLoadingSignature(): string {
    if (this.loadingCheckin && this.loadingCheckin.signatureImageKey) {
      return ImageResizer.getResizedUrl(this.loadingCheckin.signatureImageKey, 24, 24);
    }
  }

  get unloadingSignatureImagesRequired(): boolean {
    if (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage) {
      return true;
    } else {
      return false;
    }
  }

  get inlineUnloadingSignature(): string {
    if (this.unloadingCheckin && this.unloadingCheckin.signatureImageKey) {
      return ImageResizer.getResizedUrl(this.unloadingCheckin.signatureImageKey, 24, 24);
    }
  }

  get hasUnloadingSignatureImages(): boolean {
    if (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage && !this.unloadingSignature) {
      return false;
    } else {
      return true;
    }
  }

  get ticketImagesRequired(): boolean {
    if (
      (this.loadingCheckin && this.loadingCheckin.options.ticketImage) ||
      (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get hasTicketImages(): boolean {
    if (
      (this.loadingCheckin && this.loadingCheckin.options.ticketImage && !this.loadingTicket) ||
      (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage && !this.unloadingTicket)) {
      return false;
    } else {
      return true;
    }
  }

  get loadingTicketImagesRequired(): boolean {
    if (this.loadingCheckin && this.loadingCheckin.options.ticketImage) {
      return true;
    } else {
      return false;
    }
  }

  get hasLoadingTicketImages(): boolean {
    if (this.loadingCheckin && this.loadingCheckin.options.ticketImage && !this.loadingTicket) {
      return false;
    } else {
      return true;
    }
  }

  get unloadingTicketImagesRequired(): boolean {
    if (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage) {
      return true;
    } else {
      return false;
    }
  }

  get hasUnloadingTicketImages(): boolean {
    if (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage && !this.unloadingTicket) {
      return false;
    } else {
      return true;
    }
  }

  get administrativeStatus(): string {
    if (this.void) {
      return 'void';
    } else if (this.retakeStatus === 'requested') {
      return 'retake';
    } else if (this.edited) {
      return 'edited';
    }
    return '';
  }

  get displayableStatus(): boolean {
    if (this.administrativeStatus && this.administrativeStatus.length) {
      return true;
    } else {
      return false;
    }
  }

  get retakeRequested(): boolean {
    if (this.retakeStatus && this.retakeStatus === 'requested') {
      return true;
    } else {
      return false;
    }
  }

  get ticketNumbers(): string {
    let values = [];
    if (this.loadingTicketNumber && this.loadingTicketNumber.length) {
      values.push(this.loadingTicketNumber);
    }
    if (this.unloadingTicketNumber && this.unloadingTicketNumber.length) {
      values.push(this.unloadingTicketNumber);
    }

    return values.filter(Boolean).join(', ');
  }

  get jobName(): string {
    return this.jobEvent && this.jobEvent.job && this.jobEvent.job.name || '&nbsp;';
  }

  get projectName(): string {
    return this.jobEvent && this.jobEvent.job && this.jobEvent.job.project &&
      this.jobEvent.job.project.name || '&nbsp;';
  }

  get customerName(): string {
    return this.jobEvent && this.jobEvent.job && this.jobEvent.job.project &&
      this.jobEvent.job.project.customerOrganization &&
      this.jobEvent.job.project.customerOrganization.name || '&nbsp;';
  }

  get truckName(): string {
    let values = [];
    values.push(this.truck && this.truck.name);
    if (typeof this.truck.truckType === 'string') {
      values.push(this.truck.truckType && this.truck.truckType);
    } else {
      values.push(this.truck.truckType && this.truck.truckType.name);
    }

    return values.filter(Boolean).join(' - ');
  }

  get stage(): string {
    let value = '';

    if (this.unloadingCompleteTime) {
      value = 'complete';
    } else if (this.unloadingArrivalTime) {
      value = 'unloading';
    } else if (this.loadingCompleteTime) {
      value = 'en-route-two';
    } else if (this.loadingArrivalTime) {
      value = 'loading';
    } else if (this.enrouteLoadingTime) {
      value = 'en-route-one';
    }

    return value;
  }

  url(action): string {
    return '/trips/' + this.id + '/' + action;
  }

  get name(): string {
    let values = [];

    if (this.jobName) { values.push(this.jobName); }
    if (this.driver) {
      let value = this.driver.name;
      if (this.truckName) { value = value + ' (' + this.truckName + ')'; }
      values.push(value);
    }

    return values.filter(Boolean).join(' - ');
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) { remove(options, _option); }
    }

    if (!this.void) {
      let _option = find(options, { action: 'unvoid' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }

  get weightRequired(): boolean {
    if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.allowWeight) {
      return true;
    }
    return false;
  }
 /*
  get mapImageURL(): string {
    let staticURL;
    if (this.routePolyline) {
      staticURL = 'https://maps.googleapis.com/maps/api/staticmap?size=60x45&path=color:0x002649|weight:1|enc:';
      staticURL += this.routePolyline;
      staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
    }

    return staticURL;
  }

  get fullsizeMapImageURL(): string {
    let staticURL;
    if (this.routePolyline) {
      staticURL = 'https://maps.googleapis.com/maps/api/staticmap?size=800x600&path=color:0x002649|weight:4|enc:';
      staticURL += this.routePolyline;
      staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
    }

    return staticURL;
  } */

  get pluralizedWeightUnit(): string {
    let plural = true;
    if (this.weight && parseFloat(this.weight) === 1.0) { plural = false; }
    switch (this.weightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.weightUnit + 's' : this.weightUnit;
    }
  }
}
