"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var surcharge_serializer_1 = require("./surcharge.serializer");
var surcharge_service_1 = require("./surcharge.service");
var surcharge_template_service_1 = require("../surcharge-templates/surcharge-template.service");
var api_service_1 = require("../shared/api.service");
var SurchargeDialogComponent = /** @class */ (function () {
    function SurchargeDialogComponent(dialogRef, surchargeService, surchargeTemplateService, elementRef) {
        this.dialogRef = dialogRef;
        this.surchargeService = surchargeService;
        this.surchargeTemplateService = surchargeTemplateService;
        this.elementRef = elementRef;
        this.positiveAmount = true;
        this.model = {
            id: null,
            title: null,
            description: null,
            time: null,
            amount: null,
            positive: false,
            invoice: null
        };
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
        this.surchargeDropdownOptions = [];
        this.surchargeDropdownConfig = {
            nameProperty: 'title',
            searchable: true,
            loadingOptions: false
        };
    }
    SurchargeDialogComponent.prototype.ngOnInit = function () {
        this.getSurchargeTemplates();
        this.model.invoice = this.invoice && this.invoice.id;
        if (this.model && this.model.amount >= 0) {
            this.positiveAmount = true;
        }
        else {
            this.positiveAmount = false;
        }
        if (this.model && this.model.time !== null) {
            var _time = void 0;
            if (typeof this.model.time === 'string') {
                _time = new Date(this.model.time);
            }
            else {
                _time = this.model.time;
            }
            this.surchargeDate = _time;
        }
    };
    SurchargeDialogComponent.prototype.ngOnDestroy = function () {
        if (this.surchargesReq) {
            this.surchargesReq.unsubscribe();
        }
    };
    SurchargeDialogComponent.prototype.submitSurcharge = function () {
        var _this = this;
        this.loading = true;
        var date;
        if (this.surchargeDate) {
            date = new Date(this.surchargeDate);
        }
        this.model.time = date && date.toISOString();
        this.surchargeService.save(this.model).subscribe(function (res) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    SurchargeDialogComponent.prototype.onDateChanged = function (values) {
        if (values && values.length) {
            this.surchargeDate = values[0];
        }
    };
    SurchargeDialogComponent.prototype.setAmountPositive = function (positive) {
        if (positive === void 0) { positive = true; }
        if (this.model.amount === null) {
            this.model.amount = '-0.00';
        }
        else {
            this.model.amount = positive
                ? Math.abs(this.model.amount)
                : -Math.abs(this.model.amount);
        }
        this.positiveAmount = positive;
    };
    SurchargeDialogComponent.prototype.amountChange = function () {
        if (!this.positiveAmount) {
            this.model.amount = -Math.abs(this.model.amount);
        }
    };
    SurchargeDialogComponent.prototype.getSurchargeTemplates = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.surchargeDropdownOptions = [{ id: null, title: 'Custom Surcharge', button: true }];
        if (this.surchargesReq) {
            this.surchargesReq.unsubscribe();
        }
        var kind = this.invoice ? 'invoice' : 'scaleticket';
        this.surchargesReq = this.surchargeTemplateService.list(__assign({ ordering: 'title', kind: kind }, query)).subscribe(function (surchargeTemplates) {
            _this.surchargeDropdownOptions = _this.surchargeDropdownOptions.concat(surchargeTemplates);
        }, function (err) {
            _this.errors = err;
        });
    };
    SurchargeDialogComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'surcharge':
                this.surchargeTemplate = e;
                if (this.surchargeTemplate && this.surchargeTemplate.id) {
                    var _surcharge = (new surcharge_serializer_1.SurchargeSerializer).fromJson(lodash_1.pick(this.surchargeTemplate, ['title', 'description', 'amount', 'positive']));
                    this.model = Object.assign(this.model, _surcharge);
                }
                break;
        }
    };
    SurchargeDialogComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'surcharge':
                this.getSurchargeTemplates({ search: term });
                break;
        }
    };
    SurchargeDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var service;
        var options;
        switch (type) {
            case 'surcharge':
                config = this.surchargeDropdownConfig;
                service = this.surchargeService;
                options = this.surchargeDropdownOptions;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'surcharge':
                            _this.surchargeDropdownOptions = _this.surchargeDropdownOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    return SurchargeDialogComponent;
}());
exports.SurchargeDialogComponent = SurchargeDialogComponent;
