"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var shared_1 = require("../shared");
var price_service_1 = require("./price.service");
var product_service_1 = require("../products/product.service");
var price_serializer_1 = require("./price.serializer");
var PriceDialogComponent = /** @class */ (function () {
    function PriceDialogComponent(dialogRef, dialog, priceService, productService) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.priceService = priceService;
        this.productService = productService;
        this.errors = [];
        this.loading = false;
        this.products = [];
        this.priceChangePrefix = '';
        this.priceChangeSuffix = '%';
        this.productsDropdownConfig = {
            searchable: true,
            loadingOptions: false
        };
        this.priceChangeTypesDropdownConfig = {
            searchable: false,
            loadingOptions: false
        };
    }
    PriceDialogComponent.prototype.ngOnInit = function () {
        this.getProducts();
        if (!this.price) {
            this.price = new price_serializer_1.PriceSerializer().fromJson({});
        }
        if (this.price.product && this.price.product.id) {
            this.selectedProduct = this.price.product;
        }
        if (this.price.priceChangeType && this.price.priceChangeType) {
            this.selectedPriceChangeType = this.price.priceChangeType;
        }
        if (this.price && this.price.priceChangeType === 'value') {
            this.priceChangePrefix = '$';
            this.priceChangeSuffix = '';
        }
        else if (this.price && this.price.priceChangeType === 'percent') {
            this.priceChangePrefix = '';
            this.priceChangeSuffix = '%';
        }
        else if (this.price && this.price.priceChangeType === 'new_price') {
            this.priceChangePrefix = '$';
            this.priceChangeSuffix = '';
        }
    };
    PriceDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.priceService.save(this.price).subscribe(function (price) {
            _this.loading = true;
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
            _this.loading = false;
        });
    };
    PriceDialogComponent.prototype.getProducts = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        this.productService.list(__assign({}, query)).subscribe(function (products) {
            if (append) {
                _this.products = _this.products.concat(products);
            }
            else {
                _this.products = products;
            }
        });
    };
    PriceDialogComponent.prototype.productSelected = function (product) {
        this.price.product = product;
        this.selectedProduct = product;
    };
    PriceDialogComponent.prototype.productNextPage = function () {
        var _this = this;
        this.productsDropdownConfig.loadingOptions = true;
        var next = this.productService.listNext();
        if (next) {
            next.subscribe(function (res) {
                _this.products.push(res);
                _this.productsDropdownConfig.loadingOptions = false;
            });
        }
        else {
            this.productsDropdownConfig.loadingOptions = false;
        }
    };
    PriceDialogComponent.prototype.productSearch = function (term) {
        this.getProducts({ search: term });
    };
    PriceDialogComponent.prototype.priceChangeTypeSelected = function (priceChangeType) {
        this.price.priceChangeType = priceChangeType;
        this.selectedPriceChangeType = priceChangeType;
        if (priceChangeType && priceChangeType.id === 'percent') {
            this.priceChangePrefix = '';
            this.priceChangeSuffix = '%';
        }
        else {
            this.priceChangePrefix = '$';
            this.priceChangeSuffix = '';
        }
    };
    return PriceDialogComponent;
}());
exports.PriceDialogComponent = PriceDialogComponent;
