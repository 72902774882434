"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var assignment_1 = require("./assignment");
var driver_serializer_1 = require("./../drivers/driver.serializer");
var organization_1 = require("../organizations/organization");
var trip_1 = require("../trips/trip");
var trip_stats_1 = require("../trips/trip-stats");
var punch_card_1 = require("../punch-cards/punch-card");
var truck_serializer_1 = require("../trucks/truck.serializer");
var job_event_1 = require("../job-events/job-event");
var job_serializer_1 = require("../jobs/job.serializer");
var location_serializer_1 = require("../locations/location.serializer");
var job_event_share_serializer_1 = require("../job-event-shares/job-event-share.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var AssignmentSerializer = /** @class */ (function () {
    function AssignmentSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Assignment
    */
    AssignmentSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var assignment = new assignment_1.Assignment();
        if (!json) {
            return assignment;
        }
        assignment.id = json.id;
        assignment.dispatched = json.dispatched;
        assignment.dispatchedTime = json.dispatchedTime;
        if (json.truck && typeof (json.truck) === 'object') {
            assignment.truck = (new truck_serializer_1.TruckSerializer().fromJson(json.truck));
        }
        else {
            assignment.truck = new truck_serializer_1.TruckSerializer().fromJson({ id: json.truck });
        }
        if (json.driver && typeof (json.driver) === 'object') {
            assignment.driver = new driver_serializer_1.DriverSerializer().fromJson(json.driver);
        }
        else {
            assignment.driver = new driver_serializer_1.DriverSerializer().fromJson({ id: json.driver });
        }
        if (json.jobevent && typeof (json.jobevent) === 'object') {
            assignment.jobevent = new job_event_1.JobEvent(json.jobevent);
        }
        else {
            assignment.jobevent = new job_event_1.JobEvent({ id: json.jobevent });
        }
        if (json.job && typeof (json.job) === 'object') {
            assignment.job = new job_serializer_1.JobSerializer().fromJson(json.job);
        }
        else {
            assignment.job = new job_serializer_1.JobSerializer().fromJson({ id: json.job });
        }
        if (json.jobeventshare && typeof (json.jobeventshare) === 'object') {
            assignment.jobeventshare = new job_event_share_serializer_1.JobEventShareSerializer().fromJson(json.jobeventshare);
        }
        else {
            assignment.jobeventshare = new job_event_share_serializer_1.JobEventShareSerializer().fromJson({ id: json.jobeventshare });
        }
        assignment.shift = json.shift;
        assignment.shifts = json.shifts;
        assignment.estimatedNumberOfLoads = json.estimatedNumberOfLoads;
        assignment.estimatedNumberOfTons = json.estimatedNumberOfTons;
        assignment.completed = json.completed;
        if (json.contactOrganization && typeof (json.contactOrganization) === 'object') {
            assignment.contactOrganization = new organization_1.Organization(json.contactOrganization);
        }
        else {
            assignment.contactOrganization = new organization_1.Organization({ id: json.contactOrganization });
        }
        if (json.trips) {
            assignment.trips = json.trips.map(function (trip) { return new trip_1.Trip(trip); });
        }
        else if (json.completedTrips) {
            assignment.trips = json.completedTrips.map(function (trip) { return new trip_1.Trip(trip); });
        }
        else {
            assignment.trips = [];
        }
        if (json.predictedTrips) {
            assignment.predictedTrips = json.predictedTrips.map(function (trip) { return new trip_1.Trip(trip); });
        }
        else {
            assignment.predictedTrips = [];
        }
        if (json.punchcards) {
            assignment.punchCards = json.punchcards.map(function (punchCard) { return new punch_card_1.PunchCard(punchCard); });
        }
        if (json.tripStats) {
            assignment.tripStats = new trip_stats_1.TripStats(json.tripStats);
        }
        assignment.driverConfirmed = json.driverConfirmed;
        assignment.driverRejectionNote = json.driverRejectionNote;
        if (json.uniqueStart) {
            assignment.uniqueStart = json.uniqueStart;
            assignment.uniqueStartDate = moment(assignment.uniqueStart).toDate();
            assignment.uniqueStartTime = moment(assignment.uniqueStart).format('h:mm A');
        }
        else if (assignment.jobevent) {
            assignment.uniqueStart = assignment.jobevent.shift1StartTimestamp;
            assignment.uniqueStartDate = moment(assignment.uniqueStart).toDate();
            assignment.uniqueStartTime = moment(assignment.uniqueStart).format('h:mm A');
        }
        if (json.kind === 'job') {
            assignment.numberOfLoadsType = 'allDay';
        }
        else if (json.kind === 'load') {
            if (json.maxNumberOfLoads === 0 || json.maxNumberOfLoads === null) {
                assignment.numberOfLoadsType = 'allDay';
            }
            else {
                assignment.numberOfLoadsType = 'numbered';
            }
        }
        else {
            assignment.numberOfLoadsType = json.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
        }
        assignment.maxNumberOfLoads = json.maxNumberOfLoads === null ? 0 : Number(json.maxNumberOfLoads);
        assignment.numberOfLoadsLeft = json.numberOfLoadsLeft;
        assignment.driverStatus = json.driverStatus;
        var values = ['Estimated:'];
        var estimates = [];
        if (json.estimatedNumberOfLoads !== undefined) {
            estimates.push(json.estimatedNumberOfLoads + ' loads');
        }
        if (json.estimatedNumberOfTons !== undefined) {
            estimates.push(json.estimatedNumberOfTons + ' tons');
        }
        if (estimates.length === 0) {
            estimates.push('0');
        }
        values.push(estimates.filter(function (v) {
            return (v !== '' && v !== null);
        }).join(' | '));
        assignment.assignedEstimate = values.filter(function (v) {
            return (v !== '' && v !== null);
        }).join(' ');
        assignment.confirmed = json.requiresConfirmation && json.driverConfirmed;
        assignment.unconfirmed = json.requiresConfirmation && !json.driverConfirmed;
        assignment.requiresConfirmation = json.driver && json.driver.requireAssignmentConfirmation;
        if (!json.driver || !json.driver.trip) {
            assignment.waiting = true;
        }
        else if (json.driver && json.driver.trip) {
            assignment.waiting = !json.driver.isActive(json.driver.trip, 'any') ? true : false;
        }
        else {
            assignment.waiting = true;
        }
        if (assignment.waiting) {
            assignment.tripStatus = 'Waiting';
        }
        else if (json.driver.isActive(json.driver.trip, 'en-route-one')) {
            assignment.tripStatus = 'Enroute to Loading';
        }
        else if (json.driver.isActive(json.driver.trip, 'loading')) {
            assignment.tripStatus = 'Loading';
        }
        else if (json.driver.isActive(json.driver.trip, 'en-route-two')) {
            assignment.tripStatus = 'Enroute to Unloading';
        }
        else if (json.driver.isActive(json.driver.trip, 'unloading')) {
            assignment.tripStatus = 'Unloading';
        }
        else {
            assignment.tripStatus = '';
        }
        if (assignment.waiting) {
            assignment.tripStatusTime = null;
        }
        else if (json.driver.isActive(json.driver.trip, 'en-route-one')) {
            assignment.tripStatusTime = json.driver.trip.loadingEnRouteTime;
        }
        else if (json.driver.isActive(json.driver.trip, 'loading')) {
            assignment.tripStatusTime = json.driver.trip.loadingTime;
        }
        else if (json.driver.isActive(json.driver.trip, 'en-route-two')) {
            assignment.tripStatusTime = json.driver.trip.unloadingEnRouteTime;
        }
        else if (json.driver.isActive(json.driver.trip, 'unloading')) {
            assignment.tripStatusTime = json.driver.trip.unloadingTime;
        }
        assignment.expandableTrips = assignment.trips.length > 0 || false;
        assignment.expandablePredictedTrips = assignment.predictedTrips.length > 0 || false;
        assignment.details = json.details;
        assignment.notes = json.notes;
        if (assignment.jobevent) {
            assignment.jobDisplayName = [
                assignment.jobevent.externalIdentifier,
                assignment.job.name
            ].filter(Boolean).join(': ');
        }
        else if (assignment.job) {
            assignment.jobDisplayName = assignment.job.name;
        }
        if (json.yardLocation && typeof (json.yardLocation) === 'object') {
            assignment.yardLocation = new location_serializer_1.LocationSerializer().fromJson(json.yardLocation);
        }
        else {
            assignment.yardLocation = new location_serializer_1.LocationSerializer().fromJson({ id: json.yardLocation });
        }
        assignment.yardStartTime = moment(assignment.yardStartTime).toDate();
        assignment.yardLeaveTime = moment(assignment.yardLeaveTime).toDate();
        assignment.yardPrepTime = json.yardPrepTime;
        assignment.yardBufferTime = json.yardBufferTime;
        if (json.yardBufferTime && parseFloat(json.yardBufferTime) > 0) {
            assignment.yardBufferMinutes = parseFloat(json.yardBufferTime) / 60;
        }
        assignment.carrierUniqueBillingId = json.carrierUniqueBillingId;
        assignment.pavers = json.pavers || [];
        assignment.connexCreatedAt = json.connexCreatedAt;
        assignment.connexLastSentAt = json.connexLastSentAt;
        return assignment;
    };
    /**
    * @param  {Assignment} assignment
    * @returns any
    */
    AssignmentSerializer.prototype.toJson = function (assignment) {
        var json = {
            id: assignment.id,
            truck: assignment.truck && assignment.truck.id,
            driver: assignment.driver && assignment.driver.id,
            contactOrganization: assignment.contactOrganization && assignment.contactOrganization.id,
            jobevent: assignment.jobevent && assignment.jobevent.id,
            shift: assignment.shift,
            shifts: assignment.shifts,
            dispatched: assignment.dispatched,
            dispatchedTime: assignment.dispatchedTime,
            estimatedNumberOfLoads: assignment.estimatedNumberOfLoads,
            estimatedNumberOfTons: assignment.estimatedNumberOfTons,
            durationTimer: assignment.durationTimer,
            driverConfirmed: assignment.driverConfirmed,
            driverRejectionNote: assignment.driverRejectionNote,
            maxNumberOfLoads: assignment.maxNumberOfLoads,
            numberOfLoadsLeft: assignment.numberOfLoadsLeft,
            completed: assignment.completed,
            driverStatus: assignment.driverStatus,
            notes: assignment.notes,
            uniqueStart: assignment.uniqueStart && moment(assignment.uniqueStart).toISOString(),
            yardBufferTime: (assignment.yardBufferMinutes || 0) * 60
        };
        return decamelizeKeysDeep(json);
    };
    return AssignmentSerializer;
}());
exports.AssignmentSerializer = AssignmentSerializer;
