import { Preference } from './preference';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PreferenceSerializer {
  fromJson(json: any): Preference {
    const rawJson = json;
    json = camelcaseKeysDeep(json);

    const preference = new Preference();

    if (!json) { return preference; }

    preference.id = json.id;
    preference.organization = json.organization;
    preference.profile = json.profile;
    preference.name = json.name;
    preference.type = json.type;
    if (json.blob.filters && json.blob.filters[0] && json.blob.filters[0].query) {
      preference.blob = Object.assign(json.blob, {
        filters: json.blob.filters.map(filter => (Object.assign(filter, {
          query: rawJson.blob.filters.find(f => (f.key === filter.key)).query
        })))
      });
    } else {
      preference.blob = json.blob;
    }
    if (preference.blob.checkinOptions) {
      this.updateOptions(preference, 'checkin');
    }
    if (preference.blob.checkoutOptions) {
      this.updateOptions(preference, 'checkout');
    }
    return preference;
  }

  toJson(preference: Preference): any {
    const json: { [key: string]: any } = {
      id: preference.id,
      organization: preference.organization,
      profile: preference.profile,
      name: preference.name,
      type: preference.type,
      blob: preference.blob
    };

    Object.keys(json).forEach(k => {
      if (json[k] === undefined) {
        delete json[k];
      }
    });

    return decamelizeKeysDeep(json);
  }

  updateOptions(preference: Preference, option: 'checkin' | 'checkout') {
      if (preference.blob[option + 'Options']['activeTrackingConfig'] === undefined) {
        preference.blob[option + 'Options']['activeTrackingConfig'] = preference.blob[option + 'Options'].activeTracking === true ? 'required' : 'optional';
      }
      if (preference.blob[option + 'Options']['loadImageConfig'] === undefined) {
        preference.blob[option + 'Options']['loadImageConfig'] = preference.blob[option + 'Options'].loadImage === true ? 'required' : 'optional';
      }
      if (preference.blob[option + 'Options']['qrConfig'] === undefined) {
        preference.blob[option + 'Options']['qrConfig'] = preference.blob[option + 'Options'].qr === true ? 'required' : 'optional';
      }
      if (preference.blob[option + 'Options']['signatureImageConfig'] === undefined) {
        preference.blob[option + 'Options']['signatureImageConfig'] = preference.blob[option + 'Options'].signatureImage === true ? 'required' : 'optional';
      }
      if (preference.blob[option + 'Options']['ticketImageConfig'] === undefined) {
        preference.blob[option + 'Options']['ticketImageConfig'] = preference.blob[option + 'Options'].ticketImage === true ? 'required' : 'optional';
      }
      if (preference.blob[option + 'Options']['ticketNumberConfig'] === undefined) {
        preference.blob[option + 'Options']['ticketNumberConfig'] = preference.blob[option + 'Options'].ticketNumber === true ? 'required' : 'optional';
      }
      if (preference.blob[option + 'Options']['weightConfig'] === undefined) {
        preference.blob[option + 'Options']['weightConfig'] = preference.blob[option + 'Options'].weight === true ? 'required' : 'optional';
    }
  }
}
