import { DriverService } from '../../drivers/driver.service';
import { JobService } from '../../jobs/job.service';
import { JobEventService } from '../../job-events/job-event.service';
import { TruckService } from '../../trucks/truck.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from '../../organizations/organization.service';

export const JOB_DROPDOWN_CONFIG = (translateService: TranslateService) => ({
  service: JobService,
  selectText: translateService.instant('Select Job'),
  loadingText: translateService.instant('Loading Jobs...'),
  noResultsText: translateService.instant('No Jobs'),
  searchable: true,
  group: true,
  groupProperty: 'group',
  sortBy: 'project__name,name,start_date',
  includeFullObject: true,
  query: {
    serializer: 'JobGroupedDropdown',
  },
});

export const JOB_EVENTS_DROPDOWN_CONFIG = (
  translateService: TranslateService
) => ({
  searchable: true,
  showLoading: true,
  nameProperty: 'name',
  sortBy: 'shift1_start',
  selectText: translateService.instant('Select Job'),
  loadingText: translateService.instant('Loading Jobs...'),
  noResultsText: translateService.instant('No Jobs'),
  includeFullObject: true,
  service: JobEventService,
  query: {},
});

export const CARRIER_DROPDOWN_CONFIG = (
  translateService: TranslateService
) => ({
  nameProperty: 'name',
  idProperty: 'carrier.id',
  selectText: translateService.instant('Select Carrier'),
  loadingText: translateService.instant('Loading Carriers...'),
  noResultsText: translateService.instant('No Carriers'),
  service: OrganizationService,
  serviceFunction: 'get',
  query: {
    exclude: null,
  },
  includeFullObject: true,
  prefilledOptions: [],
});

export const DRIVER_DROPDOWN_CONFIG = (translateService: TranslateService) => ({
  service: DriverService,
  selectText: translateService.instant('Select Driver'),
  loadingText: translateService.instant('Loading Drivers...'),
  noResultsText: translateService.instant('No Drivers'),
  searchable: true,
  sortBy: 'profile__first_name,profile__last_name',
  includeFullObject: true,
});

export const TRUCK_DROPDOWN_CONFIG = (translateService: TranslateService) => ({
  service: TruckService,
  selectText: translateService.instant('Select Truck'),
  loadingText: translateService.instant('Loading Trucks...'),
  noResultsText: translateService.instant('No Trucks'),
  searchable: true,
  nameProperty: 'displayName',
  sortBy: 'name',
  includeFullObject: true,
});
