<div class="edit-drawer" #editTruck [ngClass]="{
  'mobile': device && device.mobile,
  'tablet': device && device.tablet,
  'desktop': device && device.desktop || !device,
  'open': open
}">
  <div *ngIf="errors && errors.length" class="errors">
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <form #truckForm="ngForm" *ngIf="modifiedTruck && truck && (truck.id === modifiedTruck.id)" (keydown.enter)="$event.preventDefault()">
    <div class="form-content">
      <div class="field-group organization full-width">
        <label translate>Fleet</label>
        <input type="hidden" name="carrier" [(ngModel)]="modifiedTruck.carrierOrganizationId" />
        <ruckit-dropdown #carriersDropdown
          [config]="carriersConfig"
          [selectedOption]="modifiedTruck.carrierOrganizationId"
          (onSelect)="optionSelected($event, 'carrier')">
        </ruckit-dropdown>
      </div>

      <div class="field-group truck-type full-width">
        <label class="required" translate>Truck Type</label>
        <input type="hidden" name="truckType" required [(ngModel)]="modifiedTruck.truckType" #truckType="ngModel" />
        <ruckit-dropdown #truckTypesDropdown
          [config]="truckTypesConfig"
          [selectedOption]="modifiedTruck.truckType"
          (onSelect)="optionSelected($event, 'truckType')">
        </ruckit-dropdown>
      </div>

      <div class="field-group full-width">
        <label translate>Condition</label>
        <input type="hidden" name="serviceStatus" [(ngModel)]="modifiedTruck.serviceStatus" />
        <dropdown #serviceStatusDropdown
          title="Select Condition"
          [config]="serviceStatusConfig"
          [options]="serviceStatusOptions"
          [selectedOption]="modifiedTruck.displayServiceStatus"
          (onSelect)="optionSelected($event.id, 'serviceStatus')">
        </dropdown>
      </div>

      <div class="field-group full-width">
        <label>Markets</label>
        <input type="hidden" name="tags_input" [(ngModel)]="modifiedTruck.tags" #tags="ngModel" />
        <ruckit-dropdown #marketsDropdown
          [config]="marketsConfig"
          [selectedItems]="modifiedTruck.tags"
          (onSelect)="optionSelected($event, 'tags')">
        </ruckit-dropdown>
      </div>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group truck-number" fxFlex="50">
          <label class="required" translate>Truck #</label>
          <input type="text" name="truck-number" placeholder="{{ 'Truck Number' | translate }}" required [(ngModel)]="modifiedTruck.name" #name="ngModel" />
          <span [ngClass]="{'ng-valid': name.valid, 'ng-invalid': name.invalid, 'ng-pristine': name.pristine}"></span>
        </div>

        <div class="field-group license-plate" fxFlex="50">
          <label class="required" translate>License Plate</label>
          <input type="text" name="license-plate" placeholder="{{ 'License Plate' | translate }}" required [(ngModel)]="modifiedTruck.licensePlate" #licensePlate="ngModel" />
          <span [ngClass]="{'ng-valid': licensePlate.valid, 'ng-invalid': licensePlate.invalid, 'ng-pristine': licensePlate.pristine}"></span>
        </div>
      </div>

      <div class="field-group yard-location full-width">
        <label translate>Yard Location</label>
        <input type="hidden" name="yardLocation" [(ngModel)]="modifiedTruck.yardLocation" #truckType="ngModel" />
        <ruckit-dropdown #locationsDropdown
          [config]="locationsConfig"
          [selectedOption]="modifiedTruck.yardLocation && modifiedTruck.yardLocation.id"
          (onSelect)="optionSelected($event, 'location')">
        </ruckit-dropdown>
      </div>

      <ng-container *ngIf="modifiedTruck.customFieldData">
        <div class="field-group">
          <div class="fields custom-fields">
            <ng-container *ngFor="let field of customFields | keyvalue">
              <label>{{ field.value.displayName }}</label>
              <input name="{{ field.key }}" type="text" placeholder="Value" [(ngModel)]="modifiedTruck.customFieldData[field.key]">
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="sidenav-footer">
      <div class="actions" fxLayout="row" fxLayoutAlign="space-between stretch">
        <button fxFlex="50" class="btn btn-inverted-delete" (click)="remove(); false" [ngClass]="{loading: loading && modifiedTruck.status !== 'removed'}">
          <i class="icomoon icon-delete"></i><ng-container translate>Delete Truck</ng-container>
        </button>
        <button fxFlex="50" class="btn btn-primary" (click)="truckForm.form.valid && submit(); false" [disabled]="!truckForm.form.valid || truckForm.form.pristine || loading" [ngClass]="{loading: loading}" translate>
          Save Changes
        </button>
      </div>
    </div>
  </form>
</div>
