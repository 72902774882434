<form #inviteConnection="ngForm" class="{{ model.search ? 'searching' : '' }}">
  <div mat-dialog-title class="modal-header">
    <h2 *ngIf="activeView === 'invite'" translate>Invite a New Connection</h2>
    <h2 *ngIf="activeView === 'search'" translate>Connect to a Ruckit Organization</h2>
    <i class="icon-close" (click)="dialogRef.close()"></i>
  </div>
  <ng-container *ngIf="!submitted">

    <ng-container *ngIf="activeView === 'search'">
      <div class="dialog-section top organization-list" [ngClass]="{'searching': searching}">
        <mat-dialog-content>
          <h2 *ngIf="!searching" translate>Search for an Organization</h2>
          <p *ngIf="!searching" translate>Connect with an existing Ruckit organization to share, manage, and track jobs.</p>
          <div class="custom-search">
            <i class="search-icon"></i>
            <input class="search" name="search"
                  [(ngModel)]="model.search" [cdAutocomplete]="inputAutocomplete"
                  (cdAutocompleteUpdate)="updateInputAutocomplete($event)"
                  (cdSelectedValueChange)="updateSelectedValue($event)"
                  type="text" autocomplete="off" placeholder="{{ 'Search' | translate }}" />
            <my-loader *ngIf="loading && model.search"></my-loader>
          </div>
        </mat-dialog-content>
      </div>

      <div class="dialog-section bottom" *ngIf="!searching">
        <mat-dialog-content>
          <h2 translate>Want to invite someone new to Ruckit?</h2>
          <p translate>Ruckit makes it easy to invite someone outside of your company to join and use Ruckit with you.</p>
        </mat-dialog-content>
        <mat-dialog-actions align="left">
          <button translate class="btn btn-default" (click)="openNewInvite()">Send Invite</button>
        </mat-dialog-actions>
      </div>

      <div class="dialog-section bottom searching" *ngIf="searching">
        <mat-dialog-actions align="center">
          <button translate class="btn btn-default" (click)="resetSearch()">Go Back</button>
          <div class="button-spacer"></div>
          <button translate class="btn btn-primary submit" [disabled]="!recipientOrganizationId" (click)="onSubmit('search')">Send Invite</button>
        </mat-dialog-actions>
      </div>
    </ng-container>
    
    <ng-container *ngIf="activeView === 'invite'">
      <div class="dialog-section top create-invite">
        <mat-dialog-content>
          <h2 translate>Send an Invite</h2>
          <p translate>Your contact will receive an email from us on behalf of you with instructions on how to create an account and setup Ruckit to work with you.</p>
        </mat-dialog-content>
        <mat-dialog-actions align="left">
          <div class="invite-input">
            <label translate>Contact</label>
            <input required name="contact" type="text" placeholder="{{ 'First and Last Name' | translate }}" [(ngModel)]="invite.contact">
          </div>
          <div class="invite-input">
            <label translate>Email</label>
            <input required name="email" email type="email" placeholder="{{ 'Email Address' | translate }}" [(ngModel)]="invite.email">
          </div>
          <div class="invite-input">
            <label translate>Business (Optional)</label>
            <dropdown [options]="['Broker','Carrier','Carrier & Broker','Not Sure']"
                      title="{{ 'Select' | translate }}"
                      (onSelect)="invite.business = $event"></dropdown>
          </div>
          <button translate [disabled]="!inviteConnection.form.valid || activeView === 'search'" class="btn btn-primary" (click)="inviteConnection.form.valid && onSubmit('invite')">Send Invite</button>
        </mat-dialog-actions>
      </div>
      <div class="dialog-section bottom">
          <mat-dialog-content>
            <h2 translate>Know Someone Using Ruckit?</h2>
            <p translate>Search for an existing Ruckit organization to send and invite and connect.</p>
          </mat-dialog-content>
          <mat-dialog-actions align="left">
            <button translate class="btn btn-default" (click)="openSearch()">Search</button>
          </mat-dialog-actions>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="submitted">
    <div class="submit-message">
      <mat-dialog-content>
        <h1 translate>Your Invite was Sent!</h1>
        <p translate>Once your contact has set up an account on Ruckit you’ll be able to collaborate.</p>
      </mat-dialog-content>
    </div>
  </ng-container>
</form>
