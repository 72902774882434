"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var CondensedTicket = /** @class */ (function () {
    function CondensedTicket() {
        this.selected = false;
        this.surcharges = [];
    }
    CondensedTicket.prototype.url = function (action) {
        return '/scale/tickets/' + this.id + '/' + action;
    };
    CondensedTicket.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return CondensedTicket;
}());
exports.CondensedTicket = CondensedTicket;
