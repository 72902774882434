import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { TripsRoutingModule } from './trips-routing.module';
import { NewTripComponent } from './new-trip/new-trip.component';
import { EditTripComponent } from './edit-trip/edit-trip.component';
import { CondensedTripsComponent } from './condensed-trips/condensed-trips.component';

@NgModule({
  imports: [CommonModule, SharedModule, TripsRoutingModule],
  declarations: [CondensedTripsComponent, NewTripComponent, EditTripComponent],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class TripsModule {
  constructor(translateService: TranslateService) {
    let browserLang = translateService.getBrowserLang();
    translateService.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');
  }
}
