"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var CyberTripFilter = /** @class */ (function () {
    function CyberTripFilter(tripFilterInfo) {
        tripFilterInfo = camelcaseKeysDeep(tripFilterInfo);
        this.id = tripFilterInfo.id;
        this.name = tripFilterInfo.name;
    }
    return CyberTripFilter;
}());
exports.CyberTripFilter = CyberTripFilter;
