import {
  Component, Input, Output, EventEmitter, HostListener, ElementRef, OnDestroy
} from '@angular/core';
import { timer as observableTimer, Subscription, Observable, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { clone, includes, sortBy } from 'lodash';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialogRef, MatDialog } from '@angular/material';

import { Preference } from '../preferences/preference';
import { PreferenceService } from '../preferences/preference.service';
import { AssignmentService } from './assignment.service';
import { DriverService } from '../drivers/driver.service';
import { AuthenticationService } from '../shared';
import { RuckitConfirmDialogComponent } from '../shared/dialogs';
import { parseErrors } from '../shared/api.service';
import { Assignment } from './assignment';
import { JobEvent } from '../job-events/job-event';
import { TruckService } from '../trucks/truck.service';
import { Truck } from '../trucks/truck';

@Component({
  selector: 'ruckit-driver-assignments-list',
  templateUrl: './driver-assignments-list.component.html',
  styleUrls: ['./driver-assignments-list.component.scss'],
  animations: [
    trigger('expandRow', [
      state('collapsed', style({ height: '*', visibility: 'visible' })),
      state('expanded', style({ 'min-height': '80px', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class DriverAssignmentsListComponent implements OnDestroy {
  open = false;
  loading = false;
  edit = false;
  preference: Preference;
  preferenceKey = 'DriverAssignmentsListComponent-AssignmentService';
  dispatchPreferences = {
    type: ''
  };
  hasCopyAssignment = false;
  hasAllDriversEnabled = false;
  allAssignmentsSelected = false;
  errors = [];
  driversReq: Subscription;
  assignmentsList = [];
  driversList = [];
  selectedAssignments = [];
  selectedDrivers = [];
  assignmentIds = [];
  driverIds = [];
  shiftIndex;
  confirmDialog: MatDialogRef<any>;
  driverDropdownConfig = {
    nameProperty: 'name',
    searchable: false,
    group: false,
    loadingOptions: false,
    multiselect: true
  };
  trucksDropdownConfig = {
    small: true,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'ticketName',
    service: TruckService,
    query: { ordering: 'name', carrier: null }
  };
  jobEventDropdownConfig = {
    nameProperty: 'jobDisplayName'
  };
  @Input() date;
  @Input() availableJobEvents: JobEvent[] = [];
  @Input() dispatch = false;
  @Output() assignmentsDeleted: EventEmitter<Assignment[]> = new EventEmitter();
  @Output() assignmentsReload = new EventEmitter();
  @Output() updateAssignmentData: EventEmitter<Assignment[]> = new EventEmitter();

  displayDate;
  driver;
  private assignmentsTimer;
  private assignmentsTimerSub;
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (
      !this._eref.nativeElement.contains(event.target) &&
      !includes(event.target.classList, 'assignment-count') &&
      !includes(event.target.classList, 'assignment-number') &&
      !includes(event.target.classList, 'edit-button') &&
      !includes(event.target.classList, 'assignment-label') &&
      !includes(event.target.classList, 'btn-warning') &&
      !includes(event.target.classList, 'btn-cancel') &&
      !includes(event.target.classList, 'assignment-menu')
    ) {
      this.setClosed();
    }
  }

  constructor(
    private preferenceService: PreferenceService,
    private assignmentService: AssignmentService,
    private driverService: DriverService,
    private authenticationService: AuthenticationService,
    private _eref: ElementRef,
    public dialog: MatDialog
  ) {
    if (!this.dispatch) { this.getDriversList(); }
    const organization = this.authenticationService.getOrganization();
    this.hasCopyAssignment = organization && organization.hasCopyAssignment;
    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
  }

  ngAfterViewInit() {
    this.getPreferences();
    // if (this.driver) { this.trucksDropdownConfig.query = { ordering: 'name', carrier: this.driver.carrier.id }; }
  }

  ngOnDestroy() {
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
  }

  getPreferences(): void {
    const user = this.authenticationService.user();
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        preferences.forEach(preference => {
          if (preference.blob.dispatchPreference) {
            this.preference = preference;
          }
        });
      }
    });
  }

  getAssignmentDetails(): void {
    this.loading = true;
    let requests: Observable<Assignment>[] = [];
    this.assignmentsList = this.driver.assignments;
    this.driver.assignments.forEach(assignment => {
      assignment['notesOpen'] = false;
      if (!assignment['deleted']) {
        let request = this.assignmentService.get(assignment.id);
        requests.push(request);
      }
    });

    forkJoin(requests).subscribe(assignments => this.setupAssignments(assignments), error => {
      this.errors = parseErrors(error);
    }, () => this.loading = false);
  }

  setupAssignments(assignments: Assignment[]) {
    if (this.driver) {
      assignments.forEach(a => {
        let assignment = <Assignment>this.driver.assignments.find(record => (record.id === a.id));
        a['deleted'] = false;
        a['startTime'] = a.uniqueStart ?
          moment(a.uniqueStart).format('h:mm a') :
          moment(a.jobevent[a.shift + 'StartTimestamp']).format('h:mm a');
        a['endTime'] = moment(a.jobevent[a.shift + 'EndTimestamp']).format('h:mm a');
        assignment['details'] = a;

        if (assignment.completed === undefined) {
          assignment['completed'] = a['completed'];
          assignment['driverStatus'] = a['driverStatus'];
          assignment['maxNumberOfLoads'] = a['maxNumberOfLoads'];
        }
        if (!assignment['numberOfLoadsType']) {
          assignment['numberOfLoadsType'] = assignment.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
        }
        assignment['job'] = a['job'];
        assignment['jobevent'] = a['jobevent'];
        // if (!this.preference || !this.preference.hasOwnProperty('name') || this.preference.name === 'all-day') {
        //   assignment['maxNumberOfLoads'] = 0;
        // } else if (this.preference.name === 'by-load') {
        //   assignment['maxNumberOfLoads'] = 1;
        // }
        assignment.uniqueStartDate = moment(a.uniqueStart).toDate();
        this.shiftIndex = assignment.details.shift ? parseInt(assignment.details.shift.replace(/[^\d]/g, ''), 10) - 1 : 0;
      });
      this.driver.assignments = sortBy(this.driver.assignments, 'uniqueStartDate');
    }
    this.loading = false;
  }

  editAssignments(event) {
    event.preventDefault();
    if (!this.allAssignmentsSelected) { this.selectAllAssignments(true); }
    this.edit = true;
  }

  changeAssignmentJobEvent(assignment, jobEvent) {
    assignment.details.jobevent = jobEvent;
  }

  // Update selected and modified assignments when Save button is clicked
  updateAssignments() {
    if (this.selectAssignments) {
      this.selectedAssignments.forEach(assignment => {
        assignment.updating = true;
        let _assignment = clone(assignment);
        const dateString = this.date.month + '/' + this.date.day + '/' + this.date.year;
        const timeString = _assignment.details && _assignment.details.startTime;
        const formatString = 'MM/DD/YYYY h:mm A';
        const parsedDate = moment(dateString + ' ' + timeString, [formatString]).format();
        if (timeString && parsedDate) { _assignment['uniqueStart'] = parsedDate; }
        if (!_assignment['maxNumberOfLoads']) {
          _assignment['maxNumberOfLoads'] = assignment.numberOfLoadsType === 'allDay' ? 0 : _assignment['maxNumberOfLoads'] || 1;
        }
        if (assignment.details && assignment.details.jobevent.id !== assignment.jobevent.id) {
          this.assignmentService.remove(assignment.id).subscribe(() => {
            this.assignmentService.save({
              driver: _assignment.driver,
              truck: _assignment.truck,
              jobevent: _assignment.details.jobevent,
              uniqueStart: _assignment.details.uniqueStart,
              shift: 'shift1',
              maxNumberOfLoads: assignment.details.maxNumberOfLoads
            }, { can_dispatch: 'True' }).subscribe(newAssignment => {
              // DEV NOTE: we need the updated jobEvent data in order to properly apply the new assignment data
              this.assignmentService.get(newAssignment.id).subscribe(assignmentUpdates => {
                this.driver.assignments[this.driver.assignments.findIndex(a => a.id === assignment.id)] = assignmentUpdates;
                this.setupAssignments(this.driver.assignments);
              });
            }, err => {
              this.errors = parseErrors(err);
              assignment.updating = false;
            });
          }, err => {
            this.errors = parseErrors(err);
            assignment.updating = false;
          });
        } else {
          this.assignmentService.save(_assignment, { can_dispatch: 'True' }).subscribe(() => {
            this.applyAssignmentChanges(assignment, _assignment);
          }, err => {
            this.errors = parseErrors(err);
            assignment.updating = false;
          });
        }
      });
    } else {
      this.allAssignmentsSelected = false;
      this.selectedAssignments = [];
    }
  }

  applyAssignmentChanges(assignment, assignmentUpdates) {
    assignment.checked = false;
    assignment.updating = false;
    this.allAssignmentsSelected = false;
    this.selectAllAssignments(false);
    this.edit = false;
    let driverAssignment = <Assignment>this.driver.assignments.find(record => (
      record.id === assignmentUpdates.id || record.details.id === assignmentUpdates.id
    ));
    driverAssignment.uniqueStartDate = moment(assignmentUpdates['uniqueStart']).toDate();
    this.driver.assignments = sortBy(this.driver.assignments, 'uniqueStartDate');
    if (this.dispatch) {
      this.updateAssignmentData.emit(this.driver.assignments);
      this.getAssignmentDetails();
    }
  }

  // Delete selected assignments after confirmation
  deleteAssignments(assignmentsList) {
    if (this.allAssignmentsSelected || this.selectedAssignments.length) {
      this.loading = true;
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '430px',
        height: '250px'
      });
      this.confirmDialog.componentInstance.attributes = {
        title: 'Delete Assignments?',
        body: `${assignmentsList.length} assignment/s will be deleted and cannot be recovered.`,
        close: 'Cancel',
        accept: 'Delete'
      };

      this.confirmDialog.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          this.loading = true;
          assignmentsList.forEach((assignment) => {
            this.removeAssignment(assignment.id);
          });
          this.loading = false;
          // this.setClosed();
          this.allAssignmentsSelected = false;
          this.selectedAssignments = [];
        }
        this.confirmDialog = null;
      });
    }
  }

  setDefaultLoadCount(assignment, type = 'allDay'): void {
    if (type === 'numbered' && (assignment.maxNumberOfLoads === 0 || assignment.maxNumberOfLoads === null)) {
      assignment.maxNumberOfLoads = 1;
    } else if (type === 'allDay') {
      assignment.maxNumberOfLoads = 0;
    }
  }

  // Get list of drivers to copy assignments
  getDriversList(): void {
    this.driversList = [];
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }

    this.driversReq = this.driverService.list().subscribe(drivers => {
      this.driversList = drivers.filter(driver => driver.truck);
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  removeAssignment(id) {
    this.loading = true;
    const matchedAssignmentIndex = this.driver.assignments.findIndex(a => (a.id === id));
    if (matchedAssignmentIndex > -1) {
      this.assignmentService.remove(id).subscribe(res => {
        if (this.driver.assignments[matchedAssignmentIndex]) {
          this.driver.assignments[matchedAssignmentIndex]['deleted'] = true;
          if (this.dispatch) { this.assignmentsDeleted.emit([this.driver.assignments[matchedAssignmentIndex]]); }
        }
        this.loading = false;
      }, error => {
        this.errors = parseErrors(error);
        this.loading = false;
      });
    }
  }

  public setClosed() {
    this.open = false;
    this.driver = null;
    this.errors = []
    if (this.assignmentsTimerSub) {
      try {
        this.assignmentsTimerSub.unsubscribe();
        this.assignmentsTimer = null;
      } catch (e) {
        this.assignmentsTimerSub = null;
        this.assignmentsTimer = null;
      }
    }
  }

  public setOpen(driverData) {
    this.driver = driverData;
    this.open = true;
    if (this.date && this.date.year) {
      this.displayDate = moment([this.date.year, this.date.month - 1, this.date.day]).format('MMM D, YYYY');
    } else {
      this.displayDate = moment(this.date).format('MMM D, YYYY');
    }
    this.assignmentsTimer = observableTimer(1, 30000);
    if (this.assignmentsTimerSub && typeof this.assignmentsTimerSub.unsubscribe === 'function') {
      this.assignmentsTimerSub.unsubscribe();
    }
    this.assignmentsTimerSub = this.assignmentsTimer.subscribe(t => {
      this.getAssignmentDetails();
    });
  }

  // Select all assignments when 'Select all assignments' is checked
  // Create an array of assignment Ids for copy-assignment API
  selectAllAssignments(event) {
    let checked = false;
    checked = (event && event.target) ? event.target.checked : event;
    this.allAssignmentsSelected = checked;
    this.assignmentsList.forEach(assignment => {
      if (this.allAssignmentsSelected) {
        assignment.checked = checked;
        this.edit = true;
        this.selectedAssignments = [...this.assignmentsList];
        this.assignmentIds = this.selectedAssignments.map(allAssignments => allAssignments.id);
      } else {
        assignment.checked = checked;
        this.edit = false;
        this.selectedAssignments = [];
      }
    });
  }

  // Select assignment/s when particular assignment is checked. 'Select all assignments' should be unchecked
  // Create an array of assignment Ids for copy-assignment API
  selectAssignments() {
    this.allAssignmentsSelected = false;
    this.edit = true;
    this.selectedAssignments = this.assignmentsList.filter(assignment => assignment.checked);
    this.assignmentIds = this.selectedAssignments.map(assignment => assignment.id);
  }

  // Pass the assignmentIds and driverIds to the copy-assignment API
  copyAssignments(event) {
    this.loading = true;
    this.selectedDrivers = [];
    this.assignmentService.copyAssignment({
      assignments: this.assignmentIds,
      drivers: this.driverIds
    }).subscribe(res => {
      this.loading = false;
      this.assignmentsReload.emit(true);
      this.setClosed();
      this.allAssignmentsSelected = false;
      this.selectedAssignments = [];
      this.selectedDrivers = [];
      this.getDriversList();
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  // Select drivers from dropdown and create array of driver ids.
  setSelectedDriver(option): void {
    if (option) { this.selectedDrivers = option; }
    this.driverIds = this.selectedDrivers.map(driver => driver.id);
  }

  changeTruck(truck: Truck) {
    if (
      (this.dispatch && (!this.driver.truck || (this.driver.truck.id !== truck.id))) ||
      (!this.dispatch && (!this.driver.latestTruck || (this.driver.latestTruck.id !== truck.id)))
    ) {
      if (this.dispatch) { this.driver.truck = truck; } else { this.driver.latestTruck = truck; }
      if (this.driver.assignments) {
        this.driver.assignments = this.driver.assignments.map(a => (Object.assign(a, { truck: truck })));
        this.driverService.save({ id: this.driver.id, truck: truck }).subscribe(res => {
          this.assignmentService.bulkUpdate(this.driver.assignments).subscribe(bulkRes => {
            if (bulkRes.errors) { this.errors = bulkRes.errors.map(e => (e.error)); }
          });
        }, err => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
    }
  }
}
