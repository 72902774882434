"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var DispatchScheduleCollaboratorGridComponent = /** @class */ (function () {
    function DispatchScheduleCollaboratorGridComponent() {
        this.loadingProgress = 0;
        this.displayKeys = ['shareStats'];
        this.connectionCount = { available: 0, assigned: 0 };
        this.selectedConnectionsValue = [];
        this.selectedConnectionsChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DispatchScheduleCollaboratorGridComponent.prototype, "selectedConnections", {
        get: function () { return this.selectedConnectionsValue; },
        set: function (data) {
            this.selectedConnectionsValue = data;
            this.selectedConnectionsChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleCollaboratorGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes.connections && changes.connections.currentValue) {
            this.setupItemList(changes.connections.currentValue);
        }
    };
    /**
     * Uses a groupBy to generate a list of groups for the input connection list
     *
     * @param {DispatchConnection[]} connections The input connection list
     */
    DispatchScheduleCollaboratorGridComponent.prototype.setupItemList = function (connections) {
        this.connectionCount = {
            available: connections.length,
            assigned: connections.filter(function (connection) { return (connection.shares.length > 0); }).length
        };
        this.connectionList = [{
                name: 'Collaborators',
                groupBy: 'default',
                items: []
            }].map(function (group) { return (Object.assign(group, {
            items: connections.map(function (c) { return (Object.assign(c, {
                shareStats: c.name + ' - ' + c.shares.length
            })); })
        })); });
        this.connectionList = this.connectionList.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
        this.filteredConnectionList = lodash_1.cloneDeep(this.connectionList);
    };
    /**
     * Creates a list of class names to append to each of the item elements based on specified collaborator states
     *
     * @param {DispatchConnection} connection The input connection
     * @return {string} The list of class names combined in a single string
     */
    DispatchScheduleCollaboratorGridComponent.prototype.generateItemClassNames = function (connection) {
        var classNames = '';
        // if (collaborator.dutyStatus === 'off-duty') {
        //   classNames += 'red ';
        // } else
        if (connection.shares.length) {
            classNames += 'blue ';
        }
        return classNames;
    };
    return DispatchScheduleCollaboratorGridComponent;
}());
exports.DispatchScheduleCollaboratorGridComponent = DispatchScheduleCollaboratorGridComponent;
