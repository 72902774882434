import { Organization } from '../organizations/organization';
import { PriceList } from './price-list';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class PriceListSerializer {
  fromJson(json: any): PriceList {
    json = camelcaseKeysDeep(json);
    const priceList = new PriceList();
    if (!json) { return priceList; }

    priceList.id = json.id;
    priceList.name = json.name;
    priceList.createdAt = json.createdAt;
    priceList.lastModified = json.lastModified;
    if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
      priceList.ownerOrganization = new Organization(json.ownerOrganization);
    } else if (json.ownerOrganization) {
      priceList.ownerOrganization = new Organization({ id: json.ownerOrganization });
    }
    priceList.notes = json.notes;
    priceList.status = json.status;
    if (json.prices && typeof (json.prices) === 'object') {
      priceList.prices = json.prices;
      // TO BE CHECKED
      // priceList.prices = (new PriceSerializer()).fromJson(json.prices);
    }
    // if (json.prices) {
    //   priceList.prices = json.prices.map(price => {
    //     if (price && typeof price === 'object') {
    //       return new Price(price);
    //     } else {
    //       return new Price({ id: price });
    //     }
    //   });
    // } else {
    //   priceList.prices = [];
    // }

    return priceList;
  }

  toJson(priceList: PriceList): any {
    let json = {
      id: priceList.id,
      name: priceList.name,
      ownerOrganization: priceList.ownerOrganization && priceList.ownerOrganization.id,
      notes: priceList.notes,
      status: priceList.status
    };

    return decamelizeKeysDeep(json);
  }
}
