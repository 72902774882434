"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
// libraries
var lodash_1 = require("lodash");
var driver_1 = require("../driver");
var filter_option_1 = require("../../shared/filters-panel/filter-option");
// services
var condensed_trip_service_1 = require("../../trips/condensed-trip.service");
var trip_service_1 = require("../../trips/trip.service");
var preference_service_1 = require("../../preferences/preference.service");
// components
var driver_trip_filters_dialog_component_1 = require("../driver-trip-filters-dialog/driver-trip-filters-dialog.component");
var column_toggle_component_1 = require("../../shared/column-toggle/column-toggle.component");
var new_trip_dialog_component_1 = require("../../trips/new-trip-dialog/new-trip-dialog.component");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var dialogs_1 = require("../../shared/dialogs");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
// table columns and actions
var columns_1 = require("./columns");
// constants
var app_constants_1 = require("../../app.constants");
// utilities
var app_utilities_1 = require("../../shared/app-utilities");
var DriverAllTrips = /** @class */ (function () {
    function DriverAllTrips(translationService, tripService, preferenceService, dialog) {
        var _this = this;
        this.translationService = translationService;
        this.tripService = tripService;
        this.preferenceService = preferenceService;
        this.dialog = dialog;
        this.loading = false;
        this.errors = [];
        this.appliedFilters = [];
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS.slice();
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.search = '';
        this.tableConfig = {
            hasHeader: true,
            hasCustomFields: true,
            filterQuery: false,
            service: condensed_trip_service_1.CondensedTripService,
            preferenceKey: 'DriverAllTripsComponent-CondensedTripService',
            query: {
                serializer: 'AllTrips',
                exclude_cf: true,
                driver: '',
            },
            collectionTitle: this.translationService.instant('Trips'),
            noResultsText: this.translationService.instant('a trip'),
            sortBy: '-start_time',
            sortDirection: 'asc',
            menuOptions: columns_1.SINGLEROWACTIONS(this.translationService).slice(),
            newRecordModal: function () { return _this.openAddTrip(); },
        };
        this.signatureImageType = app_constants_1.TRIPSIGNATUREIMAGETYPE;
        // dropdown actions
        this.multipleActionDropdownOptions = [
            { action: 'export', name: 'Export', button: true },
            { action: 'void', name: 'Void', button: true, disabled: true },
        ];
        this.enabledFeatures = [];
        this.allLoadedTrips = [];
        this.selectedTrips = [];
        this.filters = [];
        this.allSelected = false;
        this.count = 0;
        // duplicates
        this.checkingDuplicates = false;
        this.filterDate = new Date();
        this.appliedFilterQueryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            job: 'jobevent__job',
            edited: 'edited',
            incomplete: 'completed',
            duplicates: 'only_dupes',
            retake: 'retake_status',
            uninvoiced: 'uninvoiced',
            startDate: 'jobevent__shift1_start__gte',
            endDate: 'jobevent__shift1_start__lte',
        };
        this.saveTripCallback = function () {
            _this.getTrips();
        };
    }
    DriverAllTrips.prototype.ngOnInit = function () {
        this.tableConfig.query = __assign({}, this.tableConfig.query, { driver: this.driver.id });
    };
    DriverAllTrips.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    DriverAllTrips.prototype.openAddTrip = function () {
        var dialog = this.dialog.open(new_trip_dialog_component_1.NewTripDialogComponent, {
            width: '455px',
        });
        var driver = __assign({}, this.driver);
        var carrier = {
            id: driver.carrierId,
            name: driver.carrierOrganizationName,
            carrier: {
                name: driver.carrierName,
                id: driver.carrierId,
            },
        };
        dialog.componentInstance.model.driver = driver;
        dialog.componentInstance.model.truck = driver.latestTruck;
        dialog.componentInstance.model.carrier = carrier;
        // dialog.componentInstance.disabledFields = {
        //   driver: true,
        //   carrier: true,
        // };
        dialog.componentInstance.callback = this.saveTripCallback;
    };
    DriverAllTrips.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(driver_trip_filters_dialog_component_1.DriverTripFiltersDialogComponent, {
            width: '430px',
        });
        if (dialog) {
            dialog.componentInstance.title =
                this.translationService.instant('Filter Trips');
            dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
            var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
            if (startDate && startDate.hasOwnProperty('year')) {
                dialog.componentInstance.model.startDate = new Date(startDate);
            }
            var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
            if (endDate && endDate.hasOwnProperty('year')) {
                dialog.componentInstance.model.endDate = new Date(endDate);
            }
            dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'job' }), 'values');
            dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'project' }), 'values');
            dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'customer' }), 'values');
            dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
                acc[filter.key] = filter.values;
                return acc;
            }, {}));
            this.filtersDialog = dialog.componentInstance;
        }
    };
    DriverAllTrips.prototype.filterChanges = function (filterRes) {
        var _this = this;
        var falseyFilters = [];
        var displayKeys = {
            duplicates: 'Duplicates',
        };
        Object.keys(filterRes).forEach(function (key) {
            if (filterRes[key]) {
                var query = {};
                var values = void 0, displayValues = void 0;
                values =
                    filterRes[key] && key === 'tags'
                        ? filterRes[key].map(function (v) { return v.name; }).join(',')
                        : filterRes[key];
                displayValues =
                    filterRes[key] && filterRes[key]['name']
                        ? filterRes[key]['name']
                        : values;
                if (typeof values === 'boolean') {
                    if (key === 'incomplete' && values) {
                        displayValues = values.toString();
                        displayValues =
                            displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
                        var filterValue = (!values).toString();
                        filterValue =
                            filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                        query[_this.appliedFilterQueryKeys[key]] = filterValue;
                    }
                    else if (key === 'retake' && values) {
                        values = 'requested';
                        query[_this.appliedFilterQueryKeys[key]] = values;
                    }
                    else if (values) {
                        values = values.toString();
                        values = values.charAt(0).toUpperCase() + values.slice(1);
                        query[_this.appliedFilterQueryKeys[key]] = values;
                    }
                }
                else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
                    if (typeof values === 'string') {
                        query[_this.appliedFilterQueryKeys[key]] = values;
                    }
                    else {
                        query[_this.appliedFilterQueryKeys[key]] =
                            filterRes[key] && filterRes[key].id
                                ? filterRes[key].id
                                : filterRes[key];
                    }
                }
                else if (values) {
                    query[_this.appliedFilterQueryKeys[key]] =
                        filterRes[key] && (filterRes[key].id || filterRes[key]);
                }
                var filter = new filter_option_1.FilterOption({
                    filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                    key: key,
                    title: key.charAt(0).toUpperCase() + key.slice(1),
                    displayValues: displayValues || null,
                    displayKey: displayKeys[key] || null,
                    values: values,
                    query: query,
                });
                if (filter.values === 'False' || !filter.values) {
                    falseyFilters.push(filter);
                }
                var existingFilterIndex = lodash_1.findIndex(_this.appliedFilters, { key: key });
                if (existingFilterIndex !== -1) {
                    _this.appliedFilters[existingFilterIndex] = filter;
                }
                else {
                    _this.appliedFilters.push(filter);
                }
            }
            else {
                var existingFilter = lodash_1.find(_this.appliedFilters, { key: key });
                if (existingFilter) {
                    lodash_1.remove(_this.appliedFilters, existingFilter);
                }
            }
        });
        this.appliedFilters = lodash_1.uniqBy(lodash_1.difference(this.appliedFilters, falseyFilters), 'key');
    };
    DriverAllTrips.prototype.setSelectedAction = function (option) {
        switch (option.name) {
            case 'Export':
                this.exportSelectedTrips();
                break;
            case 'Void':
                this.voidSelectedTrips();
                break;
        }
    };
    DriverAllTrips.prototype.exportSelectedTrips = function () {
        var _this = this;
        var selectedTrips = this.selectedTrips.slice();
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, selectedTrips, [], this.allSelected, this.search), params = _a.params, scope = _a.scope;
        if (this.enabledFeatures.includes('hasExportTracking')) {
            scope['markAsExported'] = true;
        }
        if (this.enabledFeatures.includes('hasExportProtection')) {
            params = params.set('unexported', 'True');
        }
        params = params.set('driver', this.driver.id);
        this.tripService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'trips',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: function () {
                        _this.tripsTable.deselectAll();
                    },
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.tripService,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    DriverAllTrips.prototype.voidSelectedTrips = function () {
        var _this = this;
        var trips = this.selectedTrips.slice();
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Trips?',
            body: 'These trips will be marked as "Void" and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripsTable.loading = true;
                rxjs_1.forkJoin(trips.map(function (trip) {
                    return _this.tripService.save({ id: trip.id, void: true });
                })).subscribe(function () { return _this.getTrips(); }, function (err) {
                    _this.tripsTable.loading = false;
                    _this.errors = err;
                });
            }
            _this.confirmDialog = null;
            _this.tripsTable.deselectAll();
        });
    };
    DriverAllTrips.prototype.voidTrip = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Trip?',
            body: 'This trip will be marked as "Void" and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: true }).subscribe(function (result) {
                    trip.void = true;
                    trip.loading = false;
                    _this.getTrips();
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    DriverAllTrips.prototype.duplicateCheck = function () {
        if (!this.checkingDuplicates) {
            var filter = {
                filterType: 'text',
                key: 'duplicates',
                title: 'Duplicates',
                displayValues: 'True',
                values: true,
                query: {
                    only_dupes: 'True',
                },
            };
            var oldFilters = this.appliedFilters.map(function (f) { return f; });
            oldFilters.push(filter);
            this.appliedFilters = oldFilters;
            this.checkingDuplicates = !this.checkingDuplicates;
        }
        else {
            this.appliedFilters = this.appliedFilters.filter(function (f) { return f.key !== 'duplicates'; });
            this.checkingDuplicates = !this.checkingDuplicates;
        }
    };
    DriverAllTrips.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.appliedFilters, filter);
        var duplicateFilter = lodash_1.find(this.appliedFilters, { key: 'duplicates' });
        if (!duplicateFilter) {
            this.checkingDuplicates = false;
        }
        this.savePreferences(this.appliedFilters);
        this.getTrips();
    };
    DriverAllTrips.prototype.savePreferences = function (filters) {
        var _this = this;
        var preference = this.tripsTable.preference;
        if (preference) {
            this.preference = __assign({}, preference, { blob: { filters: filters } });
            this.preferenceService.save(this.preference).subscribe(function (updatedPref) {
                _this.preference = updatedPref;
            });
        }
    };
    DriverAllTrips.prototype.unvoidTrip = function (trip) {
        var _this = this;
        trip.loading = true;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unvoid Trip?',
            body: 'This trip will be unmarked as "Void" and will be visible for the Job.',
            close: 'Cancel',
            accept: 'Unvoid',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            _this.tripsTable.loading = true;
            if (dialogResult) {
                _this.tripService.save({ id: trip.id, void: false }).subscribe(function (result) {
                    trip.void = false;
                    trip.loading = false;
                    _this.getTrips();
                }, function (err) {
                    _this.errors = err;
                    trip.loading = false;
                    _this.tripsTable.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    DriverAllTrips.prototype.getTrips = function () {
        this.tripsTable.getRecords();
    };
    DriverAllTrips.prototype.menuAction = function (_a) {
        var name = _a[0], trip = _a[1];
        switch (name) {
            case 'void':
                this.voidTrip(trip);
                break;
            case 'unvoid':
                this.unvoidTrip(trip);
                break;
        }
    };
    DriverAllTrips.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        this.selectedTrips = this.allSelected
            ? (this.selectedTrips = this.allLoadedTrips.filter(function (t) { return !event.exclusion.selected.some(function (ex) { return ex.id === t.id; }); }))
            : event.selection.selected.slice();
        this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map(function (opt) {
            return opt.action === 'void'
                ? __assign({}, opt, { disabled: event.selection.selected.length === 0 && !event.allSelected }) : opt;
        });
    };
    DriverAllTrips.prototype.onDataLoaded = function (_a) {
        var data = _a.data;
        this.tableConfig.menuOptions[0].returnTo =
            window.location.pathname + window.location.search;
        this.tableConfig.menuOptions[3].returnTo =
            window.location.pathname + window.location.search;
        this.allLoadedTrips = data.slice();
        if (this.tripsTable.preference &&
            this.tripsTable.preference.blob &&
            this.tripsTable.preference.blob.filters) {
            this.checkingDuplicates = this.tripsTable.preference.blob.filters.some(function (f) { return f.key === 'duplicates'; });
        }
    };
    return DriverAllTrips;
}());
exports.DriverAllTrips = DriverAllTrips;
