const camelcaseKeysDeep = require('camelcase-keys-deep');

export class InvoiceFilter {
  id: string;
  name: string;

  constructor(invoiceFilterInfo: any) {
    invoiceFilterInfo = camelcaseKeysDeep(invoiceFilterInfo);

    this.id = invoiceFilterInfo.id;
    this.name = invoiceFilterInfo.name;
  }
}
