import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResourceService } from '../shared/resource.service';
import { PurchaseOrderFilterSerializer } from './purchase-order.filter.serializer';
import { PurchaseOrderFilter } from './purchase-order.filter';

@Injectable()
export class PurchaseOrderFilterService extends ResourceService<PurchaseOrderFilter> {
  baseUrl = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http, 'purchaseorders/', new PurchaseOrderFilterSerializer());
  }
}
