<div class="mobile-dispatch-jobs">
  <notification *ngFor="let error of errors">{{error}}</notification>
  <div *ngFor="let jobEvent of jobEvents" class="row"
       [routerLink]="['/dispatch', jobEvent.job && jobEvent.job.id, jobEvent.id, {shifts: jobEvent.shifts}]">
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="job-name">{{ jobEvent.job && jobEvent.job.name || '&nbsp;' }}</div>
    </div>
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="schedule">
        <i class="icon-clock"></i> 
        {{ jobEvent.shift1StartTime }} - {{ (jobEvent.shift2EndTimestamp ? jobEvent.shift2EndTime : jobEvent.shift1EndTime) }}
      </div>
      <div class="haul-rate">
        {{ jobEvent.haulRate | currency:'USD':'symbol':'1.2-4'}} / {{ jobEvent.haulRateUnit }}
      </div>
    </div>
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="material">
        <i class="icon-material"></i> {{ jobEvent.job && jobEvent.job.material || '&nbsp;' }}
      </div>
      <div class="job-event-status" *ngIf="jobEvent.cancelled">
        <job-status [status]="jobEvent.status" [statusText]="jobEvent.statusText"></job-status>
      </div>
    </div>
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="external-identifier">
        <i class="icon-inventory"></i> {{ jobEvent && (jobEvent.externalIdentifier || jobEvent.job.orderNumber) || '&nbsp;' }}
      </div>
    </div>
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="trucks">
        <i class="icon-truck"></i>
        <ng-container *ngIf="jobEvent.allowAnyTruckType">
          <span translate>Any Truck Type</span>
        </ng-container>
        <ng-container *ngIf="!jobEvent.allowAnyTruckType">
          <span *ngFor="let truckType of jobEvent.truckTypes; let i = index; last as isLast">
            <ng-container *ngIf="i <= 3">
              <ng-container *ngIf="i !== 3">{{ truckType.name }}<span *ngIf="!isLast">, </span></ng-container>
              <ng-container *ngIf="i === 3 && jobEvent.truckTypes.length > 3">+{{ jobEvent.truckTypes.length - 3 }}</ng-container>
            </ng-container>
          </span>
        </ng-container>
      </div>
      <div class="shift-slots">
        {{ jobEvent.dispatchedCount }}
        <ng-container *ngIf="jobEvent.numTrucks"> / {{ jobEvent.numTrucks }}</ng-container>
        <ng-container *ngIf="!jobEvent.numTrucks" translate> Dispatched</ng-container>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <button *ngIf="jobEvents" class="btn btn-primary" (click)="dispatchAll()" translate>Dispatch All</button>
</div>

