"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var app_constants_1 = require("../../app.constants");
var MultipleAttachmentTypePipe = /** @class */ (function () {
    function MultipleAttachmentTypePipe() {
    }
    MultipleAttachmentTypePipe.prototype.transform = function (loadingImages, unloadingImages, type) {
        if (type === void 0) { type = app_constants_1.TRIPTICKETIMAGETYPE; }
        return {
            loadingImages: loadingImages && loadingImages.length
                ? loadingImages.filter(function (img) { return img.fileType === type; })
                : [],
            unloadingImages: unloadingImages && unloadingImages.length
                ? unloadingImages.filter(function (img) { return img.fileType === type; })
                : [],
        };
    };
    return MultipleAttachmentTypePipe;
}());
exports.MultipleAttachmentTypePipe = MultipleAttachmentTypePipe;
