import { Pipe, PipeTransform } from '@angular/core';
import { TRIPTICKETIMAGETYPE } from '../../app.constants';
import { Attachment } from '../../attachments/attachment.interface';

@Pipe({
  name: 'multipleAttachmentType',
})
export class MultipleAttachmentTypePipe implements PipeTransform {
  constructor() {}
  transform(
    loadingImages: Attachment[],
    unloadingImages: Attachment[],
    type: string = TRIPTICKETIMAGETYPE
  ): { loadingImages: Attachment[]; unloadingImages: Attachment[] } {
    return {
      loadingImages:
        loadingImages && loadingImages.length
          ? loadingImages.filter((img) => img.fileType === type)
          : [],
      unloadingImages:
        unloadingImages && unloadingImages.length
          ? unloadingImages.filter((img) => img.fileType === type)
          : [],
    };
  }
}
