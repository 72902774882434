"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GroupByPipe = /** @class */ (function () {
    function GroupByPipe() {
    }
    GroupByPipe.prototype.transform = function (value, field, sort) {
        if (sort === void 0) { sort = false; }
        if (!value) {
            return;
        }
        var groupedObj = value.reduce(function (prev, cur) {
            if (!prev[cur[field]]) {
                prev[cur[field]] = [cur];
            }
            else {
                prev[cur[field]].push(cur);
            }
            return prev;
        }, {});
        var groups = Object.keys(groupedObj).map(function (key) { return ({ key: key, value: groupedObj[key] }); });
        if (sort) {
            groups = groups.sort(function (a, b) {
                if (a.key < b.key) {
                    return -1;
                }
                else if (a.key > b.key) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }
        return groups;
    };
    return GroupByPipe;
}());
exports.GroupByPipe = GroupByPipe;
