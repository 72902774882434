<div class="app" [ngClass]="{
  'sidebar-open': sidebarOpen && loggedIn,
  'mobile': data && data.device && data.device.mobile,
  'tablet': data && data.device && data.device.tablet,
  'desktop': data && data.device && data.device.desktop || !data
}">
  <div *ngIf="loggedIn && displaySidebar" class="sidebar-container" [ngClass]="{expanded: expanded}">
    <my-sidebar *ngIf="data && data.device && !data.device.mobile || !data" (expandedChange)="expanded = $event"></my-sidebar>
    <mobile-sidebar *ngIf="data && data.device && data.device.mobile" (sidebarOpenChange)="sidebarOpen = $event"></mobile-sidebar>
  </div>
  <div class="main-component-container" (click)="sidebarOpen = false; true">
    <router-outlet></router-outlet>
  </div>
</div>
