import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../../shared/resource.service';
import { DriverMetadata } from './driver-metadata.types';
import { DriverMetadataSerializer } from './driver-metadata.serializer';

@Injectable()
export class AllDriversMetadataService extends ResourceService<DriverMetadata> {
  constructor(http: HttpClient) {
    super(http, 'alldriversmapsmetadata/', new DriverMetadataSerializer());
  }
}
