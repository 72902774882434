"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var moment = require("moment");
var shared_1 = require("../../shared");
var driver_1 = require("../../drivers/driver");
var RuckitReplayTimelineComponent = /** @class */ (function () {
    function RuckitReplayTimelineComponent() {
        this.assignments = [];
        this.jobEvents = [];
        this.showVoided = false;
        this.onRefreshTimeline = new core_1.EventEmitter();
        this.timelineData = [];
        this.timelineConfig = {
            visibleBars: ['punchcards', 'trips', 'predictedTrips', 'truckStatuses'],
            headerHeight: 47,
            rowHeight: 120,
            scroll: true,
            currentTime: false,
            selectedTime: true
        };
        this.contextMenuOpened = false;
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.selectedTimeChange = new core_1.EventEmitter();
    }
    Object.defineProperty(RuckitReplayTimelineComponent.prototype, "selectedTime", {
        get: function () { return this.selectedTimeValue; },
        set: function (time) {
            this.selectedTimeValue = time;
            this.selectedTimeChange.emit(time);
        },
        enumerable: true,
        configurable: true
    });
    RuckitReplayTimelineComponent.prototype.ngOnInit = function () {
        this.timeRange = this.timeRange ? this.timeRange : {
            startDatetime: this.selectedDate.startOf('day').toISOString(),
            endDatetime: this.selectedDate.endOf('day').toISOString()
        };
        this.timelineData = [{
                referenceId: '', trips: [], predictedTrips: [], punchcards: [],
                signalLosses: [], waitTimes: [], shifts: [], pavers: []
            }];
    };
    RuckitReplayTimelineComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (((changes.showVoided) ||
            (changes.assignments && changes.assignments.currentValue.length) ||
            (changes.locationUpdates && changes.locationUpdates.currentValue.length)) && this.driver) {
            this.setupTimelineData(this.assignments);
            var matchedJobEvent = this.jobEvents.find(function (jobEvent) { return (jobEvent.id === _this.assignments[0].jobevent.id); });
            this.driverStart = this.assignments[0] && this.assignments[0].uniqueStart ? this.assignments[0].uniqueStart :
                this.assignments[0] && matchedJobEvent && matchedJobEvent.shift1StartTimestamp;
        }
    };
    RuckitReplayTimelineComponent.prototype.setupTimelineData = function (assignments) {
        var _this = this;
        if (assignments === void 0) { assignments = []; }
        this.timelineRange = {
            startDatetime: this.selectedDate.startOf('day').toISOString(),
            endDatetime: this.selectedDate.endOf('day').toISOString()
        };
        this.timelineData = [{
                referenceId: '', trips: [], predictedTrips: [], punchcards: [],
                signalLosses: [], waitTimes: [], shifts: [], pavers: []
            }];
        var waitTimeIndex = 0;
        this.locationUpdates.forEach(function (update, i) {
            if (_this.locationUpdates[i + 1] &&
                Math.abs(moment(_this.locationUpdates[i + 1].date).diff(moment(update.date), 'minutes')) > 1) {
                _this.timelineData[0].signalLosses.push({
                    startDatetime: update.date,
                    endDatetime: _this.locationUpdates[i + 1].date
                });
            }
            // STOPPED TIME SETUP CODE
            if (update.speed < 2) {
                if (!_this.timelineData[0].waitTimes[waitTimeIndex]) {
                    var stopped = false;
                    for (var x = 1; x <= 5; x++) {
                        if (_this.locationUpdates[i + x] && _this.locationUpdates[i + x].speed < 2) {
                            stopped = true;
                        }
                    }
                    if (stopped) {
                        _this.timelineData[0].waitTimes[waitTimeIndex] = {
                            startDatetime: update.date
                        };
                    }
                }
                else if (_this.timelineData[0].waitTimes[waitTimeIndex] &&
                    _this.timelineData[0].waitTimes[waitTimeIndex].startDatetime &&
                    (!_this.locationUpdates[i + 1] || _this.locationUpdates[i + 1].speed >= 2)) {
                    if (moment(update.date).diff(_this.timelineData[0].waitTimes[waitTimeIndex].startDatetime, 'minutes') >= 2) {
                        _this.timelineData[0].waitTimes[waitTimeIndex].endDatetime = update.date;
                        waitTimeIndex++;
                    }
                    else {
                        delete _this.timelineData[0].waitTimes[waitTimeIndex];
                    }
                }
            }
        });
        assignments.forEach(function (assignment) {
            var jobEvent = _this.jobEvents.find(function (jobEvent) { return (jobEvent.id === assignment.jobevent.id); });
            Object.keys(assignment).forEach(function (key) {
                if (['trips', 'predictedTrips', 'punchCards'].indexOf(key) !== -1 && assignment[key] !== null) {
                    assignment[key].forEach(function (el) {
                        el.jobEvent = _this.jobEvents.find(function (jobEvent) { return (jobEvent.id === assignment.jobevent.id); });
                    });
                }
            });
            if (assignment.uniqueStart) {
                _this.timelineData[0].shifts.push({
                    type: 'scheduled',
                    startDatetime: assignment.uniqueStart,
                    endDatetime: undefined
                });
            }
            else {
                if (jobEvent && jobEvent.shift1StartTimestamp && jobEvent.shift1EndTimestamp) {
                    _this.timelineData[0].shifts.push({
                        type: 'scheduled',
                        startDatetime: jobEvent.shift1StartTimestamp,
                        endDatetime: jobEvent.shift1EndTimestamp
                    });
                }
                if (jobEvent && jobEvent.shift2StartTimestamp && jobEvent.shift2EndTimestamp) {
                    _this.timelineData[0].shifts.push({
                        type: 'scheduled',
                        startDatetime: jobEvent.shift2StartTimestamp,
                        endDatetime: jobEvent.shift2EndTimestamp
                    });
                }
            }
            var pavers = [];
            assignment.trips.forEach(function (trip) {
                if (trip.connexPaverStartUnloading) {
                    pavers.push({
                        startDatetime: trip.connexPaverStartUnloading,
                        endDatetime: trip.connexPaverEndUnloading
                    });
                }
            });
            assignment.shifts && assignment.shifts.forEach(function (shift) {
                _this.timelineData[0].shifts.push({
                    type: 'shift',
                    startDatetime: shift.startTime,
                    endDatetime: shift.endTime,
                });
            });
            var trips = _this.timelineData[0].trips.concat(assignment.trips).filter(function (v) {
                return _this.showVoided ? true : !v.void;
            });
            var predictedTrips = _this.timelineData[0].predictedTrips.concat(assignment.predictedTrips).filter(function (trip) { return trip && !trip.void; });
            _this.timelineData = [{
                    referenceId: assignment.id,
                    trips: trips,
                    predictedTrips: predictedTrips,
                    punchcards: _this.timelineData[0].punchcards.concat(assignment.punchCards),
                    signalLosses: _this.timelineData[0].signalLosses.slice(),
                    waitTimes: _this.timelineData[0].waitTimes.slice(),
                    shifts: _this.timelineData[0].shifts.slice(),
                    pavers: pavers.slice()
                }];
        });
    };
    RuckitReplayTimelineComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuOpened = true;
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId,
        });
    };
    RuckitReplayTimelineComponent.prototype.onRefresh = function () {
        this.onRefreshTimeline.emit(true);
    };
    return RuckitReplayTimelineComponent;
}());
exports.RuckitReplayTimelineComponent = RuckitReplayTimelineComponent;
