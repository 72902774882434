"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{position:relative;flex-grow:1}form[_ngcontent-%COMP%]   .field-group.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .field-group.half-width[_ngcontent-%COMP%]{padding-right:30px;min-width:25%;margin-left:20px}form[_ngcontent-%COMP%]   .radio-buttons[_ngcontent-%COMP%]{flex-direction:row;flex:1 1 auto;display:flex;flex-direction:row;justify-content:space-between;align-content:center;align-items:center}form[_ngcontent-%COMP%]   .radio-buttons[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{min-width:100px}form[_ngcontent-%COMP%]   .radio-buttons[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]   [type=checkbox][_ngcontent-%COMP%]:after, form[_ngcontent-%COMP%]   .radio-buttons[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]   [type=radio][_ngcontent-%COMP%]:after{margin-left:1.5em}.form-content[_ngcontent-%COMP%]{padding-top:30px}.form-content[_ngcontent-%COMP%]   h5[_ngcontent-%COMP%], .form-content[_ngcontent-%COMP%]   li[_ngcontent-%COMP%], .form-content[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin:0;padding:0 0 15px;font-size:14px;font-weight:600;line-height:1.36;color:#7f8fa4}.form-content[_ngcontent-%COMP%]   .form-results[_ngcontent-%COMP%]{border-top:1px solid #e6eaee;padding-top:8px}.form-content[_ngcontent-%COMP%]   .form-results[_ngcontent-%COMP%]   h5[_ngcontent-%COMP%]{padding-bottom:5px;font-weight:700}.form-content[_ngcontent-%COMP%]   .form-results[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{padding-bottom:0}.form-content[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]{padding:0}.form-content[_ngcontent-%COMP%]   ol[_ngcontent-%COMP%]{-webkit-padding-start:30px;padding-inline-start:30px;-webkit-margin-before:0;margin-block-start:0}"];
exports.styles = styles;
