import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { reject } from 'lodash';

import { AuthenticationService } from '../index';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mobile-sidebar',
  templateUrl: './mobile-sidebar.component.html',
  styleUrls: ['./mobile-sidebar.component.scss']
})
export class MobileSidebarComponent implements OnInit {
  user;
  topSections: any[] = [];
  bottomSections: any[] = [];
  applicationVersion = environment.applicationVersion;
  open = false;
  canCreateJobs = false;

  @Output() sidebarOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.topSections = [];
    this.bottomSections = [];

    this.topSections = this.topSections.concat([
      { name: 'Live Map', icon: 'icon-locations', type: 'link', url: '/dashboard' },
      { name: 'Active Jobs', icon: 'icon-projects', type: 'link', url: '/daily' },
      { name: 'Create Jobs', icon: 'icon-dispatch', type: 'link', url: '/jobs/new' },
      { name: 'Dispatch', icon: 'icon-dispatch', type: 'link', url: '/dispatch' },
      { name: 'Locations', icon: 'icon-locations', type: 'link', url: '/locations' }
    ]);

    this.bottomSections = this.bottomSections.concat([
      { name: 'Sign Out', icon: 'icon-signout', type: 'link', url: '/logout' }
    ]);
  }

  ngOnInit() {
    this.user = this.authenticationService.user();
    if (this.user && this.user.organization) {
      const organization = this.user.organization;
      this.canCreateJobs = organization && organization.canCreateJobs ? true : false;
    }
    if (!this.canCreateJobs) {
      this.topSections = reject(this.topSections, { name: 'Create Jobs' });
    }
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  toggleOpen() {
    this.open = !this.open;
    this.sidebarOpenChange.emit(this.open);
  }
}
