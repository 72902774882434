import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { EndOfDayRoutingModule } from './end-of-day-routing.module';
import { EndOfDayComponent } from './end-of-day.component';
import { DriverDailyJobsComponent } from '../../drivers/driver-daily-jobs/driver-daily-jobs.component';
import { DriverDailyTasksComponent } from '../../drivers/driver-daily-tasks/driver-daily-tasks.component';
import { DriverDailyPunchCardsComponent } from '../../drivers/driver-daily-punch-cards/driver-daily-punch-cards.component';
import { DriverDailyComponent } from '../../drivers/driver-daily/driver-daily.component';
import { DriverTripsComponent } from '../../drivers/driver-trips/driver-trips.component';
import { DriverEODPunchCardsComponent } from '../../drivers/driver-eod-punch-cards/driver-eod-punch-cards.component';
import { EndOfDayOverallStatsService } from '../end-of-day-overall-stats/end-of-day-overall-stats.service';
import { EndOfDayOverallStatsComponent } from '../end-of-day-overall-stats/end-of-day-overall-stats.component';
import { EndOfDayFiltersDialogComponent } from '../end-of-day-filters/end-of-day-filters.component';
import { EodFilterPipe } from '../../shared/pipes/eod-filter.pipe';
import { DriverNoteDialogComponent } from '../../drivers/driver-note/driver-note-dialog.component';
import { DriverDailyGeotripsComponent } from '../../drivers/driver-daily-geotrips/driver-daily-geotrips.component';

@NgModule({
  imports: [CommonModule, SharedModule, EndOfDayRoutingModule],
  declarations: [
    EndOfDayComponent,
    DriverDailyTasksComponent,
    DriverDailyJobsComponent,
    DriverDailyPunchCardsComponent,
    DriverDailyGeotripsComponent,
    DriverDailyComponent,
    DriverTripsComponent,
    DriverEODPunchCardsComponent,
    EndOfDayOverallStatsComponent,
    EndOfDayFiltersDialogComponent,
    DriverNoteDialogComponent,
    EodFilterPipe,
  ],
  providers: [EndOfDayOverallStatsService, EodFilterPipe],
  entryComponents: [EndOfDayFiltersDialogComponent, DriverNoteDialogComponent],
  exports: [EodFilterPipe],
})
export class EndOfDayModule {}
