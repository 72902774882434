'use strict';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var lodash_1 = require("lodash");
var key_codes_enum_1 = require("../../../shared/enums/key-codes.enum");
var findIndex = function (arr, el) {
    return Array.prototype.indexOf.call(arr, el);
};
var ɵ0 = findIndex;
exports.ɵ0 = ɵ0;
var includes = function (arr, el) {
    return findIndex(arr, el) !== -1;
};
var ɵ1 = includes;
exports.ɵ1 = ɵ1;
var ScaleDropdownComponent = /** @class */ (function () {
    function ScaleDropdownComponent(el, _eref, decimalPipe) {
        this.el = el;
        this._eref = _eref;
        this.decimalPipe = decimalPipe;
        this.open = false;
        this.selectedItems = [];
        this.menuHeight = '0px';
        this.menuWidth = '100%';
        this.keySearchTerm = '';
        this.groups = {};
        this.setTitle = true;
        this.changeTitle = true;
        this.options = [];
        this.onSelect = new core_1.EventEmitter();
        this.onClick = new core_1.EventEmitter();
        this.onToggle = new core_1.EventEmitter();
        this.scaleRefreshIntervalTime = 10000;
        this.onScaleRefresh = new core_1.EventEmitter();
        this.nextPage = new core_1.EventEmitter();
        this.onSearch = new core_1.EventEmitter();
        this.config = this.config || {};
    }
    ScaleDropdownComponent.prototype.documentClick = function (event) {
        if (!this._eref.nativeElement.contains(event.target) && this.open) {
            this.toggleMenu();
        }
        this.onClick.emit(this.open);
    };
    ScaleDropdownComponent.prototype.ngOnInit = function () {
        this.setDefaultConfig();
        this.setSelectedOption();
    };
    ScaleDropdownComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        this.setDefaultConfig();
        this.setSelectedOption();
        if (this.config && this.config.group) {
            this.groups = lodash_1.groupBy(this.options, this.config.groupBy);
        }
        this.groupKeys = Object.keys(this.groups);
        if (this.open) {
            setTimeout(function () {
                _this.setSize();
            }, 0);
        }
    };
    ScaleDropdownComponent.prototype.setDefaultConfig = function () {
        this.config = this.config || {};
        this.config = __assign({ scaleStatus: 'status', scaleName: 'name', scaleWeight: 'weight', scaleUnit: 'weightUnit', nameProperty: 'name', subtitleProperty: 'subtitle', subtitle: false, imageProperty: 'image', image: false, multiselect: false, selectedProp: 'selected', style: '', pin: '', groupBy: 'group', group: false, selectable: true, width: undefined, height: undefined, loadingScales: false }, this.config);
    };
    ScaleDropdownComponent.prototype.setSelectedOption = function () {
        var _this = this;
        this.selectedOption =
            (this.options &&
                this.options.find(function (option) {
                    return option[_this.config.selectedProp];
                })) ||
                this.selectedOption;
    };
    ScaleDropdownComponent.prototype.onKeyPress = function (e) {
        var _this = this;
        if (this.el.nativeElement.contains(document.activeElement)) {
            if (e.code === 'Escape' && this.open) {
                this.toggleMenu();
            }
            var options = this.optionsElRef.nativeElement.children[0].children;
            if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
                if (!this.open) {
                    this.toggleMenu();
                }
                else if (options.length) {
                    var lastOption = options[options.length - 1];
                    var index = findIndex(options, document.activeElement);
                    if (e.code === 'ArrowDown') {
                        if (!includes(options, document.activeElement)) {
                            options[0].focus();
                        }
                        else if (document.activeElement !== lastOption) {
                            options[index + 1].focus();
                        }
                    }
                    else if (e.code === 'ArrowUp') {
                        if (!includes(options, document.activeElement)) {
                            lastOption.focus();
                        }
                        else if (index !== 0) {
                            options[index - 1].focus();
                        }
                    }
                }
                e.preventDefault();
            }
            else if (e.key.match(/^\w$/) &&
                e.target.nodeName.toLowerCase() !== 'input') {
                if (this.keyTimeout) {
                    clearTimeout(this.keyTimeout);
                }
                this.keyTimeout = setTimeout(function () {
                    _this.keySearchTerm = '';
                }, 1000);
                this.keySearchTerm += e.key.toLowerCase();
                var option = lodash_1.find(options, function (o) {
                    return o.innerText
                        .toLowerCase()
                        .match(RegExp('^' + _this.keySearchTerm));
                });
                if (option) {
                    option.focus();
                }
            }
        }
        setTimeout(function () {
            if (_this.open &&
                !_this.el.nativeElement.contains(document.activeElement)) {
                _this.toggleMenu();
            }
        }, 0);
    };
    ScaleDropdownComponent.prototype.buttonKeyPress = function (e) {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            this.toggleMenu();
        }
    };
    ScaleDropdownComponent.prototype.optionKeyPress = function (e, option) {
        if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            this.toggleOption(option);
        }
    };
    ScaleDropdownComponent.prototype.toggleMenu = function () {
        if (!this.disabled) {
            this.open = !this.open;
        }
        this.onToggle.emit(this.open);
        this.setSize();
        this.toggleScaleRefresh();
    };
    ScaleDropdownComponent.prototype.isTareWeight = function (scaleWeight) {
        if (scaleWeight.weightType === 'tare') {
            return true;
        }
    };
    ScaleDropdownComponent.prototype.toggleScaleRefresh = function () {
        var _this = this;
        if (this.open) {
            this.scaleRefreshInterval = setInterval(function () {
                _this.onScaleRefresh.emit(_this.open);
            }, this.scaleRefreshIntervalTime);
        }
        else {
            clearInterval(this.scaleRefreshInterval);
        }
    };
    ScaleDropdownComponent.prototype.setSize = function () {
        var menu = this.optionsElRef.nativeElement;
        if (this.config.height) {
            this.menuHeight = (this.open ? this.config.height : 0) + 'px';
        }
        else {
            this.menuHeight = (this.open ? menu.offsetHeight : 0) + 'px';
        }
        if (this.config.width) {
            this.menuWidth = this.config.width + 'px';
        }
        else {
            this.menuWidth = this.optionsElRef.nativeElement.offsetWidth + 'px';
        }
        var rect = menu.getBoundingClientRect();
        menu.parentElement.style.top =
            '-' + ((this.open ? rect.height : 0) + 14) + 'px';
    };
    ScaleDropdownComponent.prototype.toggleOption = function (option) {
        var _this = this;
        if (!this.config.selectable ||
            option.disabled ||
            this.isZeroWeight(option)) {
            return;
        }
        if (this.config.multiselect) {
            option[this.config.selectedProp] = !option[this.config.selectedProp];
            this.onSelect.emit(this.options.filter(function (_option) {
                return _option[_this.config.selectedProp];
            }));
        }
        else {
            this.deselectAll();
            this.selectedOption = option;
            this.toggleMenu();
            this.onSelect.emit(this.selectedOption);
        }
    };
    ScaleDropdownComponent.prototype.isSelected = function (option) {
        return (!option.button &&
            (option[this.config.selectedProp] ||
                (option && this.selectedOption && option.id === this.selectedOption.id)));
    };
    ScaleDropdownComponent.prototype.isZeroWeight = function (option) {
        return option.weight <= 0;
    };
    ScaleDropdownComponent.prototype.deselectAll = function () {
        var _this = this;
        this.options.forEach(function (option) {
            if (typeof option === 'object') {
                option[_this.config.selectedProp] = undefined;
            }
        });
        this.selectedOption = undefined;
    };
    ScaleDropdownComponent.prototype.getButtonStatus = function () {
        return this.optionScaleStatus(this.selectedOption);
    };
    ScaleDropdownComponent.prototype.getButtonText = function () {
        this.setSelectedOption();
        var buttonText = '';
        if (this.config.multiselect) {
            if (this.selectedItems.length === 1) {
                buttonText = this.optionDisplay(this.selectedItems[0]);
            }
        }
        else {
            // single select
            buttonText = this.selectedOption
                ? this.optionDisplay(this.selectedOption) +
                    (this.config.subtitle
                        ? ' ' + this.optionSubtitleDisplay(this.selectedOption)
                        : '')
                : this.title;
        }
        if (!this.setTitle) {
            buttonText = null;
        }
        if (!this.changeTitle) {
            buttonText = this.title;
        }
        return buttonText;
    };
    ScaleDropdownComponent.prototype.optionDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.nameProperty);
        }
        else {
            return option;
        }
    };
    ScaleDropdownComponent.prototype.optionSubtitleDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.subtitleProperty);
        }
        else {
            return option;
        }
    };
    ScaleDropdownComponent.prototype.optionImageDisplay = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.imageProperty);
        }
        else {
            return option;
        }
    };
    ScaleDropdownComponent.prototype.optionScaleStatus = function (option) {
        if (option && typeof option === 'object') {
            var _option = lodash_1.get(option, this.config.scaleStatus);
            return _option;
        }
        else {
            return option;
        }
    };
    ScaleDropdownComponent.prototype.optionScaleName = function (option) {
        if (typeof option === 'object') {
            return lodash_1.get(option, this.config.scaleName);
        }
        else {
            return option;
        }
    };
    ScaleDropdownComponent.prototype.optionScaleWeight = function (option) {
        if (typeof option === 'object') {
            if (option.disabled) {
                return '-';
            }
            var weight = lodash_1.get(option, this.config.scaleWeight);
            var toReturnWeight = this.decimalPipe.transform(weight);
            var toReturnUnit = lodash_1.get(option, this.config.scaleUnit);
            return toReturnWeight + ' ' + toReturnUnit;
        }
        else {
            return option;
        }
    };
    ScaleDropdownComponent.prototype.onSearchboxKeyPress = function (event) {
        if (event.code === key_codes_enum_1.KeyCodes.Enter) {
            this.onSearch.emit(this.searchTerm);
            event.preventDefault();
        }
    };
    ScaleDropdownComponent.prototype.onBlur = function () {
        this.onSearch.emit(this.searchTerm);
    };
    ScaleDropdownComponent.prototype.onScroll = function (e) {
        if (!this.config.loadingOptions &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            this.nextPage.emit();
        }
        var div = e.currentTarget;
        if (div.clientHeight + div.scrollTop + e.deltaY >= div.scrollHeight) {
            e.preventDefault();
            div.scrollTop = div.scrollHeight;
        }
        else if (div.scrollTop + e.deltaY <= 0) {
            e.preventDefault();
            div.scrollTop = 0;
        }
    };
    ScaleDropdownComponent.prototype.windowScroll = function (e) {
        if (this.open) {
            e.preventDefault();
        }
        return false;
    };
    return ScaleDropdownComponent;
}());
exports.ScaleDropdownComponent = ScaleDropdownComponent;
