'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MenuLinkComponent = /** @class */ (function () {
    function MenuLinkComponent() {
        this.isSelected = false;
        this.external = false;
        this.menuClick = new core_1.EventEmitter();
    }
    MenuLinkComponent.prototype.focusSection = function (event) {
        event.preventDefault();
        // set flag to be used later when
        // $locationChangeSuccess calls openPage()
        // controller.autoFocusContent = true;
    };
    MenuLinkComponent.prototype.onMenuClick = function () {
        this.menuClick.emit(this.section);
    };
    return MenuLinkComponent;
}());
exports.MenuLinkComponent = MenuLinkComponent;
