import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../shared/resource.service';
import { DriverAssignment } from './driver-assignment';
import { DriverAssignmentSerializer } from './driver-assignment.serializer';

@Injectable()
export class DriverAssignmentsService extends ResourceService<DriverAssignment> {

  constructor (http: HttpClient) {
    super(http, 'alldrivers/', new DriverAssignmentSerializer());
  }
}
