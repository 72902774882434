"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var ruckit_replay_component_1 = require("./ruckit-replay/ruckit-replay.component");
var routes = [
    { path: '', component: ruckit_replay_component_1.RuckitReplayComponent, canActivate: [auth_guard_1.AuthGuard] },
];
var RuckitReplayRoutingModule = /** @class */ (function () {
    function RuckitReplayRoutingModule() {
    }
    return RuckitReplayRoutingModule;
}());
exports.RuckitReplayRoutingModule = RuckitReplayRoutingModule;
