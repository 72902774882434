<div class="all-collaborators-container">
  <div class="actions-wrapper">
    <fancy-table #collaboratorsJobTable
                [(availableColumns)]="availableColumns"
                [(displayedColumns)]="displayedColumns"
                [config]="collaboratorJobsTableConfig">

      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'name'">
            <div class="data">
              {{ row.jobName ? row.jobName : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'customer'">
            <div class="data">
              {{ row.customer ? row.customer : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'status'">
            <div class="data">
              {{ row.status ? row.status : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'dispatched-trucks'">
            <div class="data">
              {{ row.dispatchedDriversCount ? (row.dispatchedDriversCount | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'confirmed-trucks'">
            <div class="data">
              {{ row.confirmedTrucks ? (row.confirmedTrucks | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'num-trucks'">
            <div class="data">
              {{ row.numTrucks ? (row.numTrucks | number) : 0 }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'truck-types'">
            <div class="data" *ngIf="row.truckTypes && row.truckTypes.length > 1">
              <truck-type-names [countOnly]="true" [truckTypes]="row.truckTypes"></truck-type-names>
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'drivers-on-shift'">
            <div class="data">
              TODO
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'delivered-loads'">
            <div class="data">
              {{ row.totalLoads ? (row.totalLoads | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'delivered-tons'">
            <div class="data">
              {{ row.totalTons ? (row.totalTons | number) : 0 }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'daily-delivery-target'">
            <div class="data">
              {{ row.dailyDeliveryTarget ? row.dailyDeliveryTarget : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'ordered-loads'">
            <div class="data">
              {{ row.totalOrdered ? (row.totalOrdered | number) : 0 }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'shares-count'">
            <div class="data">
              {{ row.sharesCount ? (row.sharesCount | number) : 0 }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'loading-location'">
            <div class="data">
              TODO
            </div>
          </div>
          <div class="value" *ngSwitchCase="'unloading-location'">
            <div class="data">
              TODO
            </div>
          </div>
          <div class="value" *ngSwitchCase="'orderNumber'">
            <div class="data">
              {{ row.orderNumber ? row.orderNumber : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'material'">
            <div class="data">
              {{ row.material ? row.material : '-' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'start-time'">
            <div class="data">
              {{ row.startTime ? row.startTime : '-' }}
            </div>
          </div>
          <div class="value centered" *ngSwitchCase="'haul-total'">
            <div class="data">
              {{ row.haulAmount ? (row.haulAmount | number) : 0 }}
            </div>
          </div>
        </ng-container>
      </ng-template>
    </fancy-table>
  </div>
</div>
