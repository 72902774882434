<trip-history-widget title="{{ 'Ticket Details' | translate }}" *ngIf="trip">
  <div class="widget-actions" widget-actions>
    <a [routerLink]="['/trips', trip.id, 'edit']">
      <h4 translate>Edit</h4>
    </a>
  </div>

  <div class="ticket-details">
    <div class="detail-row">
      <span class="detail-key" translate>Job</span>
      <span class="detail-value link">
        <a
          [routerLink]="['/jobs', trip.jobId, trip.jobEventId]"
          target="_blank"
          >{{ trip.job }}</a
        >
      </span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Customer</span>
      <span class="detail-value">{{ trip.customer || '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Date</span>
      <span class="detail-value">{{
        (trip.startDate | date: 'MM/dd/yyyy') || '-'
      }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Truck #</span>
      <span class="detail-value">{{ trip.truckName || '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Trip Time</span>
      <span class="detail-value">{{ trip.completedTripTime || '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Driver</span>
      <span class="detail-value">{{
        trip.driver + ' - ' + trip.customDriver.organizationName || '-'
      }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Job Loading Location</span>
      <span class="detail-value">
        <a
          [routerLink]="['/locations', trip.startLocationId, 'edit']"
          target="_blank"
          >{{ trip.startLocationName || '-' }}
        </a>
      </span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Geo Loading Location</span>
      <span class="detail-value">
        <a
          [routerLink]="['/locations', trip.geoStartLocationId, 'edit']"
          target="_blank"
          >{{ trip.geoStartLocationName || '-' }}
        </a>
      </span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Job Unloading Location</span>
      <span class="detail-value">
        <a
          [routerLink]="['/locations', trip.endLocationId, 'edit']"
          target="_blank"
          >{{ trip.endLocationName || '-' }}
        </a>
      </span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Geo Unloading Location</span>
      <span class="detail-value">
        <a
          [routerLink]="['/locations', trip.geoEndLocationId, 'edit']"
          target="_blank"
          >{{ trip.geoEndLocationName || '-' }}
        </a>
      </span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Replay</span>
      <span class="detail-value">
        <a
          href="/replay?date={{ trip.jobDate | date: 'yyyyMMdd' }}&driver={{
            trip.driverId
          }}"
          target="_blank"
          translate
          >View Replay</a
        >
      </span>
    </div>

    <!-- loading ticket -->
    <div class="detail-row">
      <span class="detail-key" translate>Loading Ticket #</span>
      <div class="loading-ticket">
        <span class="detail-value">{{ trip.loadingTicketNumber || '-' }}</span>
        <ng-container
          *ngIf="loadingImages | filterBy: 'isDeleted':true:true as images"
        >
          <ng-container
            *ngIf="
              loadingSignatureImages
                | filterBy: 'isDeleted':true:true as signatureImages
            "
          >
            <div
              class="multiple-images-container"
              *ngIf="images.length || signatureImages.length"
            >
              <div class="image-count-title">
                {{ images.length + signatureImages.length }} Image(s)
              </div>
              <img
                [src]="images[0]?.src ? images[0].src : signatureImages[0]?.src"
              />
              <button
                class="btn btn-primary"
                (click)="handleImagesDialogVisibility()"
                translate
              >
                View Images
              </button>
            </div>
            <div
              class="no-images-text"
              *ngIf="!images.length && !signatureImages.length"
            >
              <span (click)="handleImagesDialogVisibility()" translate
                >No Images</span
              >
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Loading Time</span>
      <span class="detail-value">{{
        trip.loadingCheckin.date | date: 'MM/dd/yyyy HH:mm a' || '-'
      }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Loading Weight</span>
      <span class="detail-value">{{ trip.loadingWeight || '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Loading Signature</span>
      <span class="detail-value" translate *ngIf="!trip.loadingSignature"
        >-</span
      >
      <div class="signature" translate *ngIf="trip.loadingSignature">
        <a [href]="trip.loadingSignature" target="_blank" translate
          >Loading Signature</a
        >
        <img [src]="trip.loadingSignature" />
      </div>
    </div>

    <!-- unloading ticket -->
    <div class="detail-row">
      <span class="detail-key" translate>Unloading Ticket #</span>
      <div class="unloading-ticket">
        <span class="detail-value">{{
          trip.unloadingTicketNumber || '-'
        }}</span>
        <ng-container
          *ngIf="unloadingImages | filterBy: 'isDeleted':true:true as images"
        >
          <ng-container
            *ngIf="
              unloadingSignatureImages
                | filterBy: 'isDeleted':true:true as signatureImages
            "
          >
            <div
              class="multiple-images-container"
              *ngIf="images.length || signatureImages.length"
            >
              <div class="image-count-title">
                {{ images.length + signatureImages.length }} Image(s)
              </div>
              <img
                [src]="images[0]?.src ? images[0].src : signatureImages[0]?.src"
              />
              <button
                class="btn btn-primary"
                (click)="handleImagesDialogVisibility()"
                translate
              >
                View Images
              </button>
            </div>
            <div
              class="no-images-text"
              *ngIf="!images.length && !signatureImages.length"
            >
              <span (click)="handleImagesDialogVisibility()" translate
                >No Images</span
              >
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Unloading Time</span>
      <span class="detail-value">{{
        trip.unloadingCheckin.date | date: 'MM/dd/yyyy HH:mm a' || '-'
      }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Unloading Weight</span>
      <span class="detail-value">{{ trip.unloadingWeight || '-' }}</span>
    </div>
    <div class="detail-row">
      <span class="detail-key" translate>Unloading Signature</span>
      <span class="detail-value" translate *ngIf="!trip.unloadingSignature"
        >-</span
      >
      <div class="signature" translate *ngIf="trip.unloadingSignature">
        <a [href]="trip.unloadingSignature" target="_blank" translate
          >Unloading Signature</a
        >
        <img [src]="trip.unloadingSignature" />
      </div>
    </div>
  </div>

  <multiple-images-edit
    #editImagesContainer
    [editable]="true"
    [loadingImages]="loadingImages"
    [unloadingImages]="unloadingImages"
    [loadingSignatureImages]="loadingSignatureImages"
    [unloadingSignatureImages]="unloadingSignatureImages"
    [showOnSave]="true"
    [buttonLoadingRef]="retakeLoadingRequest"
    [buttonUnloadingRef]="retakeUnloadingRequest"
    (onLoadingImagesChange)="onLoadingImagesChange($event)"
    (onUnloadingImagesChange)="onUnloadingImagesChange($event)"
    (onLoadingSignatureImagesChange)="onLoadingSignaturesChange($event)"
    (onUnloadingSignatureImagesChange)="onUnloadingSignaturesChange($event)"
    (uploading)="uploading($event)"
    (onSave)="saveImages()"
  ></multiple-images-edit>

  <ng-template #retakeLoadingRequest>
    <split-button
      title="{{ 'Request Ticket Retake' | translate }}"
      buttonClasses="green-button"
      controlButtonClasses="green-button"
      [disabled]="trip.retakeRequested"
      (onPrimaryAction)="requestRetake(trip.loadingCheckin.id)"
    >
      <a
        translate
        (click)="undoRequestRetake(trip)"
        [ngClass]="{
          disabled: !trip.retakeRequested
        }"
      >
        Cancel Retake Request</a
      >
    </split-button>
  </ng-template>

  <ng-template #retakeUnloadingRequest>
    <split-button
      title="{{ 'Request Ticket Retake' | translate }}"
      buttonClasses="green-button"
      controlButtonClasses="green-button"
      [disabled]="trip.retakeRequested"
      (onPrimaryAction)="requestRetake(trip.unloadingCheckin.id)"
    >
      <a
        (click)="undoRequestRetake()"
        [ngClass]="{
          disabled: !trip.retakeRequested
        }"
        translate
      >
        Cancel Retake Request
      </a>
    </split-button>
  </ng-template>
</trip-history-widget>
