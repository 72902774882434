<div class="legend" [ngClass]="{hidden: hidden, condensed: markerStatus === 'load-status'}">
  <ng-container *ngIf="markerStatus === 'each-segment'">
    <div class="enroute-loading" translate>Enroute</div>
    <div class="loading" translate>Loading</div>
    <div class="enroute-unloading" translate>Enroute</div>
    <div class="unloading" translate>Unloading</div>
    <div class="empty" translate>Empty</div>
    <div class="update-expired" translate>Expired</div>
  </ng-container>
  <ng-container *ngIf="markerStatus === 'load-status'">
    <div class="loaded" translate>Loaded</div>
    <div class="empty" translate>Empty</div>
    <div class="update-expired" translate>Expired</div>
  </ng-container>
</div>
