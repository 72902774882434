import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { MarketplaceRoutingModule } from './marketplace-routing.module';

import { MarketplaceListingsComponent } from './marketplace-listings.component';
import { JobOverviewComponent } from './job-overview.component';
import { MarketplaceAcceptJobComponent } from './marketplace-accept-job.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MarketplaceRoutingModule
  ],
  declarations: [
    MarketplaceListingsComponent,
    JobOverviewComponent,
    MarketplaceAcceptJobComponent,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class MarketplaceModule { }
