import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { MarketplaceService } from '../marketplace/marketplace.service';

@Component({
  selector: 'ruckit-marketplace-listings',
  templateUrl: './marketplace-listings.component.html',
  styleUrls: ['./marketplace-listings.component.scss'],
  providers: [MarketplaceService]
})
export class MarketplaceListingsComponent implements OnInit {
  loading = true;
  errors = [];
  marketplaces = [];
  marketplacesReq;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private marketplaceService: MarketplaceService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.errors = [];

    this.disableSidebar();
    this.getMarketplaces();
  }

  disableSidebar() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser !== {}) {
      currentUser.sidebar = false;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
  }

  getMarketplaces(query = {}) {
    if (this.marketplacesReq) {
      this.marketplacesReq.unsubscribe();
    }

    this.marketplacesReq = this.marketplaceService.getMarketplaces({
      ordering: 'jobevent__shift1_start,jobevent__job__name',
      ...query
    }).subscribe(
        marketplaces => this.marketplaces = marketplaces,
        err => this.errors = err,
        () => {
          this.loading = false;
        }
      );
  }

  openOverview(marketplace) {
    this.router.navigate(['/marketplace/' + marketplace.id]);
  }

  // POST /marketplace/id/publish
  // start_date, end_date, haul_rate
}
