"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var locationUpdate_1 = require("./locationUpdate");
var api_service_1 = require("../shared/api.service");
var LocationUpdateService = /** @class */ (function () {
    function LocationUpdateService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    LocationUpdateService.prototype.get = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        var url = this.baseUrl + 'locationupdates/';
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key].toString) {
                    if (key === 'id') {
                        _this.baseUrl += query[key] + '/';
                    }
                    else {
                        params = params.set(key, query[key].toString());
                    }
                }
            });
        }
        return this.http.get(url, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    LocationUpdateService.prototype.getAll = function (pageSize, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 1000; }
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        var url = this.baseUrl + 'locationupdates/';
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key].toString) {
                    if (key === 'id') {
                        _this.baseUrl += query[key] + '/';
                    }
                    else {
                        params = params.set(key, query[key].toString());
                    }
                }
            });
        }
        params = params.set('page_size', '1');
        var requestCount = 0;
        return this.http.get(url, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(url, {
                    headers: api_service_1.requestHeaders(),
                    params: params
                }).map(function (res) {
                    return _this.extractData(res);
                }));
            }
            else {
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(url, {
                        headers: api_service_1.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        requestCount++;
                        return res;
                    }), operators_1.map(function (res) { return _this.extractData(res); })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return mergedList;
        }), operators_1.catchError(api_service_1.handleError));
    };
    LocationUpdateService.prototype.coordinates = function (bounds, query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        var url = this.baseUrl + 'locationupdates/coordinates/';
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key].toString) {
                    if (key === 'id') {
                        _this.baseUrl += query[key] + '/';
                    }
                    else {
                        params = params.set(key, query[key].toString());
                    }
                }
            });
        }
        var model = bounds.toJSON();
        var response = this.http.post(url, model, {
            headers: api_service_1.requestHeaders(),
            params: params,
        }).pipe(operators_1.map(function (res) {
            var resObj = res;
            _this.nextUri = resObj['next'];
            _this.count = resObj['count'];
            var body = resObj['results'] || resObj;
            return body;
        }), operators_1.catchError(api_service_1.handleError));
        return response;
    };
    LocationUpdateService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    LocationUpdateService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (result) {
                return new locationUpdate_1.LocationUpdate(result);
            });
        }
        else if (resObj) {
            return new locationUpdate_1.LocationUpdate(resObj);
        }
    };
    return LocationUpdateService;
}());
exports.LocationUpdateService = LocationUpdateService;
