import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Trip } from '../../trips/trip';
import { PunchCard } from '../../punch-cards/punch-card';

@Pipe({
  name: 'startHour', pure: true
})
export class StartHourPipe implements PipeTransform {
  transform(record: Trip|PunchCard, hour: string, format = 'h a'): boolean {
    if (!record || !record.startTimeTimestamp) { return false; }
    const startHour = moment(record.startTimeTimestamp).format('h a');

    return (startHour === hour);
  }
}
