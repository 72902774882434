<div class="split-button-container">
  <button *ngIf="!primaryRouterLink" type="button" (click)="primaryActionClicked($event)"
          [disabled]="disabled" class="split-button {{ buttonClasses }}"
          [ngClass]="{'split-dropdown-enabled': dropdownEnabled}">
    {{ title }}
  </button>
  <button *ngIf="primaryRouterLink" type="button" [routerLink]="primaryRouterLink"
          [disabled]="disabled" class="split-button {{ buttonClasses }}"
          [ngClass]="{'split-dropdown-enabled': dropdownEnabled}">
    {{ title }}
  </button>

  <mat-menu #splitDropdownMenu="matMenu">
    <ng-content></ng-content>
  </mat-menu>

  <button class="control-button {{ controlButtonClasses }}"
          *ngIf="dropdownEnabled" mat-icon-button
          type="button"
          #splitMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="splitDropdownMenu">
    <mat-icon>{{ icon }}</mat-icon>
  </button>
</div>
