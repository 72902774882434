"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
// libraries
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
// angular material
var material_1 = require("@angular/material");
// services
var preference_service_1 = require("../../preferences/preference.service");
var tag_service_1 = require("../../tags/tag.service");
var driver_service_1 = require("../../drivers/driver.service");
var connection_service_1 = require("../../connections/connection.service");
var api_service_1 = require("../../shared/api.service");
var index_1 = require("../../shared/index");
var driver_1 = require("./../driver");
var job_1 = require("../../jobs/job");
// components
var assign_truck_dialog_component_1 = require("../../drivers/assign-truck-dialog/assign-truck-dialog.component");
var AvailableDriversComponent = /** @class */ (function () {
    function AvailableDriversComponent(preferenceService, tagService, driverService, connectionService, authenticationService, deviceDetectorService, dialog) {
        this.preferenceService = preferenceService;
        this.tagService = tagService;
        this.driverService = driverService;
        this.connectionService = connectionService;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.dialog = dialog;
        this.loading = false;
        this.selectedTags = [];
        this.selectedDriversChange = new core_1.EventEmitter();
        this.shiftChange = new core_1.EventEmitter();
        this.assignDriversClicked = new core_1.EventEmitter();
        this.preferenceKey = 'AvailableDriversComponent-DriversService';
        this.driversLoading = false;
        this.carriersLoading = false;
        this.errors = [];
        this.tags = [];
        this.tagsConfig = {
            nameProperty: 'name',
            loadingOptions: false,
            multiselect: true,
            deselectOption: 'All Markets'
        };
        this.selectedDrivers = [];
        this.selectedDriverCount = 0;
        this.truckOptionsForModal = [];
        this.carriers = [];
        this.carriersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.drivers = {
            items: [],
            errors: [],
            loading: false,
            search: ''
        };
        this.hasAllDriversEnabled = false;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.shift = [
            { id: 0, name: 'Shift 1' },
            { id: 1, name: 'Shift 2' }
        ];
        this.shiftConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.staggerOptions = {};
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.jobEventChange = new core_1.EventEmitter();
    }
    Object.defineProperty(AvailableDriversComponent.prototype, "jobEvent", {
        get: function () { return this.jobEventValue; },
        set: function (data) {
            this.jobEventValue = data;
            this.jobEventChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    AvailableDriversComponent.prototype.ngOnInit = function () {
        this.getPreferences();
        this.getTags();
        this.getDrivers();
        this.getCarriers();
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.setShift();
    };
    AvailableDriversComponent.prototype.ngOnChanges = function (changes) {
        if (changes['selectedTags'] && changes['selectedTags'].currentValue) {
            this.selectTags(changes['selectedTags'].currentValue);
        }
        if (changes['dispatchByJobTab']) {
            this.getDrivers();
        }
    };
    AvailableDriversComponent.prototype.ngOnDestroy = function () {
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
            this.tagsReq.unsubscribe();
        }
        if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
            this.carriersReq.unsubscribe();
        }
    };
    AvailableDriversComponent.prototype.getTags = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
            this.tagsReq.unsubscribe();
        }
        var request = this.tagService.list(query);
        if (next) {
            request = this.tagService.listNext();
        }
        else {
            this.tags = [];
            if (this.selectedTags) {
                this.selectTags(this.selectedTags);
            }
        }
        if (request) {
            this.tagsReq = request.subscribe(function (res) {
                _this.tags.push.apply(_this.tags, res);
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    AvailableDriversComponent.prototype.selectTags = function (tags) {
        if (this.tagsDropdown) {
            if (JSON.stringify(tags) !== JSON.stringify(this.selectedTags)) {
                this.selectedTags = tags;
                this.savePreferences();
            }
            this.tagsDropdown.selectedItems = tags;
            this.tagsDropdown.setButtonText();
            if (tags.length > 0) {
                this.tagsUrlList = tags.map(function (tag) { return tag.name; }).join(',');
            }
            else {
                this.tagsUrlList = null;
            }
            this.getDrivers();
        }
        else {
            this.errors = ['There was an issue loading the drivers'];
        }
    };
    /**
     * @param  {} shift
     * @returns void
     * This function is called on change of shift dropdown.
     * Selected value is set to dropdown and is emitted for
     * further use in dispatch-by-job component.
     */
    AvailableDriversComponent.prototype.selectShift = function (shift) {
        if (this.shiftDropdown) {
            this.selectedShift = shift;
            this.shiftChange.emit(this.selectedShift);
        }
    };
    /**
     * @returns void
     * This function is called as soon as the view is initialized.
     * Set and emit the value as Shift 1 by default.
     */
    AvailableDriversComponent.prototype.setShift = function () {
        this.selectedShift = this.shift[0];
        this.shiftChange.emit(this.selectedShift);
    };
    AvailableDriversComponent.prototype.getDrivers = function () {
        var _this = this;
        if (this.drivers && !this.drivers.items) {
            this.drivers.items = [];
        }
        this.driversLoading = true;
        var allCarriers, allLeased;
        if (this.driversReq) {
            this.driversReq.unsubscribe();
        }
        var carrierId;
        if (this.carrier && this.carrier.id === 'all_carriers') {
            allCarriers = true;
        }
        else if (this.carrier && this.carrier.id === 'all_leased') {
            allLeased = true;
        }
        else if (this.carrier && this.carrier.id === 'my_drivers') {
            // Nothing necessary
        }
        else if (this.carrier && this.carrier.id) {
            carrierId = this.carrier.id;
        }
        if (this.tagsUrlList && this.tagsUrlList.slice(-1) !== ',') {
            this.tagsUrlList = this.tagsUrlList + ",";
        }
        var jobEventId = this.jobEvent && this.jobEvent.id;
        this.driversReq = this.driverService.list({
            ordering: 'truck__name,name',
            simple_search: this.drivers.search,
            jobevent_available: jobEventId,
            carrier: carrierId,
            all_carriers: allCarriers,
            all_leased: allLeased,
            tags: this.tagsUrlList
        }).subscribe(function (drivers) {
            var selectedDrivers = lodash_1.filter(_this.drivers.items, 'selected');
            var dispatchedDrivers = drivers.filter(function (d) { return (!d.selected && _this.driverIsOnJobEvent(d)); });
            _this.drivers.items = lodash_1.filter(drivers, function (driver) {
                return !selectedDrivers.some(function (selectedDriver) { return selectedDriver.id === driver.id; }) && !_this.driverIsOnJobEvent(driver);
            });
            _this.drivers.items = _this.drivers.items.concat(dispatchedDrivers).concat(selectedDrivers);
        }, function (err) {
            _this.drivers.errors = err;
        }, function () {
            _this.driversLoading = false;
        });
    };
    AvailableDriversComponent.prototype.changeDriversSearch = function (term) {
        this.drivers.search = term;
        this.getDrivers();
    };
    AvailableDriversComponent.prototype.driversScroll = function (e) {
        var _this = this;
        if (!this.drivers.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.driverService.listNext();
            if (o) {
                this.drivers.loading = true;
                o.subscribe(function (drivers) {
                    _this.drivers.items = _this.drivers.items.concat(drivers);
                    _this.drivers.loading = false;
                }, function (err) { return _this.errors = err; });
            }
        }
    };
    AvailableDriversComponent.prototype.driverIsDraggable = function (driver) {
        this.selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        return this.selectedDrivers.length === 0 || lodash_1.includes(this.selectedDrivers, driver);
    };
    AvailableDriversComponent.prototype.driverIsGrabbed = function (driver) {
        return this.driverIsDraggable(driver) ?
            !!(driver === this.draggedDriver || (this.draggedDriver && driver.selected)) :
            undefined;
    };
    AvailableDriversComponent.prototype.multipleDrag = function (driver) {
        this.selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        return this.draggedDriver === driver && this.selectedDrivers.length > 1;
    };
    AvailableDriversComponent.prototype.driverDragstart = function (driver) {
        if (this.selectedDrivers && this.selectedDrivers.length) {
            this.selectedDriversChange.emit(this.selectedDrivers);
        }
        else {
            this.draggedDriver = driver;
            this.selectedDriversChange.emit([this.draggedDriver]);
        }
    };
    AvailableDriversComponent.prototype.driverDragend = function (e) {
        if (this.selectedDrivers && this.selectedDrivers.length) {
            this.drivers.items.forEach(function (driver) {
                driver.selected = false;
            });
            this.selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        }
    };
    /**
     * @returns void
     * This is called on click of assign driver button
     * Checks for the selected drivers length and emit the value to be
     * used in dispatch-by-job component
     */
    AvailableDriversComponent.prototype.assignDrivers = function () {
        if (this.selectedDrivers && this.selectedDrivers.length) {
            this.selectedDriversChange.emit(this.selectedDrivers);
            this.assignDriversClicked.emit(this.selectedDrivers);
            this.drivers.items.forEach(function (driver) {
                driver.selected = false;
            });
            this.selectedDrivers = lodash_1.filter(this.drivers.items, 'selected');
        }
    };
    AvailableDriversComponent.prototype.slotDragover = function (e) {
        e.preventDefault();
    };
    AvailableDriversComponent.prototype.slotDrag = function (slot, e) {
        slot.over = (e.type === 'dragenter');
    };
    AvailableDriversComponent.prototype.selectDriver = function (e, driver) {
        var checked = e.target.checked;
        driver.selected = (checked === undefined) ? !driver.selected : checked;
        lodash_1.extend(lodash_1.find(this.drivers.items, { id: driver.id }), driver);
        this.selectedDriverCount = lodash_1.filter(this.drivers.items, 'selected').length;
        var isAllDriverSelected = this.drivers.items.find(function (i) { return i.selected === false; });
        if (isAllDriverSelected && !isAllDriverSelected.selected) {
            this.allDriverSelected = false;
        }
        else {
            this.allDriverSelected = true;
        }
    };
    AvailableDriversComponent.prototype.allSelectDrivers = function (event) {
        this.allDriverSelected = event.target.checked;
        if (this.allDriverSelected) {
            this.drivers.items.forEach(function (driver) {
                driver.selected = true;
            });
        }
        else {
            this.drivers.items.forEach(function (driver) {
                driver.selected = false;
            });
        }
        this.selectedDriverCount = lodash_1.filter(this.drivers.items, 'selected').length;
    };
    AvailableDriversComponent.prototype.openAssignTruck = function (driver) {
        var dialog = this.dialog.open(assign_truck_dialog_component_1.AssignTruckDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.driver = lodash_1.cloneDeep(driver);
        dialog.componentInstance.jobEvent = this.jobEvent;
        dialog.componentInstance.callback = function (res) {
            Object.assign(driver, res);
        };
        return dialog;
    };
    AvailableDriversComponent.prototype.getCarriers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
            this.carriersReq.unsubscribe();
        }
        this.carriers = [
            { name: 'My Drivers', id: 'my_drivers' },
            { name: 'All Carriers', id: 'all_carriers' },
        ];
        if (this.job
            && this.job.project
            && this.job.project.customerOrganization
            && this.job.project.customerOrganization['hasLeasedOrgs']) {
            this.carriers.push({ name: 'Leased', id: 'all_leased' });
        }
        this.carriersLoading = true;
        this.carriersReq = this.connectionService.list(__assign({ ordering: 'organization__name', allow_dispatch: 'True', is_carrier: 'True' }, query)).subscribe(function (connections) {
            var _carriers = connections.map(function (connection) {
                return {
                    name: connection.organization.name,
                    id: connection.organization.carrier.id
                };
            });
            _this.carriers = _this.carriers.concat(_carriers);
            if (_this.carriersDropdown && _this.carriersDropdown.config) {
                _this.carriersDropdown.config.loadingOptions = false;
            }
            _this.carriersLoading = false;
            _this.carriersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.carriersLoading = false;
            _this.carriersConfig.loadingOptions = false;
        });
    };
    AvailableDriversComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config, service, options;
        switch (type) {
            case 'carrier':
                config = this.carriersConfig;
                service = this.connectionService;
                options = this.carriers;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'carrier':
                            var _carriers = results.map(function (connection) {
                                return {
                                    name: connection.organization.name,
                                    id: connection.organization.carrier.id
                                };
                            });
                            _this.carriers = _this.carriers.concat(_carriers);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    AvailableDriversComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'carrier':
                this.getCarriers({ search: term });
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    AvailableDriversComponent.prototype.selectCarrier = function (carrier, savePreference) {
        this.carrier = carrier;
        this.getDrivers();
        if (savePreference) {
            this.savePreferences();
        }
    };
    AvailableDriversComponent.prototype.mobileSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'carrier':
                this.getCarriers({ search: term });
                break;
            case 'drivers':
                this.changeDriversSearch(term);
                break;
            default:
                throw 'invalid type';
        }
    };
    AvailableDriversComponent.prototype.mobileGetNext = function (e, type) {
        switch (type) {
            case 'carrier':
                this.dropdownNextPage(e, type);
                break;
            case 'drivers':
                this.driversScroll(e);
                break;
            default:
                throw 'invalid type';
        }
    };
    AvailableDriversComponent.prototype.driverIsReady = function (driver) {
        return !(driver.dutyStatus === 'off-duty' ||
            (driver.truck && driver.truck.serviceStatus === 'out-of-service'));
    };
    AvailableDriversComponent.prototype.driverIsOnJobEvent = function (driver) {
        if (this.dispatchByJobTab === 'loadList') {
            return false;
        }
        return driver.conflictIds.includes(this.jobEvent.id);
    };
    AvailableDriversComponent.prototype.getPreferences = function () {
        var _this = this;
        var user = this.authenticationService.user();
        this.preferenceService.list({
            name: this.preferenceKey,
            type: 'user',
            profile: user && user.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                preferences.forEach(function (preference) {
                    if (preference.blob) {
                        _this.preference = preference;
                        _this.parsePreferences();
                    }
                });
            }
        });
    };
    AvailableDriversComponent.prototype.savePreferences = function () {
        var _this = this;
        if (this.preferenceKey) {
            var currentUser = this.authenticationService.user();
            this.preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: currentUser.id, blob: {
                    carrier: this.carrier,
                    tags: this.selectedTags
                } });
            this.preferenceService.save(this.preference).subscribe(function (preference) {
                _this.preference = preference;
            });
        }
    };
    AvailableDriversComponent.prototype.parsePreferences = function () {
        if (this.preference.blob && this.preference.blob['tags']) {
            this.selectedTags = this.preference.blob['tags'];
            this.selectTags(this.selectedTags);
        }
        if (this.preference.blob && this.preference.blob['carrier']) {
            this.selectCarrier(this.preference.blob['carrier'], false);
            this.carriersDropdown.selectedOption = this.preference.blob['carrier'];
            this.carriersDropdown.setSelectedOption();
        }
    };
    AvailableDriversComponent.prototype.openContextMenu = function (event, driver) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driver.id,
        });
    };
    return AvailableDriversComponent;
}());
exports.AvailableDriversComponent = AvailableDriversComponent;
