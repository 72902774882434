"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var IsInArrayPipe = /** @class */ (function () {
    function IsInArrayPipe() {
    }
    IsInArrayPipe.prototype.transform = function (arr, value, keyToCompare) {
        if (typeof value === 'string') {
            return arr.indexOf(value) !== -1;
        }
        else {
            return arr.some(function (item) { return item[keyToCompare] === value[keyToCompare]; });
        }
    };
    return IsInArrayPipe;
}());
exports.IsInArrayPipe = IsInArrayPipe;
