<my-toolbar [logo]=false [userDropdown]=false [menuButton]=false title="Set Haul Rate" color="ruckit-dark-blue"></my-toolbar>

<form #acceptJob="ngForm">
  <div class="job-overview" *ngIf="!loading">
    <div class="no-results" *ngIf="!loading && !marketplace" fxLayout="column" fxLayoutAlign="start center">
      <div class="icon"><i class="icon-projects"></i></div>
      <h2>No Job Found</h2>
      <p>No job was found with this ID.</p>
    </div>

    <div class="job-detail" *ngIf="marketplace && marketplace.job">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="job-name">{{ marketplace.job.name || '&nbsp;' }}</div>
      </div>
      <div class="field-row customer-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="customer">{{ marketplace.customer || '&nbsp;' }}</div>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="job-date">{{ marketplace.dateRange || '&nbsp;' }}</div>
        <div class="invoice-rate currency">{{ (marketplace.marketInvoiceRate || 0 | currency:'USD':'symbol':'1.2-4') }} / {{ marketplace.job.haulRateUnit || '&nbsp;' | titleCase }}</div>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div class="schedule">{{ marketplace.shift1StartTime || '&nbsp;' }} - {{ marketplace.endTime || '&nbsp;' }}</div>
        <div class="material">{{ marketplace.job.material || '&nbsp;' }}</div>
      </div>
    </div>

    <div class="job-rates" *ngIf="marketplace && marketplace.job">
      <notification *ngFor="let error of errors">{{error}}</notification>
      <my-loader *ngIf="loading" [ngClass]="{'full-height': !marketplace}"></my-loader>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <h2>Haul Rate</h2>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <p>You must set a haul rate before dispatching.</p>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <input type="number" name="haul_rate" placeholder="$" required [(ngModel)]="model.haulRate" />
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <select required name="haul_type" [(ngModel)]="model.haulType">
          <option value="" selected="selected">Haul Rate Type</option>
          <option *ngFor="let haulType of haulTypes" [value]="haulType.id">{{ haulType.name }}</option>
        </select>
      </div>
      <div *ngIf="model.haulType === 'weight'" class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <select required name="weight_unit" [(ngModel)]="model.weightUnit">
          <option value="" selected="selected">Weight Type</option>
          <option *ngFor="let weightUnit of weightUnits" [value]="weightUnit.id">{{ weightUnit.name }}</option>
        </select>
      </div>
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <select required #projectsSelect name="project" [(ngModel)]="model.project" (change)="onProjectChange($event, projectsSelect.value)">
          <option value="" selected="selected" *ngIf="!model.project">Project</option>
          <option value="new-project">Add a New Project</option>
          <option *ngFor="let project of projects" [value]="project.id">{{ project.name }}</option>
        </select>
      </div>
    </div>

    <div class="actions">
      <button class="btn btn-primary" (click)="acceptJob.form.valid && accept(); false"
              [disabled]=" ! acceptJob.form.valid || loading"
              [ngClass]="{loading:loading}">SET HAUL RATE</button>
    </div>
  </div>
</form>
