import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TripService } from '../../trips/trip.service';

@Component({
  selector: 'driver-note-dialog',
  templateUrl: './driver-note-dialog.component.html',
  styleUrls: ['./driver-note-dialog.component.scss'],
})
export class DriverNoteDialogComponent implements OnInit {
  @Input() tripId: string;
  @Input() note = '';
  @Input() noteProperty = 'reviewer_notes';
  @Input() disabled = false;

  newNote = '';
  maxlength = 255;

  constructor(
    public dialogRef: MatDialogRef<DriverNoteDialogComponent>,
    private tripService: TripService
  ) {}

  ngOnInit(): void {
    this.newNote = this.note || '';
  }

  onSave() {
    this.tripService
      .patchTrip(this.tripId, {
        [this.noteProperty]: this.newNote,
      })
      .subscribe(() => {
        this.dialogRef.close({
          tripId: this.tripId,
          note: this.newNote,
        });
      });
  }

  onClose() {
    this.dialogRef.close();
  }
}
