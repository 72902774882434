"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var trip_decision_1 = require("./trip-decision");
var organization_1 = require("../organizations/organization");
var trip_1 = require("./trip");
var user_serializer_1 = require("../users/user.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TripDecisionSerializer = /** @class */ (function () {
    function TripDecisionSerializer() {
    }
    /**
    * @param  {any} json
    * @returns TripDecision
    */
    TripDecisionSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var tripDecision = new trip_decision_1.TripDecision();
        if (!json) {
            return tripDecision;
        }
        tripDecision.id = json.id;
        tripDecision.decidedAt = json.decidedAt;
        tripDecision.decisionStatus = json.decisionStatus;
        tripDecision.exportedAt = json.exportedAt;
        tripDecision.notes = json.notes;
        tripDecision.loading = json.loading;
        if (json.trip && typeof (json.trip) === 'object') {
            tripDecision.trip = new trip_1.Trip(__assign({}, json.trip, { exportedAt: json.exportedAt }));
        }
        else if (json.tripDecision) {
            tripDecision.trip = new trip_1.Trip({
                id: json.trip,
                exportedAt: json.exportedAt
            });
        }
        if (json.organization && typeof (json.organization) === 'object') {
            tripDecision.organization = new organization_1.Organization(json.organization);
        }
        else if (json.organization) {
            tripDecision.organization = new organization_1.Organization({
                id: json.organization
            });
        }
        if (json.decidedBy && typeof (json.decidedBy) === 'object') {
            tripDecision.decidedBy = new user_serializer_1.UserSerializer().fromJson(json.decidedBy);
        }
        else if (json.decidedBy) {
            tripDecision.decidedBy = new user_serializer_1.UserSerializer().fromJson({
                id: json.decidedBy
            });
        }
        return tripDecision;
    };
    /**
    * @param  {TripDecision} tripDecision
    * @returns any
    */
    TripDecisionSerializer.prototype.toJson = function (tripDecision) {
        var json = {
            id: tripDecision.id,
            decidedAt: tripDecision.decidedAt,
            decisionStatus: tripDecision.decisionStatus || 'pending',
            exportedAt: tripDecision.exportedAt,
            notes: tripDecision.notes,
            trip: tripDecision.trip && tripDecision.trip.id,
            organization: tripDecision.organization && tripDecision.organization.id,
            decidedBy: tripDecision.decidedBy && tripDecision.decidedBy.id,
        };
        return decamelizeKeysDeep(json);
    };
    return TripDecisionSerializer;
}());
exports.TripDecisionSerializer = TripDecisionSerializer;
