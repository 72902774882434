<form #expensePayType="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>How is this expense paid?</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <p translate>We'll use this information to label all of your expenses for {{ expense.customerOrganization.name }}</p>

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group payment-type" fxFlex="100">
          <label>
            <input type="radio"
                   name="payment_type"
                   [(ngModel)]="expense.paymentType"
                   value="check"
                   attr.data-label="{{ 'Check' | translate }}" />
          </label>
          <label>
            <input type="radio"
                   name="payment_type"
                   [(ngModel)]="expense.paymentType"
                   value="direct_deposit"
                   attr.data-label="{{ 'Direct Deposit' | translate }}" />
          </label>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default" (click)="dialogRef.close(); false" translate>
      Cancel
    </button>
    <button class="btn btn-primary"
            [disabled]="!expensePayType.form.valid || expensePayType.form.pristine || loading"
            (click)="submit(); false"
            translate>
      Save
    </button>
  </div>
</form>
