'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var RuckitButtonComponent = /** @class */ (function () {
    function RuckitButtonComponent() {
        this.isActive = false;
        this.isDisabled = false;
        this.fullWidth = false;
        this.type = 'primary';
        this.loading = false;
    }
    RuckitButtonComponent.prototype.focusSection = function (event) {
        event.preventDefault();
        // set flag to be used later when
        // $locationChangeSuccess calls openPage()
        // controller.autoFocusContent = true;
    };
    RuckitButtonComponent.prototype.setClasses = function () {
        var _a;
        return _a = {
                active: this.isActive,
                disabled: this.isDisabled,
                'full-width': this.fullWidth
            },
            _a[this.type] = this.type,
            _a['btn-' + this.type] = true,
            _a.loading = this.loading,
            _a;
    };
    return RuckitButtonComponent;
}());
exports.RuckitButtonComponent = RuckitButtonComponent;
