"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var punch_card_decision_1 = require("./punch-card-decision");
var organization_1 = require("../organizations/organization");
var punch_card_1 = require("./punch-card");
var user_serializer_1 = require("../users/user.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PunchCardDecisionSerializer = /** @class */ (function () {
    function PunchCardDecisionSerializer() {
    }
    /**
    * @param  {any} json
    * @returns PunchCardDecision
    */
    PunchCardDecisionSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var punchCardDecision = new punch_card_decision_1.PunchCardDecision();
        if (!json) {
            return punchCardDecision;
        }
        punchCardDecision.id = json.id;
        punchCardDecision.decidedAt = json.decidedAt;
        punchCardDecision.decisionStatus = json.decisionStatus;
        punchCardDecision.notes = json.notes;
        punchCardDecision.loading = json.loading;
        if (json.punchcard && typeof (json.punchcard) === 'object') {
            punchCardDecision.punchcard = new punch_card_1.PunchCard(json.punchcard);
        }
        else if (json.punchCardDecision) {
            punchCardDecision.punchcard = new punch_card_1.PunchCard({
                id: json.punchCard
            });
        }
        if (json.organization && typeof (json.organization) === 'object') {
            punchCardDecision.organization = new organization_1.Organization(json.organization);
        }
        else if (json.organization) {
            punchCardDecision.organization = new organization_1.Organization({
                id: json.organization
            });
        }
        if (json.decidedBy && typeof (json.decidedBy) === 'object') {
            punchCardDecision.decidedBy = new user_serializer_1.UserSerializer().fromJson(json.decidedBy);
        }
        else if (json.decidedBy) {
            punchCardDecision.decidedBy = new user_serializer_1.UserSerializer().fromJson({
                id: json.decidedBy
            });
        }
        return punchCardDecision;
    };
    /**
    * @param  {PunchCardDecision} punchCardDecision
    * @returns any
    */
    PunchCardDecisionSerializer.prototype.toJson = function (punchCardDecision) {
        var json = {
            id: punchCardDecision.id,
            decidedAt: punchCardDecision.decidedAt,
            decisionStatus: punchCardDecision.decisionStatus,
            notes: punchCardDecision.notes,
            punchcard: punchCardDecision.punchcard && punchCardDecision.punchcard.id,
            organization: punchCardDecision.organization && punchCardDecision.organization.id,
            decidedBy: punchCardDecision.decidedBy && punchCardDecision.decidedBy.id,
        };
        return decamelizeKeysDeep(json);
    };
    return PunchCardDecisionSerializer;
}());
exports.PunchCardDecisionSerializer = PunchCardDecisionSerializer;
