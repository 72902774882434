"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CdAutocompleteComponent = /** @class */ (function () {
    function CdAutocompleteComponent() {
        this.autocompleteList = [];
        this.lastSelected = {};
        this.show = false;
        this.onChanged = new core_1.EventEmitter();
    }
    Object.defineProperty(CdAutocompleteComponent.prototype, "data", {
        get: function () {
            return this.autocompleteList;
        },
        set: function (data) {
            if (data && data.length > 0) {
                this.show = true;
            }
            else {
                this.lastSelected = {};
                // this.show = false;
            }
            this.autocompleteList = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CdAutocompleteComponent.prototype, "hide", {
        set: function (hide) {
            if (hide) {
                this.show = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    CdAutocompleteComponent.prototype.addValue = function (value) {
        this.onChanged.emit(value.id);
        this.lastSelected = value;
    };
    return CdAutocompleteComponent;
}());
exports.CdAutocompleteComponent = CdAutocompleteComponent;
