<form #voidTicket="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Void Ticket #{{ ticket.number }}</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group void-reason" fxFlex="100">
          <label>What is the reason for voiding this ticket?</label>
          <textarea [(ngModel)]="model.voidReason" name="void_reason"></textarea>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-default" (click)="dialogRef.close()">
      Cancel
    </button>
    <button class="btn btn-primary" (click)="submit(); false">
      Void
    </button>
  </div>
</form>
