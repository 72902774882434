<form #inviteEmployee="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Report Type</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <p translate>Use the dropdown below to select between internal or external fleet.</p>
    <div class="field-group full-width">
      <dropdown #typeSelect
                title="Select Report Type"
                [config]="typeDropdownConfig"
                [options]="typeOptions"
                (onSelect)="selectedOption = $event"></dropdown>
    </div>
    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="submit()"
            translate>
      Continue
    </button>
  </div>
</form>
