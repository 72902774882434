<my-toolbar [logo]=false [userDropdown]=false [menuButton]=false title="Job Overview" color="ruckit-green"></my-toolbar>

<div class="job-overview" *ngIf="!loading">
  <div class="no-results" *ngIf="!loading && !marketplace" fxLayout="column" fxLayoutAlign="start center">
    <div class="icon"><i class="icon-projects"></i></div>
    <h2>No Job Found</h2>
    <p>No job was found with this ID.</p>
  </div>

  <div class="job-detail" *ngIf="marketplace && marketplace.job">
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="job-name">{{ marketplace.job.name || '&nbsp;' }}</div>
    </div>
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="job-date">{{ marketplace.dateRange || '&nbsp;' }}</div>
      <div class="invoice-rate currency">{{ (marketplace.haulRate || 0 | currency:'USD':'symbol':'1.2-4') }} / {{ marketplace.job.haulRateUnit || '&nbsp;' | titleCase }}</div>
    </div>
    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="schedule">{{ marketplace.shift1StartTime || '&nbsp;' }} - {{ marketplace.endTime || '&nbsp;' }}</div>
      <div class="material">{{ marketplace.job.material || '&nbsp;' }}</div>
    </div>
  </div>

  <div class="extended-job-detail" *ngIf="marketplace && marketplace.job">
    <div class="field-row route-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="route">
        <img [src]="marketplace.job.mapImageURLWide" *ngIf="marketplace.job.mapImageURLWide" />
      </div>
    </div>

    <div class="field-row locations-row" fxLayout="row" fxLayoutAlign="space-between flex-start" fxFlex="100">
      <div class="loading">
        <h3>1. LOADING</h3>
        <div class="address" [innerHTML]="marketplace.startLocation"></div>
      </div>

      <div class="distance">
        <div class="distance-content">
          <ng-container *ngIf="(userPreferences$ | async) && (userPreferences$ | async).blob['distanceUnit'] === 'kilometers'; else distanceInMiles">
            {{ (marketplace.job.distanceInKilometers || 0 | number:'1.1-2') || '0.0' }} km
          </ng-container>
          
          <ng-template #distanceInMiles>
            {{ (marketplace.job.distanceInMiles | number:'1.1-2') || '0.0' }} mi
          </ng-template>
        </div>
      </div>

      <div class="unloading">
        <h3>2. UNLOADING</h3>
        <div class="address right" [innerHTML]="marketplace.endLocation"></div>
      </div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="job-details">Job Details</div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="centered estimated-loads label"><i class="icon-material"></i> Estimated Loads</div>
      <div class="centered estimated-loads value">{{ marketplace.estimatedNumberOfLoads }}</div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="centered trucks label"><i class="icon-truck"></i> Requested Trucks</div>
      <div class="centered trucks value">{{ marketplace.marketNumTrucks }}</div>
    </div>

    <div class="field-row notes-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100" *ngIf="marketplace.notes">
      <div class="notes" (click)="displayNotes = !displayNotes">VIEW NOTES</div>
      <div class="notes-data" *ngIf="displayNotes">{{ marketplace.notes }}</div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="dispatch-requests">Dispatch Requests</div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="centered delivery-target label"><i class="icon-material"></i> Daily Delivery Target</div>
      <div class="centered delivery-target value">{{ marketplace.dailyDeliveryTarget || 'None' }}</div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="centered requested-trucks label"><i class="icon-truck"></i> Requested Truck Types</div>
      <div class="centered requested-trucks value">{{ marketplace.truckTypeNames }}</div>
    </div>

    <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div class="centered delivery-interval label"><i class="icon-clock"></i> Delivery Interval</div>
      <div class="centered delivery-interval value">{{ marketplace.deliveryInterval || 'None' }}</div>
    </div>

    <div class="field-row requirements-row" fxLayout="row" fxLayoutAlign="space-between" fxFlex="100">
      <div class="loading-requirements">
        <h3>Loading Requirements</h3>
        <div class="options">
          <div *ngFor="let option of marketplace.checkinOptions" class="centered option">
            <i class="{{option}}"></i>
            {{ option | titleCase }}
          </div>
        </div>
      </div>

      <div class="unloading-requirements">
        <h3>Unloading Requirements</h3>
        <div class="options">
          <div *ngFor="let option of marketplace.checkoutOptions" class="centered option">
            <i class="{{option}}"></i>
            {{ option | titleCase }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <notification *ngFor="let error of errors">{{error}}</notification>
  <my-loader *ngIf="loading" [ngClass]="{'full-height': !marketplace}"></my-loader>

  <div class="actions">
    <button type="submit" [disabled]="loading" class="btn btn-primary" (click)="acceptJob()" [ngClass]="{'loading':loading}">ACCEPT JOB</button>
  </div>
</div>
