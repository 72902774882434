<item-grid
  *ngIf="jobEventList && loadingProgress === 100"
  [itemGroups]="filteredJobEventList"
  [itemClassNames]="generateItemClassNames"
  [displayKeys]="displayKeys"
  [searchProps]="displayKeys"
  [groupByOptions]="groupByOptions"
  [activeGroupBy]="activeGroupBy"
  (groupByChange)="selectGroupBy($event)"
  [(selectedItems)]="selectedJobEvents"
>
  <div header-stats>
    {{ jobEventCount.available + ' Jobs' | translate }}
  </div>
</item-grid>

<div class="grid-loader" *ngIf="!jobEventList || loadingProgress < 100">
  <h2 translate>Getting Full Job Event List</h2>
  <h3 translate>This may take longer depending on your Job amount</h3>
  <div class="loading-bar">
    <span class="text">{{ loadingProgress + '% Loaded' | translate }}</span>
    <div class="progress" [ngStyle]="{'width': loadingProgress + '%'}"></div>
  </div>
</div>
