import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { find as _find } from 'lodash';
import { Truck } from '../../trucks/truck';
import { Assignment } from '../../assignments/assignment';
import { TruckService } from '../../trucks/truck.service';
import { DriverService } from '../../drivers/driver.service';
import { parseErrors } from '../../shared/api.service';
import { DropdownComponent } from '../../shared';
import { DRIVER_DUTY_OPTIONS } from '../../drivers/driver';

type AssignmentError = {
  errors: any,
  assignment: Assignment
};

@Component({
  selector: 'assignment-truck-management-dialog',
  templateUrl: './assignment-truck-management-dialog.component.html',
  styleUrls: ['./assignment-truck-management-dialog.component.scss']
})
export class AssignmentTruckManagementDialogComponent implements OnInit {
  assignmentErrors: AssignmentError[];
  loading = false;
  errors = [];
  callback: (assignments: Assignment[]) => void;
  refreshGrid: () => void;
  @ViewChild('dutyStatusDropdown', { static: true }) dutyStatusDropdown: DropdownComponent;
  dutyStatusConfig = { nameProperty: 'name' };
  dutyStatusOptions = DRIVER_DUTY_OPTIONS;
  trucksDropdownConfig = {
    small: true,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'name',
    service: TruckService,
    query: { ordering: 'name' },
    includeFullObject: true
  };
  noTrucksDropdownConfig = {
    ...this.trucksDropdownConfig,
    initialLoad: true
  };
  jobEventTruckTypes = '';
  visibleFields: 'truckType' | 'noTruck' | 'both';

  constructor(
    private driverService: DriverService,
    public dialogRef: MatDialogRef<AssignmentTruckManagementDialogComponent>
  ) {}

  ngOnInit(): void {
    if (this.assignmentErrors) {
      this.jobEventTruckTypes = this.assignmentErrors[0].assignment.jobevent.truckTypes.map(t => (t.name)).join(', ');
      let errorText = this.assignmentErrors.map(a => (
        Object.keys(a.errors).map(key => (a.errors[key])).join(', ')
      )).join(', ').toLowerCase();
      this.visibleFields = errorText.includes('truck type') && errorText.includes('field is required') ? 'both' :
        errorText.includes('truck type') && !errorText.includes('field is required') ? 'truckType' : 'noTruck';
    }
  }

  selectTruck(error: AssignmentError, truck: Truck) {
    if (!error.assignment.truck || error.assignment.truck.id !== truck.id) {
      this.driverService.assign(Object.assign(error.assignment.driver, { truck: truck })).subscribe((res) => {
        this.assignmentErrors[
          this.assignmentErrors.findIndex(a => (a.assignment.driver.id === error.assignment.driver.id))
        ].assignment['truck'] = truck;
        error.assignment.driver.truckDriverDisplayName = (
          res.dutyStatus === 'on-duty' ? truck.name : 'Off Duty'
          ) + ' - ' + res.name;
        this.refreshGrid();
      }, (err) => {
        this.errors = parseErrors(err);
      });
    }
  }

  updateDutyStatus(error: AssignmentError, status: 'on-duty' | 'off-duty') {
    this.driverService.save({ id: error.assignment.driver.id,  dutyStatus: status }).subscribe((res) => {
      this.assignmentErrors[
        this.assignmentErrors.findIndex(a => (a.assignment.driver.id === error.assignment.driver.id))
      ].assignment['dutyStatus'] = status;
      this.refreshGrid();
    }, (err) => {
      this.errors = parseErrors(err);
    });
  }

  submit(): void {
    this.callback(this.assignmentErrors.map(e => (e.assignment)));
    this.dialogRef.close();
  }

  configSetup(config: any, error: AssignmentError): any {
    return Object.assign(config, {
      query: {
        ordering: 'name',
        carrier: error &&
          error.assignment &&
          error.assignment.driver &&
          error.assignment.driver.carrier &&
          error.assignment.driver.carrier.id
      }
    });
  }
}
