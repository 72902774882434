"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var assignment_service_1 = require("./assignment.service");
var driver_service_1 = require("../drivers/driver.service");
var dialogs_1 = require("../shared/dialogs");
var CopyAssignmentsDialogComponent = /** @class */ (function () {
    function CopyAssignmentsDialogComponent(dialog, dialogRef, assignmentService, driverService, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.assignmentService = assignmentService;
        this.driverService = driverService;
        this.data = data;
        this.assignmentList = [];
        this.driversList = [];
        this.selectedAssignment = [];
        this.deselectedAssignments = [];
        this.remainingAssignments = [];
        this.allInitialAssignments = [];
        this.selectedDrivers = [];
        this.displayAssignmentName = [];
        this.copyAssignmentObj = [];
        this.copyDriverObj = [];
        this.errors = [];
        this.loading = false;
        this.assignmentDropdownConfig = {
            nameProperty: 'name',
            searchable: false,
            group: false,
            loadingOptions: false,
            multiselect: true
        };
        this.driverDropdownConfig = {
            nameProperty: 'name',
            searchable: false,
            group: false,
            loadingOptions: false,
            multiselect: true
        };
        this.allAssignmentSelected = false;
        this.showAllAssignments = false;
        this.deselectedAssignment = false;
    }
    CopyAssignmentsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getAssignmentsList();
        this.getDriversList();
        this.showAllAssignments = true;
        if (this.showAllAssignments) {
            this.assignmentList.forEach(function (assign) {
                _this.copyAssignmentObj.push(assign.id);
            });
        }
    };
    /**
     * fetch the list of assignments for a selected driver
     * this list is used to display assignments in dropdown
     */
    CopyAssignmentsDialogComponent.prototype.getAssignmentsList = function () {
        if (this.data && this.data.selectedItem) {
            this.assignmentList = this.data.selectedItem.assignments;
            this.allInitialAssignments = this.assignmentList.slice();
            // this.assignmentList.forEach(assignment => {
            //   this.displayAssignmentName.push({ name: assignment.job.name, id: assignment.id });
            // });
        }
    };
    /**
     * fetch the list of all the drivers available in the fleet.
     * this list is used to display drivers in dropdown
     */
    CopyAssignmentsDialogComponent.prototype.getDriversList = function () {
        var _this = this;
        this.driverService.list().subscribe(function (drivers) {
            _this.driversList = drivers.filter(function (driver) { return driver.truck; });
        });
    };
    CopyAssignmentsDialogComponent.prototype.allSelectAssignments = function (event) {
        var _this = this;
        this.allAssignmentSelected = event.target.checked;
        if (this.allAssignmentSelected) {
            this.assignmentList = this.allInitialAssignments.slice();
            this.assignmentList.forEach(function (assign) {
                _this.copyAssignmentObj.push(assign.id);
            });
        }
    };
    /**
     * @param  {} option
     * @returns void
     * stores the list of selected assignments
     * creates a list of id's for selected assignments to be copied
     */
    CopyAssignmentsDialogComponent.prototype.setSelectedAssignment = function (option) {
        var _this = this;
        this.selectedAssignment = option;
        this.selectedAssignment.forEach(function (res) {
            _this.copyAssignmentObj.push(res.id);
        });
    };
    CopyAssignmentsDialogComponent.prototype.deselectAssignment = function (assignment) {
        var _this = this;
        this.deselectedAssignment = true;
        if (this.assignmentList) {
            this.assignmentList.forEach(function (currentAssignment) {
                _this.assignmentList.splice(assignment, 1);
                _this.displayAssignmentName.push({ name: currentAssignment.job.name, id: currentAssignment.id });
            });
            this.remainingAssignments = this.assignmentList.slice();
            this.copyAssignmentObj = this.remainingAssignments.map(function (copiedAssignment) { return copiedAssignment.id; });
        }
    };
    /**
     * @param  {} option
     * @returns void
     * stores the list of selected drivers
     * creates a list of id's for selected drivers to be copied
     */
    CopyAssignmentsDialogComponent.prototype.setSelectedDriver = function (option) {
        var _this = this;
        this.selectedDrivers = option;
        this.selectedDrivers.forEach(function (res) {
            _this.copyDriverObj.push(res.id);
        });
    };
    /**
     * @param  {} event
     * @returns void
     * opens the dialog to confirm to copy the assignments to drivers
     * if yes, then makes a request to copy assignments
     */
    CopyAssignmentsDialogComponent.prototype.openConfirmDialog = function (event) {
        var _this = this;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
            disableClose: true
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Are you sure?',
            body: 'The selected drivers will be assigned to the selected assignment/s',
            close: 'Cancel',
            accept: 'Copy'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                var copyAssignmentPayload = {
                    assignments: _this.copyAssignmentObj,
                    drivers: _this.copyDriverObj
                };
                _this.assignmentService.copyAssignment(copyAssignmentPayload).subscribe(function (res) {
                    _this.dialogRef.close();
                    _this.callback();
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    return CopyAssignmentsDialogComponent;
}());
exports.CopyAssignmentsDialogComponent = CopyAssignmentsDialogComponent;
