"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TruckTypeNamesComponent = /** @class */ (function () {
    function TruckTypeNamesComponent(element) {
        this.element = element;
        this.anyType = false;
        this.countOnly = false;
        this.hover = false;
        this.names = '';
    }
    TruckTypeNamesComponent.prototype.ngOnInit = function () {
        if (this.anyType) {
            this.names = 'Any Type';
        }
        else {
            this.names = this.truckTypes.map(function (truckType) {
                return truckType.name;
            }).join(', ');
        }
    };
    TruckTypeNamesComponent.prototype.onMouseEnter = function () {
        this.popoverBottom = window.innerHeight - this.element.nativeElement.getBoundingClientRect().top;
        this.popoverLeft = this.element.nativeElement.getBoundingClientRect().left;
    };
    TruckTypeNamesComponent.prototype.ngAfterViewInit = function () {
        // position based on `ref`
    };
    return TruckTypeNamesComponent;
}());
exports.TruckTypeNamesComponent = TruckTypeNamesComponent;
