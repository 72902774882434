"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var driver_serializer_1 = require("./../drivers/driver.serializer");
var truck_serializer_1 = require("../trucks/truck.serializer");
var job_event_1 = require("../job-events/job-event");
var resizer_1 = require("../images/resizer");
var lodash_1 = require("lodash");
var moment = require("moment");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var PunchCard = /** @class */ (function () {
    function PunchCard(punchCardInfo) {
        this.selected = false;
        this.loading = false;
        delete punchCardInfo['duration'];
        punchCardInfo = camelcaseKeysDeep(punchCardInfo);
        this.id = punchCardInfo.id;
        this.startTimeTimestamp = punchCardInfo.startTime;
        this.endTimeTimestamp = punchCardInfo.endTime;
        if (punchCardInfo.driver) {
            this.driver = new driver_serializer_1.DriverSerializer().fromJson(punchCardInfo.driver);
        }
        if (punchCardInfo.truck) {
            this.truck = (new truck_serializer_1.TruckSerializer().fromJson(punchCardInfo.truck));
        }
        if (punchCardInfo.jobevent) {
            this.jobEvent = new job_event_1.JobEvent(punchCardInfo.jobevent);
        }
        this.assignment = punchCardInfo.assignment;
        this.ticketImage = punchCardInfo.ticketImage;
        this.ticketImageKey = punchCardInfo.ticketImageKey;
        this.ticketNumber = punchCardInfo.ticketNumber;
        this.startLocation = punchCardInfo.startLocation;
        this.endLocation = punchCardInfo.endLocation;
        this.haulTotal = punchCardInfo.haulTotal;
        this.void = punchCardInfo.void;
        this.edited = punchCardInfo.edited;
        this.completed = punchCardInfo.completed;
        this.job = punchCardInfo.job ? punchCardInfo.job : null;
        this.approvedByName = punchCardInfo.approvedByName ? punchCardInfo.approvedByName : null;
        this.approved = punchCardInfo.approved ? punchCardInfo.approved : null;
        this.decision = punchCardInfo.decision ? punchCardInfo.decision : null;
        this.latestDecision = punchCardInfo.latestDecision ? punchCardInfo.latestDecision : null;
        this.latestDecisionStatus = punchCardInfo.latestDecisionStatus ? punchCardInfo.latestDecisionStatus : null;
        this.latestDecider = punchCardInfo.latestDecider ? punchCardInfo.latestDecider : null;
        this.latestDeciderName = punchCardInfo.latestDeciderName ? punchCardInfo.latestDeciderName : null;
        var values = [];
        if (this.jobEvent && this.jobEvent.job) {
            values.push(this.jobEvent.job.name);
        }
        if (this.driver) {
            var value = this.driver.name;
            if (this.truck) {
                value = value + " (" + this.truck.name + ")";
            }
            values.push(value);
        }
        this.name = values.filter(Boolean).join(' - ');
    }
    PunchCard.prototype.formatDates = function () {
        var d;
        if (this._endTimeTimestamp) {
            d = moment.duration(moment(this._endTimeTimestamp).diff(this._startTimeTimestamp));
        }
        else {
            d = moment.duration(moment().diff(this.startTimeTimestamp));
        }
        this._duration = d.format('D[ days], H[ hrs], m[ mins]');
        if (this._startTimeTimestamp) {
            this._startDate = new Date(this._startTimeTimestamp);
            this._startTime = moment(this._startDate).format('h:mm a');
        }
        if (this._endTimeTimestamp) {
            this._endDate = new Date(this._endTimeTimestamp);
            this._endTime = moment(this._endDate).format('h:mm a');
        }
    };
    Object.defineProperty(PunchCard.prototype, "startTimeTimestamp", {
        get: function () {
            return this._startTimeTimestamp;
        },
        set: function (value) {
            this._startTimeTimestamp = value;
            this.formatDates();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "endTimeTimestamp", {
        get: function () {
            return this._endTimeTimestamp;
        },
        set: function (value) {
            this._endTimeTimestamp = value;
            this.formatDates();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "duration", {
        get: function () {
            return this._duration;
        },
        set: function (value) {
            this._duration = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "startTime", {
        get: function () {
            return this._startTime;
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.startTimeTimestamp = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "endTime", {
        get: function () {
            return this._endTime;
        },
        set: function (value) {
            if (value) {
                var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
                var date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
                var parsedDate = moment(date + ' ' + value, pattern);
                this.endTimeTimestamp = parsedDate.toISOString();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "startDate", {
        get: function () {
            return this._startDate;
        },
        set: function (value) {
            var _value = moment(value).format('YYYY-MM-DD');
            this.startTimeTimestamp = moment(_value + ' ' + this.startTime, 'YYYY-MM-DD h:mm a').toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "endDate", {
        get: function () {
            return this._endDate;
        },
        set: function (value) {
            if (value) {
                var _value = moment(value).format('YYYY-MM-DD');
                this.endTimeTimestamp = moment(_value + ' ' + this.endTime, 'YYYY-MM-DD h:mm a').toISOString();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "administrativeStatus", {
        get: function () {
            if (this.void) {
                return 'void';
            }
            else if (this.edited) {
                return 'edited';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus && this.administrativeStatus.length) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "hasTicketImage", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "ticketImageRequired", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "editTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.ticketImageKey, 190, 254);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchCard.prototype, "fullsizeTicketImage", {
        get: function () {
            if (this.ticketImage && this.ticketImageKey) {
                return resizer_1.ImageResizer.getFullsizeUrl(this.ticketImageKey);
            }
        },
        enumerable: true,
        configurable: true
    });
    PunchCard.prototype.url = function (action) {
        return '/punchcards/' + this.id + '/' + action;
    };
    PunchCard.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.void) {
            var _option = lodash_1.find(options, { action: 'unvoid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return PunchCard;
}());
exports.PunchCard = PunchCard;
