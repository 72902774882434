"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_serializer_1 = require("../users/user.serializer");
var carrier_1 = require("../carriers/carrier");
var tag_serializer_1 = require("../tags/tag.serializer");
var moment = require("moment");
// constants
var app_constants_1 = require("../app.constants");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Organization = /** @class */ (function () {
    function Organization(organizationInfo) {
        var _this = this;
        this.hasCopyAssignment = false;
        organizationInfo = camelcaseKeysDeep(organizationInfo);
        this.id = organizationInfo.id;
        if (organizationInfo.name || organizationInfo.name === '') {
            this.name = organizationInfo.name;
        }
        else {
            this.name = organizationInfo.carrier && organizationInfo.carrier.name;
        }
        this.email = organizationInfo.email;
        this.image = organizationInfo.image;
        this.contactName = organizationInfo.contactName;
        if (organizationInfo.primaryContact && typeof (organizationInfo.primaryContact) === 'object') {
            this.primaryContact = (new user_serializer_1.UserSerializer).fromJson(organizationInfo.primaryContact);
        }
        else if (organizationInfo.primaryContact) {
            this.primaryContact = (new user_serializer_1.UserSerializer).fromJson({ id: organizationInfo.primaryContact });
        }
        else {
            this.primaryContact = (new user_serializer_1.UserSerializer).fromJson({});
        }
        // NOTE: tags are undefined
        if (organizationInfo.tags && typeof (organizationInfo.tags) === 'object') {
            this.tags = new tag_serializer_1.TagSerializer().fromJson(organizationInfo.tags);
        }
        this.createdAt = organizationInfo.createdAt;
        this.lastModified = organizationInfo.lastModified;
        this.street = organizationInfo.street;
        this.address2 = organizationInfo.address2;
        this.city = organizationInfo.city;
        this.state = organizationInfo.state;
        this.inviteCode = organizationInfo.inviteCode;
        this.zipcode = organizationInfo.zipcode;
        this.phoneNumber = organizationInfo.phoneNumber;
        this.faxNumber = organizationInfo.faxNumber;
        this.paymentTerms = organizationInfo.paymentTerms;
        this.country = organizationInfo.country;
        if (organizationInfo.billingContact) {
            this.billingContact = (new user_serializer_1.UserSerializer).fromJson(organizationInfo.billingContact);
        }
        this.billingAddress1 = organizationInfo.billingAddress1;
        this.billingAddress2 = organizationInfo.billingAddress2;
        this.billingCity = organizationInfo.billingCity;
        this.billingState = organizationInfo.billingState;
        this.billingCountry = organizationInfo.billingCountry;
        this.billingZipcode = organizationInfo.billingZipcode;
        this.billingPhoneNumber = organizationInfo.billingPhoneNumber;
        this.billingEmail = organizationInfo.billingEmail;
        this.broker = organizationInfo.broker;
        if (organizationInfo.carrier) {
            this.carrier = new carrier_1.Carrier(organizationInfo.carrier);
        }
        this.contractor = organizationInfo.contractor;
        this.startingInvoiceNumber = organizationInfo.startingInvoiceNumber;
        this.remittanceInfo = organizationInfo.remittanceInfo;
        this.driverFleetCanViewRates = organizationInfo.driverFleetCanViewRates;
        this.driverFleetQuickTicket = organizationInfo.driverFleetQuickTicket;
        this.activeTrackingEnabled = organizationInfo.activeTrackingEnabled;
        this.canCreateJobs = organizationInfo.canCreateJobs;
        this.invoiceablePunchcardCount = organizationInfo.invoiceablePunchcardCount;
        this.invoiceableTripCount = organizationInfo.invoiceableTripCount;
        this.posEnabled = organizationInfo.posEnabled;
        this.isCrh = organizationInfo.isCrh;
        this.advBillingEnabled = organizationInfo.advBillingEnabled;
        this.allDriversEnabled = organizationInfo.allDriversEnabled;
        this.driverFleetCanViewOrderDetails = organizationInfo.driverFleetCanViewOrderDetails;
        this.leasedOrg = organizationInfo.leasedOrg;
        this.hasLeasedOrgs = organizationInfo.hasLeasedOrgs;
        this.hasTrackables = organizationInfo.features && organizationInfo.features['hasTrackables'];
        this.hasRuckit3 = organizationInfo.features && organizationInfo.features['hasRuckit3'];
        this.startingTicketNumber = organizationInfo.startingTicketNumber;
        this.dvirRequired = organizationInfo.dvirRequired;
        this.driverFleetRequireAssignmentConfirmation = organizationInfo.driverFleetRequireAssignmentConfirmation;
        this.avoidTolls = organizationInfo.avoidTolls;
        this.uniqueBillingId = organizationInfo.uniqueBillingId;
        this.qrEnabled = organizationInfo.qrEnabled;
        this.hasCopyAssignment = organizationInfo.hasCopyAssignment;
        this.hasWorkOrder = organizationInfo.hasWorkOrder;
        this.workOrderDbaName = organizationInfo.workOrderDbaName;
        this.workOrderCompanyType = organizationInfo.workOrderCompanyType;
        this.features = organizationInfo.features;
        this.defaultAverageLoadingTime = organizationInfo.defaultAverageLoadingTime;
        this.defaultAverageUnloadingTime = organizationInfo.defaultAverageUnloadingTime;
        this.shiftMonitorAutoCloseInterval = organizationInfo.shiftMonitorAutoCloseInterval;
        this.shiftMonitorAutoClose = organizationInfo.shiftMonitorAutoClose;
        this.senderEmail = organizationInfo.senderEmail;
        this.senderName = organizationInfo.senderName;
        // ab5 fields
        this.dotNumber = organizationInfo.dotNumber;
        this.mcNumber = organizationInfo.mcNumber;
        this.dirNumber = organizationInfo.dirNumber;
        this.einNumber = organizationInfo.einNumber;
        this.isAb5Compliant = organizationInfo.isAb5Compliant;
        this.ab5SubmittedDate = organizationInfo.ab5SubmittedDate;
        this.ab5SubmittedBy = organizationInfo.ab5SubmittedBy;
        this.insuranceDocument = organizationInfo.insuranceDocument;
        this.insuranceExpirationDate = organizationInfo.insuranceExpirationDate;
        this.overweightPermitExpirationDate = organizationInfo.overweightPermitExpirationDate;
        this.overweightPermit = organizationInfo.overweightPermit;
        this.businessLicense = organizationInfo.businessLicense;
        this.attachments = organizationInfo.attachments;
        // attachments backward compatibility
        if (organizationInfo.attachments && organizationInfo.attachments.some(function (a) { return a.fileType === app_constants_1.ORGANIZATIONIMAGETYPE; })) {
            this.image = organizationInfo.attachments.find(function (a) { return a.fileType === app_constants_1.ORGANIZATIONIMAGETYPE; }).file;
        }
        if (organizationInfo.attachments && organizationInfo.attachments.some(function (a) { return a.fileType === app_constants_1.ORGANIZATIONINSURANCE; })) {
            this.insuranceDocument = organizationInfo.attachments.find(function (a) { return a.fileType === app_constants_1.ORGANIZATIONINSURANCE; }).file;
        }
        if (organizationInfo.attachments && organizationInfo.attachments.some(function (a) { return a.fileType === app_constants_1.ORGANIZATIONBUSINESSLICENSE; })) {
            this.businessLicense = organizationInfo.attachments.find(function (a) { return a.fileType === app_constants_1.ORGANIZATIONBUSINESSLICENSE; }).file;
        }
        if (organizationInfo.attachments && organizationInfo.attachments.some(function (a) { return a.fileType === app_constants_1.ORGANIZATIONOVERWEIGHTPERMIT; })) {
            this.overweightPermit = organizationInfo.attachments.find(function (a) { return a.fileType === app_constants_1.ORGANIZATIONOVERWEIGHTPERMIT; }).file;
        }
        // viewpoint fields
        this.viewpointCompanyNumber = organizationInfo.viewpointCompanyNumber;
        this.viewpointJcco = organizationInfo.viewpointJcco;
        this.lafargeRegion = organizationInfo.lafargeRegion;
        this.defaultRoundTripTime = organizationInfo.defaultRoundTripTime;
        if (organizationInfo.defaultRoundTripTime) {
            var roundTripTimeMinutes = moment.duration(organizationInfo.defaultRoundTripTime).asMinutes();
            if (roundTripTimeMinutes) {
                this.defaultRoundTripMinutes = roundTripTimeMinutes;
            }
        }
        this.defaultYardPrepTime = organizationInfo.defaultYardPrepTime;
        if (organizationInfo.defaultYardPrepTime) {
            var yardPrepTimeMinutes = moment.duration(organizationInfo.defaultYardPrepTime).asMinutes();
            if (yardPrepTimeMinutes) {
                this.defaultYardPrepMinutes = yardPrepTimeMinutes;
            }
        }
        this.defaultYardBufferTime = organizationInfo.defaultYardBufferTime;
        if (organizationInfo.defaultYardBufferTime && parseFloat(organizationInfo.defaultYardBufferTime) > 0) {
            this.defaultYardBufferMinutes = parseFloat(organizationInfo.defaultYardBufferTime) / 60;
        }
        this.defaultHaulRate = organizationInfo.defaultHaulRate;
        this.defaultHaulRateType = organizationInfo.defaultHaulRateType;
        if (this.features) {
            this.enabledFeatures = [];
            Object.keys(this.features).forEach(function (key) {
                if (_this.features[key]) {
                    _this.enabledFeatures.push(key);
                }
            });
        }
    }
    Object.defineProperty(Organization.prototype, "types", {
        get: function () {
            var values = [];
            if (this.carrier && this.carrier.id) {
                values.push('Carrier');
            }
            if (this.contractor && this.contractor.id) {
                values.push('Contractor');
            }
            if (this.broker && this.broker.id) {
                values.push('Broker');
            }
            return values.filter(function (v) {
                return (v !== '' && v !== null);
            }).join(' | ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Organization.prototype, "detail", {
        get: function () {
            var value = this.city + ', ' + this.state + '<br />';
            return value + this.types;
        },
        set: function (value) {
            this._detail = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Organization.prototype, "indentifier", {
        get: function () {
            return 'NEED DOT';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Organization.prototype, "location", {
        get: function () {
            var value = [this.city, this.state];
            return value.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Organization.prototype, "coordinates", {
        get: function () {
            return [30.271095, -97.728443];
        },
        enumerable: true,
        configurable: true
    });
    return Organization;
}());
exports.Organization = Organization;
