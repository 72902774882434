import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Driver } from '../driver';
import { DriverContextEvent } from '../driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'driver-infobox',
  templateUrl: './driver-infobox.component.html',
  styleUrls: ['./driver-infobox.component.scss'],
})
export class DriverInfoboxComponent {
  @Input() driver: Driver;
  @Input() createdAt: Date;
  @Input() date: Date;
  @Input() selectedMarker;
  contextMenuEventSubject = new Subject<DriverContextEvent>();

  constructor() {}

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId,
    });
  }
}
