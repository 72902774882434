import {
  Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef, SimpleChanges, OnDestroy
} from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment-timezone';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { cloneDeep, difference, find as _find } from 'lodash';

// services
import { DriverService } from '../../drivers/driver.service';
import { Driver } from '../../drivers/driver';
import { TagService } from '../../tags/tag.service';
import { ShiftReportService } from '../../shifts/shift.service';
import { AuthenticationService } from '../../shared/authentication.service';
import { ConnectionService } from '../../connections/connection.service';
import { parseErrors } from '../../shared/api.service';
import { PreferenceService } from '../../preferences/preference.service';
import { MapService } from '../../map/map.service';

// components
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { FiltersDialogComponent } from '../../shared/filters-dialog/filters-dialog.component';
import { DropdownConfig } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';
import { EditDriverComponent } from '../../drivers/driver-edit/edit-driver.component';
import { NewDriverDialogComponent } from '../../drivers/new-driver-dialog/new-driver-dialog.component';

// classes
import { TableData } from '../../shared/fancy-table/table.types';
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { Carrier } from '../../carriers/carrier';
import { Preference } from '../../preferences/preference';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';

// animations
import { editDriverAnimation } from '../../shared/animations/edit-driver.animation';

// constants
import { ViewDriverProfileAction } from '../../drivers/driver-context-menu/data/options';
import { AVAILABLECOLUMNS, DISPLAYEDCOLUMNS } from './columns';

@Component({
  selector: 'fleet-health',
  templateUrl: './fleet-health.component.html',
  styleUrls: ['./fleet-health.component.scss'],
  animations: [editDriverAnimation]
})
export class FleetHealthComponent implements OnInit, OnDestroy {

  driverList: Driver[];
  shiftsReq: Subscription;
  loadingProgress = 0;
  tableData: TableData;
  firstLoad = true;

  @Input() availableColumns = [...AVAILABLECOLUMNS(this.translationService)];
  @Input() displayedColumns = [...DISPLAYEDCOLUMNS];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() customClasses = 'drivers';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  errors = [];
  // config for fancy table
  tableConfig = {
    updateForConfigChanges: true,
    hasHeader: true,
    service: DriverService,
    preferenceKey: 'FleetHealthComponent-DriverService',
    query: { is_placeholder: 'False', user_tags: 'True'},
    collectionTitle: this.translationService.instant('Drivers'),
    noResultsText: this.translationService.instant('a driver'),
    sortBy: 'profile__first_name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
      { name: this.translationService.instant('View Driver Profile'), action: 'details', link: false, external: false }
    ],
    newRecordModal: () => this.addNewDriver()
  };
  preference: Preference;
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('driverTable', { static: false }) driverTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;
  filtersDialog: FiltersDialogComponent;

  @ViewChild(EditDriverComponent, { static: false }) editDrawer: EditDriverComponent;

  drivers: any = [];
  driver: any = {};
  driversForEdit = [];
  stateOptionsForModal = [];
  @Input() customerOnly = null;
  @Input() carrierId = '';
  @Input() query;
  @Input() customHeight;
  sortBy;
  sortAsc = true;
  drawerOpen = false;
  enabledFeatures: string[] = [];
  inviteDriverEnabled = false;
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  carriersReq: Subscription;
  carrierDropdownData: {
    carrier?: Carrier,
    carriers: Carrier[],
    config: any,
    loading: boolean
  } = {
    carriers: [],
    config: {
      nameProperty: 'name',
      searchable: true,
      loadingOptions: false
    },
    loading: false
  };
  primaryFilters = [];
  issuesSelected = false;
  onShiftSelected = false;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  // context menu
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  driverIdToOpenAssignments: string;
  viewDriverProfileAction = ViewDriverProfileAction;

  saveDriverCallback = () => {
    this.refreshTable();
  }

  inviteDriverCallback = () => {
    this.refreshTable();
  }

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private driverService: DriverService,
    private shiftReportService: ShiftReportService,
    private connectionService: ConnectionService,
    private translationService: TranslateService,
    private preferenceService: PreferenceService,
    private mapService: MapService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    if (this.enabledFeatures && this.enabledFeatures.includes('inviteDriver')) {
      this.inviteDriverEnabled = true;
    }

    this.tableConfig['query'] = this.query = {
      ...this.tableConfig['query'], ...this.query
    };
    this.allSubscriptionsToUnsubscribe.push(
      this.driverService.listAllProgress.subscribe(progress => {
        this.loadingProgress = Math.ceil(progress * 100);
      })
    );
    this.route.queryParams.forEach((params: Params) => {
      this.search = params['search'] || '';
      this.tableConfig['sortBy'] = 'profile__first_name';
    });

    this.getPreferencesAndCarriers();
  }

  ngAfterViewInit() {
    this.tableConfig['customHeight'] = this.customHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.driverTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
      this.driverTable.query = this.query;
      this.refreshTable();
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  clickAction(event) {
    if (event) { this.selectDriver(event, event[1]); }
  }

  /**
   * @param  {} e
   * @param  {} driver
   * This function would open a edit window for updating the driver
   */
  selectDriver(e, driver) {
    let target = e.target || e.srcElement || e.currentTarget;
    if (target && target.className && target.className.includes('action-menu-icon') ||
      target && target.type === 'checkbox') {
      // Do nothing
    } else {
      this.driver = cloneDeep(driver);
      if (this.editDrawer) {
        this.editDrawer.setOpen();
      }
    }
  }

  /**
   * @returns void
   * This function is called when edit is successfully completed
   * This would update the table with the changed fields
   */
   onEditComplete(): void {
    this.refreshTable();
  }

  unarchive(driver: Driver) {
    this.driverService.save({ id: driver.id, status: 'active' }).subscribe(() => {
      this.refreshTable();
    });
  }

  menuAction(name, driver) {
    switch (name) {
      case 'edit':
        setTimeout(() => {
          this.selectDriver(name, driver);
        }, 100);
        break;
      case 'details':
        if (driver && driver.id) {
          this.router.navigate(
            ['drivers', driver.id, 'details', 'details'],
            {
              queryParams: { returnTo: this.router.url },
            }
          );
        }
        break;
      case 'unarchive':
        this.unarchive(driver);
        break;
    }
  }

  isSelected(driver) {
    return this.driver && driver.id === this.driver.id;
  }

  openFilters(): void {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.filters = [
      {
        type: 'dropdown',
        field: 'tags',
        label: 'Markets',
        dropdownConfig: <DropdownConfig>{
          service: TagService,
          multiselect: true
        }
      },
      {
        type: 'text',
        field: 'truckName',
        label: 'Current Truck Search'
      },
      {
        type: 'text',
        field: 'cdl',
        label: 'CDL Search'
      }
    ];
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
      acc[filter.key] = filter.values;
      return acc;
    }, {}));
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      tags: 'tags',
      truckName: 'truck__name',
      cdl: 'cdl'
    };
    let falseyFilters = [];
    const newFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (filterRes[key]) {
        if (key === 'tags') {
          if (values && values.length > 0) {
            values = values.map(tag => tag.name).join(',');
            this.filterByTags(values);
            query[queryKeys[key]] = values;
          }
        } else {
          query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
        }
      }
      let filter = new FilterOption({
        filterType: 'text',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (!filter.values) { falseyFilters.push(filter); }
      return filter;
    });
    const modifiedFilters = newFilters.filter(f => f.key !== 'tags');
    this.appliedFilters = difference(modifiedFilters, falseyFilters);
  }

  filterByEvent(key): void {
    switch (key) {
      case 'on_shift':
        this.filterByShifts();
        break;

      case 'issues':
        this.filterByIssues();
        break;

      default:
        break;
    }
  }

  refreshTable() {
    this.driverTable.getRecords({ ...this.tableConfig['query'], ...this.query });
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *  Gets shift data for each driver if displayedColumns contains 'shift-status'
   *  Gets location update data for each driver if displayedColumns contains 'last-location-update'
   * 
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (columns.includes('shift-status') && ![...this.driverTable.displayedColumns].includes('shift-status')) {
      this.captureShiftStatus(this.tableData);
    }
    if (columns.includes('last-location-update') && ![...this.driverTable.displayedColumns].includes('last-location-update')) {
      this.getLocationUpdates(this.tableData);
    }
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = this.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  onRecordsLoaded(tableData: TableData = this.tableData) {
    this.tableData = tableData;
    this.driverTable.loading = false;
    this.driverList = tableData.data;
    const columns = [...this.driverTable.displayedColumns];
    if (columns.includes('shift-status')) {
      this.captureShiftStatus(this.tableData);
    }
    if (columns.includes('last-location-update')) {
      this.getLocationUpdates(this.tableData);
    }
    if (this.firstLoad && this.columnToggle) {
      this.firstLoad = false;
      this.columnToggle.displayedColumns = this.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  captureShiftStatus(tableData: TableData): void {
    tableData.data.forEach((driver: Driver) => {
      driver.loading = true;
      if (driver.activeShifts && driver.activeShifts.length > 0) {
        if (driver.activeShifts.length > 1) {
          // if multiple shifts, take the longest active one
          const longestDate = moment.min(
            driver.activeShifts.map((s) => moment(s.startTime))
          );
          const longestShift = driver.activeShifts.find(
            (d) =>
              moment(d.startTime).toISOString() === longestDate.toISOString()
          );
          this.displayShiftStatus(
            driver,
            longestShift.id,
            longestShift.startTime,
            longestShift.endTime
          );
        } else {
          const activeShift = driver.activeShifts[0];
          this.displayShiftStatus(
            driver,
            activeShift.id,
            activeShift.startTime,
            activeShift.endTime
          );
        }
        driver.loading = false;
      } else {
        // if no active shifts in driver.activeShifts array - fetch API
        this.shiftsReq = this.shiftReportService
          .list({
            driver: driver.id,
            page_size: 1,
            ordering: '-end_time',
          })
          .subscribe(
            (shifts) => {
              if (shifts && shifts.length) {
                const shift = shifts[0];
                this.displayShiftStatus(
                  driver,
                  shift.id,
                  shift.startTime,
                  shift.endTime
                );
              } else {
                driver.displayShiftStatus =
                  this.translationService.instant('No Shifts');
              }
            },
            (err) => {
              console.log(err);
            },
            () => (driver.loading = false)
          );
      }
    });
  }

  displayShiftStatus(
    driver: Driver,
    shiftId: string,
    startTime: string,
    endTime: string
  ) {
    driver.lastShiftId = shiftId;
    const totalHours = moment
      .duration(moment(endTime ? endTime : undefined).diff(startTime))
      .asHours();
    if (!endTime) {
      if (totalHours > 8) {
        driver.displayShiftStatus = this.translationService.instant(
          'HAS ACTIVE SHIFT of {{ hours }} Hours',
          { hours: totalHours.toFixed(2) }
        );
      } else {
        driver.displayShiftStatus =
          this.translationService.instant('HAS ACTIVE SHIFT');
      }
    } else if (totalHours > 8) {
      driver.displayShiftStatus = this.translationService.instant(
        '{{ hours }} Hours',
        { hours: totalHours.toFixed(2) }
      );
    } else {
      driver.displayShiftStatus = this.translationService.instant(
        'Ended {{ endTime }}',
        { endTime: moment(endTime).format('MM/DD/YYYY h:mm a') }
      );
    }
  }

  getLocationUpdates(tableData: TableData): void {
    tableData.data.forEach((driver: Driver) => {
      driver.lastLocationUpdateLoading = true;
      this.mapService
        .getLocationUpdates({
          driver: driver.id,
          ordering: 'date',
        })
        .subscribe(
          (updates) => {
            if (updates.locationUpdates && updates.locationUpdates.length) {
              driver.lastLocationUpdate = updates.locationUpdates[0];
            }
          },
          () => {}, // error
          () => {
            // finally
            driver.lastLocationUpdateLoading = false;
          }
        );
    });
  }

  getCarriers(query = {}) {
    return this.connectionService
      .list({
        ordering: 'organization__name',
        is_carrier: 'True',
        allow_dispatch: 'True',
        ...query,
      })
      .pipe(
        map((connections) => {
          const defaultCarriers = [
            <Carrier>{ name: 'All Carriers', id: 'all_carriers' },
            <Carrier>{ name: 'My Drivers', id: 'my_drivers' },
            <Carrier>{ name: 'Leased', id: 'all_leased' }
          ];
          const carriers = connections.map(
            (connection) =>
              <Carrier>{
                name: connection.organization.name,
                id: connection.organization.carrier.id,
              }
          );
          return [...defaultCarriers, ...carriers];
        })
      );
  }

  dropdownSearch(term): void {
    this.getCarriers({ search: term }).subscribe(carriers => {
      this.carrierDropdownData.carriers = carriers;
    });
  }

  carrierFilterTypeGetValue(value) {
    switch (value) {
      case 'my_drivers':
        return null;
      case 'all_carriers':
        return 'True';
      case 'all_leased':
        return 'True';
      default:
        return value;
    }
  }

  dropdownNextPage() {
    let config, service, options;
    config = this.carrierDropdownData.config;
    service = this.connectionService;
    options = this.carrierDropdownData.carriers;

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
              let _carriers = results.map(connection => {
                return {
                  name: connection.organization.name,
                  id: connection.organization.carrier.id
                };
              });
              this.carrierDropdownData.carriers = this.carrierDropdownData.carriers.concat(_carriers);
        }, (err) => this.errors = parseErrors(err), () => config.loadingOptions = false);
      }
    }
  }

  filterByIssues() {
    this.issuesSelected = !this.issuesSelected
    this.saveFilterPreference({ key: 'issues', value: this.issuesSelected ? 'True' : 'False' });
  }

  filterByShifts() {
    this.onShiftSelected = !this.onShiftSelected
    this.saveFilterPreference({ name: 'On Shift', key: 'on_shift', value: this.onShiftSelected ? 'True': 'False' });
  }

  filterByTags(value: string) {
    this.saveFilterPreference({ name: 'Tags', key: 'tags', value });
  }

  saveFilterPreference(filter: { name?: string, key: string; value: string }) {
    this.driverTable.loading = true;
    const preference = { ...this.preference };
    const existingFilters: { key: string; value: string }[] =
      preference.blob.filters && preference.blob.filters.length
        ? preference.blob.filters
        : [];

    const filters = existingFilters.some((f) => f.key === filter.key)
      ? this.updateFilters(filter, existingFilters)
      : [...existingFilters, filter];

    this.preferenceService
      .save({ ...this.preference, blob: { filters } })
      .subscribe((updatedPreference) => {
        this.preference = updatedPreference;
        this.savePreferenceFiltersToQuery(updatedPreference);
      });
  }

  updateFilters(
    filter: { name?: string; key: string; value: string },
    existingFilters
  ) {
    switch (filter.key) {
      case 'carrier':
        return existingFilters.map((f) => (f.key === 'carrier' ? filter : f));
      case 'tags':
        const tagFilter = existingFilters.find((f) => f.key === 'tags');
        if (tagFilter && tagFilter.value) {
          if (tagFilter.value === filter.value) {
            return existingFilters.filter((f) => f.key !== 'tags');
          } else {
            return existingFilters.map((f) => (f.key === 'tags' ? filter : f));
          }
        } else {
          return [...existingFilters, filter];
        }
      default:
        return existingFilters.filter((f) => f.key !== filter.key);
    }
  }

  getPreferencesAndCarriers(): void {
    const currentUser = this.authenticationService.user();
    const preference = {
      ...this.preference,
      name: 'FleetHealthComponent-DriverTable',
      type: 'user',
      profile: currentUser.id,
      page_size: 1,
    };

    const carriers$ = this.getCarriers({});
    const preferences$ = this.preferenceService.list(preference);

    forkJoin([carriers$, preferences$]).subscribe(([carriers, preferences]) => {
      const allCarriers = [...this.carrierDropdownData.carriers, ...carriers];
      const newPreference =
        preferences && preferences.length
          ? preferences[0]
          : {
              ...preference,
              blob: { filters: [{ key: 'carrier', value: 'all_carriers' }] }, // default first filter
            };
      const preferenceCarrier = newPreference.blob.filters.find(
        (f) => f.key === 'carrier'
      );
      const selectedCarrier = allCarriers.find(
        (c) => c.id === preferenceCarrier.value
      );

      this.carrierDropdownData.carriers = allCarriers;
      this.carrierDropdownData.carrier = selectedCarrier
        ? selectedCarrier
        : carriers.find((c) => c.id === 'all_carriers');
      this.preference = newPreference;

      this.savePreferenceFiltersToQuery(this.preference);
    });
  }

  savePreferenceFiltersToQuery(preference: Preference) {
    const filters: { key: string; value: string }[] = preference.blob.filters;
    const filterIssues = filters.find((f) => f.key === 'issues')
    const filterOnShift = filters.find((f) => f.key === 'on_shift')
    this.issuesSelected = filterIssues && filterIssues.value === 'True';
    this.onShiftSelected = filterOnShift && filterOnShift.value === 'True';

    const query = {
      ...this.query,
      issues: null,
      on_shift: null,
      all_carriers: null,
      all_leased: null,
      carrier: null,
      tags: null,
    };
    const primaryFilters = [];

    filters.forEach((filter) => {
      if (filter.key === 'carrier') {
        if (filter.value !== 'my_drivers') {
          const carrierFilterType = this.carrierFilterTypeGetValue(
            filter.value
          );
          query[
            filter.value === carrierFilterType ? filter.key : filter.value
          ] = carrierFilterType;
        }
      } else {
        query[filter.key] = filter.value;
        primaryFilters.push(filter);
      }
    });
    this.tableConfig['query'] = this.query = {
      ...this.tableConfig['query'],
      ...query
    };

    this.primaryFilters = primaryFilters;
    // reset pagination to first page
    this.driverTable.resetPageNumber({}, false)
  }

  deleteAllPrimaryFilters() {
    this.primaryFilters = [];
    const existingFilters = this.preference.blob.filters;
    if (
      existingFilters &&
      existingFilters.length &&
      existingFilters.some((f) => f.key !== 'carrier')
    ) {
      const filters = existingFilters.filter((f) => f.key === 'carrier');
      this.preferenceService
        .save({ ...this.preference, blob: { filters } })
        .subscribe((preference) => {
          this.preference = preference;
          this.savePreferenceFiltersToQuery(preference);
        });
    }
  }

  addNewDriver() {
    const dialog = this.dialog.open(NewDriverDialogComponent, {
      width: '444px'
    });
    dialog.componentInstance.leasedMode = true;
    dialog.componentInstance.callback = this.saveDriverCallback;
  }

  updatedMarkets() {
    this.refreshTable();
    this.getPreferencesAndCarriers();
  }

  onShiftEnd() {
    // refresh everything
    this.appliedFilters = [...this.appliedFilters];
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }
}
