"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var expenses_component_1 = require("./expenses.component");
var edit_expense_component_1 = require("./edit-expense.component");
var routes = [
    { path: '', component: expenses_component_1.ExpensesComponent, canActivate: [auth_guard_1.AuthGuard] },
    {
        path: 'filter/:state',
        component: expenses_component_1.ExpensesComponent,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: ':id/edit',
        component: edit_expense_component_1.EditExpenseComponent,
        canActivate: [auth_guard_1.AuthGuard]
    }
];
var ExpensesRoutingModule = /** @class */ (function () {
    function ExpensesRoutingModule() {
    }
    return ExpensesRoutingModule;
}());
exports.ExpensesRoutingModule = ExpensesRoutingModule;
