"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFilters = function (translationService) { return [
    {
        key: 'carrier',
        label: translationService.instant('Carrier'),
        values: [],
    },
    // {
    //   key: 'job',
    //   label: translationService.instant('Job'),
    //   values: [],
    // },
    {
        key: 'jobevent',
        label: translationService.instant('Job Event'),
        values: [],
    },
    // {
    //   key: 'project',
    //   label: translationService.instant('Project'),
    //   values: [],
    // },
    // {
    //   key: 'customer',
    //   label: translationService.instant('Customer'),
    //   values: [],
    // },
    // {
    //   key: 'payable',
    //   label: translationService.instant('Payable To'),
    //   values: [],
    // },
    // {
    //   key: 'edited',
    //   label: translationService.instant('Edited'),
    //   selected: false,
    // },
    // {
    //   key: 'retake_status',
    //   label: translationService.instant('Retake'),
    //   selected: false,
    // },
    // {
    //   key: 'completed',
    //   label: translationService.instant('Incomplete'),
    //   selected: false,
    // },
    // {
    //   key: 'uninvoiced',
    //   label: translationService.instant('Uninvoiced'),
    //   selected: false,
    // },
    {
        key: 'assigned_truck',
        label: translationService.instant('Assigned Truck Name'),
        values: [],
    },
]; };
