"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var end_of_day_overall_stats_1 = require("./end-of-day-overall-stats");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var EndOfDayOverallStatsSerializer = /** @class */ (function () {
    function EndOfDayOverallStatsSerializer() {
    }
    /**
    * @param  {any} json
    * @returns {EndOfDayOverallStats} EndOfDayOverallStats
    */
    EndOfDayOverallStatsSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var endOfDayOverallStats = new end_of_day_overall_stats_1.EndOfDayOverallStats();
        if (!json) {
            return endOfDayOverallStats;
        }
        endOfDayOverallStats.driversCount = json.driversCount;
        endOfDayOverallStats.plannedTrips = json.plannedTrips;
        endOfDayOverallStats.completedTrips = json.completedTrips;
        endOfDayOverallStats.totalTrips = json.totalTrips;
        endOfDayOverallStats.invoiceTotal = json.invoiceTotal;
        endOfDayOverallStats.expenseTotal = json.expenseTotal;
        return endOfDayOverallStats;
    };
    /**
    * @param  {EndOfDayOverallStats} endOfDayOverallStats
    * @returns any
    */
    EndOfDayOverallStatsSerializer.prototype.toJson = function (endOfDayOverallStats) {
        var json = {
            driversCount: endOfDayOverallStats.driversCount,
            plannedTrips: endOfDayOverallStats.plannedTrips,
            completedTrips: endOfDayOverallStats.completedTrips,
            totalTrips: endOfDayOverallStats.totalTrips,
            invoiceTotal: endOfDayOverallStats.invoiceTotal,
            expenseTotal: endOfDayOverallStats.expenseTotal,
        };
        return decamelizeKeysDeep(json);
    };
    return EndOfDayOverallStatsSerializer;
}());
exports.EndOfDayOverallStatsSerializer = EndOfDayOverallStatsSerializer;
