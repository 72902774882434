import { timer as observableTimer, Subscription } from 'rxjs';
import {
  Component, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild,
  ChangeDetectorRef, Input, Output, EventEmitter
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { NguiMapComponent, HeatmapLayer } from '@ngui/map';
import * as moment from 'moment-timezone';
import { find as _find, findIndex, filter } from 'lodash';

import { DriverSerializer } from '../../drivers/driver.serializer';
import { mapOptions } from '../../shared/static-data';
import { MapService } from '../../map/map.service';
import { AuthenticationService } from '../../shared/index';
import { AssignmentService } from '../../assignments/assignment.service';
import { JobEventService } from '../../job-events/job-event.service';
import { Driver } from '../../drivers/driver';
import { PreferenceService } from '../../preferences/preference.service';
import { LocationService } from '../../locations/location.service';

declare let google: any;

@Component({
  selector: 'truck-map',
  templateUrl: './truck-map.component.html',
  styleUrls: ['./truck-map.component.scss']
})

export class TruckMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dateRange: string;
  @Input() selectedLocationId: string;
  @Input() listComponent;
  assignmentsReq: Subscription;
  jobEventReq: Subscription;
  mapReq: Subscription;
  organizationLocationReq: Subscription;
  markerType = 'driver-initials';
  mapData;
  locations: any = [];
  locationUpdates: any = [];
  routes: any = [];
  parsedRoutes: any = [];
  polygons: any = [];
  loading = true;
  mapLoading = true;
  errors = [];
  map: google.maps.Map;
  heatmap: google.maps.visualization.HeatmapLayer;
  customLocationMarkers = [];
  customUpdateMarkers = [];
  coordinates = [];
  mapControls = {
    traffic: false,
    heatmap: false,
    heatmapRadius: 20
  };
  organization;
  mapOptions = {};
  heatmapPoints = [];
  driverInfoWindowModel;
  locationInfoWindowModel;
  @ViewChild(HeatmapLayer, { static: false }) heatmapLayer: HeatmapLayer;
  @ViewChild(NguiMapComponent, { static: false }) nguiMapComponent: NguiMapComponent;
  private jobEventsTimerSub;
  private jobEventsTimer;

  // mobile-specific declarations
  driverListOpen = false;
  hours = [];
  assignments = {
    items: [],
    errors: [],
    loading: false,
  };
  selectedJob: any;
  selectedJobEvent: any;
  activeJobs: any[] = [];
  activeJobEvents: any[] = [];
  punchCardFlag = false;
  driversList: any[] = [];
  assignmentDetails: any = {};
  preferenceReqs: any = {};
  preferences: any = {};
  loadingMapPreferences = true;
  @Output() assignmentTitle: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDriver: EventEmitter<Driver> = new EventEmitter<Driver>();
  @Input() assignmentToggle: boolean;

  constructor(
    private mapService: MapService,
    private locationService: LocationService,
    private jobEventService: JobEventService,
    private assignmentService: AssignmentService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.mapLoading = true;
    this.mapOptions = mapOptions({
      disableDefaultUI: true,
      scrollwheel: true
    }, {}, {
      mapStyle: 'google-map-style'
    });
    this.getPreferences('GeneralUserPreferences');
    this.getMapLocationUpdates(this.mapLoading, true);
    const trucksMapRefresh = this.authenticationService.getFeature('trucksMapRefresh') || 300000;
    this.jobEventsTimer = observableTimer(1, trucksMapRefresh);
    this.jobEventsTimerSub = this.jobEventsTimer.subscribe(t => {
      this.getMapLocationUpdates(this.mapLoading);
      this.mapLoading = false;
    });
    this.organization = this.authenticationService.getOrganization();
    if (window.innerWidth <= 900) {
      this.loadAssignments();
    }

    // this.assignmentDateChanged.pipe(
    //   debounceTime(300), distinctUntilChanged()
    // ).subscribe(assignmentDate => {
    //   this.assignmentDate = assignmentDate.date;
    //   this.getMapLocationUpdates(this.mapLoading, true, true);
    // });
  }

  ngOnDestroy() {
    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
    if (this.jobEventsTimerSub) {
      try {
        this.jobEventsTimerSub.unsubscribe();
        this.jobEventsTimer = null;
      } catch (e) {
        this.jobEventsTimerSub = null;
        this.jobEventsTimer = null;
      }
    }
    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }
    Object.keys(this.preferenceReqs).forEach(preferenceReqKey => {
      if (this.preferenceReqs[preferenceReqKey] && typeof this.preferenceReqs[preferenceReqKey].unsubscribe === 'function') {
        try { this.preferenceReqs[preferenceReqKey].unsubscribe(); } catch (e) { }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.assignmentToggle) {
      this.assignmentDetails = {};
      this.assignmentTitle.emit(null);
    }
  }

  onResize(event) {
    if (window.innerWidth <= 900 && this.assignments.loading !== false) {
      this.loadAssignments();
    }
  }

  getMapLocationUpdates(loading = true, centerMap = false, openInfoWindow = false) {
    this.loading = loading;

    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }

    this.mapReq = this.mapService.getLocationUpdates({
      location: this.selectedLocationId,
      exclude_pending: 'True',
      ordering: 'jobs__name'
    }).subscribe(locationUpdates => {
      this.resetMarkers();
      this.locations = locationUpdates.locations;
      this.locationUpdates = locationUpdates.locationUpdates.map((update) => {
        if (update.driver) { update.driver = new DriverSerializer().fromJson(update.driver); }
        return update;
      });
      this.routes = locationUpdates.routes;
      this.parseRoutes();
      this.polygons = this.locations.map((location) => {
        return location.paths;
      }).filter(Boolean);
      this.loading = false;
      this.mapData = locationUpdates;
      if (centerMap) { this.centerMap(); }
      if (
        openInfoWindow && this.locationUpdates.length === 1 && window.innerWidth > 900
      ) {
        this.openDriverInfoWindow(0, this.locationUpdates[0]);
      } else if (this.locationUpdates.length === 0) {
        if (this.nguiMapComponent) {
          this.nguiMapComponent.closeInfoWindow('driver-info-window');
        }
      }
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  parseRoutes() {
    if (this.map) {
      this.parsedRoutes = this.routes.map((route) => {
        if (route) {
          try {
            return new google.maps.geometry.encoding.decodePath(route);
          } catch (e) {
            console.log(e);
          }
        }
      }).filter(Boolean);
    }
  }

  centerMap() {
    let _centerOnOrganization = true;
    if (this.map) {
      let bounds = new google.maps.LatLngBounds();
      if (this.locations && this.locations.length) {
        this.locations.forEach(location => {
          let _lat = location.location && location.location.coordinates[1];
          let _lng = location.location && location.location.coordinates[0];
          if (_lat && _lng) {
            let latLng = new google.maps.LatLng(_lat, _lng);
            bounds.extend(latLng);
          }
        });
        _centerOnOrganization = false;
      }

      let locationUpdates = this.locationUpdates.map((locationUpdate) => {
        let _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
        let _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
        if (_lat && _lng) {
          return new google.maps.LatLng(_lat, _lng);
        }
      });
      for (let i = 0, latLngLen = locationUpdates.length; i < latLngLen; i++) {
        let list = locationUpdates[i];
        bounds.extend(list);
      }
      if (locationUpdates && locationUpdates.length) {
        _centerOnOrganization = false;
      }

      if (_centerOnOrganization) { this.centerOnOrganization(); }
      this.map.fitBounds(bounds);
    }
  }

  centerOnOrganization(): void {
    if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
      this.organizationLocationReq.unsubscribe();
    }
    this.locationService.getLocationByAddress(`${this.organization.street}, ${this.organization.location}`).subscribe(coords => {
      if (coords && coords[0] && coords[0].geometry) {
        let fallbackCenter = new google.maps.LatLng(
          coords[0].geometry.location.lat, coords[0].geometry.location.lng
        );
        this.mapOptions = mapOptions({
          zoom: 10,
          center: fallbackCenter,
          disableDefaultUI: true,
          scrollwheel: false,
          geoFallbackCenter: fallbackCenter
        }, {}, {
          mapStyle: 'google-map-style'
        });
      }
    }, (err) => this.errors = ['Cannot get your organizations location']);
  }

  initMap(event: any) {
    if (event) { this.map = event; }
    this.parseRoutes();
    // this.centerMap();
  }

  onCustomLocationMarkerInit(customMarker) {
    this.customLocationMarkers.push(customMarker);
  }

  onCustomUpdateMarkerInit(customMarker) {
    this.customUpdateMarkers.push(customMarker);
  }

  openDriverInfoWindow(index: number, update) {
    this.driverInfoWindowModel = update;
    if (update && update.driver) {
      let markers = filter(this.customUpdateMarkers, (m) => {
        return m.htmlEl.id === update.driver.id;
      });
      if (markers && markers.length) {
        this.nguiMapComponent.openInfoWindow(
          'driver-info-window', markers[0]
        );
      }
      this.selectedDriver.emit(update.driver);
    }
  }

  openLocationInfoWindow(index: number, location) {
    this.locationInfoWindowModel = {
      title: location.displayName,
      address: location.street,
      city: location.city,
      state: location.state,
      zip: location.zip
    };
    this.nguiMapComponent.openInfoWindow('location-info-window', this.customLocationMarkers[index]);
  }

  toggleTraffic() {
    if (this.mapControls.traffic) {
      this.mapControls.traffic = false;
    } else {
      this.mapControls.traffic = true;
    }
    this.cdr.detectChanges();
  }

  toggleHeatmap() {
    if (this.mapControls.heatmap) {
      this.mapControls.heatmap = false;
      this.heatmap.setMap(null);
    } else {
      this.mapControls.heatmap = true;
      this.heatmap.setMap(this.map);
    }
    this.cdr.detectChanges();
  }

  onHeatmapInitialized(e = null) {
    this.heatmap = e;
    this.coordinates = [];
    this.updateHeatmap();
  }

  onMapIdle(e = null) {
    if (this.heatmap) {
      this.updateHeatmap();
    }
  }

  updateHeatmap() {
    // this.locationUpdateService.coordinates(this.map.getBounds(), {
    //   jobevent: this.jobEvent && this.jobEvent.id,
    //   page_size: 2500
    // }).subscribe(coordinates => {
    //   this.coordinates = coordinates;
    //   this.heatmapPoints = coordinates && coordinates.map((coordinate) => {
    //     return { location: new google.maps.LatLng(coordinate.lon, coordinate.lat), weight: coordinate.weight || 1 };
    //   });
    //   if (this.heatmapPoints && this.heatmapPoints.length > 0) {
    //     this.heatmap.setData(this.heatmapPoints);
    //   }
    //   this.cdr.detectChanges();
    // }, err => { console.error(err); });
  }

  changeHeatmapRadius(e = null) {
    if (e && this.mapControls) {
      this.mapControls.heatmapRadius = e.target.value;
      this.heatmap.set('radius', e.target.value);
      // this.cdr.detectChanges();
    }
  }

  openDriverList() {
    this.driverListOpen = !this.driverListOpen;
  }

  loadAssignments(): void {
    this.assignments.loading = true;

    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }

    this.assignmentsReq = this.assignmentService.list({
      include_trips: 'True',
      dispatched: 'True',
      active_range: this.dateRange,
    }).subscribe(assignments => {
      this.assignments.items = assignments.map((assignment) => {
        if (assignment.driver) {
          assignment.driver = new DriverSerializer().fromJson(assignment.driver);

          let driverMatch = _find(this.driversList, assignment.driver.id);
          if (!driverMatch) { this.driversList.push(assignment.driver.id); }

          if (assignment.trips && assignment.trips.length) {
            if (!assignment.trips[0].completed) {
              assignment.driver.trip = assignment.trips[0];
              this.durationInMinutes(assignment.driver.trip);
            }
          }
        }
        if (assignment.jobevent) {
          let jobEventId = assignment.jobevent.toString();
          let jobEventMatch = _find(this.activeJobEvents, { id: jobEventId });
          if (!jobEventMatch) {
            if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
              this.jobEventReq.unsubscribe();
            }

            this.jobEventReq = this.jobEventService.getJobEvent(jobEventId).subscribe(jobEvent => {
              this.activeJobEvents.push(jobEvent);
              this.punchCardFlag = (jobEvent.invoiceType === 'hourly') ? true : false;
              this.loading = false;
              let jobId = jobEvent.job && jobEvent.job.id;
              let jobMatch = _find(this.activeJobs, { id: jobId });
              if (!jobMatch) {
                this.activeJobs.push(jobEvent.job);
              }
            }, err => {
              this.errors = err;
              this.loading = false;
            });
          }
        }
        return assignment;
      });
      this.getMapLocationUpdates(this.mapLoading, true);
    }, err => {
      this.assignments.errors = err;
    }, () => {
      this.assignments.loading = false;
    });
  }

  durationInMinutes(obj) {
    obj.durationTimer = observableTimer(1, 60000);
    obj.durationTimer.subscribe(t => {
      let duration = moment().diff(obj.startTimeTimestamp, 'minutes') + ' mins';
      obj.duration = duration;
    });
  }

  openAssignmentDetails(update, index, focusList = true) {
    if (window.innerWidth < 900) {
      let latLng;
      this.assignmentDetails = {};
      if (update && update.location && update.driver && update.driver.id) {
        this.assignmentDetails = _find(this.assignments.items, { driver: { id: update.driver.id } });
        latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
      } else if (update && update.shift && update.driver && update.driver.id) {
        this.assignmentDetails = update;
        let locationUpdate = _find(this.locationUpdates, { driver: { id: update.driver.id } });
        if (locationUpdate && locationUpdate['location']) {
          latLng = new google.maps.LatLng(locationUpdate['location'].coordinates[1], locationUpdate['location'].coordinates[0]);
        }
      }
      if (latLng && this.map && typeof this.map.setCenter === 'function') {
        this.map.setCenter(latLng);
        this.map.setZoom(17);
      }
      if (this.assignmentDetails && this.assignmentDetails.jobevent) {
        this.selectedJobEvent = _find(this.activeJobEvents, { id: this.assignmentDetails['jobevent'] });
        this.selectedJob = _find(this.activeJobs, { id: this.selectedJobEvent['job']['id'] });
        this.assignmentTitle.emit(this.assignmentDetails['driver']['profile']['name']);
      } else {
        this.errors = ['There was an issue getting the assignment details.'];
      }
    } else {
      this.openDriverInfoWindow(index, update);
      if (focusList && this.listComponent) {
        let driverId = update && update.driver && update.driver.id;
        if (driverId) {
          let drivers = this.listComponent.dataSource.data.value;
          drivers.forEach((driver) => {
            if (driver.hasOwnProperty('highlighted') && driver.id !== driverId) {
              driver['highlighted'] = false;
            } else if (driver.hasOwnProperty('highlighted') && driver.id === driverId) {
              driver['highlighted'] = true;
            }
          });
        }
      }
    }
  }

  focusDriver(driverId) {
    let update = _find(this.locationUpdates, { driver: { id: driverId } });
    let index = findIndex(this.locationUpdates, { driver: { id: driverId } });
    if (update) { this.openAssignmentDetails(update, index, false); }
  }

  resetMarkers(): void {
    this.customLocationMarkers = [];
    this.customUpdateMarkers = [];
  }

  getPreferences(preferenceKey = 'GeneralUserPreferences'): void {
    if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
      try { this.preferenceReqs[preferenceKey].unsubscribe(); } catch (e) { }
    }
    let currentUser = this.authenticationService.user();
    this.preferenceReqs[preferenceKey] = this.preferenceService.list({
      name: preferenceKey,
      type: 'user',
      profile: currentUser.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        this.preferences[preferenceKey] = preferences[0];
        this.parsePreferences(preferenceKey);
      }
    }, err => {
      this.errors = err;
    }, () => {
      if (preferenceKey === 'GeneralUserPreferences') {
        this.loadingMapPreferences = false;
      }
    });
  }

  /**
   * Examine the found preference for the provided key to determine if it has
   * the necessary data.
   *
   * Once the preferences data is handled, update the map
   */
  parsePreferences(preferenceKey = 'GeneralUserPreferences'): void {
    if (preferenceKey === 'GeneralUserPreferences') {
      let mapStyle = 'google-map-style';
      if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
        mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
      }
      this.markerType = 'driver-initials';
      if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
        this.markerType = this.preferences[preferenceKey].blob['markerType'];
      }
      this.mapOptions = mapOptions({
        disableDefaultUI: true,
        scrollwheel: true
      }, {}, {
        mapStyle: mapStyle
      });

      this.getMapLocationUpdates(this.mapLoading, true, true);
    }
  }
}
