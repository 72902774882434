"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FilterByPipe = /** @class */ (function () {
    function FilterByPipe() {
    }
    FilterByPipe.prototype.transform = function (items, filter, value, reverseFilter) {
        return reverseFilter
            ? items.filter(function (item) { return item[filter] !== value; })
            : items.filter(function (item) { return item[filter] === value; });
    };
    return FilterByPipe;
}());
exports.FilterByPipe = FilterByPipe;
