"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var collections_1 = require("@angular/cdk/collections");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var ng2_dragula_1 = require("ng2-dragula");
var operators_1 = require("rxjs/operators");
var preference_service_1 = require("../../preferences/preference.service");
var api_service_1 = require("../api.service");
var authentication_service_1 = require("../authentication.service");
var table_actions_1 = require("./table.actions");
var table_default_1 = require("./table.default");
var FancyTableComponent = /** @class */ (function () {
    function FancyTableComponent(route, router, dialog, injector, dragulaService, preferenceService, authenticationService, renderer2) {
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.injector = injector;
        this.dragulaService = dragulaService;
        this.preferenceService = preferenceService;
        this.authenticationService = authenticationService;
        this.renderer2 = renderer2;
        // inputs
        this.query = {};
        this.availableColumns = [];
        this.displayedColumns = [];
        this.detailColumns = [];
        this.filters = [];
        this.availableFilters = [];
        this.search = '';
        this.config = __assign({}, table_default_1.default);
        this.count = 0;
        this.customClasses = '';
        // outputs
        this.searchChange = new core_1.EventEmitter();
        this.filtersChange = new core_1.EventEmitter();
        this.onClickAction = new core_1.EventEmitter();
        this.onDropAction = new core_1.EventEmitter();
        this.checkBoxToggle = new core_1.EventEmitter();
        this.selectionChanged = new core_1.EventEmitter();
        this.masterCheckBoxToggle = new core_1.EventEmitter();
        this.columnChange = new core_1.EventEmitter();
        this.onMenuAction = new core_1.EventEmitter();
        this.onDataLoaded = new core_1.EventEmitter();
        // variables
        this.dataSource = new material_1.MatTableDataSource();
        this.preferenceKey = '';
        this.loading = false;
        this.errors = [];
        this.record = {};
        this.records = [];
        this.page = 1;
        this.selection = new collections_1.SelectionModel(true, []);
        this.exclusion = new collections_1.SelectionModel(true, []);
        this.allSelected = false;
        this.userColumns = [];
        this.internalColumns = ['select', 'expand', 'actions'];
        this.allSubscriptionsToUnsubscribe = [];
        this.routerPageNumber = 0;
        this.loadingPreference = false;
        this.apiCall = null;
        this.queryDateParam = null;
        this.queryDateParamInitialLoad = true;
        // dragula
        this.dragulaName = "order-columns-" + this.generateRandomId();
        this.dragStarted = false;
        this.dragulaName = this.generateRandomId();
        this.startDragula(this.dragulaName);
    }
    FancyTableComponent.prototype.onKeydownHandler = function () {
        if (this.dragStarted) {
            this.dragStarted = false;
            this.dragulaService.destroy(this.dragulaName);
            // create a new instance of dragula
            this.dragulaName = this.generateRandomId();
            this.startDragula(this.dragulaName);
        }
    };
    FancyTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selection.isSelected = this.isSelected.bind(this);
        this.exclusion.isSelected = this.isExcluded.bind(this);
        this.config = __assign({}, table_default_1.default, this.config);
        this.allSubscriptionsToUnsubscribe.push(this.route.queryParams.subscribe(function (params) {
            if (params.page && params.date && params.date !== _this.queryDateParam) {
                _this.queryDateParam = params.date;
                if (_this.queryDateParamInitialLoad) {
                    _this.queryDateParamInitialLoad = false;
                }
                else {
                    _this.updateUrl({}, true, false);
                }
            }
        }));
        this.userColumns = lodash_1.filter(this.availableColumns, function (column) {
            return !_this.internalColumns.includes(column.key);
        });
    };
    FancyTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.config || changes.query) {
            if ((changes.config && changes.config.firstChange) ||
                (changes.query && changes.query.firstChange)) {
                this.getPreferences();
            }
            else if (this.config.updateForConfigChanges) {
                this.getRecords();
            }
            else {
                // Do nothing...
                // this.updateUrl({ page: 1 });
            }
        }
        if (changes['displayedColumns'] &&
            !changes['displayedColumns'].firstChange) {
            if (!this.config.ignorePreferences) {
                this.savePreferences();
            }
        }
        if (changes['filters'] && !changes['filters'].firstChange) {
            this.page = 1;
            this.filters = changes.filters.currentValue;
            if (!this.config.ignorePreferences &&
                this.preference &&
                this.preference.blob &&
                this.preference.blob['filters'] &&
                !lodash_1.isEqual(this.preference.blob['filters'].sort(), this.filters.sort())) {
                this.savePreferences();
                this.getRecords();
            }
        }
        if (changes.search &&
            !changes.search.firstChange &&
            changes.search.currentValue !== changes.search.previousValue) {
            this.search = changes.search.currentValue;
            this.updateUrl({ page: 1, search: changes.search.currentValue });
        }
        if (changes.config &&
            !changes.config.firstChange &&
            !lodash_1.isEqual(changes.config.currentValue.query, changes.config.previousValue.query)) {
            this.getRecords();
        }
    };
    FancyTableComponent.prototype.ngOnDestroy = function () {
        // closing router and filters subscriptions
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
        if (this.dialog && typeof this.dialog.closeAll === 'function') {
            this.dialog.closeAll();
        }
        this.dragulaService.destroy(this.dragulaName);
    };
    FancyTableComponent.prototype.startDragula = function (dragulaName) {
        var _this = this;
        var nonMovableColumns = [
            'mat-column-select',
            'mat-column-expand',
            'mat-column-actions',
        ];
        var dragulaOptions = {
            moves: function (el) {
                return lodash_1.intersection(el.classList, nonMovableColumns).length === 0;
            },
            revertOnSpill: true,
            direction: 'horizontal',
        };
        this.allSubscriptionsToUnsubscribe.push(this.dragulaService.drag().subscribe(function () {
            _this.dragStarted = true;
        }));
        this.dragulaService.createGroup(dragulaName, dragulaOptions);
        this.allSubscriptionsToUnsubscribe.push(this.dragulaService
            .dropModel(dragulaName)
            .pipe(operators_1.delay(50))
            .subscribe(function () {
            _this.dragStarted = false;
            _this.preferenceKey = "table-preferences[" + _this.config.preferenceKey + "]";
            _this.reorderStuckFields();
            _this.savePreferences();
        }));
    };
    FancyTableComponent.prototype.getRecords = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.apiCall && this.apiCall.unsubscribe();
        var service = this.injector.get(this.config.service);
        this.loading = true;
        var order = (this.config.sortDirection === 'asc' ? '' : '-') + this.config.sortBy;
        var configQuery = __assign({}, this.config.query);
        if (this.filters && this.filters.length) {
            if (this.config.filterQuery) {
                var filtersToUse = this.filters.filter(function (f) { return f.removeFromFiltersQuery !== true; });
                var filtersForQuery = this.filters.filter(function (f) { return f.removeFromFiltersQuery === true; });
                if (filtersToUse && filtersToUse.length) {
                    query['filters'] = filtersToUse;
                }
                filtersForQuery.forEach(function (filterQuery) {
                    if (filterQuery.key &&
                        filterQuery.values &&
                        filterQuery.values.length) {
                        query[filterQuery.key] = filterQuery.values[0];
                    }
                });
            }
            else {
                this.filters.forEach(function (f) {
                    query = __assign({}, query, f.query);
                });
            }
            if (configQuery['user_tags'] &&
                configQuery['user_tags'] === 'True' &&
                this.filters.findIndex(function (f) { return f['key'] === 'tags'; }) > -1) {
                delete configQuery['user_tags'];
            }
        }
        if (this.query) {
            query = __assign({}, query, this.query);
        }
        service.slug = this.config.slug || undefined;
        var listFunction = this.config.serviceFunction
            ? this.config.serviceFunction
            : 'list';
        var fullQuery = __assign({ ordering: order, search: this.search, page_size: this.config.pageSize, page: this.page || this.paginator.pageIndex + 1 }, configQuery, query);
        this.apiCall = service[listFunction](fullQuery).subscribe(function (records) {
            _this.dataSource.data = table_actions_1.tableParseRecords(records, _this.config);
            _this.count = service.count || records.length || 0;
            _this.onDataLoaded.emit({
                data: records,
                query: fullQuery,
                count: _this.count,
            });
            if (_this.routerPageNumber > 1) {
                _this.setPageNumber(_this.routerPageNumber);
                _this.routerPageNumber = 0;
            }
            _this.config.customFieldsLoaded = true;
            _this.loading = false;
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.loading = false;
        });
    };
    /**
     * @param  {} updatedObj
     * @returns void
     * Update the table without making get request
     * update the edited field of the table without reloading entire table
     */
    FancyTableComponent.prototype.updateTable = function (updatedObj) {
        var index = lodash_1.findIndex(this.dataSource.data, { id: updatedObj.id });
        this.dataSource.data.splice(index, 1, updatedObj);
        if (this.fancyTable) {
            this.fancyTable.renderRows();
        }
    };
    FancyTableComponent.prototype.updateUrl = function (params, getRecords, savePreferences) {
        var _this = this;
        if (getRecords === void 0) { getRecords = true; }
        if (savePreferences === void 0) { savePreferences = true; }
        this.saveGlobalTableVariables(__assign({}, params), savePreferences);
        params['search'] = params['search'] || this.search;
        if (this.route && this.route.snapshot) {
            params = __assign({}, this.route.snapshot.queryParams, params);
        }
        this.router
            .navigate(['.'], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        })
            .then(function () {
            if (getRecords) {
                _this.getRecords();
            }
        });
    };
    FancyTableComponent.prototype.saveGlobalTableVariables = function (_a, savePreferences) {
        var _b = _a.page, page = _b === void 0 ? this.page : _b, _c = _a.pageSize, pageSize = _c === void 0 ? this.config.pageSize : _c, _d = _a.sortBy, sortBy = _d === void 0 ? this.config.sortBy : _d, _e = _a.sortAsc, sortAsc = _e === void 0 ? this.config.sortDirection : _e, _f = _a.search, search = _f === void 0 ? this.search : _f;
        if (savePreferences === void 0) { savePreferences = true; }
        this.page = page;
        this.config.pageSize = pageSize;
        this.config.sortBy = sortBy;
        this.config.sortDirection = sortAsc;
        this.search = search;
        if (savePreferences) {
            this.savePreferences();
        }
        if (this.paginator) {
            this.paginator.pageIndex = page - 1;
        }
    };
    FancyTableComponent.prototype.sortData = function (event) {
        if (event) {
            var sortBy = this.config.sortBy;
            var sortDirection = event.direction === 'asc' ? 'asc' : 'desc';
            this.config.sortDirection = sortDirection;
            var column = lodash_1.find(this.availableColumns, { key: event.active });
            if (column) {
                sortBy = column['sortBy'] || event.active;
                this.config.sortBy = sortBy;
            }
            this.updateUrl({
                page: 1,
                pageSize: this.config.pageSize,
                sortBy: sortBy,
                sortAsc: sortDirection,
            });
        }
    };
    FancyTableComponent.prototype.pageChange = function (event) {
        var initialPageSize = this.config.pageSize;
        if (event) {
            if (initialPageSize !== event.pageSize) {
                this.paginator.firstPage();
                this.updateUrl({ page: 1, pageSize: event.pageSize }, true, false);
            }
            else {
                var page = event.pageIndex >= this.page ? this.page + 1 : this.page - 1;
                var pageSize = event.pageSize || this.config.pageSize;
                this.updateUrl({
                    page: page,
                    pageSize: pageSize,
                }, true, false);
            }
            document.querySelector('mat-table').scrollTop = 0;
        }
    };
    FancyTableComponent.prototype.clearSearch = function () {
        this.loading = true;
        this.filters = [];
        this.search = '';
        this.searchChange.emit('');
        this.filtersChange.emit([]);
        this.updateUrl({ search: '' });
    };
    FancyTableComponent.prototype.openNewRecord = function () {
        this.router.navigate(this.config.newRecordRoute);
    };
    FancyTableComponent.prototype.resetSelections = function () {
        this.selection.clear();
        this.exclusion.clear();
        this.allSelected = false;
    };
    FancyTableComponent.prototype.masterToggle = function (event) {
        var service = this.injector.get(this.config.service);
        if (this.allSelected || !event.checked) {
            this.resetSelections();
        }
        service.allSelected = event.checked;
        this.masterCheckBoxToggle.emit([event.checked, this.dataSource.data]);
        this.allSelected = event.checked;
        this.selectionChanged.emit({
            allSelected: event.checked,
            selection: this.selection,
            exclusion: this.exclusion,
        });
    };
    FancyTableComponent.prototype.toggleSelection = function (selection, row, event) {
        var service = this.injector.get(this.config.service);
        if (service.allSelected && !event.checked) {
            this.exclusion.select(row);
            var startRowCount = this.selection.selected.length;
            this.selection.deselect(row);
            var stopRowCount = this.selection.selected.length;
            if (startRowCount === stopRowCount && row.id) {
                var _row = lodash_1.find(this.selection.selected, { id: row.id });
                this.selection.deselect(_row);
            }
        }
        else if (!event.checked) {
            var startRowCount = this.selection.selected.length;
            this.selection.deselect(row);
            var stopRowCount = this.selection.selected.length;
            if (startRowCount === stopRowCount && row.id) {
                var _row = lodash_1.find(this.selection.selected, { id: row.id });
                this.selection.deselect(_row);
            }
        }
        else {
            this.selection.select(row);
            var startRowCount = this.exclusion.selected.length;
            this.exclusion.deselect(row);
            var stopRowCount = this.exclusion.selected.length;
            if (startRowCount === stopRowCount && row.id) {
                var _row = lodash_1.find(this.exclusion.selected, { id: row.id });
                this.exclusion.deselect(_row);
            }
        }
        this.checkBoxToggle.emit([service.allSelected, event.checked, row]);
        this.allSelected = service.allSelected;
        this.selectionChanged.emit({
            allSelected: this.allSelected,
            selection: this.selection,
            exclusion: this.exclusion,
        });
    };
    FancyTableComponent.prototype.isSelected = function (row) {
        return this.selection.selected.find(function (el) { return el.id === row.id; });
    };
    FancyTableComponent.prototype.isExcluded = function (row) {
        return this.exclusion.selected.find(function (el) { return el.id === row.id; });
    };
    FancyTableComponent.prototype.reorderStuckFields = function () {
        table_actions_1.tableReorderStuckFields(this.displayedColumns);
    };
    FancyTableComponent.prototype.clickAction = function (event, row) {
        table_actions_1.tableClickAction(event, row, this.record, this.onClickAction);
    };
    FancyTableComponent.prototype.dragAction = function (event, row) {
        table_actions_1.tableDragAction(event, row);
    };
    FancyTableComponent.prototype.dropAction = function (event, row) {
        table_actions_1.tableDropAction(event, row, this.config, this.onDropAction);
    };
    FancyTableComponent.prototype.menuAction = function (name, row) {
        table_actions_1.tableMenuAction(name, row, this.onMenuAction);
    };
    FancyTableComponent.prototype.getPreferences = function () {
        var _this = this;
        if (this.config.ignorePreferences) {
            return;
        }
        this.loadingPreference = true;
        var currentUser = this.authenticationService.user();
        this.preferenceKey = "table-preferences[" + this.config.preferenceKey + "]";
        if (this.preference && this.preference.id) {
            this.preferenceService.get(this.preference.id).subscribe(function (preference) {
                _this.preference = preference;
                _this.parsePreferences();
            });
        }
        else {
            this.preferenceService
                .list({
                name: this.preferenceKey,
                type: 'user',
                profile: currentUser.id,
            })
                .subscribe(function (preferences) {
                if (preferences && preferences.length) {
                    _this.preference = preferences[0];
                    _this.parsePreferences();
                }
                else {
                    _this.preference = {};
                    _this.savePreferences();
                }
            });
        }
    };
    FancyTableComponent.prototype.savePreferences = function () {
        var _this = this;
        if (this.config.ignorePreferences)
            return;
        if (this.preferenceKey) {
            var currentUser = this.authenticationService.user();
            var newPreference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: currentUser.id, blob: {
                    columns: this.displayedColumns,
                    filters: this.filters,
                    pageSize: this.config.pageSize,
                } });
            this.preferenceService.save(newPreference).subscribe(function (preference) {
                _this.preference = preference;
            });
        }
    };
    /**
     * Examine the found preference to determine if it has columns and that those
     * columns are included in the list of `availableColumns` for this table.
     *
     * Capture the available columns and set the displayed column list to those
     * keys.
     */
    FancyTableComponent.prototype.parsePreferences = function () {
        var _this = this;
        if (!this.preference || !this.preference.blob)
            return;
        if (this.preference.blob['columns']) {
            var columns = lodash_1.intersectionWith(this.preference.blob['columns'], this.availableColumns, function (col, c) { return col === c.key; });
            this.displayedColumns = columns;
            this.columnChange.emit(columns);
        }
        if (this.preference.blob['filters']) {
            var filters = this.preference.blob['filters'];
            filters = filters.filter(function (field) { return !field['default']; });
            filters.forEach(function (f) {
                var filterIndex = lodash_1.findIndex(_this.filters, {
                    key: f.key,
                    default: true,
                });
                if (filterIndex !== -1) {
                    _this.filters[filterIndex] = f;
                }
            });
            this.filters = lodash_1.uniqBy(this.filters.concat(filters), 'key');
            this.filtersChange.emit(this.filters);
        }
        if (this.preference.blob['pageSize']) {
            var pageSize = this.preference.blob['pageSize'];
            this.config.pageSize = pageSize;
            this.updateUrl({
                page: 1,
                pageSize: this.config.pageSize,
            });
        }
        else {
            this.updateUrl({ page: 1 });
        }
    };
    FancyTableComponent.prototype.rowIsExpanded = function (row) {
        return row && row.expanded ? row.expanded : false;
    };
    FancyTableComponent.prototype.rowCanExpand = function (row) {
        return row.hasOwnProperty('canExpand') ? row.canExpand : true;
    };
    FancyTableComponent.prototype.resetPageNumber = function (queryParams, getRecords) {
        if (queryParams === void 0) { queryParams = {}; }
        this.page = 1;
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
        this.updateUrl(queryParams, getRecords);
    };
    FancyTableComponent.prototype.setPageNumber = function (page) {
        this.page = page;
        var queryParams = this.route.snapshot.queryParams;
        if (this.paginator) {
            this.paginator.pageIndex = page - 1;
        }
        this.updateUrl(__assign({}, queryParams, { page: this.page, pageSize: this.config.pageSize }));
    };
    FancyTableComponent.prototype.deselectAll = function () {
        this.masterToggle({ checked: false });
        this.masterCheckbox.checked = false;
    };
    FancyTableComponent.prototype.removeRecord = function (value, property) {
        if (property === void 0) { property = 'id'; }
        table_actions_1.tableRemoveRecord(this.dataSource, value, property);
    };
    FancyTableComponent.prototype.generateRandomId = function () {
        return (Math.random() + 1).toString(36).substring(7);
    };
    return FancyTableComponent;
}());
exports.FancyTableComponent = FancyTableComponent;
