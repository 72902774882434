"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormattedSecondsPipe = /** @class */ (function () {
    function FormattedSecondsPipe() {
    }
    FormattedSecondsPipe.prototype.transform = function (seconds, type) {
        if (!seconds) {
            return 'N/A';
        }
        var minutes = Math.floor(Number(seconds) / 60);
        if (type && type === 'hours') {
            return (Math.floor(minutes / 60)) + 'h';
        }
        else {
            return minutes > 1440 ?
                (Math.floor(minutes / 1440)) + 'd ' + (Math.floor((minutes - 1440) / 60)) + 'h ' + (minutes % 60) + 'm' :
                minutes > 60 ?
                    (Math.floor(minutes / 60)) + 'h ' + (minutes % 60) + 'm' :
                    (minutes % 60) + 'm';
        }
    };
    return FormattedSecondsPipe;
}());
exports.FormattedSecondsPipe = FormattedSecondsPipe;
