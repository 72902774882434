<div class="driver-pay-timeline-list-container" [ngClass]="{'read-only': readOnly}">
  <div class="top-bar">
    <p class="smaller" translate>Edit Pay Record</p>
    <mat-icon (click)="close.emit(true)">close</mat-icon>
  </div>
  <div *ngIf="payRecordData" class="section payRecordData">
    <h4 translate>Pay Record Summary</h4>
    <div class="field full-width">
      <p class="description" translate>Job</p>
      <p>{{ payRecordData.assignment && payRecordData.assignment.jobevent && payRecordData.assignment.jobevent.jobDisplayName }}</p>
    </div>
    <div class="field half-width">
      <p class="description" translate>Rate</p>
      <p class="green">
        {{ payRecordData.assignment && payRecordData.assignment.jobevent && payRecordData.assignment.jobevent.haulRate | currency }} / {{ payRecordData.assignment && payRecordData.assignment.jobevent && payRecordData.assignment.jobevent.haulRateUnit }}
      </p>
    </div>
    <div class="field half-width">
      <p class="description" translate>Pay Summary</p>
      <p class="green">{{ payRecordData.total | currency }}</p>
    </div>
    <div class="field half-width">
      <p class="description" translate>Loads</p>
      <p>{{ payRecordData.assignment.estimatedNumberOfLoads }}</p>
    </div>
    <div class="field half-width">
      <p class="description" translate>Tons</p>
      <p>{{ payRecordData.assignment.estimatedNumberOfTons }}</p>
    </div>
    <div class="field half-width">
      <p class="description" translate>Total Hours / Tons</p>
      <input type="text" (blur)="updatePayDuration()" [(ngModel)]="payRecordData.modifiedAmount"/>
    </div>
    <div class="field half-width">
      <p class="description" translate>Adjustment</p>
      <input type="number" [(ngModel)]="payRecordData.adjustment" (blur)="editPayRecord('adjustment', $event)"/>
      <span translate>
        {{ payRecordData.assignment && payRecordData.assignment.jobevent && payRecordData.assignment.jobevent.haulType === 'hour' ? 'min' : 'ton' }}
      </span>
    </div>
    <div *ngIf="payRecordData.adjustment !== 0" class="field full-width">
      <p class="description" translate>Adjustment Notes</p>
      <textarea [(ngModel)]="payRecordData.notes" (blur)="editPayRecord('notes', $event)"></textarea>
    </div>

    <div *ngFor="let payLine of payRecordData.payLines; let i = index" class="field-group">
      <h5 translate>Pay Line {{ i + 1 }}</h5>
      <div class="field half-width">
        <p class="description" translate>Pay Start Time</p>
        <input type="time" (blur)="updatePayLine(i)"
               [(ngModel)]="payLine.modifiedDuration.startTime"/>
      </div>
      <div class="field half-width">
        <p class="description" translate>Pay End Time</p>
        <input type="time" (blur)="updatePayLine(i)"
               [(ngModel)]="payLine.modifiedDuration.endTime"/>
      </div>
      <div class="field full-width">
        <p class="description" translate>Pay Date</p>
        <input type="date" (blur)="updatePayLine(i)"
               [(ngModel)]="payLine.modifiedDuration.date"/>
      </div>
    </div>

    <div class="context-list" *ngIf="payRecordData.assignment">
      <div *ngFor="let punchCard of payRecordData.assignment.punchCards; let i = index" class="context-link">
        <p translate>Punch Card {{ i + 1 }}</p>
        <a target="_blank" [routerLink]="[ '/punchcards', punchCard.id, 'edit' ]" translate>Link</a>
      </div>
      <div *ngFor="let trip of payRecordData.assignment.trips; let i = index" class="context-link">
        <p translate>Trip {{ i + 1 }}</p>
        <a target="_blank" [routerLink]="[ '/trips', trip.id, 'edit' ]" translate>Link</a>
      </div>
    </div>
  </div>
  <div class="bottom-actions">
    <button (click)="reset.emit(true)" class="btn btn-default" translate>Undo Edits</button>
    <button (click)="createPayLine()" class="btn btn-primary" translate>Create Pay Line</button>
  </div>
</div>
