"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment");
var collaborator_service_1 = require("./collaborator.service");
var connection_service_1 = require("../connections/connection.service");
var api_service_1 = require("../shared/api.service");
var app_constants_1 = require("../app.constants");
var NewDaysCollaborationDialogComponent = /** @class */ (function () {
    function NewDaysCollaborationDialogComponent(dialogRef, collaboratorService, connectionService, elementRef) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.connectionService = connectionService;
        this.elementRef = elementRef;
        this.jobEvent = null;
        this.daysAdded = [];
        this.completed = new core_1.EventEmitter();
        this.selectedCollaborators = [];
        this.selectedCount = 0;
        this.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        this.invoiceTypeOptions = [
            { value: 'weight', label: 'Weight', name: 'Weight' },
            { value: 'load', label: 'Load', name: 'Load' },
            { value: 'hour', label: 'Hour', name: 'Hour' }
        ];
        this.rateTrackingConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.collaborators = [];
        this.loading = false;
        this.errors = [];
    }
    NewDaysCollaborationDialogComponent.prototype.ngOnInit = function () {
        this.getCollaborators();
    };
    NewDaysCollaborationDialogComponent.prototype.getCollaborators = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.collaboratorsReq) {
            this.collaboratorsReq.unsubscribe();
        }
        this.loading = true;
        this.collaboratorsReq = this.collaboratorService.getCollaborators(this.jobEvent.id).subscribe(function (collaborators) {
            _this.collaborators = collaborators.map(function (collaborator) {
                collaborator.invoiceTypeOption = lodash_1.find(_this.invoiceTypeOptions, {
                    value: collaborator.invoiceType
                });
                collaborator.weightOption = lodash_1.find(_this.weightOptions, {
                    value: collaborator.invoiceRateUnit
                });
                return collaborator;
            });
            _this.loading = false;
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    NewDaysCollaborationDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var rates = this.collaborators.map(function (collaborator) {
            if (collaborator.selected) {
                return {
                    organization: collaborator.organizationId,
                    haulRate: collaborator.invoiceRate,
                    haulType: collaborator.invoiceType,
                    haulWeightUnit: collaborator.invoiceWeightUnit,
                    numTrucks: collaborator.numTrucks
                };
            }
        });
        var model = {
            dates: this.daysAdded && this.daysAdded.map(function (date) { return moment(date).format('YYYY-MM-DD'); }),
            rates: rates.filter(function (rate) { return rate !== undefined; })
        };
        this.collaboratorService.multiShare(this.jobEvent.job.id, model).subscribe(function (result) {
            _this.loading = false;
            _this.dialogRef.close();
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    NewDaysCollaborationDialogComponent.prototype.setSelectedType = function (collaborator, e, form) {
        form.controls['rate_tracking[' + collaborator.id + ']'].markAsDirty();
        collaborator.invoiceType = e.value;
    };
    NewDaysCollaborationDialogComponent.prototype.setSelectedWeight = function (collaborator, e, form) {
        form.controls['invoice_rate[' + collaborator.id + ']'].markAsDirty();
        collaborator.invoiceWeightUnit = e.value;
    };
    NewDaysCollaborationDialogComponent.prototype.selector = function (event, collaborator) {
        if (!event.target.checked) {
            collaborator.selected = false;
            lodash_1.pull(this.selectedCollaborators, collaborator.id);
            this.selectedCount = this.selectedCollaborators.length;
        }
        else {
            collaborator.selected = true;
            this.selectedCollaborators.push(collaborator.id);
            this.selectedCount = this.selectedCollaborators.length;
        }
    };
    return NewDaysCollaborationDialogComponent;
}());
exports.NewDaysCollaborationDialogComponent = NewDaysCollaborationDialogComponent;
