"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
// libraries
var moment = require("moment");
// services
var shift_service_1 = require("../../shifts/shift.service");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var options_1 = require("./data/options");
// data
var options_2 = require("./data/options");
var DriverContextMenuComponent = /** @class */ (function () {
    function DriverContextMenuComponent(translateService, shiftService, shiftReportService, router, renderer, elementRef) {
        this.translateService = translateService;
        this.shiftService = shiftService;
        this.shiftReportService = shiftReportService;
        this.router = router;
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.emitActions = [];
        this.useDefaultCoordinates = false;
        this.additionalMenuItems = [];
        this.onShiftEnd = new core_1.EventEmitter();
        this.menuSelection = new core_1.EventEmitter();
        this.contextMenuPosition = { x: '100px', y: '100px' };
        this.contextMenuOptions = [];
        this.showError = false;
    }
    DriverContextMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.contextMenuOptions = options_2.shiftMenuOptions.concat(this.additionalMenuItems);
        this.contextMenuEventSubject.subscribe(function (driverContextEvent) {
            _this.onContextMenuRightClick(driverContextEvent);
        });
    };
    DriverContextMenuComponent.prototype.ngAfterViewInit = function () {
        var height = this.contextMenuOptions.length * 48 + 74;
        this.renderer.setStyle(this.elementRef.nativeElement, 'height', height + 'px');
    };
    DriverContextMenuComponent.prototype.ngOnDestroy = function () {
        this.contextMenuEventSubject.complete();
    };
    DriverContextMenuComponent.prototype.onContextMenuRightClick = function (driverContextEvent) {
        if (driverContextEvent.shiftReport) {
            this.currentDriverShiftData = this.getShiftDataObject([
                driverContextEvent.shiftReport,
            ]);
        }
        else {
            this.getShiftData(driverContextEvent.driverId);
        }
        this.openContextMenu(driverContextEvent.event);
        this.contextMenuDriverId = driverContextEvent.driverId;
        this.driverName = driverContextEvent.name;
    };
    DriverContextMenuComponent.prototype.openContextMenu = function (event) {
        event.preventDefault();
        if (!this.useDefaultCoordinates) {
            this.contextMenuPosition.x = event.clientX + 'px';
            this.contextMenuPosition.y = event.clientY + 'px';
        }
        this.contextMenu.menuData = this.contextMenuOptions;
        this.contextMenu.openMenu();
    };
    DriverContextMenuComponent.prototype.onContextMenuAction = function (event, item) {
        if (item.disabled) {
            return;
        }
        event.stopPropagation();
        if (this.emitActions.includes(item.action)) {
            this.menuSelection.emit({
                menuItem: item,
                driverId: this.contextMenuDriverId,
            });
            this.onContextMenuClose();
        }
        else {
            this.onContextMenuSelection(item);
        }
    };
    DriverContextMenuComponent.prototype.onContextMenuClose = function () {
        this.event = null;
        this.contextMenuDriverId = null;
        this.contextMenu.closeMenu();
    };
    DriverContextMenuComponent.prototype.onContextMenuSelection = function (menu) {
        switch (menu.action) {
            case options_2.ViewEndShiftAction:
                this.endShift();
                break;
            case options_2.ViewDriverProfileAction:
                this.router.navigate(['drivers', this.contextMenuDriverId, 'details', 'details'], {
                    queryParams: { returnTo: this.router.url },
                });
                break;
            case options_2.ViewAssignmentsOptionAction:
                this.router.navigate(['jobs', 'drivers'], {
                    queryParams: {
                        driver_assignments: this.contextMenuDriverId,
                        returnTo: this.router.url,
                    },
                });
                break;
            case options_1.FleetHealthOptionAction:
                this.router.navigate(['fleet-health'], {
                    queryParams: {
                        search: this.driverName,
                    },
                });
        }
    };
    DriverContextMenuComponent.prototype.getShiftData = function (driverId) {
        var _this = this;
        this.shiftReportService
            .list({
            driver: driverId,
            page_size: 1,
            ordering: '-end_time',
            include_leased_fleet_drivers: 'True',
        })
            .subscribe(function (shifts) {
            _this.currentDriverShiftData = _this.getShiftDataObject(shifts);
        });
    };
    DriverContextMenuComponent.prototype.getShiftDataObject = function (shifts) {
        if (shifts && shifts.length) {
            var shift = shifts[0];
            if (shift.endTime) {
                // shift ended
                this.contextMenuOptions[0].disabled = true;
                var totalMinutes = moment
                    .duration(moment(shift.endTime).diff(shift.startTime))
                    .asMinutes();
                var newShiftData = {
                    value: moment(shift.endTime).format('MMM DD, YYYY') + " - " + app_utilities_1.AppUtilities.getFormattedHoursAndMinutesFromMinutes(totalMinutes),
                    text: this.translateService.instant('Shift Ended'),
                    shiftStatus: 'ended',
                    shiftId: shift.id,
                };
                this.currentDriverShiftData = newShiftData;
                return newShiftData;
            }
            else {
                // shift still in progress
                this.contextMenuOptions[0].disabled = false;
                var totalMinutes = moment
                    .duration(moment(undefined).diff(shift.startTime))
                    .asMinutes();
                var isLong = totalMinutes > 1440;
                var newShiftData = {
                    value: app_utilities_1.AppUtilities.getFormattedHoursAndMinutesFromMinutes(totalMinutes),
                    text: this.translateService.instant(isLong ? 'Active Over 24 Hours' : 'On Shift'),
                    shiftStatus: isLong ? 'long' : 'active',
                    shiftId: shift.id,
                };
                this.currentDriverShiftData = newShiftData;
                return newShiftData;
            }
        }
        else {
            this.contextMenuOptions[0].disabled = true;
            var newShiftData = {
                value: this.translateService.instant('No Shifts'),
                text: null,
                shiftStatus: null,
                shiftId: null,
            };
            this.currentDriverShiftData = newShiftData;
            return newShiftData;
        }
    };
    DriverContextMenuComponent.prototype.endShift = function () {
        var _this = this;
        var shiftData = __assign({}, this.currentDriverShiftData);
        var endTime = new Date();
        var end = moment(endTime).toISOString();
        var shift = {
            id: shiftData.shiftId,
            endTime: end,
        };
        this.shiftService
            .save(shift, { include_leased_fleet_drivers: 'True' })
            .subscribe(function () {
            _this.showError = false;
            if (_this.onShiftEnd) {
                _this.onShiftEnd.emit();
            }
            _this.onContextMenuClose();
        }, function () {
            _this.showError = true;
        });
    };
    return DriverContextMenuComponent;
}());
exports.DriverContextMenuComponent = DriverContextMenuComponent;
