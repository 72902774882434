"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Device = /** @class */ (function () {
    function Device() {
        this.info = null;
        this.mobile = false;
        this.tablet = false;
        this.desktop = false;
    }
    return Device;
}());
exports.Device = Device;
