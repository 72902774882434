<div class="app-content" (window:resize)="onResize($event)">
  <my-toolbar [title]="toolbarTitle" (updatedMarkets)="updatedMarkets()">
    <div *ngIf="toolbarTitle !== 'Dashboard' && toolbarTitle !== 'Live Map'" class="mobile-toolbar-action left-actions" left-first>
      <button (click)="assignmentToggle = false"><span class="icon-close"></span></button>
    </div>
  </my-toolbar>

  <div class="dashboard-container">
    <active-trucks #activeTrucks (assignmentTitle)="toolbarState($event)" [assignmentToggle]="assignmentToggle"></active-trucks>
    <div class="left-column">
      <daily-shifts #dailyShifts></daily-shifts>
      <daily-production #dailyProduction></daily-production>
      <needs-dispatched #needsDispatched></needs-dispatched>

      <esb-uploader #esbUploader *ngIf="enabledFeatures.includes('hasEsbUploader')"></esb-uploader>
    </div>
    <div class="right-column">
      <location-status #locationStatus></location-status>
    </div>
    <!-- <assignment-acceptance #assignmentAcceptance></assignment-acceptance> -->
  </div>
</div>
