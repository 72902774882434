import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogModule, MatDatepickerModule, MatTooltipModule,
  MatTableModule, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import { DriverPayComponent } from './driver/driver-pay.component';
import { DriverPayPrereportComponent } from './driver/prereport/driver-pay-prereport.component';
import { DriverPayTableComponent } from './driver/table/driver-pay-table.component';
import { DriverPayTableSublistComponent } from './driver/table/table-sublist/driver-pay-table-sublist.component';
import { DriverPayTimelineComponent } from './driver/timeline/driver-pay-timeline.component';
import { DriverPayTimelineListComponent } from './driver/timeline/timeline-list/driver-pay-timeline-list.component';
import { DriverPayTimelineEditPanelComponent } from './driver/timeline/timeline-edit-panel/driver-pay-timeline-edit-panel.component';
import { GenerateReportDialogComponent } from './driver/generate-report-dialog/generate-report-dialog.component';
import { BulkEditDialogComponent } from './driver/bulk-edit-dialog/bulk-edit-dialog.component';
import { PaySheetService } from './pay-sheet.service';
import { PaySheetsComponent } from './pay-sheets.component';
import { ReportTypeDialogComponent } from './report-type-dialog/report-type-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule
  ],
  providers: [
    PaySheetService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  declarations: [
    DriverPayComponent,
    DriverPayPrereportComponent,
    DriverPayTableComponent,
    DriverPayTableSublistComponent,
    DriverPayTimelineComponent,
    DriverPayTimelineListComponent,
    DriverPayTimelineEditPanelComponent,
    GenerateReportDialogComponent,
    BulkEditDialogComponent,
    PaySheetsComponent,
    ReportTypeDialogComponent
  ],
  entryComponents: [
    GenerateReportDialogComponent,
    BulkEditDialogComponent,
    ReportTypeDialogComponent
  ],
  exports: [
    DriverPayComponent,
    DriverPayTableComponent,
    DriverPayTableSublistComponent,
    DriverPayTimelineComponent,
    DriverPayTimelineListComponent,
    DriverPayTimelineEditPanelComponent,
    GenerateReportDialogComponent,
    BulkEditDialogComponent,
    PaySheetsComponent,
    ReportTypeDialogComponent
  ]
})
export class DriverPayModule {}
