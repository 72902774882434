import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';
import { pick } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../shared/index';
import { PreferenceService } from '../preferences/preference.service';
import { EditJobEventDialogComponent } from '../job-events/edit-job-event-dialog.component';
import { EditShareDialogComponent } from '../collaborators/edit-share-dialog.component';
import { EffectiveRateDialogComponent } from './effective-rate-dialog.component';
import { Job } from './job';
import { JobEvent } from '../job-events/job-event';
import { User } from '../users/user';
import { JobEventShare } from '../job-event-shares/job-event-share';

@Component({
  selector: 'day-details',
  templateUrl: './day-details.component.html',
  styleUrls: ['./day-details.component.scss']
})
export class DayDetailsComponent implements OnInit {
  @Input() job: Job;
  @Input() jobEvent: JobEvent;
  @Input() includeJobStats = false;
  @Input() linkToJobEvent = false;
  @Input() controlPosition = 'right';
  view: 'dayDetails' | 'orderSummary' = 'dayDetails';
  device;
  user: User;
  errors = [];
  effectiveRatePreferences = {
    rate: 0,
    rateType: 'hour',
    effectiveTons: 0,
    effectiveTime: 0
  };
  effectiveRate = 0;
  effectiveRateUnit = '';
  tooltipMessage = '';

  saveJobEventCallback = (e) => {
    if (e && e.jobEvent) {
      this.jobEvent = e.jobEvent;
    }
  }
  saveShareCallback = (result: {
    jobEventShare: JobEventShare,
    jobEvent: JobEvent
  }) => {
    const jobEventShare = result.jobEventShare;
    const updates = pick(jobEventShare, [
      'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
      'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
    ]);
    updates['rate'] = updates['invoiceRate'];
    Object.assign(this.jobEvent, updates);
  }
  effectiveRateCallback = (preference) => {
    if (preference && preference.blob) {
      this.effectiveRatePreferences = pick(preference.blob, [ 'rate', 'rateType', 'effectiveTons', 'effectiveTime' ]);
      this.calculateEffectiveRate();
    }
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.user = this.authenticationService.user();
    this.getPreferences(() => {
      setTimeout(() => { this.calculateEffectiveRate(); }, 750 ); // Wait for stats property to populate on jobEvent
    });
  }

  getPreferences(callback: () => void): void {
    const params = {
      name: 'EffectiveRateUserPreferences',
      type: 'user',
      profile: this.user.id
    };
    this.preferenceService.list(params).subscribe(preferences => {
      if (preferences.length) {
        this.effectiveRatePreferences = pick(preferences[0].blob, [ 'rate', 'rateType', 'effectiveTons', 'effectiveTime' ]);
      }
    });
    callback();
  }

  openEditJobEvent(): void {
    const dialog = this.dialog.open(EditJobEventDialogComponent, {
      width: '430px'
    });
    if (dialog) {
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.callback = this.saveJobEventCallback;
    }
  }

  openEditShare(change): void {
    const dialog = this.dialog.open(EditShareDialogComponent, {
      width: '430px'
    });
    if (dialog) {
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.change = change;
      dialog.componentInstance.callback = this.saveShareCallback;
    }
  }

  openEffectiveRate(): void {
    const dialog = this.dialog.open(EffectiveRateDialogComponent, {
      width: '430px'
    });
    if (dialog && dialog.componentInstance) {
      dialog.componentInstance.user = this.user;
      dialog.componentInstance.model = {
        rate: this.effectiveRatePreferences.rate,
        rateType: this.effectiveRatePreferences.rateType,
        effectiveTons: this.effectiveRatePreferences.effectiveTons,
        effectiveTime: this.effectiveRatePreferences.effectiveTime
      };
      dialog.componentInstance.callback = this.effectiveRateCallback;
    }
  }

  calculateEffectiveRate(): void {
    if (this.jobEvent && this.jobEvent.stats) {
      let effectiveTons = this.effectiveRatePreferences.effectiveTons === 0 ?
        this.jobEvent.stats.totalTons : this.jobEvent.stats.ticketedWeight;
      let effectiveTime = this.effectiveRatePreferences.effectiveTime === 0 ?
        this.jobEvent.stats.totalTripHours : this.jobEvent.stats.totalCompletedTripHours;
      const effectiveTimeTitle = this.effectiveRatePreferences.effectiveTime === 0 ?
                                 this.translateService.instant('Total Active Trip Time') :
                                 this.translateService.instant('Total Completed Trip Time') ;
      const effectiveTonsTitle = this.effectiveRatePreferences.effectiveTons === 0 ?
                                 this.translateService.instant('Tickets Delivered') :
                                 this.translateService.instant('Tickets Ticketed') ;
      if (this.effectiveRatePreferences.rateType === 'ton') {
        this.effectiveRate = effectiveTons > 0 ? (this.effectiveRatePreferences.rate * effectiveTons) / effectiveTime : 0;
        this.effectiveRateUnit = 'hour';
        this.tooltipMessage = `${this.translateService.instant('Ton Rate')} (${this.effectiveRatePreferences.rate}) * ${effectiveTonsTitle} (${effectiveTons}) / ${effectiveTimeTitle} (${effectiveTime})`;
      } else if (this.effectiveRatePreferences.rateType === 'hour') {
        this.effectiveRate = effectiveTime > 0 ? (this.effectiveRatePreferences.rate * effectiveTime) / effectiveTons : 0;
        this.effectiveRateUnit = 'ton';
        this.tooltipMessage = `${this.translateService.instant('Hourly Rate')} (${this.effectiveRatePreferences.rate}) * ${effectiveTimeTitle} (${effectiveTime}) / ${effectiveTonsTitle} (${effectiveTons})`;
      }
    }
  }
}
