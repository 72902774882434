"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var camelcaseKeysDeep = require('camelcase-keys-deep');
var ShiftFilter = /** @class */ (function () {
    function ShiftFilter(shiftFilterInfo) {
        shiftFilterInfo = camelcaseKeysDeep(shiftFilterInfo);
        this.id = shiftFilterInfo.id;
        this.name = shiftFilterInfo.name;
    }
    return ShiftFilter;
}());
exports.ShiftFilter = ShiftFilter;
