<div mat-dialog-title class="modal-title">
  <h1 translate>Thresholds Settings</h1>
  <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
</div>

<div mat-dialog-content>
  <div class="form-container">

    <div class="field-group threshold-input">
      <label class="required">Threshold</label>
      <input 
        type="number"
        placeholder="{{ 'Value without %' | translate }}"
        [formControl]="thresholdsForm"
      >
      <span [ngClass]="{'ng-valid': thresholdsForm.valid, 'ng-invalid': thresholdsForm.invalid }">
        <div class="input-icon" *ngIf="thresholdsForm.valid">
          <div class="mat-icon-container">
            <mat-icon class="mat-icon">percent</mat-icon>
          </div>
        </div>
      </span>
    </div>

    <div class="actions">
      <div class="input-color-picker"
        [style.background]="newRuleColor"
        [cpOutputFormat]="'rgba'"
        [(colorPicker)]="newRuleColor"
      ></div>
      <button class="btn btn-primary" [disabled]="!thresholdsForm.valid || duplicatedThreshold" (click)="addRule()">
        <span class="icon-plus"></span>
        Add
      </button>
    </div>
  </div>

  <p>Current rules.</p>
  <div class="thresholds" *ngIf="(thresholdConfig$ | async) as thresholdConfig">
    <ng-container *ngFor="let rule of thresholdConfig">
      <div class="threshold-rule">
        <div class="threshold-rule-value">
          &plusmn; {{ rule.value | percent: '1.2-2' }}
        </div>

        <div class="class-container">
          <div class="threshold-rule-class"
            [style.background]="rule.color || defaultColor"
            [colorPicker]="rule.color || defaultColor"
            [cpOutputFormat]="'rgba'"
            (colorPickerChange)="rule.color = $event; saveEnabled = true;"
          >
          </div>
          <div class="close-action" (click)="removeRule(rule.value)"><i class="icomoon icon-close"></i></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <button
    class="btn btn-primary"
    (click)="saveChanges()"
    [disabled]="!saveEnabled"
  >
    Save Changes
  </button>
</div>