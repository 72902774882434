"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var address_1 = require("../address");
var AddressSelectComponent = /** @class */ (function () {
    function AddressSelectComponent(deviceDetectorService) {
        this.deviceDetectorService = deviceDetectorService;
        this.loading = false;
        this.addresses = [];
        this.showingAddresses = false;
        this.addressChanged = new core_1.EventEmitter();
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    AddressSelectComponent.prototype.addressSelected = function (address) {
        this.selectedAddress = address;
        this.addressChanged.emit(this.selectedAddress);
        this.showingAddresses = false;
    };
    AddressSelectComponent.prototype.showAddresses = function () {
        this.showingAddresses = !this.showingAddresses;
    };
    return AddressSelectComponent;
}());
exports.AddressSelectComponent = AddressSelectComponent;
