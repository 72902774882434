"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CdAutocompleteResponse = /** @class */ (function () {
    function CdAutocompleteResponse(q, key) {
        this.q = q;
        if (key) {
            this.key = key;
        }
    }
    return CdAutocompleteResponse;
}());
exports.CdAutocompleteResponse = CdAutocompleteResponse;
