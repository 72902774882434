"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
// services
var driver_service_1 = require("../driver.service");
var DriverDetailsPage = /** @class */ (function () {
    function DriverDetailsPage(driverService, activatedRoute, router) {
        this.driverService = driverService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.error$ = new rxjs_1.Subject();
        this.tabChange = false;
    }
    DriverDetailsPage.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            _this.selectedTab = params.tab ? params.tab : 'details';
            if (_this.tabChange) {
                var oldQueryParams = __assign({}, _this.activatedRoute.snapshot.queryParams);
                if (oldQueryParams.page) {
                    var queryParams = { returnTo: _this.returnUrl };
                    _this.router.navigate([], {
                        queryParams: queryParams,
                        queryParamsHandling: 'merge',
                    });
                }
            }
            else {
                _this.tabChange = true;
            }
        });
        var driverId = this.activatedRoute.snapshot.params.id;
        this.getDriver(driverId);
        this.activatedRoute.queryParams.subscribe(function (params) {
            return (_this.returnUrl = _this.returnUrl
                ? _this.returnUrl
                : params.returnTo || '/');
        });
    };
    DriverDetailsPage.prototype.getDriver = function (driverId) {
        var _this = this;
        this.driver$ = this.driverService.get(driverId).pipe(operators_1.catchError(function () {
            _this.error$.next(true);
            return rxjs_1.of(null);
        }));
    };
    DriverDetailsPage.prototype.updatedMarkets = function () { };
    return DriverDetailsPage;
}());
exports.DriverDetailsPage = DriverDetailsPage;
