import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray',
  pure: true,
})
export class IsInArrayPipe implements PipeTransform {
  transform(
    arr: string[] | any[],
    value: string | any,
    keyToCompare?: string
  ): boolean {
    if (typeof value === 'string') {
      return arr.indexOf(value) !== -1;
    } else {
      return arr.some((item) => item[keyToCompare] === value[keyToCompare]);
    }
  }
}
