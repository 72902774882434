"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MobileSearchResultsComponent = /** @class */ (function () {
    function MobileSearchResultsComponent() {
        this.activeResult = new core_1.EventEmitter();
    }
    MobileSearchResultsComponent.prototype.selectActiveResult = function (result, index) {
        this.activeResult.emit({ result: result, index: index });
    };
    return MobileSearchResultsComponent;
}());
exports.MobileSearchResultsComponent = MobileSearchResultsComponent;
