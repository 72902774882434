"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TextTrimDirective = /** @class */ (function () {
    function TextTrimDirective(elementRef, renderer) {
        this.elementRef = elementRef;
        this.renderer = renderer;
    }
    TextTrimDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.listen(this.elementRef.nativeElement, 'input', function ($event) {
            _this.trimText($event);
        });
        this.renderer.listen(this.elementRef.nativeElement, 'blur', function ($event) {
            _this.trimTextOnBlur($event);
        });
    };
    TextTrimDirective.prototype.trimText = function (event) {
        var trimmedText = event.target.value.trim();
        if (trimmedText === '') {
            event.target.value = trimmedText;
        }
    };
    TextTrimDirective.prototype.trimTextOnBlur = function (event) {
        event.target.value = event.target.value.trim();
    };
    return TextTrimDirective;
}());
exports.TextTrimDirective = TextTrimDirective;
