import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';

import { ControlReportsRoutingModule } from './control-reports-routing.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { SharedModule } from '../shared/shared.module';
import { ThresholdsDialogComponent } from './thresholds-dialog/thresholds-dialog.component';
import { ControlReportsActionbarComponent } from './control-reports-actionbar/control-reports-actionbar.component';
import { ChartDialogComponent } from './chart-dialog/chart-dialog.component';


@NgModule({
  declarations: [
    NotificationsComponent,
    ThresholdsDialogComponent,
    ControlReportsActionbarComponent,
    ChartDialogComponent
  ],
  imports: [
    CommonModule,
    ControlReportsRoutingModule,
    SharedModule,
    ColorPickerModule
  ],
  entryComponents: [
    ChartDialogComponent,
    ThresholdsDialogComponent
  ]
})
export class ControlReportsModule { }
