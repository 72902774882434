"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var forms_1 = require("@angular/forms");
// libraries
var moment = require("moment-timezone");
// angular material
var material_1 = require("@angular/material");
// services
var trip_service_1 = require("./../trip.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var api_service_1 = require("../../shared/api.service");
var shared_1 = require("../../shared");
var attachment_service_1 = require("../../attachments/attachment.service");
var trip_1 = require("./../trip");
// components
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
// constants
var app_constants_1 = require("../../app.constants");
var dropdownsConfig_1 = require("./dropdownsConfig");
// validators
var trip_time_validators_1 = require("../trip-time-validators");
var NewTripDialogComponent = /** @class */ (function () {
    function NewTripDialogComponent(dialogRef, tripService, attachmentsService, jobEventService, authenticationService, translate, fb) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.tripService = tripService;
        this.attachmentsService = attachmentsService;
        this.jobEventService = jobEventService;
        this.authenticationService = authenticationService;
        this.translate = translate;
        this.fb = fb;
        this.lockedFields = false;
        this.jobEventDate = null;
        this.jobEventDatePickerOptions = null;
        this.highlightedDates = [];
        this.jobEvents = [];
        this.model = {
            jobevent: null,
            carrier: null,
            truck: null,
            driver: null,
            startTime: null,
            startDate: null,
            endTime: null,
            endDate: null,
            loadingCheckin: {
                attachments: [],
                ticketNumber: null,
                weight: null,
                date: null,
                time: null,
            },
            unloadingCheckin: {
                attachments: [],
                ticketNumber: null,
                weight: null,
                date: null,
                time: null,
            },
        };
        this.loading = false;
        this.errors = [];
        this.jobEventError = false;
        this.activeTab = 'trip-data';
        this.jobDropdownConfig = dropdownsConfig_1.JOB_DROPDOWN_CONFIG(this.translate);
        this.jobEventsDropdownConfig = dropdownsConfig_1.JOB_EVENTS_DROPDOWN_CONFIG(this.translate);
        this.carrierDropdownConfig = dropdownsConfig_1.CARRIER_DROPDOWN_CONFIG(this.translate);
        this.driverDropdownConfig = dropdownsConfig_1.DRIVER_DROPDOWN_CONFIG(this.translate);
        this.truckDropdownConfig = dropdownsConfig_1.TRUCK_DROPDOWN_CONFIG(this.translate);
        this.firstLoad = true;
        this.loadingTicketImages = [];
        this.unloadingTicketImages = [];
        this.loadingSignatures = [];
        this.unloadingSignatures = [];
        this.uploading = false;
        this.fieldsToCheck = [
            'activeTracking',
            'ticketImage',
            'signatureImage',
            'ticketNumber',
            'weight',
        ];
        this.disabledFields = {
            carrier: false,
            driver: false,
            calendar: false,
        };
        this.requiredImages = {
            loadingImages: false,
            unloadingImages: false,
            loadingSignature: false,
            unloadingSignature: false,
        };
        this.parseDateTime = function (date, time) {
            return !!date && !!time
                ? moment(moment(date).format('YYYY-MM-DD') + " " + time).toISOString()
                : null;
        };
        this.onImagesChangeParser = function (images) {
            return images
                .filter(function (img) { return img.isDeleted !== true; })
                .map(function (img) {
                return img.isNew || img.isEdited
                    ? Object.assign(img, {
                        tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                    })
                    : _this.parseImageOnChange(img);
            });
        };
    }
    NewTripDialogComponent.prototype.ngOnInit = function () {
        var user = this.authenticationService.user();
        this.carrierDropdownConfig.query = {
            exclude: user.organization.id,
        };
        this.carrierDropdownConfig.prefilledOptions.push(user.organization);
        this.initForm();
    };
    NewTripDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        var newTrip = this.parseTrip();
        this.tripService.saveWithCheckinsAndAttachments(newTrip).subscribe(function (res) {
            var trip = new trip_1.Trip(res);
            _this.dialogRef.close();
            _this.callback(trip);
        }, function (err) {
            _this.errors = api_service_1.parseErrors(Array.isArray(err) ? err : [err]);
            _this.loading = false;
        });
    };
    NewTripDialogComponent.prototype.parseTrip = function () {
        var _this = this;
        var formTrip = this.newTripForm.value;
        var loadingImages = this.loadingTicketImages.concat(this.loadingSignatures.map(function (signature) {
            Object.assign(signature, {
                tempType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
            });
            return signature;
        }));
        var unloadingImages = this.unloadingTicketImages.concat(this.unloadingSignatures.map(function (signature) {
            Object.assign(signature, {
                tempType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
            });
            return signature;
        }));
        var parsedLoadingImages = loadingImages.map(function (img) {
            return _this.attachmentsService.parseAttachment(img, img.tempType ? img.tempType : app_constants_1.TRIPTICKETIMAGETYPE, null);
        });
        var parsedUnloadingImages = unloadingImages.map(function (img) {
            return _this.attachmentsService.parseAttachment(img, img.tempType ? img.tempType : app_constants_1.TRIPTICKETIMAGETYPE, null);
        });
        var trip = __assign({}, this.newTripForm.value, { job: formTrip.job.id, jobEvent: formTrip.jobevent, jobevent: formTrip.jobevent, carrier: formTrip.carrier.id, driver: formTrip.driver.id, truck: formTrip.truck.id, startTime: this.parseDateTime(formTrip.start.date, formTrip.start.time), endTime: this.parseDateTime(formTrip.end.date, formTrip.end.time), loadingCheckin: __assign({}, formTrip.loadingCheckin, { attachments: parsedLoadingImages }, {
                date: this.parseDateTime(formTrip.loadingCheckin.date, formTrip.loadingCheckin.time),
            }), unloadingCheckin: __assign({}, formTrip.unloadingCheckin, {
                date: this.parseDateTime(formTrip.unloadingCheckin.date, formTrip.unloadingCheckin.time),
            }, { attachments: parsedUnloadingImages }) });
        return trip;
    };
    NewTripDialogComponent.prototype.onJobEventDateChanged = function (values) {
        this.jobEventError = false;
        if (!this.firstLoad && this.job && values && values.length) {
            if (!this.disabledFields.carrier && !this.disabledFields.driver) {
                this.model.carrier = null;
                this.model.driver = null;
            }
            this.model.truck = null;
            var selectedDate_1 = moment(values[0]).format().split('T')[0];
            var jobEvent = this.jobEvents.find(function (j) {
                return j.shift1Start.includes(selectedDate_1);
            });
            this.jobEventDate = values[0];
            if (jobEvent) {
                this.jobEvent = jobEvent;
                this.newTripFormSetRequiredFields(jobEvent);
                this.setRequiredImages(jobEvent);
                var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: jobEvent.id });
                this.carriersDropdown.config.query = query;
                this.carriersDropdown.getRecords(query);
                this.model.jobevent = jobEvent.id;
                this.newTripFormPatchValue('jobevent', jobEvent.id);
            }
            else {
                this.jobEventError = true;
                this.jobEvent = null;
            }
        }
    };
    NewTripDialogComponent.prototype.onDateChange = function (args, dates) {
        console.log(this.newTripForm);
        if (args && args.length > 0 && dates) {
            this.newTripFormPatchValueNested(args, dates[0]);
        }
    };
    NewTripDialogComponent.prototype.editDateTime = function (field, value) {
        if (value) {
            var checkinField = 'loadingTime' === field ? 'loadingCheckin' : 'unloadingCheckin';
            var date = this.model[checkinField].date || new Date();
            if (!!this.model[checkinField].date) {
                this.model[checkinField].date = date;
                this.newTripFormPatchValueNested([checkinField, 'date'], date);
            }
            this.model[checkinField].time = value;
            this.newTripFormPatchValueNested([checkinField, 'time'], value);
        }
    };
    NewTripDialogComponent.prototype.enableEditing = function () {
        this.lockedFields = false;
        this.firstLoad = true;
        this.disabledFields.carrier = true;
        this.disabledFields.calendar = true;
    };
    NewTripDialogComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                if (this.disabledFields.carrier || this.disabledFields.calendar) {
                    this.disabledFields.carrier = false;
                    this.disabledFields.calendar = false;
                }
                // if newly selected job is the same as existing selected job
                if (this.job && this.job.id === e.id) {
                    if (this.firstLoad) {
                        this.firstLoad = false;
                        this.getJobEvents(e.id);
                        this.newTripFormPatchValue(filterType, e);
                    }
                    break;
                }
                this.jobEventDatePickerOptions = null;
                this.job = e;
                this.jobId = e.id;
                if (this.firstLoad) {
                    this.firstLoad = false;
                }
                else {
                    this.resetForm();
                }
                this.getJobEvents(e.id);
                this.newTripFormPatchValue(filterType, e);
                break;
            case 'carrier':
                var carrier = e.carrier;
                if (this.jobEvent &&
                    (this.model.carrier === null || this.model.carrier.id !== carrier.id)) {
                    this.model.carrier = carrier;
                    this.model.driver = null;
                    this.model.truck = null;
                    this.driversDropdown.getRecords({
                        carrier: carrier.id,
                        shared_jobevent: this.jobEvent.id,
                    });
                    this.truckDropdown.getRecords({
                        carrier: carrier.id,
                    });
                    this.newTripFormPatchValue('driver', null);
                    this.newTripFormPatchValue('truck', null);
                }
                this.newTripFormPatchValue(filterType, carrier);
                break;
            default:
                this.model[filterType] = e;
                this.newTripFormPatchValue(filterType, e);
                break;
        }
    };
    NewTripDialogComponent.prototype.getJobEvents = function (jobId, query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        this.loading = true;
        this.jobEventError = false;
        // shift1Start
        this.jobEventService
            .list(__assign({ ordering: 'shift1_start_timestamp', job: jobId, page_size: 100 }, query))
            .subscribe(function (jobEvents) {
            var newDates = jobEvents.map(function (j) { return j.shift1Start; });
            _this.jobEvents = jobEvents.slice();
            var jobEvent = _this.jobEvent
                ? _this.jobEvent
                : _this.getJobEventWithDateAndJobEvents(jobEvents, _this.jobEventDate || newDates[0]);
            if (newDates.length && jobEvent && jobEvent.id) {
                _this.highlightedDates = newDates;
                _this.jobEventDatePickerOptions = {
                    min: newDates[0],
                    max: newDates[newDates.length - 1],
                };
                var query_1 = __assign({}, _this.carrierDropdownConfig.query, (jobEvent &&
                    jobEvent.id && {
                    carriers_for_jobevent: jobEvent.id,
                }));
                _this.jobEvent = jobEvent;
                _this.setRequiredImages(jobEvent);
                _this.model.jobevent = jobEvent.id;
                _this.newTripFormPatchValue('jobevent', jobEvent.id);
                _this.carriersDropdown.config.query = query_1;
                if (!_this.disabledFields.carrier && !_this.disabledFields.driver) {
                    _this.carriersDropdown.getRecords(query_1);
                }
                _this.newTripFormSetRequiredFields(jobEvent);
            }
            else {
                _this.jobEventError = true;
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
        });
    };
    NewTripDialogComponent.prototype.getJobEventWithDateAndJobEvents = function (jobEvents, jobEventDate) {
        var selectedDate = moment(jobEventDate).format().split('T')[0];
        return jobEvents.find(function (j) { return j.shift1Start.includes(selectedDate); });
    };
    NewTripDialogComponent.prototype.getJobEventWithId = function (jobEventId) {
        var _this = this;
        this.jobEventService.get(jobEventId).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
            _this.job = jobEvent.job;
            _this.newTripFormSetRequiredFields(jobEvent);
        });
    };
    NewTripDialogComponent.prototype.newTripFormPatchValue = function (key, value) {
        var _a;
        this.newTripForm.patchValue((_a = {},
            _a[key] = value,
            _a));
    };
    NewTripDialogComponent.prototype.newTripFormPatchValueNested = function (keys, value) {
        if (keys && keys.length > 0 && value) {
            var objToPatch = {};
            keys.reduce(function (prev, curr, i) {
                return (prev[curr] = i + i === keys.length ? value : {});
            }, objToPatch);
            this.newTripForm.patchValue(objToPatch);
        }
    };
    NewTripDialogComponent.prototype.newTripFormSetRequiredFields = function (jobEvent) {
        var _this = this;
        this.fieldsToCheck.forEach(function (field) {
            _this.newTripFormValidatorsSwitch('loadingCheckin', field, jobEvent.job.checkinOptions[field + "Config"] === 'required');
        });
        this.fieldsToCheck.forEach(function (field) {
            _this.newTripFormValidatorsSwitch('unloadingCheckin', field, jobEvent.job.checkoutOptions[field + "Config"] === 'required');
        });
        this.updateFormValueAndValidity(this.newTripForm);
    };
    NewTripDialogComponent.prototype.updateFormValueAndValidity = function (group) {
        var _this = this;
        Object.keys(group.controls).forEach(function (key) {
            var abstractControl = group.controls[key];
            if (abstractControl instanceof forms_1.FormGroup ||
                abstractControl instanceof forms_1.FormArray) {
                _this.updateFormValueAndValidity(abstractControl);
            }
            else {
                abstractControl.updateValueAndValidity();
            }
        });
    };
    NewTripDialogComponent.prototype.newTripFormValidatorsSwitch = function (parent, key, required) {
        switch (key) {
            case 'activeTracking':
                if (required) {
                    this.newTripFormSetValidators([parent, 'date'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                    this.newTripFormSetValidators([parent, 'time'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'date']);
                    this.newTripFormClearValidators([parent, 'time']);
                }
                break;
            case 'ticketImage':
                if (required) {
                    this.newTripFormSetValidators([parent, 'images'], [forms_1.Validators.required]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'images']);
                }
                break;
            case 'signatureImage':
                if (required) {
                    this.newTripFormSetValidators([parent, 'signatures'], [forms_1.Validators.required]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'signatures']);
                }
                break;
            case 'ticketNumber':
                if (required) {
                    this.newTripFormSetValidators([parent, 'ticketNumber'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'ticketNumber']);
                }
                break;
            case 'weight':
                if (required) {
                    this.newTripFormSetValidators([parent, 'weight'], [forms_1.Validators.required, forms_1.Validators.min(0)]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'weight']);
                }
                break;
            default:
                break;
        }
    };
    NewTripDialogComponent.prototype.newTripFormSetValidators = function (field, validators) {
        this.newTripForm.get(field).setValidators(validators);
    };
    NewTripDialogComponent.prototype.newTripFormClearValidators = function (field) {
        this.newTripForm.get(field).clearValidators();
    };
    NewTripDialogComponent.prototype.initForm = function () {
        this.model.startDate = this.jobEventDate;
        this.newTripForm = this.fb.group({
            job: [this.job, forms_1.Validators.required],
            jobevent: [this.model.jobevent, forms_1.Validators.required],
            driver: [this.model.driver, forms_1.Validators.required],
            carrier: [this.model.carrier, forms_1.Validators.required],
            truck: [this.model.truck, forms_1.Validators.required],
            start: this.fb.group({
                date: [this.model.startDate, [forms_1.Validators.required]],
                time: [this.model.startTime, [forms_1.Validators.required]],
            }),
            end: this.fb.group({
                date: [this.model.endDate],
                time: [this.model.endTime],
            }),
            loadingCheckin: this.fb.group({
                ticketNumber: [this.model.loadingCheckin.ticketNumber],
                weight: [this.model.loadingCheckin.weight],
                date: [this.model.loadingCheckin.date],
                time: [this.model.loadingCheckin.time],
                images: [null],
                signatures: [null],
            }),
            unloadingCheckin: this.fb.group({
                ticketNumber: [this.model.unloadingCheckin.ticketNumber],
                weight: [this.model.unloadingCheckin.weight],
                date: [this.model.unloadingCheckin.date],
                time: [this.model.unloadingCheckin.time],
                images: [null],
                signatures: [null],
            }),
        }, { validators: trip_time_validators_1.globalTripTimeValidator });
        if (this.model.jobevent) {
            this.getJobEventWithId(this.model.jobevent);
        }
    };
    NewTripDialogComponent.prototype.parseImageOnChange = function (image) {
        if (image) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                image.file = reader_1.result;
                image.src = reader_1.result;
            };
            reader_1.readAsDataURL(image);
            return image;
        }
        return image;
    };
    NewTripDialogComponent.prototype.onImagesChange = function (type, checkin, images) {
        var parsedImages = this.onImagesChangeParser(images);
        this[type] = parsedImages;
        this.newTripFormPatchValueNested([checkin, type.includes('Signature') ? 'signatures' : 'images'], parsedImages.slice());
    };
    NewTripDialogComponent.prototype.setRequiredImages = function (jobEvent) {
        this.requiredImages = {
            loadingImages: jobEvent.job.checkinOptions.ticketImageConfig === 'required',
            unloadingImages: jobEvent.job.checkoutOptions.ticketImageConfig === 'required',
            loadingSignature: jobEvent.job.checkinOptions.signatureImageConfig === 'required',
            unloadingSignature: jobEvent.job.checkoutOptions.signatureImageConfig === 'required',
        };
    };
    NewTripDialogComponent.prototype.fileUploading = function (uploading) {
        this.uploading = uploading;
    };
    NewTripDialogComponent.prototype.resetForm = function () {
        this.model.jobevent = null;
        this.jobEventDate = null;
        this.model.truck = null;
        if (!this.disabledFields.carrier) {
            this.model.carrier = null;
        }
        if (!this.disabledFields.driver) {
            this.model.driver = null;
        }
        this.newTripForm.reset();
    };
    Object.defineProperty(NewTripDialogComponent.prototype, "startDate", {
        get: function () {
            return this.newTripForm.get(['start', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripDialogComponent.prototype, "endDate", {
        get: function () {
            return this.newTripForm.get(['end', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripDialogComponent.prototype, "loadingCheckingDate", {
        get: function () {
            return this.newTripForm.get(['loadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripDialogComponent.prototype, "unloadingCheckingDate", {
        get: function () {
            return this.newTripForm.get(['unloadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripDialogComponent.prototype, "loadingCheckingTime", {
        get: function () {
            return this.newTripForm.get(['loadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripDialogComponent.prototype, "unloadingCheckingTime", {
        get: function () {
            return this.newTripForm.get(['unloadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    return NewTripDialogComponent;
}());
exports.NewTripDialogComponent = NewTripDialogComponent;
