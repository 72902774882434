import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { PurchaseOrder } from './purchase-order';
import { PurchaseOrderSerializer } from './purchase-order.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class PurchaseOrderService extends ResourceService<PurchaseOrder> {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;
  progress$;
  progress;

  constructor (http: HttpClient) {
    super(http, 'purchaseorders/', new PurchaseOrderSerializer());
  }

  void(model) {
    return this.save(Object.assign(model, { void: true }));
  }

  unvoid(model) {
    return this.save(Object.assign(model, { void: false }));
  }
}
