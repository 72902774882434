import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { PreferenceService } from '../preferences/preference.service';
import { parseErrors} from '../shared';
import { User } from '../users/user';

  @Component({
    selector: 'effective-rate-dialog',
    templateUrl: './effective-rate-dialog.component.html',
    styleUrls: ['./effective-rate-dialog.component.scss'],
    providers: [PreferenceService]
  })
  export class EffectiveRateDialogComponent {
    @ViewChild('effectiveRate', { static: false }) effectiveRate;
    loading = false;
    callback: any;
    errors = [];
    user: User;
    haulRateType = '';

    typeConfig = {
      nameProperty: 'name',
      group: false
    };
    rateTypeOptions = [
      { value: 'hour', label: 'Hour', name: 'Hour' },
      { value: 'ton', label: 'Ton', name: 'Ton' },
    ];
    effectiveTonsOptions = [
      { value: 0, label: 'Tickets Delivered', name: 'Tickets Delivered' },
      { value: 1, label: 'Tickets Ticketed', name: 'Tickets Ticketed' }
    ];
    effectiveTimeOptions = [
      { value: 0, label: 'Total Active Trip Time', name: 'Total Active Trip Time' },
      { value: 1, label: 'Total Completed Trip Time', name: 'Total Completed Trip Time' }
    ];
    model: any = {
      rate: 0,
      rateType: this.rateTypeOptions[0],
      effectiveTons: 0,
      effectiveTime: 0
    };

    constructor(
      public dialogRef: MatDialogRef<EffectiveRateDialogComponent>,
      private preferenceService: PreferenceService
      ) { }

    rateUnitChange(rateType: any, form: NgForm): void {
      this.model.rateType = rateType.value;
      form.controls['rate_type'].markAsDirty();
    }

    effectiveTonsChange(effectiveTons: any, form: NgForm): void {
      this.model.effectiveTons = effectiveTons.value;
      form.controls['effective_tons'].markAsDirty();
    }

    effectiveTimeChange(effectiveTime: any, form: NgForm): void {
      this.model.effectiveTime = effectiveTime.value;
      form.controls['effective_time'].markAsDirty();
    }

    submit(form: NgForm): void {
      if (form.dirty) {
        const updatedPreference = {
          name: 'EffectiveRateUserPreferences',
          type: 'user',
          profile: this.user.id,
          blob: this.model
        };
        this.loading = true;
        this.preferenceService.save(updatedPreference).subscribe(preference => {
          this.loading = true;
          this.dialogRef.close();
          this.callback(preference);
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      } else {
        this.dialogRef.close();
      }
    }
  }
