"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var Plant = /** @class */ (function () {
    function Plant() {
        this.selected = false;
    }
    Plant.prototype.url = function (action) {
        return '/plant-manager/plants/' + this.id + '/' + action;
    };
    Plant.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        return options;
    };
    return Plant;
}());
exports.Plant = Plant;
