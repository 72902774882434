import { Component } from '@angular/core';

import { EsbService } from '../../esb/esb.service';
import { EsbOrder } from '../../esb/esb-order';

@Component({
  selector: 'esb-uploader',
  templateUrl: './esb-uploader.component.html',
  styleUrls: ['./esb-uploader.component.scss']
})
export class EsbUploaderComponent {
  upload: File;
  loading = false;
  errors = [];
  orders: EsbOrder[] = [];

  constructor(
    private esbService: EsbService
  ) { }

  onResize(event) { }

  fileChange(event): void {
    this.loading = true;
    const file: File = event.target.files[0];

    this.esbService.import('order', file).subscribe(res => {
      this.orders = res['orders'];
      this.errors = res['errors'];
      this.loading = false;
    }, function (err) {
      console.error('err', err);
      this.loading = false;
    });
  }

  reload() {
    this.errors = [];
    this.orders = [];
  }
}
