"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var connection_service_1 = require("./connection.service");
var connection_dialog_component_1 = require("./connection-dialog.component");
var index_1 = require("../shared/dialogs/index");
var api_service_1 = require("../shared/api.service");
var ConnectionsListComponent = /** @class */ (function () {
    function ConnectionsListComponent(route, router, location, connectionService, translationService, dialog) {
        this.route = route;
        this.router = router;
        this.location = location;
        this.connectionService = connectionService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: this.translationService.instant('Name'), sortable: true },
            { key: 'location', title: this.translationService.instant('Location'), sortable: true },
            { key: 'identifiers', title: this.translationService.instant('Identifiers'), sortable: true },
            { key: 'primary-contact', title: this.translationService.instant('Primary Contact'), sortable: true },
            { key: 'primary-contact-email', title: this.translationService.instant('Email'), sortable: true },
            { key: 'billing-id', title: this.translationService.instant('Billing Id'), sortable: true },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'name', 'location', 'identifiers', 'primary-contact',
            'primary-contact-email', 'billing-id', 'action'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        // config for fancy table
        this.connectionTableConfig = {
            hasHeader: true,
            service: connection_service_1.ConnectionService,
            preferenceKey: 'ConnectionsListComponent-ConnectionService',
            query: { customer_only: 'False' },
            collectionTitle: this.translationService.instant('Connections'),
            noResultsText: this.translationService.instant('a connection'),
            sortBy: 'organization__name',
            sortDirection: 'asc',
            newRecordModal: this.openDialog.bind(this),
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
                { name: this.translationService.instant('Disconnect'), action: 'disconnect', link: false, external: false },
                { name: this.translationService.instant('Suspend'), action: 'suspend', link: false, external: false }
            ]
        };
        this.query = {};
        this.connections = [];
        this.page = 1;
        this.loading = true;
        this.errors = [];
        this.firstRun = true;
        this.type = 'all';
        this.changeSearchEmitter = new core_1.EventEmitter();
        this.noConnections = new core_1.EventEmitter();
    }
    ConnectionsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route && this.route.params) {
            this.route.params.forEach(function (params) {
                _this.type = params['type'] || 'all';
                _this.search = params['search'];
            });
        }
    };
    ConnectionsListComponent.prototype.ngOnChanges = function (changes) {
        var url, type;
        this.query = [];
        if (this.route && this.route.snapshot) {
            url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
            type = this.route.snapshot.params['type'] || 'all';
        }
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                var chng = changes[propName];
                if (!this.firstRun) {
                    if (propName === 'search') {
                        this.search = chng.currentValue;
                        this.query['search'] = this.search;
                    }
                    else if (propName === 'type') {
                        this.type = chng.currentValue;
                        this.query['type'] = this.type;
                        // USING QUERY FIELD
                        if (this.type === 'broker') {
                            this.query['is_broker'] = 'True';
                        }
                        else if (this.type === 'contractor') {
                            this.query['is_contractor'] = 'True';
                        }
                        else if (this.type === 'carrier') {
                            this.query['is_carrier'] = 'True';
                        }
                    }
                }
                else {
                    this.firstRun = false;
                    if (this.route && this.route.snapshot) {
                        this.search = this.route.snapshot.params['search'];
                        this.type = this.route.snapshot.params['type'] || 'all';
                    }
                }
            }
        }
        if (this.search || this.type) {
            if (this.search) {
                this.location.replaceState(url + '/' + type + '/' + this.search);
            }
            else {
                this.location.replaceState(url + '/' + type);
            }
            this.getConnections(this.query);
        }
    };
    ConnectionsListComponent.prototype.ngOnDestroy = function () {
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
    };
    ConnectionsListComponent.prototype.openDialog = function (view) {
        if (view === void 0) { view = 'invite'; }
        var dialog = this.dialog.open(connection_dialog_component_1.ConnectionDialogComponent, {
            width: '640px'
        });
        dialog.componentInstance.activeView = view;
    };
    ConnectionsListComponent.prototype.suspend = function (connection) {
        var _this = this;
        connection.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Suspend Connection?'),
            body: this.translationService.instant('You will no longer be able to send and receive jobs with \'' + connection.organization.name + '\'.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Suspend')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.connectionService.suspendConnection(connection.id).subscribe(function (result) {
                    connection.status = 'suspended';
                    connection.loading = false;
                    _this.connectionTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    connection.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    ConnectionsListComponent.prototype.disconnect = function (connection) {
        var _this = this;
        connection.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Disconnect?'),
            body: this.translationService.instant('You will no longer be able to send and receive jobs with \'' + connection.organization.name + '\'.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Disconnect')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.connectionService.disconnectConnection(connection.id).subscribe(function (result) {
                    connection.status = 'disconnected';
                    connection.loading = false;
                    _this.connectionTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    connection.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    ConnectionsListComponent.prototype.menuAction = function (action, connection) {
        switch (action) {
            case 'edit':
                this.router.navigateByUrl(connection.url(action));
                break;
            case 'suspend':
                this.suspend(connection);
                break;
            case 'disconnect':
                this.disconnect(connection);
                break;
        }
    };
    ConnectionsListComponent.prototype.getConnections = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
        query = __assign({}, query, this.buildTypeQuery(query, this.type));
        this.connectionsReq = this.connectionService.list(__assign({ search: this.search, customer_only: 'False' }, query)).subscribe(function (connections) {
            _this.connections = connections;
            _this.connectionTable.getRecords();
            _this.count = _this.connectionService.count;
            if (_this.connections.length === 0) {
                _this.noConnections.emit(true);
            }
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    ConnectionsListComponent.prototype.buildTypeQuery = function (query, type) {
        query['is_broker'] = null;
        query['is_carrier'] = null;
        query['is_contractor'] = null;
        switch (type) {
            case 'broker':
                query['is_broker'] = 'True';
                break;
            case 'carrier':
                query['is_carrier'] = 'True';
                break;
            case 'contractor':
                query['is_contractor'] = 'True';
                break;
        }
        return query;
    };
    /**
     * Used for reseting the filters or when filter is changed
     *
     * @param  {} appliedFilters
     */
    ConnectionsListComponent.prototype.filtersModified = function (appliedFilters) {
        if (!appliedFilters || !appliedFilters.length) {
            // Unimplemented
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    ConnectionsListComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return ConnectionsListComponent;
}());
exports.ConnectionsListComponent = ConnectionsListComponent;
