"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
// libraries
var lodash_1 = require("lodash");
// angualar material
var material_1 = require("@angular/material");
// services
var core_2 = require("@ngx-translate/core");
var driver_service_1 = require("./driver.service");
var authentication_service_1 = require("../shared/authentication.service");
var tag_service_1 = require("../tags/tag.service");
// components
var new_driver_dialog_component_1 = require("./new-driver-dialog/new-driver-dialog.component");
var invite_driver_dialog_component_1 = require("./invite-driver-dialog/invite-driver-dialog.component");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var filters_dialog_component_1 = require("../shared/filters-dialog/filters-dialog.component");
var filter_option_1 = require("../shared/filters-panel/filter-option");
// constants
var options_1 = require("./driver-context-menu/data/options");
var DriversComponent = /** @class */ (function () {
    function DriversComponent(authenticationService, route, router, driverService, translationService, dialog) {
        var _this = this;
        this.authenticationService = authenticationService;
        this.route = route;
        this.router = router;
        this.driverService = driverService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.view = 'list';
        this.viewArchive = new core_1.EventEmitter();
        this.loadingProgress = 0;
        this.displayKeys = ['name'];
        this.groupByOptions = ['Carrier', 'Market', 'Assigned', 'Duty Status'];
        this.activeGroupBy = 'Carrier';
        this.gridLabels = [
            {
                name: 'Truck Assigned',
                color: '#015BC5'
            },
            {
                name: 'No Truck Assigned',
                color: '#ffffff'
            },
            {
                name: 'Off Duty',
                color: 'rgba(208, 2, 27, 0.15)'
            }
        ];
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'profile__first_name' },
            { key: 'cdl', title: this.translationService.instant('CDL'), sortable: true, sortBy: 'cdl' },
            { key: 'phone-number', title: this.translationService.instant('Phone Number'), sortable: true, sortBy: 'profile__phone_number' },
            { key: 'carrier', title: this.translationService.instant('Carrier'), sortable: true, sortBy: 'carrier__name' },
            { key: 'billing-id', title: this.translationService.instant('Billing ID'), sortable: false },
            { key: 'truck', title: this.translationService.instant('Assigned Truck'), sortable: false },
            { key: 'markets', title: this.translationService.instant('Markets'), sortable: false },
            { key: 'duty-status', title: this.translationService.instant('Duty Status'), sortable: false },
            { key: 'deleted', title: this.translationService.instant('Deleted'), sortable: false },
            { key: 'action', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'select', 'name', 'cdl', 'phone-number', 'billing-id', 'truck', 'markets',
            'duty-status', 'action'
        ];
        this.appliedFilters = [];
        this.search = '';
        this.customClasses = 'drivers';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        // config for fancy table
        this.tableConfig = {
            hasHeader: true,
            service: driver_service_1.DriverService,
            preferenceKey: 'DriversComponent-DriverService',
            query: {},
            collectionTitle: this.translationService.instant('Drivers'),
            noResultsText: this.translationService.instant('a driver'),
            newRecordModal: function () { _this.openAddDriver(); },
            sortBy: 'profile__first_name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
                { name: this.translationService.instant('View Driver Profile'), action: 'details', link: false, external: false }
            ]
        };
        this.drivers = [];
        this.driver = {};
        this.driversForEdit = [];
        this.stateOptionsForModal = [];
        this.customerOnly = null;
        this.carrierId = '';
        this.query = {};
        this.sortAsc = true;
        this.drawerOpen = false;
        this.enabledFeatures = [];
        this.inviteDriverEnabled = false;
        this.changeSearchEmitter = new core_1.EventEmitter();
        // context menu
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.viewDriverProfileAction = options_1.ViewDriverProfileAction;
        this.saveDriverCallback = function () {
            _this.refreshTable();
        };
        this.inviteDriverCallback = function () {
            _this.refreshTable();
        };
    }
    DriversComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures && this.enabledFeatures.includes('inviteDriver')) {
            this.inviteDriverEnabled = true;
        }
        this.tableConfig['query'] = __assign({}, this.tableConfig['query'], this.query, query);
        this.driverService.listAllProgress.subscribe(function (progress) {
            _this.loadingProgress = Math.ceil(progress * 100);
        });
    };
    DriversComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig['customHeight'] = this.customHeight;
        this.route.queryParams.forEach(function (params) {
            _this.search = params['search'] || '';
            _this.tableConfig['sortBy'] = 'profile__first_name';
        });
    };
    DriversComponent.prototype.ngOnChanges = function (changes) {
        if (this.driverTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
            this.driverTable.query = this.query;
            this.tableConfig['query'] = __assign({}, this.tableConfig['query'], this.query);
            this.refreshTable();
            if (this.query['status']) {
                this.archive = !!(this.query['status'] === 'deleted');
            }
            else {
                this.archive = false;
            }
        }
    };
    DriversComponent.prototype.clickAction = function (event) {
        if (event) {
            this.selectDriver(event, event[1]);
        }
    };
    /**
     * @param  {} e
     * @param  {} driver
     * This function would open a edit window for updating or removing
     * the driver
     */
    DriversComponent.prototype.selectDriver = function (e, driver) {
        var _this = this;
        var target = e.target || e.srcElement || e.currentTarget;
        if (target && target.className && target.className.includes('action-menu-icon') ||
            target && target.type === 'checkbox') {
            // Do nothing
        }
        else {
            setTimeout(function () {
                if (_this.drivers) {
                    _this.driversForEdit = _this.drivers.map(function (type) {
                        var selected = type.name.trim() === driver.name.trim();
                        return __assign({}, type, { selected: selected });
                    });
                }
                _this.driver = lodash_1.cloneDeep(driver);
                if (_this.editDrawer) {
                    _this.editDrawer.setOpen();
                }
            }, 100);
        }
    };
    DriversComponent.prototype.handleGridSelection = function (driverIds) {
        if (driverIds[0]) {
            var matchedDriver = this.driverList.find(function (t) { return (t.id === driverIds[0]); });
            this.selectDriver({}, matchedDriver);
        }
        else {
            this.editDrawer.close();
        }
    };
    DriversComponent.prototype.unarchive = function (driver) {
        var _this = this;
        this.driverService.save({ id: driver.id, status: 'active' }).subscribe(function () {
            _this.refreshTable();
        });
    };
    DriversComponent.prototype.menuAction = function (name, driver) {
        var _this = this;
        switch (name) {
            case 'edit':
                setTimeout(function () {
                    _this.selectDriver(name, driver);
                }, 100);
                break;
            case 'details':
                if (driver && driver.id) {
                    this.router.navigate(['drivers', driver.id, 'details', 'details'], {
                        queryParams: { returnTo: this.router.url },
                    });
                }
                break;
            case 'unarchive':
                this.unarchive(driver);
                break;
        }
    };
    DriversComponent.prototype.isSelected = function (driver) {
        return this.driver && driver.id === this.driver.id;
    };
    DriversComponent.prototype.openAddDriver = function () {
        var dialog = this.dialog.open(new_driver_dialog_component_1.NewDriverDialogComponent, {
            width: '444px'
        });
        dialog.componentInstance.leasedMode = this.displayedColumns.includes('carrier');
        dialog.componentInstance.carrierId = this.carrierId;
        dialog.componentInstance.stateOptions = this.stateOptionsForModal;
        dialog.componentInstance.callback = this.saveDriverCallback;
    };
    DriversComponent.prototype.openInviteDriver = function () {
        var dialog = this.dialog.open(invite_driver_dialog_component_1.InviteDriverDialogComponent, {
            width: '444px'
        });
        dialog.componentInstance.stateOptions = this.stateOptionsForModal;
        dialog.componentInstance.callback = this.inviteDriverCallback;
    };
    DriversComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.filters = [
            {
                type: 'dropdown',
                field: 'tags',
                label: 'Markets',
                dropdownConfig: {
                    service: tag_service_1.TagService,
                    multiselect: true
                }
            },
            {
                type: 'text',
                field: 'truckName',
                label: 'Current Truck Search'
            },
            {
                type: 'text',
                field: 'cdl',
                label: 'CDL Search'
            }
        ];
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    DriversComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            tags: 'tags',
            truckName: 'truck__name',
            cdl: 'cdl'
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (filterRes[key]) {
                if (key === 'tags') {
                    if (values && values.length > 0) {
                        values = values.map(function (tag) { return tag.name; }).join(',');
                        query[queryKeys[key]] = values;
                    }
                }
                else {
                    query[queryKeys[key]] = filterRes[key] && filterRes[key].id || values;
                }
            }
            var filter = new filter_option_1.FilterOption({
                filterType: 'text',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (!filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    DriversComponent.prototype.refreshTable = function () {
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.driverTable.getRecords(__assign({}, this.tableConfig['query'], this.query, query));
    };
    /**
     * Gets called on when fancy table is finished with loading data
     * Opens the side edit driver drawer if driverId in query params
     *
     * @param data List of drivers
     */
    DriversComponent.prototype.dataLoaded = function (_a) {
        var data = _a.data;
        this.driverList = data;
        var driverId = this.route.snapshot.queryParams.driverId;
        if (driverId) {
            var driver = data.find(function (r) { return r.id === driverId; });
            if (driver) {
                this.selectDriver({}, driver);
            }
            this.router.navigate([], {
                queryParams: {
                    'driverId': null
                },
                queryParamsHandling: 'merge'
            });
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    DriversComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    /**
   * @returns void
   * This function is called when edit is successfully completed
   * This would update the table with the changed fields
   */
    DriversComponent.prototype.onEditComplete = function (modifiedDriver) {
        if (this.view === 'list') {
            this.refreshTable();
        }
        else {
            this.driverList = this.driverList.map(function (d) { return (d.id === modifiedDriver.id ?
                Object.assign(d, modifiedDriver) :
                d); });
            this.setupItemList(this.driverList, this.activeGroupBy);
        }
    };
    /**
     * Switched the UI view and then triggers the  driver list setup if no list is currently set up
     *
     * @param {'list' | 'grid'} view The selected view
     */
    DriversComponent.prototype.switchView = function (view) {
        this.view = view;
        if (!this.driverGroups) {
            this.getFullDriverList(this.query);
        }
    };
    /**
     * Fetches the full truck list based on the same query as the truck table
     *
     * @param {any} query The query to be appended to the full list request
     */
    DriversComponent.prototype.getFullDriverList = function (query) {
        var _this = this;
        this.driverService.listAll(25, query).subscribe(function (drivers) {
            _this.driverList = drivers;
            _this.setupItemList(_this.driverList, _this.activeGroupBy);
        });
    };
    /**
     * Sets up the driver groups based on the specified groupBy param
     *
     * @param {Driver[]} drivers The driver list to be grouped
     * @param {'Carrier' | 'Market' | 'Assigned' | 'Duty Status'} groupBy The selected groupBy option
     */
    DriversComponent.prototype.setupItemList = function (drivers, groupBy) {
        this.activeGroupBy = groupBy;
        switch (groupBy) {
            case 'Carrier':
                this.driverGroups = Array.from(new Set(drivers.map(function (d) { return ({
                    id: d.carrier.id,
                    name: d.carrierOrganizationName,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (driver.carrier.id === group.id); })
                })); });
                break;
            case 'Market':
                var marketGroups_1 = [];
                drivers.forEach(function (d) {
                    if (d.tags && d.tags.length) {
                        d.tags.forEach(function (tag) {
                            marketGroups_1.push({
                                id: tag.id,
                                name: tag.name,
                                groupBy: groupBy,
                                items: []
                            });
                        });
                    }
                    else {
                        marketGroups_1.push({
                            id: '',
                            groupBy: groupBy,
                            items: []
                        });
                    }
                });
                this.driverGroups = marketGroups_1.filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.id === '' ? (!driver.tags || driver.tags.length === 0) :
                        driver.tags.map(function (t) { return (t.id); }).join(' ').includes(group.id)); })
                })); });
                break;
            case 'Assigned':
                this.driverGroups = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.truck && d.truck.id ? 'Truck Assigned' : 'No Truck Assigned',
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) {
                        if (group.name === 'Truck Assigned') {
                            return driver.truck && driver.truck.id;
                        }
                        else {
                            return !(driver.truck && driver.truck.id);
                        }
                    })
                })); });
                break;
            case 'Duty Status':
                this.driverGroups = Array.from(new Set(drivers.map(function (d) { return ({
                    id: d.dutyStatus,
                    name: d.displayDutyStatus,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (driver.dutyStatus === group.id); })
                })); });
                break;
        }
        this.driverGroups = this.driverGroups.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
    };
    /**
     * Selects a new groupBy option and re-triggers the driver grid setup based on that option
     *
     * @param {'Carrier' | 'Market' | 'Assigned' | 'Duty Status'} groupBy The selected groupBy option
     */
    DriversComponent.prototype.selectGroupBy = function (groupBy) {
        this.setupItemList(this.driverList, groupBy);
    };
    /**
     * Returns a set of class names in a single string to be appended to item elements in the item grid
     *
     * @param {Driver} driver The driver object
     * @returns {string} The class names to be appended to item elements
     */
    DriversComponent.prototype.generateItemClassNames = function (driver) {
        var classNames = '';
        if (driver.dutyStatus === 'off-duty') {
            classNames += 'red ';
        }
        else if (driver.truck && driver.truck.id) {
            classNames += 'blue ';
        }
        return classNames;
    };
    DriversComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    return DriversComponent;
}());
exports.DriversComponent = DriversComponent;
