"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var order_service_1 = require("../order.service");
var ticket_detail_dialog_component_1 = require("../ticket-detail-dialog/ticket-detail-dialog.component");
var ngx_device_detector_1 = require("ngx-device-detector");
var job_event_service_1 = require("../../job-events/job-event.service");
var OrderTicketListComponent = /** @class */ (function () {
    function OrderTicketListComponent(route, dialog, orderService, jobEventService, deviceDetectorService) {
        this.route = route;
        this.dialog = dialog;
        this.orderService = orderService;
        this.jobEventService = jobEventService;
        this.deviceDetectorService = deviceDetectorService;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.loading = true;
        this.errors = [];
    }
    OrderTicketListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); }).subscribe(function (result) {
            if (result.params['jobEventId']) {
                _this.orderService.setJobEventId(result.params['jobEventId']);
            }
        });
        this.orderService.orderData.subscribe(function (orderData) {
            if (!_this.jobEvent) {
                _this.loading = true;
            }
            _this.getTicketData(orderData);
        });
    };
    OrderTicketListComponent.prototype.getTicketData = function (orderData) {
        var _this = this;
        this.tickets = orderData[0].sort(function (a, b) { return ((a.loadingCompleteDatetime > b.loadingCompleteDatetime) ? -1 :
            (a.loadingCompleteDatetime < b.loadingCompleteDatetime) ? 1 : 0); })
            .filter(function (ticket) { return ['enroute_loading', 'loading'].indexOf(ticket.tripStatus) === -1; });
        this.lastUpdated = new Date().toISOString();
        if (!this.jobEvent) {
            this.jobEventService.getJobEvent(this.orderService.jobEventId.getValue()).subscribe(function (jobEvent) {
                _this.jobEvent = jobEvent;
                _this.loading = false;
            });
        }
        else {
            this.loading = false;
        }
    };
    OrderTicketListComponent.prototype.openTicketDetails = function (ticket) {
        var _this = this;
        var dialog = this.dialog.open(ticket_detail_dialog_component_1.TicketDetailDialogComponent, {
            width: this.device.mobile ? '320px' : '480px',
            data: {
                ticket: ticket,
                jobEvent: this.jobEvent
            }
        });
        dialog.componentInstance.callback = function (updatedTicket) {
            _this.tickets[_this.tickets.findIndex(function (t) { return (t.id === updatedTicket.id); })] = updatedTicket;
        };
    };
    return OrderTicketListComponent;
}());
exports.OrderTicketListComponent = OrderTicketListComponent;
