"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var drivers_component_1 = require("../drivers/drivers.component");
var trucks_component_1 = require("../trucks/trucks.component");
/**
 *  Fleet Component: Requests and displays navigation links and,
 * through the {@link DriversComponent} or {@link TrucksComponent}, the fleet associated with the
 * selected .
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <fleet></fleet>
 */
var FleetComponent = /** @class */ (function () {
    function FleetComponent(route, router) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.view = { stub: 'drivers', title: 'Drivers' };
        this.loading = true;
        this.errors = [];
        this.search = '';
        this.query = {};
        this.displayedColumns = [
            'select', 'name', 'cdl', 'phone-number', 'billing-id', 'truck', 'markets',
            'action'
        ];
        /**
         * An anonymous callback function to call the FancyTable's `refreshTable()`
         * function that is called when drivers are created.
         */
        this.saveDriverCallback = function () {
            if (_this.driverTableWrapper) {
                _this.driverTableWrapper.refreshTable();
            }
        };
        this.saveTruckCallback = function () {
            if (_this.truckTableWrapper) {
                _this.truckTableWrapper.refreshTable();
            }
        };
    }
    /**
    * Examines the route and query parameters to determine the  Type and
    * persisted search value.
    *
    * If the route includes `accounts` the `customer_only: True` filter is added
    * to the query.
    *
    * If the `account` has an {@link Organization} with a {@link Carrier} the
    * `carrier: CARRIER_ID` filter is added to the query.
    */
    FleetComponent.prototype.ngOnInit = function () {
        if (this.route && this.route.snapshot && this.route.snapshot.url.length) {
            this.view = this.route.snapshot.data['view'];
            this.displayedColumns = this.view.stub === 'drivers' ? [
                'select', 'name', 'cdl', 'phone-number', 'billing-id', 'truck', 'markets', 'status', 'action'
            ] : [
                'select', 'truck-type', 'name', 'license-plate', 'markets', 'status', 'action'
            ];
        }
    };
    FleetComponent.prototype.ngAfterViewInit = function () {
        if (this.view.type && this.view.type === 'archive') {
            this.query = __assign({}, this.query, { status: 'deleted' });
            this.updateRowActions('archive');
        }
        else {
            this.query = __assign({}, this.query, { status: 'active' });
            this.updateRowActions('main');
        }
    };
    /**
     * Handles changing to and from the archive view, navigating to the correct url
     *
     * @param {boolean} view Should the view be changed to archive or not
     */
    FleetComponent.prototype.viewArchive = function (view) {
        var routeList = view ? ['/', this.view.stub, 'archive'] : ['/', this.view.stub];
        this.router.navigate(routeList, { relativeTo: this.route });
    };
    FleetComponent.prototype.updatedMarkets = function () {
        if (this.driverTableWrapper) {
            this.driverTableWrapper.refreshTable();
        }
        if (this.truckTableWrapper) {
            this.truckTableWrapper.refreshTable();
        }
    };
    FleetComponent.prototype.updateRowActions = function (type) {
        if (type === 'main') {
            if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
                this.driverTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Edit', action: 'edit', link: false, external: false },
                    { name: 'View Driver Profile', action: 'details', link: false, external: false }
                ];
            }
            if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
                this.truckTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Edit', action: 'edit', link: false },
                    { name: 'Remove', action: 'remove', link: false, external: false }
                ];
            }
        }
        else {
            if (this.driverTableWrapper && this.driverTableWrapper.tableConfig) {
                this.driverTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Reactivate', action: 'unarchive', link: false, external: false },
                    { name: 'View Driver Profile', action: 'details', link: false, external: false }
                ];
            }
            if (this.truckTableWrapper && this.truckTableWrapper.tableConfig) {
                this.truckTableWrapper.tableConfig['menuOptions'] = [
                    { name: 'Reactivate', action: 'unarchive', link: false, external: false }
                ];
            }
        }
    };
    return FleetComponent;
}());
exports.FleetComponent = FleetComponent;
