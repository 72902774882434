"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var keycodes_1 = require("@angular/cdk/keycodes");
var overlay_1 = require("@angular/cdk/overlay");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var api_service_1 = require("../api.service");
var key_codes_enum_1 = require("../enums/key-codes.enum");
var FiltersPanelComponent = /** @class */ (function () {
    function FiltersPanelComponent(fb, injector, ngZone, scroll) {
        var _this = this;
        this.fb = fb;
        this.injector = injector;
        this.ngZone = ngZone;
        this.scroll = scroll;
        this.appliedFilters = [];
        this.availableFilters = [];
        this.allowSearch = true;
        this.customFilter = false;
        this.search = '';
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.openCustomFilter = new core_1.EventEmitter();
        this.separatorKeysCodes = [keycodes_1.ENTER, keycodes_1.COMMA];
        this._onDestroy = new rxjs_1.Subject();
        this.allSubscriptionsToUnsubscribe = [];
        this.allSubscriptionsToUnsubscribe.push(this.scroll.scrolled().subscribe(function (data) {
            if (data) {
                var filterKey = void 0;
                var element = data.getElementRef();
                if (element && element.nativeElement) {
                    if (element.nativeElement.scrollTop >= (element.nativeElement.scrollHeight * 0.25)) {
                        filterKey = element.nativeElement.getAttribute('data-filter');
                        if (filterKey) {
                            _this.getNextPage(filterKey);
                        }
                    }
                }
            }
        }));
    }
    FiltersPanelComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    FiltersPanelComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    FiltersPanelComponent.prototype.ngOnChanges = function (changes) {
        if (changes['search']) {
            if (this.searchField) {
                this.searchField.setValue(changes['search'].currentValue);
            }
            this.searchChange.emit(changes['search'].currentValue);
        }
        if (changes['appliedFilters']) {
            var previousValue = changes['appliedFilters'].previousValue;
            var currentValue = changes['appliedFilters'].currentValue;
            var removedFilters = lodash_1.differenceWith(previousValue, currentValue, this.compareKeys);
            for (var _i = 0, removedFilters_1 = removedFilters; _i < removedFilters_1.length; _i++) {
                var filterOption = removedFilters_1[_i];
                filterOption.values = [];
                this.updateControl(filterOption.key, filterOption.values);
                var filter = lodash_1.find(this.appliedFilters, { key: filterOption.key });
                if (filter) {
                    this.appliedFiltersChange.emit(lodash_1.pull(this.appliedFilters, filter));
                }
            }
            this.availableFiltersChange.emit(this.availableFilters);
            this.appliedFiltersChange.emit(this.appliedFilters);
        }
    };
    Object.defineProperty(FiltersPanelComponent.prototype, "searchField", {
        get: function () {
            return this.form && this.form.get('search');
        },
        enumerable: true,
        configurable: true
    });
    FiltersPanelComponent.prototype.compareIds = function (id1, id2) {
        var a1 = id1;
        var a2 = id2;
        if (id1 && id1.constructor.name === 'Array' && id1.length > 0) {
            a1 = '' + id1[0];
        }
        if (id2 && id2.constructor.name === 'Array' && id2.length > 0) {
            a2 = '' + id2[0];
        }
        return a1 === a2;
    };
    FiltersPanelComponent.prototype.compareKeys = function (a, b) {
        return a.key === b.key;
    };
    FiltersPanelComponent.prototype.onSearchboxKeypress = function (event) {
        if (event.code === key_codes_enum_1.KeyCodes.Enter) {
            if (this.searchField.value && this.searchField.value.trim()) {
                this.searchChange.emit(event.target['value']);
            }
            event.preventDefault();
        }
    };
    FiltersPanelComponent.prototype.onBlur = function () {
        if (this.searchField.value && this.searchField.value.trim()) {
            this.searchChange.emit(this.searchField.value);
        }
    };
    FiltersPanelComponent.prototype.onChanges = function () {
        var _this = this;
        if (this.form) {
            var _loop_1 = function (filter) {
                this_1.allSubscriptionsToUnsubscribe.push(this_1.form.get(filter.key).valueChanges.subscribe(function (value) {
                    if (value || value === false) {
                        var removeFilter = false;
                        if (filter) {
                            if (!filter.values) {
                                filter.values = [];
                            }
                            if (!filter.displayValues) {
                                filter.displayValues = [];
                            }
                            if (filter.filterType === 'autocomplete') {
                                filter.values = lodash_1.uniq(filter.values.concat(value));
                            }
                            else if (filter.filterType === 'checkbox') {
                                filter.values = value;
                                if (value !== true) {
                                    lodash_1.pull(_this.appliedFilters, lodash_1.find(_this.appliedFilters, { key: filter.key }));
                                    removeFilter = true;
                                }
                            }
                            else if (filter.filterType === 'text' && filter.customField) {
                                filter.values = (value && typeof value === 'string') ? [value] : value;
                                if (value === false) {
                                    lodash_1.pull(_this.appliedFilters, lodash_1.find(_this.appliedFilters, { key: filter.key }));
                                    removeFilter = true;
                                }
                            }
                            else {
                                if (value && typeof value === 'string') {
                                    if (value && value.trim()) {
                                        filter.values = [value];
                                    }
                                    else {
                                        lodash_1.pull(_this.appliedFilters, lodash_1.find(_this.appliedFilters, { key: filter.key }));
                                        removeFilter = true;
                                    }
                                }
                                else {
                                    filter.values = value;
                                }
                            }
                            if (filter.multiple && !filter.values.length) {
                                lodash_1.pull(_this.appliedFilters, filter);
                            }
                            else if (!removeFilter) {
                                if (!lodash_1.find(_this.appliedFilters, { key: filter.key })) {
                                    _this.appliedFilters.push(filter);
                                }
                            }
                        }
                        for (var _i = 0, _a = filter.values; _i < _a.length; _i++) {
                            var selectedValue = _a[_i];
                            var option = lodash_1.find(filter.options, { id: selectedValue });
                            if (option) {
                                if (filter.multiple) {
                                    filter.displayValues = filter.displayValues.concat(option.name);
                                }
                                else {
                                    filter.displayValues = [option.name];
                                }
                            }
                        }
                    }
                    else {
                        lodash_1.pull(_this.appliedFilters, filter);
                    }
                    _this.appliedFiltersChange.emit(lodash_1.clone(_this.appliedFilters));
                }));
            };
            var this_1 = this;
            for (var _i = 0, _a = this.availableFilters; _i < _a.length; _i++) {
                var filter = _a[_i];
                _loop_1(filter);
            }
        }
    };
    FiltersPanelComponent.prototype.buildForm = function () {
        this.form = this.fb.group({ search: [''] });
        this.addFilterControls();
        this.onChanges();
    };
    FiltersPanelComponent.prototype.addFilterControls = function () {
        var _this = this;
        this.availableFilters.map(function (filter) {
            if (filter.service) {
                _this.getOptions(filter);
            }
            var control = new forms_1.FormControl(filter.values);
            _this.form.controls[filter.key] = control;
            _this.form.controls[filter.searchKey] = new forms_1.FormControl();
            _this.form.controls[filter.searchKey].valueChanges
                .pipe(operators_1.tap(function () { return filter.searching = true; }), operators_1.takeUntil(_this._onDestroy), operators_1.debounceTime(500), operators_1.map(function (search) {
                _this.getOptions(filter, false, search);
            }), operators_1.delay(200)).subscribe(function () {
                filter.searching = false;
            }, function (error) {
                filter.searching = false;
            });
        });
    };
    FiltersPanelComponent.prototype.getOptions = function (filter, append, search) {
        if (append === void 0) { append = false; }
        if (search === void 0) { search = null; }
        var service = this.injector.get(filter.service);
        if (!service || (!service.list && !service.listFilters)) {
            return;
        }
        var request;
        filter.loading = true;
        if (filter.request) {
            filter.request.unsubscribe();
        }
        if (!filter.hasOwnProperty('slug') || filter.slug === undefined || !filter.slug) {
            request = service.list({
                ordering: filter.ordering,
                search: search
            });
        }
        else {
            request = service.listFilters(filter.slug, __assign({}, filter.query, { ordering: filter.ordering, filter_search: search, page_size: 10 }));
        }
        filter.request = request.subscribe(function (options) {
            if (append) {
                filter.options = filter.options.concat(options);
            }
            else {
                filter.options = options;
            }
            if (filter.idProperty) {
                filter.options.forEach(function (option) {
                    option.id = lodash_1.get(option, filter.idProperty, option.id);
                });
            }
            filter.count = service.count;
            filter.nextUri = service.nextUri;
            filter.loading = false;
        }, function (err) {
            filter.errors = api_service_1.parseErrors(err);
            filter.loading = false;
        });
    };
    FiltersPanelComponent.prototype.getNextPage = function (filterKey) {
        var filter = lodash_1.find(this.availableFilters, { key: filterKey });
        if (filter) {
            var service_1 = this.injector.get(filter.service);
            var request_1 = service_1.listNext(filter.nextUri);
            if (!request_1 || filter.loading) {
                return;
            }
            this.ngZone.run(function () {
                filter.loading = true;
                if (filter.request) {
                    filter.request.unsubscribe();
                }
                filter.request = request_1.subscribe(function (options) {
                    // otherwise there is a problem if filters use the same service. The service is instantiated only once.
                    filter.nextUri = service_1.nextUri;
                    filter.options = filter.options.concat(options);
                    if (filter.idProperty) {
                        filter.options.forEach(function (option) {
                            option.id = lodash_1.get(option, filter.idProperty, option.id);
                        });
                    }
                    filter.count = filter.service.count;
                    filter.loading = false;
                }, function (err) {
                    filter.errors = api_service_1.parseErrors(err);
                    filter.loading = false;
                });
            });
        }
    };
    FiltersPanelComponent.prototype.resetFilters = function () {
        this.appliedFilters = [];
        this.search = '';
        this.appliedFiltersChange.emit(this.appliedFilters);
        this.searchChange.emit(this.search);
        this.availableFilters.forEach(function (filter) { return filter.values = null; });
        this.buildForm();
    };
    FiltersPanelComponent.prototype.addFilterOption = function (event, filterOption) {
        if (!filterOption.displayValues) {
            filterOption.displayValues = [];
        }
        filterOption.values = lodash_1.uniq(filterOption.values.concat(event.option.value));
        for (var _i = 0, _a = filterOption.values; _i < _a.length; _i++) {
            var selectedValue = _a[_i];
            var option = lodash_1.find(filterOption.options, { id: selectedValue });
            if (option) {
                filterOption.displayValues.concat(option.name);
            }
        }
        this.updateControl(filterOption.key, filterOption.values);
    };
    FiltersPanelComponent.prototype.removeFilterOption = function (value, filterOption) {
        if (filterOption.values) {
            lodash_1.pull(filterOption.values, value);
            this.updateControl(filterOption.key, filterOption.values);
        }
    };
    FiltersPanelComponent.prototype.updateControl = function (key, values) {
        var control = this.form.get(key);
        if (control) {
            control.setValue(values);
        }
    };
    FiltersPanelComponent.prototype.setValues = function () {
        for (var _i = 0, _a = this.appliedFilters; _i < _a.length; _i++) {
            var filter = _a[_i];
            this.form.get(filter.key).setValue(filter.values, { emitEvent: false });
        }
    };
    FiltersPanelComponent.prototype.setValue = function (event) {
        if (!event || !event.source || !event.source.ngControl) {
            return;
        }
        var filterKey = event.source.ngControl.name;
        var values = (event.value && typeof event.value === 'string') ? [event.value] : event.value;
        var appliedFilter = lodash_1.find(this.appliedFilters, { key: filterKey });
        var availableFilter = lodash_1.find(this.availableFilters, { key: filterKey });
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var selectedValue = values_1[_i];
            var option = lodash_1.find(availableFilter.options, { id: selectedValue });
            if (option) {
                if (appliedFilter.multiple) {
                    appliedFilter.displayValues = availableFilter.displayValues.concat(option.name);
                    availableFilter.displayValues = availableFilter.displayValues.concat(option.name);
                }
                else {
                    appliedFilter.displayValues = [option.name];
                    availableFilter.displayValues = [option.name];
                }
            }
        }
        appliedFilter.values = values;
        availableFilter.values = values;
    };
    FiltersPanelComponent.prototype.onDateChanged = function (filterKey, selectedDates) {
        var appliedFilter = lodash_1.find(this.appliedFilters, { key: filterKey });
        var availableFilter = lodash_1.find(this.availableFilters, { key: filterKey });
        if (!appliedFilter) {
            this.appliedFilters.push(availableFilter);
            appliedFilter = lodash_1.find(this.appliedFilters, { key: filterKey });
        }
        appliedFilter.displayValues = selectedDates;
        appliedFilter.values = selectedDates.map(function (date) { return date.toISOString(); });
        this.appliedFiltersChange.emit(lodash_1.clone(this.appliedFilters));
    };
    return FiltersPanelComponent;
}());
exports.FiltersPanelComponent = FiltersPanelComponent;
