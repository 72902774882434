import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { requestHeaders, handleError } from '../shared/index';
import { Customer } from './customer';
import { environment } from '../../environments/environment';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class CustomerService {
  baseUrl = environment.serverUrl;
  customer: Customer;
  public nextUri;

  constructor(private http: HttpClient) { }

  getCustomer(customerId: string) {
    let customerUrl = this.baseUrl + 'customers/' + customerId + '/';

    return this.http.get(customerUrl).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getCustomers(query: any = null): Observable<Customer[]> {
    let customersUrl = this.baseUrl + 'customers/';

    return this.http.get(customersUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  save(customer) {
    customer = decamelizeKeysDeep(customer);
    const customerUrl = this.baseUrl + 'connections/';
    if ( ! customer.id ) {
      return this.http.post(customerUrl, {organization: customer}, {headers: requestHeaders()});
    } else {
      return this.http.put(customerUrl + customer.id + '/', {organization: customer}, {headers: requestHeaders()});
    }
  }

  getNext(): Observable<Customer[]> {
     if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()}).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    if (body) {
      return body.map(customer => {
        return new Customer(customer);
      });
    } else if (resObj) {
      return new Customer(resObj);
    }
  }
}
