"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var location_updates_1 = require("./location-updates");
var api_service_1 = require("../shared/api.service");
var MapService = /** @class */ (function () {
    function MapService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    MapService.prototype.getLocationUpdates = function (query, endpoint, idList) {
        if (query === void 0) { query = null; }
        var mapUrl = this.baseUrl + 'lastlocationupdates/';
        if (endpoint) {
            mapUrl += endpoint + '/';
        }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        return idList ?
            this.http.post(mapUrl, idList, {
                headers: api_service_1.requestHeaders(),
                params: params
            }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError)) :
            this.http.get(mapUrl, {
                headers: api_service_1.requestHeaders(),
                params: params
            }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    MapService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (locationUpdate) {
                return new location_updates_1.LocationUpdates(locationUpdate);
            });
        }
        else if (resObj) {
            return new location_updates_1.LocationUpdates(resObj);
        }
    };
    MapService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    return MapService;
}());
exports.MapService = MapService;
