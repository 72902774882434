"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var forms_1 = require("@angular/forms");
// libraries
var moment = require("moment-timezone");
// angular material
var material_1 = require("@angular/material");
// services
var punch_card_service_1 = require("../punch-cards/punch-card.service");
var job_service_1 = require("../jobs/job.service");
var job_event_service_1 = require("../job-events/job-event.service");
var driver_service_1 = require("../drivers/driver.service");
var truck_service_1 = require("../trucks/truck.service");
var api_service_1 = require("../shared/api.service");
var organization_service_1 = require("../organizations/organization.service");
var shared_1 = require("../shared");
// components
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var NewPunchCardDialogComponent = /** @class */ (function () {
    function NewPunchCardDialogComponent(dialogRef, punchCardService, jobEventService, organizationService, translateService, authenticationService, fb) {
        this.dialogRef = dialogRef;
        this.punchCardService = punchCardService;
        this.jobEventService = jobEventService;
        this.organizationService = organizationService;
        this.translateService = translateService;
        this.authenticationService = authenticationService;
        this.fb = fb;
        this.lockedFields = false;
        this.highlightedDates = [];
        this.jobEvents = [];
        this.jobEventError = false;
        this.jobEventDate = null;
        this.jobEventDatePickerOptions = {
            min: null,
            max: null
        };
        this.model = {
            job: null,
            carrier: null,
            jobEvent: null,
            driver: null,
            truck: null,
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            ticketNumber: null,
            startTimeTimestamp: null,
            endTimeTimestamp: null
        };
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
        this.firstload = true;
        this.activeTab = 'punch-card-data';
        this.jobDropdownConfig = {
            service: job_service_1.JobService,
            selectText: this.translateService.instant('Select Job'),
            loadingText: this.translateService.instant('Loading Jobs...'),
            noResultsText: this.translateService.instant('No Jobs'),
            searchable: true,
            group: true,
            groupBy: function (job) { return job.project.name; },
            groupProperty: 'project.name',
            sortBy: 'project__name,name,start_date',
            nameProperty: 'displayName',
            serializer: 'AllJobs',
            includeFullObject: true,
            query: {
                serializer: 'JobGroupedDropdown'
            }
        };
        this.carrierDropdownConfig = {
            nameProperty: 'name',
            idProperty: 'carrier.id',
            selectText: this.translateService.instant('Select Carrier'),
            loadingText: this.translateService.instant('Loading Carriers...'),
            noResultsText: this.translateService.instant('No Carriers'),
            service: organization_service_1.OrganizationService,
            serviceFunction: 'get',
            searchable: true,
            sortBy: 'name',
            includeFullObject: true,
            prefilledOptions: [
                __assign({}, this.authenticationService.getOrganization(), { name: 'My Drivers' })
            ],
            query: {
                ordering: 'name',
                exclude: this.authenticationService.getOrganization().id,
            }
        };
        this.driverDropdownConfig = {
            service: driver_service_1.DriverService,
            selectText: this.translateService.instant('Select Driver'),
            loadingText: this.translateService.instant('Loading Drivers...'),
            noResultsText: this.translateService.instant('No Drivers'),
            searchable: true,
            sortBy: 'profile__first_name,profile__last_name',
            includeFullObject: true,
            serviceFunction: 'list',
            query: { ordering: 'profile__first_name,profile__last_name' }
        };
        this.truckDropdownConfig = {
            service: truck_service_1.TruckService,
            selectText: this.translateService.instant('Select Truck'),
            loadingText: this.translateService.instant('Loading Trucks...'),
            noResultsText: this.translateService.instant('No Trucks'),
            searchable: true,
            nameProperty: 'displayName',
            sortBy: 'name',
            includeFullObject: true,
            serviceFunction: 'list',
            query: { ordering: 'name' }
        };
        this.ticketImageFile = {
            dataUri: null,
            file: null
        };
        this.parseDateTimeToIsoString = function (date, time) {
            return moment(moment(date).format('YYYY-MM-DD') + " " + time).toISOString();
        };
    }
    NewPunchCardDialogComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    NewPunchCardDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventsReq) {
            this.jobEventsReq.unsubscribe();
        }
    };
    NewPunchCardDialogComponent.prototype.initForm = function () {
        this.model.startDate = this.jobEventDate;
        this.model.endDate = this.jobEventDate;
        this.newPunchCardForm = this.fb.group({
            job: [this.model.job, forms_1.Validators.required],
            jobEvent: [this.model.jobEvent, forms_1.Validators.required],
            driver: [this.model.driver, forms_1.Validators.required],
            carrier: [this.model.carrier, forms_1.Validators.required],
            truck: [this.model.truck, forms_1.Validators.required],
            startDate: [this.model.startDate, [forms_1.Validators.required]],
            startTime: [this.model.startTime, [forms_1.Validators.required]],
            endDate: [this.model.endDate, [forms_1.Validators.required]],
            endTime: [this.model.endTime, [forms_1.Validators.required]],
            ticketNumber: [this.model.ticketNumber, [forms_1.Validators.required, forms_1.Validators.minLength(1)]]
        });
    };
    NewPunchCardDialogComponent.prototype.submit = function () {
        var _this = this;
        this.errors = [];
        this.loading = true;
        var punchCard = this.parsePunchCard();
        this.punchCardService.saveWithFile(punchCard, this.ticketImageFile.file).subscribe(function (res) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    NewPunchCardDialogComponent.prototype.parsePunchCard = function () {
        var startTime = this.parseDateTimeToIsoString(this.model.startDate, this.model.startTime);
        var endTime = this.parseDateTimeToIsoString(this.model.endDate, this.model.endTime);
        var punchCard = __assign({}, this.model, { startTime: startTime,
            endTime: endTime, startTimeTimestamp: startTime, endTimeTimestamp: endTime, startDate: startTime, endDate: endTime });
        return punchCard;
    };
    NewPunchCardDialogComponent.prototype.enableEditing = function () {
        this.lockedFields = false;
        this.firstload = true;
    };
    NewPunchCardDialogComponent.prototype.onJobEventDateChanged = function (values) {
        this.jobEventError = false;
        if (!this.firstload && values && values.length) {
            this.model.truck = null;
            var selectedDate_1 = moment(values[0]).format().split('T')[0];
            var jobEvent = this.jobEvents.find(function (j) {
                return j.shift1Start.includes(selectedDate_1);
            });
            this.jobEventDate = values[0];
            if (jobEvent) {
                this.jobEvent = jobEvent;
                var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: jobEvent.id });
                this.carrierDropdown.config.query = query;
                this.carrierDropdown.getRecords(query);
                this.model.jobEvent = jobEvent.id;
                this.model.jobevent = jobEvent.id;
                this.newPunchCardFormPatchValue('jobEvent', jobEvent.id);
            }
            else {
                this.jobEventError = true;
                this.jobEvent = null;
            }
        }
    };
    NewPunchCardDialogComponent.prototype.onDateChanged = function (field, dates) {
        if (['startDate', 'endDate'].includes(field)) {
            this.model[field] = dates[0];
        }
        this.editDateTime(field, dates[0]);
    };
    /**
     * Makes an edit to either the date or time for the startTime or endTime field
     * and pairs that value with the date to set the overall timestamp.
     *
     * @param {string} field The datetime field we will apply the edit to
     * @param {any} value The edit value for the datetime
     */
    NewPunchCardDialogComponent.prototype.editDateTime = function (field, value) {
        if (['startTime', 'endTime'].includes(field) && value) {
            this.model[field] = value;
            this.newPunchCardFormPatchValue(field, value);
        }
        else if (['startDate', 'endDate'].includes(field) && value) {
            var newDate = moment(value, 'YYYY-MM-DD').toDate();
            this.model[field] = newDate;
            this.newPunchCardFormPatchValue(field, newDate);
        }
        else if (field && value) {
            this.model[field] = moment(value).toDate();
        }
    };
    NewPunchCardDialogComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                // if newly selected job is the same as existing selected job
                if (this.model.job && this.model.job.id === e.id) {
                    this.firstload = false;
                    this.getJobEvents(e.id);
                    this.newPunchCardFormPatchValue(filterType, e);
                    break;
                }
                if (this.model.job && this.model.job.startDate) {
                    this.disableDates(this.model.job.startDate, this.model.job.endDate);
                }
                if (this.firstload) {
                    this.firstload = false;
                }
                else {
                    this.model.jobEvent = null;
                    this.model.jobevent = null;
                    this.jobEventDate = null;
                    this.model.carrier = null;
                    this.model.driver = null;
                    this.model.truck = null;
                }
                this.getJobEvents(e.id, true);
                this.newPunchCardFormPatchValue(filterType, e);
                break;
            case 'carrier':
                var carrier = e.carrier;
                // if selected carrier is the same as existing selected carrier
                if (this.model.carrier && this.model.carrier.id === carrier.id) {
                    break;
                }
                if (!this.firstload) {
                    this.model.driver = null;
                    this.model.truck = null;
                }
                this.model.carrier = carrier;
                // get drivers and trucks based on carrier id
                var driversQuery = __assign({}, this.driverDropdownConfig.query, { carrier: carrier.id });
                this.driverDropdownConfig.query = driversQuery;
                this.driversDropdown.getRecords(driversQuery);
                var truckQuery = __assign({}, this.truckDropdownConfig.query, { carrier: carrier.id });
                this.truckDropdownConfig.query = truckQuery;
                this.truckDropdown.getRecords(truckQuery);
                this.newPunchCardFormPatchValue(filterType, carrier);
                break;
            default:
                this.model[filterType] = e;
                this.newPunchCardFormPatchValue(filterType, e);
                break;
        }
    };
    NewPunchCardDialogComponent.prototype.getJobEvents = function (jobId, query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.jobEventsReq) {
            this.jobEventsReq.unsubscribe();
        }
        if (jobId) {
            this.loading = true;
            this.jobEventError = false;
            this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ ordering: '-shift1_start_timestamp', job: jobId }, query))
                .subscribe(function (jobEvents) {
                var newDates = jobEvents.map(function (j) { return j.shift1Start; });
                _this.jobEvents = jobEvents.slice();
                var jobEvent = _this.jobEvent
                    ? _this.jobEvent
                    : _this.getJobEventWithDateAndJobEvents(jobEvents, _this.jobEventDate || newDates[0]);
                if (newDates.length && jobEvent && jobEvent.id) {
                    _this.highlightedDates = newDates;
                    _this.jobEventDatePickerOptions = {
                        min: newDates[0],
                        max: newDates[newDates.length - 1],
                    };
                    var query_1 = __assign({}, _this.carrierDropdownConfig.query, (jobEvent &&
                        jobEvent.id && {
                        carriers_for_jobevent: jobEvent.id,
                    }));
                    _this.jobEvent = jobEvent;
                    _this.model.jobevent = jobEvent.id;
                    _this.newPunchCardFormPatchValue('jobEvent', jobEvent.id);
                    _this.carrierDropdown.config.query = query_1;
                    _this.carrierDropdown.getRecords(query_1);
                }
                else {
                    _this.jobEventError = true;
                }
                _this.loading = false;
            }, function (err) {
                _this.loading = false;
                _this.errors = err;
            });
        }
        ;
    };
    NewPunchCardDialogComponent.prototype.getJobEventWithDateAndJobEvents = function (jobEvents, jobEventDate) {
        var selectedDate = moment(jobEventDate).format().split('T')[0];
        return jobEvents.find(function (j) { return j.shift1Start.includes(selectedDate); });
    };
    NewPunchCardDialogComponent.prototype.disableDates = function (startDate, endDate) {
        var copy = JSON.parse(JSON.stringify(this.jobEventDatePickerOptions));
        var min = new Date(startDate);
        var max = new Date(endDate);
        max.setDate(max.getDate() + 2);
        copy.min = min;
        copy.max = max;
        this.jobEventDatePickerOptions = copy;
    };
    NewPunchCardDialogComponent.prototype.newPunchCardFormPatchValue = function (key, value) {
        var _a;
        this.newPunchCardForm.patchValue((_a = {},
            _a[key] = value,
            _a));
    };
    NewPunchCardDialogComponent.prototype.fileChange = function (e) {
        var checkin;
        checkin = this.ticketImageFile;
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
            checkin.dataUri = loadEvent.target.result;
        };
        var file = e.srcElement.files[0];
        checkin.file = file;
        reader.readAsDataURL(file);
    };
    return NewPunchCardDialogComponent;
}());
exports.NewPunchCardDialogComponent = NewPunchCardDialogComponent;
