<div class="app-content">
  <my-toolbar *ngIf="!purchaseOrder.id" title="{{ 'New Order' | translate }}"></my-toolbar>
  <my-toolbar *ngIf="purchaseOrder.id" title="{{ purchaseOrder.name }} - {{ 'Edit Order' | translate }}"></my-toolbar>

  <div class="form-container">
    <form #purchaseOrderForm='ngForm'>
      <h1 *ngIf="!purchaseOrder.id" translate>New Order</h1>
      <h1 *ngIf="purchaseOrder.id" translate>Edit Order</h1>

      <div class="field-group">
        <label class="required" translate>Name</label>
        <input type="text" required name="name" [(ngModel)]="purchaseOrder.name" />
      </div>

      <div class="field-group">
        <label translate>P.O. #</label>
        <input type="text" name="po-number" [(ngModel)]="purchaseOrder.number" />
      </div>

      <div class="field-group">
        <div class="field">
          <label class="required" translate>Customer</label>
          <input type="hidden" name="customer" required [(ngModel)]="selectedCustomer" #customer="ngModel" />
          <dropdown id="customer" #customerDropdown [options]="customers"
                    [selectedOption]="selectedCustomer"
                    (onSelect)="customerSelected($event)"
                    title="{{ 'Select Customer' | translate }}"
                    [config]="customersDropdownConfig"
                    (onSearch)="delaySearch($event, 'customer')"
                    (nextPage)="dropdownNextPage($event, 'customer')"></dropdown>
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label class="required" translate>Product</label>
          <input type="hidden" name="product" required [(ngModel)]="selectedProduct" #product="ngModel" />
          <dropdown trim id="product" #productDropdown [options]="products"
                    [selectedOption]="selectedProduct"
                    (onSelect)="productSelected($event)"
                    title="{{ 'Select Product' | translate }}"
                    [config]="productsDropdownConfig"
                    (onSearch)="delaySearch($event, 'product')"
                    (nextPage)="dropdownNextPage($event, 'product')"></dropdown>
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label translate>Price List</label>
          <input type="hidden" name="price_list" [(ngModel)]="selectedPriceList" #priceList="ngModel" />
          <dropdown trim id="price-list" #priceListDropdown [options]="priceLists"
                    [selectedOption]="selectedPriceList"
                    (onSelect)="priceListSelected($event)"
                    [disabled]="!selectedProduct"
                    [config]="priceListsDropdownConfig"
                    title="{{ 'Select Price List' | translate }}"
                    (onSearch)="delaySearch($event, 'priceList')"
                    (nextPage)="dropdownNextPage($event, 'priceList')"></dropdown>
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label class="required" translate>Ordered Quantity</label>
          <div class="input-group">
            <input required id="ordered-quantity" name="ordered-quantity" [(ngModel)]="purchaseOrder.quantity">
            <div class="add-on add-on-width" *ngIf="purchaseOrder.unitOfMeasurement" [ngPlural]="purchaseOrder.quantity || 0">
              <ng-template ngPluralCase="=0">{{ purchaseOrder.unitOfMeasurement + 's' | translate }}</ng-template>
              <ng-template ngPluralCase="=1.00">{{ purchaseOrder.unitOfMeasurement | translate }}</ng-template>
              <ng-template ngPluralCase="other">{{ purchaseOrder.unitOfMeasurement + 's' | translate }}</ng-template>
            </div>
            <div class="add-on add-on-width" *ngIf="!purchaseOrder.unitOfMeasurement" [ngPlural]="purchaseOrder.quantity || 0">
              <ng-template ngPluralCase="=0">{{ 'units' | translate }}</ng-template>
              <ng-template ngPluralCase="=1.00">{{ 'unit' | translate }}</ng-template>
              <ng-template ngPluralCase="other">{{ 'units' | translate }}</ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <label attr.data-label="{{ 'Full Stop' | translate }}" class="toggle-label">
          <input type="checkbox" name="full_stop" [value]="true" checked="{purchaseOrder.fullStop}" [(ngModel)]="purchaseOrder.fullStop"
            class="toggle-input" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="field-group">
        <label attr.data-label="{{ 'Tax Exempt' | translate }}" class="toggle-label">
          <input type="checkbox" name="tax_exempt" [value]="true" checked="{purchaseOrder.taxExempt}" [(ngModel)]="purchaseOrder.taxExempt"
            class="toggle-input" />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="field-group">
        <div class="field">
          <label translate>Email</label>
          <input id="email" name="email" [(ngModel)]="purchaseOrder.email">
        </div>
      </div>

      <div class="field-group">
        <div class="field">
          <label translate>Phone Number</label>
          <input id="phone" name="phone" [(ngModel)]="purchaseOrder.phoneNumber">
        </div>

        <div class="field">
          <label translate>Fax Number</label>
          <input id="fax" name="fax" [(ngModel)]="purchaseOrder.faxNumber">
        </div>
      </div>

      <h2 translate>Delivery Location</h2>
      <div class="location">
        <div class="fields">
          <label translate>Select Address</label>
          <input type="hidden" name="location" [(ngModel)]="selectedLocation" #location="ngModel" />
          <dropdown id="location-dropdown" #locationDropdown
                    [selectedOption]="selectedLocation" [options]="locations"
                    (onSelect)="changeLocation($event)"
                    title="{{ 'Select Location' | translate }}" [config]="locationsDropdownConfig"
                    (onSearch)="delaySearch($event, 'location')"
                    (nextPage)="dropdownNextPage($event, 'location')">
          </dropdown>
        </div>
        <div class="map">
          <ngui-map center="{{ getCoord(1) }}, {{ getCoord(0) }}" disableDefaultUI="true" disableDoubleClickZoom="true"
            fullscreenControl="false" mapTypeControl="false" streetViewControl="false" zoomControl="false"
            clickableIcons="false" panControl="false" [zoom]="15">
            <marker *ngIf="purchaseOrder.deliveryLocation" draggable="false" [label]="''" [title]="purchaseOrder.deliveryLocation && purchaseOrder.deliveryLocation.name"
              position="{{ getCoord(1) }}, {{ getCoord(0) }}">
            </marker>
            <polygon *ngIf="purchaseOrder.deliveryLocation && purchaseOrder.deliveryLocation.paths" [editable]="false"
              [paths]="purchaseOrder.deliveryLocation && purchaseOrder.deliveryLocation.paths">
            </polygon>
          </ngui-map>
        </div>
      </div>

      <h2 translate>Additional Notes</h2>
      <label translate>Notes</label>
      <textarea [(ngModel)]="purchaseOrder.notes" name="notes"></textarea>

      <notification *ngFor="let error of errors">{{error}}</notification>

      <button id="create-purchase-order-button" class="btn btn-primary submit" [disabled]="!purchaseOrderForm.form.valid || loading"
        (click)="submit(); false" [ngClass]="{loading: loading}" translate>
        Save Order
      </button>
    </form>
  </div>
</div>
