"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["h1[_ngcontent-%COMP%]{font-size:36px;font-weight:300;margin:0 0 10px}h2[_ngcontent-%COMP%]{margin:0 0 10px}p[_ngcontent-%COMP%]{margin:0 0 30px;line-height:1.4}form[_ngcontent-%COMP%]{border-radius:3px;overflow:hidden}.invite-input[_ngcontent-%COMP%]{width:50%;margin-bottom:16px;padding-left:10px}.invite-input[_ngcontent-%COMP%]:first-of-type{padding-left:0;padding-right:10px}.invite-input[_ngcontent-%COMP%]:last-of-type{margin-left:auto}.modal-header[_ngcontent-%COMP%]{height:60px;background-color:#2c2c2d;color:#fff;margin:0;padding:0 30px;display:flex;justify-content:space-between;align-items:center}.modal-header[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{color:#fff;margin:0}.modal-header[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{transform:scale(1.5);opacity:.5;cursor:pointer}.dialog-section[_ngcontent-%COMP%]{padding:30px}.dialog-section.bottom[_ngcontent-%COMP%]{background-color:#f8fafc}.dialog-section.bottom.searching[_ngcontent-%COMP%]{min-height:auto}.dialog-section.bottom.searching[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{height:44px;flex-grow:1;margin-bottom:30px}.dialog-section.bottom.searching[_ngcontent-%COMP%]   .button-spacer[_ngcontent-%COMP%]{width:30px}.dialog-section.create-invite[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:100%;height:44px;margin:14px 0}.dialog-section.searching[_ngcontent-%COMP%]{min-height:320px;padding-bottom:0}.submit-message[_ngcontent-%COMP%]{background-color:#015bc5;padding:30px}.submit-message[_ngcontent-%COMP%]   h1[_ngcontent-%COMP%], .submit-message[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{color:#fff}.custom-search[_ngcontent-%COMP%]{width:100%}mat-dialog-actions[_ngcontent-%COMP%]{padding:0;margin:0}"];
exports.styles = styles;
