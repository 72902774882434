import { Resource } from '../../shared/resource';

export class EndOfDayOverallStats extends Resource {
  driversCount: number;
  plannedTrips: number;
  totalTrips: number;
  completedTrips: number;
  invoiceTotal: string;
  expenseTotal: string;
}
