"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var work_order_template_service_1 = require("./work-order-template.service");
var WorkOrderTemplatesComponent = /** @class */ (function () {
    function WorkOrderTemplatesComponent(translationService) {
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'name' },
            { key: 'created-at', title: this.translationService.instant('Created At'), sortable: true },
            { key: 'actions', title: this.translationService.instant('Action'), sortable: false }
        ];
        this.displayedColumns = [
            'name', 'created-at', 'actions'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            service: work_order_template_service_1.WorkOrderTemplateService,
            preferenceKey: 'WorkOrderTemplatesComponent-UserService',
            query: {},
            collectionTitle: this.translationService.instant('Work Order Templates'),
            noResultsText: this.translationService.instant('a work order template'),
            hasNoResultsAction: false,
            sortBy: 'name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('View HTML'), action: 'html', link: true, external: true },
                { name: this.translationService.instant('View PDF'), action: 'pdf', link: true, external: true }
            ]
        };
    }
    WorkOrderTemplatesComponent.prototype.ngOnInit = function () { };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    WorkOrderTemplatesComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return WorkOrderTemplatesComponent;
}());
exports.WorkOrderTemplatesComponent = WorkOrderTemplatesComponent;
