import { TranslateService } from '@ngx-translate/core';

export const DISPLAYEDCOLUMNS: string[] = [
  'select',
  'job-date',
  'job',
  'load-ticket-number',
  'load-ticket-weight',
  'load-ticket-image',
  'load-signature-image',
  'unload-ticket-number',
  'unload-ticket-weight',
  'unload-ticket-image',
  'unload-signature-image',
  'carrier',
  'driver',
  'truck',
  'time',
  'duration',
  'payable-to',
  'customer',
  'invoice',
  'invoice-total',
  'haul',
  'haul-total',
  'invoice-number',
  'expense-title',
  'paystub-title',
  'billing-id',
  'reviewed-status',
  'reviewed-by',
  'actions',
];

export const AVAILABLECOLUMNS = (
  translationService: TranslateService
): {
  key: string;
  title?: string;
  sortable?: boolean;
  sortBy?: string;
}[] => [
  { key: 'select' },
  {
    key: 'job-date',
    title: translationService.instant('Job Date'),
    sortable: true,
    sortBy: 'jobevent__shift1_start',
  },
  {
    key: 'job',
    title: translationService.instant('Job'),
    sortable: true,
    sortBy: 'jobevent__job__name',
  },
  {
    key: 'load-ticket-number',
    title: translationService.instant('Load Ticket #'),
    sortable: false,
  },
  {
    key: 'load-ticket-weight',
    title: translationService.instant('Load Wt'),
    sortable: false,
  },
  {
    key: 'load-ticket-image',
    title: translationService.instant('Load Ticket'),
    sortable: false,
  },
  {
    key: 'load-signature-image',
    title: translationService.instant('Load Signature'),
    sortable: false,
  },
  {
    key: 'unload-ticket-number',
    title: translationService.instant('Unload Ticket #'),
    sortable: false,
  },
  {
    key: 'unload-ticket-weight',
    title: translationService.instant('Unload Wt'),
    sortable: false,
  },
  {
    key: 'unload-ticket-image',
    title: translationService.instant('Unload Ticket'),
    sortable: false,
  },
  {
    key: 'unload-signature-image',
    title: translationService.instant('Unload Signature'),
    sortable: false,
  },
  {
    key: 'carrier',
    title: translationService.instant('Carrier'),
    sortable: true,
    sortBy: 'driver__carrier__name',
  },
  {
    key: 'driver',
    title: translationService.instant('Driver'),
    sortable: true,
    sortBy: 'driver__profile__first_name',
  },
  {
    key: 'truck',
    title: translationService.instant('Truck'),
    sortable: true,
    sortBy: 'truck__name',
  },
  {
    key: 'time',
    title: translationService.instant('Time'),
    sortable: false,
  },
  {
    key: 'duration',
    title: translationService.instant('Duration'),
    sortable: false,
  },
  {
    key: 'payable-to',
    title: translationService.instant('Payable To'),
    sortable: true,
    sortBy: 'jobevent__owner_organization__name',
  },
  {
    key: 'customer',
    title: translationService.instant('Customer'),
    sortable: true,
    sortBy: 'jobevent__customer_organization__name',
  },
  {
    key: 'invoice',
    title: translationService.instant('Invoice Rate'),
    sortable: false,
  },
  {
    key: 'invoice-total',
    title: translationService.instant('Invoice Total'),
    sortable: false,
  },
  {
    key: 'haul',
    title: translationService.instant('Haul Rate'),
    sortable: false,
  },
  {
    key: 'haul-total',
    title: translationService.instant('Haul Total'),
    sortable: false,
  },
  {
    key: 'invoice-number',
    title: translationService.instant('Invoice #'),
    sortable: false,
  },
  {
    key: 'expense-title',
    title: translationService.instant('Expense'),
    sortable: false,
  },
  {
    key: 'paystub-title',
    title: translationService.instant('Paystub'),
    sortable: false,
  },
  {
    key: 'billing-id',
    title: translationService.instant('Billing ID'),
    sortable: false,
  },
  {
    key: 'reviewed-status',
    title: translationService.instant('Reviewed Status'),
    sortable: false,
  },
  {
    key: 'reviewed-by',
    title: translationService.instant('Reviewed By'),
    sortable: false,
  },
  {
    key: 'actions',
    title: translationService.instant('Actions'),
    sortable: false,
  },
];

export const SINGLEROWACTIONS = (translationService: TranslateService) => {
  return [
    {
      name: translationService.instant('Edit'),
      action: 'edit',
      link: true,
      external: false,
      returnTo: '',
    },
    {
      name: translationService.instant('Void'),
      action: 'void',
      link: false,
      external: false,
    },
    {
      name: translationService.instant('Unvoid'),
      action: 'unvoid',
      link: false,
      external: false,
    },
    {
      name: translationService.instant('Details'),
      action: '',
      link: true,
      external: false,
      returnTo: '',
    },
  ];
};
