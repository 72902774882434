import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../../shared/api.service';

@Pipe({
  name: 'state',
})
export class StatePipe implements PipeTransform {
  constructor(private apiService: ApiService) {}
  transform(value: string): { name: string; abbreviation: string } {
    return this.apiService
      .getStates()
      .find((state) => state.abbreviation === value);
  }
}
