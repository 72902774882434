<widget [size]="[2,2]" (window:resize)="onResize($event)">
  <div class="heading" widget-header>
    <h2>Daily Avg Status Time</h2>
  </div>
  <div class="content" widget-content>
    <div class="widget-table">
      <div class="table-body">
        <div class="sub-headers">
          <p>Loading</p>
          <p>Unloading</p>
        </div>
        <div class="header">
          <div class="head location sort" (click)="sort('name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'name'}" translate>
            Location
          </div>
          <ng-container>
            <div class="head visits sort" (click)="sort('loading_visits')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loading_visits'}" translate>
             Visits
            </div>
            <div class="head minutes sort" (click)="sort('loading_time_spent')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'loading_time_spent'}" translate>
               Minutes
            </div>
          </ng-container>
          <ng-container>
            <div class="head visits sort" (click)="sort('unloading_visits')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'unloading_visits'}"
              translate>
               Visits
            </div>
            <div class="head minutes sort" (click)="sort('unloading_time_spent')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'unloading_time_spent'}"
              translate>
               Minutes
            </div>
          </ng-container>
        </div>

        <div *ngFor="let location of locations" class="row">
          <div class="location">
            <a [routerLink]="[ '/locations', location.id ]">{{ location.displayName }}</a>
          </div>
          <ng-container>
            <div class="visits">
              {{ location.loadingVisits }}
            </div>
            <div class="minutes">
              <div class="badge default">{{ (location.loadingTimeSpent || 0) | number : '1.0-2' }}</div>
            </div>
          </ng-container>
          <ng-container>
            <div class="visits">
              {{ location.unloadingVisits }}
            </div>
            <div class="minutes">
              <div class="badge default">{{ (location.unloadingTimeSpent || 0) | number : '1.0-2' }}</div>
            </div>
          </ng-container>
        </div>

        <div class="row" *ngIf="!loading && !locations">
          <div class="job">No Locations Found</div>
        </div>

        <my-loader *ngIf="loading"></my-loader>
      </div>
    </div>
  </div>
</widget>
