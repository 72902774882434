"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var api_service_1 = require("../shared/api.service");
var user_service_1 = require("./user.service");
var authentication_service_1 = require("../shared/authentication.service");
var api_service_2 = require("../shared/api.service");
var user_serializer_1 = require("./user.serializer");
var user_1 = require("./user");
var EditUserComponent = /** @class */ (function () {
    function EditUserComponent(route, router, apiService, userService, authenticationService, dialog) {
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.loading = true;
        this.savePasswordLoading = true;
        this.accountUpdateLoading = false;
        this.imageChanged = false;
        this.stateOptions = [];
        this.errors = [];
        this.passwordErrors = [];
        this.userPassword = {
            passwordCurrent: '',
            password: '',
            passwordConfirmation: ''
        };
        this.profileImage = {
            dataUri: null,
            file: null
        };
    }
    EditUserComponent.prototype.ngOnInit = function () {
        this.loading = false;
        this.savePasswordLoading = false;
    };
    EditUserComponent.prototype.ngOnDestroy = function () {
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
    };
    EditUserComponent.prototype.saveUser = function () {
        var _this = this;
        this.loading = true;
        this.userService.saveWithImage(this.user, this.profileImage.file).subscribe(function (user) {
            _this.user = (new user_serializer_1.UserSerializer).fromJson(user);
            var _user = _this.authenticationService.user();
            _user.name = _this.user.firstName + ' ' + _this.user.lastName;
            _user.username = _this.user.email;
            _user.image = _this.user.image;
            localStorage.setItem('currentUser', JSON.stringify(_user));
            _this.router.navigate(['/settings', 'user']);
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_2.parseErrors(err);
            _this.loading = false;
        });
    };
    EditUserComponent.prototype.savePassword = function () {
        var _this = this;
        this.savePasswordLoading = true;
        var _user = Object.assign({ id: this.user.id }, this.userPassword);
        this.userService.save(_user).subscribe(function () {
            _this.savePasswordLoading = false;
            _this.userPassword = {
                passwordCurrent: '',
                password: '',
                passwordConfirmation: ''
            };
            _this.editPassword.form.markAsPristine();
            _this.editPassword.form.markAsUntouched();
        }, function (err) {
            _this.passwordErrors = api_service_2.parseErrors(err);
            _this.savePasswordLoading = false;
        });
    };
    EditUserComponent.prototype.fileChange = function (e, form) {
        var profileImage = this.profileImage;
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
            profileImage.dataUri = loadEvent.target.result;
        };
        var file = e.srcElement.files[0];
        profileImage.file = file;
        reader.readAsDataURL(file);
        this.imageChanged = true;
    };
    EditUserComponent.prototype.updateAccount = function () {
        var _this = this;
        this.accountUpdateLoading = true;
        if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
            this.userReq.unsubscribe();
        }
        this.userReq = this.userService.get(this.user.id).subscribe(function (user) {
            _this.authenticationService.storeUser(Object.assign(_this.user, {
                features: user.features,
                enabledFeatures: user.enabledFeatures,
                organization: user.organization
            }));
            _this.accountUpdateLoading = false;
        }, function (err) {
            _this.accountUpdateLoading = false;
        });
    };
    return EditUserComponent;
}());
exports.EditUserComponent = EditUserComponent;
