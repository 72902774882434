"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var marketplace_service_1 = require("../marketplace/marketplace.service");
var MarketplaceListingsComponent = /** @class */ (function () {
    function MarketplaceListingsComponent(route, router, elementRef, marketplaceService) {
        this.route = route;
        this.router = router;
        this.elementRef = elementRef;
        this.marketplaceService = marketplaceService;
        this.loading = true;
        this.errors = [];
        this.marketplaces = [];
    }
    MarketplaceListingsComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.errors = [];
        this.disableSidebar();
        this.getMarketplaces();
    };
    MarketplaceListingsComponent.prototype.disableSidebar = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser !== {}) {
            currentUser.sidebar = false;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
    };
    MarketplaceListingsComponent.prototype.getMarketplaces = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.marketplacesReq) {
            this.marketplacesReq.unsubscribe();
        }
        this.marketplacesReq = this.marketplaceService.getMarketplaces(__assign({ ordering: 'jobevent__shift1_start,jobevent__job__name' }, query)).subscribe(function (marketplaces) { return _this.marketplaces = marketplaces; }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    MarketplaceListingsComponent.prototype.openOverview = function (marketplace) {
        this.router.navigate(['/marketplace/' + marketplace.id]);
    };
    return MarketplaceListingsComponent;
}());
exports.MarketplaceListingsComponent = MarketplaceListingsComponent;
