"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var moment = require("moment");
var ngx_device_detector_1 = require("ngx-device-detector");
var job_event_1 = require("../job-events/job-event");
var device_1 = require("../shared/device");
var PunchCardAssignmentsComponent = /** @class */ (function () {
    function PunchCardAssignmentsComponent(deviceDetectorService) {
        this.deviceDetectorService = deviceDetectorService;
        this.device = new device_1.Device();
        this.loading = false;
        this.activeAssignment = new core_1.EventEmitter();
        this.expandedDrivers = [];
        this.activeStatuses = [
            {
                name: 'Unloading',
                active: false
            }, {
                name: 'Enroute to Unloading',
                active: false
            }, {
                name: 'Loading',
                active: false
            }, {
                name: 'Enroute to Loading',
                active: false
            }
        ];
        this.controlState = 'trips';
        this.controls = [];
    }
    PunchCardAssignmentsComponent.prototype.ngOnInit = function () {
        this.getAvailableStatuses(this.assignments);
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    };
    PunchCardAssignmentsComponent.prototype.getAvailableStatuses = function (assignments) {
        var _this = this;
        assignments.forEach(function (assignment) {
            if (assignment.tripStats.lastValues.unloading || assignment.tripStats.lastValues.status === 'unloading') {
                _this.activeStatuses[0].active = true;
            }
            else if (assignment.tripStats.lastValues.enrouteUnloading || assignment.tripStats.lastValues.status === 'enroute_unloading') {
                _this.activeStatuses[1].active = true;
            }
            else if (assignment.tripStats.lastValues.loading || assignment.tripStats.lastValues.status === 'loading') {
                _this.activeStatuses[2].active = true;
            }
            else if (assignment.tripStats.lastValues.enrouteLoading || assignment.tripStats.lastValues.status === 'enroute_loading') {
                _this.activeStatuses[3].active = true;
            }
        });
    };
    PunchCardAssignmentsComponent.prototype.expandDriver = function (driver) {
        if (lodash_1.find(this.expandedDrivers, { id: driver.id })) {
            lodash_1.remove(this.expandedDrivers, { id: driver.id });
        }
        else {
            this.expandedDrivers.push(driver);
        }
    };
    PunchCardAssignmentsComponent.prototype.expandedDriver = function (driver) {
        if (lodash_1.find(this.expandedDrivers, { id: driver.id })) {
            return true;
        }
        return false;
    };
    PunchCardAssignmentsComponent.prototype.startHour = function (assignment, hour) {
        var start = false;
        if (assignment.driver && assignment.driver.punchCard) {
            var time = moment(assignment.driver.punchCard.startTimeTimestamp).format('h a');
            if (time === hour) {
                start = true;
            }
        }
        return start;
    };
    PunchCardAssignmentsComponent.prototype.completeHour = function (assignment, hour, index) {
        var complete = false;
        var currentTime = moment();
        if (assignment.driver && assignment.driver.punchCard) {
            var startTime = void 0, endTime = void 0, timeAtHour = void 0;
            if (assignment.driver.punchCard.startTimeTimestamp) {
                startTime = new Date(assignment.driver.punchCard.startTimeTimestamp);
                var _date = moment(startTime).format('YYYY-MM-DD');
                timeAtHour = moment(_date + ' ' + hour, 'YYYY-MM-DD h:mm a');
                timeAtHour.add(3599, 'seconds');
            }
            else {
                return false;
            }
            if (assignment.driver.punchCard.endTimeTimestamp) {
                endTime = new Date(assignment.driver.punchCard.endTimeTimestamp);
            }
            if (startTime < timeAtHour && !endTime && timeAtHour < currentTime) {
                complete = true;
            }
            else if (startTime < timeAtHour && timeAtHour < endTime) {
                complete = true;
            }
        }
        return complete;
    };
    PunchCardAssignmentsComponent.prototype.styles = function (assignment, hour, index) {
        var style = {};
        var startStyle = this.startStyles(assignment, hour);
        if (startStyle) {
            style = Object.assign({}, style, startStyle);
        }
        var activeStyle = this.activeStyles(assignment, hour, index);
        if (activeStyle) {
            style = Object.assign({}, style, activeStyle);
        }
        return style;
    };
    PunchCardAssignmentsComponent.prototype.startStyles = function (assignment, hour) {
        if (this.startHour(assignment, hour)) {
            var timeOffset = void 0;
            if (assignment.driver.punchCard.startTimeTimestamp) {
                var startTime = new Date(assignment.driver.punchCard.startTimeTimestamp);
                var minutes = Number(moment(startTime).format('mm'));
                if (minutes <= 5) {
                    return;
                }
                else if (minutes <= 15) {
                    timeOffset = '25px';
                }
                else if (minutes <= 30) {
                    timeOffset = '35px';
                }
                else if (minutes <= 45) {
                    timeOffset = '45px';
                }
                else if (minutes <= 55) {
                    timeOffset = '50px';
                }
            }
            return { 'margin-left': timeOffset };
        }
    };
    PunchCardAssignmentsComponent.prototype.activeStyles = function (assignment, hour, index) {
        if (!this.startHour(assignment, hour) && !this.completeHour(assignment, hour, index)) {
            var timeOffset = void 0;
            if (!assignment.driver ||
                !assignment.driver.punchCard ||
                !assignment.driver.punchCard.endTimeTimestamp) {
                var currentTime = moment();
                var minutes = Number(currentTime.format('mm'));
                if (minutes <= 5) {
                    timeOffset = '75px';
                }
                else if (minutes <= 15) {
                    timeOffset = '55px';
                }
                else if (minutes <= 30) {
                    timeOffset = '45px';
                }
                else if (minutes <= 45) {
                    timeOffset = '25px';
                }
                else if (minutes <= 55) {
                    timeOffset = '10px';
                }
            }
            return { 'margin-right': timeOffset };
        }
    };
    PunchCardAssignmentsComponent.prototype.timeOverlayStyles = function (hours) {
        var style = {};
        var width = 90;
        var hoursSkipped = 0;
        var timeOffset = 0;
        var currentTime = moment();
        var hourTime = moment(currentTime.format('YYYY-MM-DD') + ' ' + hours[0], 'YYYY-MM-DD h:mm a');
        while (hourTime < currentTime) {
            hourTime.add(1, 'hour');
            hoursSkipped += 1;
            timeOffset = width * (hoursSkipped - 1);
            var minutes = Number(currentTime.format('mm'));
            if (minutes <= 5) {
                timeOffset += 15;
            }
            else if (minutes <= 15) {
                timeOffset += 35;
            }
            else if (minutes <= 30) {
                timeOffset += 45;
            }
            else if (minutes <= 45) {
                timeOffset += 65;
            }
            else if (minutes <= 55) {
                timeOffset += 80;
            }
            else {
                timeOffset += 85;
            }
        }
        if (timeOffset > 0) {
            style = { 'left': timeOffset + 'px' };
        }
        return style;
    };
    PunchCardAssignmentsComponent.prototype.selectActiveAssignment = function (assignment) {
        this.activeAssignment.emit(assignment);
    };
    /**
     * @param  {} control
     * @param  {} event
     * @returns void
     * This function is called on toggling the segmented controls: GPS, Trips, or Hourly
     */
    PunchCardAssignmentsComponent.prototype.onSelectToggle = function (control, event) {
        var checkbox = event.target;
        this.controls.forEach(function (_control) { _control.selected = false; });
        control.selected = checkbox.checked;
        this.controlState = control.name;
    };
    return PunchCardAssignmentsComponent;
}());
exports.PunchCardAssignmentsComponent = PunchCardAssignmentsComponent;
