"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resource_service_1 = require("../shared/resource.service");
var http_1 = require("@angular/common/http");
var preference_serializer_1 = require("./preference.serializer");
var rxjs_1 = require("rxjs");
var PreferenceService = /** @class */ (function (_super) {
    __extends(PreferenceService, _super);
    function PreferenceService(http) {
        var _this = _super.call(this, http, 'preferences/', new preference_serializer_1.PreferenceSerializer()) || this;
        _this.userPreferenceKey = 'GeneralUserPreferences';
        _this.userPreference$ = new rxjs_1.BehaviorSubject(new preference_serializer_1.PreferenceSerializer().fromJson({
            blob: {
                dispatchLoadType: 'all-day',
                mapStyle: 'ruckit-map-style',
                markerType: 'driver-initials',
                markerStatus: 'each-segment',
                staggerAssignmentTime: 'staggered_start',
                distanceUnit: 'miles'
            }
        }));
        return _this;
    }
    PreferenceService.prototype.getUserPreferenceObservable = function (userId) {
        var _this = this;
        this.list({
            name: this.userPreferenceKey,
            type: 'user',
            profile: userId
        }).subscribe(function (preference) {
            var userPreference;
            if (preference && preference.length) {
                userPreference = preference[0];
            }
            if (userPreference.blob && !userPreference.blob.hasOwnProperty('distanceUnit')) {
                userPreference.blob['distanceUnit'] = 'miles';
            }
            _this.userPreference$.next(userPreference);
        });
    };
    return PreferenceService;
}(resource_service_1.ResourceService));
exports.PreferenceService = PreferenceService;
