import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { WorkOrderTemplateService } from './work-order-template.service';

@Component({
  selector: 'work-order-templates',
  templateUrl: './work-order-templates.component.html',
  styleUrls: ['./work-order-templates.component.scss']
})
export class WorkOrderTemplatesComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'name', title: this.translationService.instant('Name'), sortable: true, sortBy: 'name' },
    { key: 'created-at', title: this.translationService.instant('Created At'), sortable: true },
    { key: 'actions', title: this.translationService.instant('Action'), sortable: false }
  ];
  @Input() displayedColumns = [
    'name', 'created-at', 'actions'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  errors = [];
  tableConfig = {
    hasHeader: true,
    service: WorkOrderTemplateService,
    preferenceKey: 'WorkOrderTemplatesComponent-UserService',
    query: {},
    collectionTitle: this.translationService.instant('Work Order Templates'),
    noResultsText: this.translationService.instant('a work order template'),
    hasNoResultsAction: false,
    sortBy: 'name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('View HTML'), action: 'html', link: true, external: true },
      { name: this.translationService.instant('View PDF'), action: 'pdf', link: true, external: true }
    ]
  };

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;

  constructor(private translationService: TranslateService) {}

  ngOnInit() { }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
