"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var driver_1 = require("../drivers/driver");
var job_event_1 = require("../job-events/job-event");
var truck_service_1 = require("../trucks/truck.service");
var router_1 = require("@angular/router");
var carrier_1 = require("../carriers/carrier");
var MobileDispatchTrucksComponent = /** @class */ (function () {
    function MobileDispatchTrucksComponent(router, truckService) {
        this.router = router;
        this.truckService = truckService;
        this.selectTruck = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
    }
    MobileDispatchTrucksComponent.prototype.ngOnChanges = function (changes) {
        if (changes.scrollEvent && !this.loading &&
            this.scrollEvent && this.scrollEvent.target &&
            this.scrollEvent.target.scrollTop >
                this.scrollEvent.target.scrollHeight -
                    this.scrollEvent.target.clientHeight * 3) {
            this.getTrucks({}, true);
        }
    };
    MobileDispatchTrucksComponent.prototype.ngOnInit = function () {
        if (this.selectedDriver) {
            this.selectedTruck = this.selectedDriver.truck && this.selectedDriver.truck.id ? this.selectedDriver.truck : null;
        }
    };
    MobileDispatchTrucksComponent.prototype.ngAfterViewInit = function () {
        if (this.selectedDriver) {
            this.getTrucks();
        }
        else if (this.jobEvent) {
            this.router.navigate([
                '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'assign'
            ]);
        }
        else {
            this.router.navigate(['/dispatch']);
        }
    };
    MobileDispatchTrucksComponent.prototype.select = function (truck) {
        this.selectTruck.emit({ truck: truck, driver: this.selectedDriver });
    };
    MobileDispatchTrucksComponent.prototype.getNextTrucks = function (e) {
        this.getTrucks({}, true);
    };
    MobileDispatchTrucksComponent.prototype.getTrucks = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.trucks && !this.trucks) {
            this.trucks = [];
        }
        this.loading = true;
        if (this.trucksReq) {
            this.trucksReq.unsubscribe();
        }
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var carrier, carrierId;
        if (this.selectedCarrier) {
            carrier = this.selectedCarrier;
        }
        else if (this.selectedDriver && this.selectedDriver.carrier) {
            carrier = this.selectedDriver.carrier;
        }
        if (!['all_carriers', 'all_leased', 'my_drivers'].includes(carrier && carrier.id)) {
            carrierId = carrier.id;
        }
        var request = next ? this.truckService.listNext() : this.truckService.list({
            ordering: 'name', carrier: carrierId, jobevent_available: jobEventId
        });
        this.trucksReq = request && request.subscribe(function (trucks) {
            _this.trucks = trucks;
        }, function (err) {
            _this.errors = err;
        }, function () { return _this.loading = false; });
        if (!request) {
            this.loading = false;
        }
    };
    return MobileDispatchTrucksComponent;
}());
exports.MobileDispatchTrucksComponent = MobileDispatchTrucksComponent;
