"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var moment = require("moment-timezone");
var rxjs_1 = require("rxjs");
var core_2 = require("@ngx-translate/core");
var lodash_1 = require("lodash");
// services
var job_service_1 = require("../../jobs/job.service");
var attachment_service_1 = require("../../attachments/attachment.service");
var trip_service_1 = require("../trip.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var condensed_trip_1 = require("./../condensed-trip");
// components
var multiple_images_edit_component_1 = require("../../shared/multiple-images-edit/multiple-images-edit.component");
// constants
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
var shared_1 = require("../../shared");
var dropdownsConfig_1 = require("./dropdownsConfig");
var app_constants_1 = require("../../app.constants");
// serializers
var job_serializer_1 = require("../../jobs/job.serializer");
var project_serializer_1 = require("../../projects/project.serializer");
// validators
var trip_time_validators_1 = require("../trip-time-validators");
var NewTripComponent = /** @class */ (function () {
    function NewTripComponent(route, router, tripService, attachmentsService, jobService, jobEventService, authenticationService, translate, fb) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.tripService = tripService;
        this.attachmentsService = attachmentsService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.authenticationService = authenticationService;
        this.translate = translate;
        this.fb = fb;
        this.jobDates = [];
        this.errors = [];
        this.trip = new condensed_trip_1.CondensedTrip({});
        this.loading = false;
        this.daysLoading = false;
        this.loadingTicketImages = [];
        this.unloadingTicketImages = [];
        this.loadingSignatures = [];
        this.unloadingSignatures = [];
        this.uploading = false;
        this.skipResetFormOnJobChange = false;
        this.jobDropdownConfig = dropdownsConfig_1.JOB_DROPDOWN_CONFIG(this.translate);
        this.carrierDropdownConfig = dropdownsConfig_1.CARRIER_DROPDOWN_CONFIG(this.translate);
        this.driverDropdownConfig = dropdownsConfig_1.DRIVER_DROPDOWN_CONFIG(this.translate);
        this.truckDropdownConfig = dropdownsConfig_1.TRUCK_DROPDOWN_CONFIG(this.translate);
        this.fieldsToCheck = [
            'activeTracking',
            'ticketImage',
            'signatureImage',
            'ticketNumber',
            'weight',
        ];
        this.requiredImages = {
            loadingImages: false,
            unloadingImages: false,
            loadingSignature: false,
            unloadingSignature: false,
        };
        this.configRangePicker = {};
        this.disabledDates = [];
        this.jobEventDate = null;
        this.parseDateTime = function (date, time) {
            return !!date && !!time
                ? moment(moment(date).format('YYYY-MM-DD') + " " + time).toISOString()
                : null;
        };
        this.onImagesChangeParser = function (images) {
            return images
                .filter(function (img) { return img.isDeleted !== true; })
                .map(function (img) {
                return img.isNew || img.isEdited
                    ? Object.assign(img, {
                        tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                    })
                    : _this.parseImageOnChange(img);
            });
        };
    }
    NewTripComponent.prototype.ngOnInit = function () {
        var _this = this;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        combinedParams.subscribe(function (result) {
            _this.returnTo = result && result.qparams['returnTo'];
            if (result && result.params['jobId']) {
                var jobId = result.params['jobId'];
                var jobEventId = result.params['jobEventId'];
                _this.getJob(jobId, jobEventId);
            }
        });
        this.user = this.authenticationService.user();
        this.carrierDropdownConfig.query = {
            exclude: this.user.organization.id,
        };
        this.carrierDropdownConfig.prefilledOptions.push(this.user.organization);
        this.initForm();
    };
    NewTripComponent.prototype.submitTrip = function () {
        var _this = this;
        this.errors = [];
        var newTrip = this.parseTrip();
        this.loading = true;
        this.tripService.saveWithCheckinsAndAttachments(newTrip).subscribe(function () {
            if (_this.returnTo) {
                _this.router.navigate([_this.returnTo]);
            }
            else {
                _this.router.navigate(['/trips']);
            }
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
            _this.loading = false;
        });
    };
    NewTripComponent.prototype.parseTrip = function () {
        var _this = this;
        var formTrip = this.newTripForm.value;
        var loadingImages = this.loadingTicketImages.concat(this.loadingSignatures.map(function (signature) {
            Object.assign(signature, {
                tempType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
            });
            return signature;
        }));
        var unloadingImages = this.unloadingTicketImages.concat(this.unloadingSignatures.map(function (signature) {
            Object.assign(signature, {
                tempType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
            });
            return signature;
        }));
        var parsedLoadingImages = loadingImages.map(function (img) {
            return _this.attachmentsService.parseAttachment(img, img.tempType ? img.tempType : app_constants_1.TRIPTICKETIMAGETYPE, null);
        });
        var parsedUnloadingImages = unloadingImages.map(function (img) {
            return _this.attachmentsService.parseAttachment(img, img.tempType ? img.tempType : app_constants_1.TRIPTICKETIMAGETYPE, null);
        });
        var trip = __assign({}, this.newTripForm.value, { job: formTrip.job.id, jobEvent: formTrip.jobEvent.id, jobevent: formTrip.jobEvent.id, carrier: formTrip.carrier.id, driver: formTrip.driver.id, truck: formTrip.truck.id, startTime: this.parseDateTime(formTrip.start.date, formTrip.start.time), endTime: this.parseDateTime(formTrip.end.date, formTrip.end.time), loadingCheckin: __assign({}, formTrip.loadingCheckin, { attachments: parsedLoadingImages, date: this.parseDateTime(formTrip.loadingCheckin.date, formTrip.loadingCheckin.time) }), unloadingCheckin: __assign({}, formTrip.unloadingCheckin, { date: this.parseDateTime(formTrip.unloadingCheckin.date, formTrip.unloadingCheckin.time), attachments: parsedUnloadingImages }) });
        if (trip.loadingCheckin['images']) {
            delete trip.loadingCheckin['images'];
        }
        if (trip.unloadingCheckin['images']) {
            delete trip.unloadingCheckin['images'];
        }
        return trip;
    };
    NewTripComponent.prototype.getJob = function (id, jobEventId) {
        var _this = this;
        if (id && jobEventId) {
            this.skipResetFormOnJobChange = true;
        }
        if (id) {
            this.loading = true;
            this.jobService.get(id).subscribe(function (job) {
                _this.job = job;
                if (_this.job) {
                    _this.jobsDropdown.options = lodash_1.reject(_this.jobsDropdown.options, _this.job);
                }
                else {
                    var project = new project_serializer_1.ProjectSerializer().fromJson({
                        id: _this.job.project.id,
                        name: _this.job.project.name,
                    });
                    _this.job = new job_serializer_1.JobSerializer().fromJson({
                        id: _this.job.id,
                        name: _this.job.name,
                        project: project,
                    });
                }
                var jobId = _this.job && _this.job.id;
                _this.jobsDropdown.options.unshift(_this.job);
                _this.getJobDays(jobId);
                _this.jobsDropdown.selectedOption = _this.job;
                _this.loading = false;
            }, function (err) {
                _this.errors = err;
                _this.loading = false;
            });
        }
        if (jobEventId) {
            this.jobEventService.getJobEvent(jobEventId).subscribe(function (jobEvent) {
                _this.jobEvent = jobEvent;
                _this.jobEventDate = [moment(jobEvent.shift1Start).toDate()];
            }, 
            // error
            function () { }, 
            // complete
            function () {
                _this.getDataByJobEvent();
            });
        }
    };
    NewTripComponent.prototype.getDataByJobEvent = function (_a) {
        var _b = (_a === void 0 ? {} : _a).autoSelectCarrier, autoSelectCarrier = _b === void 0 ? true : _b;
        this.newTripFormSetRequiredFields(this.jobEvent);
        this.setRequiredImages(this.jobEvent);
        var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: this.jobEvent.id });
        this.carriersDropdown.config.query = query;
        this.carriersDropdown.getRecords(query);
        // preselect current user's carrier
        if (autoSelectCarrier && (this.carrier === null || this.carrier === undefined)) {
            this.carriersDropdown.setSelectedOption(this.user.organization);
        }
    };
    NewTripComponent.prototype.onDateChange = function (args, dates) {
        if (args && args.length > 0 && dates) {
            this.newTripFormPatchValueNested(args, dates[0]);
        }
    };
    NewTripComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                if (this.job !== e) {
                    this.job = e;
                    var jobId = e && e.id;
                    this.resetForm();
                    this.getJobDays(jobId);
                }
                break;
            case 'carrier':
                var carrier = e.carrier;
                if (this.carrier === null || (this.carrier && this.carrier.id !== carrier.id)) {
                    this.carrier = carrier;
                    this.driver = null;
                    this.truck = null;
                    this.driversDropdown.getRecords({
                        carrier: carrier.id,
                        shared_jobevent: this.jobEvent.id,
                    });
                    this.truckDropdown.getRecords({
                        carrier: carrier.id,
                    });
                }
                break;
            case 'driver':
                this.driver = e;
                break;
            case 'truck':
                this.truck = e;
                break;
        }
        this.newTripFormPatchValue(filterType, e);
    };
    NewTripComponent.prototype.getJobDays = function (jobId) {
        var _this = this;
        this.jobService.getDays(jobId).subscribe(function (days) {
            _this.jobDates = days.map(function (day) { return moment(day).toDate(); });
            _this.daysLoading = false;
            _this.setupAllowedJobDaysCalendar();
        }, function (err) {
            _this.errors = err;
            _this.daysLoading = false;
        });
    };
    NewTripComponent.prototype.setupAllowedJobDaysCalendar = function () {
        if (this.jobDates && this.jobDates.length) {
            this.disabledDates = [];
            var minDate = this.jobDates[0];
            var maxDate = this.jobDates[this.jobDates.length - 1];
            this.configRangePicker = {
                min: minDate,
                max: maxDate
            };
            var accumulatorDate = moment(minDate);
            var _loop_1 = function () {
                var currentDate = accumulatorDate.toDate();
                var isAllowedDay = this_1.jobDates.some(function (jobEventDate) { return (currentDate.toDateString() === jobEventDate.toDateString()); });
                if (!isAllowedDay) {
                    this_1.disabledDates.push(currentDate);
                }
                accumulatorDate = accumulatorDate.add(1, 'd');
            };
            var this_1 = this;
            while (accumulatorDate.toDate() < maxDate) {
                _loop_1();
            }
        }
    };
    NewTripComponent.prototype.onJobEventDateChange = function (dates) {
        var _this = this;
        if (!this.job || !this.job.id || !dates && dates.length) {
            return;
        }
        this.jobEventDate = dates;
        var selectedDate = new Date(dates[0]).toISOString();
        this.jobEventService.listCompact({
            ordering: '-shift1Start',
            job: this.job.id,
            shift1_start__gte: selectedDate,
            page_size: 1,
        }).subscribe(function (jobEvents) {
            var jobEventSelected = jobEvents && jobEvents.length && jobEvents[0];
            if (jobEventSelected && (!_this.jobEvent || _this.jobEvent.id !== jobEventSelected.id)) {
                _this.jobEvent = jobEventSelected;
                _this.getDataByJobEvent();
                _this.newTripFormPatchValue('jobEvent', jobEventSelected);
            }
        });
    };
    NewTripComponent.prototype.handleImagesDialogVisibility = function () {
        this.editImagesContainer.handleVisibility();
    };
    NewTripComponent.prototype.parseImageOnChange = function (image) {
        if (image) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                image.file = reader_1.result;
                image.src = reader_1.result;
            };
            reader_1.readAsDataURL(image);
            return image;
        }
        return image;
    };
    NewTripComponent.prototype.onImagesChange = function (type, checkin, images) {
        var parsedImages = this.onImagesChangeParser(images);
        this[type] = parsedImages;
        this.newTripFormPatchValueNested([checkin, type.includes('Signature') ? 'signatures' : 'images'], parsedImages.slice());
    };
    NewTripComponent.prototype.fileChange = function (type, img) {
        this.onImagesChange(type, type === 'loading' ? 'loadingCheckin' : 'unloadingCheckin', [img]);
    };
    NewTripComponent.prototype.fileUploading = function (uploading) {
        this.uploading = uploading;
    };
    NewTripComponent.prototype.newTripFormPatchValue = function (key, value) {
        var _a;
        this.newTripForm.patchValue((_a = {},
            _a[key] = value,
            _a));
    };
    NewTripComponent.prototype.newTripFormPatchValueNested = function (keys, value) {
        if (keys && keys.length > 0 && value) {
            var objToPatch = {};
            keys.reduce(function (prev, curr, i) {
                return (prev[curr] = i + i === keys.length ? value : {});
            }, objToPatch);
            this.newTripForm.patchValue(objToPatch);
        }
    };
    NewTripComponent.prototype.newTripFormSetRequiredFields = function (jobEvent) {
        var _this = this;
        this.fieldsToCheck.forEach(function (field) {
            _this.newTripFormValidatorsSwitch('loadingCheckin', field, jobEvent.job.checkinOptions[field + "Config"] === 'required');
        });
        this.fieldsToCheck.forEach(function (field) {
            _this.newTripFormValidatorsSwitch('unloadingCheckin', field, jobEvent.job.checkoutOptions[field + "Config"] === 'required');
        });
        this.updateFormValueAndValidity(this.newTripForm);
    };
    NewTripComponent.prototype.updateFormValueAndValidity = function (group) {
        var _this = this;
        Object.keys(group.controls).forEach(function (key) {
            var abstractControl = group.controls[key];
            if (abstractControl instanceof forms_1.FormGroup ||
                abstractControl instanceof forms_1.FormArray) {
                _this.updateFormValueAndValidity(abstractControl);
            }
            else {
                abstractControl.updateValueAndValidity();
            }
        });
    };
    NewTripComponent.prototype.newTripFormSetValidators = function (field, validators) {
        this.newTripForm.get(field).setValidators(validators);
    };
    NewTripComponent.prototype.newTripFormClearValidators = function (field) {
        this.newTripForm.get(field).clearValidators();
    };
    NewTripComponent.prototype.newTripFormValidatorsSwitch = function (parent, key, required) {
        switch (key) {
            case 'activeTracking':
                if (required) {
                    this.newTripFormSetValidators([parent, 'date'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                    this.newTripFormSetValidators([parent, 'time'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'date']);
                    this.newTripFormClearValidators([parent, 'time']);
                }
                break;
            case 'ticketImage':
                if (required) {
                    this.newTripFormSetValidators([parent, 'images'], [forms_1.Validators.required]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'images']);
                }
                break;
            case 'signatureImage':
                if (required) {
                    this.newTripFormSetValidators([parent, 'signatures'], [forms_1.Validators.required]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'signatures']);
                }
                break;
            case 'ticketNumber':
                if (required) {
                    this.newTripFormSetValidators([parent, 'ticketNumber'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'ticketNumber']);
                }
                break;
            case 'weight':
                if (required) {
                    this.newTripFormSetValidators([parent, 'weight'], [forms_1.Validators.required, forms_1.Validators.min(0)]);
                }
                else {
                    this.newTripFormClearValidators([parent, 'weight']);
                }
                break;
            default:
                break;
        }
    };
    NewTripComponent.prototype.setRequiredImages = function (jobEvent) {
        this.requiredImages = {
            loadingImages: jobEvent.job.checkinOptions.ticketImageConfig === 'required',
            unloadingImages: jobEvent.job.checkoutOptions.ticketImageConfig === 'required',
            loadingSignature: jobEvent.job.checkinOptions.signatureImageConfig === 'required',
            unloadingSignature: jobEvent.job.checkoutOptions.signatureImageConfig === 'required',
        };
    };
    // Start time: Required, always
    // Loading Checkin, optional overall, date/time must be later than start time and earlier than unloading date/time and end time
    // Unloading Checkin, optional overall, date/time must be later than start time and loading checkin date/time, and earlier than end time
    // End time: Optional, must be later than start time, loading / unloading date/time.
    NewTripComponent.prototype.initForm = function () {
        this.newTripForm = this.fb.group({
            job: [this.job, forms_1.Validators.required],
            jobEvent: [this.jobEvent, forms_1.Validators.required],
            driver: [this.driver, forms_1.Validators.required],
            truck: [this.truck, forms_1.Validators.required],
            carrier: [this.carrier, forms_1.Validators.required],
            start: this.fb.group({
                date: [this.trip.startDate, [forms_1.Validators.required]],
                time: [this.trip.startTime, [forms_1.Validators.required]],
            }),
            end: this.fb.group({
                date: [this.trip.endDate, []],
                time: [this.trip.endTime, []],
            }),
            loadingCheckin: this.fb.group({
                ticketNumber: [this.trip.loadingCheckin.ticketNumber],
                weight: [this.trip.loadingCheckin.weight],
                date: [this.trip.loadingCheckin.date],
                time: [this.trip.loadingCheckin.time],
                images: [null],
                signatures: [null],
            }),
            unloadingCheckin: this.fb.group({
                ticketNumber: [this.trip.unloadingCheckin.ticketNumber],
                weight: [this.trip.unloadingCheckin.weight],
                date: [this.trip.unloadingCheckin.date],
                time: [this.trip.unloadingCheckin.time],
                images: [null],
                signatures: [null],
            }),
        }, { validators: trip_time_validators_1.globalTripTimeValidator });
    };
    NewTripComponent.prototype.resetForm = function () {
        if (this.skipResetFormOnJobChange) {
            this.skipResetFormOnJobChange = false;
        }
        else {
            this.jobEvent = null;
            this.jobEventDate = [];
        }
        this.carrier = null;
        this.driver = null;
        this.truck = null;
        this.newTripForm.reset();
    };
    Object.defineProperty(NewTripComponent.prototype, "startDate", {
        get: function () {
            return this.newTripForm.get(['start', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripComponent.prototype, "endDate", {
        get: function () {
            return this.newTripForm.get(['end', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripComponent.prototype, "loadingCheckingDate", {
        get: function () {
            return this.newTripForm.get(['loadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripComponent.prototype, "unloadingCheckingDate", {
        get: function () {
            return this.newTripForm.get(['unloadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripComponent.prototype, "loadingCheckingTime", {
        get: function () {
            return this.newTripForm.get(['loadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTripComponent.prototype, "unloadingCheckingTime", {
        get: function () {
            return this.newTripForm.get(['unloadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    return NewTripComponent;
}());
exports.NewTripComponent = NewTripComponent;
