import { SurchargeTemplate } from './surcharge-template';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class SurchargeTemplateSerializer {
  fromJson(json: any): SurchargeTemplate {
    json = camelcaseKeysDeep(json);
    const surchargeTemplate = new SurchargeTemplate();
    if (!json) { return surchargeTemplate; }

    surchargeTemplate.id = json.id;
    surchargeTemplate.title = json.title;
    surchargeTemplate.createdAt = json.createdAt;
    surchargeTemplate.description = json.description;
    surchargeTemplate.amount = json.amount;
    if (parseFloat(json.amount) >= 0) {
      surchargeTemplate.positive = true;
    }
    if (json.kind) {
      surchargeTemplate.kind = json.kind;

      if (json.kind === 'invoice') {
        surchargeTemplate.kindTitle = 'Billing';
      } else if (json.kind === 'scaleticket') {
        surchargeTemplate.kindTitle = 'Scale Ticket';
      }
    }

    return surchargeTemplate;
  }

  toJson(surchargeTemplate: SurchargeTemplate): any {
    let json = {
      id: surchargeTemplate.id,
      title: surchargeTemplate.title,
      createdAt: surchargeTemplate.createdAt,
      description: surchargeTemplate.description,
      amount: surchargeTemplate.amount,
      kind: surchargeTemplate.kind
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}
