"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var unit_service_1 = require("../../units/unit.service");
var rate_type_service_1 = require("../../rate-types/rate-type.service");
var billing_rate_service_1 = require("./billing-rate.service");
var BillingRatesComponent = /** @class */ (function () {
    function BillingRatesComponent(translationService) {
        var _this = this;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'select' },
            { key: 'code', title: this.translationService.instant('Code'), sortable: true },
            { key: 'description', title: this.translationService.instant('Description') },
            { key: 'rateType', title: this.translationService.instant('Rate Type') },
            { key: 'unit', title: this.translationService.instant('Unit') },
            { key: 'rate', title: this.translationService.instant('Rate') },
            { key: 'actions' }
        ];
        this.displayedColumns = ['select', 'code', 'description', 'actions'];
        this.availableFilters = [
            // new FilterOption({
            //   key: 'rate_group', title: 'Rate Types', filterType: 'select',
            //   multiple: true, service: RateTypeService
            // }),
            new filter_option_1.FilterOption({
                key: 'rate_type', title: this.translationService.instant('Rate Types'),
                filterType: 'autocomplete', multiple: true, service: rate_type_service_1.RateTypeService
            }),
            new filter_option_1.FilterOption({
                key: 'unit', title: this.translationService.instant('Units'),
                filterType: 'select', multiple: true, service: unit_service_1.UnitService
            }),
        ];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.tabs = [
            { id: 'primary', name: this.translationService.instant('Primary Rates') },
            { id: 'secondary', name: this.translationService.instant('Secondary Rates') }
        ];
        this.errors = [];
        this.tableConfig = {
            service: billing_rate_service_1.BillingRateService,
            query: { rate_group: 'primary' },
            collectionTitle: this.translationService.instant('Rates'),
            noResultsText: this.translationService.instant('a rate'),
            newRecordRoute: ['/billing-rates/new'],
            sortBy: 'code',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
            ],
            newRecordModal: function () { _this.openNewRate(); }
        };
    }
    BillingRatesComponent.prototype.ngOnInit = function () { };
    BillingRatesComponent.prototype.tabChanged = function (tab) {
        var selectedTab = this.tabs[tab.index];
        if (selectedTab) {
            this.tableConfig.query = Object.assign(this.tableConfig.query, { rate_group: selectedTab.id });
        }
    };
    BillingRatesComponent.prototype.openNewRate = function () {
        //
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    BillingRatesComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return BillingRatesComponent;
}());
exports.BillingRatesComponent = BillingRatesComponent;
