import { Pipe, PipeTransform } from '@angular/core';
import { PunchCard } from '../../punch-cards/punch-card';
import { JobEventShare } from '../../job-event-shares/job-event-share';

@Pipe({
  name: 'getPunchCardsByStatusAndSharePipe',
  pure: true,
})
export class GetPunchCardsByStatusAndSharePipe implements PipeTransform {
  transform(
    punchCards: PunchCard[],
    share: JobEventShare,
    status: string,
    shares: JobEventShare[]
  ): number {
    if (punchCards && punchCards.length && status) {
      if (share) {
        if (status === 'unapproved')
          return this.getUnapprovedPunchCards(punchCards, share);
        return this.getPunchCardsByStatus(punchCards, share, status);
      } else if (shares && shares.length) {
        return shares
          .map((share) =>
            status === 'unapproved'
              ? this.getUnapprovedPunchCards(punchCards, share)
              : this.getPunchCardsByStatus(punchCards, share, status)
          )
          .reduce((a, b) => a + b, 0);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getPunchCardsByStatus = (
    punchCards: PunchCard[],
    share: JobEventShare,
    status: string
  ): number =>
    punchCards
      .map((punchCard) =>
        punchCard && punchCard.job && punchCard.job.project
          ? punchCard.job.id === share.jobId &&
            punchCard.job.project.ownerOrganization === share.organizationId &&
            punchCard.latestDecisionStatus === status
          : false
      )
      .filter(Boolean).length;

  getUnapprovedPunchCards = (
    punchCards: PunchCard[],
    share: JobEventShare
  ): number =>
    punchCards
      .map((punchCard) =>
        punchCard && punchCard.job && punchCard.job.project
          ? punchCard.job.id === share.jobId &&
            punchCard.job.project.ownerOrganization === share.organizationId &&
            punchCard.latestDecisionStatus !== 'approved'
          : false
      )
      .filter(Boolean).length;
}
