"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var moment = require("moment");
var trip_service_1 = require("./trip.service");
var edit_trip_dialog_component_1 = require("./edit-trip-dialog/edit-trip-dialog.component");
var view_tickets_dialog_component_1 = require("../checkins/view-tickets-dialog.component");
var TripDetailComponent = /** @class */ (function () {
    function TripDetailComponent(tripService, dialog) {
        this.tripService = tripService;
        this.dialog = dialog;
        this.trips = [];
        this.errors = [];
        this.actionDropdownOptions = [
            { name: 'Edit', button: true }
        ];
        this.editTripCallback = function (e) {
            // Update trip data
        };
        this.viewTicketsCallback = function (e) {
            // Update Ticket Status Icon
        };
    }
    TripDetailComponent.prototype.ngOnInit = function () { };
    TripDetailComponent.prototype.openTickets = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            var dialog = _this.dialog.open(view_tickets_dialog_component_1.ViewTicketsDialogComponent, {
                width: '722px',
                data: { trip: _trip }
            });
            dialog.componentInstance.callback = _this.viewTicketsCallback;
        }, function (err) { return console.error(err); });
    };
    TripDetailComponent.prototype.setSelectedOption = function (data, trip) {
        switch (data.name) {
            case 'Edit':
                this.editTrip(trip);
                break;
        }
    };
    TripDetailComponent.prototype.editTrip = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            var dialog = _this.dialog.open(edit_trip_dialog_component_1.EditTripDialogComponent, {
                width: '430px'
            });
            dialog.componentInstance.tripId = trip.id;
            dialog.componentInstance.callback = _this.editTripCallback;
        }, function (err) { return console.error(err); });
    };
    TripDetailComponent.prototype.formattedDuration = function (startTime) {
        var duration = moment.duration(moment().diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    return TripDetailComponent;
}());
exports.TripDetailComponent = TripDetailComponent;
