import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthenticationService } from '../../shared';
import { Device } from '../../shared/device';
import {
  AVAILABLE_COLUMNS,
  DISPLAYED_COLUMNS,
  AVAILABLE_FILTERS,
} from './table-constants';
import { clone } from 'lodash';
import moment = require('moment');
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { JobEventShareService } from '../../job-event-shares/job-event-share.service';
import { TableConfig } from '../../shared/fancy-table/table.types';

@Component({
  selector: 'hauler-shares',
  templateUrl: './hauler-shares.component.html',
  styleUrls: ['./hauler-shares.component.scss'],
})
export class HaulerSharesComponent implements OnInit, OnChanges {
  device: Device = new Device();
  search = '';
  loading = true;
  canCreateJobs = true;
  hasAllDriversEnabled = false;
  sortBy: string;
  sortAsc = true;

  @Input() availableColumns = [...AVAILABLE_COLUMNS(this.translateService)];
  @Input() displayedColumns = [...DISPLAYED_COLUMNS];
  @Input() availableFilters = [...AVAILABLE_FILTERS()];
  @Input() appliedFilters = [];
  @Input() jobEventDate: Date;

  haulerSharesTableConfig = {
    hasHeader: true,
    pageSize: 25,
    service: JobEventShareService,
    preferenceKey: 'HaulerSharesComponent-JobEventSharesService',
    preferencesEnabled: true,
    query: {},
    filterQuery: true,
    collectionTitle: this.translateService.instant('Shares'),
    hasNoResultsAction: false,
    customHeight: true,
    automaticallyHidePagination: false,
  };
  @ViewChild('haulerSharesTable', { static: false })
  haulerSharesTable: FancyTableComponent;
  @ViewChild('columnToggle', { static: false }) columnToggle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.canCreateJobs = this.authenticationService.canCreateJobs();
    this.hasAllDriversEnabled =
      this.authenticationService.hasAllDriversEnabled();

    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
      this.search = params['search'] || '';
      if (params['date']) {
        this.jobEventDate = moment(params['date'], 'YYYYMMDD').toDate();
      }
    });

    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop(),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    let jobEventDateStart = new Date();
    let jobEventDateEnd = clone(jobEventDateStart);

    if (changes['jobEventDate']) {
      const jobEventDate = changes['jobEventDate'].currentValue;
      if (jobEventDate) {
        jobEventDateStart = new Date(this.jobEventDate);
        jobEventDateEnd = clone(jobEventDateStart);
      }
    } else if (this.jobEventDate) {
      jobEventDateStart = new Date(this.jobEventDate);
      jobEventDateEnd = clone(jobEventDateStart);
    }

    jobEventDateStart.setHours(0, 0, 0, 0);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    this.haulerSharesTableConfig['query'] = {
      exclude_pending: 'True',
      jobevent__shift1_start__gte: jobEventDateStart.toISOString(),
      jobevent__shift1_start__lte: jobEventDateEnd.toISOString(),
    };
    if (this.authenticationService.hasFavoriteTags()) {
      this.haulerSharesTableConfig['query']['user_tags'] = 'True';
    }
    if (this.haulerSharesTable) {
      this.haulerSharesTable.config = <TableConfig>{
        ...this.haulerSharesTableConfig,
      };
    }
  }

  getRecords() {
    let startDate = this.jobEventDate ? clone(this.jobEventDate) : new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    this.haulerSharesTable.query = {
      ...this.haulerSharesTable.query,
      exclude_pending: 'True',
      jobevent__shift1_start__gte: startDate.toISOString(),
      jobevent__shift1_start__lte: endDate.toISOString(),
    };
  }

  onDateChanged(dates: Date[]): void {
    this.jobEventDate = dates[0];
    const queryParams: Params = Object.assign(
      {},
      this.route.snapshot.queryParams
    );
    const date = moment(this.jobEventDate).format('YYYYMMDD');
    queryParams['date'] = date;
    queryParams['search'] = this.search;
    this.router.navigate([], { queryParams: queryParams });
    this.getRecords();
  }

  onSearchClear() {
    this.search = '';
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }
}
