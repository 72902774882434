<div class="app-content tickets-container">
  <my-toolbar title="{{ 'Tickets' | translate }}"></my-toolbar>
  <notification *ngFor="let error of errors" translate>{{error}}</notification>

  <action-bar>
    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filters.length}">
        <i class="icon-filter"></i> {{ 'Filters' | translate }}
      </button>
    </div>
    <div class="notice" *ngIf="ticket">
      <notification level="info" translate>
        Ticket #{{ ticket && ticket.number }} has been voided!
      </notification>
    </div>
  </action-bar>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table #ticketTable [(availableColumns)]="availableColumns" 
                                [(displayedColumns)]="displayedColumns"
                                [(availableFilters)]="availableFilters" 
                                [(filters)]="appliedFilters" 
                                [(search)]="search"
                                [config]="ticketTableConfig"
                                (onClickAction)="clickAction($event)"
                                (columnChange)="columnsChanged($event)"
                                (onMenuAction)="menuAction($event)">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value date" *ngSwitchCase="'date'">
              <div class="data"> {{ row.deliveryDatetime | date:'M/d/yy h:mm a'}}</div>
              <div class="status" *ngIf="row.status">
                  <div class="{{ row.statusLabel }}">{{ row.status }}</div>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'number'">
              <div class="data">{{ row.number || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'truck'">
              <div class="data">{{ row.truck || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'customer'">
              <div class="data">{{ row.customer || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'purchase-order'">
              <div class="data">{{ row.purchaseOrder || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'location'">
              <div class="data">{{ row.location || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'product'">
              <div class="data">{{ row.product || '&nbsp;' }}</div>
            </div>
            <div class="value net" *ngSwitchCase="'net'">
              <div class="data">{{ row.net | number }}</div><br>
              <div class="label" [ngPlural]="row.net || 0">
                <ng-template ngPluralCase="=0">{{ row.netWeightUnit }}s</ng-template>
                <ng-template ngPluralCase="=1">{{ row.netWeightUnit }}</ng-template>
                <ng-template ngPluralCase="other">{{ row.netWeightUnit }}s</ng-template>
              </div>
            </div>
            <div class="value currency" *ngSwitchCase="'net-price'">
              <div class="data">{{ row.netPrice | currency:'USD':'symbol':'1.2-2' }}</div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
            <div class="title" translate>Tickets</div>
          </div>
        <div applied-filter-content>
          <applied-filters #appliedFiltersDisplay [(filters)]="appliedFilters" 
                            [(search)]="search" 
                            (filtersChange)="filtersModified($event)">
          </applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns" 
                       [(displayedColumns)]="displayedColumns">
        </column-toggle>
        <filters-panel [(availableFilters)]="availableFilters" 
                       [(appliedFilters)]="appliedFilters" 
                       [(search)]="search" 
                       (appliedFiltersChange)="filtersModified($event)">
        </filters-panel>
        <action-menu icon="icon-more" [disabled]="ticketTable && (!ticketTable.allSelected && ticketTable.selection.selected.length === 0)">
            <ng-container *ngFor="let option of multipleActionDropdownOptions">
              <a *ngIf="option.link" mat-menu-item [routerLink]="option.url">{{ option.name }}</a>
              <a *ngIf="!option.link" mat-menu-item (click)="setSelectedAction(option)">{{ option.name }}</a>
            </ng-container>
          </action-menu>
      </div>
    </div>
  </div>
  <!-- fancy table : ends here -->
</div>
