import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, omit, remove, difference, get, find as _find } from 'lodash';

import { ExpenseService } from  './expense.service';
import { ExpenseFiltersDialogComponent } from './expense-filters-dialog.component';
import { ExpensePayTypeDialogComponent } from './expense-pay-type-dialog.component';
import { parseErrors } from '../shared/api.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';

@Component({
  selector: 'ruckit-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  providers: [ExpenseService]
})
export class ExpensesComponent implements OnInit, OnDestroy {
  expenses: any = [];
  loading = true;
  errors = [];
  expensesReq;
  search = '';
  sortBy;
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  selectedExpense;
  confirmDialog: MatDialogRef<any>;
  paymentTypeDialog: MatDialogRef<any>;
  count = 0;
  selectedCount = {
    draft: 0,
    paid: 0
  };
  allSelected = {
    draft: false,
    paid: false
  };
  selectedExpenses = {
    draft: [],
    paid: []
  };
  excludeExpenses = {
    draft: [],
    paid: []
  };
  state = 'draft';
  dropdownOptions = {
    draft: [
      { name: 'Export', button: true }
    ],
    paid: [
      { name: 'Export', button: true },
      { name: 'Download', button: true}
    ]
  };
  menuOptions = [
    { name: 'Edit', action: 'edit', link: true, external: false },
    { name: 'Mark as Paid', action: 'mark_as_paid', link: false, external: false },
    { name: 'Remove', action: 'remove', link: false, external: false },
    { name: 'View', action: 'view', link: true, external: true },
    { name: 'Unlock', action: 'unlock', link: false, external: false },
    { name: 'Download', action: 'download', link: true, external: true },
    { name: 'Export', action: 'export', link: false, external: false },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private expenseService: ExpenseService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.queryParams.forEach((params: Params) => {
      this.loading = true;
      this.search = params['search'] || '';

      this.sortBy = params.sortBy ? params.sortBy : 'created_at';
      this.sortAsc = params.sortAsc ? (params.sortAsc === 'true') : false;
      this.getExpenses();
    });

    this.route.params.subscribe((params: Params) => {
      this.state = params['state'];
      this.getExpenses();
    });
  }

  ngOnDestroy() {
    if (this.expensesReq) { this.expensesReq.unsubscribe(); }
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.expenseService.getNext();
      if (o) {
        this.loading = true;
        o.subscribe(
          expenses => {
            this.expenses = this.expenses.concat(expenses);
            this.loading = false;
          },
          err => this.errors = err,
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getExpenses(query = {}, append = false): void {
    if (!append) {
      this.expenses = [];
    }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return {...acc, ...filter.query};
    }, {});
    delete filters['undefined'];

    if (this.expensesReq) { this.expensesReq.unsubscribe(); }

    this.expensesReq = this.expenseService.getExpenses({
      ordering: order,
      search: this.search,
      sent: this.state === 'draft' ? 'False' : 'True',
      ...omit(filters, 'jobDay'),
      ...query
    }).subscribe(
      expenses => {
        if (append) {
          this.expenses = this.expenses.concat(expenses);
        } else {
          this.expenses = expenses;
        }
        this.count = this.expenseService.count;
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortBy: this.sortBy,
        sortAsc: this.sortAsc
      }
    });
    this.loading = true;
    this.getExpenses({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getExpenses({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  changeSearch(term?: string) {
    this.search = term || '';
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    queryParams['search'] = this.search;
    this.router.navigate(['expenses', 'filter', this.state], { queryParams: queryParams });

    this.getExpenses();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters() {
    const dialog = this.dialog.open(ExpenseFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);

    let startDate = get(_find(this.filters, {key: 'startDate'}), 'value');
    if (startDate && startDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.startDate = startDate;
    }
    let endDate = get(_find(this.filters, {key: 'endDate'}), 'value');
    if (endDate && endDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.endDate = endDate;
    }
    dialog.componentInstance.model.job = get(_find(this.filters, {key: 'job'}), 'value');
    dialog.componentInstance.model.customer = get(_find(this.filters, {key: 'customer'}), 'value');

    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'customer_organization',
      job: 'job',
      sent: 'sent',
      startDate: 'jobday_start__gte',
      endDate: 'jobday_end__lte'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
          let filterValue = value.toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let displayKey = key;
      let hidden = false;
      if (key === 'startDate') { hidden = true; }
      if (key === 'endDate') { hidden = true; }
      if (key === 'jobDay') { displayKey = 'Job Day'; }
      let filter = {
        key: displayKey,
        value: displayValue || value,
        query: query,
        hidden: hidden
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });

    this.filters = difference(this.filters, falseyFilters);
    this.getExpenses();
  }

  removeFilter(filter) {
    if (filter.key === 'Job Day') {
      remove(this.filters, _find(this.filters, { key: 'Start Date' }));
      remove(this.filters, _find(this.filters, { key: 'End Date' }));
    }
    remove(this.filters, filter);
    this.getExpenses();
  }

  menuAction(action: string, expense): void {
    switch (action) {
      case 'mark_as_paid':
        this.sendExpense(expense);
        break;
      case 'remove':
        this.removeExpense(expense);
        break;
      case 'export':
        this.createExport([expense.id]);
        break;
      case 'unlock':
        this.unlockExpense(expense);
        break;
    }
  }

  sendExpense(expense) {
    if (!expense.paymentType) {
      this.capturePaymentType(expense);
    } else {
      this.loading = true;
      this.expenseService.send(expense).subscribe(() => {
        expense.sent = true;
        this.loading = false;
        this.getExpenses();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  removeExpense(expense) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Remove Expense?',
      body: 'This expense will be deleted and cannot be recovered.',
      close: 'Cancel',
      accept: 'Remove'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.expenseService.remove(expense).subscribe((res) => {
          this.getExpenses();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  unlockExpense(expense) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unlock Expense?',
      body: 'This expense will be unlocked and can be modified.',
      close: 'Cancel',
      accept: 'Unlock'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.expenseService.unlock(expense).subscribe((res) => {
          this.getExpenses();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  capturePaymentType(expense) {
    this.paymentTypeDialog = this.dialog.open(ExpensePayTypeDialogComponent, {
      width: '430px'
    });
    this.paymentTypeDialog.componentInstance.expense = expense;
    this.paymentTypeDialog.componentInstance.callback = res => {
      expense.paymentType = res.paymentType;
      this.sendExpense(expense);
    };
  }

  createExport(expenses, section = null) {
    let scope = {};
    let params = new HttpParams();

    if (expenses && expenses.length) {
      Object.assign(scope, { expenses: expenses });
    } else if (this.allSelected) {
      Object.assign(scope, { excludeExpenses: this.excludeExpenses[section] });
      Object.keys(this.filters).map(key => {
        if (this.filters[key]) {
          let query = this.filters[key]['query'];
          Object.keys(query).map(queryKey => {
            params = params.set(queryKey, query[queryKey]);
          });
        }
      });
      params = params.set('search', this.search);
      params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
    }

    this.dialog.open(ExportDialogComponent, {
      width: '430px',
      data: <ExportDialogData>{
        type: 'expenses',
        buttonText: 'Export Data to CSV',
        params: params,
        scope: scope,
        service: this.expenseService
      }
    });
  }

  createDownload(expenses, section = null) {
    let scope = {};
    let params = new HttpParams();

    if (expenses && expenses.length) {
      Object.assign(scope, { expenses: expenses });
    } else if (this.allSelected) {
      Object.assign(scope, { excludeExpenses: this.excludeExpenses[section] });
      Object.keys(this.filters).map(key => {
        if (this.filters[key]) {
          let query = this.filters[key]['query'];
          Object.keys(query).map(queryKey => {
            params = params.set(queryKey, query[queryKey]);
          });
        }
      });
      params = params.set('search', this.search);
      params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
    }
    this.expenseService.downloadPdfs(scope, params).subscribe(() => {
      this.loading = false;
      this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
        width: '430px',
        height: '250px'
      });
      this.confirmDialog.componentInstance.attributes = {
        title: 'Download Link Sent',
        body: 'Your zip file with the selected expense data is on its way to your inbox',
        close: 'Close',
        accept: null
      };
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selector(event, section = 'draft', expense = null) {
    if (expense) {
      if (!event.target.checked) {
        expense.selected = false;
        pull(this.selectedExpenses[section], expense.id);
        if (this.allSelected) {
          this.excludeExpenses[section].push(expense.id);
          this.selectedCount[section] = (this.count - this.excludeExpenses[section].length);
        } else {
          this.selectedCount[section] = this.selectedExpenses[section].length;
        }
      } else {
        expense.selected = true;
        if (this.allSelected[section]) {
          pull(this.excludeExpenses[section], expense.id);
          this.selectedCount[section] = (this.count - this.excludeExpenses[section].length);
        } else {
          this.selectedExpenses[section].push(expense.id);
          this.selectedCount[section] = this.selectedExpenses[section].length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected[section] = false;
        this.expenses.forEach((_expense) => { _expense.selected = false; });
        this.selectedCount[section] = 0;
      } else {
        this.allSelected[section] = true;
        this.selectedCount[section] = (this.count - this.excludeExpenses[section].length);
      }
      this.selectedExpenses[section] = [];
      this.excludeExpenses[section] = [];
    }
  }

  setSelectedAction(option, section) {
    switch (option.name) {
      case 'Export':
        this.createExport(this.selectedExpenses[section], section);
        break;
      case 'Download':
        this.createDownload(this.selectedExpenses[section], section);
        break;

    }
  }
}
