"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var truck_type_1 = require("./truck-type");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TruckTypeSerializer = /** @class */ (function () {
    function TruckTypeSerializer() {
    }
    TruckTypeSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var truckType = new truck_type_1.TruckType();
        if (!json) {
            return truckType;
        }
        truckType.id = json.id;
        truckType.name = json.name;
        truckType.averageTonnage = json.averageTonnage;
        truckType.isFavorite = json.isFavorite;
        truckType.active = json.active;
        if (truckType.isFavorite) {
            truckType.icon = 'star';
        }
        return truckType;
    };
    TruckTypeSerializer.prototype.toJson = function (truckType) {
        var json = {
            id: truckType.id,
            name: truckType.name,
            averageTonnage: truckType.averageTonnage,
            isFavorite: truckType.isFavorite,
            active: truckType.active
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return TruckTypeSerializer;
}());
exports.TruckTypeSerializer = TruckTypeSerializer;
