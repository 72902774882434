import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { JobEventFilter } from './job-event.filter';
import { ResourceService } from '../shared/resource.service';
import { HttpClient } from '@angular/common/http';
import { JobEventFilterSerializer } from './job-event.filter.serializer';

@Injectable()
export class JobEventFilterService extends ResourceService<JobEventFilter> {
  baseUrl = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http, 'jobevents/', new JobEventFilterSerializer());
  }
}
