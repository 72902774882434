"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var billing_rate_1 = require("./billing-rate");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var BillingRateSerializer = /** @class */ (function () {
    function BillingRateSerializer() {
    }
    BillingRateSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var billingRate = new billing_rate_1.BillingRate();
        if (!json) {
            return billingRate;
        }
        billingRate.id = json.id;
        billingRate.name = json.name;
        billingRate.rateGroup = json.rateGroup;
        billingRate.code = json.code;
        billingRate.description = json.description;
        billingRate.rateType = json.rateType;
        billingRate.unit = json.unit;
        billingRate.rate = json.rate;
        return billingRate;
    };
    BillingRateSerializer.prototype.toJson = function (billingRate) {
        var json = {
            id: billingRate.id,
            name: billingRate.name,
            rateGroup: billingRate.rateGroup,
            code: billingRate.code,
            description: billingRate.description,
            rateType: billingRate.rateType,
            unit: billingRate.unit,
            rate: billingRate.rate
        };
        return decamelizeKeysDeep(json);
    };
    return BillingRateSerializer;
}());
exports.BillingRateSerializer = BillingRateSerializer;
