import { Resource } from "../../shared/resource";

export class NotificationsReport extends Resource {
  name: string;
  lastPeriod: number;
  currentPeriod: number;
  percentageChange: number;
  ruleColor: string;
  textColor: string;

  constructor() {
    super();
    this.textColor = 'rgba(0, 0, 0, .87)';
  }
}

export interface ThresholdConfig {
  value: number;
  color?: string;
}

export interface TimeFrame {
  name: string;
  id: string;
  selected?: any;
  lastPeriodColumnName?: string;
  currentPeriodColumnName?: string;
}