"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var resource_service_1 = require("../shared/resource.service");
var http_1 = require("@angular/common/http");
var job_event_filter_serializer_1 = require("./job-event.filter.serializer");
var JobEventFilterService = /** @class */ (function (_super) {
    __extends(JobEventFilterService, _super);
    function JobEventFilterService(http) {
        var _this = _super.call(this, http, 'jobevents/', new job_event_filter_serializer_1.JobEventFilterSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        return _this;
    }
    return JobEventFilterService;
}(resource_service_1.ResourceService));
exports.JobEventFilterService = JobEventFilterService;
