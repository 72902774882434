"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var key_codes_enum_1 = require("../enums/key-codes.enum");
var SearchComponent = /** @class */ (function () {
    function SearchComponent() {
        this.autoSearchDelay = 1000;
        this.searchTermChange = new core_1.EventEmitter();
        this.allSubscriptionsToUnsubscribe = [];
        this.searchOpen = false;
    }
    SearchComponent.prototype.ngOnInit = function () {
        this.updatePlaceholder();
    };
    SearchComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.allSubscriptionsToUnsubscribe.push(this.searchInput.valueChanges
            .pipe(operators_1.debounceTime(this.autoSearchDelay), operators_1.distinctUntilChanged())
            .subscribe(function (value) {
            if (value === '') {
                _this.searchTermChange.emit(value.trim());
            }
            else {
                _this.changeTerm(value);
            }
        }));
    };
    SearchComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    SearchComponent.prototype.onKeyPress = function (event) {
        if (event.code === key_codes_enum_1.KeyCodes.Enter) {
            this.changeTerm(this.searchTerm);
            event.preventDefault();
        }
    };
    SearchComponent.prototype.openSearchBar = function (bool) {
        this.searchOpen = bool;
        if (bool) {
            this.placeholder = 'Search';
            this.searchInput.nativeElement.focus();
        }
        else {
            this.placeholder = null;
        }
    };
    SearchComponent.prototype.onBlur = function () {
        this.changeTerm(this.searchTerm);
    };
    SearchComponent.prototype.updatePlaceholder = function () {
        this.placeholder = (this.searchBarType === 'map') ? null : 'Search';
    };
    SearchComponent.prototype.changeTerm = function (value) {
        if (value && value.trim()) {
            this.searchTermChange.emit(value.trim());
        }
    };
    SearchComponent.prototype.clearTerm = function () {
        var previousSearchTerm = this.searchTerm;
        this.searchTerm = '';
        this.updatePlaceholder();
        if (previousSearchTerm && previousSearchTerm.trim() !== this.searchTerm) {
            this.searchTermChange.emit(this.searchTerm);
        }
    };
    return SearchComponent;
}());
exports.SearchComponent = SearchComponent;
