"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./billing-rates.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../shared/notification/notification.component.ngfactory");
var i3 = require("../../shared/notification/notification.component");
var i4 = require("@angular/common");
var i5 = require("../../shared/fancy-table/fancy-table.component.ngfactory");
var i6 = require("../../shared/fancy-table/fancy-table.component");
var i7 = require("@angular/router");
var i8 = require("@angular/material/dialog");
var i9 = require("ng2-dragula");
var i10 = require("../../preferences/preference.service");
var i11 = require("../../shared/authentication.service");
var i12 = require("../../shared/applied-filters/applied-filters.component.ngfactory");
var i13 = require("../../shared/applied-filters/applied-filters.component");
var i14 = require("../../shared/column-toggle/column-toggle.component.ngfactory");
var i15 = require("../../shared/column-toggle/column-toggle.component");
var i16 = require("@angular/forms");
var i17 = require("../../shared/filters-panel/filters-panel.component.ngfactory");
var i18 = require("../../shared/filters-panel/filters-panel.component");
var i19 = require("@angular/cdk/scrolling");
var i20 = require("../../../../node_modules/@angular/material/tabs/typings/index.ngfactory");
var i21 = require("@angular/material/tabs");
var i22 = require("../../shared/pipes/title-case.pipe");
var i23 = require("../../shared/toolbar/toolbar.component.ngfactory");
var i24 = require("../../shared/toolbar/toolbar.component");
var i25 = require("../../tags/tag.service");
var i26 = require("../../users/user.service");
var i27 = require("@ngx-translate/core");
var i28 = require("./billing-rates.component");
var styles_BillingRatesComponent = [i0.styles];
var RenderType_BillingRatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BillingRatesComponent, data: {} });
exports.RenderType_BillingRatesComponent = RenderType_BillingRatesComponent;
function View_BillingRatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(1, 245760, null, 0, i3.NotificationComponent, [], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_BillingRatesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.context.row.unit)); _ck(_v, 1, 0, currVal_0); }); }
function View_BillingRatesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), (_v.parent.context.row.rate || 0), "USD", "symbol")); _ck(_v, 1, 0, currVal_0); }); }
function View_BillingRatesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.value; _ck(_v, 1, 0, currVal_0); }); }
function View_BillingRatesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingRatesComponent_5)), i1.ɵdid(3, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingRatesComponent_6)), i1.ɵdid(5, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingRatesComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.column; _ck(_v, 1, 0, currVal_0); var currVal_1 = "unit"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "rate"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_BillingRatesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "actions-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "fancy-table", [], null, [[null, "availableColumnsChange"], [null, "displayedColumnsChange"], [null, "availableFiltersChange"], [null, "filtersChange"], [null, "searchChange"], [null, "columnChange"], ["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("availableColumnsChange" === en)) {
        var pd_1 = ((_co.availableColumns = $event) !== false);
        ad = (pd_1 && ad);
    } if (("displayedColumnsChange" === en)) {
        var pd_2 = ((_co.displayedColumns = $event) !== false);
        ad = (pd_2 && ad);
    } if (("availableFiltersChange" === en)) {
        var pd_3 = ((_co.availableFilters = $event) !== false);
        ad = (pd_3 && ad);
    } if (("filtersChange" === en)) {
        var pd_4 = ((_co.appliedFilters = $event) !== false);
        ad = (pd_4 && ad);
    } if (("searchChange" === en)) {
        var pd_5 = ((_co.search = $event) !== false);
        ad = (pd_5 && ad);
    } if (("columnChange" === en)) {
        var pd_6 = (_co.columnsChanged($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i5.View_FancyTableComponent_0, i5.RenderType_FancyTableComponent)), i1.ɵdid(2, 770048, null, 2, i6.FancyTableComponent, [i7.ActivatedRoute, i7.Router, i8.MatDialog, i1.Injector, i9.DragulaService, i10.PreferenceService, i11.AuthenticationService, i1.Renderer2], { availableColumns: [0, "availableColumns"], displayedColumns: [1, "displayedColumns"], filters: [2, "filters"], availableFilters: [3, "availableFilters"], search: [4, "search"], config: [5, "config"] }, { searchChange: "searchChange", filtersChange: "filtersChange", columnChange: "columnChange" }), i1.ɵqud(603979776, 6, { columnTemplates: 0 }), i1.ɵqud(603979776, 7, { detailTemplates: 0 }), (_l()(), i1.ɵand(0, [[6, 2], ["columnTemplates", 2]], 2, 0, null, View_BillingRatesComponent_4)), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "div", [["applied-filter-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "applied-filters", [], null, [[null, "filtersChange"], [null, "searchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filtersChange" === en)) {
        var pd_0 = ((_co.appliedFilters = $event) !== false);
        ad = (pd_0 && ad);
    } if (("searchChange" === en)) {
        var pd_1 = ((_co.search = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_AppliedFiltersComponent_0, i12.RenderType_AppliedFiltersComponent)), i1.ɵdid(8, 638976, null, 0, i13.AppliedFiltersComponent, [], { filters: [0, "filters"], search: [1, "search"] }, { searchChange: "searchChange", filtersChange: "filtersChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "column-toggle", [], null, [[null, "availableColumnsChange"], [null, "displayedColumnsChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("availableColumnsChange" === en)) {
        var pd_0 = ((_co.availableColumns = $event) !== false);
        ad = (pd_0 && ad);
    } if (("displayedColumnsChange" === en)) {
        var pd_1 = ((_co.displayedColumns = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_ColumnToggleComponent_0, i14.RenderType_ColumnToggleComponent)), i1.ɵdid(11, 638976, [[2, 4], ["columnToggle", 4]], 0, i15.ColumnToggleComponent, [i16.FormBuilder], { availableColumns: [0, "availableColumns"], displayedColumns: [1, "displayedColumns"] }, { availableColumnsChange: "availableColumnsChange", displayedColumnsChange: "displayedColumnsChange" }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "filters-panel", [], null, [[null, "availableFiltersChange"], [null, "appliedFiltersChange"], [null, "searchChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("availableFiltersChange" === en)) {
        var pd_0 = ((_co.availableFilters = $event) !== false);
        ad = (pd_0 && ad);
    } if (("appliedFiltersChange" === en)) {
        var pd_1 = ((_co.appliedFilters = $event) !== false);
        ad = (pd_1 && ad);
    } if (("searchChange" === en)) {
        var pd_2 = ((_co.search = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i17.View_FiltersPanelComponent_0, i17.RenderType_FiltersPanelComponent)), i1.ɵdid(13, 770048, null, 0, i18.FiltersPanelComponent, [i16.FormBuilder, i1.Injector, i1.NgZone, i19.ScrollDispatcher], { appliedFilters: [0, "appliedFilters"], availableFilters: [1, "availableFilters"], search: [2, "search"] }, { appliedFiltersChange: "appliedFiltersChange", availableFiltersChange: "availableFiltersChange", searchChange: "searchChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.availableColumns; var currVal_1 = _co.displayedColumns; var currVal_2 = _co.appliedFilters; var currVal_3 = _co.availableFilters; var currVal_4 = _co.search; var currVal_5 = _co.tableConfig; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.appliedFilters; var currVal_7 = _co.search; _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_8 = _co.availableColumns; var currVal_9 = _co.displayedColumns; _ck(_v, 11, 0, currVal_8, currVal_9); var currVal_10 = _co.appliedFilters; var currVal_11 = _co.availableFilters; var currVal_12 = _co.search; _ck(_v, 13, 0, currVal_10, currVal_11, currVal_12); }, null); }
function View_BillingRatesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-tab", [], null, null, null, i20.View_MatTab_0, i20.RenderType_MatTab)), i1.ɵdid(1, 770048, [[3, 4]], 2, i21.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[5, 2]], 0, 1, null, View_BillingRatesComponent_3)), i1.ɵdid(5, 16384, null, 0, i21.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BillingRatesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i22.TitleCasePipe, []), i1.ɵpid(0, i4.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { columnTemplate: 0 }), i1.ɵqud(671088640, 2, { columnToggle: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "my-toolbar", [["color", "#FFFFFF"], ["tagsBackgroundColor", "rgba(63, 83, 110, 0.15)"], ["textColor", "#3f536e"]], null, null, null, i23.View_ToolbarComponent_0, i23.RenderType_ToolbarComponent)), i1.ɵdid(6, 245760, null, 0, i24.ToolbarComponent, [i7.Router, i11.AuthenticationService, i25.TagService, i26.UserService], { title: [0, "title"], color: [1, "color"], textColor: [2, "textColor"], tagsBackgroundColor: [3, "tagsBackgroundColor"] }, null), i1.ɵpid(131072, i27.TranslatePipe, [i27.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingRatesComponent_1)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.tabChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_MatTabGroup_0, i20.RenderType_MatTabGroup)), i1.ɵdid(12, 3325952, null, 1, i21.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i21.MAT_TABS_CONFIG]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 3, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BillingRatesComponent_2)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Rate Sheets")), ""); var currVal_1 = "#FFFFFF"; var currVal_2 = "#3f536e"; var currVal_3 = "rgba(63, 83, 110, 0.15)"; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.errors; _ck(_v, 9, 0, currVal_4); var currVal_7 = _co.tabs; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var currVal_5 = i1.ɵnov(_v, 12).dynamicHeight; var currVal_6 = (i1.ɵnov(_v, 12).headerPosition === "below"); _ck(_v, 11, 0, currVal_5, currVal_6); }); }
exports.View_BillingRatesComponent_0 = View_BillingRatesComponent_0;
function View_BillingRatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "billing-rates", [], null, null, null, View_BillingRatesComponent_0, RenderType_BillingRatesComponent)), i1.ɵdid(1, 114688, null, 0, i28.BillingRatesComponent, [i27.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BillingRatesComponent_Host_0 = View_BillingRatesComponent_Host_0;
var BillingRatesComponentNgFactory = i1.ɵccf("billing-rates", i28.BillingRatesComponent, View_BillingRatesComponent_Host_0, { availableColumns: "availableColumns", displayedColumns: "displayedColumns", availableFilters: "availableFilters", appliedFilters: "appliedFilters", search: "search" }, { availableColumnsChange: "availableColumnsChange", displayedColumnsChange: "displayedColumnsChange", availableFiltersChange: "availableFiltersChange", appliedFiltersChange: "appliedFiltersChange", searchChange: "searchChange" }, []);
exports.BillingRatesComponentNgFactory = BillingRatesComponentNgFactory;
