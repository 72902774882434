import { of as observableOf, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import {
  HostListener, Component, OnInit, EventEmitter, Output, OnDestroy
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DecimalPipe } from '@angular/common';
import { reduce, cloneDeep, omit, find as _find } from 'lodash';

import { PurchaseOrderService } from '../purchase-orders/purchase-order.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { ConnectionService } from '../connections/connection.service';
import { ProductService } from '../products/product.service';
import { TicketService } from './ticket.service';
import { AuthenticationService } from '../shared/authentication.service';
import { ScaleService } from '../scales/scale.service';
import { TareWeightService } from '../tare-weights/tare-weight.service';
import { DriverService } from '../drivers/driver.service';
import { SurchargeTemplateService } from '../surcharge-templates/surcharge-template.service';
import { parseErrors } from '../shared/api.service';
import { CondensedTicket } from './condensed-ticket';
import { Scale } from '../scales/scale';
import { SurchargeSerializer } from '../surcharges/surcharge.serializer';
import { SurchargeService } from '../surcharges/surcharge.service';
import { PriceService } from '../prices/price.service';
import { VoidTicketDialogComponent } from './void-ticket-dialog.component';

import {
  ScaleitConfirmDialogComponent,
  ScaleitConfirmDialogResult
} from '../scaleit/shared';
import { CondensedTicketSerializer } from './condensed-ticket.serializer';
import { CondensedTicketService } from './condensed-ticket.service';

const deepEqual = require('deep-equal');

@Component({
  selector: 'ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
  providers: [
    PurchaseOrderService, ProductService, TicketService, ScaleService,
    DecimalPipe, ConnectionService, DriverService, TareWeightService,
    SurchargeTemplateService, SurchargeService, PriceService,
    AuthenticationService
  ]
})
export class TicketFormComponent implements OnInit, OnDestroy {
  @Output() onTicketVoided = new EventEmitter<CondensedTicket>();
  @Output() onTicketSaved = new EventEmitter<CondensedTicket>();
  @Output() onTicketFinished = new EventEmitter<any>();
  @Output() onNotification = new EventEmitter<any>();
  errors = [];
  notificationTimeout = 3000;
  ticketInOpenState = false;
  ticketInProgressState = false;
  ticketInFinishedHold = false;
  ticketInHoldState = false;
  currentTicket = null;
  originalTicket = null;
  onHold = false;
  licensePlate: string = null;
  unitPrice = null;
  customDriver = false;
  haulerName: string = null;
  discount: string = null;
  notes: string = null;
  formEnabled = false;
  voidButtonloading = false;
  finishButtonloading = false;
  deliveryDate: Date;
  selectedDriver = null;
  selectedCarrier = null;
  minLicensePlateLength = 1;
  searchStatusToSearch = true;
  searchStatusSearching = false;
  searchStatusNotFound = false;
  customers = [];
  selectedCustomer = null;
  customerDropdownConfig = {
    searchable: true,
    loadingOptions: false,
    nameProperty: 'name'
  };
  customerSearchTimeout;
  customerDropdownEnabled = false;
  driversReq: Subscription;
  customersReq: Subscription;
  purchaseOrdersReq: Subscription;
  surchargeTemplatesReq: Subscription;
  tareWeightsReq: Subscription;
  scalesReq: Subscription;
  productsReq: Subscription;
  drivers = [];
  driverDropdownConfig = {
    searchable: true,
    loadingOptions: false,
    nameProperty: 'name'
  };
  driverSearchTimeout;
  driverDropdownEnabled = false;
  purchaseOrders = [];
  selectedPurchaseOrder = null;
  purchaseOrderDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  purchaseOrderSearchTimeout;
  purchaseOrderDropdownEnabled = false;
  products = [];
  selectedProduct = null;
  productDropdownConfig = {
    searchable: true,
    loadingOptions: false
  };
  paymentTypeNone = { id: null, name: 'None' };
  paymentTypes = [
    { id: 'cash', name: 'Cash' },
    { id: 'check', name: 'Check' },
    { id: 'card', name: 'Card' },
    this.paymentTypeNone
  ];
  storedWeights = [];
  tareScales: any[];
  tareDropdownConfig = {
    width: 288,
    loadingScales: true,
    group: true,
    groupBy: 'weightType'
  };
  grossScales: Scale[];
  grossDropdownConfig = {
    width: 288,
    loadingScales: true,
    group: true,
    groupBy: 'weightType'
  };
  tareScale: Scale = null;
  tareWeight: string = null;
  grossScale: Scale = null;
  grossWeight: string = null;
  correctionWeight: number = null;
  defaultWeightUnit = 'lbs';
  netWeightUnit = 'lbs';
  netWeight = 0;
  surchargeTemplates = [];
  surchargeTemplateDropdownConfig = {
    searchable: true,
    loadingOptions: false,
    nameProperty: 'title'
  };
  priceList = null;
  voidDialog: MatDialogRef<VoidTicketDialogComponent>;
  confirmDialog: MatDialogRef<any>;
  searchChanged: Subject<string[]> = new Subject<string[]>();
  backgroundColor = 'rgba(234, 238, 241, 1)';
  voidTicketCallback = (ticket) => {
    this.onTicketVoided.emit(ticket);
    this.resetEntireForm();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private purchaseOrderService: PurchaseOrderService,
    private productService: ProductService,
    private ticketService: TicketService,
    private condensedTicketService: CondensedTicketService,
    private scaleService: ScaleService,
    private connectionService: ConnectionService,
    private tareWeightService: TareWeightService,
    private driverService: DriverService,
    private surchargeTemplateService: SurchargeTemplateService,
    private surchargeService: SurchargeService,
    private authenticationService: AuthenticationService,
    private priceService: PriceService,
    public dialog: MatDialog,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    let seller = this.authenticationService.getOrganization();
    this.currentTicket = new CondensedTicketSerializer().fromJson({
      surcharges: [(new SurchargeSerializer).fromJson({})],
      sellerOrganization: seller
    });
    this.originalTicket = cloneDeep(this.currentTicket);
    this.setDefaultDate();
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(data => {
      this.dropdownSearch(data[0], data[1]);
    });
  }

  ngOnDestroy() {
    if (this.productsReq) { this.productsReq.unsubscribe(); }
    if (this.scalesReq) { this.scalesReq.unsubscribe(); }
    if (this.tareWeightsReq) { this.tareWeightsReq.unsubscribe(); }
    if (this.customersReq) { this.customersReq.unsubscribe(); }
    if (this.driversReq) { this.driversReq.unsubscribe(); }
    if (this.purchaseOrdersReq) { this.purchaseOrdersReq.unsubscribe(); }
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:unload', ['$event']) // for android devices.
  handleOnBeforeUnload($event) {
    if (this.hasUnsavedChanges && this.hasUnsavedChanges()) {
      // For IE and Firefox prior to version 4
      if ($event) {
        $event.returnValue = 'You have unsaved changes!';
      }
      // For Safari
      return 'You have unsaved changes!';
    }
  }

  setDefaultDate() {
    this.deliveryDate = new Date();
  }

  deliveryDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.deliveryDate = values[0];
      let date = new Date(this.deliveryDate);
      this.currentTicket.deliveryTime = date.toISOString();
      this.saveTicket();
    }
  }

  handleSearchOnEnter(target) {
    if (target) { target.blur(); }
  }

  setSearchStatusToSearch() {
    this.searchStatusToSearch = true;
    this.searchStatusSearching = false;
    this.searchStatusNotFound = false;
  }

  setSearchStatusSearching() {
    this.searchStatusToSearch = false;
    this.searchStatusSearching = true;
    this.searchStatusNotFound = false;
  }

  setSearchStatusNotFound() {
    this.searchStatusToSearch = false;
    this.searchStatusSearching = false;
    this.searchStatusNotFound = true;
  }

  getProducts(query: any = null, saveTicket = false, resetCustomPrice = false): void {
    if (this.productsReq) { this.productsReq.unsubscribe(); }
    this.productService.list({
      purchase_order: this.selectedPurchaseOrder && this.selectedPurchaseOrder.id,
      ...query
    }).subscribe(products => {
      this.products = products;

      if (this.products.length > 0) {
        if (this.products.length === 1 && !this.selectedProduct) {
          this.selectProduct(products[0], saveTicket, resetCustomPrice);
        } else if (this.selectedProduct) {
          this.selectProduct(this.selectedProduct, saveTicket, resetCustomPrice);
        }
      } else if (!this.selectedProduct) {
        this.selectedProduct = null;
      }
    }, err => {
      this.errors = parseErrors(err);
    });
    if (saveTicket) { this.saveTicket(); }
  }

  onScaleDropdownToggle(isOpen) {
    if (isOpen) { this.getScales(); }
  }

  // Todo: Add support for Deselection
  selectTareWeight(scale) {
    if (Object.keys(scale).includes('scale')) {
      this.tareScale = scale.scale;
    } else {
      this.tareScale = scale;
    }
    this.tareWeight = this.tareScale.weight.toString();
    this.defaultWeightUnit = scale.weightUnit;
    this.currentTicket.tareWeight = this.tareWeight;
    this.currentTicket.tareWeightScaleId = this.tareScale.id;
    this.currentTicket.tareWeightScale = this.tareScale;
    this.currentTicket.tareWeightTime = new Date().toISOString();
    this.currentTicket.tareUnit = this.defaultWeightUnit;
    this.saveTicket();
  }

  selectGrossWeight(scale) {
    this.grossScale = scale;
    this.grossWeight = scale.weight.toString();
    this.defaultWeightUnit = scale.weightUnit;
    this.currentTicket.grossWeight = this.grossWeight;
    this.currentTicket.grossWeightScaleId = this.grossScale.id;
    this.currentTicket.grossWeightScale = this.grossScale;
    this.currentTicket.grossWeightTime = new Date().toISOString();
    this.currentTicket.grossUnit = this.defaultWeightUnit;
    this.saveTicket();
  }

  getClient(id) {
    this.connectionService.get(id).subscribe(clients => {
      if (clients) {
        // this.setSelectedCustomer(clients[0]);
      }
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  resetEntireForm(): void {
    this.resetFormFields();

    this.setDefaultDate();

    this.selectedProduct = null;
    this.selectedDriver = null;
    this.customDriver = false;

    this.discount = null;
    this.notes = null;

    this.tareScale = null;
    this.tareWeight = null;
    this.grossScale = null;
    this.grossWeight = null;
    this.correctionWeight = null;

    this.netWeight = 0;
    this.netWeightUnit = this.defaultWeightUnit;
  }

  resetFormFields(): void {
    this.formEnabled = false;
    this.currentTicket = new CondensedTicketSerializer().fromJson({});
    this.originalTicket = cloneDeep(this.currentTicket);
    this.unitPrice = null;
    this.purchaseOrders = [];
    this.selectedPurchaseOrder = null;
    this.purchaseOrderDropdownEnabled = false;
    this.customers = [];
    this.selectedCustomer = null;
    this.customerDropdownEnabled = false;
    this.driverDropdownEnabled = false;
    this.licensePlate = '';
    this.haulerName = '';
    this.discount = this.hasNoValue(this.discount) ? '0' : this.discount;
    this.onHold = false;
  }

  validToFinish(): boolean {
    if (
      this.currentTicket.onHold ||
      !this.currentTicket.customerId ||
      !this.currentTicket.productId ||
      !this.tareScale ||
      !this.grossScale ||
      this.hasNoValue(this.currentTicket.tareWeight) ||
      this.hasNoValue(this.currentTicket.grossWeight)
    ) {
      return false;
    }
    return true;
  }

  hasNoValue(objToCheck: any) {
    switch (typeof objToCheck) {
      case 'string':
        return (
          objToCheck === undefined || objToCheck === null || objToCheck === ''
        );
      default:
        return objToCheck === undefined || objToCheck === null;
    }
  }

  hasValue(objToCheck: any) {
    return !this.hasNoValue(objToCheck);
  }

  newTicket() {
    if (this.hasUnsavedChanges()) {
      this.warnUnsavedChanges().subscribe(dialogResult => {
        switch (dialogResult) {
          case ScaleitConfirmDialogResult.No:
            this.resetEntireForm();
            break;
          case ScaleitConfirmDialogResult.Yes:
            this.saveTicket(true);
            break;
        }
      });
    } else {
      this.resetEntireForm();
    }
  }

  saveTicket(resetOnSuccess = false, objectOverrides = {}): Observable<boolean> {
    let currentTicket = cloneDeep(this.currentTicket);
    if (!currentTicket.customUnitPrice || currentTicket.customUnitPrice === '') {
      currentTicket.customUnitPrice = Number(currentTicket.unitPrice) < 0 ? 0 : currentTicket.unitPrice;
    }
    if (currentTicket.customUnitPrice === currentTicket.originalUnitPrice) {
      delete currentTicket.customUnitPrice;
    }
    let seller = this.authenticationService.getOrganization();
    currentTicket.seller = seller.id;
    currentTicket.sellerOrganization = seller.id;
    currentTicket.sellerId = seller.id;
    currentTicket.customerOrganization = currentTicket.customerId;
    currentTicket = Object.assign(currentTicket, objectOverrides);
    delete currentTicket.surcharges;

    return new Observable((observer) => {
      this.condensedTicketService.saveTicket(currentTicket).subscribe(ticket => {
        this.unitPrice = ticket.customUnitPrice || ticket.unitPrice;
        this.currentTicket = ticket;
        if (this.customDriver) {
          this.currentTicket.driverName = ticket.driver;
        }
        this.stubSurcharges();
        this.onTicketSaved.emit(this.currentTicket);
        if (this.currentTicket.statusText === 'On Hold') { this.currentTicket.onHold = true; }
        if (resetOnSuccess) {
          this.onTicketFinished.emit(resetOnSuccess);
          this.resetEntireForm();
        }
        observer.next(true);
      }, err => {
        this.errors = parseErrors(err);
        observer.next(false);
      });
    });
  }

  setUnitPrice() {
    if (this.unitPrice !== this.currentTicket.unitPrice) {
      this.currentTicket.customUnitPrice = this.unitPrice;
    } else {
      this.currentTicket.customUnitPrice = null;
    }
    this.saveTicket();
  }

  saveSurcharges(surcharges = null): void {
    if (!surcharges) {
      surcharges = this.currentTicket && this.currentTicket.surcharges;
    } else if (!Array.isArray(surcharges)) {
      surcharges = [surcharges];
    }

    surcharges.forEach(surcharge => {
      this.surchargeService.save(surcharge).subscribe(_surcharge => {
        surcharge = _surcharge;
      }, err => {
        this.errors = parseErrors(err);
      });
    });
  }

  finishTicket(): void {
    if (this.currentTicket) {
      if (parseFloat(this.currentTicket.net) !== 0.0) {
        this.saveTicket(true, { status: 'complete' });
      } else {
        this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
          width: '430px',
          height: '280px'
        });
        this.confirmDialog.componentInstance.attributes = {
          title: 'Warning',
          body: 'Net is 0. Are you sure you want to finish the ticket?',
          close: 'No',
          accept: 'Yes'
        };

        this.confirmDialog.afterClosed().subscribe(dialogResult => {
          if (dialogResult) { this.saveTicket(true, { status: 'complete' }); }
          this.confirmDialog = null;
        });
      }
    }
  }

  toggleHold(event): void {
    if (this.currentTicket) {
      if (this.currentTicket.statusText === 'On Hold') {
        this.saveTicket(false, { status: 'in-progress' });
      } else {
        this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
          width: '430px',
          height: '280px'
        });
        this.confirmDialog.componentInstance.attributes = {
          title: 'Warning',
          body: 'A ticket cannot be completed while it is on hold. Are you sure you want to put this ticket on hold?',
          close: 'No',
          accept: 'Yes'
        };

        this.confirmDialog.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            this.onHold = true;
            this.currentTicket.onHold = true;
            this.saveTicket(false, { status: 'on-hold' });
          } else {
            this.onHold = false;
            this.currentTicket.onHold = false;
          }
          this.confirmDialog = null;
        });
      }
    }
  }

  showNotification(notificationMessage, notificationLevel, notificationTimeout = this.notificationTimeout) {
    this.onNotification.emit({
      message: notificationMessage,
      level: notificationLevel,
      timeout: notificationTimeout
    });
  }

  hasUnsavedChanges() {
    let currentTicket = omit(this.currentTicket, ['productUnitOfMeasurement', 'surcharges']);
    let originalTicket = omit(this.originalTicket, ['productUnitOfMeasurement', 'surcharges']);
    const changedValues = reduce(originalTicket, function (result, value, key) {
      return deepEqual(value, currentTicket[key]) ?
        result : result.concat(key);
    }, []);
    console.log('changed values: ', changedValues);
    return deepEqual(originalTicket, currentTicket) ? false : true;
  }

  warnUnsavedChanges() {
    this.confirmDialog = this.dialog.open(ScaleitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Warning',
      body: 'Would you like to save the changes made to this ticket?',
      yes: 'Yes',
      no: 'No'
    };

    let observable = this.confirmDialog.afterClosed();
    this.confirmDialog = null;
    return observable;
  }

  autoFillFormWithTicket(ticket, saveTicket = false) {
    this.resetEntireForm();
    this.parseCondensedTicket(ticket);
    if (saveTicket) { this.saveTicket(); }
    this.formEnabled = true;

    this.getCustomers();
    this.getDrivers();
    this.getScales();
    this.getSurchargeTemplates();
    this.getProducts();
  }

  parseCondensedTicket(ticket) {
    this.currentTicket = ticket;
    if (this.currentTicket.deliveryDatetime) {
      let date = new Date(this.currentTicket.deliveryDatetime);
      this.deliveryDate = date;
    }

    this.notes = ticket.notes;
    if (ticket.tareWeightScaleId) {
      this.tareScale = new Scale({
        id: ticket.tareWeightScaleId, name: ticket.tareWeightScale,
        weightUnit: ticket.tareWeightUnit
      });
      this.tareWeight = ticket.tareWeight;
    }
    if (ticket.grossWeightScaleId) {
      this.grossScale = new Scale({
        id: ticket.grossWeightScaleId, name: ticket.grossWeightScale,
        weightUnit: ticket.grossWeightUnit
      });
      this.grossWeight = ticket.grossWeight;
    }
    this.correctionWeight = ticket.correctionWeight;
    this.netWeight = ticket.net || 0;
    this.netWeightUnit = ticket.netWeightUnit || this.defaultWeightUnit;
    this.selectedPurchaseOrder = {
      id: ticket.purchaseOrderId, name: ticket.purchaseOrder || '-'
    };
    this.selectedProduct = {
      id: ticket.productId, name: ticket.product || ''
    };
    this.selectCustomer({
      id: ticket.customerId, name: ticket.customer
    }, false);
    if (ticket.driver && ticket.driver.length && !ticket.driverId) {
      this.customDriver = true;
      this.currentTicket.driverName = ticket.driver;
    } else {
      this.customDriver = false;
      this.selectDriver({ id: ticket.driverId, name: ticket.driver }, false);
    }
    this.selectCarrier({
      id: ticket.truckCarrierId, name: ticket.carrier
    }, false);
    this.onHold = this.currentTicket.onHold;
    this.originalTicket = cloneDeep(this.currentTicket);
    this.unitPrice = this.currentTicket.customUnitPrice || this.currentTicket.unitPrice;
  }

  warnExistingTicket() {
    this.confirmDialog = this.dialog.open(ScaleitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Warning',
      body: 'An incomplete ticket for that truck already exists. Would you like to open and continue editing it?',
      yes: 'Yes',
      no: 'No'
    };

    let observable = this.confirmDialog.afterClosed();
    this.confirmDialog = null;
    return observable;
  }

  checkUnsavedForNavigation(): Observable<boolean> {
    if (this.hasUnsavedChanges()) {
      return new Observable(observer => {
        this.warnUnsavedChanges().subscribe(dialogResult => {
          switch (dialogResult) {
            case ScaleitConfirmDialogResult.No:
              this.resetEntireForm();
              observer.next(true);
              break;
            case ScaleitConfirmDialogResult.Yes:
              this.saveTicket(true);
              break;
            default:
              observer.next(false);
          }
        });
      });
    } else {
      return observableOf(true);
    }
  }

  getScales(query = {}): void {
    if (this.scalesReq) { this.scalesReq.unsubscribe(); }
    this.scalesReq = this.scaleService.getScales({
      ordering: 'name',
      ...query
    }).subscribe(scales => {
      this.tareScales = scales;
      this.grossScales = scales;
      this.grossDropdownConfig.loadingScales = false;
      this.grossDropdownConfig = cloneDeep(this.grossDropdownConfig);
      if (this.storedWeights && this.storedWeights.length > 0) {
        this.tareScales = this.tareScales.concat(this.storedWeights);
        this.tareDropdownConfig.loadingScales = false;
        this.tareDropdownConfig = cloneDeep(this.tareDropdownConfig);
      } else {
        this.getTareWeights();
      }
    }, err => {
      this.grossDropdownConfig.loadingScales = false;
      this.tareDropdownConfig.loadingScales = false;
      this.grossDropdownConfig = cloneDeep(this.grossDropdownConfig);
      this.tareDropdownConfig = cloneDeep(this.tareDropdownConfig);
      this.errors = parseErrors(err);
    });
  }

  getTareWeights(query = {}): void {
    if (this.tareWeightsReq) { this.tareWeightsReq.unsubscribe(); }
    this.tareWeightsReq = this.tareWeightService.getTareWeights({
      ordering: 'name',
      truck: this.currentTicket && this.currentTicket.truckId,
      ...query
    }).subscribe(tareWeights => {
      this.storedWeights = tareWeights;
      this.tareScales = this.tareScales.concat(tareWeights);
      this.tareDropdownConfig.loadingScales = false;
      this.tareDropdownConfig = cloneDeep(this.tareDropdownConfig);
    }, err => {
      this.tareDropdownConfig.loadingScales = false;
      this.tareDropdownConfig = cloneDeep(this.tareDropdownConfig);
      this.errors = parseErrors(err);
    });
  }

  getCustomers(query = {}): void {
    if (this.customersReq) { this.customersReq.unsubscribe(); }
    this.customersReq = this.connectionService.list({
      status: 'active', // filter customer list based on the status(active)
      ...query
    }).subscribe(connections => {
      if (connections.length > 0) {
        let organizations = connections.map(connection => {
          let organization = connection.organization;
          organization.posPriceList = connection.posPriceList;
          organization['paymentType'] = connection.posPaymentType;
          return organization;
        });

        this.customerDropdownEnabled = true;
        this.customers = organizations;
        if (this.customers.length > 0) {
          if (this.customers.length === 1 && !this.selectedCustomer) {
            this.selectedCustomer = this.customers[0];
            this.currentTicket.paymentType = this.customers[0].paymentType;
          } else if (this.selectedCustomer) {
            let _customer = _find(this.customers, { id: this.selectedCustomer.id });
            this.selectedCustomer = _customer;
            this.currentTicket.paymentType = _customer.paymentType;
          }
        }
      } else {
        if (this.currentTicket && this.currentTicket.customerId) {
          this.selectedCustomer = {
            id: this.currentTicket.customerId,
            name: this.currentTicket.customer
          };
          this.customers = [this.selectedCustomer];
        } else {
          this.customers = [{ id: null, name: 'No Results Found' }];
        }
      }
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  getDrivers(query = {}): void {
    this.drivers = [{ id: null, name: 'New Driver', button: true }];
    if (this.currentTicket && this.currentTicket.truckCarrierId) {
      let organization = this.authenticationService.getOrganization();
      if (organization && organization.id !== this.currentTicket.truckCarrierId) {
        query = Object.assign(query, {
          organization: this.currentTicket && this.currentTicket.truckCarrierId
        });
      }
    }
    if (this.driversReq) { this.driversReq.unsubscribe(); }
    this.driversReq = this.driverService.list({
      ...query
    }).subscribe(drivers => {
      this.drivers = this.drivers.concat(drivers);
      this.driverDropdownEnabled = true;
      if (this.drivers.length > 0) {
        if (this.drivers.length === 1 && !this.selectedDriver) {
          this.selectedDriver = drivers[0];
        }
      } else {
        if (this.currentTicket) {
          this.drivers = [this.currentTicket.driverId];
          this.selectedDriver = {
            id: this.currentTicket.driverId, name: this.currentTicket.driver
          };
        } else {
          this.drivers = [{ id: null, name: 'No Results Found' }];
        }
      }
    }, err => {
      this.errors = parseErrors(err);
      this.resetFormFields();
    });
  }

  getPurchaseOrders(query = {}): void {
    if (this.purchaseOrdersReq) { this.purchaseOrdersReq.unsubscribe(); }
    this.purchaseOrdersReq = this.purchaseOrderService.list({
      customer_organization: this.selectedCustomer && this.selectedCustomer.id,
      ...query
    }).subscribe(purchaseOrders => {
      this.purchaseOrders = [{ id: null, name: '-' }].concat(purchaseOrders);
      this.purchaseOrderDropdownEnabled = true;
      if (this.purchaseOrders.length > 0) {
        if (this.selectedPurchaseOrder) {
          let _purchaseOrder = _find(this.purchaseOrders, { id: this.selectedPurchaseOrder.id });
          if (_purchaseOrder) {
            this.selectPurchaseOrder(_purchaseOrder, false);
          }
        }
      } else {
        this.purchaseOrderDropdownEnabled = false;
      }
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  getSurchargeTemplates(query = {}) {
    if (this.surchargeTemplatesReq) { this.surchargeTemplatesReq.unsubscribe(); }
    this.surchargeTemplatesReq = this.surchargeTemplateService.list({
      kind: 'scaleticket',
      ...query
    }).subscribe(surchargeTemplates => {
      this.surchargeTemplates = surchargeTemplates;
      this.stubSurcharges();
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  stubSurcharges(): void {
    if (!this.currentTicket.surcharges || !this.currentTicket.surcharges.length) {
      this.currentTicket.surcharges = [(new SurchargeSerializer).fromJson({
        scaleticket: this.currentTicket.id, kind: 'scaleticket'
      })];
    }
  }

  selectCustomer(customer, saveTicket = true, resetCustomPrice = false): void {
    if (this.currentTicket && customer && customer.id) {
      if (this.currentTicket.customerId !== customer.id) {
        this.currentTicket.purchaseOrderId = null;
        this.selectedPurchaseOrder = { id: null, name: '-' };
      }
      this.selectedCustomer = customer;
      this.currentTicket.customerId = this.selectedCustomer.id;
      this.currentTicket.customer = this.selectedCustomer.name;
      if (!this.currentTicket.paymentType && customer.customerPaymentType) {
        this.currentTicket.paymentType = customer.customerPaymentType;
      }
      if (resetCustomPrice) {
        this.currentTicket.customUnitPrice = null;
        this.unitPrice = this.currentTicket.unitPrice;
      }
      this.getPurchaseOrders();
      if (saveTicket) { this.saveTicket(); }
    }
  }

  selectDriver(driver, saveTicket = true): void {
    if (this.currentTicket && driver && driver.name && driver.name.length) {
      if (driver.name === 'New Driver') {
        this.customDriver = true;
        this.currentTicket.driver = null;
        this.currentTicket.driverId = null;
        this.currentTicket.driverImage = null;
        this.currentTicket.driverPhone = null;
        this.currentTicket.driverName = null;
        this.selectedDriver = null;
        saveTicket = false;
      } else {
        this.selectedDriver = driver;
        this.currentTicket.driver = this.selectedDriver.name;
        this.currentTicket.driverId = this.selectedDriver.id;
        this.currentTicket.driverImage = this.selectedDriver.driverImage;
        this.currentTicket.driverPhone = this.selectedDriver.driverPhone;
        this.currentTicket.driverName = null;
      }
      if (saveTicket) { this.saveTicket(); }
    }
  }

  selectCarrier(carrier, saveTicket = true): void {
    if (this.currentTicket) {
      this.selectedCarrier = carrier;
      this.currentTicket.carrierId = this.selectedCarrier.id;
      this.currentTicket.carrier = this.selectedCarrier.name;
      this.haulerName = this.selectedCarrier && this.selectedCarrier.name || '';
      if (saveTicket) { this.saveTicket(); }
    }
  }

  selectPurchaseOrder(purchaseOrder, saveTicket = true, resetCustomPrice = false): void {
    if (this.currentTicket && purchaseOrder && purchaseOrder.id) {
      this.selectedPurchaseOrder = purchaseOrder;
      this.currentTicket.purchaseOrder = this.selectedPurchaseOrder.id;
      if (this.selectedPurchaseOrder) {
        this.currentTicket.purchaseOrderId = this.selectedPurchaseOrder.id;
      }
      if (this.selectedPurchaseOrder && this.selectedPurchaseOrder.product) {
        this.selectedProduct = {
          id: this.selectedPurchaseOrder.product.id,
          name: this.selectedPurchaseOrder.product.name
        };
        this.currentTicket.product = this.selectedProduct.name;
        this.currentTicket.productId = this.selectedProduct.id;
      }
      this.getProducts({
        purchase_order: this.selectedPurchaseOrder && this.selectedPurchaseOrder.id
      }, false, resetCustomPrice);
    } else if (purchaseOrder && purchaseOrder.name === '-') {
      this.selectedPurchaseOrder = { id: null, name: '-' };
      this.currentTicket.purchaseOrderId = null;
      this.getProducts();
    } else {
      this.getProducts();
    }
    if (resetCustomPrice) {
      this.currentTicket.customUnitPrice = null;
      this.unitPrice = this.currentTicket.unitPrice;
    }
    if (saveTicket) { this.saveTicket(); }
  }

  selectProduct(product, saveTicket = true, resetCustomPrice = false): void {
    if (this.currentTicket && product && product.id) {
      this.selectedProduct = { id: product.id, name: product.name };
      this.currentTicket.productId = this.selectedProduct.id;
      this.currentTicket.productUnitOfMeasurement = this.selectedProduct.unitOfMeasurement;
      if (resetCustomPrice) {
        this.currentTicket.customUnitPrice = null;
        this.unitPrice = this.currentTicket.unitPrice;
      }
      if (saveTicket) { this.saveTicket(); }
    }
  }

  selectPaymentType(paymentType): void {
    if (this.currentTicket) {
      this.currentTicket.paymentType = paymentType;
      this.saveTicket();
    }
  }

  selectSurchargeTemplate(surchargeTemplate, index): void {
    if (this.currentTicket) {
      if (surchargeTemplate.id === 'new_surcharge') {
        //
      } else {
        let surcharge = this.currentTicket.surcharges[index];
        if (surcharge) {
          surcharge.title = surchargeTemplate.title;
          surcharge.amount = surchargeTemplate.amount;
          surcharge.scaleticket = this.currentTicket.id;
          surcharge.kind = 'scaleticket';
          surcharge.description = surchargeTemplate.description;
          this.saveSurcharges([(new SurchargeSerializer().fromJson(surcharge))]);
        }
      }
      this.saveTicket();
    }
  }

  delaySearch(term: string, type: string): void {
    this.searchChanged.next([term, type]);
  }

  dropdownSearch(term: string, type: string): void {
    switch (type) {
      case 'customer':
        this.getCustomers({ search: term });
        break;
      case 'driver':
        this.getDrivers({ search: term });
        break;
      case 'product':
        this.getProducts({ search: term });
        break;
      case 'purchaseOrder':
        this.getPurchaseOrders({ search: term });
        break;
      case 'surchargeTemplate':
        this.getSurchargeTemplates({ search: term });
        break;
    }
  }

  dropdownNextPage(e, type: string): void {
    let config;
    let service;
    let options;

    switch (type) {
      case 'customer':
        config = this.customerDropdownConfig;
        service = this.connectionService;
        options = this.customers;
        break;
      case 'driver':
        config = this.driverDropdownConfig;
        service = this.driverService;
        options = this.drivers;
        break;
      case 'product':
        config = this.productDropdownConfig;
        service = this.productService;
        options = this.products;
        break;
      case 'purchaseOrder':
        config = this.purchaseOrderDropdownConfig;
        service = this.purchaseOrderService;
        options = this.purchaseOrders;
        break;
      case 'surchargeTemplate':
        config = this.surchargeTemplateDropdownConfig;
        service = this.surchargeTemplateService;
        options = this.surchargeTemplates;
        break;
    }

    if (!config.loadingOptions) {
      let o;
      switch (type) {
        case 'customer':
          o = service.listNext();
          break;
        case 'driver':
          o = service.listNext();
          break;
        case 'product':
          o = service.listNext();
          break;
        case 'surchargeTemplate':
          o = service.listNext();
          break;
        default:
          o = service.getNext();
          break;
      }

      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'customer':
              this.customers = this.customers.concat(results);
              break;
            case 'driver':
              this.drivers = this.drivers.concat(results);
              break;
            case 'product':
              this.products = this.products.concat(results);
              break;
            case 'purchaseOrder':
              this.purchaseOrders = this.purchaseOrders.concat(results);
              break;
            case 'surchargeTemplate':
              this.surchargeTemplates = this.surchargeTemplates.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  deleteSurcharge(surcharge, index): void {
    if (surcharge.id) {
      this.surchargeService.remove(surcharge).subscribe(() => {
        this.currentTicket.surcharges.pop(index);
      }, (err) => {
        this.errors = parseErrors(err);
      });
    } else {
      this.currentTicket.surcharges.pop(index);
    }
  }

  clearSurcharge(surcharge, index): void {
    if (surcharge.id) {
      this.surchargeService.remove(surcharge).subscribe(() => {
        this.currentTicket.surcharges[index] = (new SurchargeSerializer).fromJson({
          scaleticket: this.currentTicket.id, kind: 'scaleticket'
        });
      }, (err) => {
        this.errors = parseErrors(err);
      });
    } else {
      this.currentTicket.surcharges[index] = (new SurchargeSerializer).fromJson({
        scaleticket: this.currentTicket.id, kind: 'scaleticket'
      });
    }
  }

  addSurcharge(): void {
    this.currentTicket.surcharges.push((new SurchargeSerializer).fromJson({
      scaleticket: this.currentTicket.id, kind: 'scaleticket'
    }));
  }

  voidTicket() {
    this.voidDialog = this.dialog.open(VoidTicketDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.voidDialog.componentInstance.ticket = this.currentTicket;
    this.voidDialog.componentInstance.callback = this.voidTicketCallback;
    this.voidDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        //
      }
      this.voidDialog = null;
    });
  }
}
