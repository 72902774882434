"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var job_event_service_1 = require("../job-events/job-event.service");
var api_service_1 = require("../shared/api.service");
var EditSLOrderIDDialogComponent = /** @class */ (function () {
    function EditSLOrderIDDialogComponent(dialogRef, data, jobEventService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.jobEventService = jobEventService;
        this.errors = [];
        this.loading = false;
        this.jobEvent = this.data && this.data.jobEvent;
        this.jobEventId = this.data && this.data.jobEventId;
    }
    EditSLOrderIDDialogComponent.prototype.ngOnInit = function () {
        if (this.jobEventId) {
            this.getJobEvent();
        }
    };
    EditSLOrderIDDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
    };
    EditSLOrderIDDialogComponent.prototype.getJobEvent = function () {
        var _this = this;
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        this.loading = true;
        this.jobEventReq = this.jobEventService.get(this.jobEventId).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditSLOrderIDDialogComponent.prototype.submit = function () {
        var _this = this;
        this.jobEventService.save({
            id: this.jobEvent.id, slorder_id: this.jobEvent.slorderId
        }).subscribe(function (result) {
            _this.loading = false;
            _this.dialogRef.close();
        }, function (err) {
            _this.errors = ['SLOrder ID could not be updated.'];
            _this.errors.push(api_service_1.parseErrors(err));
            _this.loading = false;
        });
    };
    return EditSLOrderIDDialogComponent;
}());
exports.EditSLOrderIDDialogComponent = EditSLOrderIDDialogComponent;
