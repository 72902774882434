import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AdvancedBillingRoutingModule } from './advanced-billing-routing.module';
import { BillingRatesComponent } from './billing-rates/billing-rates.component';
import { RatesTableComponent } from './rates-table/rates-table.component';

import { BillingRateService } from './billing-rates/billing-rate.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AdvancedBillingRoutingModule
  ],
  declarations: [BillingRatesComponent, RatesTableComponent],
  providers: [BillingRateService]
})
export class AdvancedBillingModule { }
