import { UnitOfMeasure } from './unit-of-measure';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class UnitOfMeasureSerializer {
  /**
   * @param  {any} json
   * @returns {UnitOfMeasure} UnitsOfMeasure
   */
  fromJson(json: any): UnitOfMeasure {
    json = camelcaseKeysDeep(json);
    const unitOfMeasure = new UnitOfMeasure();
    if (!json) {
      return unitOfMeasure;
    }

    unitOfMeasure.id = json.id;
    unitOfMeasure.active = json.active;
    unitOfMeasure.name = json.name;
    unitOfMeasure.isFavorite = json.isFavorite;
    unitOfMeasure.key = json.key;

    return unitOfMeasure;
  }

  /**
   * @param  {UnitOfMeasure} UnitOfMeasure
   * @returns any
   */
  toJson(unitOfMeasure: UnitOfMeasure): any {
    let json = {
      id: unitOfMeasure.id,
      active: unitOfMeasure.active,
      name: unitOfMeasure.name,
      isFavorite: unitOfMeasure.isFavorite,
      key: unitOfMeasure.key,
    };
    return decamelizeKeysDeep(json);
  }
}
