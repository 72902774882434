<div class="mobile-dispatch">
  <div class="header-container">
    <my-toolbar class="mobile" title="{{ 'Dispatching' | translate }}" color="#154b80"></my-toolbar>
    <date-range-picker [selectedDates]="selectedDates" [config]="{mobile: true}"
                       (dateChanged)="onDateChanged($event)"></date-range-picker>
  </div>
  <div class="content-container">
    <mobile-dispatch-jobs *ngIf="!loading && jobEvents && jobEvents.length" [selectedDates]="selectedDates"
                          [jobEvents]="jobEvents" (getJobEvents)="getJobEvents()"></mobile-dispatch-jobs>

    <my-loader *ngIf="loading" class="mobile-loader full-height"></my-loader>
    <no-results class="mobile" itemName="{{ 'Results' | translate }}"
                  [styles]="{height: 'calc(100% - 130px)'}"
                  [results]="jobEvents && jobEvents.length"
                  customClasses="mobile"
                  customText="{{ 'We weren\'t able to find any available results.' | translate }}"
                  [hasAddText]="false" [loading]="loading" [hasAction]="false"></no-results>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>
</div>
