import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CyberTripsRoutingModule } from './cyber-trips-routing.module';
import { CyberTripsComponent } from './cyber-trips.component';
import { CyberTripService } from './cyber-trip.service';
import { CyberTripFiltersDialogComponent } from './cyber-trip-filters-dialog.component';
import { CyberTripFilterService } from './cyber-trip.filter.service';

@NgModule({
  imports: [CommonModule, SharedModule, CyberTripsRoutingModule],
  declarations: [CyberTripsComponent, CyberTripFiltersDialogComponent],
  providers: [CyberTripService, CyberTripFilterService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [CyberTripFiltersDialogComponent]
})
export class CyberTripsModule {}
