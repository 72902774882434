import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { TripDecision } from './trip-decision';
import { ResourceService } from '../shared/resource.service';
import { TripDecisionSerializer } from './trip-decision.serializer';

@Injectable()
export class TripDecisionService extends ResourceService<TripDecision> {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(http: HttpClient) {
    super(http, 'tripdecisions/', new TripDecisionSerializer());
  }
}
