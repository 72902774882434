"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var mock_invoice_1 = require("./mock-invoice");
var invoice_1 = require("./invoice");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var InvoiceService = /** @class */ (function () {
    function InvoiceService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.count = 0;
    }
    InvoiceService.prototype.getMockInvoice = function () { return mock_invoice_1.INVOICE; };
    InvoiceService.prototype.getInvoice = function (invoiceId) {
        var invoiceUrl = this.baseUrl + 'invoices/' + invoiceId + '/';
        return this.http.get(invoiceUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.getPublicInvoice = function (invoiceId) {
        var invoiceUrl = this.baseUrl + 'invoices/' + invoiceId + '/';
        return this.http.get(invoiceUrl).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.getMockInvoices = function (count) {
        var invoices = [];
        for (var i = 1; i <= count; i++) {
            var invoice = Object.assign({}, mock_invoice_1.INVOICE);
            invoices.push(invoice);
        }
        return invoices;
    };
    InvoiceService.prototype.getInvoices = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var invoicesUrl = this.baseUrl + 'invoices/';
        return this.http.get(invoicesUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.save = function (invoice) {
        var _this = this;
        invoice = lodash_1.clone(invoice);
        var invoiceUrl = this.baseUrl + 'invoices/';
        if (typeof invoice.job === 'object') {
            invoice.job = invoice.job && invoice.job.id;
        }
        if (typeof invoice.ownerOrganization === 'object') {
            invoice.ownerOrganization = invoice.ownerOrganization.id;
        }
        if (typeof invoice.customerOrganization === 'object') {
            invoice.customerOrganization = invoice.customerOrganization.id;
        }
        if (typeof invoice.billFromCcEmails === 'string') {
            invoice.billFromCcEmails = invoice.billFromCcEmails.split(',');
        }
        if (typeof invoice.billToCcEmails === 'string') {
            invoice.billToCcEmails = invoice.billToCcEmails.split(',');
        }
        invoice = decamelizeKeysDeep(invoice);
        if (!invoice.id) {
            var params = lodash_1.clone(invoice);
            var filterParams = void 0;
            if (invoice.filters && invoice.filters.length) {
                filterParams = '?' + invoice.filters;
            }
            else {
                filterParams = '';
            }
            return this.http.post(invoiceUrl + filterParams, params, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return this.http.put(invoiceUrl + invoice.id + '/', invoice, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
    };
    InvoiceService.prototype.remove = function (invoice) {
        invoice = decamelizeKeysDeep(invoice);
        var invoiceUrl = this.baseUrl + 'invoices/';
        return this.http.delete(invoiceUrl + invoice.id + '/', { headers: api_service_1.requestHeaders() });
    };
    InvoiceService.prototype.unlock = function (invoice) {
        invoice = decamelizeKeysDeep(invoice);
        var invoiceUrl = this.baseUrl + 'invoices/';
        return this.http.put(invoiceUrl + invoice.id + '/', { sent: false }, { headers: api_service_1.requestHeaders() });
    };
    InvoiceService.prototype.send = function (invoice) {
        invoice = decamelizeKeysDeep(invoice);
        var invoiceUrl = this.baseUrl + 'invoices/';
        return this.http.post(invoiceUrl + invoice.id + '/send/', {}, { headers: api_service_1.requestHeaders() });
    };
    InvoiceService.prototype.preview = function (invoiceId) {
        var invoiceUrl = this.baseUrl + 'invoices/';
        return this.http.get(invoiceUrl + invoiceId + '/preview/', { headers: api_service_1.requestHeaders() });
    };
    InvoiceService.prototype.addToInvoice = function (invoice, type) {
        var _this = this;
        if (type === void 0) { type = 'trips'; }
        invoice = lodash_1.clone(invoice);
        var params, filterParams;
        var invoiceUrl = this.baseUrl + 'invoices/' + invoice.id + '/add/';
        if (invoice.filters && invoice.filters.length) {
            filterParams = '?' + invoice.filters;
            if (type === 'trips') {
                params = { trips: [] };
            }
            else if (type === 'punchcards') {
                params = { punchcards: [] };
            }
        }
        else {
            filterParams = '';
            if (type === 'trips') {
                params = { trips: invoice.trips || [] };
            }
            else if (type === 'punchcards') {
                params = { punchcards: invoice.punchcards || [] };
            }
        }
        if (type === 'trips') {
            Object.assign(params, { exclude_trips: invoice.excludeTrips || invoice.exclude_trips || [] });
        }
        else if (type === 'punchcards') {
            Object.assign(params, { exclude_punchcards: invoice.excludePunchCards || invoice.exclude_punch_cards || [] });
        }
        invoice = decamelizeKeysDeep(invoice);
        return this.http.post(invoiceUrl + filterParams, params, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.removeFromInvoice = function (invoice, type) {
        var _this = this;
        if (type === void 0) { type = 'trips'; }
        invoice = decamelizeKeysDeep(lodash_1.clone(invoice));
        var params, filterParams;
        var invoiceUrl = this.baseUrl + 'invoices/' + invoice.id + '/remove/';
        if (invoice.filters && invoice.filters.length) {
            filterParams = '?' + invoice.filters;
            if (type === 'trips') {
                params = { trips: [] };
            }
            else if (type === 'punchcards') {
                params = { punchcards: [] };
            }
            else if (type === 'surcharges') {
                params = { surcharges: [] };
            }
        }
        else {
            filterParams = '';
            if (type === 'trips') {
                params = { trips: invoice.trips || [] };
            }
            else if (type === 'punchcards') {
                params = { punchcards: invoice.punchcards || [] };
            }
            else if (type === 'surcharges') {
                params = { surcharges: invoice.surcharges || [] };
            }
        }
        if (type === 'trips') {
            Object.assign(params, { exclude_trips: invoice.excludeTrips || invoice.exclude_trips || [] });
        }
        else if (type === 'punchcards') {
            Object.assign(params, { exclude_punchcards: invoice.excludePunchCards || invoice.exclude_punch_cards || [] });
        }
        else if (type === 'surcharges') {
            Object.assign(params, { exclude_surcharges: invoice.excludeSurcharges || invoice.exclude_surcharges || [] });
        }
        return this.http.post(invoiceUrl + filterParams, params, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.expense = function (invoiceId) {
        var expenseUrl = this.baseUrl + 'invoices/' + invoiceId + '/expense/';
        return this.http.post(expenseUrl, {}, { headers: api_service_1.requestHeaders() });
    };
    InvoiceService.prototype.export = function (scope, params) {
        if (params === void 0) { params = null; }
        var invoiceUrl = this.baseUrl + 'invoices/export/';
        var _scope = lodash_1.clone(decamelizeKeysDeep(scope));
        return this.http.post(invoiceUrl + '?' + params, _scope, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.downloadPdfs = function (scope, params) {
        if (params === void 0) { params = null; }
        var invoiceUrl = this.baseUrl + 'invoices/download_pdfs/';
        var _scope = lodash_1.clone(decamelizeKeysDeep(scope));
        return this.http.post(invoiceUrl + '?' + params, _scope, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.catchError(api_service_1.handleError));
    };
    InvoiceService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    InvoiceService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (invoice) {
                return new invoice_1.Invoice(invoice);
            });
        }
        else if (resObj) {
            return new invoice_1.Invoice(resObj);
        }
    };
    return InvoiceService;
}());
exports.InvoiceService = InvoiceService;
