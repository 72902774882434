"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".body.driver-trip-filters-body-container[_ngcontent-%COMP%]   .toggle-container[_ngcontent-%COMP%]{display:flex;flex-wrap:wrap}.body.driver-trip-filters-body-container[_ngcontent-%COMP%]   .toggle-container[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{margin-top:12px;width:33%}.body.driver-trip-filters-body-container[_ngcontent-%COMP%]   .dropdown-row[_ngcontent-%COMP%]{margin-top:12px}.body.driver-trip-filters-body-container[_ngcontent-%COMP%]   .dropdown-row[_ngcontent-%COMP%]     .dropdown.open .dropdown-menu{width:100%!important}.body.driver-trip-filters-body-container[_ngcontent-%COMP%]   .date-range-container[_ngcontent-%COMP%]{margin:12px 0;display:flex;justify-content:space-between}.body.driver-trip-filters-body-container[_ngcontent-%COMP%]   .date-range-container[_ngcontent-%COMP%]     .date-picker-container{width:100%;min-width:180px}"];
exports.styles = styles;
