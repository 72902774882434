"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var driver_1 = require("../driver");
var DriverInfoboxComponent = /** @class */ (function () {
    function DriverInfoboxComponent() {
        this.contextMenuEventSubject = new rxjs_1.Subject();
    }
    DriverInfoboxComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId,
        });
    };
    return DriverInfoboxComponent;
}());
exports.DriverInfoboxComponent = DriverInfoboxComponent;
