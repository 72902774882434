"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ticket_service_1 = require("./ticket.service");
var VoidTicketDialogComponent = /** @class */ (function () {
    function VoidTicketDialogComponent(ticketService, dialogRef) {
        this.ticketService = ticketService;
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
        this.model = {
            voidReason: null,
            void: true,
            number: null,
            id: null
        };
    }
    VoidTicketDialogComponent.prototype.ngOnInit = function () {
        this.model.id = this.ticket && this.ticket.id;
        this.model.number = this.ticket && this.ticket.number;
    };
    VoidTicketDialogComponent.prototype.submit = function () {
        var _this = this;
        this.ticketService.void(this.model).subscribe(function (response) {
            _this.callback(response);
            _this.dialogRef.close();
        }, function (err) {
            console.error(err);
        });
    };
    return VoidTicketDialogComponent;
}());
exports.VoidTicketDialogComponent = VoidTicketDialogComponent;
