"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var tag_serializer_1 = require("./tag.serializer");
var resource_service_1 = require("../shared/resource.service");
var TagService = /** @class */ (function (_super) {
    __extends(TagService, _super);
    function TagService(http) {
        return _super.call(this, http, 'tags/', new tag_serializer_1.TagSerializer()) || this;
    }
    return TagService;
}(resource_service_1.ResourceService));
exports.TagService = TagService;
