<div *ngFor="let trip of trips; let i = index" class="trip-row"
  [ngClass]="{'current-trip': driver.trip && driver.trip.id === trip.id}">
  <div class="overall">
    <div class="trip-label">
      <span *ngIf="driver.trip && driver.trip.id === trip.id" translate>Current</span>
      <span *ngIf="(driver.trip && driver.trip.id !== trip.id)">{{ 'Trip' | translate }} {{ trips.length - i }}</span>
      <span *ngIf="!driver.trip">{{ 'Trip' | translate }} {{ trips.length - i }}</span>
    </div>
    <div class="trip-time-container">
      <div class="trip-time">
        {{ trip.endTimeTimestamp ? trip.completedTripTime : (trip.startTimeTimestamp | formattedDuration )}}
      </div>
    </div>
  </div>
  <ng-container *ngIf="trip.stage">
    <div class="en-route-one" [ngClass]="{current: trip.stage === 'en-route-one'}">
      {{ trip.loadingEnRouteTime }}
    </div>
    <div class="loading" [ngClass]="{current: trip.stage === 'loading'}">
      {{ trip.loadingTime }}
    </div>
    <div class="en-route-two" [ngClass]="{current: trip.stage === 'en-route-two'}">
      {{ trip.unloadingEnRouteTime }}
    </div>
    <div class="unloading" [ngClass]="{current: trip.stage === 'unloading'}">
      {{ trip.unloadingTime }}
    </div>
  </ng-container>
  <ng-container *ngIf="!trip.stage">
    <div class="en-route-one">
      <div [ngClass]="{'trip-status': trip.loadingArrivalDatetime }">{{ trip._loadingArrivalDatetime }}</div>
      <span class="trip-time">{{trip.loadingArrivalDatetime | date:'h:mm a'}}</span>
    </div>
    <div class="loading">
      <div [ngClass]="{'trip-status': trip.loadingCompleteDatetime }">{{ trip._loadingCompleteDatetime }}</div>
      <span class="trip-time">{{trip.loadingCompleteDatetime | date:'h:mm a'}}</span>
    </div>
    <div class="en-route-two">
      <div [ngClass]="{'trip-status': trip.unloadingArrivalDatetime }">{{ trip._unloadingArrivalDatetime }}</div>
      <span class="trip-time">{{trip.unloadingArrivalDatetime | date:'h:mm a'}}</span>
    </div>
    <div class="unloading">
      <div [ngClass]="{'trip-status': trip.unloadingCompleteDatetime }">{{ trip._unloadingCompleteDatetime }}</div>
      <span class="trip-time">{{trip.unloadingCompleteDatetime | date:'h:mm a'}}</span>
    </div>
  </ng-container>
</div>
