"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var MapMarkersLocationUpdatesComponent = /** @class */ (function () {
    function MapMarkersLocationUpdatesComponent() {
        this.activeJobEvents = [];
        this.activeJobs = [];
        this.customUpdateMarkers = [];
        this.errors = [];
        this.assignments = {
            items: [],
            errors: [],
            loading: false,
        };
        this.customUpdateMarkersChange = new core_1.EventEmitter();
        this.errorsChange = new core_1.EventEmitter();
        this.assignmentTitle = new core_1.EventEmitter();
        this.onOpenDriverInfoWindow = new core_1.EventEmitter();
        this.assignmentDetails = {};
    }
    /**
    * If the window is less than 900px wide, the assignment details are assigned
    * to component properties and displayed in the template.
    *
    * If the window is greater than or equal to 900px wide, the Driver Info Window
    * is opened on the map.
    *
    * @param {any} update The location update
    * @param {any} index The marker index in the array
    */
    MapMarkersLocationUpdatesComponent.prototype.openAssignmentDetails = function (update, index) {
        if (window.innerWidth < 900) {
            var latLng = void 0;
            this.assignmentDetails = {};
            if (update && update.location && update.driver && update.driver.id) {
                this.assignmentDetails = lodash_1.find(this.assignments.items, { driver: { id: update.driver.id } });
                latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
            }
            else if (update && update.shift && update.driver && update.driver.id) {
                this.assignmentDetails = update;
                var locationUpdate = lodash_1.find(this.locationUpdates, { driver: { id: update.driver.id } });
                if (locationUpdate && locationUpdate['location']) {
                    latLng = new google.maps.LatLng(locationUpdate['location'].coordinates[1], locationUpdate['location'].coordinates[0]);
                }
            }
            if (this.map && typeof this.map.setCenter === 'function' && latLng) {
                this.map.setCenter(latLng);
                this.map.setZoom(17);
            }
            if (this.assignmentDetails && this.assignmentDetails.jobevent) {
                this.selectedJobEvent = lodash_1.find(this.activeJobEvents, { id: this.assignmentDetails['jobevent'] });
                this.selectedJob = lodash_1.find(this.activeJobs, { id: this.selectedJobEvent['job']['id'] });
                this.assignmentTitle.emit(this.assignmentDetails['driver']['profile']['name']);
            }
            else {
                this.errors = ['There was an issue getting the assignment details.'];
                this.errorsChange.emit(this.errors);
            }
        }
        else {
            this.onOpenDriverInfoWindow.emit({ index: index, update: update });
        }
    };
    MapMarkersLocationUpdatesComponent.prototype.onCustomUpdateMarkerInit = function (customMarker) {
        this.customUpdateMarkers.push(customMarker);
        this.customUpdateMarkersChange.emit(this.customUpdateMarkers);
    };
    return MapMarkersLocationUpdatesComponent;
}());
exports.MapMarkersLocationUpdatesComponent = MapMarkersLocationUpdatesComponent;
