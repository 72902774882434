<div class="invitation-container">
  <div class="graphic">
    <img src="/assets/img/onboarding/org-illustration-0.svg" *ngIf="orgCreateStep === 0">
    <img src="/assets/img/onboarding/org-illustration-1.svg" *ngIf="orgCreateStep === 1">
    <img src="/assets/img/onboarding/truck-pattern.svg" class="full-size" *ngIf="orgCreateStep === 2">
    <img src="/assets/img/onboarding/account-card.svg" *ngIf="orgCreateStep === 3">
    <img src="/assets/img/onboarding/employee-cards.svg" *ngIf="orgCreateStep === 4">
  </div>
  <div class="content">
    <div class="pagination" *ngIf="orgCreateStep !== 0">
      <h5 translate *ngIf="orgCreateStep < 4">Step {{orgCreateStep}}/4</h5>
      <h5 translate *ngIf="orgCreateStep === 4">Done!</h5>
      <div class="dots">
        <div class="dot" [ngClass]="{ 'active': orgCreateStep === 1 }"></div>
        <div class="dot" [ngClass]="{ 'active': orgCreateStep === 2 }"></div>
        <div class="dot" [ngClass]="{ 'active': orgCreateStep === 3 }"></div>
        <div class="dot" [ngClass]="{ 'active': orgCreateStep === 4 }"></div>
      </div>
    </div>
    <div class="create introduction" *ngIf="orgCreateStep === 0">
      <div class="section header">
        <h1 translate>Welcome to Ruckit!</h1>
        <h2 translate>Let’s get started by setting up your Ruckit organization.</h2>
        <button translate class="btn btn-primary inline" (click)="nextStep()">Get Started</button>
        <label translate class="sublabel inline"><b>Already have a Ruckit account? <a routerLink="/login">Sign In</a></b></label>
        <label translate class="sublabel inline"><b>Need Help?</b><br>Call customer service at <b>1-800-555-1234</b></label>
      </div>
      <div class="section details">
        <h2 translate><b>What is a Ruckit Organization?</b></h2>
        <h2 translate>To put it simply, it’s you. With Ruckit your organization can:</h2>
        <ul>
          <li translate>Access tools to help you receive and manage jobs.</li>
          <li translate>Work collaboratively with employees, customers, and drivers.</li>
          <li translate>Expand your reach by connecting to other Ruckit Organizations.</li>
        </ul>
      </div>
    </div>
    <form #orgDetails="ngForm" *ngIf="orgCreateStep === 1">
      <div class="create step-1">
        <div class="section header">
          <h1 translate>Name your organization.</h1>
          <h2 translate>This is how your company will be identified on Ruckit and how connections will find you.</h2>
        </div>
        <div class="section org-name">
          <div class="input-field width-full">
            <input name="org-name" type="text" placeholder="{{ 'Organization Name' | translate }}" autocomplete="organization" [(ngModel)]="inviteData.organization.name">
            <label translate class="sublabel" for="org-name">Most people use their company name or their own name. You can always change this later in settings.</label>
          </div>
        </div>
        <div class="section details">
          <h2 translate>Organization Details</h2>
          <div class="input-field width-half">
            <label translate class="required" for="org-address1">Address</label>
            <input name="org-address1" type="text" autocomplete="street-address" [(ngModel)]="inviteData.organization.street">
          </div>
          <div class="input-field width-half">
            <label translate for="org-address2">Address 2</label>
            <input name="org-address2" type="text" autocomplete="address-line2" [(ngModel)]="inviteData.organization.address2">
          </div>
          <div class="input-field width-three-fourth">
            <label translate class="required" for="org-city">City</label>
            <input name="org-city" type="text" autocomplete="address-level2" [(ngModel)]="inviteData.organization.city">
          </div>
          <div class="input-field width-one-fourth">
            <label translate class="required" for="org-state">State</label>
            <select name="org-state" required autocomplete="address-level1" [(ngModel)]="inviteData.organization.state">
              <option [value]="undefined" selected disabled hidden>{{ 'Select' | translate }}</option>
              <option *ngFor="let state of states" [value]="state.abbreviation">{{ state.abbreviation }}</option>
            </select>
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="org-zipcode">ZIP</label>
            <input name="org-zipcode" type="text" placeholder="{{ 'ZIP code' | translate }}" autocomplete="postal-code" [(ngModel)]="inviteData.organization.zipcode">
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="org-phone">Phone Number</label>
            <input name="org-phone" type="text" placeholder="{{ 'Phone Number' | translate }}" autocomplete="tel" [(ngModel)]="inviteData.organization.phoneNumber">
          </div>
        </div>
        <div class="section actions">
          <button translate class="btn btn-primary"
                  [disabled]="!orgDetails.form.valid || loading"
                  (click)="saveOrgDetails()">Next</button>
          <label translate class="sublabel help-text" for="org-name"><b>Need Help?</b><br>Call customer service at <b>1-800-555-1234</b></label>
        </div>
      </div>
    </form>
    <form #orgCustom="ngForm" *ngIf="orgCreateStep === 2">
      <ng-container *ngIf="ab5; else default">
        <div class="create step-2">
          <div class="section header">
            <h1 translate>Customize your Ruckit experience.</h1>
            <h2 translate>You can enter your DOT# or Motor Carrier#, DIR# EIN# and upload your insurance documents below to help us create the right experience for you.</h2>
            <label class="checkbox" translate><input name="is-ab5" [checked]="ab5" [(ngModel)]="ab5" type="checkbox">Does your organization do business in the state of California?</label>
          </div>
          <div class="section dot-number ab5">
            <h2 translate>Compliance Details</h2>
            <div class="input-field width-half">
              <label translate for="org-dot-number">DOT#
                <i class="icon-accept" [ngClass]="{
                  'valid': inviteData.organization &&
                           !!inviteData.organization.dotNumber
                }"></i>
              </label>
              <input name="org-dot-number" type="text" placeholder="{{ 'Enter DOT#' | translate }}" [(ngModel)]="inviteData.organization.dotNumber">
            </div>
            <div class="input-field width-half">
              <label translate for="org-carrier-number">Motor Carrier#
                <i class="icon-accept" [ngClass]="{
                  'valid': inviteData.organization &&
                           !!inviteData.organization.mcNumber
                }"></i>
              </label>
              <input name="org-carrier-number" type="text" placeholder="{{ 'Enter Motor Carrier#' | translate }}" [(ngModel)]="inviteData.organization.mcNumber">
            </div>
            <div class="input-field width-half">
              <label translate for="org-dir-number">DIR#
                <i class="icon-accept" [ngClass]="{
                  'valid': inviteData.organization &&
                           !!inviteData.organization.dirNumber
                }"></i>
              </label>
              <input name="org-dir-number" type="text" placeholder="{{ 'Enter DIR#' | translate }}" [(ngModel)]="inviteData.organization.dirNumber">
            </div>
            <div class="input-field width-half">
              <label translate for="org-ein-number">EIN#
                <i class="icon-accept" [ngClass]="{
                  'valid': inviteData.organization &&
                           !!inviteData.organization.einNumber
                }"></i>
              </label>
              <input name="org-ein-number" type="text" placeholder="{{ 'Enter EIN#' | translate }}" [(ngModel)]="inviteData.organization.einNumber">
            </div>
            <div class="compliance-questions">
              <p class="status">
                Compliance Questions<br/>
                <span class="label" [ngClass]="{ 'answered': inviteData.organization && inviteData.organization.isAb5Compliant }">
                  {{ (inviteData.organization && inviteData.organization.isAb5Compliant ? 'Answered' : 'Not Answered') | translate }}
                </span>
              </p>
              <button class="btn btn-primary" (click)="openAB5Questions()" translate>{{ (inviteData.organization && inviteData.organization.isAb5Compliant ? 'Review' : 'Answer') | translate }} Questions</button>
            </div>
          </div>
          <div class="section compliance-documents">
            <h2 translate>Upload Compliance Documents</h2>
            <div class="input-field width-half">
              <label translate for="org-insurance">Insurance</label>
              <ng-container *ngIf="!inviteData.organization.insuranceDocument; else insuranceDocumentPreview">
                <input name="org-insurance"
                       type="file" [ngClass]="{'loading': documentLoading}"
                       (change)="uploadAB5Document('insuranceDocument', $event)">
              </ng-container>
              <ng-template #insuranceDocumentPreview>
                <div class="document-preview">
                  <a class="preview" [href]="inviteData.organization.insuranceDocument" translate>View Document</a>
                  <button class="btn btn-default reupload" (click)="inviteData.organization.insuranceDocument = null" translate>Reupload</button>
                </div>
              </ng-template>
            </div>
            <div class="input-field width-half">
              <label translate>Insurance Expiration Date</label>
              <date-range-picker [selectedDates]="[(inviteData.organization && inviteData.organization.insuranceExpirationDate)]"
                                 [config]="{ decreaseControl: false, increaseControl: false }"
                                 (dateChanged)="setExpirationDate($event, 'insurance')"></date-range-picker>
            </div>
            <div class="input-field width-half">
              <label translate for="org-overweight-permit">Overweight Permit</label>
              <ng-container *ngIf="!inviteData.organization.overweightPermit; else overweightPermitPreview">
                <input name="org-overweight-permit"
                       type="file" [ngClass]="{'loading': documentLoading}"
                       (change)="uploadAB5Document('overweightPermit', $event)">
              </ng-container>
              <ng-template #overweightPermitPreview>
                <div class="document-preview">
                  <a class="preview" [href]="inviteData.organization.overweightPermit" translate>View Document</a>
                  <button class="btn btn-default reupload" (click)="inviteData.organization.overweightPermit = null" translate>Reupload</button>
                </div>
              </ng-template>
            </div>
            <div class="input-field width-half">
              <label translate>Permit Expiration Date</label>
              <date-range-picker [selectedDates]="[(inviteData.organization && inviteData.organization.overweightPermitExpiration)]"
                                  [config]="{ decreaseControl: false, increaseControl: false }"
                                  (dateChanged)="setExpirationDate($event, 'overweightPermit')"></date-range-picker>
            </div>
            <div class="input-field width-half">
              <label translate for="org-business-license">Business License</label>
              <ng-container *ngIf="!inviteData.organization.businessLicense; else businessLicensePreview">
                <input name="org-business-license"
                       type="file" [ngClass]="{'loading': documentLoading}"
                       (change)="uploadAB5Document('businessLicense', $event)">
              </ng-container>
              <ng-template #businessLicensePreview>
                <div class="document-preview">
                  <a class="preview" [href]="inviteData.organization.businessLicense" translate>View Document</a>
                  <button class="btn btn-default reupload" (click)="inviteData.organization.businessLicense = null" translate>Reupload</button>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="section actions">
            <button translate class="btn btn-primary-inverted"
                    [disabled]="loading"
                    (click)="nextStep()">Skip?</button>
            <button translate class="btn btn-primary"
                    [disabled]="!complianceEntered() || loading"
                    (click)="saveOrgDetails()">Next</button>
            <label translate class="sublabel help-text"><b>Need Help?</b><br>Call customer service at <b>1-800-555-1234</b></label>
          </div>
        </div>
      </ng-container>
      <ng-template #default>
        <div class="create step-2">
          <div class="section header">
            <h1 translate>Customize your Ruckit experience.</h1>
            <h2 translate>You can enter your DOT# or Motor Carrier# and select items from the list below to help us create the right experience for you.</h2>
            <!-- <label class="checkbox" translate><input name="is-ab5" [checked]="ab5" [(ngModel)]="ab5" type="checkbox">Does your organization do business in the state of California?</label> -->
          </div>
          <div class="section dot-number">
            <h2 translate>Enter DOT# or Motor Carrier#</h2>
            <div class="input-field width-half">
              <label translate for="org-dot-number">DOT#</label>
              <input name="org-dot-number" type="text" placeholder="{{ 'Enter DOT#' | translate }}" [(ngModel)]="inviteData.carrier.dotNumber">
            </div>
            <div class="input-field width-half">
              <label translate for="org-carrier-number">Motor Carrier#</label>
              <input name="org-carrier-number" type="text" placeholder="{{ 'Enter Motor Carrier#' | translate }}" [(ngModel)]="inviteData.data.motorCarrierNumber">
            </div>
          </div>
          <div class="section my-company">
            <h2 translate>My Company...</h2>
            <label translate class="sublabel" for="org-action">Select all that apply.</label>
            <label class="checkbox" translate><input name="org-action" [checked]="inviteData.data.sendsJobsToPartners" [(ngModel)]="inviteData.data.sendsJobsToPartners" type="checkbox">Sends jobs to a partner to complete</label>
            <label class="checkbox" translate><input name="org-action" [checked]="inviteData.data.receivesJobsFromPartners" [(ngModel)]="inviteData.data.receivesJobsFromPartners" type="checkbox">Receives jobs from a partner to complete</label>
            <label class="checkbox" translate><input name="org-action" [checked]="inviteData.data.ownsManagesFleet" [(ngModel)]="inviteData.data.ownsManagesFleet" type="checkbox">Owns & manages a fleet</label>
            <label class="checkbox" translate><input name="org-action" [checked]="inviteData.data.leasesFleet" [(ngModel)]="inviteData.data.leasesFleet" type="checkbox">Leases a fleet</label>
          </div>
          <div class="section actions">
            <button translate class="btn btn-primary-inverted"
                    [disabled]="loading"
                    (click)="nextStep()">Skip?</button>
            <button translate class="btn btn-primary"
                    [disabled]="orgCustom.form.pristine || loading"
                    (click)="saveOrgDetails()">Next</button>
            <label translate class="sublabel help-text"><b>Need Help?</b><br>Call customer service at <b>1-800-555-1234</b></label>
          </div>
        </div>
      </ng-template>
    </form>
    <form #orgProfile="ngForm" *ngIf="orgCreateStep === 3">
      <div class="create step-3">
        <div class="section header">
          <h1 translate>Create your profile to start collaborating.</h1>
        </div>
        <div class="section profile-details">
          <h2 translate>Profile Details</h2>
          <div class="input-field width-half">
            <label translate class="required" for="profile-firstname">First Name</label>
            <input name="profile-firstname" required type="text" placeholder="{{ 'First Name' | translate }}" autocomplete="given-name" [(ngModel)]="inviteData.profile.firstName">
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="profile-lastname">Last Name</label>
            <input name="profile-lastname" required type="text" placeholder="{{ 'Last Name' | translate }}" autocomplete="family-name" [(ngModel)]="inviteData.profile.lastName">
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="profile-jobtitle">Job Title</label>
            <input name="profile-jobtitle" required type="text" placeholder="{{ 'Job Title' | translate }}" autocomplete="organization-title" [(ngModel)]="inviteData.profile.title">
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="profile-phone">Phone Number</label>
            <input name="profile-phone" required type="tel" placeholder="{{ 'Phone Number' | translate }}" autocomplete="tel" [(ngModel)]="inviteData.profile.phoneNumber">
          </div>
        </div>
        <div class="section login-details">
          <h2 translate>Login Details</h2>
          <div class="input-field width-half clear">
            <label translate class="required" for="profile-email">Email</label>
            <input name="profile-email" required type="email" email placeholder="{{ 'Email' | translate }}" autocomplete="email" [(ngModel)]="inviteData.profile.email">
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="profile-password">Password</label>
            <input name="profile-password" required type="password" placeholder="{{ 'Password' | translate }}" autocomplete="new-password" [(ngModel)]="inviteData.profile.password">
          </div>
          <div class="input-field width-half">
            <label translate class="required" for="profile-password-repeat">Password Confirmation</label>
            <input name="profile-password-repeat" required type="password" placeholder="{{ 'Re-enter Password' | translate }}" autocomplete="new-password" [(ngModel)]="inviteData.profile.passwordConfirmation">
          </div>
        </div>
        <div *ngIf="errors.length > 0" class="section errors">
          <notification *ngFor="let error of errors">{{error}}</notification>
        </div>
        <div class="section actions">
          <button translate class="btn btn-primary"
                  [disabled]="!orgProfile.form.valid || loading"
                  (click)="saveOrgProfile()">Next</button>
          <label translate class="sublabel help-text"><b>Need Help?</b><br>Call customer service at <b>1-800-555-1234</b></label>
        </div>
      </div>
    </form>
    <form #orgInvites="ngForm" *ngIf="orgCreateStep === 4">
      <div class="create step-4">
        <div class="section header">
          <h1 translate>Invite your employees.</h1>
          <h2 translate>Invite your team so your entire company can start using Ruckit.</h2>
        </div>
        <div class="section invites">
          <h2 translate>Send Invites to Your Employees.</h2>
          <div *ngFor="let inviteEmail of inviteList; let i = index; trackBy: inviteTrackBy" class="input-field width-half clear">
            <label translate>Email</label>
            <input [name]="'invite-email-' + i" [attr.name]="'invite-email-' + i" id="{{'invite-email-' + i}}" type="email" placeholder="{{ 'Email Address' | translate }}" [(ngModel)]="inviteList[i]">
          </div>
          <a (click)="addInviteField()">+ Add Another Email</a>
          <div class="callout">
            <h3 translate>What about drivers?</h3>
            <label translate class="sublabel">You’ll be adding drivers and trucks later. These invites are for employees that manage your jobs day-to-day.</label>
          </div>
        </div>
        <div class="section actions">
          <button translate class="btn btn-primary-inverted"
                  [disabled]="loading"
                  (click)="submitOrg()">Skip?</button>
          <button translate class="btn btn-primary"
                  [disabled]="loading"
                  (click)="submitOrg(inviteList)">Done</button>
          <label translate class="sublabel help-text" for="org-name"><b>Need Help?</b><br>Call customer service at <b>1-800-555-1234</b></label>
        </div>
      </div>
    </form>
  </div>
</div>

<my-loader [kind]="'full-size'" *ngIf="initializing"></my-loader>
