import { Component, Input } from '@angular/core';

import { Assignment } from '../assignments/assignment';
import { JobEvent } from '../job-events/job-event';

@Component({
  selector: 'mobile-assignment-details',
  templateUrl: './mobile-assignment-details.component.html',
  styleUrls: ['./mobile-assignment-details.component.scss'],
})
export class MobileAssignmentDetailsComponent {
  @Input() assignment: Assignment;
  @Input() jobEvent: JobEvent;
}
