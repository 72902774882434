"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMAGE_OPTIONS = [
    'trips',
    'punchcards',
    'expenses',
    'invoices',
    'paystubs',
    'eod-trips',
    'eod-punchcards',
];
exports.FIELD_OPTIONS = [
    'trips',
    'punchcards',
    'shifts',
    'driver-shifts',
    'jobs',
    'eod-trips',
    'eod-punchcards',
];
exports.FORMAT_OPTIONS = ['expenses', 'invoices', 'paystubs'];
exports.TYPE_OPTIONS = ['driver pay'];
exports.DATE_OPTIONS = ['driver-shifts'];
exports.LEASED_FLEET_OPTIONS = ['driver-shifts'];
exports.LEGACY_OPTIONS = [
    'shifts',
    'driver-shifts',
    'jobs',
    'eod-trips',
    'eod-punchcards',
];
exports.TYPELIST_OPTIONS = {
    IMAGE_OPTIONS: exports.IMAGE_OPTIONS,
    FIELD_OPTIONS: exports.FIELD_OPTIONS,
    FORMAT_OPTIONS: exports.FORMAT_OPTIONS,
    TYPE_OPTIONS: exports.TYPE_OPTIONS,
    DATE_OPTIONS: exports.DATE_OPTIONS,
    LEASED_FLEET_OPTIONS: exports.LEASED_FLEET_OPTIONS,
    LEGACY_OPTIONS: exports.LEGACY_OPTIONS,
};
