"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".form-content[_ngcontent-%COMP%]{margin-top:30px;margin-bottom:30px}.mat-dialog-content[_ngcontent-%COMP%], .md-dialog-content[_ngcontent-%COMP%]{height:auto;overflow-y:visible;margin-bottom:0;padding-bottom:0}.mat-dialog-content[_ngcontent-%COMP%]   .errors[_ngcontent-%COMP%], .md-dialog-content[_ngcontent-%COMP%]   .errors[_ngcontent-%COMP%]{margin-top:10px}.mat-dialog-content[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%], .md-dialog-content[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex:1 1 auto}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .collaborator-days[_ngcontent-%COMP%]{display:flex;flex-direction:row;flex:1 1 auto;width:360px}form[_ngcontent-%COMP%]   .collaborator-days[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]{width:100%;padding:0;position:relative}form[_ngcontent-%COMP%]   .collaborator-days[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{font-size:12px;color:#7f8fa4}form[_ngcontent-%COMP%]   .collaborator-days[_ngcontent-%COMP%]   .form-content[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{font-size:14px;font-weight:600;line-height:1.36;color:#354052}form[_ngcontent-%COMP%]   .modal-footer[_ngcontent-%COMP%]{display:flex;flex:0 0 60px;flex-direction:column}form[_ngcontent-%COMP%]   .modal-footer[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{flex:0 0 auto;height:100%}"];
exports.styles = styles;
