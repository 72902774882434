import { remove, find, clone } from 'lodash';

import { LocationType } from './location-type';
import { Resource } from '../shared/resource';
import { LocationOwner } from './location-owner';
import { Tag } from '../tags/tag';

export class Location extends Resource {
  id: string;
  name: string;
  displayName: string;
  street: string;
  city: string;
  zipcode: string;
  state: string;
  country: string;
  longitude: any;
  latitude: any;
  button: boolean;
  location: any;
  geofence: any;
  archived: boolean;
  averageLoadingTime: string;
  averageUnloadingTime: string;
  selected = false;
  organization: string;
  timezone: string;
  locationType: LocationType;
  locationTypeName: string;
  paths: any;
  mappable: boolean;
  createdAt: string;
  marker: any;
  tags: (string | Tag)[];
  locationOwner: LocationOwner;

  url(action: string): string {
    return '/locations/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.archived) {
      let _option = find(options, { action: 'archive' });
      if (_option) { remove(options, _option); }
    }

    if (!this.archived) {
      let _option = find(options, { action: 'unarchive' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
