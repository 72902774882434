import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { omit } from 'lodash';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { parseErrors, requestHeaders } from '../shared/api.service';
import { ResourceService } from '../shared/resource.service';
import { Ticket } from './ticket';
import { TicketSerializer } from './ticket.serializer';

@Injectable()
export class TicketService extends ResourceService<Ticket> {
  baseUrl = environment.serverUrl + 'scaletickets/';

  constructor(http: HttpClient) {
    super(http, 'scaletickets/', new TicketSerializer());
  }

  void(model) {
    const uri = this.baseUrl;
    model = new TicketSerializer().toJson(model);
    return this.http.put(uri + model.id + '/', omit(model, ['id', 'number']), {
      headers: requestHeaders()
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError((err) => parseErrors(err))
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(ticket => {
        return new TicketSerializer().fromJson(ticket);
      });
    } else if (resObj) {
      return new TicketSerializer().fromJson(resObj);
    }
  }
}
