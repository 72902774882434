"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var NumberInRangeDirective = /** @class */ (function () {
    function NumberInRangeDirective(el, renderer) {
        var _this = this;
        this.ngModelChange = new core_1.EventEmitter();
        renderer.listen(el.nativeElement, 'keydown', function ($event) {
            _this.restrict($event);
        });
    }
    NumberInRangeDirective.prototype.restrict = function (e) {
        var _this = this;
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
        if (e.keyCode === 38) {
            e.target.value++;
        }
        else if (e.keyCode === 40) {
            e.target.value--;
        }
        if (e.key && e.key.length !== 1) {
            return;
        }
        if (e.key && !e.key.match(/(\d|\.)/) && !e.metaKey) {
            e.preventDefault();
        }
        setTimeout(function () {
            var value = parseInt(e.target.value, 10);
            if (_this.maxValue != null && value > _this.maxValue) {
                e.target.value = _this.maxValue;
            }
            if (_this.minValue != null && value < _this.minValue) {
                e.target.value = _this.minValue;
            }
            _this.ngModelChange.emit(e.target.value);
        }, 10);
    };
    NumberInRangeDirective.prototype.validate = function (c) {
        var v = c.value;
        if (v && v.match) {
            var numbers = v.match(/(\d|\.)/g);
            if (numbers && numbers.length !== 0) {
            }
            else {
                return { value: false };
            }
        }
        return null;
    };
    return NumberInRangeDirective;
}());
exports.NumberInRangeDirective = NumberInRangeDirective;
