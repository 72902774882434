<div class="app-content">
  <my-toolbar title="{{ jobEvent && jobEvent.jobDisplayName }} - {{ 'Collaborators' | translate }}"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <dropdown title="{{ jobEventsLoading ? 'Loading Jobs...' : 'Select a Job' }}"
                [disabled]="!job || !jobEvent || jobEventsLoading"
                #jobEventsDropdown
                [options]="jobEvents"
                (onSelect)="selectJobEvent($event)"
                class="job-events-dropdown"></dropdown>
    </div>
    <div class="right-actions">
      <dropdown *ngIf="job && jobEvent" #actionsDropdown title="Actions" [options]="actionsDropdownOptions" [config]="actionsDropdownConfig"
        (onSelect)="setAction($event)" class="actions-dropdown"></dropdown>

        <!-- job dispatch -->
        <a
        class="btn btn-primary"
        *ngIf="isDispatchable()"
        translate
        [routerLink]="['/dispatch/' + job.id + '/' + jobEvent.id]"
      >
        Job Dispatch
      </a>

      <!-- job detail -->
      <a
        class="btn btn-primary-alt"
        translate
        *ngIf="job && job.id && jobEvent && jobEvent.id"
        [routerLink]="['/jobs/' + job.id + '/' + jobEvent.id]"
      >
        Job Detail
      </a>

      <!-- in case user comes from the page that is not job dispatch or job detail -->
      <a
        class="btn btn-primary-alt"
        translate
        *ngIf="returnTo && !(returnToTitle === ('Job Detail' | translate)) && !(returnToTitle === ('Job Dispatch' | translate) && isDispatchable())"
        [routerLink]="returnTo"
        [queryParams]="returnToQueryParams"
      >
        {{ returnToTitle }}
      </a>

    </div>
  </action-bar>

  <div class="content" #content>
    <div class="collaborator-container">
      <collaborator-summary [jobEvent]="jobEvent" *ngIf="jobEvent"></collaborator-summary>

      <collaborator-list #collaboratorList [jobEvent]="jobEvent" *ngIf="jobEvent">
      </collaborator-list>
    </div>
    <day-details [job]="job" [jobEvent]="jobEvent" [includeJobStats]="true" controlPosition="left"></day-details>
  </div>
</div>
