import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { remove } from 'lodash';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { JobEventShareService } from '../job-event-shares/job-event-share.service';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { UserService } from '../users/user.service';
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { ColumnToggleComponent } from '../shared/column-toggle/column-toggle.component';
import { AppliedFiltersComponent } from '../shared/applied-filters/applied-filters.component';
import { FiltersPanelComponent } from '../shared/filters-panel/filters-panel.component';
import { JobService } from '../jobs/job.service';
import { LocationService } from '../locations/location.service';
import { OrganizationService } from '../organizations/organization.service';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';

@Component({
  selector: 'ruckit-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.scss']
})
export class WorkOrdersComponent {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'name', title: this.translationService.instant('Job Name'), sortable: true, sortBy: 'jobevent__job__name' },
    { key: 'organization', title: this.translationService.instant('Organization Name'), sortable: true, sortBy: 'organization__name' },
    { key: 'startDate', title: this.translationService.instant('Start Date'), sortable: true, sortBy: 'jobevent__shift1_start' },
    { key: 'startTime', title: this.translationService.instant('Start Time'), sortable: false },
    { key: 'endDate', title: this.translationService.instant('End Date'), sortable: true, sortBy: 'jobevent__shift1_end' },
    { key: 'endTime', title: this.translationService.instant('End Time'), sortable: false },
    { key: 'dispatched', title: this.translationService.instant('Dispatched Trucks') },
    { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
    { key: 'num-trucks', title: this.translationService.instant('Requested Trucks') },
    { key: 'job-number', title: this.translationService.instant('Job Number') },
    { key: 'job-order-number', title: this.translationService.instant('Order Number') },
    { key: 'haul-rate-unit', title: this.translationService.instant('Haul Rate Unit') },
    { key: 'workOrderSigned', title: this.translationService.instant('Signing Status'), sortable: true, sortBy: 'work_order_signed' },
    { key: 'workOrderSignedBy', title: this.translationService.instant('Signed By'), sortable: true, sortBy: 'work_order_signed_by_name' },
    { key: 'actions', title: this.translationService.instant('Action'), sortable: false }
  ];
  @Input() displayedColumns = [
    'select', 'name', 'job-number', 'job-order-number', 'organization',
    'startDate', 'startTime', 'endDate', 'endTime', 'dispatched',
    'confirmed-trucks', 'num-trucks', 'haul-rate-unit', 'workOrderSigned',
    'workOrderSignedBy', 'actions'
  ];
  @Input() availableFilters = [
    new FilterOption({
      key: 'start__gte', title: this.translationService.instant('Start Date'),
      filterType: 'date'
    }),
    new FilterOption({
      key: 'end__lte', title: this.translationService.instant('End Date'),
      filterType: 'date'
    }),
    new FilterOption({
      key: 'work_order_signed_by',
      title: this.translationService.instant('Work Order Signed By'),
      filterType: 'select', service: UserService, ordering: 'first_name,last_name'
    }),
    new FilterOption({
      key: 'jobevent__job', title: this.translationService.instant('Job'),
      filterType: 'select', service: JobService
    }),
    new FilterOption({
      key: 'jobevent__job__start_location',
      title: this.translationService.instant('Start Location'),
      filterType: 'select', service: LocationService
    }),
    new FilterOption({
      key: 'jobevent__job__end_location',
      title: this.translationService.instant('End Location'),
      filterType: 'select', service: LocationService
    }),
    new FilterOption({
      key: 'organization', filterType: 'select', service: OrganizationService,
      title: this.translationService.instant('Organization')
    }),
    new FilterOption({
      key: 'work_order_signed', filterType: 'checkbox',
      title: this.translationService.instant('Work Order Signed'),
    }),
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  errors = [];
  workOrdersTableConfig = {
    hasHeader: true,
    service: JobEventShareService,
    preferenceKey: 'WorkOrdersComponent-JobEventShareService',
    query: {},
    collectionTitle: this.translationService.instant('Work Orders'),
    noResultsText: this.translationService.instant('a work order'),
    hasNoResultsAction: false,
    sortBy: 'name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('View HTML'), action: 'html', link: true, external: true },
      { name: this.translationService.instant('View PDF'), action: 'pdf', link: true, external: true }
    ]
  };
  multipleActionDropdownOptions = [
    { name: 'Export', button: true }
  ];

  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('workOrdersTable', { static: false }) workOrdersTable: FancyTableComponent;

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;

  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle: ColumnToggleComponent;

  /**
  * Template reference for the AppliedFilters component.
  */
  @ViewChild('appliedFiltersDisplay', { static: false }) appliedFiltersDisplay: AppliedFiltersComponent;

  /**
  * Template reference for the FiltersPanel component.
  */
  @ViewChild('filtersPanelDisplay', { static: false }) filtersPanelDisplay: FiltersPanelComponent;

  constructor(
    private dialog: MatDialog,
    private jobEventShareService: JobEventShareService,
    private translationService: TranslateService
  ) { }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  filtersModified(appliedFilters): void {
    if (this.appliedFiltersDisplay) {
      this.appliedFiltersDisplay.ngOnInit();
    }
    this.appliedFilters = appliedFilters;
  }

  removeFilter(filter): void {
    remove(this.appliedFilters, filter);
    this.filtersPanelDisplay.resetFilters();
    this.workOrdersTable.getRecords();
  }

  multiselectDisabled(): boolean {
    return !this.workOrdersTable || !this.workOrdersTable.count ||
      this.workOrdersTable.count === 0 ||
      (!this.workOrdersTable.allSelected && !this.workOrdersTable.selection.selected.length) ||
      this.workOrdersTable.count === this.workOrdersTable.exclusion.selected.length;
  }

  setSelectedAction(option) {
    switch (option.action) {
      case 'export':
        this.createExport();
        break;
    }
  }

  createExport() {
    let selection = [];
    let exclusion = [];

    if (this.workOrdersTable) {
      selection = this.workOrdersTable.selection.selected.map(record => record.id);
      exclusion = this.workOrdersTable.exclusion.selected.map(record => record.id);
    }

    let scope = { include: selection, exclude: exclusion };
    let filters = this.mergeFilters(this.appliedFilters);
    const query = { search: this.search, filters: filters };

    this.jobEventShareService.export(scope, query).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          scope: scope,
          params: params,
          service: this.jobEventShareService,
          buttonText: this.translationService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  private mergeFilters(filters): string {
    return filters.map(filter => {
      if (filter.multiple && filter.values) {
        return filter.values.map(value => {
          const _value = [filter.key, value].join('=');
          return `(${_value})`;
        }).filter(Boolean).join('|');
      } else if (filter.values) {
        let values = filter.values;
        if (values === true) { values = 'True'; }
        if (values === false) { values = 'False'; }
        const _value = [filter.key, values].join('=');
        return `(${_value})`;
      }
    }).filter(Boolean).join('&');
  }
}
