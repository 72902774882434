import * as moment from 'moment';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Conflict {
  jobevent: string;
  jobeventName: string;
  start: string;
  end: string;
  _privateTime: string;

  constructor(conflictInfo: any) {
    conflictInfo = camelcaseKeysDeep(conflictInfo);

    this.jobevent = conflictInfo.jobevent || conflictInfo.jobeventId;
    this.jobeventName = conflictInfo.jobeventName;
    this.start = conflictInfo.start;
    this.end = conflictInfo.end;

    let start = moment(this.start).format('h:mm a');
    let end = moment(this.end).format('h:mm a');
    this._privateTime = start + ' - ' + end;
  }

  get time(): string {
    return this._privateTime;
  }
}
