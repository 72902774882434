"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var paystub_service_1 = require("./paystub.service");
var organization_service_1 = require("../organizations/organization.service");
var api_service_1 = require("../shared/api.service");
var index_1 = require("../shared/dialogs/index");
var EditPaystubComponent = /** @class */ (function () {
    function EditPaystubComponent(route, router, paystubService, organizationService, dialog) {
        this.route = route;
        this.router = router;
        this.paystubService = paystubService;
        this.organizationService = organizationService;
        this.dialog = dialog;
        this.loading = true;
        this.editing = false;
        this.errors = [];
    }
    EditPaystubComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.route.params
            .subscribe(function (params) { return _this.getPaystub(params['id']); });
    };
    EditPaystubComponent.prototype.getPaystub = function (id) {
        var _this = this;
        if (id) {
            this.paystubService.getPaystub(id).subscribe(function (res) {
                _this.paystub = res;
                var _createdAt = _this.paystub.createdAt && new Date(_this.paystub.createdAt);
                if (_createdAt) {
                    _this.createdAt = _createdAt;
                }
                _this.loading = false;
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    EditPaystubComponent.prototype.updatePaystub = function (update) {
        if (update === void 0) { update = false; }
        if (update) {
            this.getPaystub(this.paystub.id);
        }
    };
    EditPaystubComponent.prototype.fileChange = function (e) {
        var _this = this;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (_e) {
            _this.paystub.ownerOrganization.image = _e.target['result'];
        };
        reader.readAsDataURL(file);
        this.organizationService.saveOnlyImage(this.paystub.ownerOrganization.id, file).subscribe(function (organization) {
            _this.save();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditPaystubComponent.prototype.save = function () {
        var _this = this;
        this.loading = true;
        this.paystubService.save(this.paystub).subscribe(function () {
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditPaystubComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Send Paystub?',
            body: 'Are you sure you want to send this paystub? You will no longer be able to edit the paystub once it has been sent.',
            close: 'Cancel',
            accept: 'Send'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.paystubService.save(_this.paystub).subscribe(function () {
                    _this.paystubService.send(_this.paystub).subscribe(function () {
                        _this.loading = false;
                        _this.router.navigate(['/paystubs/filter/paid']);
                    }, function (err) {
                        _this.errors = api_service_1.parseErrors(err);
                        _this.loading = false;
                    });
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            else {
                _this.loading = false;
            }
            _this.confirmDialog = null;
        });
    };
    EditPaystubComponent.prototype.onDateChanged = function (values) {
        if (values && values.length) {
            this.createdAt = values[0];
            this.paystub.createdAt = values[0];
        }
    };
    EditPaystubComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
    };
    return EditPaystubComponent;
}());
exports.EditPaystubComponent = EditPaystubComponent;
