import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocationUpdates } from './location-updates';
import { requestHeaders, handleError } from '../shared/api.service';

@Injectable()
export class MapService {
  baseUrl = environment.serverUrl;
  public nextUri;
  public count;

  constructor(private http: HttpClient) { }

  getLocationUpdates(query: any = null, endpoint?: string, idList?: any) {
    let mapUrl = this.baseUrl + 'lastlocationupdates/';
    if (endpoint) { mapUrl += endpoint + '/'; }
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }

    return idList ?
    this.http.post(mapUrl, idList, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    ) :
    this.http.get(mapUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(locationUpdate => {
        return new LocationUpdates(locationUpdate);
      });
    } else if (resObj) {
      return new LocationUpdates(resObj);
    }
  }

  getNext(): Observable<LocationUpdates[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, { headers: requestHeaders() }).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }
}
