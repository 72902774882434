import { BillingRate } from './billing-rate';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class BillingRateSerializer {
  fromJson(json: any): BillingRate {
    json = camelcaseKeysDeep(json);
    const billingRate = new BillingRate();
    if (!json) { return billingRate; }

    billingRate.id = json.id;
    billingRate.name = json.name;
    billingRate.rateGroup = json.rateGroup;
    billingRate.code = json.code;
    billingRate.description = json.description;
    billingRate.rateType = json.rateType;
    billingRate.unit = json.unit;
    billingRate.rate = json.rate;

    return billingRate;
  }

  toJson(billingRate: BillingRate): any {
    let json = {
      id: billingRate.id,
      name: billingRate.name,
      rateGroup: billingRate.rateGroup,
      code: billingRate.code,
      description: billingRate.description,
      rateType: billingRate.rateType,
      unit: billingRate.unit,
      rate: billingRate.rate
    };

    return decamelizeKeysDeep(json);
  }
}
