"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var expense_service_1 = require("./expense.service");
var expense_filters_dialog_component_1 = require("./expense-filters-dialog.component");
var expense_pay_type_dialog_component_1 = require("./expense-pay-type-dialog.component");
var api_service_1 = require("../shared/api.service");
var index_1 = require("../shared/dialogs/index");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var ExpensesComponent = /** @class */ (function () {
    function ExpensesComponent(route, router, expenseService, dialog) {
        this.route = route;
        this.router = router;
        this.expenseService = expenseService;
        this.dialog = dialog;
        this.expenses = [];
        this.loading = true;
        this.errors = [];
        this.search = '';
        this.sortAsc = true;
        this.filters = [];
        this.count = 0;
        this.selectedCount = {
            draft: 0,
            paid: 0
        };
        this.allSelected = {
            draft: false,
            paid: false
        };
        this.selectedExpenses = {
            draft: [],
            paid: []
        };
        this.excludeExpenses = {
            draft: [],
            paid: []
        };
        this.state = 'draft';
        this.dropdownOptions = {
            draft: [
                { name: 'Export', button: true }
            ],
            paid: [
                { name: 'Export', button: true },
                { name: 'Download', button: true }
            ]
        };
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true, external: false },
            { name: 'Mark as Paid', action: 'mark_as_paid', link: false, external: false },
            { name: 'Remove', action: 'remove', link: false, external: false },
            { name: 'View', action: 'view', link: true, external: true },
            { name: 'Unlock', action: 'unlock', link: false, external: false },
            { name: 'Download', action: 'download', link: true, external: true },
            { name: 'Export', action: 'export', link: false, external: false },
        ];
    }
    ExpensesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.loading = true;
            _this.search = params['search'] || '';
            _this.sortBy = params.sortBy ? params.sortBy : 'created_at';
            _this.sortAsc = params.sortAsc ? (params.sortAsc === 'true') : false;
            _this.getExpenses();
        });
        this.route.params.subscribe(function (params) {
            _this.state = params['state'];
            _this.getExpenses();
        });
    };
    ExpensesComponent.prototype.ngOnDestroy = function () {
        if (this.expensesReq) {
            this.expensesReq.unsubscribe();
        }
    };
    ExpensesComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.expenseService.getNext();
            if (o) {
                this.loading = true;
                o.subscribe(function (expenses) {
                    _this.expenses = _this.expenses.concat(expenses);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; }, function () {
                    _this.loading = false;
                });
            }
        }
    };
    ExpensesComponent.prototype.getExpenses = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.expenses = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        delete filters['undefined'];
        if (this.expensesReq) {
            this.expensesReq.unsubscribe();
        }
        this.expensesReq = this.expenseService.getExpenses(__assign({ ordering: order, search: this.search, sent: this.state === 'draft' ? 'False' : 'True' }, lodash_1.omit(filters, 'jobDay'), query)).subscribe(function (expenses) {
            if (append) {
                _this.expenses = _this.expenses.concat(expenses);
            }
            else {
                _this.expenses = expenses;
            }
            _this.count = _this.expenseService.count;
            _this.loading = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    ExpensesComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortBy: this.sortBy, sortAsc: this.sortAsc })
        });
        this.loading = true;
        this.getExpenses({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    ExpensesComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.loading = true;
        this.getExpenses((_a = {}, _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy, _a));
    };
    ExpensesComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        queryParams['search'] = this.search;
        this.router.navigate(['expenses', 'filter', this.state], { queryParams: queryParams });
        this.getExpenses();
    };
    ExpensesComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    ExpensesComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(expense_filters_dialog_component_1.ExpenseFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        var startDate = lodash_1.get(lodash_1.find(this.filters, { key: 'startDate' }), 'value');
        if (startDate && startDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.startDate = startDate;
        }
        var endDate = lodash_1.get(lodash_1.find(this.filters, { key: 'endDate' }), 'value');
        if (endDate && endDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.endDate = endDate;
        }
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.filters, { key: 'job' }), 'value');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.filters, { key: 'customer' }), 'value');
        dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    ExpensesComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'customer_organization',
            job: 'job',
            sent: 'sent',
            startDate: 'jobday_start__gte',
            endDate: 'jobday_end__lte'
        };
        var falseyFilters = [];
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var value = filterRes[key];
            var displayValue;
            if (typeof (value) === 'boolean') {
                if (key === 'incomplete' && value) {
                    displayValue = value.toString();
                    displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
                    value = !value;
                    var filterValue = value.toString();
                    filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                    query[queryKeys[key]] = filterValue;
                }
                else if (value) {
                    value = value.toString();
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    query[queryKeys[key]] = value;
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var displayKey = key;
            var hidden = false;
            if (key === 'startDate') {
                hidden = true;
            }
            if (key === 'endDate') {
                hidden = true;
            }
            if (key === 'jobDay') {
                displayKey = 'Job Day';
            }
            var filter = {
                key: displayKey,
                value: displayValue || value,
                query: query,
                hidden: hidden
            };
            if (filter.value === 'False' || !filter.value) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.filters = lodash_1.difference(this.filters, falseyFilters);
        this.getExpenses();
    };
    ExpensesComponent.prototype.removeFilter = function (filter) {
        if (filter.key === 'Job Day') {
            lodash_1.remove(this.filters, lodash_1.find(this.filters, { key: 'Start Date' }));
            lodash_1.remove(this.filters, lodash_1.find(this.filters, { key: 'End Date' }));
        }
        lodash_1.remove(this.filters, filter);
        this.getExpenses();
    };
    ExpensesComponent.prototype.menuAction = function (action, expense) {
        switch (action) {
            case 'mark_as_paid':
                this.sendExpense(expense);
                break;
            case 'remove':
                this.removeExpense(expense);
                break;
            case 'export':
                this.createExport([expense.id]);
                break;
            case 'unlock':
                this.unlockExpense(expense);
                break;
        }
    };
    ExpensesComponent.prototype.sendExpense = function (expense) {
        var _this = this;
        if (!expense.paymentType) {
            this.capturePaymentType(expense);
        }
        else {
            this.loading = true;
            this.expenseService.send(expense).subscribe(function () {
                expense.sent = true;
                _this.loading = false;
                _this.getExpenses();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    ExpensesComponent.prototype.removeExpense = function (expense) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Remove Expense?',
            body: 'This expense will be deleted and cannot be recovered.',
            close: 'Cancel',
            accept: 'Remove'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.expenseService.remove(expense).subscribe(function (res) {
                    _this.getExpenses();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    ExpensesComponent.prototype.unlockExpense = function (expense) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unlock Expense?',
            body: 'This expense will be unlocked and can be modified.',
            close: 'Cancel',
            accept: 'Unlock'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.expenseService.unlock(expense).subscribe(function (res) {
                    _this.getExpenses();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    ExpensesComponent.prototype.capturePaymentType = function (expense) {
        var _this = this;
        this.paymentTypeDialog = this.dialog.open(expense_pay_type_dialog_component_1.ExpensePayTypeDialogComponent, {
            width: '430px'
        });
        this.paymentTypeDialog.componentInstance.expense = expense;
        this.paymentTypeDialog.componentInstance.callback = function (res) {
            expense.paymentType = res.paymentType;
            _this.sendExpense(expense);
        };
    };
    ExpensesComponent.prototype.createExport = function (expenses, section) {
        var _this = this;
        if (section === void 0) { section = null; }
        var scope = {};
        var params = new http_1.HttpParams();
        if (expenses && expenses.length) {
            Object.assign(scope, { expenses: expenses });
        }
        else if (this.allSelected) {
            Object.assign(scope, { excludeExpenses: this.excludeExpenses[section] });
            Object.keys(this.filters).map(function (key) {
                if (_this.filters[key]) {
                    var query_1 = _this.filters[key]['query'];
                    Object.keys(query_1).map(function (queryKey) {
                        params = params.set(queryKey, query_1[queryKey]);
                    });
                }
            });
            params = params.set('search', this.search);
            params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
        }
        this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
            width: '430px',
            data: {
                type: 'expenses',
                buttonText: 'Export Data to CSV',
                params: params,
                scope: scope,
                service: this.expenseService
            }
        });
    };
    ExpensesComponent.prototype.createDownload = function (expenses, section) {
        var _this = this;
        if (section === void 0) { section = null; }
        var scope = {};
        var params = new http_1.HttpParams();
        if (expenses && expenses.length) {
            Object.assign(scope, { expenses: expenses });
        }
        else if (this.allSelected) {
            Object.assign(scope, { excludeExpenses: this.excludeExpenses[section] });
            Object.keys(this.filters).map(function (key) {
                if (_this.filters[key]) {
                    var query_2 = _this.filters[key]['query'];
                    Object.keys(query_2).map(function (queryKey) {
                        params = params.set(queryKey, query_2[queryKey]);
                    });
                }
            });
            params = params.set('search', this.search);
            params = params.set('sent', this.state === 'draft' ? 'False' : 'True');
        }
        this.expenseService.downloadPdfs(scope, params).subscribe(function () {
            _this.loading = false;
            _this.confirmDialog = _this.dialog.open(index_1.RuckitConfirmDialogComponent, {
                width: '430px',
                height: '250px'
            });
            _this.confirmDialog.componentInstance.attributes = {
                title: 'Download Link Sent',
                body: 'Your zip file with the selected expense data is on its way to your inbox',
                close: 'Close',
                accept: null
            };
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    ExpensesComponent.prototype.selector = function (event, section, expense) {
        if (section === void 0) { section = 'draft'; }
        if (expense === void 0) { expense = null; }
        if (expense) {
            if (!event.target.checked) {
                expense.selected = false;
                lodash_1.pull(this.selectedExpenses[section], expense.id);
                if (this.allSelected) {
                    this.excludeExpenses[section].push(expense.id);
                    this.selectedCount[section] = (this.count - this.excludeExpenses[section].length);
                }
                else {
                    this.selectedCount[section] = this.selectedExpenses[section].length;
                }
            }
            else {
                expense.selected = true;
                if (this.allSelected[section]) {
                    lodash_1.pull(this.excludeExpenses[section], expense.id);
                    this.selectedCount[section] = (this.count - this.excludeExpenses[section].length);
                }
                else {
                    this.selectedExpenses[section].push(expense.id);
                    this.selectedCount[section] = this.selectedExpenses[section].length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected[section] = false;
                this.expenses.forEach(function (_expense) { _expense.selected = false; });
                this.selectedCount[section] = 0;
            }
            else {
                this.allSelected[section] = true;
                this.selectedCount[section] = (this.count - this.excludeExpenses[section].length);
            }
            this.selectedExpenses[section] = [];
            this.excludeExpenses[section] = [];
        }
    };
    ExpensesComponent.prototype.setSelectedAction = function (option, section) {
        switch (option.name) {
            case 'Export':
                this.createExport(this.selectedExpenses[section], section);
                break;
            case 'Download':
                this.createDownload(this.selectedExpenses[section], section);
                break;
        }
    };
    return ExpensesComponent;
}());
exports.ExpensesComponent = ExpensesComponent;
