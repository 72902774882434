import {
  Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef
} from '@angular/core';
import { Location as CommonLocation } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as moment from 'moment-timezone';
import { clone, filter } from 'lodash';
import {
  combineLatest as observableCombineLatest, timer as observableTimer,
  Subscription
} from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { parseErrors } from '../shared/api.service';
import { JobService } from './job.service';
import { JobEventService } from '../job-events/job-event.service';
import { CancelJobDialogComponent } from './cancel-job-dialog.component';
import { NewDaysCollaborationDialogComponent } from '../collaborators/new-days-collaboration-dialog.component';
import { AssignmentService } from '../assignments/assignment.service';
import { AuthenticationService } from '../shared/authentication.service';
import { EditJobDialogComponent } from './edit-job-dialog.component';
import { EditSLOrderIDDialogComponent } from './edit-slorder-id-dialog.component';
import { PublishJobDialogComponent } from './publish-job-dialog.component';
import { CompletedTripsComponent } from '../trips/completed-trips/completed-trips.component';
import { CompletedPunchCardsComponent } from '../punch-cards/completed-punch-cards.component';
import { MessageDriversDialogComponent } from '../messages/message-drivers-dialog.component';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';
import { JobEvent } from '../job-events/job-event';
import { Job } from './job';
import { Assignment } from '../assignments/assignment';
import { DropdownComponent } from '../shared';
import { ControlOption } from '../shared/control-option';
import { JobMapComponent } from '../map/job-map/job-map.component';
import { JobEventStatService } from '../job-event-stats/job-event-stat.service';
import { mapOptions } from '../shared/static-data';
import { PreferenceService } from '../preferences/preference.service';
import { Device } from '../shared/device';

@Component({
  selector: 'ruckit-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit, OnDestroy {
  device: Device = new Device();

  private statusChangeTimerSub: Subscription;
  private locationUpdateSub: Subscription;
  private jobReq: Subscription;
  private jobEventReq: Subscription;
  private jobEventsReq: Subscription;
  private jobEventsNextReq: Subscription;

  view: 'map' | 'stats' = 'stats';
  job: Job;
  jobEvent: JobEvent;
  jobEvents: JobEvent[] = [];
  jobEventsLoading = true;
  // preference: Preference = new PreferenceSerializer().fromJson({
  //   blob: { mapStyle: 'google-map-style' }
  // });
  preferenceReqs: any = {};
  preferences: any = {};
  controlState = 'trips';
  controls: ControlOption[] = [];
  hours = [];
  assignments = {
    items: [],
    errors: [],
    loading: false,
  };
  loading = true;
  errors = [];
  locationUpdates = [];
  locations = [];
  actionsDropdownOptions = [];
  actionsDropdownConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  tripConfirmDialog: MatDialogRef<any>;
  driverListOpen = false;
  jobOverviewOpen = false;
  assignmentDetails = {};
  mapReq: Subscription;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  @ViewChild('jobMap', { static: false }) jobMap: JobMapComponent;
  @ViewChild('actionsDropdown', { static: false }) actionsDropdown: DropdownComponent;
  @ViewChild(CompletedPunchCardsComponent, { static: false }) completedPunchCardsComponent: CompletedPunchCardsComponent;
  @ViewChild(CompletedTripsComponent, { static: false }) completedTripsComponent: CompletedTripsComponent;
  @ViewChild('jobEventsDropdown', { static: false }) jobEventsDropdown: DropdownComponent;

  saveJobDaysCallback = (daysAdded) => {
    if (this.jobEvent && this.jobEvent.canShare) {
      this.openNewDaysDialog(daysAdded);
    }
  }
  saveSLOrderIDCallback = (e) => {
    this.jobEvent.slorderId = e.slorderId;
  }
  sendJobCallback = (e) => {
    // Update Sent Status
  }
  publishJobCallback = (e) => {
    // Update Published Status
  }
  cancelJobCallback = (e) => {
    this.jobEvent = e.length && e[0];
  }
  viewSignaturesCallback = () => {
    // Update Signature Status Icon
  }
  messageDriversCallback = () => {
    // Update Notice Message
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private location: CommonLocation,
    private deviceDetectorService: DeviceDetectorService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    private jobEventStatService: JobEventStatService,
    private assignmentService: AssignmentService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.loading = true;
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        if (result && result.params['jobId']) {
          this.getJob(result.params['jobId'], result.params['jobEventId']);
          this.getJobEvents(result.params['jobId']);
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.locationUpdateSub) {
      try { this.locationUpdateSub.unsubscribe(); } catch (e) { }
    }
    if (this.assignments && this.assignments.items.length) {
      try {
        this.assignments.items.forEach((assignment) => {
          if (assignment.durationTimer) {
            assignment.durationTimer.unsubscribe();
          }
        });
      } catch (e) { }
    }
    if (this.statusChangeTimerSub) {
      try { this.statusChangeTimerSub.unsubscribe(); } catch (e) { }
    }
    if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
      this.jobReq.unsubscribe();
    }
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
    if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
      this.mapReq.unsubscribe();
    }
    Object.keys(this.preferenceReqs).forEach(preferenceReqKey => {
      if (this.preferenceReqs[preferenceReqKey] && typeof this.preferenceReqs[preferenceReqKey].unsubscribe === 'function') {
        try { this.preferenceReqs[preferenceReqKey].unsubscribe(); } catch (e) { }
      }
    });
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getJob(id: string, jobEventId: string = null): void {
    if (id) {
      if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
        this.jobReq.unsubscribe();
      }

      this.jobReq = this.jobService.get(id).subscribe((res) => {
        this.job = res;

        if (jobEventId) {
          this.getJobEvent(jobEventId);
        } else {
          this.getJobEvents(this.job.id, {}, true);
        }
      }, err => {
        this.errors = err;
        this.loading = false;
      });
    }
  }

  getJobEvent(id: string): void {
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }

    this.jobEventReq = this.jobEventService.getJobEvent(id).subscribe(jobEvent => {
      this.selectJobEvent(jobEvent);
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  /**
   * @param  {} jobId
   * @param  {} query = {}
   * @param  {} selectFirst = false
   * fetch available job events for new job event picker
   * sets the first jobevent available as selected
   */
  getJobEvents(jobId: string, query = {}, selectFirst = false): void {
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }

    this.jobEventsReq = this.jobEventService.listCompact({
      ordering: '-shift1_start',
      job: jobId,
      ...query
    }).subscribe(jobEvents => {
      this.jobEvents = jobEvents;
      if (selectFirst && jobEvents[0]) { this.getJobEvent(jobEvents[0].id);
      }
      this.jobEventsLoading = false;
    }, err => {
      this.errors = err;
      this.jobEventsLoading = false;
    });
  }

  getNextJobEvents(): void {
    if (this.jobEventService.nextUri) {
      if (this.jobEventsNextReq && typeof this.jobEventsNextReq.unsubscribe === 'function') {
        this.jobEventsNextReq.unsubscribe();
      }
      this.jobEventsNextReq = this.jobEventService.listNext().subscribe(results => {
        this.jobEvents = this.jobEvents.concat(results);
      }, (err) => {
        this.errors = parseErrors(err);
      });
    }
  }

  /**
   * @param  {} jobEvent
   * @returns void
   * This function is called when jobevent is changed
   */
  selectJobEvent(jobEvent: JobEvent): void {
    if (!jobEvent.job || !jobEvent.job.id) {
      this.getJobEvent(jobEvent.id);
    } else {
      this.jobEvent = jobEvent;
      if (this.jobEventsDropdown) {
        this.jobEventsDropdown.selectedOption = this.jobEvent;
        if (this.jobEvents && this.jobEvents.findIndex(j => (j.id === this.jobEvent.id)) === -1) {
          this.jobEvents.unshift(this.jobEvent);
        }
      }
      this.updateUrl();
      this.triggerMapUpdate();
      this.getAssignmentsAndStats();
      this.addActionButtons();
      this.setControlState();
    }
  }

  updateUrl(): void {
    const jobEventId = this.jobEvent && this.jobEvent.id;
    const jobId = this.jobEvent && this.jobEvent.job && this.jobEvent.job.id;
    let url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
    if (this.job && this.jobEvent) {
      this.location.replaceState(url + '/' + jobId + '/' + jobEventId);
    }
  }

  addActionButtons(): void {
    this.actionsDropdownOptions = [
      { name: 'Clone Job', button: true },
      { name: 'Message Drivers', button: true }
    ];
    if (this.jobEvent && this.jobEvent.canShare) {
      // this.actionsDropdownOptions.push({ name: 'Share to Marketplace', button: true });
    }

    if (this.jobEvent && this.jobEvent.canEdit) {
      if (this.authenticationService.hasLafargeRegion()) {
        this.actionsDropdownOptions.push({ name: 'Edit SLOrder ID', button: true });
      }
      this.actionsDropdownOptions.push({ name: 'Edit Job', button: true });
      this.actionsDropdownOptions.push({ name: 'Edit Job Days', button: true });
      this.actionsDropdownOptions.push({ name: 'Cancel Day', button: true });
    }

    if (this.jobEvent && !this.jobEvent.future) {
      this.actionsDropdownOptions.push({ name: 'Create Trip', button: true });
      this.actionsDropdownOptions.push({ name: 'Create Punch Card', button: true });
    }

    this.actionsDropdownOptions.push({ name: 'View Tickets', button: true });
  }

  addAssignmentExportActionButton(): void {
    if (this.assignments && this.assignments.items && this.assignments.items.length) {
      this.actionsDropdownOptions.splice(0, 0, { name: 'Export Assignments', button: true });
    } else {
      this.actionsDropdownOptions.splice(0, 0, { name: 'Export Assignments', button: true, disabled: true });
    }
  }

  getAssignmentsAndStats(): void {
    this.assignments.loading = true;
    let jobEventId = this.jobEvent && this.jobEvent.id;

    let effectiveRateCalculator = '';
    const enabledFeatures = this.authenticationService.enabledFeatures();
    if (enabledFeatures && enabledFeatures.includes('effectiveRateCalculator')) {
      effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
    }

    this.jobEventStatService.getStats(jobEventId, {
      calculator: effectiveRateCalculator
    }).subscribe(
      stats => {
        this.jobEvent.stats = stats;

        this.assignmentService.listAll(5, {
          jobevent: jobEventId,
          completed: 'False',
          dispatched: 'True',
          include_trips: 'True',
        }).subscribe(assignments => {
          let _jobStart = moment(clone(this.jobEvent.jobStart));
          let _jobEnd = moment(new Date(clone(this.jobEvent.jobEnd)));
          _jobEnd.add(2, 'hour');
          while (_jobStart <= _jobEnd) {
            let hour = moment(_jobStart).format('h a');
            if (!this.hours.includes(hour)) { this.hours.push(hour); }
            _jobStart.add(1, 'hour');
          }
          this.assignments.items = assignments.map((assignment) => {
            if (assignment.driver) {
              this.loadAssignmentTrip(assignment);
              this.loadAssignmentPunchCard(assignment);
            }
            return assignment;
          }).sort((a, b) => (
            (a.uniqueStart < b.uniqueStart) ? -1 : (a.uniqueStart > b.uniqueStart) ? 1 : 0
          ));
          this.addAssignmentExportActionButton();
          this.monitorStatusChanges();

          let currentMinTime: moment.Moment;
          this.assignments.items.forEach((a: Assignment) => {
            let loadingArrivalTimes: moment.Moment[] = [];
            a.trips.forEach(trip => {
              if (trip.loadingArrivalTime) { loadingArrivalTimes.push(moment(trip.loadingArrivalTime)); }
            });
            if (loadingArrivalTimes.length) {
              const assignmentMinTime = moment.min(...loadingArrivalTimes);
              const formattedAssignmentMinTime = assignmentMinTime && assignmentMinTime.format('h:mm A');
              if (!this.jobEvent.stats.firstLoad || (
                this.jobEvent.stats.firstLoad && assignmentMinTime && assignmentMinTime.isBefore(currentMinTime)
              )) {
                this.jobEvent.stats.firstLoad = a.truck.name + ' - ' + formattedAssignmentMinTime;
                currentMinTime = assignmentMinTime;
              }
            }
          });
        }, err => {
          this.assignments.errors = err;
        }, () => {
          this.assignments.loading = false;
        });
      },
      err => console.error(err)
    );
  }

  loadAssignmentTrip(assignment: Assignment): void {
    const incompleteTrips = filter(assignment.trips, { completed: false });
    if (incompleteTrips && incompleteTrips.length) {
      assignment.driver.trip = incompleteTrips[0];
      this.durationInMinutes(assignment.driver.trip);
    }
  }

  loadAssignmentPunchCard(assignment: Assignment): void {
    if (this.jobEvent && assignment.punchCards && assignment.punchCards.length) {
      let _punchCard = assignment.punchCards[0];
      let _punchCardStart = moment(clone(_punchCard.startTimeTimestamp));
      let _punchCardEnd = moment(clone(_punchCard.endTimeTimestamp));
      let _start = moment(clone(this.jobEvent.jobStart));
      let _end = moment(new Date(clone(this.jobEvent.jobEnd)));

      while (_punchCardStart <= _start) {
        _start.subtract(1, 'hour');
        let hour = moment(_start).format('h a');
        if (!this.hours.includes(hour)) { this.hours.unshift(hour); }
      }
      while (_punchCardEnd > _end) {
        _end.add(1, 'hour');
        let hour = moment(_end).format('h a');
        if (!this.hours.includes(hour)) { this.hours.push(hour); }
      }
      assignment.driver.punchCard = _punchCard;
      this.durationInMinutes(assignment.driver.punchCard);
    }
  }

  triggerMapUpdate(): void {
    if (this.jobMap) { this.jobMap.triggerMapUpdate(); }
  }

  isDispatchable(): boolean {
    if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.project && this.jobEvent.job.project.customerOrganization) {
      const organization = this.authenticationService.getOrganization();
      if (organization && organization.id !== this.jobEvent.job.project.customerOrganization.id) {
        let endDate = new Date(this.jobEvent.jobEnd);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (endDate >= today) {
          return true;
        }
      }
    }
    return false;
  }

  isSendable(): boolean {
    // TODO: What scenarios would render a Job "unsendable"?
    return true;
  }

  openShareJob(): void {
    if (this.isSendable()) {
      const dialog = this.dialog.open(PublishJobDialogComponent, {
        width: '870px',
        data: { job: this.job, jobevent: this.jobEvent }
      });
      dialog.componentInstance.callback = this.publishJobCallback;
    }
  }

  openJobDays(): void {
    const dialog = this.dialog.open(EditJobDialogComponent, {
      width: '320px',
      data: { job: this.job }
    });
    dialog.componentInstance.callback = this.saveJobDaysCallback;
  }

  openEditSLOrderID(): void {
    const dialog = this.dialog.open(EditSLOrderIDDialogComponent, {
      width: '320px',
      data: { jobEvent: this.jobEvent }
    });
    dialog.componentInstance.callback = this.saveSLOrderIDCallback;
  }

  openCancelJob(): void {
    if (!this.jobEvent.cancelled) {
      const dialog = this.dialog.open(CancelJobDialogComponent, {
        width: '430px',
        data: { jobEventIds: [this.jobEvent.id] }
      });
      dialog.componentInstance.callback = this.cancelJobCallback;
    }
  }

  openCloneJob(): void {
    this.router.navigate(['/jobs/clone/' + this.job.id]);
  }

  openEditJob(): void {
    this.router.navigate([`/jobs/${this.job.id}/edit`], {
      queryParams: {
        returnTo: `/jobs/${this.job.id}/${this.jobEvent.id}`
      }
    });
  }

  openNewDaysDialog(daysAdded): void {
    if (daysAdded && daysAdded.length > 0) {
      const dialog = this.dialog.open(NewDaysCollaborationDialogComponent, {
        width: '850px'
      });
      dialog.componentInstance.jobEvent = this.jobEvent;
      dialog.componentInstance.daysAdded = daysAdded;
    }
  }

  openMessageDrivers(): void {
    const dialog = this.dialog.open(MessageDriversDialogComponent, {
      width: '870px'
    });
    if (dialog && this.jobEvent) {
      dialog.componentInstance.jobEventId = this.jobEvent.id;
      dialog.componentInstance.callback = this.messageDriversCallback;
    }
  }

  openExportAssignments(): void {
    let filters = { jobevent: this.jobEvent.id };
    this.assignmentService.export({}, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{ type: 'assignments' }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          params: params,
          service: this.assignmentService,
          buttonText: 'Try to Export Again'
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  openCreateTrip(): void {
    this.router.navigate(['/trips/new/', this.job.id, this.jobEvent.id]);
  }

  openCreatePunchCard(): void {
    this.router.navigate(['/punchcards/new/', this.job.id, this.jobEvent.id]);
  }

  viewTickets(jobEvent: JobEvent): void {
    console.log(jobEvent);
    this.router.navigate(
      ['/orders', jobEvent.id, 'tickets'],
      {
        queryParams: { resizeTo: this.router.url }
      }
    );
  }

  setSelectedAction(option: any, jobEvent?: JobEvent): void {
    switch (option.name) {
      case 'Share to Marketplace':
        this.openShareJob();
        break;
      case 'Clone Job':
        this.openCloneJob();
        break;
      case 'Edit Job':
        this.openEditJob();
        break;
      case 'Message Drivers':
        this.openMessageDrivers();
        break;
      case 'Export Assignments':
        this.openExportAssignments();
        break;
      case 'Edit Job Days':
        this.openJobDays();
        break;
      case 'Edit SLOrder ID':
        this.openEditSLOrderID();
        break;
      case 'Cancel Day':
        this.openCancelJob();
        break;
      case 'Create Trip':
        this.openCreateTrip();
        break;
      case 'Create Punch Card':
        this.openCreatePunchCard();
        break;
      case 'View Tickets':
        this.viewTickets(jobEvent);
        break;
    }
    this.actionsDropdown.deselectAll();
  }

  openDriverList(): void {
    this.driverListOpen = !this.driverListOpen;
  }

  openJobOverview(): void {
    this.jobOverviewOpen = !this.jobOverviewOpen;
  }

  durationInMinutes(obj): void {
    obj.durationTimer = observableTimer(1, 60000);
    this.allSubscriptionsToUnsubscribe.push(
      obj.durationTimer.subscribe(t => {
        let duration = moment().diff(obj.startTimeTimestamp, 'minutes') + ' mins';
        obj.duration = duration;
      })
    );
  }

  monitorStatusChanges(): void {
    this.assignmentService.listAllUpdate(5, 120000, {
      jobevent: this.jobEvent && this.jobEvent.id,
      completed: 'False',
      dispatched: 'True',
      include_trips: 'True',
    }).subscribe(assignments => {
      this.assignments.items = assignments.map((_assignment) => {
        if (_assignment.driver) {
          this.loadAssignmentTrip(_assignment);
          this.loadAssignmentPunchCard(_assignment);
        }
        return _assignment;
      }).sort((a, b) => (
        (a.uniqueStart < b.uniqueStart) ? -1 : (a.uniqueStart > b.uniqueStart) ? 1 : 0
      ));
    }, err => console.error(err));
  }

  onScroll(e): void {
    if (this.job.invoiceType === 'hour' && this.completedPunchCardsComponent) {
      this.completedPunchCardsComponent.onScroll(e);
    } else if (this.completedTripsComponent) {
      this.completedTripsComponent.onScroll(e);
    }
  }

  openAssignmentDetails(update, index: number): void {
    if (this.jobMap) { this.jobMap.openAssignmentDetails(update, index); }
  }

  setControlState(): void {
    this.controls = [];
    if (this.jobEvent) {
      this.controls.push(
        new ControlOption({ name: 'trips', label: 'Trips', classes: 'trips-toggle' })
      );
      if (this.jobEvent.hasGeofences) {
        this.controls.push(
          new ControlOption({ name: 'gps', label: 'GPS', classes: 'gps-toggle' }),
          new ControlOption({ name: 'timeline', label: 'Timeline', classes: 'timeline-toggle' })
        );
      } else {
        this.controls.push(
          new ControlOption({ name: 'timeline', label: 'Timeline', classes: 'timeline-toggle' })
        );
      }
      this.controlState = this.jobEvent.invoiceType === 'hour' ? 'timeline' : 'trips';
      this.controls[this.controls.findIndex(c => (
        this.jobEvent.invoiceType === 'hour' ? c.name === 'timeline' : c.name === 'trips'
      ))].selected = true;
    }
  }

  /**
   * @param  {} control
   * @param  {} event
   * @returns void
   * This function is called on toggling the segmented controls: GPS, Trips, or Hourly
   */
  onSelectToggle(control: ControlOption, event: MouseEvent): void {
    const checkbox = event.target as HTMLInputElement;
    this.controls.forEach(_control => { _control.selected = false; });
    control.selected = checkbox.checked;
    this.controlState = control.name;
  }
}
