"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".dropdown[_ngcontent-%COMP%]{width:100%}.form-content[_ngcontent-%COMP%]{margin-top:30px}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{padding:0;position:relative;flex-grow:1;min-width:50%}form[_ngcontent-%COMP%]   .field-group.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .field-group.half-width[_ngcontent-%COMP%]{min-width:25%;margin-left:20px}form[_ngcontent-%COMP%]   notification[_ngcontent-%COMP%]{display:block;margin-top:20px}p[_ngcontent-%COMP%]{margin-bottom:20px}"];
exports.styles = styles;
