import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timeFromNow'})

export class TimeFromNowPipe implements PipeTransform {
  transform(input: any): any {
    if (!input) { return ''; }

    let inputTime = new Date(input);
    let currentTime = new Date();
    let timeDiff = Math.abs(currentTime.getTime() - inputTime.getTime());

    if (timeDiff > 31556952000) {
      return Math.floor(timeDiff / 31556952000) + 'y';
    } else if (timeDiff > 2592000000) {
      return Math.floor(timeDiff / 2592000000) + 'mo';
    } else if (timeDiff > 604800000) {
      return Math.floor(timeDiff / 604800000) + 'w';
    } else if (timeDiff > 86400000) {
      return Math.floor(timeDiff / 86400000) + 'd';
    } else if (timeDiff > 3600000) {
      return Math.floor(timeDiff / 3600000) + 'h';
    } else {
      return Math.floor(timeDiff / 60000) + 'm';
    }
  }
}
