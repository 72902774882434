<div class="custom-fields">
  <div class="custom-fields__header">
    <h2 translate>Custom Fields</h2>
  </div>
  <div class="custom-fields__content">
    <div class="custom-fields__content__blurb">
      <div translate class="custom-fields__content__blurb--head">Add Custom Fields</div>
      <p translate>
        Add user define key value pairs to a Truck, Job, or Driver and allow these key value pairs to be exported along
        with the revenue in csv export.
      </p>
    </div>
    <form #customFieldsForm="ngForm" (ngSubmit)="onSubmit()" class="custom-fields__form">
      <button translate type="button" (click)="addField()" class="btn btn-default custom-fields__form__add">
        Add Field
      </button>
      <div class="custom-fields__form__fields scrollable" infiniteScroll [infiniteScrollDistance]="2"  [scrollWindow]="false"
      [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <div class="custom-fields__form__fields__labels">
          <div class="custom-fields__form__fields__labels__display">Display Name</div>
          <div class="custom-fields__form__fields__labels__kind">Custom Field Type</div>
        </div>
        <div *ngFor="let field of fields; let i = index" class="custom-fields__form__fields__field">
          <button type="button" (click)="onDelete(i)" mat-icon-button [disabled]="loading">
            <i class="icomoon icon-delete"></i>
          </button>
          <input
            [name]="'field_name_' + i"
            [value]="field.displayName"
            [(ngModel)]="field.displayName"
            type="text"
            name="field"
            placeholder="{{ 'Field Name' | translate }}"
            [disabled]="loading"
            required
          >
          <div class="custom-field__select">
            <select required [name]="'field_kind_' + i" [(ngModel)]="field.kind" [disabled]="loading">
              <option value="truck" translate>Truck</option>
              <option value="driver" translate>Driver</option>
              <option value="job" translate>Job</option>
              <option value="jobeventshare" translate>Share</option>
            </select>
          </div>
        </div>
      </div>
      <div class="custom-fields__form__submit">
        <button
          class="btn btn-primary"
          translate
          [disabled]="!customFieldsForm.form.valid || loading"
          type="submit"
          [ngClass]="{loading: loading}"
        >
          Save Fields
        </button>
      </div>
    </form>
  </div>
</div>
