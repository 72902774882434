<div class="completed-trips">
  <h3 translate>Completed Trips</h3>
  <div *ngFor="let trip of trips">
    <div class="trip">
      <div class="expand" (click)="expandTrip(trip)">
        <i *ngIf="!expandedTrip(trip)" class="icon-down-arrow"></i>
        <i *ngIf="expandedTrip(trip)" class="icon-up-arrow"></i>
      </div>
      <div class="driver">
        <div class="driver-info">
          <div class="trip-status" *ngIf="trip.displayableStatus">
            <div class="label-{{trip.administrativeStatus}}">{{trip.administrativeStatus}}</div>
          </div>
          <div class="info driver-details">
            <driver-name [driver]="trip.customDriver" (contextmenu)="openContextMenu($event, trip.customDriver)"></driver-name>
            <i *ngIf="trip.origin === 'scale-sync'" class="icon-sync"></i>
            <div class="phone driver-phone">{{ trip.driverPhone }}</div>
            <div *ngIf="trip.jobDate && trip.driverId">
              <a class="replay-link"
                [routerLink]="['/replay']"
                [queryParams]="{ date: trip.jobDate | moment: 'YYYYMMDD', driver: trip.driverId }"
                translate
              >View Replay</a>
            </div>
          </div>
        </div>

        <div class="truck-photo" [ngClass]="{empty: !trip.truck}">
          <div class="image" *ngIf="trip.truck">
            <img *ngIf="trip.truck.image" [src]="trip.truck.jobDetailImage" />
            <i *ngIf="! trip.truck.image" class="icon-fleet"></i>
          </div>
        </div>
        <div class="info">
          <div class="name">{{ trip.truckName }}</div>
          <div class="phone">{{ trip.truckType }}</div>
        </div>
        <div class="trip-time info">
          <div class="length">{{ trip.completedTripTime }}</div>
          <div class="times">{{ trip.startTime }} - {{ trip.endTime }}</div>
        </div>
        <div class="weight info">
          <div class="length" [ngClass]="{'missing-required': trip.weight !== 0 && !trip.weight}">
            {{ trip.weight }}
            <span *ngIf="trip" [ngPlural]="trip.weight || 0">
              <ng-template ngPluralCase="=0">{{ trip.haulWeightUnit + 's' | translate }}</ng-template>
              <ng-template ngPluralCase="=1">{{ trip.haulWeightUnit | translate }}</ng-template>
              <ng-template ngPluralCase="other">{{ trip.haulWeightUnit + 's' | translate }}</ng-template>
            </span>
          </div>
          <div class="label">Weight</div>
        </div>
        <ng-container *ngIf="trip.loadingAttachments | multipleAttachmentType: trip.unloadingAttachments:signatureImageType as allImages">
          <div class="signature-photo" [ngClass]="{'no-signatures': !allImages.loadingImages.length && !allImages.unloadingImages.length && trip.signatureImagesRequired}" (click)="openSignatures(trip)">
            <span *ngIf="!allImages.loadingImages.length && !allImages.unloadingImages.length">
              <i class="icon-info"></i>
              <br /> No Signature
            </span>
            <div *ngIf="allImages.loadingImages.length || allImages.unloadingImages.length">
              <img *ngIf="allImages.loadingImages.length" [src]="allImages.loadingImages[0].file" />
              <img *ngIf="allImages.unloadingImages.length" [src]="allImages.unloadingImages[0].file" />
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="trip.loadingAttachments | multipleAttachmentType: trip.unloadingAttachments:ticketImageType as allImages">
          <div class="ticket-photo" [ngClass]="{'no-tickets': !allImages.loadingImages.length && !allImages.unloadingImages.length}">
            <a [routerLink]="trip.url('edit')" [queryParams]="{ returnTo: '/jobs/' + this.jobEvent.job.id + '/' + this.jobEvent.id }">
              <div class="ticket-photo" *ngIf="!allImages.loadingImages.length && !allImages.unloadingImages.length && trip.ticketImagesRequired">
                <div class="value" class="missing-required">&nbsp;</div>
                <div class="label" translate>No Ticket</div>
              </div>
              <div class="ticket-photo" *ngIf="allImages.loadingImages.length || allImages.unloadingImages.length">
                <span>
                  <i class="icon-ticket"></i>
                </span>
              </div>
              <div *ngIf="trip.ticketNumbers" class="ticket-photo label">{{ trip.ticketNumbers }}</div>
            </a>
          </div>
        </ng-container>
        <div class="route">
          <a [href]="trip.fullsizeMapImageURL" target="_blank">
            <img [src]="trip.mapImageURL" *ngIf="trip.mapImageURL" />
          </a>
        </div>
        <div class="trip-actions">
          <action-menu icon="icon-more">
            <ng-container *ngFor="let option of trip.filterOptions(menuOptions)">
              <a *ngIf="option.link" mat-menu-item [routerLink]="trip.url(option.action)" [queryParams]="{ returnTo: '/jobs/' + this.jobEvent.job.id + '/' + this.jobEvent.id }">{{ option.name }}</a>
              <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, trip)">{{ option.name }}</a>
            </ng-container>
          </action-menu>
        </div>
      </div>
    </div>

    <ruckit-trip-times [trip]="trip" *ngIf="expandedTrip(trip)"></ruckit-trip-times>
  </div>

  <app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>

  <no-results itemName="Completed Trips"
              [results]="trips && trips.length" [loading]="loading"
              [styles]="{'min-height': '180px', 'padding-top': '15px'}"
              [bordered]="true" [hasAction]="false" [hasAddText]="false"></no-results>

  <my-loader *ngIf="loading" [ngClass]="{'full-height': trips.length === 0}"></my-loader>
</div>
