<form #driverUnassigned="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Assignment Error</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <p *ngFor="let err of errors">
        <ng-container *ngIf="err.driverName || err.truckName">
          {{err.driverName + ' - ' + err.truckName +  ' : '  + err.error}}
        </ng-container>
        <ng-container *ngIf="!err.driverName && !err.truckName">
          {{err.error}}
        </ng-container>
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="dialogRef.close(); false" [disabled]="loading"
      [ngClass]="{loading:loading}" translate>
      Okay
    </button>
  </div>
</form>
