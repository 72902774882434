<div class="modal-container">
  <nav mat-tab-nav-bar>
    <span mat-tab-link *ngFor="let tab of tabs" (click)="activeTab = tab" [active]="activeTab === tab" translate>
      {{tab}}
    </span>
  </nav>
  <div class="modal-actions tickets-modal-actions">
    <ng-container *ngIf="data.ticket.reviewedStatus; else closeButton">
      <ng-container
        *ngIf="(data.ticket.reviewedStatus !== 'approved' && data.ticket.reviewedStatus !== 'rejected') || statusMode; else changeStatus">
        <button class="btn btn-delete" (click)="reject()" translate>
          Reject
        </button>
        <button class="btn btn-success" (click)="accept()" translate>
          Approve
        </button>
      </ng-container>
      <ng-template #changeStatus>
        <button class="btn btn-primary-alt" (click)="statusMode = true" translate>
          Change Status
        </button>
      </ng-template>
    </ng-container>
    <ng-template #closeButton>
      <button class="btn btn-primary" (click)="close()" translate>
        Close
      </button>
    </ng-template>
    <button *ngIf="enabledFeatures.includes('hasTicketQcVt')" class="btn btn-primary full-width"
      (click)="openTicketReview()" translate>
      Enter QC / VT
    </button>
  </div>
  <div mat-dialog-content class="content" *ngIf="activeTab === 'Ticket Details'">
    <notification *ngFor="let error of errors">{{ error | translate }}</notification>
    <p class="large status {{ data.ticket.reviewedStatus }}">{{ data.ticket.reviewedStatus | titlecase | translate }}</p>
    <div class="break"></div>
    <p translate>Loading Ticket </p>
    <p class="large"> {{ data.ticket.loadingTicketNumber ? '#'+data.ticket.loadingTicketNumber : 'N/A' }}</p>
    <div class="break"></div>
    <p translate>Loading Weight</p>
    <p class="large">{{ data.ticket.loadingWeight }}</p>
    <div class="break"></div>
    <p translate>Loading Time</p>
    <p class="large">{{ data.ticket.loadingCompleteDatetime | moment: 'MM/DD/YYYY h:mm a' }}</p>
    <div class="break"></div>
    <p translate>Unloading Ticket </p>
    <p class="large"> {{ data.ticket.unloadingTicketNumber ? '#'+data.ticket.unloadingTicketNumber :  'N/A' }}</p>
    <div class="break"></div>
    <p translate>Unloading Weight</p>
    <p class="large">{{ data.ticket.unloadingWeight }}</p>
    <div class="break"></div>
    <p translate>Unloading Time</p>
    <p class="large">{{ data.ticket.unloadingCompleteDatetime | moment: 'MM/DD/YYYY h:mm a' }}</p>
    <div class="break"></div>
    <p translate>Truck</p>
    <p class="large">{{ data.ticket.truckName }}</p>
    <div class="break"></div>
    <p translate>Driver</p>
    <p class="large">{{ data.ticket.driver }}</p>
    <div class="break"></div>
    <p translate>Job</p>
    <p class="large">{{ data.jobEvent && data.jobEvent.jobDisplayName }}</p>
    <div class="break"></div>
    <p translate>Job ID</p>
    <p class="large">{{ data.jobEvent && data.jobEvent.job && data.jobEvent.job.jobNumber }}</p>
    <div class="break"></div>
    <p translate>Plant</p>
    <p class="large">{{ data.jobEvent && data.jobEvent.job && data.jobEvent.job.startLocation &&
      data.jobEvent.job.startLocation.name }}</p>
    <div class="break"></div>
    <p translate>Material</p>
    <p class="large">{{ data.jobEvent && data.jobEvent.job && data.jobEvent.job.material }}</p>
    <div class="break"></div>
    <p translate>Load Status</p>
    <p class="large">{{ orderService.getLoadStatus(data.ticket.tripStatus) | translate }}</p>
  </div>

  <div mat-dialog-content class="content" *ngIf="activeTab === 'Ticket Images'">
    <div class="image-container">
      <a *ngIf="data.ticket.loadingTicketImage" [href]="data.ticket.loadingTicketImage" target="_blank">
        <img [src]="data.ticket.loadingTicketImage">
      </a>
      <a *ngIf="data.ticket.unloadingTicketImage" [href]="data.ticket.unloadingTicketImage" target="_blank">
        <img [src]="data.ticket.unloadingTicketImage">
      </a>
      <i *ngIf="!data.ticket.loadingTicketImage && !data.ticket.unloadingTicketImage" class="icon-no-results"></i>
    </div>
    <div class="break"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-close" (click)="dialogRef.close()" translate>
      <i class="icon-close"></i>
    </button>
  </div>
</div>