import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

import { CollaboratorService } from '../collaborator.service';
import { JobEvent } from '../../job-events/job-event';
import { parseErrors } from '../../shared/api.service';
import { CollaborationTemplate } from '../collaboration-template';

@Component({
  selector: 'send-collaborator-notes-dialog',
  templateUrl: './send-collaborator-notes-dialog.component.html',
  styleUrls: ['./send-collaborator-notes-dialog.component.scss']
})
export class SendCollaboratorNotesDialogComponent {
  @Input() jobEvent: JobEvent;
  @Input() allSelected = false;
  @Input() selectedShareIds: string[] = [];
  @Input() excludedShareIds: string[] = [];
  notes: string;
  loading = false;
  errors = [];
  callback: Function;
  jobEvents: JobEvent[] = [];
  jobEventsReq: Subscription;
  jobEventReqs: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<SendCollaboratorNotesDialogComponent>,
    private collaboratorService: CollaboratorService
  ) { }

  submit(): void {
    this.loading = true;

    if (this.jobEvent) {
      const collaboration = new CollaborationTemplate({ notes: this.notes });
      this.collaboratorService.bulkSave(
        this.jobEvent.id, [collaboration]
      ).subscribe(res => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }
}
