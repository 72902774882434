import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { NewShiftComponent } from './new-shift/new-shift.component';
import { ShiftsComponent } from './shifts/shifts.component';

const routes: Routes = [
  { path: 'new', component: NewShiftComponent, canActivate: [AuthGuard] },
  { path: '', component: ShiftsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShiftsRoutingModule {}
