<form #effectiveRate="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Effective Rate</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">

      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group rate" fxFlex="50">
          <label translate>Rate</label>
          <div class="input-group" data-prefix="$">
            <input type="number" name="rate" [min]="0" [(ngModel)]="model.rate" />
            <div>
              <input type="hidden" name="rate_type" [(ngModel)]="model.rateType" />
              <dropdown [options]="rateTypeOptions"
                        title="-"
                        [config]="typeConfig"
                        [selectedOption]="model.rateType === 'hour' ? rateTypeOptions[0] : rateTypeOptions[1]"
                        (onSelect)="rateUnitChange($event, effectiveRate)">
              </dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row" fxLayout="column" fxLayoutAlign="center stretch">
        <div class="field-group effectiveTons" fxFlex="100">
          <label translate>Effective Tons</label>
          <input type="hidden" name="effective_tons" [(ngModel)]="model.effectiveTons" />
          <dropdown [options]="effectiveTonsOptions"
                    title="-"
                    [config]="typeConfig"
                    [selectedOption]="effectiveTonsOptions[model.effectiveTons]"
                    (onSelect)="effectiveTonsChange($event, effectiveRate)">
          </dropdown>
        </div>
      </div>

      <div class="field-row" fxLayout="column" fxLayoutAlign="center stretch">
        <div class="field-group effectiveTime" fxFlex="100">
          <label translate>Effective Time</label>
          <input type="hidden" name="effective_time" [(ngModel)]="model.effectiveTime" />
          <dropdown [options]="effectiveTimeOptions"
                    title="-"
                    [config]="typeConfig"
                    [selectedOption]="effectiveTimeOptions[model.effectiveTime]"
                    (onSelect)="effectiveTimeChange($event, effectiveRate)">
          </dropdown>
        </div>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary"
            [ngClass]="{loading: loading}"
            (click)="submit(effectiveRate); false"
            [disabled]="loading || !effectiveRate.form.valid" translate>
      Save
    </button>
  </div>
</form>