"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
// libraries
var forms_1 = require("@angular/forms");
var core_2 = require("@ngx-translate/core");
var lodash_1 = require("lodash");
// services
var custom_field_service_1 = require("../../custom-fields/custom-field.service");
var job_event_share_service_1 = require("../../job-event-shares/job-event-share.service");
var api_service_1 = require("../../shared/api.service");
var index_1 = require("../../shared/index");
var units_of_measure_service_1 = require("../../units/units-of-measure.service");
// types
var custom_field_1 = require("../../custom-fields/custom-field");
var job_load_service_1 = require("../../dispatch/dispatch-by-job/job-load.service");
var job_event_1 = require("../../job-events/job-event");
// components
var dialogs_1 = require("../../shared/dialogs");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var add_collaborators_dialog_component_1 = require("../add-collaborators/add-collaborators-dialog.component");
var edit_collaborators_dialog_component_1 = require("../edit-collaborators/edit-collaborators-dialog.component");
var extend_collaboration_dialog_component_1 = require("../extend-collaboration-dialog.component");
// constants
var app_constants_1 = require("../../app.constants");
var collaborator_service_1 = require("../collaborator.service");
var columns_1 = require("./columns");
var CollaboratorListComponent = /** @class */ (function () {
    function CollaboratorListComponent(dialog, authenticationService, jobEventShareService, collaboratorService, customFieldService, translationService, unitsOfMeasureService, jobLoadService) {
        var _this = this;
        this.dialog = dialog;
        this.authenticationService = authenticationService;
        this.jobEventShareService = jobEventShareService;
        this.collaboratorService = collaboratorService;
        this.customFieldService = customFieldService;
        this.translationService = translationService;
        this.unitsOfMeasureService = unitsOfMeasureService;
        this.jobLoadService = jobLoadService;
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS().slice();
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.loading = false;
        this.hasLoadListsEnabled = true;
        this.actionMenuDisabled = true;
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            service: job_event_share_service_1.JobEventShareService,
            customHeight: true,
            query: {},
            collectionTitle: this.translationService.instant('Collaborators'),
            noResultsText: this.translationService.instant('a collaborator'),
            hasNoResultsAction: false,
            sortBy: 'created_at',
            sortDirection: 'desc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false }
            ]
        };
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Edit Selected'), action: 'edit', link: false },
        ];
        this.rateTrackingConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.originalRecords = [];
        this.customFields = [];
        this.unitsOfMeasure = [];
        this.withRecords = false;
        this.loadList = [];
        this.modifyCollaboratorsCallback = function () {
            _this.reloadRecords();
        };
        this.extendCollaborationCallback = function (collaborator) {
            if (_this.fancyTable) {
                _this.fancyTable.updateTable(collaborator);
            }
            _this.reloadRecords();
        };
    }
    CollaboratorListComponent.prototype.ngOnInit = function () {
        var hasLoadListsEnabled = this.authenticationService.hasLoadListsEnabled();
        this.hasLoadListsEnabled = hasLoadListsEnabled;
        this.setColumns(hasLoadListsEnabled);
        this.user = this.authenticationService.user();
        this.userOrgId = this.user && this.user.organization && this.user.organization.id;
        this.jobOrgId = this.jobEvent && this.jobEvent.ownerOrganization && this.jobEvent.ownerOrganization.id;
        this.tableConfig.query = __assign({}, this.tableConfig.query, {
            jobevent: this.jobEvent && this.jobEvent.id
        });
        this.getUnitsOfMeasure();
        this.getCustomFields();
        if (this.userOrgId && this.jobOrgId && this.userOrgId === this.jobOrgId) {
            // Do nothing
        }
        else {
            this.availableColumns = this.availableColumns.filter(function (column) { return column.key !== 'internal-notes'; });
            this.displayedColumns = this.displayedColumns.filter(function (column) { return column !== 'internal-notes'; });
        }
        if (hasLoadListsEnabled) {
            this.getLoadList(this.jobEvent.id);
        }
    };
    CollaboratorListComponent.prototype.setColumns = function (hasLoadListsEnabled) {
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService, hasLoadListsEnabled).slice();
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS(hasLoadListsEnabled).slice();
    };
    CollaboratorListComponent.prototype.getLoadList = function (jobEventId) {
        var _this = this;
        if (this.loadListReq && typeof this.loadListReq.unsubscribe === 'function') {
            this.loadListReq.unsubscribe();
        }
        this.jobLoadService.getLoads(jobEventId).subscribe(function (results) {
            _this.loadList = results;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    CollaboratorListComponent.prototype.loadDropdownSelectionChanged = function (event, load, jobEventShare) {
        var loadSchedule = jobEventShare.loadSchedule;
        var existingLoad = loadSchedule.findIndex(function (l) { return l.loadNumber === load.loadNumber; });
        if (event.checked) {
            if (existingLoad === -1) {
                loadSchedule.push(load);
            }
        }
        else {
            if (existingLoad > -1) {
                loadSchedule.splice(existingLoad, 1);
            }
        }
        loadSchedule.sort(function (a, b) { return a.loadNumber - b.loadNumber; });
    };
    CollaboratorListComponent.prototype.isLoadScheduled = function (loadList, loadNumber) {
        if (loadList === void 0) { loadList = []; }
        return loadList && loadList.length ? loadList.filter(function (l) { return l.loadNumber === loadNumber; }).length > 0 : false;
    };
    CollaboratorListComponent.prototype.reloadRecords = function () {
        if (this.fancyTable) {
            this.fancyTable.getRecords();
        }
    };
    CollaboratorListComponent.prototype.openExtendCollaboration = function (jobEventShare) {
        var dialog = this.dialog.open(extend_collaboration_dialog_component_1.ExtendCollaborationDialogComponent, {
            width: '420px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.collaborator = jobEventShare;
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.callback = this.extendCollaborationCallback;
        }
    };
    CollaboratorListComponent.prototype.toggleEditAction = function (jobEventShare) {
        var originalRecord = lodash_1.find(this.originalRecords, { id: jobEventShare.id });
        if (jobEventShare.editing && originalRecord) {
            lodash_1.pull(this.originalRecords, originalRecord);
            if (this.fancyTable) {
                this.fancyTable.updateTable(originalRecord);
            }
        }
        else {
            this.originalRecords.push(lodash_1.clone(jobEventShare));
            jobEventShare.editing = true;
            if (!jobEventShare.requestedUnit) {
                jobEventShare.requestedUnit = this.unitsOfMeasure && lodash_1.find(this.unitsOfMeasure, { key: 'trucks' });
            }
        }
    };
    CollaboratorListComponent.prototype.deleteCollaborator = function (jobEventShare) {
        var _this = this;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, app_constants_1.DEFAULT_DIALOG_SIZE);
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Collaborator?'),
            body: this.translationService.instant('This collaborator will be removed for this day only.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.jobEventShareService.removeCollaboration(_this.jobEvent.id, jobEventShare.organizationId).subscribe(function () {
                    if (jobEventShare.status === 'pending') {
                        _this.fancyTable.removeRecord(jobEventShare.id);
                    }
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    CollaboratorListComponent.prototype.openAddCollaborators = function () {
        var dialog = this.dialog.open(add_collaborators_dialog_component_1.AddCollaboratorsDialogComponent, {
            width: '1100px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
            dialog.componentInstance.hasLoadListsEnabled = this.hasLoadListsEnabled;
            dialog.componentInstance.loadList = this.loadList;
            dialog.componentInstance.callback = this.modifyCollaboratorsCallback;
        }
    };
    CollaboratorListComponent.prototype.openEditCollaborators = function (selectedShareIds, excludedShareIds) {
        if (selectedShareIds === void 0) { selectedShareIds = []; }
        if (excludedShareIds === void 0) { excludedShareIds = []; }
        var dialog = this.dialog.open(edit_collaborators_dialog_component_1.EditCollaboratorsDialogComponent, {
            width: '790px'
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.allSelected = this.fancyTable.allSelected;
            dialog.componentInstance.selectedShareIds = selectedShareIds;
            dialog.componentInstance.excludedShareIds = excludedShareIds;
            dialog.componentInstance.brokerRateKey = this.brokerRateCodeKey;
            dialog.componentInstance.hasLoadListsEnabled = this.hasLoadListsEnabled;
            dialog.componentInstance.loadList = this.loadList;
            dialog.componentInstance.callback = this.modifyCollaboratorsCallback;
        }
    };
    CollaboratorListComponent.prototype.setSelectedAction = function (collaborator, option, form) {
        collaborator.invoiceType = option.value;
        if (form && form.controls && form.controls['invoice_rate']) {
            form.controls['invoice_rate'].markAsDirty();
        }
    };
    CollaboratorListComponent.prototype.setSelectedBulkAction = function (option) {
        var selectedShareIds, excludedShareIds;
        if (this.fancyTable) {
            if (!this.fancyTable.allSelected) {
                selectedShareIds = this.fancyTable.selection.selected.map(function (share) { return share.id; });
            }
            if (this.fancyTable.allSelected) {
                excludedShareIds = this.fancyTable.exclusion.selected.map(function (share) { return share.id; });
            }
        }
        switch (option.action) {
            case 'edit':
                this.openEditCollaborators(selectedShareIds, excludedShareIds);
                break;
        }
    };
    CollaboratorListComponent.prototype.validMultipliers = function (jobEventShare) {
        var _this = this;
        jobEventShare.multipliers.forEach(function (multiplier) {
            if (multiplier.haulPercentage < 0 || multiplier.haulPercentage > 100) {
                _this.errors = [
                    _this.translationService.instant('Ensure any percentages are between 0 and 100.')
                ];
                return false;
            }
        });
        return true;
    };
    CollaboratorListComponent.prototype.submit = function (jobEventShare, range) {
        var _this = this;
        if (range === void 0) { range = null; }
        if (range === 'all') {
            jobEventShare['allDays'] = true;
        }
        else if (range === 'future') {
            jobEventShare['future'] = true;
        }
        else {
            jobEventShare['allDays'] = false;
            jobEventShare['future'] = false;
        }
        if (jobEventShare.multipliers && jobEventShare.multipliers.length > 0) {
            jobEventShare['invoiceType'] = this.jobEvent.invoiceType;
            if (this.validMultipliers(jobEventShare) === false) {
                return;
            }
        }
        if (!this.jobEvent || !this.jobEvent.id) {
            return;
        }
        jobEventShare.brokerRateCodeKey = this.brokerRateCodeKey;
        if (jobEventShare.invoiceType === 'weight') {
            jobEventShare.invoiceWeightUnit = jobEventShare.weightOption.value;
        }
        if (jobEventShare.requestedUnit && jobEventShare.requestedUnit.key === 'trucks') {
            jobEventShare.numTrucks = jobEventShare.requestedAmount;
        }
        else {
            jobEventShare.numTrucks = null;
        }
        var updatedJobEventShare = __assign({}, jobEventShare, { requestedUnit: jobEventShare.requestedUnit ? jobEventShare.requestedUnit.value : null });
        // jobEventShareService not working as it should - loadSchedule doesn't get saved.
        // using collaboratorService instead
        var collaborations = [
            __assign({}, updatedJobEventShare.collaboration, updatedJobEventShare, { jobevents: [this.jobEvent.id], loadSchedule: updatedJobEventShare.loadSchedule }),
        ];
        this.collaboratorService
            .bulkSave(this.jobEvent.job.id, collaborations)
            .subscribe(function () {
            jobEventShare.editing = false;
            _this.loading = false;
        }, function (err) {
            console.error(err);
            _this.loading = false;
        });
        // this.jobEventShareService.save(updatedJobEventShare).subscribe(() => {
        //   jobEventShare.editing = false;
        //   this.loading = false;
        // }, err => {
        //   console.error(err);
        //   this.loading = false;
        // });
    };
    CollaboratorListComponent.prototype.selectionChanged = function (event) {
        this.actionMenuDisabled = !event || (event && (!event.allSelected && event.selection.selected.length === 0));
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    CollaboratorListComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    CollaboratorListComponent.prototype.getCustomFields = function () {
        var _this = this;
        this.customFieldService.list({
            kind: custom_field_1.CustomFieldKind.Share,
            display_name: 'Broker Rate Code',
            active: 'True'
        }).subscribe(function (fields) {
            _this.customFields = fields;
            _this.brokerRateCodeKey = _this.customFields && _this.customFields[0] && _this.customFields[0].key;
            if (_this.brokerRateCodeKey) {
                var noteIndex = lodash_1.findIndex(_this.displayedColumns, 'notes');
                _this.displayedColumns.splice(noteIndex, 0, 'broker-rate-code');
            }
        });
    };
    CollaboratorListComponent.prototype.getUnitsOfMeasure = function () {
        var _this = this;
        this.unitsOfMeasureService.list().subscribe(function (units) {
            _this.unitsOfMeasure = units.map(function (unit) { return ({
                value: unit.id,
                label: unit.name,
                name: unit.name,
                key: unit.key,
                selected: false,
            }); });
            _this.fancyTable.records.forEach(function (record) {
                if (!record.requestedUnit) {
                    record.requestedUnit = _this.unitsOfMeasure && lodash_1.find(_this.unitsOfMeasure, { key: 'trucks' });
                }
            });
        });
    };
    CollaboratorListComponent.prototype.mapCustomFields = function (event) {
        var _this = this;
        this.withRecords = event && event.data && event.data.length > 0 ? true : false;
        event.data.forEach(function (row) {
            row.brokerRateCode = row.customFieldData[_this.brokerRateCodeKey];
            row.weightOptions = row.weightOptions.map(function (opt) { return (__assign({}, opt, { selected: false })); });
            row.unitsOfMeasure = _this.unitsOfMeasure.slice();
            if (row.requestedUnit === null) {
                row.requestedUnit = _this.unitsOfMeasure.slice().find(function (u) { return u.name === 'Trucks'; });
            }
        });
        this.expiredSharesToEnd();
    };
    CollaboratorListComponent.prototype.expiredSharesToEnd = function () {
        var records = this.fancyTable.dataSource.data.slice();
        var expiredShares = [];
        var activeShares = [];
        for (var i = 0; i < records.length; i++) {
            if (records[i].hasOwnProperty('status') && records[i]['status'] === 'expired') {
                expiredShares.push(records[i]);
            }
            else {
                activeShares.push(records[i]);
            }
        }
        this.fancyTable.dataSource.data = activeShares.concat(expiredShares);
    };
    CollaboratorListComponent.prototype.setSelectedRate = function (option, row) {
        row.weightOption = option;
    };
    CollaboratorListComponent.prototype.setRequestedUnit = function (option, row) {
        row.requestedUnit = option;
    };
    CollaboratorListComponent.prototype.onLoadlistDropdownClick = function (event) {
        event.stopPropagation();
        this.collaboratorForm.form.markAsTouched();
        this.collaboratorForm.form.markAsDirty();
    };
    return CollaboratorListComponent;
}());
exports.CollaboratorListComponent = CollaboratorListComponent;
