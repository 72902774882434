import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Truck } from './truck';
import { TruckSerializer } from './truck.serializer';
import { ResourceService } from '../shared/resource.service';
import { requestHeaders } from '../shared/index';

@Injectable()
export class TruckService extends ResourceService<Truck> {
  progress$;
  progress;

  constructor(http: HttpClient) {
    super(http, 'trucks/', new TruckSerializer());
  }

  public uploadImage(method: string, truckId: string[], files: File[]): Observable<any> {
    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (let i = 0; i < files.length; i++) {
        formData.append('image', files[i], files[i].name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(new TruckSerializer().fromJson(JSON.parse(xhr.response)));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };
      const url = this.baseUrl + 'trucks/' + truckId + '/';

      xhr.open(method, url, true);
      requestHeaders(xhr);
      xhr.send(formData);
    });
  }
}
