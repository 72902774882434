<form #cancelJob="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Cancel Job Day</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <p>
        <span translate>Canceling will notify the dispatched drivers that the job day has been cancelled.</span>
        <span *ngIf="allJobEventsCount > 0 && jobEventsDate">
          {{allJobEventsCount}} job<span *ngIf="allJobEventsCount > 1">s</span> <span translate>will be cancelled for </span>{{ jobEventsDate | date:'mediumDate' }}.
        </span>
      </p>
      <div *ngIf="showSummary" class="form-results">
        <h5 translate>Results</h5>
        <p>
          <span *ngIf="successJobs.length">
            <b> {{successJobs.length }}</b> <span translate>
              job<span *ngIf="successJobs.length > 1">s</span> were cancelled successfully. 
            </span><br />
          </span>
          <span *ngIf="errorsJobs.length" translate>
            The following <b> {{errorsJobs.length }}</b> shared job<span *ngIf="errorsJobs.length > 1">s</span> could not be cancelled:
          </span>
        </p>
  
       <ol>
         <li *ngFor="let error of errorsJobs"> {{ error }}</li>
       </ol>
     </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="cancelJob.form.valid && submit(); false"
            [disabled]="!cancelJob.form.valid || loading"
            [ngClass]="{loading:loading}" translate>
      OK
    </button>
  </div>
</form>
