import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SurchargeTemplate } from './surcharge-template';
import { SurchargeTemplateSerializer } from './surcharge-template.serializer';
import { ResourceService } from '../shared/resource.service';

@Injectable()
export class SurchargeTemplateService extends ResourceService<SurchargeTemplate> {
  constructor(http: HttpClient) {
    super(http, 'surchargetemplates/', new SurchargeTemplateSerializer());
  }
}
