"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pay_record_1 = require("./pay-record");
var assignment_serializer_1 = require("../assignments/assignment.serializer");
var trip_1 = require("../trips/trip");
var punch_card_1 = require("../punch-cards/punch-card");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PayRecordSerializer = /** @class */ (function () {
    function PayRecordSerializer() {
    }
    PayRecordSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var payRecord = new pay_record_1.PayRecord();
        if (!json) {
            return payRecord;
        }
        payRecord.id = json.id;
        payRecord.ticketNumber = json.ticketNumber;
        payRecord.paySheet = json.paySheet;
        payRecord.processStatus = json.processStatus;
        payRecord.data = {
            referenceId: json.data && json.data.assignment && json.data.assignment.id,
            assignment: json.data && json.data.assignment && new assignment_serializer_1.AssignmentSerializer().fromJson(json.data.assignment),
            uniqueStartDate: json.data && json.data.assignment && json.data.assignment.uniqueStart,
            shift1StartTimestamp: json.data && json.data.assignment && json.data.assignment.jobevent && json.data.assignment.jobevent.shift1Start,
            shift1EndTimestamp: json.data && json.data.assignment && json.data.assignment.jobevent && json.data.assignment.jobevent.shift1End,
            rowData: {
                referenceId: json.data && json.data.assignment && json.data.assignment.id,
                trips: json.data && json.data.assignment &&
                    json.data.assignment.trips && json.data.assignment.trips.map(function (trip) { return (new trip_1.Trip(trip)); }).reverse(),
                predictedTrips: json.data && json.data.assignment &&
                    json.data.assignment.predictedTrips && json.data.assignment.predictedTrips.map(function (trip) { return (new trip_1.Trip(trip)); }).reverse(),
                punchcards: json.data && json.data.assignment &&
                    json.data.assignment.punchcards && json.data.assignment.punchcards.map(function (punchcard) { return (new punch_card_1.PunchCard(punchcard)); }).reverse(),
                shifts: json.data && json.data.assignment &&
                    json.data.assignment.shifts && json.data.assignment.shifts.map(function (shift) { return ({
                    type: 'shift',
                    startDatetime: shift.startTime,
                    endDatetime: shift.endTime,
                }); }).reverse(),
                payLines: json.payLines,
                payBasis: json.payBasis,
                payAdjustmentTotal: json.payAdjustmentTotal,
                notes: json.notes
            }
        };
        if ((json.data && json.data.assignment && json.data.assignment.uniqueStart) ||
            (json.data && json.data.assignment && json.data.assignment.jobevent && json.data.assignment.jobevent.shift1StartTimestamp)) {
            payRecord.data.rowData.shifts.push({
                type: 'scheduled',
                startDatetime: json.data.assignment.uniqueStart || json.data.assignment.jobevent.shift1StartTimestamp
            });
        }
        return payRecord;
    };
    PayRecordSerializer.prototype.toJson = function (payRecord) {
        var json = {
            id: payRecord.id,
            paySheet: payRecord.paySheet,
            data: payRecord.data,
            payAdjustmentTotal: payRecord.data.rowData.payAdjustmentTotal,
            notes: payRecord.data.rowData.notes,
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return PayRecordSerializer;
}());
exports.PayRecordSerializer = PayRecordSerializer;
