"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{height:100%;width:100%}.billing-details[_ngcontent-%COMP%]{width:100%;height:100%;display:flex;flex-direction:column;justify-content:space-between;align-content:space-between}.billing-details__field[_ngcontent-%COMP%]{display:flex;flex-direction:column;flex-wrap:wrap;width:100%;font-family:Roboto}.billing-details__field[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:link, .billing-details__field[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:visited{text-decoration:none;color:#0093ee}.billing-details__field__values[_ngcontent-%COMP%]{width:100%;display:flex;flex-direction:row;justify-content:space-between;align-content:space-between;font-size:15px;padding-bottom:10px}.billing-details__field__values__name[_ngcontent-%COMP%]{width:100px;color:#a3acaf}.billing-details__field__values__value[_ngcontent-%COMP%]{color:#3f536e;display:inline-block}"];
exports.styles = styles;
