<div class="timeline-list-container">
  <div class="timeline-list-header">
    <div class="selector">
      <input type="checkbox" name="row-selector" [checked]="allSelected" (click)="selectAll()"/>
    </div>
    <p class="smaller" translate>Driver Details</p>
  </div>
  <div *ngFor="let row of rows" class="row-header"
       [ngClass]="{ 'selected': row.data.editing }">
    <div class="selector">
      <input type="checkbox" name="row-selector" [(ngModel)]="row.data.selected" (ngModelChange)="emitSelection()"/>
    </div>
    <div class="details" *ngIf="row.data.assignment" (click)="editing.emit(row.data.referenceId)">
      <div class="text">
        <p class="smaller" (contextmenu)="openContextMenu($event, row.data.assignment.driver.id)">{{ row.data.assignment.driver && row.data.assignment.driver.name }}</p>
        <p class="description">{{ row.data.assignment.truck && row.data.assignment.truck.name }} - {{ row.data.assignment.truck && row.data.assignment.truck.truckType.name }}</p>
        <p class="description">{{ row.data.assignment.driver && row.data.assignment.driver.carrier.name }}</p>
        <p class="description">{{ row.data.assignment.jobevent && row.data.assignment.jobevent.jobDisplayName }}</p>
        
      </div>
      <div class="info" *ngIf="row.data.assignment && row.data.assignment.driver && row.data.date">
        <a class="replay-link" target="_blank" [routerLink]="['/replay']" [queryParams]="{ date: row.data.date, driver: row.data.assignment.driver.id }">
          <i class="icon-replay"></i>
        </a>
      </div>
    </div>
    <div class="dropdowns" *ngIf="!row.data.driver"></div>
  </div>
</div>

<!-- driver context menu -->
<app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject"></app-driver-context-menu>