"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./truck-type-names.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@ngx-translate/core");
var i4 = require("@angular/flex-layout/extended");
var i5 = require("@angular/flex-layout/core");
var i6 = require("./truck-type-names.component");
var styles_TruckTypeNamesComponent = [i0.styles];
var RenderType_TruckTypeNamesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TruckTypeNamesComponent, data: {} });
exports.RenderType_TruckTypeNamesComponent = RenderType_TruckTypeNamesComponent;
function View_TruckTypeNamesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.names; _ck(_v, 1, 0, currVal_0); }); }
function View_TruckTypeNamesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["0 Types"]))], null, null); }
function View_TruckTypeNamesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " Type"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.truckTypes.length; _ck(_v, 0, 0, currVal_0); }); }
function View_TruckTypeNamesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", " Types"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.truckTypes.length; _ck(_v, 0, 0, currVal_0); }); }
function View_TruckTypeNamesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgPlural, [i2.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TruckTypeNamesComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgPluralCase, [[8, "=0"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), i1.ɵdid(4, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TruckTypeNamesComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgPluralCase, [[8, "=1"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), i1.ɵdid(7, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TruckTypeNamesComponent_6)), i1.ɵdid(9, 16384, null, 0, i2.NgPluralCase, [[8, "other"], i1.TemplateRef, i1.ViewContainerRef, i2.NgPlural], null, null), i1.ɵdid(10, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.truckTypes.length; _ck(_v, 1, 0, currVal_0); var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 7, 0, currVal_2); var currVal_3 = ""; _ck(_v, 10, 0, currVal_3); }, null); }
function View_TruckTypeNamesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruckTypeNamesComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.truckTypes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TruckTypeNamesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_TruckTypeNamesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.hover = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.hover = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruckTypeNamesComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruckTypeNamesComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "popover"]], [[4, "bottom", null], [4, "left", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { visible: 0 }), i1.ɵdid(10, 933888, null, 0, i4.DefaultClassDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(11, { visible: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TruckTypeNamesComponent_7)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.countOnly; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.countOnly; _ck(_v, 5, 0, currVal_1); var currVal_4 = "popover"; var currVal_5 = _ck(_v, 9, 0, _co.hover); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = _ck(_v, 11, 0, _co.hover); var currVal_7 = "popover"; _ck(_v, 10, 0, currVal_6, currVal_7); var currVal_8 = _co.truckTypes; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.popoverBottom + "px"); var currVal_3 = (_co.popoverLeft + "px"); _ck(_v, 6, 0, currVal_2, currVal_3); }); }
exports.View_TruckTypeNamesComponent_0 = View_TruckTypeNamesComponent_0;
function View_TruckTypeNamesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "truck-type-names", [], null, [[null, "mouseenter"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TruckTypeNamesComponent_0, RenderType_TruckTypeNamesComponent)), i1.ɵdid(1, 4308992, null, 0, i6.TruckTypeNamesComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TruckTypeNamesComponent_Host_0 = View_TruckTypeNamesComponent_Host_0;
var TruckTypeNamesComponentNgFactory = i1.ɵccf("truck-type-names", i6.TruckTypeNamesComponent, View_TruckTypeNamesComponent_Host_0, { truckTypes: "truckTypes", anyType: "anyType", countOnly: "countOnly", ref: "ref" }, {}, []);
exports.TruckTypeNamesComponentNgFactory = TruckTypeNamesComponentNgFactory;
