"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var FormattedDurationPipe = /** @class */ (function () {
    function FormattedDurationPipe() {
    }
    FormattedDurationPipe.prototype.transform = function (startTime, currentTime) {
        if (currentTime === void 0) { currentTime = moment(); }
        if (!currentTime) {
            currentTime = moment();
        }
        var duration = moment.duration(moment(currentTime).diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    return FormattedDurationPipe;
}());
exports.FormattedDurationPipe = FormattedDurationPipe;
