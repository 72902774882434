"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment");
var punch_card_service_1 = require("../punch-cards/punch-card.service");
var paystub_service_1 = require("../paystubs/paystub.service");
var paystub_punch_cards_dialog_component_1 = require("./paystub-punch-cards-dialog.component");
var index_1 = require("../shared/dialogs/index");
var PaystubPunchCardsComponent = /** @class */ (function () {
    function PaystubPunchCardsComponent(route, router, punchCardService, paystubService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.punchCardService = punchCardService;
        this.paystubService = paystubService;
        this.dialog = dialog;
        this.search = '';
        this.updatePaystub = new core_1.EventEmitter();
        this.selectedCount = 0;
        this.punchCards = [];
        this.loading = true;
        this.errors = [];
        this.page = 1;
        this.sortAsc = true;
        this.allSelected = false;
        this.selectedPunchCards = [];
        this.excludePunchCards = [];
        this.checkingDuplicates = false;
        this.multipleActionDropdownOptions = [
            { name: 'Remove from Paystub', action: 'remove', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Remove from Paystub', action: 'remove', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.addPunchCardsCallback = function (e) {
            _this.updatePaystub.emit(true);
            _this.getPunchCards();
        };
    }
    PaystubPunchCardsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.loading = true;
        this.route.queryParams.forEach(function (params) {
            _this.sortBy = params.sortPunchCardsBy ? params.sortPunchCardsBy : 'start_time';
            _this.sortAsc = params.sortPunchCardsAsc ? (params.sortPunchCardsAsc === 'true') : false;
            _this.sortParameter = params.sortPunchCardsParameter ? params.sortPunchCardsParameter : null;
            _this.page = params.sortPunchCardsPage ? params.sortPunchCardsPage : 1;
        });
        this.getPunchCards((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    PaystubPunchCardsComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                var chng = changes[propName];
                if (propName === 'search') {
                    this.search = chng.currentValue;
                }
            }
        }
        this.changeSearch(this.search);
    };
    PaystubPunchCardsComponent.prototype.pageChange = function (event) {
        var _a;
        this.page = event;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortPunchCardsPage: this.page > 1 ? this.page : null })
        });
        this.getPunchCards((_a = {
                ordering: (this.sortAsc ? '' : '-') + this.sortBy
            },
            _a[this.sortParameter] = this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
            _a.page = this.page,
            _a));
    };
    PaystubPunchCardsComponent.prototype.getPunchCards = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.punchCards = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.punchCardReq) {
            this.punchCardReq.unsubscribe();
        }
        this.punchCardFilters = __assign({ ordering: order, search: this.search, invoice: this.paystub.id, exclude_cf: 'True' }, query);
        if (this.checkingDuplicates) {
            this.punchCardFilters['only_dupes'] = 'True';
        }
        this.punchCardReq = this.punchCardService.getCondensedPunchCards(this.punchCardFilters).subscribe(function (punchCards) {
            if (append) {
                _this.punchCards = _this.punchCards.concat(punchCards);
            }
            else {
                _this.punchCards = punchCards;
            }
            _this.count = _this.punchCardService.count;
            _this.loading = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    PaystubPunchCardsComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortPunchCardsBy: this.sortBy, sortPunchCardsAsc: this.sortAsc, sortPunchCardsParameter: null })
        });
        this.loading = true;
        this.getPunchCards({
            ordering: (this.sortAsc ? '' : '-') + this.sortBy,
            page: this.page
        });
    };
    PaystubPunchCardsComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, { sortPunchCardsBy: this.sortBy, sortPunchCardsAsc: this.sortAsc, sortPunchCardsParameter: this.sortParameter })
        });
        this.loading = true;
        this.getPunchCards((_a = {},
            _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy,
            _a.page = this.page,
            _a));
    };
    PaystubPunchCardsComponent.prototype.openAddPunchCard = function () {
        var dialog = this.dialog.open(paystub_punch_cards_dialog_component_1.PaystubPunchCardsDialogComponent, {
            width: '100%',
            height: '100%',
            disableClose: true
        });
        dialog.componentInstance.paystub = this.paystub;
        dialog.componentInstance.routeToPaystub = false;
        dialog.componentInstance.callback = this.addPunchCardsCallback;
    };
    PaystubPunchCardsComponent.prototype.removeSelectedPunchCards = function (punchCards) {
        var _this = this;
        if (punchCards === void 0) { punchCards = null; }
        var model = { id: this.paystub.id };
        if (punchCards || this.selectedPunchCards.length) {
            Object.assign(model, { punchcards: punchCards || this.selectedPunchCards });
        }
        else if (this.allSelected) {
            var params_1 = new http_1.HttpParams();
            var filters_1 = lodash_1.omit(this.punchCardFilters, ['ordering', 'invoice']);
            if (filters_1) {
                Object.keys(filters_1).map(function (key) {
                    if (filters_1[key] && filters_1[key].length) {
                        params_1 = params_1.set(key, filters_1[key]);
                    }
                });
            }
            if (params_1.toString().length) {
                Object.assign(model, { filters: params_1.toString() });
            }
            Object.assign(model, { excludePunchCards: this.excludePunchCards });
        }
        this.paystubReq = this.paystubService.removeFromPaystub(model, 'punchcards').subscribe(function (res) {
            _this.paystub = res;
            _this.search = '';
            _this.allSelected = false;
            _this.selectedCount = 0;
            _this.selectedPunchCards = [];
            _this.updatePaystub.emit(true);
            _this.getPunchCards();
        }, function (err) { return console.error(err); });
    };
    PaystubPunchCardsComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.getPunchCards();
    };
    PaystubPunchCardsComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    PaystubPunchCardsComponent.prototype.menuAction = function (name, punchCard) {
        switch (name) {
            case 'remove':
                punchCard ? this.removeSelectedPunchCards([punchCard.id]) : this.removeSelectedPunchCards();
                break;
            case 'void':
                punchCard ? this.voidSelectedPunchCards([punchCard.id]) : this.voidSelectedPunchCards();
                break;
        }
    };
    PaystubPunchCardsComponent.prototype.voidSelectedPunchCards = function (punchCards) {
        var _this = this;
        if (punchCards === void 0) { punchCards = null; }
        punchCards = punchCards ? punchCards : this.selectedPunchCards;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Punch Cards?',
            body: 'These punch cards will be marked as \'Void\' and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                punchCards.forEach(function (punchCardId, index) {
                    var punchCard = lodash_1.find(_this.punchCards, { id: punchCardId });
                    _this.punchCardService.save({ id: punchCard.id, void: true })
                        .subscribe(function (result) {
                        punchCard['void'] = true;
                        punchCard['loading'] = false;
                        if (index === punchCards.length - 1) {
                            _this.allSelected = false;
                            _this.selectedPunchCards = [];
                            _this.selectedCount = 0;
                            setTimeout(function () {
                                _this.getPunchCards();
                                _this.updatePaystub.emit(true);
                            }, 2000);
                        }
                    }, function (err) {
                        _this.errors = err;
                    });
                });
            }
            _this.confirmDialog = null;
        });
    };
    PaystubPunchCardsComponent.prototype.formattedDuration = function (startTime) {
        var duration = moment.duration(moment().diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    PaystubPunchCardsComponent.prototype.duplicateCheck = function () {
        this.getPunchCards();
    };
    PaystubPunchCardsComponent.prototype.selector = function (event, punchCard) {
        if (punchCard === void 0) { punchCard = null; }
        if (punchCard) {
            if (!event.target.checked) {
                punchCard.selected = false;
                lodash_1.pull(this.selectedPunchCards, punchCard.id);
                if (this.allSelected) {
                    this.excludePunchCards.push(punchCard.id);
                    this.selectedCount = (this.count - this.excludePunchCards.length);
                }
                else {
                    this.selectedCount = this.selectedPunchCards.length;
                }
            }
            else {
                punchCard.selected = true;
                if (this.allSelected) {
                    lodash_1.pull(this.excludePunchCards, punchCard.id);
                    this.selectedCount = (this.count - this.excludePunchCards.length);
                }
                else {
                    this.selectedPunchCards.push(punchCard.id);
                    this.selectedCount = this.selectedPunchCards.length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected = false;
                this.punchCards.forEach(function (_punchCard) { _punchCard.selected = false; });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                this.selectedCount = (this.count - this.excludePunchCards.length);
            }
            this.selectedPunchCards = [];
            this.excludePunchCards = [];
        }
    };
    return PaystubPunchCardsComponent;
}());
exports.PaystubPunchCardsComponent = PaystubPunchCardsComponent;
