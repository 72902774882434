"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var location_type_serializer_1 = require("./location-type.serializer");
var resource_service_1 = require("../shared/resource.service");
var LocationTypeService = /** @class */ (function (_super) {
    __extends(LocationTypeService, _super);
    function LocationTypeService(http) {
        return _super.call(this, http, 'locationtypes/', new location_type_serializer_1.LocationTypeSerializer()) || this;
    }
    return LocationTypeService;
}(resource_service_1.ResourceService));
exports.LocationTypeService = LocationTypeService;
