import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { parseErrors } from '../shared/api.service';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from '../users/user.service';
import { TagService } from '../tags/tag.service';

@Component({
  selector: 'ruckit-add-employee-dialog',
  templateUrl: './add-employee-dialog.component.html',
  styleUrls: ['./add-employee-dialog.component.scss'],
  providers: [UserService, TagService]
})

export class RuckitAddEmployeeDialogComponent implements OnInit {
  title = 'Invite a New Employee';
  blurb = 'Invite an Employee to begin using Ruckit.';
  model: any = { tags: [] };
  loading = false;
  success: boolean;
  button = {
    title: 'Invite',
    disabled: false,
    type: 'primary'
  };
  errors = [];
  tags = [];
  tagOptions = [];
  tagsReq;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RuckitAddEmployeeDialogComponent>,
    private router: Router,
    private userService: UserService,
    private tagService: TagService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.data.email) {
      this.model.email = this.data.email;
    }

    this.fetchTags();
    this.setContent();
  }

  setContent() {
    switch (this.data.type) {
      case 'customer': {
        this.title = this.translateService.instant('Invite Customer');
        this.blurb = this.translateService.instant(
          'Invite your customer to begin using Ruckit. Once they accept ' +
          'their account, they will be able to see jobs where they are ' +
          'listed as the Customer.'
        );
        break;
      }
      default: {
        this.title = this.translateService.instant('Invite a New Employee');
        this.blurb = this.translateService.instant('Invite an Employee to begin using Ruckit.');
        break;
      }
    }
  }

  fetchTags(query = {}, next = false) {
    if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
      this.tagsReq.unsubscribe();
    }
    if (next) {
      this.tagsReq = this.tagService.listNext();
    } else {
      this.tags.length = 0;
      this.tagsReq = this.tagService.list(query);
    }
    if (this.tagsReq) {
      this.tagsReq.subscribe(
        res => {
          this.tags.push.apply(this.tags, res);
          this.tags.forEach(tag => this.tagOptions.push(tag.name));
        },
        err => this.errors = err
      );
    }
  }

  tagChange(e) {
    this.model['tags'] = e;
  }

  submit() {
    this.loading = true;

    this.userService.inviteUser(
      this.model.email,
      this.data.organizationId,
      this.data.type === 'customer'
    ).subscribe(result => {
      this.success = true;
      this.button = {
        title: 'Invite Sent!',
        disabled: true,
        type: 'primary'
      };

      setTimeout(() => {
        this.loading = false;
        this.dialogRef.close();
      }, 1000);
    }, (err) => {
      this.errors = parseErrors(err);
      this.success = false;
      this.button = {
        title: 'Invite Failed!',
        disabled: true,
        type: 'error'
      };
      setTimeout(() => {
        this.loading = false;
        this.dialogRef.close();
        this.button = {
          title: 'Invite',
          disabled: false,
          type: 'primary'
        };
      }, 4000);
    });
  }
}
