import { Component, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TagService } from '../tags/tag.service';
import { parseErrors } from '../shared/api.service';

@Component({
  selector: 'organization-market-dialog',
  templateUrl: './organization-market-dialog.component.html',
  styleUrls: ['./organization-market-dialog.component.scss'],
  providers: [TagService]
})
export class OrganizationMarketDialogComponent implements OnInit {
  @Input() model = {
    id: null,
    name: null,
    notes: null
  };
  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  callback;

  constructor(
    public dialogRef: MatDialogRef<OrganizationMarketDialogComponent>,
    private tagService: TagService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
  }

  submitOrganizationMarket() {
    this.loading = true;

    this.tagService.save(this.model).subscribe((res) => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }
}
