"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISPLAYED_COLUMNS = ['carrier', 'name', 'requested', 'dispatched'];
exports.AVAILABLE_COLUMNS = function (translateService) { return [
    {
        key: 'carrier',
        title: translateService.instant('Hauler Name'),
        sortable: false,
    },
    {
        key: 'name',
        title: translateService.instant('Job Name'),
        sortable: true,
        sortBy: 'jobevent__job__name',
    },
    {
        key: 'requested',
        title: translateService.instant('Requested Trucks'),
        sortable: true,
        sortBy: 'num_trucks',
    },
    {
        key: 'dispatched',
        title: translateService.instant('Dispatched Trucks'),
        sortable: false,
    },
]; };
exports.AVAILABLE_FILTERS = function () { return []; };
// new FilterOption({
//   key: 'customer_organization',
//   slug: '-organizations',
//   title: translateService.instant('Customer'),
//   filterType: 'select',
//   service: StatsJobEventFilterService,
//   searchable: true,
//   removeFromFiltersQuery: true,
// }),
