"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var connection_1 = require("./connection");
var index_1 = require("../shared/index");
var resource_service_1 = require("../shared/resource.service");
var connection_serializer_1 = require("./connection.serializer");
var ConnectionService = /** @class */ (function (_super) {
    __extends(ConnectionService, _super);
    function ConnectionService(http) {
        var _this = _super.call(this, http, 'connections/', new connection_serializer_1.ConnectionSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        _this.params = new http_1.HttpParams();
        _this.count = 10;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        _this.token = currentUser && currentUser.token;
        _this.params = new http_1.HttpParams();
        return _this;
    }
    ConnectionService.prototype.getInvoiceStats = function (connectionId) {
        var _this = this;
        var connectionUrl = this.baseUrl + 'connections/' + connectionId + '/invoice_stats/';
        return this.http.get(connectionUrl, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }));
    };
    ConnectionService.prototype.suspendConnection = function (connectionId) {
        var connectionUrl = this.baseUrl + 'connections/' + connectionId + '/';
        return this.http.put(connectionUrl, { status: 'suspended' }, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    ConnectionService.prototype.disconnectConnection = function (connectionId) {
        var connectionUrl = this.baseUrl + 'connections/' + connectionId + '/';
        return this.http.put(connectionUrl, { status: 'disconnected' }, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    ConnectionService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (body) {
            return body.map(function (conn) {
                return new connection_1.Connection();
            });
        }
        else if (resObj) {
            return new connection_1.Connection();
        }
        else {
            return {};
        }
    };
    return ConnectionService;
}(resource_service_1.ResourceService));
exports.ConnectionService = ConnectionService;
