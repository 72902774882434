"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./job-status.component");
var styles_JobStatusComponent = [];
var RenderType_JobStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JobStatusComponent, data: {} });
exports.RenderType_JobStatusComponent = RenderType_JobStatusComponent;
function View_JobStatusComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.TitleCasePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "label label-", _co.status, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.statusText ? _co.statusText : i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.status))); _ck(_v, 2, 0, currVal_1); }); }
exports.View_JobStatusComponent_0 = View_JobStatusComponent_0;
function View_JobStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "job-status", [], null, null, null, View_JobStatusComponent_0, RenderType_JobStatusComponent)), i0.ɵdid(1, 49152, null, 0, i2.JobStatusComponent, [], null, null)], null, null); }
exports.View_JobStatusComponent_Host_0 = View_JobStatusComponent_Host_0;
var JobStatusComponentNgFactory = i0.ɵccf("job-status", i2.JobStatusComponent, View_JobStatusComponent_Host_0, { status: "status", statusText: "statusText" }, {}, []);
exports.JobStatusComponentNgFactory = JobStatusComponentNgFactory;
