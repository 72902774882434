"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mock_organization_1 = require("../organizations/mock-organization");
exports.USER = {
    id: '00000000-0000-0000-0000-000000000001',
    firstName: 'John',
    lastName: 'Smith',
    name: 'John Smith',
    email: 'jsmith@ruckit.me',
    username: 'jsmith@ruckit.me',
    title: 'Mock User',
    organization: mock_organization_1.ORGANIZATION,
    phoneNumber: '8675309',
    status: 'active',
    token: '',
    image: 'http://lorempixel.com/52/52/people',
    remittanceInfo: null,
    sidebar: false,
    createdAt: null,
    driverNotificationEmail: true,
    jobNotificationEmail: true,
    afCarrierDispatchNotificationEmail: false,
    notifications: {},
    lastModified: null,
    loading: false,
    selected: false,
    driver: null,
    save: null,
    isDriver: false,
    isCarrier: false,
    isRuckit: false,
    isCrh: false,
    isScaleit: true,
    organizationName: null,
    favoriteTags: [],
    favoriteLocation: null,
    tags: [],
    readOnlyUser: false,
    passwordCurrent: null,
    password: null,
    passwordConfirmation: null,
    classes: '',
    workOrderSignatureImage: '',
    workOrderSignatureName: '',
    features: null,
    enabledFeatures: null,
    hasSignature: true,
    lafargeRegion: null,
    url: function (action) {
        return '';
    },
    filterOptions: function (options) {
        return options;
    }
};
