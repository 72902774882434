import { Ticket } from './ticket';
import { Organization } from '../organizations/organization';
import { PaymentType } from '../payment-types/payment-type';
import { Scale } from '../scales/scale';

import { DriverSerializer } from './../drivers/driver.serializer';
import { ProductSerializer } from '../products/product.serializer';
import { LocationSerializer } from '../locations/location.serializer';
import { SurchargeSerializer } from '../surcharges/surcharge.serializer';
import { TruckSerializer } from '../trucks/truck.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TicketSerializer {
  fromJson(ticketInfo: any): Ticket {
    ticketInfo = camelcaseKeysDeep(ticketInfo);
    const ticket = new Ticket();
    if (!ticketInfo) { return ticket; }
    ticketInfo = camelcaseKeysDeep(ticketInfo);

    ticket.id = ticketInfo.id;
    ticket.createdAt = ticketInfo.createdAt;
    ticket.deliveryTime = ticketInfo.deliveryTime;
    if (ticketInfo.status === 'on-hold') {
      ticket.statusLabel = 'label-pending';
    } else if (ticketInfo.status === 'in-progress') {
      ticket.statusLabel = 'label-upcoming';
    } else if (ticketInfo.status === 'void') {
      ticket.statusLabel = 'label-void';
    }
    if (ticketInfo.onHold) {
      ticket.status = 'on-hold';
      ticket.statusLabel = 'label-pending';
    } else {
      ticket.status = 'in-progress';
      ticket.statusLabel = 'label-upcoming';
    }
    ticket.void = ticketInfo.void;
    ticket.voidReason = ticketInfo.voidReason;
    if (ticket.void) {
      ticket.status = 'void';
      ticket.statusLabel = 'label-void';
    }
    ticket.number = ticketInfo.number;
    if (ticketInfo.customerOrganization && typeof (ticketInfo.customerOrganization) === 'object') {
      ticket.customerOrganization = new Organization(ticketInfo.customerOrganization);
    } else {
      ticket.customerOrganization = new Organization({ id: ticketInfo.customerOrganization });
    }
    if (ticketInfo.sellerOrganization && typeof (ticketInfo.sellerOrganization) === 'object') {
      ticket.sellerOrganization = new Organization(ticketInfo.sellerOrganization);
    } else {
      ticket.sellerOrganization = new Organization({ id: ticketInfo.sellerOrganization });
    }
    if (ticketInfo.carrierOrganization && typeof (ticketInfo.carrierOrganization) === 'object') {
      ticket.carrierOrganization = new Organization(ticketInfo.carrierOrganization);
    } else {
      ticket.carrierOrganization = new Organization({ id: ticketInfo.carrierOrganization });
    }
    if (ticketInfo.driver && typeof (ticketInfo.driver) === 'object') {
      ticket.driver = new DriverSerializer().fromJson(ticketInfo.driver);
    } else {
      ticket.driver = new DriverSerializer().fromJson({ id: ticketInfo.driver });
    }
    ticket.driverName = ticketInfo.driverName;
    if (ticketInfo.truck && typeof (ticketInfo.truck) === 'object') {
      ticket.truck = new TruckSerializer().fromJson(ticketInfo.truck);
    } else {
      ticket.truck = new TruckSerializer().fromJson({ id: ticketInfo.truck });
    }
    if (ticketInfo.location && typeof (ticketInfo.location) === 'object') {
      ticket.location = (new LocationSerializer).fromJson(ticketInfo.location);
    } else {
      ticket.location = (new LocationSerializer).fromJson({ id: ticketInfo.location });
    }
    if (ticketInfo.product && typeof (ticketInfo.product) === 'object') {
      ticket.product = (new ProductSerializer().fromJson(ticketInfo.product));
    } else {
      ticket.product = (new ProductSerializer().fromJson({ id: ticketInfo.product }));
    }
    ticket.completedTime = ticketInfo.completedTime;
    ticket.notes = ticketInfo.notes;
    ticket.tareWeight = ticketInfo.tareWeight;
    ticket.tareWeightTime = ticketInfo.tareWeightTime;
    ticket.tareWeightUnit = ticketInfo.tareWeightUnit;
    if (ticketInfo.tareWeightScale && typeof (ticketInfo.tareWeightScale) === 'object') {
      ticket.tareWeightScale = new Scale(ticketInfo.tareWeightScale);
    } else if (ticketInfo.tareWeightScale) {
      ticket.tareWeightScale = new Scale({ id: ticketInfo.tareWeightScale });
    }
    ticket.grossWeight = ticketInfo.grossWeight;
    ticket.grossWeightTime = ticketInfo.grossWeightTime;
    ticket.grossWeightUnit = ticketInfo.grossWeightUnit;
    if (ticketInfo.grossWeightScale && typeof (ticketInfo.grossWeightScale) === 'object') {
      ticket.grossWeightScale = new Scale(ticketInfo.grossWeightScale);
    } else if (ticket.grossWeightScale) {
      ticket.grossWeightScale = new Scale({ id: ticketInfo.grossWeightScale });
    }
    ticket.net = ticketInfo.net || 0;
    ticket.netPrice = ticketInfo.netPrice || 0;
    ticket.netWeightUnit = ticketInfo.tareWeightUnit;
    ticket.totalPrice = ticketInfo.totalPrice || 0;
    ticket.unitPrice = ticketInfo.unitPrice || 0;
    if (ticketInfo.paymentType && typeof (ticketInfo.paymentType) === 'object') {
      ticket.paymentType = new PaymentType(ticketInfo.paymentType);
    } else {
      ticket.paymentType = new PaymentType({
        id: ticketInfo.paymentType, name: ticketInfo.paymentType
      });
    }
    ticket.purchaseOrder = ticketInfo.purchaseOrder;
    ticket.discountPercentage = ticketInfo.discountPercentage || 0;
    ticket.correctionWeight = ticketInfo.correctionWeight || 0;
    ticket.surcharges = ticketInfo.surcharges && ticketInfo.surcharges.map((surcharge) => {
      if (surcharge && typeof (surcharge) === 'object') {
        return new SurchargeSerializer().fromJson(surcharge);
      } else {
        return new SurchargeSerializer().fromJson({ id: surcharge });
      }
    });
    ticket.surchargeTotal = ticketInfo.surchargeTotal || 0;
    ticket.taxesTotal = ticketInfo.taxesTotal || 0;

    return ticket;
  }

  toJson(ticket: Ticket): any {
    let json = {
      id: ticket.id,
      createdAt: ticket.createdAt,
      // recordDeleted: boolean;
      statusLabel: ticket.statusLabel,
      deliveryTime: ticket.deliveryTime,
      status: ticket.status, // in-progress, on-hold, complete
      number: ticket.number,
      customerOrganization: ticket.customerOrganization && ticket.customerOrganization.id,
      sellerOrganization: ticket.sellerOrganization && ticket.sellerOrganization.id,
      carrierOrganization: ticket.carrierOrganization && ticket.carrierOrganization.id,
      driver: ticket.driver,
      driverName: ticket.driverName,
      truck: ticket.truck,
      location: ticket.location,
      product: ticket.product,
      completedTime: ticket.completedTime,
      notes: ticket.notes,
      tareWeight: ticket.tareWeight,
      tareWeightTime: ticket.tareWeightTime,
      tareWeightUnit: ticket.tareWeightUnit,
      tareWeightScale: ticket.tareWeightScale,
      grossWeight: ticket.grossWeight,
      grossWeightTime: ticket.grossWeightTime,
      grossWeightUnit: ticket.grossWeightUnit,
      grossWeightScale: ticket.grossWeightScale,
      net: ticket.net,
      netPrice: ticket.netPrice,
      netWeightUnit: ticket.netWeightUnit,
      totalPrice: ticket.totalPrice,
      unitPrice: ticket.unitPrice,
      paymentType: ticket.paymentType && ticket.paymentType.id,
      purchaseOrder: ticket.purchaseOrder,
      discountPercentage: ticket.discountPercentage,
      correctionWeight: ticket.correctionWeight,
      surcharges: ticket.surcharges,
      surchargeTotal: ticket.surchargeTotal,
      taxesTotal: ticket.taxesTotal,
      void: ticket.void,
      voidReason: ticket.voidReason,
      selected: ticket.selected
    };

    return decamelizeKeysDeep(json);
  }
}
