"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CARRIER = {
    id: '00000000-0000-0000-0000-000000000001',
    name: 'Sample Carrier',
    dotNumber: 'ABC12345',
    canCreateJobs: true,
    leasedOrg: false,
    uniqueBillingId: '01010101'
};
