import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinByProperty',
  pure: true,
})
export class JoinByPropertyPipe implements PipeTransform {
  transform(arr: any, property: string, character = ', '): any {
    if (!Array.isArray(arr)) {
      return '';
    }

    return arr.map((t) => t[property]).join(character);
  }
}
