<div
  class="image-wrapper"
  [ngClass]="{ empty: !cropperImage && !uploadedImageURL }"
>
  <div
    class="image-container upload-image-dropzone"
    [ngClass]="{
      filled: image || cropperImage || uploadedImageURL || !options.light,
      'dropzone-light': options.light,
      'dropzone-dark': !options.light,
      disabled: options.disabled
    }"
  >
    <div class="image-actions" *ngIf="image && options && options.editable">
      <div *ngIf="options.showDelete" class="edit-button">
        <i (click)="removeImage()" class="icon-delete"></i>
      </div>
      <a
        *ngIf="uploadedImageURL"
        [href]="uploadedImageURL | safeUrl"
        target="_blank"
        ><div class="edit-button"><i class="icon-expand"></i></div
      ></a>
      <div class="edit-button" (click)="rotateImage(image.type)">
        <i class="icon-resend"></i>
      </div>
    </div>
    <div class="image-actions" *ngIf="image && options && !options.editable">
      <a
        *ngIf="uploadedImageURL"
        [href]="uploadedImageURL | safeUrl"
        target="_blank"
        ><div class="edit-button"><i class="icon-expand"></i></div
      ></a>
      <div class="edit-button" (click)="rotateImage(image.type)">
        <i class="icon-resend"></i>
      </div>
    </div>
    <img
      *ngIf="!existingImage"
      [id]="imageElementId"
      [src]="cropperImage | safeUrl"
      crossOrigin="Anonymous"
    />
    <img
      *ngIf="existingImage"
      [id]="imageElementId"
      [src]="cropperImage | safeUrl"
    />
    <input
      [disabled]="options.disabled || options.editable !== true"
      type="file"
      #fileInput
      [name]="inputElementId"
      [id]="inputElementId"
      class="inputfile"
      (change)="newImage($event)"
    />
    <label
      *ngIf="options.editable && !options.light"
      [for]="inputElementId"
      class="upload-label"
      [ngClass]="{ 'no-upload': !cropperImage && !uploadedImageURL }"
    >
      <i class="icon-upload"></i>
      <span *ngIf="!cropperImage && !uploadedImageURL" translate
        >Drag and drop an image under 25mb here or browse for an image to
        upload.</span
      >
      <span *ngIf="cropperImage || uploadedImageURL" translate
        >Upload a new photo</span
      >
    </label>

    <label
      *ngIf="options.editable && !image && options.light"
      [for]="inputElementId"
      class="upload-label"
    >
      <i class="icon-upload"></i>
      <span *ngIf="!image" translate
        >Drag and drop an image under 25mb here or browse for an image to
        upload.</span
      >
      <span *ngIf="image" translate>Upload a new photo</span>
    </label>
  </div>
</div>
