<widget [size]="[2,1]" (window:resize)="onResize($event)">
  <div class="heading" widget-header>
    <h2 translate>Daily Shifts</h2>
  </div>
  <div class="content" widget-content>
    <div class="widget-table">
      <div class="table-body">
        <div class="header">
          <div class="head driver" translate>
            Driver
          </div>
          <div class="head start" translate>
            Shift Start
          </div>
          <div class="head assigned" translate>
            Assigned Truck
          </div>
          <div class="head confirmed" translate>
            Confirmed Truck
          </div>
        </div>

        <ng-container *ngIf="shifts && shifts.length">
          <!-- context menu -->
          <app-driver-context-menu [contextMenuEventSubject]="contextMenuEventSubject" (onShiftEnd)="getShifts()">
          </app-driver-context-menu>

          <div class="row context-menu-cursor" *ngFor="let shift of shifts" (contextmenu)="openContextMenu($event, shift)">
            <div class="driver">
              <div class="driver-details">
                {{ shift.driver && shift.driver.name }}
                <span class="carrier">{{ shift.driver && shift.driver.carrier && shift.driver.carrier.name }}</span>
              </div>
              <a [routerLink]="['/replay']" [queryParams]="{ driver: shift.driver && shift.driver.id }"><i class="icon-replay"></i></a>
            </div>
            <div class="start">{{ shift.startTime | moment: 'h:mm A - MMMM DD, YYYY' }}</div>
            <div class="assigned" [ngClass]="{'nonmatch': shift.driver && shift.driver.truck && shift.truckAssignments[0] && shift.truckAssignments[0].truck && shift.truckAssignments[0].truck.name !== shift.driver.truck.name}">
              <p>{{ shift.truckAssignments[0] && shift.truckAssignments[0].truck && shift.truckAssignments[0].truck.name }}</p>
            </div>
            <div class="confirmed" [ngClass]="{'nonmatch': shift.driver && shift.driver.truck && shift.truckAssignments[0] && shift.truckAssignments[0].truck && shift.truckAssignments[0].truck.name !== shift.driver.truck.name}">
              <p>{{ shift.driver && shift.driver.truck && shift.driver.truck.name }}</p>
            </div>
          </div>
        </ng-container>

        <my-loader *ngIf="loading"></my-loader>
      </div>
    </div>
  </div>
</widget>
