"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment-timezone");
var ruckit_condensed_trip_1 = require("./ruckit-condensed-trip");
var driver_serializer_1 = require("../drivers/driver.serializer");
var checkin_1 = require("../checkins/checkin");
var app_constants_1 = require("../app.constants");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CondensedTripSerializer = /** @class */ (function () {
    function CondensedTripSerializer() {
    }
    CondensedTripSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var condensedTrip = new ruckit_condensed_trip_1.RuckitCondensedTrip();
        if (!json) {
            return condensedTrip;
        }
        condensedTrip.id = json.id;
        condensedTrip.date = json.date;
        condensedTrip.jobDate = json.jobDate;
        condensedTrip.job = json.job;
        condensedTrip.jobDisplayName = json.jobDisplayName;
        condensedTrip.jobId = json.jobId;
        condensedTrip.jobNumber = json.jobNumber;
        condensedTrip.jobEventId = json.jobeventId;
        condensedTrip.phaseCode = json.phaseCode;
        condensedTrip.project = json.project;
        condensedTrip.projectId = json.projectId;
        condensedTrip.customer = json.customer;
        condensedTrip.customerId = json.customerId;
        condensedTrip.customerPhone = json.customerPhone;
        condensedTrip.driver = json.driver;
        condensedTrip.driverId = json.driverId;
        condensedTrip.driverPhone = json.driverPhone;
        condensedTrip.driverImage = json.driverImage;
        condensedTrip.customDriver = new driver_serializer_1.DriverSerializer().fromJson({
            id: condensedTrip.driverId,
            customName: condensedTrip.driver,
            phoneNumber: condensedTrip.driverPhone,
            image: condensedTrip.driverImage,
            carrierName: json.carrier
        });
        condensedTrip.truck = json.truck;
        condensedTrip.truckId = json.truckId;
        condensedTrip.truckImage = json.truckImage;
        condensedTrip.truckType = json.truckType;
        condensedTrip.payableTo = json.payableTo;
        condensedTrip.payableToId = json.payableToId;
        condensedTrip.carrier = json.carrier;
        condensedTrip.carrierId = json.carrierId;
        condensedTrip.payableToPhone = json.payableToPhone;
        condensedTrip.loadingTicketNumber = json.loadingTicketNumber;
        condensedTrip.loadingWeight = json.loadingWeight;
        condensedTrip.loadingTicketImage = json.loadingTicketImage;
        condensedTrip.loadingTicketImageKey = json.loadingTicketImageKey;
        condensedTrip.loadingSignature = json.loadingSignature;
        condensedTrip.loadingSignatureKey = json.loadingSignatureKey;
        condensedTrip.unloadingTicketNumber = json.unloadingTicketNumber;
        condensedTrip.unloadingWeight = json.unloadingWeight;
        condensedTrip.unloadingTicketImage = json.unloadingTicketImage;
        condensedTrip.unloadingTicketImageKey = json.unloadingTicketImageKey;
        condensedTrip.unloadingSignature = json.unloadingSignature;
        condensedTrip.unloadingSignatureKey = json.unloadingSignatureKey;
        condensedTrip.startTimeTimestamp = json.startTime;
        condensedTrip.endTimeTimestamp = json.endTime;
        condensedTrip.invoiceRate = json.invoiceRate;
        condensedTrip.invoiceType = json.invoiceType;
        condensedTrip.invoiceWeightUnit = json.invoiceWeightUnit || 'ton';
        condensedTrip.invoiceQuantity = json.invoiceQuantity;
        condensedTrip.haulRate = json.haulRate;
        condensedTrip.haulType = json.haulType;
        condensedTrip.haulWeightUnit = json.haulWeightUnit || 'ton';
        condensedTrip.routePolyline = json.routePolyline;
        condensedTrip.retakeStatus = json.retakeStatus;
        condensedTrip.edited = json.edited;
        condensedTrip.completed = json.completed;
        condensedTrip.tripStatus = json.tripStatus;
        condensedTrip.reviewedStatus = json.reviewedStatus;
        condensedTrip.reviewedBy = json.reviewedBy;
        condensedTrip.reviewedAt = json.reviewedAt;
        condensedTrip.checkinOptions = json.checkinOptions;
        condensedTrip.checkoutOptions = json.checkoutOptions;
        condensedTrip.invoiceTotal = json.invoiceTotal;
        condensedTrip.haulTotal = json.haulTotal;
        condensedTrip.void = json.void;
        condensedTrip.expenseId = json.expenseId;
        condensedTrip.expenseNumber = json.expenseNumber;
        condensedTrip.expenseSent = json.expenseSent;
        condensedTrip.expenseTotal = json.expenseTotal;
        condensedTrip.paystubId = json.paystubId;
        condensedTrip.paystubNumber = json.paystubNumber;
        condensedTrip.paystubSent = json.paystubSent;
        condensedTrip.invoiceId = json.invoiceId;
        condensedTrip.invoiceNumber = json.invoiceNumber;
        condensedTrip.invoiceSent = json.invoiceSent;
        condensedTrip.weight = json.weight;
        condensedTrip.ticketNumber = json.ticketNumber;
        condensedTrip.timezone = json.timezone || moment.tz.guess() || 'America/Chicago';
        condensedTrip.origin = json.origin;
        condensedTrip.loadingCompleteDatetime = json.loadingCompleteDatetime;
        condensedTrip.unloadingCompleteDatetime = json.unloadingCompleteDatetime;
        condensedTrip.loadingArrivalDatetime = json.loadingArrivalDatetime;
        condensedTrip.unloadingArrivalDatetime = json.unloadingArrivalDatetime;
        condensedTrip.localizedEndDate = json.localizedEndDate;
        condensedTrip.localizedEndDatetime = json.localizedEndDatetime;
        condensedTrip.localizedEndTime = json.localizedEndTime;
        condensedTrip.localizedStartDate = json.localizedStartDate;
        condensedTrip.localizedStartDatetime = json.localizedStartDatetime;
        condensedTrip.localizedStartTime = json.localizedStartTime;
        condensedTrip.plantQc = json.plantQc;
        condensedTrip.plantQcReviewer = json.plantQcReviewer;
        condensedTrip.plantQcWasteAmt = json.plantQcWasteAmt;
        condensedTrip.plantVt = json.plantVt;
        condensedTrip.plantVtReviewer = json.plantVtReviewer;
        condensedTrip.plantVtWasteAmt = json.plantVtWasteAmt;
        condensedTrip.roadwayQc = json.roadwayQc;
        condensedTrip.roadwayQcReviewer = json.roadwayQcReviewer;
        condensedTrip.roadwayQcWasteAmt = json.roadwayQcWasteAmt;
        condensedTrip.roadwayVt = json.roadwayVt;
        condensedTrip.roadwayVtReviewer = json.roadwayVtReviewer;
        condensedTrip.roadwayVtWasteAmt = json.roadwayVtWasteAmt;
        condensedTrip.startLocationId = json.startLocationId;
        condensedTrip.startLocationName = json.startLocationName;
        condensedTrip.endLocationId = json.endLocationId;
        condensedTrip.endLocationName = json.endLocationName;
        condensedTrip.geoStartLocationId = json.geoStartLocationId;
        condensedTrip.geoStartLocationName = json.geoStartLocationName;
        condensedTrip.geoEndLocationId = json.geoEndLocationId;
        condensedTrip.geoEndLocationName = json.geoEndLocationName;
        condensedTrip.connexPaverStartUnloading = json.connexPaverStartUnloading;
        condensedTrip.connexPaverEndUnloading = json.connexPaverEndUnloading;
        condensedTrip.loadingAttachments = json.loadingAttachments;
        condensedTrip.unloadingAttachments = json.unloadingAttachments;
        if (condensedTrip.loadingTicketImage === null && condensedTrip.loadingAttachments.length) {
            var attachment = condensedTrip.loadingAttachments.find(function (att) { return att.fileType === app_constants_1.TRIPTICKETIMAGETYPE; });
            condensedTrip.loadingTicketImage = attachment ? attachment.file : null;
        }
        if (condensedTrip.unloadingTicketImage === null && condensedTrip.unloadingAttachments.length) {
            var attachment = condensedTrip.unloadingAttachments.find(function (att) { return att.fileType === app_constants_1.TRIPTICKETIMAGETYPE; });
            condensedTrip.unloadingTicketImage = attachment ? attachment.file : null;
        }
        // formattedDuration: string;
        // formattedCompletedTripTime: string;
        // formattedStartTime: string;
        // formattedEndTime: string;
        // formattedStartDate: Date;
        // formattedEndDate: Date;
        if (json.loadingArrivalDatetime) {
            var localizedArrivalLoading = moment.utc(json.loadingArrivalDatetime).tz(condensedTrip.timezone);
            var duration = moment.duration(moment(localizedArrivalLoading).diff(condensedTrip.startDate));
            condensedTrip.formattedLoadingArrivalDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (json.loadingCompleteDatetime) {
            var localizedCompleteLoading = moment.utc(json.loadingCompleteDatetime).tz(condensedTrip.timezone);
            var duration = moment.duration(moment(localizedCompleteLoading).diff(json.loadingArrivalDatetime));
            condensedTrip.formattedLoadingCompleteDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (json.unloadingArrivalDatetime) {
            var localizedArrivalUnLoading = moment.utc(json.unloadingArrivalDatetime).tz(condensedTrip.timezone);
            var duration = moment.duration(moment(localizedArrivalUnLoading).diff(json.loadingCompleteDatetime));
            condensedTrip.formattedUnloadingArrivalDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (json.unloadingCompleteDatetime) {
            var localizedCompleteUnLoading = moment.utc(json.unloadingCompleteDatetime).tz(condensedTrip.timezone);
            var duration = moment.duration(moment(localizedCompleteUnLoading).diff(json.unloadingArrivalDatetime));
            condensedTrip.formattedUnloadingCompleteDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (condensedTrip.endTimeTimestamp) {
            condensedTrip.duration = moment.duration(moment(condensedTrip.endTimeTimestamp).diff(condensedTrip.startTimeTimestamp));
        }
        else {
            condensedTrip.duration = moment.duration(moment().diff(condensedTrip.startTimeTimestamp));
        }
        condensedTrip.formattedDuration = condensedTrip.duration.seconds();
        condensedTrip.formattedCompletedTripTime = condensedTrip.duration.format('D[ days], H[ hrs], m[ mins]');
        if (condensedTrip.startTimeTimestamp) {
            condensedTrip.startDate = new Date(condensedTrip.startTimeTimestamp);
            if (!condensedTrip.startTime) {
                condensedTrip.formattedStartTime = moment(condensedTrip.startDate).format('h:mm a');
            }
        }
        if (condensedTrip.endTimeTimestamp) {
            condensedTrip.endDate = new Date(condensedTrip.endTimeTimestamp);
            if (!condensedTrip.endTime) {
                condensedTrip.formattedEndTime = moment(condensedTrip.endDate).format('h:mm a');
            }
        }
        if (condensedTrip.loadingCompleteDatetime) {
            // condensedTrip.loadingCompleteDatetime = moment(condensedTrip.loadingCompleteDatetime).tz(this.timezone).toDate();
        }
        if (condensedTrip.unloadingCompleteDatetime) {
            // condensedTrip.unloadingCompleteDatetime = moment(condensedTrip.unloadingCompleteDatetime).tz(this.timezone).toDate();
        }
        if ((condensedTrip.checkinOptions.signatureImage) ||
            (condensedTrip.checkoutOptions.signatureImage)) {
            condensedTrip.signatureImagesRequired = true;
        }
        else {
            condensedTrip.signatureImagesRequired = false;
        }
        if ((condensedTrip.checkinOptions.signatureImage && !condensedTrip.loadingSignature) ||
            (condensedTrip.checkoutOptions.signatureImage && !condensedTrip.unloadingSignature)) {
            condensedTrip.hasSignatureImages = false;
        }
        else {
            condensedTrip.hasSignatureImages = true;
        }
        if (condensedTrip.checkinOptions.signatureImage) {
            condensedTrip.loadingSignatureImagesRequired = true;
        }
        else {
            condensedTrip.loadingSignatureImagesRequired = false;
        }
        if (condensedTrip.checkinOptions.signatureImage && !condensedTrip.loadingSignature) {
            condensedTrip.hasLoadingSignatureImages = false;
        }
        else {
            condensedTrip.hasLoadingSignatureImages = true;
        }
        if (condensedTrip.checkoutOptions.signatureImage) {
            condensedTrip.unloadingSignatureImagesRequired = true;
        }
        else {
            condensedTrip.unloadingSignatureImagesRequired = false;
        }
        if (condensedTrip.checkoutOptions.signatureImage && !condensedTrip.unloadingSignature) {
            condensedTrip.hasUnloadingSignatureImages = false;
        }
        else {
            condensedTrip.hasUnloadingSignatureImages = true;
        }
        condensedTrip.ticketImagesRequired = (condensedTrip.checkinOptions.ticketImage || condensedTrip.checkoutOptions.ticketImage) ? true : false;
        condensedTrip.hasTicketImages = (condensedTrip.loadingTicketImage || condensedTrip.unloadingTicketImage) ? true : false;
        condensedTrip.loadingTicketImagesRequired = condensedTrip.checkinOptions.ticketImage ? true : false;
        condensedTrip.hasLoadingTicketImages = condensedTrip.loadingTicketImage ? true : false;
        condensedTrip.unloadingTicketImagesRequired = condensedTrip.checkoutOptions.ticketImage ? true : false;
        condensedTrip.hasUnloadingTicketImages = condensedTrip.unloadingTicketImage ? true : false;
        condensedTrip.loadingTicket = condensedTrip.loadingCheckin && condensedTrip.loadingCheckin.ticketImage;
        condensedTrip.unloadingTicket = condensedTrip.unloadingCheckin && condensedTrip.unloadingCheckin.ticketImage;
        condensedTrip.loadingCheckin = new checkin_1.Checkin(json.loadingCheckin || { kind: 'loading', trip: condensedTrip.id });
        condensedTrip.unloadingCheckin = new checkin_1.Checkin(json.unloadingCheckin || { kind: 'unloading', trip: condensedTrip.id });
        // if (condensedTrip.loadingSignature && condensedTrip.loadingSignatureKey) {
        //   condensedTrip.inlineLoadingSignature = ImageResizer.getResizedUrl(condensedTrip.loadingSignatureKey, 24, 24);
        // }
        // if (condensedTrip.loadingTicketImage && condensedTrip.loadingTicketImageKey) {
        //   condensedTrip.inlineLoadingTicket = ImageResizer.getResizedUrl(condensedTrip.loadingTicketImageKey, 28, 28);
        // }
        // if (condensedTrip.unloadingSignature && condensedTrip.unloadingSignatureKey) {
        //   condensedTrip.inlineUnloadingSignature = ImageResizer.getResizedUrl(condensedTrip.unloadingSignatureKey, 24, 24);
        // }
        // if (condensedTrip.unloadingTicketImage && condensedTrip.unloadingTicketImageKey) {
        //   condensedTrip.inlineUnloadingTicket = ImageResizer.getResizedUrl(condensedTrip.unloadingTicketImageKey, 28, 28);
        // }
        if ((condensedTrip.loadingTicketNumber ||
            condensedTrip.loadingTicketImage ||
            condensedTrip.checkinOptions.ticketNumber ||
            condensedTrip.checkinOptions.ticketImage) && (condensedTrip.unloadingTicketNumber ||
            condensedTrip.unloadingTicketImage ||
            condensedTrip.checkinOptions.ticketNumber ||
            condensedTrip.checkoutOptions.ticketImage)) {
            condensedTrip.hasBothTickets = true;
        }
        else {
            condensedTrip.hasBothTickets = false;
        }
        var truckName = [];
        if (condensedTrip.truck && condensedTrip.truck.length) {
            truckName.push(condensedTrip.truck);
        }
        if (condensedTrip.truckType && condensedTrip.truckType.length) {
            truckName.push(condensedTrip.truckType);
        }
        condensedTrip.truckName = truckName.filter(Boolean).join(' - ');
        var tripName = [];
        if (condensedTrip.job) {
            tripName.push(condensedTrip.job);
        }
        if (condensedTrip.driver) {
            var value = condensedTrip.driver;
            if (truckName) {
                value = value + " (" + condensedTrip.truck + ")";
            }
            tripName.push(value);
        }
        condensedTrip.name = tripName.filter(Boolean).join(' - ');
        if (condensedTrip.routePolyline) {
            condensedTrip.mapImageURL = 'https://maps.googleapis.com/maps/api/staticmap?size=60x45&path=color:0x002649|weight:1|enc:';
            condensedTrip.mapImageURL += condensedTrip.routePolyline;
            condensedTrip.mapImageURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
            condensedTrip.fullsizeMapImageURL = 'https://maps.googleapis.com/maps/api/staticmap?size=800x600&path=color:0x002649|weight:4|enc:';
            condensedTrip.fullsizeMapImageURL += condensedTrip.routePolyline;
            condensedTrip.fullsizeMapImageURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
        }
        condensedTrip.weightRequired = condensedTrip.invoiceType === 'weight' || condensedTrip.haulType === 'weight';
        if (condensedTrip.invoiceSent) {
            condensedTrip.invoiceTitle = 'Sent #' + condensedTrip.invoiceNumber;
        }
        else if (condensedTrip.invoiceNumber) {
            condensedTrip.invoiceTitle = 'Draft #' + condensedTrip.invoiceNumber;
        }
        if (condensedTrip.paystubSent) {
            condensedTrip.paystubTitle = 'Paid #' + condensedTrip.paystubNumber;
        }
        else if (condensedTrip.paystubNumber) {
            condensedTrip.paystubTitle = 'Draft #' + condensedTrip.paystubNumber;
        }
        if (condensedTrip.expenseSent) {
            condensedTrip.expenseTitle = 'Paid #' + condensedTrip.expenseNumber;
        }
        else if (condensedTrip.expenseNumber) {
            condensedTrip.expenseTitle = 'Draft #' + condensedTrip.expenseNumber;
        }
        switch (condensedTrip.invoiceWeightUnit) {
            case 'cuyds':
                condensedTrip.invoiceWeightUnitDisplay = 'cu. yd.';
                break;
            default:
                condensedTrip.invoiceWeightUnitDisplay = condensedTrip.invoiceWeightUnit;
        }
        switch (condensedTrip.haulWeightUnit) {
            case 'cuyds':
                condensedTrip.haulWeightUnitDisplay = 'cu. yd.';
                break;
            default:
                condensedTrip.haulWeightUnitDisplay = condensedTrip.haulWeightUnit;
        }
        if (condensedTrip.haulType === 'weight') {
            switch (condensedTrip.haulWeightUnit) {
                case 'cuyds':
                    condensedTrip.haulRateUnit = 'cu. yd.';
                    break;
                default:
                    condensedTrip.haulRateUnit = condensedTrip.haulWeightUnit;
            }
        }
        else {
            condensedTrip.haulRateUnit = condensedTrip.haulType;
        }
        if (condensedTrip.invoiceType === 'weight') {
            switch (condensedTrip.invoiceWeightUnit) {
                case 'cuyds':
                    condensedTrip.invoiceRateUnit = 'cu. yd.';
                    break;
                default:
                    condensedTrip.invoiceRateUnit = condensedTrip.invoiceWeightUnit;
            }
        }
        else {
            condensedTrip.invoiceRateUnit = condensedTrip.invoiceType;
        }
        switch (condensedTrip.invoiceType) {
            case 'weight':
                condensedTrip.invoiceTypeName = condensedTrip.invoiceWeightUnitDisplay;
                break;
            default:
                condensedTrip.invoiceTypeName = condensedTrip.invoiceType;
        }
        switch (condensedTrip.haulType) {
            case 'weight':
                condensedTrip.haulTypeName = condensedTrip.haulWeightUnitDisplay;
                break;
            default:
                condensedTrip.haulTypeName = condensedTrip.haulType;
        }
        var ticketNumbers = [];
        if (condensedTrip.loadingTicketNumber && condensedTrip.loadingTicketNumber.length) {
            ticketNumbers.push(condensedTrip.loadingTicketNumber);
        }
        if (condensedTrip.unloadingTicketNumber && condensedTrip.unloadingTicketNumber.length) {
            ticketNumbers.push(condensedTrip.unloadingTicketNumber);
        }
        condensedTrip.ticketNumbers = ticketNumbers.filter(Boolean).join(', ');
        if (condensedTrip.retakeStatus && condensedTrip.retakeStatus === 'requested') {
            condensedTrip.retakeRequested = true;
        }
        else {
            condensedTrip.retakeRequested = false;
        }
        if (condensedTrip.void) {
            condensedTrip.administrativeStatus = 'void';
        }
        else if (condensedTrip.retakeStatus === 'requested') {
            condensedTrip.administrativeStatus = 'retake';
        }
        else if (condensedTrip.edited) {
            condensedTrip.administrativeStatus = 'edited';
        }
        else {
            condensedTrip.administrativeStatus = '';
        }
        if (condensedTrip.administrativeStatus && condensedTrip.administrativeStatus.length) {
            condensedTrip.displayableStatus = true;
        }
        else {
            condensedTrip.displayableStatus = false;
        }
        var scheduleParts = [];
        if (condensedTrip.formattedStartTime && condensedTrip.formattedStartTime.length) {
            scheduleParts.push(condensedTrip.formattedStartTime);
        }
        if (condensedTrip.endTimeTimestamp && condensedTrip.endTimeTimestamp.length) {
            scheduleParts.push(condensedTrip.formattedEndTime);
        }
        else {
            scheduleParts.push('OPEN');
        }
        condensedTrip.schedule = scheduleParts.filter(Boolean).join(' - ');
        return condensedTrip;
    };
    CondensedTripSerializer.prototype.toJson = function (condensedTrip) {
        var json = {
            id: condensedTrip.id
        };
        for (var property in json) {
            if (json[property] === undefined) {
                delete json[property];
            }
        }
        return decamelizeKeysDeep(json);
    };
    return CondensedTripSerializer;
}());
exports.CondensedTripSerializer = CondensedTripSerializer;
