import {
  Component,
  Input,
  Output,
  OnInit,
  HostListener,
  ElementRef,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// angular material
import { MatDialog, MatDialogRef } from '@angular/material';

// libraries
import { includes, find as _find, filter } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

// components
import { RuckitConfirmDialogComponent } from '../../shared/dialogs/index';
import { DropdownComponent } from '../../shared/index';
import { TagInputComponent } from '../../shared/tag-input/tag-input.component';
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

// services
import { TranslateService } from '@ngx-translate/core';
import { DriverService } from './../driver.service';
import { ApiService } from '../../shared/api.service';
import { AuthenticationService } from '../../shared/index';
import { ConnectionService } from '../../connections/connection.service';
import { parseErrors } from '../../shared/api.service';
import { CustomFieldService } from '../../custom-fields/custom-field.service';
import { TruckService } from '../../trucks/truck.service';

// models
import { CustomField, CustomFieldKind } from '../../custom-fields/custom-field';
import { Driver, DRIVER_DUTY_OPTIONS } from '../../drivers/driver';
import { User } from '../../users/user';

// animations
import { editDriverAnimation } from '../../shared/animations/edit-driver.animation';

@Component({
  selector: 'ruckit-edit-driver',
  templateUrl: './edit-driver.component.html',
  styleUrls: ['./edit-driver.component.scss'],
  animations: [editDriverAnimation],
})
export class EditDriverComponent implements OnInit {
  @Input() driver: any = { user: {}, profile: {} };
  @Output() completed = new EventEmitter();
  user: User;
  editDriverForm: FormGroup;
  open = false;
  states = [];
  stateOptions = [];
  countryOptions = [];
  loading = false;
  errors = [];
  confirmDialog: MatDialogRef<any>;
  device;

  // carriers dropdown config
  carriersConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'organization.carrier.id',
    selectText: this.translate.instant('Select Fleet'),
    loadingText: this.translate.instant('Loading Fleets...'),
    noResultsText: this.translate.instant('No Fleets'),
    service: ConnectionService,
    serviceFunction: 'list',
    query: {
      ordering: 'organization__name',
      is_carrier: 'True',
      allow_dispatch: 'True',
      status: 'active',
    },
    customOptionKeys: ['organization'],
  };
  @ViewChild('carriersDropdown', { static: false })
  carriersDropdown: RuckitDropdownComponent;

  // trucks dropdown config
  trucksConfig = {
    searchable: true,
    nameProperty: 'displayName',
    selectText: 'Assign Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    service: TruckService,
    serviceFunction: 'list',
    groupBy: 'carrierOrganizationName',
    query: {
      ordering: 'name',
      carrier: '',
    },
    prefilledOptions: [
      {
        displayName: this.translate.instant('No Truck'),
        id: null,
      },
    ],
  };
  @ViewChild('trucksDropdown', { static: false })
  trucksDropdown: RuckitDropdownComponent;
  truckFirstLoad = true;

  // duty status
  @ViewChild('dutyStatusDropdown', { static: true })
  dutyStatusDropdown: DropdownComponent;
  dutyStatusConfig = { nameProperty: 'name' };
  dutyStatusOptions = [...DRIVER_DUTY_OPTIONS];

  // tags
  @ViewChild(TagInputComponent, { static: false }) tagInput;
  customFields: { [key: string]: CustomField } = {};

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    let row = event && event.target && event.target.closest('.row');
    if (
      !includes(event.target.classList, 'row') &&
      !(row && row.contains(event.target))
    ) {
      let confirmDialog = document.querySelector('ruckit-confirm-dialog');
      if (
        !includes(event.target.classList, 'icon-more') &&
        !includes(event.target.classList, 'mat-chip-remove') &&
        !includes(event.target.classList, 'mat-option-text') &&
        !this._eref.nativeElement.contains(event.target) &&
        !(confirmDialog && confirmDialog.contains(event.target))
      ) {
        this.close();
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private driverService: DriverService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private _eref: ElementRef,
    private customFieldService: CustomFieldService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.onInit();
  }

  onInit() {
    this.user = this.authenticationService.user();
    this.checkDevice();
    this.getCountries();
    this.getCustomFields();
    this.states = this.apiService.getStates();
    this.setCountry();
  }

  checkDevice(): void {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop(),
    };
  }

  createEditDriverForm(driver: Driver) {
    const phonePattern = '(^$)|([- +()0-9]+)';
    const form = this.fb.group({
      profile: this.fb.group({
        firstName: [
          driver && driver.profile ? driver.profile.firstName : '',
          [Validators.required, Validators.minLength(1)],
        ],
        lastName: [
          driver && driver.profile ? driver.profile.lastName : '',
          [Validators.required, Validators.minLength(1)],
        ],
        email: [driver.profile ? driver.profile.email : '', [Validators.email]],
        phoneNumber: [
          driver.profile ? driver.profile.phoneNumber : '',
          [Validators.pattern(phonePattern)],
        ],
      }),
      cdl: [driver.cdl, [Validators.minLength(1)]],
      uniqueBillingId: [driver.uniqueBillingId, [Validators.minLength(1)]],
      user: this.fb.group({
        username: [
          driver && driver.user ? driver.user.username : '',
          [Validators.required, Validators.minLength(1)],
        ],
        password: [
          {
            value: driver && driver.user ? driver.user.password : '',
            disabled: true,
          },
          [Validators.required, Validators.minLength(1)],
        ],
      }),
      carrier: [driver.carrier || null],
      truck: [driver.truck || null],
      dutyStatus: [driver.dutyStatus || null],
      street: [driver.street || null],
      city: [driver.city || null],
      country: [driver.country || null],
      state: [driver.state || null],
      zipcode: [driver.zipcode || null],
      tags: [driver.tags || null],
      customFieldData: this.fb.group({}),
    });

    const customFields = form.get('customFieldData') as FormGroup;
    Object.keys(this.customFields).forEach((field) => {
      customFields.addControl(
        this.customFields[field].key,
        new FormControl(driver.customFieldData[this.customFields[field].key])
      );
    });

    return form;
  }

  setInitialValues() {
    this.editDriverForm = this.createEditDriverForm({ ...this.driver });
    this.truckFirstLoad = this.driver.truck ? true : false;
    this.errors = [];
    if (this.user.organization.carrier.id === this.driver.carrier.id) {
      this.trucksConfig.query = {
        ordering: 'name',
        carrier: null,
      };
    } else {
      this.trucksConfig.query = {
        ordering: 'name',
        carrier: this.driver.carrier.id,
      };
    }
    this.setCountry();
  }

  setCountry() {
    if (this.driver && this.driver.country) {
      this.optionSelected(this.driver.country, 'country', false);
    } else {
      this.optionSelected('US', 'country', false);
    }
  }

  public setOpen(): void {
    this.editDriverForm = null;
    setTimeout(() => {
      this.setInitialValues();
      this.open = true;
      this.addUserCarrierToCarriersDropdown();
    }, 300);
  }

  addUserCarrierToCarriersDropdown() {
    setTimeout(() => {
      if (this.carriersDropdown) {
        const userCarrierOption = {
          ...this.user.organization.carrier,
          organization: this.user.organization,
        };
        this.carriersDropdown.addAnOptionToStart(userCarrierOption);
      }
    }, 500);
  }

  close(): void {
    this.open = false;
  }

  onPasswordChange() {
    const passwordControl = this.getFormControlWithArgs(['user', 'password']);
    if (passwordControl.enabled) {
      passwordControl.disable();
    } else {
      passwordControl.enable();
    }
  }

  onSubmit() {
    this.loading = true;

    // get all form values that were changed
    const changedValues = this.getDirtyValues(this.editDriverForm);
    let modifiedDriver = { ...this.driver };

    // profile and user are nested objects, that's why it's more complicted
    // truck only needs id
    Object.keys(changedValues).forEach((key) => {
      switch (key) {
        case 'profile':
          modifiedDriver = {
            ...modifiedDriver,
            profile: {
              ...modifiedDriver.profile,
              ...changedValues['profile'],
            },
          };
          // combine first and lastname so the change will be seen on emitting
          modifiedDriver.name = `${modifiedDriver.profile.firstName} ${modifiedDriver.profile.lastName}`;
          modifiedDriver.profile.name = `${modifiedDriver.profile.firstName} ${modifiedDriver.profile.lastName}`;
          break;
        case 'user':
          modifiedDriver = {
            ...modifiedDriver,
            user: {
              ...modifiedDriver.user,
              ...changedValues['user'],
            },
          };
          break;
        case 'truck':
          modifiedDriver.truck = this.driver.truck.id;
          break;

        default:
          modifiedDriver[key] = changedValues[key];
          break;
      }
    });
    this.saveChanges(modifiedDriver);
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }

  getCountries(): void {
    this.countryOptions = this.apiService.getCountries().map((country) => {
      return {
        id: country.abbreviation,
        name: country.name,
      };
    });
  }

  getCustomFields(): void {
    this.customFieldService
      .getIndexedFieldsForKind(CustomFieldKind.Driver)
      .subscribe((fields) => {
        this.customFields = fields;
      });
  }

  saveChanges(modifiedDriver) {
    this.errors = [];
    this.driverService.saveDriver(modifiedDriver).subscribe(
      () => {
        modifiedDriver['filterOptions'] = this.driver.filterOptions;
        this.completed.emit(modifiedDriver);
        this.open = false;
        this.loading = false;
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  remove(): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px',
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translate.instant('Delete Driver?'),
      body: this.translate.instant(
        'You will no longer be able to dispatch this driver. A deleted driver will be placed in the archived section where you can reactivate at a later time.'
      ),
      close: this.translate.instant('Cancel'),
      accept: this.translate.instant('Delete'),
    };

    this.confirmDialog.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.driverService.remove(this.driver).subscribe(
          () => {
            this.driver.status = 'deleted';
            this.open = false;
            this.loading = false;
            this.completed.emit(this.driver);
          },
          (err) => {
            this.errors = err;
            this.loading = false;
          }
        );
      }
      this.confirmDialog = null;
    });
  }

  /**
   * Updates a specified field on the driver object, with specified functionality for particular fields
   *
   * @param {any} value The updated value to be applied to the driver object
   * @param {string} field The field name which the value will be set on
   * @param {boolean} makeFormControlDirty make form control dirty or not
   */
  optionSelected(value: any, field: string, makeFormControlDirty = true) {
    switch (field) {
      case 'country':
        this.stateOptions = filter(this.states, { country: value }).map(
          (_state) => {
            return {
              id: _state.abbreviation,
              name: _state.name,
            };
          }
        );
        this.driver.country = value;
        break;

      case 'carrier':
        const carrier =
          value.organization && value.organization.carrier
            ? value.organization.carrier
            : null;
        if (carrier === null) {
          return;
        }
        if (this.driver.carrier.id === carrier.id) {
          makeFormControlDirty = false;
        } else {
          this.driver.carrier = carrier;
          this.driver.carrierName = carrier.name;
          if (this.user.organization.carrier.id === carrier.id) {
            this.getTrucksWithCarrierId(null);
          } else {
            this.getTrucksWithCarrierId(carrier.id);
          }
          this.driver.truck = '';
        }
        break;

      case 'dutyStatus':
        this.driver[field] = value;
        this.dutyStatusOptions.forEach((option) => {
          if (value === option.id) {
            this.driver.displayDutyStatus = option.name;
          }
        });
        break;

      case 'tags':
        if (value.length > 0) {
          this.driver['tags'] = value;
        } else {
          this.driver['tags'] = [];
        }
        break;

      default:
        this.driver[field] = value;
        break;
    }
    if (this.editDriverForm && makeFormControlDirty) {
      if (field === 'truck' && this.truckFirstLoad) {
        this.truckFirstLoad = false;
      } else {
        this.setFormControlValueAndMarkDirty(field, value);
      }
    }
  }

  getTrucksWithCarrierId(carrierId: string) {
    this.trucksConfig.query.carrier = carrierId;
    this.trucksDropdown.getRecords({
      carrier: carrierId,
    });
  }

  setFormControlValueAndMarkDirty(args: string[] | string, value: any) {
    const formControl = this.editDriverForm.get(args);
    formControl.setValue(value);
    formControl.markAsDirty();
  }

  getFormControlWithArgs(args: string[] | string): AbstractControl {
    return this.editDriverForm.get(args);
  }
}
