"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column;position:relative}.app-content[_ngcontent-%COMP%]   .action[_ngcontent-%COMP%]{display:flex;justify-content:space-between}.app-content[_ngcontent-%COMP%]   .action[_ngcontent-%COMP%]   .left-action[_ngcontent-%COMP%]{display:flex}.app-content[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 70px - 30px - 100px)!important}.filters[_ngcontent-%COMP%]{display:flex;align-items:center;padding:0 30px;background:#f4f6f9;height:50px;border-bottom:1px solid rgba(0,0,0,.07);font-size:14px;font-weight:600;color:#7f8fa4}.filter[_ngcontent-%COMP%]{padding-right:0;white-space:nowrap;margin-left:2px}.filter[_ngcontent-%COMP%]:first-child{margin-left:10px}.filter[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#c7d2e0;font-size:22px}.container[_ngcontent-%COMP%]{padding:30px;position:relative;overflow:auto}.container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]   .data[_ngcontent-%COMP%]{width:100px}.container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{z-index:999}.container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]     action-menu .action-menu-icon{transform:rotate(90deg) scale(1.25);line-height:45px;margin-right:10px}"];
exports.styles = styles;
