import { Pipe, PipeTransform } from '@angular/core';
import { TRIPTICKETIMAGETYPE } from '../../app.constants';
import { Attachment } from '../../attachments/attachment.interface';

@Pipe({
  name: 'attachmentType',
})
export class AttachmentTypePipe implements PipeTransform {
  constructor() {}
  transform(
    images: Attachment[],
    type: string = TRIPTICKETIMAGETYPE
  ): Attachment[] {
    return images && images.length
      ? images.filter((img) => img.fileType === type)
      : [];
  }
}
