"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var work_order_template_1 = require("./work-order-template");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var WorkOrderTemplateSerializer = /** @class */ (function () {
    function WorkOrderTemplateSerializer() {
    }
    WorkOrderTemplateSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var template = new work_order_template_1.WorkOrderTemplate();
        if (!json) {
            return template;
        }
        template.id = json.id;
        template.createdAt = json.createdAt;
        template.name = json.name;
        template.html = json.html;
        template.pdf = json.pdf;
        return template;
    };
    WorkOrderTemplateSerializer.prototype.toJson = function (template) {
        var json = {
            id: template.id,
            createdAt: template.createdAt,
            name: template.name
        };
        return decamelizeKeysDeep(json);
    };
    return WorkOrderTemplateSerializer;
}());
exports.WorkOrderTemplateSerializer = WorkOrderTemplateSerializer;
