"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content.connections-container[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column}.app-content.connections-container[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 70px - 30px - 470px)!important}.page-actions[_ngcontent-%COMP%]{float:right;padding:15px;margin-top:1px}li[_ngcontent-%COMP%]{width:90%;font-size:14px;float:left;clear:both;text-align:center;list-style:none;margin-bottom:10px;padding:20px;background-color:#fff;border:1px solid #ccc;box-shadow:0 4px 4px 0 rgba(0,0,0,.5)}.organization-info[_ngcontent-%COMP%]{width:25%;float:left;text-align:left}.organization-info[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{font-size:18px;display:inline}.organization-contact[_ngcontent-%COMP%]{width:50%;float:left}.organization-contact[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{padding:10px;text-align:center;float:left}.organization-status[_ngcontent-%COMP%]{padding:10px;float:left;background:#999;border-radius:15px;text-align:center;font-style:italic;color:#fff;width:15%}.organization-actions[_ngcontent-%COMP%]{padding:10px;text-align:right;flex:1 1 auto}.list-table[_ngcontent-%COMP%]{border:0;margin:-20px 0 0;height:100%}.table-body[_ngcontent-%COMP%]{background:0 0;min-height:10em;overflow-y:scroll;height:100%;padding-bottom:20px;padding-top:20px;border-radius:0 0 3px 3px}@media (max-width:500px){.list-table[_ngcontent-%COMP%]{margin:0}.table-body[_ngcontent-%COMP%]{padding:0}}"];
exports.styles = styles;
