import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { JobEvent } from '../job-events/job-event';
import { ConnectionService } from '../connections/connection.service';
import { Carrier } from '../carriers/carrier';

@Component({
  selector: 'mobile-dispatch-carriers',
  templateUrl: './mobile-dispatch-carriers.component.html',
  styleUrls: ['./mobile-dispatch-carriers.component.scss']
})
export class MobileDispatchCarriersComponent implements OnInit, OnDestroy {
  @Input() jobEvent: JobEvent;
  @Input() scrollEvent: any;
  @Output() selectCarrier: EventEmitter<Carrier> = new EventEmitter<Carrier>();
  carriers: any[] = [];
  carriersReq: Subscription;
  loading = false;
  errors = [];

  constructor(
    private connectionService: ConnectionService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollEvent && !this.loading &&
      this.scrollEvent && this.scrollEvent.target &&
      this.scrollEvent.target.scrollTop >
      this.scrollEvent.target.scrollHeight -
      this.scrollEvent.target.clientHeight * 3) {
      this.getCarriers(true);
    }
  }

  ngOnInit() {
    this.getCarriers();
  }

  ngOnDestroy() {
    if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
      this.carriersReq.unsubscribe();
    }
  }

  select(carrier: Carrier): void {
    this.selectCarrier.emit(carrier);
  }

  getCarriers(next = false) {
    this.loading = true;
    if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
      this.carriersReq.unsubscribe();
    }
    let connectionServiceRequest = this.connectionService.list({
      ordering: 'organization__name',
      allow_dispatch: 'True',
      is_carrier: 'True'
    });

    if (!next) {
      this.carriers = [
        new Carrier({ name: 'My Drivers', id: 'my_drivers' }),
        new Carrier({ name: 'All Carriers', id: 'all_carriers' }),
      ];
      if (this.jobEvent
        && this.jobEvent.job
        && this.jobEvent.job.project
        && this.jobEvent.job.project.customerOrganization
        && this.jobEvent.job.project.customerOrganization['hasLeasedOrgs']) {
        this.carriers.push({ name: 'Leased', id: 'all_leased' });
      }
    } else {
      connectionServiceRequest = this.connectionService.listNext();
    }

    this.carriersReq = connectionServiceRequest && connectionServiceRequest.subscribe(connections => {
      let _carriers = connections.map(connection => {
        return {
          name: connection.organization.name,
          id: connection.organization.carrier.id
        };
      });
      this.carriers = this.carriers.concat(_carriers);
    }, err => {
      this.errors = err;
    }, () => this.loading = false);
    if (!connectionServiceRequest) { this.loading = false; }
  }
}
