"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var driver_job_reports_component_1 = require("./driver-job-reports.component");
var routes = [
    { path: '', component: driver_job_reports_component_1.DriverJobReportsComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var DriverReportsRoutingModule = /** @class */ (function () {
    function DriverReportsRoutingModule() {
    }
    return DriverReportsRoutingModule;
}());
exports.DriverReportsRoutingModule = DriverReportsRoutingModule;
