"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var operators_1 = require("rxjs/operators");
var truck_type_serializer_1 = require("./truck-type.serializer");
var resource_service_1 = require("../shared/resource.service");
var index_1 = require("../shared/index");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TruckTypeService = /** @class */ (function (_super) {
    __extends(TruckTypeService, _super);
    function TruckTypeService(http) {
        return _super.call(this, http, 'trucktypes/', new truck_type_serializer_1.TruckTypeSerializer()) || this;
    }
    TruckTypeService.prototype.updateTruckTypes = function (truckTypeData) {
        var truckTypeUrl = this.baseUrl + 'trucktypes/config/';
        var _truckTypeData = decamelizeKeysDeep(lodash_1.clone(truckTypeData));
        return this.http.put(truckTypeUrl, _truckTypeData, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    return TruckTypeService;
}(resource_service_1.ResourceService));
exports.TruckTypeService = TruckTypeService;
