<div class="app-content">
  <my-toolbar title="{{ 'Team' | translate }}"></my-toolbar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   (columnChange)="columnsChanged($event)"
                   [config]="tableConfig">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value team-member-name" *ngSwitchCase="'name'">
              <div class="user-photo">
                <span *ngIf="!row.image">{{ row.name[0] }}</span>
                <img *ngIf="row.image" [src]="row.image" />
              </div>
              <div class="title">{{ row.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'title'">
              <div class="data">{{ row.title || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'email'">
              <div class="data">{{ row.email || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'phone'">
              <div class="data">{{ row.phoneNumber || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'markets'">
              <div class="data">
                <tag-display [tags]="row.tags" label="{{ 'Markets' | translate }}"></tag-display>
              </div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Team</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>

        <filters-panel [(availableFilters)]="availableFilters"
                       [(appliedFilters)]="appliedFilters"
                       [(search)]="search"></filters-panel>

        <a mat-icon-button color="accent" class="add-button" (click)="openDialog()">
          <mat-icon>add_circle</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
