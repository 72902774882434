"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var punch_card_1 = require("./punch-card");
var condensed_punch_card_1 = require("./condensed-punch-card");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PunchCardService = /** @class */ (function () {
    function PunchCardService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    PunchCardService.prototype.getPunchCard = function (punchCardId) {
        var _this = this;
        var punchCardUrl = this.baseUrl + 'punchcards/' + punchCardId + '/';
        return this.http.get(punchCardUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardService.prototype.save = function (punchCard) {
        var punchCardsUrl = this.baseUrl + 'punchcards/';
        var _punchCard = decamelizeKeysDeep(lodash_1.clone(punchCard));
        if (_punchCard.assignment && typeof _punchCard.assignment === 'object') {
            _punchCard.assignment = _punchCard.assignment.id;
        }
        if (typeof _punchCard.driver === 'object') {
            _punchCard.driver = _punchCard.driver.id;
        }
        if (typeof _punchCard.truck === 'object') {
            _punchCard.truck = _punchCard.truck.id;
        }
        if (typeof _punchCard.carrier === 'object') {
            _punchCard.carrier = _punchCard.carrier.id;
        }
        if (typeof _punchCard.job_event === 'object') {
            _punchCard.jobevent = _punchCard.job_event.id;
        }
        if (typeof _punchCard.jobevent === 'object') {
            _punchCard.jobevent = _punchCard.jobevent.id;
        }
        delete _punchCard.ticket_image;
        if (!punchCard.id) {
            _punchCard.start_time = _punchCard.start_time_timestamp || _punchCard._start_time_timestamp;
            _punchCard.end_time = _punchCard.end_time_timestamp || _punchCard._end_time_timestamp;
            delete _punchCard.id;
            return this.http.post(punchCardsUrl, _punchCard, { headers: api_service_1.requestHeaders() });
        }
        else {
            _punchCard.start_time = _punchCard.start_time_timestamp || _punchCard._start_time_timestamp;
            _punchCard.end_time = _punchCard.end_time_timestamp || _punchCard._end_time_timestamp;
            delete _punchCard.assignment;
            return this.http.put(punchCardsUrl + punchCard.id + '/', _punchCard, { headers: api_service_1.requestHeaders() });
        }
    };
    PunchCardService.prototype.saveWithFile = function (punchCard, ticketImageFile) {
        var _this = this;
        if (ticketImageFile === void 0) { ticketImageFile = null; }
        if (punchCard) {
            var _punchCard_1 = lodash_1.clone(punchCard);
            if (_punchCard_1.assignment && typeof _punchCard_1.assignment === 'object') {
                _punchCard_1.assignment = _punchCard_1.assignment.id;
            }
            if (_punchCard_1.driver && typeof _punchCard_1.driver === 'object') {
                _punchCard_1.driver = _punchCard_1.driver.id;
            }
            if (_punchCard_1.truck && typeof _punchCard_1.truck === 'object') {
                _punchCard_1.truck = _punchCard_1.truck.id;
            }
            if (_punchCard_1.jobEvent && typeof _punchCard_1.jobEvent === 'object') {
                _punchCard_1.jobevent = _punchCard_1.jobEvent.id;
                delete _punchCard_1.job_event;
            }
            if (_punchCard_1.carrier && typeof _punchCard_1.carrier === 'object') {
                _punchCard_1.carrier = _punchCard_1.carrier.id;
            }
            if (_punchCard_1.jobevent && typeof _punchCard_1.jobevent === 'object') {
                _punchCard_1.jobevent = _punchCard_1.jobevent.id;
            }
            else if (_punchCard_1.jobeventId) {
                _punchCard_1.jobevent = _punchCard_1.jobeventId;
            }
            delete _punchCard_1.job;
            delete _punchCard_1.ticketImage;
            delete _punchCard_1.assignment;
            delete _punchCard_1.endLocation;
            delete _punchCard_1.startLocation;
            delete _punchCard_1.void;
            if (_punchCard_1.id === undefined) {
                delete _punchCard_1.id;
            }
            _punchCard_1 = decamelizeKeysDeep(_punchCard_1);
            _punchCard_1.start_time = _punchCard_1.start_time_timestamp || _punchCard_1._start_time_timestamp;
            _punchCard_1.end_time = _punchCard_1.end_time_timestamp || _punchCard_1._end_time_timestamp;
            return rxjs_1.Observable.create(function (observer) {
                var formData = new FormData(), xhr = new XMLHttpRequest();
                Object.keys(_punchCard_1).map(function (key, index) {
                    var value = _punchCard_1[key];
                    formData.append(key, value);
                });
                if (ticketImageFile) {
                    formData.append('ticket_image', ticketImageFile, ticketImageFile.name);
                }
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 201) {
                            observer.next(JSON.parse(xhr.response));
                            observer.complete();
                        }
                        else {
                            observer.error(xhr.response);
                        }
                    }
                };
                if (_punchCard_1 && _punchCard_1.id) {
                    delete _punchCard_1.assignment;
                    var punchCardUrl = _this.baseUrl + 'punchcards/' + _punchCard_1.id + '/';
                    xhr.open('PUT', punchCardUrl, true);
                }
                else {
                    var punchCardUrl = _this.baseUrl + 'punchcards/';
                    xhr.open('POST', punchCardUrl, true);
                }
                api_service_1.requestHeaders(xhr);
                xhr.send(formData);
            });
        }
    };
    PunchCardService.prototype.getPunchCards = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var punchCardsUrl = this.baseUrl + 'punchcards/';
        return this.http.get(punchCardsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardService.prototype.getCondensedPunchCards = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString()) {
                    params = params.set(key, query[key] && query[key].toString());
                }
            });
        }
        var punchCardsUrl = this.baseUrl + 'punchcards/condensed/';
        return this.http.get(punchCardsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardService.prototype.getCondensedPunchCard = function (punchCardId) {
        var _this = this;
        var punchCardUrl = this.baseUrl + 'punchcards/' + punchCardId + '/condensed/';
        return this.http.get(punchCardUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardService.prototype.getExportFields = function () {
        var punchCardUrl = this.baseUrl + 'punchcards/pandas-export/';
        return this.http.get(punchCardUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return lodash_1.sortBy(res, 'label'); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardService.prototype.export = function (scope, params) {
        if (params === void 0) { params = null; }
        var punchCardUrl = this.baseUrl + 'punchcards/pandas-export/';
        var _scope = lodash_1.clone(decamelizeKeysDeep(scope));
        return this.http.post(punchCardUrl + '?' + params, _scope, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.catchError(api_service_1.handleError));
    };
    PunchCardService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PunchCardService.prototype.getNextCondensed = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PunchCardService.prototype.uploadImage = function (method, punchCardId, propertyName, files) {
        var _this = this;
        return rxjs_1.Observable.create(function (observer) {
            var formData = new FormData(), xhr = new XMLHttpRequest();
            for (var i = 0; i < files.length; i++) {
                formData.append(propertyName, files[i], files[i].name);
            }
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    }
                    else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = function (event) {
                _this.progress = Math.round(event.loaded / event.total * 100);
            };
            var url = _this.baseUrl + 'punchcards/' + punchCardId + '/';
            xhr.open(method, url, true);
            api_service_1.requestHeaders(xhr);
            xhr.send(formData);
        });
    };
    PunchCardService.prototype.extractData = function (res, condensed) {
        if (condensed === void 0) { condensed = false; }
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (condensed) {
            return body.map(function (punchCard) {
                return new condensed_punch_card_1.CondensedPunchCard(punchCard);
            });
        }
        else if (body) {
            return body.map(function (punchCard) {
                return new punch_card_1.PunchCard(punchCard);
            });
        }
        else if (resObj) {
            return new punch_card_1.PunchCard(resObj);
        }
        else {
            return {};
        }
    };
    return PunchCardService;
}());
exports.PunchCardService = PunchCardService;
