import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../../shared/resource.service';
import { EndOfDayOverallStatsSerializer } from './end-of-day-overall.serializer';
import { EndOfDayOverallStats } from './end-of-day-overall-stats';

@Injectable()
export class EndOfDayOverallStatsService extends ResourceService<EndOfDayOverallStats> {
  constructor(http: HttpClient) {
    super(http, 'endofdaystats/', new EndOfDayOverallStatsSerializer());
  }
}
