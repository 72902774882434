"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{position:relative;flex-grow:1}form[_ngcontent-%COMP%]   .field-group.state[_ngcontent-%COMP%]{margin-left:0;padding-right:10px;width:50%}form[_ngcontent-%COMP%]   .field-group.state[_ngcontent-%COMP%]   .dropdown[_ngcontent-%COMP%]   .dropdown-button[_ngcontent-%COMP%]{margin-bottom:0}form[_ngcontent-%COMP%]   .field-group.zip[_ngcontent-%COMP%]{margin-left:0;padding-left:10px;width:50%}form[_ngcontent-%COMP%]   .driver-image[_ngcontent-%COMP%]{height:250px;margin:45px auto 15px}form[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{margin-top:30px}form[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .cancel[_ngcontent-%COMP%]{margin-right:10px}form[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .use[_ngcontent-%COMP%]{margin-left:10px}#step-4[_ngcontent-%COMP%]{text-align:center}#step-4[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin-bottom:30px}#step-4[_ngcontent-%COMP%]   .actions.android[_ngcontent-%COMP%], #step-4[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin-top:30px}@media only screen and (max-width:1024px){.driver-invitation-form[_ngcontent-%COMP%]{padding:0 20px 20px}h2[_ngcontent-%COMP%]{font-size:26px;text-align:center}p[_ngcontent-%COMP%]{font-size:14px;text-align:center}}"];
exports.styles = styles;
