<div class="app-content">
  <my-toolbar title="{{ 'Work Order Templates' | translate }}"></my-toolbar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   (columnChange)="columnsChanged($event)"
                   [config]="tableConfig">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value name" *ngSwitchCase="'name'">
              <div class="data">{{ row.name || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchCase="'created-at'">
              <div class="data">{{ row.createdAt || '&nbsp;' }}</div>
            </div>
            <div class="value" *ngSwitchDefault>{{ value }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Work Order Templates</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>

        <filters-panel [(availableFilters)]="availableFilters"
                       [(appliedFilters)]="appliedFilters"
                       [(search)]="search"></filters-panel>
      </div>
    </div>
  </div>
</div>
