import { clone } from 'lodash';

import { Resource } from '../shared/resource';
import { Organization } from '../organizations/organization';
import { Product } from '../products/product';
import { PriceList } from '../price-lists/price-list';

export class Price extends Resource {
  id: string;
  product: Product;
  createdAt: string;
  lastModified: string;
  notes: string;
  priceChangeType: any;
  priceChangeAmount: string;
  priceList: PriceList;
  ownerOrganization: Organization;
  remove = false;
  priceChangeTypeOptions: Array<{}> = [];
  loading: boolean;
  classes: string;

  url(action): string {
    return '/inventory/price-lists/' + this.priceList.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
