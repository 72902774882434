"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var punch_card_filter_1 = require("./punch-card.filter");
var api_service_1 = require("../shared/api.service");
var PunchCardFilterService = /** @class */ (function () {
    function PunchCardFilterService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.nextUris = {};
    }
    PunchCardFilterService.prototype.listFilters = function (filterType, query) {
        var _this = this;
        var params = new http_1.HttpParams();
        params = params.set('page_size', '6');
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        var filtersUrl = this.baseUrl + 'punchcards/condensed/' + filterType + '/';
        return this.http.get(filtersUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardFilterService.prototype.get = function (filterType, query) {
        var _this = this;
        var params = new http_1.HttpParams();
        params = params.set('page_size', '1');
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        var filtersUrl = this.baseUrl + 'punchcards/condensed/' + filterType + '/';
        return this.http.get(filtersUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res, false, true); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardFilterService.prototype.getFilters = function (filterType, search) {
        var _this = this;
        if (search === void 0) { search = null; }
        var params = new http_1.HttpParams();
        params = params.set('page_size', '6');
        if (search) {
            params = params.set('search', search);
        }
        var filtersUrl = this.baseUrl + 'punchcards/condensed/' + filterType + '/';
        return this.http.get(filtersUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
    };
    PunchCardFilterService.prototype.getNext = function (filterType) {
        var _this = this;
        if (this.nextUris[filterType]) {
            return this.http.get(this.nextUris[filterType], { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PunchCardFilterService.prototype.listNext = function (filterType) {
        var _this = this;
        if (this.nextUris[filterType]) {
            return this.http.get(this.nextUris[filterType], { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(filterType, res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PunchCardFilterService.prototype.extractData = function (filterType, res, condensed, single) {
        if (condensed === void 0) { condensed = false; }
        if (single === void 0) { single = false; }
        var resObj = res;
        if (!single) {
            this.nextUris[filterType] = resObj['next'];
        }
        var body = resObj['results'];
        if (body) {
            var results = body.map(function (filter) {
                return new punch_card_filter_1.PunchCardFilter(filter);
            });
            return single ? results && results[0] : results;
        }
        else {
            return {};
        }
    };
    PunchCardFilterService.prototype.mergeFilters = function (filters) {
        return filters.map(function (filter) {
            if (filter.multiple && filter.values) {
                return filter.values.map(function (value) {
                    var _value = [filter.key, value].join('=');
                    return "(" + _value + ")";
                }).filter(Boolean).join('|');
            }
            else if (filter.values) {
                var values = filter.values;
                if (values === true) {
                    values = 'True';
                }
                if (values === false) {
                    values = 'False';
                }
                var _value = [filter.key, values].join('=');
                return "(" + _value + ")";
            }
        }).filter(Boolean).join('&');
    };
    return PunchCardFilterService;
}());
exports.PunchCardFilterService = PunchCardFilterService;
