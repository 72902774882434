"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./scaleit-confirm-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./scaleit-confirm-dialog.component");
var i3 = require("@angular/material/dialog");
var styles_ScaleitConfirmDialogComponent = [i0.styles];
var RenderType_ScaleitConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScaleitConfirmDialogComponent, data: {} });
exports.RenderType_ScaleitConfirmDialogComponent = RenderType_ScaleitConfirmDialogComponent;
function View_ScaleitConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "dialog-header-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDialog.close(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["+"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "dialog-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "dialog-body-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "dialog-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-cancel no"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDialog.close(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary yes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDialog.close(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attributes.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.attributes.body; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.attributes.no; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.attributes.yes; _ck(_v, 12, 0, currVal_3); }); }
exports.View_ScaleitConfirmDialogComponent_0 = View_ScaleitConfirmDialogComponent_0;
function View_ScaleitConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "scaleit-confirm-dialog", [], null, null, null, View_ScaleitConfirmDialogComponent_0, RenderType_ScaleitConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i2.ScaleitConfirmDialogComponent, [i3.MatDialogRef], null, null)], null, null); }
exports.View_ScaleitConfirmDialogComponent_Host_0 = View_ScaleitConfirmDialogComponent_Host_0;
var ScaleitConfirmDialogComponentNgFactory = i1.ɵccf("scaleit-confirm-dialog", i2.ScaleitConfirmDialogComponent, View_ScaleitConfirmDialogComponent_Host_0, {}, {}, []);
exports.ScaleitConfirmDialogComponentNgFactory = ScaleitConfirmDialogComponentNgFactory;
