"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var customer_service_1 = require("./customer.service");
var api_service_1 = require("../shared/api.service");
var organization_1 = require("../organizations/organization");
var NewCustomerDialogComponent = /** @class */ (function () {
    function NewCustomerDialogComponent(dialogRef, customerService) {
        this.dialogRef = dialogRef;
        this.customerService = customerService;
        this.title = 'Add a Customer';
        this.loading = false;
        this.model = {};
        this.errors = [];
    }
    NewCustomerDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.customerService.save(this.model).subscribe(function (res) {
            _this.dialogRef.close();
            var customer = res;
            _this.callback(new organization_1.Organization({ name: customer['organization']['name'], id: customer['organization']['id'] }));
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return NewCustomerDialogComponent;
}());
exports.NewCustomerDialogComponent = NewCustomerDialogComponent;
