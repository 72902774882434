import { clone, remove, find as _find } from 'lodash';

import { Tag } from '../tags/tag';
import { Resource } from '../shared/resource';
import { JobEventStat } from '../job-event-stats/job-event-stat';
import { TruckType } from '../trucks/truck-type';
import { Note } from '../shared/notes/note.types';

export class CondensedJobEvent extends Resource {
  jobId: string;
  jobName: string;
  jobNumber: string;
  jobAmountNeeded: string;
  projectName: string;
  customerId: string;
  customer: string;
  tags: Tag[];
  routePolyline: string;
  startLocation: string;
  startLocationName: string;
  startLocationPoint: any;
  startLocationGeofence: any;
  endLocation: string;
  endLocationName: string;
  endLocationPoint: any;
  endLocationGeofence: any;
  jobStartDate: string;
  jobEndDate: string;
  material: string;
  phaseCode: string;
  numTrucks: number;
  notes: string;
  shareNotes: string;
  rate: string;
  invoiceType: string;
  invoiceWeightUnit: string;
  invoiceRateUnit: string;
  externalIdentifier: string;
  shift1Start: string;
  shift1End: string;
  shift2Start: string;
  shift2End: string;
  jobEnd: string;
  overnight: boolean;
  cancelled: boolean;
  canEdit: boolean;
  jobDisplayName: string;
  loadDeliveryTarget: string;
  dailyDeliveryTarget: string;
  dailyDeliveryTargetType: string;
  totalAmountType: string;
  status: string;
  shift1Status: string;
  shift2Status: string;
  statusText: string;
  mapImageURL: string;
  directions: any;
  notice: any;
  stats: JobEventStat;
  workOrderTemplate: string;
  workOrderTemplateName: string;
  orderNumber: string;
  orderNumberJobName?: string;
  deliveryInterval: string;
  deliveryIntervalUnit: string;
  truckTypes: TruckType[];
  truckTypeNames: string;
  allowAnyTruckType: boolean;
  lastDispatchedTime: string;
  slorderId: string;
  dsorderId: string;
  connexActualLoads: string;
  connexActualQuantity: string;
  pin: string;
  requestedAmount: number;
  requestedUnit: { key: string; name: string; active: boolean };
  notesList: Note[];

  // buffer times
  defaultRoundTripTime: number;
  defaultYardPrepTime: number;
  defaultYardBufferTime: number;
  defaultYardBufferMinutes: number;
  apexCustomerJob?: string;

  url(action: string): string {
    if (action === 'dispatch-drivers') {
      return `/dispatch/${this.jobId}/${this.id}`;
    } else if (action === 'add-collaborators') {
      return `/jobs/${this.jobId}/${this.id}/collaborators`;
    } else if (action === 'edit') {
      return `/jobs/${this.jobId}/edit`;
    } else if (action === 'clone') {
      return `/jobs/${this.jobId}/clone`;
    } else if (action === 'view-tickets') {
      return `/orders/${this.id}/tickets`;
    } else {
      return `/jobs/${this.jobId}/${this.id}/${action}`;
    }
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.cancelled) {
      let _option = _find(options, { action: 'cancel-day' });
      if (_option) {
        remove(options, _option);
      }
    }

    if (!this.canEdit) {
      options = options.filter(
        (option) => !['edit', 'edit-days', 'cancel-day'].includes(option.action)
      );
    }

    return options;
  }
}
