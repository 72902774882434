"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var device_status_event_1 = require("./device-status-event");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DeviceStatusEventSerializer = /** @class */ (function () {
    function DeviceStatusEventSerializer() {
    }
    /**
     * @param  {any} json
     * @returns DeviceStatusEvent
     */
    DeviceStatusEventSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var deviceStatusEvent = new device_status_event_1.DeviceStatusEvent();
        if (!json) {
            return deviceStatusEvent;
        }
        deviceStatusEvent.id = json.id;
        deviceStatusEvent.deviceId = json.deviceId;
        deviceStatusEvent.organization = json.organization;
        deviceStatusEvent.driver = json.driver;
        deviceStatusEvent.data = json.data;
        deviceStatusEvent.createdAt = json.createdAt;
        if (json.locationAuthorization === 'Always' ||
            json.locationAuthorization === 'always' ||
            json.locationAuthorization === 'Authorized Always' ||
            json.locationAuthorization === 'true') {
            deviceStatusEvent.locationAuthorization = 'Always';
        }
        else {
            deviceStatusEvent.locationAuthorization = 'Not Always';
        }
        deviceStatusEvent.lowPowerMode = json.lowPowerMode;
        deviceStatusEvent.backgroundAppRefresh = json.backgroundAppRefresh;
        deviceStatusEvent.lowDeviceStorage = json.lowDeviceStorage;
        if (deviceStatusEvent.data) {
            deviceStatusEvent.applicationVersion =
                deviceStatusEvent.data['applicationVersion'];
            deviceStatusEvent.applicationName =
                deviceStatusEvent.data['applicationName'];
            var deviceOS = deviceStatusEvent.data['deviceOs'];
            if (deviceOS &&
                (deviceOS.includes('iOS') ||
                    deviceOS.includes('ios') ||
                    deviceOS.includes('Android') ||
                    deviceOS.includes('android'))) {
                deviceStatusEvent.deviceOS = deviceOS;
            }
            else {
                deviceStatusEvent.deviceOS = "Android " + deviceOS;
            }
            deviceStatusEvent.signal = deviceStatusEvent.data['signal'];
            deviceStatusEvent.battery = deviceStatusEvent.data['battery'];
        }
        return deviceStatusEvent;
    };
    /**
     * @param  {DeviceStatusEvent} deviceStatusEvent
     * @returns any
     */
    DeviceStatusEventSerializer.prototype.toJson = function (deviceStatusEvent) {
        var json = {
            id: deviceStatusEvent.id,
        };
        return decamelizeKeysDeep(json);
    };
    return DeviceStatusEventSerializer;
}());
exports.DeviceStatusEventSerializer = DeviceStatusEventSerializer;
