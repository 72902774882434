"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pay_sheet_service_1 = require("../../../pay-sheet.service");
var DriverPayTableSublistComponent = /** @class */ (function () {
    function DriverPayTableSublistComponent(paySheetService) {
        this.paySheetService = paySheetService;
        this.displayedColumns = [];
        this.displayedColumns = this.type === 'Trip' ?
            ['index', 'job', 'ticketNumber', 'orderNumber', 'poNumber', 'weight', 'start', 'end', 'totalTime'] :
            this.type === 'GeoTrip' ?
                ['index', 'job', 'ticketNumber', 'weight', 'start', 'end', 'totalTime'] :
                ['index', 'job', 'ticketNumber', 'start', 'end', 'totalTime'];
    }
    return DriverPayTableSublistComponent;
}());
exports.DriverPayTableSublistComponent = DriverPayTableSublistComponent;
