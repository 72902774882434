import { JobEvent } from '../job-events/job-event';

export class CollaborationTemplate {
  jobEventsLoading: boolean;
  jobEventOptions: JobEvent[];
  jobevents: JobEvent[];
  numTrucks: string;
  invoiceWeightUnit: string;
  invoiceType: string;
  invoiceRate: string;
  notes: string;
  cancelMissing: boolean;
  requestedAmount: number;
  requestedUnit: any;

  constructor(data: any) {
    this.jobEventsLoading = data.jobEventsLoading;
    this.jobEventOptions = data.jobEventOptions;
    this.jobevents = data.jobevents;
    this.numTrucks = data.numTrucks || 1;
    this.invoiceWeightUnit = data.invoiceWeightUnit;
    this.invoiceType = data.invoiceType;
    this.invoiceRate = data.invoiceRate;
    this.notes = data.notes;
    this.cancelMissing = data.cancelMissing;
    this.requestedAmount = data.requestedAmount;
    this.requestedUnit = data.requestedUnit;
  }
}
