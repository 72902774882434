import { Component, Output, EventEmitter, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// libraries
import moment = require('moment-timezone');

// angular material
import { MatDialogRef } from '@angular/material';

// services
import { PunchCardService } from '../punch-cards/punch-card.service';
import { JobService } from '../jobs/job.service';
import { JobEventService } from '../job-events/job-event.service';
import { DriverService } from '../drivers/driver.service';
import { TruckService } from '../trucks/truck.service';
import { parseErrors } from '../shared/api.service';
import { OrganizationService } from '../organizations/organization.service';
import { AuthenticationService } from '../shared';

// components
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';

// types
import { JobEvent } from '../job-events/job-event';

@Component({
  selector: 'new-punch-card-dialog',
  templateUrl: './new-punch-card-dialog.component.html',
  styleUrls: ['./new-punch-card-dialog.component.scss']
})
export class NewPunchCardDialogComponent implements OnInit, OnDestroy {
  lockedFields = false;
  highlightedDates = [];
  jobEvents: JobEvent[] = [];
  jobEventError = false;
  jobName: string;
  jobEventDate: Date = null;
  jobEventDatePickerOptions = {
    min: null,
    max: null
  };
  model: any = {
    job: null,
    carrier: null,
    jobEvent: null,
    driver: null,
    truck: null,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    ticketNumber: null,
    startTimeTimestamp: null,
    endTimeTimestamp: null
  };

  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  jobEventsReq: Subscription;
  jobEvent;
  callback;
  firstload = true;

  activeTab = 'punch-card-data';

  @ViewChild('jobDropdown', { static: false }) jobDropdown: RuckitDropdownComponent;
  jobDropdownConfig = {
    service: JobService,
    selectText: this.translateService.instant('Select Job'),
    loadingText: this.translateService.instant('Loading Jobs...'),
    noResultsText: this.translateService.instant('No Jobs'),
    searchable: true,
    group: true,
    groupBy: job => job.project.name,
    groupProperty: 'project.name',
    sortBy: 'project__name,name,start_date',
    nameProperty: 'displayName',
    serializer: 'AllJobs',
    includeFullObject: true,
    query: {
      serializer: 'JobGroupedDropdown'
    }
  };

  @ViewChild('carrierDropdown', { static: false }) carrierDropdown: RuckitDropdownComponent;
  carrierDropdownConfig = {
    nameProperty: 'name',
    idProperty: 'carrier.id',
    selectText: this.translateService.instant('Select Carrier'),
    loadingText: this.translateService.instant('Loading Carriers...'),
    noResultsText: this.translateService.instant('No Carriers'),
    service: OrganizationService,
    serviceFunction: 'get',
    searchable: true,
    sortBy: 'name',
    includeFullObject: true,
    prefilledOptions: [
      {...this.authenticationService.getOrganization(), name: 'My Drivers'}
    ],
    query: {
      ordering: 'name',
      exclude: this.authenticationService.getOrganization().id,
    }
  };

  @ViewChild('driversDropdown', { static: false }) driversDropdown: RuckitDropdownComponent;
  driverDropdownConfig = {
    service: DriverService,
    selectText: this.translateService.instant('Select Driver'),
    loadingText: this.translateService.instant('Loading Drivers...'),
    noResultsText: this.translateService.instant('No Drivers'),
    searchable: true,
    sortBy: 'profile__first_name,profile__last_name',
    includeFullObject: true,
    serviceFunction: 'list',
    query: { ordering: 'profile__first_name,profile__last_name' }
  };

  @ViewChild('truckDropdown', { static: false }) truckDropdown: RuckitDropdownComponent;
  truckDropdownConfig = {
    service: TruckService,
    selectText: this.translateService.instant('Select Truck'),
    loadingText: this.translateService.instant('Loading Trucks...'),
    noResultsText: this.translateService.instant('No Trucks'),
    searchable: true,
    nameProperty: 'displayName',
    sortBy: 'name',
    includeFullObject: true,
    serviceFunction: 'list',
    query: { ordering: 'name' }
  };

  ticketImageFile = {
    dataUri: null,
    file: null
  };

  newPunchCardForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewPunchCardDialogComponent>,
    private punchCardService: PunchCardService,
    private jobEventService: JobEventService,
    private organizationService: OrganizationService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    if (this.jobEventsReq) { this.jobEventsReq.unsubscribe(); }
  }

  initForm() {
    this.model.startDate = this.jobEventDate;
    this.model.endDate = this.jobEventDate;

    this.newPunchCardForm = this.fb.group({
      job: [this.model.job, Validators.required],
      jobEvent: [this.model.jobEvent, Validators.required],
      driver: [this.model.driver, Validators.required],
      carrier: [this.model.carrier, Validators.required],
      truck: [this.model.truck, Validators.required],
      startDate: [this.model.startDate, [Validators.required]],
      startTime: [this.model.startTime, [Validators.required]],
      endDate: [this.model.endDate, [Validators.required]],
      endTime: [this.model.endTime, [Validators.required]],
      ticketNumber: [this.model.ticketNumber, [Validators.required, Validators.minLength(1)]]
    });
  }

  submit() {
    this.errors = [];
    this.loading = true;
    const punchCard = this.parsePunchCard();

    this.punchCardService.saveWithFile(punchCard, this.ticketImageFile.file).subscribe((res) => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  parsePunchCard() {
    const startTime = this.parseDateTimeToIsoString(this.model.startDate, this.model.startTime);
    const endTime = this.parseDateTimeToIsoString(this.model.endDate, this.model.endTime);

    const punchCard = {
      ...this.model,
      startTime,
      endTime,
      startTimeTimestamp: startTime,
      endTimeTimestamp: endTime,
      startDate: startTime,
      endDate: endTime
    };
    return punchCard;
  }

  enableEditing(): void {
    this.lockedFields = false;
    this.firstload = true;
  }

  onJobEventDateChanged(values: Date[]): void {
    this.jobEventError = false;
    if (!this.firstload && values && values.length) {
      this.model.truck = null;

      const selectedDate = moment(values[0]).format().split('T')[0];
      const jobEvent = this.jobEvents.find((j) =>
        j.shift1Start.includes(selectedDate)
      );
      this.jobEventDate = values[0];

      if (jobEvent) {
        this.jobEvent = jobEvent;
        const query = {
          ...this.carrierDropdownConfig.query,
          carriers_for_jobevent: jobEvent.id,
        };

        this.carrierDropdown.config.query = query;
        this.carrierDropdown.getRecords(query);

        this.model.jobEvent = jobEvent.id;
        this.model.jobevent = jobEvent.id;
        this.newPunchCardFormPatchValue('jobEvent', jobEvent.id);
      } else {
        this.jobEventError = true;
        this.jobEvent = null;
      }
    }
  }

  onDateChanged(field: 'startDate' | 'endDate', dates: Date[]) {
    if (['startDate', 'endDate'].includes(field)) {
      this.model[field] = dates[0];
    }

    this.editDateTime(field, dates[0]);
  }

  /**
   * Makes an edit to either the date or time for the startTime or endTime field
   * and pairs that value with the date to set the overall timestamp.
   *
   * @param {string} field The datetime field we will apply the edit to
   * @param {any} value The edit value for the datetime
   */
   editDateTime(field: 'startTime' | 'endTime' | 'startDate' | 'endDate', value: any) {
    if (['startTime', 'endTime'].includes(field) && value) {
      this.model[field] = value;
      this.newPunchCardFormPatchValue(field, value);

    } else if (['startDate', 'endDate'].includes(field) && value) {
      const newDate = moment(value, 'YYYY-MM-DD').toDate();

      this.model[field] = newDate;
      this.newPunchCardFormPatchValue(field, newDate);
    } else if (field && value) {
      this.model[field] = moment(value).toDate();
    }
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        // if newly selected job is the same as existing selected job
        if (this.model.job && this.model.job.id === e.id) {
          this.firstload = false;
          this.getJobEvents(e.id);
          this.newPunchCardFormPatchValue(filterType, e);
          break;
        }

        if (this.model.job && this.model.job.startDate) {
          this.disableDates(this.model.job.startDate, this.model.job.endDate);
        }

        if (this.firstload) {
          this.firstload = false;
        } else {
          this.model.jobEvent = null;
          this.model.jobevent = null;
          this.jobEventDate = null;
          this.model.carrier = null;
          this.model.driver = null;
          this.model.truck = null;
        }
        this.getJobEvents(e.id, true);
        this.newPunchCardFormPatchValue(filterType, e);
        break;
      case 'carrier':
        const carrier = e.carrier;
        // if selected carrier is the same as existing selected carrier
        if (this.model.carrier && this.model.carrier.id === carrier.id) { break; }

        if (!this.firstload) {
          this.model.driver = null;
          this.model.truck = null;
        }

        this.model.carrier = carrier;

        // get drivers and trucks based on carrier id
        const driversQuery = { ...this.driverDropdownConfig.query, carrier: carrier.id };
        this.driverDropdownConfig.query = driversQuery;
        this.driversDropdown.getRecords(driversQuery);

        const truckQuery = { ...this.truckDropdownConfig.query, carrier: carrier.id };
        this.truckDropdownConfig.query = truckQuery;
        this.truckDropdown.getRecords(truckQuery);
        this.newPunchCardFormPatchValue(filterType, carrier);
        break;
      default:
        this.model[filterType] = e;
        this.newPunchCardFormPatchValue(filterType, e);
        break;
    }
  }

  getJobEvents(jobId: string, query = {}): void {
    if (this.jobEventsReq) { this.jobEventsReq.unsubscribe(); }

    if (jobId) {
      this.loading = true;
      this.jobEventError = false;
      this.jobEventsReq = this.jobEventService.getJobEvents({
        ordering: '-shift1_start_timestamp',
        job: jobId,
        ...query
      })
      .subscribe(
        (jobEvents) => {
          const newDates = jobEvents.map((j) => j.shift1Start);
          this.jobEvents = [...jobEvents];
          const jobEvent = this.jobEvent
            ? this.jobEvent
            : this.getJobEventWithDateAndJobEvents(
                jobEvents,
                this.jobEventDate || newDates[0]
              );

          if (newDates.length && jobEvent && jobEvent.id) {
            this.highlightedDates = newDates;
            this.jobEventDatePickerOptions = {
              min: newDates[0],
              max: newDates[newDates.length - 1],
            };
            const query = {
              ...this.carrierDropdownConfig.query,
              ...(jobEvent &&
                jobEvent.id && {
                  carriers_for_jobevent: jobEvent.id,
                }),
            };
            this.jobEvent = jobEvent;
            this.model.jobevent = jobEvent.id;
            this.newPunchCardFormPatchValue('jobEvent', jobEvent.id);

            this.carrierDropdown.config.query = query;
            this.carrierDropdown.getRecords(query);
          } else {
            this.jobEventError = true;
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.errors = err;
        }
      )};
  }

  getJobEventWithDateAndJobEvents(jobEvents, jobEventDate) {
    const selectedDate = moment(jobEventDate).format().split('T')[0];
    return jobEvents.find((j) => j.shift1Start.includes(selectedDate));
  }

  disableDates(startDate: Date, endDate: Date): void {
    let copy = JSON.parse(JSON.stringify(this.jobEventDatePickerOptions));
    let min = new Date(startDate);
    let max = new Date(endDate);
    max.setDate(max.getDate() + 2);

    copy.min = min;
    copy.max = max;
    this.jobEventDatePickerOptions = copy;
  }

  newPunchCardFormPatchValue(key, value): void {
    this.newPunchCardForm.patchValue({
      [key]: value
    });
  }

  fileChange(e) {
    let checkin;

    checkin = this.ticketImageFile;

    let reader = new FileReader();
    reader.onload = function (loadEvent: any) {
      checkin.dataUri = loadEvent.target.result;
    };

    let file = e.srcElement.files[0];
    checkin.file = file;
    reader.readAsDataURL(file);
  }

  parseDateTimeToIsoString = (date: any, time: string): string =>
    moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).toISOString()
}
