<div class="app-content jobs-container">
  <my-toolbar title="{{ 'Jobs' | translate }}" (updatedMarkets)="jobsTable && jobsTable.getRecords();"></my-toolbar>

  <action-bar>
    <div [style.width.%]="100">
      <jobs-header></jobs-header>
    </div>
  </action-bar>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <div class="container">
    <div class="actions-wrapper">
      <fancy-table #jobsTable
                   *ngIf="customFieldsLoaded"
                   [(availableColumns)]="availableColumns"
                   [(displayedColumns)]="displayedColumns"
                   [(availableFilters)]="availableFilters"
                   [(filters)]="appliedFilters"
                   [(search)]="search"
                   (columnChange)="columnsChanged($event)"
                   (onMenuAction)="menuAction($event)"
                   (selectionChanged)="onSelectionChange($event)"
                   [config]="tableConfig">
        <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'project'">
              {{ row.project && row.project.name || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'schedule'">
              <ng-container *ngIf="row.startDate !== null">
                {{ row.startDate | date:'mediumDate' || '&nbsp;' }} - {{ row.endDate | date:'mediumDate' || '&nbsp;' }}
              </ng-container>
              <ng-container *ngIf="row.startDate === null">
                <span class="link" (click)="editJob(row)" translate>Add Job Days</span>
              </ng-container>
            </div>
            <div class="value" *ngSwitchCase="'customer'">
              {{ row.project && row.project.customerOrganization && row.project.customerOrganization.name || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'ordered'">
              {{ (row.amountNeeded || 0) | number:0 }} / {{ row.totalAmountType || '&nbsp;' | titleCase }}
            </div>
            <div class="value" *ngSwitchCase="'order-number'">
              {{ row.orderNumber || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'job-number'">
              {{ row.jobNumber || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'markets'">
              <tag-display [tags]="row.tags" label="{{ 'Markets' | translate }}"></tag-display>
            </div>
            <div class="value" *ngSwitchCase="'dsorder-id'">
              {{ row.dsorderId || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'name'">
              {{ row.displayName || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'slorder-id'">
              {{ row.slorderId || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchCase="'phase-code'">
              {{ row.phaseCode || '&nbsp;' }}
            </div>
            <div class="value" *ngSwitchDefault>{{ value || '&nbsp;' }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div class="title" translate>Jobs</div>
        </div>
        <div applied-filter-content>
          <applied-filters [(filters)]="appliedFilters" [(search)]="search"></applied-filters>
        </div>
      </fancy-table>

      <div class="actions">
        <column-toggle #columnToggle
                       [(availableColumns)]="availableColumns"
                       [(displayedColumns)]="displayedColumns"></column-toggle>
        
        <div class="archived">
          <input type="checkbox" name="show_archived" [(ngModel)]="showArchived" (change)="toggleArchived()" />
          <label attr.data-label="{{ 'Show Archived' | translate }}" for="show_archived"></label>
        </div>

        <filters-panel [(appliedFilters)]="appliedFilters" [customFilter]="true"
                       (openCustomFilter)="openFilters()" [search]="search"
                       (searchChange)="search = $event"></filters-panel>
        <div>
          <button class="btn btn-default edit-selected" *ngIf="canCreateJobs" 
                  [disabled]="!(isAllJobsSelected || (selectedJobs.length > 0))"
                  (click)="openEditSelectionDialog()">
                  {{ 'Edit Selected' | translate }}
          </button>
        </div>
        <button [routerLink]="['/jobs/new']"
                [queryParams]="{returnTo: '/jobs'}"
                class="btn btn-primary new-job" *ngIf="canCreateJobs">
          <span class="icon-plus"></span> {{ 'New Job' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
