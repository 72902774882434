<div class="app-content" [ngClass]="{ mobile: device.mobile }">
  <my-toolbar title="{{ 'Jobs' | translate }}"></my-toolbar>

  <action-bar *ngIf="!device.mobile">
    <div [style.width.%]="100">
      <jobs-header></jobs-header>
    </div>
    <div class="header-actions">
      <div class="left-actions">
        <date-range-picker
          class="date-select"
          [selectedDates]="[jobEventDate]"
          (dateChanged)="onDateChanged($event)"
        ></date-range-picker>
      </div>
    </div>
  </action-bar>
  <div class="hauler-shares-container">
    <div class="actions-wrapper">
      <fancy-table
        #haulerSharesTable
        [(availableColumns)]="availableColumns"
        [(displayedColumns)]="displayedColumns"
        [(availableFilters)]="availableFilters"
        [(filters)]="appliedFilters"
        [(search)]="search"
        [config]="haulerSharesTableConfig"
        (columnChange)="columnsChanged($event)"
      >
        <ng-template
          #columnTemplates
          let-row="row"
          let-column="column"
          let-value="value"
        >
          <ng-container [ngSwitch]="column">
            <div class="value" *ngSwitchCase="'carrier'">
              <div class="data">
                {{ row.carrierName ? row.carrierName : '-' }}
              </div>
            </div>
            <div class="value" *ngSwitchCase="'name'">
              <div class="data">
                <a [routerLink]="['/jobs/', row.jobId, row.jobeventId]">
                  {{ row.job ? row.job : '-' }}
                </a>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'requested'">
              <div class="data">
                <a [routerLink]="['/dispatch/', row.jobId, row.jobeventId]">
                  {{ row.numTrucks ? (row.numTrucks | number) : 0 }}
                </a>
              </div>
            </div>
            <div class="value" *ngSwitchCase="'dispatched'">
              <div class="data">
                <a [routerLink]="['/dispatch/', row.jobId, row.jobeventId]">
                  {{ row.dispatched ? (row.dispatched | number) : 0 }}
                </a>
              </div>
            </div>

            <div class="value" *ngSwitchDefault>{{ value || '-' }}</div>
          </ng-container>
        </ng-template>
        <div header-content>
          <div translate class="title">Hauler Shares</div>
        </div>
        <div applied-filter-content>
          <applied-filters
            [(filters)]="appliedFilters"
            [search]="search"
            (searchChange)="onSearchClear()"
          ></applied-filters>
        </div>
      </fancy-table>
      <div class="actions">
        <column-toggle
          #columnToggle
          [(availableColumns)]="availableColumns"
          [(displayedColumns)]="displayedColumns"
        ></column-toggle>
        <filters-panel
          [(availableFilters)]="availableFilters"
          [(appliedFilters)]="appliedFilters"
          [(search)]="search"
        ></filters-panel>
      </div>
    </div>
  </div>
</div>
