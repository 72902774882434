<div class="mobile-sidebar">
  <button class="mobile-menu-toggle" (click)="toggleOpen(); true"><span class="icon-mobile-toggle"></span></button>
  <div class="pinned-top">
    <div class="user">
      <div class="user-pic">
        <div class="img" *ngIf="user && user.image">
          <img [src]="user && user.image" />
        </div>
        <span *ngIf="user && !user.image">{{ user && user.name && user.name[0] }}</span>
      </div>
      <div class="user-detail">
        <div class="name">{{ user && user.name }}</div>
        <div class="organization">{{ user && user['organization'] && user['organization']['name'] }}</div>
      </div>
    </div>

    <div class="sidebar-menu">
      <div *ngFor="let section of topSections">
        <menu-link [section]="section" *ngIf="section.type === 'link' && !section.hidden" (click)="toggleOpen(); true"></menu-link>
      </div>
    </div>
  </div>

  <div class="pinned-bottom">
    <div class="sidebar-menu">
      <div *ngFor="let section of bottomSections">
        <menu-link [section]="section" *ngIf="section.type === 'link' && !section.hidden" (click)="toggleOpen(); true"></menu-link>
      </div>
      <div class="application-version">
        BUILD {{ applicationVersion | slice:0:7 }}
        <div style="display: none;">{{ applicationVersion }}</div>
      </div>
    </div>
  </div>
</div>