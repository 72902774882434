"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var location_type_service_1 = require("../location-type.service");
var LocationManagerFiltersDialogComponent = /** @class */ (function () {
    function LocationManagerFiltersDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.errors = [];
        this.model = {
            location_type: null
        };
        this.locationTypeDropdownConfig = {
            searchable: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: 'Select Location Type',
            loadingText: 'Loading Location Types...',
            noResultsText: 'No Location Types',
            service: location_type_service_1.LocationTypeService,
            query: {}
        };
        this.model = this.data && this.data.model;
        this.locationTypeDropdownConfig = __assign({}, this.locationTypeDropdownConfig);
    }
    LocationManagerFiltersDialogComponent.prototype.submit = function () {
        this.callback(this.model);
        this.dialogRef.close();
    };
    LocationManagerFiltersDialogComponent.prototype.selectLocationType = function (locationType, userSelection) {
        if (userSelection === void 0) { userSelection = true; }
        if (!userSelection) {
            return;
        }
        this.model.location_type = locationType;
    };
    return LocationManagerFiltersDialogComponent;
}());
exports.LocationManagerFiltersDialogComponent = LocationManagerFiltersDialogComponent;
