"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var location_serializer_1 = require("../locations/location.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var JobEventMap = /** @class */ (function () {
    function JobEventMap(jobEventMapInfo) {
        jobEventMapInfo = camelcaseKeysDeep(jobEventMapInfo);
        this.locationUpdates = jobEventMapInfo.locationUpdates;
        this.locations = jobEventMapInfo.locations.map(function (location) {
            return (new location_serializer_1.LocationSerializer).fromJson(location);
        });
        this.routes = jobEventMapInfo.routes;
    }
    return JobEventMap;
}());
exports.JobEventMap = JobEventMap;
