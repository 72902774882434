"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var condensed_trip_1 = require("../../condensed-trip");
var map_1 = require("@ngui/map");
var map_2 = require("@ngui/map");
var locationUpdate_service_1 = require("../../../jobs/locationUpdate.service");
var job_event_service_1 = require("../../../job-events/job-event.service");
var job_service_1 = require("./../../../jobs/job.service");
var static_data_1 = require("../../../shared/static-data");
var TripHistoryRouteComponent = /** @class */ (function () {
    function TripHistoryRouteComponent(locationUpdateService, jobEventService, jobService) {
        this.locationUpdateService = locationUpdateService;
        this.jobEventService = jobEventService;
        this.jobService = jobService;
        this.tripId = '';
        this.polygons = [];
        this.mapData = true;
        this.coordinates = [];
        this.startLocation = {
            latitude: null,
            longitude: null
        };
        this.endLocation = {
            latitude: null,
            longitude: null
        };
        this.errors = [];
        this.mapCentered = false;
        this.heatmapPoints = [];
        this.mapOptions = {};
    }
    Object.defineProperty(TripHistoryRouteComponent.prototype, "trip", {
        set: function (trip) {
            if (!trip) {
                return;
            }
            this.tripId = trip.id;
            this.jobEventId = trip.jobEventId;
            this.jobId = trip.jobId;
            this.getJob();
            if (this.tripId) {
                this.getHeatmap();
            }
        },
        enumerable: true,
        configurable: true
    });
    TripHistoryRouteComponent.prototype.ngAfterViewInit = function () {
        this.mapOptions = static_data_1.mapOptions({
            zoom: 10,
            disableDefaultUI: true,
            fullscreenControl: false
        }, {
            'poi.business.labels.icon': true
        });
    };
    TripHistoryRouteComponent.prototype.initMap = function (event) {
        if (event) {
            this.map = event;
        }
    };
    TripHistoryRouteComponent.prototype.initHeatmap = function (event) {
        if (event === void 0) { event = null; }
        this.heatmap = event;
    };
    TripHistoryRouteComponent.prototype.getHeatmap = function () {
        var _this = this;
        this.locationUpdateService.get({
            trip: this.tripId
        }).subscribe(function (locationUpdates) {
            if (locationUpdates.length && _this.map) {
                _this.mapData = true;
                var mapBounds_1 = new google.maps.LatLngBounds();
                _this.heatmapPoints = locationUpdates.map(function (locationUpdate) {
                    var lat = locationUpdate.location && locationUpdate.location.coordinates[0];
                    var lng = locationUpdate.location && locationUpdate.location.coordinates[1];
                    mapBounds_1.extend(new google.maps.LatLng(lng, lat));
                    return { location: new google.maps.LatLng(lng, lat), weight: 1 };
                });
                _this.map.fitBounds(mapBounds_1);
                if (_this.map.getZoom() > 17) {
                    _this.map.setZoom(17);
                }
                _this.getJobEventLocations(locationUpdates[0].jobevent);
            }
            else {
                _this.mapData = false;
            }
        }, function (err) {
            _this.mapData = false;
            _this.errors = err;
        });
    };
    TripHistoryRouteComponent.prototype.getJobEventLocations = function (jobEventId) {
        var _this = this;
        this.jobEventService.getJobEvent(this.jobEventId).subscribe(function (jobEvent) {
            var jobEventData = jobEvent;
            _this.startLocation = {
                latitude: jobEventData.startCoordinates.latitude,
                longitude: jobEventData.startCoordinates.longitude
            };
            _this.endLocation = {
                latitude: jobEventData.endCoordinates.latitude,
                longitude: jobEventData.endCoordinates.longitude
            };
        }, function (err) {
            _this.errors = err;
        });
    };
    TripHistoryRouteComponent.prototype.getJob = function () {
        var _this = this;
        this.jobService.get(this.jobId).subscribe(function (job) {
            var bounds = new google.maps.LatLngBounds();
            _this.polygons = [];
            if (job && job.startLocation && job.startLocation.geofence) {
                if (job.startLocation.paths) {
                    _this.polygons.push({ paths: job.startLocation.paths });
                    job.startLocation.paths.forEach(function (path) {
                        if (path.lat && path.lng) {
                            bounds.extend(new google.maps.LatLng(path.lat, path.lng));
                        }
                    });
                }
            }
            if (job && job.endLocation && job.endLocation.geofence) {
                if (job.endLocation.paths) {
                    _this.polygons.push({ paths: job.endLocation.paths });
                    job.endLocation.paths.forEach(function (path) {
                        if (path.lat && path.lng) {
                            bounds.extend(new google.maps.LatLng(path.lat, path.lng));
                        }
                    });
                }
            }
        });
    };
    return TripHistoryRouteComponent;
}());
exports.TripHistoryRouteComponent = TripHistoryRouteComponent;
