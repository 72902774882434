<div class="app-content">
  <form #editPunchCard="ngForm" class="punch-card-form">
    <my-toolbar title="{{ 'Edit Punch Card' | translate }}"></my-toolbar>

    <action-bar>
      <div class="left-actions">{{ punchCard && punchCard.name }}</div>
      <div class="right-actions">
        <button class="btn btn-primary" (click)="submitPunchCard(); false"
                *ngIf="punchCard" [ngClass]="{loading:loading}"
                [disabled]="loading || !editPunchCard.form.valid">
          Save
        </button>
      </div>
    </action-bar>

    <div class="punch-card-container" *ngIf="punchCard">
      <notification *ngFor="let error of errors">{{error}}</notification>

      <div class="punch-card-data">
        <div class="form-content">
          <div class="field-group job full-width">
            <label class="required" translate>Job</label>
            <dropdown [options]="jobDropdownOptions"
                      [config]="jobDropdownConfig"
                      #jobDropdown
                      title="&ndash;"
                      (onSelect)="onSelect('job', $event)"
                      (onSearch)="dropdownSearch($event, 'job')"
                      (nextPage)="dropdownNextPage($event, 'job')">
            </dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Jobs Allowed</label>
            <dropdown id="jobEvents-dropdown"
                      [disabled]="!job || jobEventsLoading"
                      [options]="jobEventsDropdownOptions"
                      [config]="jobEventsDropdownConfig"
                      #jobEventsDropdown
                      title="{{ jobEventsLoading ? 'Loading Job Days...' : 'Select a Job Day' }}"
                      (onSelect)="onSelect('jobevent', $event)"
                      (onSearch)="dropdownSearch($event, 'jobEvents')"
                      (nextPage)="dropdownNextPage($event, 'jobEvents')">
            </dropdown>
          </div>
        </div>

        <notification *ngIf="jobEventError">{{jobEventError}}</notification>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Carrier</label>
            <ruckit-dropdown
              #carriersDropdown
              [disabled]="!jobEvent || !carrier"
              [config]="carrierDropdownConfig"
              [selectedOption]="carrier"
              (onSelect)="onSelect('carrier', $event)"
            ></ruckit-dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group driver full-width">
            <label class="required" translate>Driver</label>
            <dropdown [disabled]="!carrier"
                      [options]="driverDropdownOptions"
                      [config]="driverDropdownConfig"
                      #driverDropdown
                      title="&ndash;"
                      (onSelect)="onSelect('driver', $event)"
                      (onSearch)="dropdownSearch($event, 'driver')"
                      (nextPage)="dropdownNextPage($event, 'driver')">
              </dropdown>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group truck full-width">
            <label class="required" translate>Truck</label>
            <dropdown [disabled]="!carrier"
                      [options]="truckDropdownOptions"
                      [config]="truckDropdownConfig"
                      #truckDropdown
                      title="&ndash;"
                      (onSelect)="onSelect('truck', $event)"
                      (onSearch)="dropdownSearch($event, 'truck')"
                      (nextPage)="dropdownNextPage($event, 'truck')">
              </dropdown>
          </div>
        </div>

        <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="field-group pickers">
            <div class="start-date group" fxFlex="50">
              <label class="required" translate>Start Time</label>
              <date-range-picker [disabled]="!jobEvent"
                                 [selectedDates]="[punchCard.startDate]"
                                 [highlightedDates]="jobDates"
                                 (dateChanged)="onStartDateChanged($event)">
              </date-range-picker>
            </div>

            <div class="start-time group" fxFlex="50">
              <label>&nbsp;</label>
              <div class="timepicker-wrapper">
                <input 
                  type="time" 
                  name="start_time" 
                  class="timepicker"
                  [value]="punchCard.startDate | moment: 'HH:mm'"
                  (focusout)="onStartTimeChange($event)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="end-date group" fxFlex="50">
            <label class="required" translate>End Time</label>
            <date-range-picker [disabled]="!jobEvent"
                               [selectedDates]="[punchCard.endDate]"
                               [highlightedDates]="jobDates"
                               (dateChanged)="onEndDateChanged($event)">
            </date-range-picker>
          </div>

          <div class="end-time group" fxFlex="50">
            <label>&nbsp;</label>
            <div class="timepicker-wrapper">
              <input 
                type="time" 
                name="end_time" 
                class="timepicker"
                [value]="punchCard.endDate | moment: 'HH:mm'"
                (focusout)="onEndTimeChange($event)" 
              />
            </div>
          </div>
        </div>

        <div class="form-content">
          <div class="field-group ticket-number full-width">
            <label class="required" translate>Ticket #</label>
            <input type="text" name="ticket-number" placeholder=""
                   [(ngModel)]="punchCard.ticketNumber"
                   #ticketNumber="ngModel" />
          </div>
        </div>

        <div class="field-group ticket-image">
          <label translate>Ticket Image</label>
          <image-edit 
            [image]="punchCardImage"
            [options]="{ editable: true }"
            (uploadData)="fileChange($event)">
          </image-edit>
        </div>
      </div>
    </div>
  </form>
</div>
