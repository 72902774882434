<div class="app-content">
  <my-toolbar title="{{ 'Jobs' | translate }}" (updatedMarkets)="updatedMarkets()"></my-toolbar>
  <action-bar>
    <div [style.width.%]="100">
      <jobs-header></jobs-header>
    </div>
    <div class="header-actions">
      <div class="left-actions">
        <div class="segmented-buttons">
          <a (click)="switchView('latest', type)" class="btn btn-default" [ngClass]="{active: view === 'latest'}">
            <i class="icon-clock" matTooltip="{{ 'Latest Status' | translate }}"></i>
          </a>
          <a (click)="switchView('timeline', type)" class="btn btn-default" [ngClass]="{active: view === 'timeline'}">
            <i class="icon-timeline" matTooltip="{{ 'Timeline' | translate }}"></i>
          </a>
          <a (click)="switchView('stats', type)" class="btn btn-default" [ngClass]="{active: view === 'stats'}">
            <i class="icon-stats" matTooltip="{{ 'Stats' | translate }}"></i>
          </a>
          <a (click)="switchView('map', type)" class="btn btn-default" [ngClass]="{active: view === 'map'}">
            <i class="icon-locations" matTooltip="{{ 'Map' | translate }}"></i>
          </a>
        </div>

        <date-range-picker class="date-select" [selectedDates]="[assignmentDate]" (dateChanged)="onDateChanged($event)"></date-range-picker>
        <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filters.length}">
          <i class="icon-filter"></i>
          <ng-container translate>Filters</ng-container>
        </button>
        <div class="show-invoice-totals" *ngIf="view === 'stats'">
          <input type="checkbox" name="show_invoice_total" [(ngModel)]="showInvoiceTotal" (ngModelChange)="showInvoiceTotalChange()" />
          <label attr.data-label="{{ 'Include Totals' | translate }}" for="show_invoice_total"></label>
        </div>
      </div>
      <div class="right-actions">
        <div class="search-container {{ loading ? 'loading' : '' }}">
          <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
        </div>
        <dropdown 
          class="actions-dropdown" 
          [changeTitle]="false" 
          title="{{ 'Actions' | translate }}"
          [options]="multipleActionDropdownOptions"
          (onSelect)="setSelectedAction($event)">
        </dropdown>
        <button id="dispatch-drivers-button" class="btn btn-primary" (click)="openNewAssignment()">
          <span class="icon-plus"></span> {{ 'Dispatch Driver' | translate }}
        </button>
      </div>
    </div>
    <div class="no-assignments" *ngIf="showNoAssignmentsAlert">
      <no-driver-assignments [driverId]="driverIdToOpenAssignments" (onCreateNewAssignment)="onCreateNewAssignment($event)"></no-driver-assignments>
    </div>
  </action-bar>
  <div class="filters" *ngIf="filters.length">
    <ng-container *ngIf="!loading">{{ count }} {{ 'Results' | translate }}</ng-container>
    <ng-container *ngIf="loading">{{ 'Loading...' | translate }}</ng-container>
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters | propertyFilter: 'hidden': false">
      {{ filter.key | titleCase }}: {{ filter.value && (filter.value.selectName || filter.value.name || filter.value) }}
      <i class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <div class="container {{ view }}">
    <div class="driver-assignments new-table" [ngClass]="{
        'list-view': view !== 'map',
        'timeline-view': view === 'timeline',
        'paginated': (!loading && count && count > pageSize)
      }">
      <my-loader *ngIf="loading" [matTableLoader]="true" [ngClass]="{'full-height': count === 0}"></my-loader>

      <ruckit-driver-assignments-list [date]="assignmentDate" #driverAssignmentsList
                                      (assignmentDeleted)="getDriverAssignments()"
                                      (assignmentsReload)="getDriverAssignments()">
      </ruckit-driver-assignments-list>

      <app-driver-context-menu
        [contextMenuEventSubject]="contextMenuEventSubject"
        [emitActions]="[viewAssignmentsOptionAction]"
        [additionalMenuItems]="additionalMenuItems"
        (onShiftEnd)="getDriverAssignments()"
        (menuSelection)="onContextMenuOpenAssignment($event)"
        >
      </app-driver-context-menu>

      <mat-table [dataSource]="rows" #driverList>
        <ng-container matColumnDef="selector">
          <mat-header-cell *matHeaderCellDef class="selector">
            <label>
              <mat-checkbox (change)="selector($event)"
                            [checked]="allSelected"
                            [indeterminate]="allSelected && excludeAssignments && excludeAssignments.length">
              </mat-checkbox>
            </label>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="selector">
            <label>
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="selector($event, row)"
                            [checked]="(allSelected && !excludeAssignments.includes(row.id)) || row.selected">
              </mat-checkbox>
            </label>
            <div class="expand" (click)="expandAssignment(row)">
              <ng-container *ngIf="(row.totalTrips && view !== 'timeline') || (row.totalGeoTrips && view === 'timeline')">
                <i *ngIf="!expandedAssignment(row)" class="icon-down-arrow"></i>
                <i *ngIf="expandedAssignment(row)" class="icon-up-arrow"></i>
              </ng-container>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="driver">
          <mat-header-cell *matHeaderCellDef (click)="sort('profile__last_name')" class="sort medium" [ngClass]="{asc: sortAsc, sorted: sortBy === 'profile__last_name'}" translate>
            Driver / Truck
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="driver-detail">
              <div class="value" (contextmenu)="openContextMenu($event, row)"><span class="shift-status" [ngClass]="{ 'active': row.hasActiveShift }"></span> {{ row.name }}</div>
              <div class="sub-title truck">
                {{ row.latestTruck && row.latestTruck.name }} -
                {{ row.latestTruck && row.latestTruck.truckType && row.latestTruck.truckType.name }}
              </div>
              <div class="sub-title organization">{{ row.organization }}</div>
              <div class="sub-title replay">
                <a *ngIf="assignmentISODate" class="replay-link"
                   routerLinkActive="active" [routerLink]="['/replay']"
                   [queryParams]="{ date: assignmentISODate.toISOString() | moment: 'YYYYMMDD', driver: row.id }"
                   translate>View Replay</a>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef class="medium">
            <div class="segmented-buttons">
              <a (click)="switchView(view, 'geotrip')" class="btn btn-default" [ngClass]="{active: type === 'geotrip'}" translate>
                <i class="icon-map-pin" matTooltip="{{ 'Geofence Data' | translate }}"></i>
              </a>
              <a (click)="switchView(view, 'driver')" class="btn btn-default" [ngClass]="{active: type === 'driver'}" translate>
                <i class="icon-steering-wheel" matTooltip="{{ 'Driver Data' | translate }}"></i>
              </a>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value">{{ row.tripStatus }}</div>
            <a [routerLink]="['/jobs', row.jobId, row.jobEvent.id]" *ngIf="row.jobId" routerLinkActive="active">
              {{ row.job }}
            </a>
            <ng-container *ngIf="!row.jobId">{{ row.job }}</ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="geo-status">
          <mat-header-cell *matHeaderCellDef class="medium">
            <div class="segmented-buttons">
              <a (click)="switchView(view, 'geotrip')" class="btn btn-default" [ngClass]="{active: type === 'geotrip'}" translate>
                <i class="icon-map-pin" matTooltip="{{ 'Geofence Data' | translate }}"></i>
              </a>
              <a (click)="switchView(view, 'driver')" class="btn btn-default" [ngClass]="{active: type === 'driver'}" translate>
                <i class="icon-steering-wheel" matTooltip="{{ 'Driver Data' | translate }}"></i>
              </a>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value">{{ row.geoTripStatus }}</div>
            <a [routerLink]="['/jobs', row.jobId, row.jobEvent.id]" *ngIf="row.jobId" routerLinkActive="active">
              {{ row.job }}
            </a>
            <ng-container *ngIf="!row.jobId">{{ row.job }}</ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Location
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="sub-title location">
              <a [routerLink]="['/locations', row.activeLocation.id, 'edit']" *ngIf="row.activeLocation" routerLinkActive="active">
                {{ row.activeLocation.displayName }}
              </a>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="en-route1">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            En Route
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value trip-status {{ row.origin }}" [ngClass]="{'in-progress': row.enRoute1.inProgress, 'complete': row.enRoute1.complete }">{{ row.enRoute1.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="loading">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Loading
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value trip-status {{ row.origin }}" [ngClass]="{'in-progress': row.tripLoading.inProgress, 'complete': row.tripLoading.complete }">{{ row.tripLoading.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="en-route2">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            En Route
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value trip-status {{ row.origin }}" [ngClass]="{'in-progress': row.enRoute2.inProgress, 'complete': row.enRoute2.complete }">{{ row.enRoute2.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unloading">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Unloading
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value trip-status {{ row.origin }}" [ngClass]="{'in-progress': row.tripUnloading.inProgress, 'complete': row.tripUnloading.complete }">{{ row.tripUnloading.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="load">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Completed Loads
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value">{{ row.load }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="weight">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Total Weight
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value">{{ row.trips && row.trips.length ? totalWeightDisplay(row.trips) : ('None' | translate) }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="invoice-total">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Invoice Total
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value">{{ row.invoiceTotal || 0 | currency : 'USD' : 'symbol' }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expense-total">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Expense Total
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value">{{ row.expenseTotal || 0 | currency : 'USD' : 'symbol' }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duration">
          <mat-header-cell *matHeaderCellDef
                           (click)="sort('latest_trip_status')" class="sort medium"
                           [ngClass]="{asc: sortAsc, sorted: sortBy === 'latest_trip_status'}"
                           translate>
            Duration
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="status-container {{ row.tripStatus.toLowerCase().replace(' ', '-') }}" [ngClass]="{ 'overage': row.duration.target && (row.duration.target < row.duration.value) }">
              <h3 *ngIf="row.duration.target && (row.duration.target < row.duration.value)">{{ row.duration.value - row.duration.target }} mins<span class="small" translate> over</span></h3>
              <h3>{{ row.duration.value }} mins<span *ngIf="row.duration.target" class="target"> vs {{ row.duration.target }} min
              <span class="small" translate> target</span></span></h3>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="geo-duration">
          <mat-header-cell *matHeaderCellDef
                           (click)="sort('latest_trip_status')" class="sort medium"
                           [ngClass]="{asc: sortAsc, sorted: sortBy === 'latest_trip_status'}"
                           translate>
            Duration
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="status-container {{ row.geoTripStatus.toLowerCase().replace(' ', '-') }}" [ngClass]="{ 'overage': row.geoDuration.target && (row.geoDuration.target < row.geoDuration.value) }">
              <h3 *ngIf="row.geoDuration.target && (row.geoDuration.target < row.geoDuration.value)">{{ row.geoDuration.value - row.geoDuration.target }} mins<span class="small" translate> over</span></h3>
              <h3>{{ row.geoDuration.value }} mins<span *ngIf="row.geoDuration.target" class="target"> vs {{ row.geoDuration.target }} min
              <span class="small" translate> target</span></span></h3>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="geo-en-route1">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            En Route
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value geo trip-status alt" [ngClass]="{'in-progress': row.geoEnRoute1.inProgress, 'complete': row.geoEnRoute1.complete }">{{ row.geoEnRoute1.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="geo-loading">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Loading
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value geo trip-status" [ngClass]="{'in-progress': row.geoTripLoading.inProgress, 'complete': row.geoTripLoading.complete }">{{ row.geoTripLoading.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="geo-en-route2">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            En Route
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value geo trip-status alt" [ngClass]="{'in-progress': row.geoEnRoute2.inProgress, 'complete': row.geoEnRoute2.complete }">{{ row.geoEnRoute2.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="geo-unloading">
          <mat-header-cell *matHeaderCellDef class="medium" translate>
            Unloading
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="value geo trip-status" [ngClass]="{'in-progress': row.geoTripUnloading.inProgress, 'complete': row.geoTripUnloading.complete }">{{ row.geoTripUnloading.value }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assignments" stickyEnd>
          <mat-header-cell *matHeaderCellDef (click)="sort('priority_assignment_status')" class="sort medium" [ngClass]="{asc: sortAsc, sorted: sortBy === 'priority_assignment_status'}" translate>
            Assignments
            <div class="cell-background"></div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="medium">
            <div class="assignment-count {{ row.statusClass }}" (click)="showDriverAssignments(row)">
              <span class="assignment-number">{{ row.assignmentCount }}</span>
            </div>
            <!-- <div class="status-icon {{ row.statusClass }}">
              <i></i>
            </div> -->
            <div class="actions">
              <!-- copy assignments -->
              <!-- <button (click)="copyAssignment(row)">
                <i class="material-icons">file_copy</i>
              </button> -->
              <!-- <button class="circle" (click)="showDriverAssignments(row)">
                <i class="icon-down-arrow"></i>
              </button> -->
              <button (click)="openNewAssignment(row)">
                <i class="icon-plus"></i>
              </button>
            </div>
            <div class="cell-background"></div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="trips">
          <mat-cell *matCellDef="let row">
            <ng-container *ngIf="view !== 'timeline'; else geoTripList">
              <assignment-trips [activeTrips]="true" [trips]="row.assignment.activeTrips"
                                *ngIf="row.assignment.activeTrips && row.assignment.activeTrips.length"></assignment-trips>
              <ng-container *ngFor="let data of row.assignment.trips | groupBy: 'jobDisplayName'">
                <div class="job-detail">
                  <div class="job-heading">
                    <h3>{{ data.key }}</h3>
                    <span *ngIf="row.assignment.latestTruck">
                      {{ row.assignment.latestTruck.name }} -
                      {{ row.assignment.latestTruck.truckType && row.assignment.latestTruck.truckType.name }}
                    </span>
                  </div>
                  <div class="trip-total">{{ totalTripTimeDisplay(row.assignment.trips, data.key) }}</div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                  <div class="weight-total">{{ totalWeightDisplay(row.assignment.trips, data.key) }}</div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                  <div *ngIf="view !== 'map'" class="filler"></div>
                </div>
                <assignment-trips [trips]="data.value"></assignment-trips>
              </ng-container>
            </ng-container>
            <ng-template #geoTripList>
              <assignment-trips [activeTrips]="true" [trips]="row.assignment.activeGeoTrips"
                                *ngIf="row.assignment.activeGeoTrips && row.assignment.activeGeoTrips.length"></assignment-trips>
              <ng-container *ngFor="let data of row.assignment.geoTrips | groupBy: 'jobDisplayName'">
                <div class="job-detail">
                  <div class="job-heading">
                    <h3>{{ data.key }}</h3>
                    <span *ngIf="row.assignment.latestTruck">
                      {{ row.assignment.latestTruck.name }} -
                      {{ row.assignment.latestTruck.truckType && row.assignment.latestTruck.truckType.name }}
                    </span>
                  </div>
                  <div class="trip-total">{{ totalTripTimeDisplay(row.assignment.geoTrips, data.key) }}</div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                  <div class="filler"></div>
                </div>
                <assignment-trips [trips]="data.value"></assignment-trips>
              </ng-container>
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple
                class="assignment-row"
                [ngClass]="{ 'expanded': expandedAssignment(row), 'selected': row.selected || row.highlighted }"
                (click)="view === 'map' && focusDriver(row)"></mat-row>
        <mat-row *matRowDef="let row; columns: ['trips']; when: isExpansionTripRow"
                  class="trips-row" [ngClass]="{ 'expanded': expandedAssignment(row.assignment) }">
        </mat-row>
        <notification *ngFor="let error of errors">{{error}}</notification>
      </mat-table>

      <timeline *ngIf="view === 'timeline' && timelineData && timelineRange"
                [rows]="timelineData" [range]="timelineRange"
                [timelineConfig]="timelineConfig" [timeInterval]="60"></timeline>

      <no-results itemName="Assignments" addText="an assignment" [results]="count"
                [styles]="{'min-height': '400px'}" [matTable]="true"
                [search]="search" (clearSearch)="expandSearch()" [loading]="loading"
                (addItem)="openNewAssignment()" [bordered]="true"></no-results>
    </div>
    <ruckit-pagination *ngIf="(!loading && count && count > pageSize) || loading" [totalCount]="count"
      [currentPage]="page" [perPage]="pageSize" (page)="pageChange($event)"></ruckit-pagination>
    <div class="driver-map" [@expandMap]="view !== 'map' ? 'collapsed' : 'expanded'">
      <driver-map *ngIf="view === 'map'" [listComponent]="driverList"
                  [assignmentDateChanged]="assignmentDateChanged"
                  [assignmentDate]="assignmentDate"
                  [searchChanged]="searchChanged"
                  [metaData]="allDriversMetadataService.metaData"
                  (selectedDriver)="scrollToSelectedDriver($event)"
                  [search]="search"></driver-map>
    </div>
  </div>
</div>
