"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content.tickets-container[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column}.app-content.tickets-container[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 70px - 30px - 100px)!important}.notice[_ngcontent-%COMP%]{margin:10px 10px 0}.net[_ngcontent-%COMP%]{flex-direction:column;align-items:flex-start}.net[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{font-size:12px;color:#7f8fa4;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;text-transform:capitalize}.date[_ngcontent-%COMP%]{flex-direction:column;align-items:flex-start}.date[_ngcontent-%COMP%]   .status[_ngcontent-%COMP%]{margin-top:6px}.currency[_ngcontent-%COMP%]{color:#02963f}.btn-filters[_ngcontent-%COMP%]{margin-left:0}.search-container[_ngcontent-%COMP%]{margin-right:15px}.ticket-action[_ngcontent-%COMP%]{padding-right:20px}.filters[_ngcontent-%COMP%]{display:flex;align-items:center;padding:0 30px;background:#f4f6f9;height:50px;border-bottom:1px solid rgba(0,0,0,.07);font-size:14px;font-weight:600;color:#7f8fa4}.filter[_ngcontent-%COMP%]{padding-right:0;white-space:nowrap;margin-left:2px}.filter[_ngcontent-%COMP%]:first-child{margin-left:10px}.filter[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{color:#c7d2e0;font-size:22px}.container[_ngcontent-%COMP%]{padding:30px;position:relative}.value[_ngcontent-%COMP%]{align-items:center}"];
exports.styles = styles;
