import {
  Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter,
  OnChanges, SimpleChanges
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';

// libraries
import { cloneDeep, includes, find as _find, extend, filter } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

// angular material
import { MatDialog, MatDialogRef } from '@angular/material';

// services
import { PreferenceService } from '../../preferences/preference.service';
import { TagService } from '../../tags/tag.service';
import { DriverService } from '../../drivers/driver.service';
import { ConnectionService } from '../../connections/connection.service';
import { parseErrors } from '../../shared/api.service';

// models
import { Preference } from '../../preferences/preference';
import { Tag } from '../../tags/tag';
import { AuthenticationService, DropdownComponent } from '../../shared/index';
import { Driver } from './../driver';
import { Job } from '../../jobs/job';
import { JobEvent } from '../../job-events/job-event';
import { Carrier } from '../../carriers/carrier';
import { DriverContextEvent } from './../driver-context-menu/interfaces/driver-context-event';

// components
import { AssignTruckDialogComponent } from '../../drivers/assign-truck-dialog/assign-truck-dialog.component';

@Component({
  selector: 'available-drivers',
  templateUrl: './available-drivers.component.html',
  styleUrls: ['./available-drivers.component.scss']
})

export class AvailableDriversComponent implements OnInit, OnDestroy, OnChanges {
  @Input() loading = false;
  @Input() job: Job;
  @Input() draggedDriver: Driver;
  @Input() selectedTags: any[] = [];
  @Input() dispatchByJobTab: string;
  @Output() selectedDriversChange: EventEmitter<Driver[]> = new EventEmitter();
  @Output() shiftChange: EventEmitter<any> = new EventEmitter();
  @Output() assignDriversClicked: EventEmitter<Driver[]> = new EventEmitter();

  preference: Preference;
  preferenceKey = 'AvailableDriversComponent-DriversService';
  driversLoading = false;
  carriersLoading = false;
  errors = [];
  tags: Tag[] = [];
  tagsReq: Subscription;
  tagsConfig = {
    nameProperty: 'name',
    loadingOptions: false,
    multiselect: true,
    deselectOption: 'All Markets'
  };
  tagsUrlList: string;
  @ViewChild('tagsDropdown', { static: false }) tagsDropdown: DropdownComponent;
  selectedDrivers: Driver[] = [];
  selectedDriverCount = 0;
  truckOptionsForModal = [];
  carrier: Carrier;
  carriers: Carrier[] = [];
  carriersReq: Subscription;
  carriersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  @ViewChild('carriersDropdown', { static: false }) carriersDropdown: DropdownComponent;
  driversReq: Subscription;
  drivers = {
    items: <Driver[]>[],
    errors: [],
    loading: false,
    search: ''
  };
  hasAllDriversEnabled = false;
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  allDriverSelected: boolean;
  shift = [
    { id: 0, name: 'Shift 1' },
    { id: 1, name: 'Shift 2' }
  ];
  selectedShift: Object;
  shiftConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  @ViewChild('shiftDropdown', { static: false }) shiftDropdown: DropdownComponent;
  staggerOptions: any = {};

  jobEventValue: JobEvent;
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  @Output() jobEventChange: EventEmitter<JobEvent> = new EventEmitter();
  @Input() get jobEvent() { return this.jobEventValue; }
  set jobEvent(data) {
    this.jobEventValue = data;
    this.jobEventChange.emit(data);
  }


  constructor(
    public preferenceService: PreferenceService,
    public tagService: TagService,
    public driverService: DriverService,
    private connectionService: ConnectionService,
    public authenticationService: AuthenticationService,
    public deviceDetectorService: DeviceDetectorService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getPreferences();
    this.getTags();
    this.getDrivers();
    this.getCarriers();

    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.setShift();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedTags'] && changes['selectedTags'].currentValue) {
      this.selectTags(changes['selectedTags'].currentValue);
    }

    if (changes['dispatchByJobTab']) {
      this.getDrivers();
    }
  }

  ngOnDestroy() {
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
    if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
      this.tagsReq.unsubscribe();
    }
    if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
      this.carriersReq.unsubscribe();
    }
  }

  getTags(query = {}, next = false): void {
    if (this.tagsReq && typeof this.tagsReq.unsubscribe === 'function') {
      this.tagsReq.unsubscribe();
    }

    let request = this.tagService.list(query);
    if (next) {
      request = this.tagService.listNext();
    } else {
      this.tags = [];
      if (this.selectedTags) { this.selectTags(this.selectedTags); }
    }
    if (request) {
      this.tagsReq = request.subscribe(res => {
        this.tags.push.apply(this.tags, res);
      }, err => {
        this.errors = err;
      });
    }
  }

  selectTags(tags): void {
    if (this.tagsDropdown) {
      if (JSON.stringify(tags) !== JSON.stringify(this.selectedTags)) {
        this.selectedTags = tags;
        this.savePreferences();
      }
      this.tagsDropdown.selectedItems = tags;
      this.tagsDropdown.setButtonText();
      if (tags.length > 0) {
        this.tagsUrlList = tags.map(tag => { return tag.name; }).join(',');
      } else {
        this.tagsUrlList = null;
      }
      this.getDrivers();
    } else {
      this.errors = ['There was an issue loading the drivers'];
    }
  }

  /**
   * @param  {} shift
   * @returns void
   * This function is called on change of shift dropdown.
   * Selected value is set to dropdown and is emitted for
   * further use in dispatch-by-job component.
   */
  selectShift(shift: Object): void {
    if (this.shiftDropdown) {
      this.selectedShift = shift;
      this.shiftChange.emit(this.selectedShift);
    }
  }

  /**
   * @returns void
   * This function is called as soon as the view is initialized.
   * Set and emit the value as Shift 1 by default.
   */
  setShift(): void {
    this.selectedShift = this.shift[0];
    this.shiftChange.emit(this.selectedShift);
  }

  getDrivers(): void {
    if (this.drivers && !this.drivers.items) { this.drivers.items = []; }
    this.driversLoading = true;
    let allCarriers: boolean, allLeased: boolean;
    if (this.driversReq) { this.driversReq.unsubscribe(); }
    let carrierId: string;
    if (this.carrier && this.carrier.id === 'all_carriers') {
      allCarriers = true;
    } else if (this.carrier && this.carrier.id === 'all_leased') {
      allLeased = true;
    } else if (this.carrier && this.carrier.id === 'my_drivers') {
      // Nothing necessary
    } else if (this.carrier && this.carrier.id) {
      carrierId = this.carrier.id;
    }

    if (this.tagsUrlList && this.tagsUrlList.slice(-1) !== ',') {
      this.tagsUrlList = `${this.tagsUrlList},`;
    }
    let jobEventId = this.jobEvent && this.jobEvent.id;
    this.driversReq = this.driverService.list({
      ordering: 'truck__name,name',
      simple_search: this.drivers.search,
      jobevent_available: jobEventId,
      carrier: carrierId,
      all_carriers: allCarriers,
      all_leased: allLeased,
      tags: this.tagsUrlList
    }).subscribe(drivers => {
      const selectedDrivers = filter(this.drivers.items, 'selected');
      const dispatchedDrivers = drivers.filter(d => (
        !d.selected && this.driverIsOnJobEvent(d)
      ));
      this.drivers.items = filter(drivers, (driver) => {
        return !selectedDrivers.some(selectedDriver => selectedDriver.id === driver.id) && !this.driverIsOnJobEvent(driver);
      });
      this.drivers.items = this.drivers.items.concat(dispatchedDrivers).concat(selectedDrivers);
    }, err => {
      this.drivers.errors = err;
    }, () => {
      this.driversLoading = false;
    });
  }

  changeDriversSearch(term: string): void {
    this.drivers.search = term;
    this.getDrivers();
  }

  driversScroll(e): void {
    if (!this.drivers.loading &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.driverService.listNext();
      if (o) {
        this.drivers.loading = true;
        o.subscribe(drivers => {
          this.drivers.items = this.drivers.items.concat(drivers);
          this.drivers.loading = false;
        }, err => this.errors = err);
      }
    }
  }

  driverIsDraggable(driver: Driver): boolean {
    this.selectedDrivers = filter(this.drivers.items, 'selected');
    return this.selectedDrivers.length === 0 || includes(this.selectedDrivers, driver);
  }

  driverIsGrabbed(driver: Driver): boolean {
    return this.driverIsDraggable(driver) ?
      !!(driver === this.draggedDriver || (this.draggedDriver && driver.selected)) :
      undefined;
  }

  multipleDrag(driver: Driver): boolean {
    this.selectedDrivers = filter(this.drivers.items, 'selected');
    return this.draggedDriver === driver && this.selectedDrivers.length > 1;
  }

  driverDragstart(driver: Driver): void {
    if (this.selectedDrivers && this.selectedDrivers.length) {
      this.selectedDriversChange.emit(this.selectedDrivers);
    } else {
      this.draggedDriver = driver;
      this.selectedDriversChange.emit([this.draggedDriver]);
    }
  }

  driverDragend(e): void {
    if (this.selectedDrivers && this.selectedDrivers.length) {
      this.drivers.items.forEach(driver => {
        driver.selected = false;
      });
      this.selectedDrivers = filter(this.drivers.items, 'selected');
    }
  }

  /**
   * @returns void
   * This is called on click of assign driver button
   * Checks for the selected drivers length and emit the value to be
   * used in dispatch-by-job component
   */
  assignDrivers(): void {
    if (this.selectedDrivers && this.selectedDrivers.length) {
      this.selectedDriversChange.emit(this.selectedDrivers);
      this.assignDriversClicked.emit(this.selectedDrivers);
      this.drivers.items.forEach(driver => {
        driver.selected = false;
      });
      this.selectedDrivers = filter(this.drivers.items, 'selected');
    }
  }

  slotDragover(e): void {
    e.preventDefault();
  }

  slotDrag(slot, e): void {
    slot.over = (e.type === 'dragenter');
  }

  selectDriver(e: MouseEvent, driver: Driver): void {
    let checked = (<HTMLInputElement>e.target).checked;
    driver.selected = (checked === undefined) ? !driver.selected : checked;
    extend(_find(this.drivers.items, { id: driver.id }), driver);
    this.selectedDriverCount = filter(this.drivers.items, 'selected').length;
    let isAllDriverSelected = this.drivers.items.find((i) => i.selected === false);
    if (isAllDriverSelected && !isAllDriverSelected.selected) {
      this.allDriverSelected = false;
    } else {
      this.allDriverSelected = true;
    }
  }

  allSelectDrivers(event): void {
    this.allDriverSelected = event.target.checked;
    if (this.allDriverSelected) {
      this.drivers.items.forEach(driver => {
        driver.selected = true;
      });
    } else {
      this.drivers.items.forEach(driver => {
        driver.selected = false;
      });
    }
    this.selectedDriverCount = filter(this.drivers.items, 'selected').length;
  }

  openAssignTruck(driver: Driver): MatDialogRef<AssignTruckDialogComponent, any> {
    const dialog = this.dialog.open(AssignTruckDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.driver = cloneDeep(driver);
    dialog.componentInstance.jobEvent = this.jobEvent;
    dialog.componentInstance.callback = (res) => {
      Object.assign(driver, res);
    };
    return dialog;
  }

  getCarriers(query = {}): void {
    if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
      this.carriersReq.unsubscribe();
    }
    this.carriers = [
      <Carrier>{ name: 'My Drivers', id: 'my_drivers' },
      <Carrier>{ name: 'All Carriers', id: 'all_carriers' },
    ];
    if (this.job
      && this.job.project
      && this.job.project.customerOrganization
      && this.job.project.customerOrganization['hasLeasedOrgs']) {
      this.carriers.push(<Carrier>{ name: 'Leased', id: 'all_leased' });
    }
    this.carriersLoading = true;
    this.carriersReq = this.connectionService.list({
      ordering: 'organization__name',
      allow_dispatch: 'True',
      is_carrier: 'True',
      ...query
    }).subscribe(connections => {
      let _carriers = connections.map(connection => {
        return <Carrier>{
          name: connection.organization.name,
          id: connection.organization.carrier.id
        };
      });
      this.carriers = this.carriers.concat(_carriers);
      if (this.carriersDropdown && this.carriersDropdown.config) {
        this.carriersDropdown.config.loadingOptions = false;
      }
      this.carriersLoading = false;
      this.carriersConfig.loadingOptions = false;
    }, err => {
      this.errors = err;
    }, () => {
      this.carriersLoading = false;
      this.carriersConfig.loadingOptions = false;
    });
  }

  dropdownNextPage(e, type: string): void {
    let config, service, options;

    switch (type) {
      case 'carrier':
        config = this.carriersConfig;
        service = this.connectionService;
        options = this.carriers;
        break;
    }

    if (!config.loadingOptions) {
      let o = service && service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'carrier':
              let _carriers = results.map(connection => {
                return {
                  name: connection.organization.name,
                  id: connection.organization.carrier.id
                };
              });
              this.carriers = this.carriers.concat(_carriers);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  dropdownSearch(term = '', type: string): void {
    switch (type) {
      case 'carrier':
        this.getCarriers({ search: term });
        break;
      default:
        throw 'invalid dropdown type';
    }
  }

  selectCarrier(carrier: Carrier, savePreference: boolean): void {
    this.carrier = carrier;
    this.getDrivers();
    if (savePreference) { this.savePreferences(); }
  }

  mobileSearch(term = '', type: string): void {
    switch (type) {
      case 'carrier':
        this.getCarriers({ search: term });
        break;
      case 'drivers':
        this.changeDriversSearch(term);
        break;
      default:
        throw 'invalid type';
    }
  }

  mobileGetNext(e, type: string): void {
    switch (type) {
      case 'carrier':
        this.dropdownNextPage(e, type);
        break;
      case 'drivers':
        this.driversScroll(e);
        break;
      default:
        throw 'invalid type';
    }
  }

  driverIsReady(driver: Driver): boolean {
    return !(
      driver.dutyStatus === 'off-duty' ||
      (driver.truck && driver.truck.serviceStatus === 'out-of-service')
    );
  }

  driverIsOnJobEvent(driver: Driver): boolean {
    if (this.dispatchByJobTab === 'loadList') { return false; }
    return driver.conflictIds.includes(this.jobEvent.id);
  }

  getPreferences(): void {
    const user = this.authenticationService.user();
    this.preferenceService.list({
      name: this.preferenceKey,
      type: 'user',
      profile: user && user.id
    }).subscribe(preferences => {
      if (preferences && preferences.length) {
        preferences.forEach(preference => {
          if (preference.blob) {
            this.preference = preference;
            this.parsePreferences();
          }
        });
      }
    });
  }

  savePreferences() {
    if (this.preferenceKey) {
      let currentUser = this.authenticationService.user();
      this.preference = {
        ...this.preference,
        name: this.preferenceKey,
        type: 'user',
        profile: currentUser.id,
        blob: {
          carrier: this.carrier,
          tags: this.selectedTags
        }
      };
      this.preferenceService.save(this.preference).subscribe(preference => {
        this.preference = preference;
      });
    }
  }

  parsePreferences(): void {
    if (this.preference.blob && this.preference.blob['tags']) {
      this.selectedTags = this.preference.blob['tags'];
      this.selectTags(this.selectedTags);
    }
    if (this.preference.blob && this.preference.blob['carrier']) {
      this.selectCarrier(this.preference.blob['carrier'], false);
      this.carriersDropdown.selectedOption = this.preference.blob['carrier'];
      this.carriersDropdown.setSelectedOption();
    }
  }

  openContextMenu(event: any, driver: Driver) {
    this.contextMenuEventSubject.next({
      event,
      driverId: driver.id,
    });
  }
}
