import { CollaboratorStat } from './collaborator-stat';
import { JobEventStatSerializer } from '../job-event-stats/job-event-stat.serializer';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class CollaboratorStatSerializer {
  fromJson(json: any): CollaboratorStat {
    json = camelcaseKeysDeep(json);
    const collaboratorStat = new CollaboratorStat();
    if (!json) { return collaboratorStat; }

    collaboratorStat.id = json.id;
    collaboratorStat.name = json.name;
    collaboratorStat.assignedTrucks = json.assignedTrucks;
    collaboratorStat.averageTripTime = json.averageTripTime;
    collaboratorStat.confirmedTrucks = json.confirmedTrucks;
    collaboratorStat.deliveredLoads = json.deliveredLoads;
    collaboratorStat.dispatchedTrucks = json.dispatchedTrucks;

    if (json.jobeventStats && json.jobeventStats.length) {
      collaboratorStat.jobEventStats = json.jobeventStats.map(jobeventStat => {
        if (jobeventStat && typeof jobeventStat === 'object') {
          return new JobEventStatSerializer().fromJson(jobeventStat);
        } else if (jobeventStat) {
          return new JobEventStatSerializer().fromJson({ id: jobeventStat });
        }
      });
    } else {
      collaboratorStat.jobEventStats = [];
    }
    if (collaboratorStat.jobEventStats && collaboratorStat.jobEventStats.length > 0) {
      collaboratorStat.canExpand = true;
    }

    collaboratorStat.requestedTrucks = json.requestedTrucks;
    collaboratorStat.ticketedWeight = json.ticketedWeight;
    collaboratorStat.truckTypes = json.truckTypes;
    collaboratorStat.weightInTransit = json.weightInTransit;
    collaboratorStat.driversOnShift = json.driversOnShift;
    collaboratorStat.dispatchedLoads = json.dispatchedLoads;
    collaboratorStat.dispatchedTons = json.dispatchedTons;
    collaboratorStat.invoiceTotal = json.invoiceTotal;
    collaboratorStat.haulTotal = json.haulTotal;

    collaboratorStat.loading = json.loading;
    collaboratorStat.selected = json.selected;

    return collaboratorStat;
  }

  toJson(collaboratorStat: CollaboratorStat): any {
    let json = {
      id: collaboratorStat.id,
      name: collaboratorStat.name
    };

    for (let property in json) {
      if (json[property] === undefined) {
        delete json[property];
      }
    }

    return decamelizeKeysDeep(json);
  }
}

