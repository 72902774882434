<a [ngClass]="{'active' : isSelected}" class="menu-link" *ngIf="!external"
   [routerLink]="[section.url]" routerLinkActive="active">
  <i class="{{section.icon ? section.icon : 'no-icon'}}"></i> {{ section.name | translate }}
  <span class="badge" *ngIf="section.badge">{{ section.badge | translate }}</span>
</a>

<a [href]="[section.url]" target="_blank" class="menu-link" *ngIf="external && !(section.type === 'click')">
  <i class="{{section.icon ? section.icon : 'no-icon'}}"></i> {{ section.name | translate }}
  <span class="badge" *ngIf="section.badge">{{ section.badge | translate }}</span>
</a>


<a (click)="onMenuClick()" class="menu-link clickable" *ngIf="section.type === 'click'">
  <i class="{{section.icon ? section.icon : 'no-icon'}}"></i> {{ section.name | translate }}
  <span class="badge" *ngIf="section.badge">{{ section.badge | translate }}</span>
</a>
