"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var notifications_list_component_1 = require("./notifications-list.component");
var routes = [
    { path: '', component: notifications_list_component_1.NotificationsListComponent, canActivate: [auth_guard_1.AuthGuard] }
];
var NotificationsRoutingModule = /** @class */ (function () {
    function NotificationsRoutingModule() {
    }
    return NotificationsRoutingModule;
}());
exports.NotificationsRoutingModule = NotificationsRoutingModule;
