"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mobile-dispatch-drivers[_ngcontent-%COMP%]{top:56px;padding:0;min-width:0;height:calc(100vh - 100px)}.mobile-dispatch-drivers[_ngcontent-%COMP%]   .readonly[_ngcontent-%COMP%]{height:calc(100vh - 86px)}"];
exports.styles = styles;
