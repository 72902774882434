"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var trackable_service_1 = require("./trackable.service");
var index_1 = require("../shared/dialogs/index");
var api_service_1 = require("../shared/api.service");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var shared_1 = require("../shared");
var fancy_table_component_1 = require("../shared/fancy-table/fancy-table.component");
var filters_dialog_component_1 = require("../shared/filters-dialog/filters-dialog.component");
var trackable_type_service_1 = require("../trackable-types/trackable-type.service");
var new_trackable_dialog_component_1 = require("./new-trackable-dialog.component");
var TrackablesComponent = /** @class */ (function () {
    function TrackablesComponent(route, router, dialog, trackableService, authenticationService, cdr, translationService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.trackableService = trackableService;
        this.authenticationService = authenticationService;
        this.cdr = cdr;
        this.translationService = translationService;
        this.loadingProgress = 0;
        this.displayKeys = ['name', 'displayName'];
        this.availableColumns = [
            { key: 'select' },
            { key: 'trackable-type', title: this.translationService.instant('Trackable Type'), sortable: true, sortBy: 'trackable_type__name' },
            { key: 'device-id', title: this.translationService.instant('Device ID'), sortable: true, sortBy: 'device_id' },
            { key: 'truck', title: this.translationService.instant('Truck'), sortable: true, sortBy: 'truck__name' },
            { key: 'active', title: this.translationService.instant('Active'), sortable: true, sortBy: 'active' },
            { key: 'device-state', title: this.translationService.instant('Device State'), sortable: true, sortBy: 'device_state' },
            { key: 'position-source', title: this.translationService.instant('Position Source'), sortable: true, sortBy: 'position_source' },
            { key: 'hdop', title: this.translationService.instant('HDOP'), sortable: true, sortBy: 'hdop' },
            { key: 'rssi', title: this.translationService.instant('RSSI'), sortable: true, sortBy: 'rssi' },
            { key: 'voltage', title: this.translationService.instant('Voltage'), sortable: true, sortBy: 'voltage' },
            { key: 'heading', title: this.translationService.instant('Heading'), sortable: true, sortBy: 'heading' },
            { key: 'speed', title: this.translationService.instant('Speed'), sortable: true, sortBy: 'speed' },
            { key: 'accuracy', title: this.translationService.instant('Accuracy'), sortable: true, sortBy: 'accuracy' },
            { key: 'temperature', title: this.translationService.instant('Temperature'), sortable: true, sortBy: 'temperature' },
            { key: 'last-status-event', title: this.translationService.instant('Last Status Event'), sortable: true, sortBy: 'last_status_event' },
            { key: 'location', title: this.translationService.instant('Location'), sortable: false },
            { key: 'action', title: this.translationService.instant('Action') },
        ];
        this.displayedColumns = [
            'select', 'trackable-type', 'device-id', 'truck', 'active', 'location',
            'last-status-event', 'action'
        ];
        this.appliedFilters = [];
        this.search = '';
        this.query = {};
        this.customClasses = 'trackables';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.tableConfig = {
            hasHeader: true,
            service: trackable_service_1.TrackableService,
            preferenceKey: 'TrackablesComponent-TrackableService',
            filterQuery: false,
            query: {},
            collectionTitle: this.translationService.instant('Trackable'),
            noResultsText: this.translationService.instant('a trackable'),
            newRecordModal: function () { _this.addTrackable(); },
            sortBy: 'name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
            ]
        };
        this.filters = [];
        this.loading = true;
        this.type = 'all';
        this.saveTrackableCallback = function () {
            _this.trackablesTable.getRecords();
        };
        this.allSubscriptionsToUnsubscribe = [];
    }
    /**
     * Subs to any updates in the listAllProgress subject in the trackable service
     */
    TrackablesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allSubscriptionsToUnsubscribe.push(this.trackableService.listAllProgress.subscribe(function (progress) {
            _this.loadingProgress = Math.ceil(progress * 100);
        }));
    };
    /**
     * Parses the url params and queries to set values used by the table and grid
     */
    TrackablesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableConfig['customHeight'] = this.customHeight;
        if (this.route && this.route.queryParams) {
            this.route.queryParams.forEach(function (params) {
                _this.loading = true;
                _this.search = params['search'] || '';
            });
        }
        this.cdr.detectChanges();
    };
    TrackablesComponent.prototype.ngOnChanges = function (changes) {
        if (this.trackablesTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
            this.trackablesTable.query = this.query;
            this.refreshTable();
        }
    };
    TrackablesComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * @param  {} event
     * This function would get called on the click of row of the table
     * On click of row it will open the edit drawer for the selected trackable.
     */
    TrackablesComponent.prototype.clickAction = function (event) {
        var _this = this;
        if (event) {
            if (this.editDrawer && this.editDrawer.trackableForm && !this.editDrawer.trackableForm.form.pristine) {
                this.editDrawer.confirmBeforeClosing().subscribe(function (dialogResult) {
                    if (dialogResult) {
                        _this.editDrawer.submit();
                    }
                    else {
                        _this.editDrawer.close();
                        _this.selectTrackable(event, event[1]);
                    }
                });
            }
            else {
                this.editDrawer.close();
                this.selectTrackable(event, event[1]);
            }
        }
    };
    /**
     * @param  {} action
     * @param  {} trackable
     * Check the action to be performed and call the corresponding function.
     * other functions like export can be added here in future.
     */
    TrackablesComponent.prototype.menuAction = function (action, trackable) {
        switch (action) {
            case 'edit':
                this.selectTrackable(null, trackable);
                break;
            case 'remove':
                this.removeTrackable(trackable);
                break;
        }
    };
    /**
     * @param  {} e
     * @param  {} trackable
     * This function would open a edit window for updating or removing
     * the trackable
     */
    TrackablesComponent.prototype.selectTrackable = function (e, trackable) {
        var _this = this;
        var target = e && (e.target || e.srcElement || e.currentTarget);
        if (target && target.className && target.className.includes('action-menu-icon') ||
            target && target.type === 'checkbox') {
            // Do nothing
        }
        else {
            // if click action is performed on the opened trackable then editor will be closed.
            if (trackable && this.trackable && this.trackable.id === trackable.id) {
                this.trackable = null;
            }
            else {
                setTimeout(function () {
                    _this.trackable = trackable;
                    _this.editDrawer.setOpen();
                    _this.editDrawer.afterEdit = _this.saveTrackableCallback;
                }, 100);
            }
        }
    };
    /**
     * @param  {} trackable
     * Removes the selected trackable and refresh the table with new data
     * Opens the remove confirm dialog
     */
    TrackablesComponent.prototype.removeTrackable = function (trackable) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Trackable?'),
            body: this.translationService.instant('This trackable will be deleted and will NOT be recoverable.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.trackableService.remove(trackable).subscribe(function (res) {
                    _this.refreshTable();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    TrackablesComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    /**
     * opens a page for adding new trackable
     */
    TrackablesComponent.prototype.addTrackable = function () {
        var dialog = this.dialog.open(new_trackable_dialog_component_1.NewTrackableDialogComponent, {
            width: '430px',
            height: '800px',
        });
        if (dialog && dialog.componentInstance) {
            dialog.componentInstance.callback = this.saveTrackableCallback;
        }
    };
    /**
     * refresh/reload the trackables table on any action performed like
     * add, edit, remove
     */
    TrackablesComponent.prototype.refreshTable = function () {
        if (this.trackablesTable) {
            var query = {};
            this.trackablesTable.getRecords(__assign({}, this.tableConfig['query'], this.query, query));
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    TrackablesComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    TrackablesComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.filters = [
            {
                type: 'dropdown',
                field: 'trackableType',
                label: 'Trackable Type',
                dropdownConfig: {
                    service: trackable_type_service_1.TrackableTypeService
                }
            },
            {
                type: 'text',
                field: 'deviceId',
                label: 'Device ID Search'
            }
        ];
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    TrackablesComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            trackableType: 'trackable_type',
            deviceId: 'device_id'
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (filterRes[key]) {
                if (key === 'tags') {
                    if (values && values.length > 0) {
                        values = values.map(function (tag) { return tag.name; }).join(',');
                        query[queryKeys[key]] = values;
                    }
                }
                else {
                    query[queryKeys[key]] = filterRes[key] && filterRes[key].id || values;
                }
            }
            var filter = new filter_option_1.FilterOption({
                filterType: 'text',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (!filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    /**
    * This function is called when edit is successfully completed
    * This would update the table with the changed fields
    *
    * @returns {void}
    */
    TrackablesComponent.prototype.onEditComplete = function (modifiedTrackable) {
        this.trackablesTable.updateTable(modifiedTrackable);
        if (modifiedTrackable && modifiedTrackable.removed) {
            this.refreshTable();
        }
    };
    TrackablesComponent.prototype.onDataLoaded = function (event) {
        var query = __assign({}, this.tableConfig.query, this.query);
    };
    TrackablesComponent.prototype.onClose = function () {
        this.trackable = null;
    };
    return TrackablesComponent;
}());
exports.TrackablesComponent = TrackablesComponent;
