"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var location_service_1 = require("../../locations/location.service");
var shared_1 = require("../../shared");
var LocationStatusComponent = /** @class */ (function () {
    function LocationStatusComponent(locationService, authenticationService, dialog) {
        this.locationService = locationService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.locations = [];
        this.loading = true;
        this.errors = [];
        this.sortAsc = true;
    }
    LocationStatusComponent.prototype.ngOnInit = function () {
        if (window.innerWidth > 900) {
            this.getAverageTimeSpent();
        }
    };
    LocationStatusComponent.prototype.ngOnDestroy = function () {
        if (this.averageTimeSpentReq && typeof this.averageTimeSpentReq.unsubscribe === 'function') {
            this.averageTimeSpentReq.unsubscribe();
        }
    };
    LocationStatusComponent.prototype.onResize = function (event) {
        if (this.locations.length === 0) {
            this.ngOnInit();
        }
    };
    LocationStatusComponent.prototype.getAverageTimeSpent = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.loading = true;
        this.locations = [];
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.averageTimeSpentReq && typeof this.averageTimeSpentReq.unsubscribe === 'function') {
            this.averageTimeSpentReq.unsubscribe();
        }
        this.averageTimeSpentReq = this.locationService.averageTimeSpent(__assign({ ordering: order }, query)).subscribe(function (locations) {
            _this.locations = locations;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    LocationStatusComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getAverageTimeSpent({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    return LocationStatusComponent;
}());
exports.LocationStatusComponent = LocationStatusComponent;
