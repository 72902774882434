"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".connections-list[_ngcontent-%COMP%]   .organization-type[_ngcontent-%COMP%]{font-size:12px;color:#7f8fa4}.connections-list[_ngcontent-%COMP%]     mat-row.primary-row{min-height:61px!important}.connections-list[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%]{padding:0 30px 30px;position:relative}.connections-list[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{align-items:center}.connections-list[_ngcontent-%COMP%]   .email[_ngcontent-%COMP%]{min-width:124px}"];
exports.styles = styles;
