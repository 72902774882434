"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GetTripsByStatusAndSharePipe = /** @class */ (function () {
    function GetTripsByStatusAndSharePipe() {
        this.getTripsByStatus = function (trips, share, status) {
            return trips
                .map(function (trip) {
                return trip && trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.project
                    ? trip.jobEvent.job.id === share.jobId &&
                        trip.latestDecisionStatus === status
                    : false;
            })
                .filter(Boolean).length;
        };
        this.getUnapprovedTrips = function (trips, share) {
            return trips
                .map(function (trip) {
                return trip && trip.jobEvent && trip.jobEvent.job && trip.jobEvent.job.project
                    ? trip.jobEvent.job.id === share.jobId &&
                        trip.latestDecisionStatus !== 'approved'
                    : false;
            })
                .filter(Boolean).length;
        };
    }
    GetTripsByStatusAndSharePipe.prototype.transform = function (trips, share, status, shares) {
        var _this = this;
        if (trips && trips.length && status) {
            if (share) {
                if (status === 'unapproved')
                    return this.getUnapprovedTrips(trips, share);
                return this.getTripsByStatus(trips, share, status);
            }
            else if (shares && shares.length) {
                return shares
                    .map(function (share) {
                    return status === 'unapproved'
                        ? _this.getUnapprovedTrips(trips, share)
                        : _this.getTripsByStatus(trips, share, status);
                })
                    .reduce(function (a, b) { return a + b; }, 0);
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    };
    return GetTripsByStatusAndSharePipe;
}());
exports.GetTripsByStatusAndSharePipe = GetTripsByStatusAndSharePipe;
// removed this check in getUnapprovedTrips and getTripsByStatus
// trip.jobEvent.job.project.ownerOrganization ===
// share.organizationId &&
