"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ngx_device_detector_1 = require("ngx-device-detector");
var DispatchComponent = /** @class */ (function () {
    function DispatchComponent(deviceDetectorService) {
        this.deviceDetectorService = deviceDetectorService;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    return DispatchComponent;
}());
exports.DispatchComponent = DispatchComponent;
