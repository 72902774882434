import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';
import { EodFilter } from '../../reports/eod-filter';

@Pipe({ name: 'eodFilterPipe', pure: false })
export class EodFilterPipe implements PipeTransform {
  transform(filters: EodFilter[]): boolean {
    return filters.some(
      (f) => (f.values && f.values.length > 0) || f.selected === true
    );
  }
}
