<div class="health-stats-container">
  <ng-container *ngIf="fleetHealthStats$ | async as stats; else loading">
    <ng-container *ngIf="stats.driverCount; else noDrivers">
      <div class="col col-summary">
        <span class="summary" translate>Summary</span>
        <span class="summary-text" translate>NUMBER OF DRIVERS ON SHIFT</span>
        <span (click)="filterByOnShift()" class="stats-number">{{
          stats.onShiftCount
        }}</span>
        <span class="summary-text" translate
          >NUMBER OF DRIVERS WITH ISSUES</span
        >
        <span (click)="filterByIssues()" class="stats-number">{{
          stats.driversWithIssues
        }}</span>
      </div>
      <div class="col col-with-legend">
        <canvas
          baseChart
          [data]="driversPieChartData"
          [labels]="driversPieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [legend]="false"
          [colors]="driversChartColors"
        >
        </canvas>
        <div class="fleet-legend">
          <div class="fleet-legend-title" translate>
            NUMBER OF DRIVERS WITH ISSUES
          </div>
          <div class="fleet-legend-row" [style.color]="driversTextColors[0]">
            <span translate>Has Issues</span>
            <span
              >{{ stats.driversWithIssues }} ({{
                ((stats.driversWithIssues / stats.driverCount) * 100).toFixed(
                  2
                )
              }}%)</span
            >
          </div>
          <div class="fleet-legend-row" [style.color]="driversTextColors[1]">
            <span translate>No Issues</span>
            <span
              >{{ stats.driverCount - stats.driversWithIssues }} ({{
                (
                  ((stats.driverCount - stats.driversWithIssues) /
                    stats.driverCount) *
                  100
                ).toFixed(2)
              }}%)
            </span>
          </div>
        </div>
      </div>
      <div class="col col-with-legend">
        <canvas
          baseChart
          [data]="versionsPieChartData"
          [labels]="versionsPieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [legend]="false"
          [colors]="versionsChartColors"
        >
        </canvas>
        <div class="fleet-legend">
          <div class="fleet-legend-title" translate>COUNT OF APP VERSIONS</div>
          <ng-container
            *ngIf="stats.applicationVersions.length < 4; else multipleVersions"
          >
            <div
              class="fleet-legend-row"
              *ngFor="let version of stats.applicationVersions; let i = index"
              [style.color]="versionsTextColors[i]"
            >
              <span>{{ version.key }}</span>
              <span
                >{{ version.count }} ({{
                  ((version.count / versionsCount) * 100).toFixed(2)
                }}%)</span
              >
            </div>
          </ng-container>
          <ng-template #multipleVersions>
            <div class="fleet-legend-row" [style.color]="versionsTextColors[0]">
              <span>{{ stats.applicationVersions[0].key }}</span>
              <span
                >{{ stats.applicationVersions[0].count }} ({{
                  (
                    (stats.applicationVersions[0].count / versionsCount) *
                    100
                  ).toFixed(2)
                }}%)</span
              >
            </div>
            <div
              matTooltip="{{
                showOtherVersions
                  ? ('Click to hide' | translate)
                  : ('Click to extend' | translate)
              }}"
              (click)="showOtherVersions = !showOtherVersions"
              class="fleet-legend-row clickable other-text"
            >
              <span translate>Other</span>
              <span
                >{{ versionsCount - stats.applicationVersions[0].count }} ({{
                  (
                    ((versionsCount - stats.applicationVersions[0].count) /
                      versionsCount) *
                    100
                  ).toFixed(2)
                }}%)</span
              >
            </div>
            <div class="other-versions-container" *ngIf="showOtherVersions">
              <div
                class="fleet-legend-row"
                *ngFor="
                  let version of stats.applicationVersions | slice: 1;
                  let i = index
                "
                [style.color]="versionsTextColors[i + 1] || '#ccc'"
              >
                <span>{{ version.key }}</span>
                <span
                  >{{ version.count }} ({{
                    ((version.count / versionsCount) * 100).toFixed(2)
                  }}%)</span
                >
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noDrivers>
    <div class="no-drivers">
      <h2 translate>No Drivers Found</h2>
    </div>
  </ng-template>

  <ng-template #loading>
    <my-loader class="full-height"></my-loader>
  </ng-template>
</div>
