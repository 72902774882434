"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["a[_ngcontent-%COMP%]{color:#d7d8d6;text-decoration:none;font-weight:400;font-size:16px;padding:15px 20px 0;width:100%;height:50px;display:block;transition:background .2s;position:relative}a.active[_ngcontent-%COMP%], a[_ngcontent-%COMP%]:hover{background:#58595b;color:#fff}a.active[_ngcontent-%COMP%]{padding:15px 20px 0 16px;border-left:4px solid #e04e39}a[_ngcontent-%COMP%]   .badge[_ngcontent-%COMP%]{position:absolute;top:0;min-width:20px;height:20px;border-radius:10px;padding:0 4px;background-color:#be2f1f;line-height:20px;text-align:center;font-size:12px;font-weight:600;color:#fff}i[_ngcontent-%COMP%]{font-size:17px;width:16px;margin-right:15px;vertical-align:middle;line-height:22px}i.no-icon[_ngcontent-%COMP%]{margin-left:20px}i.icon-signout[_ngcontent-%COMP%]{height:20px;display:inline-block;background:url(/assets/img/icons/icn-signout.svg) center/18px no-repeat}@media (max-width:720px){a[_ngcontent-%COMP%]{height:60px;color:#000;padding:15px 20px 0 28px;border-bottom:1px solid rgba(0,0,0,.05)}a.active[_ngcontent-%COMP%], a[_ngcontent-%COMP%]:active, a[_ngcontent-%COMP%]:hover{padding:15px 20px 0 28px;color:#000;background:0 0;border-left:none}i[_ngcontent-%COMP%]{opacity:.5}i.icon-signout[_ngcontent-%COMP%]{opacity:.75}}.menu-link.clickable[_ngcontent-%COMP%]{cursor:pointer}"];
exports.styles = styles;
