import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

// angular material
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material';

// libraries
import { Moment } from 'moment';
import moment = require('moment');
import { findIndex, clone, min as _min, max as _max, uniq } from 'lodash';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {
  defaultConfig = {
    multipleDates: false,
    mobile: false,
    decreaseControl: true,
    increaseControl: true,
    rangeMode: false,
    min: null,
    max: null,
    showSelectedDate: true,
  };

  @ViewChild(MatDatepicker, { static: false }) picker: MatDatepicker<Moment>;

  @Input() dateFormat = 'MMM D, YYYY';
  @Input() placeholderText;
  @Input() selectedDates: Date[] = [];
  @Input() disabledDates = [];
  @Input() highlightedDates = [];
  @Input() displayValue;
  @Input() disabled = false;
  @Input() config = this.defaultConfig;
  @Output() dateChanged: EventEmitter<Date[]> = new EventEmitter();

  shiftActive = false;
  lastSelectedDateIndex = -1;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  dateClass = (d: Moment) => {
    const date = d.toDate();
    const selectedIndex = findIndex(this.selectedDates, (s) => {
      if (!s) {
        return false;
      }
      s = typeof s === 'string' ? moment(s).toDate() : s;
      return s.toDateString() === date.toDateString();
    });
    const disabledIndex = findIndex(this.disabledDates, (s) => {
      if (!s) {
        return false;
      }
      s = typeof s === 'string' ? moment(s).toDate() : s;
      return s.toDateString() === date.toDateString();
    });
    const highlightedIndex = findIndex(this.highlightedDates, (s) => {
      if (!s) {
        return false;
      }
      s = typeof s === 'string' ? moment(s).toDate() : s;
      return s.toDateString() === date.toDateString();
    });

    if (this.selectedDates && selectedIndex !== -1) {
      return 'selected-date';
    } else if (this.highlightedDates && highlightedIndex !== -1) {
      return 'highlighted-date';
    }

    return undefined;
  };

  dateFilter = (d: Moment) => {
    const date = d.toDate();

    if (this.disabledDates && this.disabledDates.length) {
      const isDisabled = this.disabledDates.some(disabledDate => {
        if (!disabledDate || !moment(disabledDate).isValid()) {
          return false;
        }

        return moment(disabledDate).toDate().toDateString() === date.toDateString();
      })

      return !isDisabled
    }

    return true
  }

  ngOnInit() {
    this.config = { ...this.defaultConfig, ...this.config };
    if (this.selectedDates && this.selectedDates.length) {
      setTimeout(() => {
        this.updateDisplayValue();
      });
    }
    if (this.config.multipleDates) {
      this.allSubscriptionsToUnsubscribe.push(
        Observable.fromEvent(document, 'keydown').subscribe(
          (e: KeyboardEvent) => {
            this.shiftActive = e.key === 'Shift';
          }
        )
      );
      this.allSubscriptionsToUnsubscribe.push(
        Observable.fromEvent(document, 'keyup').subscribe(
          (e: KeyboardEvent) => {
            this.shiftActive = e.key === 'Shift' && false;
          }
        )
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedDates']) {
      this.updateDisplayValue(false);
    }
  }

  ngOnDestroy() {
    this.allSubscriptionsToUnsubscribe.forEach((s) => s.unsubscribe());
  }

  decreaseDate(): void {
    this.increaseDate(-1);
  }

  increaseDate(amount = 1): void {
    let date = new Date();
    if (this.selectedDates && this.selectedDates.length === 1) {
      date = this.selectedDates[0];
      date = moment(date).add(amount, 'day').toDate();
    }
    this.selectedDates = [date];
    this.updateDisplayValue();
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    let date, range;
    if (event.value && event.value['begin'] && event.value['end']) {
      range = event.value;
    } else {
      date = new Date(event.value);
    }

    // tried to simplify this logic but it's a bit tricky
    if (this.config && this.config.multipleDates && this.selectedDates) {
      const dateIndex = findIndex(
        this.selectedDates,
        (s) => s.toDateString() === date.toDateString()
      );

      if (dateIndex !== -1) {
        if (this.shiftActive && this.lastSelectedDateIndex > -1) {
          this.selectedDates.splice(
            this.lastSelectedDateIndex,
            dateIndex - this.lastSelectedDateIndex + 1
          );
          this.selectedDates = clone(this.selectedDates);
        } else {
          this.selectedDates.splice(dateIndex, 1);
          this.selectedDates = clone(this.selectedDates);
          this.lastSelectedDateIndex = dateIndex;
        }
      } else if (this.shiftActive) {
        const dateMoments = this.selectedDates.map((d) => moment(d));
        if (moment.max(dateMoments).isBefore(moment(date))) {
          let dates: Date[] = [];
          let addedDate = moment.max(dateMoments);
          while (addedDate.isBefore(moment(date))) {
            addedDate = addedDate.add(1, 'days');
            dates.push(addedDate.toDate());
          }
          this.selectedDates = this.selectedDates.concat(dates);
        }
      } else {
        this.selectedDates.push(date);
      }
    } else if (this.config && this.config.rangeMode && range) {
      this.selectedDates = [range['begin'].toDate(), range['end'].toDate()];
    } else {
      this.selectedDates = [date];
    }
    event.target.writeValue(null); // This triggers a datepicker ui refresh
    this.updateDisplayValue();
  }

  updateDisplayValue(emit = true): void {
    const minDate = _min(this.selectedDates);
    const maxDate = _max(this.selectedDates);
    this.displayValue = uniq([minDate, maxDate])
      .filter(Boolean)
      .map((d) => moment(d).format(this.dateFormat))
      .join(' - ');
    if (emit) {
      this.dateChanged.emit(this.selectedDates);
    }
  }
}
