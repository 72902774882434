"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./fleet.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../shared/toolbar/toolbar.component.ngfactory");
var i3 = require("../shared/toolbar/toolbar.component");
var i4 = require("@angular/router");
var i5 = require("../shared/authentication.service");
var i6 = require("../tags/tag.service");
var i7 = require("../users/user.service");
var i8 = require("@ngx-translate/core");
var i9 = require("../drivers/drivers.component.ngfactory");
var i10 = require("../drivers/drivers.component");
var i11 = require("../drivers/driver.service");
var i12 = require("@angular/material/dialog");
var i13 = require("../trucks/trucks.component.ngfactory");
var i14 = require("../trucks/trucks.component");
var i15 = require("../trucks/truck.service");
var i16 = require("@angular/common");
var i17 = require("./fleet.component");
var styles_FleetComponent = [i0.styles];
var RenderType_FleetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FleetComponent, data: {} });
exports.RenderType_FleetComponent = RenderType_FleetComponent;
function View_FleetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "my-toolbar", [], null, [[null, "updatedMarkets"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("updatedMarkets" === en)) {
        var pd_0 = (_co.updatedMarkets() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(1, 245760, null, 0, i3.ToolbarComponent, [i4.Router, i5.AuthenticationService, i6.TagService, i7.UserService], { title: [0, "title"] }, { updatedMarkets: "updatedMarkets" }), i1.ɵppd(2, 1), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), ("Fleet " + _co.view.title))))), ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_FleetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "drivers", [["class", "drivers"]], null, [[null, "viewArchive"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("viewArchive" === en)) {
        var pd_0 = (_co.viewArchive($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_DriversComponent_0, i9.RenderType_DriversComponent)), i1.ɵdid(2, 4833280, [[1, 4], ["driverTableWrapper", 4]], 0, i10.DriversComponent, [i5.AuthenticationService, i4.ActivatedRoute, i4.Router, i11.DriverService, i8.TranslateService, i12.MatDialog], { customClasses: [0, "customClasses"], query: [1, "query"] }, { viewArchive: "viewArchive" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customClasses; var currVal_1 = _co.query; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_FleetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "trucks", [["class", "trucks with-action-bar"]], null, [[null, "viewArchive"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("viewArchive" === en)) {
        var pd_0 = (_co.viewArchive($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_TrucksComponent_0, i13.RenderType_TrucksComponent)), i1.ɵdid(1, 4964352, [[2, 4], ["truckTableWrapper", 4]], 0, i14.TrucksComponent, [i4.ActivatedRoute, i4.Router, i12.MatDialog, i15.TruckService, i5.AuthenticationService, i1.ChangeDetectorRef, i8.TranslateService], { query: [0, "query"], customClasses: [1, "customClasses"] }, { viewArchive: "viewArchive" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.query; var currVal_1 = _co.customClasses; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FleetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i16.TitleCasePipe, []), i1.ɵqud(671088640, 1, { driverTableWrapper: 0 }), i1.ɵqud(671088640, 2, { truckTableWrapper: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "app-content fleet-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetComponent_1)), i1.ɵdid(5, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FleetComponent_2)), i1.ɵdid(7, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["truckView", 2]], null, 0, null, View_FleetComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.view; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.view && (_co.view.stub === "drivers")); var currVal_2 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
exports.View_FleetComponent_0 = View_FleetComponent_0;
function View_FleetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fleet", [], null, null, null, View_FleetComponent_0, RenderType_FleetComponent)), i1.ɵdid(1, 4308992, null, 0, i17.FleetComponent, [i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FleetComponent_Host_0 = View_FleetComponent_Host_0;
var FleetComponentNgFactory = i1.ɵccf("fleet", i17.FleetComponent, View_FleetComponent_Host_0, {}, {}, []);
exports.FleetComponentNgFactory = FleetComponentNgFactory;
