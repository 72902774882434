"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var driver_1 = require("./../driver");
var assignment_1 = require("../../assignments/assignment");
/**
 * Driver Name Component: Displays a driver's shift status using green or red
 * dots beside their name. The driver's name may be truncated if it exceeds the
 * available space.
 *
 * On hover, a popover displays the driver's photo or photo icon along with the
 * full, untruncated name, organization name, phone number, and CDL.
 *
 * @example
 * <driver-name [driver]="driver" [status]="false"></driver-name>
 */
var DriverNameComponent = /** @class */ (function () {
    function DriverNameComponent() {
        this.status = 'inactive';
        this.hover = false;
    }
    DriverNameComponent.prototype.ngOnInit = function () {
        // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        // Add 'implements OnInit' to the class.
        if (this.assignment) {
            var startTime = Date.parse(this.assignment.shifts && this.assignment.shifts[0] && this.assignment.shifts[0].startTime);
            if (Date.now() > startTime && !!!(this.assignment.shifts[0] && this.assignment.shifts[0].endTime)) {
                this.status = Date.now() < (startTime + 86400000) ? 'active' : 'warning';
            }
            else if (this.assignment.shifts[0] && this.assignment.shifts[0].endTime) {
                this.status = 'complete';
            }
            else if (startTime && this.assignment.trips.length === 0 && this.assignment.predictedTrips.length === 0) {
                this.status = 'nodata';
            }
        }
    };
    return DriverNameComponent;
}());
exports.DriverNameComponent = DriverNameComponent;
