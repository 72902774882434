"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var AnyoneGuard = /** @class */ (function () {
    function AnyoneGuard(router) {
        this.router = router;
    }
    AnyoneGuard.prototype.canActivate = function (route, state) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    return AnyoneGuard;
}());
exports.AnyoneGuard = AnyoneGuard;
