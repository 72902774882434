import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
// import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatToolbarModule,
  MatMenuModule,
  MatTabsModule,
  MatTableModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatSelectModule,
  MatOptionModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatListModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { ToolbarComponent } from './index';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragulaModule } from 'ng2-dragula';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgXtruncateModule } from 'ngx-truncate';
// import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OverlayModule } from '@angular/cdk/overlay';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { MomentModule } from 'angular2-moment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  SatDatepickerModule,
  SatNativeDateModule,
} from 'saturn-datepicker';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { environment } from '../../environments/environment';

// Components
import { DropdownComponent } from './dropdown/dropdown.component';
import { LoaderComponent } from '../directives/index';
import { NotificationComponent } from './notification/notification.component';
import { PaginationComponent } from '../shared/pagination/pagination.component';
import {
  NoResultsComponent,
  TimelineComponent,
  TimelineLegendComponent,
} from '../shared/index';
import { ColumnToggleComponent } from './column-toggle/column-toggle.component';
import { FiltersDialogComponent } from './filters-dialog/filters-dialog.component';
import { FiltersPanelComponent } from './filters-panel/filters-panel.component';
import { AppliedFiltersComponent } from './applied-filters/applied-filters.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import { FancyTableComponent } from './fancy-table/fancy-table.component';
import { RuckitDropdownComponent } from './ruckit-dropdown/ruckit-dropdown.component';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { SearchComponent } from './search/search.component';
import { ItemGridComponent } from './item-grid/item-grid.component';
import { CollaboratorsWidgetComponent } from '../collaborators/collaborators-widget.component';
import { AddCollaboratorsDialogComponent } from '../collaborators/add-collaborators/add-collaborators-dialog.component';
import { EditCollaboratorsDialogComponent } from '../collaborators/edit-collaborators/edit-collaborators-dialog.component';
import { SendCollaboratorNotesDialogComponent } from '../collaborators/send-collaborator-notes/send-collaborator-notes-dialog.component';
import { CollaborationTemplateComponent } from '../collaborators/collaboration-template/collaboration-template.component';
import { JobStatsWidgetComponent } from '../jobs/job-stats/job-stats-widget.component';
import { OverallJobProductionComponent } from '../job-event-stats/overall-job-production.component';
import { DriverAssignmentsListComponent } from '../assignments/driver-assignments-list.component';
import { DayDetailsComponent } from '../jobs/day-details.component';
import { OrderSummaryStatusComponent } from '../orders/order-summary-status/order-summary-status.component';
import {
  SidebarComponent,
  MobileSidebarComponent,
  ActionBarComponent,
  FilterBarComponent,
  NotFoundComponent,
  AlertPanelComponent,
  ImageEditComponent,
  MultipleImagesEditComponent,
  MobileSearchResultsComponent,
  BannerComponent,
  SplitButtonComponent,
  JobStatusComponent,
  RuckitInputComponent,
  EventAlertComponent,
  EventAlertService,
} from './index';
import { AddressSelectComponent } from '../locations/address-select/address-select.component';
import { MenuLinkComponent, MenuToggleComponent } from '../directives/index';
import { NguiMapModule } from '@ngui/map';
import { TagInputComponent } from './tag-input/tag-input.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateTimePickerInlineComponent } from './date-time-picker-inline/date-time-picker-inline.component';
import { CollapsibleSectionComponent } from './collapsible-section/collapsible-section.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DriverContextMenuComponent } from '../drivers/driver-context-menu/driver-context-menu.component';
import { NotesComponent } from './notes/notes.component';
import { JobsHeaderComponent } from '../jobs/jobs-header/jobs-header.component';

import { TimeInputComponent } from './time-input/time-input.component';
import { TimepickerDirective } from './timepicker/timepicker.directive';

// Pipes
import {
  TypeFilterPipe,
  StatusFilterPipe,
  MenuFilterPipe,
  RecipientIdFilterPipe,
  SenderIdFilterPipe,
  FilterPipe,
  FilterByPipe,
  MomentPipe,
  KindFilterPipe,
  TelephonePipe,
  TitleCasePipe,
  GroupByPipe,
  PropertyFilterPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  MetersToMilesPipe,
  TimeFromNowPipe,
  JoinPipe,
  FormattedDurationPipe,
  FormattedSecondsPipe,
  StartHourPipe,
  ActiveHourPipe,
  CompleteHourPipe,
  LastHourPipe,
  CoordinatesPipe,
  AbsoluteValuePipe,
  SecondsToPrettyTime,
  TruncatePipe,
  CountryPipe,
  StatePipe,
  WeightPipe,
  AttachmentTypePipe,
  MultipleAttachmentTypePipe,
  JoinByPropertyPipe,
  IsInArrayPipe,
  GetTripsByStatusAndSharePipe,
  GetPunchCardsByStatusAndSharePipe,
  GetDurationStringPipe,
  RatesCurrencyPipe
} from '../shared/pipes/index';

// Services
import { AttachmentService } from '../attachments/attachment.service';
import { NotesService } from './notes/notes.service';
import { UserService } from '../users/user.service';
import { AuthenticationService } from './authentication.service';
import { UnitService } from '../units/unit.service';
import { RateTypeService } from '../rate-types/rate-type.service';
import { DataService } from './data.service';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

const mapsApiUrl =
  'https://maps.google.com/maps/api/js?libraries=geometry,visualization,places,drawing&key=' +
  environment.googleMapsKey;

@NgModule({
  imports: [
    CommonModule,
    // HttpModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    MatTableModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatListModule,
    MatOptionModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatChipsModule,
    FlexLayoutModule,
    NgXtruncateModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    OverlayModule,
    CurrencyMaskModule,
    MomentModule,
    HttpClientModule,
    ScrollDispatchModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    InfiniteScrollModule,
    NgxMatSelectSearchModule,
    DragulaModule.forRoot(),
    NguiMapModule.forRoot({
      apiUrl: mapsApiUrl,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    DeviceDetectorModule.forRoot(),
    ChartsModule,
  ],
  providers: [
    DataService,
    UserService,
    UnitService,
    AttachmentService,
    NotesService,
    RateTypeService,
    ThemeService,
    AuthenticationService,
    EventAlertService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    CurrencyPipe
  ],
  declarations: [
    ToolbarComponent,
    DropdownComponent,
    LoaderComponent,
    NoResultsComponent,
    TimelineComponent,
    TimelineLegendComponent,
    NotificationComponent,
    PaginationComponent,
    ColumnToggleComponent,
    ActionMenuComponent,
    FancyTableComponent,
    RuckitDropdownComponent,
    UserNotificationsComponent,
    DayDetailsComponent,
    OrderSummaryStatusComponent,
    NotesComponent,
    AddressSelectComponent,
    TypeFilterPipe,
    StatusFilterPipe,
    MenuFilterPipe,
    RecipientIdFilterPipe,
    SenderIdFilterPipe,
    FilterPipe,
    FilterByPipe,
    MomentPipe,
    KindFilterPipe,
    TelephonePipe,
    TitleCasePipe,
    GroupByPipe,
    PropertyFilterPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    MetersToMilesPipe,
    TimeFromNowPipe,
    JoinPipe,
    StartHourPipe,
    ActiveHourPipe,
    CompleteHourPipe,
    LastHourPipe,
    CoordinatesPipe,
    FormattedDurationPipe,
    FormattedSecondsPipe,
    AbsoluteValuePipe,
    TruncatePipe,
    CountryPipe,
    StatePipe,
    WeightPipe,
    AttachmentTypePipe,
    MultipleAttachmentTypePipe,
    JoinByPropertyPipe,
    IsInArrayPipe,
    GetTripsByStatusAndSharePipe,
    GetPunchCardsByStatusAndSharePipe,
    GetDurationStringPipe,
    RatesCurrencyPipe,
    SecondsToPrettyTime,
    FiltersPanelComponent,
    FiltersDialogComponent,
    AppliedFiltersComponent,
    SearchComponent,
    ItemGridComponent,
    SidebarComponent,
    MobileSidebarComponent,
    ActionBarComponent,
    FilterBarComponent,
    NotFoundComponent,
    AlertPanelComponent,
    EventAlertComponent,
    ImageEditComponent,
    MultipleImagesEditComponent,
    MobileSearchResultsComponent,
    CollapsibleSectionComponent,
    JobsHeaderComponent,
    BannerComponent,
    SplitButtonComponent,
    JobStatusComponent,
    RuckitInputComponent,
    MenuLinkComponent,
    MenuToggleComponent,
    TagInputComponent,
    CollaboratorsWidgetComponent,
    AddCollaboratorsDialogComponent,
    EditCollaboratorsDialogComponent,
    SendCollaboratorNotesDialogComponent,
    CollaborationTemplateComponent,
    JobStatsWidgetComponent,
    OverallJobProductionComponent,
    DriverAssignmentsListComponent,
    TimeInputComponent,
    TimepickerDirective,
    DateRangePickerComponent,
    DateTimePickerInlineComponent,
    ContextMenuComponent,
    DriverContextMenuComponent,
    EventAlertComponent,
  ],
  exports: [
    CommonModule, MatToolbarModule, MatListModule, MatMenuModule, MatTabsModule,
    MatTableModule, MatBadgeModule, TranslateModule, ToolbarComponent, FormsModule,
    LoaderComponent, DropdownComponent, NotificationComponent, MatInputModule,
    PaginationComponent, NoResultsComponent, TimelineComponent, TimelineLegendComponent,
    DragulaModule, ChartsModule, MatFormFieldModule, NgxMatSelectSearchModule, MatProgressSpinnerModule,
    MatSortModule, MatPaginatorModule, MatIconModule, ColumnToggleComponent,
    MatCheckboxModule, ReactiveFormsModule, TypeFilterPipe, StatusFilterPipe,
    MenuFilterPipe, RecipientIdFilterPipe, SenderIdFilterPipe, FilterPipe, FilterByPipe, JoinByPropertyPipe, IsInArrayPipe,
    MomentPipe, KindFilterPipe, TelephonePipe, TitleCasePipe, GroupByPipe, AttachmentTypePipe, MultipleAttachmentTypePipe,
    PropertyFilterPipe, SafeHtmlPipe, SafeUrlPipe, MetersToMilesPipe, TruncatePipe, CountryPipe, StatePipe, WeightPipe,
    GetTripsByStatusAndSharePipe, GetPunchCardsByStatusAndSharePipe, GetDurationStringPipe, RatesCurrencyPipe,
    FiltersPanelComponent, FiltersDialogComponent, MatSelectModule, MatOptionModule, MatDialogModule, ActionMenuComponent,
    FancyTableComponent, RuckitDropdownComponent, UserNotificationsComponent, AppliedFiltersComponent,
    TimeFromNowPipe, JoinPipe, FormattedDurationPipe, FormattedSecondsPipe, SearchComponent, SidebarComponent,
    ItemGridComponent, MobileSidebarComponent, ActionBarComponent, FilterBarComponent,
    NotFoundComponent, AlertPanelComponent, EventAlertComponent, ImageEditComponent, MultipleImagesEditComponent,
    MobileSearchResultsComponent, BannerComponent, MenuLinkComponent,
    MenuToggleComponent, NguiMapModule, TagInputComponent, FlexLayoutModule,
    NgXtruncateModule, MatButtonModule, MatCardModule,
    MatChipsModule, MatGridListModule, MatAutocompleteModule, MatTooltipModule, OverlayModule,
    CurrencyMaskModule, MomentModule, HttpClientModule,
    CollaboratorsWidgetComponent, AddCollaboratorsDialogComponent,
    EditCollaboratorsDialogComponent, SendCollaboratorNotesDialogComponent, CollaborationTemplateComponent,
    TimeInputComponent, TimepickerDirective,
    ScrollDispatchModule, SatDatepickerModule, SatNativeDateModule,
    MatDatepickerModule, DateRangePickerComponent, DateTimePickerInlineComponent, StartHourPipe,
    ActiveHourPipe, CompleteHourPipe, LastHourPipe, CoordinatesPipe, SplitButtonComponent,
    JobStatusComponent, RuckitInputComponent, JobStatsWidgetComponent, OverallJobProductionComponent,
    DriverAssignmentsListComponent, CollapsibleSectionComponent, JobsHeaderComponent, NotesComponent,
    AbsoluteValuePipe, SecondsToPrettyTime, DayDetailsComponent, OrderSummaryStatusComponent,
    AddressSelectComponent, ContextMenuComponent, DriverContextMenuComponent, EventAlertComponent, InfiniteScrollModule
  ],
  entryComponents: [
    FiltersDialogComponent,
    AddCollaboratorsDialogComponent,
    EditCollaboratorsDialogComponent,
    SendCollaboratorNotesDialogComponent,
  ],
})
export class SharedModule {}
