import { Resource } from '../shared/resource';

export class JobEventShareStat extends Resource {
  dispatchedEstimatedNumberOfLoads: number;
  dispatchedEstimatedNumberOfTons: number;
  dispatchedDriversCount: number;
  numTrucks: number;
  confirmedTrucks: number;
  totalAmount: number;
  totalLoads: number;
  totalTons: number;
  totalHours: number;
  invoiceRate: number;
  invoiceType: string;
  invoiceWeightUnit: string;
  weightInTransit: number;
  ticketedWeight: number;
  avgTripTime: string;
  collaboratorName: string;
  collaboratorId: string;
  classes: string;
  loading: boolean;
  selected: boolean;
}
