"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment");
var organization_1 = require("../organizations/organization");
var condensed_trip_1 = require("../trips/condensed-trip");
var condensed_punch_card_1 = require("../punch-cards/condensed-punch-card");
var job_serializer_1 = require("../jobs/job.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Expense = /** @class */ (function () {
    function Expense(expenseInfo) {
        expenseInfo = camelcaseKeysDeep(expenseInfo);
        this.id = expenseInfo.id;
        this.number = expenseInfo.number;
        this.amount = expenseInfo.amount;
        this.expenseAmount = expenseInfo.expenseAmount || 0;
        this.surchargeAmount = expenseInfo.surchargeAmount || 0;
        this.tripsAmount = expenseInfo.tripsAmount || 0;
        this.tripsExpenseAmount = expenseInfo.tripsExpenseAmount || 0;
        this.punchcardsAmount = expenseInfo.punchcardsAmount || 0;
        this.punchcardsExpenseAmount = expenseInfo.punchcardsExpenseAmount || 0;
        this.subtotal = expenseInfo.subtotal || 0;
        if (expenseInfo.ownerOrganization) {
            this.ownerOrganization = new organization_1.Organization(expenseInfo.ownerOrganization);
        }
        if (expenseInfo.customerOrganization) {
            this.customerOrganization = new organization_1.Organization(expenseInfo.customerOrganization);
        }
        if (expenseInfo.job) {
            this.job = new job_serializer_1.JobSerializer().fromJson(expenseInfo.job);
        }
        this.sendable = expenseInfo.sendable;
        this.sent = expenseInfo.sent;
        this.draft = !expenseInfo.sent;
        this.sentTime = expenseInfo.sentTime;
        this.sentBy = expenseInfo.sentBy;
        if (expenseInfo.sentTrips && expenseInfo.sentTrips.length) {
            this.sentTrips = expenseInfo.sentTrips.map(function (trip) {
                return new condensed_trip_1.CondensedTrip(trip);
            });
        }
        if (expenseInfo.sentPunchCards && expenseInfo.sentPunchCards.length) {
            this.sentPunchCards = expenseInfo.sentPunchCards.map(function (punchCard) {
                return new condensed_punch_card_1.CondensedPunchCard(punchCard);
            });
        }
        this.billFromContactEmail = expenseInfo.billFromContactEmail;
        if (expenseInfo.billFromCcEmails && (typeof expenseInfo.billFromCcEmails === 'object')) {
            this.billFromCcEmails = expenseInfo.billFromCcEmails.join(',');
        }
        this.billFromAddress = expenseInfo.billFromAddress;
        this.billToContactEmail = expenseInfo.billToContactEmail;
        if (expenseInfo.billToCcEmails && (typeof expenseInfo.billToCcEmails === 'object')) {
            this.billToCcEmails = expenseInfo.billToCcEmails.join(',');
        }
        this.billToAddress = expenseInfo.billToAddress;
        this.createdAt = expenseInfo.createdAt;
        this.remittanceInfo = expenseInfo.remittanceInfo;
        this.pdf = expenseInfo.pdf;
        this.html = expenseInfo.html;
        this.earliestBillableItemTime = expenseInfo.earliestBillableItemTime;
        this.latestBillableItemTime = expenseInfo.latestBillableItemTime;
        this.paymentType = expenseInfo.paymentType;
    }
    Object.defineProperty(Expense.prototype, "administrativeStatus", {
        get: function () {
            if (this.sent) {
                return 'paid';
            }
            else if (this.draft) {
                return 'draft';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Expense.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus &&
                this.administrativeStatus.length &&
                this.administrativeStatus !== 'paid' &&
                this.administrativeStatus !== 'draft') {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Expense.prototype, "expensePeriod", {
        get: function () {
            var values = [];
            if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
                var _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_earliestBillableItemTime);
            }
            if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
                var _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_latestBillableItemTime);
            }
            if (!values.length) {
                values.push(this.shortTitle + ' ' + this.number);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Expense.prototype, "shortTitle", {
        get: function () {
            if (this.draft) {
                return 'Expense Draft ';
            }
            else {
                return 'Paid Expense ';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Expense.prototype, "title", {
        get: function () {
            return 'Expenses | ' + this.customerOrganization.name;
        },
        enumerable: true,
        configurable: true
    });
    Expense.prototype.url = function (action) {
        if (action === 'view') {
            return this.html;
        }
        else if (action === 'download') {
            return this.pdf;
        }
        else {
            return '/expenses/' + this.id + '/' + action;
        }
    };
    Expense.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.administrativeStatus === 'draft') {
            var _option = lodash_1.find(options, { action: 'view' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'download' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'unlock' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (this.administrativeStatus === 'paid') {
            var _option = lodash_1.find(options, { action: 'edit' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'mark_as_paid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'remove' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.pdf) {
            var _option = lodash_1.find(options, { action: 'download' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return Expense;
}());
exports.Expense = Expense;
