"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MetersToMilesPipe = /** @class */ (function () {
    function MetersToMilesPipe() {
    }
    MetersToMilesPipe.prototype.transform = function (input) {
        var inches = 39.370078 * input;
        return Math.round(inches / 63360);
    };
    return MetersToMilesPipe;
}());
exports.MetersToMilesPipe = MetersToMilesPipe;
