"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".main[_ngcontent-%COMP%]{width:100%;height:calc(100% - 70px)}mat-table[_ngcontent-%COMP%]{height:100%}mat-table[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}mat-cell[_ngcontent-%COMP%], mat-header-cell[_ngcontent-%COMP%]{padding:0 5px;width:95px;min-width:95px}mat-cell.mat-column-job[_ngcontent-%COMP%], mat-header-cell.mat-column-job[_ngcontent-%COMP%]{width:140px;min-width:140px}mat-cell[_ngcontent-%COMP%]{flex-direction:column;align-items:flex-start;justify-content:center;padding:15px 5px}p[_ngcontent-%COMP%]{font-weight:500;margin:0}p.bold[_ngcontent-%COMP%]{font-weight:600}input[_ngcontent-%COMP%], input[type=date][_ngcontent-%COMP%], input[type=time][_ngcontent-%COMP%]{height:28px;padding:0 0 0 10px;font-weight:600;border:1px solid #595959;background:#fff;margin-bottom:5px}input[_ngcontent-%COMP%]:last-of-type, input[type=date][_ngcontent-%COMP%]:last-of-type, input[type=time][_ngcontent-%COMP%]:last-of-type{margin-bottom:0}"];
exports.styles = styles;
