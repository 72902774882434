"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var mock_paystub_1 = require("./mock-paystub");
var paystub_1 = require("./paystub");
var environment_1 = require("../../environments/environment");
var api_service_1 = require("../shared/api.service");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var PaystubService = /** @class */ (function () {
    function PaystubService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        this.count = 0;
    }
    PaystubService.prototype.getMockPaystub = function () { return mock_paystub_1.PAYSTUB; };
    PaystubService.prototype.getPaystub = function (paystubId) {
        var paystubUrl = this.baseUrl + 'paystubs/' + paystubId + '/';
        return this.http.get(paystubUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    PaystubService.prototype.getMockPaystubs = function (count) {
        var paystubs = [];
        for (var i = 1; i <= count; i++) {
            var paystub = Object.assign({}, mock_paystub_1.PAYSTUB);
            paystubs.push(paystub);
        }
        return paystubs;
    };
    PaystubService.prototype.getPaystubs = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        params = params.set('page_size', '10');
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var paystubsUrl = this.baseUrl + 'paystubs/';
        return this.http.get(paystubsUrl, {
            headers: api_service_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    PaystubService.prototype.save = function (paystub) {
        var _this = this;
        paystub = lodash_1.clone(paystub);
        var paystubUrl = this.baseUrl + 'paystubs/';
        if (typeof paystub.job === 'object') {
            paystub.job = paystub.job.id;
        }
        if (typeof paystub.driver === 'object') {
            paystub.driver = paystub.driver.id;
        }
        if (typeof paystub.billFromCcEmails === 'string') {
            paystub.billFromCcEmails = paystub.billFromCcEmails.split(',');
        }
        if (typeof paystub.billToCcEmails === 'string') {
            paystub.billToCcEmails = paystub.billToCcEmails.split(',');
        }
        paystub = decamelizeKeysDeep(paystub);
        if (!paystub.id) {
            return this.http.post(paystubUrl, paystub, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return this.http.put(paystubUrl + paystub.id + '/', paystub, {
                headers: api_service_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
    };
    PaystubService.prototype.remove = function (paystub) {
        paystub = decamelizeKeysDeep(paystub);
        var paystubUrl = this.baseUrl + 'paystubs/';
        return this.http.delete(paystubUrl + paystub.id + '/', { headers: api_service_1.requestHeaders() });
    };
    PaystubService.prototype.unlock = function (paystub) {
        paystub = decamelizeKeysDeep(paystub);
        var paystubUrl = this.baseUrl + 'paystubs/';
        return this.http.put(paystubUrl + paystub.id + '/', { sent: false }, { headers: api_service_1.requestHeaders() });
    };
    PaystubService.prototype.send = function (paystub) {
        paystub = decamelizeKeysDeep(paystub);
        var paystubUrl = this.baseUrl + 'paystubs/';
        return this.http.post(paystubUrl + paystub.id + '/send/', {
            payment_type: paystub.payment_type
        }, { headers: api_service_1.requestHeaders() });
    };
    PaystubService.prototype.addToPaystub = function (paystub, type) {
        var _this = this;
        if (type === void 0) { type = 'trips'; }
        paystub = lodash_1.clone(paystub);
        var params, filterParams;
        var paystubUrl = this.baseUrl + 'paystubs/' + paystub.id + '/add/';
        if (paystub.filters && paystub.filters.length) {
            filterParams = '?' + paystub.filters;
            if (type === 'trips') {
                params = { trips: [], exclude_trips: paystub.excludeTrips || paystub.exclude_trips };
            }
            else if (type === 'punchcards') {
                params = { punchcards: [], exclude_punchcards: paystub.excludePunchCards || paystub.exclude_punch_cards };
            }
        }
        else {
            filterParams = '';
            if (type === 'trips') {
                params = { trips: paystub.trips || [] };
            }
            else if (type === 'punchcards') {
                params = { punchcards: paystub.punchCards || paystub.punchcards || [] };
            }
        }
        paystub = decamelizeKeysDeep(paystub);
        return this.http.post(paystubUrl + filterParams, params, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    PaystubService.prototype.removeFromPaystub = function (paystub, type) {
        var _this = this;
        if (type === void 0) { type = 'trips'; }
        paystub = decamelizeKeysDeep(lodash_1.clone(paystub));
        var params, filterParams;
        var paystubUrl = this.baseUrl + 'paystubs/' + paystub.id + '/remove/';
        if (paystub.filters && paystub.filters.length) {
            filterParams = '?' + paystub.filters;
            if (type === 'trips') {
                params = { trips: [], exclude_trips: paystub.excludeTrips || paystub.exclude_trips };
            }
            else if (type === 'punchcards') {
                params = { punchcards: [], exclude_punchcards: paystub.excludePunchCards || paystub.exclude_punch_cards };
            }
            else if (type === 'surcharges') {
                params = { surcharges: [], exclude_surcharges: paystub.excludeSurcharges || paystub.exclude_surcharges };
            }
        }
        else {
            filterParams = '';
            if (type === 'trips') {
                params = { trips: paystub.trips || [] };
            }
            else if (type === 'punchcards') {
                params = { punchcards: paystub.punchCards || paystub.punchcards || [] };
            }
            else if (type === 'surcharges') {
                params = { surcharges: paystub.surcharges || [] };
            }
        }
        return this.http.post(paystubUrl + filterParams, params, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
    };
    PaystubService.prototype.export = function (scope, params) {
        if (params === void 0) { params = null; }
        var paystubUrl = this.baseUrl + 'paystubs/export/';
        var _scope = lodash_1.clone(decamelizeKeysDeep(scope));
        return this.http.post(paystubUrl + '?' + params, _scope, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.catchError(api_service_1.handleError));
    };
    PaystubService.prototype.downloadPdfs = function (scope, params) {
        if (params === void 0) { params = null; }
        var paystubUrl = this.baseUrl + 'invoices/download_pdfs/';
        var _scope = lodash_1.clone(decamelizeKeysDeep(scope));
        return this.http.post(paystubUrl + '?' + params, _scope, {
            headers: api_service_1.requestHeaders()
        }).pipe(operators_1.catchError(api_service_1.handleError));
    };
    PaystubService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(api_service_1.handleError));
        }
        else {
            return null;
        }
    };
    PaystubService.prototype.extractData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (paystub) {
                return new paystub_1.Paystub(paystub);
            });
        }
        else if (resObj) {
            return new paystub_1.Paystub(resObj);
        }
    };
    return PaystubService;
}());
exports.PaystubService = PaystubService;
