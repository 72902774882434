import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { Driver } from '../drivers/driver';
import { Truck } from '../trucks/truck';
import { JobEvent } from '../job-events/job-event';
import { TruckService } from '../trucks/truck.service';
import { Router } from '@angular/router';
import { Carrier } from '../carriers/carrier';

@Component({
  selector: 'mobile-dispatch-trucks',
  templateUrl: './mobile-dispatch-trucks.component.html',
  styleUrls: ['./mobile-dispatch-trucks.component.scss']
})
export class MobileDispatchTrucksComponent implements OnInit, AfterViewInit {
  @Input() jobEvent: JobEvent;
  @Input() selectedDriver: Driver;
  @Input() selectedCarrier: Carrier;
  @Input() scrollEvent: any;
  @Output() selectTruck: EventEmitter<any> = new EventEmitter<any>();
  selectedTruck: Truck;
  trucks: Truck[];
  loading = false;
  errors = [];
  trucksReq: Subscription;

  constructor(
    private router: Router,
    private truckService: TruckService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollEvent && !this.loading &&
      this.scrollEvent && this.scrollEvent.target &&
      this.scrollEvent.target.scrollTop >
      this.scrollEvent.target.scrollHeight -
      this.scrollEvent.target.clientHeight * 3) {
      this.getTrucks({}, true);
    }
  }

  ngOnInit() {
    if (this.selectedDriver) {
      this.selectedTruck = this.selectedDriver.truck && this.selectedDriver.truck.id ? this.selectedDriver.truck : null;
    }
  }

  ngAfterViewInit() {
    if (this.selectedDriver) {
      this.getTrucks();
    } else if (this.jobEvent) {
      this.router.navigate([
        '/dispatch', this.jobEvent.job.id, this.jobEvent.id, 'assign'
      ]);
    } else {
      this.router.navigate(['/dispatch']);
    }
  }

  select(truck: Truck): void {
    this.selectTruck.emit({truck: truck, driver: this.selectedDriver});
  }

  getNextTrucks(e): void {
    this.getTrucks({}, true);
  }

  getTrucks(query = {}, next = false): void {
    if (this.trucks && !this.trucks) { this.trucks = []; }
    this.loading = true;
    if (this.trucksReq) { this.trucksReq.unsubscribe(); }
    let jobEventId = this.jobEvent && this.jobEvent.id;
    let carrier, carrierId: string;
    if (this.selectedCarrier) {
      carrier = this.selectedCarrier;
    } else if (this.selectedDriver && this.selectedDriver.carrier) {
      carrier = this.selectedDriver.carrier;
    }

    if (!['all_carriers', 'all_leased', 'my_drivers'].includes(carrier && carrier.id)) {
      carrierId = carrier.id;
    }
    let request = next ? this.truckService.listNext() : this.truckService.list({
      ordering: 'name', carrier: carrierId, jobevent_available: jobEventId
    });
    this.trucksReq = request && request.subscribe(trucks => {
      this.trucks = trucks;
    }, err => {
      this.errors = err;
    }, () => this.loading = false);
    if (!request) { this.loading = false; }
  }
}
