export class Customer {
  id: string;
  name: string;

  constructor(customerInfo: any) {
    this.id = customerInfo.id;
    this.name = customerInfo.name;
  }

  save() {
    // Save to API
    console.log(this.id, this.name);
  }
}
