"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
// angular material
var material_1 = require("@angular/material");
// lodash
var lodash_1 = require("lodash");
// services
var api_service_1 = require("../../shared/api.service");
var collaborator_service_1 = require("../collaborator.service");
var connection_service_1 = require("../../connections/connection.service");
var units_of_measure_service_1 = require("../../units/units-of-measure.service");
// models
var job_event_1 = require("../../job-events/job-event");
// constants
var app_constants_1 = require("./../../app.constants");
var AddCollaboratorsDialogComponent = /** @class */ (function () {
    function AddCollaboratorsDialogComponent(dialogRef, collaboratorService, unitsOfMeasureService, connectionService, fb) {
        this.dialogRef = dialogRef;
        this.collaboratorService = collaboratorService;
        this.unitsOfMeasureService = unitsOfMeasureService;
        this.connectionService = connectionService;
        this.fb = fb;
        this.loadList = [];
        this.hasLoadListsEnabled = false;
        this.loading = false;
        this.errors = [];
        this.allConnectionsSelected = false;
        this.connections = [];
        this.rateTrackingOptions = [
            { value: 'load', label: 'Load', name: 'Load' },
            { value: 'hour', label: 'Hour', name: 'Hour' }
        ];
        this.rateTrackingConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.rateWeightConfig = {
            nameProperty: 'label',
            loadingOptions: false
        };
        this.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        this.unitsOfMeasure = [];
        this.displayedColumns = ['loadNumber', 'loadTime', 'truck', 'driver'];
        this.noResultsStyles = {
            'min-height': 'unset', 'height': '100%', 'display': 'flex',
            'flex-direction': 'column', 'justify-content': 'center'
        };
        this.checkedConnections = [];
    }
    Object.defineProperty(AddCollaboratorsDialogComponent.prototype, "controls", {
        get: function () {
            return this.addCollaborators.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddCollaboratorsDialogComponent.prototype.ngOnInit = function () {
        if (this.selectedConnections) {
            this.preselectConnections(this.selectedConnections);
        }
        else {
            this.getConnections();
        }
        this.haulTypeOption = lodash_1.find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
        if (this.jobEvent.job.allowWeight) {
            this.rateTrackingOptions = this.rateTrackingOptions.concat([
                { value: 'weight', label: 'Weight', name: 'Weight' }
            ]);
        }
        this.weightOption = lodash_1.find(this.weightOptions, { value: this.jobEvent.haulWeightUnit });
        this.haulTypeOption = lodash_1.find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
        this.getUnitsOfMeasure();
        this.addCollaborators = this.fb.group({});
    };
    AddCollaboratorsDialogComponent.prototype.ngOnDestroy = function () {
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
    };
    AddCollaboratorsDialogComponent.prototype.createConnectionFormGroup = function (connection) {
        var invoiceType = this.rateTrackingOptions.find(function (opt) { return opt.value === connection.collaboration.invoiceType; });
        var invoiceWeightUnit = this.weightOptions.find(function (opt) { return opt.value === connection.collaboration.invoiceWeightUnit; });
        return this.fb.group({
            requestedAmount: [null, [forms_1.Validators.required, forms_1.Validators.min(1)]],
            requestedUnit: [connection.collaboration.requestedUnit],
            invoiceType: [invoiceType || this.haulTypeOption],
            invoiceRate: [connection.collaboration.invoiceRate, forms_1.Validators.min(0)],
            invoiceWeightUnit: [invoiceWeightUnit || this.weightOption],
            brokerRateCode: [connection.collaboration.brokerRateCode],
            notes: ['']
        });
    };
    AddCollaboratorsDialogComponent.prototype.getConnectionFormGroup = function (connectionId) {
        return this.addCollaborators.controls["organization_" + connectionId];
    };
    AddCollaboratorsDialogComponent.prototype.onCheckboxChange = function (event, connection) {
        if (event.target.checked) {
            var newConnectionGroup = this.createConnectionFormGroup(connection);
            this.addCollaborators.addControl("organization_" + connection.id, newConnectionGroup);
            this.checkedConnections.push(connection.id);
        }
        else {
            this.addCollaborators.removeControl("organization_" + connection.id);
            var connIndex = this.checkedConnections.findIndex(function (conn) { return conn === connection.id; });
            this.checkedConnections.splice(connIndex, 1);
        }
        connection.selected = event.target.checked;
    };
    AddCollaboratorsDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var selectedConnections = lodash_1.filter(this.connections, { selected: true });
        var collaborations = selectedConnections.map(function (connection) {
            var collaborationForm = _this.getConnectionFormGroup(connection.id);
            var collaboration = __assign({}, connection.collaboration, collaborationForm.value);
            if (!collaboration.customFieldData) {
                collaboration.customFieldData = {};
            }
            if (_this.brokerRateKey) {
                collaboration.customFieldData[_this.brokerRateKey] = collaborationForm.get('brokerRateCode').value;
            }
            collaboration.numTrucks = (collaborationForm.get('requestedUnit').value.name === 'Trucks')
                ? collaborationForm.get('requestedAmount').value : 0;
            collaboration.requestedUnit = collaboration.requestedUnit.value;
            collaboration.invoiceType = collaboration.invoiceType.value;
            collaboration.invoiceWeightUnit = collaboration.invoiceWeightUnit.value;
            return collaboration;
        });
        if (this.jobEvent && this.jobEvent.job) {
            this.collaboratorService.bulkSave(this.jobEvent.job.id, collaborations).subscribe(function (res) {
                _this.dialogRef.close();
                _this.callback();
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
    };
    AddCollaboratorsDialogComponent.prototype.getConnections = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
        this.loading = true;
        this.connectionsReq = this.connectionService.list(__assign({ ordering: 'organization__name', leased_org: 'False', is_carrier: 'True', customer_only: 'False', status: 'active', search: this.search, page_size: 100 }, query)).subscribe(function (connections) {
            _this.connections = _this.connections.filter(function (c) { return (c.selected); }).concat(_this.setCollaborationDefaults(connections));
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    AddCollaboratorsDialogComponent.prototype.preselectConnections = function (connections) {
        var _this = this;
        var connectionReqs = [];
        connections.forEach(function (id) {
            var request = _this.connectionService.get(id);
            connectionReqs.push(request);
        });
        rxjs_1.forkJoin(connectionReqs).subscribe(function (res) {
            _this.connections = _this.setCollaborationDefaults(res.map(function (c) { return (Object.assign(c, { selected: true })); }));
        });
    };
    AddCollaboratorsDialogComponent.prototype.onScroll = function (event) {
        var _this = this;
        if (!this.loading && event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight * 3) {
            var request = this.connectionService.listNext();
            if (request) {
                this.loading = true;
                this.connectionsReq = request.subscribe(function (connections) {
                    _this.connections = _this.connections.concat(_this.setCollaborationDefaults(connections));
                    _this.loading = false;
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                });
            }
        }
    };
    AddCollaboratorsDialogComponent.prototype.changeConnectionSearch = function (term) {
        this.search = term;
        this.getConnections();
    };
    AddCollaboratorsDialogComponent.prototype.connectionToggle = function (event) {
        var _this = this;
        this.connections.map(function (_connection) {
            _connection.selected = _this.allConnectionsSelected;
        });
    };
    AddCollaboratorsDialogComponent.prototype.loadDropdownSelectionChanged = function (event, load, connection) {
        var loadSchedule = connection.collaboration.loadSchedule;
        var existingLoad = loadSchedule.findIndex(function (l) { return l.loadNumber === load.loadNumber; });
        if (event.checked) {
            if (existingLoad === -1) {
                loadSchedule.push(load);
            }
        }
        else {
            if (existingLoad > -1) {
                loadSchedule.splice(existingLoad, 1);
            }
        }
        loadSchedule.sort(function (a, b) { return a.loadNumber - b.loadNumber; });
    };
    AddCollaboratorsDialogComponent.prototype.setCollaborationDefaults = function (connections) {
        var _this = this;
        return connections.map(function (connection) {
            if (!connection.collaboration) {
                connection.collaboration = {};
            }
            connection.collaboration['invoiceWeightUnit'] = _this.jobEvent.haulWeightUnit || _this.jobEvent.invoiceWeightUnit;
            connection.collaboration['invoiceType'] = _this.jobEvent.haulType || _this.jobEvent.invoiceType;
            connection.collaboration['invoiceRate'] = _this.jobEvent.haulRate || _this.jobEvent.rate;
            connection.collaboration['numTrucks'] = null;
            connection.collaboration['jobevents'] = [_this.jobEvent.id];
            connection.collaboration['brokerRateCodeKey'] = _this.brokerRateKey;
            connection.collaboration['loadSchedule'] = [];
            if (_this.unitsOfMeasure && _this.unitsOfMeasure.length) {
                connection.collaboration['requestedUnit'] = _this.unitsOfMeasure.find(function (u) { return u.name === 'Trucks'; });
            }
            return connection;
        });
    };
    AddCollaboratorsDialogComponent.prototype.setFormControlValue = function (connection, control, option) {
        var targetConnection = this.getConnectionFormGroup(connection.id);
        targetConnection.controls[control].setValue(option);
        if (control === 'invoiceType' && option.value === 'weight') {
            targetConnection.controls.invoiceWeightUnit.setValue(this.weightOption);
        }
        else if (control === 'invoiceType') {
            targetConnection.controls.invoiceWeightUnit.setValue(option);
        }
    };
    AddCollaboratorsDialogComponent.prototype.expandSearch = function () {
        this.search = null;
        this.getConnections();
    };
    AddCollaboratorsDialogComponent.prototype.applyTemplate = function (template) {
        var _this = this;
        var selectedConnections = this.connections.filter(function (connection) { return connection.selected; });
        selectedConnections.forEach(function (connection) {
            var targetConnection = _this.getConnectionFormGroup(connection.id);
            targetConnection.patchValue(template);
            targetConnection.markAsDirty();
        });
    };
    AddCollaboratorsDialogComponent.prototype.getUnitsOfMeasure = function () {
        var _this = this;
        this.unitsOfMeasureService.list().subscribe(function (units) {
            var unitsOfMeasure = units.map(function (unit) { return ({
                value: unit.id,
                label: unit.name,
                name: unit.name,
                selected: false,
            }); });
            _this.unitsOfMeasure = unitsOfMeasure.slice();
        });
    };
    return AddCollaboratorsDialogComponent;
}());
exports.AddCollaboratorsDialogComponent = AddCollaboratorsDialogComponent;
