import {
  Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, omit as _omit, find as _find } from 'lodash';
import * as moment from 'moment';

import { PunchCardService } from  '../punch-cards/punch-card.service';
import { InvoiceService } from  '../invoices/invoice.service';
import { InvoicePunchCardsDialogComponent } from './invoice-punch-cards-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';

@Component({
  selector: 'ruckit-sent-punch-cards',
  templateUrl: './sent-punch-cards.component.html',
  styleUrls: ['./sent-punch-cards.component.scss'],
  providers: [PunchCardService, InvoiceService]
})
export class SentPunchCardsComponent implements OnInit, OnChanges {
  @Input() invoice;
  @Input() search = '';
  @Output() updateInvoice = new EventEmitter<boolean>();
  count;
  selectedCount = 0;
  punchCards: any = [];
  loading = true;
  errors = [];
  punchCardReq;
  invoiceReq;
  page = 1;
  sortBy: string;
  sortAsc = true;
  sortParameter: string;
  allSelected = false;
  selectedPunchCards = [];
  excludePunchCards = [];
  punchCardFilters;
  checkingDuplicates = false;
  confirmDialog: MatDialogRef<any>;
  multipleActionDropdownOptions = [
    { name: 'Remove from Invoice', action: 'remove', link: false },
    { name: 'Void', action: 'void', link: false }
  ];
  menuOptions = [
    {name: 'Edit', action: 'edit', link: true},
    {name: 'Remove from Invoice', action: 'remove', link: false},
    {name: 'Void', action: 'void', link: false}
  ];
  addPunchCardsCallback = (e) => {
    this.updateInvoice.emit(true);
    this.getPunchCards();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private punchCardService: PunchCardService,
    private invoiceService: InvoiceService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.forEach((params: Params) => {
      this.sortBy = params.sortPunchCardsBy ? params.sortPunchCardsBy : 'start_time';
      this.sortAsc = params.sortPunchCardsAsc ? (params.sortPunchCardsAsc === 'true') : false;
      this.sortParameter = params.sortPunchCardsParameter ? params.sortPunchCardsParameter : null;
      this.page = params.sortPunchCardsPage ? params.sortPunchCardsPage : 1;
    });
    this.getPunchCards({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  ngOnChanges(changes: { [search: string]: SimpleChange }) {
    for (let propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        let chng = changes[propName];
        if (propName === 'search') { this.search = chng.currentValue; }
      }
    }

    this.changeSearch(this.search);
  }

  pageChange(event) {
    this.page = event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortPunchCardsPage: this.page > 1 ? this.page : null
      }
    });
    this.getPunchCards({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  getPunchCards(query = {}, append = false) {
    if (!append) { this.punchCards = []; }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    if (this.punchCardReq) { this.punchCardReq.unsubscribe(); }

    this.punchCardFilters = {
      ordering: order,
      search: this.search,
      invoice: this.invoice.id,
      ...query
    };

    if (this.checkingDuplicates) {
      this.punchCardFilters['only_dupes'] = 'True';
    }

    this.punchCardReq = this.punchCardService.getCondensedPunchCards(this.punchCardFilters).subscribe(
      punchCards => {
        if (append) {
          this.punchCards = this.punchCards.concat(punchCards);
        } else {
          this.punchCards = punchCards;
        }
        this.count = this.punchCardService.count;
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) { this.sortAsc = !this.sortAsc; }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortPunchCardsBy: this.sortBy,
        sortPunchCardsAsc: this.sortAsc,
        sortPunchCardsParameter: null
      }
    });
    this.loading = true;
    this.getPunchCards({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      page: this.page
    });
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        sortPunchCardsBy: this.sortBy,
        sortPunchCardsAsc: this.sortAsc,
        sortPunchCardsParameter: this.sortParameter
      }
    });
    this.loading = true;
    this.getPunchCards({
      [this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy,
      page: this.page
    });
  }

  openAddPunchCard() {
    const dialog = this.dialog.open(InvoicePunchCardsDialogComponent, {
      width: '100%',
      height: '100%',
      disableClose: true
    });
    dialog.componentInstance.invoice = this.invoice;
    dialog.componentInstance.customerId = this.invoice.customerOrganization.id;
    dialog.componentInstance.customer = this.invoice.customerOrganization;
    dialog.componentInstance.routeToInvoice = false;
    dialog.componentInstance.callback = this.addPunchCardsCallback;
  }

  removeSelectedPunchCards(punchCards = null) {
    let model = {id: this.invoice.id};

    if (punchCards || this.selectedPunchCards.length) {
      Object.assign(model, {punchcards: punchCards || this.selectedPunchCards});
    } else if (this.allSelected) {
      let params = new HttpParams();
      let filters = _omit(this.punchCardFilters, ['ordering', 'invoice']);
      if (filters) {
        Object.keys(filters).map(key => {
          if (filters[key] && filters[key].length) {
            params = params.set(key, filters[key]);
          }
        });
      }
      if (params.toString().length) {
        Object.assign(model, { filters: params.toString() });
      }
      Object.assign(model, { excludePunchCards: this.excludePunchCards });
    }

    this.invoiceReq = this.invoiceService.removeFromInvoice(model, 'punchcards').subscribe((res) => {
      this.invoice = res;
      this.search = '';
      this.allSelected = false;
      this.selectedCount = 0;
      this.selectedPunchCards = [];
      this.updateInvoice.emit(true);
      this.getPunchCards();
    }, err => console.error(err));
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.getPunchCards();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  menuAction(name, punchCard) {
    switch (name) {
      case 'remove':
        punchCard ? this.removeSelectedPunchCards([punchCard.id]) : this.removeSelectedPunchCards();
        break;
      case 'void':
        punchCard ? this.voidSelectedPunchCards([punchCard.id]) : this.voidSelectedPunchCards();
        break;
    }
  }

  voidSelectedPunchCards(punchCards = null) {
    punchCards = punchCards ? punchCards : this.selectedPunchCards;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Punch Cards?',
      body: 'These punch cards will be marked as \'Void\' and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        punchCards.forEach((punchCardId, index) => {
          let punchCard = _find(this.punchCards, { id: punchCardId });
          this.punchCardService.save({ id: punchCard.id, void: true })
            .subscribe((result) => {
              punchCard['void'] = true;
              punchCard['loading'] = false;
              if (index === punchCards.length - 1) {
                this.allSelected = false;
                this.selectedPunchCards = [];
                this.selectedCount = 0;
                setTimeout(() => {
                  this.getPunchCards();
                  this.updateInvoice.emit(true);
                }, 2000);
              }
            }, (err) => {
              this.errors = err;
            });
        });
      }
      this.confirmDialog = null;
    });
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }

  duplicateCheck() {
    this.getPunchCards();
  }

  selector(event, punchCard = null) {
    if (punchCard) {
      if (!event.target.checked) {
        punchCard.selected = false;
        pull(this.selectedPunchCards, punchCard.id);
        if (this.allSelected) {
          this.excludePunchCards.push(punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedCount = this.selectedPunchCards.length;
        }
      } else {
        punchCard.selected = true;
        if (this.allSelected) {
          pull(this.excludePunchCards, punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedPunchCards.push(punchCard.id);
          this.selectedCount = this.selectedPunchCards.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.punchCards.forEach((_punchCard) => { _punchCard.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludePunchCards.length);
      }
      this.selectedPunchCards = [];
      this.excludePunchCards = [];
    }
  }
}
