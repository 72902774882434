<div class="mobile-dispatch-drivers"
     [ngClass]="{readonly: !job || !job.canEdit}">
  <div *ngFor="let driver of drivers" class="slot" (click)="select(driver)">
    <div class="icon">
      <span *ngIf="!driver.image">{{ driver.name[0] }}</span>
      <img *ngIf="driver.image" [src]="driver.dispatchImage"/>
    </div>
    <div class="info">
      <h3>{{ driver.name }}</h3>
      <h4 (click)="editTruck(driver)">{{ driver.truck ? driver.truck.displayName : '--' }}</h4>
    </div>
    <div class="actions">
      <button class="radio-button" [ngClass]="{ 'active': (selectedDriver && selectedDriver.id) === driver.id }"></button>
    </div>
  </div>
  <my-loader *ngIf="loading" class="mobile-loader full-height"></my-loader>
  <div class="bottom-action" *ngIf="selectedDriver">
    <button translate (click)="add(selectedDriver)">CREATE ASSIGNMENT</button>
  </div>
</div>
