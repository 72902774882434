const camelcaseKeysDeep = require('camelcase-keys-deep');

export class CyberTripFilter {
  id: string;
  name: string;

  constructor(tripFilterInfo: any) {
    tripFilterInfo = camelcaseKeysDeep(tripFilterInfo);

    this.id = tripFilterInfo.id;
    this.name = tripFilterInfo.name;
  }
}
