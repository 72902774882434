import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'assignment-trips',
  templateUrl: './assignment-trips.component.html',
  styleUrls: ['./assignment-trips.component.scss'],
  providers: []
})
export class AssignmentTripsComponent implements OnInit {
  displayedColumns = [];
  view = 'list';
  dataSource: AssignmentTripDataSource;
  loading = false;
  errors = [];
  @Input() trips: any = [];
  @Input() activeTrips: any = false;
  count = 0;
  menuOptions = [
    { name: 'Export', action: 'export', link: false, external: false },
    { name: 'Void', action: 'void', link: false, external: false },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dataSource = new AssignmentTripDataSource();
  }

  ngOnInit() {
    this.dataSource.setData(this.trips);
    this.count = this.trips.length;
    this.route.queryParams.forEach((params: Params) => {
      this.view = params['view'] ? params['view'] : this.view;
      if (this.view === 'stats') {
        this.displayedColumns = [
          'ticket', 'total-time', 'en-route1', 'loading', 'en-route2',
          'unloading', 'weight', 'invoice-total', 'expense-total', 'assignments'
        ];
      } else {
        this.displayedColumns = [
          'ticket', 'total-time', 'assignments'
        ];
      }
    });
  }

  setSelectedAction(option) {
    switch (option.name) {
      case 'Export':
        break;
    }
  }
}

class AssignmentTripDataSource implements DataSource<any> {
  data = new BehaviorSubject<any>([]);

  setData(trips: any[]) {
    const rows = trips;
    this.data.next(rows);
  }

  connect() {
    return this.data;
  }

  disconnect() { }
}
