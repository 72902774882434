"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./ruckit-button.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i3 = require("@angular/material/icon");
var i4 = require("@angular/common");
var i5 = require("@angular/flex-layout/extended");
var i6 = require("@angular/flex-layout/core");
var i7 = require("./ruckit-button.component");
var styles_RuckitButtonComponent = [i0.styles];
var RenderType_RuckitButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RuckitButtonComponent, data: {} });
exports.RenderType_RuckitButtonComponent = RenderType_RuckitButtonComponent;
function View_RuckitButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[8, "className", 0], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa ", _co.leadingIcon, ""); var currVal_1 = i1.ɵnov(_v, 1).inline; var currVal_2 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_RuckitButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[8, "className", 0], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa ", _co.trailingIcon, ""); var currVal_1 = i1.ɵnov(_v, 1).inline; var currVal_2 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_RuckitButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "button", [["class", "ruckit-button btn"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 933888, null, 0, i5.DefaultClassDirective, [i1.ElementRef, i6.StyleUtils, i6.MediaMarshaller, i4.ɵNgClassImpl, [6, i4.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RuckitButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RuckitButtonComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ruckit-button btn"; var currVal_1 = _co.setClasses(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.setClasses(); var currVal_3 = "ruckit-button btn"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.leadingIcon; _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.trailingIcon; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.title; _ck(_v, 6, 0, currVal_5); }); }
exports.View_RuckitButtonComponent_0 = View_RuckitButtonComponent_0;
function View_RuckitButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ruckit-button", [], null, null, null, View_RuckitButtonComponent_0, RenderType_RuckitButtonComponent)), i1.ɵdid(1, 49152, null, 0, i7.RuckitButtonComponent, [], null, null)], null, null); }
exports.View_RuckitButtonComponent_Host_0 = View_RuckitButtonComponent_Host_0;
var RuckitButtonComponentNgFactory = i1.ɵccf("ruckit-button", i7.RuckitButtonComponent, View_RuckitButtonComponent_Host_0, { isActive: "isActive", isDisabled: "isDisabled", fullWidth: "fullWidth", title: "title", leadingIcon: "leadingIcon", trailingIcon: "trailingIcon", type: "type", loading: "loading" }, {}, []);
exports.RuckitButtonComponentNgFactory = RuckitButtonComponentNgFactory;
