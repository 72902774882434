import { DriverMetadata } from './driver-metadata.types';

export class DriverMetadataSerializer {
  fromJson(json: any): DriverMetadata {
    return json;
  }

  toJson(obj: DriverMetadata): any {
    return obj;
  }
}
