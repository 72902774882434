"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BannerComponent = /** @class */ (function () {
    function BannerComponent() {
        this.inviteConnection = new core_1.EventEmitter();
        this.findConnection = new core_1.EventEmitter();
    }
    BannerComponent.prototype.invite = function () { this.inviteConnection.emit(); };
    BannerComponent.prototype.connect = function () { this.findConnection.emit(); };
    return BannerComponent;
}());
exports.BannerComponent = BannerComponent;
