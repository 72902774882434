import { Component, Input } from '@angular/core';

@Component({
  selector: 'collapsible-section',
  templateUrl: './collapsible-section.component.html',
  styleUrls: ['./collapsible-section.component.scss']
})
export class CollapsibleSectionComponent {
  @Input() controlPosition = 'left';
  @Input() title = 'Expand';
  @Input() collapsed = false;

  constructor() { }
}
