"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableDropAction = function (event, row, config, dropAction) {
    if (config.allowDrop) {
        dropAction.emit([event, row]);
        row.over = false;
    }
};
exports.tableMenuAction = function (name, row, menuAction) {
    menuAction.emit([name, row]);
};
exports.tableDragAction = function (event, row) {
    if (event.type === 'dragenter') {
        row.over = true;
    }
    if (event.type === 'dragleave' || event.type === 'dragexit') {
        row.over = false;
    }
};
exports.tableClickAction = function (event, row, record, clickAction) {
    var target = event.target || event.srcElement || event.currentTarget;
    if ((target &&
        target.className &&
        target.className.includes('action-menu-icon')) ||
        (target && target.type === 'checkbox') ||
        target.type === 'href') {
        // Do nothing
    }
    else {
        if (record !== row) {
            record.selected = false;
        }
        record = row;
        row.selected = true;
        clickAction.emit([event, row]);
    }
};
exports.tableReorderStuckFields = function (displayedColumns) {
    var actionsIndex = displayedColumns.indexOf('actions');
    if (actionsIndex !== -1) {
        displayedColumns.splice(actionsIndex, 1);
        displayedColumns.push('actions');
    }
    var selectIndex = displayedColumns.indexOf('select');
    if (selectIndex !== -1) {
        displayedColumns.splice(selectIndex, 1);
        displayedColumns.unshift('select');
    }
};
exports.tableRemoveRecord = function (dataSource, value, property) {
    if (property === void 0) { property = 'id'; }
    dataSource.data = dataSource.data.filter(function (record) { return record[property] !== value; });
};
exports.tableParseRecords = function (records, config) {
    return records.map(function (record) {
        if (record['customFieldData']) {
            Object.keys(record['customFieldData']).forEach(function (key) {
                record[key] = record['customFieldData'][key];
            });
        }
        if (config['customRecordFields']) {
            Object.keys(config['customRecordFields']).forEach(function (key) {
                record[key] = config['customRecordFields'][key];
            });
        }
        return record;
    });
};
