"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["hr.divider[_ngcontent-%COMP%]{border:0;height:0;border-bottom:1px solid #dfe2e5;margin:20px 0}.edit-drawer[_ngcontent-%COMP%]{position:fixed;margin-top:90px;height:calc(100vh - 70px);display:flex;flex-direction:column}.edit-drawer[_ngcontent-%COMP%]   .errors[_ngcontent-%COMP%]{padding:0 25px 0 10px}  .with-action-bar .edit-drawer{position:fixed;margin-top:170px;height:calc(100vh - 150px)}form[_ngcontent-%COMP%]{position:relative;width:100%;display:flex;flex-direction:column;flex:1 1 auto;height:100%}form[_ngcontent-%COMP%]   .field-group[_ngcontent-%COMP%]{padding:0 30px;position:relative;flex-grow:1;min-width:50%}form[_ngcontent-%COMP%]   .field-group.full-width[_ngcontent-%COMP%]{min-width:100%}form[_ngcontent-%COMP%]   .field-group.half-width[_ngcontent-%COMP%]{padding-right:30px;min-width:25%;margin-left:20px}form[_ngcontent-%COMP%]   .field-group.device-id[_ngcontent-%COMP%]{margin-left:0;padding-left:30px;padding-right:10px}.form-content[_ngcontent-%COMP%]{flex:1 1 auto}.sidenav-footer[_ngcontent-%COMP%]{width:100%;min-height:60px;background-color:#f0f3f8;margin-top:30px;padding:12px 30px;text-align:center}.sidenav-footer[_ngcontent-%COMP%]   ruckit-button[_ngcontent-%COMP%]{margin-right:20px}.sidenav-footer[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .btn[_ngcontent-%COMP%]{margin-bottom:0;margin-right:20px}.sidenav-footer[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .btn[_ngcontent-%COMP%]:last-child{margin-right:0}.tablet.edit-drawer[_ngcontent-%COMP%]{margin-right:20px;margin-top:calc(64px + 30px);height:calc(100vh - 70px - 62px)}"];
exports.styles = styles;
