import { Component, ViewChild, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialogRef } from '@angular/material';

import { OrganizationService } from '../organizations/organization.service';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'driver-job-report-filter-dialog',
  templateUrl: './driver-job-report-filters-dialog.component.html',
  styleUrls: ['./driver-job-report-filters-dialog.component.scss']
})
export class DriverJobReportFiltersDialogComponent {
  datePipe = new DatePipe('en-US');
  loading = false;
  errors = [];

  companyConfig = {
    searchable: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: 'Select Company',
    loadingText: 'Loading Companies...',
    noResultsText: 'No Companies',
    service: OrganizationService,
    query: {}
  };
  @ViewChild('companyDropdown', { static: true }) companyDropdown: RuckitDropdownComponent;

  callback: Function;
  model = {
    company: null,
  };

  constructor(
    public dialogRef: MatDialogRef<DriverJobReportFiltersDialogComponent>
  ) { }

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.callback(this.model);
    this.dialogRef.close();
  }

  onDateChanged(dates: Date[], type = ''): void {
    this.model[type] = dates[0];
  }
}
