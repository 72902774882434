"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".group-header[_ngcontent-%COMP%]{white-space:nowrap}.inline[_ngcontent-%COMP%]{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.inline[_ngcontent-%COMP%]   .dropdown-button[_ngcontent-%COMP%]{min-width:unset;border:unset;border-radius:0;padding-left:0;border-bottom:1px solid #ced0da;background-image:unset}.has-icon[_ngcontent-%COMP%]   .option-left[_ngcontent-%COMP%]{display:flex}.has-icon[_ngcontent-%COMP%]   .option-left[_ngcontent-%COMP%]   .option-icon[_ngcontent-%COMP%]{align-items:center;display:flex;height:100%;margin-right:10px;color:#2e80d7}.option-number[_ngcontent-%COMP%]{font-size:16px;line-height:36px;margin-right:10px;width:18px}"];
exports.styles = styles;
