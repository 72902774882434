"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var paystub_filter_service_1 = require("./paystub.filter.service");
var connection_service_1 = require("../connections/connection.service");
var api_service_1 = require("../shared/api.service");
var PaystubFiltersDialogComponent = /** @class */ (function () {
    function PaystubFiltersDialogComponent(paystubFilterService, connectionService, dialogRef) {
        this.paystubFilterService = paystubFilterService;
        this.connectionService = connectionService;
        this.dialogRef = dialogRef;
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = false;
        this.errors = [];
        this.jobs = [];
        this.jobsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.customers = [];
        this.customersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.model = {
            startDate: null,
            endDate: null,
            job: null,
            customer: null,
            draft: false,
            sent: false
        };
    }
    PaystubFiltersDialogComponent.prototype.ngOnInit = function () {
        this.setDefaultDate();
        this.getJobs();
        this.getCustomers();
    };
    PaystubFiltersDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobsReq) {
            this.jobsReq.unsubscribe();
        }
        if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
            this.customersReq.unsubscribe();
        }
    };
    PaystubFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    PaystubFiltersDialogComponent.prototype.submit = function () {
        if (this.model.startDate) {
            var startDate = new Date(this.model.startDate);
            var name_1 = this.datePipe.transform(startDate, 'MM/dd/yyyy');
            startDate.setHours(0, 0, 0, 0);
            Object.assign(this.model.startDate, { id: startDate.toISOString(), name: name_1 });
        }
        if (this.model.endDate) {
            var endDate = new Date(this.model.endDate);
            var name_2 = this.datePipe.transform(endDate, 'MM/dd/yyyy');
            endDate.setHours(23, 59, 59, 999);
            Object.assign(this.model.endDate, { id: endDate.toISOString(), name: name_2 });
        }
        this.callback(this.model);
        this.dialogRef.close();
    };
    PaystubFiltersDialogComponent.prototype.getJobs = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        this.jobs = [];
        this.jobsConfig.loadingOptions = true;
        this.jobsConfig = lodash_1.clone(this.jobsConfig);
        if (this.jobsReq) {
            this.jobsReq.unsubscribe();
        }
        this.jobsReq = this.paystubFilterService.getFilters('jobs', search).subscribe(function (jobs) {
            _this.jobs = jobs;
            if (_this.model.job) {
                var selectedOption = lodash_1.find(_this.jobs, { id: _this.model.job.id });
                _this.jobs = lodash_1.reject(_this.jobs, selectedOption);
                _this.jobs.unshift(_this.model.job);
                _this.jobsDropdown.selectedOption = selectedOption;
            }
            _this.jobsDropdown.config.loadingOptions = false;
            _this.jobsConfig.loadingOptions = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
            _this.jobsDropdown.config.loadingOptions = false;
            _this.jobsConfig.loadingOptions = false;
            _this.jobsConfig = lodash_1.clone(_this.jobsConfig);
        });
    };
    PaystubFiltersDialogComponent.prototype.getCustomers = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.paystubFilterService.getFilters('customers', search).subscribe(function (customers) {
            _this.customers = customers;
            if (_this.model.customer) {
                var selectedOption = lodash_1.find(_this.customers, { id: _this.model.customer.id });
                _this.customers = lodash_1.reject(_this.customers, selectedOption);
                _this.customers.unshift(_this.model.customer);
                _this.customersDropdown.selectedOption = selectedOption;
            }
            _this.customersDropdown.config.loadingOptions = false;
            _this.customersConfig.loadingOptions = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.customersConfig.loadingOptions = false;
        });
    };
    PaystubFiltersDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var filterType;
        var options;
        switch (type) {
            case 'customer':
                filterType = 'customers';
                config = this.customersConfig;
                options = this.customers;
                break;
            case 'job':
                filterType = 'jobs';
                config = this.jobsConfig;
                options = this.jobs;
                break;
        }
        if (!config.loadingOptions) {
            var o = this.paystubFilterService.getNext(filterType);
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'customer':
                            _this.customers = _this.customers.concat(results);
                            break;
                        case 'job':
                            _this.jobs = _this.jobs.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    PaystubFiltersDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'customer':
                this.getCustomers(term);
                break;
            case 'job':
                this.getJobs(term);
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    PaystubFiltersDialogComponent.prototype.setDefaultDate = function () {
        if (!this.model.startDate) {
            this.model.startDate = null;
        }
        if (!this.model.endDate) {
            this.model.endDate = null;
        }
    };
    PaystubFiltersDialogComponent.prototype.onDateChanged = function (values, type) {
        if (type === void 0) { type = ''; }
        if (values && values.length) {
            this.model[type] = values[0];
        }
    };
    return PaystubFiltersDialogComponent;
}());
exports.PaystubFiltersDialogComponent = PaystubFiltersDialogComponent;
