"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var shared_1 = require("../../shared");
var ReportTypeDialogComponent = /** @class */ (function () {
    function ReportTypeDialogComponent(dialog, dialogRef, data) {
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.errors = [];
        this.typeDropdownConfig = { nameProperty: 'name' };
        this.typeOptions = [
            {
                value: 'all',
                name: 'Internal & External Fleets'
            },
            {
                value: 'internal',
                name: 'Internal Fleet'
            },
            {
                value: 'external',
                name: 'External Fleet'
            }
        ];
        this.selectedOption = this.typeOptions.find(function (option) { return (data.selectedOption === option.value); });
    }
    ReportTypeDialogComponent.prototype.ngOnInit = function () {
        if (this.selectedOption && this.typeSelect) {
            this.typeSelect.selectedOption = this.selectedOption;
        }
    };
    ReportTypeDialogComponent.prototype.submit = function () {
        this.callback(this.selectedOption);
        this.dialogRef.close();
    };
    return ReportTypeDialogComponent;
}());
exports.ReportTypeDialogComponent = ReportTypeDialogComponent;
