<form #saveOrganizationMarket="ngForm">
  <div md-dialog-title class="modal-title">
    <h1 translate>Market Tag</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <div md-dialog-content class="md-dialog-content">

    <div class="form-content">
      <div class="group name">
        <label class="required" translate>Market Name</label>
        <input required type="text" name="name" [(ngModel)]="model.name" #title="ngModel" />
      </div>
      <div class="field-group notes">
        <label translate>Notes</label>
        <textarea [(ngModel)]="model.notes" name="notes"></textarea>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="saveOrganizationMarket.form.valid && submitOrganizationMarket(); false"
            [disabled]="!saveOrganizationMarket.form.valid || loading"
            [ngClass]="{loading: loading}"
            translate>
      Save Market
    </button>
  </div>
</form>