import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { Driver } from '../../drivers/driver';
import { DriverService } from '../../drivers/driver.service';

@Component({
  selector: 'no-driver-assignments',
  templateUrl: './no-driver-assignments.component.html',
  styleUrls: ['./no-driver-assignments.component.scss'],
})
export class NoDriverAssignmentsComponent implements OnInit {
  @Input() driverId: string;
  @Output() onCreateNewAssignment: EventEmitter<Driver> = new EventEmitter();

  driver$: Observable<Driver>;
  dispatchDialog: MatDialogRef<any>;
  @ViewChild('dialogRef', { static: false }) dialogRef: TemplateRef<any>;

  constructor(private driverService: DriverService, public dialog: MatDialog) {}

  ngOnInit() {
    this.getDriverData();
  }

  getDriverData() {
    this.driverService.get(this.driverId).subscribe((driver: Driver) => {
      this.openDialog(driver);
    });
  }

  openDialog(driver: Driver) {
    this.dispatchDialog = this.dialog.open(this.dialogRef, {
      width: '430px',
      height: '250px',
      data: driver,
    });
  }

  onCreateNewAssignmentClick(driver: Driver) {
    this.onCreateNewAssignment.emit(driver);
    this.dispatchDialog.close();
  }
}
