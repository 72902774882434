import { clone } from 'lodash';

import { User } from '../users/user';
import { Organization } from '../organizations/organization';
import { Trip } from './trip';
import { Resource } from '../shared/resource';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class TripDecision extends Resource {
  id: string;
  trip: Trip;
  organization: Organization;
  decidedBy: User;
  decidedAt: string;
  decisionStatus: string;
  exportedAt: string;
  notes: string;
  loading = false;

  url(action): string {
    return '/trips/' + this.id + '/decisions/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
