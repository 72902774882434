import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { combineLatest as observableCombineLatest } from 'rxjs';

import { OrderService } from '../order.service';
import { CondensedTrip } from '../../trips/condensed-trip';
import { JobEvent } from '../../job-events/job-event';
import { TicketDetailDialogComponent } from '../ticket-detail-dialog/ticket-detail-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { JobEventService } from '../../job-events/job-event.service';

@Component({
  selector: 'order-ticket-list',
  templateUrl: './order-ticket-list.component.html',
  styleUrls: ['../order.scss']
})
export class OrderTicketListComponent implements OnInit {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  loading = true;
  errors = [];

  jobEvent: JobEvent;
  tickets: CondensedTrip[];
  lastUpdated: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public orderService: OrderService,
    private jobEventService: JobEventService,
    private deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    ).subscribe(result => {
      if (result.params['jobEventId']) { this.orderService.setJobEventId(result.params['jobEventId']); }
    });
    this.orderService.orderData.subscribe(orderData => {
      if (!this.jobEvent) { this.loading = true; }
      this.getTicketData(orderData);
    });
  }

  getTicketData(orderData: [CondensedTrip[], any]) {
    this.tickets = orderData[0].sort((a, b) => (
                                (a.loadingCompleteDatetime > b.loadingCompleteDatetime) ? -1 :
                                (a.loadingCompleteDatetime < b.loadingCompleteDatetime) ? 1 : 0
                               ))
                               .filter(ticket => ['enroute_loading', 'loading'].indexOf(ticket.tripStatus) === -1);
    this.lastUpdated = new Date().toISOString();
    if (!this.jobEvent) {
      this.jobEventService.getJobEvent(this.orderService.jobEventId.getValue()).subscribe(jobEvent => {
        this.jobEvent = jobEvent;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  openTicketDetails(ticket: CondensedTrip) {
    const dialog = this.dialog.open(TicketDetailDialogComponent, {
      width: this.device.mobile ? '320px' : '480px',
      data: {
        ticket: ticket,
        jobEvent: this.jobEvent
      }
    });
    dialog.componentInstance.callback = (updatedTicket: CondensedTrip) => {
      this.tickets[this.tickets.findIndex(t => (t.id === updatedTicket.id))] = updatedTicket;
    };
  }
}
