import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { TicketsComponent } from './tickets.component';
import { CreateTicketComponent } from './create-ticket.component';
import { TicketSaveGuard } from '../guards/scaleit-ticket-save.guard';

const routes: Routes = [
  {
    path: 'scale', component: CreateTicketComponent, canActivate: [AuthGuard],
    canDeactivate: [TicketSaveGuard]
  },
  {
    path: 'scale/create-ticket', component: CreateTicketComponent, canActivate: [AuthGuard],
    canDeactivate: [TicketSaveGuard]
  },
  {
    path: 'scale/create-ticket/:voidTicketId',
    component: CreateTicketComponent,
    canActivate: [AuthGuard],
    canDeactivate: [TicketSaveGuard]
  },
  { path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TicketsRoutingModule { }
