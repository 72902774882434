"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container.trackables-container[_ngcontent-%COMP%]{padding:10px 30px;position:relative}.container.trackables-container[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 70px - 30px)!important}.container.trackables-container[_ngcontent-%COMP%]     .fancy-table-wrapper .title{margin-right:15px}.container.trackables-container[_ngcontent-%COMP%]     .fancy-table-wrapper .mat-column-last-status-event, .container.trackables-container[_ngcontent-%COMP%]     .fancy-table-wrapper .mat-column-location, .container.trackables-container[_ngcontent-%COMP%]     .fancy-table-wrapper .mat-column-position-source{display:flex;align-items:center;justify-content:center}.value[_ngcontent-%COMP%]{display:flex;align-items:center}.value.trackable-type[_ngcontent-%COMP%]   .data[_ngcontent-%COMP%]{padding:15px 0;display:flex;align-items:center}.value.uppercase[_ngcontent-%COMP%]{text-transform:uppercase}.value.hidden-overflow[_ngcontent-%COMP%]{overflow-x:hidden;text-overflow:ellipsis;white-space:nowrap}.value.last-status-event[_ngcontent-%COMP%]   .data[_ngcontent-%COMP%], .value.location[_ngcontent-%COMP%]   .data[_ngcontent-%COMP%], .value.position-source[_ngcontent-%COMP%]   .data[_ngcontent-%COMP%]{text-align:center}"];
exports.styles = styles;
