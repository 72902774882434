"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content.jobs-container[_ngcontent-%COMP%]{display:flex;flex-direction:column}.app-content.jobs-container[_ngcontent-%COMP%]  .action-bar-content{padding-bottom:0;min-height:73px}.app-content.jobs-container[_ngcontent-%COMP%]  .subnav .links{margin:0;border-bottom:none}.app-content.jobs-container[_ngcontent-%COMP%]   .jobs[_ngcontent-%COMP%]{display:flex;flex-direction:column;flex:1 1 auto}.app-content.jobs-container[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%]{padding:30px;position:relative}.app-content.jobs-container[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 200px)!important}.app-content.jobs-container[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%]     .fancy-table-wrapper .archived{background-color:#fcfcfd;color:#a3acaf}.app-content.jobs-container[_ngcontent-%COMP%]   .container[_ngcontent-%COMP%]     .fancy-table-wrapper .archived .mat-cell{color:unset}.app-content.jobs-container[_ngcontent-%COMP%]   .link[_ngcontent-%COMP%]{font-weight:600;color:#015bc5}.app-content.jobs-container[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .archived[_ngcontent-%COMP%]{height:100%;display:flex;align-items:center;margin:0 10px}.app-content.jobs-container[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .archived[_ngcontent-%COMP%]   [type=checkbox][_ngcontent-%COMP%]{margin:8px}.app-content.jobs-container[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .edit-selected[_ngcontent-%COMP%], .app-content.jobs-container[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   .new-job[_ngcontent-%COMP%]{height:38px;margin-right:12px}"];
exports.styles = styles;
