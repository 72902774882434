"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var forms_1 = require("@angular/forms");
var index_1 = require("../shared/index");
var data_service_1 = require("../shared/data.service");
var forgot_password_dialog_component_1 = require("../shared/forgot-password-dialog/forgot-password-dialog.component");
/**
 * Login Component: Displays a login form and allows users to login using their
 * email address and password. Users are also able to select "Forgot Password"
 * and have a Password Reset email sent to their email.
 *
 * @todo Add types for properties
 * @todo Create an ErrorMessage type
 */
var LoginComponent = /** @class */ (function () {
    function LoginComponent(ngZone, router, route, dataService, authenticationService, forgotPasswordDialog, fb) {
        this.ngZone = ngZone;
        this.router = router;
        this.route = route;
        this.dataService = dataService;
        this.authenticationService = authenticationService;
        this.forgotPasswordDialog = forgotPasswordDialog;
        this.fb = fb;
        this.loginForm = this.fb.group({
            email: ['', forms_1.Validators.required],
            password: ['', forms_1.Validators.required]
        });
        this.loading = false;
        this.showPassword = false;
        this.noLocalStorage = false;
        this.errors = [];
    }
    /**
    * Calls the logout function from {@link AuthenticationService#logout} to
    * ensure that no user is logged in.
    *
    * If a parameter of `returnUrl` has been
    * set, the parts are split at the `?` into {@link #returnUrl} and
    * {@link #returnParams}.
    *
    * If the device does not support
    * [localStorage]{@link https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage}
    * an error message is set and displayed for the user.
    */
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        // reset login status
        this.authenticationService.logout();
        if (this.route && this.route.queryParams) {
            this.route.queryParams.subscribe(function (params) {
                if (params['returnUrl'] && params['returnUrl'].length) {
                    var urlParts = params['returnUrl'].split('?');
                    _this.returnUrl = urlParts[0];
                    _this.returnParams = urlParts[1];
                }
            });
        }
        try {
            localStorage.setItem('localStorage', '1');
        }
        catch (e) {
            this.noLocalStorage = true;
            this.errors.push('This website does not support mobile browsers in Private Browsing mode.');
        }
    };
    /**
     * Calls the login function from {@link AuthenticationService#login} with the
     * entered email and password as arguments.
     *
     * Upon a successful response, the user is
     * navitated to the {@link #returnUrl} (unless it equals `/logout` or `/`), to
     * `/scale` if they are a Scaleit user, and `/jobs/daily` in all other cases.
     *
     * If login was unsuccessful, the user is presented with an error message.
     */
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(function (result) {
            _this.dataService.changeData({
                authenticated: true,
                displaySidebar: _this.authenticationService.displaySidebar()
            });
            _this.loading = false;
            if (_this.returnUrl && _this.returnUrl.length && _this.returnUrl !== '/logout' && _this.returnUrl !== '/') {
                _this.ngZone.run(function () { return _this.router.navigate([_this.returnUrl]); });
            }
            else if (_this.authenticationService.isScaleit()) {
                _this.ngZone.run(function () { return _this.router.navigate(['/scale']); });
            }
            else {
                _this.ngZone.run(function () { return _this.router.navigate(['/jobs/daily']); });
            }
        }, function (err) {
            _this.loading = false;
            // this.errors = parseErrors(err);
            _this.errors = ['Unable to log in with provided credentials.'];
        });
    };
    /**
    * Calls the logout function from {@link AuthenticationService#logout} and
    * navigates the user to `/login`
    */
    LoginComponent.prototype.logout = function () {
        var _this = this;
        this.loading = true;
        this.authenticationService.logout();
        this.loading = false;
        this.ngZone.run(function () { return _this.router.navigate(['/login']); });
    };
    /**
    * Displays the {@link RuckitForgotPasswordDialogComponent} so that the user
    * can request a password reset.
    */
    LoginComponent.prototype.forgotPassword = function () {
        var _this = this;
        var dialogRef = this.forgotPasswordDialog.open(forgot_password_dialog_component_1.RuckitForgotPasswordDialogComponent, {
            autoFocus: true,
            disableClose: true,
            restoreFocus: true,
            hasBackdrop: false,
            panelClass: 'ruckit-modal'
        });
        this.isShowingForgotPwd = true;
        dialogRef.componentInstance.model = Object.assign({}, this.loginForm.value);
        dialogRef.beforeClosed().subscribe(function (result) {
            _this.isShowingForgotPwd = false;
        });
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
