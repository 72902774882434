import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { WorkOrderTemplate } from './work-order-template';
import { WorkOrderTemplateSerializer } from './work-order-template.serializer';
import { ResourceService } from '../../shared/resource.service';

@Injectable()
export class WorkOrderTemplateService extends ResourceService<WorkOrderTemplate> {
  constructor(http: HttpClient) {
    super(http, 'workordertemplates/', new WorkOrderTemplateSerializer());
  }
}
