"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var paystubs_component_1 = require("./paystubs.component");
var edit_paystub_component_1 = require("./edit-paystub.component");
var routes = [
    { path: '', component: paystubs_component_1.PaystubsComponent, canActivate: [auth_guard_1.AuthGuard] },
    {
        path: 'filter/:state',
        component: paystubs_component_1.PaystubsComponent,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: ':id/edit',
        component: edit_paystub_component_1.EditPaystubComponent,
        canActivate: [auth_guard_1.AuthGuard]
    }
];
var PaystubsRoutingModule = /** @class */ (function () {
    function PaystubsRoutingModule() {
    }
    return PaystubsRoutingModule;
}());
exports.PaystubsRoutingModule = PaystubsRoutingModule;
