<mat-toolbar [ngClass]="setClasses()" [ngStyle]="{'background-color': color, 'color': textColor}">
  <ng-content select="[left-first]"></ng-content>

  <div class="menu-button" *ngIf="menuButton">
    <i class="icon-rows"></i>
  </div>

  <div class="toolbar-logo-container" *ngIf="!sidebar && logo">
    <img src="assets/img/nav-logo.svg" alt="Ruckit Logo" class="toolbar-logo" />
  </div>

  <div class="toolbar-title" [ngClass]="{'logged-in': loggedIn}">
    <span class="title-icon" *ngIf="icon" [ngClass]="{link: iconLink}" (click)="openIconLink()">
      <i class="icon {{ icon }}"></i>
    </span>

    {{title}}
  </div>

  <ng-content select="[left-last]"></ng-content>

  <ng-content select="[center]"></ng-content>

  <span class="toolbar-spacer"></span>

  <event-alert></event-alert>
  <alert-panel></alert-panel>

  <ng-content select="[right-first]"></ng-content>

  <div class="user-markets" *ngIf="userDropdown && loggedIn">
    <mat-select placeholder="{{ 'All Markets' | translate }}"
                [compareWith]="compareTags"
                [formControl]="userTagSelect"
                [(ngModel)]="favoriteTags"
                (selectionChange)="selectTags($event)"
                multiple>
      <mat-option disabled class="mat-option-deselect" (click)="selectTags([])" translate>All Markets</mat-option>
      <mat-option *ngFor="let tag of tags" [value]="tag">{{ tag.name }}</mat-option>
    </mat-select>
  </div>

  <div class="user-notifications" *ngIf="userDropdown && loggedIn">
    <user-notifications></user-notifications>
  </div>  

  <div class="user-dropdown" *ngIf="userDropdown && loggedIn">
    <div class="user" [matMenuTriggerFor]="menu">
      <div class="user-pic">
        <div class="img" *ngIf="user && user.image">
          <img [src]="user && user.image" />
        </div>
        <span *ngIf="user && !user.image">{{ user && user.name && user.name[0] }}</span>
      </div>
      <div class="user-detail">
        <div class="name">{{ user && user.name }}</div>
        <div class="organization">{{ user && user['organization'] && user['organization']['name'] }}</div>
      </div>
    </div>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let option of menuOptions">
        <a *ngIf="option.link" mat-menu-item [routerLink]="option.action">{{ option.name }}</a>
        <button *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action)">{{ option.name }}</button>
      </ng-container>
    </mat-menu>
  </div>

  <ng-content select="[right-last]"></ng-content>
</mat-toolbar>
