import * as moment from 'moment';

export enum LocationUpdateStatus {
  ENROUTE_UNLOADING = 'enroute_unloading',
  LOADING_COMPLETE = 'loading_complete',
  UNLOADING = 'unloading',
  ENROUTE_LOADING = 'enroute_loading',
  LOADING = 'loading',
}

type TLocationUpdateStatusKey = keyof typeof LocationUpdateStatus;
export type TLocationUpdateStatus = typeof LocationUpdateStatus[TLocationUpdateStatusKey];


export class LocationUpdate {
  id: string;
  trip: string;
  driver: string;
  truck: string;
  assignment;
  date: string;
  jobevent: string;
  location: any;
  orientation: number;
  speed: number;
  provider;
  createdAt: Date;
  expired: boolean;
  moreThanThirtyMinutesAgo: boolean;
  tripStatus: TLocationUpdateStatus;

  constructor(locationInfo: any) {
    this.id = locationInfo.id;
    this.trip = locationInfo.trip;
    this.driver = locationInfo.driver;
    this.truck = locationInfo.truck;
    this.assignment = locationInfo.assignment;
    this.date = locationInfo.date;
    this.jobevent = locationInfo.jobevent;
    this.location = locationInfo.location;
    this.orientation = locationInfo.orientation;
    this.speed = locationInfo.speed;
    this.provider = locationInfo.provider;
    this.tripStatus = locationInfo.tripStatus || '';
    this.createdAt = locationInfo.createdAt && new Date(locationInfo.createdAt);
    const currentDate = moment();
    this.expired = this.createdAt && currentDate.diff(moment(this.createdAt), 'minutes') > 10;
    this.moreThanThirtyMinutesAgo = this.createdAt && currentDate.diff(moment(this.createdAt), 'minutes') > 30;
  }
}
