import { Component, Input, OnInit } from '@angular/core';
import { Widget } from './widget';

@Component({
  selector: 'widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  providers: []
})
export class WidgetComponent implements OnInit {
  loading = true;
  errors = [];
  widget: Widget;
  @Input() size: number[];
  @Input() noPadding = false;

  constructor() { }

  ngOnInit() {
    this.widget = new Widget({ size: this.size, noPadding: this.noPadding });
  }
}
