"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var rxjs_1 = require("rxjs");
var DriverPayTimelineListComponent = /** @class */ (function () {
    function DriverPayTimelineListComponent() {
        this.editing = new core_1.EventEmitter();
        this.selected = new core_1.EventEmitter();
        this.selectAllRows = new core_1.EventEmitter();
        this.allSelected = false;
        this.contextMenuEventSubject = new rxjs_1.Subject();
    }
    DriverPayTimelineListComponent.prototype.ngOnInit = function () {
        this.rows.map(function (row) {
            row.data.uniqueStartDate = row.data.assignment.jobevent && row.data.assignment.jobevent.shift1StartTimestamp;
            row.data.date = moment(row.data.assignment.jobevent && row.data.assignment.jobevent.shift1StartTimestamp).format('YYYYMMDD');
            return row;
        });
    };
    DriverPayTimelineListComponent.prototype.selectAll = function () {
        var _this = this;
        this.allSelected = !this.allSelected;
        this.rows.map(function (row) {
            row.data.selected = _this.allSelected;
            return row;
        });
        this.emitSelection();
        this.selectAllRows.emit(this.allSelected);
    };
    DriverPayTimelineListComponent.prototype.emitSelection = function () {
        var selectedRows = this.rows.filter(function (row) { return row.data.selected; });
        this.selected.emit(selectedRows.map(function (row) { return row.data.referenceId; }));
        if (selectedRows.length !== this.rows.length) {
            this.allSelected = false;
            this.selectAllRows.emit(this.allSelected);
        }
    };
    DriverPayTimelineListComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    return DriverPayTimelineListComponent;
}());
exports.DriverPayTimelineListComponent = DriverPayTimelineListComponent;
