import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { clone } from 'lodash';

import { INVOICE } from './mock-invoice';
import { Invoice } from './invoice';
import { environment } from '../../environments/environment';
import { requestHeaders, handleError } from '../shared/api.service';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Injectable()
export class InvoiceService {
  baseUrl = environment.serverUrl;
  invoice: Invoice;
  public nextUri;
  public count = 0;

  constructor(private http: HttpClient) { }

  getMockInvoice() { return INVOICE; }

  getInvoice(invoiceId: string) {
    let invoiceUrl = this.baseUrl + 'invoices/' + invoiceId + '/';

    return this.http.get(invoiceUrl, {headers: requestHeaders()}).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getPublicInvoice(invoiceId: string) {
    let invoiceUrl = this.baseUrl + 'invoices/' + invoiceId + '/';

    return this.http.get(invoiceUrl).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getMockInvoices(count: Number) {
    let invoices = [];

    for (let i = 1; i <= count; i++) {
      let invoice = Object.assign({}, INVOICE);
      invoices.push(invoice);
    }
    return invoices;
  }

  getInvoices(query: any = null): Observable<Invoice[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let invoicesUrl = this.baseUrl + 'invoices/';
    return this.http.get(invoicesUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  save(invoice) {
    invoice = clone(invoice);
    const invoiceUrl = this.baseUrl + 'invoices/';

    if (typeof invoice.job === 'object') {
      invoice.job = invoice.job && invoice.job.id;
    }
    if (typeof invoice.ownerOrganization === 'object') {
      invoice.ownerOrganization = invoice.ownerOrganization.id;
    }
    if (typeof invoice.customerOrganization === 'object') {
      invoice.customerOrganization = invoice.customerOrganization.id;
    }
    if (typeof invoice.billFromCcEmails === 'string') {
      invoice.billFromCcEmails = invoice.billFromCcEmails.split(',');
    }
    if (typeof invoice.billToCcEmails === 'string') {
      invoice.billToCcEmails = invoice.billToCcEmails.split(',');
    }

    invoice = decamelizeKeysDeep(invoice);

    if (!invoice.id) {
      let params = clone(invoice);
      let filterParams;
      if (invoice.filters && invoice.filters.length) {
        filterParams = '?' + invoice.filters;
      } else {
        filterParams = '';
      }

      return this.http.post(invoiceUrl + filterParams, params, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError(handleError)
      );
    } else {
      return this.http.put(invoiceUrl + invoice.id + '/', invoice, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.extractData(res)),
        catchError(handleError)
      );
    }
  }

  remove(invoice) {
    invoice = decamelizeKeysDeep(invoice);
    const invoiceUrl = this.baseUrl + 'invoices/';

    return this.http.delete(invoiceUrl + invoice.id + '/', { headers: requestHeaders() });
  }

  unlock(invoice) {
    invoice = decamelizeKeysDeep(invoice);
    const invoiceUrl = this.baseUrl + 'invoices/';

    return this.http.put(invoiceUrl + invoice.id + '/', { sent: false }, { headers: requestHeaders() });
  }

  send(invoice) {
    invoice = decamelizeKeysDeep(invoice);
    const invoiceUrl = this.baseUrl + 'invoices/';

    return this.http.post(invoiceUrl + invoice.id + '/send/', {}, {headers: requestHeaders()});
  }

  preview(invoiceId) {
    const invoiceUrl = this.baseUrl + 'invoices/';

    return this.http.get(invoiceUrl + invoiceId + '/preview/', { headers: requestHeaders() });
  }

  addToInvoice(invoice, type = 'trips') {
    invoice = clone(invoice);
    let params, filterParams;

    const invoiceUrl = this.baseUrl + 'invoices/' + invoice.id + '/add/';

    if (invoice.filters && invoice.filters.length) {
      filterParams = '?' + invoice.filters;
      if (type === 'trips') {
        params = {trips: []};
      } else if (type === 'punchcards') {
        params = {punchcards: []};
      }
    } else {
      filterParams = '';
      if (type === 'trips') {
        params = {trips: invoice.trips || []};
      } else if (type === 'punchcards') {
        params = {punchcards: invoice.punchcards || []};
      }
    }
    if (type === 'trips') {
      Object.assign(params, { exclude_trips: invoice.excludeTrips || invoice.exclude_trips || [] });
    } else if (type === 'punchcards') {
      Object.assign(params, { exclude_punchcards: invoice.excludePunchCards || invoice.exclude_punch_cards || [] });
    }

    invoice = decamelizeKeysDeep(invoice);

    return this.http.post(invoiceUrl + filterParams, params, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  removeFromInvoice(invoice, type = 'trips') {
    invoice = decamelizeKeysDeep(clone(invoice));
    let params, filterParams;

    const invoiceUrl = this.baseUrl + 'invoices/' + invoice.id + '/remove/';

    if (invoice.filters && invoice.filters.length) {
      filterParams = '?' + invoice.filters;
      if (type === 'trips') {
        params = { trips: [] };
      } else if (type === 'punchcards') {
        params = { punchcards: [] };
      } else if (type === 'surcharges') {
        params = { surcharges: [] };
      }
    } else {
      filterParams = '';
      if (type === 'trips') {
        params = { trips: invoice.trips || [] };
      } else if (type === 'punchcards') {
        params = { punchcards: invoice.punchcards || [] };
      } else if (type === 'surcharges') {
        params = { surcharges: invoice.surcharges || [] };
      }
    }
    if (type === 'trips') {
      Object.assign(params, { exclude_trips: invoice.excludeTrips || invoice.exclude_trips || [] });
    } else if (type === 'punchcards') {
      Object.assign(params, { exclude_punchcards: invoice.excludePunchCards || invoice.exclude_punch_cards || [] });
    } else if (type === 'surcharges') {
      Object.assign(params, { exclude_surcharges: invoice.excludeSurcharges || invoice.exclude_surcharges || [] });
    }

    return this.http.post(invoiceUrl + filterParams, params, {
      headers: requestHeaders()
    }).pipe(
      map(res => this.extractData(res)),
      catchError(handleError)
    );
  }

  expense(invoiceId: string) {
    const expenseUrl = this.baseUrl + 'invoices/' + invoiceId + '/expense/';
    return this.http.post(expenseUrl, {}, {headers: requestHeaders()});
  }

  export(scope, params: any = null) {
    const invoiceUrl = this.baseUrl + 'invoices/export/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(invoiceUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  downloadPdfs(scope, params: any = null) {
    const invoiceUrl = this.baseUrl + 'invoices/download_pdfs/';
    let _scope = clone(decamelizeKeysDeep(scope));
    return this.http.post(invoiceUrl + '?' + params, _scope, {
      headers: requestHeaders()
    }).pipe(catchError(handleError));
  }

  getNext(): Observable<Invoice[]> {
     if (this.nextUri) {
      return this.http.get(this.nextUri, {headers: requestHeaders()}).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    let body = resObj['results'];
    this.count = resObj['count'];
    if (body) {
      return body.map(invoice => {
        return new Invoice(invoice);
      });
    } else if (resObj) {
      return new Invoice(resObj);
    }
  }
}
