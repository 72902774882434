"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var core_1 = require("@angular/core");
var $ = require('jquery');
/**
 * Acts as a private class
 */
var TimepickerConfig = /** @class */ (function () {
    function TimepickerConfig() {
        // Override where the dropdown is appended.
        // Takes either a string to use as a selector, a function that gets passed the
        // clicked input element as argument or a jquery object to use directly.
        this.appendTo = 'body';
        // Close the timepicker when the window is scrolled. (Replicates <select>
        // behavior.)
        this.closeOnWindowScroll = false;
        // Disable typing in the timepicker input box; force users to select from
        // list. More information here.
        this.disableTextInput = false;
        // Disable the onscreen keyboard for touch devices. There can be instances
        // where Firefox or Chrome have touch events enabled (such as on Surface
        // tablets but not actually be a touch device. In this case
        // disableTouchKeyboard will prevent the timepicker input field from being
        // focused. More information here.
        this.disableTouchKeyboard = false;
        // Force update the time to step settings as soon as it loses focus.
        this.forceRoundTime = false;
        // Language constants used in the timepicker. Can override the defaults by
        // passing an object with one or more of the following properties: decimal,
        // mins, hr, hrs.
        this.lang = {
            am: 'am', pm: 'pm', AM: 'AM', PM: 'PM', decimal: '.', mins: 'mins',
            hr: 'hr', hrs: 'hrs'
        };
        // The time that should appear first in the dropdown list.
        this.minTime = '12:00am';
        this.selectOnBlur = false;
        // Update the input with the currently highlighted time value when the
        // timepicker loses focus.
        // Show "24:00" as an option when using 24-hour time format. You must also set
        // timeFormat for this option to work.
        this.show2400 = false;
        // Shows the relative time for each item in the dropdown. minTime or
        // durationTime must be set.
        this.showDuration = false;
        // The amount of time, in minutes, between each item in the dropdown.
        // Alternately, you can specify a function to generate steps dynamically. The
        // function will receive a count integer (0, 1, 2...) and is expected to
        // return a step integer.
        this.step = 30;
        // When scrolling on the edge of the picker, it prevent parent containers ()
        // to scroll. default: false
        this.stopScrollPropagation = false;
        // Highlight the nearest corresponding time option as a value is typed into
        // the form input.
        this.typeaheadHighlight = true;
        // Convert the input to an HTML <SELECT> control. This is ideal for small
        // screen devices, or if you want to prevent the user from entering arbitrary
        // values. This option is not compatible with the following options: appendTo,
        // closeOnWindowScroll, disableTouchKeyboard, forceRoundTime, scrollDefault,
        // selectOnBlur, typeAheadHighlight.
        this.useSelect = false;
        // If a time greater than 24 hours (27:30, for example) is entered, apply
        // modolo 24 to create a valid time. Setting this to false will cause an input
        // of 27:30 to result in a timeFormatError event.
        this.wrapHours = true;
    }
    return TimepickerConfig;
}());
var TimepickerDirective = /** @class */ (function () {
    function TimepickerDirective(_renderer, _elementRef, _ngModel) {
        this._renderer = _renderer;
        this._elementRef = _elementRef;
        this._ngModel = _ngModel;
        this.changeTime = new core_1.EventEmitter();
        this.timeFormatError = new core_1.EventEmitter();
        this.hideTimepicker = new core_1.EventEmitter();
        this.selectTime = new core_1.EventEmitter();
        this.showTimepicker = new core_1.EventEmitter();
        this.timeRangeError = new core_1.EventEmitter();
        // onChange = (_: any) => { };
        this.onTouched = function () { };
    }
    TimepickerDirective.prototype.onChange = function ($event) {
        // this.onChange($event.target.value);
    };
    TimepickerDirective.prototype.onBlur = function ($event) {
        // this.onChange($event.target.value);
    };
    TimepickerDirective.prototype.ngOnChanges = function () {
        var self = this;
        var el = $(this._elementRef.nativeElement);
        // Unbinding if timepicker present
        if (el.timepicker) {
            el.timepicker('remove');
            el.timepicker(this.options);
        }
        el.on('changeTime', function (event) {
            self.onUpdate(event);
            self.changeTime.emit(event);
        });
        el.on('timeFormatError', function (event) {
            self.timeFormatError.emit(event);
        });
        el.on('hideTimepicker', function (event) {
            self.hideTimepicker.emit(event);
        });
        el.on('selectTime', function (event) {
            self.selectTime.emit(event);
        });
        el.on('showTimepicker', function (event) {
            self.showTimepicker.emit(event);
        });
        el.on('timeRangeError', function (event) {
            self.timeRangeError.emit(event);
        });
    };
    TimepickerDirective.prototype.onUpdate = function (event) {
        var value = this._elementRef.nativeElement.value;
        this.writeValue(value);
        this._ngModel.viewToModelUpdate(value);
        this._elementRef.nativeElement.dispatchEvent(new Event('change', { bubbles: true }));
    };
    TimepickerDirective.prototype.writeValue = function (value) {
        this._renderer.setElementProperty(this._elementRef.nativeElement, 'value', value);
    };
    TimepickerDirective.prototype.registerOnChange = function (fn) { this.onChange = fn; };
    TimepickerDirective.prototype.registerOnTouched = function (fn) { this.onTouched = fn; };
    return TimepickerDirective;
}());
exports.TimepickerDirective = TimepickerDirective;
