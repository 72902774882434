"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var job_event_1 = require("../../job-events/job-event");
var MobileDispatchAssignmentsComponent = /** @class */ (function () {
    function MobileDispatchAssignmentsComponent() {
        this.loading = false;
        this.errors = [];
        this.emptySlots = [];
        this.filledSlots = [];
        this.selectSlot = new core_1.EventEmitter();
        this.selectAssignment = new core_1.EventEmitter();
        this.selectRemoveSlot = new core_1.EventEmitter();
        this.dispatchAssignments = new core_1.EventEmitter();
    }
    MobileDispatchAssignmentsComponent.prototype.ngOnInit = function () {
        this.emptySlots = lodash_1.filter(this.shift.slots, function (slot) { return !slot.driver; });
        this.filledSlots = lodash_1.filter(this.shift.slots, function (slot) { return slot.driver; });
    };
    MobileDispatchAssignmentsComponent.prototype.select = function (shift, slot, index) {
        if (slot === void 0) { slot = null; }
        if (index === void 0) { index = null; }
        if (slot && slot.driver && slot.assignment) {
            // slot.editSlot = !slot.editSlot;
        }
        else {
            this.selectSlot.emit({ shift: shift, index: index });
        }
    };
    MobileDispatchAssignmentsComponent.prototype.remove = function (slot) {
        this.selectRemoveSlot.emit(slot);
    };
    MobileDispatchAssignmentsComponent.prototype.dispatch = function () {
        this.dispatchAssignments.emit();
    };
    return MobileDispatchAssignmentsComponent;
}());
exports.MobileDispatchAssignmentsComponent = MobileDispatchAssignmentsComponent;
