"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var collaborators_component_1 = require("./collaborators.component");
var routes = [
    {
        path: '',
        component: collaborators_component_1.CollaboratorsComponent,
        canActivate: [auth_guard_1.AuthGuard]
    },
];
var CollaboratorsRoutingModule = /** @class */ (function () {
    function CollaboratorsRoutingModule() {
    }
    return CollaboratorsRoutingModule;
}());
exports.CollaboratorsRoutingModule = CollaboratorsRoutingModule;
