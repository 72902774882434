const camelcaseKeysDeep = require('camelcase-keys-deep');

export class PaymentType {
  id: string;
  name: string;

  constructor(paymentTypeInfo: any) {
    paymentTypeInfo = camelcaseKeysDeep(paymentTypeInfo);

    this.id = paymentTypeInfo.id;
    if (paymentTypeInfo.name && paymentTypeInfo.name.length) {
      this.name = paymentTypeInfo.name.charAt(0).toUpperCase() + paymentTypeInfo.name.substr(1);
    } else {
      this.name = this.id;
    }
  }
}
