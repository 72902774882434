"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".btn-filters[_ngcontent-%COMP%]{width:100px;height:38px!important;margin:0 10px;justify-content:center;background-image:linear-gradient(to top,#f2f4f7,#fff);color:#354052;background-color:#f8fafc;border-radius:4px;font-weight:600;line-height:36px;text-align:center;border:1px solid rgba(0,0,0,.15)}  .mat-menu-content{min-width:200px;margin-bottom:20px}.reset-filters[_ngcontent-%COMP%]{padding:12px 15px;border-bottom:1px solid #e4e4e4;margin-bottom:12px;text-transform:unset;font-size:16px;letter-spacing:.5px}.reset-filters[_ngcontent-%COMP%]:hover{cursor:pointer;color:#154b80;background-color:#f1f4f8}  .mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0}.search[_ngcontent-%COMP%]{margin-top:0;padding:0 15px;display:inline-block}.search[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{padding:0 4px}.search.no-filters[_ngcontent-%COMP%]{margin-bottom:15px}.filter[_ngcontent-%COMP%]{margin:0 15px;border-bottom:1px solid #e4e4e4;padding:24px 15px 0 0}.filter[_ngcontent-%COMP%]:last-child{border-bottom:0;padding-top:12px;padding-bottom:12px}.filter.checkbox[_ngcontent-%COMP%]{padding:24px 15px 12px 0}.filter.first[_ngcontent-%COMP%]{padding:12px 15px 0 0}.filter.date[_ngcontent-%COMP%]{border-bottom:none}.filter.text[_ngcontent-%COMP%]   .text-input-wrapper[_ngcontent-%COMP%]{display:flex;align-items:center}.filter.text[_ngcontent-%COMP%]   .text-input-wrapper[_ngcontent-%COMP%]   mat-checkbox[_ngcontent-%COMP%]{margin-left:10px}.scroll-container[_ngcontent-%COMP%]{max-height:256px;overflow-y:auto}"];
exports.styles = styles;
