"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".job-summary-container[_ngcontent-%COMP%]{display:flex;padding:10px}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]{display:flex;border-radius:4px;background-color:#fff;border:1px solid #e6eaee;min-height:130px;width:100%;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content;margin:auto}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{flex:1 1 auto;border-right:1px solid #e6eaee;padding:22px 30px}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{opacity:.7;font-size:12px;font-weight:600;color:#7f8fa4;text-transform:uppercase}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{font-size:36px;color:#002649}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   .rates[_ngcontent-%COMP%]{cursor:pointer}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   .rates[_ngcontent-%COMP%]   .value[_ngcontent-%COMP%]{color:#02963f}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   .rates[_ngcontent-%COMP%]   .value.edit-link[_ngcontent-%COMP%]{cursor:pointer}.job-summary-container[_ngcontent-%COMP%]   .job-summary[_ngcontent-%COMP%]   .rates[_ngcontent-%COMP%]   .value.edit-link[_ngcontent-%COMP%]:hover{color:#226acd}"];
exports.styles = styles;
