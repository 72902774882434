"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".collapsible-section[_ngcontent-%COMP%]{position:relative;background:#fff;border:1px solid #e6eaee;height:auto;min-height:85vh;margin-right:10px;max-width:305px;bottom:8px}.collapsible-section[_ngcontent-%COMP%]   .section-content[_ngcontent-%COMP%]{overflow-y:auto;overflow-x:hidden;height:85vh;padding-top:8px}.collapsible-section.collapsed[_ngcontent-%COMP%]{width:36px;flex:0 0 36px}.collapsible-section.collapsed[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{position:absolute;margin:0;top:calc(100% / 2);left:50%;width:-webkit-max-content;width:-moz-max-content;width:max-content;transform:translateX(-50%) translateY(-50%) rotate(-90deg)}.collapsible-section.collapsed.left-controls[_ngcontent-%COMP%]   .title[_ngcontent-%COMP%]   h3[_ngcontent-%COMP%]{transform:translateX(-50%) translateY(-50%) rotate(90deg)}.collapse-control[_ngcontent-%COMP%], .expand-control[_ngcontent-%COMP%]{position:absolute;cursor:pointer;z-index:999;top:calc((100% / 2) - 35px);right:-5px;border:1px solid rgba(0,0,0,.15);height:70px;display:flex;align-items:center;justify-content:center;background:linear-gradient(to right,#f2f4f7,#fff);width:10px;border-radius:2px}.collapse-control[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%], .expand-control[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{fill:#354052;font-size:16px;height:16px;width:16px}.left-controls[_ngcontent-%COMP%]   .collapse-control[_ngcontent-%COMP%], .left-controls[_ngcontent-%COMP%]   .expand-control[_ngcontent-%COMP%]{right:unset;left:-5px}.left-controls[_ngcontent-%COMP%]   .collapse-control[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%], .left-controls[_ngcontent-%COMP%]   .expand-control[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{transform:rotate(180deg)}"];
exports.styles = styles;
