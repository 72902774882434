"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var marketplace_service_1 = require("../marketplace/marketplace.service");
var api_service_1 = require("../shared/api.service");
var PublishJobDialogComponent = /** @class */ (function () {
    function PublishJobDialogComponent(dialogRef, data, marketplaceService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.marketplaceService = marketplaceService;
        this.job = {};
        this.jobevent = {};
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.errors = [];
        this.yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        this.model = {
            data: {
                dates: [],
                shareId: null,
                numTrucks: null,
                haulRate: null
            },
            recipientOrganization: null,
            kind: 'new_job'
        };
        this.job = this.data && this.data.job;
        this.jobevent = this.data && this.data.jobevent;
    }
    PublishJobDialogComponent.prototype.ngOnInit = function () {
        this.model.data.shareId = this.jobevent.shareId;
        this.model.data.haulRate = this.jobevent.haulRate;
    };
    PublishJobDialogComponent.prototype.submit = function () {
        var _this = this;
        this.model.data.dates = this.model.data.dates.map(function (d) { return _this.convertDateFormat(d); });
        this.marketplaceService.publish(this.model).subscribe(function (result) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.loading = false;
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    PublishJobDialogComponent.prototype.onDateChanged = function (values) {
        this.model.data.dates = values;
    };
    PublishJobDialogComponent.prototype.convertDateFormat = function (value) {
        var d = value && new Date(value);
        if (isNaN(+d)) {
            return;
        }
        return d.toISOString().split('T')[0];
    };
    return PublishJobDialogComponent;
}());
exports.PublishJobDialogComponent = PublishJobDialogComponent;
