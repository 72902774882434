"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var moment = require("moment");
var ngx_device_detector_1 = require("ngx-device-detector");
var driver_service_1 = require("../drivers/driver.service");
var job_service_1 = require("../jobs/job.service");
var device_1 = require("../shared/device");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var driver_job_report_service_1 = require("./driver-job-report.service");
var NewJobReportDialogComponent = /** @class */ (function () {
    function NewJobReportDialogComponent(dialogRef, driverJobReportService, deviceDetectorService) {
        this.dialogRef = dialogRef;
        this.driverJobReportService = driverJobReportService;
        this.deviceDetectorService = deviceDetectorService;
        this.device = new device_1.Device();
        this.reportDate = new Date();
        this.startDate = moment(this.reportDate).startOf('day').toISOString();
        this.endDate = moment(this.reportDate).endOf('day').toISOString();
        this.loading = false;
        this.model = {};
        this.errors = [];
        this.driverDropdownConfig = {
            service: driver_service_1.DriverService,
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            query: {
                serializer: 'DefaultDropdown',
                assignments__jobevent__shift1_start__range__exists: this.startDate + "," + this.endDate,
                assignments__jobevent__job__exists: this.model.job && this.model.job.id
            }
        };
        this.jobDropdownConfig = {
            service: job_service_1.JobService,
            selectText: 'Select Job',
            loadingText: 'Loading Jobs...',
            noResultsText: 'No Jobs',
            query: {
                serializer: 'DefaultDropdown',
                jobevents__start__gte: this.startDate,
                jobevents__start__lte: this.endDate,
            }
        };
    }
    NewJobReportDialogComponent.prototype.ngOnInit = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.updateFilters();
    };
    NewJobReportDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
        this.updateFilters();
    };
    NewJobReportDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.model.reportDate = this.reportDate;
        this.driverJobReportService.save(this.model).subscribe(function () {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    NewJobReportDialogComponent.prototype.updateFilters = function () {
        this.startDate = moment(this.reportDate).startOf('day').toISOString();
        this.endDate = moment(this.reportDate).endOf('day').toISOString();
        this.driverDropdownConfig['query'] = __assign({}, this.driverDropdownConfig['query'], { assignments__jobevent__shift1_start__range__exists: this.startDate + "," + this.endDate, assignments__jobevent__job__exists: this.model.job && this.model.job.id });
        this.driverDropdownConfig = __assign({}, this.driverDropdownConfig);
        if (this.driverDropdown) {
            this.driverDropdown.getRecords();
        }
        this.jobDropdownConfig['query'] = __assign({}, this.jobDropdownConfig['query'], { jobevents__start__gte: this.startDate, jobevents__start__lte: this.endDate });
        this.jobDropdownConfig = __assign({}, this.jobDropdownConfig);
        if (this.jobDropdown) {
            this.jobDropdown.getRecords();
        }
    };
    return NewJobReportDialogComponent;
}());
exports.NewJobReportDialogComponent = NewJobReportDialogComponent;
