"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var purchase_order_filter_service_1 = require("./purchase-order.filter.service");
var connection_service_1 = require("../connections/connection.service");
var api_service_1 = require("../shared/api.service");
var lodash_1 = require("lodash");
var PurchaseOrderFiltersDialogComponent = /** @class */ (function () {
    function PurchaseOrderFiltersDialogComponent(purchaseOrderFilterService, connectionService, dialogRef) {
        this.purchaseOrderFilterService = purchaseOrderFilterService;
        this.connectionService = connectionService;
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
        this.products = [];
        this.productsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.locations = [];
        this.locationsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.customers = [];
        this.customersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.model = {
            delivery_location: null,
            customer_organization: null,
            product: null,
            fulfilled: false,
            full_stop: false,
            void: false
        };
    }
    PurchaseOrderFiltersDialogComponent.prototype.ngOnInit = function () {
        this.getProducts();
        this.getCustomers();
        this.getLocations();
    };
    PurchaseOrderFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    PurchaseOrderFiltersDialogComponent.prototype.submit = function () {
        this.callback(this.model);
        this.dialogRef.close();
    };
    PurchaseOrderFiltersDialogComponent.prototype.getProducts = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        this.products = [];
        this.productsConfig.loadingOptions = true;
        this.productsConfig = lodash_1.clone(this.productsConfig);
        if (this.productsReq) {
            this.productsReq.unsubscribe();
        }
        this.productsReq = this.purchaseOrderFilterService.listFilters('products', search).subscribe(function (products) {
            _this.products = products;
            if (_this.model.product) {
                var selectedOption = lodash_1.find(_this.products, { id: _this.model.product.id });
                _this.products = lodash_1.reject(_this.products, selectedOption);
                _this.products.unshift(_this.model.product);
                _this.productsDropdown.selectedOption = selectedOption;
            }
            _this.productsDropdown.config.loadingOptions = false;
            _this.productsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
            _this.productsDropdown.config.loadingOptions = false;
            _this.productsConfig.loadingOptions = false;
            _this.productsConfig = lodash_1.clone(_this.productsConfig);
        });
    };
    PurchaseOrderFiltersDialogComponent.prototype.getCustomers = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.purchaseOrderFilterService.listFilters('customers', search).subscribe(function (customers) {
            _this.customers = customers;
            if (_this.model.customer_organization) {
                var selectedOption = lodash_1.find(_this.customers, { id: _this.model.customer_organization.id });
                _this.customers = lodash_1.reject(_this.customers, selectedOption);
                _this.customers.unshift(_this.model.customer_organization);
                _this.customersDropdown.selectedOption = selectedOption;
            }
            _this.customersDropdown.config.loadingOptions = false;
            _this.customersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.customersConfig.loadingOptions = false;
        });
    };
    PurchaseOrderFiltersDialogComponent.prototype.getLocations = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        this.locationsReq = this.purchaseOrderFilterService.listFilters('locations', search).subscribe(function (locations) {
            _this.locations = locations;
            if (_this.model.delivery_location) {
                var selectedOption = lodash_1.find(_this.locations, { id: _this.model.delivery_location.id });
                _this.locations = lodash_1.reject(_this.locations, selectedOption);
                _this.locations.unshift(_this.model.delivery_location);
                _this.locationsDropdown.selectedOption = selectedOption;
            }
            _this.locationsDropdown.config.loadingOptions = false;
            _this.locationsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.locationsConfig.loadingOptions = false;
        });
    };
    PurchaseOrderFiltersDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var filterType;
        var options;
        switch (type) {
            case 'customer':
                filterType = 'customers';
                config = this.customersConfig;
                options = this.customers;
                break;
            case 'product':
                filterType = 'products';
                config = this.productsConfig;
                options = this.products;
                break;
            case 'location':
                filterType = 'locations';
                config = this.locationsConfig;
                options = this.locations;
                break;
        }
        if (!config.loadingOptions) {
            var o = this.purchaseOrderFilterService.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'customer':
                            _this.customers = _this.customers.concat(results);
                            break;
                        case 'product':
                            _this.products = _this.products.concat(results);
                            break;
                        case 'location':
                            _this.locations = _this.locations.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    PurchaseOrderFiltersDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'customer':
                this.getCustomers(term);
                break;
            case 'product':
                this.getProducts(term);
                break;
            case 'location':
                this.getLocations(term);
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    return PurchaseOrderFiltersDialogComponent;
}());
exports.PurchaseOrderFiltersDialogComponent = PurchaseOrderFiltersDialogComponent;
