<div class="job-stats-container">
  <div class="actions-wrapper">
    <fancy-table #jobStatsTable *ngIf="!loading"
                 [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(availableFilters)]="availableFilters"
                 [(filters)]="appliedFilters"
                 [config]="tableConfig">

      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'requested-trucks'">
            <div class="data">{{ (row.requestedTrucksSum | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'confirmed-trucks'">
            <div class="data">{{ (row.confirmedTrucksSum | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'dispatched-trucks'">
            <div class="data">{{ (row.dispatchedTrucksSum | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'ordered-loads'">
            <div class="data">{{ (row.orderedLoadsSum | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'delivered-loads'">
            <div class="data">{{ (row.deliveredLoadsSum | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'ordered-tons'">
            <div class="data">{{ row.orderedTonsSum || '0' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'delivered-tons'">
            <div class="data">{{ row.deliveredTonsSum || '0' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'drivers-on-shift'">
            <div class="data">{{ (row.driversOnShiftCount | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'connex-actual-tons'">
            <div class="data">{{ row.connexActualQuantitySum || '0' }}</div>
          </div>
          <div class="value" *ngSwitchDefault>{{ value || '-' }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>Day Summary</div>
      </div>
    </fancy-table>
  </div>
</div>
