"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../shared/toolbar/toolbar.component.ngfactory");
var i2 = require("../shared/toolbar/toolbar.component");
var i3 = require("@angular/router");
var i4 = require("../shared/authentication.service");
var i5 = require("../tags/tag.service");
var i6 = require("../users/user.service");
var i7 = require("@ngx-translate/core");
var i8 = require("../shared/user-notifications/user-notifications.component.ngfactory");
var i9 = require("../shared/user-notifications/user-notification.service");
var i10 = require("@angular/common/http");
var i11 = require("../shared/user-notifications/user-notifications.component");
var i12 = require("./notifications-list.component");
var styles_NotificationsListComponent = [];
var RenderType_NotificationsListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationsListComponent, data: {} });
exports.RenderType_NotificationsListComponent = RenderType_NotificationsListComponent;
function View_NotificationsListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "my-toolbar", [], null, null, null, i1.View_ToolbarComponent_0, i1.RenderType_ToolbarComponent)), i0.ɵdid(2, 245760, null, 0, i2.ToolbarComponent, [i3.Router, i4.AuthenticationService, i5.TagService, i6.UserService], { title: [0, "title"] }, null), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "user-notifications", [], null, null, null, i8.View_UserNotificationsComponent_0, i8.RenderType_UserNotificationsComponent)), i0.ɵprd(512, null, i9.UserNotificationService, i9.UserNotificationService, [i10.HttpClient]), i0.ɵdid(7, 245760, null, 0, i11.UserNotificationsComponent, [i3.Router, i9.UserNotificationService], { viewType: [0, "viewType"] }, null)], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Notifications")), ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = "fullview"; _ck(_v, 7, 0, currVal_1); }, null); }
exports.View_NotificationsListComponent_0 = View_NotificationsListComponent_0;
function View_NotificationsListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notifications-list", [], null, null, null, View_NotificationsListComponent_0, RenderType_NotificationsListComponent)), i0.ɵdid(1, 49152, null, 0, i12.NotificationsListComponent, [], null, null)], null, null); }
exports.View_NotificationsListComponent_Host_0 = View_NotificationsListComponent_Host_0;
var NotificationsListComponentNgFactory = i0.ɵccf("notifications-list", i12.NotificationsListComponent, View_NotificationsListComponent_Host_0, {}, {}, []);
exports.NotificationsListComponentNgFactory = NotificationsListComponentNgFactory;
