"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_serializer_1 = require("../users/user.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Message = /** @class */ (function () {
    function Message(messageInfo) {
        messageInfo = camelcaseKeysDeep(messageInfo);
        this.id = messageInfo.id;
        if (typeof (messageInfo.user) === 'object') {
            this.user = (new user_serializer_1.UserSerializer).fromJson(messageInfo.user);
        }
        else {
            this.user = (new user_serializer_1.UserSerializer).fromJson({ id: messageInfo.user });
        }
        this.msg = messageInfo.msg;
    }
    return Message;
}());
exports.Message = Message;
