"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var location_service_1 = require("../locations/location.service");
var connection_service_1 = require("../connections/connection.service");
var project_service_1 = require("../projects/project.service");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var DailyBoardFiltersDialogComponent = /** @class */ (function () {
    function DailyBoardFiltersDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
        this.locationsConfig = {
            selectText: 'Select Location',
            loadingText: 'Loading Locations...',
            noResultsText: 'No Locations',
            service: location_service_1.LocationService,
        };
        this.projectsConfig = {
            selectText: 'Select Project',
            loadingText: 'Loading Projects...',
            noResultsText: 'No Projects',
            service: project_service_1.ProjectService
        };
        this.customersConfig = {
            nameProperty: 'organizationName',
            selectText: 'Select Customer',
            loadingText: 'Loading Customers...',
            noResultsText: 'No Customers',
            idProperty: 'organization.id',
            service: connection_service_1.ConnectionService
        };
        this.model = {
            startLocation: null,
            endLocation: null,
            project: null,
            customer: null,
            apexCustomerJob: null,
        };
    }
    DailyBoardFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    DailyBoardFiltersDialogComponent.prototype.submit = function () {
        this.callback(this.model);
        this.dialogRef.close();
    };
    return DailyBoardFiltersDialogComponent;
}());
exports.DailyBoardFiltersDialogComponent = DailyBoardFiltersDialogComponent;
