<div class="template-wrapper">
  <div class="template" [formGroup]="collaborationTemplate">
    <div class="row apply-to-all-row">
      <div class="field rate" *ngIf="!hasLoadListsEnabled" [style.width.%]="45">
        <div class="label" translate>Requested Amount</div>
        <div class="input-group">
          <input
            type="number"
            formControlName="requestedAmount"
            min="0">
          <dropdown
            [options]="unitsOfMeasure"
            [selectedOption]="collaborationTemplate.controls.requestedUnit.value"
            (onSelect)="setFormControlValue('requestedUnit', $event)">
          </dropdown>
        </div>
      </div>

      <div class="field rate-tracking" [style.width.%]="45">
        <div class="label" translate>How is the rate tracked?</div>
        <dropdown
            [options]="rateTrackingOptions"
            [config]="rateTrackingConfig"
            [selectedOption]="collaborationTemplate.controls.invoiceType.value || null"
            (onSelect)="setFormControlValue('invoiceType', $event)">
          </dropdown>
      </div>

      <div class="field rate" [style.width.%]="45">
        <div class="label" translate>What is the rate?</div>
        <div class="input-group" data-prefix="$">
          <input
            type="number" 
            formControlName="invoiceRate"
            min="0">
          <dropdown 
            *ngIf="collaborationTemplate.controls.invoiceType.value !== null &&
              collaborationTemplate.controls.invoiceType.value.value === 'weight'; else addOn"
            title="-"
            [options]="weightOptions"
            [config]="rateWeightConfig" 
            [selectedOption]="collaborationTemplate.controls.invoiceWeightUnit.value || null"
            (onSelect)="setFormControlValue('invoiceWeightUnit', $event)"
          >
          </dropdown>
          <ng-template #addOn>
            <div class="add-on">
              {{ (collaborationTemplate.controls.invoiceType.value &&
                collaborationTemplate.controls.invoiceType.value.value || '-') | titlecase }}
            </div>
          </ng-template>
        </div>
      </div>

      <div class="field notes" [style.width.%]="hasLoadListsEnabled ? 100 : 45">
        <div class="label" translate>Notes</div>
        <div class="input-group">
          <textarea formControlName="notes"></textarea>
        </div>
      </div>
    </div>

    <div class="row apply-to-all">
      <button class="btn btn-primary-alt" (click)="applyTemplate()"
        [disabled]="!collaborationTemplate.valid || loading"
        [ngClass]="{loading: loading}" translate>
        Apply To All
      </button>
    </div>
  </div>
</div>
