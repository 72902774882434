"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var driver_1 = require("../driver");
var DriverDailyGeotripsComponent = /** @class */ (function () {
    function DriverDailyGeotripsComponent() {
        this.displayedColumns = [
            'start-time',
            'end-time',
            'trip-duration',
            'enroute-loading-time',
            'loading-arrival-time',
            'loading-complete-time',
            'enroute-unloading-time',
            'unloading-arrival-time',
            'unloading-complete-time',
            'trip-status',
            'completed'
        ];
        this.displayedTimes = [
            { column: 'start-time', property: 'startTime', header: 'START TIME' },
            { column: 'end-time', property: 'endTime', header: 'END TIME' },
            { column: 'enroute-loading-time', property: 'enrouteLoadingTime', header: 'ENROUTE LOADING TIME' },
            { column: 'loading-arrival-time', property: 'loadingArrivalTime', header: 'LOADING ARRIVAL TIME' },
            { column: 'loading-complete-time', property: 'loadingCompleteTime', header: 'LOADING COMPLETE TIME' },
            { column: 'enroute-unloading-time', property: 'enrouteUnloadingTime', header: 'ENROUTE UNLOADING TIME' },
            { column: 'unloading-arrival-time', property: 'unloadingArrivalTime', header: 'UNLOADING ARRIVAL TIME' },
            { column: 'unloading-complete-time', property: 'unloadingCompleteTime', header: 'UNLOADING COMPLETE TIME' }
        ];
    }
    return DriverDailyGeotripsComponent;
}());
exports.DriverDailyGeotripsComponent = DriverDailyGeotripsComponent;
