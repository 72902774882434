"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var driver_service_1 = require("../../drivers/driver.service");
var job_service_1 = require("../../jobs/job.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var truck_service_1 = require("../../trucks/truck.service");
var organization_service_1 = require("../../organizations/organization.service");
exports.JOB_DROPDOWN_CONFIG = function (translateService) { return ({
    service: job_service_1.JobService,
    selectText: translateService.instant('Select Job'),
    loadingText: translateService.instant('Loading Jobs...'),
    noResultsText: translateService.instant('No Jobs'),
    searchable: true,
    group: true,
    groupProperty: 'group',
    sortBy: 'project__name,name,start_date',
    includeFullObject: true,
    query: {
        serializer: 'JobGroupedDropdown',
    },
}); };
exports.JOB_EVENTS_DROPDOWN_CONFIG = function (translateService) { return ({
    searchable: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'shift1_start',
    selectText: translateService.instant('Select Job'),
    loadingText: translateService.instant('Loading Jobs...'),
    noResultsText: translateService.instant('No Jobs'),
    includeFullObject: true,
    service: job_event_service_1.JobEventService,
    query: {},
}); };
exports.CARRIER_DROPDOWN_CONFIG = function (translateService) { return ({
    nameProperty: 'name',
    idProperty: 'carrier.id',
    selectText: translateService.instant('Select Carrier'),
    loadingText: translateService.instant('Loading Carriers...'),
    noResultsText: translateService.instant('No Carriers'),
    service: organization_service_1.OrganizationService,
    serviceFunction: 'get',
    query: {
        exclude: null,
    },
    includeFullObject: true,
    prefilledOptions: [],
}); };
exports.DRIVER_DROPDOWN_CONFIG = function (translateService) { return ({
    service: driver_service_1.DriverService,
    selectText: translateService.instant('Select Driver'),
    loadingText: translateService.instant('Loading Drivers...'),
    noResultsText: translateService.instant('No Drivers'),
    searchable: true,
    sortBy: 'profile__first_name,profile__last_name',
    includeFullObject: true,
}); };
exports.TRUCK_DROPDOWN_CONFIG = function (translateService) { return ({
    service: truck_service_1.TruckService,
    selectText: translateService.instant('Select Truck'),
    loadingText: translateService.instant('Loading Trucks...'),
    noResultsText: translateService.instant('No Trucks'),
    searchable: true,
    nameProperty: 'displayName',
    sortBy: 'name',
    includeFullObject: true,
}); };
