<div class="container" *ngIf="driver && driver.jobEventShares">
  <div *ngFor="let jobEventShare of driver.jobEventShares">
    <ng-container *ngIf="showUnworkedJobs || (!showUnworkedJobs && (driver.punchCards | propertyFilter:'jobEventShare.id':jobEventShare.id).length > 0)">
      <div class="job">
        <div class="job-detail">
          <div class="job-heading">
            <h3>{{ jobEventShare.job }}</h3>
            <ng-container *ngIf="tripTimes[jobEventShare.jobId + jobEventShare.organizationId]">
              <div *ngIf="tripTimes[jobEventShare.jobId + jobEventShare.organizationId].start != 'Invalid Date'" translate>First Trip Start: {{ tripTimes[jobEventShare.jobId + jobEventShare.organizationId].start | date: 'h:mm a' }}</div>
              <div *ngIf="tripTimes[jobEventShare.jobId + jobEventShare.organizationId].end != 'Invalid Date'" translate>Last Trip End: {{ tripTimes[jobEventShare.jobId + jobEventShare.organizationId].end | date: 'h:mm a' }}</div>
              <div *ngIf="tripTimes[jobEventShare.jobId + jobEventShare.organizationId].end == 'Invalid Date'" translate>Last Trip End: Set Missing End Time</div>
              <div class="total-time">{{ tripTimes[jobEventShare.jobId + jobEventShare.organizationId].totalTime }}</div>
            </ng-container>
          </div>

          <div class="invoice-rate">
            <div class="column">
              <div class="value">
                {{ jobEventShare.invoiceRate || 0 | currency : 'USD' : 'symbol' }} /
                <span *ngIf="jobEventShare.invoiceType === 'weight'">{{ jobEventShare.invoiceWeightUnit | titleCase }}</span>
                <span *ngIf="jobEventShare.invoiceType !== 'weight'">{{ jobEventShare.invoiceType | titleCase }}</span>
              </div>
              <div class="label" translate>Invoice Rate</div>
            </div>
            <div class="column edit">
              <div class="edit"><i class="icon-pencil" (click)="openEditShare('invoice', driver, jobEventShare)"></i></div>
            </div>
          </div>

          <div class="haul-rate">
            <div class="column">
              <div class="value">
                {{ jobEventShare.haulRate || 0 | currency : 'USD' : 'symbol' }} /
                <span *ngIf="jobEventShare.haulType === 'weight'">{{ jobEventShare.haulWeightUnit | titleCase }}</span>
                <span *ngIf="jobEventShare.haulType !== 'weight'">{{ jobEventShare.haulType | titleCase }}</span>
              </div>
              <div class="label" translate>Haul Rate</div>
            </div>
            <div class="column edit">
              <div class="edit"><i class="icon-pencil" (click)="openEditShare('haul', driver, jobEventShare)"></i></div>
            </div>
          </div>

          <div class="actions">
            <button class="btn btn-default" (click)="openAddPunchCard(driver, jobEventShare)" translate>
              <i class="icon-plus"></i>
              Add Punch Card
            </button>
            <button 
              *ngIf="(driver?.punchCards | getPunchCardsByStatusAndSharePipe: jobEventShare: 'unapproved') > 0"
              class="btn btn-primary" 
              (click)="approve(jobEventShare)" 
              translate
            >
              Approve All
            </button>
            <button 
              *ngIf="
              ((driver?.punchCards | getPunchCardsByStatusAndSharePipe: jobEventShare: 'unapproved') <= 0) && 
              ((driver?.punchCards | getPunchCardsByStatusAndSharePipe: jobEventShare: 'approved') > 0)" 
              class="btn btn-delete"
              (click)="unapprove(jobEventShare)" 
              translate
            >
              Unapprove All
            </button>
          </div>
        </div>
        <div class="punch-card-container">
          <driver-eod-punch-cards [punchCards]="driver.punchCards" [jobEventShare]="jobEventShare"
                        [invoiceRate]="invoiceRates[i]" [haulRate]="haulRates[i]"
                        [hideApproved]="hideApproved" [showVoided]="showVoided" (updatePunchCardData)="updatePunchCardData($event)"
                        (shouldAuditDecisions)="auditDecisions($event)"></driver-eod-punch-cards>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="punch-card-container add-punch-card">
    <button class="btn btn-default" (click)="openAddPunchCard(driver)" translate>
      <i class="icon-plus"></i>
      Add Punch Card
    </button>
  </div>
</div>
