"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var moment = require("moment");
var shared_1 = require("../../shared");
var shift_service_1 = require("../shift.service");
var EditShiftsDialogComponent = /** @class */ (function () {
    function EditShiftsDialogComponent(dialogRef, dialog, data, shiftService) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.shiftService = shiftService;
        this.attributes = {};
        this.errors = [];
        this.loading = false;
    }
    EditShiftsDialogComponent.prototype.ngOnInit = function () { };
    EditShiftsDialogComponent.prototype.ngOnDestroy = function () {
        if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
            this.shiftsReq.unsubscribe();
        }
    };
    EditShiftsDialogComponent.prototype.submit = function () {
        var _this = this;
        if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
            this.shiftsReq.unsubscribe();
        }
        this.loading = true;
        var saveData = this.data.shifts.map(function (shiftId) {
            return {
                id: shiftId,
                startTime: _this.attributes['startTime'],
                endTime: _this.attributes['endTime'],
                adjustment: _this.attributes['adjustment']
            };
        });
        this.shiftService.bulkUpdate(saveData).subscribe(function (bulkRes) {
            if (bulkRes.errors && bulkRes.errors.length) {
                _this.errors = bulkRes.errors.map(function (e) { return (e.error); });
            }
            else {
                _this.dialogRef.close();
                _this.data.callback(bulkRes);
            }
        }, function (err) {
            _this.errors = shared_1.parseErrors(err);
            _this.loading = false;
        });
    };
    /**
     * Makes an edit to either the startTime or endTime field and applies
     * that edit to all selected shift records
     *
     * @param {string} field The time field we will apply the edit to
     * @param {any} value The edit value for the time adjustment
     */
    EditShiftsDialogComponent.prototype.editTime = function (field, value) {
        if (field.toLowerCase().includes('start')) {
            this.attributes['startTime'] = field.toLowerCase().includes('time') ?
                moment(moment(this.attributes['startTime']).format('YYYY-MM-DD') + " " + value, 'YYYY-MM-DD HH:mm').toISOString() :
                moment(value + " " + moment(this.attributes['startTime']).format('HH:mm'), 'YYYY-MM-DD HH:mm').toISOString();
        }
        else if (field.toLowerCase().includes('end')) {
            this.attributes['endTime'] = field.toLowerCase().includes('time') ?
                moment(moment(this.attributes['endTime']).format('YYYY-MM-DD') + " " + value, 'YYYY-MM-DD HH:mm').toISOString() :
                moment(value + " " + moment(this.attributes['endTime']).format('HH:mm'), 'YYYY-MM-DD HH:mm').toISOString();
        }
    };
    /**
     * Makes an edit to the adjustment field and applies that edit to
     * all selected shift records
     *
     * @param {any} value The edit value for the adjustment
     */
    EditShiftsDialogComponent.prototype.editAdjustment = function (value) {
        this.attributes['adjustment'] = Number(value);
    };
    return EditShiftsDialogComponent;
}());
exports.EditShiftsDialogComponent = EditShiftsDialogComponent;
