<form #saveSurcharge="ngForm">
  <div md-dialog-title class="modal-title">
    <h1 translate>Surcharge</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>

  <div md-dialog-content class="md-dialog-content">
    <div class="form-content">
      <div class="field-group title">
        <label class="required" translate>Surcharge</label>
        <dropdown id="surcharge-dropdown"
                  *ngIf="!surchargeTemplate && !this.model.title"
                  [disabled]="loading"
                  [options]="surchargeDropdownOptions"
                  (onSelect)="onSelect('surcharge', $event)"
                  [config]="surchargeDropdownConfig"
                  #surchargeDropdown
                  title="&ndash;"
                  (onSearch)="dropdownSearch($event, 'surcharge')"
                  (nextPage)="dropdownNextPage($event, 'surcharge')"></dropdown>
        <input required type="text" name="title" *ngIf="surchargeTemplate || this.model.title" [disabled]="loading" [(ngModel)]="model.title" #title="ngModel" />
      </div>
    </div>

    <div class="form-content" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="group date" fxFlex="50">
        <label class="required" translate>Date</label>
        <input type="hidden" name="time" required [(ngModel)]="surchargeDate" />
        <date-range-picker [selectedDates]="[surchargeDate]"
          (dateChanged)="onDateChanged($event)"></date-range-picker>
      </div>
      <div class="group amount" fxFlex="50">
        <div class="modifiers">
          <div class="positive" [ngClass]="{ active: positiveAmount }" (click)="setAmountPositive(true)">+</div>
          <div class="negative" [ngClass]="{ active: !positiveAmount }" (click)="setAmountPositive(false)">&#x2013;</div>
        </div>
        <div>
          <label class="required" translate>Amount</label>
          <input required currencyMask type="text" name="amount" placeholder="" [(ngModel)]="model.amount" #amount="ngModel" [options]="{ align: 'left', allowNegative: true, precision: 2 }" />
        </div>
      </div>
    </div>

    <div class="form-content">
      <div class="field-group description">
        <label translate>Description</label>
        <textarea [(ngModel)]="model.description" name="description"></textarea>
      </div>

      <notification *ngFor="let error of errors">{{error}}</notification>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="saveSurcharge.form.valid && submitSurcharge(); false"
            [disabled]="!saveSurcharge.form.valid || loading"
            [ngClass]="{loading: loading}"
            translate>
      Save Item
    </button>
  </div>
</form>
