import { TranslateService } from '@ngx-translate/core';

export const DISPLAYEDCOLUMNS = [
  'select',
  'name',
  'job-number',
  'project',
  'schedule',
  'customer',
  'material',
  'ordered',
  'order-number',
  'markets',
  'actions',
];

export const AVAILABLECOLUMNS = (translateService: TranslateService) => [
  { key: 'select' },
  {
    key: 'name',
    title: translateService.instant('Job'),
    sortable: true,
    sortBy: 'name',
  },
  { key: 'job-number', title: translateService.instant('Job #') },
  {
    key: 'project',
    title: translateService.instant('Project'),
    sortable: true,
    sortBy: 'project__name',
  },
  {
    key: 'schedule',
    title: translateService.instant('Schedule'),
    sortable: true,
    sortBy: 'start_date',
  },
  {
    key: 'customer',
    title: translateService.instant('Customer'),
    sortable: true,
    sortBy: 'customer__name',
  },
  {
    key: 'material',
    title: translateService.instant('Material'),
    sortable: true,
    sortBy: 'material',
  },
  {
    key: 'phase-code',
    title: translateService.instant('Phase Code'),
    sortable: true,
    sortBy: 'phase_code',
  },
  {
    key: 'ordered',
    title: translateService.instant('Ordered'),
    sortable: true,
    sortBy: 'amount_needed',
  },
  {
    key: 'order-number',
    title: translateService.instant('Order #'),
    sortable: true,
    sortBy: 'order_number',
  },
  {
    key: 'dsorder-id',
    title: translateService.instant('DS Order ID'),
    sortable: true,
    sortBy: 'dsorder_id',
  },
  {
    key: 'slorder-id',
    title: translateService.instant('SL Order ID'),
    sortable: true,
    sortBy: 'slorder_id',
  },
  {
    key: 'markets',
    title: translateService.instant('Markets'),
    sortable: false,
  },
  {
    key: 'actions',
    title: translateService.instant('Actions'),
    sortable: false,
  },
];

export const MENUOPTIONS = (translateService: TranslateService) => [
  {
    name: translateService.instant('Edit'),
    action: 'edit',
    link: true,
    external: false,
    returnTo: '/jobs',
  },
  {
    name: translateService.instant('Clone'),
    action: 'clone',
    link: true,
    external: false,
  },
  {
    name: translateService.instant('Edit Job Days'),
    action: 'edit-days',
    link: false,
  },
  {
    name: translateService.instant('Archive'),
    action: 'archive',
    link: false,
    external: false,
  },
  {
    name: translateService.instant('Unarchive'),
    action: 'unarchive',
    link: false,
    external: false,
  },
  {
    name: translateService.instant('Remove'),
    action: 'remove',
    link: false,
    external: false,
  },
];
