import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../../shared/resource.service';
import { FleetHealthStatsSerializer } from './fleet-health-stats.serializer';
import { FleetHealthStats } from './fleet-health-stats';

@Injectable()
export class FleetHealthStatsService extends ResourceService<FleetHealthStats> {
  constructor(http: HttpClient) {
    super(http, 'fleethealthstats/', new FleetHealthStatsSerializer());
  }
}
