<div class="top-content">
  <div class="header-overview">
    <h1 translate>{{ jobEvent && jobEvent.jobDisplayName }}</h1>
    <div class="shift">
      <h3>{{ jobEvent.shift1StartTimestamp | date: 'MMMM dd' }}</h3>
      <h3>{{ jobEvent.shift1StartTimestamp | date: 'h:mm a' }} - {{ jobEvent.shift1EndTimestamp | date: 'h:mm a' }}</h3>
    </div>
    <div class="material">
      <h2>${{ jobEvent.haulRate | currency:'USD':'symbol':'1.2-4' }} / {{ jobEvent.haulWeightUnit }}</h2>
      <h3>{{ jobEvent.job.material }}</h3>
    </div>
  </div>
</div>
<div class="bottom-content">
  <div class="main-overview">
    <div class="overview-section loading-details">
      <div class="loading">
        <p class="bold" translate>1. LOADING</p>
        <p>{{ jobEvent.job.startLocation.name }}</p>
        <p>{{ jobEvent.job.startLocation.street }}</p>
        <p>{{ jobEvent.job.startLocation.city }}, {{ jobEvent.job.startLocation.state }} {{ jobEvent.job.startLocation.zipcode }}</p>
      </div>
      <div class="distance">
        <div>{{ jobEvent.job.distance | metersToMiles }} mi</div>
      </div>
      <div class="unloading">
        <p class="bold" translate>2. UNLOADING</p>
        <p>{{ jobEvent.job.endLocation.name }}</p>
        <p>{{ jobEvent.job.endLocation.street }}</p>
        <p>{{ jobEvent.job.endLocation.city }}, {{ jobEvent.job.endLocation.state }} {{ jobEvent.job.endLocation.zipcode }}</p>
      </div>
    </div>
    <div class="overview-section job-details">
      <p class="bold" translate>Job Details</p>
      <div>
        <span class="icon-material"></span>
        <p translate>
          Estimated Loads
          <span class="value">{{ jobEvent.estimatedNumberOfLoads }}</span>
        </p>
      </div>
      <div>
        <span class="icon-truck"></span>
        <p translate>
          Trucks
          <span class="value">{{ jobEvent.assignmentsCount }} / {{ jobEvent.numTrucks }}</span>
        </p>
      </div>
      <div class="notes" *ngIf="jobEvent.notes" [ngClass]="{ 'open': notesOpen }" (click)="notesOpen = !notesOpen">
        <span><ng-container *ngIf="!notesOpen">VIEW </ng-container>NOTES</span>
        <p *ngIf="notesOpen">{{ jobEvent.notes }}</p>
      </div>
    </div>
    <div class="overview-section job-requirements">
      <div class="loading-requirements">
        <p class="bold">Loading Requirements</p>
        <ng-container *ngIf="jobEvent.job.checkinOptions.weightConfig != 'hidden' &&
                             jobEvent.job.checkinOptions.loadImageConfig != 'hidden' &&
                             jobEvent.job.checkinOptions.signatureImageConfig != 'hidden' &&
                             jobEvent.job.checkinOptions.ticketImageConfig != 'hidden' &&
                             jobEvent.job.checkinOptions.ticketNumberConfig != 'hidden'">
          <span class="icon-none gray"></span>
          <p class="gray" translate>None</p>
        </ng-container>
        <ng-container>
          <span class="icon-weight"></span>
          <p translate>Log Weight: {{ jobEvent.job.checkinOptions.weightConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-material"></span>
          <p translate>Load Image: {{ jobEvent.job.checkinOptions.loadImageConfig }} </p>
        </ng-container>
        <ng-container>
          <span class="icon-pencil"></span>
          <p translate>Signature Image: {{ jobEvent.job.checkinOptions.signatureImageConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-ticket"></span>
          <p translate>Ticket Image: {{ jobEvent.job.checkinOptions.ticketImageConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-digital-scale"></span>
          <p translate>Ticket Number: {{ jobEvent.job.checkinOptions.ticketNumberConfig }}</p>
        </ng-container>
      </div>
      <div class="unloading-requirements">
        <p class="bold">Unloading Requirements</p>
        <ng-container *ngIf="jobEvent.job.checkoutOptions.weightConfig != 'hidden' &&
                             jobEvent.job.checkoutOptions.loadImageConfig != 'hidden' &&
                             jobEvent.job.checkoutOptions.signatureImageConfig != 'hidden' &&
                             jobEvent.job.checkoutOptions.ticketImageConfig != 'hidden' &&
                             jobEvent.job.checkoutOptions.ticketNumberConfig != 'hidden'">
          <span class="icon-none gray"></span>
          <p class="gray" translate>None</p>
        </ng-container>
        <ng-container>
          <span class="icon-weight"></span>
          <p translate>Log Weight: {{ jobEvent.job.checkoutOptions.weightConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-material"></span>
          <p translate>Load Image: {{ jobEvent.job.checkoutOptions.loadImageConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-pencil"></span>
          <p translate>Signature Image: {{ jobEvent.job.checkoutOptions.signatureImageConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-ticket"></span>
          <p translate>Ticket Image: {{ jobEvent.job.checkoutOptions.ticketImageConfig }}</p>
        </ng-container>
        <ng-container>
          <span class="icon-digital-scale"></span>
          <p translate>Ticket Number: {{ jobEvent.job.checkoutOptions.ticketNumberConfig }}</p>
        </ng-container>
      </div>
      <!-- <div class="call-driver">
        <button translate>DISPATCH TRUCKS</button>
      </div> -->
    </div>
  </div>
</div>
