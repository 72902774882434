"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var lodash_1 = require("lodash");
var app_constants_1 = require("./../../app.constants");
var CollaborationTemplateComponent = /** @class */ (function () {
    function CollaborationTemplateComponent(fb) {
        this.fb = fb;
        this.loading = false;
        this.hasLoadListsEnabled = false;
        this.rateTrackingOptions = [];
        this.unitsOfMeasure = [];
        this.onApplyToAll = new core_1.EventEmitter();
        this.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        this.rateTrackingConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.rateWeightConfig = {
            nameProperty: 'label',
            loadingOptions: false
        };
    }
    CollaborationTemplateComponent.prototype.ngOnInit = function () {
        this.setCollaborationTemplate();
        if (this.unitsOfMeasure !== undefined && this.unitsOfMeasure.length > 0) {
            this.collaborationTemplate.controls['requestedUnit'].setValue(this.unitsOfMeasure.find(function (unit) { return unit.name === 'Trucks'; }) || null);
        }
        this.defaultWeightOption = this.weightOptions.find(function (opt) { return opt.selected; }) || this.weightOptions[0];
    };
    CollaborationTemplateComponent.prototype.ngOnChanges = function (changes) {
        if (changes['unitsOfMeasure'] && changes['unitsOfMeasure'].currentValue.length > 0 && this.collaborationTemplate) {
            this.collaborationTemplate.controls['requestedUnit'].setValue(this.unitsOfMeasure.find(function (unit) { return unit.name === 'Trucks'; }) || null);
        }
    };
    CollaborationTemplateComponent.prototype.setCollaborationTemplate = function () {
        this.collaborationTemplate = this.fb.group({
            requestedAmount: [null, forms_1.Validators.min(1)],
            requestedUnit: [null],
            invoiceType: [null],
            invoiceRate: [null, forms_1.Validators.min(0)],
            invoiceWeightUnit: [null],
            notes: ['']
        });
    };
    CollaborationTemplateComponent.prototype.setFormControlValue = function (control, option) {
        this.collaborationTemplate.controls[control].setValue(option);
        if (control === 'invoiceType' && option.value === 'weight') {
            this.collaborationTemplate.controls.invoiceWeightUnit.setValue(this.defaultWeightOption);
        }
        else if (control === 'invoiceType') {
            this.collaborationTemplate.controls.invoiceWeightUnit.setValue(option);
        }
    };
    CollaborationTemplateComponent.prototype.applyTemplate = function () {
        var template = lodash_1.pickBy(this.collaborationTemplate.value, function (v) { return v !== null && v !== undefined && v !== '' && v['length'] !== 0; });
        this.onApplyToAll.emit(template);
    };
    return CollaborationTemplateComponent;
}());
exports.CollaborationTemplateComponent = CollaborationTemplateComponent;
