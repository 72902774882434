import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { find as _find } from 'lodash';

import { LocationService } from '../locations/location.service';
import { ConnectionService } from '../connections/connection.service';
import { ProjectService } from '../projects/project.service';
import { RuckitDropdownComponent } from '../shared/ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'daily-board-filters-dialog',
  templateUrl: './daily-board-filters-dialog.component.html',
  styleUrls: ['./daily-board-filters-dialog.component.scss']
})
export class DailyBoardFiltersDialogComponent {
  loading = false;
  errors = [];
  locationsConfig = {
    selectText: 'Select Location',
    loadingText: 'Loading Locations...',
    noResultsText: 'No Locations',
    service: LocationService,
  };
  projectsConfig = {
    selectText: 'Select Project',
    loadingText: 'Loading Projects...',
    noResultsText: 'No Projects',
    service: ProjectService
  };
  customersConfig = {
    nameProperty: 'organizationName',
    selectText: 'Select Customer',
    loadingText: 'Loading Customers...',
    noResultsText: 'No Customers',
    idProperty: 'organization.id',
    service: ConnectionService
  };
  callback;
  model = {
    startLocation: null,
    endLocation: null,
    project: null,
    customer: null,
    apexCustomerJob: null,
  };

  @ViewChild('startLocationsDropdown', { static: true }) startLocationsDropdown: RuckitDropdownComponent;
  @ViewChild('endLocationsDropdown', { static: true }) endLocationsDropdown: RuckitDropdownComponent;
  @ViewChild('projectsDropdown', { static: true }) projectsDropdown: RuckitDropdownComponent;
  @ViewChild('customersDropdown', { static: true }) customersDropdown: RuckitDropdownComponent;

  constructor(
    public dialogRef: MatDialogRef<DailyBoardFiltersDialogComponent>
  ) { }

  onSelect(filterType: string, e): void {
    this.model[filterType] = e;
  }

  submit(): void {
    this.callback(this.model);
    this.dialogRef.close();
  }
}
