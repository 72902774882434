"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".context-menu-container{width:270px}.context-menu-row[_ngcontent-%COMP%]{display:flex;cursor:pointer;padding:0 20px;align-items:center}.context-menu-row[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#354053}.context-menu-row[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{font-size:20px;margin-right:15px}.context-menu-row.disabled[_ngcontent-%COMP%]{opacity:.4;cursor:not-allowed}"];
exports.styles = styles;
