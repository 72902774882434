<a (click)="expandWith(true, section.pages, section.url)"
   attr.aria-controls="sidebar-menu-{{section.name}}"
   attr.aria-expanded="{{isOpen}}"
   [ngClass]="{'expanded active': isOpen || hasActiveRoute}">
  <div flex layout="row" class="menu-toggle">
    <i class="{{section.icon}}"></i> {{ section.name | translate }}
    <span flex></span>
  </div>
</a>

<div id="sidebar-menu-{{section.name}}"
     class="menu-toggle-list"
     [ngClass]="{'expanded': isOpen, 'hidden': !isOpen}"
     attr.aria-hidden="{{!isOpen}}">

  <div class="menu-section" *ngFor="let page of section.pages">
    <menu-link [section]="page" *ngIf="page.type === 'link' && !page.hidden"></menu-link>
    <menu-link [section]="page" [external]="true" *ngIf="page.type === 'external-link' && !page.hidden"></menu-link>
  </div>
</div>
