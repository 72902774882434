"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var TripTimesComponent = /** @class */ (function () {
    function TripTimesComponent(router, dialog) {
        this.router = router;
        this.dialog = dialog;
        this.driver = {};
        this.trip = null;
        this.trips = null;
        this.jobEvent = null;
        this.loading = true;
        this.errors = [];
    }
    TripTimesComponent.prototype.ngOnInit = function () {
        if (this.trips) {
            return;
        }
        if (this.trip) {
            this.driver = this.trip.driver;
            this.jobEvent = this.trip.jobEvent && this.trip.jobEvent.id;
            this.trips = [this.trip];
        }
    };
    return TripTimesComponent;
}());
exports.TripTimesComponent = TripTimesComponent;
