<i *ngIf="!searchTerm && searchBarType !== 'map'" class="icon-search" (click)="changeTerm()"></i>
<i *ngIf="searchTerm && searchBarType !== 'map'" class="icon-error" (click)="clearTerm()"></i>
<i *ngIf="searchBarType === 'map'" class="icon-search left" (click)="openSearchBar(true)"></i>
<input #searchInput="ngModel"
       type="text"
       name="search"
       [(ngModel)]="searchTerm"
       (keypress)="onKeyPress($event)"
       (blur)="onBlur()"
       placeholder="{{ placeholder | translate }}"
       class="search"
       id="search-field"
       [ngClass]="{ 'condensed': searchBarType === 'map', 'open': searchOpen }"
       autocomplete="off" />
