"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var lodash_1 = require("lodash");
var invitation_1 = require("./invitation");
var api_service_1 = require("../shared/api.service");
var environment_1 = require("../../environments/environment");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var camelcaseKeysDeep = require('camelcase-keys-deep');
var InvitationService = /** @class */ (function () {
    function InvitationService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
        this.params = new http_1.HttpParams();
    }
    InvitationService.prototype.registerFromInvitation = function (invitationId, invitationKind, params) {
        var _this = this;
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/' + invitationKind + '/';
        var headers = new http_1.HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        params = decamelizeKeysDeep(params);
        return this.http.post(invitationUrl, params, { headers: headers }).pipe(operators_1.map(function (response) {
            if (response) {
                _this.token = response && response.token;
                var email = response && response.email;
                var firstName = response && response.first_name;
                var lastName = response && response.last_name;
                var organization = response && response.organization;
                var id = response && response.id;
                var name_1 = firstName + ' ' + lastName;
                var driver = false;
                if (response && response.driver) {
                    driver = true;
                }
                if (_this.token) {
                    // store user data and JWT token in local storage to persist user session
                    localStorage.setItem('currentUser', JSON.stringify({
                        id: id,
                        username: email,
                        name: name_1,
                        token: _this.token,
                        organization: organization,
                        sidebar: (invitationKind === 'new_org' ? true : false),
                        driver: driver
                    }));
                }
                return true;
            }
            else {
                return false;
            }
        }), operators_1.catchError(function (error) {
            return rxjs_1.throwError(api_service_1.parseErrors(error));
        }));
    };
    InvitationService.prototype.createInvitation = function (params) {
        var invitationUrl = this.baseUrl + 'invitations/';
        params = decamelizeKeysDeep(params);
        return this.http.post(invitationUrl, params, { headers: api_service_1.requestHeaders() });
    };
    InvitationService.prototype.acceptInvitation = function (invitationId) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/accept/';
        return this.http.get(invitationUrl, { headers: api_service_1.requestHeaders() });
    };
    InvitationService.prototype.updateInvitation = function (invitationId, invitationData) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';
        var headers = new http_1.HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        var _invitationData = decamelizeKeysDeep(lodash_1.clone(invitationData));
        return this.http.put(invitationUrl, _invitationData, { headers: headers });
    };
    InvitationService.prototype.rejectInvitation = function (invitationId) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';
        return this.http.put(invitationUrl, { status: 'declined' }, { headers: api_service_1.requestHeaders() });
    };
    InvitationService.prototype.cancelInvitation = function (invitationId) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';
        return this.http.put(invitationUrl, { status: 'cancelled' }, { headers: api_service_1.requestHeaders() });
    };
    InvitationService.prototype.resendInvitation = function (invitationId) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/resend/';
        return this.http.get(invitationUrl, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    InvitationService.prototype.acceptJob = function (params) {
        var invitationUrl = this.baseUrl + 'invitations/' + params.invitation + '/new_job/';
        if (typeof (params.project) === 'object') {
            params.project = params.project.id;
        }
        params = decamelizeKeysDeep(params);
        return this.http.post(invitationUrl, params, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    InvitationService.prototype.rejectJob = function (invitationId) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';
        return this.http.put(invitationUrl, { status: 'declined' }, { headers: api_service_1.requestHeaders() }).pipe(operators_1.map(function (response) {
            if (response) {
                if (response.status === 201 || response.status === 200) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }));
    };
    InvitationService.prototype.getInvitation = function (invitationId) {
        var invitationUrl = this.baseUrl + 'invitations/' + invitationId + '/';
        var headers = new http_1.HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        return this.http.get(invitationUrl, { headers: headers }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    InvitationService.prototype.getInvitations = function (type, organizationId, query, kind) {
        if (query === void 0) { query = null; }
        if (kind === void 0) { kind = 'connection'; }
        var invitationsUrl = this.baseUrl + 'invitations/';
        this.buildSearchQuery(type, organizationId, kind);
        return this.http.get(invitationsUrl, {
            headers: api_service_1.requestHeaders(),
            params: this.params
        }).pipe(operators_1.map(this.extractData), operators_1.catchError(api_service_1.handleError));
    };
    InvitationService.prototype.extractData = function (res) {
        var body = res['results'];
        if (body) {
            return body.map(function (inv) {
                inv = camelcaseKeysDeep(inv);
                return new invitation_1.Invitation(inv);
            });
        }
        else if (res) {
            var inv = camelcaseKeysDeep(res);
            return new invitation_1.Invitation(inv);
        }
        else {
            return [];
        }
    };
    InvitationService.prototype.buildSearchQuery = function (type, organizationId, kind) {
        if (kind === void 0) { kind = 'connection'; }
        this.params = this.params.set('kind', kind);
        this.params = this.params.set('status', 'pending');
        switch (type) {
            case 'received':
                this.params = this.params.set('recipient_organization', organizationId);
                this.params = this.params.delete('sender_organization');
                break;
            case 'sent':
                this.params = this.params.set('sender_organization', organizationId);
                this.params = this.params.delete('recipient_organization');
                break;
        }
    };
    InvitationService.prototype.getNext = function () {
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: api_service_1.requestHeaders() });
        }
        else {
            return null;
        }
    };
    return InvitationService;
}());
exports.InvitationService = InvitationService;
