"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var surcharge_template_service_1 = require("./surcharge-template.service");
var api_service_1 = require("../shared/api.service");
var authentication_service_1 = require("../shared/authentication.service");
var SurchargeTemplateDialogComponent = /** @class */ (function () {
    function SurchargeTemplateDialogComponent(dialogRef, surchargeTemplateService, authenticationService, elementRef) {
        this.dialogRef = dialogRef;
        this.surchargeTemplateService = surchargeTemplateService;
        this.authenticationService = authenticationService;
        this.elementRef = elementRef;
        this.positiveAmount = true;
        this.model = {
            id: null,
            title: null,
            description: null,
            date: null,
            amount: null,
            positive: true,
            kind: 'invoice'
        };
        this.completed = new core_1.EventEmitter();
        this.loading = false;
        this.hasPOS = false;
        this.errors = [];
    }
    SurchargeTemplateDialogComponent.prototype.ngOnInit = function () {
        if (this.model && this.model.amount >= 0) {
            this.positiveAmount = true;
        }
        else {
            this.positiveAmount = false;
        }
        var organization = this.authenticationService.getOrganization();
        this.hasPOS = organization && organization.posEnabled ? true : false;
    };
    SurchargeTemplateDialogComponent.prototype.submitSurchargeTemplate = function () {
        var _this = this;
        this.loading = true;
        this.surchargeTemplateService.save(this.model).subscribe(function (res) {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    SurchargeTemplateDialogComponent.prototype.setAmountPositive = function (positive) {
        if (positive === void 0) { positive = true; }
        this.model.amount = positive ? Math.abs(this.model.amount) : -Math.abs(this.model.amount);
        this.positiveAmount = positive;
    };
    SurchargeTemplateDialogComponent.prototype.amountChange = function () {
        if (this.model.amount < 0) {
            this.positiveAmount = false;
        }
        if (!this.positiveAmount) {
            this.model.amount = -Math.abs(this.model.amount);
        }
    };
    return SurchargeTemplateDialogComponent;
}());
exports.SurchargeTemplateDialogComponent = SurchargeTemplateDialogComponent;
