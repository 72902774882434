import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coordinates'
})
export class CoordinatesPipe implements PipeTransform {
  transform(input: number[]): string {
    const lat = Math.abs(input[1]).toString();
    const lon = Math.abs(input[0]).toString();
    const vDirection = input[1] > 0 ? 'N' : 'S';
    const hDirection = input[0] > 0 ? 'E' : 'W';
    return lat.substring(0, 2) + '&deg;' + lat.substring(3, 5) + '&#39;' + lat.substring(5, 7) + '&quot;' + vDirection + ', ' +
           lon.substring(0, 2) + '&deg;' + lon.substring(3, 5) + '&#39;' + lon.substring(5, 7) + '&quot;' + hDirection;
  }
}
