import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from '../../shared/resource.service';
import { JobEventShareStat } from '../../job-event-stats/job-event-share.stat';
import { JobEventShareStatSerializer } from '../../job-event-stats/job-event-share-stat.serializer';

@Injectable()
export class StatsByCollaboratorService extends ResourceService<JobEventShareStat> {
  constructor(http: HttpClient) {
    super(http, `jobevents/`, new JobEventShareStatSerializer());
  }
}
