"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".timeline-list-container[_ngcontent-%COMP%]{width:265px;height:100%;background-color:#fafafa;border-right:1px solid #e8e8e8}.timeline-list-container[_ngcontent-%COMP%]     .ruckit-tooltip{border-radius:1px;box-shadow:0 1px 5px 0 rgba(0,0,0,.2),0 3px 1px -2px rgba(0,0,0,.12),0 2px 2px 0 rgba(0,0,0,.14)!important;background-color:#fafafa;color:#4a4a4a!important;padding:10px;font-size:14px;white-space:pre-line}.timeline-list-header[_ngcontent-%COMP%]{position:-webkit-sticky;position:sticky;top:0;background-color:#fafafa;height:47px;border-bottom:1px solid #e8e8e8;display:flex;padding:14px 30px}.row-header[_ngcontent-%COMP%]{height:115px;border-bottom:1px solid #e8e8e8;display:flex;align-items:center;padding:30px;cursor:pointer}.row-header.selected[_ngcontent-%COMP%]{background-color:#efefef}p[_ngcontent-%COMP%]{margin:0}p.description[_ngcontent-%COMP%]{font-weight:500}.replay-link[_ngcontent-%COMP%]{text-decoration:none;font-size:12px;color:#015bc5}.selector[_ngcontent-%COMP%]{margin-right:12px}.details[_ngcontent-%COMP%]{display:flex;justify-content:space-between;align-items:center;width:100%}.details[_ngcontent-%COMP%]   .text[_ngcontent-%COMP%]{max-width:150px}.details[_ngcontent-%COMP%]   .info[_ngcontent-%COMP%]{width:24px;height:24px;margin-right:-18px}.details[_ngcontent-%COMP%]   .info[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#015bc5;font-size:18px}"];
exports.styles = styles;
