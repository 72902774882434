<div class="app-content" [ngClass]="{
  'mobile': device.mobile,
  'tablet': device.tablet,
  'desktop': device.desktop
}">
  <my-toolbar class="mobile" title="{{ jobEvent && jobEvent.jobDisplayName }}"></my-toolbar>
  <my-loader *ngIf="loading"></my-loader>
  <div class="container">
    <p class="light" *ngIf="lastUpdated" translate>Last Updated {{ lastUpdated | moment: 'h:mm:ss a' }}</p>
    <div class="info ticket-list">
      <div class="header">
        <h2 translate>Tickets Created</h2>
        <h1>{{ tickets.length }}</h1>
      </div>
      <div *ngFor="let ticket of tickets" class="list-item" (click)="openTicketDetails(ticket)">
        <div class="image-preview">
          <img *ngIf="ticket.unloadingTicketImage" [src]="ticket.unloadingTicketImage">
          <img *ngIf="!ticket.unloadingTicketImage && ticket.loadingTicketImage" [src]="ticket.loadingTicketImage">
          <i *ngIf="!ticket.loadingTicketImage && !ticket.unloadingTicketImage" class="icon-no-results"></i>
        </div>
        <div class="details">
          <p class="color">
            <span *ngIf="ticket.loadingTicketNumber">#{{ ticket.loadingTicketNumber }}</span>
            <span *ngIf="ticket.loadingTicketNumber && ticket.unloadingTicketNumber">, </span>
            <span *ngIf="ticket.unloadingTicketNumber">#{{ticket.unloadingTicketNumber}}</span>
            <span *ngIf="!ticket.loadingTicketNumber && !ticket.unloadingTicketNumber">-</span>
          </p>
          <p class="light" translate>Truck - {{ ticket.truckName }}</p>
        </div>
        
        <div class="stat">
          <p>{{ ticket.loadingCompleteDatetime | moment: 'hh:mm a' }}</p>
          <p class="{{ ticket.reviewedStatus }}">{{ ticket.reviewedStatus | translate | titlecase }}</p>
          <p class="light">{{ orderService.getLoadStatus(ticket.tripStatus) | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
