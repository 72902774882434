import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { MessageService } from './message.service';

@Component({
  selector: 'message-drivers-dialog',
  templateUrl: './message-drivers-dialog.component.html',
  styleUrls: ['./message-drivers-dialog.component.scss'],
  providers: [MessageService]
})
export class MessageDriversDialogComponent implements OnInit {
  @Input() jobEventId: string;

  loading = false;
  message = {
    msg: null,
    jobEventId: null
  };
  messageSent = false;
  buttonLabel = 'Send Message';
  errors = [];
  callback;
  @ViewChild('messageDrivers', { static: false }) messageDrivers;

  constructor(
    public dialogRef: MatDialogRef<MessageDriversDialogComponent>,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.message.jobEventId = this.jobEventId;
  }

  submit() {
    this.loading = true;
    this.messageService.send(this.message).subscribe(result => {
      this.loading = false;
      this.messageSent = true;
      this.buttonLabel = 'Message Sent!';
      setTimeout(() => {
        this.dialogRef.close();
        this.callback();
      }, 500);
    }, (err) => {
      this.loading = false;
      this.errors = ['Message could not be sent.'];
    });
  }
}
