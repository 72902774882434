<form #extendCollaboration="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add/Remove Job Days</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors"><notification *ngFor="let error of errors">{{error}}</notification></div>

    <div class="content">
      <div *ngIf="collaborator" class="collaborator-days">
        <div class="form-content">
          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group" fxFlex="100">
              <input required type="hidden" name="job_events" [(ngModel)]="model.jobevents" />
              <dropdown *ngIf="jobEvent"
                  [config]="jobEventsDropdownConfig"
                  title="{{ loading ? 'Loading Jobs...' : 'Select Jobs' }}"
                  [disabled]="!model || !collaborator || loading"
                  #jobEventsDropdown
                  [options]="jobEvents"
                  (onSelect)="selectJobEvent($event)"
                  (nextPage)="dropdownNextPage($event)"
                  class="job-events-dropdown"></dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" translate
            (click)="extendCollaboration.form.valid && submit(); false"
            [disabled]="!extendCollaboration.form.valid || loading"
            [ngClass]="{loading:loading}">
      Save Schedule
    </button>
  </div>
</form>
