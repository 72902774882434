import { Driver, DriverShift } from '../drivers/driver';
import { Organization } from '../organizations/organization';
import { Trip } from '../trips/trip';
import { TripStats } from '../trips/trip-stats';
import { PunchCard } from '../punch-cards/punch-card';
import { Truck } from '../trucks/truck';
import { JobEvent } from '../job-events/job-event';
import { Job } from '../jobs/job';
import { Location } from '../locations/location';
import { JobEventShare } from '../job-event-shares/job-event-share';

export class Assignment {
  id: string;
  kind: string;
  truck: Truck;
  driver: Driver;
  contactOrganization: Organization;
  jobevent: JobEvent;
  jobeventshare: JobEventShare;
  job: Job;
  jobDisplayName: string;
  shift: string;
  shifts: DriverShift[];
  dispatched: boolean;
  dispatchedTime: string;
  estimatedNumberOfLoads: string;
  estimatedNumberOfTons: string;
  trips: Trip[];
  predictedTrips: Trip[];
  punchCards: PunchCard[];
  tripStats: TripStats;
  durationTimer;
  driverConfirmed: boolean;
  driverRejectionNote: string;
  uniqueStart: string;
  uniqueStartDate: Date;
  uniqueStartTime: string;
  maxNumberOfLoads: number;
  numberOfLoadsType: string;
  numberOfLoadsLeft: number;
  completed: boolean;
  driverStatus: string;
  assignedEstimate;
  confirmed: any;
  unconfirmed: any;
  requiresConfirmation: any;
  waiting: boolean;
  tripStatus: any;
  tripStatusTime: any;
  loading: boolean;
  selected: boolean;
  classes: string;
  expandableTrips: boolean;
  expandablePredictedTrips: boolean;
  details: any;
  carrierUniqueBillingId: string;
  connexCreatedAt: any;
  connexLastSentAt: any;

  yardLocation: Location;
  yardStartTime: Date;
  yardLeaveTime: Date;
  yardPrepTime: number;
  yardBufferTime: number;
  yardBufferMinutes: number;
  notes: string;
  pavers: any[];
  requestedAmount: number;
  requestedUnit: any;
}
