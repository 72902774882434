"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".app-content[_ngcontent-%COMP%]{display:flex;flex-direction:column}i[_ngcontent-%COMP%]{color:rgba(0,0,0,.25)}i.active[_ngcontent-%COMP%]{color:#27af5e}.actions-wrapper[_ngcontent-%COMP%]{position:relative;padding:30px}.actions-wrapper[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{position:absolute;top:30px;right:40px;height:57px;display:flex;align-items:center}.actions-wrapper[_ngcontent-%COMP%]     .mat-cell, .actions-wrapper[_ngcontent-%COMP%]     .mat-header-cell{min-width:80px}.actions-wrapper[_ngcontent-%COMP%]     .mat-column-driver, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-job{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;min-width:170px;max-width:170px}.actions-wrapper[_ngcontent-%COMP%]     .mat-column-driver .value, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-job .value{width:100%}.actions-wrapper[_ngcontent-%COMP%]     .mat-column-company, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-customer, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-customer-address{padding-right:10px;min-width:200px}.actions-wrapper[_ngcontent-%COMP%]     .mat-column-average-round-trip-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-clock-in-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-deficient-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-first-load-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-freight-bill-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-last-load-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-loading-wait-time, .actions-wrapper[_ngcontent-%COMP%]     .mat-column-unloading-wait-time{min-width:140px}.actions-wrapper[_ngcontent-%COMP%]     .titleize{text-transform:capitalize}.driver-job-reports[_ngcontent-%COMP%]     .action-bar-content{min-height:72px!important;height:72px!important}.driver-job-reports[_ngcontent-%COMP%]     .fancy-table-wrapper{height:calc(100vh - 200px)!important}"];
exports.styles = styles;
