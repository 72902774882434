"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".job-stats-container[_ngcontent-%COMP%]{padding:30px;height:153px;overflow-y:hidden}.job-stats-container[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#58b4f9;text-decoration:none}.job-stats-container[_ngcontent-%COMP%]   .currency[_ngcontent-%COMP%], .job-stats-container[_ngcontent-%COMP%]   .numeric[_ngcontent-%COMP%]{margin:0 auto}.job-stats-container[_ngcontent-%COMP%]     .titleize{text-transform:capitalize}.job-stats-container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]{height:calc(100vh - 144px - 60px - 30px - 30px)}"];
exports.styles = styles;
