"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var marketplace_listings_component_1 = require("./marketplace-listings.component");
var job_overview_component_1 = require("./job-overview.component");
var marketplace_accept_job_component_1 = require("./marketplace-accept-job.component");
var routes = [
    {
        path: '',
        component: marketplace_listings_component_1.MarketplaceListingsComponent,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: ':id',
        component: job_overview_component_1.JobOverviewComponent,
        canActivate: [auth_guard_1.AuthGuard]
    },
    {
        path: ':id/accept',
        component: marketplace_accept_job_component_1.MarketplaceAcceptJobComponent,
        canActivate: [auth_guard_1.AuthGuard]
    }
];
var MarketplaceRoutingModule = /** @class */ (function () {
    function MarketplaceRoutingModule() {
    }
    return MarketplaceRoutingModule;
}());
exports.MarketplaceRoutingModule = MarketplaceRoutingModule;
