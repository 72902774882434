"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var moment = require("moment");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var resource_service_1 = require("../shared/resource.service");
var pay_sheet_serializer_1 = require("./pay-sheet.serializer");
var pay_record_serializer_1 = require("./pay-record.serializer");
var lodash_1 = require("lodash");
var shared_1 = require("../shared");
var PaySheetService = /** @class */ (function (_super) {
    __extends(PaySheetService, _super);
    function PaySheetService(http, authenticationService) {
        var _this = _super.call(this, http, 'driver-pay/pay-sheets/', new pay_sheet_serializer_1.PaySheetSerializer()) || this;
        _this.http = http;
        _this.authenticationService = authenticationService;
        return _this;
    }
    PaySheetService.prototype.listReportTable = function (query) {
        var _this = this;
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
            if (query.filters) {
                var joinedFilters = this.mergeFilters(query.filters);
                params = params.set('filters', joinedFilters);
            }
        }
        if (this.slug) {
            this.resourceUrl = this.baseUrl + this.slug;
        }
        else if (this.endpoint.includes('LOCAL:')) {
            // Do not manipulate the URL
        }
        else {
            this.resourceUrl = this.baseUrl + this.endpoint;
        }
        return this.http.get(this.resourceUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.captureReportTableMetaData(res, params); }), operators_1.map(function (data) { return _this.filterLocally(data, params); }), operators_1.map(function (data) { return _this.paginateLocally(data, params); }), operators_1.map(function (data) { return _this.convertData(data); }), operators_1.map(function (data) { return _this.generateReportTableRows(data, params); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    PaySheetService.prototype.generateReportTableRows = function (reports, query) {
        var tableRecords = [];
        var reportKindFilter = query.get('carrier_type');
        reports.forEach(function (report) {
            if (reportKindFilter !== 'external') {
                tableRecords.push(__assign({}, report, { type: 'internal' }));
            }
            if (reportKindFilter !== 'internal') {
                tableRecords.push(__assign({}, report, { type: 'external' }));
            }
        });
        return tableRecords;
    };
    PaySheetService.prototype.getReportRecords = function (reportId, query) {
        var _this = this;
        var params = new http_1.HttpParams();
        params = params.set('pay_sheet', reportId);
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var resourceUrl;
        if (this.slug) {
            resourceUrl = this.baseUrl + this.slug;
        }
        else if (this.endpoint.includes('LOCAL:')) {
            // Do not manipulate the URL
        }
        else {
            resourceUrl = this.baseUrl + 'driver-pay/pay-records/';
        }
        return this.http.get(resourceUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.captureMetaData(res); }), operators_1.map(function (res) { return (res && res.map(function (item) { return new pay_record_serializer_1.PayRecordSerializer().fromJson(item); })); }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    PaySheetService.prototype.getAllReportRecords = function (reportId, pageSize, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 50; }
        var params = new http_1.HttpParams();
        params = params.set('pay_sheet', reportId);
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var resourceUrl;
        if (this.slug) {
            resourceUrl = this.baseUrl + this.slug;
        }
        else if (this.endpoint.includes('LOCAL:')) {
            // Do not manipulate the URL
        }
        else {
            resourceUrl = this.baseUrl + 'driver-pay/pay-records/';
        }
        var requestCount = 0;
        this.listAllProgress.next(0);
        return this.http.get(resourceUrl, {
            headers: this.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return (_this.captureMetaData(res)); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(_this.baseUrl + 'driver-pay/pay-records/', {
                    headers: _this.requestHeaders(),
                    params: params
                }).map(function (res) {
                    _this.listAllProgress.next(1);
                    return _this.captureMetaData(res);
                }));
            }
            else {
                _this.listAllProgress.next(1 / Math.ceil(_this.count / pageSize));
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(_this.baseUrl + 'driver-pay/pay-records/', {
                        headers: _this.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        requestCount++;
                        _this.listAllProgress.next(requestCount / Math.ceil(_this.count / pageSize));
                        return res;
                    }), operators_1.map(function (res) { return _this.captureMetaData(res); })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return mergedList.map(function (item) { return new pay_record_serializer_1.PayRecordSerializer().fromJson(item); });
        }), operators_1.catchError(function (res) { return _this.handleError(res); }));
    };
    PaySheetService.prototype.convertToDuration = function (startDateTime, endDateTime) {
        var startMoment = moment(startDateTime);
        var endMoment = moment(endDateTime);
        return {
            date: startMoment.format('YYYY-MM-DD'),
            startTime: startMoment.format('HH:mm'),
            endTime: endMoment.format('HH:mm'),
            duration: moment(endDateTime).diff(startDateTime, 'minutes')
        };
    };
    PaySheetService.prototype.getDurationString = function (startTime, endTime) {
        var payLineTotal = this.convertToDuration(startTime, endTime);
        return (startTime && endTime) ? Math.floor(payLineTotal.duration / 60) + 'h' + (payLineTotal.duration % 60) + 'm' : '-h--m';
    };
    PaySheetService.prototype.getPayTotalDurationString = function (record) {
        var minuteTotal = 0;
        record.data.rowData.payLines.forEach(function (line) {
            minuteTotal += moment(line.endDatetime).diff(line.startDatetime, 'minutes');
        });
        minuteTotal += record.data.rowData.payAdjustmentTotal;
        return Math.floor(minuteTotal / 60) + 'h' + (minuteTotal % 60) + 'm';
    };
    PaySheetService.prototype.createReport = function (payBasis, id, reportRange) {
        var _this = this;
        var resourceUrl = this.baseUrl + 'driver-pay/pay-sheets/';
        return this.http.post("" + resourceUrl + id + "/" + payBasis + "/", reportRange, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
    };
    PaySheetService.prototype.saveReport = function (payBasis, id, reportRange) {
        var _this = this;
        var resourceUrl = this.baseUrl + 'driver-pay/pay-sheets/';
        return this.http.put("" + resourceUrl + id + "/" + payBasis + "/", reportRange, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
    };
    PaySheetService.prototype.saveRecord = function (recordId, data) {
        var saveData = data.data && data.data.rowData && decamelizeKeysDeep({
            payBasis: data.data.rowData.payBasis,
            payLines: data.data.rowData.payLines,
            payAdjustmentTotal: data.data.rowData.payAdjustmentTotal || 0,
            notes: data.data.rowData.notes
        });
        var resourceUrl = this.baseUrl + "driver-pay/pay-records/" + recordId + "/save-pay-lines/";
        return this.http.post(resourceUrl, saveData, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return res; }));
    };
    PaySheetService.prototype.approveReport = function (date) {
        var _this = this;
        var resourceUrl = this.baseUrl + 'driver-pay/pay-sheets/';
        return this.http.post("" + resourceUrl + date + "/approve/", null, {
            headers: this.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.convertRecord(res); }));
    };
    PaySheetService.prototype.captureReportTableMetaData = function (res, query) {
        var json = res;
        this.nextUri = json['next'];
        this.previousUri = json['previous'];
        this.count = (query.get('carrier_type') || query.get('carrier_type') !== 'all') ?
            (json['count'] || json['results'] && json['results'].length) :
            (json['count'] || json['results'] && json['results'].length) * 2;
        this.unreadCount = json['unread_count'] || 0;
        this.mockEndpoint = json['mock'];
        this.mockSearchKeys = json['mockSearchKeys'];
        this.metaData = json['meta'];
        return json.results || json;
    };
    PaySheetService.prototype.formatExport = function (exportData, exportType) {
        var _this = this;
        var organization = this.authenticationService.getOrganization();
        var data = lodash_1.cloneDeep(exportData);
        return data.map(function (row) {
            var payDuration = 0;
            if (row.data && row.data.rowData) {
                if (row.data.rowData.payLines && row.data.rowData.payLines.length) {
                    row.data.rowData.payLines.forEach(function (line, i) {
                        payDuration += _this.convertToDuration(row.data.rowData.payLines[i].startDatetime, row.data.rowData.payLines[i].endDatetime).duration;
                    });
                }
                if (row.data.rowData.payAdjustmentTotal) {
                    payDuration += row.data.rowData.payAdjustmentTotal;
                }
                payDuration = payDuration / 60;
            }
            switch (exportType) {
                case 'raw':
                    return Object.assign.apply(Object, [{}].concat(function flatten(o) {
                        return o ? [].concat.apply([], Object.keys(o).map(function (key) {
                            var _a;
                            return typeof o[key] === 'object' ? flatten(o[key]) : (_a = {}, _a[key] = o[key], _a);
                        })) : {};
                    }(row)));
                case 'hiredHauler':
                    return {
                        'Company #': organization.viewpointCompanyNumber,
                        'Financial Month': '',
                        'SaleDate': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.shift1StartTimestamp &&
                            moment(row.data.assignment.jobevent.shift1StartTimestamp).format('M/D/YYYY'),
                        'FromLoc': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.startLocation &&
                            row.data.assignment.jobevent.job.startLocation.name &&
                            row.data.assignment.jobevent.job.startLocation.name.split(' - ')[0],
                        'Ticket #': '',
                        'Matl Vendor': '',
                        'Sale Type': 'J',
                        'JCCo': organization.viewpointJcco,
                        'Job': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.jobNumber,
                        'Customer': '',
                        'Cust Job': '',
                        'Cust PO': '',
                        'Paymt Type': '',
                        'INCo': '',
                        'To Loc': '',
                        'Material': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.material &&
                            row.data.assignment.jobevent.job.material.split(' - ')[0].replace(/"/g, '""'),
                        'UM': '',
                        'Matl Phase': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.poNumber,
                        'Matl CT': 21,
                        'Hauler Type': 'H',
                        'Hauler Vendor': row.data &&
                            row.data.assignment.carrierUniqueBillingId,
                        'Truck': row.data &&
                            row.data.assignment &&
                            row.data.assignment.truck &&
                            row.data.assignment.truck.name,
                        'Driver': row.data &&
                            row.data.assignment &&
                            row.data.assignment.driver &&
                            row.data.assignment.driver.name,
                        'Truck Type': row.data &&
                            row.data.assignment &&
                            row.data.assignment.truck &&
                            row.data.assignment.truck.truckTypeName,
                        'Start Time': row.data &&
                            row.data.rowData &&
                            row.data.rowData.payLines &&
                            row.data.rowData.payLines[0] &&
                            moment(row.data.rowData.payLines[0].startDatetime).format('h:mm A'),
                        'Stop Time': row.data &&
                            row.data.rowData &&
                            row.data.rowData.payLines &&
                            row.data.rowData.payLines[row.data.rowData.payLines.length - 1] &&
                            moment(row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime).format('h:mm A'),
                        'Loads': row.data &&
                            row.data.rowData &&
                            row.data.rowData.trips &&
                            row.data.rowData.trips.length,
                        'Miles': '',
                        'Hours': '',
                        'Zone': '',
                        'Gross': 0,
                        'Tare': 0,
                        'Haul Code': 21,
                        'Haul Phase': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.poNumber,
                        'Haul CT': 21,
                        'Units Sold': 0,
                        'Unit Price': 0,
                        'ECM': 'E',
                        'Material Total': 0,
                        'Haul Basis': payDuration.toFixed(2),
                        'Haul Rate': '',
                        'Haul Charge': '',
                        'Pay Code': 20,
                        'Pay Basis': payDuration.toFixed(2),
                        'Pay Rate': '',
                        'Pay Total': '',
                        'Tax Type': 1,
                        'Tax Code': 'TX0000',
                        'Tax Basis': payDuration.toFixed(2),
                        'Tax Total': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.rate &&
                            (payDuration * Number(row.data.assignment.jobevent.rate)).toFixed(2),
                    };
                case 'payroll': // PR format
                    return {
                        'Payroll Company': organization.viewpointJcco,
                        'Employee Number': row.data &&
                            row.data.assignment &&
                            row.data.assignment.driver &&
                            row.data.assignment.driver.uniqueBillingId,
                        'Posting Date': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.shift1StartTimestamp &&
                            moment(row.data.assignment.jobevent.shift1StartTimestamp).format('MM/DD/YY'),
                        'Job Cost Company': organization.viewpointJcco,
                        'Job': row.data.assignment.jobevent.job.jobNumber,
                        'Phase': row.data.assignment.jobevent.job.poNumber,
                        'EM Company': organization.viewpointJcco,
                        'Equipment': row.data &&
                            row.data.assignment &&
                            row.data.assignment.truck &&
                            row.data.assignment.truck.name,
                        'Cost Code': '80.450.',
                        'Hours': payDuration.toFixed(2)
                    };
                case 'equipment': // EM format
                    return {
                        'Company': organization.viewpointCompanyNumber,
                        'Sale Type': 'J',
                        'Truck': row.data &&
                            row.data.assignment &&
                            row.data.assignment.truck &&
                            row.data.assignment.truck.name,
                        'JCCo': organization.viewpointJcco,
                        'Job': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.jobNumber,
                        'Matl Phase': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.poNumber,
                        'Actual Date': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.shift1StartTimestamp &&
                            moment(row.data.assignment.jobevent.shift1StartTimestamp).format('MM/DD/YY'),
                        'Revenue Time Units': payDuration.toFixed(2)
                    };
                case 'hourlyTicket':
                    return {
                        'Hourly Ticket #': row.ticketNumber,
                        'Hauler Name': row.data &&
                            row.data.assignment &&
                            row.data.assignment.driver &&
                            row.data.assignment.driver.carrier &&
                            row.data.assignment.driver.carrier.name,
                        'Truck #': row.data &&
                            row.data.assignment &&
                            row.data.assignment.truck &&
                            row.data.assignment.truck.name,
                        'Customer Name And #': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.project &&
                            row.data.assignment.jobevent.job.project.customerOrganization &&
                            row.data.assignment.jobevent.job.project.customerOrganization.name,
                        'Date': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.shift1StartTimestamp &&
                            moment(row.data.assignment.jobevent.shift1StartTimestamp).format('MM/DD/YY'),
                        'Job Name': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.name,
                        'Job Number': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.jobNumber,
                        'Product': '',
                        'Delivery Method': '',
                        'Location': '',
                        'Phase Code': row.data &&
                            row.data.assignment &&
                            row.data.assignment.jobevent &&
                            row.data.assignment.jobevent.job &&
                            row.data.assignment.jobevent.job.poNumber,
                        'Start Time': row.data &&
                            row.data.rowData &&
                            row.data.rowData.payLines &&
                            row.data.rowData.payLines[0] &&
                            moment(row.data.rowData.payLines[0].startDatetime).format('h:mm A'),
                        'Finished Time': row.data &&
                            row.data.rowData &&
                            row.data.rowData.payLines &&
                            row.data.rowData.payLines[row.data.rowData.payLines.length - 1] &&
                            moment(row.data.rowData.payLines[row.data.rowData.payLines.length - 1].endDatetime).format('h:mm A'),
                        'Adjusted Time': row.data &&
                            row.data.rowData &&
                            row.data.rowData.payAdjustmentTotal,
                        'Total Time': payDuration.toFixed(2),
                        'Load Count': row.data &&
                            row.data.rowData &&
                            row.data.rowData.trips &&
                            row.data.rowData.trips.length,
                        'Freight': '',
                        'Freight Pay': ''
                    };
            }
        });
    };
    PaySheetService.prototype.parsePayRecordErrors = function (err) {
        var errors = [];
        if (err.status >= 500) {
            errors.push(err.statusText);
        }
        else if (err.error) {
            Object.keys(err.error).forEach(function (key) {
                if (Array.isArray(err.error[key])) {
                    errors = errors.concat(err.error[key].map(function (details) { return (key.replace(/_/g, ' ') + ': ') + JSON.stringify(details); }));
                }
            });
        }
        else {
            errors.push(err);
        }
        return errors;
    };
    return PaySheetService;
}(resource_service_1.ResourceService));
exports.PaySheetService = PaySheetService;
