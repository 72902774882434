import { clone } from 'lodash';
import { Resource } from '../../shared/resource';

export class BillingRate extends Resource {
  name: string;
  rateGroup: string;
  code: string;
  description: string;
  rateType: string;
  unit: string;
  rate: number;

  url(action): string {
    return '/billing-rates/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    return options;
  }
}
