"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DriverMetadataSerializer = /** @class */ (function () {
    function DriverMetadataSerializer() {
    }
    DriverMetadataSerializer.prototype.fromJson = function (json) {
        return json;
    };
    DriverMetadataSerializer.prototype.toJson = function (obj) {
        return obj;
    };
    return DriverMetadataSerializer;
}());
exports.DriverMetadataSerializer = DriverMetadataSerializer;
