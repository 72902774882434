import * as moment from 'moment-timezone';
import { remove, find, clone } from 'lodash';

import { Driver } from '../drivers/driver';
import { Checkin } from '../checkins/checkin';
import { User } from '../users/user';
import { Resource } from '../shared/resource';
import { Attachment } from '../attachments/attachment.interface';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class RuckitCondensedTrip extends Resource {
  id: string;
  name: string;
  date: string;
  jobDate: string;
  job: string;
  jobDisplayName: string;
  jobId: string;
  jobNumber: string;
  jobEventId: string;
  phaseCode: string;
  project: string;
  projectId: string;
  customer: string;
  customerId: string;
  customerPhone: string;
  driver: string;
  driverId: string;
  driverPhone: string;
  driverImage: string;
  customDriver: Driver;
  truck: string;
  truckId: string;
  truckType: string;
  truckImage: string;
  truckName: string;
  payableTo: string;
  payableToId: string;
  carrier: string;
  carrierId: string;
  payableToPhone: string;
  loadingTicketNumber: string;
  loadingWeight: string;
  loadingTicketImage: string;
  loadingTicketImageKey: string;
  loadingSignature: string;
  loadingSignatureKey: string;
  unloadingTicketNumber: string;
  unloadingWeight: string;
  unloadingTicketImage: string;
  unloadingTicketImageKey: string;
  unloadingSignature: string;
  unloadingSignatureKey: string;
  _startTimeTimestamp: string;
  _endTimeTimestamp: string;
  startTimeTimestamp: string;
  endTimeTimestamp: string;
  invoiceRate: string;
  invoiceType: string;
  invoiceWeightUnit: string;
  invoiceQuantity: string;
  invoiceTitle: string;
  haulRate: string;
  haulType: string;
  haulWeightUnit: string;
  routePolyline: string;
  mapImageURL: string;
  fullsizeMapImageURL: string;
  checkinOptions: any;
  checkoutOptions: any;
  retakeStatus: string;
  edited: boolean;
  completed: boolean;
  tripStatus: string;
  reviewedStatus: 'approved' | 'rejected' | 'pending';
  reviewedBy: User;
  reviewedAt: string;
  selected = false;
  invoiceTotal: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceSent = false;
  haulTotal: string;
  loading = false;
  void: boolean;
  expenseId: string;
  expenseNumber: string;
  expenseSent = false;
  expenseTotal: string;
  expenseTitle: string;
  paystubId: string;
  paystubNumber: string;
  paystubSent = false;
  paystubTitle: string;
  weight: string;
  weightRequired: boolean;
  ticketNumber: string;
  timezone: string;
  origin: string;
  loadingCheckin: Checkin;
  unloadingCheckin: Checkin;
  haulWeightUnitDisplay: string;
  invoiceWeightUnitDisplay: string;
  haulRateUnit: string;
  invoiceRateUnit: string;
  haulTypeName: string;
  invoiceTypeName: string;
  ticketNumbers: string;
  retakeRequested: boolean;
  administrativeStatus: string;
  displayableStatus: boolean;

  loadingArrivalDatetime: string;
  loadingCompleteDatetime: string;
  unloadingArrivalDatetime: string;
  unloadingCompleteDatetime: string;

  connexPaverStartUnloading: string;
  connexPaverEndUnloading: string;

  startLocationId: string;
  startLocationName: string;
  endLocationId: string;
  endLocationName: string;
  geoStartLocationId: string;
  geoStartLocationName: string;
  geoEndLocationId: string;
  geoEndLocationName: string;

  duration: moment.Duration;
  completedTripTime: string;
  startTime: string;
  endTime: string;
  startDate: Date;
  endDate: Date;
  // loadingCompleteDatetime;
  // unloadingCompleteDatetime;
  // loadingArrivalDatetime;
  // unloadingArrivalDatetime;

  signatureImagesRequired: boolean;
  hasSignatureImages: boolean;
  loadingSignatureImagesRequired: boolean;
  hasLoadingSignatureImages: boolean;
  unloadingSignatureImagesRequired: boolean;
  hasUnloadingSignatureImages: boolean;
  ticketImagesRequired: boolean;
  hasTicketImages: boolean;
  loadingTicketImagesRequired: boolean;
  hasLoadingTicketImages: boolean;
  unloadingTicketImagesRequired: boolean;
  hasUnloadingTicketImages: boolean;
  hasBothTickets: boolean;

  loadingTicket: string;
  unloadingTicket: string;

  loadingAttachments: Attachment[];
  unloadingAttachments: Attachment[];

  inlineLoadingSignature: string;
  inlineLoadingTicket: string;
  inlineUnloadingSignature: string;
  inlineUnloadingTicket: string;

  // pre-formatted fields for display
  formattedDuration: number;
  formattedCompletedTripTime: string;
  formattedStartTime: string;
  formattedEndTime: string;
  formattedStartDate: Date;
  formattedEndDate: Date;
  formattedLoadingCompleteDatetime;
  formattedUnloadingCompleteDatetime;
  formattedLoadingArrivalDatetime;
  formattedUnloadingArrivalDatetime;

  // localized date times
  localizedStartTime: string;
  localizedStartDatetime: string;
  localizedStartDate: string;
  localizedEndTime: string;
  localizedEndDatetime: string;
  localizedEndDate: string;

  schedule: string;

  // qc/vt fields
  plantQc: number;
  plantQcReviewer: User;
  plantQcWasteAmt: number;
  plantVt: number;
  plantVtReviewer: User;
  plantVtWasteAmt: number;
  roadwayQc: number;
  roadwayQcReviewer: User;
  roadwayQcWasteAmt: number;
  roadwayVt: number;
  roadwayVtReviewer: User;
  roadwayVtWasteAmt: number;

  updateStatus(): void {
    this.edited = true;
    if (this.void) {
      this.administrativeStatus = 'void';
    } else if (this.retakeStatus === 'requested') {
      this.administrativeStatus = 'retake';
    } else if (this.edited) {
      this.administrativeStatus = 'edited';
    } else {
      this.administrativeStatus = '';
    }
    this.displayableStatus =
      this.administrativeStatus && this.administrativeStatus.length
        ? true
        : false;
  }

  url(action): string {
    return '/trips/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.void) {
      let _option = find(options, { action: 'void' });
      if (_option) {
        remove(options, _option);
      }
    }

    if (!this.void) {
      let _option = find(options, { action: 'unvoid' });
      if (_option) {
        remove(options, _option);
      }
    }

    return options;
  }

  pluralizedInvoiceRateUnit(plural = true): string {
    if (this.invoiceType === 'weight') {
      switch (this.invoiceWeightUnit) {
        case 'cuyds':
          return plural ? 'cu. yds.' : 'cu. yd.';
        default:
          return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
      }
    } else {
      return this.invoiceType;
    }
  }

  pluralizedHaulRateUnit(plural = true): string {
    if (this.haulType === 'weight') {
      switch (this.haulWeightUnit) {
        case 'cuyds':
          return plural ? 'cu. yds.' : 'cu. yd.';
        default:
          return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
      }
    } else {
      return this.haulType;
    }
  }

  pluralizedInvoiceWeightUnitDisplay(plural = true): string {
    switch (this.invoiceWeightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
    }
  }

  pluralizedHaulWeightUnitDisplay(plural = true): string {
    switch (this.haulWeightUnit) {
      case 'cuyds':
        return plural ? 'cu. yds.' : 'cu. yd.';
      default:
        return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
    }
  }
}
