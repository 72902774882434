<form #moveAssignmentsForm="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>{{ copy ? 'Copy' : 'Move' | translate }} {{ assignments.length }} Assignments to New Job</h1>
    <div class="close-action" (click)="dialogRef.close()">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="field-group job-name" fxFlex="50">
          <label translate>Job</label>
          <dropdown #jobsDropdown [options]="jobs" (onSelect)="onSelect('job', $event)"
            [config]="jobsConfig" title="&ndash;" (onSearch)="dropdownSearch($event, 'job')"
            (nextPage)="dropdownNextPage($event, 'job')"></dropdown>
        </div>
        <div class="field-group job-event-name" fxFlex="50">
          <label translate>Job Day</label>
          <dropdown #jobEventsDropdown [options]="jobEvents" (onSelect)="onSelect('jobEvent', $event)"
            [config]="jobEventsConfig" title="&ndash;" (onSearch)="dropdownSearch($event, 'jobEvent')"
            (nextPage)="dropdownNextPage($event, 'jobEvent')"></dropdown>
        </div>
      </div>

      <div class="assignments-container">
        <div *ngFor="let assignment of assignments; first as isFirst"
            class="field-row assignments" [ngClass]="{first: isFirst}"
            fxLayout="row" fxLayoutAlign="space-between center">
          <div class="assignment-detail">
            <div class="name">{{ assignment.driver && assignment.driver.name }}</div>
            <ng-container *ngIf="trucksEditable; else staticTruck">
              <ruckit-dropdown [config]="trucksDropdownConfig"
                               [selectedOption]="assignment.truck && assignment.truck.id"
                               (onSelect)="assignment.truck = $event">
              </ruckit-dropdown>
            </ng-container>
            <ng-template #staticTruck>
              <div *ngIf="assignment.truck" class="truck"># {{ assignment.truck.displayName }}</div>
            </ng-template>
            <div class="driver-status">
              {{ assignment.driver.carrier && assignment.driver.carrier.leasedOrg ? 'Leased' : assignment.driver.organizationName }}
            </div>
          </div>

          <div class="assignment-options">
            <div class="start-time">
              <span class="label" translate>Start Time: </span>
              <date-range-picker [config]="{decreaseControl: false, increaseControl: false}"
                [selectedDates]="[assignment.uniqueStart]"
                (dateChanged)="assignmentUniqueStartDateChanged(assignment, $event)"></date-range-picker>
              <input type="text" required name="{{ 'driver-start-time-' + assignment.id }}" class="timepicker"
                [placeholder]="assignment.uniqueStartTime ? assignment.uniqueStartTime : (jobEvent && jobEvent['shift' + (shiftIndex + 1) + 'StartTimestamp'] | date: 'h:mm a')"
                [(ngModel)]="assignment.uniqueStartTime"
                [options]="{
                  disableTextInput: false,
                  'timeFormat': 'g:i A',
                  showDuration: false,
                  step: 5,
                  minTime: jobEvent && jobEvent['shift' + (shiftIndex + 1) + 'StartTimestamp'] | date: 'h:mm a',
                  maxTime: jobEvent && jobEvent['shift' + (shiftIndex + 1) + 'EndTimestamp'] | date: 'h:mm a'
                }" />
              <span class="dropdown-arrow"></span>
            </div>
            <div *ngIf="hasAllDriversEnabled" class="load-type">
              <input type="radio" name="{{ 'max-loads-' + assignment.id }}" required
                [(ngModel)]="assignment.numberOfLoadsType" [value]="'numbered'" />
              <label for="{{ 'max-loads-' + assignment.id }}"
                    [ngClass]="{'active': assignment.numberOfLoadsType === 'numbered'}"
                    translate>Loads:</label>
              <input type="number" name="{{ 'load-count-' + assignment.id }}"
                [disabled]="assignment.numberOfLoadsType !== 'numbered'"
                [(ngModel)]="assignment.maxNumberOfLoads" min="1" />
              <input type="radio" name="{{ 'max-loads-' + assignment.id }}" required [(ngModel)]="assignment.numberOfLoadsType" [value]="'allDay'" />
              <label for="{{ 'max-loads-' + assignment.id }}"
                    [ngClass]="{'active': assignment.numberOfLoadsType === 'allDay'}"
                    translate>All Day</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{ error }}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary" (click)="submit(); false"
      [disabled]="!job || !jobEvent" translate>
      {{ copy ? 'Copy' : 'Move' | translate }} Assignments
    </button>
  </div>
</form>
