<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu #contextMenu="matMenu" class="context-menu-container">
  <ng-container *ngTemplateOutlet="titleRef"></ng-container>
  <ng-template matMenuContent let-item="item">
    <ng-container *ngFor="let menu of menuOptions">
      <div
        class="context-menu-row"
        mat-menu-item
        [ngClass]="{ disabled: menu.disabled }"
        [disabled]="menu.disabled"
        (click)="onContextMenuAction(menu)"
      >
        <i *ngIf="menu.icon" ngClass="icon-{{ menu.icon }}"></i>
        <div [ngClass]="menu.classNames" translate>
          {{ menu.name }}
        </div>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>
