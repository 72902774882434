<div class="not-found">
  <div>
    <img src="assets/img/icon-404-barricade.svg" />

    <h1>404</h1>
    <h2>Oops! We got lost.</h2>

    <p>The page you're looking for doesn't exist or couldn't be found.</p>
    <p class="actions">
      <a href="/">Take me back to the home page</a>
    </p>
  </div>
</div>
