"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resource_1 = require("../shared/resource");
var Job = /** @class */ (function (_super) {
    __extends(Job, _super);
    function Job() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.canDelete = false;
        _this.secondShift = false;
        _this.dates = [];
        return _this;
    }
    Job.prototype.measurementUnit = function (plural) {
        if (this.invoiceType === 'weight') {
            if (this.invoiceWeightUnit === 'cuyds') {
                return plural ? 'cu. yds.' : 'cu. yd.';
            }
            else {
                return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
            }
        }
        else if (this.haulType === 'weight') {
            if (this.haulWeightUnit === 'cuyds') {
                return plural ? 'cu. yds.' : 'cu. yd.';
            }
            else {
                return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
            }
        }
        else {
            return 'ton';
        }
    };
    Job.prototype.url = function (action) {
        return '/jobs/' + this.id + '/' + action;
    };
    Job.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.archived) {
            var _option = lodash_1.find(options, { action: 'archive' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.canDelete) {
            var _option = lodash_1.find(options, { action: 'delete' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.archived) {
            var _option = lodash_1.find(options, { action: 'unarchive' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return Job;
}(resource_1.Resource));
exports.Job = Job;
