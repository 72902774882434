import {
  Component, OnInit, Input, Output, EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
})
export class NoResultsComponent implements OnInit {
  @Input() itemName = this.translateService.instant('Items');
  @Input() addButtonText: string;
  @Input() subTitle: string;
  @Input() customClasses: string;
  @Input() iconClass = 'icon-no-results';
  @Input() styles = {
    height: 'calc(100VH - 148px)'
  };
  @Input() addText = this.translateService.instant('an item');
  @Input() search = '';
  @Input() filters = [];
  @Input() results = 0;
  @Input() matTable = false;
  @Input() bordered = false;
  @Input() loading = false;
  @Input() hasHeader = false;
  @Input() hasAction = true;
  @Input() hasAddText = true;
  @Input() customText = '';
  @Output() clearSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addItem: EventEmitter<boolean> = new EventEmitter<boolean>();
  searching = false;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    if (this.search && this.search.length > 0) {
      this.searching = true;
    } else if (this.filters && this.filters.length > 0) {
      this.searching = true;
    }
  }

  clear() {
    this.clearSearch.emit(true);
  }

  add() {
    this.addItem.emit(true);
  }
}
