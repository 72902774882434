import {
  Component, OnInit, OnChanges, Input, Output,
  EventEmitter, HostListener, SimpleChanges
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';

export type ItemGroup = {
  name: string,
  id: string,
  groupBy: string
} & {
  items: any[],
  ordered?: number,
  assigned?: number
};


export type ItemList = ItemGroup[];

@Component({
  selector: 'item-grid',
  templateUrl: './item-grid.component.html',
  styleUrls: ['./item-grid.component.scss']
})
export class ItemGridComponent implements OnInit, OnChanges {
  @Input() itemGroups: ItemList;
  displayedItemGroups: ItemList;
  // This function from the parent component generates the relevant CSS class names for items
  @Input() itemClassNames: (item: any) => string;
  @Input() displayKeys: string[] = [];
  activeDisplayKey: string;
  @Input() groupByOptions: string[] = [];
  @Input() activeGroupBy: string;
  @Output() groupByChange: EventEmitter<string> = new EventEmitter();
  searchTerm = '';
  @Input() searchProps: string[] = ['name'];

  @Input() multiselect = true;
  @Output() onSelect: EventEmitter<string[]> = new EventEmitter();
  selectedItemsValue: string[] = [];
  @Input() showDriverContextMenu = false;
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  @Output() selectedItemsChange: EventEmitter<string[]> = new EventEmitter();
  @Input() get selectedItems() { return this.selectedItemsValue; }

  set selectedItems(data: string[]) {
    this.selectedItemsValue = data;
    this.selectedItemsChange.emit(data);
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      const unselectedVisibleItems = [].concat.apply(
        [], this.displayedItemGroups.map(list => (list.items))
      ).filter(item => (this.selectedItems.findIndex(id => (id === item.id)) === -1));
      if (unselectedVisibleItems.length === 1) { this.selectedItems.push(unselectedVisibleItems[0].id); }
    }
  }

  constructor() {}

  /**
   * On component init we pre-select the first display key from the input list
   */
  ngOnInit(): void {
    this.activeDisplayKey = this.displayKeys[0];
  }

  /**
   * Applies the current search term to any changes in the item array
   *
   * @param {SimpleChanges} changes The change event object
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemGroups && changes.itemGroups.currentValue) {
      this.displayedItemGroups = cloneDeep(this.itemGroups);
      this.changeSearch(this.searchTerm);
    }
  }

  /**
   * Checks if an id exists in the selection array
   *
   * @param {string} id The item id
   * @returns {boolean} Returns the boolean for if the item is selected or not
   */
  isSelected(id: string): boolean {
    return !!this.selectedItems.find(x => (x === id));
  }

  /**
   * Either filters out an existing selection or adds that to the selection array
   *
   * @param {string} id The item id
   */
  selectItem(id: string) {
    if (this.isSelected(id)) {
      this.selectedItems = this.selectedItems.filter(x => (x !== id));
    } else if (this.multiselect) {
      this.selectedItems.push(id);
    } else {
      this.selectedItems = [id];
    }
    if (this.selectedItems.length) { this.onSelect.emit(this.selectedItems); }
  }

  /**
   * Emits the inteded groupBy selection to the parent component and sets it active
   *
   * @param {string} groupBy The selected groupBy
   */
  selectGroupBy(groupBy: string) {
    this.groupByChange.emit(groupBy);
    this.activeGroupBy = groupBy;
  }

  /**
   * Uses search text to filter down the displayed items
   *
   * @param {string} e The search text
   */
  changeSearch(e: string) {
    if (e && e.length) {
      this.searchTerm = e.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      e = e.toUpperCase();
      this.displayedItemGroups.forEach((group: ItemGroup, i: number) => {
        group.items = this.itemGroups[i].items.filter(item => {
          let match = false;
          this.searchProps.forEach(prop => {
            if (
              ((item[prop] && item[prop].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(e)) ||
              (item.item && item.item[prop] && item.item[prop].toUpperCase().includes(e))) &&
              this.selectedItems.findIndex(id => (id === item.id)) === -1
            ) { match = true; }
          });
          return match;
        }).concat(
          this.itemGroups[i].items.filter(d => (
            this.selectedItems.findIndex(id => (id === d.id)) > -1
          ))
        );
      });
    } else { this.displayedItemGroups = cloneDeep(this.itemGroups); }
  }

  /**
   * Toggle the next active displayed key
   */
  toggleDisplayedNames() {
    const keyIndex = this.displayKeys.findIndex(k => (k === this.activeDisplayKey));
    this.activeDisplayKey = this.displayKeys[
      (keyIndex + 1) === this.displayKeys.length ? 0 : keyIndex + 1
    ];
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId
    });
  }
}
