import { Component, Input } from '@angular/core';
import { PunchCard } from '../../../../punch-cards/punch-card';
import { Trip } from '../../../../trips/trip';
import { PaySheetService } from '../../../pay-sheet.service';

@Component({
  selector: 'driver-pay-table-sublist',
  templateUrl: './driver-pay-table-sublist.component.html',
  styleUrls: ['./driver-pay-table-sublist.component.scss']
})
export class DriverPayTableSublistComponent {
  @Input() type: string;
  @Input() data: Trip[] | PunchCard[];

  displayedColumns = [];

  constructor(private paySheetService: PaySheetService) {
    this.displayedColumns = this.type === 'Trip' ?
                            ['index', 'job', 'ticketNumber', 'orderNumber', 'poNumber', 'weight', 'start', 'end', 'totalTime'] :
                            this.type === 'GeoTrip' ?
                            ['index', 'job', 'ticketNumber', 'weight', 'start', 'end', 'totalTime'] :
                            ['index', 'job', 'ticketNumber', 'start', 'end', 'totalTime'];
  }
}
