import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Scale } from './scale';
import { requestHeaders, handleError } from '../shared/api.service';

@Injectable()
export class ScaleService {
  baseUrl = environment.serverUrl + 'scales/';
  public nextUri;
  public count;
  progress$;
  progress;

  constructor(private http: HttpClient) { }

  getScale(scaleId: string) {
    let scaleUrl = this.baseUrl + scaleId + '/';

    return this.http.get(scaleUrl, { headers: requestHeaders() }).pipe(
      map(this.extractData),
      catchError(handleError)
    );
  }

  getScales(query: any = null): Observable<Scale[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    let scalesUrl = this.baseUrl;
    return this.http.get(scalesUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    this.nextUri = resObj['next'];
    this.count = resObj['count'];
    let body = resObj['results'];
    if (body) {
      return body.map(scale => {
        return new Scale(scale);
      });
    } else if (resObj) {
      return new Scale(resObj);
    }
  }

  getNext(): Observable<Scale[]> {
    if (this.nextUri) {
      return this.http.get(this.nextUri, {
        headers: requestHeaders()
      }).pipe(
        map((res: Response) => { return this.extractData(res); }),
        catchError(handleError)
      );
    } else {
      return null;
    }
  }
}
