"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_guard_1 = require("../guards/auth.guard");
var resources_component_1 = require("./resources.component");
var routes = [
    { path: '', component: resources_component_1.ResourcesComponent, canActivate: [auth_guard_1.AuthGuard] },
];
var ResourcesRoutingModule = /** @class */ (function () {
    function ResourcesRoutingModule() {
    }
    return ResourcesRoutingModule;
}());
exports.ResourcesRoutingModule = ResourcesRoutingModule;
