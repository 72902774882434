import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { ProjectService } from '../projects/project.service';
import { ConnectionService } from '../connections/connection.service';
import { parseErrors } from '../shared/api.service';
import { NewCustomerDialogComponent } from '../customers/new-customer-dialog.component';
import { DropdownComponent } from '../shared/dropdown/dropdown.component';

@Component({
  selector: 'ruckit-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
  providers: [ProjectService, ConnectionService]
})
export class NewProjectComponent implements OnInit {
  loading = false;
  model: any = {};
  errors = [];
  dialogRef: MatDialogRef<NewCustomerDialogComponent>;
  newCustomer = false;
  customerSearch = '';
  searchTimeout;
  customerOptions = [];
  customerLoading = false;
  connectionsReq;
  customerDropdownConfig: any = {
    nameProperty: 'name'
  };
  @ViewChild(DropdownComponent, { static: false }) customerDropdown;

  saveCustomerCallback = (customer) => {
    this.reloadCustomerOptions();
    this.model.customer = customer;
    this.model.customerOrganization = customer.id;
  }

  constructor(
    private router: Router,
    private projectService: ProjectService,
    private connectionService: ConnectionService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.customerDropdownConfig = {searchable: true, loadingOptions: false};
    this.reloadCustomerOptions();
  }

  submit() {
    this.loading = true;
    this.projectService.save(this.model).subscribe(() => {
      // Project created -- determine where to go. For now, go to /project-list
      this.router.navigate(['/project-list']);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  submitAndContinue() {
    this.loading = true;
    this.projectService.save(this.model).subscribe(project => {
      this.router.navigate(['/projects/' + project.id + '/new-job']);
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selectCustomer(customer) {
    if (customer.name === 'New Customer') {
      this.model.originalCustomer = this.model.customer;
      this.openNewCustomer();
    } else {
      this.model.customer = customer;
      this.model.customerOrganization = customer.id;
      this.model.originalCustomer = null;
    }
  }

  reloadCustomerOptions(query = {}, append = false) {
    if ( ! append) {
      this.customerOptions = [{name: 'New Customer', id: null, button: true}];
    }
    this.customerLoading = true;
    if (this.connectionsReq) {
      this.connectionsReq.unsubscribe();
    }
    this.connectionsReq = this.connectionService.list({
      ordering: 'organization__name',
      search: this.customerSearch
    }).subscribe(
      connections => {
        if (append) {
          this.customerOptions = this.customerOptions.concat(connections.map(connection => {
            return {name: connection.organization.name, id: connection.organization.id};
          }));
        } else {
          this.customerOptions = this.customerOptions.concat(connections.map(connection => {
            return {name: connection.organization.name, id: connection.organization.id};
          }));
        }
        this.customerLoading = false;
        this.customerDropdownConfig.loadingOptions = false;
      }, (err) => {
        this.errors = parseErrors(err);
        this.customerLoading = false;
        this.customerDropdownConfig.loadingOptions = false;
      }
    );
  }

  customerDropdownSearch(term) {
    this.customerOptions = [];
    this.customerDropdownConfig.loadingOptions = true;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.customerSearch = term;
    this.reloadCustomerOptions();
    this.customerDropdownConfig.loadingOptions = false;
  }

  customerDropdownNextPage() {
    if ( ! this.customerDropdownConfig.loadingOptions) {
      let o = this.connectionService.listNext();
      if (o) {
        this.customerDropdownConfig.loadingOptions = true;
        o.subscribe(
          connections => {
            this.customerOptions = this.customerOptions.concat(connections.map(connection => {
              return {name: connection.organization.name, id: connection.organization.id};
            }));
            this.customerDropdownConfig.loadingOptions = false;
          }, (err) => {
            this.errors = parseErrors(err);
            this.customerDropdownConfig.loadingOptions = false;
          }
        );
      }
    }
  }

  openNewCustomer() {
    this.newCustomer = true;
    this.dialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: '444px'
    });
    this.dialogRef.componentInstance.callback = this.saveCustomerCallback;
    this.dialogRef.afterClosed().subscribe((result: string) => {
      if (this.model.customer) {
        this.selectCustomer({name: this.model.customer.name, id: this.model.customer.id});
      } else {
        this.customerDropdown.deselectAll();
      }
    });
  }
}
