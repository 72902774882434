<div class="container driver-all-trips-container">
  <div class="actions-wrapper">
    <fancy-table
      #tripsTable
      [(availableColumns)]="availableColumns"
      [(displayedColumns)]="displayedColumns"
      [(filters)]="appliedFilters"
      [(search)]="search"
      (columnChange)="columnsChanged($event)"
      [config]="tableConfig"
      (onDataLoaded)="onDataLoaded($event)"
      (onMenuAction)="menuAction($event)"
      (selectionChanged)="selector($event)"
    >
      <ng-template
        #columnTemplates
        let-row="row"
        let-column="column"
        let-value="value"
      >
        <div class="table-cell" [ngSwitch]="column">
          <div class="head selector" *ngSwitchCase="'select'">
            <label>
              <input type="checkbox" [checked]="allSelected || row.selected" />
            </label>
          </div>
          <div class="job-date" *ngSwitchCase="'job-date'">
            <div class="value">
              {{ (row.jobDate | date : 'mediumDate') || '&nbsp;' }}
            </div>
            <div class="trip-status">
              <div
                class="label-{{ row.administrativeStatus }}"
                *ngIf="row.displayableStatus"
              >
                {{ row.administrativeStatus }}
              </div>
              <div class="spacer" *ngIf="!row.displayableStatus">&nbsp;</div>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'job'">
            <div class="value">{{ row.job || '&nbsp;' }}</div>
            <div class="label">{{ row.project || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'load-ticket-number'">
            {{ row.loadingTicketNumber || '&nbsp;' }}
          </div>
          <div class="value ticket-weight" *ngSwitchCase="'load-ticket-weight'">
            <div class="value">{{ row.loadingWeight || '&nbsp;' }}</div>
            <div class="label">{{ row.haulWeightUnit | weight }}</div>
          </div>
          <div class="value" *ngSwitchCase="'load-ticket-image'">
            <a
              *ngIf="row"
              [routerLink]="row.url('edit')"
              [queryParams]="{
                returnTo: '/drivers/' + row.driverId + '/details/trips'
              }"
            >
              <div
                class="ticket-photo"
                *ngIf="
                  !row.hasLoadingTicketImages && row.loadingTicketImagesRequired
                "
              >
                <div class="value" class="missing-required">&nbsp;</div>
                <div class="label" translate>No Ticket</div>
              </div>
              <div class="ticket-photo" *ngIf="row.hasLoadingTicketImages">
                <span><i class="icon-ticket"></i></span>
              </div>
            </a>
          </div>
          <div class="value" *ngSwitchCase="'load-signature-image'">
            <ng-container
              *ngIf="
                row.loadingAttachments
                  | attachmentType : signatureImageType as images
              "
            >
              <div
                class="signature-photo"
                *ngIf="row.loadingSignatureImagesRequired && !images.length"
              >
                <div class="value" class="missing-required">&nbsp;</div>
                <div class="label" translate>No Signature</div>
              </div>
              <div class="ticket-photo" *ngIf="images.length">
                <img [src]="images[0].file" />
              </div>
            </ng-container>
          </div>
          <div class="value" *ngSwitchCase="'unload-ticket-number'">
            {{ row.unloadingTicketNumber || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'unload-ticket-weight'">
            <div class="value">{{ row.unloadingWeight || '&nbsp;' }}</div>
            <div class="label">{{ row.haulWeightUnit | weight }}</div>
          </div>
          <div class="value" *ngSwitchCase="'unload-ticket-image'">
            <a
              *ngIf="row"
              [routerLink]="row.url('edit')"
              [queryParams]="{
                returnTo: '/drivers/' + row.driverId + '/details/trips'
              }"
            >
              <div
                class="ticket-photo"
                *ngIf="
                  !row.hasUnloadingTicketImages &&
                  row.unloadingTicketImagesRequired
                "
              >
                <div class="value" class="missing-required">&nbsp;</div>
                <div class="label" translate>No Ticket</div>
              </div>
              <div class="ticket-photo" *ngIf="row.hasUnloadingTicketImages">
                <span><i class="icon-ticket"></i></span>
              </div>
            </a>
          </div>
          <div class="value" *ngSwitchCase="'unload-signature-image'">
            <ng-container
              *ngIf="
                row.unloadingAttachments
                  | attachmentType : signatureImageType as images
              "
            >
              <div
                class="signature-photo"
                *ngIf="row.unloadingSignatureImagesRequired && !images.length"
              >
                <div class="value" class="missing-required">&nbsp;</div>
                <div class="label" translate>No Signature</div>
              </div>
              <div class="ticket-photo" *ngIf="images.length">
                <img [src]="images[0].file" />
              </div>
            </ng-container>
          </div>
          <div class="value" *ngSwitchCase="'carrier'">
            {{ row.carrier || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'driver'">
            <span>{{ row.driver || '&nbsp;' }}</span>
            <div class="label">
              <a
                class="replay-link"
                target="_blank"
                href="/replay?date={{
                  row.jobDate | moment : 'YYYYMMDD'
                }}&driver={{ row.driverId }}"
                >View Replay</a
              >
            </div>
          </div>
          <div class="value" *ngSwitchCase="'truck'">
            {{ row.truckName || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'time'">
            {{ row.schedule || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'duration'">
            {{ row.formattedCompletedTripTime || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'payable-to'">
            {{ row.payableTo || '&nbsp;' }}
            <div class="label">
              {{ (row.payableToPhone | telephone) || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'customer'">
            {{ row.customer || '&nbsp;' }}
            <div class="label">
              {{ (row.customerPhone | telephone) || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'invoice'">
            {{
              (row.invoiceRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') ||
                '&nbsp;'
            }}
            <div class="label">{{ row.invoiceTypeName || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'invoice-total'">
            {{
              (row.invoiceTotal || 0 | currency : 'USD' : 'symbol') || '&nbsp;'
            }}
            <div class="label">&nbsp;</div>
          </div>
          <div class="value" *ngSwitchCase="'haul'">
            {{
              (row.haulRate || 0 | currency : 'USD' : 'symbol' : '1.2-4') ||
                '&nbsp;'
            }}
            <div class="label">{{ row.haulTypeName || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'haul-total'">
            {{
              (row.haulTotal || 0 | currency : 'USD' : 'symbol' : '1.2-4') ||
                '&nbsp;'
            }}
            <div class="label">&nbsp;</div>
          </div>
          <div class="value" *ngSwitchCase="'invoice-number'">
            {{ row.invoiceTitle || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'expense-title'">
            {{ row.expenseTitle || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'paystub-title'">
            {{ row.paystubTitle || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'billing-id'">
            {{ row.billingId || '&nbsp;' }}
          </div>
          <div
            class="value {{ row.reviewedStatus }}"
            *ngSwitchCase="'reviewed-status'"
          >
            {{ (row.reviewedStatus | titlecase) || '&nbsp;' }}
          </div>
          <div class="value" *ngSwitchCase="'reviewed-by'">
            {{
              (row.reviewedBy &&
                row.reviewedBy.firstName + ' ' + row.reviewedBy.lastName) ||
                '&nbsp;'
            }}
            <div class="label">
              {{ (row.reviewedAt | date : 'mediumDate') || '&nbsp;' }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'plant-qc'">
            {{ row.plantQc || 'N/A' }}
            <div class="label">
              {{
                (row.plantQcReviewer && 'By ' + row.plantQcReviewer.name) ||
                  '&nbsp;'
              }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'plant-vt'">
            {{ row.plantVt || 'N/A' }}
            <div class="label">
              {{
                (row.plantVtReviewer && 'By ' + row.plantVtReviewer.name) ||
                  '&nbsp;'
              }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'roadway-qc'">
            {{ row.roadwayQc || 'N/A' }}
            <div class="label">
              {{
                (row.roadwayQcReviewer && 'By ' + row.roadwayQcReviewer.name) ||
                  '&nbsp;'
              }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'roadway-vt'">
            {{ row.roadwayVt || 'N/A' }}
            <div class="label">
              {{
                (row.roadwayVtReviewer && 'By ' + row.roadwayVtReviewer.name) ||
                  '&nbsp;'
              }}
            </div>
          </div>
          <div class="value" *ngSwitchDefault>{{ value || '&nbsp;' }}</div>
        </div>
      </ng-template>
      <div header-content>
        <div class="title" translate>Trips</div>
      </div>
      <div applied-filter-content>
        <applied-filters
          [(filters)]="appliedFilters"
          [(search)]="search"
        ></applied-filters>
      </div>
    </fancy-table>

    <div class="actions">
      <column-toggle
        #columnToggle
        [(availableColumns)]="availableColumns"
        [(displayedColumns)]="displayedColumns"
      >
      </column-toggle>
      <button
        class="btn btn-default duplicate-check"
        (click)="duplicateCheck()"
        [ngClass]="{ active: checkingDuplicates }"
        translate
      >
        Duplicate Check
      </button>

      <filters-panel
        [(appliedFilters)]="appliedFilters"
        [customFilter]="true"
        (openCustomFilter)="openFilters()"
        [search]="search"
        (searchChange)="search = $event"
      ></filters-panel>
      <dropdown
        title="Actions"
        [options]="multipleActionDropdownOptions"
        [changeTitle]="false"
        (onSelect)="setSelectedAction($event)"
        class="actions-dropdown"
        [disabled]="loading"
      ></dropdown>
      <div class="add-new-button">
        <button class="btn btn-primary" (click)="openAddTrip()">
          <span class="icon-plus"></span> {{ 'Add Trip' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
