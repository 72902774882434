export const IMAGE_OPTIONS = [
  'trips',
  'punchcards',
  'expenses',
  'invoices',
  'paystubs',
  'eod-trips',
  'eod-punchcards',
];

export const FIELD_OPTIONS = [
  'trips',
  'punchcards',
  'shifts',
  'driver-shifts',
  'jobs',
  'eod-trips',
  'eod-punchcards',
];

export const FORMAT_OPTIONS = ['expenses', 'invoices', 'paystubs'];

export const TYPE_OPTIONS = ['driver pay'];

export const DATE_OPTIONS = ['driver-shifts'];

export const LEASED_FLEET_OPTIONS = ['driver-shifts'];

export const LEGACY_OPTIONS = [
  'shifts',
  'driver-shifts',
  'jobs',
  'eod-trips',
  'eod-punchcards',
];

export const TYPELIST_OPTIONS = {
  IMAGE_OPTIONS,
  FIELD_OPTIONS,
  FORMAT_OPTIONS,
  TYPE_OPTIONS,
  DATE_OPTIONS,
  LEASED_FLEET_OPTIONS,
  LEGACY_OPTIONS,
};
