import { Organization } from '../organizations/organization';
import { User } from '../users/user';
import { Job } from '../jobs/job';
import { JobSerializer } from '../jobs/job.serializer';
import { TruckType } from '../trucks/truck-type';
import { TruckTypeSerializer } from '../trucks/truck-type.serializer';

export class Invitation {
  id: string;
  senderOrganization: Organization;
  recipientOrganization: Organization;
  resultOrganization: string;
  createdAt: string;
  resentAt: string;
  lastModified: string;
  kind: string;
  recipientEmail: string;
  status: string;
  senderUser: string;
  resultUser: User;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  title: string;
  data: any;
  password: string;
  passwordConfirmation: string;
  job: Job;
  senderFirstName: string;
  senderLastName: string;

  organizationName: string;
  organizationAddressLine1: string;
  organizationAddressLine2: string;
  organizationCity: string;
  organizationState: string;
  organizationZipcode: string;
  organizationPhoneNumber: string;

  inviteEmailAddresses: string[];

  loading: boolean;
  resent: boolean;
  allowAnyTruckType: boolean;
  truckTypes: TruckType[];

  constructor(invitationInfo: any) {
    this.id = invitationInfo.id;
    this.kind = invitationInfo.kind;
    this.recipientEmail = invitationInfo.recipientEmail;
    this.status = invitationInfo.status;
    this.senderOrganization = invitationInfo.senderOrganization;
    this.recipientOrganization = invitationInfo.recipientOrganization;
    this.resultUser = invitationInfo.resultUser;
    this.createdAt = invitationInfo.createdAt;
    this.lastModified = invitationInfo.lastModified;
    this.resentAt = invitationInfo.resentAt;
    this.data = invitationInfo.data;
    this.senderFirstName = invitationInfo.senderFirstName;
    this.senderLastName = invitationInfo.senderLastName;
    this.allowAnyTruckType = invitationInfo.allowAnyTruckType;
    if (invitationInfo.truckTypes && invitationInfo.truckTypes.length) {
      this.truckTypes = invitationInfo.truckTypes.map(truckType => {
        if (truckType && typeof truckType === 'object') {
          return new TruckTypeSerializer().fromJson(truckType);
        } else if (invitationInfo.truckTypes) {
          return new TruckTypeSerializer().fromJson({ id: truckType });
        }
      });
    } else {
      this.truckTypes = [];
    }
    if (invitationInfo.job) {
      this.job = new JobSerializer().fromJson(invitationInfo.job);
    }
  }

  save() {
    // Save to API
    console.log(this.id, this.kind, this.recipientEmail, this.status);
  }

  get senderIndentifiers(): string {
    let identifiers = [];
    if (this.senderOrganization && this.senderOrganization.carrier) {
      identifiers.push(this.senderOrganization.carrier.dotNumber);
    }

    if (this.senderOrganization && this.senderOrganization.broker) {
      identifiers.push(this.senderOrganization.broker.dotNumber);
    }
    return identifiers.filter(function(i) {
      return (i !== '' && i !== null);
    }).join(', ');
  }

  get recipientIndentifiers(): string {
    let identifiers = [];
    if (this.recipientOrganization && this.recipientOrganization.carrier) {
      identifiers.push(this.recipientOrganization.carrier.dotNumber);
    }

    if (this.recipientOrganization && this.recipientOrganization.broker) {
      identifiers.push(this.recipientOrganization.broker.dotNumber);
    }
    return identifiers.filter(function(i) {
      return (i !== '' && i !== null);
    }).join(', ');
  }

  get senderOrganizationTypes(): string {
    let values = [];
    if (this.senderOrganization) {
      if (this.senderOrganization.carrier && this.senderOrganization.carrier.id) {
        values.push('Carrier');
      }
      if (this.senderOrganization.contractor && this.senderOrganization.contractor.id) {
        values.push('Contractor');
      }
      if (this.senderOrganization.broker && this.senderOrganization.broker.id) {
        values.push('Broker');
      }
    }

    return values.filter(function(v) {
      return (v !== '' && v !== null);
    }).join(' | ');
  }

  get recipientOrganizationTypes(): string {
    let values = [];
    if (this.recipientOrganization) {
      if (this.recipientOrganization.carrier && this.recipientOrganization.carrier.id) {
        values.push('Carrier');
      }
      if (this.recipientOrganization.contractor && this.recipientOrganization.contractor.id) {
        values.push('Contractor');
      }
      if (this.recipientOrganization.broker && this.recipientOrganization.broker.id) {
        values.push('Broker');
      }
    }

    return values.filter(function(v) {
      return (v !== '' && v !== null);
    }).join(' | ');
  }

  get senderLocation(): string {
    let value = [];
    if (this.senderOrganization) {
      value = [
        this.senderOrganization.city, this.senderOrganization.state
      ];
    }
    return value.filter(Boolean).join(', ');
  }

  get recipientLocation(): string {
    let value = [];
    if (this.recipientOrganization) {
      value = [
        this.recipientOrganization.city, this.recipientOrganization.state
      ];
    }
    return value.filter(Boolean).join(', ');
  }
}
