import * as moment from 'moment';
import { Location } from '../locations/location';
import { ImageResizer } from '../images/resizer';
import { Attachment } from '../attachments/attachment.interface';

// constants
import { TRIPSIGNATUREIMAGETYPE, TRIPTICKETIMAGETYPE } from '../app.constants';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Checkin {
  id: string;
  index: string;
  trip: string;
  kind: string;
  weight: string;
  qr: string;
  weightWithLabel: string;
  invoiceWeightUnit: string;
  haulWeightUnit: string;
  location: Location;
  signatureImage: string;
  signatureImageKey: string;
  ticketImage: string;
  ticketImageKey: string;
  loadImage: string;
  ticketNumber: string;
  date: Date;
  options: any;
  checkinTime: string;
  attachments: Attachment[];

  constructor(checkinInfo: any) {
    checkinInfo = camelcaseKeysDeep(checkinInfo);

    this.id = checkinInfo.id;
    this.index = checkinInfo.index;
    this.trip = checkinInfo.trip;
    this.kind = checkinInfo.kind;
    this.weight = checkinInfo.weight;
    this.qr = checkinInfo.qr;
    this.invoiceWeightUnit = checkinInfo.invoiceWeightUnit;
    this.haulWeightUnit = checkinInfo.haulWeightUnit;
    this.weightWithLabel = checkinInfo.weightWithLabel;
    this.location = checkinInfo.location;
    this.signatureImage = checkinInfo.signatureImage;
    this.signatureImageKey = checkinInfo.signatureImageKey;
    this.ticketImage = checkinInfo.ticketImage;
    this.ticketImageKey = checkinInfo.ticketImageKey;
    this.loadImage = checkinInfo.loadImage;
    this.ticketNumber = checkinInfo.ticketNumber;
    this.options = checkinInfo.options;
    this.attachments = checkinInfo.attachments;

    // for older trips, before there were attachments
    if (this.attachments && this.attachments.length === 0) {
      if (this.signatureImage) {
        this.attachments.unshift({
          file: checkinInfo.signatureImage,
          src: checkinInfo.signatureImage,
          fileType: TRIPSIGNATUREIMAGETYPE,
          checkin: this.id,
          legacy: true,
        });
      }
      if (this.ticketImage) {
        this.attachments.unshift({
          file: checkinInfo.ticketImage,
          src: checkinInfo.ticketImage,
          fileType: TRIPTICKETIMAGETYPE,
          checkin: this.id,
          legacy: true,
        });
      }
    }

    if (checkinInfo.date) {
      this.date = new Date(checkinInfo.date);
      this.checkinTime = moment(this.date).format('h:mm a');
    }
  }

  get time(): string {
    let d = new Date(this.date);
    return moment(d).format('h:mm a');
  }

  get editTripTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getResizedUrl(this.ticketImageKey, 360, 481);
    }
  }

  get invoicedTripTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getResizedUrl(this.ticketImageKey, 28, 28);
    }
  }

  get fullsizeTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getFullsizeUrl(this.ticketImageKey);
    }
  }

  get uploadDialogTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getResizedUrl(this.ticketImageKey, 300, 400);
    }
  }

  get uploadDialogSignatureImage(): string {
    if (this.signatureImage && this.signatureImageKey) {
      return ImageResizer.getResizedUrl(this.signatureImageKey, 300, 100);
    }
  }

  get printableTicketImage(): string {
    if (this.ticketImage && this.ticketImageKey) {
      return ImageResizer.getResizedUrl(this.ticketImageKey, 400, 300);
    }
  }

  set time(value: string) {
    let pattern = value.match(/[ap]m/i)
      ? 'YYYY-MM-DD h:mm a'
      : 'YYYY-MM-DD h:mm';
    let d = moment(this.date).format('YYYY-MM-DD');
    this.date = moment(d + ' ' + value, pattern).toDate();
  }
}
