"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var pay_sheet_service_1 = require("../../../pay-sheet.service");
var DriverPayTimelineEditPanelComponent = /** @class */ (function () {
    function DriverPayTimelineEditPanelComponent(paySheetService) {
        this.paySheetService = paySheetService;
        this.readOnly = false;
        this.editsMade = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.reset = new core_1.EventEmitter();
        this.payRecordData = {
            jobEvent: {},
            total: 0,
            adjustment: 0,
            notes: '',
            payLines: []
        };
        this.selectedRowChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DriverPayTimelineEditPanelComponent.prototype, "selectedRow", {
        get: function () { return this.selectedRowValue; },
        set: function (row) {
            this.selectedRowValue = row;
            this.selectedRowChange.emit(row);
        },
        enumerable: true,
        configurable: true
    });
    DriverPayTimelineEditPanelComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedRow && changes.selectedRow.currentValue) {
            this.setupEditPanel(changes.selectedRow.currentValue);
        }
    };
    DriverPayTimelineEditPanelComponent.prototype.setupEditPanel = function (rowData, edits) {
        var _this = this;
        if (edits === void 0) { edits = false; }
        var selectedRow = rowData;
        var payLineTotal = 0;
        this.payRecordData = {
            jobEvent: {},
            assignment: {},
            total: 0,
            originalAmount: '',
            modifiedAmount: '',
            adjustment: 0,
            notes: '',
            payLines: []
        };
        if (selectedRow.data && selectedRow.data.rowData) {
            this.payRecordData.adjustment = selectedRow.data.rowData.payAdjustmentTotal;
            this.payRecordData.notes = selectedRow.data.rowData.notes;
            selectedRow.data.rowData.payLines.forEach(function (rowDataObj) {
                var convertedDurationObj;
                convertedDurationObj = {
                    originalDuration: _this.paySheetService.convertToDuration(rowDataObj.startDatetime, rowDataObj.endDatetime),
                    modifiedDuration: _this.paySheetService.convertToDuration(rowDataObj.startDatetime, rowDataObj.endDatetime)
                };
                payLineTotal += convertedDurationObj.originalDuration.duration;
                _this.payRecordData.payLines.push(convertedDurationObj);
            });
            if (selectedRow.data.assignment.jobevent) {
                this.payRecordData.jobEvent = selectedRow.data.assignment.jobevent;
                this.payRecordData.assignment = selectedRow.data.assignment;
                if (this.payRecordData.jobEvent.haulType === 'hour') {
                    this.payRecordData.originalAmount =
                        this.payRecordData.modifiedAmount =
                            Math.floor(payLineTotal / 60) + 'h' + (payLineTotal % 60) + 'm';
                    this.payRecordData.total = (payLineTotal / 60) * Number(this.payRecordData.jobEvent.haulRate);
                }
                else if (this.payRecordData.jobEvent.haulType === 'weight') {
                    this.payRecordData.originalAmount =
                        this.payRecordData.modifiedAmount =
                            this.payRecordData.assignment.estimatedNumberOfTons + ' tons';
                    this.payRecordData.total = Number(this.payRecordData.assignment.estimatedNumberOfTons) *
                        Number(this.payRecordData.jobEvent.haulRate);
                }
            }
        }
        if (edits) {
            this.editsMade.emit(this.selectedRow.data.rowData);
        }
    };
    DriverPayTimelineEditPanelComponent.prototype.convertDurationStringToMinutes = function (durationString) {
        var durationSplitString = durationString.split(/[hm]/);
        return (60 * Number(durationSplitString[0])) + Number(durationSplitString[1]);
    };
    DriverPayTimelineEditPanelComponent.prototype.updatePayDuration = function () {
        if (this.payRecordData.jobEvent.haulType === 'hour') {
            var changedMinutes = this.convertDurationStringToMinutes(this.payRecordData.originalAmount) -
                this.convertDurationStringToMinutes(this.payRecordData.modifiedAmount);
            this.payRecordData.originalAmount = this.payRecordData.modifiedAmount;
            var payLineIndex = this.payRecordData.payLines.length - 1;
            if (changedMinutes > 0) {
                while (changedMinutes > 0) {
                    if (this.payRecordData.payLines[payLineIndex].modifiedDuration.duration < changedMinutes) {
                        changedMinutes -= this.payRecordData.payLines[payLineIndex].modifiedDuration.duration;
                        delete this.payRecordData.payLines[payLineIndex];
                        delete this.selectedRow.data.rowData.payLines[payLineIndex];
                        payLineIndex -= 1;
                        if (payLineIndex < 0) {
                            changedMinutes = 0;
                        }
                    }
                    else {
                        this.payRecordData.payLines[payLineIndex].modifiedDuration.duration -= changedMinutes;
                        this.selectedRow.data.rowData.payLines[payLineIndex].endDatetime =
                            moment(this.selectedRow.data.rowData.payLines[payLineIndex].startDatetime)
                                .add(this.payRecordData.payLines[payLineIndex].modifiedDuration.duration, 'minutes').toISOString();
                        changedMinutes = 0;
                    }
                }
            }
            else {
                this.payRecordData.payLines[payLineIndex].modifiedDuration.duration += Math.abs(changedMinutes);
                this.selectedRow.data.rowData.payLines[payLineIndex].endDatetime =
                    moment(this.selectedRow.data.rowData.payLines[payLineIndex].startDatetime)
                        .add(this.payRecordData.payLines[payLineIndex].modifiedDuration.duration, 'minutes').toISOString();
            }
            this.setupEditPanel(this.selectedRow, true);
            this.payRecordData.total = (this.convertDurationStringToMinutes(this.payRecordData.modifiedAmount) / 60) *
                Number(this.payRecordData.jobEvent.haulRate);
        }
        else if (this.payRecordData.jobEvent.haulType === 'weight') {
            this.payRecordData.total = Number(this.payRecordData.modifiedAmount.replace(' tons', '')) *
                Number(this.payRecordData.jobEvent.haulRate);
        }
    };
    DriverPayTimelineEditPanelComponent.prototype.createPayLine = function () {
        var newStartTime;
        if (this.payRecordData.payLines.length) {
            var lastPayLine = this.payRecordData.payLines[this.payRecordData.payLines.length - 1];
            newStartTime = moment(lastPayLine.modifiedDuration.date +
                lastPayLine.modifiedDuration.endTime, 'YYYY-MM-DDHH:mm');
        }
        else {
            newStartTime = moment(this.payRecordData.jobEvent.shift1StartTimestamp);
        }
        this.selectedRow.data.rowData.payLines.push({
            startDatetime: newStartTime.toISOString(),
            endDatetime: newStartTime.add(1, 'hours').toISOString()
        });
        this.setupEditPanel(this.selectedRow, true);
    };
    DriverPayTimelineEditPanelComponent.prototype.deletePayLine = function (index) {
        delete this.selectedRow.data.rowData.payLines[index];
        this.setupEditPanel(this.selectedRow, true);
    };
    DriverPayTimelineEditPanelComponent.prototype.updatePayLine = function (index) {
        var payRecord = this.payRecordData.payLines[index];
        var rowData = this.selectedRow.data.rowData.payLines[index];
        var startDatetime = moment(payRecord.modifiedDuration.date +
            payRecord.modifiedDuration.startTime, 'YYYY-MM-DDHH:mm');
        var endDatetime = moment(payRecord.modifiedDuration.date +
            payRecord.modifiedDuration.endTime, 'YYYY-MM-DDHH:mm');
        rowData.startDatetime = startDatetime.toISOString();
        rowData.endDatetime = endDatetime.toISOString();
        this.editsMade.emit(this.selectedRow.data.rowData);
    };
    DriverPayTimelineEditPanelComponent.prototype.editPayRecord = function (field, event) {
        if (this.selectedRow.data.rowData.payLines.length === 0) {
            this.selectedRow.data.rowData.payLines.push({ startDatetime: null, endDatetime: null });
        }
        if (!this.selectedRow.data.rowData.payAdjustmentTotal) {
            this.selectedRow.data.rowData.payAdjustmentTotal = 0;
        }
        switch (field) {
            case 'adjustment':
                this.selectedRow.data.rowData.payAdjustmentTotal = Number(event.target['value']);
                break;
            case 'notes':
                this.selectedRow.data.rowData.notes = event.target['value'];
                break;
            default:
                break;
        }
        this.setupEditPanel(this.selectedRow, true);
    };
    return DriverPayTimelineEditPanelComponent;
}());
exports.DriverPayTimelineEditPanelComponent = DriverPayTimelineEditPanelComponent;
