import { Organization } from '../organizations/organization';
import { Resource } from '../shared/resource';

export class Project extends Resource  {
  id: string;
  name: string;
  customerOrganization: Organization;
  ownerOrganization: string;
  poNumber: string;
}
