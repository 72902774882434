"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var PaystubPayTypeDialogComponent = /** @class */ (function () {
    function PaystubPayTypeDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
    }
    PaystubPayTypeDialogComponent.prototype.ngOnInit = function () {
        this.paystub = lodash_1.cloneDeep(this.paystub);
    };
    PaystubPayTypeDialogComponent.prototype.submit = function () {
        this.callback(this.paystub);
        this.dialogRef.close();
    };
    return PaystubPayTypeDialogComponent;
}());
exports.PaystubPayTypeDialogComponent = PaystubPayTypeDialogComponent;
