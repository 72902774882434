"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TimeInputComponent = /** @class */ (function () {
    function TimeInputComponent(renderer, elementRef) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.timeChange = new core_1.EventEmitter();
        this.hours = '';
        this.minutes = '';
        this.meridian = '';
    }
    TimeInputComponent.prototype.hoursKeydown = function (e) {
        var _this = this;
        if (e.key && e.key.length !== 1) {
            return;
        }
        if (!e.key.match(/\d/)) {
            e.preventDefault();
        }
        setTimeout(function () {
            if (parseInt(e.target.value, 10) > 1) {
                if (parseInt(e.target.value, 10) > 12) {
                    _this.hours = '12';
                }
                _this.renderer.invokeElementMethod(_this.elementRef.nativeElement.querySelector('[name="minutes"]'), 'focus', []);
            }
            _this.emitChange();
        }, 0);
    };
    TimeInputComponent.prototype.minutesKeydown = function (e) {
        var _this = this;
        if (e.key && e.key.length !== 1) {
            return;
        }
        if (!e.key.match(/\d/)) {
            e.preventDefault();
        }
        setTimeout(function () {
            if (parseInt(e.target.value, 10) > 9) {
                if (parseInt(e.target.value, 10) > 59) {
                    _this.minutes = '59';
                }
                _this.renderer.invokeElementMethod(_this.elementRef.nativeElement.querySelector('[name="meridian"]'), 'focus', []);
            }
            _this.emitChange();
        }, 0);
    };
    TimeInputComponent.prototype.meridianKeydown = function (e) {
        if (e.key && e.key.length !== 1) {
            return;
        }
        if (e.key.match(/a/i)) {
            this.meridian = 'AM';
        }
        else if (e.key.match(/p/i)) {
            this.meridian = 'PM';
        }
        else {
            // do nothing.
        }
        e.preventDefault();
        this.emitChange();
    };
    TimeInputComponent.prototype.emitChange = function () {
        this.timeChange.emit((parseInt(this.hours, 10) + ((this.meridian === 'PM') ? 12 : 0)).toString()
            + ':' + this.minutes);
    };
    TimeInputComponent.prototype.select = function (e) {
        e.target.select();
    };
    TimeInputComponent.prototype.ngOnChanges = function (changes) {
        var currentValue = changes['value']['currentValue'];
        if (currentValue) {
            var parts = currentValue.split(':');
            var pm = parts[1].match(/pm/i);
            this.hours = (parts[0] % 12).toString();
            this.minutes = parts[1].match(/\d+/)[0];
            this.meridian = (parseInt(parts[0], 10) > 12 || pm) ? 'PM' : 'AM';
        }
    };
    return TimeInputComponent;
}());
exports.TimeInputComponent = TimeInputComponent;
