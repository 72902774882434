"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var FilterPipe = /** @class */ (function () {
    function FilterPipe() {
    }
    FilterPipe.prototype.transform = function (items, iterator) {
        if (!items) {
            return [];
        }
        return lodash_1.filter(items, iterator);
    };
    return FilterPipe;
}());
exports.FilterPipe = FilterPipe;
