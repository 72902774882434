<div class="app-content ruckit-replay">
  <my-toolbar title="{{ 'Replay' | translate }}"></my-toolbar>

  <!-- replay map -->
  <ruckit-replay-map
    [jobEvents]="jobEvents"
    [locations]="locations"
    [driver]="selectedDriver"
    [loadingLocationUpdates]="loadingLocationUpdates"
    [locationUpdates]="driverLocationUpdates"
    [selectedTime]="selectedTime"
    [timeRange]="timeRange"
  ></ruckit-replay-map>

  <!-- filters -->
  <div class="filters">
    <button class="btn btn-default play-button" (click)="play(playbackSpeed)">
      <mat-icon *ngFor="let icon of playIcons">play_arrow</mat-icon>
    </button>
    <button *ngIf="playing" class="btn btn-default" (click)="pause()">
      <mat-icon>pause</mat-icon>
    </button>
    <dropdown
      #intervalSelect
      title="Select Interval"
      [config]="timeIntervalConfig"
      [options]="timeIntervalOptions"
      (onSelect)="timeInterval = $event.amount"
    ></dropdown>
    <date-range-picker
      *ngIf="selectedDate"
      [selectedDates]="[selectedDate.toISOString()]"
      (dateChanged)="onDateChanged($event)"
    ></date-range-picker>
    <ruckit-dropdown
      #driverDropdownWrapper
      class="driver-dropdown"
      [config]="driverDropdownConfig"
      [selectedOption]="driverId"
      (onSelect)="getDriverAssignments($event)"
    >
    </ruckit-dropdown>
    <div class="show-voided">
      <input type="checkbox" name="show_voided" (change)="toggleVoided()" />
      <label
        attr.data-label="{{ 'Show Voided' | translate }}"
        for="show_voided"
      ></label>
    </div>
    <span class="legend-toggle" (click)="legend = !legend" translate>
      Legend
      <i
        [ngClass]="{
          'icon-next-arrow': !legend,
          'icon-previous-arrow': legend
        }"
      ></i>
    </span>
    <div class="legend-container" *ngIf="legend">
      <timeline-legend [labels]="timelineLabels"></timeline-legend>
    </div>
  </div>

  <!-- replay timeline -->
  <ruckit-replay-timeline
    [selectedDate]="selectedDate"
    [(selectedTime)]="selectedTime"
    [timeRange]="timeRange"
    [timeInterval]="timeInterval"
    [assignments]="assignments"
    [jobEvents]="jobEvents"
    [driver]="selectedDriver"
    [locationUpdates]="driverLocationUpdates"
    [playing]="playing"
    [showVoided]="showVoided"
    (onRefreshTimeline)="onRefresh()"
  ></ruckit-replay-timeline>
  <notification *ngFor="let error of errors">{{ error }}</notification>
</div>
