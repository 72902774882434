import {
  Component, ViewChild, OnInit, EventEmitter, Output, Input, TemplateRef
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { remove } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { FilterOption } from '../shared/filters-panel/filter-option';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';
import { TicketFiltersDialogComponent } from './ticket-filters-dialog.component';
import { VoidTicketDialogComponent } from './void-ticket-dialog.component';
import { CondensedTicketService } from './condensed-ticket.service';
import { TicketFilterService } from './ticket.filter.service';
import { TicketService } from './ticket.service';

@Component({
  selector: 'tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})

export class TicketsComponent implements OnInit {
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'date', title: this.translationService.instant('Date'), sortable: true, sortBy: 'delivery_time' },
    { key: 'number', title: this.translationService.instant('Ticket #'), sortable: true, sortBy: 'number' },
    { key: 'truck', title: this.translationService.instant('Truck #'), sortable: true, sortBy: 'truck__name' },
    { key: 'customer', title: this.translationService.instant('Customer'), sortable: true, sortBy: 'customer_organization__name' },
    { key: 'purchase-order', title: this.translationService.instant('Order'), sortable: true, sortBy: 'purchase_order__name' },
    { key: 'location', title: this.translationService.instant('Location'), sortable: true, sortBy: 'location__name' },
    { key: 'product', title: this.translationService.instant('Product'), sortable: true, sortBy: 'product__name' },
    { key: 'net', title: this.translationService.instant('Net'), sortable: true, sortBy: 'net' },
    { key: 'net-price', title: this.translationService.instant('Ticket Total'), sortable: true, sortBy: 'net_price' },
    { key: 'actions', title: this.translationService.instant('Action') }
  ];

  @Input() displayedColumns = [
    'select', 'date', 'number', 'truck', 'customer', 'purchase-order',
    'location', 'product', 'net', 'net-price', 'actions'
  ];
  @Input() availableFilters = [
    new FilterOption({
      key: 'customer_organization', title: this.translationService.instant('Customer'),
      filterType: 'select', service: TicketFilterService, slug: 'customers'
    }),
    new FilterOption({
      key: 'truck', title: this.translationService.instant('Truck'),
      filterType: 'select', service: TicketFilterService, slug: 'truck'
    }),
    new FilterOption({
      key: 'purchase_order', title: this.translationService.instant('Order'),
      filterType: 'select', service: TicketFilterService, slug: 'order'
    }),
    new FilterOption({
      key: 'product', title: this.translationService.instant('Product'),
      filterType: 'select', service: TicketFilterService, slug: 'products'
    }),
    new FilterOption({
      key: 'location', title: this.translationService.instant('Location'),
      filterType: 'select', service: TicketFilterService, slug: 'location'
    }),
    new FilterOption({
      key: 'ticket_status', title: this.translationService.instant('void'),
      filterType: 'toggle'
    }),
    new FilterOption({
      key: 'ticket_status', title: this.translationService.instant('in-progress'),
      filterType: 'toggle'
    }),
    new FilterOption({
      key: 'ticket_status', title: this.translationService.instant('on-hold'),
      filterType: 'toggle'
    }),
    new FilterOption({
      key: 'ticket_status', title: this.translationService.instant('complete'),
      filterType: 'toggle'
    }),
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  errors = [];
  ticketTableConfig = {
    hasHeader: true,
    service: CondensedTicketService,
    preferenceKey: 'TicketsComponent-CondensedTicketService',
    query: {},
    collectionTitle: this.translationService.instant('Tickets'),
    noResultsText: this.translationService.instant('a ticket'),
    customHeight: true,
    newRecordModal: () => { this.openNewScaleTicket(); },
    sortBy: '',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Export'), action: 'export', link: false, external: false },
      { name: this.translationService.instant('Void'), action: 'void', link: false, external: false }
    ]
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('ticketTable', { static: false }) ticketTable;
  /**
   * Template reference for the Applied Filters bar.
   */
  @ViewChild('appliedFiltersDisplay', { static: false }) appliedFiltersDisplay;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;
  loading = true;
  tickets: any = [];
  ticketsReq;
  pageSize = 25;
  ticket;
  filters = [];
  filtersDialog;
  selectedCount = 0;
  allSelected = false;
  selectedTickets = [];
  excludeTickets = [];
  confirmDialog: MatDialogRef<any>;
  voidDialog: MatDialogRef<VoidTicketDialogComponent>;
  count = 0;
  page = 1;
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Export'), action: 'export', button: true, link: false }
  ];
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  voidTicketCallback = (e) => {
    this.ticket = e;
    this.ticketTable.getRecords();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketService,
    private translationService: TranslateService,
    private dialog: MatDialog
  ) { }

  ngOnInit() { }

  clickAction(event) {
    // Note: For future use
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  openFilters() {
    const dialog = this.dialog.open(TicketFiltersDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = this.appliedFilters.reduce((acc, filter) => {
      if (filter.filterType === 'checkbox') {
        if (filter.values === true) { filter.values = ['True']; }
        acc[filter.key] = filter.values && filter.values[0] === 'True';
      } else if (filter.filterType === 'select') {
        acc[filter.key] = {
          id: filter.values && filter.values[0],
          name: filter.displayValues && filter.displayValues[0]
        };
      } else {
        acc[filter.key] = filter.value;
      }
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    let triggerRefresh = false;

    if (filterRes) {
      if (filterRes.product !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'product');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'product', title: 'Product', filterType: 'select',
          service: TicketFilterService, values: [filterRes.product.id],
          displayValues: [filterRes.product.name], slug: 'products'
        }));
      }

      if (filterRes.customer !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'customer');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'customer_organization', title: 'Customer', filterType: 'select',
          service: TicketFilterService,
          values: [filterRes.customer.id],
          displayValues: [filterRes.customer.name], slug: 'customers'
        }));
      }

      if (filterRes.location !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'location');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'location', title: 'Location', filterType: 'select',
          service: TicketFilterService,
          values: [filterRes.location.id],
          displayValues: [filterRes.location.name], slug: 'locations'
        }));
      }


      if (filterRes.order !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'order');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'purchase_order', title: 'Order', filterType: 'select',
          service: TicketFilterService,
          values: [filterRes.order.id],
          displayValues: [filterRes.order.name], slug: 'orders'
        }));
      }

      if (filterRes.truck !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'truck');
        this.appliedFilters = this.appliedFilters.concat(new FilterOption({
          key: 'truck', title: 'Truck', filterType: 'select',
          service: TicketFilterService,
          values: [filterRes.truck.id],
          displayValues: [filterRes.truck.name], slug: 'trucks'
        }));
      }

      if (filterRes.void !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'ticket_status');
        if (filterRes.void) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'ticket_status', title: 'Void', filterType: 'toggle',
            values: ['void'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }

      if (filterRes.completed !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'ticket_status');
        if (filterRes.completed) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'ticket_status', title: 'Completed', filterType: 'toggle',
            values: ['complete'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }

      if (filterRes.inProgress !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'ticket_status');
        if (filterRes.inProgress) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'ticket_status', title: 'inProgress', filterType: 'toggle',
            values: ['in-progress'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }
      if (filterRes.onHold !== undefined) {
        remove(this.appliedFilters, filter => filter.key === 'ticket_status');
        if (filterRes.onHold) {
          this.appliedFilters = this.appliedFilters.concat(new FilterOption({
            key: 'ticket_status', title: 'onHold', filterType: 'toggle',
            values: ['on-hold'], displayValues: ['True']
          }));
        } else {
          triggerRefresh = true;
        }
      }
    }
    if (triggerRefresh) { this.ticketTable.getRecords(); }
  }

  /**
   * @param  {} appliedFilters
   * Used for reseting the filters or when filter is changed
   */
  filtersModified(appliedFilters) {
    if (this.appliedFiltersDisplay) {
      this.appliedFiltersDisplay.ngOnInit();
    }
    this.appliedFilters = appliedFilters;
  }

  removeFilter(filter) {
    remove(this.appliedFilters, filter);
    this.ticketTable.getRecords();
  }

  menuAction(event) {
    switch (event[0]) {
      case 'void':
        this.void(event[1]);
        break;
      case 'export':
        this.createExport([event[1].id]);
        break;
    }
  }

  createExport(selectedTickets = null, excludeTickets = null) {
    let scope = { scaletickets: selectedTickets, exclude_scaletickets: excludeTickets, };

    let filters = this.filters.reduce((acc, filter) => {
      return { ...acc, ...filter.query };
    }, {});
    delete filters['undefined'];
    filters.search = this.search;

    this.ticketService.export(scope, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'tickets'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'tickets',
          scope: scope,
          params: params,
          service: this.ticketService,
          buttonText: this.translationService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  void(ticket) {
    this.voidDialog = this.dialog.open(VoidTicketDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.voidDialog.componentInstance.ticket = ticket;
    this.voidDialog.componentInstance.callback = this.voidTicketCallback;
    this.voidDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        //
      }
      this.voidDialog = null;
    });
  }

  openNewScaleTicket() {
    this.router.navigate(['/scale/create-ticket']);
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  /**
   * @param {} option
   * called on selecting action option from the header of the table
   */
  setSelectedAction(option) {
    switch (option.action) {
      case 'export':
        if (this.ticketTable) {
          let selectedTicketIds;
          if (!this.ticketTable.allSelected) {
            selectedTicketIds = this.ticketTable.selection.selected.map(ticket => ticket.id);
          }
          let excludedTicketIds;
          if (this.ticketTable.allSelected) {
            excludedTicketIds = this.ticketTable.exclusion.selected.map(ticket => ticket.id);
          }
          this.createExport(selectedTicketIds, excludedTicketIds);
        }
        break;
    }
  }
}
