import { clone } from 'lodash';

import { Resource } from '../shared/resource';
import { Truck } from '../trucks/truck';
import { Driver } from '../drivers/driver';

export class Shift extends Resource {
  id: string;
  driver: Driver;
  truck: Truck;
  startTime: string;
  endTime: string;
  adjustment: number;
  haulTotal: number;
  jobeventsCount: number;
  tripsCount: number;
  totalHours: number;
  totalTons: number;
  autoClosed: boolean;

  filterOptions(options): any[] {
    options = clone(options);
    return options;
  }
}
