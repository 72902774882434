"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var MomentPipe = /** @class */ (function () {
    function MomentPipe() {
    }
    MomentPipe.prototype.transform = function (input, format) {
        if (!input) {
            return '';
        }
        return moment(input).format(format);
    };
    return MomentPipe;
}());
exports.MomentPipe = MomentPipe;
