"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var TripsModule = /** @class */ (function () {
    function TripsModule(translateService) {
        var browserLang = translateService.getBrowserLang();
        translateService.use(browserLang.match(/en|fr|es/) ? browserLang : 'en');
    }
    return TripsModule;
}());
exports.TripsModule = TripsModule;
