import {
  Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatTableDataSource, MatTable } from '@angular/material';
import { Location } from '@angular/common';
import { find as _find } from 'lodash';
import * as moment from 'moment';

import { Trip } from '../../trips/trip';
import { PunchCardService } from '../../punch-cards/punch-card.service';
import { PunchCardDecisionService } from '../../punch-cards/punch-card-decision.service';
import { AuthenticationService } from '../../shared';
import { Organization } from '../../organizations/organization';
import { User } from '../../users/user';
import { PunchCard } from '../../punch-cards/punch-card';
import { PunchCardDecision } from '../../punch-cards/punch-card-decision';
import { JobEventShare } from '../../job-event-shares/job-event-share';

@Component({
  selector: 'driver-eod-punch-cards',
  templateUrl: './driver-eod-punch-cards.component.html',
  styleUrls: ['./driver-eod-punch-cards.component.scss'],
  providers: [PunchCardService, PunchCardDecisionService]
})
export class DriverEODPunchCardsComponent implements OnInit, OnChanges {
  @Input() trips: Trip[] = [];
  @Input() punchCards: any[] = [];
  @Input() jobEventShare: JobEventShare;
  @Input() invoiceRate: number;
  @Input() haulRate: number;
  @Input() hideApproved = false;
  @Input() showVoided = false;
  @Output() shouldAuditDecisions: EventEmitter<any> = new EventEmitter();
  @Output() updatePunchCardData: EventEmitter<PunchCard[]> = new EventEmitter();
  organization: Organization;
  user: User;
  loading = true;
  errors = [];
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  displayedColumns: string[] = [
    'start-time', 'end-time', 'total-time', 'loading-ticket-number', 'approved-by', 'actions'
  ]; // 'actions'
  @ViewChild('punchCardsTable', { static: false }) punchCardsTable: MatTable<PunchCard>;
  dataSource = new MatTableDataSource<PunchCard>();
  returnTo: string;

  constructor(
    public punchCardService: PunchCardService,
    public punchCardDecisionService: PunchCardDecisionService,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private location: Location
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnInit() {
    this.organization = this.authenticationService.getOrganization();
    this.user = this.authenticationService.user();
    this.returnTo = this.location.path();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    let punchCards = this.punchCards.filter(v => v.job.id === this.jobEventShare.jobId);
    punchCards = punchCards.filter(v => {
      if (this.hideApproved) {
        return !v.latestDecisionStatus || (v.latestDecisionStatus && v.latestDecisionStatus !== 'approved');
      } else {
        return true;
      }
    });
    punchCards = punchCards.filter(v => {
      return this.showVoided ? true : !v.void;
    });
    this.dataSource.data = punchCards;
    if (this.punchCardsTable) { this.punchCardsTable.renderRows(); }
    this.loading = false;
  }

  /**
   * Makes an edit to either the startTime or endTime field and saves that edit to the API trip record
   *
   * @param {PunchCard} punchCard The target punch card
   * @param {string} field The time field we will apply the edit to
   * @param {any} value The edit value for the time adjustment
   */
   editPunchCardTime(punchCard: PunchCard, field: string, value: string) {
    let saveData: any = { id: punchCard.id };
    let hasChanges = false;
    const newTime = moment(value);
    if (field === 'start' && !newTime.isSame(punchCard.startTimeTimestamp)) {
      hasChanges = true;
      saveData.startTimeTimestamp = value;
      if (newTime.isAfter(punchCard.endTimeTimestamp)) {
        saveData.endTimeTimestamp = value;
      }
    } else if (field === 'end' && !newTime.isSame(punchCard.endTimeTimestamp)) {
      hasChanges = true;
      saveData.endTimeTimestamp = value;
      if (newTime.isBefore(punchCard.startTimeTimestamp)) {
        saveData.startTimeTimestamp = value;
      }
    }
    if (!hasChanges) { return; }

    // Change detected, send to server
    punchCard.loading = true;
    this.punchCardService.save(saveData).subscribe(updates => {
      let punchCardUpdates: PunchCard = new PunchCard(updates);
      this.updatePunchCard(Object.assign(punchCard, {
        startTimeTimestamp: punchCardUpdates.startTimeTimestamp,
        endTimeTimestamp: punchCardUpdates.endTimeTimestamp,
        duration: punchCardUpdates.duration
      }));
      punchCard.loading = false;
    }, (err) => {
      this.errors = err;
      punchCard.loading = false;
    });
  }

  unapprovePunchCard(punchCard: PunchCard): void {
    this.approvePunchCard(punchCard, 'pending');
  }

  approvePunchCard(punchCard: PunchCard, decisionStatus = 'approved'): void {
    punchCard.loading = true;
    let data = {
      decisionStatus: decisionStatus,
      decidedBy: this.user,
      organization: this.organization,
      decidedAt: new Date().toISOString(),
      punchcard: punchCard
    };
    this.punchCardDecisionService.save(data).subscribe((decision: PunchCardDecision) => {
      punchCard.latestDecision = decision.id;
      punchCard.latestDecisionStatus = decision.decisionStatus;
      punchCard.latestDecider = decision.decidedBy && decision.decidedBy.id;
      punchCard.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
      this.shouldAuditDecisions.emit();
      this.updatePunchCard(punchCard);
      punchCard.loading = false;
    }, (err) => {
      this.errors = err;
      punchCard.loading = false;
    });
  }

  updatePunchCard(updates: PunchCard) {
    const updatedIndex = this.dataSource.data.findIndex(t => (t['id'] === updates['id']));
    this.dataSource.data[updatedIndex] = Object.assign(
      this.dataSource.data[updatedIndex], {
        duration: updates.duration,
        startTimeTimestamp: updates.startTimeTimestamp,
        endTimeTimestamp: updates.endTimeTimestamp,
      }
    );
    if (this.punchCardsTable) { this.punchCardsTable.renderRows(); }
    this.updatePunchCardData.emit(this.dataSource.data);
  }
}
