"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".heading[_ngcontent-%COMP%]{display:flex;flex-direction:row;align-items:center;height:100%}.heading[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{margin:0 25px 0 0;font-size:15px;font-weight:600;color:#3f536e}.heading[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{margin-right:15px;font-size:13px;font-weight:600;cursor:pointer}.heading[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a.active[_ngcontent-%COMP%], .heading[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover{color:#0093ee}.header[_ngcontent-%COMP%]{padding-right:0}.content[_ngcontent-%COMP%]{padding:20px}.content[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]{color:#58b4f9;font-weight:600;text-decoration:none}.content[_ngcontent-%COMP%]   .job[_ngcontent-%COMP%]{font-size:13px;color:#3f536e;flex:1 1 auto;overflow:hidden;text-overflow:ellipsis}.content[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .job[_ngcontent-%COMP%]{height:39px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;line-height:39px;padding-right:10px}.content[_ngcontent-%COMP%]   .hours[_ngcontent-%COMP%], .content[_ngcontent-%COMP%]   .loads[_ngcontent-%COMP%], .content[_ngcontent-%COMP%]   .tons[_ngcontent-%COMP%]{flex:0 0 85px;width:85px;overflow:hidden;text-overflow:ellipsis;font-size:13px;color:#3f536e}"];
exports.styles = styles;
