"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ɵ0 = {}, ɵ1 = [];
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
var DriverReportsModule = /** @class */ (function () {
    function DriverReportsModule() {
    }
    return DriverReportsModule;
}());
exports.DriverReportsModule = DriverReportsModule;
