<!-- with animation -->
<div
  *ngIf="withAnimation && open"
  [@slideRightToLeft]
  class="multiple-images-edit-container"
  #editImagesContainer
>
  <div class="mi-title">
    <label translate>Attachments</label>
    <div (click)="handleVisibility()" class="close-action">
      <i class="icomoon icon-close"></i>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="multipleImagesContent"></ng-container>
  <div class="save-btn-container" *ngIf="showOnSave">
    <button
      [disabled]="saveDisabled"
      type="button"
      class="btn btn-primary"
      [ngClass]="{ loading: loadingSave }"
      (click)="save()"
      translate
    >
      Save
    </button>
  </div>
</div>

<!-- no animation -->
<div class="multiple-images-edit-container-no-animation" *ngIf="!withAnimation">
  <ng-container *ngTemplateOutlet="multipleImagesContent"></ng-container>
</div>

<ng-template #multipleImagesContent>
  <!-- loading images -->
  <div class="loading-tickets-container" *ngIf="showLoadingImages">
    <label [class.required]="requiredFields.loadingImages" translate
      >Loading Ticket Image(s)</label
    >
    <div class="ticket-image-container" *ngIf="!currentLoadingImages.length">
      <image-edit
        [image]="currentLoadingImages[0]"
        [options]="{ editable: editable, light: true, showDelete: true }"
        (uploadData)="onLoadingImageChange($event, 0)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>
    <div
      *ngFor="let image of currentLoadingImages; let i = index"
      class="ticket-image-container"
    >
      <image-edit
        [image]="image"
        [options]="{ editable: editable, light: true, showDelete: true }"
        (uploadData)="onLoadingImageChange($event, i)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>

    <button
      *ngIf="editable && currentLoadingImages.length > 0"
      [disabled]="
        currentLoadingImages.length !== 0 &&
        currentLoadingImages[currentLoadingImages.length - 1] === null
      "
      type="button"
      class="btn btn-primary btn-add-img"
      (click)="onLoadingImageAdd()"
      translate
    >
      Upload Image
    </button>

    <ng-container *ngTemplateOutlet="buttonLoadingRef"></ng-container>
  </div>
  <!-- loading signature images -->
  <div class="loading-tickets-container" *ngIf="showLoadingSignatureImages">
    <label [class.required]="requiredFields.loadingSignature" translate
      >Loading Signature</label
    >
    <div
      class="ticket-image-container"
      *ngIf="!currentLoadingSignatureImages.length"
    >
      <image-edit
        [image]="currentLoadingSignatureImages[0]"
        [options]="{ editable: editable, light: true, showDelete: true }"
        (uploadData)="onLoadingSignatureImageChange($event, 0)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>
    <div
      *ngFor="let image of currentLoadingSignatureImages; let i = index"
      class="ticket-image-container"
    >
      <image-edit
        [image]="image"
        [options]="{ editable: true, light: true, showDelete: true }"
        (uploadData)="onLoadingSignatureImageChange($event, i)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>

    <button
      *ngIf="editable && currentLoadingSignatureImages.length > 0"
      [disabled]="
        currentLoadingSignatureImages.length !== 0 &&
        currentLoadingSignatureImages[
          currentLoadingSignatureImages.length - 1
        ] === null
      "
      type="button"
      class="btn btn-primary btn-add-img"
      (click)="onLoadingSignatureImageAdd()"
      translate
    >
      Upload Image
    </button>
  </div>

  <!-- unloading ticket images -->
  <div class="loading-tickets-container" *ngIf="showUnloadingImages">
    <label [class.required]="requiredFields.unloadingImages" translate
      >Unloading Ticket Image(s)</label
    >
    <div class="ticket-image-container" *ngIf="!currentUnloadingImages.length">
      <image-edit
        [image]="currentUnloadingImages[0]"
        [options]="{ editable: editable, light: true, showDelete: true }"
        (uploadData)="onUnloadingImageChange($event, 0)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>
    <div
      *ngFor="let image of currentUnloadingImages; let i = index"
      class="ticket-image-container"
    >
      <image-edit
        [image]="image"
        [options]="{ editable: editable, light: true, showDelete: true }"
        (uploadData)="onUnloadingImageChange($event, i)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>

    <button
      *ngIf="editable && currentUnloadingImages.length > 0"
      [disabled]="
        currentUnloadingImages.length !== 0 &&
        currentUnloadingImages[currentUnloadingImages.length - 1] === null
      "
      type="button"
      class="btn btn-primary btn-add-img"
      (click)="onUnloadingImageAdd()"
      translate
    >
      Upload Image
    </button>

    <ng-container *ngTemplateOutlet="buttonLoadingRef"></ng-container>
  </div>
  <!-- unloading signature images -->
  <div class="loading-tickets-container" *ngIf="showUnloadingSignatureImages">
    <label [class.required]="requiredFields.unloadingSignature" translate
      >Unloading Signature</label
    >
    <div
      class="ticket-image-container"
      *ngIf="!currentUnloadingSignatureImages.length"
    >
      <image-edit
        [image]="currentUnloadingSignatureImages[0]"
        [options]="{ editable: editable, light: true, showDelete: true }"
        (uploadData)="onUnloadingSignatureImageChange($event, 0)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>
    <div
      *ngFor="let image of currentUnloadingSignatureImages; let i = index"
      class="ticket-image-container"
    >
      <image-edit
        [image]="image"
        [options]="{ editable: true, light: true, showDelete: true }"
        (uploadData)="onUnloadingSignatureImageChange($event, i)"
        (uploading)="uploading($event)"
      ></image-edit>
    </div>

    <button
      *ngIf="editable && currentUnloadingSignatureImages.length > 0"
      [disabled]="
        currentUnloadingSignatureImages.length !== 0 &&
        currentUnloadingSignatureImages[
          currentUnloadingSignatureImages.length - 1
        ] === null
      "
      type="button"
      class="btn btn-primary btn-add-img"
      (click)="onUnloadingSignatureImageAdd()"
      translate
    >
      Upload Image
    </button>
  </div>
</ng-template>
