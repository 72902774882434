"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var Ticket = /** @class */ (function () {
    function Ticket() {
        this.surcharges = [];
        this.selected = false;
    }
    Ticket.prototype.url = function (action) {
        return '/scale/tickets/' + this.id + '/' + action;
    };
    Ticket.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return Ticket;
}());
exports.Ticket = Ticket;
