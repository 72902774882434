import { CondensedJobEventFilter } from './condensed-job-event.filter';
const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class CondensedJobEventFilterSerializer {
  fromJson(json: any): CondensedJobEventFilter {
    json = camelcaseKeysDeep(json);
    const jobEventFilter = new CondensedJobEventFilter();
    if (!json) { return jobEventFilter; }
    jobEventFilter.id = json.id;
    jobEventFilter.name = json.name;

    return jobEventFilter;
  }

  toJson(jobEventFilter: CondensedJobEventFilter): any {
    let json = {
      id: jobEventFilter.id,
      name: jobEventFilter.name
    };

    return decamelizeKeysDeep(json);
  }
}
