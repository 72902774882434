import { Component, Input, ElementRef, HostListener, OnInit, AfterViewInit } from '@angular/core';

import { TruckType } from './truck-type';

@Component({
  selector: 'truck-type-names',
  templateUrl: './truck-type-names.component.html',
  styleUrls: ['./truck-type-names.component.scss'],
})
export class TruckTypeNamesComponent implements OnInit, AfterViewInit {
  @Input() truckTypes: TruckType[];
  @Input() anyType = false;
  @Input() countOnly = false;
  @Input() ref: any;
  hover = false;
  names = '';
  popoverBottom;
  popoverLeft;

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit() {
    if (this.anyType) {
      this.names = 'Any Type';
    } else {
      this.names = this.truckTypes.map((truckType) => {
        return truckType.name;
      }).join(', ');
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.popoverBottom = window.innerHeight - this.element.nativeElement.getBoundingClientRect().top;
    this.popoverLeft = this.element.nativeElement.getBoundingClientRect().left;
  }

  ngAfterViewInit(): void {
    // position based on `ref`
  }
}
