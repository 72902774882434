"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var ticket_filter_service_1 = require("./ticket.filter.service");
var connection_service_1 = require("../connections/connection.service");
var api_service_1 = require("../shared/api.service");
var lodash_1 = require("lodash");
var TicketFiltersDialogComponent = /** @class */ (function () {
    function TicketFiltersDialogComponent(ticketFilterService, connectionService, dialogRef) {
        this.ticketFilterService = ticketFilterService;
        this.connectionService = connectionService;
        this.dialogRef = dialogRef;
        this.loading = false;
        this.errors = [];
        this.trucks = [];
        this.trucksConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.purchaseOrders = [];
        this.ordersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.customers = [];
        this.customersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.products = [];
        this.productsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.locations = [];
        this.locationsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.model = {
            truck: null,
            customer: null,
            order: null,
            product: null,
            location: null,
            void: false,
            onHold: false,
            completed: false,
            inProgress: false
        };
    }
    TicketFiltersDialogComponent.prototype.ngOnInit = function () {
        this.getOrders();
        this.getCustomers();
        this.getTrucks();
        this.getProducts();
        this.getLocations();
    };
    TicketFiltersDialogComponent.prototype.onSelect = function (filterType, e) {
        this.model[filterType] = e;
    };
    TicketFiltersDialogComponent.prototype.submit = function () {
        this.callback(this.model);
        this.dialogRef.close();
    };
    TicketFiltersDialogComponent.prototype.getTrucks = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        this.trucks = [];
        this.trucksConfig.loadingOptions = true;
        this.trucksConfig = lodash_1.clone(this.trucksConfig);
        if (this.trucksReq) {
            this.trucksReq.unsubscribe();
        }
        this.trucksReq = this.ticketFilterService.listFilters('trucks', search).subscribe(function (trucks) {
            _this.trucks = trucks;
            if (_this.model.truck) {
                var selectedOption = lodash_1.find(_this.trucks, { id: _this.model.truck.id });
                _this.trucks = lodash_1.reject(_this.trucks, selectedOption);
                _this.trucks.unshift(_this.model.truck);
                _this.trucksDropdown.selectedOption = selectedOption;
            }
            _this.trucksDropdown.config.loadingOptions = false;
            _this.trucksConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
            _this.trucksDropdown.config.loadingOptions = false;
            _this.trucksConfig.loadingOptions = false;
            _this.trucksConfig = lodash_1.clone(_this.trucksConfig);
        });
    };
    TicketFiltersDialogComponent.prototype.getCustomers = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.customersReq && typeof this.customersReq.unsubscribe === 'function') {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.ticketFilterService.listFilters('customers', search).subscribe(function (customers) {
            _this.customers = customers;
            if (_this.model.customer) {
                var selectedOption = lodash_1.find(_this.customers, { id: _this.model.customer.id });
                _this.customers = lodash_1.reject(_this.customers, selectedOption);
                _this.customers.unshift(_this.model.customer);
                _this.customersDropdown.selectedOption = selectedOption;
            }
            _this.customersDropdown.config.loadingOptions = false;
            _this.customersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.customersConfig.loadingOptions = false;
        });
    };
    TicketFiltersDialogComponent.prototype.getOrders = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.ordersReq && typeof this.ordersReq.unsubscribe === 'function') {
            this.ordersReq.unsubscribe();
        }
        this.ordersReq = this.ticketFilterService.listFilters('purchaseorders', search).subscribe(function (orders) {
            _this.purchaseOrders = orders;
            if (_this.model.order) {
                var selectedOption = lodash_1.find(_this.purchaseOrders, { id: _this.model.order.id });
                _this.purchaseOrders = lodash_1.reject(_this.purchaseOrders, selectedOption);
                _this.purchaseOrders.unshift(_this.model.order);
                _this.ordersDropdown.selectedOption = selectedOption;
            }
            _this.ordersDropdown.config.loadingOptions = false;
            _this.ordersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.ordersConfig.loadingOptions = false;
        });
    };
    TicketFiltersDialogComponent.prototype.getProducts = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.productsReq && typeof this.productsReq.unsubscribe === 'function') {
            this.productsReq.unsubscribe();
        }
        this.productsReq = this.ticketFilterService.listFilters('products', search).subscribe(function (products) {
            _this.products = products;
            if (_this.model.product) {
                var selectedOption = lodash_1.find(_this.products, { id: _this.model.product.id });
                _this.products = lodash_1.reject(_this.products, selectedOption);
                _this.products.unshift(_this.model.product);
                _this.productsDropdown.selectedOption = selectedOption;
            }
            _this.productsDropdown.config.loadingOptions = false;
            _this.productsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.productsConfig.loadingOptions = false;
        });
    };
    TicketFiltersDialogComponent.prototype.getLocations = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        this.locationsReq = this.ticketFilterService.listFilters('locations', search).subscribe(function (locations) {
            _this.locations = locations;
            if (_this.model.location) {
                var selectedOption = lodash_1.find(_this.locations, { id: _this.model.location.id });
                _this.locations = lodash_1.reject(_this.locations, selectedOption);
                _this.locations.unshift(_this.model.location);
                _this.locationsDropdown.selectedOption = selectedOption;
            }
            _this.locationsDropdown.config.loadingOptions = false;
            _this.locationsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
            _this.locationsConfig.loadingOptions = false;
        });
    };
    TicketFiltersDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var filterType;
        var options;
        switch (type) {
            case 'customer':
                filterType = 'customers';
                config = this.customersConfig;
                options = this.customers;
                break;
            case 'truck':
                filterType = 'trucks';
                config = this.trucksConfig;
                options = this.trucks;
                break;
            case 'order':
                filterType = 'purchaseOrders';
                config = this.ordersConfig;
                options = this.purchaseOrders;
                break;
            case 'product':
                filterType = 'products';
                config = this.productsConfig;
                options = this.products;
                break;
            case 'location':
                filterType = 'locations';
                config = this.locationsConfig;
                options = this.locations;
                break;
        }
        if (!config.loadingOptions) {
            var o = this.ticketFilterService.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'customer':
                            _this.customers = _this.customers.concat(results);
                            break;
                        case 'truck':
                            _this.trucks = _this.trucks.concat(results);
                            break;
                        case 'order':
                            _this.purchaseOrders = _this.purchaseOrders.concat(results);
                            break;
                        case 'product':
                            _this.products = _this.products.concat(results);
                            break;
                        case 'location':
                            _this.locations = _this.locations.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    TicketFiltersDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'customer':
                this.getCustomers(term);
                break;
            case 'truck':
                this.getTrucks(term);
                break;
            case 'order':
                this.getOrders(term);
                break;
            case 'product':
                this.getProducts(term);
                break;
            case 'location':
                this.getLocations(term);
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    return TicketFiltersDialogComponent;
}());
exports.TicketFiltersDialogComponent = TicketFiltersDialogComponent;
