import { TicketFilter } from './ticket.filter';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class TicketFilterSerializer {
  fromJson(ticketFilterInfo: any): TicketFilter {
    ticketFilterInfo = camelcaseKeysDeep(ticketFilterInfo);
    const ticketFilter = new TicketFilter();
    if (!ticketFilterInfo) { return ticketFilter; }

    ticketFilter.id = ticketFilterInfo.id;
    ticketFilter.name = ticketFilterInfo.name;

    return ticketFilter;
  }
  toJson(ticketFilter: TicketFilter): any {
    let json = {
      id: ticketFilter.id,
      name: ticketFilter.name
    };

    return decamelizeKeysDeep(json);
  }
}
