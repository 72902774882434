"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tag_1 = require("./tag");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TagSerializer = /** @class */ (function () {
    function TagSerializer() {
    }
    /**
    * @param  {any} json
    * @returns Tag
    */
    TagSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var tag = new tag_1.Tag();
        if (!json) {
            return tag;
        }
        tag.id = json.id;
        tag.name = json.name;
        tag.itemCount = json.itemCount;
        tag.notes = json.notes;
        return tag;
    };
    /**
     * @param  {Tag} Tag
     * @returns any
     */
    TagSerializer.prototype.toJson = function (tag) {
        var json = {
            id: tag.id,
            name: tag.name,
            itemCount: tag.itemCount,
            notes: tag.notes,
        };
        return decamelizeKeysDeep(json);
    };
    return TagSerializer;
}());
exports.TagSerializer = TagSerializer;
