"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ngx_device_detector_1 = require("ngx-device-detector");
var rxjs_1 = require("rxjs");
var driver_1 = require("../driver");
var DriverDailyComponent = /** @class */ (function () {
    function DriverDailyComponent(deviceDetectorService) {
        this.deviceDetectorService = deviceDetectorService;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false,
        };
        this.search = '';
        this.loading = true;
        this.drivers = [];
        this.filtersApplied = false;
        this.selectedDriver = new core_1.EventEmitter();
        this.changedSearch = new core_1.EventEmitter();
        this.contextMenuOpened = false;
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop(),
        };
    }
    DriverDailyComponent.prototype.changeSearch = function (term) {
        this.search = term;
        this.changedSearch.emit(term);
    };
    DriverDailyComponent.prototype.selectDriver = function (driver) {
        this.driver = driver;
        this.selectedDriver.emit(driver);
    };
    DriverDailyComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuOpened = true;
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId,
        });
    };
    return DriverDailyComponent;
}());
exports.DriverDailyComponent = DriverDailyComponent;
