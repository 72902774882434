import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { each } from 'lodash';

import { environment } from '../../environments/environment';
import { requestHeaders } from '../shared/api.service';
import { EsbOrder } from './esb-order';
import { EsbOrderSerializer } from './esb-order.serializer';

@Injectable()
export class EsbService {
  baseUrl = environment.serverUrl;
  progress = 0;

  constructor(private http: HttpClient) { }

  import(scope: string, file: File): Observable<{ errors: any, orders: EsbOrder[] }> {
    const importUrl = `${this.baseUrl}imports/${scope}/`;

    return Observable.create(observer => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      formData.append('upload', file, file.name);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const parsedResponse = JSON.parse(xhr.response);
            observer.next(this.convertBulkResponse(parsedResponse));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };

      xhr.open('POST', importUrl, true);
      requestHeaders(xhr, true);
      xhr.send(formData);
    });
  }

  private convertBulkResponse(res: any): { errors: any, orders: EsbOrder[] } {
    let errors = [];
    let orders: EsbOrder[] = [];
    if (res && res.errors) {
      errors = res.errors.map(error => {
        return {
          row: error['row'],
          errors: this.rescurseErrorObject(error['errors'], [])
        };
      });
    }
    if (res && res.success) {
      orders = res.success.map(record => new EsbOrderSerializer().fromJson(record));
    }

    return { errors: errors, orders: orders };
  }

  rescurseErrorObject(obj, errors) {
    if (typeof obj === 'string') {
      errors.push(obj);
    } else {
      each(obj, (msg, key) => {
        if (Array.isArray(msg)) {
          errors = errors.concat(msg.map(err => (key === 'non_field_errors' ? '' : key + ': ') + err));
        } else if (typeof msg === 'string') {
          if (key !== 'non_field_errors') {
            try {
              errors.push(key + ': ' + msg);
            } catch (e) {
              errors.push(msg);
            }
          } else {
            errors.push(msg);
          }
        } else if (typeof msg === 'object') {
          errors = this.rescurseErrorObject(msg, errors);
        }
      });
    }
    return errors;
  }
}
