"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
// libraries
var ngx_device_detector_1 = require("ngx-device-detector");
var lodash_1 = require("lodash");
// angular material
var material_1 = require("@angular/material");
// services
var core_2 = require("@ngx-translate/core");
var driver_service_1 = require("./../driver.service");
var connection_service_1 = require("../../connections/connection.service");
var api_service_1 = require("../../shared/api.service");
var custom_field_service_1 = require("../../custom-fields/custom-field.service");
var shared_1 = require("../../shared");
// types
var custom_field_1 = require("../../custom-fields/custom-field");
var NewDriverDialogComponent = /** @class */ (function () {
    function NewDriverDialogComponent(dialogRef, driverService, deviceDetectorService, apiService, customFieldService, translate, authentiationService) {
        this.dialogRef = dialogRef;
        this.driverService = driverService;
        this.deviceDetectorService = deviceDetectorService;
        this.apiService = apiService;
        this.customFieldService = customFieldService;
        this.translate = translate;
        this.authentiationService = authentiationService;
        this.leasedMode = false;
        this.states = [];
        this.stateOptions = [];
        this.countryOptions = [];
        this.loading = false;
        this.model = { profile: {}, user: {}, tags: [], customFieldData: {} };
        this.errors = [];
        this.carriersConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'organization.carrier.id',
            selectText: this.translate.instant('Select Carrier'),
            loadingText: this.translate.instant('Loading Carriers...'),
            noResultsText: this.translate.instant('No Carriers'),
            service: connection_service_1.ConnectionService,
            serviceFunction: 'list',
            query: {
                ordering: 'organization__name',
                is_carrier: 'True',
                allow_dispatch: 'True',
            },
            prefilledOptions: [],
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop(),
        };
    }
    NewDriverDialogComponent.prototype.ngOnInit = function () {
        this.prefillCarrier();
        this.states = this.apiService.getStates();
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name,
            };
        });
        if (this.model && this.model.country) {
            this.selectCountry(this.model.country);
        }
        else {
            this.selectCountry('US');
        }
        if (this.carrierId) {
            this.model.carrier = this.carrierId;
        }
        this.getCustomFields();
    };
    NewDriverDialogComponent.prototype.prefillCarrier = function () {
        var organization = this.authentiationService.getOrganization();
        if (organization && organization.carrier) {
            this.carriersConfig.prefilledOptions.push(organization.carrier);
            if (this.carrierId) {
                this.model.carrier = this.carrierId;
            }
            else {
                this.carrierId = organization.carrier.id;
                this.model.carrier = organization.carrier.id;
            }
        }
    };
    NewDriverDialogComponent.prototype.getCustomFields = function () {
        var _this = this;
        this.customFieldService
            .getFieldsForKind(custom_field_1.CustomFieldKind.Driver)
            .subscribe(function (fields) {
            _this.customFields = fields;
        });
    };
    NewDriverDialogComponent.prototype.selectState = function (state) {
        this.model.state = state;
    };
    NewDriverDialogComponent.prototype.selectCountry = function (country) {
        this.stateOptions = lodash_1.filter(this.states, { country: country }).map(function (_state) {
            return {
                id: _state.abbreviation,
                name: _state.name,
            };
        });
        this.model.country = country;
    };
    NewDriverDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.driverService.save(this.model).subscribe(function () {
            _this.dialogRef.close();
            _this.callback();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    NewDriverDialogComponent.prototype.tagChange = function (tags) {
        this.model['tags'] = tags.map(function (t) { return t.name; });
    };
    NewDriverDialogComponent.prototype.selectCarrier = function (e) {
        this.model.carrier = e.id;
    };
    return NewDriverDialogComponent;
}());
exports.NewDriverDialogComponent = NewDriverDialogComponent;
