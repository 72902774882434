<div class="app-content connections-container">
  <my-toolbar title="{{ 'Connections' | translate }}"></my-toolbar>

  <action-bar>
    <div class="left-actions">
      <dropdown title="{{ 'All Connections' | translate }}" [selectedOption]="selectedType" [config]="{style:'action'}" [options]="actionDropdownOptions" (onSelect)="setSelectedOption($event)" class="connection-type-dropdown"></dropdown>
      <span class="action-divider">|</span>
      <a [routerLink]="['/connections', 'received']" routerLinkActive="active" class="received-link" translate>Received</a>
      <a [routerLink]="['/connections', 'sent']" routerLinkActive="active" class="sent-link" translate>Sent</a>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>

      <button class="btn btn-primary" (click)="openDialog('invite')">
        <span class="icon-plus"></span>
        <ng-container translate>Add Connection</ng-container>
      </button>
    </div>
  </action-bar>

  <div class="list-table">
    <div class="table-body">
      <banner *ngIf="connectionType" (inviteConnection)="openDialog('invite')" (findConnection)="openDialog('search')"></banner>

      <ruckit-connections-list [search]="search" (changeSearchEmitter)="changeSearch()" (ngModelChange)="changeSearch()" (noConnections)="noConnections = $event" [type]="type" *ngIf="connectionType"></ruckit-connections-list>

      <ruckit-invitations *ngIf="!connectionType" (ngModelChange)="changeSearch()" [type]="type"></ruckit-invitations>
    </div>
  </div>
</div>
