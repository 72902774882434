"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var location_stat_1 = require("./location-stat");
var location_serializer_1 = require("./location.serializer");
var resource_service_1 = require("../shared/resource.service");
var index_1 = require("../shared/index");
var environment_1 = require("../../environments/environment");
var LocationService = /** @class */ (function (_super) {
    __extends(LocationService, _super);
    function LocationService(http) {
        var _this = _super.call(this, http, 'locations/', new location_serializer_1.LocationSerializer()) || this;
        _this.baseUrl = environment_1.environment.serverUrl;
        _this.mapsApiKey = environment_1.environment.googleMapsKey;
        return _this;
    }
    LocationService.prototype.archive = function (location) {
        var locationUrl = this.baseUrl + 'locations/';
        return this.http.put(locationUrl + location.id + '/', { archived: true }, { headers: index_1.requestHeaders() });
    };
    LocationService.prototype.unarchive = function (location) {
        var locationUrl = this.baseUrl + 'locations/';
        return this.http.put(locationUrl + location.id + '/', { archived: false }, { headers: index_1.requestHeaders() });
    };
    LocationService.prototype.averageTimeSpent = function (query) {
        var _this = this;
        var params = new http_1.HttpParams();
        if (query && typeof query === 'object') {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var locationsUrl = this.baseUrl + "locations/average_time_spent/?" + params.toString();
        return this.http.get(locationsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractStatData(res); }), operators_1.catchError(function (res) { return index_1.handleError(res); }));
    };
    LocationService.prototype.getLocationByIP = function () {
        return this.http.get('https://ipapi.co/json/').pipe(operators_1.map(function (res) { return res; }));
    };
    LocationService.prototype.getLocationByAddress = function (address) {
        var geocodeURL = 'https://maps.googleapis.com/maps/api/geocode/json';
        geocodeURL += '?address=' + address;
        geocodeURL += '&key=' + this.mapsApiKey;
        return this.http.get(geocodeURL).pipe(operators_1.map(function (res) {
            var resBody = res;
            return resBody['results'] || resBody;
        }), operators_1.catchError(function (res) { return index_1.handleError(res); }));
    };
    LocationService.prototype.getAddressByCoordinates = function (coordinates) {
        var geocodeURL = 'https://maps.googleapis.com/maps/api/geocode/json';
        geocodeURL += '?latlng=' + coordinates;
        geocodeURL += '&key=' + this.mapsApiKey;
        return this.http.get(geocodeURL).pipe(operators_1.map(function (res) {
            var resBody = res;
            return resBody['results'] || resBody;
        }), operators_1.catchError(function (res) { return index_1.handleError(res); }));
    };
    LocationService.prototype.getLocationTimezone = function (coordinates) {
        var timezoneURL = 'https://maps.googleapis.com/maps/api/timezone/json';
        var now = new Date();
        var params = new http_1.HttpParams();
        params = params.set('location', coordinates);
        params = params.set('timestamp', now.getUTCSeconds().toString());
        params = params.set('key', this.mapsApiKey);
        return this.http.get(timezoneURL, {
            params: params
        }).pipe(operators_1.map(function (res) { return res; }), operators_1.catchError(function (res) { return index_1.handleError(res); }));
    };
    LocationService.prototype.extractStatData = function (res) {
        var resObj = res;
        this.nextUri = resObj['next'];
        var body = resObj['results'];
        this.count = resObj['count'];
        if (body) {
            return body.map(function (location) {
                return new location_stat_1.LocationStat(location);
            });
        }
        else if (resObj) {
            return new location_stat_1.LocationStat(resObj);
        }
    };
    return LocationService;
}(resource_service_1.ResourceService));
exports.LocationService = LocationService;
