"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ngx_device_detector_1 = require("ngx-device-detector");
var lodash_1 = require("lodash");
var moment = require("moment");
var shared_1 = require("../../shared");
var dialogs_1 = require("../../shared/dialogs/");
var authentication_service_1 = require("./../../shared/authentication.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var condensed_job_event_service_1 = require("../../job-events/condensed-job-event.service");
var job_event_stat_service_1 = require("../../job-event-stats/job-event-stat.service");
var condensed_job_event_1 = require("../../job-events/condensed-job-event");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var carrier_dispatch_dialog_component_1 = require("../../dispatch/carrier-dispatch/carrier-dispatch-dialog.component");
var move_assignments_dialog_component_1 = require("../../assignments/move-assignments/move-assignments-dialog.component");
var assignment_service_1 = require("../../assignments/assignment.service");
var DailyBoardGridComponent = /** @class */ (function () {
    function DailyBoardGridComponent(route, router, authenticationService, condensedJobEventService, jobEventService, jobEventStatService, collaboratorService, assignmentService, deviceDetectorService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.condensedJobEventService = condensedJobEventService;
        this.jobEventService = jobEventService;
        this.jobEventStatService = jobEventStatService;
        this.collaboratorService = collaboratorService;
        this.assignmentService = assignmentService;
        this.deviceDetectorService = deviceDetectorService;
        this.dialog = dialog;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.hideCancelled = true;
        this.filters = [];
        this.search = '';
        this.jobEventCount = new core_1.EventEmitter();
        this.jobEvents = [];
        this.jobEvent = new condensed_job_event_1.CondensedJobEvent();
        this.jobEventStatuses = [
            {
                action: function (jobEvent) { return _this.changeStatus('cancel', jobEvent); },
                name: 'Cancel Job'
            }
        ];
        this.shares = [];
        this.loading = true;
        this.errors = [];
        this.count = 0;
        this.canCreateJobs = true;
        this.sortAsc = true;
        this.statsReqs = [];
        this.statsReqLoading = false;
        this.polylineOptions = {
            strokeColor: '#002649',
            strokeWeight: '4',
            strokeOpacity: 0.6
        };
        this.displayAsGrid = true;
        this.enabledFeatures = [];
        this.filterOptions = [
            { value: 'active', name: 'Active Jobs' },
            { value: 'cancelled', name: 'Cancelled Jobs' },
            { value: 'all', name: 'All Jobs' }
        ];
        this.carrierMode = false;
        this.jobActionsDropdownOptions = [
            { name: 'Edit Dispatches' },
            { name: 'Move to New Job' },
            { name: 'Reject Job' }
        ];
        this.actionsDropdownConfig = {
            nameProperty: 'name',
            showLoading: false
        };
    }
    DailyBoardGridComponent.prototype.ngOnInit = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.canCreateJobs = this.authenticationService.canCreateJobs();
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        this.carrierMode = !this.enabledFeatures.includes('ruckitBilling');
        this.isAcceptedCountRequired = this.authenticationService.getOrgPropertyValue('driverFleetRequireAssignmentConfirmation');
        this.getJobs();
    };
    DailyBoardGridComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.statsReqs && this.statsReqs.length) {
            try {
                this.statsReqs.forEach(function (req) {
                    try {
                        if (req && typeof req.unsubscribe === 'function') {
                            req.unsubscribe();
                        }
                    }
                    catch (e) { }
                });
            }
            catch (e) { }
        }
    };
    DailyBoardGridComponent.prototype.ngOnChanges = function (changes) {
        var jobEventDateStart = new Date();
        jobEventDateStart.setHours(0, 0, 0, 0);
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        if (changes['jobEventDate']) {
            var jobEventDate = changes['jobEventDate'].currentValue;
            if (jobEventDate) {
                jobEventDateStart = new Date(this.jobEventDate);
                jobEventDateStart.setHours(0, 0, 0, 0);
                jobEventDateEnd = lodash_1.clone(jobEventDateStart);
                jobEventDateEnd.setHours(23, 59, 59, 999);
            }
        }
        this.getJobs();
        this.jobEventCount.emit(this.count);
    };
    DailyBoardGridComponent.prototype.getJobs = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        if (!append) {
            this.jobEvents = [];
        }
        var filters = this.filters.filter(Boolean).reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var jobEventDateStart = new Date();
        jobEventDateStart.setHours(0, 0, 0, 0);
        var jobEventDateEnd = lodash_1.clone(jobEventDateStart);
        jobEventDateEnd.setHours(23, 59, 59, 999);
        if (this.jobEventDate) {
            jobEventDateStart = new Date(this.jobEventDate);
            jobEventDateStart.setHours(0, 0, 0, 0);
            jobEventDateEnd = lodash_1.clone(jobEventDateStart);
            jobEventDateEnd.setHours(23, 59, 59, 999);
        }
        this.statsReqLoading = true;
        this.jobEventsReq = this.condensedJobEventService.list(__assign({ ordering: order, search: this.search, exclude_pending: this.carrierMode ? 'False' : 'True', exclude_cf: 'True', shift1_start__gte: jobEventDateStart.toISOString(), shift1_start__lte: jobEventDateEnd.toISOString(), cancelled: this.hideCancelled && !this.carrierMode ? 'False' : undefined }, filters, query)).pipe(operators_1.mergeMap(function (jobEvents) {
            _this.count = _this.condensedJobEventService.count;
            if (jobEvents && !Array.isArray(jobEvents)) {
                jobEvents = [jobEvents];
            }
            var effectiveRateCalculator = '';
            if (_this.enabledFeatures && _this.enabledFeatures.includes('effectiveRateCalculator')) {
                effectiveRateCalculator = _this.authenticationService.getFeature('effectiveRateCalculator');
            }
            return rxjs_1.forkJoin(jobEvents.map(function (jobEvent) {
                return _this.jobEventStatService.getStats(jobEvent.id, {
                    calculator: effectiveRateCalculator
                }).pipe(operators_1.catchError(function () { return rxjs_1.of(null); }));
            })).pipe(operators_1.map(function (jobEventStats) {
                jobEvents.forEach(function (jobEvent, index) {
                    jobEvent.stats = jobEventStats[index];
                });
                return jobEvents;
            }), operators_1.defaultIfEmpty([]));
        }), operators_1.tap(function (jobEvents) {
            if (append) {
                _this.jobEvents = _this.jobEvents.concat(jobEvents);
            }
            else {
                _this.jobEvents = jobEvents;
            }
        }), operators_1.switchMap(function (jobEvents) {
            if (_this.carrierMode) {
                var query_1 = {
                    ordering: 'jobevent__job__name',
                    start__gte: jobEventDateStart.toISOString(),
                    start__lte: jobEventDateEnd.toISOString()
                };
                if (_this.search) {
                    query_1['search'] = _this.search;
                }
                return _this.collaboratorService.getShares(query_1).pipe(operators_1.tap(function (shares) { return _this.shares = shares; }));
            }
            else {
                return rxjs_1.of(jobEvents);
            }
        })).subscribe(function () {
            _this.loading = false;
            _this.statsReqLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
            _this.statsReqLoading = false;
        });
    };
    DailyBoardGridComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getJobs({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    DailyBoardGridComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.condensedJobEventService.listNext();
            if (o) {
                this.loading = true;
                this.statsReqLoading = true;
                o.pipe(operators_1.mergeMap(function (jobEvents) {
                    var effectiveRateCalculator = '';
                    if (_this.enabledFeatures && _this.enabledFeatures.includes('effectiveRateCalculator')) {
                        effectiveRateCalculator = _this.authenticationService.getFeature('effectiveRateCalculator');
                    }
                    return rxjs_1.forkJoin(jobEvents.map(function (jobEvent) {
                        return _this.jobEventStatService.getStats(jobEvent.id, {
                            calculator: effectiveRateCalculator
                        }).pipe(operators_1.catchError(function () { return rxjs_1.of(null); }));
                    })).pipe(operators_1.map(function (jobEventStats) {
                        jobEvents.forEach(function (jobEvent, index) {
                            jobEvent.stats = jobEventStats[index];
                        });
                        return jobEvents;
                    }));
                }), operators_1.tap(function (jobEvents) { return _this.jobEvents = _this.jobEvents.concat(jobEvents); }), operators_1.switchMap(function (jobEvents) {
                    var nextShares = _this.collaboratorService.getNext();
                    if (_this.carrierMode && nextShares) {
                        return nextShares.pipe(operators_1.tap(function (shares) { return _this.shares = _this.shares.concat(shares); }));
                    }
                    else {
                        return rxjs_1.of(jobEvents);
                    }
                })).subscribe(function () {
                    _this.loading = false;
                    _this.statsReqLoading = false;
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                    _this.statsReqLoading = false;
                });
            }
        }
    };
    DailyBoardGridComponent.prototype.selectJobEvent = function (e, jobEvent) {
        var target = e.target || e.srcElement || e.currentTarget;
        if (target && jobEvent && !this.carrierMode && !this.device.mobile) {
            this.router.navigate(['/jobs/' + jobEvent.jobId + '/' + jobEvent.id]);
        }
    };
    DailyBoardGridComponent.prototype.changeStatus = function (status, jobEvent) {
        var _this = this;
        var jobEventUpdates = { id: jobEvent.id };
        switch (status) {
            case 'cancel':
                jobEventUpdates.cancelled = true;
                break;
        }
        this.jobEventService.save(jobEventUpdates).subscribe(function () {
            _this.getJobs();
        }, function (err) { _this.errors = err; });
    };
    DailyBoardGridComponent.prototype.isSelected = function (jobEvent) {
        return jobEvent.id === this.jobEvent.id;
    };
    DailyBoardGridComponent.prototype.openCarrierDispatchDialog = function (jobEvent) {
        var _this = this;
        var dialog = this.dialog.open(carrier_dispatch_dialog_component_1.CarrierDispatchDialogComponent, {
            width: '1040px'
        });
        dialog.componentInstance.jobEventId = jobEvent.id;
        dialog.componentInstance.share = this.shares.find(function (s) { return (s.jobEventId === jobEvent.id); });
        dialog.componentInstance.callback = function () { return _this.getJobs(); };
    };
    DailyBoardGridComponent.prototype.rejectShare = function (jobEventId) {
        var _this = this;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Are you sure?',
            body: 'This job will be hidden if rejected!',
            close: 'Cancel',
            accept: 'Reject Job'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                var share = _this.shares.find(function (s) { return (s.jobEventId === jobEventId); });
                if (share) {
                    _this.collaboratorService.reject(share.id).subscribe(function () {
                        _this.getJobs();
                    }, function (err) { return _this.errors = err; });
                }
            }
            _this.confirmDialog = null;
        });
    };
    DailyBoardGridComponent.prototype.setJobAction = function (option, jobEvent) {
        switch (option.name) {
            case 'Edit Dispatches':
                this.openCarrierDispatchDialog(jobEvent);
                break;
            case 'Move to New Job':
                this.modifyAssignments(jobEvent);
                break;
            case 'Copy to New Job':
                this.modifyAssignments(jobEvent, true);
                break;
            case 'Remove Assignments':
                this.removeAssignments(jobEvent);
                break;
            case 'Reject Job':
                this.rejectShare(jobEvent.id);
        }
        this.actionsDropdown.deselectAll();
    };
    DailyBoardGridComponent.prototype.selectFilter = function (filter) {
        var query = {};
        switch (filter) {
            case 'active':
                query = { exclude_pending: 'True' };
                break;
            case 'cancelled':
                query = { cancelled: 'True' };
                break;
            case 'all':
                break;
        }
        this.getJobs(query);
    };
    DailyBoardGridComponent.prototype.modifyAssignments = function (jobEvent, copy) {
        var _this = this;
        if (copy === void 0) { copy = false; }
        var dialog = this.dialog.open(move_assignments_dialog_component_1.MoveAssignmentsDialogComponent, { width: '850px' });
        if (dialog) {
            var convertedJobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
            dialog.componentInstance.job = convertedJobEvent.job;
            dialog.componentInstance.start = moment(this.jobEventDate).startOf('day').toDate();
            dialog.componentInstance.jobEvent = convertedJobEvent;
            dialog.componentInstance.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
            dialog.componentInstance.trucksEditable = true;
            dialog.componentInstance.copy = copy;
            dialog.componentInstance.callback = function () { return _this.getJobs(); };
        }
    };
    DailyBoardGridComponent.prototype.removeAssignments = function (jobEvent) {
        var _this = this;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Are you sure?',
            body: 'You are about to remove all Assignments for this Job Day',
            close: 'Cancel',
            accept: 'Remove'
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.assignmentService.listAll(10, { jobevent: jobEvent.id }).pipe(operators_1.first()).subscribe(function (assignments) {
                    _this.assignmentService.bulkRemove(assignments.map(function (a) { return (a.id); })).pipe(operators_1.first()).subscribe(function () {
                        _this.getJobs();
                    }, function (err) { return _this.errors = shared_1.parseErrors(err); });
                }, function (err) { return _this.errors = shared_1.parseErrors(err); });
            }
            _this.confirmDialog = null;
        });
    };
    return DailyBoardGridComponent;
}());
exports.DailyBoardGridComponent = DailyBoardGridComponent;
