"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment-timezone");
var resizer_1 = require("../images/resizer");
var lodash_1 = require("lodash");
var driver_serializer_1 = require("./../drivers/driver.serializer");
var checkin_1 = require("../checkins/checkin");
var app_constants_1 = require("../app.constants");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var CondensedTrip = /** @class */ (function () {
    function CondensedTrip(tripInfo) {
        this.selected = false;
        this.invoiceSent = false;
        this.loading = false;
        this.expenseSent = false;
        this.paystubSent = false;
        tripInfo = camelcaseKeysDeep(tripInfo);
        this.id = tripInfo.id;
        this.date = tripInfo.date;
        this.jobDate = tripInfo.jobDate;
        this.job = tripInfo.job;
        this.jobDisplayName = tripInfo.jobDisplayName;
        this.jobId = tripInfo.jobId;
        this.jobEventId = tripInfo.jobeventId;
        this.phaseCode = tripInfo.phaseCode;
        this.project = tripInfo.project;
        this.projectId = tripInfo.projectId;
        this.customer = tripInfo.customer;
        this.customerId = tripInfo.customerId;
        this.customerPhone = tripInfo.customerPhone;
        this.driver = tripInfo.driver;
        this.driverId = tripInfo.driverId;
        this.driverPhone = tripInfo.driverPhone;
        this.driverImage = tripInfo.driverImage;
        this.customDriver = new driver_serializer_1.DriverSerializer().fromJson({
            id: this.driverId,
            customName: this.driver,
            phoneNumber: this.driverPhone,
            image: this.driverImage,
            carrierName: tripInfo.carrier,
        });
        this.truck = tripInfo.truck;
        this.truckId = tripInfo.truckId;
        this.truckImage = tripInfo.truckImage;
        this.truckType = tripInfo.truckType;
        this.payableTo = tripInfo.payableTo;
        this.payableToId = tripInfo.payableToId;
        this.carrier = tripInfo.carrier;
        this.carrierId = tripInfo.carrierId;
        this.payableToPhone = tripInfo.payableToPhone;
        this.loadingTicketNumber = tripInfo.loadingTicketNumber;
        this.loadingWeight = tripInfo.loadingWeight;
        this.loadingTicketImage = tripInfo.loadingTicketImage;
        this.loadingTicketImageKey = tripInfo.loadingTicketImageKey;
        this.loadingSignature = tripInfo.loadingSignature;
        this.loadingSignatureKey = tripInfo.loadingSignatureKey;
        this.unloadingTicketNumber = tripInfo.unloadingTicketNumber;
        this.unloadingWeight = tripInfo.unloadingWeight;
        this.unloadingTicketImage = tripInfo.unloadingTicketImage;
        this.unloadingTicketImageKey = tripInfo.unloadingTicketImageKey;
        this.unloadingSignature = tripInfo.unloadingSignature;
        this.unloadingSignatureKey = tripInfo.unloadingSignatureKey;
        this.startTimeTimestamp = tripInfo.startTime;
        this.endTimeTimestamp = tripInfo.endTime;
        this.invoiceRate = tripInfo.invoiceRate;
        this.invoiceType = tripInfo.invoiceType;
        this.invoiceWeightUnit = tripInfo.invoiceWeightUnit || 'ton';
        this.invoiceQuantity = tripInfo.invoiceQuantity;
        this.haulRate = tripInfo.haulRate;
        this.haulType = tripInfo.haulType;
        this.haulWeightUnit = tripInfo.haulWeightUnit || 'ton';
        this.routePolyline = tripInfo.routePolyline;
        this.retakeStatus = tripInfo.retakeStatus;
        this.edited = tripInfo.edited;
        this.completed = tripInfo.completed;
        this.tripStatus = tripInfo.tripStatus;
        this.reviewedStatus = tripInfo.reviewedStatus;
        this.reviewedBy = tripInfo.reviewedBy;
        this.reviewedAt = tripInfo.reviewedAt;
        this.checkinOptions = tripInfo.checkinOptions;
        this.checkoutOptions = tripInfo.checkoutOptions;
        this.invoiceTotal = tripInfo.invoiceTotal;
        this.haulTotal = tripInfo.haulTotal;
        this.void = tripInfo.void;
        this.expenseId = tripInfo.expenseId;
        this.expenseNumber = tripInfo.expenseNumber;
        this.expenseSent = tripInfo.expenseSent;
        this.expenseTotal = tripInfo.expenseTotal;
        this.paystubId = tripInfo.paystubId;
        this.paystubNumber = tripInfo.paystubNumber;
        this.paystubSent = tripInfo.paystubSent;
        this.invoiceId = tripInfo.invoiceId;
        this.invoiceNumber = tripInfo.invoiceNumber;
        this.invoiceSent = tripInfo.invoiceSent;
        this.weight = tripInfo.weight;
        this.ticketNumber = tripInfo.ticketNumber;
        this.timezone = tripInfo.timezone || moment.tz.guess() || 'America/Chicago';
        this.origin = tripInfo.origin;
        this.loadingCompleteDatetime = tripInfo.localizedLoadingCompleteDatetime;
        this.unloadingCompleteDatetime =
            tripInfo.localizedUnloadingCompleteDatetime;
        this.loadingAttachments = tripInfo.loadingAttachments || [];
        this.unloadingAttachments = tripInfo.unloadingAttachments || [];
        this.loadingArrivalDatetime = tripInfo.loadingArrivalDatetime;
        this.unloadingArrivalDatetime = tripInfo.unloadingArrivalDatetime;
        this.localizedEndDate = tripInfo.localizedEndDate;
        this.localizedEndDatetime = tripInfo.localizedEndDatetime;
        this.localizedEndTime = tripInfo.localizedEndTime;
        this.localizedStartDate = tripInfo.localizedStartDate;
        this.localizedStartDatetime = tripInfo.localizedStartDatetime;
        this.localizedStartTime = tripInfo.localizedStartTime;
        this.plantQc = tripInfo.plantQc;
        this.plantQcReviewer = tripInfo.plantQcReviewer;
        this.plantQcWasteAmt = tripInfo.plantQcWasteAmt;
        this.plantVt = tripInfo.plantVt;
        this.plantVtReviewer = tripInfo.plantVtReviewer;
        this.plantVtWasteAmt = tripInfo.plantVtWasteAmt;
        this.roadwayQc = tripInfo.roadwayQc;
        this.roadwayQcReviewer = tripInfo.roadwayQcReviewer;
        this.roadwayQcWasteAmt = tripInfo.roadwayQcWasteAmt;
        this.roadwayVt = tripInfo.roadwayVt;
        this.roadwayVtReviewer = tripInfo.roadwayVtReviewer;
        this.roadwayVtWasteAmt = tripInfo.roadwayVtWasteAmt;
        this.startLocationId = tripInfo.startLocationId;
        this.startLocationName = tripInfo.startLocationName;
        this.endLocationId = tripInfo.endLocationId;
        this.endLocationName = tripInfo.endLocationName;
        this.geoStartLocationId = tripInfo.geoStartLocationId;
        this.geoStartLocationName = tripInfo.geoStartLocationName;
        this.geoEndLocationId = tripInfo.geoEndLocationId;
        this.geoEndLocationName = tripInfo.geoEndLocationName;
        this.connexPaverStartUnloading = tripInfo.connexPaverStartUnloading;
        this.connexPaverEndUnloading = tripInfo.connexPaverEndUnloading;
        // multiple images backwards compability
        if (this.loadingTicketImage === null &&
            this.loadingAttachments &&
            this.loadingAttachments.length) {
            var attachment = this.loadingAttachments.find(function (att) { return att.fileType === app_constants_1.TRIPTICKETIMAGETYPE; });
            this.loadingTicketImage = attachment ? attachment.file : null;
        }
        if (this.unloadingTicketImage === null &&
            this.unloadingAttachments &&
            this.unloadingAttachments.length) {
            var attachment = this.unloadingAttachments.find(function (att) { return att.fileType === app_constants_1.TRIPTICKETIMAGETYPE; });
            this.unloadingTicketImage = attachment ? attachment.file : null;
        }
        if (this.loadingTicketImage &&
            this.loadingAttachments &&
            !this.loadingAttachments.length) {
            this.loadingAttachments.push({
                file: this.loadingTicketImage,
                fileType: app_constants_1.TRIPTICKETIMAGETYPE,
            });
        }
        if (this.unloadingTicketImage &&
            this.unloadingAttachments &&
            !this.unloadingAttachments.length) {
            this.unloadingAttachments.push({
                file: this.unloadingTicketImage,
                fileType: app_constants_1.TRIPTICKETIMAGETYPE,
            });
        }
        if (this.loadingSignature && this.loadingAttachments) {
            this.loadingAttachments.push({
                file: this.loadingSignature,
                fileType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
            });
        }
        if (this.unloadingSignature && this.unloadingAttachments) {
            this.unloadingAttachments.push({
                file: this.unloadingSignature,
                fileType: app_constants_1.TRIPSIGNATUREIMAGETYPE,
            });
        }
        // END multiple images backwards compability
        if (tripInfo.loadingArrivalDatetime) {
            var localizedArrivalLoading = moment
                .utc(tripInfo.loadingArrivalDatetime)
                .tz(this.timezone);
            var duration = moment.duration(moment(localizedArrivalLoading).diff(this.startDate));
            this._loadingArrivalDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (tripInfo.loadingCompleteDatetime) {
            var localizedCompleteLoading = moment
                .utc(tripInfo.loadingCompleteDatetime)
                .tz(this.timezone);
            var duration = moment.duration(moment(localizedCompleteLoading).diff(tripInfo.loadingArrivalDatetime));
            this._loadingCompleteDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (tripInfo.unloadingArrivalDatetime) {
            var localizedArrivalUnLoading = moment
                .utc(tripInfo.unloadingArrivalDatetime)
                .tz(this.timezone);
            var duration = moment.duration(moment(localizedArrivalUnLoading).diff(tripInfo.loadingCompleteDatetime));
            this._unloadingArrivalDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (tripInfo.unloadingCompleteDatetime) {
            var localizedCompleteUnLoading = moment
                .utc(tripInfo.unloadingCompleteDatetime)
                .tz(this.timezone);
            var duration = moment.duration(moment(localizedCompleteUnLoading).diff(tripInfo.unloadingArrivalDatetime));
            this._unloadingCompleteDatetime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        this.loadingCheckin = new checkin_1.Checkin(tripInfo.loadingCheckin || { kind: 'loading', trip: this.id });
        this.unloadingCheckin = new checkin_1.Checkin(tripInfo.unloadingCheckin || { kind: 'unloading', trip: this.id });
        var values = [];
        if (this.job) {
            values.push(this.job);
        }
        if (this.driver) {
            var value = this.driver;
            if (this.truckName) {
                value = value + " (" + this.truck + ")";
            }
            values.push(value);
        }
        this.name = values.filter(Boolean).join(' - ');
        this.reviewerNotes = tripInfo.reviewerNotes;
    }
    CondensedTrip.prototype.formatDates = function () {
        var duration;
        if (this._endTimeTimestamp) {
            duration = moment.duration(moment(this._endTimeTimestamp).diff(this._startTimeTimestamp));
        }
        else {
            duration = moment.duration(moment().diff(this.startTimeTimestamp));
        }
        this._completedTripTime = duration.format('D[ days], H[ hrs], m[ mins]');
        if (this._startTimeTimestamp) {
            this._startDate = new Date(this._startTimeTimestamp);
            if (!this._startTime) {
                this._startTime = moment(this._startDate).format('h:mm a');
            }
        }
        if (this._endTimeTimestamp) {
            this._endDate = new Date(this._endTimeTimestamp);
            if (!this._endTime) {
                this._endTime = moment(this._endDate).format('h:mm a');
            }
        }
        if (this._loadingCompleteDatetime) {
            this._loadingCompleteDatetime = moment(this._loadingCompleteDatetime)
                .tz(this.timezone)
                .toDate();
        }
        if (this._unloadingCompleteDatetime) {
            this._unloadingCompleteDatetime = moment(this._unloadingCompleteDatetime)
                .tz(this.timezone)
                .toDate();
        }
    };
    Object.defineProperty(CondensedTrip.prototype, "startTimeTimestamp", {
        get: function () {
            return this._startTimeTimestamp;
        },
        set: function (value) {
            this._startTimeTimestamp = value;
            this.formatDates();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "endTimeTimestamp", {
        get: function () {
            return this._endTimeTimestamp;
        },
        set: function (value) {
            this._endTimeTimestamp = value;
            this.formatDates();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "completedTripTime", {
        get: function () {
            return this._completedTripTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "startTime", {
        get: function () {
            return this._startTime;
        },
        set: function (value) {
            if (value) {
                var pattern = value.match(/[ap]m/i)
                    ? 'YYYY-MM-DD h:mm a'
                    : 'YYYY-MM-DD h:mm';
                var date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
                var parsedDate = moment(date + ' ' + value, pattern);
                this.startTimeTimestamp = parsedDate.toISOString();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "endTime", {
        get: function () {
            return this._endTime;
        },
        set: function (value) {
            if (value) {
                var pattern = value.match(/[ap]m/i)
                    ? 'YYYY-MM-DD h:mm a'
                    : 'YYYY-MM-DD h:mm';
                var date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
                var parsedDate = moment(date + ' ' + value, pattern);
                this.endTimeTimestamp = parsedDate.toISOString();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "startDate", {
        get: function () {
            return this._startDate;
        },
        set: function (value) {
            var _value = moment(value).format('YYYY-MM-DD');
            this.startTimeTimestamp = moment(_value + ' ' + this.startTime, 'YYYY-MM-DD h:mm a').toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "endDate", {
        get: function () {
            return this._endDate;
        },
        set: function (value) {
            if (value) {
                var _value = moment(value).format('YYYY-MM-DD');
                this.endTimeTimestamp = moment(_value + ' ' + this.endTime, 'YYYY-MM-DD h:mm a').toISOString();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "duration", {
        get: function () {
            return this._duration;
        },
        set: function (value) {
            this._duration = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "schedule", {
        get: function () {
            var values = [];
            if (this.startTime && this.startTime.length) {
                values.push(this.startTime);
            }
            if (this.endTimeTimestamp && this.endTimeTimestamp.length) {
                values.push(this.endTime);
            }
            else {
                values.push('OPEN');
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "signatureImagesRequired", {
        get: function () {
            if (this.checkinOptions.signatureImage ||
                this.checkoutOptions.signatureImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasSignatureImages", {
        get: function () {
            if ((this.checkinOptions.signatureImage && !this.loadingSignature) ||
                (this.checkoutOptions.signatureImage && !this.unloadingSignature)) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "loadingSignatureImagesRequired", {
        get: function () {
            if (this.checkinOptions.signatureImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasLoadingSignatureImages", {
        get: function () {
            if (this.checkinOptions.signatureImage && !this.loadingSignature) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "inlineLoadingSignature", {
        get: function () {
            if (this.loadingSignature && this.loadingSignatureKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.loadingSignatureKey, 24, 24);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "inlineLoadingTicket", {
        get: function () {
            if (this.loadingTicketImage && this.loadingTicketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.loadingTicketImageKey, 28, 28);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "unloadingSignatureImagesRequired", {
        get: function () {
            if (this.checkoutOptions.signatureImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasUnloadingSignatureImages", {
        get: function () {
            if (this.checkoutOptions.signatureImage && !this.unloadingSignature) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "inlineUnloadingSignature", {
        get: function () {
            if (this.unloadingSignature && this.unloadingSignatureKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.unloadingSignatureKey, 24, 24);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "inlineUnloadingTicket", {
        get: function () {
            if (this.unloadingTicketImage && this.unloadingTicketImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.unloadingTicketImageKey, 28, 28);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "ticketImagesRequired", {
        get: function () {
            return this.checkinOptions.ticketImage || this.checkoutOptions.ticketImage
                ? true
                : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasTicketImages", {
        get: function () {
            return this.loadingTicketImage || this.unloadingTicketImage ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasBothTickets", {
        get: function () {
            if ((this.loadingTicketNumber ||
                this.loadingTicketImage ||
                this.checkinOptions.ticketNumber ||
                this.checkinOptions.ticketImage) &&
                (this.unloadingTicketNumber ||
                    this.unloadingTicketImage ||
                    this.checkinOptions.ticketNumber ||
                    this.checkoutOptions.ticketImage)) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "loadingTicketImagesRequired", {
        get: function () {
            return this.checkinOptions.ticketImage ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasLoadingTicketImages", {
        get: function () {
            return this.loadingTicketImage ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "unloadingTicketImagesRequired", {
        get: function () {
            return this.checkoutOptions.ticketImage ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "hasUnloadingTicketImages", {
        get: function () {
            return this.unloadingTicketImage ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "administrativeStatus", {
        get: function () {
            if (this.void) {
                return 'void';
            }
            else if (this.retakeStatus === 'requested') {
                return 'retake';
            }
            else if (this.edited) {
                return 'edited';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus && this.administrativeStatus.length) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "retakeRequested", {
        get: function () {
            if (this.retakeStatus && this.retakeStatus === 'requested') {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "ticketNumbers", {
        get: function () {
            var values = [];
            if (this.loadingTicketNumber && this.loadingTicketNumber.length) {
                values.push(this.loadingTicketNumber);
            }
            if (this.unloadingTicketNumber && this.unloadingTicketNumber.length) {
                values.push(this.unloadingTicketNumber);
            }
            return values.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "truckName", {
        get: function () {
            var values = [];
            if (this.truck && this.truck.length) {
                values.push(this.truck);
            }
            if (this.truckType && this.truckType.length) {
                values.push(this.truckType);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "invoiceTypeName", {
        get: function () {
            switch (this.invoiceType) {
                case 'weight':
                    return this.invoiceWeightUnitDisplay;
                default:
                    return this.invoiceType;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "haulTypeName", {
        get: function () {
            switch (this.haulType) {
                case 'weight':
                    return this.haulWeightUnitDisplay;
                default:
                    return this.haulType;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "invoiceRateUnit", {
        get: function () {
            if (this.invoiceType === 'weight') {
                switch (this.invoiceWeightUnit) {
                    case 'cuyds':
                        return 'cu. yd.';
                    default:
                        return this.invoiceWeightUnit;
                }
            }
            else {
                return this.invoiceType;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "haulRateUnit", {
        get: function () {
            if (this.haulType === 'weight') {
                switch (this.haulWeightUnit) {
                    case 'cuyds':
                        return 'cu. yd.';
                    default:
                        return this.haulWeightUnit;
                }
            }
            else {
                return this.haulType;
            }
        },
        enumerable: true,
        configurable: true
    });
    CondensedTrip.prototype.pluralizedInvoiceRateUnit = function (plural) {
        if (plural === void 0) { plural = true; }
        if (this.invoiceType === 'weight') {
            switch (this.invoiceWeightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
            }
        }
        else {
            return this.invoiceType;
        }
    };
    CondensedTrip.prototype.pluralizedHaulRateUnit = function (plural) {
        if (plural === void 0) { plural = true; }
        if (this.haulType === 'weight') {
            switch (this.haulWeightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
            }
        }
        else {
            return this.haulType;
        }
    };
    Object.defineProperty(CondensedTrip.prototype, "invoiceWeightUnitDisplay", {
        get: function () {
            switch (this.invoiceWeightUnit) {
                case 'cuyds':
                    return 'cu. yd.';
                default:
                    return this.invoiceWeightUnit;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "haulWeightUnitDisplay", {
        get: function () {
            switch (this.haulWeightUnit) {
                case 'cuyds':
                    return 'cu. yd.';
                default:
                    return this.haulWeightUnit;
            }
        },
        enumerable: true,
        configurable: true
    });
    CondensedTrip.prototype.pluralizedInvoiceWeightUnitDisplay = function (plural) {
        if (plural === void 0) { plural = true; }
        switch (this.invoiceWeightUnit) {
            case 'cuyds':
                return plural ? 'cu. yds.' : 'cu. yd.';
            default:
                return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
        }
    };
    CondensedTrip.prototype.pluralizedHaulWeightUnitDisplay = function (plural) {
        if (plural === void 0) { plural = true; }
        switch (this.haulWeightUnit) {
            case 'cuyds':
                return plural ? 'cu. yds.' : 'cu. yd.';
            default:
                return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
        }
    };
    Object.defineProperty(CondensedTrip.prototype, "expenseTitle", {
        get: function () {
            var value = '';
            if (this.expenseSent) {
                value = 'Paid #' + this.expenseNumber;
            }
            else if (this.expenseNumber) {
                value = 'Draft #' + this.expenseNumber;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "paystubTitle", {
        get: function () {
            var value = '';
            if (this.paystubSent) {
                value = 'Paid #' + this.paystubNumber;
            }
            else if (this.paystubNumber) {
                value = 'Draft #' + this.paystubNumber;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "invoiceTitle", {
        get: function () {
            var value = '';
            if (this.invoiceSent) {
                value = 'Sent #' + this.invoiceNumber;
            }
            else if (this.invoiceNumber) {
                value = 'Draft #' + this.invoiceNumber;
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    CondensedTrip.prototype.url = function (action) {
        return '/trips/' + this.id + '/' + action;
    };
    CondensedTrip.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.void) {
            var _option = lodash_1.find(options, { action: 'unvoid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    Object.defineProperty(CondensedTrip.prototype, "weightRequired", {
        get: function () {
            return this.invoiceType === 'weight' || this.haulType === 'weight';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "mapImageURL", {
        get: function () {
            var staticURL;
            if (this.routePolyline) {
                staticURL =
                    'https://maps.googleapis.com/maps/api/staticmap?size=60x45&path=color:0x002649|weight:1|enc:';
                staticURL += this.routePolyline;
                staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
            }
            return staticURL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CondensedTrip.prototype, "fullsizeMapImageURL", {
        get: function () {
            var staticURL;
            if (this.routePolyline) {
                staticURL =
                    'https://maps.googleapis.com/maps/api/staticmap?size=800x600&path=color:0x002649|weight:4|enc:';
                staticURL += this.routePolyline;
                staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
            }
            return staticURL;
        },
        enumerable: true,
        configurable: true
    });
    return CondensedTrip;
}());
exports.CondensedTrip = CondensedTrip;
