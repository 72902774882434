"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".template-wrapper[_ngcontent-%COMP%]{padding:15px 30px;border-bottom:1px solid #dadada;background-color:#f5f5f5}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]{display:flex;flex-direction:column}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .apply-to-all-row[_ngcontent-%COMP%]{flex-wrap:wrap;gap:20px}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .apply-to-all-row[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{margin:0!important}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]{display:flex;margin-bottom:20px}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]:last-child{margin-bottom:0}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row.apply-to-all[_ngcontent-%COMP%]{justify-content:flex-end}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .field[_ngcontent-%COMP%]{flex:1 1 auto;margin-right:30px}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .field[_ngcontent-%COMP%]:last-child{margin-right:0}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .field.rate[_ngcontent-%COMP%]{width:200px}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .field.days[_ngcontent-%COMP%]   .calendar-button[_ngcontent-%COMP%]{width:100%}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .field.notes[_ngcontent-%COMP%]   textarea[_ngcontent-%COMP%]{height:100%}.template-wrapper[_ngcontent-%COMP%]   .template[_ngcontent-%COMP%]   .row[_ngcontent-%COMP%]   .field[_ngcontent-%COMP%]   .label[_ngcontent-%COMP%]{font-size:14px;font-weight:600;color:#7e8fa4}"];
exports.styles = styles;
