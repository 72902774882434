import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResourceService } from '../shared/resource.service';
import { Plant } from './plant';
import { PlantSerializer } from './plant.serializer';

@Injectable()
export class PlantService extends ResourceService<Plant> {
  baseUrl = environment.serverUrl + 'scaletickets/';

  constructor(http: HttpClient) {
    super(http, 'plants/', new PlantSerializer());
  }
}
