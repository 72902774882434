<div class="app-content">
  <my-toolbar title="{{ 'Resources' | translate }}"></my-toolbar>

  <div class="resources-container">
    <div class="banner">
      <div fxLayout="row" fxFill>
        <div class="resource-description" fxLayout="column" fxLayoutAlign="space-evenly start">
          <div fxLayout="row" fxLayoutAlign=" center">
            <img src="/assets/img/resources/resource-connex-icon.png" style="margin-right: 30px;" />
            <h1>CONNEX</h1>
          </div>
          <div translate>
            CONNEX is Command Alkon's collaboration platform, designed for heavy materials suppliers, haulers, and buyers. 
            Our cloud-based approach allows supply chain participants to share data and processes related to their heavy materials - concrete, aggregate, asphalt, and cement.
          </div>
          <div class="resource-btn">
            <a href="https://connex.commandalkon.io/" target="_blank">
              <img src="/assets/img/resources/go-to-connex-btn.png" />
            </a>
          </div>
        </div>
        <div class="resource-img-container" fxLayout="row" fxLayoutAlign="center center">
          <img class="resource-img-connex" src="/assets/img/resources/resource-connex-screen.png" />
        </div>
      </div>
    </div>

    <div class="banner">
      <div fxLayout="row" fxFill>
        <div class="resource-description" fxLayout="column" fxLayoutAlign="space-evenly start">
          <div fxLayout="row" fxLayoutAlign=" center">
            <img src="/assets/img/resources/resource-driver-icon.png" style="margin-right: 30px;" />
            <h1>Ruckit Driver App</h1>
          </div>
          <div translate>
            Ruckit’s mobile driver application takes the guess work out of materials hauling for operators. 
            Drivers are able to digitize paper tickets in the field, clock in and out, track loads, 
            receive accurate GPS directions, and communications from dispatchers.
          </div>
          <div fxLayout="row">
            <div class="resource-btn" style="margin-right: 20px;">
              <a href="https://play.google.com/store/apps/details?id=com.ruckit.driver.platform" target="_blank">
                <img src="/assets/img/resources/google-play-btn.png" />
              </a>
            </div>
            <div class="resource-btn">
              <a href="https://apps.apple.com/us/app/ruckit/id1272586096" target="_blank">
                <img src="/assets/img/resources/apple-store-btn.png" />
              </a>
            </div>
          </div>
        </div>
        <div class="resource-img-container" fxLayout="row" fxLayoutAlign="center end">
          <img class="resource-img-driver" src="/assets/img/resources/resource-driver-screen.png" />
        </div>
      </div>
    </div>

    <div class="banner">
      <div fxLayout="row" fxFill>
        <div class="resource-description" fxLayout="column" fxLayoutAlign="space-evenly start">
          <div fxLayout="row" fxLayoutAlign=" center">
            <img src="/assets/img/resources/resource-field-icon.png" style="margin-right: 30px;" />
            <h1>Ruckit Field App</h1>
          </div>
          <div translate>
            The Ruckit Field Application offers access from mobile so you can check in on your projects on the go. 
            Monitor all of your orders, track leased and owned trucks, approve tickets, and approve time sheets.
          </div>
          <div fxLayout="row">
            <div class="resource-btn" style="margin-right: 20px;">
              <a href="https://play.google.com/store/apps/details?id=com.goruckit.jobsite" target="_blank">
                <img src="/assets/img/resources/google-play-btn.png" />
              </a>
            </div>
            <div class="resource-btn">
              <a href="https://apps.apple.com/us/app/ruckit-field/id1557536102" target="_blank">
                <img src="/assets/img/resources/apple-store-btn.png" />
              </a>
            </div>
          </div>
        </div>
        <div class="resource-img-container" fxLayout="row" fxLayoutAlign="center end">
          <img class="resource-img-field" src="/assets/img/resources/resource-field-screen.png" />
        </div>
      </div>
    </div>
  </div>

</div>