"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var http_1 = require("@angular/common/http");
var common_1 = require("@angular/common");
var lodash_1 = require("lodash");
var purchase_order_service_1 = require("./purchase-order.service");
var index_1 = require("../shared/dialogs/index");
var purchase_order_filters_dialog_component_1 = require("./purchase-order-filters-dialog.component");
var api_service_1 = require("../shared/api.service");
var filter_option_1 = require("../shared/filters-panel/filter-option");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var purchase_order_filter_service_1 = require("./purchase-order.filter.service");
var core_2 = require("@ngx-translate/core");
var PurchaseOrdersComponent = /** @class */ (function () {
    function PurchaseOrdersComponent(route, router, location, purchaseOrderService, translationService, dialog) {
        this.route = route;
        this.router = router;
        this.location = location;
        this.purchaseOrderService = purchaseOrderService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'name', title: 'Name', sortable: true, sortBy: 'name' },
            { key: 'number', title: ' P.O. #', sortable: true, sortBy: 'number' },
            { key: 'customer', title: 'Customer', sortable: true, sortBy: 'customer_organization__name' },
            { key: 'product', title: 'Product', sortable: true, sortBy: 'product__name' },
            { key: 'unit-price', title: 'Unit Price', sortable: true, sortBy: 'unit_price' },
            { key: 'quantity', title: 'Quantity', sortable: true, sortBy: 'quantity' },
            { key: 'delivery-location', title: 'Delivery Location', sortable: true, sortBy: 'delivery_location__name' },
            { key: 'email', title: 'Email', sortable: true, sortBy: 'email' },
            { key: 'phone-number', title: 'Phone Number', sortable: true, sortBy: 'phone_number' },
            { key: 'actions', title: 'Action', sortable: false }
        ];
        this.displayedColumns = [
            'select', 'name', 'number', 'customer', 'product', 'unit-price', 'quantity',
            'delivery-location', 'email', 'phone-number', 'actions'
        ];
        this.availableFilters = [
            new filter_option_1.FilterOption({
                key: 'product', title: this.translationService.instant('Product'),
                filterType: 'select', service: purchase_order_filter_service_1.PurchaseOrderFilterService, slug: 'products'
            }),
            new filter_option_1.FilterOption({
                key: 'customer_organization', title: this.translationService.instant('Customer'),
                filterType: 'select', service: purchase_order_filter_service_1.PurchaseOrderFilterService, slug: 'customers'
            }),
            new filter_option_1.FilterOption({
                key: 'delivery_location', title: this.translationService.instant('Location'),
                filterType: 'select', service: purchase_order_filter_service_1.PurchaseOrderFilterService, slug: 'locations'
            }),
            new filter_option_1.FilterOption({
                key: 'fulfilled', title: this.translationService.instant('Fulfilled'),
                filterType: 'checkbox'
            }),
            new filter_option_1.FilterOption({
                key: 'full_stop', title: this.translationService.instant('Full Stop'),
                filterType: 'checkbox'
            }),
            new filter_option_1.FilterOption({
                key: 'void', title: this.translationService.instant('Void'),
                filterType: 'checkbox'
            })
        ];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        // config for fancy table
        this.purchaseOrdersTableConfig = {
            hasHeader: true,
            service: purchase_order_service_1.PurchaseOrderService,
            preferenceKey: 'PurchaseOrdersComponent-PurchaseOrderService',
            query: {},
            collectionTitle: this.translationService.instant('Purchase Orders'),
            noResultsText: this.translationService.instant('an order'),
            newRecordRoute: ['/purchase-orders/new'],
            sortBy: 'name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
                { name: this.translationService.instant('Void'), action: 'void', link: false, external: false },
                { name: this.translationService.instant('Unvoid'), action: 'unvoid', link: false, external: false }
            ]
        };
        this.loading = true;
        this.errors = [];
        this.count = 0;
        this.filters = [];
        this.loadingProductTypes = false;
        // actionMenu = [{ name: 'Export', action: 'export' }];
        this.actionMenu = []; // No export API exists
        this.changeSearchEmitter = new core_1.EventEmitter();
        this.menuOptions = [
            { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false },
            { name: this.translationService.instant('Void'), action: 'void', link: false, external: false },
            { name: this.translationService.instant('Unvoid'), action: 'unvoid', link: false, external: false }
        ];
    }
    PurchaseOrdersComponent.prototype.ngOnInit = function () { };
    PurchaseOrdersComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(purchase_order_filters_dialog_component_1.PurchaseOrderFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = this.appliedFilters.reduce(function (acc, filter) {
            if (filter.filterType === 'checkbox') {
                if (filter.values === true) {
                    filter.values = ['True'];
                }
                acc[filter.key] = filter.values && filter.values[0] === 'True';
            }
            else if (filter.filterType === 'select') {
                acc[filter.key] = {
                    id: filter.values && filter.values[0],
                    name: filter.displayValues && filter.displayValues[0]
                };
            }
            else {
                acc[filter.key] = filter.value;
            }
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    PurchaseOrdersComponent.prototype.filterChanges = function (filterRes) {
        var triggerRefresh = false;
        if (filterRes) {
            if (filterRes.product !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'product'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'product', title: 'Product', filterType: 'select',
                    service: purchase_order_filter_service_1.PurchaseOrderFilterService, values: [filterRes.product.id],
                    displayValues: [filterRes.product.name], slug: 'products'
                }));
            }
            if (filterRes.customer_organization !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'customer_organization'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'customer_organization', title: 'Customer', filterType: 'select',
                    service: purchase_order_filter_service_1.PurchaseOrderFilterService,
                    values: [filterRes.customer_organization.id],
                    displayValues: [filterRes.customer_organization.name], slug: 'customers'
                }));
            }
            if (filterRes.delivery_location !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'delivery_location'; });
                this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                    key: 'delivery_location', title: 'Location', filterType: 'select',
                    service: purchase_order_filter_service_1.PurchaseOrderFilterService,
                    values: [filterRes.delivery_location.id],
                    displayValues: [filterRes.delivery_location.name], slug: 'locations'
                }));
            }
            if (filterRes.fulfilled !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'fulfilled'; });
                if (filterRes.fulfilled) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'fulfilled', title: 'Fulfilled', filterType: 'checkbox',
                        values: ['True'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
            if (filterRes.full_stop !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'full_stop'; });
                if (filterRes.full_stop) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'full_stop', title: 'Full Stop', filterType: 'checkbox',
                        values: ['True'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
            if (filterRes.void !== undefined) {
                lodash_1.remove(this.appliedFilters, function (filter) { return filter.key === 'void'; });
                if (filterRes.void) {
                    this.appliedFilters = this.appliedFilters.concat(new filter_option_1.FilterOption({
                        key: 'void', title: 'Void', filterType: 'checkbox',
                        values: ['True'], displayValues: ['True']
                    }));
                }
                else {
                    triggerRefresh = true;
                }
            }
        }
        if (triggerRefresh) {
            this.purchaseOrdersTable.getRecords();
        }
    };
    PurchaseOrdersComponent.prototype.filtersModified = function (appliedFilters) {
        if (this.appliedFiltersDisplay) {
            this.appliedFiltersDisplay.ngOnInit();
        }
        this.appliedFilters = appliedFilters;
    };
    PurchaseOrdersComponent.prototype.removeFilter = function () {
        lodash_1.remove(this.appliedFilters);
        this.purchaseOrdersTable.getRecords();
    };
    PurchaseOrdersComponent.prototype.menuAction = function (event) {
        switch (event[0]) {
            case 'void':
                this.voidPurchaseOrder(event[1]);
                break;
            case 'unvoid':
                this.unvoidPurchaseOrder(event[1]);
                break;
        }
    };
    PurchaseOrdersComponent.prototype.voidPurchaseOrder = function (purchaseOrder) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Void Order?'),
            body: this.translationService.instant('This order will be marked as ‘Void’ and you will not be able to create tickets for this order'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Void')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.purchaseOrderService.void(purchaseOrder).subscribe(function (res) {
                    _this.purchaseOrdersTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    PurchaseOrdersComponent.prototype.unvoidPurchaseOrder = function (purchaseOrder) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Unvoid Order?'),
            body: this.translationService.instant('This order will be unmarked as ‘Void’ and you will be able to create tickets for this order'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Unvoid')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.loading = true;
                _this.purchaseOrderService.unvoid(purchaseOrder).subscribe(function (res) {
                    _this.purchaseOrdersTable.getRecords();
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    PurchaseOrdersComponent.prototype.setSelectedAction = function (option) {
        switch (option) {
            case 'export':
                if (this.purchaseOrdersTable) {
                    var selectedPurchaseOrderIds = void 0;
                    if (!this.purchaseOrdersTable.allSelected) {
                        selectedPurchaseOrderIds = this.purchaseOrdersTable.selection.selected.map(function (order) { return order.id; });
                    }
                    var excludedPurchaseOrderIds = void 0;
                    if (this.purchaseOrdersTable.allSelected) {
                        excludedPurchaseOrderIds = this.purchaseOrdersTable.exclusion.selected.map(function (order) { return order.id; });
                    }
                    this.createExport(selectedPurchaseOrderIds, excludedPurchaseOrderIds);
                }
                break;
        }
    };
    PurchaseOrdersComponent.prototype.createExport = function (selectedPurchaseOrders, excludePurchaseOrders) {
        var _this = this;
        if (selectedPurchaseOrders === void 0) { selectedPurchaseOrders = null; }
        if (excludePurchaseOrders === void 0) { excludePurchaseOrders = null; }
        var scope = { purchaseorders: selectedPurchaseOrders, exclude_purchaseorders: excludePurchaseOrders };
        var filters = { search: this.search };
        this.purchaseOrderService.export(scope, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: { type: 'purchase orders' }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'purchase orders',
                    scope: scope,
                    params: params,
                    service: _this.purchaseOrderService,
                    buttonText: _this.translationService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    PurchaseOrdersComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return PurchaseOrdersComponent;
}());
exports.PurchaseOrdersComponent = PurchaseOrdersComponent;
