"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var api_service_1 = require("../shared/api.service");
var customer_service_1 = require("./customer.service");
var CustomersComponent = /** @class */ (function () {
    function CustomersComponent(route, router, customerService) {
        this.route = route;
        this.router = router;
        this.customerService = customerService;
        this.customers = [];
        this.loading = true;
        this.errors = [];
    }
    CustomersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.loading = false;
            _this.errors = [];
            _this.customerService.getCustomers().subscribe(function (customers) {
                _this.customers = customers;
                _this.loading = false;
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
            });
        });
    };
    CustomersComponent.prototype.openDialog = function () { };
    return CustomersComponent;
}());
exports.CustomersComponent = CustomersComponent;
