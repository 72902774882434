"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var StartHourPipe = /** @class */ (function () {
    function StartHourPipe() {
    }
    StartHourPipe.prototype.transform = function (record, hour, format) {
        if (format === void 0) { format = 'h a'; }
        if (!record || !record.startTimeTimestamp) {
            return false;
        }
        var startHour = moment(record.startTimeTimestamp).format('h a');
        return (startHour === hour);
    };
    return StartHourPipe;
}());
exports.StartHourPipe = StartHourPipe;
