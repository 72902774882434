<!-- Display Mode -->
<div *ngIf="!editMode || disabled" class="datetime-picker-inline-container">

  <!-- Display Values -->
  <div *ngIf="dateTime" class="datetime-picker-display" [ngClass]="{'editable':!config.Readonly && !disabled}">
    <div>{{displayTime}}</div>
    <div>{{displayDate}}</div>
  </div>
  <div *ngIf="!dateTime" class="datetime-picker-display" [ngClass]="{'editable':!config.Readonly && !disabled}" translate>Not Set</div>

  <!-- Edit Button -->
  <div class="datetime-picker-edit-col" *ngIf="!config.Readonly && !disabled">
    <i class="icon-pencil edit" (click)="onEditClick()"></i>
  </div>
</div>

<!-- Edit Mode -->
<div *ngIf="editMode && !disabled" class="datetime-picker-inline-container">
  <div class="datetime-picker-controls">

    <!-- Time Picker -->
    <div class="datetime-picker-controls-group">
      <input type="time" class="time-picker" [(ngModel)]="editTime" />
    </div>

    <!-- Date Picker -->
    <div class="sat-datepicker-container" [ngClass]="{mobile: config.mobile}">
      <input matInput [satDatepicker]="datePicker" [value]="editDateTime"
        [min]="limitL" [max]="limitH" (dateChange)="onDateChange($event)"
        class="date-picker-input" />
      <mat-icon class="decrease-date" (click)="adjustDate(-1)">chevron_left</mat-icon>
      <div class="date-picker-button" (click)="datePicker.open()">
        <sat-datepicker-toggle matSuffix [for]="datePicker"></sat-datepicker-toggle>
        <sat-datepicker #datePicker></sat-datepicker>
        {{ editDateDisplay }}
      </div>
      <mat-icon class="increase-date" (click)="adjustDate(1)">chevron_right</mat-icon>
    </div>
  </div>

  <!-- Save/Cancel Icons -->
  <div class="datetime-picker-edit-col">
    <div>
      <i class="icon-checkmark save" (click)="onSaveClick()"></i>
      <i class="icon-close cancel" (click)="onCancelClick()"></i>
    </div>
  </div>
</div>