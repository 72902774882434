"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".markets-container[_ngcontent-%COMP%]{overflow:hidden;display:flex;flex-direction:column;position:relative}.markets-container[_ngcontent-%COMP%]   .settings[_ngcontent-%COMP%]{overflow-y:hidden;height:100%}.container[_ngcontent-%COMP%]{padding:30px;position:relative}.container[_ngcontent-%COMP%]   .actions-wrapper[_ngcontent-%COMP%]{height:calc(100VH - 80px - 70px - 30px - 30px)}.value[_ngcontent-%COMP%]{display:flex;align-items:center}"];
exports.styles = styles;
