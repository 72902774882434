<div class="map-shell">
  <edit-location-details *ngIf="enableLocationEditMode"
                         #editLocationDetails
                         [includeInstructions]="false"
                         [location]="selectedLocation"
                         [isGeoFenceAvailable]="isGeoFenceAvailable"
                         [selectedGeoFence]="selectedGeoFence"
                         (clearGeoFence)="onClearGeoFence()"
                         (onPlaceChanged)="placeChanged($event)"
                         (onEditCompletion)="toggleLocationEditMode($event)"></edit-location-details>
  <div class="overlay" *ngIf="selectedLocation && !selectedLocation.mappable">
    <h2 translate>Please Add an Address</h2>
  </div>
  <ngui-map class="map-container"
            [ngClass]="{mobile: device.mobile, 'edit-mode': enableLocationEditMode}"
            [options]="mapOptions" (mapReady$)="initMap($event)">
    <custom-marker *ngIf="jobEvent.job.startLocationMappable"
      [id]="jobEvent.job.startLocation && jobEvent.job.startLocation.id"
      [position]="[jobEvent.startCoordinates.latitude, jobEvent.startCoordinates.longitude]"
      (click)="openLocationInfoWindow(jobEvent.job.startLocation, i)"
      (initialized$)="onCustomLocationMarkerInit($event)"
      (dragend)="markerDragEnd($event)"
      draggable="enableLocationEditMode && jobEvent.job.startLocation === selectedLocation">
      <img src="assets/img/map/locationmarker-loading.png" />
    </custom-marker>
    <custom-marker *ngIf="jobEvent.job.endLocationMappable"
      [id]="jobEvent.job.endLocation && jobEvent.job.endLocation.id"
      [position]="[jobEvent.endCoordinates.latitude, jobEvent.endCoordinates.longitude]"
      (click)="openLocationInfoWindow(jobEvent.job.endLocation, i)"
      (initialized$)="onCustomLocationMarkerInit($event)"
      (dragend)="markerDragEnd($event)"
      draggable="enableLocationEditMode && jobEvent.job.endLocation === selectedLocation">
      <img src="assets/img/map/locationmarker-unloading.png" />
    </custom-marker>
    <directions-renderer *ngIf="!jobEvent.job.routePolyline && jobEvent.job.startLocationMappable && jobEvent.job.endLocationMappable"
      [suppressMarkers]="true" panel="#my-panel" [preserveViewport]="true" [directions-request]="direction"
      [polylineOptions]="polylineOptions" (directions_changed)="onMapLoaded($event)">
    </directions-renderer>
    <polygon *ngFor="let polygon of polygons" [editable]="false" [paths]="polygon.paths" [strokeColor]="'#FF8C00'"
      [strokeOpacity]="0.8" [strokeWeight]="2" [fillColor]="'#FF8C00'" [fillOpacity]="0.35">
    </polygon>
    <polyline *ngIf="routePolyline && routePolyline.length"
              [path]="routePolyline" [strokeColor]="'#869ab5'"
              [strokeOpacity]="0.6" [strokeWeight]="4"></polyline>

    <ng-container *ngFor="let update of locationUpdates; let i = index">
      <custom-marker *ngIf="update.location && update.location.coordinates" [id]="update.driver && update.driver.id"
        [position]="[update.location.coordinates[1], update.location.coordinates[0]]"
        (click)="openAssignmentDetails(update, i)" (initialized$)="onCustomUpdateMarkerInit($event)">
        <ng-container *ngIf="markerType === 'driver-initials'">
          <map-status-marker [locationUpdate]="update" [markerStatus]="markerStatus" [markerType]="markerType"></map-status-marker>
          <div class="driver trip-status-{{update.tripStatus}} {{markerStatus}}" [ngClass]="{'update-expired': update.expired }">
            {{ update.driver && update.driver.initials }}
          </div>
        </ng-container>
        <ng-container *ngIf="markerType === 'truck-number'">
          <div class="truck trip-status-{{update.tripStatus}} {{update.tripStatus}} {{markerStatus}}" [ngClass]="{'update-expired': update.expired }">
            <p>
              {{ update.driver && update.driver.truck && update.driver.truck.name }}
            </p>
          </div>
        </ng-container>
      </custom-marker>
    </ng-container>

    <ng-container *ngFor="let update of paverLocationUpdates; let i = index">
      <custom-marker *ngIf="update.location && update.location.coordinates"
        [id]="update.truck && update.truck.id"
        [position]="[update.location.coordinates[1], update.location.coordinates[0]]"
        (click)="openTruckInfoWindow(i, update)" (initialized$)="onCustomPaverUpdateMarkerInit($event)">
        <div class="paver" [ngClass]="{'update-expired': update.expired }">
          <p [title]="update?.truck?.name || 'No Paver'">
            <i class="icomoon icon-paver"></i>
            <span>{{ update?.truck?.name || 'No Paver' }}</span>
          </p>
        </div>
      </custom-marker>
    </ng-container>

    <traffic-layer class="desktop-map-element" *ngIf="mapControls.traffic"></traffic-layer>
    <heatmap-layer class="desktop-map-element" (initialized$)="onHeatmapInitialized($event)" *ngIf="mapControls.heatmap"
      dissipating="true" [radius]="mapControls.heatmapRadius" maxIntensity="5" [data]="heatmapPoints"></heatmap-layer>
    <info-window class="desktop-map-element" id="driver-info-window">
      <driver-infobox *ngIf="driverInfoWindowModel"
                      [driver]="driverInfoWindowModel.driver"
                      [createdAt]="driverInfoWindowModel.createdAt"
                      [date]="jobEventDate"></driver-infobox>
    </info-window>
    <info-window class="desktop-map-element" id="truck-info-window">
      <div *ngIf="truckInfoWindowModel" class="truck-info-window">
        <div class="title">Paver: {{ truckInfoWindowModel?.truck?.name }}</div>
      </div>
    </info-window>
    <info-window class="desktop-map-element" id="location-info-window">
      <div *ngIf="selectedLocation" class="location-info-window">
        <a class="geofence-edit" (click)="!device.mobile && toggleLocationEditMode(selectedLocation)">
          <mat-icon title="Geofence set for location (click to edit)" *ngIf="selectedLocation.geofence">layers</mat-icon>
          <mat-icon color="warn" title="Geofence missing for location (click to add)" *ngIf="!selectedLocation.geofence">layers_clear</mat-icon>
        </a>
        <div class="title">
          <a [routerLink]="['/locations', selectedLocation.id, 'edit']"
            [queryParams]="{ returnTo: '/jobs/' + jobEvent.job.id + '/' + jobEvent.id }">
            {{ selectedLocation.name }}
          </a>
        </div>
        <div class="address">{{ selectedLocation.street }}</div>
        <div class="city">
          {{ selectedLocation.city }} {{ selectedLocation.state }} {{ selectedLocation.zipcode }}
        </div>
      </div>
    </info-window>
    <drawing-manager *ngIf="!device.mobile"
                     [drawingMode]="drawingMode"
                     [drawingControlOptions]="{
                       position: 'RIGHT_BOTTOM',
                       drawingModes: ['polygon']
                     }"
                     [polygonOptions]="{
                       strokeColor: '#FF8C00',
                       strokeOpacity: 0.8,
                       strokeWeight: 2,
                       fillColor: '#FF8C00',
                       fillOpacity: 0.35
                     }">
    </drawing-manager>
    <marker *ngIf="enableLocationEditMode && selectedLocation && selectedLocation.marker && selectedLocation.marker.lat && selectedLocation.marker.lon"
            [position]="[selectedLocation.marker.lat, selectedLocation.marker.lon]"
            draggable="true"
            (dragend)="markerDragEnd($event)">
    </marker>
    <marker *ngIf="enableLocationEditMode && selectedLocation && selectedLocation.marker && selectedLocation.marker.latLng"
            [position]="selectedLocation.marker.latLng"
            draggable="true"
            (dragend)="markerDragEnd($event)">
    </marker>
  </ngui-map>
</div>

<div class="legend" *ngIf="false && !enableLocationEditMode" [ngClass]="{mobile: device.mobile, hidden: searching, condensed: markerStatus === 'load-status'}">
  <ng-container *ngIf="markerStatus === 'each-segment'">
    <div class="enroute-loading" translate>Enroute</div>
    <div class="loading" translate>Loading</div>
    <div class="enroute-unloading" translate>Enroute</div>
    <div class="unloading" translate>Unloading</div>
  </ng-container>
  <ng-container *ngIf="markerStatus === 'load-status'">
    <div class="loaded" translate>Loaded</div>
    <div class="empty" translate>Empty</div>
  </ng-container>
</div>

<div class="custom-map-controls" *ngIf="!device.mobile" [ngClass]="{'with-slider': mapControls.heatmap}">
  <div (click)="toggleStats()" [ngClass]="{active: mapControls.stats}">Stats</div>
  <div class="divider">|</div>
  <div (click)="toggleTraffic()" [ngClass]="{active: mapControls.traffic}">Traffic</div>
  <div class="divider">|</div>
  <div (click)="toggleHeatmap()" [ngClass]="{active: mapControls.heatmap}">Heatmap</div>
</div>
<div class="heatmap-slider" [ngClass]="{mobile: device.mobile}" *ngIf="mapControls.heatmap && !device.mobile">
  <div class="slidecontainer">
    <input type="range" min="1" max="40" [value]="mapControls.heatmapRadius" (change)="changeHeatmapRadius($event)"
      class="slider">
  </div>
</div>
<div class="map-search" [ngClass]="{mobile: device.mobile, expanded: searching}">
  <div class="search-input" *ngIf="searching">
    <input type="text" name="search" [(ngModel)]="search" (keypress)="onSearchboxKeyPress($event)" #mapSearch />
    <div class="no-results" *ngIf="!assignments.items.length">No Results</div>
  </div>
  <div class="clear-icon" *ngIf="searching" (click)="clearSearch()">
    <i class="icomoon icon-close"></i>
  </div>
  <div class="search-icon" *ngIf="!searching" (click)="showSearch()">
    <i class="icomoon icon-search"></i>
  </div>
</div>

<job-stats-widget *ngIf="!enableLocationEditMode && (mapControls && mapControls.stats || device.mobile)"
                  [jobEvent]="jobEvent"></job-stats-widget>
<collaborators-widget *ngIf="!enableLocationEditMode && !device.mobile && mapControls && mapControls.stats"
                      [jobEvent]="jobEvent"></collaborators-widget>
