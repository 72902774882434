import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CyberTripSerializer } from './cyber-trip.serializer';
import { ResourceService } from '../shared/resource.service';
import { CyberTrip } from './cyber-trip';

@Injectable()
export class CyberTripService extends ResourceService<CyberTrip> {

  constructor(http: HttpClient) {
    super(http, 'cybertrips/', new CyberTripSerializer());
   }
}
