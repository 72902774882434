"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var VoidTicketConfirmDialogComponent = /** @class */ (function () {
    function VoidTicketConfirmDialogComponent(confirmDialog) {
        this.confirmDialog = confirmDialog;
        this.attributes = {};
        this.createCopy = false;
        this.attributes = {
            title: 'Confirm your action!',
            body: 'Are you sure you wish to do this?',
            close: 'Cancel',
            accept: 'Continue'
        };
    }
    VoidTicketConfirmDialogComponent.prototype.onVoidButtonClicked = function () {
        var voidObject = { create_copy: this.createCopy };
        this.confirmDialog.close(voidObject);
    };
    return VoidTicketConfirmDialogComponent;
}());
exports.VoidTicketConfirmDialogComponent = VoidTicketConfirmDialogComponent;
