import { Resource } from '../shared/resource';

export class EsbOrder extends Resource {
  eventKind: string;
  slorderId: string;
  slcustId: string;
  dsorderId: string;
  dsorderDescription1: string;
  dsorderDate: string;
  versionNo: number;
  commitStatus: string;
  jobDailyDeliveryTarget: string;
  jobDeliveryInterval: string;
  jobDeliveryIntervalUnit: string;
  jobProjectCustomerOrganizationName: string;
  jobProjectCustomerOrganizationPhoneNumber: string;
  jobProjectCustomerOrganizationEmail: string;
  jobProjectOwnerOrganizationId: string;
  jobProjectName: string;
  jobName: string;
  jobOrderNumber: string;
  jobPoNumber: string;
  jobJobNumber: string;
  jobAllowAnyTruckType: boolean;
  jobMaterial: string;
  jobNumTrucks: number;
  jobInvoiceType: string;
  jobRate: string;
  jobHaulType: string;
  jobHaulRate: string;
  startDatetime: string;
  endDatetime: string;
  jobAmountNeeded: string;
  jobNotes: string;
  jobStartLocationId: string;
  jobStartLocationName: string;
  jobStartLocationStreet: string;
  jobStartLocationCity: string;
  jobStartLocationState: string;
  jobStartLocationZipcode: string;
  jobEndLocationId: string;
  jobEndLocationName: string;
  jobEndLocationStreet: string;
  jobEndLocationCity: string;
  jobEndLocationState: string;
  jobEndLocationZipcode: string;
  jobCheckinTicketNumber: boolean;
  jobCheckinTicketImage: boolean;
  jobCheckinSignature: boolean;
  jobCheckinActiveTracking: boolean;
  jobCheckinWeight: boolean;
  jobCheckoutTicketNumber: boolean;
  jobCheckoutTicketImage: boolean;
  jobCheckoutSignature: boolean;
  jobCheckoutActiveTracking: boolean;
  jobCheckoutWeight: boolean;
  action: string;
  jobTruckTypes: string[];
}
