<div class="user-container">
  <div class="user-data">
    <h2 translate>Work Order Settings</h2>
    <form #editUser="ngForm" class="user-form" *ngIf="user">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <div class="work-order-signature-name">
              <label class="required" translate>Work Order Signature Name</label>
              <input type="text" required [(ngModel)]="user.workOrderSignatureName" name="work_order_signature_name" />
            </div>
          </div>

          <div class="row" *ngIf="errors && errors.length">
            <notification *ngFor="let error of errors">{{error}}</notification>
          </div>

          <div class="row">
            <button class="btn btn-primary" (click)="saveUser(); false"
              [disabled]="loading || !editUser.form.valid || (editUser.form.pristine && !imageChanged)"
              [ngClass]="{loading: loading}" translate>
              Save Work Order Settings
            </button>
          </div>
        </div>
        <div class="right-side">
          <div class="field-group profile-image">
            <div class="upload-image-dropzone" [ngClass]="{
              filled: (user.workOrderSignatureImage) || signatureImage.file
            }">
              <div class="img">
                <img *ngIf="!signatureImage.file && user.workOrderSignatureImage"
                     [src]="user.workOrderSignatureImage" />
                <img *ngIf="signatureImage.file" [src]="signatureImage.dataUri" />
              </div>
              <input type="file" name="file" id="file" class="inputfile"
                     (change)="fileChange($event, editUser)" #fileInput />
              <label class="upload-label" for="file">
                <i class="icon-upload"></i>
                <span *ngIf="!user.workOrderSignatureImage && !signatureImage.file" translate>
                  Drag a JPG or PNG under 25mb here or browse for an image to upload.
                </span>
                <span *ngIf="user.workOrderSignatureImage || signatureImage.file" translate>
                  Upload a new signature
                </span>
              </label>
            </div>

            <label translate>Your Signature Image</label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
