import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { AuthenticationService } from '../shared';
import { User } from './user';
import { UserService } from './user.service';
import { UserSerializer } from './user.serializer';
import { parseErrors } from '../shared/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'ruckit-edit-user-work-order-settings',
  templateUrl: './edit-user-work-order-settings.component.html',
  styleUrls: ['./edit-user-work-order-settings.component.scss']
})
export class EditUserWorkOrderSettingsComponent implements OnInit {
  @Input() user: User;
  loading = false;
  signatureImage = {
    dataUri: null,
    file: null
  };
  imageChanged = false;
  errors = [];
  @ViewChild('editUser', { static: false }) editUser;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() { }

  saveUser(): void {
    this.loading = true;
    this.userService.saveWithSignatureImage(this.user, this.signatureImage.file).subscribe((user) => {
      this.user = (new UserSerializer).fromJson(user);
      let _user = this.authenticationService.user();
      _user.name = this.user.firstName + ' ' + this.user.lastName;
      _user.username = this.user.email;
      _user.image = this.user.image;
      _user.hasSignature = true;
      localStorage.setItem('currentUser', JSON.stringify(_user));

      this.router.navigate(['/settings', 'user']);
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  fileChange(e, form: NgForm) {
    let signatureImage = this.signatureImage;

    let reader = new FileReader();
    reader.onload = function (loadEvent: any) {
      signatureImage.dataUri = loadEvent.target.result;
    };

    let file = e.srcElement.files[0];
    signatureImage.file = file;
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }
}
