"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var connection_service_1 = require("./connection.service");
var organization_service_1 = require("../organizations/organization.service");
var invitation_service_1 = require("../invitation/invitation.service");
var api_service_1 = require("../shared/api.service");
var ConnectionDialogComponent = /** @class */ (function () {
    function ConnectionDialogComponent(route, router, connectionService, organizationService, invitationService, dialogRef) {
        this.route = route;
        this.router = router;
        this.connectionService = connectionService;
        this.organizationService = organizationService;
        this.invitationService = invitationService;
        this.dialogRef = dialogRef;
        this.model = {};
        this.loading = false;
        this.organizations = [];
        this.invitationSent = false;
        this.inputValue = '';
        this.inputAutocomplete = {
            changeTrigger: false,
            list: []
        };
        this.errors = [];
        this.activeView = 'invite';
        this.searching = false;
        this.submitted = false;
        this.invite = {
            'contact': '',
            'email': '',
            'business': ''
        };
    }
    ConnectionDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.forEach(function (params) {
            _this.loading = false;
        });
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.senderUserId = currentUser.id;
            this.senderOrganizationId = currentUser.organization.id;
        }
    };
    ConnectionDialogComponent.prototype.openNewInvite = function () {
        this.activeView = 'invite';
    };
    ConnectionDialogComponent.prototype.openSearch = function () {
        this.activeView = 'search';
    };
    ConnectionDialogComponent.prototype.resetSearch = function () {
        this.model.search = '';
        this.searching = false;
        this.inputAutocomplete = {
            changeTrigger: false,
            list: []
        };
        this.getOrganizations('');
    };
    ConnectionDialogComponent.prototype.filterList = function (q, list) {
        if (list) {
            return list.filter(function (item) {
                return item.name.toLowerCase().indexOf(q.toLowerCase()) >= 0;
            });
        }
    };
    ConnectionDialogComponent.prototype.getOrganizations = function (q) {
        var _this = this;
        this.loading = true;
        this.searching = q.length > 0 ? true : false;
        this.organizationService.getOrganizations(q).subscribe(function (organizations) {
            if (organizations.length > 0) {
                _this.organizations = organizations;
                _this.updateInputAutocompleteData(_this.filterList(q, _this.organizations));
            }
            else {
                _this.organizations = [{ name: 'No Companies Found!', detail: 'Please try another search term.', noResults: true }];
                _this.updateInputAutocompleteData(_this.organizations);
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    ConnectionDialogComponent.prototype.updateSelectedValue = function (selectedValue) {
        this.recipientOrganizationId = selectedValue;
    };
    ConnectionDialogComponent.prototype.updateInputAutocomplete = function (response) {
        this.getOrganizations(response.q);
    };
    ConnectionDialogComponent.prototype.updateInputAutocompleteData = function (list) {
        if (this.model.search) {
            this.inputAutocomplete.list = list;
            this.inputAutocomplete.changeTrigger = !this.inputAutocomplete.changeTrigger;
        }
    };
    ConnectionDialogComponent.prototype.onSubmit = function (type) {
        var _this = this;
        this.loading = true;
        this.invitationSent = true;
        if (type === 'search') {
            this.invitationService.createInvitation({
                sender_user: this.senderUserId,
                sender_organization: this.senderOrganizationId,
                kind: 'connection',
                recipient_organization: this.recipientOrganizationId
            }).subscribe(function (result) {
                _this.loading = false;
                _this.router.navigate(['/connections/sent']);
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
            });
        }
        else {
            this.invitationService.createInvitation({
                sender_user: this.senderUserId,
                sender_organization: this.senderOrganizationId,
                kind: 'new_org',
                recipient_email: this.invite.email,
                data: {
                    contact: this.invite.contact,
                    business: this.invite.business
                }
            }).subscribe(function (result) {
                _this.loading = false;
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
            });
        }
        // Simulate server activity...
        setTimeout(function () {
            _this.submitted = true;
            _this.loading = false;
            setTimeout(function () {
                _this.dialogRef.close();
            }, 20000);
        }, 1000);
    };
    return ConnectionDialogComponent;
}());
exports.ConnectionDialogComponent = ConnectionDialogComponent;
