"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".container[_ngcontent-%COMP%]{height:100%;display:flex}.container[_ngcontent-%COMP%]   driver-daily[_ngcontent-%COMP%]{width:450px;min-width:450px;border-right:1px solid #dfe2e5}.container[_ngcontent-%COMP%]   driver-daily-tasks[_ngcontent-%COMP%]{flex:1 1 auto;max-width:calc(100% - 450px)}.eod-topbar[_ngcontent-%COMP%]{display:flex;width:100%;justify-content:space-between;flex-wrap:wrap}.right-actions[_ngcontent-%COMP%]{display:flex;justify-content:center}.right-actions[_ngcontent-%COMP%]   .btn-filters[_ngcontent-%COMP%]{height:36px}.overall-stats[_ngcontent-%COMP%]{max-height:33px}.overall-stats[_ngcontent-%COMP%]     .loader{margin-top:10px!important;margin-bottom:0!important}.filters-container[_ngcontent-%COMP%]{padding:8px 30px;background-color:#fff;border-bottom:1px solid #ddd;display:flex}.filters-container[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]{display:flex;align-items:center;text-transform:capitalize;height:32px;padding:6px 8px 6px 12px;margin-right:15px;border-radius:16px;border:1px solid rgba(0,0,0,.12);font-size:14px;letter-spacing:.3px;color:rgba(0,0,0,.87)}.filters-container[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]   a.remove[_ngcontent-%COMP%]{cursor:pointer;margin-top:4px}.filters-container[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]   a.remove[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]{color:#9e9e9e;transform:rotate(45deg)}.filters-container[_ngcontent-%COMP%]   .filter[_ngcontent-%COMP%]   a.remove[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]:hover{color:#787878}.filters-container[_ngcontent-%COMP%]   .filter.hidden[_ngcontent-%COMP%]{display:none}.filters-container[_ngcontent-%COMP%]   .filter.reset[_ngcontent-%COMP%]{cursor:pointer}.filters-container[_ngcontent-%COMP%]   .filter.reset[_ngcontent-%COMP%]:hover{background:#e6e9ec}.filters-container[_ngcontent-%COMP%]   .filter.reset[_ngcontent-%COMP%]:hover   a.remove[_ngcontent-%COMP%]   .mat-icon[_ngcontent-%COMP%]{color:#787878}"];
exports.styles = styles;
