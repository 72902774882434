"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
// angular material
var material_1 = require("@angular/material");
// services
var core_2 = require("@ngx-translate/core");
var shared_1 = require("../shared");
var surcharge_template_service_1 = require("./surcharge-template.service");
// components
var surcharge_template_dialog_component_1 = require("./surcharge-template-dialog.component");
var index_1 = require("../shared/dialogs/index");
var SurchargeTemplatesComponent = /** @class */ (function () {
    function SurchargeTemplatesComponent(surchargeTemplateService, authenticationService, translationService, dialog) {
        var _this = this;
        this.surchargeTemplateService = surchargeTemplateService;
        this.authenticationService = authenticationService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'select' },
            { key: 'kindTitle', title: this.translationService.instant('Title'), sortable: true, sortBy: 'kind' },
            { key: 'title', title: this.translationService.instant('Title'), sortable: true },
            { key: 'description', title: this.translationService.instant('Description'), sortable: true },
            { key: 'amount', title: this.translationService.instant('Amount') },
            { key: 'action', title: this.translationService.instant('Actions') }
        ];
        this.displayedColumns = [
            'select', 'title', 'description', 'amount', 'action'
        ];
        this.availableFilters = [];
        this.appliedFilters = [];
        this.search = '';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.availableFiltersChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        this.hasPOS = false;
        this.tableConfig = {
            hasHeader: true,
            service: surcharge_template_service_1.SurchargeTemplateService,
            query: {},
            collectionTitle: this.translationService.instant('Surcharge Templates'),
            noResultsText: this.translationService.instant('a surcharge template'),
            sortBy: 'created_at',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
                { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
            ],
            newRecordModal: function () { return _this.openNewSurchargeTemplate(); }
        };
        this.newSurchargeTemplateCallback = function (e) {
            _this.refreshTable();
        };
    }
    SurchargeTemplatesComponent.prototype.ngOnInit = function () {
        var organization = this.authenticationService.getOrganization();
        this.hasPOS = organization && organization.posEnabled ? true : false;
        if (this.hasPOS) {
            this.displayedColumns = [
                'select', 'kindTitle', 'title', 'description', 'amount', 'action'
            ];
        }
    };
    SurchargeTemplatesComponent.prototype.openNewSurchargeTemplate = function () {
        var dialog = this.dialog.open(surcharge_template_dialog_component_1.SurchargeTemplateDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = this.newSurchargeTemplateCallback;
    };
    SurchargeTemplatesComponent.prototype.editSurchargeTemplate = function (surchargeTemplate) {
        var dialog = this.dialog.open(surcharge_template_dialog_component_1.SurchargeTemplateDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.model = surchargeTemplate;
        dialog.componentInstance.callback = this.newSurchargeTemplateCallback;
    };
    SurchargeTemplatesComponent.prototype.removeSurchargeTemplate = function (surchargeTemplate) {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        this.confirmDialog.componentInstance.attributes = {
            title: this.translationService.instant('Remove Surcharge Template?'),
            body: this.translationService.instant('This surcharge template will be deleted and cannot be recovered.'),
            close: this.translationService.instant('Cancel'),
            accept: this.translationService.instant('Remove')
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.surchargeTemplateService.remove(surchargeTemplate).subscribe(function (res) {
                    _this.refreshTable();
                }, function (err) {
                    // this.errors = parseErrors(err);
                });
            }
            _this.confirmDialog = null;
        });
    };
    SurchargeTemplatesComponent.prototype.refreshTable = function () {
        if (this.surchargeTemplatesTable) {
            this.surchargeTemplatesTable.getRecords();
        }
    };
    SurchargeTemplatesComponent.prototype.menuAction = function (action, surchargeTemplate) {
        switch (action) {
            case 'edit':
                this.editSurchargeTemplate(surchargeTemplate);
                break;
            case 'remove':
                this.removeSurchargeTemplate(surchargeTemplate);
                break;
        }
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    SurchargeTemplatesComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    return SurchargeTemplatesComponent;
}());
exports.SurchargeTemplatesComponent = SurchargeTemplatesComponent;
