"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var location_type_serializer_1 = require("./location-type.serializer");
var location_owner_serializer_1 = require("./location-owner.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var LocationStat = /** @class */ (function () {
    function LocationStat(locationStatInfo) {
        locationStatInfo = camelcaseKeysDeep(locationStatInfo);
        this.id = locationStatInfo.id;
        this.name = locationStatInfo.name;
        if (locationStatInfo.locationOwner) {
            this.locationOwner = new location_owner_serializer_1.LocationOwnerSerializer().fromJson(locationStatInfo.locationOwner);
        }
        this.displayName = [
            this.locationOwner && this.locationOwner.name, this.name
        ].filter(Boolean).join(': ');
        this.loadingTimeSpent = locationStatInfo.loadingTimeSpent ? (locationStatInfo.loadingTimeSpent / 60).toString() : null;
        this.unloadingTimeSpent = locationStatInfo.unloadingTimeSpent ? (locationStatInfo.unloadingTimeSpent / 60).toString() : null;
        this.averageTimeSpent = locationStatInfo.averageTimeSpent;
        this.loadingVisits = locationStatInfo.loadingVisits;
        this.unloadingVisits = locationStatInfo.unloadingVisits;
        if (locationStatInfo.locationType) {
            this.locationType = new location_type_serializer_1.LocationTypeSerializer().fromJson(locationStatInfo.locationType);
        }
        this.loadingTimeTarget = locationStatInfo.loadingTimeTarget;
        this.unloadingTimeTarget = locationStatInfo.unloadingTimeTarget;
        this.hasGeofence = locationStatInfo.hasGeofence;
        this.loadingTargetDifference = parseFloat(this.loadingTimeSpent) - parseFloat(this.loadingTimeTarget);
        this.unloadingTargetDifference = parseFloat(this.unloadingTimeSpent) - parseFloat(this.unloadingTimeTarget);
        if (this.loadingTargetDifference < 0) {
            this.loadingTargetRange = 'target-exceeded';
        }
        else if (this.loadingTargetDifference > 0) {
            this.loadingTargetRange = 'target-miss';
        }
        else {
            this.loadingTargetRange = 'target-hit';
        }
        if (this.unloadingTargetDifference < 0) {
            this.unloadingTargetRange = 'target-exceeded';
        }
        else if (this.unloadingTargetDifference > 0) {
            this.unloadingTargetRange = 'target-miss';
        }
        else {
            this.unloadingTargetRange = 'target-hit';
        }
    }
    return LocationStat;
}());
exports.LocationStat = LocationStat;
