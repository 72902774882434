"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var organization_1 = require("../organizations/organization");
var condensed_trip_1 = require("../trips/condensed-trip");
var condensed_punch_card_1 = require("../punch-cards/condensed-punch-card");
var lodash_1 = require("lodash");
var moment = require("moment");
var job_serializer_1 = require("../jobs/job.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Invoice = /** @class */ (function () {
    function Invoice(invoiceInfo) {
        this.selected = false;
        invoiceInfo = camelcaseKeysDeep(invoiceInfo);
        this.id = invoiceInfo.id;
        this.number = invoiceInfo.number;
        this.amount = invoiceInfo.amount;
        this.subtotal = invoiceInfo.subtotal;
        this.expenseAmount = invoiceInfo.expenseAmount || 0;
        this.surchargeAmount = invoiceInfo.surchargeAmount || 0;
        this.tripsAmount = invoiceInfo.tripsAmount || 0;
        this.tripsExpenseAmount = invoiceInfo.tripsExpenseAmount || 0;
        this.punchcardsAmount = invoiceInfo.punchcardsAmount || 0;
        this.punchcardsExpenseAmount = invoiceInfo.punchcardsExpenseAmount || 0;
        this.sendable = invoiceInfo.sendable;
        this.expensed = invoiceInfo.expensed;
        if (invoiceInfo.ownerOrganization) {
            this.ownerOrganization = new organization_1.Organization(invoiceInfo.ownerOrganization);
        }
        if (invoiceInfo.customerOrganization) {
            this.customerOrganization = new organization_1.Organization(invoiceInfo.customerOrganization);
        }
        if (invoiceInfo.job) {
            this.job = new job_serializer_1.JobSerializer().fromJson(invoiceInfo.job);
        }
        this.sent = invoiceInfo.sent;
        this.draft = !invoiceInfo.sent;
        this.sentTime = invoiceInfo.sentTime;
        this.sentBy = invoiceInfo.sentBy;
        if (invoiceInfo.sentTrips && invoiceInfo.sentTrips.length) {
            this.sentTrips = invoiceInfo.sentTrips.map(function (trip) {
                return new condensed_trip_1.CondensedTrip(trip);
            });
        }
        if (invoiceInfo.sentPunchCards && invoiceInfo.sentPunchCards.length) {
            this.sentPunchCards = invoiceInfo.sentPunchCards.map(function (punchCard) {
                return new condensed_punch_card_1.CondensedPunchCard(punchCard);
            });
        }
        this.billFromContactEmail = invoiceInfo.billFromContactEmail;
        if (invoiceInfo.billFromCcEmails && (typeof invoiceInfo.billFromCcEmails === 'object')) {
            this.billFromCcEmails = invoiceInfo.billFromCcEmails.join(',');
        }
        this.billFromAddress = invoiceInfo.billFromAddress;
        this.billToContactEmail = invoiceInfo.billToContactEmail;
        if (invoiceInfo.billToCcEmails && (typeof invoiceInfo.billToCcEmails === 'object')) {
            this.billToCcEmails = invoiceInfo.billToCcEmails.join(',');
        }
        this.billToAddress = invoiceInfo.billToAddress;
        this.createdAt = invoiceInfo.createdAt;
        this.remittanceInfo = invoiceInfo.remittanceInfo;
        this.pdf = invoiceInfo.pdf;
        this.html = invoiceInfo.html;
        this.earliestBillableItemTime = invoiceInfo.earliestBillableItemTime;
        this.latestBillableItemTime = invoiceInfo.latestBillableItemTime;
        this.startTime = invoiceInfo.startTime;
        this.endTime = invoiceInfo.endTime;
        this.haulTotal = invoiceInfo.haulTotal;
        this.invoiceDate = invoiceInfo.invoiceDate;
        this.kind = invoiceInfo.kind;
    }
    Object.defineProperty(Invoice.prototype, "administrativeStatus", {
        get: function () {
            if (this.sent) {
                return 'sent';
            }
            else if (this.draft) {
                return 'draft';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invoice.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus &&
                this.administrativeStatus.length &&
                this.administrativeStatus !== 'sent' &&
                this.administrativeStatus !== 'draft') {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invoice.prototype, "tripDates", {
        get: function () {
            var values = [];
            if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
                var _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_earliestBillableItemTime);
            }
            if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
                var _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_latestBillableItemTime);
            }
            if (!values.length) {
                values.push(this.shortTitle + ' ' + this.number);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invoice.prototype, "invoicePeriod", {
        get: function () {
            var values = [];
            if (this.earliestBillableItemTime && this.earliestBillableItemTime.length) {
                var _earliestBillableItemTime = moment(this.earliestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_earliestBillableItemTime);
            }
            if (this.latestBillableItemTime && this.latestBillableItemTime.length) {
                var _latestBillableItemTime = moment(this.latestBillableItemTime).format('MMMM DD, YYYY');
                values.push(_latestBillableItemTime);
            }
            if (!values.length) {
                values.push(this.shortTitle + ' ' + this.number);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invoice.prototype, "shortTitle", {
        get: function () {
            if (this.draft) {
                return 'Invoice Draft ';
            }
            else {
                return 'Sent Invoice ';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Invoice.prototype, "title", {
        get: function () {
            var name = this.job && this.job.name;
            if (!name) {
                name = this.customerOrganization && this.customerOrganization.name;
            }
            if (this.draft) {
                return 'Invoice Draft ' + name;
            }
            else {
                return 'Sent Invoice ' + name;
            }
        },
        enumerable: true,
        configurable: true
    });
    Invoice.prototype.url = function (action) {
        if (action === 'view') {
            return this.html;
        }
        else if (action === 'download') {
            return this.pdf;
        }
        else {
            return '/invoices/' + this.id + '/' + action;
        }
    };
    Invoice.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.draft) {
            var _option = lodash_1.find(options, { action: 'resend' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'view' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'download' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'unlock' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        else {
            var _option = lodash_1.find(options, { action: 'edit' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'remove' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
            _option = lodash_1.find(options, { action: 'preview' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.sendable) {
            var _option = lodash_1.find(options, { action: 'send' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (this.expensed) {
            var _option = lodash_1.find(options, { action: 'expense' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.pdf) {
            var _option = lodash_1.find(options, { action: 'download' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return Invoice;
}());
exports.Invoice = Invoice;
