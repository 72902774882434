"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var resource_1 = require("../shared/resource");
var JobEventShare = /** @class */ (function (_super) {
    __extends(JobEventShare, _super);
    function JobEventShare() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editing = false;
        _this.readonly = false;
        _this.selected = false;
        _this.jobEventOptions = [];
        return _this;
    }
    JobEventShare.prototype.url = function (action) {
        if (action === 'html') {
            return this.workOrderRenderedHtml;
        }
        else if (action === 'pdf') {
            return this.workOrderRenderedPdf;
        }
        else {
            return '/work-orders/' + this.id + '/' + action;
        }
    };
    JobEventShare.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (!this.workOrderRenderedHtml) {
            var _option = lodash_1.find(options, { action: 'html' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.workOrderRenderedPdf) {
            var _option = lodash_1.find(options, { action: 'pdf' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    return JobEventShare;
}(resource_1.Resource));
exports.JobEventShare = JobEventShare;
