"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TruncatePipe = /** @class */ (function () {
    function TruncatePipe() {
    }
    /**
     *
     * @param value - string to be truncated
     * @param args
     * first argument - limit string size
     * second argument - after truncated string - string; default is ...
     * @returns truncated string
     */
    TruncatePipe.prototype.transform = function (value, args) {
        var limit = args.length > 0 ? parseInt(args[0], 10) : 20;
        var trail = args.length > 1 ? args[1] : '...';
        return value && value.length > limit ? value.substring(0, limit) + trail : value;
    };
    return TruncatePipe;
}());
exports.TruncatePipe = TruncatePipe;
