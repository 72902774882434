"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
// libraries
var lodash_1 = require("lodash");
// material
var material_1 = require("@angular/material");
// services
var punch_card_service_1 = require("./../../punch-cards/punch-card.service");
var preference_service_1 = require("../../preferences/preference.service");
// models
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var driver_1 = require("../driver");
// components
var driver_trip_filters_dialog_component_1 = require("../driver-trip-filters-dialog/driver-trip-filters-dialog.component");
var column_toggle_component_1 = require("../../shared/column-toggle/column-toggle.component");
var dialogs_1 = require("../../shared/dialogs");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var new_punch_card_dialog_component_1 = require("../../punch-cards/new-punch-card-dialog.component");
// constants
var columns_1 = require("./columns");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var DriverPunchCardsComponent = /** @class */ (function () {
    function DriverPunchCardsComponent(translationService, punchCardService, preferenceService, router, dialog) {
        var _this = this;
        this.translationService = translationService;
        this.punchCardService = punchCardService;
        this.preferenceService = preferenceService;
        this.router = router;
        this.dialog = dialog;
        this.loading = false;
        this.errors = [];
        this.appliedFilters = [];
        this.search = '';
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS.slice();
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.tableConfig = {
            collectionTitle: this.translationService.instant('Punchcards'),
            noResultsText: this.translationService.instant('a punchcard'),
            hasHeader: true,
            service: punch_card_service_1.PunchCardService,
            serviceFunction: 'getCondensedPunchCards',
            filterQuery: false,
            preferenceKey: 'DriverPunchCards-CondensedPunchService',
            preferencesEnabled: true,
            newRecordModal: function () { return _this.openAddPunchCard(); },
            query: {
                serializer: 'CondensedPunchCard',
                driver: '',
            },
        };
        this.multipleActionDropdownOptions = [
            { name: 'Export', action: 'export', button: true, link: false },
            { name: 'Void', action: 'void', button: true, link: false, disabled: true },
        ];
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Unvoid', action: 'unvoid', link: false },
            { name: 'Void', action: 'void', link: false },
        ];
        this.punchCards = [];
        this.expandedPunchCards = [];
        this.enabledFeatures = [];
        this.count = 0;
        this.allSelected = false;
        this.selectedPunchCards = [];
        this.allLoadedPunchCards = [];
        this.lockedExportFields = null;
        this.checkingDuplicates = false;
        this.savePunchCardCallback = function () {
            _this.getPunchCards();
        };
    }
    DriverPunchCardsComponent.prototype.ngOnInit = function () {
        this.tableConfig.query = __assign({}, this.tableConfig.query, { driver: this.driver.id });
        this.currentUrl = this.router.url.split('?')[0];
    };
    DriverPunchCardsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    DriverPunchCardsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(driver_trip_filters_dialog_component_1.DriverTripFiltersDialogComponent, {
            width: '430px',
        });
        dialog.componentInstance.title =
            this.translationService.instant('Filter Punch Cards');
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
        if (startDate && startDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.startDate = new Date(startDate);
        }
        var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
        if (endDate && endDate.hasOwnProperty('year')) {
            dialog.componentInstance.model.endDate = new Date(endDate);
        }
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'job' }), 'values');
        dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'project' }), 'values');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'customer' }), 'values');
        dialog.componentInstance.visibleFields.showEdited = false;
        dialog.componentInstance.visibleFields.showRetake = false;
        dialog.componentInstance.visibleFields.showIncomplete = false;
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    DriverPunchCardsComponent.prototype.filterChanges = function (filterRes) {
        var _this = this;
        var queryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            job: 'jobevent__job',
            carrier: 'driver__carrier',
            duplicates: 'only_dupes',
            uninvoiced: 'uninvoiced',
            startDate: 'jobevent__shift1_start__gte',
            endDate: 'jobevent__shift1_start__lte',
        };
        var displayKeys = {
            duplicates: 'Duplicates',
        };
        var falseyFilters = [];
        Object.keys(filterRes).forEach(function (key) {
            if (filterRes[key]) {
                var query = {};
                var values = void 0, displayValues = void 0;
                values =
                    filterRes[key] && key === 'tags'
                        ? filterRes[key].map(function (v) { return v.name; }).join(',')
                        : filterRes[key];
                displayValues =
                    filterRes[key] && filterRes[key]['name']
                        ? filterRes[key]['name']
                        : values;
                if (typeof values === 'boolean') {
                    if (key === 'incomplete' && values) {
                        displayValues = values.toString();
                        displayValues =
                            displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
                        var filterValue = (!values).toString();
                        filterValue =
                            filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                        query[queryKeys[key]] = filterValue;
                    }
                    else if (values) {
                        values = values.toString();
                        values = values.charAt(0).toUpperCase() + values.slice(1);
                        query[queryKeys[key]] = values;
                    }
                }
                else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
                    if (typeof values === 'string') {
                        query[queryKeys[key]] = values;
                    }
                    else {
                        query[queryKeys[key]] =
                            filterRes[key] && filterRes[key].id
                                ? filterRes[key].id
                                : filterRes[key];
                    }
                }
                else if (values) {
                    query[queryKeys[key]] =
                        filterRes[key] && (filterRes[key].id || filterRes[key]);
                }
                var filter = new filter_option_1.FilterOption({
                    filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                    key: key,
                    title: key.charAt(0).toUpperCase() + key.slice(1),
                    displayValues: displayValues || null,
                    displayKey: displayKeys[key] || null,
                    values: values,
                    query: query,
                });
                if (filter.values === 'False' || !filter.values) {
                    falseyFilters.push(filter);
                }
                var existingFilterIndex = lodash_1.findIndex(_this.appliedFilters, { key: key });
                if (existingFilterIndex !== -1) {
                    _this.appliedFilters[existingFilterIndex] = filter;
                }
                else {
                    _this.appliedFilters.push(filter);
                }
            }
            else {
                var existingFilter = lodash_1.find(_this.appliedFilters, { key: key });
                if (existingFilter) {
                    lodash_1.remove(_this.appliedFilters, existingFilter);
                }
            }
        });
        this.appliedFilters = lodash_1.uniqBy(lodash_1.difference(this.appliedFilters, falseyFilters), 'key');
    };
    DriverPunchCardsComponent.prototype.openAddPunchCard = function () {
        var driver = __assign({}, this.driver);
        var dialog = this.dialog.open(new_punch_card_dialog_component_1.NewPunchCardDialogComponent, {
            width: '455px',
        });
        dialog.componentInstance.model.driver = driver;
        dialog.componentInstance.model.carrier = driver.carrierId;
        dialog.componentInstance.model.truck = driver.truck;
        dialog.componentInstance.callback = this.savePunchCardCallback;
    };
    DriverPunchCardsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedPunchCards();
                break;
            case 'locked-export':
                this.exportSelectedPunchCards(null, true);
                break;
            case 'void':
                this.voidSelectedPunchCards();
                break;
        }
    };
    DriverPunchCardsComponent.prototype.menuAction = function (name, punchCard) {
        switch (name) {
            case 'void':
                this.voidPunchCard(punchCard);
                break;
            case 'unvoid':
                this.unvoidPunchCard(punchCard);
                break;
        }
    };
    DriverPunchCardsComponent.prototype.exportSelectedPunchCards = function (punchCards, locked) {
        var _this = this;
        if (punchCards === void 0) { punchCards = null; }
        if (locked === void 0) { locked = false; }
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, punchCards || this.selectedPunchCards, this.expandedPunchCards, this.allSelected, this.search), params = _a.params, scope = _a.scope;
        if (this.enabledFeatures.includes('hasExportTracking')) {
            scope['markAsExported'] = true;
        }
        if (this.enabledFeatures.includes('hasExportProtection')) {
            params = params.set('unexported', 'True');
        }
        params = params.set('driver', this.driver.id);
        this.punchCardService.getExportFields().subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'punchcards',
                    buttonText: 'Export Data to CSV',
                    callback: function () {
                        _this.punchCardsTable.deselectAll();
                    },
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.punchCardService,
                    lockedFields: locked ? _this.lockedExportFields : null,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    DriverPunchCardsComponent.prototype.voidSelectedPunchCards = function () {
        var _this = this;
        var punchCards = this.selectedPunchCards;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Punch Cards?',
            body: 'These punch cards will be marked as "Void" and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.punchCardsTable.loading = true;
                rxjs_1.forkJoin(punchCards.map(function (punchCard) {
                    return _this.punchCardService.save({ id: punchCard.id, void: true });
                })).subscribe(function () { return _this.getPunchCards(); }, function (err) {
                    _this.errors = err;
                    _this.punchCardsTable.loading = false;
                });
            }
            _this.confirmDialog = null;
            _this.punchCardsTable.deselectAll();
        });
    };
    DriverPunchCardsComponent.prototype.voidPunchCard = function (punchCard) {
        var _this = this;
        punchCard.loading = true;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Void Punch Card?',
            body: 'This Punch Card will be marked as "Void" and will not be visible for the Job.',
            close: 'Cancel',
            accept: 'Void',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.punchCardsTable.loading = true;
                _this.punchCardService.save({ id: punchCard.id, void: true }).subscribe(function (result) {
                    punchCard.void = true;
                    punchCard.loading = false;
                    _this.punchCardsTable.loading = false;
                }, function (err) {
                    _this.errors = err;
                    punchCard.loading = false;
                    _this.punchCardsTable.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    DriverPunchCardsComponent.prototype.unvoidPunchCard = function (punchCard) {
        var _this = this;
        punchCard.loading = true;
        this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px',
        });
        this.confirmDialog.componentInstance.attributes = {
            title: 'Unvoid Punch Card?',
            body: 'This Punch Card will be unmarked as "Void" and will be visible for the Job.',
            close: 'Cancel',
            accept: 'Unvoid',
        };
        this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.punchCardsTable.loading = true;
                _this.punchCardService.save({ id: punchCard.id, void: false }).subscribe(function (result) {
                    punchCard.void = false;
                    punchCard.loading = false;
                    _this.punchCardsTable.loading = false;
                }, function (err) {
                    _this.errors = err;
                    punchCard.loading = false;
                    _this.punchCardsTable.loading = false;
                });
            }
            _this.confirmDialog = null;
        });
    };
    DriverPunchCardsComponent.prototype.duplicateCheck = function () {
        if (!this.checkingDuplicates) {
            var filter = {
                filterType: 'text',
                key: 'duplicates',
                title: 'Duplicates',
                displayValues: 'True',
                values: true,
                query: {
                    only_dupes: 'True',
                },
            };
            var oldFilters = this.appliedFilters.map(function (f) { return f; });
            oldFilters.push(filter);
            this.appliedFilters = oldFilters;
            this.checkingDuplicates = !this.checkingDuplicates;
        }
        else {
            this.appliedFilters = this.appliedFilters.filter(function (f) { return f.key !== 'duplicates'; });
            this.checkingDuplicates = !this.checkingDuplicates;
        }
    };
    DriverPunchCardsComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.appliedFilters, filter);
        var duplicateFilter = lodash_1.find(this.appliedFilters, { key: 'duplicates' });
        if (!duplicateFilter) {
            this.checkingDuplicates = false;
        }
        this.savePreferences(this.appliedFilters);
        this.getPunchCards();
    };
    DriverPunchCardsComponent.prototype.savePreferences = function (filters) {
        var _this = this;
        var preference = this.punchCardsTable.preference;
        if (preference) {
            this.preference = __assign({}, preference, { blob: { filters: filters } });
            this.preferenceService
                .save(this.preference)
                .subscribe(function (updatedPreference) {
                _this.preference = updatedPreference;
            });
        }
    };
    DriverPunchCardsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        this.selectedPunchCards = this.allSelected
            ? (this.selectedPunchCards = this.allLoadedPunchCards.filter(function (p) { return !event.exclusion.selected.some(function (ex) { return ex.id === p.id; }); }))
            : event.selection.selected.slice();
        this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map(function (opt) {
            return opt.action === 'void'
                ? __assign({}, opt, { disabled: event.selection.selected.length === 0 && !event.allSelected }) : opt;
        });
    };
    DriverPunchCardsComponent.prototype.getPunchCards = function () {
        this.punchCardsTable.getRecords();
    };
    DriverPunchCardsComponent.prototype.onDataLoaded = function (_a) {
        var data = _a.data;
        this.allLoadedPunchCards = data.slice();
        if (this.punchCardsTable.preference &&
            this.punchCardsTable.preference.blob &&
            this.punchCardsTable.preference.blob.filters) {
            this.checkingDuplicates =
                this.punchCardsTable.preference.blob.filters.some(function (f) { return f.key === 'duplicates'; });
        }
    };
    return DriverPunchCardsComponent;
}());
exports.DriverPunchCardsComponent = DriverPunchCardsComponent;
