"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var notifications_component_1 = require("./notifications/notifications.component");
var auth_guard_1 = require("../guards/auth.guard");
var routes = [
    { path: 'notifications', component: notifications_component_1.NotificationsComponent, canActivate: [auth_guard_1.AuthGuard] },
    { path: '**', pathMatch: 'full', redirectTo: 'notifications' }
];
var ControlReportsRoutingModule = /** @class */ (function () {
    function ControlReportsRoutingModule() {
    }
    return ControlReportsRoutingModule;
}());
exports.ControlReportsRoutingModule = ControlReportsRoutingModule;
