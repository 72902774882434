import {
  Component, Input, Output, ViewChild, EventEmitter
} from '@angular/core';

import { Tag } from '../../tags/tag';
import { TagService } from '../../tags/tag.service';
import { RuckitDropdownComponent } from '../ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'tag-input',
  templateUrl: 'tag-input.component.html',
  styleUrls: ['tag-input.component.scss']
})
export class TagInputComponent {
  marketsConfig = {
    searchable: true,
    multiselect: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: 'Select Markets',
    loadingText: 'Loading Markets...',
    noResultsText: 'No Markets',
    service: TagService,
    query: {}
  };
  @ViewChild('marketsDropdown', { static: true }) marketsDropdown: RuckitDropdownComponent;

  @Input() selectedOptions: Tag[] = [];
  @Output() onSelect: EventEmitter<Tag[]> = new EventEmitter<Tag[]>();

  constructor() {}

  selectTags(tags: Tag[]): void {
    this.onSelect.emit(tags);
  }

  forceSelectedTags(tags: Tag[]): void {
    this.selectedOptions = tags;
    this.marketsDropdown.selectedItems = this.selectedOptions;
  }
}
